import { HttpClient, HttpEventType } from '@angular/common/http';
import { Injectable } from '@angular/core';
import fromPairs from 'lodash/fromPairs';
import toPairs from 'lodash/toPairs';
import { concat, Observable, of } from 'rxjs';
import { delay, map, publishLast, refCount, switchMap, tap, toArray } from 'rxjs/operators';

import { ApiService } from '@modules/api';
import { modelFieldToRawListViewSettingsColumn } from '@modules/customize';
import { FieldType, fileFieldTypes } from '@modules/fields';
import { Model, ModelDescription, ModelField, ModelFieldType } from '@modules/models';
import { Resource } from '@modules/projects';
import { HTTP_QUERY_KEY_AUTH_NAME } from '@modules/projects-components/data/http-query-auth';
import {
  HttpContentType,
  HttpMethod,
  HttpQuery,
  HttpQueryOptions,
  HttpQueryService,
  QueryType,
  StorageQuery
} from '@modules/queries';
import { Storage, StorageObject, StorageObjectsResponse, StorageObjectType } from '@modules/storages';
import { AppError, coerceArray, getExtension, getExtensionMime, getFilenameWithExtension, isSet } from '@shared';

import { ModelResponse } from '../../data/model-response';
import { ResourceController } from '../../data/resource-controller';
import { applyFrontendFiltering, applyFrontendPagination } from '../../utils/filters';
import { XANO_COLLECTION_TABLE_ID_NAME, XANO_PRIMARY_KEY_FIELD_NAME } from './xano-constants';
import { XanoLegacyResourceController } from './xano-legacy.resource-controller';
import {
  IXanoField,
  IXanoFile,
  IXanoFilePagingResponse,
  IXanoFileResponse,
  IXanoGetPagingResponse,
  IXanoInstance,
  IXanoTable,
  IXanoTableResponse,
  IXanoTableSchema,
  IXanoWorkspace
} from './xano-types';

@Injectable()
export class XanoResourceController extends ResourceController {
  public legacyController: XanoLegacyResourceController;

  private apiService: ApiService;
  private httpQueryService: HttpQueryService;
  private http: HttpClient;

  init() {
    this.apiService = this.initService<ApiService>(ApiService);
    this.httpQueryService = this.initService<HttpQueryService>(HttpQueryService);
    this.http = this.initService<HttpClient>(HttpClient);
    this.legacyController = this.initService<XanoLegacyResourceController>(XanoLegacyResourceController);
  }

  supportedQueryTypes(queryClass: any): QueryType[] {
    return [QueryType.Simple];
  }

  getFileInfo(urlValue: string): { fileExtension: string; fileMime: string; fileType: string } {
    const fileExtension = getExtension(urlValue) || 'file';
    const fileMime = getExtensionMime(fileExtension);
    const fileMimeType = isSet(fileMime) ? fileMime.split('/')[0] : undefined;
    let fileType: string;

    if (fileExtension == 'svg') {
      fileType = 'file';
    } else if (['image', 'audio', 'video'].includes(fileMimeType)) {
      fileType = fileMimeType;
    } else {
      fileType = 'file';
    }

    return {
      fileExtension: fileExtension,
      fileMime: fileMime,
      fileType: fileType
    };
  }

  serializeFile(urlValue: string): IXanoFile {
    let url: URL;

    try {
      url = new URL(urlValue);
    } catch (e) {}

    const fileName = getFilenameWithExtension(urlValue);
    const { fileMime, fileType } = this.getFileInfo(urlValue);
    const fileSize = 0;

    return {
      path: url ? url.pathname : urlValue,
      name: fileName,
      type: fileType,
      size: fileSize,
      mime: fileMime
    };
  }

  serializeModel(instance: Model, modelDescription: ModelDescription, fields?: string[]): Object {
    const data = toPairs(instance.serialize(fields))
      .filter(([k, v]) => {
        const field = modelDescription.dbFields.find(i => i.name == k);
        return field && field.editable;
      })
      .map(([name, value]) => {
        const field = modelDescription.dbFields.find(i => i.name == name);

        if (fileFieldTypes.includes(field.field)) {
          if (field.params && field.params['multiple']) {
            value = coerceArray(value).map(item => this.serializeFile(item));
          } else {
            value = this.serializeFile(value);
          }
        }

        return [name, value];
      });

    return fromPairs(data);
  }

  private generateUrl(apiBaseUrl: string, endpoints: string | string[]): string {
    if (Array.isArray(endpoints)) {
      return [apiBaseUrl, ...endpoints].join('/');
    } else {
      return [apiBaseUrl, endpoints].join('/');
    }
  }

  getUrl(domain: string, path: string) {
    return `https://${domain}/api:meta/${path}`;
  }

  getWorkspaceUrl(domain: string, workspaceId: number, path: string) {
    return this.getUrl(domain, `workspace/${workspaceId}/${path}`);
  }

  getInstances(accessToken: string): Observable<IXanoInstance[]> {
    const url = 'https://app.xano.com/api:meta/instance';
    const headers = { Authorization: `Bearer ${accessToken}` };

    return this.http
      .get<IXanoInstance[]>(url, { headers: headers })
      .pipe(this.apiService.catchApiError(), publishLast(), refCount());
  }

  getWorkspaces(accessToken: string, domain: string): Observable<IXanoWorkspace[]> {
    const url = this.getUrl(domain, 'workspace');
    const headers = { Authorization: `Bearer ${accessToken}` };

    return this.http
      .get<IXanoWorkspace[]>(url, { headers: headers })
      .pipe(this.apiService.catchApiError(), publishLast(), refCount());
  }

  getWorkspace(accessToken: string, domain: string, workspaceId: number): Observable<IXanoWorkspace> {
    const url = this.getUrl(domain, `workspace/${workspaceId}`);
    const headers = { Authorization: `Bearer ${accessToken}` };

    return this.http
      .get<IXanoWorkspace>(url, { headers: headers })
      .pipe(this.apiService.catchApiError(), publishLast(), refCount());
  }

  getTableSchemas(accessToken: string, domain: string, workspaceId: number): Observable<IXanoTableSchema[]> {
    const tablesUrl = this.getWorkspaceUrl(domain, workspaceId, 'table');
    const headers = { Authorization: `Bearer ${accessToken}` };

    const getNextTableResponse = (page: number): Observable<IXanoTableSchema[]> => {
      const params = { page: String(page) };

      return this.http
        .get<IXanoTableResponse>(tablesUrl, { headers: headers, params: params })
        .pipe(
          delay(2000),
          switchMap(tableResponse => {
            const obs$: Observable<IXanoTableSchema>[] = tableResponse.items.map(table => {
              const schemaUrl = this.getWorkspaceUrl(domain, workspaceId, `table/${table.id}/schema`);
              return this.http
                .get<IXanoField[]>(schemaUrl, { headers: headers })
                .pipe(
                  map(fieldsResponse => {
                    return {
                      table: table,
                      fields: fieldsResponse
                    };
                  }),
                  delay(2000)
                );
            });

            return concat(...obs$).pipe(
              toArray(),
              switchMap(result => {
                if (isSet(tableResponse.nextPage)) {
                  return getNextTableResponse(tableResponse.nextPage).pipe(
                    map(nextResult => [...result, ...nextResult])
                  );
                } else {
                  return of(result);
                }
              })
            );
          })
        );
    };

    return getNextTableResponse(1).pipe(this.apiService.catchApiError(), publishLast(), refCount());
  }

  private transformSingleItemResponse(data: Record<string, unknown>, fields: ModelField[]): Record<string, unknown> {
    fields.forEach(field => {
      if (fileFieldTypes.includes(field.item.field) && data[field.name]) {
        if (field.item.params && field.item.params['multiple']) {
          data[field.name] = (coerceArray(data[field.name]) as Record<string, unknown>[]).map(item => item['url']);
        } else {
          data[field.name] = (data[field.name] as Record<string, unknown>)['url'];
        }
      } else if (field.item.field === FieldType.RelatedModel && data[field.name] === 0) {
        data[field.name] = null;
      }
    });

    return data;
  }

  private transformArrayItemsResponse(
    data: Record<string, unknown>[],
    fields: ModelField[]
  ): Record<string, unknown>[] {
    for (const responseItem of data) {
      this.transformSingleItemResponse(responseItem, fields);
    }

    return data;
  }

  private getTableIdFromCollectionParam(modelDescription: ModelDescription): number {
    const tableId = modelDescription.params[XANO_COLLECTION_TABLE_ID_NAME];

    if (tableId === undefined) {
      throw new AppError(`Table ID was not found in collection: ${modelDescription.model}`);
    }

    return tableId as number;
  }

  private getHeaders(): { name: string; value: string }[] {
    return [
      {
        name: 'Authorization',
        value: `Bearer {-${HTTP_QUERY_KEY_AUTH_NAME}-}`
      }
    ];
  }

  modelGet(
    resource: Resource,
    modelDescription: ModelDescription,
    params?: {},
    body?: {}
  ): Observable<ModelResponse.GetResponse> {
    const domain = resource.params['domain'];
    const workspaceId = resource.params['workspace_id'];

    if (!isSet(domain) && !isSet(workspaceId)) {
      // Backward compatibility
      return this.legacyController.modelGet(resource, modelDescription, params, body);
    }

    const options: HttpQueryOptions = { resource: resource.uniqueName };
    const tableId = this.getTableIdFromCollectionParam(modelDescription);
    const url = this.getWorkspaceUrl(domain, workspaceId, `table/${tableId}/content`);
    const queryParams = [{ name: 'per_page', value: '10000' }];
    const query = new HttpQuery();

    query.method = HttpMethod.GET;
    query.url = url;
    query.queryParams = queryParams;
    query.headers = this.getHeaders();

    return this.httpQueryService.requestBody<IXanoGetPagingResponse>(query, options).pipe(
      map(response => {
        let responseItems = this.transformArrayItemsResponse(response.items, modelDescription.fields);

        const columns = modelDescription.fields
          .filter(item => item.type == ModelFieldType.Db)
          .map(item => modelFieldToRawListViewSettingsColumn(item));

        responseItems = applyFrontendFiltering(responseItems, params, columns);

        const responseData = {
          results: responseItems,
          count: response.itemsTotal
        };

        const result = this.createGetResponse().deserialize(responseData, modelDescription.model, undefined);

        applyFrontendPagination(result, params, true);

        return result;
      }),
      tap(response => {
        response.results.forEach(item => {
          item.deserializeAttributes(modelDescription.dbFields);
        });
      }),
      map(response => {
        if (!response) {
          return;
        }

        response.results.forEach(item => {
          item.setUp(modelDescription);
        });

        return response;
      }),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  modelGetDetail(
    resource: Resource,
    modelDescription: ModelDescription,
    idField: string,
    id: number,
    params?: {}
  ): Observable<Model> {
    const domain = resource.params['domain'];
    const workspaceId = resource.params['workspace_id'];

    if (!isSet(domain) && !isSet(workspaceId)) {
      // Backward compatibility
      return this.legacyController.modelGetDetail(resource, modelDescription, idField, id, params);
    }

    const options: HttpQueryOptions = { resource: resource.uniqueName };
    const tableId = this.getTableIdFromCollectionParam(modelDescription);
    const url = this.getWorkspaceUrl(domain, workspaceId, `table/${tableId}/content/search`);
    const data = {
      search: {
        [XANO_PRIMARY_KEY_FIELD_NAME]: id
      }
    };

    const query = new HttpQuery();

    query.method = HttpMethod.POST;
    query.url = url;
    query.headers = this.getHeaders();
    query.body = data;

    return this.httpQueryService.requestBody<IXanoGetPagingResponse>(query, options).pipe(
      map(result => {
        if (!result.items.length) {
          return;
        }

        const item = this.transformSingleItemResponse(result.items[0], modelDescription.fields);
        const model = this.createModel().deserialize(undefined, item);
        model.setUp(modelDescription);
        model.deserializeAttributes(modelDescription.dbFields);
        return model;
      }),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  modelCreate(
    resource: Resource,
    modelDescription: ModelDescription,
    modelInstance: Model,
    fields?: string[]
  ): Observable<Model> {
    const domain = resource.params['domain'];
    const workspaceId = resource.params['workspace_id'];

    if (!isSet(domain) && !isSet(workspaceId)) {
      // Backward compatibility
      return this.legacyController.modelCreate(resource, modelDescription, modelInstance, fields);
    }

    const options: HttpQueryOptions = { resource: resource.uniqueName };
    const tableId = this.getTableIdFromCollectionParam(modelDescription);
    const url = this.getWorkspaceUrl(domain, workspaceId, `table/${tableId}/content`);

    const query = new HttpQuery();

    query.method = HttpMethod.POST;
    query.url = url;
    query.headers = this.getHeaders();
    query.body = this.serializeModel(modelInstance, modelDescription, fields);

    return this.httpQueryService.requestBody<Record<string, unknown>>(query, options).pipe(
      map(result => {
        if (!result) {
          return;
        }

        result = this.transformSingleItemResponse(result, modelDescription.fields);

        const model = this.createModel().deserialize(undefined, result);
        model.setUp(modelDescription);
        model.deserializeAttributes(modelDescription.dbFields);
        return model;
      }),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  modelUpdate(
    resource: Resource,
    modelDescription: ModelDescription,
    modelInstance: Model,
    fields?: string[]
  ): Observable<Model> {
    const domain = resource.params['domain'];
    const workspaceId = resource.params['workspace_id'];

    if (!isSet(domain) && !isSet(workspaceId)) {
      // Backward compatibility
      return this.legacyController.modelUpdate(resource, modelDescription, modelInstance, fields);
    }

    const id = modelInstance.initialPrimaryKey;

    // if (id === undefined) {
    //   return throwError(new MissedIdParamError(XANO_PRIMARY_KEY_FIELD_NAME));
    // }

    const options: HttpQueryOptions = { resource: resource.uniqueName };
    const tableId = this.getTableIdFromCollectionParam(modelDescription);
    const url = this.getWorkspaceUrl(domain, workspaceId, `table/${tableId}/content/${id}`);

    const query = new HttpQuery();

    query.method = HttpMethod.PUT;
    query.url = url;
    query.headers = this.getHeaders();
    query.body = this.serializeModel(modelInstance, modelDescription, fields);

    return this.httpQueryService.requestBody<Record<string, unknown>>(query, options).pipe(
      map(result => {
        if (!result) {
          return;
        }

        result = this.transformSingleItemResponse(result, modelDescription.fields);

        const instance = this.createModel().deserialize(modelDescription.model, result);
        instance.setUp(modelDescription);
        instance.deserializeAttributes(modelDescription.dbFields);
        return instance;
      }),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  modelDelete(resource: Resource, modelDescription: ModelDescription, modelInstance: Model): Observable<Object> {
    const domain = resource.params['domain'];
    const workspaceId = resource.params['workspace_id'];

    if (!isSet(domain) && !isSet(workspaceId)) {
      // Backward compatibility
      return this.legacyController.modelDelete(resource, modelDescription, modelInstance);
    }

    const id = modelInstance.initialPrimaryKey;

    // if (id === undefined) {
    //   return throwError(new MissedIdParamError(XANO_PRIMARY_KEY_FIELD_NAME));
    // }

    const options: HttpQueryOptions = { resource: resource.uniqueName };
    const tableId = this.getTableIdFromCollectionParam(modelDescription);
    const url = this.getWorkspaceUrl(domain, workspaceId, `table/${tableId}/content/${id}`);

    const query = new HttpQuery();

    query.method = HttpMethod.DELETE;
    query.url = url;
    query.headers = this.getHeaders();

    return this.httpQueryService
      .requestBody<Object>(query, options)
      .pipe(this.apiService.catchApiError(), publishLast(), refCount());
  }

  uploadFile(
    resource: Resource,
    storage: Storage,
    query: StorageQuery,
    file: File,
    path?: string,
    fileName?: string
  ): Observable<ModelResponse.UploadFileResponse> {
    const domain = resource.params['domain'];
    const workspaceId = resource.params['workspace_id'];

    if (!isSet(domain) && !isSet(workspaceId)) {
      // Backward compatibility
      return this.legacyController.uploadFile(resource, storage, query, file, path, fileName);
    }

    const apiBaseUrl = `https://${domain}`;
    const httpQuery = new HttpQuery();
    const httpOptions: HttpQueryOptions = { resource: resource.uniqueName, tokens: { file: file } };
    const fileInfo = this.getFileInfo(file.name);

    httpQuery.method = HttpMethod.POST;
    httpQuery.url = this.getWorkspaceUrl(domain, workspaceId, 'file');
    httpQuery.headers = this.getHeaders();
    httpQuery.bodyType = HttpContentType.FormData;
    httpQuery.body = [{ name: 'content', value: '{{file}}' }];

    if (fileInfo.fileType != 'file') {
      httpQuery.body.push({ name: 'type', value: fileInfo.fileType });
    }

    return this.httpQueryService.executeRequest<IXanoFileResponse>(httpQuery, httpOptions).pipe(
      map(event => {
        if (event.type == HttpEventType.Response) {
          const mediaBaseUrl = new URL(apiBaseUrl).origin;
          return {
            result: {
              uploadedPath: event.body.path,
              uploadedUrl: mediaBaseUrl + event.body.path,
              response: event
            },
            state: {
              downloadProgress: 1,
              uploadProgress: 1
            }
          };
        } else if (event.type == HttpEventType.UploadProgress) {
          return {
            state: {
              uploadProgress: event.loaded / event.total,
              downloadProgress: 0,
              uploadLoaded: event.loaded,
              uploadTotal: event.total
            }
          };
        } else if (event.type == HttpEventType.DownloadProgress) {
          return {
            state: {
              uploadProgress: 1,
              downloadProgress: event.loaded / event.total,
              downloadLoaded: event.loaded,
              downloadTotal: event.total
            }
          };
        }
      }),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  getStorageObjects(
    resource: Resource,
    storage: Storage,
    query: StorageQuery,
    path: string
  ): Observable<StorageObjectsResponse> {
    const domain = resource.params['domain'];
    const workspaceId = resource.params['workspace_id'];

    if (!isSet(domain) && !isSet(workspaceId)) {
      // Backward compatibility
      return of(new StorageObjectsResponse());
    }

    const apiBaseUrl = `https://${domain}`;
    const httpQuery = new HttpQuery();
    const httpOptions: HttpQueryOptions = { resource: resource.uniqueName };

    httpQuery.method = HttpMethod.GET;
    httpQuery.url = this.getWorkspaceUrl(domain, workspaceId, 'file');
    httpQuery.headers = this.getHeaders();

    return this.httpQueryService.requestBody<IXanoFilePagingResponse>(httpQuery, httpOptions).pipe(
      map(result => {
        const response = new StorageObjectsResponse();

        response.objects = result.items.map(item => {
          const mediaBaseUrl = new URL(apiBaseUrl).origin;
          const mediaUrl = mediaBaseUrl + item.path;

          return new StorageObject({
            path: item.path,
            url: mediaUrl,
            type: StorageObjectType.File,
            size: item.size,
            created: item.created_at
          });
        });

        return response;
      }),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  deleteStorageObject(resource: Resource, storage: Storage, query: StorageQuery, path: string): Observable<Object> {
    const domain = resource.params['domain'];
    const workspaceId = resource.params['workspace_id'];

    if (!isSet(domain) && !isSet(workspaceId)) {
      // Backward compatibility
      return of(false);
    }

    const httpOptions: HttpQueryOptions = { resource: resource.uniqueName };
    const getQuery = new HttpQuery();
    const fileName = path.split('/').slice(-1)[0];

    getQuery.method = HttpMethod.GET;
    getQuery.url = this.getWorkspaceUrl(domain, workspaceId, 'file');
    getQuery.headers = this.getHeaders();
    getQuery.queryParams = [{ name: 'search', value: fileName }];

    return this.httpQueryService.requestBody<IXanoFilePagingResponse>(getQuery, httpOptions).pipe(
      switchMap(result => {
        const file = result.items.find(item => item.path == path);

        if (!file) {
          throw new AppError('File not found');
        }

        const deleteQuery = new HttpQuery();

        deleteQuery.method = HttpMethod.DELETE;
        deleteQuery.url = this.getWorkspaceUrl(domain, workspaceId, `file/${file.id}`);
        deleteQuery.headers = this.getHeaders();

        return this.httpQueryService.requestBody<IXanoFilePagingResponse>(deleteQuery, httpOptions);
      }),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }
}

import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, publishLast, refCount } from 'rxjs/operators';

import { AdminMode, ROUTE_ADMIN_MODE } from '@modules/admin-mode';
import { ApiService, ServerRequestError } from '@modules/api';
import {
  Environment,
  Project,
  Resource,
  ResourceTypeItem,
  SecretToken,
  SecretTokenService,
  SecretTokenType
} from '@modules/projects';
import { HttpQuery, HttpQueryService } from '@modules/queries';
import { ResourceParamsResult } from '@modules/resources';

// TODO: Refactor import
import { HTTP_QUERY_KEY_AUTH_NAME } from '@modules/projects-components/data/http-query-auth';

import { stripeResourceParamsActionDescriptions } from '../../data/stripe/stripe-resource-params-action-descriptions.stub';
import { stripeResourceParamsMenuSettings } from '../../data/stripe/stripe-resource-params-menu-settings.stub';
import { stripeResourceParamsModelDescriptions } from '../../data/stripe/stripe-resource-params-model-descriptions.stub';
import { stripeResourceParamsParams } from '../../data/stripe/stripe-resource-params-params.stub';
import { stripeResourceParamsViewSettings } from '../../data/stripe/stripe-resource-params-view-settings.stub';
import { IsOptionsValidResult, ResourceGeneratorService } from '../resource-generator/resource-generator.service';

export interface StripeParamsOptions {
  secretKey: string;
}

@Injectable()
export class StripeGeneratorService extends ResourceGeneratorService<StripeParamsOptions> {
  constructor(
    @Inject(ROUTE_ADMIN_MODE) private mode: AdminMode,
    private secretTokenService: SecretTokenService,
    private httpQueryService: HttpQueryService
  ) {
    super();
  }

  isOptionsValid(options: StripeParamsOptions): Observable<IsOptionsValidResult> {
    const query = new HttpQuery();

    query.url = 'https://api.stripe.com/v1/customers';
    query.headers = [{ name: 'Authorization', value: `Bearer ${options.secretKey}` }];

    return this.httpQueryService.request(query).pipe(
      map(() => {
        return {};
      }),
      catchError(error => {
        if (error instanceof ServerRequestError && error.response instanceof HttpErrorResponse && error.status == 401) {
          error = new ServerRequestError('Secret token is not valid or not enough permissions');
        }

        return throwError(error);
      }),
      publishLast(),
      refCount()
    );
  }

  getParamsOptions(project: Project, environment: Environment, resource: Resource): Observable<StripeParamsOptions> {
    return this.secretTokenService
      .getDetail(
        project.uniqueName,
        environment.uniqueName,
        resource.uniqueName,
        HTTP_QUERY_KEY_AUTH_NAME,
        this.mode == AdminMode.Builder
      )
      .pipe(
        map(result => {
          return {
            secretKey: result.value
          };
        })
      );
  }

  generateParams(
    project: Project,
    environment: Environment,
    typeItem: ResourceTypeItem,
    options: StripeParamsOptions
  ): Observable<ResourceParamsResult> {
    const resourceParams = stripeResourceParamsParams;
    const modelDescriptions = stripeResourceParamsModelDescriptions;
    const actionDescriptions = stripeResourceParamsActionDescriptions;
    const viewSettings = stripeResourceParamsViewSettings;
    const menuSettings = stripeResourceParamsMenuSettings;
    const token = new SecretToken();

    token.name = HTTP_QUERY_KEY_AUTH_NAME;
    token.type = SecretTokenType.Static;
    token.value = options.secretKey;

    return of({
      resourceParams: resourceParams,
      actionDescriptions: actionDescriptions,
      modelDescriptions: modelDescriptions,
      viewSettings: viewSettings,
      menuSettings: menuSettings,
      secretTokens: [token.serialize()]
    });
  }
}

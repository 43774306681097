import { ComponentFactoryResolver, Injectable, Injector } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';

import { MediumDialogPopupComponent, ThinDialogPopupComponent } from '@common/dialog-popup';
import { PopupService } from '@common/popups';
import { ProjectGroup } from '@modules/projects';

import { ProjectGroupCreatePopupComponent } from '../../components/project-group-create-popup/project-group-create-popup.component';
import { ProjectGroupEditPopupComponent } from '../../components/project-group-edit-popup/project-group-edit-popup.component';

export enum ProjectGroupEditResult {
  Edited = 'edited',
  Deleted = 'deleted'
}

export interface ProjectGroupEditEvent {
  result: ProjectGroupEditResult;
  group: ProjectGroup;
}

@Injectable()
export class ProjectGroupEditController {
  constructor(
    private popupService: PopupService,
    private injector: Injector,
    private resolver: ComponentFactoryResolver
  ) {}

  create(options: { propertiesEnabled?: boolean; deleteEnabled?: boolean } = {}): Observable<ProjectGroup> {
    const obs = new ReplaySubject<ProjectGroup>();

    this.popupService.push({
      component: ProjectGroupEditPopupComponent,
      popupComponent: MediumDialogPopupComponent,
      inputs: {
        ...(options.propertiesEnabled !== undefined ? { propertiesEnabled: options.propertiesEnabled } : {}),
        ...(options.deleteEnabled !== undefined ? { deleteEnabled: options.deleteEnabled } : {})
      },
      outputs: {
        created: [result => obs.next(result)]
      },
      resolver: this.resolver,
      injector: this.injector
    });

    return obs.asObservable();
  }

  createSimple(): Observable<ProjectGroup> {
    const obs = new ReplaySubject<ProjectGroup>();

    this.popupService.push({
      component: ProjectGroupCreatePopupComponent,
      popupComponent: ThinDialogPopupComponent,
      resolver: this.resolver,
      injector: this.injector,
      outputs: {
        created: [result => obs.next(result)]
      }
    });

    return obs.asObservable();
  }

  edit(
    group: ProjectGroup,
    options: { propertiesEnabled?: boolean; deleteEnabled?: boolean } = {}
  ): Observable<ProjectGroupEditEvent> {
    const obs = new ReplaySubject<ProjectGroupEditEvent>();

    this.popupService.push({
      component: ProjectGroupEditPopupComponent,
      popupComponent: MediumDialogPopupComponent,
      resolver: this.resolver,
      injector: this.injector,
      inputs: {
        group: group,
        ...(options.propertiesEnabled !== undefined ? { propertiesEnabled: options.propertiesEnabled } : {}),
        ...(options.deleteEnabled !== undefined ? { deleteEnabled: options.deleteEnabled } : {})
      },
      outputs: {
        updated: [
          result =>
            obs.next({
              result: ProjectGroupEditResult.Edited,
              group: result
            })
        ],
        deleted: [
          result =>
            obs.next({
              result: ProjectGroupEditResult.Deleted,
              group: result
            })
        ]
      }
    });

    return obs.asObservable();
  }
}

import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
var GuideFocusService = /** @class */ (function () {
    function GuideFocusService() {
        this._position = new BehaviorSubject(undefined);
    }
    Object.defineProperty(GuideFocusService.prototype, "position", {
        get: function () {
            return this._position.value;
        },
        set: function (value) {
            this._position.next(value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GuideFocusService.prototype, "position$", {
        get: function () {
            return this._position.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    GuideFocusService.ngInjectableDef = i0.defineInjectable({ factory: function GuideFocusService_Factory() { return new GuideFocusService(); }, token: GuideFocusService, providedIn: "root" });
    return GuideFocusService;
}());
export { GuideFocusService };

import {
  ApplicationRef,
  ComponentRef,
  Directive,
  ElementRef,
  EmbeddedViewRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import isArray from 'lodash/isArray';

import { nodeListToArray } from '@shared';

import { DraggableService } from '../../services/draggable/draggable.service';
import { DraggableItemDirective } from '../draggable-item/draggable-item.directive';

@Directive({
  selector: '[appDraggable]'
})
export class DraggableDirective implements OnInit, OnDestroy {
  @Input('appDraggable') name: string;
  @Input('appDraggableOptions') options = {};
  @Output('appDraggableItemsUpdated') itemsUpdated = new EventEmitter<DraggableItemDirective[]>();
  @Output('appDraggableDragChanged') dragChanged = new EventEmitter<boolean>();
  @Output('appDraggableDragStarted') dragStarted = new EventEmitter<DraggableItemDirective>();
  @Output('appDraggableDragFinished') dragFinished = new EventEmitter<DraggableItemDirective>();
  @Output('appDraggableItemAdded') itemAdded = new EventEmitter<DraggableItemDirective>();
  @Output('appDraggableItemRemoved') itemRemoved = new EventEmitter<DraggableItemDirective>();

  items: DraggableItemDirective[] = [];
  scrollableParent: Element;

  constructor(public el: ElementRef, private appRef: ApplicationRef, private draggableService: DraggableService) {}

  ngOnInit(): void {
    this.draggableService.add(this);
    this.updateScrollableParent();
  }

  ngOnDestroy(): void {
    this.draggableService.remove(this);
  }

  addItem(item: DraggableItemDirective, silent = false) {
    if (this.items.find(i => i === item)) {
      return;
    }
    this.items.push(item);
    this.updatePositionFor(item, { silent: silent });

    if (!silent) {
      this.itemsUpdated.emit(this.items);
      this.itemAdded.emit(item);
    }
  }

  removeItem(item: DraggableItemDirective, silent = false) {
    this.items = this.items.filter(i => i !== item);

    if (!silent) {
      this.itemsUpdated.emit(this.items);
      this.itemRemoved.emit(item);
    }
  }

  addExternalItem(item: DraggableItemDirective) {
    if (this.items.find(i => i === item)) {
      return;
    }

    const copy = item.draggingFrom.options['copy'];
    const copyAllowed = !!copy && !item.copied && (!isArray(copy) || copy.includes(this.name));
    const deletable = item.draggingFrom.options['deletable'];
    const deletableAllowed =
      !!deletable && this !== item.draggingFrom && (!isArray(deletable) || deletable.includes(this.name));

    if (copyAllowed) {
      const clone = item.el.nativeElement.cloneNode(true) as HTMLElement;
      clone.classList.remove('dragging');

      item.el.nativeElement.parentNode.insertBefore(clone, item.el.nativeElement);
      item.copied = true;

      const directive = item.createDirective(new ElementRef(clone), item.parent);
      directive.data = item.data;
      directive.ngAfterViewInit();
    }

    const addNode = !deletableAllowed && (!copy || this !== item.draggingFrom);

    if (item.el.nativeElement.parentNode) {
      item.el.nativeElement.parentNode.removeChild(item.el.nativeElement);
    }

    if (item.parent.options['containerRef']) {
      const index = item.parent.options['containerRef'].indexOf(item.viewRef);

      if (index != -1) {
        item.parent.options['containerRef'].detach(index);
      }
    }

    if (item.draggingFrom.options['createComponent']) {
      const componentRef: ComponentRef<any> = item.draggingFrom.options['createComponent'](
        item,
        this,
        this.el.nativeElement
      );
      // this.viewContainerRef.insert(componentRef.hostView);
      // componentRef.changeDetectorRef.detectChanges();

      const domElem = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;

      item.el = new ElementRef(domElem);
      item.viewRef = componentRef.hostView;
      item.init();

      // this.viewContainerRef.insert(componentRef.hostView);
      if (this.options['containerRef']) {
        this.appRef.detachView(componentRef.hostView);
        this.options['containerRef'].insert(componentRef.hostView);
      }

      item.parent.removeItem(item);

      if (addNode) {
        this.addItem(item);
      }
    } else {
      if (addNode) {
        this.el.nativeElement.appendChild(item.el.nativeElement);
      }

      if (this.options['containerRef']) {
        this.options['containerRef'].insert(item.viewRef);
      }

      item.parent.removeItem(item);

      if (addNode) {
        this.addItem(item);
      }
    }

    item.parent = this;
  }

  updatePositionFor(
    item: DraggableItemDirective,
    options: { silent?: boolean; mousePosition?: { x: number; y: number } } = {}
  ) {
    if (this.options['sortable'] == false) {
      return;
    }

    const items = this.items.sort((lhs, rhs) => lhs.elementIndex - rhs.elementIndex);
    const oldIndex = items.findIndex(i => i === item);
    const newIndex = items
      .map(i => {
        return {
          item: i,
          top: options.mousePosition && i === item ? options.mousePosition.y : i.bounds.top
        };
      })
      .sort((lhs, rhs) => lhs.top - rhs.top)
      .findIndex(i => i.item === item);

    if (oldIndex == newIndex) {
      return;
    }

    const el = item.el.nativeElement;
    const parent = this.el.nativeElement;

    if (newIndex > oldIndex) {
      parent.insertBefore(el, parent.children[newIndex + 1]);
    } else {
      parent.insertBefore(el, parent.children[newIndex]);
    }

    const nodes = nodeListToArray(parent.children);

    this.items = this.items.sort((lhs, rhs) => {
      return nodes.indexOf(lhs.el.nativeElement) - nodes.indexOf(rhs.el.nativeElement);
    });

    if (!options.silent) {
      this.itemsUpdated.emit(this.items);
    }
  }

  isPointInside(x, y) {
    const bounds = this.el.nativeElement.getBoundingClientRect();

    return x >= bounds.left && x <= bounds.left + bounds.width && y >= bounds.top && y <= bounds.top + bounds.height;
  }

  get depth() {
    let depth = 0;
    let el = this.el.nativeElement;

    while (el.parentNode) {
      ++depth;
      el = el.parentNode;
    }

    return depth;
  }

  updateScrollableParent() {
    if (this.options['scrollable']) {
      this.scrollableParent = this.options['scrollable'];
      return;
    }

    const iterate = el => {
      if (!el || !(el instanceof Element)) {
        return;
      }

      if (el.getAttribute('xsScrollable') != null) {
        return el;
      } else if (['scroll', 'auto'].includes(getComputedStyle(el)['overflow'])) {
        return el;
      } else if (el === document.body) {
        return el;
      }

      return iterate(el.parentNode);
    };

    this.scrollableParent = iterate(this.el.nativeElement);
  }
}

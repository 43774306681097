import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';

import { SeparatorMenuItem } from '@modules/menu';

import { BaseMenuItemComponent } from '../base-menu-item/base-menu-item.component';

@Component({
  selector: 'app-separator-menu-item',
  templateUrl: './separator-menu-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SeparatorMenuItemComponent extends BaseMenuItemComponent implements OnInit, OnDestroy {
  @Input() menuItem: SeparatorMenuItem;

  ngOnInit() {}

  ngOnDestroy(): void {}
}

import { Injectable } from '@angular/core';
import clone from 'lodash/clone';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { PAGE_PARAM } from '@modules/models';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { ListStore, ListStoreFetchResponse } from '@shared';

import { DataSyncJobTask } from '../data/data-sync-job-task';
import { DataSyncService } from '../services/data-sync/data-sync.service';

@Injectable()
export class DataSyncJobTaskStore extends ListStore<DataSyncJobTask> {
  jobId: string;
  params = {};

  constructor(
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private dataSyncService: DataSyncService
  ) {
    super();
  }

  paramsForPage(page) {
    const params = clone(this.params);
    params[PAGE_PARAM] = page;
    return params;
  }

  fetchPage(page: number, next: boolean): Observable<ListStoreFetchResponse<DataSyncJobTask>> {
    const params = this.paramsForPage(page);

    return this.dataSyncService
      .getJobTasks(this.currentProjectStore.instance, this.currentEnvironmentStore.instance, this.jobId, params)
      .pipe(
        map(response => {
          return {
            items: response.results,
            hasMore: page < response.numPages,
            totalPages: response.numPages,
            perPage: response.perPage,
            count: response.count
          };
        })
      );
  }
}

import pickBy from 'lodash/pickBy';
import { Widget } from './widgets/items/widget';
var Dashboard = /** @class */ (function () {
    function Dashboard() {
        this.visible = true;
    }
    Dashboard.prototype.deserialize = function (data) {
        this.id = data['id'];
        this.project = data['project'];
        this.name = data['name'];
        this.ordering = data['ordering'];
        this.dateAdd = data['date_add'];
        this.visible = data['visible'];
        if (data['widgets']) {
            this.widgets = data['widgets'].map(function (item) { return new Widget().deserialize(item); });
        }
        return this;
    };
    Dashboard.prototype.serialize = function (fields) {
        var data = {
            id: this.id,
            project: this.project,
            name: this.name,
            ordering: this.ordering,
            date_add: this.dateAdd,
            visible: this.visible
        };
        if (fields) {
            data = pickBy(data, function (v, k) { return fields.includes(k); });
        }
        return data;
    };
    Object.defineProperty(Dashboard.prototype, "link", {
        get: function () {
            return ['dashboards', this.id];
        },
        enumerable: true,
        configurable: true
    });
    return Dashboard;
}());
export { Dashboard };

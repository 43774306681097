<ng-container *ngIf="relations">
  <div class="table__column-inner">
    <ng-container *ngIf="item.activityType == userActivityTypes.DashboardList">
      <a *ngIf="relations['dashboard']" [appLink]="relations['dashboard'].link">{{
        relations['dashboard'].name | titlecase
      }}</a>
    </ng-container>
    <ng-container
      *ngIf="item.activityType == userActivityTypes.ModelList || item.activityType == userActivityTypes.ModelDelete"
    >
      <a *ngIf="relations['model_description']" [appLink]="relations['model_description'].link">{{
        relations['model_description'].verboseNamePlural | titlecase
      }}</a>
    </ng-container>
    <ng-container
      *ngIf="
        item.activityType == userActivityTypes.ModelDetail ||
        item.activityType == userActivityTypes.ModelCreate ||
        item.activityType == userActivityTypes.ModelUpdate
      "
      >{{ relations['model_description'].verboseName | titlecase }} –
      <!--      <a-->
      <!--        *ngIf="relations['model']"-->
      <!--        [appLink]="relations['model'].getLink()"-->
      <!--        [innerHTML]="relations['model'] | appModelStr | appStub | async"-->
      <!--      ></a>-->
      <strong *ngIf="relations['model']" [innerHTML]="relations['model'] | appModelStr | appStub | async"></strong>
    </ng-container>
  </div>

  <ng-container *ngIf="item.activityType == userActivityTypes.ModelUpdate && item.params['changes']">
    <br />
    <div class="table__column-inner">
      <ng-container *ngFor="let item of item.params['changes']; let last = last">
        {{ relations['model_description'].field(item['key'])?.verboseName | titlecase }}
        <ng-container *ngIf="!last">, </ng-container>
      </ng-container>
    </div>
  </ng-container>

  <ng-container *ngIf="item.activityType == userActivityTypes.ModelUpdate && item.params['fields']">
    <br />
    <div class="table__column-inner">
      <ng-container *ngFor="let item of item.params['fields']; let last = last">
        {{ relations['model_description'].field(item)?.verboseName | titlecase }}
        <ng-container *ngIf="!last">, </ng-container>
      </ng-container>
    </div>
  </ng-container>

  <ng-container *ngIf="item.activityType == userActivityTypes.ModelCreate && item.params['duplicate']">
    <br />
    <div class="table__column-inner">
      duplicated from
      <!--      <a-->
      <!--        *ngIf="relations['duplicate']"-->
      <!--        [appLink]="relations['duplicate'].getLink()"-->
      <!--        [innerHTML]="relations['duplicate'] | appModelStr | appStub | async"-->
      <!--      ></a>-->
      <strong
        *ngIf="relations['duplicate']"
        [innerHTML]="relations['duplicate'] | appModelStr | appStub | async"
      ></strong>
      <ng-container *ngIf="!relations['duplicate']"
        >not existing record ({{ item.params['duplicate'] | titlecase }})</ng-container
      >
    </div>
  </ng-container>
</ng-container>

<app-popup2 [size]="'xl'">
  <div class="marketplace">
    <div class="marketplace__header">
      <div class="marketplace__title marketplace__title_margin_s">{{ title }}</div>
      <div class="marketplace__navigation">
        <div class="tabs2-navigation tabs2-navigation_orange">
          <ng-container *ngFor="let item of filters">
            <div
              *ngIf="!item.staff || (currentUserStore.instance$ | async)?.isStaff"
              class="tabs2-navigation__item"
              [class.tabs2-navigation__item_active]="item === currentFilterItem"
              [class.staff-block]="item.staff"
              [class.staff-block_background]="item.staff"
              (click)="setCurrentFilter(item)"
            >
              <span
                *ngIf="item.icon"
                class="tabs2-navigation__item-icon tabs2-navigation__item-icon_left"
                [ngClass]="'icon-' + item.icon"
              ></span>
              {{ item.label }}
            </div>
          </ng-container>
        </div>
      </div>

      <div class="marketplace__close icon-close" (click)="close.emit()"></div>
    </div>

    <div class="marketplace__content">
      <div *ngIf="loading" class="marketplace__section">
        <div class="marketplace__section-content">
          <div *ngFor="let item of [1, 2, 3]" class="marketplace__section-item" [style.width.%]="100 / perRow">
            <app-custom-view-templates-card-stub></app-custom-view-templates-card-stub>
          </div>
        </div>
      </div>

      <app-page-block *ngIf="!loading && error">
        <div class="table__empty">
          <app-error [title]="'Components failed to load' | localize" [compact]="true"></app-error>
        </div>
      </app-page-block>

      <ng-container *ngIf="!loading && !error">
        <div *ngIf="(featuredItems$ | async)?.length" class="marketplace__section">
          <div class="marketplace__section-header">
            <div class="marketplace__section-title">Featured</div>
            <!--            <div class="marketplace__section-button">See all</div>-->
          </div>

          <div class="marketplace__section-content">
            <div
              *ngFor="let item of featuredItems$ | async"
              class="marketplace__section-item"
              [style.width.%]="100 / perRow"
            >
              <app-custom-view-templates-card
                [item]="item"
                [interactive]="true"
                (click)="selectTemplate.emit(item)"
              ></app-custom-view-templates-card>
            </div>
          </div>
        </div>

        <div *ngIf="(listItems$ | async)?.length" class="marketplace__section">
          <div class="marketplace__section-header">
            <div class="marketplace__section-title">Components</div>
          </div>

          <div class="marketplace__section-content">
            <div *ngIf="viewCreateEnabled" class="marketplace__section-item" [style.width.%]="100 / perRow">
              <app-custom-view-templates-action-card (click)="createView()"></app-custom-view-templates-action-card>
            </div>

            <div
              *ngFor="let item of listItems$ | async"
              class="marketplace__section-item"
              [style.width.%]="100 / perRow"
            >
              <app-custom-view-templates-card
                [item]="item"
                [interactive]="true"
                (click)="selectTemplate.emit(item)"
              ></app-custom-view-templates-card>
            </div>

            <ng-container *ngIf="moreLoading">
              <div *ngFor="let item of [1, 2, 3]" class="marketplace__section-item" [style.width.%]="100 / perRow">
                <app-custom-view-templates-card-stub></app-custom-view-templates-card-stub>
              </div>
            </ng-container>

            <div
              *ngIf="!listStore.hasMore && (listItems$ | async)?.length > 11"
              class="marketplace__section-item"
              [style.width.%]="100 / perRow"
            >
              <app-custom-view-templates-action-card (click)="createView()"></app-custom-view-templates-action-card>
            </div>
          </div>
        </div>

        <app-page-block *ngIf="!(featuredItems$ | async)?.length && !(listItems$ | async)?.length">
          <div class="table__empty">
            <app-error [title]="'Nothing found' | localize" [compact]="true"></app-error>
          </div>
        </app-page-block>
      </ng-container>
    </div>
  </div>
</app-popup2>

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  ActionGroupElementItem,
  ActionGroupOrientation,
  CustomizeService,
  ElementType,
  registerElementComponent
} from '@modules/customize';
import { BaseElementComponent } from '@modules/customize-elements';

@Component({
  selector: 'app-action-group-element',
  templateUrl: './action-group-element.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActionGroupElementComponent extends BaseElementComponent implements OnInit, OnDestroy {
  @Input() element: ActionGroupElementItem;

  customizeEnabled$: Observable<boolean>;
  orientations = ActionGroupOrientation;

  constructor(private customizeService: CustomizeService, private cd: ChangeDetectorRef) {
    super();
  }

  ngOnInit() {
    this.customizeEnabled$ = this.customizeService.enabled$.pipe(map(item => !!item));
  }

  ngOnDestroy(): void {}
}

registerElementComponent({
  type: ElementType.ActionGroup,
  component: ActionGroupElementComponent,
  label: 'Action group',
  actions: []
});

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./compact-border-button.component";
var styles_CompactBorderButtonComponent = [];
var RenderType_CompactBorderButtonComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CompactBorderButtonComponent, data: {} });
export { RenderType_CompactBorderButtonComponent as RenderType_CompactBorderButtonComponent };
function View_CompactBorderButtonComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["class", "compact-border-button__left"]], [[2, "compact-border-button__left_background", null], [2, "compact-border-button__left_interactive", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.leftClick.emit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "div", [["class", "compact-border-button__icon"]], null, null, null, null, null)), i0.ɵdid(2, 278528, null, 0, i1.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpad(3, 1)], function (_ck, _v) { var _co = _v.component; var currVal_2 = "compact-border-button__icon"; var currVal_3 = _ck(_v, 3, 0, ("icon-" + _co.leftIcon)); _ck(_v, 2, 0, currVal_2, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.leftBackground; var currVal_1 = _co.leftInteractive; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_CompactBorderButtonComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["class", "compact-border-button__right"]], [[2, "compact-border-button__right_background", null], [2, "compact-border-button__right_interactive", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.rightClick.emit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "div", [["class", "compact-border-button__icon"]], null, null, null, null, null)), i0.ɵdid(2, 278528, null, 0, i1.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpad(3, 1)], function (_ck, _v) { var _co = _v.component; var currVal_2 = "compact-border-button__icon"; var currVal_3 = _ck(_v, 3, 0, ("icon-" + _co.rightIcon)); _ck(_v, 2, 0, currVal_2, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.rightBackground; var currVal_1 = _co.rightInteractive; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
export function View_CompactBorderButtonComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "div", [["class", "compact-border-button"]], [[2, "compact-border-button_interactive", null]], null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CompactBorderButtonComponent_1)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(3, 0, null, null, 2, "div", [["class", "compact-border-button__main"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 1, "div", [["class", "compact-border-button__label"]], null, null, null, null, null)), (_l()(), i0.ɵted(5, null, ["", ""])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CompactBorderButtonComponent_2)), i0.ɵdid(7, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.leftIcon; _ck(_v, 2, 0, currVal_1); var currVal_3 = _co.rightIcon; _ck(_v, 7, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.interactive; _ck(_v, 0, 0, currVal_0); var currVal_2 = _co.label; _ck(_v, 5, 0, currVal_2); }); }
export function View_CompactBorderButtonComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-compact-border-button", [], null, null, null, View_CompactBorderButtonComponent_0, RenderType_CompactBorderButtonComponent)), i0.ɵdid(1, 114688, null, 0, i2.CompactBorderButtonComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CompactBorderButtonComponentNgFactory = i0.ɵccf("app-compact-border-button", i2.CompactBorderButtonComponent, View_CompactBorderButtonComponent_Host_0, { label: "label", interactive: "interactive", leftIcon: "leftIcon", leftBackground: "leftBackground", leftInteractive: "leftInteractive", rightIcon: "rightIcon", rightBackground: "rightBackground", rightInteractive: "rightInteractive" }, { leftClick: "leftClick", rightClick: "rightClick" }, []);
export { CompactBorderButtonComponentNgFactory as CompactBorderButtonComponentNgFactory };

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Observable, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';

import {
  AlertElementItem,
  AlertStyle,
  AlertTint,
  CustomizeService,
  ElementType,
  registerElementComponent
} from '@modules/customize';
import { BaseElementComponent } from '@modules/customize-elements';
import { applyParamInput } from '@modules/fields';
import { isSet, TypedChanges } from '@shared';

@Component({
  selector: 'app-alert-element',
  templateUrl: './alert-element.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AlertElementComponent extends BaseElementComponent implements OnInit, OnDestroy, OnChanges {
  @Input() element: AlertElementItem;

  customizeEnabled$: Observable<boolean>;
  contextSubscription: Subscription;
  title = '';
  message = '';
  tints = AlertTint;
  styles = AlertStyle;

  constructor(private customizeService: CustomizeService, private cd: ChangeDetectorRef) {
    super();
  }

  ngOnInit() {
    this.customizeEnabled$ = this.customizeService.enabled$.pipe(map(item => !!item));
    this.initContextObserver();
  }

  ngOnDestroy(): void {}

  ngOnChanges(changes: TypedChanges<AlertElementComponent>): void {
    if (changes.element) {
      this.initContextObserver();
    }
  }

  initContextObserver() {
    if (this.contextSubscription) {
      this.contextSubscription.unsubscribe();
    }

    this.updateInputs();

    this.contextSubscription = this.context.outputValues$
      .pipe(debounceTime(10), distinctUntilChanged(), untilDestroyed(this))
      .subscribe(() => this.updateInputs());
  }

  updateInputs() {
    try {
      const title = applyParamInput(this.element.title, {
        context: this.context,
        defaultValue: ''
      });

      this.title = isSet(title) ? String(title) : '';
    } catch (e) {
      this.title = '';
    }

    try {
      const message = applyParamInput(this.element.message, {
        context: this.context,
        defaultValue: ''
      });

      this.message = isSet(message) ? String(message) : '';
    } catch (e) {
      this.message = '';
    }

    this.cd.markForCheck();
  }
}

registerElementComponent({
  type: ElementType.Alert,
  component: AlertElementComponent,
  label: 'Alert',
  actions: []
});

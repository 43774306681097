import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import keys from 'lodash/keys';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, Observable, of, ReplaySubject } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';

import { UserActivityService, UserActivityType } from '@modules/activities';
import { ServerRequestError } from '@modules/api';
import { ChangeViewSettings } from '@modules/customize';
import { DataSourceType, ListModelDescriptionDataSource } from '@modules/data-sources';
import { createFormFieldFactory } from '@modules/fields';
import { applyParamsComputedLookups, applySegments } from '@modules/filter-utils';
import { MetaService } from '@modules/meta';
import { ModelDescriptionStore, ModelService } from '@modules/model-queries';
import { Model, ModelDbField, ModelDescription, PAGE_PARAM } from '@modules/models';
import { ModelListStore } from '@modules/models-list';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';

import { MassEditFieldsForm } from './mass-edit-fields.form';

@Component({
  selector: 'app-mass-edit',
  templateUrl: './mass-edit.component.html',
  providers: [ModelListStore, MassEditFieldsForm],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MassEditComponent implements OnInit, OnDestroy {
  loading = true;
  ids: number[];
  inverseIds: boolean;
  checkboxes: { name: string; checked: boolean; field: ModelDbField }[] = undefined;
  modelItems: Model[] = [];
  items: ModelDbField[] = [];
  count: number = undefined;
  saveStarted = false;
  countSaveProcessed = 0;
  countItemSave = 0;

  error: ServerRequestError;
  viewSettings: ChangeViewSettings;
  modelDescription: ModelDescription = undefined;
  createField = createFormFieldFactory();

  constructor(
    private injector: Injector,
    private activatedRoute: ActivatedRoute,
    private modelDescriptionStore: ModelDescriptionStore,
    private cd: ChangeDetectorRef,
    private modelListStore: ModelListStore,
    public currentProjectStore: CurrentProjectStore,
    protected currentEnvironmentStore: CurrentEnvironmentStore,
    private modelService: ModelService,
    private metaService: MetaService,
    public form: MassEditFieldsForm,
    private userActivityService: UserActivityService
  ) {}

  createModel(): Model {
    return Injector.create({
      providers: [{ provide: Model, deps: [Injector] }],
      parent: this.injector
    }).get<Model>(Model);
  }

  ngOnInit() {
    combineLatest([this.activatedRoute.params, this.activatedRoute.queryParams])
      .pipe(
        tap<[Params, Params]>(([params, queryParams]) => {
          this.loading = true;
          this.cd.markForCheck();
        }),
        switchMap<[Params, Params], [[Params, Params], ModelDescription]>(([params, queryParams]) => {
          return this.modelDescriptionStore.getDetailFirst(params['model']).pipe(
            map<ModelDescription, [[Params, Params], ModelDescription]>(modelDescription => [
              [params, queryParams],
              modelDescription
            ])
          );
        }),
        switchMap(([[params, queryParams], modelDescription]) => {
          this.modelDescription = modelDescription;
          try {
            this.ids = JSON.parse(queryParams['ids']);
          } catch (e) {
            this.ids = [];
          }
          this.inverseIds = queryParams['inverseIds'];

          const applyParams = {};

          if (this.inverseIds) {
            applyParams[`exclude__${this.modelDescription.primaryKeyField}__in`] = this.ids.join(',');
          } else {
            applyParams[`${this.modelDescription.primaryKeyField}__in`] = this.ids.join(',');
          }

          this.modelListStore.dataSource = new ListModelDescriptionDataSource({
            type: DataSourceType.Query,
            queryResource: this.modelDescription.resource,
            query: this.modelDescription.getQuery
          });
          this.modelListStore.params = { ...queryParams, ...applyParams };

          if (this.modelListStore.params[PAGE_PARAM] != undefined) {
            delete this.modelListStore.params[PAGE_PARAM];
          }

          if (this.modelListStore.params['ids'] != undefined) {
            delete this.modelListStore.params['ids'];
          }

          if (this.modelListStore.params['inverseIds'] != undefined) {
            delete this.modelListStore.params['inverseIds'];
          }

          const applyResult = applySegments(this.modelListStore.params, {}, this.modelDescription);

          applyResult.params = applyParamsComputedLookups(applyResult.params);

          this.cd.markForCheck();

          return this.modelService.get(
            this.currentProjectStore.instance,
            this.currentEnvironmentStore.instance,
            params['model'],
            applyResult.params,
            keys(applyResult.body).length ? applyResult.body : undefined
          );
        }),
        untilDestroyed(this)
      )
      .subscribe(
        result => {
          this.modelItems = result.results;
          this.count = result.count;
          this.checkboxes = this.initCheckboxes(this.modelDescription.dbFields);
          this.checkboxes
            .filter(checkbox => checkbox.checked)
            .forEach(checkbox => {
              this.items.push(checkbox.field);
              this.form.addControl(checkbox.field);
            });

          this.metaService.set({
            title: [`Mass edit ${this.count}`, this.modelDescription.verboseNamePlural]
          });

          this.loading = false;

          this.cd.markForCheck();
        },
        e => {
          this.loading = false;
          this.cd.markForCheck();
        }
      );
  }

  ngOnDestroy(): void {}

  initCheckboxes(fields: ModelDbField[]): { name: string; field: ModelDbField; checked: boolean }[] {
    return fields
      .filter(field => field.editable)
      .map(field => {
        return {
          name: field.verboseName,
          field: field,
          checked: false
        };
      });
  }

  get context() {
    return {
      modelDescription: this.modelDescription
    };
  }

  updateForm(checkbox: { name: string; checked: boolean; field: ModelDbField }) {
    this.form.toggleControl(checkbox.field);
    this.updateItems();
    this.cd.markForCheck();
  }

  updateItems() {
    this.items = this.checkboxes
      .filter(checkbox => checkbox.checked)
      .map(checkbox => {
        return checkbox.field;
      });
  }

  confirm() {
    if (this.form.form.invalid) {
      return;
    }

    this.saveStarted = true;
    this.cd.markForCheck();

    this.processPage()
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.userActivityService
          .currentProjectCreateModelInstance(
            this.currentProjectStore.instance,
            this.currentEnvironmentStore.instance,
            UserActivityType.ModelMassEdit,
            this.modelDescription.modelId,
            '',
            undefined,
            {
              resource: this.modelDescription.resource,
              model: this.modelDescription.model,
              ids: this.ids,
              inverseIds: this.inverseIds
            }
          )
          .subscribe(() => {});
      });
  }

  processPage(): Observable<any> {
    this.modelListStore.reset();

    return this.modelListStore.getNext().pipe(
      switchMap(results => {
        const obs = results
          .map(item => item.initialPrimaryKey)
          .map(pk =>
            switchMap(() => {
              const req = new ReplaySubject<boolean>();
              const model = this.createModel().deserialize(this.modelDescription.model, {});
              model.modelDescription = this.modelDescription;

              this.items.map(item => {
                model.setAttribute(item.name, this.form.form.value[item.name]);
              });

              model.setPrimaryKey(pk);

              this.modelService
                .update(
                  this.currentProjectStore.instance,
                  this.currentEnvironmentStore.instance,
                  this.modelDescription.modelId,
                  model,
                  this.items.map(item => item.name)
                )
                .subscribe(
                  () => {
                    this.countSaveProcessed += 1;
                    this.countItemSave += 1;
                    this.cd.markForCheck();
                    req.next(true);
                  },
                  () => {
                    this.countSaveProcessed += 1;
                    this.cd.markForCheck();
                    req.next(false);
                  }
                );

              return req;
            })
          );

        let result = of({});

        obs.forEach(item => (result = result.pipe(item)));

        return result;
      }),
      filter(() => this.modelListStore.hasMore),
      switchMap(() => this.processPage())
    );
  }

  get listParams() {
    const params = { ...this.activatedRoute.snapshot.queryParams };

    if (params[PAGE_PARAM] != undefined) {
      delete params[PAGE_PARAM];
    }

    if (params['ids'] != undefined) {
      delete params['ids'];
    }

    if (params['inverseIds'] != undefined) {
      delete params['inverseIds'];
    }

    return params;
  }
}

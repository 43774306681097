export interface Color {
  name: string;
  label: string;
  hex: number;
}

export const defaultAccentColor = '#2b50ed';

export const colors: Color[] = [
  // {
  //   name: 'aqua',
  //   label: 'Aqua',
  //   hex: 0x00ffff
  // },
  {
    name: 'black',
    label: 'Black',
    hex: 0x000000
  },
  {
    name: 'blue',
    label: 'Blue',
    hex: 0x5776f6
  },
  {
    name: 'bright-blue',
    label: 'Bright Blue',
    hex: 0x2b50ed
  },
  {
    name: 'fuchsia',
    label: 'Fuchsia',
    hex: 0xff00ff
  },
  {
    name: 'gray',
    label: 'Gray',
    hex: 0x808080
  },
  {
    name: 'green',
    label: 'Green',
    hex: 0x26c06c
  },
  {
    name: 'lime',
    label: 'Lime',
    hex: 0x00ff00
  },
  {
    name: 'maroon',
    label: 'Maroon',
    hex: 0x800000
  },
  {
    name: 'navy',
    label: 'Navy',
    hex: 0x000080
  },
  {
    name: 'olive',
    label: 'Olive',
    hex: 0x808000
  },
  {
    name: 'orange',
    label: 'Orange',
    hex: 0xecb567
  },
  {
    name: 'purple',
    label: 'Purple',
    hex: 0x800080
  },
  {
    name: 'red',
    label: 'Red',
    hex: 0xd12f2f
  },
  {
    name: 'silver',
    label: 'Silver',
    hex: 0xc0c0c0
  },
  {
    name: 'teal',
    label: 'Teal',
    hex: 0x008080
  },
  {
    name: 'white',
    label: 'White',
    hex: 0xffffff
  },
  {
    name: 'yellow',
    label: 'Yellow',
    hex: 0xc5d12f
  }
];

export const AUTO_OPTION_COLORS = [
  'blue',
  'green',
  'red',
  'yellow',
  'teal',
  'fuchsia',
  'maroon',
  'navy',
  'olive',
  'purple',
  'bright-blue',
  'black'
];

import { Component, Input, OnInit } from '@angular/core';

import { Environment, Project } from '@modules/projects';

@Component({
  selector: 'app-install-instructions-sql-general',
  preserveWhitespaces: true,
  templateUrl: './install-instructions-sql-general.component.html'
})
export class InstallInstructionsSqlGeneralComponent implements OnInit {
  @Input() project: Project;
  @Input() environment: Environment;
  @Input() token: string;

  constructor() {}

  ngOnInit() {}

  get environmentName(): string {
    if (!this.environment) {
      return;
    }

    if (this.project.environments.length <= 1) {
      return;
    }

    return this.environment.uniqueName;
  }
}

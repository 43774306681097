import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { from, fromEvent, timer } from 'rxjs';
import SignaturePad from 'signature_pad';

import { elementResize$, isSet, TypedChanges } from '@shared';

@Component({
  selector: 'app-signature',
  templateUrl: './signature.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SignatureComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {
  @Input() value: string;
  @Input() fillHeight = false;
  @Output() valueChange = new EventEmitter<string>();

  @ViewChild('canvas') canvasElement: ElementRef<HTMLCanvasElement>;

  signaturePad: SignaturePad;
  empty = true;

  constructor(private cd: ChangeDetectorRef) {}

  ngOnInit() {}

  ngOnDestroy(): void {}

  ngOnChanges(changes: TypedChanges<SignatureComponent>): void {
    if (changes.value && !changes.value.firstChange) {
      this.loadValue();
    }
  }

  ngAfterViewInit(): void {
    this.init();
  }

  init() {
    const canvas = this.canvasElement.nativeElement;

    this.signaturePad = new SignaturePad(canvas, {
      backgroundColor: '#fff',
      penColor: '#000'
    });

    fromEvent(this.signaturePad, 'endStroke')
      .pipe(untilDestroyed(this))
      .subscribe(() => this.onChange());

    elementResize$(canvas, false)
      .pipe(untilDestroyed(this))
      .subscribe(() => this.resize());

    // Fix to initial value wrong size
    timer(16)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.resize(false);
        this.loadValue();
      });
  }

  loadValue() {
    if (!this.signaturePad) {
      return;
    }

    if (isSet(this.value)) {
      from(this.signaturePad.fromDataURL(this.value))
        .pipe(untilDestroyed(this))
        .subscribe(() => {
          this.empty = false;
          this.cd.markForCheck();
        });
    } else {
      this.signaturePad.clear();

      this.empty = true;
      this.cd.markForCheck();
    }
  }

  getCanvasSize(): { width: number; height: number; ratio: number } {
    const canvas = this.canvasElement.nativeElement;
    const ratio = Math.max(window.devicePixelRatio || 1, 1);
    const width = canvas.offsetWidth * ratio;
    const height = canvas.offsetHeight * ratio;

    return { width: width, height: height, ratio: ratio };
  }

  resize(reload = true) {
    const size = this.getCanvasSize();
    const canvas = this.canvasElement.nativeElement;

    canvas.width = size.width;
    canvas.height = size.height;
    canvas.getContext('2d').scale(size.ratio, size.ratio);

    if (reload) {
      this.reloadCanvas();
    }
  }

  reloadCanvas() {
    this.signaturePad.fromData(this.signaturePad.toData());
  }

  reset() {
    if (!this.signaturePad) {
      return;
    }

    this.signaturePad.clear();
    this.onChange();
  }

  updateEmpty() {
    if (!this.signaturePad) {
      return;
    }

    this.empty = this.signaturePad.isEmpty();
    this.cd.markForCheck();
  }

  onChange() {
    this.updateEmpty();

    const value = this.signaturePad.toDataURL('image/png');
    this.valueChange.emit(value);
  }
}

<!--<div class="step-form__item-layout">-->
<!--  <div class="step-form__item-layout__side">-->
<!--    <div class="step-form__item-title">For SQL – General Deploy</div>-->
<!--    <div class="step-form__item-description">-->
<!--      Install Jet Bridge without any additional software or web services on any server or localhost.-->
<!--      You will need to install Python dependencies and run the application manually.-->
<!--    </div>-->
<div class="step-form__item-label">Requirements:</div>
<ul class="step-form__item-requirements">
  <li class="step-form__item-requirements-item"><strong>Python</strong> 2.7 or 3.4+
  <li class="step-form__item-requirements-item"><strong>pip</strong> - package manager for Python
  <li class="step-form__item-requirements-item">
    Any of the following <strong>SQL Databases</strong>:
    <ul class="step-form__item-requirements">
      <li class="step-form__item-requirements-item">PostgreSQL</li>
      <li class="step-form__item-requirements-item">MySQL</li>
      <li class="step-form__item-requirements-item">SQLite</li>
      <li class="step-form__item-requirements-item">Oracle</li>
      <li class="step-form__item-requirements-item">Microsoft SQL Server</li>
      <li class="step-form__item-requirements-item">Firebird</li>
      <li class="step-form__item-requirements-item">Sybase</li>
    </ul>
  </li>
  <li class="step-form__item-requirements-item"><strong>localhost</strong> or <strong>web server</strong> with an external IP</li>
</ul>
<!--  </div>-->
<!--  <div class="step-form__item-layout__main">-->
<div class="step-form__item-title">Installation</div>
<div class="step-form__item-instruction">
  <p>
    If you have any questions, please refer to our <br>
    <a [appDocumentation]="'install/jet-bridge-app'">Documentation</a>
  </p>
  <p>
    1. Install <strong>Python</strong> 2.7 or 3.4+ (comes with <strong>pip</strong>)<br>
    <a href="https://www.python.org/downloads/" target="_blank">https://www.python.org/downloads/</a>
  </p>
  <p>2. Install <strong>jet_bridge</strong> package using <strong>pip</strong> or update it from the command line as follows:</p>

<app-code [language]="'bash'">pip install jet_bridge -U
# or for Python 3
pip3 install jet_bridge -U</app-code>

  <p>3. Install an appropriate database adapter and libraries</p>

<app-code [language]="'bash'"># for PostgreSQL
pip install psycopg2

# for PostgreSQL + PosGIS
pip install GeoAlchemy2==0.6.2
pip install Shapely==1.6.4

# for MySQL
pip install mysqlclient

# for MSSQL
pip install pyodbc</app-code>

  <p>4. Run <strong>Jet Bridge</strong> from the command line as shown below:</p>

<app-code *ngIf="project && token" [language]="'bash'"># for Ubuntu/MacOS/Linux/etc (UNIX systems)
PROJECT={{project.uniqueName}} TOKEN={{token}}<ng-container *ngIf="environmentName"> ENVIRONMENT={{environmentName}}</ng-container> jet_bridge

# for Windows
set PROJECT={{project.uniqueName}} && set TOKEN={{token}}<ng-container *ngIf="environmentName"> && set ENVIRONMENT={{environmentName}}</ng-container> && jet_bridge</app-code>

  <p>
    This command create config if you run it for the first time.<br>
    If you already have config file that you should edit it and set options:
  </p>

  <p *ngIf="project"><strong>PROJECT</strong>={{project.uniqueName}}<br></p>
  <p *ngIf="token"><strong>TOKEN</strong>={{token}}<br></p>
  <p *ngIf="environmentName"><strong>ENVIRONMENT</strong>={{environmentName}}<br></p>

  <p>
    After filling all required options you can now run Jet Bridge by executing command once again:
  </p>

<app-code [language]="'bash'">jet_bridge</app-code>

  <p>
    5. Finish installation by opening in your browser (usually it opens automatically):<br>
    <strong>localhost</strong> is your <strong>Jet Bridge</strong> HOST and <strong>8888</strong> is its PORT.<br>
    If you want to run Jet Bridge on different host or port, read the instructions on the
    <a [appDocumentation]="'install/configuration'" target="_blank">Configuration page</a>.
  </p>

<app-code [language]="'bash'">http://localhost:8888/api/register/</app-code>

</div>
<!--  </div>-->
<!--</div>-->

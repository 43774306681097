import {
  CdkConnectedOverlay,
  CdkOverlayOrigin,
  ConnectedOverlayPositionChange,
  ConnectedPosition
} from '@angular/cdk/overlay';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Subscription } from 'rxjs';

import { ActionType } from '@modules/actions';

import { ActionTypeSection } from '../action-type-dropdown/action-type-options';

@Component({
  selector: 'app-action-type-dropdown-nested',
  templateUrl: './action-type-dropdown-nested.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActionTypeDropdownNestedComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() value: ActionType;
  @Input() section: ActionTypeSection;
  @Input() origin: CdkOverlayOrigin;
  @Output() select = new EventEmitter<ActionType>();
  @Output() hover = new EventEmitter<boolean>();

  dropdownPositions: ConnectedPosition[] = [
    {
      panelClass: ['overlay_position_left-top'],
      originX: 'start',
      overlayX: 'end',
      originY: 'top',
      overlayY: 'top',
      offsetY: -8
    },
    {
      panelClass: ['overlay_position_left-center'],
      originX: 'start',
      overlayX: 'end',
      originY: 'center',
      overlayY: 'center'
    },
    {
      panelClass: ['overlay_position_left-bottom'],
      originX: 'start',
      overlayX: 'end',
      originY: 'bottom',
      overlayY: 'bottom',
      offsetY: 8
    },
    {
      panelClass: ['overlay_position_right-top'],
      originX: 'end',
      overlayX: 'start',
      originY: 'top',
      overlayY: 'top',
      offsetY: -8
    },
    {
      panelClass: ['overlay_position_right-center'],
      originX: 'end',
      overlayX: 'start',
      originY: 'center',
      overlayY: 'center'
    },
    {
      panelClass: ['overlay_position_right-bottom'],
      originX: 'end',
      overlayX: 'start',
      originY: 'bottom',
      overlayY: 'bottom',
      offsetY: 8
    }
  ];
  dropdownPositionsSubscription: Subscription;

  @ViewChild(CdkConnectedOverlay) cdkConnectedOverlay: CdkConnectedOverlay;

  constructor() {}

  ngOnInit() {}

  ngOnDestroy(): void {}

  ngAfterViewInit(): void {
    this.setPositionObserver();
  }

  setPositionObserver() {
    if (this.dropdownPositionsSubscription) {
      this.dropdownPositionsSubscription.unsubscribe();
    }

    if (!this.cdkConnectedOverlay) {
      return;
    }

    this.dropdownPositionsSubscription = this.cdkConnectedOverlay.positionChange
      .pipe(untilDestroyed(this))
      .subscribe((e: ConnectedOverlayPositionChange) => {
        const propsEqual = ['offsetX', 'offsetY', 'originX', 'originY', 'overlayX', 'overlayY'];
        const position = this.dropdownPositions.find(item =>
          propsEqual.every(prop => (item[prop] || undefined) == e.connectionPair[prop])
        );
        const otherPosition = this.dropdownPositions.filter(item => item !== position);

        if (position) {
          this.cdkConnectedOverlay.overlayRef.addPanelClass(position.panelClass);
        }

        otherPosition.forEach(item => this.cdkConnectedOverlay.overlayRef.removePanelClass(item.panelClass));
      });
  }
}

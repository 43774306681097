/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../fields/components/auto-field/auto-field.component.ngfactory";
import * as i2 from "../../../fields/components/auto-field/auto-field.component";
import * as i3 from "@angular/common";
import * as i4 from "../../../../shared/pipes/safe-async/safe-async.pipe";
import * as i5 from "./project-resource-data-templates-table-item.component";
var styles_ProjectResourceDataTemplatesTableItemComponent = [];
var RenderType_ProjectResourceDataTemplatesTableItemComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ProjectResourceDataTemplatesTableItemComponent, data: {} });
export { RenderType_ProjectResourceDataTemplatesTableItemComponent as RenderType_ProjectResourceDataTemplatesTableItemComponent };
function View_ProjectResourceDataTemplatesTableItemComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-auto-field", [], [[4, "pointer-events", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = ($event.stopPropagation() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_AutoFieldComponent_0, i1.RenderType_AutoFieldComponent)), i0.ɵdid(1, 770048, null, 0, i2.AutoFieldComponent, [i0.ChangeDetectorRef], { field: [0, "field"], readonly: [1, "readonly"], value: [2, "value"], label: [3, "label"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.createField(_v.parent.parent.context.$implicit.column); var currVal_2 = true; var currVal_3 = _co.row.model.getAttribute(_v.parent.parent.context.$implicit.column.name); var currVal_4 = false; _ck(_v, 1, 0, currVal_1, currVal_2, currVal_3, currVal_4); }, function (_ck, _v) { var currVal_0 = "none"; _ck(_v, 0, 0, currVal_0); }); }
function View_ProjectResourceDataTemplatesTableItemComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(1, null, [" ", " "])), i0.ɵppd(2, 1)], null, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i0.ɵnov(_v.parent.parent.parent, 0), _v.parent.parent.context.$implicit.listItem.str)); _ck(_v, 1, 0, currVal_0); }); }
function View_ProjectResourceDataTemplatesTableItemComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [], [[2, "table__column-inner", null]], null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ProjectResourceDataTemplatesTableItemComponent_3)), i0.ɵdid(2, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ProjectResourceDataTemplatesTableItemComponent_4)), i0.ɵdid(4, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_1 = (_v.parent.context.$implicit.listItem.str == undefined); _ck(_v, 2, 0, currVal_1); var currVal_2 = (_v.parent.context.$implicit.listItem.str != undefined); _ck(_v, 4, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = !((_v.parent.context.$implicit.column.field == _co.fieldTypes.Text) && _v.parent.context.$implicit.column.params["wide"]); _ck(_v, 0, 0, currVal_0); }); }
function View_ProjectResourceDataTemplatesTableItemComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Error "]))], null, null); }
function View_ProjectResourceDataTemplatesTableItemComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "td", [["class", "table__column"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ProjectResourceDataTemplatesTableItemComponent_2)), i0.ɵdid(2, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ProjectResourceDataTemplatesTableItemComponent_5)), i0.ɵdid(4, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit.listItem; _ck(_v, 2, 0, currVal_0); var currVal_1 = !_v.context.$implicit.listItem; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_ProjectResourceDataTemplatesTableItemComponent_0(_l) { return i0.ɵvid(2, [i0.ɵpid(0, i4.SafeAsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ProjectResourceDataTemplatesTableItemComponent_1)), i0.ɵdid(2, 278528, null, 0, i3.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"], ngForTrackBy: [1, "ngForTrackBy"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.columnListItems; var currVal_1 = _co.trackByFn; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_ProjectResourceDataTemplatesTableItemComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-project-resource-data-templates-table-item", [], null, null, null, View_ProjectResourceDataTemplatesTableItemComponent_0, RenderType_ProjectResourceDataTemplatesTableItemComponent)), i0.ɵdid(1, 638976, null, 0, i5.ProjectResourceDataTemplatesTableItemComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ProjectResourceDataTemplatesTableItemComponentNgFactory = i0.ɵccf("app-project-resource-data-templates-table-item, [app-project-resource-data-templates-table-item]", i5.ProjectResourceDataTemplatesTableItemComponent, View_ProjectResourceDataTemplatesTableItemComponent_Host_0, { row: "row", columns: "columns" }, {}, []);
export { ProjectResourceDataTemplatesTableItemComponentNgFactory as ProjectResourceDataTemplatesTableItemComponentNgFactory };

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./project-empty.component";
var styles_ProjectEmptyComponent = [];
var RenderType_ProjectEmptyComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ProjectEmptyComponent, data: {} });
export { RenderType_ProjectEmptyComponent as RenderType_ProjectEmptyComponent };
export function View_ProjectEmptyComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "admin admin_stub"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "div", [["class", "admin__content"]], null, null, null, null, null)), i0.ɵncd(null, 0)], null, null); }
export function View_ProjectEmptyComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-project-empty", [], null, null, null, View_ProjectEmptyComponent_0, RenderType_ProjectEmptyComponent)), i0.ɵdid(1, 114688, null, 0, i1.ProjectEmptyComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ProjectEmptyComponentNgFactory = i0.ɵccf("app-project-empty", i1.ProjectEmptyComponent, View_ProjectEmptyComponent_Host_0, { animating: "animating" }, {}, ["*"]);
export { ProjectEmptyComponentNgFactory as ProjectEmptyComponentNgFactory };

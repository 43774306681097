import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-list-footer',
  templateUrl: './list-footer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ListFooterComponent implements OnInit {
  @Input() title: string;
  @Input() pagination = true;
  @Input() currentPage: number;
  @Input() hasMorePages = false;
  @Input() lastLoadedPage: number;
  @Input() totalPages: number;
  @Input() count: number;
  @Input() reloadEnabled = true;
  @Input() viewId: string;
  @Output() pageSelected = new EventEmitter<number>();
  @Output() reload = new EventEmitter<any>();

  constructor() {}

  ngOnInit() {}
}

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../shared/components/loader-small/loader-small.component.ngfactory";
import * as i2 from "../../../../shared/components/loader-small/loader-small.component";
import * as i3 from "../../../../shared/pipes/safe-style/safe-style.pipe";
import * as i4 from "@angular/platform-browser";
import * as i5 from "../../../../shared/pipes/format-date/format-date.pipe";
import * as i6 from "@angular/common";
import * as i7 from "./import-figma-node-item.component";
import * as i8 from "../../../projects/stores/current-project.store";
import * as i9 from "../../../projects/stores/current-environment.store";
import * as i10 from "../../../figma-integration/services/figma/figma.service";
import * as i11 from "../../../../common/notifications/services/notification/notification.service";
var styles_ImportFigmaNodeItemComponent = [];
var RenderType_ImportFigmaNodeItemComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ImportFigmaNodeItemComponent, data: {} });
export { RenderType_ImportFigmaNodeItemComponent as RenderType_ImportFigmaNodeItemComponent };
function View_ImportFigmaNodeItemComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-loader-small", [["class", "button__icon button__icon_left"]], null, null, null, i1.View_LoaderSmallComponent_0, i1.RenderType_LoaderSmallComponent)), i0.ɵdid(1, 114688, null, 0, i2.LoaderSmallComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
function View_ImportFigmaNodeItemComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "span", [["class", "button__icon button__icon_left icon-download"]], null, null, null, null, null))], null, null); }
export function View_ImportFigmaNodeItemComponent_0(_l) { return i0.ɵvid(2, [i0.ɵpid(0, i3.SafeStylePipe, [i4.DomSanitizer]), i0.ɵpid(0, i5.FormatDatePipe, []), (_l()(), i0.ɵeld(2, 0, null, null, 3, "td", [["class", "card-table__column"], ["style", "width: 110px;"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 2, "div", [["class", "card-table__column-inner"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 1, "div", [["class", "field__preview field__preview_border"]], [[2, "field__preview_image", null], [2, "field__preview_contain", null], [4, "width", "px"], [4, "height", "px"], [4, "background-image", null]], null, null, null, null)), i0.ɵppd(5, 1), (_l()(), i0.ɵeld(6, 0, null, null, 2, "td", [["class", "card-table__column"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 1, "div", [["class", "card-table__column-inner"], ["style", "padding-left: 0;"]], null, null, null, null, null)), (_l()(), i0.ɵted(8, null, [" ", " "])), (_l()(), i0.ɵeld(9, 0, null, null, 4, "td", [["class", "card-table__column"]], null, null, null, null, null)), (_l()(), i0.ɵeld(10, 0, null, null, 3, "div", [["class", "card-table__column-inner"]], null, null, null, null, null)), (_l()(), i0.ɵeld(11, 0, null, null, 2, "span", [["class", "card-table__grey"]], null, null, null, null, null)), (_l()(), i0.ɵted(12, null, [" ", " "])), i0.ɵppd(13, 2), (_l()(), i0.ɵeld(14, 0, null, null, 8, "td", [["class", "card-table__column"]], null, null, null, null, null)), (_l()(), i0.ɵeld(15, 0, null, null, 7, "div", [["class", "card-table__column-inner card-table__column-inner_right"]], null, null, null, null, null)), (_l()(), i0.ɵeld(16, 0, null, null, 6, "button", [["class", "button button_extra-small button_orange-transparent"], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.applyImport(_co.item.id) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ImportFigmaNodeItemComponent_1)), i0.ɵdid(18, 16384, null, 0, i6.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ImportFigmaNodeItemComponent_2)), i0.ɵdid(20, 16384, null, 0, i6.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(21, 0, null, null, 1, "span", [["class", "button__label"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Import"]))], function (_ck, _v) { var _co = _v.component; var currVal_8 = _co.importLoading; _ck(_v, 18, 0, currVal_8); var currVal_9 = !_co.importLoading; _ck(_v, 20, 0, currVal_9); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.item.viewPreview; var currVal_1 = _co.item.viewPreview; var currVal_2 = 80; var currVal_3 = 50; var currVal_4 = (_co.item.viewPreview ? i0.ɵunv(_v, 4, 4, _ck(_v, 5, 0, i0.ɵnov(_v, 0), (("url('" + _co.item.viewPreview) + "')"))) : null); _ck(_v, 4, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); var currVal_5 = _co.item.name; _ck(_v, 8, 0, currVal_5); var currVal_6 = i0.ɵunv(_v, 12, 0, _ck(_v, 13, 0, i0.ɵnov(_v, 1), _co.item.createdAt, "lll")); _ck(_v, 12, 0, currVal_6); var currVal_7 = _co.importLoading; _ck(_v, 16, 0, currVal_7); }); }
export function View_ImportFigmaNodeItemComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-import-figma-node-item", [], null, null, null, View_ImportFigmaNodeItemComponent_0, RenderType_ImportFigmaNodeItemComponent)), i0.ɵdid(1, 245760, null, 0, i7.ImportFigmaNodeItemComponent, [i8.CurrentProjectStore, i9.CurrentEnvironmentStore, i10.FigmaService, i11.NotificationService, i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ImportFigmaNodeItemComponentNgFactory = i0.ɵccf("app-import-figma-node-item, [app-import-figma-node-item]", i7.ImportFigmaNodeItemComponent, View_ImportFigmaNodeItemComponent_Host_0, { item: "item" }, { importView: "importView" }, []);
export { ImportFigmaNodeItemComponentNgFactory as ImportFigmaNodeItemComponentNgFactory };

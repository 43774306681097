import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { AppLinkActiveDirective } from './directives/app-link-active/app-link-active.directive';
import { AppLinkDirective } from './directives/app-link/app-link.directive';
import { IsLinkActiveDirective } from './directives/is-link-active/is-link-active.directive';

@NgModule({
  imports: [CommonModule, RouterModule],
  declarations: [AppLinkDirective, AppLinkActiveDirective, IsLinkActiveDirective],
  exports: [AppLinkDirective, AppLinkActiveDirective, RouterModule, IsLinkActiveDirective]
})
export class RoutingModule {}

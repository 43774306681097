<app-inputs-edit
  [control]="form.controls.inputs"
  [parameterProvider]="form.inputFieldProvider"
  [context]="context"
  [contextElement]="contextElement"
  [contextElementPath]="contextElementPath"
  [contextElementPaths]="contextElementPaths"
  [userInput]="true"
  [collapse]="false"
  [listWrapper]="false"
  [object]="analyticsSource"
>
</app-inputs-edit>

<div class="sidebar__element">
  <app-sidebar-field [label]="'Open a new tab'">
    <app-auto-field
      [form]="form"
      [label]="false"
      [field]="
        createField({
          name: 'new_tab',
          field: 'SelectField',
          params: {
            options: form.newTabOptions,
            classes: ['select_fill', form.value['new_tab'] === 'custom' ? 'select_segment-top' : '']
          }
        })
      "
    ></app-auto-field>

    <app-input-edit
      *ngIf="form.value['new_tab'] === 'custom'"
      [itemForm]="form.controls.new_tab_custom"
      [context]="context"
      [contextElement]="contextElement"
      [contextElementPath]="contextElementPath"
      [contextElementPaths]="contextElementPaths"
      [staticValueDisabled]="true"
      [classes]="['input_segment-bottom']"
      [fill]="true"
      [analyticsSource]="analyticsSource + '_external_link_new_tab'"
    ></app-input-edit>
    <ng-container *ngIf="form.value['new_tab'] === 'custom'" description>
      Example: EQ(elements.file_name, "jpg")
    </ng-container>
  </app-sidebar-field>
</div>

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  ViewChild
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, of } from 'rxjs';
import { delayWhen, map, switchMap, tap } from 'rxjs/operators';

import { ThinDialogPopupComponent } from '@common/dialog-popup';
import { BasePopupComponent, PopupService } from '@common/popups';
import { ActionStore } from '@modules/action-queries';
import { ModelDescriptionService, ModelDescriptionStore } from '@modules/model-queries';
import { ModelDescription } from '@modules/models';
import { CurrentEnvironmentStore, CurrentProjectStore, Resource, ResourceName } from '@modules/projects';
import { ResourceControllerService } from '@modules/resources';
import { RoutingService } from '@modules/routing';
import { Template, TemplateService } from '@modules/template';
import { PageTemplatesController } from '@modules/template-queries';
import { isSet } from '@shared';

import {
  registerResourceAddModelComponent,
  ResourceAddModelAddedEvent,
  ResourceAddModelComponent
} from '../../data/resource-add-model-components';
import { ResourceModelEditController } from '../../services/resource-model-edit-controller/resource-model-edit.controller';
import { JetDatabaseImportFileComponent } from '../resource-add-model/jet-database-import-file/jet-database-import-file.component';

@Component({
  selector: 'app-project-resource-data-templates',
  templateUrl: './project-resource-data-templates.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProjectResourceDataTemplatesComponent implements OnInit, OnDestroy, ResourceAddModelComponent {
  @Input() resource: Resource;
  @Input() analyticsSource: string;
  @Input() closeEnabled = true;
  @Input() importEnabled = true;
  @Input() showPageTemplates = false;
  @Output() cancelled = new EventEmitter<void>();
  @Output() added = new EventEmitter<ResourceAddModelAddedEvent>();

  @ViewChild('viewport') viewportElement: ElementRef;

  loading = false;
  submitLoading = false;
  selectedTemplate: Template;
  name = '';
  nameEditing = false;
  defaultModelDescriptionTemplate: Template;
  modelDescriptionTemplates: Template[] = [];

  constructor(
    @Optional() private popupComponent: BasePopupComponent,
    private templateService: TemplateService,
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private modelDescriptionService: ModelDescriptionService,
    private modelDescriptionStore: ModelDescriptionStore,
    private actionStore: ActionStore,
    private resourceControllerService: ResourceControllerService,
    private resourceModelEditController: ResourceModelEditController,
    private pageTemplatesController: PageTemplatesController,
    private popupService: PopupService,
    private injector: Injector,
    private routing: RoutingService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.loading = true;
    this.cd.markForCheck();

    combineLatest(
      this.templateService.getResourceDefaultModelDescription(this.resource.typeItem.name),
      this.templateService.getResourceModelDescriptionsTemplates(this.resource.typeItem.name)
    )
      .pipe(untilDestroyed(this))
      .subscribe(
        ([defaultModelDescriptionTemplate, modelDescriptionTemplates]) => {
          this.defaultModelDescriptionTemplate = defaultModelDescriptionTemplate;
          this.modelDescriptionTemplates = modelDescriptionTemplates;
          this.loading = false;
          this.cd.markForCheck();
        },
        () => {
          this.loading = false;
          this.cd.markForCheck();
        }
      );

    this.setDefaultName();
  }

  ngOnDestroy(): void {}

  setSelectedTemplate(template: Template) {
    this.selectedTemplate = template;
    this.cd.markForCheck();
    this.viewportElement.nativeElement.scrollTo(0, 0);
    this.setDefaultName();
  }

  setNameEditing(value: boolean) {
    this.nameEditing = value;
    this.cd.markForCheck();
  }

  cleanName() {
    this.modelDescriptionStore
      .getFirst()
      .pipe(
        map(modelDescriptions => {
          const names = modelDescriptions
            .filter(item => item.resource == this.resource.uniqueName)
            .reduce((acc, item) => {
              acc.push(item.verboseName, item.verboseNamePlural);
              return acc;
            }, []);
          return this.getDistinctModelDescriptionName(names, this.name);
        }),
        untilDestroyed(this)
      )
      .subscribe(value => {
        this.name = value;
        this.cd.markForCheck();
      });
  }

  setDefaultName() {
    this.name = this.selectedTemplate ? this.selectedTemplate.name : 'New Table';
    this.cd.markForCheck();

    this.cleanName();
  }

  finishNameEditing() {
    this.setNameEditing(false);

    this.name = isSet(this.name) ? this.name.trim() : '';
    this.cd.markForCheck();

    if (isSet(this.name)) {
      this.cleanName();
    } else {
      this.setDefaultName();
    }
  }

  getDistinctModelDescriptionName(names: string[], baseName: string): string {
    let name: string;
    let index = 1;
    const match = baseName.match(/\s\d+$/);

    if (match) {
      baseName = baseName.slice(0, match.index);
      index = parseInt(match[0], 10);
    }

    do {
      name = index > 1 ? [baseName, index].join(' ') : baseName;
      ++index;
    } while (names.find(item => item.toLowerCase() == name.toLowerCase()));

    return name;
  }

  importModels() {
    this.popupService.push({
      component: JetDatabaseImportFileComponent,
      popupComponent: ThinDialogPopupComponent,
      inputs: {
        resource: this.resource,
        analyticsSource: this.analyticsSource
      },
      outputs: {
        added: [
          event => {
            if (!this.closeEnabled) {
              this.routing.navigateApp(event.modelDescription.systemSettingsLink, { queryParams: { reload: '1' } });
            }

            this.close();

            this.onModelDescriptionCreated(event.modelDescription);
          }
        ]
      },
      injector: this.injector
    });
  }

  createModelDescription(templateModelDescription: ModelDescription, templateModels: Object[]) {
    this.submitLoading = true;
    this.cd.markForCheck();

    const controller = this.resourceControllerService.get(this.resource.type);
    const instance = new ModelDescription();

    instance.resource = this.resource.uniqueName;
    instance.model = ModelDescription.generateModel();
    instance.dbTable = instance.model;
    instance.verboseName = this.name;
    instance.verboseNamePlural = this.name;
    instance.queryType = templateModelDescription.queryType;
    instance.fields = templateModelDescription.fields;
    instance.primaryKeyField = templateModelDescription.primaryKeyField || 'id';

    return controller
      .modelDescriptionCreate(this.resource, instance)
      .pipe(
        switchMap(() => this.modelDescriptionService.getFromResource(this.resource)),
        map(resourceModelDescriptions => resourceModelDescriptions.find(item => item.model == instance.model)),
        tap(result => this.modelDescriptionStore.addItem(result)),
        delayWhen(result => this.actionStore.syncAutoActions(result)),
        delayWhen(modelDescription => {
          if (!templateModels.length) {
            return of([]);
          }

          const models = templateModels.map(data => {
            const model = controller.createModel().deserialize(modelDescription.modelId, data);
            model.setUp(modelDescription);
            model.deserializeAttributes(modelDescription.dbFields);
            return model;
          });

          return controller.modelCreateBulk(this.resource, modelDescription, models);
        }),
        untilDestroyed(this)
      )
      .subscribe(
        modelDescription => {
          this.submitLoading = false;
          this.cd.markForCheck();

          this.close();

          this.onModelDescriptionCreated(modelDescription);
        },
        () => {
          this.submitLoading = false;
          this.cd.markForCheck();
        }
      );
  }

  isCreatingModelDescription(): boolean {
    return this.submitLoading;
  }

  onModelDescriptionCreated(modelDescription: ModelDescription) {
    this.added.emit({
      modelDescription: modelDescription
    });

    if (this.showPageTemplates) {
      this.pageTemplatesController
        .open({
          cancelEnabled: true,
          resource: this.resource,
          newPage: true,
          analyticsSource: this.analyticsSource
        })
        // .pipe(untilDestroyed(this))
        .subscribe(result => {
          if (result.startPage) {
            this.routing.navigateApp(result.startPage.link);
          } else if (result.newPage) {
            this.routing.navigateApp(this.currentProjectStore.instance.newPageLink);
          }
        });
    }
  }

  cancel() {
    this.close();
    this.cancelled.emit();
  }

  close() {
    if (this.popupComponent) {
      this.popupComponent.close();
    }
  }
}

registerResourceAddModelComponent(ResourceName.JetDatabase, ProjectResourceDataTemplatesComponent, 'Add or Import', {
  icon: 'new_document',
  multistep: true
});

import { isSet } from '@shared';

import { Border } from '../border';
import { CornerRadius } from '../corner-radius';
import { Fill } from '../fill';
import { FlexLayout } from '../flex-layout';
import { ContainerLayer } from '../layer';
import { LayerType } from '../layer-type';
import { registerLayerType } from '../layer-types';
import { Shadow } from '../shadow';

export class FrameLayer extends ContainerLayer {
  public type = LayerType.Frame;
  public clipContent = false;
  public fills: Fill[] = [];
  public borders: Border[] = [];
  public shadows: Shadow[] = [];
  public cornerRadius: CornerRadius = new CornerRadius();

  constructor(options: Partial<FrameLayer> = {}) {
    super(options);
  }

  deserialize(data: Object): this {
    super.deserialize(data);

    if (isSet(data['width_fluid'])) {
      this.widthFluid = data['width_fluid'];
    }

    if (isSet(data['height_fluid'])) {
      this.heightFluid = data['height_fluid'];
    }

    if (isSet(data['clip_content'])) {
      this.clipContent = data['clip_content'];
    }

    if (data['flex_layout']) {
      this.flexLayout = new FlexLayout().deserialize(data['flex_layout']);
    }

    if (data['fills']) {
      this.fills = data['fills'].map(item => new Fill().deserialize(item));
    }

    if (data['borders']) {
      this.borders = data['borders'].map(item => new Border().deserialize(item));
    }

    if (data['shadows']) {
      this.shadows = data['shadows'].map(item => new Shadow().deserialize(item));
    }

    if (data['corner_radius']) {
      this.cornerRadius = new CornerRadius().deserialize(data['corner_radius']);
    }

    return this;
  }

  serialize(): Object {
    return {
      ...super.serialize(),
      width_fluid: this.widthFluid,
      height_fluid: this.heightFluid,
      clip_content: this.clipContent,
      flex_layout: this.flexLayout ? this.flexLayout.serialize() : undefined,
      fills: this.fills.map(item => item.serialize()),
      borders: this.borders.map(item => item.serialize()),
      shadows: this.shadows.map(item => item.serialize()),
      corner_radius: this.cornerRadius ? this.cornerRadius.serialize() : undefined
    };
  }

  public get icon(): string {
    return 'corners';
  }

  public get defaultName(): string {
    return 'frame';
  }
}

registerLayerType(LayerType.Frame, FrameLayer, { container: true });

import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import cloneDeep from 'lodash/cloneDeep';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { concat, Subscription } from 'rxjs';
import { filter, toArray } from 'rxjs/operators';

import { DialogService } from '@common/dialogs';
import { NotificationService } from '@common/notifications';
import { ServerRequestError } from '@modules/api';
import { MenuSection, MenuService } from '@modules/menu';
import { CurrentProjectStore } from '@modules/projects';
import { Template, TemplateService, TemplateType } from '@modules/template';

@Component({
  selector: 'app-templates',
  templateUrl: './templates.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TemplatesComponent implements OnInit, OnDestroy {
  loading = true;
  error: string;
  tabs: { type: TemplateType; title: string }[] = [
    {
      type: TemplateType.Page,
      title: 'Page templates'
    },
    {
      type: TemplateType.Widget,
      title: 'Component templates'
    },
    {
      type: TemplateType.ResourceModelDescriptionsTemplate,
      title: 'Data templates'
    }
  ];
  currentTabType: TemplateType;
  templates: Template[];
  templatesSubscription: Subscription;

  constructor(
    public currentProjectStore: CurrentProjectStore,
    private templateService: TemplateService,
    private menuService: MenuService,
    private notificationService: NotificationService,
    private dialogService: DialogService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.menuService.section = MenuSection.None;
    this.setCurrentTabType(this.tabs[0].type);
  }

  ngOnDestroy(): void {
    this.menuService.section = MenuSection.Default;
  }

  setCurrentTabType(type: TemplateType) {
    this.currentTabType = type;
    this.cd.markForCheck();

    this.fetch(type);
  }

  fetch(type: TemplateType) {
    if (this.templatesSubscription) {
      this.templatesSubscription.unsubscribe();
      this.templatesSubscription = undefined;
    }

    this.templates = undefined;
    this.loading = true;
    this.error = undefined;
    this.cd.markForCheck();

    this.templatesSubscription = this.templateService
      .get({ all: '1' })
      .pipe(untilDestroyed(this))
      .subscribe(
        result => {
          this.templates = result.filter(item => item.type == type);
          this.loading = false;
          this.error = undefined;
          this.cd.markForCheck();
        },
        error => {
          if (error instanceof ServerRequestError && error.errors.length) {
            this.error = error.errors[0];
          } else {
            this.error = error;
          }

          this.templates = undefined;
          this.loading = false;
          this.cd.markForCheck();
        }
      );
  }

  reload() {
    this.fetch(this.currentTabType);
  }

  delete(template: Template) {
    this.dialogService
      .warning({
        title: 'Delete template',
        description: `Are you sure want to delete template <strong>${template.name}?</strong>`
      })
      .pipe(
        filter(result => result == true),
        untilDestroyed(this)
      )
      .subscribe(() => {
        this.templateService
          .delete(template)
          .pipe(untilDestroyed(this))
          .subscribe(() => {
            this.notificationService.success('Delete', `Template was successfully deleted`);
            this.reload();
          });
      });
  }

  dragDrop(event: CdkDragDrop<FormControl[]>) {
    moveItemInArray(this.templates, event.previousIndex, event.currentIndex);

    const templates$ = this.templates.reduce((acc, item, i) => {
      const ordering = i + 1;

      if (item.ordering != ordering) {
        const instance = cloneDeep(item) as Template;
        instance.ordering = ordering;
        acc.push(this.templateService.update(instance, ['ordering']));
      }

      return acc;
    }, []);

    if (!templates$.length) {
      return;
    }

    concat(...templates$)
      .pipe(toArray(), untilDestroyed(this))
      .subscribe(() => {
        this.templates = this.templates.map((item, i) => {
          item.ordering = i + 1;
          return item;
        });
      });
  }
}

var _a, _b;
export var TimeFormat;
(function (TimeFormat) {
    TimeFormat["HourMinute24"] = "hour_minute_24";
    TimeFormat["HourMinute12"] = "hour_minute_12";
    TimeFormat["HourMinuteSecond24"] = "hour_minute_second_24";
    TimeFormat["HourMinuteSecond12"] = "hour_minute_second_12";
    TimeFormat["Relative"] = "relative";
    TimeFormat["Custom"] = "custom";
})(TimeFormat || (TimeFormat = {}));
export var defaultTimeFormat = TimeFormat.HourMinute24;
export var momentTimeFormats = (_a = {},
    _a[TimeFormat.HourMinute24] = 'HH:mm',
    _a[TimeFormat.HourMinute12] = 'hh:mm A',
    _a[TimeFormat.HourMinuteSecond24] = 'HH:mm:ss',
    _a[TimeFormat.HourMinuteSecond12] = 'hh:mm:ss A',
    _a);
export function isClock12TimeFormat(format) {
    return [TimeFormat.HourMinute12, TimeFormat.HourMinuteSecond12].includes(format);
}
export var momentTimeEditFormats = (_b = {},
    _b[TimeFormat.HourMinute24] = 'HH:mm',
    _b[TimeFormat.HourMinute12] = 'hh:mm A',
    _b[TimeFormat.HourMinuteSecond24] = 'HH:mm:ss',
    _b[TimeFormat.HourMinuteSecond12] = 'hh:mm:ss A',
    _b[TimeFormat.Relative] = 'HH:mm:ss',
    _b);
export function isClock12TimeEditFormat(format) {
    return [TimeFormat.HourMinute12, TimeFormat.HourMinuteSecond12].includes(format);
}

import {
  HttpClient,
  HttpErrorResponse,
  HttpEvent,
  HttpEventType,
  HttpHeaders,
  HttpResponse,
  HttpResponseBase
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { XMLParser } from 'fast-xml-parser';
import isArray from 'lodash/isArray';
import { combineLatest, Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators';

import { AdminMode } from '@modules/admin-mode';
import { ApiService, DEMO_RESOURCES_PROJECT, ServerRequestError } from '@modules/api';
import { coerceArray, EMPTY, getFilenameWithExtension, isSet, objectGet } from '@shared';

import { HttpContentType } from '../../data/http-content-type';
import { HttpParameterType } from '../../data/http-parameter';
import { HttpQuery, HttpResponseType } from '../../data/http-query';
import { QueryService } from '../query/query.service';

export interface HttpQueryParameters {
  method: string;
  url: string;
  queryParams: { name: string; value: string }[];
  headers: { name: string; value: string }[];
  bodyType: HttpContentType;
  body: any;
  secretTokens: string[];
}

export interface HttpQueryOptions {
  resource?: string;
  baseQuery?: HttpQuery;
  tokens?: Object;
  raiseErrors?: boolean;
  customProxy?: string;
}

@Injectable({
  providedIn: 'root'
})
export class HttpQueryService {
  constructor(private http: HttpClient, private queryService: QueryService, private apiService: ApiService) {}

  cleanUrl(value: string): string {
    const url = new URL(value);
    return `${url.origin}${url.pathname}${url.search}`;
  }

  prepareParameters(query: HttpQuery, options: HttpQueryOptions = {}): HttpQueryParameters {
    if (options.baseQuery) {
      query.merge(options.baseQuery);
    }

    let url = query.getEffectiveUrl(options.baseQuery);

    url = this.queryService.applyTokens(url, options.tokens, options.raiseErrors);
    url = this.cleanUrl(url);

    const headers = (query.headers || [])
      .filter(item => item.value !== '' && item.value !== null)
      .map(item => {
        return {
          name: item.name,
          value: this.queryService.applyTokens(item.value.toString(), options.tokens, options.raiseErrors)
        };
      })
      .filter(item => item.value !== '');
    const params = query.queryParams
      .filter(item => item.value !== '' && item.value !== null)
      .map(item => {
        return {
          name: item.name,
          value: this.queryService.applyTokens(item.value.toString(), options.tokens, options.raiseErrors)
        };
      })
      .filter(item => item.value !== '');
    let bodyType = query.bodyType;

    let body = query.body;

    if (query.bodyTransformer) {
      body = this.queryService.applyTransformer(body, query.bodyTransformer, url, options.raiseErrors, options.tokens);
    }

    if (query.bodyType == HttpContentType.JSON) {
      if (isSet(body)) {
        if (typeof body == 'string') {
          body = JSON.stringify(
            this.queryService.applyTransformer(body, 'return ' + body, url, options.raiseErrors, options.tokens)
          );
        } else {
          body = JSON.stringify(body);
        }
      }
    } else if (query.bodyType == HttpContentType.GraphQL) {
      bodyType = HttpContentType.JSON;
      body = JSON.stringify({ query: body });
      body = this.queryService.applyTokens(body, options.tokens, options.raiseErrors);
    } else if (query.bodyType == HttpContentType.Raw) {
      body = this.queryService.applyTokens(body, options.tokens, options.raiseErrors);
    } else if (query.bodyType == HttpContentType.FormUrlEncoded) {
      body = body
        .filter(item => item.value !== '' && item.value !== null)
        .map(item => {
          return [item.name, this.queryService.applyTokens(item.value.toString(), options.tokens, options.raiseErrors)];
        })
        .filter(([k, v]) => v !== '')
        .map(([k, v]) => {
          return [k, encodeURIComponent(v)].join('=');
        })
        .join('&');
    } else if (query.bodyType == HttpContentType.FormData) {
      body = body
        .filter(item => item.value !== '' && item.value !== null)
        .map(item => {
          const singleToken = String(item.value).match(/^{{([^}]+)}}$/);

          if (singleToken) {
            const tokenValue = objectGet(options.tokens, singleToken[1]);
            if (tokenValue instanceof File) {
              return [item.name, tokenValue];
            } else if (tokenValue !== EMPTY && isSet(tokenValue)) {
              return [item.name, tokenValue, item.contentType, item.type];
            }
          }

          const strValue = this.queryService.applyTokens(String(item.value), options.tokens, options.raiseErrors);
          return [item.name, strValue, item.contentType, item.type];
        })
        .filter(([k, v]) => v !== '');
    }

    const secretTokens = [];
    const findSecretTokens = (str: string) => {
      if (typeof str != 'string') {
        return;
      }

      const regex = new RegExp(/{-([^}]+)-}/g);
      let m;

      while ((m = regex.exec(str))) {
        secretTokens.push(m[1]);
      }
    };

    findSecretTokens(url);
    params.forEach(item => findSecretTokens(item.value));
    headers.forEach(item => findSecretTokens(item.value));
    findSecretTokens(body);

    return {
      method: query.method,
      url: url,
      queryParams: params,
      headers: headers,
      bodyType: bodyType,
      body: body,
      secretTokens: secretTokens
    };
  }

  parseXML<T = Object>(xml: string): T {
    return new XMLParser().parse(xml, true);
  }

  executeRequest<T>(query: HttpQuery, options: HttpQueryOptions = {}): Observable<HttpEvent<T>> {
    const parameters = this.prepareParameters(query, options);

    const blobs$: Observable<Blob>[] = [];

    if (query.bodyType == HttpContentType.FormData) {
      blobs$.push(
        ...parameters.body
          .filter(([k, v, c, type]) => type == HttpParameterType.File)
          .map(parameter => {
            const [k, v, c, type] = parameter;
            const urls = isSet(v) ? coerceArray(v) : [];

            if (
              !urls.length ||
              !urls.every(url => {
                return (
                  typeof url == 'string' &&
                  ['http://', 'https://', 'file://'].some(prefix => url.toLowerCase().startsWith(prefix))
                );
              })
            ) {
              return;
            }

            return combineLatest<{ url: string; blob: Blob }[]>(
              ...urls.map(url => {
                const blobQuery = new HttpQuery();

                blobQuery.url = url;
                blobQuery.responseType = HttpResponseType.Blob;

                return this.requestBody<Blob>(blobQuery).pipe(
                  map(blob => {
                    return {
                      url: url,
                      blob: blob
                    };
                  }),
                  catchError(() => undefined)
                );
              })
            ).pipe(
              tap(blobs => {
                parameter[1] = blobs
                  .filter(item => item)
                  .map(item => {
                    const filename = getFilenameWithExtension(item.url);
                    return new File([item.blob], filename);
                  });
              })
            );
          })
          .filter(item => item)
      );
    }

    return (blobs$.length ? combineLatest(...blobs$) : of([])).pipe(
      switchMap(() => this.apiService.refreshToken()),
      switchMap(() => {
        const url = options.customProxy || this.apiService.methodURL('proxy_request/');
        let headers = new HttpHeaders();
        const data = new FormData();
        const withCredentials = !!options.customProxy;

        headers = this.apiService.setHeadersToken(headers);

        // TODO: Refactor project get
        if (options.resource && options.resource.startsWith('demo_')) {
          data.append('project', DEMO_RESOURCES_PROJECT);
        } else {
          data.append('project', window['project']);
        }

        // TODO: Refactor project get
        if (options.resource && options.resource.startsWith('demo_')) {
          data.append('environment', 'prod');
        } else {
          data.append('environment', window['project_environment']);
        }

        if (options.resource) {
          data.append('resource', options.resource);
        }

        if (window['mode'] == AdminMode.Builder) {
          data.append('draft', '1');
        }

        data.append('method', parameters.method);
        data.append('url', parameters.url);
        data.append('query_params', JSON.stringify(parameters.queryParams));
        data.append('headers', JSON.stringify(parameters.headers));
        data.append('body_type', parameters.bodyType);

        if (query.bodyType == HttpContentType.FormData) {
          parameters.body.forEach(([k, v, c]) => {
            if (v instanceof Blob) {
              data.append(`body[${k}]`, v);
            } else if (isArray(v) && v.every(item => item instanceof Blob)) {
              v.forEach(item => {
                data.append(`body[${k}]`, item);
              });
            } else {
              data.append(`body[${k}]`, v);
            }

            if (c) {
              data.append(`body_ct[${k}]`, c);
            }
          });
        } else {
          data.append('body', parameters.body);
        }

        if (parameters.secretTokens.length) {
          data.append('secret_tokens', parameters.secretTokens.join(','));
        }

        let responseType: any = 'text';

        if (query.responseType == HttpResponseType.JSON) {
          responseType = 'json';
        } else if (query.responseType == HttpResponseType.Blob) {
          responseType = 'blob';
        } else if (query.responseType == HttpResponseType.XML) {
          responseType = 'text';
        }

        return this.http
          .post<HttpEvent<T | string>>(url, data, {
            headers: headers,
            withCredentials: withCredentials,
            observe: 'events',
            reportProgress: true,
            responseType: responseType
          })
          .pipe(
            catchError(error => {
              return of(error);
            }),
            map((result: HttpEvent<T | string> | HttpErrorResponse) => {
              let content: any;

              if (result instanceof HttpResponse) {
                if (query.responseType == HttpResponseType.XML) {
                  result = result.clone({ body: this.parseXML<T>(result.body as string) });
                }

                content = result.body;
              } else if (result instanceof HttpErrorResponse) {
                if (query.responseType == HttpResponseType.XML) {
                  result = new HttpErrorResponse({
                    error: this.parseXML(result.error as string),
                    headers: result.headers,
                    status: result.status,
                    statusText: result.statusText,
                    url: result.url
                  });
                }

                content = result.error;
              } else {
                return result;
              }

              if (query.errorTransformer) {
                const error = this.queryService.applyTransformer(
                  content,
                  query.errorTransformer,
                  parameters.url,
                  false,
                  {
                    http: {
                      code: result.status
                    }
                  }
                );

                if (error) {
                  const serverError = new ServerRequestError(error);
                  serverError.response = result;
                  serverError.status = result instanceof HttpResponseBase ? result.status : undefined;
                  throw serverError;
                }
              } else {
                if (result instanceof HttpErrorResponse) {
                  throw new ServerRequestError(result);
                }
              }

              return result as HttpEvent<T>;
            })
          );
      })
    );
  }

  request<T>(query: HttpQuery, options: HttpQueryOptions = {}): Observable<HttpResponse<T>> {
    return this.executeRequest<T>(query, options).pipe(
      filter(event => event.type == HttpEventType.Response),
      map(event => event as HttpResponse<T>)
    );
  }

  requestBody<T = Object>(query: HttpQuery, options: HttpQueryOptions = {}): Observable<T> {
    return this.request<T>(query, options).pipe(
      map(response => {
        let resultBody = response.body;
        const url = query.getEffectiveUrl(options.baseQuery);

        resultBody = this.queryService.applyTransformer(
          resultBody,
          query.responseTransformer,
          url,
          options.raiseErrors,
          options.tokens
        );
        resultBody = this.queryService.getPath(resultBody, query.responsePath);

        return resultBody as T;
      })
    );
  }
}

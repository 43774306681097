/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../base/layer-resizable.directive";
import * as i2 from "../../../services/view-editor-context/view-editor.context";
import * as i3 from "@angular/common";
import * as i4 from "./line-layer-bounds.component";
var styles_LineLayerBoundsComponent = [];
var RenderType_LineLayerBoundsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_LineLayerBoundsComponent, data: {} });
export { RenderType_LineLayerBoundsComponent as RenderType_LineLayerBoundsComponent };
export function View_LineLayerBoundsComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 11, "div", [["class", "line-layer__bounds view-editor-bounds"]], [[2, "line-layer__bounds_hover", null], [2, "line-layer__bounds_active", null], [4, "top", "%"], [4, "left", "%"], [4, "width", "em"], [4, "transform", null]], null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "div", [["appLayerResizable", ""], ["class", "view-editor-bounds__handle view-editor-bounds__handle_position_top-left view-editor-bounds__handle_cursor_move"]], null, [[null, "appLayerResizableResizeStarted"], [null, "appLayerResizableResize"], [null, "appLayerResizableResizeFinished"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("appLayerResizableResizeStarted" === en)) {
        var pd_0 = (_co.onPointResizeStarted() !== false);
        ad = (pd_0 && ad);
    } if (("appLayerResizableResize" === en)) {
        var pd_1 = (_co.applyPointResize($event, false) !== false);
        ad = (pd_1 && ad);
    } if (("appLayerResizableResizeFinished" === en)) {
        var pd_2 = (_co.onPointResizeFinished() !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i0.ɵdid(2, 212992, null, 0, i1.LayerResizableDirective, [i0.ElementRef, i2.ViewEditorContext, i0.NgZone], null, { resizeStarted: "appLayerResizableResizeStarted", resize: "appLayerResizableResize", resizeFinished: "appLayerResizableResizeFinished" }), (_l()(), i0.ɵeld(3, 0, null, null, 1, "div", [["appLayerResizable", ""], ["class", "view-editor-bounds__handle view-editor-bounds__handle_position_top-right view-editor-bounds__handle_cursor_move"]], null, [[null, "appLayerResizableResizeStarted"], [null, "appLayerResizableResize"], [null, "appLayerResizableResizeFinished"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("appLayerResizableResizeStarted" === en)) {
        var pd_0 = (_co.onPointResizeStarted() !== false);
        ad = (pd_0 && ad);
    } if (("appLayerResizableResize" === en)) {
        var pd_1 = (_co.applyPointResize($event, true) !== false);
        ad = (pd_1 && ad);
    } if (("appLayerResizableResizeFinished" === en)) {
        var pd_2 = (_co.onPointResizeFinished() !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i0.ɵdid(4, 212992, null, 0, i1.LayerResizableDirective, [i0.ElementRef, i2.ViewEditorContext, i0.NgZone], null, { resizeStarted: "appLayerResizableResizeStarted", resize: "appLayerResizableResize", resizeFinished: "appLayerResizableResizeFinished" }), (_l()(), i0.ɵeld(5, 0, null, null, 0, "div", [["class", "line-layer__bounds-line"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 2, "div", [["class", "view-editor-bounds__dot view-editor-bounds__dot_position_top-left"]], [[2, "view-editor-bounds__dot_active", null]], null, null, null, null)), i0.ɵpid(131072, i3.AsyncPipe, [i0.ChangeDetectorRef]), i0.ɵpid(131072, i3.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(9, 0, null, null, 2, "div", [["class", "view-editor-bounds__dot view-editor-bounds__dot_position_top-right"]], [[2, "view-editor-bounds__dot_active", null]], null, null, null, null)), i0.ɵpid(131072, i3.AsyncPipe, [i0.ChangeDetectorRef]), i0.ɵpid(131072, i3.AsyncPipe, [i0.ChangeDetectorRef])], function (_ck, _v) { _ck(_v, 2, 0); _ck(_v, 4, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.hover; var currVal_1 = _co.active; var currVal_2 = (_co.fromY * 100); var currVal_3 = (_co.fromX * 100); var currVal_4 = (_co.length / 10); var currVal_5 = _co.transform; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); var currVal_6 = ((_co.active && !i0.ɵunv(_v, 6, 0, i0.ɵnov(_v, 7).transform(_co.editorContext.movingLayer$))) && !i0.ɵunv(_v, 6, 0, i0.ɵnov(_v, 8).transform(_co.editorContext.customizingGradient$))); _ck(_v, 6, 0, currVal_6); var currVal_7 = ((_co.active && !i0.ɵunv(_v, 9, 0, i0.ɵnov(_v, 10).transform(_co.editorContext.movingLayer$))) && !i0.ɵunv(_v, 9, 0, i0.ɵnov(_v, 11).transform(_co.editorContext.customizingGradient$))); _ck(_v, 9, 0, currVal_7); }); }
export function View_LineLayerBoundsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-line-layer-bounds", [], null, null, null, View_LineLayerBoundsComponent_0, RenderType_LineLayerBoundsComponent)), i0.ɵdid(1, 245760, null, 0, i4.LineLayerBoundsComponent, [i2.ViewEditorContext], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LineLayerBoundsComponentNgFactory = i0.ɵccf("app-line-layer-bounds", i4.LineLayerBoundsComponent, View_LineLayerBoundsComponent_Host_0, { frame: "frame", translate: "translate", points: "points", fromX: "fromX", fromY: "fromY", length: "length", transform: "transform", snapFrame: "snapFrame", snapFrameContainer: "snapFrameContainer", snapFrameExceptLayers: "snapFrameExceptLayers", active: "active", hover: "hover" }, { resizeStarted: "resizeStarted", resizeFinished: "resizeFinished", updateLayer: "updateLayer" }, []);
export { LineLayerBoundsComponentNgFactory as LineLayerBoundsComponentNgFactory };

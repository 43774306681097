import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';

import { AppDrag, AppDragDrop, DragAxis, moveItemInFormArray, transferFormArrayItem } from '@common/drag-drop2';
import { AnalyticsEvent, AnalyticsEventAction, UniversalAnalyticsService } from '@modules/analytics';
import { MenuBlockLayout, MenuItemActionService } from '@modules/menu';

import { AddMenuItemOptions } from '../add-menu-item-menu/add-menu-item-menu.component';
import { CustomizeBarPagesEditButtonControl } from '../customize-bar-pages-edit/button-control';
import {
  CustomizeBarPagesEditMenuItemArray,
  CustomizeBarPagesEditMenuItemControl
} from '../customize-bar-pages-edit/customize-bar-pages-edit.form';
import { CustomizeBarPagesEditSectionControl } from '../customize-bar-pages-edit/section-control';

@Component({
  selector: 'app-button-menu-item-edit',
  templateUrl: './button-menu-item-edit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ButtonMenuItemEditComponent implements OnInit, OnDestroy {
  @Input() control: CustomizeBarPagesEditButtonControl;
  @Input() layout: MenuBlockLayout;
  @Input() createdOptions: AddMenuItemOptions;
  @Output() deleteRequested = new EventEmitter<void>();

  actionValueDisplay: string;
  iconPopoverOpened = false;
  editPopoverOpened = false;
  childrenExpanded = false;
  createdMenuItemControl: CustomizeBarPagesEditMenuItemControl;
  createdMenuItemControlOptions: AddMenuItemOptions;
  dragAxis = DragAxis;

  trackMenuItemFn(i, item: CustomizeBarPagesEditMenuItemControl) {
    return item.menuItem.id;
  }

  constructor(
    private menuItemActionService: MenuItemActionService,
    private analyticsService: UniversalAnalyticsService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.menuItemActionService
      .getActionValueDisplay$(this.control.controls.action)
      .pipe(untilDestroyed(this))
      .subscribe(value => {
        this.actionValueDisplay = value ? value.labelShort || value.label : undefined;
        this.cd.markForCheck();
      });

    if (this.createdOptions && this.createdOptions.customize) {
      this.openEditPopover(true);
    }

    if (this.createdOptions && this.createdOptions.opened) {
      this.setChildrenExpanded(true);
    }
  }

  ngOnDestroy(): void {}

  isDroppable(item: AppDrag<CustomizeBarPagesEditMenuItemControl>): boolean {
    return !(item.data instanceof CustomizeBarPagesEditSectionControl);
  }

  onTitleChanged(value: string) {
    this.control.controls.title.patchValue(value);
    this.cd.markForCheck();
  }

  openIconPopover(value) {
    this.iconPopoverOpened = value;
    this.cd.markForCheck();
  }

  openEditPopover(value) {
    this.editPopoverOpened = value;
    this.cd.markForCheck();
  }

  setChildrenExpanded(value: boolean) {
    this.childrenExpanded = value;
    this.cd.markForCheck();
  }

  toggleChildrenExpanded() {
    this.setChildrenExpanded(!this.childrenExpanded);
  }

  addMenuItem(options: AddMenuItemOptions) {
    this.createdMenuItemControl = this.control.children.addControl(options.item, options.prepend);
    this.createdMenuItemControlOptions = options;
    this.setChildrenExpanded(true);

    this.analyticsService.sendEvent(AnalyticsEvent.GA.MenuItem, AnalyticsEventAction.Created);
    this.analyticsService.sendSimpleEvent(AnalyticsEvent.Menu.MenuItemAdded);
  }

  dragDrop(event: AppDragDrop<CustomizeBarPagesEditMenuItemArray>) {
    if (event.previousContainer === event.container) {
      moveItemInFormArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferFormArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    }
  }
}

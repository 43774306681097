var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { isSet } from '@shared';
import { MenuItem } from './menu-item';
import { MenuItemAction, MenuItemActionType } from './menu-item-action';
import { MenuItemType } from './menu-item-type';
import { registerMenuItemForType } from './menu-items';
var SimpleMenuItem = /** @class */ (function (_super) {
    __extends(SimpleMenuItem, _super);
    function SimpleMenuItem() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.type = MenuItemType.Simple;
        return _this;
    }
    SimpleMenuItem.prototype.deserialize = function (data) {
        _super.prototype.deserialize.call(this, data);
        this.title = this.params['title'];
        this.icon = this.params['icon'];
        if (this.params['action']) {
            this.action = new MenuItemAction().deserialize(this.params['action']);
        }
        else if (isSet(this.params['uid']) || isSet(this.params['unique_name'])) {
            // Backward compatibility
            this.action = new MenuItemAction();
            this.action.type = MenuItemActionType.Page;
            this.action.pageUid = this.params['uid'];
            this.action.pageUniqueName = this.params['unique_name'];
        }
        else if (isSet(this.params['custom_link'])) {
            // Backward compatibility
            this.action = new MenuItemAction();
            this.action.type = MenuItemActionType.URL;
            this.action.url = this.params['custom_link'];
        }
        return this;
    };
    SimpleMenuItem.prototype.serialize = function () {
        var data = _super.prototype.serialize.call(this);
        data['params']['title'] = this.title;
        data['params']['icon'] = this.icon;
        data['params']['action'] = this.action ? this.action.serialize() : undefined;
        return data;
    };
    SimpleMenuItem.prototype.isForPage = function (page) {
        if (!this.action || this.action.type != MenuItemActionType.Page) {
            return false;
        }
        if (isSet(this.action.pageUid)) {
            return page.uid == this.action.pageUid;
        }
        else if (isSet(this.action.pageUniqueName)) {
            return page.uniqueName == this.action.pageUniqueName;
        }
        else {
            return false;
        }
    };
    SimpleMenuItem.prototype.copyFrom = function (item) {
        _super.prototype.copyFrom.call(this, item);
        this.title = item.title;
        this.icon = item.icon;
        if (item.action) {
            this.action = new MenuItemAction();
            this.action.copyFrom(item.action);
        }
    };
    SimpleMenuItem.prototype.clone = function () {
        var instance = new SimpleMenuItem();
        instance.copyFrom(this);
        return instance;
    };
    return SimpleMenuItem;
}(MenuItem));
export { SimpleMenuItem };
registerMenuItemForType(MenuItemType.Simple, SimpleMenuItem);

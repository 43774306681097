import { ChangeDetectorRef, EventEmitter, OnChanges, OnDestroy, OnInit, SimpleChange } from '@angular/core';
import toPairs from 'lodash/toPairs';
import values from 'lodash/values';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { DynamicComponent } from '@common/dynamic-component';
import { applyBooleanInput$ } from '@modules/fields';
import { MenuBlock, MenuItem, MenuItemType } from '@modules/menu';
import { isSet } from '@shared';
import { MenuContext } from '../../../data/menu-context';
import { ButtonMenuItemComponent } from '../button-menu-item/button-menu-item.component';
import { CustomMenuItemComponent } from '../custom-menu-item/custom-menu-item.component';
import { ImageMenuItemComponent } from '../image-menu-item/image-menu-item.component';
import { ModelLinkMenuItemComponent } from '../model-link-menu-item/model-link-menu-item.component';
import { SectionMenuItemComponent } from '../section-menu-item/section-menu-item.component';
import { SeparatorMenuItemComponent } from '../separator-menu-item/separator-menu-item.component';
import { ShareMenuItemComponent } from '../share-menu-item/share-menu-item.component';
import { SimpleMenuItemComponent } from '../simple-menu-item/simple-menu-item.component';
var AutoMenuItemComponent = /** @class */ (function () {
    function AutoMenuItemComponent(context, cd) {
        this.context = context;
        this.cd = cd;
        this.primary = false;
        this.dropdown = false;
        this.horizontal = false;
        this.childrenVertical = false;
        this.forceActive = false;
        this.forceOpened = false;
        this.dropdownOpen = new EventEmitter();
        this.dropdownClose = new EventEmitter();
        this.menuItem$ = new BehaviorSubject(undefined);
        this.mapping = [
            { type: MenuItemType.Simple, component: SimpleMenuItemComponent },
            { type: MenuItemType.Section, component: SectionMenuItemComponent },
            { type: MenuItemType.ModelLink, component: ModelLinkMenuItemComponent },
            { type: MenuItemType.Image, component: ImageMenuItemComponent },
            { type: MenuItemType.Button, component: ButtonMenuItemComponent },
            { type: MenuItemType.Separator, component: SeparatorMenuItemComponent },
            { type: MenuItemType.Share, component: ShareMenuItemComponent },
            { type: MenuItemType.Custom, component: CustomMenuItemComponent }
        ];
        this.defaultMapping = SimpleMenuItemComponent;
        this.isVisible = false;
    }
    AutoMenuItemComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.menuItem$.next(this.menuItem);
        this.menuItem$
            .pipe(switchMap(function (menuItem) {
            return applyBooleanInput$(menuItem.visibleInput, {
                context: _this.context
            });
        }), untilDestroyed(this))
            .subscribe(function (value) {
            _this.isVisible = value;
            _this.cd.markForCheck();
        });
    };
    AutoMenuItemComponent.prototype.ngOnDestroy = function () { };
    AutoMenuItemComponent.prototype.ngOnChanges = function (changes) {
        if (changes.menuItem &&
            (changes.menuItem.previousValue ? changes.menuItem.previousValue.type : undefined) !==
                (changes.menuItem.currentValue ? changes.menuItem.currentValue.type : undefined)) {
            this.initComponent();
        }
        else {
            this.updateComponent();
        }
        if (changes.menuItem && !changes.menuItem.firstChange) {
            this.menuItem$.next(this.menuItem);
        }
    };
    AutoMenuItemComponent.prototype.getComponentInputs = function () {
        return {
            menuItem: this.menuItem,
            menuBlock: this.menuBlock,
            primary: this.primary,
            dropdown: this.dropdown,
            horizontal: this.horizontal,
            childrenVertical: this.childrenVertical,
            forceActive: this.forceActive,
            forceOpened: this.forceOpened,
            context: this.context
        };
    };
    AutoMenuItemComponent.prototype.initComponent = function () {
        var _this = this;
        var mapping = this.mapping.find(function (item) { return item.type == _this.menuItem.type; });
        var component = mapping ? mapping.component : this.defaultMapping;
        if (!component) {
            this.componentData = undefined;
            this.cd.markForCheck();
            console.error("No such menu item type registered: " + this.menuItem.type);
            return;
        }
        this.componentData = {
            component: component,
            inputs: this.getComponentInputs(),
            outputs: {
                dropdownOpen: [function () { return _this.dropdownOpen.emit(); }],
                dropdownClose: [function () { return _this.dropdownClose.emit(); }]
            }
        };
        this.cd.markForCheck();
    };
    AutoMenuItemComponent.prototype.updateComponent = function (options) {
        if (options === void 0) { options = {}; }
        if (!this.dynamicComponent ||
            !this.dynamicComponent.currentComponent ||
            !this.dynamicComponent.currentComponent.instance) {
            return;
        }
        var ref = this.dynamicComponent.currentComponent;
        var inputs = this.getComponentInputs();
        var changes = toPairs(inputs).reduce(function (acc, _a) {
            var prop = _a[0], currentValue = _a[1];
            var prevValue = ref.instance[prop];
            if ((isSet(options.forcePropUpdate) && prop == options.forcePropUpdate) || prevValue !== currentValue) {
                acc[prop] = new SimpleChange(prevValue, currentValue, false);
                ref.instance[prop] = currentValue;
            }
            return acc;
        }, {});
        this.componentData.inputs = inputs;
        if (values(changes).length) {
            if (ref.instance['ngOnChanges']) {
                ref.instance['ngOnChanges'](changes);
            }
            ref.changeDetectorRef.markForCheck();
        }
    };
    return AutoMenuItemComponent;
}());
export { AutoMenuItemComponent };

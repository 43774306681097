const ignoreElementCustomizeProperty = '_elementCustomize';
const markClickEventElementProperty = '_markClickEventElement';

export function ignoreElementCustomize(clickEvent: MouseEvent) {
  clickEvent[ignoreElementCustomizeProperty] = true;
}

export function isElementCustomizeIgnored(clickEvent: MouseEvent) {
  return !!clickEvent[ignoreElementCustomizeProperty];
}

export function markElementClickEvent(clickEvent: MouseEvent) {
  clickEvent[markClickEventElementProperty] = true;
}

export function isElementClickEvent(clickEvent: MouseEvent) {
  return !!clickEvent[markClickEventElementProperty];
}

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { FormControl } from '@angular/forms';
import fromPairs from 'lodash/fromPairs';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { filter } from 'rxjs/operators';

import { ViewContext, ViewContextElement } from '@modules/customize';
import { ParameterField } from '@modules/fields';
import { ViewContextTokenProvider } from '@modules/parameters-components';
import { CurrentUserStore } from '@modules/users';
import { CustomViewTemplate, CustomViewTemplateCounterStore, Frame, View, ViewMapping } from '@modules/views';
import { isSet } from '@shared';

// TODO: Refactor import
import { CustomViewTemplateFilter } from '../../../views-components/components/custom-view-templates-list/custom-view-templates-list.component';
import { CustomViewMapParametersController } from '../../../views-components/services/custom-view-map-parameters-controller/custom-view-map-parameters.controller';
import { CustomViewTemplatesController } from '../../../views-components/services/custom-view-templates-controller/custom-view-templates.controller';
import { ViewEditorController } from '../../../views-components/services/view-editor-controller/view-editor.controller';

@Component({
  selector: 'app-custom-view-item-edit',
  templateUrl: './custom-view-item-edit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomViewItemEditComponent implements OnInit, OnDestroy {
  @Input() control: FormControl;
  @Input() mappingsControl: FormControl;
  @Input() sourceParameters: ParameterField[];
  @Input() stateSelectedEnabled = false;
  @Input() subtitle = 'Custom component';
  @Input() initialTemplatesFilter: CustomViewTemplateFilter = {};
  @Input() deleteEnabled = false;
  @Input() componentLabel = 'component';
  @Input() loading = false;
  @Input() context: ViewContext;
  @Input() contextElement: ViewContextElement;
  @Input() contextElementPath: (string | number)[];
  @Input() contextElementPaths: (string | number)[][];
  @Input() analyticsSource: string;
  @Output() changeView = new EventEmitter<View>();
  @Output() selectTemplate = new EventEmitter<CustomViewTemplate>();

  templatesApprox: number;

  constructor(
    public currentUserStore: CurrentUserStore,
    private viewEditorController: ViewEditorController,
    private customViewTemplateCounterStore: CustomViewTemplateCounterStore,
    private customViewTemplatesController: CustomViewTemplatesController,
    private customViewMapParametersController: CustomViewMapParametersController,
    private contextTokenProvider: ViewContextTokenProvider,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.customViewTemplateCounterStore
      .getApproxFirst$()
      .pipe(untilDestroyed(this))
      .subscribe(value => {
        this.templatesApprox = value;
        this.cd.markForCheck();
      });

    this.control.valueChanges.pipe(untilDestroyed(this)).subscribe(() => this.cd.markForCheck());
  }

  ngOnDestroy(): void {}

  setValue(view: View, template?: CustomViewTemplate) {
    this.control.patchValue(view);
    this.changeView.emit(view);

    if (template) {
      this.selectTemplate.emit(template);
    }
  }

  setMappingValue(mappings: ViewMapping[]) {
    if (this.mappingsControl) {
      this.mappingsControl.patchValue(mappings ? mappings : []);
    }
  }

  createView(): View {
    const result = new View();

    result.generateId();
    result.name = 'New View';
    result.frame = new Frame({ width: 300, height: 240 });

    if (this.sourceParameters) {
      result.parameters = this.sourceParameters;

      if (this.contextElement) {
        const testValues = this.sourceParameters
          .map(parameter => {
            const testValue = this.contextElement.getFieldValue(parameter.name);
            return [parameter.name, testValue];
          })
          .filter(item => isSet(item));

        result.testParameters = fromPairs(testValues);
      }
    }

    return result;
  }

  openViewEditor() {
    let view: View = this.control.value;
    const create = !this.control.value;

    if (!view) {
      view = this.createView();
    }

    this.viewEditorController
      .open({
        create: create,
        view: view,
        componentLabel: this.componentLabel,
        submitLabel: create ? 'Create component' : 'Save changes',
        stateSelectedEnabled: this.stateSelectedEnabled,
        analyticsSource: this.analyticsSource
      })
      .pipe(
        filter(result => !result.cancelled),
        untilDestroyed(this)
      )
      .subscribe(result => {
        this.setValue(result.view);
      });
  }

  openCustomViewTemplates() {
    this.customViewTemplatesController
      .chooseTemplate({
        initialFilter: this.initialTemplatesFilter,
        viewCreateEnabled: true,
        stateSelectedEnabled: this.stateSelectedEnabled,
        componentLabel: this.componentLabel,
        analyticsSource: this.analyticsSource
      })
      .pipe(
        filter(result => !result.cancelled),
        untilDestroyed(this)
      )
      .subscribe(viewResult => {
        if (this.mappingsControl) {
          this.customViewMapParametersController
            .open({
              sourceParameters: this.sourceParameters,
              view: viewResult.view,
              context: this.context,
              contextElement: this.contextElement,
              contextElementPath: this.contextElementPath,
              contextElementPaths: this.contextElementPaths,
              contextTokenProvider: this.contextTokenProvider,
              analyticsSource: this.analyticsSource
            })
            .pipe(
              filter(mappingResult => !mappingResult.cancelled),
              untilDestroyed(this)
            )
            .subscribe(mappingResult => {
              this.setValue(viewResult.view, viewResult.template);
              this.setMappingValue(mappingResult.mappings);
            });
        } else {
          this.setValue(viewResult.view, viewResult.template);
        }
      });
  }

  updateCustomViewTemplate() {
    const view = this.control.value as View;

    this.customViewTemplatesController
      .setTemplateView(view, { stateSelectedEnabled: this.stateSelectedEnabled, componentLabel: this.componentLabel })
      .pipe(
        filter(result => !result.cancelled),
        untilDestroyed(this)
      )
      .subscribe();
  }

  resetCardView() {
    this.setValue(null);
    this.setMappingValue([]);
  }

  changeMapping() {
    const view: View = this.control.value;
    const mappings: ViewMapping[] = this.mappingsControl.value;

    this.customViewMapParametersController
      .open({
        sourceParameters: this.sourceParameters,
        view: view,
        mappings: mappings,
        context: this.context,
        contextElement: this.contextElement,
        contextElementPath: this.contextElementPath,
        contextElementPaths: this.contextElementPaths,
        contextTokenProvider: this.contextTokenProvider,
        analyticsSource: this.analyticsSource
      })
      .pipe(
        filter(mappingResult => !mappingResult.cancelled),
        untilDestroyed(this)
      )
      .subscribe(mappingResult => {
        this.setMappingValue(mappingResult.mappings);
      });
  }
}

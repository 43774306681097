import { CdkConnectedOverlay, ConnectedOverlayPositionChange, ConnectedPosition } from '@angular/cdk/overlay';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest, Observable, of, Subscription } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { ViewContext, ViewContextElement } from '@modules/customize';
import { ParametersConfigurable } from '@modules/field-components';
import {
  BaseField,
  FieldType,
  getFieldDescriptionByType,
  Input as FieldInput,
  InputValueType,
  ParameterControl
} from '@modules/fields';
import { getInputRepresentation } from '@modules/parameters';
import { SidebarCollapseContext } from '@modules/sidebar';
import { controlValue, forceObservable, isSet, KeyboardEventKeyCode } from '@shared';

import { InputsEditForm } from '../inputs-edit/inputs-edit.form';

@Component({
  selector: 'app-parameters-edit-with-inputs-item',
  templateUrl: './parameters-edit-with-inputs-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ParametersEditWithInputsItemComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  @Input() control: ParameterControl;
  @Input() configurable: ParametersConfigurable = {};
  @Input() inputsForm: InputsEditForm;
  @Input() itemForm: FormGroup;
  @Input() openedInitial = false;
  @Input() context: ViewContext;
  @Input() contextElement: ViewContextElement;
  @Input() contextElementPath: (string | number)[];
  @Input() contextElementPaths: (string | number)[][];
  @Input() staticValueDisabled = false;
  @Input() collapseContext: SidebarCollapseContext;
  @Input() userInput = false;
  @Input() focusedInitial = false;
  @Input() warning: string;
  @Input() displayValueTypes = [InputValueType.Formula];
  @Input() placeholder = 'Text';
  @Input() formulaPlaceholder = 'Formula';
  @Input() jsPlaceholder = 'return 2 * 3;';
  @Input() classes: string | string[];
  @Input() analyticsSource: string;
  @Output() deleteRequested = new EventEmitter<void>();

  @ViewChild(CdkConnectedOverlay) cdkConnectedOverlay: CdkConnectedOverlay;
  @ViewChild('field_type_overlay', { read: CdkConnectedOverlay }) fieldTypeOverlay: CdkConnectedOverlay;

  opened = false;
  rename = false;
  renameControl = new FormControl('');
  popoverOpened = false;
  hovered$ = new BehaviorSubject<boolean>(false);
  description: string;
  infoSubscription: Subscription;
  initCollapseContextSubscription: Subscription;
  fieldIcon$: Observable<string>;
  fieldTypeDropdownOpened = false;
  fieldTypeDropdownPositions: ConnectedPosition[] = [
    {
      panelClass: ['overlay_position_bottom-left'],
      originX: 'start',
      overlayX: 'start',
      originY: 'bottom',
      overlayY: 'top',
      offsetX: -8
    },
    {
      panelClass: ['overlay_position_bottom-right'],
      originX: 'end',
      overlayX: 'end',
      originY: 'bottom',
      overlayY: 'top',
      offsetX: 8
    },
    {
      panelClass: ['overlay_position_top-left'],
      originX: 'start',
      overlayX: 'start',
      originY: 'top',
      overlayY: 'bottom',
      offsetX: -8
    },
    {
      panelClass: ['overlay_position_top-right'],
      originX: 'end',
      overlayX: 'end',
      originY: 'top',
      overlayY: 'bottom',
      offsetX: 8
    },
    {
      panelClass: ['overlay_position_left-center'],
      originX: 'start',
      overlayX: 'end',
      originY: 'center',
      overlayY: 'center'
    },
    {
      panelClass: ['overlay_position_right-center'],
      originX: 'end',
      overlayX: 'start',
      originY: 'center',
      overlayY: 'center'
    }
  ];
  popoverPositionsSubscription: Subscription;

  constructor(protected cd: ChangeDetectorRef) {}

  ngOnInit() {
    this.fieldIcon$ = controlValue<FieldType>(this.control.controls.field).pipe(
      map(value => getFieldDescriptionByType(value).icon)
    );
  }

  ngOnDestroy(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['inputsForm']) {
      this.initItemGroup();
    }

    if (changes['collapseContext']) {
      this.initCollapseContext();
    }
  }

  ngAfterViewInit(): void {
    this.setPositionObserver();
  }

  initCollapseContext() {
    if (this.initCollapseContextSubscription) {
      this.initCollapseContextSubscription.unsubscribe();
      this.initCollapseContextSubscription = undefined;
    }

    if (this.collapseContext) {
      this.initCollapseContextSubscription = this.collapseContext.opened$
        .pipe(untilDestroyed(this))
        .subscribe(opened => {
          this.opened = opened === this;
          this.cd.markForCheck();
        });
    }

    if (this.openedInitial) {
      this.setOpened(true);
    }
  }

  initItemGroup() {
    if (this.infoSubscription) {
      this.infoSubscription.unsubscribe();
    }

    if (!this.itemForm) {
      return;
    }

    this.infoSubscription = combineLatest(
      controlValue(this.control).pipe(map(() => this.control.serialize())),
      controlValue(this.itemForm)
    )
      .pipe(switchMap(([parameter, value]) => this.getDescription(parameter, value)))
      .pipe(untilDestroyed(this))
      .subscribe(description => {
        this.description = description;
        this.cd.markForCheck();
      });
  }

  getInputRepresentation(input: FieldInput, parameter: BaseField): Observable<string> {
    return this.inputsForm.filterFields$().pipe(
      switchMap(filterFields => {
        const result = getInputRepresentation(input, {
          context: this.context,
          contextElement: this.contextElement,
          contextElementPath: this.contextElementPath,
          contextElementPaths: this.contextElementPaths,
          filterFields: filterFields
        });
        return forceObservable<string>(result);
      }),
      map(result => {
        return isSet(result) ? result : parameter.description;
      })
    );
  }

  getDescription(parameter: BaseField, value: Object): Observable<string> {
    if (!parameter) {
      return of('Not existing');
    } else if (!parameter.field) {
      return of(null);
    }

    const input = this.inputsForm.serializeValueItem(value);

    if (!input) {
      return of(null);
    }

    return this.getInputRepresentation(input, parameter);
  }

  setOpened(opened: boolean) {
    if (this.collapseContext) {
      if (opened && this.collapseContext.opened !== this) {
        this.collapseContext.opened = this;
      } else if (!opened && this.collapseContext.opened === this) {
        this.collapseContext.opened = undefined;
      }
    } else {
      this.opened = opened;
      this.cd.markForCheck();
    }
  }

  toggleOpened() {
    this.setOpened(!this.opened);
  }

  setRename(rename: boolean, save = true) {
    this.rename = rename;
    this.cd.markForCheck();

    if (this.rename) {
      this.renameControl.patchValue(this.control.controls.name.value);
    } else if (!rename && save) {
      this.control.controls.name.patchValue(this.renameControl.value);
      this.itemForm.controls['name'].patchValue(this.renameControl.value);
    }
  }

  onKeyUp(e: KeyboardEvent) {
    if (e.keyCode == KeyboardEventKeyCode.Enter) {
      this.setRename(false);
    } else if (e.keyCode == KeyboardEventKeyCode.Escape) {
      this.setRename(false, false);
    }
  }

  onKeyPress(e: KeyboardEvent) {
    if (e.keyCode == KeyboardEventKeyCode.Enter) {
      e.preventDefault();
    }
  }

  setPopoverOpened(opened: boolean) {
    this.popoverOpened = opened;
    this.cd.markForCheck();
  }

  onPopoverContentChanged() {
    this.cdkConnectedOverlay.overlayRef.updatePosition();
  }

  setFieldTypeDropdownOpened(value: boolean) {
    this.fieldTypeDropdownOpened = value;
    this.cd.markForCheck();
  }

  setPositionObserver() {
    if (this.popoverPositionsSubscription) {
      this.popoverPositionsSubscription.unsubscribe();
    }

    if (!this.fieldTypeOverlay) {
      return;
    }

    this.popoverPositionsSubscription = this.fieldTypeOverlay.positionChange
      .pipe(untilDestroyed(this))
      .subscribe((e: ConnectedOverlayPositionChange) => {
        const propsEqual = ['offsetX', 'offsetY', 'originX', 'originY', 'overlayX', 'overlayY'];
        const position = this.fieldTypeDropdownPositions.find(item =>
          propsEqual.every(prop => (item[prop] || undefined) == e.connectionPair[prop])
        );
        const otherPosition = this.fieldTypeDropdownPositions.filter(item => item !== position);

        if (position) {
          this.fieldTypeOverlay.overlayRef.addPanelClass(position.panelClass);
        }

        otherPosition.forEach(item => this.fieldTypeOverlay.overlayRef.removePanelClass(item.panelClass));
      });
  }
}

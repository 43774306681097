var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ChangeDetectorRef, EventEmitter, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { DraftItemsType } from '@modules/projects';
import { isSet } from '@shared';
export var DraftChangeItemType;
(function (DraftChangeItemType) {
    DraftChangeItemType["Created"] = "created";
    DraftChangeItemType["Updated"] = "updated";
    DraftChangeItemType["Deleted"] = "deleted";
})(DraftChangeItemType || (DraftChangeItemType = {}));
var DraftChangesItemComponent = /** @class */ (function () {
    function DraftChangesItemComponent(vcRef, cd) {
        this.vcRef = vcRef;
        this.cd = cd;
        this.disabled = false;
        this.publish = new EventEmitter();
        this.discard = new EventEmitter();
        this.expanded = false;
        this.hover = false;
        this.changeTypes = DraftChangeItemType;
    }
    DraftChangesItemComponent.prototype.onClick = function () {
        if (this.item.singleItem) {
            return;
        }
        this.toggleExpanded();
    };
    DraftChangesItemComponent.prototype.onEnter = function () {
        this.hover = true;
        this.cd.markForCheck();
    };
    DraftChangesItemComponent.prototype.onLeave = function () {
        this.hover = false;
        this.cd.markForCheck();
    };
    Object.defineProperty(DraftChangesItemComponent.prototype, "expandedClass", {
        get: function () {
            return this.expanded;
        },
        enumerable: true,
        configurable: true
    });
    DraftChangesItemComponent.prototype.ngOnInit = function () {
        this.vcRef.createEmbeddedView(this.template);
    };
    DraftChangesItemComponent.prototype.ngOnDestroy = function () { };
    DraftChangesItemComponent.prototype.expand = function () {
        this.expanded = true;
        this.cd.markForCheck();
    };
    DraftChangesItemComponent.prototype.collapse = function () {
        this.expanded = false;
        this.cd.markForCheck();
    };
    DraftChangesItemComponent.prototype.toggleExpanded = function () {
        if (this.expanded) {
            this.collapse();
        }
        else {
            this.expand();
        }
    };
    DraftChangesItemComponent.prototype.publishAll = function () {
        this.publish.emit(this.createEntityOptions());
    };
    DraftChangesItemComponent.prototype.publishItem = function (id) {
        this.publish.emit(this.createEntityOptions(id));
    };
    DraftChangesItemComponent.prototype.discardAll = function () {
        this.discard.emit(this.createEntityOptions());
    };
    DraftChangesItemComponent.prototype.discardItem = function (id) {
        this.discard.emit(this.createEntityOptions(id));
    };
    DraftChangesItemComponent.prototype.createEntityOptions = function (id) {
        return __assign({ itemsType: this.item.type }, (isSet(id) && __assign({}, (this.item.type == DraftItemsType.ViewSettings ? { viewSettings: [id] } : {}), (this.item.type == DraftItemsType.ModelDescriptions ? { modelDescriptions: [id] } : {}), (this.item.type == DraftItemsType.ActionDescriptions ? { actionDescriptions: [id] } : {}), (this.item.type == DraftItemsType.Resources ? { resources: [id] } : {}), (this.item.type == DraftItemsType.SecretTokens ? { secretTokens: [id] } : {}), (this.item.type == DraftItemsType.Storages ? { storages: [id] } : {}), (this.item.type == DraftItemsType.CustomViews ? { customViews: [id] } : {}), (this.item.type == DraftItemsType.TaskQueues ? { taskQueues: [id] } : {}), (this.item.type == DraftItemsType.ProjectProperties ? { projectProperties: [id] } : {}), (this.item.type == DraftItemsType.ProjectSettings ? { projectSettings: [id] } : {}))));
    };
    return DraftChangesItemComponent;
}());
export { DraftChangesItemComponent };

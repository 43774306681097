/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../../../dynamic-component/components/dynamic/dynamic.component.ngfactory";
import * as i3 from "../../../dynamic-component/components/dynamic/dynamic.component";
import * as i4 from "../../../dynamic-component/services/dynamic-component/dynamic-component.service";
import * as i5 from "./popover.component";
import * as i6 from "../../../popups/components/base-popup/base-popup.component";
var styles_PopoverComponent = [];
var RenderType_PopoverComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_PopoverComponent, data: {} });
export { RenderType_PopoverComponent as RenderType_PopoverComponent };
export function View_PopoverComponent_0(_l) { return i0.ɵvid(2, [i0.ɵqud(402653184, 1, { background: 0 }), i0.ɵqud(402653184, 2, { root: 0 }), (_l()(), i0.ɵeld(2, 0, [[1, 0], ["background", 1]], null, 0, "div", [["class", "popover-background"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onBackgroundClick($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(3, 0, [[2, 0], ["root", 1]], null, 5, "div", [["class", "popover"]], null, null, null, null, null)), i0.ɵdid(4, 278528, null, 0, i1.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpad(5, 1), i0.ɵdid(6, 278528, null, 0, i1.NgStyle, [i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { ngStyle: [0, "ngStyle"] }, null), (_l()(), i0.ɵeld(7, 16777216, null, null, 1, "app-dynamic-component", [], null, null, null, i2.View_DynamicComponent_0, i2.RenderType_DynamicComponent)), i0.ɵdid(8, 442368, null, 0, i3.DynamicComponent, [i0.Injector, i4.DynamicComponentService, i0.ViewContainerRef], { componentData: [0, "componentData"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "popover"; var currVal_1 = _ck(_v, 5, 0, ("popover_position_" + _co.position)); _ck(_v, 4, 0, currVal_0, currVal_1); var currVal_2 = _co.styles; _ck(_v, 6, 0, currVal_2); var currVal_3 = _co.data; _ck(_v, 8, 0, currVal_3); }, null); }
export function View_PopoverComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-popover", [], null, null, null, View_PopoverComponent_0, RenderType_PopoverComponent)), i0.ɵdid(1, 4440064, null, 0, i5.PopoverComponent, [i0.ChangeDetectorRef, i6.BasePopupComponent], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PopoverComponentNgFactory = i0.ɵccf("app-popover", i5.PopoverComponent, View_PopoverComponent_Host_0, { data: "data", anchor: "anchor" }, { showed: "showed" }, []);
export { PopoverComponentNgFactory as PopoverComponentNgFactory };

<tr
  *ngFor="let row of scrollable ? [1, 2, 3, 4, 5, 6, 7, 8] : rowsRange"
  class="table__row"
  [class.table__row_selected]="selection && row == 1"
  [class.inverse]="selection && row == 1"
>
  <td *ngIf="leftPaddingColumn" class="table__column table__column_left-padding table__stub-column"></td>

  <td
    *ngFor="let column of scrollable ? [1, 2, 3, 4, 5, 6, 7, 8] : columnsRange; let last = last"
    class="table__column table__stub-column"
  >
    <span *ngIf="!rowActionsButtons || !last" [class.loading-animation]="animating"
      ><span class="stub-text">Column</span></span
    >

    <div *ngIf="rowActionsButtons && last" class="table__column-actions">
      <div *ngFor="let item of ['edit', 'ok']" class="table__column-action">
        <div
          class="button button_small button_tint-color"
          [appButtonTintColor]="'accentColor' | appThemeColorHexOption | async"
          [appButtonTintColorStyle]="tintStyles.Default"
        >
          <span class="button__label">
            <span [class.loading-animation]="animating"
              ><span class="stub-text">{{ item }}</span></span
            >
          </span>
        </div>
      </div>
    </div>
  </td>

  <td *ngIf="newColumn" class="table__column table__column_disabled"></td>
</tr>

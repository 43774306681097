import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';

import { TokenStructureItem } from '@modules/tokens';

@Component({
  selector: 'app-token-structure-item',
  templateUrl: './token-structure-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TokenStructureItemComponent implements OnInit {
  @Input() item: TokenStructureItem;
  @Input() itemLast = false;
  @Input() parentsLast: boolean[] = [];
  @Output() inserted = new EventEmitter<TokenStructureItem>();
  @Output() valueChanged = new EventEmitter<{ item: TokenStructureItem; value: string }>();

  opened = false;
  parentsLastChild: boolean[];
  value: string;

  constructor(private cd: ChangeDetectorRef) {}

  ngOnInit() {
    this.opened = !!this.item.openedInitial;
    this.parentsLastChild = this.item.children ? [...this.parentsLast, this.itemLast] : undefined;

    if (this.item.defaultValue !== undefined) {
      this.value = this.item.defaultValue;
    }
  }

  toggleOpened() {
    this.opened = !this.opened;
    this.cd.markForCheck();
  }

  insert(item: TokenStructureItem) {
    if (!item.insert) {
      return;
    }
    this.inserted.next(item);
  }

  insertOrToggle(item: TokenStructureItem) {
    if (item.insert) {
      this.insert(item);
    } else {
      this.toggleOpened();
    }
  }

  onValueChanged(item: TokenStructureItem, value: string) {
    this.valueChanged.emit({ item: item, value: value });
  }
}

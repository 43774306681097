<!--<div class="step-form__item-layout">-->
<!--  <div class="step-form__item-layout__side">-->
<!--    <div class="step-form__item-title">For SQL using Docker</div>-->
<!--    <div class="step-form__item-description">-->
<!--      Install Jet Bridge without need to install any dependencies except Docker application.-->
<!--      May require additional network configuration for your OS.-->
<!--    </div>-->
<div class="step-form__item-label">Requirements:</div>
<ul class="step-form__item-requirements">
  <li class="step-form__item-requirements-item"><strong>Docker</strong> installed
  <li class="step-form__item-requirements-item"><strong>localhost</strong> or <strong>web server</strong> with an external IP</li>
</ul>
<!--  </div>-->
<!--  <div class="step-form__item-layout__main">-->
<div class="step-form__item-title">Installation</div>
<div class="step-form__item-instruction">
  <p>
    If you have any questions, please refer to our <br>
    <a [appDocumentation]="'install/jet-bridge-docker'">Documentation</a>
  </p>
  <p>
    1. Install <strong>Docker</strong> if you don't have it and make sure it is running<br>
    <a href="https://docs.docker.com/install/" target="_blank">https://docs.docker.com/install/</a>
  </p>

  <p>2. Install <strong>Jet Bridge</strong> container by running:</p>

<app-code *ngIf="project && token" [language]="'bash'">sh <(curl -s https://app.jetadmin.io/install_jet.sh) {{project.uniqueName}} {{token}}<ng-container *ngIf="environmentName"> {{environmentName}}</ng-container></app-code>

  <p>
    3. Finish installation by opening in your browser (usually it opens automatically):<br>
    <strong>localhost</strong> is your <strong>Jet Bridge</strong> HOST and <strong>8888</strong> is its PORT.
  </p>

<app-code [language]="'bash'">http://localhost:8888/api/register/</app-code>

</div>
<!--  </div>-->
<!--</div>-->

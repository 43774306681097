import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-popup-collapse',
  templateUrl: './popup-collapse.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PopupCollapseComponent implements OnInit {
  @Input() title: string;
  @Input() opened = false;
  @Input() border = false;

  constructor() {}

  ngOnInit() {}

  setOpened(opened: boolean) {
    this.opened = opened;
  }

  toggleOpened() {
    this.setOpened(!this.opened);
  }
}

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { untilDestroyed } from 'ngx-take-until-destroy';

import { UniqueIdToken } from '@common/unique-id';
import { TintStyle } from '@modules/actions';

import { FieldLabelButton, FormField } from '@modules/fields';

@Component({
  selector: 'app-field-wrapper',
  templateUrl: './field-wrapper.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FieldWrapperComponent implements OnInit, OnDestroy {
  @Input() form: FormGroup;
  @Input() field: FormField;
  @Input() readonly = false;
  @Input() requiredAsterisk = false;
  @Input() label = true;
  @Input() errors = true;
  @Input() fillVertical = true;
  @Input() truncate = false;
  @Input() manualMargin = false;
  @Input() labelButtons: FieldLabelButton[] = [];
  @Input() tooltip: string;
  @Input() idToken: UniqueIdToken;

  tintStyles = TintStyle;

  constructor(private cd: ChangeDetectorRef) {}

  ngOnInit() {
    if (this.form) {
      this.form.statusChanges.pipe(untilDestroyed(this)).subscribe(value => this.cd.markForCheck());
    }
  }

  ngOnDestroy(): void {}
}

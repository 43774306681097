import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';

import { AdminMode } from '@modules/admin-mode';
import { serializeTokenOptions, TokenOptions } from '@modules/api';
import { TOKEN_FRAGMENT_KEY } from '@modules/auth';
import { ProjectController } from '@modules/project-settings';
import { Environment, Project } from '@modules/projects';

@Component({
  selector: 'app-projects-item-dropdown',
  templateUrl: './projects-item-dropdown.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProjectsItemDropdownComponent implements OnInit, OnDestroy {
  @Input() project: Project;
  @Input() environment: Environment;
  @Input() externalToken: TokenOptions;

  adminModes = AdminMode;

  constructor(private projectController: ProjectController) {}

  ngOnInit() {}

  ngOnDestroy(): void {}

  deleteProject() {
    this.projectController
      .deleteProject(this.project.uniqueName, { dark: true })
      // .pipe(untilDestroyed(this))
      .subscribe();
  }

  getExternalHref(href: string): string {
    if (this.externalToken) {
      const tokenStr = btoa(JSON.stringify(serializeTokenOptions(this.externalToken)));
      return `${href}#${TOKEN_FRAGMENT_KEY}=${tokenStr}`;
    } else {
      return href;
    }
  }
}

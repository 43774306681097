import { ChangeDetectorRef, EventEmitter, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, FormControl } from '@angular/forms';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
var CompactSegmentedControlComponent = /** @class */ (function () {
    function CompactSegmentedControlComponent(cd) {
        this.cd = cd;
        this.options = [];
        this.fill = false;
        this.change = new EventEmitter();
        this.value$ = new Subject();
        this.options$ = new BehaviorSubject([]);
        this.onChange = function (value) { return undefined; };
        this.onTouched = function () { return undefined; };
    }
    CompactSegmentedControlComponent.prototype.ngOnInit = function () {
        var _this = this;
        combineLatest(this.value$, this.options$)
            .pipe(untilDestroyed(this))
            .subscribe(function (_a) {
            var value = _a[0], options = _a[1];
            _this.currentOption = options.find(function (item) { return item.value == value; });
            _this.cd.markForCheck();
        });
    };
    CompactSegmentedControlComponent.prototype.ngOnDestroy = function () { };
    CompactSegmentedControlComponent.prototype.ngOnChanges = function (changes) {
        if (changes.options) {
            this.options$.next(this.options);
        }
    };
    CompactSegmentedControlComponent.prototype.registerOnChange = function (fn) {
        this.onChange = fn;
    };
    CompactSegmentedControlComponent.prototype.registerOnTouched = function (fn) {
        this.onTouched = fn;
    };
    CompactSegmentedControlComponent.prototype.writeValue = function (value) {
        this.value$.next(value);
    };
    CompactSegmentedControlComponent.prototype.updateValue = function (value) {
        this.value$.next(value);
        this.onChange(value);
        this.change.emit();
    };
    return CompactSegmentedControlComponent;
}());
export { CompactSegmentedControlComponent };

import { FormGroup } from '@angular/forms';
import toPairs from 'lodash/toPairs';
import values from 'lodash/values';

import { ProjectPermission, ProjectPermissionType } from '@modules/projects';

import { ProjectPermissionControl } from './project-permission.control';

export class ProjectPermissionsControl extends FormGroup {
  controls: {
    project_settings: ProjectPermissionControl;
    project_billing: ProjectPermissionControl;
    project_access: ProjectPermissionControl;
    project_customization: ProjectPermissionControl;
  };

  constructor() {
    super({
      project_settings: new ProjectPermissionControl({
        type: ProjectPermissionType.Project,
        object: 'project_settings'
      }),
      project_billing: new ProjectPermissionControl({ type: ProjectPermissionType.Project, object: 'project_billing' }),
      project_access: new ProjectPermissionControl({ type: ProjectPermissionType.Project, object: 'project_access' }),
      project_customization: new ProjectPermissionControl({
        type: ProjectPermissionType.Project,
        object: 'project_customization'
      })
    });
  }

  deserialize(permissions: ProjectPermission[]) {
    toPairs(this.controls).forEach(([name, control]) => {
      const permission = permissions.find(
        item => item.permissionType == control.value.type && item.permissionObject == name
      );

      control.deserialize(permission);
    });
  }

  serialize(): ProjectPermission[] {
    return values<ProjectPermissionControl>(this.controls)
      .filter(control => control.value.enabled)
      .map(control => control.serialize());
  }

  setEveryControl(value: boolean) {
    this.controls.project_settings.patchValue({
      type: ProjectPermissionType.Project,
      object: 'project_settings',
      enabled: value
    });
    this.controls.project_billing.patchValue({
      type: ProjectPermissionType.Project,
      object: 'project_billing',
      enabled: value
    });
    this.controls.project_access.patchValue({
      type: ProjectPermissionType.Project,
      object: 'project_access',
      enabled: value
    });
    this.controls.project_customization.patchValue({
      type: ProjectPermissionType.Project,
      object: 'project_customization',
      enabled: value
    });
  }
}

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, merge } from 'rxjs';

import { BasePopupComponent } from '@common/popups';
import { AdminMode, ROUTE_ADMIN_MODE } from '@modules/admin-mode';
import { IntercomService, UniversalAnalyticsService } from '@modules/analytics';
import { ProjectTokenService, ResourceName } from '@modules/projects';
import { controlValue } from '@shared';

import { registerResourceSettingsComponent } from '../../../data/resource-settings-components';
import { ChooseSyncController } from '../../../services/choose-sync/choose-sync.controller';
import { BaseResourceSettingsComponent } from '../base-resource-settings/base-resource-settings.component';
import { SmartSuiteResourceSettingsForm } from './smart-suite-resource-settings.form';
import { SmartSuiteSolutionSource } from './smart-suite-solution-source';

@Component({
  selector: 'app-smart-suite-resource-settings',
  templateUrl: './smart-suite-resource-settings.component.html',
  providers: [SmartSuiteResourceSettingsForm, SmartSuiteSolutionSource],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SmartSuiteResourceSettingsComponent extends BaseResourceSettingsComponent implements OnInit, OnDestroy {
  constructor(
    @Inject(ROUTE_ADMIN_MODE) private mode: AdminMode,
    public solutionSource: SmartSuiteSolutionSource,
    private chooseSyncController: ChooseSyncController,
    public form: SmartSuiteResourceSettingsForm,
    popupComponent: BasePopupComponent,
    projectTokenService: ProjectTokenService,
    intercomService: IntercomService,
    analyticsService: UniversalAnalyticsService,
    cd: ChangeDetectorRef
  ) {
    super(form, popupComponent, projectTokenService, intercomService, analyticsService, cd);
  }

  ngOnInit() {
    super.ngOnInit();

    combineLatest(controlValue(this.form.form.controls['account_id']), controlValue(this.form.form.controls['key']))
      .pipe(untilDestroyed(this))
      .subscribe(([accountId, key]) => {
        this.solutionSource.accountId = accountId;
        this.solutionSource.key = key;
        this.solutionSource.reset();
      });

    merge(this.form.form.controls['account_id'].valueChanges, this.form.form.controls['key'].valueChanges)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.form.form.controls['solution_id'].patchValue(null);
      });
  }

  ngOnDestroy(): void {}

  chooseSync() {
    this.chooseSyncController
      .chooseSyncMode(this.form.form.controls['sync'], { typeItem: this.typeItem })
      .pipe(untilDestroyed(this))
      .subscribe(() => this.submit());
  }
}

registerResourceSettingsComponent(ResourceName.SmartSuite, SmartSuiteResourceSettingsComponent);

<div class="view-editor-bounds" [class.view-editor-bounds_active]="active">
  <div class="view-editor-bounds__line view-editor-bounds__line_position_top"></div>
  <div class="view-editor-bounds__line view-editor-bounds__line_position_right"></div>
  <div class="view-editor-bounds__line view-editor-bounds__line_position_bottom"></div>
  <div class="view-editor-bounds__line view-editor-bounds__line_position_left"></div>

  <div class="view-editor-bounds__dot view-editor-bounds__dot_position_top-left"></div>
  <div class="view-editor-bounds__dot view-editor-bounds__dot_position_top-right"></div>
  <div class="view-editor-bounds__dot view-editor-bounds__dot_position_bottom-right"></div>
  <div class="view-editor-bounds__dot view-editor-bounds__dot_position_bottom-left"></div>

  <ng-container *ngIf="resizeHorizontal">
    <div
      appLayerResizable
      (appLayerResizableResizeStarted)="onResizeStarted()"
      (appLayerResizableResize)="applyResize({ right: $event.deltaX, shift: $event.shift, alt: $event.alt })"
      (appLayerResizableResizeFinished)="onResizeFinished()"
      class="view-editor-bounds__handle view-editor-bounds__handle_position_right"
    ></div>

    <div
      appLayerResizable
      (appLayerResizableResizeStarted)="onResizeStarted()"
      (appLayerResizableResize)="applyResize({ left: $event.deltaX, shift: $event.shift, alt: $event.alt })"
      (appLayerResizableResizeFinished)="onResizeFinished()"
      class="view-editor-bounds__handle view-editor-bounds__handle_position_left"
    ></div>
  </ng-container>

  <ng-container *ngIf="resizeVertical">
    <div
      appLayerResizable
      (appLayerResizableResizeStarted)="onResizeStarted()"
      (appLayerResizableResize)="applyResize({ top: $event.deltaY, shift: $event.shift, alt: $event.alt })"
      (appLayerResizableResizeFinished)="onResizeFinished()"
      class="view-editor-bounds__handle view-editor-bounds__handle_position_top"
    ></div>

    <div
      appLayerResizable
      (appLayerResizableResizeStarted)="onResizeStarted()"
      (appLayerResizableResize)="applyResize({ bottom: $event.deltaY, shift: $event.shift, alt: $event.alt })"
      (appLayerResizableResizeFinished)="onResizeFinished()"
      class="view-editor-bounds__handle view-editor-bounds__handle_position_bottom"
    ></div>
  </ng-container>

  <ng-container *ngIf="resizeHorizontal || resizeVertical">
    <div
      appLayerResizable
      [appLayerResizableHorizontal]="resizeHorizontal"
      [appLayerResizableVertical]="resizeVertical"
      (appLayerResizableResizeStarted)="onResizeStarted()"
      (appLayerResizableResize)="
        applyResize({ top: $event.deltaY, left: $event.deltaX, shift: $event.shift, alt: $event.alt })
      "
      (appLayerResizableResizeFinished)="onResizeFinished()"
      class="view-editor-bounds__handle view-editor-bounds__handle_position_top-left"
    ></div>

    <div
      appLayerResizable
      [appLayerResizableHorizontal]="resizeHorizontal"
      [appLayerResizableVertical]="resizeVertical"
      (appLayerResizableResizeStarted)="onResizeStarted()"
      (appLayerResizableResize)="
        applyResize({ top: $event.deltaY, right: $event.deltaX, shift: $event.shift, alt: $event.alt })
      "
      (appLayerResizableResizeFinished)="onResizeFinished()"
      class="view-editor-bounds__handle view-editor-bounds__handle_position_top-right"
    ></div>

    <div
      appLayerResizable
      [appLayerResizableHorizontal]="resizeHorizontal"
      [appLayerResizableVertical]="resizeVertical"
      (appLayerResizableResizeStarted)="onResizeStarted()"
      (appLayerResizableResize)="
        applyResize({ right: $event.deltaX, bottom: $event.deltaY, shift: $event.shift, alt: $event.alt })
      "
      (appLayerResizableResizeFinished)="onResizeFinished()"
      class="view-editor-bounds__handle view-editor-bounds__handle_position_bottom-right"
    ></div>

    <div
      appLayerResizable
      [appLayerResizableHorizontal]="resizeHorizontal"
      [appLayerResizableVertical]="resizeVertical"
      (appLayerResizableResizeStarted)="onResizeStarted()"
      (appLayerResizableResize)="
        applyResize({ bottom: $event.deltaY, left: $event.deltaX, shift: $event.shift, alt: $event.alt })
      "
      (appLayerResizableResizeFinished)="onResizeFinished()"
      class="view-editor-bounds__handle view-editor-bounds__handle_position_bottom-left"
    ></div>
  </ng-container>
</div>

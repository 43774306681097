import { AfterViewInit, Directive, ElementRef, Input, OnDestroy, Optional } from '@angular/core';
import { TweenMax } from 'gsap';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { fromEvent, merge, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { preventDragMouseDownEvent } from '@common/drag-drop2';
import { addClass, isSet, KeyboardEventKeyCode, MouseButton, removeClass } from '@shared';

import { ResizableDirective, ResizeEvent, ResizeFinishedEvent, ResizeType } from '../resizable/resizable.directive';

const RESIZABLE_RESIZING_CLASS = 'app-resizable-resizing';
const RESIZABLE_RESIZING_HORIZONTAL_CLASS = 'app-resizable-resizing-horizontal';
const RESIZABLE_RESIZING_HORIZONTAL_REVERSE_CLASS = 'app-resizable-resizing-horizontal-reverse';
const RESIZABLE_RESIZING_VERTICAL_CLASS = 'app-resizable-resizing-vertical';
const RESIZABLE_RESIZING_VERTICAL_REVERSE_CLASS = 'app-resizable-resizing-vertical-reverse';

@Directive({
  selector: '[appResizableHandle]'
})
export class ResizableHandleDirective implements OnDestroy, AfterViewInit {
  @Input('appResizableHandle') types: ResizeType[] = [];
  @Input('appResizableHandleParent') manualParent: ResizableDirective;
  @Input('appResizableHandleSpeedHorizontal') speedHorizontal = 1;
  @Input('appResizableHandleSpeedVertical') speedVertical = 1;
  @Input('appResizableHandleReverseHorizontal') reverseHorizontal = false;
  @Input('appResizableHandleReverseVertical') reverseVertical = false;
  @Input('appResizableHandleStepHorizontal') stepHorizontal: number;
  @Input('appResizableHandleStepVertical') stepVertical: number;
  @Input('appResizableHandleMinSize') minSize: { width?: number; height?: number } = {};
  @Input('appResizableHandleMaxSize') maxSize: { width?: number; height?: number } = {};
  @Input('appResizableHandleCheckHorizontalBounds') checkHorizontalBounds = true;
  @Input('appResizableHandleCheckVerticalBounds') checkVerticalBounds = false;
  @Input('appResizableHandleBodyClass') bodyClass: string;

  resizing = false;
  altPressed = false;

  constructor(private el: ElementRef, @Optional() private autoParent: ResizableDirective) {}

  ngOnDestroy(): void {
    if (this.resizing) {
      removeClass(document.body, RESIZABLE_RESIZING_CLASS);
      removeClass(document.body, RESIZABLE_RESIZING_HORIZONTAL_CLASS);
      removeClass(document.body, RESIZABLE_RESIZING_HORIZONTAL_REVERSE_CLASS);
      removeClass(document.body, RESIZABLE_RESIZING_VERTICAL_CLASS);
      removeClass(document.body, RESIZABLE_RESIZING_VERTICAL_REVERSE_CLASS);

      if (isSet(this.bodyClass)) {
        removeClass(document.body, this.bodyClass);
      }
    }
  }

  ngAfterViewInit(): void {
    if (!this.resizableElement) {
      return;
    }

    fromEvent<MouseEvent>(this.el.nativeElement, 'mousedown')
      .pipe(
        filter(e => e.button == MouseButton.Main && this.types.length > 0),
        untilDestroyed(this)
      )
      .subscribe(e => {
        this.initResize(e, this.types);
      });

    merge(
      fromEvent<KeyboardEvent>(document, 'keydown').pipe(
        filter(e => e.keyCode == KeyboardEventKeyCode.Alt),
        map(() => true)
      ),
      fromEvent<KeyboardEvent>(document, 'keyup').pipe(
        filter(e => e.keyCode == KeyboardEventKeyCode.Alt),
        map(() => false)
      )
    )
      .pipe(untilDestroyed(this))
      .subscribe(pressed => (this.altPressed = pressed));
  }

  get parent(): ResizableDirective {
    return this.manualParent || this.autoParent;
  }

  get resizableElement(): HTMLElement {
    return this.parent ? this.parent.el.nativeElement : undefined;
  }

  initResize(downEvent: MouseEvent, types: ResizeType[]) {
    downEvent.preventDefault();

    preventDragMouseDownEvent(downEvent);

    const subscriptions: Subscription[] = [];
    const originalWidth = this.resizableElement.offsetWidth;
    const originalHeight = this.resizableElement.offsetHeight;
    let newWidth: number = null;
    let newHeight: number = null;

    this.resizing = true;

    this.parent.resizeStarted.next({
      mouseDownEvent: downEvent
    });

    addClass(document.body, RESIZABLE_RESIZING_CLASS);

    if (this.types.includes(ResizeType.Horizontal)) {
      addClass(
        document.body,
        this.reverseHorizontal ? RESIZABLE_RESIZING_HORIZONTAL_REVERSE_CLASS : RESIZABLE_RESIZING_HORIZONTAL_CLASS
      );
    }

    if (this.types.includes(ResizeType.Vertical)) {
      addClass(
        document.body,
        this.reverseVertical ? RESIZABLE_RESIZING_VERTICAL_REVERSE_CLASS : RESIZABLE_RESIZING_VERTICAL_CLASS
      );
    }

    if (isSet(this.bodyClass)) {
      addClass(document.body, this.bodyClass);
    }

    subscriptions.push(
      fromEvent<MouseEvent>(window.document, 'mousemove')
        .pipe(untilDestroyed(this))
        .subscribe(moveEvent => {
          moveEvent.preventDefault();
          moveEvent.stopPropagation();

          const attrs = {};
          let widthDelta: number;
          let heightDelta: number;

          if (types.includes(ResizeType.Horizontal)) {
            const speed = this.speedHorizontal;
            const reverse = this.reverseHorizontal ? -1 : 1;

            widthDelta = (moveEvent.clientX - downEvent.clientX) * speed * reverse;

            const width = this.clamp(
              this.stepRound(originalWidth + widthDelta, !this.altPressed ? this.stepHorizontal : undefined),
              this.minSize.width,
              this.maxSize.width
            );
            newWidth =
              this.checkHorizontalBounds && width > this.resizableElement.parentElement.offsetWidth ? undefined : width;
            attrs['width'] = newWidth === undefined ? '100%' : newWidth;
          }

          if (types.includes(ResizeType.Vertical)) {
            const speed = this.speedVertical;
            const reverse = this.reverseVertical ? -1 : 1;

            heightDelta = (moveEvent.clientY - downEvent.clientY) * speed * reverse;

            const height = this.clamp(
              this.stepRound(originalHeight + heightDelta, !this.altPressed ? this.stepVertical : undefined),
              this.minSize.height,
              this.maxSize.height
            );
            newHeight =
              this.checkVerticalBounds && height > this.resizableElement.parentElement.offsetHeight
                ? undefined
                : height;
            attrs['height'] = newHeight === undefined ? 'auto' : newHeight;
          }

          if (this.parent && this.parent.resizeElement) {
            TweenMax.set(this.resizableElement, attrs);
          }

          this.parent.resize.next({
            types: types,
            widthDelta: widthDelta,
            heightDelta: heightDelta
          });
        })
    );

    subscriptions.push(
      fromEvent<MouseEvent>(window.document, 'mouseup')
        .pipe(
          filter(e => e.button == MouseButton.Main),
          untilDestroyed(this)
        )
        .subscribe(upEvent => {
          upEvent.preventDefault();
          subscriptions.forEach(item => item.unsubscribe());

          removeClass(document.body, RESIZABLE_RESIZING_CLASS);
          removeClass(document.body, RESIZABLE_RESIZING_HORIZONTAL_CLASS);
          removeClass(document.body, RESIZABLE_RESIZING_HORIZONTAL_REVERSE_CLASS);
          removeClass(document.body, RESIZABLE_RESIZING_VERTICAL_CLASS);
          removeClass(document.body, RESIZABLE_RESIZING_VERTICAL_REVERSE_CLASS);

          if (isSet(this.bodyClass)) {
            removeClass(document.body, this.bodyClass);
          }

          const widthChanged = newWidth !== null;
          const heightChanged = newHeight !== null;
          const event: ResizeFinishedEvent = {
            mouseUpEvent: upEvent,
            types: types,
            widthChanged: widthChanged,
            heightChanged: heightChanged
          };

          if (types.includes(ResizeType.Horizontal) && widthChanged) {
            event['width'] = newWidth;
          }

          if (types.includes(ResizeType.Vertical) && heightChanged) {
            event['height'] = newHeight;
          }

          this.parent.resizeFinished.next(event);

          this.resizing = false;
        })
    );
  }

  clamp(num?: number, min?: number, max?: number) {
    if (typeof num !== 'number') {
      return num;
    }

    if (typeof min === 'number') {
      num = Math.max(num, min);
    }

    if (typeof max === 'number') {
      num = Math.min(num, max);
    }

    return num;
  }

  stepRound(num?: number, step?: number) {
    if (typeof num !== 'number') {
      return num;
    }

    if (typeof step === 'number') {
      const delta = num % step;
      num -= delta;

      if (delta / step >= 0.5) {
        num += step;
      }
    }

    return num;
  }
}

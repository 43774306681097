var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as Sentry from '@sentry/browser';
import { tap } from 'rxjs/operators';
import { AppConfigService } from '@core';
import { ApiService } from '@modules/api';
import { ProjectsStore } from '@modules/projects';
import { CurrentUserStore } from '@modules/users';
import { isSet } from '@shared';
import { AnalyticsEvent, AnalyticsEventAction } from '../../data/event';
import { TagManagerService } from '../tag-manager/tag-manager.service';
import * as i0 from "@angular/core";
import * as i1 from "../tag-manager/tag-manager.service";
import * as i2 from "../../../api/services/api/api.service";
import * as i3 from "../../../users/stores/current-user.store";
import * as i4 from "../../../projects/stores/projects.store";
import * as i5 from "../../../../core/services/app-config/app-config.service";
var UniversalAnalyticsService = /** @class */ (function () {
    function UniversalAnalyticsService(tagManagerService, apiService, currentUserStore, projectsStore, appConfigService) {
        this.tagManagerService = tagManagerService;
        this.apiService = apiService;
        this.currentUserStore = currentUserStore;
        this.projectsStore = projectsStore;
        this.appConfigService = appConfigService;
        this.logApiServiceErrors().subscribe();
    }
    UniversalAnalyticsService.prototype.logApiServiceErrors = function () {
        var _this = this;
        return this.apiService.onError.pipe(tap(function (serverError) {
            _this.sendEvent(AnalyticsEvent.GA.ApiError, AnalyticsEventAction.Emit, serverError.serialize());
        }));
    };
    UniversalAnalyticsService.prototype.logProjectApiServiceErrors = function (projectApiService) {
        var _this = this;
        return projectApiService.onError.pipe(tap(function (serverError) {
            _this.sendEvent(AnalyticsEvent.GA.ProjectApiError, AnalyticsEventAction.Emit, serverError.serialize());
        }));
    };
    UniversalAnalyticsService.prototype.sendEvent = function (category, action, label, params) {
        if (params === void 0) { params = {}; }
        this.tagManagerService.sendEvent('event-to-ga', category, action, label, params);
    };
    UniversalAnalyticsService.prototype.sendSimpleEvent = function (category, params) {
        if (params === void 0) { params = {}; }
        var project = this.projectsStore.current;
        var user = this.currentUserStore.instance;
        var userId = user ? user.uid : undefined;
        var email = user ? user.email : undefined;
        var isStaff = user ? user.isStaff || (isSet(user.email) && user.email.endsWith('@jet.test')) : undefined;
        this.tagManagerService.sendEvent('product-event', category, undefined, undefined, {
            CurrentUserID: userId,
            UserProperties: {
                CurrentUserEmail: email,
                CurrentUserIsStaff: isStaff
            },
            EventProperties: __assign({ CurrentProjectID: project ? project.uniqueName : undefined, CurrentProjectDemo: project && project.demo, CurrentProjectVersion: this.appConfigService.version, AppMode: window['mode'] }, params)
        });
        Sentry.addBreadcrumb({
            category: 'product-event',
            message: category,
            level: Sentry.Severity.Info,
            data: params
        });
    };
    UniversalAnalyticsService.ngInjectableDef = i0.defineInjectable({ factory: function UniversalAnalyticsService_Factory() { return new UniversalAnalyticsService(i0.inject(i1.TagManagerService), i0.inject(i2.ApiService), i0.inject(i3.CurrentUserStore), i0.inject(i4.ProjectsStore), i0.inject(i5.AppConfigService)); }, token: UniversalAnalyticsService, providedIn: "root" });
    return UniversalAnalyticsService;
}());
export { UniversalAnalyticsService };

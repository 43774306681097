import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';

import { BasePopupComponent } from '@common/popups';
import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import {
  CurrentEnvironmentStore,
  CurrentProjectStore,
  Environment,
  isResourceTypeItemCompatible,
  JET_APP_RESOURCE,
  Project,
  Resource,
  ResourceType,
  ResourceTypeItem
} from '@modules/projects';
import { ResourceEditController } from '@modules/projects-components';

@Component({
  selector: 'app-choose-resources',
  templateUrl: './choose-resources.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChooseResourcesComponent implements OnInit, OnDestroy {
  @Input() project: Project;
  @Input() environment: Environment;
  @Input() selectResources: { type: ResourceType; typeItem: ResourceTypeItem; token: string }[] = [];
  @Input() demoAvailable = false;
  @Input() analyticsSource: string;
  @Output() selected = new EventEmitter<{ token: string; resource: Resource }[]>();
  @Output() demoSelected = new EventEmitter<void>();
  @Output() cancelled = new EventEmitter<void>();

  resources: Resource[] = [];
  selectedResources: {
    type: ResourceType;
    typeItem: ResourceTypeItem;
    token: string;
    resource: Resource;
    created?: boolean;
  }[] = [];

  constructor(
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private popupComponent: BasePopupComponent,
    private resourceEditController: ResourceEditController,
    private analyticsService: UniversalAnalyticsService,
    private injector: Injector,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.currentEnvironmentStore.resources$.pipe(untilDestroyed(this)).subscribe(resources => {
      this.resources = resources.filter(item => !item.demo && item.uniqueName != JET_APP_RESOURCE) || [];
      this.selectedResources = this.selectResources
        // .filter(item => !item.options)
        .reduce((acc, current) => {
          if (acc.find(item => item.name == current.token)) {
            return;
          }

          const existingItem = this.selectedResources.find(item => item.token == current.token);

          acc.push({
            ...current,
            resource: existingItem ? existingItem.resource : undefined
          });

          return acc;
        }, []);
      this.cd.markForCheck();
    });

    this.analyticsService.sendSimpleEvent(AnalyticsEvent.Template.StartedToChooseResources, {
      Source: this.analyticsSource,
      ResourceID: this.selectResources.length ? this.selectResources[0].typeItem.name : undefined,
      ResourceIDs: this.selectResources.map(item => item.typeItem.name).join(','),
      MultipleResources: this.selectResources.length > 1
    });
  }

  ngOnDestroy(): void {}

  isValid() {
    return this.selectedResources.every(item => item.resource != undefined);
  }

  resourcesForItem(typeItem: ResourceTypeItem) {
    return this.resources
      .filter(item => isResourceTypeItemCompatible(item.typeItem, typeItem))
      .filter(item => !this.demoAvailable || (this.demoAvailable && !item.demo));
  }

  openResourceSettings(index: number, resource?: Resource) {
    const typeItem = this.selectedResources[index].typeItem;

    if (!resource) {
      this.analyticsService.sendSimpleEvent(AnalyticsEvent.Resource.ClickedCreateResource, {
        Source: this.analyticsSource,
        ResourceID: typeItem.name
      });
    }

    this.resourceEditController
      .openEditPopup(typeItem, resource, {
        resourceNameEditing: true,
        analyticsSource: this.analyticsSource
      })
      .pipe(untilDestroyed(this))
      .subscribe(result => {
        if (result.resource) {
          this.selectExistingResource(index, result.resource, result.created);

          if (this.selectedResources.length == 1) {
            this.submit();
          }
        }
      });
  }

  selectExistingResource(index: number, savedResource: Resource, created = false) {
    this.currentEnvironmentStore.resources$.pipe(untilDestroyed(this)).subscribe(resources => {
      const resource = resources ? resources.find(item => item.uniqueName == savedResource.uniqueName) : undefined;
      this.selectedResources = this.selectedResources.map((item, i) => {
        if (i == index) {
          item.resource = resource;
          item.created = created;
        }
        return item;
      });
      this.cd.markForCheck();
    });
  }

  submit() {
    this.popupComponent.close();

    this.analyticsService.sendSimpleEvent(AnalyticsEvent.Template.ResourcesChooseCompleted, {
      Source: this.analyticsSource,
      ResourceID: this.selectResources.length ? this.selectResources[0].typeItem.name : undefined,
      ResourceIDs: this.selectResources.map(item => item.typeItem.name).join(','),
      MultipleResources: this.selectResources.length > 1
    });

    this.selected.emit(this.selectedResources);
  }

  useDemo() {
    this.demoSelected.emit();
    this.popupComponent.close();
  }

  cancel() {
    this.cancelled.emit();
    this.popupComponent.close();
  }
}

<div class="section-tabs">
  <app-section-tab
    *ngFor="let item of tabComponents"
    [label]="item.label"
    [link]="item.link"
    [icon]="item.icon"
    [active]="item.active"
    (selected)="item.selected.emit()"
    class="section-tabs__item"
  ></app-section-tab>
</div>

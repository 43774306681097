/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../../../shared/pipes/is-color-hex/is-color-hex.pipe";
import * as i3 from "./poster-layout.component";
var styles_PosterLayoutComponent = [];
var RenderType_PosterLayoutComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_PosterLayoutComponent, data: {} });
export { RenderType_PosterLayoutComponent as RenderType_PosterLayoutComponent };
function View_PosterLayoutComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, ":svg:circle", [["cx", "400"], ["cy", "89"], ["fill", "#C8E2F2"], ["id", "Oval"], ["r", "307"]], [[4, "fill", null]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.backgroundContrastColor; _ck(_v, 0, 0, currVal_0); }); }
function View_PosterLayoutComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, ":svg:circle", [["cx", "-9.5"], ["cy", "685.5"], ["fill", "#C8E2F2"], ["id", "Oval"], ["r", "185.5"]], [[4, "fill", null]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.backgroundContrastColor; _ck(_v, 0, 0, currVal_0); }); }
function View_PosterLayoutComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, ":svg:svg", [[":xmlns:xlink", "http://www.w3.org/1999/xlink"], ["height", "705px"], ["preserveAspectRatio", "xMidYMin slice"], ["style", "width: 100%; height: 100%;"], ["version", "1.1"], ["viewBox", "0 0 480 705"], ["width", "480px"], ["xmlns", "http://www.w3.org/2000/svg"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 7, ":svg:g", [["fill", "none"], ["fill-rule", "evenodd"], ["id", "Page-1"], ["stroke", "none"], ["stroke-width", "1"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 6, ":svg:g", [["id", "Dashboard---Share-Your-Template"], ["transform", "translate(-225.000000, -98.000000)"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 5, ":svg:g", [["id", "Image"], ["transform", "translate(225.000000, 98.000000)"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 4, ":svg:g", [["id", "background"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PosterLayoutComponent_2)), i0.ɵdid(6, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PosterLayoutComponent_3)), i0.ɵdid(8, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.backgroundContrastColor; _ck(_v, 6, 0, currVal_0); var currVal_1 = _co.backgroundContrastColor; _ck(_v, 8, 0, currVal_1); }, null); }
export function View_PosterLayoutComponent_0(_l) { return i0.ɵvid(2, [i0.ɵpid(0, i2.IsColorHexPipe, []), (_l()(), i0.ɵeld(1, 0, null, null, 10, "div", [["class", "poster-layout"]], [[2, "poster-layout_left", null], [2, "poster-layout_fill-screen", null], [2, "poster-layout_preview", null]], null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 5, "div", [["class", "poster-layout__poster"]], [[4, "background-image", null], [4, "background-color", null]], null, null, null, null)), i0.ɵdid(3, 278528, null, 0, i1.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵppd(4, 1), i0.ɵppd(5, 1), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PosterLayoutComponent_1)), i0.ɵdid(7, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(8, 0, null, null, 3, "div", [["class", "poster-layout__content-container"]], null, null, null, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 2, "div", [["class", "poster-layout__content"]], null, null, null, null, null)), i0.ɵncd(null, 0), i0.ɵncd(null, 1)], function (_ck, _v) { var _co = _v.component; var currVal_5 = "poster-layout__poster"; var currVal_6 = ((_co.backgroundColor && !i0.ɵunv(_v, 3, 1, _ck(_v, 4, 0, i0.ɵnov(_v, 0), _co.backgroundColor))) ? (("background-color_" + _co.backgroundColor) + "_1") : ""); _ck(_v, 3, 0, currVal_5, currVal_6); var currVal_7 = !_co.backgroundImage; _ck(_v, 7, 0, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.left; var currVal_1 = _co.fillScreen; var currVal_2 = _co.preview; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = (_co.backgroundImage ? (("url(" + _co.backgroundImage) + ")") : ""); var currVal_4 = (i0.ɵunv(_v, 2, 1, _ck(_v, 5, 0, i0.ɵnov(_v, 0), _co.backgroundColor)) ? _co.backgroundColor : null); _ck(_v, 2, 0, currVal_3, currVal_4); }); }
export function View_PosterLayoutComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-poster-layout", [], null, null, null, View_PosterLayoutComponent_0, RenderType_PosterLayoutComponent)), i0.ɵdid(1, 638976, null, 0, i3.PosterLayoutComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PosterLayoutComponentNgFactory = i0.ɵccf("app-poster-layout", i3.PosterLayoutComponent, View_PosterLayoutComponent_Host_0, { left: "left", backgroundColor: "backgroundColor", backgroundImage: "backgroundImage", fillScreen: "fillScreen", preview: "preview" }, {}, ["*", "[data-layout-footer]"]);
export { PosterLayoutComponentNgFactory as PosterLayoutComponentNgFactory };

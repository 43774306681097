import isArray from 'lodash/isArray';

import { exactFieldLookup, inFieldLookup, isNullFieldLookup } from '@modules/field-lookups';

import { BaseField } from '../base-field';
import { FieldType } from '../field-type';

export const multipleSelectAirtableFormulas = [
  {
    field: FieldType.MultipleSelect,
    lookup: exactFieldLookup,
    formula: (lookupValue: any, field?: BaseField): string => {
      if (lookupValue === undefined) {
        return;
      }

      if (isArray(lookupValue)) {
        const predicates = lookupValue.map(item => `SEARCH("${String(item).toLowerCase()}", LOWER({${field.name}}))`);
        return `AND(${predicates.join(',')})`;
      } else {
        return `SEARCH("${String(lookupValue).toLowerCase()}", LOWER({${field.name}}))`;
      }
    }
  },
  {
    field: FieldType.MultipleSelect,
    lookup: isNullFieldLookup,
    formula: (lookupValue: boolean, field?: BaseField): string => {
      // return lookupValue ? fieldValue === null : fieldValue !== null;
      return;
    }
  },
  {
    field: FieldType.MultipleSelect,
    lookup: inFieldLookup,
    formula: (lookupValue: any[], field?: BaseField): string => {
      if (
        lookupValue === undefined ||
        (lookupValue as unknown) === '' ||
        (isArray(lookupValue) && !lookupValue.length)
      ) {
        return;
      }

      if (!isArray(lookupValue)) {
        lookupValue = [lookupValue];
      }

      const predicates = lookupValue.map(item => `SEARCH("${String(item).toLowerCase()}", LOWER({${field.name}}))`);
      return `OR(${predicates.join(',')})`;
    }
  }
];

<app-popup2 [size]="'l'" [maxWidth]="1150">
  <div class="choose-template choose-template_flexible" [class.choose-template_popup]="false">
    <div class="choose-template__header">
      <div class="choose-template__detail-title">
        <a href="javascript:void(0)" (click)="backToTypeClick.emit()">Page Layout</a>
        <span class="choose-template__detail-title-separator icon-arrow_forward_2"></span>
        <a href="javascript:void(0)" (click)="backToModelClick.emit()">Collection</a>

        <ng-container *ngFor="let item of steps.slice(0, currentStepIndex + 1); let i = index">
          <span class="choose-template__detail-title-separator icon-arrow_forward_2"></span>

          <ng-container *ngIf="i < currentStepIndex">
            <a href="javascript:void(0)" (click)="setCurrentStepIndex(i)">{{ item.label }}</a>
          </ng-container>

          <ng-container *ngIf="i === currentStepIndex"> Choose {{ item.label }} </ng-container>
        </ng-container>
      </div>

      <a
        *ngIf="cancelEnabled"
        href="javascript:void(0)"
        class="choose-template__header-close icon-close"
        (click)="cancelClick.emit()"
      ></a>
    </div>

    <div class="choose-template__content">
      <div class="choose-template__content-navigation choose-template__content-navigation_pages-preview">
        <div class="choose-template__content-navigation-inner">
          <ng-container *ngIf="!previewLoading">
            <!--            <div class="choose-template__content-navigation-header">-->
            <!--              <div class="choose-template__content-navigation-title">Choose Fields</div>-->
            <!--            </div>-->

            <ng-container *ngIf="currentStep?.fieldsControl">
              <div *ngIf="resource" class="choose-template__content-navigation-header">
                <div
                  class="choose-template-list-item choose-template-list-item_contrast choose-template-list-item_interactive"
                >
                  <div class="choose-template-list-item__left">
                    <span
                      class="choose-template-list-item__icon"
                      [style.background-image]="
                        '/assets/images/resources/icons/' + resource.typeItem.icon + '.svg' | appDeployCssUrl
                      "
                    ></span>
                  </div>

                  <div class="choose-template-list-item__main">
                    <div class="choose-template-list-item__title">{{ modelDescription.verboseName }} fields</div>
                    <div class="choose-template-list-item__subtitle">{{ resource.name }}</div>
                  </div>
                </div>
              </div>

              <div class="choose-template__content-navigation-list">
                <app-page-templates-item-settings-fields
                  [control]="currentStep.fieldsControl"
                ></app-page-templates-item-settings-fields>
              </div>
            </ng-container>
          </ng-container>

          <ng-container *ngIf="previewLoading">
            <div class="choose-template__content-navigation-list">
              <div class="choose-template-list">
                <div
                  *ngFor="
                    let item of ['long item', 'longasdads item', 'longasdads item', 'medium', 'long item', 'item']
                  "
                  class="choose-template-list__item"
                >
                  <div class="choose-template-list-item">
                    <div class="choose-template-list-item__main">
                      <span [class.loading-animation]="true">
                        <span class="stub-text">{{ item }}</span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>

      <div
        class="choose-template__content-main"
        [class.choose-template__content-main_background-dark]="true"
        [class.choose-template__content-main_clip]="true"
        [class.choose-template__content-main_relative]="true"
      >
        <app-page-template-preview-stub *ngIf="previewLoading"></app-page-template-preview-stub>

        <ng-container *ngIf="!previewLoading">
          <app-page-template-preview-custom *ngIf="!startPage"></app-page-template-preview-custom>
          <app-page-template-preview
            *ngIf="startPage"
            [startPage]="startPage"
            [otherPages]="otherPages"
            [modelDescription]="modelDescription"
            [previewFocus]="!previewInit && !previewFit ? currentStep?.previewFocus : undefined"
          ></app-page-template-preview>

          <div
            class="choose-template__detail-button"
            [class.choose-template__detail-button_active]="previewFit"
            [appTip]="'Show all'"
            [appTipOptions]="{ side: 'top' }"
            (click)="togglePreviewFit()"
          >
            <span *ngIf="!previewFit" class="icon-align_vertical_center" style="transform: rotate(45deg);"></span>
            <span *ngIf="previewFit" class="icon-resize_vertical" style="transform: rotate(45deg);"></span>
          </div>
        </ng-container>
      </div>
    </div>

    <div class="choose-template__footer">
      <app-page-block>
        <app-stack [align]="'right'">
          <button
            *ngIf="currentStepIndex == 0"
            type="button"
            class="button button_orange popup2__footer-item"
            (click)="backToTypeClick.emit()"
          >
            <span class="button__label">Back</span>
          </button>

          <button
            *ngIf="currentStepIndex > 0"
            type="button"
            class="button button_orange popup2__footer-item"
            (click)="prevStep()"
          >
            <span class="button__label">Back</span>
          </button>

          <button
            *ngIf="currentStepIndex < steps.length - 1"
            type="button"
            class="button button_primary button_orange popup2__footer-item"
            [class.button_disabled]="form.invalid || loadingSubmit"
            (click)="nextStep()"
          >
            <span class="button__label">Continue</span>
          </button>

          <button
            *ngIf="currentStepIndex == steps.length - 1"
            type="button"
            class="button button_primary button_orange popup2__footer-item"
            [class.button_disabled]="form.invalid || loadingSubmit"
            (click)="nextStep()"
          >
            <app-loader-small *ngIf="loadingSubmit" class="button__icon button__icon_left"></app-loader-small>
            <span class="button__label">Apply Template</span>
          </button>
        </app-stack>
      </app-page-block>
    </div>
  </div>
</app-popup2>

import values from 'lodash/values';

import { AggregateFunc } from '@modules/charts';
import { AVERAGE, MAX, MIN, SUM } from '@modules/fields';

import { ModelResponse } from '../data/model-response';

export function aggregateGetResponse(
  response: ModelResponse.GetResponse,
  yFunc: AggregateFunc,
  yColumn: string,
  frontendFiltering = true
): number {
  if (!yFunc) {
    if (!response.results.length) {
      return;
    }

    const model = response.results[0];
    return model.noAttributesKey ? model.getData() : model.getAttribute(yColumn);
  }

  if (frontendFiltering) {
    if (yFunc == AggregateFunc.Count) {
      return response.results.length;
    } else if (yFunc == AggregateFunc.Sum && yColumn) {
      return SUM(...response.results.map(item => parseFloat(item.getAttribute(yColumn))));
    } else if (yFunc == AggregateFunc.Min && yColumn) {
      return MIN(...response.results.map(item => parseFloat(item.getAttribute(yColumn))));
    } else if (yFunc == AggregateFunc.Max && yColumn) {
      return MAX(...response.results.map(item => parseFloat(item.getAttribute(yColumn))));
    } else if (yFunc == AggregateFunc.Avg && yColumn) {
      return AVERAGE(...response.results.map(item => parseFloat(item.getAttribute(yColumn))));
    }
  } else {
    if (!response.results[0]) {
      return;
    }

    const attributes = response.results[0].getRawAttributes();
    return values(attributes)[0] as number;
  }
}

<div class="guide-popup__header">
  <ng-container *ngIf="!loading">
    <div *ngIf="title" class="guide-popup__header-title">
      <a *ngIf="history.length > 1" href="javascript:void(0)" (click)="back()">
        <span class="icon icon-arrow_backward_2 guide-popup__header-back-icon"></span>
      </a>
      <span class="guide-popup__header-title-text" [title]="title">{{ title }}</span>
      <a
        *ngIf="pageUrl"
        [href]="pageUrl"
        class="guide-popup__header-action icon-external_link"
        target="_blank"
        [appTip]="'Open in new Tab'"
        [appTipOptions]="{ side: 'bottom' }"
      ></a>
    </div>
    <a *ngIf="!title" href="javascript:void(0)" class="guide-popup__header-back" (click)="back()">
      <span class="icon icon-arrow_backward_2 guide-popup__header-back-icon"></span>
      Back<ng-container *ngIf="pageTitle"> to {{ pageTitle }}</ng-container>
    </a>
  </ng-container>

  <ng-container *ngIf="loading">
    <div class="guide-popup__header-title">
      <span [class.loading-animation]="loading"
        ><span class="stub-text">
          Page title
        </span></span
      >
    </div>
  </ng-container>

  <a href="javascript:void(0)" class="guide-popup__header-close icon icon-close" (click)="close.next()"></a>
</div>

<div class="guide-popup__content" #popup_scroll_element>
  <div *ngIf="description" class="guide-popup__description">{{ description }}</div>
  <div [innerHTML]="content" #popup_content_element></div>

  <ng-container *ngIf="loading">
    <h2>
      <span [class.loading-animation]="loading"
        ><span class="stub-text">
          Loading
        </span></span
      >
    </h2>

    <p>
      <span [class.loading-animation]="loading"
        ><span class="stub-text">
          We are loading documentation, please, wait..
        </span></span
      >
    </p>

    <h2>
      <span [class.loading-animation]="loading"
        ><span class="stub-text">
          Second documentation
        </span></span
      >
    </h2>

    <p>
      <span [class.loading-animation]="loading"
        ><span class="stub-text">
          We are loading documentation
        </span></span
      >
    </p>

    <p>
      <span [class.loading-animation]="loading"
        ><span class="stub-text">
          please, wait..
        </span></span
      >
    </p>
  </ng-container>

  <app-error *ngIf="error" [title]="error" [fill]="true" [compact]="true">
    Failed to load requested documentation, <br />try find what you need in our
    <a href="https://docs.jetadmin.io/" target="_blank">Documentation</a>
  </app-error>
</div>

<div *ngIf="pageUrl" class="guide-popup__footer">
  <div class="guide-popup-rate">
    <div class="guide-popup-rate__label">Was this information helpful?</div>
    <div class="guide-popup-rate__options">
      <div
        class="guide-popup-rate__option guide-popup-rate__option_red"
        [class.guide-popup-rate__option_active]="rate == rates.Poor"
        [appTip]="'Poor content'"
        [appTipOptions]="{ side: 'top' }"
        [appClickEvent]="[analyticsEvents.Documentation.Rated, { URL: pageUrl, Rate: rates.Poor }]"
        (click)="setRate(rates.Poor)"
      >
        <svg viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
          <path
            fill="currentColor"
            d="M9.707 8.707a.993.993 0 00.006-1.396 1.007 1.007 0 00-1.408-.03C8.273 7.312 7.519 8 6 8c-1.497 0-2.251-.67-2.303-.717a1 1 0 00-1.404 1.424C2.425 8.839 3.653 10 6 10c2.347 0 3.575-1.161 3.707-1.293m12 0a.993.993 0 00.006-1.396 1.006 1.006 0 00-1.408-.03C20.273 7.312 19.519 8 18 8c-1.497 0-2.251-.67-2.303-.717a1 1 0 00-1.404 1.424C14.425 8.839 15.653 10 18 10c2.347 0 3.575-1.161 3.707-1.293M21.001 19a1 1 0 01-.896-.553C20.036 18.314 18.225 15 12 15c-6.225 0-8.036 3.314-8.11 3.456a1.002 1.002 0 01-1.344.43.997.997 0 01-.441-1.333C2.198 17.367 4.469 13 12 13s9.802 4.367 9.895 4.553A1.001 1.001 0 0121.001 19"
            fill-rule="evenodd"
          ></path>
        </svg>
      </div>
      <div
        class="guide-popup-rate__option guide-popup-rate__option_yellow"
        [class.guide-popup-rate__option_active]="rate == rates.OK"
        [appTip]="'OK content'"
        [appTipOptions]="{ side: 'top' }"
        [appClickEvent]="[analyticsEvents.Documentation.Rated, { URL: pageUrl, Rate: rates.OK }]"
        (click)="setRate(rates.OK)"
      >
        <svg viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
          <path
            fill="currentColor"
            d="M10 8a1 1 0 00-1-1H3a1 1 0 100 2h6a1 1 0 001-1m12 0a1 1 0 00-1-1h-6a1 1 0 100 2h6a1 1 0 001-1m-1 9H3a1 1 0 110-2h18a1 1 0 110 2"
            fill-rule="evenodd"
          ></path>
        </svg>
      </div>
      <div
        class="guide-popup-rate__option guide-popup-rate__option_green"
        [class.guide-popup-rate__option_active]="rate == rates.Excellent"
        [appTip]="'Excellent content'"
        [appTipOptions]="{ side: 'top' }"
        (click)="setRate(rates.Excellent)"
        [appClickEvent]="[analyticsEvents.Documentation.Rated, { URL: pageUrl, Rate: rates.Excellent }]"
      >
        <svg viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet">
          <path
            fill="currentColor"
            d="M9.707 8.707a.999.999 0 000-1.414C9.575 7.161 8.347 6 6 6 3.653 6 2.425 7.161 2.293 7.293a.992.992 0 00-.005 1.396 1.007 1.007 0 001.408.029C3.727 8.689 4.481 8 6 8c1.52 0 2.273.689 2.293.707a.997.997 0 001.414 0m12 0a.999.999 0 000-1.414C21.575 7.161 20.347 6 18 6c-2.347 0-3.575 1.161-3.707 1.293a.992.992 0 00-.005 1.396 1.006 1.006 0 001.407.029C15.727 8.689 16.481 8 18 8c1.52 0 2.273.689 2.293.707a.997.997 0 001.414 0M12 19c-7.53 0-9.8-4.367-9.894-4.553a1.001 1.001 0 011.786-.902C3.974 13.704 5.792 17 12 17c6.226 0 8.037-3.314 8.111-3.456a1.007 1.007 0 011.344-.43.998.998 0 01.441 1.333C21.802 14.633 19.531 19 12 19"
            fill-rule="evenodd"
          ></path>
        </svg>
      </div>
    </div>
  </div>
</div>

import { Inject, Injectable } from '@angular/core';
import { FormControl, ValidatorFn, Validators } from '@angular/forms';
import { combineLatest, Observable, of, throwError } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { AppFormGroup, FormUtils } from '@common/form-utils';
import { AdminMode, ROUTE_ADMIN_MODE } from '@modules/admin-mode';
import { ModelDescriptionStore } from '@modules/model-queries';
import { ModelDescription } from '@modules/models';
import { ProjectSettingsService } from '@modules/project-settings';
import { CurrentEnvironmentStore, CurrentProjectStore, Resource, SecretTokenService } from '@modules/projects';
import {
  googleDriveFileUidParam,
  GoogleSheetsGeneratorService,
  GoogleSheetsParamsOptionsFile
} from '@modules/resource-generators';
import { ResourceParamsResult } from '@modules/resources';

import { GoogleSheetsFileControl } from '../../resource-settings/google-sheets-resource-settings/google-sheets-resource-settings.form';

export const validateExisting: ValidatorFn = control => {
  if (!control.parent) {
    return;
  }

  const fileControl = control.parent as GoogleSheetsFileControl;

  if (!fileControl.parent) {
    return;
  }

  const parent = fileControl.parent as GoogleSheetsImportFileForm;
  const uniqueName = parent.googleSheetsGeneratorService.getUniqueName(control.value);
  const files = parent.controls.files.value as GoogleSheetsParamsOptionsFile[];

  if (
    uniqueName &&
    files.find(item => parent.googleSheetsGeneratorService.getUniqueName(item.verbose_name) == uniqueName)
  ) {
    return { local: ['File with such name already added'] };
  }
};

@Injectable()
export class GoogleSheetsImportFileForm extends AppFormGroup {
  resource: Resource;

  controls: {
    access_token: FormControl;
    params: FormControl;
    files: FormControl;
    file: GoogleSheetsFileControl;
  };

  constructor(
    @Inject(ROUTE_ADMIN_MODE) private mode: AdminMode,
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    public googleSheetsGeneratorService: GoogleSheetsGeneratorService,
    private secretTokenService: SecretTokenService,
    private formUtils: FormUtils,
    private projectSettingsService: ProjectSettingsService,
    private modelDescriptionStore: ModelDescriptionStore
  ) {
    super({
      access_token: new FormControl(null, Validators.required),
      params: new FormControl(null, Validators.required),
      files: new FormControl(null, Validators.required),
      file: new GoogleSheetsFileControl({ verboseNameValidator: validateExisting })
    });
  }

  init(resource: Resource): Observable<boolean> {
    this.resource = resource;
    return this.initValue(this.resource);
  }

  initValue(resource: Resource): Observable<boolean> {
    const project = this.currentProjectStore.instance;
    const environment = this.currentEnvironmentStore.instance;

    return combineLatest(
      this.googleSheetsGeneratorService.getParamsOptions(project, environment, resource),
      this.secretTokenService.getDetail(
        project.uniqueName,
        environment.uniqueName,
        resource.uniqueName,
        this.googleSheetsGeneratorService.tokenName,
        this.mode == AdminMode.Builder
      )
    ).pipe(
      tap(([options, secretToken]) => {
        this.controls.access_token.patchValue(options.access_token);
        this.controls.params.patchValue(options.token_params);
        this.controls.files.patchValue(options.files);
      }),
      map(() => true)
    );
  }

  getParams(): ResourceParamsResult | Observable<ResourceParamsResult> {
    return this.googleSheetsGeneratorService.generateParams(
      this.currentProjectStore.instance,
      this.currentEnvironmentStore.instance,
      this.resource.typeItem,
      {
        access_token: this.controls.access_token.value,
        token_params: this.controls.params.value,
        files: [...this.controls.files.value, this.controls.file.value]
      }
    );
  }

  submit(): Observable<ModelDescription> {
    this.clearServerErrors();

    const params = this.getParams();
    const obs: Observable<ResourceParamsResult> = params instanceof Observable ? params : of(params);

    return obs.pipe(
      switchMap(result => {
        const saveParams: ResourceParamsResult = {
          resourceParams: result.resourceParams,
          modelDescriptions: result.modelDescriptions,
          resourceModelDescriptions: result.resourceModelDescriptions,
          actionDescriptions: result.actionDescriptions,
          secretTokens: result.secretTokens,
          storages: result.storages,
          extraTokens: result.extraTokens
        };

        return this.projectSettingsService.saveResource(
          this.currentProjectStore.instance,
          this.currentEnvironmentStore.instance,
          this.resource,
          !this.resource,
          { ...saveParams, mergeExisting: true }
        );
      }),
      switchMap(() => {
        return this.modelDescriptionStore.getFirst();
      }),
      map(modelDescriptions => {
        const file = this.controls.file.value as GoogleSheetsParamsOptionsFile;
        return modelDescriptions.find(
          item => item.resource == this.resource.uniqueName && item.params[googleDriveFileUidParam] == file.uid
        );
      }),
      catchError(error => {
        console.error(error);
        this.formUtils.showFormErrors(this, error);
        return throwError(error);
      })
    );
  }
}

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../../../../../shared/pipes/capitalize/capitalize.pipe";
import * as i3 from "@angular/cdk/overlay";
import * as i4 from "@angular/cdk/bidi";
import * as i5 from "./interactions-section-cursor-dropdown.component";
var styles_InteractionsSectionCursorDropdownComponent = [];
var RenderType_InteractionsSectionCursorDropdownComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_InteractionsSectionCursorDropdownComponent, data: {} });
export { RenderType_InteractionsSectionCursorDropdownComponent as RenderType_InteractionsSectionCursorDropdownComponent };
function View_InteractionsSectionCursorDropdownComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "div", [["class", "compact-select-options__item compact-select-option"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        _co.selected.emit(_v.context.$implicit);
        _co.setDropdownOpened(false);
        var pd_0 = (_co.close.emit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "div", [["class", "compact-select-option__left"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 0, "div", [["class", "compact-select-option__checked compact-select-option__item"]], [[2, "icon-check_2", null]], null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 4, "div", [["class", "compact-select-option__main"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 3, "div", [["class", "compact-select-option__row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 2, "div", [["class", "compact-select-option__title"]], null, null, null, null, null)), (_l()(), i0.ɵted(6, null, ["", ""])), i0.ɵppd(7, 1)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.value == _v.context.$implicit); _ck(_v, 2, 0, currVal_0); var currVal_1 = i0.ɵunv(_v, 6, 0, _ck(_v, 7, 0, i0.ɵnov(_v.parent.parent, 0), _v.context.$implicit)); _ck(_v, 6, 0, currVal_1); }); }
function View_InteractionsSectionCursorDropdownComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "compact-select-options compact-select-options_min-width_sm"]], null, [[null, "mouseenter"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mouseenter" === en)) {
        var pd_0 = (_co.dropdownHover$.next(true) !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = (_co.dropdownHover$.next(false) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_InteractionsSectionCursorDropdownComponent_2)), i0.ɵdid(2, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.cursors; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_InteractionsSectionCursorDropdownComponent_0(_l) { return i0.ɵvid(2, [i0.ɵpid(0, i2.CapitalizePipe, []), (_l()(), i0.ɵand(16777216, null, null, 2, function (_v, en, $event) { var ad = true; var _co = _v.component; if (("backdropClick" === en)) {
        _co.setDropdownOpened(false);
        var pd_0 = (_co.close.emit() !== false);
        ad = (pd_0 && ad);
    } if (("detach" === en)) {
        _co.setDropdownOpened(false);
        var pd_1 = (_co.close.emit() !== false);
        ad = (pd_1 && ad);
    } return ad; }, View_InteractionsSectionCursorDropdownComponent_1)), i0.ɵdid(2, 671744, null, 0, i3.CdkConnectedOverlay, [i3.Overlay, i0.TemplateRef, i0.ViewContainerRef, i3.ɵc, [2, i4.Directionality]], { origin: [0, "origin"], positions: [1, "positions"], backdropClass: [2, "backdropClass"], panelClass: [3, "panelClass"], open: [4, "open"], hasBackdrop: [5, "hasBackdrop"], growAfterOpen: [6, "growAfterOpen"], push: [7, "push"] }, { backdropClick: "backdropClick", detach: "detach" }), i0.ɵpad(3, 2)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.origin; var currVal_1 = (_co.openOnFocus ? _co.horizontalDropdownPositions : _co.verticalDropdownPositions); var currVal_2 = "popover2-backdrop"; var currVal_3 = _ck(_v, 3, 0, "overlay", "overlay_horizontal_center"); var currVal_4 = _co.dropdownOpened; var currVal_5 = !_co.openOnFocus; var currVal_6 = true; var currVal_7 = true; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }, null); }
export function View_InteractionsSectionCursorDropdownComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-interactions-section-cursor-dropdown", [], null, null, null, View_InteractionsSectionCursorDropdownComponent_0, RenderType_InteractionsSectionCursorDropdownComponent)), i0.ɵdid(1, 770048, null, 0, i5.InteractionsSectionCursorDropdownComponent, [i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var InteractionsSectionCursorDropdownComponentNgFactory = i0.ɵccf("app-interactions-section-cursor-dropdown", i5.InteractionsSectionCursorDropdownComponent, View_InteractionsSectionCursorDropdownComponent_Host_0, { value: "value", origin: "origin", openOnFocus: "openOnFocus", forceOpened: "forceOpened" }, { selected: "selected", close: "close" }, []);
export { InteractionsSectionCursorDropdownComponentNgFactory as InteractionsSectionCursorDropdownComponentNgFactory };

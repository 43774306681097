import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { ViewContext, ViewContextElement } from '@modules/customize';
import { applyBooleanInput$, applyParamInput$, Input } from '@modules/fields';
import { generateAlphanumeric, isSet } from '@shared';

import { Color } from './color';
import { Frame } from './frame';
import { Gradient } from './gradient';
import { IconFill } from './icon-fill';
import { ImageFill } from './image-fill';

export enum FillType {
  Color = 'color',
  Gradient = 'gradient',
  Image = 'image',
  Icon = 'icon'
}

export class Fill {
  id: string;
  type: FillType = FillType.Color;
  color: Color;
  colorInput: Input;
  gradient: Gradient;
  imageFill: ImageFill;
  iconFill: IconFill;
  opacity = 1;
  enabled = true;
  enabledInput: Input;

  constructor(options: Partial<Fill> = {}) {
    Object.assign(this, options);
  }

  deserialize(data: Object): this {
    this.id = data['id'];
    this.opacity = data['opacity'];

    if (data['type']) {
      this.type = data['type'];
    }

    if (data['color']) {
      this.color = new Color().deserialize(data['color']);
    }

    if (data['color_input']) {
      this.colorInput = new Input().deserialize(data['color_input']);
    }

    if (data['gradient']) {
      this.gradient = new Gradient().deserialize(data['gradient']);
    }

    if (data['image_fill']) {
      this.imageFill = new ImageFill().deserialize(data['image_fill']);
    }

    if (data['icon_fill']) {
      this.iconFill = new IconFill().deserialize(data['icon_fill']);
    }

    if (isSet(data['enabled'])) {
      this.enabled = data['enabled'];
    }

    if (data['enabled_input']) {
      this.enabledInput = new Input().deserialize(data['enabled_input']);
    }

    if (!this.id) {
      this.generateId();
    }

    return this;
  }

  serialize(): Object {
    return {
      id: this.id,
      type: this.type,
      color: this.color ? this.color.serialize() : undefined,
      color_input: this.colorInput ? this.colorInput.serialize() : null,
      gradient: this.gradient ? this.gradient.serialize() : undefined,
      image_fill: this.imageFill ? this.imageFill.serialize() : undefined,
      icon_fill: this.iconFill ? this.iconFill.serialize() : undefined,
      opacity: this.opacity,
      enabled: this.enabled,
      enabled_input: this.enabledInput ? this.enabledInput.serialize() : null
    };
  }

  generateId() {
    this.id = generateAlphanumeric(4, { letterFirst: true });
  }

  css$(
    options: {
      frame?: Frame;
      context?: ViewContext;
      contextElement?: ViewContextElement;
      localContext?: Object;
    } = {}
  ): Observable<{ background?: string; width?: string; height?: string; transform?: string }> {
    options = {
      frame: new Frame({ width: 1, height: 1 }),
      ...options
    };

    if (this.type == FillType.Color && this.colorInput) {
      return applyParamInput$(this.colorInput, {
        context: options.context,
        contextElement: options.contextElement,
        localContext: options.localContext,
        defaultValue: ''
      }).pipe(map(value => ({ background: value })));
    } else if (this.type == FillType.Color && this.color) {
      return of({
        background: this.color.css()
      });
    } else if (this.type == FillType.Gradient && this.gradient) {
      return this.gradient.css$({
        frame: options.frame,
        context: options.context,
        contextElement: options.contextElement,
        localContext: options.localContext
      });
    } else if (this.type == FillType.Image && this.imageFill) {
      return this.imageFill.cssBackground$(options).pipe(
        map(background => {
          return {
            background: background
          };
        })
      );
    }

    return of({});
  }

  enabled$(
    options: {
      context?: ViewContext;
      contextElement?: ViewContextElement;
      localContext?: Object;
    } = {}
  ): Observable<boolean> {
    if (this.enabledInput) {
      return applyBooleanInput$(this.enabledInput, {
        context: options.context,
        contextElement: options.contextElement,
        localContext: options.localContext
      }).pipe(catchError(() => of(false)));
    } else {
      return of(true);
    }
  }
}

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../../../../../../../../node_modules/@angular/material/menu/typings/index.ngfactory";
import * as i3 from "@angular/material/menu";
import * as i4 from "@angular/cdk/a11y";
import * as i5 from "../../../../../sidebar/components/sidebar-field/sidebar-field.component.ngfactory";
import * as i6 from "../../../../../sidebar/components/sidebar-field/sidebar-field.component";
import * as i7 from "@angular/cdk/overlay";
import * as i8 from "@angular/cdk/bidi";
import * as i9 from "./customize-bar-action-edit-type-close-popup.component";
var styles_CustomizeBarActionEditTypeClosePopupComponent = [];
var RenderType_CustomizeBarActionEditTypeClosePopupComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CustomizeBarActionEditTypeClosePopupComponent, data: {} });
export { RenderType_CustomizeBarActionEditTypeClosePopupComponent as RenderType_CustomizeBarActionEditTypeClosePopupComponent };
function View_CustomizeBarActionEditTypeClosePopupComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [["class", "choose-items-item__dropdown-icon"]], null, null, null, null, null)), i0.ɵdid(1, 278528, null, 0, i1.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null)], function (_ck, _v) { var currVal_0 = "choose-items-item__dropdown-icon"; var currVal_1 = ("icon-" + _v.parent.context.$implicit.icon); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_CustomizeBarActionEditTypeClosePopupComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "button", [["class", "mat-menu-item"], ["mat-menu-item", ""]], [[1, "role", 0], [2, "mat-menu-item-highlighted", null], [2, "mat-menu-item-submenu-trigger", null], [1, "tabindex", 0], [1, "aria-disabled", 0], [1, "disabled", 0]], [[null, "click"], [null, "mouseenter"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1)._checkDisabled($event) !== false);
        ad = (pd_0 && ad);
    } if (("mouseenter" === en)) {
        var pd_1 = (i0.ɵnov(_v, 1)._handleMouseEnter() !== false);
        ad = (pd_1 && ad);
    } if (("click" === en)) {
        var pd_2 = (_co.form.controls.close_popup.patchValue(_v.context.$implicit.value) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i2.View_MatMenuItem_0, i2.RenderType_MatMenuItem)), i0.ɵdid(1, 180224, [[1, 4]], 0, i3.MatMenuItem, [i0.ElementRef, i1.DOCUMENT, i4.FocusMonitor, [2, i3.ɵf24]], { disableRipple: [0, "disableRipple"] }, null), (_l()(), i0.ɵand(16777216, null, 0, 1, null, View_CustomizeBarActionEditTypeClosePopupComponent_3)), i0.ɵdid(3, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(4, 0, [" ", " "]))], function (_ck, _v) { var currVal_6 = true; _ck(_v, 1, 0, currVal_6); var currVal_7 = _v.context.$implicit.icon; _ck(_v, 3, 0, currVal_7); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).role; var currVal_1 = i0.ɵnov(_v, 1)._highlighted; var currVal_2 = i0.ɵnov(_v, 1)._triggersSubmenu; var currVal_3 = i0.ɵnov(_v, 1)._getTabIndex(); var currVal_4 = i0.ɵnov(_v, 1).disabled.toString(); var currVal_5 = (i0.ɵnov(_v, 1).disabled || null); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); var currVal_8 = _v.context.$implicit.name; _ck(_v, 4, 0, currVal_8); }); }
function View_CustomizeBarActionEditTypeClosePopupComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["class", "mat-menu-item-message"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Modals not found "]))], null, null); }
function View_CustomizeBarActionEditTypeClosePopupComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_CustomizeBarActionEditTypeClosePopupComponent_2)), i0.ɵdid(1, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CustomizeBarActionEditTypeClosePopupComponent_4)), i0.ɵdid(3, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.popupOptions; _ck(_v, 1, 0, currVal_0); var currVal_1 = !_co.popupOptions.length; _ck(_v, 3, 0, currVal_1); }, null); }
function View_CustomizeBarActionEditTypeClosePopupComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "span", [["class", "select__input-icon"]], null, null, null, null, null)), i0.ɵdid(1, 278528, null, 0, i1.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpad(2, 1)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "select__input-icon"; var currVal_1 = _ck(_v, 2, 0, ("icon-" + _co.selectedPopupOption.icon)); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_CustomizeBarActionEditTypeClosePopupComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.selectedPopupOption == null) ? null : _co.selectedPopupOption.name); _ck(_v, 1, 0, currVal_0); }); }
function View_CustomizeBarActionEditTypeClosePopupComponent_7(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Choose Modal"]))], null, null); }
export function View_CustomizeBarActionEditTypeClosePopupComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 20, "div", [["class", "sidebar__element"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 19, "app-sidebar-field", [], null, null, null, i5.View_SidebarFieldComponent_0, i5.RenderType_SidebarFieldComponent)), i0.ɵdid(2, 114688, null, 0, i6.SidebarFieldComponent, [], { label: [0, "label"] }, null), (_l()(), i0.ɵeld(3, 0, null, 3, 6, "mat-menu", [], null, null, null, i2.View_MatMenu_0, i2.RenderType_MatMenu)), i0.ɵprd(6144, null, i3.ɵf24, null, [i3.MatMenu]), i0.ɵdid(5, 1294336, [["queue_dropdown", 4]], 2, i3.MatMenu, [i0.ElementRef, i0.NgZone, i3.MAT_MENU_DEFAULT_OPTIONS], null, null), i0.ɵqud(603979776, 1, { items: 1 }), i0.ɵqud(335544320, 2, { lazyContent: 0 }), (_l()(), i0.ɵand(16777216, null, 0, 1, null, View_CustomizeBarActionEditTypeClosePopupComponent_1)), i0.ɵdid(9, 147456, [[2, 4]], 0, i3.MatMenuContent, [i0.TemplateRef, i0.ComponentFactoryResolver, i0.ApplicationRef, i0.Injector, i0.ViewContainerRef, i1.DOCUMENT], null, null), (_l()(), i0.ɵeld(10, 16777216, null, 3, 10, "div", [["aria-haspopup", "true"], ["class", "select select_theme_jet select_fill"]], [[1, "aria-expanded", 0]], [[null, "mousedown"], [null, "keydown"], [null, "click"]], function (_v, en, $event) { var ad = true; if (("mousedown" === en)) {
        var pd_0 = (i0.ɵnov(_v, 11)._handleMousedown($event) !== false);
        ad = (pd_0 && ad);
    } if (("keydown" === en)) {
        var pd_1 = (i0.ɵnov(_v, 11)._handleKeydown($event) !== false);
        ad = (pd_1 && ad);
    } if (("click" === en)) {
        var pd_2 = (i0.ɵnov(_v, 11)._handleClick($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i0.ɵdid(11, 1196032, [["trigger", 4]], 0, i3.MatMenuTrigger, [i7.Overlay, i0.ElementRef, i0.ViewContainerRef, i3.MAT_MENU_SCROLL_STRATEGY, [2, i3.MatMenu], [8, null], [2, i8.Directionality], i4.FocusMonitor], { menu: [0, "menu"] }, null), (_l()(), i0.ɵeld(12, 0, null, null, 8, "a", [["class", "select__input"], ["href", "javascript:void(0)"]], [[2, "select__input_opened", null]], null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CustomizeBarActionEditTypeClosePopupComponent_5)), i0.ɵdid(14, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(15, 0, null, null, 4, "span", [["class", "select__input-label"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CustomizeBarActionEditTypeClosePopupComponent_6)), i0.ɵdid(17, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CustomizeBarActionEditTypeClosePopupComponent_7)), i0.ɵdid(19, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(20, 0, null, null, 0, "span", [["class", "select__input-arrow"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "Modal"; _ck(_v, 2, 0, currVal_0); _ck(_v, 5, 0); var currVal_2 = i0.ɵnov(_v, 5); _ck(_v, 11, 0, currVal_2); var currVal_4 = ((_co.selectedPopupOption == null) ? null : _co.selectedPopupOption.icon); _ck(_v, 14, 0, currVal_4); var currVal_5 = _co.selectedPopupOption; _ck(_v, 17, 0, currVal_5); var currVal_6 = !_co.selectedPopupOption; _ck(_v, 19, 0, currVal_6); }, function (_ck, _v) { var currVal_1 = (i0.ɵnov(_v, 11).menuOpen || null); _ck(_v, 10, 0, currVal_1); var currVal_3 = i0.ɵnov(_v, 11).menuOpen; _ck(_v, 12, 0, currVal_3); }); }
export function View_CustomizeBarActionEditTypeClosePopupComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-customize-bar-action-edit-type-close-popup", [], null, null, null, View_CustomizeBarActionEditTypeClosePopupComponent_0, RenderType_CustomizeBarActionEditTypeClosePopupComponent)), i0.ɵdid(1, 245760, null, 0, i9.CustomizeBarActionEditTypeClosePopupComponent, [i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CustomizeBarActionEditTypeClosePopupComponentNgFactory = i0.ɵccf("app-customize-bar-action-edit-type-close-popup", i9.CustomizeBarActionEditTypeClosePopupComponent, View_CustomizeBarActionEditTypeClosePopupComponent_Host_0, { form: "form", context: "context", analyticsSource: "analyticsSource" }, {}, []);
export { CustomizeBarActionEditTypeClosePopupComponentNgFactory as CustomizeBarActionEditTypeClosePopupComponentNgFactory };

<div class="item-selector">
  <a href="javascript:void(0)" class="item-selector__back" (click)="back.emit()">
    <span class="icon-arrow_backward_2"></span> Back
  </a>
  <div class="item-selector__title">Import files from Google Sheets</div>
  <div class="item-selector__sections">
    <div class="item-selector__sections-item">
      <div class="item-selector__section-title">Files</div>
      <div class="item-selector__list">
        <div *ngFor="let item of form.form.controls.files.controls" class="item-selector__list-item">
          <div
            class="item-selector-list-item"
            [class.item-selector-list-item_active]="item === selectedFile"
            [class.item-selector-list-item_error]="item.invalid"
            (click)="setSelectedFile(item)"
          >
            <div class="item-selector-list-item__left">
              <span
                class="item-selector-list-item__icon"
                [style.background-image]="'/assets/images/resources/googlesheets-color.svg' | appDeployCssUrl"
              >
              </span>
            </div>
            <div class="item-selector-list-item__main">
              <ng-container *ngIf="item.value['file']">
                <div class="item-selector-list-item__title">
                  {{ item.value['verbose_name'] || item.value['file']?.name }}
                </div>
                <div *ngIf="!item.invalid || item === selectedFile" class="item-selector-list-item__subtitle">
                  docs.google.com/spreadsheets/d/{{ item.value['file']?.id }}
                </div>
                <div *ngIf="item.invalid && item !== selectedFile" class="item-selector-list-item__subtitle">
                  File is not correctly set up
                </div>
              </ng-container>
              <ng-container *ngIf="!item.value['file']">
                <div class="item-selector-list-item__title">
                  New file
                </div>
                <div *ngIf="item.invalid && item !== selectedFile" class="item-selector-list-item__subtitle">
                  File is not correctly set up
                </div>
              </ng-container>
            </div>
            <div *ngIf="form.form.controls.files.controls.length > 1" class="item-selector-list-item__right">
              <a href="javascript:void(0)" class="icon-bin" [appTip]="'Delete file'" (click)="deleteFile(item)"></a>
            </div>
          </div>
        </div>
        <div class="item-selector__list-bottom">
          <a href="javascript:void(0)" class="item-selector__list-link" (click)="addFile()">
            <span class="item-selector__list-link-icon icon-plus"></span>
            Add a File
          </a>
        </div>
      </div>
    </div>
    <div *ngIf="selectedFile" class="item-selector__sections-item">
      <div class="item-selector__section-title">Settings</div>
      <div class="item-selector__detail background-inputs">
        <app-google-sheets-resource-settings-file
          [form]="selectedFile"
          [accessToken]="accessToken"
          (updating)="setFileUpdating($event)"
        >
        </app-google-sheets-resource-settings-file>
      </div>
    </div>
  </div>
</div>

<div *ngIf="fileUpdating" class="popup__overlay">
  <app-loader [fill]="true"></app-loader>
</div>

export var PaymentMethod;
(function (PaymentMethod) {
    PaymentMethod["Card"] = "card";
})(PaymentMethod || (PaymentMethod = {}));
export var CardType;
(function (CardType) {
    CardType["Mastercard"] = "master";
    CardType["Visa"] = "visa";
    CardType["AmericanExpress"] = "american_express";
    CardType["Discover"] = "discover";
    CardType["JCB"] = "jcb";
    CardType["MastercardMaestro"] = "maestro";
    CardType["DinersClub"] = "diners_club";
    CardType["UnionPay"] = "unionpay";
})(CardType || (CardType = {}));
var PaymentDetails = /** @class */ (function () {
    function PaymentDetails() {
    }
    PaymentDetails.prototype.deserialize = function (data) {
        this.paymentMethod = data['payment_method'];
        this.cardType = data['card_type'];
        this.cardLastFourDigits = data['card_last_four_digits'];
        this.cardExpiryDate = data['card_expiry_date'];
        this.updateUrl = data['update_url'];
        return this;
    };
    Object.defineProperty(PaymentDetails.prototype, "cardTypeStr", {
        get: function () {
            if (this.cardType == CardType.Mastercard) {
                return 'Mastercard';
            }
            else if (this.cardType == CardType.Visa) {
                return 'Visa';
            }
            else if (this.cardType == CardType.AmericanExpress) {
                return 'American Express';
            }
            else if (this.cardType == CardType.Discover) {
                return 'Discover';
            }
            else if (this.cardType == CardType.JCB) {
                return 'JCB';
            }
            else if (this.cardType == CardType.MastercardMaestro) {
                return 'Mastercard Maestro';
            }
            else if (this.cardType == CardType.DinersClub) {
                return 'Diners Club';
            }
            else if (this.cardType == CardType.UnionPay) {
                return 'Union Pay';
            }
        },
        enumerable: true,
        configurable: true
    });
    return PaymentDetails;
}());
export { PaymentDetails };

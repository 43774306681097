<div class="sidebar__element">
  <app-customize-bar-input-edit
    [label]="'title'"
    [required]="true"
    [itemForm]="control.controls.title"
    [context]="context"
    [contextElement]="contextElement"
    [contextElementPath]="contextElementPath"
    [contextElementPaths]="contextElementPaths"
    [fill]="true"
    [analyticsSource]="analyticsSource + '_confirmation_title'"
  ></app-customize-bar-input-edit>
</div>

<div class="sidebar__element">
  <app-customize-bar-input-edit
    [label]="'description'"
    [required]="true"
    [itemForm]="control.controls.description"
    [context]="context"
    [contextElement]="contextElement"
    [contextElementPath]="contextElementPath"
    [contextElementPaths]="contextElementPaths"
    [staticValueParams]="{ multiline: true, rows: 3 }"
    [fill]="true"
    [analyticsSource]="analyticsSource + '_confirmation_description'"
  ></app-customize-bar-input-edit>
</div>

<div class="sidebar__element">
  <app-sidebar-field [label]="'submit button'">
    <div class="sidebar__element sidebar__element_margin_xxs sidebar__element_first sidebar__element_fill">
      <app-auto-field
        [form]="control"
        [label]="false"
        [field]="
          createField({
            name: 'submit_label',
            field: 'CharField',
            placeholder: defaultSubmitLabel,
            params: { classes: ['input_fill'] }
          })
        "
      ></app-auto-field>
    </div>

    <div class="sidebar__element sidebar__element_margin_xxs sidebar__element_last sidebar__element_fill">
      <app-auto-field
        [form]="control"
        [label]="false"
        [field]="
          createField({
            name: 'submit_tint',
            field: 'ColorField',
            params: { classes: ['select_fill'], allow_empty: true, empty_accent_color: true, custom_colors: true }
          })
        "
      ></app-auto-field>
    </div>

    <div class="sidebar__element sidebar__element_margin_xxs sidebar__element_last sidebar__element_fill">
      <app-auto-field
        [form]="control"
        [label]="false"
        [field]="
          createField({
            name: 'submit_icon',
            label: 'Icon',
            field: 'IconField',
            placeholder: 'no icon',
            params: { classes: ['select_fill'], allow_empty: true }
          })
        "
      ></app-auto-field>
    </div>
  </app-sidebar-field>
</div>

<div class="sidebar__element">
  <app-sidebar-field [label]="'cancel button'">
    <div class="sidebar__element sidebar__element_margin_xxs sidebar__element_first sidebar__element_fill">
      <app-auto-field
        [form]="control"
        [label]="false"
        [field]="
          createField({
            name: 'cancel_label',
            field: 'CharField',
            placeholder: defaultCancelLabel,
            params: { classes: ['input_fill'] }
          })
        "
      ></app-auto-field>
    </div>

    <div class="sidebar__element sidebar__element_margin_xxs sidebar__element_last sidebar__element_fill">
      <app-auto-field
        [form]="control"
        [label]="false"
        [field]="
          createField({
            name: 'cancel_tint',
            field: 'ColorField',
            params: { classes: ['select_fill'], allow_empty: true, empty_accent_color: true, custom_colors: true }
          })
        "
      ></app-auto-field>
    </div>

    <div class="sidebar__element sidebar__element_margin_xxs sidebar__element_last sidebar__element_fill">
      <app-auto-field
        [form]="control"
        [label]="false"
        [field]="
          createField({
            name: 'cancel_icon',
            label: 'Icon',
            field: 'IconField',
            placeholder: 'no icon',
            params: { classes: ['select_fill'], allow_empty: true }
          })
        "
      ></app-auto-field>
    </div>
  </app-sidebar-field>
</div>

<div class="sidebar__element">
  <button type="button" class="button button_fill button_orange-alternative" (click)="disable.emit()">
    <span class="button__icon button__icon_left icon-close"></span>
    <span class="button__label">Disable confirmation dialog</span>
  </button>
</div>

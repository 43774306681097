import {
  ContentChild,
  Directive,
  ElementRef,
  EmbeddedViewRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewContainerRef
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest, fromEvent, merge, of, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap } from 'rxjs/operators';

import { MouseButton } from '@shared';

import { AppDragStart } from '../../data/drag-start';
import { getRootNode, replaceElement } from '../../utils/document';
import { handleDragMouseDownEvent, isDragMouseDownEventHandled } from '../../utils/handle-drag-mouse-down';
import { isDragMouseDownEventPrevented } from '../../utils/prevent-drag-mouse-down';
import { AppDragHandle } from '../drag-handle/drag-handle.directive';
import { AppDragPlaceholder } from '../drag-placeholder/drag-placeholder.directive';
import { AppDragPreview } from '../drag-preview/drag-preview.directive';
import { AppDropList } from '../drop-list/drop-list.directive';
import { APP_DRAG } from './drag.token';

export interface AppDraggingOver<T> {
  drag: AppDrag<T>;
}

@Directive({
  selector: '[appDrag]',
  providers: [{ provide: APP_DRAG, useExisting: AppDrag }]
})
export class AppDrag<T = any> implements OnInit, OnDestroy {
  @Input('appDragData') data: T;

  @Output() started = new EventEmitter<AppDragStart<T>>();
  @Output() draggingOver = new EventEmitter<AppDraggingOver<T>>();

  @ContentChild(AppDragPlaceholder) placeholderTemplate: AppDragPlaceholder;
  @ContentChild(AppDragPreview) previewTemplate: AppDragPreview;

  private dropList$ = new BehaviorSubject<AppDropList>(undefined);
  private placeholderView: EmbeddedViewRef<any>;
  private customPreviewView: EmbeddedViewRef<any>;
  private elOverride: HTMLElement;
  private handles: AppDragHandle[] = [];
  private dragStartThreshold = 5;
  private subscriptions: Subscription[] = [];

  constructor(private readonly el: ElementRef, private viewContainerRef: ViewContainerRef) {}

  ngOnInit(): void {
    this.dropList$
      .pipe(
        switchMap(dropList => {
          if (dropList) {
            return dropList.disabled$.pipe(map(disabled => (disabled ? undefined : dropList)));
          } else {
            return of(undefined);
          }
        }),
        distinctUntilChanged((lhs, rhs) => lhs === rhs),
        untilDestroyed(this)
      )
      .subscribe(dropList => {
        if (dropList) {
          this.initDrag(dropList);
        } else {
          this.deinitDrag();
        }
      });
  }

  ngOnDestroy(): void {}

  getElement(): HTMLElement {
    return this.elOverride || this.el.nativeElement;
  }

  setElement(newElement: HTMLElement) {
    const currentElement = this.getElement();
    replaceElement(currentElement, newElement);
    this.elOverride = newElement;
  }

  resetElement() {
    if (!this.elOverride) {
      return;
    }
    const currentElement = this.getElement();
    replaceElement(currentElement, this.el.nativeElement);
    this.elOverride = undefined;
  }

  registerHandle(handle: AppDragHandle) {
    if (this.handles.every(item => item !== handle)) {
      this.handles.push(handle);
    }
  }

  unregisterHandle(handle: AppDragHandle) {
    this.handles = this.handles.filter(item => item !== handle);
  }

  setDropList(dropList: AppDropList) {
    if (this.dropList$.value === dropList) {
      return;
    } else if (this.dropList$.value) {
      this.clearDropList(this.dropList$.value);
    }

    this.dropList$.next(dropList);
  }

  clearDropList(dropList: AppDropList) {
    if (this.dropList$.value === dropList) {
      this.dropList$.next(undefined);
    }
  }

  initDrag(dropList: AppDropList) {
    this.deinitDrag();

    this.subscriptions.push(
      fromEvent<MouseEvent>(this.el.nativeElement, 'mousedown')
        .pipe(
          filter(e => e.button == MouseButton.Main),
          filter(e => !isDragMouseDownEventHandled(e) && !isDragMouseDownEventPrevented(e)),
          filter(e => {
            handleDragMouseDownEvent(e);

            if (this.handles.length) {
              return !!this.handles
                .filter(item => !item.isDisabled())
                .map(item => item.element.nativeElement)
                .find(handle => {
                  return !!e.target && (e.target === handle || handle.contains(e.target as HTMLElement));
                });
            } else {
              return true;
            }
          }),
          untilDestroyed(this)
        )
        .subscribe(e => {
          this.waitDragStart(dropList, e);
        })
    );

    this.subscriptions.push(
      combineLatest(
        merge(
          fromEvent<MouseEvent>(this.el.nativeElement, 'mouseenter').pipe(map(() => true)),
          fromEvent<MouseEvent>(this.el.nativeElement, 'mouseleave').pipe(map(() => false))
        ),
        dropList.getDraggingState$()
      )
        .pipe(
          map(([hover, state]) => {
            return hover && !!state ? { drag: state.drag } : undefined;
          }),
          distinctUntilChanged((lhs, rhs) => {
            const lhsDrag = lhs ? lhs.drag : undefined;
            const rhsDrag = rhs ? rhs.drag : undefined;
            return lhsDrag === rhsDrag;
          }),
          untilDestroyed(this)
        )
        .subscribe(e => {
          this.draggingOver.emit(e);
        })
    );
  }

  deinitDrag() {
    if (!this.subscriptions.length) {
      return;
    }

    this.subscriptions.forEach(item => item.unsubscribe());
    this.subscriptions = [];
  }

  waitDragStart(dropList: AppDropList, downEvent: MouseEvent) {
    const subscriptions: Subscription[] = [];

    subscriptions.push(
      fromEvent<MouseEvent>(window.document, 'mousemove')
        .pipe(untilDestroyed(this))
        .subscribe(moveEvent => {
          moveEvent.preventDefault();

          const distanceX = Math.abs(moveEvent.clientX - downEvent.clientX);
          const distanceY = Math.abs(moveEvent.clientY - downEvent.clientY);

          if (distanceX + distanceY >= this.dragStartThreshold) {
            this.dragStart(dropList, downEvent);
            subscriptions.forEach(item => item.unsubscribe());
          }
        })
    );

    subscriptions.push(
      fromEvent<MouseEvent>(window.document, 'mouseup')
        .pipe(
          filter(e => e.button == MouseButton.Main),
          untilDestroyed(this)
        )
        .subscribe(() => {
          subscriptions.forEach(item => item.unsubscribe());
        })
    );
  }

  dragStart(dropList: AppDropList, e: MouseEvent) {
    const dragStartEvent: AppDragStart<T> = { source: this, event: e };

    dropList.dragStart(dragStartEvent);
    this.started.emit(dragStartEvent);
  }

  initPlaceholder() {
    this.destroyPlaceholder();

    if (this.placeholderTemplate) {
      const view = this.viewContainerRef.createEmbeddedView(this.placeholderTemplate.templateRef);

      view.detectChanges();
      this.setElement(getRootNode(view));
      this.placeholderView = view;
    }
  }

  getCustomPreview(): HTMLElement {
    if (this.previewTemplate) {
      const view = this.viewContainerRef.createEmbeddedView(this.previewTemplate.templateRef);

      view.detectChanges();
      this.customPreviewView = view;
      return getRootNode(view);
    }
  }

  destroyPlaceholder() {
    this.resetElement();

    if (this.placeholderView) {
      this.placeholderView.destroy();
      this.placeholderView = undefined;
    }
  }

  destroyCustomPreview() {
    if (this.customPreviewView) {
      this.customPreviewView.destroy();
      this.customPreviewView = undefined;
    }
  }

  isPlaceholderMissing(): boolean {
    return this.placeholderTemplate && !this.placeholderView;
  }

  getBounds(): ClientRect {
    return this.getElement().getBoundingClientRect();
  }
}

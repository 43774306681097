import { generateAlphanumeric, isSet } from '@shared';

export class PersistentIdGenerator {
  store = {};

  elementId(uniqueName?: string): string {
    if (isSet(uniqueName) && this.store.hasOwnProperty(uniqueName)) {
      return this.store[uniqueName];
    }

    const id = generateAlphanumeric(8, { letterFirst: true });

    if (isSet(uniqueName)) {
      this.store[uniqueName] = id;
    }

    return id;
  }
}

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { of } from 'rxjs';
import { slackResourceParamsActionDescriptions } from '../../data/slack/slack-resource-params-action-descriptions.stub';
import { slackResourceParamsParams } from '../../data/slack/slack-resource-params-params.stub';
import { ResourceGeneratorService } from '../resource-generator/resource-generator.service';
var SlackGeneratorService = /** @class */ (function (_super) {
    __extends(SlackGeneratorService, _super);
    function SlackGeneratorService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    SlackGeneratorService.prototype.getParamsOptions = function (project, environment, resource) {
        return of({
            webhook_url: resource.params['webhook_url']
        });
    };
    SlackGeneratorService.prototype.generateParams = function (project, environment, typeItem, options) {
        var resourceParams = slackResourceParamsParams;
        var actionDescriptions = slackResourceParamsActionDescriptions;
        var extraTokens = {
            webhook_url: options.webhook_url
        };
        resourceParams['webhook_url'] = options.webhook_url;
        return of({
            resourceParams: resourceParams,
            actionDescriptions: actionDescriptions,
            extraTokens: extraTokens
        });
    };
    return SlackGeneratorService;
}(ResourceGeneratorService));
export { SlackGeneratorService };

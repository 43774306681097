import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  Output
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { fromEvent, Subscription } from 'rxjs';
import { debounceTime, filter } from 'rxjs/operators';

import { BasePopupComponent } from '@common/popups';
import { ActionOutput, FieldType } from '@modules/fields';
import { isBodyHasChild, isElementHasChild, nodeListToArray, TypedChanges } from '@shared';

import { ViewEditorActionsForm } from './view-editor-actions.form';

const viewEditorParametersMenuClickEventProperty = '_viewEditorParametersMenuClickEvent';

export function markViewEditorParametersClickEvent(clickEvent: MouseEvent) {
  clickEvent[viewEditorParametersMenuClickEventProperty] = true;
}

export function isViewEditorParametersClickEvent(clickEvent: MouseEvent) {
  return !!clickEvent[viewEditorParametersMenuClickEventProperty];
}

@Component({
  selector: 'app-view-editor-actions',
  templateUrl: './view-editor-actions.component.html',
  providers: [ViewEditorActionsForm],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ViewEditorActionsComponent implements OnInit, OnDestroy, OnChanges {
  @Input() actions: ActionOutput[];
  @Input() opened = false;
  @Output() actionsChanged = new EventEmitter<ActionOutput[]>();
  @Output() closed = new EventEmitter<void>();

  blurSubscription: Subscription;
  fieldTypes = FieldType;
  analyticsSource = 'view_editor_actions';

  constructor(
    @Optional() private popupComponent: BasePopupComponent,
    public form: ViewEditorActionsForm,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.form.init({ actions: this.actions });

    this.form.controls.actions.valueChanges.pipe(debounceTime(200), untilDestroyed(this)).subscribe(() => {
      const result = this.form.getActions();
      this.actionsChanged.emit(result);
    });
  }

  ngOnDestroy(): void {}

  ngOnChanges(changes: TypedChanges<ViewEditorActionsComponent>): void {
    if (changes.opened && this.opened) {
      this.onOpened();
    } else if (changes.opened && !changes.opened.firstChange && !this.opened) {
      this.onClosed();
    }
  }

  close() {
    if (!this.opened) {
      return;
    }

    this.opened = false;
    this.cd.markForCheck();

    this.onClosed();
  }

  onOpened() {
    setTimeout(() => {
      this.blurSubscription = fromEvent<MouseEvent>(window.document, 'click')
        .pipe(
          filter(e => {
            if (isViewEditorParametersClickEvent(e)) {
              return false;
            }

            const overlays = nodeListToArray(document.querySelectorAll('.cdk-overlay-container, .popup'));
            if (
              overlays
                .filter(item => {
                  if (this.popupComponent && isElementHasChild(this.popupComponent.el.nativeElement, item, true)) {
                    return false;
                  } else {
                    return true;
                  }
                })
                .some(overlay => isElementHasChild(overlay, e.target as HTMLElement)) ||
              !isBodyHasChild(e.target as HTMLElement)
            ) {
              return false;
            }

            return true;
          }),
          untilDestroyed(this)
        )
        .subscribe(() => this.close());
    }, 0);
  }

  onClosed() {
    if (this.blurSubscription) {
      this.blurSubscription.unsubscribe();
      this.blurSubscription = undefined;
    }

    this.closed.emit();
  }

  markClickEvent(e: MouseEvent) {
    markViewEditorParametersClickEvent(e);
  }
}

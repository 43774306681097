import { ChangeDetectionStrategy, Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import clamp from 'lodash/clamp';

import { isSet, parseNumber } from '@shared';

@Component({
  selector: 'app-alpha-control',
  templateUrl: './alpha-control.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AlphaControlComponent),
      multi: true
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AlphaControlComponent implements OnInit, ControlValueAccessor {
  @Input() formControl: FormControl;
  @Input() widthPx = 50;

  valueControl = new FormControl(100);

  onChange = (value: any) => undefined;
  onTouched = () => undefined;

  constructor() {}

  ngOnInit() {}

  registerOnChange(fn: (value: any) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  writeValue(value: any): void {
    if (isSet(value)) {
      const valueNumber = parseNumber(value, 1);
      const alpha = Math.round(clamp(valueNumber * 100, 0, 100));

      this.valueControl.patchValue(alpha);
    } else {
      this.valueControl.patchValue(100);
    }
  }

  updateValue(value: any) {
    if (isSet(value)) {
      const valueNumber = parseNumber(value, 100);
      const alpha = Math.round(clamp(valueNumber, 0, 100)) / 100;

      this.onChange(alpha);
    } else {
      this.onChange(1);
    }
  }
}

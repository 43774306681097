import { OnInit } from '@angular/core';
import { Environment, Project } from '@modules/projects';
var InstallInstructionsSqlGeneralComponent = /** @class */ (function () {
    function InstallInstructionsSqlGeneralComponent() {
    }
    InstallInstructionsSqlGeneralComponent.prototype.ngOnInit = function () { };
    Object.defineProperty(InstallInstructionsSqlGeneralComponent.prototype, "environmentName", {
        get: function () {
            if (!this.environment) {
                return;
            }
            if (this.project.environments.length <= 1) {
                return;
            }
            return this.environment.uniqueName;
        },
        enumerable: true,
        configurable: true
    });
    return InstallInstructionsSqlGeneralComponent;
}());
export { InstallInstructionsSqlGeneralComponent };

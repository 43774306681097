import { Observable } from 'rxjs';

import { Environment, Project, Resource, ResourceTypeItem } from '@modules/projects';
import { ResourceParamsResult } from '@modules/resources';

export interface IsOptionsValidResult {
  accountName?: string;
}

export abstract class ResourceGeneratorService<T> {
  tokenRequired = false;

  isOptionsValid?(options: T): Observable<IsOptionsValidResult>;

  abstract getParamsOptions(project: Project, environment: Environment, resource: Resource): Observable<T>;

  // TODO: Consider removing project argument (DatabaseGenerator project encrypt dependency)
  abstract generateParams(
    project: Project,
    environment: Environment,
    typeItem: ResourceTypeItem,
    options: T
  ): Observable<ResourceParamsResult>;
}

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest } from 'rxjs';
import { auditTime } from 'rxjs/operators';

import { DynamicComponentArguments } from '@common/dynamic-component';
import { ActionType } from '@modules/actions';
import { elementImage, ElementItem, getElementComponentByType } from '@modules/customize';
import { CustomizeBarContext, CustomizeBarEditEventType, CustomizeBarService } from '@modules/customize-bar';
import { ContainerLayer, ElementLayer, LayerType } from '@modules/views';

import { registerCustomizeLayerComponent } from '../../../data/customize-layer-components';
import { ViewEditorContext } from '../../../services/view-editor-context/view-editor.context';
import { CustomizeLayerComponent } from '../base/base-customize-layer.component';
import { ElementCustomizeLayerForm } from './element-customize-layer.form';

@Component({
  selector: 'app-element-customize-layer',
  templateUrl: './element-customize-layer.component.html',
  providers: [ElementCustomizeLayerForm, CustomizeBarContext],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ElementCustomizeLayerComponent extends CustomizeLayerComponent<ElementLayer> implements OnInit, OnDestroy {
  containerLayer: ContainerLayer;
  name: string;
  image: string;
  settingsComponents: DynamicComponentArguments[] = [];

  constructor(
    public form: ElementCustomizeLayerForm,
    private editorContext: ViewEditorContext,
    private customizeBarService: CustomizeBarService,
    private customizeBarContext: CustomizeBarContext,
    private cd: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit() {
    const layerContainer = this.editorContext.getLayerContainer(this.layer);

    this.name = this.getElementName();
    this.image = this.getElementImage();
    this.cd.markForCheck();

    this.form.init(this.layer, { firstInit: true, container: layerContainer });
    this.trackFormUpdates(this.form, () => this.form.submit());

    combineLatest(this.layerUpdated$, this.editorContext.getLayerContainer$(this.layer))
      .pipe(auditTime(10), untilDestroyed(this))
      .subscribe(([layer, container]) => {
        this.pauseFormUpdates();
        this.form.init(layer, {
          firstInit: false,
          container: container
        });
        this.resumeFormUpdates();
      });

    this.form.containerLayer$.pipe(untilDestroyed(this)).subscribe(layer => {
      this.containerLayer = layer;
      this.cd.markForCheck();
    });

    this.customizeBarContext.settingsComponents$.pipe(untilDestroyed(this)).subscribe(item => {
      this.settingsComponents = item;
      this.cd.markForCheck();
    });
  }

  ngOnDestroy(): void {}

  getElementName(): string {
    const elementComponent = getElementComponentByType(this.layer.element.type);
    return elementComponent.label;
  }

  getElementImage(): string {
    return elementImage(this.layer.element) || 'table_list';
  }

  customize() {
    this.customizeBarService
      .customize(this.customizeBarContext, this.layer.element, this.viewContext, undefined, undefined, {
        titleEnabled: false,
        backLabel: 'Layer settings',
        tooltipEditable: false,
        marginEditable: false,
        fieldLabelEditable: false,
        fieldReadonlyEditable: false,
        actionTypesOnly: [
          ActionType.Query,
          ActionType.Workflow,
          ActionType.Link,
          ActionType.ExternalLink,
          ActionType.ElementAction,
          ActionType.ShowNotification,
          ActionType.SetProperty,
          ActionType.RunJavaScript,
          ActionType.CopyToClipboard,
          ActionType.Export,
          ActionType.Import,
          ActionType.Download,
          ActionType.ScanCode
        ]
      })
      .pipe(untilDestroyed(this))
      .subscribe(result => {
        if (!result.event || result.event !== CustomizeBarEditEventType.Deleted) {
          this.onCustomized(result.el);
        }
      });
  }

  onCustomized(element: ElementItem) {
    this.layer.element.copy(element);
    this.layerChange.emit(this.layer);
  }
}

registerCustomizeLayerComponent(LayerType.Element, ElementCustomizeLayerComponent);

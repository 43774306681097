import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Injector,
  OnDestroy,
  OnInit,
  QueryList,
  Renderer2,
  ViewChildren
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import clamp from 'lodash/clamp';
import isEqual from 'lodash/isEqual';
import moment from 'moment';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { fromEvent, Observable, Subject } from 'rxjs';

import { PopupDynamicComponentArguments, PopupService } from '@common/popups';
import { messageType } from '@modules/activities';
import { TimelinePopupComponent } from '@modules/activities-components';
import { FeedItem, FeedItemListStore, FeedItemService } from '@modules/collaboration';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { getWindowScrollTop } from '@shared';

import { CollaborationMessagesItemComponent } from '../collaboration-messages-item/collaboration-messages-item.component';

@Component({
  selector: 'app-collaboration-messages',
  templateUrl: './collaboration-messages.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [FeedItemListStore]
})
export class CollaborationMessagesComponent implements OnInit, OnDestroy, AfterViewInit {
  feedItems$: Observable<FeedItem[]>;
  feedItemsLoading$: Observable<boolean>;
  selectedItemIndex = undefined;
  baseParams: Object;
  timelineBaseParams: Object;
  timelinePopup: PopupDynamicComponentArguments;
  closeTimelinePopup = new Subject<void>();

  @ViewChildren('feedItem', { read: CollaborationMessagesItemComponent }) feedItem = new QueryList<
    CollaborationMessagesItemComponent
  >();
  constructor(
    private renderer: Renderer2,
    private feedItemService: FeedItemService,
    private popupService: PopupService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private feedItemListStore: FeedItemListStore,
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private injector: Injector,
    private cd: ChangeDetectorRef
  ) {}

  form: FormGroup = new FormGroup({
    date_add_gte: new FormControl(),
    date_add_lte: new FormControl(),
    read: new FormControl(null),
    mention: new FormControl(null)
  });
  formatDate = 'DD.MM.YYYY';
  readOptions = [
    { name: 'All Messages', value: null },
    { name: 'Unread Messages', value: 0 }
  ];

  trackByFn(index, item: FeedItem) {
    return item.dateAdd;
  }

  ngOnInit(): void {
    this.baseParams = {
      order_by: '-date_add',
      user_activity_type_in: `${[...messageType].join(',')}`
    };

    this.activatedRoute.queryParams.pipe(untilDestroyed(this)).subscribe(params => {
      const formValue = this.form.value;
      const patchValue = {};

      ['date_add_gte', 'date_add_lte', 'read', 'mention'].forEach(name => {
        if (params[name]) {
          patchValue[name] = params[name];
        } else {
          patchValue[name] = null;
        }
      });

      if (!isEqual(formValue, patchValue)) {
        this.form.patchValue({
          ...patchValue
        });
      }

      this.feedItemListStore.projectName = this.currentProjectStore.instance.uniqueName;
      this.feedItemListStore.environmentName = this.currentEnvironmentStore.instance.uniqueName;
      this.feedItemListStore.params = {
        ...this.baseParams,
        ...params
      };
      this.feedItemListStore.reset();
      this.feedItemListStore.getNext();
      this.cd.markForCheck();
    });

    this.feedItems$ = this.feedItemListStore.items$;
    this.feedItemsLoading$ = this.feedItemListStore.loading$;
  }

  ngOnDestroy(): void {}

  ngAfterViewInit(): void {
    this.form.valueChanges.pipe(untilDestroyed(this)).subscribe(value => {
      const params: Params = {};

      ['date_add_gte', 'date_add_lte', 'read', 'mention'].forEach(name => {
        if (value[name] === null) {
          return;
        }
        if (name === 'date_add_gte') {
          params[name] = moment(value[name]).startOf('day').format('YYYY-MM-DDTHH:mm:ss.SSSZ');
        } else if (name === 'date_add_lte') {
          params[name] = moment(value[name]).endOf('day').format('YYYY-MM-DDTHH:mm:ss.SSSZ');
        } else if (name === 'read' && value[name] === 0) {
          params[name] = value[name];
        } else {
          params[name] = value[name];
        }
      });

      this.router.navigate([], {
        relativeTo: this.activatedRoute,
        queryParams: params
      });

      this.selectedItemIndex = undefined;
      this.timelineBaseParams = undefined;
      this.closeTimelinePopup.next();
      this.cd.markForCheck();
    });
    fromEvent(window, 'scroll')
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        const scrollTop = getWindowScrollTop();
        const viewportHeight = window.innerHeight;
        const viewportBottom = scrollTop + viewportHeight;
        const contentHeight = document.body.offsetHeight;

        if (contentHeight - viewportBottom <= clamp(viewportHeight, 100, viewportHeight)) {
          this.onScrollFinished();
        }
      });
  }

  onScrollFinished() {
    this.feedItemListStore.getNext();
  }

  activeToggle(index: number, item: FeedItem): void {
    if (index === this.selectedItemIndex) {
      this.selectedItemIndex = undefined;
      this.timelineBaseParams = undefined;
      this.closeTimelinePopup.next();
      this.cd.markForCheck();
    } else {
      this.openTimelinePopup(item);
      this.selectedItemIndex = index;
      this.cd.markForCheck();
    }
  }

  openTimelinePopup(feedItem: FeedItem): void {
    const timelineBaseParams = {};
    if (feedItem.userActivity.objectType) {
      timelineBaseParams['object_type'] = feedItem.userActivity.objectType;
    }
    if (feedItem.userActivity.objectId) {
      timelineBaseParams['object_id'] = feedItem.userActivity.objectId;
    }

    if (!this.timelineBaseParams) {
      this.timelineBaseParams = timelineBaseParams;
      this.createPopup(feedItem, this.timelineBaseParams);
      this.setMarkRead(feedItem, this.timelineBaseParams);
    } else if (!isEqual(timelineBaseParams, this.timelineBaseParams)) {
      this.closeTimelinePopup.next();
      this.timelineBaseParams = timelineBaseParams;
      this.createPopup(feedItem, this.timelineBaseParams);
      this.setMarkRead(feedItem, this.timelineBaseParams);
    }
  }

  setMarkRead(feedItem: FeedItem, selectedItemParams: Object): void {
    this.feedItemService
      .setMarkRead(this.currentProjectStore.instance.uniqueName, this.currentEnvironmentStore.instance.uniqueName, {
        user_activity_object_type: feedItem.userActivity.objectType,
        user_activity_object_id: feedItem.userActivity.objectId
      })
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.feedItemListStore.setMarkRead(selectedItemParams);
        this.cd.detectChanges();
      });
  }

  readAll(): void {
    this.feedItemListStore.setMarkRead();
    this.cd.detectChanges();
  }

  createPopup(feedItem: FeedItem, params: object): void {
    this.timelinePopup = this.popupService.push({
      component: TimelinePopupComponent,
      disablePointerEvents: true,
      enableWindowScroll: true,
      inputs: {
        baseParams: params,
        closeObs: this.closeTimelinePopup
      },
      outputs: {
        closeObsEvent: [
          () => {
            this.selectedItemIndex = undefined;
            this.timelineBaseParams = undefined;
            this.cd.markForCheck();
          }
        ]
      },
      injector: this.injector
    });
  }
}

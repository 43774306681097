import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import cloneDeep from 'lodash/cloneDeep';
import { untilDestroyed } from 'ngx-take-until-destroy';

import { ActionItem, ViewSettingsAction } from '@modules/actions';
import { ViewContext, ViewContextElement } from '@modules/customize';
import { Input as FieldInput } from '@modules/fields';

import { CustomizeBarEditEventType } from '../../data/customize-bar-edit-event-type';
import { CustomizeBarContext } from '../../services/customize-bar-context/customize-bar.context';
import { CustomizeBarService } from '../../services/customize-bar/customize-bar.service';
import { CustomizeActionOptions } from '../customize-bar-action-edit/customize-bar-action-edit.form';
import { ActionsEditForm } from './actions-edit.form';

@Component({
  selector: 'app-actions-edit',
  templateUrl: './actions-edit.component.html',
  providers: [ActionsEditForm],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActionsEditComponent implements OnInit, OnDestroy {
  @Input() options: CustomizeActionOptions = {};
  @Input() control: AbstractControl;
  @Input() pin = false;
  @Input() context: ViewContext;
  @Input() contextElement: ViewContextElement;
  @Input() contextElementPath: (string | number)[];
  @Input() contextElementPaths: (string | number)[][];
  @Input() emptyAction: string;
  @Input() emptyActionValue: string;
  @Input() emptyIcon: string;
  @Input() actionLabel: string;
  @Input() object: string;
  @Input() classes: string | string[];
  @Input() firstInit = false;

  constructor(
    public form: ActionsEditForm,
    private customizeBarService: CustomizeBarService,
    private customizeBarContext: CustomizeBarContext,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.form.init(this.control);
    this.form.form.statusChanges.pipe(untilDestroyed(this)).subscribe(() => this.cd.markForCheck());
  }

  ngOnDestroy(): void {}

  dragDrop(event: CdkDragDrop<FormControl[]>) {
    if (event.previousIndex !== event.currentIndex) {
      moveItemInArray(this.form.form.controls, event.previousIndex, event.currentIndex);
      this.form.form.updateValueAndValidity();
    }
  }

  toggleItemPinned(control: AbstractControl) {
    const item = cloneDeep(control.value);
    item['pinned'] = !item['pinned'];
    control.patchValue(item);
    this.cd.markForCheck();
  }

  addItem() {
    const actionItemClass = this.options.actionItemClass || ActionItem;
    const item = new actionItemClass();
    item.verboseNameInput = new FieldInput().deserializeFromStatic('value', 'New Action');
    const control = this.form.createItem(item);
    this.form.arrayAppend(control);
    this.editItem(control);
  }

  removeItem(control: AbstractControl) {
    this.form.arrayRemove(control);
    this.cd.markForCheck();
  }

  editItem(control: AbstractControl, remove = false) {
    this.customizeBarService
      .customizeActionItem({
        options: {
          ...this.options,
          actionItem: control.value,
          actionLabel: this.actionLabel,
          visibleInput: control.value.visibleInput
        },
        context: this.customizeBarContext,
        viewContext: this.context,
        viewContextElement: this.contextElement,
        viewContextElementPath: this.contextElementPath,
        viewContextElementPaths: this.contextElementPaths,
        deleteEnabled: remove,
        object: this.object,
        append: true,
        firstInit: this.firstInit
      })
      .pipe(untilDestroyed(this))
      .subscribe(e => {
        if (e.type == CustomizeBarEditEventType.Created || e.type == CustomizeBarEditEventType.Updated) {
          const instance = e.args['result'] as ActionItem;
          const visibleInput = e.args['visible_input'] as FieldInput;

          if (this.options.visibleEditable && instance instanceof ViewSettingsAction) {
            instance.visibleInput = visibleInput;
          }

          control.patchValue(instance);
        } else if (e.type == CustomizeBarEditEventType.Deleted) {
          this.removeItem(control);
        }
      });
  }
}

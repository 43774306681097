import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, publishLast, refCount, switchMap } from 'rxjs/operators';

import { ApiService } from '@modules/api';

import { Widget } from '../../data/widgets/items/widget';
import { getWidgetByType } from '../../data/widgets/widgets';

@Injectable({
  providedIn: 'root'
})
export class WidgetService {
  constructor(private http: HttpClient, private apiService: ApiService) {}

  get(projectName: string, params = {}): Observable<Widget[]> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.projectMethodURL(projectName, 'widgets/');
        let headers = new HttpHeaders();
        const httpParams = new HttpParams({ fromObject: params });

        headers = this.apiService.setHeadersToken(headers);

        return this.http.get<Object[]>(url, { headers: headers, params: httpParams });
      }),
      map(result =>
        result.map(item => {
          const element = getWidgetByType(item['widget_type']);

          if (!element) {
            return new Widget().deserialize(item);
          }

          return new element().deserialize(item);
        })
      ),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  getDetail(projectName: string, id): Observable<Widget> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.projectMethodURL(projectName, `widgets/${id}/`);
        let headers = new HttpHeaders();

        headers = this.apiService.setHeadersToken(headers);

        return this.http.get<Object>(url, { headers: headers });
      }),
      map(result => {
        const element = getWidgetByType(result['type']);

        if (!element) {
          return new Widget().deserialize(result);
        }

        return new element().deserialize(result);
      }),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  create(projectName: string, widget: Widget): Observable<Widget> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.projectMethodURL(projectName, `widgets/`);
        let headers = new HttpHeaders();

        headers = this.apiService.setHeadersToken(headers);

        return this.http.post<Object>(url, widget.serialize(), { headers: headers });
      }),
      map(result => {
        const element = getWidgetByType(result['widget_type']);

        if (!element) {
          return new Widget().deserialize(result);
        }

        return new element().deserialize(result);
      }),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  update(projectName: string, widget: Widget, fields?: string[]): Observable<Widget> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.projectMethodURL(projectName, `widgets/${widget.id}/`);
        let headers = new HttpHeaders();

        headers = this.apiService.setHeadersToken(headers);

        return this.http.patch<Object>(url, widget.serialize(fields), { headers: headers });
      }),
      map(result => {
        const widgetType = widget.constructor as typeof Widget;
        return new widgetType().deserialize(result);
      }),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  delete(projectName: string, widget: Widget): Observable<boolean> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.projectMethodURL(projectName, `widgets/${widget.id}/`);
        let headers = new HttpHeaders();

        headers = this.apiService.setHeadersToken(headers);

        return this.http.delete(url, { headers: headers });
      }),
      map(() => true),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }
}

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Injector } from '@angular/core';
import { FormArray } from '@angular/forms';
import range from 'lodash/range';
import { ConditionCustomizeWorkflowStepItemControl } from './condition-customize-workflow-step-item.control';
var ConditionCustomizeWorkflowStepItemArray = /** @class */ (function (_super) {
    __extends(ConditionCustomizeWorkflowStepItemArray, _super);
    function ConditionCustomizeWorkflowStepItemArray(injector) {
        var _this = _super.call(this, []) || this;
        _this.injector = injector;
        return _this;
    }
    ConditionCustomizeWorkflowStepItemArray.prototype.init = function (items, options) {
        var _this = this;
        if (options === void 0) { options = {}; }
        var controls = items.map(function (item) {
            var control = _this.createControl();
            control.init(item, options);
            return control;
        });
        this.setControls(controls);
    };
    ConditionCustomizeWorkflowStepItemArray.prototype.createControl = function () {
        return Injector.create({
            providers: [{ provide: ConditionCustomizeWorkflowStepItemControl, deps: [] }],
            parent: this.injector
        }).get(ConditionCustomizeWorkflowStepItemControl);
    };
    ConditionCustomizeWorkflowStepItemArray.prototype.removeControl = function (control) {
        var index = this.controls.findIndex(function (item) { return item === control; });
        this.removeAt(index);
    };
    ConditionCustomizeWorkflowStepItemArray.prototype.setControls = function (controls) {
        var _this = this;
        this.removeControls();
        controls.forEach(function (item) { return _this.push(item); });
    };
    ConditionCustomizeWorkflowStepItemArray.prototype.removeControls = function () {
        var _this = this;
        range(this.controls.length).forEach(function () { return _this.removeAt(0); });
    };
    ConditionCustomizeWorkflowStepItemArray.prototype.submit = function () {
        return this.controls.map(function (item) { return item.submit(); });
    };
    return ConditionCustomizeWorkflowStepItemArray;
}(FormArray));
export { ConditionCustomizeWorkflowStepItemArray };

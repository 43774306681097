import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-demo-block',
  templateUrl: './demo-block.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DemoBlockComponent implements OnInit {
  @Input() fill = false;

  constructor() {}

  ngOnInit() {}
}

import { ChangeDetectorRef, EventEmitter, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import isArray from 'lodash/isArray';
import isPlainObject from 'lodash/isPlainObject';
import toPairs from 'lodash/toPairs';
import { isSet, limitObjectLength } from '@shared';
var QueryBuilderResponseTransformComponent = /** @class */ (function () {
    function QueryBuilderResponseTransformComponent(cd) {
        this.cd = cd;
        this.paths = [];
        this.emptyLabel = 'Transform';
        this.selectedPath = new EventEmitter();
        this.selectedJS = new EventEmitter();
        this.currentValueDisplay = [];
    }
    QueryBuilderResponseTransformComponent.prototype.ngOnInit = function () { };
    QueryBuilderResponseTransformComponent.prototype.ngOnChanges = function (changes) {
        if (changes['response']) {
            this.updatePathItems();
        }
        if (changes['pathEnabled'] || changes['pathValues']) {
            this.updateCurrentValue();
        }
    };
    QueryBuilderResponseTransformComponent.prototype.updatePathItems = function () {
        this.pathItems = isSet(this.response) ? this.processNode(this.response) : undefined;
        this.cd.markForCheck();
    };
    QueryBuilderResponseTransformComponent.prototype.updateCurrentValue = function () {
        var _this = this;
        if (!this.pathEnabled) {
            this.currentValueDisplay = ['JavaScript transform'];
            this.currentValueIcon = 'arrow_down_2';
        }
        else {
            var path = this.paths.find(function (item) { return item.name == _this.pathEnabled; });
            var pathValue = path ? this.pathValues[path.name] : undefined;
            if (path && pathValue && pathValue.length) {
                this.currentValueDisplay = [path.label].concat(pathValue);
                this.currentValueIcon = undefined;
            }
            else {
                this.currentValueDisplay = [this.emptyLabel];
                this.currentValueIcon = 'arrow_down_2';
            }
        }
        this.cd.markForCheck();
    };
    QueryBuilderResponseTransformComponent.prototype.processNode = function (node, path) {
        var _this = this;
        if (path === void 0) { path = []; }
        var response = limitObjectLength(node, 20);
        if (isArray(response)) {
            return response.map(function (value, i) {
                var itemPath = path.concat([i.toString()]);
                return {
                    label: "Element #" + (i + 1),
                    response: value,
                    path: itemPath,
                    children: _this.processNode(value, itemPath)
                };
            });
        }
        else if (isPlainObject(response)) {
            return toPairs(response).map(function (_a) {
                var key = _a[0], value = _a[1];
                var itemPath = path.concat([key]);
                return {
                    label: key,
                    response: value,
                    path: itemPath,
                    children: _this.processNode(value, itemPath)
                };
            });
        }
        else {
            return [];
        }
    };
    QueryBuilderResponseTransformComponent.prototype.close = function () {
        this.responseTrigger.closeMenu();
    };
    return QueryBuilderResponseTransformComponent;
}());
export { QueryBuilderResponseTransformComponent };

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "@angular/cdk/overlay";
import * as i3 from "../view-editor-menu-edit/view-editor-menu-edit.component.ngfactory";
import * as i4 from "../view-editor-menu-edit/view-editor-menu-edit.component";
import * as i5 from "../../services/view-editor-context/view-editor.context";
import * as i6 from "@angular/cdk/bidi";
import * as i7 from "./view-editor-menu-root.component";
import * as i8 from "../../services/custom-view-templates-controller/custom-view-templates.controller";
var styles_ViewEditorMenuRootComponent = [];
var RenderType_ViewEditorMenuRootComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ViewEditorMenuRootComponent, data: {} });
export { RenderType_ViewEditorMenuRootComponent as RenderType_ViewEditorMenuRootComponent };
function View_ViewEditorMenuRootComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "compact-select-options__item compact-select-option"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        _co.openCustomViewTemplates();
        var pd_0 = (_co.setDropdownOpened(false) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "div", [["class", "compact-select-option__main"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 2, "div", [["class", "compact-select-option__row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "div", [["class", "compact-select-option__title"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Import from Marketplace"]))], null, null); }
function View_ViewEditorMenuRootComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 44, "div", [["class", "compact-select-options compact-select-options_max-height_l"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ViewEditorMenuRootComponent_2)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(3, 0, null, null, 4, "div", [["class", "compact-select-options__item compact-select-option"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        _co.importFigmaNode.emit();
        var pd_0 = (_co.setDropdownOpened(false) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 3, "div", [["class", "compact-select-option__main"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 2, "div", [["class", "compact-select-option__row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 1, "div", [["class", "compact-select-option__title"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Import from Figma"])), (_l()(), i0.ɵeld(8, 0, null, null, 4, "div", [["class", "compact-select-options__item compact-select-option"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        _co.importSketchFile.emit();
        var pd_0 = (_co.setDropdownOpened(false) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 3, "div", [["class", "compact-select-option__main"]], null, null, null, null, null)), (_l()(), i0.ɵeld(10, 0, null, null, 2, "div", [["class", "compact-select-option__row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(11, 0, null, null, 1, "div", [["class", "compact-select-option__title"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Import from Sketch file"])), (_l()(), i0.ɵeld(13, 0, null, null, 0, "div", [["class", "compact-select-options__separator"]], null, null, null, null, null)), (_l()(), i0.ɵeld(14, 0, null, null, 7, "div", [["cdkOverlayOrigin", ""], ["class", "compact-select-options__item compact-select-option"]], [[2, "compact-select-option_active", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.setDropdownOpened(false) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(15, 16384, [["trigger", 4]], 0, i2.CdkOverlayOrigin, [i0.ElementRef], null, null), (_l()(), i0.ɵeld(16, 0, null, null, 3, "div", [["class", "compact-select-option__main"]], null, null, null, null, null)), (_l()(), i0.ɵeld(17, 0, null, null, 2, "div", [["class", "compact-select-option__row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(18, 0, null, null, 1, "div", [["class", "compact-select-option__title"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Edit"])), (_l()(), i0.ɵeld(20, 0, null, null, 1, "div", [["class", "compact-select-option__right"]], null, null, null, null, null)), (_l()(), i0.ɵeld(21, 0, null, null, 0, "div", [["class", "compact-select-option__arrow icon-arrow_forward_2"]], null, null, null, null, null)), (_l()(), i0.ɵeld(22, 0, null, null, 1, "app-view-editor-menu-edit", [], null, [[null, "close"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("close" === en)) {
        var pd_0 = (_co.setDropdownOpened(false) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_ViewEditorMenuEditComponent_0, i3.RenderType_ViewEditorMenuEditComponent)), i0.ɵdid(23, 245760, [["menu_edit", 4]], 0, i4.ViewEditorMenuEditComponent, [i5.ViewEditorContext, i0.ChangeDetectorRef], { trigger: [0, "trigger"] }, { close: "close" }), (_l()(), i0.ɵeld(24, 0, null, null, 4, "div", [["class", "compact-select-options__item compact-select-option"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        _co.rename.emit();
        var pd_0 = (_co.setDropdownOpened(false) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(25, 0, null, null, 3, "div", [["class", "compact-select-option__main"]], null, null, null, null, null)), (_l()(), i0.ɵeld(26, 0, null, null, 2, "div", [["class", "compact-select-option__row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(27, 0, null, null, 1, "div", [["class", "compact-select-option__title"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Rename component"])), (_l()(), i0.ɵeld(29, 0, null, null, 4, "div", [["class", "compact-select-options__item compact-select-option"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        _co.editorContext.customizeView();
        var pd_0 = (_co.setDropdownOpened(false) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(30, 0, null, null, 3, "div", [["class", "compact-select-option__main"]], null, null, null, null, null)), (_l()(), i0.ɵeld(31, 0, null, null, 2, "div", [["class", "compact-select-option__row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(32, 0, null, null, 1, "div", [["class", "compact-select-option__title"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Canvas setup"])), (_l()(), i0.ɵeld(34, 0, null, null, 0, "div", [["class", "compact-select-options__separator"]], null, null, null, null, null)), (_l()(), i0.ɵeld(35, 0, null, null, 4, "div", [["class", "compact-select-options__item compact-select-option"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        _co.saveEditor.emit();
        var pd_0 = (_co.setDropdownOpened(false) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(36, 0, null, null, 3, "div", [["class", "compact-select-option__main"]], null, null, null, null, null)), (_l()(), i0.ɵeld(37, 0, null, null, 2, "div", [["class", "compact-select-option__row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(38, 0, null, null, 1, "div", [["class", "compact-select-option__title"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Save component and close"])), (_l()(), i0.ɵeld(40, 0, null, null, 4, "div", [["class", "compact-select-options__item compact-select-option"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        _co.closeEditor.emit();
        var pd_0 = (_co.setDropdownOpened(false) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(41, 0, null, null, 3, "div", [["class", "compact-select-option__main"]], null, null, null, null, null)), (_l()(), i0.ɵeld(42, 0, null, null, 2, "div", [["class", "compact-select-option__row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(43, 0, null, null, 1, "div", [["class", "compact-select-option__title"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Close"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.templatesEnabled; _ck(_v, 2, 0, currVal_0); var currVal_2 = i0.ɵnov(_v, 15); _ck(_v, 23, 0, currVal_2); }, function (_ck, _v) { var currVal_1 = i0.ɵnov(_v, 23).dropdownOpened; _ck(_v, 14, 0, currVal_1); }); }
export function View_ViewEditorMenuRootComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵand(16777216, null, null, 2, function (_v, en, $event) { var ad = true; var _co = _v.component; if (("backdropClick" === en)) {
        var pd_0 = (_co.setDropdownOpened(false) !== false);
        ad = (pd_0 && ad);
    } if (("detach" === en)) {
        var pd_1 = (_co.setDropdownOpened(false) !== false);
        ad = (pd_1 && ad);
    } return ad; }, View_ViewEditorMenuRootComponent_1)), i0.ɵdid(1, 671744, null, 0, i2.CdkConnectedOverlay, [i2.Overlay, i0.TemplateRef, i0.ViewContainerRef, i2.ɵc, [2, i6.Directionality]], { origin: [0, "origin"], positions: [1, "positions"], backdropClass: [2, "backdropClass"], panelClass: [3, "panelClass"], open: [4, "open"], hasBackdrop: [5, "hasBackdrop"], growAfterOpen: [6, "growAfterOpen"], push: [7, "push"] }, { backdropClick: "backdropClick", detach: "detach" }), i0.ɵpad(2, 2)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.trigger; var currVal_1 = _co.dropdownPositions; var currVal_2 = "popover2-backdrop"; var currVal_3 = _ck(_v, 2, 0, "overlay", "overlay_horizontal_center"); var currVal_4 = _co.dropdownOpened; var currVal_5 = true; var currVal_6 = true; var currVal_7 = true; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }, null); }
export function View_ViewEditorMenuRootComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-view-editor-menu-root", [], null, null, null, View_ViewEditorMenuRootComponent_0, RenderType_ViewEditorMenuRootComponent)), i0.ɵdid(1, 245760, null, 0, i7.ViewEditorMenuRootComponent, [i5.ViewEditorContext, i8.CustomViewTemplatesController, i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ViewEditorMenuRootComponentNgFactory = i0.ɵccf("app-view-editor-menu-root", i7.ViewEditorMenuRootComponent, View_ViewEditorMenuRootComponent_Host_0, { trigger: "trigger", componentLabel: "componentLabel", submitLabel: "submitLabel", stateSelectedEnabled: "stateSelectedEnabled", templatesEnabled: "templatesEnabled", analyticsSource: "analyticsSource" }, { saveEditor: "saveEditor", closeEditor: "closeEditor", rename: "rename", importFigmaNode: "importFigmaNode", importSketchFile: "importSketchFile" }, []);
export { ViewEditorMenuRootComponentNgFactory as ViewEditorMenuRootComponentNgFactory };

<div #sidePopupWrap class="side-popup">
  <div
    #sidePopup
    [xsScrollableOptions]="{ stopPropagationAlways: true }"
    [style.padding-bottom.px]="formHeight"
    class="side-popup__inner side-popup__inner_timeline"
    xsScrollable
  >
    <div #header class="side-popup__header task-popup__header">
      <div class="side-popup__header-inner">
        <div class="side-popup__header-top">
          <h2 *ngIf="task?.name" class="side-popup__title">Task {{ task.name }}</h2>
          <span #closeButton (click)="close()" class="side-popup__close icon-close"></span>
        </div>
      </div>
    </div>
    <div class="task-popup__info" [formGroup]="taskForm">
      <ng-container *ngIf="task">
        <div class="task-popup__row">
          <div class="task-popup__cell task-popup__cell_one">
            <span class="task-popup__cell-title">Assign To</span>
          </div>
          <div class="task-popup__cell task-popup__cell_two task-popup__cell_clickable">
            <ng-container *ngIf="assignedInputVisible">
              <gxs-select
                formControlName="assigned"
                [source]="projectUserSelectSource"
                [options]="{
                  theme: 'jet',
                  search: true,
                  searchMinimumLength: 1,
                  searchDebounce: 200,
                  classes: ['input_fill'],
                  labels: {} | localizeSelect
                }"
                (change)="inputChanged('assigned')"
                #assigned
              >
              </gxs-select>
            </ng-container>
            <ng-container *ngIf="!assignedInputVisible">
              <div class="task-popup__cell-inner" (click)="openSelect('assigned')">
                <div class="task-popup__cell-border"></div>
                <div class="task-popup__cell-user">
                  <div
                    [style.background-image]="task.assigned?.photo ? 'url(' + task.assigned.photo + ')' : ''"
                    class="task-popup__cell-user-avatar"
                  ></div>
                  <span class="task-popup__cell-user-name">{{
                    task.assigned?.strFull ? task.assigned.strFull : 'Unassigned'
                  }}</span>
                </div>
              </div>
            </ng-container>
          </div>
        </div>

        <div class="task-popup__row">
          <div class="task-popup__cell task-popup__cell_one">
            <span class="task-popup__cell-title">Status</span>
          </div>
          <div class="task-popup__cell task-popup__cell_two task-popup__cell_clickable">
            <ng-container *ngIf="statusInputVisible">
              <gxs-select
                #status
                [options]="{
                  theme: 'jet',
                  search: true,
                  searchMinimumLength: 1,
                  searchDebounce: 0,
                  labels: {} | localizeSelect,
                  classes: ['input_fill']
                }"
                formControlName="status"
                (change)="inputChanged('status')"
              >
                <gxs-option *ngFor="let item of statusOptions" [name]="item.name" [value]="item.value"></gxs-option>
              </gxs-select>
            </ng-container>
            <ng-container *ngIf="!statusInputVisible">
              <div class="task-popup__cell-inner" (click)="openSelect('status')">
                <div class="task-popup__cell-border"></div>
                <span class="status">{{ task.status.name }}</span>
              </div>
            </ng-container>
          </div>
        </div>

        <div class="task-popup__row">
          <div class="task-popup__cell task-popup__cell_one">
            <span class="task-popup__cell-title">Priority</span>
          </div>
          <div class="task-popup__cell task-popup__cell_two task-popup__cell_clickable">
            <ng-container *ngIf="priorityInputVisible">
              <gxs-select
                #priority
                [options]="{
                  theme: 'jet',
                  search: true,
                  searchMinimumLength: 1,
                  searchDebounce: 0,
                  labels: {} | localizeSelect,
                  classes: ['input_fill']
                }"
                formControlName="priority"
                (change)="inputChanged('priority')"
              >
                <gxs-option *ngFor="let item of priorityOptions" [name]="item.name" [value]="item.value"></gxs-option>
              </gxs-select>
            </ng-container>
            <ng-container *ngIf="!priorityInputVisible">
              <div class="task-popup__cell-inner" (click)="openSelect('priority')">
                <div class="task-popup__cell-border"></div>
                <span
                  [ngClass]="{
                    'color_green_1 background-color_green_2': task.priority === 'low' || task.priority === 'lowest',
                    'color_yellow_1 background-color_yellow_2': task.priority === 'medium',
                    'color_red_1 background-color_red_2': task.priority === 'high' || task.priority === 'highest'
                  }"
                  class="status"
                  >{{ task.priority }}</span
                >
              </div>
            </ng-container>
          </div>
        </div>

        <div *ngIf="relatedObject" class="task-popup__row">
          <div class="task-popup__cell task-popup__cell_one">
            <span class="task-popup__cell-title">Related to</span>
          </div>
          <div class="task-popup__cell task-popup__cell_two">
            <span class="task-popup__cell-related">
              <a [appLink]="relatedObject.link">{{ relatedObject.name }}</a>
            </span>
          </div>
        </div>

        <div class="task-popup__row">
          <div class="task-popup__cell task-popup__cell_one">
            <span class="task-popup__cell-title">Author</span>
          </div>
          <div class="task-popup__cell task-popup__cell_two">
            <div class="task-popup__cell-user">
              <div
                [style.background-image]="task.author?.photo ? 'url(' + task.author.photo + ')' : ''"
                class="task-popup__cell-user-avatar"
              ></div>
              <span class="task-popup__cell-user-name">{{ task.author.strFull }}</span>
            </div>
          </div>
        </div>

        <ng-container *ngIf="paramsList.length">
          <span class="task-popup__title-separator">Details</span>
          <div class="task-popup__row task-popup__row_auto-field" *ngFor="let param of paramsList">
            <div class="task-popup__cell task-popup__cell_one">
              <span class="task-popup__cell-title">{{ param.name }}</span>
            </div>
            <div class="task-popup__cell task-popup__cell_two">
              <span class="task-popup__cell-auto-filed">
                <app-auto-field
                  [value]="param.value"
                  [label]="false"
                  [readonly]="true"
                  [field]="createField({ name: param.name, field: param.type, params: { classes: ['input_fill'] } })"
                ></app-auto-field>
              </span>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </div>
    <div class="task-popup__comments" #comments>
      <span class="task-popup__title-separator" *ngIf="userActivities?.length">Comments</span>
      <ng-container *ngFor="let activity of userActivities; trackBy: trackByFn">
        <app-message-user-activity
          *ngIf="activity.isMessageActivityType()"
          [item]="activity"
        ></app-message-user-activity>
      </ng-container>
    </div>
  </div>
  <div #footer class="side-popup__footer side-popup__footer_timeline">
    <app-timeline-popup-footer
      [params]="params"
      [disabled]="sendMessage"
      (send)="submit($event)"
      (messageChange)="setFormBottomPadding()"
    ></app-timeline-popup-footer>
    <ng-container *ngIf="task?.approveAction">
      <div *ngIf="!task.approveActionResult && assignedToMe" class="side-popup__buttons">
        <div class="side-popup__buttons-item">
          <button type="button" class="button button_danger button_fill" (click)="$event.stopPropagation(); reject()">
            Reject
          </button>
        </div>
        <div class="side-popup__buttons-item">
          <button type="button" class="button button_green button_fill" (click)="$event.stopPropagation(); approve()">
            Approve
          </button>
        </div>
      </div>
      <div *ngIf="task.approveActionResult?.state == approveResultStates.Approved" class="side-popup__buttons">
        <div class="side-popup__buttons-item">
          <app-alert [title]="'Action approved'" [success]="true"></app-alert>
        </div>
      </div>
      <div *ngIf="task.approveActionResult?.state == approveResultStates.Rejected" class="side-popup__buttons">
        <div class="side-popup__buttons-item">
          <app-alert [title]="'Action rejected'" [danger]="true" [border]="true">
            {{ task.approveActionResult.reason }}
          </app-alert>
        </div>
      </div>
    </ng-container>
  </div>
  <!--  <div *ngIf="items?.length === 0" class="side-popup__error">-->
  <!--    <app-error [title]="'Start chatting with your team'">-->
  <!--      Take a message about this record to keep track of important info.-->
  <!--      <br />-->
  <!--      <br />-->
  <!--      <button *ngIf="hasFilters()" (click)="resetFilter()" class="button button_primary button_bigger">-->
  <!--        Reset Filters-->
  <!--      </button>-->
  <!--    </app-error>-->
  <!--  </div>-->
</div>

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output
} from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import toPairs from 'lodash/toPairs';

import { emojiCategories, getEmojiConverter, getEmojiData, parseEmojiColon } from '@modules/icons';
import { isSet, TypedChanges } from '@shared';

interface Category {
  name: string;
  items: { value: string; name: string; html: SafeHtml }[];
}

const convertor = getEmojiConverter({ sprite: true });

@Component({
  selector: 'app-icon-selector-emojis',
  templateUrl: './icon-selector-emojis.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IconSelectorEmojisComponent implements OnInit, OnChanges {
  @Input() current: string;
  @Input() search = '';
  @Input() loading = false;
  @Output() selected = new EventEmitter<string>();

  categories: Category[] = [];
  filteredCategories: Category[] = [];

  constructor(private sanitizer: DomSanitizer, private cd: ChangeDetectorRef) {}

  ngOnInit() {
    this.updateCategories();
    this.updateFilteredCategories();
  }

  ngOnChanges(changes: TypedChanges<IconSelectorEmojisComponent>): void {
    if (changes.search) {
      this.search = this.search.trim().toLowerCase();
    }

    if (changes.search && !changes.search.firstChange) {
      this.updateFilteredCategories();
    }
  }

  updateCategories() {
    this.categories = toPairs(emojiCategories).map(([category, groups]) => {
      const items = toPairs(groups).reduce((acc, [group, shortNames]) => {
        shortNames.forEach(shortName => {
          const emoji = getEmojiData(shortName);
          if (emoji) {
            const value = `:${emoji.short_name}:`;
            const html = convertor.replace_colons(value);
            const htmlColon = parseEmojiColon(html);

            if (isSet(htmlColon)) {
              return;
            }

            acc.push({
              value: value,
              name: emoji.name.toLowerCase(),
              html: this.sanitizer.bypassSecurityTrustHtml(html)
            });
          }
        });

        return acc;
      }, []);

      return {
        name: category,
        items: items
      };
    });
    this.cd.markForCheck();
  }

  updateFilteredCategories() {
    if (!isSet(this.search)) {
      this.filteredCategories = this.categories;
    } else {
      this.filteredCategories = this.categories
        .map(category => {
          return {
            ...category,
            items: category.items.filter(item => {
              return [item.name, item.value].some(str => str.toLowerCase().includes(this.search));
            })
          };
        })
        .filter(category => category.items.length);
    }

    this.cd.markForCheck();
  }
}

import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';

import { ActionType } from '@modules/actions';
import { ViewContext, ViewContextElement } from '@modules/customize';
import { QueryBuilderContext } from '@modules/queries-components';

import { CustomizeBarEditEvent } from '../../../data/customize-bar-edit-event';
import { CustomizeBarEditEventType } from '../../../data/customize-bar-edit-event-type';
import { CustomizeBarActionEditTypeComponent } from '../customize-bar-action-edit-type/customize-bar-action-edit-type.component';
import { CustomizeActionOptions, CustomizeBarActionEditForm } from '../customize-bar-action-edit.form';

@Component({
  selector: 'app-customize-bar-action-edit-operation',
  templateUrl: './customize-bar-action-edit-operation.component.html',
  providers: [QueryBuilderContext],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomizeBarActionEditOperationComponent implements OnInit {
  @Input() wrapper = true;
  @Input() options: CustomizeActionOptions = {};
  @Input() form: CustomizeBarActionEditForm;
  @Input() label: string;
  @Input() context: ViewContext;
  @Input() contextElement: ViewContextElement;
  @Input() contextElementPath: (string | number)[];
  @Input() contextElementPaths: (string | number)[][];
  @Input() firstInit = false;
  @Input() setupOnCreate = false;
  @Input() object: string;
  @Output() submitImmediate = new EventEmitter<void>();
  @Output() event = new EventEmitter<CustomizeBarEditEvent>();

  @ViewChild(CustomizeBarActionEditTypeComponent) editTypeComponent: CustomizeBarActionEditTypeComponent;

  actionTypes = ActionType;
  operationTypeValid$: Observable<boolean>;

  ngOnInit(): void {
    this.operationTypeValid$ = this.form.operationTypeValid$();
  }

  submit() {
    this.event.emit({ type: CustomizeBarEditEventType.Updated, args: { result: this.form.submit() } });
  }

  cancel() {
    this.event.emit({ type: CustomizeBarEditEventType.Canceled });
  }

  addQueryInput() {
    if (this.editTypeComponent) {
      this.editTypeComponent.addQueryInput();
    }
  }
}

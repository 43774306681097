<div *ngIf="items?.length" class="sidebar__element sidebar__element_margin_l">
  <div class="sidebar-float-card">
    <div class="sidebar-float-card__header">Related components</div>
    <div class="sidebar-float-card__content">
      <div class="sidebar-float-card__content-inner">
        <div class="sidebar__list sidebar__list_padding_s">
          <div
            class="sidebar-list sidebar-list_third"
            appDropList
            [appDropListConnectedToGroup]="context.dropListGroups"
            [appDropListData]="displayItems"
            [appDropListEnterPredicate]="isDroppable"
            [appDropListSortingDisabled]="true"
            [appDropListCloneItems]="true"
            [appDropListOverrideViewport]="viewport"
            [appDropListOutsideIndicator]="true"
          >
            <!--      <div *ngFor="let item of displayItems" appDrag [appDragData]="item" class="sidebar-list__item">-->
            <div
              *ngFor="let item of displayItems"
              appDrag
              [appDragData]="item"
              class="sidebar-list__item sidebar-list__item_third"
            >
              <!--  TODO: Circular dependendcy  -->
              <!--              <app-element-placeholder-stub-->
              <!--                *appDragPlaceholder-->
              <!--                [elementType]="item.type"-->
              <!--                class="element-placeholder"-->
              <!--              ></app-element-placeholder-stub>-->

              <div *appDragPlaceholder class="element-placeholder">
                <div
                  class="element-wrapper element-wrapper_wrap"
                  [attr.element-groupable]="isGroupable(item.type) ? true : null"
                >
                  <div class="element-wrapper__inner">
                    <div class="element-placeholder-stub">
                      <div class="element-placeholder-stub__inner"></div>
                    </div>
                  </div>
                </div>
              </div>

              <app-sidebar-image-card
                [title]="item.title"
                [image]="item.image"
                [icon]="item.icon"
              ></app-sidebar-image-card>
            </div>
            <div *ngIf="items.length > showCollapsedMax" class="sidebar-list__buttons">
              <a
                *ngIf="collapsed"
                href="javascript:void(0)"
                class="button button_orange-transparent button_small sidebar-list__buttons-item"
                (click)="setCollapsed(false)"
              >
                <span class="icon-arrow_down_2 button__icon button__icon_left"></span>
                <span class="button__label">Show all Components ({{ items.length }})</span>
              </a>
              <a
                *ngIf="!collapsed"
                href="javascript:void(0)"
                class="button button_orange-transparent button_small sidebar-list__buttons-item"
                (click)="setCollapsed(true)"
              >
                <span class="icon-arrow_up_2 button__icon button__icon_left"></span>
                <span class="button__label">Collapse Components</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

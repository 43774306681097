import { combineLatest, of, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
var CustomViewLoaderService = /** @class */ (function () {
    function CustomViewLoaderService() {
    }
    CustomViewLoaderService.prototype.load = function (view) {
        var _this = this;
        if (this.isLoaded(view.tagName)) {
            return of(true);
        }
        var obs = [];
        if (view.filesJs) {
            view.filesJs.forEach(function (item) {
                obs.push(_this.loadScript("" + view.distBaseAbsoluteUrl + item));
            });
        }
        if (view.filesCss) {
            view.filesCss.forEach(function (item) {
                obs.push(_this.loadStyle("" + view.distBaseAbsoluteUrl + item));
            });
        }
        if (!obs.length) {
            return of(true);
        }
        return combineLatest(obs).pipe(map(function (result) { return result.every(function (item) { return item; }); }));
    };
    CustomViewLoaderService.prototype.isLoaded = function (name) {
        return !!customElements.get(name);
    };
    CustomViewLoaderService.prototype.loadScript = function (url) {
        var result = new ReplaySubject(1);
        var node = document.createElement('script');
        node.src = url;
        node.type = 'text/javascript';
        node.async = true;
        node.charset = 'utf-8';
        node.onload = function () { return result.next(true); };
        node.onerror = function () { return result.next(false); };
        document.getElementsByTagName('body')[0].appendChild(node);
        return result.asObservable();
    };
    CustomViewLoaderService.prototype.loadStyle = function (url) {
        var result = new ReplaySubject(1);
        var node = document.createElement('link');
        node.href = url;
        node.type = 'text/css';
        node.rel = 'stylesheet';
        node.onload = function () { return result.next(true); };
        node.onerror = function () { return result.next(false); };
        document.getElementsByTagName('body')[0].appendChild(node);
        return result.asObservable();
    };
    CustomViewLoaderService.ngInjectableDef = i0.defineInjectable({ factory: function CustomViewLoaderService_Factory() { return new CustomViewLoaderService(); }, token: CustomViewLoaderService, providedIn: "root" });
    return CustomViewLoaderService;
}());
export { CustomViewLoaderService };

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FormControl, FormGroup } from '@angular/forms';
var CustomizeBarCustomCodeEditForm = /** @class */ (function (_super) {
    __extends(CustomizeBarCustomCodeEditForm, _super);
    function CustomizeBarCustomCodeEditForm() {
        return _super.call(this, {
            append_scripts: new FormControl(''),
            append_styles: new FormControl('')
        }) || this;
    }
    CustomizeBarCustomCodeEditForm.prototype.init = function (appendScripts, appendStyles) {
        this.controls.append_scripts.patchValue(appendScripts);
        this.controls.append_styles.patchValue(appendStyles);
        this.markAsPristine();
    };
    return CustomizeBarCustomCodeEditForm;
}(FormGroup));
export { CustomizeBarCustomCodeEditForm };

<ng-container *ngIf="!loading">
  <div *ngFor="let category of filteredCategories" class="select-icon__section">
    <div class="select-icon__section-title">
      {{ category.name }}
    </div>

    <div class="select-icon__section-content">
      <div *ngFor="let item of category.items" class="select-icon__item">
        <a
          href="javascript:void(0)"
          class="select-icon-item select-icon-item_interactive"
          [class.select-icon-item_current]="current == item.value"
          [appTip]="item.name"
          [appTipOptions]="{ showDelay: 300 }"
          (click)="selected.emit(item.value)"
        >
          <span class="select-icon-item__icon select-icon-item__icon_emoji" [innerHTML]="item.html"></span>
        </a>
      </div>
    </div>
  </div>

  <div *ngIf="(search | appIsSet) && !filteredCategories.length" class="select-icon__items-empty">
    No emojis found
  </div>
</ng-container>

<app-icon-selector-stub *ngIf="loading" [sectionTitle]="true"></app-icon-selector-stub>

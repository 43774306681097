import isArray from 'lodash/isArray';
import isPlainObject from 'lodash/isPlainObject';
import keys from 'lodash/keys';
import toPairs from 'lodash/toPairs';

import { isSet } from '@shared';

import { FieldType } from '../data/field-type';
import { JsonStructureNode, JsonStructureNodeType, JsonStructureObjectParams } from '../data/json-structure';
import { detectFieldByValue, registerValueDetector } from './detect-field-by-value';

function getValueNodeType(value: any): JsonStructureNodeType {
  if (isPlainObject(value)) {
    return JsonStructureNodeType.Object;
  } else if (isArray(value)) {
    return JsonStructureNodeType.Array;
  } else {
    return JsonStructureNodeType.Field;
  }
}

function getField(name: string, fieldType: FieldType, params = {}): JsonStructureNode {
  return {
    type: JsonStructureNodeType.Field,
    name: name,
    label: name,
    params: {
      field: fieldType,
      params: params,
      required: false,
      name: name,
      label: name
    }
  };
}

function getObject(name: string, items: JsonStructureNode[] = []): JsonStructureNode {
  return {
    type: JsonStructureNodeType.Object,
    name: name,
    label: name,
    params: {
      items: items
    }
  };
}

function getArray(name: string, item?: JsonStructureNode): JsonStructureNode {
  return {
    type: JsonStructureNodeType.Array,
    name: name,
    label: name,
    params: {
      item: item
    }
  };
}

function detectJSONFieldStructureNode(name: string, value: any): JsonStructureNode {
  const nodeType = getValueNodeType(value);

  if (nodeType == JsonStructureNodeType.Object) {
    const objectItems = keys(value)
      .filter(key => value.hasOwnProperty(key))
      .map(key => detectJSONFieldStructureNode(key, value[key]));
    return getObject(name, objectItems);
  } else if (nodeType == JsonStructureNodeType.Array) {
    const firstItem = value ? value[0] : undefined;
    const arrayNode =
      firstItem !== undefined && firstItem !== null
        ? detectJSONFieldStructureNode(name, firstItem)
        : {
            type: JsonStructureNodeType.Object,
            name: undefined,
            label: undefined,
            params: { items: [] } as JsonStructureObjectParams
          };
    return getArray(name, arrayNode);
  } else {
    const valueType = detectFieldByValue(value).field;
    return getField(name, valueType);
  }
}

export function detectJSONFieldStructure(value: any): JsonStructureNode {
  if (!isSet(value)) {
    return;
  }

  return detectJSONFieldStructureNode(null, value);
}

export interface DetectFirestoreJSONFieldStructureOptions {
  resource: string;
  databasePath: string;
}

export function detectFirestoreJSONFieldStructureNode(
  name: string,
  value: Object,
  options: DetectFirestoreJSONFieldStructureOptions
): JsonStructureNode {
  if (value['integerValue']) {
    return getField(name, FieldType.Number);
  } else if (value['doubleValue']) {
    return getField(name, FieldType.Number);
  } else if (value['booleanValue']) {
    return getField(name, FieldType.Boolean);
  } else if (value['referenceValue']) {
    let params = {};

    if (isSet(value['referenceValue'])) {
      const relatedModel = String(value['referenceValue'])
        .substring(options.databasePath.length + 1)
        .split('/')
        .slice(0, -1)
        .join('/');

      params = {
        related_model: {
          model: `${options.resource}.${relatedModel}`
        }
      };
    }

    return getField(name, FieldType.RelatedModel, params);
  } else if (value['timestampValue']) {
    return getField(name, FieldType.DateTime);
  } else if (value['geoPointValue']) {
    return getField(name, FieldType.Location);
  }

  if (value['mapValue']) {
    const items = value['mapValue']['fields']
      ? toPairs(value['mapValue']['fields']).map(([k, v]) => detectFirestoreJSONFieldStructureNode(k, v, options))
      : [];
    return getObject(name, items);
  } else if (value['arrayValue']) {
    const firstItem = value['arrayValue']['values'] ? value['arrayValue']['values'][0] : undefined;
    const arrayNode: JsonStructureNode =
      firstItem !== undefined && firstItem !== null
        ? detectFirestoreJSONFieldStructureNode(null, firstItem, options)
        : {
            type: JsonStructureNodeType.Object,
            name: undefined,
            label: undefined,
            params: { items: [] } as JsonStructureObjectParams
          };
    return getArray(name, arrayNode);
  }

  return getField(name, FieldType.Text);
}

export function detectFirestoreJSONFieldStructure(
  value: any,
  options: DetectFirestoreJSONFieldStructureOptions
): JsonStructureNode {
  if (!isSet(value)) {
    return;
  }

  return detectFirestoreJSONFieldStructureNode(null, value, options);
}

registerValueDetector({
  apply: value => isPlainObject(value) || isArray(value),
  handler: value => {
    const structure = detectJSONFieldStructure(value);
    return {
      field: FieldType.JSON,
      params: {
        display_fields: !!structure,
        structure: structure
      }
    };
  }
});

<ng-container *ngIf="loading">
  <app-page-block>
    <app-page-heading>Headers</app-page-heading>
  </app-page-block>

  <app-page-block>
    <div class="code-parameters">
      <div class="code-parameters__item">
        <div class="code-parameter">
          <div class="code-parameter__title">
            <span class="code-parameter__name">
              <span [class.loading-animation]="true"><span class="stub-text">Name</span></span>
            </span>
          </div>

          <div class="code-parameter__description">
            <span [class.loading-animation]="true"><span class="stub-text">Parameter description line</span></span>
          </div>

          <div class="code-parameter__value-wrapper">
            <div class="code-parameter__value">
              <span [class.loading-animation]="true"><span class="stub-text">Value</span></span>
            </div>
          </div>

          <div class="code-parameter__description">
            <span [class.loading-animation]="true"><span class="stub-text">Description</span></span>
          </div>
        </div>
      </div>
    </div>
  </app-page-block>

  <app-page-block [marginSize]="'l'">
    <hr />
  </app-page-block>
</ng-container>

<ng-container *ngIf="!loading && parameters.length">
  <app-page-block>
    <app-page-heading>Headers</app-page-heading>
  </app-page-block>

  <app-page-block>
    <div class="code-parameters">
      <div *ngFor="let item of parameters" class="code-parameters__item">
        <app-api-parameter [item]="item"></app-api-parameter>
      </div>
    </div>
  </app-page-block>

  <app-page-block [marginSize]="'l'">
    <hr />
  </app-page-block>
</ng-container>

<app-simple-menu-item-edit
  *ngIf="menuItemType == menuItemTypes.Simple"
  [control]="control"
  [layout]="layout"
  [createdOptions]="createdOptions"
  (deleteRequested)="onItemDeleted()"
></app-simple-menu-item-edit>

<app-section-menu-item-edit
  *ngIf="menuItemType == menuItemTypes.Section"
  [control]="control"
  [layout]="layout"
  [createdOptions]="createdOptions"
  (deleteRequested)="onItemDeleted()"
></app-section-menu-item-edit>

<app-model-menu-item-edit
  *ngIf="menuItemType == menuItemTypes.ModelLink"
  [control]="control"
  [layout]="layout"
  [createdOptions]="createdOptions"
  (deleteRequested)="onItemDeleted()"
></app-model-menu-item-edit>

<app-image-menu-item-edit
  *ngIf="menuItemType == menuItemTypes.Image"
  [control]="control"
  [layout]="layout"
  [createdOptions]="createdOptions"
  (deleteRequested)="onItemDeleted()"
></app-image-menu-item-edit>

<app-button-menu-item-edit
  *ngIf="menuItemType == menuItemTypes.Button"
  [control]="control"
  [layout]="layout"
  [createdOptions]="createdOptions"
  (deleteRequested)="onItemDeleted()"
></app-button-menu-item-edit>

<app-separator-menu-item-edit
  *ngIf="menuItemType == menuItemTypes.Separator"
  [control]="control"
  [layout]="layout"
  [createdOptions]="createdOptions"
  (deleteRequested)="onItemDeleted()"
></app-separator-menu-item-edit>

<app-share-menu-item-edit
  *ngIf="menuItemType == menuItemTypes.Share"
  [control]="control"
  [layout]="layout"
  [createdOptions]="createdOptions"
  (deleteRequested)="onItemDeleted()"
></app-share-menu-item-edit>

<app-custom-menu-item-edit
  *ngIf="menuItemType == menuItemTypes.Custom"
  [control]="control"
  [layout]="layout"
  [createdOptions]="createdOptions"
  (deleteRequested)="onItemDeleted()"
></app-custom-menu-item-edit>

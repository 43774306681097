import pickBy from 'lodash/pickBy';

import { ChartWidgetSimpleQuery } from './chart-widget-simple-query';
import { Query } from './query';

export class ChartWidgetQuery extends Query<ChartWidgetSimpleQuery> {
  public simpleQuery: ChartWidgetSimpleQuery;

  simpleQueryClass = ChartWidgetSimpleQuery;

  serialize(fields?: string[]): Object {
    let data: Object = super.serialize();
    if (fields) {
      data = <Object>pickBy(data, (v, k) => fields.includes(k));
    }
    return data;
  }

  isSuperConfigured(): boolean {
    return super.isConfigured();
  }
}

import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

import { ElementItem, ViewContext } from '@modules/customize';
import { DataSourceType } from '@modules/data-sources';
import { ModelDescription } from '@modules/models';
import { TypedChanges } from '@shared';

import { ModelDescriptionDataSourceControl } from '../model-description-data-source-edit/model-description-data-source';

@Component({
  selector: 'app-data-source-bind-component',
  templateUrl: './data-source-bind-component.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DataSourceBindComponentComponent implements OnInit, OnChanges {
  @Input() control: ModelDescriptionDataSourceControl;
  @Input() context: ViewContext;
  @Input() element: ElementItem;
  @Input() targetBindPrimaryKey = true;
  @Input() selfBindPrimaryKey = true;
  @Input() inline = false;
  @Input() analyticsSource: string;

  modelDescription$: Observable<ModelDescription>;
  dataSourceTypes = DataSourceType;

  constructor() {}

  ngOnInit() {}

  ngOnChanges(changes: TypedChanges<DataSourceBindComponentComponent>): void {
    if (changes.control) {
      this.modelDescription$ = this.control.getModelDescription$();
    }
  }
}

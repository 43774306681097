import { ChangeDetectorRef, OnDestroy } from '@angular/core';
import isPlainObject from 'lodash/isPlainObject';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiType } from '@modules/api';
import {
  deserializeMultipleFileValue,
  deserializeSingleFileValue,
  FieldType,
  getFieldDescriptionByType,
  ImageOutputFormat,
  Input
} from '@modules/fields';
import { ModelService } from '@modules/model-queries';
import { CurrentEnvironmentStore, CurrentProjectStore, Resource } from '@modules/projects';
import { ResourceControllerService } from '@modules/resources';
import { Storage } from '@modules/storages';
import { StorageService } from '@modules/storages-queries';
import { getExtension, getFilename, getFilenameWithExtension, isSet } from '@shared';

// TODO: Move to data layer
export const VALUE_OUTPUT = 'value';
export const UPLOAD_RESULT_OUTPUT = 'upload_result';
export const FILE_NAME_OUTPUT = 'file_name';
export const FILE_EXTENSION_OUTPUT = 'file_extension';

import { FieldComponent } from '../field/field.component';

export interface UploadedFile {
  url: string;
  filename: string;
  extension: string;
}

export abstract class UploadFieldComponent extends FieldComponent implements OnDestroy {
  storageResource: Resource;
  storage: Storage;
  storagePath: Input;
  uploadedFile: UploadedFile;
  uploadedFiles: UploadedFile[] = [];
  updateUploadedSubscription: Subscription;

  constructor(
    protected modelService: ModelService,
    protected currentProjectStore: CurrentProjectStore,
    protected currentEnvironmentStore: CurrentEnvironmentStore,
    protected storageService: StorageService,
    protected resourceControllerService: ResourceControllerService,
    protected cd: ChangeDetectorRef
  ) {
    super();
  }

  getApiInfo() {
    return this.storageResource ? this.storageResource.apiInfo : undefined;
  }

  ngOnDestroy(): void {}

  initOutputs() {
    if (this.fieldContextElement) {
      const fieldDescription = getFieldDescriptionByType(this.field.field);

      this.fieldContextElement.setOutputs([
        {
          uniqueName: VALUE_OUTPUT,
          name: 'Value',
          icon: fieldDescription.icon,
          fieldType: this.field.field,
          fieldParams: this.field.params,
          external: true
        },
        {
          uniqueName: UPLOAD_RESULT_OUTPUT,
          name: 'Upload operation result',
          icon: 'cloud_upload',
          fieldType: FieldType.JSON,
          external: true
        },
        {
          uniqueName: FILE_NAME_OUTPUT,
          name: 'File name',
          icon: 'one_of',
          fieldType: FieldType.Text
        },
        {
          uniqueName: FILE_EXTENSION_OUTPUT,
          name: 'File extension',
          icon: 'attach_clip',
          fieldType: FieldType.Text
        }
      ]);
    }
  }

  updateStorage() {
    const storage = this.currentProjectStore.instance.getStorage(
      this.currentEnvironmentStore.instance.uniqueName,
      this.field.params['storage_resource'],
      this.field.params['storage_name'],
      { defaultFirst: true }
    );

    this.storageResource = storage ? storage.resource : undefined;
    this.storage = storage ? storage.storage : undefined;

    // Backward compatibility
    if (typeof this.field.params['path'] == 'string') {
      this.storagePath = new Input().deserializeFromStatic('value', this.field.params['path']);
    } else if (isPlainObject(this.field.params['path'])) {
      this.storagePath = new Input().deserialize(this.field.params['path']);
    } else {
      this.storagePath = undefined;
    }

    this.cd.markForCheck();
  }

  getUploadUrl(value: string): string {
    if (!isSet(value)) {
      return;
    }

    const apiInfo = this.getApiInfo();

    if (
      apiInfo &&
      apiInfo.type == ApiType.JetDjango &&
      apiInfo.versionLessThan('0.7.7') &&
      !this.field.params['ignore_old_django_format']
    ) {
      value = value['value'];
    }

    if (this.field.params['output_format'] != ImageOutputFormat.URL) {
      const controller = this.storageResource
        ? this.resourceControllerService.get(this.storageResource.type)
        : undefined;

      if (controller && controller.fileUrl) {
        return controller.fileUrl(this.storageResource, value);
      }
    }

    return value;
  }

  updateUploaded() {
    if (this.field.params['multiple']) {
      this.updateMultipleUploaded();
    } else {
      this.updateSingleUploaded();
    }
  }

  updateSingleUploaded() {
    if (this.updateUploadedSubscription) {
      this.updateUploadedSubscription.unsubscribe();
    }

    this.updateUploadedSubscription = this.currentValue$
      .pipe(
        map(value => deserializeSingleFileValue(value)),
        map(value => this.getUploadUrl(value)),
        untilDestroyed(this)
      )
      .subscribe(
        result => {
          this.uploadedFile = {
            url: result,
            filename: getFilenameWithExtension(result),
            extension: getExtension(result)
          };
          this.cd.markForCheck();
          this.onUploadedUpdated();

          if (this.fieldContextElement) {
            this.fieldContextElement.setOutputValue(FILE_NAME_OUTPUT, getFilename(this.uploadedFile.url));
            this.fieldContextElement.setOutputValue(FILE_EXTENSION_OUTPUT, this.uploadedFile.extension);
          }
        },
        () => {
          this.uploadedFile = undefined;
          this.cd.markForCheck();
          this.onUploadedUpdated();

          if (this.fieldContextElement) {
            this.fieldContextElement.setOutputValue(FILE_NAME_OUTPUT, undefined);
            this.fieldContextElement.setOutputValue(FILE_EXTENSION_OUTPUT, undefined);
          }
        }
      );
  }

  updateMultipleUploaded() {
    if (this.updateUploadedSubscription) {
      this.updateUploadedSubscription.unsubscribe();
    }

    this.updateUploadedSubscription = this.currentValue$
      .pipe(
        map(value => deserializeMultipleFileValue(value)),
        map(value => value.map(item => this.getUploadUrl(item))),
        untilDestroyed(this)
      )
      .subscribe(
        result => {
          this.uploadedFiles = result.map(item => {
            return {
              url: item,
              filename: getFilenameWithExtension(item),
              extension: getExtension(item)
            };
          });
          this.cd.markForCheck();
          this.onUploadedUpdated();

          // if (this.fieldContextElement) {
          //   this.fieldContextElement.setOutputValue(FILE_NAME_OUTPUT, getFilename(this.uploadedFile.url));
          //   this.fieldContextElement.setOutputValue(FILE_EXTENSION_OUTPUT, this.uploadedFile.extension);
          // }
        },
        () => {
          // this.uploadedFiles = undefined;
          this.cd.markForCheck();
          this.onUploadedUpdated();

          // if (this.fieldContextElement) {
          //   this.fieldContextElement.setOutputValue(FILE_NAME_OUTPUT, undefined);
          //   this.fieldContextElement.setOutputValue(FILE_EXTENSION_OUTPUT, undefined);
          // }
        }
      );
  }

  onUploadedUpdated() {}
}

import { AfterViewInit, ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, ViewChild } from '@angular/core';

import { CustomViewSettings, ElementItem, ViewContext, ViewContextElement } from '@modules/customize';
import { ElementGroupsContainerDirective } from '@modules/customize-components';
import { TypedChanges } from '@shared';

@Component({
  selector: 'app-page-template-preview-page',
  templateUrl: './page-template-preview-page.component.html',
  providers: [ViewContextElement],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PageTemplatePreviewPageComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() page: CustomViewSettings;
  @Input() context: ViewContext;
  @Input() header = true;

  @ViewChild(ElementGroupsContainerDirective) elementGroupsContainer: ElementGroupsContainerDirective;

  hasRequiredParameter = false;

  trackElement = (() => {
    return (i, item: ElementItem) => {
      const pageUid =
        this.context && this.context.viewSettings && !this.context.viewSettings.newlyCreated
          ? this.context.viewSettings.uid
          : undefined;
      return [pageUid, item.uid].join('_');
    };
  })();

  constructor(private selfContext: ViewContext, private contextElementPage: ViewContextElement) {}

  ngOnInit() {
    this.context.viewSettings = this.page;

    this.contextElementPage.initGlobal({ uniqueName: 'page', name: 'Page' });
    this.contextElementPage.setOutputs(
      this.page.parameters.map(item => {
        return {
          uniqueName: item.name,
          name: item.verboseName
        };
      })
    );
  }

  ngOnChanges(changes: TypedChanges<PageTemplatePreviewPageComponent>): void {
    if (changes.page) {
      this.hasRequiredParameter = !!this.page.parameters.find(item => item.required);
    }

    if (changes.page && !changes.page.firstChange) {
      setTimeout(() => this.updateElementStatesOnStable(), 0);
    }
  }

  ngAfterViewInit(): void {
    this.updateElementStates();
  }

  updateElementStates() {
    if (this.elementGroupsContainer) {
      this.elementGroupsContainer.updateElementStates();
    }
  }

  updateElementStatesOnStable() {
    if (this.elementGroupsContainer) {
      this.elementGroupsContainer.updateElementStatesOnStable();
    }
  }
}

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { FormControl, FormGroup } from '@angular/forms';
import round from 'lodash/round';
import { Frame } from '@modules/views';
import { isSet, setControlDisabled } from '@shared';
var FrameControl = /** @class */ (function (_super) {
    __extends(FrameControl, _super);
    function FrameControl(state) {
        if (state === void 0) { state = {}; }
        var _this = _super.call(this, {
            x: new FormControl(isSet(state.x) ? state.x : 0),
            y: new FormControl(isSet(state.y) ? state.y : 0),
            width: new FormControl(isSet(state.width) ? state.width : 0),
            height: new FormControl(isSet(state.height) ? state.height : 0),
            rotation: new FormControl(isSet(state.rotation) ? state.rotation : 0)
        }) || this;
        _this.translate = { x: 0, y: 0 };
        return _this;
    }
    FrameControl.prototype.deserialize = function (value, options) {
        if (options === void 0) { options = {}; }
        this.instance = value;
        this.translate = __assign({ x: 0, y: 0 }, options.translate);
        var x = value.x + this.translate.x;
        var y = value.y + this.translate.y;
        this.controls.x.patchValue(x, { emitEvent: options.emitEvent });
        this.controls.y.patchValue(y, { emitEvent: options.emitEvent });
        this.controls.width.patchValue(value.width, { emitEvent: options.emitEvent });
        this.controls.height.patchValue(value.height, { emitEvent: options.emitEvent });
        this.controls.rotation.patchValue(value.rotation, { emitEvent: options.emitEvent });
    };
    FrameControl.prototype.getInstance = function (instance) {
        if (!instance) {
            instance = new Frame();
        }
        var x = this.controls.x.value - this.translate.x;
        var y = this.controls.y.value - this.translate.y;
        instance.x = x;
        instance.y = y;
        instance.width = this.controls.width.value;
        instance.height = this.controls.height.value;
        instance.rotation = this.controls.rotation.value;
        return instance;
    };
    FrameControl.prototype.serialize = function () {
        return this.getInstance(this.instance);
    };
    FrameControl.prototype.getProportion = function () {
        var width = this.controls.width.value;
        var height = this.controls.height.value;
        return height != 0 ? width / height : 1;
    };
    FrameControl.prototype.updateWidthProportional = function (proportion) {
        if (!proportion) {
            return;
        }
        var height = this.controls.height.value;
        var width = round(height * proportion, 2);
        this.controls.width.patchValue(width);
    };
    FrameControl.prototype.updateHeightProportional = function (proportion) {
        if (!proportion) {
            return;
        }
        var width = this.controls.width.value;
        var height = round(width / proportion, 2);
        this.controls.height.patchValue(height);
    };
    FrameControl.prototype.setPositionDisabled = function (disabled) {
        setControlDisabled(this.controls.x, disabled);
        setControlDisabled(this.controls.y, disabled);
    };
    return FrameControl;
}(FormGroup));
export { FrameControl };

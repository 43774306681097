<div
  [class.ql-container_padding_m]="paddingSize == 'm'"
  [class.ql-text-paragraphs]="textParagraphs"
  [class.ql-schema-light]="schema == 'light'"
  [class.ql-schema-dark]="schema == 'dark'"
  [class.ql-schema-grey]="schema == 'grey'"
  [style.min-width.px]="empty ? 120 : null"
  [style.min-height.em]="empty ? 1 : null"
  #editorjs
  (mousedown)="onMouseDown($event)"
></div>

<ng-template #toolbar_left>
  <div
    [class.ql-schema-light]="schema == 'light'"
    [class.ql-schema-dark]="schema == 'dark'"
    [class.ql-schema-grey]="schema == 'grey'"
  >
    <ng-content select="[data-toolbar-left]"></ng-content>

    <span class="ql-formats" [class.ql-hidden]="!toolbarHeadersEnabled">
      <select class="ql-header"></select>
    </span>

    <span class="ql-formats">
      <button class="ql-bold" [class.ql-hidden]="!toolbarTextStyleEnabled"></button>
      <button class="ql-italic" [class.ql-hidden]="!toolbarTextStyleEnabled"></button>
      <button class="ql-underline" [class.ql-hidden]="!toolbarTextStyleEnabled"></button>
      <button class="ql-strike" [class.ql-hidden]="!toolbarTextStyleEnabled"></button>
      <button class="ql-link" [class.ql-hidden]="!toolbarTextStyleEnabled"></button>

      <button class="ql-context-formula ql-custom-button">
        <span class="ql-custom-button-icon icon-variable" [class.ql-custom-button-icon-left]="toolbarInputLabel"></span>
        <span [hidden]="!toolbarInputLabel">Insert Input</span>
      </button>
    </span>

    <span class="ql-formats" [class.ql-hidden]="!toolbarListsEnabled && !toolbarAlignEnabled">
      <button class="ql-list" value="ordered" [class.ql-hidden]="!toolbarListsEnabled"></button>
      <button class="ql-list" value="bullet" [class.ql-hidden]="!toolbarListsEnabled"></button>
      <select class="ql-align" [class.ql-hidden]="!toolbarAlignEnabled"></select>
    </span>

    <span class="ql-formats" [class.ql-hidden]="!toolbarCleanEnabled">
      <button class="ql-clean"></button>
    </span>
  </div>
</ng-template>

import { Injectable } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup } from '@angular/forms';

import { MultipleSelectOutputFormat } from '@modules/fields';

@Injectable()
export class MultipleSelectFieldDataParamsForm {
  form: FormGroup;
  control: AbstractControl;

  outputFormatOptions = [
    { value: MultipleSelectOutputFormat.Array, name: 'JSON Array' },
    { value: MultipleSelectOutputFormat.String, name: 'String' }
  ];

  constructor(private fb: FormBuilder) {
    this.form = this.fb.group({
      output_format: new FormControl(MultipleSelectOutputFormat.Array)
    });
  }

  init(control: AbstractControl) {
    this.control = control;

    if (control.value) {
      this.form.patchValue(control.value, { emitEvent: false });
    }

    this.form.valueChanges.subscribe(() => this.submit());
  }

  submit() {
    const value = this.form.value;
    const result = {
      output_format: value['output_format']
    };

    this.control.patchValue({
      ...this.control.value,
      ...result
    });
  }
}

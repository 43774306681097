var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ChangeDetectorRef, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest, of } from 'rxjs';
import { distinctUntilChanged, map, switchMap, tap } from 'rxjs/operators';
import { AccordionElementItem, CustomizeService, ElementType, registerElementComponent } from '@modules/customize';
import { BaseElementComponent } from '@modules/customize-elements';
import { applyBooleanInput$, applyParamInput$ } from '@modules/fields';
var AccordionComponent = /** @class */ (function (_super) {
    __extends(AccordionComponent, _super);
    function AccordionComponent(customizeService, cd) {
        var _this = _super.call(this) || this;
        _this.customizeService = customizeService;
        _this.cd = cd;
        _this.element$ = new BehaviorSubject(_this.element);
        _this.items = [];
        _this.openedItems = [];
        _this.trackItem = (function () {
            return function (i, item) {
                var pageUid = _this.context && _this.context.viewSettings ? _this.context.viewSettings.uid : undefined;
                return [pageUid, item.item.uid].join('_');
            };
        })();
        return _this;
    }
    AccordionComponent.prototype.ngOnInit = function () {
        this.element$.next(this.element);
        this.initItems();
        this.initItemsOpened();
        this.initItemsMultipleOpened();
    };
    AccordionComponent.prototype.ngOnDestroy = function () { };
    AccordionComponent.prototype.ngOnChanges = function (changes) {
        if (changes.element && !changes.element.firstChange) {
            this.element$.next(this.element);
        }
    };
    AccordionComponent.prototype.initItems = function () {
        var _this = this;
        this.element$
            .pipe(switchMap(function (element) {
            var items$ = element.items.map(function (accordionItem) {
                if (!accordionItem.visibleInput) {
                    return of({
                        item: accordionItem,
                        visible: true
                    });
                }
                return applyBooleanInput$(accordionItem.visibleInput, {
                    context: _this.context
                }).pipe(distinctUntilChanged(), map(function (visible) { return ({ item: accordionItem, visible: visible }); }));
            });
            if (!items$.length) {
                return of([]);
            }
            return combineLatest(items$).pipe(switchMap(function (items) {
                return _this.customizeService.enabled$.pipe(map(function (customizing) {
                    if (!customizing) {
                        return items.filter(function (item) { return item.visible; });
                    }
                    else {
                        return items;
                    }
                }));
            }));
        }), untilDestroyed(this))
            .subscribe(function (result) {
            _this.items = result;
            _this.cd.markForCheck();
        });
    };
    AccordionComponent.prototype.initItemsOpened = function () {
        var _this = this;
        this.element$
            .pipe(switchMap(function (element) {
            var items$ = element.items.map(function (accordionItem) {
                if (!accordionItem.openedInput || !accordionItem.openedInput.isSet()) {
                    return of(false);
                }
                return applyParamInput$(accordionItem.openedInput, {
                    context: _this.context
                }).pipe(distinctUntilChanged(), tap(function (opened) {
                    if (opened) {
                        _this.openItem(accordionItem.uid);
                    }
                    else {
                        _this.closeItem(accordionItem.uid);
                    }
                }));
            });
            if (!items$.length) {
                return of([]);
            }
            return combineLatest(items$);
        }), untilDestroyed(this))
            .subscribe();
    };
    AccordionComponent.prototype.initItemsMultipleOpened = function () {
        var _this = this;
        this.element$
            .pipe(map(function (element) { return element.multipleOpened; }), distinctUntilChanged(), untilDestroyed(this))
            .subscribe(function (multipleOpened) {
            if (!multipleOpened && _this.openedItems.length > 1) {
                _this.setOpenedItems(_this.openedItems.slice(0, 1));
            }
        });
    };
    AccordionComponent.prototype.setOpenedItems = function (items) {
        this.openedItems = items;
        this.cd.markForCheck();
    };
    AccordionComponent.prototype.openItem = function (uid) {
        if (this.openedItems.includes(uid)) {
            return;
        }
        if (this.element.multipleOpened) {
            this.setOpenedItems(this.openedItems.concat([uid]));
        }
        else {
            this.setOpenedItems([uid]);
        }
    };
    AccordionComponent.prototype.closeItem = function (uid) {
        if (!this.openedItems.includes(uid)) {
            return;
        }
        this.setOpenedItems(this.openedItems.filter(function (item) { return item != uid; }));
    };
    AccordionComponent.prototype.toggleOpenedItem = function (uid) {
        if (this.openedItems.includes(uid)) {
            this.closeItem(uid);
        }
        else {
            this.openItem(uid);
        }
    };
    return AccordionComponent;
}(BaseElementComponent));
export { AccordionComponent };
registerElementComponent({
    type: ElementType.Accordion,
    component: AccordionComponent,
    label: 'Accordion',
    alwaysActive: false,
    actions: []
});

<div class="dialog-background" [ngClass]="'dialog-background_style_' + currentOptions.style" #background></div>
<div class="dialog-container" (click)="$event.target == container ? miss() : null" #container>
  <div class="dialog" [ngClass]="'dialog_style_' + currentOptions.style" #root>
    <form [formGroup]="form.form" (ngSubmit)="clickSubmitButton()">
      <div class="dialog__title" [innerHTML]="currentOptions.title"></div>
      <div class="dialog__subtitle" [innerHTML]="currentOptions.subtitle"></div>
      <div
        *ngIf="currentOptions.description"
        class="dialog__description"
        [innerHTML]="currentOptions.description"
      ></div>
      <div *ngIf="currentOptions.inputs" class="dialog__content">
        <div *ngFor="let item of currentOptions.inputs; let first = first" class="dialog__input">
          <div class="dialog-input">
            <div *ngIf="item.label | appIsSet" class="dialog-input__label">
              {{ item.label }}
              <span *ngIf="item.labelAdditional | appIsSet" class="dialog-input__label-additional">
                {{ item.labelAdditional }}
              </span>
            </div>
            <div *ngIf="item.name | appIsSet" class="dialog-input__input">
              <input
                [type]="item.type"
                [placeholder]="item.placeholder"
                class="input input_fill"
                [formControlName]="item.name"
                [appAutofocus]="first"
              />
            </div>
            <div *ngIf="form.form | appFormFieldErrors: item.name as errors" class="dialog-input__errors">
              <div *ngFor="let error of errors" class="dialog-input__error">
                {{ error }}
              </div>
            </div>
            <div *ngIf="item.description" class="dialog-input__description">{{ item.description }}</div>
          </div>
        </div>
      </div>
      <div class="dialog__submit">
        <ng-container *ngFor="let item of currentOptions.buttons">
          <button
            *ngIf="item.type == dialogButtonTypes.Submit"
            type="submit"
            class="button dialog__button button_primary"
            [class.button_orange]="currentOptions.style == 'orange'"
            [ngClass]="[item.position ? 'dialog__button_position_' + item.position : '']"
            [disabled]="form.form.invalid || item.name == buttonLoading"
            [appButtonTintColor]="item.tint"
            [appButtonTintColorStyle]="tintStyles.Primary"
            [appTip]="item.tip"
            (click)="submit(item.name)"
          >
            <app-loader-small
              *ngIf="item.executor && item.name == buttonLoading"
              class="button__icon button__icon_left"
            ></app-loader-small>
            <span
              *ngIf="item.icon && !(item.executor && item.name == buttonLoading)"
              class="button__icon button__icon_left"
              [appIcon]="item.icon"
            ></span>
            <span class="button__label">{{ item.label }}</span>
          </button>

          <button
            *ngIf="item.type != dialogButtonTypes.Submit"
            type="submit"
            class="button dialog__button"
            [class.button_orange]="currentOptions.style == 'orange'"
            [class.button_primary]="item.type == dialogButtonTypes.Primary"
            [class.button_danger]="item.type == dialogButtonTypes.Danger"
            [class.button_danger-secondary]="item.type == dialogButtonTypes.DangerSecondary"
            [ngClass]="[item.position ? 'dialog__button_position_' + item.position : '']"
            [disabled]="item.name == buttonLoading"
            [appButtonTintColor]="item.tint"
            [appButtonTintColorStyle]="item.type == dialogButtonTypes.Primary ? tintStyles.Primary : tintStyles.Default"
            [appTip]="item.tip"
            (click)="submit(item.name)"
          >
            <app-loader-small
              *ngIf="item.executor && item.name == buttonLoading"
              class="button__icon button__icon_left"
            ></app-loader-small>
            <span
              *ngIf="item.icon && !(item.executor && item.name == buttonLoading)"
              class="button__icon button__icon_left"
              [appIcon]="item.icon"
            ></span>
            <span class="button__label">{{ item.label }}</span>
          </button>
        </ng-container>
      </div>
    </form>
  </div>
</div>

import { ResourceName } from '@modules/projects';

export interface ResourceSettingsComponent {
  name: ResourceName;
  component: any;
}

const resourceSettingsComponents: ResourceSettingsComponent[] = [];

export function getResourceSettingsComponent(name: ResourceName): ResourceSettingsComponent {
  return resourceSettingsComponents.find(item => item.name == name);
}

export function registerResourceSettingsComponent(name: ResourceName, component: any) {
  resourceSettingsComponents.push({
    name: name,
    component: component
  });
}

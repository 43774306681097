<div class="sidebar__element">
  <app-sidebar-field [label]="'output format'">
    <app-auto-field
      [form]="form.form"
      [label]="false"
      [field]="
        createField({
          name: 'output_format',
          field: 'SelectField',
          params: { options: form.outputFormatOptions, classes: ['select_fill'] }
        })
      "
    ></app-auto-field>
  </app-sidebar-field>
</div>

<div *ngIf="form.form.value['output_format'] == outputFormats.Storage" class="sidebar__element">
  <app-sidebar-card>
    <div class="sidebar__element">
      <app-sidebar-field>
        <gxs-select
          [placeholder]="'choose' | localize | appCapitalize"
          [formControl]="form.form.controls['storage']"
          [source]="storageSelectSource"
          [options]="{
            theme: 'jet',
            search: true,
            searchMinimumLength: 1,
            searchDebounce: 0,
            classes: ['select_fill'],
            labels: {} | localizeSelect
          }"
        >
        </gxs-select>
      </app-sidebar-field>
    </div>

    <div class="sidebar__element">
      <app-sidebar-field
        [label]="'Path inside storage'"
        [additional]="'(optional)'"
        [documentation]="'components/fields/media'"
        [documentationLabel]="'See how it works'"
        [documentationSource]="analyticsSource + '_path'"
      >
        <app-input-edit
          [itemForm]="form.form.controls['path']"
          [context]="context"
          [contextElement]="contextElement"
          [contextElementPath]="contextElementPath"
          [contextElementPaths]="contextElementPaths"
          [placeholder]="'Path'"
          [fill]="true"
          [analyticsSource]="analyticsSource + '_path'"
        ></app-input-edit>

        <ng-container description>
          Example: product/video/
        </ng-container>
      </app-sidebar-field>
    </div>
  </app-sidebar-card>
</div>

<div class="sidebar__element">
  <app-sidebar-field [label]="'fit'">
    <app-auto-field
      [form]="form.form"
      [label]="false"
      [field]="
        createField({
          name: 'fit',
          field: 'SelectField',
          params: { options: form.fitOptions, classes: ['select_fill'] }
        })
      "
    ></app-auto-field>
  </app-sidebar-field>
</div>

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import { Power2, TweenMax } from 'gsap';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { fromEvent } from 'rxjs';

import { PopupRef } from '@common/popups';

import { cropFormats } from '@modules/field-components/components/image-field/image-field.crop';
import { isSet } from '@shared';

enum CornerPosition {
  TopLeft = 0,
  TopRight = 1,
  BottomLeft = 2,
  BottomRight = 3
}

@Component({
  selector: 'app-image-editor',
  templateUrl: './image-editor.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImageEditorComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() file: File;
  @Input() cropFormat: cropFormats;
  @Input() cropFormatCustom: string;
  @Output() changed = new EventEmitter<File>();
  @ViewChild('viewport') viewport: ElementRef;
  @ViewChild('canvas') canvas: ElementRef;
  @ViewChild('preview') preview: ElementRef;
  @ViewChild('preview_inner') previewInner: ElementRef;
  @ViewChild('preview_canvas') previewCanvas: ElementRef;
  @ViewChild('image') image: ElementRef;
  @ViewChild('bounds') bounds: ElementRef;
  @ViewChildren('corner') corners = new QueryList<ElementRef>();

  img: HTMLImageElement;
  loading = true;
  width: number;
  height: number;
  position = {
    x: 0,
    y: 0,
    scale: 0.25,
    rotate: 0
  };
  boundsPosition = {
    x: 0,
    y: 0,
    width: 300,
    height: 400,
    mainSide: 'width'
  };
  commonScales = [0.01, 0.05, 0.1, 0.25, 0.5, 1.0, 2.0];
  dragging = false;
  dragPosition;
  boundsDragging = false;
  boundsDragPosition;
  cornerDragging: CornerPosition;
  cornerDragPosition;

  constructor(@Optional() private popupRef: PopupRef, private cd: ChangeDetectorRef) {}

  ngOnInit() {}

  ngOnDestroy(): void {}

  ngAfterViewInit(): void {
    this.img = new Image();

    this.img.onload = () => {
      this.width = this.img.width;
      this.height = this.img.height;
      this.loading = false;
      this.cd.detectChanges();

      this.scaleFit(false);
      this.initPositionBounds();
      const ctx = this.canvas.nativeElement.getContext('2d');
      ctx.drawImage(this.img, 0, 0, this.width, this.height);
      this.initViewportDragging();
      this.initBoundsDragging();
      this.initBoundsCornersDragging();
    };

    this.img.src = URL.createObjectURL(this.file);
  }

  initPositionBounds() {
    let width = this.imageWidth;
    let height = this.imageHeight;

    if (this.onCropFormatter()) {
      const proportion = this.proportion;
      const newWidth = height * proportion;

      if (newWidth <= width) {
        width = newWidth;
      } else {
        height = width / proportion;
      }
    }

    this.setBoundsPosition({
      x: this.position.x,
      y: this.position.y,
      width: width,
      height: height
    });
  }

  getImageTransform(): { offsetX: number; offsetY: number; width: number; height: number } {
    const offsetXMultiplier =
      (this.boundsPosition.x - this.boundsPosition.width / 2 - (this.position.x - this.imageWidth / 2)) /
      this.imageWidth;
    const offsetYMultiplier =
      (this.boundsPosition.y - this.boundsPosition.height / 2 - (this.position.y - this.imageHeight / 2)) /
      this.imageHeight;
    const widthMultiplier = this.boundsPosition.width / this.imageWidth;
    const heightMultiplier = this.boundsPosition.height / this.imageHeight;

    let offsetX: number, offsetY: number, width: number, height: number;

    if ((Math.abs(this.position.rotate) / 90) % 4 == 0) {
      offsetX = this.width * offsetXMultiplier;
      offsetY = this.height * offsetYMultiplier;
      width = this.width * widthMultiplier;
      height = this.height * heightMultiplier;
    } else if ((Math.abs(this.position.rotate) / 90) % 4 == 1) {
      offsetX = this.width * offsetYMultiplier;
      offsetY = this.height * (1 - (offsetXMultiplier + widthMultiplier));
      width = this.width * heightMultiplier;
      height = this.height * widthMultiplier;
    } else if ((Math.abs(this.position.rotate) / 90) % 4 == 2) {
      offsetX = this.width * (1 - (offsetXMultiplier + widthMultiplier));
      offsetY = this.height * (1 - (offsetYMultiplier + heightMultiplier));
      width = this.width * widthMultiplier;
      height = this.height * heightMultiplier;
    } else if ((Math.abs(this.position.rotate) / 90) % 4 == 3) {
      offsetX = this.width * (1 - (offsetYMultiplier + heightMultiplier));
      offsetY = this.height * offsetXMultiplier;
      width = this.width * heightMultiplier;
      height = this.height * widthMultiplier;
    }

    return { offsetX: offsetX, offsetY: offsetY, width: width, height: height };
  }

  updatePreview() {
    const { offsetX, offsetY, width, height } = this.getImageTransform();
    let canvasWidth, canvasHeight;

    if (this.landscape) {
      canvasWidth = height;
      canvasHeight = width;
    } else {
      canvasWidth = width;
      canvasHeight = height;
    }

    const widthHeightRation = canvasWidth / canvasHeight;
    let previewWidth: number, previewHeight: number;

    if (widthHeightRation >= 1) {
      previewWidth = 200;
      previewHeight = 200 * (1 / widthHeightRation);
    } else {
      previewWidth = 200 * widthHeightRation;
      previewHeight = 200;
    }

    TweenMax.set(this.previewCanvas.nativeElement, {
      attr: {
        width: canvasWidth,
        height: canvasHeight
      },
      scale: previewWidth / canvasWidth
    });

    TweenMax.set(this.previewInner.nativeElement, {
      width: previewWidth,
      height: previewHeight
    });

    const canvas = this.previewCanvas.nativeElement;
    const ctx = canvas.getContext('2d');

    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.translate(canvas.width / 2, canvas.height / 2);
    ctx.rotate(((Math.PI / 2) * this.position.rotate) / 90);
    ctx.drawImage(
      this.img,
      offsetX,
      offsetY,
      width,
      height,
      this.landscape ? 0 - canvas.height / 2 : 0 - canvas.width / 2,
      this.landscape ? 0 - canvas.width / 2 : 0 - canvas.height / 2,
      width,
      height
    );
  }

  initViewportDragging() {
    fromEvent<MouseEvent>([this.viewport.nativeElement, this.preview.nativeElement], 'mousedown')
      .pipe(untilDestroyed(this))
      .subscribe(e => {
        e.preventDefault();
        e.stopPropagation();

        this.dragging = true;
        this.dragPosition = {
          x: e.clientX,
          y: e.clientY
        };
      });

    fromEvent<MouseEvent>(window.document, 'mousemove')
      .pipe(untilDestroyed(this))
      .subscribe(e => {
        if (!this.dragging) {
          return;
        }

        const deltaX = e.clientX - this.dragPosition.x;
        const deltaY = e.clientY - this.dragPosition.y;

        this.setPosition({
          x: this.position.x + deltaX,
          y: this.position.y + deltaY
        });
        this.setBoundsPosition({
          x: this.boundsPosition.x + deltaX,
          y: this.boundsPosition.y + deltaY
        });

        this.dragPosition = {
          x: e.clientX,
          y: e.clientY
        };
      });

    fromEvent<MouseEvent>(window.document, 'mouseup')
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        if (!this.dragging) {
          return;
        }

        this.dragging = false;
      });
  }

  initBoundsDragging() {
    fromEvent<MouseEvent>(this.bounds.nativeElement, 'mousedown')
      .pipe(untilDestroyed(this))
      .subscribe(e => {
        e.preventDefault();
        e.stopPropagation();

        this.boundsDragging = true;
        this.boundsDragPosition = {
          x: e.clientX,
          y: e.clientY
        };
      });

    fromEvent<MouseEvent>(window.document, 'mousemove')
      .pipe(untilDestroyed(this))
      .subscribe(e => {
        if (!this.boundsDragging) {
          return;
        }

        const deltaX = e.clientX - this.boundsDragPosition.x;
        const deltaY = e.clientY - this.boundsDragPosition.y;

        this.setBoundsPosition({
          x: this.boundsPosition.x + deltaX,
          y: this.boundsPosition.y + deltaY
        });

        this.boundsDragPosition = {
          x: e.clientX,
          y: e.clientY
        };
      });

    fromEvent<MouseEvent>(window.document, 'mouseup')
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        if (!this.boundsDragging) {
          return;
        }

        this.boundsDragging = false;
      });
  }

  initBoundsCornersDragging() {
    this.corners.forEach((item, i) =>
      fromEvent<MouseEvent>(item.nativeElement, 'mousedown')
        .pipe(untilDestroyed(this))
        .subscribe(e => {
          e.preventDefault();
          e.stopPropagation();

          this.cornerDragging = i;
          this.cornerDragPosition = {
            x: e.clientX,
            y: e.clientY
          };
        })
    );

    fromEvent<MouseEvent>(window.document, 'mousemove')
      .pipe(untilDestroyed(this))
      .subscribe(e => {
        if (this.cornerDragging == undefined) {
          return;
        }

        const deltaX = e.clientX - this.cornerDragPosition.x;
        const deltaY = e.clientY - this.cornerDragPosition.y;

        if (!this.onCropFormatter()) {
          this.updatePositionBoundsDefault(deltaX, deltaY);
        } else {
          this.updatePositionBoundsCrop(deltaX, deltaY);
        }

        this.cornerDragPosition = {
          x: e.clientX,
          y: e.clientY
        };
      });

    fromEvent<MouseEvent>(window.document, 'mouseup')
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        if (this.cornerDragging == undefined) {
          return;
        }

        this.cornerDragging = undefined;
      });
  }

  onCropFormatter(): boolean {
    if (this.cropFormat === cropFormats.Custom) {
      return isSet(this.cropFormatCustom);
    } else {
      return isSet(this.cropFormat);
    }
  }

  scale(position, animate = true) {
    const deltaX = (this.boundsPosition.x - this.position.x) / this.imageWidth;
    const deltaY = (this.boundsPosition.y - this.position.y) / this.imageHeight;
    const deltaWidth = this.boundsPosition.width / this.imageWidth;
    const deltaHeight = this.boundsPosition.height / this.imageHeight;

    this.setPosition(position, animate);

    const x = this.position.x + this.imageWidth * deltaX;
    const y = this.position.y + this.imageHeight * deltaY;
    const width = this.imageWidth * deltaWidth;
    const height = this.imageHeight * deltaHeight;

    this.setBoundsPosition(
      {
        x: x,
        y: y,
        width: width,
        height: height
      },
      animate
    );
  }

  scaleUp() {
    let newScale = this.position.scale * 2;
    const commonScale = this.commonScales.find(item => this.position.scale < item && newScale > item);

    if (commonScale != undefined) {
      newScale = commonScale;
    }

    this.scale({ scale: newScale });
  }

  scaleDown() {
    let newScale = this.position.scale / 2;
    const commonScale = this.commonScales.find(item => this.position.scale > item && newScale < item);

    if (commonScale != undefined) {
      newScale = commonScale;
    }

    this.scale({ scale: newScale });
  }

  scaleFit(animate = true) {
    const padding = 80;
    const scaleX = (this.viewport.nativeElement.offsetWidth / 2 - padding) / (this.width / 2);
    const scaleY = (this.viewport.nativeElement.offsetHeight / 2 - padding) / (this.height / 2);

    this.scale(
      {
        x: this.viewport.nativeElement.offsetWidth / 2,
        y: this.viewport.nativeElement.offsetHeight / 2,
        scale: Math.min(scaleX, scaleY)
      },
      animate
    );
  }

  rotate(clockwise) {
    const clockwiseMultiplier = clockwise ? 1 : -1;
    const rotate = this.position.rotate + 90 * clockwiseMultiplier;

    this.setPosition({ rotate: rotate }, true);
    this.setBoundsPosition(
      {
        x: (this.boundsPosition.y - this.position.y) * -1 * clockwiseMultiplier + this.position.x,
        y: (this.boundsPosition.x - this.position.x) * clockwiseMultiplier + this.position.y,
        width: this.boundsPosition.height,
        height: this.boundsPosition.width
      },
      true
    );
  }

  setPosition(position, animate = false) {
    this.position = { ...this.position, ...position };

    TweenMax.to(this.image.nativeElement, animate ? 0.4 : 0, {
      x: this.position.x,
      y: this.position.y,
      xPercent: -50,
      yPercent: -50,
      rotation: this.position.rotate,
      scale: this.position.scale,
      ease: animate ? Power2.easeInOut : undefined
    });

    this.updatePreview();
  }

  setBoundsPosition(position, animate = false) {
    this.boundsPosition = { ...this.boundsPosition, ...position };
    this.validateBoundsPosition();

    TweenMax.to(this.bounds.nativeElement, animate ? 0.4 : 0, {
      x: this.boundsPosition.x,
      y: this.boundsPosition.y,
      width: this.boundsPosition.width,
      height: this.boundsPosition.height,
      xPercent: -50,
      yPercent: -50,
      ease: animate ? Power2.easeInOut : undefined
    });

    this.updatePreview();
  }

  get landscape() {
    return (Math.abs(this.position.rotate) / 90) % 2 == 1;
  }

  get imageWidth() {
    if (this.landscape) {
      return this.height * this.position.scale;
    } else {
      return this.width * this.position.scale;
    }
  }

  get imageHeight() {
    if (this.landscape) {
      return this.width * this.position.scale;
    } else {
      return this.height * this.position.scale;
    }
  }

  validateBoundsPosition() {
    // if (this.boundsPosition.width > this.imageWidth) {
    //   this.boundsPosition.width = this.imageWidth;
    // }

    // if (this.boundsPosition.height > this.imageHeight) {
    //   this.boundsPosition.height = this.imageHeight;
    // }

    const boundsRightX = this.boundsPosition.x + this.boundsPosition.width / 2;
    const rightX = this.position.x + this.imageWidth / 2;

    if (boundsRightX > rightX) {
      this.boundsPosition.x = rightX - this.boundsPosition.width / 2;
    }

    const boundsLeftX = this.boundsPosition.x - this.boundsPosition.width / 2;
    const leftX = this.position.x - this.imageWidth / 2;

    if (boundsLeftX < leftX) {
      this.boundsPosition.x = leftX + this.boundsPosition.width / 2;
    }

    const boundsBottomY = this.boundsPosition.y + this.boundsPosition.height / 2;
    const bottomY = this.position.y + this.imageHeight / 2;

    if (boundsBottomY > bottomY) {
      this.boundsPosition.y = bottomY - this.boundsPosition.height / 2;
    }

    const boundsTopY = this.boundsPosition.y - this.boundsPosition.height / 2;
    const topY = this.position.y - this.imageHeight / 2;

    if (boundsTopY < topY) {
      this.boundsPosition.y = topY + this.boundsPosition.height / 2;
    }
  }

  validateRightX(position) {
    const boundsRightX = position.x + position.width / 2;
    const rightX = this.position.x + this.imageWidth / 2;
    return boundsRightX <= rightX;
  }

  validateLeftX(position) {
    const boundsLeftX = position.x - position.width / 2;
    const leftX = this.position.x - this.imageWidth / 2;
    return boundsLeftX >= leftX;
  }

  validateBottomY(position) {
    const boundsBottomY = position.y + position.height / 2;
    const bottomY = this.position.y + this.imageHeight / 2;
    return boundsBottomY <= bottomY;
  }

  validateTopY(position) {
    const boundsTopY = position.y - position.height / 2;
    const topY = this.position.y - this.imageHeight / 2;
    return boundsTopY >= topY;
  }

  close() {
    if (this.popupRef) {
      this.popupRef.close();
    }
  }

  cancel() {
    this.close();
  }

  isImageTransformOriginal(): boolean {
    const transform = this.getImageTransform();
    return (
      transform.offsetX == 0 &&
      transform.offsetY == 0 &&
      transform.width == this.width &&
      transform.height == this.height
    );
  }

  save() {
    this.close();

    if (this.isImageTransformOriginal()) {
      this.changed.emit(this.file);
    } else {
      this.changed.emit(this.toFile());
    }
  }

  toFile(): File {
    const dataURL = this.previewCanvas.nativeElement.toDataURL(this.file.type);
    const blobBin = atob(dataURL.split(',')[1]);
    const array = [];

    for (let i = 0; i < blobBin.length; i++) {
      array.push(blobBin.charCodeAt(i));
    }

    return new File([new Uint8Array(array)], this.file.name, { type: this.file.type });
  }

  multiply(size): number {
    if (!size) {
      return 0;
    }
    return size * this.proportion;
  }

  divid(size): number {
    if (!size) {
      return 0;
    }
    return size / this.proportion;
  }

  get proportion(): number {
    if (this.cropFormat === cropFormats.Square) {
      return 1;
    } else if (this.cropFormat === cropFormats.Original) {
      return this.width / this.height;
    } else if (this.cropFormat === cropFormats.Format_16x9) {
      return 16 / 9;
    } else if (this.cropFormat === cropFormats.Format_3x2) {
      return 3 / 2;
    } else if (this.cropFormat === cropFormats.Format_4x3) {
      return 4 / 2;
    } else if (this.cropFormat === cropFormats.Format_5x4) {
      return 5 / 4;
    } else if (this.cropFormat === cropFormats.Format_7x5) {
      return 7 / 5;
    } else if (this.cropFormat === cropFormats.Custom) {
      const [a, b] = this.cropFormatCustom.split(':');
      return Number(a) / Number(b);
    }
  }

  updatePositionBoundsDefault(deltaX, deltaY) {
    let position;
    if (this.cornerDragging == CornerPosition.TopLeft) {
      position = {
        x: this.boundsPosition.x + deltaX / 2,
        y: this.boundsPosition.y + deltaY / 2,
        width: this.boundsPosition.width - deltaX,
        height: this.boundsPosition.height - deltaY
      };
      if (this.validateTopY(position) && this.validateLeftX(position)) {
        this.setBoundsPosition(position);
      }
    } else if (this.cornerDragging == CornerPosition.TopRight) {
      position = {
        x: this.boundsPosition.x + deltaX / 2,
        y: this.boundsPosition.y + deltaY / 2,
        width: this.boundsPosition.width + deltaX,
        height: this.boundsPosition.height - deltaY
      };
      if (this.validateTopY(position) && this.validateRightX(position)) {
        this.setBoundsPosition(position);
      }
    } else if (this.cornerDragging == CornerPosition.BottomLeft) {
      position = {
        x: this.boundsPosition.x + deltaX / 2,
        y: this.boundsPosition.y + deltaY / 2,
        width: this.boundsPosition.width - deltaX,
        height: this.boundsPosition.height + deltaY
      };
      if (this.validateBottomY(position) && this.validateLeftX(position)) {
        this.setBoundsPosition(position);
      }
    } else if (this.cornerDragging == CornerPosition.BottomRight) {
      position = {
        x: this.boundsPosition.x + deltaX / 2,
        y: this.boundsPosition.y + deltaY / 2,
        width: this.boundsPosition.width + deltaX,
        height: this.boundsPosition.height + deltaY
      };
      if (this.validateRightX(position) && this.validateBottomY(position)) {
        this.setBoundsPosition(position);
      }
    }
  }

  updatePositionBoundsCrop(deltaX, deltaY) {
    let position;
    if (this.cornerDragging == CornerPosition.TopLeft) {
      position = {
        x: this.boundsPosition.x + this.multiply(deltaY) / 2,
        y: this.boundsPosition.y + deltaY / 2,
        width: this.multiply(this.boundsPosition.height - deltaY),
        height: this.boundsPosition.height - deltaY
      };
      if (this.validateTopY(position) && this.validateLeftX(position)) {
        this.setBoundsPosition(position);
      }
    } else if (this.cornerDragging == CornerPosition.TopRight) {
      position = {
        x: this.boundsPosition.x + -this.multiply(deltaY) / 2,
        y: this.boundsPosition.y + deltaY / 2,
        width: this.multiply(this.boundsPosition.height - deltaY),
        height: this.boundsPosition.height - deltaY
      };

      if (this.validateTopY(position) && this.validateRightX(position)) {
        this.setBoundsPosition(position);
      }
    } else if (this.cornerDragging == CornerPosition.BottomLeft) {
      position = {
        x: this.boundsPosition.x + -this.multiply(deltaY) / 2,
        y: this.boundsPosition.y + deltaY / 2,
        width: this.multiply(this.boundsPosition.height + deltaY),
        height: this.boundsPosition.height + deltaY
      };
      if (this.validateBottomY(position) && this.validateLeftX(position)) {
        this.setBoundsPosition(position);
      }
    } else if (this.cornerDragging == CornerPosition.BottomRight) {
      position = {
        x: this.boundsPosition.x + this.multiply(deltaY) / 2,
        y: this.boundsPosition.y + deltaY / 2,
        width: this.multiply(this.boundsPosition.height + deltaY),
        height: this.boundsPosition.height + deltaY
      };
      if (this.validateRightX(position) && this.validateBottomY(position)) {
        this.setBoundsPosition(position);
      }
    }
  }
}

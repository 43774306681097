<ng-template let-margin="margin" #structure>
  <ng-container *ngFor="let parentLast of parentsLast; let first = first">
    <span *ngIf="!first" class="code-editor__structure-item-icon">
      <svg *ngIf="!parentLast" xmlns="http://www.w3.org/2000/svg" width="25" height="35" viewBox="0 0 25 35">
        <path fill="#424b56" fill-rule="evenodd" d="m1 35h-1v-35h1z" transform="translate(11)" />
      </svg>
    </span>
  </ng-container>

  <div
    *ngIf="parentsLast.length > 0"
    class="code-editor__structure-item-icon"
    [class.code-editor__structure-item-icon_margin]="margin"
  >
    <svg *ngIf="!itemLast" xmlns="http://www.w3.org/2000/svg" width="25" height="35" viewBox="0 0 25 35">
      <path
        fill="#424B56"
        fill-rule="evenodd"
        d="M17,35 L16,35 L16,0 L17,0 L17,17 L25,17 L25,18 L17,18 L17,35 Z"
        transform="translate(-5)"
      />
    </svg>

    <svg *ngIf="itemLast" xmlns="http://www.w3.org/2000/svg" width="25" height="35" viewBox="0 0 25 35">
      <path
        fill="#424B56"
        fill-rule="evenodd"
        d="M16,18 L16,0 L17,0 L17,17 L25,17 L25,18 L16,18 Z"
        transform="translate(-5)"
      />
    </svg>
  </div>
</ng-template>

<ng-container *ngIf="item.children.length">
  <a href="javascript:void(0)" class="code-editor__structure-item" (click)="toggleOpened()">
    <ng-container *ngTemplateOutlet="structure"></ng-container>

    <span
      class="code-editor__structure-item-icon code-editor__structure-item-icon_margin"
      [class.code-editor__structure-item-icon_active]="item.children.length && opened"
      [class.icon-open_folder]="item.children.length && opened"
      [class.icon-folder]="item.children.length && !opened"
    >
    </span>
    <span class="code-editor__structure-item-label" [title]="item.label ? item.label : ''">
      <span class="code-editor__structure-item-label-main">
        <ng-template #default_template>
          <span [innerHTML]="item.name"></span> ({{ item.children.length }})
        </ng-template>

        <ng-container *ngIf="(item.label | appIsSet) && item.label != item.name; else default_template">
          <span [innerHTML]="item.label"></span> [<span [innerHTML]="item.name"></span>] ({{ item.children.length }})
        </ng-container>
      </span>

      <span class="code-editor__structure-item-label-actions">
        <a
          href="javascript:void(0)"
          class="code-editor__structure-item-action icon-redo"
          (click)="$event.stopPropagation(); insert(item)"
          [appTip]="'Insert into query'"
          [appTipOptions]="{ side: 'top' }"
        ></a>
      </span>
    </span>
  </a>

  <ng-container *ngIf="opened">
    <app-token-structure-item
      *ngFor="let child of item.children; let childLast = last"
      [item]="child"
      [itemLast]="childLast"
      [parentsLast]="parentsLastChild"
      (inserted)="inserted.next($event)"
      (valueChanged)="onValueChanged($event.item, $event.value)"
    >
    </app-token-structure-item>
  </ng-container>
</ng-container>

<div *ngIf="!item.children.length && item.insert" class="code-editor__structure-item">
  <ng-container *ngTemplateOutlet="structure; context: { margin: true }"></ng-container>

  <a
    href="javascript:void(0)"
    class="code-editor__structure-item-label"
    [title]="item.label ? item.label : ''"
    (click)="insert(item)"
  >
    <span class="code-editor__structure-item-label-main">
      <ng-template #default_template>
        <span [innerHTML]="item.name"></span>
      </ng-template>

      <ng-container *ngIf="(item.label | appIsSet) && item.label != item.name; else default_template">
        <span [innerHTML]="item.label"></span> [<span [innerHTML]="item.name"></span>]
      </ng-container>
    </span>

    <span class="code-editor__structure-item-label-actions">
      <span
        class="code-editor__structure-item-action icon-redo"
        [appTip]="'Insert into query'"
        [appTipOptions]="{ side: 'top' }"
      ></span>
    </span>
  </a>

  <!--  <div>-->
  <!--    <span *ngFor="let d of depthArray" class="code-editor__structure-item-icon"></span>-->
  <!--    <input-->
  <!--      *ngIf="item.manualValue"-->
  <!--      type="text"-->
  <!--      class="input input_fill"-->
  <!--      placeholder="Enter value for execute"-->
  <!--      [(ngModel)]="value"-->
  <!--      [ngModelOptions]="{ standalone: true }"-->
  <!--      (ngModelChange)="onValueChanged(item, value)"-->
  <!--    />-->
  <!--  </div>-->
</div>

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { FormControl } from '@angular/forms';
import cloneDeep from 'lodash/cloneDeep';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { filter, switchMap } from 'rxjs/operators';

import { copyTextToClipboard } from '@common/code';
import { DialogService } from '@common/dialogs';
import { localize } from '@common/localize';
import { NotificationService } from '@common/notifications';
import { AppConfigService } from '@core';
import { AdminMode, ROUTE_ADMIN_MODE } from '@modules/admin-mode';
import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { ServerRequestError } from '@modules/api';
import {
  CurrentEnvironmentStore,
  CurrentProjectStore,
  ProjectGroup,
  ProjectPublicInvite,
  ProjectPublicInviteService
} from '@modules/projects';
import { CurrentUserStore } from '@modules/users';

@Component({
  selector: 'app-share-public-invite-item, [app-share-public-invite-item]',
  templateUrl: './share-public-invite-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SharePublicInviteItemComponent implements OnInit, OnDestroy, OnChanges {
  @Input() item: ProjectPublicInvite;
  @Output() deleted = new EventEmitter<void>();

  selectedGroup = new FormControl();
  deleteLoading = false;
  webBaseUrl: string;

  constructor(
    @Inject(ROUTE_ADMIN_MODE) private mode: AdminMode,
    public currentProjectStore: CurrentProjectStore,
    public currentEnvironmentStore: CurrentEnvironmentStore,
    public currentUserStore: CurrentUserStore,
    private projectPublicInviteService: ProjectPublicInviteService,
    private dialogService: DialogService,
    private appConfigService: AppConfigService,
    private notificationService: NotificationService,
    private analyticsService: UniversalAnalyticsService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.currentProjectStore.instance$.pipe(untilDestroyed(this)).subscribe(project => {
      this.webBaseUrl = project.domain ? `https://${project.domain.actualDomain}` : this.appConfigService.webBaseUrl;
      this.cd.markForCheck();
    });

    this.selectedGroup.valueChanges.pipe(untilDestroyed(this)).subscribe(group => {
      if (group) {
        this.update(group);
      }
    });
  }

  ngOnDestroy(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    this.selectedGroup.setValue(this.item ? this.item.group : undefined);
  }

  delete() {
    this.dialogService
      .warning({
        title: 'Deleting',
        description: 'Are you sure want to delete this invite link from App?'
      })
      .pipe(
        filter(result => result == true),
        switchMap(() => {
          this.deleteLoading = true;
          this.cd.markForCheck();
          return this.projectPublicInviteService.delete(
            this.currentProjectStore.instance.uniqueName,
            this.currentEnvironmentStore.instance.uniqueName,
            this.item
          );
        }),
        untilDestroyed(this)
      )
      .subscribe(
        () => {
          // this.notificationService.success('Deleted', 'Invite link was successfully deleted from App');

          this.deleteLoading = false;
          this.cd.markForCheck();
          this.deleted.next();

          this.analyticsService.sendSimpleEvent(AnalyticsEvent.Share.PublicLinkDeleted, {
            Code: this.item.code,
            Mode: this.mode,
            Source: 'share_popup'
          });
        },
        () => {
          this.deleteLoading = false;
          this.cd.markForCheck();
        }
      );
  }

  update(group: ProjectGroup) {
    if (group.uid == this.item.group.uid) {
      return;
    }

    const item = cloneDeep(this.item) as ProjectPublicInvite;

    item.group = group;

    this.projectPublicInviteService
      .update(this.currentProjectStore.instance.uniqueName, this.currentEnvironmentStore.instance.uniqueName, item)
      .pipe(untilDestroyed(this))
      .subscribe(
        () => {
          this.item = item;
          this.selectedGroup.setValue(group);
          this.cd.markForCheck();

          // this.notificationService.success('Saved', 'Invite link was successfully updated');
        },
        error => {
          if (error instanceof ServerRequestError && error.errors.length) {
            this.notificationService.error('Update Failed', error.errors[0]);
          } else {
            this.notificationService.error('Update Failed', error);
          }
        }
      );
  }

  copy() {
    const link = this.getPublicLink();

    copyTextToClipboard(link)
      .pipe(untilDestroyed(this))
      .subscribe(success => {
        if (!success) {
          return;
        }

        this.notificationService.info(localize('Copied'), localize('Link was copied to clipboard'));
      });
  }

  getPublicLink() {
    return this.item.link(this.webBaseUrl);
  }
}

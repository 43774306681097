<div class="sidebar__element">
  <app-sidebar-field [label]="'color'" [required]="false">
    <app-auto-field
      [form]="form.form"
      [label]="false"
      [field]="
        createField({
          name: 'tint',
          field: 'ColorField',
          params: { classes: ['select_fill'], allow_empty: true, empty_color: '#eab130', custom_colors: true }
        })
      "
    ></app-auto-field>
  </app-sidebar-field>
</div>

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map, publishLast, refCount } from 'rxjs/operators';

import { AppConfigService } from '@core';
import { ApiService, ServerRequestError } from '@modules/api';

import { PriceFeature } from '../../models/price-feature';
import { PricingPlan } from '../../models/pricing-plan';

@Injectable({
  providedIn: 'root'
})
export class PricingService {
  baseName = 'apptO8NbyvB2dtUOd';

  constructor(private http: HttpClient, private apiService: ApiService, private appConfigService: AppConfigService) {}

  public setAirTableHeadersToken(headers: HttpHeaders) {
    const token = this.appConfigService.airtableApiKey || '';

    if (!token) {
      return headers;
    }

    return headers.append('Authorization', `Bearer ${token}`);
  }

  public get airBaseUrl() {
    return `https://api.airtable.com/v0/`;
  }

  public airMethodURL(baseName, tableName) {
    return this.apiService.methodURL(`data/v0/${baseName}/${tableName}`);
  }

  getPricing(tableName: string): Observable<PriceFeature[]> {
    const url = this.airMethodURL(this.baseName, tableName);
    let headers = new HttpHeaders();
    headers = this.setAirTableHeadersToken(headers);

    return this.http
      .get<PriceFeature[]>(url, {
        headers: headers,
        params: {
          maxRecords: '',
          view: 'Grid view'
        },
        withCredentials: false
      })
      .pipe(
        map(result => {
          return result['records'].map(item => {
            const priceFeature = new PriceFeature().deserialize(item['fields']);
            priceFeature.id = item['id'];
            return priceFeature;
          });
        }),
        catchError(error => throwError(new ServerRequestError(error))),
        publishLast(),
        refCount()
      );
  }

  getPlans(tableName: string): Observable<PricingPlan[]> {
    const url = this.airMethodURL(this.baseName, tableName);
    let headers = new HttpHeaders();
    headers = this.setAirTableHeadersToken(headers);

    return this.http
      .get<PriceFeature[]>(url, {
        headers: headers,
        params: {
          maxRecords: '',
          view: 'Grid view'
        },
        withCredentials: false
      })
      .pipe(
        map(result => {
          return result['records'].map(item => new PricingPlan().deserialize(item['fields']));
        }),
        catchError(error => throwError(new ServerRequestError(error))),
        publishLast(),
        refCount()
      );
  }
}

import {
  BaseField,
  FieldDescription,
  FieldType,
  FilterableField,
  FlexField,
  FormField,
  getFieldDescriptionByType,
  parseFieldType,
  SortableField
} from '@modules/fields';

export class ModelFlexField implements BaseField, FilterableField, SortableField, FlexField {
  public name: string;
  public verboseName: string;
  public field: FieldType;
  public query: string;
  public code: string;
  public filterable = false;
  public sortable = false;
  public params = {};
  public fieldDescription: FieldDescription;
  public description: string;
  private _formField: FormField;

  deserialize(data: Object): ModelFlexField {
    this.name = data['name'];
    this.verboseName = data['verbose_name'];
    this.description = data['description'];
    this.field = parseFieldType(data['field']);
    this.query = data['query'];
    this.code = data['code'];

    const item = getFieldDescriptionByType(this.field);
    this.params = { ...item.defaultParams, ...data['params'], ...item.forceParams };

    this.updateFieldDescription();

    return this;
  }

  serialize(): Object {
    return {
      name: this.name,
      verbose_name: this.verboseName,
      field: this.field,
      query: this.query,
      code: this.code,
      params: this.params,
      description: this.description
    };
  }

  get formField(): FormField {
    if (!this._formField) {
      this._formField = new FormField().deserialize({
        name: this.name,
        label: this.verboseName,
        field: this.field,
        editable: false,
        required: false,
        params: this.params,
        description: this.description
      });
    }

    return this._formField;
  }

  updateFieldDescription() {
    this.fieldDescription = getFieldDescriptionByType(this.field);
  }
}

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { HttpClient } from '@angular/common/http';
import fromPairs from 'lodash/fromPairs';
import isArray from 'lodash/isArray';
import toPairs from 'lodash/toPairs';
import { map, publishLast, refCount, tap } from 'rxjs/operators';
import { ApiService } from '@modules/api';
import { FieldType } from '@modules/fields';
import { airtableFilterItemFormula, filterItemsFromQueryParams } from '@modules/filter-utils';
import { CURSOR_NEXT_PARAM, ORDER_BY_PARAM, PER_PAGE_PARAM, SEARCH_PARAM } from '@modules/models';
import { HttpMethod, HttpQuery, HttpQueryService, QueryType } from '@modules/queries';
import { isSet } from '@shared';
import { ResourceController } from '../../data/resource-controller';
import { AIRTABLE_CREATED_TIME, AIRTABLE_PRIMARY_KEY, AIRTABLE_TABLE_VIEW_ID_PARAM, SOURCE_FIELD_TYPE } from './airtable-constants';
import { AirtableFieldType, airtableReadOnlyFieldTypes } from './airtable-field-type';
var AirtableResourceController = /** @class */ (function (_super) {
    __extends(AirtableResourceController, _super);
    function AirtableResourceController() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.tokenName = 'oauth_access_token';
        _this.tokenNamePersonalAccessToken = 'personal_access_token';
        _this.tokenNameLegacy = 'api_key';
        return _this;
    }
    AirtableResourceController.prototype.init = function () {
        this.apiService = this.initService(ApiService);
        this.httpQueryService = this.initService(HttpQueryService);
        this.http = this.initService(HttpClient);
    };
    AirtableResourceController.prototype.supportedQueryTypes = function (queryClass) {
        return [QueryType.Simple];
    };
    AirtableResourceController.prototype.getBases = function (options) {
        var url = this.apiService.methodURL('airtable/bases/');
        var data = __assign({}, (isSet(options.accessToken) && { access_token: options.accessToken }), (isSet(options.key) && { api_key: options.key }));
        return this.http.post(url, data).pipe(this.apiService.catchApiError(), publishLast(), refCount());
    };
    AirtableResourceController.prototype.getBaseTables = function (options) {
        var url = this.apiService.methodURL("airtable/bases/" + options.base + "/tables/");
        var data = __assign({}, (isSet(options.accessToken) && { access_token: options.accessToken }), (isSet(options.key) && { api_key: options.key }));
        return this.http.post(url, data).pipe(this.apiService.catchApiError(), publishLast(), refCount());
    };
    AirtableResourceController.prototype.getBaseTableRecords = function (options) {
        var query = new HttpQuery();
        var key = isSet(options.accessToken) ? options.accessToken : options.key;
        query.url = "https://api.airtable.com/v0/" + options.base + "/" + options.table;
        query.headers = [{ name: 'Authorization', value: "Bearer " + key }];
        query.queryParams = [];
        if (options.viewId) {
            query.queryParams.push({
                name: 'view',
                value: options.viewId
            });
        }
        if (options.formula) {
            query.queryParams.push({
                name: 'filterByFormula',
                value: options.formula
            });
        }
        return this.httpQueryService
            .requestBody(query)
            .pipe(this.apiService.catchApiError(), publishLast(), refCount());
    };
    AirtableResourceController.prototype.deserializeModel = function (record, modelDescription) {
        var _a;
        var fields = toPairs(record.fields).map(function (_a) {
            var name = _a[0], value = _a[1];
            var field = modelDescription.dbFields.find(function (i) { return i.name == name; });
            var sourceType = field ? field.params[SOURCE_FIELD_TYPE] : undefined;
            if (sourceType && sourceType == AirtableFieldType.MultipleAttachments) {
                value = isArray(value) && value.length ? value.map(function (item) { return item.url; }) : [];
            }
            else if (sourceType && sourceType == AirtableFieldType.MultipleLookupValues) {
                value = isArray(value) && value.length ? value[0] : undefined;
            }
            return [name, value];
        });
        var missingFields = modelDescription.dbFields
            .filter(function (item) { return !fields.find(function (_a) {
            var k = _a[0];
            return k == item.name;
        }); })
            .map(function (item) { return [item.name, null]; });
        return __assign({}, fromPairs(fields), fromPairs(missingFields), (_a = {}, _a[AIRTABLE_PRIMARY_KEY] = record.id, _a[AIRTABLE_CREATED_TIME] = record.createdTime, _a));
    };
    AirtableResourceController.prototype.serializeModel = function (instance, modelDescription, fields) {
        var data = toPairs(instance.serialize(fields))
            .filter(function (_a) {
            var k = _a[0], v = _a[1];
            return ![AIRTABLE_PRIMARY_KEY, AIRTABLE_CREATED_TIME].includes(k);
        })
            .filter(function (_a) {
            var k = _a[0], v = _a[1];
            var field = modelDescription.dbFields.find(function (i) { return i.name == k; });
            return field && field.editable;
        })
            .map(function (_a) {
            var name = _a[0], value = _a[1];
            var field = modelDescription.dbFields.find(function (i) { return i.name == name; });
            var sourceType = field ? field.params[SOURCE_FIELD_TYPE] : undefined;
            if (sourceType && sourceType == AirtableFieldType.MultipleAttachments) {
                if (isSet(value)) {
                    value = isArray(value)
                        ? value.filter(function (item) { return item !== null; }).map(function (item) { return ({ url: item }); })
                        : [{ url: value }];
                }
                else {
                    value = [];
                }
            }
            else if (sourceType && sourceType == AirtableFieldType.MultipleSelects) {
                value = isArray(value) ? value : [value];
                value = value.filter(function (item) { return item !== null; });
            }
            else if (sourceType && sourceType == AirtableFieldType.MultipleRecordLinks) {
                value = isArray(value) ? value : [value];
                value = value.filter(function (item) { return item !== null; });
            }
            return [name, value];
        });
        return __assign({}, (isSet(instance.initialPrimaryKey) && { id: instance.initialPrimaryKey }), { fields: fromPairs(data) });
    };
    AirtableResourceController.prototype.getTokenName = function (resource) {
        var _this = this;
        if (resource.secretTokens.find(function (item) { return item.name == _this.tokenName; })) {
            return this.tokenName;
        }
        else if (resource.secretTokens.find(function (item) { return item.name == _this.tokenNamePersonalAccessToken; })) {
            return this.tokenNamePersonalAccessToken;
        }
        else {
            return this.tokenNameLegacy;
        }
    };
    AirtableResourceController.prototype.modelGet = function (resource, modelDescription, params, body) {
        var _this = this;
        var options = { resource: resource.uniqueName };
        var query = new HttpQuery();
        var viewId = modelDescription.params[AIRTABLE_TABLE_VIEW_ID_PARAM];
        var search = params[SEARCH_PARAM];
        var cursorNext = params[CURSOR_NEXT_PARAM];
        var perPage = isSet(params[PER_PAGE_PARAM]) ? Math.min(params[PER_PAGE_PARAM], 100) : undefined;
        var orderField = params[ORDER_BY_PARAM];
        var orderAscending = true;
        if (isSet(orderField) && orderField.startsWith('-')) {
            orderField = orderField.substring(1);
            orderAscending = false;
        }
        var _a = modelDescription.model.split('_'), base = _a[0], table = _a[1];
        query.url = "https://api.airtable.com/v0/" + base + "/" + table;
        query.headers = [{ name: 'Authorization', value: "Bearer {-" + this.getTokenName(resource) + "-}" }];
        query.queryParams = [];
        if (perPage) {
            query.queryParams.push({ name: 'pageSize', value: String(perPage) });
        }
        if (viewId) {
            query.queryParams.push({ name: 'view', value: viewId });
        }
        var predicates = [];
        if (isSet(search)) {
            var searchPredicates = modelDescription.dbFields
                .filter(function (item) {
                return [FieldType.Text, FieldType.Select, FieldType.MultipleSelect, FieldType.RadioButton, FieldType.URL].includes(item.field);
            })
                .filter(function (item) { return ![AIRTABLE_CREATED_TIME].includes(item.name); })
                .filter(function (item) { return !airtableReadOnlyFieldTypes.includes(item.params[SOURCE_FIELD_TYPE]); })
                .map(function (item) {
                var fieldValue = item.name == AIRTABLE_PRIMARY_KEY ? 'RECORD_ID()' : "{" + item.name + "}";
                return "SEARCH(\"" + search.toLowerCase() + "\", LOWER(" + fieldValue + "))";
            });
            var totalLength_1 = 0;
            searchPredicates = searchPredicates.reduce(function (acc, item) {
                if (totalLength_1 + item.length <= 1024) {
                    acc.push(item);
                    totalLength_1 += item.length;
                }
                return acc;
            }, []);
            predicates.push("OR(" + searchPredicates.join(',') + ")");
        }
        if (isSet(cursorNext)) {
            query.queryParams.push({ name: 'offset', value: cursorNext });
        }
        if (isSet(orderField)) {
            query.queryParams.push({ name: 'sort[0][field]', value: orderField });
            if (!orderAscending) {
                query.queryParams.push({ name: 'sort[0][direction]', value: 'desc' });
            }
        }
        var filterItems = filterItemsFromQueryParams(params);
        filterItems.forEach(function (filterItem) {
            var field = modelDescription.dbFields.find(function (i) { return i.name == filterItem.field; });
            if (!field) {
                return;
            }
            var formula = airtableFilterItemFormula(field, filterItem);
            if (!formula) {
                return;
            }
            predicates.push(formula);
        });
        if (predicates.length > 1) {
            query.queryParams.push({ name: 'filterByFormula', value: "AND(" + predicates.join(',') + ")" });
        }
        else if (predicates.length == 1) {
            query.queryParams.push({ name: 'filterByFormula', value: predicates[0] });
        }
        return this.httpQueryService.requestBody(query, options).pipe(map(function (result) {
            var data = {
                results: result.records.map(function (item) { return _this.deserializeModel(item, modelDescription); }),
                has_more: !!result.offset,
                cursor_next: result.offset,
                per_page: params[PER_PAGE_PARAM]
            };
            return _this.createGetResponse().deserialize(data, modelDescription.model, undefined);
        }), tap(function (response) {
            response.results.forEach(function (item) {
                item.deserializeAttributes(modelDescription.dbFields);
                item.setUp(modelDescription);
            });
            return response;
        }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    AirtableResourceController.prototype.modelGetDetail = function (resource, modelDescription, idField, id, params) {
        var _a;
        params = params || {};
        if (isSet(id)) {
            params = __assign({}, params, (_a = {}, _a[idField] = id, _a));
        }
        return this.modelGet(resource, modelDescription, params).pipe(map(function (result) { return result.results[0]; }));
        //   const options: HttpQueryOptions = { resource: resource.uniqueName };
        //   const query = new HttpQuery();
        //   const [base, table] = modelDescription.model.split('_');
        //
        //   query.method = HttpMethod.GET;
        //   query.url = `https://api.airtable.com/v0/${base}/${table}/${id}`;
        //   query.headers = [{ name: 'Authorization', value: `Bearer {-${this.getTokenName(resource)}-}` }];
        //
        //   return this.httpQueryService.requestBody<AirtableRecordResponse>(query, options).pipe(
        //     map(result => {
        //       const instance = this.createModel().deserialize(modelDescription.model, {
        //         ...result.fields,
        //         [AIRTABLE_PRIMARY_KEY]: result.id,
        //         [AIRTABLE_CREATED_TIME]: result.createdTime
        //       });
        //       const instance = this.createModel().deserialize(modelDescription.model, this.deserializeModel(result, modelDescription));
        //       instance.setUp(modelDescription);
        //       instance.deserializeAttributes(modelDescription.dbFields);
        //       return instance;
        //     }),
        //     this.apiService.catchApiError(),
        //     publishLast(),
        //     refCount()
        //   );
    };
    AirtableResourceController.prototype.modelCreate = function (resource, modelDescription, modelInstance, fields) {
        var _this = this;
        var options = { resource: resource.uniqueName };
        var query = new HttpQuery();
        var _a = modelDescription.model.split('_'), base = _a[0], table = _a[1];
        query.method = HttpMethod.POST;
        query.url = "https://api.airtable.com/v0/" + base + "/" + table;
        query.headers = [
            { name: 'Authorization', value: "Bearer {-" + this.getTokenName(resource) + "-}" },
            { name: 'Content-Type', value: 'application/json' }
        ];
        query.body = {
            records: [this.serializeModel(modelInstance, modelDescription, fields)]
        };
        return this.httpQueryService.requestBody(query, options).pipe(map(function (result) {
            var data = result.records.map(function (item) { return _this.deserializeModel(item, modelDescription); })[0];
            var instance = _this.createModel().deserialize(modelDescription.model, data);
            instance.setUp(modelDescription);
            instance.deserializeAttributes(modelDescription.dbFields);
            return instance;
        }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    AirtableResourceController.prototype.modelUpdate = function (resource, modelDescription, modelInstance, fields) {
        var _this = this;
        var options = { resource: resource.uniqueName };
        var query = new HttpQuery();
        var _a = modelDescription.model.split('_'), base = _a[0], table = _a[1];
        query.method = HttpMethod.PATCH;
        query.url = "https://api.airtable.com/v0/" + base + "/" + table;
        query.headers = [
            { name: 'Authorization', value: "Bearer {-" + this.getTokenName(resource) + "-}" },
            { name: 'Content-Type', value: 'application/json' }
        ];
        query.body = {
            records: [this.serializeModel(modelInstance, modelDescription, fields)]
        };
        return this.httpQueryService.requestBody(query, options).pipe(map(function (result) {
            var data = result.records.map(function (item) { return _this.deserializeModel(item, modelDescription); })[0];
            var instance = _this.createModel().deserialize(modelDescription.model, data);
            instance.setUp(modelDescription);
            instance.deserializeAttributes(modelDescription.dbFields);
            return instance;
        }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    AirtableResourceController.prototype.modelDelete = function (resource, modelDescription, modelInstance) {
        var options = { resource: resource.uniqueName };
        var query = new HttpQuery();
        var _a = modelDescription.model.split('_'), base = _a[0], table = _a[1];
        query.method = HttpMethod.DELETE;
        query.url = "https://api.airtable.com/v0/" + base + "/" + table + "/" + modelInstance.initialPrimaryKey;
        query.headers = [{ name: 'Authorization', value: "Bearer {-" + this.getTokenName(resource) + "-}" }];
        return this.httpQueryService
            .requestBody(query, options)
            .pipe(this.apiService.catchApiError(), publishLast(), refCount());
    };
    return AirtableResourceController;
}(ResourceController));
export { AirtableResourceController };

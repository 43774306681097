import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';

import { BasePopupComponent } from '@common/popups';
import { createFormFieldFactory } from '@modules/fields';
import { ProjectGroup } from '@modules/projects';

import { ProjectModelPermissionsArray } from '../project-group-change/project-model-permissions.array';
import { ProjectPagePermissionsArray } from '../project-group-change/project-page-permissions.array';

@Component({
  selector: 'app-project-group-edit-popup',
  templateUrl: './project-group-edit-popup.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ProjectModelPermissionsArray, ProjectPagePermissionsArray]
})
export class ProjectGroupEditPopupComponent implements OnDestroy {
  @Input() group: ProjectGroup;
  @Input() propertiesEnabled = true;
  @Input() deleteEnabled = true;
  @Output() created = new EventEmitter<ProjectGroup>();
  @Output() updated = new EventEmitter<ProjectGroup>();
  @Output() deleted = new EventEmitter<ProjectGroup>();

  createField = createFormFieldFactory();
  loading = false;

  constructor(private popupComponent: BasePopupComponent) {}

  ngOnDestroy(): void {}

  close() {
    this.popupComponent.close();
  }

  onSaved(group: ProjectGroup) {
    if (this.group) {
      this.updated.emit(group);
    } else {
      this.created.emit(group);
    }

    this.close();
  }

  onDeleted(group: ProjectGroup) {
    this.deleted.emit(group);
    this.close();
  }
}

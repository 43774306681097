import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';

import { LocalStorage } from '@core';
import { isSet } from '@shared';

import { PropertyItem } from '../custom-page-properties-section-item/custom-page-properties-section-item.component';

export interface PropertySectionButton {
  label: string;
  description?: string;
  icon?: string;
  handler: () => void;
}

export interface PropertySection {
  name: string;
  label: string;
  icon?: string;
  documentation?: string;
  items: PropertyItem[];
  itemsTotal: number;
  descriptionTitle?: string;
  descriptionText?: string;
  buttons: PropertySectionButton[];
  buttonsLabel?: string;
  buttonsIcon?: string;
  showEmpty?: boolean;
  getValues?: () => Object;
  defaultBannerHidden?: boolean;
  disabledTitle?: string;
  disabledText?: string;
}

@Component({
  selector: 'app-custom-page-properties-section',
  templateUrl: './custom-page-properties-section.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomPagePropertiesSectionComponent implements OnInit {
  @Input() section: PropertySection;
  @Input() values = {};

  bannerHidden = false;

  constructor(private localStorage: LocalStorage, private cd: ChangeDetectorRef) {}

  ngOnInit() {
    // const bannerHiddenKey = this.getBannerHiddenKey();
    // const bannerHidden = this.localStorage.get(bannerHiddenKey);
    //
    // if (isSet(bannerHidden)) {
    //   this.bannerHidden = !!bannerHidden;
    // } else if (isSet(this.section.defaultBannerHidden)) {
    //   this.bannerHidden = this.section.defaultBannerHidden;
    // } else {
    //   this.bannerHidden = false;
    // }
  }

  getBannerHiddenKey(): string {
    return ['properties_banner', this.section.name].join('_');
  }

  setBannerHidden(value: boolean) {
    this.bannerHidden = value;
    this.cd.markForCheck();

    const barHiddenKey = this.getBannerHiddenKey();
    if (value) {
      this.localStorage.set(barHiddenKey, '1');
    } else {
      this.localStorage.set(barHiddenKey, '0');
    }
  }
}

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import pickBy from 'lodash/pickBy';
import { DataSourceType, ListModelDescriptionDataSource } from '@modules/data-sources';
import { Input } from '@modules/fields';
import { WorkflowStepType } from '../workflow-step-type';
import { deserializerWorkflowStep, registerWorkflowStepType } from '../workflow-step-types';
import { WorkflowStep } from './base';
var IteratorWorkflowStep = /** @class */ (function (_super) {
    __extends(IteratorWorkflowStep, _super);
    function IteratorWorkflowStep() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.type = WorkflowStepType.Iterator;
        _this.sortingAsc = true;
        _this.steps = [];
        return _this;
    }
    IteratorWorkflowStep.prototype.deserialize = function (data) {
        _super.prototype.deserialize.call(this, data);
        this.sortingField = data['sorting_field'];
        if (data['data_source']) {
            this.dataSource = new ListModelDescriptionDataSource().deserialize(data['data_source']);
        }
        else if (data['iterate']) {
            this.dataSource = new ListModelDescriptionDataSource();
            this.dataSource.type = DataSourceType.Input;
            this.dataSource.input = new Input().deserialize(data['iterate']);
        }
        if (data['sorting_asc'] != undefined) {
            this.sortingAsc = data['sorting_asc'];
        }
        if (data['steps']) {
            this.steps = data['steps'].map(function (item) { return deserializerWorkflowStep(item); }).filter(function (item) { return item; });
        }
        return this;
    };
    IteratorWorkflowStep.prototype.serialize = function (fields) {
        var data = __assign({}, _super.prototype.serialize.call(this, fields), { data_source: this.dataSource ? this.dataSource.serialize() : undefined, sorting_field: this.sortingField, sorting_asc: this.sortingAsc, steps: this.steps.map(function (item) { return item.serialize(); }) });
        if (fields) {
            data = pickBy(data, function (v, k) { return fields.includes(k); });
        }
        return data;
    };
    IteratorWorkflowStep.prototype.defaultName = function () {
        return 'Iterator';
    };
    Object.defineProperty(IteratorWorkflowStep.prototype, "analyticsName", {
        get: function () {
            return 'iterator';
        },
        enumerable: true,
        configurable: true
    });
    IteratorWorkflowStep.prototype.getIcon = function () {
        return 'repeat';
    };
    return IteratorWorkflowStep;
}(WorkflowStep));
export { IteratorWorkflowStep };
registerWorkflowStepType(WorkflowStepType.Iterator, IteratorWorkflowStep);

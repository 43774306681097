var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import flatten from 'lodash/flatten';
import { filter, map, switchMap } from 'rxjs/operators';
import { CurrentProjectStore } from '@modules/projects';
import { SingletonStore } from '@shared';
import { GuideSectionService } from '../services/guide-section/guide-section.service';
var GuideSectionStore = /** @class */ (function (_super) {
    __extends(GuideSectionStore, _super);
    function GuideSectionStore(guideSectionService, currentProjectStore) {
        var _this = _super.call(this) || this;
        _this.guideSectionService = guideSectionService;
        _this.currentProjectStore = currentProjectStore;
        return _this;
    }
    GuideSectionStore.prototype.fetch = function () {
        var _this = this;
        return this.currentProjectStore.getFirst().pipe(filter(function () { return _this.currentProjectStore.instance != undefined; }), switchMap(function () { return _this.guideSectionService.get(_this.currentProjectStore.instance.uniqueName); }), map(function (result) {
            if (!result) {
                return [];
            }
            return result;
        }));
    };
    GuideSectionStore.prototype.getTasks = function (forceUpdate) {
        if (forceUpdate === void 0) { forceUpdate = false; }
        return this.getFirst(forceUpdate).pipe(map(function (result) {
            if (!result) {
                return;
            }
            return flatten(result.map(function (item) { return item.tasks; }));
        }));
    };
    return GuideSectionStore;
}(SingletonStore));
export { GuideSectionStore };

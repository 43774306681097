<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayPanelClass]="['overlay', 'overlay_horizontal_center']"
  [cdkConnectedOverlayPositions]="popoverPositions"
  [cdkConnectedOverlayOrigin]="origin"
  [cdkConnectedOverlayOpen]="dropdownOpened"
  [cdkConnectedOverlayPush]="true"
  [cdkConnectedOverlayGrowAfterOpen]="true"
  [cdkConnectedOverlayHasBackdrop]="true"
  [cdkConnectedOverlayBackdropClass]="'popover2-backdrop'"
  (backdropClick)="onBackgroundClick()"
  (detach)="setDropdownOpened(false)"
>
  <div class="popover2 overlay-content overlay-content_animated">
    <div class="popover2__inner">
      <app-filter-edit
        [dataSource]="dataSource"
        [filters]="filters"
        [filter]="filter"
        [field]="field"
        [onlyVisible]="onlyVisible"
        [optionsFilter]="optionsFilter"
        (added)="added.emit($event)"
        (edited)="edited.emit($event)"
        (deleted)="deleted.emit($event)"
        (filtersUpdated)="filtersUpdated.emit($event); close()"
      ></app-filter-edit>
    </div>
  </div>
</ng-template>

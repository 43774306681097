import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { ApiParameter } from '../../data/api-parameter';

@Component({
  selector: 'app-header-parameters',
  templateUrl: './header-parameters.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderParametersComponent implements OnInit {
  @Input() parameters: ApiParameter[] = [];
  @Input() loading = false;

  constructor() {}

  ngOnInit() {}
}

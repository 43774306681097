import { throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { PageLoadingService } from '@common/page-loading';
import { getColorHexStr } from '@modules/colors';
import { MetaService } from '@modules/meta';
import { DomainStore } from '../stores/domain.store';
import * as i0 from "@angular/core";
import * as i1 from "../stores/domain.store";
import * as i2 from "../../meta/services/meta.service";
import * as i3 from "../../../common/page-loading/services/page-loading/page-loading.service";
var DomainResolver = /** @class */ (function () {
    function DomainResolver(domainStore, metaService, pageLoadingService) {
        this.domainStore = domainStore;
        this.metaService = metaService;
        this.pageLoadingService = pageLoadingService;
    }
    DomainResolver.prototype.resolve = function (route, state) {
        var _this = this;
        return this.domainStore.getFirst().pipe(tap(function (domain) {
            if (domain) {
                _this.metaService.defaultMetaData = {
                    titleShort: domain.name,
                    shareTitle: domain.title,
                    description: domain.description,
                    image: domain.image,
                    icon: {
                        default: domain.icon
                    },
                    color: getColorHexStr(domain.logoColor)
                };
            }
            else {
                _this.metaService.setJetDefaultMetaData();
            }
            _this.metaService.resetMeta();
            _this.pageLoadingService.removeAppLoader();
        }), catchError(function (e) {
            _this.pageLoadingService.removeAppLoader();
            return throwError(e);
        }));
    };
    DomainResolver.ngInjectableDef = i0.defineInjectable({ factory: function DomainResolver_Factory() { return new DomainResolver(i0.inject(i1.DomainStore), i0.inject(i2.MetaService), i0.inject(i3.PageLoadingService)); }, token: DomainResolver, providedIn: "root" });
    return DomainResolver;
}());
export { DomainResolver };

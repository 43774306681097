import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatMenuTrigger } from '@angular/material/menu';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { colors } from '@modules/colors';
import { Option } from '@modules/field-components';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { ProjectStorageService } from '@modules/resources';
import { controlValue, fileToBase64, isSet } from '@shared';

export type LogoOption = Option<any, { url?: string; initials?: string }>;

export function getLogoControlsUrl(options: {
  urlControl: FormControl;
  fileControl?: FormControl;
  optionControl?: FormControl;
  options?: LogoOption[];
}): Observable<{ url?: string; initials?: string }> {
  return combineLatest(
    controlValue<string>(options.urlControl),
    options.fileControl ? controlValue(options.fileControl) : of(undefined),
    options.optionControl ? controlValue(options.optionControl) : of(undefined)
  ).pipe(
    switchMap(([url, file, option]) => {
      if (file) {
        return fileToBase64(file).pipe(map(value => ({ url: value })));
      } else if (isSet(url)) {
        return of({
          url: url
        });
      } else if (isSet(option) && options.options) {
        const logoOption = options.options.find(item => item.value == option);

        if (logoOption) {
          return of({
            url: logoOption.data.url,
            initials: logoOption.data.initials
          });
        }
      }

      return of(undefined);
    })
  );
}

@Component({
  selector: 'app-logo-uploader',
  templateUrl: './logo-uploader.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LogoUploaderComponent implements OnInit, OnDestroy {
  @Input() logoUrlControl: FormControl;
  @Input() logoFileControl: FormControl;
  @Input() logoIconControl: FormControl;
  @Input() logoOptionControl: FormControl;
  @Input() logoFillControl: FormControl;
  @Input() logoOptions: Option<any, { url?: string; initials?: string }>[] = [];
  @Input() colorControl: FormControl;
  @Input() colorCustomEnabledControl: FormControl;
  @Input() colorCustomControl: FormControl;
  @Input() colors = ['teal', 'green', 'red', 'black'].map(item => {
    return {
      value: item,
      color: item
    };
  });
  @Input() borderRadiusControl: FormControl;
  @Input() defaultColor: string;
  @Input() defaultColorBackground: string;
  @Input() defaultColorBorder: string;
  @Input() initials: string;

  @ViewChild('logo_file') logoFileElement: ElementRef;
  @ViewChild('options_menu_trigger', { read: MatMenuTrigger }) options_menu_trigger: MatMenuTrigger;

  logoUrl: string;
  logoInitials: string;
  colorDisplay: string;
  iconPopoverOpened = false;
  uploading = false;

  constructor(
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private projectStorageService: ProjectStorageService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    getLogoControlsUrl({
      urlControl: this.logoUrlControl,
      fileControl: this.logoFileControl,
      optionControl: this.logoOptionControl,
      options: this.logoOptions
    })
      .pipe(untilDestroyed(this))
      .subscribe(value => {
        this.logoUrl = value ? value.url : undefined;
        this.logoInitials = value ? value.initials : undefined;
        this.cd.markForCheck();
      });

    controlValue(this.colorControl)
      .pipe(untilDestroyed(this))
      .subscribe(value => {
        const color = value ? colors.find(item => item.name == value) : undefined;
        this.colorDisplay = color ? color.label : undefined;
        this.cd.markForCheck();
      });

    this.logoFillControl.valueChanges.pipe(untilDestroyed(this)).subscribe(() => this.cd.markForCheck());

    if (this.borderRadiusControl) {
      this.borderRadiusControl.valueChanges.pipe(untilDestroyed(this)).subscribe(() => this.cd.markForCheck());
    }
  }

  ngOnDestroy(): void {}

  get hasMultipleTypes(): boolean {
    return this.logoOptions.length > 0 || !!this.logoIconControl;
  }

  isSet(): boolean {
    return [this.logoUrlControl, this.logoFileControl, this.logoIconControl, this.logoOptionControl].some(
      control => control && isSet(control.value)
    );
  }

  isFillSupported(): boolean {
    return [this.logoUrlControl, this.logoFileControl, this.logoOptionControl].some(
      control => control && isSet(control.value)
    );
  }

  setValue(options: { url?: string; file?: File; icon?: string; option?: string }): void {
    this.logoUrlControl.patchValue(isSet(options.url) ? options.url : null);

    if (this.logoFileControl) {
      this.logoFileControl.patchValue(isSet(options.file) ? options.file : null);
    }

    if (this.logoIconControl) {
      this.logoIconControl.patchValue(isSet(options.icon) ? options.icon : null);
    }

    if (this.logoOptionControl) {
      this.logoOptionControl.patchValue(isSet(options.option) ? options.option : null);
    }
  }

  clear(): void {
    this.setValue({});
  }

  onFileChange(el: HTMLInputElement) {
    if (!el.files || !el.files.length) {
      return;
    }

    const file = el.files[0];

    el.value = null;

    if (this.logoFileControl) {
      this.setValue({ file: file });
      return;
    }

    const filePath = ['menu_items'].join('/');
    const fileName = file.name;

    this.uploading = true;
    this.cd.markForCheck();

    this.projectStorageService
      .uploadFile(
        this.currentProjectStore.instance.uniqueName,
        this.currentEnvironmentStore.instance.uniqueName,
        file,
        filePath,
        fileName
      )
      .pipe(untilDestroyed(this))
      .subscribe(
        response => {
          if (response.result) {
            this.uploading = false;
            this.cd.markForCheck();

            this.setValue({ url: response.result.uploadedUrl });
          }
        },
        () => {
          this.uploading = false;
          this.cd.markForCheck();
        }
      );
  }

  onLogoClick() {
    if (this.uploading) {
      return;
    }

    if (this.hasMultipleTypes) {
      this.options_menu_trigger.openMenu();
    } else {
      this.logoFileElement.nativeElement.click();
    }
  }

  openIconPopover(value) {
    this.iconPopoverOpened = value;
    this.cd.markForCheck();
  }
}

import values from 'lodash/values';
import * as moment from 'moment';

import { AggregateFunc, DataGroup, DatasetGroupLookup, datasetGroupLookupUnitOfTime } from '@modules/charts';
import { AVERAGE, MAX, MIN, SUM } from '@modules/fields';
import { Model } from '@modules/models';
import { isValidNumber } from '@shared';

import { ModelResponse } from '../data/model-response';

function parseDate(data: any, strict = false): moment.Moment {
  if (data instanceof moment) {
    return data;
  } else if (isValidNumber(data) && parseFloat(data) > 1000000) {
    return moment.unix(data);
  } else if (typeof data == 'string') {
    return moment(data, [moment.ISO_8601, 'YYYY-MM-DD HH:mm:ss', 'YYYY-MM-DD', 'MM-DD-YYYY', 'DD-MM', 'YYYY']);
  } else {
    return moment.invalid();
  }
}

export function groupGetResponse(
  response: ModelResponse.GetResponse,
  xColumns: { xColumn: string; xLookup?: DatasetGroupLookup }[],
  yFunc: AggregateFunc,
  yColumn: string,
  frontendFiltering = true
): DataGroup[] {
  if (!xColumns.length) {
    return [];
  }

  const processGroupColumn = (value: any, xLookup?: DatasetGroupLookup) => {
    const unit = datasetGroupLookupUnitOfTime(xLookup);

    if (unit) {
      const date = parseDate(value);

      if (date && date.isValid()) {
        value = moment(value).startOf(unit);
      }
    }

    if (value instanceof moment) {
      return value.toISOString();
    } else {
      return value;
    }
  };

  if (frontendFiltering) {
    const keySeparator = '__|__';
    const groupedModels: { [k: string]: { xColumnValues: any[]; models: Model[] } } = response.results.reduce(
      (acc, model) => {
        const xColumnValues = xColumns.map(item => {
          let itemKey: any = model.getAttribute(item.xColumn);
          itemKey = processGroupColumn(itemKey);
          return itemKey;
        });
        const totalKey = xColumnValues.join(keySeparator);

        if (!acc.hasOwnProperty(totalKey)) {
          acc[totalKey] = {
            xColumnValues: xColumnValues,
            models: []
          };
        }

        acc[totalKey].models.push(model);

        return acc;
      },
      {}
    );

    return values(groupedModels).map(item => {
      const result = new DataGroup();

      result.group = item.xColumnValues[0];
      result.group2 = item.xColumnValues[1];

      if (!yFunc && yColumn) {
        if (item.models.length > 1) {
          result.value = SUM(...item.models.map(model => parseFloat(model.getAttribute(yColumn))));
        } else {
          result.value = item.models[0].getAttribute(yColumn);
        }
      } else if (yFunc == AggregateFunc.Count) {
        result.value = item.models.length;
      } else if (yFunc == AggregateFunc.Sum && yColumn) {
        result.value = SUM(...item.models.map(model => parseFloat(model.getAttribute(yColumn))));
      } else if (yFunc == AggregateFunc.Min && yColumn) {
        result.value = MIN(...item.models.map(model => parseFloat(model.getAttribute(yColumn))));
      } else if (yFunc == AggregateFunc.Max && yColumn) {
        result.value = MAX(...item.models.map(model => parseFloat(model.getAttribute(yColumn))));
      } else if (yFunc == AggregateFunc.Avg && yColumn) {
        result.value = AVERAGE(...item.models.map(model => parseFloat(model.getAttribute(yColumn))));
      }

      return result;
    });
  } else {
    return response.results.map(item => {
      if (yFunc) {
        return new DataGroup().deserialize(item.getRawAttributes());
      } else {
        const group = new DataGroup();

        if (xColumns[0]) {
          group.group = item.getAttribute(xColumns[0].xColumn);
        }

        if (xColumns[1]) {
          group.group2 = item.getAttribute(xColumns[1].xColumn);
        }

        group.value = item.getAttribute(yColumn);

        return group;
      }
    });
  }
}

<app-customize-bar-header [title]="'Sign In'" [subtitle]="'Sign In & Sign Up'" [backEnabled]="false">
</app-customize-bar-header>

<div class="sidebar__content">
  <div *ngIf="!domain" class="sidebar__element">
    <app-alert [title]="'Domain is not set up'" [secondary]="true">
      <div>To customize sign up page you should connect custom domain to current App</div>
      <div style="margin-top: 4px;">
        <a href="javascript:void(0)" (click)="editDomain()">Connect domain</a>
      </div>
    </app-alert>
  </div>

  <app-sidebar-section [interactive]="!domain" (click)="onDomainRequiredClick()">
    <div class="sidebar__element">
      <app-sidebar-field [label]="'title'" [additional]="'(optional)'">
        <app-auto-field
          [form]="projectDomainUpdateForm"
          [label]="false"
          [field]="
            createField({
              name: 'sign_in_title',
              field: 'CharField',
              placeholder: 'Form title',
              params: { classes: ['input_fill'] }
            })
          "
        ></app-auto-field>
      </app-sidebar-field>
    </div>

    <div class="sidebar__element">
      <app-sidebar-field [label]="'description'" [additional]="'(optional)'">
        <app-auto-field
          [form]="projectDomainUpdateForm"
          [label]="false"
          [field]="
            createField({
              name: 'sign_in_description',
              field: 'CharField',
              placeholder: 'Form description',
              params: { classes: ['input_fill'], multiline: true }
            })
          "
        ></app-auto-field>
      </app-sidebar-field>
    </div>
  </app-sidebar-section>

  <app-sidebar-section>
    <div class="sidebar__element">
      <app-sidebar-field [label]="'terms of service URL'">
        <app-auto-field
          [form]="projectDomainUpdateForm"
          [label]="false"
          [field]="
            createField({
              name: 'terms_of_service',
              field: 'CharField',
              placeholder: 'https://www.jetadmin.io/terms-of-service',
              params: { classes: ['input_fill'] }
            })
          "
        ></app-auto-field>
      </app-sidebar-field>
    </div>

    <div class="sidebar__element">
      <app-sidebar-field [label]="'privacy policy URL'">
        <app-auto-field
          [form]="projectDomainUpdateForm"
          [label]="false"
          [field]="
            createField({
              name: 'privacy_policy',
              field: 'CharField',
              placeholder: 'https://www.jetadmin.io/privacy-policy',
              params: { classes: ['input_fill'] }
            })
          "
        ></app-auto-field>
      </app-sidebar-field>
    </div>
  </app-sidebar-section>

  <!--  <app-sidebar-section [interactive]="!domain" (click)="onDomainRequiredClick()">-->
  <!--    <div class="sidebar__element">-->
  <!--      <app-sidebar-field [label]="'Enforce SAML SSO'" [layoutLeft]="true" [tag]="'Enterprise'" [tagColor]="'teal'">-->
  <!--        <app-toggle [selected]="hasSSO" [orange]="true" [disabled]="true" (click)="openChat()"></app-toggle>-->

  <!--        <ng-container description>-->
  <!--          Require all members to sign in via SAML or OAuth2 to easily manage access to Jet Admin-->
  <!--        </ng-container>-->
  <!--      </app-sidebar-field>-->
  <!--    </div>-->

  <!--    <div class="sidebar__element">-->
  <!--      <app-sidebar-field [label]="'domain default project'" [layoutLeft]="true">-->
  <!--        <app-auto-field-->
  <!--          [form]="projectDomainUpdateForm"-->
  <!--          [label]="false"-->
  <!--          [field]="-->
  <!--            createField({-->
  <!--              name: 'default_project',-->
  <!--              field: 'BooleanField',-->
  <!--              params: { style: booleanFieldStyle.Toggle, orange: true }-->
  <!--            })-->
  <!--          "-->
  <!--        ></app-auto-field>-->

  <!--        <ng-container description>-->
  <!--          Sign in current App when opening domain-->
  <!--        </ng-container>-->
  <!--      </app-sidebar-field>-->
  <!--    </div>-->
  <!--  </app-sidebar-section>-->
</div>

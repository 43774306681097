import { InjectionToken } from '@angular/core';
import { Power2, TimelineMax } from 'gsap';
import { Subject } from 'rxjs';
export var POPUP_REF_OPTIONS = new InjectionToken('POPUP_REF_OPTIONS');
var PopupRef = /** @class */ (function () {
    function PopupRef(options) {
        if (options === void 0) { options = {}; }
        this.tl = new TimelineMax();
        this._closed$ = new Subject();
        Object.assign(this, options);
    }
    PopupRef.prototype.show = function () {
        if (!this.animate) {
            return;
        }
        this.tl
            .clear()
            .fromTo(this.backdrop || [], 0.15, {
            opacity: 0
        }, {
            opacity: 1,
            ease: Power2.easeOut
        }, 0)
            .fromTo(this.popup, 0.15, {
            y: -10,
            opacity: 0
        }, {
            y: 0,
            opacity: 1
        }, 0)
            .pause(0)
            .play();
    };
    PopupRef.prototype.close = function () {
        var _this = this;
        if (!this.animate) {
            this.dispose();
            return;
        }
        this.tl
            .clear()
            .to(this.popup, 0.15, {
            opacity: 0,
            y: 10
        }, 0)
            .to(this.backdrop || [], 0.2, {
            opacity: 0
        }, 0)
            .add(function () {
            _this.dispose();
        })
            .pause(0)
            .play();
    };
    PopupRef.prototype.dispose = function () {
        this.overlayRef.dispose();
        this._closed$.next();
    };
    Object.defineProperty(PopupRef.prototype, "closed$", {
        get: function () {
            return this._closed$.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    return PopupRef;
}());
export { PopupRef };

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as inflection from 'inflection';
import last from 'lodash/last';
import pickBy from 'lodash/pickBy';
import { deserializeDisplayField, DisplayField, FieldType, Input, ParameterField } from '@modules/fields';
import { Segment } from '@modules/filters';
import { ListModelDescriptionQuery, ModelDescriptionQuery, QueryType } from '@modules/queries';
import { ascComparator, defaultComparator, generateAlphanumeric, isSet, objectsSortPredicate, sortUsingAfterItem, splitmax, stripStart } from '@shared';
import { modelDbFieldToParameterField } from '../utils/common/common';
import { fromLegacyModel } from '../utils/legacy/legacy';
import { ModelDbField } from './model-db-field';
import { ModelFlexField } from './model-flex-field';
export var RelationDirection;
(function (RelationDirection) {
    RelationDirection["OneToMany"] = "ONETOMANY";
    RelationDirection["ManyToOne"] = "MANYTOONE";
    RelationDirection["ManyToMany"] = "MANYTOMANY";
})(RelationDirection || (RelationDirection = {}));
var ModelRelation = /** @class */ (function () {
    function ModelRelation(options) {
        if (options === void 0) { options = {}; }
        Object.assign(this, options);
    }
    ModelRelation.generateManyToOneName = function (options) {
        var name = [options.localField, 'to', options.relatedModel, options.relatedField].join('__');
        if (options.localModel.dbField(name)) {
            name = name + '_relation';
            console.log("Already detected column name, using " + name);
        }
        return name;
    };
    ModelRelation.generateOneToManyName = function (options) {
        var name = [options.relatedModel, options.relatedField, 'to', options.localField].join('__');
        if (options.localModel.dbField(name)) {
            name = name + '_relation';
            console.log("Already detected column name, using " + name);
        }
        return name;
    };
    ModelRelation.prototype.deserialize = function (data) {
        this.name = data['name'];
        this.direction = data['direction'];
        this.localField = data['local_field'];
        this.relatedModel = data['related_model'];
        this.relatedField = data['related_field'];
        return this;
    };
    return ModelRelation;
}());
export { ModelRelation };
export var ModelFieldType;
(function (ModelFieldType) {
    ModelFieldType["Db"] = "db";
    ModelFieldType["Flex"] = "flex";
    ModelFieldType["Custom"] = "custom";
})(ModelFieldType || (ModelFieldType = {}));
export function sortModelDescriptionsList(items, getter) {
    if (items.length <= 1) {
        return items;
    }
    return sortUsingAfterItem({
        items: items,
        getAfterItem: function (item) { return getter(item).orderAfter; },
        getItemId: function (item) { return getter(item).model; },
        defaultSort: function (lhs, rhs) {
            return ascComparator((getter(lhs).verboseNamePlural || getter(lhs).model).toLowerCase(), (getter(rhs).verboseNamePlural || getter(rhs).model).toLowerCase());
        }
    });
}
export function sortModelDescriptions(items) {
    return sortModelDescriptionsList(items, function (item) { return item; });
}
export function sortModelFields(items) {
    if (items.length <= 1) {
        return items;
    }
    return sortUsingAfterItem({
        items: items,
        getAfterItem: function (item) { return item.orderAfter; },
        getItemId: function (item) { return item.name; }
    });
}
var ModelField = /** @class */ (function () {
    function ModelField() {
        this.orderAfter = undefined;
        this.visible = true;
    }
    ModelField.prototype.deserialize = function (data) {
        this.name = data['name'];
        this.type = data['type'];
        if (data['item']) {
            if (this.type == ModelFieldType.Db) {
                this.item = new ModelDbField().deserialize(data['item']);
            }
            else if (this.type == ModelFieldType.Flex) {
                this.item = new ModelFlexField().deserialize(data['item']);
            }
            else if (this.type == ModelFieldType.Custom) {
                this.item = deserializeDisplayField(data['item']);
            }
        }
        if (data['data_source_order_after']) {
            this.orderAfter = data['data_source_order_after'];
        }
        else {
            this.orderAfter = data['order_after'];
        }
        if (data['data_source_hidden'] !== undefined) {
            this.visible = !data['data_source_hidden'];
        }
        else if (data['visible'] !== undefined) {
            this.visible = data['visible'];
        }
        return this;
    };
    ModelField.prototype.serialize = function () {
        return {
            name: this.name,
            type: this.type,
            item: this.item.serialize(),
            order_after: this.orderAfter,
            visible: this.visible
        };
    };
    Object.defineProperty(ModelField.prototype, "verboseName", {
        get: function () {
            return this.item.verboseName;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ModelField.prototype, "formField", {
        get: function () {
            return this.item.formField;
        },
        enumerable: true,
        configurable: true
    });
    ModelField.prototype.isDb = function () {
        return this.type == ModelFieldType.Db;
    };
    Object.defineProperty(ModelField.prototype, "icon", {
        get: function () {
            if (this.item instanceof DisplayField) {
                return this.item.icon;
            }
            else {
                return this.item.fieldDescription ? this.item.fieldDescription.icon : undefined;
            }
        },
        enumerable: true,
        configurable: true
    });
    return ModelField;
}());
export { ModelField };
var ModelDescription = /** @class */ (function () {
    function ModelDescription() {
        this.isView = false;
        this.fields = [];
        this.segments = [];
        this.relations = [];
        this.relationOverrides = [];
        this.autoVerboseName = false;
        this.getParameters = [];
        this.getInputs = [];
        this.getDetailParameters = [];
        this.getDetailParametersUseDefaults = false;
        this.createParameters = [];
        this.createParametersUseDefaults = false;
        this.updateParameters = [];
        this.updateParametersUseDefaults = false;
        this.deleteParameters = [];
        this.deleteParametersUseDefaults = false;
        this.aggregateParameters = [];
        this.groupParameters = [];
        this.fromResource = false;
        this.virtual = false;
        this.featured = false;
        this.demo = false;
        this.sync = false;
        this.syncFinished = false;
        this.syncResource = false;
        this.orderAfter = undefined;
        this.params = {};
        this.draft = false;
        this.deleted = false;
    }
    ModelDescription.generateModel = function () {
        return generateAlphanumeric(8, { letterFirst: true });
    };
    ModelDescription.prototype.deserialize = function (data, schemas) {
        if (schemas === void 0) { schemas = false; }
        var isQueriesStructure = data.hasOwnProperty('get_parameters');
        this.project = data['project'];
        this.resource = data['resource'];
        this.model = fromLegacyModel(data['model']);
        this.dbTable = data['db_table'];
        this.orderingField = data['ordering_field'];
        this.defaultOrderBy = data['default_order_by'];
        this.displayField = data['display_field'];
        this.primaryKeyField = data['primary_key_field'];
        this.description = data['description'];
        this.queryType = data['query_type'];
        this.virtual = data['virtual'];
        this.featured = data['featured'];
        this.demo = data['demo'];
        if (data.hasOwnProperty('is_view')) {
            this.isView = data['is_view'];
        }
        if (data['data_source_name']) {
            this.verboseName = data['data_source_name'];
        }
        else {
            this.verboseName = data['verbose_name'];
        }
        if (data['data_source_name_plural']) {
            this.verboseNamePlural = data['data_source_name_plural'];
        }
        else {
            this.verboseNamePlural = data['verbose_name_plural'];
        }
        if (data['data_source_hidden'] !== undefined) {
            this.hidden = data['data_source_hidden'];
        }
        else {
            this.hidden = data['hidden'];
        }
        this.generateVerboseNameIfNeeded(schemas);
        var dbFields = data['fields'];
        if (dbFields) {
            this.fields = this.fields.concat(dbFields.map(function (item) {
                var field = new ModelField();
                field.type = ModelFieldType.Db;
                field.item = new ModelDbField().deserialize(item);
                field.name = field.item.name;
                if (item['data_source_hidden'] !== undefined) {
                    field.visible = !item['data_source_hidden'];
                }
                else if (item['visible'] !== undefined) {
                    field.visible = item['visible'];
                }
                if (item['data_source_order_after']) {
                    field.orderAfter = item['data_source_order_after'];
                }
                else {
                    field.orderAfter = item['order_after'];
                }
                return field;
            }));
        }
        var flexFields = data['flex_fields'];
        if (flexFields) {
            this.fields = this.fields.concat(flexFields.map(function (item) {
                var field = new ModelField();
                field.type = ModelFieldType.Flex;
                field.item = new ModelFlexField().deserialize(item);
                field.name = field.item.name;
                if (item['data_source_hidden'] !== undefined) {
                    field.visible = !item['data_source_hidden'];
                }
                else if (item['visible'] !== undefined) {
                    field.visible = item['visible'];
                }
                if (item['data_source_order_after']) {
                    field.orderAfter = item['data_source_order_after'];
                }
                else {
                    field.orderAfter = item['order_after'];
                }
                return field;
            }));
        }
        var customFields = data['custom_fields'];
        if (customFields) {
            this.fields = this.fields.concat(customFields.map(function (item) {
                var field = new ModelField();
                field.type = ModelFieldType.Custom;
                field.item = deserializeDisplayField(item);
                field.name = field.item.name;
                if (item['data_source_hidden'] !== undefined) {
                    field.visible = !item['data_source_hidden'];
                }
                else if (item['visible'] !== undefined) {
                    field.visible = item['visible'];
                }
                if (item['data_source_order_after']) {
                    field.orderAfter = item['data_source_order_after'];
                }
                else {
                    field.orderAfter = item['order_after'];
                }
                return field;
            }));
        }
        this.fields
            .filter(function (item) { return item.item.field == FieldType.RelatedModel; })
            .forEach(function (field) {
            if (field.item.params && field.item.params['related_model'] && field.item.params['related_model']['model']) {
                ['{{resource}}.', '.'].forEach(function (prefix) {
                    field.item.params['related_model']['model'] = stripStart(field.item.params['related_model']['model'], prefix);
                });
            }
        });
        if (data['default_fields']) {
            this.defaultFields = data['default_fields'].map(function (item) { return new ModelField().deserialize(item); });
        }
        // if (!this.primaryKeyField) {
        //   let idField, firstField;
        //
        //   if ((idField = this.dbFields.find(item => item.name.toLowerCase() == 'id'))) {
        //     this.primaryKeyField = idField.name;
        //   } else if ((firstField = this.fields[0])) {
        //     this.primaryKeyField = firstField.name;
        //   }
        // }
        if (data['segments']) {
            this.segments = data['segments'].map(function (item) { return new Segment().deserialize(item); });
        }
        if (data['relations']) {
            this.relations = data['relations'].map(function (item) { return new ModelRelation().deserialize(item); });
        }
        if (data['relation_overrides']) {
            this.relationOverrides = data['relation_overrides'].map(function (item) { return new ModelRelation().deserialize(item); });
        }
        if (isQueriesStructure) {
            if (data['get_query']) {
                this.getQuery = new ListModelDescriptionQuery().deserialize(data['get_query']);
            }
        }
        else {
            this.getQuery = new ListModelDescriptionQuery();
            this.getQuery.queryType = QueryType.Simple;
            this.getQuery.simpleQuery = new this.getQuery.simpleQueryClass();
            this.getQuery.simpleQuery.model = this.model;
        }
        if (data['get_parameters']) {
            this.getParameters = data['get_parameters'].map(function (item) { return new ParameterField().deserialize(item); });
        }
        if (data['get_inputs']) {
            this.getInputs = data['get_inputs'].map(function (item) { return new Input().deserialize(item); });
        }
        if (isQueriesStructure) {
            if (data['search_query']) {
                this.searchQuery = new ListModelDescriptionQuery().deserialize(data['search_query']);
            }
        }
        else {
            this.searchQuery = new ListModelDescriptionQuery();
            this.searchQuery.queryType = QueryType.Simple;
            this.searchQuery.simpleQuery = new this.searchQuery.simpleQueryClass();
            this.searchQuery.simpleQuery.model = this.model;
        }
        if (isQueriesStructure) {
            if (data['get_detail_query']) {
                this.getDetailQuery = new ModelDescriptionQuery().deserialize(data['get_detail_query']);
            }
        }
        else {
            this.getDetailQuery = new ModelDescriptionQuery();
            this.getDetailQuery.queryType = QueryType.Simple;
            this.getDetailQuery.simpleQuery = new this.getDetailQuery.simpleQueryClass();
            this.getDetailQuery.simpleQuery.model = this.model;
        }
        if (data['get_detail_parameters']) {
            this.getDetailParameters = data['get_detail_parameters'].map(function (item) { return new ParameterField().deserialize(item); });
        }
        if (data['get_detail_parameters_use_defaults']) {
            this.getDetailParametersUseDefaults = data['get_detail_parameters_use_defaults'];
        }
        if (isQueriesStructure) {
            if (data['create_query']) {
                this.createQuery = new ModelDescriptionQuery().deserialize(data['create_query']);
            }
        }
        else {
            this.createQuery = new ModelDescriptionQuery();
            this.createQuery.queryType = QueryType.Simple;
            this.createQuery.simpleQuery = new this.createQuery.simpleQueryClass();
            this.createQuery.simpleQuery.model = this.model;
        }
        if (data['create_parameters']) {
            this.createParameters = data['create_parameters'].map(function (item) { return new ParameterField().deserialize(item); });
        }
        if (data['create_parameters_use_defaults']) {
            this.createParametersUseDefaults = data['create_parameters_use_defaults'];
        }
        if (isQueriesStructure) {
            if (data['update_query']) {
                this.updateQuery = new ModelDescriptionQuery().deserialize(data['update_query']);
            }
        }
        else {
            this.updateQuery = new ModelDescriptionQuery();
            this.updateQuery.queryType = QueryType.Simple;
            this.updateQuery.simpleQuery = new this.updateQuery.simpleQueryClass();
            this.updateQuery.simpleQuery.model = this.model;
        }
        if (data['update_parameters']) {
            this.updateParameters = data['update_parameters'].map(function (item) { return new ParameterField().deserialize(item); });
        }
        if (data['update_parameters_use_defaults']) {
            this.updateParametersUseDefaults = data['update_parameters_use_defaults'];
        }
        if (isQueriesStructure) {
            if (data['delete_query']) {
                this.deleteQuery = new ModelDescriptionQuery().deserialize(data['delete_query']);
            }
        }
        else {
            this.deleteQuery = new ModelDescriptionQuery();
            this.deleteQuery.queryType = QueryType.Simple;
            this.deleteQuery.simpleQuery = new this.deleteQuery.simpleQueryClass();
            this.deleteQuery.simpleQuery.model = this.model;
        }
        if (data['delete_parameters']) {
            this.deleteParameters = data['delete_parameters'].map(function (item) { return new ParameterField().deserialize(item); });
        }
        if (data['delete_parameters_use_defaults']) {
            this.deleteParametersUseDefaults = data['delete_parameters_use_defaults'];
        }
        if (data['aggregate_query']) {
            this.aggregateQuery = new ModelDescriptionQuery().deserialize(data['aggregate_query']);
        }
        if (data['aggregate_parameters']) {
            this.aggregateParameters = data['aggregate_parameters'].map(function (item) { return new ParameterField().deserialize(item); });
        }
        if (data['group_query']) {
            this.groupQuery = new ModelDescriptionQuery().deserialize(data['group_query']);
        }
        if (data['group_parameters']) {
            this.groupParameters = data['group_parameters'].map(function (item) { return new ParameterField().deserialize(item); });
        }
        if (data['params']) {
            this.params = data['params'];
        }
        if (this.orderingField) {
            this.defaultOrderBy = this.orderingField;
        }
        if (data['draft'] !== undefined) {
            this.draft = data['draft'];
        }
        if (data['deleted'] !== undefined) {
            this.deleted = data['deleted'];
        }
        if (data['sync'] !== undefined) {
            this.sync = data['sync'];
        }
        if (data['sync_finished'] !== undefined) {
            this.syncFinished = data['sync_finished'];
        }
        if (data['data_source_order_after'] !== undefined) {
            this.orderAfter = data['data_source_order_after'];
        }
        else {
            this.orderAfter = data['order_after'];
        }
        return this;
    };
    ModelDescription.prototype.serialize = function (fields) {
        var data = {
            project: this.project,
            resource: this.resource,
            model: this.model,
            verbose_name: this.verboseName,
            verbose_name_plural: this.verboseNamePlural,
            db_table: this.dbTable,
            hidden: this.hidden,
            fields: this.fields
                .filter(function (item) { return item.type == ModelFieldType.Db; })
                .map(function (item) {
                return __assign({}, item.item.serialize(), { order_after: item.orderAfter, visible: item.visible });
            }),
            flex_fields: this.fields
                .filter(function (item) { return item.type == ModelFieldType.Flex; })
                .map(function (item) {
                return __assign({}, item.item.serialize(), { order_after: item.orderAfter, visible: item.visible });
            }),
            custom_fields: this.fields
                .filter(function (item) { return item.type == ModelFieldType.Custom; })
                .map(function (item) {
                return __assign({}, item.item.serialize(), { order_after: item.orderAfter, visible: item.visible });
            }),
            default_fields: this.defaultFields ? this.defaultFields.map(function (item) { return item.serialize(); }) : undefined,
            segments: this.segments.map(function (item) { return item.serialize(); }),
            ordering_field: this.orderingField,
            default_order_by: this.defaultOrderBy,
            display_field: this.displayField,
            primary_key_field: this.primaryKeyField,
            description: this.description,
            query_type: this.queryType,
            get_query: this.getQuery ? this.getQuery.serialize() : undefined,
            get_parameters: this.getParameters.map(function (item) { return item.serialize(); }),
            get_inputs: this.getInputs.map(function (item) { return item.serialize(); }),
            search_query: this.searchQuery ? this.searchQuery.serialize() : undefined,
            get_detail_query: this.getDetailQuery ? this.getDetailQuery.serialize() : undefined,
            get_detail_parameters: this.getDetailParameters.map(function (item) { return item.serialize(); }),
            get_detail_parameters_use_defaults: this.getDetailParametersUseDefaults,
            create_query: this.createQuery ? this.createQuery.serialize() : undefined,
            create_parameters: this.createParameters.map(function (item) { return item.serialize(); }),
            create_parameters_use_defaults: this.createParametersUseDefaults,
            update_query: this.updateQuery ? this.updateQuery.serialize() : undefined,
            update_parameters: this.updateParameters.map(function (item) { return item.serialize(); }),
            update_parameters_use_defaults: this.updateParametersUseDefaults,
            delete_query: this.deleteQuery ? this.deleteQuery.serialize() : undefined,
            delete_parameters: this.deleteParameters.map(function (item) { return item.serialize(); }),
            delete_parameters_use_defaults: this.deleteParametersUseDefaults,
            aggregate_query: this.aggregateQuery ? this.aggregateQuery.serialize() : undefined,
            aggregate_parameters: this.aggregateParameters.map(function (item) { return item.serialize(); }),
            group_query: this.groupQuery ? this.groupQuery.serialize() : undefined,
            group_parameters: this.groupParameters.map(function (item) { return item.serialize(); }),
            virtual: this.virtual,
            featured: this.featured,
            demo: this.demo,
            sync: this.sync,
            sync_finished: this.syncFinished,
            order_after: this.orderAfter,
            params: this.params,
            draft: this.draft,
            deleted: this.deleted
        };
        if (fields) {
            data = pickBy(data, function (v, k) { return fields.includes(k); });
        }
        return data;
    };
    Object.defineProperty(ModelDescription.prototype, "getDetailEnabled", {
        get: function () {
            return this.getDetailQuery || this.getQuery;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ModelDescription.prototype, "createEnabled", {
        get: function () {
            return this.createQuery;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ModelDescription.prototype, "updateEnabled", {
        get: function () {
            return this.updateQuery;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ModelDescription.prototype, "deleteEnabled", {
        get: function () {
            return this.deleteQuery;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ModelDescription.prototype, "siblingsEnabled", {
        get: function () {
            return this.siblingsQuery;
        },
        enumerable: true,
        configurable: true
    });
    ModelDescription.prototype.generateVerboseNameIfNeeded = function (schemas) {
        if (schemas === void 0) { schemas = false; }
        if (this.verboseName == undefined && isSet(this.dbTable)) {
            var dbTable = schemas ? last(splitmax(this.dbTable, '.', 2)) : this.dbTable;
            this.verboseName = dbTable.replace(/_/g, ' ');
            this.autoVerboseName = true;
        }
        else if (this.verboseName == undefined && this.model) {
            this.verboseName = this.model.replace(/_/g, ' ');
            this.autoVerboseName = true;
        }
        if (this.verboseNamePlural == undefined && this.verboseName) {
            this.verboseNamePlural = inflection.pluralize(this.verboseName);
        }
    };
    Object.defineProperty(ModelDescription.prototype, "modelId", {
        get: function () {
            if (!this.resource) {
                return this.model;
            }
            return [this.resource, this.model].join('.');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ModelDescription.prototype, "link", {
        get: function () {
            return ['models', this.modelId];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ModelDescription.prototype, "createLink", {
        get: function () {
            return ['models', this.modelId, 'create'];
        },
        enumerable: true,
        configurable: true
    });
    ModelDescription.prototype.changeLink = function (primaryKey) {
        if (!this.getDetailEnabled) {
            return;
        }
        return ['models', this.modelId, primaryKey];
    };
    Object.defineProperty(ModelDescription.prototype, "userActivityLink", {
        get: function () {
            return ['models', this.modelId, 'user_activities'];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ModelDescription.prototype, "systemSettingsLink", {
        get: function () {
            return this.systemSettingsTabLink();
        },
        enumerable: true,
        configurable: true
    });
    ModelDescription.prototype.systemSettingsTabLink = function (tab, subTab) {
        var link = ['resources', this.resource, 'models', this.model];
        if (tab) {
            link.push(tab);
            if (subTab) {
                link.push(subTab);
            }
        }
        return link;
    };
    Object.defineProperty(ModelDescription.prototype, "deleteLink", {
        get: function () {
            return ['models', this.modelId, 'delete'];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ModelDescription.prototype, "massEditLink", {
        get: function () {
            return ['models', this.modelId, 'mass_edit'];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ModelDescription.prototype, "dbFields", {
        get: function () {
            return this.fields.filter(function (item) { return item.type == ModelFieldType.Db; }).map(function (item) { return item.item; });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ModelDescription.prototype, "dbDefaultFields", {
        get: function () {
            if (!this.defaultFields) {
                return;
            }
            return this.defaultFields.filter(function (item) { return item.type == ModelFieldType.Db; }).map(function (item) { return item.item; });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ModelDescription.prototype, "flexFields", {
        get: function () {
            return this.fields.filter(function (item) { return item.type == ModelFieldType.Flex; }).map(function (item) { return item.item; });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ModelDescription.prototype, "customFields", {
        get: function () {
            return this.fields.filter(function (item) { return item.type == ModelFieldType.Custom; }).map(function (item) { return item.item; });
        },
        enumerable: true,
        configurable: true
    });
    ModelDescription.prototype.field = function (name) {
        return this.fields.find(function (item) { return item.name == name; });
    };
    ModelDescription.prototype.dbField = function (name) {
        return this.dbFields.find(function (item) { return item.name == name; });
    };
    ModelDescription.prototype.flexField = function (name) {
        return this.flexFields.find(function (item) { return item.name == name; });
    };
    ModelDescription.prototype.customField = function (name) {
        return this.customFields.find(function (item) { return item.name == name; });
    };
    ModelDescription.prototype.relation = function (name) {
        return this.allRelations().find(function (item) { return item.name == name; });
    };
    ModelDescription.prototype.allRelations = function () {
        return this.relations.concat(this.relationOverrides);
    };
    Object.defineProperty(ModelDescription.prototype, "primaryKey", {
        get: function () {
            return this.dbField(this.primaryKeyField);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ModelDescription.prototype, "displayFields", {
        get: function () {
            var _this = this;
            return this.fields.filter(function (item) { return item.name != _this.primaryKeyField; });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ModelDescription.prototype, "displayDbFields", {
        get: function () {
            var _this = this;
            return this.dbFields.filter(function (item) { return item.name != _this.primaryKeyField; });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ModelDescription.prototype, "defaultGetDetailParameters", {
        get: function () {
            var _this = this;
            return this.dbFields
                .filter(function (item) { return item.name == _this.primaryKeyField; })
                .map(function (item) {
                var result = modelDbFieldToParameterField(item);
                result.required = result.name == _this.primaryKeyField;
                return result;
            });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ModelDescription.prototype, "getDetailParametersOrDefaults", {
        get: function () {
            return this.getDetailParametersUseDefaults ? this.defaultGetDetailParameters : this.getDetailParameters;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ModelDescription.prototype, "defaultCreateParameters", {
        get: function () {
            var _this = this;
            var fields = this.dbDefaultFields || this.dbFields;
            return fields
                .filter(function (item) {
                if (item.name == _this.primaryKeyField) {
                    return item.required;
                }
                else {
                    return true;
                }
            })
                .filter(function (item) { return item.editable; })
                .map(function (item) { return modelDbFieldToParameterField(item); })
                .sort(objectsSortPredicate(function (lhs, rhs) { return defaultComparator(lhs.name == _this.primaryKeyField, rhs.name == _this.primaryKeyField) * -1; }, '-required'));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ModelDescription.prototype, "createParametersOrDefaults", {
        get: function () {
            return this.createParametersUseDefaults ? this.defaultCreateParameters : this.createParameters;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ModelDescription.prototype, "defaultUpdateParameters", {
        get: function () {
            var _this = this;
            var fields = this.dbDefaultFields || this.dbFields;
            return fields
                .filter(function (item) { return item.editable || item.name == _this.primaryKeyField; })
                .map(function (item) {
                var result = modelDbFieldToParameterField(item);
                result.required = result.name == _this.primaryKeyField;
                return result;
            })
                .sort(objectsSortPredicate(function (lhs, rhs) { return defaultComparator(lhs.name == _this.primaryKeyField, rhs.name == _this.primaryKeyField) * -1; }, '-required'));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ModelDescription.prototype, "updateParametersOrDefaults", {
        get: function () {
            return this.updateParametersUseDefaults ? this.defaultUpdateParameters : this.updateParameters;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ModelDescription.prototype, "defaultDeleteParameters", {
        get: function () {
            var _this = this;
            var fields = this.dbDefaultFields || this.dbFields;
            return fields
                .filter(function (item) { return item.name == _this.primaryKeyField; })
                .map(function (item) {
                var result = modelDbFieldToParameterField(item);
                result.required = result.name == _this.primaryKeyField;
                return result;
            });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ModelDescription.prototype, "deleteParametersOrDefaults", {
        get: function () {
            return this.deleteParametersUseDefaults ? this.defaultDeleteParameters : this.deleteParameters;
        },
        enumerable: true,
        configurable: true
    });
    ModelDescription.prototype.getMinimal = function () {
        var instance = new ModelDescription();
        instance.resource = this.resource;
        instance.model = this.model;
        return instance;
    };
    ModelDescription.prototype.isSame = function (model) {
        if (!model) {
            return false;
        }
        if (model instanceof ModelDescription) {
            return model.resource == this.resource && model.model == this.model;
        }
        else {
            var modelId = model;
            var params = splitmax(modelId, '.', 2);
            return params.length == 2
                ? (!this.resource || this.resource == params[0]) && this.model == params[1]
                : this.model == params[0];
        }
    };
    ModelDescription.prototype.autoActions = function () {
        return [
            {
                name: 'create',
                label: 'Create',
                primaryKey: false,
                query: 'createQuery',
                uniqueName: this.autoActionUniqueName('create'),
                modify: true
            },
            {
                name: 'update',
                label: 'Update',
                primaryKey: true,
                query: 'updateQuery',
                uniqueName: this.autoActionUniqueName('update'),
                modify: true
            },
            {
                name: 'delete',
                label: 'Delete',
                primaryKey: true,
                query: 'deleteQuery',
                uniqueName: this.autoActionUniqueName('delete'),
                modify: true
            },
            {
                name: 'get',
                label: 'Get List',
                primaryKey: false,
                query: 'getQuery',
                uniqueName: this.autoActionUniqueName('get'),
                modify: false
            },
            {
                name: 'get_detail',
                label: 'Get One',
                primaryKey: false,
                query: 'getDetailQuery',
                uniqueName: this.autoActionUniqueName('get_detail'),
                modify: false
            }
        ];
    };
    ModelDescription.prototype.autoActionUniqueName = function (name) {
        return ['', this.model, name].join('__');
    };
    ModelDescription.prototype.getQuerySettings = function (name) {
        if (name == 'get') {
            return {
                query: this.getQuery,
                parameters: this.getParameters
            };
        }
        else if (name == 'get_detail') {
            return {
                query: this.getDetailQuery,
                parameters: this.getDetailParametersOrDefaults,
                parametersUseDefaults: this.getDetailParametersUseDefaults
            };
        }
        else if (name == 'create') {
            return {
                query: this.createQuery,
                parameters: this.createParametersOrDefaults,
                parametersUseDefaults: this.createParametersUseDefaults
            };
        }
        else if (name == 'update') {
            return {
                query: this.updateQuery,
                parameters: this.updateParametersOrDefaults,
                parametersUseDefaults: this.updateParametersUseDefaults
            };
        }
        else if (name == 'delete') {
            return {
                query: this.deleteQuery,
                parameters: this.deleteParametersOrDefaults,
                parametersUseDefaults: this.deleteParametersUseDefaults
            };
        }
    };
    ModelDescription.prototype.setQuerySettings = function (name, query, parameters) {
        if (name == 'get') {
            this.getQuery = query;
            this.getParameters = parameters;
        }
        else if (name == 'get_detail') {
            this.getDetailQuery = query;
            this.getDetailParameters = parameters;
        }
        else if (name == 'create') {
            this.createQuery = query;
            this.createParameters = parameters;
        }
        else if (name == 'update') {
            this.updateQuery = query;
            this.updateParameters = parameters;
        }
        else if (name == 'delete') {
            this.deleteQuery = query;
            this.deleteParameters = parameters;
        }
    };
    ModelDescription.prototype.deleteField = function (name) {
        var fields = sortModelFields(this.fields).filter(function (item) { return item.name != name; });
        fields.forEach(function (item, i) {
            var prevItem = fields[i - 1];
            var prevItemName = prevItem ? prevItem.name : undefined;
            if (item.orderAfter !== prevItemName) {
                item.orderAfter = prevItemName;
            }
        });
        this.fields = fields;
    };
    ModelDescription.prototype.moveField = function (name, afterName) {
        var fields = sortModelFields(this.fields);
        var fieldIndex = fields.findIndex(function (item) { return item.name == name; });
        if (fieldIndex !== -1) {
            var popped = fields.splice(fieldIndex, 1);
            var afterFieldIndex = isSet(afterName) ? fields.findIndex(function (item) { return item.name == afterName; }) : -1;
            fields.splice.apply(fields, [afterFieldIndex != -1 ? afterFieldIndex + 1 : 0, 0].concat(popped));
        }
        fields.forEach(function (item, i) {
            var prevItem = fields[i - 1];
            var prevItemName = prevItem ? prevItem.name : undefined;
            if (item.orderAfter !== prevItemName) {
                item.orderAfter = prevItemName;
            }
        });
    };
    ModelDescription.prototype.applyFieldSettings = function (fieldSettings) {
        var _this = this;
        fieldSettings.forEach(function (item) {
            var field = _this.field(item.field);
            if (field) {
                field.visible = item.visible;
            }
        });
    };
    ModelDescription.prototype.isSynced = function () {
        return this.sync;
    };
    ModelDescription.prototype.isSyncedFinished = function () {
        return this.syncFinished;
    };
    ModelDescription.prototype.isSyncRunning = function () {
        return this.isSynced() && !this.isSyncedFinished();
    };
    return ModelDescription;
}());
export { ModelDescription };

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  Type,
  ViewChild
} from '@angular/core';
import cloneDeep from 'lodash/cloneDeep';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { CalendarComponent } from '@modules/calendar';
import {
  CustomizeService,
  ElementType,
  getListLayoutSettingsClass,
  LinkButton,
  ListElementItem,
  ListLayoutSettings,
  registerElementComponent,
  ViewContextElement,
  ViewContextElementFactory
} from '@modules/customize';
import { CustomizeBarContext, CustomizeBarEditEventType, CustomizeBarService } from '@modules/customize-bar';
import { BaseElementComponent } from '@modules/customize-elements';
import { ViewSettingsGeneratorService } from '@modules/customize-generators';
import { Input as FieldInput } from '@modules/fields';
import { GridComponent } from '@modules/grid';
import { KanbanBoardComponent } from '@modules/kanban-board';
import { LayoutService, ListLayout, listLayouts, ListLayoutType } from '@modules/layouts';
import { ListContextService } from '@modules/list';
import { ListLayoutComponent } from '@modules/list-components';
import { MapComponent } from '@modules/map';
import { ModelDescriptionStore, ModelService } from '@modules/model-queries';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { TableComponent } from '@modules/table';
import { TimelineComponent } from '@modules/timeline';
import { TypedChanges } from '@shared';

import { CustomPagePopupComponent } from '../custom-page-popup/custom-page-popup.component';

@Component({
  selector: 'app-list-element',
  templateUrl: './list-element.component.html',
  providers: [LayoutService, ListContextService, ViewContextElement],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ListElementComponent extends BaseElementComponent implements OnInit, OnDestroy, OnChanges {
  @Input() element: ListElementItem;

  @ViewChild(TableComponent) tableComponent: TableComponent;
  @ViewChild(KanbanBoardComponent) kanbanBoardComponent: KanbanBoardComponent;
  @ViewChild(MapComponent) mapComponent: MapComponent;
  @ViewChild(CalendarComponent) calendarComponent: CalendarComponent;
  @ViewChild(GridComponent) gridComponent: GridComponent;
  @ViewChild(TimelineComponent) timelineComponent: TimelineComponent;

  params: Object = {};
  hideParams = [];
  layoutTypes = ListLayoutType;
  layout: ListLayout;
  layoutIndex: number;
  contextElements: { element: ViewContextElement; layout: ListLayoutSettings }[] = [];
  moreLink: LinkButton;
  // formSubscription: Subscription;
  viewId: string;

  customizeEnabled$: Observable<boolean>;

  constructor(
    private customizeService: CustomizeService,
    private modelService: ModelService,
    private modelDescriptionStore: ModelDescriptionStore,
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    @Optional() private customizeBarContext: CustomizeBarContext,
    private customizeBarService: CustomizeBarService,
    private viewContextElementFactory: ViewContextElementFactory,
    private injector: Injector,
    private viewSettingsGeneratorService: ViewSettingsGeneratorService,
    public viewContextElement: ViewContextElement,
    private cd: ChangeDetectorRef,
    public layoutService: LayoutService,
    @Optional() private popup: CustomPagePopupComponent
  ) {
    super();
  }

  ngOnInit() {
    this.customizeEnabled$ = this.customizeService.enabled$.pipe(map(item => !!item));
    this.viewContextElement.initElement({
      uniqueName: this.element.uid,
      name: this.element.name,
      icon: 'table',
      allowSkip: true,
      element: this.element,
      popup: this.popup ? this.popup.popup : undefined
    });
    this.viewId = [this.currentProjectStore.instance.uniqueName, 'element', this.element.uid].join('_');

    this.updateLayouts();

    combineLatest(this.layoutService.currentLayout$, this.layoutService.currentLayoutIndex$)
      .pipe(untilDestroyed(this))
      .subscribe(([layout, index]) => {
        this.layout = layout;
        this.layoutIndex = index;
        this.cd.markForCheck();
      });
  }

  ngOnDestroy(): void {}

  ngOnChanges(changes: TypedChanges<ListElementComponent>): void {
    if (changes.element && !changes.element.firstChange) {
      this.viewContextElement.initInfo({ name: this.element.name, element: this.element }, true);
      this.updateLayouts();
    }
  }

  public getCurrentLayoutIndex() {
    return this.layoutService.currentLayoutIndex;
  }

  public getContextElements(): ViewContextElement[] {
    return this.contextElements.map(item => item.element);
  }

  getListComponent(): ListLayoutComponent {
    return (
      this.tableComponent ||
      this.kanbanBoardComponent ||
      this.mapComponent ||
      this.calendarComponent ||
      this.gridComponent ||
      this.timelineComponent
    );
  }

  // subscribeParams(modelDescription) {
  //   if (this.formSubscription) {
  //     this.formSubscription.unsubscribe();
  //   }
  //
  //   if (!this.context) {
  //     return;
  //   }
  //
  //   this.formSubscription = this.context.form.controls[this.context.modelDescription.primaryKeyField].valueChanges
  //     .pipe(
  //       delay(0),
  //       untilDestroyed(this)
  //     )
  //     .subscribe(() => {
  //       this.updateParams(modelDescription, this.context.model);
  //     });
  //
  //   this.updateParams(modelDescription, this.context.model);
  // }
  //
  // updateParams(modelDescription, model: Model) {
  //   if (!model) {
  //     this.params.next(undefined);
  //     return;
  //   }
  //
  //   const params = this.element.relation.backFilterParams(this.context.modelDescription, [model]);
  //
  //   this.params.next({
  //     modelDescription: modelDescription,
  //     queryParams: params
  //   });
  // }

  updateLayouts() {
    combineLatest(this.element.layouts.map(item => this.viewSettingsGeneratorService.applyListLayoutDefaults(item)))
      .pipe(untilDestroyed(this))
      .subscribe(result => {
        this.element.layouts = result;
      });

    const layouts = this.element.layouts.map(item => item.type);
    this.layoutService.init(layouts, this.viewId);
    this.updateContextElements();
  }

  updateContextElements() {
    this.contextElements
      .filter((item, i) => this.element && !this.element.layouts[i])
      .forEach(item => item.element.unregister());
    this.contextElements = this.element.layouts
      ? this.element.layouts.map((layout, i) => {
          const existing = this.contextElements[i];
          const listLayout = listLayouts.find(item => item.type == layout.type);
          const fieldFilters = listLayout && listLayout.filters;
          const icon = listLayout ? listLayout.icon : undefined;

          if (existing) {
            existing.element.initInfo(
              {
                uniqueName: i.toString(),
                name: listLayout.label,
                icon: icon,
                fieldFilters: fieldFilters,
                getFieldValue: (field, outputs) => {
                  const component = this.getListComponent();
                  const model = component ? component.getAnyModel() : undefined;
                  return model ? model.getAttribute(field) : undefined;
                }
              },
              true
            );
            return existing;
          }

          const element = this.viewContextElementFactory.create(this.injector);
          element.initElement(
            {
              uniqueName: i.toString(),
              name: listLayout.label,
              icon: icon,
              fieldFilters: fieldFilters,
              getFieldValue: (field, outputs) => {
                const component = this.getListComponent();
                const model = component ? component.getAnyModel() : undefined;
                return model ? model.getAttribute(field) : undefined;
              }
            },
            this.viewContextElement
          );
          return { element: element, layout: layout };
        })
      : [];
    this.cd.markForCheck();
  }

  openSettings(index) {
    const settings = this.element.layouts[index];
    const element = this.context.getElementChildren(this.viewContextElement)[index];

    this.customizeBarService
      .customizeListLayoutSettings({
        context: this.customizeBarContext,
        settings: settings,
        title: this.element.name,
        titleEditable: true,
        viewContext: this.viewContextElement.context,
        viewContextElement: element,
        deleteEnabled: this.element.layouts.length > 1
      })
      .pipe(untilDestroyed(this))
      .subscribe(e => {
        if (e.type == CustomizeBarEditEventType.Updated) {
          const instance = e.args['result'] as ListLayoutSettings;
          const title = e.args['title'] as string;
          this.onSettingsChanged(index, instance, title);
        } else if (e.type == CustomizeBarEditEventType.Canceled) {
          this.onSettingsChanged(index, settings, this.element.name);
        } else if (e.type == CustomizeBarEditEventType.Deleted) {
          const newElement = cloneDeep(this.element);
          newElement.layouts = newElement.layouts.filter((item, i) => i != index);
          this.updated.emit(newElement);
        }
      });
  }

  onParamsChanged(params: Object) {
    this.params = params;
    this.cd.markForCheck();
  }

  onSettingsChanged(index: number, settings: ListLayoutSettings, name: string) {
    const element = cloneDeep(this.element) as ListElementItem;

    element.name = name;
    element.layouts = element.layouts.map((item, i) => {
      if (i == index) {
        return settings;
      } else {
        return item;
      }
    });

    this.viewContextElement.initInfo({ name: name }, true);

    this.updated.emit(element);
  }

  addLayout() {
    const element = cloneDeep(this.element) as ListElementItem;
    const listLayout = listLayouts.find(item => !element.layouts.find(i => i.type == item.type)) || listLayouts[0];
    const cls: Type<ListLayoutSettings> = getListLayoutSettingsClass(listLayout.type);
    const instance = new cls();

    instance.titleInput = new FieldInput().deserializeFromStatic('value', listLayout.label);

    element.layouts = [...element.layouts, instance];

    this.updated.emit(element);

    const index = element.layouts.length - 1;

    this.layoutService.currentLayoutIndex = index;
    this.openSettings(index);
  }

  customizeLayout(index: number) {
    this.openSettings(index);
  }
}

registerElementComponent({
  type: ElementType.List,
  component: ListElementComponent,
  alwaysActive: false,
  label: 'List',
  actions: []
});

<div class="popover2 overlay-content overlay-content_animated">
  <div class="popover2__inner">
    <div class="popover2__viewport popover2__viewport_scrollable">
      <div class="popover2__content" style="min-width: 300px;">
        <app-field-type-menu
          [sections]="overrideSections"
          [currentValue]="currentValue"
          (select)="select.emit($event)"
        ></app-field-type-menu>
      </div>
    </div>
    <div class="popover2__arrow"></div>
  </div>
</div>

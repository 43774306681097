<app-bar-code
  *ngIf="(visible || (customizeEnabled$ | async)) && (value | appIsSet)"
  [format]="element.format"
  [value]="value"
  [fillColor]="element.fillColor"
  [backgroundColor]="element.backgroundColor"
  [displayText]="element.displayText"
  [style.width.%]="100"
  [style.height.%]="100"
></app-bar-code>

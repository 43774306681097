var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { fromLegacyModel } from '@modules/models';
import { MenuItem } from './menu-item';
import { MenuItemType } from './menu-item-type';
import { registerMenuItemForType } from './menu-items';
var ModelLinkMenuItem = /** @class */ (function (_super) {
    __extends(ModelLinkMenuItem, _super);
    function ModelLinkMenuItem() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.type = MenuItemType.ModelLink;
        return _this;
    }
    ModelLinkMenuItem.prototype.deserialize = function (data) {
        _super.prototype.deserialize.call(this, data);
        this.title = this.params['title'];
        this.icon = this.params['icon'];
        this.model = fromLegacyModel(this.params['model']);
        if (this.model && !this.model.includes('.')) {
            this.model = ['jet_bridge', this.model].join('.');
        }
        return this;
    };
    ModelLinkMenuItem.prototype.serialize = function () {
        var data = _super.prototype.serialize.call(this);
        data['params']['title'] = this.title;
        data['params']['icon'] = this.icon;
        data['params']['model'] = this.model;
        return data;
    };
    ModelLinkMenuItem.prototype.copyFrom = function (item) {
        _super.prototype.copyFrom.call(this, item);
        this.title = item.title;
        this.icon = item.icon;
        this.model = item.model;
    };
    ModelLinkMenuItem.prototype.clone = function () {
        var instance = new ModelLinkMenuItem();
        instance.copyFrom(this);
        return instance;
    };
    return ModelLinkMenuItem;
}(MenuItem));
export { ModelLinkMenuItem };
registerMenuItemForType(MenuItemType.ModelLink, ModelLinkMenuItem);

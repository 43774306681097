import cloneDeep from 'lodash/cloneDeep';

import { Input } from '@modules/fields';

export enum MenuItemActionType {
  Page = 'page',
  URL = 'url',
  System = 'system',
  SetProperty = 'set_property'
}

export enum MenuItemSystemActionType {
  Home = 'home',
  Profile = 'profile',
  Users = 'users',
  ActivityLog = 'activity_log',
  ActivityLogPage = 'activity_log_page',
  Collaboration = 'collaboration',
  CollaborationTasksPage = 'collaboration_tasks_page',
  CollaborationMessagesPage = 'collaboration_messages_page',
  ToggleTheme = 'toggle_theme',
  Logout = 'logout'
}

export const menuItemSystemActionTypes = [
  MenuItemSystemActionType.Home,
  MenuItemSystemActionType.Profile,
  MenuItemSystemActionType.Users,
  MenuItemSystemActionType.ActivityLog,
  MenuItemSystemActionType.ActivityLogPage,
  MenuItemSystemActionType.Collaboration,
  MenuItemSystemActionType.CollaborationTasksPage,
  MenuItemSystemActionType.CollaborationMessagesPage,
  MenuItemSystemActionType.ToggleTheme,
  MenuItemSystemActionType.Logout
];

export interface MenuItemSystemActionTypeInfo {
  label: string;
  actionLabel: string;
  icon: string;
}

export function getMenuItemSystemActionTypeInfo(type: MenuItemSystemActionType): MenuItemSystemActionTypeInfo {
  if (type == MenuItemSystemActionType.Home) {
    return {
      label: 'Home',
      actionLabel: 'Open Home',
      icon: 'home'
    };
  } else if (type == MenuItemSystemActionType.Profile) {
    return {
      label: 'User profile',
      actionLabel: 'Open User profile',
      icon: 'human_being'
    };
  } else if (type == MenuItemSystemActionType.Users) {
    return {
      label: 'Users & Teams',
      actionLabel: 'Open Users & Teams',
      icon: 'users_teams'
    };
  } else if (type == MenuItemSystemActionType.ActivityLog) {
    return {
      label: 'Activity Log',
      actionLabel: 'Open Activity Log slideout',
      icon: 'book'
    };
  } else if (type == MenuItemSystemActionType.ActivityLogPage) {
    return {
      label: 'Activity Log',
      actionLabel: 'Open Activity Log page',
      icon: 'glasses'
    };
  } else if (type == MenuItemSystemActionType.Collaboration) {
    return {
      label: 'Collaboration',
      actionLabel: 'Open Collaboration slideout',
      icon: 'chat'
    };
  } else if (type == MenuItemSystemActionType.CollaborationTasksPage) {
    return {
      label: 'Tasks',
      actionLabel: 'Open Collaboration Tasks',
      icon: 'check_3'
    };
  } else if (type == MenuItemSystemActionType.CollaborationMessagesPage) {
    return {
      label: 'Messages',
      actionLabel: 'Open Collaboration Messages',
      icon: 'posts'
    };
  } else if (type == MenuItemSystemActionType.ToggleTheme) {
    return {
      label: 'Light/dark mode',
      actionLabel: 'Toggle light/dark mode',
      icon: 'toggle_theme'
    };
  } else if (type == MenuItemSystemActionType.Logout) {
    return {
      label: 'Logout',
      actionLabel: 'Logout',
      icon: 'log_out'
    };
  }
}

export class MenuItemAction {
  type: MenuItemActionType;
  pageUid: string;
  pageUniqueName: string; // Deprecated
  url: string;
  systemType: MenuItemSystemActionType;
  inputs: Input[] = [];
  setPropertyUid: string;
  setPropertyValue: Input;

  deserialize(data: Object): MenuItemAction {
    this.type = data['type'];
    this.pageUid = data['page_uid'];
    this.pageUniqueName = data['page_unique_name'];
    this.url = data['url'];
    this.systemType = data['system_type'];
    this.setPropertyUid = data['set_property_uid'];
    this.setPropertyValue = data['set_property_value'];

    if (data['inputs']) {
      this.inputs = data['inputs'].map(item => new Input().deserialize(item));
    }

    if (data['set_property_value']) {
      this.setPropertyValue = new Input().deserialize(data['set_property_value']);
    }

    return this;
  }

  serialize(): Object {
    return {
      type: this.type,
      page_uid: this.pageUid,
      page_unique_name: this.pageUniqueName,
      url: this.url,
      system_type: this.systemType,
      inputs: this.inputs.map(item => item.serialize()),
      set_property_uid: this.setPropertyUid,
      set_property_value: this.setPropertyValue ? this.setPropertyValue.serialize() : undefined
    };
  }

  copyFrom(item: MenuItemAction) {
    this.type = item.type;
    this.pageUid = item.pageUid;
    this.pageUniqueName = item.pageUniqueName;
    this.url = item.url;
    this.systemType = item.systemType;
    this.inputs = cloneDeep(item.inputs);
    this.setPropertyUid = item.setPropertyUid;
    this.setPropertyValue = item.setPropertyValue;
  }

  getSystemTypeInfo(): MenuItemSystemActionTypeInfo {
    return getMenuItemSystemActionTypeInfo(this.systemType);
  }
}

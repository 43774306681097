<td class="share-table__column">
  <div class="table-user table-user_photo">
    <span
      class="table-user__photo user-photo"
      [style.background-image]="item.user?.photo ? 'url(' + item.user.photo + ')' : ''"
    ></span>
    <div class="table-user__user">
      <ng-container *ngIf="item.user">{{ item.user.strFull }}</ng-container>
      <ng-container *ngIf="!item.user">{{ item.userEmail | appEmailName }} ({{ 'invited' | localize }})</ng-container>

      ({{ item.group.name }})
    </div>
    <div class="table-user__email">
      <ng-container *ngIf="item.user">{{ item.user.email }}</ng-container>
      <ng-container *ngIf="!item.user">{{ item.userEmail }}</ng-container>
    </div>
  </div>
</td>
<td class="share-table__column">
  <div *ngIf="inviteLink" style="position: relative; max-width: 360px;">
    <input
      class="input input_disabled input_fill share-form__input"
      [value]="inviteLink"
      (click)="$event.target.select()"
      style="padding-right: 85px;"
      readonly
    />

    <a
      href="javascript:void(0)"
      class="button button_small"
      style="position: absolute; right: 5px; top: 5px; margin: 0;"
      (click)="copyInviteCode(inviteLink)"
    >
      <span class="button__icon button__icon_left icon-duplicate"></span>
      <span class="button__label">{{ 'Copy' | localize }}</span>
    </a>
  </div>
</td>
<td class="share-table__column share-table__column_actions">
  <a
    href="javascript:void(0)"
    class="share-table__delete"
    [class.share-table__delete_disabled]="trigger.menuOpen"
    [matMenuTriggerFor]="dropdown"
    #trigger="matMenuTrigger"
  >
    <span class="icon-more"></span>
  </a>

  <mat-menu #dropdown="matMenu">
    <ng-template matMenuContent>
      <button
        mat-menu-item
        class="mat-menu-item-odd mat-menu-item-border"
        [disableRipple]="true"
        (click)="resendInvite(item)"
      >
        <span class="choose-items-item__dropdown-icon icon-email"></span>
        Resend Invite
      </button>

      <button
        mat-menu-item
        class="mat-menu-item-odd mat-menu-item-red mat-menu-item-border"
        [disableRipple]="true"
        (click)="delete(item)"
      >
        <span class="choose-items-item__dropdown-icon choose-items-item__dropdown-icon_red icon-bin"></span>
        {{ 'Cancel invite' | localize }}
      </button>
    </ng-template>
  </mat-menu>
</td>

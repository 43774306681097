import { ChangeDetectionStrategy, Component } from '@angular/core';

import { DialogPopupComponent } from '../dialog-popup/dialog-popup.component';

@Component({
  selector: 'app-opaque-dialog-popup',
  templateUrl: './opaque-dialog-popup.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OpaqueDialogPopupComponent extends DialogPopupComponent {
  opaque = true;
}

import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-view-editor-menu-button',
  templateUrl: './view-editor-menu-button.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ViewEditorMenuButtonComponent implements OnInit {
  @Input() componentLabel = 'component';
  @Input() submitLabel = 'Save';
  @Input() stateSelectedEnabled = false;
  @Input() templatesEnabled = true;
  @Input() analyticsSource: string;
  @Output() saveEditor = new EventEmitter<void>();
  @Output() closeEditor = new EventEmitter<void>();
  @Output() rename = new EventEmitter<void>();
  @Output() importFigmaNode = new EventEmitter<void>();
  @Output() importSketchFile = new EventEmitter<void>();

  constructor() {}

  ngOnInit() {}
}

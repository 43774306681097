import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { ParameterControl } from '@modules/fields';

@Component({
  selector: 'app-action-outputs-edit-item-parameter',
  templateUrl: './action-outputs-edit-item-parameter.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActionOutputsEditItemParameterComponent implements OnInit {
  @Input() control: ParameterControl;
  @Output() delete = new EventEmitter<void>();

  hovered$ = new BehaviorSubject<boolean>(false);
  fieldIcon$: Observable<string>;

  constructor() {}

  ngOnInit() {
    this.fieldIcon$ = this.control.fieldIcon$();
  }
}

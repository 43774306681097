import { ChangeDetectorRef, ElementRef, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { DropListService } from '@common/drag-drop2';
import { ElementType, isElementTypeGroupable, isElementTypeWrappable } from '@modules/customize';
import { DRAG_DROP_SIBLING_ACTIVE_CLASS } from '@modules/customize-elements';
import { addClass, removeClass } from '@shared';
var ElementPlaceholderStubComponent = /** @class */ (function () {
    function ElementPlaceholderStubComponent(dropListService, el, cd) {
        this.dropListService = dropListService;
        this.el = el;
        this.cd = cd;
        this.wrappable = false;
        this.groupable = false;
        this.hover$ = new BehaviorSubject(false);
        this.draggingOver$ = combineLatest(this.hover$, this.dropListService.dragging$).pipe(map(function (_a) {
            var hover = _a[0], dragging = _a[1];
            return hover && dragging;
        }));
        this.siblingLeftEntered$ = new BehaviorSubject(false);
        this.siblingRightEntered$ = new BehaviorSubject(false);
    }
    ElementPlaceholderStubComponent.prototype.ngOnInit = function () {
        var _this = this;
        combineLatest(this.siblingLeftEntered$, this.siblingRightEntered$)
            .pipe(untilDestroyed(this))
            .subscribe(function (_a) {
            var siblingLeftEntered = _a[0], siblingRightEntered = _a[1];
            var dropList = _this.dropListService.draggingDropList$.value;
            if (dropList) {
                dropList.mergeDraggingStateData({
                    siblingLeftEntered: siblingLeftEntered,
                    siblingRightEntered: siblingRightEntered,
                    siblingSelf: true
                });
            }
            if (siblingLeftEntered || siblingRightEntered) {
                addClass(document.body, DRAG_DROP_SIBLING_ACTIVE_CLASS);
            }
            else {
                removeClass(document.body, DRAG_DROP_SIBLING_ACTIVE_CLASS);
            }
        });
    };
    ElementPlaceholderStubComponent.prototype.ngOnDestroy = function () {
        if (this.siblingLeftEntered$.value || this.siblingRightEntered$.value) {
            removeClass(document.body, DRAG_DROP_SIBLING_ACTIVE_CLASS);
        }
    };
    ElementPlaceholderStubComponent.prototype.ngOnChanges = function (changes) {
        if (changes['elementType']) {
            this.wrappable = isElementTypeWrappable(this.elementType);
            this.groupable = isElementTypeGroupable(this.elementType);
        }
    };
    ElementPlaceholderStubComponent.prototype.isCreateColumnsAllowed = function () {
        return true;
        // return this.el.nativeElement.getBoundingClientRect().width >= 200;
    };
    return ElementPlaceholderStubComponent;
}());
export { ElementPlaceholderStubComponent };

import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl } from '@angular/forms';

import { ViewContext, ViewContextElement } from '@modules/customize';
import { InputsEditComponent } from '@modules/parameters-components';

import { DataSourceControl } from '../model-description-data-source-edit/data-source.control';

@Component({
  selector: 'app-data-source-inputs-edit',
  templateUrl: './data-source-inputs-edit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DataSourceInputsEditComponent implements OnInit {
  @Input() control: DataSourceControl;
  @Input() context: ViewContext;
  @Input() fieldsControl: AbstractControl;
  @Input() editable = false;
  @Input() contextElement: ViewContextElement;
  @Input() contextElementPath: (string | number)[];
  @Input() contextElementPaths: (string | number)[][];
  @Input() userInput = false;
  @Input() classes: string | string[];
  @Input() itemClasses: string | string[];
  @Input() addClasses: string | string[];
  @Input() analyticsSource: string;
  @Output() addInput = new EventEmitter<void>();

  @ViewChild(InputsEditComponent) inputsEditComponent: InputsEditComponent;

  constructor() {}

  ngOnInit() {}

  openAddInput() {
    if (this.inputsEditComponent) {
      this.inputsEditComponent.openAddInput();
    }
  }
}

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Injector } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import cloneDeep from 'lodash/cloneDeep';
import { ForkCustomizeWorkflowStepItemArray } from './fork-customize-workflow-step-item.array';
function createItemsControl(injector) {
    return Injector.create({
        providers: [{ provide: ForkCustomizeWorkflowStepItemArray, deps: [Injector] }],
        parent: injector
    }).get(ForkCustomizeWorkflowStepItemArray);
}
var ForkCustomizeWorkflowStepForm = /** @class */ (function (_super) {
    __extends(ForkCustomizeWorkflowStepForm, _super);
    function ForkCustomizeWorkflowStepForm(injector) {
        var _this = _super.call(this, {
            name: new FormControl(''),
            items: createItemsControl(injector)
        }) || this;
        _this.injector = injector;
        return _this;
    }
    ForkCustomizeWorkflowStepForm.prototype.init = function (instance, options) {
        if (options === void 0) { options = {}; }
        this.instance = instance;
        if (instance) {
            this.controls.name.patchValue(instance.name);
            this.controls.items.init(instance.items);
        }
        if (!options.firstInit) {
            this.markAsDirty();
        }
    };
    ForkCustomizeWorkflowStepForm.prototype.submit = function () {
        var instance = cloneDeep(this.instance);
        instance.name = this.controls.name.value;
        instance.items = this.controls.items.submit();
        return instance;
    };
    return ForkCustomizeWorkflowStepForm;
}(FormGroup));
export { ForkCustomizeWorkflowStepForm };

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Subscription } from 'rxjs';
// import Editor from '@toast-ui/editor';

import { EmbedScripts, ScriptsService } from '@core';
import { ApiService } from '@modules/api';
import { FieldType, registerFieldComponent, RichTextOutput } from '@modules/fields';
import { ProjectApiService } from '@modules/project-api';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { ProjectStorageService } from '@modules/resources';
import { controlValue, isSet } from '@shared';

import { FieldComponent } from '../field/field.component';

@Component({
  selector: 'app-rich-text-field',
  templateUrl: 'rich-text-field.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RichTextFieldComponent extends FieldComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {
  @ViewChild('input') input: ElementRef;
  @ViewChild('editorjs') elEditor: ElementRef;

  editor: any;
  // markdownEditor: Editor;
  actualValueSubscription: Subscription;

  constructor(
    private domSanitizer: DomSanitizer,
    private apiService: ApiService,
    private projectApiService: ProjectApiService,
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private projectStorageService: ProjectStorageService,
    private scriptsService: ScriptsService
  ) {
    super();
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    if (this.editor) {
      this.editor.destroy();
      this.editor = undefined;
    }
  }

  ngAfterViewInit(): void {
    if (!this.readonly) {
      setTimeout(() => this.initWysiwyg());
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['field'] && !changes['field'].firstChange) {
      this.initWysiwyg();
    }
  }

  get sanitizeValue() {
    if (!this.currentValue || typeof this.currentValue !== 'string') {
      return this.currentValue;
    }

    if (this.field.params['format_readonly']) {
      return this.domSanitizer.bypassSecurityTrustHtml(this.currentValue);
    } else {
      return this.currentValue.replace(/<\/?[^>]+(>|$)/g, '');
    }
  }

  getPath() {
    if (this.context && this.context.modelDescription) {
      return [this.context.modelDescription.model, this.field.name].join('/');
      // } else if (this.context['action']) {
      //   return ['actions', this.context['action'], this.field.name].join('/');
    }
  }

  isMarkdown() {
    return this.field.params && this.field.params['output'] == RichTextOutput.Markdown;
  }

  initWysiwyg() {
    if (this.actualValueSubscription) {
      this.actualValueSubscription.unsubscribe();
      this.actualValueSubscription = undefined;
    }

    if (this.isMarkdown()) {
      // if (this.markdownEditor) {
      //   return;
      // }

      if (this.editor) {
        this.editor.destroy();
        this.editor = undefined;
      }

      this.initMarkdown();
    } else {
      if (this.editor) {
        return;
      }

      // if (this.markdownEditor) {
      //   this.markdownEditor.remove();
      //   this.markdownEditor = undefined;
      // }

      this.initHTMLWysiwyg();
    }
  }

  initMarkdown() {
    // if (!this.elEditor) {
    //   return;
    // }
    //
    // const editor = new Editor({
    //   el: this.elEditor.nativeElement,
    //   height: '500px',
    //   initialEditType: 'wysiwyg',
    //   previewStyle: 'tab',
    //   initialValue: this.currentValue,
    //   usageStatistics: false,
    //   events: {
    //     change: () => {
    //       this.setCurrentValue(editor.getMarkdown());
    //     }
    //   }
    // });
    //
    // this.markdownEditor = editor;
  }

  initHTMLWysiwyg() {
    if (!this.input) {
      return;
    }

    this.scriptsService
      .loadSingleton(EmbedScripts.CKEditor)
      .pipe(untilDestroyed(this))
      .subscribe(() => this.initCKEditor());
  }

  initCKEditor() {
    const uploadUrl = this.projectStorageService.getUploadUrl(this.currentProjectStore.uniqueName);
    const CKEDITOR = window['CKEDITOR'];

    CKEDITOR.disableAutoInline = true;

    const editor = CKEDITOR.replace(this.input.nativeElement, {
      toolbar: [
        {
          name: 'clipboard',
          items: [
            'Format',
            'Indent',
            'Outdent',
            '-',
            'Cut',
            'Copy',
            'Paste',
            'PasteText',
            'PasteFromWord',
            '-',
            'Undo',
            'Redo'
          ]
        },
        {
          name: 'document',
          items: ['Source', 'Preview']
        },
        '/',
        {
          name: 'basicstyles',
          items: [
            'Bold',
            'Italic',
            'Underline',
            'Strike',
            'Subscript',
            'Superscript',
            '-',
            'TextColor',
            'BGColor',
            '-',
            'RemoveFormat'
          ]
        },
        {
          name: 'align',
          items: ['JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock']
        },
        {
          name: 'specials',
          items: ['NumberedList', 'BulletedList', '-', 'Link', 'Unlink', 'Anchor', 'Image', 'Table', 'SpecialChar']
        }
      ],
      height: isSet(this.field.params['rows']) ? `${this.field.params['rows']}em` : 'auto',
      removePlugins: 'elementspath,resize',
      editorplaceholder: this.field.placeholder,
      // fullPage: true,
      allowedContent: true,
      filebrowserUploadUrl: uploadUrl,
      versionCheck: false
    });

    this.actualValueSubscription = controlValue(this.control)
      .pipe(untilDestroyed(this))
      .subscribe(actualValue => {
        const editorValue = editor.getData();

        if (editorValue != actualValue) {
          editor.setData(actualValue);
        }
      });

    editor.on('change', e => {
      this.input.nativeElement.value = editor.getData();

      const event = document.createEvent('Event');

      event.initEvent('input', true, true);
      this.input.nativeElement.dispatchEvent(event);
    });

    editor.on('fileUploadRequest', evt => {
      const xhr = evt.data.fileLoader.xhr;
      const file = evt.data.fileLoader.file;
      const fileName = evt.data.fileLoader.fileName;
      const path = this.getPath();
      const filePath = ['rich_text', ...(isSet(path) ? [path] : [])].join('/');
      const form = this.projectStorageService.getUploadData(file, filePath, fileName);

      this.projectApiService
        .refreshToken()
        .pipe(untilDestroyed(this))
        .subscribe(() => {
          const headers = this.projectStorageService.getUploadHeaders();

          headers.keys().forEach(key => {
            xhr.setRequestHeader(key, headers.get(key));
          });

          xhr.send(form);
        });

      // Prevented the default behavior.
      evt.stop();
    });

    editor.on('fileUploadResponse', function (evt) {
      // Prevent the default response handler.
      evt.stop();

      // Get XHR and response.
      const data = evt.data,
        xhr = data.fileLoader.xhr,
        response = xhr.responseText;

      const result = JSON.parse(response);

      data.url = result['url'];

      // if (response[1]) {
      //   // An error occurred during upload.
      //   data.message = response[1];
      //   evt.cancel();
      // } else {
      //   data.url = response[0];
      // }
    });

    this.editor = editor;
  }
}

registerFieldComponent(FieldType.RichText, RichTextFieldComponent);

import { ConnectedPosition } from '@angular/cdk/overlay';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest } from 'rxjs';
import { filter } from 'rxjs/operators';

import { DialogService } from '@common/dialogs';
import { MenuContext } from '@modules/menu-components';
import { CurrentUserStore } from '@modules/users';
import { controlValue, isColorHex, isSet } from '@shared';

import { LayoutGroup, LayoutOption } from '../menu-block-layout-overlay/menu-block-layout-overlay.component';
import { MenuBlockControl } from '../project-settings/menu-block.control';

@Component({
  selector: 'app-menu-block-edit',
  templateUrl: './menu-block-edit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MenuBlockEditComponent implements OnInit, OnDestroy {
  @Input() title = 'Menu';
  @Input() control: MenuBlockControl;
  @Input() layoutGroups: LayoutGroup[] = [];
  @Input() active = false;
  @Input() disabled = false;
  @Output() edit = new EventEmitter<void>();
  @Output() remove = new EventEmitter<void>();

  enabledInputToggled = false;
  totalItems = 0;
  colorSet = false;
  displayColor: string;
  displayColorClass: string;
  colors = ['blue', 'teal', 'red', 'maroon', 'black', 'purple'];
  colorOpened = false;
  colorPositions: ConnectedPosition[] = [
    { originX: 'start', overlayX: 'start', originY: 'bottom', overlayY: 'top', offsetX: -8, offsetY: -8 },
    { originX: 'center', overlayX: 'center', originY: 'bottom', overlayY: 'top', offsetX: 0, offsetY: -8 },
    { originX: 'end', overlayX: 'end', originY: 'bottom', overlayY: 'top', offsetX: 8, offsetY: -8 },
    { originX: 'start', overlayX: 'start', originY: 'top', overlayY: 'bottom', offsetX: -8, offsetY: 8 },
    { originX: 'center', overlayX: 'center', originY: 'top', overlayY: 'bottom', offsetX: 0, offsetY: 8 },
    { originX: 'end', overlayX: 'end', originY: 'top', overlayY: 'bottom', offsetX: 8, offsetY: 8 },
    { originX: 'end', overlayX: 'start', originY: 'center', overlayY: 'center', offsetX: 0, offsetY: 0 },
    { originX: 'start', overlayX: 'end', originY: 'center', overlayY: 'center', offsetX: 0, offsetY: 0 }
  ];
  itemsPluralMap = { '=1': '# item', '=0': 'no items', other: '# items' };
  layoutOpened = false;
  layoutSelected: LayoutOption;

  constructor(
    public currentUserStore: CurrentUserStore,
    public context: MenuContext,
    private dialogService: DialogService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    combineLatest(controlValue(this.control.controls.layout), this.control.getColor$())
      .pipe(untilDestroyed(this))
      .subscribe(([layout, color]) => {
        this.layoutSelected = this.layoutGroups
          .reduce<LayoutOption[]>((acc, item) => {
            acc.push(...item.options);
            return acc;
          }, [])
          .find(item => item.layout == layout && (item.color === undefined || isSet(item.color) == isSet(color)));
        this.cd.markForCheck();
      });

    this.control
      .getTotalItems$()
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.totalItems = this.control.getTotalItems();
        this.cd.markForCheck();
      });

    combineLatest(
      controlValue(this.control.controls.layout),
      controlValue(this.control.controls.color_custom_enabled),
      controlValue(this.control.controls.color_custom),
      controlValue(this.control.controls.color)
    )
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.colorSet = this.isColorSet();
        this.displayColor = this.getDisplayColor();
        this.displayColorClass = this.getDisplayColorClass();
        this.cd.markForCheck();
      });
  }

  ngOnDestroy(): void {}

  selectLayoutOption(option: LayoutOption) {
    const newMenu = !this.control.controls.layout.value;

    this.control.setLayout(option.layout);

    if (option.color !== undefined) {
      if (!isSet(this.control.getColor()) && isSet(option.color)) {
        if (isColorHex(option.color)) {
          this.control.controls.color_custom_enabled.patchValue(true);
          this.control.controls.color_custom.patchValue(option.color);
        } else {
          this.control.controls.color.patchValue(option.color);
          this.control.controls.color_custom_enabled.patchValue(false);
        }
      } else if (isSet(this.control.getColor()) && !isSet(option.color)) {
        this.control.controls.color.patchValue('');
        this.control.controls.color_custom_enabled.patchValue(false);
      }
    }

    if (newMenu) {
      this.control.controls.enabled.patchValue(true);
      this.control.applyDefaultState();
    }
  }

  isColorSet(): boolean {
    if (this.control.controls.color_custom_enabled.value) {
      return isSet(this.control.controls.color_custom.value);
    } else {
      return isSet(this.control.controls.color.value);
    }
  }

  getDisplayColor(): string {
    if (!this.isColorSet()) {
      return;
    }

    if (this.control.controls.color_custom_enabled.value) {
      return this.control.controls.color_custom.value;
    }

    return null;
  }

  getDisplayColorClass(): string {
    if (!this.control.controls.color_custom_enabled.value) {
      return `background-color_${this.control.controls.color.value}_1`;
    }

    return null;
  }

  setColor(value: string) {
    if (isColorHex(value)) {
      this.control.controls.color_custom_enabled.patchValue(true);
      this.control.controls.color_custom.patchValue(value);
    } else {
      this.control.controls.color_custom_enabled.patchValue(false);
      this.control.controls.color.patchValue(value);
    }
  }

  setColorOpened(value: boolean) {
    this.colorOpened = value;
    this.cd.markForCheck();
  }

  setLayoutOpened(value: boolean) {
    this.layoutOpened = value;
    this.cd.markForCheck();
  }

  enable() {
    if (this.control.controls.layout.value) {
      this.control.controls.enabled.patchValue(true);
    } else {
      this.setLayoutOpened(true);
    }
  }

  disable() {
    this.control.controls.enabled.patchValue(false);
  }

  toggleEnabledInput() {
    if (this.control.controls.enabled_input_enabled.value) {
      this.control.controls.enabled_input_enabled.patchValue(false);
      this.enabledInputToggled = false;
    } else {
      this.control.controls.enabled_input_enabled.patchValue(true);
      this.enabledInputToggled = true;
    }
  }

  toggleEnabled() {
    if (this.control.controls.enabled.value) {
      this.disable();
    } else {
      this.enable();
    }
  }

  onClick(event: MouseEvent) {
    if (this.control.controls.enabled.value) {
      return;
    }

    this.enable();
    event.stopPropagation();
  }

  confirmRemove() {
    this.dialogService
      .warning({
        title: 'Deleting',
        description: `Are you sure want to delete ${this.title} menu?`
      })
      .pipe(
        filter(result => result == true),
        untilDestroyed(this)
      )
      .subscribe(() => this.remove.emit());
  }
}

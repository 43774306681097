import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Observable } from 'rxjs';

import { getImageSize, getVideoSize } from '@shared';

@Component({
  selector: 'app-lightbox',
  templateUrl: './lightbox.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LightboxComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() url: string;
  @Input() video = false;
  @Input() zoom = true;

  @ViewChild('preview') preview: ElementRef;

  img: HTMLImageElement;
  widthImg = 0;
  heightImg = 0;
  canZoom = false;
  opacityZoom = 0;
  percent = 1;
  offsetX = 0;
  offsetY = 0;

  constructor(protected cd: ChangeDetectorRef) {}

  ngOnInit() {}

  ngOnDestroy(): void {}

  ngAfterViewInit(): void {
    this.getMediaSize()
      .pipe(untilDestroyed(this))
      .subscribe(size => {
        this.widthImg = size.width;
        this.heightImg = size.height;

        this.canZoom =
          this.preview.nativeElement.offsetHeight < this.heightImg ||
          this.preview.nativeElement.offsetWidth < this.widthImg;
        this.cd.detectChanges();

        if (this.canZoom) {
          this.percent = (this.widthImg - this.widthPreview) / this.widthPreview;
        }
      });
  }

  getMediaSize(): Observable<{ width: number; height: number }> {
    return this.video ? getVideoSize(this.url) : getImageSize(this.url);
  }

  get maxHeight() {
    return document.body.offsetHeight - 100;
  }

  get heightPreview() {
    return this.preview.nativeElement.offsetHeight;
  }

  get widthPreview() {
    return this.preview.nativeElement.offsetWidth;
  }

  mousemove(event) {
    const shiftX = this.percent * (event.offsetX - this.widthPreview / 2);
    const shiftY = this.percent * (event.offsetY - this.heightPreview / 2);
    this.offsetX = -((this.widthImg - this.widthPreview) / 2 + shiftX);
    this.offsetY = -((this.heightImg - this.heightPreview) / 2 + shiftY);
    this.cd.markForCheck();
  }

  showZoom() {
    if (!this.zoom) {
      return;
    }

    this.opacityZoom = 1;
    this.cd.markForCheck();
  }

  hideZoom() {
    this.opacityZoom = 0;
    this.cd.markForCheck();
  }
}

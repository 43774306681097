import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';

import { createFormFieldFactory, FieldType, registerFieldDataParamsComponent } from '@modules/fields';

import { FieldParamsComponent } from '../../field-params/field-params.component';
import { ImageFieldDataParamsForm } from './image-field-data-params.form';

@Component({
  selector: 'app-image-field-data-params',
  templateUrl: './image-field-data-params.component.html',
  providers: [ImageFieldDataParamsForm],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImageFieldDataParamsComponent extends FieldParamsComponent implements OnInit, OnDestroy {
  createField = createFormFieldFactory();

  constructor(public form: ImageFieldDataParamsForm) {
    super();
  }

  ngOnInit() {
    this.form.init(this.control);
  }

  ngOnDestroy(): void {}
}

registerFieldDataParamsComponent(FieldType.Image, ImageFieldDataParamsComponent);

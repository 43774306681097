import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import saveAs from 'file-saver';
import snakeCase from 'lodash/snakeCase';
import * as moment from 'moment';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { NotificationService } from '@common/notifications';
import { SessionStorage } from '@core';
import { getElementByType, ViewSettings, ViewSettingsStore } from '@modules/customize';
import { createFormFieldFactory } from '@modules/fields';
import { MenuSection, MenuService } from '@modules/menu';
import { CurrentProjectStore, resourceTypeItems } from '@modules/projects';
import { RoutingService } from '@modules/routing';
import { Template, TemplateService, TemplateType } from '@modules/template';
import { isSet, readFileText } from '@shared';

import { TemplatesItemForm } from './templates-item.form';

@Component({
  selector: 'app-templates-item',
  templateUrl: './templates-item.component.html',
  providers: [TemplatesItemForm],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TemplatesItemComponent implements OnInit, OnDestroy {
  createField = createFormFieldFactory();
  template: Template;
  templateTypes = TemplateType;
  viewSettings: ViewSettings[];
  resourceTypeItems = resourceTypeItems;

  constructor(
    public form: TemplatesItemForm,
    public currentProjectStore: CurrentProjectStore,
    private activatedRoute: ActivatedRoute,
    private sessionStorage: SessionStorage,
    private templateService: TemplateService,
    private viewSettingsStore: ViewSettingsStore,
    private menuService: MenuService,
    private routing: RoutingService,
    private notificationService: NotificationService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.menuService.section = MenuSection.None;

    this.activatedRoute.params
      .pipe(
        switchMap(params => {
          const id = params['id'];

          if (isSet(id)) {
            return this.templateService.getDetail(id);
          } else {
            return of(undefined);
          }
        }),
        untilDestroyed(this)
      )
      .subscribe(template => {
        this.template = template;
        this.cd.markForCheck();

        if (template) {
          this.form.init(template);
        } else {
          try {
            const object = JSON.parse(this.sessionStorage.get('template_widget'));
            const Element = getElementByType(object['type']);
            const element = new Element().deserialize(object);

            this.form.initElement(element);
          } catch (e) {}
        }
      });

    this.updateViewSettings();
  }

  ngOnDestroy(): void {
    this.menuService.section = MenuSection.Default;
  }

  updateViewSettings() {
    this.viewSettingsStore
      .getFirst()
      .pipe(untilDestroyed(this))
      .subscribe(result => {
        this.viewSettings = result.filter(item => item.uniqueName);
        this.cd.markForCheck();
      });
  }

  submit() {
    this.form
      .submit()
      .pipe(untilDestroyed(this))
      .subscribe(
        result => {
          if (this.template) {
            this.notificationService.success('Saved', `Template was successfully updated`);
          } else {
            this.routing.navigateApp(result.link);

            this.notificationService.success('Created', `Template was successfully created`);
          }
        },
        e => {
          console.error(e);

          this.notificationService.error('Failed', `Template saving failed`);
        }
      );
  }

  dump() {
    const template = this.form.getInstance();
    const blob = new Blob([JSON.stringify(template.serialize())], { type: 'application/json; charset=utf-8' });
    const fileName = `${['template', snakeCase(template.name), moment().format('YYYY-MM-DD_HH:mm:ss')].join('_')}.json`;

    saveAs(blob, fileName);
  }

  onRestoreFileChanged(el: HTMLInputElement) {
    if (!el.files.length) {
      return;
    }

    const file = el.files[0];

    el.value = null;

    this.restore(file);
  }

  restore(file: File) {
    readFileText(file)
      .pipe(untilDestroyed(this))
      .subscribe(content => {
        try {
          const data = JSON.parse(content);
          const template = new Template().deserialize(data);
          this.form.patchTemplate(template);
        } catch (e) {}
      });
  }
}

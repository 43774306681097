import { Injectable, OnDestroy } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import range from 'lodash/range';
import values from 'lodash/values';
import * as moment from 'moment';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ActionStore } from '@modules/action-queries';
import { ActionType } from '@modules/actions';
import { CustomSelectItem, Option } from '@modules/field-components';
import { ParameterArray, ParameterField } from '@modules/fields';
import { ModelDescriptionStore } from '@modules/model-queries';
import { ModelDescription } from '@modules/models';
import { CurrentEnvironmentStore, Resource } from '@modules/projects';
import {
  AutomationTrigger,
  AutomationTriggerType,
  getAutomationTriggerByType,
  IntervalAutomationTrigger,
  ModelAutomationTrigger,
  TimetableAutomationTrigger,
  TimetableType,
  WebhookAutomationTrigger
} from '@modules/workflow';
import { ascComparator, controlValue, isSet } from '@shared';

export interface TriggerFormOptions {
  triggerEditable?: boolean;
  trigger?: AutomationTrigger;
  testParameters?: Object;
  parameters?: ParameterField[];
}

export interface WorkflowTriggerSaveEvent {
  trigger?: AutomationTrigger;
  testParameters?: Object;
  parameters?: ParameterField[];
}

@Injectable()
export class CustomizeWorkflowTriggerForm extends FormGroup implements OnDestroy {
  options: TriggerFormOptions = {};

  controls: {
    trigger_type: FormControl;
    trigger_interval: FormControl;
    trigger_timetable_type: FormControl;
    trigger_timetable_time: FormControl;
    trigger_timetable_days_of_week: FormControl;
    trigger_timetable_days: FormControl;
    trigger_timetable_months: FormControl;
    trigger_timetable_date: FormControl;
    trigger_model_resource: FormControl;
    trigger_model_action: FormControl;
    trigger_webhook_token: FormControl;
    parameters: ParameterArray;
    test_parameters: FormControl;
  };

  triggerTypeOptions: Option<AutomationTriggerType>[] = [
    { value: AutomationTriggerType.Interval, name: 'At regular intervals', icon: 'time' },
    { value: AutomationTriggerType.Timetable, name: 'Based on a schedule', icon: 'calendar' },
    { value: AutomationTriggerType.Webhook, name: 'When Webhook received', icon: 'antenna' },
    { value: AutomationTriggerType.Model, name: 'When data changes', icon: 'data' },
    { value: AutomationTriggerType.Manual, name: 'Manual run', icon: 'hand' }
  ];

  dayOfWeekOptions: Option<number>[] = range(7).map(i => {
    return {
      value: i + 1,
      name: moment().startOf('isoWeek').add(i, 'day').format('dddd')
    };
  });

  dayOptions: Option<number>[] = range(31).map(i => {
    return {
      value: i + 1,
      name: String(i + 1)
    };
  });

  monthOptions: Option<number>[] = range(12).map(i => {
    return {
      value: i + 1,
      name: moment().startOf('year').add(i, 'month').format('MMMM')
    };
  });

  timetableTypeOptions: CustomSelectItem<TimetableType>[] = [
    { option: { value: TimetableType.EveryDay, name: 'Every day' } },
    { option: { value: TimetableType.EveryWeek, name: 'Days of the week' } },
    { option: { value: TimetableType.EveryMonth, name: 'Days of the month' } },
    { option: { value: TimetableType.Once, name: 'Once' } },
    { option: { value: TimetableType.Custom, name: 'Specify dates' } }
  ];

  modelActionEquals = (() => {
    return (lhs: { model: string; action: string }, rhs: { model: string; action: string }) => {
      const lhsModel = lhs ? lhs.model : undefined;
      const lhsAction = lhs ? lhs.action : undefined;
      const rhsModel = rhs ? rhs.model : undefined;
      const rhsAction = rhs ? rhs.action : undefined;

      return lhsModel == rhsModel && lhsAction == rhsAction;
    };
  })();

  constructor(
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private actionStore: ActionStore,
    private modelDescriptionStore: ModelDescriptionStore
  ) {
    super({
      trigger_type: new FormControl(undefined),
      trigger_interval: new FormControl(15),
      trigger_timetable_type: new FormControl(undefined),
      trigger_timetable_time: new FormControl(undefined),
      trigger_timetable_days_of_week: new FormControl([]),
      trigger_timetable_days: new FormControl([]),
      trigger_timetable_months: new FormControl([]),
      trigger_timetable_date: new FormControl(undefined),
      trigger_model_resource: new FormControl(undefined),
      trigger_model_action: new FormControl(undefined),
      trigger_webhook_token: new FormControl(undefined),
      parameters: new ParameterArray([]),
      test_parameters: new FormControl({})
    });
  }

  ngOnDestroy(): void {}

  init(options: TriggerFormOptions = {}) {
    this.options = options;

    if (options.trigger) {
      this.controls.trigger_type.patchValue(options.trigger.type);

      if (options.trigger instanceof WebhookAutomationTrigger) {
        this.controls.trigger_webhook_token.patchValue(options.trigger.webhookToken);
      } else if (options.trigger instanceof IntervalAutomationTrigger) {
        this.controls.trigger_interval.patchValue(options.trigger.interval);
      } else if (options.trigger instanceof TimetableAutomationTrigger) {
        this.controls.trigger_timetable_type.patchValue(options.trigger.timetableType);
        this.controls.trigger_timetable_time.patchValue(
          options.trigger.timetableTime ? options.trigger.timetableTime.format('HH:mm') : ''
        );
        this.controls.trigger_timetable_days_of_week.patchValue(options.trigger.timetableDaysOfWeek || []);
        this.controls.trigger_timetable_days.patchValue(options.trigger.timetableDays || []);
        this.controls.trigger_timetable_months.patchValue(options.trigger.timetableMonths || []);
        this.controls.trigger_timetable_date.patchValue(
          options.trigger.timetableDate ? options.trigger.timetableDate.format('L HH:mm') : ''
        );
      } else if (options.trigger instanceof ModelAutomationTrigger) {
        const action =
          isSet(options.trigger.model) && isSet(options.trigger.action)
            ? {
                model: options.trigger.model,
                action: options.trigger.action
              }
            : undefined;

        this.controls.trigger_model_resource.patchValue(options.trigger.resource);
        this.controls.trigger_model_action.patchValue(action);
      }
    }

    if (options.parameters) {
      this.controls.parameters.patchValue(options.parameters);
    }

    if (options.testParameters) {
      this.controls.test_parameters.patchValue(options.testParameters);
    }

    this.controls.trigger_model_resource.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe(() => this.controls.trigger_model_action.patchValue(undefined));

    this.markAsPristine();
  }

  triggerModelResource$(): Observable<Resource> {
    return controlValue<string>(this.controls.trigger_model_resource).pipe(
      map(value => this.currentEnvironmentStore.resources.find(item => item.uniqueName == value))
    );
  }

  triggerModelActionItems$(): Observable<CustomSelectItem<string>[]> {
    return combineLatest(this.triggerModelResource$(), this.actionStore.get(), this.modelDescriptionStore.get()).pipe(
      map(([resource, actionDescriptions, modelDescriptions]) => {
        if (!resource) {
          return [];
        }

        const options = [];

        if (actionDescriptions) {
          const groupedOptions: {
            modelDescription?: ModelDescription;
            items: CustomSelectItem<string>[];
          }[] = values(
            actionDescriptions
              .filter(item => item.resource == resource.uniqueName)
              .filter(item => item.type == ActionType.Query)
              .filter(item => item.modelAction && !['get', 'get_detail'].includes(item.modelAction))
              .reduce((acc, actionDescription) => {
                const modelDescription = actionDescription.model
                  ? modelDescriptions.find(
                      item => item.resource == resource.uniqueName && item.model == actionDescription.model
                    )
                  : undefined;

                if (modelDescription && (!resource.demo || modelDescription.featured)) {
                  if (!acc[modelDescription.modelId]) {
                    acc[modelDescription.modelId] = {
                      modelDescription: modelDescription,
                      items: []
                    };
                  }

                  let label = actionDescription.verboseName;
                  let icon: string;

                  if (actionDescription.modelAction == 'create') {
                    label = 'Record created';
                    icon = 'plus_circle';
                  } else if (actionDescription.modelAction == 'update') {
                    label = 'Record updated';
                    icon = 'edit';
                  } else if (actionDescription.modelAction == 'delete') {
                    label = 'Record deleted';
                    icon = 'bin';
                  }

                  acc[modelDescription.modelId].items.push({
                    option: {
                      value: { model: actionDescription.model, action: actionDescription.modelAction },
                      name: label,
                      icon: icon
                    },
                    subtitle: 'Actions',
                    valueLabel: label,
                    valueIcon: icon
                  });
                }

                return acc;
              }, {})
          );

          options.push(
            ...groupedOptions
              .sort((lhs, rhs) => {
                const lhsName = lhs.modelDescription
                  ? String(lhs.modelDescription.verboseNamePlural).toLowerCase()
                  : '';
                const rhsName = rhs.modelDescription
                  ? String(rhs.modelDescription.verboseNamePlural).toLowerCase()
                  : '';

                return ascComparator(lhsName, rhsName);
              })
              .reduce((acc, item) => {
                if (item.modelDescription) {
                  acc.push({
                    button: {
                      name: item.modelDescription.model,
                      label: item.modelDescription.verboseNamePlural || item.modelDescription.model,
                      icon: 'components'
                    },
                    children: item.items,
                    subtitle: 'Collections'
                  });
                } else {
                  acc.push(
                    ...item.items.sort((lhs, rhs) => {
                      return ascComparator(
                        String(lhs.option.name).toLowerCase(),
                        String(rhs.option.name).toLowerCase()
                      );
                    })
                  );
                }

                return acc;
              }, [])
          );
        }

        return options;
      })
    );
  }

  getTriggerInstance() {
    let trigger: AutomationTrigger;

    const triggerTypeCls = getAutomationTriggerByType(this.controls.trigger_type.value);
    if (triggerTypeCls) {
      trigger = new triggerTypeCls();

      if (trigger instanceof WebhookAutomationTrigger) {
        trigger.webhookToken = this.controls.trigger_webhook_token.value;
      } else if (trigger instanceof IntervalAutomationTrigger) {
        trigger.interval = parseFloat(this.controls.trigger_interval.value || 15);
      } else if (trigger instanceof TimetableAutomationTrigger) {
        const timetableTime = this.controls.trigger_timetable_time.value
          ? moment(this.controls.trigger_timetable_time.value, ['HH:mm:ss', 'HH:mm'])
          : undefined;
        const timetableDate = this.controls.trigger_timetable_date.value
          ? moment(this.controls.trigger_timetable_date.value)
          : undefined;

        trigger.timetableType = this.controls.trigger_timetable_type.value;
        trigger.timetableTime = timetableTime && timetableTime.isValid() ? timetableTime : undefined;
        trigger.timetableDaysOfWeek = this.controls.trigger_timetable_days_of_week.value;
        trigger.timetableDays = this.controls.trigger_timetable_days.value;
        trigger.timetableMonths = this.controls.trigger_timetable_months.value;
        trigger.timetableDate = timetableDate && timetableDate.isValid() ? timetableDate : undefined;
      } else if (trigger instanceof ModelAutomationTrigger) {
        trigger.resource = this.controls.trigger_model_resource.value;

        if (isSet(this.controls.trigger_model_action.value)) {
          const { model, action } = this.controls.trigger_model_action.value;

          trigger.model = model;
          trigger.action = action;
        } else {
          trigger.action = undefined;
        }
      }
    }

    return trigger;
  }

  submit(): WorkflowTriggerSaveEvent {
    const trigger = this.options.triggerEditable ? this.getTriggerInstance() : undefined;

    return {
      trigger: trigger,
      testParameters: this.controls.test_parameters.value,
      parameters: this.controls.parameters.value
    };
  }
}

import { Directive, HostBinding, Input, OnChanges, SimpleChanges } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import * as Color from 'color';
import toPairs from 'lodash/toPairs';

import { TintStyle } from '@modules/actions';
import { getColorHex, parseColor } from '@modules/colors';
import { isSet } from '@shared';

@Directive({
  selector: '[appTintColor]'
})
export class TintColorDirective implements OnChanges {
  @Input() appTintColor: string;
  @Input() appTintColorStyle: TintStyle;

  @HostBinding('style') style: SafeStyle;

  constructor(private sanitizer: DomSanitizer) {}

  ngOnChanges(changes: SimpleChanges): void {
    const hex = getColorHex(this.appTintColor);
    const clr = isSet(hex) ? parseColor(hex) : undefined;

    if (!clr) {
      this.style = undefined;
      return;
    }

    const isDark = clr.contrast(Color('white')) >= 2;

    let vars: Object;

    if (this.appTintColorStyle == TintStyle.Primary) {
      vars = {
        'background-color': clr.string(),
        'background-hover-color': clr.darken(0.2).string(),
        'background-active-color': clr.darken(0.5).string(),
        'text-color': isDark ? '#fff' : clr.darken(0.6).string(),
        'text-hover-color': isDark ? '#fff' : clr.darken(0.6).string(),
        'text-active-color': isDark ? '#fff' : clr.darken(0.6).string()
      };
    } else if (this.appTintColorStyle == TintStyle.Transparent) {
      vars = {
        'background-hover-color': clr.darken(0.2).alpha(0.2).string(),
        'background-active-color': clr.darken(0.5).alpha(0.2).string(),
        'text-color': clr.darken(0.3).string(),
        'text-hover-color': clr.darken(0.3).string(),
        'text-active-color': clr.darken(0.3).string()
      };
    } else {
      vars = {
        'background-color': clr.alpha(0.1).string(),
        'background-hover-color': clr.darken(0.2).alpha(0.2).string(),
        'background-active-color': clr.darken(0.5).alpha(0.2).string(),
        'text-color': clr.darken(0.3).string(),
        'text-hover-color': clr.darken(0.3).string(),
        'text-active-color': clr.darken(0.3).string()
      };
    }

    const styles = toPairs(vars)
      .map(([k, v]) => `--${k}: ${v}`)
      .join(';');

    this.style = this.sanitizer.bypassSecurityTrustStyle(styles);
  }
}

<div appDropListGroup class="grid">
  <div class="grid__columns">
    <div class="grid__columns-item">
      <div class="grid__cell">
        <div
          appDropList
          [appDropListData]="list1"
          [appDropListConnectedTo]="[list2_cmp]"
          class="container"
          id="list1"
          (appDropListDropped)="dragDrop($event)"
        >
          <app-playground-item
            *ngFor="let item of list1"
            appDrag
            [appDragData]="item"
            [item]="item"
            [children]="true"
            (dropped)="dragDrop($event)"
          ></app-playground-item>
        </div>
      </div>
    </div>
    <div class="grid__columns-item">
      <div class="grid__cell">
        <div
          appDropList
          [appDropListData]="list2"
          [appDropListConnectedTo]="['list1']"
          class="container"
          id="list2"
          (appDropListDropped)="dragDrop($event)"
          #list2_cmp="appDropList"
        >
          <app-playground-item
            *ngFor="let item of list2"
            appDrag
            [appDragData]="item"
            [item]="item"
            [handle]="true"
            [children]="true"
            (dropped)="dragDrop($event)"
          ></app-playground-item>
        </div>
      </div>
    </div>
  </div>
  <div class="grid__cell">
    <div
      appDropList
      [appDropListData]="list3"
      [appDropListConnectedTo]="['list1', list2_cmp]"
      [appDropListEnterPredicate]="isDroppable"
      [appDropListSortingDisabled]="true"
      [appDropListCloneItems]="true"
      class="container"
      id="list3"
      (appDropListDropped)="dragDrop($event)"
    >
      <app-playground-item
        *ngFor="let item of list3"
        appDrag
        [appDragData]="item"
        [item]="item"
        [children]="true"
        (dropped)="dragDrop($event)"
      >
        <div *appDragPlaceholder class="example-custom-placeholder">Custom Placeholder</div>
      </app-playground-item>
    </div>
  </div>
</div>

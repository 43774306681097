import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import clamp from 'lodash/clamp';
import isEqual from 'lodash/isEqual';
import values from 'lodash/values';
import moment from 'moment';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { fromEvent, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { UserActivityListStore, UserActivityType } from '@modules/activities';
import { CustomizeHandler, CustomizeService } from '@modules/customize';
import { MenuSection, MenuService } from '@modules/menu';
import { ModelDescriptionStore, ModelService } from '@modules/model-queries';
import { Model, ModelDescription } from '@modules/models';
import { CurrentEnvironmentStore, CurrentProjectStore, ProjectUserService } from '@modules/projects';
import { getWindowScrollTop, isSet } from '@shared';

class Filters extends FormGroup {
  controls: {
    date_add_gte: FormControl;
    date_add_lte: FormControl;
    activity_type_in: FormControl;
    user: FormControl;
  };

  constructor() {
    super({
      date_add_gte: new FormControl(),
      date_add_lte: new FormControl(),
      activity_type_in: new FormControl(null),
      user: new FormControl(null)
    });
  }

  isSet(): boolean {
    return values(this.controls).some(item => isSet(item.value));
  }
}

@Component({
  selector: 'app-user-activities',
  templateUrl: './user-activities.component.html',
  providers: [UserActivityListStore],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserActivitiesComponent implements OnInit, OnDestroy, AfterViewInit, CustomizeHandler {
  modelDescription: ModelDescription;
  model: Model;

  defaultParams = {};

  loading = false;
  error: string;

  filters = new Filters();
  activityTypeOptions = [
    {
      name: 'All Activity',
      value: null
    },
    {
      name: 'Create Record',
      value: UserActivityType.ModelCreate
    },
    {
      name: 'Update Record',
      value: UserActivityType.ModelUpdate
    },
    {
      name: 'Delete Record',
      value: UserActivityType.ModelDelete
    }
    // {
    //   name: 'Delete Multiple Records',
    //   value: UserActivityType.ModelMassDelete
    // },
    // {
    //   name: 'View Record',
    //   value: UserActivityType.ModelDetail
    // },
    // {
    //   name: 'View Collection',
    //   value: UserActivityType.ModelList
    // },
    // {
    //   name: 'View Dashboard',
    //   value: UserActivityType.DashboardList
    // }
  ];
  activityUserOptions: { name: string; value: string }[] = [];
  formatDate = 'DD.MM.YYYY';

  constructor(
    private customizeService: CustomizeService,
    public currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private projectUserService: ProjectUserService,
    private activatedRoute: ActivatedRoute,
    private modelDescriptionStore: ModelDescriptionStore,
    private modelService: ModelService,
    public userActivityListStore: UserActivityListStore,
    private menuService: MenuService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.customizeService.setHandler(this);
    this.customizeService.setHandlerInfo(this, {
      breadcrumbs: ['User Activities']
    });

    this.projectUserService
      .get(this.currentProjectStore.instance.uniqueName, this.currentEnvironmentStore.instance.uniqueName)
      .pipe(untilDestroyed(this))
      .subscribe(users => {
        this.activityUserOptions = [
          {
            name: 'All Members',
            value: null
          },
          ...users
            .filter(user => user.user)
            .map(user => {
              return {
                name: user.user.firstName + ' ' + user.user.lastName,
                value: user.user.uid
              };
            })
        ];
        this.cd.markForCheck();
      });

    this.init();

    this.menuService.section = MenuSection.None;
  }

  ngOnDestroy(): void {
    this.customizeService.unsetHandler(this);
    this.menuService.section = MenuSection.Default;
  }

  ngAfterViewInit(): void {
    fromEvent(window, 'scroll')
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        const scrollTop = getWindowScrollTop();
        const viewportHeight = window.innerHeight;
        const viewportBottom = scrollTop + viewportHeight;
        const contentHeight = document.body.offsetHeight;

        if (contentHeight - viewportBottom <= clamp(viewportHeight, 100, viewportHeight)) {
          this.onScrollFinished();
        }
      });
  }

  init() {
    this.activatedRoute.params
      .pipe(
        switchMap(params => {
          const snapshot = this.activatedRoute.snapshot;

          if (snapshot.data['filter'] == 'model_description') {
            return this.modelDescriptionStore.getDetail(params['model']).pipe(
              map(modelDescription => {
                return {
                  modelDescription: modelDescription
                };
              })
            );
          } else if (snapshot.data['filter'] == 'model') {
            return this.modelDescriptionStore.getDetail(params['model']).pipe(
              switchMap(modelDescription =>
                this.modelService
                  .getDetail(
                    this.currentProjectStore.instance,
                    this.currentEnvironmentStore.instance,
                    modelDescription.modelId,
                    modelDescription.primaryKeyField,
                    params['id']
                  )
                  .pipe(
                    map(model => {
                      return {
                        modelDescription: modelDescription,
                        model: model
                      };
                    })
                  )
              )
            );
          } else {
            return of({});
          }
        }),
        untilDestroyed(this)
      )
      .subscribe(deps => {
        const params = {
          order_by: '-date_add',
          activity_type_in: this.activityTypeOptions
            .filter(item => item.value)
            .map(item => item.value)
            .join()
        };
        const snapshot = this.activatedRoute.snapshot;

        if (snapshot.data['filter'] == 'model_description') {
          this.modelDescription = deps['modelDescription'];
          this.cd.markForCheck();

          params['object_type'] = ['model', this.modelDescription.modelId].join('.');
        } else if (snapshot.data['filter'] == 'model') {
          this.model = deps['model'];
          this.modelDescription = deps['modelDescription'];
          this.cd.markForCheck();

          params['object_type'] = ['model', this.modelDescription.modelId].join('.');
          params['object_id'] = snapshot.params['id'];
        }

        this.defaultParams = params;

        this.fetch();
      });

    this.initFilters();
  }

  initFilters() {
    this.filters.valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
      const params = {
        ...this.defaultParams,
        ...(isSet(this.filters.controls.date_add_gte.value)
          ? {
              date_add_gte: moment(this.filters.controls.date_add_gte.value)
                .startOf('day')
                .format('YYYY-MM-DDTHH:mm:ss.SSSZ')
            }
          : undefined),
        ...(isSet(this.filters.controls.date_add_lte.value)
          ? {
              date_add_lte: moment(this.filters.controls.date_add_lte.value)
                .startOf('day')
                .format('YYYY-MM-DDTHH:mm:ss.SSSZ')
            }
          : undefined),
        ...(isSet(this.filters.controls.activity_type_in.value)
          ? {
              activity_type_in: this.filters.controls.activity_type_in.value
            }
          : undefined),
        ...(isSet(this.filters.controls.user.value)
          ? {
              user: this.filters.controls.user.value
            }
          : undefined)
      };

      this.fetch(params);
    });
  }

  fetch(params?: Object) {
    const newParams = params || this.defaultParams;

    if (isEqual(newParams, this.userActivityListStore.params)) {
      return;
    }

    this.loading = true;
    this.cd.markForCheck();

    this.userActivityListStore.projectName = this.currentProjectStore.instance.uniqueName;
    this.userActivityListStore.environmentName = this.currentEnvironmentStore.instance.uniqueName;
    this.userActivityListStore.params = newParams;
    this.userActivityListStore.reset();
    this.userActivityListStore
      .getNext()
      .pipe(untilDestroyed(this))
      .subscribe(
        () => {
          this.loading = false;
          this.cd.markForCheck();
        },
        error => {
          this.loading = false;
          this.error = error;
          this.cd.markForCheck();
        }
      );
  }

  onScrollFinished() {
    this.userActivityListStore.getNext();
  }

  get backParams() {
    return this.activatedRoute.snapshot.queryParams;
  }

  // TODO: Fix empty handler
  renameHandler(name: string): void {}
}

<div class="sidebar__element">
  <app-sidebar-field [label]="'display as fields'" [layoutLeft]="true">
    <app-auto-field
      [form]="form.form"
      [label]="false"
      [field]="
        createField({
          name: 'display_fields',
          field: 'BooleanField',
          params: { style: booleanFieldStyle.Toggle, orange: true }
        })
      "
    ></app-auto-field>
  </app-sidebar-field>
</div>

<app-sidebar-section *ngIf="form.form.value['display_fields']" [title]="'Fields Structure'">
  <ng-container header-right>
    <div class="sidebar-section__header-links">
      <a class="sidebar__link" href="javascript:void(0)" (click)="autoDetectStructure()">
        <span class="icon-repeat"></span> Reset
      </a>
    </div>
  </ng-container>

  <div class="sidebar__element sidebar__element_overflow">
    <app-json-field-structure [control]="form.form.controls['structure']"></app-json-field-structure>
  </div>
</app-sidebar-section>

<div class="compact-inputs-section">
  <div class="compact-inputs-section__header">
    <div class="compact-inputs-section__header-main">
      <div class="compact-inputs-section__header-title">Auto layout</div>
    </div>
    <div class="compact-inputs-section__header-right">
      <ng-container *ngIf="!enabledControl.value">
        <div
          class="compact-button compact-button_primary icon-plus"
          cdkOverlayOrigin
          #trigger="cdkOverlayOrigin"
          (click)="setDropdownOpened(true)"
        ></div>

        <ng-template
          cdkConnectedOverlay
          [cdkConnectedOverlayPanelClass]="['overlay', 'overlay_horizontal_center']"
          [cdkConnectedOverlayPositions]="dropdownPositions"
          [cdkConnectedOverlayOrigin]="trigger"
          [cdkConnectedOverlayOpen]="dropdownOpened"
          [cdkConnectedOverlayPush]="true"
          [cdkConnectedOverlayGrowAfterOpen]="true"
          [cdkConnectedOverlayHasBackdrop]="true"
          [cdkConnectedOverlayBackdropClass]="'popover2-backdrop'"
          (backdropClick)="setDropdownOpened(false)"
          (detach)="setDropdownOpened(false)"
        >
          <div class="compact-select-options">
            <div
              *ngFor="let option of control.orientationOptions"
              class="compact-select-options__item compact-select-option"
              (click)="selectOption(option); setDropdownOpened(false)"
            >
              <div *ngIf="option.icon" class="compact-select-option__left">
                <div class="compact-select-option__icon" [ngClass]="'icon-' + option.icon"></div>
              </div>

              <div class="compact-select-option__main">
                <div class="compact-select-option__row">
                  <div class="compact-select-option__title">{{ option.subtitle }}</div>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </ng-container>

      <div
        *ngIf="enabledControl.value"
        class="compact-button icon-minus"
        (click)="enabledControl.patchValue(false)"
      ></div>
    </div>
  </div>

  <app-flex-layout-control *ngIf="enabledControl.value" [control]="control"></app-flex-layout-control>
</div>

import { Point } from './point';
import { Translate } from './translate';

export class PointTranslate {
  point: Partial<Point>;
  translate: Translate = { x: 0, y: 0 };

  constructor(options: { point: Partial<Point>; translate?: Partial<Translate> }) {
    this.point = options.point;
    this.translate = { x: 0, y: 0, ...options.translate };
  }

  get x(): number {
    return this.point.x + this.translate.x;
  }

  get y(): number {
    return this.point.y + this.translate.y;
  }
}

<div class="sidebar__element">
  <app-sidebar-field [label]="'display format'">
    <ng-container actions>
      <span
        *ngIf="formatInputEnabled"
        class="sidebar__field-action sidebar__field-action_button icon-function"
        [class.sidebar__field-action_active]="control.controls.format_input_enabled.value"
        [appTip]="control.controls.format_input_enabled.value ? 'Disable Formula' : 'Set up with Formula'"
        [appTipOptions]="{ side: 'top' }"
        (click)="toggleInput()"
      ></span>
    </ng-container>

    <app-sidebar-card>
      <div *ngIf="formatInputEnabled && control.controls.format_input_enabled.value" class="sidebar__element">
        <app-sidebar-field>
          <app-input-edit
            [itemForm]="control.controls.format_input"
            [focusedInitial]="inputFocusedInitial"
            [context]="context"
            [contextElement]="contextElement"
            [contextElementPath]="contextElementPath"
            [contextElementPaths]="contextElementPaths"
            [staticValueDisabled]="true"
            [fill]="true"
            [analyticsSource]="analyticsSource + '_value_format'"
          ></app-input-edit>

          <ng-container description>
            Example: CONCAT('$', value)
          </ng-container>
        </app-sidebar-field>
      </div>

      <ng-container *ngIf="!formatInputEnabled || !control.controls.format_input_enabled.value">
        <div *ngIf="isNumber && numberFormatOptionsEnabled" class="sidebar__element">
          <app-sidebar-field>
            <mat-menu #dropdown="matMenu">
              <ng-template matMenuContent>
                <button
                  mat-menu-item
                  [class.mat-menu-item-odd]="true"
                  [disableRipple]="true"
                  (click)="control.controls.number_format.patchValue(undefined)"
                >
                  <span
                    class="choose-items-item__dropdown-icon icon-repeat"
                    [class.choose-items-item__dropdown-icon_orange]="false"
                  ></span>
                  Default
                </button>

                <button
                  *ngFor="let option of control.numberFormatOptions"
                  mat-menu-item
                  [class.mat-menu-item-odd]="true"
                  [disableRipple]="true"
                  (click)="control.controls.number_format.patchValue(option.value)"
                >
                  <span
                    *ngIf="option.icon"
                    class="choose-items-item__dropdown-icon"
                    [class.choose-items-item__dropdown-icon_orange]="false"
                    [ngClass]="['icon-' + option.icon]"
                  ></span>
                  {{ option.name }}
                </button>

                <button
                  mat-menu-item
                  [class.mat-menu-item-orange]="true"
                  [class.mat-menu-item_bottom-sticky]="true"
                  [class.mat-menu-item-odd]="true"
                  [class.mat-menu-item-border]="true"
                  [disableRipple]="true"
                  (click)="setInput(true)"
                >
                  <span
                    class="choose-items-item__dropdown-icon icon-plus"
                    [class.choose-items-item__dropdown-icon_orange]="true"
                  ></span>
                  Custom with formula
                </button>
              </ng-template>
            </mat-menu>

            <div class="select select_theme_jet select_fill">
              <div class="select__input" [matMenuTriggerFor]="dropdown">
                <ng-container *ngIf="numberFormatOption$ | async as numberFormat; else placeholder">
                  <span
                    *ngIf="numberFormat.icon"
                    class="select__input-icon"
                    [ngClass]="['icon-' + numberFormat.icon]"
                  ></span>
                  <span class="select__input-label">{{ numberFormat.name }}</span>
                </ng-container>

                <ng-template #placeholder>
                  <span class="select__input-label">Format options</span>
                </ng-template>

                <span class="select__input-arrow"></span>
              </div>
            </div>
          </app-sidebar-field>
        </div>

        <div *ngIf="prefixEnabled || postfixEnabled" class="sidebar__element">
          <div class="sidebar-cols">
            <div *ngIf="prefixEnabled" class="sidebar-cols__item">
              <app-sidebar-field>
                <app-auto-field
                  [form]="control"
                  [label]="false"
                  [field]="
                    createField({
                      name: 'prefix',
                      field: 'CharField',
                      required: false,
                      placeholder: 'e.g. $',
                      params: { classes: ['input_fill'] }
                    })
                  "
                ></app-auto-field>

                <ng-container description>
                  Prefix
                </ng-container>
              </app-sidebar-field>
            </div>

            <div *ngIf="postfixEnabled" class="sidebar-cols__item">
              <app-sidebar-field>
                <app-auto-field
                  [form]="control"
                  [label]="false"
                  [field]="
                    createField({
                      name: 'postfix',
                      field: 'CharField',
                      required: false,
                      placeholder: 'per month',
                      params: { classes: ['input_fill'] }
                    })
                  "
                ></app-auto-field>

                <ng-container description>
                  Postfix
                </ng-container>
              </app-sidebar-field>
            </div>
          </div>
        </div>

        <div *ngIf="isNumber" class="sidebar__element">
          <app-sidebar-field>
            <app-auto-field
              [form]="control"
              [label]="false"
              [field]="
                createField({
                  name: 'number_fraction',
                  field: 'SelectField',
                  params: {
                    options: control.numberFractionOptions,
                    classes: control.controls.number_fraction.value
                      ? ['select_fill', 'select_segment-top']
                      : ['select_fill']
                  }
                })
              "
            ></app-auto-field>

            <app-auto-field
              *ngIf="control.controls.number_fraction.value"
              [form]="control"
              [label]="false"
              [field]="
                createField({
                  name: 'number_fraction_separator',
                  field: 'SelectField',
                  params: {
                    options: control.numberFractionSeparatorOptions,
                    classes: ['select_fill', 'select_segment-bottom']
                  }
                })
              "
            ></app-auto-field>

            <ng-container description>
              Fractional part
            </ng-container>
          </app-sidebar-field>
        </div>

        <div *ngIf="isNumber" class="sidebar__element">
          <app-sidebar-field>
            <app-auto-field
              [form]="control"
              [label]="false"
              [field]="
                createField({
                  name: 'number_thousands_separator',
                  field: 'SelectField',
                  params: { options: control.numberThousandsSeparatorOptions, classes: ['select_fill'] }
                })
              "
            ></app-auto-field>

            <ng-container description>
              Thousands separator
            </ng-container>
          </app-sidebar-field>
        </div>
      </ng-container>
    </app-sidebar-card>
  </app-sidebar-field>
</div>

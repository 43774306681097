import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { filter, map, shareReplay } from 'rxjs/operators';

import { ApiService } from '@modules/api';
import { AppError, SingletonStore } from '@shared';

import { Project } from '../../projects/data/project';
import { ProjectService } from '../../projects/services/project/project.service';

@Injectable({
  providedIn: 'root'
})
export class ProjectsStore extends SingletonStore<Project[]> {
  private _current = new BehaviorSubject<Project>(undefined);
  public lastUsed$ = this._current.pipe(
    filter(item => item != undefined),
    shareReplay(1)
  );

  constructor(private projectService: ProjectService, private apiService: ApiService) {
    super();
  }

  set current(value: Project) {
    this._current.next(value);
  }

  get current(): Project {
    return this._current.value;
  }

  get current$(): Observable<Project> {
    return this._current.asObservable();
  }

  protected fetch(): Observable<Project[]> {
    if (!this.apiService.getAuthorization()) {
      return throwError(new AppError('Not authorized'));
    }
    return this.projectService.getAll({ minimal: true }).pipe(map(response => response));
  }

  public addItem(addInstance: Project) {
    this.instance = [...this.instance, addInstance];
  }

  public updateItem(updateInstance: Project) {
    const index = this.instance.findIndex(item => item.uniqueName == updateInstance.uniqueName);

    if (index !== -1) {
      this.instance = [...this.instance.slice(0, index), updateInstance, ...this.instance.slice(index + 1)];
    }
  }
}

import { Injectable, Injector } from '@angular/core';
import { combineLatest, Observable, ReplaySubject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { Resource } from '@modules/projects';

import { ThinDialogPopupComponent } from '@common/dialog-popup';
import { PopupService } from '@common/popups';
import { ActionStore } from '@modules/action-queries';
import { ActionDescription } from '@modules/actions';
import { ViewSettings, ViewSettingsStore } from '@modules/customize';
import { ModelDescriptionStore } from '@modules/model-queries';
import { ModelDescription } from '@modules/models';

import { ResourceSummaryComponent, Section } from '../../components/resource-summary/resource-summary.component';

export interface ResourceSummaryContent {
  models?: ModelDescription[];
  pages?: ViewSettings[];
  actions?: ActionDescription[];
  count: number;
}

@Injectable()
export class ResourceSummaryService {
  constructor(
    private modelDescriptionStore: ModelDescriptionStore,
    private actionStore: ActionStore,
    private viewSettingsStore: ViewSettingsStore,
    private popupService: PopupService,
    private injector: Injector
  ) {}

  open(resource: Resource): Observable<ResourceSummaryContent> {
    return this.getContent(resource).pipe(
      switchMap(content => {
        const result = new ReplaySubject<ResourceSummaryContent>();

        if (content.count) {
          this.popupService.push({
            component: ResourceSummaryComponent,
            popupComponent: ThinDialogPopupComponent,
            inputs: {
              tabs: this.getSections(content),
              resource: resource
            },
            popupClosed: () => {
              result.next(content);
            },
            injector: this.injector
          });
        } else {
          result.next(content);
        }

        return result;
      })
    );
  }

  private getContent(resource: Resource): Observable<ResourceSummaryContent> {
    return combineLatest(
      this.modelDescriptionStore
        .getFirst()
        .pipe(map(result => result.filter(item => item.resource == resource.uniqueName))),
      this.actionStore.getFirst().pipe(map(result => result.filter(item => item.resource == resource.uniqueName))),
      this.viewSettingsStore
        .getFirst()
        .pipe(map(result => result.filter(item => item.uniqueName && item.resource == resource.uniqueName)))
    ).pipe(
      map(([modelDescriptions, actionDescriptions, viewSettings]) => {
        return {
          models: modelDescriptions,
          actions: actionDescriptions,
          pages: viewSettings,
          count: [modelDescriptions, actionDescriptions, viewSettings]
            .filter(item => item != undefined)
            .reduce((acc, item) => acc + item.length, 0)
        };
      })
    );
  }

  private getSections(content: ResourceSummaryContent): Section[] {
    const tabs: Section[] = [];

    if (content.models && content.models.length) {
      tabs.push({
        name: 'Lists',
        items: content.models.map(item => {
          return {
            name: item.verboseNamePlural,
            description: item.description,
            icon: 'collection'
          };
        })
      });
    }

    if (content.actions && content.actions.length) {
      tabs.push({
        name: 'Buttons',
        items: content.actions.map(item => {
          return {
            name: item.verboseName,
            description: item.description,
            icon: 'action'
          };
        })
      });
    }

    if (content.pages && content.pages.length) {
      tabs.push({
        name: 'Pages',
        items: content.pages.map(item => {
          return {
            name: item.name,
            // description: item.description,
            icon: 'page'
          };
        })
      });
    }

    // if (modelDescriptions && modelDescriptions.length) {
    //   tabs.push({
    //     name: 'Pages',
    //     items: flatten(
    //       modelDescriptions.map(item => {
    //         return [
    //           {
    //             name: `${item.verboseNamePlural} - List`,
    //             icon: 'page'
    //           },
    //           {
    //             name: `${item.verboseNamePlural} - Create`,
    //             icon: 'page'
    //           },
    //           {
    //             name: `${item.verboseNamePlural} - Update`,
    //             icon: 'page'
    //           }
    //         ];
    //       })
    //     )
    //   });
    // }

    return tabs;
  }
}

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../common/localize/pipes/localize/localize.pipe";
import * as i2 from "../../../../project-settings-components/components/project-group-control/project-group-control.component.ngfactory";
import * as i3 from "../../../../project-settings-components/components/project-group-control/project-group-control.component";
import * as i4 from "../../../../../common/tips/directives/tip/tip.directive";
import * as i5 from "./share-public-invite-item.component";
import * as i6 from "../../../../admin-mode/providers/route-admin-mode";
import * as i7 from "../../../../projects/stores/current-project.store";
import * as i8 from "../../../../projects/stores/current-environment.store";
import * as i9 from "../../../../users/stores/current-user.store";
import * as i10 from "../../../../projects/services/project-public-invite/project-public-invite.service";
import * as i11 from "../../../../../common/dialogs/services/dialog/dialog.service";
import * as i12 from "../../../../../core/services/app-config/app-config.service";
import * as i13 from "../../../../../common/notifications/services/notification/notification.service";
import * as i14 from "../../../../analytics/services/universal-analytics/universal-analytics.service";
var styles_SharePublicInviteItemComponent = [];
var RenderType_SharePublicInviteItemComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SharePublicInviteItemComponent, data: {} });
export { RenderType_SharePublicInviteItemComponent as RenderType_SharePublicInviteItemComponent };
export function View_SharePublicInviteItemComponent_0(_l) { return i0.ɵvid(2, [i0.ɵpid(0, i1.LocalizePipe, []), (_l()(), i0.ɵeld(1, 0, null, null, 7, "td", [["class", "share-table__column"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 6, "div", [["style", "position: relative;"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 0, "input", [["class", "input input_disabled input_fill share-form__input"], ["readonly", ""], ["style", "padding-right: 85px;"]], [[8, "value", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = ($event.target.select() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 4, "a", [["class", "button button_small"], ["href", "javascript:void(0)"], ["style", "position: absolute; right: 5px; top: 5px; margin: 0;"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.copy() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 0, "span", [["class", "button__icon button__icon_left icon-duplicate"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 2, "span", [["class", "button__label"]], null, null, null, null, null)), (_l()(), i0.ɵted(7, null, ["", ""])), i0.ɵppd(8, 1), (_l()(), i0.ɵeld(9, 0, null, null, 3, "td", [["class", "share-table__column"]], null, null, null, null, null)), (_l()(), i0.ɵeld(10, 0, null, null, 2, "div", [["class", "share-table__select"]], null, null, null, null, null)), (_l()(), i0.ɵeld(11, 0, null, null, 1, "app-project-group-control", [], null, null, null, i2.View_ProjectGroupControlComponent_0, i2.RenderType_ProjectGroupControlComponent)), i0.ɵdid(12, 245760, null, 0, i3.ProjectGroupControlComponent, [], { control: [0, "control"] }, null), (_l()(), i0.ɵeld(13, 0, null, null, 5, "td", [["class", "share-table__column share-table__column_actions"]], null, null, null, null, null)), (_l()(), i0.ɵeld(14, 0, null, null, 4, "a", [["class", "share-table__delete"], ["href", "javascript:void(0)"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.delete() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(15, 737280, null, 0, i4.TipDirective, [i0.ElementRef, i0.ComponentFactoryResolver, i0.Injector, i0.ApplicationRef], { content: [0, "content"], optionsRaw: [1, "optionsRaw"] }, null), i0.ɵppd(16, 1), i0.ɵpod(17, { side: 0 }), (_l()(), i0.ɵeld(18, 0, null, null, 0, "span", [["class", "icon-bin"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.selectedGroup; _ck(_v, 12, 0, currVal_2); var currVal_3 = i0.ɵunv(_v, 15, 0, _ck(_v, 16, 0, i0.ɵnov(_v, 0), "Remove link")); var currVal_4 = _ck(_v, 17, 0, "top"); _ck(_v, 15, 0, currVal_3, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getPublicLink(); _ck(_v, 3, 0, currVal_0); var currVal_1 = i0.ɵunv(_v, 7, 0, _ck(_v, 8, 0, i0.ɵnov(_v, 0), "Copy")); _ck(_v, 7, 0, currVal_1); }); }
export function View_SharePublicInviteItemComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-share-public-invite-item", [], null, null, null, View_SharePublicInviteItemComponent_0, RenderType_SharePublicInviteItemComponent)), i0.ɵdid(1, 770048, null, 0, i5.SharePublicInviteItemComponent, [i6.ROUTE_ADMIN_MODE, i7.CurrentProjectStore, i8.CurrentEnvironmentStore, i9.CurrentUserStore, i10.ProjectPublicInviteService, i11.DialogService, i12.AppConfigService, i13.NotificationService, i14.UniversalAnalyticsService, i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SharePublicInviteItemComponentNgFactory = i0.ɵccf("app-share-public-invite-item, [app-share-public-invite-item]", i5.SharePublicInviteItemComponent, View_SharePublicInviteItemComponent_Host_0, { item: "item" }, { deleted: "deleted" }, []);
export { SharePublicInviteItemComponentNgFactory as SharePublicInviteItemComponentNgFactory };

import { Injectable } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { map, publishLast, refCount, switchMap, tap } from 'rxjs/operators';

import { FormUtils } from '@common/form-utils';
import { Project, ProjectService, ProjectsStore, ResourceName, resourceTypeItems } from '@modules/projects';
import { ResourceControllerService } from '@modules/resources';
import { isSet } from '@shared';

@Injectable()
export class ProjectRegisterForm {
  form: FormGroup;
  name = new FormControl('', Validators.required);
  api_base_url = new FormControl('', Validators.required);
  token = new FormControl('', Validators.required);

  constructor(
    private formUtils: FormUtils,
    private fb: FormBuilder,
    private resourceControllerService: ResourceControllerService,
    private projectService: ProjectService,
    private projectsStore: ProjectsStore
  ) {
    this.form = this.fb.group({
      name: this.name,
      api_base_url: this.api_base_url,
      token: this.token
    });
  }

  submit() {
    this.form.markAsDirty();

    const typeItem = resourceTypeItems.find(item => item.name == ResourceName.Django);
    const controller = this.resourceControllerService.get(typeItem.resourceType);
    const obs = controller.checkResource(typeItem, { url: this.form.value['api_base_url'] }).pipe(
      switchMap(() => {
        const name = this.projectService.popNewProjectName();
        const project = new Project().deserialize({
          name: isSet(name) ? name : this.form.value['name'],
          api_base_url: this.form.value['api_base_url']
        });

        return this.projectService.create(project, this.form.value['token']);
      }),
      tap(result => this.projectsStore.addItem(result)),
      publishLast(),
      refCount()
    );

    obs.subscribe(
      result => {},
      error => this.formUtils.showFormErrors(this.form, error)
    );

    return obs;
  }
}

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';

import { Domain } from '@modules/domain';
import { ProjectDomainController } from '@modules/domain-components';
import { createFormFieldFactory } from '@modules/fields';
import { CurrentProjectStore } from '@modules/projects';

import { ProjectUpdateForm } from '../project-settings/project-update.form';

@Component({
  selector: 'app-customize-bar-domain-edit',
  templateUrl: './customize-bar-domain-edit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomizeBarDomainEditComponent implements OnInit, OnDestroy {
  @Input() projectForm: ProjectUpdateForm;

  createField = createFormFieldFactory();
  domain: Domain;

  constructor(
    public currentProjectStore: CurrentProjectStore,
    private projectDomainController: ProjectDomainController,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.currentProjectStore
      .get()
      .pipe(untilDestroyed(this))
      .subscribe(project => {
        this.domain = project.domain;
        this.cd.markForCheck();
      });
  }

  ngOnDestroy(): void {}

  editDomain() {
    this.projectDomainController
      .edit({ analyticsSource: 'layout_builder_domain' })
      .pipe(untilDestroyed(this))
      .subscribe();
  }
}

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';

import { UserActivity, UserActivityDisplayService } from '@modules/activities';

@Component({
  selector: 'app-action-user-activity',
  templateUrl: './action-user-activity.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActionUserActivityComponent implements OnInit, OnDestroy {
  @Input() item: UserActivity;
  relations: Object;

  constructor(private userActivityDisplayService: UserActivityDisplayService, private cd: ChangeDetectorRef) {}

  ngOnInit() {
    this.userActivityDisplayService
      .getActionRelations(this.item)
      .pipe(untilDestroyed(this))
      .subscribe(
        result => {
          this.relations = result;
          this.cd.markForCheck();
        },
        e => {
          console.log(e);
        }
      );
  }

  ngOnDestroy(): void {}
}

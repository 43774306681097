<app-popup2 [size]="'l'">
  <form [formGroup]="form" (ngSubmit)="submit()">
    <div class="custom-page-popup custom-page-popup_style_background">
      <div class="custom-page-popup__header" [class.custom-page-popup__header_overlay]="false">
        <div class="custom-page-popup__header-main">
          <div class="custom-page-popup__title">
            <ng-container *ngIf="emailTemplate">Customizing {{ emailTemplate.info.label }}</ng-container>
            <ng-container *ngIf="!emailTemplate && baseEmailTemplate"
              >Customizing {{ baseEmailTemplate.info.label }}</ng-container
            >
          </div>
        </div>
      </div>
      <div class="custom-page-popup__content">
        <app-page-block [fill]="true">
          <app-page-block [marginSize]="'s'">
            <app-auto-field
              [form]="form"
              [field]="
                createField({
                  name: 'subject',
                  label: 'Subject',
                  field: 'CharField',
                  required: true,
                  params: { classes: ['input_fill'] }
                })
              "
            ></app-auto-field>
          </app-page-block>

          <app-page-block [marginSize]="'s'">
            <app-auto-field
              [form]="form"
              [field]="
                createField({
                  name: 'html_content',
                  label: 'Text (HTML)',
                  field: 'RichTextField',
                  required: true,
                  params: { classes: ['input_fill'], rows: 32 }
                })
              "
            ></app-auto-field>
          </app-page-block>
        </app-page-block>

        <app-page-block>
          <app-stack [align]="'right'">
            <button
              type="button"
              class="button button_orange-transparent popup2__footer-item"
              (click)="cancel()"
              [disabled]="submitLoading"
            >
              Cancel
            </button>

            <button
              type="submit"
              class="button button_primary button_orange popup2__footer-item"
              [disabled]="submitLoading || !form.valid"
            >
              <app-loader-small *ngIf="submitLoading" class="button__icon button__icon_left"></app-loader-small>
              <ng-container *ngIf="!emailTemplate">Customize</ng-container>
              <ng-container *ngIf="emailTemplate">Save</ng-container>
            </button>
          </app-stack>
        </app-page-block>
      </div>
    </div>
  </form>
</app-popup2>

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./lightbox.component";
var styles_LightboxComponent = [];
var RenderType_LightboxComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_LightboxComponent, data: {} });
export { RenderType_LightboxComponent as RenderType_LightboxComponent };
function View_LightboxComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, [[1, 0], ["preview", 1]], null, 0, "img", [["class", "lightbox__preview"]], [[8, "src", 4], [4, "max-width", "px"], [4, "max-height", "px"]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.url; var currVal_1 = 1240; var currVal_2 = _co.maxHeight; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
function View_LightboxComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, [[1, 0], ["preview", 1]], null, 0, "video", [["autoplay", ""], ["class", "lightbox__preview"], ["loop", ""], ["muted", ""]], [[8, "src", 4], [4, "max-width", "px"], [4, "max-height", "px"]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.url; var currVal_1 = 1240; var currVal_2 = _co.maxHeight; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
function View_LightboxComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "img", [["class", "lightbox__img"]], [[8, "src", 4]], null, null, null, null)), i0.ɵdid(1, 278528, null, 0, i1.NgStyle, [i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { ngStyle: [0, "ngStyle"] }, null), i0.ɵpod(2, { transform: 0 })], function (_ck, _v) { var _co = _v.component; var currVal_1 = _ck(_v, 2, 0, (((("translate(" + _co.offsetX) + "px, ") + _co.offsetY) + "px)")); _ck(_v, 1, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.url; _ck(_v, 0, 0, currVal_0); }); }
function View_LightboxComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "video", [["autoplay", ""], ["class", "lightbox__img"], ["loop", ""], ["muted", ""]], [[8, "src", 4]], null, null, null, null)), i0.ɵdid(1, 278528, null, 0, i1.NgStyle, [i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { ngStyle: [0, "ngStyle"] }, null), i0.ɵpod(2, { transform: 0 })], function (_ck, _v) { var _co = _v.component; var currVal_1 = _ck(_v, 2, 0, (((("translate(" + _co.offsetX) + "px, ") + _co.offsetY) + "px)")); _ck(_v, 1, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.url; _ck(_v, 0, 0, currVal_0); }); }
function View_LightboxComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "lightbox__zoom-area"]], [[4, "opacity", null]], [[null, "mouseenter"], [null, "mouseleave"], [null, "mousemove"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mouseenter" === en)) {
        var pd_0 = (_co.showZoom() !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = (_co.hideZoom() !== false);
        ad = (pd_1 && ad);
    } if (("mousemove" === en)) {
        var pd_2 = (_co.mousemove($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_LightboxComponent_4)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_LightboxComponent_5)), i0.ɵdid(4, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = !_co.video; _ck(_v, 2, 0, currVal_1); var currVal_2 = _co.video; _ck(_v, 4, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.opacityZoom; _ck(_v, 0, 0, currVal_0); }); }
export function View_LightboxComponent_0(_l) { return i0.ɵvid(2, [i0.ɵqud(671088640, 1, { preview: 0 }), (_l()(), i0.ɵeld(1, 0, null, null, 6, "div", [["class", "lightbox"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_LightboxComponent_1)), i0.ɵdid(3, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_LightboxComponent_2)), i0.ɵdid(5, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_LightboxComponent_3)), i0.ɵdid(7, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.video; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.video; _ck(_v, 5, 0, currVal_1); var currVal_2 = _co.canZoom; _ck(_v, 7, 0, currVal_2); }, null); }
export function View_LightboxComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-lightbox", [], null, null, null, View_LightboxComponent_0, RenderType_LightboxComponent)), i0.ɵdid(1, 4440064, null, 0, i2.LightboxComponent, [i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LightboxComponentNgFactory = i0.ɵccf("app-lightbox", i2.LightboxComponent, View_LightboxComponent_Host_0, { url: "url", video: "video", zoom: "zoom" }, {}, []);
export { LightboxComponentNgFactory as LightboxComponentNgFactory };

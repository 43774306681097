var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { AfterViewInit, ChangeDetectorRef, EventEmitter, Injector, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { DynamicComponentService } from '@common/dynamic-component';
import { NotificationService } from '@common/notifications';
import { UniversalAnalyticsService } from '@modules/analytics';
import { CustomizeBarService } from '@modules/customize-bar';
import { ProjectSettingsService } from '@modules/project-settings';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { getResourceSettingsComponent, ResourceEditController } from '@modules/projects-components';
import { ResourceGeneratorResolver } from '@modules/resource-generators';
import { SidebarCollapseContext } from '@modules/sidebar';
import { TemplateProvider } from '../../../../services/template-provider/template.provider';
import { ChangeCustomizeBarItemsBase } from '../../change-customize-bar-items-base/change-customize-bar-items-base.component';
var ChangeCustomizeBarTemplatesGroupComponent = /** @class */ (function (_super) {
    __extends(ChangeCustomizeBarTemplatesGroupComponent, _super);
    function ChangeCustomizeBarTemplatesGroupComponent(cd, templateProvider, currentProjectStore, currentEnvironmentStore, resourceEditController, injector, dynamicComponentService, customizeBarService, analyticsService, projectSettingsService, resourceGeneratorResolver, notificationService) {
        var _this = _super.call(this, injector, dynamicComponentService, customizeBarService, analyticsService) || this;
        _this.cd = cd;
        _this.templateProvider = templateProvider;
        _this.currentProjectStore = currentProjectStore;
        _this.currentEnvironmentStore = currentEnvironmentStore;
        _this.resourceEditController = resourceEditController;
        _this.injector = injector;
        _this.dynamicComponentService = dynamicComponentService;
        _this.customizeBarService = customizeBarService;
        _this.analyticsService = analyticsService;
        _this.projectSettingsService = projectSettingsService;
        _this.resourceGeneratorResolver = resourceGeneratorResolver;
        _this.notificationService = notificationService;
        _this.scrollToTop = new EventEmitter();
        _this.update = new EventEmitter();
        _this.collapseContext = new SidebarCollapseContext();
        _this.sections = [];
        _this.reloadLoading = false;
        _this.reloadAllowed = false;
        return _this;
    }
    ChangeCustomizeBarTemplatesGroupComponent.prototype.ngOnInit = function () { };
    ChangeCustomizeBarTemplatesGroupComponent.prototype.ngOnChanges = function (changes) {
        if (changes['item']) {
            this.updateSections();
        }
    };
    ChangeCustomizeBarTemplatesGroupComponent.prototype.updateSections = function () {
        var _this = this;
        this.templateProvider
            .getGroupSections(this.item, this.context)
            .pipe(untilDestroyed(this))
            .subscribe(function (result) {
            _this.sections = result;
            _this.cd.markForCheck();
        });
    };
    ChangeCustomizeBarTemplatesGroupComponent.prototype.ngOnDestroy = function () { };
    ChangeCustomizeBarTemplatesGroupComponent.prototype.ngAfterViewInit = function () {
        this.scrollToTop.emit(false);
    };
    ChangeCustomizeBarTemplatesGroupComponent.prototype.editResource = function () {
        var resource = this.item.resource;
        var settings = getResourceSettingsComponent(resource.typeItem.name);
        if (!settings) {
            return;
        }
        this.resourceEditController
            .editResource(resource, {
            resourceNameEditing: true,
            analyticsSource: 'component_templates'
        })
            .subscribe();
    };
    ChangeCustomizeBarTemplatesGroupComponent.prototype.reload = function () {
        var _this = this;
        this.reloadLoading = true;
        this.cd.markForCheck();
        var generator = this.resourceGeneratorResolver.get(this.item.resource.typeItem.name);
        if (generator) {
            this.projectSettingsService
                .syncResource(this.item.resource, generator, { mergeExisting: true })
                .pipe(untilDestroyed(this))
                .subscribe(function (res) {
                _this.update.emit();
                _this.reloadLoading = false;
                _this.cd.markForCheck();
                _this.notificationService.success('Synchronized', 'Resource was successfully updated');
            }, function (e) {
                console.error(e);
                _this.reloadLoading = false;
                _this.cd.markForCheck();
                _this.notificationService.error('Synchronized Failed', 'Resource structure update failed');
            });
        }
        else {
            this.notificationService.error('Synchronized not available', 'No synchronization mechanics found');
        }
    };
    ChangeCustomizeBarTemplatesGroupComponent.prototype.updateReload = function () {
        if (this.item.resource) {
            var generator = this.resourceGeneratorResolver.get(this.item.resource.typeItem.name);
            this.reloadAllowed =
                this.projectSettingsService.syncAllowed(this.item.resource, generator) && !this.item.resource.demo;
        }
        else {
            this.reloadAllowed = false;
        }
        this.cd.markForCheck();
    };
    return ChangeCustomizeBarTemplatesGroupComponent;
}(ChangeCustomizeBarItemsBase));
export { ChangeCustomizeBarTemplatesGroupComponent };

import { Injector } from '@angular/core';
import moment from 'moment';
import { BehaviorSubject } from 'rxjs';
import { ThinDialogPopupComponent } from '@common/dialog-popup';
import { PopupService } from '@common/popups';
import { LocalStorage } from '@core';
import { OnboardingService } from '@modules/tutorial';
import { CurrentUserStore } from '@modules/users';
import { CompanyInfoComponent } from '../../components/company-info/company-info.component';
var CompanyInfoService = /** @class */ (function () {
    function CompanyInfoService(popupService, onboardingService, localStorage, currentUserStore, injector) {
        this.popupService = popupService;
        this.onboardingService = onboardingService;
        this.localStorage = localStorage;
        this.currentUserStore = currentUserStore;
        this.injector = injector;
        this._visible = new BehaviorSubject(false);
    }
    CompanyInfoService.prototype.isProjectCreateTimeAgo = function (project) {
        var value = this.localStorage.get(project.dateCreatedKey);
        if (!value) {
            return;
        }
        var minutesPassed = moment().diff(moment(value), 'minutes');
        return minutesPassed >= 10;
    };
    CompanyInfoService.prototype.isShown = function (project) {
        return this.currentUserStore.instance.params.hasProjectCompanyInfoShowed(project.uniqueName);
    };
    Object.defineProperty(CompanyInfoService.prototype, "visible", {
        get: function () {
            return this._visible.value;
        },
        set: function (value) {
            this._visible.next(value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CompanyInfoService.prototype, "visible$", {
        get: function () {
            return this._visible.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    CompanyInfoService.prototype.showCompanyInfoPopup = function (project) {
        var _this = this;
        this.visible = true;
        this.popupService.push({
            component: CompanyInfoComponent,
            popupComponent: ThinDialogPopupComponent,
            inputs: {
                project: project
            },
            outputs: {
                closed: [
                    function () {
                        _this.visible = false;
                        _this.onboardingService.showOnboardingPopupIfNeeded(project);
                    }
                ]
            },
            injector: this.injector
        });
    };
    CompanyInfoService.prototype.showCompanyInfoPopupIfNeeded = function (project) {
        return false;
        if (this.isProjectCreateTimeAgo(project) !== false &&
            !this.isShown(project) &&
            !this.visible &&
            !project.company &&
            !project.demo) {
            // this.setShown(project);
            this.showCompanyInfoPopup(project);
            return true;
        }
        else {
            return false;
        }
    };
    return CompanyInfoService;
}());
export { CompanyInfoService };

<div class="popover2__viewport popover2__viewport_scrollable">
  <div class="popover2__content" style="min-width: 300px;">
    <div class="token-popup__item">
      <div class="token-popup-search">
        <input
          type="text"
          class="token-popup-search__input"
          [formControl]="searchControl"
          [appAutofocus]="true"
          placeholder="Search..."
        />
        <span
          *ngIf="searchControl.value | appIsSet"
          class="token-popup-search__close icon-close"
          (click)="clearSearch()"
          [appTip]="'Clear'"
          [appTipOptions]="{ side: 'top' }"
        ></span>
      </div>
    </div>

    <div class="token-popup__item">
      <div class="token-popup-section">
        <div class="token-popup-section__title">
          <div class="token-popup-section__title-main">User properties</div>
        </div>

        <div class="token-popup-section__content">
          <a
            *ngFor="let item of filteredOptions"
            class="token-popup-list-item token-popup-list-item_hoverable token-popup-list-item_clickable"
            [class.token-popup-list-item_active]="false"
            href="javascript:void(0)"
            (click)="selectOption(item)"
          >
            <div class="token-popup-list-item__inner">
              <div class="token-popup-list-item__line">
                <span *ngIf="item.icon" class="token-popup-list-item__left">
                  <span class="token-popup-list-item__icon" [ngClass]="'icon-' + item.icon"> </span>
                </span>

                <span class="token-popup-list-item__main token-popup-list-item__main_wrap">
                  {{ item.label | appCapitalize }}
                </span>
              </div>
            </div>
          </a>

          <ng-container *ngIf="!filteredOptions.length">
            <div *ngIf="searchControl.value | appIsSet" class="token-popup-list-item">
              <div class="token-popup-list-item__inner">
                <div class="token-popup-list-item__tip">
                  Nothing found (<a href="javascript:void(0)" class="link link_orange" (click)="createProperty()"
                    >Add User property</a
                  >)
                </div>
              </div>
            </div>

            <a
              *ngIf="!(searchControl.value | appIsSet)"
              class="token-popup-list-item token-popup-list-item_hoverable token-popup-list-item_clickable"
              [class.token-popup-list-item_orange]="true"
              href="javascript:void(0)"
              (click)="createProperty()"
            >
              <div class="token-popup-list-item__inner">
                <div class="token-popup-list-item__line">
                  <span class="token-popup-list-item__left">
                    <span class="token-popup-list-item__icon token-popup-list-item__icon_orange icon-plus"></span>
                  </span>

                  <span class="token-popup-list-item__main token-popup-list-item__main_wrap">
                    Add User property
                  </span>
                </div>
              </div>
            </a>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="filteredOptions.length" class="popover2__toolbar">
  <a
    href="javascript:void(0)"
    class="token-popup-list-item token-popup-list-item_hoverable token-popup-list-item_clickable"
    [class.token-popup-list-item_orange]="true"
    (click)="createProperty()"
  >
    <div class="token-popup-list-item__inner">
      <div class="token-popup-list-item__line">
        <span class="token-popup-list-item__left">
          <span class="token-popup-list-item__icon token-popup-list-item__icon_orange icon-plus"></span>
        </span>

        <span class="token-popup-list-item__main token-popup-list-item__main_wrap">
          Add User property
        </span>
      </div>
    </div>
  </a>
</div>

import { DatasetGroupLookup } from '@modules/charts';

export const radarChartSampleData = [
  {
    name: undefined,
    color: 'gray',
    format: undefined,
    groupLookup: DatasetGroupLookup.Auto,
    dataset: [
      { group: 'Segment A', value: 400 },
      { group: 'Segment B', value: 500 },
      { group: 'Segment C', value: 200 },
      { group: 'Segment D', value: 700 }
    ]
  }
];

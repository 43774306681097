import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import { ViewContext } from '@modules/customize';
import { createFormFieldFactory } from '@modules/fields';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { ProjectStorageService } from '@modules/resources';

import { ViewEditorContext } from '../../../services/view-editor-context/view-editor.context';
import { IconFillControl } from '../../controls/icon-fill.control';

@Component({
  selector: 'app-icon-fill-selector',
  templateUrl: './icon-fill-selector.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IconFillSelectorComponent implements OnInit, OnDestroy {
  @Input() control: IconFillControl;
  @Input() viewContext: ViewContext;

  createField = createFormFieldFactory();
  iconInputFocused = false;

  constructor(
    private editorContext: ViewEditorContext,
    private sanitizer: DomSanitizer,
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private projectStorageService: ProjectStorageService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {}

  ngOnDestroy(): void {}

  toggleImageInputEnabled() {
    const value = !this.control.controls.icon_input_enabled.value;

    this.control.controls.icon_input_enabled.patchValue(value);

    if (value) {
      this.iconInputFocused = true;
      this.cd.markForCheck();
    }
  }
}

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Injector } from '@angular/core';
import { FormArray } from '@angular/forms';
import isEqual from 'lodash/isEqual';
import range from 'lodash/range';
import { MenuBlock } from '@modules/menu';
import { MenuBlockControl } from './menu-block.control';
var MenuBlockArray = /** @class */ (function (_super) {
    __extends(MenuBlockArray, _super);
    function MenuBlockArray(injector, controls) {
        var _this = _super.call(this, controls) || this;
        _this.injector = injector;
        _this.controlsFixedLength = 3;
        return _this;
    }
    MenuBlockArray.inject = function (injector, controls) {
        return Injector.create({
            providers: [
                {
                    provide: MenuBlockArray,
                    useFactory: function () {
                        return new MenuBlockArray(injector, controls);
                    },
                    deps: []
                }
            ],
            parent: injector
        }).get(MenuBlockArray);
    };
    MenuBlockArray.prototype.deserialize = function (value) {
        var _this = this;
        value.forEach(function (item, i) {
            var control = _this.controls[i] || _this.appendControl();
            control.deserialize(item);
        });
        this.controls.slice(value.length).forEach(function (item) { return _this.removeControl(item); });
        range(this.controlsFixedLength - value.length).forEach(function () {
            var block = new MenuBlock();
            block.generateUid();
            block.enabled = false;
            _this.appendControl(block);
        });
    };
    MenuBlockArray.prototype.serialize = function () {
        return this.controls.map(function (control) { return control.serialize(); });
    };
    MenuBlockArray.prototype.setControls = function (controls) {
        var _this = this;
        this.removeControls();
        controls.forEach(function (item) { return _this.push(item); });
    };
    MenuBlockArray.prototype.removeControls = function () {
        var _this = this;
        range(this.controls.length).forEach(function () { return _this.removeAt(0); });
    };
    MenuBlockArray.prototype.removeControl = function (control) {
        var newControls = this.controls.filter(function (item) { return !isEqual(item, control); });
        this.setControls(newControls);
    };
    MenuBlockArray.prototype.createControl = function (item, state) {
        var control = MenuBlockControl.inject(this.injector, state || {});
        if (item) {
            control.deserialize(item);
        }
        control.markAsPristine();
        return control;
    };
    MenuBlockArray.prototype.appendControl = function (item, state) {
        var control = this.createControl(item, state);
        this.push(control);
        return control;
    };
    return MenuBlockArray;
}(FormArray));
export { MenuBlockArray };

<div class="menu-pages">
  <div class="menu-pages__header">
    <div class="menu-pages-header">
      <div class="menu-pages-header__title">Tasks</div>
    </div>
  </div>

  <div class="menu-pages__content" xsScrollable [xsScrollableOptions]="{ stopPropagationAlways: true }">
    <div class="menu-pages__group">
      <div class="menu-pages-group">
        <a class="menu-pages__item" (click)="createQueue()">
          <div class="menu-pages-item menu-pages-item_interactive">
            <span class="menu-pages-item__icon icon-plus_circle"></span>
            <span class="menu-pages-item__title">Add task queue</span>
          </div>
        </a>
        <a
          *ngFor="let queue of queues$ | async"
          [appLink]="currentProject?.settingsCollaborationTasksLink"
          [queryParams]="{ queue: queue.uid }"
          class="menu-pages__item"
          [appLinkActive]="'menu-pages__item_active'"
          (click)="closeMenu.emit()"
        >
          <div class="menu-pages-item menu-pages-item_interactive">
            <span class="menu-pages-item__icon icon-versions"></span>
            <span class="menu-pages-item__title">{{ queue.name }}</span>
          </div>
        </a>
      </div>
    </div>
  </div>

  <div *ngIf="'menu_collaboration_banner' | appBannerVisible" class="menu-pages__banner">
    <app-customize-menu-banner
      [uid]="'menu_collaboration_banner'"
      [title]="'Discover the power of the <br>Jet Admin Collaboration'"
      [showVideoOptions]="{ youtubeId: '-DMkubFxhDs' }"
    >
      Read more in our
      <a [appDocumentation]="'approval-workflow'" target="_blank">Documentation</a>
    </app-customize-menu-banner>
  </div>
</div>

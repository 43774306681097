import { Injectable } from '@angular/core';
import isEqual from 'lodash/isEqual';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ListLayoutType } from '../../data/list-layout-type';
import { ListLayout, listLayouts } from '../../data/list-layouts';
import { LayoutStore } from '../../stores/store/layout.store';

@Injectable()
export class LayoutService {
  public layouts$: Observable<ListLayout[]>;
  public currentLayout$: Observable<ListLayout>;

  private _layoutTypes = new BehaviorSubject<ListLayoutType[]>([ListLayoutType.Table]);
  private _currentLayoutIndex = new BehaviorSubject<number>(undefined);
  private defaultLayout = 0;
  private viewId: string;

  constructor(private layoutStore: LayoutStore) {
    this.layouts$ = this._layoutTypes.pipe(map(value => value.map(item => listLayouts.find(i => i.type == item))));

    this.currentLayout$ = combineLatest(this.layouts$, this._currentLayoutIndex).pipe(
      map(([layouts, index]) => {
        if (index == undefined) {
          return;
        }

        return layouts[index];
      })
    );
  }

  init(layoutTypes: ListLayoutType[], viewId: string, defaultLayout = 0) {
    this.defaultLayout = defaultLayout;
    this.viewId = viewId;

    layoutTypes = layoutTypes.filter(item => listLayouts.find(i => i.type == item));

    if (!isEqual(this._layoutTypes.value, layoutTypes)) {
      this._layoutTypes.next(layoutTypes);
    }

    const index = this.cleanLayoutIndex(this.layoutStore.getCurrentLayout(this.viewId));

    if (this._currentLayoutIndex.value !== index) {
      this._currentLayoutIndex.next(index);
    }
  }

  cleanLayoutIndex(index: any) {
    if (!this.layouts) {
      return;
    }

    index = parseInt(index, 10) || this.defaultLayout;

    if (index >= this.layouts.length) {
      return this.defaultLayout;
    }

    return index;
  }

  get layouts(): ListLayout[] {
    return this._layoutTypes.value.map(item => listLayouts.find(i => i.type == item));
  }

  get currentLayoutIndex(): number {
    return this._currentLayoutIndex.value;
  }

  get currentLayoutIndex$(): Observable<number> {
    return this._currentLayoutIndex.asObservable();
  }

  set currentLayoutIndex(index: number) {
    index = this.cleanLayoutIndex(index);
    this.layoutStore.setCurrentLayout(this.viewId, index);
    this._currentLayoutIndex.next(index);
  }
}

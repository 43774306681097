var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { FormBuilder } from '@angular/forms';
import { FieldType, ValueFormat } from '@modules/fields';
import { ValueFormatControl } from '../../value-format-edit/value-format.control';
var NumberFieldViewParamsForm = /** @class */ (function () {
    function NumberFieldViewParamsForm(fb, valueFormatControl) {
        this.fb = fb;
        this.valueFormatControl = valueFormatControl;
        this.form = this.fb.group({
            value_format: valueFormatControl
        });
    }
    NumberFieldViewParamsForm.prototype.init = function (control) {
        var _this = this;
        this.control = control;
        if (control.value) {
            this.form.patchValue({}, { emitEvent: false });
        }
        var valueFormat = control.value && control.value['value_format']
            ? new ValueFormat().deserialize(control.value['value_format'])
            : undefined;
        this.valueFormatControl.deserialize(valueFormat, { field: FieldType.Number });
        this.form.valueChanges.subscribe(function () { return _this.submit(); });
    };
    NumberFieldViewParamsForm.prototype.submit = function () {
        var data = this.form.value;
        var valueFormat = this.valueFormatControl.serialize();
        data['value_format'] = valueFormat ? valueFormat.serialize() : undefined;
        this.control.patchValue(__assign({}, this.control.value, data));
    };
    return NumberFieldViewParamsForm;
}());
export { NumberFieldViewParamsForm };

import isArray from 'lodash/isArray';
import * as moment from 'moment';

import {
  exactFieldLookup,
  gteFieldLookup,
  gtFieldLookup,
  inFieldLookup,
  isCurrentMonthFieldLookup,
  isCurrentQuarterFieldLookup,
  isCurrentWeekFieldLookup,
  isCurrentYearFieldLookup,
  isFutureFieldLookup,
  isLastMonthFieldLookup,
  isLastQuarterFieldLookup,
  isLastWeekFieldLookup,
  isLastXDaysFieldLookup,
  isLastYearFieldLookup,
  isNullFieldLookup,
  isPastFieldLookup,
  isPreviousMonthFieldLookup,
  isPreviousQuarterFieldLookup,
  isPreviousWeekFieldLookup,
  isPreviousXDaysFieldLookup,
  isPreviousYearFieldLookup,
  isTodayFieldLookup,
  isYesterdayFieldLookup,
  lteFieldLookup,
  ltFieldLookup
} from '@modules/field-lookups';

import { BaseField } from '../base-field';
import { FieldType } from '../field-type';

function getGranularity(field: BaseField): moment.unitOfTime.StartOf {
  return field && field.params['time'] === false ? 'day' : undefined;
}

export const dateTimeLookupMatchers = [
  {
    field: FieldType.DateTime,
    lookup: gteFieldLookup,
    match: (fieldValue: moment.Moment, lookupValue: moment.Moment, field?: BaseField): boolean => {
      if (!fieldValue || !lookupValue) {
        return false;
      }
      const granularity = getGranularity(field);
      return fieldValue.isSameOrAfter(lookupValue, granularity);
    }
  },
  {
    field: FieldType.DateTime,
    lookup: gtFieldLookup,
    match: (fieldValue: moment.Moment, lookupValue: moment.Moment, field?: BaseField): boolean => {
      if (!fieldValue || !lookupValue) {
        return false;
      }
      const granularity = getGranularity(field);
      return fieldValue.isAfter(lookupValue, granularity);
    }
  },
  {
    field: FieldType.DateTime,
    lookup: lteFieldLookup,
    match: (fieldValue: moment.Moment, lookupValue: moment.Moment, field?: BaseField): boolean => {
      if (!fieldValue || !lookupValue) {
        return false;
      }
      const granularity = getGranularity(field);
      return fieldValue.isSameOrBefore(lookupValue, granularity);
    }
  },
  {
    field: FieldType.DateTime,
    lookup: ltFieldLookup,
    match: (fieldValue: moment.Moment, lookupValue: moment.Moment, field?: BaseField): boolean => {
      if (!fieldValue || !lookupValue) {
        return false;
      }
      const granularity = getGranularity(field);
      return fieldValue.isBefore(lookupValue, granularity);
    }
  },
  {
    field: FieldType.DateTime,
    lookup: isPastFieldLookup,
    match: (fieldValue: moment.Moment, lookupValue: boolean, field?: BaseField): boolean => {
      if (!fieldValue || !lookupValue) {
        return false;
      }
      const granularity = getGranularity(field);
      return moment().isAfter(fieldValue, granularity);
    }
  },
  {
    field: FieldType.DateTime,
    lookup: isFutureFieldLookup,
    match: (fieldValue: moment.Moment, lookupValue: boolean, field?: BaseField): boolean => {
      if (!fieldValue || !lookupValue) {
        return false;
      }
      const granularity = getGranularity(field);
      return moment().isBefore(fieldValue, granularity);
    }
  },
  {
    field: FieldType.DateTime,
    lookup: isTodayFieldLookup,
    match: (fieldValue: moment.Moment, lookupValue: boolean, field?: BaseField): boolean => {
      if (!fieldValue || !lookupValue) {
        return false;
      }
      const from = moment().startOf('day');
      const to = moment().endOf('day');
      const granularity = getGranularity(field);
      return fieldValue.isBetween(from, to, granularity, '[]');
    }
  },
  {
    field: FieldType.DateTime,
    lookup: isYesterdayFieldLookup,
    match: (fieldValue: moment.Moment, lookupValue: boolean, field?: BaseField): boolean => {
      if (!fieldValue || !lookupValue) {
        return false;
      }
      const yesterday = moment().subtract(1, 'day');
      const from = yesterday.clone().startOf('day');
      const to = yesterday.clone().endOf('day');
      const granularity = getGranularity(field);
      return fieldValue.isBetween(from, to, granularity, '[]');
    }
  },
  {
    field: FieldType.DateTime,
    lookup: isLastWeekFieldLookup,
    match: (fieldValue: moment.Moment, lookupValue: boolean, field?: BaseField): boolean => {
      if (!fieldValue || !lookupValue) {
        return false;
      }
      const from = moment().startOf('week');
      const to = moment().endOf('week');
      const granularity = getGranularity(field);
      return fieldValue.isBetween(from, to, granularity, '[]');
    }
  },
  {
    field: FieldType.DateTime,
    lookup: isCurrentWeekFieldLookup,
    match: (fieldValue: moment.Moment, lookupValue: boolean, field?: BaseField): boolean => {
      if (!fieldValue || !lookupValue) {
        return false;
      }
      const now = moment();
      const from = now.clone().startOf('week');
      const to = now.clone().endOf('week');
      const granularity = getGranularity(field);
      return fieldValue.isBetween(from, to, granularity, '[]');
    }
  },
  {
    field: FieldType.DateTime,
    lookup: isPreviousWeekFieldLookup,
    match: (fieldValue: moment.Moment, lookupValue: boolean, field?: BaseField): boolean => {
      if (!fieldValue || !lookupValue) {
        return false;
      }
      const prevWeek = moment().subtract(1, 'week');
      const from = prevWeek.clone().startOf('week');
      const to = prevWeek.clone().endOf('week');
      const granularity = getGranularity(field);
      return fieldValue.isBetween(from, to, granularity, '[]');
    }
  },
  {
    field: FieldType.DateTime,
    lookup: isLastMonthFieldLookup,
    match: (fieldValue: moment.Moment, lookupValue: boolean, field?: BaseField): boolean => {
      if (!fieldValue || !lookupValue) {
        return false;
      }
      const from = moment().startOf('month');
      const to = moment().endOf('month');
      const granularity = getGranularity(field);
      return fieldValue.isBetween(from, to, granularity, '[]');
    }
  },
  {
    field: FieldType.DateTime,
    lookup: isCurrentMonthFieldLookup,
    match: (fieldValue: moment.Moment, lookupValue: boolean, field?: BaseField): boolean => {
      if (!fieldValue || !lookupValue) {
        return false;
      }
      const now = moment();
      const from = now.clone().startOf('month');
      const to = now.clone().endOf('month');
      const granularity = getGranularity(field);
      return fieldValue.isBetween(from, to, granularity, '[]');
    }
  },
  {
    field: FieldType.DateTime,
    lookup: isPreviousMonthFieldLookup,
    match: (fieldValue: moment.Moment, lookupValue: boolean, field?: BaseField): boolean => {
      if (!fieldValue || !lookupValue) {
        return false;
      }
      const prevMonth = moment().subtract(1, 'month');
      const from = prevMonth.clone().startOf('month');
      const to = prevMonth.clone().endOf('month');
      const granularity = getGranularity(field);
      return fieldValue.isBetween(from, to, granularity, '[]');
    }
  },
  {
    field: FieldType.DateTime,
    lookup: isLastQuarterFieldLookup,
    match: (fieldValue: moment.Moment, lookupValue: boolean, field?: BaseField): boolean => {
      if (!fieldValue || !lookupValue) {
        return false;
      }
      const from = moment().startOf('quarter');
      const to = moment().endOf('quarter');
      const granularity = getGranularity(field);
      return fieldValue.isBetween(from, to, granularity, '[]');
    }
  },
  {
    field: FieldType.DateTime,
    lookup: isCurrentQuarterFieldLookup,
    match: (fieldValue: moment.Moment, lookupValue: boolean, field?: BaseField): boolean => {
      if (!fieldValue || !lookupValue) {
        return false;
      }
      const now = moment();
      const from = now.clone().startOf('quarter');
      const to = now.clone().endOf('quarter');
      const granularity = getGranularity(field);
      return fieldValue.isBetween(from, to, granularity, '[]');
    }
  },
  {
    field: FieldType.DateTime,
    lookup: isPreviousQuarterFieldLookup,
    match: (fieldValue: moment.Moment, lookupValue: boolean, field?: BaseField): boolean => {
      if (!fieldValue || !lookupValue) {
        return false;
      }
      const prevQuarter = moment().subtract(1, 'quarter');
      const from = prevQuarter.clone().startOf('quarter');
      const to = prevQuarter.clone().endOf('quarter');
      const granularity = getGranularity(field);
      return fieldValue.isBetween(from, to, granularity, '[]');
    }
  },
  {
    field: FieldType.DateTime,
    lookup: isLastYearFieldLookup,
    match: (fieldValue: moment.Moment, lookupValue: boolean, field?: BaseField): boolean => {
      if (!fieldValue || !lookupValue) {
        return false;
      }
      const from = moment().startOf('year');
      const to = moment().endOf('year');
      const granularity = getGranularity(field);
      return fieldValue.isBetween(from, to, granularity, '[]');
    }
  },
  {
    field: FieldType.DateTime,
    lookup: isCurrentYearFieldLookup,
    match: (fieldValue: moment.Moment, lookupValue: boolean, field?: BaseField): boolean => {
      if (!fieldValue || !lookupValue) {
        return false;
      }
      const now = moment();
      const from = now.clone().startOf('year');
      const to = now.clone().endOf('year');
      const granularity = getGranularity(field);
      return fieldValue.isBetween(from, to, granularity, '[]');
    }
  },
  {
    field: FieldType.DateTime,
    lookup: isPreviousYearFieldLookup,
    match: (fieldValue: moment.Moment, lookupValue: boolean, field?: BaseField): boolean => {
      if (!fieldValue || !lookupValue) {
        return false;
      }
      const prevYear = moment().subtract(1, 'year');
      const from = prevYear.clone().startOf('year');
      const to = prevYear.clone().endOf('year');
      const granularity = getGranularity(field);
      return fieldValue.isBetween(from, to, granularity, '[]');
    }
  },
  {
    field: FieldType.DateTime,
    lookup: isLastXDaysFieldLookup,
    match: (fieldValue: moment.Moment, lookupValue: number, field?: BaseField): boolean => {
      if (!fieldValue || !lookupValue) {
        return false;
      }
      const from = moment().subtract(lookupValue, 'days');
      const to = moment();
      const granularity = getGranularity(field);
      return fieldValue.isBetween(from, to, granularity, '[]');
    }
  },
  {
    field: FieldType.DateTime,
    lookup: isPreviousXDaysFieldLookup,
    match: (fieldValue: moment.Moment, lookupValue: number, field?: BaseField): boolean => {
      if (!fieldValue || !lookupValue) {
        return false;
      }
      const from = moment().subtract(lookupValue * 2, 'days');
      const to = moment().subtract(lookupValue, 'days');
      const granularity = getGranularity(field);
      return fieldValue.isBetween(from, to, granularity, '[]');
    }
  },
  {
    field: FieldType.DateTime,
    lookup: exactFieldLookup,
    match: (fieldValue: moment.Moment, lookupValue: moment.Moment, field?: BaseField): boolean => {
      if (!fieldValue || !lookupValue) {
        return false;
      }
      const granularity = getGranularity(field);
      return fieldValue.isSame(lookupValue, granularity);
    }
  },
  {
    field: FieldType.DateTime,
    lookup: isNullFieldLookup,
    match: (fieldValue: moment.Moment, lookupValue: boolean): boolean => {
      return lookupValue ? fieldValue === null : fieldValue !== null;
    }
  },
  {
    field: FieldType.DateTime,
    lookup: inFieldLookup,
    match: (fieldValue: moment.Moment, lookupValue: moment.Moment[], field?: BaseField): boolean => {
      if (
        lookupValue === undefined ||
        (lookupValue as unknown) === '' ||
        (isArray(lookupValue) && !lookupValue.length)
      ) {
        return false;
      }

      if (!isArray(lookupValue)) {
        lookupValue = [lookupValue];
      }

      const granularity = getGranularity(field);
      return lookupValue.find(item => item.isSame(fieldValue, granularity)) != undefined;
    }
  }
];

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { AfterViewInit, ChangeDetectorRef, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import clamp from 'lodash/clamp';
import isEqual from 'lodash/isEqual';
import values from 'lodash/values';
import moment from 'moment';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { fromEvent, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { UserActivityListStore, UserActivityType } from '@modules/activities';
import { CustomizeService } from '@modules/customize';
import { MenuSection, MenuService } from '@modules/menu';
import { ModelDescriptionStore, ModelService } from '@modules/model-queries';
import { CurrentEnvironmentStore, CurrentProjectStore, ProjectUserService } from '@modules/projects';
import { getWindowScrollTop, isSet } from '@shared';
var Filters = /** @class */ (function (_super) {
    __extends(Filters, _super);
    function Filters() {
        return _super.call(this, {
            date_add_gte: new FormControl(),
            date_add_lte: new FormControl(),
            activity_type_in: new FormControl(null),
            user: new FormControl(null)
        }) || this;
    }
    Filters.prototype.isSet = function () {
        return values(this.controls).some(function (item) { return isSet(item.value); });
    };
    return Filters;
}(FormGroup));
var UserActivitiesComponent = /** @class */ (function () {
    function UserActivitiesComponent(customizeService, currentProjectStore, currentEnvironmentStore, projectUserService, activatedRoute, modelDescriptionStore, modelService, userActivityListStore, menuService, cd) {
        this.customizeService = customizeService;
        this.currentProjectStore = currentProjectStore;
        this.currentEnvironmentStore = currentEnvironmentStore;
        this.projectUserService = projectUserService;
        this.activatedRoute = activatedRoute;
        this.modelDescriptionStore = modelDescriptionStore;
        this.modelService = modelService;
        this.userActivityListStore = userActivityListStore;
        this.menuService = menuService;
        this.cd = cd;
        this.defaultParams = {};
        this.loading = false;
        this.filters = new Filters();
        this.activityTypeOptions = [
            {
                name: 'All Activity',
                value: null
            },
            {
                name: 'Create Record',
                value: UserActivityType.ModelCreate
            },
            {
                name: 'Update Record',
                value: UserActivityType.ModelUpdate
            },
            {
                name: 'Delete Record',
                value: UserActivityType.ModelDelete
            }
            // {
            //   name: 'Delete Multiple Records',
            //   value: UserActivityType.ModelMassDelete
            // },
            // {
            //   name: 'View Record',
            //   value: UserActivityType.ModelDetail
            // },
            // {
            //   name: 'View Collection',
            //   value: UserActivityType.ModelList
            // },
            // {
            //   name: 'View Dashboard',
            //   value: UserActivityType.DashboardList
            // }
        ];
        this.activityUserOptions = [];
        this.formatDate = 'DD.MM.YYYY';
    }
    UserActivitiesComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.customizeService.setHandler(this);
        this.customizeService.setHandlerInfo(this, {
            breadcrumbs: ['User Activities']
        });
        this.projectUserService
            .get(this.currentProjectStore.instance.uniqueName, this.currentEnvironmentStore.instance.uniqueName)
            .pipe(untilDestroyed(this))
            .subscribe(function (users) {
            _this.activityUserOptions = [
                {
                    name: 'All Members',
                    value: null
                }
            ].concat(users
                .filter(function (user) { return user.user; })
                .map(function (user) {
                return {
                    name: user.user.firstName + ' ' + user.user.lastName,
                    value: user.user.uid
                };
            }));
            _this.cd.markForCheck();
        });
        this.init();
        this.menuService.section = MenuSection.None;
    };
    UserActivitiesComponent.prototype.ngOnDestroy = function () {
        this.customizeService.unsetHandler(this);
        this.menuService.section = MenuSection.Default;
    };
    UserActivitiesComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        fromEvent(window, 'scroll')
            .pipe(untilDestroyed(this))
            .subscribe(function () {
            var scrollTop = getWindowScrollTop();
            var viewportHeight = window.innerHeight;
            var viewportBottom = scrollTop + viewportHeight;
            var contentHeight = document.body.offsetHeight;
            if (contentHeight - viewportBottom <= clamp(viewportHeight, 100, viewportHeight)) {
                _this.onScrollFinished();
            }
        });
    };
    UserActivitiesComponent.prototype.init = function () {
        var _this = this;
        this.activatedRoute.params
            .pipe(switchMap(function (params) {
            var snapshot = _this.activatedRoute.snapshot;
            if (snapshot.data['filter'] == 'model_description') {
                return _this.modelDescriptionStore.getDetail(params['model']).pipe(map(function (modelDescription) {
                    return {
                        modelDescription: modelDescription
                    };
                }));
            }
            else if (snapshot.data['filter'] == 'model') {
                return _this.modelDescriptionStore.getDetail(params['model']).pipe(switchMap(function (modelDescription) {
                    return _this.modelService
                        .getDetail(_this.currentProjectStore.instance, _this.currentEnvironmentStore.instance, modelDescription.modelId, modelDescription.primaryKeyField, params['id'])
                        .pipe(map(function (model) {
                        return {
                            modelDescription: modelDescription,
                            model: model
                        };
                    }));
                }));
            }
            else {
                return of({});
            }
        }), untilDestroyed(this))
            .subscribe(function (deps) {
            var params = {
                order_by: '-date_add',
                activity_type_in: _this.activityTypeOptions
                    .filter(function (item) { return item.value; })
                    .map(function (item) { return item.value; })
                    .join()
            };
            var snapshot = _this.activatedRoute.snapshot;
            if (snapshot.data['filter'] == 'model_description') {
                _this.modelDescription = deps['modelDescription'];
                _this.cd.markForCheck();
                params['object_type'] = ['model', _this.modelDescription.modelId].join('.');
            }
            else if (snapshot.data['filter'] == 'model') {
                _this.model = deps['model'];
                _this.modelDescription = deps['modelDescription'];
                _this.cd.markForCheck();
                params['object_type'] = ['model', _this.modelDescription.modelId].join('.');
                params['object_id'] = snapshot.params['id'];
            }
            _this.defaultParams = params;
            _this.fetch();
        });
        this.initFilters();
    };
    UserActivitiesComponent.prototype.initFilters = function () {
        var _this = this;
        this.filters.valueChanges.pipe(untilDestroyed(this)).subscribe(function () {
            var params = __assign({}, _this.defaultParams, (isSet(_this.filters.controls.date_add_gte.value)
                ? {
                    date_add_gte: moment(_this.filters.controls.date_add_gte.value)
                        .startOf('day')
                        .format('YYYY-MM-DDTHH:mm:ss.SSSZ')
                }
                : undefined), (isSet(_this.filters.controls.date_add_lte.value)
                ? {
                    date_add_lte: moment(_this.filters.controls.date_add_lte.value)
                        .startOf('day')
                        .format('YYYY-MM-DDTHH:mm:ss.SSSZ')
                }
                : undefined), (isSet(_this.filters.controls.activity_type_in.value)
                ? {
                    activity_type_in: _this.filters.controls.activity_type_in.value
                }
                : undefined), (isSet(_this.filters.controls.user.value)
                ? {
                    user: _this.filters.controls.user.value
                }
                : undefined));
            _this.fetch(params);
        });
    };
    UserActivitiesComponent.prototype.fetch = function (params) {
        var _this = this;
        var newParams = params || this.defaultParams;
        if (isEqual(newParams, this.userActivityListStore.params)) {
            return;
        }
        this.loading = true;
        this.cd.markForCheck();
        this.userActivityListStore.projectName = this.currentProjectStore.instance.uniqueName;
        this.userActivityListStore.environmentName = this.currentEnvironmentStore.instance.uniqueName;
        this.userActivityListStore.params = newParams;
        this.userActivityListStore.reset();
        this.userActivityListStore
            .getNext()
            .pipe(untilDestroyed(this))
            .subscribe(function () {
            _this.loading = false;
            _this.cd.markForCheck();
        }, function (error) {
            _this.loading = false;
            _this.error = error;
            _this.cd.markForCheck();
        });
    };
    UserActivitiesComponent.prototype.onScrollFinished = function () {
        this.userActivityListStore.getNext();
    };
    Object.defineProperty(UserActivitiesComponent.prototype, "backParams", {
        get: function () {
            return this.activatedRoute.snapshot.queryParams;
        },
        enumerable: true,
        configurable: true
    });
    // TODO: Fix empty handler
    UserActivitiesComponent.prototype.renameHandler = function (name) { };
    return UserActivitiesComponent;
}());
export { UserActivitiesComponent };

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Injector,
  Input,
  OnDestroy,
  OnInit
} from '@angular/core';
import cloneDeep from 'lodash/cloneDeep';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { delayWhen, switchMap } from 'rxjs/operators';

import { BasePopupComponent } from '@common/popups';

import { processElementItemResources, ViewSettings, ViewSettingsService, ViewSettingsStore } from '@modules/customize';
import { CurrentEnvironmentStore, CurrentProjectStore, Resource } from '@modules/projects';
import { ResourceEditController } from '@modules/projects-components';
import { Template, TemplateService } from '@modules/template';

@Component({
  selector: 'app-configure-resources',
  templateUrl: './configure-resources.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfigureResourcesComponent implements OnInit, OnDestroy {
  @Input() title: string;
  @Input() description: string;
  @Input() resources: Resource[];
  @Input() page: ViewSettings;
  @Input() resourceChanged = new EventEmitter<void>();

  loading = false;
  items: { resource: Resource; template?: Template; updated: boolean }[] = [];
  hasChangedResources = false;

  constructor(
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private templateService: TemplateService,
    private popupComponent: BasePopupComponent,
    private resourceEditController: ResourceEditController,
    private viewSettingsService: ViewSettingsService,
    private viewSettingsStore: ViewSettingsStore,
    private injector: Injector,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.loading = true;
    this.cd.markForCheck();

    this.templateService
      .get()
      .pipe(untilDestroyed(this))
      .subscribe(templates => {
        this.items = this.resources.map(item => {
          return {
            resource: item,
            updated: false,
            template: templates.find(i => i.id == item.templateStubData)
          };
        });
        this.loading = false;
        this.cd.markForCheck();
      });
  }

  ngOnDestroy(): void {}

  openResourceSettings(index: number) {
    const resource = this.items[index].resource;
    const editResource = resource.demo ? undefined : resource;

    this.resourceEditController
      .openEditPopup(resource.typeItem, editResource, {
        resourceNameEditing: true
      })
      .pipe(untilDestroyed(this))
      .subscribe(result => {
        if (!result.resource) {
          return;
        }

        this.items = this.items.map((item, i) => {
          if (i == index) {
            return {
              resource: result.resource,
              updated: true
            };
          } else {
            return item;
          }
        });

        if (resource.demo) {
          this.changePageResource(resource, result.resource);
        } else {
          this.hasChangedResources = true;
        }

        this.cd.markForCheck();
      });
  }

  close() {
    if (this.hasChangedResources) {
      this.resourceChanged.emit();
    }

    this.popupComponent.close();
  }

  isAllResourcesUpdated() {
    return this.items.every(item => item.updated);
  }

  changePageResource(resourceFrom: Resource, resourceTo: Resource) {
    if (!this.page) {
      return;
    }

    this.viewSettingsStore
      .getFirst()
      .pipe(
        switchMap(pages => {
          const processResource = (resourceName: string): string => {
            if (resourceFrom.uniqueName != resourceName) {
              return resourceName;
            }

            return resourceTo.uniqueName;
          };

          pages = pages
            .filter(item => {
              return (
                (this.page.uniqueName && item.uniqueName == this.page.uniqueName) ||
                (this.page.templateInstanceId && item.templateInstanceId == this.page.templateInstanceId)
              );
            })
            .map(page => {
              page = cloneDeep(page);
              processElementItemResources(page, processResource);
              page.usedResources = page.usedResources.filter(item => item.name != resourceFrom.uniqueName);
              return page;
            });

          return this.viewSettingsService.createBulk(
            this.currentProjectStore.instance.uniqueName,
            this.currentEnvironmentStore.instance.uniqueName,
            pages
          );
        }),
        delayWhen(() => this.viewSettingsStore.getFirst(true)),
        untilDestroyed(this)
      )
      .subscribe(() => {
        this.hasChangedResources = true;
      });
  }
}

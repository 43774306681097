import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/browser';
import { RewriteFrames } from '@sentry/integrations';

import { SENTRY_APP_ERROR } from '@core';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

Sentry.init({
  dsn: environment.ravenDSN,
  release: `jet-frontend@${environment.version}`,
  integrations: [new RewriteFrames()],
  ignoreErrors: ['Non-Error exception captured', 'ResizeObserver loop limit exceeded'],
  denyUrls: [/cdn\.paddle\.com/i, /cdn\.ckeditor\.com/i, /node_modules\/plyr\//i, /extensions\//i, /^chrome:\/\//i],
  autoSessionTracking: true
});

Sentry.addGlobalEventProcessor((event, hint) => {
  if (!event.extra || !event.extra[SENTRY_APP_ERROR]) {
    return null;
  }

  return event;
});

if (environment.production) {
  enableProdMode();
}

document.addEventListener('DOMContentLoaded', () => {
  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    // .then(() => {
    //   if ('serviceWorker' in navigator && environment.production) {
    //     navigator.serviceWorker.register('ngsw-worker.js');
    //   }
    // })
    .catch(err => console.error(err));
});

import { Injectable } from '@angular/core';
import { Option, SelectSource } from 'ng-gxselect';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { isSet } from '@shared';

import { Resource } from '../data/resource';
import { CurrentEnvironmentStore } from './current-environment.store';

@Injectable()
export class ResourceSelectSource extends SelectSource {
  allowEmpty = false;
  emptyName = '---';
  filter: (resource: Resource) => boolean;

  private page = 1;
  private totalPages = 1;

  constructor(private currentEnvironmentStore: CurrentEnvironmentStore) {
    super();
  }

  get emptyOption() {
    return {
      value: null,
      name: this.emptyName
    };
  }

  image(uniqueName: string) {
    const resource = this.currentEnvironmentStore.resources.find(item => item.uniqueName == uniqueName);

    if (!resource) {
      return;
    }

    return resource.icon;
  }

  getResources() {
    return this.currentEnvironmentStore.getResourcesFirst().pipe(
      map(resources => {
        if (!resources) {
          return [];
        }

        if (this.filter != undefined) {
          resources = resources.filter(this.filter);
        }

        return resources;
      })
    );
  }

  fetch(searchQuery: string): Observable<Option[]> {
    searchQuery = (searchQuery || '').trim();

    return this.getResources().pipe(
      map(result => {
        this.page += 1;

        const results = result
          .filter(item =>
            isSet(searchQuery)
              ? [item.name, item.uniqueName].some(str => str.toLowerCase().includes(searchQuery.toLowerCase()))
              : true
          )
          .map(item => {
            return {
              value: item.uniqueName,
              name: item.name,
              image: this.image(item.uniqueName)
            };
          });

        if (!this.allowEmpty) {
          return results;
        }

        return [this.emptyOption].concat(results);
      })
    );
  }

  fetchByValue(value: any): Observable<Option> {
    if (this.allowEmpty && value === null) {
      return of(this.emptyOption);
    }

    if (!value) {
      return of(undefined);
    }

    return this.getResources().pipe(
      map(result => {
        const resource = result.find(i => i.uniqueName == value);

        if (!resource) {
          return;
        }

        return {
          value: resource.uniqueName,
          name: resource.name,
          image: this.image(resource.uniqueName)
        };
      })
    );
  }

  isFetchAvailable(): boolean {
    return this.page <= this.totalPages;
  }

  resetPagination() {
    this.page = 1;
    this.totalPages = 1;
  }

  setStaticOptions(options: Option[]) {}
}

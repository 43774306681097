<div class="demo-selector">
  <div class="demo-selector__subtitle">{{ project.name }}</div>
  <div class="demo-selector__title">What Would You Like to try?</div>
  <div class="demo-selector__description">
    <div class="demo-selector__description-inner">
      This is just to get you started. You can always do more later.
    </div>
  </div>
  <div class="demo-selector__items" [class.demo-selector__items_hovered]="itemHovered">
    <div class="demo-selector__items-inner demo-selector__items-inner_wide">
      <a
        [routerLink]="projectHome()"
        [queryParamsHandling]="'merge'"
        class="demo-selector__card"
        [ngClass]="['project-any-job-' + project.uniqueName]"
        (mouseenter)="setItemHovered(true)"
        (mouseleave)="setItemHovered(false)"
        (click)="close()"
      >
        <div class="demo-selector__card-icon icon icon-eye"></div>
        <div class="demo-selector__card-title">Just Want to See Something 🤔</div>
      </a>
      <a
        *ngFor="let item of project.jobs"
        [routerLink]="jobLink(item).link"
        [queryParams]="jobLink(item).queryParams"
        [queryParamsHandling]="'merge'"
        class="demo-selector__card"
        [ngClass]="['project-job-' + project.uniqueName + '-' + item.uniqueName]"
        [appClickEvent]="[
          analyticsEvents.Undocumented.LiveDemoClickIndustryJob,
          { IndustryProjectId: project.uniqueName, IndustryProjectJobId: item.uniqueName }
        ]"
        (mouseenter)="setItemHovered(true)"
        (mouseleave)="setItemHovered(false)"
        (click)="close()"
      >
        <div
          class="demo-selector__card-icon icon"
          [ngClass]="['icon-' + item.icon, 'demo-selector__card-icon_color_' + item.color]"
        ></div>
        <div class="demo-selector__card-title">{{ item.name }}</div>
      </a>
      <a [appLink]="['/demo']" class="demo-selector__back" (click)="close()">
        <div class="demo-selector__back-icon icon icon-arrow_backward_2"></div>
      </a>
    </div>
  </div>
</div>

import { Power4, TweenMax } from 'gsap';
import 'gsap/ScrollToPlugin';
import endsWith from 'lodash/endsWith';
import isArray from 'lodash/isArray';
import isEqual from 'lodash/isEqual';
import startsWith from 'lodash/startsWith';
import { fromEvent, merge, Observable, ReplaySubject, Subject } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';

export function hasClass(element: Element, className: string) {
  return element.className.split(' ').includes(className);
}

export function addClass(element: Element, className: string) {
  if (element && !hasClass(element, className)) {
    element.className += ' ' + className;
  }
}

export function removeClass(element: Element, className: string) {
  if (element && hasClass(element, className)) {
    element.className = element.className
      .split(' ')
      .filter(item => item != className)
      .join(' ');
  }
}

export function toggleClass(element: Element, className: string, value: boolean) {
  if (value) {
    addClass(element, className);
  } else {
    removeClass(element, className);
  }
}

export function isElementInViewport(element, container?: HTMLElement) {
  const rect = element.getBoundingClientRect();

  if (container) {
    const containerBounds = container.getBoundingClientRect();
    return (
      rect.top >= containerBounds.top &&
      rect.left >= containerBounds.left &&
      rect.top < containerBounds.bottom &&
      rect.left < containerBounds.right
    );
  } else {
    return rect.left < window.innerWidth && rect.right > 0 && rect.top < window.innerHeight && rect.bottom > 0;
  }
}

export function getOffset(element, relativeTo = null) {
  let x = 0;
  let y = 0;

  while (element && !isNaN(element.offsetLeft) && !isNaN(element.offsetTop)) {
    x += element.offsetLeft || 0;
    y += element.offsetTop || 0;
    element = element.offsetParent as HTMLElement;

    if (relativeTo && element == relativeTo) {
      break;
    }
  }

  return { top: y, left: x };
}

export function getOffset2(element: Element, relativeTo: Element = null) {
  const elementBounds = element.getBoundingClientRect();

  if (relativeTo) {
    const relativeToBounds = relativeTo.getBoundingClientRect();
    return {
      top: elementBounds.top - relativeToBounds.top + relativeTo.scrollTop,
      left: elementBounds.left - relativeToBounds.left + relativeTo.scrollLeft
    };
  } else {
    return {
      top: elementBounds.top + getWindowScrollingElement().scrollTop,
      left: elementBounds.left + getWindowScrollingElement().scrollLeft
    };
  }
}

export function isElementHasChild(element: Element, child: Element, includeSelf = false) {
  if (!element || !child) {
    return false;
  }

  if (element === child && !includeSelf) {
    return false;
  }

  while (child) {
    if (child === element) {
      return true;
    }

    child = child.parentElement;
  }

  return false;
}

export function isBodyHasChild(element: Element) {
  return isElementHasChild(document.body, element);
}

export function getSize(element) {
  return { width: element.offsetWidth, height: element.offsetHeight };
}

export function encodeURIFragment(str) {
  return encodeURI(str).replace(/\./g, '%2E').replace(/\(/g, '%28').replace(/\)/g, '%29');
}

export function decodeURIComponentSafe(str: string) {
  try {
    return decodeURIComponent(str);
  } catch (e) {
    return str;
  }
}

export function decodeURIFragment(str) {
  return decodeURIComponent(str);
}

export function scrollTo(
  element: Element | Window,
  offset: number,
  duration: number = 0,
  ease = Power4.easeOut
): Observable<void> {
  const finished = new ReplaySubject<void>(1);

  TweenMax.to(element, duration, {
    scrollTo: {
      y: offset,
      onAutoKill: () => finished.next()
    },
    ease: ease,
    onOverwrite: () => finished.next(),
    onComplete: () => finished.next()
  });

  return finished;
}

export function scrollToElement(container, element, duration = 0, offset = 0, ease = Power4.easeOut): Observable<void> {
  const position = getOffset(element, container);
  return scrollTo(container, position.top + offset, duration, ease);
}

export function scrollToElement2(
  container: Element,
  element: Element,
  duration = 0,
  offset = 0,
  ease = Power4.easeOut
): Observable<void> {
  const position = getOffset2(element, container);
  return scrollTo(container, position.top + offset, duration, ease);
}

export function scrollWindowTo(offset: number, duration: number = 0, ease = Power4.easeOut) {
  return scrollTo(window, offset, duration, ease);
}

export function removeElement(element: Element) {
  if (!element) {
    return;
  }

  element.parentNode.removeChild(element);
}

export function removeChildren(element: Element) {
  while (element.firstChild) {
    element.removeChild(element.firstChild);
  }
}

export function unwrapElement(el: HTMLElement) {
  const parent = el.parentNode;
  while (el.firstChild) {
    parent.insertBefore(el.firstChild, el);
  }
  parent.removeChild(el);
}

export function isHttpsUrl(url) {
  return startsWith(url, 'https');
}

export function isHttps() {
  return isHttpsUrl(window.location.href);
}

export function isLocalLocation() {
  const isLocalhost = window.location.hostname == 'localhost';
  const isIP = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(
    window.location.hostname
  );

  return isLocalhost || isIP;
}

export function isProductionLocation() {
  return !isLocalLocation();
}

export function getLocationAppPath(): string {
  const paths = location.pathname.split('/');
  if (paths[1] == 'app' || paths[1] == 'builder') {
    return paths.slice(4).join('/');
  }
}

export function getLocationQueryParams(): { [k: string]: string | string[] } {
  const result: { [k: string]: string | string[] } = {};

  new URLSearchParams(location.search).forEach((v, k) => {
    if (result.hasOwnProperty(k)) {
      if (isArray(result[k])) {
        (result[k] as string[]).push(v);
      } else {
        result[k] = [v];
      }
    } else {
      result[k] = v;
    }
  });

  return result;
}

export function getStyleProperty(el, property) {
  const style = el['currentStyle'] || window.getComputedStyle(el);
  let value = style[property];

  if (endsWith(value, 'px')) {
    value = parseInt(value, 10);
  }

  return value;
}

export function isDescendant(parent, child) {
  if (parent == child) {
    return true;
  }

  let node = child.parentNode;

  while (node != null) {
    if (node == parent) {
      return true;
    }
    node = node.parentNode;
  }

  return false;
}

export function scrollEvent(element, orientation = 'vertical', preventDefault = false): Observable<number> {
  let touchStart: TouchEvent;

  return merge(
    fromEvent<WheelEvent>(element, 'wheel', { passive: false }),
    merge(
      fromEvent<TouchEvent>(element, 'touchstart', { passive: false }),
      fromEvent<TouchEvent>(element, 'touchmove', { passive: false })
    ).pipe(
      filter(e => {
        if (preventDefault) {
          e.preventDefault();
        }

        if (e.type == 'touchstart') {
          touchStart = e;
          return false;
        } else {
          return true;
        }
      })
    )
  ).pipe(
    map(e => {
      if (e.type == 'touchmove') {
        const event = e as TouchEvent;
        const result =
          orientation == 'horizontal'
            ? touchStart.touches[0].clientX - event.touches[0].clientX
            : touchStart.touches[0].clientY - event.touches[0].clientY;
        touchStart = event;
        return result;
      } else {
        const event = e as WheelEvent;

        if (preventDefault) {
          event.preventDefault();
        }

        let delta = orientation == 'horizontal' ? event.deltaX : event.deltaY;

        if (event.deltaMode == 1) {
          delta *= 16;
        }

        return delta;
      }
    })
  );
}

export function getWindowScrollingElement() {
  return document.scrollingElement;
}

export function getCurrentScrollingElement(element: Element) {
  if (!element) {
    return;
  }

  let parent = element.parentElement;

  while (parent) {
    const { overflow } = window.getComputedStyle(parent);
    const hasOverflowScroll = overflow.split(' ').some(item => item === 'auto' || item === 'scroll');

    if (hasOverflowScroll || parent.hasAttribute('xsscrollable')) {
      return parent;
    }

    parent = parent.parentElement;
  }

  return getWindowScrollingElement();
}

export function getWindowScrollTop() {
  return getWindowScrollingElement().scrollTop;
}

export function inputAppend(input, value) {
  if (document['selection']) {
    // IE support
    input.focus();
    const sel = document['selection'].createRange();
    sel.text = value;
  } else if (input.selectionStart || input.selectionStart == '0') {
    // MOZILLA and others
    const startPos = input.selectionStart;
    const endPos = input.selectionEnd;
    input.value = input.value.substring(0, startPos) + value + input.value.substring(endPos, input.value.length);
  } else {
    input.value += value;
  }

  input.dispatchEvent(new Event('input'));
}

export function nodeListToArray<E extends Node = Node>(list: NodeListOf<E>): E[] {
  return Array.prototype.slice.call(list);
}

export function isControlElement(element: Element) {
  return (
    (element instanceof HTMLElement && element.isContentEditable) ||
    [HTMLInputElement, HTMLSelectElement, HTMLTextAreaElement].some(item => element instanceof item)
  );
}

export interface ElementResizeEvent {
  initial: boolean;
}

export function elementResize$(element: HTMLElement, initial = true): Observable<ElementResizeEvent> {
  return Observable.create(observer => {
    if (initial) {
      observer.next({ initial: true });
    }

    const ResizeObserver = window['ResizeObserver'];
    let resizeObserver: any;

    if (ResizeObserver) {
      resizeObserver = new ResizeObserver(() => {
        observer.next({ initial: false });
      });
      resizeObserver.observe(element);
    }

    return () => {
      if (resizeObserver) {
        resizeObserver.disconnect();
      }
    };
  });
}

export function elementSize$(element: HTMLElement): Observable<ClientRect> {
  return elementResize$(element, true).pipe(
    map(() => element.getBoundingClientRect()),
    distinctUntilChanged((lhs, rhs) => lhs.width == rhs.width && lhs.height == rhs.height)
  );
}

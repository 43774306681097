import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { AudioPlayerComponent } from './components/audio-player/audio-player.component';
import { VideoPlayerComponent } from './components/video-player/video-player.component';
import { AudioPlayerDirective } from './directives/audio-player/audio-player.directive';
import { VideoPlayerDirective } from './directives/video-player/video-player.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [AudioPlayerDirective, VideoPlayerDirective, VideoPlayerComponent, AudioPlayerComponent],
  exports: [AudioPlayerDirective, VideoPlayerDirective, VideoPlayerComponent, AudioPlayerComponent]
})
export class PlayerModule {}

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ChangeDetectorRef, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { switchMap } from 'rxjs/operators';
import { ActionControllerService } from '@modules/action-queries';
import { SegueType } from '@modules/actions';
import { SUBMIT_RESULT_OUTPUT, ViewContext, ViewContextElement, ViewSettingsStore } from '@modules/customize';
import { ViewSettingsQueries } from '@modules/customize-utils';
import { createFormFieldFactory } from '@modules/fields';
import { CustomizeBarActionEditForm } from '../../customize-bar-action-edit.form';
var CustomizeBarActionEditTypeLinkComponent = /** @class */ (function () {
    function CustomizeBarActionEditTypeLinkComponent(viewSettingsStore, viewSettingsQueries, actionControllerService, cd) {
        this.viewSettingsStore = viewSettingsStore;
        this.viewSettingsQueries = viewSettingsQueries;
        this.actionControllerService = actionControllerService;
        this.cd = cd;
        this.createField = createFormFieldFactory();
        this.segueTypes = SegueType;
    }
    CustomizeBarActionEditTypeLinkComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.form
            .linkOptions$()
            .pipe(untilDestroyed(this))
            .subscribe(function (result) {
            _this.linkOptions = result.map(function (item) {
                return {
                    option: __assign({}, item, { icon: 'document' })
                };
            }).concat([
                {
                    button: {
                        name: 'add_page',
                        label: 'Add Page',
                        icon: 'plus'
                    },
                    stickyBottom: true,
                    orange: true,
                    large: true
                }
            ]);
            _this.cd.markForCheck();
        });
        this.form
            .linkPage$()
            .pipe(untilDestroyed(this))
            .subscribe(function (viewSettings) {
            _this.page = viewSettings;
            _this.cd.markForCheck();
        });
    };
    CustomizeBarActionEditTypeLinkComponent.prototype.ngOnDestroy = function () { };
    CustomizeBarActionEditTypeLinkComponent.prototype.openPage = function () {
        if (!this.page) {
            return;
        }
        var action = this.form.submit().action;
        action.approve = undefined;
        action.onSuccessActions = [];
        action.onErrorActions = [];
        this.actionControllerService
            .execute(action, {
            context: this.context,
            contextElement: this.contextElement,
            saveResultTo: SUBMIT_RESULT_OUTPUT,
            analyticsSource: this.analyticsSource + '_settings'
        })
            .pipe(untilDestroyed(this))
            .subscribe();
    };
    CustomizeBarActionEditTypeLinkComponent.prototype.onButtonItemClick = function (button) {
        var _this = this;
        if (button.name == 'add_page') {
            this.viewSettingsStore
                .getDistinctName('New Page')
                .pipe(switchMap(function (name) { return _this.viewSettingsQueries.promptCreatePage({ defaultName: name }); }), untilDestroyed(this))
                .subscribe(function (page) {
                if (page) {
                    _this.form.controls.link.patchValue({ type: SegueType.Page, page: page.uniqueName });
                }
            });
        }
    };
    return CustomizeBarActionEditTypeLinkComponent;
}());
export { CustomizeBarActionEditTypeLinkComponent };

<form *ngIf="!loading" [formGroup]="form.form">
  <div class="popup-content resource-popup resource-popup_side">
    <app-popup2-side>
      <div
        *ngIf="environment"
        class="resource-popup__tag"
        [ngClass]="['color_' + environment.color + '_1', 'background-color_' + environment.color + '_2']"
      >
        {{ environment.name }}
      </div>
      <div class="resource-popup__image">
        <div
          class="resource-popup__image-inner"
          [style.background-image]="'/assets/images/resources/' + typeItem.image + '-color.svg' | appDeployCssUrl"
        ></div>
      </div>
      <div class="resource-popup__title">
        Allow Jet Admin to access <br />
        <strong [style.color]="typeItem.color">{{ typeItem.label }}</strong>
      </div>
      <div class="resource-popup__description">
        Sign in to Salesforce to create internal tools on top of your CRM data.
      </div>

      <div class="resource-popup__content">
        <app-page-block *ngIf="resourceNameEditing">
          <app-auto-field
            [form]="form.resourceForm"
            [field]="
              createField({
                name: 'name',
                label: 'Resource Name',
                field: 'CharField',
                description: 'This is how you and your team will see it',
                required: true,
                params: { classes: ['input_fill'] }
              })
            "
          ></app-auto-field>
        </app-page-block>

        <app-page-block [marginSize]="'s'">
          <div class="field">
            <label class="field__label">
              <span class="field__label-text">OAuth Callback URL</span>
              <a
                href="javascript:void(0)"
                class="field__label-link"
                style="margin-left: 4px;"
                (click)="$event.stopPropagation(); copy(completeUrl, 'OAuth Callback URL')"
              >
                <span class="field__label-link-icon icon-duplicate"></span>
                Copy
              </a>
            </label>
            <div class="field__value">
              <div class="input-icon-wrapper">
                <span class="input-icon input-icon_left icon-link"></span>
                <input
                  class="input input_icon-left input_disabled input_fill"
                  [value]="completeUrl"
                  (click)="$event.target.select()"
                  readonly
                />
              </div>
            </div>
          </div>
        </app-page-block>

        <app-page-block>
          <div class="field">
            <div class="field__label">Domain Name</div>
            <div class="field__value">
              <div class="input-group">
                <input
                  type="text"
                  class="input-group__item input-group__item_first input-group__fill input"
                  formControlName="domain"
                  [appAutofocus]="true"
                />
                <div class="input-group__item input-group__item_last input input_disabled">.lightning.force.com</div>
              </div>
            </div>
          </div>
        </app-page-block>

        <app-page-block>
          <app-auto-field
            [form]="form.form"
            [field]="
              createField({
                name: 'key',
                label: 'Consumer Key',
                field: 'CharField',
                placeholder: defaultPlaceholder,
                required: true,
                params: { classes: ['input_fill'] }
              })
            "
          ></app-auto-field>
        </app-page-block>

        <app-page-block>
          <app-auto-field
            [form]="form.form"
            [field]="
              createField({
                name: 'secret',
                label: 'Consumer Secret',
                field: 'CharField',
                placeholder: defaultPlaceholder,
                required: true,
                params: { classes: ['input_fill'] }
              })
            "
          ></app-auto-field>
        </app-page-block>

        <app-page-block>
          <div *ngIf="accountLoading" class="field">
            <div class="field__label">Salesforce Profile</div>
            <div class="field__value">
              <div class="input input_fill">
                <span [class.loading-animation]="true"><span class="stub-text">user@example.com</span></span>
              </div>
            </div>
          </div>
          <div *ngIf="!accountLoading" class="field">
            <div *ngIf="account" class="field__label">
              <span class="field__label-text">Salesforce Profile</span>
              <span class="field__label-right">
                <a
                  *ngIf="form.isAccountParametersValid()"
                  href="javascript:void(0)"
                  class="field__label-link"
                  (click)="getOAuthToken()"
                >
                  <span class="field__label-link-icon icon-repeat"></span>
                  Refresh Salesforce token
                </a>
              </span>
            </div>
            <div *ngIf="account" class="field__value">
              <div class="input input_fill">{{ account }}</div>
            </div>
            <div *ngIf="!account" class="field__value">
              <button
                type="button"
                class="button button_primary button_fill"
                [disabled]="!form.isAccountParametersValid()"
                (click)="getOAuthToken()"
              >
                <span class="button__label">Sign In to Salesforce</span>
              </button>
            </div>
            <div class="field__description">
              Salesforce is a secure partner with Jet Admin. Your credentials are stored on Salesforce side.
            </div>
          </div>
        </app-page-block>

        <app-page-block *ngIf="(form.form | appFormErrors).length">
          <app-resource-settings-submit-error [errors]="form.form | appFormErrors"></app-resource-settings-submit-error>
        </app-page-block>
      </div>

      <div class="popup-content__submit">
        <button
          *ngIf="resourceDelete && resource"
          type="button"
          class="button button_danger popup-content__delete"
          (click)="requestDelete()"
        >
          Delete
        </button>
        <button type="button" class="button popup-content__submit-item" (click)="cancel()">
          Cancel
        </button>

        <button
          type="button"
          class="button button_primary popup-content__submit-item"
          [class.button_disabled]="!form.isValid() || loadingSubmit"
          (click)="submit()"
        >
          <app-loader-small *ngIf="loadingSubmit" class="button__icon button__icon_left"></app-loader-small>
          <span class="button__label">
            <ng-container *ngIf="resource">Update resource</ng-container>
            <ng-container *ngIf="!resource">Add resource</ng-container>
          </span>
        </button>
      </div>

      <ng-container data-side>
        <p class="resource-popup__banner-description">
          Create App in Salesforce to connect to your CRM data. Salesforce > Setup > Apps > App Manager > New Connected
          App
        </p>
        <video
          class="resource-popup__banner-video resource-popup__banner-video_zoom"
          [src]="'/assets/video/salesforce-key.mp4' | appDeployUrl"
          autoplay
          loop
          muted
          [appLightbox]="'/assets/video/salesforce-key.mp4' | appDeployUrl"
          [appLightboxVideo]="true"
          [appLightboxOrigin]="self"
          #self
        ></video>
      </ng-container>
    </app-popup2-side>
  </div>
</form>

<app-resource-settings-stub *ngIf="loading" [typeItem]="typeItem" [sideInfo]="true"></app-resource-settings-stub>

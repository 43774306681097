/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../common/player/components/audio-player/audio-player.component.ngfactory";
import * as i2 from "../../../../../common/player/components/audio-player/audio-player.component";
import * as i3 from "../../../../../shared/pipes/is-set/is-set.pipe";
import * as i4 from "@angular/common";
import * as i5 from "./audio-preview.component";
var styles_AudioPreviewComponent = [];
var RenderType_AudioPreviewComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AudioPreviewComponent, data: {} });
export { RenderType_AudioPreviewComponent as RenderType_AudioPreviewComponent };
function View_AudioPreviewComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-audio-player", [], null, null, null, i1.View_AudioPlayerComponent_0, i1.RenderType_AudioPlayerComponent)), i0.ɵdid(1, 770048, null, 0, i2.AudioPlayerComponent, [i0.ElementRef, i0.Renderer2], { src: [0, "src"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.preview; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_AudioPreviewComponent_0(_l) { return i0.ɵvid(2, [i0.ɵpid(0, i3.IsSetPipe, []), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_AudioPreviewComponent_1)), i0.ɵdid(2, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵppd(3, 1)], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 2, 0, _ck(_v, 3, 0, i0.ɵnov(_v, 0), _co.preview)); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_AudioPreviewComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-audio-preview", [], null, null, null, View_AudioPreviewComponent_0, RenderType_AudioPreviewComponent)), i0.ɵdid(1, 114688, null, 0, i5.AudioPreviewComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AudioPreviewComponentNgFactory = i0.ɵccf("app-audio-preview", i5.AudioPreviewComponent, View_AudioPreviewComponent_Host_0, { preview: "preview" }, {}, []);
export { AudioPreviewComponentNgFactory as AudioPreviewComponentNgFactory };

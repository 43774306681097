import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges
} from '@angular/core';
import * as moment from 'moment';
import { untilDestroyed } from 'ngx-take-until-destroy';

import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { AutomationService, WorkflowBackendRunWithRelations, WorkflowRunStatus } from '@modules/workflow';

@Component({
  selector: 'app-workflow-runs-item',
  templateUrl: './workflow-runs-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WorkflowRunsItemComponent implements OnInit, OnDestroy, OnChanges {
  @Input() item: WorkflowBackendRunWithRelations;

  duration: string;
  statuses = WorkflowRunStatus;

  constructor(
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private automationService: AutomationService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    if (this.item.isActive()) {
      const subscription = this.automationService
        .subscribeWorkflowRun(this.currentProjectStore.instance, this.currentEnvironmentStore.instance, this.item)
        .pipe(untilDestroyed(this))
        .subscribe(result => {
          this.item = result;
          this.cd.markForCheck();
          this.updateDuration();

          if (!result.isActive()) {
            subscription.unsubscribe();
          }
        });
    }
  }

  ngOnDestroy(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    this.updateDuration();
  }

  updateDuration() {
    if (this.item.dateRun && this.item.dateFinished) {
      const seconds = moment(this.item.dateFinished).diff(moment(this.item.dateRun), 'seconds');

      if (seconds == 0) {
        this.duration = undefined;
      } else if (seconds >= 60) {
        this.duration = `${Math.round(seconds / 60)} min.`;
      } else {
        this.duration = `${seconds} sec.`;
      }
    } else {
      this.duration = undefined;
    }

    this.cd.markForCheck();
  }
}

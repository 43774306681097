export enum ResourceType {
  JetApp = 'jet_app',
  JetBridge = 'jet_bridge',
  MessagesAPI = 'messages_api',
  RestAPI = 'rest_api',
  GraphQL = 'graphql',
  AzureBlob = 'azure_blob',
  AmazonS3 = 'amazon_s3',
  SupabaseStorage = 'supabase_storage',
  Airtable = 'airtable',
  SmartSuite = 'smart_suite',
  Firebase = 'firebase',
  Xano = 'xano'
}

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import * as JSONEditor from 'jsoneditor';
import isArray from 'lodash/isArray';
import isEqual from 'lodash/isEqual';
import isObject from 'lodash/isObject';
import isPlainObject from 'lodash/isPlainObject';
import truncate from 'lodash/truncate';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, delay, filter, map } from 'rxjs/operators';

import { EmbedScripts, ScriptsService } from '@core';
import { FieldType, JsonStructureNode, registerFieldComponent } from '@modules/fields';
import { controlDisabled, isEmptyArray, isSet } from '@shared';

import { createJsonStructureNodeControl } from '../../utils/json-structure';
import { FieldComponent } from '../field/field.component';

@Component({
  selector: 'app-json-field',
  templateUrl: 'json-field.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class JsonFieldComponent extends FieldComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  @ViewChild('editor_element') editorElement: ElementRef;

  formSubscription: Subscription;
  editor: any;
  editorChanged = new Subject<void>();
  rootNode: JsonStructureNode;
  structureForm: FormGroup;
  structureFormSubscriptions: Subscription[] = [];
  initStructureSubscription: Subscription;
  structureUpdating = false;
  valueChangeProgrammatically = false;

  constructor(private scriptsService: ScriptsService, private cd: ChangeDetectorRef) {
    super();
  }

  ngOnInit(): void {
    if (this.form) {
      this.initForm();
    } else {
      this.setValue();
    }

    this.initStructure();
  }

  ngOnDestroy(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['form']) {
      this.initForm();
    }

    if (changes['value']) {
      this.setValue();
    }
  }

  ngAfterViewInit(): void {
    this.initEditor();
    this.setValue();

    this.scriptsService
      .loadSingleton(EmbedScripts.CKEditor)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        const CKEDITOR = window['CKEDITOR'];
        CKEDITOR.disableAutoInline = true;
        CKEDITOR.editorConfig = config => {
          config.versionCheck = false;
        };
      });
  }

  initEditor() {
    if (!this.editorElement || this.editor) {
      return;
    }

    this.editorChanged.pipe(debounceTime(300), untilDestroyed(this)).subscribe(() => this.updateValue());

    this.editor = new JSONEditor(this.editorElement.nativeElement, {
      mode: 'tree',
      modes: ['tree', 'text'],
      search: false,
      enableSort: false,
      enableTransform: false,
      onChange: () => this.editorChanged.next()
    });
  }

  initStructure() {
    if (this.initStructureSubscription) {
      this.initStructureSubscription.unsubscribe();
      this.initStructureSubscription = undefined;
    }

    if (!this.field.params['display_fields']) {
      this.structureForm = undefined;
      this.rootNode = undefined;
      this.cd.markForCheck();

      this.structureFormSubscriptions.forEach(item => item.unsubscribe());
      this.structureFormSubscriptions = [];

      return;
    }

    const rootNode = this.field.params['structure'] as JsonStructureNode;

    if (!rootNode || !rootNode['type']) {
      this.structureForm = undefined;
      this.rootNode = undefined;
      this.cd.markForCheck();

      this.structureFormSubscriptions.forEach(item => item.unsubscribe());
      this.structureFormSubscriptions = [];

      return;
    }

    rootNode.name = null;
    rootNode.label = null;

    this.currentValue$
      .pipe(
        map(value => this.parseValue(value)),
        untilDestroyed(this)
      )
      .subscribe(value => {
        try {
          const existingControl = this.structureForm ? this.structureForm.controls['null'] : undefined;

          this.structureUpdating = true;
          const control = createJsonStructureNodeControl(rootNode, { existingControl: existingControl, value: value });
          this.structureUpdating = false;

          if (!existingControl || existingControl !== control) {
            this.structureForm = new FormGroup(control ? { null: control } : {});

            const structureFormSubscriptions = [];
            structureFormSubscriptions.push(
              this.structureForm.valueChanges
                .pipe(
                  filter(() => !this.structureUpdating),
                  untilDestroyed(this)
                )
                .subscribe(() => this.updateValue())
            );

            if (this.control) {
              structureFormSubscriptions.push(
                controlDisabled(this.control)
                  .pipe(untilDestroyed(this))
                  .subscribe(disabled => {
                    if (disabled && !this.structureForm.disabled) {
                      this.structureForm.disable();
                    } else if (!disabled && this.structureForm.disabled) {
                      this.structureForm.enable();
                    }
                  })
              );
            }

            this.structureFormSubscriptions = structureFormSubscriptions;
          }

          this.rootNode = rootNode;
          this.cd.markForCheck();
        } catch (e) {
          console.error(e);
          this.structureUpdating = false;
        }
      });
  }

  initForm() {
    if (this.formSubscription) {
      this.formSubscription.unsubscribe();
    }

    if (this.control) {
      this.formSubscription = this.control.valueChanges.pipe(delay(0), untilDestroyed(this)).subscribe(() => {
        if (this.valueChangeProgrammatically) {
          this.valueChangeProgrammatically = false;
          return;
        }

        this.setValue();
      });
    }

    this.setValue();
  }

  parseValue(value: any) {
    if (isSet(value) && !isObject(value)) {
      try {
        return JSON.parse(value);
      } catch (e) {}
    }

    return value;
  }

  parseCurrentValue() {
    return this.parseValue(this.currentValue);
  }

  setValue() {
    const value = this.parseCurrentValue();
    const widgetValue = this.getWidgetValue();

    if (isEqual(value, widgetValue)) {
      return;
    }

    this.setWidgetValue(value);
  }

  getWidgetValue(): any {
    if (this.editor) {
      try {
        return this.editor.get();
      } catch (e) {
        return null;
      }
    } else if (this.structureForm) {
      return this.structureForm.value['null'];
    }
  }

  setWidgetValue(value: any) {
    if (this.editor) {
      this.editor.update(value);
    } else if (this.structureForm) {
      try {
        this.structureForm.patchValue({ null: value }, { emitEvent: false });
      } catch (e) {}
    }
  }

  updateValue() {
    if (this.control) {
      const value = this.getWidgetValue();
      this.valueChangeProgrammatically = true;
      this.control.patchValue(value);
    }
  }

  serializeValue(value: any): any {
    if (isPlainObject(value) || isArray(value)) {
      try {
        return truncate(JSON.stringify(value), { length: 250 });
      } catch (e) {}
    }

    return value;
  }

  onWheel(e: MouseWheelEvent) {
    const container: Element = this.editor['scrollableContent'];

    if (!container) {
      return;
    }

    const scrollTop = container.scrollTop;
    const scrollLeft = container.scrollLeft;
    const edgeX = container.scrollWidth - container.clientWidth;
    const edgeY = container.scrollHeight - container.clientHeight;
    const verticalUp = e.deltaY < 0 && scrollTop > 0;
    const verticalDown = e.deltaY > 0 && scrollTop < edgeY;
    const horizontalLeft = e.deltaX < 0 && scrollLeft > 0;
    const horizontalRight = e.deltaX > 0 && scrollLeft < edgeX;

    if (verticalUp || verticalDown || horizontalLeft || horizontalRight) {
      e.stopPropagation();
    }
  }

  isEmpty(value: any): boolean {
    return !isSet(value);
  }
}

registerFieldComponent(FieldType.JSON, JsonFieldComponent);

import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-oauth-response',
  templateUrl: './oauth-response.component.html'
})
export class OAuthResponseComponent implements OnInit, OnDestroy {
  constructor(private activatedRoute: ActivatedRoute) {}

  ngOnInit() {
    const snapshot = this.activatedRoute.snapshot;
    const uid = snapshot.queryParams['uid'];
    const params = {};
    const paramsRegex = /^(\w+)=(.+)$/.exec(snapshot.fragment || '');

    if (paramsRegex) {
      params[paramsRegex[1]] = decodeURIComponent(paramsRegex[2].replace(/\+/g, ' '));
    }

    if (params['msg']) {
      const msg = JSON.parse(params['msg']);
      window.opener.postMessage({ type: 'oauth_response', uid: uid, params: msg }, '*');
    }

    window.close();
  }

  ngOnDestroy(): void {}
}

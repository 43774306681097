var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as moment from 'moment';
import { parseTime } from '@shared';
import { defaultDateFormat, momentDateEditFormats, momentDateFormats } from './date-format';
import { defaultTimeFormat, isClock12TimeEditFormat, isClock12TimeFormat } from './time-format';
import { momentTimeEditFormats, momentTimeFormats } from './time-format';
export function exposeLocalizedFormat(format) {
    var _longDateFormat = moment.localeData()['_longDateFormat'];
    if (!_longDateFormat[format]) {
        return format;
    }
    return _longDateFormat[format];
}
export function getDateFieldMomentFormat(field, edit, exposeLocalized) {
    if (exposeLocalized === void 0) { exposeLocalized = false; }
    var params = __assign({ date: true, time: true }, field.params);
    var dateFormat = params['date_format'] || defaultDateFormat;
    if (edit) {
        if (momentDateEditFormats[dateFormat]) {
            if (exposeLocalized) {
                return exposeLocalizedFormat(momentDateEditFormats[dateFormat]);
            }
            else {
                return momentDateEditFormats[dateFormat];
            }
        }
        else {
            if (exposeLocalized) {
                return exposeLocalizedFormat(dateFormat);
            }
            else {
                return dateFormat;
            }
        }
    }
    else {
        if (momentDateFormats[dateFormat]) {
            return momentDateFormats[dateFormat];
        }
        else {
            return dateFormat;
        }
    }
}
export function isTimeFieldClock12Format(field, edit) {
    var params = __assign({ date: true, time: true }, field.params);
    var timeFormat = params['time_format'] || defaultTimeFormat;
    if (edit) {
        return isClock12TimeEditFormat(timeFormat);
    }
    else {
        return isClock12TimeFormat(timeFormat);
    }
}
export function getTimeFieldMomentFormat(field, edit) {
    var params = __assign({ date: true, time: true }, field.params);
    var timeFormat = params['time_format'] || defaultTimeFormat;
    if (edit) {
        if (momentTimeEditFormats[timeFormat]) {
            return momentTimeEditFormats[timeFormat];
        }
        else {
            return timeFormat;
        }
    }
    else {
        if (momentTimeFormats[timeFormat]) {
            return momentTimeFormats[timeFormat];
        }
        else {
            return timeFormat;
        }
    }
}
export function isDateTimeFieldClock12Format(field, edit) {
    var params = __assign({ date: true, time: true }, field.params);
    if (params['time']) {
        return isTimeFieldClock12Format(field, edit);
    }
    return false;
}
export function getDateTimeFieldMomentFormat(field, edit, exposeLocalized) {
    if (exposeLocalized === void 0) { exposeLocalized = false; }
    var params = __assign({ date: true, time: true }, field.params);
    var parts = [getDateFieldMomentFormat(field, edit, exposeLocalized)];
    if (params['time']) {
        parts.push(getTimeFieldMomentFormat(field, edit));
    }
    return parts.join(' ');
}
export function formatDateTimeField(value, field) {
    if (!value) {
        return;
    }
    var params = __assign({ date: true, time: true }, field.params);
    var relative = params['relative'];
    // TODO: Optimize when relative=false
    var valueDate = moment(value);
    var valueDateStart = valueDate.clone().startOf('day');
    var nowDate = moment();
    var nowDateStart = nowDate.clone().startOf('day');
    var daysDiff = Math.abs(nowDateStart.diff(valueDateStart, 'days'));
    if (relative && daysDiff < 7) {
        if (params['time']) {
            return valueDate.from(nowDate);
        }
        else {
            if (daysDiff == 0) {
                return 'Today';
            }
            else {
                return valueDateStart.from(nowDateStart);
            }
        }
    }
    else {
        var format = getDateTimeFieldMomentFormat(field, false);
        return valueDate.format(format);
    }
}
export function formatTimeField(value, field) {
    if (!value) {
        return;
    }
    var params = __assign({ date: true, time: true }, field.params);
    var relative = params['relative'];
    var valueDate = parseTime(value);
    if (relative) {
        return valueDate.fromNow();
    }
    else {
        var format = getTimeFieldMomentFormat(field, false);
        return valueDate.format(format);
    }
}

import { ChangeDetectorRef, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { debounceTime, map, startWith, switchMap } from 'rxjs/operators';
import { ActionService } from '@modules/action-queries';
import { ViewContext, ViewContextElement } from '@modules/customize';
import { Workflow } from '@modules/workflow';
import { CustomizeBarContext } from '../../../services/customize-bar-context/customize-bar.context';
import { WorkflowEditContext } from '../../../services/workflow-edit-context/workflow-edit.context';
import { CustomizeWorkflowResultComponent } from '../customize-workflow-result/customize-workflow-result.component';
var WorkflowResultComponent = /** @class */ (function () {
    function WorkflowResultComponent(customizeBarContext, workflowEditContext, actionService, cd) {
        this.customizeBarContext = customizeBarContext;
        this.workflowEditContext = workflowEditContext;
        this.actionService = actionService;
        this.cd = cd;
        this.workflowEditable = false;
        this.connectorTop = true;
        this.customizeComponentData$ = new BehaviorSubject(undefined);
        this.customizing$ = new BehaviorSubject(false);
        this.outputs = [];
        this.outputArray = false;
    }
    WorkflowResultComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.initOutputs();
        combineLatest(this.customizeComponentData$, this.customizeBarContext.settingsComponents$)
            .pipe(debounceTime(10), map(function (_a) {
            var customizeComponentData = _a[0], components = _a[1];
            return customizeComponentData && components[0] === customizeComponentData;
        }), startWith(false))
            .pipe(untilDestroyed(this))
            .subscribe(function (value) { return _this.customizing$.next(value); });
    };
    WorkflowResultComponent.prototype.ngOnDestroy = function () { };
    WorkflowResultComponent.prototype.initOutputs = function () {
        var _this = this;
        if (this.resultOutputs && this.resultOutputs.length) {
            this.outputs = this.resultOutputs;
            this.outputArray = false;
            this.cd.markForCheck();
        }
        else {
            this.workflowEditContext
                .state$()
                .pipe(switchMap(function () { return _this.actionService.getWorkflowOutputs(_this.workflow); }), untilDestroyed(this))
                .subscribe(function (result) {
                _this.outputs = result.outputs;
                _this.outputArray = result.arrayOutput;
                _this.cd.markForCheck();
            });
        }
    };
    WorkflowResultComponent.prototype.customize = function () {
        var _this = this;
        if (this.customizing$.value) {
            return;
        }
        var dynamicComponent = {
            component: CustomizeWorkflowResultComponent,
            inputs: {
                result: this.workflow ? this.workflow.result : undefined,
                resultOutputs: this.resultOutputs,
                workflowEditable: this.workflowEditable,
                context: this.context,
                contextElement: this.contextElement
            },
            outputs: {
                resultChange: [
                    function (result) {
                        _this.workflow.result = result;
                        _this.cd.markForCheck();
                        _this.workflowEditContext.markChanged();
                    }
                ],
                closeCustomize: [
                    function () {
                        _this.customizeBarContext.closeSettingsComponent(dynamicComponent);
                    }
                ]
            }
        };
        this.customizeBarContext.setSettingsComponent(dynamicComponent);
        this.customizeComponentData$.next(dynamicComponent);
    };
    return WorkflowResultComponent;
}());
export { WorkflowResultComponent };

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  OnDestroy,
  Output,
  ViewChild
} from '@angular/core';
import { Power2, TimelineMax } from 'gsap';
import { untilDestroyed } from 'ngx-take-until-destroy';

import { BasePopupComponent } from '@common/popups';

import { GuideService } from '../../services/guide/guide.service';
import { GuideSectionStore } from '../../stores/guide-section.store';

@Component({
  selector: 'app-onboarding-popup',
  templateUrl: './onboarding-popup.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OnboardingPopupComponent implements OnDestroy, AfterViewInit {
  @Output() closed = new EventEmitter<void>();
  @ViewChild('root') root: ElementRef;
  @ViewChild('background') background: ElementRef;

  tl = new TimelineMax();
  animating = false;

  constructor(
    private popupComponent: BasePopupComponent,
    private guideSectionStore: GuideSectionStore,
    private guideService: GuideService
  ) {}

  ngAfterViewInit(): void {
    this.show();
  }

  ngOnDestroy(): void {
    this.closed.emit();
  }

  show() {
    if (this.animating) {
      return;
    }

    this.animating = true;
    this.tl
      .clear()
      .fromTo(
        this.background.nativeElement,
        0.6,
        {
          opacity: 0
        },
        {
          opacity: 1,
          ease: Power2.easeOut
        },
        1.0
      )
      .fromTo(
        this.root.nativeElement,
        0.6,
        {
          scale: 0,
          opacity: 0
        },
        {
          scale: 1,
          opacity: 1,
          ease: Power2.easeOut
        },
        1.0
      )
      .add(() => {
        this.animating = false;
      });
  }

  close() {
    if (this.animating) {
      return;
    }

    this.animating = true;
    this.tl
      .clear()
      .fromTo(
        this.background.nativeElement,
        0.6,
        {
          opacity: 1
        },
        {
          opacity: 0,
          ease: Power2.easeOut
        }
      )
      .fromTo(
        this.root.nativeElement,
        0.6,
        {
          scale: 1,
          opacity: 1
        },
        {
          scale: 0,
          opacity: 0,
          ease: Power2.easeOut
        },
        0
      )
      .add(() => {
        this.animating = false;
        this.popupComponent.close();
      });
  }

  get setupGuideLink() {
    return ['guide'];
  }

  getStarted() {
    this.guideSectionStore
      .getTasks()
      .pipe(untilDestroyed(this))
      .subscribe(tasks => {
        const task = tasks.find(item => item.uniqueName == 'customize_menu');
        this.guideService.setCurrentTask(task);
      });

    this.close();
  }
}

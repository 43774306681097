import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable, throwError } from 'rxjs';
import { catchError, delayWhen, map } from 'rxjs/operators';

import { FormUtils } from '@common/form-utils';
import { Domain, DomainService } from '@modules/domain';
import { DNSCNameRecord, EmailAddressService } from '@modules/emails';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { controlValue, isSet } from '@shared';

@Injectable()
export class EmailAddressEditForm extends FormGroup {
  domain: Domain;

  controls: {
    email_name: FormControl;
    email_from: FormControl;
  };

  constructor(
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private formUtils: FormUtils,
    private domainService: DomainService,
    private emailAddressService: EmailAddressService
  ) {
    super({
      email_name: new FormControl(''),
      email_from: new FormControl('', [Validators.required, Validators.email])
    });

    controlValue(this.controls.email_name).subscribe(value => {
      const email = isSet(this.domain) ? `${value}@${this.domain.domain}` : '';
      this.controls.email_from.patchValue(email);
    });
  }

  init(domain: Domain) {
    this.domain = domain;
  }

  initiateCustomEmail(): Observable<DNSCNameRecord[]> {
    return this.emailAddressService.initiateCustomEmail(
      this.currentProjectStore.instance.uniqueName,
      this.currentEnvironmentStore.instance.uniqueName,
      this.domain.domain
    );
  }

  setupCustomEmail(): Observable<boolean> {
    return this.emailAddressService
      .setupCustomEmail(
        this.currentProjectStore.instance.uniqueName,
        this.currentEnvironmentStore.instance.uniqueName,
        this.domain.domain,
        this.controls.email_name.value
      )
      .pipe(
        map(() => true),
        delayWhen(() => this.currentProjectStore.getFirst(true)),
        catchError(error => {
          this.formUtils.showFormErrors(this, error);
          return throwError(error);
        })
      );
  }

  deleteCustomEmail(): Observable<boolean> {
    return this.emailAddressService
      .deleteCustomEmail(
        this.currentProjectStore.instance.uniqueName,
        this.currentEnvironmentStore.instance.uniqueName,
        this.domain.domain
      )
      .pipe(
        map(() => true),
        delayWhen(() => this.currentProjectStore.getFirst(true)),
        catchError(error => {
          this.formUtils.showFormErrors(this, error);
          return throwError(error);
        })
      );
  }
}

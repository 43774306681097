import { isSet } from '../common/common';

export const booleanTrueValues = ['1', 'true', 'yes'];
export const booleanFalseValues = ['0', 'false', 'no'];

export function parseBoolean(value: any): boolean {
  if (!isSet(value)) {
    return false;
  }

  value = String(value).toLowerCase();

  if (booleanTrueValues.includes(value)) {
    return true;
  } else if (booleanFalseValues.includes(value)) {
    return false;
  } else {
    return !!value;
  }
}

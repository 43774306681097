import { AfterViewInit, ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import cloneDeep from 'lodash/cloneDeep';

import { ThemeService } from '@modules/theme';

import { BaseChartComponent } from '../base-chart/base-chart.component';

@Component({
  selector: 'app-polar-area-chart',
  templateUrl: './polar-area-chart.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PolarAreaChartComponent extends BaseChartComponent implements OnChanges, AfterViewInit {
  @Input() animate = true;
  @Input() legend = true;

  constructor(protected themeService: ThemeService) {
    super(themeService);
  }

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);

    if (['legend'].some(item => changes.hasOwnProperty(item))) {
      this.initChart();
    }
  }

  chartOptions() {
    return {
      type: 'polarArea'
    };
  }

  lineChartData() {
    if (!this.datasetsCurrent) {
      return [];
    }

    return this.datasetsCurrent.map(dataset => {
      const instance = cloneDeep(dataset.dataset);
      const backgroundColor = this.segmentColors(false, dataset.dataset.length).map((item, i) => {
        if (instance[i] && instance[i].color) {
          return instance[i].color;
        }
        return item;
      });

      return {
        data: instance.map(item => item.value),
        label: dataset.name,

        backgroundColor: backgroundColor,
        borderWidth: 2,
        borderColor: this.themeService.theme == 'dark' ? '#2C343A' : '#fff',
        // hoverBackgroundColor: colors,
        // hoverBorderWidth: 2,
        hoverBorderColor: this.themeService.theme == 'dark' ? '#2C343A' : '#fff'
      };
    });
  }

  lineChartOptions() {
    const parentWidth = this.chartElement.nativeElement.parentNode.offsetWidth;

    return {
      responsive: true,
      maintainAspectRatio: false,
      animation: this.animate
        ? {
            duration: 1000,
            easing: 'easeInOutBack'
          }
        : false,
      legend: this.legend
        ? {
            position: parentWidth == 0 || parentWidth >= 300 ? 'right' : 'bottom',
            labels: {
              fontColor: '#7D91A5',
              fontSize: 10,
              fontFamily: "'Fira Sans', sans-serif",
              usePointStyle: true,
              padding: 8,
              filter: (item, chart) => {
                return item['index'] < 8;
              }
            }
          }
        : false,
      tooltips: {
        callbacks: {
          label: (tooltipItem, data) => this.tooltipLabel(tooltipItem, data, 1)
        }
      }
    };
  }

  onThemeChange() {
    this.chart.data.datasets = this.lineChartData();
    this.chart.update();
  }
}

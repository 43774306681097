var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FormControl } from '@angular/forms';
import { controlValue } from '@shared';
var ParameterNameControl = /** @class */ (function (_super) {
    __extends(ParameterNameControl, _super);
    function ParameterNameControl(formState, validatorOrOpts, asyncValidator) {
        var _this = _super.call(this, formState, validatorOrOpts, asyncValidator) || this;
        controlValue(_this).subscribe(function (value) {
            var cleanNameValue = _this.cleanValue(value);
            if (cleanNameValue != value) {
                _this.setValue(cleanNameValue);
            }
        });
        return _this;
    }
    ParameterNameControl.prototype.cleanValue = function (value) {
        if (typeof value == 'string') {
            // value = value.replace(/[^\w]/g, '_').replace(/^\d/, '_');
        }
        return value;
    };
    return ParameterNameControl;
}(FormControl));
export { ParameterNameControl };

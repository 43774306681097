/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./section-footer.component";
var styles_SectionFooterComponent = [];
var RenderType_SectionFooterComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SectionFooterComponent, data: {} });
export { RenderType_SectionFooterComponent as RenderType_SectionFooterComponent };
export function View_SectionFooterComponent_0(_l) { return i0.ɵvid(2, [i0.ɵncd(null, 0)], null, null); }
export function View_SectionFooterComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-section-footer", [], null, null, null, View_SectionFooterComponent_0, RenderType_SectionFooterComponent)), i0.ɵdid(1, 114688, null, 0, i1.SectionFooterComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SectionFooterComponentNgFactory = i0.ɵccf("app-section-footer", i1.SectionFooterComponent, View_SectionFooterComponent_Host_0, {}, {}, ["*"]);
export { SectionFooterComponentNgFactory as SectionFooterComponentNgFactory };

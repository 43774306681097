var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ChangeDetectorRef, Injector, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { asyncScheduler, of } from 'rxjs';
import { distinctUntilChanged, map, skip, throttleTime } from 'rxjs/operators';
import { ActionControllerService } from '@modules/action-queries';
import { CustomizeService, ElementType, FieldElementItem, registerElementComponent, ViewContextElement } from '@modules/customize';
import { BaseElementComponent } from '@modules/customize-elements';
import { applyParamInput$, getFieldDescriptionByType, getValidatorByType, LOADING_VALUE, ParameterField } from '@modules/fields';
import { ModelDescriptionStore } from '@modules/model-queries';
import { getDefaultValue } from '@modules/models';
import { CurrentProjectStore } from '@modules/projects';
import { controlValue } from '@shared';
import { CustomPagePopupComponent } from '../custom-page-popup/custom-page-popup.component';
export var VALUE_OUTPUT = 'value';
var STANDALONE_FORM_CONTROL = 'field';
function getElementStateField(state) {
    return {
        field: state.field ? state.field.serialize() : undefined
    };
}
function getElementStateInfo(state) {
    return {
        name: state.name,
        field: state.field ? state.field.field : undefined
    };
}
function getElementStateOutputs(state) {
    return {
        field: state.field ? state.field.field : undefined,
        params: state.field ? state.field.params : undefined
    };
}
function getElementStateValue(state) {
    return {
        field: state.field ? state.field.field : undefined,
        valueInput: state.valueInput ? state.valueInput.serialize() : undefined
    };
}
function getElementStateDisabled(state) {
    return {
        disableInput: state.disableInput ? state.disableInput.serialize() : undefined
    };
}
function getElementStateValidators(state) {
    return state.field
        ? {
            required: state.field.required,
            validatorType: state.field.validatorType,
            validatorParams: state.field.validatorParams
        }
        : {};
}
var FieldFormControl = /** @class */ (function (_super) {
    __extends(FieldFormControl, _super);
    function FieldFormControl() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    FieldFormControl.prototype.init = function (options) {
        if (options === void 0) { options = {}; }
        this.field = options.field;
    };
    FieldFormControl.prototype.cleanValue = function (value) {
        if (!this.field) {
            return value;
        }
        var fieldDescription = getFieldDescriptionByType(this.field.field);
        if (fieldDescription.cleanValue) {
            value = fieldDescription.cleanValue(value, __assign({}, this.field, { field: this.field.field, params: this.field.params, description: undefined }));
        }
        return value;
    };
    FieldFormControl.prototype.patchValue = function (value, options) {
        value = this.cleanValue(value);
        _super.prototype.patchValue.call(this, value, options);
    };
    FieldFormControl.prototype.setValue = function (value, options) {
        value = this.cleanValue(value);
        _super.prototype.setValue.call(this, value, options);
    };
    return FieldFormControl;
}(FormControl));
var AutoFieldElementComponent = /** @class */ (function (_super) {
    __extends(AutoFieldElementComponent, _super);
    function AutoFieldElementComponent(customizeService, modelDescriptionStore, currentProjectStore, actionControllerService, viewContextElement, injector, cd, popup) {
        var _a;
        var _this = _super.call(this) || this;
        _this.customizeService = customizeService;
        _this.modelDescriptionStore = modelDescriptionStore;
        _this.currentProjectStore = currentProjectStore;
        _this.actionControllerService = actionControllerService;
        _this.viewContextElement = viewContextElement;
        _this.injector = injector;
        _this.cd = cd;
        _this.popup = popup;
        _this.state = {};
        _this.control = new FieldFormControl();
        _this.form = new FormGroup((_a = {}, _a[STANDALONE_FORM_CONTROL] = _this.control, _a));
        _this.updateOutputSubscriptions = [];
        _this.baseValidator = [];
        _this.loadingValue = false;
        return _this;
    }
    AutoFieldElementComponent.prototype.ngOnInit = function () {
        this.customizeEnabled$ = this.customizeService.enabled$.pipe(map(function (item) { return !!item; }));
        this.initContext();
        this.elementOnChange(this.element);
        this.trackChanges();
    };
    AutoFieldElementComponent.prototype.ngOnDestroy = function () { };
    AutoFieldElementComponent.prototype.ngOnChanges = function (changes) {
        if (changes.element && !changes.element.firstChange) {
            this.viewContextElement.initInfo({ name: this.element.name, element: this.element }, true);
        }
        if (changes.element) {
            this.elementOnChange(this.element);
        }
    };
    AutoFieldElementComponent.prototype.trackChanges = function () {
        var _this = this;
        this.element$
            .pipe(map(function (element) { return _this.getElementState(element); }), untilDestroyed(this))
            .subscribe(function (state) {
            _this.onStateUpdated(state);
            _this.state = state;
        });
    };
    AutoFieldElementComponent.prototype.getElementState = function (element) {
        var field = cloneDeep(element.formField);
        field.name = STANDALONE_FORM_CONTROL;
        field.params = __assign({}, field.params, { classes: ['input_fill', 'select_fill'] });
        return {
            element: element,
            settings: cloneDeep(element.settings),
            readonly: element.settings ? !element.settings.editable : false,
            valueInput: element.settings && element.settings.valueInput,
            disableInput: element.disableInput,
            name: element.name,
            field: field,
            tooltip: element.tooltip
        };
    };
    AutoFieldElementComponent.prototype.onStateUpdated = function (state) {
        if (!isEqual(getElementStateField(state), getElementStateField(this.state))) {
            this.updateField(state);
        }
        if (!isEqual(getElementStateInfo(state), getElementStateInfo(this.state))) {
            this.updateContextInfo(state);
        }
        if (!isEqual(getElementStateOutputs(state), getElementStateOutputs(this.state))) {
            this.updateContextOutputs(state);
            this.updateContextActions(state);
        }
        if (!isEqual(getElementStateValue(state), getElementStateValue(this.state))) {
            this.updateDefaultValue(state);
            this.updateValueOutput();
        }
        if (!isEqual(getElementStateDisabled(state), getElementStateDisabled(this.state))) {
            this.updateDisabled(state);
        }
        if (!isEqual(getElementStateValidators(state), getElementStateValidators(this.state))) {
            this.updateValidators(state);
        }
    };
    AutoFieldElementComponent.prototype.updateField = function (state) {
        var field = state.field
            ? {
                field: state.field.field,
                params: state.field.params
            }
            : undefined;
        this.control.init({ field: field });
    };
    AutoFieldElementComponent.prototype.updateDefaultValue = function (state) {
        var _this = this;
        if (this.defaultValueSubscription) {
            this.defaultValueSubscription.unsubscribe();
            this.defaultValueSubscription = undefined;
        }
        var value$;
        var fieldTypeDefault = getDefaultValue(state.settings);
        if (state.valueInput) {
            value$ = applyParamInput$(state.valueInput, {
                context: this.context,
                defaultValue: fieldTypeDefault,
                handleLoading: true
            }).pipe(distinctUntilChanged(function (lhs, rhs) { return isEqual(lhs, rhs); }));
        }
        else {
            value$ = of(fieldTypeDefault);
        }
        this.defaultValueSubscription = value$.pipe(untilDestroyed(this)).subscribe(function (value) {
            _this.setControlDefault(value);
        });
    };
    AutoFieldElementComponent.prototype.setControlDefault = function (value) {
        if (value === LOADING_VALUE) {
            this.loadingValue = true;
            this.cd.markForCheck();
        }
        else {
            this.loadingValue = false;
            this.cd.markForCheck();
            this.control.patchValue(value);
            this.control.markAsPristine();
        }
    };
    AutoFieldElementComponent.prototype.updateDisabled = function (state) {
        var _this = this;
        if (this.disabledSubscription) {
            this.disabledSubscription.unsubscribe();
            this.disabledSubscription = undefined;
        }
        var value$;
        if (state.disableInput) {
            value$ = applyParamInput$(state.disableInput, {
                context: this.context,
                defaultValue: false,
                handleLoading: true
            }).pipe(distinctUntilChanged(function (lhs, rhs) { return isEqual(lhs, rhs); }));
        }
        else {
            value$ = of(false);
        }
        this.disabledSubscription = value$.pipe(untilDestroyed(this)).subscribe(function (disabled) {
            _this.setControlDisabled(disabled);
        });
    };
    AutoFieldElementComponent.prototype.setControlDisabled = function (disabled) {
        if (disabled === LOADING_VALUE) {
            disabled = true;
        }
        if (disabled && !this.control.disabled) {
            this.control.disable();
        }
        else if (!disabled && this.control.disabled) {
            this.control.enable();
        }
        this.cd.markForCheck();
    };
    AutoFieldElementComponent.prototype.updateValidators = function (state) {
        if (this.updateValidatorSubscription) {
            this.updateValidatorSubscription.unsubscribe();
            this.updateValidatorSubscription = undefined;
        }
        var validators = this.baseValidator.slice();
        if (state.field) {
            if (state.field.required) {
                validators.push(Validators.required);
            }
            var validator = getValidatorByType(state.field.validatorType, state.field.validatorParams, {
                context: this.context,
                contextElement: this.viewContextElement
            });
            if (validator.func) {
                validators.push(validator.func);
            }
            if (validator.contextDependent && this.context) {
                var control_1 = this.control;
                this.updateValidatorSubscription = this.context.outputValues$
                    .pipe(throttleTime(10, asyncScheduler, { leading: true, trailing: true }))
                    .pipe(untilDestroyed(this))
                    .subscribe(function () { return control_1.updateValueAndValidity(); });
            }
        }
        this.control.setValidators(validators);
        this.control.updateValueAndValidity();
    };
    AutoFieldElementComponent.prototype.updateValueOutput = function () {
        var _this = this;
        this.updateOutputSubscriptions.forEach(function (item) { return item.unsubscribe(); });
        this.updateOutputSubscriptions = [];
        var control = this.control;
        this.updateOutputSubscriptions.push(controlValue(control)
            .pipe(
        // TODO: distinctUntilChanged breaks forms
        // distinctUntilChanged(),
        untilDestroyed(this))
            .subscribe(function (value) {
            var error = !!control.errors;
            _this.viewContextElement.setOutputValue(VALUE_OUTPUT, value, { error: error });
        }));
        this.updateOutputSubscriptions.push(controlValue(control)
            .pipe(distinctUntilChanged(), skip(1), untilDestroyed(this))
            .subscribe(function (value) {
            _this.element.onChangeActions.forEach(function (action) {
                var _a;
                _this.actionControllerService
                    .execute(action, {
                    context: _this.context,
                    contextElement: _this.viewContextElement,
                    localContext: (_a = {},
                        _a[VALUE_OUTPUT] = value,
                        _a),
                    injector: _this.injector
                })
                    .subscribe();
            });
        }));
    };
    AutoFieldElementComponent.prototype.initContext = function () {
        var fieldDescription = getFieldDescriptionByType(this.element.formField.field);
        this.viewContextElement.initElement({
            uniqueName: this.element.uid,
            name: this.element.name,
            icon: fieldDescription.icon,
            allowSkip: true,
            element: this.element,
            popup: this.popup ? this.popup.popup : undefined
        });
    };
    AutoFieldElementComponent.prototype.updateContextInfo = function (state) {
        var fieldDescription = getFieldDescriptionByType(state.field.field);
        this.viewContextElement.initInfo({
            name: state.name,
            icon: fieldDescription.icon,
            getFieldValue: function (field, outputs) {
                return outputs[VALUE_OUTPUT];
            }
        });
    };
    AutoFieldElementComponent.prototype.updateContextOutputs = function (state) {
        var fieldDescription = getFieldDescriptionByType(state.field.field);
        this.viewContextElement.setOutputs([
            {
                uniqueName: VALUE_OUTPUT,
                name: 'Value',
                icon: fieldDescription.icon,
                fieldType: state.field.field,
                fieldParams: state.field.params,
                external: true
            }
        ]);
    };
    AutoFieldElementComponent.prototype.updateContextActions = function (state) {
        var _this = this;
        var valueParameter = new ParameterField();
        valueParameter.name = 'value';
        valueParameter.field = state.field.field;
        valueParameter.params = __assign({}, state.field.params);
        this.viewContextElement.setActions([
            {
                uniqueName: 'set_value',
                name: 'Set Value',
                icon: 'edit',
                parameters: [valueParameter],
                handler: function (params) {
                    _this.control.setValue(params['value']);
                }
            },
            {
                uniqueName: 'clear_value',
                name: 'Clear Value',
                icon: 'delete',
                parameters: [],
                handler: function (options) {
                    if (options === void 0) { options = {}; }
                    var fieldDescription = getFieldDescriptionByType(state.field.field);
                    _this.control.setValue(fieldDescription.defaultValue);
                    if (options.pristine) {
                        _this.control.markAsPristine();
                    }
                }
            }
        ]);
    };
    return AutoFieldElementComponent;
}(BaseElementComponent));
export { AutoFieldElementComponent };
registerElementComponent({
    type: ElementType.Field,
    component: AutoFieldElementComponent,
    label: 'Field',
    actions: []
});

import pickBy from 'lodash/pickBy';

import { ResourceType } from './resource-type';

export class ProjectToken {
  public resource: string;
  public token: string;
  public resourceType: ResourceType;
  public resourceTypeItem: string;
  public resourceDeploy: string;
  public bridgeType: string;
  public bridgeVersion: string;
  public activated = false;
  public dateAdd: string;
  public dateActivated = false;

  deserialize(data: Object): ProjectToken {
    this.resource = data['resource'];
    this.token = data['token'];
    this.resourceType = data['resource_type'] || '';
    this.resourceTypeItem = data['resource_type_item'] || '';
    this.resourceDeploy = data['resource_deploy'] || '';
    this.bridgeType = data['bridge_type'];
    this.bridgeVersion = data['bridge_version'];
    this.activated = data['activated'];
    this.dateAdd = data['date_add'];
    this.dateActivated = data['date_activated'];

    return this;
  }

  serialize(fields?: string[]): Object {
    let data: Object = {
      resource: this.resource,
      token: this.token,
      resource_type: this.resourceType || '',
      resource_type_item: this.resourceTypeItem || '',
      resource_deploy: this.resourceDeploy || '',
      bridge_type: this.bridgeType,
      bridge_version: this.bridgeVersion,
      activated: this.activated,
      date_add: this.dateAdd,
      date_activated: this.dateActivated
    };
    if (fields) {
      data = <Object>pickBy(data, (v, k) => fields.includes(k));
    }
    return data;
  }

  clone() {
    return new ProjectToken().deserialize(this.serialize());
  }

  cleanToken(token) {
    return token.replace('-', '').toLowerCase();
  }

  isSameToken(token: string) {
    return this.cleanToken(this.token) == this.cleanToken(token);
  }
}

<div class="menu-pages">
  <div class="menu-pages__header">
    <div class="menu-pages-header">
      <div class="menu-pages-header__title">Data</div>
    </div>
  </div>

  <div class="menu-pages__content" xsScrollable [xsScrollableOptions]="{ stopPropagationAlways: true }">
    <div class="menu-pages__group">
      <div class="menu-pages-group">
        <div class="menu-pages-group__header">
          <div class="menu-pages-group__header-main">
            <div class="menu-pages-group__title">Connected Resources</div>
          </div>
        </div>

        <div class="menu-pages-group__content">
          <a
            href="javascript:void(0)"
            class="menu-pages__item"
            (click)="createResource(); closeMenu.emit()"
            [appClickEvent]="analyticsEvents.BuilderMenu.DataStartAddResource"
          >
            <div class="menu-pages-item menu-pages-item_interactive">
              <span class="menu-pages-item__icon icon-plus_circle"></span>
              <span class="menu-pages-item__title">Add Resource</span>
            </div>
          </a>
          <a
            *ngFor="let item of items"
            [appLink]="item.link"
            class="menu-pages__item"
            [attr.data-resource]="item.uniqueName"
            [appLinkActive]="'menu-pages__item_active'"
            (click)="closeMenu.emit()"
            [appClickEvent]="[analyticsEvents.BuilderMenu.DataOpenResource, { ResourceID: item.typeItem?.name }]"
          >
            <div class="menu-pages-item menu-pages-item_interactive">
              <span
                *ngIf="item.icon"
                class="menu-pages-item__img"
                [class.menu-pages-item__img_background]="item.typeItem.iconBackgroundColor"
                [style.background-image]="'url(' + item.icon + ')'"
                [style.background-color]="item.typeItem.iconBackgroundColor ? item.typeItem.iconBackgroundColor : null"
              >
              </span>
              <span class="menu-pages-item__title">{{ item.name || item.typeItem?.label }}</span>
            </div>
          </a>
        </div>
      </div>
    </div>

    <div class="menu-pages__group">
      <div class="menu-pages-group">
        <div class="menu-pages-group__header">
          <div class="menu-pages-group__header-main">
            <div class="menu-pages-group__title">App Built-ins</div>
          </div>
        </div>

        <div class="menu-pages-group__content">
          <a
            [appLink]="currentProjectStore.instance.settingsUsersLink"
            class="menu-pages__item"
            [appLinkActive]="'menu-pages__item_active'"
            [appClickEvent]="analyticsEvents.BuilderMenu.DataOpenUsers"
          >
            <div class="menu-pages-item menu-pages-item_interactive">
              <div class="menu-pages-item__icon icon-user"></div>
              <div class="menu-pages-item__title">
                Users
              </div>
            </div>
          </a>

          <a
            [appLink]="currentProjectStore.instance.settingsGroupsLink"
            class="menu-pages__item"
            [appLinkActive]="'menu-pages__item_active'"
            [appClickEvent]="analyticsEvents.BuilderMenu.DataOpenGroups"
          >
            <div class="menu-pages-item menu-pages-item_interactive">
              <div class="menu-pages-item__icon icon-users_teams"></div>
              <div class="menu-pages-item__title">
                Teams
              </div>
            </div>
          </a>

          <a
            [appLink]="currentProjectStore.instance.jetAppStorageLink"
            class="menu-pages__item"
            [appLinkActive]="'menu-pages__item_active'"
            [appClickEvent]="analyticsEvents.BuilderMenu.DataOpenStorage"
          >
            <div class="menu-pages-item menu-pages-item_interactive">
              <div class="menu-pages-item__icon icon-open_folder"></div>
              <div class="menu-pages-item__title">
                Storage
              </div>
            </div>
          </a>

          <a
            [appLink]="currentProjectStore.instance.logsLink"
            class="menu-pages__item"
            [appLinkActive]="'menu-pages__item_active'"
            [appClickEvent]="analyticsEvents.BuilderMenu.DataOpenLogs"
          >
            <div class="menu-pages-item menu-pages-item_interactive">
              <div class="menu-pages-item__icon icon-book"></div>
              <div class="menu-pages-item__title">
                Logs
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="'menu_data_banner' | appBannerVisible" class="menu-pages__banner">
    <app-customize-menu-banner
      [uid]="'menu_data_banner'"
      [title]="'Discover the power of the <br>Jet Admin Data'"
      [showVideoOptions]="{ youtubeId: 'adS8L7XoQ0E' }"
    >
      Read more in our
      <a [appDocumentation]="'data'" target="_blank">Documentation</a>
    </app-customize-menu-banner>
  </div>
</div>

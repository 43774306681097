import {
  CdkDragDrop,
  CdkDragEnter,
  CdkDragStart,
  CdkDropList,
  moveItemInArray,
  transferArrayItem
} from '@angular/cdk/drag-drop';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import * as Color from 'color';
import isEqual from 'lodash/isEqual';
import isPlainObject from 'lodash/isPlainObject';
import pickBy from 'lodash/pickBy';
import values from 'lodash/values';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

import { NotificationService } from '@common/notifications';
import { ServerRequestError } from '@modules/api';
import {
  CustomizeService,
  KanbanBoardSettings,
  KanbanBoardStage,
  ListDefaultSelection,
  ViewContext,
  ViewContextElement
} from '@modules/customize';
import { ListModelDescriptionDataSource } from '@modules/data-sources';
import { applyBooleanInput, DisplayField, Input as FieldInput } from '@modules/fields';
import { ColumnsModelListStore, EMPTY_OUTPUT, ListItem, listItemEquals, SELECTED_ITEM_OUTPUT } from '@modules/list';
import { Model, ModelDescription } from '@modules/models';
import { GetQueryOptions, paramsToGetQueryOptions } from '@modules/resources';
import { TypedChanges } from '@shared';

import { getBaseStateFetch, getListStateColumns, KanbanBoardState } from '../kanban-board/kanban-board-state';

interface KanbanBoardStageState extends KanbanBoardState {
  stageValue?: any;
  visibleInput?: FieldInput;
}

export function getKanbanBoardGroupStateVisible(state: KanbanBoardStageState): Object {
  return {
    visible: state.visibleInput ? state.visibleInput.serialize() : undefined
  };
}

export function getKanbanBoardGroupStateFetch(state: KanbanBoardStageState): Object {
  return {
    ...getBaseStateFetch(state),
    stageValue: state.stageValue
  };
}

@Component({
  selector: 'app-kanban-board-column',
  templateUrl: './kanban-board-column.component.html',
  providers: [ColumnsModelListStore],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class KanbanBoardColumnComponent implements OnInit, OnDestroy, OnChanges {
  @Input() stage: KanbanBoardStage;
  @Input() stageIndex: number;
  @Input() listState: KanbanBoardState;
  @Input() settings: KanbanBoardSettings;
  @Input() dataSource: ListModelDescriptionDataSource;
  @Input() modelDescription: ModelDescription;
  @Input() visibleColumns: DisplayField[] = [];
  @Input() selectedItem: ListItem;
  @Input() checkedItems: { [k: string]: Model } = {};
  @Input() context: ViewContext;
  @Input() contextElement: ViewContextElement;
  @Input() uniqueName: string;
  @Input() droppable: string[];
  @Input() accentColor: string;
  @Output() fetchStarted = new EventEmitter<void>();
  @Output() fetchFinished = new EventEmitter<ListItem[]>();
  @Output() updateSelected = new EventEmitter<ListItem>();
  @Output() updateChecked = new EventEmitter<Model[]>();
  @Output() dragStarted = new EventEmitter<ListItem>();
  @Output() dragFinished = new EventEmitter<ListItem>();
  @Output() itemAdded = new EventEmitter<ListItem>();
  @Output() modelUpdated = new EventEmitter<Model>();

  @HostBinding('class.kanban-board__column_hidden') isHidden = false;
  @ViewChild(CdkDropList) dropList: CdkDropList<ListItem[]>;

  stage$ = new BehaviorSubject<KanbanBoardStage>(undefined);
  listState$ = new BehaviorSubject<KanbanBoardState>({});
  state: KanbanBoardStageState = {};
  prevState: KanbanBoardStageState = {};

  isVisible = false;
  isVisibleSubscription: Subscription;
  items: ListItem[] = [];
  loading = true;
  loadingMore = false;
  error: string;
  fetchSubscription: Subscription;
  fetchMoreSubscription: Subscription;

  constructor(
    public listStore: ColumnsModelListStore,
    public customizeService: CustomizeService,
    private notificationService: NotificationService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    combineLatest(this.stage$, this.listState$)
      .pipe(untilDestroyed(this))
      .subscribe(([stage, listState]) => {
        const state: KanbanBoardStageState = {
          ...listState,
          stageValue: stage ? stage.value : undefined,
          visibleInput: stage ? stage.visibleInput : undefined
        };
        this.prevState = this.state;
        this.state = state;
        this.onStateUpdated(state);
      });

    this.customizeService.enabled$.pipe(untilDestroyed(this)).subscribe(() => this.updateIsHidden());
    this.updateIsHidden();
  }

  ngOnDestroy(): void {}

  ngOnChanges(changes: TypedChanges<KanbanBoardColumnComponent>): void {
    if (changes.stage) {
      this.stage$.next(this.stage);
    }

    if (changes.listState) {
      this.listState$.next(this.listState);
    }
  }

  getItems(): ListItem[] {
    return this.items;
  }

  setItems(value: ListItem[]) {
    this.items = value;
    this.cd.markForCheck();
  }

  getCount(): number {
    return this.listStore.count;
  }

  setCount(value: number) {
    this.listStore.count = value;
  }

  onStateUpdated(state: KanbanBoardStageState) {
    if (!isEqual(getKanbanBoardGroupStateVisible(state), getKanbanBoardGroupStateVisible(this.prevState))) {
      this.initVisibleObserver(state);
    }

    if (!isEqual(getKanbanBoardGroupStateFetch(state), getKanbanBoardGroupStateFetch(this.prevState))) {
      this.fetch(state);
    } else {
      if (!isEqual(getListStateColumns(state), getListStateColumns(this.listState))) {
        if (this.listStore.dataSource) {
          this.listStore.dataSource.columns = state.dataSource ? state.dataSource.columns : [];
          this.listStore.deserializeModelAttributes();
        }
      }
    }
  }

  initVisibleObserver(state: KanbanBoardStageState) {
    if (this.isVisibleSubscription) {
      this.isVisibleSubscription.unsubscribe();
    }

    if (!state.visibleInput) {
      this.isVisible = true;
      this.updateIsHidden();
      this.cd.markForCheck();
      return;
    }

    this.isVisible = applyBooleanInput(state.visibleInput, this.context);
    this.updateIsHidden();
    this.cd.markForCheck();

    this.isVisibleSubscription = this.context.outputValues$
      .pipe(debounceTime(10), distinctUntilChanged(), untilDestroyed(this))
      .subscribe(() => {
        this.isVisible = applyBooleanInput(state.visibleInput, this.context);
        this.updateIsHidden();
        this.cd.markForCheck();
      });
  }

  updateIsHidden() {
    this.isHidden = !this.isVisible && !this.customizeService.enabled;
    this.cd.markForCheck();
  }

  getStateQueryOptions(state: KanbanBoardStageState, extraParams = {}): GetQueryOptions {
    const queryOptions = paramsToGetQueryOptions({
      ...state.dataSourceParams,
      ...extraParams
    });

    queryOptions.filters = [...queryOptions.filters, ...state.filters];
    queryOptions.search = state.search;
    queryOptions.sort = state.sort;

    return queryOptions;
  }

  getBaseStateQueryOptions(): GetQueryOptions {
    return this.getStateQueryOptions(this.state);
  }

  fetch(state: KanbanBoardStageState) {
    if (!state.dataSource || !state.dataSource.isConfigured()) {
      return;
    }

    if (this.fetchSubscription) {
      this.fetchSubscription.unsubscribe();
      this.fetchSubscription = undefined;
    }

    if (this.fetchMoreSubscription) {
      this.fetchMoreSubscription.unsubscribe();
      this.fetchMoreSubscription = undefined;
    }

    this.items = undefined;
    this.loading = true;
    this.loadingMore = false;
    this.error = undefined;
    this.cd.markForCheck();

    this.fetchStarted.emit();

    if (this.settings.defaultSelection && !this.selectedItem) {
      this.contextElement.patchOutputValueMeta(SELECTED_ITEM_OUTPUT, { loading: true });
    }

    if (state.inputsLoading) {
      this.listStore.reset();
      return;
    }

    this.listStore.dataSource = state.dataSource;
    this.listStore.useDataSourceColumns = true;
    this.listStore.staticData = state.dataSourceStaticData;
    this.listStore.queryOptions = this.getStateQueryOptions(state, {
      [this.settings.stageField]: state.stageValue
    });
    this.listStore.context = this.context;
    this.listStore.contextElement = this.contextElement;
    this.listStore.perPage = state.settings && state.settings.perPage ? state.settings.perPage : 10;

    this.listStore.reset(1);
    this.items = [];
    this.cd.markForCheck();

    this.fetchSubscription = this.listStore
      .getNext()
      .pipe(untilDestroyed(this))
      .subscribe(
        result => {
          this.loading = false;
          this.items = result;
          this.cd.markForCheck();

          if (this.settings.defaultSelection && !this.selectedItem) {
            this.contextElement.patchOutputValueMeta(SELECTED_ITEM_OUTPUT, { loading: false });

            if (this.settings.defaultSelection == ListDefaultSelection.First && this.stageIndex == 0) {
              const first = this.items ? this.items[0] : undefined;
              this.updateSelected.emit(first);
              this.updateChecked.emit(first ? [first.model] : []);
            }
          }

          this.fetchFinished.emit(this.items);
        },
        error => {
          if (error instanceof ServerRequestError && error.errors.length) {
            this.error = error.errors[0];
          } else if (isPlainObject(error)) {
            this.error = JSON.stringify(error);
          } else if (error.hasOwnProperty('message')) {
            console.error(error);
            this.error = error.message;
          } else {
            console.error(error);
            this.error = error;
          }

          this.loading = false;
          this.cd.markForCheck();

          this.contextElement.setOutputValue(EMPTY_OUTPUT, false, { loading: false, error: true });

          if (this.settings.defaultSelection && !this.selectedItem) {
            this.contextElement.patchOutputValueMeta(SELECTED_ITEM_OUTPUT, { loading: false });
          }
        }
      );
  }

  fetchMore() {
    if (this.loadingMore) {
      return;
    }

    this.loadingMore = true;
    this.cd.markForCheck();

    this.fetchMoreSubscription = this.listStore
      .getNext()
      .pipe(untilDestroyed(this))
      .subscribe(
        () => {
          this.loadingMore = false;
          this.items = this.listStore.items;
          this.cd.markForCheck();
        },
        error => {
          this.loadingMore = false;
          this.cd.markForCheck();

          if (error instanceof ServerRequestError && error.errors.length) {
            this.notificationService.error('Loading failed', error.errors[0]);
          } else {
            this.notificationService.error('Loading failed', error);
          }
        }
      );
  }

  toggleSelectedItem(item: ListItem, index: number) {
    if (this.selectedItem === item) {
      this.updateSelected.emit(undefined);
    } else {
      this.updateSelected.emit(item);
    }

    if (this.settings.multipleSelection) {
      const pk = item.model.primaryKey || index;
      const checked = this.isItemChecked(item, index);

      if (!checked) {
        const checkedItems = [...values(this.checkedItems), item.model];
        this.updateChecked.emit(checkedItems);
      } else {
        const checkedItems = values(pickBy(this.checkedItems, (v, k) => k != pk));
        this.updateChecked.emit(checkedItems);
      }
    }
  }

  reloadData() {
    this.fetch(this.state);
  }

  listEntered(event: CdkDragEnter<ListItem[], ListItem>) {
    this.itemAdded.emit(event.item.data);
  }

  listDropped(event: CdkDragDrop<ListItem[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
    }

    this.dragFinished.emit(event.item.data);
  }

  listDragStarted(event: CdkDragStart<ListItem>) {
    this.dragStarted.emit(event.source.data);
  }

  trackItemFn(i, item: ListItem) {
    return item.model.primaryKey || i;
  }

  itemEquals(lhs: ListItem, rhs: ListItem) {
    return listItemEquals(lhs, rhs);
  }

  isItemSelected(item: ListItem, index: number) {
    if (this.settings.multipleSelection) {
      return this.isItemChecked(item, index);
    } else {
      return this.itemEquals(this.selectedItem, item);
    }
  }

  isItemChecked(item: ListItem, index: number) {
    const pk = item.model.primaryKey || `${index}_${index}`;
    return this.checkedItems[pk];
  }

  backgroundCustomColor(color: string) {
    try {
      const clr = Color(color);
      return clr.alpha(0.1).string();
    } catch (e) {
      return null;
    }
  }
}

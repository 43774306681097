import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { FieldLookup } from '@modules/field-lookups';
import { FieldDescriptionLookup } from '@modules/fields';
import { NO_PAGINATION_PARAM, ORDER_BY_PARAM, PAGE_PARAM, PER_PAGE_PARAM, SEARCH_PARAM } from '@modules/models';
import { modelDescriptionHasAutoParameters } from '@modules/parameters';
import { JET_APP_RESOURCE } from '@modules/projects';
import { indent, indentLines, isSet } from '@shared';

import { ApiParameter } from '../../../data/api-parameter';
import { ApiParameterType } from '../../../data/api-parameter-type';
import { fieldToApiParameter } from '../../../utils/common';
import { ModelApiBaseQueryComponent } from '../model-api-base-query/model-api-base-query.component';

@Component({
  selector: 'app-model-api-get-query',
  templateUrl: './model-api-get-query.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModelApiGetQueryComponent extends ModelApiBaseQueryComponent implements OnInit {
  @Input() pagination = true;

  parameters: ApiParameter[] = [];

  ngOnInit() {
    super.ngOnInit();
    this.fetchModel();
    this.updateParameters();
  }

  updateParameters() {
    this.parameters = this.getParameters();
    this.cd.markForCheck();
  }

  updateCurl() {
    this.curl = this.getCurlRequest({
      url: this.getAbsoluteApiUrl()
    });
    this.cd.markForCheck();
  }

  getParametersAuto(): ApiParameter[] {
    const result: ApiParameter[] = [];

    if (
      modelDescriptionHasAutoParameters(this.resource, this.modelDescription) &&
      this.resource.uniqueName != JET_APP_RESOURCE
    ) {
      result.push(
        ...[
          ...this.modelDescription.dbFields.map(field => {
            const mapLookup = (lookupInfo: FieldDescriptionLookup): ApiParameter => {
              const lookup = lookupInfo.type as FieldLookup;

              return {
                type: ApiParameterType.Query,
                name: this.getFieldParamName(field.name, lookup.lookup),
                fieldTypeLabel: lookupInfo.field,
                fieldTypeDescription: {
                  field: field.field,
                  params: field.params
                },
                description: `Return results where the ${field.verboseName} field ${lookup.verboseName} this value.`
              };
            };

            const main = field.fieldDescription.lookups
              .filter(lookupInfo => !(lookupInfo.type as FieldLookup).lookup)
              .map(lookupInfo => mapLookup(lookupInfo))[0];
            const children = field.fieldDescription.lookups
              .filter(lookupInfo => (lookupInfo.type as FieldLookup).lookup)
              .map(lookupInfo => mapLookup(lookupInfo));

            const first = main ? main : children[0];
            const others = main ? children : children.slice(1);

            return {
              ...first,
              ...(others.length ? { children: others } : {})
            };
          }),
          {
            type: ApiParameterType.Query,
            name: SEARCH_PARAM,
            fieldTypeLabel: 'string',
            description: `Search results TEXT fields with the specified value. Search is case insensitive.`
          },
          {
            type: ApiParameterType.Query,
            name: ORDER_BY_PARAM,
            fieldTypeLabel: 'string',
            description: `Sorts returned objects by the specified field name. If you want to sort descending add "-" before the field name.`
          }
        ]
      );
    } else {
      result.push(
        ...this.modelDescription.getParameters.map(parameter => {
          return fieldToApiParameter(parameter, ApiParameterType.Query);
        })
      );
    }

    if (this.pagination) {
      result.push(
        ...[
          {
            type: ApiParameterType.Query,
            name: PAGE_PARAM,
            fieldTypeLabel: 'number',
            description: `A page number for use in pagination. All results are split into pages with maximum ${PER_PAGE_PARAM} items.`
          },
          {
            type: ApiParameterType.Query,
            name: PER_PAGE_PARAM,
            fieldTypeLabel: 'number',
            description: `A limit on the number of objects to be returned. Limit can range between 1 and 10 000, and the default is 25.`
          },
          {
            type: ApiParameterType.Query,
            name: NO_PAGINATION_PARAM,
            fieldTypeLabel: 'boolean',
            description: `Disables pagination and returns all objects (maximum 10 000)`
          }
        ]
      );
    }

    return result;
  }

  getQueryUrl() {
    return ``;
  }

  getResponseData(): Object {
    const model = this.model || this.getDefaultModel();
    const modelData = this.serializer ? this.serializer(model) : this.serializeDefault(model);

    if (this.pagination) {
      const modelCount = isSet(this.modelCount) ? this.modelCount : 5;
      const modelNext = isSet(this.modelNext) ? this.modelNext : this.getAbsoluteApiUrl() + '?page=2';

      return [
        '{',
        indent(`"count": ${JSON.stringify(modelCount)},`, this.indentSize),
        indent(`"next": ${JSON.stringify(modelNext)},`, this.indentSize),
        indent(`"previous": null,`, this.indentSize),
        indent(`"results": [`, this.indentSize),
        indentLines(JSON.stringify(modelData, null, this.indentSize), this.indentSize * 2) + ',',
        indent(`{...},`, this.indentSize * 2),
        indent(`{...}`, this.indentSize * 2),
        indent(`]`, this.indentSize),
        '}'
      ].join('\n');
    } else {
      return ['[', indentLines(JSON.stringify(modelData, null, this.indentSize), this.indentSize), ']'].join('\n');
    }
  }
}

<div
  class="compact-inputs-row"
  [class.compact-inputs-row_disabled]="!control.controls.enabled.value"
  [class.compact-inputs-row_yellow]="control.controls.enabled_input | appControlValue | async | appIsFieldInputSet"
>
  <div class="compact-inputs-row__item">
    <app-color-button
      [colorControl]="control.controls.color"
      [colorInputEnabledControl]="control.controls.color_input_enabled"
      [colorInputControl]="control.controls.color_input"
      [viewContext]="viewContext"
    ></app-color-button>
  </div>

  <div class="compact-inputs-row__item compact-inputs-row__item_fill">
    <app-compact-input
      [formControl]="control.controls.thickness"
      [postfix]="'px'"
      [placeholder]="'width'"
      [number]="true"
      [numberOptions]="{ min: 1 }"
    ></app-compact-input>
  </div>

  <div *ngIf="styleEnabled" class="compact-inputs-row__item">
    <app-compact-select [formControl]="control.controls.style" [options]="control.styleOptions"></app-compact-select>
  </div>

  <div class="compact-inputs-row__item">
    <div
      class="compact-button compact-button_primary icon-eye"
      [class.icon-eye_2]="control.controls.enabled.value"
      [class.icon-eye_disabled_2]="!control.controls.enabled.value"
      (click)="control.controls.enabled.patchValue(!control.controls.enabled.value)"
    ></div>
  </div>

  <div class="compact-inputs-row__item">
    <ng-template
      cdkConnectedOverlay
      [cdkConnectedOverlayPanelClass]="['overlay', 'overlay_horizontal_center']"
      [cdkConnectedOverlayPositions]="dropdownPositions"
      [cdkConnectedOverlayOrigin]="trigger"
      [cdkConnectedOverlayOpen]="dropdownOpened"
      [cdkConnectedOverlayPush]="true"
      [cdkConnectedOverlayGrowAfterOpen]="true"
      [cdkConnectedOverlayHasBackdrop]="true"
      [cdkConnectedOverlayBackdropClass]="'popover2-backdrop'"
      (backdropClick)="setDropdownOpened(false)"
      (detach)="setDropdownOpened(false)"
    >
      <div class="compact-inputs-overlay overlay-content overlay-content_animated theme_dark">
        <div *ngIf="styleEnabled" class="compact-inputs-element">
          <div class="compact-label">
            <div class="compact-label__label compact-label__label_left">Style</div>
            <div class="compact-label__content">
              <app-compact-select
                [formControl]="control.controls.style"
                [options]="control.styleOptions"
              ></app-compact-select>
            </div>
          </div>
        </div>

        <div *ngIf="positionEnabled" class="compact-inputs-element">
          <div class="compact-label">
            <div class="compact-label__label compact-label__label_left">Position</div>
            <div class="compact-label__content">
              <app-compact-select
                [formControl]="control.controls.position"
                [options]="control.positionOptions"
              ></app-compact-select>
            </div>
          </div>
        </div>

        <div class="compact-inputs-element">
          <div class="compact-heading">
            Conditional Border
          </div>

          <app-input-edit
            [itemForm]="control.controls.enabled_input"
            [context]="viewContext"
            [staticValueDisabled]="true"
            [placeholder]="'Value'"
            [fill]="true"
            [dark]="true"
            [darker]="true"
          ></app-input-edit>
        </div>

        <div class="compact-inputs-element">
          <div class="compact-inputs-row compact-inputs-row_align_right">
            <div class="compact-inputs-row__item">
              <div class="button button_small button_orange-border button_no-margin" (click)="remove.emit()">
                <span class="button__icon button__icon_left icon-bin"></span>
                <span class="button__label">Delete border</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-template>

    <div
      class="compact-button compact-button_primary icon-more"
      cdkOverlayOrigin
      #trigger="cdkOverlayOrigin"
      (click)="setDropdownOpened(true)"
    ></div>
  </div>
</div>

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { Observable } from 'rxjs';

import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { ModelElementItem, ViewContext, ViewContextElement } from '@modules/customize';
import { DataSourceType } from '@modules/data-sources';
import { HttpResultsSection, SqlResultsSection } from '@modules/queries-components';

import { CustomizeBarEditEvent } from '../../../data/customize-bar-edit-event';
import { CustomizeBarEditEventType } from '../../../data/customize-bar-edit-event-type';
import { DataSourceInputsEditComponent } from '../../data-source-inputs-edit/data-source-inputs-edit.component';
import { ModelDescriptionDataSourceEditComponent } from '../../model-description-data-source-edit/model-description-data-source-edit.component';
import { CustomizeBarModelEditForm } from '../customize-bar-model-edit.form';

@Component({
  selector: 'app-customize-bar-model-edit-data',
  templateUrl: './customize-bar-model-edit-data.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomizeBarModelEditDataComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() element: ModelElementItem;
  @Input() name: string;
  @Input() form: CustomizeBarModelEditForm;
  @Input() context: ViewContext;
  @Input() contextElement: ViewContextElement;
  @Input() contextElementPath: (string | number)[];
  @Input() contextElementPaths: (string | number)[][];
  @Input() setupOnCreate = false;
  @Input() addInput = false;
  @Output() event = new EventEmitter<CustomizeBarEditEvent>();

  @ViewChild(ModelDescriptionDataSourceEditComponent) dataSourceEditComponent: ModelDescriptionDataSourceEditComponent;
  @ViewChild(DataSourceInputsEditComponent) inputsEditComponent: DataSourceInputsEditComponent;

  dataConfigured$: Observable<boolean>;
  dataSourceTypes = DataSourceType;

  constructor(private analyticsService: UniversalAnalyticsService) {}

  ngOnInit() {
    this.dataConfigured$ = this.form.controls.data_source.getDataConfigured$();

    this.analyticsService.sendSimpleEvent(AnalyticsEvent.ComponentData.StartedToSetUp, {
      ComponentTypeID: this.element.analyticsName
    });
  }

  ngOnDestroy(): void {}

  ngAfterViewInit(): void {
    if (this.setupOnCreate) {
      this.dataSourceEditComponent.editCustomQuery();
    }

    setTimeout(() => {
      if (this.addInput && this.inputsEditComponent) {
        this.inputsEditComponent.openAddInput();
      }
    }, 0);
  }

  submit() {
    this.event.emit({ type: CustomizeBarEditEventType.Updated, args: { result: this.form.submit() } });
  }

  cancel() {
    this.event.emit({ type: CustomizeBarEditEventType.Canceled });
  }

  addQueryInput() {
    if (this.dataSourceEditComponent) {
      this.dataSourceEditComponent.editQuery({
        initialHttpResultsSection: HttpResultsSection.Parameters,
        initialSqlResultsSection: SqlResultsSection.Parameters
      });
    }
  }
}

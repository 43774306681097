import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TipsModule } from '@common/tips';

import { SignatureComponent } from './components/signature/signature.component';

@NgModule({
  imports: [CommonModule, TipsModule],
  declarations: [SignatureComponent],
  exports: [SignatureComponent]
})
export class SignatureModule {}

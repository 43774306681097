import { MessageUserActivity } from './message-user-activity';
import { UserActivity } from './user-activity';
import { UserActivityType } from './user-activity-type';

export function deserializeUserActivity(data: Object) {
  if (data['activity_type'] == UserActivityType.Message) {
    return new MessageUserActivity().deserialize(data);
  } else {
    return new UserActivity().deserialize(data);
  }
}

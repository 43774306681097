import { AfterViewInit, ChangeDetectorRef, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, merge } from 'rxjs';
import { skip } from 'rxjs/operators';
import { AnalyticsEvent } from '@modules/analytics';
import { ElementItem, ViewContext, ViewContextElement } from '@modules/customize';
import { DataSourceType } from '@modules/data-sources';
import { BooleanFieldStyle, SelectFieldComponent } from '@modules/field-components';
import { createFormFieldFactory } from '@modules/fields';
import { QueryType } from '@modules/queries';
import { HttpResultsSection, SqlResultsSection } from '@modules/queries-components';
import { CustomizeBarEditEventType } from '../../../data/customize-bar-edit-event-type';
import { DataSourceInputsEditComponent } from '../../data-source-inputs-edit/data-source-inputs-edit.component';
import { ModelDescriptionDataSourceEditComponent } from '../../model-description-data-source-edit/model-description-data-source-edit.component';
import { CustomizeBarListLayoutSettingsForm } from './customize-bar-list-layout-settings.form';
var CustomizeBarListLayoutSettingsDataSourceComponent = /** @class */ (function () {
    function CustomizeBarListLayoutSettingsDataSourceComponent(resourceGeneratorResolver, analyticsService, cd) {
        this.resourceGeneratorResolver = resourceGeneratorResolver;
        this.analyticsService = analyticsService;
        this.cd = cd;
        this.setupOnCreate = false;
        this.addInput = false;
        this.editSorting = false;
        this.event = new EventEmitter();
        this.createField = createFormFieldFactory();
        this.queryTypes = QueryType;
        this.booleanFieldStyle = BooleanFieldStyle;
        this.dataSourceTypes = DataSourceType;
    }
    CustomizeBarListLayoutSettingsDataSourceComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.columnOptions$ = this.form.controls.data_source.getColumnOptions$();
        this.sortableColumnOptions$ = this.form.controls.data_source.getSortableColumnOptions$();
        this.dataConfigured$ = this.form.controls.data_source.getDataConfigured$();
        // TODO: Temp fix
        merge(this.columnOptions$, this.sortableColumnOptions$)
            .pipe(untilDestroyed(this))
            .subscribe(function () { return _this.cd.detectChanges(); });
        this.form.controls.data_source
            .getResource$()
            .pipe(skip(1), untilDestroyed(this))
            .subscribe(function (resource) {
            if (resource) {
                _this.analyticsService.sendSimpleEvent(AnalyticsEvent.ComponentData.ResourceSelected, {
                    ComponentTypeID: _this.settings.analyticsName,
                    ResourceID: resource ? resource.typeItem.name : undefined,
                    ResourceDemo: resource ? resource.demo : false
                });
            }
        });
        combineLatest(this.form.controls.data_source.getResource$().pipe(skip(1)), this.form.controls.data_source.getModelDescription$().pipe(skip(1)))
            .pipe(untilDestroyed(this))
            .subscribe(function (_a) {
            var resource = _a[0], modelDescription = _a[1];
            if (modelDescription) {
                _this.analyticsService.sendSimpleEvent(AnalyticsEvent.ComponentData.ResourceItemSelected, {
                    ComponentTypeID: _this.settings.analyticsName,
                    ResourceID: resource ? resource.typeItem.name : undefined,
                    ResourceDemo: resource ? resource.demo : false
                });
            }
        });
        this.analyticsService.sendSimpleEvent(AnalyticsEvent.ComponentData.StartedToSetUp, {
            ComponentTypeID: this.settings.analyticsName
        });
    };
    CustomizeBarListLayoutSettingsDataSourceComponent.prototype.ngOnDestroy = function () { };
    CustomizeBarListLayoutSettingsDataSourceComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        setTimeout(function () {
            if (_this.editSorting && _this.sortingFieldComponent) {
                _this.sortingFieldComponent.open();
            }
            if (_this.addInput && _this.inputsEditComponent) {
                _this.inputsEditComponent.openAddInput();
            }
        }, 0);
    };
    CustomizeBarListLayoutSettingsDataSourceComponent.prototype.submit = function () {
        this.event.emit({ type: CustomizeBarEditEventType.Updated, args: { result: this.form.submit() } });
    };
    CustomizeBarListLayoutSettingsDataSourceComponent.prototype.cancel = function () {
        this.event.emit({ type: CustomizeBarEditEventType.Canceled });
    };
    CustomizeBarListLayoutSettingsDataSourceComponent.prototype.addQueryInput = function () {
        if (this.dataSourceEditComponent) {
            this.dataSourceEditComponent.editQuery({
                initialHttpResultsSection: HttpResultsSection.Parameters,
                initialSqlResultsSection: SqlResultsSection.Parameters
            });
        }
    };
    return CustomizeBarListLayoutSettingsDataSourceComponent;
}());
export { CustomizeBarListLayoutSettingsDataSourceComponent };

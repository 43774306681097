import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Power2, TimelineMax } from 'gsap';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

import { NotificationService } from '@common/notifications';
import { SessionStorage } from '@core';
import { AdminMode } from '@modules/admin-mode';
import { AnalyticsEvent, GoogleAnalyticsService } from '@modules/analytics';
import { ApiService } from '@modules/api';
import { TicketService } from '@modules/contact';
import { CustomizeService, CustomizeType } from '@modules/customize';
import { MetaService } from '@modules/meta';
import { Project, ProjectService, ProjectsStore } from '@modules/projects';
import { RoutingService } from '@modules/routing';
import { CurrentUserStore } from '@modules/users';

@Component({
  templateUrl: './demo-project.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DemoProjectComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('root') root: ElementRef;
  @ViewChild('background') background: ElementRef;

  showMenu = true;
  items: Project[];
  other = false;
  project: Project;
  itemHovered: any;
  opening = false;
  tl = new TimelineMax();
  opened = false;
  otherIndustries = [
    {
      name: 'Education',
      icon: 'book'
    },
    {
      name: 'Advertisement',
      icon: 'target'
    },
    {
      name: 'E-Sports',
      icon: 'gamepad'
    },
    {
      name: 'Information Security',
      icon: 'shield'
    },
    {
      name: 'Insurance',
      icon: 'news'
    },
    {
      name: 'Travel',
      icon: 'earth_planet'
    }
  ];
  customIndustry = '';
  analyticsEvents = AnalyticsEvent;

  constructor(
    private apiService: ApiService,
    private activatedRoute: ActivatedRoute,
    private projectService: ProjectService,
    private projectsStore: ProjectsStore,
    private currentUserStore: CurrentUserStore,
    private metaService: MetaService,
    private ticketService: TicketService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private notificationService: NotificationService,
    private customizeService: CustomizeService,
    private cd: ChangeDetectorRef,
    private routing: RoutingService,
    private sessionService: SessionStorage
  ) {}

  ngOnInit() {
    this.metaService.set({ title: 'Demo' });

    this.projectService
      .getDemo()
      .pipe(untilDestroyed(this))
      .subscribe(items => {
        this.items = items;
        this.cd.markForCheck();
        this.initRoutes();
      });

    this.activatedRoute.queryParams.pipe(untilDestroyed(this)).subscribe(params => {
      this.showMenu = !params['iframe'];
      this.cd.markForCheck();
    });
  }

  ngOnDestroy(): void {}

  ngAfterViewInit(): void {
    this.opened = false;
    this.tl
      .clear()
      .set(this.background.nativeElement, { opacity: 0 })
      .set(this.root.nativeElement, { scale: 0, opacity: 0 });
  }

  initRoutes() {
    this.activatedRoute.params.pipe(untilDestroyed(this)).subscribe(params => {
      const action = params['action'];
      const projectName = params['project'];

      if (projectName && action == 'jobs') {
        this.project = this.items.find(item => item.uniqueName == projectName);
        this.itemHovered = false;
        this.cd.detectChanges();
        this.show();
      } else if (projectName) {
        this.close()
          .pipe(untilDestroyed(this))
          .subscribe(() => {
            this.openDemoProject(projectName);
          });
      } else if (this.items.length == 1) {
        this.openDemoProject(this.items[0].uniqueName);
      } else {
        this.project = undefined;
        this.itemHovered = false;
        this.cd.detectChanges();
        this.show();
      }
    });
  }

  show() {
    if (this.opened) {
      return;
    }

    this.opened = true;
    this.tl
      .clear()
      .fromTo(
        this.background.nativeElement,
        0.6,
        {
          opacity: 0
        },
        {
          opacity: 1,
          ease: Power2.easeOut
        },
        0.6
      )
      .fromTo(
        this.root.nativeElement,
        0.6,
        {
          scale: 0,
          opacity: 0
        },
        {
          scale: 1,
          opacity: 1,
          ease: Power2.easeOut
        },
        0.6
      );
  }

  close(): Observable<any> {
    if (!this.opened) {
      return of({});
    }

    const obs = new Subject<void>();

    this.opened = false;
    this.tl
      .clear()
      .fromTo(
        this.background.nativeElement,
        0.4,
        {
          opacity: 1
        },
        {
          opacity: 0,
          ease: Power2.easeIn
        }
      )
      .fromTo(
        this.root.nativeElement,
        0.4,
        {
          scale: 1,
          opacity: 1
        },
        {
          scale: 0,
          opacity: 0,
          ease: Power2.easeIn
        },
        0
      )
      .add(() => {
        obs.next();
      });

    return obs;
  }

  openDemoProject(projectName: string) {
    this.opening = true;
    this.projectService
      .createProjectToken()
      .pipe(
        switchMap(token => {
          return this.projectService.createDemo(projectName, token).pipe(
            tap(() => {
              if (!this.currentUserStore.instance) {
                this.apiService.saveProjectToken(token);
              }
            })
          );
        }),
        switchMap(project => {
          return combineLatest(this.projectsStore.updateIfLoaded(), this.currentUserStore.getFirst(true)).pipe(
            map(() => project)
          );
        }),
        untilDestroyed(this)
      )
      .subscribe(project => {
        const link = [];
        let subLink = this.activatedRoute.snapshot.queryParams['link'];
        const iframe = this.activatedRoute.snapshot.queryParams['iframe'];
        const customize = this.activatedRoute.snapshot.queryParams.hasOwnProperty('customize')
          ? Number(this.activatedRoute.snapshot.queryParams['customize'])
          : true;
        const iframeKey = `iframe_${project.uniqueName}`;

        if (subLink) {
          if (subLink[0] == '/') {
            subLink = subLink.substring(1);
          }

          link.push(...subLink.split('/'));
        }

        if (iframe) {
          this.sessionService.set(iframeKey, '1');
        } else if (this.sessionService.get(iframeKey)) {
          this.sessionService.remove(iframeKey);
        }

        const mode = customize ? AdminMode.Builder : AdminMode.App;
        this.routing.navigateLink(project.linkWithProtocol(link, { mode: mode }));
      });
  }

  setItemHovered(value) {
    this.itemHovered = value;
    this.cd.markForCheck();
  }

  chooseProject(item: Project) {
    if (!item) {
      this.routing.navigate(['/demo'], { queryParamsHandling: 'preserve' });
    } else if (item.jobs.length) {
      this.routing.navigate(['/demo', 'jobs', item.uniqueName], { queryParamsHandling: 'preserve' });
    } else {
      this.routing.navigate(['/demo', item.uniqueName], { queryParamsHandling: 'preserve' });
    }
  }

  setOther(value: boolean) {
    this.other = value;
    this.itemHovered = false;
    this.cd.markForCheck();
  }

  sendRequest(industry: string) {
    const message = `Requested industry: ${industry}\nGoogleAnalyticsClientId: ${this.googleAnalyticsService.clientId}`;

    this.ticketService
      .create('Industry Request', '', message)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.setOther(false);
        this.notificationService.success('Sent', `You industry request successfully sent and will be reviewed soon`);
      });
  }
}

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { OnChanges, OnInit } from '@angular/core';
import cloneDeep from 'lodash/cloneDeep';
import { createFormFieldFactory, FieldType } from '@modules/fields';
var ProjectResourceDataTemplatesTableItemComponent = /** @class */ (function () {
    function ProjectResourceDataTemplatesTableItemComponent() {
        this.createField = createFormFieldFactory();
        this.columnListItems = [];
        this.fieldTypes = FieldType;
    }
    ProjectResourceDataTemplatesTableItemComponent.prototype.ngOnInit = function () { };
    ProjectResourceDataTemplatesTableItemComponent.prototype.ngOnChanges = function (changes) {
        if (changes.row || changes.columns) {
            this.updateItems();
        }
    };
    ProjectResourceDataTemplatesTableItemComponent.prototype.updateItems = function () {
        var _this = this;
        this.columnListItems = this.columns.map(function (item) {
            var column = cloneDeep(item);
            var itemColumn = _this.row.columns.find(function (i) { return i.column == item.name; });
            if (item.field == FieldType.JSON && item.params['display_fields']) {
                column.params = __assign({}, column.params, { display_fields: false });
            }
            return {
                listItem: itemColumn,
                column: column
            };
        });
    };
    ProjectResourceDataTemplatesTableItemComponent.prototype.trackByFn = function (i, item) {
        return item ? item.column.name : i;
    };
    return ProjectResourceDataTemplatesTableItemComponent;
}());
export { ProjectResourceDataTemplatesTableItemComponent };

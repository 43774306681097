import cloneDeep from 'lodash/cloneDeep';

import { Input } from '@modules/fields';
import { generateAlphanumeric } from '@shared';

import { AlignHorizontal } from '../../align-horizontal';
import { AlignVertical } from '../../align-vertical';
import { ElementType } from '../element-type';

export interface Margin {
  top?: number;
  right?: number;
  bottom?: number;
  left?: number;
}

export class ElementItem {
  public uid: string;
  public name: string;
  public type: ElementType;
  public visibleInput: Input;
  public margin: Margin = {};
  public alignHorizontal: AlignHorizontal;
  public alignHorizontalDefault = AlignHorizontal.Justify;
  public alignVertical: AlignVertical;
  public params = {};

  // TODO: remove ModelDescription
  deserialize(data: Object): ElementItem {
    this.uid = data['uid'];
    this.name = data['name'];
    this.type = data['type'];
    this.alignHorizontal = data['align_horizontal'];
    this.alignVertical = data['align_vertical'];
    this.params = data['params'] ? cloneDeep(data['params']) : {};

    if (!this.uid) {
      this.generateUid();
    }

    if (data['margin']) {
      this.margin = data['margin'];
    }

    if (data['visible_input']) {
      this.visibleInput = new Input().deserialize(data['visible_input']);
    }

    return this;
  }

  get alignHorizontalOrDefault() {
    return this.alignHorizontal || this.alignHorizontalDefault;
  }

  serialize(): Object {
    return {
      uid: this.uid,
      name: this.name,
      type: this.type,
      margin: this.margin ? this.margin : {},
      visible_input: this.visibleInput ? this.visibleInput.serialize() : null,
      align_horizontal: this.alignHorizontal,
      align_vertical: this.alignVertical,
      params: this.params
    };
  }

  copy(element: ElementItem) {
    this.deserialize(element.serialize());
  }

  get typeStr(): string {
    return undefined;
  }

  get analyticsName(): string {
    return undefined;
  }

  get analyticsGenericName(): string {
    if (!this.analyticsName) {
      return;
    }
    return ['component', this.analyticsName].join('_');
  }

  generateUid() {
    this.uid = generateAlphanumeric(8, { letterFirst: true });
  }

  defaultName() {
    return 'element';
  }

  isInline() {
    return [AlignHorizontal.Left, AlignHorizontal.Center, AlignHorizontal.Right].includes(
      this.alignHorizontalOrDefault
    );
  }

  isInlineVertical() {
    return [AlignVertical.Top, AlignVertical.Center, AlignVertical.Bottom].includes(this.alignVertical);
  }

  childrenCount() {
    return 0;
  }
}

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';

import { Option } from '../../../data/option';
import { CustomSelectItem, CustomSelectItemButton, flattenItemOptions } from '../custom-select.component';

@Component({
  selector: 'app-custom-select-dropdown',
  templateUrl: './custom-select-dropdown.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomSelectDropdownComponent implements OnInit, OnChanges {
  @Input() items: CustomSelectItem[] = [];
  @Input() currentOption: Option;
  @Input() searchEnabled = false;
  @Input() emptyLabel: string;
  @Input() capitalize = true;
  @Input() panelClasses: string[] = [];
  @Output() optionClick = new EventEmitter<Option>();
  @Output() buttonClick = new EventEmitter<CustomSelectItemButton>();

  displaySearch = false;
  search = '';
  filteredItems: { item: CustomSelectItem; active: boolean }[] = [];

  constructor(private cd: ChangeDetectorRef) {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['items']) {
      this.displaySearch = this.searchEnabled && this.items && this.items.length > 4;
      this.updateFilteredItems();
    }
  }

  updateFilteredItems() {
    const items = this.items || [];
    const filteredItems = this.search
      ? items.filter(item => {
          if (item.option) {
            return item.option.name.toLowerCase().indexOf(this.search.toLowerCase()) != -1;
          } else if (item.button) {
            return item.button.label.toLowerCase().indexOf(this.search.toLowerCase()) != -1;
          } else {
            return false;
          }
        })
      : items;

    const isChildrenActive = (item: CustomSelectItem) => {
      return (
        item.children && !!flattenItemOptions(item.children).find(childOption => childOption === this.currentOption)
      );
    };

    this.filteredItems = filteredItems.map(item => {
      if (item.option) {
        return {
          item: item,
          active: item.option === this.currentOption || isChildrenActive(item)
        };
      } else if (item.button) {
        return {
          item: item,
          active: isChildrenActive(item)
        };
      }
    });

    this.cd.markForCheck();
  }

  get panelClassesStr() {
    const classes = [...this.panelClasses];

    // if (this.searchEnabled) {
    classes.push('mat-menu-panel_no-top-padding');
    // }

    // if (this.filteredOptions.find(item => item.data && item.data['stickyBottom'])) {
    classes.push('mat-menu-panel_no-bottom-padding');
    // }

    return classes.join(' ');
  }
}

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
  ViewContainerRef
} from '@angular/core';

import { DraftItemsType, EnvironmentService } from '@modules/projects';
import { isSet } from '@shared';

export enum DraftChangeItemType {
  Created = 'created',
  Updated = 'updated',
  Deleted = 'deleted'
}

export interface DraftChangeItem {
  type: DraftItemsType;
  label: string;
  items: { type: DraftChangeItemType; name: string; id: string }[];
  singleItem?: boolean;
  count: number;
}

@Component({
  selector: 'app-draft-changes-item, [app-draft-changes-item]',
  templateUrl: './draft-changes-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DraftChangesItemComponent implements OnInit, OnDestroy {
  @Input() item: DraftChangeItem;
  @Input() disabled = false;
  @Output() publish = new EventEmitter<EnvironmentService.EntitiesOptions>();
  @Output() discard = new EventEmitter<EnvironmentService.EntitiesOptions>();

  @ViewChild('secondRow') template: TemplateRef<any>;

  expanded = false;
  hover = false;
  changeTypes = DraftChangeItemType;

  @HostListener('click') onClick() {
    if (this.item.singleItem) {
      return;
    }

    this.toggleExpanded();
  }

  @HostListener('mouseenter') onEnter() {
    this.hover = true;
    this.cd.markForCheck();
  }

  @HostListener('mouseleave') onLeave() {
    this.hover = false;
    this.cd.markForCheck();
  }

  @HostBinding('class.card-table__row_expanded') get expandedClass() {
    return this.expanded;
  }

  constructor(private vcRef: ViewContainerRef, private cd: ChangeDetectorRef) {}

  ngOnInit() {
    this.vcRef.createEmbeddedView(this.template);
  }

  ngOnDestroy(): void {}

  expand() {
    this.expanded = true;
    this.cd.markForCheck();
  }

  collapse() {
    this.expanded = false;
    this.cd.markForCheck();
  }

  toggleExpanded() {
    if (this.expanded) {
      this.collapse();
    } else {
      this.expand();
    }
  }

  publishAll() {
    this.publish.emit(this.createEntityOptions());
  }

  publishItem(id: string) {
    this.publish.emit(this.createEntityOptions(id));
  }

  discardAll() {
    this.discard.emit(this.createEntityOptions());
  }

  discardItem(id: string) {
    this.discard.emit(this.createEntityOptions(id));
  }

  createEntityOptions(id?: string): EnvironmentService.EntitiesOptions {
    return {
      itemsType: this.item.type,
      ...(isSet(id) && {
        ...(this.item.type == DraftItemsType.ViewSettings ? { viewSettings: [id] } : {}),
        ...(this.item.type == DraftItemsType.ModelDescriptions ? { modelDescriptions: [id] } : {}),
        ...(this.item.type == DraftItemsType.ActionDescriptions ? { actionDescriptions: [id] } : {}),
        ...(this.item.type == DraftItemsType.Resources ? { resources: [id] } : {}),
        ...(this.item.type == DraftItemsType.SecretTokens ? { secretTokens: [id] } : {}),
        ...(this.item.type == DraftItemsType.Storages ? { storages: [id] } : {}),
        ...(this.item.type == DraftItemsType.CustomViews ? { customViews: [id] } : {}),
        ...(this.item.type == DraftItemsType.TaskQueues ? { taskQueues: [id] } : {}),
        ...(this.item.type == DraftItemsType.ProjectProperties ? { projectProperties: [id] } : {}),
        ...(this.item.type == DraftItemsType.ProjectSettings ? { projectSettings: [id] } : {})
      })
    };
  }
}

<div
  class="compact-select"
  [class.compact-select_focus]="dropdownOpened"
  [style.width.px]="widthPx"
  cdkOverlayOrigin
  #trigger="cdkOverlayOrigin"
  (click)="setDropdownOpened(true)"
>
  <div class="compact-select__main">
    <div *ngIf="currentOption" class="compact-select__value">
      {{ currentOption.name }}
    </div>

    <div *ngIf="!currentOption && (placeholder | appIsSet)" class="compact-select__placeholder">
      {{ placeholder }}
    </div>
  </div>

  <div class="compact-select__right">
    <div class="compact-select__arrow icon-arrow_down_2"></div>
  </div>
</div>

<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayPanelClass]="['overlay', 'overlay_horizontal_center']"
  [cdkConnectedOverlayPositions]="dropdownPositions"
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayOpen]="dropdownOpened"
  [cdkConnectedOverlayPush]="true"
  [cdkConnectedOverlayGrowAfterOpen]="true"
  [cdkConnectedOverlayHasBackdrop]="true"
  [cdkConnectedOverlayBackdropClass]="'popover2-backdrop'"
  (backdropClick)="setDropdownOpened(false)"
  (detach)="setDropdownOpened(false)"
>
  <div class="compact-select-options">
    <div
      *ngFor="let item of options"
      class="compact-select-options__item compact-select-option"
      [class.compact-select-option_active]="item === currentOption"
      [class.compact-select-option_disabled]="item.disabled"
      (click)="updateValue(item.value); setDropdownOpened(false)"
    >
      <div class="compact-select-option__left">
        <div
          class="compact-select-option__checked compact-select-option__item"
          [class.icon-check_2]="item === currentOption"
        ></div>

        <div
          *ngIf="item.icon | appIsSet"
          class="compact-select-option__icon compact-select-option__item"
          [ngClass]="'icon-' + item.icon"
        ></div>
      </div>

      <div class="compact-select-option__main">
        <div class="compact-select-option__row">
          <div
            class="compact-select-option__title"
            [style]="(item.style | appIsSet) ? sanitizer.bypassSecurityTrustStyle(item.style) : null"
          >
            {{ item.name }}
          </div>

          <div *ngIf="item.nameAdditional | appIsSet" class="compact-select-option__title-additional">
            {{ item.nameAdditional }}
          </div>
        </div>

        <div *ngIf="item.subtitle | appIsSet" class="compact-select-option__row">
          <div class="compact-select-option__description">{{ item.subtitle }}</div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

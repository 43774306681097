import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

export function Customizable(constructor: any) {
  constructor['_customizable'] = true;
  return constructor;
}

export const WITH_BUILDER_MODE = '_WITH_BUILDER_MODE';

export function WithBuilderMode(constructor: any) {
  constructor[WITH_BUILDER_MODE] = true;
  return constructor;
}

export function hasBuilderMode(component: any) {
  if (!component) {
    return false;
  }
  return !!component[WITH_BUILDER_MODE];
}

export function routeHasBuilderMode(state: RouterStateSnapshot) {
  const iterate = (snapshot: ActivatedRouteSnapshot) => {
    if (snapshot.component && hasBuilderMode(snapshot.component)) {
      return true;
    } else if (snapshot.firstChild) {
      return iterate(snapshot.firstChild);
    } else {
      return false;
    }
  };

  return iterate(state.root);
}

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  ViewChild
} from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import isEqual from 'lodash/isEqual';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, merge, Observable, of, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { ViewContextElement, ViewContextOutput } from '@modules/customize';
import { FieldType } from '@modules/fields';
import { HOVER_OUTPUT, PRESSED_OUTPUT } from '@modules/list';
import {
  BorderPosition,
  FillType,
  FlexLayoutAlign,
  FlexLayoutOrientation,
  Frame,
  FrameLayer,
  includesAnyLayers,
  Layer,
  LayerInteractionType,
  LayerType,
  resizeLayers
} from '@modules/views';
import { isSet, TypedChanges } from '@shared';

import { registerLayerComponent } from '../../../data/layer-components';
import {
  ViewEditorContainer,
  ViewEditorContext,
  ViewEditorCustomizeSource
} from '../../../services/view-editor-context/view-editor.context';
import { LayerComponent } from '../base/layer.component';

interface FillItem {
  id: string;
  background?: SafeStyle;
  width?: string;
  height?: string;
  transform?: SafeStyle;
  icon?: {
    icon: string;
    color?: string;
    size: number;
  };
  opacity?: number;
  enabled: boolean;
}

interface BorderItem {
  border: SafeStyle;
  position: number;
  borderRadius: SafeStyle;
  enabled: boolean;
}

@Component({
  selector: 'app-frame-layer',
  templateUrl: './frame-layer.component.html',
  providers: [ViewContextElement],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FrameLayerComponent extends LayerComponent<FrameLayer> implements OnInit, OnDestroy, OnChanges {
  @ViewChild('frame_element') frameElement: ElementRef;

  nestedContainer: ViewEditorContainer;
  hover$: Observable<boolean>;
  customizing$: Observable<boolean>;
  customizingMultiple$: Observable<boolean>;
  layersInteraction = true;
  fills: FillItem[] = [];
  fillsSubscription: Subscription;
  borders: BorderItem[] = [];
  bordersSubscription: Subscription;
  borderRadius: SafeStyle;
  boxShadow: SafeStyle;
  boxShadowSubscription: Subscription;
  displayItems: Layer[] = [];
  displayItemsSubscription: Subscription;
  alignItems: FlexLayoutAlign;
  justifyContent: FlexLayoutAlign;
  layersStyle: SafeStyle = null;
  flexLayoutOrientations = FlexLayoutOrientation;
  flexLayoutAligns = FlexLayoutAlign;

  trackFillFn(i, item: FillItem) {
    return item.id;
  }

  trackLayerFn(i, item: Layer) {
    return item.id;
  }

  constructor(
    @Optional() editorContext: ViewEditorContext,
    public contextElement: ViewContextElement,
    private sanitizer: DomSanitizer,
    private cd: ChangeDetectorRef
  ) {
    super(editorContext);
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.registerNestedContainer();

    if (this.editorContext) {
      this.hover$ = this.editorContext.isTopHoverLayer$(this.layer);
      this.customizing$ = this.editorContext.isCustomizingLayer$(this.layer);
      this.customizingMultiple$ = this.editorContext.isCustomizingMultipleLayers$();
      this.updating$ = this.getLayerUpdating$(() => !this.editorContext.isCreateTool());

      merge(
        this.editorContext
          .layerChanged$()
          .pipe(filter(event => !!this.layer.layers.find(item => item.isSame(event.layer)))),
        this.editorContext.layerContainerChanged$().pipe(filter(event => this.layer.layers === event.container))
      )
        .pipe(untilDestroyed(this))
        .subscribe(() => this.updateDisplayItems());

      combineLatest(this.editorContext.ctrlKeydown$, this.editorContext.customizingLayers$)
        .pipe(untilDestroyed(this))
        .subscribe(([ctrlKeydown, customizingLayers]) => {
          this.layersInteraction = ctrlKeydown || includesAnyLayers(this.layer.layers, customizingLayers);
          this.cd.markForCheck();
        });

      this.initFitContainer();
    }

    this.getLayer$()
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.updateNestedContainer();
        this.updateFills();
        this.updateBorders();
        this.updateBorderRadius();
        this.updateBoxShadows();
        this.updateDisplayItems();
        this.updateLayersLayout();
        this.updateLayersStyle();
        this.updateLayerContext();
      });
  }

  ngOnDestroy(): void {
    this.unregisterNestedContainer();
  }

  ngOnChanges(changes: TypedChanges<FrameLayerComponent>) {
    super.ngOnChanges(changes);

    if (this.editorContext) {
      const layerChanged = changes.layer && !changes.layer.firstChange;
      const translateChanged = changes.translate && !changes.translate.firstChange;

      if (layerChanged) {
        this.unregisterNestedContainer();
        this.registerNestedContainer();
      } else if (translateChanged) {
        this.updateNestedContainer();
      }
    }
  }

  registerNestedContainer() {
    if (!this.editorContext) {
      return;
    }

    this.nestedContainer = this.editorContext.registerContainer(this.layer.layers, {
      layer: this.layer,
      translate: {
        x: this.translate.x + this.layer.frame.x,
        y: this.translate.y + this.layer.frame.y
      }
    });
  }

  unregisterNestedContainer() {
    if (!this.editorContext) {
      return;
    }

    if (!this.nestedContainer) {
      return;
    }

    this.editorContext.unregisterContainer(this.nestedContainer.container);
    this.nestedContainer = undefined;
  }

  updateNestedContainer() {
    if (!this.editorContext) {
      return;
    }

    if (!this.nestedContainer) {
      return;
    }

    this.nestedContainer.patchOptions({
      translate: {
        x: this.translate.x + this.layer.frame.x,
        y: this.translate.y + this.layer.frame.y
      }
    });
  }

  updateFills() {
    if (this.fillsSubscription) {
      this.fillsSubscription.unsubscribe();
      this.fillsSubscription = undefined;
    }

    const fills$ = [...this.layer.fills]
      .reverse()
      .filter(item => item.enabled)
      .map(item => {
        const icon$ =
          item.type == FillType.Icon && item.iconFill
            ? item.iconFill.display$({ context: this.viewContext }).pipe(
                map(value => {
                  return {
                    icon: value.icon,
                    color: value.color,
                    size: isSet(item.iconFill.size)
                      ? item.iconFill.size
                      : Math.min(this.layer.frame.width, this.layer.frame.height)
                  };
                })
              )
            : of(undefined);
        const css$ = item.css$({ frame: this.layer.frame, context: this.viewContext });
        const enabled$ = item.enabledInput ? item.enabled$({ context: this.viewContext }) : of(true);

        return combineLatest(icon$, css$, enabled$).pipe(
          map(([icon, css, enabled]) => {
            return {
              id: item.id,
              background: isSet(css.background) ? this.sanitizer.bypassSecurityTrustStyle(css.background) : undefined,
              width: css.width,
              height: css.height,
              transform: isSet(css.transform) ? this.sanitizer.bypassSecurityTrustStyle(css.transform) : undefined,
              icon: icon,
              opacity: item.type != FillType.Color ? item.opacity : null,
              enabled: enabled
            };
          })
        );
      });

    if (!fills$.length) {
      this.fills = [];
      this.cd.markForCheck();
      return;
    }

    this.fillsSubscription = combineLatest(fills$)
      .pipe(untilDestroyed(this))
      .subscribe(fills => {
        this.fills = fills.filter(item => item.enabled);
        this.cd.markForCheck();
      });
  }

  updateBorders() {
    if (this.bordersSubscription) {
      this.bordersSubscription.unsubscribe();
      this.bordersSubscription = undefined;
    }

    const borders$ = [...this.layer.borders]
      .reverse()
      .filter(item => item.enabled)
      .map(item => {
        const border$ = item.cssBorder$({ context: this.viewContext });
        const enabled$ = item.enabledInput ? item.enabled$({ context: this.viewContext }) : of(true);

        return combineLatest(border$, enabled$).pipe(
          map(([border, enabled]) => {
            let position: number;

            if (item.position == BorderPosition.Center) {
              position = -item.thickness * 0.5;
            } else if (item.position == BorderPosition.Outside) {
              position = -item.thickness;
            } else {
              position = 0;
            }

            const borderRadius = this.layer.cornerRadius.cssBorderRadius(position * -1);

            return {
              border: isSet(border) ? this.sanitizer.bypassSecurityTrustStyle(border) : undefined,
              position: position,
              borderRadius: this.sanitizer.bypassSecurityTrustStyle(borderRadius),
              enabled: enabled
            };
          })
        );
      });

    if (!borders$.length) {
      this.borders = [];
      this.cd.markForCheck();
      return;
    }

    this.bordersSubscription = combineLatest(borders$)
      .pipe(untilDestroyed(this))
      .subscribe(borders => {
        this.borders = borders.filter(item => item.enabled && isSet(item.border));
        this.cd.markForCheck();
      });
  }

  updateBorderRadius() {
    this.borderRadius = this.sanitizer.bypassSecurityTrustStyle(this.layer.cornerRadius.cssBorderRadius());
    this.cd.markForCheck();
  }

  updateBoxShadows() {
    if (this.boxShadowSubscription) {
      this.boxShadowSubscription.unsubscribe();
      this.boxShadowSubscription = undefined;
    }

    const shadows$ = this.layer.shadows
      .filter(item => item.enabled)
      .map(item => {
        const boxShadow$ = item.cssBoxShadow$({ context: this.viewContext });
        const enabled$ = item.enabledInput ? item.enabled$({ context: this.viewContext }) : of(true);

        return combineLatest(boxShadow$, enabled$).pipe(
          map(([boxShadow, enabled]) => {
            return {
              boxShadow: boxShadow,
              enabled: enabled
            };
          })
        );
      });

    if (!shadows$.length) {
      this.boxShadow = undefined;
      this.cd.markForCheck();
      return;
    }

    this.boxShadowSubscription = combineLatest(shadows$)
      .pipe(untilDestroyed(this))
      .subscribe(shadows => {
        this.boxShadow = this.sanitizer.bypassSecurityTrustStyle(
          shadows
            .filter(item => item.enabled)
            .map(item => item.boxShadow)
            .join(',')
        );
        this.cd.markForCheck();
      });
  }

  updateDisplayItems() {
    if (this.displayItemsSubscription) {
      this.displayItemsSubscription.unsubscribe();
      this.displayItemsSubscription = undefined;
    }

    const items$ = this.layer.layers
      .filter(item => item.visible)
      .map(item => {
        const visible$ = item.visibleInput ? item.visible$({ context: this.viewContext }) : of(true);

        return combineLatest(visible$).pipe(
          map(([visible]) => {
            return {
              item: item,
              visible: visible
            };
          })
        );
      });

    if (!items$.length) {
      this.displayItems = [];
      this.cd.markForCheck();
      return;
    }

    this.displayItemsSubscription = combineLatest(items$)
      .pipe(untilDestroyed(this))
      .subscribe(items => {
        this.displayItems = items.filter(item => item.visible).map(item => item.item);
        this.cd.markForCheck();
      });
  }

  updateLayersLayout() {
    const orientation = this.layer.flexLayout ? this.layer.flexLayout.orientation : undefined;

    if (orientation == FlexLayoutOrientation.Horizontal) {
      this.alignItems = this.layer.flexLayout.alignVertical;
      this.justifyContent = this.layer.flexLayout.alignHorizontal;
    } else if (orientation == FlexLayoutOrientation.Vertical) {
      this.alignItems = this.layer.flexLayout.alignHorizontal;
      this.justifyContent = this.layer.flexLayout.alignVertical;
    } else {
      this.alignItems = undefined;
      this.justifyContent = undefined;
    }

    this.cd.markForCheck();
  }

  updateLayersStyle() {
    const gapHorizontal = this.layer.flexLayout ? this.layer.flexLayout.gapHorizontal : 0;
    const gapVertical = this.layer.flexLayout ? this.layer.flexLayout.gapVertical : 0;
    const paddingTop = this.layer.flexLayout ? this.layer.flexLayout.paddingTop : undefined;
    const paddingRight = this.layer.flexLayout ? this.layer.flexLayout.paddingRight : undefined;
    const paddingBottom = this.layer.flexLayout ? this.layer.flexLayout.paddingBottom : undefined;
    const paddingLeft = this.layer.flexLayout ? this.layer.flexLayout.paddingLeft : undefined;
    const styles = [`--flex-gap-horizontal: ${gapHorizontal}px`, `--flex-gap-vertical: ${gapVertical}px`];

    if (paddingTop) {
      styles.push(`padding-top: ${paddingTop}px`);
    }

    if (paddingRight) {
      styles.push(`padding-right: ${paddingRight}px`);
    }

    if (paddingBottom) {
      styles.push(`padding-bottom: ${paddingBottom}px`);
    }

    if (paddingLeft) {
      styles.push(`padding-left: ${paddingLeft}px`);
    }

    this.layersStyle = this.sanitizer.bypassSecurityTrustStyle(styles.join(';'));
    this.cd.markForCheck();
  }

  updateLayerContext() {
    const hoverOutput = this.layer.interactions.some(item => item.type == LayerInteractionType.HoverOutput);
    const pressedOutput = this.layer.interactions.some(item => item.type == LayerInteractionType.PressedOutput);
    const anyOutputs = hoverOutput || pressedOutput;
    const registered = this.contextElement.isRegistered();

    if (anyOutputs && !registered) {
      this.contextElement.initElement({
        uniqueName: this.layer.id,
        name: this.layer.name,
        icon: this.layer.icon
      });
    } else if (anyOutputs && registered) {
      this.contextElement.initInfo(
        {
          name: this.layer.name,
          icon: this.layer.icon
        },
        true
      );
    } else if (!anyOutputs && registered) {
      this.contextElement.unregister();
    }

    if (anyOutputs) {
      const outputs: ViewContextOutput[] = [];

      if (hoverOutput) {
        outputs.push({
          uniqueName: HOVER_OUTPUT,
          name: `Layer is hovered`,
          icon: 'target',
          fieldType: FieldType.Boolean,
          defaultValue: false,
          external: true
        });
      }

      if (pressedOutput) {
        outputs.push({
          uniqueName: PRESSED_OUTPUT,
          name: `Layer is pressed`,
          icon: 'select_all',
          fieldType: FieldType.Boolean,
          defaultValue: false,
          external: true
        });
      }

      if (
        !isEqual(
          this.contextElement.outputs.map(item => item.uniqueName),
          outputs.map(item => item.uniqueName)
        )
      ) {
        this.contextElement.setOutputs(outputs);
      }
    }
  }

  deleteLayer(index: number) {
    this.layer.layers.splice(index, 1);
    this.cd.markForCheck();
    this.editorContext.markLayerContainerChanged(this.layer.layers, ViewEditorCustomizeSource.View);
  }

  initFitContainer() {
    this.trackLayerFluidSize(this.frameElement.nativeElement)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.cd.markForCheck();
      });

    this.trackLayerChildrenFrames(layer => layer.widthFluid || layer.heightFluid)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.editorContext.fitContainer(this.layer, ViewEditorCustomizeSource.Layer);
      });
  }

  onFrameUpdate(frame: Partial<Frame>) {
    if (!this.layer.flexLayout) {
      resizeLayers({
        layers: this.layer.layers,
        originalFrame: this.layer.frame,
        newFrame: new Frame(this.layer.frame).patch(frame)
      });
      this.editorContext.markLayersChanged(this.layer.layers, ViewEditorCustomizeSource.ParentLayer);
    }

    super.onFrameUpdate(frame);
    this.updateNestedContainer();
    this.updateFills();
  }
}

registerLayerComponent(LayerType.Frame, FrameLayerComponent);

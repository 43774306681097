import { fromEvent } from 'rxjs';
import { filter } from 'rxjs/operators';
import { keyboardChars, KeyboardEventKeyCode } from '@shared';
import * as i0 from "@angular/core";
var ScreenRecordService = /** @class */ (function () {
    function ScreenRecordService() {
    }
    ScreenRecordService.prototype.cheatCode$ = function (code) {
        var cheatCode = code
            .toUpperCase()
            .split('')
            .map(function (item) { return keyboardChars.find(function (i) { return i.char == item; }); });
        var step = 0;
        return fromEvent(window, 'keydown').pipe(filter(function (event) { return event.keyCode !== KeyboardEventKeyCode.Shift; }), filter(function (event) {
            var char = cheatCode[step];
            if (event.keyCode === char.code && (!char.shift || event.shiftKey)) {
                step += 1;
                if (step === cheatCode.length) {
                    step = 0;
                    return true;
                }
            }
            else {
                step = 0;
            }
            return false;
        }));
    };
    ScreenRecordService.ngInjectableDef = i0.defineInjectable({ factory: function ScreenRecordService_Factory() { return new ScreenRecordService(); }, token: ScreenRecordService, providedIn: "root" });
    return ScreenRecordService;
}());
export { ScreenRecordService };

import { ComponentFactoryResolver, Injector } from '@angular/core';
import { Subject } from 'rxjs';
import { ThinDialogPopupComponent } from '@common/dialog-popup';
import { PopupService } from '@common/popups';
import { ProjectCreatePopupComponent } from '../../components/project-create-popup/project-create-popup.component';
import * as i0 from "@angular/core";
import * as i1 from "../../../../common/popups/services/popup/popup.service";
var ProjectCreateController = /** @class */ (function () {
    function ProjectCreateController(injector, popupService, resolver) {
        this.injector = injector;
        this.popupService = popupService;
        this.resolver = resolver;
    }
    ProjectCreateController.prototype.openCreatePopup = function (options) {
        if (options === void 0) { options = {}; }
        var result = new Subject();
        this.popupService.push({
            component: ProjectCreatePopupComponent,
            popupComponent: ThinDialogPopupComponent,
            popupComponentDark: options.dark,
            inputs: {
                applyTemplateItem: options.applyTemplateItem,
                domain: options.domain
            },
            outputs: {
                created: [
                    function (project) {
                        result.next(project);
                    }
                ]
            },
            resolver: this.resolver,
            injector: this.injector
        });
        return result.asObservable();
    };
    ProjectCreateController.ngInjectableDef = i0.defineInjectable({ factory: function ProjectCreateController_Factory() { return new ProjectCreateController(i0.inject(i0.INJECTOR), i0.inject(i1.PopupService), i0.inject(i0.ComponentFactoryResolver)); }, token: ProjectCreateController, providedIn: "root" });
    return ProjectCreateController;
}());
export { ProjectCreateController };

import { AfterViewInit, Directive, ElementRef, Injector, Input, OnDestroy } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { fromEvent } from 'rxjs';

import { PopupService } from '@common/popups';

import { VideoPopupComponent } from '../../components/video-popup/video-popup.component';

export interface ShowVideoOptions {
  src?: string;
  youtubeId?: string;
  startTime?: number;
}

@Directive({
  selector: '[appShowVideo]'
})
export class ShowVideoDirective implements OnDestroy, AfterViewInit {
  @Input() appShowVideo: ShowVideoOptions = {};

  constructor(private el: ElementRef, private popupService: PopupService, private injector: Injector) {}

  ngOnDestroy(): void {}

  ngAfterViewInit(): void {
    fromEvent(this.el.nativeElement, 'click')
      .pipe(untilDestroyed(this))
      .subscribe(() => this.openVideo(this.el.nativeElement));
  }

  openVideo(sourceElement: Element) {
    this.popupService.push({
      component: VideoPopupComponent,
      inputs: {
        src: this.appShowVideo.src,
        youtubeId: this.appShowVideo.youtubeId,
        autoPlay: true,
        startTime: this.appShowVideo.startTime,
        sourceElement: sourceElement
      },
      injector: this.injector
    });
  }
}

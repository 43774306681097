import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ConnectedPosition } from '@angular/cdk/overlay';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { take } from 'rxjs/operators';

import { ViewContext } from '@modules/customize';
import { LayerInteractionType } from '@modules/views';
import { controlValue } from '@shared';

import { LayerInteractionArray } from '../../controls/layer-interaction.array';
import { LayerInteractionControl } from '../../controls/layer-interaction.control';
import { InteractionsSectionClickComponent } from '../interactions-section-click/interactions-section-click.component';

@Component({
  selector: 'app-interactions-section',
  templateUrl: './interactions-section.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InteractionsSectionComponent implements OnInit, OnDestroy {
  @Input() control: LayerInteractionArray;
  @Input() viewContext: ViewContext;
  @Input() textInteractions = false;

  @ViewChildren(InteractionsSectionClickComponent) clickComponents = new QueryList<InteractionsSectionClickComponent>();

  hoverOutputExists = false;
  pressedOutputExists = false;
  interactionsDropdownOpened = false;
  interactionsDropdownPositions: ConnectedPosition[] = [
    { originX: 'end', overlayX: 'end', originY: 'bottom', overlayY: 'top', offsetX: 0, offsetY: 4 },
    { originX: 'center', overlayX: 'center', originY: 'top', overlayY: 'bottom', offsetX: 0, offsetY: -4 },
    { originX: 'center', overlayX: 'center', originY: 'bottom', overlayY: 'top', offsetX: 0, offsetY: 4 },
    { originX: 'end', overlayX: 'end', originY: 'top', overlayY: 'bottom', offsetX: 0, offsetY: -4 }
  ];
  types = LayerInteractionType;

  constructor(private zone: NgZone, private cd: ChangeDetectorRef) {}

  ngOnInit() {
    controlValue(this.control)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.hoverOutputExists = this.control.controls.some(item => item.type == LayerInteractionType.HoverOutput);
        this.pressedOutputExists = this.control.controls.some(item => item.type == LayerInteractionType.PressedOutput);
        this.cd.markForCheck();
      });
  }

  ngOnDestroy(): void {}

  setInteractionsDropdownOpened(value: boolean) {
    this.interactionsDropdownOpened = value;
    this.cd.markForCheck();
  }

  addHoverOutputInteraction() {
    this.control.appendControl(LayerInteractionType.HoverOutput);
  }

  addPressedOutputInteraction() {
    this.control.appendControl(LayerInteractionType.PressedOutput);
  }

  addClickInteraction() {
    const control = this.control.appendControl(LayerInteractionType.Click);
    let cursorInteraction = this.control.controls.find(item => item.type == LayerInteractionType.Cursor);

    if (!cursorInteraction) {
      cursorInteraction = this.control.appendControl(LayerInteractionType.Cursor);
      cursorInteraction.controls.cursor.patchValue('pointer');
    }

    this.zone.onStable
      .pipe(take(1))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        const component = this.clickComponents.find(item => item.control === control);
        if (component) {
          component.customizeAction();
        }
      });
  }

  addCursorInteraction(cursor: string) {
    const control =
      this.control.controls.find(item => item.type == LayerInteractionType.Cursor) ||
      this.control.appendControl(LayerInteractionType.Cursor);
    control.controls.cursor.patchValue(cursor);
  }

  getDisableTextSelectionControl(): LayerInteractionControl {
    return this.control.controls.find(item => item.type == LayerInteractionType.DisableTextSelection);
  }

  isTextSelectionEnabled(): boolean {
    return !this.getDisableTextSelectionControl();
  }

  enableTextSelection() {
    const control = this.getDisableTextSelectionControl();
    if (control) {
      this.control.removeControl(control);
    }
  }

  disableTextSelection() {
    this.control.appendControl(LayerInteractionType.DisableTextSelection);
  }

  interactionDragDrop(event: CdkDragDrop<LayerInteractionControl[]>) {
    if (event.previousIndex !== event.currentIndex) {
      moveItemInArray(this.control.controls, event.previousIndex, event.currentIndex);
      this.control.updateValueAndValidity();
    }
  }
}

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

import { OnBeforeDestroy } from '../interfaces/on-before-destroy.interface';

@Injectable({
  providedIn: 'root'
})
export class OnBeforeDestroyGuard implements CanDeactivate<OnBeforeDestroy> {
  getLastRoute(route: ActivatedRouteSnapshot): ActivatedRouteSnapshot {
    if (route.firstChild) {
      return this.getLastRoute(route.firstChild);
    } else {
      return route;
    }
  }

  canDeactivate(
    component: OnBeforeDestroy,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const currentRouteLast = this.getLastRoute(currentState.root);
    const nextRouteLast = nextState ? this.getLastRoute(nextState.root) : undefined;
    const currentRouteComponent = currentRouteLast ? currentRouteLast.component : undefined;
    const nextRouteComponent = nextRouteLast ? nextRouteLast.component : undefined;

    if (nextRouteComponent && nextRouteComponent === currentRouteComponent) {
      return true;
    }

    if (component && component.onBeforeDestroy) {
      component.onBeforeDestroy();
    }

    return true;
  }
}

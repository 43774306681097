import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

export type DescriptionAlign = 'left' | 'center' | 'right';

@Component({
  selector: 'app-page-description',
  templateUrl: './page-description.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PageDescriptionComponent implements OnInit {
  @Input() align: DescriptionAlign = 'left';

  constructor() {}

  ngOnInit() {}
}

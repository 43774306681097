import { Type } from '@angular/core';

import { ViewSettingsAction } from '@modules/actions';
import { ParameterField } from '@modules/fields';
import { ListLayoutType } from '@modules/layouts';

import { CalendarSettings } from '../calendar-settings';
import { CarouselSettings } from '../carousel-settings';
import { GridSettings } from '../grid-settings';
import { KanbanBoardSettings } from '../kanban-board-settings';
import { ListLayoutSettings } from '../list-layout-settings';
import { MapSettings } from '../map-settings';
import { TableSettings } from '../table-settings';
import { TimelineSettings } from '../timeline-settings';
import { ViewSettings, ViewSettingsType } from './base';

export function getListLayoutSettingsClass(type: ListLayoutType): Type<ListLayoutSettings> {
  if (type == ListLayoutType.Table) {
    return TableSettings;
  } else if (type == ListLayoutType.Map) {
    return MapSettings;
  } else if (type == ListLayoutType.KanbanBoard) {
    return KanbanBoardSettings;
  } else if (type == ListLayoutType.Calendar) {
    return CalendarSettings;
  } else if (type == ListLayoutType.Grid) {
    return GridSettings;
  } else if (type == ListLayoutType.Carousel) {
    return CarouselSettings;
  } else if (type == ListLayoutType.Timeline) {
    return TimelineSettings;
  }
}

export class ListViewSettings extends ViewSettings {
  public view = ViewSettingsType.List;
  public layouts: ListLayoutSettings[] = [];

  deserialize(data: Object) {
    super.deserialize(data);

    if (this.params['parameters']) {
      this.parameters = this.params['parameters'].map(item => new ParameterField().deserialize(item));
    }

    if (this.params['layouts'] || this.params['layout']) {
      const layoutsData = this.params['layouts'] || [this.params['layout']];
      this.layouts = layoutsData
        .map(item => {
          if (typeof item == 'string') {
            item = { type: item };
          }

          const cls = getListLayoutSettingsClass(item['type']);

          if (!cls) {
            return;
          }

          if (item['title'] === undefined && this.name) {
            item['title'] = this.name;
          }

          return new cls().deserialize(item);
        })
        .filter(item => item != undefined);
    } else {
      this.layouts = [new TableSettings()];
    }

    if (this.params['default_layout']) {
      this.layouts = this.layouts.sort((lhs, rhs) => {
        const defaultType = this.params['default_layout'];
        if (lhs.type == defaultType && rhs.type != defaultType) {
          return -1;
        } else if (lhs.type != defaultType && rhs.type == defaultType) {
          return 1;
        } else {
          return 0;
        }
      });
    }

    if (this.params['table_settings']) {
      const settings = this.layouts.find(item => item.type == ListLayoutType.Table);
      if (settings) {
        settings.deserialize(this.params['table_settings']);
      }
    } else if (this.params['columns']) {
      const settings = this.layouts.find(item => item.type == ListLayoutType.Table);
      if (settings) {
        settings.deserialize({
          columns: this.params['columns'],
          date_field: this.params['calendar_date_field'],
          display1_field: this.params['calendar_display1_field'],
          display2_field: this.params['calendar_display2_field'],
          display3_field: this.params['calendar_display3_field'],
          model_actions: this.params['table_model_actions']
        });
      }
    }

    if (this.params['map_settings']) {
      const settings = this.layouts.find(item => item.type == ListLayoutType.Map);
      if (settings) {
        settings.deserialize(this.params['map_settings']);
      }
    } else if (this.params['map_columns']) {
      const settings = this.layouts.find(item => item.type == ListLayoutType.Map);
      if (settings) {
        settings.deserialize({
          columns: this.params['map_columns'],
          location_storage: this.params['map_location_storage'],
          location_field: this.params['map_location_field'],
          location_latitude_field: this.params['map_location_latitude_field'],
          location_longitude_field: this.params['map_location_longitude_field'],
          model_actions: this.params['map_model_actions']
        });
      }
    }

    if (this.params['kanban_board_settings']) {
      const settings = this.layouts.find(item => item.type == ListLayoutType.KanbanBoard);
      if (settings) {
        settings.deserialize(this.params['kanban_board_settings']);
      }
    } else if (this.params['kanban_board_columns']) {
      const settings = this.layouts.find(item => item.type == ListLayoutType.KanbanBoard);
      if (settings) {
        settings.deserialize({
          columns: this.params['kanban_board_columns'],
          stage_field: this.params['kanban_board_stage_field'],
          stages: this.params['kanban_board_stages']
        });
      }
    }

    if (this.params['calendar_settings']) {
      const settings = this.layouts.find(item => item.type == ListLayoutType.Calendar);
      if (settings) {
        settings.deserialize(this.params['calendar_settings']);
      }
    } else if (this.params['calendar_date_field']) {
      const settings = this.layouts.find(item => item.type == ListLayoutType.Calendar);
      if (settings) {
        settings.deserialize({
          date_field: this.params['calendar_date_field'],
          display1_field: this.params['calendar_display1_field'],
          display2_field: this.params['calendar_display2_field'],
          display3_field: this.params['calendar_display3_field']
        });
      }
    }

    if (this.params['grid_settings']) {
      const settings = this.layouts.find(item => item.type == ListLayoutType.Grid);
      if (settings) {
        settings.deserialize(this.params['grid_settings']);
      }
    } else if (this.params['grid_columns']) {
      const settings = this.layouts.find(item => item.type == ListLayoutType.Grid);
      if (settings) {
        settings.deserialize({
          columns: this.params['grid_columns']
        });
      }
    }

    if (this.params['actions']) {
      this.layouts.forEach(layout => {
        layout.actions = this.params['actions'].map(item => new ViewSettingsAction().deserialize(item));
      });
    }

    return this;
  }

  serialize(fields?: string[]): Object {
    this.params = {};
    this.params['parameters'] = this.parameters.map(item => item.serialize());
    this.params['layouts'] = this.layouts.map(item => item.serialize());

    return super.serialize(fields);
  }

  getOrCreateLayout(layout: ListLayoutType) {
    const settings = this.layouts.find(item => item.type == layout);

    if (settings) {
      return settings;
    } else {
      const cls = getListLayoutSettingsClass(layout);
      const instance = new cls();
      this.layouts = [...this.layouts, instance];
      return new cls();
    }
  }
}

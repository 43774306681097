<app-actions-edit-item
  [actionItemClass]="options.actionItemClass"
  [title]="title"
  [action]="control.value"
  [context]="context"
  [contextElement]="contextElement"
  [disabled]="defaultDisabled && !control.value"
  [defaultSubtitle]="defaultSubtitle"
  [remove]="remove && control.value"
  [error]="control.invalid && control.parent?.dirty ? 'Not configured' : undefined"
  [icon]="icon"
  (open)="editActionItem(control, title)"
  (removeClick)="removeActionItem(control)"
>
</app-actions-edit-item>

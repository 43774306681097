<div class="background-tabs">
  <app-background-tab
    *ngFor="let item of tabComponents"
    [link]="item.link"
    [label]="item.label"
    [active]="item.active"
    (tabClick)="tabClick.emit(item.name)"
    class="background-tabs__item"
  ></app-background-tab>
</div>

<app-customize-bar-header [title]="'Appearance'" [subtitle]="'Sign In & Sign Up'" [backEnabled]="false">
</app-customize-bar-header>

<div class="sidebar__content">
  <div *ngIf="!domain" class="sidebar__element">
    <app-alert [title]="'Domain is not set up'" [secondary]="true">
      <div>To customize external pages you should connect custom domain to current App</div>
      <div style="margin-top: 4px;">
        <a href="javascript:void(0)" (click)="editDomain()">Connect domain</a>
      </div>
    </app-alert>
  </div>

  <app-sidebar-section [title]="'Layout'" [interactive]="!domain" (click)="onDomainRequiredClick()">
    <div class="sidebar__element">
      <div
        class="sidebar-image-toggle sidebar-image-toggle_cols_3"
        [class.sidebar-image-toggle_disabled]="projectDomainUpdateForm.controls.sign_up_layout.disabled"
      >
        <div *ngFor="let item of layoutOptions" class="sidebar-image-toggle__col">
          <a
            href="javascript:void(0)"
            class="sidebar-image-toggle__item"
            [class.sidebar-image-toggle__item_active]="
              item.layout === projectDomainUpdateForm.controls.sign_up_layout.value
            "
            (click)="projectDomainUpdateForm.controls.sign_up_layout.patchValue(item.layout)"
          >
            <div
              class="sidebar-image-toggle__item-inner"
              [style.padding-top.%]="(1 / 1) * 100"
              [style.background-image]="'/assets/images/sign-up-layouts/' + item.layout + '.svg' | appDeployCssUrl"
            ></div>
          </a>
        </div>
      </div>
    </div>

    <div *ngIf="currentLayoutOption?.backgroundImageEnabled" class="sidebar__element">
      <app-sidebar-field [label]="'background image'">
        <app-auto-field
          [form]="projectDomainUpdateForm"
          [label]="false"
          [field]="
            createField({
              name: 'sign_up_background_image',
              field: 'ImageField',
              params: {
                path: 'logo',
                storage_resource: projectDomainUpdateForm.getUploaderResource()?.uniqueName,
                storage_name: projectDomainUpdateForm.getUploaderResourceStorage()?.uniqueName,
                ignore_old_django_format: true,
                editor: false,
                fit: imageFits.Fit,
                background: true
              }
            })
          "
        ></app-auto-field>
      </app-sidebar-field>
    </div>

    <div class="sidebar__element">
      <app-sidebar-field [label]="'Background color'">
        <app-color-options
          [resetEnabled]="true"
          [resetBackgroundColor]="currentLayoutOption?.defaultBackgroundColor"
          [resetBorderColor]="'bright-blue'"
          [colors]="signUpBackgroundColors"
          [colorControl]="projectDomainUpdateForm.controls.sign_up_background_color"
          [customColorEnabledControl]="projectDomainUpdateForm.controls.sign_up_background_color_custom_enabled"
          [customColorControl]="projectDomainUpdateForm.controls.sign_up_background_color_custom"
        ></app-color-options>
      </app-sidebar-field>
    </div>

    <div class="sidebar__element">
      <app-sidebar-field [label]="'Buttons color'">
        <app-color-options
          [resetEnabled]="true"
          [resetBackgroundColor]="currentLayoutOption?.defaultAccentColor"
          [resetBorderColor]="'bright-blue'"
          [colors]="signUpAccentColors"
          [colorControl]="projectDomainUpdateForm.controls.sign_up_accent_color"
          [customColorEnabledControl]="projectDomainUpdateForm.controls.sign_up_accent_color_custom_enabled"
          [customColorControl]="projectDomainUpdateForm.controls.sign_up_accent_color_custom"
        ></app-color-options>
      </app-sidebar-field>
    </div>

    <div class="sidebar__element">
      <app-sidebar-field [label]="'Fonts'">
        <div class="sidebar__element-segment">
          <app-font-control
            [control]="projectDomainUpdateForm.controls.sign_up_font_regular"
            [prefix]="'Regular'"
            [fill]="true"
          ></app-font-control>
        </div>

        <div class="sidebar__element-segment">
          <app-font-control
            [control]="projectDomainUpdateForm.controls.sign_up_font_heading"
            [prefix]="'Heading'"
            [fill]="true"
          ></app-font-control>
        </div>
      </app-sidebar-field>
    </div>
  </app-sidebar-section>

  <app-sidebar-section [interactive]="!domain" (click)="onDomainRequiredClick()">
    <div class="sidebar__element">
      <app-sidebar-field [label]="'white-label'" [layoutLeft]="true">
        <app-auto-field
          [form]="projectDomainUpdateForm"
          [label]="false"
          [field]="
            createField({
              name: 'whiteLabel',
              field: 'BooleanField',
              params: { style: booleanFieldStyle.Toggle, orange: true }
            })
          "
        ></app-auto-field>

        <ng-container description>
          Replace Jet Admin branding with your own
        </ng-container>
      </app-sidebar-field>
    </div>
  </app-sidebar-section>

  <app-sidebar-section [border]="false" [interactive]="!domain" (click)="onDomainRequiredClick()">
    <div class="sidebar__element">
      <app-sidebar-field [label]="'brand name'">
        <app-auto-field
          [form]="projectDomainUpdateForm"
          [label]="false"
          [field]="
            createField({
              name: 'name',
              field: 'CharField',
              placeholder: 'Brand name',
              params: { classes: ['input_fill'] }
            })
          "
        ></app-auto-field>

        <ng-container description>
          Displayed in Sign In & Sign Up pages
        </ng-container>
      </app-sidebar-field>
    </div>

    <div class="sidebar__element">
      <app-sidebar-field [label]="'logo'">
        <app-auto-field
          [form]="projectDomainUpdateForm"
          [label]="false"
          [field]="
            createField({
              name: 'logo',
              field: 'ImageField',
              params: {
                path: 'logo',
                storage_resource: projectDomainUpdateForm.getUploaderResource()?.uniqueName,
                storage_name: projectDomainUpdateForm.getUploaderResourceStorage()?.uniqueName,
                ignore_old_django_format: true,
                editor: false,
                fit: imageFits.Fit,
                background: true
              }
            })
          "
        ></app-auto-field>

        <ng-container description>
          Recommended size is 180x180 or more
        </ng-container>
      </app-sidebar-field>
    </div>

    <div class="sidebar__element">
      <app-sidebar-field [label]="'Logo background'">
        <app-color-options
          [resetEnabled]="true"
          [resetBackgroundColor]="'bright-blue'"
          [resetBorderColor]="'bright-blue'"
          [resetValue]="'blue'"
          [colors]="logoColors"
          [colorControl]="projectDomainUpdateForm.controls.logo_color"
          [customColorEnabledControl]="projectDomainUpdateForm.controls.logo_color_custom_enabled"
          [customColorControl]="projectDomainUpdateForm.controls.logo_color_custom"
        ></app-color-options>
      </app-sidebar-field>
    </div>

    <div class="sidebar__element">
      <app-sidebar-field [label]="'favicon'">
        <app-auto-field
          [form]="projectDomainUpdateForm"
          [label]="false"
          [field]="
            createField({
              name: 'icon',
              field: 'ImageField',
              params: {
                path: 'icon',
                storage_resource: projectDomainUpdateForm.getUploaderResource()?.uniqueName,
                storage_name: projectDomainUpdateForm.getUploaderResourceStorage()?.uniqueName,
                ignore_old_django_format: true,
                editor: false,
                fit: imageFits.Fit,
                background: true
              }
            })
          "
        ></app-auto-field>

        <ng-container description>
          Recommended size is 32x32, .png or .ico
        </ng-container>
      </app-sidebar-field>
    </div>

    <!--    <div class="sidebar__element">-->
    <!--      <app-sidebar-field-->
    <!--        [label]="'Advanced customization'"-->
    <!--        [layoutLeft]="true"-->
    <!--        [tag]="'Enterprise'"-->
    <!--        [tagColor]="'teal'"-->
    <!--      >-->
    <!--        <app-toggle [orange]="true" [disabled]="true" (click)="openChat()"></app-toggle>-->

    <!--        <ng-container description>-->
    <!--          Your fully custom HTML & CSS for Sign In and Sign Up pages-->
    <!--        </ng-container>-->
    <!--      </app-sidebar-field>-->
    <!--    </div>-->
  </app-sidebar-section>
</div>

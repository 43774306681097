import { Inject, Injectable, InjectionToken, OnDestroy } from '@angular/core';
import cloneDeep from 'lodash/cloneDeep';
import defaults from 'lodash/defaults';
import { Observable, of, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

import { ActionItem, ActionType, ViewSettingsAction } from '@modules/actions';
import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import {
  AccordionElementItem,
  ActionDropdownElementItem,
  ActionElementItem,
  ActionGroupElementItem,
  AlertElementItem,
  BackElementItem,
  BarCodeElementItem,
  CardLayoutElementItem,
  CollapseElementItem,
  ColumnsLayoutElementItem,
  CustomElementItem,
  DateRangeElementItem,
  ElementActions,
  ElementItem,
  ElementType,
  FieldElementItem,
  FileViewerElementItem,
  FilterElementItem,
  FormElementItem,
  FormSubmitElementItem,
  IFrameElementItem,
  ImageElementItem,
  ListElementItem,
  ListLayoutSettings,
  Margin,
  ModelElementItem,
  PopupSettings,
  QrCodeElementItem,
  RangeSliderElementItem,
  ScannerElementItem,
  SeparatorElementItem,
  SpacingElementItem,
  StackLayoutElementItem,
  StepsElementItem,
  TabsLayoutElementItem,
  TextElementItem,
  ViewContext,
  ViewContextElement,
  ViewSettings,
  WidgetElementItem
} from '@modules/customize';
import {
  ActionElementComponent,
  AutoFieldElementComponent,
  AutoWidgetElementComponent,
  CustomPagePopupComponent,
  FormElementComponent,
  ListElementComponent,
  ModelElementComponent,
  ScannerElementComponent
} from '@modules/customize-components';
import { AutoElementComponent } from '@modules/customize-elements';
import { ChartWidget, ValueWidget, Widget } from '@modules/dashboard';
import { FieldsEditConfigurable } from '@modules/field-components';
import { BaseField, Input } from '@modules/fields';
import { ModelDescription, ModelField } from '@modules/models';
import { isSet } from '@shared';

import { RangeSliderElementComponent } from '../../../customize-components/components/range-slider-element/range-slider-element.component';
import { CustomizeBarAccordionEditComponent } from '../../components/customize-bar-accordion-edit/customize-bar-accordion-edit.component';
import { CustomizeBarActionDropdownEditComponent } from '../../components/customize-bar-action-dropdown-edit/customize-bar-action-dropdown-edit.component';
import {
  CustomizeActionOptions,
  CustomizeBarActionEditForm,
  ModelDescriptionInContext
} from '../../components/customize-bar-action-edit/customize-bar-action-edit.form';
import { CustomizeBarActionGroupEditComponent } from '../../components/customize-bar-action-group-edit/customize-bar-action-group-edit.component';
import { CustomizeBarAlertEditComponent } from '../../components/customize-bar-alert-edit/customize-bar-alert-edit.component';
import { CustomizeBarBackEditComponent } from '../../components/customize-bar-back-edit/customize-bar-back-edit.component';
import { CustomizeBarBarCodeEditComponent } from '../../components/customize-bar-bar-code-edit/customize-bar-bar-code-edit.component';
import { CustomizeBarCardEditComponent } from '../../components/customize-bar-card-edit/customize-bar-card-edit.component';
import { CustomizeBarCollapseEditComponent } from '../../components/customize-bar-collapse-edit/customize-bar-collapse-edit.component';
import { CustomizeBarColumnEditComponent } from '../../components/customize-bar-column-edit/customize-bar-column-edit.component';
import { CustomizeBarColumnsEditComponent } from '../../components/customize-bar-columns-edit/customize-bar-columns-edit.component';
import { CustomizeBarCustomEditComponent } from '../../components/customize-bar-custom-edit/customize-bar-custom-edit.component';
import { CustomizeBarDateRangeEditComponent } from '../../components/customize-bar-date-range-edit/customize-bar-date-range-edit.component';
import { CustomizeBarFileViewerEditComponent } from '../../components/customize-bar-file-viewer-edit/customize-bar-file-viewer-edit.component';
import { CustomizeBarFilterEditComponent } from '../../components/customize-bar-filter-edit/customize-bar-filter-edit.component';
import { CustomizeBarFormEditComponent } from '../../components/customize-bar-form-edit/customize-bar-form-edit.component';
import { CustomizeBarFormSubmitEditComponent } from '../../components/customize-bar-form-submit-edit/customize-bar-form-submit-edit.component';
import { CustomizeBarIFrameEditComponent } from '../../components/customize-bar-iframe-edit/customize-bar-iframe-edit.component';
import { CustomizeBarImageEditComponent } from '../../components/customize-bar-image-edit/customize-bar-image-edit.component';
import { CustomizeBarListEditComponent } from '../../components/customize-bar-list-edit/customize-bar-list-edit.component';
import { CustomizeBarPageEditComponent } from '../../components/customize-bar-page-edit/customize-bar-page-edit.component';
import { CustomizeBarPopupEditComponent } from '../../components/customize-bar-popup-edit/customize-bar-popup-edit.component';
import { CustomizeBarQrCodeEditComponent } from '../../components/customize-bar-qr-code-edit/customize-bar-qr-code-edit.component';
import { CustomizeBarRangeSliderEditComponent } from '../../components/customize-bar-range-slider-edit/customize-bar-range-slider-edit.component';
import { CustomizeBarScannerEditComponent } from '../../components/customize-bar-scanner-edit/customize-bar-scanner-edit.component';
import { CustomizeBarSeparatorEditComponent } from '../../components/customize-bar-separator-edit/customize-bar-separator-edit.component';
import { CustomizeBarSpacingEditComponent } from '../../components/customize-bar-spacing-edit/customize-bar-spacing-edit.component';
import { CustomizeBarStackEditComponent } from '../../components/customize-bar-stack-edit/customize-bar-stack-edit.component';
import { CustomizeBarStepsEditComponent } from '../../components/customize-bar-steps-edit/customize-bar-steps-edit.component';
import { CustomizeBarTabsEditComponent } from '../../components/customize-bar-tabs-edit/customize-bar-tabs-edit.component';
import { CustomizeBarTextEditComponent } from '../../components/customize-bar-text-edit/customize-bar-text-edit.component';
import { CustomizeBarChartWidgetEditComponent } from '../../components/widgets/customize-bar-chart-widget-edit/customize-bar-chart-widget-edit.component';
import { CustomizeBarValueWidgetEditComponent } from '../../components/widgets/customize-bar-value-widget-edit/customize-bar-value-widget-edit.component';
import { CustomizeBarEditEvent } from '../../data/customize-bar-edit-event';
import { CustomizeBarEditEventType } from '../../data/customize-bar-edit-event-type';
import { CustomizeBarContext } from '../customize-bar-context/customize-bar.context';

export const CUSTOMIZE_ACTION_COMPONENT = new InjectionToken<any>('CUSTOMIZE_ACTION_COMPONENT');
export const CUSTOMIZE_MODEL_COMPONENT = new InjectionToken<any>('CUSTOMIZE_MODEL_COMPONENT');

@Injectable()
export class CustomizeBarService implements OnDestroy {
  constructor(
    @Inject(CUSTOMIZE_ACTION_COMPONENT) private customizeActionComponent: any,
    @Inject(CUSTOMIZE_MODEL_COMPONENT) private customizeModelComponent: any,
    private analyticsService: UniversalAnalyticsService
  ) {}

  ngOnDestroy(): void {}

  customizeActionItem(options: {
    context: CustomizeBarContext;
    element?: ElementItem;
    viewContext?: ViewContext;
    viewContextElement?: ViewContextElement;
    viewContextElementPath?: (string | number)[];
    viewContextElementPaths?: (string | number)[][];
    label?: string;
    titleEnabled?: boolean;
    backLabel?: string;
    tooltipEditable?: boolean;
    marginEditable?: boolean;
    options?: CustomizeActionOptions;
    deleteEnabled?: boolean;
    existingForm?: CustomizeBarActionEditForm;
    parentElement?: AutoElementComponent;
    parentPopup?: CustomPagePopupComponent;
    object?: string;
    trackConfigured?: boolean;
    append?: boolean;
    firstInit?: boolean;
    setupOnCreate?: boolean;
  }): Observable<CustomizeBarEditEvent> {
    options = defaults(options, { options: {} });

    const result = new Subject<CustomizeBarEditEvent>();

    options.context.setSettingsComponent(
      {
        component: this.customizeActionComponent,
        inputs: {
          options: options.options,
          ...(options.label && { label: options.label }),
          ...(isSet(options.titleEnabled) && { titleEnabled: options.titleEnabled }),
          ...(options.backLabel && { backLabel: options.backLabel }),
          element: options.element,
          context: options.viewContext,
          contextElement: options.viewContextElement,
          contextElementPath: options.viewContextElementPath,
          contextElementPaths: options.viewContextElementPaths,
          deleteEnabled: options.deleteEnabled,
          existingForm: options.existingForm,
          parentElement: options.parentElement,
          parentPopup: options.parentPopup,
          object: options.object,
          trackConfigured: options.trackConfigured,
          firstInit: options.firstInit,
          setupOnCreate: options.setupOnCreate
        },
        outputs: {
          event: [
            e => {
              result.next(e);
            }
          ]
        }
      },
      { append: options.append }
    );

    return result.asObservable();
  }

  customizeModelElementItem(options: {
    context: CustomizeBarContext;
    element?: ModelElementItem;
    viewContext?: ViewContext;
    viewContextElement?: ViewContextElement;
    deleteEnabled?: boolean;
    parentElement?: AutoElementComponent;
    parentPopup?: CustomPagePopupComponent;
    append?: boolean;
    titleEnabled?: boolean;
    titleEditable?: boolean;
    firstInit?: boolean;
    setupOnCreate?: boolean;
  }): Observable<CustomizeBarEditEvent> {
    const result = new Subject<CustomizeBarEditEvent>();

    options.context.setSettingsComponent(
      {
        component: this.customizeModelComponent,
        inputs: {
          element: options.element,
          context: options.viewContext,
          contextElement: options.viewContextElement,
          deleteEnabled: options.deleteEnabled,
          ...(isSet(options.titleEnabled) && { titleEnabled: options.titleEnabled }),
          titleEditable: options.titleEditable,
          parentElement: options.parentElement,
          parentPopup: options.parentPopup,
          firstInit: options.firstInit,
          setupOnCreate: options.setupOnCreate
        },
        outputs: {
          event: [
            e => {
              result.next(e);
            }
          ]
        }
      },
      { append: options.append }
    );

    return result.asObservable();
  }

  customizeFormElementItem(options: {
    context: CustomizeBarContext;
    element?: FormElementItem;
    viewContext?: ViewContext;
    viewContextElement?: ViewContextElement;
    nameEnabled?: boolean;
    backLabel?: string;
    deleteEnabled?: boolean;
    parentElement?: AutoElementComponent;
    parentPopup?: CustomPagePopupComponent;
    append?: boolean;
    titleEnabled?: boolean;
    titleEditable?: boolean;
    firstInit?: boolean;
    setupOnCreate?: boolean;
  }): Observable<CustomizeBarEditEvent> {
    const result = new Subject<CustomizeBarEditEvent>();

    options.context.setSettingsComponent(
      {
        component: CustomizeBarFormEditComponent,
        inputs: {
          element: options.element,
          context: options.viewContext,
          contextElement: options.viewContextElement,
          ...(options.backLabel && { backLabel: options.backLabel }),
          deleteEnabled: options.deleteEnabled,
          ...(isSet(options.titleEnabled) && { titleEnabled: options.titleEnabled }),
          titleEditable: options.titleEditable,
          parentElement: options.parentElement,
          parentPopup: options.parentPopup,
          firstInit: options.firstInit,
          setupOnCreate: options.setupOnCreate
        },
        outputs: {
          event: [
            e => {
              result.next(e);
            }
          ]
        }
      },
      { append: options.append }
    );

    return result.asObservable();
  }

  customizeFormSubmitElementItem(options: {
    context: CustomizeBarContext;
    element?: FormSubmitElementItem;
    elementUpdated?: Observable<ElementItem>;
    viewContext?: ViewContext;
    visibleInput?: Input;
    visibleEditable?: boolean;
    parentElement?: AutoElementComponent;
    parentPopup?: CustomPagePopupComponent;
    parentForm?: AutoElementComponent;
    append?: boolean;
    title?: string;
    titleEnabled?: boolean;
    titleEditable?: boolean;
    deleteEnabled?: boolean;
    firstInit?: boolean;
  }): Observable<CustomizeBarEditEvent> {
    const result = new Subject<CustomizeBarEditEvent>();

    options.context.setSettingsComponent(
      {
        component: CustomizeBarFormSubmitEditComponent,
        inputs: {
          title: options.title,
          element: options.element,
          elementUpdated: options.elementUpdated,
          context: options.viewContext,
          ...(isSet(options.titleEnabled) && { titleEnabled: options.titleEnabled }),
          titleEditable: options.titleEditable,
          deleteEnabled: options.deleteEnabled,
          visibleInput: options.visibleInput,
          visibleEditable: options.visibleEditable,
          parentElement: options.parentElement,
          parentPopup: options.parentPopup,
          parentForm: options.parentForm,
          firstInit: options.firstInit
        },
        outputs: {
          event: [
            e => {
              result.next(e);
            }
          ]
        }
      },
      { append: options.append }
    );

    return result.asObservable();
  }

  customizeTextElementItem(options: {
    context: CustomizeBarContext;
    element?: TextElementItem;
    viewContext?: ViewContext;
    viewContextElement?: ViewContextElement;
    deleteEnabled?: boolean;
    parentElement?: AutoElementComponent;
    parentPopup?: CustomPagePopupComponent;
    append?: boolean;
    titleEnabled?: boolean;
    titleEditable?: boolean;
    firstInit?: boolean;
  }): Observable<CustomizeBarEditEvent> {
    const result = new Subject<CustomizeBarEditEvent>();

    options.context.setSettingsComponent(
      {
        component: CustomizeBarTextEditComponent,
        inputs: {
          element: options.element,
          context: options.viewContext,
          contextElement: options.viewContextElement,
          deleteEnabled: options.deleteEnabled,
          ...(isSet(options.titleEnabled) && { titleEnabled: options.titleEnabled }),
          titleEditable: options.titleEditable,
          parentElement: options.parentElement,
          parentPopup: options.parentPopup,
          firstInit: options.firstInit
        },
        outputs: {
          event: [
            e => {
              result.next(e);
            }
          ]
        }
      },
      { append: options.append }
    );

    return result.asObservable();
  }

  customizeSpacingElementItem(options: {
    context: CustomizeBarContext;
    element?: SpacingElementItem;
    viewContext?: ViewContext;
    viewContextElement?: ViewContextElement;
    deleteEnabled?: boolean;
    parentElement?: AutoElementComponent;
    parentPopup?: CustomPagePopupComponent;
    append?: boolean;
    titleEnabled?: boolean;
    titleEditable?: boolean;
    firstInit?: boolean;
  }): Observable<CustomizeBarEditEvent> {
    const result = new Subject<CustomizeBarEditEvent>();

    options.context.setSettingsComponent(
      {
        component: CustomizeBarSpacingEditComponent,
        inputs: {
          element: options.element,
          context: options.viewContext,
          contextElement: options.viewContextElement,
          deleteEnabled: options.deleteEnabled,
          ...(isSet(options.titleEnabled) && { titleEnabled: options.titleEnabled }),
          titleEditable: options.titleEditable,
          parentElement: options.parentElement,
          parentPopup: options.parentPopup,
          firstInit: options.firstInit
        },
        outputs: {
          event: [
            e => {
              result.next(e);
            }
          ]
        }
      },
      { append: options.append }
    );

    return result.asObservable();
  }

  customizeSeparatorElementItem(options: {
    context: CustomizeBarContext;
    element?: SeparatorElementItem;
    viewContext?: ViewContext;
    viewContextElement?: ViewContextElement;
    deleteEnabled?: boolean;
    parentElement?: AutoElementComponent;
    parentPopup?: CustomPagePopupComponent;
    append?: boolean;
    titleEnabled?: boolean;
    titleEditable?: boolean;
    firstInit?: boolean;
  }): Observable<CustomizeBarEditEvent> {
    const result = new Subject<CustomizeBarEditEvent>();

    options.context.setSettingsComponent(
      {
        component: CustomizeBarSeparatorEditComponent,
        inputs: {
          element: options.element,
          context: options.viewContext,
          contextElement: options.viewContextElement,
          deleteEnabled: options.deleteEnabled,
          ...(isSet(options.titleEnabled) && { titleEnabled: options.titleEnabled }),
          titleEditable: options.titleEditable,
          parentElement: options.parentElement,
          parentPopup: options.parentPopup,
          firstInit: options.firstInit
        },
        outputs: {
          event: [
            e => {
              result.next(e);
            }
          ]
        }
      },
      { append: options.append }
    );

    return result.asObservable();
  }

  customizeStepsElementItem(options: {
    context: CustomizeBarContext;
    element?: StepsElementItem;
    viewContext?: ViewContext;
    viewContextElement?: ViewContextElement;
    deleteEnabled?: boolean;
    parentElement?: AutoElementComponent;
    parentPopup?: CustomPagePopupComponent;
    append?: boolean;
    titleEnabled?: boolean;
    titleEditable?: boolean;
    firstInit?: boolean;
  }): Observable<CustomizeBarEditEvent> {
    const result = new Subject<CustomizeBarEditEvent>();

    options.context.setSettingsComponent(
      {
        component: CustomizeBarStepsEditComponent,
        inputs: {
          element: options.element,
          context: options.viewContext,
          contextElement: options.viewContextElement,
          deleteEnabled: options.deleteEnabled,
          ...(isSet(options.titleEnabled) && { titleEnabled: options.titleEnabled }),
          titleEditable: options.titleEditable,
          parentElement: options.parentElement,
          parentPopup: options.parentPopup,
          firstInit: options.firstInit
        },
        outputs: {
          event: [
            e => {
              result.next(e);
            }
          ]
        }
      },
      { append: options.append }
    );

    return result.asObservable();
  }

  customizeFilterElementItem(options: {
    context: CustomizeBarContext;
    element?: FilterElementItem;
    viewContext?: ViewContext;
    viewContextElement?: ViewContextElement;
    deleteEnabled?: boolean;
    parentElement?: AutoElementComponent;
    parentPopup?: CustomPagePopupComponent;
    append?: boolean;
    titleEnabled?: boolean;
    titleEditable?: boolean;
    firstInit?: boolean;
  }): Observable<CustomizeBarEditEvent> {
    const result = new Subject<CustomizeBarEditEvent>();

    options.context.setSettingsComponent(
      {
        component: CustomizeBarFilterEditComponent,
        inputs: {
          element: options.element,
          context: options.viewContext,
          contextElement: options.viewContextElement,
          deleteEnabled: options.deleteEnabled,
          ...(isSet(options.titleEnabled) && { titleEnabled: options.titleEnabled }),
          titleEditable: options.titleEditable,
          parentElement: options.parentElement,
          parentPopup: options.parentPopup,
          firstInit: options.firstInit
        },
        outputs: {
          event: [
            e => {
              result.next(e);
            }
          ]
        }
      },
      { append: options.append }
    );

    return result.asObservable();
  }

  customizeCardElementItem(options: {
    context: CustomizeBarContext;
    element?: CardLayoutElementItem;
    viewContext?: ViewContext;
    viewContextElement?: ViewContextElement;
    deleteEnabled?: boolean;
    parentElement?: AutoElementComponent;
    parentPopup?: CustomPagePopupComponent;
    append?: boolean;
    titleEnabled?: boolean;
    titleEditable?: boolean;
    firstInit?: boolean;
  }): Observable<CustomizeBarEditEvent> {
    const result = new Subject<CustomizeBarEditEvent>();

    options.context.setSettingsComponent(
      {
        component: CustomizeBarCardEditComponent,
        inputs: {
          element: options.element,
          context: options.viewContext,
          contextElement: options.viewContextElement,
          deleteEnabled: options.deleteEnabled,
          ...(isSet(options.titleEnabled) && { titleEnabled: options.titleEnabled }),
          titleEditable: options.titleEditable,
          parentElement: options.parentElement,
          parentPopup: options.parentPopup,
          firstInit: options.firstInit
        },
        outputs: {
          event: [
            e => {
              result.next(e);
            }
          ]
        }
      },
      { append: options.append }
    );

    return result.asObservable();
  }

  customizeStackElementItem(options: {
    context: CustomizeBarContext;
    element?: StackLayoutElementItem;
    viewContext?: ViewContext;
    viewContextElement?: ViewContextElement;
    deleteEnabled?: boolean;
    parentElement?: AutoElementComponent;
    parentPopup?: CustomPagePopupComponent;
    append?: boolean;
    titleEnabled?: boolean;
    titleEditable?: boolean;
    firstInit?: boolean;
  }): Observable<CustomizeBarEditEvent> {
    const result = new Subject<CustomizeBarEditEvent>();

    options.context.setSettingsComponent(
      {
        component: CustomizeBarStackEditComponent,
        inputs: {
          element: options.element,
          context: options.viewContext,
          contextElement: options.viewContextElement,
          deleteEnabled: options.deleteEnabled,
          ...(isSet(options.titleEnabled) && { titleEnabled: options.titleEnabled }),
          titleEditable: options.titleEditable,
          parentElement: options.parentElement,
          parentPopup: options.parentPopup,
          firstInit: options.firstInit
        },
        outputs: {
          event: [
            e => {
              result.next(e);
            }
          ]
        }
      },
      { append: options.append }
    );

    return result.asObservable();
  }

  customizeTabsElementItem(options: {
    context: CustomizeBarContext;
    element?: TabsLayoutElementItem;
    viewContext?: ViewContext;
    viewContextElement?: ViewContextElement;
    deleteEnabled?: boolean;
    append?: boolean;
    titleEnabled?: boolean;
    titleEditable?: boolean;
    parentElement?: AutoElementComponent;
    parentPopup?: CustomPagePopupComponent;
    firstInit?: boolean;
  }): Observable<CustomizeBarEditEvent> {
    const result = new Subject<CustomizeBarEditEvent>();

    options.context.setSettingsComponent(
      {
        component: CustomizeBarTabsEditComponent,
        inputs: {
          element: options.element,
          context: options.viewContext,
          contextElement: options.viewContextElement,
          deleteEnabled: options.deleteEnabled,
          ...(isSet(options.titleEnabled) && { titleEnabled: options.titleEnabled }),
          titleEditable: options.titleEditable,
          parentElement: options.parentElement,
          parentPopup: options.parentPopup,
          firstInit: options.firstInit
        },
        outputs: {
          event: [
            e => {
              result.next(e);
            }
          ]
        }
      },
      { append: options.append }
    );

    return result.asObservable();
  }

  customizeColumnsElementItem(options: {
    context: CustomizeBarContext;
    element?: ColumnsLayoutElementItem;
    viewContext?: ViewContext;
    viewContextElement?: ViewContextElement;
    deleteEnabled?: boolean;
    parentElement?: AutoElementComponent;
    parentPopup?: CustomPagePopupComponent;
    append?: boolean;
    titleEnabled?: boolean;
    titleEditable?: boolean;
    firstInit?: boolean;
  }): Observable<CustomizeBarEditEvent> {
    const result = new Subject<CustomizeBarEditEvent>();

    options.context.setSettingsComponent(
      {
        component: CustomizeBarColumnsEditComponent,
        inputs: {
          element: options.element,
          context: options.viewContext,
          contextElement: options.viewContextElement,
          deleteEnabled: options.deleteEnabled,
          ...(isSet(options.titleEnabled) && { titleEnabled: options.titleEnabled }),
          titleEditable: options.titleEditable,
          parentElement: options.parentElement,
          parentPopup: options.parentPopup,
          firstInit: options.firstInit
        },
        outputs: {
          event: [
            e => {
              result.next(e);
            }
          ]
        }
      },
      { append: options.append }
    );

    return result.asObservable();
  }

  customizeWidget(options: {
    context: CustomizeBarContext;
    element?: WidgetElementItem;
    item?: Widget;
    title?: string;
    titleEnabled?: boolean;
    titleEditable?: boolean;
    visibleInput?: Input;
    visibleEditable?: boolean;
    margin?: Margin;
    marginEditable?: boolean;
    viewContext?: ViewContext;
    viewContextElement?: ViewContextElement;
    deleteAllowed?: boolean;
    deleteEnabled?: boolean;
    parentElement?: AutoElementComponent;
    parentPopup?: CustomPagePopupComponent;
    component?: any;
    append?: boolean;
    firstInit?: boolean;
    setupOnCreate?: boolean;
  }): Observable<CustomizeBarEditEvent> {
    const result = new Subject<CustomizeBarEditEvent>();
    let component;

    if (options.item instanceof ValueWidget) {
      component = CustomizeBarValueWidgetEditComponent;
    } else if (options.item instanceof ChartWidget) {
      component = CustomizeBarChartWidgetEditComponent;
    }

    if (!component) {
      return of();
    }

    options.context.setSettingsComponent(
      {
        component: component,
        inputs: {
          element: options.element,
          widget: options.item,
          title: options.title,
          ...(isSet(options.titleEnabled) && { titleEnabled: options.titleEnabled }),
          titleEditable: options.titleEditable,
          visibleInput: options.visibleInput,
          visibleEditable: options.visibleEditable,
          margin: options.margin,
          marginEditable: options.marginEditable,
          context: options.viewContext,
          contextElement: options.viewContextElement,
          deleteAllowed: options.deleteAllowed,
          deleteEnabled: options.deleteEnabled,
          parentElement: options.parentElement,
          parentPopup: options.parentPopup,
          component: options.component,
          firstInit: options.firstInit,
          setupOnCreate: options.setupOnCreate
        },
        outputs: {
          event: [
            e => {
              result.next(e);
            }
          ]
        }
      },
      { append: options.append }
    );

    return result.asObservable();
  }

  customizeListLayoutSettings(options: {
    context: CustomizeBarContext;
    settings: ListLayoutSettings;
    visibleInput?: Input;
    visibleEditable?: boolean;
    margin?: Margin;
    marginEditable?: boolean;
    title?: string;
    titleEnabled?: boolean;
    titleEditable?: boolean;
    element?: ElementItem;
    viewContext?: ViewContext;
    viewContextElement?: ViewContextElement;
    submitEnabled?: boolean;
    backEnabled?: boolean;
    deleteEnabled?: boolean;
    parentElement?: AutoElementComponent;
    parentPopup?: CustomPagePopupComponent;
    append?: boolean;
    firstInit?: boolean;
    setupOnCreate?: boolean;
  }): Observable<CustomizeBarEditEvent> {
    options = defaults(options, { submitEnabled: true, backEnabled: true });

    const result = new Subject<CustomizeBarEditEvent>();

    options.context.setSettingsComponent(
      {
        component: CustomizeBarListEditComponent,
        inputs: {
          settings: options.settings,
          visibleInput: options.visibleInput,
          visibleEditable: options.visibleEditable,
          margin: options.margin,
          marginEditable: options.marginEditable,
          title: options.title,
          ...(isSet(options.titleEnabled) && { titleEnabled: options.titleEnabled }),
          titleEditable: options.titleEditable,
          element: options.element,
          context: options.viewContext,
          contextElement: options.viewContextElement,
          submitEnabled: options.submitEnabled,
          backEnabled: options.backEnabled,
          deleteEnabled: options.deleteEnabled,
          parentElement: options.parentElement,
          parentPopup: options.parentPopup,
          firstInit: options.firstInit,
          setupOnCreate: options.setupOnCreate
        },
        outputs: {
          event: [
            e => {
              result.next(e);
            }
          ]
        }
      },
      { append: options.append }
    );

    return result.asObservable();
  }

  customizeColumn(options: {
    context: CustomizeBarContext;
    column: BaseField;
    modelDescription?: ModelDescription;
    actions?: ViewSettingsAction[];
    elementActions?: ElementActions[];
    onChangeActions?: ActionItem[];
    configurable: FieldsEditConfigurable;
    element?: ElementItem;
    viewContext?: ViewContext;
    viewContextElement?: ViewContextElement;
    viewContextElementPath?: (string | number)[];
    viewContextElementPaths?: (string | number)[][];
    append?: boolean;
    title?: string;
    titleEnabled?: boolean;
    titleEditable?: boolean;
    titleAutoUpdate?: boolean;
    backLabel?: string;
    visibleInput?: Input;
    visibleEditable?: boolean;
    disableInput?: Input;
    disableEditable?: boolean;
    tooltip?: string;
    tooltipEditable?: boolean;
    margin?: Margin;
    marginEditable?: boolean;
    backEnabled?: boolean;
    submitEnabled?: boolean;
    deleteEnabled?: boolean;
    actionsLabels?: {
      title?: string;
      emptyAction?: string;
      actionLabel?: string;
    };
    parentElement?: AutoElementComponent;
    parentPopup?: CustomPagePopupComponent;
    parentForm?: AutoElementComponent;
    object?: string;
    trackConfigured?: boolean;
    firstInit?: boolean;
  }): Observable<CustomizeBarEditEvent> {
    options = {
      margin: {},
      backEnabled: true,
      ...options,
      configurable: {
        field: true,
        required: true,
        ...options.configurable
      }
    };

    const result = new Subject<CustomizeBarEditEvent>();

    options.context.setSettingsComponent(
      {
        component: CustomizeBarColumnEditComponent,
        inputs: {
          field: options.column,
          modelDescription: options.modelDescription,
          actions: options.actions,
          elementActions: options.elementActions,
          onChangeActions: options.onChangeActions,
          element: options.element,
          context: options.viewContext,
          contextElement: options.viewContextElement,
          contextElementPath: options.viewContextElementPath,
          contextElementPaths: options.viewContextElementPaths,
          configurable: options.configurable,
          title: options.title,
          ...(isSet(options.titleEnabled) && { titleEnabled: options.titleEnabled }),
          titleEditable: options.titleEditable,
          titleAutoUpdate: options.titleAutoUpdate,
          ...(options.backLabel && { backLabel: options.backLabel }),
          visibleInput: options.visibleInput,
          visibleEditable: options.visibleEditable,
          disableInput: options.disableInput,
          disableEditable: options.disableEditable,
          tooltip: options.tooltip,
          ...(isSet(options.tooltipEditable) && { tooltipEditable: options.tooltipEditable }),
          margin: options.margin,
          ...(isSet(options.marginEditable) && { marginEditable: options.marginEditable }),
          backEnabled: options.backEnabled,
          submitEnabled: options.submitEnabled,
          deleteEnabled: options.deleteEnabled,
          ...(options.actionsLabels && { actionsLabels: options.actionsLabels }),
          parentElement: options.parentElement,
          parentPopup: options.parentPopup,
          parentForm: options.parentForm,
          object: options.object,
          trackConfigured: options.trackConfigured,
          firstInit: options.firstInit
        },
        outputs: {
          event: [
            e => {
              result.next(e);
            }
          ]
        }
      },
      { append: options.append }
    );

    return result.asObservable();
  }

  customizePage(options: {
    context: CustomizeBarContext;
    viewSettings: ViewSettings;
    viewContext?: ViewContext;
    append?: boolean;
  }): Observable<CustomizeBarEditEvent> {
    const result = new Subject<CustomizeBarEditEvent>();

    options.context.setSettingsComponent(
      {
        component: CustomizeBarPageEditComponent,
        inputs: {
          viewSettings: options.viewSettings,
          context: options.viewContext
        },
        outputs: {
          event: [
            e => {
              result.next(e);
            }
          ]
        }
      },
      { append: options.append }
    );

    return result.asObservable();
  }

  customizePopup(options: {
    context: CustomizeBarContext;
    popup: PopupSettings;
    viewContext?: ViewContext;
    append?: boolean;
  }): Observable<CustomizeBarEditEvent> {
    const result = new Subject<CustomizeBarEditEvent>();

    options.context.setSettingsComponent(
      {
        component: CustomizeBarPopupEditComponent,
        inputs: {
          popup: options.popup,
          context: options.viewContext
        },
        outputs: {
          event: [
            e => {
              result.next(e);
            }
          ]
        }
      },
      { append: options.append }
    );

    return result.asObservable();
  }

  customizeImage(options: {
    context: CustomizeBarContext;
    element?: ImageElementItem;
    elementUpdated?: Observable<ElementItem>;
    viewContext?: ViewContext;
    parentElement?: AutoElementComponent;
    parentPopup?: CustomPagePopupComponent;
    append?: boolean;
    titleEnabled?: boolean;
    deleteEnabled?: boolean;
    firstInit?: boolean;
  }): Observable<CustomizeBarEditEvent> {
    const result = new Subject<CustomizeBarEditEvent>();

    options.context.setSettingsComponent(
      {
        component: CustomizeBarImageEditComponent,
        inputs: {
          element: options.element,
          elementUpdated: options.elementUpdated,
          context: options.viewContext,
          ...(isSet(options.titleEnabled) && { titleEnabled: options.titleEnabled }),
          deleteEnabled: options.deleteEnabled,
          parentElement: options.parentElement,
          parentPopup: options.parentPopup,
          firstInit: options.firstInit
        },
        outputs: {
          event: [
            e => {
              result.next(e);
            }
          ]
        }
      },
      { append: options.append }
    );

    return result.asObservable();
  }

  customizeIFrame(options: {
    context: CustomizeBarContext;
    element?: IFrameElementItem;
    viewContext?: ViewContext;
    parentElement?: AutoElementComponent;
    parentPopup?: CustomPagePopupComponent;
    append?: boolean;
    titleEnabled?: boolean;
    deleteEnabled?: boolean;
    firstInit?: boolean;
  }): Observable<CustomizeBarEditEvent> {
    const result = new Subject<CustomizeBarEditEvent>();

    options.context.setSettingsComponent(
      {
        component: CustomizeBarIFrameEditComponent,
        inputs: {
          element: options.element,
          context: options.viewContext,
          ...(isSet(options.titleEnabled) && { titleEnabled: options.titleEnabled }),
          deleteEnabled: options.deleteEnabled,
          parentElement: options.parentElement,
          parentPopup: options.parentPopup,
          firstInit: options.firstInit
        },
        outputs: {
          event: [
            e => {
              result.next(e);
            }
          ]
        }
      },
      { append: options.append }
    );

    return result.asObservable();
  }

  customizeQrCode(options: {
    context: CustomizeBarContext;
    element?: QrCodeElementItem;
    viewContext?: ViewContext;
    parentElement?: AutoElementComponent;
    parentPopup?: CustomPagePopupComponent;
    append?: boolean;
    titleEnabled?: boolean;
    deleteEnabled?: boolean;
    firstInit?: boolean;
  }): Observable<CustomizeBarEditEvent> {
    const result = new Subject<CustomizeBarEditEvent>();

    options.context.setSettingsComponent(
      {
        component: CustomizeBarQrCodeEditComponent,
        inputs: {
          element: options.element,
          context: options.viewContext,
          ...(isSet(options.titleEnabled) && { titleEnabled: options.titleEnabled }),
          deleteEnabled: options.deleteEnabled,
          parentElement: options.parentElement,
          parentPopup: options.parentPopup,
          firstInit: options.firstInit
        },
        outputs: {
          event: [
            e => {
              result.next(e);
            }
          ]
        }
      },
      { append: options.append }
    );

    return result.asObservable();
  }

  customizeBarCode(options: {
    context: CustomizeBarContext;
    element?: BarCodeElementItem;
    viewContext?: ViewContext;
    parentElement?: AutoElementComponent;
    parentPopup?: CustomPagePopupComponent;
    append?: boolean;
    titleEnabled?: boolean;
    deleteEnabled?: boolean;
    firstInit?: boolean;
  }): Observable<CustomizeBarEditEvent> {
    const result = new Subject<CustomizeBarEditEvent>();

    options.context.setSettingsComponent(
      {
        component: CustomizeBarBarCodeEditComponent,
        inputs: {
          element: options.element,
          context: options.viewContext,
          ...(isSet(options.titleEnabled) && { titleEnabled: options.titleEnabled }),
          deleteEnabled: options.deleteEnabled,
          parentElement: options.parentElement,
          parentPopup: options.parentPopup,
          firstInit: options.firstInit
        },
        outputs: {
          event: [
            e => {
              result.next(e);
            }
          ]
        }
      },
      { append: options.append }
    );

    return result.asObservable();
  }

  customizeScanner(options: {
    context: CustomizeBarContext;
    element?: ScannerElementItem;
    viewContext?: ViewContext;
    viewContextElement?: ViewContextElement;
    parentElement?: AutoElementComponent;
    parentPopup?: CustomPagePopupComponent;
    append?: boolean;
    titleEnabled?: boolean;
    deleteEnabled?: boolean;
    firstInit?: boolean;
    analyticsSource?: string;
  }): Observable<CustomizeBarEditEvent> {
    const result = new Subject<CustomizeBarEditEvent>();

    options.context.setSettingsComponent(
      {
        component: CustomizeBarScannerEditComponent,
        inputs: {
          element: options.element,
          context: options.viewContext,
          contextElement: options.viewContextElement,
          ...(isSet(options.titleEnabled) && { titleEnabled: options.titleEnabled }),
          deleteEnabled: options.deleteEnabled,
          parentElement: options.parentElement,
          parentPopup: options.parentPopup,
          firstInit: options.firstInit,
          analyticsSource: options.analyticsSource
        },
        outputs: {
          event: [
            e => {
              result.next(e);
            }
          ]
        }
      },
      { append: options.append }
    );

    return result.asObservable();
  }

  customizeAlert(options: {
    context: CustomizeBarContext;
    element?: AlertElementItem;
    viewContext?: ViewContext;
    parentElement?: AutoElementComponent;
    parentPopup?: CustomPagePopupComponent;
    append?: boolean;
    titleEnabled?: boolean;
    deleteEnabled?: boolean;
    firstInit?: boolean;
    analyticsSource?: string;
  }): Observable<CustomizeBarEditEvent> {
    const result = new Subject<CustomizeBarEditEvent>();

    options.context.setSettingsComponent(
      {
        component: CustomizeBarAlertEditComponent,
        inputs: {
          element: options.element,
          context: options.viewContext,
          ...(isSet(options.titleEnabled) && { titleEnabled: options.titleEnabled }),
          deleteEnabled: options.deleteEnabled,
          parentElement: options.parentElement,
          parentPopup: options.parentPopup,
          firstInit: options.firstInit,
          analyticsSource: options.analyticsSource
        },
        outputs: {
          event: [
            e => {
              result.next(e);
            }
          ]
        }
      },
      { append: options.append }
    );

    return result.asObservable();
  }

  customizeFileViewer(options: {
    context: CustomizeBarContext;
    element?: FileViewerElementItem;
    viewContext?: ViewContext;
    parentElement?: AutoElementComponent;
    parentPopup?: CustomPagePopupComponent;
    append?: boolean;
    titleEnabled?: boolean;
    deleteEnabled?: boolean;
    firstInit?: boolean;
  }): Observable<CustomizeBarEditEvent> {
    const result = new Subject<CustomizeBarEditEvent>();

    options.context.setSettingsComponent(
      {
        component: CustomizeBarFileViewerEditComponent,
        inputs: {
          element: options.element,
          context: options.viewContext,
          ...(isSet(options.titleEnabled) && { titleEnabled: options.titleEnabled }),
          deleteEnabled: options.deleteEnabled,
          parentElement: options.parentElement,
          parentPopup: options.parentPopup,
          firstInit: options.firstInit
        },
        outputs: {
          event: [
            e => {
              result.next(e);
            }
          ]
        }
      },
      { append: options.append }
    );

    return result.asObservable();
  }

  customizeRangeSlider(options: {
    context: CustomizeBarContext;
    element?: RangeSliderElementItem;
    viewContext?: ViewContext;
    viewContextElement?: ViewContextElement;
    parentElement?: AutoElementComponent;
    parentPopup?: CustomPagePopupComponent;
    append?: boolean;
    titleEnabled?: boolean;
    titleEditable?: boolean;
    deleteEnabled?: boolean;
    firstInit?: boolean;
  }): Observable<CustomizeBarEditEvent> {
    const result = new Subject<CustomizeBarEditEvent>();

    options.context.setSettingsComponent(
      {
        component: CustomizeBarRangeSliderEditComponent,
        inputs: {
          element: options.element,
          context: options.viewContext,
          contextElement: options.viewContextElement,
          ...(isSet(options.titleEnabled) && { titleEnabled: options.titleEnabled }),
          titleEditable: options.titleEditable,
          deleteEnabled: options.deleteEnabled,
          parentElement: options.parentElement,
          parentPopup: options.parentPopup,
          firstInit: options.firstInit
        },
        outputs: {
          event: [
            e => {
              result.next(e);
            }
          ]
        }
      },
      { append: options.append }
    );

    return result.asObservable();
  }

  customizeDateRange(options: {
    context: CustomizeBarContext;
    element?: DateRangeElementItem;
    viewContext?: ViewContext;
    viewContextElement?: ViewContextElement;
    parentElement?: AutoElementComponent;
    parentPopup?: CustomPagePopupComponent;
    append?: boolean;
    titleEnabled?: boolean;
    titleEditable?: boolean;
    deleteEnabled?: boolean;
    firstInit?: boolean;
  }): Observable<CustomizeBarEditEvent> {
    const result = new Subject<CustomizeBarEditEvent>();

    options.context.setSettingsComponent(
      {
        component: CustomizeBarDateRangeEditComponent,
        inputs: {
          element: options.element,
          context: options.viewContext,
          contextElement: options.viewContextElement,
          ...(isSet(options.titleEnabled) && { titleEnabled: options.titleEnabled }),
          deleteEnabled: options.deleteEnabled,
          titleEditable: options.titleEditable,
          parentElement: options.parentElement,
          parentPopup: options.parentPopup,
          firstInit: options.firstInit
        },
        outputs: {
          event: [
            e => {
              result.next(e);
            }
          ]
        }
      },
      { append: options.append }
    );

    return result.asObservable();
  }

  customizeCollapse(options: {
    context: CustomizeBarContext;
    element?: CollapseElementItem;
    viewContext?: ViewContext;
    viewContextElement?: ViewContextElement;
    parentElement?: AutoElementComponent;
    parentPopup?: CustomPagePopupComponent;
    append?: boolean;
    titleEnabled?: boolean;
    titleEditable?: boolean;
    deleteEnabled?: boolean;
    firstInit?: boolean;
  }): Observable<CustomizeBarEditEvent> {
    const result = new Subject<CustomizeBarEditEvent>();

    options.context.setSettingsComponent(
      {
        component: CustomizeBarCollapseEditComponent,
        inputs: {
          element: options.element,
          context: options.viewContext,
          contextElement: options.viewContextElement,
          ...(isSet(options.titleEnabled) && { titleEnabled: options.titleEnabled }),
          titleEditable: options.titleEditable,
          deleteEnabled: options.deleteEnabled,
          parentElement: options.parentElement,
          parentPopup: options.parentPopup,
          firstInit: options.firstInit
        },
        outputs: {
          event: [
            e => {
              result.next(e);
            }
          ]
        }
      },
      { append: options.append }
    );

    return result.asObservable();
  }

  customizeAccordion(options: {
    context: CustomizeBarContext;
    element?: AccordionElementItem;
    viewContext?: ViewContext;
    viewContextElement?: ViewContextElement;
    parentElement?: AutoElementComponent;
    parentPopup?: CustomPagePopupComponent;
    append?: boolean;
    titleEnabled?: boolean;
    deleteEnabled?: boolean;
    firstInit?: boolean;
  }): Observable<CustomizeBarEditEvent> {
    const result = new Subject<CustomizeBarEditEvent>();

    options.context.setSettingsComponent(
      {
        component: CustomizeBarAccordionEditComponent,
        inputs: {
          element: options.element,
          context: options.viewContext,
          contextElement: options.viewContextElement,
          ...(isSet(options.titleEnabled) && { titleEnabled: options.titleEnabled }),
          deleteEnabled: options.deleteEnabled,
          parentElement: options.parentElement,
          parentPopup: options.parentPopup,
          firstInit: options.firstInit
        },
        outputs: {
          event: [
            e => {
              result.next(e);
            }
          ]
        }
      },
      { append: options.append }
    );

    return result.asObservable();
  }

  customizeCustom(options: {
    context: CustomizeBarContext;
    element?: CustomElementItem;
    elementUpdated?: Observable<ElementItem>;
    viewContext?: ViewContext;
    append?: boolean;
    title?: string;
    titleEnabled?: boolean;
    titleEditable?: boolean;
    deleteEnabled?: boolean;
    parentElement?: AutoElementComponent;
    parentPopup?: CustomPagePopupComponent;
    firstInit?: boolean;
  }): Observable<CustomizeBarEditEvent> {
    const result = new Subject<CustomizeBarEditEvent>();

    options.context.setSettingsComponent(
      {
        component: CustomizeBarCustomEditComponent,
        inputs: {
          title: options.title,
          element: options.element,
          elementUpdated: options.elementUpdated,
          context: options.viewContext,
          ...(isSet(options.titleEnabled) && { titleEnabled: options.titleEnabled }),
          titleEditable: options.titleEditable,
          deleteEnabled: options.deleteEnabled,
          parentElement: options.parentElement,
          parentPopup: options.parentPopup,
          firstInit: options.firstInit
        },
        outputs: {
          event: [
            e => {
              result.next(e);
            }
          ]
        }
      },
      { append: options.append }
    );

    return result.asObservable();
  }

  customizeActionGroup(options: {
    context: CustomizeBarContext;
    element?: ActionGroupElementItem;
    elementUpdated?: Observable<ElementItem>;
    viewContext?: ViewContext;
    visibleInput?: Input;
    visibleEditable?: boolean;
    append?: boolean;
    title?: string;
    titleEnabled?: boolean;
    titleEditable?: boolean;
    deleteEnabled?: boolean;
    parentElement?: AutoElementComponent;
    parentPopup?: CustomPagePopupComponent;
    firstInit?: boolean;
  }): Observable<CustomizeBarEditEvent> {
    const result = new Subject<CustomizeBarEditEvent>();

    options.context.setSettingsComponent(
      {
        component: CustomizeBarActionGroupEditComponent,
        inputs: {
          title: options.title,
          element: options.element,
          elementUpdated: options.elementUpdated,
          context: options.viewContext,
          ...(isSet(options.titleEnabled) && { titleEnabled: options.titleEnabled }),
          titleEditable: options.titleEditable,
          deleteEnabled: options.deleteEnabled,
          visibleInput: options.visibleInput,
          visibleEditable: options.visibleEditable,
          parentElement: options.parentElement,
          parentPopup: options.parentPopup,
          firstInit: options.firstInit
        },
        outputs: {
          event: [
            e => {
              result.next(e);
            }
          ]
        }
      },
      { append: options.append }
    );

    return result.asObservable();
  }

  customizeActionDropdown(options: {
    context: CustomizeBarContext;
    element?: ActionDropdownElementItem;
    elementUpdated?: Observable<ElementItem>;
    viewContext?: ViewContext;
    visibleInput?: Input;
    visibleEditable?: boolean;
    append?: boolean;
    title?: string;
    titleEnabled?: boolean;
    titleEditable?: boolean;
    deleteEnabled?: boolean;
    parentElement?: AutoElementComponent;
    parentPopup?: CustomPagePopupComponent;
    firstInit?: boolean;
  }): Observable<CustomizeBarEditEvent> {
    const result = new Subject<CustomizeBarEditEvent>();

    options.context.setSettingsComponent(
      {
        component: CustomizeBarActionDropdownEditComponent,
        inputs: {
          title: options.title,
          element: options.element,
          elementUpdated: options.elementUpdated,
          context: options.viewContext,
          ...(isSet(options.titleEnabled) && { titleEnabled: options.titleEnabled }),
          titleEditable: options.titleEditable,
          deleteEnabled: options.deleteEnabled,
          visibleInput: options.visibleInput,
          visibleEditable: options.visibleEditable,
          parentElement: options.parentElement,
          parentPopup: options.parentPopup,
          firstInit: options.firstInit
        },
        outputs: {
          event: [
            e => {
              result.next(e);
            }
          ]
        }
      },
      { append: options.append }
    );

    return result.asObservable();
  }

  customizeBack(options: {
    context: CustomizeBarContext;
    element?: BackElementItem;
    viewContext?: ViewContext;
    append?: boolean;
    title?: string;
    titleEnabled?: boolean;
    titleEditable?: boolean;
    deleteEnabled?: boolean;
    parentElement?: AutoElementComponent;
    parentPopup?: CustomPagePopupComponent;
    firstInit?: boolean;
  }): Observable<CustomizeBarEditEvent> {
    const result = new Subject<CustomizeBarEditEvent>();

    options.context.setSettingsComponent(
      {
        component: CustomizeBarBackEditComponent,
        inputs: {
          title: options.title,
          element: options.element,
          context: options.viewContext,
          ...(isSet(options.titleEnabled) && { titleEnabled: options.titleEnabled }),
          titleEditable: options.titleEditable,
          deleteEnabled: options.deleteEnabled,
          parentElement: options.parentElement,
          parentPopup: options.parentPopup,
          firstInit: options.firstInit
        },
        outputs: {
          event: [
            e => {
              result.next(e);
            }
          ]
        }
      },
      { append: options.append }
    );

    return result.asObservable();
  }

  customize(
    context: CustomizeBarContext,
    element: ElementItem,
    viewContext?: ViewContext,
    component?: any,
    elementUpdated?: Subject<ElementItem>,
    options: {
      append?: boolean;
      titleEnabled?: boolean;
      backLabel?: string;
      tooltipEditable?: boolean;
      marginEditable?: boolean;
      fieldLabelEditable?: boolean;
      fieldReadonlyEditable?: boolean;
      actionTypesOnly?: ActionType[];
      firstInit?: boolean;
      setupOnCreate?: boolean;
      parentElement?: AutoElementComponent;
      parentPopup?: CustomPagePopupComponent;
      parentForm?: AutoElementComponent;
    } = {}
  ): Observable<{ el: ElementItem; event?: CustomizeBarEditEventType }> {
    this.analyticsService.sendSimpleEvent(AnalyticsEvent.Component.StartedSetUp, {
      ComponentTypeID: element.analyticsName
    });

    const result = new Subject<{ el: ElementItem }>();

    if (element instanceof FieldElementItem) {
      const viewContextElement = component ? (component as AutoFieldElementComponent).viewContextElement : undefined;
      const initialElement = cloneDeep(element);
      const configurable: FieldsEditConfigurable = {
        name: true,
        verboseName: isSet(options.fieldLabelEditable) ? options.fieldLabelEditable : true,
        field: true,
        required: true,
        editable: true,
        editableOnly: isSet(options.fieldReadonlyEditable) ? !options.fieldLabelEditable : false,
        params: true,
        add: true,
        value: true,
        visible: true,
        disable: true,
        margin: true,
        elementActions: true,
        onChangeActions: true
      };

      return this.customizeColumn({
        context: context,
        column: element.settings,
        configurable: configurable,
        elementActions: element.elementActions,
        onChangeActions: element.onChangeActions,
        element: element,
        viewContext: viewContext,
        viewContextElement: viewContextElement,
        title: element.name,
        titleEnabled: options.titleEnabled,
        titleEditable: true,
        titleAutoUpdate: true,
        backLabel: 'All components',
        visibleInput: element.visibleInput,
        visibleEditable: true,
        disableInput: element.disableInput,
        disableEditable: true,
        tooltip: element.tooltip,
        tooltipEditable: isSet(options.tooltipEditable) ? options.tooltipEditable : true,
        margin: element.margin,
        marginEditable: isSet(options.marginEditable) ? options.marginEditable : true,
        deleteEnabled: true,
        object: element.analyticsName,
        trackConfigured: true,
        ...options
      }).pipe(
        map(e => {
          if (e.type == CustomizeBarEditEventType.Created || e.type == CustomizeBarEditEventType.Updated) {
            const newElement = cloneDeep(element) as FieldElementItem;
            newElement.name = e.args['title'] as string;
            newElement.settings = e.args['result'] as BaseField;
            newElement.visibleInput = e.args['visible_input'] as Input;
            newElement.disableInput = e.args['disable_input'] as Input;
            newElement.elementActions = e.args['element_actions'] as ElementActions[];
            newElement.onChangeActions = e.args['on_change_actions'] as ActionItem[];
            newElement.tooltip = e.args['tooltip'] as string;
            newElement.margin = e.args['margin'] as Margin;
            newElement.updateFormField();
            return {
              el: newElement
            };
          } else if (e.type == CustomizeBarEditEventType.Canceled) {
            return {
              el: initialElement
            };
          } else if (e.type == CustomizeBarEditEventType.Deleted) {
            return {
              el: initialElement,
              event: CustomizeBarEditEventType.Deleted
            };
          }
        })
      );
    } else if (element instanceof ActionElementItem) {
      const viewContextElement = component ? (component as ActionElementComponent).viewContextElement : undefined;

      const initialElement = cloneDeep(element);
      return this.customizeActionItem({
        element: element,
        context: context,
        options: {
          actionItem: element.actionItem,
          actionLabel: 'Button click',
          title: initialElement.name,
          visibleInput: element.visibleInput,
          actionItemClass: ViewSettingsAction,
          titleEditable: true,
          nameEditable: true,
          iconEditable: true,
          styleEditable: true,
          colorsEditable: true,
          approveEnabled: true,
          confirmationEnabled: true,
          completionEditable: true,
          disabledEditable: true,
          visibleEditable: true,
          tooltip: element.tooltip,
          tooltipEditable: isSet(options.tooltipEditable) ? options.tooltipEditable : true,
          margin: element.margin,
          marginEditable: isSet(options.marginEditable) ? options.marginEditable : true,
          typesOnly: options.actionTypesOnly,
          originEnabled: true
        },
        viewContext: viewContext,
        viewContextElement: viewContextElement,
        titleEnabled: options.titleEnabled,
        backLabel: 'All components',
        tooltipEditable: options.tooltipEditable,
        marginEditable: options.marginEditable,
        deleteEnabled: true,
        object: element.analyticsName,
        trackConfigured: true,
        ...options
      }).pipe(
        map(e => {
          if (e.type == CustomizeBarEditEventType.Created || e.type == CustomizeBarEditEventType.Updated) {
            const newElement = cloneDeep(element) as ActionElementItem;
            newElement.name = e.args['title'] as string;
            newElement.actionItem = e.args['result'] as ViewSettingsAction;
            newElement.visibleInput = e.args['visible_input'] as Input;
            newElement.tooltip = e.args['tooltip'] as string;
            newElement.margin = e.args['margin'] as Margin;
            return {
              el: newElement
            };
          } else if (e.type == CustomizeBarEditEventType.Canceled) {
            return {
              el: initialElement
            };
          } else if (e.type == CustomizeBarEditEventType.Deleted) {
            return {
              el: initialElement,
              event: CustomizeBarEditEventType.Deleted
            };
          }
        })
      );
    } else if (element instanceof ListElementItem) {
      const initialElement = cloneDeep(element);

      // TODO: Refactor index get
      const index = component ? (component as ListElementComponent).getCurrentLayoutIndex() : 0;
      const viewContextElement = component ? (component as ListElementComponent).viewContextElement : undefined;
      const layoutContextElement = component
        ? (component as ListElementComponent).getContextElements()[index]
        : undefined;

      return this.customizeListLayoutSettings({
        context: context,
        settings: element.layouts[index],
        visibleInput: element.visibleInput,
        visibleEditable: true,
        margin: element.margin,
        marginEditable: true,
        title: element.name,
        titleEnabled: options.titleEnabled,
        titleEditable: true,
        element: element,
        viewContext: viewContext,
        viewContextElement: layoutContextElement,
        deleteEnabled: true,
        ...options
      }).pipe(
        map(e => {
          if (e.type == CustomizeBarEditEventType.Created || e.type == CustomizeBarEditEventType.Updated) {
            const newElement = cloneDeep(element) as ListElementItem;
            const instance = e.args['result'] as ListLayoutSettings;
            const title = e.args['title'] as string;

            newElement.name = title;
            newElement.layouts = newElement.layouts.map((item, i) => {
              if (i == index) {
                return instance;
              } else {
                return item;
              }
            });
            newElement.visibleInput = e.args['visible_input'] as Input;
            newElement.margin = e.args['margin'] as Margin;

            if (viewContextElement) {
              viewContextElement.initInfo({ name: title }, true);
            }
            return {
              el: newElement
            };
          } else if (e.type == CustomizeBarEditEventType.Canceled) {
            return {
              el: initialElement
            };
          } else if (e.type == CustomizeBarEditEventType.Deleted) {
            return {
              el: initialElement,
              event: CustomizeBarEditEventType.Deleted
            };
          }
        })
      );
    } else if (element instanceof ModelElementItem) {
      const viewContextElement = component ? (component as ModelElementComponent).viewContextElement : undefined;

      const initialElement = cloneDeep(element);
      return this.customizeModelElementItem({
        context: context,
        element: element,
        viewContext: viewContext,
        viewContextElement: viewContextElement,
        titleEnabled: options.titleEnabled,
        titleEditable: true,
        deleteEnabled: true,
        ...options
      }).pipe(
        map(e => {
          if (e.type == CustomizeBarEditEventType.Created || e.type == CustomizeBarEditEventType.Updated) {
            return {
              el: e.args['result'] as ModelElementItem
            };
          } else if (e.type == CustomizeBarEditEventType.Canceled) {
            return {
              el: initialElement
            };
          } else if (e.type == CustomizeBarEditEventType.Deleted) {
            return {
              el: initialElement,
              event: CustomizeBarEditEventType.Deleted
            };
          }
        })
      );
    } else if (element instanceof FormElementItem) {
      const viewContextElement = component ? (component as FormElementComponent).viewContextElement : undefined;

      const initialElement = cloneDeep(element);
      return this.customizeFormElementItem({
        context: context,
        element: element,
        viewContext: viewContext,
        viewContextElement: viewContextElement,
        titleEnabled: options.titleEnabled,
        titleEditable: true,
        backLabel: 'All components',
        deleteEnabled: true,
        ...options
      }).pipe(
        map(e => {
          if (e.type == CustomizeBarEditEventType.Created || e.type == CustomizeBarEditEventType.Updated) {
            return {
              el: e.args['result'] as FormElementItem
            };
          } else if (e.type == CustomizeBarEditEventType.Canceled) {
            return {
              el: initialElement
            };
          } else if (e.type == CustomizeBarEditEventType.Deleted) {
            return {
              el: initialElement,
              event: CustomizeBarEditEventType.Deleted
            };
          }
        })
      );
    } else if (element instanceof FormSubmitElementItem) {
      const initialElement = cloneDeep(element);
      return this.customizeFormSubmitElementItem({
        context: context,
        element: element,
        viewContext: viewContext,
        elementUpdated: elementUpdated,
        append: false,
        title: element.name,
        titleEnabled: options.titleEnabled,
        titleEditable: true,
        deleteEnabled: true,
        visibleInput: element.visibleInput,
        visibleEditable: true,
        ...options
      }).pipe(
        map(e => {
          if (e.type == CustomizeBarEditEventType.Created || e.type == CustomizeBarEditEventType.Updated) {
            const newElement = e.args['result'] as FormSubmitElementItem;
            newElement.name = e.args['title'] as string;
            return {
              el: newElement
            };
          } else if (e.type == CustomizeBarEditEventType.Canceled) {
            return {
              el: initialElement
            };
          } else if (e.type == CustomizeBarEditEventType.Deleted) {
            return {
              el: initialElement,
              event: CustomizeBarEditEventType.Deleted
            };
          }
        })
      );
    } else if (element instanceof FormElementItem) {
      const viewContextElement = component ? (component as FormElementComponent).viewContextElement : undefined;

      const initialElement = cloneDeep(element);
      return this.customizeFormElementItem({
        context: context,
        element: element,
        viewContext: viewContext,
        viewContextElement: viewContextElement,
        titleEnabled: options.titleEnabled,
        titleEditable: true,
        deleteEnabled: true,
        ...options
      }).pipe(
        map(e => {
          if (e.type == CustomizeBarEditEventType.Created || e.type == CustomizeBarEditEventType.Updated) {
            return {
              el: e.args['result'] as FormElementItem
            };
          } else if (e.type == CustomizeBarEditEventType.Canceled) {
            return {
              el: initialElement
            };
          } else if (e.type == CustomizeBarEditEventType.Deleted) {
            return {
              el: initialElement,
              event: CustomizeBarEditEventType.Deleted
            };
          }
        })
      );
    } else if (element instanceof TextElementItem) {
      const initialElement = cloneDeep(element);
      return this.customizeTextElementItem({
        context: context,
        element: element,
        viewContext: viewContext,
        titleEnabled: options.titleEnabled,
        titleEditable: true,
        deleteEnabled: true,
        ...options
      }).pipe(
        map(e => {
          if (e.type == CustomizeBarEditEventType.Created || e.type == CustomizeBarEditEventType.Updated) {
            return {
              el: e.args['result'] as TextElementItem
            };
          } else if (e.type == CustomizeBarEditEventType.Canceled) {
            return {
              el: initialElement
            };
          } else if (e.type == CustomizeBarEditEventType.Deleted) {
            return {
              el: initialElement,
              event: CustomizeBarEditEventType.Deleted
            };
          }
        })
      );
    } else if (element instanceof ColumnsLayoutElementItem) {
      const initialElement = cloneDeep(element);
      return this.customizeColumnsElementItem({
        context: context,
        element: element,
        viewContext: viewContext,
        titleEnabled: options.titleEnabled,
        titleEditable: true,
        deleteEnabled: true,
        ...options
      }).pipe(
        map(e => {
          if (e.type == CustomizeBarEditEventType.Created || e.type == CustomizeBarEditEventType.Updated) {
            return {
              el: e.args['result'] as ColumnsLayoutElementItem
            };
          } else if (e.type == CustomizeBarEditEventType.Canceled) {
            return {
              el: initialElement
            };
          } else if (e.type == CustomizeBarEditEventType.Deleted) {
            return {
              el: initialElement,
              event: CustomizeBarEditEventType.Deleted
            };
          }
        })
      );
    } else if (element instanceof SpacingElementItem) {
      const initialElement = cloneDeep(element);
      return this.customizeSpacingElementItem({
        context: context,
        element: element,
        viewContext: viewContext,
        titleEnabled: options.titleEnabled,
        titleEditable: true,
        deleteEnabled: true,
        ...options
      }).pipe(
        map(e => {
          if (e.type == CustomizeBarEditEventType.Created || e.type == CustomizeBarEditEventType.Updated) {
            return {
              el: e.args['result'] as SpacingElementItem
            };
          } else if (e.type == CustomizeBarEditEventType.Canceled) {
            return {
              el: initialElement
            };
          } else if (e.type == CustomizeBarEditEventType.Deleted) {
            return {
              el: initialElement,
              event: CustomizeBarEditEventType.Deleted
            };
          }
        })
      );
    } else if (element instanceof SeparatorElementItem) {
      const initialElement = cloneDeep(element);
      return this.customizeSeparatorElementItem({
        context: context,
        element: element,
        viewContext: viewContext,
        titleEnabled: options.titleEnabled,
        titleEditable: true,
        deleteEnabled: true,
        ...options
      }).pipe(
        map(e => {
          if (e.type == CustomizeBarEditEventType.Created || e.type == CustomizeBarEditEventType.Updated) {
            return {
              el: e.args['result'] as SeparatorElementItem
            };
          } else if (e.type == CustomizeBarEditEventType.Canceled) {
            return {
              el: initialElement
            };
          } else if (e.type == CustomizeBarEditEventType.Deleted) {
            return {
              el: initialElement,
              event: CustomizeBarEditEventType.Deleted
            };
          }
        })
      );
    } else if (element instanceof StepsElementItem) {
      const initialElement = cloneDeep(element);
      return this.customizeStepsElementItem({
        context: context,
        element: element,
        viewContext: viewContext,
        titleEnabled: options.titleEnabled,
        titleEditable: true,
        deleteEnabled: true,
        ...options
      }).pipe(
        map(e => {
          if (e.type == CustomizeBarEditEventType.Created || e.type == CustomizeBarEditEventType.Updated) {
            return {
              el: e.args['result'] as StepsElementItem
            };
          } else if (e.type == CustomizeBarEditEventType.Canceled) {
            return {
              el: initialElement
            };
          } else if (e.type == CustomizeBarEditEventType.Deleted) {
            return {
              el: initialElement,
              event: CustomizeBarEditEventType.Deleted
            };
          }
        })
      );
    } else if (element instanceof FilterElementItem) {
      const initialElement = cloneDeep(element);
      return this.customizeFilterElementItem({
        context: context,
        element: element,
        viewContext: viewContext,
        titleEnabled: options.titleEnabled,
        titleEditable: true,
        deleteEnabled: true,
        ...options
      }).pipe(
        map(e => {
          if (e.type == CustomizeBarEditEventType.Created || e.type == CustomizeBarEditEventType.Updated) {
            return {
              el: e.args['result'] as FilterElementItem
            };
          } else if (e.type == CustomizeBarEditEventType.Canceled) {
            return {
              el: initialElement
            };
          } else if (e.type == CustomizeBarEditEventType.Deleted) {
            return {
              el: initialElement,
              event: CustomizeBarEditEventType.Deleted
            };
          }
        })
      );
    } else if (element instanceof CardLayoutElementItem) {
      const initialElement = cloneDeep(element);
      return this.customizeCardElementItem({
        context: context,
        element: element,
        viewContext: viewContext,
        titleEnabled: options.titleEnabled,
        titleEditable: true,
        deleteEnabled: true,
        ...options
      }).pipe(
        map(e => {
          if (e.type == CustomizeBarEditEventType.Created || e.type == CustomizeBarEditEventType.Updated) {
            return {
              el: e.args['result'] as CardLayoutElementItem
            };
          } else if (e.type == CustomizeBarEditEventType.Canceled) {
            return {
              el: initialElement
            };
          } else if (e.type == CustomizeBarEditEventType.Deleted) {
            return {
              el: initialElement,
              event: CustomizeBarEditEventType.Deleted
            };
          }
        })
      );
    } else if (element instanceof StackLayoutElementItem) {
      const initialElement = cloneDeep(element);
      return this.customizeStackElementItem({
        context: context,
        element: element,
        viewContext: viewContext,
        titleEnabled: options.titleEnabled,
        titleEditable: true,
        deleteEnabled: true,
        ...options
      }).pipe(
        map(e => {
          if (e.type == CustomizeBarEditEventType.Created || e.type == CustomizeBarEditEventType.Updated) {
            return {
              el: e.args['result'] as CardLayoutElementItem
            };
          } else if (e.type == CustomizeBarEditEventType.Canceled) {
            return {
              el: initialElement
            };
          } else if (e.type == CustomizeBarEditEventType.Deleted) {
            return {
              el: initialElement,
              event: CustomizeBarEditEventType.Deleted
            };
          }
        })
      );
    } else if (element instanceof TabsLayoutElementItem) {
      const initialElement = cloneDeep(element);
      return this.customizeTabsElementItem({
        context: context,
        element: element,
        viewContext: viewContext,
        titleEnabled: options.titleEnabled,
        titleEditable: true,
        deleteEnabled: true,
        ...options
      }).pipe(
        map(e => {
          if (e.type == CustomizeBarEditEventType.Created || e.type == CustomizeBarEditEventType.Updated) {
            return {
              el: e.args['result'] as TabsLayoutElementItem
            };
          } else if (e.type == CustomizeBarEditEventType.Canceled) {
            return {
              el: initialElement
            };
          } else if (e.type == CustomizeBarEditEventType.Deleted) {
            return {
              el: initialElement,
              event: CustomizeBarEditEventType.Deleted
            };
          }
        })
      );
    } else if (element instanceof WidgetElementItem) {
      const viewContextElement = component ? (component as AutoWidgetElementComponent).viewContextElement : undefined;

      const initialElement = cloneDeep(element);
      return this.customizeWidget({
        context: context,
        element: element,
        item: element.widget,
        title: element.name,
        titleEnabled: options.titleEnabled,
        titleEditable: true,
        visibleInput: element.visibleInput,
        visibleEditable: true,
        margin: element.margin,
        marginEditable: true,
        viewContext: viewContext,
        viewContextElement: viewContextElement,
        deleteEnabled: true,
        component: component,
        ...options
      }).pipe(
        map(e => {
          if (e.type == CustomizeBarEditEventType.Created || e.type == CustomizeBarEditEventType.Updated) {
            const newElement = cloneDeep(element) as WidgetElementItem;
            newElement.name = e.args['title'] as string;
            newElement.widget = e.args['result'] as Widget;
            newElement.visibleInput = e.args['visible_input'] as Input;
            newElement.margin = e.args['margin'] as Margin;
            return {
              el: newElement
            };
          } else if (e.type == CustomizeBarEditEventType.Canceled) {
            return {
              el: initialElement
            };
          } else if (e.type == CustomizeBarEditEventType.Deleted) {
            return {
              el: initialElement,
              event: CustomizeBarEditEventType.Deleted
            };
          }
        })
      );
    } else if (element instanceof ImageElementItem) {
      const initialElement = cloneDeep(element);
      return this.customizeImage({
        context: context,
        element: element,
        viewContext: viewContext,
        elementUpdated: elementUpdated,
        titleEnabled: options.titleEnabled,
        deleteEnabled: true,
        ...options
      }).pipe(
        map(e => {
          if (e.type == CustomizeBarEditEventType.Created || e.type == CustomizeBarEditEventType.Updated) {
            return {
              el: e.args['result'] as ImageElementItem
            };
          } else if (e.type == CustomizeBarEditEventType.Canceled) {
            return {
              el: initialElement
            };
          } else if (e.type == CustomizeBarEditEventType.Deleted) {
            return {
              el: initialElement,
              event: CustomizeBarEditEventType.Deleted
            };
          }
        })
      );
    } else if (element instanceof IFrameElementItem) {
      const initialElement = cloneDeep(element);
      return this.customizeIFrame({
        context: context,
        element: element,
        viewContext: viewContext,
        titleEnabled: options.titleEnabled,
        deleteEnabled: true,
        ...options
      }).pipe(
        map(e => {
          if (e.type == CustomizeBarEditEventType.Created || e.type == CustomizeBarEditEventType.Updated) {
            return {
              el: e.args['result'] as IFrameElementItem
            };
          } else if (e.type == CustomizeBarEditEventType.Canceled) {
            return {
              el: initialElement
            };
          } else if (e.type == CustomizeBarEditEventType.Deleted) {
            return {
              el: initialElement,
              event: CustomizeBarEditEventType.Deleted
            };
          }
        })
      );
    } else if (element instanceof QrCodeElementItem) {
      const initialElement = cloneDeep(element);
      return this.customizeQrCode({
        context: context,
        element: element,
        viewContext: viewContext,
        titleEnabled: options.titleEnabled,
        deleteEnabled: true,
        ...options
      }).pipe(
        map(e => {
          if (e.type == CustomizeBarEditEventType.Created || e.type == CustomizeBarEditEventType.Updated) {
            return {
              el: e.args['result'] as QrCodeElementItem
            };
          } else if (e.type == CustomizeBarEditEventType.Canceled) {
            return {
              el: initialElement
            };
          } else if (e.type == CustomizeBarEditEventType.Deleted) {
            return {
              el: initialElement,
              event: CustomizeBarEditEventType.Deleted
            };
          }
        })
      );
    } else if (element instanceof BarCodeElementItem) {
      const initialElement = cloneDeep(element);
      return this.customizeBarCode({
        context: context,
        element: element,
        viewContext: viewContext,
        titleEnabled: options.titleEnabled,
        deleteEnabled: true,
        ...options
      }).pipe(
        map(e => {
          if (e.type == CustomizeBarEditEventType.Created || e.type == CustomizeBarEditEventType.Updated) {
            return {
              el: e.args['result'] as BarCodeElementItem
            };
          } else if (e.type == CustomizeBarEditEventType.Canceled) {
            return {
              el: initialElement
            };
          } else if (e.type == CustomizeBarEditEventType.Deleted) {
            return {
              el: initialElement,
              event: CustomizeBarEditEventType.Deleted
            };
          }
        })
      );
    } else if (element instanceof ScannerElementItem) {
      const initialElement = cloneDeep(element);
      const viewContextElement = component ? (component as ScannerElementComponent).viewContextElement : undefined;
      return this.customizeScanner({
        context: context,
        element: element,
        viewContext: viewContext,
        viewContextElement: viewContextElement,
        titleEnabled: options.titleEnabled,
        deleteEnabled: true,
        analyticsSource: element.analyticsName,
        ...options
      }).pipe(
        map(e => {
          if (e.type == CustomizeBarEditEventType.Created || e.type == CustomizeBarEditEventType.Updated) {
            return {
              el: e.args['result'] as ScannerElementItem
            };
          } else if (e.type == CustomizeBarEditEventType.Canceled) {
            return {
              el: initialElement
            };
          } else if (e.type == CustomizeBarEditEventType.Deleted) {
            return {
              el: initialElement,
              event: CustomizeBarEditEventType.Deleted
            };
          }
        })
      );
    } else if (element instanceof AlertElementItem) {
      const initialElement = cloneDeep(element);
      return this.customizeAlert({
        context: context,
        element: element,
        viewContext: viewContext,
        titleEnabled: options.titleEnabled,
        deleteEnabled: true,
        analyticsSource: element.analyticsName,
        ...options
      }).pipe(
        map(e => {
          if (e.type == CustomizeBarEditEventType.Created || e.type == CustomizeBarEditEventType.Updated) {
            return {
              el: e.args['result'] as AlertElementItem
            };
          } else if (e.type == CustomizeBarEditEventType.Canceled) {
            return {
              el: initialElement
            };
          } else if (e.type == CustomizeBarEditEventType.Deleted) {
            return {
              el: initialElement,
              event: CustomizeBarEditEventType.Deleted
            };
          }
        })
      );
    } else if (element instanceof FileViewerElementItem) {
      const initialElement = cloneDeep(element);
      return this.customizeFileViewer({
        context: context,
        element: element,
        viewContext: viewContext,
        titleEnabled: options.titleEnabled,
        deleteEnabled: true,
        ...options
      }).pipe(
        map(e => {
          if (e.type == CustomizeBarEditEventType.Created || e.type == CustomizeBarEditEventType.Updated) {
            return {
              el: e.args['result'] as FileViewerElementItem
            };
          } else if (e.type == CustomizeBarEditEventType.Canceled) {
            return {
              el: initialElement
            };
          } else if (e.type == CustomizeBarEditEventType.Deleted) {
            return {
              el: initialElement,
              event: CustomizeBarEditEventType.Deleted
            };
          }
        })
      );
    } else if (element instanceof RangeSliderElementItem) {
      const initialElement = cloneDeep(element);
      const viewContextElement = component ? (component as RangeSliderElementComponent).viewContextElement : undefined;
      return this.customizeRangeSlider({
        context: context,
        element: element,
        viewContext: viewContext,
        viewContextElement: viewContextElement,
        titleEnabled: options.titleEnabled,
        titleEditable: true,
        deleteEnabled: true,
        ...options
      }).pipe(
        map(e => {
          if (e.type == CustomizeBarEditEventType.Created || e.type == CustomizeBarEditEventType.Updated) {
            return {
              el: e.args['result'] as RangeSliderElementItem
            };
          } else if (e.type == CustomizeBarEditEventType.Canceled) {
            return {
              el: initialElement
            };
          } else if (e.type == CustomizeBarEditEventType.Deleted) {
            return {
              el: initialElement,
              event: CustomizeBarEditEventType.Deleted
            };
          }
        })
      );
    } else if (element instanceof DateRangeElementItem) {
      const initialElement = cloneDeep(element);
      const viewContextElement = component ? (component as RangeSliderElementComponent).viewContextElement : undefined;
      return this.customizeDateRange({
        context: context,
        element: element,
        viewContext: viewContext,
        viewContextElement: viewContextElement,
        titleEnabled: options.titleEnabled,
        titleEditable: true,
        deleteEnabled: true,
        ...options
      }).pipe(
        map(e => {
          if (e.type == CustomizeBarEditEventType.Created || e.type == CustomizeBarEditEventType.Updated) {
            return {
              el: e.args['result'] as DateRangeElementItem
            };
          } else if (e.type == CustomizeBarEditEventType.Canceled) {
            return {
              el: initialElement
            };
          } else if (e.type == CustomizeBarEditEventType.Deleted) {
            return {
              el: initialElement,
              event: CustomizeBarEditEventType.Deleted
            };
          }
        })
      );
    } else if (element instanceof CollapseElementItem) {
      const initialElement = cloneDeep(element);
      return this.customizeCollapse({
        context: context,
        element: element,
        viewContext: viewContext,
        titleEnabled: options.titleEnabled,
        titleEditable: true,
        deleteEnabled: true,
        ...options
      }).pipe(
        map(e => {
          if (e.type == CustomizeBarEditEventType.Created || e.type == CustomizeBarEditEventType.Updated) {
            return {
              el: e.args['result'] as CollapseElementItem
            };
          } else if (e.type == CustomizeBarEditEventType.Canceled) {
            return {
              el: initialElement
            };
          } else if (e.type == CustomizeBarEditEventType.Deleted) {
            return {
              el: initialElement,
              event: CustomizeBarEditEventType.Deleted
            };
          }
        })
      );
    } else if (element instanceof AccordionElementItem) {
      const initialElement = cloneDeep(element);
      return this.customizeAccordion({
        context: context,
        element: element,
        viewContext: viewContext,
        titleEnabled: options.titleEnabled,
        deleteEnabled: true,
        ...options
      }).pipe(
        map(e => {
          if (e.type == CustomizeBarEditEventType.Created || e.type == CustomizeBarEditEventType.Updated) {
            return {
              el: e.args['result'] as AccordionElementItem
            };
          } else if (e.type == CustomizeBarEditEventType.Canceled) {
            return {
              el: initialElement
            };
          } else if (e.type == CustomizeBarEditEventType.Deleted) {
            return {
              el: initialElement,
              event: CustomizeBarEditEventType.Deleted
            };
          }
        })
      );
    } else if (element instanceof BackElementItem) {
      const initialElement = cloneDeep(element);
      return this.customizeBack({
        context: context,
        element: element,
        viewContext: viewContext,
        append: false,
        title: element.name,
        titleEnabled: options.titleEnabled,
        titleEditable: true,
        deleteEnabled: true,
        ...options
      }).pipe(
        map(e => {
          if (e.type == CustomizeBarEditEventType.Created || e.type == CustomizeBarEditEventType.Updated) {
            const newElement = e.args['result'] as BackElementItem;
            newElement.name = e.args['title'] as string;
            return {
              el: newElement
            };
          } else if (e.type == CustomizeBarEditEventType.Canceled) {
            return {
              el: initialElement
            };
          } else if (e.type == CustomizeBarEditEventType.Deleted) {
            return {
              el: initialElement,
              event: CustomizeBarEditEventType.Deleted
            };
          }
        })
      );
    } else if (element instanceof CustomElementItem) {
      const initialElement = cloneDeep(element);
      return this.customizeCustom({
        context: context,
        element: element,
        viewContext: viewContext,
        elementUpdated: elementUpdated,
        append: false,
        title: element.name,
        titleEnabled: options.titleEnabled,
        titleEditable: true,
        deleteEnabled: true,
        ...options
      }).pipe(
        map(e => {
          if (e.type == CustomizeBarEditEventType.Created || e.type == CustomizeBarEditEventType.Updated) {
            const newElement = e.args['result'] as CustomElementItem;
            newElement.name = e.args['title'] as string;
            return {
              el: newElement
            };
          } else if (e.type == CustomizeBarEditEventType.Canceled) {
            return {
              el: initialElement
            };
          } else if (e.type == CustomizeBarEditEventType.Deleted) {
            return {
              el: initialElement,
              event: CustomizeBarEditEventType.Deleted
            };
          }
        })
      );
    } else if (element instanceof ActionGroupElementItem) {
      const initialElement = cloneDeep(element);
      return this.customizeActionGroup({
        context: context,
        element: element,
        viewContext: viewContext,
        elementUpdated: elementUpdated,
        append: false,
        title: element.name,
        titleEnabled: options.titleEnabled,
        titleEditable: true,
        deleteEnabled: true,
        visibleInput: element.visibleInput,
        visibleEditable: true,
        ...options
      }).pipe(
        map(e => {
          if (e.type == CustomizeBarEditEventType.Created || e.type == CustomizeBarEditEventType.Updated) {
            const newElement = e.args['result'] as ActionGroupElementItem;
            return {
              el: newElement
            };
          } else if (e.type == CustomizeBarEditEventType.Canceled) {
            return {
              el: initialElement
            };
          } else if (e.type == CustomizeBarEditEventType.Deleted) {
            return {
              el: initialElement,
              event: CustomizeBarEditEventType.Deleted
            };
          }
        })
      );
    } else if (element instanceof ActionDropdownElementItem) {
      const initialElement = cloneDeep(element);
      return this.customizeActionDropdown({
        context: context,
        element: element,
        viewContext: viewContext,
        elementUpdated: elementUpdated,
        append: false,
        title: element.name,
        titleEnabled: options.titleEnabled,
        titleEditable: true,
        deleteEnabled: true,
        visibleInput: element.visibleInput,
        visibleEditable: true,
        ...options
      }).pipe(
        map(e => {
          if (e.type == CustomizeBarEditEventType.Created || e.type == CustomizeBarEditEventType.Updated) {
            const newElement = e.args['result'] as ActionDropdownElementItem;
            return {
              el: newElement
            };
          } else if (e.type == CustomizeBarEditEventType.Canceled) {
            return {
              el: initialElement
            };
          } else if (e.type == CustomizeBarEditEventType.Deleted) {
            return {
              el: initialElement,
              event: CustomizeBarEditEventType.Deleted
            };
          }
        })
      );
    }

    return result;
  }
}

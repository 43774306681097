import { HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { AdminMode, ROUTE_ADMIN_MODE } from '@modules/admin-mode';
import {
  Environment,
  Project,
  Resource,
  ResourceTypeItem,
  SecretToken,
  SecretTokenService,
  SecretTokenType
} from '@modules/projects';
import { HttpQueryService, QueryType, StorageQuery } from '@modules/queries';
import { ResourceControllerService, ResourceParamsResult } from '@modules/resources';
import { Storage } from '@modules/storages';

// TODO: Refactor import
import { HTTP_QUERY_KEY_AUTH_NAME } from '@modules/projects-components/data/http-query-auth';

import { ResourceGeneratorService } from '../resource-generator/resource-generator.service';

export interface S3CompatibleParamsOptions {
  endpoint: string;
  buckets: string[];
  access_key_id: string;
  secret_access_key: string;
}

@Injectable()
export class S3CompatibleGeneratorService extends ResourceGeneratorService<S3CompatibleParamsOptions> {
  constructor(@Inject(ROUTE_ADMIN_MODE) private mode: AdminMode, private secretTokenService: SecretTokenService) {
    super();
  }

  getParamsOptions(
    project: Project,
    environment: Environment,
    resource: Resource
  ): Observable<S3CompatibleParamsOptions> {
    return this.secretTokenService
      .getDetail(
        project.uniqueName,
        environment.uniqueName,
        resource.uniqueName,
        HTTP_QUERY_KEY_AUTH_NAME,
        this.mode == AdminMode.Builder
      )
      .pipe(
        map(result => {
          return {
            endpoint: resource.params['endpoint'],
            buckets: resource.params['buckets'] || [],
            access_key_id: resource.params['access_key_id'],
            secret_access_key: result.value
          };
        })
      );
  }

  generateParams(
    project: Project,
    environment: Environment,
    typeItem: ResourceTypeItem,
    options: S3CompatibleParamsOptions
  ): Observable<ResourceParamsResult> {
    const storages = options.buckets.map(bucket => {
      const storage = new Storage();

      storage.uniqueName = bucket;
      storage.name = bucket;
      storage.params = { bucket: bucket };

      storage.uploadQuery = new StorageQuery();
      storage.uploadQuery.queryType = QueryType.Simple;
      storage.uploadQuery.simpleQuery = new storage.uploadQuery.simpleQueryClass();

      storage.getQuery = new StorageQuery();
      storage.getQuery.queryType = QueryType.Simple;
      storage.getQuery.simpleQuery = new storage.getQuery.simpleQueryClass();

      storage.removeQuery = new StorageQuery();
      storage.removeQuery.queryType = QueryType.Simple;
      storage.removeQuery.simpleQuery = new storage.removeQuery.simpleQueryClass();

      storage.createDirectoryQuery = new StorageQuery();
      storage.createDirectoryQuery.queryType = QueryType.Simple;
      storage.createDirectoryQuery.simpleQuery = new storage.createDirectoryQuery.simpleQueryClass();

      storage.getObjectUrlQuery = new StorageQuery();
      storage.getObjectUrlQuery.queryType = QueryType.Simple;
      storage.getObjectUrlQuery.simpleQuery = new storage.getObjectUrlQuery.simpleQueryClass();

      return storage;
    });

    const token = new SecretToken();

    token.name = HTTP_QUERY_KEY_AUTH_NAME;
    token.type = SecretTokenType.Static;
    token.value = options.secret_access_key;

    return of({
      resourceParams: {
        endpoint: options.endpoint,
        buckets: options.buckets,
        access_key_id: options.access_key_id
      },
      storages: storages.map(item => item.serialize()),
      secretTokens: [token.serialize()]
    });
  }
}

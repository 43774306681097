var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnDestroy } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { ModelData, ModelDataType } from '@modules/customize';
import { ModelDescriptionStore } from '@modules/model-queries';
import { CurrentEnvironmentStore } from '@modules/projects';
import { ResourceControllerService } from '@modules/resources';
import { controlValue, generateUUID } from '@shared';
import { ListModelDescriptionDataSourceControl } from '../model-description-data-source-edit/list-model-description-data-source';
import { ModelDescriptionDataSourceControl } from '../model-description-data-source-edit/model-description-data-source';
var CustomizeBarModelDataControl = /** @class */ (function (_super) {
    __extends(CustomizeBarModelDataControl, _super);
    function CustomizeBarModelDataControl(currentEnvironmentStore, modelDescriptionStore, resourceControllerService, detailDataSourceControl, listDataSourceControl) {
        var _this = _super.call(this, {
            type: new FormControl(),
            uid: new FormControl(generateUUID().substring(0, 4)),
            name: new FormControl(),
            detail_data_source: detailDataSourceControl,
            list_data_source: listDataSourceControl
        }) || this;
        _this.currentEnvironmentStore = currentEnvironmentStore;
        _this.modelDescriptionStore = modelDescriptionStore;
        _this.resourceControllerService = resourceControllerService;
        controlValue(_this.controls.type)
            .pipe(untilDestroyed(_this))
            .subscribe(function (type) {
            if (type == ModelDataType.Detail) {
                _this.controls.detail_data_source.setRequired(true);
                _this.controls.list_data_source.setRequired(false);
            }
            if (type == ModelDataType.List) {
                _this.controls.detail_data_source.setRequired(false);
                _this.controls.list_data_source.setRequired(true);
            }
        });
        return _this;
    }
    CustomizeBarModelDataControl.prototype.ngOnDestroy = function () { };
    CustomizeBarModelDataControl.prototype.deserialize = function (item) {
        this.instance = item;
        if (item) {
            this.controls.type.patchValue(item.type);
            this.controls.uid.patchValue(item.uid);
            this.controls.name.patchValue(item.name);
        }
        this.controls.detail_data_source.deserialize(item ? item.detailDataSource : undefined);
        this.controls.list_data_source.deserialize(item ? item.listDataSource : undefined);
        this.markAsPristine();
    };
    CustomizeBarModelDataControl.prototype.serialize = function () {
        var instance = new ModelData();
        if (this.instance) {
            instance.deserialize(this.instance.serialize());
        }
        instance.type = this.controls.type.value;
        instance.uid = this.controls.uid.value;
        instance.name = this.controls.name.value;
        if (this.controls.type.value == ModelDataType.Detail) {
            instance.detailDataSource = this.controls.detail_data_source.serialize();
        }
        if (this.controls.type.value == ModelDataType.List) {
            instance.listDataSource = this.controls.list_data_source.serialize();
        }
        return instance;
    };
    return CustomizeBarModelDataControl;
}(FormGroup));
export { CustomizeBarModelDataControl };

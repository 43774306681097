import { Injectable } from '@angular/core';
import { BehaviorSubject, interval, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class IntercomService {
  private _loaded: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private _visible = new BehaviorSubject(false);
  private _chatOpened = new BehaviorSubject(false);

  loadedCheck: Subscription;

  constructor() {
    this.loadedCheck = interval(1000).subscribe(() => {
      if (!this.intercom) {
        return;
      }

      this.loadedCheck.unsubscribe();
      this._loaded.next(true);
    });

    this.loaded$.subscribe(() => {
      interval(400).subscribe(() => {
        this._visible.next(this.visible);
      });

      this._chatOpened.next(this.chatOpened);

      this.intercom('onShow', () => {
        this._chatOpened.next(true);
      });

      this.intercom('onHide', () => {
        this._chatOpened.next(false);
      });
    });
  }

  get loaded$() {
    return this._loaded.pipe(filter(item => item == true));
  }

  get intercom() {
    return window['Intercom'];
  }

  openChat() {
    this.loaded$.subscribe(() => {
      this.intercom('show');
    });
  }

  show() {}

  hide() {}

  get chatOpened() {
    return document.getElementsByClassName('intercom-messenger-frame').length != 0;
  }

  get visible() {
    return document.getElementsByClassName('intercom-launcher').length != 0;
  }

  get visible$() {
    return this._visible.asObservable();
  }
}

<app-page-block [marginSize]="'s'">
  <app-auto-field
    [form]="form.form"
    [field]="
      createField({
        name: 'backend',
        label: 'Provider',
        field: 'SelectField',
        required: true,
        params: { options: form.backendOptions, classes: fill ? ['select_fill'] : [] }
      })
    "
  >
  </app-auto-field>
</app-page-block>

<ng-container *ngIf="form.form.value['backend']">
  <app-page-block [marginSize]="'s'">
    <app-auto-field
      [form]="form.form"
      [field]="
        createField({
          name: 'key',
          label: 'Client ID',
          field: 'CharField',
          required: true,
          params: { classes: fill ? ['input_fill'] : [] }
        })
      "
    >
    </app-auto-field>
  </app-page-block>

  <app-page-block [marginSize]="'s'">
    <app-auto-field
      [form]="form.form"
      [field]="
        createField({
          name: 'secret',
          label: 'Client Secret',
          field: 'CharField',
          required: true,
          params: { classes: fill ? ['input_fill'] : [] }
        })
      "
    >
    </app-auto-field>
  </app-page-block>

  <app-page-block [marginSize]="'s'">
    <app-auto-field
      [form]="form.form"
      [field]="
        createField({
          name: 'scope',
          label: 'scope',
          field: 'CharField',
          description: 'Comma separated list of scopes',
          required: true,
          params: { classes: fill ? ['input_fill'] : [] }
        })
      "
    >
    </app-auto-field>
  </app-page-block>

  <app-page-block [marginSize]="'s'">
    <app-auto-field
      [form]="form.form"
      [field]="
        createField({
          name: 'scope_separator',
          label: 'scope request separator',
          field: 'CharField',
          params: { classes: fill ? ['input_fill'] : [] }
        })
      "
    >
    </app-auto-field>
  </app-page-block>

  <ng-container *ngIf="form.form.value['backend'] == 'cognito'">
    <app-page-block [marginSize]="'s'">
      <app-auto-field
        [form]="form.cognitoOAuth2Form"
        [field]="
          createField({
            name: 'pool_domain',
            label: 'pool domain',
            field: 'CharField',
            required: true,
            params: { classes: fill ? ['input_fill'] : [] }
          })
        "
      >
      </app-auto-field>
    </app-page-block>
  </ng-container>

  <ng-container
    *ngIf="
      form.form.value['backend'] == 'azuread-tenant-oauth2' || form.form.value['backend'] == 'azuread-v2-tenant-oauth2'
    "
  >
    <app-page-block [marginSize]="'s'">
      <app-auto-field
        [form]="form.azureADTenantOAuth2Form"
        [field]="
          createField({
            name: 'tenant_id',
            label: 'Tenant ID',
            field: 'CharField',
            required: true,
            params: { classes: fill ? ['input_fill'] : [] }
          })
        "
      >
      </app-auto-field>
    </app-page-block>
  </ng-container>

  <ng-container *ngIf="form.form.value['backend'] == 'auth0'">
    <app-page-block [marginSize]="'s'">
      <app-auto-field
        [form]="form.auth0OAuth2Form"
        [field]="
          createField({
            name: 'domain',
            label: 'Domain',
            field: 'CharField',
            required: true,
            params: { classes: fill ? ['input_fill'] : [] }
          })
        "
      >
      </app-auto-field>
    </app-page-block>
  </ng-container>

  <ng-container *ngIf="form.form.value['backend'] == 'keycloak'">
    <app-page-block [marginSize]="'s'">
      <app-auto-field
        [form]="form.keycloakOAuth2Form"
        [field]="
          createField({
            name: 'authorization_url',
            label: 'authorization URL',
            field: 'CharField',
            required: true,
            params: { classes: fill ? ['input_fill'] : [] }
          })
        "
      >
      </app-auto-field>
    </app-page-block>

    <app-page-block [marginSize]="'s'">
      <app-auto-field
        [form]="form.keycloakOAuth2Form"
        [field]="
          createField({
            name: 'access_token_url',
            label: 'access token URL',
            field: 'CharField',
            required: true,
            params: { classes: fill ? ['input_fill'] : [] }
          })
        "
      >
      </app-auto-field>
    </app-page-block>

    <app-page-block [marginSize]="'s'">
      <app-auto-field
        [form]="form.keycloakOAuth2Form"
        [field]="
          createField({
            name: 'public_key',
            label: 'Public Key',
            field: 'CharField',
            required: true,
            params: { classes: fill ? ['input_fill'] : [] }
          })
        "
      >
      </app-auto-field>
    </app-page-block>
  </ng-container>

  <ng-container *ngIf="form.form.value['backend'] == 'custom_oauth_2'">
    <app-page-block [marginSize]="'s'">
      <app-auto-field
        [form]="form.customOAuth2Form"
        [field]="
          createField({
            name: 'authorization_url',
            label: 'authorization URL',
            field: 'CharField',
            required: true,
            params: { classes: fill ? ['input_fill'] : [] }
          })
        "
      >
      </app-auto-field>
    </app-page-block>

    <app-page-block [marginSize]="'s'">
      <app-auto-field
        [form]="form.customOAuth2Form"
        [field]="
          createField({
            name: 'access_token_url',
            label: 'access token URL',
            field: 'CharField',
            required: true,
            params: { classes: fill ? ['input_fill'] : [] }
          })
        "
      >
      </app-auto-field>
    </app-page-block>
  </ng-container>

  <app-page-block *ngIf="createOauthTokenRedirectUrl" [marginSize]="'s'">
    <div class="field">
      <label class="field__label">
        <span class="field__label-text">OAuth Callback URL</span>
        <a
          href="javascript:void(0)"
          class="field__label-link"
          style="margin-left: 4px;"
          (click)="$event.stopPropagation(); copy(completeUrl, 'OAuth Callback URL')"
        >
          <span class="field__label-link-icon icon-duplicate"></span>
          Copy
        </a>
      </label>
      <div class="field__value">
        <div class="input-icon-wrapper">
          <span class="input-icon input-icon_left icon-link"></span>
          <input
            class="input input_icon-left"
            [class.input_fill]="fill"
            [value]="completeUrl"
            (click)="$event.target.select()"
            readonly
          />
        </div>
      </div>
    </div>
  </app-page-block>

  <app-page-block *ngIf="userDetailsWorkflowControl">
    <div class="field">
      <label class="field__label">
        <span class="field__label-text">Workflows</span>
        <span class="field__label-optional">(optional)</span>
      </label>

      <div class="field__value">
        <app-page-block [marginSize]="'s'" [paddingSize]="'none'">
          <app-workflow-button
            [workflow]="userDetailsWorkflow"
            [title]="'User details workflow'"
            [icon]="'user'"
            [border]="true"
            (editClick)="customizeUserDetailsWorkflow()"
          ></app-workflow-button>
        </app-page-block>
      </div>
    </div>
  </app-page-block>

  <app-page-block *ngIf="accessTokenForResource" [marginSize]="'s'">
    <div class="field">
      <label class="field__label">Access Token</label>
      <div class="field__value">
        <input
          *ngIf="accessTokenName"
          class="input input_fill"
          type="text"
          [value]="form.form.value['access_token'] || (accessTokenName ? '•••••••••••••••••••••••••••' : '')"
          disabled
        />
      </div>
      <div style="margin-top: 10px;">
        <button
          type="button"
          class="button button_primary"
          [disabled]="!form.isValid()"
          (click)="getResourceAccessToken(accessTokenForResource)"
          style="margin-left: 10px;"
        >
          <span class="button__label">
            <ng-container *ngIf="accessTokenName">Refresh Access Token</ng-container>
            <ng-container *ngIf="!accessTokenName">Get New Access Token</ng-container>
          </span>
        </button>
      </div>
    </div>
  </app-page-block>
</ng-container>

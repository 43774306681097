/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../field-wrapper/field-wrapper.component.ngfactory";
import * as i2 from "../field-wrapper/field-wrapper.component";
import * as i3 from "./auto-field-stub.component";
var styles_AutoFieldStubComponent = [];
var RenderType_AutoFieldStubComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AutoFieldStubComponent, data: {} });
export { RenderType_AutoFieldStubComponent as RenderType_AutoFieldStubComponent };
export function View_AutoFieldStubComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "app-field-wrapper", [], null, null, null, i1.View_FieldWrapperComponent_0, i1.RenderType_FieldWrapperComponent)), i0.ɵdid(1, 245760, null, 0, i2.FieldWrapperComponent, [i0.ChangeDetectorRef], { field: [0, "field"], readonly: [1, "readonly"], requiredAsterisk: [2, "requiredAsterisk"], label: [3, "label"], errors: [4, "errors"], truncate: [5, "truncate"], manualMargin: [6, "manualMargin"], labelButtons: [7, "labelButtons"] }, null), (_l()(), i0.ɵeld(2, 0, null, 0, 0, "input", [["class", "input input_stub"], ["type", "text"], ["value", "Value"]], [[2, "input_fill", null], [2, "loading-animation", null], [8, "disabled", 0]], null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.field; var currVal_1 = _co.readonly; var currVal_2 = _co.requiredAsterisk; var currVal_3 = _co.label; var currVal_4 = _co.errors; var currVal_5 = _co.truncate; var currVal_6 = _co.manualMargin; var currVal_7 = _co.labelButtons; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }, function (_ck, _v) { var currVal_8 = true; var currVal_9 = true; var currVal_10 = true; _ck(_v, 2, 0, currVal_8, currVal_9, currVal_10); }); }
export function View_AutoFieldStubComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-auto-field-stub", [], null, null, null, View_AutoFieldStubComponent_0, RenderType_AutoFieldStubComponent)), i0.ɵdid(1, 114688, null, 0, i3.AutoFieldStubComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AutoFieldStubComponentNgFactory = i0.ɵccf("app-auto-field-stub", i3.AutoFieldStubComponent, View_AutoFieldStubComponent_Host_0, { field: "field", readonly: "readonly", requiredAsterisk: "requiredAsterisk", label: "label", errors: "errors", autofocus: "autofocus", truncate: "truncate", manualMargin: "manualMargin", labelButtons: "labelButtons" }, {}, []);
export { AutoFieldStubComponentNgFactory as AutoFieldStubComponentNgFactory };

import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import isPlainObject from 'lodash/isPlainObject';
import truncate from 'lodash/truncate';
import { Observable, of } from 'rxjs';
import { map, shareReplay, startWith, switchMap } from 'rxjs/operators';

import { applyValueFormat, applyValueFormat$, CustomizeService } from '@modules/customize';
import { FieldType, registerFieldComponent, ValueFormat } from '@modules/fields';
import { isSet } from '@shared';

import { FieldComponent } from '../field/field.component';

enum CharFieldWidget {
  Default,
  Multiline
}

@Component({
  selector: 'app-char-field',
  templateUrl: 'char-field.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CharFieldComponent extends FieldComponent implements OnInit, OnChanges {
  @ViewChild('input') input: ElementRef;

  displayValue$: Observable<string>;
  widget: CharFieldWidget;
  widgets = CharFieldWidget;
  rows: number;
  multilineStyles: SafeStyle;

  constructor(public customizeService: CustomizeService, private sanitizer: DomSanitizer) {
    super();
  }

  ngOnInit(): void {
    this.updateWidget();
    this.updateMultiline();
    this.displayValue$ = this.getDisplayValue$();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.updateWidget();
    this.updateMultiline();

    if (changes['value'] || changes['form'] || changes['field']) {
      this.displayValue$ = this.getDisplayValue$();
    }
  }

  truncateJson(value: any): string {
    if (isPlainObject(value)) {
      try {
        return truncate(JSON.stringify(value), { length: 160 });
      } catch (e) {}
    } else {
      return value;
    }
  }

  getDisplayValue(): string {
    let value = this.currentValue;

    value = this.truncateJson(value);

    if (this.field.params['value_format']) {
      const valueFormat = new ValueFormat().deserialize(this.field.params['value_format']);
      return applyValueFormat(value, valueFormat, { context: this.context, contextElement: this.contextElement });
    } else {
      return value;
    }
  }

  getDisplayValue$(): Observable<string> {
    return this.currentValue$.pipe(
      map<string, string>(value => {
        return this.truncateJson(value);
      }),
      switchMap(value => {
        if (this.field.params['value_format']) {
          const valueFormat = new ValueFormat().deserialize(this.field.params['value_format']);
          return applyValueFormat$(value, valueFormat, { context: this.context, contextElement: this.contextElement });
        } else {
          return of(value);
        }
      }),
      startWith(this.getDisplayValue()),
      shareReplay(1)
    );
  }

  updateWidget() {
    let widget: CharFieldWidget;

    if (this.field.params['multiline']) {
      widget = CharFieldWidget.Multiline;
    } else {
      widget = CharFieldWidget.Default;
    }

    if (this.widget == widget) {
      return;
    }

    this.widget = widget;
  }

  updateMultiline() {
    this.rows = this.field.params['rows'] || 8;
    this.multilineStyles = this.getMultilineStyles();
  }

  getMultilineStyles(): SafeStyle {
    const clamp = this.field.params['multiline'] && isSet(this.field.params['rows']);

    if (!clamp) {
      return;
    }

    const lines = this.field.params['rows'];
    const styles = [['-webkit-box-orient', 'vertical']];

    if (lines) {
      styles.push(['-webkit-line-clamp', lines]);
    }

    const stylesStr = styles.map(item => item.join(':')).join(';');
    return this.sanitizer.bypassSecurityTrustStyle(stylesStr);
  }

  // TODO: Move to separate params option
  get inputSmall() {
    return this.field.params['classes'] && this.field.params['classes'].indexOf('input_small') != -1;
  }
}

registerFieldComponent(FieldType.Text, CharFieldComponent);

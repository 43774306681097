<app-customize-bar-header [title]="'Sharing'" [subtitle]="'App settings'" [backEnabled]="false">
</app-customize-bar-header>

<div class="sidebar__content">
  <div *ngIf="!domain" class="sidebar__element">
    <app-alert [title]="'Domain is not set up'" [secondary]="true">
      <div>To customize external pages you should connect custom domain to current App</div>
      <div style="margin-top: 4px;">
        <a href="javascript:void(0)" (click)="editDomain()">Connect domain</a>
      </div>
    </app-alert>
  </div>

  <app-sidebar-section
    [description]="'This information is used when your App URL is shared on social media platforms'"
    [interactive]="!domain"
    (click)="onDomainRequiredClick()"
  >
    <div class="sidebar__element">
      <app-sidebar-field [label]="'share title'" [additional]="'(optional)'">
        <app-auto-field
          [form]="projectDomainUpdateForm"
          [label]="false"
          [field]="
            createField({
              name: 'title',
              field: 'CharField',
              placeholder: 'Title that will be used for sharing and indexed by search engines',
              params: { classes: ['input_fill'] }
            })
          "
        ></app-auto-field>
      </app-sidebar-field>
    </div>

    <div class="sidebar__element">
      <app-sidebar-field [label]="'share description'" [additional]="'(optional)'">
        <app-auto-field
          [form]="projectDomainUpdateForm"
          [label]="false"
          [field]="
            createField({
              name: 'description',
              field: 'CharField',
              placeholder: 'Description that will be used for sharing and indexed by search engines',
              params: { classes: ['input_fill'], multiline: true }
            })
          "
        ></app-auto-field>
      </app-sidebar-field>
    </div>

    <div class="sidebar__element">
      <app-sidebar-field [label]="'share image'" [additional]="'(optional)'">
        <app-auto-field
          [form]="projectDomainUpdateForm"
          [label]="false"
          [field]="
            createField({
              name: 'image',
              field: 'ImageField',
              params: {
                path: 'image',
                storage_resource: projectDomainUpdateForm.getUploaderResource()?.uniqueName,
                storage_name: projectDomainUpdateForm.getUploaderResourceStorage()?.uniqueName,
                ignore_old_django_format: true,
                editor: false,
                fit: imageFits.Fit,
                background: true
              }
            })
          "
        ></app-auto-field>
      </app-sidebar-field>
    </div>
  </app-sidebar-section>
</div>

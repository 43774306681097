var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var FrameTranslate = /** @class */ (function () {
    function FrameTranslate(options) {
        this.translate = { x: 0, y: 0 };
        this.frame = options.frame;
        this.translate = __assign({ x: 0, y: 0 }, options.translate);
    }
    Object.defineProperty(FrameTranslate.prototype, "x", {
        get: function () {
            return this.frame.x + this.translate.x;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FrameTranslate.prototype, "y", {
        get: function () {
            return this.frame.y + this.translate.y;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FrameTranslate.prototype, "width", {
        get: function () {
            return this.frame.width;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FrameTranslate.prototype, "height", {
        get: function () {
            return this.frame.height;
        },
        enumerable: true,
        configurable: true
    });
    return FrameTranslate;
}());
export { FrameTranslate };

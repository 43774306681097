import { EventEmitter, OnInit } from '@angular/core';
var ContainerBuilderStubComponent = /** @class */ (function () {
    function ContainerBuilderStubComponent() {
        this.fill = false;
        this.templates = false;
        this.templatesClick = new EventEmitter();
        this.cls = true;
    }
    Object.defineProperty(ContainerBuilderStubComponent.prototype, "marginSizeS", {
        get: function () {
            return this.fill;
        },
        enumerable: true,
        configurable: true
    });
    ContainerBuilderStubComponent.prototype.ngOnInit = function () { };
    return ContainerBuilderStubComponent;
}());
export { ContainerBuilderStubComponent };

<div class="calendar-week">
  <div class="calendar-week-table__timeline"></div>
  <table class="calendar-week-table">
    <tr class="calendar-week-table__tr">
      <th class="calendar-week-table__th">
        <span class="calendar-week-table__arrow"></span>
      </th>
      <th
        *ngFor="let day of weekDays; let i = index"
        class="calendar-week-table__th"
        [class.calendar-week-table__th--weekend]="day.weekend"
        [class.calendar-week-table__th--has-items]="weekDayItems[day.date.format('d')]"
      >
        <ng-container *ngIf="weekDayItems[day.date.format('d')] as models">
          <p class="calendar-week-table__count-orders">+{{ models.length }}</p>
          <p class="calendar-week-table__count-orders-big">{{ models.length }} {{ title }}</p>
        </ng-container>
        <div
          (click)="setParams(dayParams(day.date))"
          class="calendar-week-table__date"
          [class.calendar-week-table__date--active]="day.today"
        >
          {{ day.date.format('dddd') }},
          <span
            class="calendar-week-table__date-number"
            [appTintColor]="'accentColor' | appThemeColorHexOption | async"
            [appTintColorStyle]="tintStyles.Primary"
          >
            {{ day.date.format('D') }}
          </span>
        </div>
      </th>
    </tr>
    <tr class="calendar-week-table__tr" *ngFor="let hour of hours; let h = index">
      <td class="calendar-week-table__td">
        <span *ngIf="h > 0" class="calendar-week-table__time">{{ hour.date.format('LT') }}</span>
      </td>
      <td
        *ngFor="let weekDay of hour.items"
        class="calendar-week-table__td"
        [class.calendar-week-table__td--weekend]="weekDay.weekend"
      >
        <ng-container *ngIf="items[weekDay.key] as items">
          <app-calendar-item
            *ngFor="let item of items; let i = index"
            [item]="item"
            [listState]="listState"
            [modelDescription]="modelDescription"
            [settings]="settings"
            [visibleColumns]="visibleColumns"
            [context]="context"
            [contextElement]="contextElement"
            [selected]="isItemSelected(item, i)"
            (select)="toggleSelectedItem(item, i, $event.element, true)"
            (modelUpdated)="onModelUpdated($event)"
          ></app-calendar-item>
        </ng-container>
      </td>
    </tr>
  </table>
</div>

<div *ngIf="loading" class="calendar-loader">
  <div class="calendar-loader__content">
    <app-loader [fill]="true"></app-loader>
  </div>
</div>

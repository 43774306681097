import pickBy from 'lodash/pickBy';
import { QueryType, StorageQuery } from '@modules/queries';
import { Storage } from '@modules/storages';
import { deployUrl, isSet } from '@shared';
import { ResourceDeploy } from './resource-deploy';
import { ResourceName } from './resource-name';
import { ResourceType } from './resource-type';
import { ResourceTypeItemCategory } from './resource-type-item-category';
import { resourceTypeItems } from './resource-type-items';
import { SecretToken } from './secret-token';
var Resource = /** @class */ (function () {
    function Resource() {
        this.secretTokens = [];
        this.secretTokensWithDeleted = [];
        this.storages = [];
        this.storagesWithDeleted = [];
        this.params = {};
        this.demo = false;
        this.featured = false;
        this.draft = false;
        this.deleted = false;
    }
    Resource.prototype.deserialize = function (data) {
        var _this = this;
        this.environment = data['environment'];
        this.uniqueName = data['unique_name'];
        this.name = data['name'];
        this.type = data['type'];
        this.typeItem = resourceTypeItems.find(function (item) { return item.name == data['type_item']; });
        this.deploy = data['deploy'];
        this.mediaUrlTemplate = data['media_url_template'];
        this.token = data['token'];
        if (data['secret_tokens']) {
            this.secretTokensWithDeleted = data['secret_tokens'].map(function (item) {
                var result = new SecretToken().deserialize(item);
                result.resource = _this.uniqueName;
                return result;
            });
            this.secretTokens = this.secretTokensWithDeleted.filter(function (item) { return !item.deleted; });
        }
        if (data['storages']) {
            this.storagesWithDeleted = data['storages'].map(function (item) { return new Storage().deserialize(item); });
            this.storages = this.storagesWithDeleted.filter(function (item) { return !item.deleted; });
        }
        if (typeof data['params'] == 'string') {
            try {
                this.params = JSON.parse(data['params']);
            }
            catch (e) { }
        }
        else {
            this.params = data['params'];
        }
        if (this.type == ResourceType.JetBridge &&
            ([ResourceDeploy.Docker, ResourceDeploy.Python].includes(this.deploy) ||
                (this.typeItem && this.typeItem.name == ResourceName.Django))) {
            var storage = new Storage();
            storage.uniqueName = 'default';
            storage.name = 'Storage';
            storage.uploadQuery = new StorageQuery();
            storage.uploadQuery.queryType = QueryType.Simple;
            storage.uploadQuery.simpleQuery = new storage.uploadQuery.simpleQueryClass();
            this.storages = [storage];
        }
        this.demo = !!this.params['demo'];
        this.featured = !!this.params['featured'];
        this.templateStubData = this.params['template_stub_data'];
        if (data['draft'] !== undefined) {
            this.draft = data['draft'];
        }
        if (data['deleted'] !== undefined) {
            this.deleted = data['deleted'];
        }
        return this;
    };
    Resource.prototype.serialize = function (fields) {
        this.params = this.params || {};
        this.params['featured'] = this.featured;
        // TODO: Refactor
        if (this.parsedParams) {
            this.parsedParams['params'] = this.params;
        }
        var data = {
            unique_name: this.uniqueName,
            name: this.name,
            type: this.type,
            type_item: this.typeItem ? this.typeItem.name : undefined,
            deploy: this.deploy,
            media_url_template: this.mediaUrlTemplate,
            params: this.parsedParams ? this.parsedParams.serialize() : this.params,
            token: this.token,
            draft: this.draft,
            deleted: this.deleted
        };
        if (fields) {
            data = pickBy(data, function (v, k) { return fields.includes(k); });
        }
        return data;
    };
    Resource.prototype.parseParams = function (cls, force) {
        if (force === void 0) { force = false; }
        if (this.parsedParams instanceof cls && !force) {
            return this.parsedParams;
        }
        this.parsedParams = new cls().deserialize(this.params);
        return this.parsedParams;
    };
    Object.defineProperty(Resource.prototype, "url", {
        get: function () {
            return this.params['url'];
        },
        enumerable: true,
        configurable: true
    });
    Resource.prototype.mediaUrl = function (path) {
        if (!isSet(this.mediaUrlTemplate)) {
            return path;
        }
        return this.mediaUrlTemplate.replace('{}', path);
    };
    Object.defineProperty(Resource.prototype, "link", {
        get: function () {
            return ['resources', this.uniqueName];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Resource.prototype, "linkCreateCollection", {
        get: function () {
            return ['resources', this.uniqueName, 'models', 'create'];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Resource.prototype, "linkCreateAction", {
        get: function () {
            return ['resources', this.uniqueName, 'actions', 'create'];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Resource.prototype, "icon", {
        get: function () {
            if (!this.typeItem || !this.typeItem.icon) {
                return;
            }
            return deployUrl("/assets/images/resources/icons/" + this.typeItem.icon + ".svg");
        },
        enumerable: true,
        configurable: true
    });
    Resource.prototype.isSimpleIntegration = function () {
        return (!this.typeItem.categories.includes(ResourceTypeItemCategory.Databases) &&
            this.typeItem.name != ResourceName.RestApi);
    };
    Resource.prototype.isStub = function () {
        return this.demo || !!this.templateStubData;
    };
    Resource.prototype.isSynced = function (modelDescription) {
        if (modelDescription) {
            return (!!this.params['sync'] &&
                (!this.params['sync_model_descriptions'] || this.params['sync_model_descriptions'].includes(modelDescription)));
        }
        else {
            return !!this.params['sync'];
        }
    };
    Resource.prototype.isSyncedFinished = function () {
        return !!this.params['sync_finished'];
    };
    Resource.prototype.isSyncRunning = function () {
        return this.isSynced() && !this.isSyncedFinished();
    };
    Resource.prototype.isUsingProjectDatabase = function () {
        return this.typeItem.name == ResourceName.JetDatabase || this.isSynced() || this.hasCollectionSync();
    };
    Resource.prototype.isSameDatabase = function (localModel, relatedResource, relatedModel) {
        if (!relatedResource || !relatedModel) {
            return false;
        }
        if (relatedResource.uniqueName == this.uniqueName && this.type == ResourceType.JetBridge) {
            return true;
        }
        else {
            return ((this.typeItem.name == ResourceName.JetDatabase || this.isSynced(localModel.model) || localModel.isSynced()) &&
                (relatedResource.typeItem.name == ResourceName.JetDatabase ||
                    relatedResource.isSynced(relatedModel.model) ||
                    relatedModel.isSynced()));
        }
    };
    Resource.prototype.isResourceSyncSupported = function () {
        return this.typeItem && this.typeItem.syncEnabled;
    };
    Resource.prototype.isCollectionSyncSupported = function (modelDescription) {
        return this.typeItem.name == ResourceName.RestApi && modelDescription.queryType != QueryType.SQL;
    };
    Resource.prototype.hasCollectionSync = function () {
        return !!this.params['sync_collection'];
    };
    Resource.prototype.resourceContents = function () {
        var contents = [];
        if (this.typeItem.modelsEnabled) {
            contents.push('collections');
        }
        if (this.typeItem.modelsEnabled) {
            contents.push('actions');
        }
        if (this.typeItem.modelsEnabled) {
            contents.push('storages');
        }
        return contents;
    };
    return Resource;
}());
export { Resource };

import { DragDropModule } from '@angular/cdk/drag-drop';
import { ObserversModule } from '@angular/cdk/observers';
import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatMenuModule } from '@angular/material';
import { NgGxSelectModule } from 'ng-gxselect';

import { EditableContentModule } from '@common/editable-content';
import { LocalizeModule } from '@common/localize';
import { SelectModule } from '@common/select';
import { TipsModule } from '@common/tips';
import { CustomizeBarModule } from '@modules/customize-bar';
import { FieldComponentsModule } from '@modules/field-components';
import { FieldsModule } from '@modules/fields';
import { ParametersComponentsModule } from '@modules/parameters-components';
import { ProjectsComponentsModule } from '@modules/projects-components';
import { SidebarModule } from '@modules/sidebar';
import { SharedModule } from '@shared';
import { UiModule } from '@ui';

import { AudioFieldDataParamsComponent } from './components/audio-field-params/audio-field-data-params/audio-field-data-params.component';
import { AudioFieldViewParamsComponent } from './components/audio-field-params/audio-field-view-params/audio-field-view-params.component';
import { CharFieldViewParamsComponent } from './components/char-field-params/char-field-view-params/char-field-view-params.component';
import { DateTimeFieldDataParamsComponent } from './components/date-time-field-params/date-time-field-data-params/date-time-field-data-params.component';
import { DateTimeViewFieldParamsComponent } from './components/date-time-field-params/date-time-field-view-params/date-time-field-view-params.component';
import { FileFieldDataParamsComponent } from './components/file-field-params/file-field-data-params/file-field-data-params.component';
import { FileFieldViewParamsComponent } from './components/file-field-params/file-field-view-params/file-field-view-params.component';
import { ForeignKeyFieldViewParamsComponent } from './components/foreign-key-field-params/foreign-key-field-view-params/foreign-key-field-view-params.component';
import { GeographyFieldDataParamsComponent } from './components/geography-field-params/geography-field-data-params/geography-field-data-params.component';
import { ImageFieldDataParamsComponent } from './components/image-field-params/image-field-data-params/image-field-data-params.component';
import { ImageFieldViewParamsComponent } from './components/image-field-params/image-field-view-params/image-field-view-params.component';
import { JsonFieldDataParamsComponent } from './components/json-field-params/json-field-data-params/json-field-data-params.component';
import { JsonFieldStructureNodeComponent } from './components/json-field-params/json-field-structure-node/json-field-structure-node.component';
import { JsonFieldStructureComponent } from './components/json-field-params/json-field-structure/json-field-structure.component';
import { JsonFieldViewParamsComponent } from './components/json-field-params/json-field-view-params/json-field-view-params.component';
import { MultipleSelectFieldDataParamsComponent } from './components/multiple-select-field-data-params/multiple-select-field-data-params.component';
import { NumberFieldViewParamsComponent } from './components/number-field-params/number-field-view-params/number-field-view-params.component';
import { RatingFieldDataParamsComponent } from './components/rating-field-params/rating-field-data-params/rating-field-data-params.component';
import { RatingFieldViewParamsComponent } from './components/rating-field-params/rating-field-view-params/rating-field-view-params.component';
import { RichTextFieldViewParamsComponent } from './components/rich-text-field-params/rich-text-field-view-params/rich-text-field-view-params.component';
import { SelectFieldViewParamsComponent } from './components/select-field-params/select-field-view-params/select-field-view-params.component';
import { SliderFieldDataParamsComponent } from './components/slider-field-params/slider-field-data-params/slider-field-data-params.component';
import { SliderFieldViewParamsComponent } from './components/slider-field-params/slider-field-view-params/slider-field-view-params.component';
import { TimeFieldDataParamsComponent } from './components/time-field-params/time-field-data-params/time-field-data-params.component';
import { TimeViewFieldParamsComponent } from './components/time-field-params/time-field-view-params/time-field-view-params.component';
import { ValueFormatEditComponent } from './components/value-format-edit/value-format-edit.component';
import { VideoFieldDataParamsComponent } from './components/video-field-params/video-field-data-params/video-field-data-params.component';
import { VideoFieldViewParamsComponent } from './components/video-field-params/video-field-view-params/video-field-view-params.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgGxSelectModule,
    SidebarModule,
    FieldsModule,
    SharedModule,
    ProjectsComponentsModule,
    ParametersComponentsModule,
    LocalizeModule,
    DragDropModule,
    TipsModule,
    SelectModule,
    FieldComponentsModule,
    MatMenuModule,
    EditableContentModule,
    OverlayModule,
    ObserversModule,
    UiModule,
    CustomizeBarModule
  ],
  declarations: [
    CharFieldViewParamsComponent,
    ForeignKeyFieldViewParamsComponent,
    SelectFieldViewParamsComponent,
    DateTimeViewFieldParamsComponent,
    DateTimeFieldDataParamsComponent,
    TimeViewFieldParamsComponent,
    JsonFieldViewParamsComponent,
    FileFieldViewParamsComponent,
    FileFieldDataParamsComponent,
    ImageFieldViewParamsComponent,
    ImageFieldDataParamsComponent,
    NumberFieldViewParamsComponent,
    JsonFieldDataParamsComponent,
    RichTextFieldViewParamsComponent,
    GeographyFieldDataParamsComponent,
    TimeFieldDataParamsComponent,
    JsonFieldStructureComponent,
    JsonFieldStructureNodeComponent,
    MultipleSelectFieldDataParamsComponent,
    AudioFieldViewParamsComponent,
    AudioFieldDataParamsComponent,
    VideoFieldViewParamsComponent,
    VideoFieldDataParamsComponent,
    ValueFormatEditComponent,
    RatingFieldDataParamsComponent,
    RatingFieldViewParamsComponent,
    SliderFieldDataParamsComponent,
    SliderFieldViewParamsComponent
  ],
  entryComponents: [
    CharFieldViewParamsComponent,
    ForeignKeyFieldViewParamsComponent,
    SelectFieldViewParamsComponent,
    DateTimeViewFieldParamsComponent,
    DateTimeFieldDataParamsComponent,
    TimeViewFieldParamsComponent,
    JsonFieldViewParamsComponent,
    FileFieldViewParamsComponent,
    FileFieldDataParamsComponent,
    ImageFieldViewParamsComponent,
    ImageFieldDataParamsComponent,
    NumberFieldViewParamsComponent,
    JsonFieldDataParamsComponent,
    RichTextFieldViewParamsComponent,
    GeographyFieldDataParamsComponent,
    TimeFieldDataParamsComponent,
    MultipleSelectFieldDataParamsComponent,
    AudioFieldViewParamsComponent,
    AudioFieldDataParamsComponent,
    VideoFieldViewParamsComponent,
    VideoFieldDataParamsComponent,
    RatingFieldDataParamsComponent,
    RatingFieldViewParamsComponent,
    SliderFieldDataParamsComponent,
    SliderFieldViewParamsComponent
  ]
})
export class FieldParamsComponentsModule {}

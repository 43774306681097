import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Task } from '../task';
import { TaskScenario } from '../task-scenario';

@Injectable({
  providedIn: 'root'
})
export class InviteUserTask extends Task {
  init(): Observable<TaskScenario> | TaskScenario {
    return new TaskScenario([
      {
        link: ['project', 'users'],
        items: [
          {
            name: 'click_invite_user'
          }
        ]
      },
      {
        link: ['project', 'users', 'create'],
        items: [
          {
            name: 'choose_user'
          },
          {
            name: 'choose_group'
          },
          {
            name: 'send_invite'
          },
          {
            name: 'finished'
          }
        ]
      }
    ]);
  }
}

import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit } from '@angular/core';
import range from 'lodash/range';

import { TypedChanges } from '@shared';

@Component({
  selector: 'app-table-stub-content-header, [app-table-stub-content-header]',
  templateUrl: './table-stub-content-header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableStubContentHeaderComponent implements OnInit, OnChanges {
  @Input() columns = 5;
  @Input() animating = false;
  @Input() scrollable = false;

  columnsRange: number[];

  constructor() {}

  ngOnInit() {}

  ngOnChanges(changes: TypedChanges<TableStubContentHeaderComponent>): void {
    if (changes.columns) {
      this.columnsRange = range(1, this.columns + 1);
    }
  }
}

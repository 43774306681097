import { FormArray } from '@angular/forms';
import isEqual from 'lodash/isEqual';
import range from 'lodash/range';

import { Fill } from '@modules/views';

import { FillControl } from './fill.control';

export class FillArray extends FormArray {
  controls: FillControl[];

  deserialize(value: Fill[]) {
    value.forEach((item, i) => {
      const control = this.controls[i] || this.appendControl();
      control.deserialize(item);
    });

    this.controls.slice(value.length).forEach(item => this.removeControl(item));
  }

  serialize(): Fill[] {
    return this.controls.map(control => control.serialize());
  }

  setControls(controls: FillControl[]) {
    this.removeControls();
    controls.forEach(item => this.push(item));
  }

  removeControls() {
    range(this.controls.length).forEach(() => this.removeAt(0));
  }

  removeControl(control: FillControl) {
    const newControls = this.controls.filter(item => !isEqual(item, control));
    this.setControls(newControls);
  }

  createControl(item?: Fill): FillControl {
    const control = new FillControl();

    if (!item) {
      item = new Fill();
      item.generateId();
    }

    control.deserialize(item);
    control.markAsPristine();

    return control;
  }

  appendControl(item?: Fill): FillControl {
    const control = this.createControl(item);
    this.push(control);
    return control;
  }

  prependControl(item?: Fill): FillControl {
    const control = this.createControl(item);
    this.insert(0, control);
    return control;
  }
}

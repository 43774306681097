<app-popup2 [size]="'s'">
  <div class="custom-page-popup custom-page-popup_style_background">
    <div class="custom-page-popup__header" [class.custom-page-popup__header_overlay]="false">
      <div class="custom-page-popup__header-main">
        <div class="custom-page-popup__title">Scan code</div>
      </div>
      <div class="custom-page-popup__header-right">
        <a href="javascript:void(0)" class="custom-page-popup__close icon-close" (click)="cancel()"></a>
      </div>
    </div>
    <div class="custom-page-popup__content">
      <app-page-block>
        <div [style.height.px]="400">
          <app-scanner [scanningInitial]="true" [scanConfirm]="true" (scan)="onScan($event)"></app-scanner>
        </div>
      </app-page-block>
    </div>
  </div>
</app-popup2>

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { getFieldDescriptionByType, parseFieldType } from './fields';
var FormField = /** @class */ (function () {
    function FormField() {
        this.editable = true;
        this.required = false;
        this.resetEnabled = false;
        this.params = {};
    }
    FormField.prototype.deserialize = function (data) {
        this.name = data['name'];
        this.label = data['label'];
        this.description = data['description'];
        this.placeholder = data['placeholder'];
        this.field = parseFieldType(data['field']);
        if (data['editable'] !== undefined) {
            this.editable = data['editable'] != false;
        }
        if (data['required'] !== undefined) {
            this.required = data['required'] != false;
        }
        if (data['reset_enabled'] !== undefined) {
            this.resetEnabled = data['reset_enabled'] != false;
        }
        if (data['validator_type']) {
            this.validatorType = data['validator_type'];
        }
        if (data['validator_params']) {
            this.validatorParams = data['validator_params'];
        }
        var item = getFieldDescriptionByType(this.field);
        this.params = __assign({}, item.defaultParams, data['params'], item.forceParams);
        return this;
    };
    FormField.prototype.serialize = function () {
        return {
            name: this.name,
            label: this.label,
            description: this.description,
            placeholder: this.placeholder,
            validator_type: this.validatorType,
            validator_params: this.validatorParams,
            field: this.field,
            params: this.params,
            required: this.required,
            reset_enabled: this.resetEnabled,
            editable: this.editable
        };
    };
    return FormField;
}());
export { FormField };

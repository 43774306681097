import { Injectable } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup } from '@angular/forms';

import {
  defaultGeographyObjectOutputFormatLatitudeField,
  defaultGeographyObjectOutputFormatLongitudeField,
  GeographyOutputFormat
} from '@modules/fields';

@Injectable()
export class GeographyFieldDataParamsForm {
  form: FormGroup;
  control: AbstractControl;

  outputFormatOptions = [
    { value: GeographyOutputFormat.Object, name: 'Object' },
    { value: GeographyOutputFormat.PostgreSQL, name: 'PostgreSQL' },
    { value: GeographyOutputFormat.Array, name: 'Array' }
  ];

  constructor(private fb: FormBuilder) {
    this.form = this.fb.group({
      output_format: new FormControl(GeographyOutputFormat.Object),
      object_lat_field: new FormControl(defaultGeographyObjectOutputFormatLatitudeField),
      object_lng_field: new FormControl(defaultGeographyObjectOutputFormatLongitudeField)
    });
  }

  init(control: AbstractControl) {
    this.control = control;

    if (control.value) {
      this.form.patchValue(control.value, { emitEvent: false });
    }

    this.form.valueChanges.subscribe(() => this.submit());
  }

  submit() {
    const value = this.form.value;
    const result = {
      output_format: value['output_format']
    };

    if (value['output_format'] == GeographyOutputFormat.Object) {
      result['object_lat_field'] = value['object_lat_field'];
      result['object_lng_field'] = value['object_lng_field'];
    } else {
      result['object_lat_field'] = undefined;
      result['object_lng_field'] = undefined;
    }

    this.control.patchValue({
      ...this.control.value,
      ...result
    });
  }
}

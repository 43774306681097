var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit } from '@angular/core';
import { FieldType, formatDateTimeField, getDateTimeFieldMomentFormat, isDateTimeFieldClock12Format, registerFieldComponent } from '@modules/fields';
import { FieldComponent } from '../field/field.component';
var DateTimeFieldComponent = /** @class */ (function (_super) {
    __extends(DateTimeFieldComponent, _super);
    function DateTimeFieldComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.time = false;
        _this.editFormatClock12 = false;
        return _this;
    }
    DateTimeFieldComponent.prototype.ngOnInit = function () {
        this.time = !!this.field.params['time'];
        this.editFormat = getDateTimeFieldMomentFormat(this.field, true);
        this.editFormatClock12 = isDateTimeFieldClock12Format(this.field, true);
    };
    Object.defineProperty(DateTimeFieldComponent.prototype, "inputSmall", {
        // TODO: Move to separate params option
        get: function () {
            return this.field.params['classes'] && this.field.params['classes'].indexOf('input_small') != -1;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DateTimeFieldComponent.prototype, "valueStr", {
        get: function () {
            if (!this.currentValue) {
                return this.formatEmpty(this.currentValue);
            }
            return formatDateTimeField(this.currentValue, this.field);
        },
        enumerable: true,
        configurable: true
    });
    return DateTimeFieldComponent;
}(FieldComponent));
export { DateTimeFieldComponent };
registerFieldComponent(FieldType.DateTime, DateTimeFieldComponent);

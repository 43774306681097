import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Provider,
  ViewChild
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, Observable } from 'rxjs';
import { debounceTime, filter, first, map, skip } from 'rxjs/operators';

import { DialogService } from '@common/dialogs';
import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import {
  cleanElementName,
  ITEM_OUTPUT,
  ModelElementItem,
  RawListViewSettingsColumn,
  ViewContext,
  ViewContextElement
} from '@modules/customize';
import { FieldsEditItem } from '@modules/field-components';
import { createFormFieldFactory } from '@modules/fields';
import { ModelDescription } from '@modules/models';
import { Resource } from '@modules/projects';
import { SidebarCollapseContext } from '@modules/sidebar';
import { controlValid } from '@shared';

import { CustomizeBarEditComponent } from '../../data/customize-bar-edit-component';
import { CustomizeBarEditEvent } from '../../data/customize-bar-edit-event';
import { CustomizeBarEditEventType } from '../../data/customize-bar-edit-event-type';
import { CustomizeBarContext } from '../../services/customize-bar-context/customize-bar.context';
import { CUSTOMIZE_MODEL_COMPONENT } from '../../services/customize-bar/customize-bar.service';
import { ElementConfiguration, trackConfigured } from '../../utils/analytics';
import { DisplayFieldsEditComponent } from '../display-fields-edit/display-fields-edit.component';
import { ModelDescriptionDataSourceControl } from '../model-description-data-source-edit/model-description-data-source';
import { CustomizeBarModelEditDataComponent } from './customize-bar-model-edit-data/customize-bar-model-edit-data.component';
import { CustomizeBarModelEditForm } from './customize-bar-model-edit.form';

export const CUSTOMIZE_MODEL_COMPONENT_PROVIDER: Provider = {
  provide: CUSTOMIZE_MODEL_COMPONENT,
  useFactory: customizeModelComponentFactory
};

export function customizeModelComponentFactory(): any {
  return CustomizeBarModelEditComponent;
}

@Component({
  selector: 'app-customize-bar-model-edit',
  templateUrl: './customize-bar-model-edit.component.html',
  providers: [ModelDescriptionDataSourceControl, CustomizeBarModelEditForm],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomizeBarModelEditComponent implements OnInit, OnDestroy, CustomizeBarEditComponent {
  @Input() element: ModelElementItem;
  @Input() context: ViewContext;
  @Input() contextElement: ViewContextElement;
  @Input() contextElementPath: (string | number)[];
  @Input() contextElementPaths: (string | number)[][];
  @Input() titleEnabled = true;
  @Input() titleEditable: boolean;
  @Input() deleteEnabled = false;
  @Input() parentElement: any;
  @Input() parentPopup: any;
  @Input() firstInit = false;
  @Input() setupOnCreate = false;
  @Output() event = new EventEmitter<CustomizeBarEditEvent>();

  @ViewChild('fieldsComponent', { read: DisplayFieldsEditComponent })
  columnsEditComponent: DisplayFieldsEditComponent;

  createField = createFormFieldFactory();
  loading = false;
  collapseContext = new SidebarCollapseContext();
  result: ModelElementItem;
  configurationStarted = false;
  columnsSearch = false;

  resource: Resource;
  modelDescription: ModelDescription;
  queryConfigured$: Observable<boolean>;
  dataConfigured$: Observable<boolean>;
  columnsContextElementPaths = [[ITEM_OUTPUT]];
  visibleContextElementPaths = [[ITEM_OUTPUT]];
  itemContextElementPath = [ITEM_OUTPUT];

  titleCleanValue = (() => {
    return (value: string): string => {
      return cleanElementName(value, this.element, this.context.getElementItems());
    };
  })();

  constructor(
    public form: CustomizeBarModelEditForm,
    private customizeBarContext: CustomizeBarContext,
    private dialogService: DialogService,
    private cd: ChangeDetectorRef,
    private analyticsService: UniversalAnalyticsService
  ) {}

  ngOnInit() {
    this.form.init(this.element, this.firstInit);

    const resultObs = this.form.valueChanges.pipe(
      debounceTime(200),
      map(() => this.form.submit())
    );

    resultObs.pipe(untilDestroyed(this)).subscribe(result => {
      this.result = result;
      this.emitUpdate();
    });

    resultObs
      .pipe(
        map(result => this.form.isConfigured(result)),
        trackConfigured(),
        first(configuration => configuration == ElementConfiguration.Started),
        untilDestroyed(this)
      )
      .subscribe(() => {
        this.configurationStarted = true;
        this.analyticsService.sendSimpleEvent(AnalyticsEvent.Component.StartedConfiguration, {
          ComponentTypeID: this.element.analyticsName,
          ResourceType: this.resource ? this.resource.typeItem.name : undefined,
          ResourceDemo: this.resource ? this.resource.demo : false
        });
      });

    if (this.setupOnCreate) {
      this.openGetQuery();
    }

    this.form.controls.data_source
      .getResource$()
      .pipe(untilDestroyed(this))
      .subscribe(resource => {
        this.resource = resource;
      });

    this.form.controls.data_source
      .getModelDescription$()
      .pipe(untilDestroyed(this))
      .subscribe(modelDescription => {
        this.modelDescription = modelDescription;
      });

    this.form.controls.data_source
      .getResource$()
      .pipe(skip(1), untilDestroyed(this))
      .subscribe(resource => {
        if (resource) {
          this.analyticsService.sendSimpleEvent(AnalyticsEvent.ComponentData.ResourceSelected, {
            ComponentTypeID: this.element.analyticsName,
            ResourceID: resource ? resource.typeItem.name : undefined,
            ResourceDemo: resource ? resource.demo : false
          });
        }
      });

    combineLatest(
      this.form.controls.data_source.getResource$().pipe(skip(1)),
      this.form.controls.data_source.getModelDescription$().pipe(skip(1))
    )
      .pipe(untilDestroyed(this))
      .subscribe(([resource, modelDescription]) => {
        if (modelDescription) {
          this.analyticsService.sendSimpleEvent(AnalyticsEvent.ComponentData.ResourceItemSelected, {
            ComponentTypeID: this.element.analyticsName,
            ResourceID: resource ? resource.typeItem.name : undefined,
            ResourceDemo: resource ? resource.demo : false
          });
        }
      });

    this.queryConfigured$ = this.form.controls.data_source.getQueryConfigured$();
    this.dataConfigured$ = controlValid(this.form.controls.data_source);

    controlValid(this.form.controls.data_source)
      .pipe(
        filter((configured, i) => configured && i > 0),
        untilDestroyed(this)
      )
      .subscribe(() => {
        this.analyticsService.sendSimpleEvent(AnalyticsEvent.ComponentData.SuccessfullySetUp, {
          ComponentTypeID: this.element.analyticsName
        });
      });

    controlValid(this.form.controls.data_source.controls.query_resource)
      .pipe(
        filter((configured, i) => configured && i > 0),
        untilDestroyed(this)
      )
      .subscribe(() => {
        this.analyticsService.sendSimpleEvent(AnalyticsEvent.ComponentData.ResourceItemSuccessfullySetUp, {
          ComponentTypeID: this.element.analyticsName
        });
      });

    controlValid(this.form.controls.data_source.controls.query_inputs)
      .pipe(
        filter((configured, i) => configured && i > 0),
        untilDestroyed(this)
      )
      .subscribe(() => {
        this.analyticsService.sendSimpleEvent(AnalyticsEvent.ComponentData.InputsSuccessfullySetUp, {
          ComponentTypeID: this.element.analyticsName
        });
      });

    if (this.setupOnCreate) {
      this.openGetQuery();
    }
  }

  ngOnDestroy(): void {}

  serializeColumn(value: FieldsEditItem): RawListViewSettingsColumn {
    return value;
  }

  deserializeColumn(value: RawListViewSettingsColumn): FieldsEditItem {
    return value;
  }

  resetColumns() {
    this.dialogService
      .warning({
        title: `Are you sure want to reset component columns?`,
        description: `
          The component columns will be reset to their default values.
        `,
        style: 'orange'
      })
      .pipe(
        filter(result => !!result),
        untilDestroyed(this)
      )
      .subscribe(() => {
        this.form.controls.data_source.resetInputColumns({
          context: this.context,
          contextElement: this.contextElement,
          markAsDirty: true
        });
      });
  }

  openGetQuery(options: { setupOnCreate?: false; addInput?: boolean } = {}) {
    this.customizeBarContext.appendSettingsComponent({
      component: CustomizeBarModelEditDataComponent,
      inputs: {
        element: this.element,
        name: this.form.controls.name.value,
        form: this.form,
        context: this.context,
        contextElement: this.contextElement,
        contextElementPath: this.contextElementPath,
        contextElementPaths: this.contextElementPaths,
        setupOnCreate: options.setupOnCreate,
        addInput: options.addInput
      },
      outputs: {
        event: [
          () => {
            this.customizeBarContext.popSettingsComponent();
          }
        ]
      }
    });
  }

  close() {
    if (this.configurationStarted && this.form.isConfigured(this.result)) {
      this.analyticsService.sendSimpleEvent(AnalyticsEvent.Component.SuccessfullyConfigured, {
        ComponentTypeID: this.element.analyticsName,
        ResourceDemo: this.resource ? this.resource.demo : false
      });
    }

    this.customizeBarContext.popSettingsComponent();
  }

  submit() {
    this.result = this.form.submit();
    this.emitUpdate(true);
    this.close();
  }

  onTitleChanged(name: string) {
    this.form.controls.name.setValue(name);
  }

  emitUpdate(submit = false) {
    // TODO: Add args interface
    const args = { result: this.result, submit: submit };

    if (this.element) {
      this.event.emit({ type: CustomizeBarEditEventType.Updated, args: args });
    } else {
      this.event.emit({ type: CustomizeBarEditEventType.Created, args: args });
    }
  }

  cancel() {
    this.event.emit({ type: CustomizeBarEditEventType.Canceled });
    this.close();
  }

  delete() {
    this.event.emit({ type: CustomizeBarEditEventType.Deleted });
    this.close();
  }

  setColumnsSearch(value: boolean) {
    this.columnsSearch = value;
    this.cd.markForCheck();
  }

  toggleSearch() {
    if (this.columnsSearch) {
      this.setColumnsSearch(false);
    } else {
      this.setColumnsSearch(true);
      this.columnsEditComponent.focusSearch();
    }
  }
}

<form [formGroup]="form.form" (ngSubmit)="submit()">
  <div class="add-filter">
    <app-auto-field
      [form]="form.form"
      [field]="createField({ name: 'label', field: 'CharField' })"
      [label]="false"
      [errors]="false"
      [autofocus]="true"
    >
    </app-auto-field>

    <button type="submit" class="button button_primary" [disabled]="form.form.invalid || form.form.disabled">
      <span class="button__label">Add Dashboard</span>
    </button>
  </div>
</form>

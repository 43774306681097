import { AfterViewInit, ChangeDetectorRef, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { getImageSize, getVideoSize } from '@shared';
var LightboxComponent = /** @class */ (function () {
    function LightboxComponent(cd) {
        this.cd = cd;
        this.video = false;
        this.zoom = true;
        this.widthImg = 0;
        this.heightImg = 0;
        this.canZoom = false;
        this.opacityZoom = 0;
        this.percent = 1;
        this.offsetX = 0;
        this.offsetY = 0;
    }
    LightboxComponent.prototype.ngOnInit = function () { };
    LightboxComponent.prototype.ngOnDestroy = function () { };
    LightboxComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        this.getMediaSize()
            .pipe(untilDestroyed(this))
            .subscribe(function (size) {
            _this.widthImg = size.width;
            _this.heightImg = size.height;
            _this.canZoom =
                _this.preview.nativeElement.offsetHeight < _this.heightImg ||
                    _this.preview.nativeElement.offsetWidth < _this.widthImg;
            _this.cd.detectChanges();
            if (_this.canZoom) {
                _this.percent = (_this.widthImg - _this.widthPreview) / _this.widthPreview;
            }
        });
    };
    LightboxComponent.prototype.getMediaSize = function () {
        return this.video ? getVideoSize(this.url) : getImageSize(this.url);
    };
    Object.defineProperty(LightboxComponent.prototype, "maxHeight", {
        get: function () {
            return document.body.offsetHeight - 100;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LightboxComponent.prototype, "heightPreview", {
        get: function () {
            return this.preview.nativeElement.offsetHeight;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LightboxComponent.prototype, "widthPreview", {
        get: function () {
            return this.preview.nativeElement.offsetWidth;
        },
        enumerable: true,
        configurable: true
    });
    LightboxComponent.prototype.mousemove = function (event) {
        var shiftX = this.percent * (event.offsetX - this.widthPreview / 2);
        var shiftY = this.percent * (event.offsetY - this.heightPreview / 2);
        this.offsetX = -((this.widthImg - this.widthPreview) / 2 + shiftX);
        this.offsetY = -((this.heightImg - this.heightPreview) / 2 + shiftY);
        this.cd.markForCheck();
    };
    LightboxComponent.prototype.showZoom = function () {
        if (!this.zoom) {
            return;
        }
        this.opacityZoom = 1;
        this.cd.markForCheck();
    };
    LightboxComponent.prototype.hideZoom = function () {
        this.opacityZoom = 0;
        this.cd.markForCheck();
    };
    return LightboxComponent;
}());
export { LightboxComponent };

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ComponentFactoryResolver, Injector } from '@angular/core';
import { Subject } from 'rxjs';
import { ThinDialogPopupComponent } from '@common/dialog-popup';
import { PopupService } from '@common/popups';
import { isSet } from '@shared';
import { ChooseSyncModeComponent } from '../../components/choose-sync-mode/choose-sync-mode.component';
import { ConfirmSyncModeComponent } from '../../components/confirm-sync-mode/confirm-sync-mode.component';
var ChooseSyncController = /** @class */ (function () {
    function ChooseSyncController(injector, popupService, resolver) {
        this.injector = injector;
        this.popupService = popupService;
        this.resolver = resolver;
    }
    ChooseSyncController.prototype.chooseSyncMode = function (control, options) {
        var result = new Subject();
        this.popupService.push({
            component: ChooseSyncModeComponent,
            popupComponent: ThinDialogPopupComponent,
            inputs: {
                control: control,
                typeItem: options.typeItem
            },
            outputs: {
                chosen: [
                    function () {
                        result.next();
                    }
                ]
            },
            resolver: this.resolver,
            injector: this.injector
        });
        return result.asObservable();
    };
    ChooseSyncController.prototype.showConfirmSyncMode = function (object, sync, options) {
        return this.popupService.showComponent({
            component: ConfirmSyncModeComponent,
            injector: this.injector,
            inputs: __assign({ object: object, sync: sync, resource: options.resource, typeItem: options.typeItem }, (isSet(options.closeOnConfirm) && { closeOnConfirm: options.closeOnConfirm }))
        });
    };
    ChooseSyncController.prototype.confirmSyncMode = function (object, sync, options) {
        return this.showConfirmSyncMode(object, sync, __assign({}, options, { closeOnConfirm: true })).instance.confirm;
    };
    return ChooseSyncController;
}());
export { ChooseSyncController };

import { Injectable } from '@angular/core';

export class UniqueIdToken {}

@Injectable({
  providedIn: 'root'
})
export class UniqueIdService {
  private idCurrent = 0;
  private ids: { instance: any; id: number }[] = [];
  // private ids = new Map();

  constructor() {}

  getIdForInstance(instance: any): number {
    const item = this.ids.find(i => i.instance === instance);

    if (item) {
      return item.id;
    }

    this.ids.push({ instance: instance, id: this.idCurrent });

    return this.idCurrent++;
  }

  // getIdForInstance(instance: any): number {
  //   if (this.ids.get(instance) == undefined) {
  //     this.ids.set(instance, this.idCurrent);
  //     this.idCurrent++;
  //   }
  //
  //   return this.ids.get(instance);
  // }
}

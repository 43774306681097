<mat-menu #item_array_type_dropdown="matMenu">
  <ng-template matMenuContent>
    <button
      mat-menu-item
      class="mat-menu-item-odd"
      [class.mat-menu-item-active]="node.type == types.Array && arrayParams?.item?.type == types.Field"
      [class.mat-menu-item-orange]="node.type == types.Array && arrayParams?.item?.type == types.Field"
      [disableRipple]="true"
      (click)="changeItem(types.Array, { arrayType: types.Field })"
    >
      <span
        class="choose-items-item__dropdown-icon icon-text"
        [class.choose-items-item__dropdown-icon_orange]="
          node.type == types.Array && arrayParams?.item?.type == types.Field
        "
      ></span>
      Array of Fields
    </button>

    <button
      mat-menu-item
      class="mat-menu-item-odd"
      [class.mat-menu-item-active]="node.type == types.Array && arrayParams?.item?.type == types.Object"
      [class.mat-menu-item-orange]="node.type == types.Array && arrayParams?.item?.type == types.Object"
      [disableRipple]="true"
      (click)="changeItem(types.Array, { arrayType: types.Object })"
    >
      <span
        class="choose-items-item__dropdown-icon"
        [class.choose-items-item__dropdown-icon_orange]="
          node.type == types.Array && arrayParams?.item?.type == types.Object
        "
      >
        &#123; &#125;
      </span>
      Array of Objects
    </button>
  </ng-template>
</mat-menu>

<mat-menu #edit_item_type_dropdown="matMenu">
  <ng-template matMenuContent>
    <button
      mat-menu-item
      class="mat-menu-item-odd"
      [class.mat-menu-item-active]="node.type == types.Field"
      [class.mat-menu-item-orange]="node.type == types.Field"
      [disableRipple]="true"
      (click)="changeItem(types.Field)"
    >
      <span
        class="choose-items-item__dropdown-icon icon-text"
        [class.choose-items-item__dropdown-icon_orange]="node.type == types.Field"
      ></span>
      Field
    </button>

    <button
      mat-menu-item
      class="mat-menu-item-odd mat-menu-item-orange"
      [class.mat-menu-item-active]="node.type == types.Object"
      [class.mat-menu-item-orange]="node.type == types.Object"
      [disableRipple]="true"
      (click)="changeItem(types.Object)"
    >
      <span
        class="choose-items-item__dropdown-icon"
        [class.choose-items-item__dropdown-icon_orange]="node.type == types.Object"
      >
        &#123; &#125;
      </span>
      Object
    </button>

    <button
      mat-menu-item
      class="mat-menu-item-odd mat-menu-item-orange"
      [class.mat-menu-item-active]="node.type == types.Array"
      [class.mat-menu-item-orange]="node.type == types.Array"
      [disableRipple]="true"
      [matMenuTriggerFor]="item_array_type_dropdown"
    >
      <span
        class="choose-items-item__dropdown-icon"
        [class.choose-items-item__dropdown-icon_orange]="node.type == types.Array"
      >
        [ ]
      </span>
      Array
    </button>
  </ng-template>
</mat-menu>

<mat-menu #edit_item_dropdown="matMenu">
  <button
    *ngIf="parentNode && parentNode.type != types.Array"
    mat-menu-item
    class="mat-menu-item-odd"
    [disableRipple]="true"
    (click)="startRename()"
  >
    <span class="choose-items-item__dropdown-icon icon-edit"></span>
    Rename field
  </button>

  <button
    *ngIf="node?.type == types.Field"
    mat-menu-item
    class="mat-menu-item-odd"
    [disableRipple]="true"
    (click)="setEditNodeOpened(true)"
  >
    <span class="choose-items-item__dropdown-icon icon-configure"></span>
    Edit field
  </button>

  <button mat-menu-item class="mat-menu-item-odd" [disableRipple]="true" [matMenuTriggerFor]="edit_item_type_dropdown">
    <span class="choose-items-item__dropdown-icon icon-components"></span>
    Change type
  </button>

  <button
    *ngIf="parentNode"
    mat-menu-item
    class="mat-menu-item-odd mat-menu-item-red"
    [disableRipple]="true"
    (click)="deleted.emit()"
  >
    <span class="choose-items-item__dropdown-icon choose-items-item__dropdown-icon_red icon-bin"></span>
    Delete field
  </button>
</mat-menu>

<mat-menu #add_array_item="matMenu">
  <ng-template matMenuContent let-self="self">
    <button
      mat-menu-item
      class="mat-menu-item-odd"
      [disableRipple]="true"
      (click)="addItem(types.Array, { arrayType: types.Field, self: self })"
    >
      <span class="choose-items-item__dropdown-icon icon-text"></span> Array of Fields
    </button>

    <button
      mat-menu-item
      class="mat-menu-item-odd"
      [disableRipple]="true"
      (click)="addItem(types.Array, { arrayType: types.Object, self: self })"
    >
      <span class="choose-items-item__dropdown-icon">&#123; &#125;</span> Array of Objects
    </button>
  </ng-template>
</mat-menu>

<mat-menu [class]="'mat-menu-panel_no-top-padding'" #add_item="matMenu">
  <ng-template matMenuContent let-self="self">
    <div class="mat-menu-item-subtitle">
      Choose type
    </div>

    <button
      mat-menu-item
      class="mat-menu-item-odd"
      [disableRipple]="true"
      (click)="addItem(types.Field, { self: self })"
    >
      <span class="choose-items-item__dropdown-icon icon-text"></span> Field
    </button>

    <button
      mat-menu-item
      class="mat-menu-item-odd"
      [disableRipple]="true"
      (click)="addItem(types.Object, { self: self })"
    >
      <span class="choose-items-item__dropdown-icon">&#123; &#125;</span> Object
    </button>

    <button
      mat-menu-item
      class="mat-menu-item-odd"
      [disableRipple]="true"
      [matMenuTriggerFor]="add_array_item"
      [matMenuTriggerData]="{ self: self }"
    >
      <span class="choose-items-item__dropdown-icon">[ ]</span> Array
    </button>
  </ng-template>
</mat-menu>

<ng-template #node_title_type_template>
  <span *ngIf="node?.type == types.Array" class="sidebar-list-item__title-description">ARRAY</span>
  <span *ngIf="node?.type == types.Object" class="sidebar-list-item__title-description">OBJECT</span>
</ng-template>

<ng-template #node_title_template let-name="name" let-label="label">
  <div *ngIf="!parentNode" class="sidebar-list-item__title">
    ROOT&nbsp;<ng-container *ngTemplateOutlet="node_title_type_template"></ng-container>
  </div>

  <ng-container *ngIf="parentNode">
    <ng-container *ngIf="(label | appIsSet) && label != name; else no_label_block">
      <div class="sidebar-list-item__title">
        {{ label || 'No name' }}&nbsp;<ng-container *ngTemplateOutlet="node_title_type_template"></ng-container>
      </div>
      <div class="sidebar-list-item__description">{{ name || 'No name' }}</div>
    </ng-container>

    <ng-template #no_label_block>
      <div class="sidebar-list-item__title">
        {{ name || 'No name' }}&nbsp;<ng-container *ngTemplateOutlet="node_title_type_template"></ng-container>
      </div>
    </ng-template>
  </ng-container>
</ng-template>

<ng-template #rename_template let-origin="origin">
  <ng-template
    cdkConnectedOverlay
    [cdkConnectedOverlayPanelClass]="['overlay', 'overlay_horizontal_center']"
    [cdkConnectedOverlayPositions]="dropdownPositions"
    [cdkConnectedOverlayOrigin]="origin"
    [cdkConnectedOverlayOpen]="renameOpened"
    [cdkConnectedOverlayPush]="true"
    [cdkConnectedOverlayGrowAfterOpen]="true"
    [cdkConnectedOverlayHasBackdrop]="true"
    [cdkConnectedOverlayBackdropClass]="'popover2-backdrop'"
    (backdropClick)="finishRename()"
    (detach)="finishRename()"
    #overlay="cdkConnectedOverlay"
  >
    <div (cdkObserveContent)="onPopoverContentChanged(overlay)">
      <div class="popover2 overlay-content overlay-content_animated">
        <div class="popover2__inner popover2__inner_no-margin-collapse">
          <div class="sidebar__element">
            <app-sidebar-field [label]="'name'">
              <input
                type="text"
                class="input input_fill"
                [(ngModel)]="renameName"
                (keyup)="renameName$.next(renameName)"
                [appAutofocus]="node.type != types.Field"
              />

              <ng-container description>
                Name under which field is stored inside structure
              </ng-container>
            </app-sidebar-field>
          </div>

          <div *ngIf="node.type == types.Field" class="sidebar__element">
            <app-sidebar-field [label]="'label'" [additional]="'(optional)'">
              <input type="text" class="input input_fill" [(ngModel)]="renameLabel" [appAutofocus]="true" />

              <ng-container description>
                Label is used only for displaying field name
              </ng-container>
            </app-sidebar-field>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</ng-template>

<ng-template #edit_template let-origin="origin">
  <ng-template
    cdkConnectedOverlay
    [cdkConnectedOverlayPanelClass]="['overlay']"
    [cdkConnectedOverlayPositions]="dropdownPositions"
    [cdkConnectedOverlayOrigin]="origin"
    [cdkConnectedOverlayOpen]="editOpened"
    [cdkConnectedOverlayPush]="true"
    [cdkConnectedOverlayGrowAfterOpen]="true"
    [cdkConnectedOverlayHasBackdrop]="true"
    [cdkConnectedOverlayBackdropClass]="'popover2-backdrop'"
    (backdropClick)="setEditNodeOpened(false)"
    (detach)="setEditNodeOpened(false)"
    #overlay="cdkConnectedOverlay"
  >
    <div (cdkObserveContent)="onPopoverContentChanged(overlay)">
      <div class="popover2 overlay-content overlay-content_animated">
        <div class="popover2__inner popover2__inner_sidebar popover2__inner_no-margin-collapse">
          <div class="popover2__viewport popover2__viewport_scrollable">
            <app-fields-edit-item
              [control]="fieldControl"
              [configurable]="{
                field: true,
                required: true,
                params: true
              }"
              (saveRequested)="setEditNodeOpened(false)"
            ></app-fields-edit-item>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</ng-template>

<div *ngIf="node?.type == types.Object">
  <ng-container *ngIf="!skipSelf">
    <ng-container *ngTemplateOutlet="rename_template; context: { origin: root_trigger }"></ng-container>

    <div class="structure-item" #root_trigger="cdkOverlayOrigin" cdkOverlayOrigin>
      <div class="structure-item__content">
        <div
          class="sidebar-list-item sidebar-list-item_compact"
          [class.sidebar-list-item_clickable]="parentNode"
          [class.sidebar-list-item_draggable]="dragItem"
          (click)="startRename()"
        >
          <div class="sidebar-list-item__left">
            <span class="button button_icon button_orange button_tiny" style="margin: 0;">
              <span class="icon button__icon" [ngClass]="['icon-' + fieldIcon(fieldParams.field)]"></span>
            </span>
          </div>
          <div class="sidebar-list-item__main">
            <ng-container
              *ngTemplateOutlet="node_title_template; context: { name: node.name, label: node.label }"
            ></ng-container>
          </div>
          <div class="sidebar-list-item__right sidebar-list-item__actions">
            <div
              class="sidebar-list-item__action sidebar-list-item__action_button icon-more"
              [class.sidebar-list-item__action_hover-only]="!item_dropdown_trigger.menuOpen"
              [class.sidebar-list-item__action_active]="item_dropdown_trigger.menuOpen"
              [matMenuTriggerFor]="edit_item_dropdown"
              #item_dropdown_trigger="matMenuTrigger"
              (click)="$event.stopPropagation(); $event.preventDefault()"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="!skipContent">
    <div cdkDropList [cdkDropListLockAxis]="'y'" (cdkDropListDropped)="objectItemsDragDrop($event)">
      <div
        *ngFor="let item of objectParams.items; let i = index; let last = last; trackBy: trackNode"
        cdkDrag
        class="structure-item"
      >
        <div class="structure-item__dash">
          <span class="structure-item__dash-vertical"></span>
          <span class="structure-item__dash-horizontal"></span>
          <a
            *ngIf="item.type == types.Object || item.type == types.Array"
            href="javascript:void(0)"
            class="structure-item__dash-toggle icon-plus"
            [class.structure-item__dash-toggle_active]="childCollapsed[i]"
            [class.icon-plus]="childCollapsed[i]"
            [class.icon-minus]="!childCollapsed[i]"
            (click)="toggleCollapse(i)"
          >
          </a>
        </div>
        <div class="structure-item__content">
          <app-json-field-structure-node
            [node]="item"
            [parentNode]="node"
            [skipContent]="childCollapsed[i]"
            [created]="createdNode === item"
            [dragItem]="true"
            [deep]="deep + 1"
            (changed)="onObjectChildChanged(i, $event)"
            (deleted)="onObjectChildDeleted(i)"
          ></app-json-field-structure-node>
        </div>
      </div>
    </div>

    <a href="javascript:void(0)" class="structure-add-item" [matMenuTriggerFor]="add_item">
      <span class="structure-add-item__left">
        <span class="icon-plus structure-add-item__icon"></span>
      </span>
      <span class="structure-add-item__main">Add Field</span>
    </a>
  </ng-container>
</div>

<div *ngIf="node?.type == types.Array">
  <ng-container *ngIf="!skipSelf">
    <ng-container *ngTemplateOutlet="rename_template; context: { origin: root_trigger }"></ng-container>

    <div class="structure-item" #root_trigger="cdkOverlayOrigin" cdkOverlayOrigin>
      <div class="structure-item__content">
        <div
          class="sidebar-list-item sidebar-list-item_compact"
          [class.sidebar-list-item_clickable]="parentNode"
          [class.sidebar-list-item_draggable]="dragItem"
          (click)="startRename()"
        >
          <div class="sidebar-list-item__left">
            <span class="button button_icon button_orange button_tiny" style="margin: 0;">
              <span class="icon button__icon" [ngClass]="['icon-' + fieldIcon(fieldParams.field)]"></span>
            </span>
          </div>
          <div class="sidebar-list-item__main">
            <ng-container
              *ngTemplateOutlet="node_title_template; context: { name: node.name, label: node.label }"
            ></ng-container>
          </div>
          <div class="sidebar-list-item__right sidebar-list-item__actions">
            <div
              class="sidebar-list-item__action sidebar-list-item__action_button icon-more"
              [class.sidebar-list-item__action_hover-only]="!item_dropdown_trigger.menuOpen"
              [class.sidebar-list-item__action_active]="item_dropdown_trigger.menuOpen"
              [matMenuTriggerFor]="edit_item_dropdown"
              #item_dropdown_trigger="matMenuTrigger"
              (click)="$event.stopPropagation(); $event.preventDefault()"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="!skipContent">
    <ng-container *ngIf="arrayParams.item?.type == types.Object || arrayParams.item?.type == types.Array">
      <app-json-field-structure-node
        [node]="arrayParams.item"
        [parentNode]="node"
        [skipSelf]="true"
        [created]="createdNode === arrayParams.item"
        [deep]="deep + 1"
        (changed)="onArrayChildChanged($event)"
      ></app-json-field-structure-node>
    </ng-container>

    <div *ngIf="arrayParams.item?.type == types.Field" class="structure-item">
      <div class="structure-item__dash structure-item__dash_last">
        <span class="structure-item__dash-vertical"></span>
        <span class="structure-item__dash-horizontal"></span>
      </div>
      <div class="structure-item__content">
        <app-json-field-structure-node
          [node]="arrayParams.item"
          [parentNode]="node"
          [created]="createdNode === arrayParams.item"
          [deep]="deep + 1"
          (changed)="onArrayChildChanged($event)"
        ></app-json-field-structure-node>
      </div>
    </div>
  </ng-container>
</div>

<div *ngIf="node?.type == types.Field">
  <ng-container *ngTemplateOutlet="rename_template; context: { origin: root_trigger }"></ng-container>
  <ng-container *ngTemplateOutlet="edit_template; context: { origin: root_trigger }"></ng-container>

  <div
    class="sidebar-list-item sidebar-list-item_compact"
    [class.sidebar-list-item_clickable]="true"
    [class.sidebar-list-item_draggable]="dragItem"
    [class.sidebar-list-item_active]="renameOpened || editOpened"
    (click)="setEditNodeOpened(true)"
    style="margin: 0;"
    #root_trigger="cdkOverlayOrigin"
    cdkOverlayOrigin
  >
    <div class="sidebar-list-item__left">
      <span class="button button_icon button_orange button_tiny" style="margin: 0;">
        <span class="icon button__icon" [ngClass]="['icon-' + fieldIcon(fieldParams.field)]"></span>
      </span>
    </div>
    <div class="sidebar-list-item__main">
      <ng-container
        *ngTemplateOutlet="node_title_template; context: { name: node.name, label: fieldParams.label }"
      ></ng-container>
    </div>
    <div class="sidebar-list-item__right">
      <div
        class="sidebar-list-item__action sidebar-list-item__action_button icon-more"
        [class.sidebar-list-item__action_hover-only]="!item_dropdown_trigger.menuOpen"
        [class.sidebar-list-item__action_active]="item_dropdown_trigger.menuOpen"
        [matMenuTriggerFor]="edit_item_dropdown"
        #item_dropdown_trigger="matMenuTrigger"
        (click)="$event.stopPropagation(); $event.preventDefault()"
      ></div>
    </div>
  </div>
</div>

<div *ngIf="!node">
  <a
    href="javascript:void(0)"
    class="structure-add-item"
    [matMenuTriggerFor]="add_item"
    [matMenuTriggerData]="{ self: true }"
  >
    <span class="structure-add-item__left" [class.structure-add-item__left_root]="!parentNode">
      <span class="icon-plus structure-add-item__icon"></span>
    </span>
    <span class="structure-add-item__main">Add Node</span>
  </a>
</div>

<app-sidebar-card>
  <div class="sidebar__element sidebar__element_padding_s sidebar__element_margin_xs">
    <div class="sidebar-cols">
      <div *ngIf="horizontalControl" class="sidebar-cols__item">
        <div class="input-group">
          <div
            class="input-group__item input-group__item_first input input_small input_disabled icon-resize_horizontal"
          ></div>
          <input
            type="number"
            class="input-group__item input-group__item_last input-group__fill input input_number input_small"
            placeholder="auto"
            min="0"
            [style.font-size.px]="14"
            [formControl]="horizontalControl"
            (focus)="gap_horizontal.select()"
            #gap_horizontal
          />
        </div>
      </div>

      <div *ngIf="verticalControl" class="sidebar-cols__item">
        <div class="input-group">
          <div
            class="input-group__item input-group__item_first input input_small input_disabled icon-resize_vertical"
          ></div>
          <input
            type="number"
            class="input-group__item input-group__item_last input-group__fill input input_number input_small"
            placeholder="auto"
            min="0"
            [style.font-size.px]="14"
            [formControl]="verticalControl"
            (focus)="gap_vertical.select()"
            #gap_vertical
          />
        </div>
      </div>
    </div>
  </div>
</app-sidebar-card>

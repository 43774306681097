var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FormGroup } from '@angular/forms';
import values from 'lodash/values';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, merge, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, skip } from 'rxjs/operators';
import { ListDefaultSelection } from '@modules/customize';
import { Input as FieldInput, InputValueType } from '@modules/fields';
import { ListModelDescriptionQuery } from '@modules/queries';
import { controlValid } from '@shared';
export var validateAction = function (control) {
    var parent = control.parent;
    if (!parent) {
        return of(null);
    }
    if (!control.value) {
        return of(null);
    }
    return parent.elementConfigurationService.isActionConfigured(control.value).pipe(map(function (configured) {
        if (!configured) {
            return { required: true };
        }
    }));
};
export var validateActions = function (control) {
    var parent = control.parent;
    if (!parent) {
        return of(null);
    }
    if (!control.value || !control.value.length) {
        return of(null);
    }
    return combineLatest(control.value.map(function (item) { return parent.elementConfigurationService.isActionConfigured(item); })).pipe(map(function (result) {
        if (result.some(function (configured) { return !configured; })) {
            return { required: true };
        }
    }));
};
var CustomizeBarListLayoutSettingsForm = /** @class */ (function (_super) {
    __extends(CustomizeBarListLayoutSettingsForm, _super);
    function CustomizeBarListLayoutSettingsForm(currentProjectStore, currentEnvironmentStore, resourceControllerService, modelDescriptionStore, actionService, elementConfigurationService, controls) {
        var _this = _super.call(this, controls) || this;
        _this.currentProjectStore = currentProjectStore;
        _this.currentEnvironmentStore = currentEnvironmentStore;
        _this.resourceControllerService = resourceControllerService;
        _this.modelDescriptionStore = modelDescriptionStore;
        _this.actionService = actionService;
        _this.elementConfigurationService = elementConfigurationService;
        _this.ignoreSubmitControls = [];
        _this.submitChanges = merge.apply(void 0, values(_this.controls)
            .filter(function (item) { return !_this.ignoreSubmitControls.includes(item); })
            .map(function (item) { return item.valueChanges; }));
        _this.queryClass = ListModelDescriptionQuery;
        _this.sortingOptions = [
            { value: true, name: 'Ascending' },
            { value: false, name: 'Descending' }
        ];
        _this.listDefaultSelectionOptions = [
            { value: undefined, name: 'None' },
            { value: ListDefaultSelection.First, name: 'First' }
        ];
        return _this;
    }
    CustomizeBarListLayoutSettingsForm.prototype.ngOnDestroy = function () { };
    CustomizeBarListLayoutSettingsForm.prototype.trackChanges = function (options) {
        var _this = this;
        if (options.nameEditable) {
            this.controls.title.valueChanges.pipe(untilDestroyed(this)).subscribe(function (inputValue) {
                var input = inputValue ? new FieldInput().deserialize(inputValue) : undefined;
                if (!input || input.valueType != InputValueType.StaticValue) {
                    return;
                }
                var name = input.staticValue;
                if (options.nameCleanValue) {
                    name = options.nameCleanValue(name);
                }
                _this.controls.name.patchValue(name);
            });
        }
        this.controls.data_source
            .getModelDescription$()
            .pipe(distinctUntilChanged(function (lhs, rhs) {
            var modelId = function (item) { return (item ? item.modelId : undefined); };
            return modelId(lhs) === modelId(rhs);
        }), skip(1), untilDestroyed(this))
            .subscribe(function (modelDescription) { return _this.onModelChange(modelDescription); });
    };
    CustomizeBarListLayoutSettingsForm.prototype.controlsValid$ = function (controls) {
        var _this = this;
        return combineLatest(controls.map(function (item) { return controlValid(_this.controls[item]); })).pipe(map(function (result) { return result.every(function (item) { return item; }); }), debounceTime(60));
    };
    CustomizeBarListLayoutSettingsForm.prototype.toggleDefaultSorting = function () {
        var control = this.controls.sorting_asc;
        control.patchValue(!control.value);
    };
    CustomizeBarListLayoutSettingsForm.prototype.onModelChange = function (modelDescription) {
        var sortingField;
        var sortingAsc = true;
        if (modelDescription && modelDescription.defaultOrderBy) {
            if (modelDescription.defaultOrderBy.startsWith('-')) {
                sortingField = modelDescription.defaultOrderBy.slice(1);
                sortingAsc = false;
            }
            else {
                sortingField = modelDescription.defaultOrderBy;
                sortingAsc = true;
            }
        }
        this.controls.sorting_field.patchValue(sortingField || null);
        this.controls.sorting_asc.patchValue(sortingAsc);
        if (modelDescription && modelDescription.verboseNamePlural) {
            this.controls.title.patchValue({
                value_type: InputValueType.StaticValue,
                static_value: modelDescription.verboseNamePlural
            });
        }
    };
    CustomizeBarListLayoutSettingsForm.prototype.initPerPageClean = function (control) {
        control.valueChanges
            .pipe(filter(function (val) { return val == '0'; }), untilDestroyed(this))
            .subscribe(function () { return control.patchValue(''); });
    };
    return CustomizeBarListLayoutSettingsForm;
}(FormGroup));
export { CustomizeBarListLayoutSettingsForm };

import { FormControl, FormGroup } from '@angular/forms';

import { HttpParameterType } from '@modules/queries';
import { isSet } from '@shared';

export interface HttpParameterControlState {
  name?: string;
  value?: string;
  type?: HttpParameterType;
}

export class HttpParameterControl extends FormGroup {
  controls: {
    name: FormControl;
    value: FormControl;
    type: FormControl;
  };

  constructor(formState: HttpParameterControlState = {}) {
    super({
      name: new FormControl(formState.name !== undefined ? formState.name : ''),
      value: new FormControl(formState.value !== undefined ? formState.value : ''),
      type: new FormControl(formState.type !== undefined ? formState.type : HttpParameterType.Text)
    });
  }

  isSet(): boolean {
    return isSet(this.controls.name.value) || isSet(this.controls.value.value);
  }
}

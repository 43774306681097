import { Component } from '@angular/core';
import { first, map } from 'rxjs/operators';
import { CustomizeService } from '../../services/customize/customize.service';
import * as i0 from "@angular/core";
import * as i1 from "../../services/customize/customize.service";
var WaitUnsavedChangesGuard = /** @class */ (function () {
    function WaitUnsavedChangesGuard(customizeService) {
        this.customizeService = customizeService;
    }
    WaitUnsavedChangesGuard.prototype.canDeactivate = function (component, currentRoute, currentState, nextState) {
        if (this.customizeService.handler == undefined) {
            return true;
        }
        return this.customizeService.changesSaving$.pipe(first(function (value) { return !value; }), map(function () { return true; }));
    };
    WaitUnsavedChangesGuard.ngInjectableDef = i0.defineInjectable({ factory: function WaitUnsavedChangesGuard_Factory() { return new WaitUnsavedChangesGuard(i0.inject(i1.CustomizeService)); }, token: WaitUnsavedChangesGuard, providedIn: "root" });
    return WaitUnsavedChangesGuard;
}());
export { WaitUnsavedChangesGuard };

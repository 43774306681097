<div class="sidebar-list-item sidebar-list-item_complex">
  <div class="sidebar-list-item__row" (mouseenter)="hovered$.next(true)" (mouseleave)="hovered$.next(false)">
    <div class="sidebar-list-item__drag"></div>

    <div class="sidebar-list-item__left">
      <ng-template
        cdkConnectedOverlay
        [cdkConnectedOverlayPanelClass]="['overlay', 'overlay_horizontal_center', 'overlay_position_left-center']"
        [cdkConnectedOverlayPositions]="[
          { originX: 'start', originY: 'center', overlayX: 'end', overlayY: 'center', offsetX: 0, offsetY: 0 },
          { originX: 'start', originY: 'top', overlayX: 'end', overlayY: 'top', offsetX: 0, offsetY: -20 },
          { originX: 'start', originY: 'bottom', overlayX: 'end', overlayY: 'bottom', offsetX: 0, offsetY: 0 }
        ]"
        [cdkConnectedOverlayOrigin]="root_trigger"
        [cdkConnectedOverlayOpen]="iconPopoverOpened"
        [cdkConnectedOverlayPush]="true"
        [cdkConnectedOverlayGrowAfterOpen]="true"
        [cdkConnectedOverlayHasBackdrop]="true"
        [cdkConnectedOverlayBackdropClass]="'popover2-backdrop'"
        (backdropClick)="setIconPopoverOpened(false)"
        (detach)="setIconPopoverOpened(false)"
      >
        <div class="popover2 overlay-content overlay-content_animated">
          <div class="popover2__inner">
            <app-icon-selector
              [current]="control.controls.icon.value"
              [emptyEnabled]="true"
              (selected)="control.controls.icon.setValue($event); setIconPopoverOpened(false)"
            ></app-icon-selector>
          </div>
        </div>
      </ng-template>

      <a
        href="javascript:void(0)"
        class="button button_icon button_orange button_extra-small"
        #root_trigger="cdkOverlayOrigin"
        cdkOverlayOrigin
        style="margin: 0;"
        (click)="setIconPopoverOpened(true)"
      >
        <span
          class="button__icon"
          [ngClass]="control.controls.icon.value ? 'icon-' + control.controls.icon.value : 'icon-power'"
        ></span>
      </a>
    </div>

    <div class="sidebar-list-item__main">
      <div class="sidebar-list-item__title">
        <app-editable-content
          [value]="control.controls.name.value"
          [emptyValue]="'Action name'"
          [pen]="hovered$ | async"
          [penTitle]="'Rename action'"
          (valueChange)="control.controls.name.patchValue($event)"
          (mousedown)="$event.stopPropagation()"
        >
        </app-editable-content>
      </div>
    </div>

    <div *ngIf="hovered$ | async" class="sidebar-list-item__right">
      <a href="javascript:void(0)" class="sidebar-list-item__action icon-bin" (click)="delete.emit()"> </a>
    </div>
  </div>

  <div *ngIf="parametersEnabled" class="sidebar-list-item__row">
    <div class="sidebar-list-item__children">
      <div
        class="sidebar-list-item__children-content"
        cdkDropList
        [cdkDropListLockAxis]="'y'"
        (cdkDropListDropped)="dragDrop($event)"
      >
        <app-action-outputs-edit-item-parameter
          *ngFor="let item of control.controls.parameters.controls"
          cdkDrag
          class="sidebar-list-item__children-item"
          [control]="item"
          (delete)="deleteParameter(item)"
        ></app-action-outputs-edit-item-parameter>
      </div>

      <div
        class="sidebar-list-item__children-buttons"
        [class.sidebar-list-item__children-buttons_background]="!control.controls.parameters.controls.length"
      >
        <a
          href="javascript:void(0)"
          class="sidebar-list-item__children-button sidebar-list-item__children-buttons-item"
          (click)="addParameter()"
        >
          <span class="sidebar-list-item__children-button-icon icon-plus"></span>
          <span class="sidebar-list-item__children-button-label">Add Parameter</span>
        </a>
      </div>
    </div>
  </div>
</div>

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, publishLast, refCount, switchMap } from 'rxjs/operators';
import { ApiService } from '@modules/api';
import * as i0 from "@angular/core";
import * as i1 from "../../../api/services/api/api.service";
import * as i2 from "@angular/common/http";
var EmailService = /** @class */ (function () {
    function EmailService(apiService, http) {
        this.apiService = apiService;
        this.http = http;
    }
    EmailService.prototype.send = function (project, environment, to, subject, text, textHtml) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.nodeEnvironmentMethodURL(project.uniqueName, environment.uniqueName, 'send_email');
            var headers = new HttpHeaders();
            var data = {
                to: to,
                subject: subject,
                text: text,
                text_html: textHtml
            };
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.post(url, data, { headers: headers });
        }), map(function () { return true; }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    EmailService.ngInjectableDef = i0.defineInjectable({ factory: function EmailService_Factory() { return new EmailService(i0.inject(i1.ApiService), i0.inject(i2.HttpClient)); }, token: EmailService, providedIn: "root" });
    return EmailService;
}());
export { EmailService };

import * as moment from 'moment';

import { AutomationTriggerType } from '../automation-trigger-type';
import { registerAutomationTriggerForType } from '../automation-trigger-types';
import { AutomationTrigger } from './base';

export enum TimetableType {
  EveryDay = 'every_day',
  EveryWeek = 'every_week',
  EveryMonth = 'every_month',
  Once = 'once',
  Custom = 'model'
}

export class TimetableAutomationTrigger extends AutomationTrigger {
  type = AutomationTriggerType.Timetable;
  timetableType: TimetableType;
  timetableTime: moment.Moment;
  timetableDaysOfWeek: number[];
  timetableDays: number[];
  timetableMonths: number[];
  timetableDate: moment.Moment;

  deserialize(data: Object): this {
    const timetableTime = data['timetable_time'] ? moment(data['timetable_time'], ['HH:mm:ss', 'HH:mm']) : undefined;
    const timetableDate = data['timetable_date'] ? moment(data['timetable_date']) : undefined;

    this.timetableType = data['timetable_type'];
    this.timetableTime = timetableTime && timetableTime.isValid() ? timetableTime : undefined;
    this.timetableDaysOfWeek = data['timetable_days_of_week'];
    this.timetableDays = data['timetable_days'];
    this.timetableMonths = data['timetable_months'];
    this.timetableDate = timetableDate && timetableDate.isValid() ? timetableDate : undefined;

    return this;
  }

  serialize() {
    return {
      timetable_type: this.timetableType,
      timetable_time: this.timetableTime ? this.timetableTime.format('HH:mm:ss') : undefined,
      timetable_days_of_week: this.timetableDaysOfWeek,
      timetable_days: this.timetableDays,
      timetable_months: this.timetableMonths,
      timetable_date: this.timetableDate ? this.timetableDate.toISOString() : undefined
    };
  }
}

registerAutomationTriggerForType(AutomationTriggerType.Timetable, TimetableAutomationTrigger);

<div class="sidebar__element">
  <app-sidebar-field [label]="'JavaScript code'">
    <div
      class="field field_content-fill-vertical input-icon-wrapper"
      [class.input-icon-wrapper_fill-vertical]="fillVertical"
    >
      <ng-container *ngIf="!isInputOverlayOpened()">
        <app-auto-field
          [form]="form"
          [label]="false"
          [field]="
            createField({
              name: 'run_javascript',
              field: 'CodeField',
              placeholder: jsPlaceholder,
              params: {
                language: 'javascript',
                autogrow: !fillVertical,
                flexible: fillVertical,
                field: true,
                field_contrast: true,
                wrap: true,
                dark: dark,
                classes: [fill ? 'input_fill' : ''].concat(classes || [])
              }
            })
          "
          #js_field
        ></app-auto-field>
      </ng-container>

      <div
        *ngIf="isInputOverlayOpened()"
        class="input input_disabled"
        [class.input_fill]="fill"
        [class.input_fill-vertical]="fillVertical"
      ></div>

      <!--      <div-->
      <!--        *ngIf="overlayEnabled"-->
      <!--        class="input-expand input-expand_position_top-right input-icon-hover_flex"-->
      <!--        [class.input-expand_active]="isInputOverlayOpened()"-->
      <!--        [class.input-icon-hover]="!isInputOverlayOpened()"-->
      <!--        appInputOverlay-->
      <!--        [appInputOverlayInput]="this"-->
      <!--        (appInputOverlayFinished)="onInputOverlayFinished()"-->
      <!--      >-->
      <!--        <span-->
      <!--          class="input-expand__icon"-->
      <!--          [class.icon-copy]="isInputOverlayOpened()"-->
      <!--          [class.icon-enlarge_expand]="!isInputOverlayOpened()"-->
      <!--        ></span>-->
      <!--        <span class="input-expand__label">Editing in popup</span>-->
      <!--        <span class="input-expand__close icon-close"></span>-->
      <!--      </div>-->
    </div>

    <ng-container *ngIf="!isInputOverlayOpened()">
      <div *ngIf="jsError" class="input-error input-error_overlay input-error_rounded-bottom">
        <div class="input-error__inner" [title]="jsError">{{ jsError }}</div>
      </div>

      <app-view-context-token-popover-overlay
        [context]="context"
        [contextElement]="contextElement"
        [contextElementPath]="contextElementPath"
        [contextElementPaths]="contextElementPaths"
        [origin]="insert_token_origin"
        [control]="form.controls.run_javascript"
        [hideSections]="['functions']"
        (selected)="onJsTokenSelected($event); closeTokenPopover()"
      ></app-view-context-token-popover-overlay>

      <div class="button-tags" style="padding-top: 4px;">
        <div
          class="button-tag button-tags__item button-tags__item_fill"
          [class.button-tag_darker]="darker"
          cdkOverlayOrigin
          #insert_token_origin="cdkOverlayOrigin"
          (click)="openTokenPopover()"
        >
          <span class="button-tag__icon button-tag__icon_text">{{ '{' }}..{{ '}' }}</span>
          <span class="button-tag__label">Insert Input</span>
        </div>
      </div>
    </ng-container>
  </app-sidebar-field>
</div>

import { registerElementForType } from '../element-items';
import { ElementType } from '../element-type';
import { ElementItem } from './base';

export class TitleElementItem extends ElementItem {
  public type = ElementType.Title;
  public title: string;

  deserialize(data: Object): TitleElementItem {
    super.deserialize(data);
    this.title = this.params['title'];
    return this;
  }

  serialize(): Object {
    const data = super.serialize();
    data['params']['title'] = this.title;
    return data;
  }

  get analyticsName(): string {
    return 'title';
  }

  defaultName() {
    return 'Title';
  }
}

registerElementForType(ElementType.Title, TitleElementItem);

import pickBy from 'lodash/pickBy';

import { SimpleQuery } from './simple-query';

export class ModelDescriptionSimpleQuery extends SimpleQuery {
  model: string;

  deserialize(data: Object): ModelDescriptionSimpleQuery {
    this.model = data['model'];

    return this;
  }

  serialize(fields?: string[]): Object {
    let data: Object = {
      model: this.model
    };
    if (fields) {
      data = <Object>pickBy(data, (v, k) => fields.includes(k));
    }
    return data;
  }

  isConfigured(): boolean {
    return !!this.model;
  }
}

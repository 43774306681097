import pickBy from 'lodash/pickBy';

import { Input } from '@modules/fields';
import { generateAlphanumeric } from '@shared';

export class KanbanBoardStage {
  uid: string;
  value: any;
  name: string;
  color: string;
  visibleInput: Input;

  deserialize(data: Object): KanbanBoardStage {
    this.uid = data['uid'];
    this.value = data['value'];
    this.name = data['name'];
    this.color = data['color'];

    if (!this.uid) {
      this.generateUid();
    }

    if (data['visible_input']) {
      this.visibleInput = new Input().deserialize(data['visible_input']);
    }

    return this;
  }

  serialize(fields?: string[]): Object {
    let data: Object = {
      uid: this.uid,
      value: this.value,
      name: this.name,
      color: this.color,
      visible_input: this.visibleInput ? this.visibleInput.serialize() : undefined
    };
    if (fields) {
      data = <Object>pickBy(data, (v, k) => fields.includes(k));
    }
    return data;
  }

  generateUid() {
    this.uid = generateAlphanumeric(8, { letterFirst: true });
  }
}

<div class="query-builder__step">
  <div class="query-builder__step-header">
    <!-- <div class="query-builder__step-header-number">
      <span class="query-builder__step-header-number-content">1</span>
      <span class="query-builder__step-header-number-tick icon icon-check_2"></span>
    </div> -->
    <div class="query-builder__step-header-info">
      <div class="query-builder__step-header-title">Inputs</div>
      <div class="query-builder__step-header-subtitle">You can add inputs for dynamic data</div>
    </div>
  </div>

  <div class="query-builder__step-content">
    <div *ngFor="let itemForm of form.form.controls; let i = index; let last = last">
      <div class="query-builder__columns">
        <div class="query-builder__columns-item query-builder__columns-item_size_2colmin-action">
          <div class="field field_no-margin">
            <label *ngIf="i == 0" class="field__label">Name</label>
            <app-auto-field
              [form]="itemForm"
              [label]="false"
              [field]="createField({ name: 'name', field: 'CharField' })"
              [manualMargin]="true"
            ></app-auto-field>
          </div>
        </div>
        <div class="query-builder__columns-item query-builder__columns-item_size_2colmax-action">
          <div class="field field_no-margin">
            <label *ngIf="i == 0" class="field__label">Type</label>
            <app-field-type-field
              [form]="itemForm"
              [label]="false"
              [field]="createField({ name: 'field', label: 'type', params: { classes: ['select_fill'] } })"
              [manualMargin]="true"
            ></app-field-type-field>
          </div>
        </div>
        <div
          class="query-builder__columns-item query-builder__columns-item_size_action"
          [class.query-builder__columns-item_no-label]="i == 0"
        >
          <a
            *ngIf="customizable"
            href="javascript:void(0)"
            class="button button_icon button_bigger button_no-margin"
            (click)="openArraySettings(itemForm, button)"
            #button
          >
            <span class="icon icon-gear button__icon"></span>
          </a>
          <a
            *ngIf="!customizable"
            href="javascript:void(0)"
            class="button button_icon button_danger button_bigger button_no-margin"
            (click)="requestDelete(itemForm)"
          >
            <span class="icon icon-bin button__icon"></span>
          </a>
        </div>
      </div>
    </div>

    <div class="query-builder__step-actions">
      <a
        href="javascript:void(0)"
        class="button button_small button_no-margin query-builder__step-actions-item"
        (click)="form.arrayAppend(form.createParam())"
      >
        <span class="icon icon-plus button__icon button__icon_left"></span>
        <span class="button__label">Add more</span>
      </a>
    </div>
  </div>
</div>

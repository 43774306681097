import { Pipe, PipeTransform } from '@angular/core';

import { isSet } from '../../utils/common/common';

@Pipe({
  name: 'appIsSet'
})
export class IsSetPipe implements PipeTransform {
  transform(value: any, strict = false): boolean {
    return isSet(value, strict);
  }
}

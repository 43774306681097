export const stripeResourceParamsModelDescriptions: Object[] = [
  {
    project: '{{project}}',
    resource: '{{resource}}',
    model: 'balance_transactions',
    verbose_name: 'Balance Transactions',
    verbose_name_plural: 'Balance Transactions',
    fields: [
      {
        name: 'id',
        verbose_name: 'id',
        field: 'text',
        required: true,
        null: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'object',
        verbose_name: 'object',
        field: 'text',
        required: true,
        null: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'amount',
        verbose_name: 'amount',
        field: 'text',
        required: true,
        null: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'available_on',
        verbose_name: 'available on',
        field: 'datetime',
        required: true,
        null: false,
        default_type: 'value',
        params: {
          date: true,
          time: true,
          date_format: 'local',
          time_format: 'hour_minute_24',
          output_format: 'timestamp'
        }
      },
      {
        name: 'created',
        verbose_name: 'created',
        field: 'datetime',
        required: true,
        null: false,
        default_type: 'value',
        params: {
          date: true,
          time: true,
          date_format: 'local',
          time_format: 'hour_minute_24',
          output_format: 'timestamp'
        }
      },
      {
        name: 'currency',
        verbose_name: 'currency',
        field: 'text',
        required: true,
        null: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'description',
        verbose_name: 'description',
        field: 'text',
        required: true,
        null: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'exchange_rate',
        verbose_name: 'exchange rate',
        field: 'number',
        required: true,
        null: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'fee',
        verbose_name: 'fee',
        field: 'number',
        required: true,
        null: false,
        default_type: 'value',
        params: {
          type: 'integer',
          fraction: 2
        }
      },
      {
        name: 'fee_details',
        verbose_name: 'fee details',
        field: 'text',
        required: true,
        null: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'net',
        verbose_name: 'net',
        field: 'number',
        required: true,
        null: false,
        default_type: 'value',
        params: {
          type: 'integer',
          fraction: 2
        }
      },
      {
        name: 'reporting_category',
        verbose_name: 'reporting category',
        field: 'text',
        required: true,
        null: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'source',
        verbose_name: 'source',
        field: 'text',
        required: true,
        null: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'status',
        verbose_name: 'status',
        field: 'text',
        required: true,
        null: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'type',
        verbose_name: 'type',
        field: 'text',
        required: true,
        null: false,
        default_type: 'value',
        params: {}
      }
    ],
    flex_fields: [],
    segments: [],
    primary_key_field: 'id',
    relations: [],
    description: 'Returns a list of transactions that have contributed to the Stripe account balance.',
    get_query: {
      query_type: 'http',
      http_query: {
        method: 'GET',
        url: 'https://api.stripe.com/v1/balance_transactions',
        auth_params: {},
        body_type: 'JSON',
        body: '',
        headers: [
          {
            name: 'Authorization',
            value: 'Bearer {-api_key-}'
          }
        ],
        query_params: [
          {
            name: 'ending_before',
            value: '{{paging.cursor_prev}}'
          },
          {
            name: 'starting_after',
            value: '{{paging.cursor_next}}'
          },
          {
            name: 'payout',
            value: '{{params.payout}}'
          },
          {
            name: 'type',
            value: '{{params.type}}'
          },
          {
            name: 'currency',
            value: '{{params.currency}}'
          },
          {
            name: 'limit',
            value: '{{paging.limit}}'
          },
          {
            name: 'created',
            value: '{{params.created}}'
          }
        ],
        response_type: 'json',
        response_transformer: "// add custom transformation here\nreturn data['data']",
        request_tokens: {}
      },
      pagination: 'cursor',
      pagination_has_more_function: "// ex. data['hasMore']\nreturn data['has_more']",
      pagination_cursor_next_function:
        "// ex. data['next_cursor']\nreturn data['data'] && data['data'].length ? data['data'][data['data'].length - 1].id : undefined;",
      pagination_total_path: [],
      pagination_cursor_next_path: [],
      pagination_has_more_pages_path: [],
      pagination_has_more_total_pages_path: [],
      pagination_has_more_total_records_path: [],
      sorting: false,
      sorting_fields: []
    },
    get_parameters: [
      {
        name: 'payout',
        verbose_name: 'payout',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'type',
        verbose_name: 'type',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'currency',
        verbose_name: 'currency',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'created',
        verbose_name: 'created',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      }
    ],
    get_inputs: [],
    get_detail_query: {
      query_type: 'http',
      http_query: {
        method: 'GET',
        url: 'https://api.stripe.com/v1/balance_transactions/{{params.id}}',
        auth_params: {},
        body_type: 'JSON',
        body: '',
        headers: [
          {
            name: 'Authorization',
            value: 'Bearer {-api_key-}'
          }
        ],
        query_params: [],
        response_type: 'json',
        response_path: '',
        request_tokens: {}
      }
    },
    get_detail_parameters: [
      {
        name: 'id',
        verbose_name: 'transaction_id',
        description: '',
        field: 'text',
        required: true,
        default_type: 'value',
        default_value: '',
        params: {}
      }
    ],
    get_detail_parameters_use_defaults: false,
    create_parameters: [
      {
        name: 'id',
        verbose_name: 'id',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'object',
        verbose_name: 'object',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'amount',
        verbose_name: 'amount',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'available_on',
        verbose_name: 'available_on',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'created',
        verbose_name: 'created',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'currency',
        verbose_name: 'currency',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'description',
        verbose_name: 'description',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'exchange_rate',
        verbose_name: 'exchange_rate',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'fee',
        verbose_name: 'fee',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'fee_details',
        verbose_name: 'fee_details',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'net',
        verbose_name: 'net',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'reporting_category',
        verbose_name: 'reporting_category',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'source',
        verbose_name: 'source',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'status',
        verbose_name: 'status',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'type',
        verbose_name: 'type',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      }
    ],
    create_parameters_use_defaults: false,
    update_parameters: [
      {
        name: 'id',
        verbose_name: 'id',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'object',
        verbose_name: 'object',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'amount',
        verbose_name: 'amount',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'available_on',
        verbose_name: 'available_on',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'created',
        verbose_name: 'created',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'currency',
        verbose_name: 'currency',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'description',
        verbose_name: 'description',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'exchange_rate',
        verbose_name: 'exchange_rate',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'fee',
        verbose_name: 'fee',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'fee_details',
        verbose_name: 'fee_details',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'net',
        verbose_name: 'net',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'reporting_category',
        verbose_name: 'reporting_category',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'source',
        verbose_name: 'source',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'status',
        verbose_name: 'status',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'type',
        verbose_name: 'type',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      }
    ],
    update_parameters_use_defaults: false,
    delete_parameters: [],
    delete_parameters_use_defaults: false,
    aggregate_parameters: [],
    group_parameters: [],
    sync: false,
    sync_finished: false,
    params: {},
    draft: false,
    deleted: false
  },
  {
    project: '{{project}}',
    resource: '{{resource}}',
    model: 'charges',
    verbose_name: 'Charges',
    verbose_name_plural: 'Charges',
    fields: [
      {
        name: 'id',
        verbose_name: 'id',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'object',
        verbose_name: 'object',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'amount',
        verbose_name: 'amount',
        field: 'number',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {
          type: 'integer',
          fraction: 2
        }
      },
      {
        name: 'amount_refunded',
        verbose_name: 'amount refunded',
        field: 'number',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'application',
        verbose_name: 'application',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'application_fee',
        verbose_name: 'application fee',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'application_fee_amount',
        verbose_name: 'application fee amount',
        field: 'number',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'balance_transaction',
        verbose_name: 'balance transaction',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'billing_details',
        verbose_name: 'billing details',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'calculated_statement_descriptor',
        verbose_name: 'calculated statement descriptor',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'captured',
        verbose_name: 'captured',
        field: 'boolean',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'created',
        verbose_name: 'created',
        field: 'datetime',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {
          date: true,
          time: true,
          date_format: 'local',
          time_format: 'hour_minute_24',
          output_format: 'timestamp'
        }
      },
      {
        name: 'currency',
        verbose_name: 'currency',
        field: 'select',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'customer',
        verbose_name: 'customer',
        field: 'related_model',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {
          related_model: {
            model: '{{resource}}.customers'
          },
          custom_primary_key: 'id'
        }
      },
      {
        name: 'description',
        verbose_name: 'description',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'destination',
        verbose_name: 'destination',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'dispute',
        verbose_name: 'dispute',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'disputed',
        verbose_name: 'disputed',
        field: 'boolean',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'failure_code',
        verbose_name: 'failure code',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'failure_message',
        verbose_name: 'failure message',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'fraud_details',
        verbose_name: 'fraud details',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'invoice',
        verbose_name: 'invoice',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'livemode',
        verbose_name: 'livemode',
        field: 'boolean',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'metadata',
        verbose_name: 'metadata',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'on_behalf_of',
        verbose_name: 'on behalf of',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'order',
        verbose_name: 'order',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'outcome',
        verbose_name: 'outcome',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'paid',
        verbose_name: 'paid',
        field: 'boolean',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'payment_intent',
        verbose_name: 'payment intent',
        field: 'related_model',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {
          related_model: {
            model: '{{resource}}.payment_intents'
          },
          custom_primary_key: 'id'
        }
      },
      {
        name: 'payment_method',
        verbose_name: 'payment method',
        field: 'related_model',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {
          related_model: {
            model: '{{resource}}.payment_methods'
          },
          custom_primary_key: 'id'
        }
      },
      {
        name: 'payment_method_details',
        verbose_name: 'payment method details',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'receipt_email',
        verbose_name: 'receipt email',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'receipt_number',
        verbose_name: 'receipt number',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'receipt_url',
        verbose_name: 'receipt url',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'refunded',
        verbose_name: 'refunded',
        field: 'boolean',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'refunds',
        verbose_name: 'refunds',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'review',
        verbose_name: 'review',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'shipping',
        verbose_name: 'shipping',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'source',
        verbose_name: 'source',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'source_transfer',
        verbose_name: 'source transfer',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'statement_descriptor',
        verbose_name: 'statement descriptor',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'statement_descriptor_suffix',
        verbose_name: 'statement descriptor suffix',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'status',
        verbose_name: 'status',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'transfer_data',
        verbose_name: 'transfer data',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'transfer_group',
        verbose_name: 'transfer group',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      }
    ],
    flex_fields: [],
    segments: [],
    primary_key_field: 'id',
    relations: [],
    description:
      'Returns a list of charges you’ve previously created. The charges are returned in sorted order, with the most recent charges appearing first.',
    get_query: {
      query_type: 'http',
      http_query: {
        method: 'GET',
        url: 'https://api.stripe.com/v1/charges',
        auth_params: {},
        body_type: 'JSON',
        body: '',
        headers: [
          {
            name: 'Authorization',
            value: 'Bearer {-api_key-}'
          }
        ],
        query_params: [
          {
            name: 'limit',
            value: '{{paging.limit}}'
          },
          {
            name: 'customer',
            value: '{{params.customer}}'
          },
          {
            name: 'ending_before',
            value: '{{paging.cursor_prev}}'
          },
          {
            name: 'starting_after',
            value: '{{paging.cursor_next}}'
          },
          {
            name: 'created',
            value: '{{params.created}}'
          },
          {
            name: 'payment_intent',
            value: '{{params.payment_intent}}'
          }
        ],
        response_type: 'json',
        response_transformer: "// add custom transformation here\nreturn data['data']",
        request_tokens: {}
      },
      pagination: 'cursor',
      pagination_has_more_function: "// ex. data['hasMore']\nreturn data['has_more']",
      pagination_cursor_next_function:
        "// ex. data['next_cursor']\nreturn data['data'] && data['data'].length ? data['data'][data['data'].length - 1].id : undefined;",
      pagination_total_path: [],
      pagination_cursor_next_path: [],
      pagination_has_more_pages_path: [],
      pagination_has_more_total_pages_path: [],
      pagination_has_more_total_records_path: [],
      sorting: false,
      sorting_fields: []
    },
    get_parameters: [
      {
        name: 'customer',
        verbose_name: 'customer',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'created',
        verbose_name: 'created',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'payment_intent',
        verbose_name: 'payment_intent',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      }
    ],
    get_inputs: [],
    get_detail_query: {
      query_type: 'http',
      http_query: {
        method: 'GET',
        url: 'https://api.stripe.com/v1/charges/{{params.id}}',
        auth_params: {},
        body_type: 'JSON',
        body: '',
        headers: [
          {
            name: 'Authorization',
            value: 'Bearer {-api_key-}'
          }
        ],
        query_params: [],
        response_type: 'json',
        response_path: '',
        request_tokens: {}
      }
    },
    get_detail_parameters: [
      {
        name: 'id',
        verbose_name: 'charge_id',
        description: '',
        field: 'text',
        required: true,
        default_type: 'value',
        default_value: '',
        params: {}
      }
    ],
    get_detail_parameters_use_defaults: false,
    create_query: {
      query_type: 'http',
      http_query: {
        method: 'POST',
        url: 'https://api.stripe.com/v1/charges',
        auth_params: {},
        body_type: 'JSON',
        body: '',
        headers: [
          {
            name: 'Authorization',
            value: 'Bearer {-api_key-}'
          }
        ],
        query_params: [
          {
            name: 'amount',
            value: '{{params.amount}}'
          },
          {
            name: 'currency',
            value: '{{params.currency}}'
          },
          {
            name: 'customer',
            value: '{{params.customer}}'
          },
          {
            name: 'description',
            value: '{{params.description}}'
          },
          {
            name: 'metadata[order_id]',
            value: '{{params.metadata}}'
          },
          {
            name: 'receipt_email',
            value: '{{params.receipt_email}}'
          },
          {
            name: 'shipping',
            value: '{{params.shipping}}'
          },
          {
            name: 'source',
            value: '{{params.source}}'
          }
        ],
        response_type: 'json',
        response_path: '',
        request_tokens: {}
      }
    },
    create_parameters: [
      {
        name: 'amount',
        verbose_name: 'amount',
        description: '',
        field: 'text',
        required: true,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'currency',
        verbose_name: 'currency',
        description: '',
        field: 'text',
        required: true,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'customer',
        verbose_name: 'customer',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'description',
        verbose_name: 'description',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'metadata',
        verbose_name: 'metadata',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'receipt_email',
        verbose_name: 'receipt_email',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'shipping',
        verbose_name: 'shipping',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'source',
        verbose_name: 'source',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      }
    ],
    create_parameters_use_defaults: false,
    update_query: {
      query_type: 'http',
      http_query: {
        method: 'POST',
        url: 'https://api.stripe.com/v1/charges/{{params.id}}',
        auth_params: {},
        body_type: 'JSON',
        body: '',
        headers: [
          {
            name: 'Authorization',
            value: 'Bearer {-api_key-}'
          }
        ],
        query_params: [
          {
            name: 'metadata[order_id]',
            value: '{{params.metadata}}'
          },
          {
            name: 'customer',
            value: '{{params.customer}}'
          },
          {
            name: 'description',
            value: '{{params.description}}'
          },
          {
            name: 'receipt_email',
            value: '{{params.receipt_email}}'
          }
        ],
        response_type: 'json',
        response_path: '',
        request_tokens: {}
      }
    },
    update_parameters: [
      {
        name: 'id',
        verbose_name: 'charge_id',
        description: '',
        field: 'text',
        required: true,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'metadata',
        verbose_name: 'metadata',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'customer',
        verbose_name: 'customer',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'description',
        verbose_name: 'description',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'receipt_email',
        verbose_name: 'receipt_email',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      }
    ],
    update_parameters_use_defaults: false,
    delete_parameters: [],
    delete_parameters_use_defaults: false,
    aggregate_parameters: [],
    group_parameters: [],
    sync: false,
    sync_finished: false,
    params: {},
    draft: false,
    deleted: false
  },
  {
    project: '{{project}}',
    resource: '{{resource}}',
    model: 'customers',
    verbose_name: 'Customer',
    verbose_name_plural: 'Customers',
    fields: [
      {
        name: 'id',
        verbose_name: 'id',
        field: 'text',
        required: true,
        null: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'object',
        verbose_name: 'object',
        field: 'text',
        required: true,
        null: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'address',
        verbose_name: 'address',
        field: 'text',
        required: true,
        null: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'balance',
        verbose_name: 'balance',
        field: 'text',
        required: true,
        null: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'created',
        verbose_name: 'created',
        field: 'datetime',
        required: true,
        null: false,
        sortable: true,
        default_type: 'value',
        params: {
          date: true,
          time: true,
          date_format: 'local',
          time_format: 'hour_minute_24',
          output_format: 'timestamp'
        }
      },
      {
        name: 'currency',
        verbose_name: 'currency',
        field: 'text',
        required: true,
        null: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'default_source',
        verbose_name: 'default source',
        field: 'related_model',
        required: true,
        null: false,
        sortable: true,
        default_type: 'value',
        params: {
          related_model: {
            model: '{{resource}}.payment_methods'
          },
          custom_primary_key: 'id'
        }
      },
      {
        name: 'delinquent',
        verbose_name: 'delinquent',
        field: 'boolean',
        required: true,
        null: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'description',
        verbose_name: 'description',
        field: 'text',
        required: true,
        null: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'discount',
        verbose_name: 'discount',
        field: 'text',
        required: true,
        null: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'email',
        verbose_name: 'email',
        field: 'text',
        required: true,
        null: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'invoice_prefix',
        verbose_name: 'invoice prefix',
        field: 'text',
        required: true,
        null: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'invoice_settings',
        verbose_name: 'invoice settings',
        field: 'text',
        required: true,
        null: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'livemode',
        verbose_name: 'livemode',
        field: 'boolean',
        required: true,
        null: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'metadata',
        verbose_name: 'metadata',
        field: 'text',
        required: true,
        null: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'name',
        verbose_name: 'name',
        field: 'text',
        required: true,
        null: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'next_invoice_sequence',
        verbose_name: 'next invoice sequence',
        field: 'number',
        required: true,
        null: false,
        default_type: 'value',
        params: {
          type: 'integer',
          fraction: 2
        }
      },
      {
        name: 'phone',
        verbose_name: 'phone',
        field: 'text',
        required: true,
        null: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'preferred_locales',
        verbose_name: 'preferred locales',
        field: 'text',
        required: true,
        null: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'shipping',
        verbose_name: 'shipping',
        field: 'text',
        required: true,
        null: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'sources',
        verbose_name: 'sources',
        field: 'text',
        required: true,
        null: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'subscriptions',
        verbose_name: 'subscriptions',
        field: 'related_model',
        required: true,
        null: false,
        sortable: true,
        default_type: 'value',
        params: {
          related_model: {
            model: '{{resource}}.subscriptions'
          },
          custom_primary_key: 'id'
        }
      },
      {
        name: 'tax_exempt',
        verbose_name: 'tax exempt',
        field: 'text',
        required: true,
        null: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'tax_ids',
        verbose_name: 'tax ids',
        field: 'text',
        required: true,
        null: false,
        sortable: true,
        default_type: 'value',
        params: {}
      }
    ],
    flex_fields: [],
    segments: [],
    primary_key_field: 'id',
    relations: [],
    description: 'Returns a list of your customers.',
    get_query: {
      query_type: 'http',
      http_query: {
        method: 'GET',
        url: 'https://api.stripe.com/v1/customers',
        auth_params: {},
        body_type: 'JSON',
        body: '',
        headers: [
          {
            name: 'Authorization',
            value: 'Bearer {-api_key-}'
          }
        ],
        query_params: [
          {
            name: 'email',
            value: '{{params.email}}'
          },
          {
            name: 'limit',
            value: '{{paging.limit}}'
          },
          {
            name: 'created',
            value: '{{params.created}}'
          },
          {
            name: 'ending_before',
            value: '{{paging.cursor_prev}}'
          },
          {
            name: 'starting_after',
            value: '{{paging.cursor_next}}'
          }
        ],
        response_type: 'json',
        response_transformer: "// add custom transformation here\nreturn data['data']",
        request_tokens: {}
      },
      pagination: 'cursor',
      pagination_has_more_function: "// ex. data['hasMore']\nreturn data['has_more']",
      pagination_cursor_next_function:
        "// ex. data['next_cursor']\nreturn data['data'] && data['data'].length ? data['data'][data['data'].length - 1].id : undefined;",
      pagination_total_path: [],
      pagination_cursor_next_path: [],
      pagination_has_more_pages_path: [],
      pagination_has_more_total_pages_path: [],
      pagination_has_more_total_records_path: [],
      sorting: false,
      sorting_fields: []
    },
    get_parameters: [
      {
        name: 'email',
        verbose_name: 'email',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'created',
        verbose_name: 'created',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {
          date: true,
          time: true,
          date_format: 'european',
          time_format: 'hour_minute_12',
          output_format: 'iso'
        }
      }
    ],
    get_inputs: [],
    get_detail_query: {
      query_type: 'http',
      http_query: {
        method: 'GET',
        url: 'https://api.stripe.com/v1/customers/{{params.id}}',
        auth_params: {},
        body_type: 'JSON',
        body: '',
        headers: [
          {
            name: 'Authorization',
            value: 'Bearer {-api_key-}'
          }
        ],
        query_params: [],
        response_type: 'json',
        response_path: '',
        request_tokens: {}
      }
    },
    get_detail_parameters: [
      {
        name: 'id',
        verbose_name: 'customer_id',
        description: '',
        field: 'text',
        required: true,
        default_type: 'value',
        default_value: '',
        params: {}
      }
    ],
    get_detail_parameters_use_defaults: false,
    create_query: {
      query_type: 'http',
      http_query: {
        method: 'POST',
        url: 'https://api.stripe.com/v1/customers',
        auth_params: {},
        body_type: 'Raw',
        body: '',
        headers: [
          {
            name: 'Authorization',
            value: 'Bearer {-api_key-}'
          }
        ],
        query_params: [
          {
            name: 'address',
            value: '{{params.address}}'
          },
          {
            name: 'description',
            value: '{{params.description}}'
          },
          {
            name: 'email',
            value: '{{params.email}}'
          },
          {
            name: 'metadata[order_id]',
            value: '{{params.metadata}}'
          },
          {
            name: 'name',
            value: '{{params.name}}'
          },
          {
            name: 'phone',
            value: '{{params.phone}}'
          }
        ],
        response_type: 'json',
        response_path: '',
        request_tokens: {}
      }
    },
    create_parameters: [
      {
        name: 'address',
        verbose_name: 'address',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'description',
        verbose_name: 'description',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'email',
        verbose_name: 'email',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'metadata',
        verbose_name: 'metadata',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'name',
        verbose_name: 'name',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'phone',
        verbose_name: 'phone',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      }
    ],
    create_parameters_use_defaults: false,
    update_query: {
      query_type: 'http',
      http_query: {
        method: 'POST',
        url: 'https://api.stripe.com/v1/customers/{{params.id}}',
        auth_params: {},
        body_type: 'FormUrlEncoded',
        body: [],
        headers: [
          {
            name: 'Authorization',
            value: 'Bearer {-api_key-}'
          }
        ],
        query_params: [
          {
            name: 'metadata[order_id]',
            value: '{{params.metadata}}'
          },
          {
            name: 'address[line1]',
            value: '{{params.address}}'
          },
          {
            name: 'description',
            value: '{{params.description}}'
          },
          {
            name: 'email',
            value: '{{params.email}}'
          },
          {
            name: 'name',
            value: '{{params.name}}'
          },
          {
            name: 'phone',
            value: '{{params.phone}}'
          }
        ],
        response_type: 'json',
        response_path: '',
        request_tokens: {}
      }
    },
    update_parameters: [
      {
        name: 'id',
        verbose_name: 'customer_id',
        description: '',
        field: 'text',
        required: true,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'metadata',
        verbose_name: 'metadata',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'address',
        verbose_name: 'address',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'description',
        verbose_name: 'description',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'email',
        verbose_name: 'email',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'name',
        verbose_name: 'name',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'phone',
        verbose_name: 'phone',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      }
    ],
    update_parameters_use_defaults: false,
    delete_query: {
      query_type: 'http',
      http_query: {
        method: 'DELETE',
        url: 'https://api.stripe.com/v1/customers/{{params.customer_id}}',
        auth_params: {},
        body_type: 'JSON',
        body: '',
        headers: [
          {
            name: 'Authorization',
            value: 'Bearer {-api_key-}'
          }
        ],
        query_params: [],
        response_type: 'json',
        response_path: '',
        request_tokens: {}
      }
    },
    delete_parameters: [
      {
        name: 'customer_id',
        verbose_name: 'customer_id',
        description: '',
        field: 'text',
        required: true,
        default_type: 'value',
        default_value: '',
        params: {}
      }
    ],
    delete_parameters_use_defaults: false,
    aggregate_parameters: [],
    group_parameters: [],
    sync: false,
    sync_finished: false,
    params: {},
    draft: false,
    deleted: false
  },
  {
    project: '{{project}}',
    resource: '{{resource}}',
    model: 'disputes',
    verbose_name: 'Disputes',
    verbose_name_plural: 'Disputes',
    fields: [
      {
        name: 'id',
        verbose_name: 'id',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'object',
        verbose_name: 'object',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'amount',
        verbose_name: 'amount',
        field: 'number',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {
          type: 'integer',
          fraction: 2
        }
      },
      {
        name: 'balance_transaction',
        verbose_name: 'balance transaction',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'balance_transactions',
        verbose_name: 'balance transactions',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'charge',
        verbose_name: 'charge',
        field: 'related_model',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {
          related_model: {
            model: '{{resource}}.charges'
          },
          custom_primary_key: 'id'
        }
      },
      {
        name: 'created',
        verbose_name: 'created',
        field: 'datetime',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {
          date: true,
          time: true,
          date_format: 'local',
          time_format: 'hour_minute_24',
          output_format: 'timestamp'
        }
      },
      {
        name: 'currency',
        verbose_name: 'currency',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'evidence',
        verbose_name: 'evidence',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'evidence_details',
        verbose_name: 'evidence details',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'is_charge_refundable',
        verbose_name: 'is charge refundable',
        field: 'boolean',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'livemode',
        verbose_name: 'livemode',
        field: 'boolean',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'metadata',
        verbose_name: 'metadata',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'payment_intent',
        verbose_name: 'payment intent',
        field: 'related_model',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {
          related_model: {
            model: '{{resource}}.payment_intents'
          },
          custom_primary_key: 'id'
        }
      },
      {
        name: 'reason',
        verbose_name: 'reason',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'status',
        verbose_name: 'status',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      }
    ],
    flex_fields: [],
    segments: [],
    primary_key_field: 'id',
    relations: [],
    description: 'Returns a list of your disputes.',
    get_query: {
      query_type: 'http',
      http_query: {
        method: 'GET',
        url: 'https://api.stripe.com/v1/disputes',
        auth_params: {},
        body_type: 'JSON',
        body: '',
        headers: [
          {
            name: 'Authorization',
            value: 'Bearer {-api_key-}'
          }
        ],
        query_params: [
          {
            name: 'limit',
            value: '{{paging.limit}}'
          },
          {
            name: 'charge',
            value: '{{params.charge}}'
          },
          {
            name: 'payment_intent',
            value: '{{params.payment_intent}}'
          },
          {
            name: 'ending_before',
            value: '{{paging.cursor_prev}}'
          },
          {
            name: 'starting_after',
            value: '{{paging.cursor_next}}'
          }
        ],
        response_type: 'json',
        response_transformer: "// add custom transformation here\nreturn data['data']",
        request_tokens: {}
      },
      pagination: 'cursor',
      pagination_has_more_function: "// ex. data['hasMore']\nreturn data['has_more']",
      pagination_cursor_next_function:
        "// ex. data['next_cursor']\nreturn data['data'] && data['data'].length ? data['data'][data['data'].length - 1].id : undefined;",
      pagination_total_path: [],
      pagination_cursor_next_path: [],
      pagination_has_more_pages_path: [],
      pagination_has_more_total_pages_path: [],
      pagination_has_more_total_records_path: [],
      sorting: false,
      sorting_fields: []
    },
    get_parameters: [
      {
        name: 'charge',
        verbose_name: 'charge',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'payment_intent',
        verbose_name: 'payment_intent',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      }
    ],
    get_inputs: [],
    get_detail_query: {
      query_type: 'http',
      http_query: {
        method: 'GET',
        url: 'https://api.stripe.com/v1/disputes/{{params.id}}',
        auth_params: {},
        body_type: 'JSON',
        body: '',
        headers: [
          {
            name: 'Authorization',
            value: 'Bearer {-api_key-}'
          }
        ],
        query_params: [],
        response_type: 'json',
        response_path: '',
        request_tokens: {}
      }
    },
    get_detail_parameters: [
      {
        name: 'id',
        verbose_name: 'dispute_id',
        description: '',
        field: 'text',
        required: true,
        default_type: 'value',
        default_value: '',
        params: {}
      }
    ],
    get_detail_parameters_use_defaults: false,
    create_parameters: [
      {
        name: 'id',
        verbose_name: 'id',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'object',
        verbose_name: 'object',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'amount',
        verbose_name: 'amount',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'balance_transaction',
        verbose_name: 'balance_transaction',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'balance_transactions',
        verbose_name: 'balance_transactions',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'charge',
        verbose_name: 'charge',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'created',
        verbose_name: 'created',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'currency',
        verbose_name: 'currency',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'evidence',
        verbose_name: 'evidence',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'evidence_details',
        verbose_name: 'evidence_details',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'is_charge_refundable',
        verbose_name: 'is_charge_refundable',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'livemode',
        verbose_name: 'livemode',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'metadata',
        verbose_name: 'metadata',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'payment_intent',
        verbose_name: 'payment_intent',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'reason',
        verbose_name: 'reason',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'status',
        verbose_name: 'status',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      }
    ],
    create_parameters_use_defaults: false,
    update_query: {
      query_type: 'http',
      http_query: {
        method: 'POST',
        url: 'https://api.stripe.com/v1/disputes/{{params.id}}',
        auth_params: {},
        body_type: 'JSON',
        body: '',
        headers: [
          {
            name: 'Authorization',
            value: 'Bearer {-api_key-}'
          }
        ],
        query_params: [
          {
            name: 'evidence',
            value: '{{params.evidence}}'
          },
          {
            name: 'metadata[order_id]',
            value: '{{params.metadata}}'
          },
          {
            name: 'submit',
            value: '{{params.submit}}'
          }
        ],
        response_type: 'json',
        response_path: '',
        request_tokens: {}
      }
    },
    update_parameters: [
      {
        name: 'id',
        verbose_name: 'id',
        description: '',
        field: 'text',
        required: true,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'evidence',
        verbose_name: 'evidence',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'metadata',
        verbose_name: 'metadata',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'submit',
        verbose_name: 'submit',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      }
    ],
    update_parameters_use_defaults: false,
    delete_query: {
      query_type: 'http',
      http_query: {
        method: 'POST',
        url: 'https://api.stripe.com/v1/disputes/{{params.id}}/close',
        auth_params: {},
        body_type: 'JSON',
        body: '',
        headers: [
          {
            name: 'Authorization',
            value: 'Bearer {-api_key-}'
          }
        ],
        query_params: [],
        response_type: 'json',
        response_path: '',
        request_tokens: {}
      }
    },
    delete_parameters: [
      {
        name: 'id',
        verbose_name: 'dispute_id',
        description: '',
        field: 'text',
        required: true,
        default_type: 'value',
        default_value: '',
        params: {}
      }
    ],
    delete_parameters_use_defaults: false,
    aggregate_parameters: [],
    group_parameters: [],
    sync: false,
    sync_finished: false,
    params: {},
    draft: false,
    deleted: false
  },
  {
    project: '{{project}}',
    resource: '{{resource}}',
    model: 'invoices',
    verbose_name: 'Invoices',
    verbose_name_plural: 'Invoices',
    fields: [
      {
        name: 'id',
        verbose_name: 'id',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'object',
        verbose_name: 'object',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'account_country',
        verbose_name: 'account country',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'account_name',
        verbose_name: 'account name',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'amount_due',
        verbose_name: 'amount due',
        field: 'number',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'amount_paid',
        verbose_name: 'amount paid',
        field: 'number',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'amount_remaining',
        verbose_name: 'amount remaining',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'application_fee_amount',
        verbose_name: 'application fee amount',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'attempt_count',
        verbose_name: 'attempt count',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'attempted',
        verbose_name: 'attempted',
        field: 'boolean',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'auto_advance',
        verbose_name: 'auto advance',
        field: 'boolean',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'billing_reason',
        verbose_name: 'billing reason',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'charge',
        verbose_name: 'charge',
        field: 'related_model',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {
          related_model: {
            model: '{{resource}}.charges'
          },
          custom_primary_key: 'id'
        }
      },
      {
        name: 'collection_method',
        verbose_name: 'collection method',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'created',
        verbose_name: 'created',
        field: 'datetime',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {
          date: true,
          time: true,
          date_format: 'local',
          time_format: 'hour_minute_24',
          output_format: 'timestamp'
        }
      },
      {
        name: 'currency',
        verbose_name: 'currency',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'custom_fields',
        verbose_name: 'custom fields',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'customer',
        verbose_name: 'customer',
        field: 'related_model',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {
          related_model: {
            model: '{{resource}}.customers'
          },
          custom_primary_key: 'id'
        }
      },
      {
        name: 'customer_address',
        verbose_name: 'customer address',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'customer_email',
        verbose_name: 'customer email',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'customer_name',
        verbose_name: 'customer name',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'customer_phone',
        verbose_name: 'customer phone',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'customer_shipping',
        verbose_name: 'customer shipping',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'customer_tax_exempt',
        verbose_name: 'customer tax exempt',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'customer_tax_ids',
        verbose_name: 'customer tax ids',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'default_payment_method',
        verbose_name: 'default payment method',
        field: 'related_model',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {
          related_model: {
            model: '{{resource}}.payment_methods'
          },
          custom_primary_key: 'id'
        }
      },
      {
        name: 'default_source',
        verbose_name: 'default source',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'default_tax_rates',
        verbose_name: 'default tax rates',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'description',
        verbose_name: 'description',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'discount',
        verbose_name: 'discount',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'discounts',
        verbose_name: 'discounts',
        field: 'text',
        required: true,
        null: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'due_date',
        verbose_name: 'due date',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'ending_balance',
        verbose_name: 'ending balance',
        field: 'number',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {
          type: 'integer',
          fraction: 2
        }
      },
      {
        name: 'footer',
        verbose_name: 'footer',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'hosted_invoice_url',
        verbose_name: 'hosted invoice url',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'invoice_pdf',
        verbose_name: 'invoice pdf',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'lines',
        verbose_name: 'lines',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'livemode',
        verbose_name: 'livemode',
        field: 'boolean',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'metadata',
        verbose_name: 'metadata',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'next_payment_attempt',
        verbose_name: 'next payment attempt',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'number',
        verbose_name: 'number',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'paid',
        verbose_name: 'paid',
        field: 'boolean',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'payment_intent',
        verbose_name: 'payment intent',
        field: 'related_model',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {
          related_model: {
            model: '{{resource}}.payment_intents'
          },
          custom_primary_key: 'id'
        }
      },
      {
        name: 'period_end',
        verbose_name: 'period end',
        field: 'datetime',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {
          date: true,
          time: true,
          date_format: 'local',
          time_format: 'hour_minute_24',
          output_format: 'timestamp'
        }
      },
      {
        name: 'period_start',
        verbose_name: 'period start',
        field: 'datetime',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {
          date: true,
          time: true,
          date_format: 'local',
          time_format: 'hour_minute_24',
          output_format: 'timestamp'
        }
      },
      {
        name: 'post_payment_credit_notes_amount',
        verbose_name: 'post payment credit notes amount',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'pre_payment_credit_notes_amount',
        verbose_name: 'pre payment credit notes amount',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'receipt_number',
        verbose_name: 'receipt number',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'starting_balance',
        verbose_name: 'starting balance',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'statement_descriptor',
        verbose_name: 'statement descriptor',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'status',
        verbose_name: 'status',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'status_transitions',
        verbose_name: 'status transitions',
        field: 'json',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'subscription',
        verbose_name: 'subscription',
        field: 'related_model',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {
          related_model: {
            model: '{{resource}}.subscriptions'
          },
          custom_primary_key: 'id'
        }
      },
      {
        name: 'subtotal',
        verbose_name: 'subtotal',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'tax',
        verbose_name: 'tax',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'tax_percent',
        verbose_name: 'tax percent',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'total',
        verbose_name: 'total',
        field: 'number',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {
          type: 'integer',
          fraction: 2
        }
      },
      {
        name: 'total_discount_amounts',
        verbose_name: 'total_discount_amounts',
        field: 'text',
        required: true,
        null: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'total_tax_amounts',
        verbose_name: 'total tax amounts',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'transfer_data',
        verbose_name: 'transfer_data',
        field: 'text',
        required: true,
        null: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'webhooks_delivered_at',
        verbose_name: 'webhooks delivered at',
        field: 'datetime',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {
          date: true,
          time: true,
          date_format: 'local',
          time_format: 'hour_minute_24',
          output_format: 'timestamp'
        }
      }
    ],
    flex_fields: [],
    segments: [],
    primary_key_field: 'id',
    relations: [],
    description: 'Retrieves the full list of line items of the invoice.',
    get_query: {
      query_type: 'http',
      http_query: {
        method: 'GET',
        url: 'https://api.stripe.com/v1/invoices',
        auth_params: {},
        body_type: 'JSON',
        body: '',
        headers: [
          {
            name: 'Authorization',
            value: 'Bearer {-api_key-}'
          }
        ],
        query_params: [
          {
            name: 'limit',
            value: '{{paging.limit}}'
          },
          {
            name: 'customer',
            value: '{{params.customer}}'
          },
          {
            name: 'status',
            value: '{{params.status}}'
          },
          {
            name: 'subscription',
            value: '{{params.subscription}}'
          },
          {
            name: 'ending_before',
            value: '{{paging.cursor_prev}}'
          },
          {
            name: 'starting_after',
            value: '{{paging.cursor_next}}'
          }
        ],
        response_type: 'json',
        response_transformer: "// add custom transformation here\nreturn data['data'];",
        request_tokens: {}
      },
      pagination: 'cursor',
      pagination_has_more_function: "// ex. data['hasMore']\nreturn data['has_more']",
      pagination_cursor_next_function:
        "// ex. data['next_cursor']\nreturn data['data'] && data['data'].length ? data['data'][data['data'].length - 1].id : undefined;",
      pagination_total_path: [],
      pagination_cursor_next_path: [],
      pagination_has_more_pages_path: [],
      pagination_has_more_total_pages_path: [],
      pagination_has_more_total_records_path: [],
      sorting: false,
      sorting_fields: []
    },
    get_parameters: [
      {
        name: 'customer',
        verbose_name: 'customer',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'status',
        verbose_name: 'status',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'subscription',
        verbose_name: 'subscription',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      }
    ],
    get_inputs: [],
    get_detail_query: {
      query_type: 'http',
      http_query: {
        method: 'GET',
        url: 'https://api.stripe.com/v1/invoices/{{params.invoice_id}}',
        auth_params: {},
        body_type: 'JSON',
        body: '',
        headers: [
          {
            name: 'Authorization',
            value: 'Bearer {-api_key-}'
          }
        ],
        query_params: [],
        response_type: 'json',
        response_path: '',
        request_tokens: {}
      }
    },
    get_detail_parameters: [
      {
        name: 'invoice_id',
        verbose_name: 'invoice_id',
        description: '',
        field: 'text',
        required: true,
        default_type: 'value',
        default_value: '',
        params: {}
      }
    ],
    get_detail_parameters_use_defaults: false,
    create_query: {
      query_type: 'http',
      http_query: {
        method: 'POST',
        url: 'https://api.stripe.com/v1/invoices',
        auth_params: {},
        body_type: 'Raw',
        body: '',
        headers: [
          {
            name: 'Authorization',
            value: 'Bearer {-api_key-}'
          }
        ],
        query_params: [
          {
            name: 'customer',
            value: '{{params.customer}}'
          },
          {
            name: 'auto_advance',
            value: '{{params.auto_advance}}'
          },
          {
            name: 'collection_method',
            value: '{{params.collection_method}}'
          },
          {
            name: 'description',
            value: '{{params.description}}'
          },
          {
            name: 'metadata[order_id]',
            value: '{{params.metadata}}'
          },
          {
            name: 'subscription',
            value: '{{params.subscription}}'
          }
        ],
        response_type: 'json',
        response_path: '',
        request_tokens: {}
      }
    },
    create_parameters: [
      {
        name: 'customer',
        verbose_name: 'customer',
        description: '',
        field: 'text',
        required: true,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'auto_advance',
        verbose_name: 'auto_advance',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'collection_method',
        verbose_name: 'collection_method',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'description',
        verbose_name: 'description',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'metadata',
        verbose_name: 'metadata',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'subscription',
        verbose_name: 'subscription',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      }
    ],
    create_parameters_use_defaults: false,
    update_query: {
      query_type: 'http',
      http_query: {
        method: 'POST',
        url: 'https://api.stripe.com/v1/invoices/{{params.id}}',
        auth_params: {},
        body_type: 'JSON',
        body: '',
        headers: [
          {
            name: 'Authorization',
            value: 'Bearer {-api_key-}'
          }
        ],
        query_params: [
          {
            name: 'auto_advance',
            value: '{{params.auto_advance}}'
          },
          {
            name: 'collection_method',
            value: '{{params.collection_method}}'
          },
          {
            name: 'description',
            value: '{{params.description}}'
          },
          {
            name: 'metadata[order_id]',
            value: '{{params.metadata}}'
          }
        ],
        response_type: 'json',
        response_path: '',
        request_tokens: {}
      }
    },
    update_parameters: [
      {
        name: 'id',
        verbose_name: 'Invoice_id',
        description: '',
        field: 'text',
        required: true,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'auto_advance',
        verbose_name: 'auto_advance',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'collection_method',
        verbose_name: 'collection_method',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'description',
        verbose_name: 'description',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'metadata',
        verbose_name: 'metadata',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      }
    ],
    update_parameters_use_defaults: false,
    delete_query: {
      query_type: 'http',
      http_query: {
        method: 'DELETE',
        url: 'https://api.stripe.com/v1/invoices/{{params.id}}',
        auth_params: {},
        body_type: 'JSON',
        body: '',
        headers: [
          {
            name: 'Authorization',
            value: 'Bearer {-api_key-}'
          }
        ],
        query_params: [],
        response_type: 'json',
        response_path: '',
        request_tokens: {}
      }
    },
    delete_parameters: [
      {
        name: 'id',
        verbose_name: 'invoice_id',
        description: '',
        field: 'text',
        required: true,
        default_type: 'value',
        default_value: '',
        params: {}
      }
    ],
    delete_parameters_use_defaults: false,
    aggregate_parameters: [],
    group_parameters: [],
    sync: false,
    sync_finished: false,
    params: {},
    draft: false,
    deleted: false
  },
  {
    project: '{{project}}',
    resource: '{{resource}}',
    model: 'payment_intents',
    verbose_name: 'Payment Intents',
    verbose_name_plural: 'Payment Intents',
    fields: [
      {
        name: 'id',
        verbose_name: 'id',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'object',
        verbose_name: 'object',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'amount',
        verbose_name: 'amount',
        field: 'number',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {
          type: 'integer',
          fraction: 2
        }
      },
      {
        name: 'amount_capturable',
        verbose_name: 'amount capturable',
        field: 'number',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'amount_received',
        verbose_name: 'amount received',
        field: 'number',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'application',
        verbose_name: 'application',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'application_fee_amount',
        verbose_name: 'application fee amount',
        field: 'number',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'canceled_at',
        verbose_name: 'canceled at',
        field: 'datetime',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {
          date: true,
          time: true
        }
      },
      {
        name: 'cancellation_reason',
        verbose_name: 'cancellation reason',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'capture_method',
        verbose_name: 'capture method',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'charges',
        verbose_name: 'charges',
        field: 'related_model',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {
          related_model: {
            model: '{{resource}}.charges'
          },
          custom_primary_key: 'id'
        }
      },
      {
        name: 'client_secret',
        verbose_name: 'client secret',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'confirmation_method',
        verbose_name: 'confirmation method',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'created',
        verbose_name: 'created',
        field: 'datetime',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {
          date: true,
          time: true
        }
      },
      {
        name: 'currency',
        verbose_name: 'currency',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'customer',
        verbose_name: 'customer',
        field: 'related_model',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {
          related_model: {
            model: '{{resource}}.customers'
          },
          custom_primary_key: 'id'
        }
      },
      {
        name: 'description',
        verbose_name: 'description',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'invoice',
        verbose_name: 'invoice',
        field: 'related_model',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {
          related_model: {
            model: '{{resource}}.invoices'
          },
          custom_primary_key: 'id'
        }
      },
      {
        name: 'last_payment_error',
        verbose_name: 'last payment error',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'livemode',
        verbose_name: 'livemode',
        field: 'boolean',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'metadata',
        verbose_name: 'metadata',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'next_action',
        verbose_name: 'next action',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'on_behalf_of',
        verbose_name: 'on behalf of',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'payment_method',
        verbose_name: 'payment method',
        field: 'related_model',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {
          related_model: {
            model: '{{resource}}.payment_methods'
          },
          custom_primary_key: 'id'
        }
      },
      {
        name: 'payment_method_options',
        verbose_name: 'payment method options',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'payment_method_types',
        verbose_name: 'payment method types',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'receipt_email',
        verbose_name: 'receipt email',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'review',
        verbose_name: 'review',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'setup_future_usage',
        verbose_name: 'setup future usage',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'shipping',
        verbose_name: 'shipping',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'source',
        verbose_name: 'source',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'statement_descriptor',
        verbose_name: 'statement descriptor',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'statement_descriptor_suffix',
        verbose_name: 'statement descriptor suffix',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'status',
        verbose_name: 'status',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'transfer_data',
        verbose_name: 'transfer data',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'transfer_group',
        verbose_name: 'transfer group',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      }
    ],
    flex_fields: [],
    segments: [],
    primary_key_field: 'id',
    relations: [],
    description: 'Returns a list of PaymentIntents.',
    get_query: {
      query_type: 'http',
      http_query: {
        method: 'GET',
        url: 'https://api.stripe.com/v1/payment_intents',
        auth_params: {},
        body_type: 'JSON',
        body: '',
        headers: [
          {
            name: 'Authorization',
            value: 'Bearer {-api_key-}'
          }
        ],
        query_params: [
          {
            name: 'limit',
            value: '{{paging.limit}}'
          },
          {
            name: 'customer',
            value: '{{params.customer}}'
          },
          {
            name: 'ending_before',
            value: '{{paging.cursor_prev}}'
          },
          {
            name: 'starting_after',
            value: '{{paging.cursor_next}}'
          }
        ],
        response_type: 'json',
        response_transformer: "// add custom transformation here\nreturn data['data']",
        request_tokens: {}
      },
      pagination: 'cursor',
      pagination_has_more_function: "// ex. data['hasMore']\nreturn data['has_more']",
      pagination_cursor_next_function:
        "// ex. data['next_cursor']\nreturn data['data'] && data['data'].length ? data['data'][data['data'].length - 1].id : undefined;",
      pagination_total_path: [],
      pagination_cursor_next_path: [],
      pagination_has_more_pages_path: [],
      pagination_has_more_total_pages_path: [],
      pagination_has_more_total_records_path: [],
      sorting: false,
      sorting_fields: []
    },
    get_parameters: [
      {
        name: 'customer',
        verbose_name: 'customer',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      }
    ],
    get_inputs: [],
    get_detail_query: {
      query_type: 'http',
      http_query: {
        method: 'GET',
        url: 'https://api.stripe.com/v1/payment_intents/{{params.payment_id}}',
        auth_params: {},
        body_type: 'JSON',
        body: '',
        headers: [
          {
            name: 'Authorization',
            value: 'Bearer {-api_key-}'
          }
        ],
        query_params: [],
        response_type: 'json',
        response_path: '',
        request_tokens: {}
      }
    },
    get_detail_parameters: [
      {
        name: 'payment_id',
        verbose_name: 'payment_id',
        description: '',
        field: 'text',
        required: true,
        default_type: 'value',
        default_value: '',
        params: {}
      }
    ],
    get_detail_parameters_use_defaults: false,
    create_query: {
      query_type: 'http',
      http_query: {
        method: 'POST',
        url: 'https://api.stripe.com/v1/payment_intents',
        auth_params: {},
        body_type: 'JSON',
        body: '',
        headers: [
          {
            name: 'Authorization',
            value: 'Bearer {-api_key-}'
          }
        ],
        query_params: [
          {
            name: 'amount',
            value: '{{params.amount}}'
          },
          {
            name: 'currency',
            value: '{{params.currency}}'
          },
          {
            name: 'customer',
            value: '{{params.customer}}'
          },
          {
            name: 'description',
            value: '{{params.description}}'
          },
          {
            name: 'metadata[order_id]',
            value: '{{params.metadata}}'
          },
          {
            name: 'payment_method',
            value: '{{params.payment_method}}'
          },
          {
            name: 'payment_method_types[]',
            value: '{{params.payment_method_types}}'
          },
          {
            name: 'receipt_email',
            value: '{{params.receipt_email}}'
          },
          {
            name: 'setup_future_usage',
            value: '{{params.setup_future_usage}}'
          },
          {
            name: 'shipping',
            value: '{{params.shipping}}'
          },
          {
            name: 'statement_descriptor',
            value: '{{params.statement_descriptor}}'
          },
          {
            name: 'statement_descriptor_suffix',
            value: '{{params.statement_descriptor_suffix}}'
          }
        ],
        response_type: 'json',
        response_path: '',
        request_tokens: {}
      }
    },
    create_parameters: [
      {
        name: 'amount',
        verbose_name: 'amount',
        description: '',
        field: 'text',
        required: true,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'currency',
        verbose_name: 'currency',
        description: '',
        field: 'text',
        required: true,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'customer',
        verbose_name: 'customer',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'description',
        verbose_name: 'description',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'metadata',
        verbose_name: 'metadata',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'payment_method',
        verbose_name: 'payment_method',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'payment_method_types',
        verbose_name: 'payment_method_types',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'receipt_email',
        verbose_name: 'receipt_email',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'setup_future_usage',
        verbose_name: 'setup_future_usage',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'shipping',
        verbose_name: 'shipping',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'statement_descriptor',
        verbose_name: 'statement_descriptor',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'statement_descriptor_suffix',
        verbose_name: 'statement_descriptor_suffix',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      }
    ],
    create_parameters_use_defaults: false,
    update_query: {
      query_type: 'http',
      http_query: {
        method: 'POST',
        url: 'https://api.stripe.com/v1/payment_intents/{{params.id}}',
        auth_params: {},
        body_type: 'JSON',
        body: '',
        headers: [
          {
            name: 'Authorization',
            value: 'Bearer {-api_key-}'
          }
        ],
        query_params: [
          {
            name: 'metadata[order_id]',
            value: '{{record.metadata}}'
          },
          {
            name: 'amount',
            value: '{{record.amount}}'
          },
          {
            name: 'currency',
            value: '{{record.currency}}'
          },
          {
            name: 'customer',
            value: '{{record.customer}}'
          },
          {
            name: 'description',
            value: '{{record.description}}'
          },
          {
            name: 'payment_method',
            value: '{{record.payment_method}}'
          },
          {
            name: 'payment_method_types',
            value: '{{record.payment_method_types}}'
          },
          {
            name: 'receipt_email',
            value: '{{record.receipt_email}}'
          },
          {
            name: 'setup_future_usage',
            value: '{{record.setup_future_usage}}'
          },
          {
            name: 'shipping',
            value: '{{record.shipping}}'
          },
          {
            name: 'statement_descriptor',
            value: '{{record.statement_descriptor}}'
          },
          {
            name: 'statement_descriptor_suffix',
            value: '{{record.statement_descriptor_suffix}}'
          }
        ],
        response_type: 'json',
        response_path: '',
        request_tokens: {}
      }
    },
    update_parameters: [
      {
        name: 'id',
        verbose_name: 'id',
        description: '',
        field: 'text',
        required: true,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'metadata',
        verbose_name: 'metadata',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'amount',
        verbose_name: 'amount',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'currency',
        verbose_name: 'currency',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'customer',
        verbose_name: 'customer',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'description',
        verbose_name: 'description',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'payment_method',
        verbose_name: 'payment_method',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'payment_method_types',
        verbose_name: 'payment_method_types',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'receipt_email',
        verbose_name: 'receipt_email',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'setup_future_usage',
        verbose_name: 'setup_future_usage',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'shipping',
        verbose_name: 'shipping',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'statement_descriptor',
        verbose_name: 'statement_descriptor',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'statement_descriptor_suffix',
        verbose_name: 'statement_descriptor_suffix',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      }
    ],
    update_parameters_use_defaults: false,
    delete_query: {
      query_type: 'http',
      http_query: {
        method: 'POST',
        url: 'https://api.stripe.com/v1/payment_intents/{{params.payment_id}}/cancel',
        auth_params: {},
        body_type: 'JSON',
        body: '',
        headers: [
          {
            name: 'Authorization',
            value: 'Bearer {-api_key-}'
          }
        ],
        query_params: [
          {
            name: 'cancellation_reason',
            value: '{{params.cancellation_reason}}'
          }
        ],
        response_type: 'json',
        response_path: '',
        request_tokens: {}
      }
    },
    delete_parameters: [
      {
        name: 'payment_id',
        verbose_name: 'payment_id',
        description: '',
        field: 'text',
        required: true,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'cancellation_reason',
        verbose_name: 'cancellation_reason',
        description: '',
        field: 'text',
        required: true,
        default_type: 'value',
        default_value: '',
        params: {}
      }
    ],
    delete_parameters_use_defaults: false,
    aggregate_parameters: [],
    group_parameters: [],
    sync: false,
    sync_finished: false,
    params: {},
    draft: false,
    deleted: false
  },
  {
    project: '{{project}}',
    resource: '{{resource}}',
    model: 'payment_methods',
    verbose_name: 'Payment Methods',
    verbose_name_plural: 'Payment Methods',
    fields: [
      {
        name: 'id',
        verbose_name: 'id',
        field: 'text',
        required: true,
        null: false,
        editable: true,
        params: {}
      },
      {
        name: 'object',
        verbose_name: 'object',
        field: 'text',
        required: true,
        null: false,
        editable: true,
        params: {}
      },
      {
        name: 'billing_details',
        verbose_name: 'billing_details',
        field: 'json',
        required: true,
        null: false,
        editable: true,
        params: {
          structure: {
            name: null,
            type: 'object',
            label: null,
            params: {
              items: [
                {
                  name: 'address',
                  type: 'object',
                  label: 'address',
                  params: {
                    items: [
                      {
                        name: 'city',
                        type: 'field',
                        label: 'city',
                        params: {
                          name: 'city',
                          field: 'text',
                          label: 'city',
                          params: {},
                          required: false
                        }
                      },
                      {
                        name: 'country',
                        type: 'field',
                        label: 'country',
                        params: {
                          name: 'country',
                          field: 'text',
                          label: 'country',
                          params: {},
                          required: false
                        }
                      },
                      {
                        name: 'line1',
                        type: 'field',
                        label: 'line1',
                        params: {
                          name: 'line1',
                          field: 'text',
                          label: 'line1',
                          params: {},
                          required: false
                        }
                      },
                      {
                        name: 'line2',
                        type: 'field',
                        label: 'line2',
                        params: {
                          name: 'line2',
                          field: 'text',
                          label: 'line2',
                          params: {},
                          required: false
                        }
                      },
                      {
                        name: 'postal_code',
                        type: 'field',
                        label: 'postal_code',
                        params: {
                          name: 'postal_code',
                          field: 'text',
                          label: 'postal_code',
                          params: {},
                          required: false
                        }
                      },
                      {
                        name: 'state',
                        type: 'field',
                        label: 'state',
                        params: {
                          name: 'state',
                          field: 'text',
                          label: 'state',
                          params: {},
                          required: false
                        }
                      }
                    ]
                  }
                },
                {
                  name: 'email',
                  type: 'field',
                  label: 'email',
                  params: {
                    name: 'email',
                    field: 'text',
                    label: 'email',
                    params: {},
                    required: false
                  }
                },
                {
                  name: 'name',
                  type: 'field',
                  label: 'name',
                  params: {
                    name: 'name',
                    field: 'text',
                    label: 'name',
                    params: {},
                    required: false
                  }
                },
                {
                  name: 'phone',
                  type: 'field',
                  label: 'phone',
                  params: {
                    name: 'phone',
                    field: 'text',
                    label: 'phone',
                    params: {},
                    required: false
                  }
                }
              ]
            }
          },
          display_fields: true
        }
      },
      {
        name: 'card',
        verbose_name: 'card',
        field: 'json',
        required: true,
        null: false,
        editable: true,
        params: {
          structure: {
            name: null,
            type: 'object',
            label: null,
            params: {
              items: [
                {
                  name: 'brand',
                  type: 'field',
                  label: 'brand',
                  params: {
                    name: 'brand',
                    field: 'text',
                    label: 'brand',
                    params: {},
                    required: false
                  }
                },
                {
                  name: 'checks',
                  type: 'object',
                  label: 'checks',
                  params: {
                    items: [
                      {
                        name: 'address_line1_check',
                        type: 'field',
                        label: 'address_line1_check',
                        params: {
                          name: 'address_line1_check',
                          field: 'text',
                          label: 'address_line1_check',
                          params: {},
                          required: false
                        }
                      },
                      {
                        name: 'address_postal_code_check',
                        type: 'field',
                        label: 'address_postal_code_check',
                        params: {
                          name: 'address_postal_code_check',
                          field: 'text',
                          label: 'address_postal_code_check',
                          params: {},
                          required: false
                        }
                      },
                      {
                        name: 'cvc_check',
                        type: 'field',
                        label: 'cvc_check',
                        params: {
                          name: 'cvc_check',
                          field: 'text',
                          label: 'cvc_check',
                          params: {},
                          required: false
                        }
                      }
                    ]
                  }
                },
                {
                  name: 'country',
                  type: 'field',
                  label: 'country',
                  params: {
                    name: 'country',
                    field: 'text',
                    label: 'country',
                    params: {},
                    required: false
                  }
                },
                {
                  name: 'exp_month',
                  type: 'field',
                  label: 'exp_month',
                  params: {
                    name: 'exp_month',
                    field: 'number',
                    label: 'exp_month',
                    params: {},
                    required: false
                  }
                },
                {
                  name: 'exp_year',
                  type: 'field',
                  label: 'exp_year',
                  params: {
                    name: 'exp_year',
                    field: 'number',
                    label: 'exp_year',
                    params: {},
                    required: false
                  }
                },
                {
                  name: 'fingerprint',
                  type: 'field',
                  label: 'fingerprint',
                  params: {
                    name: 'fingerprint',
                    field: 'text',
                    label: 'fingerprint',
                    params: {},
                    required: false
                  }
                },
                {
                  name: 'funding',
                  type: 'field',
                  label: 'funding',
                  params: {
                    name: 'funding',
                    field: 'text',
                    label: 'funding',
                    params: {},
                    required: false
                  }
                },
                {
                  name: 'generated_from',
                  type: 'field',
                  label: 'generated_from',
                  params: {
                    name: 'generated_from',
                    field: 'text',
                    label: 'generated_from',
                    params: {},
                    required: false
                  }
                },
                {
                  name: 'last4',
                  type: 'field',
                  label: 'last4',
                  params: {
                    name: 'last4',
                    field: 'text',
                    label: 'last4',
                    params: {},
                    required: false
                  }
                },
                {
                  name: 'networks',
                  type: 'object',
                  label: 'networks',
                  params: {
                    items: [
                      {
                        name: 'available',
                        type: 'array',
                        label: 'available',
                        params: {
                          item: {
                            name: 'available',
                            type: 'field',
                            label: 'available',
                            params: {
                              name: 'available',
                              field: 'text',
                              label: 'available',
                              params: {},
                              required: false
                            }
                          }
                        }
                      },
                      {
                        name: 'preferred',
                        type: 'field',
                        label: 'preferred',
                        params: {
                          name: 'preferred',
                          field: 'text',
                          label: 'preferred',
                          params: {},
                          required: false
                        }
                      }
                    ]
                  }
                },
                {
                  name: 'three_d_secure_usage',
                  type: 'object',
                  label: 'three_d_secure_usage',
                  params: {
                    items: [
                      {
                        name: 'supported',
                        type: 'field',
                        label: 'supported',
                        params: {
                          name: 'supported',
                          field: 'boolean',
                          label: 'supported',
                          params: {},
                          required: false
                        }
                      }
                    ]
                  }
                },
                {
                  name: 'wallet',
                  type: 'field',
                  label: 'wallet',
                  params: {
                    name: 'wallet',
                    field: 'text',
                    label: 'wallet',
                    params: {},
                    required: false
                  }
                }
              ]
            }
          },
          display_fields: true
        }
      },
      {
        name: 'created',
        verbose_name: 'created',
        field: 'number',
        required: true,
        null: false,
        editable: true,
        params: {}
      },
      {
        name: 'customer',
        verbose_name: 'customer',
        field: 'text',
        required: true,
        null: false,
        editable: true,
        params: {}
      },
      {
        name: 'livemode',
        verbose_name: 'livemode',
        field: 'boolean',
        required: true,
        null: false,
        editable: true,
        params: {}
      },
      {
        name: 'metadata',
        verbose_name: 'metadata',
        field: 'json',
        required: true,
        null: false,
        editable: true,
        params: {
          structure: {
            name: null,
            type: 'object',
            label: null,
            params: {
              items: []
            }
          },
          display_fields: true
        }
      },
      {
        name: 'type',
        verbose_name: 'type',
        field: 'text',
        required: true,
        null: false,
        editable: true,
        params: {}
      }
    ],
    flex_fields: [],
    segments: [],
    primary_key_field: 'id',
    relations: [],
    description: 'Returns a list of PaymentMethods for a given Customer.',
    get_query: {
      query_type: 'http',
      http_query: {
        method: 'GET',
        url: 'https://api.stripe.com/v1/payment_methods',
        auth_params: {},
        body_type: 'JSON',
        body: '',
        headers: [
          {
            name: 'Authorization',
            value: 'Bearer {-api_key-}'
          }
        ],
        query_params: [
          {
            name: 'customer',
            value: '{{params.customer}}'
          },
          {
            name: 'type',
            value: '{{params.type}}'
          },
          {
            name: 'limit',
            value: '{{paging.limit}}'
          },
          {
            name: 'ending_before',
            value: '{{paging.cursor_prev}}'
          },
          {
            name: 'starting_after',
            value: '{{paging.cursor_next}}'
          }
        ],
        response_type: 'json',
        response_transformer: "// add custom transformation here\nreturn data['data']",
        request_tokens: {}
      },
      pagination: 'cursor',
      pagination_has_more_function: "// ex. data['hasMore']\nreturn data['has_more']",
      pagination_cursor_next_function:
        "// ex. data['next_cursor']\nreturn data['data'] && data['data'].length ? data['data'][data['data'].length - 1].id : undefined;",
      pagination_total_path: [],
      pagination_cursor_next_path: [],
      pagination_has_more_pages_path: [],
      pagination_has_more_total_pages_path: [],
      pagination_has_more_total_records_path: [],
      sorting: false,
      sorting_fields: []
    },
    get_parameters: [
      {
        name: 'customer',
        verbose_name: 'customer',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'type',
        verbose_name: 'type',
        description: '',
        field: 'text',
        required: true,
        default_type: 'value',
        default_value: '',
        params: {}
      }
    ],
    get_inputs: [],
    get_detail_query: {
      query_type: 'http',
      http_query: {
        method: 'GET',
        url: 'https://api.stripe.com/v1/payment_methods/{{params.id}}',
        auth_params: {},
        body_type: 'JSON',
        body: '',
        headers: [
          {
            name: 'Authorization',
            value: 'Bearer {-api_key-}'
          }
        ],
        query_params: [],
        response_type: 'json',
        response_path: '',
        request_tokens: {}
      }
    },
    get_detail_parameters: [
      {
        name: 'id',
        verbose_name: 'id',
        description: '',
        field: 'text',
        required: true,
        default_type: 'value',
        default_value: '',
        params: {}
      }
    ],
    get_detail_parameters_use_defaults: false,
    create_query: {
      query_type: 'http',
      http_query: {
        method: 'POST',
        url: 'https://api.stripe.com/v1/payment_methods',
        auth_params: {},
        body_type: 'JSON',
        body: '',
        headers: [
          {
            name: 'Authorization',
            value: 'Bearer {-api_key-}'
          }
        ],
        query_params: [
          {
            name: 'type',
            value: '{{params.type}}'
          },
          {
            name: 'billing_details',
            value: '{{params.billing_details}}'
          },
          {
            name: 'metadata[order_id]',
            value: '{{params.metadata}}'
          },
          {
            name: 'card[number]',
            value: '{{params.card_number}}'
          },
          {
            name: 'card[exp_month]',
            value: '{{params.card_exp_month}}'
          },
          {
            name: 'card[exp_year]',
            value: '{{params.card_exp_year}}'
          },
          {
            name: 'card[cvc]',
            value: '{{params.card_cvc}}'
          }
        ],
        response_type: 'json',
        response_path: '',
        request_tokens: {}
      }
    },
    create_parameters: [
      {
        name: 'billing_details',
        verbose_name: 'billing_details',
        field: 'text',
        required: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'type',
        verbose_name: 'type',
        description: '',
        field: 'text',
        required: true,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'metadata',
        verbose_name: 'metadata',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'card_number',
        verbose_name: 'card_number',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'card_exp_month',
        verbose_name: 'card_exp_month',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'card_exp_year',
        verbose_name: 'card_exp_year',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'card_cvc',
        verbose_name: 'card_cvc',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      }
    ],
    create_parameters_use_defaults: false,
    update_query: {
      query_type: 'http',
      http_query: {
        method: 'POST',
        url: 'https://api.stripe.com/v1/payment_methods/{{params.id}}',
        auth_params: {},
        body_type: 'JSON',
        body: '',
        headers: [
          {
            name: 'Authorization',
            value: 'Bearer {-api_key-}'
          }
        ],
        query_params: [
          {
            name: 'billing_details',
            value: '{{params.billing_details}}'
          },
          {
            name: 'metadata[order_id]',
            value: '{{params.metadata}}'
          },
          {
            name: 'card[exp_month]',
            value: '{{params.exp_month}}'
          },
          {
            name: 'card[exp_year]',
            value: '{{params.exp_year}}'
          }
        ],
        response_type: 'json',
        response_path: '',
        request_tokens: {}
      }
    },
    update_parameters: [
      {
        name: 'id',
        verbose_name: 'id',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'metadata',
        verbose_name: 'metadata',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'billing_details',
        verbose_name: 'billing_details',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'exp_month',
        verbose_name: 'exp_month',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'exp_year',
        verbose_name: 'exp_year',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      }
    ],
    update_parameters_use_defaults: false,
    delete_query: {
      query_type: 'http',
      http_query: {
        method: 'POST',
        url: 'https://api.stripe.com/v1/payment_methods/{{params.id}}/detach ',
        auth_params: {},
        body_type: 'JSON',
        body: '',
        headers: [
          {
            name: 'Authorization',
            value: 'Bearer {-api_key-}'
          }
        ],
        query_params: [],
        response_type: 'json',
        response_path: '',
        request_tokens: {}
      }
    },
    delete_parameters: [
      {
        name: 'id',
        verbose_name: 'id',
        description: '',
        field: 'text',
        required: true,
        default_type: 'value',
        default_value: '',
        params: {}
      }
    ],
    delete_parameters_use_defaults: false,
    aggregate_parameters: [],
    group_parameters: [],
    sync: false,
    sync_finished: false,
    params: {},
    draft: false,
    deleted: false
  },
  {
    project: '{{project}}',
    resource: '{{resource}}',
    model: 'payments',
    verbose_name: 'Payments',
    verbose_name_plural: 'Payments',
    fields: [
      {
        name: 'id',
        verbose_name: 'id',
        field: 'text',
        required: true,
        null: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'object',
        verbose_name: 'object',
        field: 'text',
        required: true,
        null: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'amount',
        verbose_name: 'amount',
        field: 'number',
        required: true,
        null: false,
        default_type: 'value',
        params: {
          type: 'integer',
          fraction: 2
        }
      },
      {
        name: 'amount_refunded',
        verbose_name: 'amount refunded',
        field: 'number',
        required: true,
        null: false,
        default_type: 'value',
        params: {
          type: 'integer',
          fraction: 2
        }
      },
      {
        name: 'application',
        verbose_name: 'application',
        field: 'text',
        required: true,
        null: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'application_fee',
        verbose_name: 'application fee',
        field: 'text',
        required: true,
        null: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'application_fee_amount',
        verbose_name: 'application fee amount',
        field: 'text',
        required: true,
        null: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'balance_transaction',
        verbose_name: 'balance transaction',
        field: 'related_model',
        required: true,
        null: false,
        default_type: 'value',
        params: {
          related_model: {
            model: '{{resource}}.balance_transactions'
          },
          custom_primary_key: 'id'
        }
      },
      {
        name: 'billing_details',
        verbose_name: 'billing details',
        field: 'text',
        required: true,
        null: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'calculated_statement_descriptor',
        verbose_name: 'calculated_statement_descriptor',
        field: 'text',
        required: true,
        null: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'captured',
        verbose_name: 'captured',
        field: 'text',
        required: true,
        null: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'created',
        verbose_name: 'created',
        field: 'datetime',
        required: true,
        null: false,
        default_type: 'value',
        params: {
          date: true,
          time: true,
          date_format: 'local',
          time_format: 'hour_minute_24',
          output_format: 'timestamp'
        }
      },
      {
        name: 'currency',
        verbose_name: 'currency',
        field: 'select',
        required: true,
        null: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'customer',
        verbose_name: 'customer',
        field: 'related_model',
        required: true,
        null: false,
        filterable: true,
        sortable: true,
        default_type: 'value',
        params: {
          related_model: {
            model: '{{resource}}.customers'
          },
          custom_primary_key: 'id'
        }
      },
      {
        name: 'description',
        verbose_name: 'description',
        field: 'text',
        required: true,
        null: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'destination',
        verbose_name: 'destination',
        field: 'text',
        required: true,
        null: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'dispute',
        verbose_name: 'dispute',
        field: 'text',
        required: true,
        null: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'disputed',
        verbose_name: 'disputed',
        field: 'boolean',
        required: true,
        null: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'failure_code',
        verbose_name: 'failure code',
        field: 'text',
        required: true,
        null: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'failure_message',
        verbose_name: 'failure message',
        field: 'text',
        required: true,
        null: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'fraud_details',
        verbose_name: 'fraud details',
        field: 'text',
        required: true,
        null: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'invoice',
        verbose_name: 'invoice',
        field: 'related_model',
        required: true,
        null: false,
        default_type: 'value',
        params: {
          related_model: {
            model: '{{resource}}.invoices'
          },
          custom_primary_key: 'id'
        }
      },
      {
        name: 'livemode',
        verbose_name: 'livemode',
        field: 'text',
        required: true,
        null: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'metadata',
        verbose_name: 'metadata',
        field: 'text',
        required: true,
        null: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'on_behalf_of',
        verbose_name: 'on behalf of',
        field: 'text',
        required: true,
        null: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'order',
        verbose_name: 'order',
        field: 'text',
        required: true,
        null: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'outcome',
        verbose_name: 'outcome',
        field: 'text',
        required: true,
        null: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'paid',
        verbose_name: 'paid',
        field: 'text',
        required: true,
        null: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'payment_intent',
        verbose_name: 'payment intent',
        field: 'text',
        required: true,
        null: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'payment_method',
        verbose_name: 'payment method',
        field: 'related_model',
        required: true,
        null: false,
        default_type: 'value',
        params: {
          related_model: {
            model: '{{resource}}.payment_methods'
          },
          custom_primary_key: 'id'
        }
      },
      {
        name: 'payment_method_details',
        verbose_name: 'payment method details',
        field: 'text',
        required: true,
        null: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'receipt_email',
        verbose_name: 'receipt email',
        field: 'text',
        required: true,
        null: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'receipt_number',
        verbose_name: 'receipt number',
        field: 'text',
        required: true,
        null: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'receipt_url',
        verbose_name: 'receipt url',
        field: 'text',
        required: true,
        null: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'refunded',
        verbose_name: 'refunded',
        field: 'boolean',
        required: true,
        null: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'refunds',
        verbose_name: 'refunds',
        field: 'text',
        required: true,
        null: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'review',
        verbose_name: 'review',
        field: 'text',
        required: true,
        null: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'shipping',
        verbose_name: 'shipping',
        field: 'text',
        required: true,
        null: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'source',
        verbose_name: 'source',
        field: 'text',
        required: true,
        null: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'source_transfer',
        verbose_name: 'source transfer',
        field: 'text',
        required: true,
        null: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'statement_descriptor',
        verbose_name: 'statement descriptor',
        field: 'text',
        required: true,
        null: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'statement_descriptor_suffix',
        verbose_name: 'statement descriptor suffix',
        field: 'text',
        required: true,
        null: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'status',
        verbose_name: 'status',
        field: 'select',
        required: true,
        null: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'transfer_data',
        verbose_name: 'transfer data',
        field: 'text',
        required: true,
        null: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'transfer_group',
        verbose_name: 'transfer group',
        field: 'text',
        required: true,
        null: false,
        default_type: 'value',
        params: {}
      }
    ],
    flex_fields: [],
    segments: [],
    primary_key_field: 'id',
    relations: [],
    description: 'Returns a list of customer payments.',
    get_query: {
      query_type: 'http',
      http_query: {
        method: 'GET',
        url: 'https://api.stripe.com/v1/charges',
        auth_params: {},
        body_type: 'JSON',
        body: '',
        headers: [
          {
            name: 'Authorization',
            value: 'Bearer {-api_key-}'
          }
        ],
        query_params: [
          {
            name: 'customer',
            value: '{{params.customer_id}}'
          },
          {
            name: 'ending_before',
            value: '{{paging.cursor_prev}}'
          },
          {
            name: 'limit',
            value: '{{paging.limit}}'
          },
          {
            name: 'starting_after',
            value: '{{paging.cursor_next}}'
          }
        ],
        response_type: 'json',
        response_transformer: "// add custom transformation here\nreturn data['data'];",
        request_tokens: {}
      },
      pagination: 'cursor',
      pagination_has_more_function: "// ex. data['hasMore']\nreturn data['has_more']",
      pagination_cursor_next_function:
        "// ex. data['next_cursor']\nreturn data['data'] && data['data'].length ? data['data'][data['data'].length - 1].id : undefined;",
      pagination_total_path: [],
      pagination_cursor_next_path: [],
      pagination_has_more_pages_path: [],
      pagination_has_more_total_pages_path: [],
      pagination_has_more_total_records_path: [],
      sorting: false,
      sorting_fields: []
    },
    get_parameters: [
      {
        name: 'customer_id',
        verbose_name: 'customer_id',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {
          classes: ['select_fill', 'input_fill', 'input_segment-bottom', 'select_segment-bottom']
        }
      }
    ],
    get_inputs: [],
    get_detail_query: {
      query_type: 'http',
      http_query: {
        method: 'GET',
        url: 'https://api.stripe.com/v1/charges/{{params.charge_id}}',
        auth_params: {},
        body_type: 'JSON',
        body: '',
        headers: [
          {
            name: 'Authorization',
            value: 'Bearer {-api_key-}'
          }
        ],
        query_params: [],
        response_type: 'json',
        response_path: '',
        request_tokens: {}
      }
    },
    get_detail_parameters: [
      {
        name: 'charge_id',
        verbose_name: 'charge_id',
        description: '',
        field: 'text',
        required: true,
        default_type: 'value',
        default_value: '',
        params: {}
      }
    ],
    get_detail_parameters_use_defaults: false,
    create_query: {
      query_type: 'http',
      http_query: {
        method: 'POST',
        url: 'https://api.stripe.com/v1/charges',
        auth_params: {},
        body_type: 'JSON',
        body: '',
        headers: [
          {
            name: 'Authorization',
            value: 'Bearer {-api_key-}'
          }
        ],
        query_params: [
          {
            name: 'amount',
            value: '{{params.amount}}'
          },
          {
            name: 'currency',
            value: '{{params.currency}}'
          },
          {
            name: 'customer',
            value: '{{params.customer}}'
          },
          {
            name: 'description',
            value: '{{params.description}}'
          },
          {
            name: 'metadata[order_id]',
            value: '{{params.metadata}}'
          },
          {
            name: 'receipt_email',
            value: '{{params.receipt_email}}'
          },
          {
            name: 'shipping',
            value: '{{params.shipping}}'
          },
          {
            name: 'source',
            value: '{{params.source}}'
          },
          {
            name: 'statement_descriptor',
            value: '{{params.statement_descriptor}}'
          },
          {
            name: 'statement_descriptor_suffix',
            value: '{{params.statement_descriptor_suffix}}'
          }
        ],
        response_type: 'json',
        response_path: '',
        request_tokens: {}
      }
    },
    create_parameters: [
      {
        name: 'amount',
        verbose_name: 'amount',
        description: '',
        field: 'text',
        required: true,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'currency',
        verbose_name: 'currency',
        description: '',
        field: 'text',
        required: true,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'customer',
        verbose_name: 'customer',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'description',
        verbose_name: 'description',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'metadata',
        verbose_name: 'metadata',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'receipt_email',
        verbose_name: 'receipt_email',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'shipping',
        verbose_name: 'shipping',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'source',
        verbose_name: 'source',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'statement_descriptor',
        verbose_name: 'statement_descriptor',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'statement_descriptor_suffix',
        verbose_name: 'statement_descriptor_suffix',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      }
    ],
    create_parameters_use_defaults: false,
    update_query: {
      query_type: 'http',
      http_query: {
        method: 'POST',
        url: 'https://api.stripe.com/v1/charges/{{params.charge_id}}',
        auth_params: {},
        body_type: 'JSON',
        body: '',
        headers: [
          {
            name: 'Authorization',
            value: 'Bearer {-api_key-}'
          }
        ],
        query_params: [
          {
            name: 'customer',
            value: '{{params.customer}}'
          },
          {
            name: 'description',
            value: '{{params.description}}'
          },
          {
            name: 'metadata[order_id]',
            value: '{{params.metadata}}'
          },
          {
            name: 'receipt_email',
            value: '{{params.receipt_email}}'
          },
          {
            name: 'shipping',
            value: '{{params.shipping}}'
          }
        ],
        response_type: 'json',
        response_path: '',
        request_tokens: {}
      }
    },
    update_parameters: [
      {
        name: 'charge_id',
        verbose_name: 'charge_id',
        description: '',
        field: 'text',
        required: true,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'customer',
        verbose_name: 'customer',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'description',
        verbose_name: 'description',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'metadata',
        verbose_name: 'metadata',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'receipt_email',
        verbose_name: 'receipt_email',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'shipping',
        verbose_name: 'shipping',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      }
    ],
    update_parameters_use_defaults: false,
    delete_parameters: [],
    delete_parameters_use_defaults: false,
    aggregate_parameters: [],
    group_parameters: [],
    sync: false,
    sync_finished: false,
    params: {},
    draft: false,
    deleted: false
  },
  {
    project: '{{project}}',
    resource: '{{resource}}',
    model: 'payouts',
    verbose_name: 'Payouts',
    verbose_name_plural: 'Payouts',
    fields: [
      {
        name: 'id',
        verbose_name: 'id',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'object',
        verbose_name: 'object',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'amount',
        verbose_name: 'amount',
        field: 'number',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {
          type: 'integer',
          fraction: 2
        }
      },
      {
        name: 'arrival_date',
        verbose_name: 'arrival date',
        field: 'datetime',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {
          date: true,
          time: true,
          date_format: 'local',
          time_format: 'hour_minute_24',
          output_format: 'timestamp'
        }
      },
      {
        name: 'automatic',
        verbose_name: 'automatic',
        field: 'boolean',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'balance_transaction',
        verbose_name: 'balance transaction',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'created',
        verbose_name: 'created',
        field: 'datetime',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {
          date: true,
          time: true,
          date_format: 'local',
          time_format: 'hour_minute_24',
          output_format: 'timestamp'
        }
      },
      {
        name: 'currency',
        verbose_name: 'currency',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'description',
        verbose_name: 'description',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'destination',
        verbose_name: 'destination',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'failure_balance_transaction',
        verbose_name: 'failure balance transaction',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'failure_code',
        verbose_name: 'failure code',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'failure_message',
        verbose_name: 'failure message',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'livemode',
        verbose_name: 'livemode',
        field: 'boolean',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'metadata',
        verbose_name: 'metadata',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'method',
        verbose_name: 'method',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'source_type',
        verbose_name: 'source type',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'statement_descriptor',
        verbose_name: 'statement descriptor',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'status',
        verbose_name: 'status',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'type',
        verbose_name: 'type',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      }
    ],
    flex_fields: [],
    segments: [],
    primary_key_field: 'id',
    relations: [],
    description: 'Returns a list of existing payouts sent to third-party bank accounts or that Stripe has sent you.',
    get_query: {
      query_type: 'http',
      http_query: {
        method: 'GET',
        url: 'https://api.stripe.com/v1/payouts',
        auth_params: {},
        body_type: 'JSON',
        body: '',
        headers: [
          {
            name: 'Authorization',
            value: 'Bearer {-api_key-}'
          }
        ],
        query_params: [
          {
            name: 'limit',
            value: '{{paging.limit}}'
          },
          {
            name: 'status',
            value: '{{params.status}}'
          },
          {
            name: 'ending_before',
            value: '{{paging.cursor_prev}}'
          },
          {
            name: 'starting_after',
            value: '{{paging.cursor_next}}'
          },
          {
            name: 'created',
            value: '{{params.created}}'
          }
        ],
        response_type: 'json',
        response_transformer: "// add custom transformation here\nreturn data['data']",
        request_tokens: {}
      },
      pagination: 'cursor',
      pagination_has_more_function: "// ex. data['hasMore']\nreturn data['has_more']",
      pagination_cursor_next_function:
        "// ex. data['next_cursor']\nreturn data['data'] && data['data'].length ? data['data'][data['data'].length - 1].id : undefined;",
      pagination_total_path: [],
      pagination_cursor_next_path: [],
      pagination_has_more_pages_path: [],
      pagination_has_more_total_pages_path: [],
      pagination_has_more_total_records_path: [],
      sorting: false,
      sorting_fields: []
    },
    get_parameters: [
      {
        name: 'status',
        verbose_name: 'status',
        description: '',
        field: 'select',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {
          options: [
            {
              name: 'pending',
              color: '',
              value: 'pending'
            },
            {
              name: 'paid',
              color: '',
              value: 'paid'
            },
            {
              name: 'failed',
              color: '',
              value: 'failed'
            },
            {
              name: 'canceled',
              color: '',
              value: 'canceled'
            }
          ],
          options_type: 'static'
        }
      },
      {
        name: 'created',
        verbose_name: 'created',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      }
    ],
    get_inputs: [],
    get_detail_query: {
      query_type: 'http',
      http_query: {
        method: 'GET',
        url: 'https://api.stripe.com/v1/payouts/{{params.id}}',
        auth_params: {},
        body_type: 'JSON',
        body: '',
        headers: [
          {
            name: 'Authorization',
            value: 'Bearer {-api_key-}'
          }
        ],
        query_params: [],
        response_type: 'json',
        response_path: '',
        request_tokens: {}
      }
    },
    get_detail_parameters: [
      {
        name: 'id',
        verbose_name: 'payout_id',
        description: '',
        field: 'text',
        required: true,
        default_type: 'value',
        default_value: '',
        params: {}
      }
    ],
    get_detail_parameters_use_defaults: false,
    create_query: {
      query_type: 'http',
      http_query: {
        method: 'POST',
        url: 'https://api.stripe.com/v1/payouts',
        auth_params: {},
        body_type: 'JSON',
        body: '',
        headers: [
          {
            name: 'Authorization',
            value: 'Bearer {-api_key-}'
          }
        ],
        query_params: [
          {
            name: 'amount',
            value: '{{params.amount}}'
          },
          {
            name: 'currency',
            value: '{{params.currency}}'
          },
          {
            name: 'description',
            value: '{{params.description}}'
          },
          {
            name: 'metadata[order_id]',
            value: '{{params.metadata}}'
          },
          {
            name: 'statement_descriptor',
            value: '{{params.statement_descriptor}}'
          },
          {
            name: 'method',
            value: '{{params.method}}'
          }
        ],
        response_type: 'json',
        response_path: '',
        request_tokens: {}
      }
    },
    create_parameters: [
      {
        name: 'amount',
        verbose_name: 'amount',
        description: '',
        field: 'text',
        required: true,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'currency',
        verbose_name: 'currency',
        description: '',
        field: 'text',
        required: true,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'description',
        verbose_name: 'description',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'metadata',
        verbose_name: 'metadata',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'statement_descriptor',
        verbose_name: 'statement_descriptor',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'method',
        verbose_name: 'method',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      }
    ],
    create_parameters_use_defaults: false,
    update_query: {
      query_type: 'http',
      http_query: {
        method: 'POST',
        url: 'https://api.stripe.com/v1/payouts/{{params.id}}',
        auth_params: {},
        body_type: 'JSON',
        body: '',
        headers: [
          {
            name: 'Authorization',
            value: 'Bearer {-api_key-}'
          }
        ],
        query_params: [
          {
            name: 'metadata[order_id]',
            value: '{{params.metadata}}'
          }
        ],
        response_type: 'json',
        response_path: '',
        request_tokens: {}
      }
    },
    update_parameters: [
      {
        name: 'id',
        verbose_name: 'payout_id',
        description: '',
        field: 'text',
        required: true,
        default_type: 'value',
        default_value: '',
        params: {}
      }
    ],
    update_parameters_use_defaults: false,
    delete_query: {
      query_type: 'http',
      http_query: {
        method: 'POST',
        url: 'https://api.stripe.com/v1/payouts/{{params.id}}/cancel',
        auth_params: {},
        body_type: 'JSON',
        body: '',
        headers: [
          {
            name: 'Authorization',
            value: 'Bearer {-api_key-}'
          }
        ],
        query_params: [],
        response_type: 'json',
        response_path: '',
        request_tokens: {}
      }
    },
    delete_parameters: [
      {
        name: 'id',
        verbose_name: 'payout_id',
        description: '',
        field: 'text',
        required: true,
        default_type: 'value',
        default_value: '',
        params: {}
      }
    ],
    delete_parameters_use_defaults: false,
    aggregate_parameters: [],
    group_parameters: [],
    sync: false,
    sync_finished: false,
    params: {},
    draft: false,
    deleted: false
  },
  {
    project: '{{project}}',
    resource: '{{resource}}',
    model: 'products',
    verbose_name: 'Products',
    verbose_name_plural: 'Products',
    fields: [
      {
        name: 'id',
        verbose_name: 'id',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'object',
        verbose_name: 'object',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'active',
        verbose_name: 'active',
        field: 'boolean',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'attributes',
        verbose_name: 'attributes',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'created',
        verbose_name: 'created',
        field: 'datetime',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {
          date: true,
          time: true,
          date_format: 'local',
          time_format: 'hour_minute_24',
          output_format: 'timestamp'
        }
      },
      {
        name: 'description',
        verbose_name: 'description',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'images',
        verbose_name: 'images',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'livemode',
        verbose_name: 'livemode',
        field: 'boolean',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'metadata',
        verbose_name: 'metadata',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'name',
        verbose_name: 'name',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'statement_descriptor',
        verbose_name: 'statement descriptor',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'type',
        verbose_name: 'type',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'unit_label',
        verbose_name: 'unit label',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'updated',
        verbose_name: 'updated',
        field: 'datetime',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {
          date: true,
          time: true,
          date_format: 'local',
          time_format: 'hour_minute_24',
          output_format: 'timestamp'
        }
      }
    ],
    flex_fields: [],
    segments: [],
    default_order_by: '-created',
    primary_key_field: 'id',
    relations: [],
    description:
      'Returns a list of your products. The products are returned sorted by creation date, with the most recently created products appearing first.',
    get_query: {
      query_type: 'http',
      http_query: {
        method: 'GET',
        url: 'https://api.stripe.com/v1/products',
        auth_params: {},
        body_type: 'Raw',
        body: '',
        headers: [
          {
            name: 'Authorization',
            value: 'Bearer {-api_key-}'
          }
        ],
        query_params: [
          {
            name: 'limit',
            value: '{{paging.limit}}'
          },
          {
            name: 'active',
            value: '{{params.active}}'
          },
          {
            name: 'ending_before',
            value: '{{paging.cursor_prev}}'
          },
          {
            name: 'starting_after',
            value: '{{paging.cursor_next}}'
          }
        ],
        response_type: 'json',
        response_transformer: "// add custom transformation here\nreturn data['data'];",
        request_tokens: {}
      },
      pagination: 'cursor',
      pagination_has_more_function: "// ex. data['hasMore']\nreturn data['has_more'];",
      pagination_cursor_next_function:
        "// ex. data['next_cursor']\nreturn data['data'] && data['data'].length ? data['data'][data['data'].length - 1].id : undefined;",
      pagination_total_path: [],
      pagination_cursor_next_path: [],
      pagination_has_more_pages_path: [],
      pagination_has_more_total_pages_path: [],
      pagination_has_more_total_records_path: [],
      sorting: false,
      sorting_fields: []
    },
    get_parameters: [
      {
        name: 'active',
        verbose_name: 'active',
        description: '',
        field: 'boolean',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      }
    ],
    get_inputs: [],
    get_detail_query: {
      query_type: 'http',
      http_query: {
        method: 'GET',
        url: 'https://api.stripe.com/v1/products/{{params.product_id}}',
        auth_params: {},
        body_type: 'JSON',
        body: '',
        headers: [
          {
            name: 'Authorization',
            value: 'Bearer {-api_key-}'
          }
        ],
        query_params: [],
        response_type: 'json',
        response_path: '',
        request_tokens: {}
      }
    },
    get_detail_parameters: [
      {
        name: 'product_id',
        verbose_name: 'product_id',
        description: '',
        field: 'text',
        required: true,
        default_type: 'value',
        default_value: '',
        params: {}
      }
    ],
    get_detail_parameters_use_defaults: false,
    create_query: {
      query_type: 'http',
      http_query: {
        method: 'POST',
        url: 'https://api.stripe.com/v1/products',
        auth_params: {},
        body_type: 'JSON',
        body: '',
        headers: [
          {
            name: 'Authorization',
            value: 'Bearer {-api_key-}'
          }
        ],
        query_params: [
          {
            name: 'name',
            value: '{{params.name}}'
          },
          {
            name: 'id',
            value: '{{params.id}}'
          },
          {
            name: 'active',
            value: '{{params.active}}'
          },
          {
            name: 'description',
            value: '{{params.description}}'
          },
          {
            name: 'metadata[order_id]',
            value: '{{params.metadata}}'
          }
        ],
        response_type: 'json',
        response_path: '',
        request_tokens: {}
      }
    },
    create_parameters: [
      {
        name: 'id',
        verbose_name: 'id',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'name',
        verbose_name: 'name',
        description: '',
        field: 'text',
        required: true,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'active',
        verbose_name: 'active',
        description: '',
        field: 'boolean',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'description',
        verbose_name: 'description',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'metadata',
        verbose_name: 'metadata',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      }
    ],
    create_parameters_use_defaults: false,
    update_query: {
      query_type: 'http',
      http_query: {
        method: 'POST',
        url: 'https://api.stripe.com/v1/products/{{params.id}}',
        auth_params: {},
        body_type: 'JSON',
        body: '',
        headers: [
          {
            name: 'Authorization',
            value: 'Bearer {-api_key-}'
          }
        ],
        query_params: [
          {
            name: 'metadata[order_id]',
            value: '{{params.metadata}}'
          },
          {
            name: 'active',
            value: '{{params.active}}'
          },
          {
            name: 'description',
            value: '{{params.description}}'
          },
          {
            name: 'name',
            value: '{{params.name}}'
          }
        ],
        response_type: 'json',
        response_path: '',
        request_tokens: {}
      }
    },
    update_parameters: [
      {
        name: 'id',
        verbose_name: 'product_id',
        description: '',
        field: 'text',
        required: true,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'metadata',
        verbose_name: 'metadata',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'active',
        verbose_name: 'active',
        description: '',
        field: 'boolean',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'description',
        verbose_name: 'description',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'name',
        verbose_name: 'name',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      }
    ],
    update_parameters_use_defaults: false,
    delete_query: {
      query_type: 'http',
      http_query: {
        method: 'DELETE',
        url: 'https://api.stripe.com/v1/products/{{params.id}}',
        auth_params: {},
        body_type: 'JSON',
        body: '',
        headers: [
          {
            name: 'Authorization',
            value: 'Bearer {-api_key-}'
          }
        ],
        query_params: [],
        response_type: 'json',
        response_path: '',
        request_tokens: {}
      }
    },
    delete_parameters: [
      {
        name: 'id',
        verbose_name: 'product_id',
        description: '',
        field: 'text',
        required: true,
        default_type: 'value',
        default_value: '',
        params: {}
      }
    ],
    delete_parameters_use_defaults: false,
    aggregate_parameters: [],
    group_parameters: [],
    sync: false,
    sync_finished: false,
    params: {},
    draft: false,
    deleted: false
  },
  {
    project: '{{project}}',
    resource: '{{resource}}',
    model: 'refunds',
    verbose_name: 'Refunds',
    verbose_name_plural: 'Refunds',
    fields: [
      {
        name: 'id',
        verbose_name: 'id',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'object',
        verbose_name: 'object',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'amount',
        verbose_name: 'amount',
        field: 'number',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {
          type: 'integer',
          fraction: 2
        }
      },
      {
        name: 'balance_transaction',
        verbose_name: 'balance transaction',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'charge',
        verbose_name: 'charge',
        field: 'related_model',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {
          related_model: {
            model: '{{resource}}.charges'
          },
          custom_primary_key: 'id'
        }
      },
      {
        name: 'created',
        verbose_name: 'created',
        field: 'datetime',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {
          date: true,
          time: true,
          date_format: 'local',
          time_format: 'hour_minute_24',
          output_format: 'timestamp'
        }
      },
      {
        name: 'currency',
        verbose_name: 'currency',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'metadata',
        verbose_name: 'metadata',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'payment_intent',
        verbose_name: 'payment intent',
        field: 'related_model',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {
          related_model: {
            model: '{{resource}}.payment_intents'
          },
          custom_primary_key: 'id'
        }
      },
      {
        name: 'reason',
        verbose_name: 'reason',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'receipt_number',
        verbose_name: 'receipt number',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'source_transfer_reversal',
        verbose_name: 'source transfer reversal',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'status',
        verbose_name: 'status',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'transfer_reversal',
        verbose_name: 'transfer reversal',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {}
      }
    ],
    flex_fields: [],
    segments: [],
    primary_key_field: 'id',
    relations: [],
    description: 'Returns a list of all refunds you’ve previously created.',
    get_query: {
      query_type: 'http',
      http_query: {
        method: 'GET',
        url: 'https://api.stripe.com/v1/refunds',
        auth_params: {},
        body_type: 'JSON',
        body: '',
        headers: [
          {
            name: 'Authorization',
            value: 'Bearer {-api_key-}'
          }
        ],
        query_params: [
          {
            name: 'limit',
            value: '{{paging.limit}}'
          },
          {
            name: 'charge',
            value: '{{params.charge}}'
          },
          {
            name: 'payment_intent',
            value: '{{params.payment_intent}}'
          },
          {
            name: 'ending_before',
            value: '{{paging.cursor_prev}}'
          },
          {
            name: 'starting_after',
            value: '{{paging.cursor_next}}'
          },
          {
            name: 'created',
            value: '{{params.created}}'
          }
        ],
        response_type: 'json',
        response_transformer: "// add custom transformation here\nreturn data['data']",
        request_tokens: {}
      },
      pagination: 'cursor',
      pagination_has_more_function: "// ex. data['hasMore']\nreturn data['has_more']",
      pagination_cursor_next_function:
        "// ex. data['next_cursor']\nreturn data['data'] && data['data'].length ? data['data'][data['data'].length - 1].id : undefined;",
      pagination_total_path: [],
      pagination_cursor_next_path: [],
      pagination_has_more_pages_path: [],
      pagination_has_more_total_pages_path: [],
      pagination_has_more_total_records_path: [],
      sorting: false,
      sorting_fields: []
    },
    get_parameters: [
      {
        name: 'charge',
        verbose_name: 'charge',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'payment_intent',
        verbose_name: 'payment_intent',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'created',
        verbose_name: 'created',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      }
    ],
    get_inputs: [],
    get_detail_query: {
      query_type: 'http',
      http_query: {
        method: 'GET',
        url: 'https://api.stripe.com/v1/refunds/{{params.refund_id}}',
        auth_params: {},
        body_type: 'JSON',
        body: '',
        headers: [
          {
            name: 'Authorization',
            value: 'Bearer {-api_key-}'
          }
        ],
        query_params: [],
        response_type: 'json',
        response_path: '',
        request_tokens: {}
      }
    },
    get_detail_parameters: [
      {
        name: 'refund_id',
        verbose_name: 'refund_id',
        description: '',
        field: 'text',
        required: true,
        default_type: 'value',
        default_value: '',
        params: {}
      }
    ],
    get_detail_parameters_use_defaults: false,
    create_query: {
      query_type: 'http',
      http_query: {
        method: 'POST',
        url: 'https://api.stripe.com/v1/refunds',
        auth_params: {},
        body_type: 'JSON',
        body: '',
        headers: [
          {
            name: 'Authorization',
            value: 'Bearer {-api_key-}'
          }
        ],
        query_params: [
          {
            name: 'charge',
            value: '{{params.charge}}'
          },
          {
            name: 'amount',
            value: '{{params.amount}}'
          },
          {
            name: 'metadata[order_id]',
            value: '{{params.metadata}}'
          },
          {
            name: 'payment_intent',
            value: '{{params.payment_intent}}'
          },
          {
            name: 'reason',
            value: '{{params.reason}}'
          }
        ],
        response_type: 'json',
        response_path: '',
        request_tokens: {}
      }
    },
    create_parameters: [
      {
        name: 'charge',
        verbose_name: 'charge',
        description: '',
        field: 'text',
        required: true,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'amount',
        verbose_name: 'amount',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'metadata',
        verbose_name: 'metadata',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'payment_intent',
        verbose_name: 'payment_intent',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'reason',
        verbose_name: 'reason',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      }
    ],
    create_parameters_use_defaults: false,
    update_query: {
      query_type: 'http',
      http_query: {
        method: 'POST',
        url: 'https://api.stripe.com/v1/refunds/{{params.id}}',
        auth_params: {},
        body_type: 'JSON',
        body: '',
        headers: [
          {
            name: 'Authorization',
            value: 'Bearer {-api_key-}'
          }
        ],
        query_params: [
          {
            name: 'metadata[order_id]',
            value: '{{params.metadata}}'
          }
        ],
        response_type: 'json',
        response_path: '',
        request_tokens: {}
      }
    },
    update_parameters: [
      {
        name: 'id',
        verbose_name: 'refund_id',
        description: '',
        field: 'text',
        required: true,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'metadata',
        verbose_name: 'metadata',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      }
    ],
    update_parameters_use_defaults: false,
    delete_parameters: [],
    delete_parameters_use_defaults: false,
    aggregate_parameters: [],
    group_parameters: [],
    sync: false,
    sync_finished: false,
    params: {},
    draft: false,
    deleted: false
  },
  {
    project: '{{project}}',
    resource: '{{resource}}',
    model: 'subscriptions',
    verbose_name: 'Subscriptions',
    verbose_name_plural: 'Subscriptions',
    fields: [
      {
        name: 'id',
        verbose_name: 'id',
        field: 'text',
        required: true,
        null: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'object',
        verbose_name: 'object',
        field: 'text',
        required: true,
        null: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'application_fee_percent',
        verbose_name: 'application fee percent',
        field: 'number',
        required: true,
        null: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'billing_cycle_anchor',
        verbose_name: 'billing cycle anchor',
        field: 'datetime',
        required: true,
        null: false,
        sortable: true,
        default_type: 'value',
        params: {
          date: true,
          time: true,
          date_format: 'local',
          time_format: 'hour_minute_24',
          output_format: 'timestamp'
        }
      },
      {
        name: 'billing_thresholds',
        verbose_name: 'billing thresholds',
        field: 'text',
        required: true,
        null: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'cancel_at',
        verbose_name: 'cancel at',
        field: 'text',
        required: true,
        null: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'cancel_at_period_end',
        verbose_name: 'cancel at period end',
        field: 'boolean',
        required: true,
        null: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'canceled_at',
        verbose_name: 'canceled at',
        field: 'datetime',
        required: true,
        null: false,
        sortable: true,
        default_type: 'value',
        params: {
          date: true,
          time: true,
          date_format: 'local',
          time_format: 'hour_minute_24',
          output_format: 'timestamp'
        }
      },
      {
        name: 'collection_method',
        verbose_name: 'collection method',
        field: 'text',
        required: true,
        null: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'created',
        verbose_name: 'created',
        field: 'datetime',
        required: true,
        null: false,
        sortable: true,
        default_type: 'value',
        params: {
          date: true,
          time: true,
          date_format: 'local',
          time_format: 'hour_minute_24',
          output_format: 'timestamp'
        }
      },
      {
        name: 'current_period_end',
        verbose_name: 'current period end',
        field: 'datetime',
        required: true,
        null: false,
        sortable: true,
        default_type: 'value',
        params: {
          date: true,
          time: true
        }
      },
      {
        name: 'current_period_start',
        verbose_name: 'current period start',
        field: 'datetime',
        required: true,
        null: false,
        sortable: true,
        default_type: 'value',
        params: {
          date: true,
          time: true,
          date_format: 'local',
          time_format: 'hour_minute_24',
          output_format: 'timestamp'
        }
      },
      {
        name: 'customer',
        verbose_name: 'customer',
        field: 'related_model',
        required: true,
        null: false,
        sortable: true,
        default_type: 'value',
        params: {
          related_model: {
            model: '{{resource}}.customers'
          },
          custom_primary_key: 'id'
        }
      },
      {
        name: 'days_until_due',
        verbose_name: 'days until due',
        field: 'number',
        required: true,
        null: false,
        sortable: true,
        default_type: 'value',
        params: {
          type: 'integer',
          fraction: 2
        }
      },
      {
        name: 'default_payment_method',
        verbose_name: 'default payment method',
        field: 'related_model',
        required: true,
        null: false,
        sortable: true,
        default_type: 'value',
        params: {
          related_model: {
            model: '{{resource}}.payment_methods'
          },
          custom_primary_key: 'id'
        }
      },
      {
        name: 'default_source',
        verbose_name: 'default source',
        field: 'text',
        required: true,
        null: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'default_tax_rates',
        verbose_name: 'default tax rates',
        field: 'text',
        required: true,
        null: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'discount',
        verbose_name: 'discount',
        field: 'text',
        required: true,
        null: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'ended_at',
        verbose_name: 'ended at',
        field: 'datetime',
        required: true,
        null: false,
        sortable: true,
        default_type: 'value',
        params: {
          date: true,
          time: true,
          date_format: 'local',
          time_format: 'hour_minute_24',
          output_format: 'timestamp'
        }
      },
      {
        name: 'items',
        verbose_name: 'items',
        field: 'text',
        required: true,
        null: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'latest_invoice',
        verbose_name: 'latest invoice',
        field: 'related_model',
        required: true,
        null: false,
        sortable: true,
        default_type: 'value',
        params: {
          related_model: {
            model: '{{resource}}.invoices'
          },
          custom_primary_key: 'id'
        }
      },
      {
        name: 'livemode',
        verbose_name: 'livemode',
        field: 'boolean',
        required: true,
        null: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'metadata',
        verbose_name: 'metadata',
        field: 'text',
        required: true,
        null: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'next_pending_invoice_item_invoice',
        verbose_name: 'next pending invoice item invoice',
        field: 'text',
        required: true,
        null: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'pause_collection',
        verbose_name: 'pause collection',
        field: 'text',
        required: true,
        null: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'pending_invoice_item_interval',
        verbose_name: 'pending invoice item interval',
        field: 'text',
        required: true,
        null: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'pending_setup_intent',
        verbose_name: 'pending setup intent',
        field: 'text',
        required: true,
        null: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'pending_update',
        verbose_name: 'pending update',
        field: 'text',
        required: true,
        null: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'plan',
        verbose_name: 'plan',
        field: 'text',
        required: true,
        null: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'quantity',
        verbose_name: 'quantity',
        field: 'number',
        required: true,
        null: false,
        sortable: true,
        default_type: 'value',
        params: {
          type: 'integer',
          fraction: 2
        }
      },
      {
        name: 'schedule',
        verbose_name: 'schedule',
        field: 'text',
        required: true,
        null: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'start_date',
        verbose_name: 'start date',
        field: 'datetime',
        required: true,
        null: false,
        sortable: true,
        default_type: 'value',
        params: {
          date: true,
          time: true,
          date_format: 'local',
          time_format: 'hour_minute_24',
          output_format: 'timestamp'
        }
      },
      {
        name: 'status',
        verbose_name: 'status',
        field: 'text',
        required: true,
        null: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'tax_percent',
        verbose_name: 'tax percent',
        field: 'text',
        required: true,
        null: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'transfer_data',
        verbose_name: 'transfer data',
        field: 'text',
        required: true,
        null: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'trial_end',
        verbose_name: 'trial end',
        field: 'datetime',
        required: true,
        null: false,
        sortable: true,
        default_type: 'value',
        params: {
          date: true,
          time: true,
          date_format: 'local',
          time_format: 'hour_minute_24',
          output_format: 'timestamp'
        }
      },
      {
        name: 'trial_start',
        verbose_name: 'trial start',
        field: 'datetime',
        required: true,
        null: false,
        sortable: true,
        default_type: 'value',
        params: {
          date: true,
          time: true,
          date_format: 'local',
          time_format: 'hour_minute_24',
          output_format: 'timestamp'
        }
      }
    ],
    flex_fields: [],
    segments: [],
    primary_key_field: 'id',
    relations: [],
    description: 'Returns a list of subscriptions that have not been canceled.',
    get_query: {
      query_type: 'http',
      http_query: {
        method: 'GET',
        url: 'https://api.stripe.com/v1/subscriptions',
        auth_params: {},
        body_type: 'JSON',
        body: '',
        headers: [
          {
            name: 'Authorization',
            value: 'Bearer {-api_key-}'
          }
        ],
        query_params: [
          {
            name: 'limit',
            value: '{{paging.limit}}'
          },
          {
            name: 'customer',
            value: '{{params.customer}}'
          },
          {
            name: 'plan',
            value: '{{params.plan}}'
          },
          {
            name: 'status',
            value: '{{params.status}}'
          },
          {
            name: 'ending_before',
            value: '{{paging.cursor_prev}}'
          },
          {
            name: 'starting_after',
            value: '{{paging.cursor_next}}'
          }
        ],
        response_type: 'json',
        response_transformer: "// add custom transformation here\nreturn data['data']",
        request_tokens: {}
      },
      pagination: 'cursor',
      pagination_has_more_function: "// ex. data['hasMore']\nreturn data['has_more']",
      pagination_cursor_next_function:
        "// ex. data['next_cursor']\nreturn data['data'] && data['data'].length ? data['data'][data['data'].length - 1].id : undefined;",
      pagination_total_path: [],
      pagination_cursor_next_path: [],
      pagination_has_more_pages_path: [],
      pagination_has_more_total_pages_path: [],
      pagination_has_more_total_records_path: [],
      sorting: false,
      sorting_fields: []
    },
    get_parameters: [
      {
        name: 'customer',
        verbose_name: 'customer',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'plan',
        verbose_name: 'plan',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'status',
        verbose_name: 'status',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      }
    ],
    get_inputs: [],
    get_detail_query: {
      query_type: 'http',
      http_query: {
        method: 'GET',
        url: 'https://api.stripe.com/v1/subscriptions/{{params.id}}',
        auth_params: {},
        body_type: 'FormUrlEncoded',
        body: [],
        headers: [
          {
            name: 'Authorization',
            value: 'Bearer {-api_key-}'
          }
        ],
        query_params: [],
        response_type: 'json',
        response_path: '',
        request_tokens: {}
      }
    },
    get_detail_parameters: [
      {
        name: 'id',
        verbose_name: 'id',
        description: '',
        field: 'text',
        required: true,
        default_type: 'value',
        default_value: '',
        params: {}
      }
    ],
    get_detail_parameters_use_defaults: false,
    create_query: {
      query_type: 'http',
      http_query: {
        method: 'POST',
        url: 'https://api.stripe.com/v1/subscriptions',
        auth_params: {},
        body_type: 'Raw',
        body: '',
        headers: [
          {
            name: 'Authorization',
            value: 'Bearer {-api_key-}'
          }
        ],
        query_params: [
          {
            name: 'customer',
            value: '{{params.customer}}'
          },
          {
            name: 'items[0][price]',
            value: '{{params.items}}'
          },
          {
            name: 'cancel_at_period_end',
            value: '{{params.cancel_at_period_end}}'
          },
          {
            name: 'default_payment_method',
            value: '{{params.default_payment_method}}'
          },
          {
            name: 'metadata[order_id]',
            value: '{{params.metadata}}'
          }
        ],
        response_type: 'json',
        response_path: '',
        request_tokens: {}
      }
    },
    create_parameters: [
      {
        name: 'customer',
        verbose_name: 'customer',
        description: '',
        field: 'text',
        required: true,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'items',
        verbose_name: 'items',
        description: '',
        field: 'text',
        required: true,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'cancel_at_period_end',
        verbose_name: 'cancel_at_period_end',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'default_payment_method',
        verbose_name: 'default_payment_method',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'metadata',
        verbose_name: 'metadata',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      }
    ],
    create_parameters_use_defaults: false,
    update_query: {
      query_type: 'http',
      http_query: {
        method: 'POST',
        url: 'https://api.stripe.com/v1/subscriptions/{{params.id}}',
        auth_params: {},
        body_type: 'JSON',
        body: '',
        headers: [
          {
            name: 'Authorization',
            value: 'Bearer {-api_key-}'
          }
        ],
        query_params: [
          {
            name: 'cancel_at_period_end',
            value: '{{params.cancel_at_period_end}}'
          },
          {
            name: 'default_payment_method',
            value: '{{params.default_payment_method}}'
          },
          {
            name: 'items[0][price]',
            value: '{{params.items}}'
          },
          {
            name: 'metadata[order_id]',
            value: '{{params.metadata}}'
          }
        ],
        response_type: 'json',
        response_path: '',
        request_tokens: {}
      }
    },
    update_parameters: [
      {
        name: 'id',
        verbose_name: 'id',
        description: '',
        field: 'text',
        required: true,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'cancel_at_period_end',
        verbose_name: 'cancel_at_period_end',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'default_payment_method',
        verbose_name: 'default_payment_method',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'items',
        verbose_name: 'items',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'metadata',
        verbose_name: 'metadata',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      }
    ],
    update_parameters_use_defaults: false,
    delete_query: {
      query_type: 'http',
      http_query: {
        method: 'DELETE',
        url: 'https://api.stripe.com/v1/subscriptions/{{params.id}}',
        auth_params: {},
        body_type: 'JSON',
        body: '',
        headers: [
          {
            name: 'Authorization',
            value: 'Bearer {-api_key-}'
          }
        ],
        query_params: [],
        response_type: 'json',
        response_path: '',
        request_tokens: {}
      }
    },
    delete_parameters: [
      {
        name: 'id',
        verbose_name: 'id',
        description: '',
        field: 'text',
        required: true,
        default_type: 'value',
        default_value: '',
        params: {}
      }
    ],
    delete_parameters_use_defaults: false,
    aggregate_parameters: [],
    group_parameters: [],
    sync: false,
    sync_finished: false,
    params: {},
    draft: false,
    deleted: false
  }
];

<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayPanelClass]="['overlay', 'overlay_horizontal_center']"
  [cdkConnectedOverlayPositions]="popoverPositions"
  [cdkConnectedOverlayOrigin]="origin"
  [cdkConnectedOverlayOpen]="dropdownOpened"
  [cdkConnectedOverlayPush]="true"
  [cdkConnectedOverlayGrowAfterOpen]="true"
  [cdkConnectedOverlayHasBackdrop]="true"
  [cdkConnectedOverlayBackdropClass]="'popover2-backdrop'"
  (backdropClick)="setDropdownOpened(false)"
  (detach)="setDropdownOpened(false)"
>
  <div class="popover2 overlay-content overlay-content_animated">
    <div class="popover2__inner">
      <div class="popover2__viewport popover2__viewport_scrollable">
        <div style="min-width: 300px;">
          <app-option-enabled-edit
            [control]="control"
            [dataSourceControl]="dataSourceControl"
            [onlyEditableAllowed]="onlyEditableAllowed"
            [emptyMessage]="emptyMessage"
          ></app-option-enabled-edit>
        </div>
      </div>
    </div>
  </div>
</ng-template>

import round from 'lodash/round';
import * as moment from 'moment';

export class Benchmark {
  created: moment.Moment;

  constructor(defer = false) {
    if (!defer) {
      this.reset();
    }
  }

  reset() {
    this.created = moment();
  }

  secondElapsed(precision?: number): number {
    const result = moment().diff(this.created, 'seconds', true);
    return round(result, precision);
  }
}

import { Injectable } from '@angular/core';
import clone from 'lodash/clone';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { PAGE_PARAM, PER_PAGE_PARAM } from '@modules/models';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { ListStore, ListStoreFetchResponse } from '@shared';

import { FigmaExternalImport } from '../data/figma-external-import';
import { FigmaService } from '../services/figma/figma.service';

@Injectable()
export class FigmaExternalImportListStore extends ListStore<FigmaExternalImport> {
  params = {};

  constructor(
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private figmaService: FigmaService
  ) {
    super();
  }

  paramsForPage(page) {
    const params = clone(this.params);
    params[PAGE_PARAM] = page;
    params[PER_PAGE_PARAM] = this.perPage;
    return params;
  }

  fetchPage(page: number, next: boolean): Observable<ListStoreFetchResponse<FigmaExternalImport>> {
    const params = this.paramsForPage(page);

    return this.figmaService
      .getImports(
        this.currentProjectStore.instance.uniqueName,
        this.currentEnvironmentStore.instance.uniqueName,
        params
      )
      .pipe(
        map(response => {
          return {
            items: response.results,
            hasMore: page < response.numPages,
            totalPages: response.numPages,
            perPage: response.perPage,
            count: response.count
          };
        })
      );
  }
}

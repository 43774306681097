var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ChangeDetectorRef, OnDestroy } from '@angular/core';
import { UniversalAnalyticsService } from '@modules/analytics';
import { MapLocationStorage } from '@modules/customize';
import { FieldType } from '@modules/fields';
import { ResourceGeneratorResolver } from '@modules/resource-generators';
import { CustomizeBarListLayoutSettingsDataSourceComponent } from '../../customize-bar-list-layout-settings/customize-bar-list-layout-settings-data-source.component';
import { CustomizeBarMapSettingsForm } from '../customize-bar-map-settings.form';
var CustomizeBarMapSettingsDataSourceComponent = /** @class */ (function (_super) {
    __extends(CustomizeBarMapSettingsDataSourceComponent, _super);
    function CustomizeBarMapSettingsDataSourceComponent(resourceGeneratorResolver, analyticsService, cd) {
        var _this = _super.call(this, resourceGeneratorResolver, analyticsService, cd) || this;
        _this.extraTokens = [
            {
                name: ['params', 'latitude__gte'].join('.'),
                verboseName: 'latitude greater than or equals',
                field: FieldType.Number
            },
            {
                name: ['params', 'latitude__lte'].join('.'),
                verboseName: 'latitude less than or equals',
                field: FieldType.Number
            },
            {
                name: ['params', 'longitude__gte'].join('.'),
                verboseName: 'longitude greater than or equals',
                field: FieldType.Number
            },
            {
                name: ['params', 'longitude__lte'].join('.'),
                verboseName: 'longitude less than or equals',
                field: FieldType.Number
            }
        ];
        _this.mapLocationStorages = MapLocationStorage;
        return _this;
    }
    return CustomizeBarMapSettingsDataSourceComponent;
}(CustomizeBarListLayoutSettingsDataSourceComponent));
export { CustomizeBarMapSettingsDataSourceComponent };

import { Pipe, PipeTransform } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { Observable } from 'rxjs';

import { controlValue } from '../../utils/forms/forms';

@Pipe({
  name: 'appControlValue'
})
export class ControlValuePipe implements PipeTransform {
  transform(control: AbstractControl): Observable<any> {
    return controlValue(control);
  }
}

import { TemplateRef } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
var CustomizeToolbarBottomService = /** @class */ (function () {
    function CustomizeToolbarBottomService() {
        this._templates = new BehaviorSubject([]);
    }
    Object.defineProperty(CustomizeToolbarBottomService.prototype, "templates", {
        get: function () {
            return this._templates.value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CustomizeToolbarBottomService.prototype, "templates$", {
        get: function () {
            return this._templates.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    CustomizeToolbarBottomService.prototype.setTemplate = function (id, template, options) {
        if (options === void 0) { options = {}; }
        if (this._templates.value.find(function (item) { return item.id == id && item.template === template; })) {
            return;
        }
        var templateItem = { id: id, template: template, options: options };
        var templateItems = this._templates.value.filter(function (item) { return item.id != id; }).concat([templateItem]);
        this._templates.next(templateItems);
    };
    CustomizeToolbarBottomService.prototype.removeTemplate = function (id) {
        if (!this._templates.value.find(function (item) { return item.id == id; })) {
            return;
        }
        var templateItems = this._templates.value.filter(function (item) { return item.id != id; }).slice();
        this._templates.next(templateItems);
    };
    CustomizeToolbarBottomService.ngInjectableDef = i0.defineInjectable({ factory: function CustomizeToolbarBottomService_Factory() { return new CustomizeToolbarBottomService(); }, token: CustomizeToolbarBottomService, providedIn: "root" });
    return CustomizeToolbarBottomService;
}());
export { CustomizeToolbarBottomService };

import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import cloneDeep from 'lodash/cloneDeep';
import { combineLatest, Observable } from 'rxjs';
import { delayWhen, map, switchMap } from 'rxjs/operators';

import { ActionDescriptionService, ActionStore } from '@modules/action-queries';
import { ActionDescription, ActionType, QueryAction } from '@modules/actions';
import { FieldType, OptionsType, ParameterField } from '@modules/fields';
import { MessageName, MessageService } from '@modules/messages';
import { ModelDescriptionService, ModelDescriptionStore } from '@modules/model-queries';
import { ModelDbField, ModelDescription, ModelField, ModelFieldType } from '@modules/models';
import { ProjectApiService } from '@modules/project-api';
import {
  CurrentEnvironmentStore,
  CurrentProjectStore,
  Environment,
  Project,
  Resource,
  ResourceName,
  ResourceService,
  ResourceType,
  resourceTypeItems
} from '@modules/projects';
import {
  ActionQuery,
  HttpContentType,
  HttpMethod,
  HttpQuery,
  ListModelDescriptionQuery,
  QueryType
} from '@modules/queries';
import {
  JetBridgeResourceController,
  MessagesAPIResourceController,
  ResourceControllerService
} from '@modules/resources';

export const MESSAGES_API_RESOURCE_NAME = 'messages_api';

@Injectable()
export class MessagesConverterForm extends FormGroup {
  controls: {
    api_url: FormControl;
  };

  constructor(
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private resourceControllerService: ResourceControllerService,
    private modelDescriptionService: ModelDescriptionService,
    private modelDescriptionStore: ModelDescriptionStore,
    private actionDescriptionService: ActionDescriptionService,
    private actionStore: ActionStore,
    private messageService: MessageService,
    private apiService: ProjectApiService,
    private resourceService: ResourceService
  ) {
    super({
      api_url: new FormControl('', Validators.required)
    });
  }

  syncResource(project: Project, environment: Environment): Observable<Resource> {
    const existingResource = this.currentEnvironmentStore.resources.find(
      item => item.uniqueName == MESSAGES_API_RESOURCE_NAME
    );
    const resource = existingResource ? cloneDeep(existingResource) : new Resource();
    const typeItem = resourceTypeItems.find(item => item.name == ResourceName.RestApi);

    resource.uniqueName = MESSAGES_API_RESOURCE_NAME;
    resource.name = 'Messages API';
    resource.type = ResourceType.RestAPI;
    resource.typeItem = typeItem;
    resource.draft = true;
    resource.deleted = false;

    if (!existingResource) {
      return this.resourceService.update(project.uniqueName, environment.uniqueName, resource, [
        'unique_name',
        'name',
        'type',
        'type_item',
        'deploy',
        'token',
        'params',
        'deleted'
      ]);
    } else {
      return this.resourceService.create(project.uniqueName, environment.uniqueName, resource);
    }
  }

  syncActions(
    project: Project,
    environment: Environment,
    resource: Resource,
    messagesActions: ActionDescription[],
    messagesResource: Resource
  ): Observable<ActionDescription[]> {
    return this.actionStore.getFirst().pipe(
      map(actions => actions.filter(item => item.resource == resource.uniqueName)),
      switchMap(existingActions => {
        const actions = messagesActions.map(messagesAction => {
          const existingAction = existingActions.find(item => item.name == messagesAction.name);
          const action = existingAction ? cloneDeep(existingAction) : new ActionDescription();

          action.resource = resource.uniqueName;
          action.name = messagesAction.name;
          action.draft = true;
          action.deleted = false;

          this.syncAction(action, messagesAction, messagesResource);

          return action;
        });

        return this.actionDescriptionService.createBulk(project.uniqueName, environment.uniqueName, actions);
      })
    );
  }

  syncAction(
    actionDescription: ActionDescription,
    messagesAction: ActionDescription,
    messagesResource: Resource
  ): ActionDescription {
    const systemParams = [];

    if (messagesAction.deprecatedModelAction && messagesAction.deprecatedModelAction.for_instance) {
      if (messagesAction.deprecatedModelAction.bulk) {
        systemParams.push(
          ...[
            new ParameterField({
              name: 'ids',
              verboseName: 'ids',
              field: FieldType.JSON,
              required: true
            }),
            new ParameterField({
              name: 'inverseIds',
              verboseName: 'inverseIds',
              field: FieldType.Boolean,
              required: false
            })
          ]
        );
      } else {
        systemParams.push(
          new ParameterField({
            name: 'id',
            verboseName: 'id',
            field: FieldType.Text,
            required: true
          })
        );
      }
    }

    const userParams = messagesAction.actionParams.map(item => {
      item.name = `ext_${item.name}`;
      return item;
    });
    const allParams = [...systemParams, ...userParams];

    actionDescription.verboseName = messagesAction.verboseName;
    actionDescription.type = ActionType.Query;
    actionDescription.queryAction = new QueryAction();
    actionDescription.queryAction.query = new ActionQuery();
    actionDescription.queryAction.query.queryType = QueryType.Http;
    actionDescription.actionParams = allParams;
    actionDescription.outputs = messagesAction.outputs;
    actionDescription.arrayOutput = messagesAction.arrayOutput;

    const httpQuery = new HttpQuery();

    httpQuery.method = HttpMethod.POST;
    httpQuery.url = this.messageService.messagesUrl(messagesResource);
    httpQuery.headers = [
      { name: 'Authorization', value: 'JWT {{user.token}}' },
      { name: 'Content-Type', value: 'application/json' }
    ];

    const systemParamsBody = systemParams.reduce((acc, item) => {
      acc[item.name] = `{{params.${item.name}}}`;
      return acc;
    }, {});
    const userParamsBody = userParams.reduce((acc, item) => {
      acc[item.name] = `{{params.${item.name}}}`;
      return acc;
    }, {});

    httpQuery.bodyType = HttpContentType.JSON;
    httpQuery.body = JSON.stringify(
      {
        name: MessageName.ExecuteAction,
        params: {
          name: `${actionDescription.name}`,
          ...(messagesAction.deprecatedModelAction && { model: messagesAction.deprecatedModelAction.model }),
          ...systemParamsBody,
          params: userParamsBody
        }
      },
      undefined,
      2
    ).replace(/"{{([^}]+)}}"/g, '$1');

    actionDescription.queryAction.query.httpQuery = httpQuery;

    return actionDescription;
  }

  syncModelDescriptions(
    project: Project,
    environment: Environment,
    resource: Resource,
    jetBridgeModelDescriptions: ModelDescription[],
    messagesActions: ActionDescription[],
    messagesResource: Resource
  ): Observable<ModelDescription[]> {
    return this.modelDescriptionStore.getFirst().pipe(
      map(modelDescriptions => modelDescriptions.filter(item => item.resource == resource.uniqueName)),
      switchMap(existingModelDescriptions => {
        const optionsModelDescriptions = jetBridgeModelDescriptions.reduce((acc, jetBridgeModelDescription) => {
          jetBridgeModelDescription.dbFields
            .filter(
              field =>
                field.field == FieldType.Select &&
                field.params &&
                field.params['options_type'] == OptionsType.MessagesAPI
            )
            .forEach(field => {
              const name = ['field_options', jetBridgeModelDescription.model, field.name].join('_');
              const existingModelDescription = existingModelDescriptions.find(item => item.model == name);
              const modelDescription = existingModelDescription
                ? cloneDeep(existingModelDescription)
                : new ModelDescription();

              modelDescription.resource = resource.uniqueName;
              modelDescription.model = name;
              modelDescription.draft = true;
              modelDescription.deleted = false;

              this.syncOptionsModel(modelDescription, jetBridgeModelDescription, field, messagesResource);

              acc.push(modelDescription);
            });

          return acc;
        }, []);

        const elementStatusModelDescriptions = messagesActions
          .filter(messagesAction => messagesAction.deprecatedDynamicStatus)
          .map(messagesAction => {
            const name = ['element_status', messagesAction.name].join('_');
            const existingModelDescription = existingModelDescriptions.find(item => item.model == name);
            const modelDescription = existingModelDescription
              ? cloneDeep(existingModelDescription)
              : new ModelDescription();

            modelDescription.resource = resource.uniqueName;
            modelDescription.model = name;
            modelDescription.draft = true;
            modelDescription.deleted = false;

            this.syncElementStatusModel(modelDescription, messagesAction, messagesResource);

            return modelDescription;
          });

        return this.modelDescriptionService.createBulk(project.uniqueName, environment.uniqueName, [
          ...optionsModelDescriptions,
          ...elementStatusModelDescriptions
        ]);
      })
    );
  }

  syncOptionsModel(
    modelDescription: ModelDescription,
    jetBridgeModelDescription: ModelDescription,
    jetBridgeField: ModelDbField,
    messagesResource: Resource
  ): ModelDescription {
    const modelIdParam = new ParameterField({
      name: 'model_id',
      verboseName: 'record id',
      field: FieldType.Text,
      required: false
    });
    const systemParams = [modelIdParam];

    modelDescription.verboseName = [
      'options for',
      jetBridgeModelDescription.verboseNamePlural || jetBridgeModelDescription.model,
      jetBridgeField.verboseName || jetBridgeField.name
    ].join(' ');
    modelDescription.fields = ['name', 'value', 'color'].map(item => {
      const dbField = new ModelDbField();

      dbField.name = item;
      dbField.field = FieldType.Text;
      dbField.params = {};
      dbField.updateFieldDescription();

      const field = new ModelField();

      field.name = dbField.name;
      field.type = ModelFieldType.Db;
      field.item = dbField;

      return field;
    });

    const httpQuery = new HttpQuery();

    httpQuery.method = HttpMethod.POST;
    httpQuery.url = this.messageService.messagesUrl(messagesResource);
    httpQuery.headers = [
      { name: 'Authorization', value: 'JWT {{user.token}}' },
      { name: 'Content-Type', value: 'application/json' }
    ];

    httpQuery.bodyType = HttpContentType.JSON;
    httpQuery.body = JSON.stringify(
      {
        name: MessageName.GetFieldOptions,
        params: {
          field: jetBridgeField.name,
          params: jetBridgeField.params,
          model: jetBridgeModelDescription.model,
          model_primary_key: `{{params.${modelIdParam.name}}}`
        }
      },
      undefined,
      2
    ).replace(/"{{([^}]+)}}"/g, '$1');

    modelDescription.getQuery = new ListModelDescriptionQuery();
    modelDescription.getQuery.queryType = QueryType.Http;
    modelDescription.getQuery.httpQuery = httpQuery;

    modelDescription.getParameters = systemParams;
    modelDescription.getDetailParametersUseDefaults = false;

    return modelDescription;
  }

  syncElementStatusModel(
    modelDescription: ModelDescription,
    messagesAction: ActionDescription,
    messagesResource: Resource
  ): ModelDescription {
    const modelIdParam = new ParameterField({
      name: 'model_id',
      verboseName: 'record id',
      field: FieldType.Text,
      required: messagesAction.deprecatedModelAction && messagesAction.deprecatedModelAction.for_instance
    });
    const systemParams = [modelIdParam];

    modelDescription.verboseName = ['status for', messagesAction.verboseName || messagesAction.name].join(' ');
    modelDescription.fields = ['hidden', 'disabled'].map(item => {
      const dbField = new ModelDbField();

      dbField.name = item;
      dbField.field = FieldType.Text;
      dbField.params = {};
      dbField.updateFieldDescription();

      const field = new ModelField();

      field.name = dbField.name;
      field.type = ModelFieldType.Db;
      field.item = dbField;

      return field;
    });

    const httpQuery = new HttpQuery();

    httpQuery.method = HttpMethod.POST;
    httpQuery.url = this.messageService.messagesUrl(messagesResource);
    httpQuery.headers = [
      { name: 'Authorization', value: 'JWT {{user.token}}' },
      { name: 'Content-Type', value: 'application/json' }
    ];

    httpQuery.bodyType = HttpContentType.JSON;
    httpQuery.body = JSON.stringify(
      {
        name: MessageName.GetElementStatus,
        params: {
          element: 'action_element',
          name: messagesAction.name,
          context: {
            model: messagesAction.deprecatedModelAction ? messagesAction.deprecatedModelAction.model : undefined,
            model_primary_key: `{{params.${modelIdParam.name}}}`
          }
        }
      },
      undefined,
      2
    ).replace(/"{{([^}]+)}}"/g, '$1');

    modelDescription.getQuery = new ListModelDescriptionQuery();
    modelDescription.getQuery.queryType = QueryType.Http;
    modelDescription.getQuery.httpQuery = httpQuery;

    modelDescription.getParameters = systemParams;
    modelDescription.getDetailParametersUseDefaults = false;

    return modelDescription;
  }

  submit(): Observable<Resource> {
    this.markAsDirty();

    const project = this.currentProjectStore.instance;
    const environment = this.currentEnvironmentStore.instance;
    const messagesController = this.resourceControllerService.get(
      ResourceType.MessagesAPI
    ) as MessagesAPIResourceController;
    const messagesResource = new Resource();
    const messagesUrl = `${this.controls.api_url.value}messages/`;
    const jetBridgeController = this.resourceControllerService.get(
      ResourceType.JetBridge
    ) as JetBridgeResourceController;
    const jetBridgeResource = new Resource();
    const jetBridgeUrl = this.controls.api_url.value;

    jetBridgeResource.typeItem = resourceTypeItems.find(item => item.name == ResourceName.Django);
    jetBridgeResource.params = { url: jetBridgeUrl };

    messagesResource.typeItem = resourceTypeItems.find(item => item.name == ResourceName.MessagesApi);
    messagesResource.params = { url: messagesUrl };

    return combineLatest(
      this.syncResource(project, environment),
      jetBridgeController.modelDescriptionGet(jetBridgeResource),
      messagesController.actionDescriptionGet(messagesResource)
    ).pipe(
      delayWhen(([resource, jetBridgeModelDescriptions, messagesActions]) => {
        return this.syncActions(project, environment, resource, messagesActions, messagesResource);
      }),
      delayWhen(([resource, jetBridgeModelDescriptions, messagesActions]) => {
        return this.syncModelDescriptions(
          project,
          environment,
          resource,
          jetBridgeModelDescriptions,
          messagesActions,
          messagesResource
        );
      }),
      delayWhen(() => this.currentProjectStore.getFirst(true)),
      delayWhen(() => this.modelDescriptionStore.getFirst(true)),
      delayWhen(() => this.actionStore.getFirst(true)),
      map(([resource]) => resource)
    );
  }
}

import { AfterViewInit, ElementRef, EventEmitter, OnDestroy } from '@angular/core';
import { Power2, TimelineMax } from 'gsap';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BasePopupComponent } from '@common/popups';
import { GuideService } from '../../services/guide/guide.service';
import { GuideSectionStore } from '../../stores/guide-section.store';
var OnboardingPopupComponent = /** @class */ (function () {
    function OnboardingPopupComponent(popupComponent, guideSectionStore, guideService) {
        this.popupComponent = popupComponent;
        this.guideSectionStore = guideSectionStore;
        this.guideService = guideService;
        this.closed = new EventEmitter();
        this.tl = new TimelineMax();
        this.animating = false;
    }
    OnboardingPopupComponent.prototype.ngAfterViewInit = function () {
        this.show();
    };
    OnboardingPopupComponent.prototype.ngOnDestroy = function () {
        this.closed.emit();
    };
    OnboardingPopupComponent.prototype.show = function () {
        var _this = this;
        if (this.animating) {
            return;
        }
        this.animating = true;
        this.tl
            .clear()
            .fromTo(this.background.nativeElement, 0.6, {
            opacity: 0
        }, {
            opacity: 1,
            ease: Power2.easeOut
        }, 1.0)
            .fromTo(this.root.nativeElement, 0.6, {
            scale: 0,
            opacity: 0
        }, {
            scale: 1,
            opacity: 1,
            ease: Power2.easeOut
        }, 1.0)
            .add(function () {
            _this.animating = false;
        });
    };
    OnboardingPopupComponent.prototype.close = function () {
        var _this = this;
        if (this.animating) {
            return;
        }
        this.animating = true;
        this.tl
            .clear()
            .fromTo(this.background.nativeElement, 0.6, {
            opacity: 1
        }, {
            opacity: 0,
            ease: Power2.easeOut
        })
            .fromTo(this.root.nativeElement, 0.6, {
            scale: 1,
            opacity: 1
        }, {
            scale: 0,
            opacity: 0,
            ease: Power2.easeOut
        }, 0)
            .add(function () {
            _this.animating = false;
            _this.popupComponent.close();
        });
    };
    Object.defineProperty(OnboardingPopupComponent.prototype, "setupGuideLink", {
        get: function () {
            return ['guide'];
        },
        enumerable: true,
        configurable: true
    });
    OnboardingPopupComponent.prototype.getStarted = function () {
        var _this = this;
        this.guideSectionStore
            .getTasks()
            .pipe(untilDestroyed(this))
            .subscribe(function (tasks) {
            var task = tasks.find(function (item) { return item.uniqueName == 'customize_menu'; });
            _this.guideService.setCurrentTask(task);
        });
        this.close();
    };
    return OnboardingPopupComponent;
}());
export { OnboardingPopupComponent };

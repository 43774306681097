var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ChangeDetectorRef, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import isEqual from 'lodash/isEqual';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ViewContextElement } from '@modules/customize';
import { FieldType } from '@modules/fields';
import { HOVER_OUTPUT, PRESSED_OUTPUT } from '@modules/list';
import { BorderPosition, FillType, LayerInteractionType, LayerType } from '@modules/views';
import { isSet } from '@shared';
import { registerLayerComponent } from '../../../data/layer-components';
import { ViewEditorContext } from '../../../services/view-editor-context/view-editor.context';
import { LayerComponent } from '../base/layer.component';
var RectangleLayerComponent = /** @class */ (function (_super) {
    __extends(RectangleLayerComponent, _super);
    function RectangleLayerComponent(editorContext, contextElement, sanitizer, cd) {
        var _this = _super.call(this, editorContext) || this;
        _this.contextElement = contextElement;
        _this.sanitizer = sanitizer;
        _this.cd = cd;
        _this.fills = [];
        _this.borders = [];
        return _this;
    }
    RectangleLayerComponent.prototype.trackFillFn = function (i, item) {
        return item.id;
    };
    RectangleLayerComponent.prototype.ngOnInit = function () {
        var _this = this;
        _super.prototype.ngOnInit.call(this);
        if (this.editorContext) {
            this.hover$ = this.editorContext.isTopHoverLayer$(this.layer);
            this.customizing$ = this.editorContext.isCustomizingLayer$(this.layer);
            this.customizingMultiple$ = this.editorContext.isCustomizingMultipleLayers$();
            this.updating$ = this.getLayerUpdating$(function () { return !_this.editorContext.isCreateTool(); });
        }
        this.getLayer$()
            .pipe(untilDestroyed(this))
            .subscribe(function () {
            _this.updateFills();
            _this.updateBorders();
            _this.updateBorderRadius();
            _this.updateBoxShadows();
            _this.updateLayerContext();
        });
    };
    RectangleLayerComponent.prototype.ngOnDestroy = function () { };
    RectangleLayerComponent.prototype.updateFills = function () {
        var _this = this;
        if (this.fillsSubscription) {
            this.fillsSubscription.unsubscribe();
            this.fillsSubscription = undefined;
        }
        var fills$ = this.layer.fills.slice().reverse()
            .filter(function (item) { return item.enabled; })
            .map(function (item) {
            var icon$ = item.type == FillType.Icon && item.iconFill
                ? item.iconFill.display$({ context: _this.viewContext }).pipe(map(function (value) {
                    return {
                        icon: value.icon,
                        color: value.color,
                        size: isSet(item.iconFill.size)
                            ? item.iconFill.size
                            : Math.min(_this.layer.frame.width, _this.layer.frame.height)
                    };
                }))
                : of(undefined);
            var css$ = item.css$({ frame: _this.layer.frame, context: _this.viewContext });
            var enabled$ = item.enabledInput ? item.enabled$({ context: _this.viewContext }) : of(true);
            return combineLatest(icon$, css$, enabled$).pipe(map(function (_a) {
                var icon = _a[0], css = _a[1], enabled = _a[2];
                return {
                    id: item.id,
                    background: isSet(css.background) ? _this.sanitizer.bypassSecurityTrustStyle(css.background) : undefined,
                    width: css.width,
                    height: css.height,
                    transform: isSet(css.transform) ? _this.sanitizer.bypassSecurityTrustStyle(css.transform) : undefined,
                    icon: icon,
                    opacity: item.type != FillType.Color ? item.opacity : null,
                    enabled: enabled
                };
            }));
        });
        if (!fills$.length) {
            this.fills = [];
            this.cd.markForCheck();
            return;
        }
        this.fillsSubscription = combineLatest(fills$)
            .pipe(untilDestroyed(this))
            .subscribe(function (fills) {
            _this.fills = fills.filter(function (item) { return item.enabled; });
            _this.cd.markForCheck();
        });
    };
    RectangleLayerComponent.prototype.updateBorders = function () {
        var _this = this;
        if (this.bordersSubscription) {
            this.bordersSubscription.unsubscribe();
            this.bordersSubscription = undefined;
        }
        var borders$ = this.layer.borders.slice().reverse()
            .filter(function (item) { return item.enabled; })
            .map(function (item) {
            var border$ = item.cssBorder$({ context: _this.viewContext });
            var enabled$ = item.enabledInput ? item.enabled$({ context: _this.viewContext }) : of(true);
            return combineLatest(border$, enabled$).pipe(map(function (_a) {
                var border = _a[0], enabled = _a[1];
                var position;
                if (item.position == BorderPosition.Center) {
                    position = -item.thickness * 0.5;
                }
                else if (item.position == BorderPosition.Outside) {
                    position = -item.thickness;
                }
                else {
                    position = 0;
                }
                var borderRadius = _this.layer.cornerRadius.cssBorderRadius(position * -1);
                return {
                    border: isSet(border) ? _this.sanitizer.bypassSecurityTrustStyle(border) : undefined,
                    position: position,
                    borderRadius: _this.sanitizer.bypassSecurityTrustStyle(borderRadius),
                    enabled: enabled
                };
            }));
        });
        if (!borders$.length) {
            this.borders = [];
            this.cd.markForCheck();
            return;
        }
        this.bordersSubscription = combineLatest(borders$)
            .pipe(untilDestroyed(this))
            .subscribe(function (borders) {
            _this.borders = borders.filter(function (item) { return item.enabled && isSet(item.border); });
            _this.cd.markForCheck();
        });
    };
    RectangleLayerComponent.prototype.updateBorderRadius = function () {
        this.borderRadius = this.sanitizer.bypassSecurityTrustStyle(this.layer.cornerRadius.cssBorderRadius());
        this.cd.markForCheck();
    };
    RectangleLayerComponent.prototype.updateBoxShadows = function () {
        var _this = this;
        if (this.boxShadowSubscription) {
            this.boxShadowSubscription.unsubscribe();
            this.boxShadowSubscription = undefined;
        }
        var shadows$ = this.layer.shadows
            .filter(function (item) { return item.enabled; })
            .map(function (item) {
            var boxShadow$ = item.cssBoxShadow$({ context: _this.viewContext });
            var enabled$ = item.enabledInput ? item.enabled$({ context: _this.viewContext }) : of(true);
            return combineLatest(boxShadow$, enabled$).pipe(map(function (_a) {
                var boxShadow = _a[0], enabled = _a[1];
                return {
                    boxShadow: boxShadow,
                    enabled: enabled
                };
            }));
        });
        if (!shadows$.length) {
            this.boxShadow = undefined;
            this.cd.markForCheck();
            return;
        }
        this.boxShadowSubscription = combineLatest(shadows$)
            .pipe(untilDestroyed(this))
            .subscribe(function (shadows) {
            _this.boxShadow = _this.sanitizer.bypassSecurityTrustStyle(shadows
                .filter(function (item) { return item.enabled; })
                .map(function (item) { return item.boxShadow; })
                .join(','));
            _this.cd.markForCheck();
        });
    };
    RectangleLayerComponent.prototype.updateLayerContext = function () {
        var hoverOutput = this.layer.interactions.some(function (item) { return item.type == LayerInteractionType.HoverOutput; });
        var pressedOutput = this.layer.interactions.some(function (item) { return item.type == LayerInteractionType.PressedOutput; });
        var anyOutputs = hoverOutput || pressedOutput;
        var registered = this.contextElement.isRegistered();
        if (anyOutputs && !registered) {
            this.contextElement.initElement({
                uniqueName: this.layer.id,
                name: this.layer.name,
                icon: this.layer.icon
            });
        }
        else if (anyOutputs && registered) {
            this.contextElement.initInfo({
                name: this.layer.name,
                icon: this.layer.icon
            }, true);
        }
        else if (!anyOutputs && registered) {
            this.contextElement.unregister();
        }
        if (anyOutputs) {
            var outputs = [];
            if (hoverOutput) {
                outputs.push({
                    uniqueName: HOVER_OUTPUT,
                    name: "Layer is hovered",
                    icon: 'target',
                    fieldType: FieldType.Boolean,
                    defaultValue: false,
                    external: true
                });
            }
            if (pressedOutput) {
                outputs.push({
                    uniqueName: PRESSED_OUTPUT,
                    name: "Layer is pressed",
                    icon: 'select_all',
                    fieldType: FieldType.Boolean,
                    defaultValue: false,
                    external: true
                });
            }
            if (!isEqual(this.contextElement.outputs.map(function (item) { return item.uniqueName; }), outputs.map(function (item) { return item.uniqueName; }))) {
                this.contextElement.setOutputs(outputs);
            }
        }
    };
    RectangleLayerComponent.prototype.onFrameUpdate = function (frame) {
        _super.prototype.onFrameUpdate.call(this, frame);
        this.updateFills();
    };
    return RectangleLayerComponent;
}(LayerComponent));
export { RectangleLayerComponent };
registerLayerComponent(LayerType.Rectangle, RectangleLayerComponent);

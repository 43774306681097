import { Type } from '@angular/core';

import { WorkflowStepType } from '@modules/workflow';

import { WorkflowStepComponent } from '../components/workflow/steps/base-workflow-step/base-workflow-step.component';

const workflowStepsByType: { type: WorkflowStepType; cls: Type<WorkflowStepComponent> }[] = [];

export function getWorkflowStepComponent(type: WorkflowStepType): Type<WorkflowStepComponent> {
  const instance = workflowStepsByType.find(item => item.type == type);

  if (!instance) {
    return;
  }

  return instance.cls;
}

export function registerWorkflowStepComponent(type: WorkflowStepType, cls: Type<WorkflowStepComponent>) {
  workflowStepsByType.push({ type: type, cls: cls });
}

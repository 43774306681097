import pickBy from 'lodash/pickBy';

import { Input } from '@modules/fields';

import { registerElementForType } from '../element-items';
import { ElementType } from '../element-type';
import { ElementItem } from './base';
import { ResizableElementItem } from './widget';

export enum BarCodeFormat {
  CODE128 = 'CODE128',
  CODE128A = 'CODE128A',
  CODE128B = 'CODE128B',
  CODE128C = 'CODE128C',
  EAN13 = 'EAN13',
  EAN8 = 'EAN8',
  EAN5 = 'EAN5',
  EAN2 = 'EAN2',
  UPC = 'UPC',
  CODE39 = 'CODE39',
  ITF14 = 'ITF14',
  ITF = 'ITF',
  MSI = 'MSI',
  MSI10 = 'MSI10',
  MSI11 = 'MSI11',
  MSI1010 = 'MSI1010',
  MSI1110 = 'MSI1110',
  Pharmacode = 'pharmacode',
  Codabar = 'codabar'
}

export class BarCodeElementItem extends ElementItem implements ResizableElementItem {
  public type = ElementType.BarCode;
  public format: BarCodeFormat = BarCodeFormat.CODE128;
  public value: Input;
  public height: number;
  public fillColor: string;
  public backgroundColor = 'white';
  public displayText = true;

  deserialize(data: Object): BarCodeElementItem {
    super.deserialize(data);
    this.format = this.params['format'];
    this.height = this.params['height'];
    this.fillColor = this.params['fill_color'];
    this.backgroundColor = this.params['background_color'];

    if (this.params['value']) {
      this.value = new Input().deserialize(this.params['value']);
    }

    if (this.params.hasOwnProperty('display_text')) {
      this.displayText = this.params['display_text'];
    }

    return this;
  }

  serialize(fields?: string[]): Object {
    this.params = {};
    this.params['format'] = this.format;
    this.params['value'] = this.value ? this.value.serialize() : null;
    this.params['height'] = this.height;
    this.params['fill_color'] = this.fillColor;
    this.params['background_color'] = this.backgroundColor;
    this.params['display_text'] = this.displayText;

    let data = super.serialize();
    if (fields) {
      data = <Object>pickBy(data, (v, k) => fields.includes(k));
    }
    return data;
  }

  get typeStr(): string {
    return 'bar code';
  }

  get analyticsName(): string {
    return 'bar_code';
  }

  defaultName() {
    return 'Bar code';
  }
}

registerElementForType(ElementType.BarCode, BarCodeElementItem);

<div class="sidebar__element">
  <div class="sidebar-list-item">
    <div class="sidebar-list-item__left">
      <img
        *ngIf="item.icon"
        class="sidebar-list-item__icon"
        [src]="'/assets/images/resources/icons/' + item.icon + '.svg' | appDeployUrl"
      />
      <span *ngIf="!item.icon" class="sidebar-list-item__icon icon-bank"></span>
    </div>
    <div class="sidebar-list-item__main">
      <div class="sidebar-list-item__title">{{ item.title }}</div>
      <div *ngIf="item.subtitle" class="sidebar-list-item__description">{{ item.subtitle }}</div>
    </div>
    <div *ngIf="item.resource" class="sidebar-list-item__right">
      <a
        href="javascript:void(0)"
        class="sidebar-list-item__action sidebar-list-item__action_button icon-repeat"
        (click)="reload()"
      ></a>
      <a
        href="javascript:void(0)"
        class="sidebar-list-item__action sidebar-list-item__action_button icon-edit"
        (click)="editResource()"
      ></a>
    </div>
  </div>
</div>

<ng-container *ngIf="sections?.length > 1">
  <app-sidebar-collapse
    *ngFor="let section of sections"
    [title]="section.title | appCapitalize"
    [description]="section.description"
    [descriptionWhenOpened]="section.description"
    [context]="collapseContext"
    [openedInitial]="section.openedInitial"
    #collapse
  >
    <app-change-customize-bar-templates-group-section
      [section]="section"
      [context]="context"
      [dropListGroups]="dropListGroups"
      [focus]="(collapseContext.opened$ | async) === collapse"
    >
    </app-change-customize-bar-templates-group-section>
  </app-sidebar-collapse>
</ng-container>

<ng-container *ngIf="sections?.length == 1">
  <app-change-customize-bar-templates-group-section
    [section]="sections[0]"
    [context]="context"
    [dropListGroups]="dropListGroups"
  >
  </app-change-customize-bar-templates-group-section>
</ng-container>

<div *ngIf="!sections?.length" class="sidebar__message">
  Nothing here
</div>

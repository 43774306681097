<div *ngIf="!pinned" class="token-popup__item">
  <a
    href="javascript:void(0)"
    class="token-popup-list-item token-popup-list-item_hoverable token-popup-list-item_clickable"
    [class.token-popup-list-item_disabled]="disabled || submitLoading"
    (click)="executeAction(item, root_element)"
    #root_element
  >
    <div class="token-popup-list-item__inner">
      <div class="token-popup-list-item__line">
        <span class="token-popup-list-item__left">
          <app-loader-small *ngIf="submitLoading" class="token-popup-list-item__icon"></app-loader-small>
          <span class="token-popup-list-item__icon" [appIcon]="actionIcon(item)"> </span>
        </span>

        <span class="token-popup-list-item__main">
          {{ verboseName | appCapitalize }}
        </span>
      </div>
    </div>
  </a>
</div>

<a
  *ngIf="pinned"
  class="button list__actions-item"
  [class.button_primary]="!item.tint && style == tintStyles.Primary"
  [class.button_primary-transparent]="!item.tint && style == tintStyles.Transparent"
  [class.button_icon]="!displayTitle()"
  [class.button_disabled]="disabled || submitLoading"
  [appButtonTintColor]="item.tint || accentColor"
  [appButtonTintColorStyle]="style"
  (click)="executeAction(item, root_element)"
  #root_element
>
  <app-loader-small
    *ngIf="submitLoading"
    class="button__icon"
    [class.button__icon_left]="displayTitle()"
  ></app-loader-small>
  <span
    *ngIf="item.icon && !submitLoading"
    class="icon button__icon"
    [class.button__icon_left]="displayTitle()"
    [appIcon]="item.icon"
  >
  </span>

  <span *ngIf="displayTitle()" class="button__label">
    {{ verboseName | appCapitalize }}
  </span>
</a>

<span
  *ngIf="!isVisible && (customizeService.enabled$ | async)"
  class="element__indicator icon-eye"
  [class.element__indicator_outside]="pinned"
  [class.element__indicator_tiny]="!pinned"
  [appTip]="'Action is hidden'"
></span>

<div
  cdkDropList
  [cdkDropListLockAxis]="'y'"
  class="sidebar-list"
  [class.sidebar-list_disabled]="submitLoading"
  [class.sidebar-list__interaction-disabled]="submitControlLoading"
  (cdkDropListDropped)="dragDrop($event)"
>
  <div *ngIf="searchEnabled" class="sidebar-list__item">
    <div class="input-icon-wrapper">
      <a
        *ngIf="search"
        href="javascript:void(0)"
        class="input-icon input-icon_toolbar input-icon_interactive input-icon_right icon-close"
        (click)="clearSearch()"
      ></a>
      <input
        class="input input_toolbar input_background input_icon-right sidebar__input-inner"
        type="text"
        [placeholder]="'Search...'"
        [appAutofocus]="searchFocus"
        [(ngModel)]="search"
        (keyup)="searchUpdated.next()"
        (blur)="onSearchBlur()"
        #search_autofocus
      />
    </div>
  </div>

  <div *ngFor="let control of displayItems; let i = index" cdkDrag class="sidebar-list__item">
    <app-display-fields-edit-item
      [form]="form"
      [control]="control"
      [index]="i"
      [modelDescription]="modelDescription"
      [search]="search"
      [loading]="submitControlLoading === control"
      [visibleEditable]="visibleEditable"
      (customize)="customize(control)"
      (moveToTop)="form.moveToTop(control)"
      (removeControl)="form.removeControl(control)"
      (openViewEditor)="openViewEditor(control, $event)"
      (openCustomViewTemplates)="openCustomViewTemplates(control)"
      (changeMapping)="changeMapping(control, $event)"
      (updateCustomViewTemplate)="updateCustomViewTemplate(control, $event)"
      (rename)="renameCustomView(control, $event)"
    ></app-display-fields-edit-item>
  </div>

  <!--  <div *ngIf="!form.form.controls.length" class="sidebar__message">-->
  <!--    There are no {{itemName}}-->
  <!--  </div>-->

  <div
    *ngIf="collapsible && !(search | appIsSet) && form.controls.length > maxDisplayInitial"
    class="sidebar-list__buttons"
  >
    <a
      *ngIf="collapsed"
      href="javascript:void(0)"
      class="button button_transparent button_small sidebar-list__buttons-item"
      (click)="setCollapsed(false)"
    >
      <span class="icon-arrow_down_2 button__icon button__icon_left"></span>
      <span class="button__label">Show all ({{ form.controls.length }})</span>
    </a>
    <a
      *ngIf="!collapsed"
      href="javascript:void(0)"
      class="button button_transparent button_small sidebar-list__buttons-item"
      (click)="setCollapsed(true)"
    >
      <span class="icon-arrow_up_2 button__icon button__icon_left"></span>
      <span class="button__label">Collapse</span>
    </a>
  </div>

  <div *ngIf="(modelDescription && isLookupsSupported) || contextElement" class="sidebar-list__buttons">
    <!--    <mat-menu #add_db_field_menu="matMenu">-->
    <!--      <ng-template matMenuContent let-trigger="trigger">-->
    <!--        <app-field-type-menu-->
    <!--          (select)="addComputedItem($event); trigger.closeMenu()"-->
    <!--          (click)="$event.stopPropagation()"-->
    <!--        ></app-field-type-menu>-->
    <!--      </ng-template>-->
    <!--    </mat-menu>-->

    <mat-menu #add_field_menu="matMenu">
      <ng-template matMenuContent let-trigger="trigger">
        <button
          *ngIf="contextElement"
          mat-menu-item
          class="mat-menu-item-odd"
          [disableRipple]="true"
          (click)="addComputedItem()"
        >
          <span class="mat-menu-item-icon icon-function"></span>
          Add computed {{ itemName }}
        </button>

        <button
          *ngIf="customViewEnabled && contextElement"
          mat-menu-item
          class="mat-menu-item-odd"
          [disableRipple]="true"
          (click)="openViewEditor()"
        >
          <span class="mat-menu-item-icon icon-canvas"></span>
          Create {{ itemName }} with Designer
        </button>

        <button
          *ngIf="customViewEnabled && contextElement"
          mat-menu-item
          class="mat-menu-item-odd"
          [disableRipple]="true"
          (click)="openCustomViewTemplates()"
        >
          <span class="mat-menu-item-icon icon-blocks"></span>
          Use Marketplace {{ itemName }}
        </button>

        <mat-menu class="mat-menu-panel_wide" #add_lookup_field_menu="matMenu">
          <ng-template matMenuContent let-trigger="trigger">
            <div style="min-width: 300px;">
              <app-select-model-field
                [modelDescription]="modelDescription"
                [searchPlaceholder]="'Search field...'"
                [onlyNestedFields]="true"
                [relationsSelectEnabled]="false"
                [emptyMessage]="
                  'This collection does not have any links to other collections. Add <strong>Link to record</strong> fields to create relations between collections.'
                "
                (selected)="addLookupItem($event); trigger.closeMenu()"
                (click)="$event.stopPropagation()"
              ></app-select-model-field>
            </div>
          </ng-template>
        </mat-menu>

        <button
          *ngIf="modelDescription && isLookupsSupported"
          mat-menu-item
          class="mat-menu-item-odd"
          [disableRipple]="true"
          [matMenuTriggerFor]="add_lookup_field_menu"
          [matMenuTriggerData]="{ trigger: trigger }"
        >
          <span class="mat-menu-item-icon icon-lookup"></span>
          Lookup related field
        </button>

        <mat-menu class="mat-menu-panel_wide" #add_aggregate_field_menu="matMenu">
          <ng-template matMenuContent let-trigger="trigger">
            <div style="min-width: 300px;">
              <app-select-model-field
                [modelDescription]="modelDescription"
                [searchPlaceholder]="'Search field...'"
                [onlyNestedFields]="true"
                [relationsEnabled]="true"
                [fieldsSelectEnabled]="false"
                [aggregationsEnabled]="false"
                [emptyMessage]="
                  'This collection does not have any links to other collections. Add <strong>Link to record</strong> fields to create relations between collections.'
                "
                (selected)="addAggregateItem($event); trigger.closeMenu()"
                (click)="$event.stopPropagation()"
              ></app-select-model-field>
            </div>
          </ng-template>
        </mat-menu>

        <button
          *ngIf="modelDescription && isLookupsSupported"
          mat-menu-item
          class="mat-menu-item-odd"
          [disableRipple]="true"
          [matMenuTriggerFor]="add_aggregate_field_menu"
          [matMenuTriggerData]="{ trigger: trigger }"
        >
          <span class="mat-menu-item-icon icon-spiral"></span>
          Rollup related records
        </button>
      </ng-template>
    </mat-menu>

    <a
      href="javascript:void(0)"
      class="button button_orange-transparent button_small sidebar-list__buttons-item"
      [matMenuTriggerFor]="add_field_menu"
      [matMenuTriggerData]="{ trigger: trigger }"
      #trigger="matMenuTrigger"
    >
      <span class="icon icon-plus button__icon button__icon_left"></span>
      <span class="button__label">Add {{ itemName | appCapitalize }}</span>
    </a>

    <a
      [appDocumentation]="'computed-columns'"
      [appDocumentationLeft]="true"
      [appDocumentationSource]="analyticsSource + '_custom-field'"
      class="button button_orange-alternative button_small button_icon sidebar-list__buttons-item"
      [appTip]="'See how it works'"
      [appTipOptions]="{ side: 'top' }"
      (click)="$event.stopPropagation()"
    >
      <div class="button__icon icon-help"></div>
    </a>
  </div>

  <div *ngIf="submitLoading" class="sidebar__loader">
    <app-loader [fill]="true"></app-loader>
  </div>
</div>

import { gteFieldLookup, lteFieldLookup } from '../base-lookups';
import { FieldLookup } from './base';

export abstract class BetweenFieldLookup extends FieldLookup {
  excludable = false;

  abstract from(value: string): string;
  abstract to(value: string): string;

  compute(
    field: string[],
    value: any,
    exclude: boolean
  ): { field: string[]; lookup: FieldLookup; value: any; exclude?: boolean }[] {
    return exclude
      ? []
      : [
          {
            field: field,
            lookup: gteFieldLookup,
            value: this.from(value)
          },
          {
            field: field,
            lookup: lteFieldLookup,
            value: this.to(value)
          }
        ];
  }
}

<!--<div class="sidebar__element">-->
<!--  <div class="sidebar-list-item">-->
<!--    <div class="sidebar-list-item__left">-->
<!--      <img-->
<!--        *ngIf="group.icon"-->
<!--        class="sidebar-list-item__icon"-->
<!--        [src]="'/assets/images/resources/icons/' + group.icon + '.svg' | appDeployUrl"-->
<!--      />-->
<!--      <span *ngIf="!group.icon" class="sidebar-list-item__icon icon-bank"></span>-->
<!--    </div>-->
<!--    <div class="sidebar-list-item__main">-->
<!--      <div class="sidebar-list-item__title">{{ group.title }}</div>-->
<!--      <div *ngIf="group.subtitle" class="sidebar-list-item__description">{{ group.subtitle }}</div>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->

<app-sidebar-section>
  <div class="sidebar__list">
    <div class="sidebar-list">
      <div *ngFor="let item of items" class="sidebar-list__item">
        <div class="sidebar-list-item sidebar-list-item_clickable" (click)="selected.emit(item)">
          <div class="sidebar-list-item__left">
            <img
              *ngIf="item.image"
              class="sidebar-list-item__icon"
              [src]="'/assets/images/resources/icons/' + item.image + '.svg' | appDeployUrl"
            />
            <span *ngIf="!item.image" class="sidebar-list-item__icon icon-bank"></span>
          </div>
          <div class="sidebar-list-item__main">
            <div class="sidebar-list-item__title">{{ item.label }}</div>
            <div *ngIf="item.featuresDescription || item.shortDescription" class="sidebar-list-item__description">
              {{ item.featuresDescription || item.shortDescription | appCapitalize }}
            </div>
          </div>
          <div class="sidebar-list-item__right">
            <span class="sidebar-list-item__action icon-arrow_forward_2"></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</app-sidebar-section>

<div *ngIf="!items?.length" class="sidebar__message">
  Nothing here
</div>

<div class="compact-inputs-row compact-inputs-row_vertical-align_bottom">
  <div class="compact-inputs-row__item compact-inputs-row__item_fill" style="margin-right: 10px; margin-bottom: 5px;">
    <div class="compact-heading">
      Opacity
    </div>

    <app-slider
      [baseControl]="valueControl"
      [min]="0"
      [max]="100"
      [debounce]="0"
      [valueLabel]="false"
      [labels]="false"
      [trackBackground]="'#3a454d'"
      [fillBackground]="'#eb633a'"
      [thumbBackgroundColor]="'#99a7b2'"
      [thumbBorderColor]="'#fff'"
      [dark]="true"
      [compact]="true"
      (baseChange)="updateValue(valueControl.value)"
    ></app-slider>
  </div>

  <div class="compact-inputs-row__item">
    <app-compact-input
      [formControl]="valueControl"
      [postfix]="'%'"
      [placeholder]="'100'"
      [widthPx]="60"
      [number]="true"
      [numberOptions]="{ min: 0, max: 100 }"
      [numberDefault]="100"
      (change)="updateValue(valueControl.value)"
    ></app-compact-input>
  </div>
</div>

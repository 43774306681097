import { elementItemCategories } from '../data/elements/element-type';
import { ViewContext } from '../data/view-context';
import { ViewContextElement } from '../data/view-context-element';

export function getTokenSubtitle(contextElement: ViewContextElement): string {
  if (contextElement && contextElement.popup) {
    return contextElement.popup.name;
  } else if (contextElement && contextElement.element) {
    return elementItemCategories[contextElement.element.type];
  }
}

export function elementHasParent(
  element: ViewContextElement,
  parent: ViewContextElement,
  context: ViewContext
): boolean {
  let currentElement = element;

  while (true) {
    const contextItem = context.elements.find(item => item.element === currentElement);
    if (!contextItem) {
      break;
    }

    if (contextItem.parent === parent) {
      return true;
    } else {
      currentElement = contextItem.parent;
    }
  }

  return false;
}

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./section.component";
var styles_SectionComponent = [];
var RenderType_SectionComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SectionComponent, data: {} });
export { RenderType_SectionComponent as RenderType_SectionComponent };
export function View_SectionComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 8, "div", [["class", "section__inner"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "div", [["class", "section__toolbar"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "div", [["class", "section__toolbar-inner"]], null, null, null, null, null)), i0.ɵncd(null, 0), i0.ɵncd(null, 1), (_l()(), i0.ɵeld(5, 0, null, null, 1, "div", [["class", "section__columns"]], null, null, null, null, null)), i0.ɵncd(null, 2), (_l()(), i0.ɵeld(7, 0, null, null, 1, "div", [["class", "section__footer"]], null, null, null, null, null)), i0.ɵncd(null, 3)], null, null); }
export function View_SectionComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-section", [], [[2, "section", null], [2, "section_top", null], [2, "section_no-top-margin", null], [2, "section_no-bottom-margin", null], [2, "section_screen", null], [2, "section_disabled", null], [2, "section_full-border-radius", null], [2, "section_small-border-radius", null]], null, null, View_SectionComponent_0, RenderType_SectionComponent)), i0.ɵdid(1, 114688, null, 0, i1.SectionComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).cls; var currVal_1 = i0.ɵnov(_v, 1).top; var currVal_2 = i0.ɵnov(_v, 1).noTopMargin; var currVal_3 = i0.ɵnov(_v, 1).noBottomMargin; var currVal_4 = i0.ɵnov(_v, 1).screen; var currVal_5 = i0.ɵnov(_v, 1).disabled; var currVal_6 = i0.ɵnov(_v, 1).fullBorderRadius; var currVal_7 = i0.ɵnov(_v, 1).smallBorderRadius; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }); }
var SectionComponentNgFactory = i0.ɵccf("app-section", i1.SectionComponent, View_SectionComponent_Host_0, { cls: "cls", top: "top", noTopMargin: "noTopMargin", noBottomMargin: "noBottomMargin", screen: "screen", disabled: "disabled", fullBorderRadius: "fullBorderRadius", smallBorderRadius: "smallBorderRadius" }, {}, ["app-section-toolbar", "*", "app-section-column", "app-section-footer"]);
export { SectionComponentNgFactory as SectionComponentNgFactory };

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../filter-edit/filter-edit.component.ngfactory";
import * as i2 from "../filter-edit/filter-edit.form";
import * as i3 from "../../../projects/stores/current-environment.store";
import * as i4 from "../../../model-queries/stores/model-description.store";
import * as i5 from "../filter-edit/filter-edit.component";
import * as i6 from "@angular/cdk/overlay";
import * as i7 from "@angular/cdk/bidi";
import * as i8 from "./filter-edit-overlay.component";
var styles_FilterEditOverlayComponent = [];
var RenderType_FilterEditOverlayComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_FilterEditOverlayComponent, data: {} });
export { RenderType_FilterEditOverlayComponent as RenderType_FilterEditOverlayComponent };
function View_FilterEditOverlayComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "popover2 overlay-content overlay-content_animated"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "div", [["class", "popover2__inner"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 2, "app-filter-edit", [], null, [[null, "added"], [null, "edited"], [null, "deleted"], [null, "filtersUpdated"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("added" === en)) {
        var pd_0 = (_co.added.emit($event) !== false);
        ad = (pd_0 && ad);
    } if (("edited" === en)) {
        var pd_1 = (_co.edited.emit($event) !== false);
        ad = (pd_1 && ad);
    } if (("deleted" === en)) {
        var pd_2 = (_co.deleted.emit($event) !== false);
        ad = (pd_2 && ad);
    } if (("filtersUpdated" === en)) {
        _co.filtersUpdated.emit($event);
        var pd_3 = (_co.close() !== false);
        ad = (pd_3 && ad);
    } return ad; }, i1.View_FilterEditComponent_0, i1.RenderType_FilterEditComponent)), i0.ɵprd(131584, null, i2.FilterEditForm, i2.FilterEditForm, [i3.CurrentEnvironmentStore, i4.ModelDescriptionStore]), i0.ɵdid(4, 245760, [[2, 4]], 0, i5.FilterEditComponent, [i2.FilterEditForm, i0.ChangeDetectorRef], { dataSource: [0, "dataSource"], filters: [1, "filters"], filter: [2, "filter"], field: [3, "field"], onlyVisible: [4, "onlyVisible"], optionsFilter: [5, "optionsFilter"] }, { added: "added", edited: "edited", deleted: "deleted", filtersUpdated: "filtersUpdated" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.dataSource; var currVal_1 = _co.filters; var currVal_2 = _co.filter; var currVal_3 = _co.field; var currVal_4 = _co.onlyVisible; var currVal_5 = _co.optionsFilter; _ck(_v, 4, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); }, null); }
export function View_FilterEditOverlayComponent_0(_l) { return i0.ɵvid(2, [i0.ɵqud(402653184, 1, { cdkConnectedOverlay: 0 }), i0.ɵqud(671088640, 2, { filterEditComponent: 0 }), (_l()(), i0.ɵand(16777216, null, null, 2, function (_v, en, $event) { var ad = true; var _co = _v.component; if (("backdropClick" === en)) {
        var pd_0 = (_co.onBackgroundClick() !== false);
        ad = (pd_0 && ad);
    } if (("detach" === en)) {
        var pd_1 = (_co.setDropdownOpened(false) !== false);
        ad = (pd_1 && ad);
    } return ad; }, View_FilterEditOverlayComponent_1)), i0.ɵdid(3, 671744, [[1, 4]], 0, i6.CdkConnectedOverlay, [i6.Overlay, i0.TemplateRef, i0.ViewContainerRef, i6.ɵc, [2, i7.Directionality]], { origin: [0, "origin"], positions: [1, "positions"], backdropClass: [2, "backdropClass"], panelClass: [3, "panelClass"], open: [4, "open"], hasBackdrop: [5, "hasBackdrop"], growAfterOpen: [6, "growAfterOpen"], push: [7, "push"] }, { backdropClick: "backdropClick", detach: "detach" }), i0.ɵpad(4, 2)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.origin; var currVal_1 = _co.popoverPositions; var currVal_2 = "popover2-backdrop"; var currVal_3 = _ck(_v, 4, 0, "overlay", "overlay_horizontal_center"); var currVal_4 = _co.dropdownOpened; var currVal_5 = true; var currVal_6 = true; var currVal_7 = true; _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }, null); }
export function View_FilterEditOverlayComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-filter-edit-overlay", [], null, null, null, View_FilterEditOverlayComponent_0, RenderType_FilterEditOverlayComponent)), i0.ɵdid(1, 4440064, null, 0, i8.FilterEditOverlayComponent, [i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FilterEditOverlayComponentNgFactory = i0.ɵccf("app-filter-edit-overlay", i8.FilterEditOverlayComponent, View_FilterEditOverlayComponent_Host_0, { dataSource: "dataSource", filters: "filters", filter: "filter", field: "field", onlyVisible: "onlyVisible", optionsFilter: "optionsFilter", origin: "origin", trigger: "trigger", triggerManual: "triggerManual" }, { added: "added", edited: "edited", deleted: "deleted", filtersUpdated: "filtersUpdated" }, []);
export { FilterEditOverlayComponentNgFactory as FilterEditOverlayComponentNgFactory };

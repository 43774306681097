export enum TaskCompleteAction {
  Hover = 'hover',
  Click = 'click',
  Input = 'input'
}

export interface TaskScenarioItemTip {
  text: string;
  selector: string;
  selectorFilter?: (el: any) => any;
  completeAction?: TaskCompleteAction;
}

export interface TaskScenarioItem {
  name: string;
  tip?: TaskScenarioItemTip;
}

export interface TaskScenarioPage {
  link: any[] | string;
  noAutoRedirect?: boolean;
  items: TaskScenarioItem[];
}

export class TaskScenario {
  constructor(public pages: TaskScenarioPage[]) {}

  get firstLink() {
    if (!this.pages.length) {
      return;
    }

    return this.pages[0].link;
  }

  get isEmpty() {
    return !this.pages.length || !this.pages[0].items.length;
  }
}

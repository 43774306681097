var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import pickBy from 'lodash/pickBy';
import { Input } from '@modules/fields';
import { registerElementForType } from '../element-items';
import { ElementType } from '../element-type';
import { ElementItem } from './base';
export var FileViewerType;
(function (FileViewerType) {
    FileViewerType["Native"] = "native";
    FileViewerType["Google"] = "google";
})(FileViewerType || (FileViewerType = {}));
var FileViewerElementItem = /** @class */ (function (_super) {
    __extends(FileViewerElementItem, _super);
    function FileViewerElementItem() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.type = ElementType.FileViewer;
        _this.border = false;
        _this.viewerType = FileViewerType.Native;
        _this.toolbar = false;
        return _this;
    }
    FileViewerElementItem.prototype.deserialize = function (data) {
        _super.prototype.deserialize.call(this, data);
        this.url = this.params['url'];
        this.width = this.params['width'];
        this.height = this.params['height'];
        this.border = this.params['border'];
        this.toolbar = this.params['toolbar'];
        if (this.params['url']) {
            // Backward compatibility
            if (typeof this.params['url'] == 'string') {
                this.url = new Input().deserializeFromStatic('value', this.params['url']);
            }
            else {
                this.url = new Input().deserialize(this.params['url']);
            }
        }
        if (this.params['viewer_type']) {
            this.viewerType = this.params['viewer_type'];
        }
        return this;
    };
    FileViewerElementItem.prototype.serialize = function (fields) {
        this.params = {};
        this.params['url'] = this.url ? this.url.serialize() : null;
        this.params['width'] = this.width;
        this.params['height'] = this.height;
        this.params['border'] = this.border;
        this.params['viewer_type'] = this.viewerType;
        this.params['toolbar'] = this.toolbar;
        var data = _super.prototype.serialize.call(this);
        if (fields) {
            data = pickBy(data, function (v, k) { return fields.includes(k); });
        }
        return data;
    };
    Object.defineProperty(FileViewerElementItem.prototype, "typeStr", {
        get: function () {
            return 'embed file';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FileViewerElementItem.prototype, "analyticsName", {
        get: function () {
            return 'file_viewer';
        },
        enumerable: true,
        configurable: true
    });
    FileViewerElementItem.prototype.defaultName = function () {
        return 'File Viewer';
    };
    return FileViewerElementItem;
}(ElementItem));
export { FileViewerElementItem };
registerElementForType(ElementType.FileViewer, FileViewerElementItem);

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { PopoverModule } from '@common/popover';
import { RoutingModule } from '@modules/routing';
import { SharedModule } from '@shared';

import { DemoBlockComponent } from './components/demo-block/demo-block.component';
import { DemoComponent } from './components/demo/demo.component';
import { ProBlockComponent } from './components/pro-block/pro-block.component';
import { DemoOnlyDirective } from './directives/demo-only/demo-only.directive';

@NgModule({
  imports: [CommonModule, RoutingModule, FormsModule, ReactiveFormsModule, PopoverModule, SharedModule],
  declarations: [DemoBlockComponent, ProBlockComponent, DemoComponent, DemoOnlyDirective],
  entryComponents: [DemoBlockComponent, ProBlockComponent],
  exports: [DemoOnlyDirective]
})
export class DemoModule {}

<div class="menu-pages">
  <div class="menu-pages__header">
    <div class="menu-pages-header">
      <ng-container *ngIf="!editing">
        <div class="menu-pages-header__title">Pages</div>
        <div class="menu-pages-header__actions">
          <a
            href="javascript:void(0)"
            class="menu-pages-header__actions-item menu-pages-header__actions-item_icon"
            [appTip]="'Edit pages'"
            (click)="setEditing(true)"
          >
            <span class="menu-pages-header__actions-item__icon icon-edit"></span>
          </a>
          <a
            href="javascript:void(0)"
            class="menu-pages-header__actions-item menu-pages-header__actions-item_icon"
            cdkOverlayOrigin
            #trigger="cdkOverlayOrigin"
            [appTip]="'Create new page'"
            (click)="createPage()"
          >
            <app-loader-small *ngIf="createPageLoading" class="button__icon"></app-loader-small>
            <span *ngIf="!createPageLoading" class="menu-pages-header__actions-item__icon icon-new_document"></span>
          </a>
        </div>
      </ng-container>

      <ng-container *ngIf="editing">
        <div class="menu-pages-header__title">
          <a href="javascript:void(0)" class="menu-pages-header__actions-item" (click)="setEditing(false)">
            <span
              class="menu-pages-header__actions-item__icon menu-pages-header__actions-item__icon_left icon-close"
            ></span>
            <span class="menu-pages-header__actions-item__label">Cancel</span>
          </a>
        </div>
        <div class="menu-pages-header__actions">
          <a
            href="javascript:void(0)"
            class="menu-pages-header__actions-item menu-pages-header__actions-item_icon menu-pages-header__actions-item_danger"
            [class.menu-pages-header__actions-item_disabled]="!selectedPagesUids?.length || deletePagesLoading"
            [appTip]="'Delete pages'"
            (click)="deleteSelectedPages()"
          >
            <app-loader-small *ngIf="deletePagesLoading" class="button__icon"></app-loader-small>
            <span *ngIf="!deletePagesLoading" class="menu-pages-header__actions-item__icon icon-bin"></span>
          </a>
        </div>
      </ng-container>
    </div>
  </div>
  <div class="menu-pages__content" xsScrollable [xsScrollableOptions]="{ stopPropagationAlways: true }">
    <div class="menu-pages__search">
      <div class="menu-pages-input">
        <span class="menu-pages-input__icon icon-search"></span>
        <input
          class="menu-pages-input__control"
          placeholder="Search pages..."
          [appAutofocus]="true"
          [(ngModel)]="search"
          (keyup)="onSearchKey($event)"
        />
        <span *ngIf="search | appIsSet" class="menu-pages-input__close icon-close" (click)="resetSearch()"></span>
      </div>
    </div>

    <div *ngIf="loading" class="menu-pages__group">
      <div class="menu-pages-group">
        <div class="menu-pages-group__header">
          <div class="menu-pages-group__header-main">
            <div class="menu-pages-group__title">
              <span class="loading-animation"><span class="stub-text">custom pages</span></span>
            </div>
          </div>
        </div>

        <div class="menu-pages-group__content">
          <div
            *ngFor="let item of ['page', 'long page', 'page', 'page', 'long page', 'extra long page', 'page']"
            class="menu-pages__item"
          >
            <div class="menu-pages-item">
              <div class="menu-pages-item__icon">
                <span class="loading-animation">
                  <span class="stub-text">
                    --
                  </span>
                </span>
              </div>
              <div class="menu-pages-item__title">
                <span class="loading-animation">
                  <span class="stub-text">
                    {{ item }}
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ng-container *ngIf="!loading">
      <div
        class="menu-pages__select"
        [style.display]="filteredItems.length > 0 && !filteredQuery.length ? null : 'none'"
      >
        <app-customize-home
          [menuItems]="items"
          [disabled]="editing"
          [dark]="true"
          [analyticsSource]="analyticsSource"
          (startInteracting)="startInteracting.emit()"
          (finishInteracting)="finishInteracting.emit()"
          #home
        ></app-customize-home>
      </div>

      <div *ngFor="let group of filteredGroups" class="menu-pages__group">
        <app-customize-menu-pages-group
          [group]="group"
          [editing]="editing"
          [filteredQuery]="filteredQuery"
          [selectedPages]="selectedPages"
          [homeItem]="home.menuItemValue"
          (itemClick)="onItemClick($event.item, $event.event)"
          (actionClick)="closeMenu.emit()"
        ></app-customize-menu-pages-group>
      </div>

      <div *ngIf="!filteredGroups?.length" class="menu-pages__message">
        <ng-container *ngIf="filteredQuery.length">Nothing found</ng-container>
        <ng-container *ngIf="!filteredQuery.length">Nothing here</ng-container>
      </div>
    </ng-container>
  </div>
</div>

import { ContainerLayer } from '../layer';
import { LayerType } from '../layer-type';
import { registerLayerType } from '../layer-types';

export class GroupLayer extends ContainerLayer {
  public type = LayerType.Group;

  constructor(options: Partial<GroupLayer> = {}) {
    super(options);
  }

  public get icon(): string {
    return 'layers';
  }

  public get defaultName(): string {
    return 'group';
  }
}

registerLayerType(LayerType.Group, GroupLayer, { container: true });

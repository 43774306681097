<div class="token-popup-list-item">
  <div class="token-popup-list-item__inner">
    <div class="token-popup-list-item__line">
      <div class="token-popup-list-item__title">
        <span class="ref-function">{{ function.name }}</span
        ><span class="ref-punctuation">(</span
        ><ng-container *ngFor="let argument of function.arguments; let last = last"
          ><span *ngIf="argument.optional" class="ref-argument"
            >[{{ argument.name }}<ng-container *ngIf="argument.repeatable">, ...</ng-container>]</span
          ><span *ngIf="!argument.optional" class="ref-argument">{{ argument.name }}</span
          ><ng-container *ngIf="!last">, </ng-container></ng-container
        ><span class="ref-punctuation">)</span>
      </div>
    </div>

    <div *ngIf="function.result" class="token-popup-list-item__section token-popup-list-item__section_first">
      <div class="token-popup-list-item__text" [innerHTML]="function.result | appFormatFormulaDescription"></div>
    </div>

    <ng-container *ngIf="function.arguments?.length">
      <ng-container *ngFor="let argument of function.arguments">
        <div *ngIf="argument.description" class="token-popup-list-item__section">
          <div class="token-popup-list-item__label">
            <span class="ref-argument">{{ argument.name }}</span>
            <ng-container *ngIf="argument.optional || argument.repeatable">
              -
              <i *ngIf="argument.optional"> optional<ng-container *ngIf="argument.repeatable">, </ng-container> </i>
              <i *ngIf="argument.repeatable">repeatable</i>
            </ng-container>
          </div>
          <div
            class="token-popup-list-item__text"
            [innerHTML]="argument.description | appFormatFormulaDescription"
          ></div>
        </div>
      </ng-container>
    </ng-container>

    <div *ngIf="function.examples?.length" class="token-popup-list-item__section">
      <div class="token-popup-list-item__heading">
        <ng-container *ngIf="function.examples.length > 1">Examples</ng-container>
        <ng-container *ngIf="function.examples.length == 1">Example</ng-container>
      </div>
      <div *ngFor="let example of function.examples" class="token-popup-list-item__code theme_dark">
        <app-view-context-token-popover-function-formula
          [formula]="example"
        ></app-view-context-token-popover-function-formula>
      </div>
    </div>
  </div>
</div>

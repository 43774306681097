/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./sidebar-tab.component";
var styles_SidebarTabComponent = [];
var RenderType_SidebarTabComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SidebarTabComponent, data: {} });
export { RenderType_SidebarTabComponent as RenderType_SidebarTabComponent };
function View_SidebarTabComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "div", [["class", "sidebar-tabs2-content"]], [[2, "sidebar-tabs2-content_disabled", null]], null, null, null, null)), i0.ɵncd(null, 0)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.contentDisabled; _ck(_v, 1, 0, currVal_0); }); }
export function View_SidebarTabComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_SidebarTabComponent_1)), i0.ɵdid(1, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.hidden; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_SidebarTabComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-sidebar-tab", [], [[8, "hidden", 0]], null, null, View_SidebarTabComponent_0, RenderType_SidebarTabComponent)), i0.ɵdid(1, 638976, null, 0, i2.SidebarTabComponent, [i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).hidden; _ck(_v, 0, 0, currVal_0); }); }
var SidebarTabComponentNgFactory = i0.ɵccf("app-sidebar-tab", i2.SidebarTabComponent, View_SidebarTabComponent_Host_0, { label: "label", error: "error", disabled: "disabled", contentDisabled: "contentDisabled" }, {}, ["*"]);
export { SidebarTabComponentNgFactory as SidebarTabComponentNgFactory };

import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Injector,
  Input,
  OnDestroy,
  OnInit
} from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { PopoverService } from '@common/popover';
import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { LinkButton, ViewContext, ViewContextElement } from '@modules/customize';
import { createFormFieldFactory } from '@modules/fields';
import { isSet } from '@shared';

import { FieldsEditItemComponent } from '../fields-edit-item/fields-edit-item.component';
import { FieldEditFormOptions, FieldsEditConfigurable, FieldsEditForm, FieldsEditItem } from './fields-edit.form';

@Component({
  selector: 'app-fields-edit',
  templateUrl: './fields-edit.component.html',
  providers: [FieldsEditForm],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FieldsEditComponent implements OnInit, OnDestroy {
  @Input() control: AbstractControl;
  @Input() configurable: FieldsEditConfigurable = {};
  @Input() serializeItem: (value: FieldsEditItem) => any;
  @Input() deserializeItem: (value: any) => FieldsEditItem;
  @Input() buttons: LinkButton[] = [];
  @Input() addLabel = 'Add Input';
  @Input() addDefaults = {};
  @Input() addBaseName = 'param';
  @Input() context: ViewContext;
  @Input() contextElement: ViewContextElement;
  @Input() contextElementPath: (string | number)[];
  @Input() contextElementPaths: (string | number)[][];
  @Input() object: string;

  createField = createFormFieldFactory();
  createNewItemIfEmpty = false;
  formOptions: FieldEditFormOptions = {};
  hovered$ = new BehaviorSubject<number>(undefined);
  sendParametersAnalytics = true;

  constructor(
    public form: FieldsEditForm,
    protected analyticsService: UniversalAnalyticsService,
    protected popoverService: PopoverService,
    protected injector: Injector,
    protected cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.form.configurable = this.configurable;
    this.form.serializeItem = this.serializeItem;
    this.form.deserializeItem = this.deserializeItem;
    this.form.init(this.control, this.createNewItemIfEmpty, this.formOptions);

    this.form.formUpdated.pipe(untilDestroyed(this)).subscribe(() => this.cd.markForCheck());

    if (this.sendParametersAnalytics) {
      this.form.form.valueChanges.pipe(debounceTime(1000), untilDestroyed(this)).subscribe(value => {
        this.analyticsService.sendSimpleEvent(AnalyticsEvent.Parameter.SuccessfullySetUp, {
          Object: this.object
        });
      });
    }
  }

  ngOnDestroy(): void {}

  openItemSettings(form: FormGroup, sourceElement) {
    this.popoverService.create(
      {
        component: FieldsEditItemComponent,
        inputs: {
          form: form,
          configurable: {
            name: true,
            editable: this.configurable.editable,
            params: this.configurable.action,
            required: true
          }
        },
        outputs: {
          deleteRequested: [
            () => {
              this.deleteItem(form);
            }
          ]
        },
        injector: this.injector
      },
      sourceElement
    );
  }

  dragDrop(event: CdkDragDrop<FormControl[]>) {
    if (event.previousIndex !== event.currentIndex) {
      moveItemInArray(this.form.form.controls, event.previousIndex, event.currentIndex);
      this.form.form.updateValueAndValidity();
    }
  }

  deleteItem(form: FormGroup) {
    this.form.arrayDelete(form);

    if (this.sendParametersAnalytics) {
      this.analyticsService.sendSimpleEvent(AnalyticsEvent.Parameter.Deleted, {
        Object: this.object
      });
    }
  }

  getDistinctName(baseName: string, template = (n, i) => `${n}_${i}`, startIndex = 1) {
    const names = this.form.form.controls.map(item => {
      const value = item.value['name'];
      return isSet(value) ? value : '';
    });
    let name: string;
    let index = startIndex;

    do {
      name = template(baseName, index);
      ++index;
    } while (names.find(item => item.toLowerCase() == name.toLowerCase()));

    return name;
  }

  addItem() {
    const name = this.getDistinctName(this.addBaseName);
    this.form.arrayAppend(
      this.form.createItem({
        name: name,
        ...this.addDefaults
      })
    );

    if (this.sendParametersAnalytics) {
      this.analyticsService.sendSimpleEvent(AnalyticsEvent.Parameter.Added, {
        Object: this.object
      });
    }
  }
}

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';

import { ResourceType, ResourceTypeItem, resourceTypeItems } from '@modules/projects';

import { TemplatesGroup } from '../../../../data/templates-group';

@Component({
  selector: 'app-change-customize-bar-templates-select-resource',
  templateUrl: './change-customize-bar-templates-select-resource.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChangeCustomizeBarTemplatesSelectResourceComponent implements OnInit, OnDestroy, OnChanges {
  @Input() group: TemplatesGroup;
  @Input() resourceType: ResourceType;
  @Output() selected = new EventEmitter<ResourceTypeItem>();

  items: ResourceTypeItem[] = [];

  constructor(private cd: ChangeDetectorRef) {}

  ngOnInit() {}

  ngOnDestroy(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['resourceType']) {
      this.updateResources();
    }
  }

  updateResources() {
    this.items = resourceTypeItems
      .filter(item => !item.hidden && !item.protected)
      .filter(item => !this.resourceType || item.resourceType == this.resourceType);
    this.cd.markForCheck();
  }
}

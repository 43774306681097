var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Task } from '../task';
import { TaskScenario } from '../task-scenario';
import * as i0 from "@angular/core";
var AddChartWidgetSqlTask = /** @class */ (function (_super) {
    __extends(AddChartWidgetSqlTask, _super);
    function AddChartWidgetSqlTask() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    AddChartWidgetSqlTask.prototype.init = function () {
        return new TaskScenario([
            {
                link: [],
                items: []
            }
        ]);
    };
    AddChartWidgetSqlTask.ngInjectableDef = i0.defineInjectable({ factory: function AddChartWidgetSqlTask_Factory() { return new AddChartWidgetSqlTask(); }, token: AddChartWidgetSqlTask, providedIn: "root" });
    return AddChartWidgetSqlTask;
}(Task));
export { AddChartWidgetSqlTask };

import { AbstractControlOptions, AsyncValidatorFn, FormControl, ValidatorFn } from '@angular/forms';

import { controlValue } from '@shared';

export class ParameterNameControl extends FormControl {
  constructor(
    formState?: any,
    validatorOrOpts?: ValidatorFn | ValidatorFn[] | AbstractControlOptions | null,
    asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null
  ) {
    super(formState, validatorOrOpts, asyncValidator);

    controlValue(this).subscribe(value => {
      const cleanNameValue = this.cleanValue(value);

      if (cleanNameValue != value) {
        this.setValue(cleanNameValue);
      }
    });
  }

  cleanValue(value: any): any {
    if (typeof value == 'string') {
      // value = value.replace(/[^\w]/g, '_').replace(/^\d/, '_');
    }

    return value;
  }
}

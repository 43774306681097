/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../common/tips/directives/tip/tip.directive";
import * as i2 from "@angular/common";
import * as i3 from "../icon-selector-stub/icon-selector-stub.component.ngfactory";
import * as i4 from "../icon-selector-stub/icon-selector-stub.component";
import * as i5 from "../../../../shared/pipes/is-set/is-set.pipe";
import * as i6 from "./icon-selector-emojis.component";
import * as i7 from "@angular/platform-browser";
var styles_IconSelectorEmojisComponent = [];
var RenderType_IconSelectorEmojisComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_IconSelectorEmojisComponent, data: {} });
export { RenderType_IconSelectorEmojisComponent as RenderType_IconSelectorEmojisComponent };
function View_IconSelectorEmojisComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "select-icon__item"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "a", [["class", "select-icon-item select-icon-item_interactive"], ["href", "javascript:void(0)"]], [[2, "select-icon-item_current", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.selected.emit(_v.context.$implicit.value) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(2, 737280, null, 0, i1.TipDirective, [i0.ElementRef, i0.ComponentFactoryResolver, i0.Injector, i0.ApplicationRef], { content: [0, "content"], optionsRaw: [1, "optionsRaw"] }, null), i0.ɵpod(3, { showDelay: 0 }), (_l()(), i0.ɵeld(4, 0, null, null, 0, "span", [["class", "select-icon-item__icon select-icon-item__icon_emoji"]], [[8, "innerHTML", 1]], null, null, null, null))], function (_ck, _v) { var currVal_1 = _v.context.$implicit.name; var currVal_2 = _ck(_v, 3, 0, 300); _ck(_v, 2, 0, currVal_1, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.current == _v.context.$implicit.value); _ck(_v, 1, 0, currVal_0); var currVal_3 = _v.context.$implicit.html; _ck(_v, 4, 0, currVal_3); }); }
function View_IconSelectorEmojisComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [["class", "select-icon__section"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "div", [["class", "select-icon__section-title"]], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, [" ", " "])), (_l()(), i0.ɵeld(3, 0, null, null, 2, "div", [["class", "select-icon__section-content"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_IconSelectorEmojisComponent_3)), i0.ɵdid(5, 278528, null, 0, i2.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_1 = _v.context.$implicit.items; _ck(_v, 5, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.name; _ck(_v, 2, 0, currVal_0); }); }
function View_IconSelectorEmojisComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["class", "select-icon__items-empty"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" No emojis found "]))], null, null); }
function View_IconSelectorEmojisComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_IconSelectorEmojisComponent_2)), i0.ɵdid(2, 278528, null, 0, i2.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_IconSelectorEmojisComponent_4)), i0.ɵdid(4, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵppd(5, 1), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.filteredCategories; _ck(_v, 2, 0, currVal_0); var currVal_1 = (i0.ɵunv(_v, 4, 0, _ck(_v, 5, 0, i0.ɵnov(_v.parent, 0), _co.search)) && !_co.filteredCategories.length); _ck(_v, 4, 0, currVal_1); }, null); }
function View_IconSelectorEmojisComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-icon-selector-stub", [], null, null, null, i3.View_IconSelectorStubComponent_0, i3.RenderType_IconSelectorStubComponent)), i0.ɵdid(1, 638976, null, 0, i4.IconSelectorStubComponent, [], { sectionTitle: [0, "sectionTitle"] }, null)], function (_ck, _v) { var currVal_0 = true; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_IconSelectorEmojisComponent_0(_l) { return i0.ɵvid(2, [i0.ɵpid(0, i5.IsSetPipe, []), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_IconSelectorEmojisComponent_1)), i0.ɵdid(2, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_IconSelectorEmojisComponent_5)), i0.ɵdid(4, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.loading; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.loading; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_IconSelectorEmojisComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-icon-selector-emojis", [], null, null, null, View_IconSelectorEmojisComponent_0, RenderType_IconSelectorEmojisComponent)), i0.ɵdid(1, 638976, null, 0, i6.IconSelectorEmojisComponent, [i7.DomSanitizer, i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var IconSelectorEmojisComponentNgFactory = i0.ɵccf("app-icon-selector-emojis", i6.IconSelectorEmojisComponent, View_IconSelectorEmojisComponent_Host_0, { current: "current", search: "search", loading: "loading" }, { selected: "selected" }, []);
export { IconSelectorEmojisComponentNgFactory as IconSelectorEmojisComponentNgFactory };

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ComponentFactoryResolver, Injector } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { MediumDialogPopupComponent, ThinDialogPopupComponent } from '@common/dialog-popup';
import { PopupService } from '@common/popups';
import { ProjectGroupCreatePopupComponent } from '../../components/project-group-create-popup/project-group-create-popup.component';
import { ProjectGroupEditPopupComponent } from '../../components/project-group-edit-popup/project-group-edit-popup.component';
export var ProjectGroupEditResult;
(function (ProjectGroupEditResult) {
    ProjectGroupEditResult["Edited"] = "edited";
    ProjectGroupEditResult["Deleted"] = "deleted";
})(ProjectGroupEditResult || (ProjectGroupEditResult = {}));
var ProjectGroupEditController = /** @class */ (function () {
    function ProjectGroupEditController(popupService, injector, resolver) {
        this.popupService = popupService;
        this.injector = injector;
        this.resolver = resolver;
    }
    ProjectGroupEditController.prototype.create = function (options) {
        if (options === void 0) { options = {}; }
        var obs = new ReplaySubject();
        this.popupService.push({
            component: ProjectGroupEditPopupComponent,
            popupComponent: MediumDialogPopupComponent,
            inputs: __assign({}, (options.propertiesEnabled !== undefined ? { propertiesEnabled: options.propertiesEnabled } : {}), (options.deleteEnabled !== undefined ? { deleteEnabled: options.deleteEnabled } : {})),
            outputs: {
                created: [function (result) { return obs.next(result); }]
            },
            resolver: this.resolver,
            injector: this.injector
        });
        return obs.asObservable();
    };
    ProjectGroupEditController.prototype.createSimple = function () {
        var obs = new ReplaySubject();
        this.popupService.push({
            component: ProjectGroupCreatePopupComponent,
            popupComponent: ThinDialogPopupComponent,
            resolver: this.resolver,
            injector: this.injector,
            outputs: {
                created: [function (result) { return obs.next(result); }]
            }
        });
        return obs.asObservable();
    };
    ProjectGroupEditController.prototype.edit = function (group, options) {
        if (options === void 0) { options = {}; }
        var obs = new ReplaySubject();
        this.popupService.push({
            component: ProjectGroupEditPopupComponent,
            popupComponent: MediumDialogPopupComponent,
            resolver: this.resolver,
            injector: this.injector,
            inputs: __assign({ group: group }, (options.propertiesEnabled !== undefined ? { propertiesEnabled: options.propertiesEnabled } : {}), (options.deleteEnabled !== undefined ? { deleteEnabled: options.deleteEnabled } : {})),
            outputs: {
                updated: [
                    function (result) {
                        return obs.next({
                            result: ProjectGroupEditResult.Edited,
                            group: result
                        });
                    }
                ],
                deleted: [
                    function (result) {
                        return obs.next({
                            result: ProjectGroupEditResult.Deleted,
                            group: result
                        });
                    }
                ]
            }
        });
        return obs.asObservable();
    };
    return ProjectGroupEditController;
}());
export { ProjectGroupEditController };

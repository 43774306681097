import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Optional,
  Output,
  ViewChild
} from '@angular/core';

import { BasePopupComponent } from '@common/popups';
import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { RoutingService } from '@modules/routing';
import { CustomViewTemplate, CustomViewTemplateType, View } from '@modules/views';

import {
  CustomViewTemplateFilter,
  CustomViewTemplatesListComponent
} from '../custom-view-templates-list/custom-view-templates-list.component';

enum RouteType {
  List = 'list',
  Detail = 'detail',
  Update = 'update'
}

interface BaseRoute {
  type: RouteType;
}

interface ListRoute extends BaseRoute {
  type: RouteType.List;
}

interface DetailRoute extends BaseRoute {
  type: RouteType.Detail;
  id: string;
}

interface UpdateRoute extends BaseRoute {
  type: RouteType.Update;
  id: string;
}

@Component({
  selector: 'app-custom-view-templates',
  templateUrl: './custom-view-templates.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomViewTemplatesComponent implements OnInit {
  @Input() initialFilter: CustomViewTemplateFilter = {};
  @Input() updateOnSelect = false;
  @Input() setTemplateView: View;
  @Input() nameEditingEnabled = true;
  @Input() viewCustomizeEnabled = true;
  @Input() viewCreateEnabled = false;
  @Input() stateSelectedEnabled = false;
  @Input() componentLabel = 'component';
  @Input() analyticsSource: string;
  @Output() selectView = new EventEmitter<{ view: View; template?: CustomViewTemplate }>();
  @Output() templateUpdated = new EventEmitter<CustomViewTemplate>();
  @Output() cancelled = new EventEmitter<void>();

  @ViewChild(CustomViewTemplatesListComponent) listComponent: CustomViewTemplatesListComponent;

  defaultRoute: BaseRoute = {
    type: RouteType.List
  };
  route: BaseRoute = this.defaultRoute;
  routeTypes = RouteType;

  get detailRoute(): DetailRoute {
    return this.route as DetailRoute;
  }

  get updateRoute(): UpdateRoute {
    return this.route as UpdateRoute;
  }

  constructor(
    @Optional() public popupComponent: BasePopupComponent,
    private routing: RoutingService,
    private analyticsService: UniversalAnalyticsService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {}

  close() {
    if (this.popupComponent) {
      this.popupComponent.close();
    }
  }

  openList(options: { type?: CustomViewTemplateType } = {}) {
    this.route = { type: RouteType.List } as ListRoute;
    this.cd.markForCheck();

    if (options.type) {
      this.listComponent.filterByTemplateType(options.type);
    }
  }

  openDetail(id: string) {
    this.route = { type: RouteType.Detail, id: id } as DetailRoute;
    this.cd.markForCheck();
  }

  openUpdate(id: string) {
    this.route = { type: RouteType.Update, id: id } as UpdateRoute;
    this.cd.markForCheck();
  }

  onTemplateSelected(template: CustomViewTemplate) {
    if (this.updateOnSelect) {
      this.openUpdate(template.id);
    } else {
      this.openDetail(template.id);

      this.analyticsService.sendSimpleEvent(AnalyticsEvent.ViewTemplates.ViewOpened, {
        Name: template.name,
        Source: this.analyticsSource
      });
    }
  }

  onViewSelected(view: View, template?: CustomViewTemplate) {
    this.selectView.emit({ view: view, template: template });
    this.close();
  }

  onTemplateSaved(template: CustomViewTemplate) {
    if (this.updateOnSelect) {
      this.templateUpdated.emit(template);
      this.close();
    } else {
      this.openDetail(template.id);
      this.listComponent.fetch();
    }
  }

  cancel() {
    this.cancelled.emit();
    this.close();
  }
}

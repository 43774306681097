export const pipedriveResourceParamsModelDescriptions: Object[] = [
  {
    project: '{{project}}',
    resource: '{{resource}}',
    model: 'activities',
    verbose_name: 'Activities',
    verbose_name_plural: 'Activities',
    fields: [
      {
        name: 'id',
        verbose_name: 'id',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'company_id',
        verbose_name: 'company id',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'user_id',
        verbose_name: 'user',
        field: 'related_model',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {
          related_model: {
            model: '{{resource}}.users'
          },
          custom_primary_key: 'id'
        }
      },
      {
        name: 'done',
        verbose_name: 'done',
        field: 'boolean',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'type',
        verbose_name: 'type',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'reference_type',
        verbose_name: 'reference type',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'reference_id',
        verbose_name: 'reference id',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'due_date',
        verbose_name: 'due date',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {
          date: true,
          time: false
        }
      },
      {
        name: 'due_time',
        verbose_name: 'due time',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'duration',
        verbose_name: 'duration',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'busy_flag',
        verbose_name: 'busy flag',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'add_time',
        verbose_name: 'add time',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'marked_as_done_time',
        verbose_name: 'marked as done time',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'last_notification_time',
        verbose_name: 'last notification time',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'last_notification_user_id',
        verbose_name: 'last notification user id',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'notification_language_id',
        verbose_name: 'notification language id',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'subject',
        verbose_name: 'subject',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'public_description',
        verbose_name: 'public description',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'calendar_sync_include_context',
        verbose_name: 'calendar sync include context',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'location',
        verbose_name: 'location',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'org_id',
        verbose_name: 'org',
        field: 'related_model',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {
          related_model: {
            model: '{{resource}}.organization'
          },
          custom_primary_key: 'id'
        }
      },
      {
        name: 'person_id',
        verbose_name: 'person id',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'deal_id',
        verbose_name: 'deal id',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'lead_id',
        verbose_name: 'lead id',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'lead_title',
        verbose_name: 'lead title',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'active_flag',
        verbose_name: 'active flag',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'update_time',
        verbose_name: 'update time',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'update_user_id',
        verbose_name: 'update user id',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'gcal_event_id',
        verbose_name: 'gcal event id',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'google_calendar_id',
        verbose_name: 'google calendar id',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'google_calendar_etag',
        verbose_name: 'google calendar etag',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'source_timezone',
        verbose_name: 'source timezone',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'rec_rule',
        verbose_name: 'rec rule',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'rec_rule_extension',
        verbose_name: 'rec rule extension',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'rec_master_activity_id',
        verbose_name: 'rec master activity id',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'note',
        verbose_name: 'note',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'created_by_user_id',
        verbose_name: 'created by user id',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'location_subpremise',
        verbose_name: 'location subpremise',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'location_street_number',
        verbose_name: 'location street number',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'location_route',
        verbose_name: 'location route',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'location_sublocality',
        verbose_name: 'location sublocality',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'location_locality',
        verbose_name: 'location locality',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'location_admin_area_level_1',
        verbose_name: 'location admin area level 1',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'location_admin_area_level_2',
        verbose_name: 'location admin area level 2',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'location_country',
        verbose_name: 'location country',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'location_postal_code',
        verbose_name: 'location postal code',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'location_formatted_address',
        verbose_name: 'location formatted address',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'attendees',
        verbose_name: 'attendees',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'participants',
        verbose_name: 'participants',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'series',
        verbose_name: 'series',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'org_name',
        verbose_name: 'org name',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'person_name',
        verbose_name: 'person name',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'deal_title',
        verbose_name: 'deal title',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'owner_name',
        verbose_name: 'owner name',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'person_dropbox_bcc',
        verbose_name: 'person dropbox bcc',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'deal_dropbox_bcc',
        verbose_name: 'deal dropbox bcc',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'assigned_to_user_id',
        verbose_name: 'assigned to user id',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'file',
        verbose_name: 'file',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      }
    ],
    flex_fields: [],
    segments: [],
    primary_key_field: 'id',
    relations: [],
    description: 'Lists activities associated with a deal.',
    get_query: {
      query_type: 'http',
      http_query: {
        method: 'GET',
        url: 'https://api.pipedrive.com/v1/activities',
        auth_params: {},
        body_type: 'Raw',
        body: '',
        headers: [
          {
            name: 'Accept',
            value: 'application/json'
          }
        ],
        query_params: [
          {
            name: 'sort',
            value: "{% if (sorting.field) { %}{{sorting.field}} {{sorting.asc ? 'ASC': 'DESC'}}{% } %}"
          },
          {
            name: 'api_token',
            value: '{-api_key-}'
          },
          {
            name: 'user_id',
            value: '{{params.user_id}}'
          },
          {
            name: 'filter_id',
            value: '{{params.filter_id}}'
          },
          {
            name: 'type',
            value: '{{params.type}}'
          },
          {
            name: 'start',
            value: '{{paging.offset}}'
          },
          {
            name: 'limit',
            value: '{{paging.limit}}'
          },
          {
            name: 'start_date',
            value: '{{params.start_date}}'
          },
          {
            name: 'end_date',
            value: '{{params.end_date}}'
          },
          {
            name: 'done',
            value: '{{params.done}}'
          }
        ],
        response_type: 'json',
        response_transformer:
          "return (data && data['data'] || []).map(item => {\n    const result = {};\n    \n    Object.keys(item).forEach(key => {\n        if (item[key] && item[key]['value']) {\n            result[key] = item[key]['value'];\n        } else {\n            result[key] = item[key];\n        }\n    });\n    \n    return result;\n});",
        response_path: '',
        request_tokens: {}
      },
      pagination: 'offset',
      pagination_has_more_function:
        "// ex. data['hasMore']\nreturn data['additional_data']['pagination']['more_items_in_collection'];",
      pagination_total_function: "// ex. data['count']\nreturn ",
      pagination_cursor_next_function: "// ex. data['next_cursor']\nreturn ",
      pagination_has_more_pages_path: [],
      pagination_has_more_total_pages_path: [],
      pagination_has_more_total_records_path: [],
      sorting: true
    },
    get_parameters: [
      {
        name: 'user_id',
        verbose_name: 'user_id',
        description: '',
        field: 'number',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {
          type: 'integer',
          fraction: 2
        }
      },
      {
        name: 'filter_id',
        verbose_name: 'filter_id',
        description: '',
        field: 'number',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {
          type: 'integer',
          fraction: 2
        }
      },
      {
        name: 'type',
        verbose_name: 'type',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'start_date',
        verbose_name: 'start_date',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'end_date',
        verbose_name: 'end_date',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'done',
        verbose_name: 'done',
        description: '',
        field: 'number',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {
          type: 'integer',
          fraction: 2
        }
      }
    ],
    get_inputs: [],
    get_detail_query: {
      query_type: 'http',
      http_query: {
        method: 'GET',
        url: 'https://api.pipedrive.com/v1/activities/{{params.id}}',
        auth_params: {},
        body_type: 'JSON',
        body: '',
        headers: [
          {
            name: 'Accept',
            value: 'application/json'
          }
        ],
        query_params: [
          {
            name: 'api_token',
            value: '{-api_key-}'
          }
        ],
        response_type: 'json',
        response_transformer:
          "const item = data['data'];\nconst result = {};\n\nObject.keys(item).forEach(key => {\n    if (item[key] && item[key]['value']) {\n        result[key] = item[key]['value'];\n    } else {\n        result[key] = item[key];\n    }\n});\n\nreturn result;",
        response_path: '',
        request_tokens: {}
      }
    },
    get_detail_parameters: [
      {
        name: 'id',
        verbose_name: 'id',
        description: '',
        field: 'number',
        required: true,
        default_type: 'value',
        default_value: '',
        params: {
          type: 'integer',
          fraction: 2
        }
      }
    ],
    get_detail_parameters_use_defaults: false,
    create_query: {
      query_type: 'http',
      http_query: {
        method: 'POST',
        url: 'https://api.pipedrive.com/v1/activities',
        auth_params: {},
        body_type: 'Raw',
        body:
          '{\r\n"subject": "{{params.subject}}",\r\n"type": "{{params.type}}",\r\n"due_date": "{{params.due_date}}",\r\n"due_time": "{{params.due_time}}",\r\n"duration": "{{params.duration}}",\r\n"user_id": "{{params.user_id}}",\r\n"deal_id": "{{params.deal_id}}",\r\n"person_id": "{{params.person_id}}",\r\n"org_id": "{{params.org_id}}",\r\n"note": "{{params.note}}",\r\n"location": "{{params.location}}",\r\n"public_description": "{{params.public_description}}",\r\n"done": "{{params.done}}",\r\n"busy_flag": "{{params.busy_flag}}"\r\n}',
        headers: [
          {
            name: 'Accept',
            value: 'application/json'
          },
          {
            name: 'Content-Type',
            value: 'application/json'
          }
        ],
        query_params: [
          {
            name: 'api_token',
            value: '{-api_key-}'
          }
        ],
        response_type: 'json',
        response_transformer:
          "const item = data['data'];\nconst result = {};\n\nObject.keys(item).forEach(key => {\n    if (item[key] && item[key]['value']) {\n        result[key] = item[key]['value'];\n    } else {\n        result[key] = item[key];\n    }\n});\n\nreturn result;",
        response_path: '',
        body_transformer:
          'function getFullDict(data) {\n    for (var key in data) {\n        var value = data[key];\n        if (value == "" || value == "{}" || value == "null") {\n            delete data[key];\n        }\n    }\n    return data;\n}\n// add custom transformation here\nreturn getFullDict(data);',
        request_tokens: {}
      }
    },
    create_parameters: [
      {
        name: 'subject',
        verbose_name: 'subject',
        description: '',
        field: 'text',
        required: true,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'type',
        verbose_name: 'type',
        description: '',
        field: 'text',
        required: true,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'due_date',
        verbose_name: 'due_date',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'due_time',
        verbose_name: 'due_time',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'duration',
        verbose_name: 'duration',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'user_id',
        verbose_name: 'user_id',
        description: '',
        field: 'number',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {
          type: 'integer',
          fraction: 2
        }
      },
      {
        name: 'params.deal_id',
        verbose_name: 'params.deal_id',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'person_id',
        verbose_name: 'person_id',
        description: '',
        field: 'number',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {
          type: 'integer',
          fraction: 2
        }
      },
      {
        name: 'org_id',
        verbose_name: 'org_id',
        description: '',
        field: 'number',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {
          type: 'integer',
          fraction: 2
        }
      },
      {
        name: 'note',
        verbose_name: 'note',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'location',
        verbose_name: 'location',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'public_description',
        verbose_name: 'public_description',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'done',
        verbose_name: 'done',
        description: '',
        field: 'boolean',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'busy_flag',
        verbose_name: 'busy_flag',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      }
    ],
    create_parameters_use_defaults: false,
    update_query: {
      query_type: 'http',
      http_query: {
        method: 'PUT',
        url: 'https://api.pipedrive.com/v1/activities/{{params.id}}',
        auth_params: {},
        body_type: 'Raw',
        body:
          '{\r\n"subject": "{{params.subject}}",\r\n"type": "{{params.type}}",\r\n"due_date": "{{params.due_date}}",\r\n"due_time": "{{params.due_time}}",\r\n"duration": "{{params.duration}}",\r\n"user_id": "{{params.user_id}}",\r\n"deal_id": "{{params.deal_id}}",\r\n"person_id": "{{params.person_id}}",\r\n"org_id": "{{params.org_id}}",\r\n"note": "{{params.note}}",\r\n"location": "{{params.location}}",\r\n"public_description": "{{params.public_description}}",\r\n"done": "{{params.done}}",\r\n"busy_flag": "{{params.busy_flag}}"\r\n}',
        headers: [
          {
            name: 'Accept',
            value: 'application/json'
          },
          {
            name: 'Content-Type',
            value: 'application/json'
          }
        ],
        query_params: [
          {
            name: 'api_token',
            value: '{-api_key-}'
          }
        ],
        response_type: 'json',
        response_transformer:
          "const item = data['data'];\nconst result = {};\n\nObject.keys(item).forEach(key => {\n    if (item[key] && item[key]['value']) {\n        result[key] = item[key]['value'];\n    } else {\n        result[key] = item[key];\n    }\n});\n\nreturn result;",
        response_path: '',
        body_transformer:
          'function getFullDict(data) {\n    for (var key in data) {\n        var value = data[key];\n        if (value == "" || value == "{}" || value == "null") {\n            delete data[key];\n        }\n    }\n    return data;\n}\n// add custom transformation here\nreturn getFullDict(data);',
        request_tokens: {}
      }
    },
    update_parameters: [
      {
        name: 'id',
        verbose_name: 'id',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'subject',
        verbose_name: 'subject',
        description: '',
        field: 'text',
        required: true,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'type',
        verbose_name: 'type',
        description: '',
        field: 'text',
        required: true,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'due_date',
        verbose_name: 'due_date',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'due_time',
        verbose_name: 'due_time',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'duration',
        verbose_name: 'duration',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'user_id',
        verbose_name: 'user_id',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'deal_id',
        verbose_name: 'deal_id',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'person_id',
        verbose_name: 'person_id',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'org_id',
        verbose_name: 'org_id',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'note',
        verbose_name: 'note',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'location',
        verbose_name: 'location',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'public_description',
        verbose_name: 'public_description',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'done',
        verbose_name: 'done',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'busy_flag',
        verbose_name: 'busy_flag',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      }
    ],
    update_parameters_use_defaults: false,
    delete_query: {
      query_type: 'http',
      http_query: {
        method: 'DELETE',
        url: 'https://api.pipedrive.com/v1/activities/{{params.id}}',
        auth_params: {},
        body_type: 'JSON',
        body: '',
        headers: [
          {
            name: 'Accept',
            value: 'application/json'
          }
        ],
        query_params: [
          {
            name: 'api_token',
            value: '{-api_key-}'
          }
        ],
        response_type: 'json',
        response_path: 'success',
        request_tokens: {}
      }
    },
    delete_parameters: [
      {
        name: 'id',
        verbose_name: 'id',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      }
    ],
    delete_parameters_use_defaults: false,
    aggregate_parameters: [],
    group_parameters: [],
    sync: false,
    sync_finished: false,
    params: {},
    draft: false,
    deleted: false
  },
  {
    project: '{{project}}',
    resource: '{{resource}}',
    model: 'deals',
    verbose_name: 'Deals',
    verbose_name_plural: 'Deals',
    fields: [
      {
        name: 'id',
        verbose_name: 'id',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'creator_user_id',
        verbose_name: 'creator user',
        field: 'related_model',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {
          related_model: {
            model: '{{resource}}.users'
          },
          custom_primary_key: 'id'
        }
      },
      {
        name: 'user_id',
        verbose_name: 'user',
        field: 'related_model',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {
          related_model: {
            model: '{{resource}}.users'
          },
          custom_primary_key: 'id'
        }
      },
      {
        name: 'person_id',
        verbose_name: 'person',
        field: 'related_model',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {
          related_model: {
            model: '{{resource}}.person'
          },
          custom_primary_key: 'id'
        }
      },
      {
        name: 'org_id',
        verbose_name: 'org',
        field: 'related_model',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {
          related_model: {
            model: '{{resource}}.organization'
          },
          custom_primary_key: 'id'
        }
      },
      {
        name: 'stage_id',
        verbose_name: 'stage id',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'title',
        verbose_name: 'title',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'value',
        verbose_name: 'value',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'currency',
        verbose_name: 'currency',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'add_time',
        verbose_name: 'add time',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'update_time',
        verbose_name: 'update time',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'stage_change_time',
        verbose_name: 'stage change time',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'active',
        verbose_name: 'active',
        field: 'boolean',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'deleted',
        verbose_name: 'deleted',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'status',
        verbose_name: 'status',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'probability',
        verbose_name: 'probability',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'next_activity_date',
        verbose_name: 'next activity date',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'next_activity_time',
        verbose_name: 'next activity time',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'next_activity_id',
        verbose_name: 'next activity id',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'last_activity_id',
        verbose_name: 'last activity id',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'last_activity_date',
        verbose_name: 'last activity date',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'lost_reason',
        verbose_name: 'lost reason',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'visible_to',
        verbose_name: 'visible to',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'close_time',
        verbose_name: 'close time',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'pipeline_id',
        verbose_name: 'pipeline id',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'won_time',
        verbose_name: 'won time',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'first_won_time',
        verbose_name: 'first won time',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'lost_time',
        verbose_name: 'lost time',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'products_count',
        verbose_name: 'products count',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'files_count',
        verbose_name: 'files count',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'notes_count',
        verbose_name: 'notes count',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'followers_count',
        verbose_name: 'followers count',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'email_messages_count',
        verbose_name: 'email messages count',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'activities_count',
        verbose_name: 'activities count',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'done_activities_count',
        verbose_name: 'done activities count',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'undone_activities_count',
        verbose_name: 'undone activities count',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'reference_activities_count',
        verbose_name: 'reference activities count',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'participants_count',
        verbose_name: 'participants count',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'expected_close_date',
        verbose_name: 'expected close date',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'last_incoming_mail_time',
        verbose_name: 'last incoming mail time',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'last_outgoing_mail_time',
        verbose_name: 'last outgoing mail time',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'label',
        verbose_name: 'label',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'stage_order_nr',
        verbose_name: 'stage order nr',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'person_name',
        verbose_name: 'person name',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'org_name',
        verbose_name: 'org name',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'next_activity_subject',
        verbose_name: 'next activity subject',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'next_activity_type',
        verbose_name: 'next activity type',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'next_activity_duration',
        verbose_name: 'next activity duration',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'next_activity_note',
        verbose_name: 'next activity note',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'formatted_value',
        verbose_name: 'formatted value',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'weighted_value',
        verbose_name: 'weighted value',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'formatted_weighted_value',
        verbose_name: 'formatted weighted value',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'weighted_value_currency',
        verbose_name: 'weighted value currency',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'rotten_time',
        verbose_name: 'rotten time',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'owner_name',
        verbose_name: 'owner name',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'cc_email',
        verbose_name: 'cc email',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'org_hidden',
        verbose_name: 'org hidden',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: false,
        default_type: 'value',
        params: {}
      },
      {
        name: 'person_hidden',
        verbose_name: 'person hidden',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      }
    ],
    flex_fields: [],
    segments: [],
    primary_key_field: 'id',
    relations: [],
    description: 'Searches for deals.',
    get_query: {
      query_type: 'http',
      http_query: {
        method: 'GET',
        url: 'https://api.pipedrive.com/v1/deals',
        auth_params: {},
        body_type: 'JSON',
        body: '',
        headers: [],
        query_params: [
          {
            name: 'sort',
            value: "{% if (sorting.field) { %}{{sorting.field}} {{sorting.asc ? 'ASC': 'DESC'}}{% } %}"
          },
          {
            name: 'api_token',
            value: '{-api_key-}'
          },
          {
            name: 'start',
            value: '{{paging.offset}}'
          },
          {
            name: 'limit',
            value: '{{paging.limit}}'
          },
          {
            name: 'user_id',
            value: '{{params.user_id}}'
          },
          {
            name: 'filter_id',
            value: '{{params.filter_id}}'
          },
          {
            name: 'stage_id',
            value: '{{params.stage_id}}'
          },
          {
            name: 'status',
            value: '{{params.status}}'
          },
          {
            name: 'owned_by_you',
            value: '{{params.owned_by_you}}'
          }
        ],
        response_type: 'json',
        response_transformer:
          "return (data && data['data'] || []).map(item => {\n    const result = {};\n    \n    Object.keys(item).forEach(key => {\n        if (item[key] && item[key]['value']) {\n            result[key] = item[key]['value'];\n        } else {\n            result[key] = item[key];\n        }\n    });\n    \n    return result;\n});",
        response_path: '',
        request_tokens: {}
      },
      pagination: 'offset',
      pagination_has_more_function:
        "// ex. data['hasMore']\nreturn data['additional_data']['pagination']['more_items_in_collection'];",
      pagination_total_function: "// ex. data['count']\nreturn ",
      pagination_cursor_next_function: "// ex. data['next_cursor']\nreturn ",
      pagination_has_more_pages_path: [],
      pagination_has_more_total_pages_path: [],
      pagination_has_more_total_records_path: [],
      sorting: true
    },
    get_parameters: [
      {
        name: 'user_id',
        verbose_name: 'user_id',
        description: '',
        field: 'number',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {
          type: 'integer',
          fraction: 2
        }
      },
      {
        name: 'filter_id',
        verbose_name: 'filter_id',
        description: '',
        field: 'number',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {
          type: 'integer',
          fraction: 2
        }
      },
      {
        name: 'stage_id',
        verbose_name: 'stage_id',
        description: '',
        field: 'number',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {
          type: 'integer',
          fraction: 2
        }
      },
      {
        name: 'status',
        verbose_name: 'status',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'owned_by_you',
        verbose_name: 'owned_by_you',
        description: '',
        field: 'number',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      }
    ],
    get_inputs: [],
    get_detail_query: {
      query_type: 'http',
      http_query: {
        method: 'GET',
        url: 'https://api.pipedrive.com/v1/deals/{{params.id}}',
        auth_params: {},
        body_type: 'JSON',
        body: '',
        headers: [
          {
            name: 'Accept',
            value: 'application/json'
          }
        ],
        query_params: [
          {
            name: 'api_token',
            value: '{-api_key-}'
          }
        ],
        response_type: 'json',
        response_transformer:
          "const item = data['data'];\nconst result = {};\n\nObject.keys(item).forEach(key => {\n    if (item[key] && item[key]['value']) {\n        result[key] = item[key]['value'];\n    } else {\n        result[key] = item[key];\n    }\n});\n\nreturn result;",
        response_path: '',
        request_tokens: {}
      }
    },
    get_detail_parameters: [
      {
        name: 'id',
        verbose_name: 'id',
        description: '',
        field: 'text',
        required: true,
        default_type: 'value',
        default_value: '',
        params: {}
      }
    ],
    get_detail_parameters_use_defaults: false,
    create_query: {
      query_type: 'http',
      http_query: {
        method: 'POST',
        url: 'https://api.pipedrive.com/v1/deals',
        auth_params: {},
        body_type: 'Raw',
        body:
          '{\r\n"title": "{{params.title}}",\r\n"value": "{{params.value}}",\r\n"currency": "{{params.currency}}",\r\n"user_id": "{{params.user_id}}",\r\n"person_id": "{{params.person_id}}",\r\n"org_id": "{{params.org_id}}",\r\n"stage_id": "{{params.stage_id}}",\r\n"probability": "{{params.probability}}",\r\n"lost_reason": "{{params.lost_reason}}",\r\n"add_time": "{{params.add_time}}",\r\n"status": "{{params.status}}",\r\n"visible_to": "{{params.visible_to}}"\r\n}',
        headers: [
          {
            name: 'Accept',
            value: 'application/json'
          },
          {
            name: 'Content-Type',
            value: 'application/json'
          }
        ],
        query_params: [
          {
            name: 'api_token',
            value: '{-api_key-}'
          }
        ],
        response_type: 'json',
        response_path: 'data',
        request_tokens: {}
      }
    },
    create_parameters: [
      {
        name: 'title',
        verbose_name: 'title',
        description: '',
        field: 'text',
        required: true,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'value',
        verbose_name: 'value',
        description: '',
        field: 'text',
        required: true,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'currency',
        verbose_name: 'currency',
        description: '',
        field: 'text',
        required: true,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'user_id',
        verbose_name: 'user_id',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'person_id',
        verbose_name: 'person_id',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'org_id',
        verbose_name: 'org_id',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'stage_id',
        verbose_name: 'stage_id',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'probability',
        verbose_name: 'probability',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'lost_reason',
        verbose_name: 'lost_reason',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'add_time',
        verbose_name: 'add_time',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'status',
        verbose_name: 'status',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'visible_to',
        verbose_name: 'visible_to',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      }
    ],
    create_parameters_use_defaults: false,
    update_query: {
      query_type: 'http',
      http_query: {
        method: 'PUT',
        url: 'https://api.pipedrive.com/v1/deals/{{params.id}}',
        auth_params: {},
        body_type: 'Raw',
        body:
          '{\r\n"title": "{{params.title}}",\r\n"value": "{{params.value}}",\r\n"currency": "{{params.currency}}",\r\n"user_id": "{{params.user_id}}",\r\n"person_id": "{{params.person_id}}",\r\n"org_id": "{{params.org_id}}",\r\n"stage_id": "{{params.stage_id}}",\r\n"probability": "{{params.probability}}",\r\n"lost_reason": "{{params.lost_reason}}",\r\n"add_time": "{{params.add_time}}",\r\n"status": "{{params.status}}",\r\n"visible_to": "{{params.visible_to}}"\r\n}',
        headers: [
          {
            name: 'Accept',
            value: 'application/json'
          },
          {
            name: 'Content-Type',
            value: 'application/json'
          }
        ],
        query_params: [
          {
            name: 'api_token',
            value: '{-api_key-}'
          }
        ],
        response_type: 'json',
        response_path: 'data',
        request_tokens: {}
      }
    },
    update_parameters: [
      {
        name: 'id',
        verbose_name: 'id',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'title',
        verbose_name: 'title',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'value',
        verbose_name: 'value',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'currency',
        verbose_name: 'currency',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'user_id',
        verbose_name: 'user_id',
        description: '',
        field: 'number',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {
          type: 'integer',
          fraction: 2
        }
      },
      {
        name: 'person_id',
        verbose_name: 'person_id',
        description: '',
        field: 'number',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {
          type: 'integer',
          fraction: 2
        }
      },
      {
        name: 'org_id',
        verbose_name: 'org_id',
        description: '',
        field: 'number',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {
          type: 'integer',
          fraction: 2
        }
      },
      {
        name: 'stage_id',
        verbose_name: 'stage_id',
        description: '',
        field: 'number',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {
          type: 'integer',
          fraction: 2
        }
      },
      {
        name: 'probability',
        verbose_name: 'probability',
        description: '',
        field: 'number',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'lost_reason',
        verbose_name: 'lost_reason',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'add_time',
        verbose_name: 'add_time',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'status',
        verbose_name: 'status',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'visible_to',
        verbose_name: 'visible_to',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      }
    ],
    update_parameters_use_defaults: false,
    delete_query: {
      query_type: 'http',
      http_query: {
        method: 'DELETE',
        url: 'https://api.pipedrive.com/v1/deals/{{params.id}}',
        auth_params: {},
        body_type: 'JSON',
        body: '',
        headers: [
          {
            name: 'Accept',
            value: 'application/json'
          }
        ],
        query_params: [
          {
            name: 'api_token',
            value: '{-api_key-}'
          }
        ],
        response_type: 'json',
        response_path: '',
        request_tokens: {}
      }
    },
    delete_parameters: [
      {
        name: 'id',
        verbose_name: 'id',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      }
    ],
    delete_parameters_use_defaults: false,
    aggregate_parameters: [],
    group_parameters: [],
    sync: false,
    sync_finished: false,
    params: {},
    draft: false,
    deleted: false
  },
  {
    project: '{{project}}',
    resource: '{{resource}}',
    model: 'organization',
    verbose_name: 'Organization',
    verbose_name_plural: 'Organizations',
    fields: [
      {
        name: 'id',
        verbose_name: 'id',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'company_id',
        verbose_name: 'company id',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'owner_id',
        verbose_name: 'owner id',
        field: 'json',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'name',
        verbose_name: 'name',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'open_deals_count',
        verbose_name: 'open deals count',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'related_open_deals_count',
        verbose_name: 'related open deals count',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'closed_deals_count',
        verbose_name: 'closed deals count',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'related_closed_deals_count',
        verbose_name: 'related closed deals count',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'email_messages_count',
        verbose_name: 'email messages count',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'people_count',
        verbose_name: 'people count',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'activities_count',
        verbose_name: 'activities count',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'done_activities_count',
        verbose_name: 'done activities count',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'undone_activities_count',
        verbose_name: 'undone activities count',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'files_count',
        verbose_name: 'files count',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'notes_count',
        verbose_name: 'notes count',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'followers_count',
        verbose_name: 'followers count',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'won_deals_count',
        verbose_name: 'won deals count',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'related_won_deals_count',
        verbose_name: 'related won deals count',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'lost_deals_count',
        verbose_name: 'lost deals count',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'related_lost_deals_count',
        verbose_name: 'related lost deals count',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'active_flag',
        verbose_name: 'active flag',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'category_id',
        verbose_name: 'category id',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'picture_id',
        verbose_name: 'picture id',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'country_code',
        verbose_name: 'country code',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'first_char',
        verbose_name: 'first char',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'update_time',
        verbose_name: 'update time',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {
          date: true,
          time: false
        }
      },
      {
        name: 'add_time',
        verbose_name: 'add time',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {
          date: true,
          time: false
        }
      },
      {
        name: 'visible_to',
        verbose_name: 'visible to',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'next_activity_date',
        verbose_name: 'next activity date',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'next_activity_time',
        verbose_name: 'next activity time',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'next_activity_id',
        verbose_name: 'next activity id',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'last_activity_id',
        verbose_name: 'last activity id',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'last_activity_date',
        verbose_name: 'last activity date',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'label',
        verbose_name: 'label',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'address',
        verbose_name: 'address',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'address_subpremise',
        verbose_name: 'address subpremise',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'address_street_number',
        verbose_name: 'address street number',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'address_route',
        verbose_name: 'address route',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'address_sublocality',
        verbose_name: 'address sublocality',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'address_locality',
        verbose_name: 'address locality',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'address_admin_area_level_1',
        verbose_name: 'address admin area level 1',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'address_admin_area_level_2',
        verbose_name: 'address admin area level 2',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'address_country',
        verbose_name: 'address country',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'address_postal_code',
        verbose_name: 'address postal code',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'address_formatted_address',
        verbose_name: 'address formatted address',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'owner_name',
        verbose_name: 'owner name',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'cc_email',
        verbose_name: 'cc email',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      }
    ],
    flex_fields: [],
    segments: [],
    primary_key_field: 'id',
    relations: [],
    description: 'Searches for organizations.',
    get_query: {
      query_type: 'http',
      http_query: {
        method: 'GET',
        url: 'https://api.pipedrive.com/v1/organizations',
        auth_params: {},
        body_type: 'JSON',
        body: '',
        headers: [],
        query_params: [
          {
            name: 'sort',
            value: "{% if (sorting.field) { %}{{sorting.field}} {{sorting.asc ? 'ASC': 'DESC'}}{% } %}"
          },
          {
            name: 'api_token',
            value: '{-api_key-}'
          },
          {
            name: 'start',
            value: '{{paging.offset}}'
          },
          {
            name: 'limit',
            value: '{{paging.limit}}'
          },
          {
            name: 'user_id',
            value: '{{params.user_id}}'
          },
          {
            name: 'filter_id',
            value: '{{params.filter_id}}'
          },
          {
            name: 'first_char',
            value: '{{params.first_char}}'
          }
        ],
        response_type: 'json',
        response_transformer:
          "return (data && data['data'] || []).map(item => {\n    const result = {};\n    \n    Object.keys(item).forEach(key => {\n        if (item[key] && item[key]['value']) {\n            result[key] = item[key]['value'];\n        } else {\n            result[key] = item[key];\n        }\n    });\n    \n    return result;\n});",
        response_path: '',
        request_tokens: {}
      },
      pagination: 'offset',
      pagination_has_more_function:
        "// ex. data['hasMore']\nreturn data['additional_data']['pagination']['more_items_in_collection'];",
      pagination_total_function: "// ex. data['count']\nreturn ",
      pagination_cursor_next_function: "// ex. data['next_cursor']\nreturn ",
      pagination_has_more_pages_path: [],
      pagination_has_more_total_pages_path: [],
      pagination_has_more_total_records_path: [],
      sorting: true
    },
    get_parameters: [
      {
        name: 'user_id',
        verbose_name: 'user_id',
        description: '',
        field: 'number',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {
          type: 'integer',
          fraction: 2
        }
      },
      {
        name: 'filter_id',
        verbose_name: 'filter_id',
        description: '',
        field: 'number',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {
          type: 'integer',
          fraction: 2
        }
      },
      {
        name: 'first_char',
        verbose_name: 'first_char',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      }
    ],
    get_inputs: [],
    get_detail_query: {
      query_type: 'http',
      http_query: {
        method: 'GET',
        url: 'https://api.pipedrive.com/v1/organizations/{{params.id}}',
        auth_params: {},
        body_type: 'Raw',
        body: '',
        headers: [
          {
            name: 'Accept',
            value: 'application/json'
          }
        ],
        query_params: [
          {
            name: 'api_token',
            value: '{-api_key-}'
          }
        ],
        response_type: 'json',
        response_transformer:
          "const item = data['data'];\nconst result = {};\n\nObject.keys(item).forEach(key => {\n    if (item[key] && item[key]['value']) {\n        result[key] = item[key]['value'];\n    } else {\n        result[key] = item[key];\n    }\n});\n\nreturn result;",
        response_path: '',
        request_tokens: {}
      }
    },
    get_detail_parameters: [
      {
        name: 'id',
        verbose_name: 'id',
        description: '',
        field: 'text',
        required: true,
        default_type: 'value',
        default_value: '',
        params: {}
      }
    ],
    get_detail_parameters_use_defaults: false,
    create_query: {
      query_type: 'http',
      http_query: {
        method: 'POST',
        url: 'https://api.pipedrive.com/v1/organizations',
        auth_params: {},
        body_type: 'Raw',
        body:
          '{\r\n"name": "{{params.name}}",\r\n"owner_id": {{params.owner_id}},\r\n"add_time": "{{params.add_time}}",\r\n"visible_to": "{{params.visible_to}}"\r\n}',
        headers: [
          {
            name: 'Accept',
            value: 'application/json'
          },
          {
            name: 'Content-Type',
            value: 'application/json'
          }
        ],
        query_params: [
          {
            name: 'api_token',
            value: '{-api_key-}'
          }
        ],
        response_type: 'json',
        response_path: 'data',
        request_tokens: {}
      }
    },
    create_parameters: [
      {
        name: 'name',
        verbose_name: 'name',
        description: '',
        field: 'text',
        required: true,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'owner_id',
        verbose_name: 'owner_id',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'add_time',
        verbose_name: 'add_time',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'visible_to',
        verbose_name: 'visible_to',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      }
    ],
    create_parameters_use_defaults: false,
    update_query: {
      query_type: 'http',
      http_query: {
        method: 'PUT',
        url: 'https://api.pipedrive.com/v1/organizations/{{params.id}}',
        auth_params: {},
        body_type: 'Raw',
        body:
          '{\r\n"name": "{{params.name}}",\r\n"owner_id": {{params.owner_id}},\r\n"visible_to": "{{params.visible_to}}"\r\n}',
        headers: [
          {
            name: 'Accept',
            value: 'application/json'
          },
          {
            name: 'Content-Type',
            value: 'application/json'
          }
        ],
        query_params: [
          {
            name: 'api_token',
            value: '{-api_key-}'
          }
        ],
        response_type: 'json',
        response_path: 'data',
        request_tokens: {}
      }
    },
    update_parameters: [
      {
        name: 'id',
        verbose_name: 'id',
        description: '',
        field: 'text',
        required: true,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'name',
        verbose_name: 'name',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'owner_id',
        verbose_name: 'owner_id',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'visible_to',
        verbose_name: 'visible_to',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      }
    ],
    update_parameters_use_defaults: false,
    delete_query: {
      query_type: 'http',
      http_query: {
        method: 'DELETE',
        url: 'https://api.pipedrive.com/v1/organizations/{{params.id}}',
        auth_params: {},
        body_type: 'JSON',
        body: '',
        headers: [
          {
            name: 'Accept',
            value: 'application/json'
          }
        ],
        query_params: [
          {
            name: 'api_token',
            value: '{-api_key-}'
          }
        ],
        response_type: 'json',
        response_path: '',
        request_tokens: {}
      }
    },
    delete_parameters: [
      {
        name: 'id',
        verbose_name: 'id',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      }
    ],
    delete_parameters_use_defaults: false,
    aggregate_parameters: [],
    group_parameters: [],
    sync: false,
    sync_finished: false,
    params: {},
    draft: false,
    deleted: false
  },
  {
    project: '{{project}}',
    resource: '{{resource}}',
    model: 'person',
    verbose_name: 'Person',
    verbose_name_plural: 'People',
    fields: [
      {
        name: 'id',
        verbose_name: 'id',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'company_id',
        verbose_name: 'company id',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'owner_id',
        verbose_name: 'owner',
        field: 'related_model',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {
          related_model: {
            model: '{{resource}}.organization'
          },
          custom_primary_key: 'id'
        }
      },
      {
        name: 'org_id',
        verbose_name: 'org',
        field: 'related_model',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {
          related_model: {
            model: '{{resource}}.organization'
          },
          custom_primary_key: 'id'
        }
      },
      {
        name: 'name',
        verbose_name: 'name',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'first_name',
        verbose_name: 'first name',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'last_name',
        verbose_name: 'last name',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'open_deals_count',
        verbose_name: 'open deals count',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'related_open_deals_count',
        verbose_name: 'related open deals count',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'closed_deals_count',
        verbose_name: 'closed deals count',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'related_closed_deals_count',
        verbose_name: 'related closed deals count',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'participant_open_deals_count',
        verbose_name: 'participant open deals count',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'participant_closed_deals_count',
        verbose_name: 'participant closed deals count',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'email_messages_count',
        verbose_name: 'email messages count',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'activities_count',
        verbose_name: 'activities count',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'done_activities_count',
        verbose_name: 'done activities count',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'undone_activities_count',
        verbose_name: 'undone activities count',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'reference_activities_count',
        verbose_name: 'reference activities count',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'files_count',
        verbose_name: 'files count',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'notes_count',
        verbose_name: 'notes count',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'followers_count',
        verbose_name: 'followers count',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'won_deals_count',
        verbose_name: 'won deals count',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'related_won_deals_count',
        verbose_name: 'related won deals count',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'lost_deals_count',
        verbose_name: 'lost deals count',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'related_lost_deals_count',
        verbose_name: 'related lost deals count',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'active_flag',
        verbose_name: 'active flag',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'phone',
        verbose_name: 'phone',
        field: 'json',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'email',
        verbose_name: 'email',
        field: 'json',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'first_char',
        verbose_name: 'first char',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'update_time',
        verbose_name: 'update time',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'add_time',
        verbose_name: 'add time',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'visible_to',
        verbose_name: 'visible to',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'picture_id',
        verbose_name: 'picture id',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'next_activity_date',
        verbose_name: 'next activity date',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'next_activity_time',
        verbose_name: 'next activity time',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'next_activity_id',
        verbose_name: 'next activity id',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'last_activity_id',
        verbose_name: 'last activity id',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'last_activity_date',
        verbose_name: 'last activity date',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'last_incoming_mail_time',
        verbose_name: 'last incoming mail time',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'last_outgoing_mail_time',
        verbose_name: 'last outgoing mail time',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'label',
        verbose_name: 'label',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'org_name',
        verbose_name: 'org name',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'owner_name',
        verbose_name: 'owner name',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'cc_email',
        verbose_name: 'cc email',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      }
    ],
    flex_fields: [],
    segments: [],
    primary_key_field: 'id',
    relations: [],
    description:
      'Lists all persons associated with a deal, regardless of whether the person is the primary contact of the deal, or added as a participant.',
    get_query: {
      query_type: 'http',
      http_query: {
        method: 'GET',
        url: 'https://api.pipedrive.com/v1/persons',
        auth_params: {},
        body_type: 'JSON',
        body: '',
        headers: [],
        query_params: [
          {
            name: 'sort',
            value: "{% if (sorting.field) { %}{{sorting.field}} {{sorting.asc ? 'ASC': 'DESC'}}{% } %}"
          },
          {
            name: 'api_token',
            value: '{-api_key-}'
          },
          {
            name: 'start',
            value: '{{paging.offset}}'
          },
          {
            name: 'limit',
            value: '{{paging.limit}}'
          },
          {
            name: 'user_id',
            value: '{{params.user_id}}'
          },
          {
            name: 'filter_id',
            value: '{{params.filter_id}}'
          },
          {
            name: 'first_char',
            value: '{{params.first_char}}'
          }
        ],
        response_type: 'json',
        response_transformer:
          "return (data && data['data'] || []).map(item => {\n    const result = {};\n    \n    Object.keys(item).forEach(key => {\n        if (item[key] && item[key]['value']) {\n            result[key] = item[key]['value'];\n        } else {\n            result[key] = item[key];\n        }\n    });\n    \n    return result;\n});",
        response_path: '',
        request_tokens: {}
      },
      pagination: 'offset',
      pagination_has_more_function:
        "// ex. data['hasMore']\nreturn data['additional_data']['pagination']['more_items_in_collection'];",
      pagination_total_function: "// ex. data['count']\nreturn ",
      pagination_cursor_next_function: "// ex. data['next_cursor']\nreturn ",
      pagination_has_more_pages_path: [],
      pagination_has_more_total_pages_path: [],
      pagination_has_more_total_records_path: [],
      sorting: true
    },
    get_parameters: [
      {
        name: 'user_id',
        verbose_name: 'user_id',
        description: '',
        field: 'number',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {
          type: 'integer',
          fraction: 2
        }
      },
      {
        name: 'filter_id',
        verbose_name: 'filter_id',
        description: '',
        field: 'number',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {
          type: 'integer',
          fraction: 2
        }
      },
      {
        name: 'first_char',
        verbose_name: 'first_char',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      }
    ],
    get_inputs: [],
    get_detail_query: {
      query_type: 'http',
      http_query: {
        method: 'GET',
        url: 'https://api.pipedrive.com/v1/persons/{{params.person_id}}',
        auth_params: {},
        body_type: 'Raw',
        body: '',
        headers: [
          {
            name: 'Accept',
            value: 'application/json'
          }
        ],
        query_params: [
          {
            name: 'api_token',
            value: '{-api_key-}'
          }
        ],
        response_type: 'json',
        response_transformer:
          "const item = data['data'];\nconst result = {};\n\nObject.keys(item).forEach(key => {\n    if (item[key] && item[key]['value']) {\n        result[key] = item[key]['value'];\n    } else {\n        result[key] = item[key];\n    }\n});\n\nreturn result;",
        response_path: '',
        request_tokens: {}
      }
    },
    get_detail_parameters: [
      {
        name: 'person_id',
        verbose_name: 'person_id',
        description: '',
        field: 'text',
        required: true,
        default_type: 'value',
        default_value: '',
        params: {}
      }
    ],
    get_detail_parameters_use_defaults: false,
    create_query: {
      query_type: 'http',
      http_query: {
        method: 'POST',
        url: 'https://api.pipedrive.com/v1/persons',
        auth_params: {},
        body_type: 'Raw',
        body:
          '{\r\n"name": "{{params.name}}",\r\n"owner_id": "{{params.owner_id}}",\r\n"org_id": "{{params.org_id}}",\r\n"phone": "{{params.phone}}",\r\n"email": "{{params.email}}",\r\n"add_time": "{{params.add_time}}",\r\n"visible_to": "{{params.visible_to}}"\r\n}',
        headers: [
          {
            name: 'Accept',
            value: 'application/json'
          },
          {
            name: 'Content-Type',
            value: 'application/json'
          }
        ],
        query_params: [
          {
            name: 'api_token',
            value: '{-api_key-}'
          }
        ],
        response_type: 'json',
        response_transformer:
          "const item = data['data'];\nconst result = {};\n\nObject.keys(item).forEach(key => {\n    if (item[key] && item[key]['value']) {\n        result[key] = item[key]['value'];\n    } else {\n        result[key] = item[key];\n    }\n});\n\nreturn result;",
        response_path: '',
        body_transformer:
          'function getFullDict(data) {\n    for (var key in data) {\n        var value = data[key];\n        if (value == "" || value == "{}" || value == "null") {\n            delete data[key];\n        }\n    }\n    return data;\n}\n// add custom transformation here\nreturn getFullDict(data);',
        request_tokens: {}
      }
    },
    create_parameters: [
      {
        name: 'name',
        verbose_name: 'name',
        description: '',
        field: 'text',
        required: true,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'owner_id',
        verbose_name: 'owner_id',
        description: '',
        field: 'number',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {
          type: 'integer',
          fraction: 2
        }
      },
      {
        name: 'org_id',
        verbose_name: 'org_id',
        description: '',
        field: 'number',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {
          type: 'integer',
          fraction: 2
        }
      },
      {
        name: 'phone',
        verbose_name: 'phone',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'email',
        verbose_name: 'email',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'add_time',
        verbose_name: 'add_time',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'visible_to',
        verbose_name: 'visible_to',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      }
    ],
    create_parameters_use_defaults: false,
    update_query: {
      query_type: 'http',
      http_query: {
        method: 'PUT',
        url: 'https://api.pipedrive.com/v1/persons/{{params.person_id}}',
        auth_params: {},
        body_type: 'Raw',
        body:
          '{\r\n"name": "{{params.name}}",\r\n"owner_id": "{{params.owner_id}}",\r\n"org_id": "{{params.org_id}}",\r\n"phone": "{{params.phone}}",\r\n"email": "{{params.email}}",\r\n"add_time": "{{params.add_time}}",\r\n"visible_to": "{{params.visible_to}}"\r\n}',
        headers: [
          {
            name: 'Accept',
            value: 'application/json'
          },
          {
            name: 'Content-Type',
            value: 'application/json'
          }
        ],
        query_params: [
          {
            name: 'api_token',
            value: '{-api_key-}'
          }
        ],
        response_type: 'json',
        response_path: 'data',
        request_tokens: {}
      }
    },
    update_parameters: [
      {
        name: 'person_id',
        verbose_name: 'id',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'name',
        verbose_name: 'name',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'owner_id',
        verbose_name: 'owner_id',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'org_id',
        verbose_name: 'org_id',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'phone',
        verbose_name: 'phone',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'email',
        verbose_name: 'email',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'add_time',
        verbose_name: 'add_time',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'visible_to',
        verbose_name: 'visible_to',
        description: '',
        field: 'text',
        required: false,
        default_type: 'value',
        default_value: '',
        params: {}
      }
    ],
    update_parameters_use_defaults: false,
    delete_query: {
      query_type: 'http',
      http_query: {
        method: 'DELETE',
        url: 'https://api.pipedrive.com/v1/persons/{{params.person_id}}',
        auth_params: {},
        body_type: 'JSON',
        body: '',
        headers: [
          {
            name: 'Accept',
            value: 'application/json'
          }
        ],
        query_params: [
          {
            name: 'api_token',
            value: '{-api_key-}'
          }
        ],
        response_type: 'json',
        response_path: '',
        request_tokens: {}
      }
    },
    delete_parameters: [
      {
        name: 'person_id',
        verbose_name: 'id',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      }
    ],
    delete_parameters_use_defaults: false,
    aggregate_parameters: [],
    group_parameters: [],
    sync: false,
    sync_finished: false,
    params: {},
    draft: false,
    deleted: false
  },
  {
    project: '{{project}}',
    resource: '{{resource}}',
    model: 'users',
    verbose_name: 'Users',
    verbose_name_plural: 'Users',
    fields: [
      {
        name: 'id',
        verbose_name: 'id',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'name',
        verbose_name: 'name',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'default_currency',
        verbose_name: 'default currency',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'locale',
        verbose_name: 'locale',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'email',
        verbose_name: 'email',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'phone',
        verbose_name: 'phone',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'created',
        verbose_name: 'created',
        field: 'datetime',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {
          date: true,
          time: true
        }
      },
      {
        name: 'modified',
        verbose_name: 'modified',
        field: 'datetime',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {
          date: true,
          time: true
        }
      },
      {
        name: 'active_flag',
        verbose_name: 'active flag',
        field: 'boolean',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'is_admin',
        verbose_name: 'is admin',
        field: 'boolean',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'last_login',
        verbose_name: 'last login',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'lang',
        verbose_name: 'lang',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'signup_flow_variation',
        verbose_name: 'signup flow variation',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'has_created_company',
        verbose_name: 'has created company',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'role_id',
        verbose_name: 'role id',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'activated',
        verbose_name: 'activated',
        field: 'boolean',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'timezone_name',
        verbose_name: 'timezone name',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'timezone_offset',
        verbose_name: 'timezone offset',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'icon_url',
        verbose_name: 'icon url',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'is_you',
        verbose_name: 'is you',
        field: 'text',
        required: true,
        null: false,
        filterable: false,
        sortable: true,
        default_type: 'value',
        params: {}
      }
    ],
    flex_fields: [],
    segments: [],
    primary_key_field: 'id',
    relations: [],
    description: 'Returns data about all users.',
    get_query: {
      query_type: 'http',
      http_query: {
        method: 'GET',
        url: 'https://api.pipedrive.com/v1/users',
        auth_params: {},
        body_type: 'JSON',
        body: '',
        headers: [
          {
            name: 'Accept',
            value: 'application/json'
          }
        ],
        query_params: [
          {
            name: 'api_token',
            value: '{-api_key-}'
          }
        ],
        response_type: 'json',
        response_transformer:
          "return (data && data['data'] || []).map(item => {\n    const result = {};\n    \n    Object.keys(item).forEach(key => {\n        if (item[key] && item[key]['value']) {\n            result[key] = item[key]['value'];\n        } else {\n            result[key] = item[key];\n        }\n    });\n    \n    return result;\n});",
        response_path: '',
        request_tokens: {}
      },
      pagination_has_more_function: "// ex. data['hasMore']\nreturn ",
      pagination_total_function: "// ex. data['count']\nreturn ",
      pagination_cursor_next_function: "// ex. data['next_cursor']\nreturn ",
      pagination_has_more_pages_path: [],
      pagination_has_more_total_pages_path: [],
      pagination_has_more_total_records_path: [],
      sorting: false
    },
    get_parameters: [],
    get_inputs: [],
    get_detail_query: {
      query_type: 'http',
      http_query: {
        method: 'GET',
        url: 'https://api.pipedrive.com/v1/users/{{params.id}}',
        auth_params: {},
        body_type: 'JSON',
        body: '',
        headers: [
          {
            name: 'Accept',
            value: 'application/json'
          }
        ],
        query_params: [
          {
            name: 'api_token',
            value: '{-api_key-}'
          }
        ],
        response_type: 'json',
        response_transformer:
          "const item = data['data'];\nconst result = {};\n\nObject.keys(item).forEach(key => {\n    if (item[key] && item[key]['value']) {\n        result[key] = item[key]['value'];\n    } else {\n        result[key] = item[key];\n    }\n});\n\nreturn result;",
        response_path: '',
        request_tokens: {}
      }
    },
    get_detail_parameters: [
      {
        name: 'id',
        verbose_name: 'id',
        description: '',
        field: 'text',
        required: true,
        default_type: 'value',
        default_value: '',
        params: {}
      }
    ],
    get_detail_parameters_use_defaults: false,
    create_query: {
      query_type: 'http',
      http_query: {
        method: 'POST',
        url: 'https://api.pipedrive.com/v1/users',
        auth_params: {},
        body_type: 'Raw',
        body:
          '{\r\n"name": "{{params.name}}",\r\n"email": "{{params.email}}",\r\n"active_flag": "{{params.active_flag}}"\r\n}',
        headers: [
          {
            name: 'Accept',
            value: 'application/json'
          },
          {
            name: 'Content-Type',
            value: 'application/json'
          }
        ],
        query_params: [
          {
            name: 'api_token',
            value: '{-api_key-}'
          }
        ],
        response_type: 'json',
        response_path: '',
        request_tokens: {}
      }
    },
    create_parameters: [
      {
        name: 'name',
        verbose_name: 'name',
        description: '',
        field: 'text',
        required: true,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'email',
        verbose_name: 'email',
        description: '',
        field: 'text',
        required: true,
        default_type: 'value',
        default_value: '',
        params: {}
      },
      {
        name: 'active_flag',
        verbose_name: 'active_flag',
        description: '',
        field: 'text',
        required: true,
        default_type: 'value',
        default_value: '',
        params: {}
      }
    ],
    create_parameters_use_defaults: false,
    update_query: {
      query_type: 'http',
      http_query: {
        method: 'PUT',
        url: 'https://api.pipedrive.com/v1/users/{{params.id}}',
        auth_params: {},
        body_type: 'Raw',
        body: '{\r\n"active_flag": "{{params.active_flag}}"\r\n}',
        headers: [
          {
            name: 'Accept',
            value: 'application/json'
          },
          {
            name: 'Content-Type',
            value: 'application/json'
          }
        ],
        query_params: [
          {
            name: 'api_token',
            value: '{-api_key-}'
          }
        ],
        response_type: 'json',
        response_path: '',
        request_tokens: {}
      }
    },
    update_parameters: [
      {
        name: 'id',
        verbose_name: 'id',
        field: 'number',
        required: true,
        default_type: 'value',
        params: {
          type: 'integer',
          fraction: 2
        }
      },
      {
        name: 'active_flag',
        verbose_name: 'active_flag',
        description: '',
        field: 'boolean',
        required: true,
        default_type: 'value',
        default_value: '',
        params: {}
      }
    ],
    update_parameters_use_defaults: false,
    delete_query: {
      query_type: 'http',
      http_query: {
        method: 'DELETE',
        url: 'https://api.pipedrive.com/v1/users/{{params.id}}/roleAssignments',
        auth_params: {},
        body_type: 'Raw',
        body: '',
        headers: [
          {
            name: 'Accept',
            value: 'application/json'
          }
        ],
        query_params: [
          {
            name: 'api_token',
            value: '{-api_key-}'
          },
          {
            name: 'role_id',
            value: '{{params.role_id}}'
          }
        ],
        response_type: 'json',
        response_path: '',
        request_tokens: {}
      }
    },
    delete_parameters: [
      {
        name: 'id',
        verbose_name: 'id',
        field: 'text',
        required: true,
        default_type: 'value',
        params: {}
      },
      {
        name: 'role_id',
        verbose_name: 'role_id',
        description: '',
        field: 'text',
        required: true,
        default_type: 'value',
        default_value: '',
        params: {}
      }
    ],
    delete_parameters_use_defaults: false,
    aggregate_parameters: [],
    group_parameters: [],
    sync: false,
    sync_finished: false,
    params: {},
    draft: false,
    deleted: false
  }
];

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  OnInit
} from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import * as Color from 'color';
import toPairs from 'lodash/toPairs';

import { getColorHex, isLightColor } from '@modules/colors';
import { MenuBlock, MenuGeneratorService, MenuItem } from '@modules/menu';
import { isSet, TypedChanges } from '@shared';

export namespace MenuSecondary {
  export function isLight(color: string): boolean {
    if (!isSet(color)) {
      return;
    }

    return isLightColor(color);
  }

  export function getLightColor(color: string): string {
    try {
      const colorHex = getColorHex(color);
      const clr = Color(colorHex);
      return clr.lighten(0.2).string();
    } catch (e) {}
  }

  export function getDarkColor(color: string): string {
    try {
      const colorHex = getColorHex(color);
      const clr = Color(colorHex);
      return clr.darken(0.05).string();
    } catch (e) {}
  }

  export function getDark2Color(color: string): string {
    try {
      const colorHex = getColorHex(color);
      const clr = Color(colorHex);
      return clr.darken(0.45).desaturate(0.35).string();
    } catch (e) {}
  }

  export function getDark3Color(color: string): string {
    try {
      const colorHex = getColorHex(color);
      const clr = Color(colorHex);
      return clr.darken(0.7).desaturate(0.25).string();
    } catch (e) {}
  }

  export function getVars(accentColor: string): Object {
    if (!isSet(accentColor)) {
      accentColor = '#f3f5f8';
    }

    const lightColor = getLightColor(accentColor);
    const darkColor = getDarkColor(accentColor);
    const dark2Color = getDark2Color(accentColor);
    const dark3Color = getDark3Color(accentColor);

    return {
      ...(lightColor && { 'light-color': lightColor }),
      ...(darkColor && { 'dark-color': darkColor }),
      ...(dark2Color && { 'dark2-color': dark2Color }),
      ...(dark3Color && { 'dark3-color': dark3Color })
    };
  }
}

@Component({
  selector: 'app-menu-secondary',
  templateUrl: './menu-secondary.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MenuSecondaryComponent implements OnInit, OnDestroy, OnChanges {
  @Input() block: MenuBlock;
  @Input() horizontal = false;

  @HostBinding('style') menuStyle: SafeStyle;

  startItems: MenuItem[] = [];
  centerItems: MenuItem[] = [];
  endItems: MenuItem[] = [];
  menuIsLight: boolean;

  trackMenuItemFn(i, item: MenuItem) {
    return item.id;
  }

  constructor(
    private menuGeneratorService: MenuGeneratorService,
    private sanitizer: DomSanitizer,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {}

  ngOnDestroy(): void {}

  ngOnChanges(changes: TypedChanges<MenuSecondaryComponent>): void {
    if (changes.block) {
      this.updateItems();
      this.menuIsLight = MenuSecondary.isLight(this.block.accentColor);
      this.menuStyle = this.getStyleVars(this.block.accentColor);
    }
  }

  updateItems() {
    this.startItems = this.menuGeneratorService.cleanMenuItemsAppMode(this.block.startItems);
    this.centerItems = this.menuGeneratorService.cleanMenuItemsAppMode(this.block.centerItems);
    this.endItems = this.menuGeneratorService.cleanMenuItemsAppMode(this.block.endItems);
    this.cd.markForCheck();
  }

  getStyleVars(accentColor: string): SafeStyle {
    const vars = MenuSecondary.getVars(accentColor);
    const styles = toPairs(vars)
      .map(([k, v]) => `--${k}: ${v}`)
      .join(';');

    return this.sanitizer.bypassSecurityTrustStyle(styles);
  }
}

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';

import { ViewContext } from '@modules/customize';
import { Template, TemplateService, TemplateType } from '@modules/template';
import { KeyboardEventKeyCode } from '@shared';

import { ViewEditorContext, ViewEditorTool } from '../../services/view-editor-context/view-editor.context';

export interface ToolItem {
  label: string;
  icon: string;
  tool?: ViewEditorTool;
  toolHotkey?: KeyboardEventKeyCode;
  toolFile?: boolean;
  toolElement?: boolean;
}

export interface RootToolItem extends ToolItem {
  children?: ToolItem[];
}

@Component({
  selector: 'app-view-editor-tools',
  templateUrl: './view-editor-tools.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ViewEditorToolsComponent implements OnInit, OnDestroy {
  @Input() viewContext: ViewContext;

  tools: RootToolItem[] = [
    {
      label: 'Selection',
      icon: 'cursor',
      tool: ViewEditorTool.Selection,
      toolHotkey: KeyboardEventKeyCode.V
    },
    {
      label: 'Hand',
      icon: 'hand_regular',
      tool: ViewEditorTool.Hand,
      toolHotkey: KeyboardEventKeyCode.H
    },
    {
      label: 'Frame',
      icon: 'corners',
      tool: ViewEditorTool.Frame,
      toolHotkey: KeyboardEventKeyCode.F
    },
    {
      label: 'Shapes',
      icon: 'rectangle',
      children: [
        {
          label: 'Rectangle',
          icon: 'rectangle',
          tool: ViewEditorTool.Rectangle,
          toolHotkey: KeyboardEventKeyCode.R
        },
        {
          tool: ViewEditorTool.Ellipse,
          label: 'Ellipse',
          icon: 'circle_2',
          toolHotkey: KeyboardEventKeyCode.O
        },
        // {
        //   label: 'Rounded',
        //   icon: 'rectangle_rounded',
        //   tool: ViewEditorTool.Rectangle,
        //   toolHotkey: KeyboardEventKeyCode.U
        // },
        {
          label: 'Line',
          icon: 'line',
          tool: ViewEditorTool.Line,
          toolHotkey: KeyboardEventKeyCode.L
        }
        // {
        //   label: 'Arrow',
        //   icon: 'arrow_increase',
        //   tool: ViewEditorTool.Rectangle
        // },
        // {
        //   label: 'Triangle',
        //   icon: 'triangle',
        //   tool: ViewEditorTool.Rectangle
        // },
        // {
        //   label: 'Polygon',
        //   icon: 'polygon',
        //   tool: ViewEditorTool.Rectangle
        // },
        // {
        //   label: 'Star',
        //   icon: 'star',
        //   tool: ViewEditorTool.Rectangle
        // }
      ]
    },
    {
      label: 'Text',
      icon: 'text_2',
      tool: ViewEditorTool.Text,
      toolHotkey: KeyboardEventKeyCode.T
    },
    {
      label: 'Image',
      icon: 'image',
      tool: ViewEditorTool.Image,
      toolFile: true
    },
    {
      label: 'Components',
      icon: 'components_2',
      tool: ViewEditorTool.Element,
      toolElement: true
    }
  ];
  toolOpenedIndex: number;
  elementTemplates: Template[] = [];
  elementTemplatesLoading = false;

  constructor(
    public editorContext: ViewEditorContext,
    private templateService: TemplateService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.loadElementTemplates();

    this.editorContext
      .trackKeydown()
      .pipe(untilDestroyed(this))
      .subscribe(e => {
        const tools = this.getAllTools();

        for (const tool of tools) {
          if (tool.toolHotkey && tool.toolHotkey == e.keyCode && !e.ctrlKey && !e.shiftKey && !e.metaKey && !e.altKey) {
            e.stopPropagation();
            this.editorContext.tool$.next(tool.tool);
            break;
          }
        }
      });
  }

  ngOnDestroy(): void {}

  getAllTools(): ToolItem[] {
    return this.tools.reduce((acc, item) => {
      acc.push(item);

      if (item.children) {
        acc.push(...item.children);
      }

      return acc;
    }, []);
  }

  setToolOpenedIndex(value: number) {
    this.toolOpenedIndex = value;
    this.cd.markForCheck();
  }

  loadElementTemplates() {
    this.elementTemplatesLoading = true;
    this.cd.markForCheck();

    this.templateService
      .get({ type: TemplateType.DefaultComponent })
      .pipe(untilDestroyed(this))
      .subscribe(
        result => {
          this.elementTemplates = result;
          this.elementTemplatesLoading = false;
          this.cd.markForCheck();
        },
        () => {
          this.elementTemplatesLoading = false;
          this.cd.markForCheck();
        }
      );
  }
}

import { OnInit } from '@angular/core';
var PageComponent = /** @class */ (function () {
    function PageComponent() {
        this.paddingLeft = false;
        this.paddingHorizontal = false;
        this.cls = true;
    }
    Object.defineProperty(PageComponent.prototype, "paddingLeftCls", {
        get: function () {
            return this.paddingLeft;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PageComponent.prototype, "paddingHorizontalCls", {
        get: function () {
            return this.paddingHorizontal;
        },
        enumerable: true,
        configurable: true
    });
    PageComponent.prototype.ngOnInit = function () { };
    return PageComponent;
}());
export { PageComponent };

import { ReplaySubject } from 'rxjs';
import * as i0 from "@angular/core";
export var EmbedScripts;
(function (EmbedScripts) {
    EmbedScripts.CKEditor = 'https://cdn.ckeditor.com/4.22.1/full/ckeditor.js';
    EmbedScripts.Paddle = 'https://cdn.paddle.com/paddle/paddle.js';
    EmbedScripts.Tally = 'https://tally.so/widgets/embed.js';
})(EmbedScripts || (EmbedScripts = {}));
var ScriptsService = /** @class */ (function () {
    function ScriptsService() {
        this.loaded = {};
    }
    ScriptsService.prototype.loadSingleton = function (src) {
        if (this.loaded[src]) {
            return this.loaded[src].loaded$;
        }
        var result = new ReplaySubject();
        var script = document.createElement('script');
        script.onload = function () { return result.next(); };
        script.src = src;
        script.async = true;
        script.defer = true;
        document.head.appendChild(script);
        this.loaded[src] = {
            src: src,
            loaded$: result
        };
        return result.asObservable();
    };
    ScriptsService.prototype.initTagManager = function (gtmId, dataLayerKey) {
        if (dataLayerKey === void 0) { dataLayerKey = 'dataLayer'; }
        window[dataLayerKey] = window[dataLayerKey] || [];
        window[dataLayerKey].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
        var src = "https://www.googletagmanager.com/gtm.js?id=" + gtmId;
        if (dataLayerKey != 'dataLayer') {
            src += '&l=' + dataLayerKey;
        }
        return this.loadSingleton(src);
    };
    ScriptsService.ngInjectableDef = i0.defineInjectable({ factory: function ScriptsService_Factory() { return new ScriptsService(); }, token: ScriptsService, providedIn: "root" });
    return ScriptsService;
}());
export { ScriptsService };

<td class="table__column table__column_left-padding"></td>

<td class="table__column">
  <div class="table__column-inner">
    <div
      [style.background-image]="item.user.photo ? 'url(' + item.user.photo + ')' : ''"
      [appTip]="item.user.firstName + ' ' + item.user.lastName"
      [appTipOptions]="{ side: 'bottom' }"
      class="user-activities-popup__photo"
    ></div>
  </div>
</td>

<td class="table__column">
  <div class="table__column-inner">
    <div class="user-activities-popup__main">
      <div>
        {{ item.description({ user: item.user?.strFull }) }}
      </div>

      <div app-user-activities-description [relations]="relations" [item]="item"></div>
    </div>

    <div class="user-activities-popup__bottom">
      <span [appTip]="item.dateAdd | appFormatDate: 'lll'" [appTipOptions]="{ side: 'bottom' }">
        <span class="user-activities-popup__icon icon-time"></span>
        {{ item.dateAdd | appTimeAgo }}
      </span>
    </div>
  </div>
</td>

<div class="sync-mode-image">
  <div class="sync-mode-image__left">
    <div class="sync-mode-image__block">
      <div
        class="sync-mode-image__block-icon"
        [style.background-image]="'/assets/images/resources/icons/' + typeItem?.icon + '.svg' | appDeployCssUrl"
      ></div>
      <div class="sync-mode-image__block-title" [style.color]="typeItem.color">{{ typeItem?.label }}</div>
    </div>
  </div>

  <div class="sync-mode-image__center">
    <div class="sync-mode-image__connection">
      <div class="sync-mode-image__connection-top">
        <svg
          width="74px"
          height="8px"
          viewBox="0 0 74 8"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
        >
          <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="Artboard" transform="translate(-533.000000, -371.000000)" fill="currentColor">
              <g id="Group-2" transform="translate(391.846154, 353.000000)">
                <path
                  d="M147.231654,19.2554768 C147.32987,19.4137826 147.381912,19.5963773 147.381912,19.7826761 L147.381884,20.9995391 L210.153846,21 C210.706131,21 211.153846,21.4477153 211.153846,22 C211.153846,22.5522847 210.706131,23 210.153846,23 L147.381884,22.9995391 L147.381912,24.2173244 C147.381912,24.7696091 146.934197,25.2173244 146.381912,25.2173244 C146.195613,25.2173244 146.013018,25.1652826 145.854713,25.067066 L142.280822,22.8497419 C141.811523,22.5585777 141.667115,21.9421003 141.95828,21.4728009 C142.03951,21.3418741 142.149895,21.2314886 142.280822,21.1502586 L145.854713,18.9329344 C146.324012,18.6417703 146.940489,18.7861774 147.231654,19.2554768 Z M214.153846,21 C214.706131,21 215.153846,21.4477153 215.153846,22 C215.153846,22.5522847 214.706131,23 214.153846,23 C213.601561,23 213.153846,22.5522847 213.153846,22 C213.153846,21.4477153 213.601561,21 214.153846,21 Z"
                  id="Combined-Shape"
                ></path>
              </g>
            </g>
          </g>
        </svg>
      </div>
      <div class="sync-mode-image__connection-label">Sync</div>
      <div class="sync-mode-image__connection-bottom">
        <svg
          width="33px"
          height="10px"
          viewBox="0 0 33 10"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
        >
          <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="Artboard" transform="translate(-554.000000, -416.000000)" fill="currentColor">
              <g id="Group-2" transform="translate(391.846154, 353.000000)">
                <path
                  d="M169.35582,64.1725365 C169.454037,64.3308423 169.506079,64.5134371 169.506079,64.6997358 L169.505564,66.9995988 L189.946739,67 C190.499024,67 190.946739,67.4477153 190.946739,68 C190.946739,68.5522847 190.499024,69 189.946739,69 L169.505564,68.9995988 L169.506079,71.3002646 C169.506079,71.8525494 169.058363,72.3002646 168.506079,72.3002646 C168.31978,72.3002646 168.137185,72.2482229 167.978879,72.1500063 L162.659502,68.8497419 C162.190202,68.5585777 162.045795,67.9421003 162.336959,67.4728009 C162.418189,67.3418741 162.528575,67.2314886 162.659502,67.1502586 L167.978879,63.8499942 C168.448179,63.55883 169.064656,63.7032372 169.35582,64.1725365 Z M193.946739,67 C194.499024,67 194.946739,67.4477153 194.946739,68 C194.946739,68.5522847 194.499024,69 193.946739,69 C193.394455,69 192.946739,68.5522847 192.946739,68 C192.946739,67.4477153 193.394455,67 193.946739,67 Z"
                  id="Combined-Shape"
                  transform="translate(178.566652, 67.999932) scale(-1, 1) translate(-178.566652, -67.999932) "
                ></path>
              </g>
            </g>
          </g>
        </svg>
      </div>
    </div>
  </div>

  <div class="sync-mode-image__right">
    <div class="sync-mode-image__block">
      <div
        class="sync-mode-image__block-icon"
        [style.background-image]="'/assets/images/resources/icons/jet-database.svg' | appDeployCssUrl"
      ></div>
      <div class="sync-mode-image__block-title">Jet Tables</div>
    </div>
  </div>
</div>

import { BehaviorSubject } from 'rxjs';
var ListContextService = /** @class */ (function () {
    function ListContextService() {
        this._editingField = new BehaviorSubject(undefined);
    }
    Object.defineProperty(ListContextService.prototype, "editingField", {
        get: function () {
            return this._editingField.value;
        },
        set: function (value) {
            this._editingField.next(value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ListContextService.prototype, "editingField$", {
        get: function () {
            return this._editingField.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    return ListContextService;
}());
export { ListContextService };

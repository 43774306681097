var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ChangeDetectorRef, EventEmitter, InjectionToken, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, Observable, timer } from 'rxjs';
import { ApiService } from '@modules/api';
import { FieldType, ParameterField } from '@modules/fields';
import { CurrentProjectStore } from '@modules/projects';
import { QueryPagination } from '@modules/queries';
import { flattenTokens } from '@modules/tokens';
import { CurrentUserStore } from '@modules/users';
import { QueryBuilderContext } from '../../data/query-builder-context';
export var CONTEXT_TOKEN = new InjectionToken('QueryBuilderContext');
export var SEARCH_TOKEN = new InjectionToken('Observable<TokenSearchResult>');
export var InputTokensEventType;
(function (InputTokensEventType) {
    InputTokensEventType[InputTokensEventType["SetSorting"] = 0] = "SetSorting";
    InputTokensEventType[InputTokensEventType["SetPagination"] = 1] = "SetPagination";
    InputTokensEventType[InputTokensEventType["AddParameter"] = 2] = "AddParameter";
    InputTokensEventType[InputTokensEventType["Submit"] = 3] = "Submit";
})(InputTokensEventType || (InputTokensEventType = {}));
var InputTokensComponent = /** @class */ (function () {
    function InputTokensComponent(context, search$, currentUserStore, currentProjectStore, apiService, cd) {
        this.context = context;
        this.search$ = search$;
        this.currentUserStore = currentUserStore;
        this.currentProjectStore = currentProjectStore;
        this.apiService = apiService;
        this.cd = cd;
        this.inserted = new EventEmitter();
        this.event = new EventEmitter();
        this.searchSuggestAdd = false;
        this.selectedTokenValue = new FormControl('');
        this.choosePagination = false;
        this.specifyAddParameter = false;
        this.addParameterName = '';
        this.paginationOptions = [
            { value: QueryPagination.Page, name: 'Page pagination', icon: 'documents' },
            { value: QueryPagination.Offset, name: 'Offset pagination', icon: 'starts_with' },
            { value: QueryPagination.Cursor, name: 'Cursor pagination', icon: 'cursor' }
        ];
        this.queryPaginations = QueryPagination;
        this.mainTokens = [];
        this.mainTokensFiltered = [];
        this.otherTokens = [];
        this.otherTokensFiltered = [];
        this.paginationTokens = [];
        this.paginationTokensFiltered = [];
        this.sortingTokens = [];
        this.sortingTokensFiltered = [];
        this.tokenValues = {};
    }
    InputTokensComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.search$.pipe(untilDestroyed(this)).subscribe(function (search) {
            if (search && search.query) {
                _this.search = search.query;
                _this.searchSuggestAdd =
                    search.query && (!search.query.includes('.') || search.query.startsWith('params.')) && !search.exact;
                _this.updatedTokensFiltered(search.query, search.exact);
            }
            else {
                _this.search = undefined;
                _this.searchSuggestAdd = false;
                _this.updatedTokensFiltered();
            }
            _this.cd.markForCheck();
        });
        combineLatest(this.context.tokens$, this.context.systemTokens$)
            .pipe(untilDestroyed(this))
            .subscribe(function (_a) {
            var tokens = _a[0], systemTokens = _a[1];
            _this.mainTokens = tokens.filter(function (item) {
                return item.children && (item.children.length || (item.data && item.data['parameter_tokens']));
            });
            var otherTokens = [];
            var paginationTokens = [];
            var sortingTokens = [];
            systemTokens
                .filter(function (item) { return item.children && item.children.length; })
                .forEach(function (item) {
                if (item.label == 'Pagination') {
                    paginationTokens.push.apply(paginationTokens, item.children);
                }
                else if (item.label == 'Sorting') {
                    sortingTokens.push.apply(sortingTokens, item.children);
                }
                else {
                    otherTokens.push(item);
                }
            });
            _this.otherTokens = otherTokens;
            _this.paginationTokens = paginationTokens;
            _this.sortingTokens = sortingTokens;
            _this.cd.markForCheck();
            _this.updatedTokensFiltered();
            _this.tokenValues = _this.context.getTokenValues();
            _this.cd.markForCheck();
        });
        this.selectedTokenValue.valueChanges.pipe(untilDestroyed(this)).subscribe(function (value) { return _this.setTokenValue(value); });
    };
    InputTokensComponent.prototype.ngOnDestroy = function () { };
    InputTokensComponent.prototype.filterTokens = function (tokens, search) {
        var filterItems = function (items) {
            return items
                .map(function (item) {
                if (item.name && item.name.startsWith(search)) {
                    return item;
                }
                else if (item.children) {
                    var result = __assign({}, item, { children: filterItems(item.children) });
                    if (result.children.length) {
                        return result;
                    }
                }
            })
                .filter(function (item) { return item != undefined; });
        };
        return filterItems(tokens);
    };
    InputTokensComponent.prototype.updatedTokensFiltered = function (search, exact) {
        if (!search) {
            this.mainTokensFiltered = this.mainTokens;
            this.otherTokensFiltered = this.otherTokens;
            this.paginationTokensFiltered = this.paginationTokens;
            this.sortingTokensFiltered = this.sortingTokens;
            this.clearSelectedToken();
            this.cd.markForCheck();
            return;
        }
        this.mainTokensFiltered = this.filterTokens(this.mainTokens, search);
        this.otherTokensFiltered = this.filterTokens(this.otherTokens, search);
        this.paginationTokensFiltered = this.filterTokens(this.paginationTokens, search);
        this.sortingTokensFiltered = this.filterTokens(this.sortingTokens, search);
        var lists = this.mainTokensFiltered.concat(this.otherTokensFiltered);
        if (this.paginationTokensFiltered) {
            lists.push.apply(lists, this.paginationTokensFiltered);
        }
        if (this.context.form.controls.sorting.value) {
            lists.push.apply(lists, this.sortingTokensFiltered);
        }
        this.selectedToken = search ? flattenTokens(lists).find(function (item) { return item.name == search; }) : undefined;
        this.selectedTokenValue.patchValue(this.selectedToken ? this.context.getTokenValue(this.selectedToken) : '', {
            emitEvent: false
        });
        if (this.selectedToken && this.selectedToken.fixedValue) {
            this.selectedTokenValue.disable();
        }
        else {
            this.selectedTokenValue.enable();
        }
        if (exact && !this.selectedToken) {
            this.mainTokensFiltered = this.mainTokens;
            this.otherTokensFiltered = this.otherTokens;
            this.paginationTokensFiltered = this.paginationTokens;
            this.sortingTokensFiltered = this.sortingTokens;
        }
        this.selectedTokenValue.markAsPristine();
        this.cd.markForCheck();
    };
    InputTokensComponent.prototype.setSorting = function (sorting) {
        this.context.form.controls.sorting.patchValue(sorting);
        this.updatedTokensFiltered(this.search);
        this.event.emit({
            type: InputTokensEventType.SetSorting,
            data: {
                value: sorting
            }
        });
    };
    InputTokensComponent.prototype.setChoosePagination = function (value) {
        this.choosePagination = value;
        this.cd.markForCheck();
    };
    InputTokensComponent.prototype.setPagination = function (pagination) {
        this.context.form.setPagination(pagination);
        this.setChoosePagination(false);
        this.event.emit({
            type: InputTokensEventType.SetPagination,
            data: {
                pagination: pagination
            }
        });
    };
    InputTokensComponent.prototype.setSpecifyAddParameter = function (value) {
        this.specifyAddParameter = value;
        this.cd.markForCheck();
    };
    InputTokensComponent.prototype.clearSelectedToken = function () {
        this.selectedToken = undefined;
        this.selectedTokenValue.patchValue('', { emitEvent: false });
        this.cd.markForCheck();
    };
    InputTokensComponent.prototype.insert = function (item) {
        this.inserted.next(item);
    };
    InputTokensComponent.prototype.addParameter = function (name, value) {
        var _this = this;
        if (!this.context.parametersControl) {
            return;
        }
        var result = new ParameterField();
        result.name = name;
        result.verboseName = name;
        result.field = FieldType.Text;
        result.required = true;
        result.updateFieldDescription();
        var parameters = (this.context.parametersControl.value || []).concat([result]);
        this.context.parametersControl.patchValue(parameters);
        timer(0)
            .pipe(untilDestroyed(this))
            .subscribe(function () {
            var tokenName = ['params', name].join('.');
            var token = flattenTokens(_this.context.tokens).find(function (item) { return item.name == tokenName; });
            _this.setSpecifyAddParameter(false);
            _this.addParameterName = '';
            if (token) {
                _this.insert(token);
            }
            _this.selectedToken = token;
            _this.createdToken = token;
            _this.setTokenValue(value);
            _this.cd.markForCheck();
            _this.event.emit({
                type: InputTokensEventType.AddParameter
            });
        });
    };
    InputTokensComponent.prototype.addParameterFromInput = function () {
        if (!this.addParameterName) {
            return;
        }
        this.addParameter(this.addParameterName, this.selectedTokenValue.value);
    };
    InputTokensComponent.prototype.setAddParameterName = function (nameValue) {
        this.addParameterName = nameValue.replace(/[^\w]/g, '_').replace(/^\d/, '_');
    };
    InputTokensComponent.prototype.submitTokenValue = function () {
        this.event.emit({
            type: InputTokensEventType.Submit
        });
    };
    InputTokensComponent.prototype.setTokenValue = function (value) {
        if (!this.selectedToken) {
            return;
        }
        this.context.setTokenValue(this.selectedToken, value);
        this.selectedTokenValue.markAsPristine();
    };
    Object.defineProperty(InputTokensComponent.prototype, "currentPaginationOption", {
        get: function () {
            var _this = this;
            return this.paginationOptions.find(function (item) { return item.value == _this.context.form.controls.pagination.value; });
        },
        enumerable: true,
        configurable: true
    });
    return InputTokensComponent;
}());
export { InputTokensComponent };

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AggregateFunc, DatasetGroupLookup } from '@modules/charts';
import { ViewContext, ViewContextElement } from '@modules/customize';
import { ChartType } from '@modules/dashboard';
import { DataSourceType } from '@modules/data-sources';
import { HttpResultsSection, SqlResultsSection } from '@modules/queries-components';
import { controlValue, isSet } from '@shared';

import {
  ChartColumnOption,
  ChartWidgetDataSourceControl
} from '../model-description-data-source-edit/chart-widget-data-source';
import { ModelDescriptionDataSourceEditComponent } from '../model-description-data-source-edit/model-description-data-source-edit.component';

interface ControlLabel {
  label: string;
  additional?: string;
}

@Component({
  selector: 'app-chart-widget-data-source-edit',
  templateUrl: './chart-widget-data-source-edit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChartWidgetDataSourceEditComponent implements OnInit, OnDestroy {
  @Input() control: ChartWidgetDataSourceControl;
  @Input() datasetColumnEnabled = false;
  @Input() datasetColumnAllowed = false;
  @Input() datasetColumnControl: FormControl;
  @Input() chartType: ChartType;
  @Input() label: string;
  @Input() typeOrange = false;
  @Input() queryOptionEmptyLabel: string;
  @Input() search = false;
  @Input() context: ViewContext;
  @Input() contextElement: ViewContextElement;
  @Input() contextElementPath: (string | number)[];
  @Input() contextElementPaths: (string | number)[][];
  @Input() resourceClasses: string | string[];
  @Input() queryOptionClasses: string | string[];
  @Input() analyticsSource: string;

  @ViewChild(ModelDescriptionDataSourceEditComponent) dataSourceEditComponent: ModelDescriptionDataSourceEditComponent;

  controlLabels: {
    yColumn: ControlLabel;
    xColumn: ControlLabel;
    xColumn2?: ControlLabel;
  };
  columnOptions$: Observable<ChartColumnOption<string>[]>;
  xColumnLabel$: Observable<string>;
  x2ColumnLabel$: Observable<string>;
  yFuncLabel$: Observable<string>;
  datasetColumnLabel$: Observable<string>;
  dataSourceTypes = DataSourceType;
  datasetGroupLookup = DatasetGroupLookup;

  constructor(private cd: ChangeDetectorRef) {}

  ngOnInit() {
    this.updateControlLabels();

    this.columnOptions$ = this.control.getChartColumnOptions$();

    // TODO: Temp fix
    this.columnOptions$.pipe(untilDestroyed(this)).subscribe(() => this.cd.detectChanges());

    this.xColumnLabel$ = this.getXColumnLabel$();
    this.x2ColumnLabel$ = this.getX2ColumnLabel$();
    this.yFuncLabel$ = this.getYFuncLabel$();
    this.datasetColumnLabel$ = this.getDatasetColumnLabel$();
  }

  ngOnDestroy(): void {}

  updateControlLabels() {
    let yColumn: ControlLabel;
    let xColumn: ControlLabel;
    let xColumn2: ControlLabel;

    if (this.control.xColumn2ChartTypes.includes(this.chartType)) {
      yColumn = {
        label: 'show',
        additional: 'bubble size'
      };
      xColumn = {
        label: 'X axis'
      };
      xColumn2 = {
        label: 'Y axis'
      };
    } else if (this.control.axisChartTypes.includes(this.chartType)) {
      yColumn = {
        label: 'show',
        additional: 'Y axis'
      };
      xColumn = {
        label: 'group by',
        additional: 'X axis'
      };
    } else {
      yColumn = {
        label: 'show'
      };
      xColumn = {
        label: 'group by'
      };
    }

    this.controlLabels = {
      yColumn: yColumn,
      xColumn: xColumn,
      xColumn2: xColumn2
    };
    this.cd.markForCheck();
  }

  getXColumnLabel$(): Observable<string> {
    return combineLatest(
      controlValue<AggregateFunc>(this.control.controls.x_column),
      controlValue<string>(this.control.controls.x_column_lookup)
    ).pipe(
      map(([column, lookup]) => {
        const xColumnLookupOption = this.control.xColumnLookupAllOptions.find(item => item.value == lookup);

        if (isSet(column) && xColumnLookupOption) {
          return xColumnLookupOption.valueDisplay(column);
        }
      })
    );
  }

  getX2ColumnLabel$(): Observable<string> {
    return combineLatest(
      controlValue<AggregateFunc>(this.control.controls.x_column_2),
      controlValue<string>(this.control.controls.x_column_2_lookup)
    ).pipe(
      map(([column, lookup]) => {
        const xColumnLookupOption = this.control.xColumnLookupAllOptions.find(item => item.value == lookup);

        if (isSet(column) && xColumnLookupOption) {
          return xColumnLookupOption.valueDisplay(column);
        }
      })
    );
  }

  getYFuncLabel$(): Observable<string> {
    return combineLatest(
      controlValue<AggregateFunc>(this.control.controls.y_func),
      controlValue<string>(this.control.controls.y_column)
    ).pipe(
      map(([func, column]) => {
        const yFuncOption = this.control.yColumnFuncOptions.find(item => item.value == func);

        if (!isSet(func) && isSet(column)) {
          return `Pre-aggregated Field - ${column}`;
        } else if (yFuncOption) {
          return yFuncOption.valueDisplay(column);
        }
      })
    );
  }

  getDatasetColumnLabel$(): Observable<string> {
    if (!this.datasetColumnControl) {
      return;
    }

    return combineLatest(controlValue<string>(this.datasetColumnControl)).pipe(
      map(([column]) => {
        return column;
      })
    );
  }

  addQueryInput() {
    if (this.dataSourceEditComponent) {
      this.dataSourceEditComponent.editQuery({
        initialHttpResultsSection: HttpResultsSection.Parameters,
        initialSqlResultsSection: SqlResultsSection.Parameters
      });
    }
  }
}

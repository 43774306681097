import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import fromPairs from 'lodash/fromPairs';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { ActionService } from '@modules/action-queries';
import {
  CarouselSettings,
  getModelAttributesByColumns,
  GridSettings,
  ViewContext,
  ViewContextElement
} from '@modules/customize';
import { applyParamInput$, DisplayField, ParameterField } from '@modules/fields';
import { ITEM_OUTPUT, ListItem } from '@modules/list';
import { Model, ModelDescription } from '@modules/models';
import { View } from '@modules/views';
import { EMPTY, isSet, TypedChanges } from '@shared';

@Component({
  selector: 'app-grid-item',
  templateUrl: './grid-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GridItemComponent implements OnInit, OnDestroy, OnChanges {
  @Input() item: ListItem;
  @Input() modelDescription: ModelDescription;
  @Input() settings: GridSettings | CarouselSettings;
  @Input() visibleColumns: DisplayField[] = [];
  @Input() rowCards = 1;
  @Input() view: View;
  @Input() context: ViewContext;
  @Input() contextElement: ViewContextElement;
  @Input() containerWidth: number;
  @Input() accentColor: string;
  @Input() snap = false;
  @Input() snapMargin = false;
  @Output() select = new EventEmitter<{ item: ListItem; element: HTMLElement }>();
  @Output() modelUpdated = new EventEmitter<Model>();

  @Input() @HostBinding('class.grid__item_clickable') clickable = true;
  @Input() @HostBinding('class.grid__item_selected') selected = false;
  @HostBinding('class.grid__item') class = true;
  @HostBinding('class.grid__item_snap') get snapCls() {
    return this.snap;
  }
  @HostBinding('class.grid__item_snap-margin') get snapMarginCls() {
    return this.snapMargin;
  }
  @HostBinding('attr.data-card-width') get dataGridCards() {
    return isSet(this.rowCards) ? true : null;
  }
  @HostBinding('style.min-width.px') get minWidthPx() {
    return this.view && this.view.widthResize.enabled && isSet(this.view.widthResize.min)
      ? this.view.widthResize.min
      : null;
  }
  @HostBinding('style.max-width.px') get maxWidthPx() {
    return this.view && this.view.widthResize.enabled && isSet(this.view.widthResize.max)
      ? this.view.widthResize.max
      : null;
  }
  @HostBinding('style.min-height.px') get minHeightPx() {
    return this.view && this.view.heightResize.enabled && isSet(this.view.heightResize.min)
      ? this.view.heightResize.min
      : null;
  }
  @HostBinding('style.max-height.px') get maxHeightPx() {
    return this.view && this.view.heightResize.enabled && isSet(this.view.heightResize.max)
      ? this.view.heightResize.max
      : null;
  }
  @HostBinding('style.padding-left.px') get paddingLeftPx() {
    return isSet(this.settings.gapHorizontal) ? this.settings.gapHorizontal * 0.5 : null;
  }
  @HostBinding('style.padding-right.px') get paddingRightPx() {
    return isSet(this.settings.gapHorizontal) ? this.settings.gapHorizontal * 0.5 : null;
  }
  @HostBinding('style.padding-top.px') get paddingTopPx() {
    if (this.settings instanceof GridSettings) {
      return isSet(this.settings.gapVertical) ? this.settings.gapVertical * 0.5 : null;
    } else {
      return null;
    }
  }
  @HostBinding('style.padding-bottom.px') get paddingBottomPx() {
    if (this.settings instanceof GridSettings) {
      return isSet(this.settings.gapVertical) ? this.settings.gapVertical * 0.5 : null;
    } else {
      return null;
    }
  }

  settings$ = new BehaviorSubject<GridSettings | CarouselSettings>(undefined);
  viewParameters: ParameterField[] = [];
  viewParams$: Observable<Object> = of({});
  localContext: Object = {};

  constructor(
    public el: ElementRef<HTMLElement>,
    private actionService: ActionService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {}

  ngOnDestroy(): void {}

  ngOnChanges(changes: TypedChanges<GridItemComponent>): void {
    if (changes.item) {
      // this.contextElement.context.model = this.item.model;
      this.localContext = {
        [ITEM_OUTPUT]: this.item.model.getAttributes()
      };
    }

    if (changes.settings) {
      this.settings$.next(this.settings);
    }

    if (changes.settings || changes.view) {
      this.updateViewParameters();
    }

    if (changes.settings || changes.item || changes.view) {
      this.updateViewParams();
    }

    if (changes.rowCards) {
      if (this.rowCards) {
        this.el.nativeElement.style.setProperty('--card-width', `${100 / this.rowCards}%`);
      } else {
        this.el.nativeElement.style.removeProperty('--card-width');
      }
    }
  }

  @HostListener('click', ['$event']) onClick(e: MouseEvent) {
    this.contextElement.context.clickEvent = e;
    this.select.emit({ item: this.item, element: this.el.nativeElement });
  }

  updateViewParameters() {
    if (!this.view) {
      this.viewParameters = [];
      this.cd.markForCheck();
      return;
    }

    const columns = this.settings.dataSource ? this.settings.dataSource.columns : [];

    this.viewParameters = columns.map(item => {
      const parameter = new ParameterField();

      parameter.name = item.name;
      parameter.verboseName = item.verboseName;
      parameter.field = item.field;
      parameter.params = item.params;

      return parameter;
    });
    this.cd.markForCheck();
  }

  updateViewParams() {
    if (!this.view) {
      this.viewParams$ = of({});
      this.cd.markForCheck();
      return;
    }

    const columns = this.settings.dataSource ? this.settings.dataSource.columns : [];
    const modelParams = getModelAttributesByColumns(this.item.model, columns);

    this.viewParams$ = combineLatest(
      this.settings.cardCustomViewMappings.map(mapping => {
        if (mapping.sourceParameterInput) {
          return applyParamInput$(mapping.sourceParameterInput, {
            context: this.context,
            contextElement: this.contextElement,
            localContext: {
              [ITEM_OUTPUT]: modelParams
            }
          }).pipe(
            map(value => {
              return {
                name: mapping.targetParameter,
                value: value !== EMPTY ? value : undefined
              };
            })
          );
        } else {
          const value = modelParams[mapping.sourceParameter];
          return of({
            name: mapping.targetParameter,
            value: value
          });
        }
      })
    ).pipe(
      map(values => {
        return fromPairs(values.map(item => [item.name, item.value]));
      })
    );
    this.cd.markForCheck();
  }
}

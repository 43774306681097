import { DatasetGroupLookup } from '@modules/charts';

export const scatterSampleData = [
  {
    name: undefined,
    color: 'gray',
    format: undefined,
    groupLookup: DatasetGroupLookup.Auto,
    dataset: [
      { group: 10, value: 10 },
      { group: 15, value: 30 },
      { group: 15, value: 40 },
      { group: 18, value: 20 },
      { group: 18, value: 30 },
      { group: 20, value: 40 },
      { group: 20, value: 35 },
      { group: 20, value: 30 },
      { group: 45, value: 40 },
      { group: 50, value: 40 },
      { group: 50, value: 30 },
      { group: 60, value: 60 },
      { group: 60, value: 50 },
      { group: 80, value: 60 },
      { group: 80, value: 80 },
      { group: 90, value: 80 },
      { group: 90, value: 60 }
    ]
  }
];

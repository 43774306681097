export class CornerRadius {
  topLeft = 0;
  topRight = 0;
  bottomRight = 0;
  bottomLeft = 0;

  constructor(options: Partial<CornerRadius> = {}) {
    Object.assign(this, options);
  }

  deserialize(data: Object): this {
    this.topLeft = data['top_left'];
    this.topRight = data['top_right'];
    this.bottomRight = data['bottom_right'];
    this.bottomLeft = data['bottom_left'];

    return this;
  }

  serialize(): Object {
    return {
      top_left: this.topLeft,
      top_right: this.topRight,
      bottom_right: this.bottomRight,
      bottom_left: this.bottomLeft
    };
  }

  setAll(value: number) {
    this.topLeft = value;
    this.topRight = value;
    this.bottomRight = value;
    this.bottomLeft = value;
  }

  cssBorderRadius(add = 0): string {
    return [this.topLeft, this.topRight, this.bottomRight, this.bottomLeft]
      .map(item => (item != 0 ? item + add : 0))
      .map(item => `${item}px`)
      .join(' ');
  }
}

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import isEqual from 'lodash/isEqual';
import toPairs from 'lodash/toPairs';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import {
  ContextItem,
  ElementItem,
  ElementType,
  FormElementItem,
  getTokenSubtitle,
  ITEM_OUTPUT,
  ListElementItem,
  ModelElementItem,
  RawListViewSettingsColumn,
  ViewContext,
  ViewContextElement,
  ViewContextElementType
} from '@modules/customize';
import { DataSourceType, ModelDescriptionDataSource } from '@modules/data-sources';
import {
  DisplayFieldType,
  FieldType,
  getFieldDescriptionByType,
  Input as FieldInput,
  InputValueType
} from '@modules/fields';
import { SELECTED_ITEM_OUTPUT } from '@modules/list';
import { ModelDescriptionStore } from '@modules/model-queries';
import { forceModelId, ModelDescription } from '@modules/models';
import { isSet, TypedChanges } from '@shared';

interface ComponentItemSource {
  name: string;
  icon?: string;
  selfParameter?: string;
}

interface ComponentItemTarget {
  name: string;
  icon?: string;
  targetContextValue?: string[];
}

interface ComponentItem {
  name: string;
  contextElement: ViewContextElement;
  icon?: string;
  selfParameter?: string;
  selfParameters?: ComponentItemSource[];
  targetContextValue?: string[];
  targetContextValues?: ComponentItemTarget[];
}

interface ComponentItemSection {
  name: string;
  children: ComponentItem[];
}

@Component({
  selector: 'app-bind-component',
  templateUrl: './bind-component.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BindComponentComponent implements OnInit, OnDestroy, OnChanges {
  @Input() inputs: FieldInput[] = [];
  @Input() selfModelDescription: ModelDescription;
  @Input() selfColumns: RawListViewSettingsColumn[] = [];
  @Input() context: ViewContext;
  @Input() element: ElementItem;
  @Input() targetBindField = true;
  @Input() targetBindPrimaryKey = true;
  @Input() targetElementType: ElementType;
  @Input() selfBindPrimaryKey = true;
  @Input() selfBindField: string;
  @Input() inline = false;
  @Input() analyticsSource: string;
  @Output() updateInputs = new EventEmitter<FieldInput[]>();

  inputs$ = new BehaviorSubject<FieldInput[]>([]);
  selfModelDescription$ = new BehaviorSubject<ModelDescription>(undefined);
  selfColumns$ = new BehaviorSubject<RawListViewSettingsColumn[]>([]);

  opened = false;
  hasInputs = false;
  sections: ComponentItemSection[] = [];
  items$ = new BehaviorSubject<ComponentItem[]>([]);
  boundItem: ComponentItem;
  selectedItem: ComponentItem;
  dataSourceTypes = DataSourceType;

  constructor(
    private modelDescriptionStore: ModelDescriptionStore,
    private analyticsService: UniversalAnalyticsService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.initItems();

    this.items$.pipe(untilDestroyed(this)).subscribe(items => {
      this.sections = this.groupItems(items);
      this.cd.markForCheck();
    });

    combineLatest(this.items$, this.inputs$)
      .pipe(untilDestroyed(this))
      .subscribe(([items, queryInputs]) => {
        this.hasInputs = queryInputs.length > 0;
        this.boundItem = items.find((componentItem: ComponentItem) => {
          const selfParameters = componentItem.selfParameter
            ? [componentItem.selfParameter]
            : componentItem.selfParameters.map(i => i.name);

          return selfParameters
            .map(selfParameter => {
              return isSet(selfParameter) ? queryInputs.find(i => i.name == selfParameter) : undefined;
            })
            .filter(queryInput => queryInput && queryInput.valueType == InputValueType.Context)
            .some(queryInput => {
              if (componentItem.targetContextValue) {
                return isEqual(componentItem.targetContextValue, queryInput.contextValue);
              } else if (componentItem.targetContextValues) {
                return componentItem.targetContextValues.some(item => {
                  return isEqual(item.targetContextValue, queryInput.contextValue);
                });
              } else {
                return false;
              }
            });
        });
        this.cd.markForCheck();
      });
  }

  ngOnDestroy(): void {}

  ngOnChanges(changes: TypedChanges<BindComponentComponent>): void {
    if (changes.inputs) {
      this.inputs$.next(this.inputs);
    }

    if (changes.selfModelDescription) {
      this.selfModelDescription$.next(this.selfModelDescription);
    }

    if (changes.selfColumns) {
      this.selfColumns$.next(this.selfColumns);
    }
  }

  initItems() {
    combineLatest(
      this.context.elements$,
      this.selfModelDescription$,
      this.selfColumns$,
      this.modelDescriptionStore.get()
    )
      .pipe(
        map(([elements, selfModelDescription, selfColumns, modelDescriptions]) => {
          const mapElement = (contextItem: ContextItem): ComponentItem => {
            let selfParameter: string;
            let selfParameters: ComponentItemSource[] = [];
            let targetDataSource: ModelDescriptionDataSource;
            let targetElementPath: string[];
            let targetGetContextValue: (field: string) => string[];
            let targetContextValue: string[];
            let targetContextValues: ComponentItemTarget[] = [];

            if (contextItem.element.element instanceof ListElementItem) {
              const layoutIndex = 0;
              const settings = contextItem.element.element.layouts[layoutIndex];

              if (settings) {
                targetDataSource = settings.dataSource;
                targetElementPath = ['elements', contextItem.element.uniqueName, layoutIndex.toString()];
                targetGetContextValue = field => [...targetElementPath, SELECTED_ITEM_OUTPUT, field];
              }
            } else if (contextItem.element.element instanceof FormElementItem) {
              targetDataSource = contextItem.element.element.getDataSource;
              targetElementPath = ['elements', contextItem.element.uniqueName];
              targetGetContextValue = field => [...targetElementPath, ITEM_OUTPUT, field];
            } else if (contextItem.element.element instanceof ModelElementItem) {
              targetDataSource = contextItem.element.element.dataSource;
              targetElementPath = ['elements', contextItem.element.uniqueName];
              targetGetContextValue = field => [...targetElementPath, ITEM_OUTPUT, field];
            }

            if (!this.targetBindField) {
              selfParameter = 'value';
              targetContextValue = targetElementPath;
            } else if (targetDataSource && targetDataSource.query && targetDataSource.query.isConfigured()) {
              const targetResource = targetDataSource.queryResource;
              const targetModel =
                targetDataSource.query && targetDataSource.query.simpleQuery
                  ? targetDataSource.query.simpleQuery.model
                  : undefined;
              const targetColumns = targetDataSource.columns || [];
              const targetModelId =
                isSet(targetResource) && isSet(targetModel) ? [targetResource, targetModel].join('.') : undefined;
              const targetModelDescription = isSet(targetModelId)
                ? modelDescriptions.find(item => item.isSame(targetModelId))
                : undefined;

              if (
                selfModelDescription &&
                targetModelDescription &&
                targetModelDescription.isSame(selfModelDescription)
              ) {
                selfParameter = selfModelDescription.primaryKeyField;
                targetContextValue = targetGetContextValue(targetModelDescription.primaryKeyField);
              } else {
                if (this.selfBindField) {
                  selfParameter = this.selfBindField;
                } else if (this.selfBindPrimaryKey && selfModelDescription && isSet(selfModelDescription)) {
                  selfParameter = selfModelDescription.primaryKeyField;
                } else {
                  const relatedField = isSet(targetModelId)
                    ? selfColumns
                        .filter(item => !item.flex)
                        .filter(item => item.field == FieldType.RelatedModel)
                        .find(item => {
                          const modelId =
                            item.params['related_model'] && selfModelDescription
                              ? forceModelId(item.params['related_model']['model'], selfModelDescription.resource)
                              : undefined;
                          return modelId == targetModelId;
                        })
                    : undefined;

                  if (relatedField) {
                    selfParameter = relatedField.name;
                  } else {
                    selfParameters = selfColumns
                      .filter(item => !item.flex)
                      .map<ComponentItemSource>(item => {
                        const fieldDescription = getFieldDescriptionByType(item.field);
                        return {
                          name: item.verboseName || item.name,
                          icon: fieldDescription ? fieldDescription.icon : undefined,
                          selfParameter: item.name
                        };
                      });
                  }
                }

                if (
                  this.targetBindPrimaryKey &&
                  targetModelDescription &&
                  isSet(targetModelDescription.primaryKeyField)
                ) {
                  targetContextValue = targetGetContextValue(targetModelDescription.primaryKeyField);
                } else {
                  const relatedField = selfModelDescription
                    ? targetColumns
                        .filter(item => item.type != DisplayFieldType.Computed)
                        .filter(item => item.field == FieldType.RelatedModel)
                        .find(item => {
                          const modelId = item.params['related_model']
                            ? forceModelId(item.params['related_model']['model'], targetResource)
                            : undefined;
                          return modelId == selfModelDescription.modelId;
                        })
                    : undefined;

                  if (relatedField) {
                    targetContextValue = targetGetContextValue(relatedField.name);
                  } else {
                    targetContextValues = targetColumns
                      .filter(item => item.type != DisplayFieldType.Computed)
                      .map(item => {
                        const fieldDescription = getFieldDescriptionByType(item.field);
                        return {
                          name: item.verboseName || item.name,
                          icon: fieldDescription ? fieldDescription.icon : undefined,
                          targetContextValue: targetGetContextValue(item.name)
                        };
                      });
                  }
                }
              }
            }

            if (
              !isSet(selfParameter) &&
              !selfParameters.length &&
              !isSet(targetContextValue) &&
              !targetContextValues.length
            ) {
              return;
            }

            return {
              name: contextItem.element.name,
              contextElement: contextItem.element,
              icon: contextItem.element.icon,
              selfParameter: selfParameter,
              selfParameters: selfParameters,
              targetContextValue: targetContextValue,
              targetContextValues: targetContextValues
            };
          };

          return elements
            .filter(item => item.element.type == ViewContextElementType.Element && !item.parent)
            .filter(item => !this.element || item.element.uniqueName != this.element.uid)
            .filter(item => {
              if (this.targetElementType) {
                return item.element.element && item.element.element.type == this.targetElementType;
              } else {
                return true;
              }
            })
            .map(item => mapElement(item))
            .filter(item => item);
        }),
        untilDestroyed(this)
      )
      .subscribe(result => {
        this.items$.next(result);
        this.cd.markForCheck();
      });
  }

  groupItems(items: ComponentItem[]): ComponentItemSection[] {
    return toPairs<ComponentItem[]>(
      items.reduce((acc, item) => {
        const key = getTokenSubtitle(item.contextElement) || '';
        if (!acc[key]) {
          acc[key] = [];
        }
        acc[key].push(item);
        return acc;
      }, {})
    ).map(([name, children]) => {
      return {
        name: name,
        children: children
      };
    });
  }

  bindComponent(selfParameter: string, targetContextValue: string[], componentItem?: ComponentItem) {
    const input = new FieldInput();

    input.name = selfParameter;
    input.valueType = InputValueType.Context;
    input.contextValue = targetContextValue;

    const newValue = [input, ...this.inputs.filter(item => item.name != selfParameter)];
    this.updateInputs.emit(newValue);

    if (componentItem) {
      const element = componentItem.contextElement.element;

      this.analyticsService.sendSimpleEvent(AnalyticsEvent.Component.BindSuccessfullySetUp, {
        WasBound: !!this.boundItem,
        ComponentType: element ? element.analyticsGenericName : undefined,
        Source: this.analyticsSource
      });
    }
  }

  setOpened(value: boolean) {
    this.opened = value;
    this.cd.markForCheck();
  }

  setSelectedItem(value: ComponentItem) {
    this.selectedItem = value;
    this.cd.markForCheck();

    if (value) {
      const element = value.contextElement.element;

      this.analyticsService.sendSimpleEvent(AnalyticsEvent.Component.BindChooseField, {
        WasBound: !!this.boundItem,
        ComponentType: element ? element.analyticsGenericName : undefined,
        Source: this.analyticsSource
      });
    }
  }

  toggleOpened() {
    this.setOpened(!this.opened);
  }

  onOpen() {
    this.analyticsService.sendSimpleEvent(AnalyticsEvent.Component.BindStarted, {
      WasBound: !!this.boundItem,
      Source: this.analyticsSource
    });
  }

  onCancel() {
    this.analyticsService.sendSimpleEvent(AnalyticsEvent.Component.BindCancelled, {
      WasBound: !!this.boundItem,
      Source: this.analyticsSource
    });
  }
}

import isArray from 'lodash/isArray';

import { isEmptyArray, isSet } from '@shared';

import {
  containsFieldLookup,
  endsWithFieldLookup,
  exactFieldLookup,
  inFieldLookup,
  isEmptyFieldLookup,
  isNullFieldLookup,
  startsWithFieldLookup
} from '@modules/field-lookups';

import { FieldType } from '../field-type';

export function parseInLookupStringValue(value: string): string[] {
  value = String(value).trim();
  if (value === '') {
    return [];
  }
  return value.split(',').map(item => item.trim());
}

export const textLookupMatchers = [
  {
    field: FieldType.Text,
    lookup: containsFieldLookup,
    match: (fieldValue: string, lookupValue: string): boolean => {
      if (!fieldValue || !lookupValue) {
        return false;
      }
      return String(fieldValue).toLowerCase().includes(String(lookupValue).toLowerCase());
    }
  },
  {
    field: FieldType.Text,
    lookup: exactFieldLookup,
    match: (fieldValue: string, lookupValue: string): boolean => {
      return fieldValue == lookupValue;
    }
  },
  {
    field: FieldType.Text,
    lookup: startsWithFieldLookup,
    match: (fieldValue: string, lookupValue: string): boolean => {
      if (!fieldValue || !lookupValue) {
        return false;
      }
      return String(fieldValue).startsWith(lookupValue);
    }
  },
  {
    field: FieldType.Text,
    lookup: endsWithFieldLookup,
    match: (fieldValue: string, lookupValue: string): boolean => {
      if (!fieldValue || !lookupValue) {
        return false;
      }
      return String(fieldValue).endsWith(lookupValue);
    }
  },
  {
    field: FieldType.Text,
    lookup: isEmptyFieldLookup,
    match: (fieldValue: string, lookupValue: boolean): boolean => {
      return !!lookupValue
        ? !isSet(fieldValue) || isEmptyArray(fieldValue)
        : isSet(fieldValue) && !isEmptyArray(fieldValue);
    }
  },
  {
    field: FieldType.Text,
    lookup: isNullFieldLookup,
    match: (fieldValue: string, lookupValue: boolean): boolean => {
      return !!lookupValue ? fieldValue === null : fieldValue !== null;
    }
  },
  {
    field: FieldType.Text,
    lookup: inFieldLookup,
    match: (fieldValue: string, lookupValue: string | string[]): boolean => {
      if (!lookupValue || !lookupValue) {
        return false;
      }

      if (typeof lookupValue == 'string') {
        lookupValue = parseInLookupStringValue(lookupValue);
      } else if (!isArray(lookupValue)) {
        lookupValue = [lookupValue];
      }

      return lookupValue.some(item => fieldValue == item);
    }
  }
];

import { ChangeDetectionStrategy, Component, ElementRef, Input, OnInit } from '@angular/core';

import { mathjs } from '@modules/fields';
import { cleanWrapSpaces } from '@shared';

import { renderFormulaElementDescriptors, renderFormulaNode } from '../../utils/formula';

@Component({
  selector: 'app-view-context-token-popover-function-formula',
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ViewContextTokenPopoverFunctionFormulaComponent implements OnInit {
  @Input() formula: string;

  constructor(private el: ElementRef) {}

  ngOnInit() {
    try {
      const rootNode = this.parseFormula(this.formula);
      const element = rootNode ? renderFormulaNode(rootNode) : undefined;
      renderFormulaElementDescriptors(this.el.nativeElement, element ? [element] : []);
    } catch (e) {
      console.error(e);
    }
  }

  parseFormula(formulaValue: string) {
    return mathjs.parse(cleanWrapSpaces(formulaValue)).transform(node => {
      // if (node['isIndexNode']) {
      //   const dimension = node.dimensions[0];
      //
      //   if (!node.isObjectProperty() && dimension && typeof dimension.value == 'number') {
      //     dimension.value = dimension.value + 1;
      //   }
      // }
      return node;
    });
  }
}

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';

import { ThemeService } from '@modules/theme';
import { deployUrl, TypedChanges } from '@shared';

@Component({
  selector: 'app-sidebar-image-card',
  templateUrl: './sidebar-image-card.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SidebarImageCardComponent implements OnInit, OnDestroy, OnChanges {
  @Input() title: string;
  @Input() image: string;
  @Input() icon: string;
  @Input() indicatorImage: string;
  @Input() movable = true;
  @Input() hoverable = true;
  @Input() purpleGradient = false;
  @Input() shadow = false;
  @Input() beta = false;

  imagePath: string;

  constructor(private themeService: ThemeService, private cd: ChangeDetectorRef) {}

  ngOnInit() {
    this.themeService.theme$.pipe(untilDestroyed(this)).subscribe(() => this.updateImagePath());
  }

  ngOnChanges(changes: TypedChanges<SidebarImageCardComponent>): void {
    if (changes.image || changes.purpleGradient) {
      this.updateImagePath();
    }
  }

  updateImagePath() {
    this.imagePath = this.getImagePath();
    this.cd.markForCheck();
  }

  getImagePath(): string {
    if (!this.image) {
      return;
    }

    if (this.themeService.theme == 'dark') {
      return `url(${deployUrl('/assets/images/widgets/' + this.image + '_dark.svg')})`;
    } else {
      if (this.purpleGradient) {
        return `url(${deployUrl('/assets/images/widgets/' + this.image + '_contrast.svg')})`;
      } else {
        return `url(${deployUrl('/assets/images/widgets/' + this.image + '.svg')})`;
      }
    }
  }

  ngOnDestroy(): void {}
}

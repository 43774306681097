import isFunction from 'lodash/isFunction';
import keys from 'lodash/keys';

import { isSet } from '../common/common';
import { objectGet } from '../object/object';

export function defaultComparator(lhs, rhs) {
  if (lhs < rhs) {
    return -1;
  } else if (lhs > rhs) {
    return 1;
  } else {
    return 0;
  }
}

export function ascComparator(lhs: any, rhs: any): number {
  if (lhs < rhs) {
    return -1;
  } else if (lhs > rhs) {
    return 1;
  } else {
    return 0;
  }
}

const typesOrder = ['number', 'bigint', 'string', 'symbol', 'object', 'boolean', 'function', 'undefined'];

export function objectsSortPredicate(...sortBy: (string | ((item1: any, item2: any) => number))[]) {
  return (lhs: any, rhs: any): number => {
    for (const sorting of sortBy) {
      if (typeof sorting == 'string') {
        const [path, asc] = sorting.startsWith('-') ? [sorting.slice(1), false] : [sorting, true];
        let lhsValue = objectGet(lhs, path, null);
        let rhsValue = objectGet(rhs, path, null);

        if (lhsValue === undefined) {
          lhsValue = null;
        }

        if (rhsValue === undefined) {
          rhsValue = null;
        }

        if (lhsValue !== null && rhsValue === null) {
          return asc ? -1 : 1;
        } else if (lhsValue === null && rhsValue !== null) {
          return asc ? 1 : -1;
        } else if (lhsValue === null && rhsValue === null) {
          continue;
        }

        const typesIndexDiff = typesOrder.indexOf(typeof lhsValue) - typesOrder.indexOf(typeof rhsValue);
        const typesSort = asc ? typesIndexDiff : typesIndexDiff * -1;
        if (typesSort != 0) {
          return typesSort;
        }

        if (lhsValue < rhsValue) {
          return asc ? -1 : 1;
        } else if (lhsValue > rhsValue) {
          return asc ? 1 : -1;
        }
      } else if (isFunction(sorting)) {
        const result = sorting(lhs, rhs);
        if (result != 0) {
          return result;
        }
      }
    }

    return 0;
  };
}

export function objectsListSort(list: any[], property: (item: any) => any) {
  return (lhs: any, rhs: any): number => {
    const lhsIndex = list.indexOf(property(lhs));
    const rhsIndex = list.indexOf(property(rhs));
    return lhsIndex < rhsIndex ? -1 : lhsIndex == rhsIndex ? 0 : 1;
  };
}

export function sortUsingAfterItem<T>(options: {
  items: T[];
  getAfterItem: (item: T) => string;
  getItemId: (item: T) => string;
  defaultSort?: (lhs: T, rhs: T) => number;
}) {
  if (options.defaultSort) {
    options.items = options.items.sort(options.defaultSort);
  }

  const itemsByAfterItem = options.items.reduce<{ [k: string]: T[] }>((acc, item) => {
    const afterItem = options.getAfterItem(item);
    const key = isSet(afterItem) ? afterItem : '';

    if (!acc[key]) {
      acc[key] = [];
    }

    acc[key].push(item);

    return acc;
  }, {});

  const result = [];

  const appendItemsAfter = (afterItem: string) => {
    if (!itemsByAfterItem[afterItem]) {
      return;
    }

    result.push(...itemsByAfterItem[afterItem]);

    const itemsNested = itemsByAfterItem[afterItem];
    delete itemsByAfterItem[afterItem];

    itemsNested.forEach(item => {
      const itemId = options.getItemId(item);
      appendItemsAfter(itemId);
    });
  };

  const findMostBeforeItem = (afterItemId: string, acc: { [k: string]: boolean } = {}): string => {
    // Found cyclic afterItemId
    if (acc.hasOwnProperty(afterItemId)) {
      return afterItemId;
    }

    const beforeItem = options.items.find(item => options.getItemId(item) == afterItemId);
    const beforeItemAfterItem = beforeItem ? options.getAfterItem(beforeItem) : undefined;

    acc[afterItemId] = true;

    if (isSet(beforeItemAfterItem)) {
      return findMostBeforeItem(beforeItemAfterItem, acc);
    } else {
      return afterItemId;
    }
  };

  while (keys(itemsByAfterItem).length) {
    if (itemsByAfterItem['']) {
      appendItemsAfter('');
    } else {
      const firstKey =
        keys(itemsByAfterItem).find(key => !!options.items.find(item => options.getItemId(item) == key)) ||
        keys(itemsByAfterItem)[0];
      const parentKey = findMostBeforeItem(firstKey);
      appendItemsAfter(parentKey);
    }
  }

  return result;
}

import { OnInit } from '@angular/core';
import { PageBlockDefaultsDirective } from '../../directives/page-block-defaults/page-block-defaults.directive';
var PageBlockComponent = /** @class */ (function () {
    function PageBlockComponent(defaultsDirective) {
        this.defaultsDirective = defaultsDirective;
        this.cls = true;
        this.title = false;
        this.fill = false;
        this.first = false;
        this.last = false;
        this.defaultMarginSize = 'm';
        this.defaultPaddingSize = 'm';
    }
    Object.defineProperty(PageBlockComponent.prototype, "sizeM", {
        get: function () {
            return this.size == 'm';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PageBlockComponent.prototype, "marginSizeXS", {
        get: function () {
            return this.marginSize == 'xs';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PageBlockComponent.prototype, "marginSizeS", {
        get: function () {
            return this.marginSize == 's';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PageBlockComponent.prototype, "marginSizeM", {
        get: function () {
            return this.marginSize == 'm';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PageBlockComponent.prototype, "marginSizeL", {
        get: function () {
            return this.marginSize == 'l';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PageBlockComponent.prototype, "paddingSizeNone", {
        get: function () {
            return this.paddingSize == 'none';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PageBlockComponent.prototype, "paddingSizeS", {
        get: function () {
            return this.paddingSize == 's';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PageBlockComponent.prototype, "paddingSizeM", {
        get: function () {
            return this.paddingSize == 'm';
        },
        enumerable: true,
        configurable: true
    });
    PageBlockComponent.prototype.ngOnInit = function () {
        var defaultsDirective = this.defaultsDirective
            ? this.defaultsDirective.appPageBlockDefaults
            : {};
        this.size = this.size || defaultsDirective.size;
        this.marginSize = this.marginSize || defaultsDirective.marginSize || this.defaultMarginSize;
        this.paddingSize = this.paddingSize || defaultsDirective.paddingSize || this.defaultPaddingSize;
    };
    return PageBlockComponent;
}());
export { PageBlockComponent };

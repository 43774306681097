import values from 'lodash/values';
import { QueryService } from '../query/query.service';
import * as i0 from "@angular/core";
import * as i1 from "../query/query.service";
var SqlQueryService = /** @class */ (function () {
    function SqlQueryService(queryService) {
        this.queryService = queryService;
    }
    SqlQueryService.prototype.applyTokensSql = function (query, tokens, raiseErrors) {
        var _this = this;
        if (tokens === void 0) { tokens = {}; }
        if (raiseErrors === void 0) { raiseErrors = false; }
        if (!query) {
            return;
        }
        var params = {};
        var paramsList = [];
        // Remove comments
        query = query.replace(/^(\s*)?--.*/gm, '');
        // Interpolate
        var resultQuery = query.replace(/\{\{([^\}]+)\}\}/g, function (match) {
            var value = _this.queryService.applyTokens(match, tokens, raiseErrors);
            if (!params.hasOwnProperty(match)) {
                var name_1 = "param" + values(params).length;
                params[match] = { name: name_1, value: value };
                paramsList.push(value);
            }
            return ":" + params[match].name;
        });
        // Evaluate logic
        resultQuery = this.queryService.applyTokens(resultQuery, tokens, raiseErrors, true);
        // Params to Object
        var resultParams = values(params).reduce(function (acc, item) {
            acc[item.name] = item.value;
            return acc;
        }, {});
        return {
            query: resultQuery,
            params: resultParams,
            paramsList: paramsList
        };
    };
    SqlQueryService.ngInjectableDef = i0.defineInjectable({ factory: function SqlQueryService_Factory() { return new SqlQueryService(i0.inject(i1.QueryService)); }, token: SqlQueryService, providedIn: "root" });
    return SqlQueryService;
}());
export { SqlQueryService };

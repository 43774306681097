var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import range from 'lodash/range';
var SortingFieldsControl = /** @class */ (function (_super) {
    __extends(SortingFieldsControl, _super);
    function SortingFieldsControl(_a) {
        var formState = _a.formState, validatorOrOpts = _a.validatorOrOpts, asyncValidator = _a.asyncValidator;
        var _this = _super.call(this, [], validatorOrOpts, asyncValidator) || this;
        _this.setItems(formState, { emitEvent: false });
        return _this;
    }
    SortingFieldsControl.prototype.createItem = function (defaults) {
        if (defaults === void 0) { defaults = {}; }
        return new FormGroup({
            name: new FormControl(defaults['name']),
            sortable: new FormControl(defaults['sortable'])
        });
    };
    SortingFieldsControl.prototype.addItem = function (defaults) {
        var group = this.createItem(defaults);
        this.push(group);
        return group;
    };
    SortingFieldsControl.prototype.removeItems = function () {
        var _this = this;
        range(this.controls.length).forEach(function () { return _this.removeAt(0); });
    };
    SortingFieldsControl.prototype.setItems = function (value, options) {
        var _this = this;
        this.removeItems();
        value.forEach(function (itemValue) {
            var control = _this.addItem();
            control.patchValue(itemValue, options);
        });
    };
    SortingFieldsControl.prototype.setValue = function (value, options) {
        this.setItems(value, options);
    };
    SortingFieldsControl.prototype.patchValue = function (value, options) {
        this.setItems(value, options);
    };
    return SortingFieldsControl;
}(FormArray));
export { SortingFieldsControl };

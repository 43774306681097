import { Injectable, Injector } from '@angular/core';
import fromPairs from 'lodash/fromPairs';
import { concat, defer, from, Observable, of, throwError } from 'rxjs';
import { catchError, delayWhen, map, tap } from 'rxjs/operators';

import { ServerRequestError } from '@modules/api';
import { exportFormats, ExportFormatType } from '@modules/export';
import { ParameterField } from '@modules/fields';
import { ModelService } from '@modules/model-queries';
import { Model, ModelAction, ModelDescription } from '@modules/models';
import { CurrentEnvironmentStore, CurrentProjectStore, Resource } from '@modules/projects';
import { ResourceController, ResourceControllerService } from '@modules/resources';
import { AppError, getExtension, isSet, readFileArrayBuffer, readFileText } from '@shared';

import { ModelImportEvent, ModelImportEventType } from '../../data/model-import.event';
import { TextEncoding } from '../../data/text-encoding';

export const IMPORT_OBJECT_ERROR_KEY = '__import_error__';

@Injectable()
export class ImportService {
  constructor(
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private resourceControllerService: ResourceControllerService,
    private modelService: ModelService,
    private injector: Injector
  ) {}

  createModel(): Model {
    return Injector.create({
      providers: [{ provide: Model, deps: [Injector] }],
      parent: this.injector
    }).get<Model>(Model);
  }

  parseJSONFileData(file: File, options: { encoding?: TextEncoding } = {}): Observable<Object[]> {
    // Default UTF-8
    let encoding = 'utf-8';

    if (options.encoding == TextEncoding.Windows1250CentralEuropean) {
      encoding = 'windows-1250';
    } else if (options.encoding == TextEncoding.Windows1251Cyrillic) {
      encoding = 'windows-1251';
    } else if (options.encoding == TextEncoding.Windows1252Latin1) {
      encoding = 'windows-1252';
    } else if (options.encoding == TextEncoding.Windows1253Greek) {
      encoding = 'windows-1253';
    } else if (options.encoding == TextEncoding.Windows1254Turkish) {
      encoding = 'windows-1254';
    } else if (options.encoding == TextEncoding.Windows1255Hebrew) {
      encoding = 'windows-1255';
    } else if (options.encoding == TextEncoding.Windows1256Arabic) {
      encoding = 'windows-1256';
    } else if (options.encoding == TextEncoding.Windows1257Baltic) {
      encoding = 'windows-1257';
    } else if (options.encoding == TextEncoding.Windows1258Vietnamese) {
      encoding = 'windows-1258';
    } else if (options.encoding == TextEncoding.Windows874Thai) {
      encoding = 'windows-874';
    } else if (options.encoding == TextEncoding.SimplifiedChineseGBK) {
      encoding = 'gbk';
    } else if (options.encoding == TextEncoding.TraditionalChineseBig5) {
      encoding = 'big5';
    } else if (options.encoding == TextEncoding.JapaneseShiftJIS) {
      encoding = 'shift-jis';
    } else if (options.encoding == TextEncoding.UTF16LE) {
      encoding = 'utf-16le';
    }

    return readFileText(file, encoding).pipe(
      map(text => {
        try {
          return JSON.parse(text);
        } catch (e) {
          throw new ServerRequestError('Incorrect JSON contents');
        }
      })
    );
  }

  async parseWorkbookFileData(file: File, options: { encoding?: TextEncoding } = {}): Promise<Object[]> {
    const XLSX = import(/* webpackChunkName: "xlsx" */ 'xlsx');
    const xlsx = await XLSX;
    const arrayBuffer = await readFileArrayBuffer(file).toPromise();
    const dataArr = new Uint8Array(arrayBuffer);

    // Default UTF-8
    let codepage = 65001;

    if (options.encoding == TextEncoding.Windows1250CentralEuropean) {
      codepage = 1250;
    } else if (options.encoding == TextEncoding.Windows1251Cyrillic) {
      codepage = 1251;
    } else if (options.encoding == TextEncoding.Windows1252Latin1) {
      codepage = 1252;
    } else if (options.encoding == TextEncoding.Windows1253Greek) {
      codepage = 1253;
    } else if (options.encoding == TextEncoding.Windows1254Turkish) {
      codepage = 1254;
    } else if (options.encoding == TextEncoding.Windows1255Hebrew) {
      codepage = 1255;
    } else if (options.encoding == TextEncoding.Windows1256Arabic) {
      codepage = 1256;
    } else if (options.encoding == TextEncoding.Windows1257Baltic) {
      codepage = 1257;
    } else if (options.encoding == TextEncoding.Windows1258Vietnamese) {
      codepage = 1258;
    } else if (options.encoding == TextEncoding.Windows874Thai) {
      codepage = 874;
    } else if (options.encoding == TextEncoding.SimplifiedChineseGBK) {
      codepage = 936;
    } else if (options.encoding == TextEncoding.TraditionalChineseBig5) {
      codepage = 950;
    } else if (options.encoding == TextEncoding.JapaneseShiftJIS) {
      codepage = 932;
    } else if (options.encoding == TextEncoding.UTF16LE) {
      codepage = 1200;
    }

    try {
      const workbook = xlsx.read(dataArr, {
        type: 'array',
        codepage: codepage,
        raw: true
      });
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      return xlsx.utils.sheet_to_json(worksheet, { defval: null });
    } catch (e) {
      throw new AppError(`File read failed: ${e}`);
    }
  }

  getFileFormatType(file: File): ExportFormatType {
    const extension = (getExtension(file.name) || '').toLowerCase();
    const exportFormat = exportFormats.find(item => item.extension == extension);

    return exportFormat ? exportFormat.type : undefined;
  }

  parseFileData(file: File, options: { encoding?: TextEncoding } = {}): Observable<Object[]> {
    const fileFormatType = this.getFileFormatType(file);

    if (!fileFormatType) {
      return throwError(new AppError(`Unsupported file extension: ${getExtension(file.name)}`));
    } else if (fileFormatType == ExportFormatType.JSON) {
      return this.parseJSONFileData(file, options);
    } else {
      return from(this.parseWorkbookFileData(file, options));
    }
  }

  importRecord(
    resource: Resource,
    controller: ResourceController,
    modelDescription: ModelDescription,
    data: Object,
    options: { ignorePostCreate?: boolean } = {}
  ): Observable<Model> {
    const project = this.currentProjectStore.instance;
    const environment = this.currentEnvironmentStore.instance;
    const instance = this.createModel().deserialize(modelDescription.model, data);

    instance.setUp(modelDescription);
    instance.deserializeAttributes(modelDescription.dbFields);
    instance.setAttributes(data);

    return controller.modelCreate(resource, modelDescription, instance).pipe(
      delayWhen(result => {
        if (options.ignorePostCreate) {
          return of(undefined);
        }

        return this.modelService.onModelAction(
          project,
          environment,
          resource,
          controller,
          modelDescription,
          result,
          ModelAction.Create
        );
      })
    );
  }

  importRecords(
    resource: Resource,
    modelDescription: ModelDescription,
    sourceData: Object[],
    mappings?: { field: ParameterField; source: string }[],
    options: { ignorePostCreate?: boolean } = {}
  ): Observable<ModelImportEvent> {
    const controller = this.resourceControllerService.get(resource.type);
    const importData: Object[] = mappings
      ? sourceData.map(item => {
          const pairs = mappings
            .map(mapping => {
              let value = item[mapping.source];

              if (!isSet(value)) {
                return;
              }

              if (mapping.field.fieldDescription.deserializeValue) {
                value = mapping.field.fieldDescription.deserializeValue(value, mapping.field);
              }

              return [mapping.field.name, value];
            })
            .filter(pair => !!pair);

          return fromPairs(pairs);
        })
      : sourceData;

    if (!importData.length) {
      return of({
        type: ModelImportEventType.Finished,
        modelDescription: modelDescription,
        processedCount: 0,
        successCount: 0,
        failedCount: 0,
        totalCount: 0
      });
    }

    let successCount = 0;
    let failedCount = 0;
    const objectResults: Object[] = [];

    return concat(
      of({
        type: ModelImportEventType.Started,
        modelDescription: modelDescription,
        totalCount: importData.length
      }),
      ...importData.map((item, i) => {
        return this.importRecord(resource, controller, modelDescription, item, options).pipe(
          tap(() => {
            objectResults.push(item);
          }),
          catchError(error => {
            let errorStr: string;

            if (error instanceof ServerRequestError && error.errors.length) {
              errorStr = String(error.errors[0]);
            } else {
              errorStr = String(error);
            }

            objectResults.push({
              ...sourceData[i],
              [IMPORT_OBJECT_ERROR_KEY]: errorStr
            });

            return of(undefined);
          }),
          map(model => {
            if (model) {
              ++successCount;
            } else {
              ++failedCount;
            }

            return {
              type: ModelImportEventType.Progress,
              modelDescription: modelDescription,
              processedCount: successCount + failedCount,
              successCount: successCount,
              failedCount: failedCount,
              totalCount: importData.length
            };
          })
        );
      }),
      defer(() => {
        return of({
          type: ModelImportEventType.Finished,
          modelDescription: modelDescription,
          processedCount: successCount + failedCount,
          successCount: successCount,
          failedCount: failedCount,
          totalCount: importData.length,
          objectResults: objectResults
        });
      })
    );
  }
}

import { copyArrayItem, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';

import { AppDragDrop } from '../../data/drag-drop';
import { AppDrag } from '../../directives/drag/drag.directive';

export interface DragData {
  name: string;
  children?: DragData[];
}

@Component({
  selector: 'app-playground',
  templateUrl: './playground.component.html',
  styleUrls: ['./playground.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class PlaygroundComponent implements OnInit, OnDestroy {
  list1: DragData[] = [{ name: 'item1', children: [] }];
  list2: DragData[] = [{ name: 'item2' }];
  list3: DragData[] = [{ name: 'item3' }, { name: 'item4' }, { name: 'item5' }];

  constructor() {}

  ngOnInit() {}

  ngOnDestroy(): void {}

  dragDrop(event: AppDragDrop<DragData[]>) {
    console.log('appDropListDropped', event);
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else if (event.previousContainer.cloneItems) {
      copyArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
    }
  }

  isDroppable(item: AppDrag<DragData>): boolean {
    return false;
  }
}

<div appElement [appElementDetectVisible]="true" (appElementFirstVisible)="onFirstVisible()" style="min-height: 2px;">
  <ng-container *ngIf="!readonly">
    <ng-template
      let-actions="actions"
      let-stickyTop="stickyTop"
      let-stickyBottom="stickyBottom"
      let-closable="closable"
      #actions_template
    >
      <app-select-field-action-item
        *ngFor="let item of asActions(actions)"
        [item]="item"
        [context]="context"
        [contextElement]="contextElement"
        [stickyTop]="stickyTop"
        [stickyBottom]="stickyBottom"
        (execute)="asClosableComponent(closable).close()"
      ></app-select-field-action-item>
    </ng-template>

    <app-field-wrapper
      [form]="form"
      [field]="field"
      [readonly]="readonly"
      [requiredAsterisk]="requiredAsterisk"
      [label]="label"
      [errors]="errors"
      [truncate]="truncate"
      [manualMargin]="manualMargin"
      [labelButtons]="labelButtons"
      [tooltip]="tooltip"
      [idToken]="idToken"
    >
      <app-select
        *ngIf="control && field.params['style'] != styles.Chips"
        [multiple]="true"
        [placeholder]="field.placeholder || ('choose' | localize | appCapitalize)"
        [control]="control"
        [fill]="selectFill"
        [segment]="selectSegment"
        [compareWith]="field.params['valueEquals']"
        [source]="source?.initialized ? source : undefined"
        [options]="staticOptions$ | async"
        [resetEnabled]="!field['required'] && field['resetEnabled']"
        [searchEnabled]="true"
        [searchMinimumLength]="1"
        [searchDebounce]="source ? 200 : 0"
        [id]="field.name | appUniqueId: idToken"
        [classes]="field.params['classes']"
        #select
      >
        <ng-container dropdown-top>
          <ng-container
            *ngTemplateOutlet="actions_template; context: { actions: topActions, closable: select }"
          ></ng-container>
        </ng-container>

        <ng-container dropdown-bottom>
          <ng-container
            *ngTemplateOutlet="
              actions_template;
              context: { actions: bottomActions, stickyBottom: true, closable: select }
            "
          ></ng-container>
        </ng-container>
      </app-select>

      <app-chips
        *ngIf="control && field.params['style'] == styles.Chips"
        [control]="control"
        [source]="source?.initialized ? source : undefined"
        [options]="staticOptions$ | async"
        [placeholder]="field.placeholder"
        [resetEnabled]="field.resetEnabled"
        [allowNew]="field.params['create_enabled']"
        [circular]="field.params['circular']"
        #chips
      >
        <ng-container dropdown-top>
          <ng-container
            *ngTemplateOutlet="actions_template; context: { actions: topActions, closable: chips }"
          ></ng-container>
        </ng-container>

        <ng-container dropdown-bottom>
          <ng-container
            *ngTemplateOutlet="
              actions_template;
              context: { actions: bottomActions, stickyBottom: true, closable: chips }
            "
          ></ng-container>
        </ng-container>
      </app-chips>

      <app-select
        *ngIf="!form"
        [multiple]="true"
        [placeholder]="'choose' | localize | appCapitalize"
        [fill]="selectFill"
        [segment]="selectSegment"
        [compareWith]="field.params['valueEquals']"
        [source]="source?.initialized ? source : undefined"
        [options]="selectedOptions"
        [resetEnabled]="!field['required'] && field['resetEnabled']"
        [searchEnabled]="true"
        [searchMinimumLength]="1"
        [searchDebounce]="source ? 200 : 0"
        [id]="field.name | appUniqueId: idToken"
        [classes]="field.params['classes']"
        #select
      >
        <ng-container dropdown-top>
          <ng-container
            *ngTemplateOutlet="actions_template; context: { actions: topActions, closable: select }"
          ></ng-container>
        </ng-container>

        <ng-container dropdown-bottom>
          <ng-container
            *ngTemplateOutlet="
              actions_template;
              context: { actions: bottomActions, stickyBottom: true, closable: select }
            "
          ></ng-container>
        </ng-container>
      </app-select>
    </app-field-wrapper>
  </ng-container>

  <app-field-wrapper
    *ngIf="readonly"
    [form]="form"
    [field]="field"
    [readonly]="readonly"
    [requiredAsterisk]="requiredAsterisk"
    [label]="label"
    [errors]="errors"
    [truncate]="truncate"
    [manualMargin]="manualMargin"
    [labelButtons]="labelButtons"
    [tooltip]="tooltip"
    [idToken]="idToken"
  >
    <div *ngIf="!selectedOptionsLoading" class="status-list" #list_element>
      <ng-container *ngFor="let item of selectedOptions; let i = index">
        <span
          *ngIf="clipListItems === undefined || i < clipListItems"
          class="status"
          [ngClass]="
            !(item.color | appIsColorHex)
              ? ['color_' + item.color + '_1', 'background-color_' + item.color + '_2']
              : ['']
          "
          [style.color]="(item.color | appIsColorHex) ? item.color : null"
          [style.background-color]="(item.color | appIsColorHex) ? backgroundCustomColor(item.color) : null"
          #list_item_element
        >
          {{ item.name || item.value }}
        </span>
      </ng-container>

      <span *ngIf="clipListItems < selectedOptions.length" class="status">
        +{{ selectedOptions.length - clipListItems }}
      </span>
    </div>
    <div *ngIf="selectedOptionsLoading" class="status-list">
      <span class="status">
        <span [class.loading-animation]="true">
          <span class="stub-text">option</span>
        </span>
      </span>
    </div>
  </app-field-wrapper>
</div>

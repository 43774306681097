<ng-container *ngIf="!loading && !(saving$ | async) && projectSettings">
  <ng-template
    [cdkConnectedOverlayBackdropClass]="'popover2-backdrop'"
    [cdkConnectedOverlayOpen]="typeOpened$ | async"
    [cdkConnectedOverlayOrigin]="type_trigger"
    [cdkConnectedOverlayPanelClass]="[
      'overlay',
      'overlay_horizontal_center',
      dropdownLeft ? 'overlay_position_bottom-right' : 'overlay_position_bottom-left',
      dark ? 'theme_dark' : 'theme_default'
    ]"
    [cdkConnectedOverlayPositions]="typeDropdownPositions"
    [cdkConnectedOverlayPush]="true"
    [cdkConnectedOverlayGrowAfterOpen]="true"
    [cdkConnectedOverlayHasBackdrop]="true"
    (backdropClick)="typeOpened$.next(false)"
    (detach)="typeOpened$.next(false)"
    cdkConnectedOverlay
  >
    <ng-template
      [cdkConnectedOverlayBackdropClass]="'popover2-backdrop'"
      [cdkConnectedOverlayOpen]="pagesOpened$ | async"
      [cdkConnectedOverlayOrigin]="page_trigger"
      [cdkConnectedOverlayPanelClass]="[
        'overlay',
        'overlay_horizontal_center',
        'overlay_position_right-center',
        dropdownLeft ? 'overlay_position_left-center' : 'overlay_position_right-center',
        dark ? 'theme_dark' : 'theme_default'
      ]"
      [cdkConnectedOverlayPositions]="pagesDropdownPositions"
      [cdkConnectedOverlayPush]="true"
      [cdkConnectedOverlayGrowAfterOpen]="true"
      [cdkConnectedOverlayHasBackdrop]="true"
      (backdropClick)="pagesOpened$.next(false)"
      (detach)="pagesOpened$.next(false)"
      cdkConnectedOverlay
    >
      <app-choose-page-dropdown
        class="menu-pages-item__dropdown overlay-content overlay-content_animated"
        [currentPage]="(customizeService.handlerInfo$ | async)?.page"
        [brightShadow]="true"
        (chosen)="setHomePage($event); typeOpened$.next(false); pagesOpened$.next(false)"
      ></app-choose-page-dropdown>
    </ng-template>

    <div class="menu-pages-item__dropdown overlay-content overlay-content_animated">
      <div class="dropdown-selector dropdown-selector_small-border-radius dropdown-selector_bright-shadow">
        <div class="dropdown-selector__item" (click)="setHomeFirstMenuItem(); typeOpened$.next(false)">
          <div class="dropdown-selector__item-left">
            <div class="dropdown-selector__item-icon icon-fileds"></div>
          </div>
          <div class="dropdown-selector__item-main">
            <div class="dropdown-selector__item-title">
              First menu page
            </div>
          </div>
        </div>

        <div
          class="dropdown-selector__item"
          cdkOverlayOrigin
          #page_trigger="cdkOverlayOrigin"
          (click)="$event.stopPropagation(); pagesOpened$.next(true)"
        >
          <div class="dropdown-selector__item-left">
            <div class="dropdown-selector__item-icon icon-documents"></div>
          </div>
          <div class="dropdown-selector__item-main">
            <div class="dropdown-selector__item-title">
              Select page
            </div>
          </div>
          <div class="dropdown-selector__item-right">
            <span class="dropdown-selector__item-check icon-arrow_forward_2"></span>
          </div>
        </div>

        <div class="dropdown-selector__item" (click)="editHomeWorkflow(); typeOpened$.next(false)">
          <div class="dropdown-selector__item-left">
            <div class="dropdown-selector__item-icon icon-workflow"></div>
          </div>
          <div class="dropdown-selector__item-main">
            <div class="dropdown-selector__item-title">
              Using workflow
            </div>
          </div>
          <div class="dropdown-selector__item-right">
            <span class="dropdown-selector__item-check icon-arrow_forward_2"></span>
          </div>
        </div>
      </div>
    </div>
  </ng-template>

  <div
    class="menu-pages-select menu-pages-select_interactive"
    [class.menu-pages-select_active]="typeOpened$ | async"
    [class.menu-pages-select_disabled]="disabled"
    cdkOverlayOrigin
    #type_trigger="cdkOverlayOrigin"
    (click)="$event.stopPropagation(); typeOpened$.next(true)"
  >
    <div class="menu-pages-select__left">
      <div class="menu-pages-select__icon">
        <div class="menu-pages-select__icon-inner icon-home"></div>
      </div>
    </div>
    <div class="menu-pages-select__main">
      <div class="menu-pages-select__subtitle">Home page</div>
      <div class="menu-pages-select__title">
        {{ displayValue || 'Not specified' }}
      </div>
    </div>
    <div class="menu-pages-select__right">
      <div class="menu-pages-item__arrow icon-arrow_down_2"></div>
    </div>
  </div>
</ng-container>

<div *ngIf="loading || (saving$ | async)" class="menu-pages-select">
  <div class="menu-pages-select__left">
    <div class="menu-pages-select__icon">
      <div class="menu-pages-select__icon-inner icon-home"></div>
    </div>
  </div>
  <div class="menu-pages-select__main">
    <div class="menu-pages-select__subtitle">
      <span [class.loading-animation]="true"><span class="stub-text">Home</span></span>
    </div>
    <div class="menu-pages-select__title">
      <span [class.loading-animation]="true"><span class="stub-text">Page value</span></span>
    </div>
  </div>
  <div class="menu-pages-select__right">
    <div class="menu-pages-item__arrow icon-arrow_down_2"></div>
  </div>
</div>

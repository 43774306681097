/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../shared/components/loader-small/loader-small.component.ngfactory";
import * as i2 from "../../../../../shared/components/loader-small/loader-small.component";
import * as i3 from "@angular/common";
import * as i4 from "../../../../icons/directives/icon/icon.directive";
import * as i5 from "../../../../../core/services/app-config/app-config.service";
import * as i6 from "../../../../colors-shared/directives/button-tint-color/button-tint-color.directive";
import * as i7 from "@angular/platform-browser";
import * as i8 from "../../../../../common/tips/directives/tip/tip.directive";
import * as i9 from "../../../../../shared/pipes/capitalize/capitalize.pipe";
import * as i10 from "./list-actions-item.component";
import * as i11 from "../../../../customize/services/customize/customize.service";
import * as i12 from "../../../../action-queries/services/action/action.service";
import * as i13 from "../../../../action-queries/services/action-controller/action-controller.service";
import * as i14 from "../../../../../common/notifications/services/notification/notification.service";
var styles_ListActionsItemComponent = [];
var RenderType_ListActionsItemComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ListActionsItemComponent, data: {} });
export { RenderType_ListActionsItemComponent as RenderType_ListActionsItemComponent };
function View_ListActionsItemComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-loader-small", [["class", "token-popup-list-item__icon"]], null, null, null, i1.View_LoaderSmallComponent_0, i1.RenderType_LoaderSmallComponent)), i0.ɵdid(1, 114688, null, 0, i2.LoaderSmallComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
function View_ListActionsItemComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 11, "div", [["class", "token-popup__item"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, [["root_element", 1]], null, 10, "a", [["class", "token-popup-list-item token-popup-list-item_hoverable token-popup-list-item_clickable"], ["href", "javascript:void(0)"]], [[2, "token-popup-list-item_disabled", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.executeAction(_co.item, i0.ɵnov(_v, 1)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 9, "div", [["class", "token-popup-list-item__inner"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 8, "div", [["class", "token-popup-list-item__line"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 4, "span", [["class", "token-popup-list-item__left"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ListActionsItemComponent_2)), i0.ɵdid(6, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(7, 0, null, null, 1, "span", [["class", "token-popup-list-item__icon"]], [[4, "background-image", null]], null, null, null, null)), i0.ɵdid(8, 540672, null, 0, i4.IconDirective, [i5.AppConfigService, i0.ElementRef], { icon: [0, "icon"] }, null), (_l()(), i0.ɵeld(9, 0, null, null, 2, "span", [["class", "token-popup-list-item__main"]], null, null, null, null, null)), (_l()(), i0.ɵted(10, null, [" ", " "])), i0.ɵppd(11, 1)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.submitLoading; _ck(_v, 6, 0, currVal_1); var currVal_3 = _co.actionIcon(_co.item); _ck(_v, 8, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.disabled || _co.submitLoading); _ck(_v, 1, 0, currVal_0); var currVal_2 = i0.ɵnov(_v, 8).backgroundImage; _ck(_v, 7, 0, currVal_2); var currVal_4 = i0.ɵunv(_v, 10, 0, _ck(_v, 11, 0, i0.ɵnov(_v.parent, 0), _co.verboseName)); _ck(_v, 10, 0, currVal_4); }); }
function View_ListActionsItemComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-loader-small", [["class", "button__icon"]], [[2, "button__icon_left", null]], null, null, i1.View_LoaderSmallComponent_0, i1.RenderType_LoaderSmallComponent)), i0.ɵdid(1, 114688, null, 0, i2.LoaderSmallComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.displayTitle(); _ck(_v, 0, 0, currVal_0); }); }
function View_ListActionsItemComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [["class", "icon button__icon"]], [[2, "button__icon_left", null], [4, "background-image", null]], null, null, null, null)), i0.ɵdid(1, 540672, null, 0, i4.IconDirective, [i5.AppConfigService, i0.ElementRef], { icon: [0, "icon"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.item.icon; _ck(_v, 1, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.displayTitle(); var currVal_1 = i0.ɵnov(_v, 1).backgroundImage; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_ListActionsItemComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "span", [["class", "button__label"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, [" ", " "])), i0.ɵppd(2, 1)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i0.ɵnov(_v.parent.parent, 0), _co.verboseName)); _ck(_v, 1, 0, currVal_0); }); }
function View_ListActionsItemComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, [["root_element", 1]], null, 7, "a", [["class", "button list__actions-item"]], [[2, "button_primary", null], [2, "button_primary-transparent", null], [2, "button_icon", null], [2, "button_disabled", null], [8, "style", 2], [2, "button_tint-color", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.executeAction(_co.item, i0.ɵnov(_v, 0)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 540672, null, 0, i6.ButtonTintColorDirective, [i7.DomSanitizer], { appTintColor: [0, "appTintColor"], appTintColorStyle: [1, "appTintColorStyle"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ListActionsItemComponent_4)), i0.ɵdid(3, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ListActionsItemComponent_5)), i0.ɵdid(5, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ListActionsItemComponent_6)), i0.ɵdid(7, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_6 = (_co.item.tint || _co.accentColor); var currVal_7 = _co.style; _ck(_v, 1, 0, currVal_6, currVal_7); var currVal_8 = _co.submitLoading; _ck(_v, 3, 0, currVal_8); var currVal_9 = (_co.item.icon && !_co.submitLoading); _ck(_v, 5, 0, currVal_9); var currVal_10 = _co.displayTitle(); _ck(_v, 7, 0, currVal_10); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (!_co.item.tint && (_co.style == _co.tintStyles.Primary)); var currVal_1 = (!_co.item.tint && (_co.style == _co.tintStyles.Transparent)); var currVal_2 = !_co.displayTitle(); var currVal_3 = (_co.disabled || _co.submitLoading); var currVal_4 = i0.ɵnov(_v, 1).style; var currVal_5 = i0.ɵnov(_v, 1).cls; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); }); }
function View_ListActionsItemComponent_7(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [["class", "element__indicator icon-eye"]], [[2, "element__indicator_outside", null], [2, "element__indicator_tiny", null]], null, null, null, null)), i0.ɵdid(1, 737280, null, 0, i8.TipDirective, [i0.ElementRef, i0.ComponentFactoryResolver, i0.Injector, i0.ApplicationRef], { content: [0, "content"] }, null)], function (_ck, _v) { var currVal_2 = "Action is hidden"; _ck(_v, 1, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.pinned; var currVal_1 = !_co.pinned; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
export function View_ListActionsItemComponent_0(_l) { return i0.ɵvid(2, [i0.ɵpid(0, i9.CapitalizePipe, []), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ListActionsItemComponent_1)), i0.ɵdid(2, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ListActionsItemComponent_3)), i0.ɵdid(4, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_ListActionsItemComponent_7)), i0.ɵdid(6, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵpid(131072, i3.AsyncPipe, [i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.pinned; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.pinned; _ck(_v, 4, 0, currVal_1); var currVal_2 = (!_co.isVisible && i0.ɵunv(_v, 6, 0, i0.ɵnov(_v, 7).transform(_co.customizeService.enabled$))); _ck(_v, 6, 0, currVal_2); }, null); }
export function View_ListActionsItemComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-list-actions-item", [], [[2, "element__indicator-wrapper", null], [2, "element__indicator-wrapper_block", null], [2, "hidden", null]], null, null, View_ListActionsItemComponent_0, RenderType_ListActionsItemComponent)), i0.ɵdid(1, 770048, null, 0, i10.ListActionsItemComponent, [i11.CustomizeService, i12.ActionService, i13.ActionControllerService, i14.NotificationService, i0.Injector, i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).indicatorWrapper; var currVal_1 = i0.ɵnov(_v, 1).indicatorWrapperBlock; var currVal_2 = i0.ɵnov(_v, 1).hidden; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
var ListActionsItemComponentNgFactory = i0.ɵccf("app-list-actions-item", i10.ListActionsItemComponent, View_ListActionsItemComponent_Host_0, { item: "item", pinned: "pinned", context: "context", contextElement: "contextElement", compact: "compact", accentColor: "accentColor" }, { selected: "selected" }, []);
export { ListActionsItemComponentNgFactory as ListActionsItemComponentNgFactory };

import toPairs from 'lodash/toPairs';

import { isFormulaAccessorItemDotNotation } from '@modules/parameters';
import { isSet } from '@shared';

export class FormulaElementDescriptor {
  readonly tag: string;
  classes = new Set<string>();
  text: string;
  children: FormulaElementDescriptor[] = [];
  onCreateHandlers: ((element: HTMLElement) => void)[] = [];

  constructor(tag: string, options: Partial<FormulaElementDescriptor> = {}) {
    this.tag = tag.toUpperCase();
    Object.assign(this, options);
  }

  addClass(...classes: string[]) {
    classes.forEach(item => this.classes.add(item));
  }

  appendChild(element: FormulaElementDescriptor) {
    this.children.push(element);
  }

  onCreate(callback: (element: HTMLElement) => void) {
    this.onCreateHandlers.push(callback);
  }
}

export function renderFormulaNode(
  node,
  options: { parentNode?; setupDescriptor?: (descriptor: FormulaElementDescriptor, node) => void } = {}
): FormulaElementDescriptor {
  if (node.isFunctionNode) {
    const root = new FormulaElementDescriptor('span');

    root.addClass('formula-func');

    const label = new FormulaElementDescriptor('span');

    label.addClass('formula-func-label');
    label.text = node.name.toUpperCase();
    root.appendChild(label);

    const parenthesisOpen = new FormulaElementDescriptor('span');

    parenthesisOpen.addClass('formula-punctuation');
    parenthesisOpen.text = '(';
    root.appendChild(parenthesisOpen);

    let prevArg;

    for (const arg of node.args) {
      if (prevArg) {
        const comma = new FormulaElementDescriptor('span');

        comma.addClass('formula-punctuation', 'formula-punctuation_comma');
        comma.text = ',';
        root.appendChild(comma);
      }

      const argEl = renderFormulaNode(arg, { ...options, parentNode: node });
      root.appendChild(argEl);
      prevArg = arg;
    }

    const parenthesisClose = new FormulaElementDescriptor('span');

    parenthesisClose.addClass('formula-punctuation');
    parenthesisClose.text = ')';
    root.appendChild(parenthesisClose);

    if (options.setupDescriptor) {
      options.setupDescriptor(root, node);
    }

    return root;
  } else if (node.isConstantNode) {
    const root = new FormulaElementDescriptor('span');

    if (typeof node.value === 'string') {
      if (
        options.parentNode &&
        options.parentNode.isAccessorNode &&
        options.parentNode.index.dotNotation &&
        isFormulaAccessorItemDotNotation(node.value)
      ) {
        root.addClass('formula-symbol');
        root.text = node.value;
      } else {
        root.addClass('formula-string');
        root.text = JSON.stringify(node.value);
      }
    } else if (typeof node.value === 'number') {
      root.addClass('formula-number');
      root.text = String(node.value);
    } else if (typeof node.value === 'boolean') {
      root.addClass('formula-boolean');
      root.text = String(node.value);
    } else if (node.value === null || node.value === undefined) {
      root.addClass('formula-symbol');
      root.text = String(node.value);
    }

    if (options.setupDescriptor) {
      options.setupDescriptor(root, node);
    }

    return root;
  } else if (node.isSymbolNode) {
    const root = new FormulaElementDescriptor('span');

    root.addClass('formula-symbol');
    root.text = node.name;

    if (options.setupDescriptor) {
      options.setupDescriptor(root, node);
    }

    return root;
  } else if (node.isParenthesisNode) {
    const root = new FormulaElementDescriptor('span');

    root.addClass('formula-parenthesis');

    const parenthesisOpen = new FormulaElementDescriptor('span');

    parenthesisOpen.addClass('formula-punctuation');
    parenthesisOpen.text = '(';
    root.appendChild(parenthesisOpen);

    const content = renderFormulaNode(node.content, { ...options, parentNode: node });
    root.appendChild(content);

    const parenthesisClose = new FormulaElementDescriptor('span');

    parenthesisClose.addClass('formula-punctuation');
    parenthesisClose.text = ')';
    root.appendChild(parenthesisClose);

    if (options.setupDescriptor) {
      options.setupDescriptor(root, node);
    }

    return root;
  } else if (node.isOperatorNode) {
    const root = new FormulaElementDescriptor('span');

    root.addClass('formula-operator-node');

    const operator = new FormulaElementDescriptor('span');

    operator.addClass('formula-operator');
    operator.text = node.op;

    const arg0 = renderFormulaNode(node.args[0], { ...options, parentNode: node });
    const arg1 = node.args[1] ? renderFormulaNode(node.args[1], { ...options, parentNode: node }) : undefined;

    if (arg1) {
      operator.addClass('formula-operator_binary');

      root.appendChild(arg0);
      root.appendChild(operator);
      root.appendChild(arg1);
    } else if (['-', '+'].includes(node.op)) {
      root.appendChild(operator);
      root.appendChild(arg0);
    } else {
      root.appendChild(arg0);
      root.appendChild(operator);
    }

    if (options.setupDescriptor) {
      options.setupDescriptor(root, node);
    }

    return root;
  } else if (node.isAccessorNode) {
    const root = new FormulaElementDescriptor('span');

    root.addClass('formula-token');

    const object = renderFormulaNode(node.object, { ...options, parentNode: node });
    root.appendChild(object);

    if (node.index.dotNotation) {
      const dot = new FormulaElementDescriptor('span');

      dot.addClass('formula-punctuation');
      dot.text = '.';
      root.appendChild(dot);

      const index = renderFormulaNode(node.index.dimensions[0], { ...options, parentNode: node });
      root.appendChild(index);
    } else {
      const bracketOpen = new FormulaElementDescriptor('span');

      bracketOpen.addClass('formula-punctuation');
      bracketOpen.text = '[';
      root.appendChild(bracketOpen);

      const index = renderFormulaNode(node.index.dimensions[0], { ...options, parentNode: node });
      root.appendChild(index);

      const bracketClose = new FormulaElementDescriptor('span');

      bracketClose.addClass('formula-punctuation');
      bracketClose.text = ']';
      root.appendChild(bracketClose);
    }

    if (options.setupDescriptor) {
      options.setupDescriptor(root, node);
    }

    return root;
  } else if (node.isObjectNode) {
    const root = new FormulaElementDescriptor('span');

    root.addClass('formula-object');

    const braceOpen = new FormulaElementDescriptor('span');

    braceOpen.addClass('formula-punctuation');
    braceOpen.text = '{';
    root.appendChild(braceOpen);

    let prevValue;

    for (const [name, value] of toPairs(node.properties)) {
      if (prevValue) {
        const comma = new FormulaElementDescriptor('span');

        comma.addClass('formula-punctuation', 'formula-punctuation_comma');
        comma.text = ',';
        root.appendChild(comma);
      }

      const key = new FormulaElementDescriptor('span');

      key.addClass('formula-string');
      key.text = `"${name}"`;

      root.appendChild(key);

      const colon = new FormulaElementDescriptor('span');

      colon.addClass('formula-punctuation', 'formula-punctuation_colon');
      colon.text = ':';

      root.appendChild(colon);

      const valueEl = renderFormulaNode(value, { ...options, parentNode: node });
      root.appendChild(valueEl);

      prevValue = value;
    }

    const braceClose = new FormulaElementDescriptor('span');

    braceClose.addClass('formula-punctuation');
    braceClose.text = '}';
    root.appendChild(braceClose);

    if (options.setupDescriptor) {
      options.setupDescriptor(root, node);
    }

    return root;
  } else if (node.isArrayNode) {
    const root = new FormulaElementDescriptor('span');

    root.addClass('formula-object');

    const bracketOpen = new FormulaElementDescriptor('span');

    bracketOpen.addClass('formula-punctuation');
    bracketOpen.text = '[';
    root.appendChild(bracketOpen);

    let prevValue;

    for (const value of node.items) {
      if (prevValue) {
        const comma = new FormulaElementDescriptor('span');

        comma.addClass('formula-punctuation', 'formula-punctuation_comma');
        comma.text = ',';
        root.appendChild(comma);
      }

      const valueEl = renderFormulaNode(value, { ...options, parentNode: node });
      root.appendChild(valueEl);

      prevValue = value;
    }

    const bracketClose = new FormulaElementDescriptor('span');

    bracketClose.addClass('formula-punctuation');
    bracketClose.text = ']';
    root.appendChild(bracketClose);

    if (options.setupDescriptor) {
      options.setupDescriptor(root, node);
    }

    return root;
  } else if (node.isBlockNode) {
    const root = new FormulaElementDescriptor('span');

    root.addClass('formula-blocks');

    let prevBlock;

    for (const block of node.blocks) {
      if (prevBlock) {
        const comma = new FormulaElementDescriptor('span');

        comma.addClass('formula-punctuation', 'formula-punctuation_comma');
        comma.text = ';';
        root.appendChild(comma);
      }

      const argEl = renderFormulaNode(block.node, { ...options, parentNode: node });
      root.appendChild(argEl);
      prevBlock = block;
    }

    if (options.setupDescriptor) {
      options.setupDescriptor(root, node);
    }

    return root;
  } else if (node.isFunctionAssignmentNode) {
    const root = new FormulaElementDescriptor('span');

    root.addClass('formula-func-assign');

    const label = new FormulaElementDescriptor('span');

    label.addClass('formula-func-label');
    label.text = node.name;
    root.appendChild(label);

    const parenthesisOpen = new FormulaElementDescriptor('span');

    parenthesisOpen.addClass('formula-punctuation');
    parenthesisOpen.text = '(';
    root.appendChild(parenthesisOpen);

    let prevArg;

    for (const arg of node.params) {
      if (prevArg) {
        const comma = new FormulaElementDescriptor('span');

        comma.addClass('formula-punctuation', 'formula-punctuation_comma');
        comma.text = ',';
        root.appendChild(comma);
      }

      const argEl = new FormulaElementDescriptor('span');

      argEl.addClass('formula-symbol');
      argEl.text = arg;
      root.appendChild(argEl);

      prevArg = arg;
    }

    const parenthesisClose = new FormulaElementDescriptor('span');

    parenthesisClose.addClass('formula-punctuation');
    parenthesisClose.text = ')';
    root.appendChild(parenthesisClose);

    const equals = new FormulaElementDescriptor('span');

    equals.addClass('formula-operator', 'formula-operator_binary');
    equals.text = '=';
    root.appendChild(equals);

    const expression = renderFormulaNode(node.expr, { ...options, parentNode: node });
    root.appendChild(expression);

    if (options.setupDescriptor) {
      options.setupDescriptor(root, node);
    }

    return root;
  } else {
    throw new Error(`Unsupported node: ${node}`);
  }
}

export function renderFormulaElementDescriptors(container: HTMLElement, elements: FormulaElementDescriptor[]) {
  container.childNodes.forEach(item => {
    if (item.nodeType != document.ELEMENT_NODE) {
      container.removeChild(item);
    }
  });

  for (let i = 0; i < elements.length; ++i) {
    const element = elements[i];
    let domElement: HTMLElement;

    if (!container.children[i]) {
      domElement = document.createElement(element.tag);
      container.appendChild(domElement);
    } else if (container.children[i].tagName != element.tag) {
      domElement = document.createElement(element.tag);
      container.replaceChild(domElement, container.children[i]);
    } else {
      domElement = container.children[i] as HTMLElement;
    }

    const removeClasses = [];
    domElement.classList.forEach(className => {
      if (!element.classes.has(className)) {
        removeClasses.push(className);
      }
    });
    domElement.classList.remove(...removeClasses);
    element.classes.forEach(className => {
      if (!domElement.classList.contains(className)) {
        domElement.classList.add(className);
      }
    });

    if (isSet(element.text)) {
      if (domElement.innerText != element.text) {
        domElement.innerText = element.text;
      }
    } else {
      renderFormulaElementDescriptors(domElement, element.children);
    }

    element.onCreateHandlers.forEach(handler => handler(domElement));
  }

  Array.from(container.children)
    .slice(elements.length)
    .forEach(item => container.removeChild(item));
}

export function getRootFormulaToken(element: HTMLElement): HTMLElement | null {
  if (element.classList.contains('formula-token') || element.classList.contains('formula-symbol')) {
    const parentResult = element.parentElement ? getRootFormulaToken(element.parentElement) : null;
    return parentResult || element;
  } else {
    return null;
  }
}

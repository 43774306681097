import { ChangeDetectionStrategy, Component } from '@angular/core';

import { DialogPopupComponent } from '../dialog-popup/dialog-popup.component';

@Component({
  selector: 'app-thin-dialog-popup',
  templateUrl: './thin-dialog-popup.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ThinDialogPopupComponent extends DialogPopupComponent {
  wide = false;
}

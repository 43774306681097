<td class="share-table__column">
  <div style="position: relative;">
    <input
      class="input input_disabled input_fill share-form__input"
      [value]="getPublicLink()"
      (click)="$event.target.select()"
      style="padding-right: 85px;"
      readonly
    />

    <a
      href="javascript:void(0)"
      class="button button_small"
      style="position: absolute; right: 5px; top: 5px; margin: 0;"
      (click)="copy()"
    >
      <span class="button__icon button__icon_left icon-duplicate"></span>
      <span class="button__label">{{ 'Copy' | localize }}</span>
    </a>
  </div>
</td>

<td class="share-table__column">
  <div class="share-table__select">
    <app-project-group-control [control]="selectedGroup"></app-project-group-control>
  </div>
</td>

<td class="share-table__column share-table__column_actions">
  <a
    href="javascript:void(0)"
    class="share-table__delete"
    (click)="delete()"
    [appTip]="'Remove link' | localize"
    [appTipOptions]="{ side: 'top' }"
  >
    <span class="icon-bin"></span>
  </a>
</td>

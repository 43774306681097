var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ContainerLayer } from '../layer';
import { LayerType } from '../layer-type';
import { registerLayerType } from '../layer-types';
var GroupLayer = /** @class */ (function (_super) {
    __extends(GroupLayer, _super);
    function GroupLayer(options) {
        if (options === void 0) { options = {}; }
        var _this = _super.call(this, options) || this;
        _this.type = LayerType.Group;
        return _this;
    }
    Object.defineProperty(GroupLayer.prototype, "icon", {
        get: function () {
            return 'layers';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GroupLayer.prototype, "defaultName", {
        get: function () {
            return 'group';
        },
        enumerable: true,
        configurable: true
    });
    return GroupLayer;
}(ContainerLayer));
export { GroupLayer };
registerLayerType(LayerType.Group, GroupLayer, { container: true });

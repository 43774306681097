<div class="task-queue-edit-popup">
  <div class="task-queue-edit-popup__header">
    <h2 class="task-queue-edit-popup__title">{{ queue ? 'Edit Task Queue' : 'Add Task Queue' }}</h2>
  </div>
  <div class="task-queue-edit-popup__content">
    <form class="task-queue-edit-popup__form" [formGroup]="form.form" (ngSubmit)="submit()">
      <div class="task-queue-edit-popup__form-container">
        <span class="task-queue-edit-popup__form-label">Name</span>
        <app-auto-field
          [form]="form.form"
          [label]="false"
          [field]="
            createField({
              name: 'name',
              label: 'Name',
              field: 'CharField',
              params: { classes: ['input_fill'] }
            })
          "
        ></app-auto-field>
      </div>

      <div class="task-queue-edit-popup__form-container">
        <span class="task-queue-edit-popup__form-label">Statuses</span>
        <div class="task-queue-edit-popup__form-statuses">
          <div class="task-queue-edit-popup__form-status" *ngFor="let item of form.array('statuses').controls">
            <div class="task-queue-edit-popup__form-field-wrap">
              <app-auto-field
                [form]="item"
                [label]="false"
                [field]="
                  createField({
                    name: 'name',
                    field: 'CharField',
                    placeholder: 'Enter Name',
                    params: { classes: ['input_fill'] }
                  })
                "
              ></app-auto-field>
            </div>
            <div class="task-queue-edit-popup__form-field-wrap task-queue-edit-popup__form-field-wrap_small">
              <app-auto-field
                [form]="item"
                [label]="false"
                [field]="
                  createField({
                    name: 'category',
                    field: 'SelectField',
                    placeholder: 'Select Category',
                    params: { classes: ['input_fill'], options: form.categoryOptions }
                  })
                "
              ></app-auto-field>
            </div>
            <button (click)="form.removeStatus(item)" type="button" class="button button_icon button_transparent">
              <span class="icon icon-bin button__icon"></span>
            </button>
          </div>
          <a
            href="javascript:void(0)"
            class="button button_orange task-queue-edit-popup__form-button"
            (click)="form.appendStatus()"
          >
            <span class="button__icon button__icon_left icon-plus"></span>
            <span class="button__label">Add new status</span>
          </a>
        </div>
      </div>

      <div class="task-queue-edit-popup__form-container task-queue-edit-popup__form-container_parameters">
        <span class="task-queue-edit-popup__form-label">Inputs</span>
        <app-parameters-edit
          [control]="form.form.controls['parameters']"
          [configurable]="{
            name: true,
            field: true,
            required: true,
            add: true,
            sortable: true
          }"
          [analyticsSource]="'page'"
        >
        </app-parameters-edit>
      </div>
      <app-form-errors [form]="form.form" [manualMargin]="true"></app-form-errors>
    </form>
  </div>

  <div class="task-queue-edit-popup__footer">
    <div class="task-queue-edit-popup__submit">
      <button
        *ngIf="queue"
        [class.button_disabled]="loading"
        (click)="delete()"
        type="button"
        class="button button_danger task-queue-edit-popup__submit-button task-queue-edit-popup__submit-button_left"
      >
        <app-loader-small *ngIf="loading" class="button__icon button__icon_left"></app-loader-small>
        Delete
      </button>
      <button type="button" class="button button_orange task-queue-edit-popup__submit-button" (click)="cancel()">
        Cancel
      </button>
      <button
        type="button"
        class="button button_primary button_orange task-queue-edit-popup__submit-button"
        [class.button_disabled]="loading || form.form.invalid || form.form.pristine"
        (click)="submit()"
      >
        <app-loader-small *ngIf="loading" class="button__icon button__icon_left"></app-loader-small>
        <ng-container *ngIf="queue">Update</ng-container>
        <ng-container *ngIf="!queue">Add</ng-container>
      </button>
    </div>
  </div>
</div>

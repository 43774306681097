import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { filter } from 'rxjs/operators';

import { NotificationService } from '@common/notifications';
import { SessionStorage } from '@core';
import { ServerRequestError } from '@modules/api';
import { ViewSettingsStore } from '@modules/customize';
import { createFormFieldFactory, ImageFit } from '@modules/fields';
import { MenuSection, MenuService } from '@modules/menu';
import { CurrentProjectStore } from '@modules/projects';
import { CustomViewTemplate, CustomViewTemplateService, View } from '@modules/views';
import { TypedChanges } from '@shared';

import { ViewEditorController } from '../../services/view-editor-controller/view-editor.controller';
import { CustomViewTemplatesChangeForm } from './custom-view-templates-change.form';

@Component({
  selector: 'app-custom-view-templates-change',
  templateUrl: './custom-view-templates-change.component.html',
  providers: [CustomViewTemplatesChangeForm],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomViewTemplatesChangeComponent implements OnInit, OnDestroy, OnChanges {
  @Input() id: string;
  @Input() setView: View;
  @Input() stateSelectedEnabled = false;
  @Input() componentLabel = 'component';
  @Output() openList = new EventEmitter<void>();
  @Output() openDetail = new EventEmitter<string>();
  @Output() saved = new EventEmitter<CustomViewTemplate>();
  @Output() back = new EventEmitter<void>();
  @Output() close = new EventEmitter<void>();

  createField = createFormFieldFactory();
  loading = false;
  error: string;
  notFound = false;
  submitLoading = false;
  template: CustomViewTemplate;
  templatePreview: CustomViewTemplate;
  templatePreviewScale: number;
  imageFits = ImageFit;

  constructor(
    public form: CustomViewTemplatesChangeForm,
    public currentProjectStore: CurrentProjectStore,
    private sessionStorage: SessionStorage,
    private customViewTemplateService: CustomViewTemplateService,
    private viewEditorController: ViewEditorController,
    private viewSettingsStore: ViewSettingsStore,
    private menuService: MenuService,
    private notificationService: NotificationService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.menuService.section = MenuSection.None;

    this.load();

    this.form
      .getInstance$()
      .pipe(untilDestroyed(this))
      .subscribe(result => {
        this.templatePreview = result;
        this.cd.markForCheck();

        const frame = result.view ? result.view.frame : undefined;
        const maxWidth = 516;

        this.templatePreviewScale = frame && frame.width > maxWidth ? maxWidth / frame.width : undefined;
        this.cd.markForCheck();
      });
  }

  ngOnDestroy(): void {
    this.menuService.section = MenuSection.Default;
  }

  ngOnChanges(changes: TypedChanges<CustomViewTemplatesChangeComponent>): void {
    if (changes.id && !changes.id.firstChange) {
      this.load();
    }
  }

  load() {
    this.error = undefined;
    this.notFound = false;
    this.template = undefined;
    this.cd.markForCheck();

    if (!this.id) {
      this.loading = false;
      this.cd.markForCheck();

      return;
    }

    this.loading = true;
    this.cd.markForCheck();

    return this.customViewTemplateService
      .getDetail(this.id)
      .pipe(untilDestroyed(this))
      .subscribe(
        template => {
          this.template = template;
          this.loading = false;
          this.cd.markForCheck();

          this.form.init(template);

          if (this.setView) {
            this.form.controls.view.patchValue(this.setView);
          }
        },
        error => {
          if (error instanceof ServerRequestError && error.errors.length) {
            this.error = error.errors[0];
          } else {
            this.error = error;
          }

          this.notFound = error instanceof ServerRequestError && error.response && error.response.status == 404;
          this.cd.markForCheck();
        }
      );
  }

  customizeView() {
    const view = this.form.controls.view.value as View;

    this.viewEditorController
      .open({
        create: !view,
        view: view,
        componentLabel: this.componentLabel,
        submitLabel: 'Apply changes',
        stateSelectedEnabled: this.stateSelectedEnabled,
        templatesEnabled: false,
        analyticsSource: 'view_templates_change'
      })
      .pipe(
        filter(result => !result.cancelled),
        untilDestroyed(this)
      )
      .subscribe(result => {
        this.form.controls.view.patchValue(result.view);
      });
  }

  submit() {
    this.submitLoading = true;
    this.cd.markForCheck();

    this.form
      .submit()
      .pipe(untilDestroyed(this))
      .subscribe(
        result => {
          this.saved.emit(result);

          if (this.template) {
            this.notificationService.success('Saved', `Component was successfully updated`);
          } else {
            this.notificationService.success('Created', `Component was successfully created`);
          }
        },
        e => {
          console.error(e);

          this.submitLoading = false;
          this.cd.markForCheck();

          this.notificationService.error('Failed', `Component saving failed`);
        }
      );
  }

  // dump() {
  //   const template = this.form.getInstance();
  //   const blob = new Blob([JSON.stringify(template.serialize())], { type: 'application/json; charset=utf-8' });
  //   const fileName = `${['template', snakeCase(template.name), moment().format('YYYY-MM-DD_HH:mm:ss')].join('_')}.json`;
  //
  //   saveAs(blob, fileName);
  // }
  //
  // onRestoreFileChanged(el: HTMLInputElement) {
  //   if (!el.files.length) {
  //     return;
  //   }
  //
  //   const file = el.files[0];
  //
  //   el.value = null;
  //
  //   this.restore(file);
  // }
  //
  // restore(file: File) {
  //   readFileText(file)
  //     .pipe(untilDestroyed(this))
  //     .subscribe(content => {
  //       try {
  //         const data = JSON.parse(content);
  //         const template = new Template().deserialize(data);
  //         this.form.patchTemplate(template);
  //       } catch (e) {}
  //     });
  // }
}

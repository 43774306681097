import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { timer } from 'rxjs';

import { AppConfigService } from '@core';
import { getIconData, IconType } from '@modules/icons';
import { isSet, KeyboardEventKeyCode, scrollTo } from '@shared';

@Component({
  selector: 'app-icon-selector',
  templateUrl: './icon-selector.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IconSelectorComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() current: string;
  @Input() emptyEnabled = false;
  @Input() emptyValue: any;
  @Output() selected = new EventEmitter<string>();

  @ViewChild('search_input') searchInputElement: ElementRef;
  @ViewChild('scrollable_element') scrollableElement: ElementRef;

  loading = true;
  search = '';
  typeOptions = [
    {
      type: IconType.Icon,
      label: 'Icons'
    },
    {
      type: IconType.Emoji,
      label: 'Emojis'
    },
    {
      type: IconType.Image,
      label: 'Custom'
    }
  ];
  selectedType: IconType;
  types = IconType;

  constructor(private appConfigService: AppConfigService, private cd: ChangeDetectorRef) {}

  ngOnInit() {
    const icon = getIconData(this.current, {
      mediaBaseUrl: this.appConfigService.getMediaBaseUrl()
    });

    this.selectedType = icon ? icon.type : IconType.Icon;
    this.cd.markForCheck();
  }

  ngOnDestroy(): void {}

  ngAfterViewInit(): void {
    timer(160)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.loading = false;
        this.cd.markForCheck();
      });
  }

  setSelectedType(value: IconType) {
    this.selectedType = value;
    this.search = '';
    this.cd.markForCheck();
    this.onSearch();
    this.searchInputElement.nativeElement.focus();
  }

  save(icon: string) {
    this.selected.emit(icon);
  }

  reset() {
    this.selected.emit(this.emptyValue);
  }

  clearSearch() {
    if (!isSet(this.search)) {
      return;
    }

    this.search = '';
    this.cd.markForCheck();
    this.onSearch();
  }

  onSearch() {
    scrollTo(this.scrollableElement.nativeElement, 0);
  }

  onSearchKey(e: KeyboardEvent) {
    if (e.keyCode == KeyboardEventKeyCode.Escape) {
      this.clearSearch();
    }
  }
}

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../value-format-edit/value-format-edit.component.ngfactory";
import * as i2 from "../../value-format-edit/value-format-edit.component";
import * as i3 from "../../value-format-edit/value-format.control";
import * as i4 from "../../../../projects/stores/current-project.store";
import * as i5 from "./number-field-view-params.form";
import * as i6 from "@angular/forms";
import * as i7 from "./number-field-view-params.component";
var styles_NumberFieldViewParamsComponent = [];
var RenderType_NumberFieldViewParamsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_NumberFieldViewParamsComponent, data: {} });
export { RenderType_NumberFieldViewParamsComponent as RenderType_NumberFieldViewParamsComponent };
export function View_NumberFieldViewParamsComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-value-format-edit", [], null, null, null, i1.View_ValueFormatEditComponent_0, i1.RenderType_ValueFormatEditComponent)), i0.ɵdid(1, 638976, null, 0, i2.ValueFormatEditComponent, [i0.ChangeDetectorRef], { control: [0, "control"], field: [1, "field"], formatInputEnabled: [2, "formatInputEnabled"], prefixEnabled: [3, "prefixEnabled"], postfixEnabled: [4, "postfixEnabled"], numberFormatOptionsEnabled: [5, "numberFormatOptionsEnabled"], context: [6, "context"], analyticsSource: [7, "analyticsSource"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.form.form.controls["value_format"]; var currVal_1 = _co.fieldTypes.Number; var currVal_2 = !_co.editable; var currVal_3 = true; var currVal_4 = !_co.editable; var currVal_5 = !_co.editable; var currVal_6 = _co.context; var currVal_7 = _co.analyticsSource; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }, null); }
export function View_NumberFieldViewParamsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "app-number-field-view-params", [], null, null, null, View_NumberFieldViewParamsComponent_0, RenderType_NumberFieldViewParamsComponent)), i0.ɵprd(131584, null, i3.ValueFormatControl, i3.ValueFormatControl, [i4.CurrentProjectStore]), i0.ɵprd(512, null, i5.NumberFieldViewParamsForm, i5.NumberFieldViewParamsForm, [i6.FormBuilder, i3.ValueFormatControl]), i0.ɵdid(3, 245760, null, 0, i7.NumberFieldViewParamsComponent, [i5.NumberFieldViewParamsForm, i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 3, 0); }, null); }
var NumberFieldViewParamsComponentNgFactory = i0.ɵccf("app-number-field-view-params", i7.NumberFieldViewParamsComponent, View_NumberFieldViewParamsComponent_Host_0, { field: "field", field$: "field$", configurable: "configurable", control: "control", element: "element", resourceField: "resourceField", context: "context", contextElement: "contextElement", contextElementPath: "contextElementPath", contextElementPaths: "contextElementPaths", analyticsSource: "analyticsSource" }, {}, []);
export { NumberFieldViewParamsComponentNgFactory as NumberFieldViewParamsComponentNgFactory };

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnChanges, OnInit } from '@angular/core';
import isEqual from 'lodash/isEqual';
import { combineLatest, Observable, of } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

import { ViewContextElement } from '@modules/customize';
import { applyParamInput$ } from '@modules/fields';
import { ExitWorkflowStep, WorkflowStepType } from '@modules/workflow';
import { isSet, TypedChanges } from '@shared';

import { registerWorkflowStepComponent } from '../../../../data/workflow-step-components';
import { WorkflowEditContext } from '../../../../services/workflow-edit-context/workflow-edit.context';
import { workflowStepsToken } from '../../workflow/workflow.component';
import { WorkflowStepComponent } from '../base-workflow-step/base-workflow-step.component';

interface State {
  step?: ExitWorkflowStep;
  name?: string;
  title?: string;
  icon?: string;
  type?: WorkflowStepType;
}

@Component({
  selector: 'app-exit-workflow-step',
  templateUrl: './exit-workflow-step.component.html',
  providers: [ViewContextElement],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExitWorkflowStepComponent extends WorkflowStepComponent<ExitWorkflowStep, State>
  implements OnInit, OnChanges {
  title: string;
  icon: string;

  constructor(
    @Inject(workflowStepsToken) private workflowStepsContextElement: ViewContextElement,
    contextElement: ViewContextElement,
    workflowEditContext: WorkflowEditContext,
    cd: ChangeDetectorRef
  ) {
    super(contextElement, workflowEditContext, cd);
  }

  ngOnInit() {
    this.trackRun();
    this.trackExecuteStatus();
    this.stepOnChange(this.step);
    this.trackChanges();

    this.workflowEditContext.updateCreatedElement(this.step, { contextElement: this.contextElement });
  }

  ngOnChanges(changes: TypedChanges<ExitWorkflowStepComponent>): void {
    if (changes.step) {
      if (!changes.step.firstChange) {
        this.stepOnChange(this.step);
      }
    }
  }

  getStepState(step: ExitWorkflowStep): Observable<State> {
    const errorText$ =
      step && step.errorText
        ? applyParamInput$(step.errorText, {
            context: this.context,
            defaultValue: '',
            raiseErrors: false
          }).pipe(distinctUntilChanged((lhs, rhs) => isEqual(lhs, rhs)))
        : of('');

    return combineLatest(errorText$).pipe(
      map(([errorText]) => {
        return {
          step: step,
          name: step ? step.name : undefined,
          title: step ? this.getTitle(errorText) : undefined,
          icon: step ? step.getIcon() : undefined,
          type: step ? step.type : undefined
        };
      })
    );
  }

  onStateUpdated(state: State) {
    this.title = state.title;
    this.icon = this.step.getIcon();
  }

  getTitle(errorText: string) {
    return isSet(errorText) ? errorText : 'No error text';
  }
}

registerWorkflowStepComponent(WorkflowStepType.Exit, ExitWorkflowStepComponent);

import pickBy from 'lodash/pickBy';

import { ViewSettingsAction } from '@modules/actions';
import { ModelDescriptionDataSource } from '@modules/data-sources';
import { Input } from '@modules/fields';
import { ModelDescriptionQuery } from '@modules/queries';

import { migrateModelDescriptionDataSource } from '../../../utils/migration';
import { FieldActions } from '../../field-actions';
import { registerElementForType } from '../element-items';
import { ElementType } from '../element-type';
import { ElementItem } from './base';

export class ModelElementItem extends ElementItem {
  public type = ElementType.Model;
  public titleInput: Input;
  public dataSource: ModelDescriptionDataSource;
  public columnActions: FieldActions[] = [];
  public tooltip: string;

  deserialize(data: Object): ModelElementItem {
    super.deserialize(data);
    this.tooltip = this.params['tooltip'];

    if (this.params['title_input']) {
      this.titleInput = new Input().deserialize(this.params['title_input']);
    } else if (this.params['title']) {
      // Backward compatibility
      this.titleInput = new Input().deserializeFromStatic('value', this.params['title']);
    }

    if (this.params['data_source']) {
      this.dataSource = new ModelDescriptionDataSource().deserialize(this.params['data_source']);
    } else if (this.params['resource']) {
      // Backward compatibility
      this.dataSource = migrateModelDescriptionDataSource(ModelDescriptionDataSource, ModelDescriptionQuery, {
        resource: this.params['resource'],
        query: this.params['get_query'],
        parameters: this.params['parameters'],
        inputs: this.params['inputs'],
        columns: this.params['columns']
      });
    }

    if (this.params['column_actions']) {
      this.columnActions = this.params['column_actions']
        .filter(item => item['actions'])
        .map(item => {
          return {
            name: item['name'],
            actions: item['actions'].map(subItem => new ViewSettingsAction().deserialize(subItem))
          };
        });
    } else if (this.params['data_source'] && this.params['data_source']['columns']) {
      // Backward compatibility
      this.columnActions = this.params['data_source']['columns']
        .filter(item => item['action'])
        .map(item => {
          return {
            name: item['name'],
            actions: [new ViewSettingsAction().deserialize(item['action'])]
          };
        });
    }

    return this;
  }

  serialize(fields?: string[]): Object {
    this.params = {};
    this.params['title_input'] = this.titleInput ? this.titleInput.serialize() : null;
    this.params['data_source'] = this.dataSource ? this.dataSource.serialize() : undefined;
    this.params['column_actions'] = this.columnActions.map(item => {
      return {
        name: item.name,
        actions: item.actions.map(action => action.serialize())
      };
    });
    this.params['tooltip'] = this.tooltip;

    let data = super.serialize();
    if (fields) {
      data = <Object>pickBy(data, (v, k) => fields.includes(k));
    }
    return data;
  }

  get typeStr(): string {
    return 'displayed detailed record data';
  }

  get analyticsName(): string {
    return 'detail';
  }

  defaultName() {
    return 'Detail';
  }
}

registerElementForType(ElementType.Model, ModelElementItem);

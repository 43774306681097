import { ChangeDetectionStrategy, Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import clamp from 'lodash/clamp';

import { isSet, parseNumber } from '@shared';

@Component({
  selector: 'app-opacity-control',
  templateUrl: './opacity-control.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => OpacityControlComponent),
      multi: true
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OpacityControlComponent implements OnInit, OnDestroy, ControlValueAccessor {
  @Input() formControl: FormControl;

  valueControl = new FormControl(100);

  onChange = (value: any) => undefined;
  onTouched = () => undefined;

  constructor() {}

  ngOnInit() {}

  ngOnDestroy(): void {}

  registerOnChange(fn: (value: any) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  writeValue(value: any): void {
    if (isSet(value)) {
      const valueNumber = parseNumber(value, 1);
      const cleanValue = Math.round(clamp(valueNumber * 100, 0, 100));

      this.valueControl.patchValue(cleanValue);
    } else {
      this.valueControl.patchValue(100);
    }
  }

  updateValue(value: string) {
    if (isSet(value)) {
      const valueNumber = parseNumber(value, 100);
      const cleanValue = Math.round(clamp(valueNumber, 0, 100)) / 100;

      this.onChange(cleanValue);
    } else {
      this.onChange(1);
    }
  }
}

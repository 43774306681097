import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map, publishLast, refCount, switchMap } from 'rxjs/operators';
import { ApiService } from '@modules/api';
import { EnvironmentObjectMerge } from '../../data/environment-object-merge';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../../api/services/api/api.service";
var EnvironmentObjectMergeService = /** @class */ (function () {
    function EnvironmentObjectMergeService(http, apiService) {
        this.http = http;
        this.apiService = apiService;
    }
    EnvironmentObjectMergeService.prototype.get = function (projectName, params) {
        var _this = this;
        if (params === void 0) { params = {}; }
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.projectMethodURL(projectName, 'environment_object_merges/');
            var headers = new HttpHeaders();
            var httpParams = new HttpParams({ fromObject: params });
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.get(url, { headers: headers, params: httpParams });
        }), map(function (result) { return result.map(function (item) { return new EnvironmentObjectMerge().deserialize(item); }); }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    EnvironmentObjectMergeService.ngInjectableDef = i0.defineInjectable({ factory: function EnvironmentObjectMergeService_Factory() { return new EnvironmentObjectMergeService(i0.inject(i1.HttpClient), i0.inject(i2.ApiService)); }, token: EnvironmentObjectMergeService, providedIn: "root" });
    return EnvironmentObjectMergeService;
}());
export { EnvironmentObjectMergeService };

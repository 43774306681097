var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Type } from '@angular/core';
import pickBy from 'lodash/pickBy';
import { map, publishLast, refCount, switchMap } from 'rxjs/operators';
import { ApiService } from '@modules/api';
import { CentrifugoService } from '@modules/centrifugo';
import { DataSyncJob } from '../../data/data-sync-job';
import { DataSyncJobTask } from '../../data/data-sync-job-task';
import * as i0 from "@angular/core";
import * as i1 from "../../../api/services/api/api.service";
import * as i2 from "@angular/common/http";
import * as i3 from "../../../centrifugo/services/centrifugo/centrifugo.service";
var DataSyncService = /** @class */ (function () {
    function DataSyncService(apiService, http, centrifugoService) {
        this.apiService = apiService;
        this.http = http;
        this.centrifugoService = centrifugoService;
    }
    DataSyncService.prototype.getJobs = function (project, environment, params) {
        var _this = this;
        if (params === void 0) { params = {}; }
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.syncDataEnvironmentMethodURL(project.uniqueName, environment.uniqueName, 'jobs');
            var headers = new HttpHeaders();
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.get(url, { headers: headers, params: params });
        }), map(function (result) { return result.map(function (item) { return new DataSyncJob().deserialize(item); }); }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    DataSyncService.prototype.getJob = function (project, environment, id) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.syncDataEnvironmentMethodURL(project.uniqueName, environment.uniqueName, "jobs/" + id);
            var headers = new HttpHeaders();
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.get(url, { headers: headers });
        }), map(function (result) { return new DataSyncJob().deserialize(result); }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    DataSyncService.prototype.createJob = function (project, environment, job) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.syncDataEnvironmentMethodURL(project.uniqueName, environment.uniqueName, 'jobs');
            var headers = new HttpHeaders();
            var data = {
                resourceUniqueName: job.sourceResource.uniqueName,
                runInterval: job.runInterval,
                useAllModelDescriptions: job.useAllModelDescriptions,
                modelDescriptions: job.modelDescriptions
            };
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.post(url, data, { headers: headers });
        }), map(function (result) { return new DataSyncJob().deserialize(result); }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    DataSyncService.prototype.updateJob = function (project, environment, job, fields, forceUpdate) {
        var _this = this;
        if (forceUpdate === void 0) { forceUpdate = false; }
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.syncDataEnvironmentMethodURL(project.uniqueName, environment.uniqueName, "jobs/" + job.id);
            var headers = new HttpHeaders();
            var data = {
                active: job.active,
                runInterval: job.runInterval,
                readMode: job.readMode,
                writeMode: job.writeMode
            };
            headers = _this.apiService.setHeadersToken(headers);
            if (fields) {
                data = pickBy(data, function (v, k) { return fields.includes(k); });
            }
            data['forceUpdate'] = forceUpdate;
            return _this.http.patch(url, data, { headers: headers });
        }), map(function (result) { return new DataSyncJob().deserialize(result); }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    DataSyncService.prototype.deleteJob = function (project, environment, job) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.syncDataEnvironmentMethodURL(project.uniqueName, environment.uniqueName, "jobs/" + job.id);
            var headers = new HttpHeaders();
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.delete(url, { headers: headers });
        }), map(function () { return true; }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    DataSyncService.prototype.runJob = function (project, environment, id, recreateStructure) {
        var _this = this;
        if (recreateStructure === void 0) { recreateStructure = false; }
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.syncDataEnvironmentMethodURL(project.uniqueName, environment.uniqueName, "jobs/" + id + "/run");
            var headers = new HttpHeaders();
            var data = {
                recreateStructure: recreateStructure
            };
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.post(url, data, { headers: headers });
        }), map(function (result) { return new DataSyncJob().deserialize(result); }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    DataSyncService.prototype.subscribeJob = function (project, environment, job) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var channel = ['$data_sync_job:data_sync_job', project.uniqueName, environment.uniqueName, job.id].join('/');
            return _this.centrifugoService.subscribeState(channel).pipe(map(function (msg) { return new DataSyncJob().deserialize(msg.data); }));
        }));
    };
    DataSyncService.prototype.getJobTasks = function (project, environment, jobId, params) {
        var _this = this;
        if (params === void 0) { params = {}; }
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.syncDataEnvironmentMethodURL(project.uniqueName, environment.uniqueName, "jobs/" + jobId + "/tasks");
            var headers = new HttpHeaders();
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.get(url, { headers: headers, params: params });
        }), map(function (result) { return new DataSyncService.GetResponse(DataSyncJobTask).deserialize(result); }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    DataSyncService.prototype.getJobTaskLogs = function (project, environment, taskId, params) {
        var _this = this;
        if (params === void 0) { params = {}; }
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.syncDataEnvironmentMethodURL(project.uniqueName, environment.uniqueName, "tasks/" + taskId + "/logs");
            var headers = new HttpHeaders();
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.get(url, { headers: headers, params: params, responseType: 'text' });
        }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    DataSyncService.prototype.stopJobTask = function (project, environment, taskId) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.syncDataEnvironmentMethodURL(project.uniqueName, environment.uniqueName, "tasks/" + taskId + "/stop");
            var headers = new HttpHeaders();
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.post(url, {}, { headers: headers });
        }), map(function () { return true; }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    DataSyncService.prototype.subscribeJobTask = function (task) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var channel = ['$data_sync_task:data_sync_task', task.id].join('/');
            return _this.centrifugoService
                .subscribeState(channel)
                .pipe(map(function (msg) { return new DataSyncJobTask().deserialize(msg.data); }));
        }));
    };
    DataSyncService.prototype.updateRecord = function (project, environment, resourceUniqueName, modelDescriptionUniqueName, primaryKey, params) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.syncDataEnvironmentMethodURL(project.uniqueName, environment.uniqueName, 'update_record');
            var headers = new HttpHeaders();
            var data = __assign({ resourceUniqueName: resourceUniqueName, collectionName: modelDescriptionUniqueName, primaryKeyValue: primaryKey }, (params && { params: params }));
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.post(url, data, { headers: headers });
        }), map(function () { return true; }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    DataSyncService.ngInjectableDef = i0.defineInjectable({ factory: function DataSyncService_Factory() { return new DataSyncService(i0.inject(i1.ApiService), i0.inject(i2.HttpClient), i0.inject(i3.CentrifugoService)); }, token: DataSyncService, providedIn: "root" });
    return DataSyncService;
}());
export { DataSyncService };
(function (DataSyncService) {
    var GetResponse = /** @class */ (function () {
        function GetResponse(cls) {
            this.cls = cls;
        }
        GetResponse.prototype.deserialize = function (data) {
            var _this = this;
            this.results = data['results'].map(function (item) { return new _this.cls().deserialize(item); });
            this.count = data['count'];
            this.perPage = data['per_page'];
            this.numPages = data['num_pages'];
            return this;
        };
        return GetResponse;
    }());
    DataSyncService.GetResponse = GetResponse;
})(DataSyncService || (DataSyncService = {}));

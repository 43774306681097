import { Injectable } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup } from '@angular/forms';

import { TimeOutputFormat } from '@modules/fields';

@Injectable()
export class TimeFieldDataParamsForm {
  form: FormGroup;
  control: AbstractControl;

  outputFormat = [
    { value: TimeOutputFormat.ISO, name: 'hh:mm:ss' },
    { value: TimeOutputFormat.Number, name: 'Google Sheets' }
  ];

  constructor(private fb: FormBuilder) {
    this.form = this.fb.group({
      output_format: new FormControl(TimeOutputFormat.ISO)
    });
  }

  init(control: AbstractControl) {
    this.control = control;

    if (control.value) {
      this.form.patchValue(control.value, { emitEvent: false });
    }

    this.form.valueChanges.subscribe(() => this.submit());
  }

  submit() {
    const data = this.form.value;

    this.control.patchValue({
      ...this.control.value,
      ...data
    });
  }
}

<app-compact-input
  [formControl]="valueControl"
  [postfix]="'%'"
  [placeholder]="'100'"
  [widthPx]="widthPx"
  [number]="true"
  [numberOptions]="{ min: 0, max: 100 }"
  [numberDefault]="100"
  (change)="updateValue(valueControl.value)"
></app-compact-input>

import { ElementRef, OnChanges, OnInit } from '@angular/core';
import { isSet } from '@shared';
var GridStubItemComponent = /** @class */ (function () {
    function GridStubItemComponent(el) {
        this.el = el;
        this.columns = 5;
        this.rowCards = 1;
        this.animating = false;
        this.snap = false;
        this.snapMargin = false;
        this.effectiveHeight = 245;
        this.cls = true;
    }
    Object.defineProperty(GridStubItemComponent.prototype, "snapCls", {
        get: function () {
            return this.snap;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GridStubItemComponent.prototype, "snapMarginCls", {
        get: function () {
            return this.snapMargin;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GridStubItemComponent.prototype, "dataGridCards", {
        get: function () {
            return isSet(this.rowCards) ? true : null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GridStubItemComponent.prototype, "paddingLeftPx", {
        get: function () {
            return isSet(this.gapHorizontal) ? this.gapHorizontal * 0.5 : null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GridStubItemComponent.prototype, "paddingRightPx", {
        get: function () {
            return isSet(this.gapHorizontal) ? this.gapHorizontal * 0.5 : null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GridStubItemComponent.prototype, "paddingTopPx", {
        get: function () {
            return isSet(this.gapVertical) ? this.gapVertical * 0.5 : null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(GridStubItemComponent.prototype, "paddingBottomPx", {
        get: function () {
            return isSet(this.gapVertical) ? this.gapVertical * 0.5 : null;
        },
        enumerable: true,
        configurable: true
    });
    GridStubItemComponent.prototype.ngOnInit = function () { };
    GridStubItemComponent.prototype.ngOnChanges = function (changes) {
        if (changes.columns || changes.height) {
            if (isSet(this.height)) {
                this.effectiveHeight = this.height;
            }
            else if (this.columns) {
                this.effectiveHeight = this.columns * 41.5 + (this.columns + 1) * 15;
            }
            else {
                this.effectiveHeight = 245;
            }
        }
        if (changes.rowCards) {
            if (this.rowCards) {
                this.el.nativeElement.style.setProperty('--card-width', 100 / this.rowCards + "%");
            }
            else {
                this.el.nativeElement.style.removeProperty('--card-width');
            }
        }
    };
    return GridStubItemComponent;
}());
export { GridStubItemComponent };

var _a, _b, _c;
export var UserActivityType;
(function (UserActivityType) {
    UserActivityType["DashboardList"] = "dashboard_list";
    UserActivityType["ModelList"] = "model_list";
    UserActivityType["ModelDetail"] = "model_detail";
    UserActivityType["ModelCreate"] = "model_create";
    UserActivityType["ModelUpdate"] = "model_update";
    UserActivityType["ModelDelete"] = "model_delete";
    UserActivityType["ModelMassDelete"] = "model_mass_delete";
    UserActivityType["ModelMassEdit"] = "model_mass_edit";
    UserActivityType["Message"] = "message";
})(UserActivityType || (UserActivityType = {}));
export var userActivityTypeLabels = (_a = {},
    _a[UserActivityType.DashboardList] = 'view dashboard',
    _a[UserActivityType.ModelList] = 'view collection',
    _a[UserActivityType.ModelDetail] = 'view record',
    _a[UserActivityType.ModelCreate] = 'create record',
    _a[UserActivityType.ModelUpdate] = 'update record',
    _a[UserActivityType.ModelDelete] = 'delete record',
    _a[UserActivityType.ModelMassDelete] = 'delete multiple records',
    _a);
export var userActivityTypeDescriptions = (_b = {},
    _b[UserActivityType.DashboardList] = function (ctx) { return ctx.user + " viewed dashboard"; },
    _b[UserActivityType.ModelList] = function (ctx) { return ctx.user + " viewed collection"; },
    _b[UserActivityType.ModelDetail] = function (ctx) { return ctx.user + " viewed record"; },
    _b[UserActivityType.ModelCreate] = function (ctx) { return ctx.user + " created record"; },
    _b[UserActivityType.ModelUpdate] = function (ctx) { return ctx.user + " updated record"; },
    _b[UserActivityType.ModelDelete] = function (ctx) { return ctx.user + " deleted record"; },
    _b[UserActivityType.ModelMassDelete] = function (ctx) { return ctx.user + " delete multiple records"; },
    _b);
export var userActivityTypeIcons = (_c = {},
    _c[UserActivityType.DashboardList] = 'chart',
    _c[UserActivityType.ModelList] = 'eye',
    _c[UserActivityType.ModelDetail] = 'eye',
    _c[UserActivityType.ModelCreate] = 'new_document',
    _c[UserActivityType.ModelUpdate] = 'pen',
    _c[UserActivityType.ModelDelete] = 'bin',
    _c[UserActivityType.ModelMassDelete] = 'delete',
    _c);
export var messageType = [UserActivityType.Message];
export var actionType = [
    UserActivityType.DashboardList,
    UserActivityType.ModelList,
    UserActivityType.ModelDetail,
    UserActivityType.ModelCreate,
    UserActivityType.ModelUpdate,
    UserActivityType.ModelDelete,
    UserActivityType.ModelMassDelete,
    UserActivityType.ModelMassEdit
];

import { Pipe, PipeTransform } from '@angular/core';

import { capitalize } from '@shared/utils/text/text';

@Pipe({
  name: 'appEmailName'
})
export class EmailNamePipe implements PipeTransform {
  transform(value: string, args?: any): any {
    if (value) {
      return value
        .split('@')[0]
        .split('.')
        .map(word => capitalize(word))
        .join(' ');
    }
    return null;
  }
}

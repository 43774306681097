import { CdkConnectedOverlay } from '@angular/cdk/overlay';
import { AfterViewInit, ChangeDetectorRef, ElementRef, Injector, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject } from 'rxjs';
import { SimplePopupComponent } from '@common/dialog-popup';
import { PopupService } from '@common/popups';
import { ImageFit } from '@modules/fields';
import { ProjectApiService } from '@modules/project-api';
import { Resource } from '@modules/projects';
import { getImageSize } from '@shared';
import { ImageFieldType } from '../../image-field/image-field.types';
import { LightboxComponent } from '../../image-field/lightbox/lightbox.component';
var ImagePreviewComponent = /** @class */ (function () {
    function ImagePreviewComponent(popupService, domSanitizer, apiService, cd, injector) {
        this.popupService = popupService;
        this.domSanitizer = domSanitizer;
        this.apiService = apiService;
        this.cd = cd;
        this.injector = injector;
        this.noBackground = false;
        this.background = false;
        this.resize = false;
        this.lightBox = false;
        this.preview = false;
        this.compact = false;
        this.previewVisible$ = new BehaviorSubject(false);
        this.popoverPositions = [
            {
                panelClass: ['overlay_position_center-top'],
                originX: 'center',
                overlayX: 'start',
                originY: 'top',
                overlayY: 'bottom',
                offsetX: 0,
                offsetY: -10,
                weight: 4
            },
            {
                panelClass: ['overlay_position_center-bottom'],
                originX: 'center',
                overlayX: 'start',
                originY: 'bottom',
                overlayY: 'top',
                offsetX: 0,
                offsetY: 10,
                weight: 3
            },
            {
                panelClass: ['overlay_position_right-center'],
                originX: 'end',
                overlayX: 'start',
                originY: 'center',
                overlayY: 'top',
                offsetX: 10,
                offsetY: 0,
                weight: 2
            },
            {
                panelClass: ['overlay_position_left-center'],
                originX: 'start',
                overlayX: 'end',
                originY: 'top',
                overlayY: 'top',
                offsetX: -10,
                offsetY: 0,
                weight: 1
            }
        ];
        this.imageFits = ImageFit;
    }
    ImagePreviewComponent.prototype.ngOnInit = function () { };
    ImagePreviewComponent.prototype.ngOnDestroy = function () { };
    ImagePreviewComponent.prototype.ngOnChanges = function (changes) {
        var _this = this;
        if (changes['value']) {
            getImageSize(this.value)
                .pipe(untilDestroyed(this))
                .subscribe(function (size) {
                _this.imageWidth = size.width;
                _this.imageHeight = size.height;
            }, function () {
                _this.imageWidth = undefined;
                _this.imageHeight = undefined;
            });
        }
    };
    ImagePreviewComponent.prototype.ngAfterViewInit = function () {
        this.setPositionObserver();
    };
    ImagePreviewComponent.prototype.openLightbox = function (event, force) {
        if (force === void 0) { force = false; }
        if (!this.url) {
            return;
        }
        if (!this.lightBox && !force) {
            return;
        }
        event.stopPropagation();
        this.popupService.push({
            component: LightboxComponent,
            popupComponent: SimplePopupComponent,
            inputs: {
                url: this.url,
                origin: this.previewImg.nativeElement
            },
            injector: this.injector
        });
    };
    Object.defineProperty(ImagePreviewComponent.prototype, "previewBackgroundImage", {
        get: function () {
            if (!this.url) {
                return;
            }
            var img = this.url;
            // const apiInfo = this.getApiInfo();
            var apiInfo = this.storageResource ? this.storageResource.apiInfo : undefined;
            if (this.resize && apiInfo && apiInfo.isCompatibleJetBridge({ jetBridge: '0.0.6', jetDjango: '0.7.7' })) {
                img = this.apiService.methodURLForProjectResource('image_resize/', this.storageResource) + "?path=" + encodeURIComponent(this.value);
            }
            return this.domSanitizer.bypassSecurityTrustStyle("url('" + img + "')");
        },
        enumerable: true,
        configurable: true
    });
    ImagePreviewComponent.prototype.isLargerPreviewAvailable = function () {
        return (this.imageWidth &&
            this.imageHeight &&
            (this.imageWidth > this.previewImg.nativeElement.offsetWidth ||
                this.imageHeight > this.previewImg.nativeElement.offsetHeight) &&
            (this.previewImg.nativeElement.offsetWidth < 320 || this.previewImg.nativeElement.offsetHeight < 240));
    };
    ImagePreviewComponent.prototype.showPreview = function () {
        if (this.preview && this.isLargerPreviewAvailable()) {
            this.previewVisible$.next(true);
        }
    };
    ImagePreviewComponent.prototype.hidePreview = function () {
        this.previewVisible$.next(false);
    };
    ImagePreviewComponent.prototype.setPositionObserver = function () {
        var _this = this;
        if (!this.cdkConnectedOverlay) {
            return;
        }
        this.cdkConnectedOverlay.positionChange
            .pipe(untilDestroyed(this))
            .subscribe(function (e) {
            var propsEqual = ['offsetX', 'offsetY', 'originX', 'originY', 'overlayX', 'overlayY'];
            var position = _this.popoverPositions.find(function (item) {
                return propsEqual.every(function (prop) { return (item[prop] || undefined) == e.connectionPair[prop]; });
            });
            var otherPosition = _this.popoverPositions.filter(function (item) { return item !== position; });
            if (position) {
                _this.cdkConnectedOverlay.overlayRef.addPanelClass(position.panelClass);
            }
            otherPosition.forEach(function (item) { return _this.cdkConnectedOverlay.overlayRef.removePanelClass(item.panelClass); });
        });
    };
    return ImagePreviewComponent;
}());
export { ImagePreviewComponent };

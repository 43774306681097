<svg [attr.width]="size" [attr.height]="size" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
  <g fill="none" fill-rule="evenodd">
    <path
      d="m8.99961383.89395098.00038617 13.42052272-4.75107018 2.9346326c-.08916327.0550742-.19637966.0729556-.29859024.0497984-.21545336-.0488139-.35054133-.2630449-.30172742-.4784983l1.25638784-5.5454064-4.21147966-3.74060013c-.07632947-.06779518-.12384815-.16220994-.13282251-.26390474-.01941973-.22005869.14323028-.41419431.36328896-.43361404l5.54701321-.48951267 2.1595208-5.20656145c.04059369-.09787059.11836006-.17563696.21623064-.21623065.05003339-.0207523.10186888-.03059617.15286239-.03062534z"
      [attr.fill]="leftActive ? activeColor : normalColor"
    />
    <path
      d="m9.00058763.89395161c.15662212.00029414.30513915.09314953.36889157.24685536l2.1595208 5.20656145 5.5458557.48941052c.1018201.00898542.1963372.05660953.2641463.13309479.1465525.16530374.1313516.41811315-.0339522.56466561l-4.2190498 3.74046066 1.2637664 5.5439618c.023333.1023583.0054892.2097867-.0496812.2991057-.1160929.1879505-.3625688.2462026-.5505193.1301097l-4.7495659-2.9337035z"
      [attr.fill]="rightActive ? activeColor : normalColor"
    />
  </g>
</svg>

import { HttpErrorResponse, HttpResponseBase } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges
} from '@angular/core';
import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';
import isPlainObject from 'lodash/isPlainObject';
import keys from 'lodash/keys';

import { HttpResponseType } from '@modules/queries';
import { limitObjectLength } from '@shared';

@Component({
  selector: 'app-query-builder-data-response',
  templateUrl: './query-builder-data-response.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class QueryBuilderDataResponseComponent implements OnInit, OnChanges {
  @Input() response: HttpResponseBase;
  @Input() responseBody: any;
  @Input() responseType: HttpResponseType;
  @Input() animating = false;

  columns: string[];
  rows: any[][];
  error = false;
  responseTypes = HttpResponseType;

  constructor(private cd: ChangeDetectorRef) {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['responseType'] && !this.responseType) {
      this.responseType = HttpResponseType.JSON;
    }

    if (changes['response'] || changes['responseBody'] || changes['responseType']) {
      this.updateResponse();
    }
  }

  updateResponse() {
    if (
      (!this.response && !this.responseBody) ||
      (this.responseType && ![HttpResponseType.JSON, HttpResponseType.XML].includes(this.responseType))
    ) {
      this.columns = undefined;
      this.rows = undefined;
      this.cd.markForCheck();
      return;
    }

    let responseDisplay: any;

    if (this.response instanceof HttpErrorResponse) {
      responseDisplay = this.response.error;
      this.error = true;
    } else {
      responseDisplay = this.responseBody;
      this.error = false;
    }

    const response = limitObjectLength(responseDisplay, 20);
    let columns;
    let rows;

    if (response instanceof Blob) {
      columns = undefined;
      rows = [[response]];
    } else if (isArray(response) && response.some(item => isPlainObject(item))) {
      columns = keys(
        response.reduce((prev, current) => {
          keys(current).forEach(key => (prev[key] = true));
          return prev;
        }, {})
      );
      rows = response.map(item => {
        return columns.map(column => item[column]);
      });
    } else if (isArray(response) && response.every(item => !isPlainObject(item))) {
      columns = undefined;
      rows = response.map(item => [item]);
    } else if (isObject(response)) {
      columns = keys(response);
      rows = [columns.map(column => response[column])];
    } else {
      columns = undefined;
      rows = [[response]];
    }

    this.columns = columns;
    this.rows = rows
      ? rows.map(row =>
          row.map(column => {
            if (isObject(column)) {
              return JSON.stringify(column);
            } else {
              return column;
            }
          })
        )
      : undefined;
    this.cd.markForCheck();
  }
}

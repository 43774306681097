import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map, publishLast, refCount, switchMap } from 'rxjs/operators';
import { ApiService } from '@modules/api';
import { Plan } from '../../data/plan';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../../api/services/api/api.service";
var PlanService = /** @class */ (function () {
    function PlanService(http, apiService) {
        this.http = http;
        this.apiService = apiService;
    }
    PlanService.prototype.get = function (params) {
        var _this = this;
        if (params === void 0) { params = {}; }
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.methodURL('plans/');
            var headers = new HttpHeaders();
            var httpParams = new HttpParams({ fromObject: params });
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.get(url, { headers: headers, params: httpParams });
        }), map(function (result) { return result.map(function (item) { return new Plan().deserialize(item); }); }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    PlanService.prototype.getDetail = function (uniqueName) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.methodURL("plans/" + uniqueName + "/");
            var headers = new HttpHeaders();
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.get(url, { headers: headers });
        }), map(function (result) { return new Plan().deserialize(result); }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    PlanService.ngInjectableDef = i0.defineInjectable({ factory: function PlanService_Factory() { return new PlanService(i0.inject(i1.HttpClient), i0.inject(i2.ApiService)); }, token: PlanService, providedIn: "root" });
    return PlanService;
}());
export { PlanService };

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Injector,
  Input,
  OnDestroy,
  OnInit
} from '@angular/core';
import fromPairs from 'lodash/fromPairs';
import toPairs from 'lodash/toPairs';
import { untilDestroyed } from 'ngx-take-until-destroy';

import { copyTextToClipboard } from '@common/code';
import { NotificationService } from '@common/notifications';
import { ApiService } from '@modules/api';
import { ApiKey, ApiKeyService } from '@modules/api-keys';
import { ModelService } from '@modules/model-queries';
import { Model, ModelDescription } from '@modules/models';
import { CurrentEnvironmentStore, CurrentProjectStore, Resource, ResourceName } from '@modules/projects';
import { capitalize, indent, isSet } from '@shared';

import { ApiParameter } from '../../../data/api-parameter';
import { ApiParameterType } from '../../../data/api-parameter-type';

@Component({
  selector: 'app-model-api-base-query',
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModelApiBaseQueryComponent implements OnInit, OnDestroy {
  @Input() resource: Resource;
  @Input() modelDescription: ModelDescription;
  @Input() serializer: (model: Model) => Object;
  @Input() overrideParameters: ApiParameter[];
  @Input() section = true;

  headerParametersLoading = false;
  modelApi = true;
  model: Model;
  modelCount: number;
  modelNext: string;
  curl: string;
  headerParameters: ApiParameter[] = [];
  indentSize = 2;

  constructor(
    protected notificationService: NotificationService,
    protected apiService: ApiService,
    protected apiKeyService: ApiKeyService,
    protected modelService: ModelService,
    protected currentProjectStore: CurrentProjectStore,
    protected currentEnvironmentStore: CurrentEnvironmentStore,
    protected injector: Injector,
    protected cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.fetchHeaderParameters();
  }

  ngOnDestroy(): void {}

  fetchHeaderParameters() {
    this.headerParametersLoading = true;
    this.cd.markForCheck();

    this.apiKeyService
      .get(this.currentProjectStore.instance, this.currentEnvironmentStore.instance)
      .pipe(untilDestroyed(this))
      .subscribe(
        result => {
          this.headerParameters = this.getHeaderParameters(result);
          this.headerParametersLoading = false;
          this.updateCurl();
          this.cd.markForCheck();
        },
        () => {
          this.headerParametersLoading = false;
          this.cd.markForCheck();
        }
      );
  }

  getHeaderParameters(apiKeys: ApiKey[]): ApiParameter[] {
    return [
      {
        type: ApiParameterType.Header,
        name: 'Authorization',
        description: `
          ${this.resource.name} API uses static tokens for authorization. <strong>Be sure to keep them secure!</strong><br>
          You can create as many API tokens as you need for different applications and purposes.
        `,
        value: apiKeys.length ? `Bearer ${apiKeys[0].token}` : '****************',
        required: true,
        apiKey: true
      }
    ];
  }

  getFieldParamName(fieldName: string, lookup?: string): string {
    const parts = [fieldName];

    if (lookup) {
      parts.push(lookup);
    }

    return parts.join('__');
  }

  getCurlRequest(options: { url: string; method?: string; params?: Record<string, string>; data?: Object }) {
    const urlParts = [options.url];

    if (options.params) {
      const queryString = toPairs(options.params)
        .map(([k, v]) => `${k}=${v}`)
        .join('&');
      urlParts.push(queryString);
    }

    const url = urlParts.join('?');
    const result = [`curl '${url}'`];

    if (options.method) {
      result.push(indent(`-X ${options.method.toUpperCase()}`, this.indentSize));
    }

    const headers: ApiParameter[] = [...this.headerParameters];

    if (options.data) {
      headers.push({ type: ApiParameterType.Header, name: 'Content-Type', value: 'application/json' });
    }

    result.push(
      ...headers.map(item => {
        return indent(`-H '${item.name}: ${item.value}'`, this.indentSize);
      })
    );

    if (options.data) {
      result.push(indent(`-d '${JSON.stringify(options.data)}'`, this.indentSize));
    }

    return result.join(' \\\n');
  }

  fetchModel() {
    this.modelService
      .get(this.currentProjectStore.instance, this.currentEnvironmentStore.instance, this.modelDescription.modelId)
      .pipe(untilDestroyed(this))
      .subscribe(result => {
        this.model = result.results[0];
        this.modelCount = result.count;
        // this.modelNext = result.next || null;
        this.cd.markForCheck();

        this.onModelFetched();
      });
  }

  updateCurl() {}

  onModelFetched() {}

  copy(text: string, contentLabel?: string) {
    copyTextToClipboard(text)
      .pipe(untilDestroyed(this))
      .subscribe(success => {
        if (!success) {
          return;
        }

        const description = isSet(contentLabel) ? `${capitalize(contentLabel)} was copied to clipboard` : undefined;
        this.notificationService.info('Copied', description);
      });
  }

  getApiBaseUrl(): string {
    return this.apiService.dataSourcesEnvironmentMethodURL(
      this.currentProjectStore.instance.uniqueName,
      this.currentEnvironmentStore.instance.uniqueName,
      this.modelApi
        ? `${this.resource.uniqueName}/models/${this.modelDescription.model}`
        : `${this.resource.uniqueName}`
    );
  }

  getQueryUrl(): string {
    return undefined;
  }

  getAbsoluteApiUrl(): string {
    return [this.getApiBaseUrl(), this.getQueryUrl()].join('');
  }

  getUrlPath(): string {
    const url = this.getAbsoluteApiUrl();

    if (!isSet(url)) {
      return url;
    }

    return new URL(url).pathname;
  }

  getPrimaryKey(): string {
    if (this.resource.typeItem.name == ResourceName.Firebase) {
      // TODO: Move __jet_item_pk__, __parent__ out of generators
      return '__jet_item_pk__';
    } else if (isSet(this.modelDescription.primaryKeyField)) {
      return this.modelDescription.primaryKeyField;
    } else {
      return 'id';
    }
  }

  getParameters(): ApiParameter[] {
    if (this.overrideParameters) {
      return this.overrideParameters;
    } else {
      return this.getParametersAuto();
    }
  }

  getParametersAuto(): ApiParameter[] {
    return [];
  }

  getRequestData(): Object {
    const parameters = this.getParameters().filter(item => item.type == ApiParameterType.Body);

    return fromPairs(
      parameters.map(item => {
        let modelValue: any;

        if (this.model && item['getter']) {
          modelValue = (item as ApiParameter).getter(this.model);
        } else if (this.model) {
          modelValue = this.model.getAttribute(item.name);
        }

        const value = isSet(modelValue) ? modelValue : '...';
        return [item.name, value];
      })
    );
  }

  getDefaultModel(): Model {
    const model = new Model(this.injector);
    this.modelDescription.dbFields.forEach(item => {
      model.setRawAttribute(item.name, '...');
    });
    return model;
  }

  serializeDefault(model: Model): Object {
    return fromPairs(
      this.modelDescription.dbFields.map(item => {
        const value = model.getRawAttribute(item.name);
        return [item.name, value];
      })
    );
  }
}

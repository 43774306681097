import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { FieldComponentsModule } from '@modules/field-components';
import { FieldsModule } from '@modules/fields';
import { SharedModule } from '@shared';
import { UiModule } from '@ui';

import { ProjectDomainComponent } from './components/project-domain/project-domain.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    UiModule,
    FieldsModule,
    FieldComponentsModule,
    SharedModule
  ],
  declarations: [ProjectDomainComponent],
  exports: [ProjectDomainComponent],
  entryComponents: [ProjectDomainComponent]
})
export class DomainComponentsModule {}

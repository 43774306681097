var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Domain } from '@modules/domain';
import { ThemeOptionsDirective } from '../theme-options/theme-options.directive';
var DomainThemeOptionsDirective = /** @class */ (function (_super) {
    __extends(DomainThemeOptionsDirective, _super);
    function DomainThemeOptionsDirective() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    DomainThemeOptionsDirective.prototype.getThemeOptions = function () {
        if (this.domain) {
            return {
                accentColor: this.domain.signUpAccentColor,
                fontRegular: this.domain.signUpFontRegular,
                fontHeading: this.domain.signUpFontHeading
            };
        }
        else {
            return {
                accentColor: this.accentColor,
                fontRegular: this.fontRegular,
                fontHeading: this.fontHeading
            };
        }
    };
    return DomainThemeOptionsDirective;
}(ThemeOptionsDirective));
export { DomainThemeOptionsDirective };

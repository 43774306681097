import { AfterViewInit, Directive, ElementRef, Inject, Input, OnDestroy, ViewRef } from '@angular/core';
import clamp from 'lodash/clamp';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { fromEvent, interval, merge, of, Subscription } from 'rxjs';

import { addClass, getWindowScrollingElement, MouseButton, nodeListToArray, removeClass } from '@shared';

import { DraggableService } from '../../services/draggable/draggable.service';
import { DraggableDirective } from '../draggable/draggable.directive';

const ignoreDragDropProperty = '_ignoreDragDrop';

export function ignoreDragDrop(mouseDownEvent: MouseEvent) {
  mouseDownEvent[ignoreDragDropProperty] = true;
}

export function isDragDropIgnored(mouseDownEvent: MouseEvent) {
  return !!mouseDownEvent[ignoreDragDropProperty];
}

@Directive({
  selector: '[appDraggableItem]',
  exportAs: 'draggableItem'
})
export class DraggableItemDirective implements OnDestroy, AfterViewInit {
  @Input('appDraggableItemData') data: any;
  @Input('appDraggableItemOptions') options = {};

  draggingRequested = false;
  draggingRequestedStartPosition;

  dragging = false;
  draggingStartPosition;
  draggingStartDelta;
  draggingFrom: DraggableDirective;
  mirror: HTMLElement;
  elementSubscriptions: Subscription[] = [];
  scrollInterval: Subscription;
  copied = false;

  viewRef: ViewRef;

  constructor(
    public el: ElementRef,
    private draggableService: DraggableService,
    @Inject(DraggableDirective) public parent: DraggableDirective
  ) {}

  ngOnDestroy(): void {
    // this.parent.removeItem(this);
    this.stopScroll();
  }

  ngAfterViewInit(): void {
    this.init();
  }

  handleMatches(el: any, selector: string, selectorDisable: string, end: any) {
    if (!(el instanceof HTMLElement)) {
      return false;
    }

    if (el['matches'](selector)) {
      return true;
    }

    if (selectorDisable && el['matches'](selectorDisable)) {
      return false;
    }

    if (!el.parentNode || el.parentNode == end) {
      return false;
    }

    return this.handleMatches(el.parentNode, selector, selectorDisable, end);
  }

  deinit() {
    this.elementSubscriptions.forEach(item => item.unsubscribe());
    this.elementSubscriptions = [];
  }

  init() {
    this.deinit();

    if (this.dragging) {
      this.el.nativeElement.classList.add('dragging');
    }

    this.elementSubscriptions.push(
      fromEvent<MouseEvent>(this.el.nativeElement, 'mousedown')
        .pipe(untilDestroyed(this))
        .subscribe(e => {
          if (isDragDropIgnored(e)) {
            return;
          }

          if (e.button != MouseButton.Main) {
            return;
          }

          if (this.parent.options['isDraggable'] && !this.parent.options['isDraggable'](this, this.parent)) {
            return;
          }

          if (
            this.parent.options['handle'] &&
            !this.handleMatches(
              e.target,
              this.parent.options['handle'],
              this.parent.options['handleDisable'],
              this.el.nativeElement
            )
          ) {
            return;
          }

          e.preventDefault();
          e.stopPropagation();

          const bounds = this.el.nativeElement.getBoundingClientRect();

          this.draggingRequested = true;
          this.draggingRequestedStartPosition = { x: e.clientX, y: e.clientY };
          this.draggingStartDelta = { x: e.clientX - bounds.left, y: e.clientY - bounds.top };
        })
    );

    this.elementSubscriptions.push(
      fromEvent<MouseEvent>(window.document, 'mousemove')
        .pipe(untilDestroyed(this))
        .subscribe(e => {
          if (!this.draggingRequested) {
            return;
          }

          if (
            !this.dragging &&
            (Math.abs(this.draggingRequestedStartPosition.x - e.clientX) >= 5 ||
              Math.abs(this.draggingRequestedStartPosition.y - e.clientY) >= 5)
          ) {
            this.dragging = true;
            this.draggingStartPosition = { x: e.clientX, y: e.clientY };
            this.draggingFrom = this.parent;

            this.parent.dragChanged.emit(true);
            this.parent.dragStarted.emit(this);
          }

          if (!this.dragging) {
            return;
          }

          if (!this.mirror) {
            const mirror = this.el.nativeElement.cloneNode(true) as HTMLElement;

            mirror.style.position = 'absolute';
            mirror.style.width = `${this.el.nativeElement.offsetWidth}px`;
            mirror.style.height = `${this.el.nativeElement.offsetHeight}px`;
            mirror.classList.add('mirror', `draggable-parent_${this.parent.name}`);
            addClass(document.body, 'movable');

            if (this.options['mirrorClass']) {
              mirror.classList.add(this.options['mirrorClass']);
            }

            document.body.appendChild(mirror);

            this.mirror = mirror;
            this.el.nativeElement.classList.add('dragging');

            if (mirror.tagName == 'TR') {
              const originalChildren = nodeListToArray<HTMLElement>(
                this.el.nativeElement.querySelectorAll(':scope > TD')
              );
              const mirrorChildren = nodeListToArray<HTMLElement>(mirror.querySelectorAll(':scope > TD'));

              mirrorChildren.forEach((item, i) => {
                item.style.width = `${originalChildren[i].offsetWidth}px`;
              });
            }
          }

          this.mirror.style.top = `${e.clientY - this.draggingStartDelta.y + getWindowScrollingElement().scrollTop}px`;
          this.mirror.style.left = `${
            e.clientX - this.draggingStartDelta.x + getWindowScrollingElement().scrollLeft
          }px`;

          const droppable = this.draggableService.items
            .filter(item => {
              return item === this.parent || (this.parent.options['droppable'] || []).find(name => name == item.name);
            })
            .filter(item => {
              if (!item.options['isDroppable']) {
                return true;
              }

              return item.options['isDroppable'](this, this.parent);
            })
            .filter(item => {
              return !this.el.nativeElement.contains(item.el.nativeElement);
            })
            .sort((lhs, rhs) => rhs.depth - lhs.depth)
            .find(item => item.isPointInside(e.clientX, e.clientY));

          if (droppable) {
            if (this.parent !== droppable) {
              droppable.addExternalItem(this);
            }

            droppable.updatePositionFor(this, { mousePosition: { x: e.clientX, y: e.clientY } });
          } else {
          }

          if (this.parent.scrollableParent) {
            const bounds = this.parent.scrollableParent.getBoundingClientRect();
            const scrollDistance = 80;
            const scrollMultiplierUp =
              this.parent.scrollableParent == document.body
                ? clamp((scrollDistance - e.clientY) / scrollDistance, 0, 1)
                : clamp((bounds.top + scrollDistance - e.clientY) / scrollDistance, 0, 1);
            const scrollMultiplierDown =
              this.parent.scrollableParent == document.body
                ? clamp(((window.innerHeight - scrollDistance - e.clientY) / scrollDistance) * -1, 0, 1)
                : clamp(((bounds.bottom - scrollDistance - e.clientY) / scrollDistance) * -1, 0, 1);

            if (scrollMultiplierUp > 0) {
              this.startScroll(false, scrollMultiplierUp);
            } else if (scrollMultiplierDown > 0) {
              this.startScroll(true, scrollMultiplierDown);
            } else {
              this.stopScroll();
            }
          }
        })
    );

    this.elementSubscriptions.push(
      fromEvent<MouseEvent>(window.document, 'mouseup')
        .pipe(untilDestroyed(this))
        .subscribe(e => {
          if (e.button != MouseButton.Main) {
            return;
          }

          this.onDragEnd();
        })
    );

    this.parent.addItem(this, true);
  }

  onDragEnd() {
    this.draggingRequested = false;

    if (!this.dragging) {
      return;
    }

    this.dragging = false;

    if (this.mirror) {
      this.mirror.parentNode.removeChild(this.mirror);
      this.mirror = undefined;
    }
    removeClass(document.body, 'movable');
    this.el.nativeElement.classList.remove('dragging');

    this.draggingFrom.dragChanged.emit(false);
    this.draggingFrom.dragFinished.emit(this);
    this.draggingFrom = undefined;
    this.copied = false;

    this.stopScroll();
  }

  get bounds() {
    return this.mirror ? this.mirror.getBoundingClientRect() : this.el.nativeElement.getBoundingClientRect();
  }

  get elementIndex() {
    if (!this.el.nativeElement.parentNode) {
      return -1;
    }
    return Array.prototype.indexOf.call(this.el.nativeElement.parentNode.children, this.el.nativeElement);
  }

  createDirective(el: ElementRef, parent: DraggableDirective) {
    return new DraggableItemDirective(el, this.draggableService, parent);
  }

  startScroll(down, multiplier) {
    this.stopScroll();

    const maxSpeed = 20;
    const speed = down ? multiplier * maxSpeed : multiplier * maxSpeed * -1;

    this.scrollInterval = merge(of({}), interval(1000 / 60))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        if (!this.parent.scrollableParent) {
          return;
        }

        if (this.parent.scrollableParent == document.body) {
          const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
          document.documentElement.scrollTop = document.body.scrollTop = scrollTop + speed;
        } else {
          this.parent.scrollableParent.scrollTop = this.parent.scrollableParent.scrollTop + speed;
        }
      });
  }

  stopScroll() {
    if (this.scrollInterval) {
      this.scrollInterval.unsubscribe();
      this.scrollInterval = undefined;
    }
  }
}

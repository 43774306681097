var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ChangeDetectorRef, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, of } from 'rxjs';
import { distinctUntilChanged, tap } from 'rxjs/operators';
import { applyParamInput$, FieldType, Input, registerFieldComponent } from '@modules/fields';
import { isSet } from '@shared';
import { FieldComponent } from '../field/field.component';
var SliderFieldComponent = /** @class */ (function (_super) {
    __extends(SliderFieldComponent, _super);
    function SliderFieldComponent(cd) {
        var _this = _super.call(this) || this;
        _this.cd = cd;
        _this.defaultMinValue = 1;
        _this.defaultMaxValue = 10;
        _this.defaultStepSize = 1;
        _this.minValue = _this.defaultMinValue;
        _this.maxValue = _this.defaultMaxValue;
        _this.stepSize = _this.defaultStepSize;
        return _this;
    }
    SliderFieldComponent.prototype.ngOnInit = function () {
        this.updateParams();
        this.initContextObserver();
    };
    SliderFieldComponent.prototype.ngOnDestroy = function () { };
    SliderFieldComponent.prototype.ngOnChanges = function (changes) {
        this.updateParams();
        this.initContextObserver();
    };
    SliderFieldComponent.prototype.updateParams = function () {
        this.minValueLabel = this.field.params['min_value_label'];
        this.maxValueLabel = this.field.params['max_value_label'];
        this.normalValueLabel = this.field.params['normal_label'];
    };
    SliderFieldComponent.prototype.getParams = function () {
        var result = {};
        var params = this.field.params;
        if (params['min_value_input']) {
            result.minValueInput = new Input().deserialize(params['min_value_input']);
        }
        else if (isSet(params['min_value'])) {
            // Backward compatibility
            result.minValueInput = new Input().deserializeFromStatic('value', params['min_value']);
        }
        if (params['max_value_input']) {
            result.maxValueInput = new Input().deserialize(params['max_value_input']);
        }
        else if (isSet(params['max_value'])) {
            // Backward compatibility
            result.maxValueInput = new Input().deserializeFromStatic('value', params['max_value']);
        }
        if (params['step_size_input']) {
            result.stepSizeInput = new Input().deserialize(params['step_size_input']);
        }
        else if (isSet(params['step_size'])) {
            // Backward compatibility
            result.stepSizeInput = new Input().deserializeFromStatic('value', params['step_size']);
        }
        return result;
    };
    SliderFieldComponent.prototype.initContextObserver = function () {
        var _this = this;
        if (this.contextSubscription) {
            this.contextSubscription.unsubscribe();
        }
        var params = this.getParams();
        var minValue$ = params.minValueInput
            ? applyParamInput$(params.minValueInput, {
                context: this.context,
                defaultValue: null
            }).pipe(distinctUntilChanged())
            : of(null);
        var maxValue$ = params.maxValueInput
            ? applyParamInput$(params.maxValueInput, {
                context: this.context,
                defaultValue: null
            }).pipe(distinctUntilChanged())
            : of(null);
        var stepSize$ = params.stepSizeInput
            ? applyParamInput$(params.stepSizeInput, {
                context: this.context,
                defaultValue: null
            }).pipe(distinctUntilChanged())
            : of(null);
        this.contextSubscription = combineLatest(minValue$.pipe(tap(function (value) {
            _this.minValue = isSet(value) ? value : _this.defaultMinValue;
            _this.cd.markForCheck();
        })), maxValue$.pipe(tap(function (value) {
            _this.maxValue = isSet(value) ? value : _this.defaultMaxValue;
            _this.cd.markForCheck();
        })), stepSize$.pipe(tap(function (value) {
            _this.stepSize = isSet(value) ? value : _this.defaultStepSize;
            _this.cd.markForCheck();
        })))
            .pipe(untilDestroyed(this))
            .subscribe();
    };
    return SliderFieldComponent;
}(FieldComponent));
export { SliderFieldComponent };
registerFieldComponent(FieldType.Slider, SliderFieldComponent);

import pickBy from 'lodash/pickBy';

import { generateAlphanumeric } from '@shared';

import { WorkflowStepType } from '../workflow-step-type';

export class WorkflowStep {
  public uid: string;
  public name: string;
  public type: WorkflowStepType;

  deserialize(data: Object) {
    this.uid = data['uid'];
    this.name = data['name'];
    this.type = data['type'];

    if (!this.uid) {
      this.generateUid();
    }

    return this;
  }

  serialize(fields?: string[]) {
    let data: Object = {
      uid: this.uid,
      name: this.name,
      type: this.type
    };
    if (fields) {
      data = <Object>pickBy(data, (v, k) => fields.includes(k));
    }
    return data;
  }

  generateUid() {
    this.uid = generateAlphanumeric(8, { letterFirst: true });
  }

  defaultName() {
    return 'Step';
  }

  get analyticsName(): string {
    return undefined;
  }

  getIcon() {
    return 'power';
  }
}

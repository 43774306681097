/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./view-editor-navigator-drag-preview.component";
import * as i3 from "../../../../common/drag-drop2/services/drop-list/drop-list.service";
import * as i4 from "../../services/view-editor-context/view-editor.context";
var styles_ViewEditorNavigatorDragPreviewComponent = [];
var RenderType_ViewEditorNavigatorDragPreviewComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ViewEditorNavigatorDragPreviewComponent, data: {} });
export { RenderType_ViewEditorNavigatorDragPreviewComponent as RenderType_ViewEditorNavigatorDragPreviewComponent };
function View_ViewEditorNavigatorDragPreviewComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "div", [["class", "compact-list-item compact-list-item_interactive"]], [[2, "compact-list-item_active", null]], null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "div", [["class", "compact-list-item__left"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "div", [["class", "compact-list-item__icon"]], null, null, null, null, null)), i0.ɵdid(3, 278528, null, 0, i1.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i0.ɵeld(4, 0, null, null, 2, "div", [["class", "compact-list-item__main"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 1, "div", [["class", "compact-list-item__title"]], null, null, null, null, null)), (_l()(), i0.ɵted(6, null, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = "compact-list-item__icon"; var currVal_2 = ("icon-" + _co.layer.icon); _ck(_v, 3, 0, currVal_1, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = true; _ck(_v, 0, 0, currVal_0); var currVal_3 = _co.layer.name; _ck(_v, 6, 0, currVal_3); }); }
function View_ViewEditorNavigatorDragPreviewComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [["class", "compact-list-item compact-list-item_interactive"]], [[2, "compact-list-item_active", null]], null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "div", [["class", "compact-list-item__left"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 0, "div", [["class", "compact-list-item__icon icon-layers"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 2, "div", [["class", "compact-list-item__main"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 1, "div", [["class", "compact-list-item__title"]], null, null, null, null, null)), (_l()(), i0.ɵted(5, null, ["Layers (", ")"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = true; _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.multipleLayers.length; _ck(_v, 5, 0, currVal_1); }); }
export function View_ViewEditorNavigatorDragPreviewComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_ViewEditorNavigatorDragPreviewComponent_1)), i0.ɵdid(1, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ViewEditorNavigatorDragPreviewComponent_2)), i0.ɵdid(3, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.layer; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.multipleLayers; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_ViewEditorNavigatorDragPreviewComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-view-editor-navigator-drag-preview", [], null, null, null, View_ViewEditorNavigatorDragPreviewComponent_0, RenderType_ViewEditorNavigatorDragPreviewComponent)), i0.ɵdid(1, 114688, null, 0, i2.ViewEditorNavigatorDragPreviewComponent, [i3.DropListService, i4.ViewEditorContext], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ViewEditorNavigatorDragPreviewComponentNgFactory = i0.ɵccf("app-view-editor-navigator-drag-preview", i2.ViewEditorNavigatorDragPreviewComponent, View_ViewEditorNavigatorDragPreviewComponent_Host_0, {}, {}, []);
export { ViewEditorNavigatorDragPreviewComponentNgFactory as ViewEditorNavigatorDragPreviewComponentNgFactory };

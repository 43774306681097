/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../section-tab/section-tab.component.ngfactory";
import * as i2 from "../section-tab/section-tab.component";
import * as i3 from "@angular/common";
import * as i4 from "./section-tabs.component";
var styles_SectionTabsComponent = [];
var RenderType_SectionTabsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SectionTabsComponent, data: {} });
export { RenderType_SectionTabsComponent as RenderType_SectionTabsComponent };
function View_SectionTabsComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-section-tab", [["class", "section-tabs__item"]], null, [[null, "selected"]], function (_v, en, $event) { var ad = true; if (("selected" === en)) {
        var pd_0 = (_v.context.$implicit.selected.emit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_SectionTabComponent_0, i1.RenderType_SectionTabComponent)), i0.ɵdid(1, 114688, null, 0, i2.SectionTabComponent, [], { label: [0, "label"], link: [1, "link"], icon: [2, "icon"], active: [3, "active"] }, { selected: "selected" })], function (_ck, _v) { var currVal_0 = _v.context.$implicit.label; var currVal_1 = _v.context.$implicit.link; var currVal_2 = _v.context.$implicit.icon; var currVal_3 = _v.context.$implicit.active; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
export function View_SectionTabsComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "section-tabs"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SectionTabsComponent_1)), i0.ɵdid(2, 278528, null, 0, i3.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.tabComponents; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_SectionTabsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "app-section-tabs", [], null, null, null, View_SectionTabsComponent_0, RenderType_SectionTabsComponent)), i0.ɵdid(1, 2211840, null, 1, i4.SectionTabsComponent, [i0.ChangeDetectorRef], null, null), i0.ɵqud(603979776, 1, { tabComponents: 1 })], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SectionTabsComponentNgFactory = i0.ɵccf("app-section-tabs", i4.SectionTabsComponent, View_SectionTabsComponent_Host_0, {}, {}, []);
export { SectionTabsComponentNgFactory as SectionTabsComponentNgFactory };

import { ChangeDetectorRef, ElementRef, EventEmitter, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import clamp from 'lodash/clamp';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, fromEvent, of, Subject } from 'rxjs';
import { debounceTime, filter } from 'rxjs/operators';
import { addClass, coerceFloat, controlValue, isSet, MouseButton, removeClass } from '@shared';
var SLIDER_DRAGGING_CLASS = 'app-slider-dragging';
var DEFAULT_MIN = 1;
var DEFAULT_MAX = 10;
var DEFAULT_STEP = 1;
var SliderComponent = /** @class */ (function () {
    function SliderComponent(sanitizer, cd) {
        this.sanitizer = sanitizer;
        this.cd = cd;
        this.range = false;
        this.disabled = false;
        this.min = DEFAULT_MIN;
        this.minLabel = 'Min';
        this.max = DEFAULT_MAX;
        this.maxLabel = 'Max';
        this.normalLabel = 'Normal';
        this.step = DEFAULT_STEP;
        this.stickToSteps = false;
        this.debounce = 60;
        this.valueLabel = true;
        this.labels = true;
        this.orange = false;
        this.dark = false;
        this.compact = false;
        this.thumbInside = false;
        this.baseChange = new EventEmitter();
        this.extentChange = new EventEmitter();
        this.setBaseValue = new Subject();
        this.setExtentValue = new Subject();
    }
    SliderComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.setBaseValue.pipe(debounceTime(this.debounce), untilDestroyed(this)).subscribe(function (value) {
            if (_this.baseControl) {
                _this.baseControl.setValue(value);
            }
            _this.baseChange.emit(value);
        });
        this.setExtentValue.pipe(debounceTime(this.debounce), untilDestroyed(this)).subscribe(function (value) {
            if (_this.extentControl) {
                _this.extentControl.setValue(value);
            }
            _this.extentChange.emit(value);
        });
        var baseValue$ = this.baseControl ? controlValue(this.baseControl) : of(this.baseValue);
        var extentValue$ = this.extentControl ? controlValue(this.extentControl) : of(this.extentValue);
        combineLatest(baseValue$, extentValue$)
            .pipe(untilDestroyed(this))
            .subscribe(function (_a) {
            var baseValue = _a[0], extentValue = _a[1];
            _this.baseDisplayValue = baseValue;
            _this.extentDisplayValue = isSet(extentValue) ? Math.max(baseValue, extentValue) : undefined;
            _this.cd.markForCheck();
        });
    };
    SliderComponent.prototype.ngOnDestroy = function () {
        if (this.draggingState) {
            removeClass(document.body, SLIDER_DRAGGING_CLASS);
        }
    };
    SliderComponent.prototype.ngOnChanges = function (changes) {
        if (changes.min) {
            this.min = coerceFloat(this.min, DEFAULT_MIN);
        }
        if (changes.max) {
            this.max = coerceFloat(this.max, DEFAULT_MAX);
        }
        if (changes.step) {
            this.step = coerceFloat(this.step, DEFAULT_STEP);
        }
        if (changes.trackBackground) {
            this.trackBackgroundSafe = this.sanitizer.bypassSecurityTrustStyle(this.trackBackground);
        }
        if (changes.fillBackground) {
            this.fillBackgroundSafe = this.sanitizer.bypassSecurityTrustStyle(this.fillBackground);
        }
        if (changes.thumbBackgroundColor) {
            this.thumbBackgroundColorSafe = this.sanitizer.bypassSecurityTrustStyle(this.thumbBackgroundColor);
        }
        if (changes.thumbBorderColor) {
            this.thumbBorderColorSafe = this.sanitizer.bypassSecurityTrustStyle(this.thumbBorderColor);
        }
    };
    SliderComponent.prototype.cleanValue = function (value) {
        if (typeof value != 'number') {
            value = parseFloat(value);
        }
        if (isNaN(value)) {
            value = this.min;
        }
        value = clamp(value, this.min, this.max);
        var diff = (value - this.min) % this.step;
        if (diff != 0) {
            if (diff >= this.step / 2) {
                value += this.step - diff;
            }
            else {
                value -= diff;
            }
        }
        return value;
    };
    Object.defineProperty(SliderComponent.prototype, "leftPercentage", {
        get: function () {
            if (this.range) {
                var value = this.isDraggingThumb(true) ? this.baseDraggingValue : this.cleanValue(this.baseDisplayValue);
                var total = this.max - this.min;
                return total != 0 ? clamp((value - this.min) / total, 0, 1) : 0;
            }
            else {
                return 0;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SliderComponent.prototype, "widthPercentage", {
        get: function () {
            if (this.range) {
                var leftPercentage = this.leftPercentage;
                var value = this.isDraggingThumb(false) ? this.extentDraggingValue : this.cleanValue(this.extentDisplayValue);
                var total = this.max - this.min;
                return total != 0 ? clamp((value - this.min) / total - leftPercentage, 0, 1 - leftPercentage) : 0;
            }
            else {
                var value = this.isDraggingThumb(true) ? this.baseDraggingValue : this.cleanValue(this.baseDisplayValue);
                var total = this.max - this.min;
                return total != 0 ? clamp((value - this.min) / total, 0, 1) : 0;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SliderComponent.prototype, "baseValueClean", {
        get: function () {
            return this.cleanValue(this.isDraggingThumb(true) ? this.baseDraggingValue : this.baseDisplayValue);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SliderComponent.prototype, "extentValueClean", {
        get: function () {
            return this.cleanValue(this.isDraggingThumb(false) ? this.extentDraggingValue : this.extentDisplayValue);
        },
        enumerable: true,
        configurable: true
    });
    SliderComponent.prototype.isDraggingThumb = function (base) {
        if (!this.draggingState) {
            return false;
        }
        if (base) {
            return this.draggingState.baseThumb;
        }
        else {
            return !this.draggingState.baseThumb;
        }
    };
    SliderComponent.prototype.isBaseThumbPosition = function (position) {
        if (this.range) {
            var rawValue = this.getRawValueForPosition(position);
            if (rawValue < this.baseDisplayValue) {
                return true;
            }
            else if (rawValue >= this.extentDisplayValue) {
                return false;
            }
            else {
                var baseDistance = Math.abs(rawValue - this.baseDisplayValue);
                var extentDistance = Math.abs(rawValue - this.extentDisplayValue);
                return baseDistance < extentDistance;
            }
        }
        else {
            return true;
        }
    };
    SliderComponent.prototype.dragStart = function (mouseDownEvent) {
        var _this = this;
        if (mouseDownEvent.button != MouseButton.Main) {
            return;
        }
        mouseDownEvent.preventDefault();
        var position = { x: mouseDownEvent.clientX, y: mouseDownEvent.clientY };
        var baseThumb = this.isBaseThumbPosition(position);
        var thumbElement = baseThumb ? this.baseThumbElement.nativeElement : this.extentThumbElement.nativeElement;
        var dragBounds = thumbElement.getBoundingClientRect();
        var subscriptions = [];
        subscriptions.push(fromEvent(window.document, 'mousemove')
            .pipe(untilDestroyed(this))
            .subscribe(function (e) {
            e.preventDefault();
            _this.dragMove(e);
        }));
        subscriptions.push(fromEvent(window.document, 'mouseup')
            .pipe(filter(function (e) { return e.button == MouseButton.Main; }), untilDestroyed(this))
            .subscribe(function (e) { return _this.dragFinish(e); }));
        addClass(document.body, SLIDER_DRAGGING_CLASS);
        this.draggingState = {
            startPosition: position,
            startDelta: {
                x: mouseDownEvent.clientX - (dragBounds.left + dragBounds.width * 0.5),
                y: mouseDownEvent.clientY - (dragBounds.top + dragBounds.height * 0.5)
            },
            baseThumb: baseThumb,
            subscriptions: subscriptions
        };
        if (baseThumb) {
            this.baseDraggingValue = this.cleanValue(this.baseDisplayValue);
        }
        else {
            this.extentDraggingValue = this.cleanValue(this.extentDisplayValue);
        }
        this.setValueForPosition(position);
    };
    SliderComponent.prototype.dragMove = function (e) {
        var position = { x: e.clientX, y: e.clientY };
        this.setValueForPosition(position);
    };
    SliderComponent.prototype.dragFinish = function (e) {
        var position = { x: e.clientX, y: e.clientY };
        this.setValueForPosition(position);
        removeClass(document.body, SLIDER_DRAGGING_CLASS);
        if (this.draggingState) {
            this.draggingState.subscriptions.forEach(function (item) { return item.unsubscribe(); });
        }
        this.baseDraggingValue = undefined;
        this.extentDraggingValue = undefined;
        this.draggingState = undefined;
        this.cd.markForCheck();
    };
    SliderComponent.prototype.getRawValueForPosition = function (position) {
        var padding = 5;
        var bounds = this.trackElement.nativeElement.getBoundingClientRect();
        var percentage = clamp((position.x - (bounds.left + padding)) / (bounds.width - padding * 2), 0, 1);
        return this.min + (this.max - this.min) * percentage;
    };
    SliderComponent.prototype.setValueForPosition = function (position) {
        var value = this.getRawValueForPosition(position);
        if (this.range) {
            if (this.draggingState.baseThumb) {
                value = Math.min(value, this.extentDisplayValue);
            }
            else {
                value = Math.max(value, this.baseDisplayValue);
            }
        }
        var cleanValue = this.cleanValue(value);
        if (this.range && !this.draggingState.baseThumb) {
            this.extentDisplayValue = this.extentDraggingValue;
            this.extentDraggingValue = this.stickToSteps ? cleanValue : value;
            this.cd.markForCheck();
            this.setExtentValue.next(cleanValue);
        }
        else {
            this.baseDisplayValue = this.baseDraggingValue;
            this.baseDraggingValue = this.stickToSteps ? cleanValue : value;
            this.cd.markForCheck();
            this.setBaseValue.next(cleanValue);
        }
    };
    return SliderComponent;
}());
export { SliderComponent };

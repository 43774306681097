import { ChangeDetectorRef, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import cloneDeep from 'lodash/cloneDeep';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest, of, timer } from 'rxjs';
import { debounce, map, skip, switchMap } from 'rxjs/operators';
import { ActionDescription, ActionItem, ActionType } from '@modules/actions';
import { AllProjectSettings, HomeType, ProjectSettingsStore } from '@modules/all-project-settings';
import { CustomizeService, ViewSettingsStore } from '@modules/customize';
import { GlobalContext } from '@modules/customize-utils';
import { FieldOutput, FieldType, Input as FieldInput, InputValueType } from '@modules/fields';
import { CurrentEnvironmentStore, CurrentProjectStore, HomeTriggerOutput, ProjectSettingsName, ProjectSettingsService } from '@modules/projects';
import { ActionWorkflowStep, ConditionWorkflowStep, ConditionWorkflowStepItem, ConditionWorkflowStepType, Workflow } from '@modules/workflow';
import { isSet } from '@shared';
import { WorkflowEditController } from '../../services/workflow-edit-controller/workflow-edit.controller';
var CustomizeHomeComponent = /** @class */ (function () {
    function CustomizeHomeComponent(context, customizeService, currentProjectStore, currentEnvironmentStore, projectSettingsService, projectSettingsStore, viewSettingsStore, workflowEditController, cd) {
        this.context = context;
        this.customizeService = customizeService;
        this.currentProjectStore = currentProjectStore;
        this.currentEnvironmentStore = currentEnvironmentStore;
        this.projectSettingsService = projectSettingsService;
        this.projectSettingsStore = projectSettingsStore;
        this.viewSettingsStore = viewSettingsStore;
        this.workflowEditController = workflowEditController;
        this.cd = cd;
        this.menuItems = [];
        this.disabled = false;
        this.dark = false;
        this.dropdownLeft = false;
        this.startInteracting = new EventEmitter();
        this.finishInteracting = new EventEmitter();
        this.loading = false;
        this.homeTypes = HomeType;
        this.typeOpened$ = new BehaviorSubject(false);
        this.pagesOpened$ = new BehaviorSubject(false);
        this.workflowOpened$ = new BehaviorSubject(false);
        this.saving$ = new BehaviorSubject(false);
        this.typeDropdownPositions = [];
        this.pagesDropdownPositions = [];
    }
    CustomizeHomeComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.typeDropdownPositions = this.getTypeDropdownPositions();
        this.pagesDropdownPositions = this.getPagesDropdownPositions();
        this.loading = true;
        this.cd.markForCheck();
        combineLatest(this.projectSettingsStore.getAllSettings$(), this.projectSettingsStore.getAllSettings$().pipe(switchMap(function (projectSettings) {
            if (projectSettings && projectSettings.homeType == HomeType.Page && isSet(projectSettings.homePageUid)) {
                return _this.viewSettingsStore.getDetailByUid(projectSettings.homePageUid);
            }
            else {
                return of(undefined);
            }
        })))
            .pipe(untilDestroyed(this))
            .subscribe(function (_a) {
            var projectSettings = _a[0], homePage = _a[1];
            _this.projectSettings = projectSettings;
            if (projectSettings && projectSettings.homeType == HomeType.FirstMenuItem) {
                _this.displayValue = 'First menu page';
                _this.menuItemValue = _this.menuItems[0];
            }
            else if (projectSettings && projectSettings.homeType == HomeType.Page) {
                _this.displayValue = homePage ? homePage.name : 'Unknown page';
                _this.menuItemValue = homePage
                    ? _this.menuItems.find(function (item) { return isSet(item.pageUid) && item.pageUid == homePage.uid; })
                    : undefined;
            }
            else if (projectSettings && projectSettings.homeType == HomeType.Workflow) {
                _this.displayValue = 'Using Workflow';
                _this.menuItemValue = undefined;
            }
            _this.loading = false;
            _this.cd.markForCheck();
        });
        combineLatest(this.typeOpened$.pipe(debounce(function (opened) { return timer(opened ? 0 : 200); })), this.pagesOpened$.pipe(debounce(function (opened) { return timer(opened ? 0 : 200); })), this.workflowOpened$.pipe(debounce(function (opened) { return timer(opened ? 0 : 600); })), this.saving$)
            .pipe(map(function (_a) {
            var typeOpened = _a[0], pagesOpened = _a[1], workflowOpened = _a[2], saving = _a[3];
            return typeOpened || pagesOpened || workflowOpened || saving;
        }), skip(1), untilDestroyed(this))
            .subscribe(function (interacting) {
            if (interacting) {
                _this.startInteracting.emit();
            }
            else {
                _this.finishInteracting.emit();
            }
        });
    };
    CustomizeHomeComponent.prototype.ngOnDestroy = function () { };
    CustomizeHomeComponent.prototype.setHomeFirstMenuItem = function () {
        this.updateHome({ homeType: HomeType.FirstMenuItem });
    };
    CustomizeHomeComponent.prototype.setHomePage = function (page) {
        this.updateHome({ homeType: HomeType.Page, homePageUid: page.uid });
    };
    CustomizeHomeComponent.prototype.editHomeWorkflow = function () {
        var _this = this;
        var workflow;
        if (this.projectSettings.homeWorkflow) {
            workflow = cloneDeep(this.projectSettings.homeWorkflow);
        }
        else {
            workflow = new Workflow();
            workflow.generateUid();
            var conditionStep = new ConditionWorkflowStep();
            var getLinkAction_1 = function (triggerOutput) {
                var actionStep = new ActionWorkflowStep();
                actionStep.generateUid();
                if (triggerOutput == HomeTriggerOutput.AcceptInvite) {
                    actionStep.name = "Navigate after invite accepted";
                }
                else if (triggerOutput == HomeTriggerOutput.Register) {
                    actionStep.name = "Navigate after sign up";
                }
                else if (triggerOutput == HomeTriggerOutput.Login) {
                    actionStep.name = "Navigate after login";
                }
                else if (!isSet(triggerOutput)) {
                    actionStep.name = "Default navigate";
                }
                actionStep.action = new ActionItem();
                actionStep.action.actionDescription = new ActionDescription();
                actionStep.action.actionDescription.type = ActionType.Link;
                return actionStep;
            };
            conditionStep.generateUid();
            conditionStep.name = 'Condition';
            conditionStep.conditionType = ConditionWorkflowStepType.Switch;
            conditionStep.items = [HomeTriggerOutput.Register, HomeTriggerOutput.AcceptInvite].map(function (triggerOutput, i) {
                var stepItem = new ConditionWorkflowStepItem();
                stepItem.generateUid();
                if (triggerOutput == HomeTriggerOutput.AcceptInvite) {
                    stepItem.label = "After invite accepted";
                }
                else if (triggerOutput == HomeTriggerOutput.Register) {
                    stepItem.label = "After sign up";
                }
                stepItem.condition = new FieldInput();
                stepItem.condition.name = 'value';
                stepItem.condition.valueType = InputValueType.Context;
                stepItem.condition.contextValue = ['workflow', triggerOutput];
                var actionStep = getLinkAction_1(triggerOutput);
                stepItem.steps = [actionStep];
                return stepItem;
            });
            var elseActionStep = getLinkAction_1(HomeTriggerOutput.Login);
            conditionStep.elseSteps = [elseActionStep];
            conditionStep.elseLabel = 'After login';
            workflow.steps = [conditionStep];
        }
        var outputs = [
            {
                name: HomeTriggerOutput.Register,
                verboseName: 'After sign up',
                field: FieldType.Boolean,
                description: 'Session after user has signed up',
                icon: 'signup'
            },
            {
                name: HomeTriggerOutput.AcceptInvite,
                verboseName: 'After invite accepted',
                field: FieldType.Boolean,
                description: 'Session after user has accepted invite',
                icon: 'email'
            },
            {
                name: HomeTriggerOutput.Login,
                verboseName: 'After login',
                field: FieldType.Boolean,
                description: 'Session after user has logged in',
                icon: 'signin'
            }
        ].map(function (item) {
            var output = new FieldOutput();
            output.name = item.name;
            output.verboseName = item.verboseName;
            output.field = item.field;
            output.icon = item.icon;
            output.updateFieldDescription();
            return output;
        });
        this.workflowOpened$.next(true);
        this.workflowEditController
            .open({
            create: !this.projectSettings.homeWorkflow,
            workflow: workflow,
            workflowRun: workflow.testRun,
            workflowEditable: true,
            parameters: [],
            triggerOutputs: outputs,
            triggerLabel: 'When app opens',
            triggerIcon: 'home',
            customizeTrigger: false,
            context: this.context,
            actionTypesEnabled: [
                ActionType.Query,
                ActionType.Link,
                ActionType.ExternalLink,
                ActionType.ShowNotification,
                ActionType.SetProperty,
                ActionType.RunJavaScript,
                ActionType.CopyToClipboard
            ],
            analyticsSource: this.analyticsSource
        })
            // .pipe(untilDestroyed(this))
            .subscribe(function (result) {
            _this.workflowOpened$.next(false);
            if (result.cancelled) {
                return;
            }
            result.workflow.testRun = result.workflowRun;
            _this.setHomeWorkflow(result.workflow);
        }, function () {
            _this.workflowOpened$.next(false);
        });
    };
    CustomizeHomeComponent.prototype.setHomeWorkflow = function (workflow) {
        this.updateHome({ homeType: HomeType.Workflow, homeWorkflow: workflow });
    };
    CustomizeHomeComponent.prototype.updateHome = function (settings) {
        var _this = this;
        var updateProjectSettings = new AllProjectSettings(settings).serialize([ProjectSettingsName.Home]);
        this.saving$.next(true);
        this.projectSettingsService
            .createBulk(this.currentProjectStore.instance.uniqueName, this.currentEnvironmentStore.instance.uniqueName, updateProjectSettings)
            .pipe(switchMap(function () { return _this.projectSettingsStore.getFirst(true); })
        // untilDestroyed(this)
        )
            .subscribe(function () {
            _this.saving$.next(false);
        }, function () {
            _this.saving$.next(false);
        });
    };
    CustomizeHomeComponent.prototype.getTypeDropdownPositions = function () {
        return this.dropdownLeft
            ? [
                {
                    panelClass: ['overlay_position_bottom-right'],
                    originX: 'end',
                    overlayX: 'end',
                    originY: 'bottom',
                    overlayY: 'top',
                    offsetY: 8
                },
                {
                    panelClass: ['overlay_position_top-right'],
                    originX: 'end',
                    overlayX: 'end',
                    originY: 'top',
                    overlayY: 'bottom',
                    offsetY: -8
                }
            ]
            : [
                {
                    panelClass: ['overlay_position_bottom-left'],
                    originX: 'start',
                    overlayX: 'start',
                    originY: 'bottom',
                    overlayY: 'top',
                    offsetY: 8
                },
                {
                    panelClass: ['overlay_position_top-left'],
                    originX: 'start',
                    overlayX: 'start',
                    originY: 'top',
                    overlayY: 'bottom',
                    offsetY: -8
                }
            ];
    };
    CustomizeHomeComponent.prototype.getPagesDropdownPositions = function () {
        return this.dropdownLeft
            ? [
                {
                    panelClass: ['overlay_position_left-top'],
                    originX: 'start',
                    overlayX: 'end',
                    originY: 'top',
                    overlayY: 'top',
                    offsetX: 8,
                    offsetY: -8
                },
                {
                    panelClass: ['overlay_position_left-bottom'],
                    originX: 'start',
                    overlayX: 'end',
                    originY: 'bottom',
                    overlayY: 'bottom',
                    offsetX: 8,
                    offsetY: 8
                },
                {
                    panelClass: ['overlay_position_left-center'],
                    originX: 'start',
                    overlayX: 'end',
                    originY: 'center',
                    overlayY: 'center',
                    offsetX: 8,
                    offsetY: 0
                }
            ]
            : [
                {
                    panelClass: ['overlay_position_right-top'],
                    originX: 'end',
                    overlayX: 'start',
                    originY: 'top',
                    overlayY: 'top',
                    offsetX: -8,
                    offsetY: -8
                },
                {
                    panelClass: ['overlay_position_right-bottom'],
                    originX: 'end',
                    overlayX: 'start',
                    originY: 'bottom',
                    overlayY: 'bottom',
                    offsetX: -8,
                    offsetY: 8
                },
                {
                    panelClass: ['overlay_position_right-center'],
                    originX: 'end',
                    overlayX: 'start',
                    originY: 'center',
                    overlayY: 'center',
                    offsetX: -8,
                    offsetY: 0
                }
            ];
    };
    return CustomizeHomeComponent;
}());
export { CustomizeHomeComponent };

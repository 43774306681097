/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../routing/directives/app-link/app-link.directive";
import * as i2 from "../../../routing/services/routing/routing.service";
import * as i3 from "@angular/router";
import * as i4 from "@angular/common";
import * as i5 from "./feature-overview-line.component";
import * as i6 from "../../../projects/stores/current-project.store";
var styles_FeatureOverviewLineComponent = [];
var RenderType_FeatureOverviewLineComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_FeatureOverviewLineComponent, data: {} });
export { RenderType_FeatureOverviewLineComponent as RenderType_FeatureOverviewLineComponent };
export function View_FeatureOverviewLineComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 11, "div", [["class", "feature-overview-line"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "div", [["class", "feature-overview-line__subtitle"]], null, null, null, null, null)), i0.ɵncd(null, 0), (_l()(), i0.ɵeld(3, 0, null, null, 1, "div", [["class", "feature-overview-line__title"]], null, null, null, null, null)), i0.ɵncd(null, 1), (_l()(), i0.ɵeld(5, 0, null, null, 1, "div", [["class", "feature-overview-line__description"]], null, null, null, null, null)), i0.ɵncd(null, 2), (_l()(), i0.ɵeld(7, 0, null, null, 4, "div", [["class", "feature-overview-line__buttons"]], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 3, "a", [["class", "button button_primary button_small"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 9).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(9, 737280, null, 0, i1.AppLinkDirective, [i2.RoutingService, i3.Router, i3.ActivatedRoute, i4.LocationStrategy], { appLink: [0, "appLink"] }, null), i0.ɵpid(131072, i4.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵted(-1, null, ["Upgrade Plan"]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = i0.ɵunv(_v, 9, 0, i0.ɵnov(_v, 10).transform(_co.currentProjectStore.instance$)).settingsBillingLink; _ck(_v, 9, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 9).target; var currVal_1 = i0.ɵnov(_v, 9).href; _ck(_v, 8, 0, currVal_0, currVal_1); }); }
export function View_FeatureOverviewLineComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-feature-overview-line", [], null, null, null, View_FeatureOverviewLineComponent_0, RenderType_FeatureOverviewLineComponent)), i0.ɵdid(1, 245760, null, 0, i5.FeatureOverviewLineComponent, [i6.CurrentProjectStore], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FeatureOverviewLineComponentNgFactory = i0.ɵccf("app-feature-overview-line", i5.FeatureOverviewLineComponent, View_FeatureOverviewLineComponent_Host_0, {}, {}, ["[data-subtitle]", "[data-title]", "[data-description]"]);
export { FeatureOverviewLineComponentNgFactory as FeatureOverviewLineComponentNgFactory };

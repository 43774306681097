<app-page style="display: block; padding-left: 20px;">
  <app-section [top]="true">
    <app-page-header>
      <app-page-title>Templates</app-page-title>

      <app-page-header-right>
        <a [appLink]="(currentProjectStore.instance$ | async).createTemplateLink" class="button button_primary">
          <span class="button__icon button__icon_left icon-plus"></span>
          <span class="button__label">Create</span>
        </a>
      </app-page-header-right>
    </app-page-header>

    <app-section-tabs>
      <app-section-tab
        *ngFor="let tab of tabs"
        [label]="tab.title"
        [active]="tab.type == currentTabType"
        (selected)="setCurrentTabType(tab.type)"
      ></app-section-tab>
    </app-section-tabs>
  </app-section>

  <app-section>
    <app-page-block [fill]="true" [first]="true" [last]="true">
      <app-page-block [fill]="true">
        <table *ngIf="templates?.length && !loading && !error" class="table">
          <thead>
            <tr class="table__heading-row">
              <th class="table__heading-column table__heading-column_left-padding"></th>
              <th class="table__heading-column" colspan="2">
                Name
              </th>
              <th class="table__heading-column" style="width: 0;">Active</th>
              <th class="table__heading-column table__column_right"></th>
              <th class="table__column"></th>
            </tr>
          </thead>
          <tbody
            cdkDropList
            [cdkDropListDisabled]="false"
            [cdkDropListLockAxis]="'y'"
            class="table__row"
            (cdkDropListDropped)="dragDrop($event)"
          >
            <tr *ngFor="let item of templates; let i = index" cdkDrag class="table__row">
              <td class="table__column table__column_left-padding">
                <div class="table__column-drag"></div>
              </td>

              <td class="table__column" style="width: 50px;">
                <app-project-logo
                  [color]="item.color"
                  [logo]="item.logo"
                  [icon]="item.icon ? 'icon-' + item.icon : undefined"
                  [size]="'s'"
                  [borderRadius]="'s'"
                  [initials]="item.name | appInitials"
                  [fill]="item.logoFill"
                ></app-project-logo>
              </td>

              <td class="table__column">
                <div class="table__column-inner">
                  <a [appLink]="item.link">{{ item.name }}</a>

                  <span
                    *ngIf="item.featured"
                    class="status color_blue_1 background-color_blue_2"
                    style="margin-left: 6px;"
                  >
                    Featured
                  </span>
                </div>
              </td>

              <td class="table__column">
                <div class="table__column-inner">
                  <span class="checkmark" [class.checkmark_on]="item.active" [class.checkmark_off]="!item.active">
                    <span
                      class="checkmark__icon"
                      [class.icon-check_2]="item.active"
                      [class.icon-close]="!item.active"
                    ></span>
                  </span>
                </div>
              </td>

              <td class="table__column table__column_right">
                <a class="button button_icon" [appLink]="item.link" [appTip]="'Edit'" [appTipOptions]="{ side: 'top' }">
                  <span class="icon icon-pen button__icon"></span>
                </a>
                <button
                  (click)="delete(item)"
                  [disabled]="false"
                  [appTip]="'Delete'"
                  [appTipOptions]="{ side: 'top' }"
                  class="button button_icon button_bg-transparent"
                >
                  <app-loader-small *ngIf="false" class="button__icon"> </app-loader-small>
                  <span *ngIf="true" class="icon icon-bin button__icon"></span>
                </button>
              </td>

              <td class="table__column"></td>
            </tr>
          </tbody>
        </table>

        <div *ngIf="templates?.length === 0 && !loading && !error" class="table__empty">
          <app-error [title]="'Nothing found' | localize">
            Unfortunately, no Templates here
          </app-error>
        </div>

        <div *ngIf="error" class="table__empty">
          <app-error [title]="'Loading failed' | localize">
            {{ error }}
          </app-error>
        </div>

        <table *ngIf="loading" class="table">
          <thead>
            <tr class="table__heading-row">
              <th class="table__heading-column table__heading-column_left-padding" colspan="2">
                <span [class.loading-animation]="true">
                  <span class="stub-text">Name</span>
                </span>
              </th>
              <th class="table__heading-column">
                <span [class.loading-animation]="true">
                  <span class="stub-text">Type</span>
                </span>
              </th>
              <th class="table__heading-column" style="width: 0;">
                <span [class.loading-animation]="true">
                  <span class="stub-text">Active</span>
                </span>
              </th>
              <th class="table__heading-column table__column_right"></th>
              <th class="table__column"></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of [1, 2, 3, 4]" class="table__row">
              <td class="table__column table__column_left-padding"></td>
              <td class="table__column">
                <span [class.loading-animation]="true">
                  <span class="stub-text">Management</span>
                </span>
              </td>
              <td class="table__column">
                <span [class.loading-animation]="true">
                  <span class="stub-text">Type</span>
                </span>
              </td>
              <td class="table__column">
                <span [class.loading-animation]="true">
                  <span class="stub-text">Yes</span>
                </span>
              </td>
              <td class="table__column"></td>
              <td class="table__column"></td>
            </tr>
          </tbody>
        </table>
      </app-page-block>
    </app-page-block>
  </app-section>
</app-page>

import * as moment from 'moment';

import { Environment } from './environment';
import { Project } from './project';
import { ProjectGroup, ProjectUser } from './project-user';

export class ProjectPublicAccess {
  public uid: string;
  public project: Project;
  public projectUsers: ProjectUser[] = [];
  public environment: Environment;
  public group: ProjectGroup;
  public params: Object = {};
  public code: string;
  public dateAdd: any;
  public pageUid: string;
  public pageParams: Object;

  deserialize(data: Object): ProjectPublicAccess {
    this.uid = data['uid'];
    this.code = data['code'];
    this.params = data['params'];
    this.dateAdd = moment(data['date_add']);

    if (data['project']) {
      this.project = new Project().deserialize(data['project']);
    }

    if (data['environment']) {
      this.environment = new Environment().deserialize(data['environment']);
    }

    if (data['group']) {
      this.group = new ProjectGroup().deserialize(data['group']);
    } else {
      this.group = new ProjectGroup();
    }

    if (this.params['page_uid']) {
      this.pageUid = this.params['page_uid'];
    }

    if (this.params['page_params']) {
      this.pageParams = this.params['page_params'];
    }

    return this;
  }

  serialize() {
    return {
      group: this.group ? this.group.uid : undefined,
      params: {
        page_uid: this.pageUid,
        page_params: this.pageParams
      }
    };
  }

  link(webBaseUrl: string) {
    return `${webBaseUrl}/public/${this.code}`;
  }
}

import { MenuItem } from '../data/menu-item';
import { MenuBlockLayout, MenuSettings } from '../data/menu-settings';

export function findMenuItem(menuItems: MenuItem[], predicate: (item: MenuItem) => boolean): MenuItem {
  const processItems = (items: MenuItem[]): MenuItem => {
    for (let i = 0; i < items.length; ++i) {
      const item = items[i];

      if (predicate(item)) {
        return item;
      }

      if (item.children) {
        const result = processItems(item.children);

        if (result) {
          return result;
        }
      }
    }
  };

  return processItems(menuItems);
}

export function forEachMenuItems(menuItems: MenuItem[], callback: (item: MenuItem) => void): MenuItem[] {
  const processItems = (items: MenuItem[]) => {
    items.forEach(item => {
      callback(item);

      if (item.children) {
        item.children.forEach(child => callback(child));
      }
    });

    return items;
  };

  return processItems(menuItems);
}

// TODO: Make immutable
export function filterMenuItems(menuItems: MenuItem[], predicate: (item: MenuItem) => boolean): MenuItem[] {
  const processItems = (items: MenuItem[]) => {
    items = items.filter(item => predicate(item));

    items.forEach(item => {
      if (item.children) {
        item.children = processItems(item.children);
      }
    });

    return items;
  };

  return processItems(menuItems);
}

export function forEachMenuSettings(
  settings: MenuSettings,
  callback: (item: MenuItem, menu: MenuBlockLayout) => void
): MenuSettings {
  settings.blocks.forEach(block => {
    block.startItems = forEachMenuItems(block.startItems, item => {
      callback(item, block.layout);
    });

    block.centerItems = forEachMenuItems(block.centerItems, item => {
      callback(item, block.layout);
    });

    block.endItems = forEachMenuItems(block.endItems, item => {
      callback(item, block.layout);
    });
  });

  return settings;
}

// TODO: Make immutable
export function filterMenuSettings(
  settings: MenuSettings,
  predicate: (item: MenuItem, menu: MenuBlockLayout) => boolean
): MenuSettings {
  settings.blocks.forEach(block => {
    block.startItems = filterMenuItems(block.startItems, item => {
      return predicate(item, block.layout);
    });

    block.centerItems = filterMenuItems(block.centerItems, item => {
      return predicate(item, block.layout);
    });

    block.endItems = filterMenuItems(block.endItems, item => {
      return predicate(item, block.layout);
    });
  });

  return settings;
}

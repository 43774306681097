<ng-container *ngIf="visible || element.loadInvisible || (customizeEnabled$ | async)">
  <div
    *ngIf="element"
    class="columns"
    [style.display]="element.loadInvisible && !visible && !(customizeEnabled$ | async) ? 'none' : null"
    #columns_element
  >
    <div
      *ngFor="let column of element.columns; let c = index; let first = first; let last = last; trackBy: trackColumn"
      class="columns__item"
      [class.columns__item_single]="!column.fit && element.columns.length == 1"
      [class.columns__item_fit]="column.fit"
      [class.columns__item_disabled]="dragging"
      [class.columns__item_active]="draggingIndex === c"
      [style.flex-basis]="!column.fit && column.width && element.columns.length > 1 ? column.width + 'px' : null"
      [style.flex-grow]="!column.fit && column.weight ? column.weight * 100 : null"
      #column_element
    >
      <div *ngIf="element.columns.length > 1" class="columns__item-actions">
        <div class="columns__item-actions-dropdown-wrapper">
          <span class="columns__item-action">
            <span
              [class.icon-align_vertical_center]="column.fit"
              [class.columns__item-action-rotate-icon]="column.fit"
              [class.icon-fixed_horizontal]="!column.fit && column.width"
              [class.icon-resize_horizontal]="!column.fit && column.weight"
            ></span>
          </span>

          <div class="columns__item-actions-dropdown">
            <a
              *ngIf="!column.fit && column.children.length"
              href="javascript:void(0)"
              class="columns__item-action"
              (click)="$event.stopPropagation(); setFitContents(c)"
              [appTip]="'Fit contents'"
              [appTipOptions]="{ side: 'top' }"
            >
              <span class="icon-align_vertical_center columns__item-action-rotate-icon"></span>
            </a>
            <span
              *ngIf="column.fit || !column.children.length"
              class="columns__item-action columns__item-action_disabled"
              [appTip]="column.fit ? 'Fit contents (selected)' : 'Fit contents (empty column)'"
              [appTipOptions]="{ side: 'top' }"
            >
              <span class="icon-align_vertical_center columns__item-action-rotate-icon"></span>
            </span>

            <a
              *ngIf="!column.weight"
              href="javascript:void(0)"
              class="columns__item-action"
              (click)="$event.stopPropagation(); setFluidWidth(c)"
              [appTip]="'Fluid width'"
              [appTipOptions]="{ side: 'top' }"
            >
              <span class="icon-resize_horizontal"></span>
            </a>
            <span
              *ngIf="column.weight"
              class="columns__item-action columns__item-action_disabled"
              [appTip]="'Fluid width (selected)'"
              [appTipOptions]="{ side: 'top' }"
            >
              <span class="icon-resize_horizontal"></span>
            </span>

            <a
              *ngIf="!column.width"
              href="javascript:void(0)"
              class="columns__item-action"
              (click)="$event.stopPropagation(); setFixedWidth(c)"
              [appTip]="'Fixed width'"
              [appTipOptions]="{ side: 'top' }"
            >
              <span class="icon-fixed_horizontal"></span>
            </a>
            <span
              *ngIf="column.width"
              class="columns__item-action columns__item-action_disabled"
              [appTip]="'Fixed width (selected)'"
              [appTipOptions]="{ side: 'top' }"
            >
              <span class="icon-fixed_horizontal"></span>
            </span>
          </div>
        </div>

        <a
          href="javascript:void(0)"
          class="columns__item-action columns__item-action_danger"
          (click)="$event.stopPropagation(); deleteColumn(c)"
          [appTip]="'Delete Column'"
          [appTipOptions]="{ side: 'top' }"
        >
          <span class="icon-bin"></span>
        </a>
      </div>
      <div class="placeholder" [class.placeholder_active]="customizeService.layoutEnabled$ | async">
        <app-element-wrapper [wrap]="column.children.length > 0">
          <div
            class="columns__item-placeholder"
            appDropList
            [appDropListDisabled]="!(customizeService.layoutEnabled$ | async)"
            [appDropListEnterPredicate]="canEnter"
            [appDropListData]="column.children"
            [appDropListSwapDistance]="20"
            [appDropListAreaMargin]="[0, last ? 15 : 0, 0, first ? 15 : 0]"
            [appDropListPreviewMissPointerStartDelta]="{ x: 20, y: -20 }"
            [appDropListOutsideIndicator]="true"
            (appDropListDragStarted)="updateElementStates(c)"
            (appDropListEntered)="updateElementStates(c)"
            (appDropListExited)="updateElementStates(c)"
            (appDropListSorted)="updateElementStates(c)"
            (appDropListDragged)="draggedOutside(); updateElementStatesOnStable(c)"
            (appDropListDropped)="dragDrop(c, $event)"
            appElementGroupsContainer
          >
            <app-auto-element
              *ngFor="let item of column.children; let i = index; trackBy: trackElement"
              appDrag
              [appDragData]="item"
              [element]="item"
              [context]="context"
              [customizing]="customizeService.layoutEnabled$ | async"
              [accentColor]="'accentColor' | appThemeColorHexOption | async"
              (duplicatedRequested)="duplicateItem(c, i)"
              (replaceRequested)="replaceItem(c, i, $event)"
              (deleteRequested)="deleteItem(item)"
              (moveToRequested)="moveItemTo(item, $event)"
              (visibleUpdated)="updateElementStates(c)"
            >
              <app-element-placeholder-stub
                *appDragPlaceholder
                [elementType]="item.type"
                class="element-placeholder"
              ></app-element-placeholder-stub>

              <app-element-drag-preview *appDragPreview [element]="item"></app-element-drag-preview>
            </app-auto-element>
          </div>
          <div class="placeholder__border"></div>
        </app-element-wrapper>
      </div>

      <div
        class="columns__item-sibling"
        [class.columns__item-sibling_active]="(siblingEntered$ | async) === c"
        appDropList
        [appDropListDisabled]="!(customizeService.layoutEnabled$ | async)"
        [appDropListEnterPredicate]="canEnterAfter"
        [appDropListOutsideIndicator]="true"
        (appDropListEntered)="updateElementStates(c)"
        (appDropListExited)="updateElementStates(c)"
        (appDropListDropped)="dragDropAfter(c, $event)"
        [appDropListPriority]="10"
        (mouseenter)="siblingEntered$.next(c)"
        (mouseleave)="siblingEntered$.next(undefined)"
      ></div>

      <app-columns-layout-handle
        *ngIf="!last"
        [active]="draggingIndex === c"
        [stick]="stick"
        (dragStarted)="onHandleDragStarted(c)"
      ></app-columns-layout-handle>
    </div>
  </div>
</ng-container>

import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-sidebar-header',
  templateUrl: './sidebar-header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SidebarHeaderComponent implements OnInit {
  @Input() title: string;
  @Input() titleEnabled = true;
  @Input() titleEditable = false;
  @Input() titleCleanValue?: (value: string) => string;
  @Input() titleEditTip: string;
  @Input() subtitle: string;
  @Input() backEnabled = true;
  @Input() backLabel = 'Back';
  @Input() backIcon = 'arrow_backward_2';
  @Input() deleteEnabled = false;
  @Input() deleteTip: string;
  @Input() parentLabel: string;
  @Output() back = new EventEmitter<void>();
  @Output() titleChanged = new EventEmitter<string>();
  @Output() deleted = new EventEmitter<any>();
  @Output() parentClick = new EventEmitter<MouseEvent>();

  ngOnInit() {}
}

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../modules/icons/directives/icon/icon.directive";
import * as i2 from "../../../../core/services/app-config/app-config.service";
import * as i3 from "../../../../shared/pipes/is-color-hex/is-color-hex.pipe";
import * as i4 from "../../../../shared/pipes/is-set/is-set.pipe";
import * as i5 from "@angular/common";
import * as i6 from "./notification.component";
import * as i7 from "../../services/notification/notification.service";
var styles_NotificationComponent = [];
var RenderType_NotificationComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_NotificationComponent, data: {} });
export { RenderType_NotificationComponent as RenderType_NotificationComponent };
function View_NotificationComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["class", "notification__left"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "div", [["class", "notification__icon"]], [[4, "color", null], [4, "background-image", null]], null, null, null, null)), i0.ɵdid(2, 540672, null, 0, i1.IconDirective, [i2.AppConfigService, i0.ElementRef], { icon: [0, "icon"] }, null), i0.ɵppd(3, 1)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.notification.icon; _ck(_v, 2, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (i0.ɵunv(_v, 1, 0, _ck(_v, 3, 0, i0.ɵnov(_v.parent, 0), _co.notification.color)) ? _co.notification.color : null); var currVal_1 = i0.ɵnov(_v, 2).backgroundImage; _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_NotificationComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["class", "notification__title"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.notification.title; _ck(_v, 1, 0, currVal_0); }); }
function View_NotificationComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "div", [["class", "notification__description"]], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.notification.description; _ck(_v, 0, 0, currVal_0); }); }
export function View_NotificationComponent_0(_l) { return i0.ɵvid(2, [i0.ɵpid(0, i3.IsColorHexPipe, []), i0.ɵpid(0, i4.IsSetPipe, []), i0.ɵqud(402653184, 1, { root: 0 }), (_l()(), i0.ɵeld(3, 0, [[1, 0], ["root", 1]], null, 14, "div", [["class", "notification"], ["href", "javascript:void(0)"]], null, [[null, "click"], [null, "mouseenter"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } if (("mouseenter" === en)) {
        var pd_1 = (_co.onMouseEnter() !== false);
        ad = (pd_1 && ad);
    } if (("mouseleave" === en)) {
        var pd_2 = (_co.onMouseLeave() !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i0.ɵdid(4, 278528, null, 0, i5.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpad(5, 1), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_NotificationComponent_1)), i0.ɵdid(7, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(8, 0, null, null, 6, "div", [["class", "notification__main"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_NotificationComponent_2)), i0.ɵdid(10, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵppd(11, 1), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_NotificationComponent_3)), i0.ɵdid(13, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵppd(14, 1), (_l()(), i0.ɵeld(15, 0, null, null, 1, "div", [["class", "notification__badge"]], [[4, "background-color", null]], null, null, null, null)), i0.ɵppd(16, 1), (_l()(), i0.ɵeld(17, 0, null, null, 0, "div", [["class", "notification__close icon-close"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "notification"; var currVal_1 = _ck(_v, 5, 0, ("notification_type_" + _co.notification.type)); _ck(_v, 4, 0, currVal_0, currVal_1); var currVal_2 = _co.notification.icon; _ck(_v, 7, 0, currVal_2); var currVal_3 = i0.ɵunv(_v, 10, 0, _ck(_v, 11, 0, i0.ɵnov(_v, 1), _co.notification.title)); _ck(_v, 10, 0, currVal_3); var currVal_4 = i0.ɵunv(_v, 13, 0, _ck(_v, 14, 0, i0.ɵnov(_v, 1), _co.notification.description)); _ck(_v, 13, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_5 = (i0.ɵunv(_v, 15, 0, _ck(_v, 16, 0, i0.ɵnov(_v, 0), _co.notification.color)) ? _co.notification.color : null); _ck(_v, 15, 0, currVal_5); }); }
export function View_NotificationComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-notification", [], null, null, null, View_NotificationComponent_0, RenderType_NotificationComponent)), i0.ɵdid(1, 4440064, null, 0, i6.NotificationComponent, [i7.NotificationService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NotificationComponentNgFactory = i0.ɵccf("app-notification", i6.NotificationComponent, View_NotificationComponent_Host_0, { notification: "notification" }, {}, []);
export { NotificationComponentNgFactory as NotificationComponentNgFactory };

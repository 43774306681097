import { ApiService } from '@modules/api';
import { AuthService } from '@modules/auth';
import { ModelDescriptionStore, ModelService } from '@modules/model-queries';
import { ProjectApiService } from '@modules/project-api';
import { CurrentProjectStore, ProjectsStore } from '@modules/projects';
import { CurrentUserStore } from '@modules/users';
var PublicApiService = /** @class */ (function () {
    function PublicApiService(_apiService, _authService, _projectApiService, _projectsStore, _currentProjectStore, _currentUserStore, _modelDescriptionStore, _modelService) {
        this._apiService = _apiService;
        this._authService = _authService;
        this._projectApiService = _projectApiService;
        this._projectsStore = _projectsStore;
        this._currentProjectStore = _currentProjectStore;
        this._currentUserStore = _currentUserStore;
        this._modelDescriptionStore = _modelDescriptionStore;
        this._modelService = _modelService;
        window['jet_public_api'] = this;
    }
    Object.defineProperty(PublicApiService.prototype, "apiService", {
        get: function () {
            return this._apiService;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PublicApiService.prototype, "authService", {
        get: function () {
            return this._authService;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PublicApiService.prototype, "projectApiService", {
        get: function () {
            return this._projectApiService;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PublicApiService.prototype, "projectsStore", {
        get: function () {
            return this._projectsStore;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PublicApiService.prototype, "currentProjectStore", {
        get: function () {
            return this._currentProjectStore;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PublicApiService.prototype, "currentUserStore", {
        get: function () {
            return this._currentUserStore;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PublicApiService.prototype, "modelDescriptionStore", {
        get: function () {
            return this._modelDescriptionStore;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PublicApiService.prototype, "modelService", {
        get: function () {
            return this._modelService;
        },
        enumerable: true,
        configurable: true
    });
    return PublicApiService;
}());
export { PublicApiService };

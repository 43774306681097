import { ProjectToken } from '@modules/projects';

export class Migration {
  public name: string;
  public bridgeType: string;
  public bridgeVersion: string;
  public bridgeDependencies: string[] = [];
  public migrated = false;
  public tokens: ProjectToken[] = [];

  deserialize(data: Object): Migration {
    this.name = data['name'];
    this.bridgeType = data['bridge_type'];
    this.bridgeVersion = data['bridge_version'];
    this.migrated = data['migrated'];

    if (data['bridge_dependencies']) {
      this.bridgeDependencies = data['bridge_dependencies'];
    }

    if (data['tokens']) {
      this.tokens = data['tokens'].map(item => new ProjectToken().deserialize(item));
    }

    return this;
  }
}

import { Observable, ReplaySubject } from 'rxjs';

export function copyTextToClipboard(text): Observable<boolean> {
  if (!navigator['clipboard']) {
    return fallbackCopyTextToClipboard(text);
  }

  const result = new ReplaySubject<boolean>(1);

  navigator['clipboard'].writeText(text).then(
    () => result.next(true),
    () => result.next(false)
  );

  return result;
}

export function fallbackCopyTextToClipboard(text): Observable<boolean> {
  const result = new ReplaySubject<boolean>(1);
  const textArea = document.createElement('textarea');
  textArea.value = text;
  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    const successful = document.execCommand('copy');
    result.next(successful);
  } catch (e) {
    result.next(false);
  }

  document.body.removeChild(textArea);

  return result;
}

<ng-template
  [cdkConnectedOverlayBackdropClass]="'popover2-backdrop'"
  [cdkConnectedOverlayGrowAfterOpen]="true"
  [cdkConnectedOverlayHasBackdrop]="true"
  [cdkConnectedOverlayOpen]="editing"
  [cdkConnectedOverlayOrigin]="overlayOrigin"
  [cdkConnectedOverlayPanelClass]="['overlay', 'overlay_horizontal_center']"
  [cdkConnectedOverlayPositions]="[
    { originX: 'start', overlayX: 'start', originY: 'top', overlayY: 'top' },
    { originX: 'end', overlayX: 'end', originY: 'top', overlayY: 'top' },
    { originX: 'start', overlayX: 'start', originY: 'bottom', overlayY: 'bottom' },
    { originX: 'end', overlayX: 'end', originY: 'bottom', overlayY: 'bottom' }
  ]"
  [cdkConnectedOverlayPush]="true"
  (backdropClick)="finishEditing(true)"
  (detach)="finishEditing()"
  cdkConnectedOverlay
>
  <form
    [formGroup]="editingForm"
    class="table__column-editor"
    [style.min-width.px]="minWidth"
    [style.min-height.px]="minHeight"
    (ngSubmit)="finishEditing(true)"
  >
    <app-auto-field
      class="table__column-editor-inner"
      [form]="editingForm"
      [field]="formField"
      [label]="false"
      [autofocus]="true"
      [context]="context"
      [contextElement]="contextElement"
      (click)="$event.stopPropagation()"
    >
    </app-auto-field>
  </form>
</ng-template>

<div [class.table__column-inner]="!(fieldType == fieldTypes.Text && fieldParams && fieldParams['wide'])">
  <ng-container *ngIf="fieldType">
    <app-auto-field
      *ngIf="!(valueStr | appIsSet)"
      [field]="formField"
      [label]="false"
      [readonly]="true"
      [style.pointer-events]="'none'"
      [value]="value"
      [context]="context"
      [contextElement]="contextElement"
      [compact]="true"
    >
    </app-auto-field>

    <ng-container *ngIf="valueStr | appIsSet">
      {{ valueStr | appSafeAsync }}
    </ng-container>
  </ng-container>

  <ng-container *ngIf="!fieldType">
    Error
  </ng-container>
</div>

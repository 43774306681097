import { HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, publishLast, refCount } from 'rxjs/operators';

import { AdminMode, ROUTE_ADMIN_MODE } from '@modules/admin-mode';
import { ServerRequestError } from '@modules/api';
import {
  Environment,
  Project,
  Resource,
  ResourceTypeItem,
  SecretToken,
  SecretTokenService,
  SecretTokenType
} from '@modules/projects';
import { HttpQuery, HttpQueryService } from '@modules/queries';
import { ResourceParamsResult } from '@modules/resources';

// TODO: Refactor import
import { HTTP_QUERY_KEY_AUTH_NAME } from '@modules/projects-components/data/http-query-auth';

import { mailchimpResourceParamsActionDescriptions } from '../../data/mailchimp/mailchimp-resource-params-action-descriptions.stub';
import { mailchimpResourceParamsMenuSettings } from '../../data/mailchimp/mailchimp-resource-params-menu-settings.stub';
import { mailchimpResourceParamsModelDescriptions } from '../../data/mailchimp/mailchimp-resource-params-model-descriptions.stub';
import { mailchimpResourceParamsParams } from '../../data/mailchimp/mailchimp-resource-params-params.stub';
import { IsOptionsValidResult, ResourceGeneratorService } from '../resource-generator/resource-generator.service';

export interface MailchimpParamsOptions {
  key: string;
  server: string;
}

@Injectable()
export class MailchimpGeneratorService extends ResourceGeneratorService<MailchimpParamsOptions> {
  constructor(
    @Inject(ROUTE_ADMIN_MODE) private mode: AdminMode,
    private secretTokenService: SecretTokenService,
    private httpQueryService: HttpQueryService
  ) {
    super();
  }

  getKeyServer(key: string) {
    if (!key) {
      return;
    }

    const keyParts = key.split('-', 2);
    return keyParts.length == 2 ? keyParts[1] : undefined;
  }

  isOptionsValid(options: MailchimpParamsOptions): Observable<IsOptionsValidResult> {
    const query = new HttpQuery();
    const server = this.getKeyServer(options.key);

    if (!server) {
      return throwError(new ServerRequestError('API key has incorrect format'));
    }

    query.url = `https://${server}.api.mailchimp.com/3.0/ping`;
    // query.headers = [{ name: 'Authorization', value: `Basic ${this.authTokenFromOptions(options)}` }];
    query.headers = [{ name: 'Authorization', value: `Bearer ${this.authTokenFromOptions(options)}` }];

    return this.httpQueryService.request(query).pipe(
      map(() => {
        return {};
      }),
      catchError(error => {
        if (error instanceof ServerRequestError && error.response instanceof HttpErrorResponse && error.status == 400) {
          error = new ServerRequestError('API key is not valid');
        } else if (
          error instanceof ServerRequestError &&
          error.response instanceof HttpErrorResponse &&
          error.status == 401
        ) {
          error = new ServerRequestError('API key is not valid or not enough permissions');
        }

        return throwError(error);
      }),
      publishLast(),
      refCount()
    );
  }

  getParamsOptions(project: Project, environment: Environment, resource: Resource): Observable<MailchimpParamsOptions> {
    return this.secretTokenService
      .getDetail(
        project.uniqueName,
        environment.uniqueName,
        resource.uniqueName,
        HTTP_QUERY_KEY_AUTH_NAME,
        this.mode == AdminMode.Builder
      )
      .pipe(
        map(result => {
          return {
            key: result.value,
            server: resource.params['server']
          };
        })
      );
  }

  authTokenFromOptions(options: MailchimpParamsOptions): string {
    return options.key;
  }

  generateParams(
    project: Project,
    environment: Environment,
    typeItem: ResourceTypeItem,
    options: MailchimpParamsOptions
  ): Observable<ResourceParamsResult> {
    const resourceParams = mailchimpResourceParamsParams;
    const modelDescriptions = mailchimpResourceParamsModelDescriptions;
    const actionDescriptions = mailchimpResourceParamsActionDescriptions;
    const menuSettings = mailchimpResourceParamsMenuSettings;
    const token = new SecretToken();

    token.name = HTTP_QUERY_KEY_AUTH_NAME;
    token.type = SecretTokenType.Static;
    token.value = this.authTokenFromOptions(options);

    resourceParams['server'] = options.server;

    return of({
      resourceParams: resourceParams,
      modelDescriptions: modelDescriptions,
      actionDescriptions: actionDescriptions,
      menuSettings: menuSettings,
      secretTokens: [token.serialize()],
      extraTokens: {
        server: options.server
      }
    });
  }
}

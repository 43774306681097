<div *ngIf="(layoutService.layouts$ | async)?.length > 1" class="list__layouts">
  <button
    *ngFor="let item of layoutService.layouts$ | async; let i = index; trackBy: trackLayoutByFn"
    class="button button_small button_icon"
    [class.button_orange]="customizeService.enabled$ | async"
    [class.button_primary]="(layoutService.currentLayoutIndex$ | async) == i"
    (click)="setCurrentLayoutIndex(i)"
    [appTip]="item.label"
    [appTipOptions]="{ side: 'bottom' }"
  >
    <span class="icon button__icon" [ngClass]="['icon-' + item.icon]"></span>
  </button>
  <button
    *ngIf="customizeService.enabled$ | async"
    class="button button_orange button_small button_icon"
    (click)="addLayout()"
    [appTip]="'Add layout'"
    [appTipOptions]="{ side: 'bottom' }"
  >
    <span class="icon-plus button__icon"></span>
  </button>
</div>

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ChangeDetectorRef, EventEmitter, NgZone, OnDestroy, OnInit, QueryList } from '@angular/core';
import isEqual from 'lodash/isEqual';
import toPairs from 'lodash/toPairs';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import { delayWhen, map, tap } from 'rxjs/operators';
import { DialogButtonHotkey, DialogButtonType, DialogService } from '@common/dialogs';
import { NotificationService } from '@common/notifications';
import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { CustomizeService, ViewSettings, ViewSettingsService, ViewSettingsStore } from '@modules/customize';
import { ViewSettingsQueries } from '@modules/customize-utils';
import { MenuPagesService, MenuService, MenuSettingsStore } from '@modules/menu';
import { NavigationService } from '@modules/navigation';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { AppLinkActiveDirective, RoutingService } from '@modules/routing';
import { PageTemplatesController } from '@modules/template-queries';
import { isSet, KeyboardEventKeyCode, objectsSortPredicate } from '@shared';
var CustomizeMenuPagesComponent = /** @class */ (function () {
    function CustomizeMenuPagesComponent(customizeService, currentProjectStore, currentEnvironmentStore, viewSettingsService, viewSettingsStore, menuService, menuSettingsStore, notificationService, viewSettingsQueries, pageTemplatesController, routing, analyticsService, menuPagesService, navigationService, dialogService, zone, cd) {
        this.customizeService = customizeService;
        this.currentProjectStore = currentProjectStore;
        this.currentEnvironmentStore = currentEnvironmentStore;
        this.viewSettingsService = viewSettingsService;
        this.viewSettingsStore = viewSettingsStore;
        this.menuService = menuService;
        this.menuSettingsStore = menuSettingsStore;
        this.notificationService = notificationService;
        this.viewSettingsQueries = viewSettingsQueries;
        this.pageTemplatesController = pageTemplatesController;
        this.routing = routing;
        this.analyticsService = analyticsService;
        this.menuPagesService = menuPagesService;
        this.navigationService = navigationService;
        this.dialogService = dialogService;
        this.zone = zone;
        this.cd = cd;
        this.startInteracting = new EventEmitter();
        this.finishInteracting = new EventEmitter();
        this.closeMenu = new EventEmitter();
        this.linkActiveDirectives = new QueryList();
        this.loading = false;
        this.editing = false;
        this.selectedPages = {};
        this.createPageLoading = false;
        this.deletePagesLoading = false;
        this.search = '';
        this.searchUpdated = new Subject();
        this.items = [];
        this.groups = [];
        this.filteredQuery = '';
        this.filteredItems = [];
        this.filteredGroups = [];
        this.analyticsEvents = AnalyticsEvent;
        this.analyticsSource = 'menu_pages';
    }
    CustomizeMenuPagesComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.getGroups();
        this.searchUpdated.pipe(untilDestroyed(this)).subscribe(function () { return _this.updateFiltered(); });
    };
    CustomizeMenuPagesComponent.prototype.ngOnDestroy = function () { };
    CustomizeMenuPagesComponent.prototype.getGroups = function () {
        var _this = this;
        this.loading = true;
        this.cd.markForCheck();
        this.menuPagesService
            .getGroups()
            .pipe(untilDestroyed(this))
            .subscribe(function (groups) {
            _this.items = groups.reduce(function (acc, group) {
                acc.push.apply(acc, group.items);
                return acc;
            }, []);
            _this.groups = groups;
            _this.updateFiltered();
            _this.loading = false;
            _this.cd.markForCheck();
        }, function () {
            _this.loading = false;
            _this.cd.markForCheck();
        });
    };
    CustomizeMenuPagesComponent.prototype.setEditing = function (editing) {
        if (this.editing == editing) {
            return;
        }
        this.editing = editing;
        this.selectedPages = {};
        this.lastSelectedPage = undefined;
        this.cd.markForCheck();
        if (this.editing) {
            this.startInteracting.emit();
        }
        else {
            this.finishInteracting.emit();
        }
    };
    CustomizeMenuPagesComponent.prototype.createPage = function () {
        var _this = this;
        // this.routing.navigateApp(this.currentProjectStore.instance.newPageLink);
        this.pageTemplatesController
            .open({
            cancelEnabled: true,
            newPage: true,
            analyticsSource: this.analyticsSource
        })
            // .pipe(untilDestroyed(this))
            .subscribe(function (result) {
            if (result.startPage) {
                _this.routing.navigateApp(result.startPage.link);
            }
            else if (result.newPage) {
                _this.routing.navigateApp(_this.currentProjectStore.instance.newPageLink);
            }
        });
        this.analyticsService.sendSimpleEvent(AnalyticsEvent.Page.PageCreateClicked);
    };
    CustomizeMenuPagesComponent.prototype.deleteSelectedPages = function () {
        var _this = this;
        var pageUids = this.selectedPagesUids;
        var deletedCurrentRoute;
        return this.dialogService
            .dialog({
            title: "Delete pages (" + pageUids.length + ")",
            description: 'Are you sure want to delete selected pages?',
            style: 'orange',
            buttons: [
                {
                    name: 'cancel',
                    label: 'Cancel',
                    type: DialogButtonType.Default,
                    hotkey: DialogButtonHotkey.Cancel
                },
                {
                    name: 'yes',
                    label: 'OK',
                    type: DialogButtonType.Danger,
                    hotkey: DialogButtonHotkey.Submit,
                    executor: function () {
                        if (!pageUids.length) {
                            return of(0);
                        }
                        _this.deletePagesLoading = true;
                        _this.cd.markForCheck();
                        deletedCurrentRoute = _this.linkActiveDirectives
                            .filter(function (item) { return item.isActive; })
                            .map(function (item) {
                            var menuItem = item.appLinkActiveData['item'];
                            return menuItem ? menuItem.pageUid : undefined;
                        })
                            .find(function (item) { return pageUids.includes(item); });
                        return _this.deleteSelectedPagesProcess(pageUids);
                    }
                }
            ]
        })
            .pipe(untilDestroyed(this))
            .subscribe(function (result) {
            if (result.executorResult) {
                _this.deletePagesLoading = false;
                _this.setEditing(false);
                _this.cd.markForCheck();
                if (deletedCurrentRoute) {
                    var handler = _this.customizeService.handler;
                    var handlerPage = handler && handler.getPage ? handler.getPage() : undefined;
                    if (handlerPage && handlerPage.uid == deletedCurrentRoute) {
                        _this.customizeService.unsetHandler(handler);
                    }
                    _this.routing.navigateApp(_this.currentProjectStore.instance.homeLink);
                }
            }
        }, function () {
            _this.deletePagesLoading = false;
            _this.cd.markForCheck();
        });
    };
    CustomizeMenuPagesComponent.prototype.deleteSelectedPagesProcess = function (pageUids) {
        var _this = this;
        return combineLatest(pageUids.map(function (uid) {
            var viewSettings = new ViewSettings();
            viewSettings.uid = uid;
            return _this.viewSettingsService
                .delete(_this.currentProjectStore.instance.uniqueName, _this.currentEnvironmentStore.instance.uniqueName, viewSettings)
                .pipe(tap(function () {
                _this.analyticsService.sendSimpleEvent(AnalyticsEvent.Page.Deleted, {
                    PageID: viewSettings.uniqueName
                });
            }));
        })).pipe(map(function () { return pageUids.length; }), delayWhen(function () { return _this.viewSettingsStore.getFirst(true); }), delayWhen(function () { return _this.menuSettingsStore.getFirst(true); }));
    };
    CustomizeMenuPagesComponent.prototype.updateFiltered = function () {
        var compareObj = function (links) {
            return links.map(function (item) { return ({ title: item.title, link: item.link }); }).sort(objectsSortPredicate('title'));
        };
        var result = this.groups;
        var search = this.search.toLowerCase().trim();
        var duplicateGroup = this.groups.find(function (item) { return item.duplicate; });
        var otherItems = this.groups
            .filter(function (item) { return !item.duplicate; })
            .reduce(function (acc, item) {
            acc.push.apply(acc, item.items);
            return acc;
        }, []);
        if (isSet(search)) {
            result = result
                .filter(function (group) { return !group.searchIgnore; })
                .map(function (group) {
                return __assign({}, group, { items: group.items.filter(function (item) {
                        return item.title.toLowerCase().includes(search);
                    }) });
            });
        }
        else if (duplicateGroup && isEqual(compareObj(duplicateGroup.items), compareObj(otherItems))) {
            result = [duplicateGroup];
        }
        this.filteredQuery = search;
        this.filteredItems = result.reduce(function (acc, group) {
            acc.push.apply(acc, group.items);
            return acc;
        }, []);
        this.filteredGroups = result.filter(function (group) { return group.items.length || group.showEmpty; });
        this.cd.markForCheck();
    };
    CustomizeMenuPagesComponent.prototype.resetSearch = function () {
        this.search = '';
        this.updateFiltered();
    };
    CustomizeMenuPagesComponent.prototype.onSearchKey = function (e) {
        if (e.keyCode == KeyboardEventKeyCode.Escape) {
            this.resetSearch();
        }
        else {
            this.searchUpdated.next();
        }
    };
    CustomizeMenuPagesComponent.prototype.onItemClick = function (clickItem, event) {
        if (!this.editing) {
            this.closeMenu.emit();
        }
        else {
            event.preventDefault();
            event.stopPropagation();
            if (event.shiftKey) {
                var allItems = this.filteredGroups.reduce(function (acc, item) {
                    acc.push.apply(acc, item.items);
                    return acc;
                }, []);
                var fromIndex = this.lastSelectedPage ? allItems.indexOf(this.lastSelectedPage) : -1;
                var toIndex = allItems.indexOf(clickItem);
                var indexLower = Math.min(fromIndex, toIndex);
                var indexHigher = Math.max(fromIndex, toIndex);
                for (var i = indexLower + 1; i <= indexHigher - 1; ++i) {
                    this.setSelectedPage(allItems[i], true);
                }
            }
            this.toggleSelectedPage(clickItem, true);
        }
    };
    CustomizeMenuPagesComponent.prototype.setSelectedPage = function (item, selected) {
        if (!item.pageUid) {
            return;
        }
        this.selectedPages[item.pageUid] = selected;
        this.cd.markForCheck();
    };
    CustomizeMenuPagesComponent.prototype.toggleSelectedPage = function (item, click) {
        if (click === void 0) { click = false; }
        if (!item.pageUid) {
            return;
        }
        var currentValue = !!this.selectedPages[item.pageUid];
        var newValue = !currentValue;
        this.selectedPages[item.pageUid] = newValue;
        if (click && newValue) {
            this.lastSelectedPage = item;
        }
        this.cd.markForCheck();
    };
    Object.defineProperty(CustomizeMenuPagesComponent.prototype, "selectedPagesUids", {
        get: function () {
            return toPairs(this.selectedPages)
                .filter(function (_a) {
                var k = _a[0], v = _a[1];
                return v;
            })
                .map(function (_a) {
                var k = _a[0], v = _a[1];
                return k;
            });
        },
        enumerable: true,
        configurable: true
    });
    return CustomizeMenuPagesComponent;
}());
export { CustomizeMenuPagesComponent };

<app-alert [title]="'Integration with data source failed'" [danger]="true" [border]="true">
  <div *ngFor="let error of errors">
    {{ error }}
  </div>

  <button type="button" class="button button_danger button_small" style="margin: 10px 0 0 0;" (click)="openChat()">
    <span class="button__icon button__icon_left icon-chat"></span>
    <span class="button__label">Need help</span>
  </button>
</app-alert>

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { PlaygroundItemComponent } from './components/playground-item/playground-item.component';
import { PlaygroundComponent } from './components/playground/playground.component';
import { AppDragHandle } from './directives/drag-handle/drag-handle.directive';
import { AppDragPlaceholder } from './directives/drag-placeholder/drag-placeholder.directive';
import { AppDragPreview } from './directives/drag-preview/drag-preview.directive';
import { AppDrag } from './directives/drag/drag.directive';
import { AppDropListGroup } from './directives/drop-list-group/drop-list-group.directive';
import { AppDropList } from './directives/drop-list/drop-list.directive';
import { routes } from './drag-drop2.routes';

@NgModule({
  imports: [CommonModule, RouterModule.forChild(routes)],
  declarations: [
    PlaygroundComponent,
    AppDropList,
    AppDrag,
    AppDropListGroup,
    AppDragPlaceholder,
    AppDragHandle,
    AppDragPreview,
    PlaygroundItemComponent
  ],
  exports: [AppDropList, AppDrag, AppDropListGroup, AppDragPlaceholder, AppDragPreview, AppDragHandle]
})
export class DragDrop2Module {}

import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map, publishLast, refCount, switchMap } from 'rxjs/operators';
import { ApiService } from '@modules/api';
import { Template, TemplateType } from '../../data/template';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../../api/services/api/api.service";
var TemplateService = /** @class */ (function () {
    function TemplateService(http, apiService) {
        this.http = http;
        this.apiService = apiService;
    }
    TemplateService.prototype.get = function (params) {
        var _this = this;
        if (params === void 0) { params = {}; }
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.methodURL('templates/');
            var headers = new HttpHeaders();
            var httpParams = new HttpParams({ fromObject: params });
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.get(url, { headers: headers, params: httpParams });
        }), map(function (result) { return result.map(function (item) { return new Template().deserialize(item); }); }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    TemplateService.prototype.getDetail = function (id) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.methodURL("templates/" + id + "/");
            var headers = new HttpHeaders();
            var params = new HttpParams({
                fromObject: {
                    all: '1'
                }
            });
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.get(url, { headers: headers, params: params });
        }), map(function (result) { return new Template().deserialize(result); }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    TemplateService.prototype.getWithPredicate = function (type, predicate) {
        return this.get({ type: type }).pipe(map(function (result) {
            return result.filter(function (item) { return predicate(item); });
        }));
    };
    TemplateService.prototype.getDetailWithPredicate = function (type, predicate) {
        return this.get({ type: type, all: '1' }).pipe(map(function (result) {
            return result.find(function (item) { return predicate(item); });
        }));
    };
    TemplateService.prototype.getResourceDefaultModelDescription = function (resource) {
        return this.getDetailWithPredicate(TemplateType.ResourceDefaultModelDescription, function (template) { return template.forResources.find(function (item) { return item.typeItem.name == resource; }) !== undefined; });
    };
    TemplateService.prototype.getResourceModelDescriptionsTemplates = function (resource) {
        return this.getWithPredicate(TemplateType.ResourceModelDescriptionsTemplate, function (template) { return template.forResources.find(function (item) { return item.typeItem.name == resource; }) !== undefined; });
    };
    TemplateService.prototype.getDetailResourceModelDescriptionsTemplate = function (resource, name) {
        return this.getDetailWithPredicate(TemplateType.ResourceModelDescriptionsTemplate, function (template) {
            return template.forResources.find(function (item) { return item.typeItem.name == resource; }) !== undefined && template.uniqueName == name;
        });
    };
    TemplateService.prototype.getResourceInitialModelDescriptions = function (resource) {
        return this.getDetailWithPredicate(TemplateType.ResourceInitialModelDescriptions, function (template) { return template.forResources.find(function (item) { return item.typeItem.name == resource; }) !== undefined; });
    };
    TemplateService.prototype.create = function (instance, fields) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.methodURL("templates/");
            var data = instance.serialize(fields);
            var headers = new HttpHeaders();
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.post(url, data, { headers: headers });
        }), map(function (result) { return new Template().deserialize(result); }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    TemplateService.prototype.update = function (instance, fields) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.methodURL("templates/" + instance.id + "/");
            var data = instance.serialize(fields);
            var headers = new HttpHeaders();
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.patch(url, data, { headers: headers });
        }), map(function (result) { return new Template().deserialize(result); }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    TemplateService.prototype.delete = function (instance) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.methodURL("templates/" + instance.id + "/");
            var headers = new HttpHeaders();
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.delete(url, { headers: headers });
        }), map(function () { return true; }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    TemplateService.ngInjectableDef = i0.defineInjectable({ factory: function TemplateService_Factory() { return new TemplateService(i0.inject(i1.HttpClient), i0.inject(i2.ApiService)); }, token: TemplateService, providedIn: "root" });
    return TemplateService;
}());
export { TemplateService };

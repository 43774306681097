import {
  AfterViewInit,
  ChangeDetectorRef,
  ContentChildren,
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  QueryList,
  SimpleChanges
} from '@angular/core';
import { TweenMax } from 'gsap';
import uniq from 'lodash/uniq';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, fromEvent, Observable, Subscription } from 'rxjs';
import { filter, pairwise, startWith } from 'rxjs/operators';

import { addClass, isDescendant, isSet, MouseButton, removeClass } from '@shared';

import { AppDragDrop } from '../../data/drag-drop';
import { AppDragEnter } from '../../data/drag-enter';
import { AppDragExit } from '../../data/drag-exit';
import { AppDragSortEvent } from '../../data/drag-sort';
import { AppDragStart } from '../../data/drag-start';
import { AppDragStarted } from '../../data/drag-started';
import { DraggingState } from '../../data/dragging-state';
import { Point } from '../../data/point';
import { DragAxis, DragAxisType, DropListOrientation } from '../../data/types';
import { DropListService } from '../../services/drop-list/drop-list.service';
import { ScrollService } from '../../services/scroll/scroll.service';
import { coerceArray, moveInArray } from '../../utils/common';
import {
  applyElementPreviewStyles,
  createElementPreview,
  createOutsideIndicator,
  DRAG_OUTSIDE_INDICATOR_BOTTOM_CLASS,
  DRAG_OUTSIDE_INDICATOR_TOP_CLASS,
  getElementDepth,
  getElementViewport,
  isPointInside,
  moveElement,
  removeElement,
  replaceElement
} from '../../utils/document';
import { AppDrag } from '../drag/drag.directive';
import { AppDropListGroup } from '../drop-list-group/drop-list-group.directive';

const DRAG_PREVIEW_CLASS = 'app-drag-preview';
const DRAG_PLACEHOLDER_CLASS = 'app-drag-placeholder';
const DRAG_DROP_DRAGGING_CLASS = 'app-drag-drop-dragging';

export type Margin = [number, number, number, number];

@Directive({
  selector: '[appDropList]',
  exportAs: 'appDropList'
})
export class AppDropList<T = any> implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  @Input('appDropListLockAxis') lockAxis: DragAxisType;
  @Input('appDropListConnectedTo') connectedTo: (AppDropList | string)[] | AppDropList | string = [];
  @Input('appDropListConnectedToGroup') connectedToGroup: AppDropListGroup | AppDropListGroup[] = [];
  @Input('appDropListIgnoreParentGroup') ignoreParentGroup = false;
  @Input('appDropListData') data: T;
  @Input('appDropListOrientation') orientation: DropListOrientation = DropListOrientation.Vertical;
  @Input('appDropListEnterPredicate') enterPredicate: (drag: AppDrag, drop: AppDropList) => boolean;
  @Input('appDropListMovePredicate') movePredicate: (drag: AppDrag, drop: AppDropList) => boolean;
  @Input('appDropListSwapDistance') swapDistance = 0;
  @Input('appDropListSortingDisabled') sortingDisabled = false;
  @Input('appDropListCloneItems') cloneItems = false;
  @Input('appDropListOverrideViewport') overrideViewport: HTMLElement;
  @Input('appDropListAreaMargin') areaMargin: Margin = [0, 0, 0, 0];
  @Input('appDropListAreaMarginForward') areaMarginForward: Margin;
  @Input('appDropListAreaMarginBackward') areaMarginBackward: Margin;
  @Input('appDropListPreviewMissPointerStartDelta') previewMissPointerStartDelta: Point;
  @Input('appDropListPreviewSizeMatchSelector') previewSizeMatchSelector: string;
  @Input('appDropListOutsideIndicator') outsideIndicator = false;
  @Input('appDropListDisabled') disabled = false;
  @Input('appDropListDestroy') destroy = false;
  @Input('appDropListPrevent') prevent = false;
  @Input('appDropListPriority') priority = 0;
  @Input('appDropListParams') params = {};

  @Output('appDropListDragStarted') dragStarted = new EventEmitter<AppDragStarted>();
  @Output('appDropListDropped') dropped = new EventEmitter<AppDragDrop<T, any>>();
  @Output('appDropListEntered') entered = new EventEmitter<AppDragEnter<T, any>>();
  @Output('appDropListExited') exited = new EventEmitter<AppDragExit<T, any>>();
  @Output('appDropListSorted') sorted = new EventEmitter<AppDragSortEvent<T, any>>();
  @Output('appDropListDragged') dragged = new EventEmitter<AppDragDrop<T, any>>();

  @ContentChildren(AppDrag) draggables = new QueryList<AppDrag>();

  disabled$ = new BehaviorSubject<boolean>(this.disabled);

  private _draggingState = new BehaviorSubject<DraggingState<T, any>>(undefined);
  private siblings: AppDropList[] = [];
  private bounds: ClientRect;
  private currentDrags: AppDrag[] = [];
  private viewport: HTMLElement;
  private subscriptions: Subscription[] = [];

  constructor(
    private readonly el: ElementRef,
    @Optional() private readonly group: AppDropListGroup,
    private changeDetectorRef: ChangeDetectorRef,
    private dropListService: DropListService,
    private scrollService: ScrollService
  ) {}

  ngOnInit(): void {
    this.dropListService.registerDropList(this);

    if (this.group && !this.ignoreParentGroup) {
      this.registerForGroup(this.group);
    }

    coerceArray(this.connectedToGroup)
      .filter(item => item)
      .forEach(item => {
        this.registerForGroup(item);
      });

    this.disabled$.pipe(untilDestroyed(this)).subscribe(disabled => {
      if (disabled) {
        this.deinitDropList();
      } else {
        this.initDropList();
      }
    });
  }

  ngOnDestroy(): void {
    this.currentDrags.forEach(item => item.clearDropList(this));
    this.dropListService.unregisterDropList(this);

    if (this.group && !this.ignoreParentGroup) {
      this.unregisterFromGroup(this.group);
    }

    coerceArray(this.connectedToGroup)
      .filter(item => item)
      .forEach(item => {
        this.unregisterFromGroup(item);
      });

    this.scrollService.stopScroll();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['connectedTo']) {
      this.updateSiblings();
    }

    if (changes['viewport']) {
      this.updateViewport();
    }

    if (changes['disabled']) {
      this.disabled$.next(this.disabled);
    }
  }

  ngAfterViewInit(): void {
    this.initDrags();

    this.draggables.changes.pipe(untilDestroyed(this)).subscribe(e => {
      this.initDrags();
    });
  }

  private get draggingState(): DraggingState<T, any> {
    return this._draggingState.value;
  }

  private set draggingState(value: DraggingState<T, any>) {
    this._draggingState.next(value);
  }

  getDraggingState(): DraggingState<T, any> {
    return this._draggingState.value;
  }

  getDraggingState$(): Observable<DraggingState<T, any>> {
    return this._draggingState.asObservable();
  }

  isOrientationHorizontal() {
    return this.orientation == DropListOrientation.Horizontal;
  }

  registerForGroup(group: AppDropListGroup) {
    group.registerDropList(this);
    group.dropListsUpdated$.pipe(untilDestroyed(this)).subscribe(() => {
      this.updateSiblings();
    });
    this.updateSiblings();
  }

  unregisterFromGroup(group: AppDropListGroup) {
    group.unregisterDropList(this);
  }

  updateViewport() {
    if (this.overrideViewport) {
      this.viewport = this.overrideViewport;
    } else {
      this.viewport = getElementViewport(this.el.nativeElement);
    }
  }

  getViewport() {
    if (!this.viewport) {
      this.updateViewport();
    }

    return this.viewport;
  }

  initDropList() {
    this.deinitDropList();

    this.subscriptions.push(
      fromEvent<MouseEvent>(window.document, 'mousemove')
        .pipe(
          filter(() => !!this.draggingState),
          startWith(undefined),
          pairwise(),
          untilDestroyed(this)
        )
        .subscribe(([prevEvent, event]) => {
          const previousPosition: Point = prevEvent
            ? {
                x: prevEvent.x,
                y: prevEvent.y
              }
            : this.draggingState.startPosition;
          const previousPositionValue = this.isOrientationHorizontal() ? previousPosition.x : previousPosition.y;
          const positionValue = this.isOrientationHorizontal() ? event.x : event.y;
          let moveDirection: boolean;

          if (positionValue > previousPositionValue) {
            moveDirection = true;
          } else if (positionValue < previousPositionValue) {
            moveDirection = false;
          }

          event.preventDefault();

          this.dragMove(event, moveDirection);

          const viewport = this.getViewport();
          this.scrollService.scrollNearBounds(viewport, event);
        })
    );

    this.subscriptions.push(
      fromEvent<MouseEvent>(window.document, 'mouseup')
        .pipe(
          filter(e => e.button == MouseButton.Main),
          filter(() => !!this.draggingState),
          untilDestroyed(this)
        )
        .subscribe(e => this.dragFinish(e))
    );

    this.subscriptions.push(
      fromEvent(window, 'scroll')
        .pipe(
          filter(() => !!this.draggingState),
          untilDestroyed(this)
        )
        .subscribe(() => {
          this.updateIndicator();
        })
    );

    // this.updateBounds();
  }

  deinitDropList() {
    if (!this.subscriptions.length) {
      return;
    }

    this.subscriptions.forEach(item => item.unsubscribe());
    this.subscriptions = [];
  }

  initDrags() {
    const draggables = this.draggables.toArray();

    draggables.forEach(item => item.setDropList(this));
    this.currentDrags = draggables;
  }

  dragStart(e: AppDragStart) {
    this.dropListService.dragging$.next(true);
    this.dropListService.draggingDropList$.next(this);
    this.dropListService.draggingDragStart$.next(e);

    const position = { x: e.event.clientX, y: e.event.clientY };

    const customPreview = e.source.getCustomPreview();
    const preview = customPreview
      ? applyElementPreviewStyles(customPreview)
      : createElementPreview(e.source.getElement(), this.previewSizeMatchSelector);
    const dragBounds = e.source.getElement().getBoundingClientRect();

    if (!this.cloneItems) {
      e.source.initPlaceholder();
    }

    addClass(preview, DRAG_PREVIEW_CLASS);
    addClass(e.source.getElement(), DRAG_PLACEHOLDER_CLASS);
    addClass(document.body, DRAG_DROP_DRAGGING_CLASS);

    this.draggingState = {
      drag: e.source,
      fromIndex: this.currentDrags.indexOf(e.source),
      fromDropList: this,
      startPosition: position,
      startDelta: {
        x: e.event.clientX - dragBounds.left,
        y: e.event.clientY - dragBounds.top
      },
      moveDirection: true,
      lastSwapPosition: position,
      preview: preview,
      outsideIndicator: this.createIndicator()
    };

    document.body.appendChild(preview);

    this.updateDragMirror(position, true);

    this.dragStarted.emit({
      currentIndex: this.draggingState.fromIndex,
      item: this.draggingState.drag
    });
  }

  dragMove(e: MouseEvent, moveDirection?: boolean) {
    const position = { x: e.clientX, y: e.clientY };
    const swapLast = this.draggingState.lastSwapPosition;
    const swapDirection = this.isOrientationHorizontal() ? position.x >= swapLast.x : position.y >= swapLast.y;

    if (moveDirection !== undefined) {
      this.draggingState = {
        ...this.draggingState,
        moveDirection: moveDirection
      };
    }

    const positionDropList = this.siblings
      .filter(item => !item.disabled)
      .filter(item => !isDescendant(this.draggingState.drag.getElement(), item.el.nativeElement))
      .filter(item => !item.enterPredicate || item.enterPredicate(this.draggingState.drag, this))
      .sort((lhs, rhs) => {
        return (
          -1 * (getElementDepth(lhs.el.nativeElement) - getElementDepth(rhs.el.nativeElement)) +
          -1 * (lhs.priority - rhs.priority)
        );
      })
      .find(item => item.isPointInsideBounds(position, swapDirection));

    const targetDropList = positionDropList || this;

    this.updateDragMirror(position);

    if (targetDropList.prevent) {
      return;
    }

    const currentIndex = this.currentDrags.indexOf(this.draggingState.drag);
    let index = this.getIndexForPosition(targetDropList, position);

    // Disable sorting in current DropList with disabled sorting
    if (targetDropList === this && this.sortingDisabled) {
      return;
    }

    // Restore original position if transferring to initial DropList with disabled sorting
    if (
      targetDropList !== this &&
      targetDropList.sortingDisabled &&
      this.draggingState.fromDropList === targetDropList
    ) {
      index = this.draggingState.fromIndex;
    }

    if (targetDropList !== this || currentIndex !== index) {
      if (targetDropList.movePredicate && !targetDropList.movePredicate(this.draggingState.drag, this)) {
        return;
      }

      this.draggingState = {
        ...this.draggingState,
        lastSwapPosition: position
      };
      this.moveToDropList(targetDropList, index);
    }
  }

  moveToDropList(dropList: AppDropList, index: number) {
    if (dropList === this) {
      const previousIndex = this.currentDrags.indexOf(this.draggingState.drag);
      this.reorderCurrentDragWithIndex(index);
      this.updateIndicator();

      this.sorted.emit({
        previousIndex: previousIndex,
        currentIndex: index,
        container: this,
        item: this.draggingState.drag
      });
    } else {
      dropList.receiveDragging(this);
      dropList.reorderCurrentDragWithIndex(index);
      this.updateIndicator();

      dropList.entered.emit({
        container: dropList,
        item: dropList.draggingState.drag,
        currentIndex: index
      });
    }
  }

  dragFinish(e: MouseEvent) {
    const draggingState = this.draggingState;
    const currentIndex = this.currentDrags.indexOf(draggingState.drag);
    const position = { x: e.clientX, y: e.clientY };

    this.moveToDropList(draggingState.fromDropList, draggingState.fromIndex);
    draggingState.fromDropList.cleanUpDrag();
    this.scrollService.stopScroll();

    const dragDropEvent: AppDragDrop<T, any> = {
      previousIndex: draggingState.fromIndex,
      currentIndex: currentIndex,
      item: draggingState.drag,
      container: this,
      previousContainer: draggingState.fromDropList,
      isPointerOverContainer: this.isPointInsideBounds(position),
      data: draggingState.data
    };

    this.dropped.emit(dragDropEvent);
    this.dropListService.dropped$.next(dragDropEvent);

    if (draggingState.fromDropList !== this) {
      draggingState.fromDropList.dragged.emit(dragDropEvent);
    }

    this.changeDetectorRef.markForCheck();
    draggingState.fromDropList.changeDetectorRef.markForCheck();

    this.dropListService.dragging$.next(false);
    this.dropListService.draggingDropList$.next(undefined);
    this.dropListService.draggingDragStart$.next(undefined);
  }

  public receiveDragging(fromDropList: AppDropList<T>) {
    const drag = fromDropList.draggingState.drag;
    const state = fromDropList.removeDragging();

    fromDropList.exited.emit({
      container: fromDropList,
      item: drag
    });

    this.addDragging(state);

    this.dropListService.draggingDropList$.next(this);
  }

  public addDragging(state: DraggingState<T, any>) {
    const el = state.drag.getElement();
    this.draggingState = state;

    if (this.destroy) {
      removeElement(el);
    } else {
      this.el.nativeElement.appendChild(el);
    }

    this.currentDrags = [...this.currentDrags, state.drag];
    // this.updateBounds();

    if (this.draggingState.drag.isPlaceholderMissing()) {
      this.draggingState.drag.initPlaceholder();
    }

    // Remove clone if transferring back to source DropList with cloneItems
    if (this.draggingState.fromDropList === this && this.cloneItems) {
      if (this.draggingState.sourceDragClone) {
        removeElement(this.draggingState.sourceDragClone);
      }

      this.draggingState = {
        ...this.draggingState,
        sourceDragClone: undefined
      };
    }
  }

  public removeDragging(): DraggingState<T, any> {
    const el = this.draggingState.drag.getElement();

    // Clone item in source DropList with cloneItems
    if (this.draggingState.fromDropList === this && this.cloneItems) {
      const sourceDragClone = el.cloneNode(true) as HTMLElement;

      removeClass(sourceDragClone, DRAG_PLACEHOLDER_CLASS);
      replaceElement(el, sourceDragClone);

      this.draggingState = {
        ...this.draggingState,
        sourceDragClone: sourceDragClone
      };
    } else {
      removeElement(el);
    }

    this.currentDrags = this.currentDrags.filter(item => item !== this.draggingState.drag);

    const state = this.draggingState;
    this.draggingState = undefined;
    // this.updateBounds();

    return state;
  }

  updateSiblings() {
    const connectedLists = coerceArray(this.connectedTo)
      .filter(item => item)
      .map(selector => {
        if (selector instanceof AppDropList) {
          return selector;
        } else if (typeof selector == 'string') {
          const dropList = this.dropListService.dropLists.find(item => item.el.nativeElement.id == selector);

          if (!dropList && (typeof ngDevMode === 'undefined' || ngDevMode)) {
            console.warn(`AppDropList could not find connected drop list with id "${selector}"`);
          }

          return dropList;
        }
      })
      .filter(item => isSet(item));

    this.siblings = uniq([
      this,
      ...connectedLists,
      ...(this.group && !this.group.disabled && !this.ignoreParentGroup ? this.group.dropLists : []),
      ...coerceArray(this.connectedToGroup)
        .filter(item => item && !item.disabled)
        .reduce((acc, item) => {
          acc.push(...item.dropLists);
          return acc;
        }, [])
    ]);
  }

  updateBounds() {
    this.bounds = this.el.nativeElement.getBoundingClientRect();
  }

  getIndexForPosition(dropList: AppDropList, position: Point): number {
    const currentDragItem = {
      drag: this.draggingState.drag,
      position: this.isOrientationHorizontal() ? position.x : position.y
    };
    const dropListDragItems = dropList.currentDrags
      .filter(item => item !== this.draggingState.drag)
      .map(item => {
        const bounds = item.getBounds();
        let itemPosition: number;

        if (this.draggingState.moveDirection) {
          itemPosition = this.isOrientationHorizontal()
            ? bounds.left + this.swapDistance
            : bounds.top + this.swapDistance;
        } else {
          itemPosition = this.isOrientationHorizontal()
            ? bounds.right - this.swapDistance
            : bounds.bottom - this.swapDistance;
        }

        return {
          drag: item,
          position: itemPosition
        };
      });

    return [...dropListDragItems, currentDragItem]
      .sort((lhs, rhs) => lhs.position - rhs.position)
      .findIndex(item => item.drag === this.draggingState.drag);
  }

  reorderCurrentDragWithIndex(index: number) {
    moveElement(this.draggingState.drag.getElement(), index);
    this.currentDrags = moveInArray(this.currentDrags, this.draggingState.drag, index);
  }

  isPointInsideBounds(point: Point, swapDirection?: boolean) {
    // if (!this.bounds) {
    this.updateBounds();
    // }

    let margin: Margin;

    if (swapDirection === true) {
      margin = this.areaMarginForward || this.areaMargin;
    } else if (swapDirection === false) {
      margin = this.areaMarginBackward || this.areaMargin;
    } else {
      margin = this.areaMargin;
    }

    return isPointInside(point, this.bounds, margin);
  }

  updateDragMirror(position: Point, initial = false) {
    const positionX = this.lockAxis && this.lockAxis == DragAxis.Y ? this.draggingState.startPosition.x : position.x;
    const positionY = this.lockAxis && this.lockAxis == DragAxis.X ? this.draggingState.startPosition.y : position.y;
    const previewPositionY = positionY - this.draggingState.startDelta.y;
    const previewPositionX = positionX - this.draggingState.startDelta.x;

    TweenMax.set(this.draggingState.preview, {
      x: previewPositionX,
      y: previewPositionY
    });

    if (this.previewMissPointerStartDelta && initial) {
      const bounds = this.draggingState.preview.getBoundingClientRect();

      if (position.x > bounds.right || position.x < bounds.left) {
        this.draggingState = {
          ...this.draggingState,
          startDelta: this.previewMissPointerStartDelta
        };
      }

      if (position.y > bounds.bottom || position.y < bounds.top) {
        this.draggingState = {
          ...this.draggingState,
          startDelta: this.previewMissPointerStartDelta
        };
      }

      this.updateDragMirror(position);
    }
  }

  mergeDraggingStateData(data: Object) {
    const state = this.draggingState;

    if (!state) {
      return;
    }

    this.draggingState = {
      ...state,
      data: {
        ...(state.data || {}),
        ...data
      }
    };
  }

  createIndicator() {
    if (!this.outsideIndicator) {
      return;
    }

    const element = createOutsideIndicator();
    document.body.appendChild(element);
    return element;
  }

  updateIndicator() {
    if (!this.draggingState || !this.draggingState.outsideIndicator) {
      return;
    }

    const bounds = this.draggingState.drag.getElement().getBoundingClientRect();

    if (bounds.bottom <= 50) {
      addClass(this.draggingState.outsideIndicator, DRAG_OUTSIDE_INDICATOR_TOP_CLASS);
      removeClass(this.draggingState.outsideIndicator, DRAG_OUTSIDE_INDICATOR_BOTTOM_CLASS);
    } else if (bounds.top >= window.innerHeight) {
      removeClass(this.draggingState.outsideIndicator, DRAG_OUTSIDE_INDICATOR_TOP_CLASS);
      addClass(this.draggingState.outsideIndicator, DRAG_OUTSIDE_INDICATOR_BOTTOM_CLASS);
    } else {
      removeClass(this.draggingState.outsideIndicator, DRAG_OUTSIDE_INDICATOR_TOP_CLASS);
      removeClass(this.draggingState.outsideIndicator, DRAG_OUTSIDE_INDICATOR_BOTTOM_CLASS);
    }
  }

  cleanUpDrag() {
    this.draggingState.drag.destroyPlaceholder();
    this.draggingState.drag.destroyCustomPreview();

    removeElement(this.draggingState.preview);

    if (this.draggingState.outsideIndicator) {
      removeElement(this.draggingState.outsideIndicator);
    }

    removeClass(this.draggingState.drag.getElement(), DRAG_PLACEHOLDER_CLASS);
    removeClass(document.body, DRAG_DROP_DRAGGING_CLASS);

    // Clean clone item from source DropList
    if (this.draggingState.sourceDragClone) {
      removeElement(this.draggingState.sourceDragClone);
    }

    this.draggingState = undefined;
  }
}

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import cloneDeep from 'lodash/cloneDeep';
import { Observable } from 'rxjs';
import { map, publishLast, refCount, switchMap } from 'rxjs/operators';

import { ApiService } from '@modules/api';

import { Resource } from '../../data/resource';

@Injectable({
  providedIn: 'root'
})
export class ResourceService {
  constructor(private http: HttpClient, private apiService: ApiService) {}

  get(projectName: string, environmentName: string, params = {}, draft = false): Observable<Resource[]> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.environmentMethodURL(projectName, environmentName, 'resources/');
        let headers = new HttpHeaders();
        const httpParams = {
          ...params,
          ...(draft && { draft: '1' })
        };

        headers = this.apiService.setHeadersToken(headers);

        return this.http.get<Array<Object>>(url, { headers: headers, params: httpParams });
      }),
      map(result => result.map(item => new Resource().deserialize(item))),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  getDetail(projectName: string, environmentName: string, id: string): Observable<Resource> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.environmentMethodURL(projectName, environmentName, `resources/${id}/`);
        let headers = new HttpHeaders();

        headers = this.apiService.setHeadersToken(headers);

        return this.http.get<Array<Object>>(url, { headers: headers });
      }),
      map(result => new Resource().deserialize(result)),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  createBulk(
    projectName: string,
    environmentName: string,
    instances: Resource[],
    fields?: string[]
  ): Observable<Resource[]> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.environmentMethodURL(projectName, environmentName, 'resources/');
        let headers = new HttpHeaders();
        const params = {
          draft: '1'
        };
        const data = instances.map(item => item.serialize(fields));

        headers = this.apiService.setHeadersToken(headers);

        return this.http.post<Object[]>(url, data, { headers: headers, params: params });
      }),
      map(result => result.map(item => new Resource().deserialize(item))),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  create(projectName: string, environmentName: string, instance: Resource, fields?: string[]): Observable<Resource> {
    return this.createBulk(projectName, environmentName, [instance], fields).pipe(map(result => result[0]));
    // return this.apiService.refreshToken().pipe(
    //   switchMap(() => {
    //     const url = this.apiService.environmentMethodURL(projectName, environmentName, 'resources/');
    //     let headers = new HttpHeaders();
    //
    //     headers = this.apiService.setHeadersToken(headers);
    //
    //     return this.http.post(url, resource.serialize(), { headers: headers });
    //   }),
    //   map(result => new Resource().deserialize(result)),
    //   this.apiService.catchApiError(),
    //   publishLast(),
    //   refCount()
    // );
  }

  update(projectName: string, environmentName: string, instance: Resource, fields?: string[]): Observable<Resource> {
    return this.createBulk(projectName, environmentName, [instance], fields).pipe(map(result => result[0]));
    // return this.apiService.refreshToken().pipe(
    //   switchMap(() => {
    //     const url = this.apiService.environmentMethodURL(projectName, environmentName, `resources/${id}/`);
    //     let headers = new HttpHeaders();
    //
    //     headers = this.apiService.setHeadersToken(headers);
    //
    //     return this.http.patch(url, resource.serialize(fields), { headers: headers });
    //   }),
    //   map(result => new Resource().deserialize(result)),
    //   this.apiService.catchApiError(),
    //   publishLast(),
    //   refCount()
    // );
  }

  delete(projectName: string, environmentName: string, instance: Resource): Observable<boolean> {
    instance = cloneDeep(instance);
    instance.deleted = true;
    return this.createBulk(projectName, environmentName, [instance]).pipe(map(() => true));
    // return this.apiService.refreshToken().pipe(
    //   switchMap(() => {
    //     const url = this.apiService.environmentMethodURL(
    //       projectName,
    //       environmentName,
    //       `resources/${resource.uniqueName}/`
    //     );
    //     let headers = new HttpHeaders();
    //
    //     headers = this.apiService.setHeadersToken(headers);
    //
    //     return this.http.delete(url, { headers: headers });
    //   }),
    //   map(result => true),
    //   this.apiService.catchApiError(),
    //   publishLast(),
    //   refCount()
    // );
  }
}

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a;
import { moveItemInArray } from '@angular/cdk/drag-drop';
import { CdkConnectedOverlay } from '@angular/cdk/overlay';
import { AfterViewInit, ChangeDetectorRef, EventEmitter, OnChanges, OnDestroy, OnInit, QueryList } from '@angular/core';
import cloneDeep from 'lodash/cloneDeep';
import fromPairs from 'lodash/fromPairs';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject } from 'rxjs';
import { pairwise } from 'rxjs/operators';
import { FieldType, getFieldDescriptionByType, JsonStructureNodeType, ParameterControl } from '@modules/fields';
import { isSet } from '@shared';
export var nodeTypeDefaultNames = (_a = {},
    _a[JsonStructureNodeType.Field] = 'field',
    _a[JsonStructureNodeType.Object] = 'object',
    _a[JsonStructureNodeType.Array] = 'array',
    _a);
var JsonFieldStructureNodeComponent = /** @class */ (function () {
    function JsonFieldStructureNodeComponent(cd) {
        this.cd = cd;
        this.skipSelf = false;
        this.skipContent = false;
        this.created = false;
        this.dragItem = false;
        this.deep = 0;
        this.changed = new EventEmitter();
        this.deleted = new EventEmitter();
        this.trackNode = (function () {
            return function (i, item) {
                return isSet(item) ? item.name : i;
            };
        })();
        this.types = JsonStructureNodeType;
        this.childCollapsed = [];
        this.renameOpened = false;
        this.editOpened = false;
        this.popoverPositionsSubscriptions = [];
        this.fieldControl = new ParameterControl();
        this.dropdownPositions = [
            {
                panelClass: ['overlay_position_left-center'],
                originX: 'start',
                overlayX: 'end',
                originY: 'center',
                overlayY: 'center',
                offsetX: 0,
                offsetY: 0,
                weight: 2
            },
            {
                panelClass: ['overlay_position_left-top'],
                originX: 'start',
                overlayX: 'end',
                originY: 'top',
                overlayY: 'top',
                offsetX: 0,
                offsetY: -8,
                weight: 2
            },
            {
                panelClass: ['overlay_position_left-bottom'],
                originX: 'start',
                overlayX: 'end',
                originY: 'bottom',
                overlayY: 'bottom',
                offsetX: 0,
                offsetY: 8,
                weight: 2
            },
            {
                panelClass: ['overlay_position_right-center'],
                originX: 'end',
                overlayX: 'start',
                originY: 'center',
                overlayY: 'center',
                offsetX: 0,
                offsetY: 0,
                weight: 1
            },
            {
                panelClass: ['overlay_position_right-top'],
                originX: 'end',
                overlayX: 'start',
                originY: 'top',
                overlayY: 'top',
                offsetX: 0,
                offsetY: -8,
                weight: 1
            },
            {
                panelClass: ['overlay_position_right-bottom'],
                originX: 'end',
                overlayX: 'start',
                originY: 'bottom',
                overlayY: 'bottom',
                offsetX: 0,
                offsetY: 8,
                weight: 1
            }
        ];
        this.cdkConnectedOverlays = new QueryList();
    }
    JsonFieldStructureNodeComponent.prototype.ngOnInit = function () { };
    JsonFieldStructureNodeComponent.prototype.ngOnDestroy = function () { };
    JsonFieldStructureNodeComponent.prototype.ngOnChanges = function (changes) {
        if (changes.node) {
            var defaultCollapsed_1 = this.deep > 3;
            if (this.node && this.node.type == JsonStructureNodeType.Object) {
                this.childCollapsed = this.objectParams.items.map(function () { return defaultCollapsed_1; });
            }
            else if (this.node && this.node.type == JsonStructureNodeType.Array) {
                this.childCollapsed = [defaultCollapsed_1];
            }
            else if (this.node && this.node.type == JsonStructureNodeType.Field) {
                this.initFieldControl();
            }
        }
    };
    JsonFieldStructureNodeComponent.prototype.ngAfterViewInit = function () {
        this.setPositionObserver();
    };
    Object.defineProperty(JsonFieldStructureNodeComponent.prototype, "objectParams", {
        get: function () {
            return this.node.params;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(JsonFieldStructureNodeComponent.prototype, "arrayParams", {
        get: function () {
            return this.node.params;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(JsonFieldStructureNodeComponent.prototype, "fieldParams", {
        get: function () {
            return this.node.params;
        },
        enumerable: true,
        configurable: true
    });
    JsonFieldStructureNodeComponent.prototype.fieldIcon = function (field) {
        return getFieldDescriptionByType(field).icon;
    };
    JsonFieldStructureNodeComponent.prototype.toggleCollapse = function (i) {
        this.childCollapsed[i] = !this.childCollapsed[i];
    };
    JsonFieldStructureNodeComponent.prototype.getItemName = function (defaultName, names) {
        var namesObj = fromPairs(names.map(function (item) { return [item, true]; }));
        var i = 1;
        var newName;
        do {
            newName = i > 1 ? [defaultName, i].join('') : defaultName;
            ++i;
        } while (namesObj.hasOwnProperty(newName));
        return newName;
    };
    JsonFieldStructureNodeComponent.prototype.getItemParams = function (options) {
        if (options.type == JsonStructureNodeType.Field) {
            return {
                name: options.name,
                label: options.label,
                field: FieldType.Text
            };
        }
        else if (options.type == JsonStructureNodeType.Object) {
            var itemType = JsonStructureNodeType.Field;
            var itemName = nodeTypeDefaultNames[itemType];
            return {
                items: [
                    {
                        type: itemType,
                        name: itemName,
                        label: itemName,
                        params: this.getItemParams({ type: itemType, name: itemName, label: itemName })
                    }
                ]
            };
        }
        else if (options.type == JsonStructureNodeType.Array) {
            var itemName = nodeTypeDefaultNames[options.arrayType];
            return {
                item: {
                    type: options.arrayType,
                    name: name,
                    label: name,
                    params: this.getItemParams({ type: options.arrayType, name: itemName })
                }
            };
        }
    };
    JsonFieldStructureNodeComponent.prototype.createItem = function (type, names, options) {
        if (options === void 0) { options = {}; }
        var defaultName = nodeTypeDefaultNames[type];
        if (!defaultName) {
            return;
        }
        var name;
        if (options.self && !this.parentNode) {
            name = null;
        }
        else {
            name = this.getItemName(defaultName, names);
        }
        var params = this.getItemParams({ type: type, name: name, label: name, arrayType: options.arrayType });
        if (!params) {
            return;
        }
        return { type: type, name: name, label: null, params: params };
    };
    JsonFieldStructureNodeComponent.prototype.addItem = function (type, options) {
        if (options === void 0) { options = {}; }
        if (options.self) {
            var newItem = this.createItem(type, [], options);
            this.changed.emit(newItem);
        }
        else {
            var instance = cloneDeep(this.node);
            var instanceParams = instance.params;
            var names = instanceParams.items.map(function (item) { return item.name; });
            var newItem = this.createItem(type, names, options);
            this.createdNode = newItem;
            instanceParams.items.push(newItem);
            this.changed.emit(instance);
        }
    };
    JsonFieldStructureNodeComponent.prototype.changeItem = function (type, options) {
        if (options === void 0) { options = {}; }
        var params = this.getItemParams({ type: type, name: this.node.name, arrayType: options.arrayType });
        if (!params) {
            return;
        }
        var newInstance = { type: type, name: this.node.name, label: this.node.name, params: params };
        this.changed.emit(newInstance);
    };
    JsonFieldStructureNodeComponent.prototype.onObjectChildChanged = function (index, child) {
        var instance = cloneDeep(this.node);
        var params = instance.params;
        params.items[index] = child;
        this.changed.emit(instance);
    };
    JsonFieldStructureNodeComponent.prototype.onObjectChildDeleted = function (index) {
        var instance = cloneDeep(this.node);
        var params = instance.params;
        params.items = params.items.slice(0, index).concat(params.items.slice(index + 1));
        this.changed.emit(instance);
    };
    JsonFieldStructureNodeComponent.prototype.onArrayChildChanged = function (child) {
        var instance = cloneDeep(this.node);
        var params = instance.params;
        params.item = child;
        this.changed.emit(instance);
    };
    JsonFieldStructureNodeComponent.prototype.onNameChanged = function (name) {
        var instance = cloneDeep(this.node);
        instance.name = name;
        instance.label = name;
        if (instance.type == JsonStructureNodeType.Field) {
            var params = instance.params;
            params.name = name;
        }
        this.changed.emit(instance);
    };
    JsonFieldStructureNodeComponent.prototype.initFieldControl = function () {
        var _this = this;
        if (this.fieldControlSubscription) {
            this.fieldControlSubscription.unsubscribe();
            this.fieldControlSubscription = undefined;
        }
        var fieldParams = this.fieldParams;
        this.fieldControl.controls.field.patchValue(fieldParams.field);
        this.fieldControl.controls.required.patchValue(fieldParams.required);
        this.fieldControl.controls.editable.patchValue(fieldParams.editable);
        this.fieldControl.controls.params.patchValue(fieldParams.params);
        this.fieldControlSubscription = this.fieldControl.valueChanges.pipe(untilDestroyed(this)).subscribe(function () {
            var instance = cloneDeep(_this.node);
            instance.params = __assign({}, instance.params, { field: _this.fieldControl.controls.field.value, required: _this.fieldControl.controls.required.value, editable: _this.fieldControl.controls.editable.value, params: _this.fieldControl.controls.params.value });
            _this.changed.emit(instance);
        });
    };
    JsonFieldStructureNodeComponent.prototype.startRename = function () {
        var _this = this;
        if (!this.parentNode) {
            return;
        }
        if (this.renameOpened) {
            return;
        }
        this.renameOpened = true;
        this.renameName = this.node.name;
        this.renameName$ = new BehaviorSubject(this.renameName);
        this.renameLabel = this.node.type == JsonStructureNodeType.Field ? this.fieldParams.label : this.node.label;
        this.cd.markForCheck();
        this.renameNameSubscription = this.renameName$
            .pipe(pairwise(), untilDestroyed(this))
            .subscribe(function (_a) {
            var prevName = _a[0], currentName = _a[1];
            if (_this.renameLabel == prevName) {
                _this.renameLabel = currentName;
                _this.cd.markForCheck();
            }
        });
    };
    JsonFieldStructureNodeComponent.prototype.finishRename = function () {
        if (!this.renameOpened) {
            return;
        }
        if (this.renameNameSubscription) {
            this.renameNameSubscription.unsubscribe();
            this.renameNameSubscription = undefined;
        }
        var prevName = this.node.name;
        var prevLabel = this.node.type == JsonStructureNodeType.Field ? this.fieldParams.label : this.node.label;
        if (prevName != this.renameName || prevLabel != this.renameLabel) {
            var instance = cloneDeep(this.node);
            instance.name = this.renameName;
            instance.label = this.renameLabel;
            if (this.node.type == JsonStructureNodeType.Field) {
                instance.params = __assign({}, instance.params, { name: this.renameName, label: this.renameLabel });
            }
            this.changed.emit(instance);
        }
        this.renameOpened = false;
        this.renameName = undefined;
        this.renameLabel = undefined;
        this.cd.markForCheck();
    };
    JsonFieldStructureNodeComponent.prototype.setEditNodeOpened = function (opened) {
        this.editOpened = opened;
        this.cd.markForCheck();
    };
    JsonFieldStructureNodeComponent.prototype.onPopoverContentChanged = function (overlay) {
        overlay.overlayRef.updatePosition();
    };
    JsonFieldStructureNodeComponent.prototype.setPositionObserver = function () {
        var _this = this;
        this.popoverPositionsSubscriptions.forEach(function (item) { return item.unsubscribe(); });
        this.popoverPositionsSubscriptions = [];
        this.popoverPositionsSubscriptions = this.cdkConnectedOverlays.map(function (overlay) {
            return overlay.positionChange.pipe(untilDestroyed(_this)).subscribe(function (e) {
                var propsEqual = ['offsetX', 'offsetY', 'originX', 'originY', 'overlayX', 'overlayY'];
                var position = _this.dropdownPositions.find(function (item) {
                    return propsEqual.every(function (prop) { return (item[prop] || undefined) == e.connectionPair[prop]; });
                });
                var otherPosition = _this.dropdownPositions.filter(function (item) { return item !== position; });
                if (position) {
                    overlay.overlayRef.addPanelClass(position.panelClass);
                }
                otherPosition.forEach(function (item) { return overlay.overlayRef.removePanelClass(item.panelClass); });
            });
        });
    };
    JsonFieldStructureNodeComponent.prototype.objectItemsDragDrop = function (event) {
        if (event.previousIndex !== event.currentIndex) {
            var instance = cloneDeep(this.node);
            var params = instance.params;
            moveItemInArray(params.items, event.previousIndex, event.currentIndex);
            this.changed.emit(instance);
        }
    };
    return JsonFieldStructureNodeComponent;
}());
export { JsonFieldStructureNodeComponent };

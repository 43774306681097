/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../common/editable-content/components/editable-content/editable-content.component.ngfactory";
import * as i2 from "../../../../common/editable-content/components/editable-content/editable-content.component";
import * as i3 from "@angular/common";
import * as i4 from "../../../../common/tips/directives/tip/tip.directive";
import * as i5 from "../../../../shared/pipes/is-set/is-set.pipe";
import * as i6 from "../../../../shared/pipes/capitalize/capitalize.pipe";
import * as i7 from "./sidebar-header.component";
var styles_SidebarHeaderComponent = [];
var RenderType_SidebarHeaderComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SidebarHeaderComponent, data: {} });
export { RenderType_SidebarHeaderComponent as RenderType_SidebarHeaderComponent };
function View_SidebarHeaderComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-editable-content", [], null, [[null, "valueChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("valueChange" === en)) {
        var pd_0 = (_co.titleChanged.emit($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_EditableContentComponent_0, i1.RenderType_EditableContentComponent)), i0.ɵdid(1, 770048, null, 0, i2.EditableContentComponent, [i0.ChangeDetectorRef, i0.NgZone], { value: [0, "value"], pen: [1, "pen"], penTitle: [2, "penTitle"], cleanValue: [3, "cleanValue"] }, { valueChange: "valueChange" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; var currVal_1 = true; var currVal_2 = (_co.titleEditTip ? _co.titleEditTip : "Rename Component"); var currVal_3 = _co.titleCleanValue; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
function View_SidebarHeaderComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(1, null, [" - ", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.subtitle; _ck(_v, 1, 0, currVal_0); }); }
function View_SidebarHeaderComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(1, null, [" ", ""])), i0.ɵppd(2, 1), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SidebarHeaderComponent_6)), i0.ɵdid(4, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.subtitle; _ck(_v, 4, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i0.ɵnov(_v.parent.parent.parent.parent, 1), _co.title)); _ck(_v, 1, 0, currVal_0); }); }
function View_SidebarHeaderComponent_7(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(1, null, [" ", " "])), i0.ɵppd(2, 1)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i0.ɵnov(_v.parent.parent.parent.parent, 1), _co.title)); _ck(_v, 1, 0, currVal_0); }); }
function View_SidebarHeaderComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SidebarHeaderComponent_5)), i0.ɵdid(2, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SidebarHeaderComponent_7)), i0.ɵdid(4, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.backEnabled; _ck(_v, 2, 0, currVal_0); var currVal_1 = !_co.backEnabled; _ck(_v, 4, 0, currVal_1); }, null); }
function View_SidebarHeaderComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "sidebar-header__title"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SidebarHeaderComponent_3)), i0.ɵdid(2, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SidebarHeaderComponent_4)), i0.ɵdid(4, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.titleEditable; _ck(_v, 2, 0, currVal_0); var currVal_1 = !_co.titleEditable; _ck(_v, 4, 0, currVal_1); }, null); }
function View_SidebarHeaderComponent_8(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "a", [["class", "button button_orange-transparent button_icon button_no-margin sidebar-header__delete"], ["href", "javascript:void(0)"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.deleted.emit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 737280, null, 0, i4.TipDirective, [i0.ElementRef, i0.ComponentFactoryResolver, i0.Injector, i0.ApplicationRef], { content: [0, "content"], optionsRaw: [1, "optionsRaw"] }, null), i0.ɵpod(2, { side: 0 }), (_l()(), i0.ɵeld(3, 0, null, null, 0, "span", [["class", "icon icon-bin button__icon"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.deleteTip ? _co.deleteTip : "Delete Component"); var currVal_1 = _ck(_v, 2, 0, "left"); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_SidebarHeaderComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "div", [["class", "sidebar-header__element"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_SidebarHeaderComponent_2)), i0.ɵdid(2, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵppd(3, 1), i0.ɵncd(null, 0), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SidebarHeaderComponent_8)), i0.ɵdid(6, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (i0.ɵunv(_v, 2, 0, _ck(_v, 3, 0, i0.ɵnov(_v.parent, 0), _co.title)) || _co.titleEditable); _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.deleteEnabled; _ck(_v, 6, 0, currVal_1); }, null); }
function View_SidebarHeaderComponent_9(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "a", [["class", "sidebar-header__link"], ["href", "javascript:void(0)"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.back.next() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "span", [], null, null, null, null, null)), i0.ɵdid(2, 278528, null, 0, i3.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { ngClass: [0, "ngClass"] }, null), (_l()(), i0.ɵted(3, null, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = ("icon-" + _co.backIcon); _ck(_v, 2, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.backLabel; _ck(_v, 3, 0, currVal_1); }); }
function View_SidebarHeaderComponent_11(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_SidebarHeaderComponent_10(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 11, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 10, "div", [["class", "sidebar-header__container"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 9, "div", [["class", "sidebar-header__parent"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 5, "div", [["class", "sidebar-header__parent-main"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.parentClick.emit($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 0, "span", [["class", "icon-arrow_up sidebar-header__parent-icon"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 1, "span", [["class", "sidebar-header__parent-title"]], null, null, null, null, null)), (_l()(), i0.ɵted(6, null, ["", ""])), (_l()(), i0.ɵeld(7, 0, null, null, 1, "span", [["class", "sidebar-header__parent-subtitle"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["parent"])), (_l()(), i0.ɵeld(9, 0, null, null, 2, "div", [["class", "sidebar-header__parent-content"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SidebarHeaderComponent_11)), i0.ɵdid(11, 540672, null, 0, i3.NgTemplateOutlet, [i0.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null)], function (_ck, _v) { var currVal_1 = i0.ɵnov(_v.parent, 2); _ck(_v, 11, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.parentLabel; _ck(_v, 6, 0, currVal_0); }); }
function View_SidebarHeaderComponent_13(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["class", "sidebar-header__subtitle"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.subtitle; _ck(_v, 1, 0, currVal_0); }); }
function View_SidebarHeaderComponent_14(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_SidebarHeaderComponent_12(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 4, "div", [["class", "sidebar-header__container"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SidebarHeaderComponent_13)), i0.ɵdid(3, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SidebarHeaderComponent_14)), i0.ɵdid(5, 540672, null, 0, i3.NgTemplateOutlet, [i0.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.subtitle && !_co.backEnabled); _ck(_v, 3, 0, currVal_0); var currVal_1 = i0.ɵnov(_v.parent, 2); _ck(_v, 5, 0, currVal_1); }, null); }
export function View_SidebarHeaderComponent_0(_l) { return i0.ɵvid(2, [i0.ɵpid(0, i5.IsSetPipe, []), i0.ɵpid(0, i6.CapitalizePipe, []), (_l()(), i0.ɵand(0, [["element", 2]], null, 0, null, View_SidebarHeaderComponent_1)), (_l()(), i0.ɵeld(3, 0, null, null, 6, "div", [["class", "sidebar__header sidebar-header"]], [[2, "sidebar__header_compact", null], [2, "sidebar-header_parent", null]], null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SidebarHeaderComponent_9)), i0.ɵdid(5, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SidebarHeaderComponent_10)), i0.ɵdid(7, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SidebarHeaderComponent_12)), i0.ɵdid(9, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.backEnabled; _ck(_v, 5, 0, currVal_2); var currVal_3 = (_co.titleEnabled && _co.parentLabel); _ck(_v, 7, 0, currVal_3); var currVal_4 = (_co.titleEnabled && !_co.parentLabel); _ck(_v, 9, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.titleEnabled; var currVal_1 = _co.parentLabel; _ck(_v, 3, 0, currVal_0, currVal_1); }); }
export function View_SidebarHeaderComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-sidebar-header", [], null, null, null, View_SidebarHeaderComponent_0, RenderType_SidebarHeaderComponent)), i0.ɵdid(1, 114688, null, 0, i7.SidebarHeaderComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SidebarHeaderComponentNgFactory = i0.ɵccf("app-sidebar-header", i7.SidebarHeaderComponent, View_SidebarHeaderComponent_Host_0, { title: "title", titleEnabled: "titleEnabled", titleEditable: "titleEditable", titleCleanValue: "titleCleanValue", titleEditTip: "titleEditTip", subtitle: "subtitle", backEnabled: "backEnabled", backLabel: "backLabel", backIcon: "backIcon", deleteEnabled: "deleteEnabled", deleteTip: "deleteTip", parentLabel: "parentLabel" }, { back: "back", titleChanged: "titleChanged", deleted: "deleted", parentClick: "parentClick" }, ["ng-container[actions]"]);
export { SidebarHeaderComponentNgFactory as SidebarHeaderComponentNgFactory };

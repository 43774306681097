var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FormControl, FormGroup } from '@angular/forms';
import { HttpParameterType } from '@modules/queries';
import { isSet } from '@shared';
var HttpParameterControl = /** @class */ (function (_super) {
    __extends(HttpParameterControl, _super);
    function HttpParameterControl(formState) {
        if (formState === void 0) { formState = {}; }
        return _super.call(this, {
            name: new FormControl(formState.name !== undefined ? formState.name : ''),
            value: new FormControl(formState.value !== undefined ? formState.value : ''),
            type: new FormControl(formState.type !== undefined ? formState.type : HttpParameterType.Text)
        }) || this;
    }
    HttpParameterControl.prototype.isSet = function () {
        return isSet(this.controls.name.value) || isSet(this.controls.value.value);
    };
    return HttpParameterControl;
}(FormGroup));
export { HttpParameterControl };

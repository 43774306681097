import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';

import { BasePopupComponent } from '@common/popups';
import { ModelDescription } from '@modules/models';
import { Resource, ResourceName } from '@modules/projects';

import {
  registerResourceAddModelComponent,
  ResourceAddModelAddedEvent,
  ResourceAddModelComponent
} from '../../../data/resource-add-model-components';
import { GoogleSheetsImportFileForm } from './google-sheets-import-file.form';

@Component({
  selector: 'app-google-sheets-import-file',
  templateUrl: './google-sheets-import-file.component.html',
  providers: [GoogleSheetsImportFileForm],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GoogleSheetsImportFileComponent implements OnInit, OnDestroy, ResourceAddModelComponent {
  @Input() resource: Resource;
  @Output() cancelled = new EventEmitter<void>();
  @Output() added = new EventEmitter<ResourceAddModelAddedEvent>();

  loading = false;
  submitLoading = false;

  constructor(
    public form: GoogleSheetsImportFileForm,
    @Optional() protected popupComponent: BasePopupComponent,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.loading = true;
    this.cd.markForCheck();

    this.form
      .init(this.resource)
      .pipe(untilDestroyed(this))
      .subscribe(
        () => {
          this.loading = false;
          this.cd.markForCheck();
        },
        () => {
          this.loading = false;
          this.cd.markForCheck();
        }
      );
  }

  ngOnDestroy(): void {}

  close() {
    if (this.popupComponent) {
      this.popupComponent.close();
    }
  }

  submit() {
    this.submitLoading = true;
    this.cd.markForCheck();

    this.form
      .submit()
      .pipe(untilDestroyed(this))
      .subscribe(
        result => {
          this.close();
          this.added.emit({ modelDescription: result });
        },
        error => {
          console.error(error);
          this.submitLoading = false;
          this.cd.markForCheck();

          // const errorMessage = error instanceof ServerRequestError && error.errors.length ? error.errors[0] : error;
          //
          // if (this.resource) {
          //   this.analyticsService.sendSimpleEvent(AnalyticsEvent.Resource.ResourceUpdateFailed, {
          //     Source: this.analyticsSource,
          //     ResourceID: this.typeItem.name,
          //     Error: errorMessage
          //   });
          // } else {
          //   this.analyticsService.sendSimpleEvent(AnalyticsEvent.Resource.ResourceAddFailed, {
          //     Source: this.analyticsSource,
          //     ResourceID: this.typeItem.name,
          //     Error: errorMessage
          //   });
          // }
        }
      );
  }

  cancel() {
    this.cancelled.emit();
    this.close();
  }
}

registerResourceAddModelComponent(ResourceName.GoogleSheets, GoogleSheetsImportFileComponent, 'Import File', {
  icon: 'upload'
});

import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';

import { ViewContext, ViewContextElement } from '@modules/customize';
import { applyParamInput$, BaseField, Input } from '@modules/fields';

@Pipe({
  name: 'appApplyParamInput'
})
export class ApplyParamInputPipe implements PipeTransform {
  transform(
    input: Input,
    options: {
      context?: ViewContext;
      contextElement?: ViewContextElement;
      localContext?: Object;
      field?: BaseField;
      defaultValue?: any;
      raiseErrors?: boolean;
      debounce?: number;
    } = {}
  ): Observable<string> {
    return applyParamInput$(input, options);
  }
}

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../scanner-cameras-dropdown/scanner-cameras-dropdown.component.ngfactory";
import * as i2 from "../scanner-cameras-dropdown/scanner-cameras-dropdown.component";
import * as i3 from "@angular/cdk/overlay";
import * as i4 from "../../../../common/tips/directives/tip/tip.directive";
import * as i5 from "@angular/common";
import * as i6 from "../../../../common/unique-id/pipes/unique-id/unique-id.pipe";
import * as i7 from "../../../../common/unique-id/services/unique-id/unique-id.service";
import * as i8 from "../../../../shared/pipes/is-set/is-set.pipe";
import * as i9 from "./scanner.component";
import * as i10 from "../../../../common/notifications/services/notification/notification.service";
var styles_ScannerComponent = [];
var RenderType_ScannerComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ScannerComponent, data: {} });
export { RenderType_ScannerComponent as RenderType_ScannerComponent };
function View_ScannerComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "div", [["class", "scanner__background-icon icon-camera"]], null, null, null, null, null))], null, null); }
function View_ScannerComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 14, "div", [["class", "scanner__placeholder"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "app-scanner-cameras-dropdown", [], null, [[null, "selectCamera"], [null, "close"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selectCamera" === en)) {
        _co.setCameraDropdownOpened(false);
        var pd_0 = (_co.selectCamera($event.id) !== false);
        ad = (pd_0 && ad);
    } if (("close" === en)) {
        var pd_1 = (_co.setCameraDropdownOpened(false) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i1.View_ScannerCamerasDropdownComponent_0, i1.RenderType_ScannerCamerasDropdownComponent)), i0.ɵdid(2, 4440064, null, 0, i2.ScannerCamerasDropdownComponent, [], { origin: [0, "origin"], opened: [1, "opened"], cameras: [2, "cameras"], selectedCamera: [3, "selectedCamera"] }, { selectCamera: "selectCamera", close: "close" }), (_l()(), i0.ɵeld(3, 0, null, null, 4, "div", [["cdkOverlayOrigin", ""], ["class", "scanner__placeholder-button scanner__button"]], [[2, "scanner__button_disabled", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openCameraDropdown() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(4, 16384, [["camera_control", 4]], 0, i3.CdkOverlayOrigin, [i0.ElementRef], null, null), (_l()(), i0.ɵeld(5, 0, null, null, 0, "div", [["class", "scanner__button-icon icon-camera"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 1, "div", [["class", "scanner__button-label"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Scan with Camera"])), (_l()(), i0.ɵeld(8, 0, null, null, 1, "div", [["class", "scanner__placeholder-label"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["or"])), (_l()(), i0.ɵeld(10, 0, null, null, 4, "label", [["class", "scanner__placeholder-button scanner__button"]], [[8, "htmlFor", 0]], null, null, null, null)), i0.ɵppd(11, 2), (_l()(), i0.ɵeld(12, 0, null, null, 0, "span", [["class", "scanner__button-icon icon-image"]], null, null, null, null, null)), (_l()(), i0.ɵeld(13, 0, null, null, 1, "span", [["class", "scanner__button-label"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Scan Image file"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵnov(_v, 4); var currVal_1 = _co.cameraDropdownOpened; var currVal_2 = _co.cameras; var currVal_3 = _co.scanningCamera; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_4 = (_co.camerasLoading || _co.cameraDropdownOpened); _ck(_v, 3, 0, currVal_4); var currVal_5 = i0.ɵunv(_v, 10, 0, _ck(_v, 11, 0, i0.ɵnov(_v.parent, 0), "scanner_file", _co.idToken)); _ck(_v, 10, 0, currVal_5); }); }
function View_ScannerComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["class", "scanner__overlay"]], [[4, "background-image", null]], null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "div", [["class", "scanner__overlay-footer"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "div", [["class", "scanner__overlay-message"]], null, null, null, null, null)), (_l()(), i0.ɵted(3, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (("url(" + _co.scanConfirmImage) + ")"); _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.scanningImageError; _ck(_v, 3, 0, currVal_1); }); }
function View_ScannerComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "scanner__control icon-close"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.stopScanning() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 737280, null, 0, i4.TipDirective, [i0.ElementRef, i0.ComponentFactoryResolver, i0.Injector, i0.ApplicationRef], { content: [0, "content"], optionsRaw: [1, "optionsRaw"] }, null), i0.ɵpod(2, { side: 0 })], function (_ck, _v) { var currVal_0 = "Stop scanning"; var currVal_1 = _ck(_v, 2, 0, "left"); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_ScannerComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 12, "div", [["class", "scanner__controls"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "app-scanner-cameras-dropdown", [], null, [[null, "selectCamera"], [null, "close"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selectCamera" === en)) {
        _co.setCameraDropdownOpened(false);
        var pd_0 = (_co.selectCamera($event.id) !== false);
        ad = (pd_0 && ad);
    } if (("close" === en)) {
        var pd_1 = (_co.setCameraDropdownOpened(false) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i1.View_ScannerCamerasDropdownComponent_0, i1.RenderType_ScannerCamerasDropdownComponent)), i0.ɵdid(2, 4440064, null, 0, i2.ScannerCamerasDropdownComponent, [], { origin: [0, "origin"], opened: [1, "opened"], cameras: [2, "cameras"], selectedCamera: [3, "selectedCamera"] }, { selectCamera: "selectCamera", close: "close" }), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ScannerComponent_5)), i0.ɵdid(4, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(5, 0, null, null, 3, "div", [["cdkOverlayOrigin", ""], ["class", "scanner__control icon-camera"]], [[2, "scanner__control_disabled", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openCameraDropdown() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(6, 16384, [["camera_control", 4]], 0, i3.CdkOverlayOrigin, [i0.ElementRef], null, null), i0.ɵdid(7, 737280, null, 0, i4.TipDirective, [i0.ElementRef, i0.ComponentFactoryResolver, i0.Injector, i0.ApplicationRef], { content: [0, "content"], optionsRaw: [1, "optionsRaw"] }, null), i0.ɵpod(8, { side: 0 }), (_l()(), i0.ɵeld(9, 0, null, null, 3, "label", [["class", "scanner__control icon-image"]], [[8, "htmlFor", 0]], null, null, null, null)), i0.ɵdid(10, 737280, null, 0, i4.TipDirective, [i0.ElementRef, i0.ComponentFactoryResolver, i0.Injector, i0.ApplicationRef], { content: [0, "content"], optionsRaw: [1, "optionsRaw"] }, null), i0.ɵpod(11, { side: 0 }), i0.ɵppd(12, 2)], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵnov(_v, 6); var currVal_1 = _co.cameraDropdownOpened; var currVal_2 = _co.cameras; var currVal_3 = _co.scanningCamera; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_4 = _co.scanning; _ck(_v, 4, 0, currVal_4); var currVal_6 = "Choose camera"; var currVal_7 = _ck(_v, 8, 0, "left"); _ck(_v, 7, 0, currVal_6, currVal_7); var currVal_9 = "Scan Image file"; var currVal_10 = _ck(_v, 11, 0, "left"); _ck(_v, 10, 0, currVal_9, currVal_10); }, function (_ck, _v) { var _co = _v.component; var currVal_5 = (_co.camerasLoading || _co.cameraDropdownOpened); _ck(_v, 5, 0, currVal_5); var currVal_8 = i0.ɵunv(_v, 9, 0, _ck(_v, 12, 0, i0.ɵnov(_v.parent, 0), "scanner_file", _co.idToken)); _ck(_v, 9, 0, currVal_8); }); }
function View_ScannerComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, "div", [["class", "scanner__overlay scanner__overlay_bottom scanner__overlay_background"]], [[4, "background-image", null]], null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 7, "div", [["class", "scanner__overlay-footer"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "div", [["class", "scanner__overlay-message scanner__overlay-message_fill scanner__overlay-message_wrap"]], null, null, null, null, null)), (_l()(), i0.ɵted(3, null, [" ", " "])), (_l()(), i0.ɵeld(4, 0, null, null, 4, "div", [["class", "scanner__overlay-buttons"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 1, "div", [["class", "button button_fill scanner__overlay-button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.scanConfirmCancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["Scan again"])), (_l()(), i0.ɵeld(7, 0, null, null, 1, "div", [["class", "button button_primary button_fill scanner__overlay-button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.scanConfirmSubmit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, [" Use this scan "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (("url(" + _co.scanConfirmImage) + ")"); _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.scanConfirmValue; _ck(_v, 3, 0, currVal_1); }); }
export function View_ScannerComponent_0(_l) { return i0.ɵvid(2, [i0.ɵpid(0, i6.UniqueIdPipe, [i7.UniqueIdService]), i0.ɵpid(0, i8.IsSetPipe, []), i0.ɵqud(402653184, 1, { scannerElement: 0 }), (_l()(), i0.ɵeld(3, 0, null, null, 14, "div", [["class", "scanner"]], [[2, "scanner_disabled", null]], [[null, "dragover"], [null, "dragleave"], [null, "drop"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("dragover" === en)) {
        var pd_0 = (_co.onDragOver($event) !== false);
        ad = (pd_0 && ad);
    } if (("dragleave" === en)) {
        var pd_1 = (_co.onDragLeave($event) !== false);
        ad = (pd_1 && ad);
    } if (("drop" === en)) {
        var pd_2 = (_co.onDrop($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 2, "div", [["class", "scanner__background"]], [[2, "scanner__background_upload-empty", null], [2, "scanner__background_upload-active", null]], null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ScannerComponent_1)), i0.ɵdid(6, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(7, 0, [[1, 0], ["scanner", 1]], null, 1, "div", [["class", "scanner__viewport"]], [[8, "id", 0]], null, null, null, null)), i0.ɵppd(8, 2), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ScannerComponent_2)), i0.ɵdid(10, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_ScannerComponent_3)), i0.ɵdid(12, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵppd(13, 1), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ScannerComponent_4)), i0.ɵdid(15, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ScannerComponent_6)), i0.ɵdid(17, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(18, 0, null, null, 1, "input", [["class", "input input_file"], ["type", "file"]], [[8, "id", 0]], [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.onFileChange($event.target) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵppd(19, 2)], function (_ck, _v) { var _co = _v.component; var currVal_3 = (_co.scanning || _co.scanningInitialLoading); _ck(_v, 6, 0, currVal_3); var currVal_5 = ((!_co.scanning && !_co.scanningInitialLoading) && !_co.scanningImage); _ck(_v, 10, 0, currVal_5); var currVal_6 = i0.ɵunv(_v, 12, 0, _ck(_v, 13, 0, i0.ɵnov(_v, 1), (_co.scanningImage && _co.scanningImageError))); _ck(_v, 12, 0, currVal_6); var currVal_7 = ((_co.scanning || _co.scanningInitialLoading) || _co.scanningImage); _ck(_v, 15, 0, currVal_7); var currVal_8 = _co.scanConfirmImage; _ck(_v, 17, 0, currVal_8); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.scanningLoading || _co.scanningInitialLoading); _ck(_v, 3, 0, currVal_0); var currVal_1 = ((!_co.scanning && !_co.scanningInitialLoading) && !_co.scanningImage); var currVal_2 = _co.draggingOver; _ck(_v, 4, 0, currVal_1, currVal_2); var currVal_4 = i0.ɵunv(_v, 7, 0, _ck(_v, 8, 0, i0.ɵnov(_v, 0), "scanner", _co.idToken)); _ck(_v, 7, 0, currVal_4); var currVal_9 = i0.ɵunv(_v, 18, 0, _ck(_v, 19, 0, i0.ɵnov(_v, 0), "scanner_file", _co.idToken)); _ck(_v, 18, 0, currVal_9); }); }
export function View_ScannerComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-scanner", [], null, null, null, View_ScannerComponent_0, RenderType_ScannerComponent)), i0.ɵdid(1, 4964352, null, 0, i9.ScannerComponent, [i0.ElementRef, i10.NotificationService, i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ScannerComponentNgFactory = i0.ɵccf("app-scanner", i9.ScannerComponent, View_ScannerComponent_Host_0, { scanningInitial: "scanningInitial", scanConfirm: "scanConfirm" }, { scan: "scan" }, []);
export { ScannerComponentNgFactory as ScannerComponentNgFactory };

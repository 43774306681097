import { Directive, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { AppDropList } from '../drop-list/drop-list.directive';

@Directive({
  selector: '[appDropListGroup]',
  exportAs: 'appDropListGroup'
})
export class AppDropListGroup implements OnChanges {
  @Input('appDropListGroupDisabled') disabled = false;

  public readonly dropLists: AppDropList[] = [];
  private dropListsUpdated = new Subject<void>();

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    this.dropListsUpdated.next();
  }

  public registerDropList(dropList: AppDropList) {
    const index = this.dropLists.indexOf(dropList);

    if (index == -1) {
      this.dropLists.push(dropList);
      this.dropListsUpdated.next();
    }
  }

  public unregisterDropList(dropList: AppDropList) {
    const index = this.dropLists.indexOf(dropList);

    if (index != -1) {
      this.dropLists.splice(index, 1);
      this.dropListsUpdated.next();
    }
  }

  public get dropListsUpdated$(): Observable<void> {
    return this.dropListsUpdated.asObservable();
  }
}

import { BehaviorSubject, interval } from 'rxjs';
import { filter } from 'rxjs/operators';
import * as i0 from "@angular/core";
var IntercomService = /** @class */ (function () {
    function IntercomService() {
        var _this = this;
        this._loaded = new BehaviorSubject(false);
        this._visible = new BehaviorSubject(false);
        this._chatOpened = new BehaviorSubject(false);
        this.loadedCheck = interval(1000).subscribe(function () {
            if (!_this.intercom) {
                return;
            }
            _this.loadedCheck.unsubscribe();
            _this._loaded.next(true);
        });
        this.loaded$.subscribe(function () {
            interval(400).subscribe(function () {
                _this._visible.next(_this.visible);
            });
            _this._chatOpened.next(_this.chatOpened);
            _this.intercom('onShow', function () {
                _this._chatOpened.next(true);
            });
            _this.intercom('onHide', function () {
                _this._chatOpened.next(false);
            });
        });
    }
    Object.defineProperty(IntercomService.prototype, "loaded$", {
        get: function () {
            return this._loaded.pipe(filter(function (item) { return item == true; }));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(IntercomService.prototype, "intercom", {
        get: function () {
            return window['Intercom'];
        },
        enumerable: true,
        configurable: true
    });
    IntercomService.prototype.openChat = function () {
        var _this = this;
        this.loaded$.subscribe(function () {
            _this.intercom('show');
        });
    };
    IntercomService.prototype.show = function () { };
    IntercomService.prototype.hide = function () { };
    Object.defineProperty(IntercomService.prototype, "chatOpened", {
        get: function () {
            return document.getElementsByClassName('intercom-messenger-frame').length != 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(IntercomService.prototype, "visible", {
        get: function () {
            return document.getElementsByClassName('intercom-launcher').length != 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(IntercomService.prototype, "visible$", {
        get: function () {
            return this._visible.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    IntercomService.ngInjectableDef = i0.defineInjectable({ factory: function IntercomService_Factory() { return new IntercomService(); }, token: IntercomService, providedIn: "root" });
    return IntercomService;
}());
export { IntercomService };

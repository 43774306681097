var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { SelectSource } from 'ng-gxselect';
import { of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ResourceType } from '@modules/projects';
import { ResourceControllerService } from '@modules/resources';
var SmartSuiteSolutionSource = /** @class */ (function (_super) {
    __extends(SmartSuiteSolutionSource, _super);
    function SmartSuiteSolutionSource(resourceControllerService) {
        var _this = _super.call(this) || this;
        _this.resourceControllerService = resourceControllerService;
        _this.loadedPage = false;
        _this.controller = _this.resourceControllerService.get(ResourceType.SmartSuite);
        return _this;
    }
    SmartSuiteSolutionSource.prototype.fetch = function (searchQuery, markLoaded) {
        var _this = this;
        if (markLoaded === void 0) { markLoaded = true; }
        return this.controller.getSolutions({ accountId: this.accountId, key: this.key }).pipe(map(function (result) {
            return result
                .map(function (item) {
                return {
                    value: item.id,
                    name: item.name
                };
            })
                .filter(function (item) {
                if (!searchQuery) {
                    return true;
                }
                return item.name.toLowerCase().indexOf(searchQuery.toLowerCase()) != -1;
            });
        }), tap(function () {
            if (markLoaded) {
                _this.loadedPage = true;
            }
        }));
    };
    SmartSuiteSolutionSource.prototype.fetchByValue = function (value) {
        if (!value) {
            return of(undefined);
        }
        return this.fetch('', false).pipe(map(function (items) { return items.find(function (i) { return i.value == value; }); }));
    };
    SmartSuiteSolutionSource.prototype.isFetchAvailable = function () {
        return !this.loadedPage;
    };
    SmartSuiteSolutionSource.prototype.resetPagination = function () {
        this.loadedPage = false;
    };
    SmartSuiteSolutionSource.prototype.setStaticOptions = function (options) { };
    return SmartSuiteSolutionSource;
}(SelectSource));
export { SmartSuiteSolutionSource };

import { ChangeDetectorRef, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import * as Color from 'color';
import toPairs from 'lodash/toPairs';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { DragAxis, moveItemInFormArray, transferFormArrayItem } from '@common/drag-drop2';
import { AnalyticsEvent, AnalyticsEventAction, UniversalAnalyticsService } from '@modules/analytics';
import { getColorHex, isLightColor } from '@modules/colors';
import { applyParamInput$ } from '@modules/fields';
import { getLogoControlsUrl } from '@modules/layout-components';
import { ImageMenuItemOption, MenuBlockLayout } from '@modules/menu';
import { MenuContext } from '@modules/menu-components';
import { CurrentProjectStore } from '@modules/projects';
import { CurrentUserStore } from '@modules/users';
import { controlValue, deployUrl, isSet } from '@shared';
import { CustomizeBarPagesEditImageControl } from '../customize-bar-pages-edit/image-control';
import { CustomizeBarPagesEditSectionControl } from '../customize-bar-pages-edit/section-control';
var ImageMenuItemEditComponent = /** @class */ (function () {
    function ImageMenuItemEditComponent(context, currentProjectStore, currentUserStore, sanitizer, analyticsService, cd) {
        this.context = context;
        this.currentProjectStore = currentProjectStore;
        this.currentUserStore = currentUserStore;
        this.sanitizer = sanitizer;
        this.analyticsService = analyticsService;
        this.cd = cd;
        this.deleteRequested = new EventEmitter();
        this.imageColorLight = false;
        this.imageOptions = [];
        this.iconPopoverOpened = false;
        this.editPopoverOpened = false;
        this.editPopoverOptions = {};
        this.childrenExpanded = false;
        this.dragAxis = DragAxis;
    }
    ImageMenuItemEditComponent.prototype.trackMenuItemFn = function (i, item) {
        return item.menuItem.id;
    };
    ImageMenuItemEditComponent.prototype.ngOnInit = function () {
        var _this = this;
        combineLatest(this.currentProjectStore.get(), this.currentUserStore.get())
            .pipe(switchMap(function (_a) {
            var project = _a[0], user = _a[1];
            _this.imageOptions = [
                {
                    value: ImageMenuItemOption.UserPhoto,
                    name: 'Current user photo',
                    icon: 'human_being',
                    data: {
                        url: user && isSet(user.photo) ? user.photo : deployUrl('/assets/images/no-photo.svg')
                    }
                },
                {
                    value: ImageMenuItemOption.ProjectLogo,
                    name: 'App logo',
                    icon: 'image',
                    data: {
                        url: project ? project.logo : undefined,
                        initials: project ? project.initials : undefined
                    }
                }
            ];
            _this.cd.markForCheck();
            return getLogoControlsUrl({
                urlControl: _this.control.controls.image_custom,
                optionControl: _this.control.controls.image_option,
                options: _this.imageOptions
            });
        }), untilDestroyed(this))
            .subscribe(function (value) {
            _this.imageUrl = value ? value.url : undefined;
            _this.imageInitials = value ? value.initials : undefined;
            _this.cd.markForCheck();
        });
        this.control
            .getImageColor$()
            .pipe(untilDestroyed(this))
            .subscribe(function (value) {
            _this.imageColor = value;
            _this.imageColorLight = isLightColor(_this.imageColor);
            _this.imageStyle = _this.getStyleVars(_this.imageColor);
            _this.cd.markForCheck();
        });
        controlValue(this.control.controls.title)
            .pipe(map(function () { return _this.control.controls.title.serialize(); }), switchMap(function (input) {
            return applyParamInput$(input, {
                context: _this.context,
                defaultValue: ''
            });
        }), untilDestroyed(this))
            .subscribe(function (value) {
            _this.title = value;
            _this.cd.markForCheck();
        });
        controlValue(this.control.controls.subtitle)
            .pipe(map(function () { return _this.control.controls.subtitle.serialize(); }), switchMap(function (input) {
            return applyParamInput$(input, {
                context: _this.context,
                defaultValue: ''
            });
        }), untilDestroyed(this))
            .subscribe(function (value) {
            _this.subtitle = value;
            _this.cd.markForCheck();
        });
        if (this.createdOptions && this.createdOptions.customize) {
            this.openEditPopover(true, { focusTitle: true });
        }
        if (this.createdOptions && this.createdOptions.opened) {
            this.setChildrenExpanded(true);
        }
    };
    ImageMenuItemEditComponent.prototype.ngOnDestroy = function () { };
    ImageMenuItemEditComponent.prototype.getDarkColor = function (color) {
        if (!isSet(color)) {
            color = 'white';
        }
        try {
            var colorHex = getColorHex(color);
            var clr = Color(colorHex);
            return clr.darken(0.6).string();
        }
        catch (e) { }
    };
    ImageMenuItemEditComponent.prototype.getStyleVars = function (color) {
        var darkColor = this.getDarkColor(color);
        var vars = {};
        if (darkColor) {
            vars['dark-color'] = darkColor;
        }
        var styles = toPairs(vars)
            .map(function (_a) {
            var k = _a[0], v = _a[1];
            return "--" + k + ": " + v;
        })
            .join(';');
        return this.sanitizer.bypassSecurityTrustStyle(styles);
    };
    ImageMenuItemEditComponent.prototype.isDroppable = function (item) {
        return !(item.data instanceof CustomizeBarPagesEditSectionControl);
    };
    ImageMenuItemEditComponent.prototype.openIconPopover = function (value) {
        this.iconPopoverOpened = value;
        this.cd.markForCheck();
    };
    ImageMenuItemEditComponent.prototype.openEditPopover = function (value, options) {
        if (options === void 0) { options = {}; }
        this.editPopoverOpened = value;
        this.editPopoverOptions = options;
        this.cd.markForCheck();
    };
    ImageMenuItemEditComponent.prototype.setChildrenExpanded = function (value) {
        this.childrenExpanded = value;
        this.cd.markForCheck();
    };
    ImageMenuItemEditComponent.prototype.toggleChildrenExpanded = function () {
        this.setChildrenExpanded(!this.childrenExpanded);
    };
    ImageMenuItemEditComponent.prototype.addMenuItem = function (options) {
        this.createdMenuItemControl = this.control.children.addControl(options.item, options.prepend);
        this.createdMenuItemControlOptions = options;
        this.setChildrenExpanded(true);
        this.analyticsService.sendEvent(AnalyticsEvent.GA.MenuItem, AnalyticsEventAction.Created);
        this.analyticsService.sendSimpleEvent(AnalyticsEvent.Menu.MenuItemAdded);
    };
    ImageMenuItemEditComponent.prototype.dragDrop = function (event) {
        if (event.previousContainer === event.container) {
            moveItemInFormArray(event.container.data, event.previousIndex, event.currentIndex);
        }
        else {
            transferFormArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
        }
    };
    return ImageMenuItemEditComponent;
}());
export { ImageMenuItemEditComponent };

<app-customize-workflow-step-header
  [step]="step"
  [nameControl]="workflowEditable ? form.controls.name : undefined"
  [nameValue]="form.controls.name.value"
  [deleteEnabled]="workflowEditable"
  (close)="closeCustomize.emit()"
  (delete)="stepDelete.emit()"
></app-customize-workflow-step-header>

<div class="sidebar__content">
  <app-sidebar-section [disabled]="!workflowEditable">
    <div class="sidebar__element">
      <div class="sidebar-icon-buttons">
        <div
          *ngFor="let item of form.typeOptions"
          class="sidebar-icon-button"
          [class.sidebar-icon-button_active]="form.controls.condition_type.value == item.value"
          (click)="form.setConditionType(item.value)"
        >
          <div class="sidebar-icon-button__icon" [ngClass]="['icon-' + item.icon]"></div>
          <div class="sidebar-icon-button__label">{{ item.name }}</div>
        </div>
      </div>
    </div>

    <div *ngIf="form.controls.condition_type.value == conditionTypes.Boolean" class="sidebar__element">
      <app-sidebar-field [label]="'Condition to check'">
        <app-input-edit
          *ngIf="form.controls.items.controls[0]"
          [itemForm]="form.controls.items.controls[0].controls.condition"
          [context]="context"
          [staticValueDisabled]="true"
          [placeholder]="'Value'"
          [fill]="true"
        ></app-input-edit>

        <ng-container description>
          Example: EQ(steps.query1.status, 'active')
        </ng-container>
      </app-sidebar-field>
    </div>

    <div *ngIf="form.controls.condition_type.value == conditionTypes.Switch" class="sidebar__list">
      <div cdkDropList [cdkDropListLockAxis]="'y'" class="sidebar-list" (cdkDropListDropped)="dragDropItem($event)">
        <app-condition-customize-workflow-step-switch-item
          *ngFor="let stepItemControl of form.controls.items.controls; let i = index; trackBy: trackByFn"
          cdkDrag
          class="sidebar-list__item"
          [control]="stepItemControl"
          [title]="
            (stepItemControl.controls.label.value | appIsSet)
              ? stepItemControl.controls.label.value
              : 'Condition ' + (i + 1)
          "
          [defaultTitle]="'Condition ' + (i + 1)"
          [collapseContext]="collapseContext"
          [openedInitial]="stepItemControl === createdItem"
          [context]="context"
          [contextElement]="contextElement"
          (remove)="removeItem(stepItemControl, i)"
        ></app-condition-customize-workflow-step-switch-item>

        <div class="sidebar-list__item">
          <div class="sidebar-list-item" (mouseenter)="elseHovered$.next(true)" (mouseleave)="elseHovered$.next(false)">
            <div class="sidebar-list-item__main">
              <div class="sidebar-list-item__title">
                <app-editable-content
                  [value]="form.controls.else_label.value"
                  [emptyValue]="'Else'"
                  [pen]="elseHovered$ | async"
                  [penTitle]="'Rename condition'"
                  (valueChange)="form.controls.else_label.patchValue($event)"
                >
                </app-editable-content>
              </div>
              <div class="sidebar-list-item__description">Runs when other conditions are not met</div>
            </div>
          </div>
        </div>

        <div class="sidebar-list__buttons">
          <a
            href="javascript:void(0)"
            class="button button_orange-transparent button_small sidebar-list__buttons-item"
            (click)="addItem()"
          >
            <span class="icon icon-plus button__icon button__icon_left"></span>
            <span class="button__label">Add Condition</span>
          </a>

          <app-field-errors
            *ngIf="!form.controls.items.controls.length"
            [form]="form"
            [fieldName]="'items'"
          ></app-field-errors>
        </div>
      </div>
    </div>
  </app-sidebar-section>
</div>

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ChangeDetectorRef, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Subject } from 'rxjs';
import { localize } from '@common/localize';
import { BasePopupComponent } from '@common/popups';
import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { ResourceName, resourcesCustom, ResourceTypeItemCategory, resourceTypeItems } from '@modules/projects';
import { CurrentUserStore } from '@modules/users';
import { KeyboardEventKeyCode } from '@shared';
import { TemplateItemType } from '../../data/template-item-type';
export var TemplatePreviewSection;
(function (TemplatePreviewSection) {
    TemplatePreviewSection["Templates"] = "templates";
    TemplatePreviewSection["Resources"] = "resources";
})(TemplatePreviewSection || (TemplatePreviewSection = {}));
var TemplatePreviewComponent = /** @class */ (function () {
    function TemplatePreviewComponent(popupComponent, currentUserStore, analyticsService, cd) {
        this.popupComponent = popupComponent;
        this.currentUserStore = currentUserStore;
        this.analyticsService = analyticsService;
        this.cd = cd;
        this.templates = [];
        this.cancel = false;
        this.templateApply = new EventEmitter();
        this.adminPanelTemplateApply = new EventEmitter();
        this.loading = false;
        this.section = TemplatePreviewSection.Templates;
        this.sections = TemplatePreviewSection;
        this.breadcrumbs = [];
        this.analyticsEvents = AnalyticsEvent;
        this.templatesFiltered = [];
        this.templateItemTypes = TemplateItemType;
        this.templateSearch = '';
        this.templateSearchUpdated = new Subject();
        this.tagsMaximum = 4;
        this.customResource = resourceTypeItems.find(function (item) { return item.name == ResourceName.JetDatabase; });
        this.resourceFilters = [
            {
                label: localize('All Integrations')
            },
            {
                label: localize('Databases'),
                filter: { category: ResourceTypeItemCategory.Databases }
            },
            {
                label: localize('APIs'),
                filter: { category: ResourceTypeItemCategory.APIs }
            },
            {
                label: localize('Frameworks'),
                filter: { category: ResourceTypeItemCategory.Frameworks }
            },
            {
                label: localize('Storages'),
                filter: { category: ResourceTypeItemCategory.Storages }
            }
        ];
        this.resourceFilterIndex = 0;
        this.resourceFilterCounts = [];
        this.resourceSearchUpdated = new Subject();
    }
    TemplatePreviewComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.templateSearchUpdated.pipe(untilDestroyed(this)).subscribe(function () { return _this.updateTemplatesFiltered(); });
        this.updateTemplatesFiltered();
        this.resources =
            this.selectedTemplate && this.selectedTemplate.selectResources
                ? this.selectedTemplate.selectResources
                : resourceTypeItems.filter(function (item) { return !item.hidden && !item.protected && item.name != ResourceName.JetDatabase; });
        this.resourcesNotFound = resourceTypeItems.filter(function (item) { return resourcesCustom.includes(item.name); });
        this.resourceSearchUpdated.pipe(untilDestroyed(this)).subscribe(function () { return _this.updateResourcesFiltered(); });
        this.updateResourcesFiltered();
    };
    TemplatePreviewComponent.prototype.ngOnDestroy = function () { };
    TemplatePreviewComponent.prototype.updateTemplatesFiltered = function () {
        var _this = this;
        this.templatesFiltered = this.templates
            ? this.templates.filter(function (item) {
                if (!_this.templateSearch) {
                    return true;
                }
                return item.name.toLowerCase().includes(_this.templateSearch.toLowerCase());
            })
            : undefined;
        this.cd.markForCheck();
    };
    TemplatePreviewComponent.prototype.updateResourcesFiltered = function () {
        var _this = this;
        var filterResources = function (resources, filter) {
            return resources.filter(function (item) {
                if (filter) {
                    if (filter.category != undefined) {
                        if (!item.categories.includes(filter.category)) {
                            return false;
                        }
                    }
                }
                if (_this.resourceSearch) {
                    if (item.label.toLowerCase().includes(_this.resourceSearch.toLowerCase()) == false) {
                        return false;
                    }
                }
                return true;
            });
        };
        this.resourceFilterCounts = this.resourceFilters.map(function (item) { return filterResources(_this.resources, item.filter).length; });
        if (!this.resourceFilterCounts[this.resourceFilterIndex]) {
            this.resourceFilterIndex = 0;
        }
        var currentFilter = this.currentResourceFilter;
        this.resourcesFiltered = filterResources(this.resources, currentFilter);
        this.cd.markForCheck();
    };
    TemplatePreviewComponent.prototype.clearSearch = function () {
        this.resourceSearch = '';
        this.resourceSearchUpdated.next();
        this.cd.markForCheck();
    };
    TemplatePreviewComponent.prototype.onSearchKey = function (e) {
        if (e.keyCode == KeyboardEventKeyCode.Escape) {
            this.clearSearch();
        }
    };
    Object.defineProperty(TemplatePreviewComponent.prototype, "currentResourceFilter", {
        get: function () {
            if (!this.resourceFilters[this.resourceFilterIndex]) {
                return;
            }
            return this.resourceFilters[this.resourceFilterIndex].filter;
        },
        enumerable: true,
        configurable: true
    });
    TemplatePreviewComponent.prototype.setSelectedResource = function (resource) {
        this.selectedResource = resource;
        this.cd.markForCheck();
    };
    TemplatePreviewComponent.prototype.setResourceFilterIndex = function (i) {
        this.resourceFilterIndex = i;
        this.cd.markForCheck();
        this.updateResourcesFiltered();
    };
    TemplatePreviewComponent.prototype.navigate = function (section) {
        this.breadcrumbs = this.breadcrumbs.concat([this.section]);
        this.section = section;
        this.cd.markForCheck();
        if (section == TemplatePreviewSection.Resources) {
            this.analyticsService.sendSimpleEvent(AnalyticsEvent.Template.StartedToChooseResources, {
                TemplateID: this.selectedTemplate.name,
                Source: 'home_templates_preview'
            });
        }
    };
    TemplatePreviewComponent.prototype.navigateBack = function () {
        this.section = this.previousSection;
        this.breadcrumbs = this.breadcrumbs.slice(0, this.breadcrumbs.length - 1);
        this.cd.markForCheck();
    };
    Object.defineProperty(TemplatePreviewComponent.prototype, "previousSection", {
        get: function () {
            return this.breadcrumbs[this.breadcrumbs.length - 1];
        },
        enumerable: true,
        configurable: true
    });
    TemplatePreviewComponent.prototype.isVideoUrl = function (url) {
        return ['mp4', 'webm', 'ogg'].some(function (item) { return url.toLowerCase().endsWith("." + item); });
    };
    TemplatePreviewComponent.prototype.applyTemplate = function (template) {
        this.close();
        this.templateApply.emit(template);
        this.analyticsService.sendSimpleEvent(AnalyticsEvent.Template.Apply, {
            TemplateID: template.name,
            ResourceType: template.resource ? template.resource.name : undefined,
            Source: 'home_templates_preview'
        });
    };
    TemplatePreviewComponent.prototype.applyAdminPanelTemplate = function (resource) {
        if (!resource) {
            return;
        }
        this.applyTemplate(__assign({}, this.selectedTemplate, { resource: resource }));
    };
    TemplatePreviewComponent.prototype.close = function () {
        if (this.popupComponent) {
            this.popupComponent.close();
        }
    };
    return TemplatePreviewComponent;
}());
export { TemplatePreviewComponent };

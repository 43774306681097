import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { debounceTime } from 'rxjs/operators';

import { HttpParameterType } from '@modules/queries';

import { QueryBuilderContext } from '../../data/query-builder-context';
import { InputTokensEvent } from '../input-tokens/input-tokens.component';
import { HttpParameterArray } from '../query-builder-http/http-parameter.array';

@Component({
  selector: 'app-query-builder-parameters',
  templateUrl: './query-builder-parameters.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class QueryBuilderParametersComponent implements OnInit, OnDestroy {
  @Input() arrayControl: HttpParameterArray;
  @Input() context: QueryBuilderContext;
  @Input() typeEnabled = false;
  @Output() inputTokensEvent = new EventEmitter<InputTokensEvent>();

  httpParameterTypes = HttpParameterType;

  constructor(private cd: ChangeDetectorRef) {}

  ngOnInit() {
    this.arrayControl.valueChanges.pipe(debounceTime(60), untilDestroyed(this)).subscribe(() => this.cd.markForCheck());
  }

  ngOnDestroy(): void {}
}

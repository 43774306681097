<app-page>
  <div *ngIf="loading" class="change__loading"></div>

  <div *ngIf="loading">
    <app-loader [title]="'Loading'" [fill]="true">
      We are fetching item data, please, wait...
    </app-loader>
  </div>

  <div *ngIf="!loading" class="section section_top">
    <div class="section__inner">
      <div class="change__header">
        <h1 class="change__title">
          <a [appLink]="modelDescription.link" [queryParams]="listParams" class="change__title-back">
            <span class="icon icon-arrow_backward_3"></span> {{ modelDescription.verboseNamePlural }}
          </a>
          – Mass Edit
        </h1>
      </div>
      <div class="section__body">
        <ng-container *ngIf="!saveStarted">
          <div class="card">
            <div class="card__inner">
              <ul>
                <li>
                  You are going to edit <strong>{{ modelDescription.verboseNamePlural }}</strong> items: {{ count }}
                </li>
              </ul>
            </div>
          </div>
          <div class="card">
            <div class="card__inner">
              <ul>
                <li>The following items will be edited:</li>
                <li>---</li>
                <li *ngFor="let item of modelItems">
                  <a [appLink]="item.getLink()" [innerHTML]="item | appModelStr | appStub | async"></a>
                </li>
                <li *ngIf="modelItems.length < count">...{{ count - modelItems.length }} more</li>
              </ul>
            </div>
          </div>

          <p class="section__subtitle">Please select fields you want to edit:</p>
          <div class="card">
            <div class="card__inner">
              <ng-container *ngFor="let checkbox of checkboxes; let i = index">
                <div class="field">
                  <input
                    type="checkbox"
                    [id]="'file_' + i"
                    [(ngModel)]="checkbox.checked"
                    (change)="updateForm(checkbox)"
                  />
                  <label class="field__label field__label_inline" [for]="'file_' + i">
                    {{ checkbox.name }}
                  </label>
                </div>
              </ng-container>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="saveStarted">
          <div class="card">
            <div class="card__inner">
              <app-bar-progress
                [label]="countSaveProcessed == count ? 'Finished' : 'Executing...'"
                [progress]="countSaveProcessed / count"
                [processed]="countSaveProcessed"
                [total]="count"
              >
              </app-bar-progress>
            </div>
          </div>
          <div *ngIf="countSaveProcessed == count" class="change__submit">
            <a [appLink]="modelDescription.link" [queryParams]="listParams" class="button button_primary">
              <span class="button__label">Continue</span>
            </a>
          </div>
        </ng-container>
      </div>
    </div>
  </div>

  <div *ngIf="!loading && !saveStarted && items.length" class="section">
    <div class="section__inner">
      <div class="section__body">
        <div class="card">
          <div class="card__inner">
            <app-auto-field
              *ngFor="let item of items"
              [form]="form.form"
              [field]="
                createField({
                  name: item.name,
                  label: item.verboseName,
                  required: item.required,
                  field: item.field,
                  params: item.params
                })
              "
              [context]="context"
            >
            </app-auto-field>
          </div>
        </div>
        <div class="change__submit">
          <a
            href="javascript:void(0)"
            class="button button_primary"
            style="margin-right: 10px;"
            [class.button_disabled]="form.form.invalid"
            (click)="confirm()"
          >
            <span class="button__label">Save</span>
          </a>
          <a [appLink]="modelDescription.link" [queryParams]="listParams" class="button">
            <span class="button__label">Cancel</span>
          </a>
        </div>
      </div>
    </div>
  </div>
</app-page>

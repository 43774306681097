import { ChangeDetectorRef, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { untilDestroyed } from 'ngx-take-until-destroy';
var JsonFieldStructureComponent = /** @class */ (function () {
    function JsonFieldStructureComponent(cd) {
        this.cd = cd;
    }
    JsonFieldStructureComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.control.valueChanges.pipe(untilDestroyed(this)).subscribe(function (e) {
            _this.cd.markForCheck();
        });
    };
    JsonFieldStructureComponent.prototype.ngOnDestroy = function () { };
    Object.defineProperty(JsonFieldStructureComponent.prototype, "value", {
        get: function () {
            return this.control.value;
        },
        enumerable: true,
        configurable: true
    });
    JsonFieldStructureComponent.prototype.onChanged = function (value) {
        this.control.patchValue(value);
    };
    JsonFieldStructureComponent.prototype.onWheel = function (event) {
        if (Math.abs(event.deltaX) > Math.abs(event.deltaY)) {
            event.stopPropagation();
        }
    };
    return JsonFieldStructureComponent;
}());
export { JsonFieldStructureComponent };

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { InputFormatDirective } from './directives/input-format/input-format.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [InputFormatDirective],
  exports: [InputFormatDirective]
})
export class InputFormatModule {}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Option, SelectSource } from 'ng-gxselect';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

// TODO: Refactor imports
import { GoogleDriveFile, GoogleDriveFiles } from '../../../data/google-sheets-resource-params.data';

@Injectable()
export class GoogleSheetsFileSource extends SelectSource {
  accessToken: string;

  private pageToken: string;

  constructor(private http: HttpClient) {
    super();
  }

  fetch(searchQuery: string): Observable<Option[]> {
    const url = 'https://www.googleapis.com/drive/v3/files';
    const headers = {
      Authorization: `Bearer ${this.accessToken}`
    };
    const params: {} = {
      q: searchQuery
        ? `mimeType='application/vnd.google-apps.spreadsheet' and (name contains '${searchQuery}')`
        : `mimeType='application/vnd.google-apps.spreadsheet'`,
      fields: 'files(kind,id,name,mimeType,capabilities/canDelete,capabilities/canEdit)',
      orderBy: `viewedByMeTime desc`,
      pageSize: 20
    };

    if (this.pageToken) {
      params['pageToken'] = this.pageToken;
    }

    return this.http
      .get<GoogleDriveFiles>(url, { headers: headers, params: params })
      .pipe(
        map(result => {
          this.pageToken = result.nextPageToken ? result.nextPageToken : null;

          return result.files.map(item => {
            return {
              value: item,
              name: item.name
            };
          });
        })
      );
  }

  fetchByValue(value: GoogleDriveFile): Observable<Option> {
    if (!value) {
      return of(undefined);
    }

    const url = `https://www.googleapis.com/drive/v3/files/${value.id}`;
    const headers = {
      Authorization: `Bearer ${this.accessToken}`
    };
    const params: {} = {
      fields: 'kind,id,name,mimeType,capabilities/canDelete,capabilities/canEdit'
    };

    return this.http
      .get<GoogleDriveFile>(url, { headers: headers, params: params })
      .pipe(
        map(result => {
          if (!result) {
            return;
          }

          return {
            value: result,
            name: result.name
          };
        })
      );
  }

  isFetchAvailable(): boolean {
    return this.pageToken !== null;
  }

  resetPagination() {
    this.pageToken = undefined;
  }

  setStaticOptions(options: Option[]) {}
}

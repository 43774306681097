import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import last from 'lodash/fp/last';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { TaskQueue, TaskQueueService, TaskQueueStore } from '@modules/collaboration';
import { CustomizeHandler, CustomizeService } from '@modules/customize';
import { MenuSection, MenuService } from '@modules/menu';
import { CurrentProjectStore, Project } from '@modules/projects';

@Component({
  selector: 'app-collaboration',
  templateUrl: './collaboration.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CollaborationComponent implements OnInit, AfterViewInit, OnDestroy, CustomizeHandler {
  queues$: Observable<TaskQueue[]>;
  currentPage: string;
  currentProject: Project;
  customizeEnabled$: Observable<boolean>;

  constructor(
    private customizeService: CustomizeService,
    public currentProjectStore: CurrentProjectStore,
    private router: Router,
    private menuService: MenuService,
    private taskQueueService: TaskQueueService,
    private taskQueueStore: TaskQueueStore,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.customizeService.layoutEnabled$.pipe(untilDestroyed(this)).subscribe(enabled => {
      if (enabled) {
        this.customizeService.setHandler(this);
        this.customizeService.setHandlerInfo(this, {
          breadcrumbs: ['Collaboration']
        });
      } else {
        this.customizeService.unsetHandler(this);
      }
    });

    this.menuService.section = MenuSection.None;
    this.queues$ = this.taskQueueStore.get();

    this.customizeEnabled$ = this.customizeService.enabled$.pipe(map(item => !!item));

    this.currentProjectStore.instance$.pipe(untilDestroyed(this)).subscribe(currentProject => {
      this.currentProject = currentProject;
      this.cd.markForCheck();
    });

    this.currentPage = last(this.getPathWithoutParams(this.router.url).split('/'));
    this.cd.markForCheck();

    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        untilDestroyed(this)
      )
      .subscribe((e: NavigationEnd) => {
        this.currentPage = last(this.getPathWithoutParams(e.urlAfterRedirects).split('/'));
        this.cd.markForCheck();
      });
  }

  ngAfterViewInit(): void {}

  getPathWithoutParams(url: string): string {
    const urlTree = this.router.parseUrl(url);
    urlTree.queryParams = {};
    return urlTree.toString();
  }

  ngOnDestroy(): void {
    this.menuService.section = MenuSection.Default;
    this.customizeService.unsetHandler(this);
  }

  // TODO: Fix empty handler
  closePopup(uid?: string): void {}
}

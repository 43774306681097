import { Injectable } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ActionStore } from '@modules/action-queries';
import { ActionDescription } from '@modules/actions';
import { ProjectSettingsStore } from '@modules/all-project-settings';
import { TaskQueue, TaskQueueStore } from '@modules/collaboration';
import { CustomView, CustomViewsStore } from '@modules/custom-views';
import { ViewSettings, ViewSettingsStore } from '@modules/customize';
import { MenuSettings, MenuSettingsStore } from '@modules/menu';
import { ModelDescriptionStore } from '@modules/model-queries';
import { JET_APP_GROUPS, JET_APP_USERS, ModelDescription } from '@modules/models';
import {
  CurrentEnvironmentStore,
  CurrentProjectStore,
  DraftItemsType,
  JET_APP_RESOURCE,
  ProjectProperty,
  ProjectPropertyStore,
  ProjectPropertyType,
  ProjectSettings,
  Resource,
  SecretToken
} from '@modules/projects';
import { isSet } from '@shared';

export interface DraftChangeItem {
  type: DraftItemsType;
  count: number;
}

@Injectable()
export class DraftChangesService {
  constructor(
    public currentProjectStore: CurrentProjectStore,
    public currentEnvironmentStore: CurrentEnvironmentStore,
    private modelDescriptionStore: ModelDescriptionStore,
    private actionStore: ActionStore,
    private viewSettingsStore: ViewSettingsStore,
    private menuSettingsStore: MenuSettingsStore,
    private customViewsStore: CustomViewsStore,
    private taskQueueStore: TaskQueueStore,
    private projectPropertyStore: ProjectPropertyStore,
    private projectSettingsStore: ProjectSettingsStore
  ) {}

  getDraftChanges$(
    options: { resource?: string; modelId?: string; action?: string } = {}
  ): Observable<DraftChangeItem[]> {
    return combineLatest(
      this.viewSettingsStore.get(false, { includeDeleted: true }).pipe(map(value => value || [])),
      this.modelDescriptionStore.get(false, { includeDeleted: true }).pipe(map(value => value || [])),
      this.actionStore.get(false, { includeDeleted: true }).pipe(map(value => value || [])),
      this.menuSettingsStore.get(false, { includeDeleted: true }),
      this.customViewsStore.get(false, { includeDeleted: true }).pipe(map(value => value || [])),
      combineLatest(this.currentProjectStore.get(), this.currentEnvironmentStore.instance$).pipe(
        map(([project, environment]) => {
          if (!project || !environment) {
            return [];
          }

          return project.getEnvironmentResources(environment.uniqueName, { includeDeleted: true });
        })
      ),
      this.taskQueueStore.get(false, { includeDeleted: true }).pipe(map(value => value || [])),
      this.projectPropertyStore.get(false, { includeDeleted: true }).pipe(map(value => value || [])),
      this.projectSettingsStore.get(false, { includeDeleted: true })
    ).pipe(
      map<
        [
          ViewSettings[],
          ModelDescription[],
          ActionDescription[],
          MenuSettings,
          CustomView[],
          Resource[],
          TaskQueue[],
          ProjectProperty[],
          ProjectSettings[]
        ],
        DraftChangeItem[]
      >(
        ([
          viewSettings,
          modelDescriptions,
          actionDescriptions,
          menuSettings,
          customViews,
          resources,
          taskQueues,
          projectProperties,
          projectSettings
        ]) => {
          const draftChanges: DraftItemsType[] = [];
          let secretTokens: SecretToken[] = resources.reduce((acc, item) => {
            acc.push(...item.secretTokensWithDeleted);
            return acc;
          }, []);
          const storages: Storage[] = resources.reduce((acc, item) => {
            acc.push(...item.storagesWithDeleted);
            return acc;
          }, []);

          if (isSet(options.resource)) {
            resources = resources.filter(item => {
              return item.uniqueName == options.resource;
            });

            draftChanges.push(DraftItemsType.Resources);

            secretTokens = secretTokens.filter(item => {
              return item.resource == options.resource;
            });

            draftChanges.push(DraftItemsType.SecretTokens);
          }

          if (isSet(options.modelId)) {
            modelDescriptions = modelDescriptions.filter(item => {
              return item.isSame(options.modelId);
            });

            draftChanges.push(DraftItemsType.ModelDescriptions);

            actionDescriptions = actionDescriptions.filter(item => {
              return [item.resource, item.model].join('.') == options.modelId;
            });

            draftChanges.push(DraftItemsType.ActionDescriptions);

            projectProperties = projectProperties.filter(item => {
              if (options.modelId == [JET_APP_RESOURCE, JET_APP_USERS].join('.')) {
                return item.type == ProjectPropertyType.User;
              } else if (options.modelId == [JET_APP_RESOURCE, JET_APP_GROUPS].join('.')) {
                return item.type == ProjectPropertyType.Group;
              } else {
                return false;
              }
            });

            draftChanges.push(DraftItemsType.ProjectProperties);
          }

          if (isSet(options.action)) {
            actionDescriptions = actionDescriptions.filter(item => {
              return item.isSame(options.action);
            });

            draftChanges.push(DraftItemsType.ActionDescriptions);
          }

          return [
            {
              type: DraftItemsType.ViewSettings,
              count: viewSettings.filter(item => item.draft).length
            },
            {
              type: DraftItemsType.ModelDescriptions,
              count: modelDescriptions.filter(item => item.draft).length
            },
            {
              type: DraftItemsType.ActionDescriptions,
              count: actionDescriptions.filter(item => item.draft).length
            },
            {
              type: DraftItemsType.MenuSettings,
              count: menuSettings && menuSettings.draft ? 1 : 0
            },
            {
              type: DraftItemsType.CustomViews,
              count: customViews.filter(item => item.draft).length
            },
            {
              type: DraftItemsType.Resources,
              count: resources.filter(item => item.draft).length
            },
            {
              type: DraftItemsType.SecretTokens,
              count: secretTokens.filter(item => item.draft).length
            },
            {
              type: DraftItemsType.Storages,
              count: storages.filter(item => item.draft).length
            },
            {
              type: DraftItemsType.TaskQueues,
              count: taskQueues.filter(item => item.draft).length
            },
            {
              type: DraftItemsType.ProjectProperties,
              count: projectProperties.filter(item => item.draft).length
            },
            {
              type: DraftItemsType.ProjectSettings,
              count: projectSettings.filter(item => item.draft).length
            }
          ].filter(item => !draftChanges.length || draftChanges.includes(item.type));
        }
      )
    );
  }
}

export enum TextEncoding {
  UTF8,
  Windows1250CentralEuropean,
  Windows1251Cyrillic,
  Windows1252Latin1,
  Windows1253Greek,
  Windows1254Turkish,
  Windows1255Hebrew,
  Windows1256Arabic,
  Windows1257Baltic,
  Windows1258Vietnamese,
  Windows874Thai,
  SimplifiedChineseGBK,
  TraditionalChineseBig5,
  JapaneseShiftJIS,
  UTF16LE
}

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { FormField, getFieldDescriptionByType, parseFieldType } from '@modules/fields';
var ModelFlexField = /** @class */ (function () {
    function ModelFlexField() {
        this.filterable = false;
        this.sortable = false;
        this.params = {};
    }
    ModelFlexField.prototype.deserialize = function (data) {
        this.name = data['name'];
        this.verboseName = data['verbose_name'];
        this.description = data['description'];
        this.field = parseFieldType(data['field']);
        this.query = data['query'];
        this.code = data['code'];
        var item = getFieldDescriptionByType(this.field);
        this.params = __assign({}, item.defaultParams, data['params'], item.forceParams);
        this.updateFieldDescription();
        return this;
    };
    ModelFlexField.prototype.serialize = function () {
        return {
            name: this.name,
            verbose_name: this.verboseName,
            field: this.field,
            query: this.query,
            code: this.code,
            params: this.params,
            description: this.description
        };
    };
    Object.defineProperty(ModelFlexField.prototype, "formField", {
        get: function () {
            if (!this._formField) {
                this._formField = new FormField().deserialize({
                    name: this.name,
                    label: this.verboseName,
                    field: this.field,
                    editable: false,
                    required: false,
                    params: this.params,
                    description: this.description
                });
            }
            return this._formField;
        },
        enumerable: true,
        configurable: true
    });
    ModelFlexField.prototype.updateFieldDescription = function () {
        this.fieldDescription = getFieldDescriptionByType(this.field);
    };
    return ModelFlexField;
}());
export { ModelFlexField };

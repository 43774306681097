import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { DynamicComponentArguments } from '@common/dynamic-component';
import { ViewContext } from '@modules/customize';
import { View } from '@modules/views';
import { scrollTo } from '@shared';

import { getCustomizeLayerComponent } from '../../data/customize-layer-components';
import { ViewEditorContext, ViewEditorCustomizeSource } from '../../services/view-editor-context/view-editor.context';
import { CustomizeLayerComponent } from '../customize-layers/base/base-customize-layer.component';

@Component({
  selector: 'app-view-editor-sidebar',
  templateUrl: './view-editor-sidebar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ViewEditorSidebarComponent implements OnInit, OnDestroy {
  @Input() viewContext: ViewContext;

  customizeView: View;
  customizeLayerComponent: DynamicComponentArguments<CustomizeLayerComponent>;
  viewUpdated$ = this.editorContext.viewChanged$().pipe(
    filter(event => event.source != ViewEditorCustomizeSource.CustomizeView),
    map(event => event.view)
  );

  @ViewChild('root') root: ElementRef;

  constructor(public editorContext: ViewEditorContext, private cd: ChangeDetectorRef) {}

  ngOnInit() {
    combineLatest(this.editorContext.customizingLayers$, this.editorContext.customizingView$)
      .pipe(untilDestroyed(this))
      .subscribe(([customizingLayers, customizingView]) => {
        const singleLayer = customizingLayers.length == 1 ? customizingLayers[0] : undefined;

        if (singleLayer) {
          const component = getCustomizeLayerComponent(singleLayer.type);
          if (!component) {
            this.customizeLayerComponent = undefined;
            this.customizeView = undefined;
            this.cd.markForCheck();
            return;
          }

          this.customizeLayerComponent = {
            component: component,
            inputs: {
              layer: singleLayer,
              layerUpdated$: this.editorContext.layerChanged$().pipe(
                filter(
                  event => event.layer.isSame(singleLayer) && event.source != ViewEditorCustomizeSource.CustomizeLayer
                ),
                map(event => event.layer)
              ),
              viewContext: this.viewContext
            },
            outputs: {
              layerChange: [
                result => {
                  this.editorContext.markLayersChanged([result], ViewEditorCustomizeSource.CustomizeLayer);
                }
              ],
              closeCustomize: [
                () => {
                  this.editorContext.resetCustomizingLayers();
                }
              ]
            }
          };
          this.customizeView = undefined;
          this.cd.detectChanges();
          this.scrollToTop();
        } else if (customizingView) {
          this.customizeView = this.editorContext.view$.value;
          this.customizeLayerComponent = undefined;
          this.cd.detectChanges();
          this.scrollToTop();
        } else {
          this.customizeView = undefined;
          this.customizeLayerComponent = undefined;
          this.cd.markForCheck();
        }
      });
  }

  ngOnDestroy(): void {}

  scrollToTop(animated = true) {
    const duration = animated && this.root.nativeElement.scrollTop > 0 ? 0.4 : 0;
    scrollTo(this.root.nativeElement, 0, duration);
  }

  onViewChange(result: View) {
    this.editorContext.markViewChanged(result, ViewEditorCustomizeSource.CustomizeView);
  }

  onViewCloseCustomize() {
    this.editorContext.resetCustomizeView();
  }
}

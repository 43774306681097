<app-field-wrapper
  *ngIf="!readonly"
  [form]="form"
  [field]="field"
  [readonly]="readonly"
  [requiredAsterisk]="requiredAsterisk"
  [label]="label"
  [errors]="errors"
  [truncate]="truncate"
  [manualMargin]="manualMargin"
  [labelButtons]="labelButtons"
  [tooltip]="tooltip"
  [idToken]="idToken"
>
  <div class="field__map" [class.field__map_disabled]="control?.disabled">
    <agm-map
      [latitude]="viewportCoordinates ? viewportCoordinates.lat : undefined"
      [longitude]="viewportCoordinates ? viewportCoordinates.lng : undefined"
      [zoom]="viewportZoom"
      [streetViewControl]="field.params.hasOwnProperty('street_view') ? field.params['street_view'] : true"
      [backgroundColor]="backgroundColor$ | async"
      [styles]="mapStyles$ | async"
    >
      <agm-marker
        *ngIf="markerCoordinates"
        [title]="valueToStr | appSafeAsync"
        [latitude]="markerCoordinates.lat"
        [longitude]="markerCoordinates.lng"
        [markerDraggable]="!readonly"
        [iconUrl]="markerIcon$ | async"
        (drag)="onDrag($event)"
        (dragEnd)="onDragEnd($event)"
      >
        <agm-snazzy-info-window
          [isOpen]="true"
          [placement]="'top'"
          [maxWidth]="'none'"
          [maxHeight]="'none'"
          [showCloseButton]="false"
        >
          <ng-template>
            <div class="map-popup">
              <app-page-block [paddingSize]="'s'">
                <div class="field">
                  <label class="field__label">
                    <span class="field__label-text">Latitude</span>
                    <span *ngIf="!field.required" class="field__label-optional">(optional)</span>
                  </label>
                  <input class="input" type="text" [(ngModel)]="currentCoordinates.lat" (keyup)="updateValue()" />
                </div>
              </app-page-block>

              <app-page-block [paddingSize]="'s'">
                <div class="field">
                  <label class="field__label">
                    <span class="field__label-text">Longitude</span>
                    <span *ngIf="!field.required" class="field__label-optional">(optional)</span>
                  </label>
                  <input class="input" type="text" [(ngModel)]="currentCoordinates.lng" (keyup)="updateValue()" />
                </div>
              </app-page-block>
            </div>
          </ng-template>
        </agm-snazzy-info-window>
      </agm-marker>
    </agm-map>
  </div>
</app-field-wrapper>
<app-field-wrapper
  *ngIf="readonly"
  [form]="form"
  [field]="field"
  [readonly]="readonly"
  [requiredAsterisk]="requiredAsterisk"
  [label]="label"
  [errors]="errors"
  [truncate]="truncate"
  [manualMargin]="manualMargin"
  [labelButtons]="labelButtons"
  [tooltip]="tooltip"
  [idToken]="idToken"
>
  {{ valueToStr | appSafeAsync }}
</app-field-wrapper>

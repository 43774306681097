var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ChangeDetectorRef, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, fromEvent } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { copyTextToClipboard } from '@common/code';
import { NotificationService } from '@common/notifications';
import { BasePopupComponent } from '@common/popups';
import { AppConfigService } from '@core';
import { AdminMode } from '@modules/admin-mode';
import { IntercomService, UniversalAnalyticsService } from '@modules/analytics';
import { ApiService } from '@modules/api';
import { CurrentProjectStore, ProjectTokenService, ResourceName, salesforceBackendName, SecretToken, socialBackends } from '@modules/projects';
import { HttpMethod, HttpQuery, HttpQueryService, submitForm } from '@modules/queries';
import { capitalize, controlValue, isSet } from '@shared';
import { registerResourceSettingsComponent } from '../../../data/resource-settings-components';
import { ChooseSyncController } from '../../../services/choose-sync/choose-sync.controller';
import { BaseResourceSettingsComponent } from '../base-resource-settings/base-resource-settings.component';
import { SalesforceResourceSettingsForm } from './salesforce-resource-settings.form';
var SalesforceResourceSettingsComponent = /** @class */ (function (_super) {
    __extends(SalesforceResourceSettingsComponent, _super);
    function SalesforceResourceSettingsComponent(mode, httpQueryService, apiService, appConfigService, currentProjectStore, chooseSyncController, notificationService, form, popupComponent, projectTokenService, intercomService, analyticsService, cd) {
        var _this = _super.call(this, form, popupComponent, projectTokenService, intercomService, analyticsService, cd) || this;
        _this.mode = mode;
        _this.httpQueryService = httpQueryService;
        _this.apiService = apiService;
        _this.appConfigService = appConfigService;
        _this.currentProjectStore = currentProjectStore;
        _this.chooseSyncController = chooseSyncController;
        _this.notificationService = notificationService;
        _this.form = form;
        _this.accountLoading = false;
        return _this;
    }
    SalesforceResourceSettingsComponent.prototype.ngOnInit = function () {
        var _this = this;
        _super.prototype.ngOnInit.call(this);
        fromEvent(window, 'message')
            .pipe(untilDestroyed(this))
            .subscribe(function (message) {
            if (message.data && message.data['type'] == 'oauth_response') {
                var data = message.data['params'];
                var secretToken = new SecretToken().deserialize(data);
                _this.form.form.patchValue({
                    access_token: secretToken.value,
                    params: secretToken.params
                });
            }
        });
        combineLatest(controlValue(this.form.form.controls['access_token']), controlValue(this.form.form.controls['domain']))
            .pipe(untilDestroyed(this))
            .subscribe(function (_a) {
            var accessToken = _a[0], domain = _a[1];
            return _this.updateAccount(accessToken, domain);
        });
    };
    SalesforceResourceSettingsComponent.prototype.ngOnDestroy = function () { };
    SalesforceResourceSettingsComponent.prototype.updateAccount = function (accessToken, domain) {
        var _this = this;
        if (this.accountSubscription) {
            this.accountSubscription.unsubscribe();
            this.accountSubscription = undefined;
        }
        if (!isSet(accessToken) || !isSet(domain)) {
            this.account = undefined;
            this.accountLoading = false;
            this.cd.markForCheck();
            return;
        }
        var headers = [{ name: 'Authorization', value: "Bearer " + accessToken }];
        var query = new HttpQuery();
        query.url = "https://" + domain + ".my.salesforce.com/services/data/v52.0/";
        query.headers = headers;
        this.accountLoading = true;
        this.cd.markForCheck();
        this.accountSubscription = this.httpQueryService
            .requestBody(query)
            .pipe(switchMap(function (result) {
            var identityQuery = new HttpQuery();
            identityQuery.url = result['identity'];
            identityQuery.headers = headers;
            return _this.httpQueryService.requestBody(identityQuery);
        }), untilDestroyed(this))
            .subscribe(function (result) {
            _this.account = result['email'];
            _this.accountSubscription = undefined;
            _this.accountLoading = false;
            _this.cd.markForCheck();
        }, function () {
            _this.account = undefined;
            _this.accountSubscription = undefined;
            _this.accountLoading = false;
            _this.cd.markForCheck();
        });
    };
    SalesforceResourceSettingsComponent.prototype.getOAuthToken = function () {
        var _this = this;
        this.apiService
            .refreshToken()
            .pipe(untilDestroyed(this))
            .subscribe(function () {
            var params = {
                KEY: _this.form.form.value['key'],
                SECRET: _this.form.form.value['secret'],
                SCOPE: ['api', 'refresh_token', 'offline_access'],
                SCOPE_SEPARATOR: ' '
            };
            var redirect = _this.redirectUrl();
            var backend = socialBackends.find(function (item) { return item.name == salesforceBackendName; });
            var data = [
                {
                    name: 'name',
                    value: _this.form.tokenName
                },
                {
                    name: 'backend',
                    value: backend ? backend.path : ''
                },
                {
                    name: 'params',
                    value: JSON.stringify(params)
                },
                {
                    name: 'access_token',
                    value: _this.apiService.getAccessToken()
                },
                {
                    name: 'redirect_uri',
                    value: redirect
                }
            ];
            if (_this.mode == AdminMode.Builder) {
                data.push({
                    name: 'draft',
                    value: '1'
                });
            }
            var w = open('', '', 'height=800,width=600');
            if (!w) {
                _this.notificationService.error('Failed to open popup', 'Your browser has blocked opening a new window. Please check your browser settings to allow opening pop-ups');
                return;
            }
            submitForm(HttpMethod.POST, _this.apiService.createOAuthTokenUrl, data, w.document);
        });
    };
    Object.defineProperty(SalesforceResourceSettingsComponent.prototype, "completeUrl", {
        get: function () {
            return this.apiService.createOAuthTokenCompleteUrl;
        },
        enumerable: true,
        configurable: true
    });
    SalesforceResourceSettingsComponent.prototype.redirectUrl = function () {
        return window.location.origin + "/oauth_response";
    };
    SalesforceResourceSettingsComponent.prototype.copy = function (text, contentLabel) {
        var _this = this;
        copyTextToClipboard(text)
            .pipe(untilDestroyed(this))
            .subscribe(function (success) {
            if (!success) {
                return;
            }
            var description = isSet(contentLabel) ? capitalize(contentLabel) + " was copied to clipboard" : undefined;
            _this.notificationService.info('Copied', description);
        });
    };
    return SalesforceResourceSettingsComponent;
}(BaseResourceSettingsComponent));
export { SalesforceResourceSettingsComponent };
registerResourceSettingsComponent(ResourceName.Salesforce, SalesforceResourceSettingsComponent);

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatMenuModule } from '@angular/material';

import { LocalizeModule } from '@common/localize';
import { TipsModule } from '@common/tips';
import { RoutingModule } from '@modules/routing';
import { SidebarModule } from '@modules/sidebar';
import { SharedModule } from '@shared';
import { UiModule } from '@ui';

import { AutomationRunsItemComponent } from './components/automation-runs-item/automation-runs-item.component';
import { AutomationRunsComponent } from './components/automation-runs/automation-runs.component';
import { AutomationsItemComponent } from './components/automations-item/automations-item.component';
import { AutomationsComponent } from './components/automations/automations.component';

@NgModule({
  imports: [
    CommonModule,
    SidebarModule,
    UiModule,
    SharedModule,
    MatMenuModule,
    TipsModule,
    RoutingModule,
    LocalizeModule
  ],
  declarations: [AutomationsComponent, AutomationsItemComponent, AutomationRunsComponent, AutomationRunsItemComponent],
  exports: [AutomationsComponent]
})
export class WorkflowRoutesModule {}

import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { ViewContext, ViewContextElement } from '@modules/customize';
import { applyParamInput$, Input } from '@modules/fields';
import { isSet } from '@shared';

export enum ImageFillType {
  Fill = 'fill',
  Fit = 'fit',
  Stretch = 'stretch',
  Tile = 'tile'
}

export class ImageFill {
  type: ImageFillType = ImageFillType.Fill;
  image: string;
  imageInput: Input;
  scaleX: number;
  scaleY: number;

  constructor(options: Partial<ImageFill> = {}) {
    Object.assign(this, options);
  }

  deserialize(data: Object): this {
    if (data['type']) {
      this.type = data['type'];
    }

    this.image = data['image'];
    this.scaleX = data['scale_x'];
    this.scaleY = data['scale_y'];

    if (data['image_input']) {
      this.imageInput = new Input().deserialize(data['image_input']);
    }

    return this;
  }

  serialize(): Object {
    return {
      type: this.type,
      image: this.image,
      image_input: this.imageInput ? this.imageInput.serialize() : null,
      scale_x: this.scaleX,
      scale_y: this.scaleY
    };
  }

  cssBackground$(
    options: {
      context?: ViewContext;
      contextElement?: ViewContextElement;
      localContext?: Object;
    } = {}
  ): Observable<string> {
    let image$: Observable<string>;

    if (this.imageInput) {
      image$ = applyParamInput$(this.imageInput, {
        context: options.context,
        contextElement: options.contextElement,
        localContext: options.localContext,
        defaultValue: ''
      });
    } else {
      image$ = of(this.image);
    }

    return image$.pipe(
      map(image => {
        const result = [];

        if (isSet(image)) {
          result.push(`url("${image}")`);
        }

        if (this.type == ImageFillType.Tile) {
          result.push('0 0');
        } else {
          result.push('center center');
        }

        if (this.type == ImageFillType.Fill) {
          result.push('/ cover');
        } else if (this.type == ImageFillType.Fit) {
          result.push('/ contain');
        }

        if (this.type != ImageFillType.Tile) {
          result.push('no-repeat');
        }

        return result.join(' ');
      })
    );
  }
}

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { NgZone } from '@angular/core';
import toPairs from 'lodash/toPairs';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, first, map, skip, switchMap } from 'rxjs/operators';
import { NotificationService } from '@common/notifications';
import { ActionService } from '@modules/action-queries';
import { modelFieldToDisplayField } from '@modules/customize';
import { DataSourceType, ModelDescriptionDataSource } from '@modules/data-sources';
import { ModelDescriptionStore, ModelService } from '@modules/model-queries';
import { ModelFieldType } from '@modules/models';
import { inputFieldProviderItemsFromModelGet, inputFieldProviderItemsFromModelGetDetail, modelDescriptionHasAutoParameters, parametersToProviderItems } from '@modules/parameters';
import { ProjectSettingsService } from '@modules/project-settings';
import { CurrentEnvironmentStore, getResourceTypeItemRequestName, ResourceName, ResourceType, resourceTypeItems } from '@modules/projects';
import { getResourceAddModelComponents } from '@modules/projects-components';
import { editableQueryTypes, ModelDescriptionQuery, QueryService, QueryType } from '@modules/queries';
import { ResourceGeneratorResolver } from '@modules/resource-generators';
import { isResourceCollectionCustom, isResourceCustom, prepareDataSourceColumnForGet, ResourceControllerService } from '@modules/resources';
import { ascComparator, controlValue, isSet, splitmax } from '@shared';
import { DataSourceControl } from './data-source.control';
var ModelDescriptionDataSourceControl = /** @class */ (function (_super) {
    __extends(ModelDescriptionDataSourceControl, _super);
    function ModelDescriptionDataSourceControl(modelDescriptionStore, currentEnvironmentStore, projectSettingsService, resourceControllerService, resourceGeneratorResolver, modelService, actionService, queryService, notificationService, zone, extraControls) {
        if (extraControls === void 0) { extraControls = {}; }
        var _this = _super.call(this, currentEnvironmentStore, projectSettingsService, resourceControllerService, resourceGeneratorResolver, modelService, actionService, queryService, notificationService, zone, extraControls) || this;
        _this.modelDescriptionStore = modelDescriptionStore;
        _this.resourceFieldParams = { model_resources: true };
        _this.queryOptionEquals = (function () {
            return function (lhs, rhs) {
                var lhsQueryType = lhs ? lhs.queryType : undefined;
                var lhsName = lhs && lhs.simpleQuery ? lhs.simpleQuery.model : undefined;
                var rhsQueryType = rhs ? rhs.queryType : undefined;
                var rhsName = rhs && rhs.simpleQuery ? rhs.simpleQuery.model : undefined;
                return lhsQueryType == rhsQueryType && lhsName == rhsName;
            };
        })();
        return _this;
    }
    ModelDescriptionDataSourceControl.prototype.deserialize = function (instance, initial) {
        var _this = this;
        if (initial === void 0) { initial = true; }
        _super.prototype.deserialize.call(this, instance, initial);
        if (initial) {
            this.getModelDescription$()
                .pipe(distinctUntilChanged(function (lhs, rhs) {
                var lhsModelId = lhs ? lhs.modelId : undefined;
                var rhsModelId = rhs ? rhs.modelId : undefined;
                return lhsModelId === rhsModelId;
            }), skip(1), untilDestroyed(this))
                .subscribe(function (value) { return _this.onModelDescriptionChange(value); });
        }
    };
    ModelDescriptionDataSourceControl.prototype.getSimpleQueryDefaultColumns = function () {
        var _this = this;
        return this.getModelDescription$().pipe(first(), map(function (modelDescription) { return _this.getModelDescriptionColumns(modelDescription); }));
    };
    ModelDescriptionDataSourceControl.prototype.getModelDescriptionColumns = function (modelDescription) {
        if (!modelDescription) {
            return [];
        }
        var resource = this.currentEnvironmentStore.resources.find(function (item) { return item.uniqueName == modelDescription.resource; });
        if (!resource) {
            return [];
        }
        return modelDescription.fields
            .map(function (item) { return modelFieldToDisplayField(item, false); })
            .map(function (item) { return prepareDataSourceColumnForGet(resource, modelDescription, item); });
    };
    ModelDescriptionDataSourceControl.prototype.onModelDescriptionChange = function (modelDescription) {
        var columns = this.getModelDescriptionColumns(modelDescription);
        this.setColumns(columns, { modelDescription: modelDescription });
        this.controls.query_parameters.setValue([]);
    };
    ModelDescriptionDataSourceControl.prototype.createModelQuery = function () {
        return new ModelDescriptionQuery();
    };
    ModelDescriptionDataSourceControl.prototype.getQueryOptionValue = function (model) {
        var option = this.createModelQuery();
        option.queryType = QueryType.Simple;
        option.simpleQuery = new option.simpleQueryClass();
        option.simpleQuery.model = model.model;
        return option;
    };
    ModelDescriptionDataSourceControl.prototype.getCustomQueryOption = function (name, queryType) {
        var option = this.createModelQuery();
        option.queryType = queryType;
        return {
            value: option,
            name: name,
            icon: 'plus'
        };
    };
    ModelDescriptionDataSourceControl.prototype.getCustomQueryOptions = function (resource) {
        var options = [];
        if (isResourceCustom(resource)) {
            var controller = this.resourceControllerService.get(resource.type);
            var queryTypes_1 = controller ? controller.supportedQueryTypes(ModelDescriptionQuery) : undefined;
            var queryType = editableQueryTypes.find(function (item) { return queryTypes_1.includes(item); });
            if (queryType) {
                var option = this.getCustomQueryOption("Make " + getResourceTypeItemRequestName(resource.typeItem), queryType);
                options.push(option);
            }
        }
        if (resource.type == ResourceType.JetBridge || resource.isSynced() || resource.hasCollectionSync()) {
            var controller = this.resourceControllerService.get(ResourceType.JetBridge);
            var typeItem = resource.isSynced() || resource.hasCollectionSync()
                ? resourceTypeItems.find(function (item) { return item.name == ResourceName.PostgreSQL; })
                : resource.typeItem;
            var queryTypes_2 = controller ? controller.supportedQueryTypes(ModelDescriptionQuery) : undefined;
            var queryType = editableQueryTypes.find(function (item) { return queryTypes_2.includes(item); });
            if (queryType) {
                var option = this.getCustomQueryOption("Make " + getResourceTypeItemRequestName(typeItem), queryType);
                options.push(option);
            }
        }
        return options;
    };
    ModelDescriptionDataSourceControl.prototype.getQueryOptions$ = function () {
        var _this = this;
        return combineLatest(this.getResource$(), this.modelDescriptionStore.get()).pipe(map(function (_a) {
            var resource = _a[0], modelDescriptions = _a[1];
            if (!resource) {
                return [];
            }
            var options = [];
            var resourceModels = modelDescriptions
                ? modelDescriptions
                    .filter(function (item) { return item.resource == resource.uniqueName; })
                    .filter(function (item) {
                    var query = _this.controls.query.value;
                    return (!resource.demo ||
                        item.featured ||
                        (query && query.simpleQuery && query.simpleQuery.model == item.model));
                })
                : [];
            if (resourceModels.length) {
                if (resource.typeItem.name == ResourceName.BigQuery) {
                    var datasets = resourceModels
                        .sort(function (lhs, rhs) { return ascComparator(lhs.dbTable, rhs.dbTable); })
                        .reduce(function (acc, item) {
                        var _a = isSet(item.dbTable) && item.dbTable.includes('.') ? splitmax(item.dbTable, '.', 2) : ['', item.model], dataset = _a[0], name = _a[1];
                        if (!acc[dataset]) {
                            acc[dataset] = [];
                        }
                        acc[dataset].push({
                            item: item,
                            name: name
                        });
                        return acc;
                    }, {});
                    options.push.apply(options, toPairs(datasets).reduce(function (acc, _a) {
                        var dataset = _a[0], items = _a[1];
                        if (isSet(dataset)) {
                            acc.push({
                                button: {
                                    label: dataset,
                                    icon: 'folder'
                                },
                                children: items
                                    .sort(function (lhs, rhs) {
                                    return ascComparator(String(lhs.item.verboseNamePlural).toLowerCase(), String(rhs.item.verboseNamePlural).toLowerCase());
                                })
                                    .map(function (item) {
                                    return {
                                        option: {
                                            value: _this.getQueryOptionValue(item.item),
                                            name: item.item.verboseNamePlural,
                                            icon: 'document'
                                        },
                                        valueTag: item.item.demo ? 'DEMO' : undefined
                                    };
                                })
                            });
                        }
                        else {
                            acc.push.apply(acc, items
                                .sort(function (lhs, rhs) {
                                return ascComparator(String(lhs.item.verboseNamePlural).toLowerCase(), String(rhs.item.verboseNamePlural).toLowerCase());
                            })
                                .map(function (item) {
                                return {
                                    option: {
                                        value: _this.getQueryOptionValue(item.item),
                                        name: item.item.verboseNamePlural,
                                        icon: 'document'
                                    },
                                    valueTag: item.item.demo ? 'DEMO' : undefined
                                };
                            }));
                        }
                        return acc;
                    }, []));
                }
                else {
                    options.push.apply(options, resourceModels
                        .sort(function (lhs, rhs) {
                        return ascComparator(String(lhs.verboseNamePlural).toLowerCase(), String(rhs.verboseNamePlural).toLowerCase());
                    })
                        .map(function (item) {
                        return {
                            option: {
                                value: _this.getQueryOptionValue(item),
                                name: item.verboseNamePlural,
                                icon: 'document'
                            },
                            valueTag: item.demo ? 'DEMO' : undefined
                        };
                    }));
                }
            }
            var addModelComponents = !resource.demo ? getResourceAddModelComponents(resource.typeItem.name) : [];
            options.push.apply(options, addModelComponents.map(function (item) {
                return {
                    button: {
                        name: 'add_model',
                        label: item.label,
                        icon: item.icon,
                        data: {
                            addModelComponent: item
                        }
                    },
                    stickyBottom: true,
                    orange: true,
                    large: true
                };
            }));
            var noCustomQueryData = resource.type == ResourceType.JetBridge && !resourceModels.length;
            if (!noCustomQueryData) {
                options.push.apply(options, _this.getCustomQueryOptions(resource).map(function (item) {
                    return {
                        option: item,
                        valueIcon: null,
                        stickyBottom: true,
                        orange: true,
                        large: true
                    };
                }));
            }
            return options;
        }));
    };
    ModelDescriptionDataSourceControl.prototype.getModelDescription$ = function () {
        var _this = this;
        return combineLatest(controlValue(this.controls.type), controlValue(this.controls.query_resource), controlValue(this.controls.query)).pipe(switchMap(function (_a) {
            var type = _a[0], resource = _a[1], query = _a[2];
            if (type != DataSourceType.Query || !query || !query.simpleQuery) {
                return of(undefined);
            }
            var modelId = [resource, query.simpleQuery.model].join('.');
            return _this.modelDescriptionStore.getDetailFirst(modelId);
        }));
    };
    ModelDescriptionDataSourceControl.prototype.getModelDescriptionCustom$ = function () {
        return combineLatest(this.getResource$(), this.getModelDescription$()).pipe(map(function (_a) {
            var resource = _a[0], modelDescription = _a[1];
            if (isResourceCollectionCustom(resource, modelDescription)) {
                return modelDescription;
            }
        }));
    };
    ModelDescriptionDataSourceControl.prototype.getInputFieldProvider$ = function () {
        return combineLatest(controlValue(this.controls.type), this.getResource$(), controlValue(this.controls.query), this.getModelDescription$(), controlValue(this.controls.query_parameters), controlValue(this.controls.columns)).pipe(debounceTime(60), map(function (_a) {
            var type = _a[0], resource = _a[1], query = _a[2], modelDescription = _a[3], parameters = _a[4], columns = _a[5];
            if (modelDescription &&
                modelDescription.getDetailQuery &&
                !(modelDescriptionHasAutoParameters(resource, modelDescription) && !modelDescription.virtual)) {
                return inputFieldProviderItemsFromModelGetDetail(resource, modelDescription, parameters);
            }
            else {
                return parametersToProviderItems(parameters).concat(inputFieldProviderItemsFromModelGet(resource, modelDescription, modelDescription ? modelDescription.getQuery : query, columns, type));
            }
            // if (
            //   query &&
            //   query.queryType == QueryType.Simple &&
            //   modelDescription &&
            //   modelDescription.getDetailQuery &&
            //   !(resourceHasAutoParameters(resource) && !modelDescription.virtual)
            // ) {
            //   return inputFieldProviderItemsFromModelGetDetail(resource, modelDescription, parameters);
            // } else {
            //   return [
            //     ...parametersToProviderItems(parameters),
            //     ...autoInputFieldProviderItemsFromModelGet(resource, modelDescription, query, columns)
            //   ];
            // }
        }));
    };
    ModelDescriptionDataSourceControl.prototype.onResourceReloaded = function () {
        return this.reloadColumns();
    };
    ModelDescriptionDataSourceControl.prototype.reloadColumns = function () {
        var _this = this;
        return this.getModelDescription$().pipe(map(function (modelDescription) {
            var resource = modelDescription
                ? _this.currentEnvironmentStore.resources.find(function (item) { return item.uniqueName == modelDescription.resource; })
                : undefined;
            var columns = resource && modelDescription
                ? modelDescription.fields
                    .filter(function (item) { return item.type == ModelFieldType.Db; })
                    .map(function (item) { return modelFieldToDisplayField(item); })
                    .map(function (item) { return prepareDataSourceColumnForGet(resource, modelDescription, item); })
                : [];
            _this.mergeColumns(columns);
        }));
    };
    ModelDescriptionDataSourceControl.prototype.reset = function (options) {
        this.controls.type.patchValue(DataSourceType.Query, options);
        this.controls.query_resource.patchValue('', options);
        this.controls.query.patchValue(null, options);
        this.controls.query_parameters.patchValue([], options);
        this.controls.query_inputs.patchValue([], options);
        this.controls.input.patchValue({ name: 'value' }, options);
        this.controls.columns.patchValue([], options);
    };
    ModelDescriptionDataSourceControl.instanceCls = ModelDescriptionDataSource;
    return ModelDescriptionDataSourceControl;
}(DataSourceControl));
export { ModelDescriptionDataSourceControl };

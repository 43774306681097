import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output
} from '@angular/core';

import { Icon, icons } from '@modules/icons';
import { isSet, TypedChanges } from '@shared';

@Component({
  selector: 'app-icon-selector-icons',
  templateUrl: './icon-selector-icons.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IconSelectorIconsComponent implements OnInit, OnChanges {
  @Input() current: string;
  @Input() search = '';
  @Input() loading = false;
  @Output() selected = new EventEmitter<string>();

  items = icons;
  filteredItems: Icon[] = [];

  constructor(private cd: ChangeDetectorRef) {}

  ngOnInit() {
    this.updateFilteredItems();
  }

  ngOnChanges(changes: TypedChanges<IconSelectorIconsComponent>): void {
    if (changes.search) {
      this.search = this.search.trim().toLowerCase();
    }

    if (changes.search && !changes.search.firstChange) {
      this.updateFilteredItems();
    }
  }

  updateFilteredItems() {
    if (!isSet(this.search)) {
      this.filteredItems = this.items;
    } else {
      this.filteredItems = this.items.filter(item => item.label.toLowerCase().includes(this.search));
    }

    this.cd.markForCheck();
  }
}

import { Type } from '@angular/core';

import { deserializeDisplayField, DisplayField, Input, isRequiredInputsSet, ParameterField } from '@modules/fields';
import { isSet } from '@shared';

import { Query } from '../../queries/data/query';
import { Workflow } from '../../workflow/data/workflow';

export enum DataSourceType {
  Input = 'input',
  Query = 'query',
  Workflow = 'workflow'
}

export abstract class DataSource<T extends Query = Query> {
  public static queryCls: Type<Query>;
  public type: DataSourceType;
  public queryResource: string;
  public query: T;
  public queryParameters: ParameterField[] = [];
  public queryInputs: Input[] = [];
  public input: Input;
  public workflow: Workflow;
  public columns: DisplayField[] = [];

  constructor(options: Partial<DataSource<T>> = {}) {
    if (options.hasOwnProperty('type')) {
      this.type = options.type;
    }

    if (options.hasOwnProperty('queryResource')) {
      this.queryResource = options.queryResource;
    }

    if (options.hasOwnProperty('query')) {
      this.query = options.query;
    }

    if (options.hasOwnProperty('queryParameters')) {
      this.queryParameters = options.queryParameters;
    }

    if (options.hasOwnProperty('queryInputs')) {
      this.queryInputs = options.queryInputs;
    }

    if (options.hasOwnProperty('input')) {
      this.input = options.input;
    }

    if (options.hasOwnProperty('workflow')) {
      this.workflow = new Workflow().deserialize(options['workflow']);
    }

    if (options.hasOwnProperty('columns')) {
      this.columns = options.columns;
    }
  }

  deserialize(data: Object): this {
    this.type = data['type'];
    this.queryResource = data['query_resource'];

    if (data['input']) {
      this.input = new Input().deserialize(data['input']);
    }

    if (data['workflow']) {
      this.workflow = new Workflow().deserialize(data['workflow']);
    }

    if (data['query']) {
      const queryCls = this.getQueryCls();
      this.query = new queryCls().deserialize(data['query']);
    }

    if (data['query_parameters']) {
      this.queryParameters = data['query_parameters'].map(item => new ParameterField().deserialize(item));
    }

    if (data['query_inputs']) {
      this.queryInputs = data['query_inputs'].map(item => new Input().deserialize(item));
    }

    if (data['columns']) {
      this.columns = data['columns'].map(item => deserializeDisplayField(item));
    }

    return this;
  }

  serialize(): Object {
    return {
      type: this.type,
      query_resource: this.queryResource,
      input: this.input ? this.input.serialize() : null,
      workflow: this.workflow ? this.workflow.serialize() : undefined,
      query: this.query ? this.query.serialize() : undefined,
      query_parameters: this.queryParameters.map(item => item.serialize()),
      query_inputs: this.queryInputs.map(item => item.serialize()),
      columns: this.columns.map(item => item.serialize())
    };
  }

  getQueryCls(): Type<T> {
    return (this.constructor as typeof DataSource).queryCls as Type<T>;
  }

  isConfigured(options: { columnsOptional?: boolean } = {}): boolean {
    if (this.type == DataSourceType.Query) {
      return (
        isSet(this.queryResource) &&
        isSet(this.query) &&
        this.query.isConfigured() &&
        isRequiredInputsSet(this.queryParameters, this.queryInputs) &&
        (options.columnsOptional || this.columns.length > 0)
      );
    } else if (this.type == DataSourceType.Input) {
      return isSet(this.input) && this.input.isSet() && this.columns.length > 0;
    } else if (this.type == DataSourceType.Workflow) {
      return isSet(this.workflow) && this.columns.length > 0;
    } else {
      return false;
    }
  }

  getVisibleColumns(): DisplayField[] {
    return this.columns.filter(item => item.visible);
  }
}

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, Observable, of, Subscription } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { NotificationService } from '@common/notifications';
import { ActionControllerService, ActionService } from '@modules/action-queries';
import { ActionItem, ViewSettingsAction } from '@modules/actions';
import { CustomizeService, ViewContext, ViewContextElement } from '@modules/customize';
import { applyBooleanInput$, applyParamInput$, getInputsValid$ } from '@modules/fields';

@Component({
  selector: 'app-select-field-action-item',
  templateUrl: './select-field-action-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectFieldActionItemComponent implements OnInit, OnDestroy, OnChanges {
  @Input() item: ViewSettingsAction;
  @Input() context: ViewContext;
  @Input() contextElement: ViewContextElement;
  @Input() stickyTop = false;
  @Input() stickyBottom = false;
  @Input() analyticsSource: string;
  @Output() execute = new EventEmitter<void>();

  submitLoading = false;
  customizeEnabled$: Observable<boolean>;
  verboseName: string;
  verboseNameSubscription: Subscription;
  disabled = false;
  disabledSubscription: Subscription;
  visible = false;
  visibleSubscription: Subscription;

  constructor(
    private actionService: ActionService,
    private actionControllerService: ActionControllerService,
    private customizeService: CustomizeService,
    private notificationService: NotificationService,
    private injector: Injector,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.customizeEnabled$ = this.customizeService.enabled$.pipe(map(item => !!item));
  }

  ngOnDestroy(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    this.initVerboseName();
    this.initDisabled();
    this.updateVisible();
  }

  initVerboseName() {
    if (this.verboseNameSubscription) {
      this.verboseNameSubscription.unsubscribe();
      this.verboseNameSubscription = undefined;
    }

    if (!this.item.verboseNameInput) {
      this.verboseName = undefined;
      this.cd.markForCheck();
      return;
    }

    this.verboseNameSubscription = applyParamInput$<string>(this.item.verboseNameInput, {
      context: this.context,
      contextElement: this.contextElement,
      defaultValue: ''
    })
      .pipe(untilDestroyed(this))
      .subscribe(value => {
        this.verboseName = value;
        this.cd.markForCheck();
      });
  }

  getInputsValid$(): Observable<boolean> {
    return this.actionService.getActionDescription(this.item).pipe(
      switchMap(actionDescription => {
        if (!actionDescription) {
          return of(true);
        }

        return getInputsValid$(this.item.inputs, {
          context: this.context,
          parameters: actionDescription.actionParams
        });
      })
    );
  }

  getElementDisabled$() {
    return this.item.disabledInput && this.item.disabledInput.isSet()
      ? applyBooleanInput$(this.item.disabledInput, {
          context: this.context
        })
      : of(false);
  }

  initDisabled() {
    if (this.disabledSubscription) {
      this.disabledSubscription.unsubscribe();
      this.disabledSubscription = undefined;
    }

    if (!this.item) {
      this.disabled = false;
      this.cd.markForCheck();
      return;
    }

    combineLatest(this.getInputsValid$(), this.getElementDisabled$())
      .pipe(untilDestroyed(this))
      .subscribe(([inputsValid, inputDisabled]) => {
        this.disabled = inputDisabled || !inputsValid;
        this.cd.markForCheck();
      });
  }

  updateVisible() {
    if (this.visibleSubscription) {
      this.visibleSubscription.unsubscribe();
      this.visibleSubscription = undefined;
    }

    if (!this.item.visibleInput) {
      this.visible = true;
      this.cd.markForCheck();
      return;
    }

    this.visibleSubscription = applyBooleanInput$(this.item.visibleInput, {
      context: this.context,
      contextElement: this.contextElement
    })
      .pipe(untilDestroyed(this))
      .subscribe(visible => {
        this.visible = visible;
        this.cd.markForCheck();
      });
  }

  executeAction(item: ActionItem) {
    this.execute.emit();

    this.submitLoading = true;
    this.cd.markForCheck();

    this.actionControllerService
      .execute(item, {
        context: this.context,
        contextElement: this.contextElement,
        injector: this.injector,
        analyticsSource: this.analyticsSource
      })
      .subscribe(
        () => {
          this.submitLoading = false;
          this.cd.markForCheck();
        },
        () => {
          this.submitLoading = false;
          this.cd.markForCheck();
        }
      );
  }
}

import { Directive, ElementRef, EventEmitter, Input, NgZone, OnDestroy, OnInit, Output } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { fromEvent } from 'rxjs';
import { filter } from 'rxjs/operators';

import { MouseButton, pointsDistance } from '@shared';

import { ViewEditorContext } from '../../../services/view-editor-context/view-editor.context';

const markViewBoundsMouseDownProperty = '_markViewBoundsMouseDownProperty';

export function markViewBoundsMouseDown(mouseDownEvent: MouseEvent) {
  mouseDownEvent[markViewBoundsMouseDownProperty] = true;
}

export function isViewBoundsMouseDown(mouseDownEvent: MouseEvent) {
  return !!mouseDownEvent[markViewBoundsMouseDownProperty];
}

export interface LayerResizableResizeEvent {
  deltaX: number;
  deltaY: number;
  shift?: boolean;
  alt?: boolean;
}

@Directive({
  selector: '[appLayerResizable]'
})
export class LayerResizableDirective implements OnInit, OnDestroy {
  @Input('appLayerResizableHorizontal') horizontal = true;
  @Input('appLayerResizableVertical') vertical = true;
  @Output('appLayerResizableResizeStarted') resizeStarted = new EventEmitter<void>();
  @Output('appLayerResizableResize') resize = new EventEmitter<LayerResizableResizeEvent>();
  @Output('appLayerResizableResizeFinished') resizeFinished = new EventEmitter<void>();

  resizeThreshold = 5;
  originalPosition: {
    x: number;
    y: number;
  };

  constructor(private el: ElementRef, private editorContext: ViewEditorContext, private zone: NgZone) {}

  ngOnInit(): void {
    this.initResize();
  }

  ngOnDestroy(): void {}

  initResize() {
    this.zone.runOutsideAngular(() => {
      fromEvent<MouseEvent>(this.el.nativeElement, 'mousedown')
        .pipe(
          filter(e => e.button == MouseButton.Main),
          filter(() => {
            return !this.editorContext.isCreateTool() && !this.editorContext.navigateMode$.value;
          }),
          untilDestroyed(this)
        )
        .subscribe(downEvent => {
          downEvent.preventDefault();

          markViewBoundsMouseDown(downEvent);

          const subscriptions = [];
          let thresholdPassed = false;

          this.originalPosition = {
            x: downEvent.clientX,
            y: downEvent.clientY
          };
          this.resizeStarted.emit();

          subscriptions.push(
            fromEvent<MouseEvent>(document, 'mousemove')
              .pipe(untilDestroyed(this))
              .subscribe(moveEvent => {
                moveEvent.preventDefault();

                if (!thresholdPassed) {
                  const originDistance = pointsDistance(
                    downEvent.clientX,
                    downEvent.clientY,
                    moveEvent.clientX,
                    moveEvent.clientY
                  );
                  if (originDistance >= this.resizeThreshold) {
                    thresholdPassed = true;
                  }
                }

                if (thresholdPassed) {
                  const scale = this.editorContext.viewportScale$.value;
                  const deltaX = Math.round((moveEvent.clientX - downEvent.clientX) / scale);
                  const deltaY = Math.round((moveEvent.clientY - downEvent.clientY) / scale);

                  this.resize.emit({
                    deltaX: this.horizontal ? deltaX : 0,
                    deltaY: this.vertical ? deltaY : 0,
                    shift: moveEvent.shiftKey,
                    alt: moveEvent.altKey
                  });
                }
              })
          );

          subscriptions.push(
            fromEvent<MouseEvent>(document, 'mouseup')
              .pipe(
                filter(e => e.button == MouseButton.Main),
                untilDestroyed(this)
              )
              .subscribe(upEvent => {
                upEvent.preventDefault();

                subscriptions.forEach(item => item.unsubscribe());

                this.resizeFinished.emit();
              })
          );
        });
    });
  }
}

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { FormUtils } from '@common/form-utils';
import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { CurrentUserStore, Employment, UserLevel } from '@modules/users';
import { isSet } from '@shared';
export var validateCompanyWebsite = function (control) {
    if (!control.parent) {
        return;
    }
    var parent = control.parent;
    if (parent.controls.employment.value != Employment.Company) {
        return;
    }
    if (!isSet(control.value)) {
        return { required: true };
    }
};
var ProjectCreateUserForm = /** @class */ (function (_super) {
    __extends(ProjectCreateUserForm, _super);
    function ProjectCreateUserForm(formUtils, analyticsService, currentUserStore) {
        var _this = _super.call(this, {
            employment: new FormControl(Employment.Company, Validators.required),
            company_website: new FormControl('', validateCompanyWebsite),
            phone: new FormControl('')
        }) || this;
        _this.formUtils = formUtils;
        _this.analyticsService = analyticsService;
        _this.currentUserStore = currentUserStore;
        _this.levelOptions = [
            { value: UserLevel.Beginner, name: '📙 Beginner. I am new to databases and business apps' },
            { value: UserLevel.Intermediate, name: '🤙 Intermediate. I have experience with databases and business apps' },
            { value: UserLevel.Advanced, name: '😎 Advanced. I am an expert with databases and business apps' }
        ];
        _this.controls.employment.valueChanges.pipe(untilDestroyed(_this)).subscribe(function () {
            _this.controls.company_website.updateValueAndValidity();
        });
        _this.markAsSubmit(false);
        return _this;
    }
    ProjectCreateUserForm.prototype.ngOnDestroy = function () { };
    ProjectCreateUserForm.prototype.markAsSubmit = function (submit) {
        this['submit'] = submit;
        if (submit) {
            this.markAsDirty();
        }
    };
    ProjectCreateUserForm.prototype.send = function () {
        var _this = this;
        this.markAsSubmit(true);
        this.currentUserStore.instance.params.userInfo = {
            employment: this.controls.employment.value,
            company_website: this.controls.company_website.value,
            phone: this.controls.phone.value
        };
        return this.currentUserStore.update(['params']).pipe(tap(function () {
            _this.analyticsService.sendSimpleEvent(AnalyticsEvent.Project.UserInfoEntered, {
                Employment: _this.controls.employment.value,
                CompanyWebsite: _this.controls.company_website.value,
                Phone: _this.controls.phone.value
            });
        }), catchError(function (error) {
            _this.formUtils.showFormErrors(_this, error);
            return throwError(error);
        }));
    };
    return ProjectCreateUserForm;
}(FormGroup));
export { ProjectCreateUserForm };

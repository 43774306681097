import { Injectable } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import cloneDeep from 'lodash/cloneDeep';

import { IteratorWorkflowStep } from '@modules/workflow';
import { isSet } from '@shared';

import { ListModelDescriptionDataSourceControl } from '../../../model-description-data-source-edit/list-model-description-data-source';

@Injectable()
export class IteratorCustomizeWorkflowStepForm extends FormGroup {
  instance: IteratorWorkflowStep;

  controls: {
    name: FormControl;
    data_source: ListModelDescriptionDataSourceControl;
    sorting_field: FormControl;
    sorting_asc: FormControl;
  };

  constructor(dataSourceControl: ListModelDescriptionDataSourceControl) {
    super({
      name: new FormControl(''),
      data_source: dataSourceControl,
      sorting_field: new FormControl(undefined),
      sorting_asc: new FormControl(true)
    });
  }

  init(instance: IteratorWorkflowStep, options: { firstInit?: boolean } = {}) {
    this.instance = instance;

    if (instance) {
      this.controls.name.patchValue(instance.name);
      this.controls.data_source.deserialize(instance.dataSource);
      this.controls.sorting_field.patchValue(instance.sortingField || null);
      this.controls.sorting_asc.patchValue(instance.sortingAsc);
    }

    if (!options.firstInit) {
      this.markAsDirty();
    }
  }

  toggleDefaultSorting() {
    const control = this.controls.sorting_asc;
    control.patchValue(!control.value);
  }

  submit(): IteratorWorkflowStep {
    const instance = cloneDeep(this.instance) as IteratorWorkflowStep;

    instance.name = this.controls.name.value;
    instance.dataSource = this.controls.data_source.serialize();

    if (isSet(this.controls.sorting_field.value)) {
      instance.sortingField = this.controls.sorting_field.value;
    } else {
      instance.sortingField = undefined;
    }

    instance.sortingAsc = this.controls.sorting_asc.value;

    return instance;
  }
}

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';

import { ProjectBillingService, ProjectUser } from '@modules/projects';
import { CurrentUserStore } from '@modules/users';
import { TypedChanges } from '@shared';

import { SubscriptionEnvironmentUserListStore } from '../../../projects/stores/subscription-environment-user.list-store';

@Component({
  selector: 'app-project-billing-details-popup-environment',
  templateUrl: './project-billing-details-popup-environment.component.html',
  providers: [SubscriptionEnvironmentUserListStore],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProjectBillingDetailsPopupEnvironmentComponent implements OnInit, OnDestroy, OnChanges {
  @Input() environmentData: ProjectBillingService.EnvironmentUsersResponse;

  items: ProjectUser[];
  loading = false;
  hasMore = true;

  constructor(
    public userListStore: SubscriptionEnvironmentUserListStore,
    public currentUserStore: CurrentUserStore,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.userListStore.items$.pipe(untilDestroyed(this)).subscribe(value => {
      this.items = value;
      this.cd.markForCheck();
    });

    this.userListStore.loading$.pipe(untilDestroyed(this)).subscribe(value => {
      this.loading = value;
      this.cd.markForCheck();
    });

    this.userListStore.hasMore$.pipe(untilDestroyed(this)).subscribe(value => {
      this.hasMore = value;
      this.cd.markForCheck();
    });
  }

  ngOnDestroy(): void {}

  ngOnChanges(changes: TypedChanges<ProjectBillingDetailsPopupEnvironmentComponent>): void {
    if (changes.environmentData) {
      this.userListStore.environment = this.environmentData.environment.uniqueName;
      this.userListStore.perPage = this.environmentData.pageSize;
      this.userListStore.reset(1);

      this.userListStore.appendFetchItems(this.environmentData.results);
      this.userListStore.appendItems(this.environmentData.results);

      this.userListStore.fetchHasMore = this.environmentData.next;
      this.userListStore.fetchTotalPages = Math.ceil(this.environmentData.count / this.environmentData.pageSize);
      this.userListStore.currentPage = 1;
      this.userListStore.nextPage = 2;
      this.userListStore.fetchNextPage = 2;
      this.userListStore.count = this.environmentData.count;
    }
  }
}

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChildren,
  ViewContainerRef
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TweenMax } from 'gsap';
import * as moment from 'moment';
import { untilDestroyed } from 'ngx-take-until-destroy';

import { LocalStorage } from '@core';
import { ActionService, isModelUpdateEventMatch, patchModel } from '@modules/action-queries';
import { TableSettings, ViewContext, ViewContextElement } from '@modules/customize';
import { DisplayField, FieldType } from '@modules/fields';
import { ColumnListItem, ListItem } from '@modules/list';
import { Model, ModelDescription } from '@modules/models';
import { isSet } from '@shared';

@Component({
  selector: 'app-table-item, [app-table-item]',
  templateUrl: './table-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableItemComponent implements OnInit, OnDestroy, OnChanges {
  @Input() row: ListItem;
  @Input() modelDescription: ModelDescription;
  @Input() visibleColumns: DisplayField[];
  @Input() selected = false;
  @Input() checked = false;
  @Input() checkEnabled = false;
  @Input() settings: TableSettings;
  @Input() context: ViewContext;
  @Input() contextElement: ViewContextElement;
  @Input() viewId: string;
  @Input() accentColor: string;
  @Output() init = new EventEmitter<TableItemComponent>();
  @Output() destroy = new EventEmitter<TableItemComponent>();
  @Output() modelUpdated = new EventEmitter<Model>();
  @Output() selectToggle = new EventEmitter<ListItem>();
  @Output() checkedToggle = new EventEmitter<void>();
  @Output() changeColumnValue = new EventEmitter<{
    name: string;
    value: any;
  }>();

  @ViewChildren('column_element', { read: ElementRef }) columnElements = new QueryList<ElementRef>();

  columnListItems: ColumnListItem[] = [];
  fieldTypes = FieldType;

  constructor(
    public el: ElementRef,
    private vcRef: ViewContainerRef,
    private activatedRoute: ActivatedRoute,
    private cd: ChangeDetectorRef,
    private actionService: ActionService,
    private localStorage: LocalStorage
  ) {}

  ngOnInit() {
    this.actionService.modelUpdated$.pipe(untilDestroyed(this)).subscribe(e => {
      if (isModelUpdateEventMatch(e, this.modelDescription, this.row.model)) {
        this.row.model = patchModel(this.row.model, e.model);
        this.cd.markForCheck();
        this.modelUpdated.next(this.row.model);
      }
    });

    this.init.emit(this);
  }

  ngOnDestroy(): void {
    this.destroy.emit(this);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['row'] || changes['visibleColumns']) {
      this.updateItems();
    }
  }

  updateItems() {
    this.columnListItems = this.visibleColumns.map(item => {
      return {
        listItem: this.row.columns.find(i => i.column == item.name),
        column: item,
        width: this.getColumnWidth(item.name)
      };
    });
  }

  trackByFn(i, item: ColumnListItem) {
    return item ? item.column.name : i;
  }

  toggleChecked() {
    if (!this.checkEnabled) {
      return;
    }

    this.checkedToggle.emit();
  }

  onClick(e: MouseEvent) {
    this.contextElement.context.clickEvent = e;
    this.selectToggle.emit(this.row);
  }

  isEditableColumn(listItem: ColumnListItem): boolean {
    if (!this.modelDescription) {
      return false;
    }

    const updateParameters = this.modelDescription.updateParametersOrDefaults;
    const field = this.modelDescription.dbField(listItem.column.name);

    return (
      this.modelDescription.updateQuery &&
      this.modelDescription.updateQuery.isConfigured() &&
      field &&
      !!updateParameters.find(item => item.name == listItem.column.name) &&
      !!this.settings.editingFields.find(item => item.name == listItem.column.name)
    );
  }

  setColumnWidth(index: number, width: number, save = false) {
    this.columnElements.forEach((item, i) => {
      const itemWidth = i === index ? width : item.nativeElement.offsetWidth;
      TweenMax.set(item.nativeElement, {
        'min-width': itemWidth,
        'max-width': itemWidth
      });
    });

    if (save) {
      this.saveColumnWidth(this.columnListItems[index].column.name, width);
      this.updateItems();
    }
  }

  getColumnWidthKey(name: string) {
    return ['column_width', this.viewId, name].join('_');
  }

  getColumnWidth(name: string): number {
    const dataStr = this.localStorage.get(this.getColumnWidthKey(name));
    if (!isSet(dataStr)) {
      return;
    }
    const data = JSON.parse(dataStr);
    return data['width'];
    // TODO: Add localstorage cleanup
  }

  saveColumnWidth(name: string, width: number) {
    this.localStorage.set(
      this.getColumnWidthKey(name),
      JSON.stringify({
        width: width,
        date: moment().toISOString()
      })
    );
  }
}

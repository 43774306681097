var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Injector } from '@angular/core';
import clone from 'lodash/clone';
import isPlainObject from 'lodash/isPlainObject';
import keys from 'lodash/keys';
import pickBy from 'lodash/pickBy';
import { getFieldDescriptionByType } from '@modules/fields';
import { EMPTY, isSet } from '@shared';
import { detectPrimaryKey } from '../utils/common/common';
export var NO_KEY_ATTRIBUTE = 'result';
var Model = /** @class */ (function () {
    function Model(injector) {
        this.injector = injector;
        this.rawAttributes = {};
        this.attributes = {};
        this.relations = {};
        this.noAttributesKey = false;
    }
    Model.prototype.createModel = function () {
        return Injector.create({
            providers: [{ provide: Model, deps: [Injector] }],
            parent: this.injector
        }).get(Model);
    };
    Model.prototype.getRelationKey = function (options) {
        return JSON.stringify([options.path, options.field]);
    };
    Model.prototype.getRelationValue = function (options) {
        var key = this.getRelationKey(options);
        var defaultValue = options.defaultValue !== undefined ? options.defaultValue : EMPTY;
        return this.relations.hasOwnProperty(key) ? this.relations[key].value : defaultValue;
    };
    Model.prototype.deserialize = function (model, data, relations) {
        var _this = this;
        if (data === void 0) { data = {}; }
        if (relations === void 0) { relations = []; }
        this.model = model;
        if (data['__str__'] != undefined) {
            this.dbStr = data['__str__'];
            delete data['__str__'];
        }
        if (!isPlainObject(data)) {
            this.setRawAttribute(NO_KEY_ATTRIBUTE, data);
            this.noAttributesKey = true;
        }
        else {
            keys(data).forEach(function (key) {
                _this.setRawAttribute(key, data[key]);
            });
        }
        if (data['__previous_sibling__']) {
            this.previousSibling = this.createModel().deserialize(this.model, data['__previous_sibling__']);
        }
        if (data['__next_sibling__']) {
            this.nextSibling = this.createModel().deserialize(this.model, data['__next_sibling__']);
        }
        this.relations = relations.reduce(function (acc, item) {
            var key = _this.getRelationKey(item);
            acc[key] = item;
            return acc;
        }, {});
        return this;
    };
    Model.prototype.setUp = function (modelDescription) {
        this.model = modelDescription.model;
        this.modelDescription = modelDescription;
        this.updatePrimaryKey();
        if (this.previousSibling) {
            this.previousSibling.setUp(modelDescription);
        }
        if (this.nextSibling) {
            this.nextSibling.setUp(modelDescription);
        }
    };
    Model.prototype.setPrimaryKey = function (value) {
        this.primaryKey = value;
        if (!isSet(this.initialPrimaryKey) && isSet(value)) {
            this.initialPrimaryKey = value;
        }
    };
    Model.prototype.updatePrimaryKey = function () {
        if (this.modelDescription && this.modelDescription.primaryKeyField) {
            var primaryKey = this.getAttribute(this.modelDescription.primaryKeyField);
            this.setPrimaryKey(primaryKey);
        }
        else {
            var primaryKeyField = detectPrimaryKey(keys(this.getAttributes()));
            if (isSet(primaryKeyField)) {
                var primaryKey = this.getAttribute(primaryKeyField);
                this.setPrimaryKey(primaryKey);
            }
        }
    };
    Model.prototype.deserializeAttributes = function (fields) {
        var _this = this;
        var jetFormat = this.modelDescription ? this.modelDescription.syncResource : false;
        fields.forEach(function (item) {
            var value = _this.getRawAttribute(item.name);
            var fieldDescription = getFieldDescriptionByType(item.field);
            if (fieldDescription && fieldDescription.deserializeValue) {
                var field = item;
                if (jetFormat && item.params && item.params['output_format']) {
                    field = __assign({}, item, { params: __assign({}, item.params, { output_format: undefined }) });
                }
                value = fieldDescription.deserializeValue(value, field);
            }
            _this.setAttribute(item.name, value);
        });
        this.updatePrimaryKey();
    };
    Model.prototype.serialize = function (fields) {
        var data = clone(this.getAttributes());
        if (fields) {
            data = pickBy(data, function (v, k) { return fields.includes(k); });
        }
        if (this.modelDescription) {
            this.modelDescription.dbFields
                .filter(function (item) { return item.fieldDescription.serializeValue != undefined && data[item.name] != undefined; })
                .forEach(function (item) {
                data[item.name] = item.fieldDescription.serializeValue(data[item.name], item);
            });
        }
        return data;
    };
    Model.prototype.getRawAttribute = function (name) {
        return this.rawAttributes[name];
    };
    Model.prototype.hasRawAttribute = function (name) {
        return this.rawAttributes.hasOwnProperty(name);
    };
    Model.prototype.getRawAttributes = function () {
        return this.rawAttributes;
    };
    Model.prototype.setRawAttribute = function (name, value) {
        this.rawAttributes[name] = value;
    };
    Model.prototype.setRawAttributes = function (data) {
        this.rawAttributes = __assign({}, data);
    };
    Model.prototype.getRawData = function () {
        if (this.noAttributesKey) {
            return this.getRawAttribute(NO_KEY_ATTRIBUTE);
        }
        else {
            return this.rawAttributes;
        }
    };
    Model.prototype.getAttribute = function (name) {
        return this.attributes[name];
    };
    Model.prototype.hasAttribute = function (name) {
        return this.attributes.hasOwnProperty(name);
    };
    Model.prototype.getAttributes = function () {
        return this.attributes;
    };
    Model.prototype.setAttribute = function (name, value) {
        this.attributes[name] = value;
    };
    Model.prototype.setAttributes = function (data) {
        this.attributes = __assign({}, data);
    };
    Model.prototype.getData = function () {
        if (this.noAttributesKey) {
            return this.getAttribute(NO_KEY_ATTRIBUTE);
        }
        else {
            return this.attributes;
        }
    };
    Object.defineProperty(Model.prototype, "modelId", {
        get: function () {
            if (!this.modelDescription || !this.modelDescription.resource) {
                return this.model;
            }
            return [this.modelDescription.resource, this.model].join('.');
        },
        enumerable: true,
        configurable: true
    });
    Model.prototype.getLink = function () {
        if (!this.modelDescription) {
            return;
        }
        return this.modelDescription.changeLink(this.primaryKey);
    };
    Object.defineProperty(Model.prototype, "userActivityLink", {
        get: function () {
            return ['models', this.modelId, this.primaryKey, 'user_activities'];
        },
        enumerable: true,
        configurable: true
    });
    Model.prototype.isSame = function (model) {
        if (!model) {
            return false;
        }
        return isSet(this.primaryKey) && isSet(model.primaryKey) && this.primaryKey == model.primaryKey;
    };
    Model.prototype.clone = function () {
        var result = this.createModel();
        result.modelDescription = this.modelDescription;
        result.rawAttributes = this.rawAttributes;
        result.attributes = this.attributes;
        result.primaryKey = this.primaryKey;
        result.initialPrimaryKey = this.initialPrimaryKey;
        result.dbStr = this.dbStr;
        result.previousSibling = this.previousSibling;
        result.nextSibling = this.nextSibling;
        result.model = this.model;
        result.noAttributesKey = this.noAttributesKey;
        return result;
    };
    return Model;
}());
export { Model };

<div *ngIf="!control.controls.icon_input_enabled.value" class="compact-inputs-element">
  <div class="compact-inputs-row">
    <div class="compact-inputs-row__item compact-inputs-row__item_fill">
      <app-auto-field
        [form]="control"
        [label]="false"
        [field]="
          createField({
            name: 'icon',
            label: 'Icon',
            field: 'IconField',
            params: { classes: ['select_small', 'select_fill'] }
          })
        "
      ></app-auto-field>
    </div>

    <div class="compact-inputs-row__item">
      <div
        class="compact-button compact-button_primary icon-function"
        [appTip]="'Set up with Formula'"
        [appTipOptions]="{ side: 'top' }"
        (click)="toggleImageInputEnabled()"
      ></div>
    </div>
  </div>
</div>

<ng-container *ngIf="control.controls.icon_input_enabled.value">
  <div class="compact-inputs-element">
    <app-input-edit
      [itemForm]="control.controls.icon_input"
      [context]="viewContext"
      [staticValueDisabled]="true"
      [focusedInitial]="iconInputFocused"
      [formulaPlaceholder]="'Icon with Formula'"
      [fill]="true"
      [dark]="true"
      [darker]="true"
    ></app-input-edit>
  </div>

  <div class="compact-inputs-element">
    <div class="compact-button-tag button-tags__item_fill" (click)="toggleImageInputEnabled()">
      <span class="compact-button-tag__icon icon-function"></span>
      <span class="compact-button-tag__label">Disable Formula</span>
    </div>
  </div>
</ng-container>

<app-color-with-input-selector
  [colorControl]="control.controls.color"
  [colorInputEnabledControl]="control.controls.color_input_enabled"
  [colorInputControl]="control.controls.color_input"
  [viewContext]="viewContext"
></app-color-with-input-selector>

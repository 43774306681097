import { exactFieldLookup } from '@modules/field-lookups';

import { BaseField } from '../base-field';
import { FieldType } from '../field-type';

export const booleanAirtableFormulas = [
  {
    field: FieldType.Boolean,
    lookup: exactFieldLookup,
    formula: (lookupValue: boolean, field?: BaseField): string => {
      return `{${field.name}}`;
    }
  }
];

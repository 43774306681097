import { Injectable } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import cloneDeep from 'lodash/cloneDeep';
import { throwError } from 'rxjs';
import { catchError, delayWhen, tap } from 'rxjs/operators';

import { FormUtils } from '@common/form-utils';
import { ActionDescriptionService, ActionStore } from '@modules/action-queries';
import { ActionDescription, ActionType, DownloadAction, QueryAction } from '@modules/actions';
import { ParameterField } from '@modules/fields';
import { CurrentEnvironmentStore, CurrentProjectStore, Resource } from '@modules/projects';
import { ActionQuery } from '@modules/queries';
import { generateAlphanumeric } from '@shared';

export interface ActionCreateOptions {
  type?: ActionType;
  query?: ActionQuery;
  parameters?: ParameterField[];
}

@Injectable()
export class ActionCreatePopupForm {
  form: FormGroup;
  resource: Resource;
  options: ActionCreateOptions = {};

  constructor(
    private actionDescriptionService: ActionDescriptionService,
    private actionStore: ActionStore,
    private fb: FormBuilder,
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private formUtils: FormUtils
  ) {
    this.form = this.fb.group({
      verbose_name: new FormControl('', [Validators.required, this.validateVerboseName()])
    });
  }

  validateVerboseName(): ValidatorFn {
    return control => {
      if (!control.value || !this.resource) {
        return;
      }

      if (
        this.actionStore.instance
          .filter(item => item.resource == this.resource.uniqueName && !item.deleted)
          .find(item => item.verboseName && item.verboseName.toLowerCase() == control.value.toLowerCase())
      ) {
        return {
          local: ['Action with such Name already exists']
        };
      }
    };
  }

  init(resource: Resource, options: ActionCreateOptions = {}) {
    this.resource = resource;
    this.options = options;
  }

  submit() {
    if (!this.form.valid) {
      return throwError('Not all fields are filled in.');
    }

    const value = this.form.value;
    const instance = new ActionDescription();

    instance.resource = this.resource.uniqueName;
    instance.name = ActionDescription.generateName();
    instance.verboseName = value.verbose_name;
    instance.virtual = true;
    instance.type = this.options.type || ActionType.Query;

    if (this.options.query) {
      if (instance.type == ActionType.Query) {
        instance.queryAction = new QueryAction();
        instance.queryAction.query = cloneDeep(this.options.query);
      } else if (instance.type == ActionType.Download) {
        instance.downloadAction = new DownloadAction();
        instance.downloadAction.query = cloneDeep(this.options.query);
      }
    }

    if (this.options.parameters) {
      instance.actionParams = cloneDeep(this.options.parameters);
    }

    return this.actionDescriptionService
      .create(this.currentProjectStore.instance.uniqueName, this.currentEnvironmentStore.instance.uniqueName, instance)
      .pipe(
        tap(result => this.actionStore.addItem(result)),
        catchError(error => {
          this.form.markAsDirty();
          this.formUtils.showFormErrors(this.form, error);
          return throwError(error);
        })
      );
  }
}

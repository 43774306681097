import Dexie from 'dexie';
import * as moment from 'moment';

import { ProjectProperty, ProjectPropertyType } from '../../data/project-property';

export interface BaseProjectPropertyValueTable {
  project: string;
  environment: string;
  name: string;
  value: string;
  dateUpdated: string;
}

export interface PageProjectPropertyValueTable extends BaseProjectPropertyValueTable {
  pageUid: string;
}

export type ProjectPropertyValueTableCriteria = Partial<BaseProjectPropertyValueTable & PageProjectPropertyValueTable>;

export class ProjectPropertyValueDB extends Dexie {
  global!: Dexie.Table<BaseProjectPropertyValueTable, [string, string, string]>;
  page!: Dexie.Table<PageProjectPropertyValueTable, [string, string, string, string]>;

  constructor(private databaseName: string) {
    super(databaseName);
    this.version(1).stores({
      global: '[project+environment+name], value, dateUpdated',
      page: '[project+environment+pageUid+name], value, dateUpdated'
    });
  }

  getPropertyTable(type: ProjectPropertyType) {
    if (type == ProjectPropertyType.Global) {
      return this.global;
    } else if (type == ProjectPropertyType.Page) {
      return this.page;
    }
  }

  getPropertyCriteria(
    projectName: string,
    environmentName: string,
    property: ProjectProperty,
    options: { pageUid?: string } = {}
  ): ProjectPropertyValueTableCriteria {
    if (property.type == ProjectPropertyType.Global) {
      return {
        project: projectName,
        environment: environmentName,
        name: property.uid
      };
    } else if (property.type == ProjectPropertyType.Page) {
      return {
        project: projectName,
        environment: environmentName,
        pageUid: options.pageUid,
        name: property.uid
      };
    }
  }

  getGlobalPropertyPk(criteria: ProjectPropertyValueTableCriteria): [string, string, string] {
    return [criteria.project, criteria.environment, criteria.name];
  }

  getPagePropertyPk(criteria: ProjectPropertyValueTableCriteria): [string, string, string, string] {
    return [criteria.project, criteria.environment, criteria.pageUid, criteria.name];
  }

  getPropertyValue(type: ProjectPropertyType, criteria: ProjectPropertyValueTableCriteria): Promise<any> {
    const table = this.getPropertyTable(type);
    return table.get(criteria).then(record => {
      return record ? record.value : undefined;
    });
  }

  putPropertyValue(type: ProjectPropertyType, criteria: ProjectPropertyValueTableCriteria, value: any): Promise<any> {
    const data = {
      ...criteria,
      value: value,
      dateUpdated: moment().toISOString()
    };

    if (type == ProjectPropertyType.Global) {
      return this.global.put(data as BaseProjectPropertyValueTable);
    } else if (type == ProjectPropertyType.Page) {
      return this.page.put(data as PageProjectPropertyValueTable);
    }
  }

  deleteProperty(type: ProjectPropertyType, criteria: ProjectPropertyValueTableCriteria): Promise<void> {
    if (type == ProjectPropertyType.Global) {
      const pk = this.getGlobalPropertyPk(criteria);
      return this.global.delete(pk);
    } else if (type == ProjectPropertyType.Page) {
      const pk = this.getPagePropertyPk(criteria);
      return this.page.delete(pk);
    }
  }
}

import { HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, publishLast, refCount } from 'rxjs/operators';

import { AdminMode, ROUTE_ADMIN_MODE } from '@modules/admin-mode';
import { ServerRequestError } from '@modules/api';
import {
  Environment,
  Project,
  Resource,
  ResourceTypeItem,
  SecretToken,
  SecretTokenService,
  SecretTokenType
} from '@modules/projects';
import { HttpQuery, HttpQueryService } from '@modules/queries';
import { ResourceParamsResult } from '@modules/resources';

// TODO: Refactor import
import { HTTP_QUERY_BASIC_AUTH_VALUE } from '@modules/projects-components/data/http-query-auth';

import { zendeskResourceParamsMenuSettings } from '../../data/zendesk/zendesk-resource-params-menu-settings.stub';
import { zendeskResourceParamsModelDescriptions } from '../../data/zendesk/zendesk-resource-params-model-descriptions.stub';
import { zendeskResourceParamsParams } from '../../data/zendesk/zendesk-resource-params-params.stub';
import { zendeskResourceParamsViewSettings } from '../../data/zendesk/zendesk-resource-params-view-settings.stub';
import { IsOptionsValidResult, ResourceGeneratorService } from '../resource-generator/resource-generator.service';

export interface ZendeskParamsOptions {
  account_name: string;
  account_email: string;
  key: string;
}

@Injectable()
export class ZendeskGeneratorService extends ResourceGeneratorService<ZendeskParamsOptions> {
  constructor(
    @Inject(ROUTE_ADMIN_MODE) private mode: AdminMode,
    private secretTokenService: SecretTokenService,
    private httpQueryService: HttpQueryService
  ) {
    super();
  }

  isOptionsValid(options: ZendeskParamsOptions): Observable<IsOptionsValidResult> {
    const query = new HttpQuery();

    query.url = `https://${options.account_name}.zendesk.com/api/v2/search.json`;
    query.headers = [{ name: 'Authorization', value: `Basic ${this.authTokenFromOptions(options)}` }];
    query.queryParams = [{ name: 'query', value: 'type:ticket' }];

    return this.httpQueryService.request(query).pipe(
      map(() => {
        return {};
      }),
      catchError(error => {
        if (error instanceof ServerRequestError && error.response instanceof HttpErrorResponse && error.status == 404) {
          error = new ServerRequestError('There is no help desk with such Account Name');
        } else if (
          error instanceof ServerRequestError &&
          error.response instanceof HttpErrorResponse &&
          error.status == 401
        ) {
          error = new ServerRequestError('Account Email or API Token is not valid or not enough permissions');
        }

        return throwError(error);
      }),
      publishLast(),
      refCount()
    );
  }

  getParamsOptions(project: Project, environment: Environment, resource: Resource): Observable<ZendeskParamsOptions> {
    return this.secretTokenService
      .getDetail(
        project.uniqueName,
        environment.uniqueName,
        resource.uniqueName,
        HTTP_QUERY_BASIC_AUTH_VALUE,
        this.mode == AdminMode.Builder
      )
      .pipe(map(result => this.authTokenToOptions(result.value, resource.params['account_name'])));
  }

  authTokenFromOptions(options: ZendeskParamsOptions): string {
    try {
      return btoa(`${options.account_email}/token:${options.key}`);
    } catch (e) {}
  }

  authTokenToOptions(token: string, accountName: string): ZendeskParamsOptions {
    try {
      const [account, key] = atob(token).split(':', 2);

      return {
        account_name: accountName,
        account_email: account.replace('/token', ''),
        key: key
      };
    } catch (e) {
      return {
        account_name: undefined,
        account_email: undefined,
        key: undefined
      };
    }
  }

  generateParams(
    project: Project,
    environment: Environment,
    typeItem: ResourceTypeItem,
    options: ZendeskParamsOptions
  ): Observable<ResourceParamsResult> {
    const resourceParams = zendeskResourceParamsParams;
    const modelDescriptions = zendeskResourceParamsModelDescriptions;
    const viewSettings = zendeskResourceParamsViewSettings;
    const menuSettings = zendeskResourceParamsMenuSettings;
    const token = new SecretToken();

    token.name = HTTP_QUERY_BASIC_AUTH_VALUE;
    token.type = SecretTokenType.Static;
    token.value = this.authTokenFromOptions(options);

    resourceParams['account_name'] = options.account_name;

    return of({
      resourceParams: resourceParams,
      modelDescriptions: modelDescriptions,
      viewSettings: viewSettings,
      menuSettings: menuSettings,
      secretTokens: [token.serialize()],
      extraTokens: {
        account_name: options.account_name
      }
    });
  }
}

export function isPointsEqual(lhs: Partial<Point>, rhs: Partial<Point>): boolean {
  return lhs.x == rhs.x && lhs.y == rhs.y;
}

export class Point {
  x = 0;
  y = 0;

  constructor(options: Partial<Point> = {}) {
    Object.assign(this, options);
  }

  deserialize(data: Object): this {
    this.x = data['x'];
    this.y = data['y'];

    return this;
  }

  serialize(): Object {
    return {
      x: this.x,
      y: this.y
    };
  }

  isEqual(point: Point): boolean {
    return isPointsEqual(this, point);
  }
}

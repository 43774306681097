import { ChangeDetectorRef, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest } from 'rxjs';
import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { ModelDescriptionStore } from '@modules/model-queries';
import { CurrentEnvironmentStore, Resource, ResourceName, resourceTypeItems } from '@modules/projects';
import { PageTemplateType, PageVariantType } from '@modules/template-generators';
import { objectsSortPredicate } from '@shared';
var PageTemplatesChooseItemModelComponent = /** @class */ (function () {
    function PageTemplatesChooseItemModelComponent(currentEnvironmentStore, modelDescriptionStore, analyticsService, cd) {
        this.currentEnvironmentStore = currentEnvironmentStore;
        this.modelDescriptionStore = modelDescriptionStore;
        this.analyticsService = analyticsService;
        this.cd = cd;
        this.selectModel = new EventEmitter();
        this.backClick = new EventEmitter();
        this.cancelClick = new EventEmitter();
        this.loading = false;
        this.modelDescriptions = [];
        this.resources = [];
    }
    PageTemplatesChooseItemModelComponent.prototype.ngOnInit = function () {
        this.initResources();
    };
    PageTemplatesChooseItemModelComponent.prototype.ngOnDestroy = function () { };
    PageTemplatesChooseItemModelComponent.prototype.initResources = function () {
        var _this = this;
        this.loading = true;
        this.cd.markForCheck();
        combineLatest(this.currentEnvironmentStore.resources$, this.modelDescriptionStore.get())
            .pipe(untilDestroyed(this))
            .subscribe(function (_a) {
            var resources = _a[0], modelDescriptions = _a[1];
            _this.resources = resources
                .filter(function (item) { return !item.typeItem.hidden && !item.demo; })
                .map(function (resource) {
                var models = _this.getResourceModels(modelDescriptions, resource);
                var disabled = false;
                var error;
                if (!models.length) {
                    disabled = true;
                    error = 'No collections';
                }
                else if (_this.template.type == PageTemplateType.Dashboard && !_this.isResourceChartsEnabled(resource)) {
                    disabled = true;
                    error = 'Charts not supported';
                }
                return {
                    resource: resource,
                    models: models,
                    disabled: disabled,
                    error: error
                };
            })
                .sort(objectsSortPredicate('disabled', 'resource.name'));
            _this.modelDescriptions = modelDescriptions;
            _this.loading = false;
            _this.cd.markForCheck();
            if (_this.resource) {
                _this.setSelectedResource({
                    resource: _this.resource,
                    models: _this.getResourceModels(modelDescriptions, _this.resource)
                });
            }
        }, function () {
            _this.loading = false;
            _this.cd.markForCheck();
        });
    };
    PageTemplatesChooseItemModelComponent.prototype.isResourceChartsEnabled = function (resource) {
        var typeItem = resource.typeItem;
        if ((resource && (resource.isSynced() || resource.hasCollectionSync())) || (!resource && typeItem.syncEnabled)) {
            typeItem = resourceTypeItems.find(function (item) { return item.name == ResourceName.PostgreSQL; });
        }
        return typeItem.chartsEnabled;
    };
    PageTemplatesChooseItemModelComponent.prototype.isResourceModelChartsEnabled = function (resource, modelDescription) {
        var typeItem = resource.typeItem;
        if (resource.isSynced(modelDescription.model) || modelDescription.isSynced()) {
            typeItem = resourceTypeItems.find(function (item) { return item.name == ResourceName.PostgreSQL; });
        }
        return typeItem.chartsEnabled;
    };
    PageTemplatesChooseItemModelComponent.prototype.getResourceModels = function (modelDescriptions, resource) {
        var _this = this;
        return modelDescriptions
            .filter(function (item) { return item.resource == resource.uniqueName && !item.hidden; })
            .map(function (item) {
            var disabled = false;
            var error;
            if (!item.getQuery) {
                disabled = true;
                error = 'Get query not configured';
            }
            else if (!item.dbFields.length) {
                disabled = true;
                error = 'Not fields';
            }
            else if (_this.template.type == PageTemplateType.AdminPanelEdit && !item.createQuery && !item.updateQuery) {
                disabled = true;
                error = 'Edit queries not configured';
            }
            else if (_this.template.type == PageTemplateType.Dashboard &&
                !_this.isResourceModelChartsEnabled(resource, item)) {
                disabled = true;
                error = 'Charts not supported';
            }
            return {
                modelDescription: item,
                disabled: disabled,
                error: error
            };
        })
            .sort(objectsSortPredicate('disabled', 'modelDescription.verboseNamePlural'));
    };
    PageTemplatesChooseItemModelComponent.prototype.setSelectedResource = function (resource) {
        this.selectedResource = resource;
        this.cd.markForCheck();
        if (this.selectedResource) {
            this.analyticsService.sendSimpleEvent(AnalyticsEvent.PageTemplates.ResourceSelected, {
                Template: this.template ? this.template.type : undefined,
                TemplateVariant: this.templateVariant,
                ResourceType: this.selectedResource.resource.typeItem.name,
                Resource: this.selectedResource.resource.uniqueName,
                Source: this.analyticsSource
            });
            if (this.selectedResource.models.length == 1 && !this.selectedResource.models[0].disabled) {
                this.setSelectedModel(this.selectedResource.models[0]);
                this.submit();
            }
        }
    };
    PageTemplatesChooseItemModelComponent.prototype.setSelectedModel = function (item) {
        this.selectedModel = item;
        this.cd.markForCheck();
    };
    PageTemplatesChooseItemModelComponent.prototype.submit = function () {
        this.selectModel.emit(this.selectedModel.modelDescription);
    };
    return PageTemplatesChooseItemModelComponent;
}());
export { PageTemplatesChooseItemModelComponent };

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FormControl, FormGroup } from '@angular/forms';
import { map } from 'rxjs/operators';
import { FlexLayout, FlexLayoutAlign, FlexLayoutOrientation } from '@modules/views';
import { controlValue, isSet } from '@shared';
export var FlexLayoutOrientationOption;
(function (FlexLayoutOrientationOption) {
    FlexLayoutOrientationOption["Horizontal"] = "horizontal";
    FlexLayoutOrientationOption["Vertical"] = "vertical";
    FlexLayoutOrientationOption["HorizontalWrap"] = "horizontal_wrap";
    FlexLayoutOrientationOption["VerticalWrap"] = "vertical_wrap";
})(FlexLayoutOrientationOption || (FlexLayoutOrientationOption = {}));
export function deserializeFlexLayoutOrientationOption(orientation, wrap) {
    if (orientation == FlexLayoutOrientation.Horizontal && !wrap) {
        return FlexLayoutOrientationOption.Horizontal;
    }
    else if (orientation == FlexLayoutOrientation.Horizontal && wrap) {
        return FlexLayoutOrientationOption.HorizontalWrap;
    }
    else if (orientation == FlexLayoutOrientation.Vertical && !wrap) {
        return FlexLayoutOrientationOption.Vertical;
    }
    else if (orientation == FlexLayoutOrientation.Vertical && wrap) {
        return FlexLayoutOrientationOption.VerticalWrap;
    }
    else {
        return FlexLayoutOrientationOption.Horizontal;
    }
}
export function serializeFlexLayoutOrientationOption(option) {
    if (option == FlexLayoutOrientationOption.Horizontal) {
        return { orientation: FlexLayoutOrientation.Horizontal, wrap: false };
    }
    else if (option == FlexLayoutOrientationOption.HorizontalWrap) {
        return { orientation: FlexLayoutOrientation.Horizontal, wrap: true };
    }
    else if (option == FlexLayoutOrientationOption.Vertical) {
        return { orientation: FlexLayoutOrientation.Vertical, wrap: false };
    }
    else if (option == FlexLayoutOrientationOption.VerticalWrap) {
        return { orientation: FlexLayoutOrientation.Vertical, wrap: true };
    }
    else {
        return { orientation: FlexLayoutOrientation.Horizontal, wrap: false };
    }
}
var FlexLayoutPaddingControl = /** @class */ (function (_super) {
    __extends(FlexLayoutPaddingControl, _super);
    function FlexLayoutPaddingControl(state) {
        if (state === void 0) { state = {}; }
        var _this = _super.call(this, {
            padding_top: new FormControl(isSet(state.paddingTop) ? state.paddingTop : 0),
            padding_right: new FormControl(isSet(state.paddingRight) ? state.paddingRight : 0),
            padding_bottom: new FormControl(isSet(state.paddingBottom) ? state.paddingBottom : 0),
            padding_left: new FormControl(isSet(state.paddingLeft) ? state.paddingLeft : 0)
        }) || this;
        _this.onDisabledChange = function (value) { return undefined; };
        return _this;
    }
    FlexLayoutPaddingControl.prototype.deserialize = function (value, options) {
        if (options === void 0) { options = {}; }
        this.instance = value;
        this.controls.padding_top.patchValue(value.paddingTop, { emitEvent: options.emitEvent });
        this.controls.padding_right.patchValue(value.paddingRight, { emitEvent: options.emitEvent });
        this.controls.padding_bottom.patchValue(value.paddingBottom, { emitEvent: options.emitEvent });
        this.controls.padding_left.patchValue(value.paddingLeft, { emitEvent: options.emitEvent });
    };
    FlexLayoutPaddingControl.prototype.getInstance = function (instance) {
        if (!instance) {
            instance = new FlexLayout();
        }
        instance.paddingTop = this.controls.padding_top.value;
        instance.paddingRight = this.controls.padding_right.value;
        instance.paddingBottom = this.controls.padding_bottom.value;
        instance.paddingLeft = this.controls.padding_left.value;
        return instance;
    };
    FlexLayoutPaddingControl.prototype.serialize = function () {
        return this.getInstance(this.instance);
    };
    FlexLayoutPaddingControl.prototype.registerOnChange = function (fn) {
        this.valueChanges.subscribe(function (value) { return fn(value); });
    };
    FlexLayoutPaddingControl.prototype.registerOnDisabledChange = function (fn) {
        this.onDisabledChange = fn;
    };
    FlexLayoutPaddingControl.prototype.enable = function (opts) {
        _super.prototype.enable.call(this, opts);
        this.onDisabledChange(false);
    };
    FlexLayoutPaddingControl.prototype.disable = function (opts) {
        _super.prototype.disable.call(this, opts);
        this.onDisabledChange(true);
    };
    return FlexLayoutPaddingControl;
}(FormGroup));
export { FlexLayoutPaddingControl };
var FlexLayoutControl = /** @class */ (function (_super) {
    __extends(FlexLayoutControl, _super);
    function FlexLayoutControl(state) {
        if (state === void 0) { state = {}; }
        var _this = _super.call(this, {
            orientation_option: new FormControl(isSet(state.orientation)
                ? deserializeFlexLayoutOrientationOption(state.orientation, state.wrap)
                : FlexLayoutOrientationOption.Horizontal),
            wrap: new FormControl(isSet(state.wrap) ? state.wrap : false),
            align_horizontal: new FormControl(isSet(state.alignHorizontal) ? state.alignHorizontal : FlexLayoutAlign.Start),
            align_vertical: new FormControl(isSet(state.alignVertical) ? state.alignVertical : FlexLayoutAlign.Start),
            gap_horizontal: new FormControl(isSet(state.gapHorizontal) ? state.gapHorizontal : 0),
            gap_vertical: new FormControl(isSet(state.gapVertical) ? state.gapVertical : 0),
            padding: new FlexLayoutPaddingControl(state)
        }) || this;
        _this.orientationOptions = [
            {
                value: FlexLayoutOrientationOption.Horizontal,
                name: '',
                subtitle: 'Horizontal layout',
                icon: 'arrow_forward'
            },
            {
                value: FlexLayoutOrientationOption.Vertical,
                name: '',
                subtitle: 'Vertical layout',
                icon: 'arrow_down'
            },
            {
                value: FlexLayoutOrientationOption.HorizontalWrap,
                name: '',
                subtitle: 'Horizontal wrap layout',
                icon: 'arrow_backward_turn'
            },
            {
                value: FlexLayoutOrientationOption.VerticalWrap,
                name: '',
                subtitle: 'Vertical wrap layout',
                icon: 'arrow_up_turn'
            }
        ];
        return _this;
    }
    FlexLayoutControl.prototype.deserialize = function (value, options) {
        if (options === void 0) { options = {}; }
        this.instance = value;
        this.controls.orientation_option.patchValue(deserializeFlexLayoutOrientationOption(value.orientation, value.wrap), {
            emitEvent: options.emitEvent
        });
        this.controls.align_horizontal.patchValue(value.alignHorizontal, { emitEvent: options.emitEvent });
        this.controls.align_vertical.patchValue(value.alignVertical, { emitEvent: options.emitEvent });
        this.controls.gap_horizontal.patchValue(value.gapHorizontal, { emitEvent: options.emitEvent });
        this.controls.gap_vertical.patchValue(value.gapVertical, { emitEvent: options.emitEvent });
        this.controls.padding.deserialize(value, { emitEvent: options.emitEvent });
    };
    FlexLayoutControl.prototype.getInstance = function (instance) {
        if (!instance) {
            instance = new FlexLayout();
        }
        var _a = serializeFlexLayoutOrientationOption(this.controls.orientation_option.value), orientation = _a.orientation, wrap = _a.wrap;
        instance.orientation = orientation;
        instance.wrap = wrap;
        instance.alignHorizontal = this.controls.align_horizontal.value;
        instance.alignVertical = this.controls.align_vertical.value;
        instance.gapHorizontal = this.controls.gap_horizontal.value;
        instance.gapVertical = this.controls.gap_vertical.value;
        instance.paddingTop = this.controls.padding.controls.padding_top.value;
        instance.paddingRight = this.controls.padding.controls.padding_right.value;
        instance.paddingBottom = this.controls.padding.controls.padding_bottom.value;
        instance.paddingLeft = this.controls.padding.controls.padding_left.value;
        return instance;
    };
    FlexLayoutControl.prototype.serialize = function () {
        return this.getInstance(this.instance);
    };
    FlexLayoutControl.prototype.isHorizontalOrientation$ = function () {
        return controlValue(this.controls.orientation_option.value).pipe(map(function (value) {
            var orientation = serializeFlexLayoutOrientationOption(value).orientation;
            return orientation == FlexLayoutOrientation.Horizontal;
        }));
    };
    FlexLayoutControl.prototype.isVerticalOrientation$ = function () {
        return controlValue(this.controls.orientation_option).pipe(map(function (value) {
            var orientation = serializeFlexLayoutOrientationOption(value).orientation;
            return orientation == FlexLayoutOrientation.Vertical;
        }));
    };
    FlexLayoutControl.prototype.isWrapOrientation$ = function () {
        return controlValue(this.controls.orientation_option).pipe(map(function (value) {
            var wrap = serializeFlexLayoutOrientationOption(value).wrap;
            return wrap;
        }));
    };
    return FlexLayoutControl;
}(FormGroup));
export { FlexLayoutControl };

<div class="feature-overview-line">
  <div class="feature-overview-line__subtitle">
    <ng-content select="[data-subtitle]"></ng-content>
  </div>
  <div class="feature-overview-line__title">
    <ng-content select="[data-title]"></ng-content>
  </div>
  <div class="feature-overview-line__description">
    <ng-content select="[data-description]"></ng-content>
  </div>
  <div class="feature-overview-line__buttons">
    <a
      [appLink]="(currentProjectStore.instance$ | async).settingsBillingLink"
      class="button button_primary button_small"
      >Upgrade Plan</a
    >
  </div>
</div>

import { ChangeDetectorRef, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Subject } from 'rxjs';
import { FeatureService } from '@modules/features';
import { CurrentProjectStore, isResourceTypeItem3rdParty, isResourceTypeItemCustom, Project, ResourceName, ResourceTypeItemCategory, resourceTypeItems } from '@modules/projects';
import { KeyboardEventKeyCode } from '@shared';
var CreateResourceComponent = /** @class */ (function () {
    function CreateResourceComponent(currentProjectStore, featureService, cd) {
        this.currentProjectStore = currentProjectStore;
        this.featureService = featureService;
        this.cd = cd;
        this.animatedShow = false;
        this.passed = false;
        this.future = false;
        this.chosen = new EventEmitter();
        this.customResource = resourceTypeItems.find(function (item) { return item.name == ResourceName.JetDatabase; });
        this.resourceFilters = [
            {
                label: 'All Integrations'
            },
            {
                label: 'Databases',
                filter: { category: ResourceTypeItemCategory.Databases }
            },
            {
                label: 'APIs',
                filter: { category: ResourceTypeItemCategory.APIs }
            },
            {
                label: 'Frameworks',
                filter: { category: ResourceTypeItemCategory.Frameworks }
            },
            {
                label: 'Storages',
                filter: { category: ResourceTypeItemCategory.Storages }
            }
        ];
        this.resourceFilterIndex = 0;
        this.resourceFilterCounts = [];
        this.resourceSearchUpdated = new Subject();
    }
    CreateResourceComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.resources = resourceTypeItems.filter(function (item) { return !item.hidden && !item.protected && item.name != ResourceName.JetDatabase; });
        this.resourcesNotFound = resourceTypeItems.filter(function (item) {
            return [ResourceName.RestApi, ResourceName.GraphQL].includes(item.name);
        });
        this.resourceSearchUpdated.pipe(untilDestroyed(this)).subscribe(function () { return _this.updateResourcesFiltered(); });
        this.updateResourcesFiltered();
    };
    CreateResourceComponent.prototype.ngOnDestroy = function () { };
    CreateResourceComponent.prototype.setResourceFilterIndex = function (i) {
        this.resourceFilterIndex = i;
        this.cd.markForCheck();
        this.updateResourcesFiltered();
    };
    CreateResourceComponent.prototype.updateResourcesFiltered = function () {
        var _this = this;
        var filterResources = function (resources, filter) {
            return resources.filter(function (item) {
                if (filter) {
                    if (filter.category != undefined) {
                        if (!item.categories.includes(filter.category)) {
                            return false;
                        }
                    }
                }
                if (_this.resourceSearch) {
                    if (item.label.toLowerCase().includes(_this.resourceSearch.toLowerCase()) == false) {
                        return false;
                    }
                }
                return true;
            });
        };
        this.resourceFilterCounts = this.resourceFilters.map(function (item) { return filterResources(_this.resources, item.filter).length; });
        if (!this.resourceFilterCounts[this.resourceFilterIndex]) {
            this.resourceFilterIndex = 0;
        }
        var currentFilter = this.currentResourceFilter;
        this.resourcesFiltered = filterResources(this.resources, currentFilter);
        this.cd.markForCheck();
    };
    CreateResourceComponent.prototype.clearSearch = function () {
        this.resourceSearch = '';
        this.resourceSearchUpdated.next();
        this.cd.markForCheck();
    };
    CreateResourceComponent.prototype.onSearchKey = function (e) {
        if (e.keyCode == KeyboardEventKeyCode.Escape) {
            this.clearSearch();
        }
    };
    Object.defineProperty(CreateResourceComponent.prototype, "currentResourceFilter", {
        get: function () {
            if (!this.resourceFilters[this.resourceFilterIndex]) {
                return;
            }
            return this.resourceFilters[this.resourceFilterIndex].filter;
        },
        enumerable: true,
        configurable: true
    });
    CreateResourceComponent.prototype.chooseResource = function (typeItem) {
        if (isResourceTypeItem3rdParty(typeItem) &&
            !this.currentProjectStore.instance.features.isThirdPartyResourcesEnabled()) {
            this.featureService.showFeatureOverview({
                subtitle: 'Paid Feature',
                title: 'Build App with <strong>Business Apps</strong>',
                description: "\n          Connect data from various business apps. You can see all your data and take action in one place.\n        "
            });
            return;
        }
        else if (isResourceTypeItemCustom(typeItem) &&
            !this.currentProjectStore.instance.features.isCustomResourcesEnabled()) {
            this.featureService.showFeatureOverview({
                subtitle: 'Paid Feature',
                title: 'Build App with <strong>Custom Queries</strong>',
                description: "\n          Connect data from custom queries. You can see all your data and take action in one place.\n        "
            });
            return;
        }
        this.chosen.emit(typeItem);
    };
    return CreateResourceComponent;
}());
export { CreateResourceComponent };

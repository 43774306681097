var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { CdkOverlayOrigin } from '@angular/cdk/overlay';
import { ChangeDetectorRef, ElementRef, EventEmitter, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import isEqual from 'lodash/isEqual';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { timer } from 'rxjs';
import { ViewContext, ViewContextElement } from '@modules/customize';
import { createFormFieldFactory, FieldType, FormField } from '@modules/fields';
export var DBLCLICK_MAX_DELAY = 500;
var TableEditableCellComponent = /** @class */ (function () {
    function TableEditableCellComponent(el, cd) {
        this.el = el;
        this.cd = cd;
        this.fieldParams = {};
        this.selectedRow = false;
        this.editingEnabled = true;
        this.disableSelectOnEdit = false;
        this.startEditingClick = new EventEmitter();
        this.selectCell = new EventEmitter();
        this.changeValue = new EventEmitter();
        this.cls = true;
        this.clickable = true;
        this.createField = createFormFieldFactory();
        this.editing = false;
        this.fieldTypes = FieldType;
    }
    Object.defineProperty(TableEditableCellComponent.prototype, "selected", {
        get: function () {
            return this.selectedCell === this.el.nativeElement && !this.editing;
        },
        enumerable: true,
        configurable: true
    });
    TableEditableCellComponent.prototype.ngOnInit = function () {
        this.overlayOrigin = new CdkOverlayOrigin(this.el);
    };
    TableEditableCellComponent.prototype.ngOnDestroy = function () { };
    TableEditableCellComponent.prototype.ngOnChanges = function (changes) {
        if (changes.fieldName || changes.fieldType || changes.fieldParams) {
            this.formField = new FormField();
            this.formField.name = this.fieldName;
            this.formField.field = this.fieldType;
            this.formField.resetEnabled = true;
            var classes = this.fieldParams && this.fieldParams['classes'] ? this.fieldParams['classes'] : [];
            this.formField.params = __assign({}, this.fieldParams, { classes: ['select_fill', 'select_small', 'input_fill', 'input_small'].concat(classes), rows: 6, street_view: false });
        }
    };
    TableEditableCellComponent.prototype.setEditing = function (value) {
        this.editing = value;
        this.cd.markForCheck();
    };
    TableEditableCellComponent.prototype.startEditing = function () {
        var _a;
        this.startEditingClick.emit();
        if (!this.editingEnabled) {
            return;
        }
        this.editingControl = new FormControl(this.value);
        this.editingForm = new FormGroup((_a = {},
            _a[this.formField.name] = this.editingControl,
            _a));
        this.cd.markForCheck();
        this.setEditing(true);
    };
    TableEditableCellComponent.prototype.finishEditing = function (save) {
        if (save === void 0) { save = false; }
        this.setEditing(false);
        if (this.editingControl) {
            var newValue = this.editingControl.value;
            this.editingControl = undefined;
            this.editingForm = undefined;
            this.cd.markForCheck();
            if (save && !isEqual(this.value, newValue)) {
                this.changeValue.emit(newValue);
            }
        }
    };
    TableEditableCellComponent.prototype.onClick = function (e) {
        var _this = this;
        if (this.disableSelectOnEdit && this.selectedRow) {
            if (this.clickSubscription) {
                this.clickSubscription.unsubscribe();
                this.clickSubscription = undefined;
            }
            var subscription_1 = timer(DBLCLICK_MAX_DELAY)
                .pipe(untilDestroyed(this))
                .subscribe(function () {
                if (_this.clickSubscription === subscription_1) {
                    _this.clickSubscription.unsubscribe();
                    _this.clickSubscription = undefined;
                }
                _this.selectCell.emit({ element: _this.el.nativeElement, event: e });
            });
            this.clickSubscription = subscription_1;
        }
        else {
            this.selectCell.emit({ element: this.el.nativeElement, event: e });
        }
    };
    TableEditableCellComponent.prototype.onDblClick = function (e) {
        if (this.clickSubscription) {
            this.clickSubscription.unsubscribe();
            this.clickSubscription = undefined;
        }
        if (!this.fieldType) {
            return;
        }
        var fieldMinWidth = this.getFieldMinWidth(this.fieldType);
        this.minWidth = fieldMinWidth
            ? Math.max(this.el.nativeElement.offsetWidth, fieldMinWidth)
            : this.el.nativeElement.offsetWidth;
        this.minHeight = this.el.nativeElement.offsetHeight;
        e.preventDefault();
        this.startEditing();
    };
    TableEditableCellComponent.prototype.getFieldMinWidth = function (fieldType) {
        if (fieldType == FieldType.JSON) {
            return 400;
        }
        else if (fieldType == FieldType.Location) {
            return 600;
        }
    };
    return TableEditableCellComponent;
}());
export { TableEditableCellComponent };

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

import { createFormFieldFactory } from '@modules/fields';
import { Field } from '@modules/projects';
import { TypedChanges } from '@shared';

@Component({
  selector: 'app-register-template-field',
  templateUrl: './register-template-field.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RegisterTemplateFieldComponent implements OnInit, OnChanges {
  @Input() form: FormGroup;
  @Input() field: Field;

  createField = createFormFieldFactory();
  fieldParams = {};

  constructor(private cd: ChangeDetectorRef) {}

  ngOnInit() {}

  ngOnChanges(changes: TypedChanges<RegisterTemplateFieldComponent>): void {
    if (changes.form || changes.field) {
      if (!this.form) {
        this.form = this.field ? new FormGroup({ [this.field.name]: new FormControl() }) : undefined;
      }
    }

    if (changes.field) {
      this.updateFieldParams();
    }
  }

  updateFieldParams() {
    const params = this.field.params;
    const classes = params && params['classes'] ? params['classes'] : [];

    this.fieldParams = {
      ...params,
      classes: ['input_fill', 'select_fill', ...classes]
    };
    this.cd.markForCheck();
  }
}

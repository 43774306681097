import { Injectable } from '@angular/core';
import defaults from 'lodash/defaults';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { FieldType } from '@modules/fields';
import { ModelOption } from '@modules/filters-components';
import { ModelDescriptionStore, ModelService } from '@modules/model-queries';
import { ModelDescription, RelationDirection } from '@modules/models';
import { CurrentEnvironmentStore } from '@modules/projects';
import { isSet } from '@shared';

@Injectable()
export class ModelOptionsSource {
  constructor(
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private modelDescriptionStore: ModelDescriptionStore,
    private modelService: ModelService
  ) {}

  getOptions$(
    modelDescription: ModelDescription,
    options: {
      path?: ModelOption[];
      onlyNestedFields?: boolean;
      relationsEnabled?: boolean;
      maxRelationsDepth?: number;
    } = {}
  ): Observable<ModelOption[]> {
    options = defaults(options, {
      path: [],
      maxRelationsDepth: 4
    });

    return this.modelDescriptionStore.get().pipe(
      map(modelDescriptions => {
        const result: ModelOption[] = [];
        const depth = options.path.length + 1;
        const resource = this.currentEnvironmentStore.resources.find(
          item => item.uniqueName == modelDescription.resource
        );

        result.push(
          ...modelDescription.dbFields
            .filter(field => {
              if (!options.onlyNestedFields) {
                return true;
              }

              if (depth == 1) {
                return field.field == FieldType.RelatedModel;
              } else {
                return true;
              }
            })
            .map(field => {
              let relatedModelDescription: ModelDescription;

              if (
                field.field == FieldType.RelatedModel &&
                options.relationsEnabled &&
                depth < options.maxRelationsDepth
              ) {
                const relatedModelId = field.params ? field.params['related_model']['model'] : undefined;
                const relatedModel = relatedModelId
                  ? modelDescriptions.find(item => item.isSame(relatedModelId))
                  : undefined;
                const relatedResource = relatedModel
                  ? this.currentEnvironmentStore.resources.find(item => item.uniqueName == relatedModel.resource)
                  : undefined;

                if (
                  this.modelService.isGetAdvSupported(resource, modelDescription) &&
                  resource.isSameDatabase(modelDescription, relatedResource, relatedModel)
                ) {
                  relatedModelDescription = relatedModel;
                }
              }

              return {
                name: field.name,
                verboseName: field.verboseName || field.name,
                icon: field.fieldDescription.icon,
                field: field,
                modelDescription: modelDescription,
                relatedModelDescription: relatedModelDescription
              };
            })
        );

        if (options.relationsEnabled) {
          result.push(
            ...modelDescription
              .allRelations()
              .filter(relation => relation.direction == RelationDirection.OneToMany)
              .filter(() => !isSet(options.maxRelationsDepth) || depth < options.maxRelationsDepth)
              .map(relation => {
                const relatedModel = modelDescriptions.find(item => item.isSame(relation.relatedModel));
                const relatedResource = relatedModel
                  ? this.currentEnvironmentStore.resources.find(item => item.uniqueName == relatedModel.resource)
                  : undefined;

                if (
                  !relatedResource ||
                  !relatedModel ||
                  !this.modelService.isGetAdvSupported(resource, modelDescription) ||
                  !resource.isSameDatabase(modelDescription, relatedResource, relatedModel)
                ) {
                  return;
                }

                const localField = modelDescription.dbField(relation.localField);
                const relatedField = relatedModel.dbField(relation.relatedField);

                if (!localField || !relatedField) {
                  return;
                }

                const isPrimaryKeyRelation = modelDescription.primaryKeyField == relation.localField;

                return {
                  name: relation.name,
                  verboseName: relatedModel.verboseNamePlural,
                  ...(isPrimaryKeyRelation
                    ? { additional: relatedField.verboseName || relatedField.name }
                    : {
                        description: [
                          relatedField.verboseName || relatedField.name,
                          localField.verboseName || localField.name
                        ].join(' ↔ ')
                      }),
                  icon: 'lookup',
                  relation: relation,
                  modelDescription: modelDescription,
                  relatedModelDescription: relatedModel
                };
              })
              .filter(item => item)
          );
        }

        return result;
      })
    );
  }
}

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit } from '@angular/core';
import { createFormFieldFactory, FieldType, registerFieldViewParamsComponent, TimeFormat } from '@modules/fields';
import { FieldParamsComponent } from '../../field-params/field-params.component';
import { TimeFieldViewParamsForm } from './time-field-view-params.form';
var TimeViewFieldParamsComponent = /** @class */ (function (_super) {
    __extends(TimeViewFieldParamsComponent, _super);
    function TimeViewFieldParamsComponent(form) {
        var _this = _super.call(this) || this;
        _this.form = form;
        _this.createField = createFormFieldFactory();
        _this.timeFormats = TimeFormat;
        return _this;
    }
    TimeViewFieldParamsComponent.prototype.ngOnInit = function () {
        this.form.init(this.control);
    };
    return TimeViewFieldParamsComponent;
}(FieldParamsComponent));
export { TimeViewFieldParamsComponent };
registerFieldViewParamsComponent(FieldType.Time, TimeViewFieldParamsComponent);

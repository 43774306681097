import { ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output } from '@angular/core';

import { PopupRef } from '@common/popups';
import { ScanResult } from '@modules/image-codes';

@Component({
  selector: 'app-scanner-popup',
  templateUrl: './scanner-popup.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ScannerPopupComponent implements OnInit {
  @Output() scan = new EventEmitter<ScanResult>();
  @Output() cancelled = new EventEmitter<void>();

  constructor(private popupRef: PopupRef) {}

  ngOnInit() {}

  onScan(scan: ScanResult) {
    this.scan.emit(scan);
    this.close();
  }

  cancel() {
    this.cancelled.emit();
    this.close();
  }

  close() {
    this.popupRef.close();
  }
}

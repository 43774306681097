<div class="choose-template__detail-browser">
  <div class="browser-window">
    <div class="browser-window__header">
      <app-browser-header
        [address]="'Blank'"
        [addressIconHasDefault]="false"
        [addressSearchIcon]="false"
      ></app-browser-header>
    </div>

    <div class="browser-window__content">
      <div class="browser-window__content-inner">
        <div class="browser-window__center">
          <app-container-builder-stub></app-container-builder-stub>
        </div>
      </div>
    </div>
  </div>
</div>

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FormArray } from '@angular/forms';
import isEqual from 'lodash/isEqual';
import range from 'lodash/range';
import { OptionEnabledControl } from './option-enabled.control';
var OptionEnabledArray = /** @class */ (function (_super) {
    __extends(OptionEnabledArray, _super);
    function OptionEnabledArray() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.defaultDisabled = [];
        return _this;
    }
    OptionEnabledArray.prototype.patchValue = function (value, options) {
        var _this = this;
        value.forEach(function (item, i) {
            var control = _this.controls[i];
            if (control) {
                control.setValue(item);
            }
            else {
                _this.appendControl(item);
            }
        });
        this.controls.slice(value.length).forEach(function (item) { return _this.removeControl(item); });
    };
    OptionEnabledArray.prototype.setValue = function (value, options) {
        _super.prototype.setValue.call(this, value, options);
    };
    OptionEnabledArray.prototype.setControls = function (controls) {
        var _this = this;
        this.removeControls();
        controls.forEach(function (item) { return _this.push(item); });
    };
    OptionEnabledArray.prototype.removeControls = function () {
        var _this = this;
        range(this.controls.length).forEach(function () { return _this.removeAt(0); });
    };
    OptionEnabledArray.prototype.removeControl = function (control) {
        var newControls = this.controls.filter(function (item) { return !isEqual(item, control); });
        this.setControls(newControls);
    };
    OptionEnabledArray.prototype.createControl = function (item) {
        var control = new OptionEnabledControl(item);
        control.markAsPristine();
        return control;
    };
    OptionEnabledArray.prototype.appendControl = function (item) {
        var control = this.createControl(item);
        this.push(control);
        return control;
    };
    OptionEnabledArray.prototype.syncControls = function (names, options) {
        var _this = this;
        if (options === void 0) { options = {}; }
        names
            .filter(function (name) { return !_this.controls.find(function (control) { return control.controls.name.value == name; }); })
            .forEach(function (name) { return _this.appendControl({ name: name }); });
        this.controls
            .filter(function (control) { return !names.find(function (name) { return name == control.controls.name.value; }); })
            .forEach(function (control) { return _this.removeControl(control); });
        this.source = options.source;
    };
    OptionEnabledArray.prototype.enableAll = function () {
        this.controls.forEach(function (item) { return item.controls.enabled.patchValue(true); });
    };
    OptionEnabledArray.prototype.disableAll = function () {
        this.controls.forEach(function (item) { return item.controls.enabled.patchValue(false); });
    };
    OptionEnabledArray.prototype.enableDefault = function () {
        var _this = this;
        this.controls.forEach(function (item) {
            var defaultDisabled = _this.defaultDisabled.includes(item.controls.name.value);
            item.controls.enabled.patchValue(!defaultDisabled);
        });
    };
    OptionEnabledArray.prototype.isAllEnabled = function () {
        return this.controls.every(function (item) { return item.controls.enabled.value; });
    };
    OptionEnabledArray.prototype.isAllDisabled = function () {
        return this.controls.every(function (item) { return !item.controls.enabled.value; });
    };
    OptionEnabledArray.prototype.isSource = function (source) {
        return this.source == source;
    };
    OptionEnabledArray.prototype.isSourceSet = function () {
        return this.source !== undefined;
    };
    return OptionEnabledArray;
}(FormArray));
export { OptionEnabledArray };

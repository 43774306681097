import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';

import { isSet } from '@shared';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaginationComponent implements OnInit, OnChanges {
  @Input() current: number;
  @Input() hasMore = false;
  @Input() lastLoaded: number;
  @Input() total: number;
  @Output() pageSelected = new EventEmitter<number>();

  bordersSiblings = 1;
  currentSiblings = 2;
  items: { type: string; page: number }[] = [];

  constructor(private cd: ChangeDetectorRef) {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges): void {
    this.updatePages();
  }

  updatePages() {
    const items = [];
    const lastVisible = this.total || this.lastLoaded || this.current;
    let lastPage = 0;

    const addPage = page => {
      if (page < 1) {
        return;
      }

      if (page > lastVisible) {
        return;
      }

      if (page <= lastPage) {
        return;
      }

      items.push({ type: 'page', page: page });
      lastPage = page;
    };

    for (let i = 1; i <= 1 + this.bordersSiblings; ++i) {
      addPage(i);
    }

    if (lastPage < this.current - this.currentSiblings - 1) {
      items.push({ type: 'separator' });
    }

    for (let i = this.current - this.currentSiblings; i <= this.current + this.currentSiblings; ++i) {
      addPage(i);
    }

    if (lastPage < lastVisible - this.currentSiblings) {
      items.push({ type: 'separator' });
    }

    for (let i = lastVisible - this.bordersSiblings; i <= lastVisible; ++i) {
      addPage(i);
    }

    this.items = items;
    this.cd.markForCheck();
  }

  get prevPage() {
    if (this.current <= 1) {
      return;
    }

    return this.current - 1;
  }

  get nextPage() {
    if (!this.hasMore) {
      return;
    }

    return this.current + 1;
  }

  trackByFn(_, item) {
    return item.page;
  }

  openPage(page: number) {
    if (!isSet(page)) {
      return;
    }

    this.pageSelected.emit(page);
    // const params = clone(this.params);
    //
    // params[PAGE_PARAM] = page;
    // delete params[NEXT_PAGE_SCROLL_PARAM];
    //
    // this.paramsChanged.emit(params);
  }
}

import {
  AbstractControlOptions,
  AsyncValidatorFn,
  FormArray,
  FormControl,
  ValidatorFn,
  Validators
} from '@angular/forms';

import { isSet } from '@shared';

export class StorageBucketArray extends FormArray {
  controls: FormControl[];

  constructor(
    value: string[] = [],
    validatorOrOpts?: ValidatorFn | ValidatorFn[] | AbstractControlOptions | null,
    asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null
  ) {
    super([], validatorOrOpts, asyncValidator);
    this.deserialize(value);
    this.markAsPristine();
  }

  deserialize(value: string[]): this {
    value.forEach((item, i) => {
      const control = this.controls[i];

      if (control) {
        control.patchValue(item);
      } else {
        this.appendControl(item);
      }
    });

    this.controls.slice(value.length).forEach(item => this.removeControl(item));

    return this;
  }

  createControl(value?: string) {
    return new FormControl(isSet(value) ? value : '', Validators.required);
  }

  appendControl(value?: string): FormControl {
    const control = this.createControl(value);
    this.push(control);
    return control;
  }

  removeControl(control: FormControl) {
    const index = this.controls.findIndex(item => item === control);

    if (index == -1) {
      return;
    }

    this.removeAt(index);
    this.updateValueAndValidity();
  }
}

export function validateStorageBucketArray(): ValidatorFn {
  return control => {
    if (!control.value || !control.value.length) {
      return { required: true };
    }
  };
}

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./zig-zag-connection.component";
var styles_ZigZagConnectionComponent = [];
var RenderType_ZigZagConnectionComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ZigZagConnectionComponent, data: {} });
export { RenderType_ZigZagConnectionComponent as RenderType_ZigZagConnectionComponent };
export function View_ZigZagConnectionComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 1, ":svg:svg", [["xmlns", "http://www.w3.org/2000/svg"]], [[1, "width", 0], [1, "height", 0], [1, "viewBox", 0]], null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, ":svg:path", [["fill", "none"], ["stroke-width", "2"]], [[1, "stroke", 0], [1, "d", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.width; var currVal_1 = _co.height; var currVal_2 = _co.viewBox; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = _co.color; var currVal_4 = _co.pathD; _ck(_v, 1, 0, currVal_3, currVal_4); }); }
export function View_ZigZagConnectionComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-zig-zag-connection", [], null, null, null, View_ZigZagConnectionComponent_0, RenderType_ZigZagConnectionComponent)), i0.ɵdid(1, 638976, null, 0, i1.ZigZagConnectionComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ZigZagConnectionComponentNgFactory = i0.ɵccf("app-zig-zag-connection", i1.ZigZagConnectionComponent, View_ZigZagConnectionComponent_Host_0, { left: "left", width: "width", height: "height", horizontalY: "horizontalY", color: "color" }, {}, []);
export { ZigZagConnectionComponentNgFactory as ZigZagConnectionComponentNgFactory };

import { Injectable } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup } from '@angular/forms';
import range from 'lodash/range';
import { debounceTime } from 'rxjs/operators';
import { slugify } from 'transliteration';

import { ParameterField } from '@modules/fields';

@Injectable()
export class ParameterFieldsEditForm {
  form = new FormArray([]);

  init(control: AbstractControl) {
    const params = (control.value || []) as ParameterField[];

    this.arraySet(params.map(() => this.createParam()));
    this.form.patchValue(
      params.map(item => {
        return {
          name: item.name,
          verbose_name: item.verboseName,
          field: item.field,
          required: item.required,
          params: item.params
        };
      }),
      { emitEvent: false }
    );

    this.form.valueChanges.pipe(debounceTime(60)).subscribe(value => {
      control.patchValue(
        value.map(item => {
          const result = new ParameterField();
          result.name = item['name'];
          result.verboseName = item['verbose_name'];
          result.field = item['field'];
          result.required = item['required'];
          result.params = item['params'];
          result.updateFieldDescription();
          return result;
        })
      );
    });
  }

  createParam(): FormGroup {
    const form = new FormGroup({
      name: new FormControl(''),
      verbose_name: new FormControl(''),
      field: new FormControl('CharField'),
      required: new FormControl(false),
      params: new FormControl({})
    });

    form.controls['verbose_name'].valueChanges.subscribe(value => {
      const name = slugify(value, { trim: true, separator: '_' }).replace(/_+/g, '_');
      form.patchValue({ name: name });
    });

    return form;
  }

  arraySet(groups) {
    range(this.form.controls.length).forEach(() => this.form.removeAt(0));
    groups.forEach(item => this.form.push(item));
  }

  arrayAppend(group) {
    this.form.push(group);
  }

  arrayDelete(group) {
    const index = this.form.controls.findIndex(item => item === group);

    if (index == -1) {
      return;
    }

    this.form.removeAt(index);
  }
}

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import 'brace/mode/html';
import 'brace/mode/javascript';
import 'brace/snippets/text';
import 'brace/theme/github';
import { AceEditorDirective } from 'ng2-ace-editor';

import { FieldType, registerFieldComponent } from '@modules/fields';
import { ModelDescription } from '@modules/models';
import { ThemeService } from '@modules/theme';

import { FieldComponent } from '../field/field.component';

@Component({
  selector: 'app-code-field',
  templateUrl: 'code-field.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CodeFieldComponent extends FieldComponent implements OnDestroy, OnChanges, AfterViewInit {
  @ViewChild(AceEditorDirective) ace: AceEditorDirective;

  constructor(private themeService: ThemeService) {
    super();
  }

  ngOnDestroy(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    const modelDescriptionRoot = this.field.params['modelDescription'] as ModelDescription;

    if (!modelDescriptionRoot) {
      return;
    }
  }

  ngAfterViewInit(): void {
    this.ngOnChanges({});

    if (this.field.params['wrap']) {
      this.ace.editor.session.setUseWrapMode(true);
    }
  }

  get theme() {
    if (this.themeService.theme == 'dark' || this.field.params['dark']) {
      return 'dracula';
    } else {
      return this.field.params['field_contrast'] ? 'xcode-contrast' : 'xcode';
    }
  }

  editorInsert(text) {
    this.ace.editor.session.replace(this.ace.editor.selection.getRange(), text);
  }

  focus() {
    if (!this.ace) {
      return;
    }

    this.ace.editor.focus();
  }
}

registerFieldComponent(FieldType.Code, CodeFieldComponent);

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Injector, NgZone } from '@angular/core';
import { FormArray } from '@angular/forms';
import isEqual from 'lodash/isEqual';
import range from 'lodash/range';
import { NotificationService } from '@common/notifications';
import { ActionService } from '@modules/action-queries';
import { ModelData } from '@modules/customize';
import { ModelDescriptionStore, ModelService } from '@modules/model-queries';
import { ProjectSettingsService } from '@modules/project-settings';
import { CurrentEnvironmentStore } from '@modules/projects';
import { QueryService } from '@modules/queries';
import { ResourceGeneratorResolver } from '@modules/resource-generators';
import { ResourceControllerService } from '@modules/resources';
import { ListModelDescriptionDataSourceControl } from '../model-description-data-source-edit/list-model-description-data-source';
import { ModelDescriptionDataSourceControl } from '../model-description-data-source-edit/model-description-data-source';
import { CustomizeBarModelDataControl } from './customize-bar-model-data.control';
var CustomizeBarModelDataArray = /** @class */ (function (_super) {
    __extends(CustomizeBarModelDataArray, _super);
    function CustomizeBarModelDataArray(currentEnvironmentStore, modelDescriptionStore, resourceControllerService, injector) {
        var _this = _super.call(this, []) || this;
        _this.currentEnvironmentStore = currentEnvironmentStore;
        _this.modelDescriptionStore = modelDescriptionStore;
        _this.resourceControllerService = resourceControllerService;
        _this.injector = injector;
        return _this;
    }
    CustomizeBarModelDataArray.prototype.deserialize = function (value) {
        var _this = this;
        var controls = (value || [])
            .map(function (item) {
            var control = _this.createControl();
            if (!control) {
                return;
            }
            control.deserialize(item);
            return control;
        })
            .filter(function (item) { return item != undefined; });
        this.setControls(controls);
    };
    CustomizeBarModelDataArray.prototype.setControls = function (controls) {
        var _this = this;
        this.removeControls();
        controls.forEach(function (item) { return _this.push(item); });
    };
    CustomizeBarModelDataArray.prototype.removeControls = function () {
        var _this = this;
        range(this.controls.length).forEach(function () { return _this.removeAt(0); });
    };
    CustomizeBarModelDataArray.prototype.removeControl = function (control) {
        var newControls = this.controls.filter(function (item) { return !isEqual(item, control); });
        this.setControls(newControls);
    };
    CustomizeBarModelDataArray.prototype.prependControl = function (options) {
        if (options === void 0) { options = {}; }
        var control = this.createControl();
        if (!control) {
            return;
        }
        control.deserialize(options.instance);
        if (options.value) {
            control.patchValue(options.value);
            this.markAsPristine();
        }
        this.insert(0, control);
        return control;
    };
    CustomizeBarModelDataArray.prototype.appendControl = function (options) {
        if (options === void 0) { options = {}; }
        var control = this.createControl();
        if (!control) {
            return;
        }
        control.deserialize(options.instance);
        if (options.value) {
            control.patchValue(options.value);
            this.markAsPristine();
        }
        this.push(control);
        return control;
    };
    CustomizeBarModelDataArray.prototype.createControl = function () {
        return Injector.create({
            providers: [
                {
                    provide: ModelDescriptionDataSourceControl,
                    deps: [
                        ModelDescriptionStore,
                        CurrentEnvironmentStore,
                        ProjectSettingsService,
                        ResourceControllerService,
                        ResourceGeneratorResolver,
                        ModelService,
                        ActionService,
                        QueryService,
                        NotificationService,
                        NgZone
                    ]
                },
                {
                    provide: ListModelDescriptionDataSourceControl,
                    deps: [
                        ModelDescriptionStore,
                        CurrentEnvironmentStore,
                        ProjectSettingsService,
                        ResourceControllerService,
                        ResourceGeneratorResolver,
                        ModelService,
                        ActionService,
                        QueryService,
                        NotificationService,
                        NgZone
                    ]
                },
                {
                    provide: CustomizeBarModelDataControl,
                    deps: [
                        CurrentEnvironmentStore,
                        ModelDescriptionStore,
                        ResourceControllerService,
                        ModelDescriptionDataSourceControl,
                        ListModelDescriptionDataSourceControl
                    ]
                }
            ],
            parent: this.injector
        }).get(CustomizeBarModelDataControl);
    };
    CustomizeBarModelDataArray.prototype.serialize = function () {
        return this.controls.map(function (control) { return control.serialize(); });
    };
    return CustomizeBarModelDataArray;
}(FormArray));
export { CustomizeBarModelDataArray };

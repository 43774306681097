<div class="live-widget-menu" [class.live-widget-menu_active]="menuOpened" #menu_element>
  <div class="live-widget-menu__inner">
    <div class="live-widget-menu__section">
      <!--      <div class="live-widget-menu__section-title">Help and Support</div>-->
      <div class="live-widget-menu__section-content">
        <!--        <a-->
        <!--          href="javascript:void(0)"-->
        <!--          class="live-widget-menu__item"-->
        <!--          id="builder-help-checklist"-->
        <!--          (click)="setMenuOpened(false); onItemClicked('checklist')"-->
        <!--        >-->
        <!--          <span class="live-widget-menu__item-icon icon-rocket"></span>-->
        <!--          <span class="live-widget-menu__item-label">Get Started Checklist</span>-->
        <!--        </a>-->
        <a
          href="https://docs.jetadmin.io/"
          class="live-widget-menu__item"
          target="_blank"
          (click)="setMenuOpened(false); onItemClicked('documentation')"
        >
          <span class="live-widget-menu__item-icon icon-documents"></span>
          <span class="live-widget-menu__item-label">Read Documentation</span>
        </a>
        <a
          href="https://docs.jetadmin.io/videos"
          class="live-widget-menu__item"
          target="_blank"
          (click)="setMenuOpened(false); onItemClicked('video_tutorials')"
        >
          <span class="live-widget-menu__item-icon icon-play"></span>
          <span class="live-widget-menu__item-label">Watch Video Tutorials</span>
        </a>
      </div>
    </div>
    <div class="live-widget-menu__section">
      <!--      <div class="live-widget-menu__section-title">Product</div>-->
      <div class="live-widget-menu__section-content">
        <a
          href="https://feedback.jetadmin.io/"
          class="live-widget-menu__item"
          target="_blank"
          (click)="setMenuOpened(false); onItemClicked('request_feature')"
        >
          <span class="live-widget-menu__item-icon icon-idea"></span>
          <span class="live-widget-menu__item-label">Request a Feature</span>
        </a>
        <a
          href="https://feedback.jetadmin.io/changelog/"
          class="live-widget-menu__item"
          target="_blank"
          (click)="setMenuOpened(false); onItemClicked('whats_new')"
        >
          <span class="live-widget-menu__item-icon icon-news"></span>
          <span class="live-widget-menu__item-label">See What’s New</span>
        </a>
        <a
          href="https://www.jetadmin.io/changelog"
          class="live-widget-menu__item"
          target="_blank"
          (click)="setMenuOpened(false); onItemClicked('whats_new')"
        >
          <span class="live-widget-menu__item-icon icon-align_left"></span>
          <span class="live-widget-menu__item-label">View Changelog</span>
        </a>
      </div>
    </div>
    <div class="live-widget-menu__section">
      <div class="live-widget-menu__section-content">
        <a
          href="javascript:void(0)"
          class="live-widget-menu__item"
          [class.live-widget-menu__item_orange]="
            slackChannelStore.isEnabled() && !slackChannelLoading && !slackChannel?.isJoined()
          "
          (click)="setMenuOpened(false); joinSlack(); onItemClicked('join_slack')"
        >
          <span class="live-widget-menu__item-icon icon-dash"></span>
          <span class="live-widget-menu__item-label">
            <ng-container *ngIf="slackChannelLoading || slackChannel?.isJoined()">Slack channel</ng-container>
            <ng-container *ngIf="!slackChannelLoading && !slackChannel?.isJoined()">Join Slack channel</ng-container>
          </span>
        </a>
        <a
          href="https://www.jetadmin.io/experts"
          class="live-widget-menu__item"
          target="_blank"
          (click)="setMenuOpened(false); onItemClicked('experts')"
        >
          <span class="live-widget-menu__item-icon icon-human_being"></span>
          <span class="live-widget-menu__item-label">Hire an Expert</span>
        </a>
        <a
          href="https://community.jetadmin.io/"
          class="live-widget-menu__item"
          target="_blank"
          (click)="setMenuOpened(false); onItemClicked('ask_community')"
        >
          <span class="live-widget-menu__item-icon icon-teams"></span>
          <span class="live-widget-menu__item-label">Ask Community</span>
        </a>
        <a
          href="javascript:void(0)"
          class="live-widget-menu__item"
          (click)="setMenuOpened(false); openChat(); onItemClicked('chat')"
        >
          <span class="live-widget-menu__item-icon icon-chat"></span>
          <span class="live-widget-menu__item-label">Talk to Support</span>
        </a>
      </div>
    </div>
  </div>
</div>

<div
  class="live-widget-badge icon-help_filled"
  [class.live-widget-badge_active]="menuOpened"
  (click)="toggleMenuOpened()"
>
  <div
    *ngIf="slackChannelStore.isEnabled()"
    class="live-widget-badge__indicator live-widget-badge__indicator_slack live-widget-badge__indicator_hidden"
    [class.live-widget-badge__indicator_hidden]="slackChannelLoading || slackChannel?.isJoined()"
  ></div>
</div>

<div class="section__inner">
  <div class="section__toolbar">
    <div class="section__toolbar-inner">
      <ng-content select="app-section-toolbar"></ng-content>
    </div>
  </div>

  <ng-content></ng-content>

  <div class="section__columns">
    <ng-content select="app-section-column"></ng-content>
  </div>

  <div class="section__footer">
    <ng-content select="app-section-footer"></ng-content>
  </div>
</div>

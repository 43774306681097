/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../shared/pipes/is-set/is-set.pipe";
import * as i2 from "../view-context-token-popover-overlay/view-context-token-popover-overlay.component.ngfactory";
import * as i3 from "../view-context-token-popover-overlay/view-context-token-popover-overlay.component";
import * as i4 from "@angular/cdk/a11y";
import * as i5 from "@angular/common";
import * as i6 from "@angular/cdk/overlay";
import * as i7 from "./input-edit-formula-value.component";
var styles_InputEditFormulaValueComponent = [];
var RenderType_InputEditFormulaValueComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_InputEditFormulaValueComponent, data: {} });
export { RenderType_InputEditFormulaValueComponent as RenderType_InputEditFormulaValueComponent };
function View_InputEditFormulaValueComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "input-error"]], [[2, "input-error_overlay", null], [2, "input-error_rounded-bottom", null]], null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "div", [["class", "input-error__inner"]], [[8, "title", 0]], null, null, null, null)), (_l()(), i0.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.inline; var currVal_1 = !_co.inline; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = _co.formulaEffectiveError; _ck(_v, 1, 0, currVal_2); var currVal_3 = _co.formulaEffectiveError; _ck(_v, 2, 0, currVal_3); }); }
export function View_InputEditFormulaValueComponent_0(_l) { return i0.ɵvid(2, [i0.ɵpid(0, i1.IsSetPipe, []), i0.ɵqud(402653184, 1, { inputElement: 0 }), (_l()(), i0.ɵeld(2, 0, null, null, 4, "app-view-context-token-popover-overlay", [], null, [[null, "selected"], [null, "opened"], [null, "closed"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selected" === en)) {
        var pd_0 = (_co.onTokenSelected($event) !== false);
        ad = (pd_0 && ad);
    } if (("opened" === en)) {
        _co.popoverOpened$.next(true);
        var pd_1 = (_co.popoverOpened.emit() !== false);
        ad = (pd_1 && ad);
    } if (("closed" === en)) {
        _co.popoverOpened$.next(false);
        var pd_2 = (_co.checkIfContextValue() !== false);
        ad = (pd_2 && ad);
    } return ad; }, i2.View_ViewContextTokenPopoverOverlayComponent_0, i2.RenderType_ViewContextTokenPopoverOverlayComponent)), i0.ɵdid(3, 770048, null, 0, i3.ViewContextTokenPopoverOverlayComponent, [i4.FocusMonitor, i0.ChangeDetectorRef], { context: [0, "context"], contextElement: [1, "contextElement"], contextElementPath: [2, "contextElementPath"], contextElementPaths: [3, "contextElementPaths"], origin: [4, "origin"], control: [5, "control"], openOnFocus: [6, "openOnFocus"], extraSections: [7, "extraSections"], search: [8, "search"], selectFunction: [9, "selectFunction"] }, { selected: "selected", opened: "opened", closed: "closed" }), i0.ɵpid(131072, i5.AsyncPipe, [i0.ChangeDetectorRef]), i0.ɵpid(131072, i5.AsyncPipe, [i0.ChangeDetectorRef]), i0.ɵpid(131072, i5.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(7, 0, [[1, 0], ["input_element", 1]], null, 2, "div", [["autocapitalize", "off"], ["cdkOverlayOrigin", ""], ["class", "input input_textarea input_formula input_fill"], ["contenteditable", "true"], ["role", "textbox"], ["spellcheck", "false"]], [[2, "input_fill", null], [2, "input_fill-vertical", null], [2, "input_inline", null], [2, "input_small", null], [2, "input_segment-top", null], [1, "autocorrect", 0], [1, "placeholder", 0]], [[null, "keydown"], [null, "focus"], [null, "blur"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("keydown" === en)) {
        var pd_0 = (_co.onKeydown($event) !== false);
        ad = (pd_0 && ad);
    } if (("focus" === en)) {
        _co.focus$.next(true);
        var pd_1 = (_co.onFocus() !== false);
        ad = (pd_1 && ad);
    } if (("blur" === en)) {
        var pd_2 = (_co.focus$.next(false) !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i0.ɵdid(8, 16384, [["textarea_origin", 4]], 0, i6.CdkOverlayOrigin, [i0.ElementRef], null, null), i0.ɵppd(9, 1), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_InputEditFormulaValueComponent_1)), i0.ɵdid(11, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵppd(12, 1)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.context; var currVal_1 = _co.contextElement; var currVal_2 = _co.contextElementPath; var currVal_3 = _co.contextElementPaths; var currVal_4 = (_co.popoverOrigin || i0.ɵnov(_v, 8)); var currVal_5 = _co.control; var currVal_6 = i0.ɵnov(_v, 7); var currVal_7 = _co.extraSections; var currVal_8 = i0.ɵunv(_v, 3, 8, i0.ɵnov(_v, 4).transform(_co.search$)); var currVal_9 = (i0.ɵunv(_v, 3, 9, i0.ɵnov(_v, 5).transform(_co.hoverFunction$)) || i0.ɵunv(_v, 3, 9, i0.ɵnov(_v, 6).transform(_co.activeFunction$))); _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9); var currVal_17 = i0.ɵunv(_v, 11, 0, _ck(_v, 12, 0, i0.ɵnov(_v, 0), _co.formulaEffectiveError)); _ck(_v, 11, 0, currVal_17); }, function (_ck, _v) { var _co = _v.component; var currVal_10 = _co.fill; var currVal_11 = _co.fillVertical; var currVal_12 = _co.inline; var currVal_13 = _co.small; var currVal_14 = i0.ɵunv(_v, 7, 4, _ck(_v, 9, 0, i0.ɵnov(_v, 0), _co.formulaEffectiveError)); var currVal_15 = "off"; var currVal_16 = _co.placeholder; _ck(_v, 7, 0, currVal_10, currVal_11, currVal_12, currVal_13, currVal_14, currVal_15, currVal_16); }); }
export function View_InputEditFormulaValueComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-input-edit-formula-value", [], null, null, null, View_InputEditFormulaValueComponent_0, RenderType_InputEditFormulaValueComponent)), i0.ɵdid(1, 4440064, null, 0, i7.InputEditFormulaValueComponent, [i0.NgZone, i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var InputEditFormulaValueComponentNgFactory = i0.ɵccf("app-input-edit-formula-value", i7.InputEditFormulaValueComponent, View_InputEditFormulaValueComponent_Host_0, { control: "control", context: "context", contextElement: "contextElement", contextElementPath: "contextElementPath", contextElementPaths: "contextElementPaths", contextValueEnabled: "contextValueEnabled", focusedInitial: "focusedInitial", placeholder: "placeholder", extraSections: "extraSections", fill: "fill", fillVertical: "fillVertical", inline: "inline", small: "small", popoverOrigin: "popoverOrigin" }, { popoverOpened: "popoverOpened", switchToContext: "switchToContext", switchToTextInputs: "switchToTextInputs", switchToJs: "switchToJs", switchToEmptyString: "switchToEmptyString", switchToNull: "switchToNull", switchToPrompt: "switchToPrompt", switchToFieldFilter: "switchToFieldFilter" }, []);
export { InputEditFormulaValueComponentNgFactory as InputEditFormulaValueComponentNgFactory };

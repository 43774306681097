var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Task } from '../task';
import { TaskScenario } from '../task-scenario';
import * as i0 from "@angular/core";
var AddNewProjectTask = /** @class */ (function (_super) {
    __extends(AddNewProjectTask, _super);
    function AddNewProjectTask() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    AddNewProjectTask.prototype.init = function () {
        return new TaskScenario([
            {
                link: ['/projects', 'create'],
                items: []
            }
        ]);
    };
    AddNewProjectTask.ngInjectableDef = i0.defineInjectable({ factory: function AddNewProjectTask_Factory() { return new AddNewProjectTask(); }, token: AddNewProjectTask, providedIn: "root" });
    return AddNewProjectTask;
}(Task));
export { AddNewProjectTask };

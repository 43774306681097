import { FormArray } from '@angular/forms';
import isEqual from 'lodash/isEqual';
import range from 'lodash/range';

import { LayerInteraction, LayerInteractionType } from '@modules/views';

import { LayerInteractionControl } from './layer-interaction.control';

export class LayerInteractionArray extends FormArray {
  controls: LayerInteractionControl[];

  deserialize(value: LayerInteraction[]) {
    value.forEach((item, i) => {
      const control = this.controls[i] || this.appendControl(item.type);
      control.deserialize(item);
    });

    this.controls.slice(value.length).forEach(item => this.removeControl(item));
  }

  serialize(): LayerInteraction[] {
    return this.controls.map(control => control.serialize());
  }

  setControls(controls: LayerInteractionControl[]) {
    this.removeControls();
    controls.forEach(item => this.push(item));
  }

  removeControls() {
    range(this.controls.length).forEach(() => this.removeAt(0));
  }

  removeControl(control: LayerInteractionControl) {
    const newControls = this.controls.filter(item => !isEqual(item, control));
    this.setControls(newControls);
  }

  createControl(type: LayerInteractionType, item?: LayerInteraction): LayerInteractionControl {
    const control = new LayerInteractionControl(type);

    if (item) {
      control.deserialize(item);
    }

    control.markAsPristine();

    return control;
  }

  appendControl(type: LayerInteractionType, item?: LayerInteraction): LayerInteractionControl {
    const control = this.createControl(type, item);
    this.push(control);
    return control;
  }

  prependControl(type: LayerInteractionType, item?: LayerInteraction): LayerInteractionControl {
    const control = this.createControl(type, item);
    this.insert(0, control);
    return control;
  }
}

import { Pipe, PipeTransform } from '@angular/core';

import { stripTags } from '../../utils/text/text';

@Pipe({
  name: 'appStripTags'
})
export class StripTagsPipe implements PipeTransform {
  transform(value: string): any {
    return stripTags(value);
  }
}

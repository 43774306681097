import cloneDeep from 'lodash/cloneDeep';
import pickBy from 'lodash/pickBy';

import { ActionItem } from '@modules/actions';
import { Input, ParameterField } from '@modules/fields';
import { generateAlphanumeric } from '@shared';

import { getElementByType } from './elements/element-items';
import { ElementItem } from './elements/items/base';

export enum PopupStyle {
  Modal = 'modal',
  ModalBackground = 'modal_background'
}

export enum PopupPosition {
  Center = 'center',
  SideRight = 'side_right',
  Origin = 'origin'
}

export class PopupSettings {
  public uid: string;
  public name: string;
  public title: Input;
  public elements: ElementItem[] = [];
  public width: number;
  public style: PopupStyle = PopupStyle.Modal;
  public position: PopupPosition = PopupPosition.Center;
  public overlay = true;
  public closeButton = true;
  public closeOnBlur = true;
  public parameters: ParameterField[] = [];
  public closeActions: ActionItem[] = [];
  public openActions: ActionItem[] = [];

  deserialize(data: Object) {
    this.uid = data['uid'];
    this.name = data['name'];
    this.width = data['width'];

    if (data['title']) {
      this.title = new Input().deserialize(data['title']);
    } else {
      this.title = undefined;
    }

    const mapElement = item => {
      const element = getElementByType(item['type']);
      if (!element) {
        console.error(`Unsupported element type: ${item['type']}`);
        return;
      }
      return new element().deserialize(item);
    };

    if (data['elements']) {
      this.elements = data['elements'].map(mapElement).filter(item => item != undefined);
    }

    if (data['style']) {
      this.style = data['style'];
    }

    if (data['position']) {
      this.position = data['position'];
    }

    if (data['overlay'] !== undefined) {
      this.overlay = data['overlay'];
    }

    if (data['close_button'] !== undefined) {
      this.closeButton = data['close_button'];
    }

    if (data['close_on_blur'] !== undefined) {
      this.closeOnBlur = data['close_on_blur'];
    }

    if (data['parameters']) {
      this.parameters = data['parameters'].map(item => new ParameterField().deserialize(item));
    }

    if (data['open_actions']) {
      this.openActions = data['open_actions'].map(item => new ActionItem().deserialize(item));
    }

    if (data['close_actions']) {
      this.closeActions = data['close_actions'].map(item => new ActionItem().deserialize(item));
    }

    if (!this.uid) {
      this.generateUid();
    }

    return this;
  }

  serialize(fields?: string[]): Object {
    let data: Object = {
      uid: this.uid,
      name: this.name,
      title: this.title ? this.title.serialize() : undefined,
      elements: this.elements.map(item => item.serialize()),
      width: this.width,
      style: this.style,
      position: this.position,
      overlay: this.overlay,
      close_button: this.closeButton,
      close_on_blur: this.closeOnBlur,
      parameters: this.parameters.map(item => item.serialize()),
      open_actions: this.openActions.map(item => item.serialize()),
      close_actions: this.closeActions.map(item => item.serialize())
    };
    if (fields) {
      data = <Object>pickBy(data, (v, k) => fields.includes(k));
    }
    return data;
  }

  generateUid() {
    this.uid = generateAlphanumeric(8, { letterFirst: true });
  }

  patch(popup: PopupSettings) {
    this.name = popup.name;
    this.title = cloneDeep(popup.title);
    this.width = popup.width;
    this.style = popup.style;
    this.position = popup.position;
    this.overlay = popup.overlay;
    this.closeButton = popup.closeButton;
    this.closeOnBlur = popup.closeOnBlur;
    this.parameters = cloneDeep(popup.parameters);
    this.openActions = cloneDeep(popup.openActions);
    this.closeActions = cloneDeep(popup.closeActions);
  }

  isSide(): boolean {
    return [PopupPosition.SideRight].includes(this.position);
  }

  isOrigin(): boolean {
    return [PopupPosition.Origin].includes(this.position);
  }

  isBackground(): boolean {
    return [PopupStyle.ModalBackground].includes(this.style);
  }

  getIcon(): string {
    if (this.isSide()) {
      return 'versions';
    } else if (this.isOrigin()) {
      return 'fileds';
    } else {
      return 'windows';
    }
  }
}

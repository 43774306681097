import { Directive, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { fromEvent } from 'rxjs';

import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';

import { GuidePopupService } from '../../services/guide-popup/guide-popup.service';

@Directive({
  selector: '[appDocumentation]'
})
export class DocumentationDirective implements OnInit, OnDestroy {
  @Input('appDocumentation') documentation: string;
  @Input('appDocumentationLeft') documentationLeft = false;
  @Input('appDocumentationSource') documentationSource: string;

  constructor(
    private el: ElementRef<HTMLElement>,
    private guidePopupService: GuidePopupService,
    private analyticsService: UniversalAnalyticsService
  ) {}

  ngOnInit(): void {
    if (!this.documentation) {
      return;
    }

    // this.el.nativeElement.setAttribute('href', this.documentation);

    if (this.el.nativeElement.tagName == 'A') {
      this.el.nativeElement.setAttribute('href', 'javascript:void(0)');
    }

    fromEvent<MouseEvent>(this.el.nativeElement, 'click')
      .pipe(untilDestroyed(this))
      .subscribe(e => {
        e.preventDefault();

        this.guidePopupService.openDocumentation(this.documentation, {
          left: this.documentationLeft
        });

        this.analyticsService.sendSimpleEvent(AnalyticsEvent.Documentation.Opened, {
          Path: this.documentation,
          Source: this.documentationSource
        });
      });
  }

  ngOnDestroy(): void {}
}

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { delay } from 'rxjs/operators';

import { DynamicComponentArguments } from '@common/dynamic-component';
import { PopoverComponent } from '@common/popover';
import { ViewContext, ViewContextElement } from '@modules/customize';
import { CurrentEnvironmentStore } from '@modules/projects';
import { Singleton } from '@shared';

import { createFormFieldFactory, getFieldComponentsByName, getFieldDescriptionByType } from '@modules/fields';

@Component({
  selector: 'app-parameter-fields-edit-item',
  templateUrl: './parameter-fields-edit-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ParameterFieldsEditItemComponent implements OnInit, OnDestroy {
  @Input() form: FormGroup;
  @Output() deleteRequested = new EventEmitter<void>();

  createField = createFormFieldFactory();
  viewParamsComponentData: DynamicComponentArguments;
  dataParamsComponentData: DynamicComponentArguments;
  contextNew = new Singleton(() => new ViewContext(this.currentEnvironmentStore));

  constructor(
    @Optional() private context: ViewContext,
    @Optional() private contextElement: ViewContextElement,
    private popoverComponent: PopoverComponent,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.form.controls['field'].valueChanges
      .pipe(delay(0), untilDestroyed(this))
      .subscribe(() => this.updateParamsComponentData());

    this.updateParamsComponentData();
  }

  ngOnDestroy(): void {}

  getContextProviders() {
    return [
      {
        provide: ViewContext,
        useFactory: () => {
          if (this.context) {
            return this.context;
          } else {
            return this.contextNew.get();
          }
        }
      }
    ];
  }

  updateParamsComponentData() {
    const item = getFieldDescriptionByType(this.form.value['field']);
    const components = getFieldComponentsByName(item.name);

    if (!components || !components.viewParamsComponent) {
      this.viewParamsComponentData = undefined;
    } else {
      this.viewParamsComponentData = {
        component: components.viewParamsComponent,
        inputs: {
          control: this.form.controls['params'],
          context: this.context || this.contextNew.get()
        },
        providers: this.getContextProviders()
      };
    }

    if (!components || !components.dataParamsComponent) {
      this.dataParamsComponentData = undefined;
    } else {
      this.dataParamsComponentData = {
        component: components.dataParamsComponent,
        inputs: {
          control: this.form.controls['params'],
          context: this.context || this.contextNew.get()
        },
        providers: this.getContextProviders()
      };
    }

    this.cd.markForCheck();
  }

  save() {
    this.popoverComponent.close();
  }

  requestDelete() {
    this.popoverComponent.close();
    this.deleteRequested.emit();
  }
}

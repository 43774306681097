import { InjectionToken, Input } from '@angular/core';

import { ViewSettingsAction } from '@modules/actions';
import { ViewContext, ViewContextElement } from '@modules/customize';

export abstract class ActionServiceActionMenuComponent {
  @Input() actions: ViewSettingsAction[] = [];
  @Input() context: ViewContext;
  @Input() contextElement: ViewContextElement;
  @Input() localContext: Object;
  @Input() analyticsSource: string;
}

export const ACTION_SERVICE_EXPORT_COMPONENT = new InjectionToken<any>('ACTION_SERVICE_EXPORT_COMPONENT');
export const ACTION_SERVICE_IMPORT_COMPONENT = new InjectionToken<any>('ACTION_SERVICE_IMPORT_COMPONENT');
export const ACTION_SERVICE_ACTION_MENU_COMPONENT = new InjectionToken<ActionServiceActionMenuComponent>(
  'ACTION_SERVICE_ACTION_MENU_COMPONENT'
);

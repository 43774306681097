import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import * as moment from 'moment';
import { map, publishLast, refCount, switchMap } from 'rxjs/operators';
import { ApiService } from '@modules/api';
import { ProjectPublicAccess } from '../../data/project-public-access';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../../api/services/api/api.service";
var AccessTokenResponse = /** @class */ (function () {
    function AccessTokenResponse() {
    }
    AccessTokenResponse.prototype.deserialize = function (data) {
        this.accessToken = data['access_token'];
        this.accessTokenExpires = data['access_token_expires'] ? moment(data['access_token_expires']) : undefined;
        this.refreshToken = data['refresh_token'];
        this.refreshTokenExpires = data['refresh_token_expires'] ? moment(data['refresh_token_expires']) : undefined;
        this.serverTime = data['server_time'] ? moment(data['server_time']) : undefined;
        return this;
    };
    return AccessTokenResponse;
}());
export { AccessTokenResponse };
var ProjectPublicAccessService = /** @class */ (function () {
    function ProjectPublicAccessService(http, apiService) {
        this.http = http;
        this.apiService = apiService;
    }
    ProjectPublicAccessService.prototype.get = function (projectName, environmentName, params) {
        var _this = this;
        if (params === void 0) { params = {}; }
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.environmentMethodURL(projectName, environmentName, 'public_accesses/');
            var headers = new HttpHeaders();
            var httpParams = new HttpParams({ fromObject: params });
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.get(url, { headers: headers, params: httpParams });
        }), map(function (result) { return result.map(function (item) { return new ProjectPublicAccess().deserialize(item); }); }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    ProjectPublicAccessService.prototype.getDetail = function (projectName, environmentName, id) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.environmentMethodURL(projectName, environmentName, "public_accesses/" + id + "/");
            var headers = new HttpHeaders();
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.get(url, { headers: headers });
        }), map(function (result) { return new ProjectPublicAccess().deserialize(result); }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    ProjectPublicAccessService.prototype.create = function (projectName, environmentName, instance) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.environmentMethodURL(projectName, environmentName, 'public_accesses/');
            var headers = new HttpHeaders();
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.post(url, instance.serialize(), { headers: headers });
        }), map(function (result) { return new ProjectPublicAccess().deserialize(result); }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    ProjectPublicAccessService.prototype.update = function (projectName, environmentName, instance) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.environmentMethodURL(projectName, environmentName, "public_accesses/" + instance.uid + "/");
            var headers = new HttpHeaders();
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.patch(url, instance.serialize(), { headers: headers });
        }), map(function (result) { return new ProjectPublicAccess().deserialize(result); }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    ProjectPublicAccessService.prototype.delete = function (projectName, environmentName, invite) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.environmentMethodURL(projectName, environmentName, "public_accesses/" + invite.uid + "/");
            var headers = new HttpHeaders();
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.delete(url, { headers: headers });
        }), map(function (result) { return true; }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    ProjectPublicAccessService.prototype.getByCode = function (code) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.methodURL("project_public_accesses/" + code + "/");
            var headers = new HttpHeaders();
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.get(url, { headers: headers });
        }), map(function (result) { return new ProjectPublicAccess().deserialize(result); }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    ProjectPublicAccessService.prototype.accept = function (code) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.methodURL("project_public_accesses/" + code + "/accept/");
            var headers = new HttpHeaders();
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.post(url, {}, { headers: headers });
        }), map(function (result) {
            return {
                userToken: new AccessTokenResponse().deserialize(result['user_token']),
                publicAccess: new ProjectPublicAccess().deserialize(result['public_access'])
            };
        }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    ProjectPublicAccessService.ngInjectableDef = i0.defineInjectable({ factory: function ProjectPublicAccessService_Factory() { return new ProjectPublicAccessService(i0.inject(i1.HttpClient), i0.inject(i2.ApiService)); }, token: ProjectPublicAccessService, providedIn: "root" });
    return ProjectPublicAccessService;
}());
export { ProjectPublicAccessService };

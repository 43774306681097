import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Injector,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { delay } from 'rxjs/operators';

import { PopoverService } from '@common/popover';

import { FieldType, registerFieldComponent } from '@modules/fields';
import { FilterItem } from '@modules/filters';
import { AddFilterPopupComponent } from '@modules/filters-edit';
import { ModelDescriptionStore } from '@modules/model-queries';
import { ModelDescription } from '@modules/models';

import { FieldComponent } from '../field/field.component';

@Component({
  selector: 'app-filters-field',
  templateUrl: 'filters-field.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FiltersFieldComponent extends FieldComponent implements OnInit, OnDestroy, OnChanges {
  filterItems: FilterItem[];
  modelDescription: ModelDescription;

  constructor(
    private injector: Injector,
    private cd: ChangeDetectorRef,
    private modelDescriptionStore: ModelDescriptionStore,
    private popoverService: PopoverService
  ) {
    super();
  }

  ngOnInit(): void {
    if (this.form) {
      this.form.controls[this.field.name].valueChanges
        .pipe(
          delay(1), // TODO: refactor delay(1)
          untilDestroyed(this)
        )
        .subscribe(value => this.updateFilterItems());
    }

    this.updateFilterItems();
    this.updateModelDescription();
  }

  ngOnDestroy(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.form && changes['value']) {
      this.updateFilterItems();
    }

    if (changes['field']) {
      this.updateModelDescription();
    }
  }

  updateFilterItems() {
    if (this.form) {
      this.filterItems = this.form.value[this.field.name] || [];
    } else {
      this.filterItems = this.value || [];
    }

    this.cd.markForCheck();
  }

  updateModelDescription() {
    const model = this.field.params['modelDescription'];

    if (!model) {
      this.modelDescription = undefined;
      this.cd.markForCheck();
      return;
    }

    this.modelDescriptionStore
      .getDetailFirst(model['model'])
      .pipe(untilDestroyed(this))
      .subscribe(modelDescription => {
        this.modelDescription = modelDescription;
        this.cd.markForCheck();
      });
  }

  addFilter(sourceElement) {
    if (!this.filterItems || !this.modelDescription) {
      return;
    }

    this.popoverService.create(
      {
        component: AddFilterPopupComponent,
        inputs: {
          modelDescription: this.modelDescription
        },
        outputs: {
          added: [
            (item: FilterItem) => {
              this.setValue(this.filterItems.concat([item]));
            }
          ]
        },
        injector: this.injector
      },
      sourceElement
    );
  }

  removeFilter(filterItem: FilterItem) {
    this.setValue(this.filterItems.filter(item => item != filterItem));
  }

  changeFilterItems(filterItems: FilterItem[]) {
    this.setValue(filterItems);
  }

  setValue(value: FilterItem[]) {
    this.filterItems = value;
    this.cd.markForCheck();

    if (this.form) {
      const params = {};
      params[this.field.name] = this.filterItems;
      this.form.patchValue(params);
    }
  }
}

registerFieldComponent(FieldType.Filters, FiltersFieldComponent);

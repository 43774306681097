import { TemplateRef } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { Frame, Translate } from '@modules/views';
var ViewEditorViewportOverlayService = /** @class */ (function () {
    function ViewEditorViewportOverlayService() {
        this._templates = new BehaviorSubject([]);
        this._templateChecked = new Subject();
    }
    Object.defineProperty(ViewEditorViewportOverlayService.prototype, "templates", {
        get: function () {
            return this._templates.value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ViewEditorViewportOverlayService.prototype, "templates$", {
        get: function () {
            return this._templates.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    ViewEditorViewportOverlayService.prototype.setTemplate = function (id, template, options) {
        if (options === void 0) { options = {}; }
        var templateItem = { id: id, template: template, options: options };
        var templateItems = this._templates.value.filter(function (item) { return item.id != id; }).concat([templateItem]);
        this._templates.next(templateItems);
    };
    ViewEditorViewportOverlayService.prototype.removeTemplate = function (id) {
        if (!this._templates.value.find(function (item) { return item.id == id; })) {
            return;
        }
        var templateItems = this._templates.value.filter(function (item) { return item.id != id; }).slice();
        this._templates.next(templateItems);
    };
    ViewEditorViewportOverlayService.prototype.markTemplateChecked = function (id) {
        this._templateChecked.next(id);
    };
    ViewEditorViewportOverlayService.prototype.templateChecked$ = function () {
        return this._templateChecked.asObservable();
    };
    return ViewEditorViewportOverlayService;
}());
export { ViewEditorViewportOverlayService };

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { delay, filter } from 'rxjs/operators';

import { IntercomService } from '@modules/analytics';
import { CurrentProjectStore } from '@modules/projects';

import { GuideTask } from '../../data/guide-task';
import { GuidePopupService } from '../../services/guide-popup/guide-popup.service';
import { GuideService } from '../../services/guide/guide.service';
import { GuideSectionStore } from '../../stores/guide-section.store';

@Component({
  selector: 'app-guide-widget',
  templateUrl: './guide-widget.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GuideWidgetComponent implements OnInit, OnDestroy {
  visible = false;
  currentTask: GuideTask;
  completedTask: GuideTask;
  completedTaskVisible = false;
  firstTaskCompleted = false;

  constructor(
    private guideService: GuideService,
    private guidePopupService: GuidePopupService,
    private guideSectionStore: GuideSectionStore,
    public intercomService: IntercomService,
    public currentProjectStore: CurrentProjectStore,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.guideService.state$.pipe(untilDestroyed(this)).subscribe(state => {
      this.visible = state != undefined;
      this.currentTask = state ? state.task : undefined;
      this.cd.markForCheck();
    });

    this.guideService.completedTask$
      .pipe(
        filter(state => !state.scenario.isEmpty),
        delay(600),
        untilDestroyed(this)
      )
      .subscribe(state => {
        this.completedTask = state.task;
        this.completedTaskVisible = true;
        this.cd.markForCheck();

        this.guideSectionStore
          .getTasks(true)
          .pipe(untilDestroyed(this))
          .subscribe(tasks => {
            const completed = tasks.filter(item => !item.autoComplete && item.completed);
            this.firstTaskCompleted = completed.length == 1;
            this.cd.markForCheck();
          });
      });
  }

  ngOnDestroy(): void {}

  openTask() {
    this.guidePopupService.openTask(this.guideService.state.task);
  }

  closeCompleted() {
    this.completedTaskVisible = false;
    this.cd.markForCheck();
  }

  get setupGuideLink() {
    return ['guide'];
  }
}

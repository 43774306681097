import { combineLatest, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { applyBooleanInput$, applyParamInput$, Input } from '@modules/fields';
import { isSet } from '@shared';
import { Color } from './color';
export var ShadowPosition;
(function (ShadowPosition) {
    ShadowPosition["Outside"] = "outside";
    ShadowPosition["Inside"] = "inside";
})(ShadowPosition || (ShadowPosition = {}));
var Shadow = /** @class */ (function () {
    function Shadow(options) {
        if (options === void 0) { options = {}; }
        this.position = ShadowPosition.Outside;
        this.offsetX = 0;
        this.offsetY = 2;
        this.blurRadius = 4;
        this.spreadRadius = 0;
        this.enabled = true;
        Object.assign(this, options);
    }
    Shadow.prototype.deserialize = function (data) {
        this.offsetX = data['offset_x'];
        this.offsetY = data['offset_y'];
        this.blurRadius = data['blur_radius'];
        this.spreadRadius = data['spread_radius'];
        if (data['position']) {
            this.position = data['position'];
        }
        if (data['color']) {
            this.color = new Color().deserialize(data['color']);
        }
        if (data['color_input']) {
            this.colorInput = new Input().deserialize(data['color_input']);
        }
        if (isSet(data['enabled'])) {
            this.enabled = data['enabled'];
        }
        if (data['enabled_input']) {
            this.enabledInput = new Input().deserialize(data['enabled_input']);
        }
        return this;
    };
    Shadow.prototype.serialize = function () {
        return {
            color: this.color ? this.color.serialize() : undefined,
            color_input: this.colorInput ? this.colorInput.serialize() : null,
            position: this.position,
            offset_x: this.offsetX,
            offset_y: this.offsetY,
            blur_radius: this.blurRadius,
            spread_radius: this.spreadRadius,
            enabled: this.enabled,
            enabled_input: this.enabledInput ? this.enabledInput.serialize() : null
        };
    };
    Shadow.prototype.cssColor$ = function (options) {
        if (options === void 0) { options = {}; }
        if (this.colorInput) {
            return applyParamInput$(this.colorInput, {
                context: options.context,
                contextElement: options.contextElement,
                localContext: options.localContext,
                defaultValue: ''
            });
        }
        else if (this.color) {
            return of(this.color.css());
        }
    };
    Shadow.prototype.cssBoxShadow$ = function (options) {
        var _this = this;
        if (options === void 0) { options = {}; }
        return combineLatest(this.cssColor$(options)).pipe(map(function (_a) {
            var color = _a[0];
            var result = [];
            if (_this.position == ShadowPosition.Inside) {
                result.push('inset');
            }
            result.push.apply(result, [_this.offsetX + "px", _this.offsetY + "px", _this.blurRadius + "px", _this.spreadRadius + "px"]);
            if (isSet(color)) {
                result.push(color);
            }
            return result.join(' ');
        }));
    };
    Shadow.prototype.cssTextShadow$ = function (options) {
        var _this = this;
        if (options === void 0) { options = {}; }
        return combineLatest(this.cssColor$(options)).pipe(map(function (_a) {
            var color = _a[0];
            var result = [_this.offsetX + "px", _this.offsetY + "px", _this.blurRadius + "px"];
            if (isSet(color)) {
                result.push(color);
            }
            return result.join(' ');
        }));
    };
    Shadow.prototype.enabled$ = function (options) {
        if (options === void 0) { options = {}; }
        if (this.enabledInput) {
            return applyBooleanInput$(this.enabledInput, {
                context: options.context,
                contextElement: options.contextElement,
                localContext: options.localContext
            }).pipe(catchError(function () { return of(false); }));
        }
        else {
            return of(true);
        }
    };
    return Shadow;
}());
export { Shadow };

import { ReplaySubject } from 'rxjs';
import { first } from 'rxjs/operators';
import { RoutingService } from '@modules/routing';
import { AuthService } from '../services/auth/auth.service';
import * as i0 from "@angular/core";
import * as i1 from "../services/auth/auth.service";
import * as i2 from "../../routing/services/routing/routing.service";
var IsNotUserAuthenticatedGuard = /** @class */ (function () {
    function IsNotUserAuthenticatedGuard(authService, routing) {
        this.authService = authService;
        this.routing = routing;
    }
    IsNotUserAuthenticatedGuard.prototype.canActivate = function (route, state) {
        var _this = this;
        var canActivate = new ReplaySubject();
        this.authService
            .isUserAuthorized()
            .pipe(first())
            .subscribe(function (authorized) { return canActivate.next(!authorized); }, function () { return canActivate.next(true); });
        canActivate.subscribe(function (allow) {
            if (!allow) {
                _this.routing.navigate(['/projects']);
            }
        });
        return canActivate;
    };
    IsNotUserAuthenticatedGuard.ngInjectableDef = i0.defineInjectable({ factory: function IsNotUserAuthenticatedGuard_Factory() { return new IsNotUserAuthenticatedGuard(i0.inject(i1.AuthService), i0.inject(i2.RoutingService)); }, token: IsNotUserAuthenticatedGuard, providedIn: "root" });
    return IsNotUserAuthenticatedGuard;
}());
export { IsNotUserAuthenticatedGuard };

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./input-tokens-list-item.component";
var styles_InputTokensListItemComponent = [];
var RenderType_InputTokensListItemComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_InputTokensListItemComponent, data: {} });
export { RenderType_InputTokensListItemComponent as RenderType_InputTokensListItemComponent };
function View_InputTokensListItemComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "span", [["class", "token-popup-list-item__left"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "span", [["class", "token-popup-list-item__icon"]], null, null, null, null, null)), i0.ɵdid(2, 278528, null, 0, i1.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "token-popup-list-item__icon"; var currVal_1 = ("icon-" + _co.item.icon); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_InputTokensListItemComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 8, "a", [["class", "token-popup-list-item token-popup-list-item_hoverable token-popup-list-item_clickable"], ["href", "javascript:void(0)"]], [[2, "token-popup-list-item_active", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.clickItem.emit(_co.item) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 7, "div", [["class", "token-popup-list-item__inner"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 6, "div", [["class", "token-popup-list-item__line"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_InputTokensListItemComponent_1)), i0.ɵdid(4, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(5, 0, null, null, 1, "span", [["class", "token-popup-list-item__main token-popup-list-item__main_wrap"]], [[8, "title", 0]], null, null, null, null)), (_l()(), i0.ɵted(6, null, ["", ""])), (_l()(), i0.ɵeld(7, 0, null, null, 1, "span", [["class", "token-popup-list-item__right token-popup-list-item__right_wrap"]], null, null, null, null, null)), (_l()(), i0.ɵted(8, null, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.item.icon; _ck(_v, 4, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = false; _ck(_v, 0, 0, currVal_0); var currVal_2 = _co.item.label; _ck(_v, 5, 0, currVal_2); var currVal_3 = _co.item.label; _ck(_v, 6, 0, currVal_3); var currVal_4 = (_co.value || _co.item.name); _ck(_v, 8, 0, currVal_4); }); }
export function View_InputTokensListItemComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-input-tokens-list-item", [], null, null, null, View_InputTokensListItemComponent_0, RenderType_InputTokensListItemComponent)), i0.ɵdid(1, 638976, null, 0, i2.InputTokensListItemComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var InputTokensListItemComponentNgFactory = i0.ɵccf("app-input-tokens-list-item", i2.InputTokensListItemComponent, View_InputTokensListItemComponent_Host_0, { item: "item", tokenValues: "tokenValues" }, { clickItem: "clickItem" }, []);
export { InputTokensListItemComponentNgFactory as InputTokensListItemComponentNgFactory };

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../../../../shared/pipes/is-set/is-set.pipe";
import * as i3 from "../../../../shared/pipes/safe-url/safe-url.pipe";
import * as i4 from "@angular/platform-browser";
import * as i5 from "./file-viewer-element.component";
import * as i6 from "../../../customize/services/customize/customize.service";
var styles_FileViewerElementComponent = [];
var RenderType_FileViewerElementComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_FileViewerElementComponent, data: {} });
export { RenderType_FileViewerElementComponent as RenderType_FileViewerElementComponent };
function View_FileViewerElementComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "iframe", [["class", "embed-element"]], [[2, "embed-element_border", null], [8, "src", 5]], null, null, null, null)), i0.ɵppd(1, 1)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.element.border; var currVal_1 = i0.ɵunv(_v, 0, 1, _ck(_v, 1, 0, i0.ɵnov(_v.parent.parent, 1), _co.embedUrl)); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_FileViewerElementComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "embed", [["class", "embed-element"]], [[2, "embed-element_border", null], [8, "src", 5]], null, null, null, null)), i0.ɵppd(1, 1)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.element.border; var currVal_1 = i0.ɵunv(_v, 0, 1, _ck(_v, 1, 0, i0.ɵnov(_v.parent.parent, 1), _co.embedUrl)); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_FileViewerElementComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_FileViewerElementComponent_2)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_FileViewerElementComponent_3)), i0.ɵdid(4, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.element.viewerType == _co.fileViewerTypes.Google) || _co.isSafari); _ck(_v, 2, 0, currVal_0); var currVal_1 = ((_co.element.viewerType != _co.fileViewerTypes.Google) && !_co.isSafari); _ck(_v, 4, 0, currVal_1); }, null); }
export function View_FileViewerElementComponent_0(_l) { return i0.ɵvid(2, [i0.ɵpid(0, i2.IsSetPipe, []), i0.ɵpid(0, i3.SafeUrlPipe, [i4.DomSanitizer]), (_l()(), i0.ɵand(16777216, null, null, 3, null, View_FileViewerElementComponent_1)), i0.ɵdid(3, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵpid(131072, i1.AsyncPipe, [i0.ChangeDetectorRef]), i0.ɵppd(5, 1)], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.visible || i0.ɵunv(_v, 3, 0, i0.ɵnov(_v, 4).transform(_co.customizeEnabled$))) && i0.ɵunv(_v, 3, 0, _ck(_v, 5, 0, i0.ɵnov(_v, 0), _co.embedUrl))); _ck(_v, 3, 0, currVal_0); }, null); }
export function View_FileViewerElementComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-file-viewer-element", [], null, null, null, View_FileViewerElementComponent_0, RenderType_FileViewerElementComponent)), i0.ɵdid(1, 770048, null, 0, i5.FileViewerElementComponent, [i6.CustomizeService, i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FileViewerElementComponentNgFactory = i0.ɵccf("app-file-viewer-element", i5.FileViewerElementComponent, View_FileViewerElementComponent_Host_0, { element: "element", elementActive: "elementActive", context: "context", visible: "visible", actionClicked: "actionClicked", accentColor: "accentColor" }, { updated: "updated", replaceRequested: "replaceRequested", deleteRequested: "deleteRequested" }, []);
export { FileViewerElementComponentNgFactory as FileViewerElementComponentNgFactory };

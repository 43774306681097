import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { combineLatest, Observable, of, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { AppFormGroup, FormUtils } from '@common/form-utils';
import { PopupService } from '@common/popups';
import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { ServerRequestError } from '@modules/api';
import { MenuGeneratorService } from '@modules/menu';
import { ProjectSettingsService } from '@modules/project-settings';
import { ProjectTokenService, SecretTokenService } from '@modules/projects';
import { FirebaseGeneratorService, FirebaseParamsOptions } from '@modules/resource-generators';
import { FirebaseDatabaseOption, FirebaseDatabaseType, ResourceParamsResult } from '@modules/resources';
import { isSet } from '@shared';

import { BaseResourceSettingsForm } from '../base-resource-settings/base-resource-settings.form';

@Injectable()
export class FirebaseResourceSettingsForm extends BaseResourceSettingsForm<FirebaseParamsOptions> {
  form = new AppFormGroup({
    service_token: new FormControl(null, [Validators.required, this.validateServiceToken()]),
    project_id: new FormControl(null, Validators.required),
    access_token: new FormControl(null, Validators.required),
    database_option: new FormControl(null, Validators.required),
    sync: new FormControl(true)
  });

  constructor(
    private firebaseGeneratorService: FirebaseGeneratorService,
    private http: HttpClient,
    private analyticsService: UniversalAnalyticsService,
    protected secretTokenService: SecretTokenService,
    protected formUtils: FormUtils,
    protected projectSettingsService: ProjectSettingsService,
    protected projectTokenService: ProjectTokenService,
    protected popupService: PopupService,
    protected menuGeneratorService: MenuGeneratorService,
    protected injector: Injector
  ) {
    super(
      secretTokenService,
      formUtils,
      projectSettingsService,
      projectTokenService,
      popupService,
      menuGeneratorService,
      injector
    );

    this.form.controls['service_token'].valueChanges
      .pipe(
        switchMap(value => {
          return this.firebaseGeneratorService.readServiceToken(value).pipe(catchError(() => of(undefined)));
        })
      )
      .subscribe(result => {
        this.form.patchValue(
          result
            ? {
                project_id: result.projectId,
                access_token: result.accessToken
              }
            : {
                project_id: '',
                access_token: ''
              }
        );
      });
  }

  validateServiceToken(): ValidatorFn {
    return (control: FormControl) => {
      if (!isSet(control.value)) {
        return;
      }

      const group = control.parent as FormGroup;

      if (!group) {
        return;
      }

      if (group.value['service_token'] && (!group.value['project_id'] || !group.value['access_token'])) {
        return { local: ['Service key is not valid or not enough permissions'] };
      }
    };
  }

  initResourceValue(): Observable<void> {
    return this.firebaseGeneratorService.getParamsOptions(this.project, this.environment, this.resource).pipe(
      map(result => {
        this.form.patchValue({
          service_token: result.serviceToken,
          project_id: result.projectId,
          access_token: result.accessToken,
          database_option: result.databaseOption || {
            type: FirebaseDatabaseType.Firestore
          },
          sync: !!this.resource.isSynced()
        });
      })
    );
  }

  getOptions(): FirebaseParamsOptions {
    return {
      serviceToken: this.form.value['service_token'].trim(),
      projectId: this.form.value['project_id'],
      accessToken: this.form.value['access_token'],
      databaseOption: this.form.value['database_option']
    };
  }

  databaseOptionEquals(lhs: FirebaseDatabaseOption, rhs: FirebaseDatabaseOption) {
    if (lhs && rhs) {
      return lhs.type == lhs.type && rhs.id == rhs.id;
    } else if (!lhs && !rhs) {
      return true;
    } else {
      return false;
    }
  }

  getParams(): ResourceParamsResult | Observable<ResourceParamsResult> {
    const options = this.getOptions();
    return this.firebaseGeneratorService.generateParams(this.project, this.environment, this.typeItem, options).pipe(
      map(result => {
        return {
          ...result,
          resourceName: this.resourceForm.value['name'],
          sync: this.form.value['sync'],
          syncModelDescriptions: this.getParamsSyncModelDescriptions(result)
        };
      }),
      catchError(error => {
        if (!this.resource) {
          const url = `https://firebasedatabase.googleapis.com/v1beta/projects/${options.projectId}/locations/-/instances`;
          const headers = { Authorization: `Bearer ${options.accessToken}` };
          const errorMessage = error instanceof ServerRequestError && error.errors.length ? error.errors[0] : error;

          this.http
            .get(url, { headers: headers })
            .pipe(
              switchMap(result => {
                if (!result['instances'].length) {
                  return of([]);
                }

                return combineLatest(
                  result['instances'].map(item => {
                    const databaseUrl = `${item['databaseUrl']}/.json`;
                    return this.http.get(databaseUrl, { headers: headers });
                  })
                );
              })
            )
            .subscribe(
              databases => {
                this.analyticsService.sendSimpleEvent(AnalyticsEvent.Resource.ResourceAddFailedDiagnostics, {
                  ResourceID: this.typeItem.name,
                  Error: errorMessage,
                  Options: options,
                  ResourceContents: databases
                });
              },
              databaseError => {
                const databaseErrorMessage =
                  databaseError instanceof ServerRequestError && databaseError.errors.length
                    ? databaseError.errors[0]
                    : databaseError;

                this.analyticsService.sendSimpleEvent(AnalyticsEvent.Resource.ResourceAddFailedDiagnostics, {
                  ResourceID: this.typeItem.name,
                  Error: errorMessage,
                  Options: options,
                  ResourceContents: databaseErrorMessage
                });
              }
            );
        }

        return throwError(error);
      })
    );
  }
}

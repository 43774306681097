var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { map } from 'rxjs/operators';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { ListStore } from '@shared';
import { WorkflowBackendRunService } from '../services/workflow-backend-run/workflow-backend-run.service';
var WorkflowBackendRunListStore = /** @class */ (function (_super) {
    __extends(WorkflowBackendRunListStore, _super);
    function WorkflowBackendRunListStore(currentProjectStore, currentEnvironmentStore, workflowRunService) {
        var _this = _super.call(this) || this;
        _this.currentProjectStore = currentProjectStore;
        _this.currentEnvironmentStore = currentEnvironmentStore;
        _this.workflowRunService = workflowRunService;
        return _this;
    }
    Object.defineProperty(WorkflowBackendRunListStore.prototype, "hasMore", {
        get: function () {
            return true;
        },
        enumerable: true,
        configurable: true
    });
    WorkflowBackendRunListStore.prototype.fetchPage = function (page, next) {
        return this.workflowRunService
            .get(this.currentProjectStore.instance, this.currentEnvironmentStore.instance, {
            automationUid: this.automationUid,
            workflowUid: this.workflowUid,
            page: page
        })
            .pipe(map(function (response) {
            return {
                items: response.results,
                hasMore: page < response.numPages,
                totalPages: response.numPages,
                perPage: response.perPage,
                count: response.count
            };
        }));
    };
    return WorkflowBackendRunListStore;
}(ListStore));
export { WorkflowBackendRunListStore };

import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-custom-view-templates-action-card',
  templateUrl: './custom-view-templates-action-card.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomViewTemplatesActionCardComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}

export class Region {
  public uid: string;
  public name: string;
  public jetBridgeCloudIPs: string[] = [];
  public jetBridgeCloudUrl: string;
  public active = false;
  public default = false;
  public dateAdd: string;
  public params = {};

  deserialize(data: Object): Region {
    this.uid = data['uid'];
    this.name = data['name'];
    this.jetBridgeCloudUrl = data['jet_bridge_cloud_url'];
    this.active = data['active'];
    this.default = data['default'];
    this.dateAdd = data['date_add'];

    if (data['jet_bridge_cloud_ips']) {
      this.jetBridgeCloudIPs = data['jet_bridge_cloud_ips'];
    }

    if (data['params']) {
      this.params = data['params'];
    }

    return this;
  }
}

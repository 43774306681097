import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';

import { NotificationService } from '@common/notifications';
import { PopupRef } from '@common/popups';
import { ServerRequestError } from '@modules/api';
import { createFormFieldFactory } from '@modules/fields';
import { SlackChannelStore, SlackService } from '@modules/slack';
import { openUrl, setControlEnabled } from '@shared';

import { SlackJoinPopupForm } from './slack-join-popup.form';

enum CustomizeMode {
  Email = 'email',
  Channel = 'channel'
}

interface Customize {
  mode: CustomizeMode;
  valueBefore: Object;
}

@Component({
  selector: 'app-slack-join-popup',
  templateUrl: './slack-join-popup.component.html',
  providers: [SlackJoinPopupForm],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SlackJoinPopupComponent implements OnInit, OnDestroy {
  @Output() cancelled = new EventEmitter<void>();

  createField = createFormFieldFactory();
  loading = false;
  joinSlackLoading = false;
  joinSlackDone = false;
  saveSlackChannelLoading = false;
  slackChannel: SlackService.SlackChannelResponse;
  customizing: Customize;
  customizeModes = CustomizeMode;

  constructor(
    public form: SlackJoinPopupForm,
    private slackChannelStore: SlackChannelStore,
    private notificationService: NotificationService,
    private popupRef: PopupRef,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.loading = true;
    this.cd.markForCheck();

    this.slackChannelStore
      .get$(true)
      .pipe(untilDestroyed(this))
      .subscribe(
        result => {
          this.slackChannel = result;
          this.loading = false;
          this.cd.markForCheck();

          this.form.init({ slackChannel: result });
        },
        () => {
          this.loading = false;
          this.cd.markForCheck();
        }
      );
  }

  ngOnDestroy(): void {}

  joinSlack() {
    this.joinSlackLoading = true;
    this.cd.markForCheck();

    this.form
      .getSlackJoinLink()
      .pipe(untilDestroyed(this))
      .subscribe(
        url => {
          openUrl(url, true);

          this.joinSlackDone = true;
          this.joinSlackLoading = false;
          this.cd.markForCheck();
        },
        error => {
          console.error(error);

          this.joinSlackLoading = false;
          this.cd.markForCheck();

          if (error instanceof ServerRequestError && error.errors.length) {
            this.notificationService.error('Join failed', error.errors[0]);
          } else {
            this.notificationService.error('Join failed', error);
          }
        }
      );
  }

  setCustomize(customize: Customize) {
    this.customizing = customize;
    this.cd.markForCheck();

    setControlEnabled(this.form.controls.email, customize && customize.mode == CustomizeMode.Email);
    setControlEnabled(this.form.controls.channel, customize && customize.mode == CustomizeMode.Channel);
  }

  startCustomize(mode: CustomizeMode) {
    this.cancelCustomize();
    this.setCustomize({
      mode: mode,
      valueBefore: this.form.value
    });
  }

  finishCustomize() {
    if (!this.customizing) {
      return;
    }

    this.setCustomize(undefined);
  }

  cancelCustomize() {
    if (!this.customizing) {
      return;
    }

    this.form.patchValue(this.customizing.valueBefore);
    this.form.markAsPristine();

    this.setCustomize(undefined);
  }

  saveSlackChannel() {
    if (this.form.invalid) {
      return;
    }

    const join = !this.form.joined;

    this.saveSlackChannelLoading = true;
    this.cd.markForCheck();

    this.form
      .joinSlackChannel()
      .pipe(untilDestroyed(this))
      .subscribe(
        result => {
          this.saveSlackChannelLoading = false;
          this.cd.markForCheck();

          this.slackChannelStore.set(result);
          this.finishCustomize();

          if (join) {
            openUrl(result.channel.href, true);

            this.notificationService.success('Channel joined', 'Please open Slack to reveal your personal channel');
          } else {
            this.notificationService.success('Channel updated', 'Please open Slack to reveal your personal channel');
          }
        },
        error => {
          console.error(error);

          this.saveSlackChannelLoading = false;
          this.cd.markForCheck();

          if (error instanceof ServerRequestError && error.errors.length) {
            this.notificationService.error('Join channel failed', error.errors[0]);
          } else {
            this.notificationService.error('Join channel failed', error);
          }
        }
      );
  }

  cancel() {
    this.cancelled.emit();
    this.close();
  }

  close() {
    this.popupRef.close();
  }
}

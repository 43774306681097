var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { AfterViewInit, ElementRef, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import Chart from 'chart.js';
import * as Color from 'color';
import cloneDeep from 'lodash/cloneDeep';
import maxBy from 'lodash/maxBy';
import merge from 'lodash/merge';
import range from 'lodash/range';
import * as moment from 'moment';
import { untilDestroyed } from 'ngx-take-until-destroy';
import * as numeral from 'numeral';
import { interval } from 'rxjs';
import { filter, map, pairwise } from 'rxjs/operators';
import { isColorHex, isSet } from '@shared';
import { datasetGroupDateLookups, DatasetGroupLookup } from '../../data/dataset';
import { datasetGroupLookupUnitOfTime, dateFormatByUnitOfTime, groupDatasetByUnitOfTime, parseDate } from '../../utils/date';
export function safeNumber(value) {
    if (!isSet(value)) {
        return 0;
    }
    else if (typeof value === 'number') {
        return value;
    }
    var result = parseInt(value, 10);
    if (isNaN(result)) {
        return 0;
    }
    else {
        return result;
    }
}
var BaseChartComponent = /** @class */ (function () {
    function BaseChartComponent(themeService) {
        this.themeService = themeService;
        this.datasets = [];
        this.datasetsCurrent = [];
        this.colors = {
            default: {
                gridLine: 'rgba(0, 0, 0, 0.05)',
                border: {
                    aqua: '#6ed5d4',
                    black: '#000',
                    blue: '#007fd2',
                    'bright-blue': '#1755e5',
                    fuchsia: '#cc99ff',
                    gray: '#808080',
                    green: '#4bcc29',
                    lime: '#baf164',
                    maroon: '#800000',
                    navy: '#006780',
                    olive: '#678000',
                    purple: '#800080',
                    red: '#ff4c59',
                    silver: '#C0C0C0',
                    teal: '#22bbb3',
                    white: '#fff',
                    yellow: '#eade0c',
                    orange: '#ffae00'
                },
                gradient: {
                    aqua: '110,213,212',
                    black: '0,0,0',
                    blue: '80,125,255',
                    'bright-blue': '80,125,255',
                    fuchsia: '255,0,255',
                    gray: '128,128,128',
                    green: '68,224,90',
                    lime: '0,255,2',
                    maroon: '128,0,0',
                    navy: '0,0,128',
                    olive: '128,128,0',
                    purple: '128,0,128',
                    red: '428,71,90',
                    silver: '192,192,192',
                    teal: '0,128,128',
                    white: '255,255,255',
                    yellow: '255,238,106',
                    orange: '255,238,106'
                },
                gradientOpacity: '0.25'
            },
            dark: {
                gridLine: 'rgba(255, 255, 255, 0.1)',
                border: {
                    aqua: '#6ed5d4',
                    black: '#000',
                    blue: '#007fd2',
                    'bright-blue': '#1755e5',
                    fuchsia: '#cc99ff',
                    gray: '#808080',
                    green: '#4bcc29',
                    lime: '#baf164',
                    maroon: '#800000',
                    navy: '#006780',
                    olive: '#678000',
                    purple: '#800080',
                    red: '#ff4c59',
                    silver: '#C0C0C0',
                    teal: '#22bbb3',
                    white: '#fff',
                    yellow: '#eade0c',
                    orange: '#ffae00'
                },
                gradient: {
                    aqua: '29,35,41',
                    black: '0,0,0',
                    blue: '29,35,41',
                    'bright-blue': '29,35,41',
                    fuchsia: '255,0,255',
                    gray: '128,128,128',
                    green: '29,35,41',
                    lime: '0,255,2',
                    maroon: '128,0,0',
                    navy: '0,0,128',
                    olive: '128,128,0',
                    purple: '128,0,128',
                    red: '29,35,41',
                    silver: '192,192,192',
                    teal: '0,128,128',
                    white: '255,255,255',
                    yellow: '29,35,41',
                    orange: '29,35,41'
                },
                gradientOpacity: '0.74'
            }
        };
        this.displayFormats = {
            millisecond: 'mm:ss.SSS',
            second: 'HH:mm:ss',
            minute: 'HH:mm',
            hour: 'hA',
            day: 'MMM D',
            week: 'MMM D',
            month: 'MMM',
            quarter: '[Q]Q',
            year: 'YYYY'
        };
    }
    BaseChartComponent.prototype.ngOnDestroy = function () { };
    BaseChartComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        interval(100)
            .pipe(map(function () {
            return {
                width: _this.chartElement.nativeElement.offsetWidth,
                height: _this.chartElement.nativeElement.offsetHeight
            };
        }), pairwise(), filter(function (values) { return values[0].width != values[1].width || values[0].height != values[1].height; }), untilDestroyed(this))
            .subscribe(function () { return _this.onResize(); });
    };
    BaseChartComponent.prototype.ngOnChanges = function (changes) {
        if (changes['datasets']) {
            this.initData();
            this.initChart();
        }
    };
    BaseChartComponent.prototype.initData = function () {
        this.xAxis = this.getXAxis(this.datasets);
        this.datasetsCurrent = cloneDeep(this.datasets);
        if (this.xAxis['type'] == 'time') {
            var unit_1 = this.xAxis['time']['unit'];
            this.datasetsCurrent = this.datasetsCurrent.map(function (dataset) {
                dataset.dataset = dataset.dataset
                    .filter(function (group) { return group.group !== null && group.group !== undefined; })
                    .map(function (item) {
                    if (!(item.group instanceof moment)) {
                        item.group = parseDate(item.group);
                    }
                    return item;
                })
                    .filter(function (group) { return !(group.group instanceof moment && !group.group.isValid()); })
                    .sort(function (lhs, rhs) {
                    if (lhs.group instanceof moment && rhs.group instanceof moment) {
                        return lhs.group.valueOf() - rhs.group.valueOf();
                    }
                    else if (lhs.group < rhs.group) {
                        return -1;
                    }
                    else if (lhs.group > rhs.group) {
                        return 1;
                    }
                    else {
                        return 0;
                    }
                });
                if (unit_1) {
                    dataset.dataset = groupDatasetByUnitOfTime(dataset.dataset, unit_1);
                }
                return dataset;
            });
        }
    };
    BaseChartComponent.prototype.initChart = function () {
        if (this.chart) {
            this.updateChart();
        }
        else {
            this.createChart();
        }
    };
    BaseChartComponent.prototype.createChart = function () {
        var _this = this;
        var labels = this.lineChartLabels();
        var datasets = this.lineChartData();
        var options = merge({
            data: __assign({}, (labels ? { labels: labels } : {}), { datasets: datasets }),
            options: this.lineChartOptions()
        }, this.chartOptions());
        this.ctx = this.chartElement.nativeElement.getContext('2d');
        this.chart = new Chart(this.ctx, options);
        this.themeService.theme$.pipe(untilDestroyed(this)).subscribe(function () { return _this.onThemeChange(); });
    };
    BaseChartComponent.prototype.updateChart = function () {
        var labels = this.lineChartLabels();
        var datasets = this.lineChartData();
        if (labels) {
            this.chart.data.labels = labels;
        }
        this.chart.data.datasets = datasets;
        this.chart.options = this.lineChartOptions();
        this.chart.update({
            duration: 0
        });
    };
    BaseChartComponent.prototype.chartOptions = function () {
        return {};
    };
    BaseChartComponent.prototype.lineChartLabels = function () {
        if (!this.datasetsCurrent || !this.datasetsCurrent.length) {
            return [];
        }
        return this.datasetsCurrent[0].dataset.map(function (item) { return item.group; });
    };
    BaseChartComponent.prototype.detectTimeUnit = function (datasets) {
        var durations = [
            // ['millisecond', 1, 0],
            // ['second', 1000, 0],
            // ['minute', 1000 * 60, 0],
            // ['hour', 1000 * 60 * 60, 0],
            ['day', 1000 * 60 * 60 * 24, 0],
            ['week', 1000 * 60 * 60 * 24 * 7, 0],
            ['month', 1000 * 60 * 60 * 24 * 30, 0],
            ['quarter', 1000 * 60 * 60 * 24 * 30 * 3, 0],
            ['year', 1000 * 60 * 60 * 24 * 30 * 12, 0]
        ];
        datasets.forEach(function (dataset) {
            for (var i = 1; i < dataset.dataset.length; ++i) {
                var maxDuration = 0;
                var current = parseDate(dataset.dataset[i].group);
                var prev = parseDate(dataset.dataset[i - 1].group);
                if (current.isValid()) {
                    dataset.dataset[i].group = current;
                }
                if (prev.isValid()) {
                    dataset.dataset[i - 1].group = prev;
                }
                if (!current.isValid() || !prev.isValid()) {
                    continue;
                }
                var diff = Math.abs(current.diff(prev));
                for (var j = 1; j < durations.length; ++j) {
                    if (diff < durations[j][1]) {
                        break;
                    }
                    maxDuration = j;
                }
                ++durations[maxDuration][2];
            }
        });
        return maxBy(durations, function (duration) { return duration[2]; })[0];
    };
    BaseChartComponent.prototype.getXAxis = function (datasets) {
        var lookup = datasets && datasets.length ? datasets[0].groupLookup : undefined;
        var isDateLookup = datasetGroupDateLookups.includes(lookup);
        if (lookup && (lookup == DatasetGroupLookup.Auto || isDateLookup)) {
            var firstDataset = datasets.filter(function (dataset) {
                return dataset.dataset.length &&
                    dataset.dataset.find(function (item) { return item.group !== undefined && item.group !== null; }) != undefined;
            });
            var firstValue = firstDataset.length
                ? firstDataset[0].dataset.find(function (item) { return item.group !== undefined && item.group !== null; }).group
                : undefined;
            var firstValueIsDate = parseDate(firstValue).isValid();
            if (isDateLookup || firstValueIsDate) {
                var unit = isSet(lookup) ? datasetGroupLookupUnitOfTime(lookup) : this.detectTimeUnit(datasets);
                return {
                    type: 'time',
                    time: {
                        unit: unit,
                        isoWeekday: true,
                        tooltipFormat: dateFormatByUnitOfTime[unit],
                        displayFormats: this.displayFormats
                    }
                };
            }
        }
        return {
            type: undefined
        };
    };
    BaseChartComponent.prototype.getDatasetsTotalData = function () {
        return this.datasetsCurrent.reduce(function (acc, dataset) {
            dataset.dataset.forEach(function (item) {
                var key = String(item.group);
                acc[key] = safeNumber(acc[key]) + safeNumber(item.value);
            });
            return acc;
        }, {});
    };
    BaseChartComponent.prototype.normalizeDatasetValue = function (item, totalData) {
        var totalItemData = totalData[String(item.group)];
        if (totalItemData === 0 || !isSet(totalItemData)) {
            return 0;
        }
        else {
            return safeNumber(item.value) / totalItemData;
        }
    };
    Object.defineProperty(BaseChartComponent.prototype, "currentColors", {
        get: function () {
            return this.colors[this.themeService.theme];
        },
        enumerable: true,
        configurable: true
    });
    BaseChartComponent.prototype.segmentColors = function (reverse, fill) {
        var _this = this;
        if (reverse === void 0) { reverse = false; }
        var mainColors = ['blue', 'red', 'green', 'fuchsia', 'teal', 'yellow', 'aqua'];
        var colors = (reverse ? mainColors.reverse() : mainColors).concat([
            'orange',
            'lime',
            'navy',
            'purple',
            'olive',
            'maroon'
        ]).map(function (item) { return _this.currentColors.border[item]; })
            .filter(function (item) { return !!item; });
        var fillFromArray = function (array, length) {
            var arrayLength = array.length;
            return range(0, length).map(function (i) { return array[i % arrayLength]; });
        };
        if (fill) {
            colors = fillFromArray(colors, fill);
        }
        return colors;
    };
    BaseChartComponent.prototype.gradientColor = function (color) {
        if (!color) {
            return this.currentColors.gradient['blue'];
        }
        if (isColorHex(color)) {
            return Color(color).color.join(',');
        }
        else {
            return this.currentColors.gradient[color] || this.currentColors.gradient['blue'];
        }
    };
    BaseChartComponent.prototype.borderColor = function (color) {
        if (!color) {
            return this.currentColors.border['blue'];
        }
        if (isColorHex(color)) {
            return color;
        }
        else {
            return this.currentColors.border[color] || this.currentColors.border['blue'];
        }
    };
    BaseChartComponent.prototype.tooltipLabel = function (tooltipItem, data, axes, showXLabel) {
        if (showXLabel === void 0) { showXLabel = false; }
        var label;
        var value;
        if (axes == 3) {
            label = data.datasets[tooltipItem['datasetIndex']]['label'] || '';
            value = [
                this.datasetsCurrent[tooltipItem['datasetIndex']].dataset[tooltipItem['index']].group2,
                this.datasetsCurrent[tooltipItem['datasetIndex']].dataset[tooltipItem['index']].value
            ].join(', ');
        }
        else if (axes == 2) {
            label = data.datasets[tooltipItem['datasetIndex']]['label'] || '';
            value = tooltipItem['yLabel'];
        }
        else if (axes == 1) {
            label = this.datasetsCurrent[tooltipItem['datasetIndex']].dataset[tooltipItem['index']].group;
            value = data.datasets[tooltipItem['datasetIndex']]['data'][tooltipItem['index']];
        }
        var format = this.datasetsCurrent[tooltipItem['datasetIndex']].format || this.defaultDatasetFormat;
        if (format) {
            value = numeral(value).format(format);
        }
        if (showXLabel) {
            var xLabel = tooltipItem.xLabel;
            return label + ": (" + xLabel + ", " + value + ")";
        }
        else {
            return label + ": " + value;
        }
    };
    BaseChartComponent.prototype.onResize = function () { };
    BaseChartComponent.prototype.onThemeChange = function () { };
    return BaseChartComponent;
}());
export { BaseChartComponent };

<a
  href="javascript:void(0)"
  class="demo-widget icon"
  [class.icon-briefcase]="!jobsOpened"
  [class.icon-close]="jobsOpened"
  [class.demo-widget_active]="jobsOpened"
  [class.demo-widget_visible]="jobs"
  [class.demo-widget_shift]="intercomService.visible$ | async"
  (click)="toggleJobs()"
>
</a>

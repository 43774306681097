var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ChangeDetectorRef, OnDestroy, OnInit } from '@angular/core';
import { map } from 'rxjs/operators';
import { ActionGroupElementItem, ActionGroupOrientation, CustomizeService, ElementType, registerElementComponent } from '@modules/customize';
import { BaseElementComponent } from '@modules/customize-elements';
var ActionGroupElementComponent = /** @class */ (function (_super) {
    __extends(ActionGroupElementComponent, _super);
    function ActionGroupElementComponent(customizeService, cd) {
        var _this = _super.call(this) || this;
        _this.customizeService = customizeService;
        _this.cd = cd;
        _this.orientations = ActionGroupOrientation;
        return _this;
    }
    ActionGroupElementComponent.prototype.ngOnInit = function () {
        this.customizeEnabled$ = this.customizeService.enabled$.pipe(map(function (item) { return !!item; }));
    };
    ActionGroupElementComponent.prototype.ngOnDestroy = function () { };
    return ActionGroupElementComponent;
}(BaseElementComponent));
export { ActionGroupElementComponent };
registerElementComponent({
    type: ElementType.ActionGroup,
    component: ActionGroupElementComponent,
    label: 'Action group',
    actions: []
});

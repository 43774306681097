import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { combineLatest, Observable } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';

import { Margin } from '@modules/customize';
import { Input } from '@modules/fields';
import { FieldInputControl } from '@modules/parameters';
import { controlValid } from '@shared';

import { MarginControl } from '../../margin-control/margin-control.component';

export abstract class CustomizeBarBaseWidgetEditForm<T> extends FormGroup {
  controls: {
    name: FormControl;
    title: FieldInputControl;
    visible_input: FieldInputControl;
    margin: MarginControl;
  };

  widget: T;
  component: any;

  abstract init(widget?: T, name?: string, visibleInput?: Input, margin?: Margin, firstInit?: boolean, component?: any);

  controlsValid$(controls: AbstractControl[]): Observable<boolean> {
    return combineLatest(controls.map(control => controlValid(control))).pipe(
      map(result => result.every(item => item)),
      debounceTime(60)
    );
  }

  abstract isConfigured(instance: T): Observable<boolean>;

  abstract submit(): { widget: T; name?: string; visibleInput?: Input; margin?: Margin };
}

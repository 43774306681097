import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import range from 'lodash/range';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { merge, Observable, of } from 'rxjs';
import { filter, map, pairwise, switchMap } from 'rxjs/operators';

import { DropListService } from '@common/drag-drop2';
import { ContainerLayer, findLayer, isContainerLayer, Layer } from '@modules/views';
import { isSet, KeyboardEventKeyCode, TypedChanges } from '@shared';

import { ViewEditorContext, ViewEditorCustomizeSource } from '../../services/view-editor-context/view-editor.context';

@Component({
  selector: 'app-view-editor-navigator-item',
  templateUrl: './view-editor-navigator-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ViewEditorNavigatorItemComponent implements OnInit, OnDestroy, OnChanges {
  @Input() layer: Layer;
  @Input() flexLayout = false;
  @Input() indent = 0;
  @Input() search = '';
  @Output() itemCustomize = new EventEmitter<void>();
  @Output() itemAddCustomizing = new EventEmitter<{ shift?: boolean }>();
  @Output() itemRemoveCustomizing = new EventEmitter<void>();
  @Output() visibleUpdated = new EventEmitter<void>();

  @ViewChild('input') inputElement: ElementRef;

  containerLayer: ContainerLayer;
  indentRange: number[] = [];
  customizing$: Observable<boolean>;
  nameValue: string;
  nameEditing = false;
  transferring = false;
  layersOpened = false;

  constructor(
    public editorContext: ViewEditorContext,
    private dropListService: DropListService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.customizing$ = this.editorContext.isCustomizingLayer$(this.layer);

    this.dropListService.draggingDragStart$.pipe(untilDestroyed(this)).subscribe(dragStart => {
      if (dragStart) {
        const dragLayer = dragStart.source.data as Layer;
        const customizingLayers = this.editorContext.customizingLayers$.value;
        const transferLayers =
          customizingLayers.length > 1 && customizingLayers.find(item => item.isSame(dragLayer))
            ? customizingLayers
            : [dragLayer];

        this.transferring = transferLayers.some(item => item.isSame(this.layer));
        this.cd.markForCheck();
      } else {
        this.transferring = false;
        this.cd.markForCheck();
      }
    });

    this.editorContext
      .layerChanged$()
      .pipe(
        filter(event => event.layer.isSame(this.layer)),
        untilDestroyed(this)
      )
      .subscribe(() => this.cd.markForCheck());

    if (isContainerLayer(this.layer)) {
      const container = this.layer.layers;

      merge(
        of(container),
        this.editorContext.layerContainerChanged$().pipe(
          filter(event => event.container === container),
          map(event => event.container)
        )
      )
        .pipe(
          switchMap(layers => {
            return this.editorContext.customizingLayers$.pipe(
              map(customizingLayers => {
                return layers.filter(layer => customizingLayers.find(item => item.isSame(layer)));
              }),
              pairwise(),
              filter(([prev, current]) => {
                return current.some(currentItem => !prev.find(prevItem => prevItem.isSame(currentItem)));
              })
            );
          }),
          untilDestroyed(this)
        )
        .subscribe(() => {
          if (!this.layersOpened) {
            this.layersOpened = true;
            this.cd.markForCheck();
          }
        });

      this.editorContext.customizingLayers$
        .pipe(
          filter(layers => layers.length && !this.layersOpened),
          untilDestroyed(this)
        )
        .subscribe(layers => {
          if (findLayer(container, layer => layers.some(item => item.isSame(layer)))) {
            this.setOpened(true);
          }
        });
    }
  }

  ngOnDestroy(): void {
    this.editorContext.removeHoverLayer(this.layer, this);
  }

  ngOnChanges(changes: TypedChanges<ViewEditorNavigatorItemComponent>): void {
    if (changes.layer) {
      this.containerLayer = this.layer && isContainerLayer(this.layer) ? this.layer : undefined;

      if (changes.layer.firstChange) {
        this.layersOpened = this.containerLayer ? this.containerLayer.layersOpened : false;
      }
    }

    if (changes.indent) {
      this.indentRange = range(this.indent);
    }
  }

  startEditing() {
    this.nameValue = this.layer.name;
    this.nameEditing = true;
    this.cd.detectChanges();

    this.inputElement.nativeElement.focus();
    setTimeout(() => this.inputElement.nativeElement.select(), 0);
  }

  finishEditing(save = true) {
    this.nameEditing = false;
    this.cd.markForCheck();

    if (save && isSet(this.nameValue)) {
      this.layer.name = this.nameValue;
      this.cd.markForCheck();
      this.editorContext.markLayersChanged([this.layer], ViewEditorCustomizeSource.Navigator);
    }

    this.nameValue = undefined;
  }

  onClick(e: MouseEvent) {
    if (e.metaKey || e.ctrlKey) {
      if (this.editorContext.isCustomizingLayer(this.layer) && this.editorContext.isCustomizingMultipleLayers()) {
        this.itemRemoveCustomizing.emit();
      } else {
        this.itemAddCustomizing.emit({ shift: false });
      }
    } else if (e.shiftKey) {
      this.itemAddCustomizing.emit({ shift: e.shiftKey });
    } else {
      this.itemCustomize.emit();
    }
  }

  onMouseEnter() {
    if (this.dropListService.dragging$.value) {
      return;
    }

    this.editorContext.addHoverLayer(this.layer, this);
  }

  onMouseLeave() {
    this.editorContext.removeHoverLayer(this.layer, this);
  }

  onKeyUp(e: KeyboardEvent) {
    if (e.keyCode == KeyboardEventKeyCode.Enter) {
      this.finishEditing();
    } else if (e.keyCode == KeyboardEventKeyCode.Escape) {
      this.finishEditing(false);
    }
  }

  toggleVisible() {
    this.layer.visible = !this.layer.visible;
    this.cd.markForCheck();
    this.editorContext.markLayersChanged([this.layer], ViewEditorCustomizeSource.Navigator);
    this.visibleUpdated.emit();
  }

  setOpened(value: boolean) {
    this.layersOpened = value;
    this.cd.markForCheck();

    if (this.containerLayer) {
      this.containerLayer.layersOpened = value;
    }
  }

  toggleOpened() {
    this.setOpened(!this.layersOpened);
  }
}

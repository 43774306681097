import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { IntercomService } from '@modules/analytics';
import { Project } from '@modules/projects';

@Component({
  selector: 'app-subscription-past-due-toast',
  templateUrl: './subscription-past-due-toast.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubscriptionPastDueToastComponent implements OnInit {
  @Input() project: Project;
  @Input() shift = false;

  constructor(private intercomService: IntercomService) {}

  ngOnInit() {}

  openChat() {
    this.intercomService.openChat();
  }
}

<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayPanelClass]="['overlay', 'overlay_horizontal_center']"
  [cdkConnectedOverlayPositions]="popoverPositions"
  [cdkConnectedOverlayOrigin]="origin"
  [cdkConnectedOverlayOpen]="dropdownOpened"
  [cdkConnectedOverlayPush]="true"
  [cdkConnectedOverlayGrowAfterOpen]="true"
  [cdkConnectedOverlayHasBackdrop]="true"
  [cdkConnectedOverlayBackdropClass]="'popover2-backdrop'"
  (backdropClick)="onBackgroundClick()"
  (detach)="setDropdownOpened(false)"
>
  <div class="popover2 overlay-content overlay-content_animated">
    <div class="popover2__inner">
      <form class="table-form" (submit)="submit()">
        <div class="table-form__items">
          <div class="table-form__item">
            <div class="table-form-item">
              <div class="table-form-item__main">
                <div class="table-form-item__inputs">
                  <div class="table-form-item__input table-form-item__input_big">
                    <textarea
                      [formControl]="queryControl"
                      class="input input_textarea input_fill"
                      rows="4"
                      [placeholder]="example"
                      [appAutofocus]="true"
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="table-form__footer">
          <div class="table-form-footer">
            <div class="table-form-footer__main"></div>
            <div class="table-form-footer__right">
              <button
                type="submit"
                class="table-form-button table-form-button_red table-form-footer__item"
                [class.table-form-button_disabled]="queryControl.invalid"
              >
                <span class="table-form-button__icon icon-play_filled"></span>
                <span class="table-form-button__label">To SQL</span>
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</ng-template>

<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayPanelClass]="['overlay', 'overlay_horizontal_center']"
  [cdkConnectedOverlayPositions]="colorPositions"
  [cdkConnectedOverlayOrigin]="color_trigger"
  [cdkConnectedOverlayOpen]="colorOpened"
  [cdkConnectedOverlayPush]="true"
  [cdkConnectedOverlayGrowAfterOpen]="true"
  [cdkConnectedOverlayHasBackdrop]="true"
  [cdkConnectedOverlayBackdropClass]="'popover2-backdrop'"
  (backdropClick)="setOpened(false)"
  (detach)="setOpened(false)"
>
  <div class="compact-inputs-overlay overlay-content overlay-content_animated theme_dark">
    <app-color-selector-advanced *ngIf="!colorInputControl" [formControl]="colorControl"></app-color-selector-advanced>

    <app-color-with-input-selector
      *ngIf="colorInputControl"
      [colorControl]="colorControl"
      [colorInputEnabledControl]="colorInputEnabledControl"
      [colorInputControl]="colorInputControl"
      [viewContext]="viewContext"
    ></app-color-with-input-selector>
  </div>
</ng-template>

<div class="compact-color-button" cdkOverlayOrigin #color_trigger="cdkOverlayOrigin" (click)="setOpened(true)">
  <div class="compact-color-button__fill compact-color-button__fill_transparent"></div>
  <div class="compact-color-button__fill" [style.background]="colorBackground"></div>
</div>

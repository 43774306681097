import { Injectable } from '@angular/core';
import clone from 'lodash/clone';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { PAGE_PARAM } from '@modules/models';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { ListStore, ListStoreFetchResponse } from '@shared';

import { Snapshot } from '../data/snapshot';
import { SnapshotService } from '../services/snapshot/snapshot.service';

@Injectable()
export class SnapshotStore extends ListStore<Snapshot> {
  params = {};
  createAllowed = false;

  constructor(
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private snapshotService: SnapshotService
  ) {
    super();
  }

  paramsForPage(page) {
    const params = clone(this.params);
    params[PAGE_PARAM] = page;
    params['perPage'] = this.perPage;
    return params;
  }

  fetchPage(page: number, next: boolean): Observable<ListStoreFetchResponse<Snapshot>> {
    const params = this.paramsForPage(page);

    return this.snapshotService
      .get(this.currentProjectStore.instance.uniqueName, this.currentEnvironmentStore.instance.uniqueName, params)
      .pipe(
        map(response => {
          this.createAllowed = response.createAllowed;

          return {
            items: response.results,
            hasMore: page < response.numPages,
            totalPages: response.numPages,
            perPage: response.perPage,
            count: response.count
          };
        })
      );
  }
}

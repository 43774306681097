import { Injectable, OnDestroy } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, from, Observable, of, ReplaySubject, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

import { NotificationService } from '@common/notifications';

interface ColorSelectionResult {
  sRGBHex: string;
}

@Injectable({
  providedIn: 'root'
})
export class PickColorService implements OnDestroy {
  private pickSubscriptions = new BehaviorSubject<Subscription[]>([]);

  constructor(private notificationService: NotificationService) {}

  ngOnDestroy(): void {}

  pick(): Observable<string> {
    if (!('EyeDropper' in window)) {
      this.notificationService.error(
        'Eyedropper is not supported',
        'Your browser does not support the EyeDropper API yet'
      );

      return of(undefined);
    }

    const obs$ = new ReplaySubject<string>();
    // @ts-ignore
    const eyeDropper = new window.EyeDropper();

    const subscription = from<ColorSelectionResult>(eyeDropper.open())
      .pipe(untilDestroyed(this))
      .subscribe(
        result => {
          obs$.next(result.sRGBHex);
          this.removePickSubscription(subscription);
        },
        error => {
          obs$.error(error);
          this.removePickSubscription(subscription);
        }
      );

    this.addPickSubscription(subscription);

    return obs$.asObservable();
  }

  isPicking$(): Observable<boolean> {
    return this.pickSubscriptions.pipe(map(value => value.length > 0));
  }

  addPickSubscription(subscription: Subscription) {
    const value = [...this.pickSubscriptions.value, subscription];
    this.pickSubscriptions.next(value);
  }

  removePickSubscription(subscription: Subscription) {
    const value = this.pickSubscriptions.value.filter(item => item !== subscription);
    this.pickSubscriptions.next(value);
  }
}

<form (ngSubmit)="submit()">
  <app-popup2 [size]="'m'">
    <app-popup2-header>
      <app-popup2-title>Change App URL</app-popup2-title>
      <app-popup2-description>This will change your App URL name.</app-popup2-description>
    </app-popup2-header>

    <app-page-block *ngIf="domain">
      <div class="field">
        <label class="field__label field__label_bright">
          Domain
        </label>

        <ng-container *ngIf="!domain.custom">
          <div class="input-icon-wrapper" style="cursor: text;" (click)="onSubdomainInputClick()">
            <app-loader-small *ngIf="subdomainIsUsedLoading" class="input-icon input-icon_left"></app-loader-small>
            <span *ngIf="!subdomainIsUsedLoading" class="input-icon input-icon_left icon-earth_planet"></span>

            <div class="input input_fill input_icon-left input_template">
              <div *ngIf="!(form.controls.subdomain.value | appIsSet)" class="input__template-placeholder">
                domain
              </div>

              <div
                class="input__template-value input__template-value_flexible-width"
                [style.width.px]="!(form.controls.subdomain.value | appIsSet) ? 48 : null"
                contenteditable="true"
                (input)="setSubdomainValue(subdomain_input.innerText)"
                (click)="$event.stopPropagation()"
                #subdomain_input
              >
                {{ form.controls.subdomain.value }}
              </div>

              <div class="input__template-postfix input__template-postfix_placeholder">.{{ subdomainsBaseDomain }}</div>
            </div>
          </div>

          <div *ngIf="subdomainIsUsed" class="field__errors">
            <div class="field__error">This domain is already used, please try another one</div>
          </div>

          <app-field-errors [form]="form" [fieldName]="'subdomain'"></app-field-errors>

          <div class="field__description">
            <a href="javascript:void(0)" class="field__label-link" (click)="editCustomDomain()">
              <span class="field__label-link-icon icon-plus"></span>
              Connect Custom domain
            </a>
          </div>
        </ng-container>

        <ng-container *ngIf="domain.custom">
          <div class="input-icon-wrapper">
            <span class="input-icon input-icon_left icon-earth_planet"></span>
            <input type="text" class="input input_fill input_icon-left" [value]="domain.domain" disabled />
          </div>

          <div class="field__description">
            <a href="javascript:void(0)" class="field__label-link" (click)="editCustomDomain()">
              <span class="field__label-link-icon icon-pen"></span>
              Change Custom domain
            </a>
          </div>
        </ng-container>
      </div>
    </app-page-block>

    <app-page-block>
      <div class="field">
        <label class="field__label field__label_bright">App URL</label>

        <div class="input-icon-wrapper">
          <app-loader-small *ngIf="uniqueNameIsUsedLoading" class="input-icon input-icon_left"></app-loader-small>
          <span *ngIf="!uniqueNameIsUsedLoading" class="input-icon input-icon_left icon-blocks"></span>

          <div class="input input_fill input_icon-left input_template">
            <div class="input__template-prefix">{{ appBaseUrl }}</div>
            <input
              class="input__template-value input__template-value_fill"
              [formControl]="form.controls.unique_name"
              placeholder="app_name"
              type="text"
            />
          </div>
        </div>

        <div *ngIf="uniqueNameIsUsed" class="field__errors">
          <div class="field__error">This APP URL is already used, please try another one</div>
        </div>

        <app-field-errors [form]="form" [fieldName]="'unique_name'"></app-field-errors>
      </div>
    </app-page-block>

    <app-page-block>
      <app-alert [warning]="true"> Please note that current App URLs <strong>will stop working</strong> </app-alert>
    </app-page-block>

    <app-page-block>
      <app-form-errors [form]="form" [manualMargin]="true"></app-form-errors>
    </app-page-block>

    <app-popup2-footer>
      <app-page-block>
        <app-stack [align]="'right'">
          <a href="javascript:void(0)" class="button button_danger-secondary popup2__footer-item" (click)="close()"
            >Cancel</a
          >

          <button
            type="submit"
            class="button popup2__footer-item"
            [class.button_danger]="form.valid"
            [class.button_disabled]="
              form.invalid ||
              uniqueNameIsUsed ||
              uniqueNameIsUsedLoading ||
              subdomainIsUsed ||
              subdomainIsUsedLoading ||
              !form.hasChanges() ||
              loading
            "
          >
            <app-loader-small *ngIf="loading" class="button__icon button__icon_left"></app-loader-small>
            <span class="button__label">Change App URL</span>
          </button>
        </app-stack>
      </app-page-block>
    </app-popup2-footer>
  </app-popup2>
</form>

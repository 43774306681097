var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ChangeDetectorRef, EventEmitter, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest, merge, ReplaySubject } from 'rxjs';
import { UniqueIdToken } from '@common/unique-id';
import { ApiType, ServerRequestError } from '@modules/api';
import { ViewContext, ViewContextElement } from '@modules/customize';
import { applyParamInput, deserializeMultipleFileValue, ImageOutputFormat, Input as FieldInput, MultipleFileStoreFormat } from '@modules/fields';
import { controlValue, getExtension, getFilename, getFilenameWithExtension, isSet, openUrl } from '@shared';
import { cropFormats } from '../image-field/image-field.crop';
export var UPLOAD_RESULT_OUTPUT = 'upload_result';
export var FILE_NAME_OUTPUT = 'file_name';
export var FILE_EXTENSION_OUTPUT = 'file_extension';
var BaseMultipleUploadComponent = /** @class */ (function () {
    function BaseMultipleUploadComponent(currentProjectStore, currentEnvironmentStore, resourceControllerService, storageService, cd) {
        this.currentProjectStore = currentProjectStore;
        this.currentEnvironmentStore = currentEnvironmentStore;
        this.resourceControllerService = resourceControllerService;
        this.storageService = storageService;
        this.cd = cd;
        this.autofocus = false;
        this.disabled = false;
        this.background = false;
        this.resize = false;
        this.editor = true;
        this.compact = false;
        this.uploadedUpdated = new EventEmitter();
        this.idToken = new UniqueIdToken();
        this.uploadedFiles = [];
        this.storage$ = new BehaviorSubject(undefined);
        this.storageResource$ = new BehaviorSubject(undefined);
    }
    BaseMultipleUploadComponent.prototype.ngOnInit = function () {
        var _this = this;
        combineLatest(this.storageResource$, controlValue(this.control))
            .pipe(untilDestroyed(this))
            .subscribe(function (_a) {
            var storageResource = _a[0], value = _a[1];
            var values = deserializeMultipleFileValue(value);
            _this.uploadedFiles = values.map(function (item) {
                item = _this.getUploadUrl(item, storageResource);
                return _this.getUploadedFileFromValue(item);
            });
            _this.cd.markForCheck();
            _this.onUploadedUpdated();
        });
    };
    BaseMultipleUploadComponent.prototype.ngOnDestroy = function () { };
    BaseMultipleUploadComponent.prototype.ngOnChanges = function (changes) {
        if (changes['storageResourceName'] || changes['storageName']) {
            this.updateStorage();
        }
    };
    BaseMultipleUploadComponent.prototype.getUploadUrl = function (value, storageResource) {
        if (!isSet(value)) {
            return;
        }
        // const apiInfo = this.getApiInfo();
        var apiInfo = storageResource ? storageResource.apiInfo : undefined;
        if (apiInfo &&
            apiInfo.type == ApiType.JetDjango &&
            apiInfo.versionLessThan('0.7.7')
        // !this.field.params['ignore_old_django_format']
        ) {
            value = value['value'];
        }
        if (this.outputFormat == ImageOutputFormat.Storage) {
            var controller = storageResource ? this.resourceControllerService.get(storageResource.type) : undefined;
            if (controller && controller.fileUrl) {
                return controller.fileUrl(storageResource, value);
            }
        }
        return value;
    };
    BaseMultipleUploadComponent.prototype.updateStorage = function () {
        var storage = this.currentProjectStore.instance.getStorage(this.currentEnvironmentStore.instance.uniqueName, this.storageResourceName, this.storageName, { defaultFirst: true });
        this.storageResource$.next(storage ? storage.resource : undefined);
        this.storage$.next(storage ? storage.storage : undefined);
    };
    BaseMultipleUploadComponent.prototype.getUploadedFileFromValue = function (value) {
        if (!value) {
            return;
        }
        return {
            url: value,
            filename: getFilenameWithExtension(value),
            extension: getExtension(value)
        };
        // if (this.contextElement) {
        //   this.contextElement.setOutputValue(FILE_NAME_OUTPUT, getFilename(this.preview));
        //   this.contextElement.setOutputValue(FILE_EXTENSION_OUTPUT, this.previewExtension);
        // }
        //   },
        //   () => {
        //     this.preview = undefined;
        //     this.previewFilename = undefined;
        //     this.previewExtension = undefined;
        //     this.cd.markForCheck();
        //     this.onPreviewUpdate(this.preview);
        //
        //     if (this.contextElement) {
        //       this.contextElement.setOutputValue(FILE_NAME_OUTPUT, this.previewFilename);
        //       this.contextElement.setOutputValue(FILE_EXTENSION_OUTPUT, this.previewExtension);
        //     }
        //   }
        // );
    };
    BaseMultipleUploadComponent.prototype.onUploadedUpdated = function () { };
    // isUploadable() {
    //   return (
    //     (!this.field.params['output_format'] || this.field.params['output_format'] == ImageOutputFormat.Storage) &&
    //     !this.field.params['external']
    //   );
    // }
    BaseMultipleUploadComponent.prototype.isUploadable = function () {
        return this.outputFormat != ImageOutputFormat.URL;
    };
    BaseMultipleUploadComponent.prototype.setControlValue = function (value) {
        var newValue;
        if (this.storeFormat == MultipleFileStoreFormat.String) {
            newValue = value.join(',');
        }
        else {
            newValue = value;
        }
        this.control.patchValue(newValue);
    };
    BaseMultipleUploadComponent.prototype.clearCurrentValue = function (index) {
        if (this.control) {
            var values = deserializeMultipleFileValue(this.control.value);
            values.splice(index, 1);
            this.setControlValue(values);
        }
    };
    BaseMultipleUploadComponent.prototype.onFileChange = function (el) {
        if (!el.files.length) {
            return;
        }
        var file = el.files[0];
        el.value = null;
        this.onFileSelected(file);
    };
    BaseMultipleUploadComponent.prototype.getUploadPath = function (file) {
        var _a;
        if (this.path) {
            try {
                var result = applyParamInput(this.path, {
                    context: this.context,
                    contextElement: this.contextElement,
                    localContext: (_a = {},
                        _a[FILE_NAME_OUTPUT] = getFilename(file.name),
                        _a[FILE_EXTENSION_OUTPUT] = getExtension(file.name),
                        _a),
                    defaultValue: ''
                });
                if (isSet(result)) {
                    result = String(result);
                }
                return result;
            }
            catch (e) {
                return '';
            }
            // } else if (this.context['modelDescription']) {
            //   return [this.context['modelDescription'].model, this.field.name].join('/');
            // } else if (this.context['action']) {
            //   return ['actions', this.context['action'], this.field.name].join('/');
        }
        else {
            return '';
        }
    };
    BaseMultipleUploadComponent.prototype.onFileSelected = function (file, index) {
        var path = this.getUploadPath(file);
        this.uploadFile(file, path, index).pipe(untilDestroyed(this)).subscribe();
    };
    BaseMultipleUploadComponent.prototype.onFilesSelected = function (files, index) {
        var _this = this;
        merge.apply(void 0, files.map(function (file) {
            var path = _this.getUploadPath(file);
            return _this.uploadFile(file, path, index);
        })).pipe(untilDestroyed(this))
            .subscribe();
    };
    BaseMultipleUploadComponent.prototype.uploadFile = function (file, path, index) {
        var _this = this;
        var obs$ = new ReplaySubject();
        if (isSet(index)) {
            this.uploadedFiles[index] = {
                url: undefined,
                filename: undefined,
                extension: undefined,
                uploadFileSize: file.size,
                uploadProgress: 0,
                uploadError: undefined
            };
        }
        else {
            this.upload = {
                uploadFileSize: file.size,
                uploadProgress: 0,
                uploadError: undefined
            };
        }
        this.cd.markForCheck();
        this.onUploadedUpdated();
        var storageResource = this.storageResource$.value;
        var storage = this.storage$.value;
        if (!storageResource || !storage) {
            obs$.error(undefined);
            obs$.complete();
            return obs$.asObservable();
        }
        this.storageService
            .upload(storageResource, storage, storage.uploadQuery, file, path)
            .pipe(untilDestroyed(this))
            .subscribe(function (response) {
            if (!response) {
                if (isSet(index)) {
                    _this.uploadedFiles[index] = __assign({}, _this.uploadedFiles[index], { uploadProgress: undefined });
                }
                else {
                    _this.upload = undefined;
                }
                _this.cd.markForCheck();
                if (_this.contextElement) {
                    _this.contextElement.setOutputValue(UPLOAD_RESULT_OUTPUT, undefined);
                }
                obs$.error(undefined);
                obs$.complete();
                return;
            }
            if (response.result) {
                var value_1;
                // if (this.form) {
                // const params = {};
                // const apiInfo = this.getApiInfo();
                var apiInfo = storageResource ? storageResource.apiInfo : undefined;
                if (apiInfo &&
                    apiInfo.type == ApiType.JetDjango &&
                    apiInfo.versionLessThan('0.7.7')
                // !this.field.params['ignore_old_django_format']
                ) {
                    value_1 = {
                        value: response.result.uploadedPath,
                        url: response.result.uploadedUrl
                    };
                }
                else {
                    value_1 = response.result.uploadedUrl;
                }
                if (isSet(index)) {
                    _this.uploadedFiles[index] = __assign({}, _this.uploadedFiles[index], { uploadProgress: undefined });
                }
                else {
                    _this.upload = undefined;
                }
                _this.cd.markForCheck();
                if (_this.control) {
                    var values = deserializeMultipleFileValue(_this.control.value);
                    var newValue = void 0;
                    if (isSet(index)) {
                        newValue = values.map(function (item, i) { return (i === index ? value_1 : item); });
                    }
                    else {
                        newValue = values.concat([value_1]);
                    }
                    _this.setControlValue(newValue);
                }
                _this.uploadedUpdated.next();
                if (_this.contextElement) {
                    _this.contextElement.setOutputValue(UPLOAD_RESULT_OUTPUT, response.result.response ? response.result.response.body : undefined);
                }
                obs$.next(value_1);
                obs$.complete();
            }
            else {
                var progress = response.state.uploadProgress * 0.95 + response.state.downloadProgress * 0.05;
                if (isSet(index)) {
                    _this.uploadedFiles[index] = __assign({}, _this.uploadedFiles[index], { uploadProgress: progress });
                }
                else {
                    _this.upload = __assign({}, _this.upload, { uploadProgress: progress });
                }
                _this.cd.markForCheck();
                if (_this.contextElement) {
                    _this.contextElement.setOutputValue(UPLOAD_RESULT_OUTPUT, undefined);
                }
            }
        }, function (error) {
            var uploadError;
            if (error instanceof ServerRequestError && error.nonFieldErrors.length) {
                uploadError = error.nonFieldErrors[0];
            }
            else {
                uploadError = 'Unknown error';
            }
            if (isSet(index)) {
                _this.uploadedFiles[index] = __assign({}, _this.uploadedFiles[index], { uploadProgress: undefined, uploadError: uploadError });
            }
            else {
                _this.upload = __assign({}, _this.upload, { uploadProgress: undefined, uploadError: uploadError });
            }
            _this.cd.markForCheck();
            if (_this.contextElement) {
                _this.contextElement.setOutputValue(UPLOAD_RESULT_OUTPUT, undefined);
            }
            obs$.error(error);
        });
        return obs$.asObservable();
    };
    BaseMultipleUploadComponent.prototype.download = function (index) {
        var uploadedFile = this.uploadedFiles[index];
        if (uploadedFile) {
            openUrl(uploadedFile.url, true);
        }
    };
    return BaseMultipleUploadComponent;
}());
export { BaseMultipleUploadComponent };

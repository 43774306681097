import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-sidebar-list-separator',
  templateUrl: './sidebar-list-separator.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SidebarListSeparatorComponent implements OnInit {
  @Input() title: string;

  constructor() {}

  ngOnInit() {}
}

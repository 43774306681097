/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../shared/components/loader-small/loader-small.component.ngfactory";
import * as i2 from "../../../../../shared/components/loader-small/loader-small.component";
import * as i3 from "@angular/common";
import * as i4 from "../../../../../common/tips/directives/tip/tip.directive";
import * as i5 from "../../../../../common/editable-content/components/editable-content/editable-content.component.ngfactory";
import * as i6 from "../../../../../common/editable-content/components/editable-content/editable-content.component";
import * as i7 from "./customize-workflow-step-header.component";
import * as i8 from "../../../services/workflow-edit-context/workflow-edit.context";
var styles_CustomizeWorkflowStepHeaderComponent = [];
var RenderType_CustomizeWorkflowStepHeaderComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CustomizeWorkflowStepHeaderComponent, data: {} });
export { RenderType_CustomizeWorkflowStepHeaderComponent as RenderType_CustomizeWorkflowStepHeaderComponent };
function View_CustomizeWorkflowStepHeaderComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-loader-small", [["class", "sidebar-header2-button__icon sidebar-header2-button__icon_left"], ["style", "font-size: 13px;"]], null, null, null, i1.View_LoaderSmallComponent_0, i1.RenderType_LoaderSmallComponent)), i0.ɵdid(1, 114688, null, 0, i2.LoaderSmallComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
function View_CustomizeWorkflowStepHeaderComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "span", [["class", "sidebar-header2-button__icon sidebar-header2-button__icon_left icon-play"]], null, null, null, null, null))], null, null); }
function View_CustomizeWorkflowStepHeaderComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "div", [["class", "sidebar-header2-button sidebar-header2-button_orange sidebar-header2__top-item"]], [[2, "sidebar-header2-button_disabled", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.execute.emit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CustomizeWorkflowStepHeaderComponent_2)), i0.ɵdid(2, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CustomizeWorkflowStepHeaderComponent_3)), i0.ɵdid(4, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(5, 0, null, null, 1, "span", [["class", "sidebar-header2-button__label"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Test Step"]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.executeLoading; _ck(_v, 2, 0, currVal_1); var currVal_2 = !_co.executeLoading; _ck(_v, 4, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.executeLoading; _ck(_v, 0, 0, currVal_0); }); }
function View_CustomizeWorkflowStepHeaderComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["class", "sidebar-header2-button sidebar-header2__top-item"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.delete.emit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 737280, null, 0, i4.TipDirective, [i0.ElementRef, i0.ComponentFactoryResolver, i0.Injector, i0.ApplicationRef], { content: [0, "content"], optionsRaw: [1, "optionsRaw"] }, null), i0.ɵpod(2, { side: 0 }), (_l()(), i0.ɵeld(3, 0, null, null, 0, "span", [["class", "sidebar-header2-button__icon icon-bin"]], null, null, null, null, null))], function (_ck, _v) { var currVal_0 = "Delete Step"; var currVal_1 = _ck(_v, 2, 0, "bottom"); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_CustomizeWorkflowStepHeaderComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "sidebar-header2__title sidebar-header2__title_editable"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "app-editable-content", [], null, [[null, "valueChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("valueChange" === en)) {
        var pd_0 = (_co.nameControl.patchValue($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_EditableContentComponent_0, i5.RenderType_EditableContentComponent)), i0.ɵdid(2, 770048, null, 0, i6.EditableContentComponent, [i0.ChangeDetectorRef, i0.NgZone], { value: [0, "value"], pen: [1, "pen"], penHover: [2, "penHover"], penTitle: [3, "penTitle"], cleanValue: [4, "cleanValue"] }, { valueChange: "valueChange" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.nameControl.value; var currVal_1 = true; var currVal_2 = true; var currVal_3 = "Rename step"; var currVal_4 = _co.titleCleanValue; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }, null); }
function View_CustomizeWorkflowStepHeaderComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["class", "sidebar-header2__title"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.nameValue; _ck(_v, 1, 0, currVal_0); }); }
export function View_CustomizeWorkflowStepHeaderComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 16, "div", [["class", "sidebar__header2"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 15, "div", [["class", "sidebar-header2"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 10, "div", [["class", "sidebar-header2__top"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 4, "div", [["class", "sidebar-header2__top-main"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 3, "div", [["class", "sidebar-header2-button sidebar-header2__top-item"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close.emit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 0, "span", [["class", "sidebar-header2-button__icon sidebar-header2-button__icon_left icon-close"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 1, "span", [["class", "sidebar-header2-button__label"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Close"])), (_l()(), i0.ɵeld(8, 0, null, null, 4, "div", [["class", "sidebar-header2__top-right"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CustomizeWorkflowStepHeaderComponent_1)), i0.ɵdid(10, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CustomizeWorkflowStepHeaderComponent_4)), i0.ɵdid(12, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CustomizeWorkflowStepHeaderComponent_5)), i0.ɵdid(14, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CustomizeWorkflowStepHeaderComponent_6)), i0.ɵdid(16, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.executeEnabled; _ck(_v, 10, 0, currVal_0); var currVal_1 = _co.deleteEnabled; _ck(_v, 12, 0, currVal_1); var currVal_2 = _co.nameControl; _ck(_v, 14, 0, currVal_2); var currVal_3 = !_co.nameControl; _ck(_v, 16, 0, currVal_3); }, null); }
export function View_CustomizeWorkflowStepHeaderComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-customize-workflow-step-header", [], null, null, null, View_CustomizeWorkflowStepHeaderComponent_0, RenderType_CustomizeWorkflowStepHeaderComponent)), i0.ɵdid(1, 114688, null, 0, i7.CustomizeWorkflowStepHeaderComponent, [i8.WorkflowEditContext], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CustomizeWorkflowStepHeaderComponentNgFactory = i0.ɵccf("app-customize-workflow-step-header", i7.CustomizeWorkflowStepHeaderComponent, View_CustomizeWorkflowStepHeaderComponent_Host_0, { step: "step", nameControl: "nameControl", nameValue: "nameValue", deleteEnabled: "deleteEnabled", executeEnabled: "executeEnabled", executeLoading: "executeLoading" }, { close: "close", delete: "delete", execute: "execute" }, []);
export { CustomizeWorkflowStepHeaderComponentNgFactory as CustomizeWorkflowStepHeaderComponentNgFactory };

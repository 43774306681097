var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { OnChanges, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { CustomizeService } from '@modules/customize';
var CustomizeHandlerHeaderCenterDirective = /** @class */ (function () {
    function CustomizeHandlerHeaderCenterDirective(tpl, customizeService) {
        this.tpl = tpl;
        this.customizeService = customizeService;
    }
    CustomizeHandlerHeaderCenterDirective.prototype.ngOnInit = function () { };
    CustomizeHandlerHeaderCenterDirective.prototype.ngOnDestroy = function () {
        if (this.customizeService.handlerHeaderCenter === this.tpl) {
            this.customizeService.handlerHeaderCenter = undefined;
        }
    };
    CustomizeHandlerHeaderCenterDirective.prototype.ngOnChanges = function (changes) {
        if (changes.options) {
            this.updateTemplate();
        }
    };
    CustomizeHandlerHeaderCenterDirective.prototype.getOptions = function () {
        return __assign({ enabled: true }, this.options);
    };
    CustomizeHandlerHeaderCenterDirective.prototype.updateTemplate = function () {
        var options = this.getOptions();
        this.customizeService.handlerHeaderCenter = options.enabled ? this.tpl : undefined;
    };
    return CustomizeHandlerHeaderCenterDirective;
}());
export { CustomizeHandlerHeaderCenterDirective };

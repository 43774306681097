import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import shuffle from 'lodash/shuffle';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest, EMPTY, Observable, of } from 'rxjs';
import { debounceTime, map, switchMap, tap } from 'rxjs/operators';

import { ThinDialogPopupComponent } from '@common/dialog-popup';
import { DialogService } from '@common/dialogs';
import { PopupService } from '@common/popups';
import { AdminMode } from '@modules/admin-mode';
import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { isTokenExpired, serializeTokenOptions, TokenOptions } from '@modules/api';
import { AuthService, TOKEN_FRAGMENT_KEY } from '@modules/auth';
import { Domain } from '@modules/domain';
import { MetaService } from '@modules/meta';
import { Project, ProjectDeployment, ProjectService, ProjectsStore, resourceTypeItems } from '@modules/projects';
import { ROUTE_LOADED_PROJECTS_STORE } from '@modules/projects-components';
import { RoutingService } from '@modules/routing';
import { TemplateService, TemplateType } from '@modules/template';
import {
  adminPanelTemplateItem,
  TemplateItem,
  TemplateItemType,
  TemplatePreviewComponent,
  toTemplateItem
} from '@modules/template-components';
import { CurrentUserStore } from '@modules/users';
import { controlValue, isSet, KeyboardEventKeyCode, Link } from '@shared';

import { ProjectCreateController } from '../../services/project-create/project-create.controller';
import {
  PROJECT_CREATE_TEMPLATE_ITEM,
  TemplateItemSerialized
} from '../project-create-popup/project-create-popup.component';

function filterItems<T>(items: T[], lookups: (item: T) => string[], search: string): T[] {
  if (!isSet(items)) {
    return;
  }

  if (!isSet(search)) {
    return items;
  }

  return items.filter(item => {
    return lookups(item).some(str => String(str).toLowerCase().indexOf(search) != -1);
  });
}

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProjectsComponent implements OnInit, OnDestroy {
  loading = true;
  searchControl = new FormControl('');
  projects$ = new BehaviorSubject<Project[]>([]);
  projectsFiltered: Project[];
  templates: TemplateItem[] = [];
  featuredTemplates$ = new BehaviorSubject<TemplateItem[]>([]);
  featuredTemplatesFiltered: TemplateItem[] = [];
  itemHovered = false;
  openBuilderLink: any[];
  domain: Domain;
  externalToken: TokenOptions;
  analyticsEvents = AnalyticsEvent;

  constructor(
    public projectsStore: ProjectsStore,
    private projectService: ProjectService,
    private projectCreateController: ProjectCreateController,
    public currentUserStore: CurrentUserStore,
    private routing: RoutingService,
    private metaService: MetaService,
    private cd: ChangeDetectorRef,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private templateService: TemplateService,
    private popupService: PopupService,
    private dialogService: DialogService,
    private analyticsService: UniversalAnalyticsService
  ) {}

  ngOnInit() {
    this.metaService.set({ title: 'My Projects' });

    const reloadProjects = !this.routing.popRouterAttr(ROUTE_LOADED_PROJECTS_STORE);

    this.fetchProjects(reloadProjects);
    this.initSearch();
    this.initQueryParams();
  }

  ngOnDestroy(): void {}

  fetchProjects(reload = true): void {
    this.loading = true;
    this.cd.markForCheck();

    combineLatest(
      this.projectsStore.get(reload),
      this.activatedRoute.data.pipe(map(data => data.domain as Domain)),
      this.templateService.get({ type: TemplateType.Page }),
      this.getExternalToken()
    )
      .pipe(
        map(([projects, domain, templates]) => {
          projects = (projects || []).filter(project => {
            return project.environments.some(item => project.hasEnvironmentPermissions(item));
          });

          if (domain && domain.whiteLabel && domain.domain) {
            projects = projects.filter(item => item.isDomain(domain));
          }

          projects = this.projectService.sortProjectsLastUsed(projects);

          return { projects: projects, domain: domain, templates: templates };
        }),
        untilDestroyed(this)
      )
      .subscribe(
        ({ projects, domain, templates }) => {
          const templateItems = templates.map(item => toTemplateItem(item));
          const featuredTemplates = shuffle([
            adminPanelTemplateItem,
            ...templateItems.filter(item => item.template.featured)
          ]);
          const otherTemplates = shuffle(templateItems.filter(item => !item.template.featured));

          this.projects$.next(projects);
          this.domain = domain;
          this.templates = [...featuredTemplates, ...otherTemplates];
          this.featuredTemplates$.next(featuredTemplates);
          this.refreshFiltered();
          this.loading = false;
          this.cd.markForCheck();
        },
        () => {
          this.loading = false;
          this.cd.markForCheck();
        }
      );

    this.currentUserStore
      .get()
      .pipe(untilDestroyed(this))
      .subscribe(() => this.cd.detectChanges());
  }

  initSearch() {
    combineLatest(
      this.projects$,
      this.featuredTemplates$,
      controlValue<string>(this.searchControl).pipe(debounceTime(60))
    )
      .pipe(untilDestroyed(this))
      .subscribe(([projects, templates, search]) => this.updateFiltered(projects, templates, search));
  }

  updateFiltered(projects: Project[], templates: TemplateItem[], search: string) {
    const searchClean = search.toLowerCase().trim();

    this.projectsFiltered = filterItems<Project>(projects, item => [item.name, item.uniqueName], searchClean);
    this.featuredTemplatesFiltered = filterItems<TemplateItem>(templates, item => [item.name], searchClean);
    this.cd.markForCheck();
  }

  refreshFiltered() {
    this.updateFiltered(this.projects$.value, this.featuredTemplates$.value, this.searchControl.value);
  }

  resetSearch() {
    this.searchControl.patchValue('');
    this.refreshFiltered();
  }

  onSearchKey(e) {
    if (e.keyCode == KeyboardEventKeyCode.Escape) {
      this.resetSearch();
    }
  }

  initQueryParams() {
    this.activatedRoute.queryParams.pipe(untilDestroyed(this)).subscribe(params => {
      this.openBuilderLink = isSet(params['builder_link']) ? params['builder_link'].split('/') : undefined;
      this.cd.markForCheck();
    });

    this.activatedRoute.queryParams
      .pipe(
        switchMap(params => {
          if (params['apply_template']) {
            return this.templateService.getDetail(params['apply_template']).pipe(
              map(template => {
                if (!template) {
                  return;
                }

                return toTemplateItem(template);
              })
            );
          } else if (params['apply_template_admin_panel']) {
            return of(adminPanelTemplateItem);
          } else if (params['apply_template_admin_panel_resource']) {
            const resource = resourceTypeItems.find(item => item.name == params['apply_template_admin_panel_resource']);
            return of({
              ...adminPanelTemplateItem,
              resource: resource
            });
          } else if (params['create']) {
            return of(undefined);
          } else {
            return EMPTY;
          }
        }),
        untilDestroyed(this)
      )
      .subscribe(item => {
        if (!item) {
          this.createNewProject(item);
          return;
        }

        if (item.type == TemplateItemType.AdminPanel) {
          this.createNewProject(item);
        } else {
          this.createTemplateProject(item);
        }

        this.analyticsService.sendSimpleEvent(AnalyticsEvent.Template.Apply, {
          TemplateID: item.name,
          ResourceType: item.resource ? item.resource.name : undefined,
          Source: 'external'
        });
      });
  }

  setItemHovered(value) {
    this.itemHovered = value;
    this.cd.markForCheck();
  }

  get isWhiteLabel() {
    return this.domain && this.domain.whiteLabel;
  }

  createNewProject(applyTemplateItem?: TemplateItem, force?: boolean) {
    const project$ =
      applyTemplateItem && force
        ? this.createProjectForTemplate(applyTemplateItem)
        : this.projectCreateController.openCreatePopup({
            applyTemplateItem: applyTemplateItem,
            domain: this.domain,
            dark: true
          });

    project$
      .pipe(
        switchMap(result => {
          let link: any[];

          if (this.openBuilderLink) {
            link = this.openBuilderLink;
          } else if (result.deployment == ProjectDeployment.OnPremise) {
            link = result.onPremiseLink;
          } else {
            link = result.homeLink;
          }

          return this.getCreatedProjectParams(result, link);
        }),
        untilDestroyed(this)
      )
      .subscribe(({ project, link, fragment }) => {
        const queryParams = {};

        if (applyTemplateItem) {
          const serialized: TemplateItemSerialized = {
            type: applyTemplateItem.type,
            resource: applyTemplateItem.resource,
            template: applyTemplateItem.template ? applyTemplateItem.template.id : undefined
          };

          queryParams[PROJECT_CREATE_TEMPLATE_ITEM] = JSON.stringify(serialized);
        }

        this.routing.navigateLink(link, { queryParams: queryParams, queryParamsHandling: 'merge', fragment: fragment });

        this.onProjectCreated(project, applyTemplateItem);
      });
  }

  getCreatedProjectParams(
    project: Project,
    appLink: any[] = []
  ): Observable<{ project: Project; link: Link; fragment?: string }> {
    const link = project.linkWithProtocol(appLink, {
      environmentName: project.defaultEnvironment ? project.defaultEnvironment.uniqueName : 'prod',
      mode: AdminMode.Builder
    });

    if (!link.href) {
      return of({ project: project, link: link });
    }

    return this.getExternalToken().pipe(
      map(token => {
        const fragment = this.getExternalTokenFragment(token);

        return {
          project: project,
          link: link,
          fragment: fragment
        };
      })
    );
  }

  getExternalTokenFragment(token: TokenOptions) {
    const tokenStr = btoa(JSON.stringify(serializeTokenOptions(token)));
    return `${TOKEN_FRAGMENT_KEY}=${tokenStr}`;
  }

  getExternalToken(): Observable<TokenOptions> {
    if (this.externalToken && !isTokenExpired(this.externalToken.accessTokenExpires)) {
      return of(this.externalToken);
    }

    return this.authService.generateToken().pipe(tap(token => (this.externalToken = token)));
  }

  previewTemplate(template: TemplateItem) {
    this.analyticsService.sendSimpleEvent(AnalyticsEvent.Template.Viewed, {
      TemplateID: template.name,
      Source: 'home_templates'
    });

    this.popupService.push({
      component: TemplatePreviewComponent,
      popupComponent: ThinDialogPopupComponent,
      popupComponentCloseWithoutConfirm: true,
      inputs: {
        templates: this.templates,
        selectedTemplate: template,
        cancel: true,
        analyticsSource: ''
      },
      outputs: {
        templateApply: [
          result => {
            if (result.template) {
              this.createTemplateProject(result);
            } else {
              this.createNewProject(result, true);
            }
          }
        ],
        adminPanelTemplateApply: [
          result => {
            this.createNewProject(result, true);
          }
        ]
      }
    });
  }

  createProjectForTemplate(template: TemplateItem): Observable<Project> {
    const logoFile$: Observable<File> = isSet(template.logo)
      ? this.projectService.getLogoFile(template.logo)
      : of(undefined);

    return logoFile$.pipe(
      switchMap(logoFile => {
        const instance = new Project();
        const name = this.projectService.popNewProjectName();

        instance.name = isSet(name) ? name : template.name;
        instance.logoColor = template.color;
        instance.logoFile = logoFile;
        instance.logoFill = template.logoFill;

        return this.projectService.create(instance, undefined, ['name', 'color', 'logo', 'params']);
      })
    );
  }

  createTemplateProject(template: TemplateItem) {
    if (template.template && isSet(template.template.copyProject)) {
      const loadingDialog = this.dialogService.loading({
        title: 'Applying template',
        description: 'We are preparing template for you, please, wait...',
        dark: true
      });

      loadingDialog.closeOnUnsubscribe().pipe(untilDestroyed(this)).subscribe();

      const instance = new Project();

      instance.name = template.name;

      return this.projectService
        .createFromTemplate(instance, template.template.copyProject)
        .pipe(
          switchMap(result => this.getCreatedProjectParams(result)),
          untilDestroyed(this)
        )
        .subscribe(
          ({ project, link, fragment }) => {
            loadingDialog.close();

            this.routing.navigateLink(link, { fragment: fragment });

            this.onProjectCreated(project, template);
          },
          () => {
            loadingDialog.close();
          }
        );
    } else {
      this.createProjectForTemplate(template)
        .pipe(
          switchMap(result => this.getCreatedProjectParams(result, ['templates', 'apply', template.template.id])),
          untilDestroyed(this)
        )
        .subscribe(({ project, link, fragment }) => {
          this.routing.navigateLink(link, { fragment: fragment });

          this.onProjectCreated(project, template);
        });
    }
  }

  onProjectCreated(project: Project, templateItem?: TemplateItem) {
    const onboardingEventParams = {
      ...(templateItem &&
        templateItem.template && {
          TemplateID: templateItem.template.name
        }),
      ...(templateItem &&
        templateItem.type == TemplateItemType.AdminPanel &&
        !templateItem.resource && {
          OnboardingTemplateAdminPanel: true
        }),
      ...(templateItem &&
        templateItem.type == TemplateItemType.AdminPanel &&
        templateItem.resource && {
          OnboardingTemplateAdminPanelResource: templateItem.resource.name
        })
    };

    this.analyticsService.sendSimpleEvent(AnalyticsEvent.Project.ProjectCreated, {
      ProjectID: project.uniqueName,
      About: project.about,
      ...onboardingEventParams
      // Type: value['type']
    });

    this.analyticsService.sendSimpleEvent(AnalyticsEvent.Subscription.SuccessfulSubscribed, {
      ProjectID: project.uniqueName,
      Plan: project.subscription ? project.subscription.plan.uniqueName : undefined,
      Price: project.subscription ? project.subscription.price : undefined,
      FreeTrial: project.subscription ? !!project.subscription.freeTrial : undefined,
      DateBegin:
        project.subscription && project.subscription.dateBegin
          ? project.subscription.dateBegin.toISOString()
          : undefined,
      DateEnd:
        project.subscription && project.subscription.dateEnd ? project.subscription.dateEnd.toISOString() : undefined
    });
  }

  projectsTrackByFn(i, item: Project) {
    return item.uniqueName;
  }

  templatesTrackByFn(i, item: TemplateItem) {
    return [item.type, ...(item.template ? [item.template.id] : [])].join('_');
  }
}

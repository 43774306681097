var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ChangeDetectorRef, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { FieldType, registerFieldComponent } from '@modules/fields';
import { ModelService } from '@modules/model-queries';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { ResourceControllerService } from '@modules/resources';
import { StorageService } from '@modules/storages-queries';
import { UploadFieldComponent } from '../file-field/upload-field.component';
var ImageFieldComponent = /** @class */ (function (_super) {
    __extends(ImageFieldComponent, _super);
    function ImageFieldComponent(modelService, currentProjectStore, currentEnvironmentStore, storageService, resourceControllerService, cd) {
        return _super.call(this, modelService, currentProjectStore, currentEnvironmentStore, storageService, resourceControllerService, cd) || this;
    }
    ImageFieldComponent.prototype.ngOnInit = function () {
        this.updateStorage();
        this.updateUploaded();
        this.initOutputs();
    };
    ImageFieldComponent.prototype.ngOnDestroy = function () { };
    ImageFieldComponent.prototype.ngOnChanges = function (changes) {
        this.updateStorage();
        this.updateUploaded();
    };
    return ImageFieldComponent;
}(UploadFieldComponent));
export { ImageFieldComponent };
registerFieldComponent(FieldType.Image, ImageFieldComponent);

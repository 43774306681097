var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ChangeDetectorRef, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { MenuGeneratorService, SectionMenuItem } from '@modules/menu';
import { BaseMenuItemComponent } from '../base-menu-item/base-menu-item.component';
var SectionMenuItemComponent = /** @class */ (function (_super) {
    __extends(SectionMenuItemComponent, _super);
    function SectionMenuItemComponent(menuGeneratorService, cd) {
        var _this = _super.call(this) || this;
        _this.menuGeneratorService = menuGeneratorService;
        _this.cd = cd;
        _this.children = [];
        return _this;
    }
    SectionMenuItemComponent.prototype.trackMenuItemFn = function (i, item) {
        return item.id;
    };
    SectionMenuItemComponent.prototype.ngOnInit = function () {
        this.updateChildren();
    };
    SectionMenuItemComponent.prototype.ngOnDestroy = function () { };
    SectionMenuItemComponent.prototype.ngOnChanges = function (changes) {
        if (changes.menuItem && !changes.menuItem.firstChange) {
            this.updateChildren();
        }
    };
    SectionMenuItemComponent.prototype.updateChildren = function () {
        this.children = this.menuGeneratorService.cleanMenuItemsAppMode(this.menuItem.children);
        this.cd.markForCheck();
    };
    return SectionMenuItemComponent;
}(BaseMenuItemComponent));
export { SectionMenuItemComponent };

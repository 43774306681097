import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import cloneDeep from 'lodash/cloneDeep';

import { CustomViewSettings } from '@modules/customize';
import { ParameterArray } from '@modules/fields';

@Injectable()
export class CustomPageParametersForm extends FormGroup {
  viewSettings: CustomViewSettings;

  controls: {
    parameters: ParameterArray;
  };

  constructor() {
    super({
      parameters: new ParameterArray([])
    });
  }

  init(viewSettings: CustomViewSettings) {
    this.viewSettings = viewSettings;

    if (viewSettings) {
      this.controls.parameters.patchValue(viewSettings.parameters);
    }
  }

  submit(): CustomViewSettings {
    const instance = cloneDeep(this.viewSettings) as CustomViewSettings;

    instance.parameters = this.controls.parameters.value;

    return instance;
  }
}

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import isEqual from 'lodash/isEqual';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, Observable, of } from 'rxjs';
import { auditTime, debounceTime, filter, first, map, skip, switchMap } from 'rxjs/operators';

import { DialogService } from '@common/dialogs';
import { AppDrag } from '@common/drag-drop2';
import { ActionItem, ActionType } from '@modules/actions';
import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { CustomizeBarItem } from '@modules/change-components';
import {
  cleanElementName,
  ContextItem,
  CustomizeService,
  ElementItem,
  ElementType,
  FieldElementItem,
  FormElementItem,
  FormSubmitElementItem,
  ITEM_OUTPUT,
  SUBMIT_RESULT_OUTPUT,
  VALUE_OUTPUT,
  ViewContext,
  ViewContextElement,
  ViewContextElementType
} from '@modules/customize';
import { DataSourceType } from '@modules/data-sources';
import { BooleanFieldStyle } from '@modules/field-components';
import {
  createFormFieldFactory,
  EditableField,
  getFieldDescriptionByType,
  Input as FieldInput,
  InputValueType
} from '@modules/fields';
import { ModelDescription } from '@modules/models';
import { ActionQuery, QueryType } from '@modules/queries';
import { HttpResultsSection, SqlResultsSection } from '@modules/queries-components';
import { SidebarCollapseContext } from '@modules/sidebar';
import { controlValid, controlValue } from '@shared';

// TODO: Refactor import
import { ElementComponentsService } from '../../../customize-elements/services/element-components/element-components.service';
import { ElementContainerService } from '../../../customize-elements/services/element-container/element-container.service';

import { CustomizeBarEditComponent } from '../../data/customize-bar-edit-component';
import { CustomizeBarEditEvent } from '../../data/customize-bar-edit-event';
import { CustomizeBarEditEventType } from '../../data/customize-bar-edit-event-type';
import { CustomizeBarContext } from '../../services/customize-bar-context/customize-bar.context';
import { ElementConfiguration, trackConfigured } from '../../utils/analytics';
import {
  CustomizeBarActionEditFormAsyncValidator,
  CustomizeBarActionEditFormDefaultType,
  ModelDescriptionInContext
} from '../customize-bar-action-edit/customize-bar-action-edit.form';
import { CUSTOMIZE_BAR_ACTION_EDIT_FORM_PROVIDER } from '../customize-bar-action-edit/customize-bar-action-edit.provider';
import { ModelDescriptionDataSourceControl } from '../model-description-data-source-edit/model-description-data-source';
import { ModelDescriptionDataSourceEditComponent } from '../model-description-data-source-edit/model-description-data-source-edit.component';
import { CustomizeBarFormEditGetQueryComponent } from './customize-bar-form-edit-get-query/customize-bar-form-edit-get-query.component';
import { CustomizeBarFormEditForm, validateAction } from './customize-bar-form-edit.form';

@Component({
  selector: 'app-customize-bar-form-edit',
  templateUrl: './customize-bar-form-edit.component.html',
  providers: [
    ModelDescriptionDataSourceControl,
    CustomizeBarFormEditForm,
    ...CUSTOMIZE_BAR_ACTION_EDIT_FORM_PROVIDER,
    { provide: CustomizeBarActionEditFormDefaultType, useValue: ActionType.Query },
    { provide: CustomizeBarActionEditFormAsyncValidator, useValue: validateAction }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomizeBarFormEditComponent implements OnInit, OnDestroy, CustomizeBarEditComponent {
  @Input() element: FormElementItem;
  @Input() context: ViewContext;
  @Input() contextElement: ViewContextElement;
  @Input() contextElementPath: (string | number)[];
  @Input() contextElementPaths: (string | number)[][];
  @Input() titleEnabled = true;
  @Input() titleEditable: boolean;
  @Input() backLabel = 'Back';
  @Input() deleteEnabled = false;
  @Input() parentElement: any;
  @Input() parentPopup: any;
  @Input() firstInit = false;
  @Input() setupOnCreate = false;
  @Output() event = new EventEmitter<CustomizeBarEditEvent>();

  @ViewChild(ModelDescriptionDataSourceEditComponent) dataSourceEditComponent: ModelDescriptionDataSourceEditComponent;

  createField = createFormFieldFactory();
  collapseContext = new SidebarCollapseContext();
  submitActionClass = ActionItem;
  result: FormElementItem;
  configurationStarted = false;
  actionOperationConfigured = true;
  actionConfigured = true;
  dataConfigured$: Observable<boolean>;
  dataQueryConfigured$: Observable<boolean>;
  operationValid$: Observable<boolean>;
  actionsValid$: Observable<boolean>;
  operationContextElementPaths = [[ITEM_OUTPUT], [VALUE_OUTPUT]];
  visibleContextElementPaths = [[ITEM_OUTPUT]];
  completionContextElementPaths = [[ITEM_OUTPUT], [VALUE_OUTPUT], [SUBMIT_RESULT_OUTPUT]];
  approvalContextElementPaths = [[ITEM_OUTPUT], [VALUE_OUTPUT]];

  customAction = false;
  modelAction: string;
  modelDescription: ModelDescription;
  modelDescriptionInContext: ModelDescriptionInContext;
  itemContextElementPath = [ITEM_OUTPUT];
  fields: FieldElementItem[] = [];
  missingFields: CustomizeBarItem[] = [];
  dataSourceTypes = DataSourceType;
  booleanFieldStyle = BooleanFieldStyle;
  actionTypes = ActionType;

  titleCleanValue = (() => {
    return (value: string): string => {
      return cleanElementName(value, this.element, this.context.getElementItems());
    };
  })();

  constructor(
    public form: CustomizeBarFormEditForm,
    private customizeBarContext: CustomizeBarContext,
    private customizeService: CustomizeService,
    private elementContainerService: ElementContainerService,
    private elementComponentsService: ElementComponentsService,
    private dialogService: DialogService,
    private cd: ChangeDetectorRef,
    private analyticsService: UniversalAnalyticsService
  ) {}

  ngOnInit() {
    this.form.init(this.element, this.context, this.firstInit);

    const resultObs = this.form.valueChanges.pipe(
      debounceTime(200),
      map(() => this.form.submit())
    );

    resultObs.pipe(untilDestroyed(this)).subscribe(result => {
      this.result = result;
      this.emitUpdate();
    });

    resultObs
      .pipe(
        switchMap(result => this.form.isConfigured(result)),
        trackConfigured(),
        first(configuration => configuration == ElementConfiguration.Started),
        untilDestroyed(this)
      )
      .subscribe(() => {
        this.configurationStarted = true;
        this.analyticsService.sendSimpleEvent(AnalyticsEvent.Component.StartedConfiguration, {
          ComponentTypeID: this.element.analyticsName
        });
      });

    controlValue<ActionQuery>(this.form.controls.submit_action.controls.query)
      .pipe(untilDestroyed(this))
      .subscribe(query => {
        this.customAction = query && query.queryType && query.queryType != QueryType.Simple;
        this.cd.markForCheck();
      });

    this.form.controls.submit_action
      .getActionDescription$()
      .pipe(untilDestroyed(this))
      .subscribe(actionDescription => {
        this.modelAction = actionDescription ? actionDescription.modelAction : undefined;
        this.cd.markForCheck();
      });

    this.form.controls.get_data_source
      .getModelDescription$()
      .pipe(auditTime(1000 / 60), untilDestroyed(this))
      .subscribe(modelDescription => {
        this.modelDescription = modelDescription;
        this.modelDescriptionInContext = modelDescription
          ? {
              modelDescription: modelDescription,
              fieldToken: field => {
                if (!modelDescription.fields.find(item => item.name == field)) {
                  return;
                }

                return ['item', field];
              }
            }
          : undefined;
        this.cd.markForCheck();
      });

    this.form.controls.get_data_source
      .getResource$()
      .pipe(skip(1), untilDestroyed(this))
      .subscribe(resource => {
        if (resource) {
          this.analyticsService.sendSimpleEvent(AnalyticsEvent.ComponentData.ResourceSelected, {
            ComponentTypeID: this.element.analyticsName,
            ResourceID: resource ? resource.typeItem.name : undefined,
            ResourceDemo: resource ? resource.demo : false
          });
        }
      });

    combineLatest(
      this.form.controls.get_data_source.getResource$().pipe(skip(1)),
      this.form.controls.get_data_source.getModelDescription$().pipe(skip(1))
    )
      .pipe(untilDestroyed(this))
      .subscribe(([resource, modelDescription]) => {
        if (modelDescription) {
          this.analyticsService.sendSimpleEvent(AnalyticsEvent.ComponentData.ResourceItemSelected, {
            ComponentTypeID: this.element.analyticsName,
            ResourceID: resource ? resource.typeItem.name : undefined,
            ResourceDemo: resource ? resource.demo : false
          });
        }
      });

    this.dataConfigured$ = controlValid(this.form.controls.get_data_source);
    this.dataQueryConfigured$ = this.form.controls.get_data_source.getDataConfigured$();
    this.operationValid$ = this.form.controls.submit_action.operationValid$();
    this.actionsValid$ = this.form.controls.submit_action.actionsValid$();

    this.operationValid$.pipe(untilDestroyed(this)).subscribe(() => {
      // TODO: Fix for change detection
      this.cd.markForCheck();
    });

    this.form
      .submitActionOperationValid$()
      .pipe(untilDestroyed(this))
      .subscribe(configured => {
        this.actionOperationConfigured = configured;
        this.cd.markForCheck();
      });

    this.form
      .submitActionValid$()
      .pipe(untilDestroyed(this))
      .subscribe(configured => {
        this.actionConfigured = configured;
        this.cd.markForCheck();
      });

    controlValid(this.form.controls.get_data_source.controls.query_resource)
      .pipe(
        filter((configured, i) => configured && i > 0),
        untilDestroyed(this)
      )
      .subscribe(() => {
        this.analyticsService.sendSimpleEvent(AnalyticsEvent.ComponentData.ResourceItemSuccessfullySetUp, {
          ComponentTypeID: this.element.analyticsName
        });
      });

    controlValid(this.form.controls.get_data_source.controls.query_inputs)
      .pipe(
        filter((configured, i) => configured && i > 0),
        untilDestroyed(this)
      )
      .subscribe(() => {
        this.analyticsService.sendSimpleEvent(AnalyticsEvent.ComponentData.InputsSuccessfullySetUp, {
          ComponentTypeID: this.element.analyticsName
        });
      });

    this.form.statusChanges.pipe(untilDestroyed(this)).subscribe(() => this.cd.markForCheck());

    this.initFields();
  }

  ngOnDestroy(): void {}

  openGetQuery(options: { setupOnCreate?: false; addInput?: boolean } = {}) {
    this.form.enableGetQuery();

    this.customizeBarContext.appendSettingsComponent({
      component: CustomizeBarFormEditGetQueryComponent,
      inputs: {
        element: this.element,
        name: this.form.controls.name.value,
        form: this.form,
        context: this.context,
        contextElement: this.contextElement,
        contextElementPath: this.contextElementPath,
        contextElementPaths: this.contextElementPaths,
        setupOnCreate: options.setupOnCreate,
        addInput: options.addInput
      },
      outputs: {
        event: [
          () => {
            this.customizeBarContext.popSettingsComponent();
          }
        ]
      }
    });
  }

  close() {
    (this.configurationStarted ? this.form.isConfigured(this.result) : of(false))
      .pipe(untilDestroyed(this))
      .subscribe(configured => {
        if (configured) {
          this.analyticsService.sendSimpleEvent(AnalyticsEvent.Component.SuccessfullyConfigured, {
            ComponentTypeID: this.element.analyticsName
          });
        }

        this.customizeBarContext.popSettingsComponent();
      });
  }

  submit() {
    this.result = this.form.submit();
    this.emitUpdate(true);
    this.close();
  }

  onTitleChanged(name: string) {
    this.form.controls.name.setValue(name);
  }

  onApprovalOpenedChanged(opened: boolean) {
    if (opened) {
      this.form.controls.submit_action.controls.approve_enabled.patchValue(true);
      this.form.markAsDirty();
    }
  }

  emitUpdate(submit = false) {
    // TODO: Add args interface
    const args = { result: this.result, submit: submit };

    if (this.element) {
      this.event.emit({ type: CustomizeBarEditEventType.Updated, args: args });
    } else {
      this.event.emit({ type: CustomizeBarEditEventType.Created, args: args });
    }
  }

  cancel() {
    this.event.emit({ type: CustomizeBarEditEventType.Canceled });
    this.close();
  }

  delete() {
    this.event.emit({ type: CustomizeBarEditEventType.Deleted });
    this.close();
  }

  initFields() {
    combineLatest(
      this.context.elements$,
      controlValue<FieldInput[]>(this.form.controls.submit_action.controls.inputs),
      this.form.controls.submit_action.inputFieldProvider.fields$
    )
      .pipe(untilDestroyed(this))
      .subscribe(([elements, inputs, actionFields]) => {
        this.fields = this.getFields(elements, inputs);
        this.missingFields = this.getMissingFields(inputs, actionFields);
        this.cd.markForCheck();
      });
  }

  getFields(elements: ContextItem[], inputs: FieldInput[]): FieldElementItem[] {
    const fieldElements: { [k: string]: FieldElementItem } = elements.reduce((acc, contextItem) => {
      if (
        contextItem.element.type == ViewContextElementType.Element &&
        contextItem.element.element instanceof FieldElementItem
      ) {
        const path = this.context.getElementPath(contextItem.element);
        const contextValue = [...path, VALUE_OUTPUT];
        acc[JSON.stringify(contextValue)] = contextItem.element.element;
      }

      return acc;
    }, {});

    return inputs.reduce((acc, input) => {
      const fieldElement =
        input && input.valueType == InputValueType.Context
          ? fieldElements[JSON.stringify(input.contextValue)]
          : undefined;

      if (fieldElement && fieldElement instanceof FieldElementItem) {
        acc.push(fieldElement);
      }

      return acc;
    }, []);
  }

  getMissingFields(inputs: FieldInput[], actionFields: EditableField[]): CustomizeBarItem[] {
    const selfPath = this.context.getElementPath(this.contextElement);

    if (!selfPath) {
      return [];
    }

    return actionFields
      .filter(actionField => {
        return !this.fields.find(element => {
          const contextElement = this.context.elements.find(item => item.element.element === element);

          if (!contextElement) {
            return false;
          }

          const path = this.context.getElementPath(contextElement.element);
          const contextValue = [...path, VALUE_OUTPUT];
          const input = inputs.find(
            item => item.valueType == InputValueType.Context && isEqual(item.contextValue, contextValue)
          );
          return input && input.name == actionField.name;
        });
      })
      .map(field => {
        const fieldDescription = getFieldDescriptionByType(field.field);
        let valueInput: FieldInput;

        if (this.form.controls.get_enabled.value) {
          valueInput = new FieldInput();
          valueInput.name = 'value';
          valueInput.valueType = InputValueType.Context;
          valueInput.contextValue = [...selfPath, ITEM_OUTPUT, field.name];
        }

        return {
          title: field.verboseName || field.name,
          icon: fieldDescription.icon,
          image: fieldDescription.image,
          type: ElementType.Field,
          defaultName: field.verboseName || field.name,
          defaultParams: {
            settings: {
              field: field.field,
              verboseName: field.verboseName || field.name,
              editable: true,
              params: field.params,
              valueInput: valueInput ? valueInput.serialize() : undefined
            },
            ...(field.params || {})
          },
          postCreate: (element: FieldElementItem): FieldElementItem => {
            const currentInputs = this.form.controls.submit_action.controls.inputs.value as FieldInput[];
            const input = new FieldInput();
            const path = ['elements', element.uid];

            input.name = field.name;
            input.valueType = InputValueType.Context;
            input.contextValue = [...path, VALUE_OUTPUT];

            const newInputs = [...currentInputs.filter(item => item.name != field.name), input];
            this.form.controls.submit_action.controls.inputs.patchValue(newInputs);

            return element;
          }
        };
      });
  }

  customizeField(element: FieldElementItem) {
    const component = this.elementComponentsService.get(element);

    if (component) {
      component.customize({ append: true });
    }
  }

  deleteField(element: FieldElementItem) {
    return this.dialogService
      .warning({
        title: 'Deleting',
        description: `Are you sure want to delete Field <strong>${element.formField.label || element.name}</strong>?`
      })
      .pipe(untilDestroyed(this))
      .subscribe(result => {
        if (!result) {
          return of(false);
        }

        this.deleteFieldProcess(element);
      });
  }

  deleteFieldProcess(element: FieldElementItem) {
    this.result = this.form.submit();

    const children = this.result.children.filter(item => item.uid != element.uid);
    this.form.controls.children.patchValue(children);

    const contextElement = this.context.elements.find(item => item.element.element === element);

    if (!contextElement) {
      return;
    }

    const path = this.context.getElementPath(contextElement.element);
    const contextValue = [...path, VALUE_OUTPUT];
    const inputs = this.form.controls.submit_action.controls.inputs.value as FieldInput[];
    const input = inputs.find(
      item => item.valueType == InputValueType.Context && isEqual(item.contextValue, contextValue)
    );

    if (!input) {
      return;
    }

    const currentInputs = this.form.controls.submit_action.controls.inputs.value as FieldInput[];
    const parameters = this.form.getFormParameters().map(item => item.parameter);
    const parameter = parameters.find(item => item.name == input.name);
    const isRequired = parameter ? parameter.required : false;

    if (isRequired && this.form.controls.get_enabled.value) {
      const newInput = new FieldInput();

      newInput.name = input.name;
      newInput.valueType = InputValueType.Context;
      newInput.contextValue = [ITEM_OUTPUT, input.name];

      const newInputs = [...currentInputs.filter(item => item.name != input.name), newInput];
      this.form.controls.submit_action.controls.inputs.patchValue(newInputs);
    } else {
      const newInputs = currentInputs.filter(item => item.name != input.name);
      this.form.controls.submit_action.controls.inputs.patchValue(newInputs);
    }
  }

  addField(barItem: CustomizeBarItem) {
    this.result = this.form.submit();

    const submitIndex = this.result.children.findIndex(item => item instanceof FormSubmitElementItem);
    const children = [...this.result.children];
    const element = this.elementContainerService.insertElementItem<FieldElementItem>(
      barItem,
      children,
      this.context,
      submitIndex !== -1 ? submitIndex : children.length
    );
    this.customizeService.registerCreatedElement(element, barItem);
    this.form.controls.children.patchValue(children);
  }

  addQueryInput() {
    if (this.dataSourceEditComponent) {
      this.dataSourceEditComponent.editQuery({
        initialHttpResultsSection: HttpResultsSection.Parameters,
        initialSqlResultsSection: SqlResultsSection.Parameters
      });
    }
  }
}

import { Injectable, Injector } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, delayWhen, filter, map, tap } from 'rxjs/operators';

import { SessionStorage } from '@core';
import { ViewSettings, ViewSettingsService, ViewSettingsStore } from '@modules/customize';
import { MenuSettingsStore } from '@modules/menu';
import {
  CurrentEnvironmentStore,
  CurrentProjectStore,
  Environment,
  Project,
  Resource,
  ResourceTypeItem
} from '@modules/projects';
import { ResourceEditController, ResourceSummaryService } from '@modules/projects-components';
import { isResourceCustom } from '@modules/resources';
import { RoutingService } from '@modules/routing';
import { Template } from '@modules/template';
import { AppError } from '@shared';

import { TemplateItem } from '../../data/template-item';
import { TemplateItemType } from '../../data/template-item-type';
import { TemplateApplyService } from '../template-apply/template-apply.service';

export interface ProcessTemplateResult {
  processed: boolean;
  link?: any[];
  newPage?: boolean;
  cancelled?: boolean;
}

export const CUSTOMIZE_BAR_OPEN_RESOURCE_TEMPLATES = 'customize_bar_open_resource_templates';

@Injectable()
export class TemplateApplyController {
  constructor(
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private templateApplyService: TemplateApplyService,
    private resourceEditController: ResourceEditController,
    private resourceSummaryService: ResourceSummaryService,
    private routing: RoutingService,
    private viewSettingsService: ViewSettingsService,
    private viewSettingsStore: ViewSettingsStore,
    private menuSettingsStore: MenuSettingsStore,
    private sessionStorage: SessionStorage
  ) {}

  applyTemplateFromPage(
    item: TemplateItem,
    injector: Injector,
    currentPage?: ViewSettings,
    options: { resource?: Resource; useDemoResources?: boolean; analyticsSource?: string } = {}
  ): Observable<any[]> {
    return this.applyTemplateProcess(
      this.currentProjectStore.instance,
      this.currentEnvironmentStore.instance,
      injector,
      {
        type: item.type,
        resourceType: item.resource,
        resource: options.resource,
        template: item.template,
        resourceNameEditing: true,
        useDemoResources: options.useDemoResources,
        analyticsSource: options.analyticsSource
      }
    ).pipe(
      map(result => result.link),
      filter(result => result != undefined),
      delayWhen(() => {
        if (currentPage && currentPage.uid) {
          return this.deleteCurrentPage(currentPage);
        } else {
          return of({});
        }
      })
    );
  }

  applyTemplateProcess(
    project: Project,
    environment: Environment,
    injector: Injector,
    options: {
      type: TemplateItemType;
      resourceType?: ResourceTypeItem;
      resource?: Resource;
      template?: Template;
      resourceNameEditing: boolean;
      pagesOptional?: boolean;
      showSummary?: boolean;
      useDemoResources?: boolean;
      analyticsSource?: string;
    }
  ): Observable<ProcessTemplateResult> {
    const cancelled = new AppError();

    if (options.type == TemplateItemType.AdminPanel) {
      return this.templateApplyService
        .applyAdminPanelTemplate(project, environment, options.resourceType, {
          resource: options.resource,
          optional: options.pagesOptional,
          analyticsSource: options.analyticsSource
        })
        .pipe(
          tap(result => {
            if (result.cancelled) {
              throw cancelled;
            }
          }),
          delayWhen(result => {
            if (!options.showSummary) {
              return of(undefined);
            }

            return this.resourceSummaryService.open(result.resource);
          }),
          map(result => {
            // if (result.resource.typeItem.installConfigurationNeeded) {
            //   // obs.next(resource.link);
            //   return { processed: true, link: result.resource.link };
            // } else {
            // TODO: Filtering list page
            const page = result.viewSettings
              ? result.viewSettings.filter(item => !item.parameters.length && !item.name.endsWith(' - Create'))[0]
              : undefined;
            // const model = result.models ? result.models[0] : undefined;

            if (page) {
              return { processed: true, link: page.link };
              // } else if (model) {
              //   return { processed: true, link: model.link };
            } else if (result.newPage) {
              return { processed: true, link: project.newPageLink };
              // } else if (isResourceCustom(result.resource)) {
              //   this.sessionStorage.set(CUSTOMIZE_BAR_OPEN_RESOURCE_TEMPLATES, result.resource.uniqueName);
              //   return { processed: true, link: project.newPageLink };
            } else if (result.resource.link) {
              return { processed: true, link: result.resource.link };
            } else {
              return { processed: true, link: project.homeLink };
            }
          }),
          catchError(error => {
            if (error === cancelled) {
              return of({ processed: false, cancelled: true });
            } else {
              return throwError(error);
            }
          })
        );
    } else if (options.type == TemplateItemType.Template) {
      return this.templateApplyService
        .applyPage(project, environment, options.template, {
          useExistingUsedResource: options.resource,
          useDemoResources: options.useDemoResources,
          analyticsSource: options.analyticsSource
        })
        .pipe(
          map(result => {
            if (result.viewSettings[0]) {
              return { processed: true, link: result.viewSettings[0].link };
            } else {
              return { processed: true, link: project.homeLink };
            }
          })
        );
    }
  }

  deleteCurrentPage(currentPage: ViewSettings) {
    if (!currentPage) {
      return of(false);
    }

    return this.viewSettingsService
      .delete(
        this.currentProjectStore.instance.uniqueName,
        this.currentEnvironmentStore.instance.uniqueName,
        currentPage
      )
      .pipe(
        delayWhen(() => this.viewSettingsStore.getFirst(true)),
        delayWhen(() => this.menuSettingsStore.getFirst(true))
      );
  }
}

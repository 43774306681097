import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Type } from '@angular/core';
import pickBy from 'lodash/pickBy';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Observable } from 'rxjs';
import { map, publishLast, refCount, switchMap } from 'rxjs/operators';

import { ApiService } from '@modules/api';
import { CentrifugoService } from '@modules/centrifugo';
import { Environment, Project } from '@modules/projects';
import { Deserializable } from '@shared';

import { DataSyncJob } from '../../data/data-sync-job';
import { DataSyncJobTask } from '../../data/data-sync-job-task';

@Injectable({
  providedIn: 'root'
})
export class DataSyncService {
  constructor(private apiService: ApiService, private http: HttpClient, private centrifugoService: CentrifugoService) {}

  getJobs(project: Project, environment: Environment, params = {}): Observable<DataSyncJob[]> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.syncDataEnvironmentMethodURL(project.uniqueName, environment.uniqueName, 'jobs');
        let headers = new HttpHeaders();

        headers = this.apiService.setHeadersToken(headers);

        return this.http.get<Object[]>(url, { headers: headers, params: params });
      }),
      map(result => result.map(item => new DataSyncJob().deserialize(item))),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  getJob(project: Project, environment: Environment, id: string): Observable<DataSyncJob> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.syncDataEnvironmentMethodURL(
          project.uniqueName,
          environment.uniqueName,
          `jobs/${id}`
        );
        let headers = new HttpHeaders();

        headers = this.apiService.setHeadersToken(headers);

        return this.http.get<Object>(url, { headers: headers });
      }),
      map(result => new DataSyncJob().deserialize(result)),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  createJob(project: Project, environment: Environment, job: DataSyncJob): Observable<DataSyncJob> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.syncDataEnvironmentMethodURL(project.uniqueName, environment.uniqueName, 'jobs');
        let headers = new HttpHeaders();
        const data = {
          resourceUniqueName: job.sourceResource.uniqueName,
          runInterval: job.runInterval,
          useAllModelDescriptions: job.useAllModelDescriptions,
          modelDescriptions: job.modelDescriptions
        };

        headers = this.apiService.setHeadersToken(headers);

        return this.http.post<Object>(url, data, { headers: headers });
      }),
      map(result => new DataSyncJob().deserialize(result)),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  updateJob(
    project: Project,
    environment: Environment,
    job: DataSyncJob,
    fields?: string[],
    forceUpdate = false
  ): Observable<DataSyncJob> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.syncDataEnvironmentMethodURL(
          project.uniqueName,
          environment.uniqueName,
          `jobs/${job.id}`
        );
        let headers = new HttpHeaders();
        let data: Object = {
          active: job.active,
          runInterval: job.runInterval,
          readMode: job.readMode,
          writeMode: job.writeMode
        };

        headers = this.apiService.setHeadersToken(headers);

        if (fields) {
          data = <Object>pickBy(data, (v, k) => fields.includes(k));
        }

        data['forceUpdate'] = forceUpdate;

        return this.http.patch<Object>(url, data, { headers: headers });
      }),
      map(result => new DataSyncJob().deserialize(result)),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  deleteJob(project: Project, environment: Environment, job: DataSyncJob): Observable<boolean> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.syncDataEnvironmentMethodURL(
          project.uniqueName,
          environment.uniqueName,
          `jobs/${job.id}`
        );
        let headers = new HttpHeaders();
        headers = this.apiService.setHeadersToken(headers);

        return this.http.delete(url, { headers: headers });
      }),
      map(() => true),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  runJob(project: Project, environment: Environment, id: string, recreateStructure = false): Observable<DataSyncJob> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.syncDataEnvironmentMethodURL(
          project.uniqueName,
          environment.uniqueName,
          `jobs/${id}/run`
        );
        let headers = new HttpHeaders();
        const data = {
          recreateStructure: recreateStructure
        };

        headers = this.apiService.setHeadersToken(headers);

        return this.http.post<Object>(url, data, { headers: headers });
      }),
      map(result => new DataSyncJob().deserialize(result)),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  subscribeJob(project: Project, environment: Environment, job: DataSyncJob): Observable<DataSyncJob> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const channel = ['$data_sync_job:data_sync_job', project.uniqueName, environment.uniqueName, job.id].join('/');
        return this.centrifugoService.subscribeState(channel).pipe(map(msg => new DataSyncJob().deserialize(msg.data)));
      })
    );
  }

  getJobTasks(
    project: Project,
    environment: Environment,
    jobId: string,
    params = {}
  ): Observable<DataSyncService.GetResponse<DataSyncJobTask>> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.syncDataEnvironmentMethodURL(
          project.uniqueName,
          environment.uniqueName,
          `jobs/${jobId}/tasks`
        );
        let headers = new HttpHeaders();

        headers = this.apiService.setHeadersToken(headers);

        return this.http.get<Object>(url, { headers: headers, params: params });
      }),
      map(result => new DataSyncService.GetResponse<DataSyncJobTask>(DataSyncJobTask).deserialize(result)),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  getJobTaskLogs(project: Project, environment: Environment, taskId: string, params = {}): Observable<string> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.syncDataEnvironmentMethodURL(
          project.uniqueName,
          environment.uniqueName,
          `tasks/${taskId}/logs`
        );
        let headers = new HttpHeaders();

        headers = this.apiService.setHeadersToken(headers);

        return this.http.get(url, { headers: headers, params: params, responseType: 'text' });
      }),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  stopJobTask(project: Project, environment: Environment, taskId: string): Observable<boolean> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.syncDataEnvironmentMethodURL(
          project.uniqueName,
          environment.uniqueName,
          `tasks/${taskId}/stop`
        );
        let headers = new HttpHeaders();

        headers = this.apiService.setHeadersToken(headers);

        return this.http.post(url, {}, { headers: headers });
      }),
      map(() => true),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  subscribeJobTask(task: DataSyncJobTask): Observable<DataSyncJobTask> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const channel = ['$data_sync_task:data_sync_task', task.id].join('/');
        return this.centrifugoService
          .subscribeState(channel)
          .pipe(map(msg => new DataSyncJobTask().deserialize(msg.data)));
      })
    );
  }

  updateRecord(
    project: Project,
    environment: Environment,
    resourceUniqueName: string,
    modelDescriptionUniqueName: string,
    primaryKey: string,
    params?: Object
  ): Observable<boolean> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.syncDataEnvironmentMethodURL(
          project.uniqueName,
          environment.uniqueName,
          'update_record'
        );
        let headers = new HttpHeaders();
        const data = {
          resourceUniqueName: resourceUniqueName,
          collectionName: modelDescriptionUniqueName,
          primaryKeyValue: primaryKey,
          ...(params && { params: params })
        };

        headers = this.apiService.setHeadersToken(headers);

        return this.http.post<Object>(url, data, { headers: headers });
      }),
      map(() => true),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }
}

export namespace DataSyncService {
  export class GetResponse<T extends Deserializable<T>> {
    public results: T[];
    public count: number;
    public perPage: number;
    public numPages: number;

    constructor(private cls: Type<T>) {}

    deserialize(data: Object) {
      this.results = data['results'].map(item => new this.cls().deserialize(item));
      this.count = data['count'];
      this.perPage = data['per_page'];
      this.numPages = data['num_pages'];

      return this;
    }
  }
}

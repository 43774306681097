var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ChangeDetectorRef, Injector } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { ImageEditorComponent } from '@common/image-editor';
import { PopupService } from '@common/popups';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { ResourceControllerService } from '@modules/resources';
import { StorageService } from '@modules/storages-queries';
import { ImagePreviewComponent } from '../../previews/image-preview/image-preview.component';
import { BaseUploadComponent } from '../base-upload.component';
var ImageUploadComponent = /** @class */ (function (_super) {
    __extends(ImageUploadComponent, _super);
    function ImageUploadComponent(popupService, injector, currentProjectStore, currentEnvironmentStore, resourceControllerService, storageService, cd) {
        var _this = _super.call(this, currentProjectStore, currentEnvironmentStore, resourceControllerService, storageService, cd) || this;
        _this.popupService = popupService;
        _this.injector = injector;
        return _this;
    }
    ImageUploadComponent.prototype.onFileSelected = function (file) {
        var _this = this;
        var path = this.getUploadPath(file);
        if (!this.editor || file.type == 'image/svg+xml') {
            this.uploadFile(file, path);
            return;
        }
        var popup = this.popupService.showComponent({
            component: ImageEditorComponent,
            injector: this.injector,
            inputs: {
                file: file,
                cropFormat: this.cropFormat,
                cropFormatCustom: this.cropFormatCustom
            }
        });
        popup.instance.changed.pipe(untilDestroyed(this)).subscribe(function (result) {
            _this.uploadFile(result, path);
        });
    };
    return ImageUploadComponent;
}(BaseUploadComponent));
export { ImageUploadComponent };

<app-customize-bar-column-edit
  [field]="modelField?.item || defaults"
  [modelDescription]="modelDescription"
  [context]="context"
  [contextElement]="contextElement"
  [contextElementPath]="contextElementPath"
  [contextElementPaths]="contextElementPaths"
  [configurable]="{
    verboseName: true,
    add: true,
    name: true,
    field: true,
    required: true,
    editable: fieldEditable,
    value: valueEditable,
    lookup: lookupEditable,
    aggregate: aggregateEditable
  }"
  [backLabel]="backLabel"
  [submitEnabled]="true"
  [deleteEnabled]="deleteEnabled"
  [object]="analyticsSource"
  [trackConfigured]="trackConfigured"
  [firstInit]="firstInit"
  [controller]="this"
  (event)="onEvent($event)"
></app-customize-bar-column-edit>

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FormControl, FormGroup } from '@angular/forms';
import { SignUpField } from '@modules/all-project-settings';
import { isSet } from '@shared';
var SignUpFieldControl = /** @class */ (function (_super) {
    __extends(SignUpFieldControl, _super);
    function SignUpFieldControl(state) {
        if (state === void 0) { state = {}; }
        return _super.call(this, {
            project_property: new FormControl(isSet(state.project_property) ? state.project_property : undefined),
            required: new FormControl(isSet(state.required) ? state.required : false),
            placeholder: new FormControl(isSet(state.placeholder) ? state.placeholder : undefined)
        }) || this;
    }
    SignUpFieldControl.prototype.deserialize = function (instance) {
        this.controls.project_property.patchValue(instance.property);
        this.controls.required.patchValue(instance.required);
        this.controls.placeholder.patchValue(instance.placeholder);
        this.markAsPristine();
    };
    SignUpFieldControl.prototype.serialize = function () {
        var result = new SignUpField();
        result.property = this.controls.project_property.value;
        result.required = this.controls.required.value;
        result.placeholder = this.controls.placeholder.value;
        return result;
    };
    return SignUpFieldControl;
}(FormGroup));
export { SignUpFieldControl };

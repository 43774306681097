<ng-container *ngIf="!loading" [formGroup]="form.form">
  <div *ngIf="'action_approve' | appBannerVisible" class="sidebar__element">
    <a
      [appDocumentation]="'approval-workflow'"
      [appDocumentationLeft]="true"
      [appDocumentationSource]="source + '_approval'"
      class="sidebar-float-button"
      target="_blank"
    >
      <div class="sidebar-float-button__icon icon-help"></div>
      <div class="sidebar-float-button__label">See how it works</div>
      <div
        [appHideBanner]="'action_approve'"
        class="sidebar-float-button__close icon-close"
        (click)="$event.stopPropagation(); $event.preventDefault()"
      ></div>
    </a>
  </div>

  <div class="sidebar__element">
    <app-sidebar-field [label]="'Task Queue'" [required]="true">
      <mat-menu #queue_dropdown="matMenu">
        <ng-template matMenuContent>
          <button
            *ngFor="let queue of queues$ | async"
            mat-menu-item
            [disableRipple]="true"
            (click)="form.selectQueue(queue)"
          >
            <span class="choose-items-item__dropdown-icon icon-versions"></span>
            {{ queue.name }}
          </button>
          <div *ngIf="!(queues$ | async)?.length" class="mat-menu-item-message">
            Queues not found
          </div>
          <button mat-menu-item [disableRipple]="true" (click)="openQueueEditPopup()">
            <span class="choose-items-item__dropdown-icon icon-plus"></span>
            Add new
          </button>
        </ng-template>
      </mat-menu>

      <div class="control">
        <div class="control__input">
          <div
            class="select select_theme_jet select_fill"
            [matMenuTriggerFor]="queue_dropdown"
            #trigger="matMenuTrigger"
          >
            <a href="javascript:void(0)" class="select__input" [class.select__input_opened]="trigger.menuOpen">
              <span class="select__input-label">
                <ng-container *ngIf="selectedQueue">{{ selectedQueue.name }}</ng-container>
                <ng-container *ngIf="!selectedQueue">Select a queue</ng-container>
              </span>
              <span class="select__input-arrow"></span>
            </a>
          </div>
        </div>
        <div *ngIf="selectedQueue" class="control__right">
          <a
            (click)="openQueueEditPopup(selectedQueue)"
            href="javascript:void(0)"
            class="button button_bigger button_icon button_orange button_primary control__button"
            [appTip]="'Edit queue'"
          >
            <span class="icon icon-pen button__icon"></span>
          </a>
        </div>
      </div>
    </app-sidebar-field>
  </div>

  <div class="sidebar__element">
    <app-sidebar-field [label]="'Task Name'" [required]="true">
      <app-input-edit
        [itemForm]="form.form.controls['task_name']"
        [context]="context"
        [contextElement]="contextElement"
        [contextElementPath]="contextElementPath"
        [contextElementPaths]="contextElementPaths"
        [staticValueField]="fieldTypes.Text"
        [staticValueParams]="{ classes: ['input_fill', 'input_segment-bottom'] }"
        [userInput]="true"
        [fill]="true"
        [analyticsSource]="source + '_approve_task_name'"
      ></app-input-edit>
    </app-sidebar-field>
  </div>

  <div class="sidebar__element">
    <app-sidebar-field [label]="'initial status'" [required]="true">
      <gxs-select
        formControlName="task_create_status"
        [source]="createStatusSelectSource"
        [placeholder]="'Choose status'"
        [options]="{
          theme: 'jet',
          search: true,
          searchMinimumLength: 1,
          searchDebounce: 200,
          classes: ['select_fill'],
          labels: {} | localizeSelect
        }"
      ></gxs-select>
    </app-sidebar-field>
  </div>

  <div class="sidebar__element">
    <app-sidebar-field [label]="'initial assignee'">
      <gxs-select
        formControlName="task_assignee"
        [source]="projectUserSelectSource"
        [placeholder]="'Unassigned'"
        [options]="{
          theme: 'jet',
          search: true,
          searchMinimumLength: 1,
          searchDebounce: 200,
          classes: ['select_fill'],
          labels: {} | localizeSelect
        }"
      ></gxs-select>
    </app-sidebar-field>
  </div>

  <div class="sidebar__element">
    <app-sidebar-field [label]="'status after approve'">
      <gxs-select
        formControlName="task_approve_status"
        [source]="approveStatusSelectSource"
        [placeholder]="'---'"
        [options]="{
          theme: 'jet',
          search: true,
          searchMinimumLength: 1,
          searchDebounce: 200,
          classes: ['select_fill'],
          labels: {} | localizeSelect
        }"
      ></gxs-select>
    </app-sidebar-field>
  </div>

  <div class="sidebar__element">
    <app-sidebar-field [label]="'status after reject'">
      <gxs-select
        formControlName="task_reject_status"
        [source]="rejectStatusSelectSource"
        [placeholder]="'---'"
        [options]="{
          theme: 'jet',
          search: true,
          searchMinimumLength: 1,
          searchDebounce: 200,
          classes: ['select_fill'],
          labels: {} | localizeSelect
        }"
      ></gxs-select>
    </app-sidebar-field>
  </div>

  <app-sidebar-section [title]="'Specify Inputs'" [description]="'Manually or use value from other components'">
    <div class="sidebar__list">
      <app-inputs-edit
        [control]="form.form.controls['task_inputs']"
        [parameterProvider]="form.inputFieldProvider"
        [context]="context"
        [contextElement]="contextElement"
        [object]="analyticsSource"
      >
      </app-inputs-edit>
    </div>
  </app-sidebar-section>

  <app-sidebar-section [title]="'Additional actions'" [description]="'When approve is requested or rejected'">
    <div class="sidebar__list">
      <div class="sidebar-list__separator">
        <app-sidebar-list-separator [title]="'When approve requested'"></app-sidebar-list-separator>
      </div>
      <app-actions-edit
        [control]="form.form.controls['on_task_create_actions']"
        [context]="context"
        [contextElement]="contextElement"
        [contextElementPath]="contextElementPath"
        [contextElementPaths]="contextElementPaths"
        [emptyAction]="'Approve action'"
        [emptyActionValue]="'Not specified'"
        [emptyIcon]="'check_circle'"
        [actionLabel]="'Action approved'"
        [object]="analyticsSource"
        [firstInit]="firstInit"
      >
      </app-actions-edit>
      <div class="sidebar-list__separator">
        <app-sidebar-list-separator [title]="'When action rejected'"></app-sidebar-list-separator>
      </div>
      <app-actions-edit
        [control]="form.form.controls['on_reject_actions']"
        [emptyAction]="'Reject action'"
        [emptyActionValue]="'Not specified'"
        [emptyIcon]="'close'"
        [actionLabel]="'Action rejected'"
        [object]="analyticsSource"
        [firstInit]="firstInit"
      >
      </app-actions-edit>
    </div>
  </app-sidebar-section>
</ng-container>

import { AfterViewInit, ApplicationRef, Component, OnDestroy, OnInit, Optional } from '@angular/core';
import { NavigationCancel, RouteConfigLoadEnd, RouteConfigLoadStart, Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { concat, fromEvent, interval, of } from 'rxjs';
import { delay } from 'rxjs/operators';

import { DialogService } from '@common/dialogs';
import { PageLoadingService } from '@common/page-loading';
import { AuthService } from '@modules/auth';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
  constructor(
    private appRef: ApplicationRef,
    private router: Router,
    @Optional() private swUpdate: SwUpdate,
    private pageLoadingService: PageLoadingService,
    private dialogService: DialogService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.router.events.pipe(untilDestroyed(this)).subscribe(event => {
      if (event instanceof RouteConfigLoadStart) {
        this.pageLoadingService.addLoader(this);
      } else if (event instanceof RouteConfigLoadEnd) {
        this.pageLoadingService.removeLoader(this);
      } else if (event instanceof NavigationCancel) {
        this.pageLoadingService.removeLoader(this);
      }
    });
  }

  ngOnDestroy(): void {}

  ngAfterViewInit(): void {
    if (this.swUpdate && this.swUpdate.isEnabled) {
      // this.swUpdate.available.pipe(untilDestroyed(this)).subscribe(() => {
      // this.dialogService.confirm({
      //   title: 'Update available',
      //   description: 'New version of Jet Admin is available, would like to launch it now?'
      // })
      //  .pipe(untilDestroyed(this))
      //   .subscribe(confirmed => {
      //     if (!confirmed) {
      //       return;
      //     }
      //
      //     window.location.reload();
      //   });
      // });
      // this.checkForUpdates();
    } else {
      if (navigator.serviceWorker) {
        navigator.serviceWorker.getRegistrations().then(function (registrations) {
          for (const registration of registrations) {
            registration.unregister();
          }
        });
      }
    }

    fromEvent(document, 'tick')
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.appRef.tick();
      });
  }

  // hideChatra() {
  //   this.chatraService.hide();
  // }

  checkForUpdates() {
    concat(of({}).pipe(delay(10 * 1000)), interval(60 * 1000))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.swUpdate.checkForUpdate();
      });
  }
}

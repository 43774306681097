import { Injectable } from '@angular/core';
import clone from 'lodash/clone';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { PAGE_PARAM } from '@modules/models';
import { ListStore, ListStoreFetchResponse } from '@shared';

import { Task } from '../data/task';
import { TaskService } from '../services/task/task.service';

@Injectable()
export class TaskListStore extends ListStore<Task> {
  projectName: string;
  environmentName: string;
  params = {};

  constructor(private service: TaskService) {
    super();
  }

  paramsForPage(page) {
    const params = clone(this.params);
    params[PAGE_PARAM] = page;
    return params;
  }

  fetchPage(page: number, next: boolean): Observable<ListStoreFetchResponse<Task>> {
    const params = this.paramsForPage(page);

    return this.service.get(this.projectName, this.environmentName, params).pipe(
      map(response => {
        return {
          items: response.results,
          hasMore: response.hasMore,
          totalPages: response.numPages,
          perPage: response.perPage,
          count: response.count
        };
      })
    );
  }
}

import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

import { TaskScenario } from './task-scenario';

export abstract class Task {
  _scenario: TaskScenario;

  get scenario$(): Observable<TaskScenario> {
    if (this._scenario) {
      return of(this._scenario);
    }

    const init = this.init();

    if (init instanceof Observable) {
      return init.pipe(tap(result => (this._scenario = result)));
    } else {
      this._scenario = init as TaskScenario;
      return of(this._scenario);
    }
  }

  abstract init(): Observable<TaskScenario> | TaskScenario;
}

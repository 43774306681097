import { localize } from '@common/localize';
import { ResourceTypeItem, resourceTypeItems } from '@modules/projects';
import { Template, TemplateImage, TemplateTag } from '@modules/template';
import { deployUrl } from '@shared';

import { TemplateItemType } from './template-item-type';

export interface TemplateItem {
  name: string;
  subtitle?: string;
  description?: string;
  logo?: string;
  logoFill?: boolean;
  icon?: string;
  color?: string;
  type: TemplateItemType;
  tags: TemplateTag[];
  images: TemplateImage[];
  selectResources?: ResourceTypeItem[];
  resource?: ResourceTypeItem;
  template?: Template;
}

export function toTemplateItem(item: Template) {
  return {
    name: item.name,
    subtitle: item.subtitle,
    description: item.description,
    logo: item.logo,
    logoFill: item.logoFill,
    icon: item.icon,
    color: item.color,
    type: TemplateItemType.Template,
    tags: item.tags,
    images: item.images,
    template: item
  };
}

export const adminPanelTemplateItem: TemplateItem = {
  name: localize('Admin Panel'),
  subtitle: localize('List, create and update (CRUD) operations on top of your data'),
  description: localize(
    'Build a custom admin panel based on any databases, apps and APIs like Stripe, Twilio and GraphQL.'
  ),
  logo: deployUrl('/assets/images/admin-panel.svg'),
  logoFill: true,
  color: '#d3f9f7',
  type: TemplateItemType.AdminPanel,
  tags: resourceTypeItems
    .filter(item => item.hasAutoCollections && !item.hidden && !item.protected)
    .map(item => {
      const tag = new TemplateTag();
      tag.name = item.name;
      tag.color = item.color;
      return tag;
    }),
  images: [new TemplateImage().deserialize({ image: deployUrl('/assets/video/content_marketing_800.mp4') })]
};

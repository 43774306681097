/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../ui/components/alert/alert.component.ngfactory";
import * as i2 from "../../../../ui/components/alert/alert.component";
import * as i3 from "@angular/common";
import * as i4 from "./resource-settings-submit-error.component";
import * as i5 from "../../../analytics/services/intercom/intercom.service";
var styles_ResourceSettingsSubmitErrorComponent = [];
var RenderType_ResourceSettingsSubmitErrorComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ResourceSettingsSubmitErrorComponent, data: {} });
export { RenderType_ResourceSettingsSubmitErrorComponent as RenderType_ResourceSettingsSubmitErrorComponent };
function View_ResourceSettingsSubmitErrorComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 1, 0, currVal_0); }); }
export function View_ResourceSettingsSubmitErrorComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "app-alert", [], null, null, null, i1.View_AlertComponent_0, i1.RenderType_AlertComponent)), i0.ɵdid(1, 114688, null, 0, i2.AlertComponent, [], { title: [0, "title"], danger: [1, "danger"], border: [2, "border"] }, null), (_l()(), i0.ɵand(16777216, null, 0, 1, null, View_ResourceSettingsSubmitErrorComponent_1)), i0.ɵdid(3, 278528, null, 0, i3.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵeld(4, 0, null, 0, 3, "button", [["class", "button button_danger button_small"], ["style", "margin: 10px 0 0 0;"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openChat() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 0, "span", [["class", "button__icon button__icon_left icon-chat"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 1, "span", [["class", "button__label"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Need help"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "Integration with data source failed"; var currVal_1 = true; var currVal_2 = true; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = _co.errors; _ck(_v, 3, 0, currVal_3); }, null); }
export function View_ResourceSettingsSubmitErrorComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-resource-settings-submit-error", [], null, null, null, View_ResourceSettingsSubmitErrorComponent_0, RenderType_ResourceSettingsSubmitErrorComponent)), i0.ɵdid(1, 114688, null, 0, i4.ResourceSettingsSubmitErrorComponent, [i5.IntercomService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ResourceSettingsSubmitErrorComponentNgFactory = i0.ɵccf("app-resource-settings-submit-error", i4.ResourceSettingsSubmitErrorComponent, View_ResourceSettingsSubmitErrorComponent_Host_0, { errors: "errors" }, { contact: "contact" }, []);
export { ResourceSettingsSubmitErrorComponentNgFactory as ResourceSettingsSubmitErrorComponentNgFactory };

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

import { deserializeTokenOptions, TokenOptions } from '@modules/api';
import { RoutingService } from '@modules/routing';

import { AuthService } from '../services/auth/auth.service';

export const TOKEN_FRAGMENT_KEY = 'jet_token';

export function getRouteExternalToken(route: ActivatedRouteSnapshot): TokenOptions {
  const params = {};
  const paramsRegex = /^(\w+)=(.+)$/.exec(route.fragment || '');
  if (paramsRegex) {
    params[paramsRegex[1]] = decodeURIComponent(paramsRegex[2].replace(/\+/g, ' '));
  }

  if (params[TOKEN_FRAGMENT_KEY]) {
    try {
      return deserializeTokenOptions(JSON.parse(atob(params[TOKEN_FRAGMENT_KEY])));
    } catch (e) {}
  }
}

@Injectable({
  providedIn: 'root'
})
export class SetExternalTokenGuard implements CanActivate {
  constructor(private authService: AuthService, private routing: RoutingService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const externalToken = getRouteExternalToken(route);

    if (externalToken) {
      this.authService.saveToken(externalToken);
      this.routing.navigateRoute(route, { queryParams: route.queryParams, fragment: undefined, replaceUrl: true });

      return false;
    }

    return true;
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ExternalFontsComponent } from './components/external-fonts/external-fonts.component';
import { DomainThemeOptionsDirective } from './directives/domain-theme-options/domain-theme-options.directive';
import { ProjectThemeOptionsDirective } from './directives/project-theme-options/project-theme-options.directive';
import { ThemeOptionsDirective } from './directives/theme-options/theme-options.directive';
import { ThemeColorHexOptionPipe } from './pipes/theme-color-hex-option/theme-color-hex-option.pipe';
import { ThemeOptionPipe } from './pipes/theme-option/theme-option.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [
    ThemeOptionsDirective,
    ProjectThemeOptionsDirective,
    DomainThemeOptionsDirective,
    ExternalFontsComponent,
    ThemeOptionPipe,
    ThemeColorHexOptionPipe
  ],
  exports: [
    ThemeOptionsDirective,
    ProjectThemeOptionsDirective,
    DomainThemeOptionsDirective,
    ExternalFontsComponent,
    ThemeOptionPipe,
    ThemeColorHexOptionPipe
  ]
})
export class ThemeComponentsModule {}

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../routing/directives/app-link/app-link.directive";
import * as i2 from "../../../routing/services/routing/routing.service";
import * as i3 from "@angular/router";
import * as i4 from "@angular/common";
import * as i5 from "../../../../shared/components/loader-small/loader-small.component.ngfactory";
import * as i6 from "../../../../shared/components/loader-small/loader-small.component";
import * as i7 from "../../../../shared/components/bar-progress/bar-progress.component.ngfactory";
import * as i8 from "../../../../shared/components/bar-progress/bar-progress.component";
import * as i9 from "../../../../ui/components/alert/alert.component.ngfactory";
import * as i10 from "../../../../ui/components/alert/alert.component";
import * as i11 from "../../../../shared/pipes/stub/stub.pipe";
import * as i12 from "@angular/platform-browser";
import * as i13 from "../../../model-queries/pipes/model-str/model-str.pipe";
import * as i14 from "../../../model-queries/services/model-utils/model-utils.service";
import * as i15 from "../../../models-list/stores/model-list.store";
import * as i16 from "../../../model-queries/services/model/model.service";
import * as i17 from "../../../projects/stores/current-project.store";
import * as i18 from "../../../projects/stores/current-environment.store";
import * as i19 from "../../../model-queries/stores/model-description.store";
import * as i20 from "../../../data-sources-queries/services/model-description-data-source/model-description-data-source.service";
import * as i21 from "../../../resources/services/resource-controller/resource-controller.service";
import * as i22 from "./delete.component";
import * as i23 from "../../../activities/services/user-activity/user-activity.service";
import * as i24 from "../../../../core/services/session-storage/session.storage";
import * as i25 from "../../../meta/services/meta.service";
var styles_DeleteComponent = [];
var RenderType_DeleteComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_DeleteComponent, data: {} });
export { RenderType_DeleteComponent as RenderType_DeleteComponent };
function View_DeleteComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(1, null, [": ", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.count; _ck(_v, 1, 0, currVal_0); }); }
function View_DeleteComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "li", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 4, "a", [], [[8, "innerHTML", 1], [1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 2).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(2, 737280, null, 0, i1.AppLinkDirective, [i2.RoutingService, i3.Router, i3.ActivatedRoute, i4.LocationStrategy], { appLink: [0, "appLink"] }, null), i0.ɵppd(3, 1), i0.ɵppd(4, 1), i0.ɵpid(131072, i4.AsyncPipe, [i0.ChangeDetectorRef])], function (_ck, _v) { var currVal_3 = _v.context.$implicit.getLink(); _ck(_v, 2, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 5).transform(i0.ɵunv(_v, 1, 0, _ck(_v, 4, 0, i0.ɵnov(_v.parent.parent.parent, 0), i0.ɵunv(_v, 1, 0, _ck(_v, 3, 0, i0.ɵnov(_v.parent.parent.parent, 1), _v.context.$implicit)))))); var currVal_1 = i0.ɵnov(_v, 2).target; var currVal_2 = i0.ɵnov(_v, 2).href; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }); }
function View_DeleteComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "li", [], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["...", " more"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.count - _co.items.length); _ck(_v, 1, 0, currVal_0); }); }
function View_DeleteComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 10, "div", [["class", "card"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 9, "div", [["class", "card__inner"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 8, "ul", [], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "li", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["The following items will be removed:"])), (_l()(), i0.ɵeld(5, 0, null, null, 1, "li", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["---"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_DeleteComponent_4)), i0.ɵdid(8, 278528, null, 0, i4.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_DeleteComponent_5)), i0.ɵdid(10, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.items; _ck(_v, 8, 0, currVal_0); var currVal_1 = (_co.items.length < _co.count); _ck(_v, 10, 0, currVal_1); }, null); }
function View_DeleteComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-loader-small", [["class", "button__icon button__icon_left"]], null, null, null, i5.View_LoaderSmallComponent_0, i5.RenderType_LoaderSmallComponent)), i0.ɵdid(1, 114688, null, 0, i6.LoaderSmallComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
function View_DeleteComponent_7(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "a", [["class", "button"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 737280, null, 0, i1.AppLinkDirective, [i2.RoutingService, i3.Router, i3.ActivatedRoute, i4.LocationStrategy], { queryParams: [0, "queryParams"], appLink: [1, "appLink"] }, null), (_l()(), i0.ɵeld(2, 0, null, null, 1, "span", [["class", "button__label"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Cancel"]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.listParams; var currVal_3 = _co.modelDescription.link; _ck(_v, 1, 0, currVal_2, currVal_3); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).target; var currVal_1 = i0.ɵnov(_v, 1).href; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_DeleteComponent_9(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "change__submit"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "a", [["class", "button button_primary"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 2).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(2, 737280, null, 0, i1.AppLinkDirective, [i2.RoutingService, i3.Router, i3.ActivatedRoute, i4.LocationStrategy], { queryParams: [0, "queryParams"], appLink: [1, "appLink"] }, null), (_l()(), i0.ɵeld(3, 0, null, null, 1, "span", [["class", "button__label"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Continue"]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.listParams; var currVal_3 = _co.modelDescription.link; _ck(_v, 2, 0, currVal_2, currVal_3); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 2).target; var currVal_1 = i0.ɵnov(_v, 2).href; _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_DeleteComponent_8(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "app-bar-progress", [], null, null, null, i7.View_BarProgressComponent_0, i7.RenderType_BarProgressComponent)), i0.ɵdid(2, 638976, null, 0, i8.BarProgressComponent, [], { label: [0, "label"], progress: [1, "progress"], processed: [2, "processed"], total: [3, "total"], errors: [4, "errors"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_DeleteComponent_9)), i0.ɵdid(4, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.deleteProcessed == _co.count) ? "Finished" : "Deleting..."); var currVal_1 = (_co.deleteProcessed / _co.count); var currVal_2 = _co.deleteProcessed; var currVal_3 = _co.count; var currVal_4 = (_co.deleteProcessed - _co.deleteDeleted); _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); var currVal_5 = (_co.deleteProcessed == _co.count); _ck(_v, 4, 0, currVal_5); }, null); }
function View_DeleteComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 30, "div", [["class", "delete"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 29, "div", [["class", "section section_top"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 28, "div", [["class", "section__inner"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 6, "div", [["class", "change__header"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 5, "h1", [["class", "change__title"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 3, "a", [["class", "change__title-back"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 6).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(6, 737280, null, 0, i1.AppLinkDirective, [i2.RoutingService, i3.Router, i3.ActivatedRoute, i4.LocationStrategy], { queryParams: [0, "queryParams"], appLink: [1, "appLink"] }, null), (_l()(), i0.ɵeld(7, 0, null, null, 0, "span", [["class", "icon icon-arrow_backward_3"]], null, null, null, null, null)), (_l()(), i0.ɵted(8, null, [" ", " "])), (_l()(), i0.ɵted(-1, null, [" \u2013 Delete "])), (_l()(), i0.ɵeld(10, 0, null, null, 8, "div", [["style", "margin: 20px 30px;"]], null, null, null, null, null)), (_l()(), i0.ɵeld(11, 0, null, null, 7, "app-alert", [], null, null, null, i9.View_AlertComponent_0, i9.RenderType_AlertComponent)), i0.ɵdid(12, 114688, null, 0, i10.AlertComponent, [], { title: [0, "title"], danger: [1, "danger"], border: [2, "border"] }, null), (_l()(), i0.ɵted(-1, 0, [" You are going to delete "])), (_l()(), i0.ɵeld(14, 0, null, 0, 1, "strong", [], null, null, null, null, null)), (_l()(), i0.ɵted(15, null, ["", ""])), (_l()(), i0.ɵted(-1, 0, [" items "])), (_l()(), i0.ɵand(16777216, null, 0, 1, null, View_DeleteComponent_2)), i0.ɵdid(18, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_DeleteComponent_3)), i0.ɵdid(20, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(21, 0, null, null, 7, "div", [["class", "change__submit"]], null, null, null, null, null)), (_l()(), i0.ɵeld(22, 0, null, null, 4, "a", [["class", "button button_primary button_danger"], ["href", "javascript:void(0)"], ["style", "margin-right: 10px;"]], [[2, "button_disabled", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.confirm() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_DeleteComponent_6)), i0.ɵdid(24, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(25, 0, null, null, 1, "span", [["class", "button__label"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Confirm"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_DeleteComponent_7)), i0.ɵdid(28, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_DeleteComponent_8)), i0.ɵdid(30, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.listParams; var currVal_3 = _co.modelDescription.link; _ck(_v, 6, 0, currVal_2, currVal_3); var currVal_5 = "Warning"; var currVal_6 = true; var currVal_7 = true; _ck(_v, 12, 0, currVal_5, currVal_6, currVal_7); var currVal_9 = (_co.count != undefined); _ck(_v, 18, 0, currVal_9); var currVal_10 = _co.getItemList; _ck(_v, 20, 0, currVal_10); var currVal_12 = (_co.deleteStarted && (_co.deleteProcessed < _co.count)); _ck(_v, 24, 0, currVal_12); var currVal_13 = !_co.deleteStarted; _ck(_v, 28, 0, currVal_13); var currVal_14 = _co.deleteStarted; _ck(_v, 30, 0, currVal_14); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵnov(_v, 6).target; var currVal_1 = i0.ɵnov(_v, 6).href; _ck(_v, 5, 0, currVal_0, currVal_1); var currVal_4 = _co.modelDescription.verboseNamePlural; _ck(_v, 8, 0, currVal_4); var currVal_8 = _co.modelDescription.verboseNamePlural; _ck(_v, 15, 0, currVal_8); var currVal_11 = (_co.deleteStarted || (_co.deleteProcessed == _co.count)); _ck(_v, 22, 0, currVal_11); }); }
export function View_DeleteComponent_0(_l) { return i0.ɵvid(2, [i0.ɵpid(0, i11.StubPipe, [i12.DomSanitizer]), i0.ɵpid(0, i13.ModelStrPipe, [i14.ModelUtilsService]), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_DeleteComponent_1)), i0.ɵdid(3, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.modelDescription && !_co.loading); _ck(_v, 3, 0, currVal_0); }, null); }
export function View_DeleteComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "app-delete", [], null, null, null, View_DeleteComponent_0, RenderType_DeleteComponent)), i0.ɵprd(131584, null, i15.ModelListStore, i15.ModelListStore, [i16.ModelService, i17.CurrentProjectStore, i18.CurrentEnvironmentStore, i19.ModelDescriptionStore, i20.ModelDescriptionDataSourceService, i21.ResourceControllerService]), i0.ɵdid(2, 4440064, null, 0, i22.DeleteComponent, [i0.Injector, i16.ModelService, i15.ModelListStore, i19.ModelDescriptionStore, i23.UserActivityService, i3.ActivatedRoute, i17.CurrentProjectStore, i18.CurrentEnvironmentStore, i24.SessionStorage, i25.MetaService, i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var DeleteComponentNgFactory = i0.ɵccf("app-delete", i22.DeleteComponent, View_DeleteComponent_Host_0, {}, {}, []);
export { DeleteComponentNgFactory as DeleteComponentNgFactory };

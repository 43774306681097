import { Injectable } from '@angular/core';

import { ElementItem } from '@modules/customize';

import { AutoElementComponent } from '../../components/auto-element/auto-element.component';

@Injectable({
  providedIn: 'root'
})
export class ElementComponentsService {
  elementComponents: { element: ElementItem; component: AutoElementComponent }[] = [];

  register(element: ElementItem, component: AutoElementComponent) {
    this.elementComponents.push({
      element: element,
      component: component
    });
  }

  unregister(element: ElementItem) {
    this.elementComponents = this.elementComponents.filter(item => item.element !== element);
  }

  get(element: ElementItem) {
    const result = this.elementComponents.find(item => item.element === element);
    return result ? result.component : undefined;
  }
}

import pickBy from 'lodash/pickBy';
import * as moment from 'moment';
import { User } from '@modules/users';
import { isSet } from '@shared';
import { SnapshotStatus } from './snapshot-status';
var Snapshot = /** @class */ (function () {
    function Snapshot() {
        this.nameByUser = false;
        this.latest = false;
    }
    Snapshot.prototype.deserialize = function (data) {
        this.id = data['id'];
        this.name = data['name'];
        this.systemName = data['system_name'];
        this.hash = data['hash'];
        this.status = data['status'];
        this.latest = data['latest'];
        this.createdAt = data['created_at'] ? moment(data['created_at']) : undefined;
        this.updatedAt = data['updated_at'] ? moment(data['updated_at']) : undefined;
        if (data['latest'] !== undefined) {
            this.latest = data['latest'];
        }
        if (data['user']) {
            this.user = new User().deserialize(data['user']);
        }
        return this;
    };
    Snapshot.prototype.serialize = function (fields) {
        var data = {
            id: this.id,
            name: this.name,
            hash: this.hash,
            status: this.status,
            latest: this.latest
        };
        if (fields) {
            data = pickBy(data, function (v, k) { return fields.includes(k); });
        }
        return data;
    };
    Snapshot.prototype.isProcessing = function () {
        return [SnapshotStatus.Pending, SnapshotStatus.Running].includes(this.status);
    };
    Snapshot.prototype.isFailed = function () {
        return this.status == SnapshotStatus.Failed;
    };
    Snapshot.prototype.isSucceeded = function () {
        return this.status == SnapshotStatus.Succeeded;
    };
    Object.defineProperty(Snapshot.prototype, "shortHash", {
        get: function () {
            return isSet(this.hash) ? this.hash.substr(0, 8) : undefined;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Snapshot.prototype, "anyName", {
        get: function () {
            return isSet(this.name) ? this.name : this.systemName;
        },
        enumerable: true,
        configurable: true
    });
    return Snapshot;
}());
export { Snapshot };

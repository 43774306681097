var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { FormControl, FormGroup } from '@angular/forms';
import { MultipleFileStoreFormat } from '@modules/fields';
var VideoFieldDataParamsForm = /** @class */ (function (_super) {
    __extends(VideoFieldDataParamsForm, _super);
    function VideoFieldDataParamsForm() {
        var _this = _super.call(this, {
            multiple: new FormControl(false),
            store_format: new FormControl(MultipleFileStoreFormat.Array)
        }) || this;
        _this.outputFormatOptions = [
            { value: MultipleFileStoreFormat.Array, name: 'JSON Array' },
            { value: MultipleFileStoreFormat.String, name: 'String' }
        ];
        return _this;
    }
    VideoFieldDataParamsForm.prototype.init = function (paramsControl) {
        var _this = this;
        this.paramsControl = paramsControl;
        if (paramsControl.value) {
            this.controls.multiple.patchValue(paramsControl.value['multiple']);
            this.controls.store_format.patchValue(paramsControl.value['store_format'] || MultipleFileStoreFormat.Array);
            this.markAsPristine();
        }
        this.valueChanges.subscribe(function () { return _this.submit(); });
    };
    VideoFieldDataParamsForm.prototype.submit = function () {
        var result = {
            multiple: this.controls.multiple.value,
            store_format: this.controls.store_format.value
        };
        this.paramsControl.patchValue(__assign({}, this.paramsControl.value, result));
    };
    return VideoFieldDataParamsForm;
}(FormGroup));
export { VideoFieldDataParamsForm };

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../calendar-item/calendar-item.component.ngfactory";
import * as i2 from "../calendar-item/calendar-item.component";
import * as i3 from "../../../custom-views/stores/custom-views.store";
import * as i4 from "@angular/common";
import * as i5 from "../../../colors-shared/directives/tint-color/tint-color.directive";
import * as i6 from "@angular/platform-browser";
import * as i7 from "../../../../shared/components/loader/loader.component.ngfactory";
import * as i8 from "../../../../shared/components/loader/loader.component";
import * as i9 from "../../../theme-components/pipes/theme-color-hex-option/theme-color-hex-option.pipe";
import * as i10 from "../../../theme-components/data/theme-context";
import * as i11 from "../../../list/stores/columns-model-list.store";
import * as i12 from "../../../model-queries/services/model-utils/model-utils.service";
import * as i13 from "../../../model-queries/services/reduced-model/reduced-model.service";
import * as i14 from "../../../queries/services/query/query.service";
import * as i15 from "../../../messages/services/message/message.service";
import * as i16 from "../../../model-queries/services/model/model.service";
import * as i17 from "../../../projects/stores/current-project.store";
import * as i18 from "../../../projects/stores/current-environment.store";
import * as i19 from "../../../model-queries/stores/model-description.store";
import * as i20 from "../../../data-sources-queries/services/model-description-data-source/model-description-data-source.service";
import * as i21 from "../../../resources/services/resource-controller/resource-controller.service";
import * as i22 from "./calendar-month.component";
import * as i23 from "../../../action-queries/services/action-controller/action-controller.service";
import * as i24 from "../../../../common/notifications/services/notification/notification.service";
var styles_CalendarMonthComponent = [];
var RenderType_CalendarMonthComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CalendarMonthComponent, data: {} });
export { RenderType_CalendarMonthComponent as RenderType_CalendarMonthComponent };
function View_CalendarMonthComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "th", [["class", "calendar-month-table__th"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.date.format("dddd"); _ck(_v, 1, 0, currVal_0); }); }
function View_CalendarMonthComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [["class", "calendar-month-table__date-month"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", " "]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.date.format("MMMM"); _ck(_v, 1, 0, currVal_0); }); }
function View_CalendarMonthComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-calendar-item", [], null, [[null, "select"], [null, "modelUpdated"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("select" === en)) {
        var pd_0 = (_co.toggleSelectedItem(_v.context.$implicit, _v.context.index, $event.element, true) !== false);
        ad = (pd_0 && ad);
    } if (("modelUpdated" === en)) {
        var pd_1 = (_co.onModelUpdated($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i1.View_CalendarItemComponent_0, i1.RenderType_CalendarItemComponent)), i0.ɵdid(1, 770048, null, 0, i2.CalendarItemComponent, [i3.CustomViewsStore, i0.ChangeDetectorRef], { item: [0, "item"], listState: [1, "listState"], modelDescription: [2, "modelDescription"], settings: [3, "settings"], visibleColumns: [4, "visibleColumns"], context: [5, "context"], contextElement: [6, "contextElement"], selected: [7, "selected"] }, { select: "select", modelUpdated: "modelUpdated" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit; var currVal_1 = _co.listState; var currVal_2 = _co.modelDescription; var currVal_3 = _co.settings; var currVal_4 = _co.visibleColumns; var currVal_5 = _co.context; var currVal_6 = _co.contextElement; var currVal_7 = _co.isItemSelected(_v.context.$implicit, _v.context.index); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }, null); }
function View_CalendarMonthComponent_7(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "a", [["class", "calendar-card__more-items"], ["href", "javascript:void(0)"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.setParams(_co.dayParams(_v.parent.parent.context.$implicit.date)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(1, null, [" + ", " More Items "]))], null, function (_ck, _v) { var currVal_0 = (_v.parent.context.ngIf.length - 2); _ck(_v, 1, 0, currVal_0); }); }
function View_CalendarMonthComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CalendarMonthComponent_6)), i0.ɵdid(2, 278528, null, 0, i4.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CalendarMonthComponent_7)), i0.ɵdid(4, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = _v.context.ngIf.slice(0, 2); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_v.context.ngIf.length > 2); _ck(_v, 4, 0, currVal_1); }, null); }
function View_CalendarMonthComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 12, "td", [["class", "calendar-month-table__td"]], [[2, "calendar-month-table__td--weekend", null], [2, "calendar-month-table__td--active", null]], null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 9, "div", [["class", "calendar-month-table__date"]], [[2, "calendar-month-table__date--month", null], [8, "style", 2]], null, null, null, null)), i0.ɵdid(2, 540672, null, 0, i5.TintColorDirective, [i6.DomSanitizer], { appTintColor: [0, "appTintColor"], appTintColorStyle: [1, "appTintColorStyle"] }, null), i0.ɵppd(3, 1), i0.ɵpid(131072, i4.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CalendarMonthComponent_4)), i0.ɵdid(6, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(7, 0, null, null, 1, "span", [["class", "calendar-month-table__date-week"]], null, null, null, null, null)), (_l()(), i0.ɵted(8, null, ["", ""])), (_l()(), i0.ɵeld(9, 0, null, null, 1, "a", [["class", "calendar-month-table__date-number"], ["href", "javascript:void(0)"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.setParams(_co.dayParams(_v.context.$implicit.date)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(10, null, [" ", " "])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CalendarMonthComponent_5)), i0.ɵdid(12, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_4 = i0.ɵunv(_v, 2, 0, i0.ɵnov(_v, 4).transform(i0.ɵunv(_v, 2, 0, _ck(_v, 3, 0, i0.ɵnov(_v.parent.parent, 0), "accentColor")))); var currVal_5 = _co.tintStyles.Primary; _ck(_v, 2, 0, currVal_4, currVal_5); var currVal_6 = (_v.context.$implicit.date.date() === 1); _ck(_v, 6, 0, currVal_6); var currVal_9 = _co.items[_v.context.$implicit.key]; _ck(_v, 12, 0, currVal_9); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.weekend; var currVal_1 = _v.context.$implicit.today; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = (_v.context.$implicit.date.date() == 1); var currVal_3 = i0.ɵnov(_v, 2).style; _ck(_v, 1, 0, currVal_2, currVal_3); var currVal_7 = _v.context.$implicit.date.format("dd"); _ck(_v, 8, 0, currVal_7); var currVal_8 = _v.context.$implicit.date.format("D"); _ck(_v, 10, 0, currVal_8); }); }
function View_CalendarMonthComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "tr", [["class", "calendar-month-table__tr"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CalendarMonthComponent_3)), i0.ɵdid(2, 278528, null, 0, i4.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 2, 0, currVal_0); }, null); }
function View_CalendarMonthComponent_8(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["class", "calendar-loader"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "div", [["class", "calendar-loader__content"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "app-loader", [], null, null, null, i7.View_LoaderComponent_0, i7.RenderType_LoaderComponent)), i0.ɵdid(3, 114688, null, 0, i8.LoaderComponent, [], { fill: [0, "fill"] }, null)], function (_ck, _v) { var currVal_0 = true; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_CalendarMonthComponent_0(_l) { return i0.ɵvid(2, [i0.ɵpid(0, i9.ThemeColorHexOptionPipe, [[2, i10.ThemeContext]]), (_l()(), i0.ɵeld(1, 0, null, null, 7, "div", [["class", "calendar-month"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 6, "table", [["class", "calendar-month-table"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 5, "tbody", [], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 2, "tr", [["class", "calendar-month-table__tr"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CalendarMonthComponent_1)), i0.ɵdid(6, 278528, null, 0, i4.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CalendarMonthComponent_2)), i0.ɵdid(8, 278528, null, 0, i4.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CalendarMonthComponent_8)), i0.ɵdid(10, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.weekDays; _ck(_v, 6, 0, currVal_0); var currVal_1 = _co.weeks; _ck(_v, 8, 0, currVal_1); var currVal_2 = _co.loading; _ck(_v, 10, 0, currVal_2); }, null); }
export function View_CalendarMonthComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "app-calendar-month", [], null, null, null, View_CalendarMonthComponent_0, RenderType_CalendarMonthComponent)), i0.ɵprd(131584, null, i11.ColumnsModelListStore, i11.ColumnsModelListStore, [i12.ModelUtilsService, i13.ReducedModelService, i14.QueryService, i15.MessageService, i0.Injector, i16.ModelService, i17.CurrentProjectStore, i18.CurrentEnvironmentStore, i19.ModelDescriptionStore, i20.ModelDescriptionDataSourceService, i21.ResourceControllerService]), i0.ɵdid(2, 245760, null, 0, i22.CalendarMonthComponent, [i0.Injector, i23.ActionControllerService, i11.ColumnsModelListStore, i24.NotificationService, i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var CalendarMonthComponentNgFactory = i0.ɵccf("app-calendar-month", i22.CalendarMonthComponent, View_CalendarMonthComponent_Host_0, { title: "title", date: "date", params: "params", context: "context", listState: "listState", modelDescription: "modelDescription", settings: "settings", contextElement: "contextElement", accentColor: "accentColor" }, { queryParamsChanged: "queryParamsChanged" }, []);
export { CalendarMonthComponentNgFactory as CalendarMonthComponentNgFactory };

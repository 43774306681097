import { AbstractControl, FormArray, FormControl, FormGroup } from '@angular/forms';
import isArray from 'lodash/isArray';
import isEqual from 'lodash/isEqual';
import toPairs from 'lodash/toPairs';

import {
  JsonStructureArrayParams,
  JsonStructureNode,
  JsonStructureNodeType,
  JsonStructureObjectParams
} from '@modules/fields';

export function createJsonStructureNodeControl(
  node: JsonStructureNode,
  options: {
    existingControl?: AbstractControl;
    value?: Object | Object[];
  } = {}
): AbstractControl {
  if (!node) {
    return;
  }

  if (node.type == JsonStructureNodeType.Object) {
    const params = node.params as JsonStructureObjectParams;
    const result =
      options.existingControl && options.existingControl instanceof FormGroup
        ? options.existingControl
        : new FormGroup({});
    const addedControls = [];

    params.items.forEach(item => {
      const itemValue = options.value != undefined ? options.value[item.name] : undefined;
      const existingControl = result.controls[item.name];
      const control = createJsonStructureNodeControl(item, {
        existingControl: existingControl,
        value: itemValue
      });

      if (!existingControl && control) {
        result.addControl(item.name, control);
      }

      addedControls.push(item.name);
    });

    toPairs(result.controls)
      .filter(([name, control]) => addedControls.indexOf(name) == -1)
      .forEach(([name, control]) => result.removeControl(name));

    return result;
  } else if (node.type == JsonStructureNodeType.Array) {
    const params = node.params as JsonStructureArrayParams;
    const result =
      options.existingControl && options.existingControl instanceof FormArray
        ? options.existingControl
        : new FormArray([]);

    const itemsValue = isArray(options.value) ? (options.value as Object[]) || [] : [];

    itemsValue.forEach((itemValue, i) => {
      const existingControl = result.controls[i];
      const control = createJsonStructureNodeControl(params.item, {
        existingControl: existingControl,
        value: itemValue
      });

      if (!existingControl && control) {
        result.push(control);
      }
    });

    result.controls.slice(itemsValue.length).forEach(() => result.removeAt(itemsValue.length));

    return result;
  } else if (node.type == JsonStructureNodeType.Field) {
    if (options.existingControl && options.existingControl instanceof FormControl) {
      if (options.value !== undefined && !isEqual(options.existingControl.value, options.value)) {
        options.existingControl.setValue(options.value);
      }

      return options.existingControl;
    } else {
      return new FormControl(options.value);
    }
  }
}

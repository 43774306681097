<div class="token-popup-search">
  <input type="text" class="token-popup-search__input" placeholder="Search..." disabled />
</div>

<div class="token-popup__item">
  <div class="token-popup-section">
    <div class="token-popup-section__content">
      <ng-container *ngFor="let item of [1, 2, 3, 4]">
        <div
          class="token-popup-list-item"
          [class.token-popup-list-item_orange]="false"
          [class.token-popup-list-item_active]="false"
        >
          <div class="token-popup-list-item__inner">
            <div class="token-popup-list-item__line">
              <span
                class="token-popup-list-item__main token-popup-list-item__main_wrap"
                [class.token-popup-list-item__main_orange]="false"
                [class.token-popup-list-item__main_function]="false"
              >
                <span [class.loading-animation]="true">
                  <span class="stub-text">token item</span>
                </span>
              </span>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>

import { MenuItem } from './menu-item';
import { MenuItemType } from './menu-item-type';
import { registerMenuItemForType } from './menu-items';

export class SeparatorMenuItem extends MenuItem {
  public type = MenuItemType.Separator;

  clone(): SeparatorMenuItem {
    const instance = new SeparatorMenuItem();
    instance.copyFrom(this);
    return instance;
  }
}

registerMenuItemForType(MenuItemType.Separator, SeparatorMenuItem);

import { Injectable } from '@angular/core';
import { Option, SelectSource } from 'ng-gxselect';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { ProjectGroupService } from '../services/project-group/project-group.service';
import { CurrentEnvironmentStore } from './current-environment.store';
import { CurrentProjectStore } from './current-project.store';

@Injectable()
export class ProjectGroupSource extends SelectSource {
  private page = 1;
  private totalPages = 1;

  constructor(
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private projectGroupService: ProjectGroupService
  ) {
    super();
  }

  fetch(searchQuery: string): Observable<Option[]> {
    return this.projectGroupService
      .get(this.currentProjectStore.instance.uniqueName, this.currentEnvironmentStore.instance.uniqueName)
      .pipe(
        map(result => {
          this.page += 1;

          return result
            .filter(item => !searchQuery || item.name.toLowerCase().includes(searchQuery.toLowerCase()))
            .map(item => {
              return {
                value: item.uid,
                name: item.name
              };
            });
        })
      );
  }

  fetchByValue(value: any): Observable<Option> {
    if (!value) {
      return of(undefined);
    }
    return this.projectGroupService
      .get(this.currentProjectStore.instance.uniqueName, this.currentEnvironmentStore.instance.uniqueName)
      .pipe(
        map(result => {
          const project = result.find(item => item.uid == value);

          if (!project) {
            return;
          }

          return {
            value: project.uid,
            name: project.name
          };
        })
      );
  }

  isFetchAvailable(): boolean {
    return this.page <= this.totalPages;
  }

  resetPagination() {
    this.page = 1;
    this.totalPages = 1;
  }

  setStaticOptions(options: Option[]) {}
}

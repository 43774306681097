import { ChangeDetectorRef, EventEmitter, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, merge, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { ActionStore } from '@modules/action-queries';
import { ViewContext, ViewContextElement } from '@modules/customize';
import { DataSourceType } from '@modules/data-sources';
import { applyParamInput$ } from '@modules/fields';
import { ModelDescriptionStore } from '@modules/model-queries';
import { ActionQuery, ModelDescriptionQuery, QueryType, ValueWidgetQuery } from '@modules/queries';
import { controlValue, isSet } from '@shared';
import { ModelDescriptionDataSourceControl } from '../model-description-data-source-edit/model-description-data-source';
var ModelDescriptionDataSourceButtonComponent = /** @class */ (function () {
    function ModelDescriptionDataSourceButtonComponent(modelDescriptionStore, actionStore, cd) {
        this.modelDescriptionStore = modelDescriptionStore;
        this.actionStore = actionStore;
        this.cd = cd;
        this.required = false;
        this.placeholder = 'Connect Data Source';
        this.placeholderIcon = 'components';
        this.remove = false;
        this.editClick = new EventEmitter();
        this.addInputClick = new EventEmitter();
        this.editSortingClick = new EventEmitter();
        this.removeClick = new EventEmitter();
    }
    ModelDescriptionDataSourceButtonComponent.prototype.ngOnInit = function () { };
    ModelDescriptionDataSourceButtonComponent.prototype.ngOnDestroy = function () { };
    ModelDescriptionDataSourceButtonComponent.prototype.ngOnChanges = function (changes) {
        if (changes['control']) {
            this.updateObservers();
        }
        if (changes['control'] || changes['context'] || changes['contextElement']) {
            this.updateDataSourceValue();
        }
        if (changes['control'] || changes['sortingFieldControl']) {
            this.updateSortingFieldLabel();
        }
    };
    ModelDescriptionDataSourceButtonComponent.prototype.updateObservers = function () {
        var _this = this;
        if (this.observersSubscription) {
            this.observersSubscription.unsubscribe();
            this.observersSubscription = undefined;
        }
        if (!this.control) {
            return;
        }
        var observeControls = [this.control.controls.query_inputs];
        if (this.sortingFieldControl && this.sortingAscControl) {
            observeControls.push(this.sortingFieldControl, this.sortingAscControl);
        }
        this.observersSubscription = merge.apply(void 0, observeControls.map(function (item) { return item.valueChanges; })).pipe(untilDestroyed(this))
            .subscribe(function () { return _this.cd.markForCheck(); });
    };
    ModelDescriptionDataSourceButtonComponent.prototype.getDataSourceValue$ = function () {
        var _this = this;
        if (!this.control) {
            return of(undefined);
        }
        return combineLatest(controlValue(this.control.controls.type), this.control.getResource$(), controlValue(this.control.controls.query), this.control.getInput$(), controlValue(this.control.controls.workflow)).pipe(switchMap(function (_a) {
            var type = _a[0], resource = _a[1], query = _a[2], input = _a[3], workflow = _a[4];
            if (type == DataSourceType.Query && resource) {
                return _this.getDataSourceQueryValue$(resource, query).pipe(map(function (value) {
                    return {
                        image: resource ? resource.icon : undefined,
                        title1: value,
                        title2: resource ? resource.name : undefined,
                        tag: resource && resource.demo ? 'DEMO' : undefined
                    };
                }));
            }
            else if (type == DataSourceType.Input) {
                return _this.getDataSourceInputValue$(input).pipe(map(function (value) {
                    return {
                        icon: 'link',
                        title1: 'Specified data',
                        title2: value
                    };
                }));
            }
            else if (type == DataSourceType.Workflow) {
                var steps = workflow ? workflow.getStepsCount() : 0;
                return of({
                    icon: 'workflow',
                    title1: 'Load using Workflow',
                    title2: steps == 1 ? steps + " step" : steps + " steps"
                });
            }
            else {
                return of(undefined);
            }
        }));
    };
    ModelDescriptionDataSourceButtonComponent.prototype.getDataSourceQueryValue$ = function (resource, query) {
        if (!query) {
            return of(undefined);
        }
        if (query.queryType == QueryType.Simple) {
            var resourceName = resource ? resource.uniqueName : undefined;
            if (query instanceof ModelDescriptionQuery || query instanceof ValueWidgetQuery) {
                var simpleQuery = query.simpleQuery;
                var model = simpleQuery ? simpleQuery.model : undefined;
                var modelId = [resourceName, model].join('.');
                return this.modelDescriptionStore.getDetailFirst(modelId).pipe(map(function (modelDescription) {
                    return modelDescription ? modelDescription.verboseNamePlural || modelDescription.model : undefined;
                }));
            }
            else if (query instanceof ActionQuery) {
                var simpleQuery = query.simpleQuery;
                var action = simpleQuery ? simpleQuery.name : undefined;
                var actionId = [resourceName, action].join('.');
                return this.actionStore.getDetailFirst(actionId).pipe(map(function (actionDescription) {
                    return actionDescription ? actionDescription.verboseName || actionDescription.name : undefined;
                }));
            }
            else {
                return of(undefined);
            }
        }
        else if (query.queryType == QueryType.Http) {
            return of('HTTP query');
        }
        else if (query.queryType == QueryType.SQL) {
            return of('SQL query');
        }
        else if (query.queryType == QueryType.Object) {
            return of('Database query');
        }
        else {
            return of(undefined);
        }
    };
    ModelDescriptionDataSourceButtonComponent.prototype.getDataSourceInputValue$ = function (input) {
        if (!input || !input.isSet()) {
            return of(undefined);
        }
        return applyParamInput$(input, {
            context: this.context,
            contextElement: this.contextElement,
            defaultValue: ''
        }).pipe(map(function (result) {
            if (isArray(result)) {
                return 'Array';
            }
            else if (isObject(result)) {
                return 'Object';
            }
            else if (isSet(result)) {
                return String(result);
            }
            else {
                return input.valueTypeStr();
            }
        }));
    };
    ModelDescriptionDataSourceButtonComponent.prototype.updateDataSourceValue = function () {
        var _this = this;
        if (this.dataSourceValueSubscription) {
            this.dataSourceValueSubscription.unsubscribe();
            this.dataSourceValueSubscription = undefined;
        }
        this.dataSourceValueSubscription = this.getDataSourceValue$()
            .pipe(untilDestroyed(this))
            .subscribe(function (result) {
            _this.dataSourceValue = result;
            _this.cd.markForCheck();
        });
    };
    ModelDescriptionDataSourceButtonComponent.prototype.getSortingFieldLabel$ = function () {
        if (!this.control || !this.sortingFieldControl) {
            return of(undefined);
        }
        return combineLatest(controlValue(this.control.controls.columns), controlValue(this.sortingFieldControl)).pipe(map(function (_a) {
            var columns = _a[0], sortingField = _a[1];
            var column = columns.find(function (item) { return item.name == sortingField; });
            return column && isSet(column.verboseName) ? column.verboseName : sortingField;
        }));
    };
    ModelDescriptionDataSourceButtonComponent.prototype.updateSortingFieldLabel = function () {
        var _this = this;
        if (this.sortingFieldLabelSubscription) {
            this.sortingFieldLabelSubscription.unsubscribe();
            this.sortingFieldLabelSubscription = undefined;
        }
        this.sortingFieldLabelSubscription = this.getSortingFieldLabel$()
            .pipe(untilDestroyed(this))
            .subscribe(function (result) {
            _this.sortingFieldLabel = result;
            _this.cd.markForCheck();
        });
    };
    ModelDescriptionDataSourceButtonComponent.prototype.onInputsClick = function (e) {
        var inputs = this.control.controls.query_inputs.value;
        if (inputs && !inputs.length) {
            this.addInputClick.emit(e);
        }
        else {
            this.editClick.emit(e);
        }
    };
    return ModelDescriptionDataSourceButtonComponent;
}());
export { ModelDescriptionDataSourceButtonComponent };

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./guide-popup-task.component";
import * as i3 from "../../../services/guide-popup/guide-popup.service";
import * as i4 from "../../../../../common/dialogs/services/dialog/dialog.service";
import * as i5 from "../../../../analytics/services/universal-analytics/universal-analytics.service";
import * as i6 from "../../../services/guide/guide.service";
var styles_GuidePopupTaskComponent = [];
var RenderType_GuidePopupTaskComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_GuidePopupTaskComponent, data: {} });
export { RenderType_GuidePopupTaskComponent as RenderType_GuidePopupTaskComponent };
function View_GuidePopupTaskComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "a", [["class", "guide-popup__actions-item guide-popup__actions-item_color_yellow"], ["href", "javascript:void(0)"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openDocumentation() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, [" Documentation "]))], null, null); }
function View_GuidePopupTaskComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "a", [["class", "guide-popup__actions-item guide-popup__actions-item_color_red"], ["href", "javascript:void(0)"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancelTask() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, [" Cancel task "]))], null, null); }
export function View_GuidePopupTaskComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 11, "div", [["class", "guide-popup__content"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "div", [["class", "guide-popup__subtitle"]], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, ["Duration \u2013 ", ""])), (_l()(), i0.ɵeld(3, 0, null, null, 1, "div", [["class", "guide-popup__title"]], null, null, null, null, null)), (_l()(), i0.ɵted(4, null, ["", ""])), (_l()(), i0.ɵeld(5, 0, null, null, 1, "p", [["class", "guide-popup__description"]], null, null, null, null, null)), (_l()(), i0.ɵted(6, null, ["", ""])), (_l()(), i0.ɵeld(7, 0, null, null, 4, "div", [["class", "guide-popup__actions"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_GuidePopupTaskComponent_1)), i0.ɵdid(9, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_GuidePopupTaskComponent_2)), i0.ɵdid(11, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.task.documentation; _ck(_v, 9, 0, currVal_3); var currVal_4 = _co.task; _ck(_v, 11, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.task.duration; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.task.title; _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.task.description; _ck(_v, 6, 0, currVal_2); }); }
export function View_GuidePopupTaskComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-guide-popup-task", [], null, null, null, View_GuidePopupTaskComponent_0, RenderType_GuidePopupTaskComponent)), i0.ɵdid(1, 245760, null, 0, i2.GuidePopupTaskComponent, [i3.GuidePopupService, i4.DialogService, i5.UniversalAnalyticsService, i6.GuideService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var GuidePopupTaskComponentNgFactory = i0.ɵccf("app-guide-popup-task", i2.GuidePopupTaskComponent, View_GuidePopupTaskComponent_Host_0, { task: "task" }, {}, []);
export { GuidePopupTaskComponentNgFactory as GuidePopupTaskComponentNgFactory };

import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import cloneDeep from 'lodash/cloneDeep';
import isPlainObject from 'lodash/isPlainObject';
import toPairs from 'lodash/toPairs';
import { Observable } from 'rxjs';
import { map, publishLast, refCount, switchMap } from 'rxjs/operators';

import { ApiService } from '@modules/api';

import { CustomView } from '../../data/custom-view';

@Injectable({
  providedIn: 'root'
})
export class CustomViewService {
  constructor(private http: HttpClient, private apiService: ApiService) {}

  get(projectName: string, environmentName: string, params = {}, draft = false): Observable<CustomView[]> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.environmentMethodURL(projectName, environmentName, 'custom_views/');
        let headers = new HttpHeaders();
        const httpParams = {
          ...params,
          ...(draft && { draft: '1' })
        };

        headers = this.apiService.setHeadersToken(headers);

        return this.http.get<Array<Object>>(url, { headers: headers, params: httpParams });
      }),
      map(result => result.map(item => new CustomView().deserialize(item))),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  getDetail(
    projectName: string,
    environmentName: string,
    uniqueName: string,
    params = {},
    draft = false
  ): Observable<CustomView> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.environmentMethodURL(projectName, environmentName, `custom_views/${uniqueName}/`);
        let headers = new HttpHeaders();
        const httpParams = {
          ...params,
          ...(draft && { draft: '1' })
        };

        headers = this.apiService.setHeadersToken(headers);

        return this.http.get<Array<Object>>(url, { headers: headers, params: httpParams });
      }),
      map(result => new CustomView().deserialize(result)),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  createBulk(
    projectName: string,
    environmentName: string,
    actionDescriptions: CustomView[],
    fields?: string[]
  ): Observable<CustomView[]> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.environmentMethodURL(projectName, environmentName, 'custom_views/');
        let headers = new HttpHeaders();
        const params = {
          draft: '1'
        };
        const data = actionDescriptions.map(item => item.serialize(fields));

        headers = this.apiService.setHeadersToken(headers);

        return this.http.post<Object[]>(url, data, { headers: headers, params: params });
      }),
      map(result => result.map(item => new CustomView().deserialize(item))),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  create(
    projectName: string,
    environmentName: string,
    instance: CustomView,
    options: { fields?: string[]; draft?: boolean } = {}
  ): Observable<CustomView> {
    if (options.draft && options.fields && !options.fields.includes('dist')) {
      return this.createBulk(projectName, environmentName, [instance], options.fields).pipe(map(result => result[0]));
    } else {
      return this.apiService.refreshToken().pipe(
        switchMap(() => {
          const url = this.apiService.environmentMethodURL(projectName, environmentName, 'custom_views/');
          const formData = new FormData();
          const data = instance.serialize(options.fields);
          const params = {
            // ...(options.draft && { draft: '1' })
          };
          let headers = new HttpHeaders();

          headers = this.apiService.setHeadersToken(headers);

          toPairs(data)
            .filter(([_, value]) => value !== undefined)
            .forEach(([key, value]) => {
              if (key == 'view' && isPlainObject(value)) {
                value = JSON.stringify(value);
              }

              formData.append(key, value);
            });

          return this.http.post(url, formData, { headers: headers, params: params });
        }),
        map(result => new CustomView().deserialize(result)),
        this.apiService.catchApiError(),
        publishLast(),
        refCount()
      );
    }
  }

  update(
    projectName: string,
    environmentName: string,
    instance: CustomView,
    options: { fields?: string[]; draft?: boolean } = {}
  ): Observable<CustomView> {
    if (options.draft && options.fields && !options.fields.includes('dist')) {
      return this.createBulk(projectName, environmentName, [instance], options.fields).pipe(map(result => result[0]));
    } else {
      return this.apiService.refreshToken().pipe(
        switchMap(() => {
          const url = this.apiService.environmentMethodURL(
            projectName,
            environmentName,
            `custom_views/${instance.uniqueName}/`
          );
          const formData = new FormData();
          const data = instance.serialize(options.fields);
          const params = {
            // ...(options.draft && { draft: '1' })
          };
          let headers = new HttpHeaders();

          headers = this.apiService.setHeadersToken(headers);

          toPairs(data)
            .filter(([_, value]) => value !== undefined)
            .forEach(([key, value]) => {
              if (key == 'view' && isPlainObject(value)) {
                value = JSON.stringify(value);
              }

              formData.append(key, value);
            });

          return this.http.patch(url, formData, { headers: headers, params: params });
        }),
        map(result => new CustomView().deserialize(result)),
        this.apiService.catchApiError(),
        publishLast(),
        refCount()
      );
    }
  }

  delete(projectName: string, environmentName: string, instance: CustomView): Observable<boolean> {
    instance = cloneDeep(instance);
    instance.deleted = true;
    return this.createBulk(projectName, environmentName, [instance]).pipe(map(() => true));
    // return this.apiService.refreshToken().pipe(
    //   switchMap(() => {
    //     const url = this.apiService.environmentMethodURL(
    //       projectName,
    //       environmentName,
    //       `custom_views/${instance.uniqueName}/`
    //     );
    //     let headers = new HttpHeaders();
    //
    //     headers = this.apiService.setHeadersToken(headers);
    //
    //     return this.http.delete(url, { headers: headers });
    //   }),
    //   map(result => true),
    //   this.apiService.catchApiError(),
    //   publishLast(),
    //   refCount()
    // );
  }

  downloadDist(url: string): Observable<ArrayBuffer> {
    return this.http.get(url, { responseType: 'arraybuffer' }).pipe(publishLast(), refCount());
  }
}

import { Injectable } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import cloneDeep from 'lodash/cloneDeep';
import flatten from 'lodash/flatten';
import { combineLatest, Observable, throwError } from 'rxjs';
import { catchError, delayWhen, tap } from 'rxjs/operators';

import { FormUtils } from '@common/form-utils';
import { ModelDescriptionService, ModelDescriptionStore } from '@modules/model-queries';
import { ModelDescription } from '@modules/models';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';

@Injectable()
export class ModelEditPopupForm extends FormGroup {
  modelDescription: ModelDescription;
  fieldOptions = [];
  dbFieldOptions = [];
  defaultOrderByOptions = [];

  constructor(
    private formUtils: FormUtils,
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private modelDescriptionService: ModelDescriptionService,
    private modelDescriptionStore: ModelDescriptionStore
  ) {
    super({
      verbose_name_plural: new FormControl(''),
      description: new FormControl(''),
      display_field: new FormControl(''),
      default_order_by: new FormControl('')
    });
  }

  setInstance(modelDescription: ModelDescription) {
    this.modelDescription = modelDescription;

    this.patchValue({
      verbose_name_plural: modelDescription.verboseNamePlural,
      description: modelDescription.description,
      display_field: modelDescription.displayField,
      default_order_by: modelDescription.defaultOrderBy
    });

    this.fieldOptions = [
      {
        name: '---',
        value: undefined
      }
    ].concat(
      this.modelDescription.fields.map(item => {
        return {
          name: item.verboseName,
          value: item.name
        };
      })
    );

    this.dbFieldOptions = [
      {
        name: '---',
        value: undefined
      }
    ].concat(
      this.modelDescription.dbFields.map(item => {
        return {
          name: item.verboseName,
          value: item.name
        };
      })
    );

    this.defaultOrderByOptions = flatten(
      this.modelDescription.dbFields.map(item => [
        {
          name: `${item.verboseName} (ascending)`,
          value: item.name
        },
        {
          name: `${item.verboseName} (descending)`,
          value: `-${item.name}`
        }
      ])
    );
  }

  getInstance(): ModelDescription {
    const value = this.value;
    const instance = cloneDeep(this.modelDescription) as ModelDescription;

    instance.verboseNamePlural = value.verbose_name_plural;
    instance.description = value.description;
    instance.displayField = value.display_field;
    instance.defaultOrderBy = value.default_order_by;

    return instance;
  }

  submit(): Observable<ModelDescription> {
    const instance = this.getInstance();

    return this.modelDescriptionService
      .update(this.currentProjectStore.instance.uniqueName, this.currentEnvironmentStore.instance.uniqueName, instance)
      .pipe(
        delayWhen(() => {
          return combineLatest(this.modelDescriptionStore.getFirst(true));
        }),
        tap(result => {
          this.modelDescription = result;
        }),
        catchError(error => {
          this.markAsDirty();
          this.formUtils.showFormErrors(this, error);
          return throwError(error);
        })
      );
  }
}

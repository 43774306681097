import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  Optional
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, fromEvent, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { copyTextToClipboard } from '@common/code';
import { NotificationService } from '@common/notifications';
import { BasePopupComponent } from '@common/popups';
import { AppConfigService } from '@core';
import { AdminMode, ROUTE_ADMIN_MODE } from '@modules/admin-mode';
import { IntercomService, UniversalAnalyticsService } from '@modules/analytics';
import { ApiService } from '@modules/api';
import {
  CurrentProjectStore,
  ProjectTokenService,
  ResourceName,
  salesforceBackendName,
  SecretToken,
  socialBackends
} from '@modules/projects';
import { HttpMethod, HttpQuery, HttpQueryService, submitForm } from '@modules/queries';
import { capitalize, controlValue, isSet } from '@shared';

import { registerResourceSettingsComponent } from '../../../data/resource-settings-components';
import { ChooseSyncController } from '../../../services/choose-sync/choose-sync.controller';
import { BaseResourceSettingsComponent } from '../base-resource-settings/base-resource-settings.component';
import { SalesforceResourceSettingsForm } from './salesforce-resource-settings.form';

@Component({
  selector: 'app-salesforce-resource-settings',
  templateUrl: './salesforce-resource-settings.component.html',
  providers: [SalesforceResourceSettingsForm],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SalesforceResourceSettingsComponent extends BaseResourceSettingsComponent implements OnInit, OnDestroy {
  account: string;
  accountSubscription: Subscription;
  accountLoading = false;

  constructor(
    @Inject(ROUTE_ADMIN_MODE) private mode: AdminMode,
    private httpQueryService: HttpQueryService,
    private apiService: ApiService,
    private appConfigService: AppConfigService,
    private currentProjectStore: CurrentProjectStore,
    private chooseSyncController: ChooseSyncController,
    private notificationService: NotificationService,
    public form: SalesforceResourceSettingsForm,
    @Optional() popupComponent: BasePopupComponent,
    projectTokenService: ProjectTokenService,
    intercomService: IntercomService,
    analyticsService: UniversalAnalyticsService,
    cd: ChangeDetectorRef
  ) {
    super(form, popupComponent, projectTokenService, intercomService, analyticsService, cd);
  }

  ngOnInit(): void {
    super.ngOnInit();

    fromEvent<MessageEvent>(window, 'message')
      .pipe(untilDestroyed(this))
      .subscribe(message => {
        if (message.data && message.data['type'] == 'oauth_response') {
          const data = message.data['params'];
          const secretToken = new SecretToken().deserialize(data);

          this.form.form.patchValue({
            access_token: secretToken.value,
            params: secretToken.params
          });
        }
      });

    combineLatest(
      controlValue(this.form.form.controls['access_token']),
      controlValue(this.form.form.controls['domain'])
    )
      .pipe(untilDestroyed(this))
      .subscribe(([accessToken, domain]) => this.updateAccount(accessToken, domain));
  }

  ngOnDestroy(): void {}

  updateAccount(accessToken: string, domain: string) {
    if (this.accountSubscription) {
      this.accountSubscription.unsubscribe();
      this.accountSubscription = undefined;
    }

    if (!isSet(accessToken) || !isSet(domain)) {
      this.account = undefined;
      this.accountLoading = false;
      this.cd.markForCheck();
      return;
    }

    const headers = [{ name: 'Authorization', value: `Bearer ${accessToken}` }];
    const query = new HttpQuery();

    query.url = `https://${domain}.my.salesforce.com/services/data/v52.0/`;
    query.headers = headers;

    this.accountLoading = true;
    this.cd.markForCheck();

    this.accountSubscription = this.httpQueryService
      .requestBody(query)
      .pipe(
        switchMap(result => {
          const identityQuery = new HttpQuery();

          identityQuery.url = result['identity'];
          identityQuery.headers = headers;

          return this.httpQueryService.requestBody(identityQuery);
        }),
        untilDestroyed(this)
      )
      .subscribe(
        result => {
          this.account = result['email'];
          this.accountSubscription = undefined;
          this.accountLoading = false;
          this.cd.markForCheck();
        },
        () => {
          this.account = undefined;
          this.accountSubscription = undefined;
          this.accountLoading = false;
          this.cd.markForCheck();
        }
      );
  }

  getOAuthToken() {
    this.apiService
      .refreshToken()
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        const params = {
          KEY: this.form.form.value['key'],
          SECRET: this.form.form.value['secret'],
          SCOPE: ['api', 'refresh_token', 'offline_access'],
          SCOPE_SEPARATOR: ' '
        };
        const redirect = this.redirectUrl();
        const backend = socialBackends.find(item => item.name == salesforceBackendName);
        const data = [
          {
            name: 'name',
            value: this.form.tokenName
          },
          {
            name: 'backend',
            value: backend ? backend.path : ''
          },
          {
            name: 'params',
            value: JSON.stringify(params)
          },
          {
            name: 'access_token',
            value: this.apiService.getAccessToken()
          },
          {
            name: 'redirect_uri',
            value: redirect
          }
        ];

        if (this.mode == AdminMode.Builder) {
          data.push({
            name: 'draft',
            value: '1'
          });
        }

        const w = open('', '', 'height=800,width=600');
        if (!w) {
          this.notificationService.error(
            'Failed to open popup',
            'Your browser has blocked opening a new window. Please check your browser settings to allow opening pop-ups'
          );
          return;
        }

        submitForm(HttpMethod.POST, this.apiService.createOAuthTokenUrl, data, w.document);
      });
  }

  get completeUrl() {
    return this.apiService.createOAuthTokenCompleteUrl;
  }

  redirectUrl() {
    return `${window.location.origin}/oauth_response`;
  }

  copy(text: string, contentLabel?: string) {
    copyTextToClipboard(text)
      .pipe(untilDestroyed(this))
      .subscribe(success => {
        if (!success) {
          return;
        }

        const description = isSet(contentLabel) ? `${capitalize(contentLabel)} was copied to clipboard` : undefined;
        this.notificationService.info('Copied', description);
      });
  }
}

registerResourceSettingsComponent(ResourceName.Salesforce, SalesforceResourceSettingsComponent);

import { Border } from '../border';
import { Fill } from '../fill';
import { Layer } from '../layer';
import { LayerType } from '../layer-type';
import { registerLayerType } from '../layer-types';
import { Shadow } from '../shadow';

export class EllipseLayer extends Layer {
  public type = LayerType.Ellipse;
  public icon = 'circle_2';
  public defaultName = 'ellipse';
  public fills: Fill[] = [];
  public borders: Border[] = [];
  public shadows: Shadow[] = [];

  constructor(options: Partial<EllipseLayer> = {}) {
    super(options);
  }

  deserialize(data: Object): this {
    super.deserialize(data);

    if (data['fills']) {
      this.fills = data['fills'].map(item => new Fill().deserialize(item));
    }

    if (data['borders']) {
      this.borders = data['borders'].map(item => new Border().deserialize(item));
    }

    if (data['shadows']) {
      this.shadows = data['shadows'].map(item => new Shadow().deserialize(item));
    }

    return this;
  }

  serialize(): Object {
    return {
      ...super.serialize(),
      fills: this.fills.map(item => item.serialize()),
      borders: this.borders.map(item => item.serialize()),
      shadows: this.shadows.map(item => item.serialize())
    };
  }
}

registerLayerType(LayerType.Ellipse, EllipseLayer);

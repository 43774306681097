import { Pipe, PipeTransform, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { merge, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Pipe({
  name: 'appStub'
})
export class StubPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(value: Observable<string>, text?: string): Observable<string> {
    return merge(
      of(`<span class="loading-animation"><span class="stub-text">${text || 'Record Name'}</span></span>`),
      value.pipe(map(str => this.sanitizer.sanitize(SecurityContext.HTML, str)))
    );
  }
}

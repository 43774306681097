import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, Subscription } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';

import { DynamicComponentArguments } from '@common/dynamic-component';
import { AppConfigService } from '@core';
import { ProjectSettingsStore } from '@modules/all-project-settings';
import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { CustomizeHandler, CustomizeService } from '@modules/customize';
import { CustomizeBarContext } from '@modules/customize-bar';
import { Domain } from '@modules/domain';
import { MenuSection, MenuService, MenuSettingsStore } from '@modules/menu';
import { MetaService } from '@modules/meta';
import {
  CurrentEnvironmentStore,
  CurrentProjectStore,
  Field,
  hasEnvironmentPermission,
  Project,
  ProjectPermissions,
  ProjectPropertyStore
} from '@modules/projects';
import { RoutingService } from '@modules/routing';
import { SSOSettings } from '@modules/sso';
import { isSet, scrollTo } from '@shared';

import { CustomizeBarAuthEditComponent } from '../customize-bar-auth-edit/customize-bar-auth-edit.component';
import { CustomizeBarSignUpCustomCodeEditComponent } from '../customize-bar-sign-up-custom-code-edit/customize-bar-sign-up-custom-code-edit.component';
import { CustomizeBarSigninEditComponent } from '../customize-bar-signin-edit/customize-bar-signin-edit.component';
import { CustomizeBarSignupAppearanceEditComponent } from '../customize-bar-signup-appearance-edit/customize-bar-signup-appearance-edit.component';
import { CustomizeBarSignupEditComponent } from '../customize-bar-signup-edit/customize-bar-signup-edit.component';
import { ProjectSettingsUpdateForm } from '../project-settings/project-settings-update.form';
import { ProjectUpdateForm } from '../project-settings/project-update.form';
import { ProjectDomainUpdateForm } from './project-domain-update.form';
import { ProjectSignUpForm } from './project-sign-up.form';

enum MenuItemType {
  Appearance = 'appearance',
  SignIn = 'sign_in',
  SignUp = 'sign_up',
  Auth = 'auth',
  CustomCode = 'custom_code'
}

enum DisplayComponent {
  SignUp = 'SignUp',
  SignIn = 'SignIn'
}

@Component({
  selector: 'app-sign-up-builder',
  templateUrl: './sign-up-builder.component.html',
  providers: [
    CustomizeBarContext,
    ProjectUpdateForm,
    ProjectSettingsUpdateForm,
    ProjectSignUpForm,
    ProjectDomainUpdateForm
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SignUpBuilderComponent implements OnInit, OnDestroy, CustomizeHandler {
  @ViewChild('root') root: ElementRef;

  menuItems = [
    {
      type: MenuItemType.Appearance,
      title: 'Appearance',
      subtitle: 'App name, layout and colors',
      icon: 'palette',
      component: CustomizeBarSignupAppearanceEditComponent,
      inputs: {
        projectSignUpForm: this.projectSignUpForm,
        projectDomainUpdateForm: this.projectDomainUpdateForm
      },
      displayComponent: DisplayComponent.SignIn
    },
    {
      type: MenuItemType.SignIn,
      title: 'Sign In',
      subtitle: 'Customize Sign In page',
      icon: 'signin',
      component: CustomizeBarSigninEditComponent,
      inputs: {
        projectSignUpForm: this.projectSignUpForm,
        projectDomainUpdateForm: this.projectDomainUpdateForm
      },
      displayComponent: DisplayComponent.SignIn
    },
    {
      type: MenuItemType.SignUp,
      title: 'Sign Up',
      subtitle: 'Customize Sign Up page',
      icon: 'signup',
      component: CustomizeBarSignupEditComponent,
      inputs: {
        projectSignUpForm: this.projectSignUpForm,
        projectDomainUpdateForm: this.projectDomainUpdateForm,
        settingsForm: this.settingsForm
      },
      displayComponent: DisplayComponent.SignUp
    },
    {
      type: MenuItemType.Auth,
      title: 'Authentication',
      subtitle: 'Email, SSO, socials',
      icon: 'lock_open',
      component: CustomizeBarAuthEditComponent,
      inputs: {
        projectDomainUpdateForm: this.projectDomainUpdateForm
      },
      displayComponent: DisplayComponent.SignIn
    },
    {
      type: MenuItemType.CustomCode,
      title: 'Scripts & Styles',
      subtitle: 'Set Sign In/Sign Up JS and CSS',
      icon: 'console',
      component: CustomizeBarSignUpCustomCodeEditComponent,
      inputs: {
        projectDomainUpdateForm: this.projectDomainUpdateForm
      },
      displayComponent: DisplayComponent.SignIn
    }
  ];
  selectedMenuItemType: MenuItemType;
  displayComponent: DisplayComponent;
  displayComponents = DisplayComponent;
  settingsComponents: DynamicComponentArguments[] = [];
  project: Project;
  signUpFields: Field[] = [];
  signUpLink: string;
  domain: Domain;
  currentSSO: SSOSettings;
  loading = false;
  domainFormSubscription: Subscription;

  constructor(
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private activatedRoute: ActivatedRoute,
    public projectForm: ProjectUpdateForm,
    public settingsForm: ProjectSettingsUpdateForm,
    private projectSignUpForm: ProjectSignUpForm,
    public projectDomainUpdateForm: ProjectDomainUpdateForm,
    private menuService: MenuService,
    private customizeBarContext: CustomizeBarContext,
    private currentProjectStore: CurrentProjectStore,
    private projectSettingsStore: ProjectSettingsStore,
    private projectPropertyStore: ProjectPropertyStore,
    private customizeService: CustomizeService,
    private menuSettingsStore: MenuSettingsStore,
    private routing: RoutingService,
    private appConfigService: AppConfigService,
    public metaService: MetaService,
    private analyticsService: UniversalAnalyticsService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    if (!hasEnvironmentPermission(this.currentEnvironmentStore.instance, ProjectPermissions.ProjectCustomization)) {
      this.routing.navigateApp(['not-allowed'], { skipLocationChange: true });
      return;
    }

    this.menuService.section = MenuSection.None;
    this.loading = true;
    this.cd.markForCheck();

    this.activatedRoute.params
      .pipe(
        map(params => params['tab']),
        untilDestroyed(this)
      )
      .subscribe(tab => {
        if (isSet(tab)) {
          this.setSelectedMenuItemType(tab);
        } else {
          const link = this.currentProjectStore.instance.settingsSignUpLink(this.menuItems[0].type);
          this.routing.navigateApp(link, { replaceUrl: true });
        }
      });

    this.customizeService.setHandler(this);
    this.customizeService.setHandlerInfo(this, {
      breadcrumbs: [],
      inAppDisabled: true
    });

    this.customizeBarContext.settingsComponents$.pipe(untilDestroyed(this)).subscribe(item => {
      this.settingsComponents = item;
      this.cd.detectChanges();
      this.scrollToTop();
    });

    combineLatest(this.settingsForm.getInstance$(), this.projectPropertyStore.getUser())
      .pipe(untilDestroyed(this))
      .subscribe(([projectSettings, userProperties]) => {
        this.signUpFields = projectSettings
          ? projectSettings.signUpFields
              .map(field => {
                const property = userProperties.find(item => item.uid == field.property);
                if (!property) {
                  return;
                }

                return new Field({
                  name: property.uid,
                  verboseName: property.name,
                  ...(property.field && {
                    field: property.field.field,
                    params: property.field.params
                  }),
                  ...(isSet(field.required) && { required: field.required }),
                  ...(isSet(field.placeholder) && { placeholder: field.placeholder })
                });
              })
              .filter(item => item)
          : [];
        this.cd.markForCheck();
      });

    this.projectDomainUpdateForm
      .getInstance$()
      .pipe(untilDestroyed(this))
      .subscribe(domain => {
        this.domain = domain;
        this.currentSSO =
          domain && domain.ssoSettings.length == 1 && domain.disableEmailSignIn ? domain.ssoSettings[0] : undefined;
        this.cd.markForCheck();
      });

    combineLatest(
      this.currentProjectStore.getFirst(),
      this.projectSettingsStore.getAllSettingsFirst$(),
      this.menuSettingsStore.getFirst()
    )
      .pipe(untilDestroyed(this))
      .subscribe(
        ([project, projectSettings, menuSettings]) => {
          this.project = project;
          this.domain = project ? project.domain : undefined;
          this.signUpLink = this.getSignUpLink();
          this.loading = false;
          this.cd.markForCheck();

          this.projectForm.init(project);
          this.settingsForm.init(projectSettings);
          this.projectSignUpForm.init(this.project);

          this.projectForm.valueChanges.pipe(debounceTime(200), untilDestroyed(this)).subscribe(() => {
            this.projectForm.submit().subscribe(() => this.onSave());
          });

          this.settingsForm.valueChanges.pipe(debounceTime(200), untilDestroyed(this)).subscribe(() => {
            this.settingsForm.submit().subscribe(() => this.onSave());
          });

          this.projectSignUpForm.valueChanges.pipe(debounceTime(200), untilDestroyed(this)).subscribe(() => {
            this.projectSignUpForm.submit().subscribe(() => this.onSave());
          });
        },
        () => {
          this.loading = false;
          this.cd.markForCheck();
        }
      );

    this.currentProjectStore
      .get()
      .pipe(untilDestroyed(this))
      .subscribe(result => {
        this.project = result;
        this.domain = result ? result.domain : undefined;
        this.signUpLink = this.getSignUpLink();
        this.cd.markForCheck();

        this.projectForm.project = this.project;
        this.projectSignUpForm.project = this.project;

        this.initDomainForm();
      });
  }

  ngOnDestroy(): void {
    this.menuService.section = MenuSection.Default;
    this.customizeService.unsetHandler(this);
  }

  initDomainForm() {
    if (this.domainFormSubscription) {
      this.domainFormSubscription.unsubscribe();
      this.domainFormSubscription = undefined;
    }

    this.projectDomainUpdateForm.init(this.domain);

    this.domainFormSubscription = this.projectDomainUpdateForm.valueChanges
      .pipe(debounceTime(200), untilDestroyed(this))
      .subscribe(() => {
        this.projectDomainUpdateForm.submit().subscribe(() => this.onSave());
      });
  }

  get selectedMenuItem() {
    return this.menuItems.find(item => item.type == this.selectedMenuItemType);
  }

  setSelectedMenuItemType(type: MenuItemType) {
    if (this.selectedMenuItemType === type) {
      return;
    }

    this.selectedMenuItemType = type;
    this.cd.markForCheck();

    const selectedMenuItem = this.selectedMenuItem;

    if (!selectedMenuItem) {
      return;
    }

    this.displayComponent = selectedMenuItem.displayComponent;
    this.cd.markForCheck();

    if (selectedMenuItem.component) {
      this.customizeBarContext.setSettingsComponent({
        component: selectedMenuItem.component,
        inputs: selectedMenuItem.inputs || {}
      });
    }
  }

  scrollToTop(animated = true) {
    if (!this.root) {
      return;
    }

    const duration = animated && this.root.nativeElement.scrollTop > 0 ? 0.4 : 0;
    scrollTo(this.root.nativeElement, 0, duration);
  }

  onMenuItemClicked(name: string) {
    this.analyticsService.sendSimpleEvent(AnalyticsEvent.SignUpSettings.MenuItemClicked, {
      Name: name
    });
  }

  getSignUpLink(): string {
    const project = this.currentProjectStore.instance;
    const defaultDomainProject =
      project.domain && project.domain.defaultProject == this.currentProjectStore.instance.uniqueName;
    const webBaseUrl = project.domain ? `https://${project.domain.actualDomain}` : this.appConfigService.webBaseUrl;

    return [
      webBaseUrl,
      'register',
      ...(defaultDomainProject ? [] : [this.currentProjectStore.instance.uniqueName])
    ].join('/');
  }

  onSave() {
    this.analyticsService.sendSimpleEvent(AnalyticsEvent.Project.BuilderChange, {
      Type: 'signup_settings'
    });
  }

  // TODO: Fix empty handler
  closePopup(uid?: string): void {}
}

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Optional } from '@angular/core';

import { BasePopupComponent } from '@common/popups';
import { IntercomService, UniversalAnalyticsService } from '@modules/analytics';
import { ProjectTokenService, ResourceName, SecretToken } from '@modules/projects';
import { HttpQueryAuthType } from '@modules/queries';

import { registerResourceSettingsComponent } from '../../../data/resource-settings-components';
import { BaseResourceSettingsComponent } from '../base-resource-settings/base-resource-settings.component';
import { RestApiResourceSettingsForm } from './rest-api-resource-settings.form';

@Component({
  selector: 'app-rest-api-resource-settings',
  templateUrl: './rest-api-resource-settings.component.html',
  providers: [RestApiResourceSettingsForm],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RestApiResourceSettingsComponent extends BaseResourceSettingsComponent {
  httpQueryAuthTypes = HttpQueryAuthType;

  constructor(
    public form: RestApiResourceSettingsForm,
    @Optional() popupComponent: BasePopupComponent,
    projectTokenService: ProjectTokenService,
    intercomService: IntercomService,
    analyticsService: UniversalAnalyticsService,
    cd: ChangeDetectorRef
  ) {
    super(form, popupComponent, projectTokenService, intercomService, analyticsService, cd);
  }

  onResourceAccessToken(token: SecretToken) {
    if (token) {
      this.form.form.patchValue({ access_token_name: `{-${token.name}-}` });
    }
  }
}

registerResourceSettingsComponent(ResourceName.RestApi, RestApiResourceSettingsComponent);
registerResourceSettingsComponent(ResourceName.GraphQL, RestApiResourceSettingsComponent);

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, of } from 'rxjs';

import { Domain } from '@modules/domain';
import { PER_PAGE_PARAM } from '@modules/models';
import {
  CurrentEnvironmentStore,
  CurrentProjectStore,
  Project,
  ProjectGroup,
  ProjectGroupService,
  ProjectPublicAccess,
  ProjectPublicAccessService,
  ProjectPublicInvite,
  ProjectPublicInviteService,
  ProjectUser,
  ProjectUserService
} from '@modules/projects';
import { isSet, TypedChanges } from '@shared';

@Component({
  selector: 'app-publish-menu',
  templateUrl: './publish-menu.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PublishMenuComponent implements OnInit, OnDestroy, OnChanges {
  @Input() published = false;
  @Input() draftChangesTotal = 0;
  @Output() close = new EventEmitter<void>();
  @Output() showChanges = new EventEmitter<void>();
  @Output() deleteDraft = new EventEmitter<void>();
  @Output() publishDraft = new EventEmitter<void>();
  @Output() editDomain = new EventEmitter<void>();
  @Output() openAppUrl = new EventEmitter<Domain>();
  @Output() openShareInvite = new EventEmitter<void>();
  @Output() openSharePublicLink = new EventEmitter<void>();
  @Output() openSharePublicAccess = new EventEmitter<void>();
  @Output() openSignUp = new EventEmitter<void>();

  loading = false;
  project: Project;
  signUpEnabled = false;
  signUpGroup: ProjectGroup;
  usersCount = 0;
  publicInvites: ProjectPublicInvite[] = [];
  publicAccess: ProjectPublicAccess[] = [];

  constructor(
    private currentProjectStore: CurrentProjectStore,
    public currentEnvironmentStore: CurrentEnvironmentStore,
    private projectUserService: ProjectUserService,
    private projectGroupService: ProjectGroupService,
    private projectPublicInviteService: ProjectPublicInviteService,
    private projectPublicAccessService: ProjectPublicAccessService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.currentProjectStore
      .get()
      .pipe(untilDestroyed(this))
      .subscribe(result => {
        this.project = result;
        this.signUpEnabled = result ? !!result.defaultGroup : false;
        this.cd.markForCheck();
      });
  }

  ngOnDestroy(): void {}

  ngOnChanges(changes: TypedChanges<PublishMenuComponent>): void {
    if (changes.published) {
      this.fetchShared();
    }
  }

  fetchShared() {
    if (!this.published) {
      return;
    }

    this.loading = true;
    this.cd.markForCheck();

    const signUpGroup$ = isSet(this.currentProjectStore.instance.defaultGroup)
      ? this.projectGroupService.getDetail(
          this.currentProjectStore.instance.uniqueName,
          this.currentEnvironmentStore.instance.uniqueName,
          this.currentProjectStore.instance.defaultGroup
        )
      : of(undefined);

    combineLatest(
      this.projectUserService.getPaginate(
        this.currentProjectStore.instance.uniqueName,
        this.currentEnvironmentStore.instance.uniqueName,
        { [PER_PAGE_PARAM]: 1 }
      ),
      signUpGroup$,
      this.projectPublicInviteService.get(
        this.currentProjectStore.instance.uniqueName,
        this.currentEnvironmentStore.instance.uniqueName
      ),
      this.projectPublicAccessService.get(
        this.currentProjectStore.instance.uniqueName,
        this.currentEnvironmentStore.instance.uniqueName
      )
    )
      .pipe(untilDestroyed(this))
      .subscribe(
        ([users, signUpGroup, publicInvites, publicAccess]) => {
          this.usersCount = users.count;
          this.signUpGroup = signUpGroup;
          this.publicInvites = publicInvites;
          this.publicAccess = publicAccess;
          this.loading = false;
          this.cd.markForCheck();
        },
        () => {
          this.loading = false;
          this.cd.markForCheck();
        }
      );
  }
}

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ChangeDetectorRef, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { fromEvent } from 'rxjs';
import { NotificationService } from '@common/notifications';
import { BasePopupComponent } from '@common/popups';
import { AdminMode } from '@modules/admin-mode';
import { IntercomService, UniversalAnalyticsService } from '@modules/analytics';
import { ApiService } from '@modules/api';
import { customOAuth2BackendName, ProjectTokenService, ResourceName, SecretToken, socialBackends } from '@modules/projects';
import { HttpMethod, HttpQuery, HttpQueryService, submitForm } from '@modules/queries';
import { controlValue, isSet } from '@shared';
import { registerResourceSettingsComponent } from '../../../data/resource-settings-components';
import { ChooseSyncController } from '../../../services/choose-sync/choose-sync.controller';
import { BaseResourceSettingsComponent } from '../base-resource-settings/base-resource-settings.component';
import { HubspotResourceSettingsForm } from './hubspot-resource-settings.form';
var HubspotResourceSettingsComponent = /** @class */ (function (_super) {
    __extends(HubspotResourceSettingsComponent, _super);
    function HubspotResourceSettingsComponent(mode, httpQueryService, apiService, notificationService, form, chooseSyncController, popupComponent, projectTokenService, intercomService, analyticsService, cd) {
        var _this = _super.call(this, form, popupComponent, projectTokenService, intercomService, analyticsService, cd) || this;
        _this.mode = mode;
        _this.httpQueryService = httpQueryService;
        _this.apiService = apiService;
        _this.notificationService = notificationService;
        _this.form = form;
        _this.chooseSyncController = chooseSyncController;
        _this.accountLoading = true;
        return _this;
    }
    HubspotResourceSettingsComponent.prototype.ngOnInit = function () {
        var _this = this;
        _super.prototype.ngOnInit.call(this);
        fromEvent(window, 'message')
            .pipe(untilDestroyed(this))
            .subscribe(function (message) {
            if (message.data && message.data['type'] == 'oauth_response') {
                var data = message.data['params'];
                var secretToken = new SecretToken().deserialize(data);
                _this.form.form.patchValue({
                    access_token: secretToken.value,
                    params: secretToken.params
                });
            }
        });
    };
    HubspotResourceSettingsComponent.prototype.ngOnDestroy = function () { };
    HubspotResourceSettingsComponent.prototype.onFormInit = function () {
        var _this = this;
        _super.prototype.onFormInit.call(this);
        controlValue(this.form.form.controls['access_token'])
            .pipe(untilDestroyed(this))
            .subscribe(function (accessToken) { return _this.updateAccount(accessToken); });
    };
    HubspotResourceSettingsComponent.prototype.updateAccount = function (accessToken) {
        var _this = this;
        if (this.accountSubscription) {
            this.accountSubscription.unsubscribe();
            this.accountSubscription = undefined;
        }
        if (!isSet(accessToken)) {
            this.account = undefined;
            this.accountLoading = false;
            this.cd.markForCheck();
            return;
        }
        this.accountLoading = true;
        this.cd.markForCheck();
        var query = new HttpQuery();
        query.url = 'https://api.hubapi.com/account-info/v3/details';
        query.headers = [{ name: 'Authorization', value: "Bearer " + accessToken }];
        return this.httpQueryService
            .requestBody(query)
            .pipe(untilDestroyed(this))
            .subscribe(function (result) {
            _this.account = "Hub ID " + result.portalId;
            _this.accountSubscription = undefined;
            _this.accountLoading = false;
            _this.cd.markForCheck();
        }, function () {
            _this.account = undefined;
            _this.accountSubscription = undefined;
            _this.accountLoading = false;
            _this.cd.markForCheck();
        });
    };
    HubspotResourceSettingsComponent.prototype.getOAuthToken = function () {
        var _this = this;
        this.apiService
            .refreshToken()
            .pipe(untilDestroyed(this))
            .subscribe(function () {
            var params = { USE_BACKEND_SHARED_PARAMS: 'hubspot' };
            var redirect = _this.redirectUrl();
            var backend = socialBackends.find(function (item) { return item.name == customOAuth2BackendName; });
            var data = [
                {
                    name: 'name',
                    value: _this.form.tokenName
                },
                {
                    name: 'backend',
                    value: backend ? backend.path : ''
                },
                {
                    name: 'params',
                    value: JSON.stringify(params)
                },
                {
                    name: 'access_token',
                    value: _this.apiService.getAccessToken()
                },
                {
                    name: 'redirect_uri',
                    value: redirect
                }
            ];
            if (_this.mode == AdminMode.Builder) {
                data.push({
                    name: 'draft',
                    value: '1'
                });
            }
            var w = open('', '', 'height=800,width=600');
            if (!w) {
                _this.notificationService.error('Failed to open popup', 'Your browser has blocked opening a new window. Please check your browser settings to allow opening pop-ups');
                return;
            }
            submitForm(HttpMethod.POST, _this.apiService.createOAuthTokenUrl, data, w.document);
        });
    };
    HubspotResourceSettingsComponent.prototype.redirectUrl = function () {
        return window.location.origin + "/oauth_response";
    };
    HubspotResourceSettingsComponent.prototype.chooseSync = function () {
        var _this = this;
        this.chooseSyncController
            .chooseSyncMode(this.form.form.controls['sync'], { typeItem: this.typeItem })
            .pipe(untilDestroyed(this))
            .subscribe(function () { return _this.submit(); });
    };
    return HubspotResourceSettingsComponent;
}(BaseResourceSettingsComponent));
export { HubspotResourceSettingsComponent };
registerResourceSettingsComponent(ResourceName.HubSpot, HubspotResourceSettingsComponent);

import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map, publishLast, refCount, switchMap } from 'rxjs/operators';
import { ApiService } from '../../../api/services/api/api.service';
import { Dashboard } from '../../data/dashboard';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../../api/services/api/api.service";
var DashboardService = /** @class */ (function () {
    function DashboardService(http, apiService) {
        this.http = http;
        this.apiService = apiService;
    }
    DashboardService.prototype.get = function (projectName, environmentName, params) {
        var _this = this;
        if (params === void 0) { params = {}; }
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.environmentMethodURL(projectName, environmentName, 'dashboards/');
            var headers = new HttpHeaders();
            var httpParams = new HttpParams({ fromObject: params });
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.get(url, { headers: headers, params: httpParams });
        }), map(function (result) { return result.map(function (item) { return new Dashboard().deserialize(item); }); }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    DashboardService.prototype.create = function (projectName, environmentName, dashboard) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.environmentMethodURL(projectName, environmentName, 'dashboards/');
            var headers = new HttpHeaders();
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.post(url, dashboard.serialize(), { headers: headers });
        }), map(function (result) { return new Dashboard().deserialize(result); }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    DashboardService.prototype.getDetail = function (projectName, environmentName, id) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.environmentMethodURL(projectName, environmentName, "dashboards/" + id + "/");
            var headers = new HttpHeaders();
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.get(url, { headers: headers });
        }), map(function (result) { return new Dashboard().deserialize(result); }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    DashboardService.prototype.setWidgets = function (projectName, environmentName, dashboard, widgets) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.environmentMethodURL(projectName, environmentName, "dashboards/" + dashboard.id + "/set_widgets/");
            var headers = new HttpHeaders();
            var data = {
                widgets: widgets.map(function (item) { return item.serialize(['id', 'x', 'y', 'width', 'height']); })
            };
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.put(url, data, { headers: headers });
        }), map(function (result) { return new Dashboard().deserialize(result); }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    DashboardService.prototype.update = function (projectName, environmentName, dashboard, fields) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.environmentMethodURL(projectName, environmentName, "dashboards/" + dashboard.id + "/");
            var headers = new HttpHeaders();
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.patch(url, dashboard.serialize(fields), { headers: headers });
        }), map(function (result) { return new Dashboard().deserialize(result); }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    DashboardService.prototype.delete = function (projectName, environmentName, dashboard) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.environmentMethodURL(projectName, environmentName, "dashboards/" + dashboard.id + "/");
            var headers = new HttpHeaders();
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.delete(url, { headers: headers });
        }), map(function (result) { return true; }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    DashboardService.ngInjectableDef = i0.defineInjectable({ factory: function DashboardService_Factory() { return new DashboardService(i0.inject(i1.HttpClient), i0.inject(i2.ApiService)); }, token: DashboardService, providedIn: "root" });
    return DashboardService;
}());
export { DashboardService };

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../ui/components/browser-header/browser-header.component.ngfactory";
import * as i2 from "../../../../ui/components/browser-header/browser-header.component";
import * as i3 from "./page-template-preview-stub.component";
var styles_PageTemplatePreviewStubComponent = [];
var RenderType_PageTemplatePreviewStubComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_PageTemplatePreviewStubComponent, data: {} });
export { RenderType_PageTemplatePreviewStubComponent as RenderType_PageTemplatePreviewStubComponent };
export function View_PageTemplatePreviewStubComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "div", [["class", "choose-template__detail-browser"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 6, "div", [["class", "browser-window"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 2, "div", [["class", "browser-window__header"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "app-browser-header", [], null, null, null, i1.View_BrowserHeaderComponent_0, i1.RenderType_BrowserHeaderComponent)), i0.ɵdid(4, 114688, null, 0, i2.BrowserHeaderComponent, [], { addressIconHasDefault: [0, "addressIconHasDefault"], addressSearchIcon: [1, "addressSearchIcon"] }, null), (_l()(), i0.ɵeld(5, 0, null, null, 2, "div", [["class", "browser-window__content"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 1, "div", [["class", "browser-window__content-inner"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 0, "div", [["class", "browser-window__center"]], null, null, null, null, null))], function (_ck, _v) { var currVal_0 = false; var currVal_1 = false; _ck(_v, 4, 0, currVal_0, currVal_1); }, null); }
export function View_PageTemplatePreviewStubComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-page-template-preview-stub", [], null, null, null, View_PageTemplatePreviewStubComponent_0, RenderType_PageTemplatePreviewStubComponent)), i0.ɵdid(1, 114688, null, 0, i3.PageTemplatePreviewStubComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PageTemplatePreviewStubComponentNgFactory = i0.ɵccf("app-page-template-preview-stub", i3.PageTemplatePreviewStubComponent, View_PageTemplatePreviewStubComponent_Host_0, {}, {}, []);
export { PageTemplatePreviewStubComponentNgFactory as PageTemplatePreviewStubComponentNgFactory };

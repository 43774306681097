import { Injectable } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import cloneDeep from 'lodash/cloneDeep';

import { Input } from '@modules/fields';
import { FieldInputControl } from '@modules/parameters';
import { TransformWorkflowStep } from '@modules/workflow';

@Injectable()
export class TransformCustomizeWorkflowStepForm extends FormGroup {
  instance: TransformWorkflowStep;

  controls: {
    name: FormControl;
    value: FieldInputControl;
  };

  constructor() {
    super({
      name: new FormControl(''),
      value: new FieldInputControl({ name: 'value' })
    });
  }

  init(instance: TransformWorkflowStep, options: { firstInit?: boolean } = {}) {
    this.instance = instance;

    if (instance) {
      this.controls.name.patchValue(instance.name);
      this.controls.value.patchValue(instance.value ? instance.value.serialize() : {});
    }

    if (!options.firstInit) {
      this.markAsDirty();
    }
  }

  submit(): TransformWorkflowStep {
    const instance = cloneDeep(this.instance) as TransformWorkflowStep;

    instance.name = this.controls.name.value;

    if (this.controls.value.value) {
      instance.value = new Input().deserialize(this.controls.value.value);
    } else {
      instance.value = undefined;
    }

    return instance;
  }
}

import { JET_APP_GROUPS, JET_APP_USERS, ModelAction } from '@modules/models';
import { JET_APP_RESOURCE } from '@modules/projects';

import { AutomationTriggerType } from '../automation-trigger-type';
import { registerAutomationTriggerForType } from '../automation-trigger-types';
import { AutomationTrigger } from './base';

export class ModelAutomationTrigger extends AutomationTrigger {
  type = AutomationTriggerType.Model;
  resource: string;
  model: string;
  action: ModelAction;

  deserialize(data: Object): this {
    this.resource = data['resource'];
    this.model = data['model'];
    this.action = data['action'];

    return this;
  }

  serialize() {
    return {
      resource: this.resource,
      model: this.model,
      action: this.action
    };
  }

  public getTypeStr(): string {
    if (this.resource == JET_APP_RESOURCE && this.model == JET_APP_USERS) {
      if (this.action == ModelAction.Create) {
        return 'on user joined';
      } else if (this.action == ModelAction.Update) {
        return 'on user updated';
      } else if (this.action == ModelAction.Delete) {
        return 'on user deleted';
      }
    } else if (this.resource == JET_APP_RESOURCE && this.model == JET_APP_GROUPS) {
      if (this.action == ModelAction.Create) {
        return 'on team created';
      } else if (this.action == ModelAction.Update) {
        return 'on team updated';
      } else if (this.action == ModelAction.Delete) {
        return 'on team deleted';
      }
    }

    return super.getTypeStr();
  }

  public getTypeIcon(): string {
    if (this.resource == JET_APP_RESOURCE && this.model == JET_APP_USERS) {
      return 'users_teams';
    } else if (this.resource == JET_APP_RESOURCE && this.model == JET_APP_GROUPS) {
      return 'users_teams';
    } else {
      return super.getTypeIcon();
    }
  }
}

registerAutomationTriggerForType(AutomationTriggerType.Model, ModelAutomationTrigger);

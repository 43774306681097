import * as moment from 'moment';

import { localize } from '@common/localize';
import { isSet } from '@shared';

import { BetweenFieldLookup } from './between';

export class IsPreviousXDaysFieldLookup extends BetweenFieldLookup {
  public lookup = 'is_previous_x_days';
  public param = 'isPreviousXDays';
  public verboseName = localize('is Previous X Days');
  public icon = 'calendar';

  from(value: string): string {
    return moment()
      .subtract(parseFloat(value) * 2, 'days')
      .startOf('day')
      .toISOString();
  }

  to(value: string): string {
    return moment().subtract(value, 'days').endOf('day').toISOString();
  }

  str(field?: string, value?: string, exclude?: boolean): string {
    if (exclude) {
      if (isSet(field) && isSet(value)) {
        return localize('{0} is not previous {1} days', [field, value]);
      } else {
        return localize('is not previous X days');
      }
    } else {
      if (isSet(field) && isSet(value)) {
        return localize('{0} is previous {1} days', [field, value]);
      } else {
        return localize('previous X days');
      }
    }
  }
}

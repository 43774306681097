import { ChangeDetectionStrategy, Component, Injector, Input, OnChanges, OnInit } from '@angular/core';

import { modelFieldToDisplayField } from '@modules/customize';
import { DisplayField, getFieldDescriptionByType } from '@modules/fields';
import { ListItem } from '@modules/list';
import { Model, ModelDescription, ModelFieldType } from '@modules/models';
import { TypedChanges } from '@shared';

@Component({
  selector: 'app-project-resource-data-templates-table',
  templateUrl: './project-resource-data-templates-table.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProjectResourceDataTemplatesTableComponent implements OnInit, OnChanges {
  @Input() modelDescription: ModelDescription;
  @Input() data: Object[] = [];

  columns: DisplayField[] = [];
  rows: ListItem[] = [];

  constructor(private injector: Injector) {}

  ngOnInit() {}

  ngOnChanges(changes: TypedChanges<ProjectResourceDataTemplatesTableComponent>): void {
    if (changes.modelDescription) {
      this.columns = this.modelDescription.fields
        .filter(item => item.type == ModelFieldType.Db)
        .map(item => modelFieldToDisplayField(item));
    }

    if (changes.modelDescription || changes.data) {
      this.rows = this.data
        .map(data => {
          const model = this.createModel().deserialize(this.modelDescription.model, data);

          model.setUp(this.modelDescription);
          model.deserializeAttributes(this.modelDescription.dbFields);

          return {
            columns: this.columns.map(item => {
              const fieldDescription = getFieldDescriptionByType(item.field);
              return {
                column: item.name,
                str: fieldDescription.valueToStr
                  ? fieldDescription.valueToStr(model.getAttribute(item.name), { field: item })
                  : undefined
              };
            }),
            model: model
          };
        })
        .slice(0, 8);
    }
  }

  createModel(): Model {
    return Injector.create({
      providers: [{ provide: Model, deps: [Injector] }],
      parent: this.injector
    }).get<Model>(Model);
  }

  rowTrackByFn(i, item: ListItem) {
    return item.model.primaryKey || i;
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { LocalizeModule } from '@common/localize';
import { TipsModule } from '@common/tips';
import { SharedModule } from '@shared';

import { DataSyncJobTasksItemComponent } from './components/data-sync-job-tasks-item/data-sync-job-tasks-item.component';
import { DataSyncJobTasksComponent } from './components/data-sync-job-tasks/data-sync-job-tasks.component';

@NgModule({
  imports: [CommonModule, SharedModule, TipsModule, LocalizeModule],
  declarations: [DataSyncJobTasksComponent, DataSyncJobTasksItemComponent],
  exports: [DataSyncJobTasksComponent],
  entryComponents: [DataSyncJobTasksComponent]
})
export class DataSyncComponentsModule {}

<div class="sidebar__element">
  <app-sidebar-field [label]="'time format'">
    <app-auto-field
      [form]="form.form"
      [label]="false"
      [field]="
        createField({
          name: 'time_format',
          field: 'SelectField',
          params: {
            options: form.timeFormatOptions,
            classes: ['select_fill', form.form.value['time_format'] == timeFormats.Custom ? 'select_segment-top' : '']
          }
        })
      "
    ></app-auto-field>

    <app-auto-field
      *ngIf="form.form.value['time_format'] == timeFormats.Custom"
      [form]="form.form"
      [label]="false"
      [field]="
        createField({
          name: 'time_format_custom',
          field: 'CharField',
          params: { classes: ['input_fill', 'input_segment-bottom'] }
        })
      "
    ></app-auto-field>
  </app-sidebar-field>
</div>

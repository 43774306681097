<div class="direct-mode-image">
  <div class="direct-mode-image__left">
    <div class="direct-mode-image__connection">
      Direct Connection

      <div class="direct-mode-image__connection-arrow">
        <svg
          width="47px"
          height="13px"
          viewBox="0 0 47 13"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
        >
          <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="Artboard" transform="translate(-934.000000, -387.000000)" fill="currentColor">
              <g id="Group-3" transform="translate(810.846154, 349.000000)">
                <g id="Select-2" transform="translate(123.153846, 0.000000)">
                  <path
                    d="M10.4166313,38.8574844 C10.5148479,39.0157902 10.5668896,39.1983849 10.5668896,39.3846837 L10.566,42.9995467 L39.0954561,43 C39.6477409,43 40.0954561,43.4477153 40.0954561,44 L40.0954561,45 C40.0954561,45.5522847 39.6477409,46 39.0954561,46 L10.566,45.9995467 L10.5668896,50.0147051 C10.5668896,50.5669898 10.1191744,51.0147051 9.56688961,51.0147051 C9.38059084,51.0147051 9.19799613,50.9626633 9.03969031,50.8644468 L0.472937683,45.5494361 C0.00363832165,45.2582719 -0.140768811,44.6417945 0.150395325,44.1724951 C0.231625323,44.0415683 0.342010892,43.9311827 0.472937683,43.8499527 L9.03969031,38.534942 C9.50898967,38.2437779 10.1254671,38.388185 10.4166313,38.8574844 Z M45.0954561,43 C45.6477409,43 46.0954561,43.4477153 46.0954561,44 L46.0954561,45 C46.0954561,45.5522847 45.6477409,46 45.0954561,46 L44.0954561,46 C43.5431714,46 43.0954561,45.5522847 43.0954561,45 L43.0954561,44 C43.0954561,43.4477153 43.5431714,43 44.0954561,43 L45.0954561,43 Z"
                    id="Combined-Shape"
                    transform="translate(23.047728, 44.699626) scale(-1, 1) translate(-23.047728, -44.699626) "
                  ></path>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </div>
    </div>
  </div>
  <div class="direct-mode-image__main">
    <div class="direct-mode-image__block">
      <div
        class="direct-mode-image__block-icon"
        [style.background-image]="'/assets/images/resources/icons/' + typeItem?.icon + '.svg' | appDeployCssUrl"
      ></div>
      <div class="direct-mode-image__block-title" [style.color]="typeItem.color">{{ typeItem?.label }}</div>
    </div>
  </div>
</div>

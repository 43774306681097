import { BehaviorSubject, combineLatest, interval } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';
import { hasClass } from '@shared';
var DriftService = /** @class */ (function () {
    function DriftService() {
        var _this = this;
        this._loaded = new BehaviorSubject(false);
        this._playbookActive = new BehaviorSubject(false);
        this._manuallyShown = new BehaviorSubject(false);
        this.loadedCheck = interval(1000).subscribe(function () {
            if (!_this.drift) {
                return;
            }
            _this.loadedCheck.unsubscribe();
            _this.drift.on('ready', function (api, payload) {
                _this.api = api;
                _this.payload = payload;
                _this._loaded.next(true);
            });
        });
        this.loaded$.subscribe(function () {
            interval(400).subscribe(function () {
                _this._manuallyShown.next(_this.visible);
            });
            _this.drift.on('campaign:dismiss', function () {
                _this._playbookActive.next(false);
            });
            _this.drift.on('conversation:playbookFired', function () {
                _this._playbookActive.next(true);
            });
        });
    }
    Object.defineProperty(DriftService.prototype, "loaded$", {
        get: function () {
            return this._loaded.pipe(filter(function (item) { return item == true; }));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DriftService.prototype, "drift", {
        get: function () {
            return window['drift'];
        },
        enumerable: true,
        configurable: true
    });
    DriftService.prototype.openChat = function () {
        var _this = this;
        this.loaded$.subscribe(function () {
            _this.api.openChat();
        });
    };
    DriftService.prototype.show = function () {
        var _this = this;
        this.loaded$.subscribe(function () {
            _this.api.widget.show();
            _this._manuallyShown.next(true);
        });
    };
    DriftService.prototype.hide = function () {
        var _this = this;
        this.loaded$.subscribe(function () {
            _this.api.widget.hide();
        });
    };
    Object.defineProperty(DriftService.prototype, "visible", {
        get: function () {
            return hasClass(document.getElementById('drift-widget'), 'drift-widget-welcome-expanded-online');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DriftService.prototype, "visible$", {
        get: function () {
            return combineLatest(this._playbookActive, this._manuallyShown).pipe(map(function (_a) {
                var playbookActive = _a[0], manuallyShown = _a[1];
                return playbookActive || manuallyShown;
            }), distinctUntilChanged());
        },
        enumerable: true,
        configurable: true
    });
    return DriftService;
}());
export { DriftService };

import { Injectable } from '@angular/core';
import clone from 'lodash/clone';
import flatten from 'lodash/flatten';
import flow from 'lodash/flow';
import groupBy from 'lodash/groupBy';
import isEqual from 'lodash/isEqual';
import keys from 'lodash/keys';
import toPairs from 'lodash/toPairs';
import uniq from 'lodash/uniq';
import { Observable, of, Subject } from 'rxjs';
import { catchError, debounceTime, map as rxMap, switchMap } from 'rxjs/operators';

import { modelFieldToDisplayField } from '@modules/customize';
import { exactFieldLookup, inFieldLookup } from '@modules/field-lookups';
import { FilterItem2 } from '@modules/filters';
import { Model } from '@modules/models';
import { CurrentEnvironmentStore, CurrentProjectStore, Resource } from '@modules/projects';
import {
  GetQueryOptions,
  JetBridgeResourceController,
  ModelResponse,
  paramsToGetQueryOptions,
  prepareDataSourceColumnForGet,
  ResourceControllerService
} from '@modules/resources';
import { isSet } from '@shared';

import { ModelDescriptionStore } from '../../stores/model-description.store';
import { ModelService } from '../model/model.service';

interface Request {
  model: string;
  params: Object;
  commonParams: Object;
  obs: Subject<Model[]>[];
}

interface SqlRequest {
  resource: Resource;
  query: string;
  params?: Object;
  obs: Subject<ModelResponse.SqlResponse>[];
}

@Injectable()
export class ReducedModelService {
  requests: Request[] = [];
  requestsUpdated = new Subject<void>();

  sqlRequests: SqlRequest[] = [];
  sqlRequestsUpdated = new Subject<void>();

  constructor(
    private modelDescriptionStore: ModelDescriptionStore,
    private modelService: ModelService,
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private resourceControllerService: ResourceControllerService
  ) {
    this.requestsUpdated.pipe(debounceTime(300)).subscribe(() => {
      const requestsByModel = flow([items => groupBy(items, item => this.requestKey(item)), toPairs])(this.requests);

      this.requests = [];

      requestsByModel.forEach(([key, value]) => {
        const modelId = value[0].model;
        const commonParams = value[0].commonParams;
        const queryOptions: GetQueryOptions = commonParams ? paramsToGetQueryOptions(commonParams) : {};

        queryOptions.filters = queryOptions.filters || [];
        queryOptions.paging = { limit: 10000 };

        toPairs(groupBy(flatten(value.map(item => toPairs(item.params))), item => item[0])).forEach(
          ([field, pairs]) => {
            if (pairs.length == 1) {
              const filterItem = new FilterItem2({
                field: [field],
                lookup: exactFieldLookup,
                value: pairs[0][1]
              });
              queryOptions.filters.push(filterItem);
            } else {
              const filterItem = new FilterItem2({
                field: [field],
                lookup: inFieldLookup,
                value: pairs.map(pair => pair[1])
              });
              queryOptions.filters.push(filterItem);
            }
          }
        );

        return this.modelDescriptionStore
          .getDetailFirst(modelId)
          .pipe(
            switchMap(modelDescription => {
              const resource = this.currentEnvironmentStore.resources.find(
                item => item.uniqueName == modelDescription.resource
              );

              queryOptions.columns =
                resource && modelDescription
                  ? modelDescription.fields
                      .map(item => modelFieldToDisplayField(item, false))
                      .map(item => prepareDataSourceColumnForGet(resource, modelDescription, item))
                  : [];

              return this.modelService.getAdv(
                this.currentProjectStore.instance,
                this.currentEnvironmentStore.instance,
                modelId,
                queryOptions
              );
            }),
            catchError(() => of(undefined))
          )
          .subscribe(result => {
            value.forEach(request => {
              if (!result) {
                request.obs.forEach(obs => obs.next([]));
                return;
              }

              const requestResults = result.results.filter(item => {
                return toPairs(request.params).every(([k, v]) => item.getAttribute(k) == v);
              });

              request.obs.forEach(obs => obs.next(requestResults));
            });
          });
      });
    });

    this.sqlRequestsUpdated.pipe(debounceTime(300)).subscribe(() => {
      const requests = this.sqlRequests;
      const resources = uniq(this.sqlRequests.map(item => item.resource));

      this.sqlRequests = [];

      resources.forEach(resource => {
        const resourceRequests = requests.filter(item => item.resource === resource);
        this.modelService
          .sqls(
            resource,
            resourceRequests.map(item => {
              return {
                query: item.query,
                tokens: item.params
              };
            })
          )
          .subscribe(result => {
            result.forEach((item, i) => {
              resourceRequests[i].obs.forEach(obs => obs.next(item));
            });
          });
      });
    });
  }

  requestKey(request: Request) {
    const paramNames = keys(request.params).sort((lhs, rhs) => {
      return lhs < rhs ? -1 : lhs < rhs ? 0 : 1;
    });

    return `${request.model}_${paramNames.join('_')}`;
  }

  get(modelId: string, params = {}, commonParams = {}): Observable<Model[]> {
    const obs = new Subject<Model[]>();
    let request = {
      model: modelId,
      params: params,
      commonParams: commonParams,
      obs: []
    };
    const existing = this.requests.find(item => {
      return (
        item.model == request.model && isEqual(item.params, request.params) && isEqual(item.commonParams, commonParams)
      );
    });

    if (!existing) {
      this.requests.push(request);
    } else {
      request = existing;
    }

    request.obs.push(obs);

    this.requestsUpdated.next();

    return obs;
  }

  getDetail(modelId: string, idField: string, id: any, params = {}, commonParams = {}): Observable<Model> {
    if (!isSet(id) || id == 'null') {
      return of(undefined);
    }

    const resources = this.currentProjectStore.instance.getEnvironmentResources(
      this.currentEnvironmentStore.instance.uniqueName
    );

    return this.modelService.getForModel(resources, modelId).pipe(
      switchMap(modelParams => {
        const [resource, modelDescription, controller] = modelParams;

        if (controller.filtersLookups) {
          const instanceParams = clone(params);
          instanceParams[idField] = id;
          return this.get(modelId, instanceParams, commonParams).pipe(
            rxMap(result => {
              return result.length ? result[0] : undefined;
            })
          );
        } else {
          return controller.modelGetDetail(resource, modelDescription, idField, id, params);
        }
      })
    );
  }

  sql(resource: Resource, query: string, tokens?: Object): Observable<ModelResponse.SqlResponse> {
    const controller = this.resourceControllerService.get<JetBridgeResourceController>(resource.type);

    if (!controller || !controller.checkApiInfo) {
      return of(undefined);
    }

    return controller.checkApiInfo(resource).pipe(
      switchMap(() => {
        const apiInfo = resource ? resource.apiInfo : undefined;
        if (!apiInfo || apiInfo.isCompatibleJetBridge({ jetBridge: '0.3.7', jetDjango: '0.6.0' })) {
          const obs = new Subject<ModelResponse.SqlResponse>();
          let request: SqlRequest = {
            resource: resource,
            query: query,
            params: tokens,
            obs: []
          };
          const existing = this.sqlRequests.find(item => {
            return item.resource == resource && item.query == request.query && isEqual(item.params, request.params);
          });

          if (!existing) {
            this.sqlRequests.push(request);
          } else {
            request = existing;
          }

          request.obs.push(obs);

          this.sqlRequestsUpdated.next();

          return obs;
        } else {
          return this.modelService.sql(resource, query, tokens);
        }
      })
    );
  }
}

<app-auto-field
  *ngIf="form"
  [form]="form"
  [field]="
    createField({
      name: field.name,
      label: field.verboseName,
      field: field.field,
      params: fieldParams,
      required: field.required,
      placeholder: field.placeholder
    })
  "
></app-auto-field>

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FormControl, FormGroup, Validators } from '@angular/forms';
import cloneDeep from 'lodash/cloneDeep';
import { tap } from 'rxjs/operators';
import { ModelDescriptionService, ModelDescriptionStore } from '@modules/model-queries';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
var ModelPrimaryKeyForm = /** @class */ (function (_super) {
    __extends(ModelPrimaryKeyForm, _super);
    function ModelPrimaryKeyForm(currentProjectStore, currentEnvironmentStore, modelDescriptionStore, modelDescriptionService) {
        var _this = _super.call(this, {
            primaryKey: new FormControl(undefined, Validators.required)
        }) || this;
        _this.currentProjectStore = currentProjectStore;
        _this.currentEnvironmentStore = currentEnvironmentStore;
        _this.modelDescriptionStore = modelDescriptionStore;
        _this.modelDescriptionService = modelDescriptionService;
        _this.fieldOptions = [];
        return _this;
    }
    ModelPrimaryKeyForm.prototype.init = function (modelDescription) {
        this.modelDescription = modelDescription;
        this.fieldOptions = this.modelDescription.dbFields.map(function (item) {
            return {
                name: item.verboseName,
                value: item.name
            };
        });
    };
    ModelPrimaryKeyForm.prototype.submit = function () {
        var _this = this;
        var instance = cloneDeep(this.modelDescription);
        instance.primaryKeyField = this.controls.primaryKey.value;
        return this.modelDescriptionService
            .update(this.currentProjectStore.instance.uniqueName, this.currentEnvironmentStore.instance.uniqueName, instance)
            .pipe(tap(function () { return _this.modelDescriptionStore.updateItem(instance); }));
    };
    return ModelPrimaryKeyForm;
}(FormGroup));
export { ModelPrimaryKeyForm };

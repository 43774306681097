import { ResourceName } from '@modules/projects';

import { hubspotResourceParamsMenuSettings } from './hubspot/hubspot-resource-params-menu-settings.stub';
import { hubspotResourceParamsModelDescriptions } from './hubspot/hubspot-resource-params-model-descriptions.stub';
import { hubspotResourceParamsViewSettings } from './hubspot/hubspot-resource-params-view-settings.stub';

import { pipedriveResourceParamsMenuSettings } from './pipedrive/pipedrive-resource-params-menu-settings.stub';
import { pipedriveResourceParamsModelDescriptions } from './pipedrive/pipedrive-resource-params-model-descriptions.stub';
import { pipedriveResourceParamsViewSettings } from './pipedrive/pipedrive-resource-params-view-settings.stub';

import { sendGridResourceParamsActionDescriptions } from './send-grid/send-grid-resource-params-action-descriptions.stub';

import { slackResourceParamsActionDescriptions } from './slack/slack-resource-params-action-descriptions.stub';

import { stripeResourceParamsActionDescriptions } from './stripe/stripe-resource-params-action-descriptions.stub';
import { stripeResourceParamsMenuSettings } from './stripe/stripe-resource-params-menu-settings.stub';
import { stripeResourceParamsModelDescriptions } from './stripe/stripe-resource-params-model-descriptions.stub';
import { stripeResourceParamsViewSettings } from './stripe/stripe-resource-params-view-settings.stub';

import { twilioResourceParamsActionDescriptions } from './twilio/twilio-resource-params-action-descriptions.stub';

import { openaiResourceParamsActionDescriptions } from './openai/openai-resource-params-action-descriptions.stub';

import { zendeskResourceParamsMenuSettings } from './zendesk/zendesk-resource-params-menu-settings.stub';
import { zendeskResourceParamsModelDescriptions } from './zendesk/zendesk-resource-params-model-descriptions.stub';
import { zendeskResourceParamsViewSettings } from './zendesk/zendesk-resource-params-view-settings.stub';

import { mixpanelResourceParamsMenuSettings } from './mixpanel/mixpanel-resource-params-menu-settings.stub';
import { mixpanelResourceParamsModelDescriptions } from './mixpanel/mixpanel-resource-params-model-descriptions.stub';

import { amplitudeResourceParamsMenuSettings } from './amplitude/amplitude-resource-params-menu-settings.stub';
import { amplitudeResourceParamsModelDescriptions } from './amplitude/amplitude-resource-params-model-descriptions.stub';

import { intercomResourceParamsMenuSettings } from './intercom/intercom-resource-params-menu-settings.stub';
import { intercomResourceParamsModelDescriptions } from './intercom/intercom-resource-params-model-descriptions.stub';
import { intercomResourceParamsViewSettings } from './intercom/intercom-resource-params-view-settings.stub';

import { mailchimpResourceParamsActionDescriptions } from './mailchimp/mailchimp-resource-params-action-descriptions.stub';
import { mailchimpResourceParamsMenuSettings } from './mailchimp/mailchimp-resource-params-menu-settings.stub';
import { mailchimpResourceParamsModelDescriptions } from './mailchimp/mailchimp-resource-params-model-descriptions.stub';
import { mailchimpResourceParamsViewSettings } from './mailchimp/mailchimp-resource-params-view-settings.stub';

import { mailgunResourceParamsActionDescriptions } from './mailgun/mailgun-resource-params-action-descriptions.stub';

export const resourceTemplates: {
  name: string;
  modelDescriptions?: Object[];
  actionDescriptions?: Object[];
  viewSettings?: Object[];
  menuSettings?: Object;
}[] = [
  {
    name: ResourceName.HubSpot,
    modelDescriptions: hubspotResourceParamsModelDescriptions,
    viewSettings: hubspotResourceParamsViewSettings,
    menuSettings: hubspotResourceParamsMenuSettings
  },
  {
    name: ResourceName.Pipedrive,
    modelDescriptions: pipedriveResourceParamsModelDescriptions,
    viewSettings: pipedriveResourceParamsViewSettings,
    menuSettings: pipedriveResourceParamsMenuSettings
  },
  {
    name: ResourceName.SendGrid,
    actionDescriptions: sendGridResourceParamsActionDescriptions
  },
  {
    name: ResourceName.Slack,
    actionDescriptions: slackResourceParamsActionDescriptions
  },
  {
    name: ResourceName.Stripe,
    modelDescriptions: stripeResourceParamsModelDescriptions,
    viewSettings: stripeResourceParamsViewSettings,
    actionDescriptions: stripeResourceParamsActionDescriptions,
    menuSettings: stripeResourceParamsMenuSettings
  },
  {
    name: ResourceName.Twilio,
    actionDescriptions: twilioResourceParamsActionDescriptions
  },
  {
    name: ResourceName.OpenAI,
    actionDescriptions: openaiResourceParamsActionDescriptions
  },
  {
    name: ResourceName.Zendesk,
    modelDescriptions: zendeskResourceParamsModelDescriptions,
    viewSettings: zendeskResourceParamsViewSettings,
    menuSettings: zendeskResourceParamsMenuSettings
  },
  {
    name: ResourceName.Mixpanel,
    modelDescriptions: mixpanelResourceParamsModelDescriptions,
    menuSettings: mixpanelResourceParamsMenuSettings
  },
  {
    name: ResourceName.Amplitude,
    modelDescriptions: amplitudeResourceParamsModelDescriptions,
    menuSettings: amplitudeResourceParamsMenuSettings
  },
  {
    name: ResourceName.Intercom,
    modelDescriptions: intercomResourceParamsModelDescriptions,
    viewSettings: intercomResourceParamsViewSettings,
    menuSettings: intercomResourceParamsMenuSettings
  },
  {
    name: ResourceName.Mailchimp,
    modelDescriptions: mailchimpResourceParamsModelDescriptions,
    actionDescriptions: mailchimpResourceParamsActionDescriptions,
    viewSettings: mailchimpResourceParamsViewSettings,
    menuSettings: mailchimpResourceParamsMenuSettings
  },
  {
    name: ResourceName.Mailgun,
    actionDescriptions: mailgunResourceParamsActionDescriptions
  }
];

import { Injectable } from '@angular/core';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import { combineLatest, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { ActionStore } from '@modules/action-queries';
import { ProjectSettingsStore } from '@modules/all-project-settings';
import { TaskQueueStore } from '@modules/collaboration';
import { CustomViewsStore } from '@modules/custom-views';
import { MenuSettingsStore } from '@modules/menu';
import { ModelDescriptionStore } from '@modules/model-queries';

import { CustomViewSettings, PagePermissionsService, ViewSettingsStore, ViewSettingsType } from '@modules/customize';
import {
  CurrentEnvironmentStore,
  CurrentProjectStore,
  DraftItemsType,
  ProjectGroup,
  ProjectGroupService,
  ProjectPermissionType,
  ProjectPropertyStore
} from '@modules/projects';

@Injectable()
export class PublishService {
  constructor(
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private modelDescriptionStore: ModelDescriptionStore,
    private actionStore: ActionStore,
    private viewSettingsStore: ViewSettingsStore,
    private menuSettingsStore: MenuSettingsStore,
    private customViewsStore: CustomViewsStore,
    private taskQueueStore: TaskQueueStore,
    private projectPropertyStore: ProjectPropertyStore,
    private projectSettingsStore: ProjectSettingsStore,
    private pagePermissionsService: PagePermissionsService,
    private projectGroupService: ProjectGroupService
  ) {}

  reloadChanges(draftChanges: { type: DraftItemsType }[]): Observable<boolean> {
    const wait = [];

    if (draftChanges.find(item => item.type == DraftItemsType.ViewSettings)) {
      wait.push(this.viewSettingsStore.getFirst(true));
    }

    if (draftChanges.find(item => item.type == DraftItemsType.ModelDescriptions)) {
      wait.push(this.modelDescriptionStore.getFirst(true));
    }

    if (draftChanges.find(item => item.type == DraftItemsType.ActionDescriptions)) {
      wait.push(this.actionStore.getFirst(true));
    }

    if (draftChanges.find(item => item.type == DraftItemsType.MenuSettings)) {
      wait.push(this.menuSettingsStore.getFirst(true));
    }

    if (draftChanges.find(item => item.type == DraftItemsType.CustomViews)) {
      wait.push(this.customViewsStore.getFirst(true));
    }

    if (
      draftChanges.find(item => item.type == DraftItemsType.Resources) ||
      draftChanges.find(item => item.type == DraftItemsType.SecretTokens) ||
      draftChanges.find(item => item.type == DraftItemsType.Storages)
    ) {
      wait.push(this.currentProjectStore.getFirst(true));
    }

    if (draftChanges.find(item => item.type == DraftItemsType.TaskQueues)) {
      wait.push(this.taskQueueStore.getFirst(true));
    }

    if (draftChanges.find(item => item.type == DraftItemsType.ProjectProperties)) {
      wait.push(this.projectPropertyStore.getFirst(true));
    }

    if (draftChanges.find(item => item.type == DraftItemsType.ProjectSettings)) {
      wait.push(this.projectSettingsStore.getFirst(true));
    }

    return wait.length ? combineLatest(...wait).pipe(map(() => true)) : of(false);
  }

  updatePagePermissions(): Observable<boolean> {
    return combineLatest(
      this.projectGroupService.get(
        this.currentProjectStore.instance.uniqueName,
        this.currentEnvironmentStore.instance.uniqueName
      ),
      this.viewSettingsStore
        .getFirst()
        .pipe(map(result => result.filter(item => item.view == ViewSettingsType.Custom) as CustomViewSettings[]))
    ).pipe(
      switchMap(([groups, pages]) => {
        groups = groups.filter(group => !group.permissionsGroup);

        if (!groups.length) {
          return of(false);
        }

        return combineLatest(
          ...groups.map(group => {
            const pagePermissions = group.permissions.filter(item => item.permissionType == ProjectPermissionType.Page);
            const existingModelPermissions = group.permissions.filter(
              item => item.permissionType == ProjectPermissionType.Model
            );

            const newModelPermissions = this.pagePermissionsService.getModelPermissionsFromPagePermissions(
              pagePermissions,
              existingModelPermissions,
              pages,
              this.actionStore.instance
            );

            if (!isEqual(existingModelPermissions, newModelPermissions)) {
              const newGroup = cloneDeep(group) as ProjectGroup;

              newGroup.permissions = [
                ...group.permissions.filter(item => item.permissionType != ProjectPermissionType.Model),
                ...newModelPermissions
              ];

              return this.projectGroupService.update(
                this.currentProjectStore.instance.uniqueName,
                this.currentEnvironmentStore.instance.uniqueName,
                newGroup
              );
            } else {
              return of(false);
            }
          })
        ).pipe(map(() => true));
      })
    );
  }
}

import { Injectable, Injector } from '@angular/core';
import isArray from 'lodash/isArray';
import { Observable, of } from 'rxjs';
import { delay, filter, map } from 'rxjs/operators';

import { ActionService, WorkflowExecuteEventType, WorkflowExecuteWorkflowFinishedEvent } from '@modules/action-queries';
import { ServerRequestError } from '@modules/api';
import { DataGroup, DatasetGroupLookup } from '@modules/charts';
import { ViewContext, ViewContextElement } from '@modules/customize';
import { ChartWidgetDataSource, DataSourceType, ValueWidgetDataSource } from '@modules/data-sources';
import { Environment, Project } from '@modules/projects';
import { applyFrontendFiltering, ModelResponse } from '@modules/resources';
import { aggregateGetResponse } from '@modules/resources/utils/aggregate';
import { groupGetResponse } from '@modules/resources/utils/group';
import { isSet } from '@shared';

import { WidgetQueryService } from '../widget-query/widget-query.service';

@Injectable()
export class WidgetDataSourceService {
  constructor(
    private widgetQueryService: WidgetQueryService,
    private actionService: ActionService,
    private injector: Injector
  ) {}

  createGetResponse(): ModelResponse.GetResponse {
    return Injector.create({
      providers: [{ provide: ModelResponse.GetResponse, deps: [Injector] }],
      parent: this.injector
    }).get<ModelResponse.GetResponse>(ModelResponse.GetResponse);
  }

  aggregate(options: {
    project: Project;
    environment: Environment;
    dataSource: ValueWidgetDataSource;
    params?: Object;
    staticData?: Object;
    context?: ViewContext;
    contextElement?: ViewContextElement;
    localContext?: Object;
  }): Observable<any> {
    if (options.dataSource.type == DataSourceType.Query) {
      const resource = options.project
        .getEnvironmentResources(options.environment.uniqueName)
        .find(item => item.uniqueName == options.dataSource.queryResource);

      return this.widgetQueryService.aggregateQuery(
        resource,
        options.dataSource.query,
        options.dataSource.func,
        options.dataSource.column,
        options.dataSource.queryParameters,
        options.params,
        options.dataSource.columns
      );
    } else if (options.dataSource.type == DataSourceType.Input) {
      let result: Object[] = isArray(options.staticData) ? options.staticData : [options.staticData];

      result = applyFrontendFiltering(result, options.params, options.dataSource.columns);

      const data = {
        results: result,
        count: result.length
      };
      const response = this.createGetResponse().deserialize(data, undefined, undefined);

      response.results.forEach(item => {
        item.deserializeAttributes(options.dataSource.columns);
      });

      // TODO: No delay breaks pages
      return of(aggregateGetResponse(response, options.dataSource.func, options.dataSource.column)).pipe(delay(0));
    } else if (options.dataSource.type == DataSourceType.Workflow) {
      return this.actionService
        .executeWorkflow(options.dataSource.workflow, options.params, {
          context: options.context,
          contextElement: options.contextElement,
          localContext: options.localContext
        })
        .pipe(
          filter(event => event.type == WorkflowExecuteEventType.WorkflowFinished),
          map((event: WorkflowExecuteWorkflowFinishedEvent) => {
            if (event.error) {
              throw new ServerRequestError(event.error);
            }

            return event.result;
          }),
          map(workflowResult => {
            let result: Object[] = isArray(workflowResult) ? workflowResult : [workflowResult];

            result = applyFrontendFiltering(result, options.params, options.dataSource.columns);

            const data = {
              results: result,
              count: result.length
            };
            const response = this.createGetResponse().deserialize(data, undefined, undefined);

            response.results.forEach(item => {
              item.deserializeAttributes(options.dataSource.columns);
            });

            return aggregateGetResponse(response, options.dataSource.func, options.dataSource.column);
          })
        );
    } else {
      return of(undefined);
    }
  }

  group(options: {
    project: Project;
    environment: Environment;
    datasetColumn?: string;
    dataSource: ChartWidgetDataSource;
    params?: Object;
    staticData?: Object;
    context?: ViewContext;
    contextElement?: ViewContextElement;
    localContext?: Object;
  }): Observable<DataGroup[]> {
    const xColumns: { xColumn: string; xLookup?: DatasetGroupLookup }[] = [];

    if (isSet(options.datasetColumn)) {
      xColumns.push({
        xColumn: options.datasetColumn,
        xLookup: DatasetGroupLookup.Plain
      });
    }

    if (isSet(options.dataSource.xColumn)) {
      xColumns.push({
        xColumn: options.dataSource.xColumn,
        xLookup: options.dataSource.xLookup || DatasetGroupLookup.Auto
      });
    }

    if (isSet(options.dataSource.xColumn2)) {
      xColumns.push({
        xColumn: options.dataSource.xColumn2,
        xLookup: options.dataSource.xLookup2 || DatasetGroupLookup.Auto
      });
    }

    if (options.dataSource.type == DataSourceType.Query) {
      const resource = options.project
        .getEnvironmentResources(options.environment.uniqueName)
        .find(item => item.uniqueName == options.dataSource.queryResource);

      return this.widgetQueryService.groupQuery(
        resource,
        options.dataSource.query,
        xColumns,
        options.dataSource.yFunc,
        options.dataSource.yColumn,
        options.dataSource.queryParameters,
        options.params,
        options.dataSource.columns
      );
    } else if (options.dataSource.type == DataSourceType.Input) {
      let result: Object[] = isArray(options.staticData) ? options.staticData : [options.staticData];

      result = applyFrontendFiltering(result, options.params, options.dataSource.columns);

      const data = {
        results: result,
        count: result.length
      };
      const response = this.createGetResponse().deserialize(data, undefined, undefined);

      response.results.forEach(item => {
        item.deserializeAttributes(options.dataSource.columns);
      });

      // TODO: No delay breaks pages
      return of(groupGetResponse(response, xColumns, options.dataSource.yFunc, options.dataSource.yColumn)).pipe(
        delay(0)
      );
    } else if (options.dataSource.type == DataSourceType.Workflow) {
      return this.actionService
        .executeWorkflow(options.dataSource.workflow, options.params, {
          context: options.context,
          contextElement: options.contextElement,
          localContext: options.localContext
        })
        .pipe(
          filter(event => event.type == WorkflowExecuteEventType.WorkflowFinished),
          map((event: WorkflowExecuteWorkflowFinishedEvent) => {
            if (event.error) {
              throw new ServerRequestError(event.error);
            }

            return event.result;
          }),
          map(workflowResult => {
            let result: Object[] = isArray(workflowResult) ? workflowResult : [workflowResult];

            result = applyFrontendFiltering(result, options.params, options.dataSource.columns);

            const data = {
              results: result,
              count: result.length
            };
            const response = this.createGetResponse().deserialize(data, undefined, undefined);

            response.results.forEach(item => {
              item.deserializeAttributes(options.dataSource.columns);
            });

            return groupGetResponse(response, xColumns, options.dataSource.yFunc, options.dataSource.yColumn);
          })
        );
    } else {
      return of(undefined);
    }
  }
}

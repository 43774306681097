import { localize } from '@common/localize';
import { isSet } from '@shared';

import { FieldLookup } from './base';

export class IsEmptyFieldLookup extends FieldLookup {
  public lookup = 'isempty';
  public param = 'isEmpty';
  public verboseName = localize('is empty');
  public icon = 'deselect';

  str(field?: string, value?: string, exclude?: boolean): string {
    if (exclude) {
      if (isSet(field) && isSet(value)) {
        return localize('{0} is not empty', [field]);
      } else {
        return localize('is not empty');
      }
    } else {
      if (isSet(field) && isSet(value)) {
        return localize('{0} is empty', [field]);
      } else {
        return localize('is empty');
      }
    }
  }
}

import { Component } from '@angular/core';
import { map } from 'rxjs/operators';
import { DialogService } from '@common/dialogs';
import { PopupService } from '@common/popups';
import * as i0 from "@angular/core";
import * as i1 from "../../popups/services/popup/popup.service";
import * as i2 from "../../dialogs/services/dialog/dialog.service";
var NoOpenPopupsGuard = /** @class */ (function () {
    function NoOpenPopupsGuard(popupService, dialogService) {
        this.popupService = popupService;
        this.dialogService = dialogService;
    }
    NoOpenPopupsGuard.prototype.canDeactivate = function (component, currentRoute, currentState, nextState) {
        var closePopups = this.popupService.items.filter(function (item) { return item.popupCloseOnDeactivate && !item.closeRequested; });
        if (!closePopups.length) {
            return true;
        }
        return this.dialogService
            .confirm({
            title: 'Close',
            description: 'Are you sure want to close?',
            style: closePopups[0].popupComponentOrange ? 'orange' : undefined
        })
            .pipe(map(function (confirmed) {
            if (confirmed) {
                closePopups.filter(function (item) { return item.popupRequestClose; }).forEach(function (item) { return item.popupRequestClose.next(); });
            }
            return false;
        }));
    };
    NoOpenPopupsGuard.ngInjectableDef = i0.defineInjectable({ factory: function NoOpenPopupsGuard_Factory() { return new NoOpenPopupsGuard(i0.inject(i1.PopupService), i0.inject(i2.DialogService)); }, token: NoOpenPopupsGuard, providedIn: "root" });
    return NoOpenPopupsGuard;
}());
export { NoOpenPopupsGuard };

import { HttpClient, HttpEventType } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import yaml from 'js-yaml';
import fromPairs from 'lodash/fromPairs';
import toPairs from 'lodash/toPairs';
import OpenAPIV3 from 'openapi-types';
import { Observable, throwError } from 'rxjs';
import { map, publishLast, refCount, tap } from 'rxjs/operators';

import { ApiService } from '@modules/api';
import { FieldType, fileFieldTypes } from '@modules/fields';
import { Model, ModelDescription, ModelField, ORDER_BY_PARAM, PAGE_PARAM, PER_PAGE_PARAM } from '@modules/models';
import { Resource } from '@modules/projects';
import { HTTP_QUERY_KEY_AUTH_NAME } from '@modules/projects-components/data/http-query-auth';
import {
  HttpContentType,
  HttpMethod,
  HttpQuery,
  HttpQueryOptions,
  HttpQueryService,
  StorageQuery
} from '@modules/queries';
import { Storage } from '@modules/storages';
import { AppError, getExtension, getExtensionMime, getFilenameWithExtension, isSet } from '@shared';

import { ModelResponse } from '../../data/model-response';
import {
  XANO_COLLECTION_MODEL_CREATE_PARAM_NAME,
  XANO_COLLECTION_MODEL_DELETE_PARAM_NAME,
  XANO_COLLECTION_MODEL_GET_DETAIL_PARAM_NAME,
  XANO_COLLECTION_MODEL_GET_PARAM_NAME,
  XANO_COLLECTION_MODEL_UPDATE_PARAM_NAME
} from './xano-constants';
import { IXanoGetPagingResponse, XanoStorageParams } from './xano-types';

@Injectable()
export class XanoLegacyResourceController {
  private readonly DEFAULT_PER_PAGE = 25;

  constructor(
    private apiService: ApiService,
    private httpQueryService: HttpQueryService,
    private http: HttpClient,
    private injector: Injector
  ) {}

  createModel(): Model {
    return Injector.create({
      providers: [{ provide: Model, deps: [Injector] }],
      parent: this.injector
    }).get<Model>(Model);
  }

  createGetResponse(): ModelResponse.GetResponse {
    return Injector.create({
      providers: [{ provide: ModelResponse.GetResponse, deps: [Injector] }],
      parent: this.injector
    }).get<ModelResponse.GetResponse>(ModelResponse.GetResponse);
  }

  getSchema(apiBaseUrl: string): Observable<OpenAPIV3.OpenAPIV3.Document> {
    const swaggerSchemaUrl = apiBaseUrl.replace('api:', 'apispec:');

    return this.http.get(swaggerSchemaUrl, { responseType: 'text' }).pipe(
      map<string, OpenAPIV3.OpenAPIV3.Document>(data => {
        try {
          return yaml.load(data);
        } catch (e) {}
      }),
      tap(schema => {
        if (!schema || [schema.openapi, schema.info, schema.paths].some(item => !isSet(item))) {
          throw new AppError(`Not valid Swagger URL`);
        }
      })
    );
  }

  private transformSingleItemResponse(data: Record<string, unknown>, fields: ModelField[]): Record<string, unknown> {
    fields.forEach(field => {
      if (fileFieldTypes.includes(field.item.field) && data[field.name]) {
        data[field.name] = (data[field.name] as Record<string, unknown>)['url'];
      } else if (field.item.field === FieldType.RelatedModel && data[field.name] === 0) {
        data[field.name] = null;
      }
    });

    return data;
  }

  private transformArrayItemsResponse(
    data: Record<string, unknown>[],
    fields: ModelField[]
  ): Record<string, unknown>[] {
    for (const responseItem of data) {
      this.transformSingleItemResponse(responseItem, fields);
    }

    return data;
  }

  serializeModel(instance: Model, modelDescription: ModelDescription, fields?: string[]): Object {
    const data = toPairs(instance.serialize(fields))
      .filter(([k, v]) => {
        const field = modelDescription.dbFields.find(i => i.name == k);
        return field && field.editable;
      })
      .map(([name, value]) => {
        const field = modelDescription.dbFields.find(i => i.name == name);

        if (fileFieldTypes.includes(field.field)) {
          const url = new URL(value);
          const fileName = getFilenameWithExtension(value);
          const fileExtension = getExtension(value) || 'file';
          const fileMime = getExtensionMime(fileExtension);
          const fileMimeType = isSet(fileMime) ? fileMime.split('/')[0] : undefined;
          const fileType = fileMimeType || fileExtension || 'file';
          const fileSize = 0;

          value = {
            path: url.pathname,
            name: fileName,
            type: fileType,
            size: fileSize,
            mime: fileMime,
            meta: {},
            url: value
          };
        }

        return [name, value];
      });

    return fromPairs(data);
  }

  private generateUrl(apiBaseUrl: string, endpoints: string | string[]): string {
    if (Array.isArray(endpoints)) {
      return [apiBaseUrl, ...endpoints].join('/');
    } else {
      return [apiBaseUrl, endpoints].join('/');
    }
  }

  private getMethodFromCollectionParam(modelDescription: ModelDescription, methodKeyName: string): HttpMethod {
    const method = modelDescription.params[methodKeyName];

    if (method === undefined) {
      throw new AppError(`${methodKeyName} is not found in collection params`);
    }

    return method;
  }

  private getHeaders(): { name: string; value: string }[] {
    return [
      {
        name: 'Authorization',
        value: `{-${HTTP_QUERY_KEY_AUTH_NAME}-}`
      }
    ];
  }

  modelGet(
    resource: Resource,
    modelDescription: ModelDescription,
    params?: {},
    body?: {}
  ): Observable<ModelResponse.GetResponse> {
    const options: HttpQueryOptions = { resource: resource.uniqueName };
    const apiBaseUrl = resource.params['api_base_url'];
    const page = parseInt(params[PAGE_PARAM], 10) || 1;
    const perPage = isSet(params[PER_PAGE_PARAM]) ? Math.min(params[PER_PAGE_PARAM], 100) : undefined;
    const external = {
      page: page,
      per_page: perPage
    };
    const statements = modelDescription.dbFields
      .filter(item => params[item.name] !== undefined)
      .map(item => {
        const value = params[item.name];
        return {
          statement: {
            left: {
              tag: 'col',
              operand: item.name
            },
            right: {
              operand: value
            }
          }
        };
      });

    if (statements.length) {
      external['expression'] = statements;
    }

    if (params[ORDER_BY_PARAM]) {
      const [field, ascending] = params[ORDER_BY_PARAM].startsWith('-')
        ? [params[ORDER_BY_PARAM].slice(1), false]
        : [params[ORDER_BY_PARAM], true];

      external['sort'] = [{ sortBy: field, orderBy: ascending ? 'asc' : 'desc' }];
    }

    const query = new HttpQuery();

    query.method = this.getMethodFromCollectionParam(modelDescription, XANO_COLLECTION_MODEL_GET_PARAM_NAME);
    query.url = this.generateUrl(apiBaseUrl, modelDescription.model);
    query.headers = this.getHeaders();
    query.queryParams = [
      {
        name: 'external',
        value: JSON.stringify(external)
      }
    ];

    return this.httpQueryService.requestBody<IXanoGetPagingResponse | Record<string, unknown>[]>(query, options).pipe(
      map(response => {
        let data = {};

        if (response['items'] !== undefined) {
          const responseData = response as IXanoGetPagingResponse;
          data = {
            results: this.transformArrayItemsResponse(
              responseData.items as Record<string, unknown>[],
              modelDescription.fields
            ),
            count: responseData.itemsTotal,
            has_more: responseData.nextPage !== null,
            num_pages: responseData.pageTotal,
            per_page: perPage ? perPage : this.DEFAULT_PER_PAGE
          };
        } else if (Array.isArray(response)) {
          const responseData = response as Record<string, unknown>[];
          data = {
            results: this.transformArrayItemsResponse(
              responseData as Record<string, unknown>[],
              modelDescription.fields
            ),
            count: responseData.length,
            has_more: false,
            num_pages: 1,
            per_page: perPage ? perPage : this.DEFAULT_PER_PAGE
          };
        }

        return this.createGetResponse().deserialize(data, modelDescription.model, undefined);
      }),
      tap(response => {
        response.results.forEach(item => {
          item.deserializeAttributes(modelDescription.dbFields);
        });
      }),
      map(response => {
        if (!response) {
          return;
        }

        response.results.forEach(item => {
          item.setUp(modelDescription);
        });

        return response;
      }),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  modelGetDetail(
    resource: Resource,
    modelDescription: ModelDescription,
    idField: string,
    id: number,
    params?: {}
  ): Observable<Model> {
    params = params || {};

    // const id = params[XANO_PRIMARY_KEY_FIELD_NAME];

    // if (id === undefined) {
    //   return throwError(new MissedIdParamError(XANO_PRIMARY_KEY_FIELD_NAME));
    // }

    const options: HttpQueryOptions = { resource: resource.uniqueName };
    const apiBaseUrl = resource.params['api_base_url'];

    const query = new HttpQuery();

    query.method = this.getMethodFromCollectionParam(modelDescription, XANO_COLLECTION_MODEL_GET_DETAIL_PARAM_NAME);
    query.url = this.generateUrl(apiBaseUrl, [modelDescription.model, String(id)]);
    query.headers = this.getHeaders();

    return this.httpQueryService.requestBody<Record<string, unknown>>(query, options).pipe(
      map(response => {
        if (!response) {
          return;
        }

        response = this.transformSingleItemResponse(response, modelDescription.fields);

        const model = this.createModel().deserialize(undefined, response);
        model.setUp(modelDescription);
        model.deserializeAttributes(modelDescription.dbFields);
        return model;
      }),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  modelCreate(
    resource: Resource,
    modelDescription: ModelDescription,
    modelInstance: Model,
    fields?: string[]
  ): Observable<Model> {
    const options: HttpQueryOptions = { resource: resource.uniqueName };
    const apiBaseUrl = resource.params['api_base_url'];

    const query = new HttpQuery();

    query.method = this.getMethodFromCollectionParam(modelDescription, XANO_COLLECTION_MODEL_CREATE_PARAM_NAME);
    query.url = this.generateUrl(apiBaseUrl, modelDescription.model);
    query.headers = this.getHeaders();
    query.body = this.serializeModel(modelInstance, modelDescription, fields);

    return this.httpQueryService.requestBody<Record<string, unknown>>(query, options).pipe(
      map(result => {
        if (!result) {
          return;
        }

        result = this.transformSingleItemResponse(result, modelDescription.fields);

        const model = this.createModel().deserialize(undefined, result);
        model.setUp(modelDescription);
        model.deserializeAttributes(modelDescription.dbFields);
        return model;
      }),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  modelUpdate(
    resource: Resource,
    modelDescription: ModelDescription,
    modelInstance: Model,
    fields?: string[]
  ): Observable<Model> {
    const id = modelInstance.initialPrimaryKey;

    // if (id === undefined) {
    //   return throwError(new MissedIdParamError(XANO_PRIMARY_KEY_FIELD_NAME));
    // }

    const options: HttpQueryOptions = { resource: resource.uniqueName };
    const apiBaseUrl = resource.params['api_base_url'];

    const query = new HttpQuery();

    query.method = this.getMethodFromCollectionParam(modelDescription, XANO_COLLECTION_MODEL_UPDATE_PARAM_NAME);
    query.url = this.generateUrl(apiBaseUrl, [modelDescription.model, String(id)]);
    query.headers = this.getHeaders();
    query.body = this.serializeModel(modelInstance, modelDescription, fields);

    return this.httpQueryService.requestBody<Record<string, unknown>>(query, options).pipe(
      map(result => {
        if (!result) {
          return;
        }

        result = this.transformSingleItemResponse(result, modelDescription.fields);

        const instance = this.createModel().deserialize(modelDescription.model, result);
        instance.setUp(modelDescription);
        instance.deserializeAttributes(modelDescription.dbFields);
        return instance;
      }),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  modelDelete(resource: Resource, modelDescription: ModelDescription, modelInstance: Model): Observable<Object> {
    const id = modelInstance.initialPrimaryKey;

    // if (id === undefined) {
    //   return throwError(new MissedIdParamError(XANO_PRIMARY_KEY_FIELD_NAME));
    // }

    const options: HttpQueryOptions = { resource: resource.uniqueName };
    const apiBaseUrl = resource.params['api_base_url'];

    const query = new HttpQuery();

    query.method = this.getMethodFromCollectionParam(modelDescription, XANO_COLLECTION_MODEL_DELETE_PARAM_NAME);
    query.url = this.generateUrl(apiBaseUrl, [modelDescription.model, id as string]);
    query.headers = this.getHeaders();

    return this.httpQueryService
      .requestBody<Object>(query, options)
      .pipe(this.apiService.catchApiError(), publishLast(), refCount());
  }

  uploadFile(
    resource: Resource,
    storage: Storage,
    query: StorageQuery,
    file: File,
    path?: string,
    fileName?: string
  ): Observable<ModelResponse.UploadFileResponse> {
    const params = storage.extraParams as XanoStorageParams;

    if (!params.upload) {
      return throwError(new AppError('Upload is not configured'));
    }

    const apiBaseUrl = resource.params['api_base_url'];
    const httpQuery = new HttpQuery();
    const httpOptions: HttpQueryOptions = { resource: resource.uniqueName, tokens: { file: file } };

    httpQuery.method = params.upload.method;
    httpQuery.url = this.generateUrl(apiBaseUrl, 'upload/attachment');
    httpQuery.headers = this.getHeaders();
    httpQuery.bodyType = HttpContentType.FormData;
    httpQuery.body = [{ name: params.upload.file_parameter, value: '{{file}}' }];

    return this.httpQueryService.executeRequest(httpQuery, httpOptions).pipe(
      map(event => {
        if (event.type == HttpEventType.Response) {
          const mediaBaseUrl = new URL(apiBaseUrl).origin;
          return {
            result: {
              uploadedPath: event.body['path'],
              uploadedUrl: mediaBaseUrl + event.body['path'],
              response: event
            },
            state: {
              downloadProgress: 1,
              uploadProgress: 1
            }
          };
        } else if (event.type == HttpEventType.UploadProgress) {
          return {
            state: {
              uploadProgress: event.loaded / event.total,
              downloadProgress: 0,
              uploadLoaded: event.loaded,
              uploadTotal: event.total
            }
          };
        } else if (event.type == HttpEventType.DownloadProgress) {
          return {
            state: {
              uploadProgress: 1,
              downloadProgress: event.loaded / event.total,
              downloadLoaded: event.loaded,
              downloadTotal: event.total
            }
          };
        }
      }),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }
}

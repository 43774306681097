import { FormControl, FormGroup } from '@angular/forms';

import { Point } from '@modules/views';
import { isSet } from '@shared';

export class PointControl extends FormGroup {
  instance: Point;

  controls: {
    x: FormControl;
    y: FormControl;
  };

  constructor(state: Partial<Point> = {}) {
    super({
      x: new FormControl(isSet(state.x) ? state.x : 0),
      y: new FormControl(isSet(state.y) ? state.y : 0)
    });
  }

  deserialize(value: Point, options: { emitEvent?: boolean } = {}) {
    this.instance = value;

    this.controls.x.patchValue(value.x, { emitEvent: options.emitEvent });
    this.controls.y.patchValue(value.y, { emitEvent: options.emitEvent });
  }

  getInstance(instance?: Point): Point {
    if (!instance) {
      instance = new Point();
    }

    instance.x = this.controls.x.value;
    instance.y = this.controls.y.value;

    return instance;
  }

  serialize(): Point {
    return this.getInstance(this.instance);
  }
}

<form [formGroup]="customCodeForm" (ngSubmit)="submit()">
  <app-customize-bar-header
    [title]="'Scripts and Styles'"
    [subtitle]="'Sign In & Sign Up'"
    [backEnabled]="false"
  ></app-customize-bar-header>

  <div class="sidebar__content">
    <div *ngIf="!domain" class="sidebar__element">
      <app-alert [title]="'Domain is not set up'" [secondary]="true">
        <div>To customize sign up page you should connect custom domain to current App</div>
        <div style="margin-top: 4px;">
          <a href="javascript:void(0)" (click)="editDomain()">Connect domain</a>
        </div>
      </app-alert>
    </div>

    <app-sidebar-section
      [description]="
        'This will be applied to the following pages:<br><strong>Sign In, Sign Up, Email Verification, Password Restore, Password Restore Completion</strong>'
      "
      [interactive]="!domain"
      (click)="onDomainRequiredClick()"
    >
      <div class="sidebar__element">
        <app-sidebar-field [label]="'Add Sign In/Sign Up JavaScript'">
          <app-auto-field
            [form]="customCodeForm"
            [label]="false"
            [field]="
              createField({
                name: 'append_scripts',
                field: 'CodeField',
                placeholder: 'document.createElement(\'script\');',
                params: {
                  language: 'javascript',
                  autogrow: true,
                  field: true,
                  field_contrast: true,
                  wrap: true,
                  classes: ['input_fill']
                }
              })
            "
          ></app-auto-field>

          <ng-container description>
            Load 3rd party libraries or run custom JavaScript for Sign In/Sign Up pages.
          </ng-container>
        </app-sidebar-field>
      </div>

      <div class="sidebar__element">
        <app-sidebar-field [label]="'Add Sign In/Sign Up CSS'">
          <app-auto-field
            [form]="customCodeForm"
            [label]="false"
            [field]="
              createField({
                name: 'append_styles',
                field: 'CodeField',
                placeholder: '.example { color: blue; }',
                params: {
                  language: 'css',
                  autogrow: true,
                  field: true,
                  field_contrast: true,
                  wrap: true,
                  classes: ['input_fill']
                }
              })
            "
          ></app-auto-field>

          <ng-container description>
            Overrides Sign In/Sign Up pages styles with custom CSS.
          </ng-container>
        </app-sidebar-field>
      </div>
    </app-sidebar-section>
  </div>

  <app-sidebar-submit
    *ngIf="customCodeForm.dirty"
    [saveDisabled]="customCodeForm.invalid || customCodeForm.disabled"
    [saveLoading]="loading"
    [errors]="customCodeForm | appFormErrors"
    [cancelEnabled]="false"
  >
  </app-sidebar-submit>
</form>

import pickBy from 'lodash/pickBy';

import { Input } from '@modules/fields';

import { getElementByType, registerElementForType } from '../element-items';
import { ElementType } from '../element-type';
import { ElementItem } from './base';

export enum CollapseIndicatorPosition {
  None = 'none',
  Right = 'right',
  Left = 'left'
}

export class CollapseElementItem extends ElementItem {
  public type = ElementType.Collapse;
  public width: number;
  public titleInput: Input;
  public icon: string;
  public children: ElementItem[] = [];
  public openedInput: Input;
  public visibleInput: Input;
  public tooltip: string;
  public collapseIndicatorPosition: CollapseIndicatorPosition = CollapseIndicatorPosition.Right;

  deserialize(data: Object): CollapseElementItem {
    super.deserialize(data);
    this.width = this.params['width'];
    this.icon = this.params['icon'];
    this.tooltip = this.params['tooltip'];

    if (this.params['title_input']) {
      this.titleInput = new Input().deserialize(this.params['title_input']);
    }

    if (this.params['children']) {
      this.children = this.params['children']
        .map(item => {
          const element = getElementByType(item['type']);
          if (!element) {
            console.error(`Unsupported element type: ${item['type']}`);
            return;
          }
          return new element().deserialize(item);
        })
        .filter(item => item != undefined);
    }

    if (this.params['opened_input']) {
      this.openedInput = new Input().deserialize(this.params['opened_input']);
    }

    if (this.params['collapse_indicator_position']) {
      this.collapseIndicatorPosition = this.params['collapse_indicator_position'];
    }

    return this;
  }

  serialize(fields?: string[]): Object {
    this.params = {
      width: this.width,
      title_input: this.titleInput ? this.titleInput.serialize() : null,
      icon: this.icon,
      children: this.children.map(item => item.serialize()),
      opened_input: this.openedInput ? this.openedInput.serialize() : null,
      visible_input: this.visibleInput ? this.visibleInput.serialize() : null,
      tooltip: this.tooltip,
      collapse_indicator_position: this.collapseIndicatorPosition
    };

    let data = super.serialize();
    if (fields) {
      data = <Object>pickBy(data, (v, k) => fields.includes(k));
    }
    return data;
  }

  get analyticsName(): string {
    return 'collapse';
  }

  defaultName() {
    return 'Collapse';
  }

  childrenCount() {
    return this.children.length;
  }
}

registerElementForType(ElementType.Collapse, CollapseElementItem);

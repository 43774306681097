var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ChangeDetectorRef, Injector, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import isEqual from 'lodash/isEqual';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { of } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { NotificationService } from '@common/notifications';
import { ActionControllerService } from '@modules/action-queries';
import { ApiType } from '@modules/api';
import { CustomizeService, ElementType, registerElementComponent } from '@modules/customize';
import { BaseElementComponent } from '@modules/customize-elements';
import { applyParamInput$, ImageOutputFormat } from '@modules/fields';
import { CurrentEnvironmentStore } from '@modules/projects';
import { ResourceControllerService } from '@modules/resources';
import { isSet } from '@shared';
function getElementStateValue(state) {
    return {
        valueInput: state.valueInput ? state.valueInput.serialize() : undefined,
        storageResource: state.storageResource
    };
}
var ImageElementComponent = /** @class */ (function (_super) {
    __extends(ImageElementComponent, _super);
    function ImageElementComponent(customizeService, cd, currentEnvironmentStore, resourceControllerService, actionControllerService, notificationService, injector) {
        var _this = _super.call(this) || this;
        _this.customizeService = customizeService;
        _this.cd = cd;
        _this.currentEnvironmentStore = currentEnvironmentStore;
        _this.resourceControllerService = resourceControllerService;
        _this.actionControllerService = actionControllerService;
        _this.notificationService = notificationService;
        _this.injector = injector;
        _this.state = {};
        return _this;
    }
    ImageElementComponent.prototype.ngOnInit = function () {
        this.customizeEnabled$ = this.customizeService.enabled$.pipe(map(function (item) { return !!item; }));
        this.elementOnChange(this.element);
        this.trackChanges();
    };
    ImageElementComponent.prototype.ngOnDestroy = function () { };
    ImageElementComponent.prototype.ngOnChanges = function (changes) {
        if (changes['element']) {
            this.elementOnChange(this.element);
        }
    };
    ImageElementComponent.prototype.trackChanges = function () {
        var _this = this;
        this.element$
            .pipe(map(function (element) { return _this.getElementState(element); }), untilDestroyed(this))
            .subscribe(function (state) {
            _this.onStateUpdated(state);
            _this.state = state;
        });
    };
    ImageElementComponent.prototype.getElementState = function (element) {
        return {
            valueInput: element.valueInput,
            storageResource: element.storageResource
        };
    };
    ImageElementComponent.prototype.onStateUpdated = function (state) {
        if (!isEqual(getElementStateValue(state), getElementStateValue(this.state))) {
            this.updateValue(state);
        }
    };
    ImageElementComponent.prototype.updateValue = function (state) {
        var _this = this;
        if (this.valueSubscription) {
            this.valueSubscription.unsubscribe();
            this.valueSubscription = undefined;
        }
        var value$;
        if (state.valueInput) {
            value$ = applyParamInput$(state.valueInput, {
                context: this.context,
                handleLoading: true
            }).pipe(distinctUntilChanged(function (lhs, rhs) { return isEqual(lhs, rhs); }));
        }
        else {
            value$ = of(undefined);
        }
        this.valueSubscription = value$.pipe(untilDestroyed(this)).subscribe(function (value) {
            _this.value = value;
            _this.preview = _this.getPreview(state, value);
            _this.cd.markForCheck();
        });
    };
    ImageElementComponent.prototype.getPreview = function (state, value) {
        if (!isSet(value)) {
            return;
        }
        if (this.element.outputFormat == ImageOutputFormat.Storage) {
            var resource = state.storageResource
                ? this.currentEnvironmentStore.resources.find(function (item) { return item.uniqueName == state.storageResource; })
                : undefined;
            var apiInfo = resource ? resource.apiInfo : undefined;
            if (apiInfo && apiInfo.type == ApiType.JetDjango && apiInfo.versionLessThan('0.7.7')) {
                value = value['value'];
            }
            var controller = resource ? this.resourceControllerService.get(resource.type) : undefined;
            if (controller && controller.fileUrl) {
                return controller.fileUrl(resource, value);
            }
        }
        return value;
    };
    ImageElementComponent.prototype.onClick = function (element) {
        if (this.element.clickAction) {
            this.actionControllerService
                .execute(this.element.clickAction, {
                context: this.context,
                injector: this.injector,
                origin: element
            })
                .subscribe();
        }
    };
    return ImageElementComponent;
}(BaseElementComponent));
export { ImageElementComponent };
registerElementComponent({
    type: ElementType.Image,
    component: ImageElementComponent,
    label: 'Image',
    actions: []
});

<div class="status-page">
  <div class="status-page__main">
    <div class="status-page__content">
      <h1 class="status-page__title">
        <ng-container *ngIf="error">{{ error }}</ng-container>
        <ng-container *ngIf="!error">License is not valid</ng-container>
      </h1>
      <div class="status-page__description">
        <ng-container *ngIf="errorDescription">{{ errorDescription }}</ng-container>
        <ng-container *ngIf="!errorDescription"
          >Your license is not valid. Please check documentation or contact support.</ng-container
        >
      </div>
      <div class="status-page__actions">
        <!--        <a [routerLink]="['/']" class="status-page__button button button_primary button_bigger">Go to Home page</a>-->
      </div>
    </div>
  </div>
  <div class="status-page__right">
    <div class="status-page__image">
      <div class="status-page__image-inner status-page__image-inner_type_not-found"></div>
    </div>
  </div>
</div>

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../view-editor-navigator-drag-preview/view-editor-navigator-drag-preview.component.ngfactory";
import * as i2 from "../view-editor-navigator-drag-preview/view-editor-navigator-drag-preview.component";
import * as i3 from "../../../../common/drag-drop2/services/drop-list/drop-list.service";
import * as i4 from "../../services/view-editor-context/view-editor.context";
import * as i5 from "../view-editor-navigator-item/view-editor-navigator-item.component.ngfactory";
import * as i6 from "../../../../common/drag-drop2/directives/drag/drag.token";
import * as i7 from "../../../../common/drag-drop2/directives/drag/drag.directive";
import * as i8 from "../view-editor-navigator-item/view-editor-navigator-item.component";
import * as i9 from "../../../../common/drag-drop2/directives/drag-placeholder/drag-placeholder.directive";
import * as i10 from "../../../../common/drag-drop2/directives/drag-preview/drag-preview.directive";
import * as i11 from "../../../../shared/pipes/is-set/is-set.pipe";
import * as i12 from "../../../../common/drag-drop2/directives/drop-list/drop-list.directive";
import * as i13 from "../../../../common/drag-drop2/directives/drop-list-group/drop-list-group.directive";
import * as i14 from "../../../../common/drag-drop2/services/scroll/scroll.service";
import * as i15 from "@angular/common";
import * as i16 from "./view-editor-navigator-items.component";
import * as i17 from "../../services/view-editor-navigator/view-editor-navigator.service";
import * as i18 from "@angular/platform-browser";
var styles_ViewEditorNavigatorItemsComponent = [];
var RenderType_ViewEditorNavigatorItemsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ViewEditorNavigatorItemsComponent, data: {} });
export { RenderType_ViewEditorNavigatorItemsComponent as RenderType_ViewEditorNavigatorItemsComponent };
function View_ViewEditorNavigatorItemsComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["class", "compact-list__position"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "div", [["class", "compact-list__position-bounds"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 0, "div", [["class", "compact-list__position-line"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 0, "div", [["class", "compact-list__position-dot"]], null, null, null, null, null))], null, null); }
function View_ViewEditorNavigatorItemsComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [], [[4, "width", "px"]], null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "app-view-editor-navigator-drag-preview", [], null, null, null, i1.View_ViewEditorNavigatorDragPreviewComponent_0, i1.RenderType_ViewEditorNavigatorDragPreviewComponent)), i0.ɵdid(2, 114688, null, 0, i2.ViewEditorNavigatorDragPreviewComponent, [i3.DropListService, i4.ViewEditorContext], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, function (_ck, _v) { var currVal_0 = 220; _ck(_v, 0, 0, currVal_0); }); }
function View_ViewEditorNavigatorItemsComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 16777216, null, null, 9, "app-view-editor-navigator-item", [["appDrag", ""], ["class", "compact-list__item"]], null, [[null, "itemCustomize"], [null, "itemAddCustomizing"], [null, "itemRemoveCustomizing"], [null, "visibleUpdated"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("itemCustomize" === en)) {
        var pd_0 = (_co.customizeItem(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } if (("itemAddCustomizing" === en)) {
        var pd_1 = (_co.addCustomizingItem(_v.context.$implicit, $event.shift) !== false);
        ad = (pd_1 && ad);
    } if (("itemRemoveCustomizing" === en)) {
        var pd_2 = (_co.removeCustomizingItem(_v.context.$implicit) !== false);
        ad = (pd_2 && ad);
    } if (("visibleUpdated" === en)) {
        var pd_3 = (_co.onItemVisibleUpdated(_v.context.$implicit) !== false);
        ad = (pd_3 && ad);
    } return ad; }, i5.View_ViewEditorNavigatorItemComponent_0, i5.RenderType_ViewEditorNavigatorItemComponent)), i0.ɵprd(6144, null, i6.APP_DRAG, null, [i7.AppDrag]), i0.ɵdid(2, 212992, [[1, 4]], 2, i7.AppDrag, [i0.ElementRef, i0.ViewContainerRef], { data: [0, "data"] }, null), i0.ɵqud(335544320, 2, { placeholderTemplate: 0 }), i0.ɵqud(335544320, 3, { previewTemplate: 0 }), i0.ɵdid(5, 770048, null, 0, i8.ViewEditorNavigatorItemComponent, [i4.ViewEditorContext, i3.DropListService, i0.ChangeDetectorRef], { layer: [0, "layer"], flexLayout: [1, "flexLayout"], indent: [2, "indent"], search: [3, "search"] }, { itemCustomize: "itemCustomize", itemAddCustomizing: "itemAddCustomizing", itemRemoveCustomizing: "itemRemoveCustomizing", visibleUpdated: "visibleUpdated" }), (_l()(), i0.ɵand(0, null, null, 1, null, View_ViewEditorNavigatorItemsComponent_2)), i0.ɵdid(7, 16384, [[2, 4]], 0, i9.AppDragPlaceholder, [i0.TemplateRef], null, null), (_l()(), i0.ɵand(0, null, null, 1, null, View_ViewEditorNavigatorItemsComponent_3)), i0.ɵdid(9, 16384, [[3, 4]], 0, i10.AppDragPreview, [i0.TemplateRef], null, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit; _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.context.$implicit; var currVal_2 = _co.flexLayout; var currVal_3 = _co.indent; var currVal_4 = _co.search; _ck(_v, 5, 0, currVal_1, currVal_2, currVal_3, currVal_4); }, null); }
export function View_ViewEditorNavigatorItemsComponent_0(_l) { return i0.ɵvid(2, [i0.ɵpid(0, i11.IsSetPipe, []), (_l()(), i0.ɵeld(1, 0, null, null, 8, "div", [["appDropList", ""], ["class", "compact-list"]], [[8, "style", 2]], [[null, "appDropListDropped"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("appDropListDropped" === en)) {
        var pd_0 = (_co.dragDrop($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(2, 4931584, null, 1, i12.AppDropList, [i0.ElementRef, [2, i13.AppDropListGroup], i0.ChangeDetectorRef, i3.DropListService, i14.ScrollService], { data: [0, "data"], swapDistance: [1, "swapDistance"], areaMarginForward: [2, "areaMarginForward"], areaMarginBackward: [3, "areaMarginBackward"], previewMissPointerStartDelta: [4, "previewMissPointerStartDelta"], outsideIndicator: [5, "outsideIndicator"], disabled: [6, "disabled"] }, { dropped: "appDropListDropped" }), i0.ɵqud(603979776, 1, { draggables: 1 }), i0.ɵpad(4, 4), i0.ɵpad(5, 4), i0.ɵpod(6, { x: 0, y: 1 }), i0.ɵppd(7, 1), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ViewEditorNavigatorItemsComponent_1)), i0.ɵdid(9, 278528, null, 0, i15.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"], ngForTrackBy: [1, "ngForTrackBy"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.state; var currVal_2 = 20; var currVal_3 = _ck(_v, 4, 0, (0 - 10), 0, 0, 0); var currVal_4 = _ck(_v, 5, 0, 0, 0, (0 - 10), 0); var currVal_5 = _ck(_v, 6, 0, 20, (0 - 20)); var currVal_6 = true; var currVal_7 = i0.ɵunv(_v, 2, 6, _ck(_v, 7, 0, i0.ɵnov(_v, 0), _co.search)); _ck(_v, 2, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); var currVal_8 = _co.state.displayItems; var currVal_9 = _co.trackLayerFn; _ck(_v, 9, 0, currVal_8, currVal_9); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.style; _ck(_v, 1, 0, currVal_0); }); }
export function View_ViewEditorNavigatorItemsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-view-editor-navigator-items", [], null, null, null, View_ViewEditorNavigatorItemsComponent_0, RenderType_ViewEditorNavigatorItemsComponent)), i0.ɵdid(1, 770048, null, 0, i16.ViewEditorNavigatorItemsComponent, [i4.ViewEditorContext, i17.ViewEditorNavigatorService, i18.DomSanitizer, i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ViewEditorNavigatorItemsComponentNgFactory = i0.ɵccf("app-view-editor-navigator-items", i16.ViewEditorNavigatorItemsComponent, View_ViewEditorNavigatorItemsComponent_Host_0, { items: "items", flexLayout: "flexLayout", indent: "indent", search: "search" }, {}, []);
export { ViewEditorNavigatorItemsComponentNgFactory as ViewEditorNavigatorItemsComponentNgFactory };

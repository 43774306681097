<div class="table-form">
  <div class="table-form__items">
    <div *ngFor="let section of sections" class="table-form__section">
      <div *ngIf="section.title | appIsSet" class="table-form__title">
        <div class="table-form-title">
          <div class="table-form-title__main">{{ section.title }}</div>
        </div>
      </div>

      <div
        *ngFor="let item of section.items"
        class="table-form__item table-form__item_interactive"
        [class.table-form__item_interactive]="item.editable"
        (click)="onClick(item)"
      >
        <div class="table-form-item">
          <div class="table-form-item__left">
            <div class="table-form-item__icon" [ngClass]="'icon-' + item.option.icon"></div>
          </div>

          <div class="table-form-item__main">
            <div
              class="table-form-item__title"
              [class.table-form-item__title_disabled]="!item.editable"
              [title]="item.option.verboseName"
            >
              {{ item.option.verboseName | appCapitalize }}
            </div>
          </div>

          <div class="table-form-item__right">
            <app-toggle
              *ngIf="item.editable"
              class="table-form-item__control"
              [selected]="item.control.controls.enabled.value"
              [orange]="true"
            ></app-toggle>

            <app-toggle
              *ngIf="!item.editable"
              class="table-form-item__control"
              [orange]="true"
              [disabled]="true"
            ></app-toggle>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="!sections.length" class="table-form__item">
      {{ emptyMessage }}
    </div>
  </div>

  <div *ngIf="sections.length" class="table-form__footer">
    <div class="table-form-footer">
      <div class="table-form-footer__main">
        <div
          class="table-form-button table-form-button_orange table-form-footer__item"
          [class.table-form-button_disabled]="control.isAllDisabled()"
          (click)="control.disableAll()"
        >
          <div class="table-form-button__icon icon-close"></div>
          <div class="table-form-button__label">Disable all</div>
        </div>

        <div
          class="table-form-button table-form-button_orange table-form-footer__item"
          [class.table-form-button_disabled]="control.isAllEnabled()"
          (click)="control.enableAll()"
        >
          <div class="table-form-button__icon icon-check_2"></div>
          <div class="table-form-button__label">Enable all</div>
        </div>
      </div>
    </div>
  </div>
</div>

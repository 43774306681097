<td class="card-table__column" style="width: 110px;">
  <div class="card-table__column-inner"></div>
</td>

<td class="card-table__column">
  <div class="card-table__column-inner" style="padding-left: 0;">
    <span [class.loading-animation]="true"><span class="stub-text">Name</span></span>
  </div>
</td>

<td class="card-table__column">
  <div class="card-table__column-inner">
    <span class="card-table__grey">
      <span [class.loading-animation]="true"><span class="stub-text">Date</span></span>
    </span>
  </div>
</td>

<td class="card-table__column">
  <div class="card-table__column-inner"></div>
</td>

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgGxScrollableModule } from 'ng-gxscrollable';

import { DragDropModule } from '@common/drag-drop';
import { DragDrop2Module } from '@common/drag-drop2';
import { DynamicComponentModule } from '@common/dynamic-component';
import { TipsModule } from '@common/tips';
import { AnalyticsModule } from '@modules/analytics';
import { CustomizeBarModule } from '@modules/customize-bar';
import { CustomizeComponentsModule } from '@modules/customize-components';
import { CustomizeUiModule } from '@modules/customize-ui';
import { SidebarModule } from '@modules/sidebar';
import { SharedModule } from '@shared';
import { UiModule } from '@ui';

import { ChangeCustomizeBarComponentsComponent } from './components/change-customize-bar/change-customize-bar-components/change-customize-bar-components.component';
import { ChangeCustomizeBarCustomViewsComponent } from './components/change-customize-bar/change-customize-bar-custom-views/change-customize-bar-custom-views.component';
import { ChangeCustomizeBarTemplatesGroupSectionItemComponent } from './components/change-customize-bar/change-customize-bar-templates/change-customize-bar-templates-group-section-item/change-customize-bar-templates-group-section-item.component';
import { ChangeCustomizeBarTemplatesGroupSectionComponent } from './components/change-customize-bar/change-customize-bar-templates/change-customize-bar-templates-group-section/change-customize-bar-templates-group-section.component';
import { ChangeCustomizeBarTemplatesGroupComponent } from './components/change-customize-bar/change-customize-bar-templates/change-customize-bar-templates-group/change-customize-bar-templates-group.component';
import { ChangeCustomizeBarTemplatesItemComponent } from './components/change-customize-bar/change-customize-bar-templates/change-customize-bar-templates-item/change-customize-bar-templates-item.component';
import { ChangeCustomizeBarTemplatesSelectResourceComponent } from './components/change-customize-bar/change-customize-bar-templates/change-customize-bar-templates-select-resource/change-customize-bar-templates-select-resource.component';
import { ChangeCustomizeBarTemplatesComponent } from './components/change-customize-bar/change-customize-bar-templates/change-customize-bar-templates.component';
import { ChangeCustomizeBarComponent } from './components/change-customize-bar/change-customize-bar.component';
import { ChangeStubComponent } from './components/change-stub/change-stub.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgGxScrollableModule,
    DragDropModule,
    TipsModule,
    DynamicComponentModule,
    SharedModule,
    AnalyticsModule,
    CustomizeBarModule,
    SidebarModule,
    DragDrop2Module,
    CustomizeComponentsModule,
    UiModule,
    CustomizeUiModule
  ],
  declarations: [
    ChangeStubComponent,
    ChangeCustomizeBarComponent,
    ChangeCustomizeBarComponentsComponent,
    ChangeCustomizeBarTemplatesComponent,
    ChangeCustomizeBarTemplatesSelectResourceComponent,
    ChangeCustomizeBarTemplatesGroupComponent,
    ChangeCustomizeBarTemplatesGroupSectionComponent,
    ChangeCustomizeBarTemplatesItemComponent,
    ChangeCustomizeBarTemplatesGroupSectionItemComponent,
    ChangeCustomizeBarCustomViewsComponent
  ],
  exports: [ChangeStubComponent, ChangeCustomizeBarComponent]
})
export class ChangeComponentsModule {}

<div class="sidebar-list-item sidebar-list-item_clickable" (click)="open.emit(item)">
  <div class="sidebar-list-item__left">
    <img
      *ngIf="item.icon"
      class="sidebar-list-item__icon"
      [src]="'/assets/images/resources/icons/' + item.icon + '.svg' | appDeployUrl"
    />
    <span *ngIf="!item.icon" class="sidebar-list-item__icon icon-bank"></span>
  </div>
  <div class="sidebar-list-item__main">
    <div class="sidebar-list-item__title">{{ item.title }}</div>
    <div *ngIf="item.subtitle" class="sidebar-list-item__description">{{ item.subtitle }}</div>
  </div>
  <div class="sidebar-list-item__right">
    <span class="sidebar-list-item__action icon-arrow_forward_2"></span>
  </div>
</div>

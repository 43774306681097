var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FormControl, FormGroup } from '@angular/forms';
import { ObjectQuery, ObjectQueryOperation } from '@modules/queries';
var QueryBuilderObjectForm = /** @class */ (function (_super) {
    __extends(QueryBuilderObjectForm, _super);
    function QueryBuilderObjectForm() {
        var _this = _super.call(this, {
            operation: new FormControl(undefined),
            id: new FormControl(''),
            query: new FormControl(''),
            object_to_array: new FormControl(true),
            request_response: new FormControl(undefined),
            request_tokens: new FormControl({})
        }) || this;
        _this.options = {};
        _this.operationOptions = [
            { value: ObjectQueryOperation.Get, name: 'Get Data' },
            { value: ObjectQueryOperation.Create, name: 'Create Record in Object' },
            { value: ObjectQueryOperation.Update, name: 'Update Record in Object' },
            { value: ObjectQueryOperation.Delete, name: 'Delete Record in Object' }
        ];
        return _this;
    }
    QueryBuilderObjectForm.prototype.init = function (query, arrayResponse, options) {
        if (options === void 0) { options = {}; }
        if (query) {
            this.controls.operation.patchValue(query.operation);
            this.controls.id.patchValue(query.id);
            this.controls.query.patchValue(query.query);
            this.controls.object_to_array.patchValue(query.queryOptions.objectToArray);
            this.controls.request_response.patchValue(query.requestResponse);
            this.controls.request_tokens.patchValue(query.requestTokens);
        }
        else {
            this.controls.operation.patchValue(options.forceOperation || options.defaultOperation);
            this.controls.object_to_array.patchValue(!!arrayResponse);
        }
    };
    QueryBuilderObjectForm.prototype.getInstance = function () {
        var instance = new ObjectQuery();
        instance.operation = this.controls.operation.value;
        instance.id = this.controls.id.value;
        instance.query = this.controls.query.value;
        instance.queryOptions = { objectToArray: this.controls.object_to_array.value };
        instance.requestResponse = this.controls.request_response.value;
        instance.requestTokens = this.controls.request_tokens.value;
        return instance;
    };
    return QueryBuilderObjectForm;
}(FormGroup));
export { QueryBuilderObjectForm };

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { combineLatest, Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { AdminMode } from '@modules/admin-mode';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { SingletonStore } from '@shared';
import { ActionDescriptionService } from '../services/action-description/action-description.service';
var ActionStore = /** @class */ (function (_super) {
    __extends(ActionStore, _super);
    function ActionStore(mode$, mode, actionDescriptionService, currentProjectStore, currentEnvironmentStore) {
        var _this = _super.call(this) || this;
        _this.mode$ = mode$;
        _this.mode = mode;
        _this.actionDescriptionService = actionDescriptionService;
        _this.currentProjectStore = currentProjectStore;
        _this.currentEnvironmentStore = currentEnvironmentStore;
        return _this;
    }
    ActionStore.prototype.getOverrides = function (options) {
        var _this = this;
        if (options === void 0) { options = {}; }
        if (options.forceUpdate && this.overrides) {
            this.overrides = undefined;
        }
        if (this.overrides) {
            return of(this.overrides);
        }
        return this.actionDescriptionService
            .getOverrides(this.currentProjectStore.instance, this.currentEnvironmentStore.instance, options)
            .pipe(tap(function (result) {
            _this.overrides = result;
        }));
    };
    ActionStore.prototype.getActions = function (options) {
        var _this = this;
        if (options === void 0) { options = {}; }
        return combineLatest(this.actionDescriptionService.getFromResources(this.currentProjectStore.instance, this.currentEnvironmentStore.instance), this.actionDescriptionService.getAutoActions(), this.getOverrides(options)).pipe(map(function (_a) {
            var fromResource = _a[0], autoActions = _a[1], overrideResult = _a[2];
            var originalResult = fromResource.concat(autoActions);
            _this.actionDescriptionService.applyOverrides(originalResult, overrideResult);
            return originalResult;
        }));
    };
    ActionStore.prototype.fetch = function () {
        var _this = this;
        return this.mode$.pipe(switchMap(function (mode) {
            return _this.getActions({ draft: mode == AdminMode.Builder, forceUpdate: true }).pipe(map(function (result) {
                if (!result) {
                    return [];
                }
                return result;
            }), catchError(function () {
                return of([]);
            }));
        }));
    };
    ActionStore.prototype.get = function (forceUpdate, options) {
        if (forceUpdate === void 0) { forceUpdate = false; }
        if (options === void 0) { options = {}; }
        return _super.prototype.get.call(this, forceUpdate, options).pipe(map(function (result) {
            if (!result) {
                return result;
            }
            return result.filter(function (item) { return options.includeDeleted || !item.deleted; });
        }));
    };
    ActionStore.prototype.getFirst = function (forceUpdate, options) {
        if (forceUpdate === void 0) { forceUpdate = false; }
        if (options === void 0) { options = {}; }
        return _super.prototype.getFirst.call(this, forceUpdate, options).pipe(map(function (result) {
            if (!result) {
                return result;
            }
            return result.filter(function (item) { return options.includeDeleted || !item.deleted; });
        }));
    };
    ActionStore.prototype.getDetail = function (id, forceUpdate, options) {
        if (forceUpdate === void 0) { forceUpdate = false; }
        if (options === void 0) { options = {}; }
        return this.get(forceUpdate, options).pipe(map(function (result) {
            if (!result) {
                return;
            }
            return result.find(function (item) { return item.isSame(id); });
        }));
    };
    ActionStore.prototype.getDetailFirst = function (id, forceUpdate, options) {
        if (forceUpdate === void 0) { forceUpdate = false; }
        if (options === void 0) { options = {}; }
        return this.getFirst(forceUpdate, options).pipe(map(function (result) {
            if (!result) {
                return;
            }
            return result.find(function (item) { return item.isSame(id); });
        }));
    };
    ActionStore.prototype.addItem = function (actionDescription) {
        this.addItems([actionDescription]);
    };
    ActionStore.prototype.addItems = function (actionDescription) {
        this.instance = this.instance.concat(actionDescription);
    };
    ActionStore.prototype.updateItem = function (actionDescription) {
        var index = this.instance.findIndex(function (item) { return item.isSame(actionDescription); });
        if (index !== -1) {
            this.instance = this.instance.map(function (item, i) {
                if (i === index) {
                    return actionDescription;
                }
                else {
                    return item;
                }
            });
        }
    };
    ActionStore.prototype.getUpdateItems = function (actionDescriptions) {
        var replacesFound = false;
        var newInstance = this.instance.map(function (instanceItem, i) {
            var replaceItem = actionDescriptions.find(function (item) { return item.isSame(instanceItem); });
            if (!replacesFound && replaceItem) {
                replacesFound = true;
            }
            return replaceItem || instanceItem;
        });
        if (replacesFound) {
            return newInstance;
        }
    };
    ActionStore.prototype.updateItems = function (actionDescriptions) {
        var updatedItems = this.getUpdateItems(actionDescriptions);
        if (updatedItems) {
            this.instance = updatedItems;
        }
    };
    ActionStore.prototype.syncItems = function (newItems, itemsFilter) {
        var currentItemsByName = this.instance.reduce(function (acc, item) {
            acc[item.name] = item;
            return acc;
        }, {});
        var syncItems = newItems.reduce(function (acc, item) {
            if (currentItemsByName[item.name]) {
                acc.update.push(item);
            }
            else {
                acc.add.push(item);
            }
            return acc;
        }, { add: [], update: [], delete: [] });
        var updatedItems = syncItems.update.length ? this.getUpdateItems(syncItems.update) : undefined;
        var preserveItems = updatedItems || this.instance;
        if (itemsFilter) {
            var newItemsByName_1 = newItems.reduce(function (acc, item) {
                acc[item.name] = item;
                return acc;
            }, {});
            syncItems.delete = preserveItems.filter(function (item) { return itemsFilter(item) && !newItemsByName_1[item.name]; });
        }
        if (syncItems.add.length || syncItems.delete.length || updatedItems) {
            this.instance = preserveItems.filter(function (preserveItem) { return !syncItems.delete.find(function (item) { return item === preserveItem; }); }).concat(syncItems.add);
        }
    };
    ActionStore.prototype.deleteItem = function (actionDescription) {
        var index = this.instance.findIndex(function (item) { return item.isSame(actionDescription); });
        if (index !== -1) {
            this.instance = this.instance.filter(function (item, i) { return i !== index; });
        }
    };
    ActionStore.prototype.syncAutoActions = function (modelDescription) {
        var _this = this;
        return combineLatest(this.actionDescriptionService.getAutoActions({ modelDescription: modelDescription }), this.getOverrides({ draft: this.mode == AdminMode.Builder })).pipe(map(function (_a) {
            var originalResult = _a[0], overrideResult = _a[1];
            _this.actionDescriptionService.applyOverrides(originalResult, overrideResult, { onlyExistent: true });
            _this.syncItems(originalResult, function (item) { return item.resource == modelDescription.resource && item.model == modelDescription.model; });
            return true;
        }));
    };
    return ActionStore;
}(SingletonStore));
export { ActionStore };

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ChangeDetectorRef, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { MenuItemActionService, SimpleMenuItem } from '@modules/menu';
import { BaseMenuItemComponent } from '../base-menu-item/base-menu-item.component';
var SimpleMenuItemComponent = /** @class */ (function (_super) {
    __extends(SimpleMenuItemComponent, _super);
    function SimpleMenuItemComponent(menuItemActionService, cd) {
        var _this = _super.call(this) || this;
        _this.menuItemActionService = menuItemActionService;
        _this.cd = cd;
        return _this;
    }
    SimpleMenuItemComponent.prototype.ngOnInit = function () {
        this.updateLink();
    };
    SimpleMenuItemComponent.prototype.ngOnDestroy = function () { };
    SimpleMenuItemComponent.prototype.ngOnChanges = function (changes) {
        if (changes.menuItem && !changes.menuItem.firstChange) {
            this.updateLink();
        }
    };
    SimpleMenuItemComponent.prototype.updateLink = function () {
        var _this = this;
        this.menuItemActionService
            .getActionExecution(this.menuItem.action, { context: this.context })
            .pipe(untilDestroyed(this))
            .subscribe(function (result) {
            _this.link = result.link;
            _this.href = result.href;
            _this.queryParams = result.queryParams;
            _this.handler = result.handler;
            _this.cd.markForCheck();
        });
    };
    return SimpleMenuItemComponent;
}(BaseMenuItemComponent));
export { SimpleMenuItemComponent };

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { HttpClient, HttpHeaders } from '@angular/common/http';
import cloneDeep from 'lodash/cloneDeep';
import { map, publishLast, refCount, switchMap } from 'rxjs/operators';
import { ApiService } from '@modules/api';
import { SecretToken } from '../../data/secret-token';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../../api/services/api/api.service";
var SecretTokenService = /** @class */ (function () {
    function SecretTokenService(http, apiService) {
        this.http = http;
        this.apiService = apiService;
    }
    SecretTokenService.prototype.get = function (projectName, environmentName, resourceName, params, draft) {
        var _this = this;
        if (params === void 0) { params = {}; }
        if (draft === void 0) { draft = false; }
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.environmentMethodURL(projectName, environmentName, "resources/" + resourceName + "/secret_tokens/");
            var headers = new HttpHeaders();
            var httpParams = __assign({}, params, (draft && { draft: '1' }));
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.get(url, { headers: headers, params: httpParams });
        }), map(function (result) { return result.map(function (item) { return new SecretToken().deserialize(item); }); }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    SecretTokenService.prototype.getDetail = function (projectName, environmentName, resourceName, id, draft) {
        var _this = this;
        if (draft === void 0) { draft = false; }
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.environmentMethodURL(projectName, environmentName, "resources/" + resourceName + "/secret_tokens/" + id + "/");
            var params = __assign({}, (draft && { draft: '1' }));
            var headers = new HttpHeaders();
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.get(url, { headers: headers, params: params });
        }), map(function (result) { return new SecretToken().deserialize(result); }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    SecretTokenService.prototype.createBulk = function (projectName, environmentName, resourceName, instances) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.environmentMethodURL(projectName, environmentName, "resources/" + resourceName + "/secret_tokens/");
            var headers = new HttpHeaders();
            var params = {
                draft: '1'
            };
            var data = instances.map(function (item) { return item.serialize(); });
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.post(url, data, { headers: headers, params: params });
        }), map(function (result) { return result.map(function (item) { return new SecretToken().deserialize(item); }); }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    SecretTokenService.prototype.create = function (projectName, environmentName, resourceName, instance) {
        return this.createBulk(projectName, environmentName, resourceName, [instance]).pipe(map(function (result) { return result[0]; }));
        // return this.apiService.refreshToken().pipe(
        //   switchMap(() => {
        //     const url = this.apiService.environmentMethodURL(
        //       projectName,
        //       environmentName,
        //       `resources/${resourceName}/secret_tokens/`
        //     );
        //     let headers = new HttpHeaders();
        //
        //     headers = this.apiService.setHeadersToken(headers);
        //
        //     return this.http.post(url, instance.serialize(), { headers: headers });
        //   }),
        //   map(result => new SecretToken().deserialize(result)),
        //   this.apiService.catchApiError(),
        //   publishLast(),
        //   refCount()
        // );
    };
    SecretTokenService.prototype.update = function (projectName, environmentName, resourceName, instance) {
        return this.createBulk(projectName, environmentName, resourceName, [instance]).pipe(map(function (result) { return result[0]; }));
        // return this.apiService.refreshToken().pipe(
        //   switchMap(() => {
        //     const url = this.apiService.environmentMethodURL(
        //       projectName,
        //       environmentName,
        //       `resources/${resourceName}/secret_tokens/${instance.name}/`
        //     );
        //     let headers = new HttpHeaders();
        //
        //     headers = this.apiService.setHeadersToken(headers);
        //
        //     return this.http.patch(url, instance.serialize(), { headers: headers });
        //   }),
        //   map(result => new SecretToken().deserialize(result)),
        //   this.apiService.catchApiError(),
        //   publishLast(),
        //   refCount()
        // );
    };
    SecretTokenService.prototype.delete = function (projectName, environmentName, resourceName, instance) {
        instance = cloneDeep(instance);
        instance.deleted = true;
        return this.createBulk(projectName, environmentName, resourceName, [instance]).pipe(map(function () { return true; }));
        // return this.apiService.refreshToken().pipe(
        //   switchMap(() => {
        //     const url = this.apiService.environmentMethodURL(
        //       projectName,
        //       environmentName,
        //       `resources/${resourceName}/secret_tokens/${instance.name}/`
        //     );
        //     let headers = new HttpHeaders();
        //
        //     headers = this.apiService.setHeadersToken(headers);
        //
        //     return this.http.delete(url, { headers: headers });
        //   }),
        //   map(result => true),
        //   this.apiService.catchApiError(),
        //   publishLast(),
        //   refCount()
        // );
    };
    SecretTokenService.ngInjectableDef = i0.defineInjectable({ factory: function SecretTokenService_Factory() { return new SecretTokenService(i0.inject(i1.HttpClient), i0.inject(i2.ApiService)); }, token: SecretTokenService, providedIn: "root" });
    return SecretTokenService;
}());
export { SecretTokenService };

import { Injectable, Injector } from '@angular/core';
import { Subject } from 'rxjs';

import { PopupDynamicComponentArguments, PopupService } from '@common/popups';

import { TimelinePopupComponent } from '../../components/timeline-popup/timeline-popup.component';

@Injectable()
export class TimelineController {
  closeObs: Subject<void>;

  constructor(private popupService: PopupService, private injector: Injector) {}

  open(objectType: string, objectId: any, params = {}): PopupDynamicComponentArguments {
    if (this.closeObs) {
      this.closeObs.next();
    }

    params = {
      object_type: objectType,
      object_id: objectId,
      ...params
    };

    const closeObs = new Subject<void>();

    this.closeObs = closeObs;

    return this.popupService.push({
      component: TimelinePopupComponent,
      disablePointerEvents: true,
      enableWindowScroll: true,
      inputs: {
        baseParams: params,
        closeObs: closeObs.asObservable()
      },
      outputs: {
        closeObsEvent: [
          () => {
            if (this.closeObs === closeObs) {
              this.closeObs = undefined;
            }
          }
        ]
      },
      popupClosed: () => {
        if (this.closeObs === closeObs) {
          this.closeObs = undefined;
        }
      },
      injector: this.injector
    });
  }
}

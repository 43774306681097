/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../project-group-change-popup/project-group-change-popup.component.ngfactory";
import * as i2 from "../project-group-change/project-model-permissions.array";
import * as i3 from "../../../model-queries/stores/model-description.store";
import * as i4 from "../project-group-change/project-page-permissions.array";
import * as i5 from "../../../customize/stores/view-settings.store";
import * as i6 from "../project-group-change/project-group-change.form";
import * as i7 from "../../../../common/form-utils/services/form-utils";
import * as i8 from "../../../projects/stores/current-project.store";
import * as i9 from "../../../projects/stores/project-group.store";
import * as i10 from "../../../projects/stores/current-environment.store";
import * as i11 from "../../../projects/services/project-group/project-group.service";
import * as i12 from "../../../action-queries/stores/action.store";
import * as i13 from "../../../customize/services/page-permissions/page-permissions.service";
import * as i14 from "../../forms/project-user.form";
import * as i15 from "../../../projects/services/project-user/project-user.service";
import * as i16 from "@angular/forms";
import * as i17 from "../project-group-change-popup/project-group-change-popup.component";
import * as i18 from "../../../admin-mode/providers/route-admin-mode";
import * as i19 from "../../../../common/popups/components/base-popup/base-popup.component";
import * as i20 from "../../../../common/notifications/services/notification/notification.service";
import * as i21 from "../../../routing/services/routing/routing.service";
import * as i22 from "../../../tutorial/services/guide/guide.service";
import * as i23 from "../../../projects/services/project-invite/project-invite.service";
import * as i24 from "../../../../common/dialogs/services/dialog/dialog.service";
import * as i25 from "../../../analytics/services/universal-analytics/universal-analytics.service";
import * as i26 from "../../../users/stores/current-user.store";
import * as i27 from "./project-group-edit-popup.component";
var styles_ProjectGroupEditPopupComponent = [];
var RenderType_ProjectGroupEditPopupComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ProjectGroupEditPopupComponent, data: {} });
export { RenderType_ProjectGroupEditPopupComponent as RenderType_ProjectGroupEditPopupComponent };
export function View_ProjectGroupEditPopupComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "app-project-group-change-popup", [], null, [[null, "saved"], [null, "deleted"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("saved" === en)) {
        var pd_0 = (_co.onSaved($event) !== false);
        ad = (pd_0 && ad);
    } if (("deleted" === en)) {
        var pd_1 = (_co.onDeleted($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i1.View_ProjectGroupChangePopupComponent_0, i1.RenderType_ProjectGroupChangePopupComponent)), i0.ɵprd(131584, null, i2.ProjectModelPermissionsArray, i2.ProjectModelPermissionsArray, [i3.ModelDescriptionStore]), i0.ɵprd(131584, null, i4.ProjectPagePermissionsArray, i4.ProjectPagePermissionsArray, [i5.ViewSettingsStore]), i0.ɵprd(131584, null, i6.ProjectGroupChangeForm, i6.ProjectGroupChangeForm, [i2.ProjectModelPermissionsArray, i4.ProjectPagePermissionsArray, i7.FormUtils, i8.CurrentProjectStore, i9.ProjectGroupStore, i10.CurrentEnvironmentStore, i11.ProjectGroupService, i12.ActionStore, i5.ViewSettingsStore, i13.PagePermissionsService]), i0.ɵprd(512, null, i14.ProjectUserForm, i14.ProjectUserForm, [i7.FormUtils, i8.CurrentProjectStore, i10.CurrentEnvironmentStore, i15.ProjectUserService, i16.FormBuilder]), i0.ɵdid(5, 245760, null, 0, i17.ProjectGroupChangePopupComponent, [i18.ROUTE_ADMIN_MODE, i11.ProjectGroupService, i9.ProjectGroupStore, i8.CurrentProjectStore, i10.CurrentEnvironmentStore, [2, i19.BasePopupComponent], i20.NotificationService, i6.ProjectGroupChangeForm, i21.RoutingService, i22.GuideService, i0.ChangeDetectorRef, i15.ProjectUserService, i23.ProjectInviteService, i24.DialogService, i25.UniversalAnalyticsService, i26.CurrentUserStore, i14.ProjectUserForm], { group: [0, "group"], members: [1, "members"], propertiesEnabled: [2, "propertiesEnabled"], deleteEnabled: [3, "deleteEnabled"] }, { saved: "saved", deleted: "deleted" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.group; var currVal_1 = false; var currVal_2 = _co.propertiesEnabled; var currVal_3 = _co.deleteEnabled; _ck(_v, 5, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
export function View_ProjectGroupEditPopupComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "app-project-group-edit-popup", [], null, null, null, View_ProjectGroupEditPopupComponent_0, RenderType_ProjectGroupEditPopupComponent)), i0.ɵprd(135680, null, i2.ProjectModelPermissionsArray, i2.ProjectModelPermissionsArray, [i3.ModelDescriptionStore]), i0.ɵprd(135680, null, i4.ProjectPagePermissionsArray, i4.ProjectPagePermissionsArray, [i5.ViewSettingsStore]), i0.ɵdid(3, 180224, null, 0, i27.ProjectGroupEditPopupComponent, [i19.BasePopupComponent], null, null)], null, null); }
var ProjectGroupEditPopupComponentNgFactory = i0.ɵccf("app-project-group-edit-popup", i27.ProjectGroupEditPopupComponent, View_ProjectGroupEditPopupComponent_Host_0, { group: "group", propertiesEnabled: "propertiesEnabled", deleteEnabled: "deleteEnabled" }, { created: "created", updated: "updated", deleted: "deleted" }, []);
export { ProjectGroupEditPopupComponentNgFactory as ProjectGroupEditPopupComponentNgFactory };

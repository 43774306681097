import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-toggle-content',
  templateUrl: './toggle-content.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToggleContentComponent implements OnInit {
  @Input() title: string;
  @Input() tabLabel: string;
  @Input() tabEnabled = false;
  @Input() description: string;
  @Output() tabEnabledToggle = new EventEmitter<void>();

  constructor() {}

  ngOnInit() {}

  toggleTabEnabled() {
    this.tabEnabledToggle.emit();
  }
}

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./preview-tip.component";
var styles_PreviewTipComponent = [];
var RenderType_PreviewTipComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_PreviewTipComponent, data: {} });
export { RenderType_PreviewTipComponent as RenderType_PreviewTipComponent };
export function View_PreviewTipComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "preview-tip"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "div", [["class", "preview-tip__bounds"]], [[4, "width", "px"], [4, "height", "px"], [4, "padding-top", "%"]], null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 0, "div", [["class", "preview-tip__preview"]], [[4, "background-image", null]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.width; var currVal_1 = _co.height; var currVal_2 = ((_co.height / _co.width) * 100); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = (("url(" + _co.url) + ")"); _ck(_v, 2, 0, currVal_3); }); }
export function View_PreviewTipComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-preview-tip", [], null, null, null, View_PreviewTipComponent_0, RenderType_PreviewTipComponent)), i0.ɵdid(1, 114688, null, 0, i1.PreviewTipComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PreviewTipComponentNgFactory = i0.ɵccf("app-preview-tip", i1.PreviewTipComponent, View_PreviewTipComponent_Host_0, { url: "url", width: "width", height: "height" }, {}, []);
export { PreviewTipComponentNgFactory as PreviewTipComponentNgFactory };

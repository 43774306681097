import { Injectable } from '@angular/core';
import clone from 'lodash/clone';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ListStore, ListStoreFetchResponse } from '@shared';

// TODO: Refactor import
import { PAGE_PARAM, PER_PAGE_PARAM } from '../../models/data/params';

import { ProjectUser } from '../data/project-user';
import { ProjectBillingService } from '../services/project-billing/project-billing.service';
import { CurrentEnvironmentStore } from './current-environment.store';
import { CurrentProjectStore } from './current-project.store';

@Injectable()
export class SubscriptionEnvironmentUserListStore extends ListStore<ProjectUser> {
  environment: string;
  params = {};

  constructor(
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private projectBillingService: ProjectBillingService
  ) {
    super();
  }

  paramsForPage(page) {
    const params = clone(this.params);
    params['environment'] = this.environment;
    params[PAGE_PARAM] = page;
    params[PER_PAGE_PARAM] = this.perPage;
    return params;
  }

  fetchPage(page: number, next: boolean): Observable<ListStoreFetchResponse<ProjectUser>> {
    const params = this.paramsForPage(page);

    return this.projectBillingService
      .getSubscriptionUsers(
        this.currentProjectStore.instance.uniqueName,
        this.currentEnvironmentStore.instance.uniqueName,
        params
      )
      .pipe(
        map(response => {
          const users = response.environments.find(item => item.environment.uniqueName == this.environment);
          if (!users) {
            return {
              items: [],
              hasMore: false,
              count: 0
            };
          }

          const totalPages = Math.ceil(users.count / users.pageSize);
          return {
            items: users.results,
            hasMore: users.next,
            totalPages: totalPages,
            perPage: users.pageSize,
            count: users.count
          };
        })
      );
  }
}

<app-page-header>
  <app-page-title *ngIf="title">{{ title }}</app-page-title>
  <app-page-description *ngIf="description">{{ description }}</app-page-description>

  <!--    <app-page-header-right>-->
  <!--      <button type="button" class="button page-header__right-item" [disabled]="disabled" (click)="addProperty()">-->
  <!--        <span class="icon button__icon_left icon-plus button__icon"></span>-->
  <!--        <span class="button__label">Add</span>-->
  <!--      </button>-->
  <!--    </app-page-header-right>-->
</app-page-header>

<app-page-block
  *ngIf="properties.length"
  [fill]="true"
  style="overflow: auto;"
  [style.height.px]="scrollable ? 358 : null"
>
  <table class="table" [class.table_disabled]="disabled">
    <thead>
      <tr class="table__heading-row">
        <th class="table__heading-column table__heading-column_left-padding" style="width: 200px;" colspan="2">
          {{ 'Name' | localize }}
        </th>
        <th class="table__heading-column">
          {{ 'Value' | localize }}
        </th>
        <th class="table__heading-column"></th>
      </tr>
    </thead>
    <tbody>
      <tr
        *ngFor="let property of properties; let i = index"
        app-project-property-change-item
        [property]="property"
        [propertiesControl]="control"
        [analyticsSource]="analyticsSource"
        class="table__row"
      ></tr>
    </tbody>
  </table>
</app-page-block>

<app-page-block *ngIf="!properties.length">
  {{ 'No properties specified' | localize }}
</app-page-block>

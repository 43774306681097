import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { ViewContext, ViewContextElement } from '@modules/customize';

import { CustomizeBarActionEditForm } from '../../customize-bar-action-edit.form';

@Component({
  selector: 'app-customize-bar-action-edit-parameters-export',
  templateUrl: './customize-bar-action-edit-parameters-export.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomizeBarActionEditParametersExportComponent implements OnInit {
  @Input() form: CustomizeBarActionEditForm;
  @Input() context: ViewContext;
  @Input() contextElement: ViewContextElement;
  @Input() contextElementPath: (string | number)[];
  @Input() contextElementPaths: (string | number)[][];
  @Input() userInput = false;
  @Input() analyticsSource: string;
  @Output() addQueryInput = new EventEmitter<void>();

  constructor() {}

  ngOnInit() {}
}

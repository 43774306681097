import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import clamp from 'lodash/clamp';
import { Observable, throwError } from 'rxjs';
import { catchError, delayWhen } from 'rxjs/operators';

import { FormUtils } from '@common/form-utils';
import { Domain, DomainService } from '@modules/domain';
import { CurrentEnvironmentStore, CurrentProjectStore, Project } from '@modules/projects';
import { isSet } from '@shared';

@Injectable()
export class ProjectDomainForm extends FormGroup {
  instance: Domain;

  controls: {
    domain: FormControl;
    domain_entered: FormControl;
  };

  constructor(
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private formUtils: FormUtils,
    private domainService: DomainService
  ) {
    super({
      domain: new FormControl('', [
        Validators.required,
        Validators.pattern(/^(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]$/)
      ]),
      domain_entered: new FormControl(null, Validators.required)
    });

    this.controls.domain.valueChanges.subscribe(rawValue => {
      const cleanValue = this.cleanValue(rawValue);

      if (cleanValue !== rawValue) {
        this.controls.domain.setValue(cleanValue);
      }

      this.controls.domain_entered.patchValue(false);
    });
  }

  cleanValue(value: any) {
    if (!isSet(value)) {
      value = '';
    }

    return value
      .toLowerCase()
      .replace(/[^a-z0-9\-.]/g, '')
      .replace(/\.+/g, '.');
  }

  init(project: Project) {
    this.instance = project.domain;
  }

  add(): Observable<Domain> {
    const instance = new Domain();

    instance.domain = this.controls.domain.value;

    return this.domainService
      .createCustom(this.currentProjectStore.instance, this.currentEnvironmentStore.instance, instance)
      .pipe(
        delayWhen(() => this.currentProjectStore.getFirst(true)),
        catchError(error => {
          this.formUtils.showFormErrors(this, error);
          return throwError(error);
        })
      );
  }

  delete(): Observable<boolean> {
    return this.domainService
      .deleteCustom(this.currentProjectStore.instance, this.currentEnvironmentStore.instance, this.instance)
      .pipe(
        delayWhen(() => this.currentProjectStore.getFirst(true)),
        catchError(error => {
          this.formUtils.showFormErrors(this, error);
          return throwError(error);
        })
      );
  }
}

import isArray from 'lodash/isArray';
import isPlainObject from 'lodash/isPlainObject';

import { isEmptyArray, isSet } from '@shared';

import {
  containsFieldLookup,
  endsWithFieldLookup,
  exactFieldLookup,
  inFieldLookup,
  isEmptyFieldLookup,
  isNullFieldLookup,
  jsonContainsFieldLookup,
  startsWithFieldLookup
} from '@modules/field-lookups';

import { FieldType } from '../field-type';
import { parseInLookupStringValue } from './text';

export const jsonLookupMatchers = [
  {
    field: FieldType.JSON,
    lookup: containsFieldLookup,
    match: (fieldValue: Object, lookupValue: string): boolean => {
      if (!fieldValue || !lookupValue) {
        return false;
      }
      return String(fieldValue).toLowerCase().includes(String(lookupValue).toLowerCase());
    }
  },
  {
    field: FieldType.JSON,
    lookup: exactFieldLookup,
    match: (fieldValue: Object, lookupValue: string): boolean => {
      if (isArray(fieldValue) && fieldValue.every(item => !isArray(item) && !isPlainObject(item))) {
        return fieldValue.some(item => item == lookupValue);
      } else {
        return JSON.stringify(fieldValue) == JSON.stringify(lookupValue);
      }
    }
  },
  {
    field: FieldType.JSON,
    lookup: startsWithFieldLookup,
    match: (fieldValue: Object, lookupValue: string): boolean => {
      if (!fieldValue || !lookupValue) {
        return false;
      }
      return String(fieldValue).startsWith(lookupValue);
    }
  },
  {
    field: FieldType.JSON,
    lookup: endsWithFieldLookup,
    match: (fieldValue: Object, lookupValue: string): boolean => {
      if (!fieldValue || !lookupValue) {
        return false;
      }
      return String(fieldValue).endsWith(lookupValue);
    }
  },
  {
    field: FieldType.JSON,
    lookup: isEmptyFieldLookup,
    match: (fieldValue: Object, lookupValue: boolean): boolean => {
      return !!lookupValue
        ? !isSet(fieldValue) || isEmptyArray(fieldValue)
        : isSet(fieldValue) && !isEmptyArray(fieldValue);
    }
  },
  {
    field: FieldType.JSON,
    lookup: isNullFieldLookup,
    match: (fieldValue: Object, lookupValue: boolean): boolean => {
      return !!lookupValue ? fieldValue === null : fieldValue !== null;
    }
  },
  {
    field: FieldType.JSON,
    lookup: inFieldLookup,
    match: (fieldValue: Object, lookupValue: string | string[]): boolean => {
      if (!lookupValue || !lookupValue) {
        return false;
      }

      if (typeof lookupValue == 'string') {
        lookupValue = parseInLookupStringValue(lookupValue);
      } else if (!isArray(lookupValue)) {
        lookupValue = [lookupValue];
      }

      return lookupValue.some(lookupValueItem => {
        if (isArray(fieldValue) && fieldValue.every(item => !isArray(item) && !isPlainObject(item))) {
          return fieldValue.some(item => item == lookupValueItem);
        } else {
          return JSON.stringify(fieldValue) == JSON.stringify(lookupValueItem);
        }
      });
    }
  },
  {
    field: FieldType.JSON,
    lookup: jsonContainsFieldLookup,
    match: (fieldValue: Object, lookupValue: string): boolean => {
      if (!fieldValue || !lookupValue) {
        return false;
      }
      return String(fieldValue).toLowerCase().includes(String(lookupValue).toLowerCase());
    }
  }
];

import { ActivatedRouteSnapshot } from '@angular/router';

const IGNORE_SCROLL_ENABLED_KEY = '_ignore_scroll_top_enabled';
const IGNORE_SCROLL_OPTIONS_KEY = '_ignore_scroll_top_options';

export interface IgnoreScrollTopOptions {
  predicate?: (newSnapshot: ActivatedRouteSnapshot, prevSnapshot?: ActivatedRouteSnapshot) => boolean;
}

export function IgnoreScrollTop(options: IgnoreScrollTopOptions = {}) {
  return constructor => {
    constructor[IGNORE_SCROLL_ENABLED_KEY] = true;
    constructor[IGNORE_SCROLL_OPTIONS_KEY] = options;
    return constructor;
  };
}

export function componentHasIgnoreScrollTop(component: any): boolean {
  return component && component[IGNORE_SCROLL_ENABLED_KEY];
}

export function getComponentIgnoreScrollTopOptions(component: any): IgnoreScrollTopOptions {
  if (component && component[IGNORE_SCROLL_OPTIONS_KEY]) {
    return component[IGNORE_SCROLL_OPTIONS_KEY];
  } else {
    return {};
  }
}

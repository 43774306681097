import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatMenuModule } from '@angular/material';
import { NgGxScrollableModule } from 'ng-gxscrollable';

import { DragDropModule as LegacyDragDropModule } from '@common/drag-drop';
import { DragDrop2Module } from '@common/drag-drop2';
import { FixedComponentModule } from '@common/fixed-component';
import { LocalizeModule } from '@common/localize';
import { ResizableModule } from '@common/resizable';
import { TipsModule } from '@common/tips';
import { AdminHeaderModule } from '@modules/admin-header';
import { AnalyticsModule } from '@modules/analytics';
import { ChangeComponentsModule } from '@modules/change-components';
import { ChangeRoutesModule } from '@modules/change-routes';
import { CustomizeBarModule } from '@modules/customize-bar';
import { CustomizeComponentsModule } from '@modules/customize-components';
import { CustomizeToolbarModule } from '@modules/customize-toolbar';
import { FieldComponentsModule } from '@modules/field-components';
import { FieldsModule } from '@modules/fields';
import { ListRoutesModule } from '@modules/list-routes';
import { ProjectsComponentsModule } from '@modules/projects-components';
import { RoutingModule } from '@modules/routing';
import { SidebarModule } from '@modules/sidebar';
import { TableModule } from '@modules/table';
import { TemplateComponentsModule } from '@modules/template-components';
import { ThemeComponentsModule } from '@modules/theme-components';
import { SharedModule } from '@shared';
import { UiModule } from '@ui';

import { CustomPageComponent } from './components/custom-page/custom-page.component';
import { PageComponent } from './components/page/page.component';
import { TemplatesItemComponent } from './components/templates-item/templates-item.component';
import { TemplatesComponent } from './components/templates/templates.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RoutingModule,
    ReactiveFormsModule,
    NgGxScrollableModule,
    ProjectsComponentsModule,
    FixedComponentModule,
    AnalyticsModule,
    DragDropModule,
    ChangeComponentsModule,
    ListRoutesModule,
    ChangeRoutesModule,
    CustomizeBarModule,
    CustomizeComponentsModule,
    TemplateComponentsModule,
    FieldsModule,
    SidebarModule,
    TableModule,
    SharedModule,
    AdminHeaderModule,
    DragDrop2Module,
    TipsModule,
    CustomizeToolbarModule,
    ResizableModule,
    UiModule,
    FieldComponentsModule,
    LegacyDragDropModule,
    DragDropModule,
    LocalizeModule,
    ThemeComponentsModule,
    MatMenuModule
  ],
  declarations: [PageComponent, CustomPageComponent, TemplatesItemComponent, TemplatesComponent],
  exports: [PageComponent]
})
export class CustomizeRoutesModule {}

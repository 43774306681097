/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../add-step-dropdown/add-step-dropdown.component.ngfactory";
import * as i2 from "../add-step-dropdown/add-step-dropdown.component";
import * as i3 from "../../../../projects/stores/current-project.store";
import * as i4 from "../../../../projects/stores/current-environment.store";
import * as i5 from "../../../../../core/services/local-storage/local.storage";
import * as i6 from "../../../../../../../node_modules/@angular/material/menu/typings/index.ngfactory";
import * as i7 from "@angular/material/menu";
import * as i8 from "@angular/common";
import * as i9 from "./add-step-menu.component";
import * as i10 from "../../../../projects-components/services/resource-edit-controller/resource-edit.controller";
import * as i11 from "../../../../features/services/feature/feature.service";
import * as i12 from "../../../services/workflow-edit-context/workflow-edit.context";
import * as i13 from "../../../../analytics/services/universal-analytics/universal-analytics.service";
var styles_AddStepMenuComponent = [];
var RenderType_AddStepMenuComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AddStepMenuComponent, data: {} });
export { RenderType_AddStepMenuComponent as RenderType_AddStepMenuComponent };
function View_AddStepMenuComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-add-step-dropdown", [], null, [[null, "buttonClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("buttonClick" === en)) {
        var pd_0 = (_co.onButtonClick($event, _v.context.trigger) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_AddStepDropdownComponent_0, i1.RenderType_AddStepDropdownComponent)), i0.ɵdid(1, 245760, null, 0, i2.AddStepDropdownComponent, [i3.CurrentProjectStore, i4.CurrentEnvironmentStore, i5.LocalStorage, i0.ChangeDetectorRef], { actionTypesEnabled: [0, "actionTypesEnabled"] }, { buttonClick: "buttonClick" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.actionTypesEnabled; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_AddStepMenuComponent_0(_l) { return i0.ɵvid(2, [i0.ɵqud(402653184, 1, { menu: 0 }), (_l()(), i0.ɵeld(1, 0, null, null, 6, "mat-menu", [], null, null, null, i6.View_MatMenu_0, i6.RenderType_MatMenu)), i0.ɵprd(6144, null, i7.ɵf24, null, [i7.MatMenu]), i0.ɵdid(3, 1294336, [[1, 4]], 2, i7.MatMenu, [i0.ElementRef, i0.NgZone, i7.MAT_MENU_DEFAULT_OPTIONS], null, null), i0.ɵqud(603979776, 2, { items: 1 }), i0.ɵqud(335544320, 3, { lazyContent: 0 }), (_l()(), i0.ɵand(16777216, null, 0, 1, null, View_AddStepMenuComponent_1)), i0.ɵdid(7, 147456, [[3, 4]], 0, i7.MatMenuContent, [i0.TemplateRef, i0.ComponentFactoryResolver, i0.ApplicationRef, i0.Injector, i0.ViewContainerRef, i8.DOCUMENT], null, null)], function (_ck, _v) { _ck(_v, 3, 0); }, null); }
export function View_AddStepMenuComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-add-step-menu", [], null, null, null, View_AddStepMenuComponent_0, RenderType_AddStepMenuComponent)), i0.ɵdid(1, 245760, null, 0, i9.AddStepMenuComponent, [i3.CurrentProjectStore, i10.ResourceEditController, i11.FeatureService, i12.WorkflowEditContext, i13.UniversalAnalyticsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AddStepMenuComponentNgFactory = i0.ɵccf("app-add-step-menu", i9.AddStepMenuComponent, View_AddStepMenuComponent_Host_0, { actionTypesEnabled: "actionTypesEnabled" }, { add: "add" }, []);
export { AddStepMenuComponentNgFactory as AddStepMenuComponentNgFactory };

import { Pipe, PipeTransform } from '@angular/core';

import { isColorHex } from '../../utils/colors/colors';

@Pipe({
  name: 'appIsColorHex'
})
export class IsColorHexPipe implements PipeTransform {
  transform(color: any): any {
    return isColorHex(color);
  }
}

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map, publishLast, refCount, switchMap } from 'rxjs/operators';
import { ApiService } from '@modules/api';
import { ProjectInvite } from '../../data/project-invite';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../../api/services/api/api.service";
var ProjectInviteService = /** @class */ (function () {
    function ProjectInviteService(http, apiService) {
        this.http = http;
        this.apiService = apiService;
    }
    ProjectInviteService.prototype.get = function (projectName, environmentName, params) {
        var _this = this;
        if (params === void 0) { params = {}; }
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.environmentMethodURL(projectName, environmentName, 'invites/');
            var headers = new HttpHeaders();
            var httpParams = new HttpParams({ fromObject: params });
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.get(url, { headers: headers, params: httpParams });
        }), map(function (result) { return result.map(function (item) { return new ProjectInvite().deserialize(item); }); }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    ProjectInviteService.prototype.getPaginate = function (projectName, environmentName, params) {
        var _this = this;
        if (params === void 0) { params = {}; }
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.environmentMethodURL(projectName, environmentName, 'invites/');
            var headers = new HttpHeaders();
            var httpParams = new HttpParams({
                fromObject: __assign({ _paginate: '1' }, params)
            });
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.get(url, { headers: headers, params: httpParams });
        }), map(function (result) { return new ProjectInviteService.GetResponse().deserialize(result); }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    ProjectInviteService.prototype.getDetail = function (projectName, environmentName, id) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.environmentMethodURL(projectName, environmentName, "invites/" + id + "/");
            var headers = new HttpHeaders();
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.get(url, { headers: headers });
        }), map(function (result) { return new ProjectInvite().deserialize(result); }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    ProjectInviteService.prototype.update = function (projectName, environmentName, user) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.environmentMethodURL(projectName, environmentName, "invites/" + user.uid + "/");
            var headers = new HttpHeaders();
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.patch(url, user.serialize(), { headers: headers });
        }), map(function (result) { return new ProjectInvite().deserialize(result); }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    ProjectInviteService.prototype.delete = function (projectName, environmentName, invite) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.environmentMethodURL(projectName, environmentName, "invites/" + invite.uid + "/");
            var headers = new HttpHeaders();
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.delete(url, { headers: headers });
        }), map(function (result) { return true; }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    ProjectInviteService.prototype.resend = function (projectName, environmentName, invite) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.nodeEnvironmentMethodURL(projectName, environmentName, "invites/" + invite.uid + "/resend");
            var headers = new HttpHeaders();
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.post(url, {}, { headers: headers });
        }), map(function (result) { return true; }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    ProjectInviteService.prototype.getByCode = function (code) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.methodURL("project_invites/" + code + "/");
            var headers = new HttpHeaders();
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.get(url, { headers: headers });
        }), map(function (result) { return new ProjectInvite().deserialize(result); }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    ProjectInviteService.prototype.accept = function (code) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.methodURL("project_invites/" + code + "/accept/");
            var headers = new HttpHeaders();
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.post(url, {}, { headers: headers });
        }), map(function (result) { return (result ? result['result'] : false); }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    ProjectInviteService.prototype.reject = function (code) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.methodURL("project_invites/" + code + "/reject/");
            var headers = new HttpHeaders();
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.post(url, {}, { headers: headers });
        }), map(function (result) { return (result ? result['result'] : false); }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    ProjectInviteService.ngInjectableDef = i0.defineInjectable({ factory: function ProjectInviteService_Factory() { return new ProjectInviteService(i0.inject(i1.HttpClient), i0.inject(i2.ApiService)); }, token: ProjectInviteService, providedIn: "root" });
    return ProjectInviteService;
}());
export { ProjectInviteService };
(function (ProjectInviteService) {
    var GetResponse = /** @class */ (function () {
        function GetResponse() {
        }
        GetResponse.prototype.deserialize = function (data) {
            this.results = data['results'].map(function (item) { return new ProjectInvite().deserialize(item); });
            this.count = data['count'];
            this.next = data['next'];
            this.previous = data['previous'];
            return this;
        };
        return GetResponse;
    }());
    ProjectInviteService.GetResponse = GetResponse;
})(ProjectInviteService || (ProjectInviteService = {}));

import pickBy from 'lodash/pickBy';
import * as moment from 'moment';

import { SSOSettings } from '@modules/sso';
import { User } from '@modules/users';

import { LogHttpRequest } from './log-http-request';
import { LogLevel } from './log-level';

export class Log {
  public uid: string;
  public ssoSettings: SSOSettings;
  public trace: string;
  public user: User;
  public level: LogLevel;
  public source: string;
  public action: string;
  public text: string;
  public publicParams = {};
  public publicParamsUnused = {};
  public dateAdd: moment.Moment;
  public httpRequest: LogHttpRequest;

  deserialize(data: Object): Log {
    this.uid = data['uid'];
    this.trace = data['trace'];
    this.level = data['level'];
    this.source = data['source'];
    this.action = data['action'];
    this.text = data['text'];
    this.publicParams = data['public_params'];
    this.dateAdd = moment(data['date_add']);

    if (data['sso_settings']) {
      this.ssoSettings = new SSOSettings().deserialize(data['sso_settings']);
    }

    if (data['user']) {
      this.user = new User().deserialize(data['user']);
    }

    if (this.publicParams['http_request']) {
      this.httpRequest = new LogHttpRequest().deserialize(this.publicParams['http_request']);
    }

    this.publicParamsUnused = <Object>pickBy(this.publicParams, (v, k) => !['http_request'].includes(k));

    return this;
  }

  get traceShort(): string {
    if (!this.trace) {
      return;
    }

    return this.trace.slice(0, 4);
  }
}

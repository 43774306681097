/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../ui/components/alert/alert.component.ngfactory";
import * as i2 from "../../../../ui/components/alert/alert.component";
import * as i3 from "@angular/cdk/drag-drop";
import * as i4 from "@angular/common";
import * as i5 from "@angular/cdk/scrolling";
import * as i6 from "@angular/cdk/bidi";
import * as i7 from "../../../../common/tips/directives/tip/tip.directive";
import * as i8 from "../../../customize-bar/components/customize-bar-header/customize-bar-header.component.ngfactory";
import * as i9 from "../../../customize-bar/components/customize-bar-header/customize-bar-header.component";
import * as i10 from "../../../customize-bar/services/customize-bar-context/customize-bar.context";
import * as i11 from "../../../sidebar/components/sidebar-section/sidebar-section.component.ngfactory";
import * as i12 from "../../../sidebar/components/sidebar-section/sidebar-section.component";
import * as i13 from "../../../sidebar/components/sidebar-field/sidebar-field.component.ngfactory";
import * as i14 from "../../../sidebar/components/sidebar-field/sidebar-field.component";
import * as i15 from "./customize-bar-emails-edit.component";
import * as i16 from "../../../projects/stores/current-project.store";
import * as i17 from "../../../projects/stores/current-environment.store";
import * as i18 from "../../../../core/services/app-config/app-config.service";
import * as i19 from "../../../domain-components/services/project-domain/project-domain.controller";
import * as i20 from "../../../emails/services/email-template/email-template.service";
import * as i21 from "../../../emails-components/services/email-template-edit/email-template-edit.controller";
import * as i22 from "../../../emails-components/services/email-address-edit/email-address-edit.controller";
var styles_CustomizeBarEmailsEditComponent = [];
var RenderType_CustomizeBarEmailsEditComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CustomizeBarEmailsEditComponent, data: {} });
export { RenderType_CustomizeBarEmailsEditComponent as RenderType_CustomizeBarEmailsEditComponent };
function View_CustomizeBarEmailsEditComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "div", [["class", "sidebar__element"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 6, "app-alert", [], null, null, null, i1.View_AlertComponent_0, i1.RenderType_AlertComponent)), i0.ɵdid(2, 114688, null, 0, i2.AlertComponent, [], { title: [0, "title"], secondary: [1, "secondary"] }, null), (_l()(), i0.ɵeld(3, 0, null, 0, 1, "div", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["To customize Email templates you should connect custom domain to current App"])), (_l()(), i0.ɵeld(5, 0, null, 0, 2, "div", [["style", "margin-top: 4px;"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 1, "a", [["href", "javascript:void(0)"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.editDomain() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["Connect domain"]))], function (_ck, _v) { var currVal_0 = "Domain is not set up"; var currVal_1 = true; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_CustomizeBarEmailsEditComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [["class", "sidebar__element"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 4, "app-alert", [], null, null, null, i1.View_AlertComponent_0, i1.RenderType_AlertComponent)), i0.ɵdid(2, 114688, null, 0, i2.AlertComponent, [], { warning: [0, "warning"] }, null), (_l()(), i0.ɵted(-1, 0, [" Only when using "])), (_l()(), i0.ɵeld(4, 0, null, 0, 1, "strong", [["class", "no-wrap"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Custom domain"]))], function (_ck, _v) { var currVal_0 = true; _ck(_v, 2, 0, currVal_0); }, null); }
function View_CustomizeBarEmailsEditComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 16777216, null, null, 11, "div", [["cdkDrag", ""], ["class", "sidebar-list__item cdk-drag"]], [[2, "cdk-drag-disabled", null], [2, "cdk-drag-dragging", null]], null, null, null, null)), i0.ɵprd(6144, null, i3.ɵb, null, [i3.CdkDrag]), i0.ɵdid(2, 4866048, null, 3, i3.CdkDrag, [i0.ElementRef, [3, i3.CDK_DROP_LIST], i4.DOCUMENT, i0.NgZone, i0.ViewContainerRef, i5.ViewportRuler, i3.DragDropRegistry, i3.CDK_DRAG_CONFIG, [2, i6.Directionality], i3.DragDrop, i0.ChangeDetectorRef], null, null), i0.ɵqud(603979776, 2, { _handles: 1 }), i0.ɵqud(335544320, 3, { _previewTemplate: 0 }), i0.ɵqud(335544320, 4, { _placeholderTemplate: 0 }), (_l()(), i0.ɵeld(6, 0, null, null, 5, "div", [["class", "sidebar-list-item"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 4, "div", [["class", "sidebar-list-item__main"]], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 3, "div", [["class", "sidebar-list-item__title"]], null, null, null, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 2, "span", [], [[2, "loading-animation", null]], null, null, null, null)), (_l()(), i0.ɵeld(10, 0, null, null, 1, "span", [["class", "stub-text"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["email template"])), (_l()(), i0.ɵand(0, null, null, 0))], null, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 2).disabled; var currVal_1 = i0.ɵnov(_v, 2)._dragRef.isDragging(); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = true; _ck(_v, 9, 0, currVal_2); }); }
function View_CustomizeBarEmailsEditComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["class", "sidebar-list"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_CustomizeBarEmailsEditComponent_4)), i0.ɵdid(2, 278528, null, 0, i4.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i0.ɵpad(3, 3)], function (_ck, _v) { var currVal_0 = _ck(_v, 3, 0, 1, 2, 3); _ck(_v, 2, 0, currVal_0); }, null); }
function View_CustomizeBarEmailsEditComponent_7(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "sidebar-list-item__left"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "span", [["class", "sidebar-list-item__icon"]], null, null, null, null, null)), i0.ɵdid(2, 278528, null, 0, i4.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null)], function (_ck, _v) { var currVal_0 = "sidebar-list-item__icon"; var currVal_1 = ("icon-" + _v.parent.context.$implicit.info.icon); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_CustomizeBarEmailsEditComponent_8(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["class", "sidebar-list-item__description"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Using custom template "]))], null, null); }
function View_CustomizeBarEmailsEditComponent_9(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "a", [["class", "sidebar-list-item__action sidebar-list-item__action_button icon-repeat"], ["href", "javascript:void(0)"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        $event.stopPropagation();
        var pd_0 = (_co.resetEmailItem(_v.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 737280, null, 0, i7.TipDirective, [i0.ElementRef, i0.ComponentFactoryResolver, i0.Injector, i0.ApplicationRef], { content: [0, "content"], optionsRaw: [1, "optionsRaw"] }, null), i0.ɵpod(2, { side: 0 })], function (_ck, _v) { var currVal_0 = "Reset to default"; var currVal_1 = _ck(_v, 2, 0, "left"); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_CustomizeBarEmailsEditComponent_10(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "span", [["class", "sidebar-list-item__action sidebar-list-item__action_button icon-edit"]], null, null, null, null, null))], null, null); }
function View_CustomizeBarEmailsEditComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 13, "div", [["class", "sidebar-list__item"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 12, "div", [["class", "sidebar-list-item sidebar-list-item_clickable"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.editEmailItem(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CustomizeBarEmailsEditComponent_7)), i0.ɵdid(3, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(4, 0, null, null, 4, "div", [["class", "sidebar-list-item__main"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 1, "div", [["class", "sidebar-list-item__title"]], null, null, null, null, null)), (_l()(), i0.ɵted(6, null, ["", ""])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CustomizeBarEmailsEditComponent_8)), i0.ɵdid(8, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(9, 0, null, null, 4, "div", [["class", "sidebar-list-item__right"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CustomizeBarEmailsEditComponent_9)), i0.ɵdid(11, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CustomizeBarEmailsEditComponent_10)), i0.ɵdid(13, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit.info.icon; _ck(_v, 3, 0, currVal_0); var currVal_2 = _v.context.$implicit.custom; _ck(_v, 8, 0, currVal_2); var currVal_3 = _v.context.$implicit.custom; _ck(_v, 11, 0, currVal_3); var currVal_4 = !_v.context.$implicit.custom; _ck(_v, 13, 0, currVal_4); }, function (_ck, _v) { var currVal_1 = _v.context.$implicit.info.label; _ck(_v, 6, 0, currVal_1); }); }
function View_CustomizeBarEmailsEditComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "sidebar-list"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CustomizeBarEmailsEditComponent_6)), i0.ɵdid(2, 278528, null, 0, i4.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.emailItems; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_CustomizeBarEmailsEditComponent_0(_l) { return i0.ɵvid(2, [i0.ɵqud(402653184, 1, { signUpEnableGroupDropdown: 0 }), (_l()(), i0.ɵeld(1, 0, null, null, 1, "app-customize-bar-header", [], null, null, null, i8.View_CustomizeBarHeaderComponent_0, i8.RenderType_CustomizeBarHeaderComponent)), i0.ɵdid(2, 180224, null, 0, i9.CustomizeBarHeaderComponent, [[2, i10.CustomizeBarContext]], { title: [0, "title"], backEnabled: [1, "backEnabled"], subtitle: [2, "subtitle"] }, null), (_l()(), i0.ɵeld(3, 0, null, null, 27, "div", [["class", "sidebar__content"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CustomizeBarEmailsEditComponent_1)), i0.ɵdid(5, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(6, 0, null, null, 17, "app-sidebar-section", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onCustomDomainRequiredClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i11.View_SidebarSectionComponent_0, i11.RenderType_SidebarSectionComponent)), i0.ɵdid(7, 114688, null, 0, i12.SidebarSectionComponent, [], { interactive: [0, "interactive"], disabled: [1, "disabled"] }, null), (_l()(), i0.ɵeld(8, 0, null, 3, 13, "div", [["class", "sidebar__element"]], null, null, null, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 12, "app-sidebar-field", [], null, null, null, i13.View_SidebarFieldComponent_0, i13.RenderType_SidebarFieldComponent)), i0.ɵdid(10, 114688, null, 0, i14.SidebarFieldComponent, [], { label: [0, "label"] }, null), (_l()(), i0.ɵeld(11, 0, null, 3, 4, "div", [["class", "input-icon-wrapper"]], null, null, null, null, null)), (_l()(), i0.ɵeld(12, 0, null, null, 1, "div", [["class", "input input_fill input_template input_clickable input_icon-right"]], null, [[null, "mouseenter"], [null, "mouseleave"], [null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mouseenter" === en)) {
        var pd_0 = (_co.emailsFromHover.next(true) !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = (_co.emailsFromHover.next(false) !== false);
        ad = (pd_1 && ad);
    } if (("click" === en)) {
        var pd_2 = (_co.editEmailsFrom() !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(13, null, [" ", " "])), (_l()(), i0.ɵeld(14, 0, null, null, 1, "div", [["class", "input-icon input-icon_right icon-pen"]], [[2, "input-icon_bright", null]], null, null, null, null)), i0.ɵpid(131072, i4.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(16, 0, null, 4, 5, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" This address will be used for all transactional Emails and messages send through "])), (_l()(), i0.ɵeld(18, 0, null, null, 3, "strong", [["class", "no-wrap"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["App built-ins "])), (_l()(), i0.ɵeld(20, 0, null, null, 0, "span", [["class", "icon-arrow_forward_2"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Send email"])), (_l()(), i0.ɵand(16777216, null, 3, 1, null, View_CustomizeBarEmailsEditComponent_2)), i0.ɵdid(23, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(24, 0, null, null, 6, "app-sidebar-section", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onCustomDomainRequiredClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i11.View_SidebarSectionComponent_0, i11.RenderType_SidebarSectionComponent)), i0.ɵdid(25, 114688, null, 0, i12.SidebarSectionComponent, [], { title: [0, "title"], description: [1, "description"], interactive: [2, "interactive"], disabled: [3, "disabled"] }, null), (_l()(), i0.ɵeld(26, 0, null, 3, 4, "div", [["class", "sidebar__list"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CustomizeBarEmailsEditComponent_3)), i0.ɵdid(28, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CustomizeBarEmailsEditComponent_5)), i0.ɵdid(30, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "Emails"; var currVal_1 = false; var currVal_2 = "App settings"; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = !_co.domain; _ck(_v, 5, 0, currVal_3); var currVal_4 = (!_co.domain || !_co.domain.custom); var currVal_5 = (!_co.domain || !_co.domain.custom); _ck(_v, 7, 0, currVal_4, currVal_5); var currVal_6 = "From Email address"; _ck(_v, 10, 0, currVal_6); var currVal_9 = (!_co.domain || !_co.domain.custom); _ck(_v, 23, 0, currVal_9); var currVal_10 = "Transactional emails"; var currVal_11 = "Customize templates text and content"; var currVal_12 = !_co.domain; var currVal_13 = !_co.domain; _ck(_v, 25, 0, currVal_10, currVal_11, currVal_12, currVal_13); var currVal_14 = _co.emailItemsLoading; _ck(_v, 28, 0, currVal_14); var currVal_15 = (!_co.emailItemsLoading && _co.emailItems); _ck(_v, 30, 0, currVal_15); }, function (_ck, _v) { var _co = _v.component; var currVal_7 = (((_co.domain == null) ? null : _co.domain.emailConfigFrom) || _co.appConfigService.defaultEmailFrom); _ck(_v, 13, 0, currVal_7); var currVal_8 = i0.ɵunv(_v, 14, 0, i0.ɵnov(_v, 15).transform(_co.emailsFromHover)); _ck(_v, 14, 0, currVal_8); }); }
export function View_CustomizeBarEmailsEditComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-customize-bar-emails-edit", [], null, null, null, View_CustomizeBarEmailsEditComponent_0, RenderType_CustomizeBarEmailsEditComponent)), i0.ɵdid(1, 245760, null, 0, i15.CustomizeBarEmailsEditComponent, [i16.CurrentProjectStore, i17.CurrentEnvironmentStore, i18.AppConfigService, i19.ProjectDomainController, i20.EmailTemplateService, i21.EmailTemplateEditController, i22.EmailAddressEditController, i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CustomizeBarEmailsEditComponentNgFactory = i0.ɵccf("app-customize-bar-emails-edit", i15.CustomizeBarEmailsEditComponent, View_CustomizeBarEmailsEditComponent_Host_0, { projectSignUpForm: "projectSignUpForm", projectDomainUpdateForm: "projectDomainUpdateForm" }, {}, []);
export { CustomizeBarEmailsEditComponentNgFactory as CustomizeBarEmailsEditComponentNgFactory };

import { Injectable, OnDestroy } from '@angular/core';
import { Observable, of } from 'rxjs';

import { AppFormGroup } from '@common/form-utils';
import { HttpContentType, HttpMethod, HttpQuery } from '@modules/queries';
import { ResourceParamsResult, RestAPIResourceParams } from '@modules/resources';

import { BaseResourceSettingsForm } from '../base-resource-settings/base-resource-settings.form';

@Injectable()
export class ZapierResourceSettingsForm extends BaseResourceSettingsForm implements OnDestroy {
  form = new AppFormGroup({});

  getParams(): ResourceParamsResult | Observable<ResourceParamsResult> {
    const params = new RestAPIResourceParams();
    const baseHttpQuery = new HttpQuery();

    baseHttpQuery.method = HttpMethod.POST;
    baseHttpQuery.bodyType = HttpContentType.FormData;

    params.baseHttpQuery = baseHttpQuery;

    return {
      resourceName: this.resourceForm.value['name'],
      resourceParams: params.serialize()
    };
  }
}

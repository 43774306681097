import { moveItemInArray } from '@angular/cdk/drag-drop';
import { ChangeDetectorRef, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { copyTextToClipboard } from '@common/code';
import { NotificationService } from '@common/notifications';
import { AppConfigService } from '@core';
import { IntercomService } from '@modules/analytics';
import { ProjectPropertyEditController } from '@modules/customize-bar';
import { SignUpLayout } from '@modules/domain';
import { ProjectDomainController } from '@modules/domain-components';
import { FeatureService } from '@modules/features';
import { BooleanFieldStyle } from '@modules/field-components';
import { createFormFieldFactory, ImageFit } from '@modules/fields';
import { MenuPagesService } from '@modules/menu';
import { ProjectGroupDropdownComponent } from '@modules/project-settings-components';
import { CurrentEnvironmentStore, CurrentProjectStore, ProjectPropertyStore, ProjectPropertyType } from '@modules/projects';
import { controlValue, setControlEnabled } from '@shared';
import { ProjectSettingsUpdateForm } from '../project-settings/project-settings-update.form';
import { ProjectDomainUpdateForm } from '../sign-up-builder/project-domain-update.form';
import { ProjectSignUpForm } from '../sign-up-builder/project-sign-up.form';
var CustomizeBarSignupEditComponent = /** @class */ (function () {
    function CustomizeBarSignupEditComponent(currentProjectStore, currentEnvironmentStore, appConfigService, notificationService, featureService, projectDomainController, projectPropertyStore, projectPropertyEditController, menuPagesService, intercomService, cd) {
        var _this = this;
        this.currentProjectStore = currentProjectStore;
        this.currentEnvironmentStore = currentEnvironmentStore;
        this.appConfigService = appConfigService;
        this.notificationService = notificationService;
        this.featureService = featureService;
        this.projectDomainController = projectDomainController;
        this.projectPropertyStore = projectPropertyStore;
        this.projectPropertyEditController = projectPropertyEditController;
        this.menuPagesService = menuPagesService;
        this.intercomService = intercomService;
        this.cd = cd;
        this.createField = createFormFieldFactory();
        this.booleanFieldStyle = BooleanFieldStyle;
        this.signUpOpened = false;
        this.hasSSO = false;
        this.layoutOptions = [
            {
                layout: SignUpLayout.PosterRight,
                backgroundImageEnabled: true,
                defaultBackgroundColor: '#d9eaf4'
            },
            {
                layout: SignUpLayout.PosterLeft,
                backgroundImageEnabled: true,
                defaultBackgroundColor: '#d9eaf4'
            },
            {
                layout: SignUpLayout.Form,
                backgroundImageEnabled: true,
                defaultBackgroundColor: '#dce0e6'
            },
            {
                layout: SignUpLayout.Background,
                defaultBackgroundColor: '#fff'
            }
        ];
        this.imageFits = ImageFit;
        this.signUpFields = [];
        this.signUpBackgroundColors = ['blue', 'teal', 'maroon', 'navy', 'purple', 'orange'];
        this.builtInSignUpFields = [
            {
                icon: 'user',
                label: 'First Name'
            },
            {
                icon: 'user',
                label: 'Last Name',
                optional: true
            },
            {
                icon: 'email',
                label: 'Email'
            },
            {
                icon: 'lock_close',
                label: 'Password'
            }
        ];
        this.menuLoading = true;
        this.menuItems = [];
        this.projectPropertyTypes = ProjectPropertyType;
        this.analyticsSource = 'layout_builder_sign_up';
        this.filterPropertyOption = (function () {
            return function (property) {
                return !_this.settingsForm.controls.sign_up_fields.controls.find(function (control) {
                    return control.controls.project_property.value == property.uid;
                });
            };
        })();
    }
    CustomizeBarSignupEditComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.currentProjectStore
            .get()
            .pipe(untilDestroyed(this))
            .subscribe(function (project) {
            _this.signUpLink = _this.getSignUpLink();
            _this.cd.markForCheck();
        });
        this.projectDomainUpdateForm
            .getInstance$()
            .pipe(untilDestroyed(this))
            .subscribe(function (domain) {
            _this.domain = domain;
            _this.hasSSO = _this.domain && _this.domain.ssoSettings.length != 0;
            _this.cd.markForCheck();
        });
        this.menuPagesService
            .getGroups()
            .pipe(untilDestroyed(this))
            .subscribe(function (groups) {
            _this.menuItems = groups.reduce(function (acc, group) {
                acc.push.apply(acc, group.items);
                return acc;
            }, []);
            _this.menuLoading = false;
            _this.cd.markForCheck();
        }, function () {
            _this.menuLoading = false;
            _this.cd.markForCheck();
        });
        combineLatest(controlValue(this.settingsForm.controls.sign_up_fields), this.projectPropertyStore
            .get()
            .pipe(map(function (properties) { return properties.filter(function (item) { return item.type == ProjectPropertyType.User; }); })))
            .pipe(untilDestroyed(this))
            .subscribe(function (_a) {
            var _ = _a[0], properties = _a[1];
            _this.signUpFields = _this.settingsForm.controls.sign_up_fields.controls
                .map(function (control) {
                var property = properties
                    ? properties.find(function (item) { return item.uid === control.controls.project_property.value; })
                    : undefined;
                return {
                    control: control,
                    property: property
                };
            })
                .filter(function (item) { return item.property; });
            _this.cd.markForCheck();
        });
        controlValue(this.projectSignUpForm.controls.enabled)
            .pipe(untilDestroyed(this))
            .subscribe(function (signUpEnabled) {
            setControlEnabled(_this.projectDomainUpdateForm.controls.sign_up_title, signUpEnabled);
            setControlEnabled(_this.projectDomainUpdateForm.controls.sign_up_description, signUpEnabled);
            setControlEnabled(_this.projectDomainUpdateForm.controls.privacy_policy, signUpEnabled);
            setControlEnabled(_this.projectDomainUpdateForm.controls.terms_of_service, signUpEnabled);
        });
    };
    CustomizeBarSignupEditComponent.prototype.ngOnDestroy = function () { };
    Object.defineProperty(CustomizeBarSignupEditComponent.prototype, "currentLayoutOption", {
        get: function () {
            var _this = this;
            return this.layoutOptions.find(function (item) { return item.layout == _this.projectDomainUpdateForm.controls.sign_up_layout.value; });
        },
        enumerable: true,
        configurable: true
    });
    CustomizeBarSignupEditComponent.prototype.showSignUpFeatureOverview = function () {
        if (this.currentProjectStore.instance.features.isCustomerSignUpEnabled()) {
            return;
        }
        this.featureService.showFeatureOverview({
            subtitle: 'Customer Portal Feature',
            title: 'App sign up with <strong>Customer Access</strong>',
            description: "\n        <ul>\n          <li>Customers can get access to app on their own without invitation</li>\n          <li>Assign particular Team for newly registered customers</li>\n          <li>Filter data for customers</li>\n        </ul>\n      "
        });
    };
    CustomizeBarSignupEditComponent.prototype.getSignUpLink = function () {
        var project = this.currentProjectStore.instance;
        var webBaseUrl = project.domain ? "https://" + project.domain.actualDomain : this.appConfigService.webBaseUrl;
        return webBaseUrl + "/register/" + this.currentProjectStore.instance.uniqueName;
    };
    CustomizeBarSignupEditComponent.prototype.copySignUpCode = function () {
        var _this = this;
        var text = this.getSignUpLink();
        copyTextToClipboard(text)
            .pipe(untilDestroyed(this))
            .subscribe(function (success) {
            if (!success) {
                return;
            }
            _this.notificationService.info('Copied', 'Link was copied to clipboard');
        });
    };
    CustomizeBarSignupEditComponent.prototype.editDomain = function () {
        this.projectDomainController.edit({ analyticsSource: this.analyticsSource }).pipe(untilDestroyed(this)).subscribe();
    };
    CustomizeBarSignupEditComponent.prototype.onDomainRequiredClick = function () {
        if (!this.domain) {
            this.editDomain();
        }
    };
    CustomizeBarSignupEditComponent.prototype.isSignUpEnabled = function () {
        return (!this.projectDomainUpdateForm.controls.disable_email_sign_up.value ||
            this.projectSignUpForm.controls.enabled.value);
    };
    CustomizeBarSignupEditComponent.prototype.onSignUpClick = function () {
        if (!this.isSignUpEnabled()) {
            if (!this.currentProjectStore.instance.features.isCustomerSignUpEnabled()) {
                this.showSignUpFeatureOverview();
            }
            else {
                this.signUpEnableGroupDropdown.open();
            }
            if (!this.projectDomainUpdateForm.controls.default_project.value) {
                this.projectDomainUpdateForm.controls.default_project.patchValue(true);
            }
        }
        else {
            this.signUpOpened = !this.signUpOpened;
            this.cd.markForCheck();
        }
    };
    CustomizeBarSignupEditComponent.prototype.onSignUpToggleClick = function (event) {
        if (this.isSignUpEnabled()) {
            event.stopPropagation();
            this.projectDomainUpdateForm.controls.disable_email_sign_up.patchValue(true);
            this.projectSignUpForm.controls.enabled.patchValue(false);
            this.signUpOpened = false;
            this.cd.markForCheck();
        }
    };
    CustomizeBarSignupEditComponent.prototype.onSignUpEnableGroupChange = function (value) {
        if (value) {
            this.projectDomainUpdateForm.controls.disable_email_sign_up.patchValue(false);
            this.projectSignUpForm.controls.enabled.patchValue(true);
            this.signUpOpened = true;
            this.cd.markForCheck();
        }
    };
    CustomizeBarSignupEditComponent.prototype.openChat = function () {
        this.intercomService.openChat();
    };
    CustomizeBarSignupEditComponent.prototype.createProperty = function () {
        var _this = this;
        this.projectPropertyEditController
            .create({
            type: ProjectPropertyType.User,
            requiredEnabled: true,
            placeholderEnabled: true,
            analyticsSource: this.analyticsSource
        })
            .pipe(untilDestroyed(this))
            .subscribe(function (result) {
            var control = _this.settingsForm.controls.sign_up_fields.appendControl(result.property);
            control.controls.required.patchValue(result.required);
            control.controls.placeholder.patchValue(result.placeholder);
        });
    };
    CustomizeBarSignupEditComponent.prototype.editProperty = function (control, property) {
        this.projectPropertyEditController
            .edit({
            property: property,
            requiredEnabled: true,
            required: control.controls.required.value,
            placeholderEnabled: true,
            placeholder: control.controls.placeholder.value,
            analyticsSource: this.analyticsSource
        })
            .pipe(untilDestroyed(this))
            .subscribe(function (result) {
            control.controls.required.patchValue(result.required);
            control.controls.placeholder.patchValue(result.placeholder);
        });
    };
    CustomizeBarSignupEditComponent.prototype.dragDrop = function (event) {
        if (event.previousIndex !== event.currentIndex) {
            moveItemInArray(this.settingsForm.controls.sign_up_fields.controls, event.previousIndex, event.currentIndex);
            this.settingsForm.controls.sign_up_fields.updateValueAndValidity();
        }
    };
    return CustomizeBarSignupEditComponent;
}());
export { CustomizeBarSignupEditComponent };

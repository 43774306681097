import pickBy from 'lodash/pickBy';

import { DataSourceType, ListModelDescriptionDataSource } from '@modules/data-sources';
import { Input } from '@modules/fields';

import { WorkflowStepType } from '../workflow-step-type';
import { deserializerWorkflowStep, registerWorkflowStepType } from '../workflow-step-types';
import { WorkflowStep } from './base';

export class IteratorWorkflowStep extends WorkflowStep {
  public type = WorkflowStepType.Iterator;
  public dataSource: ListModelDescriptionDataSource;
  public sortingField: string;
  public sortingAsc = true;
  public steps: WorkflowStep[] = [];

  deserialize(data: Object) {
    super.deserialize(data);
    this.sortingField = data['sorting_field'];

    if (data['data_source']) {
      this.dataSource = new ListModelDescriptionDataSource().deserialize(data['data_source']);
    } else if (data['iterate']) {
      this.dataSource = new ListModelDescriptionDataSource();
      this.dataSource.type = DataSourceType.Input;
      this.dataSource.input = new Input().deserialize(data['iterate']);
    }

    if (data['sorting_asc'] != undefined) {
      this.sortingAsc = data['sorting_asc'];
    }

    if (data['steps']) {
      this.steps = data['steps'].map(item => deserializerWorkflowStep(item)).filter(item => item);
    }

    return this;
  }

  serialize(fields?: string[]) {
    let data: Object = {
      ...super.serialize(fields),
      data_source: this.dataSource ? this.dataSource.serialize() : undefined,
      sorting_field: this.sortingField,
      sorting_asc: this.sortingAsc,
      steps: this.steps.map(item => item.serialize())
    };
    if (fields) {
      data = <Object>pickBy(data, (v, k) => fields.includes(k));
    }
    return data;
  }

  defaultName() {
    return 'Iterator';
  }

  get analyticsName(): string {
    return 'iterator';
  }

  getIcon() {
    return 'repeat';
  }
}

registerWorkflowStepType(WorkflowStepType.Iterator, IteratorWorkflowStep);

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./table-stub-content-header.component";
var styles_TableStubContentHeaderComponent = [];
var RenderType_TableStubContentHeaderComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_TableStubContentHeaderComponent, data: {} });
export { RenderType_TableStubContentHeaderComponent as RenderType_TableStubContentHeaderComponent };
function View_TableStubContentHeaderComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "th", [["class", "table__heading-column table__stub-column"]], [[1, "colspan", 0], [2, "table__heading-column_left-padding", null]], null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "span", [], [[2, "loading-animation", null]], null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "span", [["class", "stub-text"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Column"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_v.context.first ? 2 : null); var currVal_1 = _v.context.first; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = _co.animating; _ck(_v, 1, 0, currVal_2); }); }
export function View_TableStubContentHeaderComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "tr", [["class", "table__heading-row"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_TableStubContentHeaderComponent_1)), i0.ɵdid(2, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i0.ɵpad(3, 8)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.scrollable ? _ck(_v, 3, 0, 1, 2, 3, 4, 5, 6, 7, 8) : _co.columnsRange); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_TableStubContentHeaderComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-table-stub-content-header", [], null, null, null, View_TableStubContentHeaderComponent_0, RenderType_TableStubContentHeaderComponent)), i0.ɵdid(1, 638976, null, 0, i2.TableStubContentHeaderComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TableStubContentHeaderComponentNgFactory = i0.ɵccf("app-table-stub-content-header, [app-table-stub-content-header]", i2.TableStubContentHeaderComponent, View_TableStubContentHeaderComponent_Host_0, { columns: "columns", animating: "animating", scrollable: "scrollable" }, {}, []);
export { TableStubContentHeaderComponentNgFactory as TableStubContentHeaderComponentNgFactory };

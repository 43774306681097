/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./columns-layout-handle.component";
var styles_ColumnsLayoutHandleComponent = [];
var RenderType_ColumnsLayoutHandleComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ColumnsLayoutHandleComponent, data: {} });
export { RenderType_ColumnsLayoutHandleComponent as RenderType_ColumnsLayoutHandleComponent };
function View_ColumnsLayoutHandleComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [["class", "columns__item-handle-label"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.stick.label; _ck(_v, 1, 0, currVal_0); }); }
export function View_ColumnsLayoutHandleComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "span", [["class", "columns__item-handle"]], [[2, "columns__item-handle_active", null]], [[null, "mousedown"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mousedown" === en)) {
        var pd_0 = (_co.onMouseDown($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ColumnsLayoutHandleComponent_1)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.stick; _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.active; _ck(_v, 0, 0, currVal_0); }); }
export function View_ColumnsLayoutHandleComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-columns-layout-handle", [], null, null, null, View_ColumnsLayoutHandleComponent_0, RenderType_ColumnsLayoutHandleComponent)), i0.ɵdid(1, 114688, null, 0, i2.ColumnsLayoutHandleComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ColumnsLayoutHandleComponentNgFactory = i0.ɵccf("app-columns-layout-handle", i2.ColumnsLayoutHandleComponent, View_ColumnsLayoutHandleComponent_Host_0, { active: "active", stick: "stick" }, { dragStarted: "dragStarted" }, []);
export { ColumnsLayoutHandleComponentNgFactory as ColumnsLayoutHandleComponentNgFactory };

export enum AirtableFieldType {
  AutoNumber = 'autoNumber',
  Barcode = 'barcode',
  Button = 'button',
  Checkbox = 'checkbox',
  Count = 'count',
  CreatedBy = 'createdBy',
  CreatedTime = 'createdTime',
  Currency = 'currency',
  Date = 'date',
  DateTime = 'dateTime',
  Duration = 'duration',
  Email = 'email',
  ExternalSyncSource = 'externalSyncSource',
  Formula = 'formula',
  LastModifiedBy = 'lastModifiedBy',
  LastModifiedTime = 'lastModifiedTime',
  MultilineText = 'multilineText',
  MultipleAttachments = 'multipleAttachments',
  MultipleCollaborators = 'multipleCollaborators',
  MultipleLookupValues = 'multipleLookupValues',
  MultipleRecordLinks = 'multipleRecordLinks',
  MultipleSelects = 'multipleSelects',
  Number = 'number',
  Percent = 'percent',
  PhoneNumber = 'phoneNumber',
  Rating = 'rating',
  RichText = 'richText',
  Rollup = 'rollup',
  SingleCollaborator = 'singleCollaborator',
  SingleLineText = 'singleLineText',
  SingleSelect = 'singleSelect',
  Url = 'url'
}

export const airtableReadOnlyFieldTypes = [
  AirtableFieldType.Formula,
  AirtableFieldType.Count,
  AirtableFieldType.Rollup,
  AirtableFieldType.MultipleLookupValues,
  AirtableFieldType.AutoNumber,
  AirtableFieldType.CreatedBy,
  AirtableFieldType.CreatedTime,
  AirtableFieldType.LastModifiedBy,
  AirtableFieldType.LastModifiedTime,
  AirtableFieldType.ExternalSyncSource
];

export abstract class FieldLookup {
  public lookup: string;
  public param: string;
  public abstract verboseName: string;
  public icon: string;
  public symbol: string;
  public excludable = true;

  abstract str(field?: string, value?: string, exclude?: boolean): string;
  compute?(
    field: string[],
    value: any,
    exclude: boolean
  ): { field: string[]; lookup: FieldLookup; value: any; exclude?: boolean }[];
}

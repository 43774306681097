var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FormControl, FormGroup } from '@angular/forms';
import cloneDeep from 'lodash/cloneDeep';
import { combineLatest, of } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import { PopupPosition, PopupStyle } from '@modules/customize';
import { ElementConfigurationService } from '@modules/customize-configuration';
import { Input, ParameterArray } from '@modules/fields';
import { FieldInputControl } from '@modules/parameters';
import { controlValid } from '@shared';
export var validateActions = function (control) {
    var parent = control.parent;
    if (!parent) {
        return of(null);
    }
    if (!control.value || !control.value.length) {
        return of(null);
    }
    return combineLatest(control.value.map(function (item) { return parent.elementConfigurationService.isActionConfigured(item); })).pipe(map(function (result) {
        if (result.some(function (configured) { return !configured; })) {
            return { required: true };
        }
    }));
};
var CustomizeBarPopupEditForm = /** @class */ (function (_super) {
    __extends(CustomizeBarPopupEditForm, _super);
    function CustomizeBarPopupEditForm(elementConfigurationService) {
        var _this = _super.call(this, {
            name: new FormControl(''),
            title: new FieldInputControl({ name: 'value' }),
            style: new FormControl(PopupStyle.Modal),
            position: new FormControl(PopupPosition.Center),
            overlay: new FormControl(true),
            close_button: new FormControl(true),
            close_on_blur: new FormControl(true),
            parameters: new ParameterArray([]),
            open_actions: new FormControl([], undefined, validateActions),
            close_actions: new FormControl([], undefined, validateActions)
        }) || this;
        _this.elementConfigurationService = elementConfigurationService;
        _this.styleOptions = [
            {
                style: PopupStyle.Modal,
                position: PopupPosition.Center,
                name: 'Modal',
                image: 'popup-modal'
            },
            {
                style: PopupStyle.ModalBackground,
                position: PopupPosition.Center,
                name: 'Modal with background',
                image: 'popup-modal-background'
            },
            {
                style: PopupStyle.Modal,
                position: PopupPosition.SideRight,
                name: 'Slide right',
                image: 'popup-side-right'
            },
            {
                style: PopupStyle.ModalBackground,
                position: PopupPosition.SideRight,
                name: 'Slide right with background',
                image: 'popup-side-right-background'
            },
            {
                style: PopupStyle.Modal,
                position: PopupPosition.Origin,
                name: 'Dropdown',
                image: 'popup-origin'
            },
            {
                style: PopupStyle.ModalBackground,
                position: PopupPosition.Origin,
                name: 'Dropdown with background',
                image: 'popup-origin-background'
            }
        ];
        return _this;
    }
    CustomizeBarPopupEditForm.prototype.init = function (popup, firstInit) {
        if (firstInit === void 0) { firstInit = false; }
        this.popup = popup;
        this.controls.name.patchValue(popup.name);
        this.controls.title.patchValue(popup.title ? popup.title.serialize() : {});
        this.controls.style.patchValue(popup.style);
        this.controls.position.patchValue(popup.position);
        this.controls.overlay.patchValue(popup.overlay);
        this.controls.close_button.patchValue(popup.closeButton);
        this.controls.close_on_blur.patchValue(popup.closeOnBlur);
        this.controls.parameters.patchValue(popup.parameters);
        this.controls.open_actions.patchValue(popup.openActions);
        this.controls.close_actions.patchValue(popup.closeActions);
        this.markAsPristine();
    };
    CustomizeBarPopupEditForm.prototype.controlsValid$ = function (controls) {
        return combineLatest(controls.map(function (control) { return controlValid(control); })).pipe(map(function (result) { return result.every(function (item) { return item; }); }), debounceTime(60));
    };
    CustomizeBarPopupEditForm.prototype.actionsValid$ = function () {
        return this.controlsValid$([this.controls.open_actions, this.controls.close_actions]);
    };
    CustomizeBarPopupEditForm.prototype.submit = function () {
        var instance = cloneDeep(this.popup);
        instance.name = this.controls.name.value;
        instance.title = this.controls.title.value ? new Input().deserialize(this.controls.title.value) : undefined;
        instance.style = this.controls.style.value;
        instance.position = this.controls.position.value;
        instance.overlay = this.controls.overlay.value;
        instance.closeButton = this.controls.close_button.value;
        instance.closeOnBlur = this.controls.close_on_blur.value;
        instance.parameters = this.controls.parameters.value;
        if (this.controls.open_actions.value) {
            instance.openActions = this.controls.open_actions.value;
        }
        if (this.controls.close_actions.value) {
            instance.closeActions = this.controls.close_actions.value;
        }
        return instance;
    };
    return CustomizeBarPopupEditForm;
}(FormGroup));
export { CustomizeBarPopupEditForm };

<app-page-templates-choose-item
  *ngIf="!selectedModel"
  [title]="title"
  [cancelEnabled]="cancelEnabled"
  [newPage]="newPage"
  [template]="selectedTemplate"
  [templateVariant]="selectedTemplateVariant"
  [resource]="resource"
  [analyticsSource]="analyticsSource"
  (selected)="onSelectedItem($event)"
  (cancelClick)="cancel()"
></app-page-templates-choose-item>

<app-page-templates-item-settings
  *ngIf="selectedTemplate && selectedModel"
  [template]="selectedTemplate"
  [templateVariant]="selectedTemplateVariant"
  [modelDescription]="selectedModel"
  [cancelEnabled]="cancelEnabled"
  [analyticsSource]="analyticsSource"
  (createdStartPage)="onCreatedStartPage($event)"
  (backToTypeClick)="setSelectedTemplate(undefined); setSelectedModel(undefined)"
  (backToModelClick)="setSelectedModel(undefined)"
  (cancelClick)="cancel()"
></app-page-templates-item-settings>

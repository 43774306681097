<ng-container *ngIf="popup">
  <div class="sidebar-section-border"></div>

  <app-sidebar-section [title]="'Overlay Parameters'" [documentation]="'inputs'">
    <div class="sidebar__list">
      <app-parameters-edit-with-inputs
        [control]="form.popupParametersControl"
        [inputsControl]="form.controls.inputs"
        [parameterProvider]="form.inputFieldProvider"
        [context]="context"
        [contextElement]="contextElement"
        [contextElementPath]="contextElementPath"
        [contextElementPaths]="contextElementPaths"
        [userInput]="userInput"
        [displayValueTypes]="userInput ? [inputValueTypes.Formula, inputValueTypes.Prompt] : [inputValueTypes.Formula]"
        [configurable]="{
          name: true,
          field: true,
          required: true,
          add: true,
          sortable: true
        }"
        [classes]="'component-action-inputs-item'"
        [analyticsSource]="analyticsSource"
      >
      </app-parameters-edit-with-inputs>
    </div>
  </app-sidebar-section>
</ng-container>

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { CdkConnectedOverlay } from '@angular/cdk/overlay';
import { AfterViewInit, ChangeDetectorRef, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { fieldDescriptions, FieldType, registerFieldComponent } from '@modules/fields';
import { isSet } from '@shared';
import { FieldComponent } from '../field/field.component';
var FieldTypeFieldComponent = /** @class */ (function (_super) {
    __extends(FieldTypeFieldComponent, _super);
    function FieldTypeFieldComponent(cd) {
        var _this = _super.call(this) || this;
        _this.cd = cd;
        _this.dropdownOpened = false;
        _this.dropdownPositions = [
            {
                panelClass: ['overlay_position_bottom-left'],
                originX: 'start',
                overlayX: 'start',
                originY: 'bottom',
                overlayY: 'top',
                offsetX: -8
            },
            {
                panelClass: ['overlay_position_bottom-right'],
                originX: 'end',
                overlayX: 'end',
                originY: 'bottom',
                overlayY: 'top',
                offsetX: 8
            },
            {
                panelClass: ['overlay_position_top-left'],
                originX: 'start',
                overlayX: 'start',
                originY: 'top',
                overlayY: 'bottom',
                offsetX: -8
            },
            {
                panelClass: ['overlay_position_top-right'],
                originX: 'end',
                overlayX: 'end',
                originY: 'top',
                overlayY: 'bottom',
                offsetX: 8
            },
            {
                panelClass: ['overlay_position_left-center'],
                originX: 'start',
                overlayX: 'end',
                originY: 'center',
                overlayY: 'center'
            },
            {
                panelClass: ['overlay_position_right-center'],
                originX: 'end',
                overlayX: 'start',
                originY: 'center',
                overlayY: 'center'
            }
        ];
        return _this;
    }
    FieldTypeFieldComponent.prototype.ngOnInit = function () {
        this.initCurrentValue();
    };
    FieldTypeFieldComponent.prototype.ngOnDestroy = function () { };
    FieldTypeFieldComponent.prototype.ngOnChanges = function (changes) {
        if (changes.fieldTypeSections) {
            this.fieldTypeSectionItems = this.fieldTypeSections
                ? this.fieldTypeSections.reduce(function (acc, section) {
                    var items = section.children.map(function (item) {
                        return __assign({}, item, { icon: item.icon || section.icon });
                    });
                    acc.push.apply(acc, items);
                    return acc;
                }, [])
                : undefined;
        }
        if (changes.value) {
            this.initCurrentValue();
        }
    };
    FieldTypeFieldComponent.prototype.ngAfterViewInit = function () {
        this.setDropdownPositionObserver();
    };
    FieldTypeFieldComponent.prototype.initCurrentValue = function () {
        var _this = this;
        if (this.currentValueSubscription) {
            this.currentValueSubscription.unsubscribe();
            this.currentValueSubscription = undefined;
        }
        this.currentValueSubscription = this.currentValue$.pipe(untilDestroyed(this)).subscribe(function (value) {
            if (_this.fieldTypeSectionItems) {
                var currentItem = isSet(value) ? _this.fieldTypeSectionItems.find(function (item) { return item.value == value; }) : undefined;
                _this.currentValueLabel = currentItem ? currentItem.name : undefined;
                _this.currentValueIcon = currentItem ? currentItem.icon : undefined;
            }
            else {
                var fieldDescription = isSet(value) ? fieldDescriptions.find(function (item) { return item.name == value; }) : undefined;
                _this.currentValueLabel = fieldDescription ? fieldDescription.label : undefined;
                _this.currentValueIcon = fieldDescription ? fieldDescription.icon : undefined;
            }
            _this.cd.markForCheck();
        });
    };
    FieldTypeFieldComponent.prototype.setDropdownOpened = function (value) {
        this.dropdownOpened = value;
        this.cd.markForCheck();
    };
    FieldTypeFieldComponent.prototype.onInputClick = function () {
        this.setDropdownOpened(true);
    };
    FieldTypeFieldComponent.prototype.setDropdownPositionObserver = function () {
        var _this = this;
        if (this.dropdownPositionsSubscription) {
            this.dropdownPositionsSubscription.unsubscribe();
        }
        if (!this.cdkConnectedOverlay) {
            return;
        }
        this.dropdownPositionsSubscription = this.cdkConnectedOverlay.positionChange
            .pipe(untilDestroyed(this))
            .subscribe(function (e) {
            var propsEqual = ['offsetX', 'offsetY', 'originX', 'originY', 'overlayX', 'overlayY'];
            var position = _this.dropdownPositions.find(function (item) {
                return propsEqual.every(function (prop) { return (item[prop] || undefined) == e.connectionPair[prop]; });
            });
            var otherPosition = _this.dropdownPositions.filter(function (item) { return item !== position; });
            if (position) {
                _this.cdkConnectedOverlay.overlayRef.addPanelClass(position.panelClass);
            }
            otherPosition.forEach(function (item) { return _this.cdkConnectedOverlay.overlayRef.removePanelClass(item.panelClass); });
        });
    };
    return FieldTypeFieldComponent;
}(FieldComponent));
export { FieldTypeFieldComponent };
registerFieldComponent(FieldType.FieldType, FieldTypeFieldComponent);

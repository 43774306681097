/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/forms";
import * as i2 from "../../../../shared/directives/form-control-selector/form-control-selector.directive";
import * as i3 from "../../../sidebar/components/sidebar-card/sidebar-card.component.ngfactory";
import * as i4 from "../../../sidebar/components/sidebar-card/sidebar-card.component";
import * as i5 from "@angular/common";
import * as i6 from "./gap-control.component";
var styles_GapControlComponent = [];
var RenderType_GapControlComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_GapControlComponent, data: {} });
export { RenderType_GapControlComponent as RenderType_GapControlComponent };
function View_GapControlComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 10, "div", [["class", "sidebar-cols__item"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 9, "div", [["class", "input-group"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 0, "div", [["class", "input-group__item input-group__item_first input input_small input_disabled icon-resize_horizontal"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, [["gap_horizontal", 1]], null, 7, "input", [["class", "input-group__item input-group__item_last input-group__fill input input_number input_small"], ["min", "0"], ["placeholder", "auto"], ["type", "number"]], [[4, "font-size", "px"], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "focus"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"], [null, "change"]], function (_v, en, $event) { var ad = true; if (("input" === en)) {
        var pd_0 = (i0.ɵnov(_v, 4)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i0.ɵnov(_v, 4).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i0.ɵnov(_v, 4)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i0.ɵnov(_v, 4)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("change" === en)) {
        var pd_4 = (i0.ɵnov(_v, 5).onChange($event.target.value) !== false);
        ad = (pd_4 && ad);
    } if (("input" === en)) {
        var pd_5 = (i0.ɵnov(_v, 5).onChange($event.target.value) !== false);
        ad = (pd_5 && ad);
    } if (("blur" === en)) {
        var pd_6 = (i0.ɵnov(_v, 5).onTouched() !== false);
        ad = (pd_6 && ad);
    } if (("focus" === en)) {
        var pd_7 = (i0.ɵnov(_v, 3).select() !== false);
        ad = (pd_7 && ad);
    } return ad; }, null, null)), i0.ɵdid(4, 16384, null, 0, i1.DefaultValueAccessor, [i0.Renderer2, i0.ElementRef, [2, i1.COMPOSITION_BUFFER_MODE]], null, null), i0.ɵdid(5, 16384, null, 0, i1.ɵangular_packages_forms_forms_be, [i0.Renderer2, i0.ElementRef], null, null), i0.ɵprd(1024, null, i1.NG_VALUE_ACCESSOR, function (p0_0, p1_0) { return [p0_0, p1_0]; }, [i1.DefaultValueAccessor, i1.ɵangular_packages_forms_forms_be]), i0.ɵdid(7, 540672, null, 0, i1.FormControlDirective, [[8, null], [8, null], [6, i1.NG_VALUE_ACCESSOR], [2, i1.ɵangular_packages_forms_forms_k]], { form: [0, "form"] }, null), i0.ɵprd(2048, null, i1.NgControl, null, [i1.FormControlDirective]), i0.ɵdid(9, 16384, null, 0, i1.NgControlStatus, [[4, i1.NgControl]], null, null), i0.ɵdid(10, 81920, null, 0, i2.FormControlSelectorDirective, [i0.ElementRef, [2, i1.FormControlDirective], [2, i1.FormControlName]], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_8 = _co.horizontalControl; _ck(_v, 7, 0, currVal_8); _ck(_v, 10, 0); }, function (_ck, _v) { var currVal_0 = 14; var currVal_1 = i0.ɵnov(_v, 9).ngClassUntouched; var currVal_2 = i0.ɵnov(_v, 9).ngClassTouched; var currVal_3 = i0.ɵnov(_v, 9).ngClassPristine; var currVal_4 = i0.ɵnov(_v, 9).ngClassDirty; var currVal_5 = i0.ɵnov(_v, 9).ngClassValid; var currVal_6 = i0.ɵnov(_v, 9).ngClassInvalid; var currVal_7 = i0.ɵnov(_v, 9).ngClassPending; _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }); }
function View_GapControlComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 10, "div", [["class", "sidebar-cols__item"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 9, "div", [["class", "input-group"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 0, "div", [["class", "input-group__item input-group__item_first input input_small input_disabled icon-resize_vertical"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, [["gap_vertical", 1]], null, 7, "input", [["class", "input-group__item input-group__item_last input-group__fill input input_number input_small"], ["min", "0"], ["placeholder", "auto"], ["type", "number"]], [[4, "font-size", "px"], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "focus"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"], [null, "change"]], function (_v, en, $event) { var ad = true; if (("input" === en)) {
        var pd_0 = (i0.ɵnov(_v, 4)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i0.ɵnov(_v, 4).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i0.ɵnov(_v, 4)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i0.ɵnov(_v, 4)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("change" === en)) {
        var pd_4 = (i0.ɵnov(_v, 5).onChange($event.target.value) !== false);
        ad = (pd_4 && ad);
    } if (("input" === en)) {
        var pd_5 = (i0.ɵnov(_v, 5).onChange($event.target.value) !== false);
        ad = (pd_5 && ad);
    } if (("blur" === en)) {
        var pd_6 = (i0.ɵnov(_v, 5).onTouched() !== false);
        ad = (pd_6 && ad);
    } if (("focus" === en)) {
        var pd_7 = (i0.ɵnov(_v, 3).select() !== false);
        ad = (pd_7 && ad);
    } return ad; }, null, null)), i0.ɵdid(4, 16384, null, 0, i1.DefaultValueAccessor, [i0.Renderer2, i0.ElementRef, [2, i1.COMPOSITION_BUFFER_MODE]], null, null), i0.ɵdid(5, 16384, null, 0, i1.ɵangular_packages_forms_forms_be, [i0.Renderer2, i0.ElementRef], null, null), i0.ɵprd(1024, null, i1.NG_VALUE_ACCESSOR, function (p0_0, p1_0) { return [p0_0, p1_0]; }, [i1.DefaultValueAccessor, i1.ɵangular_packages_forms_forms_be]), i0.ɵdid(7, 540672, null, 0, i1.FormControlDirective, [[8, null], [8, null], [6, i1.NG_VALUE_ACCESSOR], [2, i1.ɵangular_packages_forms_forms_k]], { form: [0, "form"] }, null), i0.ɵprd(2048, null, i1.NgControl, null, [i1.FormControlDirective]), i0.ɵdid(9, 16384, null, 0, i1.NgControlStatus, [[4, i1.NgControl]], null, null), i0.ɵdid(10, 81920, null, 0, i2.FormControlSelectorDirective, [i0.ElementRef, [2, i1.FormControlDirective], [2, i1.FormControlName]], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_8 = _co.verticalControl; _ck(_v, 7, 0, currVal_8); _ck(_v, 10, 0); }, function (_ck, _v) { var currVal_0 = 14; var currVal_1 = i0.ɵnov(_v, 9).ngClassUntouched; var currVal_2 = i0.ɵnov(_v, 9).ngClassTouched; var currVal_3 = i0.ɵnov(_v, 9).ngClassPristine; var currVal_4 = i0.ɵnov(_v, 9).ngClassDirty; var currVal_5 = i0.ɵnov(_v, 9).ngClassValid; var currVal_6 = i0.ɵnov(_v, 9).ngClassInvalid; var currVal_7 = i0.ɵnov(_v, 9).ngClassPending; _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }); }
export function View_GapControlComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "app-sidebar-card", [], null, null, null, i3.View_SidebarCardComponent_0, i3.RenderType_SidebarCardComponent)), i0.ɵdid(1, 114688, null, 0, i4.SidebarCardComponent, [], null, null), (_l()(), i0.ɵeld(2, 0, null, 0, 5, "div", [["class", "sidebar__element sidebar__element_padding_s sidebar__element_margin_xs"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 4, "div", [["class", "sidebar-cols"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_GapControlComponent_1)), i0.ɵdid(5, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_GapControlComponent_2)), i0.ɵdid(7, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 1, 0); var currVal_0 = _co.horizontalControl; _ck(_v, 5, 0, currVal_0); var currVal_1 = _co.verticalControl; _ck(_v, 7, 0, currVal_1); }, null); }
export function View_GapControlComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-gap-control", [], null, null, null, View_GapControlComponent_0, RenderType_GapControlComponent)), i0.ɵdid(1, 114688, null, 0, i6.GapControlComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var GapControlComponentNgFactory = i0.ɵccf("app-gap-control", i6.GapControlComponent, View_GapControlComponent_Host_0, { horizontalControl: "horizontalControl", verticalControl: "verticalControl" }, {}, []);
export { GapControlComponentNgFactory as GapControlComponentNgFactory };

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Provider
} from '@angular/core';

import { BasePopupComponent } from '@common/popups';
import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { ViewSettings } from '@modules/customize';
import { ModelDescription } from '@modules/models';
import { CurrentEnvironmentStore, CurrentProjectStore, Resource } from '@modules/projects';
import { RoutingService } from '@modules/routing';
import { PageTemplate, PageVariantType } from '@modules/template-generators';
import { PAGE_TEMPLATES_COMPONENT } from '@modules/template-queries';

export const PAGE_TEMPLATES_COMPONENT_PROVIDER: Provider = {
  provide: PAGE_TEMPLATES_COMPONENT,
  useFactory: pageTemplatesComponentFactory
};

export function pageTemplatesComponentFactory(): any {
  return PageTemplatesComponent;
}

@Component({
  selector: 'app-page-templates',
  templateUrl: './page-templates.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PageTemplatesComponent implements OnInit, OnDestroy {
  @Input() title: string;
  @Input() resource: Resource;
  @Input() cancelEnabled = false;
  @Input() newPage = false;
  @Input() publish = false;
  @Input() analyticsSource: string;
  @Output() createdPages = new EventEmitter<{
    startPage: ViewSettings;
  }>();
  @Output() createNewPage = new EventEmitter<void>();
  @Output() cancelled = new EventEmitter<void>();

  selectedTemplate: PageTemplate;
  selectedTemplateVariant: PageVariantType;
  selectedModel: ModelDescription;

  constructor(
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private popupComponent: BasePopupComponent,
    private routing: RoutingService,
    private analyticsService: UniversalAnalyticsService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {}

  ngOnDestroy(): void {}

  setSelectedTemplate(template: PageTemplate) {
    this.selectedTemplate = template;
    this.cd.markForCheck();
  }

  setSelectedTemplateVariant(variant: PageVariantType) {
    this.selectedTemplateVariant = variant;
    this.cd.markForCheck();
  }

  setSelectedModel(modelDescription: ModelDescription) {
    this.selectedModel = modelDescription;
    this.cd.markForCheck();
  }

  close(data?: any) {
    if (this.popupComponent) {
      this.popupComponent.close(data);
    }
  }

  cancel() {
    this.close();
    this.cancelled.emit();
  }

  onSelectedItem(value: {
    template: PageTemplate;
    templateVariant: PageVariantType;
    modelDescription?: ModelDescription;
  }) {
    if (value.template.type) {
      this.setSelectedTemplate(value.template);
      this.setSelectedTemplateVariant(value.templateVariant);
      this.setSelectedModel(value.modelDescription);
    } else {
      this.close();
      this.createNewPage.emit();

      this.analyticsService.sendSimpleEvent(AnalyticsEvent.PageTemplates.Applied, {
        Template: 'new_page',
        Source: this.analyticsSource
      });
    }
  }

  onCreatedStartPage(page: ViewSettings) {
    this.close();

    this.createdPages.emit({
      startPage: page
    });

    const resource = this.selectedModel
      ? this.currentEnvironmentStore.resources.find(item => item.uniqueName == this.selectedModel.resource)
      : undefined;

    this.analyticsService.sendSimpleEvent(AnalyticsEvent.PageTemplates.Applied, {
      Template: this.selectedTemplate ? this.selectedTemplate.type : undefined,
      TemplateVariant: this.selectedTemplateVariant,
      ResourceType: resource ? resource.typeItem.name : undefined,
      Resource: resource ? resource.uniqueName : undefined,
      Collection: this.selectedModel ? this.selectedModel.model : undefined,
      Source: this.analyticsSource
    });
  }
}

<app-add-step-menu
  [actionTypesEnabled]="actionTypesEnabled"
  (add)="stepAddBefore.emit($event)"
  #add_step_menu
></app-add-step-menu>

<div class="workflow-connection" [class.workflow-connection_first]="index == 0">
  <div
    *ngIf="stepHasPreviousConnection()"
    class="workflow-connection__bounds workflow-connection__bounds_interactive"
    [matMenuTriggerFor]="add_step_menu.menu"
    [matMenuTriggerData]="{ trigger: trigger }"
    #trigger="matMenuTrigger"
  >
    <div class="workflow-connection__dash">
      <div
        *ngIf="workflowEditable"
        class="workflow-connection__button icon-plus"
        [class.workflow-connection__button_active]="trigger.menuOpen"
        [ngClass]="[
          'workflow-connection__button_size_s',
          'workflow-connection__button_position_center',
          'workflow-connection__button_on-hover'
        ]"
      ></div>
    </div>
  </div>

  <div *ngIf="!stepHasPreviousConnection()" class="workflow-connection__space"></div>
</div>

<div class="workflow-iterator">
  <div class="workflow-iterator__back-path workflow-iterator__back-path_position_top">
    <div class="workflow-iterator__back-path-arrow workflow-iterator__back-path-arrow_position_top-right"></div>
  </div>

  <div class="workflow-iterator__back-path workflow-iterator__back-path_position_bottom"></div>

  <div class="workflow-iterator__root">
    <app-workflow-step-card
      appDragHandle
      [title]="'Iterator'"
      [subtitle]="step.name"
      [icon]="icon"
      [indicatorLoader]="status == statuses.Executing"
      [indicatorIcon]="statusIcon"
      [indicatorColor]="statusColor"
      [active]="customizing$ | async"
      [interactive]="true"
      [connectorTop]="stepHasPreviousConnection()"
      [duplicateEnabled]="workflowEditable"
      [deleteEnabled]="workflowEditable"
      [executeEnabled]="workflowEditable"
      (click)="stepCustomize.emit()"
      (stepDuplicate)="stepDuplicate.emit()"
      (stepDelete)="stepDelete.emit()"
      (stepExecute)="stepExecute.emit()"
    ></app-workflow-step-card>
  </div>

  <div class="workflow-iterator__children">
    <div class="workflow-connection workflow-iterator__footer-connection">
      <div class="workflow-connection__bounds">
        <div class="workflow-connection__dash"></div>
      </div>
    </div>

    <app-add-step-menu
      [actionTypesEnabled]="actionTypesEnabled"
      (add)="addStep($event)"
      #add_step_menu
    ></app-add-step-menu>

    <div
      class="workflow-container"
      appDropList
      [appDropListData]="step.steps"
      [appDropListDisabled]="!workflowEditable"
      [appDropListSwapDistance]="20"
      [appDropListAreaMarginForward]="[-110, -40, -66, -40]"
      [appDropListAreaMarginBackward]="[-66, -40, -80, -40]"
      [appDropListPreviewMissPointerStartDelta]="{ x: 20, y: 20 }"
      (appDropListDropped)="dragDrop($event)"
    >
      <app-auto-workflow-step
        *ngFor="let item of step.steps; let i = index; let first = first; trackBy: trackStep"
        class="workflow-container__item"
        appDrag
        [appDragData]="item"
        [step]="item"
        [prevStep]="step.steps[i - 1]"
        [workflowEditable]="workflowEditable"
        [index]="i"
        [context]="context"
        [actionTypesEnabled]="actionTypesEnabled"
        [analyticsSource]="analyticsSource"
        (stepAddBefore)="addStep($event, { addBeforeIndex: i })"
        (stepDuplicate)="duplicateStep(i)"
        (stepDelete)="deleteStep(i)"
      >
        <app-workflow-step-card
          *appDragPreview
          [title]="item.name"
          [subtitle]="item.type"
          [active]="true"
          [connectorTop]="false"
          [connectorBottom]="false"
        ></app-workflow-step-card>
      </app-auto-workflow-step>
    </div>

    <div class="workflow-connection">
      <div *ngIf="stepsHasNextConnection()" class="workflow-connection__bounds">
        <div class="workflow-connection__dash" [class.workflow-connection__dash_size_l]="step.steps.length">
          <div
            *ngIf="workflowEditable"
            class="workflow-connection__button icon-plus"
            [class.workflow-connection__button_position_top]="!step.steps.length"
            [class.workflow-connection__button_position_center]="step.steps.length"
            [class.workflow-connection__button_active]="trigger.menuOpen"
            [matMenuTriggerFor]="add_step_menu.menu"
            [matMenuTriggerData]="{ trigger: trigger }"
            #trigger="matMenuTrigger"
          ></div>
        </div>
      </div>

      <div *ngIf="!stepsHasNextConnection()" class="workflow-connection__space"></div>
    </div>
  </div>

  <div class="workflow-iterator__footer">
    <div class="workflow-iterator__footer-label">
      Iterator end
    </div>
  </div>
</div>

import { fromEvent, Observable, of, ReplaySubject } from 'rxjs';

export function fileToBase64(file: File): Observable<string> {
  if (!file) {
    return of(undefined);
  }

  const obs = new ReplaySubject<string>();
  const fileReader = new FileReader();

  fromEvent<ProgressEvent>(fileReader, 'load').subscribe(e => {
    obs.next(e.target['result']);
  });

  fileReader.readAsDataURL(file);

  return obs;
}

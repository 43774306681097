import { Injectable } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment-timezone';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { FormUtils } from '@common/form-utils';
import { DEFAULT_LANGUAGE, getLanguages } from '@common/localize';
import { LocalStorage, timezones } from '@core';
import { Option } from '@modules/field-components';
import { CurrentUserStore, User } from '@modules/users';
import { NonEmptyValidator, trimAll } from '@shared';

@Injectable()
export class UserEditForm extends FormGroup {
  controls: {
    first_name: FormControl;
    last_name: FormControl;
    email_subscription: FormControl;
    new_password: FormControl;
    repeat_password: FormControl;
    language: FormControl;
    timezone: FormControl;
  };

  user: User;
  languageInitial: string;
  timezoneInitial: string;

  languageOptions: Option[] = [];
  languageDefaultOption: Option;
  timezoneOptions: Option[] = [];
  timezoneDefaultOption: Option;

  constructor(
    private formUtils: FormUtils,
    private fb: FormBuilder,
    private currentUserStore: CurrentUserStore,
    private localStorage: LocalStorage
  ) {
    super({
      first_name: new FormControl('', [Validators.required, NonEmptyValidator("This field can't be empty")]),
      last_name: new FormControl(''),
      email_subscription: new FormControl(false),
      new_password: new FormControl(''),
      repeat_password: new FormControl(''),
      language: new FormControl(''),
      timezone: new FormControl('')
    });
  }

  init(user: User) {
    this.languageOptions = getLanguages();
    this.languageDefaultOption = this.languageOptions.find(item => item.value == DEFAULT_LANGUAGE);

    this.timezoneOptions = timezones.map(item => {
      return {
        value: item.name,
        name: `(GMT${item.gmt}) ${item.name}`,
        data: {
          name: item.name,
          tz: item.tz
        }
      };
    });
    this.timezoneDefaultOption = this.timezoneOptions.find(item => item.data['tz'] == moment.tz.guess());

    this.user = user;
    this.languageInitial = user.language;
    this.timezoneInitial = user.timezone;

    this.controls.first_name.patchValue(user.firstName);
    this.controls.last_name.patchValue(user.lastName);
    this.controls.email_subscription.patchValue(user.emailSubscription);
    this.controls.language.patchValue(user.language);
    this.controls.timezone.patchValue(user.timezone);

    this.markAsPristine();
  }

  submit(): Observable<{ languageChanged: boolean; timezoneChanged: boolean }> {
    this.markAsDirty();

    const languageChanged = this.controls.language.value != this.languageInitial;
    const timezoneChanged = this.controls.timezone.value != this.timezoneInitial;

    this.currentUserStore.instance.firstName = trimAll(this.controls.first_name.value);
    this.currentUserStore.instance.lastName = trimAll(this.controls.last_name.value);
    this.currentUserStore.instance.emailSubscription = this.controls.email_subscription.value;
    this.currentUserStore.instance.language = this.controls.language.value;
    this.currentUserStore.instance.timezone = this.controls.timezone.value;

    return this.currentUserStore.update(['first_name', 'last_name', 'email_subscription', 'language', 'timezone']).pipe(
      map(user => {
        this.init(user);
        this.markAsPristine();

        if (languageChanged) {
          this.localStorage.setLanguage(this.controls.language.value);
        }

        if (timezoneChanged) {
          this.localStorage.setTimezone(this.controls.timezone.value);
        }

        return {
          languageChanged: languageChanged,
          timezoneChanged: timezoneChanged
        };
      }),
      catchError(error => {
        this.formUtils.showFormErrors(this, error);
        return throwError(error);
      })
    );
  }

  toggleEmailSubscription() {
    this.controls.email_subscription.setValue(!this.controls.email_subscription.value);
    this.markAsDirty();
  }
}

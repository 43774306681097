/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./token-structure-item.component";
import * as i3 from "../../../../common/tips/directives/tip/tip.directive";
import * as i4 from "../../../../shared/pipes/is-set/is-set.pipe";
var styles_TokenStructureItemComponent = [];
var RenderType_TokenStructureItemComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_TokenStructureItemComponent, data: {} });
export { RenderType_TokenStructureItemComponent as RenderType_TokenStructureItemComponent };
function View_TokenStructureItemComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, ":svg:svg", [["height", "35"], ["viewBox", "0 0 25 35"], ["width", "25"], ["xmlns", "http://www.w3.org/2000/svg"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, ":svg:path", [["d", "m1 35h-1v-35h1z"], ["fill", "#424b56"], ["fill-rule", "evenodd"], ["transform", "translate(11)"]], null, null, null, null, null))], null, null); }
function View_TokenStructureItemComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "span", [["class", "code-editor__structure-item-icon"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TokenStructureItemComponent_4)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = !_v.parent.context.$implicit; _ck(_v, 2, 0, currVal_0); }, null); }
function View_TokenStructureItemComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TokenStructureItemComponent_3)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = !_v.context.first; _ck(_v, 2, 0, currVal_0); }, null); }
function View_TokenStructureItemComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, ":svg:svg", [["height", "35"], ["viewBox", "0 0 25 35"], ["width", "25"], ["xmlns", "http://www.w3.org/2000/svg"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, ":svg:path", [["d", "M17,35 L16,35 L16,0 L17,0 L17,17 L25,17 L25,18 L17,18 L17,35 Z"], ["fill", "#424B56"], ["fill-rule", "evenodd"], ["transform", "translate(-5)"]], null, null, null, null, null))], null, null); }
function View_TokenStructureItemComponent_7(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, ":svg:svg", [["height", "35"], ["viewBox", "0 0 25 35"], ["width", "25"], ["xmlns", "http://www.w3.org/2000/svg"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, ":svg:path", [["d", "M16,18 L16,0 L17,0 L17,17 L25,17 L25,18 L16,18 Z"], ["fill", "#424B56"], ["fill-rule", "evenodd"], ["transform", "translate(-5)"]], null, null, null, null, null))], null, null); }
function View_TokenStructureItemComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "code-editor__structure-item-icon"]], [[2, "code-editor__structure-item-icon_margin", null]], null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TokenStructureItemComponent_6)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TokenStructureItemComponent_7)), i0.ɵdid(4, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = !_co.itemLast; _ck(_v, 2, 0, currVal_1); var currVal_2 = _co.itemLast; _ck(_v, 4, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = _v.parent.context.margin; _ck(_v, 0, 0, currVal_0); }); }
function View_TokenStructureItemComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_TokenStructureItemComponent_2)), i0.ɵdid(1, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TokenStructureItemComponent_5)), i0.ɵdid(3, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.parentsLast; _ck(_v, 1, 0, currVal_0); var currVal_1 = (_co.parentsLast.length > 0); _ck(_v, 3, 0, currVal_1); }, null); }
function View_TokenStructureItemComponent_9(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_TokenStructureItemComponent_10(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "span", [], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i0.ɵted(1, null, [" (", ") "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.item.name; _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.item.children.length; _ck(_v, 1, 0, currVal_1); }); }
function View_TokenStructureItemComponent_11(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "span", [], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" ["])), (_l()(), i0.ɵeld(3, 0, null, null, 0, "span", [], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i0.ɵted(4, null, ["] (", ") "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.item.label; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.item.name; _ck(_v, 3, 0, currVal_1); var currVal_2 = _co.item.children.length; _ck(_v, 4, 0, currVal_2); }); }
function View_TokenStructureItemComponent_13(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-token-structure-item", [], null, [[null, "inserted"], [null, "valueChanged"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("inserted" === en)) {
        var pd_0 = (_co.inserted.next($event) !== false);
        ad = (pd_0 && ad);
    } if (("valueChanged" === en)) {
        var pd_1 = (_co.onValueChanged($event.item, $event.value) !== false);
        ad = (pd_1 && ad);
    } return ad; }, View_TokenStructureItemComponent_0, RenderType_TokenStructureItemComponent)), i0.ɵdid(1, 114688, null, 0, i2.TokenStructureItemComponent, [i0.ChangeDetectorRef], { item: [0, "item"], itemLast: [1, "itemLast"], parentsLast: [2, "parentsLast"] }, { inserted: "inserted", valueChanged: "valueChanged" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit; var currVal_1 = _v.context.last; var currVal_2 = _co.parentsLastChild; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_TokenStructureItemComponent_12(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TokenStructureItemComponent_13)), i0.ɵdid(2, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.item.children; _ck(_v, 2, 0, currVal_0); }, null); }
function View_TokenStructureItemComponent_8(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 16, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 13, "a", [["class", "code-editor__structure-item"], ["href", "javascript:void(0)"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleOpened() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TokenStructureItemComponent_9)), i0.ɵdid(3, 540672, null, 0, i1.NgTemplateOutlet, [i0.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null), (_l()(), i0.ɵeld(4, 0, null, null, 0, "span", [["class", "code-editor__structure-item-icon code-editor__structure-item-icon_margin"]], [[2, "code-editor__structure-item-icon_active", null], [2, "icon-open_folder", null], [2, "icon-folder", null]], null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 9, "span", [["class", "code-editor__structure-item-label"]], [[8, "title", 0]], null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 4, "span", [["class", "code-editor__structure-item-label-main"]], null, null, null, null, null)), (_l()(), i0.ɵand(0, [["default_template", 2]], null, 0, null, View_TokenStructureItemComponent_10)), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_TokenStructureItemComponent_11)), i0.ɵdid(9, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), i0.ɵppd(10, 1), (_l()(), i0.ɵeld(11, 0, null, null, 3, "span", [["class", "code-editor__structure-item-label-actions"]], null, null, null, null, null)), (_l()(), i0.ɵeld(12, 0, null, null, 2, "a", [["class", "code-editor__structure-item-action icon-redo"], ["href", "javascript:void(0)"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        $event.stopPropagation();
        var pd_0 = (_co.insert(_co.item) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(13, 737280, null, 0, i3.TipDirective, [i0.ElementRef, i0.ComponentFactoryResolver, i0.Injector, i0.ApplicationRef], { content: [0, "content"], optionsRaw: [1, "optionsRaw"] }, null), i0.ɵpod(14, { side: 0 }), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TokenStructureItemComponent_12)), i0.ɵdid(16, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵnov(_v.parent, 1); _ck(_v, 3, 0, currVal_0); var currVal_5 = (i0.ɵunv(_v, 9, 0, _ck(_v, 10, 0, i0.ɵnov(_v.parent, 0), _co.item.label)) && (_co.item.label != _co.item.name)); var currVal_6 = i0.ɵnov(_v, 7); _ck(_v, 9, 0, currVal_5, currVal_6); var currVal_7 = "Insert into query"; var currVal_8 = _ck(_v, 14, 0, "top"); _ck(_v, 13, 0, currVal_7, currVal_8); var currVal_9 = _co.opened; _ck(_v, 16, 0, currVal_9); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.item.children.length && _co.opened); var currVal_2 = (_co.item.children.length && _co.opened); var currVal_3 = (_co.item.children.length && !_co.opened); _ck(_v, 4, 0, currVal_1, currVal_2, currVal_3); var currVal_4 = (_co.item.label ? _co.item.label : ""); _ck(_v, 5, 0, currVal_4); }); }
function View_TokenStructureItemComponent_15(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_TokenStructureItemComponent_16(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "span", [], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.item.name; _ck(_v, 0, 0, currVal_0); }); }
function View_TokenStructureItemComponent_17(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "span", [], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" ["])), (_l()(), i0.ɵeld(3, 0, null, null, 0, "span", [], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["] "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.item.label; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.item.name; _ck(_v, 3, 0, currVal_1); }); }
function View_TokenStructureItemComponent_14(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 13, "div", [["class", "code-editor__structure-item"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_TokenStructureItemComponent_15)), i0.ɵdid(2, 540672, null, 0, i1.NgTemplateOutlet, [i0.ViewContainerRef], { ngTemplateOutletContext: [0, "ngTemplateOutletContext"], ngTemplateOutlet: [1, "ngTemplateOutlet"] }, null), i0.ɵpod(3, { margin: 0 }), (_l()(), i0.ɵeld(4, 0, null, null, 9, "a", [["class", "code-editor__structure-item-label"], ["href", "javascript:void(0)"]], [[8, "title", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.insert(_co.item) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 4, "span", [["class", "code-editor__structure-item-label-main"]], null, null, null, null, null)), (_l()(), i0.ɵand(0, [["default_template", 2]], null, 0, null, View_TokenStructureItemComponent_16)), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_TokenStructureItemComponent_17)), i0.ɵdid(8, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), i0.ɵppd(9, 1), (_l()(), i0.ɵeld(10, 0, null, null, 3, "span", [["class", "code-editor__structure-item-label-actions"]], null, null, null, null, null)), (_l()(), i0.ɵeld(11, 0, null, null, 2, "span", [["class", "code-editor__structure-item-action icon-redo"]], null, null, null, null, null)), i0.ɵdid(12, 737280, null, 0, i3.TipDirective, [i0.ElementRef, i0.ComponentFactoryResolver, i0.Injector, i0.ApplicationRef], { content: [0, "content"], optionsRaw: [1, "optionsRaw"] }, null), i0.ɵpod(13, { side: 0 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 3, 0, true); var currVal_1 = i0.ɵnov(_v.parent, 1); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_3 = (i0.ɵunv(_v, 8, 0, _ck(_v, 9, 0, i0.ɵnov(_v.parent, 0), _co.item.label)) && (_co.item.label != _co.item.name)); var currVal_4 = i0.ɵnov(_v, 6); _ck(_v, 8, 0, currVal_3, currVal_4); var currVal_5 = "Insert into query"; var currVal_6 = _ck(_v, 13, 0, "top"); _ck(_v, 12, 0, currVal_5, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = (_co.item.label ? _co.item.label : ""); _ck(_v, 4, 0, currVal_2); }); }
export function View_TokenStructureItemComponent_0(_l) { return i0.ɵvid(2, [i0.ɵpid(0, i4.IsSetPipe, []), (_l()(), i0.ɵand(0, [["structure", 2]], null, 0, null, View_TokenStructureItemComponent_1)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TokenStructureItemComponent_8)), i0.ɵdid(3, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TokenStructureItemComponent_14)), i0.ɵdid(5, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.item.children.length; _ck(_v, 3, 0, currVal_0); var currVal_1 = (!_co.item.children.length && _co.item.insert); _ck(_v, 5, 0, currVal_1); }, null); }
export function View_TokenStructureItemComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-token-structure-item", [], null, null, null, View_TokenStructureItemComponent_0, RenderType_TokenStructureItemComponent)), i0.ɵdid(1, 114688, null, 0, i2.TokenStructureItemComponent, [i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TokenStructureItemComponentNgFactory = i0.ɵccf("app-token-structure-item", i2.TokenStructureItemComponent, View_TokenStructureItemComponent_Host_0, { item: "item", itemLast: "itemLast", parentsLast: "parentsLast" }, { inserted: "inserted", valueChanged: "valueChanged" }, []);
export { TokenStructureItemComponentNgFactory as TokenStructureItemComponentNgFactory };

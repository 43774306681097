export const ITEM_OUTPUT = 'item';
export const ITEMS_OUTPUT = 'items';
export const SELECTED_ITEM_OUTPUT = 'selected_item';
export const SELECTED_OUTPUT = 'selected';
export const HOVER_OUTPUT = 'hover';
export const PRESSED_OUTPUT = 'pressed';
export const HAS_SELECTED_ITEM_OUTPUT = 'has_selected_item';
export const EMPTY_OUTPUT = 'empty';
export const NO_SELECTED_ITEM_OUTPUT = 'no_selected_item';
export const CHECKED_ITEMS_OUTPUT = 'checked_items';
export const COLUMN_FROM_OUTPUT = 'column_from';
export const COLUMN_TO_OUTPUT = 'column_to';
export const ORDER_OUTPUT = 'order';
export const BEFORE_ITEM_OUTPUT = 'before_item';
export const AFTER_ITEM_OUTPUT = 'after_item';
export const COLUMN_OUTPUT = 'column';
export const PREV_ORDER_OUTPUT = 'prev_order';
export const PREV_BEFORE_ITEM_OUTPUT = 'prev_before_item';
export const PREV_AFTER_ITEM_OUTPUT = 'prev_after_item';
export const PREV_COLUMN_OUTPUT = 'prev_column';

import { BehaviorSubject, Observable, of, ReplaySubject, Subject, Subscription } from 'rxjs';
import { catchError, first, map, switchMap } from 'rxjs/operators';

export abstract class SingletonStore<T> {
  private _instance = new BehaviorSubject<T>(undefined);
  private _loading = new BehaviorSubject<boolean>(false);
  private _error = new BehaviorSubject<any>(undefined);
  private requested: Subject<T>;
  private fetchSubscription: Subscription;

  protected abstract fetch(): Observable<T>;

  public get instance() {
    return this._instance.value;
  }

  public set instance(value) {
    this._instance.next(value);
  }

  public get instance$(): Observable<T> {
    return this._instance.asObservable();
  }

  public get loading() {
    return this._loading.value;
  }

  public get loading$() {
    return this._loading.asObservable();
  }

  public get error() {
    return this._error.value;
  }

  public get error$() {
    return this._error.asObservable();
  }

  public get(forceUpdate = false, options: Object = {}): Observable<T> {
    if (this.requested && forceUpdate) {
      // this.fetchSubscription.unsubscribe(); // TODO: remove redundant queries
      this.requested = undefined;
      this.fetchSubscription = undefined;
    }

    if (!this.requested && this.instance && !forceUpdate) {
      return this.instance$;
    }

    if (!this.requested) {
      const subject = new ReplaySubject<T>(1);

      this.requested = subject;
      this._loading.next(true);
      this._error.next(undefined);
      this.fetchSubscription = this.fetch().subscribe(
        result => {
          subject.next(result);
          this._loading.next(false);
          this._error.next(undefined);
        },
        error => {
          subject.error(error);
          this._loading.next(false);
          this._error.next(error);
        }
      );
    }

    const requested = this.requested;

    return requested.pipe(
      switchMap(result => {
        if (this.requested === requested) {
          this.requested = undefined;
          this.fetchSubscription = undefined;
          this.instance = result;
          return this.instance$;
        } else {
          return this.get(false, options);
        }
      })
      // catchError(e => {
      //   if (this.requested === requested) {
      //     this.requested = undefined;
      //     this.fetchSubscription = undefined;
      //     this.instance = undefined;
      //     return this.instance$;
      //   } else {
      //     return this.get();
      //   }
      // })
    );
  }

  public getFirst(forceUpdate = false, options: Object = {}): Observable<T> {
    return this.get(forceUpdate, options).pipe(first());
  }

  public updateIfLoaded(): Observable<void> {
    if (this.instance || this.requested) {
      return this.getFirst(true).pipe(map(() => undefined));
    } else {
      return of(undefined);
    }
  }

  public reset() {
    if (this.fetchSubscription) {
      this.fetchSubscription.unsubscribe();
    }

    this.requested = undefined;
    this.instance = undefined;
  }
}

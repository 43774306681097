var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ChangeDetectorRef, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, of } from 'rxjs';
import { distinctUntilChanged, switchMap } from 'rxjs/operators';
import { CollapseElementItem, CustomizeService, ElementType, registerElementComponent, ViewContextElement } from '@modules/customize';
import { BaseElementComponent } from '@modules/customize-elements';
import { applyParamInput$ } from '@modules/fields';
import { CustomPagePopupComponent } from '../custom-page-popup/custom-page-popup.component';
var CollapseComponent = /** @class */ (function (_super) {
    __extends(CollapseComponent, _super);
    function CollapseComponent(customizeService, viewContextElement, cd, popup) {
        var _this = _super.call(this) || this;
        _this.customizeService = customizeService;
        _this.viewContextElement = viewContextElement;
        _this.cd = cd;
        _this.popup = popup;
        _this.element$ = new BehaviorSubject(_this.element);
        _this.opened = false;
        return _this;
    }
    CollapseComponent.prototype.ngOnInit = function () {
        this.element$.next(this.element);
        this.initItemsOpened();
        this.initContext();
        this.updateContextActions();
    };
    CollapseComponent.prototype.ngOnDestroy = function () { };
    CollapseComponent.prototype.ngOnChanges = function (changes) {
        if (changes.element && !changes.element.firstChange) {
            this.element$.next(this.element);
            this.updateContextInfo();
        }
    };
    CollapseComponent.prototype.initItemsOpened = function () {
        var _this = this;
        this.element$
            .pipe(switchMap(function (element) {
            if (!element.openedInput || !element.openedInput.isSet()) {
                return of(false);
            }
            return applyParamInput$(element.openedInput, {
                context: _this.context
            }).pipe(distinctUntilChanged());
        }), untilDestroyed(this))
            .subscribe(function (opened) {
            if (opened && !_this.opened) {
                _this.setOpened(true);
            }
            else if (!opened && _this.opened) {
                _this.setOpened(false);
            }
        });
    };
    CollapseComponent.prototype.setOpened = function (value) {
        this.opened = value;
        this.cd.markForCheck();
    };
    CollapseComponent.prototype.toggleOpened = function () {
        this.setOpened(!this.opened);
    };
    CollapseComponent.prototype.initContext = function () {
        this.viewContextElement.initElement({
            uniqueName: this.element.uid,
            name: this.element.name,
            icon: 'align_vertical_fill',
            element: this.element,
            popup: this.popup ? this.popup.popup : undefined
        });
    };
    CollapseComponent.prototype.updateContextInfo = function () {
        this.viewContextElement.initInfo({
            name: this.element.name,
            element: this.element
        }, true);
    };
    CollapseComponent.prototype.updateContextActions = function () {
        var _this = this;
        this.viewContextElement.setActions([
            {
                uniqueName: 'show',
                name: 'Show content',
                icon: 'align_vertical_fill',
                parameters: [],
                handler: function () {
                    _this.setOpened(true);
                }
            },
            {
                uniqueName: 'hide',
                name: 'Hide content',
                icon: 'align_vertical_center',
                parameters: [],
                handler: function () {
                    _this.setOpened(false);
                }
            },
            {
                uniqueName: 'toggle',
                name: 'Toggle content visibility',
                icon: 'sort',
                parameters: [],
                handler: function () {
                    _this.toggleOpened();
                }
            }
        ]);
    };
    return CollapseComponent;
}(BaseElementComponent));
export { CollapseComponent };
registerElementComponent({
    type: ElementType.Collapse,
    component: CollapseComponent,
    label: 'Collapse',
    alwaysActive: false,
    actions: []
});

import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-pro-block',
  templateUrl: './pro-block.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProBlockComponent implements OnInit {
  @Input() fill = false;

  constructor() {}

  ngOnInit() {}
}

<button
  *ngIf="visible || (customizeEnabled$ | async)"
  type="button"
  class="button button_small alert__action"
  [class.button_primary]="!item.tint && item.style == tintStyles.Primary"
  [class.button_primary-transparent]="!item.tint && item.style == tintStyles.Transparent"
  [class.button_icon]="!(verboseName | appIsSet)"
  [class.button_disabled]="disabled"
  [appButtonTintColor]="item.tint || accentColor"
  [appButtonTintColorStyle]="item.style"
  [disabled]="disabled || submitLoading"
  (click)="executeAction(item, button_element)"
  #button_element
>
  <app-loader-small *ngIf="submitLoading" class="button__icon button__icon_left"></app-loader-small>
  <span *ngIf="item.icon && !submitLoading" class="button__icon button__icon_left" [appIcon]="item.icon"></span>

  {{ verboseName | appCapitalize }}

  <span
    *ngIf="!visible && (customizeEnabled$ | async)"
    class="element__indicator element__indicator_tiny icon-eye"
    [appTip]="'Component is hidden'"
  ></span>
</button>

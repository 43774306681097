import { DataGroup } from './data-group';

export enum DatasetGroupLookup {
  Auto = 'auto',
  Plain = 'plain',
  DateDay = 'date',
  DateWeek = 'date_week',
  DateMonth = 'date_month',
  DateQuarter = 'date_quarter',
  DateYear = 'date_year',
  DateHour = 'date_hour',
  DateMinute = 'date_minute',
  DateSecond = 'date_second'
}

export const datasetGroupDateLookups = [
  DatasetGroupLookup.DateDay,
  DatasetGroupLookup.DateWeek,
  DatasetGroupLookup.DateMonth,
  DatasetGroupLookup.DateQuarter,
  DatasetGroupLookup.DateYear,
  DatasetGroupLookup.DateHour,
  DatasetGroupLookup.DateMinute,
  DatasetGroupLookup.DateSecond
];

export const datasetGroupTimeLookups = [
  DatasetGroupLookup.DateHour,
  DatasetGroupLookup.DateMinute,
  DatasetGroupLookup.DateSecond
];

export interface Dataset<V = any, G1 = any, G2 = any, G3 = any> {
  name?: string;
  color?: string;
  format?: string;
  groupLookup?: DatasetGroupLookup;
  dataset: DataGroup<V, G1, G2, G3>[];
}

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ChangeDetectorRef, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import * as Color from 'color';
import toPairs from 'lodash/toPairs';
import { getColorHex, isLightColor } from '@modules/colors';
import { MenuBlock, MenuGeneratorService } from '@modules/menu';
import { isSet } from '@shared';
export var MenuSecondary;
(function (MenuSecondary) {
    function isLight(color) {
        if (!isSet(color)) {
            return;
        }
        return isLightColor(color);
    }
    MenuSecondary.isLight = isLight;
    function getLightColor(color) {
        try {
            var colorHex = getColorHex(color);
            var clr = Color(colorHex);
            return clr.lighten(0.2).string();
        }
        catch (e) { }
    }
    MenuSecondary.getLightColor = getLightColor;
    function getDarkColor(color) {
        try {
            var colorHex = getColorHex(color);
            var clr = Color(colorHex);
            return clr.darken(0.05).string();
        }
        catch (e) { }
    }
    MenuSecondary.getDarkColor = getDarkColor;
    function getDark2Color(color) {
        try {
            var colorHex = getColorHex(color);
            var clr = Color(colorHex);
            return clr.darken(0.45).desaturate(0.35).string();
        }
        catch (e) { }
    }
    MenuSecondary.getDark2Color = getDark2Color;
    function getDark3Color(color) {
        try {
            var colorHex = getColorHex(color);
            var clr = Color(colorHex);
            return clr.darken(0.7).desaturate(0.25).string();
        }
        catch (e) { }
    }
    MenuSecondary.getDark3Color = getDark3Color;
    function getVars(accentColor) {
        if (!isSet(accentColor)) {
            accentColor = '#f3f5f8';
        }
        var lightColor = getLightColor(accentColor);
        var darkColor = getDarkColor(accentColor);
        var dark2Color = getDark2Color(accentColor);
        var dark3Color = getDark3Color(accentColor);
        return __assign({}, (lightColor && { 'light-color': lightColor }), (darkColor && { 'dark-color': darkColor }), (dark2Color && { 'dark2-color': dark2Color }), (dark3Color && { 'dark3-color': dark3Color }));
    }
    MenuSecondary.getVars = getVars;
})(MenuSecondary || (MenuSecondary = {}));
var MenuSecondaryComponent = /** @class */ (function () {
    function MenuSecondaryComponent(menuGeneratorService, sanitizer, cd) {
        this.menuGeneratorService = menuGeneratorService;
        this.sanitizer = sanitizer;
        this.cd = cd;
        this.horizontal = false;
        this.startItems = [];
        this.centerItems = [];
        this.endItems = [];
    }
    MenuSecondaryComponent.prototype.trackMenuItemFn = function (i, item) {
        return item.id;
    };
    MenuSecondaryComponent.prototype.ngOnInit = function () { };
    MenuSecondaryComponent.prototype.ngOnDestroy = function () { };
    MenuSecondaryComponent.prototype.ngOnChanges = function (changes) {
        if (changes.block) {
            this.updateItems();
            this.menuIsLight = MenuSecondary.isLight(this.block.accentColor);
            this.menuStyle = this.getStyleVars(this.block.accentColor);
        }
    };
    MenuSecondaryComponent.prototype.updateItems = function () {
        this.startItems = this.menuGeneratorService.cleanMenuItemsAppMode(this.block.startItems);
        this.centerItems = this.menuGeneratorService.cleanMenuItemsAppMode(this.block.centerItems);
        this.endItems = this.menuGeneratorService.cleanMenuItemsAppMode(this.block.endItems);
        this.cd.markForCheck();
    };
    MenuSecondaryComponent.prototype.getStyleVars = function (accentColor) {
        var vars = MenuSecondary.getVars(accentColor);
        var styles = toPairs(vars)
            .map(function (_a) {
            var k = _a[0], v = _a[1];
            return "--" + k + ": " + v;
        })
            .join(';');
        return this.sanitizer.bypassSecurityTrustStyle(styles);
    };
    return MenuSecondaryComponent;
}());
export { MenuSecondaryComponent };

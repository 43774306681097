import { getElementByType, registerElementForType } from '../element-items';
import { ElementType } from '../element-type';
import { ElementItem } from './base';

export class CardLayoutElementItem extends ElementItem {
  public type = ElementType.Card;
  public children: ElementItem[] = [];
  public loadInvisible = false;

  deserialize(data: Object): CardLayoutElementItem {
    super.deserialize(data);

    if (this.params['children']) {
      this.children = this.params['children']
        .map(item => {
          const element = getElementByType(item['type']);
          if (!element) {
            console.error(`Unsupported element type: ${item['type']}`);
            return;
          }
          return new element().deserialize(item);
        })
        .filter(item => item != undefined);
    }

    if (this.params['load_invisible'] != undefined) {
      this.loadInvisible = this.params['load_invisible'];
    }

    return this;
  }

  serialize(): Object {
    const data = super.serialize();
    data['params']['children'] = this.children.map(item => item.serialize());
    data['params']['load_invisible'] = this.loadInvisible;
    return data;
  }

  get analyticsName(): string {
    return 'card';
  }

  defaultName() {
    return 'Card';
  }

  childrenCount() {
    return this.children.length;
  }
}

registerElementForType(ElementType.Card, CardLayoutElementItem);

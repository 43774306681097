import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { debounceTime } from 'rxjs/operators';

import { cleanElementName, CollapseElementItem, ViewContext, ViewContextElement } from '@modules/customize';
import { BooleanFieldStyle, Option } from '@modules/field-components';
import { createFormFieldFactory, FieldType } from '@modules/fields';
import { QueryBuilderContext } from '@modules/queries-components';

import { CustomizeBarEditComponent } from '../../data/customize-bar-edit-component';
import { CustomizeBarEditEvent } from '../../data/customize-bar-edit-event';
import { CustomizeBarEditEventType } from '../../data/customize-bar-edit-event-type';
import { CustomizeBarContext } from '../../services/customize-bar-context/customize-bar.context';
import { CustomizeBarCollapseEditForm } from './customize-bar-collapse-edit.form';

@Component({
  selector: 'app-customize-bar-collapse-edit',
  templateUrl: './customize-bar-collapse-edit.component.html',
  providers: [CustomizeBarCollapseEditForm, QueryBuilderContext],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomizeBarCollapseEditComponent implements OnInit, OnDestroy, CustomizeBarEditComponent {
  @Input() element: CollapseElementItem;
  @Input() context: ViewContext;
  @Input() contextElement: ViewContextElement;
  @Input() titleEnabled = true;
  @Input() titleEditable: boolean;
  @Input() deleteEnabled = false;
  @Input() parentElement: any;
  @Input() parentPopup: any;
  @Output() event = new EventEmitter<CustomizeBarEditEvent>();

  createField = createFormFieldFactory();
  loading = false;
  openedOptions: Option[] = [
    {
      value: false,
      name: 'Closed by default'
    },
    {
      value: true,
      name: 'Opened by default'
    }
  ];
  booleanFieldStyle = BooleanFieldStyle;
  fieldTypes = FieldType;

  titleCleanValue = (() => {
    return (value: string): string => {
      return cleanElementName(value, this.element, this.context.getElementItems());
    };
  })();

  constructor(
    public form: CustomizeBarCollapseEditForm,
    private customizeBarContext: CustomizeBarContext,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.form.init(this.element);

    this.form.valueChanges.pipe(debounceTime(200), untilDestroyed(this)).subscribe(() => {
      this.emitUpdate(this.form.submit());
    });
  }

  ngOnDestroy(): void {}

  onTitleChanged(title: string) {
    this.form.controls.name.setValue(title);
  }

  close() {
    this.customizeBarContext.popSettingsComponent();
  }

  submit() {
    const result = this.form.submit();

    this.emitUpdate(result, true);
    this.close();
  }

  emitUpdate(result: CollapseElementItem, submit = false) {
    if (this.element) {
      this.event.emit({ type: CustomizeBarEditEventType.Updated, args: { result: result, submit: submit } });
    } else {
      this.event.emit({ type: CustomizeBarEditEventType.Created, args: { result: result, submit: submit } });
    }
  }

  cancel() {
    this.event.emit({ type: CustomizeBarEditEventType.Canceled });
    this.close();
  }

  delete() {
    this.event.emit({ type: CustomizeBarEditEventType.Deleted });
    this.close();
  }
}

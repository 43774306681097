/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./timeline-stub-content.component";
var styles_TimelineStubContentComponent = [];
var RenderType_TimelineStubContentComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_TimelineStubContentComponent, data: {} });
export { RenderType_TimelineStubContentComponent as RenderType_TimelineStubContentComponent };
function View_TimelineStubContentComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "label", [["class", "model-card__item-label"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "span", [], [[2, "loading-animation", null]], null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "span", [["class", "stub-text"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Label"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.animating; _ck(_v, 1, 0, currVal_0); }); }
function View_TimelineStubContentComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 6, "div", [["class", "model-card__item"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TimelineStubContentComponent_3)), i0.ɵdid(3, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(4, 0, null, null, 3, "div", [["class", "model-card__item-value"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 2, "span", [], [[2, "loading-animation", null]], null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 1, "span", [["class", "stub-text"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Value"]))], function (_ck, _v) { var currVal_0 = true; _ck(_v, 3, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.animating; _ck(_v, 5, 0, currVal_1); }); }
function View_TimelineStubContentComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 10, "div", [["class", "timeline__item"]], [[2, "timeline__item_clickable", null], [2, "timeline__item_selected", null]], null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "div", [["class", "timeline__item-inner"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 2, "div", [["class", "model-card"]], [[2, "model-card_vertical", null], [2, "model-card_compact", null]], null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TimelineStubContentComponent_2)), i0.ɵdid(4, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵeld(5, 0, null, null, 4, "div", [["class", "timeline__item-title"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 3, "div", [["class", "model-card__item-value"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 2, "span", [], [[2, "loading-animation", null]], null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 1, "span", [["class", "stub-text"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["date"])), (_l()(), i0.ɵeld(10, 0, null, null, 0, "div", [["class", "timeline__item-dot"]], [[2, "timeline__item-dot_icon", null], [2, "icon-time", null]], null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_4 = _co.columnsRange; _ck(_v, 4, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = false; var currVal_1 = false; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = false; var currVal_3 = false; _ck(_v, 2, 0, currVal_2, currVal_3); var currVal_5 = _co.animating; _ck(_v, 7, 0, currVal_5); var currVal_6 = _v.context.first; var currVal_7 = _v.context.first; _ck(_v, 10, 0, currVal_6, currVal_7); }); }
function View_TimelineStubContentComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "label", [["class", "model-card__item-label"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "span", [], [[2, "loading-animation", null]], null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "span", [["class", "stub-text"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Label"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.animating; _ck(_v, 1, 0, currVal_0); }); }
function View_TimelineStubContentComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 6, "div", [["class", "model-card__item"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TimelineStubContentComponent_6)), i0.ɵdid(3, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(4, 0, null, null, 3, "div", [["class", "model-card__item-value"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 2, "span", [], [[2, "loading-animation", null]], null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 1, "span", [["class", "stub-text"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Value"]))], function (_ck, _v) { var currVal_0 = true; _ck(_v, 3, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.animating; _ck(_v, 5, 0, currVal_1); }); }
function View_TimelineStubContentComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 10, "div", [["class", "timeline__item"]], [[2, "timeline__item_clickable", null], [2, "timeline__item_selected", null]], null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "div", [["class", "timeline__item-inner"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 2, "div", [["class", "model-card"]], [[2, "model-card_vertical", null], [2, "model-card_compact", null]], null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TimelineStubContentComponent_5)), i0.ɵdid(4, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵeld(5, 0, null, null, 4, "div", [["class", "timeline__item-title"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 3, "div", [["class", "model-card__item-value"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 2, "span", [], [[2, "loading-animation", null]], null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 1, "span", [["class", "stub-text"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["date"])), (_l()(), i0.ɵeld(10, 0, null, null, 0, "div", [["class", "timeline__item-dot"]], [[2, "timeline__item-dot_icon", null], [2, "icon-time", null]], null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_4 = _co.columnsRange; _ck(_v, 4, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = false; var currVal_1 = false; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = false; var currVal_3 = false; _ck(_v, 2, 0, currVal_2, currVal_3); var currVal_5 = _co.animating; _ck(_v, 7, 0, currVal_5); var currVal_6 = false; var currVal_7 = false; _ck(_v, 10, 0, currVal_6, currVal_7); }); }
export function View_TimelineStubContentComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "div", [["class", "timeline"], ["style", "overflow: hidden;"]], [[4, "height", "px"]], null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "div", [["class", "timeline__line-container"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 0, "div", [["class", "timeline__line"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TimelineStubContentComponent_1)), i0.ɵdid(4, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TimelineStubContentComponent_4)), i0.ɵdid(6, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.rowsRange.slice(0, (0 - 1)); _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.rowsRange.slice((0 - 1)); _ck(_v, 6, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.height; _ck(_v, 0, 0, currVal_0); }); }
export function View_TimelineStubContentComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-timeline-stub-content", [], null, null, null, View_TimelineStubContentComponent_0, RenderType_TimelineStubContentComponent)), i0.ɵdid(1, 638976, null, 0, i2.TimelineStubContentComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TimelineStubContentComponentNgFactory = i0.ɵccf("app-timeline-stub-content", i2.TimelineStubContentComponent, View_TimelineStubContentComponent_Host_0, { header: "header", columns: "columns", rows: "rows", animating: "animating", scrollable: "scrollable", height: "height" }, {}, []);
export { TimelineStubContentComponentNgFactory as TimelineStubContentComponentNgFactory };

export const stripeResourceParamsActionDescriptions: Object[] = [
  {
    resource: '{{resource}}',
    name: 'cancel_subscription',
    params: {
      type: 'query',
      verbose_name: 'Cancel subscription',
      dynamic_status: false,
      action_params: [
        {
          name: 'id',
          verbose_name: 'id',
          field: 'text',
          required: false,
          default_type: 'value',
          params: {}
        },
        {
          name: 'invoice_now',
          verbose_name: 'invoice_now',
          field: 'text',
          required: false,
          default_type: 'value',
          params: {}
        },
        {
          name: 'prorate',
          verbose_name: 'prorate',
          field: 'text',
          required: false,
          default_type: 'value',
          params: {}
        }
      ],
      outputs: [],
      description:
        'Cancels a customer’s subscription immediately. The customer will not be charged again for the subscription.',
      protected: false,
      query_action: {
        query: {
          query_type: 'http',
          http_query: {
            method: 'DELETE',
            url: 'https://api.stripe.com/v1/subscriptions/{{params.id}}',
            auth_params: {},
            body_type: 'JSON',
            body: '',
            headers: [
              {
                name: 'Authorization',
                value: 'Bearer {-api_key-}'
              }
            ],
            query_params: [
              {
                name: 'invoice_now',
                value: '{{params.invoice_now}}'
              },
              {
                name: 'prorate',
                value: '{{params.prorate}}'
              }
            ],
            response_type: 'json',
            response_path: '',
            request_tokens: {}
          }
        }
      },
      virtual: false,
      featured: false
    },
    draft: false,
    deleted: false
  },
  {
    resource: '{{resource}}',
    name: 'capture_charge',
    params: {
      type: 'query',
      verbose_name: 'Capture charge',
      dynamic_status: false,
      action_params: [
        {
          name: 'id',
          verbose_name: 'id',
          field: 'text',
          required: false,
          default_type: 'value',
          params: {}
        },
        {
          name: 'receipt_email',
          verbose_name: 'receipt_email',
          field: 'text',
          required: false,
          default_type: 'value',
          params: {}
        },
        {
          name: 'statement_descriptor',
          verbose_name: 'statement_descriptor',
          field: 'text',
          required: false,
          default_type: 'value',
          params: {}
        },
        {
          name: 'statement_descriptor_suffix',
          verbose_name: 'statement_descriptor_suffix',
          field: 'text',
          required: false,
          default_type: 'value',
          params: {}
        },
        {
          name: 'amount',
          verbose_name: 'amount',
          description: '',
          field: 'text',
          required: false,
          default_type: 'value',
          params: {}
        }
      ],
      outputs: [],
      description: 'Capture the payment of an existing, uncaptured, charge.',
      protected: false,
      query_action: {
        query: {
          query_type: 'http',
          http_query: {
            method: 'POST',
            url: 'https://api.stripe.com/v1/charges/{{params.id}}/capture',
            auth_params: {},
            body_type: 'JSON',
            body: '',
            headers: [
              {
                name: 'Authorization',
                value: 'Bearer {-api_key-}'
              }
            ],
            query_params: [
              {
                name: 'amount',
                value: '{{params.amount}}'
              },
              {
                name: 'receipt_email',
                value: '{{params.receipt_email}}'
              },
              {
                name: 'statement_descriptor',
                value: '{{params.statement_descriptor}}'
              },
              {
                name: 'statement_descriptor_suffix',
                value: '{{params.statement_descriptor_suffix}}'
              }
            ],
            response_type: 'json',
            response_path: '',
            request_tokens: {}
          }
        }
      },
      virtual: false,
      featured: false
    },
    draft: false,
    deleted: false
  },
  {
    resource: '{{resource}}',
    name: 'refund',
    params: {
      type: 'query',
      verbose_name: 'Refund',
      dynamic_status: false,
      action_params: [
        {
          name: 'charge',
          verbose_name: 'charge',
          field: 'text',
          required: true,
          default_type: 'value',
          params: {}
        },
        {
          name: 'amount',
          verbose_name: 'amount',
          field: 'number',
          required: false,
          default_type: 'value',
          params: {
            type: 'integer',
            fraction: 2
          }
        }
      ],
      outputs: [],
      description: 'Creates a new refund.',
      protected: false,
      query_action: {
        query: {
          query_type: 'http',
          http_query: {
            method: 'POST',
            url: 'https://api.stripe.com/v1/refunds',
            auth_params: {},
            body_type: 'JSON',
            body: '',
            headers: [
              {
                name: 'Authorization',
                value: 'Bearer {-api_key-}'
              }
            ],
            query_params: [
              {
                name: 'charge',
                value: '{{params.charge}}'
              },
              {
                name: 'amount',
                value: '{{params.amount}}'
              }
            ],
            response_type: 'json',
            response_transformer: "// add custom transformation here\nreturn data['status']",
            response_path: '',
            request_tokens: {}
          }
        }
      },
      virtual: false,
      featured: false
    },
    draft: false,
    deleted: false
  }
];

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpErrorResponse } from '@angular/common/http';
import { of, throwError } from 'rxjs';
import { catchError, map, publishLast, refCount } from 'rxjs/operators';
import { AdminMode } from '@modules/admin-mode';
import { ServerRequestError } from '@modules/api';
import { SecretToken, SecretTokenService, SecretTokenType } from '@modules/projects';
import { HttpQuery, HttpQueryService } from '@modules/queries';
// TODO: Refactor import
import { HTTP_QUERY_BASIC_AUTH_VALUE } from '@modules/projects-components/data/http-query-auth';
import { zendeskResourceParamsMenuSettings } from '../../data/zendesk/zendesk-resource-params-menu-settings.stub';
import { zendeskResourceParamsModelDescriptions } from '../../data/zendesk/zendesk-resource-params-model-descriptions.stub';
import { zendeskResourceParamsParams } from '../../data/zendesk/zendesk-resource-params-params.stub';
import { zendeskResourceParamsViewSettings } from '../../data/zendesk/zendesk-resource-params-view-settings.stub';
import { ResourceGeneratorService } from '../resource-generator/resource-generator.service';
var ZendeskGeneratorService = /** @class */ (function (_super) {
    __extends(ZendeskGeneratorService, _super);
    function ZendeskGeneratorService(mode, secretTokenService, httpQueryService) {
        var _this = _super.call(this) || this;
        _this.mode = mode;
        _this.secretTokenService = secretTokenService;
        _this.httpQueryService = httpQueryService;
        return _this;
    }
    ZendeskGeneratorService.prototype.isOptionsValid = function (options) {
        var query = new HttpQuery();
        query.url = "https://" + options.account_name + ".zendesk.com/api/v2/search.json";
        query.headers = [{ name: 'Authorization', value: "Basic " + this.authTokenFromOptions(options) }];
        query.queryParams = [{ name: 'query', value: 'type:ticket' }];
        return this.httpQueryService.request(query).pipe(map(function () {
            return {};
        }), catchError(function (error) {
            if (error instanceof ServerRequestError && error.response instanceof HttpErrorResponse && error.status == 404) {
                error = new ServerRequestError('There is no help desk with such Account Name');
            }
            else if (error instanceof ServerRequestError &&
                error.response instanceof HttpErrorResponse &&
                error.status == 401) {
                error = new ServerRequestError('Account Email or API Token is not valid or not enough permissions');
            }
            return throwError(error);
        }), publishLast(), refCount());
    };
    ZendeskGeneratorService.prototype.getParamsOptions = function (project, environment, resource) {
        var _this = this;
        return this.secretTokenService
            .getDetail(project.uniqueName, environment.uniqueName, resource.uniqueName, HTTP_QUERY_BASIC_AUTH_VALUE, this.mode == AdminMode.Builder)
            .pipe(map(function (result) { return _this.authTokenToOptions(result.value, resource.params['account_name']); }));
    };
    ZendeskGeneratorService.prototype.authTokenFromOptions = function (options) {
        try {
            return btoa(options.account_email + "/token:" + options.key);
        }
        catch (e) { }
    };
    ZendeskGeneratorService.prototype.authTokenToOptions = function (token, accountName) {
        try {
            var _a = atob(token).split(':', 2), account = _a[0], key = _a[1];
            return {
                account_name: accountName,
                account_email: account.replace('/token', ''),
                key: key
            };
        }
        catch (e) {
            return {
                account_name: undefined,
                account_email: undefined,
                key: undefined
            };
        }
    };
    ZendeskGeneratorService.prototype.generateParams = function (project, environment, typeItem, options) {
        var resourceParams = zendeskResourceParamsParams;
        var modelDescriptions = zendeskResourceParamsModelDescriptions;
        var viewSettings = zendeskResourceParamsViewSettings;
        var menuSettings = zendeskResourceParamsMenuSettings;
        var token = new SecretToken();
        token.name = HTTP_QUERY_BASIC_AUTH_VALUE;
        token.type = SecretTokenType.Static;
        token.value = this.authTokenFromOptions(options);
        resourceParams['account_name'] = options.account_name;
        return of({
            resourceParams: resourceParams,
            modelDescriptions: modelDescriptions,
            viewSettings: viewSettings,
            menuSettings: menuSettings,
            secretTokens: [token.serialize()],
            extraTokens: {
                account_name: options.account_name
            }
        });
    };
    return ZendeskGeneratorService;
}(ResourceGeneratorService));
export { ZendeskGeneratorService };

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';

import { MenuBlockLayout } from '@modules/menu';

import { AddMenuItemOptions } from '../add-menu-item-menu/add-menu-item-menu.component';
import { CustomizeBarPagesEditSeparatorControl } from '../customize-bar-pages-edit/separator-control';

@Component({
  selector: 'app-separator-menu-item-edit',
  templateUrl: './separator-menu-item-edit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SeparatorMenuItemEditComponent implements OnInit {
  @Input() control: CustomizeBarPagesEditSeparatorControl;
  @Input() layout: MenuBlockLayout;
  @Input() createdOptions: AddMenuItemOptions;
  @Output() deleteRequested = new EventEmitter<void>();

  editPopoverOpened = false;

  constructor(private cd: ChangeDetectorRef) {}

  ngOnInit() {
    if (this.createdOptions && this.createdOptions.customize) {
      this.openEditPopover(true);
    }
  }

  openEditPopover(value) {
    this.editPopoverOpened = value;
    this.cd.markForCheck();
  }
}

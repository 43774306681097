var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ChangeDetectorRef, Injector, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import keys from 'lodash/keys';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, of, ReplaySubject } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { UserActivityService, UserActivityType } from '@modules/activities';
import { DataSourceType, ListModelDescriptionDataSource } from '@modules/data-sources';
import { createFormFieldFactory } from '@modules/fields';
import { applyParamsComputedLookups, applySegments } from '@modules/filter-utils';
import { MetaService } from '@modules/meta';
import { ModelDescriptionStore, ModelService } from '@modules/model-queries';
import { Model, PAGE_PARAM } from '@modules/models';
import { ModelListStore } from '@modules/models-list';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { MassEditFieldsForm } from './mass-edit-fields.form';
var MassEditComponent = /** @class */ (function () {
    function MassEditComponent(injector, activatedRoute, modelDescriptionStore, cd, modelListStore, currentProjectStore, currentEnvironmentStore, modelService, metaService, form, userActivityService) {
        this.injector = injector;
        this.activatedRoute = activatedRoute;
        this.modelDescriptionStore = modelDescriptionStore;
        this.cd = cd;
        this.modelListStore = modelListStore;
        this.currentProjectStore = currentProjectStore;
        this.currentEnvironmentStore = currentEnvironmentStore;
        this.modelService = modelService;
        this.metaService = metaService;
        this.form = form;
        this.userActivityService = userActivityService;
        this.loading = true;
        this.checkboxes = undefined;
        this.modelItems = [];
        this.items = [];
        this.count = undefined;
        this.saveStarted = false;
        this.countSaveProcessed = 0;
        this.countItemSave = 0;
        this.modelDescription = undefined;
        this.createField = createFormFieldFactory();
    }
    MassEditComponent.prototype.createModel = function () {
        return Injector.create({
            providers: [{ provide: Model, deps: [Injector] }],
            parent: this.injector
        }).get(Model);
    };
    MassEditComponent.prototype.ngOnInit = function () {
        var _this = this;
        combineLatest([this.activatedRoute.params, this.activatedRoute.queryParams])
            .pipe(tap(function (_a) {
            var params = _a[0], queryParams = _a[1];
            _this.loading = true;
            _this.cd.markForCheck();
        }), switchMap(function (_a) {
            var params = _a[0], queryParams = _a[1];
            return _this.modelDescriptionStore.getDetailFirst(params['model']).pipe(map(function (modelDescription) { return [
                [params, queryParams],
                modelDescription
            ]; }));
        }), switchMap(function (_a) {
            var _b = _a[0], params = _b[0], queryParams = _b[1], modelDescription = _a[1];
            _this.modelDescription = modelDescription;
            try {
                _this.ids = JSON.parse(queryParams['ids']);
            }
            catch (e) {
                _this.ids = [];
            }
            _this.inverseIds = queryParams['inverseIds'];
            var applyParams = {};
            if (_this.inverseIds) {
                applyParams["exclude__" + _this.modelDescription.primaryKeyField + "__in"] = _this.ids.join(',');
            }
            else {
                applyParams[_this.modelDescription.primaryKeyField + "__in"] = _this.ids.join(',');
            }
            _this.modelListStore.dataSource = new ListModelDescriptionDataSource({
                type: DataSourceType.Query,
                queryResource: _this.modelDescription.resource,
                query: _this.modelDescription.getQuery
            });
            _this.modelListStore.params = __assign({}, queryParams, applyParams);
            if (_this.modelListStore.params[PAGE_PARAM] != undefined) {
                delete _this.modelListStore.params[PAGE_PARAM];
            }
            if (_this.modelListStore.params['ids'] != undefined) {
                delete _this.modelListStore.params['ids'];
            }
            if (_this.modelListStore.params['inverseIds'] != undefined) {
                delete _this.modelListStore.params['inverseIds'];
            }
            var applyResult = applySegments(_this.modelListStore.params, {}, _this.modelDescription);
            applyResult.params = applyParamsComputedLookups(applyResult.params);
            _this.cd.markForCheck();
            return _this.modelService.get(_this.currentProjectStore.instance, _this.currentEnvironmentStore.instance, params['model'], applyResult.params, keys(applyResult.body).length ? applyResult.body : undefined);
        }), untilDestroyed(this))
            .subscribe(function (result) {
            _this.modelItems = result.results;
            _this.count = result.count;
            _this.checkboxes = _this.initCheckboxes(_this.modelDescription.dbFields);
            _this.checkboxes
                .filter(function (checkbox) { return checkbox.checked; })
                .forEach(function (checkbox) {
                _this.items.push(checkbox.field);
                _this.form.addControl(checkbox.field);
            });
            _this.metaService.set({
                title: ["Mass edit " + _this.count, _this.modelDescription.verboseNamePlural]
            });
            _this.loading = false;
            _this.cd.markForCheck();
        }, function (e) {
            _this.loading = false;
            _this.cd.markForCheck();
        });
    };
    MassEditComponent.prototype.ngOnDestroy = function () { };
    MassEditComponent.prototype.initCheckboxes = function (fields) {
        return fields
            .filter(function (field) { return field.editable; })
            .map(function (field) {
            return {
                name: field.verboseName,
                field: field,
                checked: false
            };
        });
    };
    Object.defineProperty(MassEditComponent.prototype, "context", {
        get: function () {
            return {
                modelDescription: this.modelDescription
            };
        },
        enumerable: true,
        configurable: true
    });
    MassEditComponent.prototype.updateForm = function (checkbox) {
        this.form.toggleControl(checkbox.field);
        this.updateItems();
        this.cd.markForCheck();
    };
    MassEditComponent.prototype.updateItems = function () {
        this.items = this.checkboxes
            .filter(function (checkbox) { return checkbox.checked; })
            .map(function (checkbox) {
            return checkbox.field;
        });
    };
    MassEditComponent.prototype.confirm = function () {
        var _this = this;
        if (this.form.form.invalid) {
            return;
        }
        this.saveStarted = true;
        this.cd.markForCheck();
        this.processPage()
            .pipe(untilDestroyed(this))
            .subscribe(function () {
            _this.userActivityService
                .currentProjectCreateModelInstance(_this.currentProjectStore.instance, _this.currentEnvironmentStore.instance, UserActivityType.ModelMassEdit, _this.modelDescription.modelId, '', undefined, {
                resource: _this.modelDescription.resource,
                model: _this.modelDescription.model,
                ids: _this.ids,
                inverseIds: _this.inverseIds
            })
                .subscribe(function () { });
        });
    };
    MassEditComponent.prototype.processPage = function () {
        var _this = this;
        this.modelListStore.reset();
        return this.modelListStore.getNext().pipe(switchMap(function (results) {
            var obs = results
                .map(function (item) { return item.initialPrimaryKey; })
                .map(function (pk) {
                return switchMap(function () {
                    var req = new ReplaySubject();
                    var model = _this.createModel().deserialize(_this.modelDescription.model, {});
                    model.modelDescription = _this.modelDescription;
                    _this.items.map(function (item) {
                        model.setAttribute(item.name, _this.form.form.value[item.name]);
                    });
                    model.setPrimaryKey(pk);
                    _this.modelService
                        .update(_this.currentProjectStore.instance, _this.currentEnvironmentStore.instance, _this.modelDescription.modelId, model, _this.items.map(function (item) { return item.name; }))
                        .subscribe(function () {
                        _this.countSaveProcessed += 1;
                        _this.countItemSave += 1;
                        _this.cd.markForCheck();
                        req.next(true);
                    }, function () {
                        _this.countSaveProcessed += 1;
                        _this.cd.markForCheck();
                        req.next(false);
                    });
                    return req;
                });
            });
            var result = of({});
            obs.forEach(function (item) { return (result = result.pipe(item)); });
            return result;
        }), filter(function () { return _this.modelListStore.hasMore; }), switchMap(function () { return _this.processPage(); }));
    };
    Object.defineProperty(MassEditComponent.prototype, "listParams", {
        get: function () {
            var params = __assign({}, this.activatedRoute.snapshot.queryParams);
            if (params[PAGE_PARAM] != undefined) {
                delete params[PAGE_PARAM];
            }
            if (params['ids'] != undefined) {
                delete params['ids'];
            }
            if (params['inverseIds'] != undefined) {
                delete params['inverseIds'];
            }
            return params;
        },
        enumerable: true,
        configurable: true
    });
    return MassEditComponent;
}());
export { MassEditComponent };

import * as moment from 'moment';

import { localize } from '@common/localize';
import { isSet } from '@shared';

import { BetweenFieldLookup } from './between';

export class IsCurrentQuarterFieldLookup extends BetweenFieldLookup {
  public lookup = 'is_current_quarter';
  public param = 'isCurrentQuarter';
  public verboseName = localize('is Current Quarter');
  public icon = 'calendar';

  from(value: string): string {
    return moment().startOf('quarter').toISOString();
  }

  to(value: string): string {
    return moment().endOf('quarter').toISOString();
  }

  str(field?: string, value?: string, exclude?: boolean): string {
    if (exclude) {
      if (isSet(field) && isSet(value)) {
        return localize('{0} is not current quarter', [field]);
      } else {
        return localize('is not current quarter');
      }
    } else {
      if (isSet(field) && isSet(value)) {
        return localize('{0} is current quarter', [field]);
      } else {
        return localize('current quarter');
      }
    }
  }
}

<div class="select-icon">
  <div class="select-icon__header">
    <div class="select-icon__tabs">
      <div class="tabs2">
        <div class="tabs2-navigation tabs2-navigation_center">
          <a
            *ngFor="let item of typeOptions"
            href="javascript:void(0)"
            class="tabs2-navigation__item"
            [class.tabs2-navigation__item_active]="item.type == selectedType"
            (click)="setSelectedType(item.type)"
          >
            {{ item.label }}
          </a>
        </div>
      </div>
    </div>

    <div class="select-icon__search">
      <div class="input-icon-wrapper">
        <a
          *ngIf="search"
          href="javascript:void(0)"
          class="input-icon input-icon_small input-icon_interactive input-icon_right icon-close"
          (click)="clearSearch()"
        ></a>

        <input
          class="input input_fill input_small input_icon-right"
          type="text"
          placeholder="Search..."
          [(ngModel)]="search"
          [appAutofocus]="true"
          (keydown)="$event.stopPropagation(); onSearch()"
          (keyup)="onSearchKey($event)"
          #search_input
        />
      </div>
    </div>
  </div>

  <div class="select-icon__viewport" xsScrollable #scrollable_element #scrollable="scrollableDirective">
    <div *ngIf="emptyEnabled && (current | appIsSet) && !(search | appIsSet)" class="select-icon__item">
      <a
        href="javascript:void(0)"
        class="select-icon-item icon-selector-item_secondary"
        [appTip]="'Reset icon'"
        (click)="reset()"
      >
        <span class="select-icon-item__icon icon-close"></span>
      </a>
    </div>

    <app-icon-selector-icons
      *ngIf="selectedType == types.Icon"
      [current]="current"
      [search]="search"
      [loading]="loading"
      (selected)="save($event)"
    ></app-icon-selector-icons>

    <app-icon-selector-emojis
      *ngIf="selectedType == types.Emoji"
      [current]="current"
      [search]="search"
      [loading]="loading"
      (selected)="save($event)"
    ></app-icon-selector-emojis>

    <app-icon-selector-images
      *ngIf="selectedType == types.Image"
      [current]="current"
      [search]="search"
      [loading]="loading"
      (selected)="save($event)"
    ></app-icon-selector-images>
  </div>
  <gxs-scrollbar [theme]="'jet-mini'" [scrollable]="scrollable"></gxs-scrollbar>
</div>

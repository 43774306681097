import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map, publishLast, refCount, switchMap } from 'rxjs/operators';
import { ApiService } from '@modules/api';
import { ProjectGroup } from '../../data/project-user';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../../api/services/api/api.service";
var ProjectGroupService = /** @class */ (function () {
    function ProjectGroupService(http, apiService) {
        this.http = http;
        this.apiService = apiService;
    }
    ProjectGroupService.prototype.get = function (projectName, environmentName, params) {
        var _this = this;
        if (params === void 0) { params = {}; }
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.environmentMethodURL(projectName, environmentName, 'groups/');
            var headers = new HttpHeaders();
            var httpParams = new HttpParams({ fromObject: params });
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.get(url, { headers: headers, params: httpParams });
        }), map(function (result) { return result.map(function (item) { return new ProjectGroup().deserialize(item); }); }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    ProjectGroupService.prototype.getDetail = function (projectName, environmentName, id) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.environmentMethodURL(projectName, environmentName, "groups/" + id + "/");
            var headers = new HttpHeaders();
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.get(url, { headers: headers });
        }), map(function (result) { return new ProjectGroup().deserialize(result); }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    ProjectGroupService.prototype.create = function (projectName, environmentName, group) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.environmentMethodURL(projectName, environmentName, 'groups/');
            var headers = new HttpHeaders();
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.post(url, group.serialize(), { headers: headers });
        }), map(function (result) { return new ProjectGroup().deserialize(result); }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    ProjectGroupService.prototype.update = function (projectName, environmentName, group, fields) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.environmentMethodURL(projectName, environmentName, "groups/" + group.uid + "/");
            var headers = new HttpHeaders();
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.patch(url, group.serialize(fields), { headers: headers });
        }), map(function (result) { return new ProjectGroup().deserialize(result); }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    ProjectGroupService.prototype.delete = function (projectName, environmentName, group) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.environmentMethodURL(projectName, environmentName, "groups/" + group.uid + "/");
            var headers = new HttpHeaders();
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.delete(url, { headers: headers });
        }), map(function (result) { return true; }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    ProjectGroupService.ngInjectableDef = i0.defineInjectable({ factory: function ProjectGroupService_Factory() { return new ProjectGroupService(i0.inject(i1.HttpClient), i0.inject(i2.ApiService)); }, token: ProjectGroupService, providedIn: "root" });
    return ProjectGroupService;
}());
export { ProjectGroupService };

<div class="popover2 popover2_header">
  <div class="popover2__inner" cdkOverlayOrigin #token_popover_origin="cdkOverlayOrigin">
    <div class="token-popup__header">
      <div class="token-popup-tabs">
        <div
          class="token-popup-tabs__item icon-search"
          [class.token-popup-tabs__item_active]="!(selectedSection$ | async)"
          [appTip]="'All'"
          [appTipOptions]="{ side: 'top' }"
          (click)="setSelectedSectionName(undefined)"
        ></div>
        <div
          *ngFor="let item of displayTabSections$ | async; trackBy: trackSection"
          class="token-popup-tabs__item"
          [class.token-popup-tabs__item_orange]="item?.orange"
          [class.token-popup-tabs__item_active]="item?.label === (selectedSection$ | async)?.label"
          [ngClass]="['icon-' + item.icon]"
          [appTip]="item.label"
          [appTipOptions]="{ side: 'top' }"
          (click)="setSelectedSectionName(item.label)"
        ></div>
      </div>
    </div>

    <div
      class="popover2__viewport popover2__viewport_scrollable"
      [style.max-height.px]="maxHeight || null"
      #scrollable_element
    >
      <div class="popover2__content" [style.min-width.px]="300">
        <ng-container *ngIf="!loading">
          <ng-container *ngIf="openedSection">
            <div class="token-popup__item">
              <a
                href="javascript:void(0)"
                class="token-popup-list-item token-popup-list-item_orange"
                (click)="popOpenedSection()"
              >
                <div class="token-popup-list-item__inner token-popup-list-item__line">
                  <span class="token-popup-list-item__left">
                    <span class="icon-arrow_backward_2"></span>
                  </span>
                  <span class="token-popup-list-item__main">
                    <ng-container *ngIf="openedSectionParent">Back to {{ openedSectionParent.label }}</ng-container>
                    <ng-container *ngIf="!openedSectionParent">Back</ng-container>
                  </span>
                </div>
              </a>
            </div>

            <app-view-context-token-popover-section
              [section]="openedSection"
              [activeToken]="activeToken$ | async"
              [descriptionAlwaysVisible]="!!(selectedSection$ | async) || (searchControl.value | appIsSet)"
              (hover)="onItemHover($event)"
              (out)="onItemOut()"
              (opened)="addOpenedSection($event)"
              (selected)="onItemSelected($event)"
              (close)="close.emit()"
            ></app-view-context-token-popover-section>
          </ng-container>

          <ng-container *ngIf="!openedSection">
            <div class="token-popup-search">
              <input
                type="text"
                class="token-popup-search__input"
                [formControl]="searchControl"
                placeholder="Search..."
              />
              <span
                *ngIf="searchControl.value | appIsSet"
                class="token-popup-search__close icon-close"
                (click)="clearSearch()"
                [appTip]="'Clear'"
                [appTipOptions]="{ side: 'top' }"
              ></span>
            </div>

            <app-view-context-token-popover-section
              *ngIf="selectedSection$ | async"
              [section]="selectedSection$ | async"
              [activeToken]="activeToken$ | async"
              [title]="true"
              [showSubtitle]="true"
              (hover)="onItemHover($event)"
              (out)="onItemOut()"
              (opened)="addOpenedSection($event)"
              (selected)="onItemSelected($event)"
              (close)="close.emit()"
            ></app-view-context-token-popover-section>

            <ng-container *ngIf="!(selectedSection$ | async)">
              <ng-container *ngFor="let section of displaySections$ | async; let last = last; trackBy: trackSection">
                <app-view-context-token-popover-section
                  [section]="section"
                  [activeToken]="activeToken$ | async"
                  [title]="true"
                  [descriptionAlwaysVisible]="!!(selectedSection$ | async) || (searchControl.value | appIsSet)"
                  [limitItems]="(searchControl.value | appIsSet) ? 20 : 6"
                  (hover)="onItemHover($event)"
                  (out)="onItemOut()"
                  (opened)="addOpenedSection($event)"
                  (selected)="onItemSelected($event)"
                  (showMoreClick)="setSelectedSectionName(section.label)"
                  (close)="close.emit()"
                ></app-view-context-token-popover-section>
                <div *ngIf="!last" class="token-popup-border"></div>
              </ng-container>

              <div
                *ngIf="(searchControl.value | appIsSet) && !(displaySections$ | async).length"
                class="token-popup__item"
              >
                <div class="token-popup-section">
                  <div class="token-popup-section__content">
                    <div class="token-popup-list-item token-popup-section__content-item">
                      <div class="token-popup-list-item__inner">
                        <div class="token-popup-list-item__tip">Nothing found</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
          </ng-container>
        </ng-container>

        <app-view-context-token-popover-stub *ngIf="loading"></app-view-context-token-popover-stub>
      </div>
    </div>

    <div *ngIf="!loading" class="token-popup__footer">
      <div class="token-popup-hotkeys">
        <div class="token-popup-hotkeys__item">
          <div class="token-popup-hotkeys__item-icon">↑ ↓</div>
          <div class="token-popup-hotkeys__item-label">Navigate</div>
        </div>

        <div class="token-popup-hotkeys__item">
          <div class="token-popup-hotkeys__item-icon">Tab</div>
          <div class="token-popup-hotkeys__item-label">Select</div>
        </div>

        <div class="token-popup-hotkeys__item">
          <div class="token-popup-hotkeys__item-icon">Shift + Tab</div>
          <div class="token-popup-hotkeys__item-label">Back</div>
        </div>
      </div>
    </div>

    <div class="popover2__arrow"></div>
  </div>
</div>

<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayPanelClass]="['overlay']"
  [cdkConnectedOverlayPositions]="[
    { originX: 'start', overlayX: 'end', originY: 'top', overlayY: 'top', offsetX: -4, offsetY: 0 },
    { originX: 'end', overlayX: 'start', originY: 'top', overlayY: 'top', offsetX: 4, offsetY: 0 }
  ]"
  [cdkConnectedOverlayOrigin]="token_popover_origin"
  [cdkConnectedOverlayOpen]="!!showFunctionReference"
  [cdkConnectedOverlayPush]="true"
  [cdkConnectedOverlayGrowAfterOpen]="true"
  [cdkConnectedOverlayHasBackdrop]="false"
  #reference_popover_overlay
>
  <div (cdkObserveContent)="onReferencePopoverContentChanged()" (mousedown)="markReferencePopoverMouseEvent($event)">
    <div class="token-popup-reference">
      <div class="token-popup__item">
        <div class="token-popup-section">
          <div class="token-popup-section__content">
            <app-view-context-token-popover-function
              [function]="showFunctionReference"
            ></app-view-context-token-popover-function>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

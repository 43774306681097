import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-sidebar-section',
  templateUrl: './sidebar-section.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SidebarSectionComponent implements OnInit {
  @Input() title: string;
  @Input() documentation: string;
  @Input() documentationLabel = 'See how it works';
  @Input() documentationLeft = true;
  @Input() documentationSource: string;
  @Input() description: string;
  @Input() border = true;
  @Input() interactive = false;
  @Input() disabled = false;

  constructor() {}

  ngOnInit() {}
}

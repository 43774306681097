<div class="list-footer">
  <div class="list-footer__main">
    <div *ngIf="pagination" class="list__pagination">
      <app-pagination
        [current]="currentPage"
        [hasMore]="hasMorePages"
        [lastLoaded]="lastLoadedPage"
        [total]="totalPages"
        (pageSelected)="pageSelected.emit($event)"
      >
      </app-pagination>
    </div>
  </div>
  <div class="list-footer__right">
    <div *ngIf="count | appIsSet" class="list__status list-footer__item">
      <ng-container *ngIf="count >= 10000">≈</ng-container>
      <ng-container *ngIf="title">{{ count }} {{ title }}</ng-container>
      <ng-container *ngIf="!title">{{ '{0} items' | localize: [count] }}</ng-container>
    </div>

    <app-refresh-data
      *ngIf="reloadEnabled"
      class="list__refresh list-footer__item"
      [viewId]="viewId"
      (updateData)="reload.emit()"
    ></app-refresh-data>
  </div>
</div>

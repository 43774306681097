<div class="action-user-activity">
  <div class="action-user-activity__inner">
    <div class="action-user-activity__left">
      <div
        [style.background-image]="item.user?.photo ? 'url(' + item.user.photo + ')' : ''"
        class="action-user-activity__avatar"
      ></div>
    </div>
    <div class="action-user-activity__right">
      <span class="action-user-activity__author">{{ item.user.strFull }}</span>
      <p class="action-user-activity__description">
        <span
          ><span class="action-user-activity__description-icon" [ngClass]="'icon-' + item.icon"></span
          >{{ item.label | titlecase }} </span
        ><span [item]="item" [relations]="relations" app-user-activities-description> </span>
      </p>
      <span class="action-user-activity__date">{{ item.dateAdd | appTimeAgo: true }}</span>
    </div>
  </div>
</div>

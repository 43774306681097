<div class="dropdown__inner">
  <a
    class="profile-link"
    href="javascript:void(0)"
    [appDemoOnly]="{ link: ['/profile/update'] }"
    [ngClass]="[theme ? 'profile-link_theme-' + theme : '', position ? 'profile-link_position-' + position : '']"
    (click)="onItemClicked('profile')"
  >
    <span
      class="profile-link__photo"
      [style.background-image]="
        (currentUserStore.instance$ | async)?.photo ? 'url(' + (currentUserStore.instance$ | async)?.photo + ')' : ''
      "
    >
      <span class="profile-link__photo-icon icon-gear_2"></span>
    </span>
    <span class="profile-link__name">
      {{ (currentUserStore.instance$ | async)?.strShort }}
    </span>
    <span *ngIf="(currentUserStore.instance$ | async)?.email" class="profile-link__description">
      {{ (currentUserStore.instance$ | async)?.email }}
    </span>
  </a>

  <div class="dropdown__section">
    <ng-container *ngIf="currentEnvironmentStore?.instance$ | async as environment">
      <a
        *ngIf="
          currentProject?.hasEnvironmentAccessPermission(environment) &&
          ((customizeService.enabled$ | async) || currentProject?.features.isUsersEnabled())
        "
        [appLink]="currentProject.settingsUsersLink"
        class="dropdown__item"
        [appLinkActive]="['dropdown__item_selected']"
        (click)="onItemClicked('users')"
      >
        <span class="icon-users_teams dropdown__item-icon"></span>
        <div class="dropdown__item-label">
          {{ 'Users & Teams' | localize }}
        </div>
      </a>

      <!--      <a-->
      <!--        *ngIf="-->
      <!--          currentProject?.hasEnvironmentAccessPermission(environment) &&-->
      <!--          ((customizeService.enabled$ | async) || currentProject?.features.isGroupsEnabled())-->
      <!--        "-->
      <!--        [appLink]="currentProject.settingsGroupsLink"-->
      <!--        class="dropdown__item"-->
      <!--        [appLinkActive]="['dropdown__item_selected']"-->
      <!--        (click)="onItemClicked('groups')"-->
      <!--      >-->
      <!--        <span class="icon-users_teams dropdown__item-icon"></span>-->
      <!--        <div class="dropdown__item-label">-->
      <!--          Teams-->
      <!--        </div>-->
      <!--      </a>-->
    </ng-container>

    <ng-container *ngIf="customizeService.enabled$ | async">
      <a
        *ngIf="currentProject?.hasProjectBillingPermission() && (customizeService.enabled$ | async)"
        [appLink]="currentProject.settingsBillingLink"
        class="dropdown__item"
        [appLinkActive]="['dropdown__item_selected']"
        (click)="onItemClicked('billing')"
      >
        <span class="icon-payments dropdown__item-icon"></span>
        <div class="dropdown__item-label">
          <ng-container *ngIf="currentProject?.subscription.onPremise">
            {{ 'Billing' | localize }}
          </ng-container>
          <ng-container *ngIf="!currentProject?.subscription.onPremise">
            {{ 'Billing & Plans' | localize }}
          </ng-container>
        </div>
      </a>

      <a
        *ngIf="currentProject?.hasProjectSettingsPermission() && (customizeService.enabled$ | async)"
        [appLink]="currentProject.settingsLink"
        class="dropdown__item"
        [appLinkActive]="['dropdown__item_selected']"
        (click)="onItemClicked('api')"
      >
        <span class="icon-console dropdown__item-icon"></span>
        <div class="dropdown__item-label">{{ 'API keys' | localize }}</div>
      </a>

      <a
        *ngIf="
          currentProject?.hasProjectSettingsPermission() &&
          (customizeService.enabled$ | async) &&
          appConfigService.deploymentTypeEnabled
        "
        [appLink]="currentProject.onPremiseLink"
        class="dropdown__item"
        [appLinkActive]="['dropdown__item_selected']"
        (click)="onItemClicked('on-premise')"
      >
        <span class="icon-server dropdown__item-icon"></span>
        <div class="dropdown__item-label">On-premise</div>
      </a>

      <a
        *ngIf="currentProject?.hasProjectSettingsPermission() && (customizeService.enabled$ | async)"
        [appLink]="currentProject.settingsUserActivitiesLink"
        class="dropdown__item"
        [appLinkActive]="['dropdown__item_selected']"
        (click)="onItemClicked('activity_log')"
      >
        <span class="icon-book dropdown__item-icon"></span>
        <div class="dropdown__item-label">{{ 'Activity Log' | localize }}</div>
      </a>

      <a
        *ngIf="currentProject && (currentUserStore.instance$ | async)?.isStaff"
        [appLink]="currentProject.templatesLink"
        class="dropdown__item staff-block"
        [appLinkActive]="['dropdown__item_selected']"
        (click)="onItemClicked('manage_templates')"
      >
        <span class="icon-magic_wand dropdown__item-icon"></span>
        <div class="dropdown__item-label">{{ 'Manage Templates' | localize }}</div>
      </a>
    </ng-container>
  </div>

  <div class="dropdown__section">
    <a
      *ngIf="themeChange && themeService"
      href="javascript:void(0)"
      class="dropdown__item"
      [appTutorialTip]="{
        tip: 'Toggle theme',
        task: 'switch_to_dark_theme',
        page: 0,
        pageItem: 'toggle_theme'
      }"
      (click)="toggleTheme(); onItemClicked('toggle_theme', { Dark: !themeService.isDefaultTheme })"
    >
      <span class="icon-toggle_theme dropdown__item-icon"></span>
      <div class="dropdown__item-label">
        <ng-container *ngIf="themeService.isDefaultTheme$ | async">{{ 'Dark Mode' | localize }}</ng-container>
        <ng-container *ngIf="!(themeService.isDefaultTheme$ | async)">{{ 'Light Mode' | localize }}</ng-container>
      </div>
    </a>

    <a
      *ngIf="(currentUserStore.instance$ | async)?.isStaff && themeService"
      href="javascript:void(0)"
      class="dropdown__item"
      (click)="toggleStaffEnabled(); onItemClicked('toggle_staff', { Enable: themeService.staffEnabled })"
    >
      <span class="icon-power dropdown__item-icon"></span>
      <div class="dropdown__item-label">
        <ng-container *ngIf="themeService.staffEnabled$ | async">{{ 'Disable Staff' | localize }}</ng-container>
        <ng-container *ngIf="!(themeService.staffEnabled$ | async)">{{ 'Enable Staff' | localize }}</ng-container>
      </div>
    </a>

    <a
      href="javascript:void(0)"
      class="dropdown__item"
      [appClickEvent]="analyticsEvents.Logout.UserLoggedOut"
      (click)="logout(); onItemClicked('logout')"
    >
      <span class="icon-log_out dropdown__item-icon"></span>
      <div class="dropdown__item-label">{{ 'Logout' | localize }}</div>
    </a>
  </div>
</div>

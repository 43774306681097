import pickBy from 'lodash/pickBy';
import { User } from '@modules/users';
import { actionType, messageType, userActivityTypeDescriptions, userActivityTypeIcons } from './user-activity-type';
import { userActivityTypeLabels } from './user-activity-type';
var UserActivity = /** @class */ (function () {
    function UserActivity() {
        this.params = {};
    }
    UserActivity.prototype.deserialize = function (data) {
        this.activityType = data['activity_type'];
        this.objectType = data['object_type'];
        this.objectId = data['object_id'];
        this.viewSettings = data['view_settings'];
        this.dateAdd = data['date_add'];
        this.params = data['params'];
        if (data['user']) {
            this.user = new User().deserialize(data['user']);
        }
        return this;
    };
    UserActivity.prototype.serialize = function (fields) {
        var data = {
            activity_type: this.activityType,
            object_type: this.objectType,
            object_id: this.objectId,
            view_settings: this.viewSettings,
            params: this.params
        };
        if (fields) {
            data = pickBy(data, function (v, k) { return fields.includes(k); });
        }
        return data;
    };
    Object.defineProperty(UserActivity.prototype, "label", {
        get: function () {
            return userActivityTypeLabels[this.activityType] || this.activityType;
        },
        enumerable: true,
        configurable: true
    });
    UserActivity.prototype.description = function (ctx) {
        if (userActivityTypeDescriptions[this.activityType]) {
            return userActivityTypeDescriptions[this.activityType](ctx);
        }
        else {
            return this.activityType;
        }
    };
    Object.defineProperty(UserActivity.prototype, "icon", {
        get: function () {
            return userActivityTypeIcons[this.activityType];
        },
        enumerable: true,
        configurable: true
    });
    UserActivity.prototype.isActionActivityType = function () {
        var _this = this;
        return actionType.slice().some(function (item) { return _this.activityType === item; });
    };
    UserActivity.prototype.isMessageActivityType = function () {
        var _this = this;
        return messageType.slice().some(function (item) { return _this.activityType === item; });
    };
    return UserActivity;
}());
export { UserActivity };

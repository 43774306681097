var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ChangeDetectorRef, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, of } from 'rxjs';
import { catchError, debounceTime, map, switchMap } from 'rxjs/operators';
import { BasePopupComponent } from '@common/popups';
import { IntercomService, UniversalAnalyticsService } from '@modules/analytics';
import { ProjectTokenService, ResourceName } from '@modules/projects';
import { FirebaseDatabaseState, FirebaseDatabaseType, FirebaseResourceController } from '@modules/resources';
import { controlValue, isSet, readFileText } from '@shared';
import { registerResourceSettingsComponent } from '../../../data/resource-settings-components';
import { ChooseSyncController } from '../../../services/choose-sync/choose-sync.controller';
import { BaseResourceSettingsComponent } from '../base-resource-settings/base-resource-settings.component';
import { FirebaseResourceSettingsForm } from './firebase-resource-settings.form';
var FirebaseResourceSettingsComponent = /** @class */ (function (_super) {
    __extends(FirebaseResourceSettingsComponent, _super);
    function FirebaseResourceSettingsComponent(chooseSyncController, firebaseResourceController, form, popupComponent, projectTokenService, intercomService, analyticsService, cd) {
        var _this = _super.call(this, form, popupComponent, projectTokenService, intercomService, analyticsService, cd) || this;
        _this.chooseSyncController = chooseSyncController;
        _this.firebaseResourceController = firebaseResourceController;
        _this.form = form;
        _this.databaseOptions = [];
        _this.databaseOptionsLoading = false;
        return _this;
    }
    FirebaseResourceSettingsComponent.prototype.ngOnInit = function () {
        var _this = this;
        _super.prototype.ngOnInit.call(this);
        combineLatest(controlValue(this.form.form.controls['project_id']), controlValue(this.form.form.controls['access_token']))
            .pipe(debounceTime(200), switchMap(function (_a) {
            var projectId = _a[0], accessToken = _a[1];
            _this.databaseOptionsLoading = true;
            _this.databaseOptionsError = undefined;
            _this.cd.markForCheck();
            if (!isSet(projectId) || !isSet(accessToken)) {
                return of({
                    formValid: false
                });
            }
            return combineLatest(_this.firebaseResourceController
                .getFirestoreCollectionIds(projectId, accessToken)
                .pipe(catchError(function () { return of(undefined); })), _this.firebaseResourceController
                .getRealtimeDatabases(projectId, accessToken)
                .pipe(catchError(function () { return of(undefined); }))).pipe(map(function (_a) {
                var firestoreResponse = _a[0], realtimeDatabaseResponse = _a[1];
                return {
                    formValid: true,
                    firestoreResponse: firestoreResponse,
                    realtimeDatabaseResponse: realtimeDatabaseResponse
                };
            }));
        }))
            .subscribe(function (result) {
            var options = [];
            if (result.firestoreResponse) {
                options.push({ value: { type: FirebaseDatabaseType.Firestore }, name: 'Firestore' });
            }
            if (result.realtimeDatabaseResponse && result.realtimeDatabaseResponse.instances) {
                options.push.apply(options, result.realtimeDatabaseResponse.instances
                    .filter(function (item) { return item.state == FirebaseDatabaseState.Active; })
                    .map(function (item) {
                    var databaseName = item.name.split('/').slice(-1)[0];
                    return {
                        value: { type: FirebaseDatabaseType.Realtime, id: item.databaseUrl },
                        name: "Realtime - " + databaseName
                    };
                }));
            }
            _this.databaseOptions = options;
            _this.databaseOptionsLoading = false;
            if (!result.formValid) {
                _this.databaseOptionsError = undefined;
            }
            else if (!result.firestoreResponse && !result.realtimeDatabaseResponse) {
                _this.databaseOptionsError =
                    'Error loading databases, please check your Service Token and whether Firestore or Realtime database is enabled in your Firebase project';
            }
            else {
                _this.databaseOptionsError = undefined;
            }
            _this.cd.markForCheck();
        });
        this.form.form.valueChanges.pipe(untilDestroyed(this)).subscribe(function () { return _this.cd.markForCheck(); });
    };
    FirebaseResourceSettingsComponent.prototype.onFileChange = function (field, el) {
        var _this = this;
        if (!el.files.length) {
            return;
        }
        var file = el.files[0];
        el.value = null;
        readFileText(file)
            .pipe(untilDestroyed(this))
            .subscribe(function (content) {
            var _a;
            _this.form.form.patchValue((_a = {}, _a[field] = content, _a));
            _this.form.form.markAsDirty();
        });
    };
    FirebaseResourceSettingsComponent.prototype.chooseSync = function () {
        var _this = this;
        var databaseOption = this.form.form.controls['database_option'].value;
        if (databaseOption && databaseOption.type == FirebaseDatabaseType.Realtime) {
            this.form.form.controls['sync'].patchValue(false);
            this.submit();
        }
        else {
            this.chooseSyncController
                .chooseSyncMode(this.form.form.controls['sync'], { typeItem: this.typeItem })
                .pipe(untilDestroyed(this))
                .subscribe(function () { return _this.submit(); });
        }
    };
    return FirebaseResourceSettingsComponent;
}(BaseResourceSettingsComponent));
export { FirebaseResourceSettingsComponent };
registerResourceSettingsComponent(ResourceName.Firebase, FirebaseResourceSettingsComponent);

<form [formGroup]="form.form" (ngSubmit)="submit(true)">
  <div class="add-filter">
    <div *ngIf="!field" class="add-filter__select-left">
      <app-auto-field
        [form]="form.form"
        [field]="createField({ name: 'field', field: 'SelectField', params: { options: fieldOptions } })"
        [label]="false"
        [errors]="false"
      >
      </app-auto-field>
    </div>

    <div class="add-filter__lookup-container">
      <mat-menu #dropdown="matMenu">
        <ng-template matMenuContent>
          <button
            *ngFor="let lookup of lookups"
            mat-menu-item
            [class.mat-menu-item-active]="form.form.controls['lookup'].value?.type?.lookup === lookup.type.lookup"
            [disableRipple]="true"
            (click)="form.form.patchValue({ lookup: lookup })"
          >
            <span *ngIf="lookup.type.icon" class="mat-menu-item-icon" [ngClass]="['icon-' + lookup.type.icon]"></span>
            <span *ngIf="!lookup.type.icon" class="mat-menu-item-icon">{{ lookup.type.symbol }}</span>
            {{ lookup.type.verboseName }}
          </button>
        </ng-template>
      </mat-menu>

      <a
        *ngIf="lookups.length"
        href="javascript:void(0)"
        class="add-filter__lookup add-filter__lookup_clickable"
        [class.add-filter__lookup_outside]="field != undefined"
        [matMenuTriggerFor]="dropdown"
      >
        <ng-container *ngIf="form.form.controls['lookup'].value">
          <span
            *ngIf="form.form.controls['lookup'].value.type?.icon"
            [ngClass]="['icon-' + form.form.controls['lookup'].value.type.icon]"
          ></span>
          <span *ngIf="!form.form.controls['lookup'].value.type?.icon" class="add-filter__lookup-symbol">{{
            form.form.controls['lookup'].value?.type?.symbol
          }}</span>
        </ng-container>
        <ng-container *ngIf="!form.form.controls['lookup'].value">
          <span class="icon-equals"></span>
        </ng-container>
      </a>
      <span
        *ngIf="!lookups.length"
        class="add-filter__lookup"
        [class.add-filter__lookup_outside]="field != undefined"
        [class.add-filter__lookup_disabled]="!form.form.value['field']"
      >
        <span class="icon-equals"></span>
      </span>
    </div>

    <div *ngIf="valueField" class="add-filter__value">
      <app-auto-field
        [form]="form.form"
        [field]="createField({ name: 'value', field: valueField, params: valueFieldParams })"
        [context]="context"
        [label]="false"
        [errors]="false"
        [autofocus]="true"
      >
      </app-auto-field>
    </div>
    <div *ngIf="!valueField" class="add-filter__value">
      <input type="text" [placeholder]="valueFieldLookup?.verboseName || ''" disabled />
    </div>

    <span class="add-filter__submit">
      <button
        type="submit"
        class="button button_primary"
        [class.button_segment-left]="
          excludeAllowed && (!form.form.value['lookup'] || form.form.value['lookup'].type?.excludable)
        "
        [disabled]="form.form.invalid || form.form.disabled"
      >
        <span class="button__label">{{ 'Filter' | localize }}</span>
      </button>

      <a
        *ngIf="excludeAllowed && (!form.form.value['lookup'] || form.form.value['lookup'].type?.excludable)"
        href="javascript:void(0)"
        class="button button_segment-right"
        [class.button_disabled]="form.form.invalid || form.form.disabled"
        (click)="submit(false)"
      >
        <span class="button__label">{{ 'Exclude' | localize }}</span>
      </a>
    </span>
  </div>
</form>

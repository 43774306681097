import { ActionDescription } from '@modules/actions';
import { DisplayField } from '@modules/fields';
import { ModelDescription } from '@modules/models';
import { Resource, ResourceName, ResourceType } from '@modules/projects';
import { QueryType } from '@modules/queries';

import { FirebaseDatabaseOption, FirebaseDatabaseType } from '../data/firebase';

export function isResourceCustom(resource: Resource): boolean {
  if (!resource) {
    return false;
  }

  if (resource.typeItem.name == ResourceName.Firebase) {
    const database = resource.params['database_option'] as FirebaseDatabaseOption;
    return database && database.type == FirebaseDatabaseType.Realtime;
  } else {
    return [ResourceName.RestApi, ResourceName.GraphQL, ResourceName.Zapier, ResourceName.Integromat].includes(
      resource.typeItem.name
    );
  }
}

export function isResourceCollectionCustom(resource: Resource, modelDescription: ModelDescription): boolean {
  const resourceWithVirtualCollections =
    (resource &&
      (resource.type === ResourceType.JetBridge || (modelDescription && resource.isSynced(modelDescription.model)))) ||
    (modelDescription && modelDescription.isSynced());
  return isResourceCustom(resource) || (resourceWithVirtualCollections && modelDescription && modelDescription.virtual);
}

export function isResourceActionCustom(resource: Resource, actionDescription: ActionDescription): boolean {
  return (
    isResourceCustom(resource) ||
    (resource && resource.type === ResourceType.JetBridge && actionDescription && actionDescription.virtual)
  );
}

export function isResourceQueryCustom(resource: Resource, queryType: QueryType): boolean {
  return (
    isResourceCustom(resource) ||
    (resource && resource.type === ResourceType.JetBridge && queryType && queryType != QueryType.Simple)
  );
}

export function prepareDataSourceColumnForGet(
  resource: Resource,
  modelDescription: ModelDescription,
  field: DisplayField
) {
  if (resource.isSynced(modelDescription.model) || modelDescription.isSynced()) {
    if (field.params) {
      delete field.params['output_format'];
    }
  }

  return field;
}

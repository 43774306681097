var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ChangeDetectorRef, OnDestroy, OnInit } from '@angular/core';
import { UniversalAnalyticsService } from '@modules/analytics';
import { ResourceGeneratorResolver } from '@modules/resource-generators';
import { SidebarCollapseContext } from '@modules/sidebar';
import { CustomizeBarListLayoutSettingsDataSourceComponent } from '../../customize-bar-list-layout-settings/customize-bar-list-layout-settings-data-source.component';
import { CustomizeBarKanbanBoardSettingsForm } from '../customize-bar-kanban-board-settings.form';
var CustomizeBarKanbanBoardSettingsDataSourceComponent = /** @class */ (function (_super) {
    __extends(CustomizeBarKanbanBoardSettingsDataSourceComponent, _super);
    function CustomizeBarKanbanBoardSettingsDataSourceComponent(resourceGeneratorResolver, analyticsService, cd) {
        var _this = _super.call(this, resourceGeneratorResolver, analyticsService, cd) || this;
        _this.collapseContext = new SidebarCollapseContext();
        return _this;
    }
    CustomizeBarKanbanBoardSettingsDataSourceComponent.prototype.ngOnDestroy = function () { };
    return CustomizeBarKanbanBoardSettingsDataSourceComponent;
}(CustomizeBarListLayoutSettingsDataSourceComponent));
export { CustomizeBarKanbanBoardSettingsDataSourceComponent };

import { Injectable } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import cloneDeep from 'lodash/cloneDeep';
import { Observable, of, throwError } from 'rxjs';
import { catchError, delayWhen, tap } from 'rxjs/operators';

import { FormUtils } from '@common/form-utils';
import { CurrentProjectStore, Project, ProjectService } from '@modules/projects';
import { isColorHex } from '@shared';

@Injectable()
export class ProjectUpdateForm extends FormGroup {
  controls: {
    name: FormControl;
    logo_color: FormControl;
    logo_color_custom_enabled: FormControl;
    logo_color_custom: FormControl;
    logo: FormControl;
    logo_file: FormControl;
    logo_fill: FormControl;
  };

  constructor(
    private projectService: ProjectService,
    private currentProjectStore: CurrentProjectStore,
    private formUtils: FormUtils
  ) {
    super({
      name: new FormControl(''),
      logo_color: new FormControl(''),
      logo_color_custom_enabled: new FormControl(false),
      logo_color_custom: new FormControl('#2B50ED'),
      logo: new FormControl(null),
      logo_file: new FormControl(null),
      logo_fill: new FormControl(false)
    });
  }

  project: Project;

  init(project: Project) {
    this.project = project;

    this.controls.name.patchValue(project.name);

    if (isColorHex(project.logoColor)) {
      this.controls.logo_color.patchValue('');
      this.controls.logo_color_custom_enabled.patchValue(true);
      this.controls.logo_color_custom.patchValue(project.logoColor);
    } else {
      this.controls.logo_color.patchValue(project.logoColor);
      this.controls.logo_color_custom_enabled.patchValue(false);
      this.controls.logo_color_custom.patchValue('#2B50ED');
    }

    this.controls.logo.patchValue(project.logo);
    this.controls.logo_file.patchValue(null);
    this.controls.logo_fill.patchValue(project.logoFill);

    this.markAsPristine();
  }

  getLogoColor(): string {
    if (this.controls.logo_color_custom_enabled.value) {
      return this.controls.logo_color_custom.value;
    } else {
      return this.controls.logo_color.value;
    }
  }

  getInstance(): Project {
    const project = cloneDeep(this.project) as Project;

    project.name = this.controls.name.value;
    project.logoColor = this.getLogoColor();

    if (this.controls.logo_file.value) {
      project.logoFile = this.controls.logo_file.value;
    } else {
      project.logo = this.controls.logo.value;
    }

    project.logoFill =
      this.controls.logo.value || this.controls.logo_file.value ? this.controls.logo_fill.value : false;

    return project;
  }

  submit(): Observable<Project> {
    const project = this.getInstance();

    if (!project) {
      return of(undefined);
    }

    const obs = this.projectService.update(project.uniqueName, project, [
      'name',
      'color',
      // 'url',
      // 'default_group',
      'logo',
      'default_theme',
      'params'
    ]);

    return obs.pipe(
      tap(result => (this.project = result)),
      delayWhen(() => this.currentProjectStore.getFirst(true)),
      catchError(error => {
        this.formUtils.showFormErrors(this, error);
        return throwError(error);
      })
    );
  }
}

import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';

import { BasePopupComponent } from '@common/popups';

import { ActionDescription } from '@modules/actions';
import { createFormFieldFactory } from '@modules/fields';
import { Resource } from '@modules/projects';

import { ActionCreateOptions, ActionCreatePopupForm } from './action-create-popup.form';

@Component({
  selector: 'app-action-create-popup',
  templateUrl: './action-create-popup.component.html',
  providers: [ActionCreatePopupForm]
})
export class ActionCreatePopupComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() resource: Resource;
  @Input() options: ActionCreateOptions = {};
  @Output() created = new EventEmitter<ActionDescription>();

  createField = createFormFieldFactory();
  loading = false;

  constructor(
    public form: ActionCreatePopupForm,
    private popupComponent: BasePopupComponent,
    private cd: ChangeDetectorRef
  ) {}

  ngAfterViewInit(): void {
    this.form.init(this.resource, this.options);
  }

  ngOnDestroy(): void {}

  ngOnInit() {}

  submit() {
    this.loading = true;
    this.cd.markForCheck();

    this.form
      .submit()
      .pipe(untilDestroyed(this))
      .subscribe(
        result => {
          this.created.emit(result);
          this.close();
        },
        () => {
          this.loading = false;
          this.cd.markForCheck();
        }
      );
  }

  close() {
    this.popupComponent.close();
  }
}

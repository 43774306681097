import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { AggregateFunc, DatasetGroupLookup } from '@modules/charts';
import { ColumnsLayoutColumnElementItem, ColumnsLayoutElementItem, CustomViewSettings, FilterStyle, validateElementNames, VALUE_OUTPUT, ViewSettingsStore } from '@modules/customize';
import { ChartType } from '@modules/dashboard';
import { FieldType, Input, InputValueType, OptionsType } from '@modules/fields';
import { capitalize, isSet } from '@shared';
import { BackGenerator } from '../back-generator/back-generator.service';
import { ChartWidgetGenerator } from '../chart-widget-generator/chart-widget-generator.service';
import { CreateFormGenerator } from '../create-form-generator/create-form-generator.service';
import { DeleteButtonGenerator } from '../delete-button-generator/delete-button-generator.service';
import { DetailGenerator } from '../detail-generator/detail-generator.service';
import { FilterGenerator } from '../filter-generator/filter-generator.service';
import { GeneratorUtils } from '../generator-utils/generator-utils.service';
import { ListGenerator } from '../list-generator/list-generator.service';
import { UpdateFormGenerator } from '../update-form-generator/update-form-generator.service';
import { ValueWidgetGenerator } from '../value-widget-generator/value-widget-generator.service';
import { ViewSettingsGeneratorService } from '../view-settings-generator/view-settings-generator.service';
var DashboardGenerator = /** @class */ (function () {
    function DashboardGenerator(viewSettingsStore, viewSettingsGeneratorService, createFormGenerator, updateFormGenerator, detailGenerator, deleteButtonGenerator, backGenerator, listGenerator, filterGenerator, valueWidgetGenerator, chartWidgetGenerator, generatorUtils) {
        this.viewSettingsStore = viewSettingsStore;
        this.viewSettingsGeneratorService = viewSettingsGeneratorService;
        this.createFormGenerator = createFormGenerator;
        this.updateFormGenerator = updateFormGenerator;
        this.detailGenerator = detailGenerator;
        this.deleteButtonGenerator = deleteButtonGenerator;
        this.backGenerator = backGenerator;
        this.listGenerator = listGenerator;
        this.filterGenerator = filterGenerator;
        this.valueWidgetGenerator = valueWidgetGenerator;
        this.chartWidgetGenerator = chartWidgetGenerator;
        this.generatorUtils = generatorUtils;
    }
    DashboardGenerator.prototype.getModelPage = function (project, resource, modelDescription, uniqueName, options) {
        var _this = this;
        var _a = this.getModelDataSegments(modelDescription), segments = _a.segments, segmentOptions = _a.segmentOptions, dateField = _a.dateField;
        var viewSettings = new CustomViewSettings();
        viewSettings.project = project.uniqueName;
        viewSettings.uniqueName = uniqueName;
        viewSettings.name = modelDescription.verboseNamePlural;
        viewSettings.resource = resource.uniqueName;
        viewSettings.model = modelDescription.model;
        viewSettings.configuredElements = 1;
        viewSettings.configuredModelElements = 1;
        viewSettings.newlyCreated = true;
        var row1Element = new ColumnsLayoutElementItem();
        row1Element.uid = options.idGenerator ? options.idGenerator.elementId('row1Element') : undefined;
        var valueWidgetSettings = [
            {}
        ].concat([0, 1].map(function (categoryIndex) {
            var categoryOption = segmentOptions[categoryIndex];
            if (categoryOption) {
                return {
                    label: categoryOption.label,
                    filters: [{ name: categoryOption.field, value: categoryOption.value }]
                };
            }
            else {
                return {};
            }
        }));
        valueWidgetSettings.forEach(function (settings, i) {
            var element = _this.valueWidgetGenerator.getElement(resource, modelDescription, {
                func: AggregateFunc.Count,
                label: isSet(settings.label) ? settings.label : 'Total number',
                filters: settings.filters,
                uid: options.idGenerator ? options.idGenerator.elementId("row1Column" + (i + 1) + "Widget") : undefined
            });
            var column = new ColumnsLayoutColumnElementItem();
            column.uid = options.idGenerator ? options.idGenerator.elementId("row1Column" + (i + 1)) : undefined;
            column.children = [element];
            column.weight = 1;
            row1Element.columns.push(column);
        });
        var row2Element = new ColumnsLayoutElementItem();
        row2Element.uid = options.idGenerator ? options.idGenerator.elementId('row2Element') : undefined;
        if (segments[0]) {
            var element = this.chartWidgetGenerator.getElement(resource, modelDescription, {
                yFunc: AggregateFunc.Count,
                xColumn: segments[0].field,
                label: [capitalize(modelDescription.verboseNamePlural), 'by', capitalize(segments[0].fieldLabel)].join(' '),
                chartType: segments[0].options.length > 6 ? ChartType.Bar : ChartType.Doughnut,
                uid: options.idGenerator ? options.idGenerator.elementId('row2Column1Widget') : undefined
            });
            element.height = 280;
            var column = new ColumnsLayoutColumnElementItem();
            column.uid = options.idGenerator ? options.idGenerator.elementId("row2Column1") : undefined;
            column.children = [element];
            column.weight = 1;
            row2Element.columns.push(column);
        }
        if (dateField) {
            var element = this.chartWidgetGenerator.getElement(resource, modelDescription, {
                yFunc: AggregateFunc.Count,
                xColumn: dateField.name,
                xLookup: DatasetGroupLookup.DateMonth,
                label: [capitalize(modelDescription.verboseNamePlural), 'by', capitalize(dateField.verboseName)].join(' '),
                chartType: ChartType.Bar,
                uid: options.idGenerator ? options.idGenerator.elementId('row2Column2Widget') : undefined
            });
            element.height = 280;
            var column = new ColumnsLayoutColumnElementItem();
            column.uid = options.idGenerator ? options.idGenerator.elementId("row1column2") : undefined;
            column.children = [element];
            column.weight = 1;
            row2Element.columns.push(column);
        }
        else if (!dateField && segments[1]) {
            var element = this.chartWidgetGenerator.getElement(resource, modelDescription, {
                yFunc: AggregateFunc.Count,
                xColumn: segments[1].field,
                label: [capitalize(modelDescription.verboseNamePlural), 'by', capitalize(segments[1].fieldLabel)].join(' '),
                chartType: segments[1].options.length > 6 ? ChartType.Bar : ChartType.Doughnut,
                uid: options.idGenerator ? options.idGenerator.elementId('row2Column2Widget') : undefined
            });
            element.height = 280;
            var column = new ColumnsLayoutColumnElementItem();
            column.uid = options.idGenerator ? options.idGenerator.elementId("row2Column2") : undefined;
            column.children = [element];
            column.weight = 1;
            row2Element.columns.push(column);
        }
        var listElement = this.listGenerator.getTableElement(resource, modelDescription, uniqueName, {
            templates: options.templates,
            fields: options.listFields,
            sortingField: dateField ? dateField.name : undefined,
            sortingAsc: dateField ? false : undefined,
            uid: options.idGenerator ? options.idGenerator.elementId('listElement') : undefined
        });
        var filterElement = this.filterGenerator.getElement(modelDescription, listElement.uid, {
            style: FilterStyle.Wrap,
            uid: options.idGenerator ? options.idGenerator.elementId('filterElement') : undefined
        });
        listElement.layouts[0].dataSource.queryInputs = filterElement.elementInputs.map(function (item) {
            var input = new Input();
            input.name = item.name;
            input.valueType = InputValueType.Context;
            input.contextValue = ['elements', filterElement.uid, item.name, VALUE_OUTPUT];
            return input;
        });
        viewSettings.elements = [
            row1Element
        ].concat((row2Element.columns.length ? [row2Element] : []), [
            filterElement,
            listElement
        ]);
        validateElementNames(viewSettings.elements);
        return viewSettings;
    };
    DashboardGenerator.prototype.getModelDataSegments = function (modelDescription) {
        var selectFields = modelDescription.dbFields.filter(function (item) {
            return (item.field == FieldType.Select &&
                item.params['options_type'] == OptionsType.Static &&
                item.params['options'] &&
                item.params['options'].length);
        });
        var booleanFields = modelDescription.dbFields.filter(function (item) { return item.field == FieldType.Boolean; });
        var dateField = modelDescription.dbFields.find(function (item) { return item.field == FieldType.DateTime; });
        var segments = [];
        selectFields.slice(0, 2).forEach(function (field) {
            var selectOptions = field.params['options'];
            segments.push({
                field: field.name,
                fieldLabel: field.verboseName || field.name,
                options: selectOptions.map(function (item) {
                    return {
                        value: item.value,
                        label: [capitalize(field.verboseName), 'is', item.name].join(' ')
                    };
                })
            });
        });
        booleanFields.slice(0, 2).forEach(function (field) {
            segments.push({
                field: field.name,
                fieldLabel: field.verboseName || field.name,
                options: [
                    {
                        value: true,
                        label: ['Is', capitalize(field.verboseName)].join(' ')
                    }
                ]
            });
        });
        booleanFields.slice(0, 2).forEach(function (field) {
            segments.push({
                field: field.name,
                fieldLabel: field.verboseName || field.name,
                options: [
                    {
                        value: false,
                        label: ['Is not', capitalize(field.verboseName)].join(' ')
                    }
                ]
            });
        });
        var segmentOptions = segments.reduce(function (acc, item) {
            item.options.forEach(function (option) {
                acc.push({
                    field: item.field,
                    value: option.value,
                    label: option.label
                });
            });
            return acc;
        }, []);
        return {
            segments: segments,
            segmentOptions: segmentOptions,
            dateField: dateField
        };
    };
    DashboardGenerator.prototype.getPage = function (project, resource, modelDescription, options) {
        var _this = this;
        if (options === void 0) { options = {}; }
        var baseUniqueName = this.generatorUtils.getModelPageUniqueName(resource, modelDescription);
        return combineLatest(this.viewSettingsStore.getDistinctUniqueName(baseUniqueName), this.generatorUtils.getDefaultComponentTemplates()).pipe(map(function (_a) {
            var uniqueName = _a[0], templates = _a[1];
            return _this.getModelPage(project, resource, modelDescription, uniqueName, {
                templates: templates,
                listFields: options.listFields,
                // detailFields: options.detailFields,
                idGenerator: options.idGenerator
            });
        }));
    };
    return DashboardGenerator;
}());
export { DashboardGenerator };

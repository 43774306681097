import { Injectable, Injector } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AppFormGroup, FormUtils } from '@common/form-utils';
import { PopupService } from '@common/popups';
import { MenuGeneratorService } from '@modules/menu';
import { ProjectSettingsService } from '@modules/project-settings';
import { ProjectTokenService, SecretTokenService } from '@modules/projects';
import { IsOptionsValidResult, ZendeskGeneratorService, ZendeskParamsOptions } from '@modules/resource-generators';
import { ResourceParamsResult } from '@modules/resources';

import { BaseResourceSettingsForm } from '../base-resource-settings/base-resource-settings.form';

@Injectable()
export class ZendeskResourceSettingsForm extends BaseResourceSettingsForm<ZendeskParamsOptions> {
  form = new AppFormGroup({
    account_name: new FormControl('', Validators.required),
    account_email: new FormControl('', Validators.required),
    key: new FormControl('', Validators.required),
    sync: new FormControl(true)
  });

  constructor(
    private zendeskGeneratorService: ZendeskGeneratorService,
    protected secretTokenService: SecretTokenService,
    protected formUtils: FormUtils,
    protected projectSettingsService: ProjectSettingsService,
    protected projectTokenService: ProjectTokenService,
    protected popupService: PopupService,
    protected menuGeneratorService: MenuGeneratorService,
    protected injector: Injector
  ) {
    super(
      secretTokenService,
      formUtils,
      projectSettingsService,
      projectTokenService,
      popupService,
      menuGeneratorService,
      injector
    );
  }

  initResourceValue(): Observable<void> {
    return this.zendeskGeneratorService.getParamsOptions(this.project, this.environment, this.resource).pipe(
      map(result => {
        this.form.patchValue({
          account_name: result.account_name,
          account_email: result.account_email,
          key: result.key,
          sync: !!this.resource.isSynced()
        });
      })
    );
  }

  getOptions(): ZendeskParamsOptions {
    return {
      account_name: this.form.value['account_name'],
      account_email: this.form.value['account_email'],
      key: this.form.value['key']
    };
  }

  isOptionsValid(): Observable<IsOptionsValidResult> {
    return this.zendeskGeneratorService.isOptionsValid(this.getOptions());
  }

  getParams(): ResourceParamsResult | Observable<ResourceParamsResult> {
    return this.zendeskGeneratorService
      .generateParams(this.project, this.environment, this.typeItem, this.getOptions())
      .pipe(
        map(result => {
          return {
            ...result,
            resourceName: this.resourceForm.value['name'],
            sync: this.form.value['sync'],
            syncModelDescriptions: this.getParamsSyncModelDescriptions(result)
          };
        })
      );
  }
}

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, Optional } from '@angular/core';

import { BasePopupComponent } from '@common/popups';
import { IntercomService, UniversalAnalyticsService } from '@modules/analytics';
import { CurrentEnvironmentStore, CurrentProjectStore, ProjectTokenService, ResourceName } from '@modules/projects';

import { registerResourceSettingsComponent } from '../../../data/resource-settings-components';
import { BaseResourceSettingsComponent } from '../base-resource-settings/base-resource-settings.component';
import { JetDatabaseResourceSettingsForm } from './jet-database-resource-settings-form.service';

@Component({
  selector: 'app-jet-database-resource-settings',
  templateUrl: './jet-database-resource-settings.component.html',
  providers: [JetDatabaseResourceSettingsForm],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class JetDatabaseResourceSettingsComponent extends BaseResourceSettingsComponent implements OnInit, OnDestroy {
  autoCreate = false;

  constructor(
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    public form: JetDatabaseResourceSettingsForm,
    @Optional() popupComponent: BasePopupComponent,
    projectTokenService: ProjectTokenService,
    intercomService: IntercomService,
    analyticsService: UniversalAnalyticsService,
    cd: ChangeDetectorRef
  ) {
    super(form, popupComponent, projectTokenService, intercomService, analyticsService, cd);
  }

  ngOnInit() {
    super.ngOnInit();

    const existingResources = this.currentProjectStore.instance
      .getEnvironmentResources(this.currentEnvironmentStore.instance.uniqueName)
      .filter(item => !item.demo && item.typeItem.name == ResourceName.JetDatabase);

    if (!this.resource && !existingResources.length) {
      this.autoCreate = true;
      this.cd.markForCheck();
      this.submit();
    }
  }
}

registerResourceSettingsComponent(ResourceName.JetDatabase, JetDatabaseResourceSettingsComponent);

import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';

import { AppConfigService } from '@core';
import { FieldType, registerFieldComponent } from '@modules/fields';
import { getEmojiData, getIconData } from '@modules/icons';

import { FieldComponent } from '../field/field.component';

@Component({
  selector: 'app-icon-field',
  templateUrl: 'icon-field.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IconFieldComponent extends FieldComponent {
  editPopoverOpened = false;

  constructor(private appConfigService: AppConfigService, private cd: ChangeDetectorRef) {
    super();
  }

  openEditPopover(value) {
    this.editPopoverOpened = value;
    this.cd.markForCheck();
  }

  getLabel(value: string) {
    const icon = getIconData(value, {
      mediaBaseUrl: this.appConfigService.getMediaBaseUrl()
    });

    if (icon.emoji) {
      const emoji = getEmojiData(icon.emoji);
      if (emoji) {
        return emoji.name.toLowerCase();
      } else {
        return value;
      }
    } else if (icon.icon) {
      return icon.icon.replace(/_/g, ' ');
    } else if (icon.image) {
      return icon.imageFileName;
    } else {
      return value;
    }
  }

  setIcon(icon: string) {
    const patch = {};
    patch[this.field.name] = icon;
    this.form.patchValue(patch);
  }
}

registerFieldComponent(FieldType.Icon, IconFieldComponent);

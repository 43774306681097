import { Injectable } from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';

import { MultipleFileStoreFormat } from '@modules/fields';

@Injectable()
export class AudioFieldDataParamsForm extends FormGroup {
  paramsControl: AbstractControl;
  controls: {
    multiple: FormControl;
    store_format: FormControl;
  };

  outputFormatOptions = [
    { value: MultipleFileStoreFormat.Array, name: 'JSON Array' },
    { value: MultipleFileStoreFormat.String, name: 'String' }
  ];

  constructor() {
    super({
      multiple: new FormControl(false),
      store_format: new FormControl(MultipleFileStoreFormat.Array)
    });
  }

  init(paramsControl: AbstractControl) {
    this.paramsControl = paramsControl;

    if (paramsControl.value) {
      this.controls.multiple.patchValue(paramsControl.value['multiple']);
      this.controls.store_format.patchValue(paramsControl.value['store_format'] || MultipleFileStoreFormat.Array);
      this.markAsPristine();
    }

    this.valueChanges.subscribe(() => this.submit());
  }

  submit() {
    const result = {
      multiple: this.controls.multiple.value,
      store_format: this.controls.store_format.value
    };

    this.paramsControl.patchValue({
      ...this.paramsControl.value,
      ...result
    });
  }
}

var _a, _b, _c;
export var ElementType;
(function (ElementType) {
    ElementType["Section"] = "section_layout";
    ElementType["Columns"] = "columns_layout";
    ElementType["Card"] = "card_layout";
    ElementType["Stack"] = "stack_layout";
    ElementType["Tabs"] = "tabs_layout";
    ElementType["Collapse"] = "collapse";
    ElementType["Accordion"] = "accordion";
    ElementType["Title"] = "title";
    ElementType["Text"] = "text";
    ElementType["Field"] = "field";
    ElementType["Action"] = "action";
    ElementType["ActionGroup"] = "action_group";
    ElementType["ActionDropdown"] = "action_dropdown";
    ElementType["Widget"] = "widget";
    ElementType["Image"] = "image";
    ElementType["List"] = "list";
    ElementType["Filter"] = "filter";
    ElementType["Model"] = "model";
    ElementType["Form"] = "form";
    ElementType["FormSubmit"] = "form_submit";
    ElementType["Back"] = "back";
    ElementType["IFrame"] = "iframe";
    ElementType["QrCode"] = "qr_code";
    ElementType["BarCode"] = "bar_code";
    ElementType["Alert"] = "alert";
    ElementType["Scanner"] = "scanner";
    ElementType["Spacing"] = "spacing";
    ElementType["Separator"] = "separator";
    ElementType["Steps"] = "steps";
    ElementType["FileViewer"] = "file_viewer";
    ElementType["RangeSlider"] = "range_slider";
    ElementType["DateRange"] = "date_range";
    ElementType["Custom"] = "custom";
})(ElementType || (ElementType = {}));
export var elementItemDataCategories = (_a = {},
    _a[ElementType.Section] = 'Layout',
    _a[ElementType.Columns] = 'Layout',
    _a[ElementType.Card] = 'Layout',
    _a[ElementType.Stack] = 'Layout',
    _a[ElementType.Tabs] = 'Layout',
    _a[ElementType.Collapse] = 'Layout',
    _a[ElementType.Accordion] = 'Layout',
    _a[ElementType.Title] = 'Layout',
    _a[ElementType.Text] = 'Layout',
    _a[ElementType.Field] = 'Fields',
    _a[ElementType.List] = 'Collections',
    _a[ElementType.Model] = 'Collections',
    _a[ElementType.Custom] = 'Advanced',
    _a[ElementType.Form] = 'Actions',
    _a[ElementType.Action] = 'Actions',
    _a[ElementType.ActionGroup] = 'Actions',
    _a[ElementType.ActionDropdown] = 'Actions',
    _a[ElementType.Widget] = 'Charts',
    _a);
export var elementItemCategories = (_b = {},
    _b[ElementType.Section] = 'Layout',
    _b[ElementType.Columns] = 'Layout',
    _b[ElementType.Card] = 'Layout',
    _b[ElementType.Stack] = 'Layout',
    _b[ElementType.Tabs] = 'Layout',
    _b[ElementType.Collapse] = 'Layout',
    _b[ElementType.Accordion] = 'Layout',
    _b[ElementType.Title] = 'Layout',
    _b[ElementType.Text] = 'Layout',
    _b[ElementType.List] = 'Lists',
    _b[ElementType.Field] = 'Fields',
    _b[ElementType.Model] = 'Details',
    _b[ElementType.Scanner] = 'Advanced',
    _b[ElementType.Custom] = 'Advanced',
    _b[ElementType.Form] = 'Actions',
    _b[ElementType.Action] = 'Actions',
    _b[ElementType.ActionGroup] = 'Actions',
    _b[ElementType.ActionDropdown] = 'Actions',
    _b[ElementType.Widget] = 'Charts',
    _b);
export var elementItemImages = (_c = {},
    _c[ElementType.Section] = 'section_layout',
    _c[ElementType.Columns] = 'columns_layout',
    _c[ElementType.Card] = 'card_layout',
    _c[ElementType.Tabs] = 'tabs_layout',
    _c[ElementType.Collapse] = 'collapse',
    _c[ElementType.Accordion] = 'accordion',
    _c[ElementType.Title] = 'heading',
    _c[ElementType.Text] = 'text',
    _c[ElementType.Field] = 'text_field',
    _c[ElementType.Action] = 'button',
    _c[ElementType.Widget] = 'line_chart',
    _c[ElementType.List] = 'table_list',
    _c[ElementType.Model] = 'detail',
    _c[ElementType.Custom] = 'form',
    _c);

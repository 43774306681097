import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { untilDestroyed } from 'ngx-take-until-destroy';

import { ActionItem } from '@modules/actions';
import { ViewContext, ViewContextElement } from '@modules/customize';
import { controlValue } from '@shared';

import { CustomizeBarEditEventType } from '../../data/customize-bar-edit-event-type';
import { CustomizeBarContext } from '../../services/customize-bar-context/customize-bar.context';
import { CustomizeBarService } from '../../services/customize-bar/customize-bar.service';
import {
  CustomizeActionEditOptions,
  CustomizeBarActionEditForm
} from '../customize-bar-action-edit/customize-bar-action-edit.form';

@Component({
  selector: 'app-action-form-single-edit',
  templateUrl: './action-form-single-edit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActionFormSingleEditComponent implements OnInit, OnDestroy {
  @Input() options: CustomizeActionEditOptions = {};
  @Input() label: string;
  @Input() title: string;
  @Input() form: CustomizeBarActionEditForm;
  @Input() enabledControl: AbstractControl;
  @Input() context: ViewContext;
  @Input() contextElement: ViewContextElement;
  @Input() contextElementPath: (string | number)[];
  @Input() contextElementPaths: (string | number)[][];
  @Input() defaultTitle: string;
  @Input() defaultSubtitle = 'Not specified';
  @Input() defaultDisabled = true;
  @Input() draggable = false;
  @Input() remove = false;
  @Input() pin = false;
  @Input() pinned = false;
  @Input() icon: string;
  @Input() object: string;
  @Input() customError: string;
  @Input() classes: string | string[];
  @Input() firstInit = false;
  @Input() setupOnCreate = false;

  action: ActionItem;

  constructor(
    private customizeBarService: CustomizeBarService,
    private customizeBarContext: CustomizeBarContext,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    controlValue(this.form)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.action = this.form.submit().action;
        this.cd.markForCheck();
      });

    if (this.form.parent) {
      this.form.parent.statusChanges.pipe(untilDestroyed(this)).subscribe(() => this.cd.markForCheck());
    }

    if (this.setupOnCreate) {
      this.editActionItem();
    }
  }

  ngOnDestroy(): void {}

  editActionItem() {
    this.customizeBarService
      .customizeActionItem({
        options: this.options,
        context: this.customizeBarContext,
        viewContext: this.context,
        viewContextElement: this.contextElement,
        viewContextElementPath: this.contextElementPath,
        viewContextElementPaths: this.contextElementPaths,
        label: this.label,
        existingForm: this.form,
        object: this.object,
        append: true,
        firstInit: this.firstInit,
        setupOnCreate: this.setupOnCreate
      })
      .pipe(untilDestroyed(this))
      .subscribe(e => {
        if (e.type == CustomizeBarEditEventType.Created || e.type == CustomizeBarEditEventType.Updated) {
          this.setEnabled(true);
        } else if (e.type == CustomizeBarEditEventType.Deleted) {
          this.setEnabled(false);
        }
      });
  }

  setEnabled(enabled: boolean) {
    if (!this.enabledControl) {
      return;
    }

    if (this.enabledControl.value != enabled) {
      this.enabledControl.patchValue(enabled);
    }

    this.enabledControl.markAsDirty();
  }
}

export enum MenuItemType {
  Simple = 'simple',
  Section = 'section',
  ModelLink = 'model_link',
  Image = 'image',
  Button = 'button',
  Share = 'share',
  Separator = 'separator',
  Custom = 'custom'
}

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { untilDestroyed } from 'ngx-take-until-destroy';

import { ProjectProperty, ProjectPropertyStore, ProjectPropertyType } from '@modules/projects';
import { ascComparator, isSet } from '@shared';

export interface PropertyOption {
  property: ProjectProperty;
  label: string;
  icon?: string;
}

@Component({
  selector: 'app-project-property-menu',
  templateUrl: './project-property-menu.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProjectPropertyMenuComponent implements OnInit, OnDestroy {
  @Input() type: ProjectPropertyType;
  @Input() filterOptions: (property: ProjectProperty) => boolean;
  @Output() propertySelect = new EventEmitter<{ property: ProjectProperty }>();
  @Output() createPropertyClick = new EventEmitter<void>();

  searchControl = new FormControl('');
  options: PropertyOption[] = [];
  filteredOptions: PropertyOption[] = [];

  constructor(private projectPropertyStore: ProjectPropertyStore, private cd: ChangeDetectorRef) {}

  ngOnInit() {
    this.projectPropertyStore
      .get()
      .pipe(untilDestroyed(this))
      .subscribe(properties => {
        this.options = properties
          .filter(item => !this.type || item.type == this.type)
          .filter(item => !this.filterOptions || this.filterOptions(item))
          .map(item => {
            return {
              property: item,
              label: item.name,
              icon: item.fieldDescription.icon
            };
          })
          .sort((lhs, rhs) => ascComparator(lhs.label.toLowerCase(), rhs.label.toLowerCase()));
        this.updateFilteredOptions();
      });

    this.searchControl.valueChanges.pipe(untilDestroyed(this)).subscribe(() => this.updateFilteredOptions());
  }

  ngOnDestroy(): void {}

  getFilteredOptions(): PropertyOption[] {
    const search = this.searchControl.value.toLowerCase().trim();

    if (!isSet(search)) {
      return this.options;
    }

    return this.options.filter(item => {
      return item.label.toLowerCase().includes(search);
    });
  }

  updateFilteredOptions() {
    this.filteredOptions = this.getFilteredOptions();
    this.cd.markForCheck();
  }

  clearSearch() {
    this.searchControl.patchValue('');
    this.updateFilteredOptions();
  }

  selectOption(option: PropertyOption) {
    this.propertySelect.emit({ property: option.property });
  }

  createProperty() {
    this.createPropertyClick.emit();
  }
}

import { Injector, StaticProvider } from '@angular/core';
import { Factory } from '@core';
import { ViewContext } from '../data/view-context';
import { ViewContextElement } from '../data/view-context-element';
import * as i0 from "@angular/core";
import * as i1 from "../../../core/services/factory/factory.service";
var ViewContextElementFactory = /** @class */ (function () {
    function ViewContextElementFactory(factory) {
        this.factory = factory;
    }
    ViewContextElementFactory.prototype.create = function (injector, options) {
        if (options === void 0) { options = {}; }
        return this.factory.create(ViewContextElement, {
            dependencies: [ViewContext],
            injector: injector,
            providers: options.providers
        });
    };
    ViewContextElementFactory.ngInjectableDef = i0.defineInjectable({ factory: function ViewContextElementFactory_Factory() { return new ViewContextElementFactory(i0.inject(i1.Factory)); }, token: ViewContextElementFactory, providedIn: "root" });
    return ViewContextElementFactory;
}());
export { ViewContextElementFactory };

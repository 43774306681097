<ng-container *ngIf="!publicAccessNotEnabled && !publicAccessNotEnabledInFree">
  <div *ngIf="loading" class="loader loader_screen">
    <div class="loader__inner">
      <div class="spinner-double-pulse">
        <div class="spinner-double-pulse__pulse1"></div>
        <div class="spinner-double-pulse__pulse2"></div>
      </div>
    </div>
  </div>

  <div *ngIf="notFound" class="popups popups_visible">
    <div class="popup-background"></div>
    <div class="popup-container">
      <div class="popup popup_shadow">
        <app-popup2 [size]="'m'">
          <app-popup2-header>
            <app-popup2-title align="center">Application not found</app-popup2-title>
            <app-popup2-description align="center">
              Looks like this public application was disabled or you have followed the wrong link.
            </app-popup2-description>
          </app-popup2-header>
        </app-popup2>
      </div>
    </div>
  </div>
</ng-container>

<app-feature-overview *ngIf="publicAccessNotEnabled" [feature]="'permissions'">
  <div data-subtitle>Paid Feature</div>
  <div data-title><strong>Public access</strong> feature is not enabled in the current App plan</div>
  <div data-description>
    <ul>
      <li>Ask App administrator to upgrade plan</li>
    </ul>
  </div>
</app-feature-overview>

<div *ngIf="publicAccessNotEnabledInFree" class="status-page">
  <div class="status-page__main">
    <div class="status-page__content">
      <h1 class="status-page__title">This page is disabled</h1>
      <div class="status-page__description">
        Looks like this page is disabled or maybe you have followed the wrong link. Please contact App administrator get
        correct link.
      </div>
    </div>
  </div>
  <div class="status-page__right">
    <div class="status-page__image">
      <div class="status-page__image-inner status-page__image-inner_type_not-found"></div>
    </div>
  </div>
</div>

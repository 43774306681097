import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { untilDestroyed } from 'ngx-take-until-destroy';

import { getColorHexStr } from '@modules/colors';
import { isSet } from '@shared';

export type ColorOptionsSize = 'sm' | 'm';

@Component({
  selector: 'app-color-options',
  templateUrl: './color-options.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ColorOptionsComponent implements OnInit, OnDestroy {
  @Input() resetEnabled = false;
  @Input() resetBackgroundColor: string;
  @Input() resetBorderColor: string;
  @Input() resetValue = '';
  @Input() colors: { value: any; color: string }[] = [];
  @Input() colorControl: AbstractControl;
  @Input() customColorEnabledControl: AbstractControl;
  @Input() customColorControl: AbstractControl;
  @Input() size: ColorOptionsSize = 'm';

  colorOpened = false;

  constructor(private cd: ChangeDetectorRef) {}

  ngOnInit() {
    if (this.colorControl) {
      this.colorControl.statusChanges.pipe(untilDestroyed(this)).subscribe(() => this.cd.markForCheck());
    }
  }

  ngOnDestroy(): void {}

  setColorOpened(value: boolean) {
    this.colorOpened = value;
    this.cd.markForCheck();
  }

  onCustomColorClick() {
    if (!this.customColorEnabledControl.value) {
      const currentColor =
        this.resetEnabled && this.colorControl.value == this.resetValue
          ? this.resetBackgroundColor
          : this.colorControl.value;
      const currentColorHex = isSet(currentColor) ? getColorHexStr(currentColor) : undefined;

      this.customColorEnabledControl.patchValue(true);

      if (isSet(currentColorHex)) {
        this.customColorControl.patchValue(currentColorHex);
      }
    }

    this.setColorOpened(true);
  }
}

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChildren
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Subscription } from 'rxjs';
import { first, switchMap } from 'rxjs/operators';

import { MenuGroup, MenuGroupItem } from '@modules/menu';
import { NavigationService } from '@modules/navigation';
import { AppLinkActiveDirective } from '@modules/routing';
import { TypedChanges } from '@shared';

@Component({
  selector: 'app-customize-menu-pages-group',
  templateUrl: './customize-menu-pages-group.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomizeMenuPagesGroupComponent implements OnInit, OnDestroy, OnChanges {
  @Input() group: MenuGroup;
  @Input() editing = false;
  @Input() filteredQuery = '';
  @Input() selectedPages: { [k: string]: boolean } = {};
  @Input() homeItem: MenuGroupItem;
  @Output() itemClick = new EventEmitter<{ item: MenuGroupItem; event: MouseEvent }>();
  @Output() actionClick = new EventEmitter<void>();

  @ViewChildren(AppLinkActiveDirective) linkActiveDirectives = new QueryList<AppLinkActiveDirective>();

  hasActiveDuplicateLink = false;
  linksSubscription: Subscription;
  collapsed = true;
  displayItems: MenuGroupItem[] = [];
  maxDisplayInitial = 9;

  constructor(private navigationService: NavigationService, private zone: NgZone, private cd: ChangeDetectorRef) {}

  ngOnInit() {}

  ngOnDestroy(): void {}

  ngOnChanges(changes: TypedChanges<CustomizeMenuPagesGroupComponent>): void {
    if (changes.group) {
      this.updateDisplayItems();
      this.updateLinks();
    }
  }

  updateDisplayItems() {
    if (this.group.collapsible && this.collapsed) {
      this.displayItems = this.group.items.slice(0, this.maxDisplayInitial);
    } else {
      this.displayItems = this.group.items;
    }

    this.cd.markForCheck();
  }

  updateLinks() {
    if (this.linksSubscription) {
      this.linksSubscription.unsubscribe();
    }

    this.linksSubscription = this.zone.onStable
      .pipe(
        first(),
        switchMap(() => this.navigationService.routeSnapshot$),
        untilDestroyed(this)
      )
      .subscribe(() => {
        this.hasActiveDuplicateLink = this.linkActiveDirectives.some(
          item => item.appLinkActiveData['duplicate'] && item.isActive
        );
        this.cd.markForCheck();
      });
  }

  setCollapsed(value: boolean) {
    this.collapsed = value;
    this.cd.markForCheck();
    this.updateDisplayItems();
  }
}

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./kanban-board-stub-content.component";
var styles_KanbanBoardStubContentComponent = [];
var RenderType_KanbanBoardStubContentComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_KanbanBoardStubContentComponent, data: {} });
export { RenderType_KanbanBoardStubContentComponent as RenderType_KanbanBoardStubContentComponent };
function View_KanbanBoardStubContentComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, "div", [["class", "model-card__item"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "label", [["class", "model-card__item-label"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 2, "span", [], [[2, "loading-animation", null]], null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "span", [["class", "stub-text"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Field"])), (_l()(), i0.ɵeld(5, 0, null, null, 3, "div", [["class", "model-card__item-value"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 2, "span", [], [[2, "loading-animation", null]], null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 1, "span", [["class", "stub-text"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Value"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.animating; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.animating; _ck(_v, 6, 0, currVal_1); }); }
function View_KanbanBoardStubContentComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "div", [["class", "kanban-board-column__item"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 5, "div", [["class", "kanban-board-item"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 4, "div", [["class", "model-card"]], [[2, "model-card_vertical", null]], null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_KanbanBoardStubContentComponent_2)), i0.ɵdid(5, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i0.ɵpad(6, 2)], function (_ck, _v) { var currVal_1 = _ck(_v, 6, 0, 1, 2); _ck(_v, 5, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = true; _ck(_v, 2, 0, currVal_0); }); }
export function View_KanbanBoardStubContentComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_KanbanBoardStubContentComponent_1)), i0.ɵdid(1, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.items; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_KanbanBoardStubContentComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-kanban-board-stub-content", [], null, null, null, View_KanbanBoardStubContentComponent_0, RenderType_KanbanBoardStubContentComponent)), i0.ɵdid(1, 114688, null, 0, i2.KanbanBoardStubContentComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var KanbanBoardStubContentComponentNgFactory = i0.ɵccf("app-kanban-board-stub-content", i2.KanbanBoardStubContentComponent, View_KanbanBoardStubContentComponent_Host_0, { items: "items", animating: "animating" }, {}, []);
export { KanbanBoardStubContentComponentNgFactory as KanbanBoardStubContentComponentNgFactory };

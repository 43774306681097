import { moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { AfterViewInit, ChangeDetectorRef, ElementRef, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import cloneDeep from 'lodash/cloneDeep';
import last from 'lodash/last';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { ViewContext } from '@modules/customize';
import { ThemeService } from '@modules/theme';
import { cleanWorkflowStepName, WorkflowStepType } from '@modules/workflow';
import { isSet } from '@shared';
import { WorkflowEditContext } from '../../../../services/workflow-edit-context/workflow-edit.context';
var ConditionWorkflowStepItemComponent = /** @class */ (function () {
    function ConditionWorkflowStepItemComponent(el, workflowEditContext, themeService, cd) {
        this.el = el;
        this.workflowEditContext = workflowEditContext;
        this.themeService = themeService;
        this.cd = cd;
        this.steps = [];
        this.workflowEditable = false;
        this.active = false;
        this.stepClick = new EventEmitter();
        this.cls = true;
        this.zigZagLeft = false;
        this.zigZagX = 0;
        this.zigZagWidth = 0;
        this.trackStep = (function () {
            return function (i, item) {
                return item ? item.uid : i;
            };
        })();
    }
    ConditionWorkflowStepItemComponent.prototype.ngOnInit = function () { };
    ConditionWorkflowStepItemComponent.prototype.ngOnDestroy = function () { };
    ConditionWorkflowStepItemComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        setTimeout(function () { return _this.updateConnections(); }, 0);
        this.workflowEditContext
            .state$()
            .pipe(untilDestroyed(this))
            .subscribe(function () { return _this.updateConnections(); });
    };
    Object.defineProperty(ConditionWorkflowStepItemComponent.prototype, "connectionColor", {
        get: function () {
            return this.themeService.theme == 'dark' ? '#353e48' : '#ccd3de';
        },
        enumerable: true,
        configurable: true
    });
    ConditionWorkflowStepItemComponent.prototype.updateConnections = function () {
        var containerBounds = this.containerElement.getBoundingClientRect();
        var leftChildBounds = this.el.nativeElement.getBoundingClientRect();
        if (leftChildBounds.left + leftChildBounds.width / 2 >= containerBounds.left + containerBounds.width / 2) {
            this.zigZagLeft = false;
            this.zigZagWidth =
                leftChildBounds.left + leftChildBounds.width / 2 - (containerBounds.left + containerBounds.width / 2) + 2;
            this.zigZagX = containerBounds.width / 2 - 1;
            this.cd.markForCheck();
        }
        else {
            this.zigZagLeft = true;
            this.zigZagWidth =
                containerBounds.left + containerBounds.width / 2 - (leftChildBounds.left + leftChildBounds.width / 2) + 2;
            this.zigZagX = containerBounds.width / 2 - this.zigZagWidth + 1;
            this.cd.markForCheck();
        }
    };
    ConditionWorkflowStepItemComponent.prototype.dragDrop = function (event) {
        if (event.previousContainer === event.container) {
            moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        }
        else {
            transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
        }
        this.workflowEditContext.markChanged();
    };
    ConditionWorkflowStepItemComponent.prototype.addStep = function (step, options) {
        if (options === void 0) { options = {}; }
        this.workflowEditContext.registerCreatedElement(step);
        if (isSet(options.addBeforeIndex)) {
            this.steps.splice(options.addBeforeIndex, 0, step);
        }
        else {
            this.steps.push(step);
        }
        this.cd.markForCheck();
        this.workflowEditContext.markChanged();
    };
    ConditionWorkflowStepItemComponent.prototype.duplicateStep = function (index) {
        var step = cloneDeep(this.steps[index]);
        var defaultName = "Copy of " + step.name;
        step.generateUid();
        step.name = cleanWorkflowStepName(defaultName, step, this.workflowEditContext.state.workflow.steps);
        this.workflowEditContext.registerCreatedElement(step);
        this.steps.splice(index + 1, 0, step);
        this.cd.markForCheck();
        this.workflowEditContext.markChanged();
    };
    ConditionWorkflowStepItemComponent.prototype.deleteStep = function (index) {
        this.steps.splice(index, 1);
        this.cd.markForCheck();
        this.workflowEditContext.markChanged();
    };
    ConditionWorkflowStepItemComponent.prototype.stepsHasNextConnection = function () {
        return !this.steps.length || last(this.steps).type != WorkflowStepType.Exit;
    };
    return ConditionWorkflowStepItemComponent;
}());
export { ConditionWorkflowStepItemComponent };

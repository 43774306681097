var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ChangeDetectorRef } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BasePopupComponent } from '@common/popups';
import { IntercomService, UniversalAnalyticsService } from '@modules/analytics';
import { ProjectTokenService, ResourceName } from '@modules/projects';
import { registerResourceSettingsComponent } from '../../../data/resource-settings-components';
import { ChooseSyncController } from '../../../services/choose-sync/choose-sync.controller';
import { BaseResourceSettingsComponent } from '../base-resource-settings/base-resource-settings.component';
import { PipedriveResourceSettingsForm } from './pipedrive-resource-settings.form';
var PipedriveResourceSettingsComponent = /** @class */ (function (_super) {
    __extends(PipedriveResourceSettingsComponent, _super);
    function PipedriveResourceSettingsComponent(form, chooseSyncController, popupComponent, projectTokenService, intercomService, analyticsService, cd) {
        var _this = _super.call(this, form, popupComponent, projectTokenService, intercomService, analyticsService, cd) || this;
        _this.form = form;
        _this.chooseSyncController = chooseSyncController;
        return _this;
    }
    PipedriveResourceSettingsComponent.prototype.chooseSync = function () {
        var _this = this;
        this.chooseSyncController
            .chooseSyncMode(this.form.form.controls['sync'], { typeItem: this.typeItem })
            .pipe(untilDestroyed(this))
            .subscribe(function () { return _this.submit(); });
    };
    return PipedriveResourceSettingsComponent;
}(BaseResourceSettingsComponent));
export { PipedriveResourceSettingsComponent };
registerResourceSettingsComponent(ResourceName.Pipedrive, PipedriveResourceSettingsComponent);

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgGxScrollableModule } from 'ng-gxscrollable';

import { TipsModule } from '@common/tips';
import { UniqueIdModule } from '@common/unique-id';
import { StoragesComponentsModule } from '@modules/storages-components';
import { SharedModule } from '@shared';

import { IconSelectorEmojisComponent } from './components/icon-selector-emojis/icon-selector-emojis.component';
import { IconSelectorIconsComponent } from './components/icon-selector-icons/icon-selector-icons.component';
import { IconSelectorImagesComponent } from './components/icon-selector-images/icon-selector-images.component';
import { IconSelectorStubComponent } from './components/icon-selector-stub/icon-selector-stub.component';
import { IconSelectorComponent } from './components/icon-selector/icon-selector.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    SharedModule,
    NgGxScrollableModule,
    TipsModule,
    UniqueIdModule,
    StoragesComponentsModule
  ],
  declarations: [
    IconSelectorComponent,
    IconSelectorEmojisComponent,
    IconSelectorIconsComponent,
    IconSelectorImagesComponent,
    IconSelectorStubComponent
  ],
  exports: [IconSelectorComponent]
})
export class IconsComponentsModule {}

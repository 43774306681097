/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../shared/pipes/is-set/is-set.pipe";
import * as i2 from "../../../../shared/components/loader/loader.component.ngfactory";
import * as i3 from "../../../../shared/components/loader/loader.component";
import * as i4 from "./loading-dialog.component";
var styles_LoadingDialogComponent = [];
var RenderType_LoadingDialogComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_LoadingDialogComponent, data: {} });
export { RenderType_LoadingDialogComponent as RenderType_LoadingDialogComponent };
export function View_LoadingDialogComponent_0(_l) { return i0.ɵvid(2, [i0.ɵpid(0, i1.IsSetPipe, []), (_l()(), i0.ɵeld(1, 0, null, null, 0, "div", [["class", "dialog-background"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 8, "div", [["class", "dialog-container"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 7, "div", [["class", "dialog"]], [[2, "dialog_fit", null], [2, "dialog_no-padding", null], [2, "theme-dark", null]], null, null, null, null)), i0.ɵppd(4, 1), i0.ɵppd(5, 1), i0.ɵppd(6, 1), i0.ɵppd(7, 1), (_l()(), i0.ɵeld(8, 0, null, null, 2, "app-loader", [], null, null, null, i2.View_LoaderComponent_0, i2.RenderType_LoaderComponent)), i0.ɵdid(9, 114688, null, 0, i3.LoaderComponent, [], { title: [0, "title"], compact: [1, "compact"] }, null), (_l()(), i0.ɵted(10, 0, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.currentOptions.title; var currVal_4 = true; _ck(_v, 9, 0, currVal_3, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (!i0.ɵunv(_v, 3, 0, _ck(_v, 4, 0, i0.ɵnov(_v, 0), _co.currentOptions.title)) && !i0.ɵunv(_v, 3, 0, _ck(_v, 5, 0, i0.ɵnov(_v, 0), _co.currentOptions.description))); var currVal_1 = (!i0.ɵunv(_v, 3, 1, _ck(_v, 6, 0, i0.ɵnov(_v, 0), _co.currentOptions.title)) && !i0.ɵunv(_v, 3, 1, _ck(_v, 7, 0, i0.ɵnov(_v, 0), _co.currentOptions.description))); var currVal_2 = _co.currentOptions.dark; _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2); var currVal_5 = _co.currentOptions.description; _ck(_v, 10, 0, currVal_5); }); }
export function View_LoadingDialogComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-loading-dialog", [], null, null, null, View_LoadingDialogComponent_0, RenderType_LoadingDialogComponent)), i0.ɵdid(1, 114688, null, 0, i4.LoadingDialogComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LoadingDialogComponentNgFactory = i0.ɵccf("app-loading-dialog", i4.LoadingDialogComponent, View_LoadingDialogComponent_Host_0, { options: "options" }, {}, []);
export { LoadingDialogComponentNgFactory as LoadingDialogComponentNgFactory };

import { Injectable } from '@angular/core';
import * as Sentry from '@sentry/browser';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { AppConfigService } from '@core';
import { ApiService, ServerRequestError } from '@modules/api';
import { ProjectApiService } from '@modules/project-api';
import { ProjectsStore } from '@modules/projects';
import { CurrentUserStore } from '@modules/users';
import { isSet } from '@shared';

import { AnalyticsEvent, AnalyticsEventAction, AnalyticsEventType } from '../../data/event';
import { TagManagerService } from '../tag-manager/tag-manager.service';

@Injectable({
  providedIn: 'root'
})
export class UniversalAnalyticsService {
  constructor(
    private tagManagerService: TagManagerService,
    private apiService: ApiService,
    private currentUserStore: CurrentUserStore,
    private projectsStore: ProjectsStore,
    private appConfigService: AppConfigService
  ) {
    this.logApiServiceErrors().subscribe();
  }

  logApiServiceErrors(): Observable<ServerRequestError> {
    return this.apiService.onError.pipe(
      tap(serverError => {
        this.sendEvent(AnalyticsEvent.GA.ApiError, AnalyticsEventAction.Emit, serverError.serialize());
      })
    );
  }

  logProjectApiServiceErrors(projectApiService: ProjectApiService): Observable<ServerRequestError> {
    return projectApiService.onError.pipe(
      tap(serverError => {
        this.sendEvent(AnalyticsEvent.GA.ProjectApiError, AnalyticsEventAction.Emit, serverError.serialize());
      })
    );
  }

  sendEvent(category: AnalyticsEventType, action: AnalyticsEventAction, label?: string, params = {}) {
    this.tagManagerService.sendEvent('event-to-ga', category, action, label, params);
  }

  sendSimpleEvent(category: AnalyticsEventType, params = {}) {
    const project = this.projectsStore.current;
    const user = this.currentUserStore.instance;
    const userId = user ? user.uid : undefined;
    const email = user ? user.email : undefined;
    const isStaff = user ? user.isStaff || (isSet(user.email) && user.email.endsWith('@jet.test')) : undefined;

    this.tagManagerService.sendEvent('product-event', category, undefined, undefined, {
      CurrentUserID: userId,
      UserProperties: {
        CurrentUserEmail: email,
        CurrentUserIsStaff: isStaff
      },
      EventProperties: {
        CurrentProjectID: project ? project.uniqueName : undefined,
        CurrentProjectDemo: project && project.demo,
        CurrentProjectVersion: this.appConfigService.version,
        AppMode: window['mode'],
        ...params
      }
    });

    Sentry.addBreadcrumb({
      category: 'product-event',
      message: category,
      level: Sentry.Severity.Info,
      data: params
    });
  }
}

import { FormArray } from '@angular/forms';
import isEqual from 'lodash/isEqual';
import range from 'lodash/range';

import { GradientStop } from '@modules/views';

import { GradientStopControl } from './gradient-stop.control';

export class GradientStopArray extends FormArray {
  controls: GradientStopControl[];

  deserialize(value: GradientStop[]) {
    value.forEach((item, i) => {
      const control = this.controls[i] || this.appendControl();
      control.deserialize(item);
    });

    this.controls.slice(value.length).forEach(item => this.removeControl(item));
  }

  getInstance(instances: GradientStop[] = []): GradientStop[] {
    return this.controls.map((control, i) => control.getInstance(instances[i]));
  }

  serialize(): GradientStop[] {
    return this.controls.map(control => control.getInstance(control.instance));
  }

  setControls(controls: GradientStopControl[]) {
    this.removeControls();
    controls.forEach(item => this.push(item));
  }

  removeControls() {
    range(this.controls.length).forEach(() => this.removeAt(0));
  }

  removeControl(control: GradientStopControl) {
    const newControls = this.controls.filter(item => !isEqual(item, control));
    this.setControls(newControls);
  }

  createControl(item?: GradientStop): GradientStopControl {
    const control = new GradientStopControl();

    if (!item) {
      item = new GradientStop();
      item.generateId();
    }

    control.deserialize(item);
    control.markAsPristine();

    return control;
  }

  appendControl(item?: GradientStop): GradientStopControl {
    const control = this.createControl(item);
    this.push(control);
    return control;
  }

  prependControl(item?: GradientStop): GradientStopControl {
    const control = this.createControl(item);
    this.insert(0, control);
    return control;
  }
}

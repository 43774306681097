import { ElementType, ListElementItem } from '@modules/customize';
import { Input } from '@modules/fields';
import { ListLayoutType } from '@modules/layouts';
import { isSet } from '@shared';

export function listComponentPostCreate(element: ListElementItem): ListElementItem {
  if (isSet(element.name)) {
    element.layouts.forEach(item => {
      item.titleInput = new Input().deserializeFromStatic('value', element.name);
    });
  }

  return element;
}

export const changeCustomizeBarComponentsLists = [
  {
    title: 'Table',
    image: 'table_list',
    action: 'Add Table',
    type: ElementType.List,
    defaultParams: {
      title: 'Table',
      layouts: [
        {
          type: ListLayoutType.Table,
          title: 'Table'
        }
      ]
    },
    postCreate: listComponentPostCreate
  },
  {
    title: 'Map',
    image: 'map_list',
    action: 'Add Map',
    type: ElementType.List,
    defaultParams: {
      title: 'Map',
      layouts: [
        {
          type: ListLayoutType.Map,
          title: 'Map'
        }
      ]
    },
    postCreate: listComponentPostCreate
  },
  {
    title: 'Kanban Board',
    image: 'kanban_list',
    action: 'Add Kanban Board',
    type: ElementType.List,
    defaultParams: {
      title: 'Kanban Board',
      layouts: [
        {
          type: ListLayoutType.KanbanBoard,
          title: 'Kanban Board'
        }
      ]
    },
    postCreate: listComponentPostCreate
  },
  {
    title: 'Calendar',
    image: 'calendar_list',
    action: 'Add Calendar',
    type: ElementType.List,
    defaultParams: {
      title: 'Calendar',
      layouts: [
        {
          type: ListLayoutType.Calendar,
          title: 'Calendar'
        }
      ]
    },
    postCreate: listComponentPostCreate
  },
  {
    title: 'Gallery',
    image: 'gallery_list2',
    action: 'Add Gallery',
    type: ElementType.List,
    defaultParams: {
      title: 'Gallery',
      layouts: [
        {
          type: ListLayoutType.Grid,
          title: 'Gallery'
        }
      ]
    },
    postCreate: listComponentPostCreate
  },
  {
    title: 'Carousel',
    image: 'carousel_list',
    action: 'Add Carousel',
    type: ElementType.List,
    defaultParams: {
      title: 'Carousel',
      layouts: [
        {
          type: ListLayoutType.Carousel,
          title: 'Carousel'
        }
      ]
    },
    postCreate: listComponentPostCreate
  },
  {
    title: 'Timeline',
    image: 'timeline_list',
    action: 'Add Timeline',
    type: ElementType.List,
    defaultParams: {
      title: 'Timeline',
      layouts: [
        {
          type: ListLayoutType.Timeline,
          title: 'Timeline'
        }
      ]
    },
    postCreate: listComponentPostCreate
  },
  {
    title: 'Filter',
    image: 'filter',
    action: 'Add Filter',
    type: ElementType.Filter,
    defaultParams: {}
  }
];

import { IsCurrentMonthFieldLookup } from './lookups/is_current_month';
import { IsCurrentQuarterFieldLookup } from './lookups/is_current_quarter';
import { IsCurrentWeekFieldLookup } from './lookups/is_current_week';
import { IsCurrentYearFieldLookup } from './lookups/is_current_year';
import { IsEmptyFieldLookup } from './lookups/is_empty';
import { IsFutureFieldLookup } from './lookups/is_future';
import { IsLastMonthFieldLookup } from './lookups/is_last_month';
import { IsLastQuarterFieldLookup } from './lookups/is_last_quarter';
import { IsLastWeekFieldLookup } from './lookups/is_last_week';
import { IsLastXDaysFieldLookup } from './lookups/is_last_x_days';
import { IsLastYearFieldLookup } from './lookups/is_last_year';
import { IsPastFieldLookup } from './lookups/is_past';
import { IsPreviousMonthFieldLookup } from './lookups/is_previous_month';
import { IsPreviousQuarterFieldLookup } from './lookups/is_previous_quarter';
import { IsPreviousWeekFieldLookup } from './lookups/is_previous_week';
import { IsPreviousXDaysFieldLookup } from './lookups/is_previous_x_days';
import { IsPreviousYearFieldLookup } from './lookups/is_previous_year';
import { IsTodayFieldLookup } from './lookups/is_today';
import { IsYesterdayFieldLookup } from './lookups/is_yesterday';

export const isEmptyFieldLookup = new IsEmptyFieldLookup();
export const isPastFieldLookup = new IsPastFieldLookup();
export const isFutureFieldLookup = new IsFutureFieldLookup();
export const isTodayFieldLookup = new IsTodayFieldLookup();
export const isYesterdayFieldLookup = new IsYesterdayFieldLookup();
export const isCurrentWeekFieldLookup = new IsCurrentWeekFieldLookup();
export const isPreviousWeekFieldLookup = new IsPreviousWeekFieldLookup();
export const isCurrentMonthFieldLookup = new IsCurrentMonthFieldLookup();
export const isPreviousMonthFieldLookup = new IsPreviousMonthFieldLookup();
export const isCurrentQuarterFieldLookup = new IsCurrentQuarterFieldLookup();
export const isPreviousQuarterFieldLookup = new IsPreviousQuarterFieldLookup();
export const isCurrentYearFieldLookup = new IsCurrentYearFieldLookup();
export const isPreviousYearFieldLookup = new IsPreviousYearFieldLookup();
export const isPreviousXDaysFieldLookup = new IsPreviousXDaysFieldLookup();
export const isLastWeekFieldLookup = new IsLastWeekFieldLookup();
export const isLastMonthFieldLookup = new IsLastMonthFieldLookup();
export const isLastQuarterFieldLookup = new IsLastQuarterFieldLookup();
export const isLastYearFieldLookup = new IsLastYearFieldLookup();
export const isLastXDaysFieldLookup = new IsLastXDaysFieldLookup();

export const computedLookups = [
  isEmptyFieldLookup,
  isPastFieldLookup,
  isFutureFieldLookup,
  isTodayFieldLookup,
  isYesterdayFieldLookup,
  isCurrentWeekFieldLookup,
  isPreviousWeekFieldLookup,
  isCurrentMonthFieldLookup,
  isPreviousMonthFieldLookup,
  isCurrentQuarterFieldLookup,
  isPreviousQuarterFieldLookup,
  isCurrentYearFieldLookup,
  isPreviousYearFieldLookup,
  isPreviousXDaysFieldLookup,
  isLastWeekFieldLookup,
  isLastMonthFieldLookup,
  isLastQuarterFieldLookup,
  isLastYearFieldLookup,
  isLastXDaysFieldLookup
];

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { timer } from 'rxjs';
import { debounceTime, switchMap } from 'rxjs/operators';

import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { CustomViewSettings, ViewContext, ViewSettings } from '@modules/customize';
import { PersistentIdGenerator } from '@modules/customize-generators';
import { ModelDescription } from '@modules/models';
import { CurrentEnvironmentStore, Resource } from '@modules/projects';
import {
  PageTemplate,
  PageTemplatesGeneratorService,
  PageTemplateType,
  PageVariantType
} from '@modules/template-generators';
import { controlValue } from '@shared';

import { PreviewFocus } from '../page-template-preview/page-template-preview.component';
import { PageTemplatesItemFieldArray } from './page-templates-item-field.array';
import { PageTemplatesItemSettingsForm } from './page-templates-item-settings.form';

interface SettingsStep {
  label: string;
  fieldsControl?: PageTemplatesItemFieldArray;
  // previewStyle?: string;
  previewFocus?: PreviewFocus;
}

@Component({
  selector: 'app-page-templates-item-settings',
  templateUrl: './page-templates-item-settings.component.html',
  providers: [ViewContext, PageTemplatesItemSettingsForm],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PageTemplatesItemSettingsComponent implements OnInit, OnDestroy {
  @Input() template: PageTemplate;
  @Input() templateVariant: PageVariantType;
  @Input() modelDescription: ModelDescription;
  @Input() cancelEnabled = false;
  @Input() analyticsSource: string;
  @Output() createdStartPage = new EventEmitter<ViewSettings>();
  @Output() backToTypeClick = new EventEmitter<void>();
  @Output() backToModelClick = new EventEmitter<void>();
  @Output() cancelClick = new EventEmitter<void>();

  resource: Resource;
  loadingSubmit = false;
  steps: SettingsStep[] = [];
  currentStepIndex = 0;
  initialStep = true;

  previewLoading = true;
  previewInit = true;
  startPage: CustomViewSettings;
  otherPages: CustomViewSettings[] = [];

  // previewStyle: SafeStyle;
  previewFit = false;

  constructor(
    public form: PageTemplatesItemSettingsForm,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private pageTemplatesGeneratorService: PageTemplatesGeneratorService,
    // private sanitizer: DomSanitizer,
    public context: ViewContext,
    private analyticsService: UniversalAnalyticsService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.currentEnvironmentStore.resources$.pipe(untilDestroyed(this)).subscribe(resources => {
      this.resource = resources.find(item => item.uniqueName == this.modelDescription.resource);
      this.cd.markForCheck();
    });

    this.form.init(this.template, this.templateVariant, this.modelDescription);

    const idGenerator = new PersistentIdGenerator();

    controlValue(this.form)
      .pipe(
        debounceTime(60),
        switchMap(() => this.form.createTemplatePages(idGenerator)),
        untilDestroyed(this)
      )
      .subscribe(result => {
        this.startPage = result ? result.startPage : undefined;
        this.otherPages = result ? result.pages.filter(item => item !== this.startPage) : [];
        this.context.viewSettings = this.startPage;
        this.steps = result ? this.getSteps() : [];
        // this.cd.markForCheck();
        this.cd.markForCheck();

        if (this.previewLoading) {
          this.previewLoading = false;
          this.cd.detectChanges();

          timer(600)
            .pipe(untilDestroyed(this))
            .subscribe(() => {
              // this.setCurrentStepIndex(0);
              this.previewInit = false;
              this.cd.markForCheck();
            });

          this.onStepOpened();
        }
      });
  }

  ngOnDestroy(): void {}

  getSteps(): SettingsStep[] {
    const steps: SettingsStep[] = [];

    // if (this.template.type == PageTemplateType.AdminPanelEdit || this.template.type == PageTemplateType.AdminPanelView) {
    // let previewFocus: PreviewFocus;
    //
    // if (this.templateVariant == PageVariantType.SinglePage) {
    //   previewFocus = {
    //     page: this.startPage,
    //     left: true
    //   };
    // } else if (this.templateVariant == PageVariantType.Popups) {
    //   previewFocus = {
    //     page: this.startPage
    //     // popup: this.startPage.popups[0]
    //   };
    // } else if (this.templateVariant == PageVariantType.SeparatePages) {
    //   previewFocus = {
    //     page: this.startPage
    //   };
    // }

    // steps.push({
    //   label: 'Table fields',
    //   fieldsControl: this.form.controls.listFields,
    //   previewFocus: previewFocus
    // });

    if (this.template.type == PageTemplateType.AdminPanelView) {
      if (this.templateVariant == PageVariantType.SinglePage) {
        steps.push({
          label: 'Table fields',
          fieldsControl: this.form.controls.listFields,
          previewFocus: {
            page: this.startPage,
            left: true
          }
        });

        steps.push({
          label: 'Detail fields',
          fieldsControl: this.form.controls.detailFields,
          previewFocus: {
            page: this.startPage,
            right: true
          }
        });
      } else if (this.templateVariant == PageVariantType.Popups) {
        steps.push({
          label: 'Table fields',
          fieldsControl: this.form.controls.listFields,
          previewFocus: {
            page: this.startPage
          }
        });

        steps.push({
          label: 'Detail fields',
          fieldsControl: this.form.controls.detailFields,
          previewFocus: {
            page: this.startPage,
            popup: this.startPage.popups[0]
          }
        });
      } else if (this.templateVariant == PageVariantType.SeparatePages) {
        steps.push({
          label: 'Table fields',
          fieldsControl: this.form.controls.listFields,
          previewFocus: {
            page: this.startPage
          }
        });

        steps.push({
          label: 'Detail page',
          fieldsControl: this.form.controls.detailFields,
          previewFocus: {
            page: this.otherPages[0]
          }
        });
      }
    } else if (this.template.type == PageTemplateType.AdminPanelEdit) {
      if (this.templateVariant == PageVariantType.SinglePage) {
        steps.push({
          label: 'Table fields',
          fieldsControl: this.form.controls.listFields,
          previewFocus: {
            page: this.startPage,
            left: true
          }
        });

        steps.push({
          label: 'Form fields',
          fieldsControl: this.form.controls.updateFields,
          previewFocus: {
            page: this.startPage,
            right: true
          }
        });
      } else if (this.templateVariant == PageVariantType.Popups) {
        steps.push({
          label: 'Table fields',
          fieldsControl: this.form.controls.listFields,
          previewFocus: {
            page: this.startPage
          }
        });

        steps.push({
          label: 'Form fields',
          fieldsControl: this.form.controls.updateFields,
          previewFocus: {
            page: this.startPage,
            popup: this.startPage.popups[0]
          }
        });
      } else if (this.templateVariant == PageVariantType.SeparatePages) {
        steps.push({
          label: 'Table fields',
          fieldsControl: this.form.controls.listFields,
          previewFocus: {
            page: this.startPage
          }
        });

        steps.push({
          label: 'Create page',
          fieldsControl: this.form.controls.createFields,
          previewFocus: {
            page: this.otherPages[0]
          }
        });

        steps.push({
          label: 'Update page',
          fieldsControl: this.form.controls.updateFields,
          previewFocus: {
            page: this.otherPages[1]
          }
        });
      }
    } else if (this.template.type == PageTemplateType.CreateForm) {
      steps.push({
        label: 'Create fields',
        fieldsControl: this.form.controls.createFields,
        previewFocus: {
          page: this.startPage
        }
      });
    } else if (this.template.type == PageTemplateType.Dashboard) {
      steps.push({
        label: 'Table fields',
        fieldsControl: this.form.controls.listFields,
        previewFocus: {
          page: this.startPage
        }
      });
    }

    return steps;
  }

  get currentStep(): SettingsStep {
    return this.steps[this.currentStepIndex];
  }

  setCurrentStepIndex(index: number) {
    if (this.currentStepIndex === index) {
      return;
    }

    this.currentStepIndex = index;
    // this.previewStyle =
    //   this.currentStep && this.currentStep.previewStyle
    //     ? this.sanitizer.bypassSecurityTrustStyle(this.currentStep.previewStyle)
    //     : undefined;
    // this.previewFit = false;
    this.cd.markForCheck();

    this.onStepOpened();
  }

  prevStep() {
    this.setCurrentStepIndex(this.currentStepIndex - 1);
  }

  nextStep() {
    this.onStepFinished();

    if (this.currentStepIndex == this.steps.length - 1) {
      this.submit();
      return;
    }

    const currentStep = this.currentStep;
    // const firstStep = this.currentStepIndex === 0;

    this.setCurrentStepIndex(this.currentStepIndex + 1);

    if (this.initialStep && currentStep.fieldsControl === this.form.controls.listFields) {
      this.initialStep = false;

      this.steps
        .slice(1)
        .map(item => item.fieldsControl)
        .filter(item => !!item)
        .forEach(array => {
          array.controls.forEach(control => {
            const listControl = this.form.controls.listFields.controls.find(
              item => item.instance.name == control.instance.name
            );
            if (listControl && control.controls.enabled.value !== listControl.controls.enabled.value) {
              control.controls.enabled.patchValue(listControl.controls.enabled.value);
            }
          });
        });
    }
  }

  onStepOpened() {
    if (this.currentStep) {
      const resource = this.modelDescription
        ? this.currentEnvironmentStore.resources.find(item => item.uniqueName == this.modelDescription.resource)
        : undefined;

      this.analyticsService.sendSimpleEvent(AnalyticsEvent.PageTemplates.StartedCustomizeFields, {
        Fields: this.currentStep.label,
        Template: this.template ? this.template.type : undefined,
        TemplateVariant: this.templateVariant,
        ResourceType: resource ? resource.typeItem.name : undefined,
        Resource: resource ? resource.uniqueName : undefined,
        Source: this.analyticsSource
      });
    }
  }

  onStepFinished() {
    if (this.currentStep) {
      const resource = this.modelDescription
        ? this.currentEnvironmentStore.resources.find(item => item.uniqueName == this.modelDescription.resource)
        : undefined;

      this.analyticsService.sendSimpleEvent(AnalyticsEvent.PageTemplates.FinishedCustomizeFields, {
        Fields: this.currentStep.label,
        Template: this.template ? this.template.type : undefined,
        TemplateVariant: this.templateVariant,
        ResourceType: resource ? resource.typeItem.name : undefined,
        Resource: resource ? resource.uniqueName : undefined,
        Source: this.analyticsSource
      });
    }
  }

  togglePreviewFit() {
    this.previewFit = !this.previewFit;
    this.cd.markForCheck();
  }

  submit() {
    if (this.loadingSubmit) {
      return;
    }

    this.loadingSubmit = true;
    this.cd.markForCheck();

    this.form
      .submit()
      .pipe(untilDestroyed(this))
      .subscribe(
        startPage => {
          if (startPage) {
            this.createdStartPage.emit(startPage);
          } else {
            this.loadingSubmit = false;
            this.cd.markForCheck();
          }
        },
        () => {
          this.loadingSubmit = false;
          this.cd.markForCheck();
        }
      );
  }
}

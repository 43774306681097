var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import isEqual from 'lodash/isEqual';
import { isSet } from '@shared';
export function getSectionTokens(sections) {
    return sections.reduce(function (acc, section) {
        section.items.forEach(function (item) {
            if (item.item) {
                acc.push(item.item);
            }
            else if (item.section) {
                acc.push.apply(acc, getSectionTokens([item.section]));
            }
        });
        return acc;
    }, []);
}
export function findSectionToken(sections, options) {
    for (var _i = 0, sections_1 = sections; _i < sections_1.length; _i++) {
        var section = sections_1[_i];
        for (var _a = 0, _b = section.items; _a < _b.length; _a++) {
            var item = _b[_a];
            if (!options.predicate || options.predicate(item)) {
                return item;
            }
            else if (item.section && options.nested) {
                var matchItem = findSectionToken([item.section], options);
                if (matchItem) {
                    return matchItem;
                }
            }
        }
    }
}
export function getFormulaTokens(formula) {
    var regex = /(?:(?:\[[^\]]+\])|[\w.])+/g;
    var positions = [];
    var m;
    while ((m = regex.exec(formula))) {
        if (m[0]) {
            positions.push({
                value: m[0],
                index: m.index,
                length: m[0].length
            });
        }
    }
    return positions;
}
export function detectFormulaToken(formula, index) {
    var positions = getFormulaTokens(formula);
    return positions.find(function (item) {
        var searchIndex = index;
        var searchChar = formula.substring(searchIndex, searchIndex + 1);
        if (searchChar == '(') {
            --searchIndex;
        }
        return searchIndex >= item.index && searchIndex < item.index + item.length;
    });
}
export function searchFormulaSections(sections, search) {
    if (search === void 0) { search = ''; }
    if (!isSet(search)) {
        return sections;
    }
    var processQuery = function (q) {
        return String(q).toLowerCase().replace(/[_-]/g, ' ').replace(/\s\s+/g, ' ').trim();
    };
    var query = processQuery(search);
    var matchQuery = function (str, q) {
        if (!isSet(str)) {
            return;
        }
        var index = processQuery(str).indexOf(q);
        if (index == -1) {
            return;
        }
        return {
            marked: [
                str.substring(0, index),
                '<em>',
                str.substring(index, index + q.length),
                '</em>',
                str.substring(index + q.length)
            ].join('')
        };
    };
    var searchSection = function (rootItems) {
        var searchItems = function (items, path) {
            return items.reduce(function (acc, item) {
                if (item.section) {
                    var itemPath = path.concat([item.section.label]);
                    var match = matchQuery(item.section.label, query);
                    if (match) {
                        acc.push({
                            path: item.path,
                            section: __assign({}, item.section, { name: match.marked }),
                            subtitle: item.subtitle
                        });
                    }
                    acc.push.apply(acc, searchItems(item.section.items, itemPath));
                }
                else if (item.item) {
                    var labelSecondary = path.join(' · ');
                    var matchLabel = matchQuery(item.item.label, query);
                    var matchLabelSecondary = matchQuery(labelSecondary, query);
                    var matchInsert = matchQuery(item.item.insert ? item.item.insert.join('.') : undefined, query);
                    if (matchLabel) {
                        acc.push({
                            path: item.path,
                            item: __assign({}, item.item, { label: matchLabel.marked, labelPlain: item.item.label, labelSecondary: labelSecondary }),
                            subtitle: item.subtitle
                        });
                    }
                    else if (matchLabelSecondary) {
                        acc.push({
                            path: item.path,
                            item: __assign({}, item.item, { labelSecondary: matchLabelSecondary.marked }),
                            subtitle: item.subtitle
                        });
                    }
                    else if (matchInsert) {
                        acc.push({
                            path: item.path,
                            item: __assign({}, item.item, { labelSecondary: labelSecondary }),
                            subtitle: item.subtitle
                        });
                    }
                }
                return acc;
            }, []);
        };
        return searchItems(rootItems, []);
    };
    return sections
        .map(function (section) {
        return {
            label: section.label,
            icon: section.icon,
            items: searchSection(section.items).filter(function (item) { return item.item || item.section.items.length; })
        };
    })
        .filter(function (section) { return section.items.length; });
}
export function formulaTokensEqual(lhs, rhs) {
    return isEqual(lhs.path, rhs.path);
}
export function findFormulaTokenSibling(sections, token, next) {
    var prevToken = null;
    for (var _i = 0, sections_2 = sections; _i < sections_2.length; _i++) {
        var section = sections_2[_i];
        for (var _a = 0, _b = section.items; _a < _b.length; _a++) {
            var item = _b[_a];
            if (next && prevToken && formulaTokensEqual(prevToken, token)) {
                return item;
            }
            else if (!next && formulaTokensEqual(item, token)) {
                return prevToken;
            }
            prevToken = item;
        }
    }
}

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { isSet } from '@shared';
import { Automation } from './automation';
import { Workflow } from './workflow';
import { WorkflowBackendRun } from './workflow-backend-run';
var WorkflowBackendRunWithRelations = /** @class */ (function (_super) {
    __extends(WorkflowBackendRunWithRelations, _super);
    function WorkflowBackendRunWithRelations(options) {
        if (options === void 0) { options = {}; }
        return _super.call(this, options) || this;
    }
    WorkflowBackendRunWithRelations.prototype.deserialize = function (data) {
        _super.prototype.deserialize.call(this, data);
        if (data['automation']) {
            this.automation = new Automation().deserialize(data['automation']);
        }
        if (data['workflow']) {
            this.workflow = new Workflow().deserialize(data['workflow']['params']);
        }
        return this;
    };
    WorkflowBackendRunWithRelations.prototype.getName = function () {
        var name = [];
        if (this.automation && isSet(this.automation.name)) {
            name.push(this.automation.name);
        }
        else if (this.workflow && isSet(this.workflow.name)) {
            name.push(this.workflow.name);
        }
        name.push(this.getLastDate().format('lll'));
        return name.join(' - ');
    };
    return WorkflowBackendRunWithRelations;
}(WorkflowBackendRun));
export { WorkflowBackendRunWithRelations };

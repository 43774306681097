<div class="video-popup-background" #background></div>
<div class="video-popup-container" (click)="$event.target == container ? miss() : null" #container>
  <div class="video-popup">
    <div class="video-popup__bounds" #root>
      <div class="video-popup__inner">
        <video *ngIf="src" playsinline controls muted loop #video>
          <source [src]="src | appSafeUrl" type="video/mp4" />
        </video>
        <iframe
          *ngIf="youtubeId"
          width="100%"
          height="100%"
          [src]="getYoutubeLink() | appSafeUrl"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        >
        </iframe>
      </div>
    </div>
    <span *ngIf="title" class="video-popup__title" #title_element></span>
    <a href="javascript:void(0)" (click)="close()" class="video-popup__close" #close_element
      ><span class="icon icon-close"></span> close video</a
    >
    <span class="video-popup__buttons" #buttons_element>
      <ng-container *ngFor="let item of links">
        <a
          *ngIf="item.href"
          [href]="item.href"
          class="video-popup__button"
          [class.video-popup__button_primary]="item.primary"
          (click)="onLinkClick(item)"
          target="_blank"
        >
          {{ item.label }}
        </a>
        <a
          *ngIf="item.link"
          [appLink]="item.link"
          class="video-popup__button"
          [class.video-popup__button_primary]="item.primary"
          (click)="onLinkClick(item)"
        >
          {{ item.label }}
        </a>
        <a
          *ngIf="!item.link && !item.href"
          href="javascript:void(0)"
          class="video-popup__button"
          [class.video-popup__button_primary]="item.primary"
          (click)="onLinkClick(item)"
        >
          {{ item.label }}
        </a>
      </ng-container>
    </span>
  </div>
</div>

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { FormUtils } from '@common/form-utils';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { SlackChannelStore, SlackService } from '@modules/slack';
import { CurrentUserStore } from '@modules/users';
import { setControlDisabled, stripStart } from '@shared';
var SlackJoinPopupForm = /** @class */ (function (_super) {
    __extends(SlackJoinPopupForm, _super);
    function SlackJoinPopupForm(currentProjectStore, currentEnvironmentStore, currentUserStore, formUtils, fb, slackService, slackChannelStore) {
        var _this = _super.call(this, {
            email: new FormControl('', [Validators.required, Validators.email]),
            channel: new FormControl('', Validators.required)
        }) || this;
        _this.currentProjectStore = currentProjectStore;
        _this.currentEnvironmentStore = currentEnvironmentStore;
        _this.currentUserStore = currentUserStore;
        _this.formUtils = formUtils;
        _this.fb = fb;
        _this.slackService = slackService;
        _this.slackChannelStore = slackChannelStore;
        _this.joined = false;
        return _this;
    }
    SlackJoinPopupForm.prototype.init = function (options) {
        this.channel = options.slackChannel;
        this.joined = options.slackChannel && options.slackChannel.isJoined();
        var channelUserEmail = options.slackChannel && options.slackChannel.channelUser
            ? options.slackChannel.channelUser.email
            : this.currentUserStore.instance.email;
        var channelNamePrefix = options.slackChannel ? options.slackChannel.channelPrefix : '';
        var channelName = options.slackChannel && options.slackChannel.channel
            ? stripStart(options.slackChannel.channel.name, channelNamePrefix)
            : this.currentProjectStore.instance.uniqueName;
        this.controls.email.patchValue(channelUserEmail);
        this.controls.channel.patchValue(channelName);
        setControlDisabled(this.controls.email, this.joined);
        setControlDisabled(this.controls.channel, this.joined);
        this.markAsPristine();
    };
    SlackJoinPopupForm.prototype.getSlackJoinLink = function () {
        return this.slackService.getSlackJoinLink(this.currentProjectStore.instance.uniqueName, this.currentEnvironmentStore.instance.uniqueName);
    };
    SlackJoinPopupForm.prototype.joinSlackChannel = function () {
        var _this = this;
        this.markAsDirty();
        var email = this.controls.email.value;
        var channel = this.controls.channel.value;
        return this.slackService
            .joinSlackChannel(this.currentProjectStore.instance.uniqueName, this.currentEnvironmentStore.instance.uniqueName, email, channel)
            .pipe(tap(function (result) {
            _this.slackChannelStore.set(result);
            _this.init({ slackChannel: result });
            _this.markAsPristine();
        }), catchError(function (error) {
            _this.formUtils.showFormErrors(_this, error);
            return throwError(error);
        }));
    };
    return SlackJoinPopupForm;
}(FormGroup));
export { SlackJoinPopupForm };

import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { ResourceTypeItem } from '@modules/projects';

@Component({
  selector: 'app-resource-settings-stub',
  templateUrl: './resource-settings-stub.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResourceSettingsStubComponent implements OnInit {
  @Input() typeItem: ResourceTypeItem;
  @Input() sideInfo = false;
  @Input() wide = false;

  constructor() {}

  ngOnInit() {}
}

var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { ChangeDetectorRef, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { merge } from 'rxjs';
import { IgnoreScrollTop } from '@common/route-scroll';
import { CustomizeService, WithBuilderMode } from '@modules/customize';
import { RoutingService } from '@modules/routing';
import { ListComponent } from '../list/list.component';
var ɵ0 = function (newSnapshot, prevSnapshot) {
    var model = newSnapshot.params['model'];
    var prevModel = prevSnapshot ? prevSnapshot.params['model'] : undefined;
    return model == prevModel;
};
var ListRouteComponent = /** @class */ (function () {
    function ListRouteComponent(customizeService, activatedRoute, routing, cd) {
        this.customizeService = customizeService;
        this.activatedRoute = activatedRoute;
        this.routing = routing;
        this.cd = cd;
        this.params = {};
    }
    ListRouteComponent.prototype.ngOnInit = function () {
        var _this = this;
        merge(this.activatedRoute.params, this.activatedRoute.queryParams)
            .pipe(untilDestroyed(this))
            .subscribe(function () {
            var snapshot = _this.activatedRoute.snapshot;
            _this.modelId = snapshot.params['model'];
            _this.params = snapshot.queryParams;
            _this.cd.markForCheck();
        });
    };
    ListRouteComponent.prototype.ngOnDestroy = function () { };
    ListRouteComponent.prototype.onBeforeDestroy = function () {
        if (this.listComponent) {
            this.listComponent.onBeforeDestroy();
        }
    };
    ListRouteComponent.prototype.getCollaborationParams = function () {
        var snapshot = this.activatedRoute.snapshot;
        return {
            object_type: ['model', snapshot.params['model']].join('.')
        };
    };
    ListRouteComponent.prototype.getUserActivitiesParams = function () {
        var snapshot = this.activatedRoute.snapshot;
        return {
            object_type: ['model', snapshot.params['model']].join('.')
        };
    };
    ListRouteComponent.prototype.onParamsChanged = function (params) {
        this.routing.navigateApp(['models', this.modelId], {
            queryParams: params
        });
    };
    ListRouteComponent = __decorate([
        WithBuilderMode,
        IgnoreScrollTop({
            predicate: ɵ0
        }),
        __metadata("design:paramtypes", [CustomizeService,
            ActivatedRoute,
            RoutingService,
            ChangeDetectorRef])
    ], ListRouteComponent);
    return ListRouteComponent;
}());
export { ListRouteComponent };
export { ɵ0 };

<app-page-block *ngIf="customizable">
  <div class="field">
    <label class="field__label">
      <span class="field__label-text">Variables</span>
      <span class="field__label-optional">(optional)</span>
    </label>

    <div class="field__value">
      <div cdkDropList [cdkDropListLockAxis]="'y'" (cdkDropListDropped)="dragDrop($event)">
        <app-page-block
          *ngFor="let item of control.controls; let i = index"
          cdkDrag
          [marginSize]="'xs'"
          [paddingSize]="'none'"
        >
          <app-parameter-values-item
            [control]="item"
            [openedInitial]="item === lastAddedParameterControl"
            [collapseContext]="collapseContext"
            (deleteRequested)="removeItem(item)"
          ></app-parameter-values-item>
        </app-page-block>
      </div>

      <app-page-block *ngIf="!control.controls.length" [marginSize]="'xs'" [paddingSize]="'none'">
        <div class="sidebar-list-item sidebar-list-item_clickable sidebar-list-item_disabled" (click)="addItem()">
          <div class="sidebar-list-item__drag"></div>
          <div class="sidebar-list-item__left">
            <span class="button button_icon button_orange button_extra-small" style="margin: 0;">
              <span class="icon-text button__icon"></span>
            </span>
          </div>
          <div class="sidebar-list-item__main">
            <div class="sidebar-list-item__title">Variable</div>
            <div class="sidebar-list-item__description">Not specified</div>
          </div>
        </div>
      </app-page-block>

      <app-page-block *ngIf="control.controls.length" [marginSize]="'xs'" [paddingSize]="'none'">
        <a
          href="javascript:void(0)"
          class="button button_orange-transparent button_small"
          style="margin: 0;"
          (click)="addItem()"
        >
          <span class="icon icon-plus button__icon button__icon_left"></span>
          <span class="button__label">Add Variable</span>
        </a>
      </app-page-block>
    </div>
  </div>
</app-page-block>

<ng-container *ngIf="!customizable">
  <app-page-block *ngFor="let item of control.controls">
    <app-auto-field
      [form]="item"
      [field]="
        createField({
          name: 'value',
          label: item.controls.name.value,
          field: item.controls.field.value,
          params: getItemParams(item.controls.params.value),
          required: item.instance?.required,
          placeholder: item.instance?.placeholder || 'Enter variable value'
        })
      "
    ></app-auto-field>
  </app-page-block>
</ng-container>

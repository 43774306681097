var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ChangeDetectorRef, EventEmitter, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import * as moment from 'moment';
import { TintStyle } from '@modules/actions';
import { DATE_PARAM, TYPE_PARAM } from '@modules/models';
import { CalendarType } from '../../data/calendar-type';
var CalendarHeaderComponent = /** @class */ (function () {
    function CalendarHeaderComponent(cd) {
        this.cd = cd;
        this.paramsChanged = new EventEmitter();
        this.calendarTypes = CalendarType;
        this.tintStyles = TintStyle;
    }
    CalendarHeaderComponent.prototype.ngOnInit = function () { };
    CalendarHeaderComponent.prototype.ngOnDestroy = function () { };
    CalendarHeaderComponent.prototype.ngOnChanges = function (changes) {
        this.prevParams = {};
        this.nextParams = {};
        this.nowParams = {};
        if (this.type == CalendarType.Day) {
            this.title = this.date.format('D MMMM, YYYY');
            this.prevParams[DATE_PARAM] = this.date.clone().startOf('date').subtract(1, 'day').toISOString();
            this.nextParams[DATE_PARAM] = this.date.clone().startOf('date').add(1, 'day').toISOString();
        }
        else if (this.type == CalendarType.Week) {
            this.title = this.date.clone().startOf('isoWeek').format('D MMMM, YYYY');
            this.prevParams[DATE_PARAM] = this.date.clone().startOf('isoWeek').subtract(1, 'week').toISOString();
            this.nextParams[DATE_PARAM] = this.date.clone().startOf('isoWeek').add(1, 'week').toISOString();
        }
        else if (this.type == CalendarType.Month) {
            this.title = this.date.format('MMMM, YYYY');
            this.prevParams[DATE_PARAM] = this.date.clone().startOf('month').subtract(1, 'month').toISOString();
            this.nextParams[DATE_PARAM] = this.date.clone().startOf('month').add(1, 'month').toISOString();
        }
        else if (this.type == CalendarType.Year) {
            this.title = this.date.format('YYYY');
            this.prevParams[DATE_PARAM] = this.date.clone().startOf('year').subtract(1, 'year').toISOString();
            this.nextParams[DATE_PARAM] = this.date.clone().startOf('year').add(1, 'year').toISOString();
        }
        this.nowParams[DATE_PARAM] = this.now.clone().startOf('date').toISOString();
        this.cd.markForCheck();
    };
    Object.defineProperty(CalendarHeaderComponent.prototype, "now", {
        get: function () {
            return moment();
        },
        enumerable: true,
        configurable: true
    });
    CalendarHeaderComponent.prototype.applyParams = function (params) {
        return __assign({}, this.params, params);
    };
    CalendarHeaderComponent.prototype.typeParams = function (type) {
        var params = {};
        params[TYPE_PARAM] = type;
        return this.applyParams(params);
    };
    CalendarHeaderComponent.prototype.setParams = function (params) {
        this.paramsChanged.emit(this.applyParams(params));
    };
    return CalendarHeaderComponent;
}());
export { CalendarHeaderComponent };

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ComponentFactoryResolver, Injector } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { CLOSE_BY_BACKGROUND_CLICK, ThinDialogPopupComponent } from '@common/dialog-popup';
import { CLOSE_BY_DEACTIVATE, PopupService } from '@common/popups';
import { StorageUploadPopupComponent } from '../../components/storage-upload-popup/storage-upload-popup.component';
var StorageUploadPopupController = /** @class */ (function () {
    function StorageUploadPopupController(popupService) {
        this.popupService = popupService;
    }
    StorageUploadPopupController.prototype.upload = function (options) {
        var obs$ = new ReplaySubject();
        var getCancelledState = function () {
            return {
                files: options.files.map(function (item) {
                    return {
                        file: item,
                        path: (options.path || []).concat([item.name]).join('/'),
                        uploadProgress: 0
                    };
                }),
                processed: 0,
                succeeded: 0,
                failed: 0,
                cancelled: true
            };
        };
        this.popupService.push({
            component: StorageUploadPopupComponent,
            popupComponent: ThinDialogPopupComponent,
            popupComponentOrange: true,
            inputs: __assign({ files: options.files, resource: options.resource, storage: options.storage }, (options.path && {
                path: options.path
            })),
            outputs: {
                finished: [
                    function (result) {
                        obs$.next(result);
                    }
                ],
                cancelled: [
                    function (result) {
                        obs$.next(result);
                    }
                ]
            },
            popupClosed: function (data) {
                if (data == CLOSE_BY_BACKGROUND_CLICK || data == CLOSE_BY_DEACTIVATE) {
                    obs$.next(getCancelledState());
                }
            },
            resolver: options.resolver,
            injector: options.injector
        });
        return obs$;
    };
    return StorageUploadPopupController;
}());
export { StorageUploadPopupController };

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { removeElement } from '@shared';

@Injectable({
  providedIn: 'root'
})
export class PageLoadingService {
  private _loaders = new BehaviorSubject<any[]>([]);

  constructor() {}

  hasLoader(loader: any) {
    return this._loaders.value.some(item => item === loader);
  }

  hasLoaders$(): Observable<boolean> {
    return this._loaders.pipe(map(value => !!value.length));
  }

  addLoader(loader: any) {
    if (!this.hasLoader(loader)) {
      this._loaders.next([...this._loaders.value, loader]);
    }
  }

  removeLoader(loader: any) {
    if (this.hasLoader(loader)) {
      this._loaders.next(this._loaders.value.filter(item => item !== loader));
    }
  }

  removeAppLoader() {
    removeElement(document.getElementsByTagName('app-preloader')[0]);
  }
}

import isArray from 'lodash/isArray';
import isPlainObject from 'lodash/isPlainObject';

import { Resource } from '@modules/projects';
import { Deserializable } from '@shared';

import { DataSyncJobTask } from './data-sync-job-task';

export enum DataSyncJobReadMode {
  Incremental = 'Incremental',
  Timestamp = 'Timestamp',
  Checksum = 'Checksum',
  XMIN = 'XMIN',
  ALL = 'ALL'
}

export enum DataSyncJobWriteMode {
  Append = 'Append',
  Overwrite = 'Overwrite',
  OverwriteDelete = 'OverwriteDelete',
  FullRewrite = 'FullRewrite'
}

export class DataSyncJob implements Deserializable<DataSyncJob> {
  id: string;
  sourceResource: Resource;
  useAllModelDescriptions = false;
  modelDescriptions: { name: string }[] = [];
  runInterval: number;
  readMode: DataSyncJobReadMode;
  writeMode: DataSyncJobWriteMode;
  createdAt: Date;
  updatedAt: Date;
  lastTask: DataSyncJobTask;
  structureInitialized = false;
  active = false;

  deserialize(data: Object): DataSyncJob {
    this.id = data['id'];
    this.useAllModelDescriptions = data['useAllModelDescriptions'];
    this.runInterval = data['runInterval'];
    this.readMode = data['readMode'];
    this.writeMode = data['writeMode'];
    this.createdAt = data['createdAt'];
    this.updatedAt = data['updatedAt'];
    this.structureInitialized = data['structureInitialized'];
    this.active = data['active'];

    if (data['modelDescriptions'] && isArray(data['modelDescriptions'])) {
      this.modelDescriptions = data['modelDescriptions'].map(item => {
        return {
          name: item['name']
        };
      });
    }

    if (data['sourceResource'] && isPlainObject(data['sourceResource'])) {
      this.sourceResource = new Resource().deserialize(data['sourceResource']);
    }

    if (data['lastTask']) {
      this.lastTask = new DataSyncJobTask().deserialize(data['lastTask']);
    }

    return this;
  }

  getStatusLabel(): string {
    if (!this.active) {
      return 'paused';
    }

    const lastTaskStatus = this.lastTask ? this.lastTask.getStatusLabel() : undefined;

    if (lastTaskStatus) {
      return lastTaskStatus;
    } else {
      return 'pending';
    }
  }
}

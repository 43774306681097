<div
  class="sidebar-collapse"
  [class.sidebar-collapse_openable]="true"
  [class.sidebar-collapse_block]="true"
  [class.sidebar-collapse_opened]="opened"
  [class.sidebar-collapse_disabled]="false"
  (mouseenter)="hovered$.next(true)"
  (mouseleave)="hovered$.next(false)"
>
  <div class="sidebar-collapse__header" [class.sidebar-collapse__header_error]="false" (click)="toggleOpened()">
    <div class="sidebar-collapse__header-left">
      <div class="sidebar-collapse__header-drag"></div>
    </div>
    <div class="sidebar-collapse__header-main">
      <div *ngIf="opened" class="sidebar-collapse__header-title sidebar-collapse__header-title_editable">
        <input
          *ngIf="rename"
          class="editable-content-input"
          [class.editable-content-input_inline]="false"
          [formControl]="renameControl"
          [placeholder]="defaultTitle"
          [appAutofocus]="true"
          (keyup)="onKeyUp($event)"
          (keypress)="onKeyPress($event)"
          (blur)="setRename(false)"
          (click)="$event.stopPropagation()"
        />

        <span
          *ngIf="!rename"
          class="editable-content-label"
          [class.editable-content-label_inline]="true"
          [class.editable-content-label_with-button]="true"
          (click)="$event.stopPropagation(); setRename(true)"
        >
          {{ title }}

          <span
            *ngIf="hovered$ | async"
            class="editable-content-button"
            [appTip]="'Rename Condition'"
            [appTipOptions]="{ side: 'top' }"
          >
            <span class="icon icon-pen"></span>
          </span>
        </span>
      </div>

      <ng-container *ngIf="!opened">
        <div class="sidebar-collapse__header-title">
          {{ title }}
        </div>

        <div
          *ngIf="(description | appIsSet) && !opened"
          class="sidebar-collapse__header-description"
          [class.sidebar-collapse__header-description_wrap]="true"
        >
          {{ description }}
        </div>
      </ng-container>
    </div>
    <div class="sidebar-collapse__header-right">
      <a
        href="javascript:void(0)"
        class="sidebar-list-item__action icon-bin"
        (click)="$event.stopPropagation(); remove.emit()"
      >
      </a>
    </div>
  </div>
  <div *ngIf="opened" class="sidebar-collapse__content" [class.sidebar-collapse__content_padding]="false">
    <div style="padding: 10px 0;">
      <div class="sidebar__element sidebar__element_margin_xxs">
        <app-sidebar-field>
          <app-input-edit
            [itemForm]="control.controls.condition"
            [context]="context"
            [staticValueDisabled]="true"
            [focusedInitial]="true"
            [placeholder]="'Value'"
            [fill]="true"
          ></app-input-edit>

          <ng-container description>
            Example: EQ(steps.query1.type, 'new')
          </ng-container>
        </app-sidebar-field>
      </div>
    </div>
  </div>
</div>

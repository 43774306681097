/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/cdk/portal";
import * as i2 from "@angular/common";
import * as i3 from "../../../../shared/pipes/safe-style/safe-style.pipe";
import * as i4 from "@angular/platform-browser";
import * as i5 from "./view-editor-viewport-overlays.component";
import * as i6 from "../../services/customize-toolbar-bottom/view-editor-viewport-overlay.service";
var styles_ViewEditorViewportOverlaysComponent = [];
var RenderType_ViewEditorViewportOverlaysComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ViewEditorViewportOverlaysComponent, data: {} });
export { RenderType_ViewEditorViewportOverlaysComponent as RenderType_ViewEditorViewportOverlaysComponent };
function View_ViewEditorViewportOverlaysComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(0, null, null, 0))], null, null); }
function View_ViewEditorViewportOverlaysComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["class", "view-editor-viewport__overlay-item"]], [[2, "view-editor-viewport__overlay-interactive", null], [4, "left", "em"], [4, "top", "em"], [4, "width", "em"], [4, "height", "em"], [4, "transform", null]], null, null, null, null)), i0.ɵppd(1, 1), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ViewEditorViewportOverlaysComponent_3)), i0.ɵdid(3, 212992, null, 0, i1.CdkPortalOutlet, [i0.ComponentFactoryResolver, i0.ViewContainerRef], { portal: [0, "portal"] }, null)], function (_ck, _v) { var currVal_6 = _v.parent.context.$implicit.portal; _ck(_v, 3, 0, currVal_6); }, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.options.interactive; var currVal_1 = (((((_v.parent.context.$implicit.options.frame == null) ? null : _v.parent.context.$implicit.options.frame.x) || 0) + (((_v.parent.context.$implicit.options.translate == null) ? null : _v.parent.context.$implicit.options.translate.x) || 0)) / 10); var currVal_2 = (((((_v.parent.context.$implicit.options.frame == null) ? null : _v.parent.context.$implicit.options.frame.y) || 0) + (((_v.parent.context.$implicit.options.translate == null) ? null : _v.parent.context.$implicit.options.translate.y) || 0)) / 10); var currVal_3 = (_v.parent.context.$implicit.options.frame.width / 10); var currVal_4 = (_v.parent.context.$implicit.options.frame.height / 10); var currVal_5 = i0.ɵunv(_v, 0, 5, _ck(_v, 1, 0, i0.ɵnov(_v.parent.parent, 0), _v.parent.context.$implicit.options.transform)); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); }); }
function View_ViewEditorViewportOverlaysComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(0, null, null, 0))], null, null); }
function View_ViewEditorViewportOverlaysComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [], [[2, "view-editor-viewport__overlay-interactive", null]], null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ViewEditorViewportOverlaysComponent_5)), i0.ɵdid(2, 212992, null, 0, i1.CdkPortalOutlet, [i0.ComponentFactoryResolver, i0.ViewContainerRef], { portal: [0, "portal"] }, null)], function (_ck, _v) { var currVal_1 = _v.parent.context.$implicit.portal; _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.options.interactive; _ck(_v, 0, 0, currVal_0); }); }
function View_ViewEditorViewportOverlaysComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ViewEditorViewportOverlaysComponent_2)), i0.ɵdid(2, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ViewEditorViewportOverlaysComponent_4)), i0.ɵdid(4, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = _v.context.$implicit.options.frame; _ck(_v, 2, 0, currVal_0); var currVal_1 = !_v.context.$implicit.options.frame; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_ViewEditorViewportOverlaysComponent_0(_l) { return i0.ɵvid(2, [i0.ɵpid(0, i3.SafeStylePipe, [i4.DomSanitizer]), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ViewEditorViewportOverlaysComponent_1)), i0.ɵdid(2, 278528, null, 0, i2.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"], ngForTrackBy: [1, "ngForTrackBy"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.viewportOverlays; var currVal_1 = _co.trackViewportOverlayFn; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_ViewEditorViewportOverlaysComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 16777216, null, null, 1, "app-view-editor-viewport-overlays", [], null, null, null, View_ViewEditorViewportOverlaysComponent_0, RenderType_ViewEditorViewportOverlaysComponent)), i0.ɵdid(1, 245760, null, 0, i5.ViewEditorViewportOverlaysComponent, [i6.ViewEditorViewportOverlayService, i0.ViewContainerRef, i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ViewEditorViewportOverlaysComponentNgFactory = i0.ɵccf("app-view-editor-viewport-overlays", i5.ViewEditorViewportOverlaysComponent, View_ViewEditorViewportOverlaysComponent_Host_0, {}, {}, []);
export { ViewEditorViewportOverlaysComponentNgFactory as ViewEditorViewportOverlaysComponentNgFactory };

import trim from 'lodash/trim';

export function numberToHex(num: number): string {
  // tslint:disable-next-line:no-bitwise
  const str = (num & 0x00ffffff).toString(16).toUpperCase();
  return '00000'.substring(0, 6 - str.length) + str;
}

export function parseNumber(value: any, defaultValue = null): number {
  if (typeof value === 'number' || value === undefined || value === null) {
    return value;
  } else if (typeof value !== 'string') {
    return defaultValue;
  }

  value = trim(value);

  if (value === '') {
    return defaultValue;
  }

  value = parseFloat(value.replace(',', '.'));

  if (isNaN(value)) {
    return defaultValue;
  }

  return value;
}

export function degToRad(degrees: number): number {
  return degrees * (Math.PI / 180);
}

export function radToDeg(radians: number): number {
  return (radians * 180) / Math.PI;
}

export function coerceFloat(value: any, defaultValue?: any): number {
  const result = parseFloat(value);
  return !isNaN(result) ? result : defaultValue;
}

export function getNumberOrdinal(num: number): string {
  if (typeof num !== 'number') {
    return String(num);
  } else if (num < 0) {
    return String(num);
  } else if (num === 0) {
    return 'zero';
  } else if (num === 1) {
    return '1st';
  } else if (num === 2) {
    return '2nd';
  } else if (num === 3) {
    return '3rd';
  } else {
    return `${num}th`;
  }
}

import { EventEmitter, Input, Output } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Observable, Subscription } from 'rxjs';
import { auditTime, filter, map } from 'rxjs/operators';

import { ViewContext } from '@modules/customize';
import { Layer } from '@modules/views';

export abstract class CustomizeLayerComponent<T extends Layer = Layer> {
  @Input() layer: T;
  @Input() layerUpdated$: Observable<T>;
  @Input() viewContext: ViewContext;
  @Output() layerChange = new EventEmitter<T>();
  @Output() closeCustomize = new EventEmitter<void>();

  formUpdatesSubscription: Subscription;
  formUpdatesTracking = true;

  constructor() {}

  trackFormUpdates(form: AbstractControl, getLayer: () => T) {
    this.formUpdatesSubscription = form.valueChanges
      .pipe(
        filter(() => this.formUpdatesTracking),
        auditTime(10),
        map(() => getLayer()),
        untilDestroyed(this)
      )
      .subscribe(result => this.layerChange.emit(result));
  }

  pauseFormUpdates() {
    this.formUpdatesTracking = false;
  }

  resumeFormUpdates() {
    this.formUpdatesTracking = true;
  }
}

<app-popup2 [size]="'s'">
  <form [formGroup]="form" (ngSubmit)="submit()">
    <div class="custom-page-popup custom-page-popup_style_background">
      <div class="custom-page-popup__header" [class.custom-page-popup__header_overlay]="false">
        <div class="custom-page-popup__header-main">
          <div class="custom-page-popup__title">Google Tag Manager</div>
          <div class="custom-page-popup__description">
            Allows you to manage and deploy analytics and marketing tags as well as other code snippets. This
            integration places the GTM container code snippets onto your App so that you do not need to add this
            manually.
          </div>
        </div>
      </div>
      <div class="custom-page-popup__content">
        <app-page-block>
          <div class="field">
            <label class="field__label">GTM ID</label>

            <app-auto-field
              *ngIf="!loading"
              [form]="form"
              [label]="false"
              [field]="
                createField({
                  name: 'id',
                  field: 'CharField',
                  placeholder: 'GTM-XXXXXXX',
                  required: true,
                  params: { classes: ['input_fill'] }
                })
              "
            ></app-auto-field>

            <input
              *ngIf="loading"
              class="input input_stub input_fill"
              [class.loading-animation]="true"
              value="value"
              style="pointer-events: none;"
              readonly
            />

            <div class="field__description">
              Copy your <a href="https://tagmanager.google.com/" target="_blank">Google Tag Manager</a> Container ID and
              paste it here.
            </div>
          </div>
        </app-page-block>

        <app-page-block>
          <app-stack [align]="'right'">
            <button
              type="button"
              class="button button_orange-transparent popup2__footer-item"
              (click)="cancel()"
              [disabled]="loading || submitLoading"
            >
              Cancel
            </button>

            <button
              type="submit"
              class="button button_primary button_orange popup2__footer-item"
              [disabled]="loading || submitLoading || !form.valid"
            >
              <app-loader-small *ngIf="submitLoading" class="button__icon button__icon_left"></app-loader-small>
              Save
            </button>
          </app-stack>
        </app-page-block>
      </div>
    </div>
  </form>
</app-popup2>

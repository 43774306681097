import { Injectable, Injector, Type } from '@angular/core';
import clone from 'lodash/clone';
import defaults from 'lodash/defaults';
import { Observable, Subject } from 'rxjs';

import { CLOSE_BY_BACKGROUND_CLICK, OpaqueThinDialogPopupComponent } from '@common/dialog-popup';
import { CLOSE_BY_DEACTIVATE, PopupService } from '@common/popups';
import { FeatureService } from '@modules/features';
import { ParameterArray } from '@modules/fields';
import { CurrentProjectStore, Resource, ResourceType } from '@modules/projects';
import { HttpContentType, HttpMethod, Query, QueryType } from '@modules/queries';

import { QueryBuilderHttpOptions } from '../../components/query-builder-http/query-builder-http.component';
import { QueryBuilderObjectOptions } from '../../components/query-builder-object/query-builder-object.form';
import { QueryBuilderSqlOptions } from '../../components/query-builder-sql/query-builder-sql.component';
import {
  QueryBuilderComponent,
  QueryBuilderCustomComponent,
  QueryBuilderResultEvent
} from '../../components/query-builder/query-builder.component';
import { QueryBuilderContext } from '../../data/query-builder-context';

@Injectable()
export class QueryBuilderService {
  constructor(
    private popupService: PopupService,
    private currentProjectStore: CurrentProjectStore,
    private featureService: FeatureService
  ) {}

  edit(options: {
    injector: Injector;
    queryClass: Type<Query>;
    queryTypes: QueryType[];
    resource?: Resource;
    resourceType?: ResourceType;
    context: QueryBuilderContext;
    requireResponse?: boolean;
    arrayResponse?: boolean;
    query?: Query;
    customSections?: QueryBuilderCustomComponent[];
    parametersControl?: ParameterArray;
    httpOptions?: QueryBuilderHttpOptions;
    sqlOptions?: QueryBuilderSqlOptions;
    objectOptions?: QueryBuilderObjectOptions;
    currentUserTokens?: boolean;
    source?: string;
  }): Observable<QueryBuilderResultEvent> {
    options = defaults(options, {
      queryTypes: [],
      customSections: [],
      httpOptions: {},
      objectOptions: {},
      currentUserTokens: true
    });

    const obs = new Subject<QueryBuilderResultEvent>();

    if (options.resource && !this.currentProjectStore.instance.features.isCustomResourcesEnabled()) {
      this.featureService.showFeatureOverview({
        subtitle: 'Paid Feature',
        title: 'Build App with <strong>Custom Queries</strong>',
        description: `
          Connect data from custom queries. You can see all your data and take action in one place.
        `
      });
      return obs;
    }

    const httpOptions = clone(options.httpOptions);

    if (options.resourceType == ResourceType.GraphQL) {
      httpOptions.defaults = {
        method: HttpMethod.POST,
        headers: [{ name: 'Content-Type', value: 'application/json' }],
        body_type: HttpContentType.GraphQL
      };
      httpOptions.defaultTab = 'body';
      httpOptions.enabledBodyTypes = [HttpContentType.GraphQL];
    }

    this.popupService.push({
      component: QueryBuilderComponent,
      popupComponent: OpaqueThinDialogPopupComponent,
      popupComponentOrange: true,
      popupCloseOnDeactivate: true,
      inputs: {
        queryClass: options.queryClass,
        queryTypes: options.queryTypes,
        resource: options.resource,
        context: options.context,
        requireResponse: options.requireResponse,
        arrayResponse: options.arrayResponse,
        query: options.query,
        currentUserTokens: options.currentUserTokens,
        customSections: options.customSections,
        parametersControl: options.parametersControl,
        httpOptions: httpOptions,
        sqlOptions: options.sqlOptions,
        objectOptions: options.objectOptions,
        source: options.source
      },
      outputs: {
        result: [result => obs.next(result)]
      },
      popupClosed: data => {
        if (data == CLOSE_BY_BACKGROUND_CLICK || data == CLOSE_BY_DEACTIVATE) {
          obs.next({
            cancelled: true
          });
        }
      },
      injector: options.injector
    });

    return obs.asObservable();
  }
}

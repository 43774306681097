import { TemplateRef } from '@angular/core';

import { FieldType } from './field-type';
import { getFieldDescriptionByType, parseFieldType } from './fields';
import { ValidatorType } from './validator-type';

export interface FormFieldSerialized {
  name?: string;
  label?: string;
  description?: string | TemplateRef<any>;
  placeholder?: string;
  validator_type?: ValidatorType;
  validator_params?: Object;
  field?: string;
  editable?: boolean;
  required?: boolean;
  reset_enabled?: boolean;
  params?: Object;
}

export class FormField {
  name: string;
  label: string;
  description: string | TemplateRef<any>;
  placeholder: string;
  validatorType: ValidatorType;
  validatorParams: Object;
  field: FieldType;
  editable = true;
  required = false;
  resetEnabled = false;
  params = {};

  deserialize(data: FormFieldSerialized): FormField {
    this.name = data['name'];
    this.label = data['label'];
    this.description = data['description'];
    this.placeholder = data['placeholder'];
    this.field = parseFieldType(data['field']);

    if (data['editable'] !== undefined) {
      this.editable = data['editable'] != false;
    }

    if (data['required'] !== undefined) {
      this.required = data['required'] != false;
    }

    if (data['reset_enabled'] !== undefined) {
      this.resetEnabled = data['reset_enabled'] != false;
    }

    if (data['validator_type']) {
      this.validatorType = data['validator_type'];
    }

    if (data['validator_params']) {
      this.validatorParams = data['validator_params'];
    }

    const item = getFieldDescriptionByType(this.field);
    this.params = { ...item.defaultParams, ...data['params'], ...item.forceParams };

    return this;
  }

  serialize(): FormFieldSerialized {
    return {
      name: this.name,
      label: this.label,
      description: this.description,
      placeholder: this.placeholder,
      validator_type: this.validatorType,
      validator_params: this.validatorParams,
      field: this.field,
      params: this.params,
      required: this.required,
      reset_enabled: this.resetEnabled,
      editable: this.editable
    };
  }
}

import { ChangeDetectionStrategy, Component } from '@angular/core';

import { FieldType, registerFieldComponent } from '@modules/fields';

import { FieldComponent } from '../field/field.component';

export enum BooleanFieldStyle {
  Checkbox = 'checkbox',
  Toggle = 'toggle'
}

@Component({
  selector: 'app-boolean-field',
  templateUrl: 'boolean-field.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BooleanFieldComponent extends FieldComponent {
  styles = BooleanFieldStyle;
  defaultStyle = BooleanFieldStyle.Checkbox;

  get style() {
    return this.field.params['style'] || this.defaultStyle;
  }

  toggle() {
    if (this.control && this.control.disabled) {
      return;
    }

    this.form.patchValue({
      [this.field.name]: !this.currentValue
    });
  }
}

registerFieldComponent(FieldType.Boolean, BooleanFieldComponent);

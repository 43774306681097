import { ComponentRef, ElementRef, Injector, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import defaults from 'lodash/defaults';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { fromEvent } from 'rxjs';
import { debounceTime, filter } from 'rxjs/operators';
import { DynamicComponentService } from '@common/dynamic-component';
import { TipComponent } from '@common/tips';
import { isSet } from '@shared';
import { GuideFocusService } from '../../services/guide-focus/guide-focus.service';
import { GuideService } from '../../services/guide/guide.service';
export var DefaultTutorialTipOptions = {
    tip: undefined,
    task: undefined,
    page: undefined,
    pageItem: undefined,
    action: 'click'
};
var TutorialTipDirective = /** @class */ (function () {
    function TutorialTipDirective(el, injector, guideService, guideFocusService, dynamicComponentService) {
        this.el = el;
        this.injector = injector;
        this.guideService = guideService;
        this.guideFocusService = guideFocusService;
        this.dynamicComponentService = dynamicComponentService;
        this.subscriptions = [];
    }
    Object.defineProperty(TutorialTipDirective.prototype, "options", {
        get: function () {
            return defaults(this.inputOptions, DefaultTutorialTipOptions);
        },
        enumerable: true,
        configurable: true
    });
    TutorialTipDirective.prototype.ngOnInit = function () {
        var _this = this;
        if (!this.guideService) {
            return;
        }
        this.guideService.state$.pipe(untilDestroyed(this)).subscribe(function (state) {
            var current = state &&
                state.task.uniqueName == _this.options.task &&
                state.page == _this.options.page &&
                _this.guideService.statePageItemName == _this.options.pageItem;
            if (current) {
                _this.show();
                _this.initEvents();
            }
            else {
                _this.hide();
                _this.deinitEvents();
            }
        });
    };
    TutorialTipDirective.prototype.ngOnDestroy = function () {
        var _this = this;
        this.hide();
        setTimeout(function () { return _this.deinitEvents(); }, 0); // TODO: workaround for navigate after click
    };
    TutorialTipDirective.prototype.initEvents = function () {
        var _this = this;
        if (this.subscriptions.length) {
            return;
        }
        if (this.options.action == 'hover') {
            this.subscriptions.push(fromEvent(this.el.nativeElement, 'mouseenter')
                .pipe(untilDestroyed(this))
                .subscribe(function () {
                _this.onActivated();
            }));
        }
        else if (this.options.action == 'click') {
            this.subscriptions.push(fromEvent(this.el.nativeElement, 'click')
                // .pipe(untilDestroyed(this)) // TODO: workaround for navigate after click
                .subscribe(function () {
                _this.onActivated();
            }));
        }
        else if (this.options.action == 'input') {
            if (this.options.formGroup) {
                var controlName = this.el.nativeElement.getAttribute('formControlName');
                var control_1 = this.options.formGroup.controls[controlName];
                this.subscriptions.push(control_1.valueChanges
                    .pipe(debounceTime(1000), filter(function () { return control_1.valid; }), untilDestroyed(this))
                    .subscribe(function () {
                    _this.onActivated();
                }));
            }
            else {
                this.subscriptions.push(fromEvent(this.el.nativeElement, 'input')
                    .pipe(debounceTime(1000), filter(function () { return isSet(_this.el.nativeElement.value); }), untilDestroyed(this))
                    .subscribe(function () {
                    _this.onActivated();
                }));
            }
        }
    };
    TutorialTipDirective.prototype.deinitEvents = function () {
        if (!this.subscriptions.length) {
            return;
        }
        this.subscriptions.forEach(function (item) { return item.unsubscribe(); });
        this.subscriptions = [];
    };
    TutorialTipDirective.prototype.show = function () {
        var _this = this;
        if (this.componentRef) {
            this.hide();
        }
        if (!this.options.tip) {
            return;
        }
        var componentRef = this.dynamicComponentService.appendComponentToBody(this.injector, {
            component: TipComponent,
            inputs: {
                content: this.options.tip,
                options: {
                    hoverable: false,
                    stick: true,
                    style: 'bright'
                },
                sourceElement: this.el.nativeElement
            },
            outputs: {
                closed: [
                    function () {
                        componentRef.destroy();
                        if (_this.componentRef == componentRef) {
                            _this.componentRef = undefined;
                        }
                    }
                ],
                moved: [
                    function (position) {
                        if (!_this.guideService.state) {
                            return;
                        }
                        _this.guideFocusService.position = { x: position.x, y: position.y };
                    }
                ]
            }
        });
        this.componentRef = componentRef;
    };
    TutorialTipDirective.prototype.hide = function () {
        if (!this.componentRef) {
            return;
        }
        this.componentRef.instance.close();
    };
    TutorialTipDirective.prototype.onActivated = function () {
        this.guideService.completeTaskProgress(this.options.task, this.options.page, this.options.pageItem);
    };
    return TutorialTipDirective;
}());
export { TutorialTipDirective };

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, publishLast, refCount, switchMap } from 'rxjs/operators';

import { ApiService } from '@modules/api';

import { Environment } from '../../data/environment';
import { Project } from '../../data/project';

@Injectable({
  providedIn: 'root'
})
export class EmailService {
  constructor(private apiService: ApiService, private http: HttpClient) {}

  send(
    project: Project,
    environment: Environment,
    to: string,
    subject: string,
    text: string,
    textHtml: string
  ): Observable<boolean> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.nodeEnvironmentMethodURL(project.uniqueName, environment.uniqueName, 'send_email');
        let headers = new HttpHeaders();
        const data = {
          to: to,
          subject: subject,
          text: text,
          text_html: textHtml
        };

        headers = this.apiService.setHeadersToken(headers);

        return this.http.post(url, data, { headers: headers });
      }),
      map(() => true),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }
}

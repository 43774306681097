var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { TemplatePortal } from '@angular/cdk/portal';
import { ChangeDetectorRef, ElementRef, Injector, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest, from, fromEvent, of, timer } from 'rxjs';
import { debounce, delayWhen, filter, first, map, switchMap } from 'rxjs/operators';
import { ContrastDialogPopupComponent, ThinDialogPopupComponent } from '@common/dialog-popup';
import { DialogButtonHotkey, DialogButtonPosition, DialogButtonType, DialogService } from '@common/dialogs';
import { PopupService } from '@common/popups';
import { AppConfigService } from '@core';
import { ActionStore } from '@modules/action-queries';
import { AdminMode } from '@modules/admin-mode';
import { AnalyticsEvent, IntercomService, UniversalAnalyticsService } from '@modules/analytics';
import { ApiService } from '@modules/api';
import { AuthService } from '@modules/auth';
import { CustomizeService, CustomizeType, ViewSettingsStore } from '@modules/customize';
import { DraftChangesService, PublishService } from '@modules/customize-utils';
import { ProjectDomainController } from '@modules/domain-components';
import { FeatureService } from '@modules/features';
import { ModelDescriptionStore } from '@modules/model-queries';
import { PER_PAGE_PARAM } from '@modules/models';
import { EnvironmentController } from '@modules/project-settings-components';
import { CurrentEnvironmentStore, CurrentProjectStore, EnvironmentService, ProjectUserService } from '@modules/projects';
import { RoutingService } from '@modules/routing';
import { ShareController, ShareTab } from '@modules/share';
import { SnapshotPopupComponent } from '@modules/snapshots-components';
import { ConfigureResourcesComponent } from '@modules/template-components';
import { ThemeService } from '@modules/theme';
import { IntroService } from '@modules/tutorial';
import { CurrentUserStore } from '@modules/users';
import { isControlElement, isSet, KeyboardEventKeyCode, openUrl } from '@shared';
import { DraftChangesComponent } from '../draft-changes/draft-changes.component';
var CustomizeToolbarComponent = /** @class */ (function () {
    function CustomizeToolbarComponent(customizeService, currentUserStore, introService, currentProjectStore, currentEnvironmentStore, environmentService, environmentController, publishService, intercomService, themeService, shareController, appConfigService, injector, cd, authService, routing, popupService, featureService, apiService, dialogService, modelDescriptionStore, actionStore, viewSettingsStore, projectUserService, projectDomainController, draftChangesService, vcr, analyticsService) {
        this.customizeService = customizeService;
        this.currentUserStore = currentUserStore;
        this.introService = introService;
        this.currentProjectStore = currentProjectStore;
        this.currentEnvironmentStore = currentEnvironmentStore;
        this.environmentService = environmentService;
        this.environmentController = environmentController;
        this.publishService = publishService;
        this.intercomService = intercomService;
        this.themeService = themeService;
        this.shareController = shareController;
        this.appConfigService = appConfigService;
        this.injector = injector;
        this.cd = cd;
        this.authService = authService;
        this.routing = routing;
        this.popupService = popupService;
        this.featureService = featureService;
        this.apiService = apiService;
        this.dialogService = dialogService;
        this.modelDescriptionStore = modelDescriptionStore;
        this.actionStore = actionStore;
        this.viewSettingsStore = viewSettingsStore;
        this.projectUserService = projectUserService;
        this.projectDomainController = projectDomainController;
        this.draftChangesService = draftChangesService;
        this.vcr = vcr;
        this.analyticsService = analyticsService;
        this.menuCustomizing = false;
        this.handlerRename = false;
        this.handlerSettings = false;
        this.handlerDuplicate = false;
        this.handlerDuplicateLoading = false;
        this.demoUser = false;
        this.previewHover$ = new BehaviorSubject(false);
        this.savingChanges = false;
        this.saveErrorOpened = false;
        this.saveErrorHover$ = new BehaviorSubject(false);
        this.environments = [];
        this.publishOpened = false;
        this.draftChanges = [];
        this.draftChangesTotal = 0;
        this.publishLoading = false;
        this.published = false;
        this.snapshotsEnabled = false;
        this.shareTabs = ShareTab;
        this.analyticsEvents = AnalyticsEvent;
        this.source = 'customize_toolbar';
    }
    CustomizeToolbarComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.customizeService.handlerInfo$.pipe(untilDestroyed(this)).subscribe(function (info) {
            _this.info = info;
            _this.cd.markForCheck();
            _this.updateStubResources();
        });
        this.customizeService.handler$.pipe(untilDestroyed(this)).subscribe(function (handler) {
            _this.handlerRename = handler && !!handler.renameHandler;
            _this.handlerSettings = handler && !!handler.openHandlerSettings;
            _this.handlerDuplicate = handler && !!handler.duplicate;
            _this.cd.markForCheck();
        });
        this.customizeService.handlerHeaderLeft$.pipe(untilDestroyed(this)).subscribe(function (template) {
            _this.handlerHeaderLeftPortal = template ? new TemplatePortal(template, _this.vcr) : undefined;
            _this.cd.markForCheck();
        });
        this.customizeService.handlerHeaderCenter$.pipe(untilDestroyed(this)).subscribe(function (template) {
            _this.handlerHeaderCenterPortal = template ? new TemplatePortal(template, _this.vcr) : undefined;
            _this.cd.markForCheck();
        });
        this.customizeService.menuEnabled$.pipe(untilDestroyed(this)).subscribe(function (result) {
            _this.menuCustomizing = result;
            _this.cd.markForCheck();
        });
        this.currentUserStore.instance$.pipe(untilDestroyed(this)).subscribe(function (result) {
            _this.demoUser = !!_this.apiService.getProjectToken();
            _this.cd.markForCheck();
        });
        this.currentProjectStore
            .get()
            .pipe(untilDestroyed(this))
            .subscribe(function (result) {
            _this.project = result;
            _this.environments = _this.project
                ? _this.project
                    .getEnvironmentsSorted()
                    .filter(function (item) {
                    return _this.project.hasEnvironmentSettingsPermission(item) ||
                        _this.project.hasEnvironmentAccessPermission(item);
                })
                : [];
            _this.cd.markForCheck();
        });
        this.currentEnvironmentStore.instance$.pipe(untilDestroyed(this)).subscribe(function (result) {
            _this.environment = result;
            _this.cd.markForCheck();
        });
        this.draftChangesService
            .getDraftChanges$()
            .pipe(untilDestroyed(this))
            .subscribe(function (changes) {
            _this.draftChanges = changes.filter(function (item) { return item.count; });
            _this.draftChangesTotal = _this.draftChanges.reduce(function (acc, item) { return acc + item.count; }, 0);
            _this.cd.markForCheck();
        });
        this.customizeService.changesSaving$.pipe(untilDestroyed(this)).subscribe(function (value) {
            _this.savingChanges = value;
            _this.cd.markForCheck();
        });
        this.customizeService.changesSaveError$.pipe(untilDestroyed(this)).subscribe(function (value) {
            var noSaveErrorBefore = !isSet(_this.saveError);
            _this.saveError = value;
            if (noSaveErrorBefore && isSet(value)) {
                _this.saveErrorOpened = true;
            }
            else if (!isSet(value)) {
                _this.saveErrorOpened = false;
            }
            _this.cd.markForCheck();
        });
        combineLatest(this.previewHover$.pipe(debounce(function (value) { return timer(value ? 0 : 10); })), this.saveErrorHover$.pipe(debounce(function (value) { return timer(value ? 0 : 10); })))
            .pipe(untilDestroyed(this))
            .subscribe(function (_a) {
            var previewHover = _a[0], saveErrorHover = _a[1];
            _this.saveErrorOpened = previewHover || saveErrorHover;
            _this.cd.markForCheck();
        });
        this.customizeService
            .publishRequested$()
            .pipe(untilDestroyed(this))
            .subscribe(function () {
            _this.publishDraft(true);
        });
        fromEvent(document, 'keydown')
            .pipe(filter(function () { return _this.customizeService.handler && !!_this.customizeService.handler.setChangesState; }), filter(function () { return !_this.popupService.items.length && !isControlElement(document.activeElement); }), map(function (e) {
            if (e.keyCode == KeyboardEventKeyCode.Z && (e.metaKey || e.ctrlKey) && !e.shiftKey) {
                return -1;
            }
            else if (e.keyCode == KeyboardEventKeyCode.Z && (e.metaKey || e.ctrlKey) && e.shiftKey) {
                return 1;
            }
        }), filter(function (move) { return isSet(move); }), switchMap(function (move) {
            if (move > 0) {
                return _this.customizeService.isRedoAvailable$.pipe(first(), map(function (allowed) { return [move, allowed]; }));
            }
            else {
                return _this.customizeService.isUndoAvailable$.pipe(first(), map(function (allowed) { return [move, allowed]; }));
            }
        }), untilDestroyed(this))
            .subscribe(function (_a) {
            var move = _a[0], allowed = _a[1];
            if (!allowed) {
                return;
            }
            if (move > 0) {
                _this.customizeService.redoChangesHistory();
            }
            else {
                _this.customizeService.undoChangesHistory();
            }
        });
        this.snapshotsEnabled = this.appConfigService.snapshotsEnabled;
        this.cd.markForCheck();
    };
    CustomizeToolbarComponent.prototype.ngOnDestroy = function () { };
    // saveChanges() {
    //   this.saveLoading = true;
    //   this.cd.markForCheck();
    //
    //   this.customizeService
    //     .saveCustomize()
    //     .pipe(untilDestroyed(this))
    //     .subscribe(
    //       result => {
    //         this.saveLoading = false;
    //         this.cd.markForCheck();
    //       },
    //       () => {
    //         this.saveLoading = false;
    //         this.cd.markForCheck();
    //       }
    //     );
    // }
    // cancel() {
    //   this.cancelLoading = true;
    //   this.cd.markForCheck();
    //
    //   this.customizeService
    //     .cancelCustomize()
    //     .pipe(untilDestroyed(this))
    //     .subscribe(
    //       () => {
    //         this.cancelLoading = false;
    //         this.cd.markForCheck();
    //       },
    //       () => {
    //         this.cancelLoading = false;
    //         this.cd.markForCheck();
    //       }
    //     );
    // }
    CustomizeToolbarComponent.prototype.updateStubResources = function () {
        var _this = this;
        if (!this.info || !this.info.page) {
            this.stubResources = [];
            this.cd.markForCheck();
            return;
        }
        this.stubResources = this.info.page.usedResources
            .map(function (item) { return _this.currentEnvironmentStore.resources.find(function (resource) { return resource.uniqueName == item.name; }); })
            .filter(function (item) { return item != undefined && item.isStub() && item.isSimpleIntegration(); });
        this.cd.markForCheck();
    };
    CustomizeToolbarComponent.prototype.configureStubResources = function () {
        var _this = this;
        this.popupService.push({
            component: ConfigureResourcesComponent,
            popupComponent: ThinDialogPopupComponent,
            inputs: {
                title: 'Connect your Resources',
                description: "\n          The following resource credentials are taken from Jet Admin templates demo data.\n          Provide your credentials to use templates with your data.\n        ",
                resources: this.stubResources,
                page: this.info ? this.info.page : undefined
            },
            outputs: {
                resourceChanged: [
                    function () {
                        if (_this.customizeService.handler && _this.customizeService.handler.reload) {
                            _this.customizeService.handler.reload().subscribe();
                        }
                    }
                ]
            },
            injector: this.injector
        });
        this.analyticsService.sendSimpleEvent(AnalyticsEvent.Template.ConnectResourcesClicked);
    };
    CustomizeToolbarComponent.prototype.openChat = function () {
        this.intercomService.openChat();
    };
    CustomizeToolbarComponent.prototype.showOverview = function () {
        this.introService.showOverview(this.overviewElement.nativeElement, true);
    };
    CustomizeToolbarComponent.prototype.logout = function () {
        this.authService.logout();
        this.routing.navigate(['/login']);
    };
    CustomizeToolbarComponent.prototype.share = function (initialTab) {
        this.shareController.open({ initialTab: initialTab }).pipe(untilDestroyed(this)).subscribe();
    };
    CustomizeToolbarComponent.prototype.onUpdateTitle = function (title) {
        this.customizeService.renameHandler(title);
        this.analyticsService.sendSimpleEvent(AnalyticsEvent.Page.Renamed, {
            PageID: this.info ? this.info.page : undefined
        });
    };
    CustomizeToolbarComponent.prototype.duplicate = function () {
        var _this = this;
        this.handlerDuplicateLoading = true;
        this.cd.markForCheck();
        this.customizeService
            .duplicateHandler()
            .pipe(untilDestroyed(this))
            .subscribe(function () {
            _this.handlerDuplicateLoading = false;
            _this.cd.markForCheck();
        }, function () {
            _this.handlerDuplicateLoading = false;
            _this.cd.markForCheck();
        });
    };
    CustomizeToolbarComponent.prototype.openSettings = function () {
        this.customizeService.openHandlerSettings();
        this.analyticsService.sendSimpleEvent(AnalyticsEvent.Page.StartedSetUp, {
            PageID: this.info && this.info.page ? this.info.page.uid : undefined
        });
    };
    CustomizeToolbarComponent.prototype.setPublishOpened = function (opened, published) {
        if (published === void 0) { published = false; }
        this.publishOpened = opened;
        this.published = published;
        this.cd.markForCheck();
        if (this.publishOpened) {
            this.analyticsService.sendSimpleEvent(AnalyticsEvent.Publish.OpenPublishOptions);
        }
    };
    CustomizeToolbarComponent.prototype.setSaveErrorOpened = function (opened) {
        this.saveErrorOpened = opened;
        this.cd.markForCheck();
    };
    CustomizeToolbarComponent.prototype.preview = function () {
        var _this = this;
        var obs = !this.customizeService.handlerInfo || !this.customizeService.handlerInfo.page
            ? from(this.routing.navigateApp(this.currentProjectStore.instance.homeLink))
            : of(undefined);
        obs.pipe(untilDestroyed(this)).subscribe(function () {
            _this.customizeService.enabled = undefined;
            _this.getConfigurationAnalytics()
                .pipe(untilDestroyed(_this))
                .subscribe(function (configuration) {
                _this.analyticsService.sendSimpleEvent(AnalyticsEvent.Publish.ClickPreview, __assign({ Published: _this.published }, configuration));
            });
        });
    };
    CustomizeToolbarComponent.prototype.exitPreview = function () {
        this.customizeService.enabled = CustomizeType.Layout;
        this.analyticsService.sendSimpleEvent(AnalyticsEvent.Publish.ExitPreview, {
            Published: this.published
        });
    };
    CustomizeToolbarComponent.prototype.getAppUrl = function (domain) {
        var project = this.currentProjectStore.instance;
        var inAppDisabled = this.customizeService.handlerInfo && this.customizeService.handlerInfo.inAppDisabled;
        var newPage = this.customizeService.handlerInfo &&
            this.customizeService.handlerInfo.page &&
            !this.customizeService.handlerInfo.page.uid;
        var path;
        var params;
        if (this.customizeService.handler && !inAppDisabled && !newPage) {
            path = window.location.pathname.replace('/builder/', '/app/');
            params = window.location.search;
        }
        else if (project) {
            path = project.getHomeLink({ mode: AdminMode.App }).join('/');
            params = '';
        }
        if (domain) {
            return project.protocol + "://" + domain.actualDomain + path + params;
        }
        else {
            return "" + this.appConfigService.webBaseUrl + path + params;
        }
    };
    CustomizeToolbarComponent.prototype.switchToApp = function (domain) {
        var url = this.getAppUrl(domain);
        openUrl(url);
    };
    CustomizeToolbarComponent.prototype.openAppUrl = function (domain) {
        var _this = this;
        var url = this.getAppUrl(domain);
        openUrl(url, true);
        this.getConfigurationAnalytics()
            .pipe(untilDestroyed(this))
            .subscribe(function (configuration) {
            _this.analyticsService.sendSimpleEvent(AnalyticsEvent.Publish.OpenApp, __assign({ Published: _this.published }, configuration));
        });
    };
    CustomizeToolbarComponent.prototype.openSignUp = function () {
        this.routing.navigateApp(this.currentProjectStore.instance.settingsSignUpLink('sign_up'));
    };
    CustomizeToolbarComponent.prototype.getConfigurationAnalytics = function () {
        var _a;
        return combineLatest(this.currentEnvironmentStore.getResourcesFirst(), this.modelDescriptionStore.getFirst(), this.actionStore.getFirst(), this.viewSettingsStore.getFirst(), this.projectUserService.getPaginate(this.currentProjectStore.instance.uniqueName, this.currentEnvironmentStore.instance.uniqueName, (_a = {}, _a[PER_PAGE_PARAM] = 1, _a))).pipe(map(function (_a) {
            var resources = _a[0], modelDescriptions = _a[1], actionDescriptions = _a[2], viewSettings = _a[3], projectUsers = _a[4];
            return {
                Resources: resources.filter(function (item) { return !item.demo; }).length,
                ProjectUsers: projectUsers.count,
                ConfiguredComponents: viewSettings.reduce(function (acc, item) { return acc + item.configuredElements; }, 0),
                ConfiguredCollectionComponents: viewSettings.reduce(function (acc, item) { return acc + item.configuredModelElements; }, 0),
                ConfiguredActionComponents: viewSettings.reduce(function (acc, item) { return acc + item.configuredActionElements; }, 0),
                ConfiguredVirtualCollections: modelDescriptions.filter(function (item) { return item.virtual && item.getQuery && item.getQuery.isConfigured(); }).length,
                ConfiguredVirtualActions: actionDescriptions.filter(function (item) { return item.virtual && item.queryAction && item.queryAction && item.queryAction.query.isConfigured(); }).length
            };
        }));
    };
    // requestPublish() {
    //   if (this.hasChange) {
    //     return this.dialogService
    //       .prompt({
    //         title: 'You have unsaved changes',
    //         description: 'Do you want to save current changes before publishing?',
    //         style: 'orange',
    //         buttons: [
    //           {
    //             name: 'cancel',
    //             label: "Don't save",
    //             type: DialogButtonType.Default
    //           },
    //           {
    //             name: 'submit',
    //             label: 'Save',
    //             type: DialogButtonType.Submit,
    //             executor: () => {
    //               return this.customizeService.saveCustomize();
    //             }
    //           }
    //         ]
    //       })
    //       .pipe(untilDestroyed(this))
    //       .subscribe(() => this.publish());
    //   } else {
    //     this.publish();
    //   }
    // }
    // publish() {
    //   const url = this.getAppUrl();
    //   openUrl(url, true);
    //
    //   combineLatest(
    //     this.currentEnvironmentStore.getResourcesFirst(),
    //     this.modelDescriptionStore.getFirst(),
    //     this.actionStore.getFirst(),
    //     this.viewSettingsStore.getViewSettingsFirst(),
    //     this.projectUserStore.getFirst()
    //   )
    //     .pipe(untilDestroyed(this))
    //     .subscribe(([resources, modelDescriptions, actionDescriptions, viewSettings, projectUsers]) => {
    //       this.analyticsService.sendSimpleEvent(AnalyticsEvent.Publish.OpenApp, {
    //         Resources: resources.filter(item => !item.demo).length,
    //         ProjectUsers: projectUsers.length + 1,
    //         ConfiguredComponents: viewSettings.reduce((acc, item) => acc + item.configuredElements, 0),
    //         ConfiguredCollectionComponents: viewSettings.reduce((acc, item) => acc + item.configuredModelElements, 0),
    //         ConfiguredActionComponents: viewSettings.reduce((acc, item) => acc + item.configuredActionElements, 0),
    //         ConfiguredVirtualCollections: modelDescriptions.filter(
    //           item => item.virtual && item.getQuery && item.getQuery.isConfigured()
    //         ).length,
    //         ConfiguredVirtualActions: actionDescriptions.filter(
    //           item => item.virtual && item.queryAction && item.queryAction && item.queryAction.query.isConfigured()
    //         ).length
    //       });
    //     });
    // }
    CustomizeToolbarComponent.prototype.publishDraft = function (silent) {
        var _this = this;
        if (silent === void 0) { silent = false; }
        if (!this.draftChanges.length) {
            return;
        }
        this.publishLoading = true;
        this.cd.markForCheck();
        this.environmentService
            .publishDraft(this.currentProjectStore.instance.uniqueName, this.currentEnvironmentStore.instance.uniqueName)
            .pipe(delayWhen(function () { return _this.publishService.reloadChanges(_this.draftChanges); }), delayWhen(function () { return _this.publishService.updatePagePermissions(); }), untilDestroyed(this))
            .subscribe(function () {
            _this.publishLoading = false;
            _this.cd.markForCheck();
            if (!silent) {
                _this.setPublishOpened(true, true);
                _this.analyticsService.sendSimpleEvent(AnalyticsEvent.Publish.Published);
            }
        }, function () {
            _this.publishLoading = false;
            _this.cd.markForCheck();
            if (!silent) {
                _this.analyticsService.sendSimpleEvent(AnalyticsEvent.Publish.PublishFailed);
            }
        });
        if (!silent) {
            this.analyticsService.sendSimpleEvent(AnalyticsEvent.Publish.ClickPublish);
        }
    };
    CustomizeToolbarComponent.prototype.deleteDraft = function () {
        var _this = this;
        this.analyticsService.sendSimpleEvent(AnalyticsEvent.Publish.ClickDiscard);
        this.setPublishOpened(false);
        this.dialogService
            .dialog({
            title: "Are you sure want to discard current draft changes?",
            description: "\n          Current published project state will be restored.<br>\n          Other environments will not be affected.\n        ",
            style: 'orange',
            buttons: [
                {
                    name: 'review',
                    label: 'Review changes',
                    type: DialogButtonType.Default,
                    position: DialogButtonPosition.Left
                },
                {
                    name: 'cancel',
                    label: 'Cancel',
                    type: DialogButtonType.Default,
                    hotkey: DialogButtonHotkey.Cancel
                },
                {
                    name: 'ok',
                    label: 'OK',
                    type: DialogButtonType.Danger,
                    hotkey: DialogButtonHotkey.Submit,
                    executor: function () {
                        _this.savingChanges = true;
                        _this.cd.markForCheck();
                        return _this.environmentService.deleteDraft(_this.currentProjectStore.instance.uniqueName, _this.currentEnvironmentStore.instance.uniqueName);
                    }
                }
            ]
        })
            .pipe(untilDestroyed(this))
            .subscribe(function (result) {
            if (result.button == 'ok') {
                _this.analyticsService.sendSimpleEvent(AnalyticsEvent.Publish.Discarded);
                window.location.reload();
            }
            else if (result.button == 'cancel') {
                _this.analyticsService.sendSimpleEvent(AnalyticsEvent.Publish.DiscardCancelled);
            }
            else if (result.button == 'review') {
                _this.analyticsService.sendSimpleEvent(AnalyticsEvent.Publish.DiscardReviewChanges);
                _this.showChanges();
            }
        }, function () {
            _this.analyticsService.sendSimpleEvent(AnalyticsEvent.Publish.DiscardFailed);
            _this.savingChanges = false;
            _this.cd.markForCheck();
        });
    };
    CustomizeToolbarComponent.prototype.openProjects = function () {
        if (this.currentEnvironmentStore.instance.version) {
            openUrl('/projects');
        }
        else {
            this.routing.navigate(['/projects']);
        }
    };
    CustomizeToolbarComponent.prototype.createEnvironment = function () {
        this.environmentController.createEnvironment({ source: this.source });
    };
    CustomizeToolbarComponent.prototype.setEnvironment = function (environment) {
        var link = this.project.getHomeLinkWithProtocol({ environmentName: environment.uniqueName });
        this.routing.navigateLink(link);
    };
    CustomizeToolbarComponent.prototype.openEnvironmentSettings = function (environment) {
        var link = this.project.linkWithProtocol(environment.link, { environmentName: environment.uniqueName });
        this.routing.navigateLink(link);
    };
    CustomizeToolbarComponent.prototype.showChanges = function () {
        this.popupService.push({
            component: DraftChangesComponent,
            popupComponent: ContrastDialogPopupComponent,
            popupComponentCloseWithoutConfirm: true,
            injector: this.injector
        });
    };
    CustomizeToolbarComponent.prototype.saveChanges = function () {
        this.customizeService.saveCurrentChangesHistory().pipe(untilDestroyed(this)).subscribe();
    };
    CustomizeToolbarComponent.prototype.showSnapshots = function () {
        this.popupService.push({
            component: SnapshotPopupComponent,
            popupComponent: ContrastDialogPopupComponent,
            popupComponentCloseWithoutConfirm: true,
            injector: this.injector
        });
    };
    CustomizeToolbarComponent.prototype.editDomain = function () {
        this.projectDomainController.edit({ analyticsSource: 'customize_toolbar' }).pipe(untilDestroyed(this)).subscribe();
    };
    CustomizeToolbarComponent.prototype.onSnapshotsClick = function () {
        var featureEnabled = this.currentProjectStore.instance.features.isSnapshotsEnabled();
        this.analyticsService.sendSimpleEvent(AnalyticsEvent.Snapshots.ClickSnapshots, {
            FeatureEnabled: featureEnabled
        });
        if (!featureEnabled) {
            this.featureService.showFeatureOverview({
                subtitle: 'Version Control Feature',
                title: '<strong>Version Control</strong> of your App',
                description: "\n        <ul>\n          <li>Automatic snapshots when App being changed or published</li>\n          <li>App snapshots when new version is released</li>\n          <li>Version history of your App</li>\n          <li>Rollback to previous versions</li>\n        </ul>\n      "
            });
            return;
        }
        this.showSnapshots();
    };
    return CustomizeToolbarComponent;
}());
export { CustomizeToolbarComponent };

import { AfterViewInit, Directive, ElementRef, EventEmitter, OnDestroy, Output } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { fromEvent } from 'rxjs';

import { KeyboardEventKeyCode } from '../../utils/events/events';

@Directive({
  selector: '[appKeyEvent]'
})
export class KeyEventDirective implements OnDestroy, AfterViewInit {
  @Output() enterUp = new EventEmitter<void>();

  constructor(private el: ElementRef) {}

  ngOnDestroy(): void {}

  ngAfterViewInit(): void {
    fromEvent<KeyboardEvent>(this.el.nativeElement, 'keyup')
      .pipe(untilDestroyed(this))
      .subscribe(e => {
        if (e.keyCode == KeyboardEventKeyCode.Enter) {
          this.enterUp.emit();
        }
      });
  }
}

<div class="sidebar__element">
  <app-customize-bar-input-edit
    [label]="'Title'"
    [required]="true"
    [itemForm]="form.controls.notification_action_title"
    [context]="context"
    [contextElement]="contextElement"
    [contextElementPath]="contextElementPath"
    [contextElementPaths]="contextElementPaths"
    [fill]="true"
    [analyticsSource]="analyticsSource + '_notification_title'"
  ></app-customize-bar-input-edit>
</div>

<div class="sidebar__element">
  <app-customize-bar-input-edit
    [label]="'Description'"
    [itemForm]="form.controls.notification_action_description"
    [context]="context"
    [contextElement]="contextElement"
    [contextElementPath]="contextElementPath"
    [contextElementPaths]="contextElementPaths"
    [staticValueParams]="{ multiline: true, rows: 4 }"
    [fill]="true"
    [analyticsSource]="analyticsSource + '_notification_description'"
  ></app-customize-bar-input-edit>
</div>

<div class="sidebar__element">
  <app-sidebar-field [label]="'Icon'">
    <app-auto-field
      [form]="form"
      [label]="false"
      [field]="
        createField({
          name: 'notification_action_icon',
          label: 'Icon',
          field: 'IconField',
          params: { classes: ['select_fill'], allow_empty: true }
        })
      "
    ></app-auto-field>
  </app-sidebar-field>
</div>

<div class="sidebar__element">
  <app-sidebar-field [label]="'Color'">
    <app-sidebar-card>
      <div class="sidebar__element sidebar__element_padding_s sidebar__element_margin_xs">
        <app-color-options
          [colors]="colors"
          [colorControl]="form.controls.notification_action_type"
          [customColorEnabledControl]="form.controls.notification_action_color_enabled"
          [customColorControl]="form.controls.notification_action_color"
        ></app-color-options>
      </div>
    </app-sidebar-card>
  </app-sidebar-field>
</div>

<div class="sidebar__element">
  <app-sidebar-field [label]="'close notification'">
    <app-auto-field
      [form]="form"
      [label]="false"
      [field]="
        createField({
          name: 'notification_action_close_timeout_enabled',
          field: 'SelectField',
          params: {
            options: form.notificationCloseTimeoutEnabledOptions,
            classes: [
              'select_fill',
              form.value['notification_action_close_timeout_enabled'] ? 'select_segment-top' : ''
            ]
          }
        })
      "
    ></app-auto-field>

    <app-auto-field
      *ngIf="form.value['notification_action_close_timeout_enabled']"
      [form]="form"
      [label]="false"
      [field]="
        createField({
          name: 'notification_action_close_timeout',
          field: 'IntegerField',
          placeholder: 'Default is 3',
          params: { classes: ['input_fill', 'input_segment-bottom'] }
        })
      "
    ></app-auto-field>
  </app-sidebar-field>
</div>

import { ComponentFactoryResolver, Injectable, Injector } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';

import { ThinDialogPopupComponent } from '@common/dialog-popup';
import { PopupService } from '@common/popups';
import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { Domain } from '@modules/domain';
import { CurrentProjectStore } from '@modules/projects';
import { isSet } from '@shared';

import { EmailAddressEditPopupComponent } from '../../components/email-address-edit-popup/email-address-edit-popup.component';

export interface EmailAddressEditResult {
  added?: Domain;
  deleted?: Domain;
}

@Injectable()
export class EmailAddressEditController {
  constructor(
    private currentProjectStore: CurrentProjectStore,
    private popupService: PopupService,
    private analyticsService: UniversalAnalyticsService,
    private injector: Injector,
    private resolver: ComponentFactoryResolver
  ) {}

  edit(options: { analyticsSource?: string } = {}): Observable<EmailAddressEditResult> {
    const obs$ = new ReplaySubject<EmailAddressEditResult>();

    const create = !isSet(this.currentProjectStore.instance.domain.emailConfigFrom);

    if (create) {
      this.analyticsService.sendSimpleEvent(AnalyticsEvent.CustomEmail.AddClicked, {
        Source: options.analyticsSource
      });
    } else {
      this.analyticsService.sendSimpleEvent(AnalyticsEvent.CustomEmail.EditClicked, {
        Source: options.analyticsSource
      });
    }

    this.popupService.push({
      component: EmailAddressEditPopupComponent,
      popupComponent: ThinDialogPopupComponent,
      outputs: {
        added: [
          result => {
            obs$.next({
              added: result
            });

            this.analyticsService.sendSimpleEvent(AnalyticsEvent.CustomEmail.CustomEmailAdded, {
              Source: options.analyticsSource
            });
          }
        ],
        deleted: [
          result => {
            obs$.next({
              deleted: result
            });

            this.analyticsService.sendSimpleEvent(AnalyticsEvent.CustomEmail.CustomEmailDeleted, {
              Source: options.analyticsSource
            });
          }
        ]
      },
      resolver: this.resolver,
      injector: this.injector
    });

    return obs$.asObservable();
  }
}

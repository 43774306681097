import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { FieldTypeSection } from '../field-type-menu/field-type-menu.component';

@Component({
  selector: 'app-field-type-dropdown',
  templateUrl: './field-type-dropdown.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FieldTypeDropdownComponent {
  @Input('sections') overrideSections: FieldTypeSection[];
  @Input() currentValue: string;
  @Output() select = new EventEmitter<string>();
}

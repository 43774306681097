export class Singleton<T = any> {
  private instance: T;

  constructor(private factory: () => T) {}

  public get(): T {
    if (this.instance === undefined) {
      this.instance = this.factory();
    }

    return this.instance;
  }
}

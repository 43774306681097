/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../json-field-structure-node/json-field-structure-node.component.ngfactory";
import * as i2 from "../json-field-structure-node/json-field-structure-node.component";
import * as i3 from "./json-field-structure.component";
var styles_JsonFieldStructureComponent = [];
var RenderType_JsonFieldStructureComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_JsonFieldStructureComponent, data: {} });
export { RenderType_JsonFieldStructureComponent as RenderType_JsonFieldStructureComponent };
export function View_JsonFieldStructureComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["style", "padding-right: 15px; overflow: auto;"]], null, [[null, "wheel"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("wheel" === en)) {
        var pd_0 = (_co.onWheel($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "app-json-field-structure-node", [], null, [[null, "changed"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("changed" === en)) {
        var pd_0 = (_co.onChanged($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_JsonFieldStructureNodeComponent_0, i1.RenderType_JsonFieldStructureNodeComponent)), i0.ɵdid(2, 4964352, null, 0, i2.JsonFieldStructureNodeComponent, [i0.ChangeDetectorRef], { node: [0, "node"] }, { changed: "changed" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.value; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_JsonFieldStructureComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-json-field-structure", [], null, null, null, View_JsonFieldStructureComponent_0, RenderType_JsonFieldStructureComponent)), i0.ɵdid(1, 245760, null, 0, i3.JsonFieldStructureComponent, [i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var JsonFieldStructureComponentNgFactory = i0.ɵccf("app-json-field-structure", i3.JsonFieldStructureComponent, View_JsonFieldStructureComponent_Host_0, { control: "control" }, {}, []);
export { JsonFieldStructureComponentNgFactory as JsonFieldStructureComponentNgFactory };

<div
  class="compact-list"
  [style]="style"
  appDropList
  [appDropListData]="state"
  [appDropListDisabled]="search | appIsSet"
  [appDropListSwapDistance]="20"
  [appDropListAreaMarginForward]="[-10, 0, 0, 0]"
  [appDropListAreaMarginBackward]="[0, 0, -10, 0]"
  [appDropListPreviewMissPointerStartDelta]="{ x: 20, y: -20 }"
  [appDropListOutsideIndicator]="true"
  (appDropListDropped)="dragDrop($event)"
>
  <app-view-editor-navigator-item
    *ngFor="let item of state.displayItems; trackBy: trackLayerFn"
    appDrag
    class="compact-list__item"
    [layer]="item"
    [flexLayout]="flexLayout"
    [indent]="indent"
    [search]="search"
    [appDragData]="item"
    (itemCustomize)="customizeItem(item)"
    (itemAddCustomizing)="addCustomizingItem(item, $event.shift)"
    (itemRemoveCustomizing)="removeCustomizingItem(item)"
    (visibleUpdated)="onItemVisibleUpdated(item)"
  >
    <div *appDragPlaceholder class="compact-list__position">
      <div class="compact-list__position-bounds">
        <div class="compact-list__position-line"></div>
        <div class="compact-list__position-dot"></div>
      </div>
    </div>

    <div *appDragPreview [style.width.px]="220">
      <app-view-editor-navigator-drag-preview></app-view-editor-navigator-drag-preview>
    </div>
  </app-view-editor-navigator-item>
</div>

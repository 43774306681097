var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FormControl, FormGroup } from '@angular/forms';
import { ValueResize } from '@modules/views';
import { isSet } from '@shared';
var ValueResizeControl = /** @class */ (function (_super) {
    __extends(ValueResizeControl, _super);
    function ValueResizeControl(state) {
        if (state === void 0) { state = {}; }
        var _this = _super.call(this, {
            enabled: new FormControl(isSet(state.enabled) ? state.enabled : true),
            min: new FormControl(isSet(state.min) ? state.min : undefined),
            max: new FormControl(isSet(state.max) ? state.max : undefined)
        }) || this;
        _this.onDisabledChange = function (value) { return undefined; };
        return _this;
    }
    ValueResizeControl.prototype.deserialize = function (value, options) {
        if (options === void 0) { options = {}; }
        this.instance = value;
        this.controls.enabled.patchValue(value.enabled, options);
        this.controls.min.patchValue(value.min, options);
        this.controls.max.patchValue(value.max, options);
    };
    ValueResizeControl.prototype.getInstance = function (instance) {
        if (!instance) {
            instance = new ValueResize();
        }
        instance.enabled = this.controls.enabled.value;
        instance.min = this.controls.min.value;
        instance.max = this.controls.max.value;
        return instance;
    };
    ValueResizeControl.prototype.serialize = function () {
        return this.getInstance(this.instance);
    };
    ValueResizeControl.prototype.registerOnChange = function (fn) {
        this.valueChanges.subscribe(function (value) { return fn(value); });
    };
    ValueResizeControl.prototype.registerOnDisabledChange = function (fn) {
        this.onDisabledChange = fn;
    };
    ValueResizeControl.prototype.enable = function (opts) {
        _super.prototype.enable.call(this, opts);
        this.onDisabledChange(false);
    };
    ValueResizeControl.prototype.disable = function (opts) {
        _super.prototype.disable.call(this, opts);
        this.onDisabledChange(true);
    };
    return ValueResizeControl;
}(FormGroup));
export { ValueResizeControl };

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Injector, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import cloneDeep from 'lodash/cloneDeep';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppFormGroup, FormUtils } from '@common/form-utils';
import { PopupService } from '@common/popups';
import { AdminMode } from '@modules/admin-mode';
import { MenuGeneratorService } from '@modules/menu';
import { ModelDescription } from '@modules/models';
import { ProjectSettingsService } from '@modules/project-settings';
import { ProjectTokenService, SecretToken, SecretTokenService, socialBackends } from '@modules/projects';
import { HttpQuery, HttpQueryAuthType, RateLimit } from '@modules/queries';
import { RestAPIResourceParams } from '@modules/resources';
import { TemplateService } from '@modules/template';
import { parseNumber } from '@shared';
// TODO: Refactor import
import { HTTP_QUERY_BASIC_AUTH_VALUE, HTTP_QUERY_KEY_AUTH_NAME, HttpQueryKeyAuthSendAs } from '../../../data/http-query-auth';
import { BaseResourceSettingsForm } from '../base-resource-settings/base-resource-settings.form';
var RestApiResourceSettingsForm = /** @class */ (function (_super) {
    __extends(RestApiResourceSettingsForm, _super);
    function RestApiResourceSettingsForm(mode, templateService, secretTokenService, formUtils, projectSettingsService, projectTokenService, popupService, menuGeneratorService, injector) {
        var _this = _super.call(this, secretTokenService, formUtils, projectSettingsService, projectTokenService, popupService, menuGeneratorService, injector) || this;
        _this.mode = mode;
        _this.templateService = templateService;
        _this.form = new AppFormGroup({
            url: new FormControl(''),
            auth_type: new FormControl(null),
            auth_params: new FormControl({}),
            access_token_name: new FormControl(''),
            rate_limit_actions: new FormControl(undefined),
            rate_limit_per_seconds: new FormControl(60),
            custom_proxy: new FormControl('')
        });
        _this.keyForm = new FormGroup({
            send_as: new FormControl('', Validators.required),
            name: new FormControl('', Validators.required),
            value: new FormControl('', Validators.required)
        });
        _this.basicForm = new FormGroup({
            username: new FormControl('', Validators.required),
            password: new FormControl('', Validators.required)
        });
        _this.keyAuthSendAsOptions = [
            { value: HttpQueryKeyAuthSendAs.Header, name: 'Header' },
            { value: HttpQueryKeyAuthSendAs.QueryParam, name: 'Query Param' }
        ];
        _this.authTypeOptions = [
            { value: null, name: 'None' },
            { value: HttpQueryAuthType.Key, name: 'API Key' },
            { value: HttpQueryAuthType.BasicAuth, name: 'Basic Auth' },
            { value: HttpQueryAuthType.OAuth2, name: 'OAuth 2.0' }
        ];
        return _this;
    }
    RestApiResourceSettingsForm.prototype.initResourceValue = function () {
        var _this = this;
        var resourceParams = this.resource.parseParams(RestAPIResourceParams);
        if (!resourceParams.baseHttpQuery) {
            return of(undefined);
        }
        var query = resourceParams.baseHttpQuery;
        this.form.patchValue(__assign({ url: query.url, auth_type: query.authType || null, custom_proxy: resourceParams.customProxy }, (resourceParams.rateLimit &&
            resourceParams.rateLimit.isSet() && {
            rate_limit_actions: resourceParams.rateLimit.actions,
            rate_limit_per_seconds: resourceParams.rateLimit.perMs / 1000
        })), { emitEvent: false });
        if (query.authType == HttpQueryAuthType.Key) {
            if (query.authParams) {
                this.keyForm.patchValue({
                    send_as: query.authParams['send_as'] || HttpQueryKeyAuthSendAs.Header,
                    name: query.authParams['name'] || 'Authorization'
                }, { emitEvent: false });
            }
            return this.secretTokenService
                .getDetail(this.project.uniqueName, this.environment.uniqueName, this.resource.uniqueName, HTTP_QUERY_KEY_AUTH_NAME, this.mode == AdminMode.Builder)
                .pipe(map(function (result) {
                _this.keyForm.patchValue({
                    value: result.value
                }, { emitEvent: false });
            }));
        }
        else if (query.authType == HttpQueryAuthType.BasicAuth) {
            return this.secretTokenService
                .getDetail(this.project.uniqueName, this.environment.uniqueName, this.resource.uniqueName, HTTP_QUERY_BASIC_AUTH_VALUE, this.mode == AdminMode.Builder)
                .pipe(map(function (result) {
                try {
                    var credentials = atob(result.value).split(':');
                    _this.basicForm.patchValue({
                        username: credentials[0],
                        password: credentials[1]
                    }, { emitEvent: false });
                }
                catch (e) { }
            }));
        }
        else if (query.authType == HttpQueryAuthType.OAuth2) {
            if (query.headers) {
                var accessToken = query.headers.find(function (item) { return item.name == 'Authorization'; });
                var prefix = 'Bearer ';
                if (accessToken && accessToken['value'].startsWith(prefix)) {
                    this.form.patchValue({
                        access_token_name: accessToken['value'].substring(prefix.length)
                    }, { emitEvent: false });
                }
            }
            var params_1 = cloneDeep(query.authParams);
            if (params_1['backend']) {
                var backend = socialBackends.find(function (item) { return item.path == params_1['backend']; });
                params_1['backend'] = backend ? backend.name : undefined;
            }
            this.form.patchValue({ auth_params: params_1 });
            return of(undefined);
        }
        else {
            return of(undefined);
        }
    };
    RestApiResourceSettingsForm.prototype.getParams = function () {
        var _this = this;
        var params = new RestAPIResourceParams();
        var baseHttpQuery = new HttpQuery();
        var tokens = [];
        var value = this.form.value;
        var keyFormValue = this.keyForm.value;
        var basicFormValue = this.basicForm.value;
        var hasValues = false;
        if (value['url']) {
            hasValues = true;
            baseHttpQuery.url = value['url'];
        }
        if (value['auth_type'] == HttpQueryAuthType.Key) {
            var keyValue = this.keyForm.value;
            hasValues = true;
            baseHttpQuery.authType = value['auth_type'];
            baseHttpQuery.authParams = {
                send_as: keyValue['send_as'],
                name: keyValue['name']
            };
            if (keyValue['send_as'] == HttpQueryKeyAuthSendAs.Header) {
                baseHttpQuery.headers = [{ name: keyValue['name'], value: "{-" + HTTP_QUERY_KEY_AUTH_NAME + "-}" }];
            }
            else if (keyValue['send_as'] == HttpQueryKeyAuthSendAs.QueryParam) {
                baseHttpQuery.queryParams = [{ name: keyValue['name'], value: "{-" + HTTP_QUERY_KEY_AUTH_NAME + "-}" }];
            }
        }
        else if (value['auth_type'] == HttpQueryAuthType.BasicAuth) {
            hasValues = true;
            baseHttpQuery.authType = value['auth_type'];
            baseHttpQuery.headers = [{ name: 'Authorization', value: "Basic {-" + HTTP_QUERY_BASIC_AUTH_VALUE + "-}" }];
        }
        else if (value['auth_type'] == HttpQueryAuthType.OAuth2) {
            hasValues = true;
            baseHttpQuery.authType = value['auth_type'];
            baseHttpQuery.headers = [{ name: 'Authorization', value: "Bearer " + value['access_token_name'] }];
            var newParams_1 = cloneDeep(value['auth_params']);
            if (newParams_1['backend']) {
                var backend = socialBackends.find(function (item) { return item.name == newParams_1['backend']; });
                newParams_1['backend'] = backend ? backend.path : undefined;
            }
            baseHttpQuery.authParams = newParams_1;
        }
        if (keyFormValue['value']) {
            var token = new SecretToken();
            token.name = HTTP_QUERY_KEY_AUTH_NAME;
            token.value = keyFormValue['value'];
            tokens.push(token);
        }
        if (basicFormValue['username'] && basicFormValue['password']) {
            try {
                var token = new SecretToken();
                token.name = HTTP_QUERY_BASIC_AUTH_VALUE;
                token.value = btoa([basicFormValue['username'], basicFormValue['password']].join(':'));
                tokens.push(token);
            }
            catch (e) { }
        }
        if (hasValues) {
            params.baseHttpQuery = baseHttpQuery;
        }
        var rateLimitActions = parseNumber(value['rate_limit_actions']);
        var rateLimitPerSeconds = parseNumber(value['rate_limit_per_seconds']);
        if (rateLimitActions && rateLimitPerSeconds) {
            params.rateLimit = new RateLimit({
                actions: rateLimitActions,
                perMs: rateLimitPerSeconds * 1000
            });
        }
        else {
            params.rateLimit = undefined;
        }
        params.customProxy = value['custom_proxy'];
        var modelDescriptions$;
        if (!this.resource) {
            modelDescriptions$ = this.templateService.getResourceInitialModelDescriptions(this.typeItem.name).pipe(map(function (template) {
                if (!template) {
                    return;
                }
                return template.modelDescriptions.map(function (item) {
                    item.model = ModelDescription.generateModel();
                    item.resource = '{{resource}}';
                    item.demo = true;
                    return item.serialize();
                });
            }));
        }
        else {
            modelDescriptions$ = of(undefined);
        }
        return modelDescriptions$.pipe(map(function (modelDescriptions) {
            return {
                resourceName: _this.resourceForm.value['name'],
                resourceParams: params.serialize(),
                modelDescriptions: modelDescriptions,
                secretTokens: tokens.map(function (item) { return item.serialize(); })
            };
        }));
    };
    return RestApiResourceSettingsForm;
}(BaseResourceSettingsForm));
export { RestApiResourceSettingsForm };

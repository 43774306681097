/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../customize-bar/components/customize-bar-header/customize-bar-header.component.ngfactory";
import * as i2 from "../../../customize-bar/components/customize-bar-header/customize-bar-header.component";
import * as i3 from "../../../customize-bar/services/customize-bar-context/customize-bar.context";
import * as i4 from "../../../sidebar/components/sidebar-section/sidebar-section.component.ngfactory";
import * as i5 from "../../../sidebar/components/sidebar-section/sidebar-section.component";
import * as i6 from "../../../sidebar/components/sidebar-field/sidebar-field.component.ngfactory";
import * as i7 from "../../../sidebar/components/sidebar-field/sidebar-field.component";
import * as i8 from "@angular/common";
import * as i9 from "./customize-bar-domain-edit.component";
import * as i10 from "../../../projects/stores/current-project.store";
import * as i11 from "../../../domain-components/services/project-domain/project-domain.controller";
var styles_CustomizeBarDomainEditComponent = [];
var RenderType_CustomizeBarDomainEditComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CustomizeBarDomainEditComponent, data: {} });
export { RenderType_CustomizeBarDomainEditComponent as RenderType_CustomizeBarDomainEditComponent };
function View_CustomizeBarDomainEditComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 9, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "div", [["class", "sidebar-list-item__left"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 0, "span", [["class", "sidebar-list-item__icon icon-earth_planet"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 4, "div", [["class", "sidebar-list-item__main"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 1, "div", [["class", "sidebar-list-item__title"]], null, null, null, null, null)), (_l()(), i0.ɵted(5, null, ["", ""])), (_l()(), i0.ɵeld(6, 0, null, null, 1, "div", [["class", "sidebar-list-item__description"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Domain is configured"])), (_l()(), i0.ɵeld(8, 0, null, null, 1, "div", [["class", "sidebar-list-item__right"]], null, null, null, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 0, "a", [["class", "sidebar-list-item__action icon-edit"], ["href", "javascript:void(0)"]], null, null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.domain.domain; _ck(_v, 5, 0, currVal_0); }); }
function View_CustomizeBarDomainEditComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "div", [["class", "sidebar-list-item__left"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 0, "span", [["class", "sidebar-list-item__icon icon-earth_planet"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 2, "div", [["class", "sidebar-list-item__main"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 1, "div", [["class", "sidebar-list-item__title sidebar-list-item__title_secondary"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Add custom domain"])), (_l()(), i0.ɵeld(6, 0, null, null, 1, "div", [["class", "sidebar-list-item__right"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 0, "span", [["class", "sidebar-list-item__action icon-arrow_forward_2"]], null, null, null, null, null))], null, null); }
export function View_CustomizeBarDomainEditComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-customize-bar-header", [], null, null, null, i1.View_CustomizeBarHeaderComponent_0, i1.RenderType_CustomizeBarHeaderComponent)), i0.ɵdid(1, 180224, null, 0, i2.CustomizeBarHeaderComponent, [[2, i3.CustomizeBarContext]], { title: [0, "title"], backEnabled: [1, "backEnabled"], subtitle: [2, "subtitle"] }, null), (_l()(), i0.ɵeld(2, 0, null, null, 10, "div", [["class", "sidebar__content"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 9, "app-sidebar-section", [], null, null, null, i4.View_SidebarSectionComponent_0, i4.RenderType_SidebarSectionComponent)), i0.ɵdid(4, 114688, null, 0, i5.SidebarSectionComponent, [], { title: [0, "title"], description: [1, "description"] }, null), (_l()(), i0.ɵeld(5, 0, null, 3, 7, "div", [["class", "sidebar__element"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 6, "app-sidebar-field", [], null, null, null, i6.View_SidebarFieldComponent_0, i6.RenderType_SidebarFieldComponent)), i0.ɵdid(7, 114688, null, 0, i7.SidebarFieldComponent, [], null, null), (_l()(), i0.ɵeld(8, 0, null, 3, 4, "div", [["class", "sidebar-list-item sidebar-list-item_clickable"]], [[2, "sidebar-list-item_error", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.editDomain() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CustomizeBarDomainEditComponent_1)), i0.ɵdid(10, 16384, null, 0, i8.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CustomizeBarDomainEditComponent_2)), i0.ɵdid(12, 16384, null, 0, i8.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "Custom Domain"; var currVal_1 = false; var currVal_2 = "App settings"; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = "Custom Domain"; var currVal_4 = "App will be available under this domain"; _ck(_v, 4, 0, currVal_3, currVal_4); _ck(_v, 7, 0); var currVal_6 = _co.domain; _ck(_v, 10, 0, currVal_6); var currVal_7 = !_co.domain; _ck(_v, 12, 0, currVal_7); }, function (_ck, _v) { var currVal_5 = false; _ck(_v, 8, 0, currVal_5); }); }
export function View_CustomizeBarDomainEditComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-customize-bar-domain-edit", [], null, null, null, View_CustomizeBarDomainEditComponent_0, RenderType_CustomizeBarDomainEditComponent)), i0.ɵdid(1, 245760, null, 0, i9.CustomizeBarDomainEditComponent, [i10.CurrentProjectStore, i11.ProjectDomainController, i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CustomizeBarDomainEditComponentNgFactory = i0.ɵccf("app-customize-bar-domain-edit", i9.CustomizeBarDomainEditComponent, View_CustomizeBarDomainEditComponent_Host_0, { projectForm: "projectForm" }, {}, []);
export { CustomizeBarDomainEditComponentNgFactory as CustomizeBarDomainEditComponentNgFactory };

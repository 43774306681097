import pickBy from 'lodash/pickBy';

import { generateAlphanumeric } from '@shared';

import { WorkflowStepType } from '../workflow-step-type';
import { deserializerWorkflowStep, registerWorkflowStepType } from '../workflow-step-types';
import { WorkflowStep } from './base';

export class ForkWorkflowStepItem {
  public uid: string;
  public steps: WorkflowStep[] = [];

  deserialize(data: Object) {
    this.uid = data['uid'];

    if (data['steps']) {
      this.steps = data['steps'].map(item => deserializerWorkflowStep(item)).filter(item => item);
    }

    return this;
  }

  serialize(fields?: string[]) {
    let data: Object = {
      uid: this.uid,
      steps: this.steps.map(item => item.serialize())
    };
    if (fields) {
      data = <Object>pickBy(data, (v, k) => fields.includes(k));
    }
    return data;
  }

  generateUid() {
    this.uid = generateAlphanumeric(8, { letterFirst: true });
  }
}

export class ForkWorkflowStep extends WorkflowStep {
  public type = WorkflowStepType.Fork;
  public items: ForkWorkflowStepItem[] = [];

  deserialize(data: Object) {
    super.deserialize(data);

    if (data['items']) {
      this.items = data['items'].map(item => new ForkWorkflowStepItem().deserialize(item));
    }

    return this;
  }

  serialize(fields?: string[]) {
    let data: Object = {
      ...super.serialize(fields),
      items: this.items.map(item => item.serialize())
    };
    if (fields) {
      data = <Object>pickBy(data, (v, k) => fields.includes(k));
    }
    return data;
  }

  defaultName() {
    return 'Parallelize';
  }

  get analyticsName(): string {
    return 'fork';
  }

  getIcon() {
    return 'matches';
  }
}

registerWorkflowStepType(WorkflowStepType.Fork, ForkWorkflowStep);

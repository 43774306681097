<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayPanelClass]="['overlay', 'overlay_horizontal_center']"
  [cdkConnectedOverlayPositions]="dropdownPositions"
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayOpen]="dropdownOpened"
  [cdkConnectedOverlayPush]="true"
  [cdkConnectedOverlayGrowAfterOpen]="true"
  [cdkConnectedOverlayHasBackdrop]="true"
  [cdkConnectedOverlayBackdropClass]="'popover2-backdrop'"
  (backdropClick)="setDropdownOpened(false)"
  (detach)="setDropdownOpened(false)"
>
  <div class="compact-select-options compact-select-options_max-height_l">
    <div
      *ngIf="templatesEnabled"
      class="compact-select-options__item compact-select-option"
      (click)="openCustomViewTemplates(); setDropdownOpened(false)"
    >
      <div class="compact-select-option__main">
        <div class="compact-select-option__row">
          <div class="compact-select-option__title">Import from Marketplace</div>
        </div>
      </div>
    </div>

    <div
      class="compact-select-options__item compact-select-option"
      (click)="importFigmaNode.emit(); setDropdownOpened(false)"
    >
      <div class="compact-select-option__main">
        <div class="compact-select-option__row">
          <div class="compact-select-option__title">Import from Figma</div>
        </div>
      </div>
    </div>

    <div
      class="compact-select-options__item compact-select-option"
      (click)="importSketchFile.emit(); setDropdownOpened(false)"
    >
      <div class="compact-select-option__main">
        <div class="compact-select-option__row">
          <div class="compact-select-option__title">Import from Sketch file</div>
        </div>
      </div>
    </div>

    <div class="compact-select-options__separator"></div>

    <div
      class="compact-select-options__item compact-select-option"
      [class.compact-select-option_active]="menu_edit.dropdownOpened"
      cdkOverlayOrigin
      #trigger="cdkOverlayOrigin"
      (click)="setDropdownOpened(false)"
    >
      <div class="compact-select-option__main">
        <div class="compact-select-option__row">
          <div class="compact-select-option__title">Edit</div>
        </div>
      </div>

      <div class="compact-select-option__right">
        <div class="compact-select-option__arrow icon-arrow_forward_2"></div>
      </div>
    </div>

    <app-view-editor-menu-edit
      [trigger]="trigger"
      (close)="setDropdownOpened(false)"
      #menu_edit
    ></app-view-editor-menu-edit>

    <div class="compact-select-options__item compact-select-option" (click)="rename.emit(); setDropdownOpened(false)">
      <div class="compact-select-option__main">
        <div class="compact-select-option__row">
          <div class="compact-select-option__title">Rename component</div>
        </div>
      </div>
    </div>

    <div
      class="compact-select-options__item compact-select-option"
      (click)="editorContext.customizeView(); setDropdownOpened(false)"
    >
      <div class="compact-select-option__main">
        <div class="compact-select-option__row">
          <div class="compact-select-option__title">Canvas setup</div>
        </div>
      </div>
    </div>

    <div class="compact-select-options__separator"></div>

    <div
      class="compact-select-options__item compact-select-option"
      (click)="saveEditor.emit(); setDropdownOpened(false)"
    >
      <div class="compact-select-option__main">
        <div class="compact-select-option__row">
          <div class="compact-select-option__title">Save component and close</div>
        </div>
      </div>
    </div>

    <div
      class="compact-select-options__item compact-select-option"
      (click)="closeEditor.emit(); setDropdownOpened(false)"
    >
      <div class="compact-select-option__main">
        <div class="compact-select-option__row">
          <div class="compact-select-option__title">Close</div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

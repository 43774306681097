/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../field-components/components/image-field/image-field.component.ngfactory";
import * as i2 from "../../../field-components/components/image-field/image-field.component";
import * as i3 from "../../../model-queries/services/model/model.service";
import * as i4 from "../../../projects/stores/current-project.store";
import * as i5 from "../../../projects/stores/current-environment.store";
import * as i6 from "../../../storages-queries/services/storage/storage.service";
import * as i7 from "../../../resources/services/resource-controller/resource-controller.service";
import * as i8 from "./model-card-item/model-card-item.component.ngfactory";
import * as i9 from "./model-card-item/model-card-item.component";
import * as i10 from "../../../custom-views/stores/custom-views.store";
import * as i11 from "@angular/common";
import * as i12 from "./model-card.component";
import * as i13 from "../../../action-queries/services/action/action.service";
var styles_ModelCardComponent = [];
var RenderType_ModelCardComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ModelCardComponent, data: {} });
export { RenderType_ModelCardComponent as RenderType_ModelCardComponent };
function View_ModelCardComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "model-card__image"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "app-image-field", [], null, null, null, i1.View_ImageFieldComponent_0, i1.RenderType_ImageFieldComponent)), i0.ɵdid(2, 770048, null, 0, i2.ImageFieldComponent, [i3.ModelService, i4.CurrentProjectStore, i5.CurrentEnvironmentStore, i6.StorageService, i7.ResourceControllerService, i0.ChangeDetectorRef], { field: [0, "field"], readonly: [1, "readonly"], value: [2, "value"], label: [3, "label"], context: [4, "context"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.createField(_v.parent.context.$implicit); var currVal_1 = true; var currVal_2 = _co.item.model.getAttribute(_v.parent.context.$implicit.name); var currVal_3 = false; var currVal_4 = ((_co.contextElement == null) ? null : _co.contextElement.context); _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }, null); }
function View_ModelCardComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-model-card-item", [], null, null, null, i8.View_ModelCardItemComponent_0, i8.RenderType_ModelCardItemComponent)), i0.ɵdid(1, 770048, null, 0, i9.ModelCardItemComponent, [i10.CustomViewsStore, i0.ChangeDetectorRef], { item: [0, "item"], model: [1, "model"], columns: [2, "columns"], valueStr: [3, "valueStr"], label: [4, "label"], columnActions: [5, "columnActions"], context: [6, "context"], contextElement: [7, "contextElement"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.parent.context.$implicit; var currVal_1 = _co.item.model; var currVal_2 = _co.columns; var currVal_3 = _co.getValueStr(_v.parent.context.$implicit); var currVal_4 = _co.labels; var currVal_5 = _co.columnActions; var currVal_6 = _co.context; var currVal_7 = _co.contextElement; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }, null); }
function View_ModelCardComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ModelCardComponent_2)), i0.ɵdid(2, 16384, null, 0, i11.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ModelCardComponent_3)), i0.ɵdid(4, 16384, null, 0, i11.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_v.context.$implicit.field == _co.fieldTypes.Image); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_v.context.$implicit.field != _co.fieldTypes.Image); _ck(_v, 4, 0, currVal_1); }, null); }
export function View_ModelCardComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "model-card"]], [[2, "model-card_vertical", null], [2, "model-card_compact", null]], null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ModelCardComponent_1)), i0.ɵdid(2, 278528, null, 0, i11.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"], ngForTrackBy: [1, "ngForTrackBy"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.visibleColumns; var currVal_3 = _co.trackByFn; _ck(_v, 2, 0, currVal_2, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.vertical; var currVal_1 = _co.compact; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
export function View_ModelCardComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-model-card", [], null, null, null, View_ModelCardComponent_0, RenderType_ModelCardComponent)), i0.ɵdid(1, 245760, null, 0, i12.ModelCardComponent, [i13.ActionService, i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ModelCardComponentNgFactory = i0.ɵccf("app-model-card", i12.ModelCardComponent, View_ModelCardComponent_Host_0, { item: "item", modelDescription: "modelDescription", visibleColumns: "visibleColumns", columns: "columns", columnActions: "columnActions", labels: "labels", vertical: "vertical", compact: "compact", context: "context", contextElement: "contextElement" }, { modelUpdated: "modelUpdated" }, []);
export { ModelCardComponentNgFactory as ModelCardComponentNgFactory };

import { Injectable } from '@angular/core';
import { Option, SelectSource } from 'ng-gxselect';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { CurrentEnvironmentStore, CurrentProjectStore, Resource } from '@modules/projects';
import { Storage } from '@modules/storages';
import { isSet } from '@shared';

@Injectable()
export class StorageSelectSource extends SelectSource {
  allowEmpty = false;
  emptyName = '---';

  private page = 1;
  private totalPages = 1;

  constructor(
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore
  ) {
    super();
  }

  get emptyOption() {
    return {
      value: null,
      name: this.emptyName
    };
  }

  image(uniqueName: string) {
    const resource = this.currentEnvironmentStore.resources.find(item => item.uniqueName == uniqueName);

    if (!resource) {
      return;
    }

    return resource.icon;
  }

  getStorages(): Observable<{ resource: Resource; storage: Storage }[]> {
    return this.currentProjectStore.getFirst().pipe(
      map(result => {
        if (!result) {
          return [];
        }

        return result
          .getStorages(this.currentEnvironmentStore.instance.uniqueName)
          .filter(item => item.storage.isConfigured());
      })
    );
  }

  fetch(searchQuery: string): Observable<Option[]> {
    searchQuery = (searchQuery || '').trim();

    return this.getStorages().pipe(
      map(result => result.filter(item => !item.resource.demo)),
      map(result => {
        this.page += 1;

        const results = result
          .filter(item =>
            isSet(searchQuery)
              ? [item.resource.name, item.resource.uniqueName].some(str =>
                  str.toLowerCase().includes(searchQuery.toLowerCase())
                )
              : true
          )
          .map(item => {
            return {
              value: { resource: item.resource.uniqueName, name: item.storage.uniqueName },
              name: [item.resource.name, item.storage.name].join(' - '),
              image: this.image(item.resource.uniqueName)
            };
          });

        if (!this.allowEmpty) {
          return results;
        }

        return [this.emptyOption].concat(results);
      })
    );
  }

  fetchByValue(value: any): Observable<Option> {
    if (this.allowEmpty && value === null) {
      return of(this.emptyOption);
    }

    if (!value) {
      return of(undefined);
    }

    return this.getStorages().pipe(
      map(result => {
        const item = result.find(
          i => i.resource.uniqueName == value['resource'] && i.storage.uniqueName == value['name']
        );

        if (!item) {
          return;
        }

        return {
          value: { resource: item.resource.uniqueName, name: item.storage.uniqueName },
          name: [item.resource.name, item.storage.name].join(' - '),
          image: this.image(item.resource.uniqueName)
        };
      })
    );
  }

  isFetchAvailable(): boolean {
    return this.page <= this.totalPages;
  }

  resetPagination() {
    this.page = 1;
    this.totalPages = 1;
  }

  setStaticOptions(options: Option[]) {}
}

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';

import { FlexLayoutAlign } from '@modules/views';

import { FlexLayoutControl } from '../../controls/flex-layout';

@Component({
  selector: 'app-flex-layout-control',
  templateUrl: './flex-layout-control.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FlexLayoutControlComponent implements OnInit, OnDestroy {
  @Input() control: FlexLayoutControl;

  vertical = false;
  wrap = false;
  aligns = [FlexLayoutAlign.Start, FlexLayoutAlign.Center, FlexLayoutAlign.End];
  flexAligns = FlexLayoutAlign;

  constructor(private cd: ChangeDetectorRef) {}

  ngOnInit() {
    this.control
      .isVerticalOrientation$()
      .pipe(untilDestroyed(this))
      .subscribe(value => {
        this.vertical = value;
        this.cd.markForCheck();
      });

    this.control
      .isWrapOrientation$()
      .pipe(untilDestroyed(this))
      .subscribe(value => {
        this.wrap = value;
        this.cd.markForCheck();
      });
  }

  ngOnDestroy(): void {}
}

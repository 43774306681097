import isArray from 'lodash/isArray';
import isEqual from 'lodash/isEqual';
import pickBy from 'lodash/pickBy';
import Delta from 'quill-delta';

import { cleanWrapSpaces, isSet } from '@shared';
// // TODO: Refactor import
// import { singleTokenFormulaToContextValue } from '../../parameters/utils/formula';

import { mathjs } from '../utils/math';
import { InputValueType } from './input-value-type';

export function singleTokenFormulaToContextValue(formulaValue: string): (string | number)[] {
  if (typeof formulaValue !== 'string') {
    return formulaValue;
  }

  try {
    const result = [];
    let node = mathjs.parse(cleanWrapSpaces(formulaValue));

    while (node) {
      if (node && node.isAccessorNode) {
        const name = node.index.dimensions[0].value;
        result.push(name);
        node = node.object;
      } else if (node && node.isSymbolNode) {
        result.push(node.name);
        break;
      } else {
        return;
      }
    }

    return result.reverse();
  } catch (e) {
    return;
  }
}

export function getInputValueTypeStr(
  valueType: InputValueType,
  options: {
    context?: 'page' | 'component' | 'workflow';
  } = {}
): string {
  if (valueType == InputValueType.Prompt) {
    return 'Ask user';
  } else if (valueType == InputValueType.EmptyString) {
    return 'Empty string';
  } else if (valueType == InputValueType.Null) {
    return 'Null';
  } else if (valueType == InputValueType.StaticValue) {
    return 'Static value';
  } else if (valueType == InputValueType.Filter) {
    return 'Component filter';
  } else if (valueType == InputValueType.Context) {
    if (options.context == 'workflow') {
      return 'Workflow step value';
    } else if (options.context == 'component') {
      return 'Component value';
    } else {
      return 'Page value';
    }
  } else if (valueType == InputValueType.Formula) {
    return 'Computed value';
  } else if (valueType == InputValueType.TextInputs) {
    return 'Text with Inputs';
  } else if (valueType == InputValueType.Js) {
    return 'JavaScript';
  }
}

export enum MarkupType {
  Text = 'text',
  HTML = 'html',
  Markdown = 'Markdown'
}

export class Input {
  name: string;
  valueType: InputValueType;
  staticValue: any;
  contextValue: (string | number)[];
  filterField: string;
  filterLookup: string;
  formulaValue: string;
  textInputsType: MarkupType = MarkupType.Text;
  textInputsValue: Delta;
  jsValue: string;
  array = false;
  required = false;

  deserialize(data: Object): Input {
    this.name = data['name'];
    this.valueType = data['value_type'];
    this.staticValue = data['static_value'];

    if (isArray(data['context_value'])) {
      this.contextValue = data['context_value'];
    } else if (typeof data['context_value'] == 'string') {
      // Backward compatibility
      this.contextValue = singleTokenFormulaToContextValue(data['context_value']);
    }

    this.filterField = data['filter_field'];
    this.filterLookup = data['filter_lookup'];
    this.formulaValue = data['formula_value'];
    this.jsValue = data['js_value'];

    if (data['text_inputs_type']) {
      this.textInputsType = data['text_inputs_type'];
    }

    if (data['text_inputs_value']) {
      this.textInputsValue = data['text_inputs_value'];
    }

    if (data['required'] !== undefined) {
      this.required = data['required'];
    }

    return this;
  }

  deserializeFromStatic(name: string, value: string): Input {
    this.name = name;
    this.valueType = InputValueType.StaticValue;
    this.staticValue = value;

    return this;
  }

  serialize() {
    return {
      name: this.name,
      value_type: this.valueType,
      static_value: this.staticValue,
      context_value: this.contextValue,
      filter_field: this.filterField,
      filter_lookup: this.filterLookup,
      formula_value: this.formulaValue,
      text_inputs_type: this.textInputsType,
      text_inputs_value: this.textInputsValue,
      js_value: this.jsValue,
      required: this.required
    };
  }

  isSet() {
    if (this.valueType == InputValueType.Prompt) {
      return true;
    } else if (this.valueType == InputValueType.EmptyString) {
      return true;
    } else if (this.valueType == InputValueType.Null) {
      return true;
    } else if (this.valueType == InputValueType.StaticValue) {
      return isSet(this.staticValue);
    } else if (this.valueType == InputValueType.Filter) {
      return isSet(this.filterField) && isSet(this.filterLookup);
    } else if (this.valueType == InputValueType.Context) {
      return isSet(this.contextValue);
    } else if (this.valueType == InputValueType.Formula) {
      return isSet(this.formulaValue);
    } else if (this.valueType == InputValueType.TextInputs) {
      return this.textInputsValue && this.textInputsValue.ops.length;
    } else if (this.valueType == InputValueType.Js) {
      return isSet(this.jsValue);
    } else {
      return false;
    }
  }

  valueTypeStr(
    options: {
      context?: 'page' | 'workflow';
    } = {}
  ): string {
    return getInputValueTypeStr(this.valueType, options);
  }

  contextValueEquals(value: string[]) {
    return this.valueType == InputValueType.Context && isEqual(this.contextValue, value);
  }

  contextValueStartsWith(value: string[]) {
    return this.valueType == InputValueType.Context && isEqual(this.contextValue.slice(0, value.length), value);
  }
}

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./page-templates-choose-item-type.component";
import * as i3 from "../../../projects/stores/current-environment.store";
import * as i4 from "../../../template-generators/services/page-templates-generator/page-templates-generator.service";
import * as i5 from "../../../model-queries/stores/model-description.store";
import * as i6 from "../../../theme/services/theme/theme.service";
var styles_PageTemplatesChooseItemTypeComponent = [];
var RenderType_PageTemplatesChooseItemTypeComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_PageTemplatesChooseItemTypeComponent, data: {} });
export { RenderType_PageTemplatesChooseItemTypeComponent as RenderType_PageTemplatesChooseItemTypeComponent };
function View_PageTemplatesChooseItemTypeComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["class", "choose-template-list-item__left"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "span", [["class", "choose-template-list-item__image"]], [[4, "background-image", null]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getTemplateIcon(_v.parent.context.$implicit); _ck(_v, 1, 0, currVal_0); }); }
function View_PageTemplatesChooseItemTypeComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["class", "choose-template-list-item__subtitle"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.description; _ck(_v, 1, 0, currVal_0); }); }
function View_PageTemplatesChooseItemTypeComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["class", "choose-template-list-item__switch-item"]], [[2, "choose-template-list-item__switch-item_active", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        $event.stopPropagation();
        _co.setSelectedTemplateVariant(_v.context.$implicit.type);
        var pd_0 = (_co.submit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_v.context.$implicit.type == _co.selectedTemplateVariant); _ck(_v, 0, 0, currVal_0); var currVal_1 = _v.context.$implicit.label; _ck(_v, 1, 0, currVal_1); }); }
function View_PageTemplatesChooseItemTypeComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["class", "choose-template-list-item__row choose-template-list-item__active-only"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "div", [["class", "choose-template-list-item__switch"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PageTemplatesChooseItemTypeComponent_5)), i0.ɵdid(3, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.variants; _ck(_v, 3, 0, currVal_0); }, null); }
function View_PageTemplatesChooseItemTypeComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 11, "div", [["class", "choose-template-list__item"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 10, "div", [["class", "choose-template-list-item choose-template-list-item_complex choose-template-list-item_border choose-template-list-item_interactive"]], [[2, "choose-template-list-item_active", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        _co.setSelectedTemplate(_v.context.$implicit);
        var pd_0 = (_co.submit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 7, "div", [["class", "choose-template-list-item__row"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PageTemplatesChooseItemTypeComponent_2)), i0.ɵdid(4, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(5, 0, null, null, 4, "div", [["class", "choose-template-list-item__main"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 1, "div", [["class", "choose-template-list-item__title"]], null, null, null, null, null)), (_l()(), i0.ɵted(7, null, ["", ""])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PageTemplatesChooseItemTypeComponent_3)), i0.ɵdid(9, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PageTemplatesChooseItemTypeComponent_4)), i0.ɵdid(11, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_1 = _v.context.$implicit.image; _ck(_v, 4, 0, currVal_1); var currVal_3 = _v.context.$implicit.description; _ck(_v, 9, 0, currVal_3); var currVal_4 = ((_v.context.$implicit.variants == null) ? null : _v.context.$implicit.variants.length); _ck(_v, 11, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.selectedTemplate === _v.context.$implicit); _ck(_v, 1, 0, currVal_0); var currVal_2 = _v.context.$implicit.name; _ck(_v, 7, 0, currVal_2); }); }
export function View_PageTemplatesChooseItemTypeComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["class", "choose-template__content-navigation-list"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "div", [["class", "choose-template-list"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PageTemplatesChooseItemTypeComponent_1)), i0.ɵdid(3, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.templates; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_PageTemplatesChooseItemTypeComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-page-templates-choose-item-type", [], null, null, null, View_PageTemplatesChooseItemTypeComponent_0, RenderType_PageTemplatesChooseItemTypeComponent)), i0.ɵdid(1, 245760, null, 0, i2.PageTemplatesChooseItemTypeComponent, [i3.CurrentEnvironmentStore, i4.PageTemplatesGeneratorService, i5.ModelDescriptionStore, i6.ThemeService, i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PageTemplatesChooseItemTypeComponentNgFactory = i0.ɵccf("app-page-templates-choose-item-type", i2.PageTemplatesChooseItemTypeComponent, View_PageTemplatesChooseItemTypeComponent_Host_0, { newPage: "newPage" }, { selectTemplate: "selectTemplate", cancelClick: "cancelClick" }, []);
export { PageTemplatesChooseItemTypeComponentNgFactory as PageTemplatesChooseItemTypeComponentNgFactory };

import { Injectable, OnDestroy, TemplateRef } from '@angular/core';
import last from 'lodash/last';
import * as moment from 'moment';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest, Observable, of, Subject, Subscription, throwError, timer } from 'rxjs';
import { catchError, debounce, debounceTime, filter, map, skip, switchMap, tap } from 'rxjs/operators';

import { ActionOrigin } from '@modules/action-queries';
import { ServerRequestError } from '@modules/api';
import { CustomizeBarItem } from '@modules/change-components/data/customize-bar-item';
import { forceObservable } from '@shared';

import { CustomizeSelection } from '../../data/customize-selection';
import { ElementItem } from '../../data/elements/items/base';
import { PopupPosition, PopupSettings, PopupStyle } from '../../data/popup';
import { ViewSettings } from '../../data/view-settings/base';

export enum CustomizeType {
  Menu = 'menu',
  Layout = 'layout',
  Settings = 'settings'
}

export enum CustomizeExitAction {
  Cancel,
  Reset,
  Save
}

export interface CustomizeHandlerInfo {
  breadcrumbs: string[];
  title?: string;
  page?: ViewSettings;
  inAppDisabled?: boolean;
}

// export interface CustomizeSaveResult {
//   viewSettings?: ViewSettings;
// }

// export interface CustomizeChange<T = any> {
//   value: T;
//   hasChanges: boolean;
// }

// export interface CustomizeChanges {
//   [k: string]: CustomizeChange;
// }

export interface CustomizeHandler<S = any> {
  renameHandler?: (name: string) => void;
  openHandlerSettings?(): void;
  duplicate?(): Observable<boolean>;
  getPage?(): ViewSettings;

  // Tracking changes
  getChangesState?(): S | Observable<S>;
  setChangesState?(state: S);
  isChangesStateEqual?(lhs: S, rhs: S): boolean | Observable<boolean>;
  saveChangesState?(state: S): Observable<S>;

  reload?(): Observable<any>;
  openPopup?(uid: string, options?: CustomizeOpenPopupOptions): void;
  closePopup?(uid?: string): void;
  createPopup?(open?: boolean, options?: CustomizeCreatePopupOptions): PopupSettings;
  updatePopup?(uid: string, newPopup: PopupSettings): void;
  getOpenedPopup?(): PopupSettings;

  getCollaborationParams?(): Object;
  getUserActivitiesParams?(): Object;
}

export interface CustomizeOpenPopupOptions {
  params?: Object;
  openComponents?: boolean;
  customize?: boolean;
  togglePopup?: boolean;
  origin?: ActionOrigin;
}

export interface CustomizeCreatePopupOptions {
  width?: number;
  defaultName?: string;
  style?: PopupStyle;
  position?: PopupPosition;
  overlay?: boolean;
  analyticsSource?: string;
}

@Injectable({
  providedIn: 'root'
})
export class CustomizeService implements OnDestroy {
  _selection = new BehaviorSubject<boolean>(false);
  // _animating = new BehaviorSubject<boolean>(false);
  _enabled = new BehaviorSubject<CustomizeType>(undefined);
  _layoutCustomization = new BehaviorSubject<CustomizeSelection>(undefined);
  _lastHovered = new BehaviorSubject<any>(undefined);
  _handler = new BehaviorSubject<CustomizeHandler>(undefined);
  _handlerInfo = new BehaviorSubject<CustomizeHandlerInfo>(undefined);
  _handlerHeaderLeft = new BehaviorSubject<TemplateRef<unknown>>(undefined);
  _handlerHeaderCenter = new BehaviorSubject<TemplateRef<unknown>>(undefined);
  hovered = [];
  hoveredForced: moment.Moment;

  _publishRequested = new Subject<void>(); // TODO: Refactor
  createdElements: { element: ElementItem; barItem?: CustomizeBarItem }[] = [];

  public isUndoAvailable$: Observable<boolean>;
  public isRedoAvailable$: Observable<boolean>;

  private _changed$ = new Subject<boolean>();
  private changesHistory$ = new BehaviorSubject<any[]>([]);
  private changesHistoryIndex$ = new BehaviorSubject<number>(0);
  private changesStartTrackSubscription: Subscription;
  private changesAddHistorySubscription: Subscription;
  private changesSaveSubscription: Subscription;
  private _changesSaving$ = new BehaviorSubject<boolean>(false);
  private _changesSaveError$ = new BehaviorSubject<string>(undefined);
  private _createElement = new Subject<CustomizeBarItem>();
  // historyMaxPrevious = 50;

  constructor() {
    this.isUndoAvailable$ = this.hasChangesHistory$(-1);
    this.isRedoAvailable$ = this.hasChangesHistory$(1);
  }

  ngOnDestroy(): void {}

  get enabled(): CustomizeType {
    return this._enabled.value;
  }

  get enabled$(): Observable<CustomizeType> {
    return this._enabled.asObservable();
  }

  set enabled(value: CustomizeType) {
    if (this.enabled == value) {
      return;
    }

    this._enabled.next(value);

    if (value != undefined) {
      this.selection = false;
    }
  }

  get menuEnabled(): boolean {
    return this._enabled.value == CustomizeType.Menu;
  }

  get menuEnabled$(): Observable<boolean> {
    return this._enabled.asObservable().pipe(map(item => item == CustomizeType.Menu));
  }

  get layoutEnabled(): boolean {
    return this._enabled.value == CustomizeType.Layout;
  }

  get layoutEnabled$(): Observable<boolean> {
    return this._enabled.asObservable().pipe(map(item => item == CustomizeType.Layout));
  }

  get anyEnabled$(): Observable<boolean> {
    return this._enabled.asObservable().pipe(map(item => item != undefined));
  }

  // changes$() {
  //   return this._changes.asObservable();
  // }
  //
  // markChanged() {
  //   this._changes.next(undefined);
  // }

  toggleEnabled(value: CustomizeType) {
    this.enabled = this.enabled != value ? value : undefined;
  }

  get selection(): boolean {
    return this._selection.value;
  }

  get selection$(): Observable<boolean> {
    return this._selection.asObservable();
  }

  set selection(value: boolean) {
    if (this.selection == value) {
      return;
    }

    this._selection.next(value);

    if (value) {
      this.enabled = undefined;
    }
  }

  // get animating(): boolean {
  //   return this._animating.value;
  // }
  //
  // get animating$(): Observable<boolean> {
  //   return this._animating.asObservable();
  // }
  //
  // set animating(value: boolean) {
  //   this._animating.next(value);
  // }

  get layoutCustomization(): CustomizeSelection {
    return this._layoutCustomization.value;
  }

  get layoutCustomization$(): Observable<CustomizeSelection> {
    return this._layoutCustomization.asObservable();
  }

  set layoutCustomization(value: CustomizeSelection) {
    this._layoutCustomization.next(value);
  }

  toggleSelection() {
    this.selection = !this.selection;
  }

  addHover(any) {
    const timeSinceHoveredForced = this.hoveredForced ? moment().diff(this.hoveredForced, 'milliseconds') : undefined;

    if (timeSinceHoveredForced !== undefined && timeSinceHoveredForced <= 60) {
      return;
    }

    this.hovered.push(any);
    this.updateLastHovered();
  }

  removeHover(any) {
    this.hovered = this.hovered.filter(item => item !== any);
    this.updateLastHovered();
  }

  forceHover(any) {
    const index = this.hovered.findIndex(item => item === any);

    if (index !== -1 && index < this.hovered.length - 1) {
      this.hovered = this.hovered.slice(0, index + 1);
      this.updateLastHovered();
    }

    this.hoveredForced = moment();
  }

  updateLastHovered() {
    let lastHovered;

    if (this.hovered.length) {
      lastHovered = this.hovered[this.hovered.length - 1];
    } else {
      lastHovered = undefined;
    }

    if (this._lastHovered.value === lastHovered) {
      return;
    }

    this._lastHovered.next(lastHovered);
  }

  get lastHovered$(): Observable<any> {
    return this._lastHovered.asObservable();
  }

  get handler(): CustomizeHandler {
    return this._handler.value;
  }

  get handler$(): Observable<CustomizeHandler> {
    return this._handler.asObservable();
  }

  setHandler(handler: CustomizeHandler) {
    if (this.handler === handler) {
      return;
    }

    this._handler.next(handler);
    this._handlerInfo.next(undefined);
  }

  get handlerInfo(): CustomizeHandlerInfo {
    return this._handlerInfo.value;
  }

  get handlerInfo$(): Observable<CustomizeHandlerInfo> {
    return this._handlerInfo.asObservable();
  }

  get handlerHeaderLeft(): TemplateRef<unknown> {
    return this._handlerHeaderLeft.value;
  }

  set handlerHeaderLeft(value: TemplateRef<unknown>) {
    this._handlerHeaderLeft.next(value);
  }

  get handlerHeaderLeft$(): Observable<TemplateRef<unknown>> {
    return this._handlerHeaderLeft.asObservable();
  }

  get handlerHeaderCenter(): TemplateRef<unknown> {
    return this._handlerHeaderCenter.value;
  }

  set handlerHeaderCenter(value: TemplateRef<unknown>) {
    this._handlerHeaderCenter.next(value);
  }

  get handlerHeaderCenter$(): Observable<TemplateRef<unknown>> {
    return this._handlerHeaderCenter.asObservable();
  }

  setHandlerInfo(handler: CustomizeHandler, value: CustomizeHandlerInfo) {
    if (this.handler !== handler) {
      return;
    }

    this._handlerInfo.next(value);
  }

  unsetHandler(value: CustomizeHandler) {
    if (this.handler === value) {
      this._handler.next(undefined);
      this._handlerInfo.next(undefined);
      this.stopTrackChanges();
    }
  }

  startTrackChanges() {
    this.stopTrackChanges();

    if (!this.handler || !this.handler.getChangesState) {
      return;
    }

    this.changesStartTrackSubscription = forceObservable(this.handler.getChangesState())
      .pipe(untilDestroyed(this))
      .subscribe(initialState => {
        this.changesHistory$.next([initialState]);
        this.changesHistoryIndex$.next(0);

        this.changesAddHistorySubscription = this.changed$
          .pipe(
            debounce(immediate => timer(immediate ? 0 : 200)),
            switchMap(() => forceObservable(this.handler.getChangesState())),
            switchMap(newState => {
              const currentState = this.getCurrentChangesHistory();
              return forceObservable(this.handler.isChangesStateEqual(currentState, newState)).pipe(
                map(stateEquals => {
                  return {
                    state: newState,
                    stateUpdated: !stateEquals
                  };
                })
              );
            }),
            filter(value => value.stateUpdated),
            untilDestroyed(this)
          )
          .subscribe(value => this.addChangesHistory(value.state));

        this.changesSaveSubscription = this.getCurrentChangesHistory$()
          .pipe(
            skip(1),
            debounceTime(600),
            switchMap(() => this.saveCurrentChangesHistory().pipe(catchError(() => of(undefined)))),
            untilDestroyed(this)
          )
          .subscribe();
      });
  }

  unsubscribeTrackChanges() {
    if (this.changesStartTrackSubscription) {
      this.changesStartTrackSubscription.unsubscribe();
      this.changesStartTrackSubscription = undefined;
    }

    if (this.changesAddHistorySubscription) {
      this.changesAddHistorySubscription.unsubscribe();
      this.changesAddHistorySubscription = undefined;
    }

    if (this.changesSaveSubscription) {
      this.changesSaveSubscription.unsubscribe();
      this.changesSaveSubscription = undefined;
    }
  }

  stopTrackChanges() {
    this.unsubscribeTrackChanges();

    this.changesHistory$.next([]);
    this.changesHistoryIndex$.next(0);
    this._changesSaving$.next(false);
    this._changesSaveError$.next(undefined);
  }

  get changed$(): Observable<boolean> {
    return this._changed$.asObservable();
  }

  markChanged(immediate = false) {
    this._changed$.next(immediate);
  }

  addChangesHistory(state: any) {
    const history = [...this.changesHistory$.value.slice(0, this.changesHistoryIndex$.value + 1), state];
    this.changesHistory$.next(history);
    this.changesHistoryIndex$.next(history.length - 1);
  }

  applyChangesHistory(index: number) {
    const state = this.changesHistory$.value[index];

    if (!state) {
      return;
    }

    this.handler.setChangesState(state);
    this.changesHistoryIndex$.next(index);
  }

  hasChangesHistory$(delta: number): Observable<boolean> {
    return combineLatest(this.changesHistory$, this.changesHistoryIndex$).pipe(
      map(([history, historyIndex]) => {
        return history[historyIndex + delta] !== undefined;
      })
    );
  }

  undoChangesHistory() {
    this.applyChangesHistory(this.changesHistoryIndex$.value - 1);
  }

  redoChangesHistory() {
    this.applyChangesHistory(this.changesHistoryIndex$.value + 1);
  }

  getCurrentChangesHistory(): any {
    return this.changesHistory$.value[this.changesHistoryIndex$.value];
  }

  getCurrentChangesHistory$(): Observable<any> {
    return combineLatest(this.changesHistory$, this.changesHistoryIndex$).pipe(
      map(([history, historyIndex]) => {
        return history[historyIndex];
      })
    );
  }

  saveCurrentChangesHistory(): Observable<any> {
    const state = this.getCurrentChangesHistory();
    return this.saveChanges(state);
  }

  saveActualChanges(): Observable<any> {
    if (!this.handler) {
      return of(undefined);
    }

    return forceObservable(this.handler.getChangesState()).pipe(switchMap(state => this.saveChanges(state)));
  }

  saveChanges(state: any): Observable<any> {
    this._changesSaveError$.next(undefined);
    this._changesSaving$.next(true);

    return this.handler.saveChangesState(state).pipe(
      tap(() => {
        this._changesSaving$.next(false);
      }),
      catchError(error => {
        let errorMessage: string;

        if (error instanceof ServerRequestError && error.nonFieldErrors.length) {
          errorMessage = error.nonFieldErrors[0];
        } else {
          errorMessage = String(error);
        }

        this._changesSaving$.next(false);
        this._changesSaveError$.next(errorMessage);
        return throwError(error);
      })
    );
  }

  get changesSaving(): boolean {
    return this._changesSaving$.value;
  }

  get changesSaving$(): Observable<boolean> {
    return this._changesSaving$.asObservable();
  }

  get changesSaveError(): string {
    return this._changesSaveError$.value;
  }

  get changesSaveError$(): Observable<string> {
    return this._changesSaveError$.asObservable();
  }

  getHandlerName() {
    return this.handlerInfo && this.handlerInfo.breadcrumbs && this.handlerInfo.breadcrumbs.length
      ? last(this.handlerInfo.breadcrumbs)
      : 'Current Page';
  }

  renameHandler(title) {
    this.handler.renameHandler(title);
    this.markChanged();
  }

  openHandlerSettings() {
    this.handler.openHandlerSettings();
  }

  duplicateHandler(): Observable<boolean> {
    return this.handler.duplicate();
  }

  offMenu() {
    // if (this.menuEnabled) {
    //   this.requestExit().subscribe(allow => {
    //     if (allow) {
    //       this.toggleEnabled(CustomizeType.Layout);
    //       this.markChanged();
    //     }
    //   });
    // }
  }

  requestPublish() {
    return this._publishRequested.next();
  }

  publishRequested$(): Observable<void> {
    return this._publishRequested.asObservable();
  }

  registerCreatedElement(element: ElementItem, barItem?: CustomizeBarItem) {
    this.createdElements.push({
      element: element,
      barItem: barItem
    });
  }

  applyCreatedElementComponent(element: ElementItem) {
    const createdElement = this.createdElements.find(item => item.element === element);

    if (!createdElement) {
      return;
    }

    this.createdElements = this.createdElements.filter(item => item !== createdElement);
    return createdElement;
  }

  createElement(barItem: CustomizeBarItem) {
    this._createElement.next(barItem);
  }

  createElement$(): Observable<CustomizeBarItem> {
    return this._createElement.asObservable();
  }
}

import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
var Task = /** @class */ (function () {
    function Task() {
    }
    Object.defineProperty(Task.prototype, "scenario$", {
        get: function () {
            var _this = this;
            if (this._scenario) {
                return of(this._scenario);
            }
            var init = this.init();
            if (init instanceof Observable) {
                return init.pipe(tap(function (result) { return (_this._scenario = result); }));
            }
            else {
                this._scenario = init;
                return of(this._scenario);
            }
        },
        enumerable: true,
        configurable: true
    });
    return Task;
}());
export { Task };

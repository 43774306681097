import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, Observable, of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

import { OnBeforeDestroy } from '@common/lifecycle';

import { WithBuilderMode } from '@modules/customize';
import { Dashboard, DashboardService, DashboardStore } from '@modules/dashboard';
import { ModelDescription } from '@modules/models';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { RoutingService } from '@modules/routing';

import { DashboardComponent } from '../dashboard/dashboard.component';

@Component({
  templateUrl: 'index.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
@WithBuilderMode
export class IndexComponent implements OnInit, OnDestroy, OnBeforeDestroy {
  @ViewChild(DashboardComponent) dashboardComponent: DashboardComponent;

  items: ModelDescription[] = [];
  dashboard: Dashboard;
  loading = false;

  constructor(
    private dashboardStore: DashboardStore,
    private dashboardService: DashboardService,
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private routing: RoutingService,
    private cd: ChangeDetectorRef,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.loadDashboard();
  }

  ngOnDestroy(): void {}

  onBeforeDestroy(): void {
    if (this.dashboardComponent) {
      this.dashboardComponent.onBeforeDestroy();
    }
  }

  loadDashboard() {
    combineLatest(this.dashboardStore.get(), this.activatedRoute.params)
      .pipe(
        switchMap(() => {
          const id = this.activatedRoute.snapshot.params['id'];

          this.loading = true;
          this.cd.markForCheck();

          let obs: Observable<Dashboard>;

          if (id) {
            obs = this.dashboardStore.getDetailFirst(id);
          } else {
            obs = this.dashboardStore.getFirst().pipe(
              switchMap(result => {
                if (!result.length) {
                  return this.dashboardService
                    .create(
                      this.currentProjectStore.instance.uniqueName,
                      this.currentEnvironmentStore.instance.uniqueName,
                      new Dashboard().deserialize({
                        project: this.currentProjectStore.instance.uniqueName,
                        name: 'First',
                        ordering: 1
                      })
                    )
                    .pipe(
                      tap(dashboard => {
                        this.dashboardStore.getFirst(true);
                      })
                    );
                }
                return of(result[0]);
              })
            );
          }

          return obs;
        }),
        untilDestroyed(this)
      )
      .subscribe(
        dashboard => {
          const id = this.activatedRoute.snapshot.params['id'];

          if (!id) {
            this.routing.navigateApp(dashboard.link, { replaceUrl: true });
            return;
          }

          this.dashboard = dashboard;
          this.loading = false;
          this.cd.markForCheck();
        },
        () => {
          this.loading = false;
          this.cd.markForCheck();
        }
      );
  }
}

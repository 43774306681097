import { Injectable, Injector } from '@angular/core';
import { FormArray } from '@angular/forms';
import range from 'lodash/range';

import { ConditionWorkflowStepItem } from '@modules/workflow';

import { ConditionCustomizeWorkflowStepItemControl } from './condition-customize-workflow-step-item.control';

@Injectable()
export class ConditionCustomizeWorkflowStepItemArray extends FormArray {
  controls: ConditionCustomizeWorkflowStepItemControl[];

  constructor(private injector: Injector) {
    super([]);
  }

  init(items: ConditionWorkflowStepItem[], options: { firstInit?: boolean } = {}) {
    const controls = items.map(item => {
      const control = this.createControl();
      control.init(item, options);
      return control;
    });

    this.setControls(controls);
  }

  createControl(): ConditionCustomizeWorkflowStepItemControl {
    return Injector.create({
      providers: [{ provide: ConditionCustomizeWorkflowStepItemControl, deps: [] }],
      parent: this.injector
    }).get<ConditionCustomizeWorkflowStepItemControl>(ConditionCustomizeWorkflowStepItemControl);
  }

  removeControl(control: ConditionCustomizeWorkflowStepItemControl) {
    const index = this.controls.findIndex(item => item === control);
    this.removeAt(index);
  }

  setControls(controls: ConditionCustomizeWorkflowStepItemControl[]) {
    this.removeControls();
    controls.forEach(item => this.push(item));
  }

  removeControls() {
    range(this.controls.length).forEach(() => this.removeAt(0));
  }

  submit(): ConditionWorkflowStepItem[] {
    return this.controls.map(item => item.submit());
  }
}

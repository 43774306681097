/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../common/dynamic-component/components/dynamic/dynamic.component.ngfactory";
import * as i2 from "../../../../../common/dynamic-component/components/dynamic/dynamic.component";
import * as i3 from "../../../../../common/dynamic-component/services/dynamic-component/dynamic-component.service";
import * as i4 from "../../../../fields/components/auto-field/auto-field.component.ngfactory";
import * as i5 from "../../../../fields/components/auto-field/auto-field.component";
import * as i6 from "@angular/common";
import * as i7 from "./parameter-fields-edit-item.component";
import * as i8 from "../../../../customize/data/view-context";
import * as i9 from "../../../../customize/data/view-context-element";
import * as i10 from "../../../../../common/popover/components/popover/popover.component";
import * as i11 from "../../../../projects/stores/current-environment.store";
var styles_ParameterFieldsEditItemComponent = [];
var RenderType_ParameterFieldsEditItemComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ParameterFieldsEditItemComponent, data: {} });
export { RenderType_ParameterFieldsEditItemComponent as RenderType_ParameterFieldsEditItemComponent };
function View_ParameterFieldsEditItemComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 16777216, null, null, 1, "app-dynamic-component", [], null, null, null, i1.View_DynamicComponent_0, i1.RenderType_DynamicComponent)), i0.ɵdid(1, 442368, null, 0, i2.DynamicComponent, [i0.Injector, i3.DynamicComponentService, i0.ViewContainerRef], { componentData: [0, "componentData"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.dataParamsComponentData; _ck(_v, 1, 0, currVal_0); }, null); }
function View_ParameterFieldsEditItemComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 16777216, null, null, 1, "app-dynamic-component", [], null, null, null, i1.View_DynamicComponent_0, i1.RenderType_DynamicComponent)), i0.ɵdid(1, 442368, null, 0, i2.DynamicComponent, [i0.Injector, i3.DynamicComponentService, i0.ViewContainerRef], { componentData: [0, "componentData"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.viewParamsComponentData; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ParameterFieldsEditItemComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 11, "div", [["class", "card"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 10, "div", [["class", "card__inner"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 4, "app-auto-field", [], null, null, null, i4.View_AutoFieldComponent_0, i4.RenderType_AutoFieldComponent)), i0.ɵdid(3, 770048, null, 0, i5.AutoFieldComponent, [i0.ChangeDetectorRef], { form: [0, "form"], field: [1, "field"] }, null), i0.ɵpad(4, 1), i0.ɵpod(5, { classes: 0 }), i0.ɵpod(6, { name: 0, label: 1, field: 2, params: 3 }), (_l()(), i0.ɵeld(7, 0, null, null, 4, "app-auto-field", [], null, null, null, i4.View_AutoFieldComponent_0, i4.RenderType_AutoFieldComponent)), i0.ɵdid(8, 770048, null, 0, i5.AutoFieldComponent, [i0.ChangeDetectorRef], { form: [0, "form"], field: [1, "field"] }, null), i0.ɵpad(9, 1), i0.ɵpod(10, { classes: 0 }), i0.ɵpod(11, { name: 0, label: 1, field: 2, params: 3 }), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ParameterFieldsEditItemComponent_1)), i0.ɵdid(13, 16384, null, 0, i6.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ParameterFieldsEditItemComponent_2)), i0.ɵdid(15, 16384, null, 0, i6.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(16, 0, null, null, 6, "div", [["class", "edit-popup__submit"]], null, null, null, null, null)), (_l()(), i0.ɵeld(17, 0, null, null, 2, "button", [["class", "button button_primary button_fill"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.save() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(18, 0, null, null, 1, "span", [["class", "button__label"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Save"])), (_l()(), i0.ɵeld(20, 0, null, null, 2, "button", [["class", "button button_danger button_fill"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.requestDelete() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(21, 0, null, null, 1, "span", [["class", "button__label"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Delete"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.form; var currVal_1 = _co.createField(_ck(_v, 6, 0, "verbose_name", "display name", "CharField", _ck(_v, 5, 0, _ck(_v, 4, 0, "input_fill")))); _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_2 = _co.form; var currVal_3 = _co.createField(_ck(_v, 11, 0, "required", "required", "BooleanField", _ck(_v, 10, 0, _ck(_v, 9, 0, "input_fill")))); _ck(_v, 8, 0, currVal_2, currVal_3); var currVal_4 = _co.dataParamsComponentData; _ck(_v, 13, 0, currVal_4); var currVal_5 = _co.viewParamsComponentData; _ck(_v, 15, 0, currVal_5); }, null); }
export function View_ParameterFieldsEditItemComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-parameter-fields-edit-item", [], null, null, null, View_ParameterFieldsEditItemComponent_0, RenderType_ParameterFieldsEditItemComponent)), i0.ɵdid(1, 245760, null, 0, i7.ParameterFieldsEditItemComponent, [[2, i8.ViewContext], [2, i9.ViewContextElement], i10.PopoverComponent, i11.CurrentEnvironmentStore, i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ParameterFieldsEditItemComponentNgFactory = i0.ɵccf("app-parameter-fields-edit-item", i7.ParameterFieldsEditItemComponent, View_ParameterFieldsEditItemComponent_Host_0, { form: "form" }, { deleteRequested: "deleteRequested" }, []);
export { ParameterFieldsEditItemComponentNgFactory as ParameterFieldsEditItemComponentNgFactory };

import { ParameterField } from './parameter-field';

export class ActionOutput {
  public name: string;
  public verboseName: string;
  public icon: string;
  public parameters: ParameterField[] = [];

  deserialize(data: Object): ActionOutput {
    this.name = data['name'];
    this.verboseName = data['verbose_name'] || this.name;
    this.icon = data['icon'];

    if (data['parameters']) {
      this.parameters = data['parameters'].map(status => new ParameterField().deserialize(status));
    }

    return this;
  }

  serialize(): Object {
    return {
      name: this.name,
      verbose_name: this.verboseName,
      icon: this.icon,
      parameters: this.parameters.map(item => item.serialize())
    };
  }
}

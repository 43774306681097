var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { AfterViewInit, ChangeDetectorRef, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { MatMenuTrigger } from '@angular/material';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest, of } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import { TintStyle } from '@modules/actions';
import { ActionDropdownElementItem, AlignHorizontal, CustomizeService, ElementType, registerElementComponent, ViewContextElement } from '@modules/customize';
import { BaseElementComponent } from '@modules/customize-elements';
import { applyBooleanInput$, applyParamInput$ } from '@modules/fields';
import { CustomPagePopupComponent } from '../custom-page-popup/custom-page-popup.component';
var ActionDropdownElementComponent = /** @class */ (function (_super) {
    __extends(ActionDropdownElementComponent, _super);
    function ActionDropdownElementComponent(viewContextElement, cd, customizeService, popup) {
        var _this = _super.call(this) || this;
        _this.viewContextElement = viewContextElement;
        _this.cd = cd;
        _this.customizeService = customizeService;
        _this.popup = popup;
        _this.buttonHover = new BehaviorSubject(false);
        _this.menuHover = new BehaviorSubject(false);
        _this.style = TintStyle.Primary;
        _this.tintStyles = TintStyle;
        _this.alignHorizontals = AlignHorizontal;
        _this.disabled = false;
        return _this;
    }
    ActionDropdownElementComponent.prototype.ngOnInit = function () {
        this.customizeEnabled$ = this.customizeService.enabled$.pipe(map(function (item) { return !!item; }));
        this.viewContextElement.initElement({
            uniqueName: this.element.uid,
            name: this.element.name,
            element: this.element,
            popup: this.popup ? this.popup.popup : undefined
        });
        this.initVerboseName();
        this.initStyle();
        this.initDisabled();
    };
    ActionDropdownElementComponent.prototype.ngOnDestroy = function () { };
    ActionDropdownElementComponent.prototype.ngOnChanges = function (changes) {
        if (changes.element && !changes.element.firstChange) {
            this.viewContextElement.initInfo({ name: this.element.name, element: this.element }, true);
        }
        if (changes.element) {
            this.initVerboseName();
            this.initStyle();
            this.initDisabled();
        }
    };
    ActionDropdownElementComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        combineLatest(this.buttonHover, this.menuHover)
            .pipe(debounceTime(100), untilDestroyed(this))
            .subscribe(function (_a) {
            var buttonHover = _a[0], menuHover = _a[1];
            if (!_this.menuTrigger) {
                return;
            }
            if (buttonHover || menuHover) {
                _this.menuTrigger.openMenu();
            }
            else {
                _this.menuTrigger.closeMenu();
            }
        });
    };
    ActionDropdownElementComponent.prototype.initVerboseName = function () {
        var _this = this;
        if (this.verboseNameSubscription) {
            this.verboseNameSubscription.unsubscribe();
            this.verboseNameSubscription = undefined;
        }
        if (!this.element.verboseNameInput) {
            this.verboseName = undefined;
            this.cd.markForCheck();
            return;
        }
        this.verboseNameSubscription = applyParamInput$(this.element.verboseNameInput, {
            context: this.context,
            contextElement: this.viewContextElement,
            defaultValue: ''
        })
            .pipe(untilDestroyed(this))
            .subscribe(function (value) {
            _this.verboseName = value;
            _this.cd.markForCheck();
        });
    };
    ActionDropdownElementComponent.prototype.initStyle = function () {
        this.style = this.element.style ? this.element.style : TintStyle.Primary;
        this.cd.markForCheck();
    };
    ActionDropdownElementComponent.prototype.getElementDisabled$ = function () {
        return this.element.disabledInput && this.element.disabledInput.isSet()
            ? applyBooleanInput$(this.element.disabledInput, {
                context: this.context,
                contextElement: this.viewContextElement
            })
            : of(false);
    };
    ActionDropdownElementComponent.prototype.initDisabled = function () {
        var _this = this;
        if (this.disabledSubscription) {
            this.disabledSubscription.unsubscribe();
            this.disabledSubscription = undefined;
        }
        if (!this.element) {
            this.disabled = false;
            this.cd.markForCheck();
            return;
        }
        this.getElementDisabled$()
            .pipe(untilDestroyed(this))
            .subscribe(function (inputDisabled) {
            _this.disabled = inputDisabled;
            _this.cd.markForCheck();
        });
    };
    return ActionDropdownElementComponent;
}(BaseElementComponent));
export { ActionDropdownElementComponent };
registerElementComponent({
    type: ElementType.ActionDropdown,
    component: ActionDropdownElementComponent,
    label: 'Button dropdown',
    actions: []
});

<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayPanelClass]="['overlay']"
  [cdkConnectedOverlayPositions]="popoverPositions"
  [cdkConnectedOverlayOrigin]="origin"
  [cdkConnectedOverlayOpen]="tokenPopoverOpened"
  [cdkConnectedOverlayPush]="true"
  [cdkConnectedOverlayGrowAfterOpen]="true"
  #token_popover_overlay
>
  <div (cdkObserveContent)="onTokenPopoverContentChanged()" (mousedown)="markTokenPopoverMouseEvent($event)">
    <app-view-context-token-popover
      [context]="context"
      [contextElement]="contextElement"
      [contextElementPath]="contextElementPath"
      [contextElementPaths]="contextElementPaths"
      [id]="id"
      [maxHeight]="maxHeight"
      [extraSections]="extraSections"
      [hideSections]="hideSections"
      [selectFunction]="selectFunction"
      [search]="search"
      (selected)="selected.emit($event)"
      (close)="close()"
    ></app-view-context-token-popover>
  </div>
</ng-template>

<div *ngIf="loading" class="change__fill">
  <app-loader [title]="'Loading'" [fill]="true">
    We are fetching item data, please, wait...
  </app-loader>
</div>

<ng-container *ngIf="!loading">
  <app-page>
    <div class="section section_top">
      <div class="section__inner">
        <div class="change__header">
          <div class="change__actions">
            <ng-container *ngIf="customView">
              <a *ngIf="customView?.link" [appLink]="customView.link" class="button change__actions-item">
                <span class="button__label">Test FlexView</span>
              </a>
              <a
                *ngIf="!customView?.link"
                href="javascript:void(0)"
                class="button button_disabled change__actions-item"
                [appTip]="'To test Collection and Record FlexView go to their pages'"
              >
                <span class="button__label">Test FlexView</span>
              </a>
            </ng-container>
            <a
              *ngIf="customView"
              href="javascript:void(0)"
              class="button button_icon button_danger change__actions-item"
              (click)="requestDelete()"
              [appTip]="'Delete'"
            >
              <span class="icon icon-bin button__icon"></span>
            </a>
          </div>

          <h1 class="change__title">
            <a [appLink]="(currentProjectStore.instance$ | async)?.settingsCustomViewsLink" class="change__title-back">
              <span class="icon icon-arrow_backward_3"></span> FlexViews
            </a>
            –
            <ng-container *ngIf="!customView">Adding FlexView</ng-container>
            <ng-container *ngIf="customView">{{ customView.name }}</ng-container>
          </h1>
        </div>

        <div class="section__body">
          <form [formGroup]="form.form" (ngSubmit)="submit()">
            <div class="card">
              <div class="card__inner">
                <app-auto-field
                  *ngIf="!customView"
                  [form]="form.form"
                  [field]="createField({ name: 'unique_name', label: 'unique tag name', field: 'CharField' })"
                ></app-auto-field>
                <app-auto-field
                  [form]="form.form"
                  [field]="createField({ name: 'name', label: 'display name', field: 'CharField' })"
                ></app-auto-field>
                <!--                <app-auto-field [form]="form.form" [field]="createField({ name: 'view_type', label: 'view type', field: 'SelectField', params: { options: form.viewTypeOptions } })"></app-auto-field>-->
                <app-auto-field
                  *ngIf="
                    [customViewTypes.ModelDescription, customViewTypes.Model].includes(form.form.value['view_type'])
                  "
                  [form]="form.form"
                  [field]="
                    createField({
                      name: 'model_description',
                      label: 'for collection',
                      field: 'ModelDescriptionField',
                      params: { allow_empty: true, empty_name: 'any' }
                    })
                  "
                ></app-auto-field>

                <div class="field">
                  <label class="field__label" for="dist">Compiled Custom Element (.zip)</label>

                  <input
                    class="input"
                    [class.input_error]="(form.form | appFormFieldErrors: 'dist')?.length"
                    type="hidden"
                    [formControlName]="'dist'"
                    id="dist"
                  />

                  <div *ngIf="distFilename">
                    <strong>{{ distFilename }}</strong>
                    <br /><br />
                  </div>

                  <input class="input input_file" type="file" (change)="onFileChange($event.target)" id="dist_upload" />

                  <a
                    *ngIf="distPreview && distPreview != distFilename"
                    [href]="distPreview"
                    class="button button_small"
                    target="_blank"
                  >
                    <span class="icon icon-cloud_download button__icon"></span>&nbsp;
                    <span class="button__label">Download</span>
                  </a>

                  <label for="dist_upload" class="button button_small">
                    <span class="icon icon-cloud_upload button__icon"></span>&nbsp;
                    <span class="button__label">
                      <ng-container *ngIf="distPreview">Upload new file</ng-container>
                      <ng-container *ngIf="!distPreview">Upload file</ng-container>
                    </span>
                  </label>

                  <app-field-errors [form]="form.form" [fieldName]="'dist'"></app-field-errors>
                </div>

                <app-change-custom-view-files
                  [distControl]="form.form.controls['dist']"
                  [filesControl]="form.form.controls['params']"
                ></app-change-custom-view-files>
              </div>
            </div>

            <div class="change__submit">
              <button
                type="submit"
                class="button button_primary"
                [disabled]="form.form.pristine || form.form.invalid || form.form.disabled"
                style="margin-right: 10px;"
              >
                <span class="button__label">
                  <ng-container *ngIf="!customView">Create</ng-container>
                  <ng-container *ngIf="customView">Save</ng-container>
                </span>
              </button>
              <a [appLink]="(currentProjectStore.instance$ | async)?.settingsCustomViewsLink" class="button">
                <span class="button__label">Cancel</span>
              </a>
            </div>
          </form>
        </div>
      </div>
    </div>
  </app-page>
</ng-container>

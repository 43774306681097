var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { FormGroup } from '@angular/forms';
import { Input, Input as FieldInput, InputValueType } from '@modules/fields';
import { FieldInputControl } from '@modules/parameters';
import { isSet } from '@shared';
var defaultMinValueInput = {
    name: 'value',
    value_type: InputValueType.StaticValue,
    static_value: 1
};
var defaultMaxValueInput = {
    name: 'value',
    value_type: InputValueType.StaticValue,
    static_value: 10
};
var defaultStepSizeInput = {
    name: 'value',
    value_type: InputValueType.StaticValue,
    static_value: 1
};
var SliderFieldDataParamsForm = /** @class */ (function (_super) {
    __extends(SliderFieldDataParamsForm, _super);
    function SliderFieldDataParamsForm() {
        return _super.call(this, {
            min_value_input: new FieldInputControl(defaultMinValueInput),
            max_value_input: new FieldInputControl(defaultMaxValueInput),
            step_size_input: new FieldInputControl(defaultStepSizeInput)
        }) || this;
    }
    SliderFieldDataParamsForm.prototype.init = function (paramsControl) {
        var _this = this;
        this.paramsControl = paramsControl;
        if (paramsControl.value) {
            var params = this.getParams(paramsControl.value || {});
            var value = {
                min_value_input: params.minValueInput ? params.minValueInput.serialize() : defaultMinValueInput,
                max_value_input: params.maxValueInput ? params.maxValueInput.serialize() : defaultMaxValueInput,
                step_size_input: params.stepSizeInput ? params.stepSizeInput.serialize() : defaultStepSizeInput
            };
            this.patchValue(value, { emitEvent: false });
        }
        this.valueChanges.subscribe(function () { return _this.submit(); });
    };
    SliderFieldDataParamsForm.prototype.getParams = function (params) {
        var result = {};
        if (params['min_value_input']) {
            result.minValueInput = new Input().deserialize(params['min_value_input']);
        }
        else if (isSet(params['min_value'])) {
            // Backward compatibility
            result.minValueInput = new Input().deserializeFromStatic('value', params['min_value']);
        }
        if (params['max_value_input']) {
            result.maxValueInput = new Input().deserialize(params['max_value_input']);
        }
        else if (isSet(params['max_value'])) {
            // Backward compatibility
            result.maxValueInput = new Input().deserializeFromStatic('value', params['max_value']);
        }
        if (params['step_size_input']) {
            result.stepSizeInput = new Input().deserialize(params['step_size_input']);
        }
        else if (isSet(params['step_size'])) {
            // Backward compatibility
            result.stepSizeInput = new Input().deserializeFromStatic('value', params['step_size']);
        }
        return result;
    };
    SliderFieldDataParamsForm.prototype.submit = function () {
        var result = {
            min_value_input: this.controls.min_value_input.value
                ? new FieldInput().deserialize(this.controls.min_value_input.value).serialize()
                : undefined,
            max_value_input: this.controls.max_value_input.value
                ? new FieldInput().deserialize(this.controls.max_value_input.value).serialize()
                : undefined,
            step_size_input: this.controls.step_size_input.value
                ? new FieldInput().deserialize(this.controls.step_size_input.value).serialize()
                : undefined
        };
        this.paramsControl.patchValue(__assign({}, this.paramsControl.value, result));
    };
    return SliderFieldDataParamsForm;
}(FormGroup));
export { SliderFieldDataParamsForm };

import { Injectable } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import cloneDeep from 'lodash/cloneDeep';

import { StackLayoutElementItem } from '@modules/customize';
import { Input } from '@modules/fields';
import { FieldInputControl } from '@modules/parameters';

import { MarginControl } from '../margin-control/margin-control.component';

@Injectable()
export class CustomizeBarStackEditForm {
  element: StackLayoutElementItem;
  form = new FormGroup({
    title: new FormControl(''),
    visible_input: new FieldInputControl({ name: 'value' }),
    margin: new MarginControl()
  });

  constructor() {}

  init(element: StackLayoutElementItem) {
    this.element = element;

    const value = {
      title: element.name ? element.name : 'Stack',
      visible_input: element.visibleInput ? element.visibleInput.serialize() : {},
      margin: element.margin
    };

    this.form.patchValue(value, { emitEvent: false });
  }

  submit(): StackLayoutElementItem {
    const value = this.form.value;
    const instance = cloneDeep(this.element) as StackLayoutElementItem;

    instance.name = value['title'];

    if (value['visible_input']) {
      instance.visibleInput = new Input().deserialize(value['visible_input']);
    } else {
      instance.visibleInput = undefined;
    }

    instance.margin = value['margin'];

    return instance;
  }
}

import { Injectable } from '@angular/core';

import { Layer } from '@modules/views';

export interface ViewEditorNavigatorItemsState {
  container: Layer[];
  displayItems: Layer[];
  displayReverse: boolean;
}

export interface IViewEditorNavigatorItemsComponent {
  state: ViewEditorNavigatorItemsState;
}

@Injectable()
export class ViewEditorNavigatorService {
  components: IViewEditorNavigatorItemsComponent[] = [];

  constructor() {}

  registerComponent(component: IViewEditorNavigatorItemsComponent) {
    if (this.components.find(item => item === component)) {
      return;
    }

    this.components = [...this.components, component];
  }

  unregisterComponent(component: IViewEditorNavigatorItemsComponent) {
    this.components = this.components.filter(item => item !== component);
  }
}

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { AfterViewInit, ChangeDetectorRef, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { BasePopupComponent } from '@common/popups';
import { DataSourceType, ListModelDescriptionDataSource } from '@modules/data-sources';
import { createFormFieldFactory } from '@modules/fields';
import { ModelDescription, ORDER_BY_PARAM, PAGE_PARAM } from '@modules/models';
import { ModelListStore } from '@modules/models-list';
import { MoveForm } from './move.form';
var MoveComponent = /** @class */ (function () {
    function MoveComponent(form, modelListStore, popupComponent, cd) {
        this.form = form;
        this.modelListStore = modelListStore;
        this.popupComponent = popupComponent;
        this.cd = cd;
        this.inverseIds = false;
        this.reordered = new EventEmitter();
        this.createField = createFormFieldFactory();
        this.loading = true;
        this.executeActionStarted = false;
        this.executeActionProgress = 0;
        this.executeActionSucceeded = false;
    }
    MoveComponent.prototype.ngOnInit = function () {
        this.form.init(this.modelDescription);
    };
    MoveComponent.prototype.ngOnDestroy = function () { };
    MoveComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        this.modelListStore.dataSource = new ListModelDescriptionDataSource({
            type: DataSourceType.Query,
            queryResource: this.modelDescription.resource,
            query: this.modelDescription.getQuery
        });
        this.modelListStore.params = this.actionQueryParams();
        this.modelListStore
            .getNext()
            .pipe(untilDestroyed(this))
            .subscribe(function () {
            _this.loading = false;
            _this.firstItems = _this.modelListStore.items;
            _this.count = _this.modelListStore.count;
            _this.cd.markForCheck();
        }, function () {
            _this.loading = false;
            _this.cd.markForCheck();
        });
    };
    MoveComponent.prototype.actionQueryParams = function () {
        var params = __assign({}, this.queryParams);
        if (this.ids) {
            if (this.inverseIds) {
                params["exclude__" + this.modelDescription.primaryKeyField + "__in"] = this.ids.join(',');
            }
            else {
                params[this.modelDescription.primaryKeyField + "__in"] = this.ids.join(',');
            }
        }
        if (params[PAGE_PARAM] != undefined) {
            delete params[PAGE_PARAM];
        }
        if (this.form.isForwardProcessing()) {
            params[ORDER_BY_PARAM] = this.modelDescription.orderingField;
        }
        else {
            params[ORDER_BY_PARAM] = "-" + this.modelDescription.orderingField;
        }
        return params;
    };
    MoveComponent.prototype.processItems = function (items, fromIndex, processed) {
        var _this = this;
        if (processed === void 0) { processed = 0; }
        return this.form.submit(items[fromIndex].primaryKey).pipe(switchMap(function () {
            processed += 1;
            _this.executeActionProgress = processed / _this.modelListStore.count;
            _this.cd.markForCheck();
            if (fromIndex < items.length - 1) {
                return _this.processItems(items, fromIndex + 1, processed);
            }
            else {
                return of(processed);
            }
        }));
    };
    MoveComponent.prototype.processPage = function (processed) {
        var _this = this;
        if (processed === void 0) { processed = 0; }
        return this.modelListStore.getNext().pipe(switchMap(function (items) {
            return _this.processItems(items, 0, processed);
        }), switchMap(function (newProcessed) {
            _this.executeActionProgress = newProcessed / _this.modelListStore.count;
            _this.cd.markForCheck();
            if (_this.modelListStore.hasMore) {
                return _this.processPage(newProcessed);
            }
            else {
                return of(newProcessed);
            }
        }));
    };
    MoveComponent.prototype.submit = function () {
        var _this = this;
        if (this.form.form.invalid || this.executeActionStarted) {
            return;
        }
        this.executeActionStarted = true;
        this.executeActionProgress = 0;
        this.cd.markForCheck();
        this.modelListStore.params = this.actionQueryParams();
        this.modelListStore.reset();
        this.processPage()
            .pipe(untilDestroyed(this))
            .subscribe(function () {
            _this.executeActionSucceeded = true;
            _this.executeActionProgress = 1;
            _this.cd.markForCheck();
            _this.reordered.next();
        }, function () {
            _this.executeActionSucceeded = true;
            _this.executeActionProgress = 1;
            _this.cd.markForCheck();
        });
    };
    MoveComponent.prototype.close = function () {
        this.popupComponent.close();
    };
    return MoveComponent;
}());
export { MoveComponent };

import { ChangeDetectorRef, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import { AppConfigService } from '@core';
import { ProjectSettingsStore } from '@modules/all-project-settings';
import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { CustomizeService } from '@modules/customize';
import { CustomizeBarContext } from '@modules/customize-bar';
import { MenuSection, MenuService, MenuSettingsStore } from '@modules/menu';
import { MetaService } from '@modules/meta';
import { CurrentEnvironmentStore, CurrentProjectStore, hasEnvironmentPermission, ProjectPermissions } from '@modules/projects';
import { RoutingService } from '@modules/routing';
import { isSet, scrollTo } from '@shared';
import { CustomizeBarAppearanceEditComponent } from '../customize-bar-appearance-edit/customize-bar-appearance-edit.component';
import { CustomizeBarCustomCodeEditComponent } from '../customize-bar-custom-code-edit/customize-bar-custom-code-edit.component';
import { CustomizeBarDomainEditComponent } from '../customize-bar-domain-edit/customize-bar-domain-edit.component';
import { CustomizeBarEmailsEditComponent } from '../customize-bar-emails-edit/customize-bar-emails-edit.component';
import { CustomizeBarIntegrationsEditComponent } from '../customize-bar-integrations-edit/customize-bar-integrations-edit.component';
import { CustomizeBarLanguageRegionEditComponent } from '../customize-bar-language-region-edit/customize-bar-language-region-edit.component';
import { CustomizeBarSharingEditComponent } from '../customize-bar-sharing-edit/customize-bar-sharing-edit.component';
import { ProjectDomainUpdateForm } from '../sign-up-builder/project-domain-update.form';
import { ProjectSignUpForm } from '../sign-up-builder/project-sign-up.form';
import { MenuUpdateForm } from './menu-update.form';
import { ProjectSettingsUpdateForm } from './project-settings-update.form';
import { ProjectUpdateForm } from './project-update.form';
var MenuItemType;
(function (MenuItemType) {
    MenuItemType["Appearance"] = "appearance";
    MenuItemType["LanguageRegion"] = "language_region";
    MenuItemType["Menu"] = "menu";
    MenuItemType["SignUp"] = "sign_up";
    MenuItemType["Domain"] = "domain";
    MenuItemType["Emails"] = "emails";
    MenuItemType["Features"] = "features";
    MenuItemType["Sharing"] = "Sharing";
    MenuItemType["Integrations"] = "Integrations";
    MenuItemType["CustomCode"] = "custom_code";
})(MenuItemType || (MenuItemType = {}));
var DisplayComponent;
(function (DisplayComponent) {
})(DisplayComponent || (DisplayComponent = {}));
var ProjectSettingsComponent = /** @class */ (function () {
    function ProjectSettingsComponent(currentEnvironmentStore, activatedRoute, projectForm, menuForm, settingsForm, projectSignUpForm, projectDomainUpdateForm, menuService, customizeBarContext, currentProjectStore, projectSettingsStore, customizeService, menuSettingsStore, routing, appConfigService, metaService, analyticsService, cd) {
        this.currentEnvironmentStore = currentEnvironmentStore;
        this.activatedRoute = activatedRoute;
        this.projectForm = projectForm;
        this.menuForm = menuForm;
        this.settingsForm = settingsForm;
        this.projectSignUpForm = projectSignUpForm;
        this.projectDomainUpdateForm = projectDomainUpdateForm;
        this.menuService = menuService;
        this.customizeBarContext = customizeBarContext;
        this.currentProjectStore = currentProjectStore;
        this.projectSettingsStore = projectSettingsStore;
        this.customizeService = customizeService;
        this.menuSettingsStore = menuSettingsStore;
        this.routing = routing;
        this.appConfigService = appConfigService;
        this.metaService = metaService;
        this.analyticsService = analyticsService;
        this.cd = cd;
        this.menuItems = [
            {
                type: MenuItemType.Appearance,
                title: 'Appearance',
                subtitle: 'App name, layout and colors',
                icon: 'palette',
                component: CustomizeBarAppearanceEditComponent,
                inputs: {
                    projectForm: this.projectForm,
                    settingsForm: this.settingsForm,
                    menuForm: this.menuForm
                },
                displayComponent: undefined
            },
            // {
            //   type: MenuItemType.Menu,
            //   title: 'Menu',
            //   subtitle: 'Pages navigation',
            //   icon: 'fileds',
            //   component: CustomizeBarMenuEditComponent,
            //   inputs: {
            //     menuForm: this.menuForm,
            //     settingsForm: this.settingsForm
            //   },
            //   displayComponent: undefined
            // },
            {
                type: MenuItemType.Domain,
                title: 'Custom Domain',
                subtitle: 'Host app under your domain',
                icon: 'external_link',
                component: CustomizeBarDomainEditComponent,
                inputs: {
                    projectForm: this.projectForm
                },
                displayComponent: undefined
            },
            {
                type: MenuItemType.LanguageRegion,
                title: 'Language & Region',
                subtitle: 'Localization, timezone and formats',
                icon: 'earth_planet',
                component: CustomizeBarLanguageRegionEditComponent,
                inputs: {
                    projectDomainUpdateForm: this.projectDomainUpdateForm
                },
                displayComponent: undefined
            },
            {
                type: MenuItemType.Emails,
                title: 'Emails',
                subtitle: 'Settings, templates',
                icon: 'email',
                component: CustomizeBarEmailsEditComponent,
                inputs: {
                    projectSignUpForm: this.projectSignUpForm,
                    projectDomainUpdateForm: this.projectDomainUpdateForm
                },
                displayComponent: undefined
            },
            // {
            //   type: MenuItemType.Features,
            //   title: 'Features',
            //   subtitle: 'Advanced functionality',
            //   icon: 'switch',
            //   component: CustomizeBarFeaturesEditComponent,
            //   inputs: {
            //     settingsForm: this.settingsForm
            //   },
            //   displayComponent: undefined
            // },
            {
                type: MenuItemType.Sharing,
                title: 'Sharing',
                subtitle: 'Meta info for URL sharing',
                icon: 'redo',
                component: CustomizeBarSharingEditComponent,
                inputs: {
                    projectForm: this.projectForm,
                    projectDomainUpdateForm: this.projectDomainUpdateForm
                },
                displayComponent: undefined
            },
            {
                type: MenuItemType.Integrations,
                title: 'Integrations',
                subtitle: '3rd party services',
                icon: 'chip',
                component: CustomizeBarIntegrationsEditComponent,
                inputs: {
                    settingsForm: this.settingsForm
                },
                displayComponent: undefined
            },
            {
                type: MenuItemType.CustomCode,
                title: 'Scripts & Styles',
                subtitle: 'Set global JS and CSS',
                icon: 'console',
                component: CustomizeBarCustomCodeEditComponent,
                inputs: {
                    settingsForm: this.settingsForm
                },
                displayComponent: undefined
            }
        ];
        this.displayComponents = DisplayComponent;
        this.settingsComponents = [];
        this.loading = false;
    }
    ProjectSettingsComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (!hasEnvironmentPermission(this.currentEnvironmentStore.instance, ProjectPermissions.ProjectCustomization)) {
            this.routing.navigateApp(['not-allowed'], { skipLocationChange: true });
            return;
        }
        this.menuService.section = MenuSection.None;
        this.loading = true;
        this.cd.markForCheck();
        this.activatedRoute.params
            .pipe(map(function (params) { return params['tab']; }), untilDestroyed(this))
            .subscribe(function (tab) {
            if (isSet(tab)) {
                _this.setSelectedMenuItemType(tab);
            }
            else {
                var link = _this.currentProjectStore.instance.settingsLayoutLink(_this.menuItems[0].type);
                _this.routing.navigateApp(link, { replaceUrl: true });
            }
        });
        this.customizeService.setHandler(this);
        this.customizeService.setHandlerInfo(this, {
            breadcrumbs: [],
            inAppDisabled: true
        });
        this.customizeBarContext.settingsComponents$.pipe(untilDestroyed(this)).subscribe(function (item) {
            _this.settingsComponents = item;
            _this.cd.detectChanges();
            _this.scrollToTop();
        });
        this.projectDomainUpdateForm
            .getInstance$()
            .pipe(untilDestroyed(this))
            .subscribe(function (domain) {
            _this.domain = domain;
            _this.cd.markForCheck();
        });
        combineLatest(this.currentProjectStore.getFirst(), this.projectSettingsStore.getAllSettingsFirst$(), this.menuSettingsStore.getFirst())
            .pipe(untilDestroyed(this))
            .subscribe(function (_a) {
            var project = _a[0], projectSettings = _a[1], menuSettings = _a[2];
            var webBaseUrl = project && project.domain ? "https://" + project.domain.actualDomain : _this.appConfigService.webBaseUrl;
            _this.project = project;
            _this.domain = project ? project.domain : undefined;
            _this.projectHome = [webBaseUrl, 'app', project.uniqueName].join('/');
            _this.loading = false;
            _this.cd.markForCheck();
            _this.projectForm.init(project);
            _this.menuForm.init(menuSettings);
            _this.settingsForm.init(projectSettings);
            _this.projectSignUpForm.init(project);
            _this.projectForm.valueChanges.pipe(debounceTime(200), untilDestroyed(_this)).subscribe(function () {
                _this.projectForm.submit().subscribe(function () { return _this.onSave(); });
            });
            _this.menuForm.valueChanges.pipe(debounceTime(200), untilDestroyed(_this)).subscribe(function () {
                _this.menuForm.submit().subscribe(function () { return _this.onSave(); });
            });
            _this.settingsForm.valueChanges.pipe(debounceTime(200), untilDestroyed(_this)).subscribe(function () {
                _this.settingsForm.submit().subscribe(function () { return _this.onSave(); });
            });
            _this.projectSignUpForm.valueChanges.pipe(debounceTime(200), untilDestroyed(_this)).subscribe(function () {
                _this.projectSignUpForm.submit().subscribe(function () { return _this.onSave(); });
            });
        }, function () {
            _this.loading = false;
            _this.cd.markForCheck();
        });
        this.currentProjectStore
            .get()
            .pipe(untilDestroyed(this))
            .subscribe(function (result) {
            var webBaseUrl = result && result.domain ? "https://" + result.domain.actualDomain : _this.appConfigService.webBaseUrl;
            _this.project = result;
            _this.domain = result ? result.domain : undefined;
            _this.projectHome = [webBaseUrl, 'app', result.uniqueName].join('/');
            _this.cd.markForCheck();
            _this.projectForm.project = _this.project;
            _this.projectSignUpForm.project = _this.project;
            _this.initDomainForm();
        });
    };
    ProjectSettingsComponent.prototype.ngOnDestroy = function () {
        this.menuService.section = MenuSection.Default;
        this.customizeService.unsetHandler(this);
    };
    ProjectSettingsComponent.prototype.initDomainForm = function () {
        var _this = this;
        if (this.domainFormSubscription) {
            this.domainFormSubscription.unsubscribe();
            this.domainFormSubscription = undefined;
        }
        this.projectDomainUpdateForm.init(this.domain);
        this.domainFormSubscription = this.projectDomainUpdateForm.valueChanges
            .pipe(debounceTime(200), untilDestroyed(this))
            .subscribe(function () {
            _this.projectDomainUpdateForm.submit().subscribe(function () { return _this.onSave(); });
        });
    };
    Object.defineProperty(ProjectSettingsComponent.prototype, "selectedMenuItem", {
        get: function () {
            var _this = this;
            return this.menuItems.find(function (item) { return item.type == _this.selectedMenuItemType; });
        },
        enumerable: true,
        configurable: true
    });
    ProjectSettingsComponent.prototype.setSelectedMenuItemType = function (type) {
        if (this.selectedMenuItemType === type) {
            return;
        }
        this.selectedMenuItemType = type;
        this.cd.markForCheck();
        var selectedMenuItem = this.selectedMenuItem;
        if (!selectedMenuItem) {
            return;
        }
        this.displayComponent = selectedMenuItem.displayComponent;
        this.cd.markForCheck();
        if (selectedMenuItem.component) {
            this.customizeBarContext.setSettingsComponent({
                component: selectedMenuItem.component,
                inputs: selectedMenuItem.inputs || {}
            });
        }
    };
    ProjectSettingsComponent.prototype.scrollToTop = function (animated) {
        if (animated === void 0) { animated = true; }
        if (!this.root) {
            return;
        }
        var duration = animated && this.root.nativeElement.scrollTop > 0 ? 0.4 : 0;
        scrollTo(this.root.nativeElement, 0, duration);
    };
    ProjectSettingsComponent.prototype.onMenuItemClicked = function (name) {
        this.analyticsService.sendSimpleEvent(AnalyticsEvent.AppSettings.MenuItemClicked, {
            Name: name
        });
    };
    ProjectSettingsComponent.prototype.onSave = function () {
        this.analyticsService.sendSimpleEvent(AnalyticsEvent.Project.BuilderChange, {
            Type: 'app_settings'
        });
    };
    // TODO: Fix empty handler
    ProjectSettingsComponent.prototype.closePopup = function (uid) { };
    return ProjectSettingsComponent;
}());
export { ProjectSettingsComponent };

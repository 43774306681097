import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';

import { BasePopupComponent } from '@common/popups';
import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { CurrentProjectStore, Resource, ResourceTypeItem } from '@modules/projects';
import { ResourceEditController } from '@modules/projects-components';

@Component({
  selector: 'app-create-resource-popup',
  templateUrl: './create-resource-popup.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreateResourcePopupComponent implements OnInit, OnDestroy {
  @Input() analyticsSource: string;
  @Output() created = new EventEmitter<Resource>();
  @Output() cancelled = new EventEmitter<void>();

  constructor(
    public currentProjectStore: CurrentProjectStore,
    private popupComponent: BasePopupComponent,
    private cd: ChangeDetectorRef,
    private analyticsService: UniversalAnalyticsService,
    private resourceEditController: ResourceEditController
  ) {}

  ngOnInit() {}

  ngOnDestroy(): void {}

  createResource(item: ResourceTypeItem) {
    this.analyticsService.sendSimpleEvent(AnalyticsEvent.Resource.ClickedCreateResource, {
      Source: this.analyticsSource,
      ResourceID: item.name
    });

    this.resourceEditController
      .createResource(item, {
        resourceNameEditing: true,
        analyticsSource: this.analyticsSource
      })
      .pipe(untilDestroyed(this))
      .subscribe(result => {
        if (result.resource) {
          this.close();
          this.created.next(result.resource);
        }
      });
  }

  cancel() {
    this.close();
    this.cancelled.emit();
  }

  close(data?: any) {
    if (this.popupComponent) {
      this.popupComponent.close(data);
    }
  }
}

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { environment } from '@env/environment';
import * as i0 from "@angular/core";
var TagManagerService = /** @class */ (function () {
    function TagManagerService() {
    }
    TagManagerService.prototype.sendEvent = function (event, category, action, label, params) {
        if (params === void 0) { params = {}; }
        var data = __assign({ event: event, eventCategory: category, eventAction: action, eventLabel: label }, params);
        if (environment.production) {
            window['dataLayer'] = window['dataLayer'] || [];
            window['dataLayer'].push(data);
        }
        else {
            console.debug('sendEvent', data);
        }
    };
    TagManagerService.ngInjectableDef = i0.defineInjectable({ factory: function TagManagerService_Factory() { return new TagManagerService(); }, token: TagManagerService, providedIn: "root" });
    return TagManagerService;
}());
export { TagManagerService };

import isEqual from 'lodash/isEqual';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { ListLayoutType } from '../../data/list-layout-type';
import { listLayouts } from '../../data/list-layouts';
import { LayoutStore } from '../../stores/store/layout.store';
var LayoutService = /** @class */ (function () {
    function LayoutService(layoutStore) {
        this.layoutStore = layoutStore;
        this._layoutTypes = new BehaviorSubject([ListLayoutType.Table]);
        this._currentLayoutIndex = new BehaviorSubject(undefined);
        this.defaultLayout = 0;
        this.layouts$ = this._layoutTypes.pipe(map(function (value) { return value.map(function (item) { return listLayouts.find(function (i) { return i.type == item; }); }); }));
        this.currentLayout$ = combineLatest(this.layouts$, this._currentLayoutIndex).pipe(map(function (_a) {
            var layouts = _a[0], index = _a[1];
            if (index == undefined) {
                return;
            }
            return layouts[index];
        }));
    }
    LayoutService.prototype.init = function (layoutTypes, viewId, defaultLayout) {
        if (defaultLayout === void 0) { defaultLayout = 0; }
        this.defaultLayout = defaultLayout;
        this.viewId = viewId;
        layoutTypes = layoutTypes.filter(function (item) { return listLayouts.find(function (i) { return i.type == item; }); });
        if (!isEqual(this._layoutTypes.value, layoutTypes)) {
            this._layoutTypes.next(layoutTypes);
        }
        var index = this.cleanLayoutIndex(this.layoutStore.getCurrentLayout(this.viewId));
        if (this._currentLayoutIndex.value !== index) {
            this._currentLayoutIndex.next(index);
        }
    };
    LayoutService.prototype.cleanLayoutIndex = function (index) {
        if (!this.layouts) {
            return;
        }
        index = parseInt(index, 10) || this.defaultLayout;
        if (index >= this.layouts.length) {
            return this.defaultLayout;
        }
        return index;
    };
    Object.defineProperty(LayoutService.prototype, "layouts", {
        get: function () {
            return this._layoutTypes.value.map(function (item) { return listLayouts.find(function (i) { return i.type == item; }); });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LayoutService.prototype, "currentLayoutIndex", {
        get: function () {
            return this._currentLayoutIndex.value;
        },
        set: function (index) {
            index = this.cleanLayoutIndex(index);
            this.layoutStore.setCurrentLayout(this.viewId, index);
            this._currentLayoutIndex.next(index);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LayoutService.prototype, "currentLayoutIndex$", {
        get: function () {
            return this._currentLayoutIndex.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    return LayoutService;
}());
export { LayoutService };

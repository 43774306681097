import { OnDestroy } from '@angular/core';
import clamp from 'lodash/clamp';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { interval } from 'rxjs';
import { startWith } from 'rxjs/operators';
import * as i0 from "@angular/core";
var ScrollService = /** @class */ (function () {
    function ScrollService() {
        this.maxSpeed = 20;
        this.interval = 1000 / 60;
    }
    ScrollService.prototype.ngOnDestroy = function () { };
    ScrollService.prototype.scrollNearBounds = function (viewport, e) {
        var bounds = viewport.getBoundingClientRect();
        var scrollDistance = 80;
        var scrollMultiplierUp = viewport == document.body
            ? clamp((scrollDistance - e.clientY) / scrollDistance, 0, 1)
            : clamp((bounds.top + scrollDistance - e.clientY) / scrollDistance, 0, 1);
        var scrollMultiplierDown = viewport == document.body
            ? clamp(((window.innerHeight - scrollDistance - e.clientY) / scrollDistance) * -1, 0, 1)
            : clamp(((bounds.bottom - scrollDistance - e.clientY) / scrollDistance) * -1, 0, 1);
        if (scrollMultiplierUp > 0) {
            this.startScroll(viewport, false, scrollMultiplierUp);
        }
        else if (scrollMultiplierDown > 0) {
            this.startScroll(viewport, true, scrollMultiplierDown);
        }
        else {
            this.stopScroll();
        }
    };
    ScrollService.prototype.startScroll = function (viewport, down, power) {
        this.stopScroll();
        if (!viewport) {
            return;
        }
        var speed = down ? power * this.maxSpeed : power * this.maxSpeed * -1;
        this.scrollInterval = interval(this.interval)
            .pipe(startWith(0), untilDestroyed(this))
            .subscribe(function () {
            if (viewport == document.body) {
                var scrollTop = window.pageYOffset || document.documentElement.scrollTop;
                document.documentElement.scrollTop = document.body.scrollTop = scrollTop + speed;
            }
            else {
                viewport.scrollTop = viewport.scrollTop + speed;
            }
        });
    };
    ScrollService.prototype.stopScroll = function () {
        if (this.scrollInterval) {
            this.scrollInterval.unsubscribe();
            this.scrollInterval = undefined;
        }
    };
    ScrollService.ngInjectableDef = i0.defineInjectable({ factory: function ScrollService_Factory() { return new ScrollService(); }, token: ScrollService, providedIn: "root" });
    return ScrollService;
}());
export { ScrollService };

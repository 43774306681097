/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../shared/components/toast/toast.component.ngfactory";
import * as i2 from "../../../../shared/components/toast/toast.component";
import * as i3 from "../../../../core/services/local-storage/local.storage";
import * as i4 from "@angular/common";
import * as i5 from "./email-verification-toast.component";
import * as i6 from "../../../users/stores/current-user.store";
import * as i7 from "../../../../common/notifications/services/notification/notification.service";
import * as i8 from "../../../users/services/user/user.service";
var styles_EmailVerificationToastComponent = [];
var RenderType_EmailVerificationToastComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_EmailVerificationToastComponent, data: {} });
export { RenderType_EmailVerificationToastComponent as RenderType_EmailVerificationToastComponent };
function View_EmailVerificationToastComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["class", "toast2__button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.resend() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "div", [["class", "toast2__button-icon icon-repeat"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "div", [["class", "toast2__button-label"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Resend verification email"]))], null, null); }
export function View_EmailVerificationToastComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 12, "app-toast", [], null, null, null, i1.View_ToastComponent_0, i1.RenderType_ToastComponent)), i0.ɵdid(1, 638976, null, 0, i2.ToastComponent, [i3.LocalStorage, i0.ChangeDetectorRef], { icon: [0, "icon"], uniqueName: [1, "uniqueName"], expands: [2, "expands"], buttonsCenter: [3, "buttonsCenter"], shift: [4, "shift"] }, null), (_l()(), i0.ɵeld(2, 0, null, 2, 1, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Please verify your email address "])), (_l()(), i0.ɵeld(4, 0, null, 0, 5, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 4, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" We have sent the validation link to "])), (_l()(), i0.ɵeld(7, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i0.ɵted(8, null, ["", ""])), (_l()(), i0.ɵted(-1, null, [" to verify your email address. If you haven't received email from use you can resend it using the button below. "])), (_l()(), i0.ɵeld(10, 0, null, 1, 2, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_EmailVerificationToastComponent_1)), i0.ɵdid(12, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "email"; var currVal_1 = "email_verification"; var currVal_2 = !_co.sent; var currVal_3 = true; var currVal_4 = _co.shift; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); var currVal_6 = !_co.sent; _ck(_v, 12, 0, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_5 = ((_co.currentUserStore.instance == null) ? null : _co.currentUserStore.instance.email); _ck(_v, 8, 0, currVal_5); }); }
export function View_EmailVerificationToastComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-email-verification-toast", [], null, null, null, View_EmailVerificationToastComponent_0, RenderType_EmailVerificationToastComponent)), i0.ɵdid(1, 245760, null, 0, i5.EmailVerificationToastComponent, [i6.CurrentUserStore, i7.NotificationService, i8.UserService, i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EmailVerificationToastComponentNgFactory = i0.ɵccf("app-email-verification-toast", i5.EmailVerificationToastComponent, View_EmailVerificationToastComponent_Host_0, { shift: "shift" }, {}, []);
export { EmailVerificationToastComponentNgFactory as EmailVerificationToastComponentNgFactory };

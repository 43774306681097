import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouteReuseStrategy, RouterModule } from '@angular/router';
import { NgGxSelectModule } from 'ng-gxselect';

import { AdminModule } from '@modules/admin';
import { AnalyticsModule } from '@modules/analytics';
import { ApiModule } from '@modules/api';
import { ContactModule } from '@modules/contact';
import { DemoWidgetModule } from '@modules/demo-widget';
import { FieldsModule } from '@modules/fields';
import { MenuComponentsModule } from '@modules/menu-components';
import { ProjectsComponentsModule } from '@modules/projects-components';
import { RoutingModule } from '@modules/routing';
import { TableModule } from '@modules/table';
import { ComponentRouteReuseStrategy, SharedModule } from '@shared';
import { UiModule } from '@ui';

import { DemoProjectComponent } from './components/demo-project/demo-project.component';
import { routes } from './demo-create.routes';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgGxSelectModule,
    ApiModule,
    FieldsModule,
    SharedModule,
    MenuComponentsModule,
    ProjectsComponentsModule,
    AdminModule,
    DemoWidgetModule,
    TableModule,
    ContactModule,
    AnalyticsModule,
    RouterModule.forChild(routes),
    RoutingModule,
    UiModule
  ],
  declarations: [DemoProjectComponent],
  providers: [
    {
      provide: RouteReuseStrategy,
      useClass: ComponentRouteReuseStrategy
    }
  ]
})
export class DemoCreateModule {}

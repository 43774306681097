import pickBy from 'lodash/pickBy';

import { HttpQuery, RateLimit } from '@modules/queries';
import { Mappable } from '@shared';

export class RestAPIResourceParams implements Mappable<RestAPIResourceParams> {
  baseHttpQuery: HttpQuery;
  rateLimit: RateLimit;
  customProxy: string;
  params = {};

  deserialize(data: Object): RestAPIResourceParams {
    this.params = data;

    if (data['base_http_query']) {
      this.baseHttpQuery = new HttpQuery().deserialize(data['base_http_query']);
    } else if (data['base_query']) {
      this.baseHttpQuery = new HttpQuery().deserialize(data['base_query']);
    } else {
      this.baseHttpQuery = new HttpQuery();
    }

    if (data['rate_limit']) {
      this.rateLimit = new RateLimit().deserialize(data['rate_limit']);
    }

    this.customProxy = data['custom_proxy'];

    return this;
  }

  serialize(fields?: string[]): Object {
    let data: Object = {
      ...this.params,
      base_http_query: this.baseHttpQuery ? this.baseHttpQuery.serialize() : undefined,
      rate_limit: this.rateLimit ? this.rateLimit.serialize() : undefined,
      custom_proxy: this.customProxy
    };
    if (fields) {
      data = <Object>pickBy(data, (v, k) => fields.includes(k));
    }
    return data;
  }
}

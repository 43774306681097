import cloneDeep from 'lodash/cloneDeep';
import isArray from 'lodash/isArray';
import isEqual from 'lodash/isEqual';
import isFunction from 'lodash/isFunction';
import isObject from 'lodash/isObject';
import isPlainObject from 'lodash/isPlainObject';
import keys from 'lodash/keys';
import mapValues from 'lodash/mapValues';
import toPairs from 'lodash/toPairs';
export var EMPTY_CLS = function () { };
EMPTY_CLS.prototype.toString = function () { return 'EMPTY'; };
export var EMPTY = new EMPTY_CLS();
function objectPathNormalize(path) {
    if (typeof path == 'string') {
        path = path
            .replace(/\[["']?/g, '.')
            .replace(/["']?]/g, '.')
            .split('.');
    }
    return path.filter(function (item) { return item !== ''; });
}
export function objectGet(obj, path, defaultValue) {
    if (path === undefined || path === null) {
        return;
    }
    path = objectPathNormalize(path);
    return path.reduce(function (prev, item) {
        if (!prev || !prev.hasOwnProperty(item)) {
            return defaultValue !== undefined ? defaultValue : EMPTY;
        }
        return prev[item];
    }, obj);
}
export function objectSet(obj, path, value) {
    var instance = obj;
    path = objectPathNormalize(path);
    if (!path.length) {
        return (obj = value);
    }
    instance = path.slice(0, -1).reduce(function (prev, item) {
        if (!prev) {
            return;
        }
        if (prev[item] == undefined) {
            prev[item] = {};
        }
        return prev[item];
    }, obj);
    return (instance[path[path.length - 1]] = value);
}
export function mapValuesDeep(obj, iteree) {
    if (isArray(obj)) {
        return obj.map(function (item) { return mapValuesDeep(item, iteree); });
    }
    else if (isObject(obj)) {
        return mapValues(obj, function (v) { return mapValuesDeep(v, iteree); });
    }
    else {
        return iteree(obj);
    }
}
export function limitObjectLength(obj, length, addTrimmedObject) {
    if (addTrimmedObject === void 0) { addTrimmedObject = false; }
    if (obj instanceof Blob) {
        return {
            type: obj.type,
            size: obj.size
        };
    }
    var iter = function (item) {
        if (isPlainObject(item)) {
            keys(item).forEach(function (key) {
                item[key] = iter(item[key]);
            });
            return item;
        }
        else if (isArray(item)) {
            var array = item;
            var arraySize = array.length;
            var trim = arraySize > length;
            if (trim) {
                array = array.slice(0, length);
            }
            array = array.map(function (subItem) { return iter(subItem); });
            if (trim && addTrimmedObject) {
                array.push("{ ... Trimmed elements: " + (arraySize - length) + " }");
            }
            return array;
        }
        else {
            return item;
        }
    };
    return iter(cloneDeep(obj));
}
export function filterObject(obj, callbackfn) {
    var skip = {};
    var iter = function (item) {
        if (!callbackfn(item)) {
            return skip;
        }
        if (isPlainObject(item)) {
            var acc_1 = {};
            keys(item).forEach(function (key) {
                var result = iter(item[key]);
                if (result !== skip) {
                    acc_1[key] = result;
                }
            });
            return acc_1;
        }
        else if (isArray(item)) {
            return item.reduce(function (acc, subItem) {
                var result = iter(subItem);
                if (result !== skip) {
                    acc.push(subItem);
                }
                return acc;
            }, []);
        }
        else {
            return item;
        }
    };
    var final = iter(cloneDeep(obj));
    if (final === skip) {
        return;
    }
    return final;
}
export function areObjectsEqual(lhs, rhs, compare) {
    var serialize = function (obj) {
        return compare.map(function (item) {
            if (isFunction(item)) {
                var func = item;
                return func(obj);
            }
            else {
                var key = item;
                return obj[key];
            }
        });
    };
    return isEqual(serialize(lhs), serialize(rhs));
}
export function strictObject(obj, exc) {
    if (typeof Proxy === 'undefined') {
        return obj;
    }
    return new Proxy(obj, {
        get: function (target, property) {
            if (property == 'length' ||
                property == '_isBuffer' ||
                property == 'constructor' ||
                property == Symbol.toStringTag) {
                return;
            }
            else if (property == 'hasOwnProperty') {
                return function (v) { return objectGet(target, [v]) !== EMPTY; };
            }
            var result = objectGet(target, [property]);
            if (result === EMPTY) {
                throw exc(property);
            }
            return result;
        }
    });
}
export function aggregateObjects(data) {
    return data.reduce(function (acc, item) {
        toPairs(item).forEach(function (_a) {
            var key = _a[0], value = _a[1];
            if (!acc.hasOwnProperty(key)) {
                acc[key] = value;
            }
            else if (acc.hasOwnProperty(key) &&
                (acc[key] == null || acc[key] === undefined) &&
                !(value === null || value === undefined)) {
                acc[key] = item[key];
            }
        });
        return acc;
    }, {});
}

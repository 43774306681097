var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import uniq from 'lodash/uniq';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import { AUTO_OPTION_COLORS } from '@modules/colors';
import { FieldType, MultipleSelectOutputFormat, NumberValueFormat, OptionsType, OutputFormat } from '@modules/fields';
import { AIRTABLE_CREATED_TIME, AIRTABLE_FIELD_ID_PARAM, AIRTABLE_PRIMARY_KEY, AirtableFieldType } from '@modules/resources';
import { forceObservable, getCircleIndex, isSet } from '@shared';
export function parseAirtableColor(color) {
    if (!isSet(color)) {
        return;
    }
    color = color.replace(/(Bright|Light2)$/, '').toLowerCase();
    return AUTO_OPTION_COLORS.find(function (item) { return item == color; });
}
var airtableCollaboratorsStructure = function (max_items) {
    return {
        type: 'array',
        name: null,
        label: null,
        params: __assign({}, (max_items !== undefined && { max_items: max_items }), { item: {
                type: 'object',
                name: null,
                label: null,
                params: {
                    items: [
                        {
                            type: 'field',
                            name: 'id',
                            label: 'id',
                            params: {
                                field: 'text',
                                params: {},
                                required: false,
                                name: 'id',
                                label: 'id'
                            }
                        },
                        {
                            type: 'field',
                            name: 'email',
                            label: 'email',
                            params: {
                                field: 'text',
                                params: {},
                                required: false,
                                name: 'email',
                                label: 'email'
                            }
                        },
                        {
                            type: 'field',
                            name: 'name',
                            label: 'name',
                            params: {
                                field: 'text',
                                params: {},
                                required: false,
                                name: 'name',
                                label: 'name'
                            }
                        }
                    ]
                }
            } })
    };
};
var ɵ0 = airtableCollaboratorsStructure;
export var airtableFieldMapping = [
    {
        type: AirtableFieldType.AutoNumber,
        mapping: { field: FieldType.Number }
    },
    {
        type: AirtableFieldType.Barcode,
        mapping: { field: FieldType.JSON }
    },
    {
        type: AirtableFieldType.Checkbox,
        mapping: { field: FieldType.Boolean }
    },
    {
        type: AirtableFieldType.Count,
        mapping: { field: FieldType.Number }
    },
    {
        type: AirtableFieldType.Currency,
        mapping: {
            field: FieldType.Number,
            getParams: function (options) {
                return {
                    value_format: {
                        number_format: NumberValueFormat.Currency,
                        number_fraction: options.field.options.precision > 0 ? options.field.options.precision : 2
                    }
                };
            }
        }
    },
    {
        type: AirtableFieldType.Date,
        mapping: { field: FieldType.DateTime, params: { date: true, time: false, output_format: OutputFormat.ISODate } }
    },
    {
        type: AirtableFieldType.DateTime,
        mapping: { field: FieldType.DateTime }
    },
    {
        type: AirtableFieldType.Formula,
        mapping: {
            getField: function (options) {
                var _a;
                var mapping = options.field.options.result
                    ? airtableFieldMapping.find(function (i) { return i.type == options.field.options.result.type; })
                    : undefined;
                var resultMapping = mapping ? mapping.mapping : defaultAirtableFieldMapping;
                var extraParams = (_a = {}, _a[AIRTABLE_FIELD_ID_PARAM] = options.field.id, _a);
                var fieldWithParams;
                if (resultMapping.getField) {
                    fieldWithParams = forceObservable(resultMapping.getField({
                        name: options.name,
                        records: options.records,
                        options: options.options,
                        table: options.table,
                        field: options.field.options.result,
                        tables: options.tables,
                        controller: options.controller
                    })).pipe(map(function (field) {
                        return __assign({}, field, { params: __assign({}, field.params, extraParams) });
                    }));
                }
                else if (resultMapping.getParams) {
                    fieldWithParams = forceObservable(resultMapping.getParams({
                        name: options.name,
                        records: options.records,
                        options: options.options,
                        table: options.table,
                        field: options.field.options.result,
                        tables: options.tables,
                        controller: options.controller
                    })).pipe(map(function (params) {
                        return {
                            field: resultMapping.field,
                            params: __assign({}, params, extraParams)
                        };
                    }));
                }
                else {
                    fieldWithParams = of({
                        field: resultMapping.field,
                        params: __assign({}, resultMapping.params, extraParams)
                    });
                }
                return fieldWithParams;
            }
        }
    },
    {
        type: AirtableFieldType.LastModifiedTime,
        mapping: { field: FieldType.DateTime }
    },
    {
        type: AirtableFieldType.MultilineText,
        mapping: { field: FieldType.Text, params: { multiline: true } }
    },
    {
        type: AirtableFieldType.MultipleAttachments,
        mapping: {
            field: FieldType.File,
            params: { storage_resource: 'demo_rest_api', storage_name: 'temporary', multiple: true }
        }
    },
    {
        type: AirtableFieldType.MultipleCollaborators,
        mapping: {
            field: FieldType.JSON,
            params: {
                store_object: true,
                display_fields: true,
                structure: airtableCollaboratorsStructure()
            }
        }
    },
    {
        type: AirtableFieldType.MultipleLookupValues,
        mapping: {
            getField: function (options) {
                var _a;
                var mapping = options.field.options.result
                    ? airtableFieldMapping.find(function (i) { return i.type == options.field.options.result.type; })
                    : undefined;
                var resultMapping = mapping ? mapping.mapping : defaultAirtableFieldMapping;
                var extraParams = (_a = {}, _a[AIRTABLE_FIELD_ID_PARAM] = options.field.id, _a);
                var fieldWithParams;
                var table = options.tables.find(function (item) { return item.id == options.field.options.fieldIdInLinkedTable; });
                if (resultMapping.getField) {
                    fieldWithParams = forceObservable(resultMapping.getField({
                        name: options.name,
                        records: options.records,
                        options: options.options,
                        table: table,
                        field: options.field.options.result,
                        tables: options.tables,
                        controller: options.controller
                    })).pipe(map(function (field) {
                        return __assign({}, field, { params: __assign({}, field.params, extraParams) });
                    }));
                }
                else if (resultMapping.getParams) {
                    fieldWithParams = forceObservable(resultMapping.getParams({
                        name: options.name,
                        records: options.records,
                        options: options.options,
                        table: table,
                        field: options.field.options.result,
                        tables: options.tables,
                        controller: options.controller
                    })).pipe(map(function (params) {
                        return {
                            field: resultMapping.field,
                            params: __assign({}, params, extraParams)
                        };
                    }));
                }
                else {
                    fieldWithParams = of({
                        field: resultMapping.field,
                        params: __assign({}, resultMapping.params, extraParams)
                    });
                }
                return fieldWithParams;
            }
        }
    },
    {
        type: AirtableFieldType.MultipleRecordLinks,
        mapping: {
            getField: function (options) {
                var relatedTable = options.tables.find(function (item) { return item.id == options.field.options.linkedTableId; });
                var relatedTableId = relatedTable ? [options.options.base, relatedTable.id].join('_') : undefined;
                if (relatedTable && options.field.options.prefersSingleRecordLink) {
                    return {
                        field: FieldType.RelatedModel,
                        params: {
                            related_model: {
                                model: "{{resource}}." + relatedTableId
                            }
                        }
                    };
                }
                else if (relatedTable && !options.field.options.prefersSingleRecordLink) {
                    var displayField = relatedTable.fields.find(function (item) { return item.id == relatedTable.primaryFieldId; });
                    return {
                        field: FieldType.MultipleSelect,
                        params: {
                            output_format: MultipleSelectOutputFormat.Array,
                            options_type: OptionsType.Query,
                            resource: '{{resource}}',
                            query: {
                                query_type: 'simple',
                                simple_query: {
                                    model: relatedTableId
                                }
                            },
                            value_field: AIRTABLE_PRIMARY_KEY,
                            label_field: displayField ? displayField.name : undefined,
                            columns: [
                                {
                                    name: AIRTABLE_PRIMARY_KEY,
                                    verboseName: 'ID',
                                    field: FieldType.Text
                                }
                            ].concat(relatedTable.fields.map(function (item) {
                                return {
                                    name: item.name,
                                    field: FieldType.Text
                                };
                            }), [
                                {
                                    name: AIRTABLE_CREATED_TIME,
                                    field: FieldType.DateTime
                                }
                            ])
                        }
                    };
                }
                else {
                    var values = uniq(options.records
                        .reduce(function (acc, item) {
                        if (item.fields[options.name]) {
                            acc.push.apply(acc, item.fields[options.name]);
                        }
                        return acc;
                    }, [])
                        .filter(function (item) { return isSet(item); }));
                    return {
                        field: FieldType.MultipleSelect,
                        params: {
                            output_format: MultipleSelectOutputFormat.Array,
                            options_type: OptionsType.Static,
                            options: values.map(function (item, i) {
                                return {
                                    value: item,
                                    name: item,
                                    color: AUTO_OPTION_COLORS[i]
                                };
                            })
                        }
                    };
                }
            }
        }
    },
    {
        type: AirtableFieldType.MultipleSelects,
        mapping: {
            field: FieldType.MultipleSelect,
            getParams: function (options) {
                return {
                    output_format: MultipleSelectOutputFormat.Array,
                    options_type: OptionsType.Static,
                    options: options.field.options.choices.map(function (item, i) {
                        return {
                            value: item.name,
                            name: item.name,
                            color: parseAirtableColor(item.color) || getCircleIndex(AUTO_OPTION_COLORS, i)
                        };
                    })
                };
            }
        }
    },
    {
        type: AirtableFieldType.Number,
        mapping: {
            field: FieldType.Number,
            getParams: function (options) {
                return {
                    value_format: {
                        number_fraction: options.field.options.precision > 0 ? options.field.options.precision : 2
                    }
                };
            }
        }
    },
    {
        type: AirtableFieldType.Percent,
        mapping: {
            field: FieldType.Number,
            getParams: function (options) {
                return {
                    value_format: {
                        number_format: NumberValueFormat.Percentage,
                        number_fraction: options.field.options.precision > 0 ? options.field.options.precision : 2
                    }
                };
            }
        }
    },
    {
        type: AirtableFieldType.Rating,
        mapping: {
            field: FieldType.Rating,
            getParams: function (options) {
                return {
                    max_value: options.field.options.max
                };
            }
        }
    },
    {
        type: AirtableFieldType.RichText,
        // mapping: { field: FieldType.RichText, params: { output: RichTextOutput.Markdown } }
        mapping: { field: FieldType.Text, params: { multiline: true } }
    },
    {
        type: AirtableFieldType.Rollup,
        mapping: { field: FieldType.Text }
    },
    {
        type: AirtableFieldType.SingleCollaborator,
        mapping: {
            field: FieldType.JSON,
            params: {
                store_object: true,
                display_fields: true,
                structure: airtableCollaboratorsStructure(1)
            }
        }
    },
    {
        type: AirtableFieldType.SingleLineText,
        mapping: { field: FieldType.Text }
    },
    {
        type: AirtableFieldType.SingleSelect,
        mapping: {
            field: FieldType.Select,
            getParams: function (options) {
                return {
                    options_type: OptionsType.Static,
                    options: options.field.options.choices.map(function (item, i) {
                        return {
                            value: item.name,
                            name: item.name,
                            color: parseAirtableColor(item.color) || getCircleIndex(AUTO_OPTION_COLORS, i)
                        };
                    })
                };
            }
        }
    },
    {
        type: AirtableFieldType.Url,
        mapping: { field: FieldType.URL }
    }
];
export var defaultAirtableFieldMapping = {
    field: FieldType.Text
};
export { ɵ0 };

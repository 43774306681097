var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { FormBuilder, FormControl } from '@angular/forms';
import { JsonOutputFormat } from '@modules/fields';
var JsonFieldDataParamsForm = /** @class */ (function () {
    function JsonFieldDataParamsForm(fb) {
        this.fb = fb;
        this.outputFormatOptions = [
            { value: JsonOutputFormat.Object, name: 'JSON Object' },
            { value: JsonOutputFormat.String, name: 'String' },
            { value: JsonOutputFormat.Firestore, name: 'Firebase format' }
        ];
        this.form = this.fb.group({
            output_format: new FormControl(JsonOutputFormat.Object)
        });
    }
    JsonFieldDataParamsForm.prototype.init = function (control) {
        var _this = this;
        this.control = control;
        if (control.value) {
            var outputFormat = void 0;
            if (control.value.hasOwnProperty('output_format')) {
                outputFormat = control.value['output_format'];
            }
            else if (control.value.hasOwnProperty('store_object')) {
                outputFormat = control.value['store_object'] ? JsonOutputFormat.Object : JsonOutputFormat.String;
            }
            if (outputFormat) {
                this.form.controls['output_format'].patchValue(outputFormat, { emitEvent: false });
            }
        }
        this.form.valueChanges.subscribe(function () { return _this.submit(); });
    };
    JsonFieldDataParamsForm.prototype.submit = function () {
        var value = this.form.value;
        var result = {
            output_format: value['output_format']
        };
        this.control.patchValue(__assign({}, this.control.value, result));
    };
    return JsonFieldDataParamsForm;
}());
export { JsonFieldDataParamsForm };

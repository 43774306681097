import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';

import { NotificationService } from '@common/notifications';
import { ServerRequestError } from '@modules/api';
import { CurrentUserStore, UserService } from '@modules/users';

@Component({
  selector: 'app-email-verification-toast',
  templateUrl: './email-verification-toast.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmailVerificationToastComponent implements OnInit, OnDestroy {
  @Input() shift = false;

  sent = false;

  constructor(
    public currentUserStore: CurrentUserStore,
    private notificationService: NotificationService,
    private userService: UserService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {}

  ngOnDestroy(): void {}

  resend() {
    this.userService
      .resendVerification()
      .pipe(untilDestroyed(this))
      .subscribe(
        () => {
          this.sent = true;
          this.cd.markForCheck();
          this.notificationService.success(
            'Verification email sent',
            'Please check your email and follow instructions'
          );
        },
        error => {
          if (error instanceof ServerRequestError && error.nonFieldErrors.length) {
            this.notificationService.error('Failed to send', error.nonFieldErrors[0]);
          } else {
            this.notificationService.error('Failed to send', `Unknown error`);
          }
        }
      );
  }
}

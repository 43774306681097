import { Injectable, Injector } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AppFormGroup, FormUtils } from '@common/form-utils';
import { PopupService } from '@common/popups';
import { MenuGeneratorService } from '@modules/menu';
import { ProjectSettingsService } from '@modules/project-settings';
import { ProjectTokenService, SecretTokenService } from '@modules/projects';
import { AmazonS3GeneratorService, AmazonS3ParamsOptions } from '@modules/resource-generators';
import { ResourceParamsResult } from '@modules/resources';

import { BaseResourceSettingsForm } from '../base-resource-settings/base-resource-settings.form';
import {
  StorageBucketArray,
  validateStorageBucketArray
} from '../s3-compatible-resource-settings/storage-bucket.array';

export class Form extends AppFormGroup {
  controls: {
    buckets: StorageBucketArray;
    access_key_id: FormControl;
    secret_access_key: FormControl;
  };

  constructor() {
    super({
      buckets: new StorageBucketArray([''], validateStorageBucketArray()),
      access_key_id: new FormControl('', Validators.required),
      secret_access_key: new FormControl('', Validators.required)
    });
  }
}

@Injectable()
export class AmazonS3ResourceSettingsForm extends BaseResourceSettingsForm<AmazonS3ParamsOptions> {
  form = new Form();

  constructor(
    private amazonS3GeneratorService: AmazonS3GeneratorService,
    protected secretTokenService: SecretTokenService,
    protected formUtils: FormUtils,
    protected projectSettingsService: ProjectSettingsService,
    protected projectTokenService: ProjectTokenService,
    protected popupService: PopupService,
    protected menuGeneratorService: MenuGeneratorService,
    protected injector: Injector
  ) {
    super(
      secretTokenService,
      formUtils,
      projectSettingsService,
      projectTokenService,
      popupService,
      menuGeneratorService,
      injector
    );
  }

  initResourceValue(): Observable<void> {
    return this.amazonS3GeneratorService.getParamsOptions(this.project, this.environment, this.resource).pipe(
      map(result => {
        this.form.patchValue({
          access_key_id: result.access_key_id,
          secret_access_key: result.secret_access_key
        });

        this.form.controls.buckets.deserialize(result.buckets.length ? result.buckets : ['']);
      })
    );
  }

  getOptions(): AmazonS3ParamsOptions {
    return {
      buckets: this.form.value['buckets'],
      access_key_id: this.form.value['access_key_id'],
      secret_access_key: this.form.value['secret_access_key']
    };
  }

  getParams(): ResourceParamsResult | Observable<ResourceParamsResult> {
    return this.amazonS3GeneratorService
      .generateParams(this.project, this.environment, this.typeItem, this.getOptions())
      .pipe(
        map(result => {
          return {
            ...result,
            resourceName: this.resourceForm.value['name']
          };
        })
      );
  }
}

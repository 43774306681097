/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../shared/pipes/is-set/is-set.pipe";
import * as i2 from "@angular/common";
import * as i3 from "./bar-code.component";
var styles_BarCodeComponent = [];
var RenderType_BarCodeComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_BarCodeComponent, data: {} });
export { RenderType_BarCodeComponent as RenderType_BarCodeComponent };
function View_BarCodeComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["class", "bar-code__error"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.error; _ck(_v, 1, 0, currVal_0); }); }
export function View_BarCodeComponent_0(_l) { return i0.ɵvid(2, [i0.ɵpid(0, i1.IsSetPipe, []), i0.ɵqud(402653184, 1, { svgElement: 0 }), (_l()(), i0.ɵeld(2, 0, [[1, 0], ["svg", 1]], null, 0, ":svg:svg", [], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_BarCodeComponent_1)), i0.ɵdid(4, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵppd(5, 1)], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 4, 0, _ck(_v, 5, 0, i0.ɵnov(_v, 0), _co.error)); _ck(_v, 4, 0, currVal_0); }, null); }
export function View_BarCodeComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-bar-code", [], [[2, "bar-code", null], [4, "min-width", "px"]], null, null, View_BarCodeComponent_0, RenderType_BarCodeComponent)), i0.ɵdid(1, 770048, null, 0, i3.BarCodeComponent, [i0.ElementRef, i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).cls; var currVal_1 = i0.ɵnov(_v, 1).minWidth; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
var BarCodeComponentNgFactory = i0.ɵccf("app-bar-code", i3.BarCodeComponent, View_BarCodeComponent_Host_0, { format: "format", value: "value", fillColor: "fillColor", backgroundColor: "backgroundColor", displayText: "displayText" }, {}, []);
export { BarCodeComponentNgFactory as BarCodeComponentNgFactory };

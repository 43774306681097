<ng-container *ngIf="!loading">
  <app-select-model-field
    *ngIf="selectedOption"
    [modelDescription]="selectedOption.relatedModelDescription"
    [searchPlaceholder]="searchPlaceholder"
    [relationsEnabled]="relationsEnabled"
    [onlyNestedFields]="onlyNestedFields"
    [fieldsSelectEnabled]="fieldsSelectEnabled"
    [relationsSelectEnabled]="relationsSelectEnabled"
    [aggregationsEnabled]="aggregationsEnabled"
    [path]="selectedOptionPath"
    (nameSelected)="nameSelected.emit($event)"
    (selected)="selected.emit($event)"
    (back)="setSelectedFieldOption(undefined)"
  ></app-select-model-field>

  <ng-container *ngIf="!selectedOption">
    <div *ngIf="path.length || options.length" class="token-popup__item">
      <a
        *ngIf="path[path.length - 1] as prevItem"
        href="javascript:void(0)"
        class="token-popup-list-item token-popup-list-item_orange"
        style="margin-bottom: 4px;"
        (click)="back.emit()"
      >
        <div class="token-popup-list-item__inner token-popup-list-item__line" style="padding: 7px 4px;">
          <span class="token-popup-list-item__left"><span class="icon-arrow_backward_2"></span></span>
          <span class="token-popup-list-item__main">
            <ng-container *ngIf="prevItem.modelDescription">
              {{ 'Back to {0} fields' | localize: [prevItem.modelDescription.verboseName | appCapitalize] }}
            </ng-container>

            <ng-container *ngIf="!prevItem.modelDescription">
              {{ 'Back' | localize }}
            </ng-container>
          </span>
        </div>
      </a>

      <div *ngIf="options.length" class="token-popup-search">
        <input
          type="text"
          class="token-popup-search__input"
          [formControl]="searchControl"
          [appAutofocus]="true"
          [placeholder]="searchPlaceholder"
        />
        <span
          *ngIf="searchControl.value | appIsSet"
          class="token-popup-search__close icon-close"
          (click)="clearSearch()"
          [appTip]="'Clear value' | localize"
          [appTipOptions]="{ side: 'top' }"
        ></span>
      </div>
    </div>

    <ng-container *ngIf="!(searchControl.value | appIsSet) && path[path.length - 1] as prevItem">
      <div *ngIf="isSelectAllowed(prevItem)" class="token-popup__item">
        <div
          *ngIf="prevItem.field"
          class="token-popup-list-item token-popup-list-item_hoverable token-popup-list-item_clickable"
          (click)="selectOption(prevItem, { appendPath: false })"
        >
          <div class="token-popup-list-item__inner">
            <div class="token-popup-list-item__line">
              <span *ngIf="prevItem.icon" class="token-popup-list-item__left">
                <span class="token-popup-list-item__icon" [ngClass]="'icon-' + prevItem.icon"></span>
              </span>
              <span class="token-popup-list-item__main token-popup-list-item__main_wrap" [title]="prevItem.verboseName">
                {{ 'Choose {0} from list' | localize: [prevItem.verboseName | appCapitalize] }}
              </span>
            </div>
          </div>
        </div>

        <div
          *ngIf="prevItem.relation && !aggregationsEnabled"
          class="token-popup-list-item token-popup-list-item_hoverable token-popup-list-item_clickable"
          (click)="selectOption(prevItem, { appendPath: false })"
        >
          <div class="token-popup-list-item__inner">
            <div class="token-popup-list-item__line">
              <span *ngIf="prevItem.icon" class="token-popup-list-item__left">
                <span class="token-popup-list-item__icon" [ngClass]="'icon-' + prevItem.icon"></span>
              </span>
              <span class="token-popup-list-item__main token-popup-list-item__main_wrap" [title]="prevItem.verboseName">
                {{ 'Aggregate {0}' | localize: [prevItem.verboseName | appCapitalize] }}
              </span>
            </div>
          </div>
        </div>

        <div *ngIf="prevItem.relation && aggregationsEnabled" class="token-popup-section">
          <div class="token-popup-section__title">
            <div class="token-popup-section__title-main">
              {{ prevItem.verboseName | appCapitalize }}
            </div>
          </div>

          <div class="token-popup-section__content">
            <ng-container *ngFor="let aggregation of aggregations; let i = index">
              <ng-container *ngIf="aggregation.fieldDropdown">
                <ng-template
                  cdkConnectedOverlay
                  [cdkConnectedOverlayPanelClass]="['overlay', 'overlay_horizontal_center']"
                  [cdkConnectedOverlayPositions]="fieldDropdownPositions"
                  [cdkConnectedOverlayOrigin]="dropdownTrigger"
                  [cdkConnectedOverlayOpen]="(selectedAggregationIndex$ | async) === i"
                  [cdkConnectedOverlayPush]="true"
                  [cdkConnectedOverlayGrowAfterOpen]="true"
                  [cdkConnectedOverlayHasBackdrop]="false"
                  [cdkConnectedOverlayBackdropClass]="'popover2-backdrop'"
                >
                  <div class="popover2 overlay-content overlay-content_animated">
                    <div class="popover2__inner">
                      <div class="popover2__viewport popover2__viewport_scrollable">
                        <div class="popover2__content" style="min-width: 300px;">
                          <div class="token-popup__item" style="margin-top: 10px;">
                            <div class="token-popup-section">
                              <div class="token-popup-section__title">
                                {{ aggregation.label(prevItem.verboseName) }}
                              </div>
                              <div class="token-popup-section__content">
                                <ng-container *ngFor="let field of modelDescription.dbFields">
                                  <div
                                    class="token-popup-list-item token-popup-list-item_hoverable token-popup-list-item_clickable"
                                    (click)="
                                      selectOption(prevItem, {
                                        appendPath: false,
                                        aggregation: { func: aggregation.func, field: field.name }
                                      })
                                    "
                                  >
                                    <div class="token-popup-list-item__inner">
                                      <div class="token-popup-list-item__line">
                                        <span *ngIf="field.fieldDescription?.icon" class="token-popup-list-item__left">
                                          <span
                                            class="token-popup-list-item__icon"
                                            [ngClass]="'icon-' + field.fieldDescription.icon"
                                          ></span>
                                        </span>
                                        <span class="token-popup-list-item__main token-popup-list-item__main_wrap">
                                          {{ field.verboseName || field.name }}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </ng-container>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-template>

                <div
                  class="token-popup-list-item token-popup-list-item_hoverable token-popup-list-item_clickable"
                  (mouseenter)="selectedAggregationIndex$.next(i)"
                  cdkOverlayOrigin
                  #dropdownTrigger="cdkOverlayOrigin"
                >
                  <div class="token-popup-list-item__inner">
                    <div class="token-popup-list-item__line">
                      <span class="token-popup-list-item__main token-popup-list-item__main_wrap">
                        {{ aggregation.label(prevItem.verboseName) }}
                      </span>
                      <span class="token-popup-list-item__right">
                        <span class="token-popup-list-item__icon icon-arrow_forward_2"></span>
                      </span>
                    </div>
                  </div>
                </div>
              </ng-container>

              <ng-container *ngIf="!aggregation.fieldDropdown">
                <div
                  class="token-popup-list-item token-popup-list-item_hoverable token-popup-list-item_clickable"
                  (click)="selectOption(prevItem, { appendPath: false, aggregation: { func: aggregation.func } })"
                  (mouseenter)="selectedAggregationIndex$.next(undefined)"
                >
                  <div class="token-popup-list-item__inner">
                    <div class="token-popup-list-item__line">
                      <span class="token-popup-list-item__main token-popup-list-item__main_wrap">
                        {{ aggregation.label(prevItem.verboseName) }}
                      </span>
                    </div>
                  </div>
                </div>
              </ng-container>
            </ng-container>
          </div>
        </div>
      </div>
    </ng-container>

    <div *ngIf="!options.length" class="token-popup__item" style="margin-top: 10px;">
      <div class="token-popup-section">
        <div class="token-popup-section__content">
          <div class="token-popup-list-item">
            <div class="token-popup-list-item__inner">
              <div *ngIf="emptyMessage | appIsSet" class="token-popup-list-item__tip" [innerHTML]="emptyMessage"></div>
              <div *ngIf="!(emptyMessage | appIsSet)" class="token-popup-list-item__tip">
                {{ 'No fields to select' | localize }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      *ngIf="options.length"
      class="token-popup__item"
      [style.margin-top.px]="!path.length ? 10 : null"
      (mouseenter)="selectedAggregationIndex$.next(undefined)"
    >
      <div *ngIf="!filteredFieldOptions.length && !filteredRelationOptions.length" class="token-popup-section">
        <div class="token-popup-section__content">
          <div class="token-popup-list-item">
            <div class="token-popup-list-item__inner">
              <div class="token-popup-list-item__tip">
                {{ 'Nothing found' | localize }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="filteredFieldOptions.length" class="token-popup-section">
        <div *ngIf="path[path.length - 1] as prevItem" class="token-popup-section__title">
          <div class="token-popup-section__title-main">
            {{ '{0} fields' | localize: [prevItem.verboseName] }}
          </div>
        </div>

        <div class="token-popup-section__content">
          <ng-container *ngFor="let item of filteredFieldOptions">
            <div
              *ngIf="!item.relatedModelDescription"
              class="token-popup-list-item token-popup-list-item_hoverable token-popup-list-item_clickable"
              (click)="selectOption(item)"
            >
              <div class="token-popup-list-item__inner">
                <div class="token-popup-list-item__line">
                  <span *ngIf="item.icon" class="token-popup-list-item__left">
                    <span class="token-popup-list-item__icon" [ngClass]="'icon-' + item.icon"></span>
                  </span>
                  <span class="token-popup-list-item__main token-popup-list-item__main_wrap" [title]="item.verboseName">
                    {{ item.verboseName | appCapitalize }}
                  </span>
                </div>
              </div>
            </div>

            <div
              *ngIf="item.relatedModelDescription"
              class="token-popup-list-item token-popup-list-item_hoverable token-popup-list-item_clickable"
              (click)="setSelectedFieldOption(item)"
            >
              <div class="token-popup-list-item__inner">
                <div class="token-popup-list-item__line">
                  <span *ngIf="item.icon" class="token-popup-list-item__left">
                    <span class="token-popup-list-item__icon" [ngClass]="'icon-' + item.icon"></span>
                  </span>
                  <span class="token-popup-list-item__main token-popup-list-item__main_wrap" [title]="item.verboseName">
                    {{ item.verboseName | appCapitalize }}
                  </span>
                  <span class="token-popup-list-item__right">
                    <span class="token-popup-list-item__icon icon-arrow_forward_2"></span>
                  </span>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>

      <div *ngIf="filteredRelationOptions.length" class="token-popup-section">
        <div class="token-popup-section__title">
          <div class="token-popup-section__title-main">
            {{
              '{0} relationships' | localize: [path[path.length - 1] ? path[path.length - 1].verboseName : undefined]
            }}
          </div>
        </div>

        <div class="token-popup-section__content">
          <ng-container *ngFor="let item of filteredRelationOptions">
            <div
              class="token-popup-list-item token-popup-list-item_hoverable token-popup-list-item_clickable"
              (click)="setSelectedFieldOption(item)"
            >
              <div class="token-popup-list-item__inner">
                <div class="token-popup-list-item__line">
                  <span *ngIf="item.icon" class="token-popup-list-item__left">
                    <span class="token-popup-list-item__icon" [ngClass]="'icon-' + item.icon"></span>
                  </span>
                  <span class="token-popup-list-item__main token-popup-list-item__main_wrap" [title]="item.verboseName">
                    {{ item.verboseName | appCapitalize }}

                    <span *ngIf="item.additional | appIsSet" class="token-popup-list-item__main-additional">
                      {{ item.additional }}
                    </span>
                  </span>
                  <span class="token-popup-list-item__right">
                    <span class="token-popup-list-item__icon icon-arrow_forward_2"></span>
                  </span>
                </div>

                <div *ngIf="item.description | appIsSet" class="token-popup-list-item__line">
                  <div class="token-popup-list-item__description">{{ item.description }}</div>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>

<ng-container *ngIf="loading">
  <app-select-model-field-stub></app-select-model-field-stub>
</ng-container>

import { Injectable, Injector } from '@angular/core';
import { FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { AppFormGroup, FormUtils } from '@common/form-utils';
import { PopupService } from '@common/popups';
import { MenuGeneratorService } from '@modules/menu';
import { ProjectSettingsService } from '@modules/project-settings';
import { ProjectTokenService, SecretTokenService } from '@modules/projects';
import { GoogleCloudStorageGeneratorService, GoogleCloudStorageParamsOptions } from '@modules/resource-generators';
import { ResourceParamsResult } from '@modules/resources';
import { isSet } from '@shared';

import { BaseResourceSettingsForm } from '../base-resource-settings/base-resource-settings.form';

@Injectable()
export class GoogleCloudStorageResourceSettingsForm extends BaseResourceSettingsForm<GoogleCloudStorageParamsOptions> {
  form = new AppFormGroup({
    service_token: new FormControl(null, [Validators.required, this.validateServiceToken()]),
    project_id: new FormControl(null, Validators.required),
    access_token: new FormControl(null, Validators.required)
  });

  constructor(
    private googleCloudStorageGeneratorService: GoogleCloudStorageGeneratorService,
    protected secretTokenService: SecretTokenService,
    protected formUtils: FormUtils,
    protected projectSettingsService: ProjectSettingsService,
    protected projectTokenService: ProjectTokenService,
    protected popupService: PopupService,
    protected menuGeneratorService: MenuGeneratorService,
    protected injector: Injector
  ) {
    super(
      secretTokenService,
      formUtils,
      projectSettingsService,
      projectTokenService,
      popupService,
      menuGeneratorService,
      injector
    );

    this.form.controls['service_token'].valueChanges
      .pipe(
        switchMap(value => {
          return this.googleCloudStorageGeneratorService.readServiceToken(value).pipe(catchError(() => of(undefined)));
        })
      )
      .subscribe(result => {
        this.form.patchValue(
          result
            ? {
                project_id: result.projectId,
                access_token: result.accessToken
              }
            : {
                project_id: '',
                access_token: ''
              }
        );
      });
  }

  validateServiceToken(): ValidatorFn {
    return (control: FormControl) => {
      if (!isSet(control.value)) {
        return;
      }

      const group = control.parent as FormGroup;

      if (!group) {
        return;
      }

      if (group.value['service_token'] && (!group.value['project_id'] || !group.value['access_token'])) {
        return { local: ['Service key is not valid or not enough permissions'] };
      }
    };
  }

  initResourceValue(): Observable<void> {
    return this.googleCloudStorageGeneratorService.getParamsOptions(this.project, this.environment, this.resource).pipe(
      map(result => {
        this.form.patchValue({
          service_token: result.serviceToken,
          project_id: result.projectId,
          access_token: result.accessToken
        });
      })
    );
  }

  getOptions(): GoogleCloudStorageParamsOptions {
    return {
      serviceToken: this.form.value['service_token'].trim(),
      projectId: this.form.value['project_id'],
      accessToken: this.form.value['access_token']
    };
  }

  getParams(): ResourceParamsResult | Observable<ResourceParamsResult> {
    return this.googleCloudStorageGeneratorService
      .generateParams(this.project, this.environment, this.typeItem, this.getOptions())
      .pipe(
        map(result => {
          return {
            ...result,
            resourceName: this.resourceForm.value['name']
          };
        })
      );
  }
}

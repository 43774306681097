<div class="table-editor table-editor_simple">
  <div class="table-editor__list">
    <div class="table-editor__list-inner">
      <a
        href="javascript:void(0)"
        *ngFor="let field of modelDescription.dbFields; trackBy: trackByFn"
        (click)="addFlexField(field)"
        class="table-editor__item table-editor__item_add"
      >
        <span class="table-editor__item-label">
          {{ field.verboseName }}
        </span>
      </a>
    </div>
    <div class="table-editor__list-inner" *ngIf="relatedFields.length">
      <div class="table-editor__title">
        <span class="icon icon-link"></span>
        Related
      </div>
      <a
        *ngFor="let field of relatedFields"
        href="javascript:void(0)"
        class="table-editor__item table-editor__item_link"
        (click)="showRelation(field, list_item)"
        #list_item
      >
        <div class="table-editor__item-label">
          {{ field.verboseName }}
        </div>
      </a>
    </div>
  </div>
</div>

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../../../../shared/pipes/is-set/is-set.pipe";
import * as i3 from "../../../../shared/pipes/capitalize/capitalize.pipe";
import * as i4 from "@angular/cdk/overlay";
import * as i5 from "@angular/cdk/bidi";
import * as i6 from "./custom-page-properties-add-menu.component";
var styles_CustomPagePropertiesAddMenuComponent = [];
var RenderType_CustomPagePropertiesAddMenuComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CustomPagePropertiesAddMenuComponent, data: {} });
export { RenderType_CustomPagePropertiesAddMenuComponent as RenderType_CustomPagePropertiesAddMenuComponent };
function View_CustomPagePropertiesAddMenuComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "token-popup-list-item__line"]], [[2, "token-popup-list-item__line_active", null]], null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "div", [["class", "token-popup-list-item__description"]], [[8, "innerHTML", 1]], null, null, null, null)), i0.ɵppd(2, 1)], null, function (_ck, _v) { var currVal_0 = false; _ck(_v, 0, 0, currVal_0); var currVal_1 = i0.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i0.ɵnov(_v.parent.parent.parent, 1), _v.parent.context.$implicit.description)); _ck(_v, 1, 0, currVal_1); }); }
function View_CustomPagePropertiesAddMenuComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 10, "a", [["class", "token-popup-list-item token-popup-list-item_hoverable token-popup-list-item_clickable"], ["href", "javascript:void(0)"]], [[2, "token-popup-list-item_active", null], [4, "margin-top", "px"]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        _co.buttonClick.emit(_v.context.$implicit);
        var pd_0 = (_co.setDropdownOpened(false) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 9, "div", [["class", "token-popup-list-item__inner"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 5, "div", [["class", "token-popup-list-item__line"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 2, "span", [["class", "token-popup-list-item__left"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 1, "span", [["class", "token-popup-list-item__icon"]], null, null, null, null, null)), i0.ɵdid(5, 278528, null, 0, i1.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i0.ɵeld(6, 0, null, null, 1, "span", [["class", "token-popup-list-item__main token-popup-list-item__main_wrap"]], null, null, null, null, null)), (_l()(), i0.ɵted(7, null, [" ", " "])), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_CustomPagePropertiesAddMenuComponent_3)), i0.ɵdid(9, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵppd(10, 1)], function (_ck, _v) { var currVal_2 = "token-popup-list-item__icon"; var currVal_3 = ("icon-" + _v.context.$implicit.icon); _ck(_v, 5, 0, currVal_2, currVal_3); var currVal_5 = i0.ɵunv(_v, 9, 0, _ck(_v, 10, 0, i0.ɵnov(_v.parent.parent, 0), _v.context.$implicit.description)); _ck(_v, 9, 0, currVal_5); }, function (_ck, _v) { var currVal_0 = false; var currVal_1 = (_v.context.first ? 0 : 5); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_4 = _v.context.$implicit.label; _ck(_v, 7, 0, currVal_4); }); }
function View_CustomPagePropertiesAddMenuComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "div", [["class", "popover2 overlay-content overlay-content_animated"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 5, "div", [["class", "popover2__inner"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 4, "div", [["class", "popover2__viewport popover2__viewport_scrollable"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 3, "div", [["class", "popover2__content"], ["style", "min-width: 300px;"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 2, "div", [["class", "token-popup__item"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CustomPagePropertiesAddMenuComponent_2)), i0.ɵdid(6, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.buttons; _ck(_v, 6, 0, currVal_0); }, null); }
export function View_CustomPagePropertiesAddMenuComponent_0(_l) { return i0.ɵvid(0, [i0.ɵpid(0, i2.IsSetPipe, []), i0.ɵpid(0, i3.CapitalizePipe, []), i0.ɵqud(402653184, 1, { cdkConnectedOverlay: 0 }), (_l()(), i0.ɵand(16777216, null, null, 2, function (_v, en, $event) { var ad = true; var _co = _v.component; if (("backdropClick" === en)) {
        var pd_0 = (_co.setDropdownOpened(false) !== false);
        ad = (pd_0 && ad);
    } if (("detach" === en)) {
        var pd_1 = (_co.setDropdownOpened(false) !== false);
        ad = (pd_1 && ad);
    } return ad; }, View_CustomPagePropertiesAddMenuComponent_1)), i0.ɵdid(4, 671744, [[1, 4]], 0, i4.CdkConnectedOverlay, [i4.Overlay, i0.TemplateRef, i0.ViewContainerRef, i4.ɵc, [2, i5.Directionality]], { origin: [0, "origin"], positions: [1, "positions"], backdropClass: [2, "backdropClass"], panelClass: [3, "panelClass"], open: [4, "open"], hasBackdrop: [5, "hasBackdrop"], growAfterOpen: [6, "growAfterOpen"], push: [7, "push"] }, { backdropClick: "backdropClick", detach: "detach" }), i0.ɵpad(5, 2)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.origin; var currVal_1 = _co.popoverPositions; var currVal_2 = "popover2-backdrop"; var currVal_3 = _ck(_v, 5, 0, "overlay", "overlay_horizontal_center"); var currVal_4 = _co.dropdownOpened; var currVal_5 = true; var currVal_6 = true; var currVal_7 = true; _ck(_v, 4, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }, null); }
export function View_CustomPagePropertiesAddMenuComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-custom-page-properties-add-menu", [], null, null, null, View_CustomPagePropertiesAddMenuComponent_0, RenderType_CustomPagePropertiesAddMenuComponent)), i0.ɵdid(1, 4440064, null, 0, i6.CustomPagePropertiesAddMenuComponent, [i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CustomPagePropertiesAddMenuComponentNgFactory = i0.ɵccf("app-custom-page-properties-add-menu", i6.CustomPagePropertiesAddMenuComponent, View_CustomPagePropertiesAddMenuComponent_Host_0, { buttons: "buttons", origin: "origin", trigger: "trigger", triggerManual: "triggerManual" }, { buttonClick: "buttonClick" }, []);
export { CustomPagePropertiesAddMenuComponentNgFactory as CustomPagePropertiesAddMenuComponentNgFactory };

import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import values from 'lodash/values';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, Observable, of, Subscription } from 'rxjs';
import { auditTime, delay, distinctUntilChanged, map, skip, switchMap } from 'rxjs/operators';

import { DialogService } from '@common/dialogs';
import { NotificationService } from '@common/notifications';
import { rawListViewSettingsColumnsToDisplayField, ViewContextElement } from '@modules/customize';
import { BooleanFieldStyle, CustomSelectItem, CustomSelectItemButton, Option } from '@modules/field-components';
import {
  applyParamInputs,
  createFormFieldFactory,
  deserializeDisplayField,
  DisplayField,
  FieldType,
  getFieldDescriptionByType,
  Input,
  MultipleSelectStyle,
  OptionsType,
  ParameterField,
  registerFieldViewParamsComponent
} from '@modules/fields';
import { ITEM_OUTPUT } from '@modules/list';
import { ModelDescription, PER_PAGE_PARAM } from '@modules/models';
import { ModelSelectSource } from '@modules/models-list';
import { Resource } from '@modules/projects';
import { ResourceAddModelComponentItem, ResourceModelEditController } from '@modules/projects-components';
import {
  editableQueryTypes,
  HttpQuery,
  ListModelDescriptionQuery,
  ObjectQueryOperation,
  QueryService,
  QueryType
} from '@modules/queries';
import { QueryBuilderContext, QueryBuilderSaveEvent, QueryBuilderService } from '@modules/queries-components';
import { RoutingService } from '@modules/routing';
import { SidebarCollapseContext } from '@modules/sidebar';
import { Token } from '@modules/tokens';
import { controlValue, isSet } from '@shared';

import { FieldParamsComponent } from '../../field-params/field-params.component';
import { ModelOption, SelectFieldViewParamsForm } from './select-field-view-params.form';

export const optionsElementName = '__jet_options_element__';

@Component({
  selector: 'app-select-field-view-params',
  templateUrl: './select-field-view-params.component.html',
  providers: [SelectFieldViewParamsForm, QueryBuilderContext, ViewContextElement, ModelSelectSource],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectFieldViewParamsComponent extends FieldParamsComponent implements OnInit, OnDestroy {
  createField = createFormFieldFactory();
  displayOptions: FormGroup[] = [];
  addingMissing = false;
  optionsTypes = OptionsType;
  collapseContext = new SidebarCollapseContext();
  createdOption: FormGroup;
  type: FieldType;
  multiple = false;
  editable = false;
  toStaticOptionsLoading = false;
  toStaticOptionsSubscription: Subscription;
  optionsMaxDisplayInitial = 8;
  optionsCollapsed = true;

  queryTypes = QueryType;
  editableQueryTypes = editableQueryTypes;
  fieldTypes = FieldType;

  resource: Resource;
  resourceModelItems: CustomSelectItem<ModelOption>[];
  modelOption: ModelOption;
  modelDescription: ModelDescription;
  resourceBaseHttpQuery: HttpQuery;
  columnOptions: CustomSelectItem<string>[];
  sortableColumnOptions: Option<string>[];
  columnContextElementPath = [ITEM_OUTPUT];
  booleanFieldStyle = BooleanFieldStyle;
  multipleSelectStyles = MultipleSelectStyle;
  object = 'component_field_select';

  constructor(
    public form: SelectFieldViewParamsForm,
    public columnContextElement: ViewContextElement,
    private queryContext: QueryBuilderContext,
    private queryBuilderService: QueryBuilderService,
    private notificationService: NotificationService,
    private dialogService: DialogService,
    private queryService: QueryService,
    private resourceModelEditController: ResourceModelEditController,
    private modelSelectSource: ModelSelectSource,
    private routing: RoutingService,
    private injector: Injector,
    private cd: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit() {
    this.form.init(this.control, this.context);
    this.queryContext.parametersControl = this.form.controls.parameters;

    this.updateTokens();

    this.form.controls.parameters.valueChanges.pipe(delay(0), untilDestroyed(this)).subscribe(() => {
      this.updateTokens();
    });

    this.columnContextElement.initElement({
      uniqueName: optionsElementName,
      name: 'Fields'
    });

    if (this.field$) {
      this.field$.pipe(untilDestroyed(this)).subscribe(field => {
        this.type = field ? field.field : undefined;
        this.multiple = this.type == FieldType.MultipleSelect;
        this.editable = (!this.configurable || this.configurable.editable) && field && field['editable'];
        this.cd.markForCheck();
      });
    }

    controlValue(this.form.controls.options)
      .pipe(untilDestroyed(this))
      .subscribe(() => this.updateDisplayItems());

    combineLatest(
      this.form.resource$(this.form.controls.resource),
      this.form.resourceModelItems$(this.form.controls.resource),
      this.form.modelOption$(this.form.controls.model),
      this.form.modelDescription$(this.form.controls.resource, this.form.controls.model),
      this.form.resourceBaseHttpQuery$(this.form.controls.resource),
      this.form.columnOptions$(this.form.controls.columns),
      this.form.sortableColumnOptions$()
    )
      .pipe(auditTime(1000 / 60), untilDestroyed(this))
      .subscribe(result => {
        this.resource = result[0];
        this.resourceModelItems = result[1];
        this.modelOption = result[2];
        this.modelDescription = result[3];
        this.resourceBaseHttpQuery = result[4];
        this.columnOptions = result[5];
        this.sortableColumnOptions = result[6];
        this.cd.markForCheck();

        this.columnContextElement.setOutputs([
          {
            uniqueName: ITEM_OUTPUT,
            name: 'Fields',
            internal: true,
            children: this.columnOptions.map(item => {
              return {
                uniqueName: item.option.value,
                name: item.option.name,
                icon: item.option.icon
              };
            })
          }
        ]);
      });

    controlValue<ListModelDescriptionQuery>(this.form.controls.query)
      .pipe(
        delay(0),
        map(query => (query ? query.queryType : undefined)),
        distinctUntilChanged(),
        skip(1),
        untilDestroyed(this)
      )
      .subscribe(queryType => {
        if (editableQueryTypes.includes(queryType)) {
          this.editQuery();
        }
      });
  }

  ngOnDestroy(): void {}

  updateDisplayItems() {
    if (this.optionsCollapsed) {
      this.displayOptions = this.form.controls.options.controls.slice(0, this.optionsMaxDisplayInitial) as FormGroup[];
    } else {
      this.displayOptions = this.form.controls.options.controls as FormGroup[];
    }

    this.cd.markForCheck();
  }

  removeOption(group: FormGroup, i) {
    // this.dialogService
    //   .warning({
    //     title: 'Delete Option',
    //     description: `Are you sure want to delete <strong>Option #${i + 1}</strong>?`,
    //     style: 'orange'
    //   })
    //   .pipe(untilDestroyed(this))
    //   .subscribe(result => {
    //     if (result) {
    this.form.formArrayRemove('options', group);
    //   }
    // });
  }

  addOption() {
    const item = this.form.createOption();
    this.form.formArrayAppend('options', item);
    this.createdOption = item;
    this.cd.markForCheck();
  }

  dragDropOption(event: CdkDragDrop<FormControl[]>) {
    if (event.previousIndex !== event.currentIndex) {
      moveItemInArray(this.form.formArrayControls('options'), event.previousIndex, event.currentIndex);
      this.form.controls.options.updateValueAndValidity();
    }
  }

  addMissing() {
    this.addingMissing = true;
    this.cd.markForCheck();

    this.form
      .getMissing()
      .pipe(untilDestroyed(this))
      .subscribe(
        result => {
          if (result.length == 0) {
            this.notificationService.warning('No missing values', 'All found values are already added');
          } else {
            const maxValues = 10;
            let newValues = result;

            if (result.length > maxValues) {
              newValues = newValues.slice(0, maxValues);
              this.notificationService.success(
                'Missing values added',
                `
                Found <strong>${result.length}</strong> missing values,
                added first <strong>${newValues.length}</strong> of them
              `
              );
            } else {
              this.notificationService.success(
                'Missing values added',
                `
                <strong>${newValues.length}</strong> values were added
              `
              );
            }

            this.form.addMissing(newValues);
          }

          this.addingMissing = false;
          this.cd.markForCheck();
        },
        () => {
          this.addingMissing = false;
          this.cd.markForCheck();
        }
      );
  }

  updateTokens() {
    const tokens: Token[] = [
      {
        name: undefined,
        label: 'general',
        children: [
          {
            name: 'search',
            field: FieldType.Text,
            label: 'Search query'
          }
        ]
      },
      {
        name: 'filters._value',
        label: 'Selected Value'
      }
    ];

    if (this.form.controls.parameters.value) {
      tokens.push({
        name: undefined,
        label: 'Inputs',
        data: {
          parameter_tokens: true
        },
        children: this.form.controls.parameters.value
          .filter(item => item.name)
          .map(item => {
            const fieldDescription = getFieldDescriptionByType(item.field);

            return {
              name: ['params', item.name].join('.'),
              field: item.field,
              params: item.params,
              label: item.verboseName || item.name,
              icon: fieldDescription.icon
            };
          })
      });
    }

    this.queryContext.tokens = tokens;
  }

  editQuery() {
    if (!this.resource) {
      return;
    }

    const query = this.form.controls.query.value;

    this.queryBuilderService
      .edit({
        injector: this.injector,
        queryClass: this.form.queryClass,
        queryTypes: [query.queryType],
        resource: this.resource,
        resourceType: this.resource.type,
        context: this.queryContext,
        requireResponse: true,
        arrayResponse: true,
        query: query,
        parametersControl: this.form.controls.parameters,
        httpOptions: {
          baseQuery: this.resourceBaseHttpQuery
        },
        objectOptions: {
          forceOperation: ObjectQueryOperation.Get
        },
        source: 'component_select'
      })
      .pipe(untilDestroyed(this))
      .subscribe(e => {
        if (e.saved) {
          this.onQuerySaved(e.saved);
        }
      });
  }

  onQuerySaved(event: QueryBuilderSaveEvent) {
    const responseColumns = this.queryService.getAutoDetectColumns(event.query, true);

    if (!responseColumns) {
      this.notificationService.error('Failed to detect fields', 'Response is incorrect');
      return;
    }

    const columns = responseColumns.map(item => {
      const result = rawListViewSettingsColumnsToDisplayField(item);
      result.visible = true;
      return result;
    });
    const prevColumns = this.form.controls.columns.value as DisplayField[];

    this.form.patchValue({
      query: event.query,
      columns: columns.map(item => {
        const existingColumn = prevColumns ? prevColumns.find(i => i.name == item.name) : undefined;
        return existingColumn || item;
      })
    });
  }

  onModelButtonClick(button: CustomSelectItemButton) {
    if (button.name == 'add_model') {
      this.addModel(button.data['addModelComponent']);
    }
  }

  addModel(addModelComponent: ResourceAddModelComponentItem) {
    this.resourceModelEditController
      .addModel(this.resource, addModelComponent, { source: this.object })
      .pipe(untilDestroyed(this))
      .subscribe(result => {
        if (result.link) {
          this.routing.navigateApp(result.link);
        } else {
          this.form.controls.model.patchValue({ queryType: QueryType.Simple, model: result.modelDescription.model });
          this.form.markAsDirty();
        }
      });
  }

  toStaticOptions(merge = false) {
    if (this.toStaticOptionsSubscription) {
      this.toStaticOptionsSubscription.unsubscribe();
      this.toStaticOptionsSubscription = undefined;
    }

    const fieldParams = this.form.serialize();
    const parameters = fieldParams['parameters']
      ? fieldParams['parameters'].map(item => new ParameterField().deserialize(item))
      : [];
    const inputs = fieldParams['inputs'] ? fieldParams['inputs'].map(item => new Input().deserialize(item)) : [];
    const params = applyParamInputs(
      {
        [PER_PAGE_PARAM]: 100
      },
      inputs,
      { context: this.context, parameters: parameters }
    );

    this.modelSelectSource.init({
      resource: fieldParams['resource'],
      query: fieldParams['query'] ? new ListModelDescriptionQuery().deserialize(fieldParams['query']) : undefined,
      queryParameters: parameters,
      columns: fieldParams['columns'] ? fieldParams['columns'].map(item => deserializeDisplayField(item)) : undefined,
      valueField: fieldParams['value_field'],
      nameField: fieldParams['label_field'],
      nameInput: fieldParams['label_field_input']
        ? new Input().deserialize(fieldParams['label_field_input'])
        : undefined,
      subtitleField: fieldParams['subtitle_field'],
      subtitleInput: fieldParams['subtitle_input'] ? new Input().deserialize(fieldParams['subtitle_input']) : undefined,
      iconField: fieldParams['icon_field'],
      iconInput: fieldParams['icon_input'] ? new Input().deserialize(fieldParams['icon_input']) : undefined,
      colorField: fieldParams['color_field'],
      colorInput: fieldParams['color_input'] ? new Input().deserialize(fieldParams['color_input']) : undefined,
      params: params,
      multiple: this.multiple,
      context: this.context,
      contextElement: this.contextElement
    });
    this.modelSelectSource.reset();

    this.toStaticOptionsLoading = true;
    this.cd.markForCheck();

    this.toStaticOptionsSubscription = this.processStaticOptionsPage({})
      .pipe(untilDestroyed(this))
      .subscribe(
        result => {
          const options = values(result);

          if (!options.length) {
            this.notificationService.error('No options found', 'Specified query contains no options');
            return;
          }

          const existingOptions = merge ? this.form.controls.options.value : [];
          const existingColors = existingOptions.map(item => item['color']);
          const newOptions = [
            ...existingOptions,
            ...options
              .filter(option => {
                if (merge) {
                  return this.form.controls.options.value.find(item => item.value === option.value) === undefined;
                } else {
                  return true;
                }
              })
              .map((item, i) => {
                const autoColor = this.form.getAutoColor(existingColors, i);

                if (!existingColors.includes(autoColor)) {
                  existingColors.push(autoColor);
                }

                return {
                  color: autoColor,
                  name: item.name,
                  value: item.value,
                  subtitle: item.data['subtitle'],
                  icon: item.image
                };
              })
          ];

          this.form.formArraySet(
            'options',
            newOptions.map(() => this.form.createOption())
          );
          this.form.controls.options_type.patchValue(OptionsType.Static);
          this.form.controls.options.patchValue(newOptions);

          this.toStaticOptionsLoading = false;
          this.cd.markForCheck();
        },
        () => {
          this.toStaticOptionsLoading = false;
          this.cd.markForCheck();
        }
      );
  }

  processStaticOptionsPage(optionsAcc: { [k: string]: Option }): Observable<{ [k: string]: Option }> {
    if (!this.modelSelectSource.isFetchAvailable()) {
      return of(optionsAcc);
    }

    return this.modelSelectSource.fetch('').pipe(
      switchMap(result => {
        optionsAcc = result.reduce((acc, item) => {
          const key = JSON.stringify(item.value);
          if (!acc.hasOwnProperty(key)) {
            acc[key] = item;
          }

          return acc;
        }, optionsAcc);

        return this.processStaticOptionsPage(optionsAcc);
      })
    );
  }

  setOptionsCollapsed(value: boolean) {
    this.optionsCollapsed = value;
    this.cd.markForCheck();
    this.updateDisplayItems();
  }
}

registerFieldViewParamsComponent(FieldType.Select, SelectFieldViewParamsComponent);
registerFieldViewParamsComponent(FieldType.RadioButton, SelectFieldViewParamsComponent);
registerFieldViewParamsComponent(FieldType.MultipleSelect, SelectFieldViewParamsComponent);

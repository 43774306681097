/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./audio-player.component";
var styles_AudioPlayerComponent = [];
var RenderType_AudioPlayerComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AudioPlayerComponent, data: {} });
export { RenderType_AudioPlayerComponent as RenderType_AudioPlayerComponent };
export function View_AudioPlayerComponent_0(_l) { return i0.ɵvid(2, [], null, null); }
export function View_AudioPlayerComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-audio-player", [], null, null, null, View_AudioPlayerComponent_0, RenderType_AudioPlayerComponent)), i0.ɵdid(1, 770048, null, 0, i1.AudioPlayerComponent, [i0.ElementRef, i0.Renderer2], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AudioPlayerComponentNgFactory = i0.ɵccf("app-audio-player", i1.AudioPlayerComponent, View_AudioPlayerComponent_Host_0, { src: "src" }, {}, []);
export { AudioPlayerComponentNgFactory as AudioPlayerComponentNgFactory };

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FormControl, FormGroup } from '@angular/forms';
import { map } from 'rxjs/operators';
import { controlValue, isSet, objectsListSort } from '@shared';
import { fieldTypesOrder } from '../data/field-type';
import { FieldType } from '../data/field-type';
import { fieldDescriptions, getFieldDescriptionByType } from '../data/fields';
import { ParameterField } from '../data/parameter-field';
import { ParameterNameControl } from './parameter-name.control';
var ParameterControl = /** @class */ (function (_super) {
    __extends(ParameterControl, _super);
    function ParameterControl() {
        var _this = _super.call(this, {
            name: new ParameterNameControl(''),
            verboseName: new FormControl(''),
            description: new FormControl(''),
            field: new FormControl(FieldType.Text),
            required: new FormControl(true),
            editable: new FormControl(true),
            defaultType: new FormControl(''),
            defaultValue: new FormControl(''),
            placeholder: new FormControl(''),
            validatorType: new FormControl(null),
            validatorParams: new FormControl(null),
            params: new FormControl({}),
            protected: new FormControl(false)
        }) || this;
        _this.fieldOptions = fieldDescriptions
            .filter(function (item) { return item.public; })
            .map(function (item) {
            return {
                value: item.name,
                name: item.label,
                icon: item.icon
            };
        })
            .sort(objectsListSort(fieldTypesOrder, function (item) { return item.value; }));
        return _this;
    }
    ParameterControl.prototype.deserialize = function (item) {
        this.instance = item;
        this.patchValue({
            name: item.name,
            verboseName: item.verboseName,
            description: item.description,
            field: item.field,
            required: item.required,
            // editable: item.editable,
            defaultType: item.defaultType,
            defaultValue: item.defaultValue,
            placeholder: item.placeholder,
            params: item.params,
            protected: item.protected
        });
        this.markAsPristine();
    };
    ParameterControl.prototype.serialize = function () {
        var value = this.value;
        var result = new ParameterField();
        if (this.instance) {
            result.deserialize(this.instance.serialize());
        }
        result.name = value.name;
        result.verboseName = value.verboseName;
        result.description = value.description;
        result.field = value.field;
        result.required = value.required;
        // result.editable = value.editable;
        result.defaultType = value.defaultType;
        result.defaultValue = value.defaultValue;
        result.placeholder = value.placeholder;
        result.params = value.params;
        result.protected = value.protected;
        result.updateFieldDescription();
        return result;
    };
    ParameterControl.prototype.hasName = function () {
        return isSet(this.controls.name.value);
    };
    ParameterControl.prototype.fieldDescription$ = function () {
        return controlValue(this.controls.field).pipe(map(function (value) { return getFieldDescriptionByType(value); }));
    };
    ParameterControl.prototype.fieldIcon$ = function () {
        return this.fieldDescription$().pipe(map(function (value) { return value.icon; }));
    };
    return ParameterControl;
}(FormGroup));
export { ParameterControl };

<input
  class="view-editor-header-segmented-button-file"
  type="file"
  [id]="'tool_file' | appUniqueId: idToken"
  (change)="onToolFileChange($event.target)"
/>

<ng-template
  *ngIf="tool.children"
  cdkConnectedOverlay
  [cdkConnectedOverlayPanelClass]="['overlay', 'overlay_horizontal_center']"
  [cdkConnectedOverlayPositions]="dropdownPositions"
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayOpen]="opened"
  [cdkConnectedOverlayPush]="true"
  [cdkConnectedOverlayGrowAfterOpen]="true"
  [cdkConnectedOverlayHasBackdrop]="true"
  [cdkConnectedOverlayBackdropClass]="'popover2-backdrop'"
  (backdropClick)="closeTool.emit()"
  (detach)="closeTool.emit()"
>
  <div class="compact-select-options compact-select-options_max-height_l">
    <ng-container *ngFor="let childTool of tool.children">
      <label
        class="compact-select-options__item compact-select-option"
        [class.compact-select-option_active]="(editorContext.tool$ | async) == childTool.tool"
        [for]="childTool.toolFile ? 'tool_file' : null"
        (click)="onToolClick(childTool); closeTool.emit()"
      >
        <span class="compact-select-option__left">
          <span
            class="compact-select-option__checked compact-select-option__item"
            [class.icon-check_2]="(editorContext.tool$ | async) == childTool.tool"
          ></span>

          <span
            *ngIf="childTool.icon | appIsSet"
            class="compact-select-option__icon compact-select-option__item"
            [ngClass]="'icon-' + childTool.icon"
          ></span>
        </span>

        <span class="compact-select-option__main">
          <span class="compact-select-option__row">
            <span class="compact-select-option__title">{{ childTool.label }}</span>
          </span>
        </span>

        <span *ngIf="childTool.toolHotkey | appIsSet" class="compact-select-option__right">
          <span class="compact-select-option__hotkey">
            <span class="compact-select-option__hotkey-symbol">{{ getKeyChar(childTool.toolHotkey) }}</span>
          </span>
        </span>
      </label>
    </ng-container>
  </div>
</ng-template>

<ng-template
  *ngIf="tool.toolElement"
  cdkConnectedOverlay
  [cdkConnectedOverlayPanelClass]="['overlay', 'overlay_horizontal_center']"
  [cdkConnectedOverlayPositions]="dropdownPositions"
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayOpen]="opened"
  [cdkConnectedOverlayPush]="true"
  [cdkConnectedOverlayGrowAfterOpen]="true"
  [cdkConnectedOverlayHasBackdrop]="true"
  [cdkConnectedOverlayBackdropClass]="'popover2-backdrop'"
  (backdropClick)="closeTool.emit()"
  (detach)="closeTool.emit()"
>
  <div class="compact-select-options compact-select-options_min-width_m compact-select-options_max-height_l">
    <ng-container *ngFor="let toolElement of toolElements">
      <div
        class="compact-select-options__item compact-select-option compact-select-option_contrast"
        [class.compact-select-option_active]="false"
        (click)="onToolClick(tool, { element: toolElement }); closeTool.emit()"
      >
        <span class="compact-select-option__left">
          <span
            class="compact-select-option__image"
            [style.background-image]="'/assets/images/widgets/' + toolElement.image + '_dark.svg' | appDeployCssUrl"
          ></span>
        </span>

        <span class="compact-select-option__main">
          <span class="compact-select-option__row">
            <span class="compact-select-option__title">{{ toolElement.title }}</span>
          </span>

          <span *ngIf="toolElement.subtitle | appIsSet" class="compact-select-option__row">
            <span class="compact-select-option__description">{{ toolElement.subtitle }}</span>
          </span>
        </span>
      </div>
    </ng-container>
  </div>
</ng-template>

<label
  class="view-editor-header-segmented-button"
  [class.view-editor-header-segmented-button_first]="first"
  [class.view-editor-header-segmented-button_last]="last"
  [class.view-editor-header-segmented-button_active]="toolActive || childToolActive"
  [class.view-editor-header-segmented-button_hover]="opened || toolFileUploading === tool"
  [class.view-editor-header-segmented-button_disabled]="tool.toolElement && elementTemplatesLoading"
  [for]="tool.toolFile ? ('tool_file' | appUniqueId: idToken) : null"
  (click)="onRootToolClick(tool)"
  [appTip]="tool_tip"
  [appTipOptions]="{ side: 'bottom' }"
  cdkOverlayOrigin
  #trigger="cdkOverlayOrigin"
>
  <span *ngIf="tool.toolElement && elementTemplatesLoading" class="view-editor-header-segmented-button__icon">
    <span [class.loading-animation]="true"><span class="stub-text">TL</span></span>
  </span>

  <ng-container *ngIf="!(tool.toolElement && elementTemplatesLoading)">
    <span
      class="view-editor-header-segmented-button__icon"
      [ngClass]="defaultChildTool ? 'icon-' + defaultChildTool.icon : 'icon-' + tool.icon"
    ></span>

    <span
      *ngIf="tool.children || tool.toolElement"
      class="view-editor-header-segmented-button__arrow icon-arrow_down_2"
    ></span>
  </ng-container>
</label>

<ng-template #tool_tip>
  {{ tool.label }} <app-tip-hotkey *ngIf="tool.toolHotkey" [key]="getKeyChar(tool.toolHotkey)"></app-tip-hotkey>
</ng-template>

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ActionItem } from '@modules/actions';
export var LayerInteractionType;
(function (LayerInteractionType) {
    LayerInteractionType["HoverOutput"] = "hover_output";
    LayerInteractionType["PressedOutput"] = "pressed_output";
    LayerInteractionType["Click"] = "click";
    LayerInteractionType["DisableTextSelection"] = "disable_text_selection";
    LayerInteractionType["Cursor"] = "cursor";
})(LayerInteractionType || (LayerInteractionType = {}));
var LayerInteraction = /** @class */ (function () {
    function LayerInteraction(options) {
        if (options === void 0) { options = {}; }
        Object.assign(this, options);
    }
    LayerInteraction.prototype.deserialize = function (data) {
        this.type = data['type'];
        return this;
    };
    LayerInteraction.prototype.serialize = function () {
        return {
            type: this.type
        };
    };
    return LayerInteraction;
}());
export { LayerInteraction };
var ActionLayerInteraction = /** @class */ (function (_super) {
    __extends(ActionLayerInteraction, _super);
    function ActionLayerInteraction() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ActionLayerInteraction.prototype.deserialize = function (data) {
        _super.prototype.deserialize.call(this, data);
        if (data['action']) {
            this.action = new ActionItem().deserialize(data['action']);
        }
        return this;
    };
    ActionLayerInteraction.prototype.serialize = function () {
        return __assign({}, _super.prototype.serialize.call(this), { action: this.action ? this.action.serialize() : undefined });
    };
    return ActionLayerInteraction;
}(LayerInteraction));
export { ActionLayerInteraction };
var CursorLayerInteraction = /** @class */ (function (_super) {
    __extends(CursorLayerInteraction, _super);
    function CursorLayerInteraction() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    CursorLayerInteraction.prototype.deserialize = function (data) {
        _super.prototype.deserialize.call(this, data);
        this.cursor = data['cursor'];
        return this;
    };
    CursorLayerInteraction.prototype.serialize = function () {
        return __assign({}, _super.prototype.serialize.call(this), { cursor: this.cursor });
    };
    return CursorLayerInteraction;
}(LayerInteraction));
export { CursorLayerInteraction };
export function createLayerInteraction(type) {
    if ([LayerInteractionType.Click].includes(type)) {
        return new ActionLayerInteraction({ type: type });
    }
    else if ([LayerInteractionType.Cursor].includes(type)) {
        return new CursorLayerInteraction({ type: type });
    }
    else {
        return new LayerInteraction({ type: type });
    }
}
export function deserializeLayerInteraction(data) {
    var result = createLayerInteraction(data['type']);
    return result.deserialize(data);
}

<app-customize-workflow-step-header
  [step]="step"
  [nameControl]="workflowEditable ? form.controls.name : undefined"
  [nameValue]="form.controls.name.value"
  [deleteEnabled]="workflowEditable"
  (close)="closeCustomize.emit()"
  (delete)="stepDelete.emit()"
></app-customize-workflow-step-header>

<div class="sidebar__content">
  <app-sidebar-section [disabled]="!workflowEditable">
    <div class="sidebar__element">
      <app-sidebar-field [label]="'delay'" [additional]="'in seconds'">
        <app-auto-field
          [form]="form"
          [label]="false"
          [autofocus]="true"
          [field]="
            createField({
              name: 'delay',
              field: 'IntegerField',
              required: true,
              placeholder: '1 - 300 seconds',
              params: { classes: ['input_fill'] }
            })
          "
        ></app-auto-field>

        <ng-container description>
          Wait for a specified period of time before executing the next step
        </ng-container>
      </app-sidebar-field>
    </div>
  </app-sidebar-section>
</div>

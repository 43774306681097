import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { Power2, TimelineMax } from 'gsap';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Subscription, timer } from 'rxjs';

import { Notification } from '../../data/notification';
import { NotificationService } from '../../services/notification/notification.service';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() notification: Notification;

  @ViewChild('root') root: ElementRef;

  tl = new TimelineMax();
  closeTimer: Subscription;
  hover = false;
  timerPassed = false;
  closed = false;
  showDuration = 0.5;
  closeDuration = 0.5;
  closeTimeout: number | null;

  constructor(private notificationService: NotificationService) {}

  ngOnInit() {
    if (this.notification.closeTimeout === null) {
      this.closeTimeout = null;
    } else if (typeof this.notification.closeTimeout == 'number') {
      this.closeTimeout = this.notification.closeTimeout;
    } else {
      this.closeTimeout = 3;
    }
  }

  ngOnDestroy(): void {}

  ngAfterViewInit(): void {
    this.show();
  }

  show() {
    this.tl
      .fromTo(
        this.root.nativeElement,
        this.showDuration,
        {
          x: 100,
          opacity: 0
        },
        {
          x: 0,
          opacity: 1,
          ease: Power2.easeOut
        }
      )
      .add(() => {
        if (this.closeTimeout !== null) {
          this.closeTimer = timer(this.closeTimeout * 1000)
            .pipe(untilDestroyed(this))
            .subscribe(() => {
              if (!this.hover) {
                this.close();
              }
              this.timerPassed = true;
            });
        }
      });
  }

  close() {
    if (this.closed) {
      return;
    }

    this.closed = true;

    if (this.closeTimer) {
      this.closeTimer.unsubscribe();
      this.closeTimer = undefined;
    }

    this.tl
      .clear()
      .fromTo(
        this.root.nativeElement,
        this.closeDuration,
        {
          x: 0,
          opacity: 1
        },
        {
          xPercent: 100,
          opacity: 0,
          ease: Power2.easeOut
        }
      )
      .add(() => this.notificationService.remove(this.notification));
  }

  onMouseEnter() {
    this.hover = true;
  }

  onMouseLeave() {
    this.hover = false;
    if (this.timerPassed) {
      this.close();
    }
  }
}

import { Injector, StaticProvider, Type } from '@angular/core';
import 'reflect-metadata';
import * as i0 from "@angular/core";
var Factory = /** @class */ (function () {
    function Factory(injector) {
        this.injector = injector;
    }
    Factory.prototype.createReflected = function (cls) {
        var argTypes = Reflect.getMetadata('design:paramtypes', cls) || [];
        var childInjector = Injector.create({
            providers: [{ provide: cls, deps: argTypes }],
            parent: this.injector
        });
        return childInjector.get(cls);
    };
    Factory.prototype.create = function (cls, options) {
        if (options === void 0) { options = {}; }
        var childInjector = Injector.create({
            providers: [{ provide: cls, deps: options.dependencies }].concat((options.providers || [])),
            parent: options.injector || this.injector
        });
        return childInjector.get(cls);
    };
    Factory.ngInjectableDef = i0.defineInjectable({ factory: function Factory_Factory() { return new Factory(i0.inject(i0.INJECTOR)); }, token: Factory, providedIn: "root" });
    return Factory;
}());
export { Factory };

import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { debounceTime, map } from 'rxjs/operators';

import { createFormFieldFactory } from '@modules/fields';
import { DelayWorkflowStep, WorkflowStepType } from '@modules/workflow';

import { registerCustomizeWorkflowStepComponent } from '../../../../data/customize-workflow-step-components';
import { CustomizeWorkflowStepComponent } from '../base-customize-workflow-step/base-customize-workflow-step.component';
import { DelayCustomizeWorkflowStepForm } from './delay-customize-workflow-step.form';

@Component({
  selector: 'app-delay-customize-workflow-step',
  templateUrl: './delay-customize-workflow-step.component.html',
  providers: [DelayCustomizeWorkflowStepForm],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DelayCustomizeWorkflowStepComponent extends CustomizeWorkflowStepComponent<DelayWorkflowStep>
  implements OnInit, OnDestroy {
  createField = createFormFieldFactory();

  constructor(public form: DelayCustomizeWorkflowStepForm) {
    super();
  }

  ngOnInit() {
    this.form.init(this.step, { firstInit: false });

    if (this.workflowEditable) {
      this.form.valueChanges
        .pipe(
          debounceTime(200),
          map(() => this.form.submit()),
          untilDestroyed(this)
        )
        .subscribe(result => {
          this.submit(result);
        });
    }
  }

  ngOnDestroy(): void {}

  submit(value?: DelayWorkflowStep) {
    if (!value) {
      value = this.form.submit();
    }

    this.stepChange.emit(value);
  }
}

registerCustomizeWorkflowStepComponent(WorkflowStepType.Delay, DelayCustomizeWorkflowStepComponent);

<app-customize-workflow-step-header
  [step]="step"
  [nameControl]="workflowEditable ? form.controls.name : undefined"
  [nameValue]="form.controls.name.value"
  [deleteEnabled]="workflowEditable"
  [executeEnabled]="workflowEditable"
  [executeLoading]="status == statuses.Executing"
  (close)="closeCustomize.emit()"
  (delete)="stepDelete.emit()"
  (execute)="execute()"
></app-customize-workflow-step-header>

<div class="sidebar__content">
  <app-sidebar-tabs [initialTabIndex]="workflowEditable ? 0 : 1">
    <app-sidebar-tab [label]="'Settings'" [contentDisabled]="!workflowEditable">
      <app-customize-bar-action-edit-type
        [options]="{
          actionItemClass: submitActionClass,
          modelDescriptionInContext: undefined,
          parametersEditable: false,
          typesOnly: [actionTypes.Query, actionTypes.Download]
        }"
        [form]="form.controls.action"
        [label]="'Submit action'"
        [typeFieldEnabled]="false"
        [typeFieldLabel]="'What step should do'"
        [typeFieldRequired]="true"
        [context]="context"
        [contextElement]="contextElement"
        [contextElementPaths]="undefined"
        [firstInit]="false"
        [setupOnCreate]="false"
        [showDraftChanges]="true"
        [analyticsSource]="undefined"
      ></app-customize-bar-action-edit-type>

      <div *ngIf="getOperation$ | async" class="sidebar__element">
        <app-alert [warning]="true" [title]="'Only first 20 records'" [icon]="'warning_filled'" [compact]="true">
          Getting data with Get Record List operation is limited to maximum 20 records. Please use Iterator step to load
          more data.
        </app-alert>
      </div>

      <div
        *ngIf="form.resultStepsActionTypes.includes(form.controls.action.controls.type.value)"
        class="sidebar__element"
      >
        <app-sidebar-field>
          <div class="sidebar-icon-buttons sidebar-icon-buttons_border">
            <div
              class="sidebar-icon-button"
              [class.sidebar-icon-button_active]="!form.controls.result_steps.value"
              (click)="form.controls.result_steps.setValue(false)"
            >
              <div class="sidebar-icon-button__label">Exit on error</div>
            </div>
            <div
              class="sidebar-icon-button"
              [class.sidebar-icon-button_active]="form.controls.result_steps.value"
              (click)="form.controls.result_steps.setValue(true)"
            >
              <div class="sidebar-icon-button__label">Specify error steps</div>
            </div>
          </div>

          <ng-container description>
            Choose if workflow should stop on error or run success and error steps
          </ng-container>
        </app-sidebar-field>
      </div>

      <app-sidebar-section *ngIf="operationTypeValid$ | async">
        <app-customize-bar-action-edit-parameters
          [form]="form.controls.action"
          [context]="context"
          [contextElement]="contextElement"
          [contextElementPath]="undefined"
          [contextElementPaths]="undefined"
          [analyticsSource]="undefined"
          (addQueryInput)="addQueryInput()"
        ></app-customize-bar-action-edit-parameters>
      </app-sidebar-section>
    </app-sidebar-tab>

    <app-sidebar-tab
      [label]="workflowEditable ? 'Test result' : 'Run result'"
      [error]="stepRun?.error ? (workflowEditable ? 'Test failed' : 'Run failed') : undefined"
    >
      <div *ngIf="status == statuses.Executing" class="sidebar__element">
        <app-alert [title]="'Step is executing'" [secondary]="true">
          Please wait until execution is finished
        </app-alert>
      </div>

      <ng-container *ngIf="status != statuses.Executing">
        <div *ngIf="stepRun?.params | appIsSet" class="sidebar__element">
          <app-sidebar-field [label]="'inputs used'">
            <app-json-viewer [content]="stepRun.params"></app-json-viewer>
          </app-sidebar-field>
        </div>

        <div *ngIf="stepRun?.result | appIsSet" class="sidebar__element">
          <app-sidebar-field [label]="'step result'">
            <app-json-viewer [content]="stepRun.result"></app-json-viewer>
          </app-sidebar-field>
        </div>

        <ng-container *ngIf="stepRun?.error | appIsSet">
          <div class="sidebar__element">
            <app-alert [title]="'Step failed with the following error'" [danger]="true" [border]="true">
              Please check if the step and all inputs are configured correctly
            </app-alert>
          </div>

          <div class="sidebar__element">
            <app-json-viewer [content]="stepRun.error"></app-json-viewer>
          </div>
        </ng-container>

        <div *ngIf="!(stepRun?.result | appIsSet) && !(stepRun?.error | appIsSet)" class="sidebar__element">
          <app-alert [title]="'Click Test Step to get result'" [secondary]="true">
            <div>You can test step to check whether your Workflow is working correctly</div>
            <div style="margin-top: 4px;">
              <a href="javascript:void(0)" (click)="execute()">Test Step</a>
            </div>
          </app-alert>
        </div>
      </ng-container>
    </app-sidebar-tab>
  </app-sidebar-tabs>
</div>

import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, Optional, Output } from '@angular/core';

import { CustomizeBarContext } from '../../services/customize-bar-context/customize-bar.context';

@Component({
  selector: 'app-customize-bar-header',
  templateUrl: './customize-bar-header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomizeBarHeaderComponent implements OnDestroy {
  @Input() title: string;
  @Input() titleEnabled = true;
  @Input() titleEditable = false;
  @Input() titleCleanValue?: (value: string) => string;
  @Input() backEnabled = true;
  @Input() backLabel = 'Back';
  @Input() backPopSettingsComponent = true;
  @Input() subtitle: string;
  @Input() deleteEnabled = false;
  @Input() deleteTip: string;
  @Input() parentLabel: string;
  @Output() titleChanged = new EventEmitter<string>();
  @Output() deleteClick = new EventEmitter<string>();
  @Output() parentClick = new EventEmitter<MouseEvent>();
  @Output() backClick = new EventEmitter<void>();

  constructor(@Optional() private customizeBarContext: CustomizeBarContext) {}

  ngOnDestroy(): void {}

  back() {
    if (this.backPopSettingsComponent) {
      this.customizeBarContext.popSettingsComponent();
    }

    this.backClick.emit();
  }
}

<form [formGroup]="form">
  <div class="query-builder">
    <div class="query-builder__main">
      <app-query-builder-http-request-parameters
        class="query-builder__main-top"
        [resource]="resource"
        [context]="context"
        [form]="form"
        [defaultTab]="currentOptions.defaultTab"
        [enabledBodyTypes]="currentOptions.enabledBodyTypes"
        (execute)="execute()"
        (inputTokensEvent)="onInputTokensEvent($event, queryBuilderSections.QueryParameters)"
      >
      </app-query-builder-http-request-parameters>
      <div class="query-builder__main-bottom">
        <div class="query-builder__toolbar query-builder__toolbar_sticky">
          <div class="query-builder__toolbar-main">
            <div class="query-builder__radio query-builder__radio_orange">
              <a
                *ngIf="parametersControl"
                href="javascript:void(0)"
                class="query-builder__radio-item"
                [class.query-builder__radio-item_active]="resultsSection == resultsSections.Parameters"
                (click)="setResultsSection(resultsSections.Parameters)"
              >
                Inputs
              </a>
            </div>
          </div>
          <div class="query-builder__toolbar-right">
            <div class="query-builder__radio query-builder__radio_orange">
              <a
                href="javascript:void(0)"
                class="query-builder__radio-item"
                [class.query-builder__radio-item_active]="resultsSection == resultsSections.Http"
                (click)="setResultsSection(resultsSections.Http)"
              >
                Request
              </a>
              <a
                href="javascript:void(0)"
                class="query-builder__radio-item"
                [class.query-builder__radio-item_active]="resultsSection == resultsSections.Result"
                (click)="setResultsSection(resultsSections.Result)"
              >
                Result
              </a>
            </div>
            <div class="query-builder__radio query-builder__radio_orange">
              <a
                href="javascript:void(0)"
                class="query-builder__radio-item query-builder__radio-item_icon query-builder__radio-item_primary"
                [class.query-builder__radio-item_active]="resultsSection == resultsSections.Result"
                [appTip]="'Send Request'"
                [appTipOptions]="{ side: 'top' }"
                (click)="setResultsSection(resultsSections.Result); execute()"
              >
                <app-loader-small *ngIf="loading" class="query-builder__radio-item-icon"></app-loader-small>
                <span *ngIf="!loading" class="icon-play_filled query-builder__radio-item-icon"></span>
              </a>
            </div>
          </div>
        </div>
        <div *ngIf="loading" class="query-builder__table">
          <app-query-builder-data-response [animating]="true"></app-query-builder-data-response>
        </div>
        <ng-container *ngIf="!loading">
          <div *ngIf="resultsSection == resultsSections.Parameters && parametersControl">
            <app-query-builder-parameters-edit
              [control]="parametersControl"
              [queryContext]="context"
              [object]="'query_builder'"
            >
            </app-query-builder-parameters-edit>
          </div>

          <app-query-builder-http-response
            *ngIf="resultsSection == resultsSections.Http"
            class="query-builder__request"
            [requestParameters]="requestParameters"
            [response]="response"
            [responseActual]="responseActual"
            [responseBody]="responseBody"
          >
          </app-query-builder-http-response>

          <ng-container *ngIf="resultsSection == resultsSections.Result">
            <div class="query-builder__toolbar">
              <div class="query-builder__toolbar-main">
                <strong *ngIf="responseBodyProcessed && !response_component.error">
                  <ng-container *ngIf="response_component.columns !== undefined">
                    {{ response_component.rows?.length || 0 }} rows,
                    {{ response_component.columns?.length || 0 }} columns
                  </ng-container>
                  <ng-container
                    *ngIf="response_component.columns === undefined && response_component.rows !== undefined"
                  >
                    {{ response_component.rows?.length || 0 }} items
                  </ng-container>
                </strong>
                <!--                  Last updated 21.5.2020, 2:33 PM-->
              </div>
              <div class="query-builder__toolbar-right">
                <app-auto-field
                  [form]="form"
                  [label]="false"
                  [field]="
                    createField({
                      name: 'response_type',
                      field: 'SelectField',
                      params: { options: form.responseTypeOptions, classes: ['select_fill'] }
                    })
                  "
                ></app-auto-field>

                <ng-container
                  *ngIf="[httpResponseTypes.JSON, httpResponseTypes.XML].includes(form.controls.response_type.value)"
                >
                  <app-query-builder-response-transform
                    [paths]="[{ name: 'response_path', label: 'Top Level', chooseLabel: 'Top Level' }]"
                    [response]="responseBody"
                    [pathValues]="{ response_path: form.controls.response_path.value }"
                    [pathEnabled]="form.controls.response_transformer_enabled.value ? undefined : 'response_path'"
                    (selectedPath)="onResponseTransformPathSelected($event); response_transform.close()"
                    (selectedJS)="onResponseTransformJsSelected(); response_transform.close()"
                    #response_transform
                  >
                  </app-query-builder-response-transform>
                </ng-container>
              </div>
            </div>

            <div class="query-builder__table">
              <div *ngIf="form.controls.response_transformer_enabled.value" style="margin-top: 10px;">
                <app-auto-field
                  [form]="form"
                  [label]="false"
                  [field]="
                    createField({
                      name: 'response_transformer',
                      field: 'CodeField',
                      params: { autogrow: true, field: true, field_contrast: true, classes: ['input_fill'] }
                    })
                  "
                  [appTokenInput]="{
                    context: context,
                    control: form.controls.response_transformer,
                    quote: { open: '', close: '' }
                  }"
                  (appTokenEvent)="onInputTokensEvent($event, queryBuilderSections.Result)"
                ></app-auto-field>
              </div>
              <app-query-builder-data-response
                [response]="response"
                [responseBody]="responseBodyProcessed"
                [responseType]="query?.responseType"
                #response_component
              >
              </app-query-builder-data-response>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </div>

    <div [formGroup]="form" class="query-builder__right query-builder-side">
      <ng-container *ngIf="sideBarSection">
        <div class="query-builder-side__header">
          <div class="query-builder-side__header-main">
            <div class="query-builder__title">
              <ng-container *ngIf="sideBarSection == sideBarSections.Sorting">Sorting</ng-container>
              <ng-container *ngIf="sideBarSection == sideBarSections.Pagination">Pagination</ng-container>
              <ng-container *ngIf="sideBarSection == sideBarSections.ErrorTransformer">Error displaying</ng-container>
            </div>
          </div>
          <div class="query-builder-side__header-right">
            <ng-container *ngIf="sideBarSection == sideBarSections.Sorting">
              <app-toggle
                [orange]="true"
                [selected]="queryForm.controls.sorting.value"
                (click)="queryForm.controls.sorting.patchValue(false); setSideBarSection(undefined)"
              >
              </app-toggle>
            </ng-container>

            <ng-container *ngIf="sideBarSection == sideBarSections.Pagination && queryForm.controls.pagination.value">
              <app-toggle
                [orange]="true"
                [selected]="queryForm.controls.pagination.value"
                (click)="queryForm.setPagination(undefined); setSideBarSection(undefined)"
              >
              </app-toggle>
            </ng-container>

            <ng-container *ngIf="sideBarSection == sideBarSections.ErrorTransformer">
              <app-toggle
                [orange]="true"
                [selected]="form.controls.error_transformer_visible.value"
                (click)="form.controls.error_transformer_visible.patchValue(false); setSideBarSection(undefined)"
              >
              </app-toggle>
            </ng-container>
          </div>
        </div>

        <div class="query-builder-side__content">
          <a href="javascript:void(0)" class="query-builder__button-link" (click)="setSideBarSection(undefined)">
            <span class="icon-arrow_backward_2 query-builder__button-link-icon"></span> Back
          </a>

          <ng-container *ngIf="sideBarSection == sideBarSections.Sorting">
            <app-page-block [paddingSize]="'s'">
              <div class="query-builder__heading">Specify sortable fields</div>
              <p class="query-builder__text">
                Specify what fields should be sortable and use as value for
                <strong class="ref-argument">Sorting field</strong> (sorting.field) parameter.
              </p>
            </app-page-block>
            <ng-container *ngIf="!queryForm.controls.sorting_fields.controls?.length">
              <app-page-block [paddingSize]="'s'">
                <p class="query-builder__label query-builder__label_center">
                  No fields found, send your request first
                </p>
              </app-page-block>
              <app-page-block [paddingSize]="'s'">
                <p class="query-builder__label query-builder__label_center">
                  <button
                    type="button"
                    class="button button_orange"
                    (click)="setResultsSection(resultsSections.Result); execute()"
                  >
                    <span class="button__icon button__icon_left icon-play_filled"></span>
                    <span class="button__label">Send Request</span>
                  </button>
                </p>
              </app-page-block>
            </ng-container>

            <app-page-block
              *ngFor="let itemForm of queryForm.controls.sorting_fields.controls"
              [paddingSize]="'s'"
              [marginSize]="'s'"
            >
              <div class="sidebar-list-item sidebar-list-item_background">
                <div class="sidebar-list-item__main">
                  <div class="sidebar-list-item__title">
                    {{ itemForm.value['name'] }}
                  </div>
                </div>
                <div class="sidebar-list-item__right">
                  <app-auto-field
                    [form]="itemForm"
                    [label]="false"
                    [field]="
                      createField({
                        name: 'sortable',
                        field: 'BooleanField',
                        params: { style: booleanFieldStyle.Toggle, orange: true }
                      })
                    "
                  ></app-auto-field>
                </div>
              </div>
            </app-page-block>
          </ng-container>

          <ng-container *ngIf="sideBarSection == sideBarSections.Pagination">
            <app-page-block [paddingSize]="'s'">
              <div class="field">
                <div class="field__label">Choose pagination</div>

                <ng-container *ngIf="!queryForm.controls.pagination.value">
                  <div *ngFor="let item of queryForm.paginationOptions">
                    <button
                      type="button"
                      class="button button_orange button_fill"
                      (click)="queryForm.setPagination(item.value)"
                    >
                      <span class="button__icon button__icon_left" [ngClass]="['icon-' + item.icon]"></span>
                      <span class="button__label">{{ item.name }}</span>
                      <span
                        class="button__icon button__icon_fixed button__icon_right"
                        [class.icon-check_2]="item.value == queryForm.controls.pagination.value"
                      ></span>
                    </button>
                  </div>
                </ng-container>

                <ng-container *ngIf="queryForm.controls.pagination.value" [formGroup]="queryForm">
                  <ng-template let-image="image" let-name="name" let-data="data" #input_template>
                    <span *ngIf="image" class="select__input-icon" [ngClass]="['icon-' + image]"></span>
                    <span class="select__input-label">{{ name }}</span>
                    <span class="select__input-arrow"></span>
                  </ng-template>

                  <ng-template let-image="image" let-name="name" let-data="data" #options_template>
                    <span *ngIf="image" class="options__item-icon" [ngClass]="['icon-' + image]"></span>
                    <span class="options__item-label">{{ name }}</span>
                  </ng-template>

                  <gxs-select
                    [placeholder]="'choose' | localize | appCapitalize"
                    [formControlName]="'pagination'"
                    [inputTemplate]="input_template"
                    [optionTemplate]="options_template"
                    [options]="{
                      theme: 'jet',
                      search: false,
                      classes: ['select_fill'],
                      labels: {} | localizeSelect
                    }"
                  >
                    <gxs-option
                      *ngFor="let option of queryForm.optionalPaginationOptions"
                      [name]="option.name"
                      [value]="option.value"
                      [image]="option.icon"
                    >
                    </gxs-option>
                  </gxs-select>
                </ng-container>
              </div>
            </app-page-block>

            <ng-container *ngIf="queryForm.controls.pagination.value == queryForm.queryPaginations.Page">
              <app-page-block [paddingSize]="'s'">
                <div class="query-builder__separator"></div>
              </app-page-block>

              <app-page-block [paddingSize]="'s'">
                <div class="query-builder__heading">Implement Page pagination inputs</div>
              </app-page-block>

              <app-page-block [paddingSize]="'s'">
                <div class="parameter-info">
                  <div class="parameter-info__header">
                    <span class="parameter-info__required">*</span> Page number
                    <span class="parameter-info__quote">number</span>
                  </div>
                  <div class="parameter-info__content">
                    <div class="parameter-info__text">
                      Contains List's current Page number - [1..N]<br />
                      Specify <strong class="ref-argument">Page number</strong> in Query Params
                    </div>
                  </div>
                </div>
                <div class="query-builder__separator"></div>
                <div class="parameter-info">
                  <div class="parameter-info__header">
                    Per page limit <span class="parameter-info__optional">(Optional)</span>
                    <span class="parameter-info__quote">number</span>
                  </div>
                  <div class="parameter-info__content">
                    <div class="parameter-info__text">
                      Contains a number of items per Page.<br />
                      Specify <strong class="ref-argument">Per page limit</strong> in Query Params
                    </div>

                    <div class="parameter-info__input parameter-info__input_padding">
                      <app-auto-field
                        [form]="queryForm"
                        [field]="
                          createField({
                            name: 'pagination_per_page_max',
                            label: 'Maximum per page limit',
                            field: 'IntegerField',
                            placeholder: 'Default is 1000',
                            params: { classes: ['input_fill'] }
                          })
                        "
                      ></app-auto-field>
                    </div>

                    <div class="parameter-info__input parameter-info__input_padding">
                      <app-auto-field
                        [form]="queryForm"
                        [field]="
                          createField({
                            name: 'pagination_per_page_min',
                            label: 'Minimum per page limit',
                            field: 'IntegerField',
                            placeholder: 'Default is 1',
                            params: { classes: ['input_fill'] }
                          })
                        "
                      ></app-auto-field>
                    </div>
                  </div>
                </div>
                <div class="query-builder__separator"></div>
                <div class="parameter-info">
                  <div class="parameter-info__header">
                    <span class="parameter-info__required">*</span> Has more function
                    <span class="parameter-info__quote">boolean</span>
                  </div>
                  <div class="parameter-info__content">
                    <div class="parameter-info__text">
                      Return <strong class="ref-argument">true</strong> if there're any pages left after the request.<br />
                      Return <strong class="ref-argument">false</strong> otherwise
                    </div>
                    <app-query-builder-response-transform
                      [response]="responseBody"
                      [paths]="[
                        {
                          name: 'pagination_has_more_pages_path',
                          label: 'Pages',
                          chooseLabel: 'Choose Pages'
                        },
                        {
                          name: 'pagination_has_more_total_pages_path',
                          label: 'Total Pages',
                          chooseLabel: 'Choose Total Pages'
                        },
                        {
                          name: 'pagination_has_more_total_records_path',
                          label: 'Total Records',
                          chooseLabel: 'Choose Total Records'
                        }
                      ]"
                      [pathValues]="{
                        pagination_has_more_pages_path: queryForm.controls.pagination_has_more_pages_path.value,
                        pagination_has_more_total_pages_path:
                          queryForm.controls.pagination_has_more_total_pages_path.value,
                        pagination_has_more_total_records_path:
                          queryForm.controls.pagination_has_more_total_records_path.value
                      }"
                      [pathEnabled]="queryForm.controls.pagination_has_more_path.value"
                      [emptyLabel]="'Choose Has More Records'"
                      (selectedPath)="onHasMorePathSelected($event); cursor_has_more_transform.close()"
                      (selectedJS)="onHasMorePathJsSelected(); cursor_has_more_transform.close()"
                      #cursor_has_more_transform
                    ></app-query-builder-response-transform>
                    <div class="parameter-info__input" *ngIf="queryForm.controls.pagination_has_more_path.value">
                      <app-auto-field
                        [form]="queryForm"
                        [label]="false"
                        [field]="
                          createField({
                            name: 'pagination_per_page',
                            field: 'IntegerField',
                            placeholder: 'Records per page',
                            params: { classes: ['input_fill'] }
                          })
                        "
                      ></app-auto-field>
                    </div>
                    <div class="parameter-info__field" *ngIf="!queryForm.controls.pagination_has_more_path.value">
                      <app-auto-field
                        [form]="queryForm"
                        [label]="false"
                        [field]="
                          createField({
                            name: 'pagination_has_more_function',
                            field: 'CodeField',
                            params: { autogrow: true, field: true, wrap: true, classes: ['input_fill'] }
                          })
                        "
                      ></app-auto-field>
                    </div>
                  </div>
                </div>
                <div class="query-builder__separator"></div>
                <div class="parameter-info">
                  <div class="parameter-info__header">
                    Total function <span class="parameter-info__optional">(Optional)</span>
                    <span class="parameter-info__quote">number</span>
                  </div>
                  <div class="parameter-info__content">
                    <div class="parameter-info__text">
                      Implement if you want to see total records number<br />
                      Return <strong class="ref-argument">number</strong> of total records
                    </div>
                    <app-query-builder-response-transform
                      [response]="responseBody"
                      [paths]="[
                        { name: 'pagination_total_path', label: 'Total Records', chooseLabel: 'Choose Total Records' }
                      ]"
                      [pathValues]="{ pagination_total_path: queryForm.controls.pagination_total_path.value }"
                      [pathEnabled]="
                        queryForm.controls.pagination_total_transformer_enabled.value
                          ? undefined
                          : 'pagination_total_path'
                      "
                      [emptyLabel]="'Choose Total Records'"
                      (selectedPath)="onTotalTransformPathSelected($event); total_transform.close()"
                      (selectedJS)="onTotalTransformJsSelected(); total_transform.close()"
                      #total_transform
                    >
                    </app-query-builder-response-transform>
                    <div
                      class="parameter-info__field"
                      *ngIf="queryForm.controls.pagination_total_transformer_enabled.value"
                    >
                      <app-auto-field
                        [form]="queryForm"
                        [label]="false"
                        [field]="
                          createField({
                            name: 'pagination_total_function',
                            field: 'CodeField',
                            params: { autogrow: true, field: true, wrap: true, classes: ['input_fill'] }
                          })
                        "
                      ></app-auto-field>
                    </div>
                  </div>
                </div>
              </app-page-block>
            </ng-container>

            <ng-container *ngIf="queryForm.controls.pagination.value == queryForm.queryPaginations.Offset">
              <app-page-block [paddingSize]="'s'">
                <div class="query-builder__separator"></div>
              </app-page-block>

              <app-page-block [paddingSize]="'s'">
                <div class="query-builder__heading">Implement Offset pagination inputs</div>
              </app-page-block>

              <app-page-block [paddingSize]="'s'">
                <div class="parameter-info">
                  <div class="parameter-info__header">
                    <span class="parameter-info__required">*</span> Pagination offset
                    <span class="parameter-info__quote">number</span>
                  </div>
                  <div class="parameter-info__content">
                    <div class="parameter-info__text">
                      Contains List's current offset - [1..N]<br />
                      Specify <strong class="ref-argument">Page offset</strong> in Query Params
                    </div>
                  </div>
                </div>
                <div class="query-builder__separator"></div>
                <div class="parameter-info">
                  <div class="parameter-info__header">
                    Per page limit <span class="parameter-info__optional">(Optional)</span>
                    <span class="parameter-info__quote">number</span>
                  </div>
                  <div class="parameter-info__content">
                    <div class="parameter-info__text">
                      Contains a number of items per Page.<br />
                      Specify <strong class="ref-argument">Per page limit</strong> in Query Params
                    </div>

                    <div class="parameter-info__input parameter-info__input_padding">
                      <app-auto-field
                        [form]="queryForm"
                        [field]="
                          createField({
                            name: 'pagination_per_page_max',
                            label: 'Maximum per page limit',
                            field: 'IntegerField',
                            placeholder: 'Default is 1000',
                            params: { classes: ['input_fill'] }
                          })
                        "
                      ></app-auto-field>
                    </div>

                    <div class="parameter-info__input parameter-info__input_padding">
                      <app-auto-field
                        [form]="queryForm"
                        [field]="
                          createField({
                            name: 'pagination_per_page_min',
                            label: 'Minimum per page limit',
                            field: 'IntegerField',
                            placeholder: 'Default is 1',
                            params: { classes: ['input_fill'] }
                          })
                        "
                      ></app-auto-field>
                    </div>
                  </div>
                </div>
                <div class="query-builder__separator"></div>
                <div class="parameter-info">
                  <div class="parameter-info__header">
                    <span class="parameter-info__required">*</span> Has more function
                    <span class="parameter-info__quote">boolean</span>
                  </div>
                  <div class="parameter-info__content">
                    <div class="parameter-info__text">
                      Return <strong class="ref-argument">true</strong> if there're any pages left after the request.<br />
                      Return <strong class="ref-argument">false</strong> otherwise
                    </div>
                    <app-query-builder-response-transform
                      [response]="responseBody"
                      [paths]="[
                        {
                          name: 'pagination_has_more_pages_path',
                          label: 'Pages',
                          chooseLabel: 'Choose Pages'
                        },
                        {
                          name: 'pagination_has_more_total_pages_path',
                          label: 'Total Pages',
                          chooseLabel: 'Choose Total Pages'
                        },
                        {
                          name: 'pagination_has_more_total_records_path',
                          label: 'Total Records',
                          chooseLabel: 'Choose Total Records'
                        }
                      ]"
                      [pathValues]="{
                        pagination_has_more_pages_path: queryForm.controls.pagination_has_more_pages_path.value,
                        pagination_has_more_total_pages_path:
                          queryForm.controls.pagination_has_more_total_pages_path.value,
                        pagination_has_more_total_records_path:
                          queryForm.controls.pagination_has_more_total_records_path.value
                      }"
                      [pathEnabled]="queryForm.controls.pagination_has_more_path.value"
                      [emptyLabel]="'Choose Has More Records'"
                      (selectedPath)="onHasMorePathSelected($event); cursor_has_more_transform.close()"
                      (selectedJS)="onHasMorePathJsSelected(); cursor_has_more_transform.close()"
                      #cursor_has_more_transform
                    ></app-query-builder-response-transform>
                    <div class="parameter-info__input" *ngIf="queryForm.controls.pagination_has_more_path.value">
                      <app-auto-field
                        [form]="queryForm"
                        [label]="false"
                        [field]="
                          createField({
                            name: 'pagination_per_page',
                            field: 'IntegerField',
                            placeholder: 'Default records per page',
                            params: { classes: ['input_fill'] }
                          })
                        "
                      ></app-auto-field>
                    </div>
                    <div class="parameter-info__field" *ngIf="!queryForm.controls.pagination_has_more_path.value">
                      <app-auto-field
                        [form]="queryForm"
                        [label]="false"
                        [field]="
                          createField({
                            name: 'pagination_has_more_function',
                            field: 'CodeField',
                            params: { autogrow: true, field: true, wrap: true, classes: ['input_fill'] }
                          })
                        "
                      ></app-auto-field>
                    </div>
                  </div>
                </div>
                <div class="query-builder__separator"></div>
                <div class="parameter-info">
                  <div class="parameter-info__header">
                    Total function <span class="parameter-info__optional">(Optional)</span>
                    <span class="parameter-info__quote">number</span>
                  </div>
                  <div class="parameter-info__content">
                    <div class="parameter-info__text">
                      Implement if you want to see total records number<br />
                      Return <strong class="ref-argument">number</strong> of total records
                    </div>
                    <app-query-builder-response-transform
                      [response]="responseBody"
                      [paths]="[
                        { name: 'pagination_total_path', label: 'Total Records', chooseLabel: 'Choose Total Records' }
                      ]"
                      [pathValues]="{ pagination_total_path: queryForm.controls.pagination_total_path.value }"
                      [pathEnabled]="
                        queryForm.controls.pagination_total_transformer_enabled.value
                          ? undefined
                          : 'pagination_total_path'
                      "
                      [emptyLabel]="'Choose Total Records'"
                      (selectedPath)="onTotalTransformPathSelected($event); total_transform.close()"
                      (selectedJS)="onTotalTransformJsSelected(); total_transform.close()"
                      #total_transform
                    >
                    </app-query-builder-response-transform>
                    <div
                      class="parameter-info__field"
                      *ngIf="queryForm.controls.pagination_total_transformer_enabled.value"
                    >
                      <app-auto-field
                        [form]="queryForm"
                        [label]="false"
                        [field]="
                          createField({
                            name: 'pagination_total_function',
                            field: 'CodeField',
                            params: { autogrow: true, field: true, wrap: true, classes: ['input_fill'] }
                          })
                        "
                      ></app-auto-field>
                    </div>
                  </div>
                </div>
              </app-page-block>
            </ng-container>

            <ng-container *ngIf="queryForm.controls.pagination.value == queryForm.queryPaginations.Cursor">
              <app-page-block [paddingSize]="'s'">
                <div class="query-builder__separator"></div>
              </app-page-block>

              <app-page-block [paddingSize]="'s'">
                <div class="query-builder__heading">Implement Cursor pagination inputs</div>
              </app-page-block>

              <app-page-block [paddingSize]="'s'">
                <div class="parameter-info">
                  <div class="parameter-info__header">
                    <span class="parameter-info__required">*</span> Cursor next
                    <span class="parameter-info__quote">number</span>
                  </div>
                  <div class="parameter-info__content">
                    <div class="parameter-info__text">
                      Contains List's next Page cursor - [cus_He1Fc7ABpoldhR]<br />
                      Specify <strong class="ref-argument">Cursor next</strong> in Query Params
                    </div>
                  </div>
                </div>
                <div class="query-builder__separator"></div>
                <div class="parameter-info">
                  <div class="parameter-info__header">
                    Cursor previous <span class="parameter-info__optional">(Optional)</span>
                    <span class="parameter-info__quote">number</span>
                  </div>
                  <div class="parameter-info__content">
                    <div class="parameter-info__text">
                      Contains List's previous Page cursor - [cus_AbfFc7jUfiSgkF]<br />
                      Specify <strong class="ref-argument">Cursor next</strong> in Query Params
                    </div>
                  </div>
                </div>
                <div class="query-builder__separator"></div>
                <div class="parameter-info">
                  <div class="parameter-info__header">
                    Per page limit <span class="parameter-info__optional">(Optional)</span>
                    <span class="parameter-info__quote">number</span>
                  </div>
                  <div class="parameter-info__content">
                    <div class="parameter-info__text">
                      Contains a number of items per Page.<br />
                      Specify <strong class="ref-argument">Per page limit</strong> in Query Params
                    </div>

                    <div class="parameter-info__input parameter-info__input_padding">
                      <app-auto-field
                        [form]="queryForm"
                        [field]="
                          createField({
                            name: 'pagination_per_page_max',
                            label: 'Maximum per page limit',
                            field: 'IntegerField',
                            placeholder: 'Default is 1000',
                            params: { classes: ['input_fill'] }
                          })
                        "
                      ></app-auto-field>
                    </div>

                    <div class="parameter-info__input parameter-info__input_padding">
                      <app-auto-field
                        [form]="queryForm"
                        [field]="
                          createField({
                            name: 'pagination_per_page_min',
                            label: 'Minimum per page limit',
                            field: 'IntegerField',
                            placeholder: 'Default is 1',
                            params: { classes: ['input_fill'] }
                          })
                        "
                      ></app-auto-field>
                    </div>
                  </div>
                </div>
                <div class="query-builder__separator"></div>
                <div class="parameter-info">
                  <div class="parameter-info__header">
                    <span class="parameter-info__required">*</span> Cursor next function
                    <span class="parameter-info__quote">any</span>
                  </div>
                  <div class="parameter-info__content">
                    <div class="parameter-info__text">
                      Return <strong class="ref-argument">cursor</strong> that will be used to run next page query
                    </div>
                    <app-query-builder-response-transform
                      [response]="responseBody"
                      [paths]="[
                        {
                          name: 'pagination_cursor_next_path',
                          label: 'Cursor Next Records',
                          chooseLabel: 'Choose Cursor Next Records'
                        }
                      ]"
                      [pathValues]="{
                        pagination_cursor_next_path: queryForm.controls.pagination_cursor_next_path.value
                      }"
                      [pathEnabled]="
                        queryForm.controls.pagination_cursor_next_transformer_enabled.value
                          ? undefined
                          : 'pagination_cursor_next_path'
                      "
                      [emptyLabel]="'Choose Cursor Next Records'"
                      (selectedPath)="onCursorNextPathSelected($event); cursor_next_transform.close()"
                      (selectedJS)="onCursorNextTransformJsSelected(); cursor_next_transform.close()"
                      #cursor_next_transform
                    ></app-query-builder-response-transform>
                    <div
                      class="parameter-info__field"
                      *ngIf="queryForm.controls.pagination_cursor_next_transformer_enabled.value"
                    >
                      <app-auto-field
                        [form]="queryForm"
                        [label]="false"
                        [field]="
                          createField({
                            name: 'pagination_cursor_next_function',
                            field: 'CodeField',
                            params: { autogrow: true, field: true, wrap: true, classes: ['input_fill'] }
                          })
                        "
                      ></app-auto-field>
                    </div>
                  </div>
                </div>
                <div class="query-builder__separator"></div>
                <div class="parameter-info">
                  <div class="parameter-info__header">
                    Cursor prev function <span class="parameter-info__optional">(Optional)</span>
                    <span class="parameter-info__quote">any</span>
                  </div>
                  <div class="parameter-info__content">
                    <div class="parameter-info__text">
                      Return <strong class="ref-argument">cursor</strong> that will be used to run prev page query if
                      you want to pagination to work both ways
                    </div>
                    <app-query-builder-response-transform
                      [response]="responseBody"
                      [paths]="[
                        {
                          name: 'pagination_cursor_prev_path',
                          label: 'Cursor Prev Records',
                          chooseLabel: 'Choose Cursor Prev Records'
                        }
                      ]"
                      [pathValues]="{
                        pagination_cursor_prev_path: queryForm.controls.pagination_cursor_prev_path.value
                      }"
                      [pathEnabled]="
                        queryForm.controls.pagination_cursor_prev_transformer_enabled.value
                          ? undefined
                          : 'pagination_cursor_prev_path'
                      "
                      [emptyLabel]="'Choose Cursor Prev Records'"
                      (selectedPath)="onCursorPrevPathSelected($event); cursor_prev_transform.close()"
                      (selectedJS)="onCursorPrevTransformJsSelected(); cursor_prev_transform.close()"
                      #cursor_prev_transform
                    ></app-query-builder-response-transform>
                    <div
                      class="parameter-info__field"
                      *ngIf="queryForm.controls.pagination_cursor_prev_transformer_enabled.value"
                    >
                      <app-auto-field
                        [form]="queryForm"
                        [label]="false"
                        [field]="
                          createField({
                            name: 'pagination_cursor_prev_function',
                            field: 'CodeField',
                            params: { autogrow: true, field: true, wrap: true, classes: ['input_fill'] }
                          })
                        "
                      ></app-auto-field>
                    </div>
                  </div>
                </div>
                <div class="query-builder__separator"></div>
                <div class="parameter-info">
                  <div class="parameter-info__header">
                    Total function <span class="parameter-info__optional">(Optional)</span>
                    <span class="parameter-info__quote">number</span>
                  </div>
                  <div class="parameter-info__content">
                    <div class="parameter-info__text">
                      Implement if you want to see total records number<br />
                      Return <strong class="ref-argument">number</strong> of total records
                    </div>
                    <app-query-builder-response-transform
                      [response]="responseBody"
                      [paths]="[
                        { name: 'pagination_total_path', label: 'Total Records', chooseLabel: 'Choose Total Records' }
                      ]"
                      [pathValues]="{ pagination_total_path: queryForm.controls.pagination_total_path.value }"
                      [pathEnabled]="
                        queryForm.controls.pagination_total_transformer_enabled.value
                          ? undefined
                          : 'pagination_total_path'
                      "
                      [emptyLabel]="'Choose Total Records'"
                      (selectedPath)="onTotalTransformPathSelected($event); total_transform.close()"
                      (selectedJS)="onTotalTransformJsSelected(); total_transform.close()"
                      #total_transform
                    ></app-query-builder-response-transform>
                    <div
                      class="parameter-info__field"
                      *ngIf="queryForm.controls.pagination_total_transformer_enabled.value"
                    >
                      <app-auto-field
                        [form]="queryForm"
                        [label]="false"
                        [field]="
                          createField({
                            name: 'pagination_total_function',
                            field: 'CodeField',
                            params: { autogrow: true, field: true, wrap: true, classes: ['input_fill'] }
                          })
                        "
                      ></app-auto-field>
                    </div>
                  </div>
                </div>
              </app-page-block>
            </ng-container>
          </ng-container>

          <ng-container *ngIf="sideBarSection == sideBarSections.ErrorTransformer">
            <app-page-block [paddingSize]="'s'">
              <p class="query-builder__text">
                Implement <strong class="ref-function">Error message function</strong> which should return error message
                from the response. You can use HTTP code, headers or response to get data.
              </p>
            </app-page-block>

            <app-page-block [paddingSize]="'s'">
              <app-auto-field
                [form]="form"
                [field]="
                  createField({
                    name: 'error_transformer',
                    label: 'Error message function',
                    field: 'CodeField',
                    params: { autogrow: true, field: true, wrap: true, classes: ['input_fill'] }
                  })
                "
                [appTokenInput]="{
                  context: context,
                  control: form.controls.error_transformer,
                  quote: { open: '', close: '' }
                }"
                (appTokenEvent)="onInputTokensEvent($event, queryBuilderSections.SideBar)"
              ></app-auto-field>
            </app-page-block>
          </ng-container>
        </div>
      </ng-container>

      <ng-container *ngIf="!sideBarSection">
        <div class="query-builder-side__header">
          <div class="query-builder-side__header-left">
            <span class="query-builder__title-icon" [style.background-image]="'url(' + resource.icon + ')'"></span>
          </div>
          <div class="query-builder-side__header-main">
            <div class="query-builder__title">
              <ng-container *ngIf="resource?.typeItem.name == resourceNames.GraphQL">Make GraphQL query</ng-container>
              <ng-container *ngIf="resource?.typeItem.name != resourceNames.GraphQL">Make HTTP query</ng-container>
            </div>
          </div>
          <div class="query-builder-side__header-right">
            <mat-menu #request_dropdown="matMenu">
              <ng-template matMenuContent>
                <button mat-menu-item class="mat-menu-item-odd" [disableRipple]="true" (click)="copyRequest()">
                  <span class="choose-items-item__dropdown-icon icon-copy"></span>
                  Copy request
                </button>

                <button
                  mat-menu-item
                  class="mat-menu-item-odd"
                  [disabled]="!isPasteRequestAvailable()"
                  [disableRipple]="true"
                  (click)="pasteRequest()"
                >
                  <span class="choose-items-item__dropdown-icon icon-download"></span>
                  Paste request
                </button>
              </ng-template>
            </mat-menu>

            <div
              class="button button_icon"
              [class.button_disabled]="request_dropdown_trigger.menuOpen"
              [matMenuTriggerFor]="request_dropdown"
              #request_dropdown_trigger="matMenuTrigger"
            >
              <span class="button__icon icon-more"></span>
            </div>

            <!--            <a class="button" target="_blank" [appDocumentation]="'http-queries'">-->
            <!--              <span class="icon button__icon_left icon-documents button__icon"></span>-->
            <!--              <span class="button__label">Docs</span>-->
            <!--            </a>-->
          </div>
        </div>

        <div class="query-builder__links">
          <a [appDocumentation]="'http-queries'" class="query-builder__links-item" target="_blank">
            <span class="query-builder__links-item-icon icon-book"></span>
            <span class="query-builder__links-item-label">Read Docs</span>
          </a>
          <a
            href="javascript:void(0)"
            class="query-builder__links-item"
            [appShowVideo]="{ youtubeId: 'ajqCp3uTb-o', startTime: 74 }"
          >
            <span class="query-builder__links-item-icon icon-play_2"></span>
            <span class="query-builder__links-item-label">Watch Video</span>
          </a>
          <a href="javascript:void(0)" class="query-builder__links-item query-builder-http-tour-link">
            <span class="query-builder__links-item-icon icon-cursor"></span>
            <span class="query-builder__links-item-label">Take Tour</span>
          </a>
        </div>

        <ng-content select="ng-container[tabs]"></ng-content>
        <div class="query-builder-side__content">
          <app-dynamic-component *ngFor="let item of customSectionComponentsTop" [componentData]="item">
          </app-dynamic-component>

          <app-page-block *ngIf="queryForm.listQuery" [paddingSize]="'s'">
            <div class="query-builder__button-group">
              <div class="query-builder__button-group-header">
                <!--              <a-->
                <!--                href="javascript:void(0)"-->
                <!--                class="query-builder__button-group-toggle"-->
                <!--                [class.query-builder__button-group-toggle_active]="!queryForm.controls.frontend_filtering.value"-->
                <!--                (click)="queryForm.toggleFrontendFiltering()"-->
                <!--              >-->
                <!--                <ng-container *ngIf="!queryForm.controls.frontend_filtering.value">Enabled</ng-container>-->
                <!--                <ng-container *ngIf="queryForm.controls.frontend_filtering.value">Disabled</ng-container>-->
                <!--              </a>-->
                <!--              <div class="query-builder__button-group-title">Server query operations</div>-->
                <span class="query-builder__button-group-switch">
                  <a
                    href="javascript:void(0)"
                    class="query-builder__button-group-switch-item"
                    [class.query-builder__button-group-switch-item_active]="queryForm.controls.frontend_filtering.value"
                    (click)="queryForm.controls.frontend_filtering.patchValue(true)"
                  >
                    Client
                  </a>
                  <a
                    href="javascript:void(0)"
                    class="query-builder__button-group-switch-item"
                    [class.query-builder__button-group-switch-item_active]="
                      !queryForm.controls.frontend_filtering.value
                    "
                    (click)="queryForm.controls.frontend_filtering.patchValue(false)"
                  >
                    Server
                  </a>
                </span>
                <div class="query-builder__button-group-title">Query operations</div>
                <div class="query-builder__button-group-description">
                  Set up server based filtering, sorting and pagination or use automatically generated
                </div>
              </div>
              <div
                class="query-builder__button-group-content"
                [class.query-builder__button-group-content_disabled]="queryForm.controls.frontend_filtering.value"
              >
                <a
                  href="javascript:void(0)"
                  class="query-builder__button-section"
                  [class.query-builder__button-section_disabled]="queryForm.controls.frontend_filtering.value"
                  (click)="setSideBarSection(sideBarSections.Pagination)"
                >
                  <div class="query-builder__button-section-left">
                    <span
                      class="query-builder__button-section-icon icon-documents"
                      [class.query-builder__button-section-icon_orange]="
                        queryForm.controls.frontend_filtering.value || queryForm.controls.pagination.value
                      "
                    ></span>
                  </div>
                  <div class="query-builder__button-section-main">
                    <div class="query-builder__button-section-title">
                      Pagination
                      <span
                        *ngIf="queryForm.controls.pagination.value"
                        class="query-builder__button-section-title-control icon-gear_2"
                      ></span>
                    </div>
                    <!--                  <div class="query-builder__button-section-description">-->
                    <!--                    Split records by pages to display large datasets-->
                    <!--                  </div>-->
                  </div>
                  <div class="query-builder__button-section-right">
                    <app-toggle
                      *ngIf="queryForm.controls.pagination.value"
                      [orange]="true"
                      [selected]="queryForm.controls.frontend_filtering.value || queryForm.controls.pagination.value"
                      (click)="$event.stopPropagation(); queryForm.setPagination(undefined)"
                    >
                    </app-toggle>
                    <app-toggle
                      *ngIf="!queryForm.controls.pagination.value"
                      [orange]="true"
                      [selected]="queryForm.controls.frontend_filtering.value || queryForm.controls.pagination.value"
                    >
                    </app-toggle>
                  </div>
                </a>

                <ng-template #filters_tip>
                  <div style="max-width: 240px;">
                    <div>
                      Add filters by creating Inputs and inserting them in Query Params, Headers or Body
                    </div>
                    <!--                  <video autoplay loop muted playsinline style="margin-top: 10px; max-width: 240px; min-height: 135px;">-->
                    <!--                    <source src="https://www.jetadmin.io/assets/videos/visually-build.mp4" />-->
                    <!--                  </video>-->
                  </div>
                </ng-template>

                <div
                  class="query-builder__button-section query-builder__button-section_info"
                  [class.query-builder__button-section_disabled]="queryForm.controls.frontend_filtering.value"
                  [appTip]="filters_tip"
                  [appTipOptions]="{ opaque: true, flexible: true }"
                >
                  <div class="query-builder__button-section-left">
                    <span
                      class="query-builder__button-section-icon query-builder__button-section-icon_orange icon-filter"
                    ></span>
                  </div>
                  <div class="query-builder__button-section-main">
                    <div class="query-builder__button-section-title">Filters</div>
                    <!--                  <div class="query-builder__button-section-description">-->
                    <!--                    Filter received result based on Inputs-->
                    <!--                  </div>-->
                  </div>
                  <div class="query-builder__button-section-right">
                    <app-toggle *ngIf="queryForm.controls.frontend_filtering.value" [orange]="true" [selected]="true">
                    </app-toggle>
                    <span
                      *ngIf="!queryForm.controls.frontend_filtering.value"
                      class="query-builder__button-section-icon-control icon-help"
                    ></span>
                  </div>
                </div>

                <a
                  href="javascript:void(0)"
                  class="query-builder__button-section query-builder__button-section_clickable"
                  [class.query-builder__button-section_disabled]="queryForm.controls.frontend_filtering.value"
                  (click)="queryForm.controls.sorting.patchValue(true); setSideBarSection(sideBarSections.Sorting)"
                >
                  <div class="query-builder__button-section-left">
                    <span
                      class="query-builder__button-section-icon icon-filter_down"
                      [class.query-builder__button-section-icon_orange]="
                        queryForm.controls.frontend_filtering.value || queryForm.controls.sorting.value
                      "
                    ></span>
                  </div>
                  <div class="query-builder__button-section-main">
                    <div class="query-builder__button-section-title">
                      Sorting
                      <span
                        *ngIf="queryForm.controls.sorting.value"
                        class="query-builder__button-section-title-control icon-gear_2"
                      ></span>
                    </div>
                    <!--                  <div class="query-builder__button-section-description">-->
                    <!--                    Allows to reorder collection items by some field-->
                    <!--                  </div>-->
                  </div>
                  <div class="query-builder__button-section-right">
                    <app-toggle
                      *ngIf="queryForm.controls.sorting.value"
                      [orange]="true"
                      [selected]="queryForm.controls.frontend_filtering.value || queryForm.controls.sorting.value"
                      (click)="$event.stopPropagation(); queryForm.controls.sorting.patchValue(false)"
                    >
                    </app-toggle>
                    <app-toggle
                      *ngIf="!queryForm.controls.sorting.value"
                      [orange]="true"
                      [selected]="queryForm.controls.frontend_filtering.value || queryForm.controls.sorting.value"
                    >
                    </app-toggle>
                  </div>
                </a>

                <a
                  *ngIf="queryForm.controls.frontend_filtering.value"
                  href="javascript:void(0)"
                  class="query-builder__button-group-content-overlay"
                  (click)="queryForm.toggleFrontendFiltering()"
                >
                  <span class="icon-edit query-builder__button-group-content-overlay-icon"></span> Set up server query
                  operations
                </a>
              </div>
            </div>
          </app-page-block>

          <app-page-block [paddingSize]="'s'">
            <a
              href="javascript:void(0)"
              class="query-builder__button-section"
              (click)="
                form.controls.error_transformer_visible.patchValue(true);
                setSideBarSection(sideBarSections.ErrorTransformer)
              "
            >
              <div class="query-builder__button-section-left">
                <span
                  class="query-builder__button-section-icon icon-warning"
                  [class.query-builder__button-section-icon_orange]="form.controls.error_transformer_visible.value"
                ></span>
              </div>
              <div class="query-builder__button-section-main">
                <div class="query-builder__button-section-title">
                  Error displaying
                  <span
                    *ngIf="form.controls.error_transformer_visible.value"
                    class="query-builder__button-section-title-control icon-gear_2"
                  ></span>
                </div>
                <div class="query-builder__button-section-description">
                  Specify what kind of result to consider as errors and what error messages should be displayed to user
                </div>
              </div>
              <div class="query-builder__button-section-right">
                <app-toggle
                  *ngIf="form.controls.error_transformer_visible.value"
                  [orange]="true"
                  [selected]="form.controls.error_transformer_visible.value"
                  (click)="$event.stopPropagation(); form.controls.error_transformer_visible.patchValue(false)"
                >
                </app-toggle>
                <app-toggle
                  *ngIf="!form.controls.error_transformer_visible.value"
                  [orange]="true"
                  [selected]="form.controls.error_transformer_visible.value"
                >
                </app-toggle>
              </div>
            </a>
          </app-page-block>

          <ng-content select="ng-container[sections-bottom]"></ng-content>

          <app-dynamic-component *ngFor="let item of customSectionComponentsBottom" [componentData]="item">
          </app-dynamic-component>

          <!--          <app-sidebar-field-->
          <!--            class="query-builder__toggle query-builder__toggle_with_content"-->
          <!--            [label]="'Error nested key'"-->
          <!--            [right]="true"-->
          <!--          >-->
          <!--            <app-auto-field-->
          <!--              [form]="form"-->
          <!--              [label]="false"-->
          <!--              [field]="-->
          <!--                createField({-->
          <!--                  name: 'error_transformer_visible',-->
          <!--                  field: 'BooleanField',-->
          <!--                  params: { style: booleanFieldStyle.Toggle, orange: true }-->
          <!--                })-->
          <!--              "-->
          <!--            ></app-auto-field>-->
          <!--          </app-sidebar-field>-->
          <!--          <app-toggle-content-->
          <!--            *ngIf="form.controls.error_transformer_visible.value"-->
          <!--            class="query-builder__toggle-content"-->
          <!--          >-->
          <!--            <ng-container default>-->
          <!--              <app-auto-field-->
          <!--                [form]="form"-->
          <!--                [label]="false"-->
          <!--                [field]="-->
          <!--                  createField({-->
          <!--                    name: 'error_transformer',-->
          <!--                    field: 'CodeField',-->
          <!--                    params: { autogrow: true, field: true, field_contrast: true, wrap: true, classes: ['input_fill'] }-->
          <!--                  })-->
          <!--                "-->
          <!--                [appTokenInput]="{ context: context, control: form.controls.error_transformer } (appTokenEvent)="onInputTokensEvent($event)"-->
          <!--              ></app-auto-field>-->
          <!--            </ng-container>-->
          <!--          </app-toggle-content>-->

          <!--          <app-sidebar-field-->
          <!--            class="query-builder__toggle query-builder__toggle_with_content"-->
          <!--            [label]="'Body transformer'"-->
          <!--            [right]="true"-->
          <!--          >-->
          <!--            <app-auto-field-->
          <!--              [form]="form"-->
          <!--              [label]="false"-->
          <!--              [field]="-->
          <!--                createField({-->
          <!--                  name: 'body_transformer_visible',-->
          <!--                  field: 'BooleanField',-->
          <!--                  params: { style: booleanFieldStyle.Toggle, orange: true }-->
          <!--                })-->
          <!--              "-->
          <!--            ></app-auto-field>-->
          <!--          </app-sidebar-field>-->
          <!--          <app-toggle-content-->
          <!--            *ngIf="form.controls.body_transformer_visible.value"-->
          <!--            class="query-builder__toggle-content"-->
          <!--          >-->
          <!--            <ng-container default>-->
          <!--              <app-auto-field-->
          <!--                [form]="form"-->
          <!--                [label]="false"-->
          <!--                [field]="-->
          <!--                  createField({-->
          <!--                    name: 'body_transformer',-->
          <!--                    field: 'CodeField',-->
          <!--                    params: { autogrow: true, field: true, field_contrast: true, wrap: true, classes: ['input_fill'] }-->
          <!--                  })-->
          <!--                "-->
          <!--                [appTokenInput]="{ context: context, control: form.controls.body_transformer }" (appTokenEvent)="onInputTokensEvent($event)"-->
          <!--              ></app-auto-field>-->
          <!--            </ng-container>-->
          <!--          </app-toggle-content>-->
        </div>
      </ng-container>
      <div class="query-builder-side__footer">
        <div class="query-builder-side__footer-left">
          <button type="button" class="button button_orange" (click)="cancel()">
            <span class="button__label">Cancel</span>
          </button>
        </div>
        <div class="query-builder-side__footer-right">
          <button
            type="button"
            class="button button_primary button_orange"
            [disabled]="form.invalid || form.disabled"
            cdkOverlayOrigin
            #save_trigger="cdkOverlayOrigin"
            (mouseenter)="saveHovered.next(true)"
            (mouseleave)="saveHovered.next(false)"
            (click)="submit()"
          >
            <span class="button__label">Save</span>
          </button>

          <ng-template
            cdkConnectedOverlay
            [cdkConnectedOverlayPanelClass]="['overlay_position_top']"
            [cdkConnectedOverlayPositions]="[
              {
                originX: 'center',
                originY: 'top',
                overlayX: 'center',
                overlayY: 'bottom',
                offsetX: 0,
                offsetY: -10
              }
            ]"
            [cdkConnectedOverlayOrigin]="save_trigger"
            [cdkConnectedOverlayOpen]="requireResponseMessageVisible$ | async"
            [cdkConnectedOverlayPush]="true"
            [cdkConnectedOverlayGrowAfterOpen]="true"
          >
            <div
              class="tip-overlay"
              (mouseenter)="requireResponseMessageHovered.next(true)"
              (mouseleave)="requireResponseMessageHovered.next(false)"
            >
              <div class="tip-overlay__title">Send request before save</div>
              <div class="tip-overlay__text">
                <ng-container *ngIf="requestCreate">
                  Request was not sent. You need to get request response to save it.
                </ng-container>
                <ng-container *ngIf="!requestCreate">
                  Request was changed. You need to get updated request response to save it.
                </ng-container>
              </div>
              <div class="tip-overlay__buttons">
                <ng-container *ngIf="isResponseMissing()">
                  <a
                    href="javascript:void(0)"
                    class="tip-overlay__button tip-overlay-button tip-overlay-button_primary"
                    (click)="setResultsSection(resultsSections.Result); execute()"
                  >
                    <app-loader-small *ngIf="loading" class="tip-overlay-button__icon"></app-loader-small>
                    <span *ngIf="!loading" class="tip-overlay-button__icon icon-play_filled"></span>
                    Send Request
                  </a>
                  <a
                    href="javascript:void(0)"
                    class="tip-overlay__button tip-overlay-button"
                    (click)="requireResponseMessageHovered.next(false); saveProcess()"
                    >Save anyway</a
                  >
                </ng-container>
                <ng-container *ngIf="!isResponseMissing()">
                  <a
                    href="javascript:void(0)"
                    class="tip-overlay__button tip-overlay-button"
                    (click)="setResultsSection(resultsSections.Result); execute()"
                  >
                    <app-loader-small *ngIf="loading" class="tip-overlay-button__icon"></app-loader-small>
                    <span *ngIf="!loading" class="tip-overlay-button__icon icon-play_filled"></span>
                    Send Request
                  </a>
                  <a
                    href="javascript:void(0)"
                    class="tip-overlay__button tip-overlay-button tip-overlay-button_primary"
                    (click)="requireResponseMessageHovered.next(false); saveProcess()"
                    >Finish</a
                  >
                </ng-container>
              </div>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</form>

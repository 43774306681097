import { Injectable } from '@angular/core';
import { OutputBlockData } from '@editorjs/editorjs';

import { editorJsOutputDataToQuillDelta, TextElementItem } from '@modules/customize';
import { isSet } from '@shared';

@Injectable()
export class TextGenerator {
  constructor() {}

  getElement(options: { h1?: string; h2?: string; h3?: string; text?: string; uid?: string }): TextElementItem {
    const element = new TextElementItem();

    if (options.uid) {
      element.uid = options.uid;
    } else {
      element.generateUid();
    }

    const blocks: OutputBlockData[] = [];

    if (isSet(options.h1)) {
      blocks.push({
        type: 'header',
        data: {
          text: options.h1,
          level: 1
        }
      });
    }

    if (isSet(options.h2)) {
      blocks.push({
        type: 'header',
        data: {
          text: options.h2,
          level: 2
        }
      });
    }

    if (isSet(options.h3)) {
      blocks.push({
        type: 'header',
        data: {
          text: options.h3,
          level: 3
        }
      });
    }

    if (isSet(options.text)) {
      blocks.push({
        type: 'paragraph',
        data: {
          text: options.text
        }
      });
    }

    element.quillDelta = editorJsOutputDataToQuillDelta({
      blocks: blocks
    });

    return element;
  }
}

<a
  class="button button_small"
  [class.button_primary]="!item.tint && style == tintStyles.Primary"
  [class.button_primary-transparent]="!item.tint && style == tintStyles.Transparent"
  [class.button_disabled]="disabled || submitLoading"
  [class.button_icon]="!(verboseName | appIsSet)"
  [appButtonTintColor]="item.tint || accentColor"
  [appButtonTintColorStyle]="style"
  (click)="executeAction(item, button_element)"
  #button_element
>
  <app-loader-small
    *ngIf="submitLoading"
    class="button__icon"
    [class.button__icon_left]="verboseName | appIsSet"
  ></app-loader-small>
  <span
    *ngIf="item.icon && !submitLoading"
    class="icon button__icon"
    [class.button__icon_left]="verboseName | appIsSet"
    [appIcon]="item.icon"
  >
  </span>
  <span *ngIf="verboseName | appIsSet" class="button__label">
    {{ verboseName | appCapitalize }}
  </span>
</a>

<span
  *ngIf="!isVisible && (customizeService.enabled$ | async)"
  class="element__indicator element__indicator_outside icon-eye"
  [appTip]="'Action is hidden'"
></span>

import { moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  InjectionToken,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  Provider,
  ViewChild
} from '@angular/core';
import cloneDeep from 'lodash/cloneDeep';
import last from 'lodash/last';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, fromEvent, Observable } from 'rxjs';
import { filter, first, map, switchMap } from 'rxjs/operators';

import { AppDragDrop } from '@common/drag-drop2';
import { DynamicComponentArguments } from '@common/dynamic-component';
import { BasePopupComponent, PopupService } from '@common/popups';
import { ActionService, WorkflowExecuteEventType } from '@modules/action-queries';
import { ActionType } from '@modules/actions';
import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { ServerRequestError } from '@modules/api';
import { ViewContext, ViewContextElement } from '@modules/customize';
import { FieldOutput, ParameterField } from '@modules/fields';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import {
  Automation,
  AutomationTrigger,
  cleanWorkflowStepName,
  Workflow,
  WorkflowRun,
  WorkflowStep,
  WorkflowStepType
} from '@modules/workflow';
import { isControlElement, isSet, KeyboardEventKeyCode } from '@shared';

import { CustomizeBarContext } from '../../../services/customize-bar-context/customize-bar.context';
import { WorkflowEditContext } from '../../../services/workflow-edit-context/workflow-edit.context';
import { WORKFLOW_CONTROLLER_COMPONENT } from '../../../services/workflow-edit-controller/workflow-edit.controller';
import { isWorkflowElementClick, markWorkflowElementClick } from '../auto-workflow-step/auto-workflow-step.component';
import { WorkflowRunsComponent } from '../workflow-runs/workflow-runs.component';
import { WorkflowSaveEvent } from './workflow-save-event';

export const workflowStepsToken = new InjectionToken<ViewContextElement>('workflowStepsToken');
export const workflowAppToken = new InjectionToken<ViewContextElement>('workflowAppToken');

export const WORKFLOW_CONTROLLER_COMPONENT_PROVIDER: Provider = {
  provide: WORKFLOW_CONTROLLER_COMPONENT,
  useFactory: workflowControllerComponentFactory
};

export function workflowControllerComponentFactory(): any {
  return WorkflowComponent;
}

@Component({
  selector: 'app-workflow',
  templateUrl: './workflow.component.html',
  providers: [
    CustomizeBarContext,
    WorkflowEditContext,
    { provide: workflowStepsToken, useClass: ViewContextElement },
    { provide: workflowAppToken, useClass: ViewContextElement }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WorkflowComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() nameEditable = false;
  @Input() name: string;
  @Input() workflow: Workflow;
  @Input() workflowEditable = false;
  @Input() workflowRun: WorkflowRun;
  @Input() parametersEnabled = false;
  @Input() parameters: ParameterField[] = [];
  @Input() automation: Automation;
  @Input() triggerEditable = false;
  @Input() trigger: AutomationTrigger;
  @Input() triggerOutputs: FieldOutput[];
  @Input() triggerLabel: string;
  @Input() triggerIcon: string;
  @Input() triggerData: any;
  @Input() customizeTrigger = false;
  @Input() context: ViewContext;
  @Input() contextElement: ViewContextElement;
  @Input() actionTypesEnabled: ActionType[];
  @Input() historyEnabled = false;
  @Input() historyOpenedInitial = false;
  @Input() resultEnabled = false;
  @Input() resultOutputs: FieldOutput[];
  @Input() analyticsSource: string;
  @Output() result = new EventEmitter<WorkflowSaveEvent>();
  @Output() cancel = new EventEmitter<void>();

  @ViewChild('canvas_element') canvasElement: ElementRef;

  loadingExecute = false;
  statusIcon: string;
  statusColor: string;
  isUndoAvailable$: Observable<boolean>;
  isRedoAvailable$: Observable<boolean>;
  nameEditing = false;

  trackStep = (() => {
    return (i, item: WorkflowStep) => {
      return item ? item.uid : i;
    };
  })();

  constructor(
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    public customizeBarContext: CustomizeBarContext,
    public workflowEditContext: WorkflowEditContext,
    private actionService: ActionService,
    private popupService: PopupService,
    @Inject(workflowStepsToken) private workflowStepsContextElement: ViewContextElement,
    @Inject(workflowAppToken) private workflowAppContextElement: ViewContextElement,
    @Optional() private popupComponent: BasePopupComponent,
    private cd: ChangeDetectorRef,
    private analyticsService: UniversalAnalyticsService
  ) {}

  ngOnInit() {
    this.workflowEditContext.init({ workflow: this.workflow, parameters: this.parameters });
    this.workflowEditContext.run$.next(this.workflowRun || new WorkflowRun());
    this.isUndoAvailable$ = this.workflowEditContext.isUndoAvailable$();
    this.isRedoAvailable$ = this.workflowEditContext.isRedoAvailable$();

    this.workflowStepsContextElement.initGlobal({ uniqueName: 'steps', name: 'Steps' });

    this.workflowAppContextElement.initGlobal({ uniqueName: 'app', name: 'Application' });
    this.workflowAppContextElement.setOutputs([
      {
        uniqueName: 'name',
        name: 'App name',
        icon: 'home'
      },
      {
        uniqueName: 'env_name',
        name: 'Environment name',
        icon: 'tag'
      }
    ]);

    combineLatest(this.currentProjectStore.instance$, this.currentEnvironmentStore.instance$)
      .pipe(untilDestroyed(this))
      .subscribe(([project, environment]) => {
        this.workflowAppContextElement.setOutputValues({
          name: project.uniqueName,
          env_name: environment.uniqueName
        });
      });

    if (this.workflowEditable) {
      this.initHotkeys();
    }

    if (this.historyOpenedInitial && this.historyEnabled) {
      this.openRuns();
    }
  }

  ngOnDestroy(): void {
    this.workflowStepsContextElement.ngOnDestroy();
  }

  ngAfterViewInit(): void {
    setTimeout(() => this.scrollToCenter(), 0);
  }

  scrollToCenter() {
    const element = this.canvasElement.nativeElement;
    element.scrollTo((element.scrollWidth - element.offsetWidth) * 0.5, 0);
  }

  initHotkeys() {
    fromEvent<KeyboardEvent>(document, 'keydown')
      .pipe(
        filter(
          () => this.popupService.last() === this.popupComponent.data && !isControlElement(document.activeElement)
        ),
        map(e => {
          if (e.keyCode == KeyboardEventKeyCode.Z && (e.metaKey || e.ctrlKey) && !e.shiftKey) {
            return -1;
          } else if (e.keyCode == KeyboardEventKeyCode.Z && (e.metaKey || e.ctrlKey) && e.shiftKey) {
            return 1;
          }
        }),
        filter(move => isSet(move)),
        switchMap(move => {
          if (move > 0) {
            return this.isRedoAvailable$.pipe(
              first(),
              map(allowed => [move, allowed])
            );
          } else {
            return this.isUndoAvailable$.pipe(
              first(),
              map(allowed => [move, allowed])
            );
          }
        }),
        untilDestroyed(this)
      )
      .subscribe(([move, allowed]) => {
        if (!allowed) {
          return;
        }

        if (move > 0) {
          this.workflowEditContext.redo();
        } else {
          this.workflowEditContext.undo();
        }
      });
  }

  dragDrop(event: AppDragDrop<WorkflowStep[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
    }
    this.workflowEditContext.markChanged();
  }

  addStep(step: WorkflowStep, options: { addBeforeIndex?: number } = {}) {
    this.workflowEditContext.registerCreatedElement(step);

    if (isSet(options.addBeforeIndex)) {
      this.workflow.steps.splice(options.addBeforeIndex, 0, step);
    } else {
      this.workflow.steps.push(step);
    }

    this.cd.markForCheck();
    this.workflowEditContext.markChanged();
  }

  duplicateStep(index: number) {
    const step = cloneDeep(this.workflow.steps[index]);
    const defaultName = `Copy of ${step.name}`;

    step.generateUid();
    step.name = cleanWorkflowStepName(defaultName, step, this.workflowEditContext.state.workflow.steps);

    this.workflowEditContext.registerCreatedElement(step);
    this.workflow.steps.splice(index + 1, 0, step);

    this.cd.markForCheck();
    this.workflowEditContext.markChanged();
  }

  deleteStep(index: number) {
    this.workflow.steps.splice(index, 1);
    this.cd.markForCheck();
    this.workflowEditContext.markChanged();
  }

  executeWorkflow() {
    this.loadingExecute = true;
    this.cd.markForCheck();

    const params = this.workflow.testParameters;

    this.workflowEditContext.run$.next(undefined);

    this.actionService
      .executeWorkflow(this.workflow, params, {
        context: this.context,
        showSuccess: false,
        showError: false,
        disableRouting: true,
        disablePopups: true
      })
      .pipe(untilDestroyed(this))
      .subscribe(
        event => {
          this.workflowEditContext.testExecuteEvents$.next(event);

          if (event.type == WorkflowExecuteEventType.WorkflowStarted) {
            this.statusIcon = undefined;
            this.statusColor = undefined;
          } else if (event.type == WorkflowExecuteEventType.WorkflowFinished) {
            this.loadingExecute = false;
            this.workflowEditContext.run$.next(event.run);

            if (event.success) {
              this.statusIcon = 'check_2';
              this.statusColor = 'green';
            } else {
              this.statusIcon = 'close';
              this.statusColor = 'red';
            }

            this.analyticsService.sendSimpleEvent(AnalyticsEvent.WorkflowBuilder.WorkflowTest, {
              Success: true,
              Steps: this.workflow.getStepsCount(),
              Source: this.analyticsSource
            });
          }

          this.cd.markForCheck();
        },
        error => {
          this.loadingExecute = false;
          this.cd.markForCheck();

          let errorMessage: string;

          if (error instanceof ServerRequestError && error.nonFieldErrors.length) {
            errorMessage = error.nonFieldErrors[0];
          } else {
            errorMessage = 'Unknown error';
          }

          this.analyticsService.sendSimpleEvent(AnalyticsEvent.WorkflowBuilder.WorkflowTest, {
            Success: false,
            Error: errorMessage,
            Steps: this.workflow.getStepsCount(),
            Source: this.analyticsSource
          });
        }
      );
  }

  submit(
    options: {
      automationActive?: boolean;
    } = {}
  ) {
    this.result.emit({
      name: this.name,
      trigger: this.trigger,
      workflow: this.workflow,
      workflowRun: this.workflowEditContext.run$.value,
      parameters: this.parameters,
      ...(isSet(options.automationActive) ? { automationActive: options.automationActive } : {})
    });
    this.close();
  }

  close() {
    if (this.popupComponent) {
      this.popupComponent.close();
    }
  }

  onWorkflowElementClick(event: MouseEvent) {
    markWorkflowElementClick(event);
  }

  onTriggerChange(trigger: AutomationTrigger) {
    this.trigger = trigger;
    this.cd.markForCheck();
  }

  setNameEditing(value: boolean) {
    this.nameEditing = value;
    this.cd.markForCheck();
  }

  cleanName() {
    this.name = isSet(this.name) ? this.name.trim() : '';

    if (!isSet(this.name)) {
      this.name = 'Automation';
    }

    this.cd.markForCheck();
  }

  closeCustomize(event: MouseEvent) {
    if (!isWorkflowElementClick(event)) {
      this.customizeBarContext.resetSettingsComponent();
    }
  }

  openRuns() {
    const dynamicComponent: DynamicComponentArguments<WorkflowRunsComponent> = {
      component: WorkflowRunsComponent,
      inputs: {
        automation: this.automation,
        workflow: this.workflow
      }
    };

    this.customizeBarContext.setSettingsComponent(dynamicComponent);
  }

  stepsHasNextConnection(): boolean {
    return !this.workflow.steps.length || last(this.workflow.steps).type != WorkflowStepType.Exit;
  }
}

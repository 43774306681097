/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../projects-components/components/create-resource/create-resource.component.ngfactory";
import * as i2 from "../../../projects-components/components/create-resource/create-resource.component";
import * as i3 from "../../../projects/stores/current-project.store";
import * as i4 from "../../../features/services/feature/feature.service";
import * as i5 from "@angular/common";
import * as i6 from "./create-resource-popup.component";
import * as i7 from "../../../../common/popups/components/base-popup/base-popup.component";
import * as i8 from "../../../analytics/services/universal-analytics/universal-analytics.service";
import * as i9 from "../../../projects-components/services/resource-edit-controller/resource-edit.controller";
var styles_CreateResourcePopupComponent = [];
var RenderType_CreateResourcePopupComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CreateResourcePopupComponent, data: {} });
export { RenderType_CreateResourcePopupComponent as RenderType_CreateResourcePopupComponent };
export function View_CreateResourcePopupComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "div", [["class", "choose-template choose-template_popup"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "div", [["class", "choose-template__header"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Create a Resource "])), (_l()(), i0.ɵeld(3, 0, null, null, 0, "a", [["class", "choose-template__header-close icon-close"], ["href", "javascript:void(0)"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 3, "div", [["class", "choose-template__content"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 2, "app-create-resource", [["class", "choose-template__select-item"]], null, [[null, "chosen"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("chosen" === en)) {
        var pd_0 = (_co.createResource($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_CreateResourceComponent_0, i1.RenderType_CreateResourceComponent)), i0.ɵdid(6, 245760, null, 0, i2.CreateResourceComponent, [i3.CurrentProjectStore, i4.FeatureService, i0.ChangeDetectorRef], { project: [0, "project"] }, { chosen: "chosen" }), i0.ɵpid(131072, i5.AsyncPipe, [i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 6, 0, i0.ɵnov(_v, 7).transform(_co.currentProjectStore.instance$)); _ck(_v, 6, 0, currVal_0); }, null); }
export function View_CreateResourcePopupComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-create-resource-popup", [], null, null, null, View_CreateResourcePopupComponent_0, RenderType_CreateResourcePopupComponent)), i0.ɵdid(1, 245760, null, 0, i6.CreateResourcePopupComponent, [i3.CurrentProjectStore, i7.BasePopupComponent, i0.ChangeDetectorRef, i8.UniversalAnalyticsService, i9.ResourceEditController], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CreateResourcePopupComponentNgFactory = i0.ɵccf("app-create-resource-popup", i6.CreateResourcePopupComponent, View_CreateResourcePopupComponent_Host_0, { analyticsSource: "analyticsSource" }, { created: "created", cancelled: "cancelled" }, []);
export { CreateResourcePopupComponentNgFactory as CreateResourcePopupComponentNgFactory };

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ChangeDetectorRef, EventEmitter, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { fromEvent } from 'rxjs';
import { debounceTime, filter } from 'rxjs/operators';
import { BasePopupComponent } from '@common/popups';
import { DARK_THEME_OUTPUT } from '@modules/customize';
import { FieldType } from '@modules/fields';
import { SELECTED_OUTPUT } from '@modules/list';
import { SidebarCollapseContext } from '@modules/sidebar';
import { isBodyHasChild, isElementHasChild, nodeListToArray } from '@shared';
import { ViewEditorParametersForm } from './view-editor-parameters.form';
var viewEditorParametersMenuClickEventProperty = '_viewEditorParametersMenuClickEvent';
export function markViewEditorParametersClickEvent(clickEvent) {
    clickEvent[viewEditorParametersMenuClickEventProperty] = true;
}
export function isViewEditorParametersClickEvent(clickEvent) {
    return !!clickEvent[viewEditorParametersMenuClickEventProperty];
}
var ViewEditorParametersComponent = /** @class */ (function () {
    function ViewEditorParametersComponent(popupComponent, form, cd) {
        this.popupComponent = popupComponent;
        this.form = form;
        this.cd = cd;
        this.componentLabel = 'component';
        this.parametersEditable = false;
        this.stateSelectedEnabled = false;
        this.testParameters = {};
        this.testState = {};
        this.opened = false;
        this.parametersChanged = new EventEmitter();
        this.testParametersChanged = new EventEmitter();
        this.testStateChanged = new EventEmitter();
        this.closed = new EventEmitter();
        this.SELECTED_OUTPUT = SELECTED_OUTPUT;
        this.DARK_THEME_OUTPUT = DARK_THEME_OUTPUT;
        this.stateCollapseContext = new SidebarCollapseContext();
        this.fieldTypes = FieldType;
        this.analyticsSource = 'view_editor_parameters';
    }
    ViewEditorParametersComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.form.init({ parameters: this.parameters, testParameters: this.testParameters });
        this.form.controls.parameters.valueChanges.pipe(debounceTime(200), untilDestroyed(this)).subscribe(function () {
            var result = _this.form.getParameters();
            _this.parametersChanged.emit(result);
        });
        this.form.controls.test_parameters.valueChanges.pipe(debounceTime(200), untilDestroyed(this)).subscribe(function () {
            var result = _this.form.getTestParameters();
            _this.testParametersChanged.emit(result);
        });
    };
    ViewEditorParametersComponent.prototype.ngOnDestroy = function () { };
    ViewEditorParametersComponent.prototype.ngOnChanges = function (changes) {
        if (changes.opened && this.opened) {
            this.onOpened();
        }
        else if (changes.opened && !changes.opened.firstChange && !this.opened) {
            this.onClosed();
        }
    };
    ViewEditorParametersComponent.prototype.onStateValueChange = function (name, value) {
        var _a;
        this.testStateChanged.emit(__assign({}, this.testState, (_a = {}, _a[name] = value, _a)));
    };
    ViewEditorParametersComponent.prototype.close = function () {
        if (!this.opened) {
            return;
        }
        this.opened = false;
        this.cd.markForCheck();
        this.onClosed();
    };
    ViewEditorParametersComponent.prototype.onOpened = function () {
        var _this = this;
        setTimeout(function () {
            _this.blurSubscription = fromEvent(window.document, 'click')
                .pipe(filter(function (e) {
                if (isViewEditorParametersClickEvent(e)) {
                    return false;
                }
                var overlays = nodeListToArray(document.querySelectorAll('.cdk-overlay-container, .popup'));
                if (overlays
                    .filter(function (item) {
                    if (_this.popupComponent && isElementHasChild(_this.popupComponent.el.nativeElement, item, true)) {
                        return false;
                    }
                    else {
                        return true;
                    }
                })
                    .some(function (overlay) { return isElementHasChild(overlay, e.target); }) ||
                    !isBodyHasChild(e.target)) {
                    return false;
                }
                return true;
            }), untilDestroyed(_this))
                .subscribe(function () { return _this.close(); });
        }, 0);
    };
    ViewEditorParametersComponent.prototype.onClosed = function () {
        if (this.blurSubscription) {
            this.blurSubscription.unsubscribe();
            this.blurSubscription = undefined;
        }
        this.closed.emit();
    };
    ViewEditorParametersComponent.prototype.markClickEvent = function (e) {
        markViewEditorParametersClickEvent(e);
    };
    return ViewEditorParametersComponent;
}());
export { ViewEditorParametersComponent };

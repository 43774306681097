<app-sidebar-header
  *ngIf="selectedItem"
  [title]="'Integrations'"
  [backEnabled]="true"
  (back)="
    initialResource || initialResourceType ? selectTab.emit(customizeBarTabs.Components) : setSelectedItem(undefined)
  "
></app-sidebar-header>

<app-sidebar-header
  *ngIf="selectResourceType"
  [title]="'Databases'"
  [subtitle]="'Integrations'"
  [backEnabled]="true"
  (back)="setSelectResourceType(undefined, undefined)"
></app-sidebar-header>

<app-sidebar-header
  *ngIf="!selectedItem && !selectResourceType"
  [title]="'Integrations'"
  [backEnabled]="true"
  (back)="selectTab.emit(customizeBarTabs.Components)"
></app-sidebar-header>

<div class="sidebar__content">
  <ng-container *ngIf="selectedItem">
    <app-change-customize-bar-templates-group
      [item]="selectedItem"
      [context]="context"
      [dropListGroups]="dropListGroups"
      (scrollToTop)="scrollToTop.emit($event)"
      (update)="updateGroups()"
    >
    </app-change-customize-bar-templates-group>
  </ng-container>

  <ng-container *ngIf="selectResourceType">
    <!--    <a href="javascript:void(0)" class="sidebar__title-link" (click)="setSelectResourceType(undefined, undefined)">-->
    <!--      <span class="icon-arrow_backward_2"></span> See All-->
    <!--    </a>-->

    <app-change-customize-bar-templates-select-resource
      [resourceType]="selectResourceType"
      [group]="selectResourceGroup"
      (selected)="addResource($event)"
    >
    </app-change-customize-bar-templates-select-resource>
  </ng-container>

  <ng-container *ngIf="!selectedItem && !selectResourceType">
    <app-sidebar-section>
      <div class="sidebar__input">
        <div class="input-icon-wrapper">
          <span class="input-icon input-icon_toolbar input-icon_left icon-search"></span>
          <input
            class="input input_toolbar input_background input_icon-left sidebar__input-inner"
            type="text"
            [placeholder]="'Search resources...'"
            [appAutofocus]="true"
            [(ngModel)]="search"
            (keyup)="searchUpdated.next()"
          />
        </div>
      </div>
    </app-sidebar-section>

    <app-sidebar-section *ngIf="installedItemsFiltered?.length" [title]="'Connected'">
      <div class="sidebar__list">
        <div class="sidebar-list">
          <div *ngFor="let item of installedItemsFiltered; let i = index" class="sidebar-list__item">
            <app-change-customize-bar-templates-item
              [item]="item"
              (open)="onItemClick(item)"
            ></app-change-customize-bar-templates-item>
          </div>
        </div>
      </div>
    </app-sidebar-section>

    <app-sidebar-section *ngIf="otherItemsFiltered?.length" [title]="'All Resources'">
      <div class="sidebar__list">
        <div class="sidebar-list">
          <div *ngFor="let item of otherItemsFiltered; let i = index" class="sidebar-list__item">
            <app-change-customize-bar-templates-item
              [item]="item"
              (open)="onItemClick(item)"
            ></app-change-customize-bar-templates-item>
          </div>
        </div>
      </div>
    </app-sidebar-section>

    <app-sidebar-section *ngIf="loading">
      <div class="sidebar__list">
        <div class="sidebar-list">
          <div
            *ngFor="let item of ['long item', 'longasdads item', 'longasdads item', 'medium', 'long item', 'item']"
            class="sidebar-list__item"
          >
            <div class="sidebar-list-item">
              <div class="sidebar-list-item__main">
                <div class="sidebar-list-item__title">
                  <span [class.loading-animation]="true"
                    ><span class="stub-text">{{ item }}</span></span
                  >
                </div>
                <div class="sidebar-list-item__description">
                  <span [class.loading-animation]="true"
                    ><span class="stub-text">{{ item }}</span></span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </app-sidebar-section>

    <div *ngIf="emptyLabel && !loading" class="sidebar__message">
      {{ emptyLabel }}
    </div>

    <app-sidebar-section>
      <div class="sidebar__element">
        <button type="button" class="button button_orange-alternative button_fill button_bigger" id="request_template">
          <span class="icon button__icon_left icon-magic_wand button__icon"></span>
          <span class="button__label">Suggest a Template</span>
          <span class="icon button__icon_right icon-arrow_forward_2 button__icon"></span>
        </button>
        <!--      <div class="sidebar-field__description">-->
        <!--        Let people discover and copy your app. List your app for free, or pick a price and sell it.-->
        <!--      </div>-->
      </div>
    </app-sidebar-section>
  </ng-container>
</div>

<span
  *ngIf="!editing"
  class="editable-content-label"
  [class.editable-content-label_inline]="inline"
  [class.editable-content-label_outline]="outline"
  [class.editable-content-label_cursor-pointer]="cursorPointer"
  (mousedown)="startEditing()"
  [class.editable-content-label_with-button]="pen || bin"
>
  {{ value || emptyValue }}
  <span
    *ngIf="pen && !bin"
    class="editable-content-button"
    [class.editable-content-button_hover]="penHover"
    [appTip]="penTitle"
    [appTipOptions]="{ side: 'top' }"
  >
    <span class="icon icon-pen"></span>
  </span>

  <span
    *ngIf="bin && !pen"
    class="editable-content-button"
    style="cursor: pointer;"
    (click)="onDelete($event)"
    (mousedown)="$event.stopPropagation()"
  >
    <span class="icon icon-bin"></span>
  </span>
</span>

<ng-container *ngIf="editing">
  <textarea
    *ngIf="multiline"
    class="editable-content-input"
    [class.editable-content-input_inline]="inline"
    [class.editable-content-input_outline]="activeTextColor"
    [class.editable-content-input_outline-background]="outline"
    [placeholder]="placeholder"
    [cdkTextareaAutosize]="autogrow"
    [appAutofocus]="true"
    [(ngModel)]="currentValue"
    (keyup)="onKeyUp($event)"
    (keypress)="onKeyPress($event)"
    (blur)="finishEditing()"
  ></textarea>
  <input
    *ngIf="!multiline"
    class="editable-content-input"
    [class.editable-content-input_inline]="inline"
    [class.editable-content-input_outline]="activeTextColor"
    [class.editable-content-input_outline-background]="outline"
    [style.width]="autogrow ? (currentValue.length >= 5 ? currentValue.length : 5) + 'ch' : 'auto'"
    [placeholder]="placeholder"
    [appAutofocus]="true"
    [(ngModel)]="currentValue"
    (keyup)="onKeyUp($event)"
    (keypress)="onKeyPress($event)"
    (blur)="finishEditing()"
  />
</ng-container>

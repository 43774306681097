import toPairs from 'lodash/toPairs';

import { RawListViewSettingsColumn } from '@modules/customize';
import { getFieldDescriptionByType } from '@modules/fields';
import { filterItemsFromQueryParams, matchFilterItem } from '@modules/filter-utils';
import { ORDER_BY_PARAM, PAGE_PARAM, PER_PAGE_PARAM, SEARCH_PARAM } from '@modules/models';
import { ModelResponse } from '@modules/resources';
import { isSet, objectsSortPredicate } from '@shared';

export function applyFrontendFiltering(resultBody: Object[], params: Object, columns: RawListViewSettingsColumn[]) {
  const filterItems = filterItemsFromQueryParams(params);
  let search = params[SEARCH_PARAM];
  const orderBy = params[ORDER_BY_PARAM];

  resultBody = resultBody.filter(item => {
    return filterItems.every(filterItem => {
      const column = columns.find(i => i.name == filterItem.field);

      if (!column) {
        return true;
      }

      const fieldValue = item[column.name];
      return matchFilterItem(column, fieldValue, filterItem);
    });
  });

  if (isSet(search)) {
    search = String(search).toLowerCase();

    resultBody = resultBody.filter(item => {
      return toPairs(item).some(([name, value]) => {
        const field = columns.find(i => i.name == name);

        if (!field) {
          return false;
        }

        const fieldDescription = getFieldDescriptionByType(field.field);

        if (fieldDescription.deserializeValue) {
          value = fieldDescription.deserializeValue(value, field);
        }

        if (fieldDescription.valueToStr) {
          value = fieldDescription.valueToStr(value, { field: field });
        }

        return String(value).toLowerCase().includes(search);
      });
    });
  }

  if (isSet(orderBy)) {
    resultBody = resultBody.sort(objectsSortPredicate(orderBy));
  }

  return resultBody;
}

export function applyFrontendPagination(response: ModelResponse.GetResponse, params: Object, paginate: boolean) {
  const page = params[PAGE_PARAM] || 1;
  const perPage = params[PER_PAGE_PARAM] || 20;
  const from = (page - 1) * perPage;
  const to = page * perPage - 1;

  response.count = response.results.length;
  response.hasMore = response.count - 1 > to;
  response.perPage = perPage;

  if (paginate) {
    response.results = response.results.slice(from, to + 1);
  }
}

import { CdkOverlayOrigin } from '@angular/cdk/overlay';
import { AfterViewInit, ChangeDetectorRef, ElementRef, EventEmitter, NgZone, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import last from 'lodash/last';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest, fromEvent, merge, of, ReplaySubject, Subject } from 'rxjs';
import { debounceTime, filter, first, map, switchMap, take, takeUntil } from 'rxjs/operators';
import { ViewContext, ViewContextElement } from '@modules/customize';
import { mathjs, mathJsIsSingleToken } from '@modules/fields';
import { contextToFormulaValue, singleTokenFormulaToContextValue, transformFormulaElementAccessors } from '@modules/parameters';
import { ascComparator, cleanWrapSpaces, controlValue, isSet, KeyboardEventKeyCode, toggleClass } from '@shared';
import { viewContextTokenProviderFunctions } from '../../services/view-context-token-provider/view-context-token-provider-functions.stub';
import { getRootFormulaToken, renderFormulaElementDescriptors, renderFormulaNode } from '../../utils/formula';
import { InputTokenType } from '../input-edit/input-token';
function getElementDepth(el, relativeTo) {
    var depth = 0;
    while (el.parentElement && (!relativeTo || el.parentElement !== relativeTo)) {
        el = el.parentElement;
        depth++;
    }
    return depth;
}
function serializeSelection(selection) {
    if (selection.type == 'None') {
        return;
    }
    return {
        type: selection.type,
        anchorNode: selection.anchorNode,
        anchorOffset: selection.anchorOffset,
        baseNode: selection.baseNode,
        baseOffset: selection.baseOffset,
        extentNode: selection.extentNode,
        extentOffset: selection.extentOffset,
        focusNode: selection.focusNode,
        focusOffset: selection.focusOffset,
        rangeCount: selection.rangeCount,
        range: selection.rangeCount ? selection.getRangeAt(0) : undefined
    };
}
var InputEditFormulaValueComponent = /** @class */ (function () {
    function InputEditFormulaValueComponent(zone, cd) {
        this.zone = zone;
        this.cd = cd;
        this.contextValueEnabled = true;
        this.focusedInitial = false;
        this.placeholder = 'Formula';
        this.extraSections = [];
        this.fill = false;
        this.fillVertical = false;
        this.inline = false;
        this.small = false;
        this.popoverOpened = new EventEmitter();
        this.switchToContext = new EventEmitter();
        this.switchToTextInputs = new EventEmitter();
        this.switchToJs = new EventEmitter();
        this.switchToEmptyString = new EventEmitter();
        this.switchToNull = new EventEmitter();
        this.switchToPrompt = new EventEmitter();
        this.switchToFieldFilter = new EventEmitter();
        this.popoverOpened$ = new BehaviorSubject(false);
        this.focus$ = new BehaviorSubject(false);
        this.hoverElements$ = new BehaviorSubject([]);
        this.hoverLastElement$ = this.hoverElements$.pipe(map(function (value) { return last(value); }));
        this.hoverFunction$ = this.hoverLastElement$.pipe(map(function (value) {
            if (value && value.node.isFunctionNode) {
                return value.node.name.toUpperCase();
            }
        }));
        this.activeElements$ = new BehaviorSubject([]);
        this.activeLastElement$ = this.activeElements$.pipe(map(function (value) { return last(value); }));
        this.activeFunction$ = this.activeLastElement$.pipe(map(function (value) {
            if (value && value.node.isFunctionNode) {
                return value.node.name.toUpperCase();
            }
        }));
        this.saveValue$ = new Subject();
        this.inputSelection$ = new BehaviorSubject(undefined);
        this.inputSelectionLast$ = new BehaviorSubject(undefined);
        this.search$ = new BehaviorSubject('');
        this.empty = false;
        this.history = [];
        this.historyIndex = -1;
        this.historyChange = false;
    }
    InputEditFormulaValueComponent.prototype.ngOnInit = function () { };
    InputEditFormulaValueComponent.prototype.ngOnDestroy = function () {
        if (this.destroyed$) {
            this.destroyed$.next();
            this.destroyed$ = undefined;
        }
    };
    InputEditFormulaValueComponent.prototype.ngAfterViewInit = function () {
        this.init();
    };
    InputEditFormulaValueComponent.prototype.init = function () {
        var _this = this;
        merge(merge(fromEvent(this.inputElement.nativeElement, 'input'), fromEvent(this.inputElement.nativeElement, 'textinput')).pipe(map(function (e) { return ({ addText: e.data }); }), debounceTime(60)), this.saveValue$.pipe(map(function () { return ({ forceSave: true }); })))
            .pipe(untilDestroyed(this))
            .subscribe(function (e) {
            var selection = serializeSelection(window.getSelection());
            var input = _this.inputElement.nativeElement;
            var inputSelection = selection && selection.type == 'Caret' && input.contains(selection.baseNode) ? selection : undefined;
            var savedCaretPosition;
            if (e.addText == '(' &&
                inputSelection &&
                inputSelection.baseNode &&
                inputSelection.baseOffset == inputSelection.baseNode.textContent.length &&
                inputSelection.baseNode.parentElement.classList.contains('formula-symbol') &&
                inputSelection.baseNode.parentElement.innerText.match(/^\w+\($/)) {
                var root = inputSelection.baseNode.parentElement;
                var match_1 = root.innerText.match(/^(\w+)\($/);
                if (match_1) {
                    savedCaretPosition = _this.getCaretPosition();
                    root.innerText = root.innerText.toUpperCase() + ')';
                    var func = viewContextTokenProviderFunctions.find(function (item) { return item.function.name == match_1[1].toUpperCase(); });
                    if (func && (!func.function.arguments || !func.function.arguments.length)) {
                        savedCaretPosition += 1;
                    }
                }
                _this.search$.next('');
            }
            else if (isSet(e.addText) &&
                inputSelection &&
                inputSelection.baseNode &&
                inputSelection.baseOffset == inputSelection.baseNode.textContent.length &&
                inputSelection.baseNode.parentElement.classList.contains('formula-symbol') &&
                inputSelection.baseNode.parentElement.innerText.match(/^\w+$/)) {
                _this.search$.next(inputSelection.baseNode.parentElement.innerText);
            }
            else {
                _this.search$.next('');
            }
            _this.formulaSaveError = undefined;
            _this.savedCaretPosition = undefined;
            _this.cd.markForCheck();
            var formulaValue = input.innerText;
            try {
                var rootNode = isSet(formulaValue.trim()) ? _this.parseFormula(formulaValue) : undefined;
                var cleanFormulaValue = rootNode ? rootNode.toString() : undefined;
                if (cleanFormulaValue != _this.control.value || e.forceSave) {
                    _this.savedCaretPosition = savedCaretPosition;
                    _this.formulaSaveValue = formulaValue;
                    _this.control.patchValue(cleanFormulaValue);
                }
            }
            catch (e) {
                console.error('Save failed', e.message);
                var match = /^(\w+:\s)?(.+)(?:\s\(char\s(\d+)\))$/.exec(String(e));
                if (match) {
                    _this.formulaSaveError = "Position " + match[3] + ": " + match[2];
                }
                else {
                    _this.formulaSaveError = String(e);
                }
                _this.cd.markForCheck();
            }
        });
        merge(of(undefined), fromEvent(document, 'selectionchange'))
            .pipe(untilDestroyed(this))
            .subscribe(function () {
            var selection = serializeSelection(window.getSelection());
            var input = _this.inputElement.nativeElement;
            var inputSelection = selection && input.contains(selection.baseNode) ? selection : undefined;
            _this.inputSelection$.next(inputSelection);
            if (inputSelection) {
                _this.inputSelectionLast$.next(inputSelection);
            }
        });
        controlValue(this.control)
            .pipe(map(function (value, index) { return [value, index]; }), untilDestroyed(this))
            .subscribe(function (_a) {
            var formulaValue = _a[0], index = _a[1];
            _this.empty = formulaValue === undefined || (typeof formulaValue === 'string' && formulaValue.trim() === '');
            _this.formulaValueError = undefined;
            _this.formulaSaveError = undefined;
            _this.cd.markForCheck();
            try {
                var rootNode = void 0;
                try {
                    rootNode = !_this.empty ? _this.parseFormula(formulaValue) : undefined;
                }
                catch (e) {
                    var match = /^(\w+:\s)?(.+)(?:\s\(char\s(\d+)\))$/.exec(String(e));
                    if (match) {
                        _this.formulaValueError = "Position " + match[3] + ": " + match[2];
                    }
                    else {
                        _this.formulaValueError = String(e);
                    }
                    _this.cd.markForCheck();
                    return;
                }
                var destroyed$ = new ReplaySubject(1);
                var element = rootNode ? _this.renderNode(rootNode, destroyed$) : undefined;
                var position = isSet(_this.savedCaretPosition) ? _this.savedCaretPosition : _this.getCaretPosition();
                _this.savedCaretPosition = undefined;
                if (_this.destroyed$) {
                    _this.destroyed$.next();
                    _this.destroyed$ = undefined;
                }
                _this.hoverElements$.next([]);
                _this.activeElements$.next([]);
                renderFormulaElementDescriptors(_this.inputElement.nativeElement, element ? [element] : []);
                var formulaActualValue = _this.inputElement.nativeElement.innerText;
                if (isSet(_this.formulaSaveValue) && formulaActualValue.length < _this.formulaSaveValue.length) {
                    var afterCaret = _this.formulaSaveValue.slice(position);
                    if (formulaActualValue.endsWith(afterCaret)) {
                        position = formulaActualValue.length - afterCaret.length;
                    }
                }
                _this.destroyed$ = destroyed$;
                _this.formulaSaveValue = undefined;
                if (_this.inputElement.nativeElement === document.activeElement && isSet(position)) {
                    _this.setCaretPosition(position);
                }
            }
            catch (e) {
                console.error('Parse failed', e.message);
            }
            if (index == 0 && _this.focusedInitial) {
                _this.focusValueOnStable();
                _this.focusedInitial = false;
            }
        });
        controlValue(this.control, { debounce: 200 })
            .pipe(untilDestroyed(this))
            .subscribe(function () {
            if (_this.historyChange) {
                _this.historyChange = false;
                return;
            }
            _this.saveHistory();
        });
        this.focus$
            .pipe(switchMap(function (focus) {
            if (focus) {
                return fromEvent(document, 'keydown');
            }
            else {
                return of(undefined);
            }
        }), filter(function (e) { return e; }), untilDestroyed(this))
            .subscribe(function (e) {
            if ((e.metaKey || e.ctrlKey) && !e.shiftKey && e.keyCode == KeyboardEventKeyCode.Z) {
                e.preventDefault();
                e.stopPropagation();
                _this.undoHistory();
            }
            else if ((e.metaKey || e.ctrlKey) && e.shiftKey && e.keyCode == KeyboardEventKeyCode.Z) {
                e.preventDefault();
                e.stopPropagation();
                _this.redoHistory();
            }
        });
        fromEvent(this.inputElement.nativeElement, 'paste')
            .pipe(untilDestroyed(this))
            .subscribe(function (e) {
            e.preventDefault();
            var text = e.clipboardData.getData('text/plain');
            document.execCommand('insertText', false, text);
        });
    };
    InputEditFormulaValueComponent.prototype.getCaretPosition = function () {
        var selection = window.getSelection();
        if (!selection.rangeCount) {
            return;
        }
        var range = selection.getRangeAt(0).cloneRange();
        range.setStart(this.inputElement.nativeElement, 0);
        return range.toString().length;
    };
    InputEditFormulaValueComponent.prototype.setCaretPosition = function (index) {
        var selection = window.getSelection();
        var position = this.getTextNodeAtPosition(this.inputElement.nativeElement, index);
        var range = new Range();
        range.setStart(position.node, position.position);
        selection.removeAllRanges();
        selection.addRange(range);
    };
    InputEditFormulaValueComponent.prototype.moveCaretToEnd = function () {
        var selection = window.getSelection();
        var range = new Range();
        range.setStart(this.inputElement.nativeElement, this.inputElement.nativeElement.childNodes.length);
        selection.removeAllRanges();
        selection.addRange(range);
    };
    InputEditFormulaValueComponent.prototype.focus = function () {
        this.inputElement.nativeElement.focus();
        this.moveCaretToEnd();
    };
    InputEditFormulaValueComponent.prototype.focusValueOnStable = function () {
        var _this = this;
        this.zone.onStable
            .pipe(take(1))
            .pipe(untilDestroyed(this))
            .subscribe(function () { return _this.focus(); });
    };
    InputEditFormulaValueComponent.prototype.getTextNodeAtPosition = function (root, index) {
        var NODE_TYPE = NodeFilter.SHOW_TEXT;
        var treeWalker = document.createTreeWalker(root, NODE_TYPE, {
            acceptNode: function (elem) {
                if (index > elem.textContent.length) {
                    index -= elem.textContent.length;
                    return NodeFilter.FILTER_REJECT;
                }
                return NodeFilter.FILTER_ACCEPT;
            }
        });
        var c = treeWalker.nextNode();
        return {
            node: c ? c : root,
            position: index
        };
    };
    InputEditFormulaValueComponent.prototype.parseFormula = function (formulaValue) {
        return mathjs.parse(cleanWrapSpaces(formulaValue)).transform(function (node) {
            if (node.isFunctionNode) {
                var name_1 = node.name.toUpperCase();
                var func = viewContextTokenProviderFunctions.find(function (item) { return item.function.name == name_1; });
                if (!func) {
                    throw new Error("Unknown function \"" + name_1 + "\"");
                }
            }
            // if (node['isIndexNode']) {
            //   const dimension = node.dimensions[0];
            //
            //   if (!node.isObjectProperty() && dimension && typeof dimension.value == 'number') {
            //     dimension.value = dimension.value + 1;
            //   }
            // }
            return node;
        });
    };
    InputEditFormulaValueComponent.prototype.addHoverElement = function (node, element) {
        var value = this.hoverElements$.value;
        if (!value.find(function (item) { return item.element === element; })) {
            this.hoverElements$.next(value.concat([{ node: node, element: element }]));
        }
    };
    InputEditFormulaValueComponent.prototype.removeHoverElementByElement = function (element) {
        var value = this.hoverElements$.value;
        if (value.find(function (item) { return item.element === element; })) {
            this.hoverElements$.next(value.filter(function (item) { return item.element !== element; }));
        }
    };
    InputEditFormulaValueComponent.prototype.addActiveElement = function (node, element) {
        var _this = this;
        var value = this.activeElements$.value;
        if (!value.find(function (item) { return item.element === element; })) {
            this.activeElements$.next(value.concat([{ node: node, element: element }]).sort(function (lhs, rhs) {
                var lhsDepth = getElementDepth(lhs.element, _this.inputElement.nativeElement);
                var rhsDepth = getElementDepth(rhs.element, _this.inputElement.nativeElement);
                return ascComparator(lhsDepth, rhsDepth);
            }));
        }
    };
    InputEditFormulaValueComponent.prototype.removeActiveElementByElement = function (element) {
        var value = this.activeElements$.value;
        if (value.find(function (item) { return item.element === element; })) {
            this.activeElements$.next(value.filter(function (item) { return item.element !== element; }));
        }
    };
    InputEditFormulaValueComponent.prototype.renderNode = function (rootNode, destroyed$) {
        var _this = this;
        return renderFormulaNode(rootNode, {
            setupDescriptor: function (descriptor, node) {
                if (descriptor.classes.has('formula-func')) {
                    descriptor.onCreate(function (root) {
                        fromEvent(root, 'mouseenter')
                            .pipe(takeUntil(destroyed$))
                            .subscribe(function () { return _this.addHoverElement(node, root); });
                        fromEvent(root, 'mouseleave')
                            .pipe(takeUntil(destroyed$))
                            .subscribe(function () { return _this.removeHoverElementByElement(root); });
                        combineLatest(_this.inputSelection$, _this.inputSelectionLast$, _this.popoverOpened$)
                            .pipe(takeUntil(destroyed$))
                            .subscribe(function (_a) {
                            var selection = _a[0], selectionLast = _a[1], popoverOpened = _a[2];
                            if (!selection && popoverOpened) {
                                selection = selectionLast;
                            }
                            if (selection && root.contains(selection.baseNode) && !selection.baseNode.textContent.startsWith(')')) {
                                _this.addActiveElement(node, root);
                            }
                            else {
                                _this.removeActiveElementByElement(root);
                            }
                        });
                        combineLatest(_this.hoverLastElement$, _this.activeLastElement$)
                            .pipe(takeUntil(destroyed$))
                            .subscribe(function (_a) {
                            var hover = _a[0], active = _a[1];
                            toggleClass(root, 'formula-func_hover', hover && hover.element === root);
                            toggleClass(root, 'formula-func_active', !hover && active && active.element === root);
                        });
                    });
                }
            }
        });
    };
    Object.defineProperty(InputEditFormulaValueComponent.prototype, "formulaEffectiveError", {
        get: function () {
            return isSet(this.formulaSaveError) ? this.formulaSaveError : this.formulaValueError;
        },
        enumerable: true,
        configurable: true
    });
    InputEditFormulaValueComponent.prototype.saveHistory = function () {
        this.history = this.history.slice(0, this.historyIndex + 1).slice(-50).concat([
            {
                value: this.control.value,
                position: this.getCaretPosition()
            }
        ]);
        this.historyIndex = this.history.length - 1;
    };
    InputEditFormulaValueComponent.prototype.moveHistory = function (delta) {
        var newIndex = this.historyIndex + delta;
        var newItem = this.history[newIndex];
        if (!newItem) {
            return;
        }
        this.historyIndex = newIndex;
        this.historyChange = true;
        this.control.patchValue(newItem.value);
        try {
            this.setCaretPosition(newItem.position);
        }
        catch (e) { }
    };
    InputEditFormulaValueComponent.prototype.undoHistory = function () {
        this.moveHistory(-1);
    };
    InputEditFormulaValueComponent.prototype.redoHistory = function () {
        this.moveHistory(1);
    };
    InputEditFormulaValueComponent.prototype.insertFormulaToken = function (item) {
        var insert;
        if (item.formula) {
            insert = item.formula;
        }
        else if (isSet(item.caretIndex)) {
            insert = contextToFormulaValue(item.insert) + "()";
        }
        else {
            insert = contextToFormulaValue(item.insert);
        }
        var caretIndex = isSet(item.caretIndex) ? item.caretIndex : insert.length;
        var selection = this.inputSelectionLast$.value;
        if (this.contextValueEnabled &&
            !isSet(this.inputElement.nativeElement.textContent) &&
            !item.caretIndex &&
            !isSet(item.formula)) {
            var toExternalValue = transformFormulaElementAccessors(insert, this.context, false);
            this.switchToContext.emit(toExternalValue);
        }
        else if (selection) {
            var selectionNode = void 0;
            var selectionIndex = void 0;
            if (selection.type == 'Range' && selection.baseNode === selection.extentNode) {
                var _a = [selection.baseOffset, selection.extentOffset].sort(), start = _a[0], end = _a[1];
                selection.baseNode.textContent = [
                    selection.baseNode.textContent.slice(0, start),
                    insert,
                    selection.baseNode.textContent.slice(end)
                ].join('');
                selectionIndex = start + caretIndex;
            }
            else {
                var textContent = selection.baseNode.textContent;
                var symbolSuffix = isSet(textContent) ? textContent.match(/\w+$/) : undefined;
                var formulaToken = symbolSuffix && selection.baseNode.parentElement
                    ? getRootFormulaToken(selection.baseNode.parentElement)
                    : undefined;
                if (formulaToken) {
                    formulaToken.textContent = insert;
                    selectionNode = formulaToken.childNodes[0];
                    selectionIndex = caretIndex;
                }
                else if (symbolSuffix) {
                    var prefix = textContent.slice(0, textContent.length - symbolSuffix[0].length);
                    selection.baseNode.textContent = prefix + insert;
                    selectionIndex = prefix.length + caretIndex;
                }
                else {
                    selection.baseNode.textContent = textContent + insert;
                    selectionIndex = textContent.length + caretIndex;
                }
            }
            var newSelection = document.getSelection();
            var range = new Range();
            if (!selectionNode) {
                selectionNode =
                    selection.baseNode.nodeType != Node.TEXT_NODE ? selection.baseNode.childNodes[0] : selection.baseNode;
            }
            range.setStart(selectionNode, selectionIndex);
            newSelection.removeAllRanges();
            newSelection.addRange(range);
            this.saveValue$.next();
        }
        else {
            var node = document.createTextNode(insert);
            this.inputElement.nativeElement.appendChild(node);
            var newSelection = document.getSelection();
            var range = new Range();
            range.setStart(node, selection && isSet(selection.baseOffset) ? selection.baseOffset + caretIndex : 0);
            newSelection.removeAllRanges();
            newSelection.addRange(range);
            this.saveValue$.next();
        }
    };
    InputEditFormulaValueComponent.prototype.onKeydown = function (e) {
        if (e.keyCode == KeyboardEventKeyCode.Enter) {
            e.preventDefault();
        }
    };
    InputEditFormulaValueComponent.prototype.onFocus = function () {
        this.inputSelectionLast$.next(undefined);
    };
    InputEditFormulaValueComponent.prototype.onTokenSelected = function (item) {
        this.search$.next('');
        if (isSet(item.insert) || isSet(item.formula)) {
            this.insertFormulaToken(item);
        }
        else if (item.token[0] == InputTokenType.TextInputs) {
            this.switchToTextInputs.emit();
        }
        else if (item.token[0] == InputTokenType.Js) {
            this.switchToJs.emit();
        }
        else if (item.token[0] == InputTokenType.EmptyString) {
            this.switchToEmptyString.emit();
        }
        else if (item.token[0] == InputTokenType.Null) {
            this.switchToNull.emit();
        }
        else if (item.token[0] == InputTokenType.Prompt) {
            this.switchToPrompt.emit();
        }
        else if (item.token[0] == InputTokenType.Filter) {
            var filterItemData = item.data;
            this.switchToFieldFilter.emit(filterItemData);
        }
    };
    InputEditFormulaValueComponent.prototype.checkIfContextValue = function () {
        var _this = this;
        if (!this.contextValueEnabled || !this.context) {
            return;
        }
        var formulaValueHuman = this.control.value;
        var formulaValue = transformFormulaElementAccessors(formulaValueHuman, this.context, false);
        try {
            var rootNode = mathjs.parse(cleanWrapSpaces(formulaValue));
            if (!mathJsIsSingleToken(rootNode)) {
                return;
            }
        }
        catch (e) {
            return;
        }
        var contextValue = singleTokenFormulaToContextValue(formulaValue);
        this.context
            .tokenPath$(contextValue, this.contextElement, this.contextElementPath, this.contextElementPaths)
            .pipe(first(), untilDestroyed(this))
            .subscribe(function (tokenPath) {
            if (!tokenPath) {
                return;
            }
            _this.switchToContext.emit(formulaValue);
        });
    };
    return InputEditFormulaValueComponent;
}());
export { InputEditFormulaValueComponent };

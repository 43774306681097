import { combineLatest, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { applyBooleanInput$, applyParamInput$, Input } from '@modules/fields';
import { isSet } from '@shared';
import { Color } from './color';
export var BorderStyle;
(function (BorderStyle) {
    BorderStyle["Solid"] = "solid";
    BorderStyle["Dashed"] = "dashed";
    BorderStyle["Dotted"] = "dotted";
    BorderStyle["Double"] = "Double";
    BorderStyle["Groove"] = "groove";
    BorderStyle["Ridge"] = "ridge";
    BorderStyle["Inset"] = "inset";
    BorderStyle["Outset"] = "outset";
})(BorderStyle || (BorderStyle = {}));
export var BorderPosition;
(function (BorderPosition) {
    BorderPosition["Inside"] = "inside";
    BorderPosition["Center"] = "center";
    BorderPosition["Outside"] = "outside";
})(BorderPosition || (BorderPosition = {}));
var Border = /** @class */ (function () {
    function Border(options) {
        if (options === void 0) { options = {}; }
        this.thickness = 1;
        this.style = BorderStyle.Solid;
        this.position = BorderPosition.Inside;
        this.enabled = true;
        Object.assign(this, options);
    }
    Border.prototype.deserialize = function (data) {
        if (isSet(data['thickness'])) {
            this.thickness = data['thickness'];
        }
        if (isSet(data['style'])) {
            this.style = data['style'];
        }
        if (isSet(data['position'])) {
            this.position = data['position'];
        }
        if (data['color']) {
            this.color = new Color().deserialize(data['color']);
        }
        if (data['color_input']) {
            this.colorInput = new Input().deserialize(data['color_input']);
        }
        if (isSet(data['enabled'])) {
            this.enabled = data['enabled'];
        }
        if (data['enabled_input']) {
            this.enabledInput = new Input().deserialize(data['enabled_input']);
        }
        return this;
    };
    Border.prototype.serialize = function () {
        return {
            color: this.color ? this.color.serialize() : undefined,
            color_input: this.colorInput ? this.colorInput.serialize() : null,
            thickness: this.thickness,
            style: this.style,
            position: this.position,
            enabled: this.enabled,
            enabled_input: this.enabledInput ? this.enabledInput.serialize() : null
        };
    };
    Border.prototype.cssColor$ = function (options) {
        if (options === void 0) { options = {}; }
        if (this.colorInput) {
            return applyParamInput$(this.colorInput, {
                context: options.context,
                contextElement: options.contextElement,
                localContext: options.localContext,
                defaultValue: ''
            });
        }
        else if (this.color) {
            return of(this.color.css());
        }
    };
    Border.prototype.cssBorder$ = function (options) {
        var _this = this;
        if (options === void 0) { options = {}; }
        return combineLatest(this.cssColor$(options)).pipe(map(function (_a) {
            var color = _a[0];
            var result = [_this.thickness + "px", _this.style];
            if (isSet(color)) {
                result.push(color);
            }
            return result.join(' ');
        }));
    };
    Border.prototype.cssTextStroke$ = function (options) {
        var _this = this;
        if (options === void 0) { options = {}; }
        return combineLatest(this.cssColor$(options)).pipe(map(function (_a) {
            var color = _a[0];
            var result = [_this.thickness + "px"];
            if (isSet(color)) {
                result.push(color);
            }
            return result.join(' ');
        }));
    };
    Border.prototype.enabled$ = function (options) {
        if (options === void 0) { options = {}; }
        if (this.enabledInput) {
            return applyBooleanInput$(this.enabledInput, {
                context: options.context,
                contextElement: options.contextElement,
                localContext: options.localContext
            }).pipe(catchError(function () { return of(false); }));
        }
        else {
            return of(true);
        }
    };
    return Border;
}());
export { Border };

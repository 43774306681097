/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./pagination.component";
var styles_PaginationComponent = [];
var RenderType_PaginationComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_PaginationComponent, data: {} });
export { RenderType_PaginationComponent as RenderType_PaginationComponent };
function View_PaginationComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "a", [["class", "pagination__pages-item"], ["href", "javascript:void(0)"]], [[2, "pagination__pages-item_selected", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openPage(_v.parent.context.$implicit.page) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.current == _v.parent.context.$implicit.page); _ck(_v, 0, 0, currVal_0); var currVal_1 = _v.parent.context.$implicit.page; _ck(_v, 1, 0, currVal_1); }); }
function View_PaginationComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [["class", "pagination__pages-item pagination__pages-item_disabled"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" ... "]))], null, null); }
function View_PaginationComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PaginationComponent_2)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PaginationComponent_3)), i0.ɵdid(4, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = (_v.context.$implicit.type == "page"); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_v.context.$implicit.type == "separator"); _ck(_v, 4, 0, currVal_1); }, null); }
export function View_PaginationComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [["class", "pagination"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "a", [["class", "pagination__control icon-arrow_backward_2"], ["href", "javascript:void(0)"]], [[2, "pagination__control_disabled", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openPage(_co.prevPage) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 2, "div", [["class", "pagination__pages"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PaginationComponent_1)), i0.ɵdid(4, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"], ngForTrackBy: [1, "ngForTrackBy"] }, null), (_l()(), i0.ɵeld(5, 0, null, null, 0, "a", [["class", "pagination__control icon-arrow_forward_2"], ["href", "javascript:void(0)"]], [[2, "pagination__control_disabled", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openPage(_co.nextPage) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.items; var currVal_2 = _co.trackByFn; _ck(_v, 4, 0, currVal_1, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.prevPage; _ck(_v, 1, 0, currVal_0); var currVal_3 = !_co.nextPage; _ck(_v, 5, 0, currVal_3); }); }
export function View_PaginationComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-pagination", [], null, null, null, View_PaginationComponent_0, RenderType_PaginationComponent)), i0.ɵdid(1, 638976, null, 0, i2.PaginationComponent, [i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PaginationComponentNgFactory = i0.ɵccf("app-pagination", i2.PaginationComponent, View_PaginationComponent_Host_0, { current: "current", hasMore: "hasMore", lastLoaded: "lastLoaded", total: "total" }, { pageSelected: "pageSelected" }, []);
export { PaginationComponentNgFactory as PaginationComponentNgFactory };

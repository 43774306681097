import * as moment from 'moment';

import { localize } from '@common/localize';
import { isSet } from '@shared';

import { BetweenFieldLookup } from './between';

export class IsLastWeekFieldLookup extends BetweenFieldLookup {
  public lookup = 'is_last_week';
  public param = 'isLastWeek';
  public verboseName = localize('is Last Week');
  public icon = 'calendar';

  from(value: string): string {
    return moment().subtract(1, 'week').toISOString();
  }

  to(value: string): string {
    return moment().toISOString();
  }

  str(field?: string, value?: string, exclude?: boolean): string {
    if (exclude) {
      if (isSet(field) && isSet(value)) {
        return localize('{0} is not last week', [field]);
      } else {
        return localize('is not last week');
      }
    } else {
      if (isSet(field) && isSet(value)) {
        return localize('{0} is last week', [field]);
      } else {
        return localize('last week');
      }
    }
  }
}

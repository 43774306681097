import { AgmCoreModule } from '@agm/core';
import { AgmJsMarkerClustererModule } from '@agm/js-marker-clusterer';
import { AgmSnazzyInfoWindowModule } from '@agm/snazzy-info-window';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgGxScrollableModule } from 'ng-gxscrollable';

import { DialogsModule } from '@common/dialogs';
import { TipsModule } from '@common/tips';
import { AnalyticsModule } from '@modules/analytics';
import { DemoModule } from '@modules/demo';
import { ExportModule } from '@modules/export';
import { FieldComponentsModule } from '@modules/field-components';
import { FieldsModule } from '@modules/fields';
import { ListComponentsModule } from '@modules/list-components';
import { ModelQueriesModule } from '@modules/model-queries';
import { RoutingModule } from '@modules/routing';
import { ViewsComponentsModule } from '@modules/views-components';
import { SharedModule } from '@shared';

import { MapMarkerComponent } from './components/map-marker/map-marker.component';
import { MapPopupComponent } from './components/map-popup/map-popup.component';
import { MapComponent } from './components/map/map.component';

@NgModule({
  imports: [
    CommonModule,
    RoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgGxScrollableModule,
    SharedModule,
    DemoModule,
    ListComponentsModule,
    FieldsModule,
    FieldComponentsModule,
    TipsModule,
    DialogsModule,
    ModelQueriesModule,
    ExportModule,
    AnalyticsModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyAtLlbTV72olE2Ofc1Jrmg9l1Weu_GSluc'
    }),
    AgmJsMarkerClustererModule,
    AgmSnazzyInfoWindowModule,
    ViewsComponentsModule
  ],
  declarations: [MapComponent, MapPopupComponent, MapMarkerComponent],
  exports: [MapComponent]
})
export class MapModule {}

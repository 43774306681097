import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import fromPairs from 'lodash/fromPairs';
import keys from 'lodash/keys';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Subscription } from 'rxjs';

import {
  FieldName,
  LinkButton,
  ListLayoutSettings,
  ListViewSettings,
  ViewContext,
  ViewContextElement
} from '@modules/customize';
import { ListModelDescriptionDataSource } from '@modules/data-sources';
import { applyParamInput$, InputValueType, ParameterField } from '@modules/fields';
import { Input as FieldInput } from '@modules/fields';
import { FilterItem2, Segment } from '@modules/filters';

@Component({
  selector: 'app-list-header',
  templateUrl: './list-header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ListHeaderComponent implements OnInit, OnDestroy, OnChanges {
  @Input() noBottomMargin = false;
  @Input() params: Object;
  @Input() filtersEnabled = false;
  @Input() filters: FilterItem2[] = [];
  @Input() filterFields: FieldName[] = [];
  @Input() hideParams: string[] = [];
  @Input() pageSettings: ListViewSettings;
  @Input() settings: ListLayoutSettings;
  @Input() dataSource: ListModelDescriptionDataSource;
  @Input() inputs: FieldInput[] = [];
  @Input() parameters: ParameterField[] = [];
  @Input() moreLink: LinkButton;
  @Input() focus = false;
  @Input() searchEnabled = false;
  @Input() search: string;
  @Input() searchLive = false;
  @Input() createSegmentAllowed = false;
  @Input() viewId: string;
  @Input() context: ViewContext;
  @Input() contextElement: ViewContextElement;
  @Input() accentColor: string;
  @Output() paramsChanged = new EventEmitter<Object>();
  @Output() filtersUpdated = new EventEmitter<FilterItem2[]>();
  @Output() searchUpdated = new EventEmitter<string>();
  @Output() layoutAdd = new EventEmitter<void>();
  @Output() layoutCustomize = new EventEmitter<number>();
  @Output() segmentCreated = new EventEmitter<Segment>();
  @Output() updateData = new EventEmitter<any>();
  @Output() customizeModelDescription = new EventEmitter<any>();
  @Output() customizeViewSettings = new EventEmitter<any>();

  @ViewChild('main_element') mainElement: ElementRef;

  hasFilterableColumns = false;
  title: string;
  titleSubscription: Subscription;
  compactActions = false;

  constructor(private cd: ChangeDetectorRef) {}

  ngOnInit() {}

  ngOnDestroy(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['settings']) {
      this.initTitle();
    }

    if (changes['inputs'] || changes['settings'] || changes['dataSource']) {
      this.updateFilterableColumns();
    }
  }

  initTitle() {
    if (this.titleSubscription) {
      this.titleSubscription.unsubscribe();
      this.titleSubscription = undefined;
    }

    if (!this.settings.titleInput) {
      this.title = undefined;
      this.cd.detectChanges();
      this.updateCompact();
      return;
    }

    this.titleSubscription = applyParamInput$<string>(this.settings.titleInput, {
      context: this.context,
      contextElement: this.contextElement,
      defaultValue: ''
    })
      .pipe(untilDestroyed(this))
      .subscribe(value => {
        this.title = value;
        this.cd.detectChanges();
        this.updateCompact();
      });
  }

  updateFilterableColumns() {
    const filterableColumns = fromPairs(
      this.inputs
        .filter(item => item.valueType == InputValueType.Filter)
        .map(input => {
          if (!this.dataSource) {
            return;
          }
          return this.dataSource.columns.find(item => item.name == input.filterField);
        })
        .filter(item => item != undefined)
        .map(item => [item.name, item])
    );
    this.hasFilterableColumns = keys(filterableColumns).length != 0;
    this.cd.markForCheck();
  }

  resetParams() {
    this.paramsChanged.emit({});
  }

  isActionsFit() {
    const titleElement = this.mainElement.nativeElement.children[0];
    return titleElement.scrollWidth <= titleElement.offsetWidth && titleElement.offsetWidth != 0;
  }

  updateCompact() {
    const newCompactActions = !this.isActionsFit();

    if (this.compactActions && !newCompactActions) {
      this.compactActions = newCompactActions;
      this.cd.detectChanges();

      if (!this.isActionsFit()) {
        this.compactActions = true;
        this.cd.detectChanges();
      }
    } else if (!this.compactActions && newCompactActions) {
      this.compactActions = newCompactActions;
      this.cd.markForCheck();
    }
  }
}

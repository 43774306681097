/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../routing/directives/app-link/app-link.directive";
import * as i2 from "../../../routing/services/routing/routing.service";
import * as i3 from "@angular/router";
import * as i4 from "@angular/common";
import * as i5 from "../../../../shared/pipes/safe-url/safe-url.pipe";
import * as i6 from "@angular/platform-browser";
import * as i7 from "./video-popup.component";
import * as i8 from "../../../../common/popups/components/base-popup/base-popup.component";
import * as i9 from "../../../analytics/services/universal-analytics/universal-analytics.service";
import * as i10 from "../../../../common/dialogs/services/dialog/dialog.service";
var styles_VideoPopupComponent = [];
var RenderType_VideoPopupComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_VideoPopupComponent, data: {} });
export { RenderType_VideoPopupComponent as RenderType_VideoPopupComponent };
function View_VideoPopupComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, [[6, 0], ["video", 1]], null, 2, "video", [["controls", ""], ["loop", ""], ["muted", ""], ["playsinline", ""]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "source", [["type", "video/mp4"]], [[8, "src", 4]], null, null, null, null)), i0.ɵppd(2, 1)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i0.ɵnov(_v.parent, 0), _co.src)); _ck(_v, 1, 0, currVal_0); }); }
function View_VideoPopupComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "iframe", [["allow", "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"], ["allowfullscreen", ""], ["frameborder", "0"], ["height", "100%"], ["width", "100%"]], [[8, "src", 5]], null, null, null, null)), i0.ɵppd(1, 1)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 0, 0, _ck(_v, 1, 0, i0.ɵnov(_v.parent, 0), _co.getYoutubeLink())); _ck(_v, 0, 0, currVal_0); }); }
function View_VideoPopupComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, [[3, 0], ["title_element", 1]], null, 0, "span", [["class", "video-popup__title"]], null, null, null, null, null))], null, null); }
function View_VideoPopupComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "a", [["class", "video-popup__button"], ["target", "_blank"]], [[8, "href", 4], [2, "video-popup__button_primary", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onLinkClick(_v.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.href; var currVal_1 = _v.parent.context.$implicit.primary; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = _v.parent.context.$implicit.label; _ck(_v, 1, 0, currVal_2); }); }
function View_VideoPopupComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "a", [["class", "video-popup__button"]], [[2, "video-popup__button_primary", null], [1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.onLinkClick(_v.parent.context.$implicit) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 737280, null, 0, i1.AppLinkDirective, [i2.RoutingService, i3.Router, i3.ActivatedRoute, i4.LocationStrategy], { appLink: [0, "appLink"] }, null), (_l()(), i0.ɵted(2, null, [" ", " "]))], function (_ck, _v) { var currVal_3 = _v.parent.context.$implicit.link; _ck(_v, 1, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.primary; var currVal_1 = i0.ɵnov(_v, 1).target; var currVal_2 = i0.ɵnov(_v, 1).href; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); var currVal_4 = _v.parent.context.$implicit.label; _ck(_v, 2, 0, currVal_4); }); }
function View_VideoPopupComponent_7(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "a", [["class", "video-popup__button"], ["href", "javascript:void(0)"]], [[2, "video-popup__button_primary", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onLinkClick(_v.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.primary; _ck(_v, 0, 0, currVal_0); var currVal_1 = _v.parent.context.$implicit.label; _ck(_v, 1, 0, currVal_1); }); }
function View_VideoPopupComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_VideoPopupComponent_5)), i0.ɵdid(2, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_VideoPopupComponent_6)), i0.ɵdid(4, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_VideoPopupComponent_7)), i0.ɵdid(6, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = _v.context.$implicit.href; _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.context.$implicit.link; _ck(_v, 4, 0, currVal_1); var currVal_2 = (!_v.context.$implicit.link && !_v.context.$implicit.href); _ck(_v, 6, 0, currVal_2); }, null); }
export function View_VideoPopupComponent_0(_l) { return i0.ɵvid(2, [i0.ɵpid(0, i5.SafeUrlPipe, [i6.DomSanitizer]), i0.ɵqud(402653184, 1, { root: 0 }), i0.ɵqud(402653184, 2, { background: 0 }), i0.ɵqud(671088640, 3, { titleElement: 0 }), i0.ɵqud(402653184, 4, { closeElement: 0 }), i0.ɵqud(402653184, 5, { buttonsElement: 0 }), i0.ɵqud(671088640, 6, { videoElement: 0 }), (_l()(), i0.ɵeld(7, 0, [[2, 0], ["background", 1]], null, 0, "div", [["class", "video-popup-background"]], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, [["container", 1]], null, 15, "div", [["class", "video-popup-container"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((($event.target == i0.ɵnov(_v, 8)) ? _co.miss() : null) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 14, "div", [["class", "video-popup"]], null, null, null, null, null)), (_l()(), i0.ɵeld(10, 0, [[1, 0], ["root", 1]], null, 5, "div", [["class", "video-popup__bounds"]], null, null, null, null, null)), (_l()(), i0.ɵeld(11, 0, null, null, 4, "div", [["class", "video-popup__inner"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_VideoPopupComponent_1)), i0.ɵdid(13, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_VideoPopupComponent_2)), i0.ɵdid(15, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_VideoPopupComponent_3)), i0.ɵdid(17, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(18, 0, [[4, 0], ["close_element", 1]], null, 2, "a", [["class", "video-popup__close"], ["href", "javascript:void(0)"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(19, 0, null, null, 0, "span", [["class", "icon icon-close"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" close video"])), (_l()(), i0.ɵeld(21, 0, [[5, 0], ["buttons_element", 1]], null, 2, "span", [["class", "video-popup__buttons"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_VideoPopupComponent_4)), i0.ɵdid(23, 278528, null, 0, i4.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.src; _ck(_v, 13, 0, currVal_0); var currVal_1 = _co.youtubeId; _ck(_v, 15, 0, currVal_1); var currVal_2 = _co.title; _ck(_v, 17, 0, currVal_2); var currVal_3 = _co.links; _ck(_v, 23, 0, currVal_3); }, null); }
export function View_VideoPopupComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_VideoPopupComponent_0, RenderType_VideoPopupComponent)), i0.ɵdid(1, 4374528, null, 0, i7.VideoPopupComponent, [i8.BasePopupComponent, i9.UniversalAnalyticsService, i10.DialogService], null, null)], null, null); }
var VideoPopupComponentNgFactory = i0.ɵccf("ng-component", i7.VideoPopupComponent, View_VideoPopupComponent_Host_0, { title: "title", src: "src", youtubeId: "youtubeId", autoPlay: "autoPlay", startTime: "startTime", sourceElement: "sourceElement", links: "links" }, { closed: "closed" }, []);
export { VideoPopupComponentNgFactory as VideoPopupComponentNgFactory };

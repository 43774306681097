var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import isArray from 'lodash/isArray';
import keys from 'lodash/keys';
import { ConstantNode, IndexNode } from 'mathjs';
import { ElementType } from '@modules/customize';
import { mathjs } from '@modules/fields';
import { cleanWrapSpaces, isSet } from '@shared';
export function getFormulaUsages(value, context) {
    var result = {
        userProperties: false,
        teamProperties: false,
        pageParameters: false
    };
    // const elementToken = contextTokens ? contextTokens.find(item => item.uniqueName == 'elements') : undefined;
    // const elementTokens = elementToken ? elementToken.children : [];
    var prevNodes = [];
    var usedElements = {};
    var usedElementProperties = {};
    var usedUser = {};
    var usedFunctions = {};
    mathjs.parse(cleanWrapSpaces(value)).transform(function (node) {
        if (node.isSymbolNode) {
            if (node.name == 'user_properties') {
                result.userProperties = true;
            }
            else if (node.name == 'team_properties') {
                result.teamProperties = true;
            }
            else if (node.name == 'page') {
                result.pageParameters = true;
            }
        }
        else if (node.isAccessorNode && node.object && node.object.isSymbolNode) {
            if (node.object.name == 'user') {
                usedUser[node.name] = true;
            }
            else if (node.object.name == 'elements') {
                var contextElement = context
                    ? context.elements.find(function (contextItem) {
                        if (contextItem.parent) {
                            return false;
                        }
                        return contextItem.element.uniqueName == node.name;
                    })
                    : undefined;
                var element = contextElement ? contextElement.element.element : undefined;
                if (element) {
                    var key = element.analyticsName;
                    usedElements[key] = true;
                    if (element.type == ElementType.List) {
                        var propertyNode = prevNodes[prevNodes.length - 2];
                        if (propertyNode &&
                            propertyNode.isAccessorNode &&
                            propertyNode.object &&
                            propertyNode.object.isAccessorNode &&
                            propertyNode.object.object === node) {
                            usedElementProperties[key + "_" + propertyNode.name] = true;
                        }
                    }
                    else {
                        var propertyNode = prevNodes[prevNodes.length - 1];
                        if (propertyNode && propertyNode.isAccessorNode && propertyNode.object === node) {
                            usedElementProperties[key + "_" + propertyNode.name] = true;
                        }
                    }
                }
            }
        }
        else if (node.isFunctionNode) {
            usedFunctions[node.name.toUpperCase()] = true;
        }
        prevNodes.push(node);
        return node;
    });
    return __assign({}, result, { elements: keys(usedElements), elementProperties: keys(usedElementProperties), user: keys(usedElements), functions: keys(usedFunctions) });
}
export function transformFormulaAccessors(formulaValue, contextTokens, toHuman) {
    if (toHuman === void 0) { toHuman = true; }
    var elementToken = contextTokens.find(function (item) { return item.uniqueName == 'elements'; });
    var elementTokens = elementToken ? elementToken.children : [];
    try {
        var math = mathjs.parse(cleanWrapSpaces(formulaValue));
        var rootNode = math.transform(function (node) {
            if (node.isAccessorNode) {
                var item_1 = node;
                while (item_1) {
                    if (item_1.object.isAccessorNode) {
                        item_1 = item_1.object;
                    }
                    else {
                        if (item_1.object.isSymbolNode && item_1.object.name == 'elements') {
                            var token = elementTokens.find(function (i) {
                                return toHuman ? i.uniqueName == item_1.name : i.name == item_1.name;
                            });
                            if (token) {
                                var newIndex = toHuman ? token.name : token.uniqueName;
                                if (isSet(newIndex)) {
                                    item_1.index = new IndexNode([new ConstantNode(newIndex)], isFormulaAccessorItemDotNotation(newIndex));
                                }
                            }
                        }
                        break;
                    }
                }
            }
            return node;
        });
        if (rootNode) {
            if (rootNode.isConstantNode && rootNode.value == undefined) {
                return formulaValue;
            }
            else {
                return rootNode.toString();
            }
        }
        else {
            return formulaValue;
        }
    }
    catch (e) {
        return formulaValue;
    }
}
export function transformFormulaElementAccessors(formulaValue, context, toHuman) {
    if (toHuman === void 0) { toHuman = true; }
    try {
        var math = mathjs.parse(cleanWrapSpaces(formulaValue));
        var rootNode = math.transform(function (node) {
            if (node.isAccessorNode) {
                var item_2 = node;
                while (item_2) {
                    if (item_2.object.isAccessorNode) {
                        item_2 = item_2.object;
                    }
                    else {
                        if (item_2.object.isSymbolNode && item_2.object.name == 'elements') {
                            var contextElement = context.elements.find(function (contextItem) {
                                if (contextItem.parent) {
                                    return false;
                                }
                                return toHuman ? contextItem.element.uniqueName == item_2.name : contextItem.element.name == item_2.name;
                            });
                            if (contextElement) {
                                var newIndex = toHuman ? contextElement.element.name : contextElement.element.uniqueName;
                                if (isSet(newIndex)) {
                                    item_2.index = new IndexNode([new ConstantNode(newIndex)], isFormulaAccessorItemDotNotation(newIndex));
                                }
                            }
                        }
                        break;
                    }
                }
            }
            return node;
        });
        if (rootNode) {
            if (rootNode.isConstantNode && rootNode.value == undefined) {
                return formulaValue;
            }
            else {
                return rootNode.toString();
            }
        }
        else {
            return formulaValue;
        }
    }
    catch (e) {
        return formulaValue;
    }
}
export function isFormulaAccessorItemDotNotation(accessorItem) {
    var startsWithLetter = !!accessorItem.match(/^[A-z](\w+)?$/);
    var isExponent = !!accessorItem.match(/^[eE]\d/);
    var systemNames = ['mod', 'to', 'in', 'and', 'xor', 'or', 'not'];
    return startsWithLetter && !isExponent && !systemNames.includes(accessorItem);
}
// export function addFormulaAccessors(token: string): string {
//   return token.split('.').reduce((acc, item, i) => {
//     if (i == 0) {
//       return item;
//     } else if (isFormulaAccessorItemDotNotation(item)) {
//       return `${acc}.${item}`;
//     } else {
//       return `${acc}["${item.replace(/\n/g, '\\n')}"]`;
//     }
//   }, '');
// }
//
// export function removeFormulaAccessors(token: string): string {
//   return token.replace(/\["([^"]*)"]/g, '.$1');
// }
export function contextToFormulaValue(contextValue) {
    if (!isArray(contextValue)) {
        return contextValue;
    }
    return contextValue.reduce(function (acc, item, i) {
        if (i == 0) {
            return String(item);
        }
        else if (isFormulaAccessorItemDotNotation(String(item))) {
            return acc + "." + item;
        }
        else {
            if (typeof item === 'string') {
                item = item.replace(/\n/g, '\\n');
            }
            if (typeof item == 'number') {
                return acc + "[" + item + "]";
            }
            else {
                return acc + "[\"" + item + "\"]";
            }
        }
    }, '');
}
export function singleTokenFormulaToContextValue(formulaValue) {
    if (typeof formulaValue !== 'string') {
        return formulaValue;
    }
    try {
        var result = [];
        var node = mathjs.parse(cleanWrapSpaces(formulaValue));
        while (node) {
            if (node && node.isAccessorNode) {
                var name_1 = node.index.dimensions[0].value;
                result.push(name_1);
                node = node.object;
            }
            else if (node && node.isSymbolNode) {
                result.push(node.name);
                break;
            }
            else {
                return;
            }
        }
        return result.reverse();
    }
    catch (e) {
        return;
    }
}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest } from 'rxjs';

import { ApiService } from '@modules/api';
import { ProjectsStore } from '@modules/projects';
import { RoutingService } from '@modules/routing';
import { CurrentUserStore } from '@modules/users';

@Component({
  selector: 'app-set-token',
  templateUrl: './set-token.component.html'
})
export class SetTokenComponent implements OnInit, OnDestroy {
  constructor(
    private apiService: ApiService,
    private routing: RoutingService,
    private projectsStore: ProjectsStore,
    private currentUserStore: CurrentUserStore,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    const token = this.activatedRoute.snapshot.params['token'];
    this.apiService.saveToken(token);

    combineLatest([this.currentUserStore.getFirst(true), this.projectsStore.updateIfLoaded()])
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.routing.navigate(['/']);
      });
  }

  ngOnDestroy(): void {}
}

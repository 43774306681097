export class ValueResize {
  enabled = true;
  min: number;
  max: number;

  constructor(options: Partial<ValueResize> = {}) {
    Object.assign(this, options);
  }

  deserialize(data: Object): this {
    if (data.hasOwnProperty('enabled')) {
      this.enabled = data['enabled'];
    }

    this.min = data['min'];
    this.max = data['max'];

    return this;
  }

  serialize(): Object {
    return {
      enabled: this.enabled,
      min: this.min,
      max: this.max
    };
  }
}

import * as moment from 'moment';

import { View } from '@modules/views';

export class FigmaExternalImport {
  public id: string;
  public name: string;
  public view: View;
  public viewPreview: string;
  public createdAt: moment.Moment;
  public updatedAt: moment.Moment;

  deserialize(data: Object): FigmaExternalImport {
    this.id = data['id'];
    this.name = data['name'];
    this.viewPreview = data['view_preview'];
    this.createdAt = data['created_at'] ? moment(data['created_at']) : undefined;
    this.updatedAt = data['updated_at'] ? moment(data['updated_at']) : undefined;

    if (data['view']) {
      this.view = new View().deserialize(data['view']);
    }

    return this;
  }
}

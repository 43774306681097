import { CdkConnectedOverlay } from '@angular/cdk/overlay';
import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Component({
  selector: 'app-font-control',
  templateUrl: './font-control.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FontControlComponent implements OnInit, OnDestroy {
  @Input() control: AbstractControl;
  @Input() placeholder = 'Choose Font';
  @Input() prefix: string;
  @Input() fill = false;
  @Input() small = false;
  @Input() allowNull = false;

  @ViewChild(CdkConnectedOverlay) cdkConnectedOverlay: CdkConnectedOverlay;

  constructor() {}

  ngOnInit() {}

  ngOnDestroy(): void {}
}

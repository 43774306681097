var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FormControl, FormGroup } from '@angular/forms';
import cloneDeep from 'lodash/cloneDeep';
import { combineLatest, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { AlertStyle, AlertTint } from '@modules/customize';
import { ElementConfigurationService } from '@modules/customize-configuration';
import { Input, Input as FieldInput } from '@modules/fields';
import { FieldInputControl } from '@modules/parameters';
import { controlValid, isSet } from '@shared';
import { MarginControl } from '../margin-control/margin-control.component';
export function validateActions() {
    return function (control) {
        var parent = control.parent;
        if (!parent) {
            return of(null);
        }
        if (!control.value || !control.value.length) {
            return of(null);
        }
        return combineLatest(control.value.map(function (item) { return parent.elementConfigurationService.isActionConfigured(item); })).pipe(map(function (result) {
            if (result.some(function (configured) { return !configured; })) {
                return { required: true };
            }
        }));
    };
}
var CustomizeBarAlertEditForm = /** @class */ (function (_super) {
    __extends(CustomizeBarAlertEditForm, _super);
    function CustomizeBarAlertEditForm(elementConfigurationService) {
        var _this = _super.call(this, {
            tint: new FormControl(AlertTint.Default),
            color_enabled: new FormControl(false),
            color: new FormControl('#7640f5'),
            style: new FormControl(AlertStyle.Fill),
            title: new FieldInputControl({ name: 'value' }),
            message: new FieldInputControl({ name: 'value' }),
            icon: new FormControl(null),
            actions: new FormControl([], undefined, validateActions()),
            visible_input: new FieldInputControl({ name: 'value' }),
            margin: new MarginControl()
        }) || this;
        _this.elementConfigurationService = elementConfigurationService;
        _this.tintOptions = [
            {
                value: AlertTint.Default,
                color: '#dae0ea'
            },
            {
                value: AlertTint.Info,
                color: '#607fff'
            },
            {
                value: AlertTint.Danger,
                color: '#ff2a2a'
            },
            {
                value: AlertTint.Warning,
                color: '#fac72d'
            },
            {
                value: AlertTint.Success,
                color: '#2cca74'
            },
            {
                value: AlertTint.Special,
                color: '#7640f5'
            }
        ];
        _this.styleOptions = [
            {
                value: AlertStyle.Fill,
                name: 'Alert with fill',
                image: 'alert-style-fill'
            },
            {
                value: AlertStyle.Border,
                name: 'Alert with border',
                image: 'alert-style-border'
            }
        ];
        return _this;
    }
    CustomizeBarAlertEditForm.prototype.init = function (element, firstInit) {
        if (firstInit === void 0) { firstInit = false; }
        this.element = element;
        var value = {
            tint: element.tint,
            color_enabled: isSet(element.color),
            color: isSet(element.color) ? element.color : '#7640f5',
            style: isSet(element.style) ? element.style : AlertStyle.Fill,
            title: element.title ? element.title.serialize() : {},
            message: element.message ? element.message.serialize() : {},
            icon: element.icon,
            actions: element.actions,
            visible_input: element.visibleInput ? element.visibleInput.serialize() : {},
            margin: element.margin
        };
        this.patchValue(value, { emitEvent: false });
        if (!firstInit) {
            this.markAsDirty();
        }
    };
    CustomizeBarAlertEditForm.prototype.isConfigured = function (instance) {
        return this.elementConfigurationService.isAlertConfigured(instance);
    };
    CustomizeBarAlertEditForm.prototype.controlsValid$ = function (controls) {
        if (!controls.length) {
            return of(true);
        }
        return combineLatest(controls.map(function (control) { return controlValid(control); })).pipe(map(function (result) { return result.every(function (item) { return item; }); })
        // debounceTime(60) TODO: Too long wait with debounceTime
        );
    };
    CustomizeBarAlertEditForm.prototype.actionsValid$ = function () {
        return this.controlsValid$([this.controls.actions]);
    };
    CustomizeBarAlertEditForm.prototype.submit = function () {
        var instance = cloneDeep(this.element);
        instance.tint = this.controls.tint.value;
        if (this.controls.color_enabled.value && isSet(this.controls.color.value)) {
            instance.color = this.controls.color.value;
        }
        else {
            instance.color = undefined;
        }
        instance.style = this.controls.style.value;
        instance.title = this.controls.title.value ? new FieldInput().deserialize(this.controls.title.value) : undefined;
        instance.message = this.controls.message.value
            ? new FieldInput().deserialize(this.controls.message.value)
            : undefined;
        instance.icon = this.controls.icon.value;
        instance.actions = this.controls.actions.value;
        instance.margin = this.controls.margin.value;
        if (this.controls.visible_input.value) {
            instance.visibleInput = new Input().deserialize(this.controls.visible_input.value);
        }
        else {
            instance.visibleInput = undefined;
        }
        return instance;
    };
    return CustomizeBarAlertEditForm;
}(FormGroup));
export { CustomizeBarAlertEditForm };

import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-browser-header',
  templateUrl: './browser-header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BrowserHeaderComponent implements OnInit {
  @Input() title: string;
  @Input() address: string;
  @Input() addressIcon: string;
  @Input() addressIconHasDefault = true;
  @Input() addressSearchIcon = true;

  ngOnInit() {}
}

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import pickBy from 'lodash/pickBy';
import { Input } from '@modules/fields';
import { WorkflowStepType } from '../workflow-step-type';
import { registerWorkflowStepType } from '../workflow-step-types';
import { WorkflowStep } from './base';
var TransformWorkflowStep = /** @class */ (function (_super) {
    __extends(TransformWorkflowStep, _super);
    function TransformWorkflowStep() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.type = WorkflowStepType.Transform;
        return _this;
    }
    TransformWorkflowStep.prototype.deserialize = function (data) {
        _super.prototype.deserialize.call(this, data);
        if (data['value']) {
            this.value = new Input().deserialize(data['value']);
        }
        return this;
    };
    TransformWorkflowStep.prototype.serialize = function (fields) {
        var data = __assign({}, _super.prototype.serialize.call(this, fields), { value: this.value ? this.value.serialize() : undefined });
        if (fields) {
            data = pickBy(data, function (v, k) { return fields.includes(k); });
        }
        return data;
    };
    TransformWorkflowStep.prototype.defaultName = function () {
        return 'Transform';
    };
    Object.defineProperty(TransformWorkflowStep.prototype, "analyticsName", {
        get: function () {
            return 'transform';
        },
        enumerable: true,
        configurable: true
    });
    TransformWorkflowStep.prototype.getIcon = function () {
        return 'reflect';
    };
    return TransformWorkflowStep;
}(WorkflowStep));
export { TransformWorkflowStep };
registerWorkflowStepType(WorkflowStepType.Transform, TransformWorkflowStep);

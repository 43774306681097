import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import cloneDeep from 'lodash/cloneDeep';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { Option } from '@modules/field-components';
import { ModelDescriptionService, ModelDescriptionStore } from '@modules/model-queries';
import { ModelDescription } from '@modules/models';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';

@Injectable()
export class ModelPrimaryKeyForm extends FormGroup {
  modelDescription: ModelDescription;
  fieldOptions: Option<string>[] = [];

  controls: {
    primaryKey: FormControl;
  };

  constructor(
    public currentProjectStore: CurrentProjectStore,
    public currentEnvironmentStore: CurrentEnvironmentStore,
    private modelDescriptionStore: ModelDescriptionStore,
    private modelDescriptionService: ModelDescriptionService
  ) {
    super({
      primaryKey: new FormControl(undefined, Validators.required)
    });
  }

  init(modelDescription: ModelDescription) {
    this.modelDescription = modelDescription;
    this.fieldOptions = this.modelDescription.dbFields.map(item => {
      return {
        name: item.verboseName,
        value: item.name
      };
    });
  }

  submit(): Observable<ModelDescription> {
    const instance = cloneDeep(this.modelDescription);

    instance.primaryKeyField = this.controls.primaryKey.value;

    return this.modelDescriptionService
      .update(this.currentProjectStore.instance.uniqueName, this.currentEnvironmentStore.instance.uniqueName, instance)
      .pipe(tap(() => this.modelDescriptionStore.updateItem(instance)));
  }
}

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../background-tab/background-tab.component.ngfactory";
import * as i2 from "../background-tab/background-tab.component";
import * as i3 from "@angular/common";
import * as i4 from "./background-tabs.component";
var styles_BackgroundTabsComponent = [];
var RenderType_BackgroundTabsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_BackgroundTabsComponent, data: {} });
export { RenderType_BackgroundTabsComponent as RenderType_BackgroundTabsComponent };
function View_BackgroundTabsComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-background-tab", [["class", "background-tabs__item"]], null, [[null, "tabClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("tabClick" === en)) {
        var pd_0 = (_co.tabClick.emit(_v.context.$implicit.name) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_BackgroundTabComponent_0, i1.RenderType_BackgroundTabComponent)), i0.ɵdid(1, 114688, null, 0, i2.BackgroundTabComponent, [], { link: [0, "link"], label: [1, "label"], active: [2, "active"] }, { tabClick: "tabClick" })], function (_ck, _v) { var currVal_0 = _v.context.$implicit.link; var currVal_1 = _v.context.$implicit.label; var currVal_2 = _v.context.$implicit.active; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_BackgroundTabsComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "background-tabs"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_BackgroundTabsComponent_1)), i0.ɵdid(2, 278528, null, 0, i3.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.tabComponents; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_BackgroundTabsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "app-background-tabs", [], null, null, null, View_BackgroundTabsComponent_0, RenderType_BackgroundTabsComponent)), i0.ɵdid(1, 2211840, null, 1, i4.BackgroundTabsComponent, [i0.ChangeDetectorRef], null, null), i0.ɵqud(603979776, 1, { tabComponents: 1 })], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BackgroundTabsComponentNgFactory = i0.ɵccf("app-background-tabs", i4.BackgroundTabsComponent, View_BackgroundTabsComponent_Host_0, {}, { tabClick: "tabClick" }, []);
export { BackgroundTabsComponentNgFactory as BackgroundTabsComponentNgFactory };

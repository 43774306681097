var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { moveItemInArray } from '@angular/cdk/drag-drop';
import { ChangeDetectorRef, OnDestroy, OnInit, QueryList } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest } from 'rxjs';
import { auditTime } from 'rxjs/operators';
import { CustomizeBarContext } from '@modules/customize-bar';
import { LayerType } from '@modules/views';
import { registerCustomizeLayerComponent } from '../../../data/customize-layer-components';
import { ViewEditorContext } from '../../../services/view-editor-context/view-editor.context';
import { FillControlComponent } from '../../ui/fill-control/fill-control.component';
import { CustomizeLayerComponent } from '../base/base-customize-layer.component';
import { EllipseCustomizeLayerForm } from './ellipse-customize-layer.form';
var EllipseCustomizeLayerComponent = /** @class */ (function (_super) {
    __extends(EllipseCustomizeLayerComponent, _super);
    function EllipseCustomizeLayerComponent(form, editorContext, customizeBarContext, cd) {
        var _this = _super.call(this) || this;
        _this.form = form;
        _this.editorContext = editorContext;
        _this.customizeBarContext = customizeBarContext;
        _this.cd = cd;
        _this.fillControlComponents = new QueryList();
        _this.settingsComponents = [];
        return _this;
    }
    EllipseCustomizeLayerComponent.prototype.ngOnInit = function () {
        var _this = this;
        var layerContainer = this.editorContext.getLayerContainer(this.layer);
        this.form.init(this.layer, { firstInit: true, container: layerContainer });
        this.trackFormUpdates(this.form, function () { return _this.form.submit(); });
        combineLatest(this.layerUpdated$, this.editorContext.getLayerContainer$(this.layer))
            .pipe(auditTime(10), untilDestroyed(this))
            .subscribe(function (_a) {
            var layer = _a[0], container = _a[1];
            _this.pauseFormUpdates();
            _this.form.init(layer, {
                firstInit: false,
                container: container
            });
            _this.resumeFormUpdates();
        });
        this.form.containerLayer$.pipe(untilDestroyed(this)).subscribe(function (layer) {
            _this.containerLayer = layer;
            _this.cd.markForCheck();
        });
        this.customizeBarContext.settingsComponents$.pipe(untilDestroyed(this)).subscribe(function (item) {
            _this.settingsComponents = item;
            _this.cd.markForCheck();
        });
    };
    EllipseCustomizeLayerComponent.prototype.ngOnDestroy = function () { };
    EllipseCustomizeLayerComponent.prototype.fillsDragDrop = function (event) {
        if (event.previousIndex !== event.currentIndex) {
            moveItemInArray(this.form.controls.fills.controls, event.previousIndex, event.currentIndex);
            this.form.controls.fills.updateValueAndValidity();
        }
    };
    EllipseCustomizeLayerComponent.prototype.bordersDragDrop = function (event) {
        if (event.previousIndex !== event.currentIndex) {
            moveItemInArray(this.form.controls.borders.controls, event.previousIndex, event.currentIndex);
            this.form.controls.borders.updateValueAndValidity();
        }
    };
    EllipseCustomizeLayerComponent.prototype.shadowsDragDrop = function (event) {
        if (event.previousIndex !== event.currentIndex) {
            moveItemInArray(this.form.controls.shadows.controls, event.previousIndex, event.currentIndex);
            this.form.controls.shadows.updateValueAndValidity();
        }
    };
    EllipseCustomizeLayerComponent.prototype.updateFillControlOpened = function () {
        this.fillControlOpened = this.fillControlComponents.find(function (item) { return item.isColorOpened(); });
        this.cd.markForCheck();
    };
    return EllipseCustomizeLayerComponent;
}(CustomizeLayerComponent));
export { EllipseCustomizeLayerComponent };
registerCustomizeLayerComponent(LayerType.Ellipse, EllipseCustomizeLayerComponent);

import { Type } from '@angular/core';

import { AutomationTriggerType } from './automation-trigger-type';
import { AutomationTrigger } from './automation-triggers';

const automationTriggers: { [k: string]: Type<AutomationTrigger> } = {};

export function getAutomationTriggerByType(type: AutomationTriggerType): Type<AutomationTrigger> {
  return automationTriggers[type];
}

export function registerAutomationTriggerForType(type: AutomationTriggerType, cls: Type<AutomationTrigger>) {
  automationTriggers[type] = cls;
}

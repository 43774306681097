var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { AfterViewInit, ChangeDetectorRef, ElementRef, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import * as JSONEditor from 'jsoneditor';
import isArray from 'lodash/isArray';
import isEqual from 'lodash/isEqual';
import isObject from 'lodash/isObject';
import isPlainObject from 'lodash/isPlainObject';
import truncate from 'lodash/truncate';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Subject } from 'rxjs';
import { debounceTime, delay, filter, map } from 'rxjs/operators';
import { EmbedScripts, ScriptsService } from '@core';
import { FieldType, registerFieldComponent } from '@modules/fields';
import { controlDisabled, isSet } from '@shared';
import { createJsonStructureNodeControl } from '../../utils/json-structure';
import { FieldComponent } from '../field/field.component';
var JsonFieldComponent = /** @class */ (function (_super) {
    __extends(JsonFieldComponent, _super);
    function JsonFieldComponent(scriptsService, cd) {
        var _this = _super.call(this) || this;
        _this.scriptsService = scriptsService;
        _this.cd = cd;
        _this.editorChanged = new Subject();
        _this.structureFormSubscriptions = [];
        _this.structureUpdating = false;
        _this.valueChangeProgrammatically = false;
        return _this;
    }
    JsonFieldComponent.prototype.ngOnInit = function () {
        if (this.form) {
            this.initForm();
        }
        else {
            this.setValue();
        }
        this.initStructure();
    };
    JsonFieldComponent.prototype.ngOnDestroy = function () { };
    JsonFieldComponent.prototype.ngOnChanges = function (changes) {
        if (changes['form']) {
            this.initForm();
        }
        if (changes['value']) {
            this.setValue();
        }
    };
    JsonFieldComponent.prototype.ngAfterViewInit = function () {
        this.initEditor();
        this.setValue();
        this.scriptsService
            .loadSingleton(EmbedScripts.CKEditor)
            .pipe(untilDestroyed(this))
            .subscribe(function () {
            var CKEDITOR = window['CKEDITOR'];
            CKEDITOR.disableAutoInline = true;
            CKEDITOR.editorConfig = function (config) {
                config.versionCheck = false;
            };
        });
    };
    JsonFieldComponent.prototype.initEditor = function () {
        var _this = this;
        if (!this.editorElement || this.editor) {
            return;
        }
        this.editorChanged.pipe(debounceTime(300), untilDestroyed(this)).subscribe(function () { return _this.updateValue(); });
        this.editor = new JSONEditor(this.editorElement.nativeElement, {
            mode: 'tree',
            modes: ['tree', 'text'],
            search: false,
            enableSort: false,
            enableTransform: false,
            onChange: function () { return _this.editorChanged.next(); }
        });
    };
    JsonFieldComponent.prototype.initStructure = function () {
        var _this = this;
        if (this.initStructureSubscription) {
            this.initStructureSubscription.unsubscribe();
            this.initStructureSubscription = undefined;
        }
        if (!this.field.params['display_fields']) {
            this.structureForm = undefined;
            this.rootNode = undefined;
            this.cd.markForCheck();
            this.structureFormSubscriptions.forEach(function (item) { return item.unsubscribe(); });
            this.structureFormSubscriptions = [];
            return;
        }
        var rootNode = this.field.params['structure'];
        if (!rootNode || !rootNode['type']) {
            this.structureForm = undefined;
            this.rootNode = undefined;
            this.cd.markForCheck();
            this.structureFormSubscriptions.forEach(function (item) { return item.unsubscribe(); });
            this.structureFormSubscriptions = [];
            return;
        }
        rootNode.name = null;
        rootNode.label = null;
        this.currentValue$
            .pipe(map(function (value) { return _this.parseValue(value); }), untilDestroyed(this))
            .subscribe(function (value) {
            try {
                var existingControl = _this.structureForm ? _this.structureForm.controls['null'] : undefined;
                _this.structureUpdating = true;
                var control = createJsonStructureNodeControl(rootNode, { existingControl: existingControl, value: value });
                _this.structureUpdating = false;
                if (!existingControl || existingControl !== control) {
                    _this.structureForm = new FormGroup(control ? { null: control } : {});
                    var structureFormSubscriptions = [];
                    structureFormSubscriptions.push(_this.structureForm.valueChanges
                        .pipe(filter(function () { return !_this.structureUpdating; }), untilDestroyed(_this))
                        .subscribe(function () { return _this.updateValue(); }));
                    if (_this.control) {
                        structureFormSubscriptions.push(controlDisabled(_this.control)
                            .pipe(untilDestroyed(_this))
                            .subscribe(function (disabled) {
                            if (disabled && !_this.structureForm.disabled) {
                                _this.structureForm.disable();
                            }
                            else if (!disabled && _this.structureForm.disabled) {
                                _this.structureForm.enable();
                            }
                        }));
                    }
                    _this.structureFormSubscriptions = structureFormSubscriptions;
                }
                _this.rootNode = rootNode;
                _this.cd.markForCheck();
            }
            catch (e) {
                console.error(e);
                _this.structureUpdating = false;
            }
        });
    };
    JsonFieldComponent.prototype.initForm = function () {
        var _this = this;
        if (this.formSubscription) {
            this.formSubscription.unsubscribe();
        }
        if (this.control) {
            this.formSubscription = this.control.valueChanges.pipe(delay(0), untilDestroyed(this)).subscribe(function () {
                if (_this.valueChangeProgrammatically) {
                    _this.valueChangeProgrammatically = false;
                    return;
                }
                _this.setValue();
            });
        }
        this.setValue();
    };
    JsonFieldComponent.prototype.parseValue = function (value) {
        if (isSet(value) && !isObject(value)) {
            try {
                return JSON.parse(value);
            }
            catch (e) { }
        }
        return value;
    };
    JsonFieldComponent.prototype.parseCurrentValue = function () {
        return this.parseValue(this.currentValue);
    };
    JsonFieldComponent.prototype.setValue = function () {
        var value = this.parseCurrentValue();
        var widgetValue = this.getWidgetValue();
        if (isEqual(value, widgetValue)) {
            return;
        }
        this.setWidgetValue(value);
    };
    JsonFieldComponent.prototype.getWidgetValue = function () {
        if (this.editor) {
            try {
                return this.editor.get();
            }
            catch (e) {
                return null;
            }
        }
        else if (this.structureForm) {
            return this.structureForm.value['null'];
        }
    };
    JsonFieldComponent.prototype.setWidgetValue = function (value) {
        if (this.editor) {
            this.editor.update(value);
        }
        else if (this.structureForm) {
            try {
                this.structureForm.patchValue({ null: value }, { emitEvent: false });
            }
            catch (e) { }
        }
    };
    JsonFieldComponent.prototype.updateValue = function () {
        if (this.control) {
            var value = this.getWidgetValue();
            this.valueChangeProgrammatically = true;
            this.control.patchValue(value);
        }
    };
    JsonFieldComponent.prototype.serializeValue = function (value) {
        if (isPlainObject(value) || isArray(value)) {
            try {
                return truncate(JSON.stringify(value), { length: 250 });
            }
            catch (e) { }
        }
        return value;
    };
    JsonFieldComponent.prototype.onWheel = function (e) {
        var container = this.editor['scrollableContent'];
        if (!container) {
            return;
        }
        var scrollTop = container.scrollTop;
        var scrollLeft = container.scrollLeft;
        var edgeX = container.scrollWidth - container.clientWidth;
        var edgeY = container.scrollHeight - container.clientHeight;
        var verticalUp = e.deltaY < 0 && scrollTop > 0;
        var verticalDown = e.deltaY > 0 && scrollTop < edgeY;
        var horizontalLeft = e.deltaX < 0 && scrollLeft > 0;
        var horizontalRight = e.deltaX > 0 && scrollLeft < edgeX;
        if (verticalUp || verticalDown || horizontalLeft || horizontalRight) {
            e.stopPropagation();
        }
    };
    JsonFieldComponent.prototype.isEmpty = function (value) {
        return !isSet(value);
    };
    return JsonFieldComponent;
}(FieldComponent));
export { JsonFieldComponent };
registerFieldComponent(FieldType.JSON, JsonFieldComponent);

import { AgmCoreModule } from '@agm/core';
import { AgmSnazzyInfoWindowModule } from '@agm/snazzy-info-window';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ObserversModule } from '@angular/cdk/observers';
import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatMenuModule } from '@angular/material/menu';
import { NgGxDatepickerModule } from 'ng-gxdatepicker';
import { NgGxSelectModule } from 'ng-gxselect';
import { AceEditorModule } from 'ng2-ace-editor';

import { CodeModule } from '@common/code';
import { DynamicComponentModule } from '@common/dynamic-component';
import { EditableContentModule } from '@common/editable-content';
import { FormUtilsModule } from '@common/form-utils';
import { ImageEditorModule } from '@common/image-editor';
import { InputFormatModule } from '@common/input-format';
import { LocalizeModule } from '@common/localize';
import { PlayerModule } from '@common/player';
import { SelectModule } from '@common/select';
import { TipsModule } from '@common/tips';
import { UniqueIdModule } from '@common/unique-id';
import { ColorsComponentsModule } from '@modules/colors-components';
import { ColorsSharedModule } from '@modules/colors-shared';
import { CustomizeSharedModule } from '@modules/customize-shared';
import { FieldsModule } from '@modules/fields';
import { IconsModule } from '@modules/icons';
import { IconsComponentsModule } from '@modules/icons-components';
import { RoutingModule } from '@modules/routing';
import { SidebarModule } from '@modules/sidebar';
import { SignatureModule } from '@modules/signature';
import { ThemeComponentsModule } from '@modules/theme-components';
import { TokensComponentsModule } from '@modules/tokens-components';
import { SharedModule } from '@shared';
import { UiModule } from '@ui';

import { ActionOutputsEditItemParameterComponent } from './components/action-outputs-edit-item-parameter/action-outputs-edit-item-parameter.component';
import { ActionOutputsEditItemComponent } from './components/action-outputs-edit-item/action-outputs-edit-item.component';
import { ActionOutputsEditComponent } from './components/action-outputs-edit/action-outputs-edit.component';
import { AudioFieldComponent } from './components/audio-field/audio-field.component';
import { AutoFieldStubComponent } from './components/auto-field-stub/auto-field-stub.component';
import { BooleanFieldComponent } from './components/boolean-field/boolean-field.component';
import { CharFieldComponent } from './components/char-field/char-field.component';
import { CodeFieldComponent } from './components/code-field/code-field.component';
import { ColorFieldComponent } from './components/color-field/color-field.component';
import { CustomActionFieldComponent } from './components/custom-action-field/custom-action-field.component';
import { CustomCharFieldComponent } from './components/custom-char-field/custom-char-field.component';
import { CustomSelectFieldComponent } from './components/custom-select-field/custom-select-field.component';
import { CustomSelectDropdownComponent } from './components/custom-select/custom-select-dropdown/custom-select-dropdown.component';
import { CustomSelectComponent } from './components/custom-select/custom-select.component';
import { CustomViewFieldComponent } from './components/custom-view-field/custom-view-field.component';
import { DateTimeFieldComponent } from './components/date-time-field/date-time-field.component';
import { ErrorsComponent } from './components/errors/errors.component';
import { FieldErrorsComponent } from './components/field-errors/field-errors.component';
import { FieldTypeDropdownComponent } from './components/field-type-dropdown/field-type-dropdown.component';
import { FieldTypeFieldComponent } from './components/field-type-field/field-type-field.component';
import { FieldTypeMenuComponent } from './components/field-type-menu/field-type-menu.component';
import { FieldWrapperComponent } from './components/field-wrapper/field-wrapper.component';
import { FieldsEditItemComponent } from './components/fields-edit-item/fields-edit-item.component';
import { FieldsEditComponent } from './components/fields-edit/fields-edit.component';
import { FileFieldComponent } from './components/file-field/file-field.component';
import { FiltersFieldComponent } from './components/filters-field/filters-field.component';
import { FontControlComponent } from './components/font-control/font-control.component';
import { FontMenuItemComponent } from './components/font-menu-item/font-menu-item.component';
import { FontMenuOverlayComponent } from './components/font-menu-overlay/font-menu-overlay.component';
import { FontMenuComponent } from './components/font-menu/font-menu.component';
import { ForeignKeyFieldComponent } from './components/foreign-key-field/foreign-key-field.component';
import { FormErrorsComponent } from './components/form-errors/form-errors.component';
import { GeographyFieldComponent } from './components/geography-field/geography-field.component';
import { IconFieldComponent } from './components/icon-field/icon-field.component';
import { ImageFieldComponent } from './components/image-field/image-field.component';
import { LightboxComponent } from './components/image-field/lightbox/lightbox.component';
import { PreviewTipComponent } from './components/image-field/preview-tip/preview-tip.component';
import { JsonFieldNodeComponent } from './components/json-field/json-field-node/json-field-node.component';
import { JsonFieldComponent } from './components/json-field/json-field.component';
import { ModelDescriptionFieldComponent } from './components/model-description-field/model-description-field.component';
import { MultipleSelectFieldComponent } from './components/multiple-select-field/multiple-select-field.component';
import { NumberFieldComponent } from './components/number-field/number-field.component';
import { ParametersEditItemComponent } from './components/parameters-edit-item/parameters-edit-item.component';
import { ParametersEditComponent } from './components/parameters-edit/parameters-edit.component';
import { PasswordFieldComponent } from './components/password-field/password-field.component';
import { AudioPreviewComponent } from './components/previews/audio-preview/audio-preview.component';
import { FilePreviewComponent } from './components/previews/file-preview/file-preview.component';
import { ImagePreviewComponent } from './components/previews/image-preview/image-preview.component';
import { VideoPreviewComponent } from './components/previews/video-preview/video-preview.component';
import { RadioButtonFieldComponent } from './components/radio-button-field/radio-button-field.component';
import { RatingFieldComponent } from './components/rating-field/rating-field.component';
import { ResourceFieldComponent } from './components/resource-field/resource-field.component';
import { RichTextFieldComponent } from './components/rich-text-field/rich-text-field.component';
import { SelectFieldActionItemComponent } from './components/select-field/select-field-action-item/select-field-action-item.component';
import { SelectFieldComponent } from './components/select-field/select-field.component';
import { SignatureFieldComponent } from './components/signature-field/signature-field.component';
import { SliderFieldComponent } from './components/slider-field/slider-field.component';
import { SqlFieldComponent } from './components/sql-field/sql-field.component';
import { TimeFieldComponent } from './components/time-field/time-field.component';
import { TranslatableFieldComponent } from './components/translatable-field/translatable-field.component';
import { AudioUploadComponent } from './components/upload/audio-upload/audio-upload.component';
import { FileUploadComponent } from './components/upload/file-upload/file-upload.component';
import { ImageUploadComponent } from './components/upload/image-upload/image-upload.component';
import { MultipleAudioUploadComponent } from './components/upload/multiple-audio-upload/multiple-audio-upload.component';
import { MultipleFileUploadComponent } from './components/upload/multiple-file-upload/multiple-file-upload.component';
import { MultipleImageUploadComponent } from './components/upload/multiple-image-upload/multiple-image-upload.component';
import { MultipleVideoUploadComponent } from './components/upload/multiple-video-upload/multiple-video-upload.component';
import { UploadTemplateComponent } from './components/upload/upload-template/upload-template.component';
import { VideoUploadComponent } from './components/upload/video-upload/video-upload.component';
import { URLFieldComponent } from './components/url-field/url-field.component';
import { VideoFieldComponent } from './components/video-field/video-field.component';
import { LightboxDirective } from './directives/lightbox/lightbox.directive';
import { ApplyParamInputPipe } from './pipes/apply-param-input/apply-param-input.pipe';
import { FieldInputPipe } from './pipes/field-input/field-input.pipe';
import { IsFieldInputSetPipe } from './pipes/is-field-input-set/is-field-input-set.pipe';

@NgModule({
  imports: [
    CommonModule,
    RoutingModule,
    FormsModule,
    ReactiveFormsModule,
    DragDropModule,
    MatMenuModule,
    NgGxSelectModule,
    NgGxDatepickerModule,
    AceEditorModule,
    FieldsModule,
    TokensComponentsModule,
    ImageEditorModule,
    DynamicComponentModule,
    SharedModule,
    UniqueIdModule,
    TipsModule,
    LocalizeModule,
    IconsModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyAtLlbTV72olE2Ofc1Jrmg9l1Weu_GSluc'
    }),
    AgmSnazzyInfoWindowModule,
    SidebarModule,
    InputFormatModule,
    UiModule,
    CodeModule,
    OverlayModule,
    ObserversModule,
    SelectModule,
    PlayerModule,
    EditableContentModule,
    FormUtilsModule,
    ThemeComponentsModule,
    ColorsComponentsModule,
    ColorsSharedModule,
    SignatureModule,
    CustomizeSharedModule,
    IconsComponentsModule
  ],
  declarations: [
    CharFieldComponent,
    BooleanFieldComponent,
    TranslatableFieldComponent,
    ForeignKeyFieldComponent,
    SelectFieldComponent,
    SelectFieldActionItemComponent,
    DateTimeFieldComponent,
    TimeFieldComponent,
    JsonFieldComponent,
    JsonFieldNodeComponent,
    FileFieldComponent,
    ImageFieldComponent,
    SqlFieldComponent,
    CodeFieldComponent,
    ErrorsComponent,
    IconFieldComponent,
    ModelDescriptionFieldComponent,
    CustomViewFieldComponent,
    CustomActionFieldComponent,
    FiltersFieldComponent,
    FieldErrorsComponent,
    FormErrorsComponent,
    GeographyFieldComponent,
    FieldWrapperComponent,
    URLFieldComponent,
    NumberFieldComponent,
    RatingFieldComponent,
    SliderFieldComponent,
    PasswordFieldComponent,
    ColorFieldComponent,
    ResourceFieldComponent,
    PreviewTipComponent,
    LightboxComponent,
    FieldTypeFieldComponent,
    CustomCharFieldComponent,
    CustomSelectFieldComponent,
    RichTextFieldComponent,
    FieldsEditComponent,
    FieldsEditItemComponent,
    CustomSelectComponent,
    CustomSelectDropdownComponent,
    RadioButtonFieldComponent,
    MultipleSelectFieldComponent,
    AudioFieldComponent,
    VideoFieldComponent,
    SignatureFieldComponent,
    ParametersEditComponent,
    ParametersEditItemComponent,
    ApplyParamInputPipe,
    FieldInputPipe,
    UploadTemplateComponent,
    ImageUploadComponent,
    MultipleImageUploadComponent,
    FileUploadComponent,
    MultipleFileUploadComponent,
    AudioUploadComponent,
    MultipleAudioUploadComponent,
    VideoUploadComponent,
    MultipleVideoUploadComponent,
    ImagePreviewComponent,
    FilePreviewComponent,
    AudioPreviewComponent,
    VideoPreviewComponent,
    AutoFieldStubComponent,
    FieldTypeDropdownComponent,
    FieldTypeMenuComponent,
    LightboxDirective,
    FontMenuComponent,
    FontMenuItemComponent,
    FontMenuOverlayComponent,
    FontControlComponent,
    IsFieldInputSetPipe,
    ActionOutputsEditComponent,
    ActionOutputsEditItemComponent,
    ActionOutputsEditItemParameterComponent
  ],
  entryComponents: [
    CharFieldComponent,
    BooleanFieldComponent,
    TranslatableFieldComponent,
    ForeignKeyFieldComponent,
    SelectFieldComponent,
    DateTimeFieldComponent,
    TimeFieldComponent,
    JsonFieldComponent,
    FileFieldComponent,
    ImageFieldComponent,
    SqlFieldComponent,
    CodeFieldComponent,
    IconFieldComponent,
    ModelDescriptionFieldComponent,
    CustomViewFieldComponent,
    CustomActionFieldComponent,
    FiltersFieldComponent,
    GeographyFieldComponent,
    URLFieldComponent,
    NumberFieldComponent,
    RatingFieldComponent,
    SliderFieldComponent,
    PasswordFieldComponent,
    ColorFieldComponent,
    ResourceFieldComponent,
    PreviewTipComponent,
    LightboxComponent,
    FieldTypeFieldComponent,
    CustomCharFieldComponent,
    RichTextFieldComponent,
    FieldsEditComponent,
    FieldsEditItemComponent,
    RadioButtonFieldComponent,
    MultipleSelectFieldComponent,
    AudioFieldComponent,
    VideoFieldComponent,
    SignatureFieldComponent
  ],
  exports: [
    CharFieldComponent,
    BooleanFieldComponent,
    TranslatableFieldComponent,
    ForeignKeyFieldComponent,
    SelectFieldComponent,
    DateTimeFieldComponent,
    TimeFieldComponent,
    JsonFieldComponent,
    ImageFieldComponent,
    SqlFieldComponent,
    CodeFieldComponent,
    IconFieldComponent,
    ModelDescriptionFieldComponent,
    CustomViewFieldComponent,
    CustomActionFieldComponent,
    FiltersFieldComponent,
    ErrorsComponent,
    FieldErrorsComponent,
    FormErrorsComponent,
    GeographyFieldComponent,
    URLFieldComponent,
    NumberFieldComponent,
    RatingFieldComponent,
    SliderFieldComponent,
    PasswordFieldComponent,
    ColorFieldComponent,
    ResourceFieldComponent,
    FieldWrapperComponent,
    FieldTypeFieldComponent,
    CustomCharFieldComponent,
    CustomSelectFieldComponent,
    RichTextFieldComponent,
    FieldsEditComponent,
    CustomSelectComponent,
    CustomSelectDropdownComponent,
    RadioButtonFieldComponent,
    MultipleSelectFieldComponent,
    AudioFieldComponent,
    VideoFieldComponent,
    SignatureFieldComponent,
    FieldsEditItemComponent,
    ParametersEditComponent,
    ParametersEditComponent,
    ParametersEditItemComponent,
    ApplyParamInputPipe,
    FieldInputPipe,
    FileUploadComponent,
    FilePreviewComponent,
    AutoFieldStubComponent,
    ImagePreviewComponent,
    FieldTypeDropdownComponent,
    FieldTypeMenuComponent,
    LightboxDirective,
    FontMenuOverlayComponent,
    FontControlComponent,
    IsFieldInputSetPipe,
    ActionOutputsEditComponent
  ]
})
export class FieldComponentsModule {}

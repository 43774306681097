import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-container-builder-stub',
  templateUrl: './container-builder-stub.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContainerBuilderStubComponent implements OnInit {
  @Input() fill = false;
  @Input() templates = false;
  @Output() templatesClick = new EventEmitter<void>();

  @HostBinding('class.container-stub') cls = true;
  @HostBinding('class.container-stub_fill') get marginSizeS() {
    return this.fill;
  }

  constructor() {}

  ngOnInit() {}
}

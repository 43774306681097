import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';

import { CurrentUserStore } from '@modules/users';

import { GoogleAnalyticsService } from '../google-analytics/google-analytics.service';
import { YandexMetrikaService } from '../yandex-metrika/yandex-metrika.service';

@Injectable()
export class ChatraService {
  private _loaded: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private _showed: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(
    private currentUserStore: CurrentUserStore,
    private googleAnalyticsService: GoogleAnalyticsService,
    private yandexMetrikaService: YandexMetrikaService
  ) {
    const interval = setInterval(() => {
      if (!this.chatra) {
        return;
      }

      this.init();
      clearInterval(interval);
    }, 1000);
  }

  get loaded$() {
    return this._loaded.pipe(filter(item => item == true));
  }

  get showed$() {
    return this._showed.asObservable();
  }

  get showed() {
    return this._showed.value;
  }

  init() {
    this.currentUserStore.instance$.subscribe(user => {
      if (!this.chatra || !user) {
        return;
      }

      this._loaded.next(true);

      this.chatra('updateIntegrationData', {
        name: `${user.firstName} ${user.lastName}`,
        email: user.email,
        username: user.username,
        gaClientId: this.googleAnalyticsService.clientId,
        ymClientId: this.yandexMetrikaService.clientId
      });
    });
  }

  get chatra() {
    return window['Chatra'];
  }

  openChat(focus) {
    this.loaded$.subscribe(() => {
      this.chatra('openChat', focus);
    });
  }

  show() {
    this.loaded$.subscribe(() => {
      this.chatra('show');
      this._showed.next(true);
    });
  }

  hide() {
    this.loaded$.subscribe(() => {
      this.chatra('hide');
      this._showed.next(false);
    });
  }
}

import { Injector, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { fromEvent, merge } from 'rxjs';
import { map } from 'rxjs/operators';
import { ActionControllerService } from '@modules/action-queries';
import { ViewContext, ViewContextElement } from '@modules/customize';
import { HOVER_OUTPUT, PRESSED_OUTPUT } from '@modules/list';
import { ActionLayerInteraction, LayerInteraction, LayerInteractionType } from '@modules/views';
var LayerInteractionComponent = /** @class */ (function () {
    function LayerInteractionComponent(actionControllerService, injector) {
        this.actionControllerService = actionControllerService;
        this.injector = injector;
        this.submitLoading = false;
    }
    LayerInteractionComponent.prototype.ngOnInit = function () {
        this.initInteraction(this.interaction);
    };
    LayerInteractionComponent.prototype.initInteraction = function (interaction) {
        var _this = this;
        if (interaction instanceof ActionLayerInteraction) {
            var action_1 = interaction.action;
            if (interaction.type == LayerInteractionType.Click) {
                fromEvent(this.element, 'click')
                    .pipe(untilDestroyed(this))
                    .subscribe(function () {
                    if (!action_1) {
                        return;
                    }
                    _this.actionControllerService
                        .execute(action_1, {
                        context: _this.context,
                        contextElement: _this.contextElement,
                        injector: _this.injector,
                        analyticsSource: _this.analyticsSource,
                        origin: _this.element
                    })
                        .subscribe();
                });
            }
        }
        else {
            if (interaction.type == LayerInteractionType.HoverOutput) {
                merge(fromEvent(this.element, 'mouseover').pipe(map(function () { return true; })), fromEvent(this.element, 'mouseout').pipe(map(function () { return false; })))
                    .pipe(untilDestroyed(this))
                    .subscribe(function (value) {
                    var _a;
                    _this.contextElement.patchOutputValues((_a = {},
                        _a[HOVER_OUTPUT] = value,
                        _a));
                });
            }
            else if (interaction.type == LayerInteractionType.PressedOutput) {
                merge(fromEvent(this.element, 'mousedown').pipe(map(function () { return true; })), fromEvent(window.document, 'mouseup').pipe(map(function () { return false; })))
                    .pipe(untilDestroyed(this))
                    .subscribe(function (value) {
                    var _a;
                    _this.contextElement.patchOutputValues((_a = {},
                        _a[PRESSED_OUTPUT] = value,
                        _a));
                });
            }
        }
    };
    LayerInteractionComponent.prototype.ngOnDestroy = function () { };
    LayerInteractionComponent.prototype.ngOnChanges = function (changes) { };
    return LayerInteractionComponent;
}());
export { LayerInteractionComponent };

export const twilioResourceParamsActionDescriptions: Object[] = [
  {
    resource: '{{resource}}',
    name: 'send_sms',
    params: {
      type: 'query',
      verbose_name: 'Send SMS',
      dynamic_status: false,
      action_params: [
        {
          name: 'From',
          verbose_name: 'From',
          field: 'text',
          required: true,
          default_type: 'value',
          params: {}
        },
        {
          name: 'To',
          verbose_name: 'To',
          field: 'text',
          required: true,
          default_type: 'value',
          params: {}
        },
        {
          name: 'Body',
          verbose_name: 'Body',
          field: 'text',
          required: false,
          default_type: 'value',
          params: {}
        },
        {
          name: 'account_sid',
          verbose_name: 'account_sid',
          field: 'text',
          required: true,
          default_type: 'value',
          params: {}
        }
      ],
      protected: false,
      query_action: {
        query: {
          query_type: 'http',
          http_query: {
            method: 'POST',
            url: 'https://api.twilio.com/2010-04-01/Accounts/{{params.account_sid}}/Messages.json',
            auth_params: {},
            body_type: 'FormUrlEncoded',
            body: [
              {
                name: 'From',
                value: '{{params.From}}'
              },
              {
                name: 'Body',
                value: '{{params.Body}}'
              },
              {
                name: 'To',
                value: '{{params.To}}'
              }
            ],
            headers: [
              {
                name: 'Content-Type',
                value: 'application/x-www-form-urlencoded'
              },
              {
                name: 'Authorization',
                value: 'Basic {-basic_auth-}'
              }
            ],
            query_params: [],
            response_type: 'json',
            request_tokens: {}
          }
        }
      }
    }
  },
  {
    resource: '{{resource}}',
    name: 'make_a_call',
    params: {
      type: 'query',
      verbose_name: 'Make a call',
      dynamic_status: false,
      action_params: [
        {
          name: 'From',
          verbose_name: 'From',
          field: 'text',
          required: true,
          default_type: 'value',
          params: {}
        },
        {
          name: 'To',
          verbose_name: 'To',
          field: 'text',
          required: true,
          default_type: 'value',
          params: {}
        },
        {
          name: 'Url',
          verbose_name: 'Url',
          field: 'text',
          required: false,
          default_type: 'value',
          params: {}
        },
        {
          name: 'Record',
          verbose_name: 'Record',
          field: 'boolean',
          required: false,
          default_type: 'value',
          params: {}
        },
        {
          name: 'SendDigits',
          verbose_name: 'SendDigits',
          field: 'text',
          required: false,
          default_type: 'value',
          params: {}
        },
        {
          name: 'account_sid',
          verbose_name: 'account_sid',
          field: 'text',
          required: true,
          default_type: 'value',
          params: {}
        }
      ],
      protected: false,
      query_action: {
        query: {
          query_type: 'http',
          http_query: {
            method: 'POST',
            url: 'https://api.twilio.com/2010-04-01/Accounts/{{params.account_sid}}/Calls.json',
            auth_params: {},
            body_type: 'FormUrlEncoded',
            body: [
              {
                name: 'Url',
                value: '{{params.Url}}'
              },
              {
                name: 'From',
                value: '{{params.From}}'
              },
              {
                name: 'To',
                value: '{{params.To}}'
              },
              {
                name: 'Record',
                value: '{{params.Record}}'
              },
              {
                name: 'SendDigits',
                value: '{{params.SendDigits}}'
              }
            ],
            headers: [
              {
                name: 'Authorization',
                value: 'Basic {-basic_auth-}'
              }
            ],
            query_params: [],
            response_type: 'json',
            request_tokens: {}
          }
        }
      }
    }
  }
];

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Injector } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import isEqual from 'lodash/isEqual';
import range from 'lodash/range';
var CustomizeBarPagesEditMenuItemControl = /** @class */ (function (_super) {
    __extends(CustomizeBarPagesEditMenuItemControl, _super);
    function CustomizeBarPagesEditMenuItemControl() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    CustomizeBarPagesEditMenuItemControl.inject = function (injector) {
        var args = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            args[_i - 1] = arguments[_i];
        }
        throw new Error('inject is not implemented');
    };
    return CustomizeBarPagesEditMenuItemControl;
}(FormGroup));
export { CustomizeBarPagesEditMenuItemControl };
export var menuItemControls = [];
export function getMenuItemControlChildren(control, includeSelf) {
    if (includeSelf === void 0) { includeSelf = false; }
    var result = [];
    if (includeSelf) {
        result.push(control);
    }
    if (control.controls.children) {
        control.controls.children.controls.forEach(function (subControl) {
            result.push.apply(result, getMenuItemControlChildren(subControl));
        });
    }
    return result;
}
var CustomizeBarPagesEditMenuItemArray = /** @class */ (function (_super) {
    __extends(CustomizeBarPagesEditMenuItemArray, _super);
    function CustomizeBarPagesEditMenuItemArray(injector, controls) {
        if (controls === void 0) { controls = []; }
        var _this = _super.call(this, controls) || this;
        _this.injector = injector;
        return _this;
    }
    CustomizeBarPagesEditMenuItemArray.inject = function (injector, controls) {
        if (controls === void 0) { controls = []; }
        return Injector.create({
            providers: [
                {
                    provide: CustomizeBarPagesEditMenuItemArray,
                    useFactory: function () {
                        return new CustomizeBarPagesEditMenuItemArray(injector, controls);
                    },
                    deps: []
                }
            ],
            parent: injector
        }).get(CustomizeBarPagesEditMenuItemArray);
    };
    CustomizeBarPagesEditMenuItemArray.prototype.deserialize = function (value) {
        var _this = this;
        var controls = (value || [])
            .map(function (item) {
            var control = _this.createMenuItemControl(item);
            if (!control) {
                return;
            }
            control.deserialize(item);
            return control;
        })
            .filter(function (item) { return item != undefined; });
        this.setControls(controls);
    };
    CustomizeBarPagesEditMenuItemArray.prototype.setControls = function (controls) {
        var _this = this;
        this.removeControls();
        controls.forEach(function (item) { return _this.push(item); });
    };
    CustomizeBarPagesEditMenuItemArray.prototype.removeControls = function () {
        var _this = this;
        range(this.controls.length).forEach(function () { return _this.removeAt(0); });
    };
    CustomizeBarPagesEditMenuItemArray.prototype.removeControl = function (control) {
        var newControls = this.controls.filter(function (item) { return !isEqual(item, control); });
        this.setControls(newControls);
    };
    CustomizeBarPagesEditMenuItemArray.prototype.appendControl = function (item) {
        var control = this.createMenuItemControl(item);
        if (!control) {
            return;
        }
        control.deserialize(item);
        this.push(control);
        return control;
    };
    CustomizeBarPagesEditMenuItemArray.prototype.prependControl = function (item) {
        var control = this.createMenuItemControl(item);
        if (!control) {
            return;
        }
        control.deserialize(item);
        this.insert(0, control);
        return control;
    };
    CustomizeBarPagesEditMenuItemArray.prototype.addControl = function (item, prepend) {
        if (prepend === void 0) { prepend = false; }
        if (prepend) {
            return this.prependControl(item);
        }
        else {
            return this.appendControl(item);
        }
    };
    CustomizeBarPagesEditMenuItemArray.prototype.createMenuItemControl = function (menuItem) {
        var control = menuItemControls.find(function (item) { return menuItem instanceof item.menuItem; });
        if (control) {
            return control.control.inject(this.injector);
        }
    };
    CustomizeBarPagesEditMenuItemArray.prototype.serialize = function () {
        return this.controls.map(function (control) { return control.serialize(); });
    };
    return CustomizeBarPagesEditMenuItemArray;
}(FormArray));
export { CustomizeBarPagesEditMenuItemArray };

import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
export var MenuSection;
(function (MenuSection) {
    MenuSection[MenuSection["Default"] = 0] = "Default";
    MenuSection[MenuSection["Project"] = 1] = "Project";
    MenuSection[MenuSection["None"] = 2] = "None";
})(MenuSection || (MenuSection = {}));
export var MenuCustomSection;
(function (MenuCustomSection) {
    MenuCustomSection["Pages"] = "pages";
    MenuCustomSection["Data"] = "data";
    MenuCustomSection["Layout"] = "layout";
    MenuCustomSection["SignUp"] = "sign_up";
    MenuCustomSection["Automations"] = "automations";
    MenuCustomSection["Collaboration"] = "Collaboration";
})(MenuCustomSection || (MenuCustomSection = {}));
var MenuService = /** @class */ (function () {
    function MenuService() {
        this._opened = new BehaviorSubject(false);
        this._section = new BehaviorSubject(MenuSection.Default);
        this._customizeBarOpened = new BehaviorSubject(false);
    }
    Object.defineProperty(MenuService.prototype, "opened", {
        get: function () {
            return this._opened.value;
        },
        set: function (value) {
            this._opened.next(value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MenuService.prototype, "opened$", {
        get: function () {
            return this._opened.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    MenuService.prototype.toggleOpened = function () {
        this.opened = !this.opened;
    };
    Object.defineProperty(MenuService.prototype, "section", {
        get: function () {
            return this._section.value;
        },
        set: function (value) {
            this._section.next(value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MenuService.prototype, "section$", {
        get: function () {
            return this._section.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MenuService.prototype, "customizeBarOpened", {
        get: function () {
            return this._customizeBarOpened.value;
        },
        set: function (value) {
            this._customizeBarOpened.next(value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MenuService.prototype, "customizeBarOpened$", {
        get: function () {
            return this._customizeBarOpened.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    MenuService.ngInjectableDef = i0.defineInjectable({ factory: function MenuService_Factory() { return new MenuService(); }, token: MenuService, providedIn: "root" });
    return MenuService;
}());
export { MenuService };

import { ChangeDetectorRef, EventEmitter, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, FormControl } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
var CompactSelectComponent = /** @class */ (function () {
    function CompactSelectComponent(sanitizer, cd) {
        this.sanitizer = sanitizer;
        this.cd = cd;
        this.options = [];
        this.placeholder = 'Choose';
        this.widthPx = null;
        this.compareWith = this.defaultCompare;
        this.change = new EventEmitter();
        this.value$ = new Subject();
        this.options$ = new BehaviorSubject([]);
        this.dropdownOpened = false;
        this.dropdownPositions = [
            { originX: 'start', overlayX: 'start', originY: 'bottom', overlayY: 'top', offsetX: 0, offsetY: 4 },
            { originX: 'center', overlayX: 'center', originY: 'bottom', overlayY: 'top', offsetX: 0, offsetY: 4 },
            { originX: 'end', overlayX: 'end', originY: 'bottom', overlayY: 'top', offsetX: 0, offsetY: 4 },
            { originX: 'start', overlayX: 'start', originY: 'top', overlayY: 'bottom', offsetX: 0, offsetY: -4 },
            { originX: 'center', overlayX: 'center', originY: 'top', overlayY: 'bottom', offsetX: 0, offsetY: -4 },
            { originX: 'end', overlayX: 'end', originY: 'top', overlayY: 'bottom', offsetX: 0, offsetY: -4 },
            { originX: 'end', overlayX: 'start', originY: 'center', overlayY: 'center', offsetX: 4, offsetY: 0 },
            { originX: 'start', overlayX: 'end', originY: 'center', overlayY: 'center', offsetX: -4, offsetY: 0 }
        ];
        this.onChange = function (value) { return undefined; };
        this.onTouched = function () { return undefined; };
    }
    CompactSelectComponent.prototype.defaultCompare = function (o1, o2) {
        return o1 == o2;
    };
    CompactSelectComponent.prototype.ngOnInit = function () {
        var _this = this;
        combineLatest(this.value$, this.options$)
            .pipe(untilDestroyed(this))
            .subscribe(function (_a) {
            var value = _a[0], options = _a[1];
            _this.currentOption = options.find(function (item) { return _this.compareWith(item.value, value); });
            _this.cd.markForCheck();
        });
        this.formControl.valueChanges.pipe(untilDestroyed(this)).subscribe(function (value) { return _this.value$.next(value); });
    };
    CompactSelectComponent.prototype.ngOnDestroy = function () { };
    CompactSelectComponent.prototype.ngOnChanges = function (changes) {
        if (changes.options) {
            this.options$.next(this.options);
        }
    };
    CompactSelectComponent.prototype.registerOnChange = function (fn) {
        this.onChange = fn;
    };
    CompactSelectComponent.prototype.registerOnTouched = function (fn) {
        this.onTouched = fn;
    };
    CompactSelectComponent.prototype.writeValue = function (value) {
        this.value$.next(value);
    };
    CompactSelectComponent.prototype.updateValue = function (value) {
        this.value$.next(value);
        this.onChange(value);
        this.change.emit();
    };
    CompactSelectComponent.prototype.setDropdownOpened = function (value) {
        this.dropdownOpened = value;
        this.cd.markForCheck();
    };
    CompactSelectComponent.prototype.toggleDropdownOpened = function () {
        this.setDropdownOpened(!this.dropdownOpened);
    };
    return CompactSelectComponent;
}());
export { CompactSelectComponent };

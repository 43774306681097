<div class="calendar-year">
  <div *ngFor="let month of months" class="calendar-year__item">
    <p class="calendar-year__item-caption">
      <a
        href="javascript:void(0)"
        (click)="setParams(monthParams(month.date))"
        class="calendar-year__item-caption-link"
      >
        {{ month.date.format('MMMM') }}
      </a>
    </p>
    <table class="calendar-year-table">
      <tr class="calendar-year-table__tr">
        <th *ngFor="let day of month.weekDays" class="calendar-year-table__th">{{ day.date.format('dd') }}</th>
      </tr>
      <tr *ngFor="let week of month.weeks" class="calendar-year-table__tr">
        <td *ngFor="let day of week; let i = index" class="calendar-year-table__td">
          <div
            class="calendar-year-table__date-number"
            [class.calendar-year-table__date-number--disabled]="!day.currentMonth"
            [class.calendar-year-table__date-number--active]="day.today"
            [appTintColor]="'accentColor' | appThemeColorHexOption | async"
            [appTintColorStyle]="tintStyles.Primary"
            (click)="setParams(weekParams(day.date))"
          >
            {{ day.date.format('D') }}
            <div *ngIf="items[day.date.format(itemsGroupBy)]" class="calendar-year-table__status">
              <span class="calendar-year-table__status-item calendar-year-table__status-item--blue"></span>
            </div>
          </div>
        </td>
      </tr>
    </table>
  </div>
</div>

<div *ngIf="loading" class="calendar-loader">
  <div class="calendar-loader__content">
    <app-loader [fill]="true"></app-loader>
  </div>
</div>

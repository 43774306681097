import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-popup2-title',
  templateUrl: './popup2-title.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class Popup2TitleComponent implements OnInit {
  @Input() align: 'left' | 'center' | 'right' = 'left';
  @Input() size: 's' | 'm' = 'm';

  constructor() {}

  ngOnInit() {}
}

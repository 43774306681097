import { Injectable, OnDestroy } from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';

import { DialogOptions } from '../../data/options';

@Injectable()
export class DialogForm implements OnDestroy {
  form = new FormGroup({});
  options: DialogOptions;

  ngOnDestroy(): void {}

  init(options: DialogOptions) {
    this.options = options;
    this.updateForm();
  }

  updateForm() {
    const controls: { [key: string]: AbstractControl } = {};

    if (this.options.inputs) {
      this.options.inputs.forEach(item => {
        controls[item.name] = new FormControl(item.default, item.validators, item.asyncValidators);
      });
    }

    this.form = new FormGroup(controls);
  }
}

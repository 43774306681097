import { ChangeDetectionStrategy, Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';

import { PopoverComponent } from '@common/popover';
import { Dashboard } from '@modules/dashboard';
import { createFormFieldFactory } from '@modules/fields';

import { AddDashboardForm } from './add-dashboard.form';

@Component({
  selector: 'app-add-dashboard',
  templateUrl: './add-dashboard.component.html',
  providers: [AddDashboardForm],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddDashboardComponent implements OnInit, OnDestroy {
  @Output() created = new EventEmitter<Dashboard>();

  createField = createFormFieldFactory();

  constructor(public form: AddDashboardForm, private popoverComponent: PopoverComponent) {}

  ngOnInit() {}

  ngOnDestroy(): void {}

  submit() {
    this.form
      .submit()
      .pipe(untilDestroyed(this))
      .subscribe(dashboard => {
        this.popoverComponent.close();
        this.created.emit(dashboard);
      });
  }
}

<app-project-group-dropdown
  [control]="control"
  [origin]="dropdownTrigger"
  [allowNull]="allowNull"
  [environmentName]="environmentName"
  #dropdown="appProjectGroupDropdown"
></app-project-group-dropdown>

<div class="select select_theme_jet" [class.select_fill]="fill" [class.select_small]="small">
  <div
    class="select__input"
    [class.select__input_disabled]="control.disabled"
    [class.select__input_opened]="dropdown.isOpened()"
    cdkOverlayOrigin
    #dropdownTrigger="cdkOverlayOrigin"
    (click)="dropdown.open()"
  >
    <span
      *ngIf="dropdown.currentOption"
      class="select__input-icon"
      [ngClass]="['icon-' + (dropdown.currentOption.icon || 'users_teams')]"
    >
    </span>
    <span class="select__input-label">
      <ng-container *ngIf="dropdown.currentOption">{{ dropdown.currentOption.name | appCapitalize }}</ng-container>
      <ng-container *ngIf="!dropdown.currentOption">{{ placeholder }}</ng-container>
    </span>
    <span class="select__input-arrow"></span>
  </div>
</div>

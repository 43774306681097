<app-customize-bar-action-edit-parameters-query
  *ngIf="
    form.controls.type.value == actionTypes.Query ||
    (form.controls.type.value == actionTypes.Download &&
      form.controls.download_action_type.value == downloadActionTypes.Query)
  "
  [form]="form"
  [context]="context"
  [contextElement]="contextElement"
  [contextElementPath]="contextElementPath"
  [contextElementPaths]="contextElementPaths"
  [userInput]="userInput"
  [analyticsSource]="analyticsSource"
  (addQueryInput)="addQueryInput.emit()"
></app-customize-bar-action-edit-parameters-query>

<app-customize-bar-action-edit-parameters-link
  *ngIf="form.controls.type.value == actionTypes.Link"
  [form]="form"
  [context]="context"
  [contextElement]="contextElement"
  [contextElementPath]="contextElementPath"
  [contextElementPaths]="contextElementPaths"
  [userInput]="userInput"
  [analyticsSource]="analyticsSource"
></app-customize-bar-action-edit-parameters-link>

<app-customize-bar-action-edit-parameters-export
  *ngIf="form.controls.type.value == actionTypes.Export"
  [form]="form"
  [context]="context"
  [contextElement]="contextElement"
  [contextElementPath]="contextElementPath"
  [contextElementPaths]="contextElementPaths"
  [userInput]="userInput"
  [analyticsSource]="analyticsSource"
  (addQueryInput)="addQueryInput.emit()"
></app-customize-bar-action-edit-parameters-export>

<app-customize-bar-action-edit-parameters-open-popup
  *ngIf="form.controls.type.value == actionTypes.OpenPopup"
  [form]="form"
  [context]="context"
  [contextElement]="contextElement"
  [contextElementPath]="contextElementPath"
  [contextElementPaths]="contextElementPaths"
  [userInput]="userInput"
  [analyticsSource]="analyticsSource"
></app-customize-bar-action-edit-parameters-open-popup>

<app-customize-bar-action-edit-parameters-element-action
  *ngIf="form.controls.type.value == actionTypes.ElementAction"
  [form]="form"
  [context]="context"
  [contextElement]="contextElement"
  [contextElementPath]="contextElementPath"
  [contextElementPaths]="contextElementPaths"
  [userInput]="userInput"
  [analyticsSource]="analyticsSource"
></app-customize-bar-action-edit-parameters-element-action>

<app-customize-bar-action-edit-parameters-workflow
  *ngIf="form.controls.type.value == actionTypes.Workflow"
  [form]="form"
  [context]="context"
  [contextElement]="contextElement"
  [contextElementPath]="contextElementPath"
  [contextElementPaths]="contextElementPaths"
  [userInput]="userInput"
  [analyticsSource]="analyticsSource"
  (addQueryInput)="addQueryInput.emit()"
></app-customize-bar-action-edit-parameters-workflow>

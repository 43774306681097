import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import * as Color from 'color';
import toPairs from 'lodash/toPairs';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { AppDrag, AppDragDrop, DragAxis, moveItemInFormArray, transferFormArrayItem } from '@common/drag-drop2';
import { AnalyticsEvent, AnalyticsEventAction, UniversalAnalyticsService } from '@modules/analytics';
import { getColorHex, isLightColor } from '@modules/colors';
import { applyParamInput$ } from '@modules/fields';
import { getLogoControlsUrl, LogoOption } from '@modules/layout-components';
import { ImageMenuItemOption, MenuBlockLayout } from '@modules/menu';
import { MenuContext } from '@modules/menu-components';
import { CurrentProjectStore } from '@modules/projects';
import { CurrentUserStore } from '@modules/users';
import { controlValue, deployUrl, isSet } from '@shared';

import { AddMenuItemOptions } from '../add-menu-item-menu/add-menu-item-menu.component';
import {
  CustomizeBarPagesEditMenuItemArray,
  CustomizeBarPagesEditMenuItemControl
} from '../customize-bar-pages-edit/customize-bar-pages-edit.form';
import { CustomizeBarPagesEditImageControl } from '../customize-bar-pages-edit/image-control';
import { CustomizeBarPagesEditSectionControl } from '../customize-bar-pages-edit/section-control';
import { ImageMenuItemPopoverOptions } from '../image-menu-item-popover/image-menu-item-popover.component';

@Component({
  selector: 'app-image-menu-item-edit',
  templateUrl: './image-menu-item-edit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImageMenuItemEditComponent implements OnInit, OnDestroy {
  @Input() control: CustomizeBarPagesEditImageControl;
  @Input() layout: MenuBlockLayout;
  @Input() createdOptions: AddMenuItemOptions;
  @Output() deleteRequested = new EventEmitter<void>();

  imageUrl: string;
  imageInitials: string;
  imageColor: string;
  imageColorLight = false;
  imageStyle: SafeStyle;
  imageOptions: LogoOption[] = [];
  title: string;
  subtitle: string;
  iconPopoverOpened = false;
  editPopoverOpened = false;
  editPopoverOptions: ImageMenuItemPopoverOptions = {};
  childrenExpanded = false;
  createdMenuItemControl: CustomizeBarPagesEditMenuItemControl;
  createdMenuItemControlOptions: AddMenuItemOptions;
  dragAxis = DragAxis;

  trackMenuItemFn(i, item: CustomizeBarPagesEditMenuItemControl) {
    return item.menuItem.id;
  }

  constructor(
    private context: MenuContext,
    private currentProjectStore: CurrentProjectStore,
    private currentUserStore: CurrentUserStore,
    private sanitizer: DomSanitizer,
    private analyticsService: UniversalAnalyticsService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    combineLatest(this.currentProjectStore.get(), this.currentUserStore.get())
      .pipe(
        switchMap(([project, user]) => {
          this.imageOptions = [
            {
              value: ImageMenuItemOption.UserPhoto,
              name: 'Current user photo',
              icon: 'human_being',
              data: {
                url: user && isSet(user.photo) ? user.photo : deployUrl('/assets/images/no-photo.svg')
              }
            },
            {
              value: ImageMenuItemOption.ProjectLogo,
              name: 'App logo',
              icon: 'image',
              data: {
                url: project ? project.logo : undefined,
                initials: project ? project.initials : undefined
              }
            }
          ];
          this.cd.markForCheck();

          return getLogoControlsUrl({
            urlControl: this.control.controls.image_custom,
            optionControl: this.control.controls.image_option,
            options: this.imageOptions
          });
        }),
        untilDestroyed(this)
      )
      .subscribe(value => {
        this.imageUrl = value ? value.url : undefined;
        this.imageInitials = value ? value.initials : undefined;
        this.cd.markForCheck();
      });

    this.control
      .getImageColor$()
      .pipe(untilDestroyed(this))
      .subscribe(value => {
        this.imageColor = value;
        this.imageColorLight = isLightColor(this.imageColor);
        this.imageStyle = this.getStyleVars(this.imageColor);
        this.cd.markForCheck();
      });

    controlValue(this.control.controls.title)
      .pipe(
        map(() => this.control.controls.title.serialize()),
        switchMap(input => {
          return applyParamInput$(input, {
            context: this.context,
            defaultValue: ''
          });
        }),
        untilDestroyed(this)
      )
      .subscribe(value => {
        this.title = value;
        this.cd.markForCheck();
      });

    controlValue(this.control.controls.subtitle)
      .pipe(
        map(() => this.control.controls.subtitle.serialize()),
        switchMap(input => {
          return applyParamInput$(input, {
            context: this.context,
            defaultValue: ''
          });
        }),
        untilDestroyed(this)
      )
      .subscribe(value => {
        this.subtitle = value;
        this.cd.markForCheck();
      });

    if (this.createdOptions && this.createdOptions.customize) {
      this.openEditPopover(true, { focusTitle: true });
    }

    if (this.createdOptions && this.createdOptions.opened) {
      this.setChildrenExpanded(true);
    }
  }

  ngOnDestroy(): void {}

  getDarkColor(color: string): string {
    if (!isSet(color)) {
      color = 'white';
    }

    try {
      const colorHex = getColorHex(color);
      const clr = Color(colorHex);
      return clr.darken(0.6).string();
    } catch (e) {}
  }

  getStyleVars(color: string): SafeStyle {
    const darkColor = this.getDarkColor(color);

    const vars = {};

    if (darkColor) {
      vars['dark-color'] = darkColor;
    }

    const styles = toPairs(vars)
      .map(([k, v]) => `--${k}: ${v}`)
      .join(';');

    return this.sanitizer.bypassSecurityTrustStyle(styles);
  }

  isDroppable(item: AppDrag<CustomizeBarPagesEditMenuItemControl>): boolean {
    return !(item.data instanceof CustomizeBarPagesEditSectionControl);
  }

  openIconPopover(value) {
    this.iconPopoverOpened = value;
    this.cd.markForCheck();
  }

  openEditPopover(value, options: ImageMenuItemPopoverOptions = {}) {
    this.editPopoverOpened = value;
    this.editPopoverOptions = options;
    this.cd.markForCheck();
  }

  setChildrenExpanded(value: boolean) {
    this.childrenExpanded = value;
    this.cd.markForCheck();
  }

  toggleChildrenExpanded() {
    this.setChildrenExpanded(!this.childrenExpanded);
  }

  addMenuItem(options: AddMenuItemOptions) {
    this.createdMenuItemControl = this.control.children.addControl(options.item, options.prepend);
    this.createdMenuItemControlOptions = options;
    this.setChildrenExpanded(true);

    this.analyticsService.sendEvent(AnalyticsEvent.GA.MenuItem, AnalyticsEventAction.Created);
    this.analyticsService.sendSimpleEvent(AnalyticsEvent.Menu.MenuItemAdded);
  }

  dragDrop(event: AppDragDrop<CustomizeBarPagesEditMenuItemArray>) {
    if (event.previousContainer === event.container) {
      moveItemInFormArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferFormArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    }
  }
}

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Type } from '@angular/core';
import { map, publishLast, refCount, switchMap } from 'rxjs/operators';
import { ApiService } from '@modules/api';
import { AutomationTriggerType } from '../../data/automation-trigger-type';
import { WorkflowRunStatus } from '../../data/workflow-backend-run';
import { WorkflowBackendRunWithRelations } from '../../data/workflow-backend-run-with-relations';
import * as i0 from "@angular/core";
import * as i1 from "../../../api/services/api/api.service";
import * as i2 from "@angular/common/http";
var WorkflowBackendRunService = /** @class */ (function () {
    function WorkflowBackendRunService(apiService, http) {
        this.apiService = apiService;
        this.http = http;
    }
    WorkflowBackendRunService.prototype.get = function (project, environment, options) {
        var _this = this;
        if (options === void 0) { options = {}; }
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.workflowsEnvironmentMethodURL(project.uniqueName, environment.uniqueName, 'workflow_runs');
            var headers = new HttpHeaders();
            var params = {};
            if (options.automationUid) {
                params['automationUid'] = options.automationUid;
            }
            if (options.workflowUid) {
                params['workflowUid'] = options.workflowUid;
            }
            if (options.page) {
                params['page'] = options.page.toString();
            }
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.get(url, { headers: headers, params: params });
        }), map(function (result) {
            return new WorkflowBackendRunService.GetResponse(WorkflowBackendRunWithRelations).deserialize(result);
        }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    WorkflowBackendRunService.prototype.getDetail = function (project, environment, id) {
        var _this = this;
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.workflowsEnvironmentMethodURL(project.uniqueName, environment.uniqueName, "workflow_runs/" + id);
            var headers = new HttpHeaders();
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.get(url, { headers: headers });
        }), map(function (result) { return new WorkflowBackendRunWithRelations().deserialize(result); }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    WorkflowBackendRunService.prototype.createForWorkflow = function (projectName, environmentName, workflow, success, workflowRun, dateRun, dateFinished, options) {
        var _this = this;
        if (options === void 0) { options = {}; }
        return this.apiService.refreshToken().pipe(switchMap(function () {
            var url = _this.apiService.workflowsEnvironmentMethodURL(projectName, environmentName, "workflow_runs");
            var headers = new HttpHeaders();
            var data = {
                workflow_uid: workflow.uid,
                workflow: workflow.serialize(),
                status: success ? WorkflowRunStatus.Succeeded : WorkflowRunStatus.Failed,
                trigger_type: AutomationTriggerType.PageAction,
                trigger_params: {},
                success: success,
                workflow_run: workflowRun.serialize(),
                date_run: dateRun.toISOString(),
                date_finished: dateFinished.toISOString(),
                params: {
                    page_uid: options.pageUid,
                    element_uid: options.elementUid,
                    element_type: options.elementType,
                    element_label: options.elementLabel,
                    user_uid: options.userUid
                }
            };
            headers = _this.apiService.setHeadersToken(headers);
            return _this.http.post(url, data, { headers: headers });
        }), map(function (result) { return new WorkflowBackendRunWithRelations().deserialize(result); }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    WorkflowBackendRunService.ngInjectableDef = i0.defineInjectable({ factory: function WorkflowBackendRunService_Factory() { return new WorkflowBackendRunService(i0.inject(i1.ApiService), i0.inject(i2.HttpClient)); }, token: WorkflowBackendRunService, providedIn: "root" });
    return WorkflowBackendRunService;
}());
export { WorkflowBackendRunService };
(function (WorkflowBackendRunService) {
    var GetResponse = /** @class */ (function () {
        function GetResponse(cls) {
            this.cls = cls;
        }
        GetResponse.prototype.deserialize = function (data) {
            var _this = this;
            this.results = data['results'].map(function (item) { return new _this.cls().deserialize(item); });
            this.count = data['count'];
            this.perPage = data['per_page'];
            this.numPages = data['num_pages'];
            return this;
        };
        return GetResponse;
    }());
    WorkflowBackendRunService.GetResponse = GetResponse;
})(WorkflowBackendRunService || (WorkflowBackendRunService = {}));

import { HttpClient, HttpEventType } from '@angular/common/http';
import { Injector } from '@angular/core';
import yaml from 'js-yaml';
import fromPairs from 'lodash/fromPairs';
import toPairs from 'lodash/toPairs';
import { throwError } from 'rxjs';
import { map, publishLast, refCount, tap } from 'rxjs/operators';
import { ApiService } from '@modules/api';
import { FieldType, fileFieldTypes } from '@modules/fields';
import { Model, ORDER_BY_PARAM, PAGE_PARAM, PER_PAGE_PARAM } from '@modules/models';
import { HTTP_QUERY_KEY_AUTH_NAME } from '@modules/projects-components/data/http-query-auth';
import { HttpContentType, HttpQuery, HttpQueryService } from '@modules/queries';
import { AppError, getExtension, getExtensionMime, getFilenameWithExtension, isSet } from '@shared';
import { ModelResponse } from '../../data/model-response';
import { XANO_COLLECTION_MODEL_CREATE_PARAM_NAME, XANO_COLLECTION_MODEL_DELETE_PARAM_NAME, XANO_COLLECTION_MODEL_GET_DETAIL_PARAM_NAME, XANO_COLLECTION_MODEL_GET_PARAM_NAME, XANO_COLLECTION_MODEL_UPDATE_PARAM_NAME } from './xano-constants';
var XanoLegacyResourceController = /** @class */ (function () {
    function XanoLegacyResourceController(apiService, httpQueryService, http, injector) {
        this.apiService = apiService;
        this.httpQueryService = httpQueryService;
        this.http = http;
        this.injector = injector;
        this.DEFAULT_PER_PAGE = 25;
    }
    XanoLegacyResourceController.prototype.createModel = function () {
        return Injector.create({
            providers: [{ provide: Model, deps: [Injector] }],
            parent: this.injector
        }).get(Model);
    };
    XanoLegacyResourceController.prototype.createGetResponse = function () {
        return Injector.create({
            providers: [{ provide: ModelResponse.GetResponse, deps: [Injector] }],
            parent: this.injector
        }).get(ModelResponse.GetResponse);
    };
    XanoLegacyResourceController.prototype.getSchema = function (apiBaseUrl) {
        var swaggerSchemaUrl = apiBaseUrl.replace('api:', 'apispec:');
        return this.http.get(swaggerSchemaUrl, { responseType: 'text' }).pipe(map(function (data) {
            try {
                return yaml.load(data);
            }
            catch (e) { }
        }), tap(function (schema) {
            if (!schema || [schema.openapi, schema.info, schema.paths].some(function (item) { return !isSet(item); })) {
                throw new AppError("Not valid Swagger URL");
            }
        }));
    };
    XanoLegacyResourceController.prototype.transformSingleItemResponse = function (data, fields) {
        fields.forEach(function (field) {
            if (fileFieldTypes.includes(field.item.field) && data[field.name]) {
                data[field.name] = data[field.name]['url'];
            }
            else if (field.item.field === FieldType.RelatedModel && data[field.name] === 0) {
                data[field.name] = null;
            }
        });
        return data;
    };
    XanoLegacyResourceController.prototype.transformArrayItemsResponse = function (data, fields) {
        for (var _i = 0, data_1 = data; _i < data_1.length; _i++) {
            var responseItem = data_1[_i];
            this.transformSingleItemResponse(responseItem, fields);
        }
        return data;
    };
    XanoLegacyResourceController.prototype.serializeModel = function (instance, modelDescription, fields) {
        var data = toPairs(instance.serialize(fields))
            .filter(function (_a) {
            var k = _a[0], v = _a[1];
            var field = modelDescription.dbFields.find(function (i) { return i.name == k; });
            return field && field.editable;
        })
            .map(function (_a) {
            var name = _a[0], value = _a[1];
            var field = modelDescription.dbFields.find(function (i) { return i.name == name; });
            if (fileFieldTypes.includes(field.field)) {
                var url = new URL(value);
                var fileName = getFilenameWithExtension(value);
                var fileExtension = getExtension(value) || 'file';
                var fileMime = getExtensionMime(fileExtension);
                var fileMimeType = isSet(fileMime) ? fileMime.split('/')[0] : undefined;
                var fileType = fileMimeType || fileExtension || 'file';
                var fileSize = 0;
                value = {
                    path: url.pathname,
                    name: fileName,
                    type: fileType,
                    size: fileSize,
                    mime: fileMime,
                    meta: {},
                    url: value
                };
            }
            return [name, value];
        });
        return fromPairs(data);
    };
    XanoLegacyResourceController.prototype.generateUrl = function (apiBaseUrl, endpoints) {
        if (Array.isArray(endpoints)) {
            return [apiBaseUrl].concat(endpoints).join('/');
        }
        else {
            return [apiBaseUrl, endpoints].join('/');
        }
    };
    XanoLegacyResourceController.prototype.getMethodFromCollectionParam = function (modelDescription, methodKeyName) {
        var method = modelDescription.params[methodKeyName];
        if (method === undefined) {
            throw new AppError(methodKeyName + " is not found in collection params");
        }
        return method;
    };
    XanoLegacyResourceController.prototype.getHeaders = function () {
        return [
            {
                name: 'Authorization',
                value: "{-" + HTTP_QUERY_KEY_AUTH_NAME + "-}"
            }
        ];
    };
    XanoLegacyResourceController.prototype.modelGet = function (resource, modelDescription, params, body) {
        var _this = this;
        var options = { resource: resource.uniqueName };
        var apiBaseUrl = resource.params['api_base_url'];
        var page = parseInt(params[PAGE_PARAM], 10) || 1;
        var perPage = isSet(params[PER_PAGE_PARAM]) ? Math.min(params[PER_PAGE_PARAM], 100) : undefined;
        var external = {
            page: page,
            per_page: perPage
        };
        var statements = modelDescription.dbFields
            .filter(function (item) { return params[item.name] !== undefined; })
            .map(function (item) {
            var value = params[item.name];
            return {
                statement: {
                    left: {
                        tag: 'col',
                        operand: item.name
                    },
                    right: {
                        operand: value
                    }
                }
            };
        });
        if (statements.length) {
            external['expression'] = statements;
        }
        if (params[ORDER_BY_PARAM]) {
            var _a = params[ORDER_BY_PARAM].startsWith('-')
                ? [params[ORDER_BY_PARAM].slice(1), false]
                : [params[ORDER_BY_PARAM], true], field = _a[0], ascending = _a[1];
            external['sort'] = [{ sortBy: field, orderBy: ascending ? 'asc' : 'desc' }];
        }
        var query = new HttpQuery();
        query.method = this.getMethodFromCollectionParam(modelDescription, XANO_COLLECTION_MODEL_GET_PARAM_NAME);
        query.url = this.generateUrl(apiBaseUrl, modelDescription.model);
        query.headers = this.getHeaders();
        query.queryParams = [
            {
                name: 'external',
                value: JSON.stringify(external)
            }
        ];
        return this.httpQueryService.requestBody(query, options).pipe(map(function (response) {
            var data = {};
            if (response['items'] !== undefined) {
                var responseData = response;
                data = {
                    results: _this.transformArrayItemsResponse(responseData.items, modelDescription.fields),
                    count: responseData.itemsTotal,
                    has_more: responseData.nextPage !== null,
                    num_pages: responseData.pageTotal,
                    per_page: perPage ? perPage : _this.DEFAULT_PER_PAGE
                };
            }
            else if (Array.isArray(response)) {
                var responseData = response;
                data = {
                    results: _this.transformArrayItemsResponse(responseData, modelDescription.fields),
                    count: responseData.length,
                    has_more: false,
                    num_pages: 1,
                    per_page: perPage ? perPage : _this.DEFAULT_PER_PAGE
                };
            }
            return _this.createGetResponse().deserialize(data, modelDescription.model, undefined);
        }), tap(function (response) {
            response.results.forEach(function (item) {
                item.deserializeAttributes(modelDescription.dbFields);
            });
        }), map(function (response) {
            if (!response) {
                return;
            }
            response.results.forEach(function (item) {
                item.setUp(modelDescription);
            });
            return response;
        }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    XanoLegacyResourceController.prototype.modelGetDetail = function (resource, modelDescription, idField, id, params) {
        var _this = this;
        params = params || {};
        // const id = params[XANO_PRIMARY_KEY_FIELD_NAME];
        // if (id === undefined) {
        //   return throwError(new MissedIdParamError(XANO_PRIMARY_KEY_FIELD_NAME));
        // }
        var options = { resource: resource.uniqueName };
        var apiBaseUrl = resource.params['api_base_url'];
        var query = new HttpQuery();
        query.method = this.getMethodFromCollectionParam(modelDescription, XANO_COLLECTION_MODEL_GET_DETAIL_PARAM_NAME);
        query.url = this.generateUrl(apiBaseUrl, [modelDescription.model, String(id)]);
        query.headers = this.getHeaders();
        return this.httpQueryService.requestBody(query, options).pipe(map(function (response) {
            if (!response) {
                return;
            }
            response = _this.transformSingleItemResponse(response, modelDescription.fields);
            var model = _this.createModel().deserialize(undefined, response);
            model.setUp(modelDescription);
            model.deserializeAttributes(modelDescription.dbFields);
            return model;
        }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    XanoLegacyResourceController.prototype.modelCreate = function (resource, modelDescription, modelInstance, fields) {
        var _this = this;
        var options = { resource: resource.uniqueName };
        var apiBaseUrl = resource.params['api_base_url'];
        var query = new HttpQuery();
        query.method = this.getMethodFromCollectionParam(modelDescription, XANO_COLLECTION_MODEL_CREATE_PARAM_NAME);
        query.url = this.generateUrl(apiBaseUrl, modelDescription.model);
        query.headers = this.getHeaders();
        query.body = this.serializeModel(modelInstance, modelDescription, fields);
        return this.httpQueryService.requestBody(query, options).pipe(map(function (result) {
            if (!result) {
                return;
            }
            result = _this.transformSingleItemResponse(result, modelDescription.fields);
            var model = _this.createModel().deserialize(undefined, result);
            model.setUp(modelDescription);
            model.deserializeAttributes(modelDescription.dbFields);
            return model;
        }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    XanoLegacyResourceController.prototype.modelUpdate = function (resource, modelDescription, modelInstance, fields) {
        var _this = this;
        var id = modelInstance.initialPrimaryKey;
        // if (id === undefined) {
        //   return throwError(new MissedIdParamError(XANO_PRIMARY_KEY_FIELD_NAME));
        // }
        var options = { resource: resource.uniqueName };
        var apiBaseUrl = resource.params['api_base_url'];
        var query = new HttpQuery();
        query.method = this.getMethodFromCollectionParam(modelDescription, XANO_COLLECTION_MODEL_UPDATE_PARAM_NAME);
        query.url = this.generateUrl(apiBaseUrl, [modelDescription.model, String(id)]);
        query.headers = this.getHeaders();
        query.body = this.serializeModel(modelInstance, modelDescription, fields);
        return this.httpQueryService.requestBody(query, options).pipe(map(function (result) {
            if (!result) {
                return;
            }
            result = _this.transformSingleItemResponse(result, modelDescription.fields);
            var instance = _this.createModel().deserialize(modelDescription.model, result);
            instance.setUp(modelDescription);
            instance.deserializeAttributes(modelDescription.dbFields);
            return instance;
        }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    XanoLegacyResourceController.prototype.modelDelete = function (resource, modelDescription, modelInstance) {
        var id = modelInstance.initialPrimaryKey;
        // if (id === undefined) {
        //   return throwError(new MissedIdParamError(XANO_PRIMARY_KEY_FIELD_NAME));
        // }
        var options = { resource: resource.uniqueName };
        var apiBaseUrl = resource.params['api_base_url'];
        var query = new HttpQuery();
        query.method = this.getMethodFromCollectionParam(modelDescription, XANO_COLLECTION_MODEL_DELETE_PARAM_NAME);
        query.url = this.generateUrl(apiBaseUrl, [modelDescription.model, id]);
        query.headers = this.getHeaders();
        return this.httpQueryService
            .requestBody(query, options)
            .pipe(this.apiService.catchApiError(), publishLast(), refCount());
    };
    XanoLegacyResourceController.prototype.uploadFile = function (resource, storage, query, file, path, fileName) {
        var params = storage.extraParams;
        if (!params.upload) {
            return throwError(new AppError('Upload is not configured'));
        }
        var apiBaseUrl = resource.params['api_base_url'];
        var httpQuery = new HttpQuery();
        var httpOptions = { resource: resource.uniqueName, tokens: { file: file } };
        httpQuery.method = params.upload.method;
        httpQuery.url = this.generateUrl(apiBaseUrl, 'upload/attachment');
        httpQuery.headers = this.getHeaders();
        httpQuery.bodyType = HttpContentType.FormData;
        httpQuery.body = [{ name: params.upload.file_parameter, value: '{{file}}' }];
        return this.httpQueryService.executeRequest(httpQuery, httpOptions).pipe(map(function (event) {
            if (event.type == HttpEventType.Response) {
                var mediaBaseUrl = new URL(apiBaseUrl).origin;
                return {
                    result: {
                        uploadedPath: event.body['path'],
                        uploadedUrl: mediaBaseUrl + event.body['path'],
                        response: event
                    },
                    state: {
                        downloadProgress: 1,
                        uploadProgress: 1
                    }
                };
            }
            else if (event.type == HttpEventType.UploadProgress) {
                return {
                    state: {
                        uploadProgress: event.loaded / event.total,
                        downloadProgress: 0,
                        uploadLoaded: event.loaded,
                        uploadTotal: event.total
                    }
                };
            }
            else if (event.type == HttpEventType.DownloadProgress) {
                return {
                    state: {
                        uploadProgress: 1,
                        downloadProgress: event.loaded / event.total,
                        downloadLoaded: event.loaded,
                        downloadTotal: event.total
                    }
                };
            }
        }), this.apiService.catchApiError(), publishLast(), refCount());
    };
    return XanoLegacyResourceController;
}());
export { XanoLegacyResourceController };

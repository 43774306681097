import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { FieldsModule } from '@modules/fields';
import { ModelQueriesModule } from '@modules/model-queries';
import { RoutingModule } from '@modules/routing';
import { SharedModule } from '@shared';
import { UiModule } from '@ui';

import { MassEditComponent } from './components/mass-edit/mass-edit.component';

@NgModule({
  imports: [CommonModule, RoutingModule, ModelQueriesModule, SharedModule, FormsModule, FieldsModule, UiModule],
  declarations: [MassEditComponent]
})
export class MassEditRoutesModule {}

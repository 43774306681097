var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FormControl, FormGroup } from '@angular/forms';
import cloneDeep from 'lodash/cloneDeep';
import { isSet } from '@shared';
import { ListModelDescriptionDataSourceControl } from '../../../model-description-data-source-edit/list-model-description-data-source';
var IteratorCustomizeWorkflowStepForm = /** @class */ (function (_super) {
    __extends(IteratorCustomizeWorkflowStepForm, _super);
    function IteratorCustomizeWorkflowStepForm(dataSourceControl) {
        return _super.call(this, {
            name: new FormControl(''),
            data_source: dataSourceControl,
            sorting_field: new FormControl(undefined),
            sorting_asc: new FormControl(true)
        }) || this;
    }
    IteratorCustomizeWorkflowStepForm.prototype.init = function (instance, options) {
        if (options === void 0) { options = {}; }
        this.instance = instance;
        if (instance) {
            this.controls.name.patchValue(instance.name);
            this.controls.data_source.deserialize(instance.dataSource);
            this.controls.sorting_field.patchValue(instance.sortingField || null);
            this.controls.sorting_asc.patchValue(instance.sortingAsc);
        }
        if (!options.firstInit) {
            this.markAsDirty();
        }
    };
    IteratorCustomizeWorkflowStepForm.prototype.toggleDefaultSorting = function () {
        var control = this.controls.sorting_asc;
        control.patchValue(!control.value);
    };
    IteratorCustomizeWorkflowStepForm.prototype.submit = function () {
        var instance = cloneDeep(this.instance);
        instance.name = this.controls.name.value;
        instance.dataSource = this.controls.data_source.serialize();
        if (isSet(this.controls.sorting_field.value)) {
            instance.sortingField = this.controls.sorting_field.value;
        }
        else {
            instance.sortingField = undefined;
        }
        instance.sortingAsc = this.controls.sorting_asc.value;
        return instance;
    };
    return IteratorCustomizeWorkflowStepForm;
}(FormGroup));
export { IteratorCustomizeWorkflowStepForm };

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { TweenMax } from 'gsap';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, fromEvent, merge, of, Subscription } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';

import { ViewContext, ViewContextElement, ViewContextOutput } from '@modules/customize';
import { FieldType } from '@modules/fields';
import { HOVER_OUTPUT, PRESSED_OUTPUT } from '@modules/list';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { ProjectStorageService } from '@modules/resources';
import {
  Border,
  BorderPosition,
  Color,
  ContainerLayer,
  CustomViewTemplateCounterStore,
  ElementLayer,
  EllipseLayer,
  Fill,
  FillType,
  Frame,
  frameFromFrames,
  FrameLayer,
  FrameTranslate,
  getAllFontFamilies,
  GroupLayer,
  ImageFill,
  isContainerLayer,
  Layer,
  LayerInteractionType,
  LineLayer,
  RectangleLayer,
  resizeLayers,
  TextLayer,
  traverseLayers,
  View
} from '@modules/views';
import {
  getFilename,
  getImageSize,
  isImageMime,
  isSet,
  KeyboardEventKeyCode,
  MouseButton,
  pointsDistance,
  Size,
  TypedChanges
} from '@shared';

import { CustomViewTemplatesController } from '../../services/custom-view-templates-controller/custom-view-templates.controller';
import {
  CreatedLayerSource,
  ViewEditorContainer,
  ViewEditorContext,
  ViewEditorCustomizeSource,
  ViewEditorTool
} from '../../services/view-editor-context/view-editor.context';
import { snapFrame } from '../../utils/guides';

const markViewViewClickProperty = '_markViewViewClickProperty';

export function markViewViewClick(clickEvent: MouseEvent) {
  clickEvent[markViewViewClickProperty] = true;
}

export function isViewViewClick(clickEvent: MouseEvent) {
  return !!clickEvent[markViewViewClickProperty];
}

interface FillItem {
  id: string;
  background?: SafeStyle;
  width?: string;
  height?: string;
  transform?: SafeStyle;
  icon?: {
    icon: string;
    color?: string;
    size: number;
  };
  opacity?: number;
  enabled: boolean;
}

interface BorderItem {
  border: SafeStyle;
  position: number;
  borderRadius: SafeStyle;
  enabled: boolean;
}

@Component({
  selector: 'app-view-editor-view',
  templateUrl: './view-editor-view.component.html',
  providers: [ViewContextElement],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ViewEditorViewComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  @Input() view: View;
  @Input() viewContext: ViewContext;
  @Input() componentLabel = 'component';
  @Input() stateSelectedEnabled = false;
  @Input() templatesEnabled = true;
  @Input() dark = false;
  @Input() analyticsSource: string;
  @Output() importFigmaNode = new EventEmitter<void>();
  @Output() importSketchFile = new EventEmitter<void>();

  @ViewChild('root_element') rootElement: ElementRef;

  x = 0;
  y = 0;
  fills: FillItem[] = [];
  fillsSubscription: Subscription;
  borders: BorderItem[] = [];
  bordersSubscription: Subscription;
  borderRadius: SafeStyle;
  boxShadow: SafeStyle;
  boxShadowSubscription: Subscription;
  displayItems: Layer[] = [];
  displayItemsSubscription: Subscription;
  externalFonts: string[] = [];
  createLayerThreshold = 5;
  creatingLayer: {
    layer: Layer;
    container?: ViewEditorContainer;
  };
  createToolSubscriptions: Subscription[] = [];
  prevFrame = new Frame();
  frameBeforeResize: Frame;
  viewBeforeResize: View;
  templatesApprox: number;

  trackLayerFn(i, item: Layer) {
    return item.id;
  }

  trackFillFn(i, item: FillItem) {
    return item.id;
  }

  constructor(
    public editorContext: ViewEditorContext,
    public contextElement: ViewContextElement,
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private projectStorageService: ProjectStorageService,
    private customViewTemplatesController: CustomViewTemplatesController,
    private customViewTemplateCounterStore: CustomViewTemplateCounterStore,
    private sanitizer: DomSanitizer,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    merge(
      this.editorContext.layerChanged$().pipe(filter(item => item.layer instanceof TextLayer)),
      this.editorContext.layerContainerChanged$()
    )
      .pipe(untilDestroyed(this))
      .subscribe(() => this.updateExternalFonts());

    merge(
      this.editorContext
        .layerChanged$()
        .pipe(filter(event => !!this.view.layers.find(item => item.isSame(event.layer)))),
      this.editorContext.layerContainerChanged$().pipe(filter(event => this.view.layers === event.container))
    )
      .pipe(untilDestroyed(this))
      .subscribe(() => this.updateDisplayItems());

    this.editorContext
      .viewChanged$()
      .pipe(
        filter(event => event.source != ViewEditorCustomizeSource.View),
        untilDestroyed(this)
      )
      .subscribe(() => {
        this.updateFills();
        this.updateBorders();
        this.updateBorderRadius();
        this.updateBoxShadows();
        this.updateDisplayItems();
        this.updateFrameTransform();
        this.updateLayerContext();

        this.prevFrame = cloneDeep(this.view.frame);
      });

    this.editorContext
      .trackKeydown()
      .pipe(untilDestroyed(this))
      .subscribe(e => {
        if (e.keyCode == KeyboardEventKeyCode.Escape && this.editorContext.isCreateTool()) {
          e.stopPropagation();
          this.editorContext.tool$.next(ViewEditorTool.Selection);
        } else if (e.keyCode == KeyboardEventKeyCode.Escape) {
          e.stopPropagation();

          if (this.editorContext.customizingLayers$.value.length) {
            this.editorContext.resetCustomizingLayers();
          }
        } else if (e.keyCode == KeyboardEventKeyCode.Backspace && !(e.metaKey || e.ctrlKey)) {
          e.stopPropagation();

          if (this.editorContext.customizingLayers$.value.length) {
            this.deleteLayers(this.editorContext.customizingLayers$.value);
            this.editorContext.customizingLayers$.next([]);
          }
        } else if (e.keyCode == KeyboardEventKeyCode.C && (e.metaKey || e.ctrlKey)) {
          e.stopPropagation();

          if (this.editorContext.customizingLayers$.value.length) {
            const layers = this.copyLayers(this.editorContext.customizingLayers$.value);
            this.editorContext.bufferLayers(layers);
          }
        } else if (e.keyCode == KeyboardEventKeyCode.X && (e.metaKey || e.ctrlKey)) {
          e.stopPropagation();

          if (this.editorContext.customizingLayers$.value.length) {
            const layers = this.copyLayers(this.editorContext.customizingLayers$.value);
            this.editorContext.bufferLayers(layers);
            this.deleteLayers(layers);
          }
        } else if (e.keyCode == KeyboardEventKeyCode.V && (e.metaKey || e.ctrlKey)) {
          e.stopPropagation();

          if (this.pasteBufferLayers()) {
            e.preventDefault();
          }
        } else if (
          e.keyCode == KeyboardEventKeyCode.G &&
          (e.metaKey || e.ctrlKey) &&
          e.altKey &&
          this.editorContext.customizingLayers$.value.length
        ) {
          e.stopPropagation();
          e.preventDefault();

          this.groupViewLayers(this.editorContext.customizingLayers$.value, {
            frame: true,
            source: CreatedLayerSource.Hotkey
          });
        } else if (
          e.keyCode == KeyboardEventKeyCode.G &&
          (e.metaKey || e.ctrlKey) &&
          this.editorContext.customizingLayers$.value.length
        ) {
          e.stopPropagation();
          e.preventDefault();

          this.groupViewLayers(this.editorContext.customizingLayers$.value, { source: CreatedLayerSource.Hotkey });
        } else if (
          e.keyCode == KeyboardEventKeyCode.Backspace &&
          (e.metaKey || e.ctrlKey) &&
          this.editorContext.customizingLayers$.value.some(item => isContainerLayer(item))
        ) {
          e.stopPropagation();
          e.preventDefault();

          this.ungroupViewLayers(
            this.editorContext.customizingLayers$.value.filter(item => isContainerLayer(item)) as ContainerLayer[],
            { source: CreatedLayerSource.Hotkey }
          );
        }
      });

    fromEvent<ClipboardEvent>(document, 'paste')
      .pipe(
        map(e => {
          const dt: DataTransfer = e.clipboardData || window['clipboardData'];
          return {
            event: e,
            file: dt ? dt.files[0] : undefined
          };
        }),
        filter(({ event, file }) => {
          if (isImageMime(file.type)) {
            event.stopPropagation();
            return true;
          } else {
            return false;
          }
        }),
        switchMap(({ event, file }) => {
          const filePath = ['custom_views', this.editorContext.view$.value.id].join('/');
          const fileName = file.name;
          const fileUrl = URL.createObjectURL(file);

          return combineLatest(
            this.projectStorageService.uploadFile(
              this.currentProjectStore.instance.uniqueName,
              this.currentEnvironmentStore.instance.uniqueName,
              file,
              filePath,
              fileName
            ),
            getImageSize(fileUrl).pipe(catchError(() => of(undefined)))
          ).pipe(
            map(([response, imageSize]) => {
              return {
                fileName: getFilename(fileName),
                url: response.result.uploadedUrl,
                size: imageSize
              };
            })
          );
        }),
        catchError(() => of(undefined)),
        filter(item => item),
        untilDestroyed(this)
      )
      .subscribe(file => {
        this.createImageFileLayer(file.url, file.fileName, file.size);
      });

    this.customViewTemplateCounterStore
      .getApproxFirst$()
      .pipe(untilDestroyed(this))
      .subscribe(value => {
        this.templatesApprox = value;
        this.cd.markForCheck();
      });
  }

  ngOnDestroy(): void {
    if (this.editorContext) {
      this.editorContext.unregisterContainer(this.view.layers);
    }
  }

  ngOnChanges(changes: TypedChanges<ViewEditorViewComponent>): void {
    if (changes.view) {
      if (this.editorContext) {
        if (changes.view.previousValue) {
          this.editorContext.unregisterContainer(changes.view.previousValue.layers);
        }

        this.editorContext.registerContainer(this.view.layers);
      }

      if (!changes.view.firstChange && this.editorContext) {
        this.editorContext.markViewChanged(this.view, ViewEditorCustomizeSource.HistoryMove);
      } else {
        this.updateFills();
        this.updateBorders();
        this.updateBorderRadius();
        this.updateBoxShadows();
        this.updateDisplayItems();
        this.updateExternalFonts();
        this.updateFrameTransform();
        this.updateLayerContext();
      }

      this.prevFrame = cloneDeep(this.view.frame);
    }
  }

  ngAfterViewInit(): void {
    TweenMax.set(this.rootElement.nativeElement, {
      x: this.x,
      y: this.y,
      xPercent: -50,
      yPercent: -50,
      width: this.view.frame.width,
      height: this.view.frame.height
    });
  }

  updateFrameTransform() {
    if (!this.rootElement) {
      return;
    }

    TweenMax.set(this.rootElement.nativeElement, {
      width: this.view.frame.width,
      height: this.view.frame.height
    });
  }

  updateFills() {
    if (this.fillsSubscription) {
      this.fillsSubscription.unsubscribe();
      this.fillsSubscription = undefined;
    }

    const fills$ = [...this.view.fills]
      .reverse()
      .filter(item => item.enabled)
      .map(item => {
        const icon$ =
          item.type == FillType.Icon && item.iconFill
            ? item.iconFill.display$({ context: this.viewContext }).pipe(
                map(value => {
                  return {
                    icon: value.icon,
                    color: value.color,
                    size: isSet(item.iconFill.size)
                      ? item.iconFill.size
                      : Math.min(this.view.frame.width, this.view.frame.height)
                  };
                })
              )
            : of(undefined);
        const css$ = item.css$({ frame: this.view.frame, context: this.viewContext });
        const enabled$ = item.enabledInput ? item.enabled$({ context: this.viewContext }) : of(true);

        return combineLatest(icon$, css$, enabled$).pipe(
          map(([icon, css, enabled]) => {
            return {
              id: item.id,
              background: isSet(css.background) ? this.sanitizer.bypassSecurityTrustStyle(css.background) : undefined,
              width: css.width,
              height: css.height,
              transform: isSet(css.transform) ? this.sanitizer.bypassSecurityTrustStyle(css.transform) : undefined,
              icon: icon,
              opacity: item.type != FillType.Color ? item.opacity : null,
              enabled: enabled
            };
          })
        );
      });

    if (!fills$.length) {
      this.fills = [];
      this.cd.markForCheck();
      return;
    }

    this.fillsSubscription = combineLatest(fills$)
      .pipe(untilDestroyed(this))
      .subscribe(fills => {
        this.fills = fills.filter(item => item.enabled);
        this.cd.markForCheck();
      });
  }

  updateBorders() {
    if (this.bordersSubscription) {
      this.bordersSubscription.unsubscribe();
      this.bordersSubscription = undefined;
    }

    const borders$ = [...this.view.borders]
      .reverse()
      .filter(item => item.enabled)
      .map(item => {
        const border$ = item.cssBorder$({ context: this.viewContext });
        const enabled$ = item.enabledInput ? item.enabled$({ context: this.viewContext }) : of(true);

        return combineLatest(border$, enabled$).pipe(
          map(([border, enabled]) => {
            let position: number;

            if (item.position == BorderPosition.Center) {
              position = -item.thickness * 0.5;
            } else if (item.position == BorderPosition.Outside) {
              position = -item.thickness;
            } else {
              position = 0;
            }

            const borderRadius = this.view.cornerRadius.cssBorderRadius(position * -1);

            return {
              border: isSet(border) ? this.sanitizer.bypassSecurityTrustStyle(border) : undefined,
              position: position,
              borderRadius: this.sanitizer.bypassSecurityTrustStyle(borderRadius),
              enabled: enabled
            };
          })
        );
      });

    if (!borders$.length) {
      this.borders = [];
      this.cd.markForCheck();
      return;
    }

    this.bordersSubscription = combineLatest(borders$)
      .pipe(untilDestroyed(this))
      .subscribe(borders => {
        this.borders = borders.filter(item => item.enabled && isSet(item.border));
        this.cd.markForCheck();
      });
  }

  updateBorderRadius() {
    this.borderRadius = this.sanitizer.bypassSecurityTrustStyle(this.view.cornerRadius.cssBorderRadius());
    this.cd.markForCheck();
  }

  updateBoxShadows() {
    if (this.boxShadowSubscription) {
      this.boxShadowSubscription.unsubscribe();
      this.boxShadowSubscription = undefined;
    }

    const shadows$ = this.view.shadows
      .filter(item => item.enabled)
      .map(item => {
        const boxShadow$ = item.cssBoxShadow$({ context: this.viewContext });
        const enabled$ = item.enabledInput ? item.enabled$({ context: this.viewContext }) : of(true);

        return combineLatest(boxShadow$, enabled$).pipe(
          map(([boxShadow, enabled]) => {
            return {
              boxShadow: boxShadow,
              enabled: enabled
            };
          })
        );
      });

    if (!shadows$.length) {
      this.boxShadow = undefined;
      this.cd.markForCheck();
      return;
    }

    this.boxShadowSubscription = combineLatest(shadows$)
      .pipe(untilDestroyed(this))
      .subscribe(shadows => {
        this.boxShadow = this.sanitizer.bypassSecurityTrustStyle(
          shadows
            .filter(item => item.enabled)
            .map(item => item.boxShadow)
            .join(',')
        );
        this.cd.markForCheck();
      });
  }

  updateDisplayItems() {
    if (this.displayItemsSubscription) {
      this.displayItemsSubscription.unsubscribe();
      this.displayItemsSubscription = undefined;
    }

    const items$ = this.view.layers
      .filter(item => item.visible)
      .map(item => {
        const visible$ = item.visibleInput ? item.visible$({ context: this.viewContext }) : of(true);

        return combineLatest(visible$).pipe(
          map(([visible]) => {
            return {
              item: item,
              visible: visible
            };
          })
        );
      });

    if (!items$.length) {
      this.displayItems = [];
      this.cd.markForCheck();
      return;
    }

    this.displayItemsSubscription = combineLatest(items$)
      .pipe(untilDestroyed(this))
      .subscribe(items => {
        this.displayItems = items.filter(item => item.visible).map(item => item.item);
        this.cd.markForCheck();
      });
  }

  updateExternalFonts() {
    this.externalFonts = getAllFontFamilies(this.view.layers);
    this.cd.markForCheck();
  }

  updateLayerContext() {
    const hoverOutput = this.view.interactions.some(item => item.type == LayerInteractionType.HoverOutput);
    const pressedOutput = this.view.interactions.some(item => item.type == LayerInteractionType.PressedOutput);
    const anyOutputs = hoverOutput || pressedOutput;
    const registered = this.contextElement.isRegistered();

    if (anyOutputs && !registered) {
      this.contextElement.initElement({
        uniqueName: 'view',
        name: 'Canvas',
        icon: 'canvas'
      });
    } else if (anyOutputs && registered) {
      this.contextElement.initInfo(
        {
          name: 'Canvas',
          icon: 'canvas'
        },
        true
      );
    } else if (!anyOutputs && registered) {
      this.contextElement.unregister();
    }

    if (anyOutputs) {
      const outputs: ViewContextOutput[] = [];

      if (hoverOutput) {
        outputs.push({
          uniqueName: HOVER_OUTPUT,
          name: `Layer is hovered`,
          icon: 'target',
          fieldType: FieldType.Boolean,
          defaultValue: false,
          external: true
        });
      }

      if (pressedOutput) {
        outputs.push({
          uniqueName: PRESSED_OUTPUT,
          name: `Layer is pressed`,
          icon: 'select_all',
          fieldType: FieldType.Boolean,
          defaultValue: false,
          external: true
        });
      }

      if (
        !isEqual(
          this.contextElement.outputs.map(item => item.uniqueName),
          outputs.map(item => item.uniqueName)
        )
      ) {
        this.contextElement.setOutputs(outputs);
      }
    }
  }

  createLayerFromTool(frame: Partial<Frame>): Layer {
    const tool = this.editorContext.tool$.value;
    const defaultFillColor = new Color({ red: 217 / 255, green: 217 / 255, blue: 217 / 255 });

    if (tool == ViewEditorTool.Rectangle) {
      const layer = new RectangleLayer();

      layer.generateId();
      layer.frame = new Frame(frame);
      layer.name = this.editorContext.generateLayerName(layer);

      const fill = new Fill({ color: defaultFillColor });

      fill.generateId();
      layer.fills = [fill];

      return layer;
    } else if (tool == ViewEditorTool.Ellipse) {
      const layer = new EllipseLayer();

      layer.generateId();
      layer.frame = new Frame(frame);
      layer.name = this.editorContext.generateLayerName(layer);

      const fill = new Fill({ color: defaultFillColor });

      fill.generateId();
      layer.fills = [fill];

      return layer;
    } else if (tool == ViewEditorTool.Line) {
      const layer = new LineLayer();

      layer.generateId();
      layer.frame = new Frame(frame);
      layer.name = this.editorContext.generateLayerName(layer);

      const border = new Border({ color: defaultFillColor });

      // border.generateId();
      layer.borders = [border];

      return layer;
    } else if (tool == ViewEditorTool.Text) {
      const layer = new TextLayer();

      layer.generateId();
      layer.frame = new Frame(frame);
      layer.name = this.editorContext.generateLayerName(layer);

      const color = new Color();
      const fill = new Fill({ color: color });

      fill.generateId();
      fill.color = color;

      layer.fills = [fill];

      return layer;
    } else if (tool == ViewEditorTool.Image) {
      const layer = new RectangleLayer();
      const file = this.editorContext.toolFile$.value;
      const imageFill = new ImageFill({ image: file.url });

      layer.generateId();
      layer.frame = new Frame(frame);
      layer.name = this.editorContext.generateLayerName(layer, { defaultName: file.fileName });
      layer.constrainProportion = file.size.height != 0 ? file.size.width / file.size.height : 1;

      const fill = new Fill({ type: FillType.Image, imageFill: imageFill });

      fill.generateId();
      layer.fills = [fill];

      return layer;
    } else if (tool == ViewEditorTool.Element) {
      const layer = new ElementLayer();
      const toolElement = this.editorContext.toolElement$.value;

      layer.generateId();
      layer.frame = new Frame(frame);
      layer.name = this.editorContext.generateLayerName(layer);
      layer.widthFluid = toolElement.widthFluid;
      layer.heightFluid = toolElement.heightFluid;
      layer.element = toolElement.element;

      if (toolElement.width) {
        layer.frame.width = toolElement.width;
      }

      if (toolElement.height) {
        layer.frame.height = toolElement.height;
      }

      return layer;
    } else if (tool == ViewEditorTool.Frame) {
      const layer = new FrameLayer();

      layer.generateId();
      layer.frame = new Frame(frame);
      layer.name = this.editorContext.generateLayerName(layer);

      return layer;
    }
  }

  appendViewLayers(layers: Layer[], source: CreatedLayerSource): { container: ViewEditorContainer } {
    const view = this.editorContext.view$.value;
    const targetLayer = this.editorContext.getLastCustomizingLayer();
    let layerContainer: ViewEditorContainer;

    if (targetLayer && isContainerLayer(targetLayer)) {
      layerContainer = this.editorContext.getContainer(targetLayer.layers);
    } else {
      layerContainer = targetLayer ? this.editorContext.getLayerContainer(targetLayer) : undefined;
    }

    const container = layerContainer ? layerContainer.container : view.layers;
    const targetIndex = targetLayer && container ? container.findIndex(item => item.isSame(targetLayer)) : -1;

    layers.forEach(item => {
      if (layerContainer) {
        item.frame.x -= layerContainer.options.translate.x || 0;
        item.frame.y -= layerContainer.options.translate.y || 0;
      }
    });

    if (targetIndex != -1) {
      container.splice(targetIndex + 1, 0, ...layers);
    } else {
      container.push(...layers);
    }

    layers.forEach(layer => this.editorContext.registerCreatedLayer(layer, source));

    this.editorContext.markLayerContainerChanged(container, ViewEditorCustomizeSource.View);
    this.cd.markForCheck();

    return {
      container: layerContainer
    };
  }

  groupViewLayers(childLayers: Layer[], options: { frame?: boolean; source?: CreatedLayerSource } = {}) {
    const container = this.editorContext.getLayerContainer(childLayers[0]);
    const allContainers = [container.container];
    let index = container.container.findIndex(item => item.isSame(childLayers[0]));

    const containerLayer = options.frame ? new FrameLayer() : new GroupLayer();
    const frames = childLayers.map(childLayer => {
      const childContainer = this.editorContext.getLayerContainer(childLayer);
      return new FrameTranslate({
        frame: childLayer.frame,
        translate: childContainer ? childContainer.options.translate : undefined
      });
    });
    const frame = frameFromFrames(frames);
    if (!frame) {
      return;
    }

    containerLayer.generateId();
    containerLayer.frame = frame;
    containerLayer.frame.x -= container.options.translate.x;
    containerLayer.frame.y -= container.options.translate.y;
    containerLayer.name = this.editorContext.generateLayerName(containerLayer);

    childLayers.forEach(childLayer => {
      const childContainer = this.editorContext.getLayerContainer(childLayer);
      const childIndex = childContainer.container.findIndex(item => item.isSame(childLayer));
      if (childIndex !== -1) {
        childContainer.container.splice(childIndex, 1);
        containerLayer.layers.push(childLayer);

        const childViewPosition = {
          x: childLayer.frame.x + childContainer.options.translate.x,
          y: childLayer.frame.y + childContainer.options.translate.y
        };

        childLayer.frame.x = childViewPosition.x - container.options.translate.x - containerLayer.frame.x;
        childLayer.frame.y = childViewPosition.y - container.options.translate.y - containerLayer.frame.y;

        if (!allContainers.includes(childContainer.container)) {
          allContainers.push(childContainer.container);
        }

        if (childContainer === container && childIndex < index) {
          --index;
        }
      }
    });

    container.container.splice(index, 0, containerLayer);

    this.editorContext.registerCreatedLayer(containerLayer, options.source);
    this.editorContext.setCustomizingLayer(containerLayer);

    this.editorContext.markLayersChanged(containerLayer.layers, ViewEditorCustomizeSource.View);
    allContainers.forEach(item => this.editorContext.markLayerContainerChanged(item, ViewEditorCustomizeSource.View));
    this.cd.markForCheck();
  }

  ungroupViewLayers(containerLayers: ContainerLayer[], options: { source?: CreatedLayerSource } = {}) {
    const allContainers = [];
    const allLayers = [];

    containerLayers.forEach(groupLayer => {
      const parentContainer = this.editorContext.getLayerContainer(groupLayer);
      const groupContainer = this.editorContext.getContainer(groupLayer.layers);
      const groupIndex = parentContainer.container.findIndex(item => item.isSame(groupLayer));

      if (groupIndex === -1) {
        return;
      }

      parentContainer.container.splice(groupIndex, 1, ...groupLayer.layers);

      groupLayer.layers.forEach(layer => {
        layer.frame.x = layer.frame.x + groupContainer.options.translate.x;
        layer.frame.y = layer.frame.y + groupContainer.options.translate.y;

        allLayers.push(layer);
      });

      if (!allContainers.includes(parentContainer.container)) {
        allContainers.push(parentContainer.container);
      }
    });

    this.editorContext.setCustomizingLayer(...allLayers);

    this.editorContext.markLayersChanged(allLayers, ViewEditorCustomizeSource.View);
    allContainers.forEach(item => this.editorContext.markLayerContainerChanged(item, ViewEditorCustomizeSource.View));
    this.cd.markForCheck();
  }

  createViewLayerFromTool(
    frame: Partial<Frame>,
    source: CreatedLayerSource
  ): { layer: Layer; container?: ViewEditorContainer } {
    const layer = this.createLayerFromTool(frame);

    if (!layer) {
      return;
    }

    const append = this.appendViewLayers([layer], source);

    return {
      layer: layer,
      container: append.container
    };
  }

  createViewLayers(layers: Layer[]) {
    traverseLayers(layers, item => item.generateId());
    this.appendViewLayers(layers, CreatedLayerSource.Buffer);

    this.editorContext.setCustomizingLayer(...layers);
  }

  createImageFileLayer(url: string, fileName: string, fileSize?: { width: number; height: number }) {
    const size: Size = fileSize || { width: 64, height: 64 };
    const position = { x: this.view.frame.width * 0.5, y: this.view.frame.height * 0.5 };
    const frame = { x: position.x - size.width * 0.5, y: position.y - size.height * 0.5, ...size };
    const layer = new RectangleLayer();
    const imageFill = new ImageFill({ image: url });

    layer.generateId();
    layer.frame = new Frame(frame);
    layer.name = this.editorContext.generateLayerName(layer, { defaultName: fileName });
    layer.constrainProportion = size.height != 0 ? size.width / size.height : 1;

    const fill = new Fill({ type: FillType.Image, imageFill: imageFill });

    fill.generateId();
    layer.fills = [fill];

    this.appendViewLayers([layer], CreatedLayerSource.Buffer);
  }

  deleteLayer(layer: Layer) {
    const container = this.editorContext.getLayerContainer(layer);
    if (!container) {
      return;
    }

    const index = container.container.findIndex(item => item.isSame(layer));
    if (index === -1) {
      return;
    }

    container.container.splice(index, 1);
    this.cd.markForCheck();
    this.editorContext.markLayerContainerChanged(container.container, ViewEditorCustomizeSource.View);
  }

  deleteLayers(layers: Layer[]) {
    const containers = [];

    layers.forEach(layer => {
      const container = this.editorContext.getLayerContainer(layer);
      if (!container) {
        return;
      }

      const index = container.container.findIndex(item => item.isSame(layer));
      if (index === -1) {
        return;
      }

      container.container.splice(index, 1);

      if (!containers.includes(container.container)) {
        containers.push(container.container);
      }
    });

    this.cd.markForCheck();
    containers.forEach(item => this.editorContext.markLayerContainerChanged(item, ViewEditorCustomizeSource.View));
  }

  copyLayers(sourceLayers: Layer[]): Layer[] {
    const layers = cloneDeep(sourceLayers);

    layers.forEach(layer => {
      const container = this.editorContext.getLayerContainer(layer);
      if (container) {
        layer.frame.x += container.options.translate.x || 0;
        layer.frame.y += container.options.translate.y || 0;
      }
    });

    return layers;
  }

  pasteBufferLayers(): Layer[] {
    const layers = this.editorContext.getBufferLayers();
    if (!layers.length) {
      return [];
    }

    const newLayers = cloneDeep(layers);
    this.createViewLayers(newLayers);

    return newLayers;
  }

  getMouseEventViewPosition(event: MouseEvent, bounds: DOMRect) {
    return {
      x: Math.round((event.clientX - bounds.left) / this.editorContext.viewportScale$.value),
      y: Math.round((event.clientY - bounds.top) / this.editorContext.viewportScale$.value)
    };
  }

  getMouseEventsFrame(
    downEvent: MouseEvent,
    moveEvent: MouseEvent
  ): {
    frame: Partial<Frame>;
    downPosition: { x: number; y: number };
    movePosition: { x: number; y: number };
    xInverse: boolean;
    yInverse: boolean;
  } {
    const bounds = this.rootElement.nativeElement.getBoundingClientRect();
    const downPosition = this.getMouseEventViewPosition(downEvent, bounds);
    const movePosition = this.getMouseEventViewPosition(moveEvent, bounds);
    const xInverse = movePosition.x < downPosition.x;
    const yInverse = movePosition.y < downPosition.y;
    let width = Math.abs(movePosition.x - downPosition.x);
    let height = Math.abs(movePosition.y - downPosition.y);

    if (moveEvent.shiftKey) {
      const max = Math.max(width, height);
      width = height = max;
    }

    const x = xInverse ? downPosition.x - width : downPosition.x;
    const y = yInverse ? downPosition.y - height : downPosition.y;

    return {
      frame: {
        x: x,
        y: y,
        width: width,
        height: height
      },
      downPosition: downPosition,
      movePosition: movePosition,
      xInverse: xInverse,
      yInverse: yInverse
    };
  }

  onMouseDown(downEvent: MouseEvent) {
    if (!this.editorContext.isCreateTool()) {
      return;
    }

    const subscriptions = [];

    subscriptions.push(
      fromEvent<MouseEvent>(document, 'mousemove')
        .pipe(untilDestroyed(this))
        .subscribe(moveEvent => {
          moveEvent.preventDefault();

          this.onMouseMove(downEvent, moveEvent);
        })
    );

    subscriptions.push(
      fromEvent<MouseEvent>(document, 'mouseup')
        .pipe(
          filter(e => e.button == MouseButton.Main),
          untilDestroyed(this)
        )
        .subscribe(e => this.onMouseUp(e))
    );

    this.createToolSubscriptions = subscriptions;
  }

  onMouseMove(downEvent: MouseEvent, moveEvent: MouseEvent) {
    if (!this.creatingLayer) {
      const originDistance = pointsDistance(downEvent.clientX, downEvent.clientY, moveEvent.clientX, moveEvent.clientY);
      if (originDistance >= this.createLayerThreshold) {
        const eventsFrame = this.getMouseEventsFrame(downEvent, moveEvent);

        this.creatingLayer = this.createViewLayerFromTool(eventsFrame.frame, CreatedLayerSource.Tool);

        this.editorContext.removeHorizontalGuides();
        this.editorContext.removeVerticalGuides();
      }
    }

    if (this.creatingLayer) {
      const eventsFrame = this.getMouseEventsFrame(downEvent, moveEvent);
      const newFrame = new Frame(eventsFrame.frame);
      const otherFrames = this.editorContext.getFrames({
        onlyContainers: [this.view.layers],
        exceptLayers: [this.creatingLayer.layer]
      });
      const snap = snapFrame({
        base: new FrameTranslate({
          frame: newFrame,
          translate: this.creatingLayer.container ? this.creatingLayer.container.options.translate : undefined
        }),
        others: otherFrames,
        horizontalSnapX: eventsFrame.xInverse,
        horizontalSnapWidth: true,
        horizontalOriginSnaps: eventsFrame.xInverse ? [0] : [1],
        verticalSnapY: eventsFrame.yInverse,
        verticalSnapHeight: true,
        verticalOriginSnaps: eventsFrame.yInverse ? [0] : [1]
      });

      const snapSizes = [snap.updateFrame.width, snap.updateFrame.height].filter(item => isSet(item));

      if (moveEvent.shiftKey && snapSizes.length) {
        const max = Math.max(...snapSizes);
        snap.updateFrame.width = snap.updateFrame.height = max;

        snap.updateFrame.x = eventsFrame.xInverse
          ? eventsFrame.downPosition.x - snap.updateFrame.width
          : eventsFrame.downPosition.x;
        snap.updateFrame.y = eventsFrame.yInverse
          ? eventsFrame.downPosition.y - snap.updateFrame.height
          : eventsFrame.downPosition.y;
      }

      newFrame.patch(snap.updateFrame);

      if (moveEvent.altKey) {
        newFrame.x = eventsFrame.xInverse ? newFrame.x : newFrame.x - newFrame.width;
        newFrame.y = eventsFrame.yInverse ? newFrame.y : newFrame.y - newFrame.height;
        newFrame.width *= 2;
        newFrame.height *= 2;
      }

      this.creatingLayer.layer.applyMouseEventsFrame(newFrame, {
        translate: this.creatingLayer.container ? this.creatingLayer.container.options.translate : undefined,
        xInverse: eventsFrame.xInverse,
        yInverse: eventsFrame.yInverse
      });

      this.editorContext.markLayersChanged([this.creatingLayer.layer], ViewEditorCustomizeSource.View);
      this.editorContext.setHorizontalGuides(snap.horizontalGuides);
      this.editorContext.setVerticalGuides(snap.verticalGuides);
    }
  }

  onMouseUp(e: MouseEvent) {
    const tool = this.editorContext.tool$.value;

    if (!this.creatingLayer) {
      const bounds = this.rootElement.nativeElement.getBoundingClientRect();
      const position = this.getMouseEventViewPosition(e, bounds);

      if (tool == ViewEditorTool.Text) {
        this.creatingLayer = this.createViewLayerFromTool(
          { x: position.x, y: position.y - 20 * 0.5, width: 120, height: 20 },
          CreatedLayerSource.Tool
        );
        this.creatingLayer.layer.widthFluid = true;
        this.creatingLayer.layer.heightFluid = true;
      } else if (tool == ViewEditorTool.Image) {
        const file = this.editorContext.toolFile$.value;
        const size: Size = file.size || { width: 64, height: 64 };

        this.creatingLayer = this.createViewLayerFromTool(
          { x: position.x - size.width * 0.5, y: position.y - size.height * 0.5, ...size },
          CreatedLayerSource.Tool
        );
      } else if (tool == ViewEditorTool.Element) {
        const size: Size = { width: 100, height: 60 };

        this.creatingLayer = this.createViewLayerFromTool(
          { x: position.x - size.width * 0.5, y: position.y - size.height * 0.5, ...size },
          CreatedLayerSource.Tool
        );
      }
    }

    if (this.creatingLayer) {
      this.editorContext.setCustomizingLayer(this.creatingLayer.layer);
    }

    this.createToolSubscriptions.forEach(item => item.unsubscribe());
    this.createToolSubscriptions = [];

    this.creatingLayer = undefined;
    this.editorContext.tool$.next(ViewEditorTool.Selection);

    this.editorContext.removeHorizontalGuides();
    this.editorContext.removeVerticalGuides();
  }

  resizeViewLayers(originalFrame: Frame, originalView: View) {
    const newFrame = this.view.frame;
    const sizeChanged = !isEqual(newFrame.getSize(), this.prevFrame.getSize());
    if (!sizeChanged) {
      return;
    }

    resizeLayers({
      layers: this.view.layers,
      originalLayers: originalView.layers,
      originalFrame: originalFrame,
      newFrame: newFrame
    });

    this.editorContext.markGlobalLayersChange(ViewEditorCustomizeSource.View);
  }

  onLabelClick(e: MouseEvent) {
    if (this.editorContext.navigateMode$.value) {
      return;
    }

    this.editorContext.customizeView();
    markViewViewClick(e);
  }

  onResizeStarted() {
    this.editorContext.resizingView$.next(true);
    this.frameBeforeResize = cloneDeep(this.view.frame);
    this.viewBeforeResize = cloneDeep(this.view);
  }

  onResizeUpdateFrame(frame: Partial<Frame>) {
    this.view.frame.patch(frame);

    this.editorContext.markViewChanged(this.view, ViewEditorCustomizeSource.View);
    this.updateFrameTransform();

    if (this.view.resizeContent) {
      this.resizeViewLayers(this.frameBeforeResize, this.viewBeforeResize);
    }

    this.prevFrame = cloneDeep(this.view.frame);
  }

  onResizeFinished() {
    this.editorContext.resizingView$.next(false);
    this.frameBeforeResize = undefined;
    this.viewBeforeResize = undefined;
  }

  openCustomViewTemplates() {
    this.customViewTemplatesController
      .chooseTemplate({
        viewCustomizeEnabled: false,
        stateSelectedEnabled: this.stateSelectedEnabled,
        componentLabel: this.componentLabel,
        analyticsSource: 'view_editor'
      })
      .pipe(
        filter(result => !result.cancelled),
        untilDestroyed(this)
      )
      .subscribe(viewResult => {
        this.editorContext.updateView(viewResult.view);
      });
  }
}

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { AppConfigService, LocalStorage } from '@core';
import { isProductionLocation, isSet, SingletonStore, stripEnd } from '@shared';

import { Domain } from '../domain';
import { DomainService } from '../services/domain/domain.service';

@Injectable({
  providedIn: 'root'
})
export class DomainStore extends SingletonStore<Domain> {
  lastFetch = new BehaviorSubject<Domain>(undefined);

  constructor(
    private domainService: DomainService,
    private appConfigService: AppConfigService,
    private localStorage: LocalStorage
  ) {
    super();
    this.getFirst().subscribe(domain => {
      if (!domain) {
        return;
      }

      if (isSet(domain.language) && !isSet(this.localStorage.getLanguage())) {
        this.localStorage.setLanguage(domain.language);
      }

      if (isSet(domain.timezone) && !isSet(this.localStorage.getTimezone())) {
        this.localStorage.setTimezone(domain.timezone);
      }
    });
  }

  getCurrentDomain(): { domain: string; custom: boolean } {
    const subdomainSuffix = `.${this.appConfigService.subdomainsBaseDomain}`;

    if (window.location.hostname.toLowerCase().endsWith(subdomainSuffix.toLowerCase())) {
      return {
        domain: stripEnd(window.location.hostname, subdomainSuffix),
        custom: false
      };
    }

    return {
      domain: window.location.hostname,
      custom: true
    };
  }

  protected executeFetch(): Observable<Domain> {
    // if (!isProductionLocation()) {
    //   return of(undefined);
    // }

    const domain = this.getCurrentDomain();

    // let webHostName: string;
    //
    // try {
    //   webHostName = new URL(this.appConfigService.webBaseUrl).hostname;
    // } catch (e) {}
    //
    // if (domain == webHostName) {
    //   return of(undefined);
    // }

    return this.domainService.get(domain.domain, domain.custom).pipe(catchError(() => of(undefined)));
  }

  protected fetch(): Observable<Domain> {
    if (this.lastFetch.value !== undefined) {
      return of(this.lastFetch.value);
    }

    return this.executeFetch().pipe(
      map(domain => {
        const result = isSet(domain) ? domain : null;
        this.lastFetch.next(result);
        return result;
      })
    );
  }

  public reset() {
    super.reset();
    this.lastFetch.next(undefined);
  }
}

import { Injectable, Injector } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AppFormGroup, FormUtils } from '@common/form-utils';
import { PopupService } from '@common/popups';
import { MenuGeneratorService } from '@modules/menu';
import { ProjectSettingsService } from '@modules/project-settings';
import { ProjectTokenService, SecretTokenService } from '@modules/projects';
import {
  IsOptionsValidResult,
  SmartSuiteGeneratorService,
  SmartSuiteParamsOptions
} from '@modules/resource-generators';
import { ResourceParamsResult } from '@modules/resources';

import { BaseResourceSettingsForm } from '../base-resource-settings/base-resource-settings.form';

@Injectable()
export class SmartSuiteResourceSettingsForm extends BaseResourceSettingsForm<SmartSuiteParamsOptions> {
  form = new AppFormGroup({
    account_id: new FormControl(null, Validators.required),
    key: new FormControl(null, Validators.required),
    solution_id: new FormControl(null, Validators.required),
    sync: new FormControl(true)
  });

  constructor(
    private smartSuiteGeneratorService: SmartSuiteGeneratorService,
    protected secretTokenService: SecretTokenService,
    protected formUtils: FormUtils,
    protected projectSettingsService: ProjectSettingsService,
    protected projectTokenService: ProjectTokenService,
    protected popupService: PopupService,
    protected menuGeneratorService: MenuGeneratorService,
    protected injector: Injector
  ) {
    super(
      secretTokenService,
      formUtils,
      projectSettingsService,
      projectTokenService,
      popupService,
      menuGeneratorService,
      injector
    );
  }

  initResourceValue(): Observable<void> {
    return this.smartSuiteGeneratorService.getParamsOptions(this.project, this.environment, this.resource).pipe(
      map(result => {
        this.form.patchValue({
          account_id: result.accountId,
          key: result.key,
          solution_id: result.solutionId,
          sync: !!this.resource.isSynced()
        });
      })
    );
  }

  getOptions(): SmartSuiteParamsOptions {
    return {
      accountId: this.form.value['account_id'],
      key: this.form.value['key'],
      solutionId: this.form.value['solution_id']
    };
  }

  isOptionsValid(): Observable<IsOptionsValidResult> {
    return this.smartSuiteGeneratorService.isOptionsValid(this.getOptions());
  }

  getParams(): ResourceParamsResult | Observable<ResourceParamsResult> {
    const options = this.getOptions();
    return this.smartSuiteGeneratorService.generateParams(this.project, this.environment, this.typeItem, options).pipe(
      map(result => {
        return {
          ...result,
          resourceName: this.resourceForm.value['name'],
          sync: this.form.value['sync'],
          syncModelDescriptions: this.getParamsSyncModelDescriptions(result)
        };
      })
    );
  }
}

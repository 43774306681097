import { AutomationTriggerType } from '../automation-trigger-type';

export abstract class AutomationTrigger {
  type: AutomationTriggerType;

  constructor(options: Partial<AutomationTrigger> = {}) {
    Object.assign(this, options);
  }

  abstract deserialize(data: Object): this;

  abstract serialize(): Object;

  public getTypeStr(): string {
    if (this.type == AutomationTriggerType.Webhook) {
      return 'webhook';
    } else if (this.type == AutomationTriggerType.Interval) {
      return 'interval';
    } else if (this.type == AutomationTriggerType.Timetable) {
      return 'schedule';
    } else if (this.type == AutomationTriggerType.Model) {
      return 'on data change';
    } else if (this.type == AutomationTriggerType.Manual) {
      return 'manual';
    }
  }

  public getTypeIcon(): string {
    if (this.type == AutomationTriggerType.Webhook) {
      return 'antenna';
    } else if (this.type == AutomationTriggerType.Interval) {
      return 'time';
    } else if (this.type == AutomationTriggerType.Timetable) {
      return 'calendar';
    } else if (this.type == AutomationTriggerType.Model) {
      return 'data';
    } else if (this.type == AutomationTriggerType.Manual) {
      return 'hand';
    }
  }
}

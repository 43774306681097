import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-popup2-description',
  templateUrl: './popup2-description.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class Popup2DescriptionComponent implements OnInit {
  @Input() align: 'left' | 'center' | 'right' = 'left';

  constructor() {}

  ngOnInit() {}
}

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../project-property-menu/project-property-menu.component.ngfactory";
import * as i2 from "../project-property-menu/project-property-menu.component";
import * as i3 from "../../../projects/stores/project-property.store";
import * as i4 from "@angular/cdk/overlay";
import * as i5 from "@angular/cdk/bidi";
import * as i6 from "./project-property-menu-overlay.component";
var styles_ProjectPropertyMenuOverlayComponent = [];
var RenderType_ProjectPropertyMenuOverlayComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ProjectPropertyMenuOverlayComponent, data: {} });
export { RenderType_ProjectPropertyMenuOverlayComponent as RenderType_ProjectPropertyMenuOverlayComponent };
function View_ProjectPropertyMenuOverlayComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["class", "popover2 overlay-content overlay-content_animated"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "div", [["class", "popover2__inner"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "app-project-property-menu", [], null, [[null, "propertySelect"], [null, "createPropertyClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("propertySelect" === en)) {
        _co.propertySelect.emit($event);
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } if (("createPropertyClick" === en)) {
        _co.createPropertyClick.emit();
        var pd_1 = (_co.close() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i1.View_ProjectPropertyMenuComponent_0, i1.RenderType_ProjectPropertyMenuComponent)), i0.ɵdid(3, 245760, null, 0, i2.ProjectPropertyMenuComponent, [i3.ProjectPropertyStore, i0.ChangeDetectorRef], { type: [0, "type"], filterOptions: [1, "filterOptions"] }, { propertySelect: "propertySelect", createPropertyClick: "createPropertyClick" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.type; var currVal_1 = _co.filterOptions; _ck(_v, 3, 0, currVal_0, currVal_1); }, null); }
export function View_ProjectPropertyMenuOverlayComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(402653184, 1, { cdkConnectedOverlay: 0 }), (_l()(), i0.ɵand(16777216, null, null, 2, function (_v, en, $event) { var ad = true; var _co = _v.component; if (("backdropClick" === en)) {
        var pd_0 = (_co.setDropdownOpened(false) !== false);
        ad = (pd_0 && ad);
    } if (("detach" === en)) {
        var pd_1 = (_co.setDropdownOpened(false) !== false);
        ad = (pd_1 && ad);
    } return ad; }, View_ProjectPropertyMenuOverlayComponent_1)), i0.ɵdid(2, 671744, [[1, 4]], 0, i4.CdkConnectedOverlay, [i4.Overlay, i0.TemplateRef, i0.ViewContainerRef, i4.ɵc, [2, i5.Directionality]], { origin: [0, "origin"], positions: [1, "positions"], backdropClass: [2, "backdropClass"], panelClass: [3, "panelClass"], open: [4, "open"], hasBackdrop: [5, "hasBackdrop"], growAfterOpen: [6, "growAfterOpen"], push: [7, "push"] }, { backdropClick: "backdropClick", detach: "detach" }), i0.ɵpad(3, 2)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.origin; var currVal_1 = _co.popoverPositions; var currVal_2 = "popover2-backdrop"; var currVal_3 = _ck(_v, 3, 0, "overlay", "overlay_horizontal_center"); var currVal_4 = _co.dropdownOpened; var currVal_5 = true; var currVal_6 = true; var currVal_7 = true; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }, null); }
export function View_ProjectPropertyMenuOverlayComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-project-property-menu-overlay", [], null, null, null, View_ProjectPropertyMenuOverlayComponent_0, RenderType_ProjectPropertyMenuOverlayComponent)), i0.ɵdid(1, 4440064, null, 0, i6.ProjectPropertyMenuOverlayComponent, [i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ProjectPropertyMenuOverlayComponentNgFactory = i0.ɵccf("app-project-property-menu-overlay", i6.ProjectPropertyMenuOverlayComponent, View_ProjectPropertyMenuOverlayComponent_Host_0, { type: "type", origin: "origin", trigger: "trigger", triggerManual: "triggerManual", filterOptions: "filterOptions" }, { propertySelect: "propertySelect", createPropertyClick: "createPropertyClick" }, []);
export { ProjectPropertyMenuOverlayComponentNgFactory as ProjectPropertyMenuOverlayComponentNgFactory };

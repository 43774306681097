import { AfterViewInit, ChangeDetectorRef, Directive, ElementRef, Input, OnDestroy } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { fromEvent } from 'rxjs';

import { LocalStorage } from '@core';

@Directive({
  selector: '[appHideBanner]'
})
export class HideBannerDirective implements OnDestroy, AfterViewInit {
  @Input() appHideBanner: string;

  constructor(private el: ElementRef, private localStorage: LocalStorage, private cd: ChangeDetectorRef) {}

  ngOnDestroy(): void {}

  ngAfterViewInit(): void {
    fromEvent(this.el.nativeElement, 'click')
      .pipe(untilDestroyed(this))
      .subscribe(() => this.hideBanner(this.appHideBanner));
  }

  hideBanner(uid: string) {
    this.localStorage.set(`hide_banner_${uid}`, '1');
    this.cd.markForCheck();
  }
}

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import * as JsBarcode from 'jsbarcode';
import { untilDestroyed } from 'ngx-take-until-destroy';

import { getColorHexStr } from '@modules/colors';
import { BarCodeFormat } from '@modules/customize';
import { elementResize$, isSet, removeChildren, TypedChanges } from '@shared';

@Component({
  selector: 'app-bar-code',
  templateUrl: './bar-code.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BarCodeComponent implements OnInit, OnDestroy, OnChanges {
  @Input() format: BarCodeFormat = BarCodeFormat.CODE128;
  @Input() value = '';
  @Input() fillColor: string;
  @Input() backgroundColor: string;
  @Input() displayText = true;

  @HostBinding('class.bar-code') cls = true;
  @HostBinding('style.min-width.px') get minWidth() {
    return isSet(this.error) ? 120 : null;
  }
  @ViewChild('svg') svgElement: ElementRef;

  error: string;

  constructor(private el: ElementRef, private cd: ChangeDetectorRef) {}

  ngOnInit() {
    elementResize$(this.el.nativeElement)
      .pipe(untilDestroyed(this))
      .subscribe(() => this.render());
  }

  ngOnDestroy(): void {}

  ngOnChanges(changes: TypedChanges<BarCodeComponent>): void {
    if (
      [changes.format, changes.value, changes.fillColor, changes.backgroundColor, changes.displayText].some(
        item => item && !item.firstChange
      )
    ) {
      this.render();
    }
  }

  render() {
    const boundsHeight = this.el.nativeElement.offsetHeight;
    const textMargin = 2;
    const fontSize = 20;
    const height = boundsHeight - (this.displayText ? textMargin + fontSize : 0);
    const fillColor = isSet(this.fillColor) ? getColorHexStr(this.fillColor) : undefined;
    const backgroundColor = isSet(this.backgroundColor) ? getColorHexStr(this.backgroundColor) : undefined;

    this.error = undefined;
    this.cd.markForCheck();

    try {
      JsBarcode(this.svgElement.nativeElement, this.value, {
        format: this.format,
        ...(fillColor && { lineColor: fillColor }),
        background: backgroundColor || 'transparent',
        height: height,
        displayValue: this.displayText,
        margin: 0
      });
    } catch (e) {
      console.error(e);

      this.error = 'Invalid value';
      this.cd.markForCheck();

      removeChildren(this.svgElement.nativeElement);
    }
  }
}

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../icons/directives/icon/icon.directive";
import * as i2 from "../../../../../core/services/app-config/app-config.service";
import * as i3 from "@angular/cdk/overlay";
import * as i4 from "../../../../colors-shared/directives/tint-color/tint-color.directive";
import * as i5 from "@angular/platform-browser";
import * as i6 from "@angular/common";
import * as i7 from "../auto-menu-item/auto-menu-item.component.ngfactory";
import * as i8 from "../auto-menu-item/auto-menu-item.component";
import * as i9 from "../../../data/menu-context";
import * as i10 from "@angular/router";
import * as i11 from "../../../../routing/directives/is-link-active/is-link-active.directive";
import * as i12 from "../../../../routing/services/routing/routing.service";
import * as i13 from "../../../../theme-components/pipes/theme-color-hex-option/theme-color-hex-option.pipe";
import * as i14 from "../../../../theme-components/data/theme-context";
import * as i15 from "../../../../../shared/pipes/capitalize/capitalize.pipe";
import * as i16 from "../../../../../shared/pipes/is-set/is-set.pipe";
import * as i17 from "@angular/cdk/bidi";
import * as i18 from "./button-menu-item.component";
import * as i19 from "../../../../menu/services/menu-generator/menu-generator.service";
import * as i20 from "../../../../menu/services/menu-item-action/menu-item-action.service";
var styles_ButtonMenuItemComponent = [];
var RenderType_ButtonMenuItemComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ButtonMenuItemComponent, data: {} });
export { RenderType_ButtonMenuItemComponent as RenderType_ButtonMenuItemComponent };
function View_ButtonMenuItemComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [["class", "menu2-button-item__icon"]], [[2, "menu2-button-item__icon_left", null], [4, "background-image", null]], null, null, null, null)), i0.ɵdid(1, 540672, null, 0, i1.IconDirective, [i2.AppConfigService, i0.ElementRef], { icon: [0, "icon"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.menuItem.icon; _ck(_v, 1, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.isIconButton(); var currVal_1 = i0.ɵnov(_v, 1).backgroundImage; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_ButtonMenuItemComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "span", [["class", "menu2-button-item__label"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, [" ", " "])), i0.ɵppd(2, 1)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i0.ɵnov(_v.parent.parent, 1), _co.menuItem.title)); _ck(_v, 1, 0, currVal_0); }); }
function View_ButtonMenuItemComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "span", [["class", "menu2-button-item__icon icon-arrow_down_2"]], [[2, "menu2-button-item__icon_right", null]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.isIconButton(); _ck(_v, 0, 0, currVal_0); }); }
function View_ButtonMenuItemComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 11, "div", [["cdkOverlayOrigin", ""], ["class", "menu2-button-item"]], [[2, "menu2-button-item_small", null], [2, "menu2-button-item_icon", null], [2, "menu2-button-item_fill", null], [8, "style", 2]], [[null, "mouseenter"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mouseenter" === en)) {
        var pd_0 = (_co.menuItemHovered$.next(i0.ɵnov(_v, 1)) !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = (_co.menuItemHovered$.next(undefined) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 16384, [["dropdown_item_origin", 4]], 0, i3.CdkOverlayOrigin, [i0.ElementRef], null, null), i0.ɵdid(2, 540672, null, 0, i4.TintColorDirective, [i5.DomSanitizer], { appTintColor: [0, "appTintColor"], appTintColorStyle: [1, "appTintColorStyle"] }, null), i0.ɵppd(3, 1), i0.ɵpid(131072, i6.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(5, 0, null, null, 6, "div", [["class", "menu2-button-item__inner"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ButtonMenuItemComponent_2)), i0.ɵdid(7, 16384, null, 0, i6.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ButtonMenuItemComponent_3)), i0.ɵdid(9, 16384, null, 0, i6.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ButtonMenuItemComponent_4)), i0.ɵdid(11, 16384, null, 0, i6.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_4 = (_co.menuItem.tint || i0.ɵunv(_v, 2, 0, i0.ɵnov(_v, 4).transform(i0.ɵunv(_v, 2, 0, _ck(_v, 3, 0, i0.ɵnov(_v.parent, 0), "accentColor"))))); var currVal_5 = _co.menuItem.style; _ck(_v, 2, 0, currVal_4, currVal_5); var currVal_6 = _co.menuItem.icon; _ck(_v, 7, 0, currVal_6); var currVal_7 = !_co.isIconButton(); _ck(_v, 9, 0, currVal_7); var currVal_8 = (_co.children.length && (!_co.isIconButton() || !_co.menuItem.icon)); _ck(_v, 11, 0, currVal_8); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = !(_co.primary && !_co.horizontal); var currVal_1 = _co.isIconButton(); var currVal_2 = !_co.horizontal; var currVal_3 = i0.ɵnov(_v, 2).style; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3); }); }
function View_ButtonMenuItemComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-auto-menu-item", [], null, [[null, "dropdownOpen"], [null, "dropdownClose"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("dropdownOpen" === en)) {
        var pd_0 = (_co.menuChildDropdownOpened$.next(true) !== false);
        ad = (pd_0 && ad);
    } if (("dropdownClose" === en)) {
        var pd_1 = (_co.menuChildDropdownOpened$.next(false) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i7.View_AutoMenuItemComponent_0, i7.RenderType_AutoMenuItemComponent)), i0.ɵdid(1, 770048, null, 0, i8.AutoMenuItemComponent, [i9.MenuContext, i0.ChangeDetectorRef], { menuItem: [0, "menuItem"], menuBlock: [1, "menuBlock"], dropdown: [2, "dropdown"] }, { dropdownOpen: "dropdownOpen", dropdownClose: "dropdownClose" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit; var currVal_1 = _co.menuBlock; var currVal_2 = true; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_ButtonMenuItemComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [["class", "popover2 overlay-content overlay-content_animated"]], null, [[null, "mouseenter"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mouseenter" === en)) {
        var pd_0 = (_co.menuDropdownHovered$.next(true) !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = (_co.menuDropdownHovered$.next(false) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 4, "div", [["class", "popover2__inner"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 3, "div", [["class", "popover2__viewport popover2__viewport_scrollable"], ["style", "min-width: 220px;"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 2, "div", [["class", "popover2__content popover2__content_padding_sm menu2-dropdown menu2-block menu2-block_unset"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ButtonMenuItemComponent_6)), i0.ɵdid(5, 278528, null, 0, i6.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"], ngForTrackBy: [1, "ngForTrackBy"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.children; var currVal_1 = _co.trackMenuItemFn; _ck(_v, 5, 0, currVal_0, currVal_1); }, null); }
function View_ButtonMenuItemComponent_8(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_ButtonMenuItemComponent_7(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "a", [["class", "menu2-link menu2-item-wrapper"]], [[2, "menu2-link_active", null], [1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.onClick() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 671744, null, 0, i10.RouterLinkWithHref, [i10.Router, i10.ActivatedRoute, i6.LocationStrategy], { queryParams: [0, "queryParams"], routerLink: [1, "routerLink"] }, null), i0.ɵdid(2, 737280, [["app_is_link_active", 4]], 0, i11.IsLinkActiveDirective, [i10.Router, i12.RoutingService, i0.ChangeDetectorRef], { appIsLinkActiveAppLink: [0, "appIsLinkActiveAppLink"], appIsLinkActiveQueryParams: [1, "appIsLinkActiveQueryParams"] }, null), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_ButtonMenuItemComponent_8)), i0.ɵdid(4, 540672, null, 0, i6.NgTemplateOutlet, [i0.ViewContainerRef], { ngTemplateOutletContext: [0, "ngTemplateOutletContext"], ngTemplateOutlet: [1, "ngTemplateOutlet"] }, null), i0.ɵpod(5, { active: 0 })], function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.queryParams; var currVal_4 = _co.link; _ck(_v, 1, 0, currVal_3, currVal_4); var currVal_5 = _co.link; var currVal_6 = _co.queryParams; _ck(_v, 2, 0, currVal_5, currVal_6); var currVal_7 = _ck(_v, 5, 0, i0.ɵnov(_v, 2).active); var currVal_8 = i0.ɵnov(_v.parent, 4); _ck(_v, 4, 0, currVal_7, currVal_8); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 2).active; var currVal_1 = i0.ɵnov(_v, 1).target; var currVal_2 = i0.ɵnov(_v, 1).href; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
function View_ButtonMenuItemComponent_10(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_ButtonMenuItemComponent_9(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "a", [["class", "menu2-link menu2-item-wrapper"], ["target", "_blank"]], [[8, "href", 4], [2, "menu2-link_active", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 737280, [["app_is_link_active", 4]], 0, i11.IsLinkActiveDirective, [i10.Router, i12.RoutingService, i0.ChangeDetectorRef], { appIsLinkActiveHref: [0, "appIsLinkActiveHref"] }, null), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_ButtonMenuItemComponent_10)), i0.ɵdid(3, 540672, null, 0, i6.NgTemplateOutlet, [i0.ViewContainerRef], { ngTemplateOutletContext: [0, "ngTemplateOutletContext"], ngTemplateOutlet: [1, "ngTemplateOutlet"] }, null), i0.ɵpod(4, { active: 0 })], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.href; _ck(_v, 1, 0, currVal_2); var currVal_3 = _ck(_v, 4, 0, i0.ɵnov(_v, 1).active); var currVal_4 = i0.ɵnov(_v.parent, 4); _ck(_v, 3, 0, currVal_3, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.href; var currVal_1 = i0.ɵnov(_v, 1).active; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_ButtonMenuItemComponent_12(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_ButtonMenuItemComponent_11(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "span", [["class", "menu2-item-wrapper"]], [[2, "menu2-link", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ButtonMenuItemComponent_12)), i0.ɵdid(2, 540672, null, 0, i6.NgTemplateOutlet, [i0.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null)], function (_ck, _v) { var currVal_1 = i0.ɵnov(_v.parent, 4); _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.handler; _ck(_v, 0, 0, currVal_0); }); }
export function View_ButtonMenuItemComponent_0(_l) { return i0.ɵvid(2, [i0.ɵpid(0, i13.ThemeColorHexOptionPipe, [[2, i14.ThemeContext]]), i0.ɵpid(0, i15.CapitalizePipe, []), i0.ɵpid(0, i16.IsSetPipe, []), i0.ɵqud(402653184, 1, { cdkConnectedOverlay: 0 }), (_l()(), i0.ɵand(0, [["menu_item", 2]], null, 0, null, View_ButtonMenuItemComponent_1)), (_l()(), i0.ɵand(16777216, null, null, 2, function (_v, en, $event) { var ad = true; var _co = _v.component; if (("backdropClick" === en)) {
        var pd_0 = (_co.closeDropdown() !== false);
        ad = (pd_0 && ad);
    } if (("detach" === en)) {
        var pd_1 = (_co.closeDropdown() !== false);
        ad = (pd_1 && ad);
    } return ad; }, View_ButtonMenuItemComponent_5)), i0.ɵdid(6, 671744, [[1, 4]], 0, i3.CdkConnectedOverlay, [i3.Overlay, i0.TemplateRef, i0.ViewContainerRef, i3.ɵc, [2, i17.Directionality]], { origin: [0, "origin"], positions: [1, "positions"], backdropClass: [2, "backdropClass"], panelClass: [3, "panelClass"], open: [4, "open"], hasBackdrop: [5, "hasBackdrop"], growAfterOpen: [6, "growAfterOpen"], push: [7, "push"] }, { backdropClick: "backdropClick", detach: "detach" }), i0.ɵpad(7, 2), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ButtonMenuItemComponent_7)), i0.ɵdid(9, 16384, null, 0, i6.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_ButtonMenuItemComponent_9)), i0.ɵdid(11, 16384, null, 0, i6.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵppd(12, 1), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_ButtonMenuItemComponent_11)), i0.ɵdid(14, 16384, null, 0, i6.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵppd(15, 1)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.origin; var currVal_1 = _co.popoverPositions; var currVal_2 = "popover2-backdrop"; var currVal_3 = _ck(_v, 7, 0, "overlay", "overlay_horizontal_center"); var currVal_4 = _co.dropdownOpened; var currVal_5 = false; var currVal_6 = true; var currVal_7 = true; _ck(_v, 6, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); var currVal_8 = _co.link; _ck(_v, 9, 0, currVal_8); var currVal_9 = (!_co.link && i0.ɵunv(_v, 11, 0, _ck(_v, 12, 0, i0.ɵnov(_v, 2), _co.href))); _ck(_v, 11, 0, currVal_9); var currVal_10 = (!_co.link && !i0.ɵunv(_v, 14, 0, _ck(_v, 15, 0, i0.ɵnov(_v, 2), _co.href))); _ck(_v, 14, 0, currVal_10); }, null); }
export function View_ButtonMenuItemComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-button-menu-item", [], null, null, null, View_ButtonMenuItemComponent_0, RenderType_ButtonMenuItemComponent)), i0.ɵdid(1, 4964352, null, 0, i18.ButtonMenuItemComponent, [i19.MenuGeneratorService, i20.MenuItemActionService, i10.Router, i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ButtonMenuItemComponentNgFactory = i0.ɵccf("app-button-menu-item", i18.ButtonMenuItemComponent, View_ButtonMenuItemComponent_Host_0, { menuItem: "menuItem", menuBlock: "menuBlock", childrenVertical: "childrenVertical", primary: "primary", dropdown: "dropdown", horizontal: "horizontal", forceActive: "forceActive", forceOpened: "forceOpened", context: "context" }, { dropdownOpen: "dropdownOpen", dropdownClose: "dropdownClose" }, []);
export { ButtonMenuItemComponentNgFactory as ButtonMenuItemComponentNgFactory };

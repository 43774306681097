export function getNavigatorLanguages(): string[] {
  const language = navigator.language || navigator['userLanguage'];

  if (!language) {
    return [];
  }

  const parts = language.toLowerCase().split('-');

  return parts.map((item, i) => parts.slice(0, i + 1).join('-')).reverse();
}

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { AfterViewInit, ChangeDetectorRef, OnChanges, OnDestroy, OnInit, QueryList, SimpleChanges } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { UniversalAnalyticsService } from '@modules/analytics';
import { CustomizeService, ElementItem, ElementType, registerElementComponent, SectionLayoutElementItem, traverseElementItems } from '@modules/customize';
import { AutoElementComponent, BaseElementComponent, ElementContainerService } from '@modules/customize-elements';
import { RoutingService } from '@modules/routing';
import { ElementGroupsContainerDirective } from '../../directives/element-groups-container/element-groups-container.directive';
import { FormElementComponent } from '../form-element/form-element.component';
var SectionLayoutComponent = /** @class */ (function (_super) {
    __extends(SectionLayoutComponent, _super);
    function SectionLayoutComponent(customizeService, elementContainerService, routing, analyticsService, formElementComponent, cd) {
        var _this = _super.call(this) || this;
        _this.customizeService = customizeService;
        _this.elementContainerService = elementContainerService;
        _this.routing = routing;
        _this.analyticsService = analyticsService;
        _this.formElementComponent = formElementComponent;
        _this.cd = cd;
        _this.elementComponents = new QueryList();
        _this.canEnter = (function () {
            return function (drag, drop) {
                if (drag.data instanceof ElementItem && drag.data.type == ElementType.FormSubmit) {
                    var isInsideCurrentForm_1 = false;
                    if (_this.formElementComponent) {
                        traverseElementItems(_this.formElementComponent.element, function (item) {
                            if (item === drag.data) {
                                isInsideCurrentForm_1 = true;
                            }
                        });
                    }
                    return isInsideCurrentForm_1;
                }
                else {
                    return true;
                }
            };
        })();
        _this.trackElement = (function () {
            return function (i, item) {
                var pageUid = _this.context && _this.context.viewSettings && !_this.context.viewSettings.newlyCreated
                    ? _this.context.viewSettings.uid
                    : undefined;
                return [pageUid, item.uid].join('_');
            };
        })();
        return _this;
    }
    SectionLayoutComponent.prototype.ngOnInit = function () { };
    SectionLayoutComponent.prototype.ngOnDestroy = function () { };
    SectionLayoutComponent.prototype.ngOnChanges = function (changes) {
        var _this = this;
        if (changes['element'] && !changes['element'].firstChange) {
            setTimeout(function () { return _this.updateElementStatesOnStable(); }, 0);
        }
    };
    SectionLayoutComponent.prototype.ngAfterViewInit = function () {
        this.updateElementStates();
    };
    SectionLayoutComponent.prototype.dragDrop = function (event) {
        var item = event.previousContainer.data[event.previousIndex];
        var barItem = item instanceof ElementItem ? undefined : item;
        if (barItem && barItem.popup) {
            if (this.customizeService.handler && this.customizeService.handler.createPopup) {
                this.customizeService.handler.createPopup(true, __assign({}, (barItem.defaultParams && {
                    width: barItem.defaultParams['width'],
                    style: barItem.defaultParams['style'],
                    position: barItem.defaultParams['position']
                }), { analyticsSource: 'components_library' }));
                this.elementContainerService.sendAddPopupAnalytics();
            }
            return;
        }
        var siblingLeftEntered = event.data ? !!event.data['siblingLeftEntered'] : false;
        var siblingRightEntered = event.data ? !!event.data['siblingRightEntered'] : false;
        var siblingSelf = event.data ? !!event.data['siblingSelf'] : false;
        var siblingAnchor = event.data ? event.data['siblingAnchor'] : undefined;
        var siblingAnchorContainer = event.data ? event.data['siblingAnchorContainer'] : undefined;
        if (siblingLeftEntered || siblingRightEntered) {
            var anchorContainer = siblingSelf ? event.container.data : siblingAnchorContainer.data;
            var anchorIndex = siblingSelf ? event.currentIndex : anchorContainer.indexOf(siblingAnchor);
            this.elementContainerService.dragDropIntoSiblingColumn({
                sourceContainer: event.previousContainer.data,
                sourceIndex: event.previousIndex,
                sourceCloneItem: event.previousContainer.cloneItems,
                anchorContainer: anchorContainer,
                anchorIndex: anchorIndex,
                anchorSelf: siblingSelf,
                left: siblingLeftEntered,
                context: this.context,
                parent: this.element
            });
            // TODO: Implement onAdd
            // this.cd.detectChanges();
            // const component = this.elementComponents.find(i => i.element === elementItem);
            // this.onAdd(elementItem, item, component);
        }
        else {
            if (event.previousContainer === event.container) {
                moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
            }
            else if (event.previousContainer.cloneItems) {
                var elementItem = this.elementContainerService.copyElementItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex, this.context);
                this.customizeService.registerCreatedElement(elementItem, barItem);
            }
            else {
                transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
            }
        }
        this.customizeService.markChanged();
        this.updateElementStatesOnStable();
    };
    SectionLayoutComponent.prototype.duplicateItem = function (index) {
        var elementItem = this.elementContainerService.duplicateElementItem(this.element.children, this.context, index);
        this.cd.detectChanges();
        this.customizeService.markChanged();
        this.updateElementStatesOnStable();
        var component = this.elementComponents.find(function (i) { return i.element === elementItem; });
        if (component) {
            component.customize();
        }
    };
    SectionLayoutComponent.prototype.replaceItem = function (index, elements) {
        this.elementContainerService.replaceElementItem(this.element.children, index, elements);
        this.cd.markForCheck();
        this.customizeService.markChanged();
        this.updateElementStatesOnStable();
    };
    SectionLayoutComponent.prototype.deleteItem = function (element) {
        var index = this.element.children.findIndex(function (item) { return item === element; });
        if (index == -1) {
            return;
        }
        this.elementContainerService.deleteElementItem(this.element.children, index);
        this.cd.markForCheck();
        this.customizeService.markChanged();
        this.updateElementStatesOnStable();
    };
    SectionLayoutComponent.prototype.moveItemTo = function (element, link) {
        var _this = this;
        this.deleteItem(element);
        this.customizeService.stopTrackChanges();
        this.customizeService
            .saveActualChanges()
            .pipe(untilDestroyed(this))
            .subscribe(function () { return _this.routing.navigateApp(link); });
    };
    SectionLayoutComponent.prototype.updateElementStatesOnStable = function () {
        if (this.elementGroupsContainer) {
            this.elementGroupsContainer.updateElementStatesOnStable();
        }
    };
    SectionLayoutComponent.prototype.updateElementStates = function () {
        if (this.elementGroupsContainer) {
            this.elementGroupsContainer.updateElementStates();
        }
    };
    return SectionLayoutComponent;
}(BaseElementComponent));
export { SectionLayoutComponent };
registerElementComponent({
    type: ElementType.Section,
    component: SectionLayoutComponent,
    label: 'Section',
    alwaysActive: false,
    actions: []
});

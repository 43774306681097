var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ChangeDetectorRef, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import fromPairs from 'lodash/fromPairs';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { BasePopupComponent } from '@common/popups';
import { ViewContext, ViewContextElement } from '@modules/customize';
import { applyParamInput$ } from '@modules/fields';
import { ITEM_OUTPUT } from '@modules/list';
import { View } from '@modules/views';
import { controlValue, EMPTY, isSet } from '@shared';
import { CustomViewMapParametersForm } from './custom-view-map-parameters.form';
var CustomViewMapParametersComponent = /** @class */ (function () {
    function CustomViewMapParametersComponent(form, popupComponent, cd) {
        this.form = form;
        this.popupComponent = popupComponent;
        this.cd = cd;
        this.sourceParameters = [];
        this.selectMapping = new EventEmitter();
        this.cancelled = new EventEmitter();
        this.viewScale = 1;
        this.testParameters = {};
        this.testSetParameters = {};
    }
    CustomViewMapParametersComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.form.init({
            sourceParameters: this.sourceParameters,
            view: this.view,
            mappings: this.mappings
        });
        var frame = this.view.frame;
        var maxWidth = 400;
        this.viewScale = frame.width > maxWidth ? maxWidth / frame.width : undefined;
        this.cd.markForCheck();
        if (this.contextElement) {
            var sourceParameterValues_1 = fromPairs(this.sourceParameters.map(function (item) {
                var value = _this.contextElement.getFieldValue(item.name);
                return [item.name, value];
            }));
            combineLatest(this.form.controls.map(function (control) {
                return controlValue(control).pipe(switchMap(function () {
                    var _a;
                    if (control.controls.source_parameter_input_enabled.value) {
                        var input = control.getSourceParameterInput();
                        return applyParamInput$(input, {
                            context: _this.context,
                            contextElement: _this.contextElement,
                            localContext: (_a = {},
                                _a[ITEM_OUTPUT] = sourceParameterValues_1,
                                _a)
                        }).pipe(map(function (value) { return (value !== EMPTY ? value : undefined); }));
                    }
                    else {
                        var sourceParameter = control.controls.source_parameter.value;
                        var value = sourceParameter !== EMPTY ? sourceParameterValues_1[sourceParameter] : EMPTY;
                        return of(value);
                    }
                }), map(function (testValue) {
                    return [control.target.name, testValue];
                }));
            }))
                .pipe(untilDestroyed(this))
                .subscribe(function (testValues) {
                var testValuesClean = fromPairs(testValues.filter(function (_a) {
                    var k = _a[0], v = _a[1];
                    return isSet(v);
                }).map(function (_a) {
                    var k = _a[0], v = _a[1];
                    return [k, v !== EMPTY ? v : undefined];
                }));
                _this.testParameters = __assign({}, _this.view.testParameters, testValuesClean);
                _this.testSetParameters = __assign({}, testValuesClean);
                _this.cd.markForCheck();
            });
        }
    };
    CustomViewMapParametersComponent.prototype.ngOnDestroy = function () { };
    CustomViewMapParametersComponent.prototype.toggleSourceParameterInput = function (control) {
        control.toggleSourceParameterInput();
        if (control.controls.source_parameter_input_enabled.value) {
            this.controlSourceParameterInputEnabled = control;
        }
    };
    CustomViewMapParametersComponent.prototype.submit = function () {
        var result = this.form.submit();
        this.selectMapping.emit(result);
        this.close();
    };
    CustomViewMapParametersComponent.prototype.close = function () {
        if (this.popupComponent) {
            this.popupComponent.close();
        }
    };
    CustomViewMapParametersComponent.prototype.cancel = function () {
        this.cancelled.emit();
        this.close();
    };
    return CustomViewMapParametersComponent;
}());
export { CustomViewMapParametersComponent };

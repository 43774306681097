import { Pipe, PipeTransform } from '@angular/core';

import { localizeAdv, LocalizeOptions } from '../../utils/localize';

@Pipe({
  name: 'localizeAdv'
})
export class LocalizeAdvPipe implements PipeTransform {
  transform(label: string, options: LocalizeOptions = {}): any {
    return localizeAdv(label, options);
  }
}

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';

import { BooleanFieldStyle, cropFormats } from '@modules/field-components';
import {
  createFormFieldFactory,
  FieldType,
  ImageOutputFormat,
  registerFieldViewParamsComponent
} from '@modules/fields';
import { StorageSelectSource } from '@modules/storages-queries';

import { FieldParamsComponent } from '../../field-params/field-params.component';
import { ImageFieldViewParamsForm } from './image-field-view-params.form';

@Component({
  selector: 'app-image-field-view-params',
  templateUrl: './image-field-view-params.component.html',
  providers: [ImageFieldViewParamsForm, StorageSelectSource],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImageFieldViewParamsComponent extends FieldParamsComponent implements OnInit, OnDestroy {
  createField = createFormFieldFactory();
  editable = false;
  cropFormats = cropFormats;
  booleanFieldStyle = BooleanFieldStyle;
  outputFormats = ImageOutputFormat;

  constructor(
    public form: ImageFieldViewParamsForm,
    public storageSelectSource: StorageSelectSource,
    private cd: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit() {
    this.form.init(this.control, { context: this.context });

    if (this.field$) {
      this.field$.pipe(untilDestroyed(this)).subscribe(field => {
        this.editable = (!this.configurable || this.configurable.editable) && field && field['editable'];
        this.cd.markForCheck();
      });
    }
  }

  ngOnDestroy(): void {}
}

registerFieldViewParamsComponent(FieldType.Image, ImageFieldViewParamsComponent);

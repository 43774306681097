export enum ProjectFeature {
  Users = 'users',
  Groups = 'groups',
  Environments = 'environments',
  Tasks = 'tasks',
  Domain = 'domain',
  WhiteLabel = 'white_label',
  CustomViews = 'custom_views',
  SSO = 'sso',
  ActivityLog = 'activity_log',
  DatabaseResources = 'database_resources',
  ThirdPartyResources = '3rd_party_resources',
  CustomResources = 'custom_resources',
  CustomerSignUp = 'customer_sign_up',
  Snapshots = 'snapshots',
  PublicAccess = 'public_access',
  OnPremise = 'on_premise',
  SlackChannel = 'slack_channel'
}

export class ProjectFeatures {
  public features: { [k: string]: { enabled: boolean; params?: Object } } = {};

  deserialize(data: Object): ProjectFeatures {
    this.features = data as any;

    return this;
  }

  isUsersEnabled() {
    if (!this.features[ProjectFeature.Users]) {
      return false;
    }
    return this.features[ProjectFeature.Users].enabled;
  }

  isGroupsEnabled() {
    if (!this.features[ProjectFeature.Groups]) {
      return false;
    }
    return this.features[ProjectFeature.Groups].enabled;
  }

  isEnvironmentsEnabled() {
    if (!this.features[ProjectFeature.Environments]) {
      return false;
    }
    return this.features[ProjectFeature.Environments].enabled;
  }

  isTasksEnabled() {
    if (!this.features[ProjectFeature.Tasks]) {
      return false;
    }
    return this.features[ProjectFeature.Tasks].enabled;
  }

  isWhiteLabelEnabled() {
    if (!this.features[ProjectFeature.WhiteLabel]) {
      return false;
    }
    return this.features[ProjectFeature.WhiteLabel].enabled;
  }

  isCustomViewsEnabled() {
    if (!this.features[ProjectFeature.CustomViews]) {
      return false;
    }
    return this.features[ProjectFeature.CustomViews].enabled;
  }

  isSSOEnabled() {
    if (!this.features[ProjectFeature.SSO]) {
      return false;
    }
    return this.features[ProjectFeature.SSO].enabled;
  }

  isActivityLogEnabled() {
    if (!this.features[ProjectFeature.ActivityLog]) {
      return false;
    }
    return this.features[ProjectFeature.ActivityLog].enabled;
  }

  isDatabaseResourcesEnabled() {
    if (!this.features[ProjectFeature.DatabaseResources]) {
      return false;
    }
    return this.features[ProjectFeature.DatabaseResources].enabled;
  }

  isThirdPartyResourcesEnabled() {
    if (!this.features[ProjectFeature.ThirdPartyResources]) {
      return false;
    }
    return this.features[ProjectFeature.ThirdPartyResources].enabled;
  }

  isCustomResourcesEnabled() {
    if (!this.features[ProjectFeature.CustomResources]) {
      return false;
    }
    return this.features[ProjectFeature.CustomResources].enabled;
  }

  isCustomerSignUpEnabled() {
    if (!this.features[ProjectFeature.CustomerSignUp]) {
      return false;
    }
    return this.features[ProjectFeature.CustomerSignUp].enabled;
  }

  isSnapshotsEnabled() {
    if (!this.features[ProjectFeature.Snapshots]) {
      return false;
    }
    return this.features[ProjectFeature.Snapshots].enabled;
  }

  isPublicAccessEnabled() {
    if (!this.features[ProjectFeature.PublicAccess]) {
      return false;
    }
    return this.features[ProjectFeature.PublicAccess].enabled;
  }

  isOnPremiseEnabled() {
    if (!this.features[ProjectFeature.OnPremise]) {
      return false;
    }
    return this.features[ProjectFeature.OnPremise].enabled;
  }

  isSlackChannelEnabled() {
    if (!this.features[ProjectFeature.SlackChannel]) {
      return false;
    }
    return this.features[ProjectFeature.SlackChannel].enabled;
  }
}

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../shared/pipes/format-text/format-text.pipe";
import * as i2 from "@angular/platform-browser";
import * as i3 from "../../../../../shared/pipes/time-ago/time-ago.pipe";
import * as i4 from "./message-user-activity.component";
import * as i5 from "../../../../projects/stores/current-project.store";
import * as i6 from "../../../../projects/stores/current-environment.store";
import * as i7 from "../../../../projects/services/project-user/project-user.service";
var styles_MessageUserActivityComponent = [];
var RenderType_MessageUserActivityComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_MessageUserActivityComponent, data: {} });
export { RenderType_MessageUserActivityComponent as RenderType_MessageUserActivityComponent };
export function View_MessageUserActivityComponent_0(_l) { return i0.ɵvid(2, [i0.ɵpid(0, i1.FormatTextPipe, [i2.DomSanitizer]), i0.ɵpid(0, i3.TimeAgoPipe, []), (_l()(), i0.ɵeld(2, 0, null, null, 11, "div", [["class", "message-user-activity"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 10, "div", [["class", "message-user-activity__inner"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 1, "div", [["class", "message-user-activity__left"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 0, "div", [["class", "message-user-activity__avatar"]], [[4, "background-image", null]], null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 7, "div", [["class", "message-user-activity__right"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 1, "span", [["class", "message-user-activity__author"]], null, null, null, null, null)), (_l()(), i0.ɵted(8, null, ["", ""])), (_l()(), i0.ɵeld(9, 0, null, null, 1, "p", [["class", "message-user-activity__message"]], [[8, "innerHTML", 1]], null, null, null, null)), i0.ɵppd(10, 1), (_l()(), i0.ɵeld(11, 0, null, null, 2, "span", [["class", "message-user-activity__date"]], null, null, null, null, null)), (_l()(), i0.ɵted(12, null, ["", ""])), i0.ɵppd(13, 2)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (((_co.item.user == null) ? null : _co.item.user.photo) ? (("url(" + _co.item.user.photo) + ")") : ""); _ck(_v, 5, 0, currVal_0); var currVal_1 = _co.item.user.strFull; _ck(_v, 8, 0, currVal_1); var currVal_2 = i0.ɵunv(_v, 9, 0, _ck(_v, 10, 0, i0.ɵnov(_v, 0), _co.message)); _ck(_v, 9, 0, currVal_2); var currVal_3 = i0.ɵunv(_v, 12, 0, _ck(_v, 13, 0, i0.ɵnov(_v, 1), _co.item.dateAdd, true)); _ck(_v, 12, 0, currVal_3); }); }
export function View_MessageUserActivityComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-message-user-activity", [], null, null, null, View_MessageUserActivityComponent_0, RenderType_MessageUserActivityComponent)), i0.ɵdid(1, 245760, null, 0, i4.MessageUserActivityComponent, [i5.CurrentProjectStore, i6.CurrentEnvironmentStore, i7.ProjectUserService, i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MessageUserActivityComponentNgFactory = i0.ɵccf("app-message-user-activity", i4.MessageUserActivityComponent, View_MessageUserActivityComponent_Host_0, { item: "item" }, {}, []);
export { MessageUserActivityComponentNgFactory as MessageUserActivityComponentNgFactory };

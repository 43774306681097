import { Injectable } from '@angular/core';
import { FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import cloneDeep from 'lodash/cloneDeep';
import { Observable, of, throwError } from 'rxjs';
import { catchError, delayWhen, map, switchMap, tap } from 'rxjs/operators';

import { FormUtils } from '@common/form-utils';
import { ActionStore } from '@modules/action-queries';
import { RawListViewSettingsColumn, rawListViewSettingsColumnToModelField } from '@modules/customize';
import { ParameterField } from '@modules/fields';
import { ModelDescriptionService, ModelDescriptionStore } from '@modules/model-queries';
import { ModelDbField, ModelDescription } from '@modules/models';
import { CurrentEnvironmentStore, CurrentProjectStore, Resource } from '@modules/projects';
import { ListModelDescriptionQuery, QueryType } from '@modules/queries';
import { ResourceControllerService } from '@modules/resources';
import { TemplateService } from '@modules/template';
import { isSet } from '@shared';

export interface ModelCreateOptions {
  queryType?: QueryType;
  query?: ListModelDescriptionQuery;
  parameters?: ParameterField[];
  columns?: RawListViewSettingsColumn[];
  primaryKey?: string;
  resourceCreate?: boolean;
}

export function validateVerboseName(): ValidatorFn {
  return control => {
    const parent = control.parent as ModelCreateForm;

    if (!control.value || !parent || !parent.resource) {
      return;
    }

    if (
      parent.modelDescriptionStore.instance
        .filter(item => item.resource == parent.resource.uniqueName && !item.deleted)
        .find(item => item.verboseNamePlural && item.verboseNamePlural.toLowerCase() == control.value.toLowerCase())
    ) {
      return {
        local: ['Collection with such Name already exists']
      };
    }
  };
}

@Injectable()
export class ModelCreateForm extends FormGroup {
  controls: {
    verbose_name: FormControl;
    verbose_name_plural: FormControl;
  };

  resource: Resource;
  options: ModelCreateOptions = {};

  constructor(
    public modelDescriptionStore: ModelDescriptionStore,
    public actionStore: ActionStore,
    private modelDescriptionService: ModelDescriptionService,
    private resourceControllerService: ResourceControllerService,
    private formUtils: FormUtils,
    private templateService: TemplateService,
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore
  ) {
    super({
      verbose_name: new FormControl('', [Validators.required, validateVerboseName()]),
      verbose_name_plural: new FormControl('')
    });
  }

  init(resource: Resource, options: ModelCreateOptions = {}) {
    this.resource = resource;
    this.options = options;
  }

  submit(): Observable<ModelDescription> {
    if (!this.valid) {
      return throwError('Not all fields are filled in.');
    }

    const instance = new ModelDescription();
    const controller = this.resourceControllerService.get(this.resource.type);

    instance.resource = this.resource.uniqueName;
    instance.model = ModelDescription.generateModel();
    instance.verboseName = this.controls.verbose_name.value;
    instance.verboseNamePlural = this.controls.verbose_name.value;
    instance.queryType = this.options.queryType;
    instance.virtual = !this.options.resourceCreate;

    if (isSet(this.options.primaryKey)) {
      instance.primaryKeyField = this.options.primaryKey;
    }

    if (isSet(this.options.query)) {
      instance.getQuery = cloneDeep(this.options.query);
    }

    if (isSet(this.options.parameters)) {
      instance.getParameters = cloneDeep(this.options.parameters);
    }

    if (this.options.columns) {
      const fields = this.options.columns.map(item => {
        const result = rawListViewSettingsColumnToModelField(item);
        if (result.item instanceof ModelDbField) {
          result.item.editable = true;
        }
        return result;
      });

      if (controller) {
        controller.setUpModelDescriptionBasedOnGetQuery(this.resource, instance, instance.getQuery, fields);
      }
    }

    if (this.options.resourceCreate) {
      instance.dbTable = instance.model;

      return this.templateService.getResourceDefaultModelDescription(this.resource.typeItem.name).pipe(
        switchMap(template => {
          instance.model = ModelDescription.generateModel();
          instance.dbTable = instance.model;

          if (template && template.modelDescription) {
            instance.fields = [
              ...instance.fields.filter(item => item.name == instance.primaryKeyField),
              ...template.modelDescription.fields.filter(item => item.name != instance.primaryKeyField)
            ];
          }

          return controller.modelDescriptionCreate(this.resource, instance).pipe(
            switchMap(() => this.modelDescriptionService.getFromResource(this.resource)),
            map(resourceModelDescriptions => resourceModelDescriptions.find(item => item.model == instance.model)),
            tap(result => this.modelDescriptionStore.addItem(result)),
            delayWhen(result => this.actionStore.syncAutoActions(result)),
            delayWhen(modelDescription => {
              if (!template || !template.models.length) {
                return of([]);
              }

              const models = template.models.map(data => {
                const model = controller.createModel().deserialize(modelDescription.modelId, data);
                model.setUp(modelDescription);
                model.deserializeAttributes(modelDescription.dbFields);
                return model;
              });

              return controller.modelCreateBulk(this.resource, modelDescription, models);
            })
          );
        }),
        catchError(error => {
          this.markAsDirty();
          this.formUtils.showFormErrors(this, error);
          return throwError(error);
        })
      );
    } else {
      return this.modelDescriptionService
        .create(
          this.currentProjectStore.instance.uniqueName,
          this.currentEnvironmentStore.instance.uniqueName,
          instance
        )
        .pipe(
          tap(result => this.modelDescriptionStore.addItem(result)),
          delayWhen(result => this.actionStore.syncAutoActions(result)),
          catchError(error => {
            this.markAsDirty();
            this.formUtils.showFormErrors(this, error);
            return throwError(error);
          })
        );
    }
  }
}

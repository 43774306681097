import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import cloneDeep from 'lodash/cloneDeep';
import { Observable } from 'rxjs';
import { map, publishLast, refCount, switchMap } from 'rxjs/operators';

import { ApiService } from '@modules/api';

import { SecretToken } from '../../data/secret-token';

@Injectable({
  providedIn: 'root'
})
export class SecretTokenService {
  constructor(private http: HttpClient, private apiService: ApiService) {}

  get(
    projectName: string,
    environmentName: string,
    resourceName: string,
    params = {},
    draft = false
  ): Observable<SecretToken[]> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.environmentMethodURL(
          projectName,
          environmentName,
          `resources/${resourceName}/secret_tokens/`
        );
        let headers = new HttpHeaders();
        const httpParams = {
          ...params,
          ...(draft && { draft: '1' })
        };

        headers = this.apiService.setHeadersToken(headers);

        return this.http.get<Array<Object>>(url, { headers: headers, params: httpParams });
      }),
      map(result => result.map(item => new SecretToken().deserialize(item))),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  getDetail(
    projectName: string,
    environmentName: string,
    resourceName: string,
    id: string,
    draft = false
  ): Observable<SecretToken> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.environmentMethodURL(
          projectName,
          environmentName,
          `resources/${resourceName}/secret_tokens/${id}/`
        );
        const params = {
          ...(draft && { draft: '1' })
        };
        let headers = new HttpHeaders();

        headers = this.apiService.setHeadersToken(headers);

        return this.http.get<Array<Object>>(url, { headers: headers, params: params });
      }),
      map(result => new SecretToken().deserialize(result)),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  createBulk(
    projectName: string,
    environmentName: string,
    resourceName: string,
    instances: SecretToken[]
  ): Observable<SecretToken[]> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.environmentMethodURL(
          projectName,
          environmentName,
          `resources/${resourceName}/secret_tokens/`
        );
        let headers = new HttpHeaders();
        const params = {
          draft: '1'
        };
        const data = instances.map(item => item.serialize());

        headers = this.apiService.setHeadersToken(headers);

        return this.http.post<Object[]>(url, data, { headers: headers, params: params });
      }),
      map(result => result.map(item => new SecretToken().deserialize(item))),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  create(
    projectName: string,
    environmentName: string,
    resourceName: string,
    instance: SecretToken
  ): Observable<SecretToken> {
    return this.createBulk(projectName, environmentName, resourceName, [instance]).pipe(map(result => result[0]));
    // return this.apiService.refreshToken().pipe(
    //   switchMap(() => {
    //     const url = this.apiService.environmentMethodURL(
    //       projectName,
    //       environmentName,
    //       `resources/${resourceName}/secret_tokens/`
    //     );
    //     let headers = new HttpHeaders();
    //
    //     headers = this.apiService.setHeadersToken(headers);
    //
    //     return this.http.post(url, instance.serialize(), { headers: headers });
    //   }),
    //   map(result => new SecretToken().deserialize(result)),
    //   this.apiService.catchApiError(),
    //   publishLast(),
    //   refCount()
    // );
  }

  update(
    projectName: string,
    environmentName: string,
    resourceName: string,
    instance: SecretToken
  ): Observable<SecretToken> {
    return this.createBulk(projectName, environmentName, resourceName, [instance]).pipe(map(result => result[0]));
    // return this.apiService.refreshToken().pipe(
    //   switchMap(() => {
    //     const url = this.apiService.environmentMethodURL(
    //       projectName,
    //       environmentName,
    //       `resources/${resourceName}/secret_tokens/${instance.name}/`
    //     );
    //     let headers = new HttpHeaders();
    //
    //     headers = this.apiService.setHeadersToken(headers);
    //
    //     return this.http.patch(url, instance.serialize(), { headers: headers });
    //   }),
    //   map(result => new SecretToken().deserialize(result)),
    //   this.apiService.catchApiError(),
    //   publishLast(),
    //   refCount()
    // );
  }

  delete(
    projectName: string,
    environmentName: string,
    resourceName: string,
    instance: SecretToken
  ): Observable<boolean> {
    instance = cloneDeep(instance);
    instance.deleted = true;
    return this.createBulk(projectName, environmentName, resourceName, [instance]).pipe(map(() => true));
    // return this.apiService.refreshToken().pipe(
    //   switchMap(() => {
    //     const url = this.apiService.environmentMethodURL(
    //       projectName,
    //       environmentName,
    //       `resources/${resourceName}/secret_tokens/${instance.name}/`
    //     );
    //     let headers = new HttpHeaders();
    //
    //     headers = this.apiService.setHeadersToken(headers);
    //
    //     return this.http.delete(url, { headers: headers });
    //   }),
    //   map(result => true),
    //   this.apiService.catchApiError(),
    //   publishLast(),
    //   refCount()
    // );
  }
}

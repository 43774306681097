/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../token-structure-item/token-structure-item.component.ngfactory";
import * as i3 from "../token-structure-item/token-structure-item.component";
import * as i4 from "./token-structure-section.component";
var styles_TokenStructureSectionComponent = [];
var RenderType_TokenStructureSectionComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_TokenStructureSectionComponent, data: {} });
export { RenderType_TokenStructureSectionComponent as RenderType_TokenStructureSectionComponent };
function View_TokenStructureSectionComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["class", "code-editor__structure-section-header-left"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "div", [["class", "code-editor__structure-section-header-icon"]], [[4, "background-image", null]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (("url(" + _co.section.icon) + ")"); _ck(_v, 1, 0, currVal_0); }); }
function View_TokenStructureSectionComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [["class", "code-editor__structure-section-header-title-additional"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.section.subtitle; _ck(_v, 1, 0, currVal_0); }); }
function View_TokenStructureSectionComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 9, "div", [["class", "code-editor__structure-section-header code-editor__structure-section-header_clickable"]], [[2, "code-editor__structure-section-header_active", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleOpened() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TokenStructureSectionComponent_2)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(3, 0, null, null, 4, "div", [["class", "code-editor__structure-section-header-main"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 3, "div", [["class", "code-editor__structure-section-header-title"]], null, null, null, null, null)), (_l()(), i0.ɵted(5, null, [" ", " "])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TokenStructureSectionComponent_3)), i0.ɵdid(7, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(8, 0, null, null, 1, "div", [["class", "code-editor__structure-section-header-right"]], null, null, null, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 0, "span", [["class", "code-editor__structure-section-header-icon"]], [[2, "icon-arrow_down_2", null], [2, "icon-arrow_up_2", null]], null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.section.icon; _ck(_v, 2, 0, currVal_1); var currVal_3 = _co.section.subtitle; _ck(_v, 7, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.opened; _ck(_v, 0, 0, currVal_0); var currVal_2 = _co.section.label; _ck(_v, 5, 0, currVal_2); var currVal_4 = _co.opened; var currVal_5 = !_co.opened; _ck(_v, 9, 0, currVal_4, currVal_5); }); }
function View_TokenStructureSectionComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-token-structure-item", [], null, [[null, "inserted"], [null, "valueChanged"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("inserted" === en)) {
        var pd_0 = (_co.inserted.emit($event) !== false);
        ad = (pd_0 && ad);
    } if (("valueChanged" === en)) {
        var pd_1 = (_co.valueChanged.emit($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_TokenStructureItemComponent_0, i2.RenderType_TokenStructureItemComponent)), i0.ɵdid(1, 114688, null, 0, i3.TokenStructureItemComponent, [i0.ChangeDetectorRef], { item: [0, "item"], itemLast: [1, "itemLast"] }, { inserted: "inserted", valueChanged: "valueChanged" })], function (_ck, _v) { var currVal_0 = _v.context.$implicit; var currVal_1 = _v.context.last; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_TokenStructureSectionComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "code-editor__structure-section-content"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TokenStructureSectionComponent_5)), i0.ɵdid(2, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.section.children; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_TokenStructureSectionComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "code-editor__structure-section"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TokenStructureSectionComponent_1)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TokenStructureSectionComponent_4)), i0.ɵdid(4, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.section.label; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.opened; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_TokenStructureSectionComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-token-structure-section", [], null, null, null, View_TokenStructureSectionComponent_0, RenderType_TokenStructureSectionComponent)), i0.ɵdid(1, 114688, null, 0, i4.TokenStructureSectionComponent, [i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TokenStructureSectionComponentNgFactory = i0.ɵccf("app-token-structure-section", i4.TokenStructureSectionComponent, View_TokenStructureSectionComponent_Host_0, { section: "section", openedInitial: "openedInitial" }, { inserted: "inserted", valueChanged: "valueChanged" }, []);
export { TokenStructureSectionComponentNgFactory as TokenStructureSectionComponentNgFactory };

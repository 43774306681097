<app-customize-bar-header [title]="'Appearance'" [subtitle]="'App settings'" [backEnabled]="false">
</app-customize-bar-header>

<div class="sidebar__content">
  <app-sidebar-section>
    <div class="sidebar__element">
      <app-sidebar-field [label]="'App Name'">
        <app-auto-field
          [form]="projectForm"
          [label]="false"
          [field]="createField({ name: 'name', field: 'CharField', params: { classes: ['input_fill'] } })"
        ></app-auto-field>
      </app-sidebar-field>
    </div>

    <div class="sidebar__element">
      <app-sidebar-field [label]="'App URL'">
        <div class="input-icon-wrapper">
          <div
            class="input input_fill input_template input_clickable input_icon-right"
            (mouseenter)="appUrlHover.next(true)"
            (mouseleave)="appUrlHover.next(false)"
            (click)="editUniqueName()"
          >
            {{ appBaseUrl }}{{ appProjectUrl }}
          </div>
          <div class="input-icon input-icon_right icon-pen" [class.input-icon_bright]="appUrlHover | async"></div>
        </div>
      </app-sidebar-field>
    </div>

    <div class="sidebar__element">
      <a
        [appLink]="currentProjectStore.instance.settingsMenuLink()"
        [queryParams]="{ app_settings: true }"
        class="sidebar-summary-block"
        [class.sidebar-summary-block_clickable]="true"
      >
        <div class="sidebar-summary-block__header">
          <div class="sidebar-summary-block__header-main">
            <div class="sidebar-summary-block__header-title">
              App menu
            </div>
          </div>

          <div class="sidebar-summary-block__header-right">
            <div class="sidebar-summary-block__header-action">
              <div class="sidebar-summary-block__header-action-label">
                <ng-container *ngIf="menuBlocks.length">Customize</ng-container>
                <ng-container *ngIf="!menuBlocks.length">Add menu</ng-container>
              </div>
              <div
                class="sidebar-summary-block__header-action-icon sidebar-summary-block__header-action-icon_right icon-arrow_forward_2"
              ></div>
            </div>
          </div>
        </div>

        <div class="sidebar-summary-block__content">
          <div class="sidebar-summary-block__row">
            <div class="sidebar-summary-block__row-left">
              <div
                class="sidebar-summary-block__layout"
                [style.background-image]="
                  '/assets/images/project-layouts/layout-double-menu-left.svg' | appDeployCssUrl
                "
              ></div>
            </div>

            <div class="sidebar-summary-block__row-main">
              <div class="sidebar-summary-block__item">
                <div class="sidebar-summary-block__item-main">
                  <div
                    *ngIf="menuBlocks.length"
                    class="sidebar-summary-block__item-title sidebar-summary-block__item-element"
                  >
                    <ng-container *ngFor="let item of menuBlocks; let last = last"
                      >{{ item.controls.length }} {{ item.label }} menu<ng-container *ngIf="!last"
                        >,
                      </ng-container></ng-container
                    >
                  </div>

                  <div *ngIf="!menuBlocks.length" class="sidebar-summary-block__item-additional">
                    No menu added
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </a>
    </div>

    <div class="sidebar__element">
      <app-sidebar-field [label]="'Icon'">
        <app-logo-uploader
          [logoUrlControl]="projectForm.controls.logo"
          [logoFileControl]="projectForm.controls.logo_file"
          [logoFillControl]="projectForm.controls.logo_fill"
          [colorControl]="projectForm.controls.logo_color"
          [colorCustomEnabledControl]="projectForm.controls.logo_color_custom_enabled"
          [colorCustomControl]="projectForm.controls.logo_color_custom"
          [defaultColor]="'blue'"
          [defaultColorBackground]="'bright-blue'"
          [defaultColorBorder]="'bright-blue'"
          [initials]="(currentProjectStore.instance$ | async)?.initials"
        ></app-logo-uploader>
      </app-sidebar-field>
    </div>

    <div class="sidebar__element">
      <app-sidebar-field [label]="'Accent Color'">
        <app-color-options
          [resetEnabled]="true"
          [resetBackgroundColor]="(themeService.isDefaultTheme$ | async) ? 'bright-blue' : 'black'"
          [resetBorderColor]="'bright-blue'"
          [colors]="accentColors"
          [colorControl]="settingsForm.controls.accent_color"
          [customColorEnabledControl]="settingsForm.controls.accent_color_custom_enabled"
          [customColorControl]="settingsForm.controls.accent_color_custom"
        ></app-color-options>
      </app-sidebar-field>
    </div>

    <div class="sidebar__element">
      <app-sidebar-field [label]="'Fonts'">
        <div class="sidebar__element-segment">
          <app-font-control
            [control]="settingsForm.controls.font_regular"
            [prefix]="'Regular'"
            [fill]="true"
          ></app-font-control>
        </div>

        <div class="sidebar__element-segment">
          <app-font-control
            [control]="settingsForm.controls.font_heading"
            [prefix]="'Heading'"
            [fill]="true"
          ></app-font-control>
        </div>
      </app-sidebar-field>
    </div>

    <div class="sidebar__element">
      <app-sidebar-field [label]="'Visual theme'" [additional]="'Can be changed by user'">
        <div class="sidebar-image-toggle sidebar-image-toggle_cols_3" style="margin-top: 2px;">
          <div *ngFor="let theme of projectThemes" class="sidebar-image-toggle__col">
            <a
              href="javascript:void(0)"
              class="sidebar-image-toggle__item"
              [ngClass]="'sidebar-image-toggle__item-' + theme"
              [class.sidebar-image-toggle__item_active]="theme === settingsForm.controls.default_theme.value"
              (click)="changeTheme(theme)"
            >
              <div
                class="sidebar-image-toggle__item-inner"
                [style.background-image]="'/assets/images/theme-' + theme + '.svg' | appDeployCssUrl"
              ></div>
            </a>
          </div>
        </div>
      </app-sidebar-field>
    </div>
  </app-sidebar-section>
</div>

<app-page-template-preview-page
  class="choose-template__detail-browser"
  [class.choose-template__detail-browser_focus]="startPageFocus"
  [page]="page"
  [context]="context"
></app-page-template-preview-page>

<div
  *ngIf="fade"
  class="choose-template__detail-bottom-gradient"
  [class.choose-template__detail-bottom-gradient_visible]="true"
></div>

<ng-container *ngIf="popup">
  <div class="choose-template__detail-overlay" [class.choose-template__detail-overlay_visible]="popupFocus"></div>

  <div class="choose-template__detail-popup" [class.choose-template__detail-popup_visible]="popupFocus">
    <app-custom-page-popup
      [popup]="popup"
      [context]="context"
      [visible]="true"
      [closeEnabled]="false"
    ></app-custom-page-popup>
  </div>
</ng-container>

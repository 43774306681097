import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Observable } from 'rxjs';

import { ViewContext, ViewContextElement } from '@modules/customize';
import { FieldType } from '@modules/fields';
import { ModelDescription } from '@modules/models';
import { QueryType } from '@modules/queries';
import { HttpResultsSection, SqlResultsSection } from '@modules/queries-components';

import { DisplayFieldsEditComponent } from '../../../display-fields-edit/display-fields-edit.component';
import { ModelDescriptionDataSourceEditComponent } from '../../../model-description-data-source-edit/model-description-data-source-edit.component';
import { CustomizeBarActionEditForm } from '../../customize-bar-action-edit.form';

@Component({
  selector: 'app-customize-bar-action-edit-type-export',
  templateUrl: './customize-bar-action-edit-type-export.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomizeBarActionEditTypeExportComponent implements OnInit, OnDestroy {
  @Input() form: CustomizeBarActionEditForm;
  @Input() context: ViewContext;
  @Input() contextElement: ViewContextElement;
  @Input() contextElementPath: (string | number)[];
  @Input() contextElementPaths: (string | number)[][];
  @Input() analyticsSource: string;

  @ViewChild(ModelDescriptionDataSourceEditComponent) dataSourceEditComponent: ModelDescriptionDataSourceEditComponent;
  @ViewChild('fieldsComponent', { read: DisplayFieldsEditComponent }) columnsEditComponent: DisplayFieldsEditComponent;

  fieldTypes = FieldType;
  queryTypes = QueryType;
  columnsSearch = false;
  queryConfigured$: Observable<boolean>;
  modelDescription: ModelDescription;

  constructor(private cd: ChangeDetectorRef) {}

  ngOnInit() {
    this.queryConfigured$ = this.form.controls.export_data_source.getQueryConfigured$();

    this.form.controls.export_data_source
      .getModelDescription$()
      .pipe(untilDestroyed(this))
      .subscribe(modelDescription => {
        this.modelDescription = modelDescription;
        this.cd.markForCheck();
      });
  }

  ngOnDestroy(): void {}

  setColumnsSearch(value: boolean) {
    this.columnsSearch = value;
    this.cd.markForCheck();
  }

  toggleSearch() {
    if (this.columnsSearch) {
      this.setColumnsSearch(false);
    } else {
      this.setColumnsSearch(true);
      this.columnsEditComponent.focusSearch();
    }
  }

  addQueryInput() {
    if (this.dataSourceEditComponent) {
      this.dataSourceEditComponent.editQuery({
        initialHttpResultsSection: HttpResultsSection.Parameters,
        initialSqlResultsSection: SqlResultsSection.Parameters
      });
    }
  }
}

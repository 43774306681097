import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, publishLast, refCount, switchMap } from 'rxjs/operators';

import { ApiService } from '@modules/api';

import { Project } from '../../data/project';
import { ProjectToken } from '../../data/project-token';

@Injectable({
  providedIn: 'root'
})
export class ProjectTokenService {
  constructor(private http: HttpClient, private apiService: ApiService) {}

  create(project: Project, token: ProjectToken): Observable<ProjectToken> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.methodURL(`projects/${project.uniqueName}/project_tokens/`);
        let headers = new HttpHeaders();
        const data = token.serialize();

        headers = this.apiService.setHeadersToken(headers);

        return this.http.post(url, data, { headers: headers });
      }),
      map(result => new ProjectToken().deserialize(result)),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  update(project: Project, token: ProjectToken, fields?: string[]): Observable<ProjectToken> {
    return this.apiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.methodURL(`projects/${project.uniqueName}/project_tokens/${token.token}/`);
        let headers = new HttpHeaders();

        headers = this.apiService.setHeadersToken(headers);

        return this.http.patch(url, token.serialize(fields), { headers: headers });
      }),
      map(result => new ProjectToken().deserialize(result)),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }
}

<div class="constraints-editor">
  <div class="constraints-editor-bounds constraints-editor__bounds">
    <div
      class="constraints-editor-bounds__control constraints-editor-bounds__control_position_top"
      [class.constraints-editor-bounds__control_hover]="isControlHoverDisplay(controls.Top)"
      [class.constraints-editor-bounds__control_active]="
        (control.controls.top | appControlValue | async) == sideConstraints.Fixed
      "
      [class.constraints-editor-bounds__control_disabled]="control.isTopDisabled()"
      (mouseenter)="onControlHover(controls.Top)"
      (mouseleave)="onControlOut(controls.Top)"
      (click)="control.toggleTop(); onControlChange(controls.Top)"
    >
      <div class="constraints-editor-bounds__control-x-line"></div>
      <div class="constraints-editor-bounds__control-y-line"></div>
      <div
        class="constraints-editor-bounds__control-area constraints-editor-bounds__control-area_position_top-left"
      ></div>
      <div
        class="constraints-editor-bounds__control-area constraints-editor-bounds__control-area_position_top-right"
      ></div>
    </div>

    <div
      class="constraints-editor-bounds__control constraints-editor-bounds__control_position_right"
      [class.constraints-editor-bounds__control_hover]="isControlHoverDisplay(controls.Right)"
      [class.constraints-editor-bounds__control_active]="
        (control.controls.right | appControlValue | async) == sideConstraints.Fixed
      "
      [class.constraints-editor-bounds__control_disabled]="control.isRightDisabled()"
      (mouseenter)="onControlHover(controls.Right)"
      (mouseleave)="onControlOut(controls.Right)"
      (click)="control.toggleRight(); onControlChange(controls.Right)"
    >
      <div class="constraints-editor-bounds__control-x-line"></div>
      <div class="constraints-editor-bounds__control-y-line"></div>
      <div
        class="constraints-editor-bounds__control-area constraints-editor-bounds__control-area_position_right-top"
      ></div>
      <div
        class="constraints-editor-bounds__control-area constraints-editor-bounds__control-area_position_right-bottom"
      ></div>
    </div>

    <div
      class="constraints-editor-bounds__control constraints-editor-bounds__control_position_bottom"
      [class.constraints-editor-bounds__control_hover]="isControlHoverDisplay(controls.Bottom)"
      [class.constraints-editor-bounds__control_active]="
        (control.controls.bottom | appControlValue | async) == sideConstraints.Fixed
      "
      [class.constraints-editor-bounds__control_disabled]="control.isBottomDisabled()"
      (mouseenter)="onControlHover(controls.Bottom)"
      (mouseleave)="onControlOut(controls.Bottom)"
      (click)="control.toggleBottom(); onControlChange(controls.Bottom)"
    >
      <div class="constraints-editor-bounds__control-x-line"></div>
      <div class="constraints-editor-bounds__control-y-line"></div>
      <div
        class="constraints-editor-bounds__control-area constraints-editor-bounds__control-area_position_top-left"
      ></div>
      <div
        class="constraints-editor-bounds__control-area constraints-editor-bounds__control-area_position_top-right"
      ></div>
    </div>

    <div
      class="constraints-editor-bounds__control constraints-editor-bounds__control_position_left"
      [class.constraints-editor-bounds__control_hover]="isControlHoverDisplay(controls.Left)"
      [class.constraints-editor-bounds__control_active]="
        (control.controls.left | appControlValue | async) == sideConstraints.Fixed
      "
      [class.constraints-editor-bounds__control_disabled]="control.isLeftDisabled()"
      (mouseenter)="onControlHover(controls.Left)"
      (mouseleave)="onControlOut(controls.Left)"
      (click)="control.toggleLeft(); onControlChange(controls.Left)"
    >
      <div class="constraints-editor-bounds__control-x-line"></div>
      <div class="constraints-editor-bounds__control-y-line"></div>
      <div
        class="constraints-editor-bounds__control-area constraints-editor-bounds__control-area_position_right-top"
      ></div>
      <div
        class="constraints-editor-bounds__control-area constraints-editor-bounds__control-area_position_right-bottom"
      ></div>
    </div>

    <div class="constraints-editor-bounds__label" [class.constraints-editor-bounds__label_active]="isSidesHover()">
      snap to
    </div>
  </div>

  <div class="constraints-editor-size constraints-editor__size">
    <div
      class="constraints-editor-size-item constraints-editor-size__item"
      [class.constraints-editor-size-item_hover]="isControlHoverDisplay(controls.Width)"
      [class.constraints-editor-size-item_active]="
        (control.controls.width | appControlValue | async) == sizeConstraints.Fixed
      "
      [class.constraints-editor-size-item_disabled]="control.isWidthDisabled()"
      (mouseenter)="onControlHover(controls.Width)"
      (mouseleave)="onControlOut(controls.Width)"
      (click)="control.toggleWidth(); onControlChange(controls.Width)"
    >
      <div class="constraints-editor-size-item__control">
        <div class="constraints-editor-size-item__control-x">
          <div class="constraints-editor-size-item__control-x-end"></div>
          <div class="constraints-editor-size-item__control-x-line"></div>
          <div class="constraints-editor-size-item__control-x-end"></div>
        </div>
      </div>

      <div class="constraints-editor-size-item__label">Fix Width</div>
    </div>

    <div
      class="constraints-editor-size-item constraints-editor-size__item"
      [class.constraints-editor-size-item_hover]="isControlHoverDisplay(controls.Height)"
      [class.constraints-editor-size-item_active]="
        (control.controls.height | appControlValue | async) == sizeConstraints.Fixed
      "
      [class.constraints-editor-size-item_disabled]="control.isHeightDisabled()"
      (mouseenter)="onControlHover(controls.Height)"
      (mouseleave)="onControlOut(controls.Height)"
      (click)="control.toggleHeight(); onControlChange(controls.Height)"
    >
      <div class="constraints-editor-size-item__control">
        <div class="constraints-editor-size-item__control-y">
          <div class="constraints-editor-size-item__control-y-end"></div>
          <div class="constraints-editor-size-item__control-y-line"></div>
          <div class="constraints-editor-size-item__control-y-end"></div>
        </div>
      </div>

      <div class="constraints-editor-size-item__label">Fix Height</div>
    </div>
  </div>
</div>

import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { AdminMode, ROUTE_ADMIN_MODE$ } from '@modules/admin-mode';
import { isSet, SingletonStore } from '@shared';

import { ProjectProperty, ProjectPropertyType } from '../data/project-property';
import { ProjectPropertyService } from '../services/project-property/project-property.service';
import { CurrentEnvironmentStore } from './current-environment.store';
import { CurrentProjectStore } from './current-project.store';

@Injectable()
export class ProjectPropertyStore extends SingletonStore<ProjectProperty[]> {
  constructor(
    @Inject(ROUTE_ADMIN_MODE$) private mode$: Observable<AdminMode>,
    private projectPropertyService: ProjectPropertyService,
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore
  ) {
    super();
  }

  protected fetch(): Observable<ProjectProperty[]> {
    return this.mode$.pipe(
      switchMap(mode =>
        this.projectPropertyService.get(
          this.currentProjectStore.instance.uniqueName,
          this.currentEnvironmentStore.instance.uniqueName,
          {},
          mode == AdminMode.Builder
        )
      )
    );
  }

  get(forceUpdate: boolean = false, options: { includeDeleted?: boolean } = {}): Observable<ProjectProperty[]> {
    return super.get(forceUpdate, options).pipe(
      map(result => {
        if (!result) {
          return result;
        }

        return result.filter(item => options.includeDeleted || !item.deleted);
      })
    );
  }

  getUser(forceUpdate: boolean = false, options: { includeDeleted?: boolean } = {}): Observable<ProjectProperty[]> {
    return this.get(forceUpdate, options).pipe(
      map(result => {
        if (!result) {
          return result;
        }

        return result.filter(item => item.type == ProjectPropertyType.User);
      })
    );
  }

  getGroup(forceUpdate: boolean = false, options: { includeDeleted?: boolean } = {}): Observable<ProjectProperty[]> {
    return this.get(forceUpdate, options).pipe(
      map(result => {
        if (!result) {
          return result;
        }

        return result.filter(item => item.type == ProjectPropertyType.Group);
      })
    );
  }

  getGlobal(forceUpdate: boolean = false, options: { includeDeleted?: boolean } = {}): Observable<ProjectProperty[]> {
    return this.get(forceUpdate, options).pipe(
      map(result => {
        if (!result) {
          return result;
        }

        return result.filter(item => item.type == ProjectPropertyType.Global);
      })
    );
  }

  getPage(
    pageUid: string,
    forceUpdate: boolean = false,
    options: { includeDeleted?: boolean } = {}
  ): Observable<ProjectProperty[]> {
    return this.get(forceUpdate, options).pipe(
      map(result => {
        if (!result) {
          return result;
        }

        return result.filter(
          item => item.type == ProjectPropertyType.Page && isSet(item.pageUid) && item.pageUid == pageUid
        );
      })
    );
  }

  getFirst(forceUpdate: boolean = false, options: { includeDeleted?: boolean } = {}): Observable<ProjectProperty[]> {
    return super.getFirst(forceUpdate, options).pipe(
      map(result => {
        if (!result) {
          return result;
        }

        return result.filter(item => options.includeDeleted || !item.deleted);
      })
    );
  }

  getDetail(uid: string, forceUpdate = false, options: { includeDeleted?: boolean } = {}) {
    return this.get(forceUpdate, options).pipe(
      map(properties => {
        if (!properties) {
          return;
        }

        return properties.find(property => property.uid === uid);
      })
    );
  }

  getDetailFirst(uid: string, forceUpdate = false, options: { includeDeleted?: boolean } = {}) {
    return this.getFirst(forceUpdate, options).pipe(
      map(properties => {
        if (!properties) {
          return;
        }

        return properties.find(property => property.uid === uid);
      })
    );
  }
}

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, publishLast, refCount, switchMap } from 'rxjs/operators';

import { ApiService } from '@modules/api';
import { ProjectApiService } from '@modules/project-api';
import { View } from '@modules/views';

export interface SketchGetPagesResponse {
  pages: {
    id: string;
    name: string;
    artboards: { id: string; name: string }[];
  }[];
  previewBase64?: string;
}

@Injectable()
export class SketchService {
  constructor(private http: HttpClient, private apiService: ApiService, private projectApiService: ProjectApiService) {}

  getPages(projectName: string, environmentName: string, file: Blob): Observable<SketchGetPagesResponse> {
    return this.projectApiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.nodeEnvironmentMethodURL(projectName, environmentName, 'sketch/get_pages');
        let headers = new HttpHeaders();
        const data = new FormData();

        data.set('file', file);

        headers = this.projectApiService.setHeadersToken(headers);

        return this.http.post<SketchGetPagesResponse>(url, data, { headers: headers });
      }),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }

  parseArtboard(
    projectName: string,
    environmentName: string,
    file: Blob,
    pageId: string,
    artboardId: string
  ): Observable<View> {
    return this.projectApiService.refreshToken().pipe(
      switchMap(() => {
        const url = this.apiService.nodeEnvironmentMethodURL(projectName, environmentName, 'sketch/parse_artboard');
        let headers = new HttpHeaders();
        const data = new FormData();

        data.set('file', file);
        data.set('pageId', pageId);
        data.set('artboardId', artboardId);

        headers = this.projectApiService.setHeadersToken(headers);

        return this.http.post<{ view: Object }>(url, data, { headers: headers });
      }),
      map(result => new View().deserialize(result.view)),
      this.apiService.catchApiError(),
      publishLast(),
      refCount()
    );
  }
}

import { isSet } from '@shared';

import { AutomationTriggerType } from '../automation-trigger-type';
import { registerAutomationTriggerForType } from '../automation-trigger-types';
import { AutomationTrigger } from './base';

export class WebhookAutomationTrigger extends AutomationTrigger {
  type = AutomationTriggerType.Webhook;
  webhookToken: string;

  constructor(options: Partial<WebhookAutomationTrigger> = {}) {
    super(options);
  }

  deserialize(data: Object): this {
    this.webhookToken = data['webhook_token'];

    return this;
  }

  serialize() {
    return {
      webhook_token: this.webhookToken
    };
  }

  getRunPath() {
    return isSet(this.webhookToken) ? `hooks/${this.webhookToken}` : undefined;
  }
}

registerAutomationTriggerForType(AutomationTriggerType.Webhook, WebhookAutomationTrigger);

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject } from 'rxjs';

import { AppConfigService } from '@core';
import { Domain } from '@modules/domain';
import { ProjectDomainController } from '@modules/domain-components';
import { EmailTemplate, EmailTemplateInfo, emailTemplates, EmailTemplateService } from '@modules/emails';
import { EmailAddressEditController, EmailTemplateEditController } from '@modules/emails-components';
import { ProjectGroupDropdownComponent } from '@modules/project-settings-components';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { isSet } from '@shared';

import { ProjectDomainUpdateForm } from '../sign-up-builder/project-domain-update.form';
import { ProjectSignUpForm } from '../sign-up-builder/project-sign-up.form';

interface EmailItem {
  info: EmailTemplateInfo;
  base: EmailTemplate;
  custom?: EmailTemplate;
}

@Component({
  selector: 'app-customize-bar-emails-edit',
  templateUrl: './customize-bar-emails-edit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomizeBarEmailsEditComponent implements OnInit, OnDestroy {
  @Input() projectSignUpForm: ProjectSignUpForm;
  @Input() projectDomainUpdateForm: ProjectDomainUpdateForm;

  @ViewChild('dropdown') signUpEnableGroupDropdown: ProjectGroupDropdownComponent;

  domain: Domain;
  isWhiteLabel = false;
  emailsFromHover = new BehaviorSubject<boolean>(false);
  emailItems: EmailItem[];
  emailItemsLoading = false;
  analyticsSource = 'layout_builder_emails';

  constructor(
    public currentProjectStore: CurrentProjectStore,
    public currentEnvironmentStore: CurrentEnvironmentStore,
    public appConfigService: AppConfigService,
    private projectDomainController: ProjectDomainController,
    private emailTemplateService: EmailTemplateService,
    private emailTemplateEditController: EmailTemplateEditController,
    private emailsFromEditController: EmailAddressEditController,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.projectDomainUpdateForm
      .getInstance$()
      .pipe(untilDestroyed(this))
      .subscribe(domain => {
        this.domain = domain;
        this.isWhiteLabel = this.domain && this.domain.whiteLabel;
        this.cd.markForCheck();
      });

    this.fetchEmailTemplates();
  }

  ngOnDestroy(): void {}

  editEmailsFrom() {
    this.emailsFromEditController.edit().pipe(untilDestroyed(this)).subscribe();
  }

  fetchEmailTemplates() {
    this.emailItemsLoading = true;
    this.cd.markForCheck();

    this.emailTemplateService
      .get(this.currentProjectStore.instance.uniqueName, this.currentEnvironmentStore.instance.uniqueName)
      .pipe(untilDestroyed(this))
      .subscribe(
        result => {
          const defaultLanguageEmails = result.filter(item => !isSet(item.language));

          this.emailItems = emailTemplates.map(info => {
            return {
              info: info,
              base: defaultLanguageEmails.find(item => item.name == info.name && item.protected),
              custom: defaultLanguageEmails.find(item => item.name == info.name && !item.protected)
            };
          });
          this.emailItemsLoading = false;
          this.cd.markForCheck();
        },
        () => {
          this.emailItemsLoading = false;
          this.cd.markForCheck();
        }
      );
  }

  editDomain() {
    this.projectDomainController
      .edit({ analyticsSource: 'layout_builder_sign_up' })
      .pipe(untilDestroyed(this))
      .subscribe();
  }

  onCustomDomainRequiredClick() {
    if (!this.domain || !this.domain.custom) {
      this.editDomain();
    }
  }

  editEmailItem(emailItem: EmailItem) {
    if (emailItem.custom) {
      this.emailTemplateEditController
        .edit({
          emailTemplate: emailItem.custom,
          baseEmailTemplate: emailItem.base,
          analyticsSource: this.analyticsSource
        })
        .pipe(untilDestroyed(this))
        .subscribe(result => {
          this.emailItems = this.emailItems.map(item => {
            if (item.info.name == emailItem.info.name) {
              return {
                ...item,
                custom: result
              };
            } else {
              return item;
            }
          });
          this.cd.markForCheck();
        });
    } else {
      this.emailTemplateEditController
        .create({ baseEmailTemplate: emailItem.base, analyticsSource: this.analyticsSource })
        .pipe(untilDestroyed(this))
        .subscribe(result => {
          this.emailItems = this.emailItems.map(item => {
            if (item.info.name == emailItem.info.name) {
              return {
                ...item,
                custom: result
              };
            } else {
              return item;
            }
          });
          this.cd.markForCheck();
        });
    }
  }

  resetEmailItem(emailItem: EmailItem) {
    this.emailTemplateEditController
      .reset({ emailTemplate: emailItem.custom, analyticsSource: this.analyticsSource })
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.emailItems = this.emailItems.map(item => {
          if (item.info.name == emailItem.info.name) {
            return {
              ...item,
              custom: undefined
            };
          } else {
            return item;
          }
        });
        this.cd.markForCheck();
      });
  }
}

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./guide-focus.component";
import * as i2 from "../../services/guide-focus/guide-focus.service";
import * as i3 from "@angular/platform-browser";
var styles_GuideFocusComponent = [];
var RenderType_GuideFocusComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_GuideFocusComponent, data: {} });
export { RenderType_GuideFocusComponent as RenderType_GuideFocusComponent };
export function View_GuideFocusComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "div", [["class", "guide-focus"]], [[2, "guide-focus_visible", null], [4, "transform", null]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.focusTransform; var currVal_1 = _co.focusTransform; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
export function View_GuideFocusComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-guide-focus", [], null, null, null, View_GuideFocusComponent_0, RenderType_GuideFocusComponent)), i0.ɵdid(1, 245760, null, 0, i1.GuideFocusComponent, [i2.GuideFocusService, i3.DomSanitizer, i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var GuideFocusComponentNgFactory = i0.ɵccf("app-guide-focus", i1.GuideFocusComponent, View_GuideFocusComponent_Host_0, {}, {}, []);
export { GuideFocusComponentNgFactory as GuideFocusComponentNgFactory };

<ng-container *ngFor="let item of sections; let last = last">
  <div class="token-popup__item">
    <div class="token-popup-section" [class.token-popup-section_horizontal]="section.horizontal">
      <div *ngIf="title && item.title" class="token-popup-section__title">
        <div class="token-popup-section__title-main">
          <span [innerHTML]="item.title"></span>
          <a
            *ngIf="section.documentation"
            href="javascript:void(0)"
            class="token-popup-section__title-link token-popup-section__title-icon token-popup-section__title-icon_right icon-help"
            [appDocumentation]="section.documentation"
            [appDocumentationLeft]="true"
          ></a>
        </div>

        <div *ngIf="section.action" class="token-popup-section__title-right">
          <a
            href="javascript:void(0)"
            class="token-popup-section__title-link token-popup-section__title-link_orange"
            (click)="onSectionActionClick()"
          >
            <span *ngIf="section.action.icon" [ngClass]="'icon-' + section.action.icon"></span>
            {{ section.action.label }}
          </a>
        </div>
      </div>
      <div class="token-popup-section__content">
        <div *ngIf="!section.items.length" class="token-popup-list-item token-popup-section__content-item">
          <div class="token-popup-list-item__inner">
            <div class="token-popup-list-item__tip">Nothing here</div>
          </div>
        </div>

        <app-view-context-token-popover-section-item
          *ngFor="let item of item.items; trackBy: trackItem"
          class="token-popup-section__content-item"
          [item]="item"
          [activeToken]="activeToken"
          [descriptionAlwaysVisible]="descriptionAlwaysVisible"
          (hover)="hover.emit(item)"
          (out)="out.emit(item)"
          (opened)="onSectionOpened($event)"
          (selected)="onItemSelected($event)"
        ></app-view-context-token-popover-section-item>

        <a
          *ngIf="showMore"
          href="javascript:void(0)"
          class="token-popup-list-item token-popup-list-item_orange token-popup-list-item_clickable token-popup-section__content-item"
          (click)="showMoreClick.emit()"
        >
          <div class="token-popup-list-item__inner">
            <div class="token-popup-list-item__line">
              <span class="token-popup-list-item__main">Show more</span>
            </div>
          </div>
        </a>
      </div>
      <div *ngIf="section.orange && title" class="token-popup-section__indicator"></div>
    </div>
  </div>
  <div *ngIf="showSubtitle && !last" class="token-popup-border"></div>
</ng-container>

import { Injectable, OnDestroy } from '@angular/core';
import { AbstractControl, FormArray, FormGroup } from '@angular/forms';
import fromPairs from 'lodash/fromPairs';
import isEqual from 'lodash/isEqual';
import range from 'lodash/range';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';

import { ViewContext, ViewContextElement } from '@modules/customize';
import {
  BaseField,
  EditableField,
  getFieldDescriptionByType,
  Input,
  InputFilterField,
  InputValueType,
  isInputSet
} from '@modules/fields';
import {
  FieldInputControl,
  FieldInputRequiredValidator,
  InputFieldProvider,
  InputFieldProviderItem
} from '@modules/parameters';
import { controlValue } from '@shared';

@Injectable()
export class InputsEditForm implements OnDestroy {
  control: AbstractControl;
  parameterProvider: InputFieldProvider;
  fieldsControl: AbstractControl;
  context: ViewContext;
  contextElement: ViewContextElement;

  form = new FormArray([]);
  updatedFromControl = new Subject();

  init(
    control: AbstractControl,
    parameterProvider: InputFieldProvider,
    fieldsControl: AbstractControl,
    context?: ViewContext,
    contextElement?: ViewContextElement
  ) {
    this.control = control;
    this.parameterProvider = parameterProvider;
    this.fieldsControl = fieldsControl;
    this.context = context;
    this.contextElement = contextElement;

    this.control.valueChanges.pipe(debounceTime(10), untilDestroyed(this)).subscribe(() => {
      if (this.updateValueFromControl()) {
        this.updatedFromControl.next();
      }
    });

    this.updateValueFromControl();

    this.form.valueChanges.pipe(debounceTime(60)).subscribe(value => this.updateValueToControl(value));
  }

  ngOnDestroy(): void {}

  serializeValueItem(item: Object): Input {
    const input = new Input();
    const field = this.parameterProvider.fields.find(i => i.name == item['name']);

    if (!field) {
      return;
    }

    input.name = item['name'];

    if (!field.field) {
      input.valueType = InputValueType.StaticValue;
      input.staticValue = true;
    } else {
      input.valueType = item['value_type'];
      input.staticValue = item['static_value'];

      if (input.valueType == InputValueType.StaticValue && field) {
        const fieldDescription = getFieldDescriptionByType(field.field);
        if (fieldDescription.serializeValue) {
          input.staticValue = fieldDescription.serializeValue(input.staticValue, {
            ...field,
            params: {
              ...field.params,
              output_format: undefined
            }
          });
        }
      }

      input.contextValue = item['context_value'];
      input.filterField = item['filter_field'];
      input.filterLookup = item['filter_lookup'];
      input.formulaValue = item['formula_value'];
      input.textInputsType = item['text_inputs_type'];
      input.textInputsValue = item['text_inputs_value'];
      input.jsValue = item['js_value'];
      input.required = item['required'];
    }

    return input;
  }

  serializeValue(value: Object[]): Input[] {
    return value
      .filter(item => item['name'])
      .map(item => this.serializeValueItem(item))
      .filter(item => item);
  }

  deserializeValue(value: Input[]): Object[] {
    return value.map(item => {
      return {
        name: item.name,
        value_type: item.valueType,
        static_value: item.staticValue,
        context_value: item.contextValue,
        filter_field: item.filterField,
        filter_lookup: item.filterLookup,
        formula_value: item.formulaValue,
        text_inputs_type: item.textInputsType,
        text_inputs_value: item.textInputsValue,
        js_value: item.jsValue,
        required: item.required
      };
    });
  }

  updateValueFromControl() {
    const value = this.control.value as Input[];
    // TODO: Add value equals check
    const createItems = [
      ...this.parameterProvider.fields
        .filter(item => item['required'] === true)
        .filter(field => value.find(item => item.name == field.name) == undefined)
        .map(field => {
          return {
            name: field.name,
            field: field.field
          };
        }),
      ...this.deserializeValue(value).map(item => {
        const field = this.parameterProvider.fields.find(i => i.name == item['name']);
        return {
          field: field ? field.field : null,
          ...item
        };
      })
    ];

    if (isEqual(createItems, this.form.value)) {
      return false;
    }

    this.arrayUpdate(createItems);

    return true;
  }

  updateValueToControl(value: any[]) {
    const currentValue = this.control.value as Input[];
    const controlValueObj = this.deserializeValue(currentValue);
    if (isEqual(value, controlValueObj)) {
      return;
    }

    this.control.patchValue(this.serializeValue(value));
  }

  parameter$(form: FormGroup): Observable<EditableField> {
    return combineLatest(controlValue<string>(form.controls['name']), this.parameterProvider.fields$).pipe(
      map(([name, fields]) => {
        if (!name) {
          return;
        }
        return fields.find(item => item.name == name);
      })
    );
  }

  providerItem$(form: FormGroup): Observable<InputFieldProviderItem> {
    return combineLatest(controlValue<string>(form.controls['name']), this.parameterProvider.value$).pipe(
      map(([name, values]) => {
        if (!name) {
          return;
        }

        return values.find(item => item.field && item.field.name == name);
      })
    );
  }

  value$(form: FormGroup): Observable<Object> {
    return controlValue<Object>(form);
  }

  isSet$(form: FormGroup): Observable<boolean> {
    return this.value$(form).pipe(map(value => isInputSet(value)));
  }

  filterFields$(): Observable<InputFilterField[]> {
    if (!this.fieldsControl) {
      return of([]);
    }

    return controlValue<BaseField[]>(this.fieldsControl).pipe(
      map(fields => {
        return fields.map(item => {
          const fieldDescription = getFieldDescriptionByType(item.field);

          return {
            name: item.name,
            label: item.verboseName || item.name,
            lookups: fieldDescription
              ? fieldDescription.lookups.map(lookup => {
                  return {
                    name: lookup.type.lookup,
                    label: lookup.type.verboseName || lookup.type.name
                  };
                })
              : []
          };
        });
      })
    );
  }

  createItem(value?: Object): FormGroup {
    return new FieldInputControl(value, FieldInputRequiredValidator);
  }

  addItem(item: InputFieldProviderItem) {
    const form = this.createItem({ name: item.field.name, field: item.field.field });
    this.arrayAppend(form);
    return form;
  }

  isItemPristine(item: FormGroup) {
    return item.value['value_type'] == InputValueType.StaticValue && item.value['static_value'] === '';
  }

  groupTrackBy(item: Object): any {
    return item['name'];
  }

  arraySet(groups: AbstractControl[]) {
    range(this.form.controls.length).forEach(() => this.form.removeAt(0));
    groups.forEach(item => this.form.push(item));
    this.form.updateValueAndValidity();
  }

  arrayUpdate(value: Object[]) {
    const existingControls = fromPairs(this.form.controls.map(item => [this.groupTrackBy(item.value), item]));
    const preserveControls = [];

    value
      .sort((lhs, rhs) => {
        const isInputSetLhs = isInputSet(lhs) ? 1 : 0;
        const isInputSetRhs = isInputSet(rhs) ? 1 : 0;
        const parameterIndexLhs = this.parameterProvider.fields.findIndex(item => item.name == lhs['name']);
        const parameterIndexRhs = this.parameterProvider.fields.findIndex(item => item.name == rhs['name']);
        const parameterLhs = parameterIndexLhs != -1 ? this.parameterProvider.fields[parameterIndexLhs] : undefined;
        const parameterRhs = parameterIndexRhs != -1 ? this.parameterProvider.fields[parameterIndexRhs] : undefined;
        const requiredLhs = parameterLhs && parameterLhs.required ? 1 : 0;
        const requiredRhs = parameterRhs && parameterRhs.required ? 1 : 0;

        return (
          (requiredLhs - requiredRhs) * 10 * -1 +
          (isInputSetLhs - isInputSetRhs) * -1 +
          (parameterIndexLhs - parameterIndexRhs) / 10
        );
      })
      .forEach(item => {
        const id = this.groupTrackBy(item);
        let control = existingControls[id];

        if (control) {
          control.patchValue(item);
        } else {
          control = this.createItem(item);
          this.form.push(control);
        }

        preserveControls.push(control);
      });

    this.form.controls
      .map<[AbstractControl, number]>((item, i) => [item, i])
      .filter(([item, index]) => !preserveControls.includes(item))
      .reverse()
      .forEach(([item, index]) => {
        this.form.removeAt(index);
      });

    this.form.updateValueAndValidity();
  }

  arrayAppend(...groups: FormGroup[]) {
    groups.forEach(item => this.form.push(item));
    this.form.updateValueAndValidity();
  }

  arrayRemove(...groups: FormGroup[]) {
    groups.forEach(item => {
      const index = this.form.controls.findIndex(i => i === item);

      if (index == -1) {
        return;
      }

      this.form.removeAt(index);
    });

    this.form.updateValueAndValidity();
  }
}

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./popup2-description.component";
var styles_Popup2DescriptionComponent = [];
var RenderType_Popup2DescriptionComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_Popup2DescriptionComponent, data: {} });
export { RenderType_Popup2DescriptionComponent as RenderType_Popup2DescriptionComponent };
export function View_Popup2DescriptionComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["class", "popup2-description"]], null, null, null, null, null)), i0.ɵdid(1, 278528, null, 0, i1.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpad(2, 1), i0.ɵncd(null, 0)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "popup2-description"; var currVal_1 = _ck(_v, 2, 0, ("popup2-description_align_" + _co.align)); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_Popup2DescriptionComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-popup2-description", [], null, null, null, View_Popup2DescriptionComponent_0, RenderType_Popup2DescriptionComponent)), i0.ɵdid(1, 114688, null, 0, i2.Popup2DescriptionComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var Popup2DescriptionComponentNgFactory = i0.ɵccf("app-popup2-description", i2.Popup2DescriptionComponent, View_Popup2DescriptionComponent_Host_0, { align: "align" }, {}, ["*"]);
export { Popup2DescriptionComponentNgFactory as Popup2DescriptionComponentNgFactory };

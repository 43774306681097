var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a;
import { ChangeDetectorRef, EventEmitter, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import toPairs from 'lodash/toPairs';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { FieldDescriptionCategory, fieldDescriptions, fieldTypesOrder } from '@modules/fields';
import { isSet, objectsListSort } from '@shared';
export var fieldTypeCategoryLabels = (_a = {},
    _a[FieldDescriptionCategory.Text] = 'Text',
    _a[FieldDescriptionCategory.Numeric] = 'Numeric',
    _a[FieldDescriptionCategory.Choices] = 'Choices',
    _a[FieldDescriptionCategory.DateTime] = 'Date & Time',
    _a[FieldDescriptionCategory.Relationships] = 'Relationships',
    _a[FieldDescriptionCategory.Files] = 'Files',
    _a[FieldDescriptionCategory.Others] = 'Others',
    _a);
var FieldTypeMenuComponent = /** @class */ (function () {
    function FieldTypeMenuComponent(cd) {
        this.cd = cd;
        this.select = new EventEmitter();
        this.searchControl = new FormControl('');
        this.defaultSections = toPairs(fieldDescriptions
            .filter(function (item) { return item.public; })
            .map(function (item) {
            return {
                value: item.name,
                name: item.label,
                icon: item.icon,
                description: item.description,
                group: item.category
            };
        })
            .sort(objectsListSort(fieldTypesOrder, function (item) { return item.value; }))
            .reduce(function (acc, item) {
            if (!acc[item.group]) {
                acc[item.group] = [];
            }
            acc[item.group].push(item);
            return acc;
        }, {}))
            .sort(objectsListSort([
            FieldDescriptionCategory.Text,
            FieldDescriptionCategory.Numeric,
            FieldDescriptionCategory.Choices,
            FieldDescriptionCategory.DateTime,
            FieldDescriptionCategory.Relationships,
            FieldDescriptionCategory.Files,
            FieldDescriptionCategory.Others
        ], function (_a) {
            var group = _a[0], items = _a[1];
            return group;
        }))
            .map(function (_a) {
            var group = _a[0], items = _a[1];
            var category = group;
            return {
                label: fieldTypeCategoryLabels[category],
                children: items
            };
        });
        this.sections = this.defaultSections;
        this.sectionsFiltered = [];
    }
    FieldTypeMenuComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.updateFilteredSections();
        this.searchControl.valueChanges.pipe(untilDestroyed(this)).subscribe(function () { return _this.updateFilteredSections(); });
    };
    FieldTypeMenuComponent.prototype.ngOnDestroy = function () { };
    FieldTypeMenuComponent.prototype.ngOnChanges = function (changes) {
        if (changes.overrideSections) {
            this.sections = this.overrideSections || this.defaultSections;
        }
    };
    FieldTypeMenuComponent.prototype.getFilteredSections = function () {
        var search = this.searchControl.value.toLowerCase().trim();
        if (!isSet(search)) {
            return this.sections;
        }
        return this.sections
            .map(function (section) {
            return __assign({}, section, { children: section.children.filter(function (sectionItem) {
                    return [sectionItem.name, sectionItem.description, section.label].some(function (item) {
                        return isSet(item) && item.toLowerCase().includes(search);
                    });
                }) });
        })
            .filter(function (item) { return item.children.length; });
    };
    FieldTypeMenuComponent.prototype.updateFilteredSections = function () {
        this.sectionsFiltered = this.getFilteredSections();
        this.cd.markForCheck();
    };
    FieldTypeMenuComponent.prototype.clearSearch = function () {
        this.searchControl.patchValue('');
        this.updateFilteredSections();
    };
    return FieldTypeMenuComponent;
}());
export { FieldTypeMenuComponent };

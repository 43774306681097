var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ChangeDetectorRef, OnDestroy, OnInit, Provider } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { NotificationService } from '@common/notifications';
import { BasePopupComponent } from '@common/popups';
import { ACTION_SERVICE_EXPORT_COMPONENT } from '@modules/action-queries';
import { ServerRequestError } from '@modules/api';
import { ViewContext, ViewContextElement } from '@modules/customize';
import { ListModelDescriptionDataSource } from '@modules/data-sources';
import { applyParamInput } from '@modules/fields';
import { PAGE_PARAM } from '@modules/models';
import { ModelListStore } from '@modules/models-list';
import { paramsToGetQueryOptions } from '@modules/resources';
import { isSet } from '@shared';
import { exportFormats } from '../../data/export-formats';
import { ExportService } from '../../services/export/export.service';
export var ACTION_SERVICE_EXPORT_COMPONENT_PROVIDER = {
    provide: ACTION_SERVICE_EXPORT_COMPONENT,
    useFactory: actionServiceExportComponentFactory
};
export function actionServiceExportComponentFactory() {
    return ExportComponent;
}
var ExportComponent = /** @class */ (function () {
    function ExportComponent(exportService, modelListStore, popupComponent, notificationService, cd) {
        this.exportService = exportService;
        this.modelListStore = modelListStore;
        this.popupComponent = popupComponent;
        this.notificationService = notificationService;
        this.cd = cd;
        this.inverseIds = false;
        this.loading = true;
        this.exportFormats = exportFormats;
        this.executeActionStarted = false;
        this.executeActionProgress = 0;
        this.executeActionSucceeded = false;
    }
    ExportComponent.prototype.ngOnInit = function () {
        var _this = this;
        var queryOptions = this.getGetQueryOptions();
        var staticData = [];
        try {
            staticData = applyParamInput(this.dataSource.input, {
                context: this.context,
                contextElement: this.contextElement,
                localContext: this.localContext,
                defaultValue: []
            });
        }
        catch (e) { }
        this.modelListStore.dataSource = this.dataSource;
        this.modelListStore.queryOptions = queryOptions;
        this.modelListStore.staticData = staticData;
        this.modelListStore.context = this.context;
        this.modelListStore.contextElement = this.contextElement;
        this.modelListStore.localContext = this.localContext;
        this.modelListStore
            .getNext()
            .pipe(untilDestroyed(this))
            .subscribe(function () {
            _this.loading = false;
            _this.items = _this.modelListStore.items;
            _this.count = _this.modelListStore.count;
            _this.cd.markForCheck();
        }, function () {
            _this.loading = false;
            _this.cd.markForCheck();
        });
    };
    ExportComponent.prototype.ngOnDestroy = function () { };
    ExportComponent.prototype.getQueryParams = function () {
        var params = {};
        if (this.primaryKey && this.ids && this.ids.length) {
            if (this.inverseIds) {
                params["exclude__" + this.primaryKey + "__in"] = this.ids;
            }
            else {
                params[this.primaryKey + "__in"] = this.ids;
            }
        }
        params = __assign({}, this.params, params);
        if (params[PAGE_PARAM] != undefined) {
            delete params[PAGE_PARAM];
        }
        return params;
    };
    ExportComponent.prototype.getGetQueryOptions = function () {
        var queryParams = this.getQueryParams();
        var queryOptions = queryParams ? paramsToGetQueryOptions(queryParams) : {};
        queryOptions.filters = (this.queryOptions && this.queryOptions.filters ? this.queryOptions.filters : []).concat((queryOptions.filters ? queryOptions.filters : []));
        if (!isSet(queryOptions.search) && this.queryOptions && isSet(this.queryOptions.search)) {
            queryOptions.search = this.queryOptions.search;
        }
        queryOptions.sort = (this.queryOptions && this.queryOptions.sort ? this.queryOptions.sort : []).concat((queryOptions.sort ? queryOptions.sort : []));
        return queryOptions;
    };
    Object.defineProperty(ExportComponent.prototype, "executeActionStatus", {
        get: function () {
            if (this.executeActionProgress === undefined || this.executeActionProgress < 1) {
                return 'Exporting...';
            }
            else if (this.executeActionSucceeded) {
                return 'Export Completed';
            }
            else if (!this.executeActionSucceeded) {
                return 'Export Error';
            }
        },
        enumerable: true,
        configurable: true
    });
    ExportComponent.prototype.exportData = function () {
        var _this = this;
        if (!this.formatType || this.executeActionStarted) {
            return;
        }
        this.executeActionStarted = true;
        this.executeActionProgress = 0;
        this.cd.markForCheck();
        var queryOptions = this.getGetQueryOptions();
        this.exportService
            .exportModels(this.title, this.dataSource, queryOptions, this.formatType, this.context, this.contextElement, this.localContext)
            .pipe(untilDestroyed(this))
            .subscribe(function (progress) {
            _this.executeActionProgress = progress;
            _this.executeActionSucceeded = progress == 1;
            _this.cd.markForCheck();
        }, function (error) {
            console.error(error);
            if (error instanceof ServerRequestError && error.errors.length) {
                _this.notificationService.error('Export Failed', error.errors[0]);
            }
            else {
                _this.notificationService.error('Export Failed', error);
            }
            _this.executeActionProgress = 1;
            _this.executeActionSucceeded = false;
            _this.cd.markForCheck();
        });
    };
    ExportComponent.prototype.close = function () {
        this.exportService.cancel = true;
        this.popupComponent.close();
    };
    return ExportComponent;
}());
export { ExportComponent };

import { FormControl, FormGroup } from '@angular/forms';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { getInputValueTypeStr, Input } from '@modules/fields';
import { FieldInputControl } from '@modules/parameters';
import { Fill, FillType, GradientType } from '@modules/views';
import { controlValue, isSet } from '@shared';

import { ColorControl } from './color.control';
import { GradientControl } from './gradient.control';
import { IconFillControl } from './icon-fill.control';
import { ImageFillControl } from './image-fill.control';

export class FillControl extends FormGroup {
  instance: Fill;

  controls: {
    type: FormControl;
    color: ColorControl;
    color_input_enabled: FormControl;
    color_input: FieldInputControl;
    gradient: GradientControl;
    image_fill: ImageFillControl;
    icon_fill: IconFillControl;
    opacity: FormControl;
    enabled: FormControl;
    enabled_input: FieldInputControl;
  };

  constructor(state: Partial<Fill> = {}) {
    super({
      type: new FormControl(isSet(state.type) ? state.type : FillType.Color),
      color: new ColorControl(isSet(state.color) ? state.color : {}),
      color_input_enabled: new FormControl(isSet(state.colorInput) ? !!state.colorInput : false),
      color_input: new FieldInputControl({ name: 'value' }),
      gradient: new GradientControl(isSet(state.gradient) ? state.gradient : {}),
      image_fill: new ImageFillControl(isSet(state.imageFill) ? state.imageFill : {}),
      icon_fill: new IconFillControl(isSet(state.iconFill) ? state.iconFill : {}),
      opacity: new FormControl(isSet(state.opacity) ? state.opacity : 1),
      enabled: new FormControl(isSet(state.enabled) ? state.enabled : true),
      enabled_input: new FieldInputControl({ name: 'value' })
    });
  }

  deserialize(value: Fill, options: { emitEvent?: boolean } = {}) {
    this.instance = value;

    this.controls.type.patchValue(value.type, { emitEvent: options.emitEvent });

    if (value.color) {
      this.controls.color.deserialize(value.color, { emitEvent: options.emitEvent });
    }

    this.controls.color_input_enabled.patchValue(!!value.colorInput, { emitEvent: options.emitEvent });
    this.controls.color_input.patchValue(value.colorInput ? value.colorInput.serialize() : {}, {
      emitEvent: options.emitEvent
    });

    if (value.gradient) {
      this.controls.gradient.deserialize(value.gradient, { emitEvent: options.emitEvent });
    }

    if (value.imageFill) {
      this.controls.image_fill.deserialize(value.imageFill, { emitEvent: options.emitEvent });
    }

    if (value.iconFill) {
      this.controls.icon_fill.deserialize(value.iconFill, { emitEvent: options.emitEvent });
    }

    this.controls.opacity.patchValue(value.opacity, { emitEvent: options.emitEvent });
    this.controls.enabled.patchValue(value.enabled, { emitEvent: options.emitEvent });
    this.controls.enabled_input.patchValue(value.enabledInput ? value.enabledInput.serialize() : {});
  }

  setGradientType(type: GradientType) {
    const prevType = this.controls.type.value;
    const defaultColor = prevType == FillType.Color ? this.controls.color.getInstance() : undefined;

    this.patchValue({
      type: FillType.Gradient,
      gradient: {
        type: type
      }
    });
    this.controls.gradient.validateStops(defaultColor);
  }

  getColorInputRepresentation(): Observable<string> {
    return combineLatest(
      controlValue<boolean>(this.controls.color_input_enabled),
      controlValue(this.controls.color_input).pipe(map(() => this.controls.color_input.serialize()))
    ).pipe(
      map(([colorInputEnabled, colorInput]) => {
        if (colorInputEnabled && colorInput) {
          return getInputValueTypeStr(colorInput.valueType, { context: 'component' });
        }
      })
    );
  }

  getInstance(instance?: Fill): Fill {
    if (!instance) {
      instance = new Fill();
      instance.generateId();
    }

    instance.type = this.controls.type.value;

    if (instance.type == FillType.Color || instance.color || instance.colorInput) {
      if (this.controls.color_input_enabled.value) {
        instance.color = this.controls.color.getInstance(instance.color);
        instance.colorInput = this.controls.color_input.value
          ? new Input().deserialize(this.controls.color_input.value)
          : undefined;
      } else {
        instance.color = this.controls.color.getInstance(instance.color);
        instance.colorInput = undefined;
      }
    }

    if (instance.type == FillType.Gradient || instance.gradient) {
      instance.gradient = this.controls.gradient.getInstance(instance.gradient);
    }

    if (instance.type == FillType.Image || instance.imageFill) {
      instance.imageFill = this.controls.image_fill.getInstance(instance.imageFill);
    }

    if (instance.type == FillType.Icon || instance.iconFill) {
      instance.iconFill = this.controls.icon_fill.getInstance(instance.iconFill);
    }

    instance.opacity = this.controls.opacity.value;
    instance.enabled = this.controls.enabled.value;
    instance.enabledInput = this.controls.enabled_input.value
      ? new Input().deserialize(this.controls.enabled_input.value)
      : undefined;

    return instance;
  }

  serialize(): Fill {
    return this.getInstance(this.instance);
  }
}

import { ResourceName } from './resource-name';

export const databaseResourcesEngines = [
  { name: ResourceName.PostgreSQL, engines: ['postgresql'], defaultPort: 5432 },
  { name: ResourceName.MySQL, engines: ['mysql'], defaultPort: 3306 },
  { name: ResourceName.MariaDB, engines: ['mysql'], defaultPort: 3306 },
  { name: ResourceName.MicrosoftSQL, engines: ['mssql+pyodbc'], defaultPort: 1433 },
  { name: ResourceName.Oracle, engines: ['oracle'], defaultPort: 1521 },
  { name: ResourceName.BigQuery, engines: ['bigquery'] },
  { name: ResourceName.Snowflake, engines: ['snowflake'] },
  { name: ResourceName.Redshift, engines: ['postgresql'], defaultPort: 5439 },
  { name: ResourceName.AlloyDB, engines: ['postgresql'], defaultPort: 5432 },
  { name: ResourceName.Supabase, engines: ['postgresql'], defaultPort: 5432 },
  { name: ResourceName.SQLite, engines: ['sqlite'] },
  { name: ResourceName.JetDatabase, engines: ['postgresql'] }
];

import { WorkflowRun, WorkflowStep } from '@modules/workflow';
import { AppError, isSet } from '@shared';

export class WorkflowExecuteStepError extends AppError {
  constructor(public readonly step: WorkflowStep, public readonly error: any, public readonly silent = false) {
    super(isSet(error) ? String(error) : 'WorkflowExecuteStepError');

    Object.setPrototypeOf(this, WorkflowExecuteStepError.prototype);
  }
}

export enum WorkflowExecuteEventType {
  StepStarted = 'step_started',
  StepFinished = 'step_finished',
  WorkflowStarted = 'workflow_started',
  WorkflowFinished = 'workflow_finished'
}

export interface WorkflowExecuteStepStartedEvent {
  type: WorkflowExecuteEventType.StepStarted;
  step: WorkflowStep;
}

export interface WorkflowExecuteStepFinishedEvent {
  type: WorkflowExecuteEventType.StepFinished;
  step: WorkflowStep;
  success: boolean;
  params?: Object;
  result?: any;
  error?: any;
}

export interface WorkflowExecuteWorkflowStartedEvent {
  type: WorkflowExecuteEventType.WorkflowStarted;
}

export interface WorkflowExecuteWorkflowFinishedEvent {
  type: WorkflowExecuteEventType.WorkflowFinished;
  success: boolean;
  result?: any;
  error?: any;
  run?: WorkflowRun;
}

export type WorkflowExecuteEvent =
  | WorkflowExecuteStepStartedEvent
  | WorkflowExecuteStepFinishedEvent
  | WorkflowExecuteWorkflowStartedEvent
  | WorkflowExecuteWorkflowFinishedEvent;

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as moment from 'moment';
import { localize } from '@common/localize';
import { isSet } from '@shared';
import { BetweenFieldLookup } from './between';
var IsLastQuarterFieldLookup = /** @class */ (function (_super) {
    __extends(IsLastQuarterFieldLookup, _super);
    function IsLastQuarterFieldLookup() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.lookup = 'is_last_quarter';
        _this.param = 'isLastQuarter';
        _this.verboseName = localize('is Last Quarter');
        _this.icon = 'calendar';
        return _this;
    }
    IsLastQuarterFieldLookup.prototype.from = function (value) {
        return moment().subtract(1, 'quarter').toISOString();
    };
    IsLastQuarterFieldLookup.prototype.to = function (value) {
        return moment().toISOString();
    };
    IsLastQuarterFieldLookup.prototype.str = function (field, value, exclude) {
        if (exclude) {
            if (isSet(field) && isSet(value)) {
                return localize('{0} is not last quarter', [field]);
            }
            else {
                return localize('is not last quarter');
            }
        }
        else {
            if (isSet(field) && isSet(value)) {
                return localize('{0} is last quarter', [field]);
            }
            else {
                return localize('last quarter');
            }
        }
    };
    return IsLastQuarterFieldLookup;
}(BetweenFieldLookup));
export { IsLastQuarterFieldLookup };

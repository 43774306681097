import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  OnInit
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Subscription } from 'rxjs';

import { CustomizeService, TabsLayoutItem, ViewContext, ViewContextElement } from '@modules/customize';
import { applyParamInput$ } from '@modules/fields';
import { TypedChanges } from '@shared';

@Component({
  selector: 'app-tabs-layout-navigation-item',
  templateUrl: './tabs-layout-navigation-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TabsLayoutNavigationItemComponent implements OnInit, OnDestroy, OnChanges {
  @Input() tab: TabsLayoutItem;
  @Input() context: ViewContext;
  @Input() contextElement: ViewContextElement;
  @Input() isVisible = false;

  @HostBinding('class.tabs2-navigation__item') cls = true;
  @HostBinding('class.tabs2-navigation__item_hidden') isHidden = false;
  @Input() @HostBinding('class.tabs2-navigation__item_active') active = false;

  title: string;
  titleSubscription: Subscription;

  constructor(public customizeService: CustomizeService, private cd: ChangeDetectorRef) {}

  ngOnInit() {
    this.customizeService.enabled$.pipe(untilDestroyed(this)).subscribe(() => this.updateIsHidden());
  }

  ngOnDestroy(): void {}

  ngOnChanges(changes: TypedChanges<TabsLayoutNavigationItemComponent>): void {
    if (changes.tab || changes.context) {
      this.initTitle();
    }

    if (changes.isVisible) {
      this.updateIsHidden();
    }
  }

  initTitle() {
    if (this.titleSubscription) {
      this.titleSubscription.unsubscribe();
      this.titleSubscription = undefined;
    }

    if (!this.tab.titleInput) {
      this.title = undefined;
      this.cd.markForCheck();
      return;
    }

    this.titleSubscription = applyParamInput$<string>(this.tab.titleInput, {
      context: this.context,
      contextElement: this.contextElement,
      defaultValue: ''
    })
      .pipe(untilDestroyed(this))
      .subscribe(value => {
        this.title = value;
        this.cd.markForCheck();
      });
  }

  updateIsHidden() {
    this.isHidden = !this.isVisible && !this.customizeService.enabled;
    this.cd.markForCheck();
  }
}

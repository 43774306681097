<div
  class="sidebar-list-item sidebar-list-item_clickable"
  [class.sidebar-list-item_border]="border"
  [class.sidebar-list-item_disabled2]="!workflow && emptyDisabled"
  (click)="editClick.emit()"
>
  <div class="sidebar-list-item__left">
    <span class="button button_icon button_orange button_extra-small" style="margin: 0;">
      <span class="button__icon" [ngClass]="'icon-' + icon"></span>
    </span>
  </div>

  <div class="sidebar-list-item__main">
    <div class="sidebar-list-item__title">
      {{ title }}
    </div>
    <div class="sidebar-list-item__description">
      <ng-container *ngIf="workflow">
        {{ workflowStepsCount | i18nPlural: { '=1': '# step', other: '# steps' } }}
      </ng-container>

      <ng-container *ngIf="!workflow">
        {{ emptySubtitle }}
      </ng-container>
    </div>
  </div>

  <div class="sidebar-list-item__right">
    <div *ngIf="!workflow || !stepsInfo.length" class="square-icons">
      <div class="square-icon square-icons__item">
        <div class="square-icon__inner icon-power"></div>
      </div>
    </div>

    <div *ngIf="workflow && stepsInfo.length" class="square-icons">
      <div
        *ngFor="let item of stepsInfo"
        class="square-icon square-icons__item"
        [appTip]="item.labels?.join(' - ')"
        [appTipOptions]="{ side: 'left' }"
      >
        <div
          class="square-icon__inner"
          [class.square-icon__inner_background]="item.icon"
          [ngClass]="item.icon ? 'icon-' + item.icon : null"
          [style.background-image]="item.image ? 'url(' + item.image + ')' : null"
        ></div>
      </div>

      <div *ngIf="stepsMore > 0" class="square-icon square-icons__item">
        <div class="square-icon__inner square-icon__inner_text">+{{ stepsMore }}</div>
      </div>
    </div>

    <a
      *ngIf="workflow && remove"
      href="javascript:void(0)"
      class="sidebar-list-item__action sidebar-list-item__action_button icon-bin"
      style="margin-left: 8px;"
      (click)="$event.stopPropagation(); removeClick.emit()"
    >
    </a>
  </div>
</div>

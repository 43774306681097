import { UserActivity } from './user-activity';

export class MessageUserActivity extends UserActivity {
  public message: string;

  deserialize(data: Object): MessageUserActivity {
    super.deserialize(data);

    if (this.isJSON(this.params)) {
      this.message = JSON.parse(<string>this.params)['message'];
    } else {
      this.message = this.params['message'];
    }

    return this;
  }

  serialize(fields?: string[]): Object {
    this.params = {
      message: this.message
    };
    return super.serialize(fields);
  }

  isJSON(text): boolean {
    if (typeof text !== 'string') {
      return false;
    }
    try {
      JSON.parse(text);
      return true;
    } catch (error) {
      return false;
    }
  }
}

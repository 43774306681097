import isArray from 'lodash/isArray';

import { exactFieldLookup, inFieldLookup, isNullFieldLookup } from '@modules/field-lookups';

import { FieldType } from '../field-type';

export const relatedModelLookupMatchers = [
  {
    field: FieldType.RelatedModel,
    lookup: exactFieldLookup,
    match: (fieldValue: any, lookupValue: any): boolean => {
      return fieldValue == lookupValue;
    }
  },
  {
    field: FieldType.RelatedModel,
    lookup: isNullFieldLookup,
    match: (fieldValue: any, lookupValue: boolean): boolean => {
      return lookupValue ? fieldValue === null : fieldValue !== null;
    }
  },
  {
    field: FieldType.RelatedModel,
    lookup: inFieldLookup,
    match: (fieldValue: any, lookupValue: any[]): boolean => {
      if (
        lookupValue === undefined ||
        (lookupValue as unknown) === '' ||
        (isArray(lookupValue) && !lookupValue.length)
      ) {
        return false;
      }

      if (!isArray(lookupValue)) {
        lookupValue = [lookupValue];
      }

      return lookupValue.some(item => fieldValue == item);
    }
  }
];

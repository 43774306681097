import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import * as moment from 'moment';
import { untilDestroyed } from 'ngx-take-until-destroy';

import { LocalStorage } from '@core';
import { ActionType } from '@modules/actions';
import { CustomSelectItem, CustomSelectItemButton } from '@modules/field-components';
import {
  CurrentEnvironmentStore,
  CurrentProjectStore,
  Resource,
  ResourceName,
  ResourceTypeItem,
  resourceTypeItems
} from '@modules/projects';
import { ConditionWorkflowStepType, WorkflowStepType } from '@modules/workflow';
import { deployUrl, objectsSortPredicate } from '@shared';

export enum AddStepOption {
  CreateResource = 'create_resource',
  AddStep = 'add_step'
}

@Component({
  selector: 'app-add-step-dropdown',
  templateUrl: './add-step-dropdown.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddStepDropdownComponent implements OnInit, OnDestroy {
  @Input() actionTypesEnabled: ActionType[];
  @Output() buttonClick = new EventEmitter<CustomSelectItemButton>();

  items: CustomSelectItem[] = [];

  constructor(
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private localStorage: LocalStorage,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.currentEnvironmentStore.resources$
      .pipe(untilDestroyed(this))
      .subscribe(resourcesAll => this.updateItems(resourcesAll));
  }

  ngOnDestroy(): void {}

  getResourceItems(resourcesAll: Resource[], type: WorkflowStepType, actionType: ActionType): CustomSelectItem[] {
    const resourceTypes = resourceTypeItems.filter(
      item => !item.hidden && !item.protected && this.isResourceEnabled(item)
    );
    const userResourcesKey = `last_used_resources_${this.currentProjectStore.uniqueName}`;
    const localStorageResources = this.localStorage.get(userResourcesKey);
    const lastUsedResources = localStorageResources ? JSON.parse(localStorageResources) : {};
    const defaultPredicate = objectsSortPredicate('demo', 'name');
    const resources = resourcesAll
      ? resourcesAll
          .filter(item => this.isResourceEnabled(item.typeItem, item))
          .sort((lhs, rhs) => {
            const lhsLastUsed = lastUsedResources[lhs.uniqueName]
              ? moment(lastUsedResources[lhs.uniqueName])
              : undefined;
            const rhsLastUsed = lastUsedResources[rhs.uniqueName]
              ? moment(lastUsedResources[rhs.uniqueName])
              : undefined;

            if (!lhsLastUsed && !rhsLastUsed) {
              return defaultPredicate(lhs, rhs);
            } else if (lhsLastUsed && !rhsLastUsed) {
              return -1;
            } else if (!lhsLastUsed && rhsLastUsed) {
              return 1;
            } else if (lhsLastUsed && rhsLastUsed) {
              return (lhsLastUsed.unix() - rhsLastUsed.unix()) * -1;
            }
          })
      : [];
    const userResources = resources.filter(item => !item.demo);
    const demoResources = resources.filter(item => item.demo && item.featured);

    return userResources.length
      ? [
          {
            button: {
              icon: 'plus',
              label: 'Connect Resource'
            },
            children: resourceTypes.map(item => {
              return {
                button: {
                  name: AddStepOption.CreateResource,
                  label: item.label,
                  image: deployUrl(`/assets/images/resources/icons/${item.icon}.svg`),
                  data: { type: type, actionType: actionType, resourceTypeItem: item }
                }
              };
            }),
            stickyTop: true,
            orange: true
          },
          ...userResources.map(item => {
            return {
              // option: {
              //   value: item.uniqueName,
              //   name: item.name,
              //   image: item.icon
              // }
              button: {
                name: AddStepOption.AddStep,
                label: item.name,
                image: item.icon,
                data: { type: type, actionType: actionType, resource: item }
              }
            };
          })
          // {
          //   button: {
          //     icon: 'play_2',
          //     label: 'Demo'
          //   },
          //   children: demoResources.map(item => {
          //     return {
          //       // option: {
          //       //   value: item.uniqueName,
          //       //   name: item.name,
          //       //   image: item.icon
          //       // },
          //       button: {
          //         name: AddStepOption.AddStep,
          //         label: item.name,
          //         image: item.icon,
          //         data: { type: type, actionType: actionType, resource: item }
          //       },
          //       valueTag: 'DEMO'
          //     };
          //   }),
          //   stickyBottom: true,
          //   large: true
          // }
        ]
      : [
          ...resourceTypes.map(item => {
            return {
              button: {
                name: AddStepOption.CreateResource,
                label: item.label,
                image: deployUrl(`/assets/images/resources/icons/${item.icon}.svg`),
                data: { type: type, actionType: actionType, resourceTypeItem: item }
              },
              subtitle: 'Connect Resource'
            };
          })
          // {
          //   button: {
          //     icon: 'play_2',
          //     label: 'Demo'
          //   },
          //   children: demoResources.map(item => {
          //     return {
          //       // option: {
          //       //   value: item.uniqueName,
          //       //   name: item.name,
          //       //   image: item.icon
          //       // },
          //       button: {
          //         name: AddStepOption.AddStep,
          //         label: item.name,
          //         image: item.icon,
          //         data: { type: type, actionType: actionType, resource: item }
          //       },
          //       valueTag: 'DEMO'
          //     };
          //   }),
          //   stickyBottom: true,
          //   large: true
          // }
        ];
  }

  updateItems(resourcesAll: Resource[]) {
    const actionOptions: {
      name?: string;
      label: string;
      icon?: string;
      children?: CustomSelectItem[];
      data?: Object;
    }[] = [
      ...(!this.actionTypesEnabled || this.actionTypesEnabled.includes(ActionType.Query)
        ? [
            {
              label: 'Run Operation',
              icon: 'cloud_upload',
              children: this.getResourceItems(resourcesAll, WorkflowStepType.Action, ActionType.Query)
            }
          ]
        : []),
      {
        label: 'Condition',
        icon: 'workflow',
        children: [
          {
            button: {
              label: 'Yes/No',
              icon: 'switch',
              name: AddStepOption.AddStep,
              data: { type: WorkflowStepType.Condition, conditionType: ConditionWorkflowStepType.Boolean }
            }
          },
          {
            button: {
              label: 'Switch',
              icon: 'versions',
              name: AddStepOption.AddStep,
              data: { type: WorkflowStepType.Condition, conditionType: ConditionWorkflowStepType.Switch }
            }
          }
        ]
      },
      {
        label: 'Parallelize',
        icon: 'matches',
        name: AddStepOption.AddStep,
        data: { type: WorkflowStepType.Fork }
      },
      {
        label: 'Iterator',
        icon: 'repeat',
        name: AddStepOption.AddStep,
        data: { type: WorkflowStepType.Iterator }
      },
      {
        label: 'Transform',
        icon: 'reflect',
        name: AddStepOption.AddStep,
        data: { type: WorkflowStepType.Transform }
      },
      {
        label: 'Delay',
        icon: 'time',
        name: AddStepOption.AddStep,
        data: { type: WorkflowStepType.Delay }
      },
      {
        label: 'Exit',
        icon: 'log_out',
        name: AddStepOption.AddStep,
        data: { type: WorkflowStepType.Exit }
      },
      {
        label: 'Navigate to Page',
        icon: 'redo',
        name: AddStepOption.AddStep,
        data: { type: WorkflowStepType.Action, actionType: ActionType.Link }
      },
      {
        label: 'Open URL',
        icon: 'model_link',
        name: AddStepOption.AddStep,
        data: { type: WorkflowStepType.Action, actionType: ActionType.ExternalLink }
      },
      {
        label: 'Open Overlay',
        icon: 'copy',
        name: AddStepOption.AddStep,
        data: { type: WorkflowStepType.Action, actionType: ActionType.OpenPopup }
      },
      {
        label: 'Close Overlay',
        icon: 'windows',
        name: AddStepOption.AddStep,
        data: { type: WorkflowStepType.Action, actionType: ActionType.ClosePopup }
      },
      {
        label: 'Run Component action',
        icon: 'components',
        name: AddStepOption.AddStep,
        data: { type: WorkflowStepType.Action, actionType: ActionType.ElementAction }
      },
      {
        label: 'Show Notification',
        icon: 'notification',
        name: AddStepOption.AddStep,
        data: { type: WorkflowStepType.Action, actionType: ActionType.ShowNotification }
      },
      {
        label: 'Set Variable',
        icon: 'variable',
        name: AddStepOption.AddStep,
        data: { type: WorkflowStepType.Action, actionType: ActionType.SetProperty }
      },
      {
        label: 'Run JavaScript',
        icon: 'console',
        name: AddStepOption.AddStep,
        data: { type: WorkflowStepType.Action, actionType: ActionType.RunJavaScript }
      },
      {
        label: 'Copy to Clipboard',
        icon: 'documents',
        name: AddStepOption.AddStep,
        data: { type: WorkflowStepType.Action, actionType: ActionType.CopyToClipboard }
      },
      {
        label: 'Export Data',
        icon: 'download',
        name: AddStepOption.AddStep,
        data: { type: WorkflowStepType.Action, actionType: ActionType.Export }
      },
      {
        label: 'Import Data',
        icon: 'upload',
        name: AddStepOption.AddStep,
        data: { type: WorkflowStepType.Action, actionType: ActionType.Import }
      },
      ...(!this.actionTypesEnabled || this.actionTypesEnabled.includes(ActionType.Download)
        ? [
            {
              label: 'Download File',
              icon: 'save',
              children: this.getResourceItems(resourcesAll, WorkflowStepType.Action, ActionType.Download)
            }
          ]
        : []),
      {
        label: 'Scan QR/Bar code',
        icon: 'qr_code',
        name: AddStepOption.AddStep,
        data: { type: WorkflowStepType.Action, actionType: ActionType.ScanCode }
      }
    ];

    this.items = actionOptions
      .filter(item => {
        if (item.name == AddStepOption.AddStep && item.data) {
          const type: WorkflowStepType = item.data['type'];
          const actionType: ActionType = item.data['actionType'];

          if (type == WorkflowStepType.Action) {
            return !this.actionTypesEnabled || this.actionTypesEnabled.includes(actionType);
          }
        }

        return true;
      })
      .map(item => {
        return {
          button: {
            name: item.name,
            label: item.label,
            icon: item.icon,
            data: item.data
          },
          children: item.children
        };
      });

    this.cd.markForCheck();
  }

  isResourceEnabled(typeItem: ResourceTypeItem, resource?: Resource): boolean {
    if (!typeItem) {
      return false;
    }

    if ((resource && (resource.isSynced() || resource.hasCollectionSync())) || (!resource && typeItem.syncEnabled)) {
      typeItem = resourceTypeItems.find(item => item.name == ResourceName.PostgreSQL);
    }

    // Storages are implemented on frontend only
    if (!this.actionTypesEnabled || this.actionTypesEnabled.includes(ActionType.ElementAction)) {
      return typeItem.actionsEnabled || typeItem.storagesEnabled;
    } else {
      return typeItem.actionsEnabled;
    }
  }

  updateLastUsed(resourceName: string) {
    const userResourcesKey = `last_used_resources_${this.currentProjectStore.uniqueName}`;
    const resources = this.localStorage.get(userResourcesKey);

    const lastUsedResources = resources ? JSON.parse(resources) : {};
    lastUsedResources[resourceName] = moment().toISOString();
    this.localStorage.set(userResourcesKey, JSON.stringify(lastUsedResources));

    this.updateItems(this.currentEnvironmentStore.resources);
  }

  onButtonClick(button: CustomSelectItemButton) {
    if (button.name == AddStepOption.AddStep && button.data['resource']) {
      this.updateLastUsed(button.data['resource'].uniqueName);
    }

    this.buttonClick.emit(button);
  }
}

<app-feature-overview *ngIf="!('collaboration' | appFeatureActive | async)" [fill]="true" [feature]="'collaboration'">
  <div data-title>Enhance Teamwork in the Workplace with <strong>Collaboration</strong></div>
  <div data-description>
    <ul>
      <li>Assign tasks and leave notes</li>
      <li>Chat with your teammates directly through Jet</li>
      <li>Make sure everyone on the team is up-to-date</li>
    </ul>
  </div>
</app-feature-overview>

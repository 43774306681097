<app-customize-workflow-step-header
  [step]="step"
  [nameControl]="workflowEditable ? form.controls.name : undefined"
  [nameValue]="form.controls.name.value"
  [deleteEnabled]="workflowEditable"
  (close)="closeCustomize.emit()"
  (delete)="stepDelete.emit()"
></app-customize-workflow-step-header>

<div class="sidebar__content">
  <app-sidebar-section [disabled]="!workflowEditable">
    <div class="sidebar__element">
      <app-sidebar-field [label]="'error text'" [additional]="'use others steps result'">
        <app-input-edit
          [itemForm]="form.controls.error_text"
          [context]="context"
          [contextElement]="contextElement"
          [focusedInitial]="true"
          [placeholder]="'Error text'"
          [fill]="true"
        ></app-input-edit>
      </app-sidebar-field>
    </div>
  </app-sidebar-section>
</div>

import { ChangeDetectorRef, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { MenuBlockLayout } from '@modules/menu';
import { PER_PAGE_PARAM } from '@modules/models';
import { CurrentEnvironmentStore, CurrentProjectStore, ProjectUserService } from '@modules/projects';
import { CustomizeBarPagesEditShareControl } from '../customize-bar-pages-edit/share-control';
var ShareMenuItemEditComponent = /** @class */ (function () {
    function ShareMenuItemEditComponent(currentProjectStore, currentEnvironmentStore, projectUserService, cd) {
        this.currentProjectStore = currentProjectStore;
        this.currentEnvironmentStore = currentEnvironmentStore;
        this.projectUserService = projectUserService;
        this.cd = cd;
        this.deleteRequested = new EventEmitter();
        this.loading = false;
        this.displayUsersCount = 4;
        this.otherUsersCount = 0;
        this.editPopoverOpened = false;
    }
    ShareMenuItemEditComponent.prototype.ngOnInit = function () {
        var _this = this;
        var _a;
        this.loading = true;
        this.cd.markForCheck();
        this.projectUserService
            .getPaginate(this.currentProjectStore.instance.uniqueName, this.currentEnvironmentStore.instance.uniqueName, (_a = {},
            _a[PER_PAGE_PARAM] = this.displayUsersCount,
            _a))
            .pipe(untilDestroyed(this))
            .subscribe(function (result) {
            _this.displayUsers = result.results
                .filter(function (user) { return user.user; })
                .map(function (user) { return user.user; })
                .slice(0, _this.displayUsersCount + 1)
                .reverse();
            _this.otherUsersCount = result.count - _this.displayUsers.length;
            _this.loading = false;
            _this.cd.markForCheck();
        }, function () {
            _this.loading = false;
            _this.cd.markForCheck();
        });
        if (this.createdOptions && this.createdOptions.customize) {
            this.openEditPopover(true);
        }
    };
    ShareMenuItemEditComponent.prototype.ngOnDestroy = function () { };
    ShareMenuItemEditComponent.prototype.openEditPopover = function (value) {
        this.editPopoverOpened = value;
        this.cd.markForCheck();
    };
    return ShareMenuItemEditComponent;
}());
export { ShareMenuItemEditComponent };

import isArray from 'lodash/isArray';

import { forceString, isSet } from '@shared';

import { WorkflowStep } from '../data/steps/base';
import { ConditionWorkflowStep } from '../data/steps/condition';
import { ForkWorkflowStep } from '../data/steps/fork';
import { WorkflowStepType } from '../data/workflow-step-type';

export function traverseWorkflowSteps(item: any, process: (item: any) => boolean | void) {
  if (process(item) === false) {
    return;
  }

  if (isArray(item)) {
    item.forEach(child => {
      traverseWorkflowSteps(child, process);
    });
  } else if (item instanceof ConditionWorkflowStep) {
    item.items.forEach(conditionItem => traverseWorkflowSteps(conditionItem.steps, process));
  } else if (item instanceof ForkWorkflowStep) {
    item.items.forEach(conditionItem => traverseWorkflowSteps(conditionItem.steps, process));
  } else if (item instanceof WorkflowStep && item.type == WorkflowStepType.Iterator) {
    // TODO: Workaround before refactor imports
    traverseWorkflowSteps(item['steps'], process);
  }
}

export function processWorkflowStepNames(root: any, process: (name: string, item: WorkflowStep) => string) {
  traverseWorkflowSteps(root, item => {
    if (item instanceof WorkflowStep) {
      item.name = process(item.name, item);
    }
  });
}

export function generateWorkflowStepName(
  step: WorkflowStep,
  names: { [name: string]: any },
  name?: string,
  caseSensitive = true,
  numberSeparator = ' '
): string {
  let defaultName = step.defaultName();
  let i = 1;
  let newName: string;

  if (isSet(name)) {
    defaultName = name;
  }

  do {
    newName = i > 1 ? [defaultName, i].join(numberSeparator) : defaultName;
    ++i;
  } while (names.hasOwnProperty(caseSensitive ? newName : newName.toLowerCase()));

  return newName;
}

export function getWorkflowStepNames(
  steps: WorkflowStep[],
  caseSensitive = true,
  filter?: (item: WorkflowStep) => boolean
): { [name: string]: WorkflowStep } {
  const names: { [name: string]: WorkflowStep } = {};

  processWorkflowStepNames(steps, (name, step) => {
    if (filter && !filter(step)) {
      return name;
    }

    name = forceString(name);
    if (isSet(name) && !names.hasOwnProperty(name)) {
      const prop = caseSensitive ? name : name.toLowerCase();
      names[prop] = step;
    }

    return name;
  });

  return names;
}

export function cleanWorkflowStepName(name: string, step: WorkflowStep, steps: WorkflowStep[]) {
  const names = getWorkflowStepNames(steps, false, item => item.uid != step.uid);
  return generateWorkflowStepName(step, names, name, false, ' ');
}

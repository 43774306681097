<div class="popup-collapse" [class.popup-collapse_opened]="opened">
  <div class="popup-collapse__header" [class.popup-collapse__header_border]="border" (click)="toggleOpened()">
    <div class="popup-collapse__header-main">
      {{ title }}
    </div>
    <div class="popup-collapse__header-right">
      <span class="popup-collapse__arrow icon-arrow_down_2"></span>
    </div>
  </div>
  <div class="popup-collapse__content">
    <ng-content></ng-content>
  </div>
</div>

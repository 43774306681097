var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { AfterViewInit, OnChanges, SimpleChanges } from '@angular/core';
import cloneDeep from 'lodash/cloneDeep';
import { ThemeService } from '@modules/theme';
import { BaseChartComponent } from '../base-chart/base-chart.component';
var RadarChartComponent = /** @class */ (function (_super) {
    __extends(RadarChartComponent, _super);
    function RadarChartComponent(themeService) {
        var _this = _super.call(this, themeService) || this;
        _this.themeService = themeService;
        _this.animate = true;
        _this.legend = true;
        return _this;
    }
    RadarChartComponent.prototype.ngOnChanges = function (changes) {
        _super.prototype.ngOnChanges.call(this, changes);
        if (['legend'].some(function (item) { return changes.hasOwnProperty(item); })) {
            this.initChart();
        }
    };
    RadarChartComponent.prototype.chartOptions = function () {
        return {
            type: 'radar'
        };
    };
    RadarChartComponent.prototype.lineChartData = function () {
        var _this = this;
        if (!this.datasetsCurrent) {
            return [];
        }
        return this.datasetsCurrent.map(function (dataset) {
            var instance = cloneDeep(dataset.dataset);
            return {
                data: instance.map(function (item) { return item.value; }),
                label: dataset.name,
                // backgroundColor: !dataset.color
                //   ? this.segmentColors(false, dataset.dataset.length).map(item => item[1])
                //   : this.borderColor(dataset.color),
                // backgroundColor: this.borderColor(dataset.color),
                backgroundColor: _this.gradientFill(dataset.color),
                borderColor: _this.borderColor(dataset.color),
                pointBackgroundColor: _this.borderColor(dataset.color),
                pointBorderColor: _this.themeService.theme == 'dark' ? '#2C343A' : '#fff',
                // backgroundColor: this.segmentColors().map((item, i) => {
                //   if (instance[i] && instance[i].color) {
                //     return instance[i].color;
                //   }
                //   return item[1];
                // }),
                borderWidth: 2
                // borderColor: this.themeService.theme == 'dark' ? '#2C343A' : '#fff',
                // // hoverBackgroundColor: colors,
                // // hoverBorderWidth: 2,
                // hoverBorderColor: this.themeService.theme == 'dark' ? '#2C343A' : '#fff'
            };
        });
    };
    RadarChartComponent.prototype.lineChartOptions = function () {
        var _this = this;
        var parentWidth = this.chartElement.nativeElement.parentNode.offsetWidth;
        return {
            responsive: true,
            maintainAspectRatio: false,
            animation: this.animate
                ? {
                    duration: 1000,
                    easing: 'easeInOutBack'
                }
                : false,
            legend: this.legend
                ? {
                    position: parentWidth == 0 || parentWidth >= 300 ? 'right' : 'bottom',
                    labels: {
                        fontColor: '#7D91A5',
                        fontSize: 10,
                        fontFamily: "'Fira Sans', sans-serif",
                        usePointStyle: true,
                        padding: 8,
                        filter: function (item, chart) {
                            return item['index'] < 8;
                        }
                    }
                }
                : false,
            tooltips: {
                callbacks: {
                    label: function (tooltipItem, data) { return _this.tooltipLabel(tooltipItem, data, 1); }
                }
            }
        };
    };
    RadarChartComponent.prototype.gradientFill = function (color) {
        if (!this.ctx) {
            return;
        }
        var gradientFill = this.ctx.createLinearGradient(0, 0, 0, this.ctx.canvas.parentNode.offsetHeight);
        gradientFill.addColorStop(0, "rgba(" + this.gradientColor(color) + ",0.15");
        gradientFill.addColorStop(1, "rgba(" + this.gradientColor(color) + ",0.15)");
        return gradientFill;
    };
    RadarChartComponent.prototype.updateGradient = function () {
        var _this = this;
        this.chart.data.datasets.forEach(function (item, i) { return (item.backgroundColor = _this.gradientFill(_this.datasetsCurrent[i].color)); });
        this.chart.update();
    };
    RadarChartComponent.prototype.onResize = function () {
        this.updateGradient();
    };
    RadarChartComponent.prototype.onThemeChange = function () {
        this.updateGradient();
    };
    return RadarChartComponent;
}(BaseChartComponent));
export { RadarChartComponent };

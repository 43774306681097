/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../image-field/preview-tip/preview-tip.component.ngfactory";
import * as i2 from "../../image-field/preview-tip/preview-tip.component";
import * as i3 from "@angular/cdk/overlay";
import * as i4 from "@angular/cdk/bidi";
import * as i5 from "@angular/common";
import * as i6 from "./image-preview.component";
import * as i7 from "../../../../../common/popups/services/popup/popup.service";
import * as i8 from "@angular/platform-browser";
import * as i9 from "../../../../project-api/services/project-api/project-api.service";
var styles_ImagePreviewComponent = [];
var RenderType_ImagePreviewComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ImagePreviewComponent, data: {} });
export { RenderType_ImagePreviewComponent as RenderType_ImagePreviewComponent };
function View_ImagePreviewComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-preview-tip", [], null, null, null, i1.View_PreviewTipComponent_0, i1.RenderType_PreviewTipComponent)), i0.ɵdid(1, 114688, null, 0, i2.PreviewTipComponent, [], { url: [0, "url"], width: [1, "width"], height: [2, "height"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.value; var currVal_1 = _co.imageWidth; var currVal_2 = _co.imageHeight; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_ImagePreviewComponent_0(_l) { return i0.ɵvid(2, [i0.ɵqud(402653184, 1, { previewImg: 0 }), i0.ɵqud(402653184, 2, { cdkConnectedOverlay: 0 }), (_l()(), i0.ɵand(16777216, null, null, 3, null, View_ImagePreviewComponent_1)), i0.ɵdid(3, 671744, [[2, 4]], 0, i3.CdkConnectedOverlay, [i3.Overlay, i0.TemplateRef, i0.ViewContainerRef, i3.ɵc, [2, i4.Directionality]], { origin: [0, "origin"], positions: [1, "positions"], panelClass: [2, "panelClass"], open: [3, "open"], hasBackdrop: [4, "hasBackdrop"], growAfterOpen: [5, "growAfterOpen"], push: [6, "push"] }, null), i0.ɵpad(4, 1), i0.ɵpid(131072, i5.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(6, 0, [[1, 0], ["preview_img", 1]], null, 4, "span", [["cdkOverlayOrigin", ""], ["class", "field__preview field__preview_image field__preview_fill"]], [[2, "field__preview_cover", null], [2, "field__preview_contain", null], [2, "field__preview_zoom", null], [2, "field__preview_no-background", null], [2, "field__preview_background", null], [2, "field__preview_compact", null], [4, "background-image", null], [4, "opacity", null]], [[null, "mouseenter"], [null, "mouseleave"], [null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mouseenter" === en)) {
        var pd_0 = (_co.showPreview() !== false);
        ad = (pd_0 && ad);
    } if (("mouseleave" === en)) {
        var pd_1 = (_co.hidePreview() !== false);
        ad = (pd_1 && ad);
    } if (("click" === en)) {
        var pd_2 = (_co.openLightbox($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i0.ɵdid(7, 278528, null, 0, i5.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpad(8, 1), i0.ɵdid(9, 16384, [["trigger", 4]], 0, i3.CdkOverlayOrigin, [i0.ElementRef], null, null), i0.ɵpid(131072, i5.AsyncPipe, [i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵnov(_v, 9); var currVal_1 = _co.popoverPositions; var currVal_2 = _ck(_v, 4, 0, "overlay"); var currVal_3 = i0.ɵunv(_v, 3, 3, i0.ɵnov(_v, 5).transform(_co.previewVisible$)); var currVal_4 = false; var currVal_5 = true; var currVal_6 = true; _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_15 = "field__preview field__preview_image field__preview_fill"; var currVal_16 = _ck(_v, 8, 0, ("field__preview_type_" + _co.type)); _ck(_v, 7, 0, currVal_15, currVal_16); }, function (_ck, _v) { var _co = _v.component; var currVal_7 = (_co.previewBackgroundImage && (_co.fit != _co.imageFits.Fit)); var currVal_8 = (_co.previewBackgroundImage && (_co.fit == _co.imageFits.Fit)); var currVal_9 = (_co.url && _co.lightBox); var currVal_10 = _co.noBackground; var currVal_11 = _co.background; var currVal_12 = _co.compact; var currVal_13 = _co.previewBackgroundImage; var currVal_14 = (i0.ɵunv(_v, 6, 7, i0.ɵnov(_v, 10).transform(_co.previewVisible$)) ? 0.25 : 1); _ck(_v, 6, 0, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13, currVal_14); }); }
export function View_ImagePreviewComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-image-preview", [], null, null, null, View_ImagePreviewComponent_0, RenderType_ImagePreviewComponent)), i0.ɵdid(1, 4964352, null, 0, i6.ImagePreviewComponent, [i7.PopupService, i8.DomSanitizer, i9.ProjectApiService, i0.ChangeDetectorRef, i0.Injector], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ImagePreviewComponentNgFactory = i0.ɵccf("app-image-preview", i6.ImagePreviewComponent, View_ImagePreviewComponent_Host_0, { value: "value", url: "url", storageResource: "storageResource", noBackground: "noBackground", background: "background", fit: "fit", type: "type", resize: "resize", lightBox: "lightBox", preview: "preview", compact: "compact" }, {}, []);
export { ImagePreviewComponentNgFactory as ImagePreviewComponentNgFactory };

<div
  class="element-wrapper__inner"
  [class.element-align]="alignHorizontal || alignVertical"
  [class.element-align_horizontal_left]="alignHorizontal == alignsHorizontal.Left"
  [class.element-align_horizontal_center]="alignHorizontal == alignsHorizontal.Center"
  [class.element-align_horizontal_right]="alignHorizontal == alignsHorizontal.Right"
  [class.element-align_vertical_top]="alignVertical == alignsVertical.Top"
  [class.element-align_vertical_center]="alignVertical == alignsVertical.Center"
  [class.element-align_vertical_bottom]="alignVertical == alignsVertical.Bottom"
>
  <ng-content></ng-content>
</div>

import { ChangeDetectorRef, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { copyTextToClipboard } from '@common/code';
import { DialogService } from '@common/dialogs';
import { localize } from '@common/localize';
import { NotificationService } from '@common/notifications';
import { AppConfigService } from '@core';
import { AdminMode } from '@modules/admin-mode';
import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { CustomizeService, CustomViewSettings } from '@modules/customize';
import { CurrentEnvironmentStore, CurrentProjectStore, PermissionsGroup, ProjectGroupStore, ProjectPublicAccess, ProjectPublicAccessService } from '@modules/projects';
import { capitalize, isSet } from '@shared';
var SharePublicComponent = /** @class */ (function () {
    function SharePublicComponent(mode, currentProjectStore, currentEnvironmentStore, activatedRoute, projectPublicAccessService, projectGroupStore, dialogService, customizeService, notificationService, appConfigService, analyticsService, cd) {
        this.mode = mode;
        this.currentProjectStore = currentProjectStore;
        this.currentEnvironmentStore = currentEnvironmentStore;
        this.activatedRoute = activatedRoute;
        this.projectPublicAccessService = projectPublicAccessService;
        this.projectGroupStore = projectGroupStore;
        this.dialogService = dialogService;
        this.customizeService = customizeService;
        this.notificationService = notificationService;
        this.appConfigService = appConfigService;
        this.analyticsService = analyticsService;
        this.cd = cd;
        this.hasDraftChanges = false;
        this.close = new EventEmitter();
        this.selectedGroup = new FormControl();
        this.loading = false;
        this.submitLoading = false;
        this.localize = localize;
    }
    SharePublicComponent.prototype.ngOnInit = function () {
        this.initCurrentPage();
    };
    SharePublicComponent.prototype.ngOnDestroy = function () { };
    SharePublicComponent.prototype.initCurrentPage = function () {
        var _this = this;
        this.customizeService.handlerInfo$.pipe(untilDestroyed(this)).subscribe(function (handlerInfo) {
            if (handlerInfo && handlerInfo.page && handlerInfo.page instanceof CustomViewSettings) {
                var project = _this.currentProjectStore.instance;
                var environment = _this.currentEnvironmentStore.instance;
                var webBaseUrl = project.domain ? "https://" + project.domain.actualDomain : _this.appConfigService.webBaseUrl;
                var url = [
                    webBaseUrl,
                    AdminMode.App,
                    project.uniqueName,
                    environment.uniqueName
                ].concat(handlerInfo.page.link).join('/');
                var queryParams = _this.activatedRoute.snapshot.queryParams;
                var queryParamsStr = new URLSearchParams(queryParams).toString();
                _this.currentPage = handlerInfo.page;
                _this.currentPageParams = queryParams;
                _this.currentPageUrl = [url].concat((isSet(queryParamsStr) ? [queryParamsStr] : [])).join('?');
                _this.cd.markForCheck();
            }
            else {
                _this.currentPage = undefined;
                _this.currentPageParams = undefined;
                _this.currentPageUrl = undefined;
                _this.cd.markForCheck();
            }
            _this.loadPublicAccess();
        });
    };
    SharePublicComponent.prototype.loadPublicAccess = function () {
        var _this = this;
        this.loading = true;
        this.cd.markForCheck();
        this.projectPublicAccessService
            .get(this.currentProjectStore.instance.uniqueName, this.currentEnvironmentStore.instance.uniqueName)
            .pipe(untilDestroyed(this))
            .subscribe(function (result) {
            var pageUid = _this.currentPage ? _this.currentPage.uid : undefined;
            var instance = result.find(function (item) {
                return item.pageUid === pageUid && isEqual(item.pageParams, _this.currentPageParams);
            });
            _this.init(instance);
            _this.loading = false;
            _this.cd.markForCheck();
        }, function () {
            _this.loading = false;
            _this.cd.markForCheck();
        });
    };
    SharePublicComponent.prototype.init = function (publicAccess) {
        var _this = this;
        this.setPublicAccess(publicAccess);
        this.projectGroupStore
            .getFirst()
            .pipe(untilDestroyed(this))
            .subscribe(function (groups) {
            var group = (groups || []).find(function (item) {
                if (publicAccess && publicAccess.group) {
                    return item.uid == publicAccess.group.uid;
                }
                else {
                    return item.permissionsGroup == PermissionsGroup.Editor;
                }
            });
            if (group) {
                _this.selectedGroup.patchValue(group);
            }
            _this.selectedGroup.valueChanges.pipe(untilDestroyed(_this)).subscribe(function () {
                if (_this.publicAccess) {
                    _this.updatePublicAccess();
                }
            });
        });
    };
    SharePublicComponent.prototype.setPublicAccess = function (value) {
        this.publicAccess = value;
        this.publicAccessLink = this.getLink();
        this.publicAccessIFrame = this.getIFrame();
        this.cd.markForCheck();
    };
    SharePublicComponent.prototype.getLink = function () {
        var project = this.currentProjectStore.instance;
        var webBaseUrl = project.domain ? "https://" + project.domain.actualDomain : this.appConfigService.webBaseUrl;
        return this.publicAccess ? this.publicAccess.link(webBaseUrl) : undefined;
    };
    SharePublicComponent.prototype.getIFrame = function () {
        var link = this.getLink();
        return link
            ? "<iframe src=\"" + link + "?iframe=1\" width=\"100%\" height=\"640\" frameborder=\"0\" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>"
            : undefined;
    };
    SharePublicComponent.prototype.copy = function (text, contentLabel) {
        var _this = this;
        copyTextToClipboard(text)
            .pipe(untilDestroyed(this))
            .subscribe(function (success) {
            if (!success) {
                return;
            }
            var description = isSet(contentLabel)
                ? localize('{0} was copied to clipboard', [capitalize(contentLabel)])
                : undefined;
            _this.notificationService.info(localize('Copied'), description);
        });
    };
    SharePublicComponent.prototype.createPublicAccess = function () {
        var _this = this;
        if (!this.selectedGroup.value) {
            return;
        }
        this.submitLoading = true;
        this.cd.markForCheck();
        var instance = new ProjectPublicAccess();
        instance.group = this.selectedGroup.value;
        instance.pageUid = this.currentPage ? this.currentPage.uid : undefined;
        instance.pageParams = this.currentPageParams;
        this.projectPublicAccessService
            .create(this.currentProjectStore.instance.uniqueName, this.currentEnvironmentStore.instance.uniqueName, instance)
            .pipe(untilDestroyed(this))
            .subscribe(function (result) {
            _this.setPublicAccess(result);
            _this.submitLoading = false;
            _this.cd.markForCheck();
            _this.analyticsService.sendSimpleEvent(AnalyticsEvent.Share.PublicAccessCreated, {
                Code: result.code,
                Source: 'share_popup'
            });
        }, function () {
            _this.submitLoading = false;
            _this.cd.markForCheck();
        });
    };
    SharePublicComponent.prototype.updatePublicAccess = function () {
        var _this = this;
        if (!this.selectedGroup.value || !this.publicAccess) {
            return;
        }
        this.submitLoading = true;
        this.cd.markForCheck();
        var instance = cloneDeep(this.publicAccess);
        instance.group = this.selectedGroup.value;
        this.projectPublicAccessService
            .update(this.currentProjectStore.instance.uniqueName, this.currentEnvironmentStore.instance.uniqueName, instance)
            .pipe(untilDestroyed(this))
            .subscribe(function (result) {
            _this.setPublicAccess(result);
            _this.submitLoading = false;
            _this.cd.markForCheck();
            _this.analyticsService.sendSimpleEvent(AnalyticsEvent.Share.PublicAccessUpdated, {
                Code: result.code,
                Source: 'share_popup'
            });
        }, function () {
            _this.submitLoading = false;
            _this.cd.markForCheck();
        });
    };
    SharePublicComponent.prototype.deletePublicAccess = function () {
        var _this = this;
        var instance = this.publicAccess;
        this.submitLoading = true;
        this.cd.markForCheck();
        this.projectPublicAccessService
            .delete(this.currentProjectStore.instance.uniqueName, this.currentEnvironmentStore.instance.uniqueName, instance)
            .pipe(untilDestroyed(this))
            .subscribe(function () {
            _this.setPublicAccess(undefined);
            // this.notificationService.success('Deleted', 'Public access was successfully deleted from App');
            _this.submitLoading = false;
            _this.cd.markForCheck();
            _this.analyticsService.sendSimpleEvent(AnalyticsEvent.Share.PublicAccessDeleted, {
                Code: instance.code,
                Mode: _this.mode,
                Source: 'share_popup'
            });
        }, function () {
            _this.submitLoading = false;
            _this.cd.markForCheck();
        });
    };
    SharePublicComponent.prototype.togglePublicAccess = function () {
        if (this.submitLoading) {
            return;
        }
        if (this.publicAccess) {
            this.deletePublicAccess();
        }
        else {
            this.createPublicAccess();
        }
    };
    return SharePublicComponent;
}());
export { SharePublicComponent };

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "../../../features/components/feature-overview/feature-overview.component.ngfactory";
import * as i3 from "../../../features/components/feature-overview/feature-overview.component";
import * as i4 from "../../../projects/stores/current-project.store";
import * as i5 from "@angular/common";
import * as i6 from "./collaboration.component";
import * as i7 from "../../../customize/services/customize/customize.service";
import * as i8 from "../../../menu/services/menu/menu.service";
import * as i9 from "../../../collaboration/services/task-queue/task-queue.service";
import * as i10 from "../../../collaboration/stores/task-queue.store";
var styles_CollaborationComponent = [];
var RenderType_CollaborationComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CollaborationComponent, data: {} });
export { RenderType_CollaborationComponent as RenderType_CollaborationComponent };
function View_CollaborationComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_CollaborationComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "collaboration__content"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "div", [["class", "collaboration__right"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 2, "div", [["class", "collaboration__right-content"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i0.ɵdid(4, 212992, null, 0, i1.RouterOutlet, [i1.ChildrenOutletContexts, i0.ViewContainerRef, i0.ComponentFactoryResolver, [8, null], i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 4, 0); }, null); }
function View_CollaborationComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 9, "app-feature-overview", [], null, null, null, i2.View_FeatureOverviewComponent_0, i2.RenderType_FeatureOverviewComponent)), i0.ɵdid(1, 245760, null, 0, i3.FeatureOverviewComponent, [[2, i4.CurrentProjectStore]], null, null), (_l()(), i0.ɵeld(2, 0, null, 0, 1, "div", [["data-subtitle", ""]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Paid Feature"])), (_l()(), i0.ɵeld(4, 0, null, 1, 3, "div", [["data-title", ""]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Collaborate on a project with "])), (_l()(), i0.ɵeld(6, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Collaboration"])), (_l()(), i0.ɵeld(8, 0, null, 2, 1, "div", [["data-description", ""]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Assign tasks, leave notes, and write comments on a specific record page. Chat with your teammates directly through Jet and make sure everyone is up-to-date. "]))], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_CollaborationComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "section", [["class", "collaboration"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_CollaborationComponent_1)), i0.ɵdid(2, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"], ngIfThen: [1, "ngIfThen"], ngIfElse: [2, "ngIfElse"] }, null), i0.ɵpid(131072, i5.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵand(0, [["content", 2]], null, 0, null, View_CollaborationComponent_2)), (_l()(), i0.ɵand(0, [["not_enabled", 2]], null, 0, null, View_CollaborationComponent_3))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 2, 0, i0.ɵnov(_v, 3).transform(_co.currentProjectStore.instance$)).features.isTasksEnabled(); var currVal_1 = i0.ɵnov(_v, 4); var currVal_2 = i0.ɵnov(_v, 5); _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_CollaborationComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-collaboration", [], null, null, null, View_CollaborationComponent_0, RenderType_CollaborationComponent)), i0.ɵdid(1, 4440064, null, 0, i6.CollaborationComponent, [i7.CustomizeService, i4.CurrentProjectStore, i1.Router, i8.MenuService, i9.TaskQueueService, i10.TaskQueueStore, i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CollaborationComponentNgFactory = i0.ɵccf("app-collaboration", i6.CollaborationComponent, View_CollaborationComponent_Host_0, {}, {}, []);
export { CollaborationComponentNgFactory as CollaborationComponentNgFactory };

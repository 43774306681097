import { ChangeDetectorRef, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { ViewContext, ViewContextElement } from '@modules/customize';
import { DataSourceType } from '@modules/data-sources';
import { HttpResultsSection, SqlResultsSection } from '@modules/queries-components';
import { controlValue, isSet } from '@shared';
import { ModelDescriptionDataSourceEditComponent } from '../model-description-data-source-edit/model-description-data-source-edit.component';
import { ValueWidgetDataSourceControl } from '../model-description-data-source-edit/value-widget-data-source';
var ValueWidgetDataSourceEditComponent = /** @class */ (function () {
    function ValueWidgetDataSourceEditComponent(cd) {
        this.cd = cd;
        this.typeOrange = false;
        this.search = false;
        this.dataSourceTypes = DataSourceType;
    }
    ValueWidgetDataSourceEditComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.columnOptions$ = this.control.getColumnOptions$();
        // TODO: Temp fix
        this.columnOptions$.pipe(untilDestroyed(this)).subscribe(function () { return _this.cd.detectChanges(); });
        this.funcLabel$ = this.getFuncLabel$();
    };
    ValueWidgetDataSourceEditComponent.prototype.ngOnDestroy = function () { };
    ValueWidgetDataSourceEditComponent.prototype.getFuncLabel$ = function () {
        var _this = this;
        return combineLatest(controlValue(this.control.controls.func), controlValue(this.control.controls.column)).pipe(map(function (_a) {
            var func = _a[0], column = _a[1];
            var yFuncOption = _this.control.yColumnFuncOptions.find(function (item) { return item.value == func; });
            if (!isSet(func) && isSet(column)) {
                return "Pre-aggregated Field - " + column;
            }
            else if (yFuncOption) {
                return yFuncOption.valueDisplay(column);
            }
        }));
    };
    ValueWidgetDataSourceEditComponent.prototype.addQueryInput = function () {
        if (this.dataSourceEditComponent) {
            this.dataSourceEditComponent.editQuery({
                initialHttpResultsSection: HttpResultsSection.Parameters,
                initialSqlResultsSection: SqlResultsSection.Parameters
            });
        }
    };
    return ValueWidgetDataSourceEditComponent;
}());
export { ValueWidgetDataSourceEditComponent };

import { ResourceName } from '../data/resource-name';
import { ResourceType } from '../data/resource-type';
import { ResourceTypeItem } from '../data/resource-type-item';
import { ResourceTypeItemCategory } from '../data/resource-type-item-category';

export function isResourceTypeItemCompatible(lhs: ResourceTypeItem, rhs: ResourceTypeItem) {
  if (!lhs || !rhs) {
    return false;
  }

  if (lhs.name == ResourceName.JetDatabase || rhs.name == ResourceName.JetDatabase) {
    return lhs.name == ResourceName.JetDatabase && rhs.name == ResourceName.JetDatabase;
  } else if (lhs.resourceType == ResourceType.JetBridge && rhs.resourceType == ResourceType.JetBridge) {
    return true;
  } else {
    return lhs.name == rhs.name;
  }
}

export function isResourceTypeItemCustom(typeItem: ResourceTypeItem) {
  return [
    ResourceName.RestApi,
    ResourceName.GraphQL,
    ResourceName.Zapier,
    ResourceName.Integromat,
    ResourceName.Firebase
  ].includes(typeItem.name);
}

export function getResourceTypeItemRequestName(typeItem: ResourceTypeItem) {
  if (typeItem.requestName) {
    return typeItem.requestName;
  } else if (typeItem.resourceType == ResourceType.RestAPI) {
    return 'HTTP request';
  } else if (typeItem.resourceType == ResourceType.GraphQL) {
    return 'GraphQL request';
  } else if (typeItem.resourceType == ResourceType.JetBridge) {
    return 'SQL query';
  } else if (typeItem.resourceType == ResourceType.Firebase) {
    return 'Database query';
  } else {
    return 'request';
  }
}

export function isResourceTypeDatabase(typeItem: ResourceTypeItem) {
  return typeItem.categories.includes(ResourceTypeItemCategory.Databases);
}

export function isResourceTypeItem3rdParty(typeItem: ResourceTypeItem) {
  return [
    ResourceName.Stripe,
    ResourceName.Twilio,
    ResourceName.SendGrid,
    ResourceName.Zendesk,
    ResourceName.HubSpot,
    ResourceName.Pipedrive,
    ResourceName.Slack,
    ResourceName.Mixpanel,
    ResourceName.Amplitude,
    ResourceName.Intercom,
    ResourceName.Mailchimp,
    ResourceName.Mailgun,
    ResourceName.AmazonS3,
    ResourceName.DigitalOceanSpaces,
    ResourceName.S3Compatible,
    ResourceName.GoogleCloudStorage,
    ResourceName.SupabaseStorage,
    ResourceName.AzureBlob
  ].includes(typeItem.name);
}

export function isResourceTypeItemReplicable(typeItem: ResourceTypeItem) {
  return typeItem.name == ResourceName.JetDatabase;
}

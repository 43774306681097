export const slackResourceParamsParams: Object = {
  base_http_query: {
    method: 'GET',
    body_type: 'JSON',
    body: '',
    headers: [],
    query_params: [],
    response_type: 'json',
    request_tokens: {}
  }
};

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  InjectionToken,
  Input,
  OnDestroy,
  OnInit
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Observable } from 'rxjs';
import { debounceTime, filter } from 'rxjs/operators';

import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { TaskQueue, TaskQueueStore, TaskStatusSelectSource } from '@modules/collaboration';
import { TaskQueueEditController } from '@modules/collaboration-components';
import { ViewContext, ViewContextElement } from '@modules/customize';
import { createFormFieldFactory, FieldType } from '@modules/fields';
import { ProjectUserSelectSource } from '@modules/projects';
import { controlValid, controlValue } from '@shared';

import { CustomizeBarApproveEditForm } from './customize-bar-approve-edit.form';

export const createStatusSelectSourceToken = new InjectionToken<TaskStatusSelectSource>(
  'createStatusSelectSourceToken'
);
export const approveStatusSelectSourceToken = new InjectionToken<TaskStatusSelectSource>(
  'approveStatusSelectSourceToken'
);
export const rejectStatusSelectSourceToken = new InjectionToken<TaskStatusSelectSource>(
  'rejectStatusSelectSourceToken'
);

@Component({
  selector: 'app-customize-bar-approve-edit',
  templateUrl: './customize-bar-approve-edit.component.html',
  providers: [
    CustomizeBarApproveEditForm,
    ProjectUserSelectSource,
    { provide: createStatusSelectSourceToken, useClass: TaskStatusSelectSource },
    { provide: approveStatusSelectSourceToken, useClass: TaskStatusSelectSource },
    { provide: rejectStatusSelectSourceToken, useClass: TaskStatusSelectSource }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomizeBarApproveEditComponent implements OnInit, OnDestroy {
  @Input() control: FormControl;
  @Input() context: ViewContext;
  @Input() contextElement: ViewContextElement;
  @Input() contextElementPath: (string | number)[];
  @Input() contextElementPaths: (string | number)[][];
  @Input() firstInit = false;
  @Input() source: string;

  loading = false;
  queues$: Observable<TaskQueue[]>;
  selectedQueue: TaskQueue;
  fieldTypes = FieldType;
  createField = createFormFieldFactory();
  analyticsSource = 'component_action_approve';

  constructor(
    private taskQueueEditController: TaskQueueEditController,
    @Inject(createStatusSelectSourceToken) public createStatusSelectSource: TaskStatusSelectSource,
    @Inject(approveStatusSelectSourceToken) public approveStatusSelectSource: TaskStatusSelectSource,
    @Inject(rejectStatusSelectSourceToken) public rejectStatusSelectSource: TaskStatusSelectSource,
    public projectUserSelectSource: ProjectUserSelectSource,
    public form: CustomizeBarApproveEditForm,
    private taskQueueStore: TaskQueueStore,
    private analyticsService: UniversalAnalyticsService,
    protected cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.loading = true;
    this.cd.markForCheck();

    this.queues$ = this.taskQueueStore.get();
    this.form
      .init(this.control, this.context, this.contextElement, this.firstInit)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.form.form.valueChanges.pipe(debounceTime(60), untilDestroyed(this)).subscribe(() => {
          const instance = this.form.submit();
          this.control.patchValue(instance);
          this.control.markAsDirty();
        });

        // TODO: Move to mat-select
        controlValue(this.form.form.controls['task_queue'])
          .pipe(untilDestroyed(this))
          .subscribe(value => {
            this.selectedQueue = value;
            this.cd.markForCheck();

            [this.createStatusSelectSource, this.approveStatusSelectSource, this.rejectStatusSelectSource].forEach(
              source => {
                source.queue = value ? value.uid : undefined;
                source.reset();
              }
            );
          });

        this.approveStatusSelectSource.allowEmpty = true;
        this.rejectStatusSelectSource.allowEmpty = true;
        this.projectUserSelectSource.allowEmpty = true;
        this.projectUserSelectSource.emptyName = 'Unassigned';
        this.projectUserSelectSource.userValue = true;

        this.loading = false;
        this.cd.markForCheck();
      });

    controlValid(this.form.form)
      .pipe(
        filter((configured, i) => configured && i > 0),
        untilDestroyed(this)
      )
      .subscribe(() => {
        this.analyticsService.sendSimpleEvent(AnalyticsEvent.Component.ApprovalSuccessfullySetUp, {
          ComponentTypeID: this.source
        });
      });

    this.analyticsService.sendSimpleEvent(AnalyticsEvent.Component.ApprovalStartedToSetUp, {
      ComponentTypeID: this.source
    });
  }

  ngOnDestroy(): void {}

  openQueueEditPopup(queue?: TaskQueue) {
    this.taskQueueEditController
      .openEditPopup(queue, { analyticsSource: this.source })
      .pipe(untilDestroyed(this))
      .subscribe(result => {
        if (result.created) {
          this.form.form.patchValue({
            task_queue: result.queue
          });
          this.form.form.markAsDirty();
        } else if (result.deleted) {
          const firstQueue = this.taskQueueStore.instance ? this.taskQueueStore.instance[0] : undefined;
          this.form.form.patchValue({
            task_queue: firstQueue || null
          });
          this.form.form.markAsDirty();
        }
      });
  }
}

import { ViewSettings } from '@modules/customize';
import { isSet } from '@shared';

import { MenuItem } from './menu-item';
import { MenuItemAction, MenuItemActionType } from './menu-item-action';
import { MenuItemType } from './menu-item-type';
import { registerMenuItemForType } from './menu-items';

export class SimpleMenuItem extends MenuItem {
  public type = MenuItemType.Simple;
  public title: string;
  public icon: string;
  public action: MenuItemAction;

  deserialize(data: Object): SimpleMenuItem {
    super.deserialize(data);
    this.title = this.params['title'];
    this.icon = this.params['icon'];

    if (this.params['action']) {
      this.action = new MenuItemAction().deserialize(this.params['action']);
    } else if (isSet(this.params['uid']) || isSet(this.params['unique_name'])) {
      // Backward compatibility
      this.action = new MenuItemAction();
      this.action.type = MenuItemActionType.Page;
      this.action.pageUid = this.params['uid'];
      this.action.pageUniqueName = this.params['unique_name'];
    } else if (isSet(this.params['custom_link'])) {
      // Backward compatibility
      this.action = new MenuItemAction();
      this.action.type = MenuItemActionType.URL;
      this.action.url = this.params['custom_link'];
    }

    return this;
  }

  serialize(): Object {
    const data = super.serialize();
    data['params']['title'] = this.title;
    data['params']['icon'] = this.icon;
    data['params']['action'] = this.action ? this.action.serialize() : undefined;
    return data;
  }

  isForPage(page: ViewSettings) {
    if (!this.action || this.action.type != MenuItemActionType.Page) {
      return false;
    }

    if (isSet(this.action.pageUid)) {
      return page.uid == this.action.pageUid;
    } else if (isSet(this.action.pageUniqueName)) {
      return page.uniqueName == this.action.pageUniqueName;
    } else {
      return false;
    }
  }

  copyFrom(item: SimpleMenuItem) {
    super.copyFrom(item);
    this.title = item.title;
    this.icon = item.icon;

    if (item.action) {
      this.action = new MenuItemAction();
      this.action.copyFrom(item.action);
    }
  }

  clone(): SimpleMenuItem {
    const instance = new SimpleMenuItem();
    instance.copyFrom(this);
    return instance;
  }
}

registerMenuItemForType(MenuItemType.Simple, SimpleMenuItem);

import { ChangeDetectionStrategy, Component, HostBinding, Input, OnInit } from '@angular/core';

export type SectionColumnOptionsSize = 's' | 'fill';

@Component({
  selector: 'app-section-column',
  templateUrl: './section-column.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SectionColumnComponent implements OnInit {
  @HostBinding('class.section__column') cls = true;
  @Input() size: SectionColumnOptionsSize = 'fill';

  @HostBinding('class.section__column_size_s') get sizeS() {
    return this.size == 's';
  }

  constructor() {}

  ngOnInit() {}
}

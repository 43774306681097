import { ChangeDetectionStrategy, Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';

import { Option } from '@modules/field-components';
import { isSet, parseNumber } from '@shared';

import { FlexLayoutPaddingControl, FlexLayoutPaddingControlValue } from '../../controls/flex-layout';

enum Type {
  All = 'all',
  Custom = 'custom'
}

@Component({
  selector: 'app-flex-layout-padding-control',
  templateUrl: './flex-layout-padding-control.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FlexLayoutPaddingControlComponent),
      multi: true
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FlexLayoutPaddingControlComponent implements OnInit, OnDestroy, ControlValueAccessor {
  @Input() formControl: FlexLayoutPaddingControl;

  typeControl = new FormControl();
  allControl = new FormControl(0);
  topControl = new FormControl(0);
  rightControl = new FormControl(0);
  bottomControl = new FormControl(0);
  leftControl = new FormControl(0);

  typeOptions: Option<Type>[] = [
    {
      value: Type.All,
      icon: 'square_rounded',
      name: '',
      subtitle: 'Equal padding'
    },
    {
      value: Type.Custom,
      icon: 'sides',
      name: '',
      subtitle: 'Independent padding'
    }
  ];
  types = Type;

  onChange = (value: FlexLayoutPaddingControlValue) => undefined;
  onTouched = () => undefined;

  constructor() {}

  ngOnDestroy(): void {}

  ngOnInit() {}

  registerOnChange(fn: (value: FlexLayoutPaddingControlValue) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  writeValue(value: FlexLayoutPaddingControlValue): void {
    const topNumber = isSet(value.padding_top) ? parseNumber(value.padding_top, 0) : 0;
    const topClean = Math.max(topNumber, 0);

    const rightNumber = isSet(value.padding_right) ? parseNumber(value.padding_right, 0) : 0;
    const rightClean = Math.max(rightNumber, 0);

    const bottomNumber = isSet(value.padding_bottom) ? parseNumber(value.padding_bottom, 0) : 0;
    const bottomClean = Math.max(bottomNumber, 0);

    const leftNumber = isSet(value.padding_left) ? parseNumber(value.padding_left, 0) : 0;
    const leftClean = Math.max(leftNumber, 0);

    const equalCorners = [rightClean, bottomClean, leftClean].every(item => item == topClean);

    if (!equalCorners || !this.typeControl.value) {
      this.typeControl.patchValue(equalCorners ? Type.All : Type.Custom);
    }

    this.allControl.patchValue(topClean);
    this.topControl.patchValue(topClean);
    this.rightControl.patchValue(rightClean);
    this.bottomControl.patchValue(bottomClean);
    this.leftControl.patchValue(leftClean);
  }

  updateLocalValue(control: FormControl, value: string) {
    const valueNumber = isSet(value) ? parseNumber(value, 0) : 0;
    const cleanValue = Math.max(valueNumber, 0);

    control.patchValue(cleanValue);

    this.updateValue();
  }

  updateValue() {
    const value: FlexLayoutPaddingControlValue =
      this.typeControl.value == Type.Custom
        ? {
            padding_top: this.topControl.value,
            padding_right: this.rightControl.value,
            padding_bottom: this.bottomControl.value,
            padding_left: this.leftControl.value
          }
        : {
            padding_top: this.allControl.value,
            padding_right: this.allControl.value,
            padding_bottom: this.allControl.value,
            padding_left: this.allControl.value
          };

    this.onChange(value);
    this.onTouched();
  }
}

import { FormControl, FormGroup } from '@angular/forms';
import cloneDeep from 'lodash/cloneDeep';

import { ChartWidgetGroup } from '@modules/dashboard';
import { isSet } from '@shared';

export class ChartWidgetGroupControl extends FormGroup {
  instance: ChartWidgetGroup;

  controls: {
    value: FormControl;
    name: FormControl;
    color: FormControl;
    removable: FormControl;
  };

  constructor(state: { value?: any; name?: string; description?: string; color?: string } = {}) {
    super({
      value: new FormControl(isSet(state.value) ? state.value : ''),
      name: new FormControl(isSet(state.name) ? state.name : ''),
      color: new FormControl(isSet(state.color) ? state.color : ''),
      removable: new FormControl(false)
    });

    this.controls.value.disable();
  }

  deserialize(instance: ChartWidgetGroup) {
    this.instance = instance;

    if (instance) {
      this.controls.value.patchValue(instance.value);
      this.controls.name.patchValue(instance.name);
      this.controls.color.patchValue(instance.color);
    } else {
      this.controls.value.patchValue('');
      this.controls.name.patchValue('');
      this.controls.color.patchValue('');
    }

    this.markAsPristine();

    return this;
  }

  serialize(): ChartWidgetGroup {
    const result = cloneDeep(this.instance) || new ChartWidgetGroup();

    result.value = this.controls.value.value;
    result.name = this.controls.name.value;
    result.color = this.controls.color.value;

    return result;
  }

  isSet(): boolean {
    return isSet(this.controls.name.value) || isSet(this.controls.color.value);
  }
}

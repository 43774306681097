var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import isEqual from 'lodash/isEqual';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, of, throwError, timer } from 'rxjs';
import { catchError, debounceTime, delayWhen, map, switchMap } from 'rxjs/operators';
import { FormUtils } from '@common/form-utils';
import { ActionStore } from '@modules/action-queries';
import { PagePermissionsService, ViewSettingsStore, ViewSettingsType } from '@modules/customize';
import { CurrentEnvironmentStore, CurrentProjectStore, PermissionsGroup, ProjectGroup, ProjectGroupService, ProjectGroupStore } from '@modules/projects';
import { ProjectModelPermissionsArray } from './project-model-permissions.array';
import { ProjectPagePermissionsArray } from './project-page-permissions.array';
import { ProjectPermissionsControl } from './project-permissions.control';
export function duplicateNameValidator() {
    return function (control) {
        var projectGroupService = control.parent
            ? control.parent['projectGroupService']
            : undefined;
        var currentProjectStore = control.parent
            ? control.parent['currentProjectStore']
            : undefined;
        var currentEnvironmentStore = control.parent
            ? control.parent['currentEnvironmentStore']
            : undefined;
        var group = control.parent ? control.parent['group'] : undefined;
        if (control.value === null || !projectGroupService) {
            return of(null);
        }
        return timer(200).pipe(switchMap(function () {
            return projectGroupService.get(currentProjectStore.instance.uniqueName, currentEnvironmentStore.instance.uniqueName);
        }), map(function (groups) {
            var groupMatch = groups
                .filter(function (item) { return !group || group.uid != item.uid; })
                .find(function (item) { return item.name.toLowerCase().trim() === control.value.toLowerCase().trim(); });
            if (groupMatch) {
                return { local: ['Team with such name already exists'] };
            }
            return null;
        }));
    };
}
var ProjectGroupChangeForm = /** @class */ (function (_super) {
    __extends(ProjectGroupChangeForm, _super);
    function ProjectGroupChangeForm(modelPermissionsArray, pagePermissionsArray, formUtils, currentProjectStore, projectGroupStore, currentEnvironmentStore, projectGroupService, actionStore, viewSettingsStore, pagePermissionsService) {
        var _this = _super.call(this, {
            name: new FormControl('', [Validators.required], [duplicateNameValidator()]),
            // super_group: new FormControl(false),
            properties: new FormControl({}),
            permissions: new ProjectPermissionsControl(),
            model_permissions: modelPermissionsArray,
            page_permissions: pagePermissionsArray
        }) || this;
        _this.modelPermissionsArray = modelPermissionsArray;
        _this.pagePermissionsArray = pagePermissionsArray;
        _this.formUtils = formUtils;
        _this.currentProjectStore = currentProjectStore;
        _this.projectGroupStore = projectGroupStore;
        _this.currentEnvironmentStore = currentEnvironmentStore;
        _this.projectGroupService = projectGroupService;
        _this.actionStore = actionStore;
        _this.viewSettingsStore = viewSettingsStore;
        _this.pagePermissionsService = pagePermissionsService;
        return _this;
    }
    ProjectGroupChangeForm.prototype.ngOnDestroy = function () { };
    ProjectGroupChangeForm.prototype.init = function (group) {
        var _this = this;
        this.group = group;
        if (this.group) {
            this.controls.name.setValue(this.group.name);
            // this.controls.super_group.setValue(this.group.superGroup);
            this.controls.properties.setValue(this.group.properties);
            this.controls.permissions.deserialize(this.group.permissions);
            this.controls.model_permissions.deserialize(this.group.permissions);
            if (this.group.permissionsVersion == 1 && !this.group.permissionsGroup) {
                this.controls.page_permissions.initialized$.subscribe(function () {
                    _this.controls.page_permissions.setEveryControlAllActions(true);
                });
            }
            else {
                this.controls.page_permissions.deserialize(this.group.permissions);
            }
            if (this.group.protected) {
                if (this.group.permissionsGroup == PermissionsGroup.FullAccess) {
                    this.controls.permissions.setEveryControl(true);
                    this.controls.page_permissions.setEveryControlAllActions(true);
                    this.controls.model_permissions.setEveryControlAllActions(true);
                }
                else if (this.group.permissionsGroup == PermissionsGroup.Editor) {
                    this.controls.page_permissions.setEveryControlAllActions(true);
                    this.controls.model_permissions.setEveryControlAllActions(true);
                }
                else if (this.group.permissionsGroup == PermissionsGroup.ReadOnly) {
                    this.controls.page_permissions.setEveryControlAction('read', true);
                    this.controls.model_permissions.setEveryControlAction('read', true);
                }
            }
        }
        combineLatest(this.viewSettingsStore
            .get()
            .pipe(map(function (viewSettings) { return viewSettings.filter(function (item) { return item.view == ViewSettingsType.Custom; }); })), this.actionStore.get(), this.controls.page_permissions.valueChanges)
            .pipe(debounceTime(60), untilDestroyed(this))
            .subscribe(function (_a) {
            var viewSettings = _a[0], actions = _a[1], pagePermissions = _a[2];
            var newValue = _this.pagePermissionsService.getModelPermissionsFromPagePermissions(_this.controls.page_permissions.serialize(), _this.controls.model_permissions.permissions, viewSettings, actions);
            var currentValue = _this.controls.model_permissions.serialize();
            if (!isEqual(currentValue, newValue)) {
                _this.controls.model_permissions.deserialize(newValue);
            }
        });
    };
    ProjectGroupChangeForm.prototype.isPropertiesSet = function () {
        return this.controls.properties.value && !isEqual(this.controls.properties.value, {});
    };
    ProjectGroupChangeForm.prototype.submit = function () {
        var _this = this;
        this.markAsDirty();
        var value = this.value;
        var group = new ProjectGroup();
        if (this.group) {
            group.uid = this.group.uid;
        }
        group.name = value['name'];
        // group.superGroup = value['super_group'];
        group.properties = value['properties'];
        if (!group.protected) {
            group.permissions = this.controls.permissions.serialize().concat(this.controls.model_permissions.serialize(), this.controls.page_permissions.serialize());
        }
        group.permissionsVersion = 2;
        var obs;
        if (this.group) {
            obs = this.projectGroupService.update(this.currentProjectStore.instance.uniqueName, this.currentEnvironmentStore.instance.uniqueName, group);
        }
        else {
            obs = this.projectGroupService.create(this.currentProjectStore.instance.uniqueName, this.currentEnvironmentStore.instance.uniqueName, group);
        }
        return obs.pipe(delayWhen(function () { return _this.currentProjectStore.getFirst(true); }), delayWhen(function () { return _this.projectGroupStore.getFirst(true); }), catchError(function (error) {
            _this.formUtils.showFormErrors(_this, error);
            return throwError(error);
        }));
    };
    return ProjectGroupChangeForm;
}(FormGroup));
export { ProjectGroupChangeForm };

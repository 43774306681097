<div class="query-builder">
  <div class="query-builder__side">
    <ng-content select="ng-container[tabs]"></ng-content>
    <div class="query-builder-side__content">
      <div
        class="query-builder__step"
        [class.query-builder__step_active]="false"
        [class.query-builder__step_completed]="false"
        [class.query-builder__step_disabled]="false"
      >
        <!--          <div class="query-builder__step-header">-->
        <!--            <div class="query-builder__step-header-number">-->
        <!--              <span class="query-builder__step-header-number-content">1</span>-->
        <!--              <span class="query-builder__step-header-number-tick icon icon-check_2"></span>-->
        <!--            </div>-->
        <!--            <div class="query-builder__step-header-info">-->
        <!--              <div class="query-builder__step-header-title">Set up response</div>-->
        <!--              <div class="query-builder__step-header-subtitle">Transform response if needed</div>-->
        <!--            </div>-->
        <!--          </div>-->

        <div class="query-builder__step-content" style="padding: 0 20px; text-align: center;">
          This action is configured automatically
        </div>
      </div>
    </div>
    <div class="query-builder-side__footer">
      <div class="query-builder-side__footer-left">
        <button type="button" class="button" (click)="cancel()">
          <span class="button__label">Cancel</span>
        </button>
      </div>
      <div class="query-builder-side__footer-right">
        <button type="button" class="button button_primary" (click)="submit()">
          <span class="button__label">Save</span>
        </button>
      </div>
    </div>
  </div>
  <div *ngIf="false" class="query-builder__main">
    <div class="query-builder__main-top">
      <div class="code-editor code-editor_flexible">
        <div class="code-editor__main">
          <div class="code-editor__main-code"></div>
        </div>
      </div>
    </div>
    <div class="query-builder__main-bottom">
      <div class="query-builder__toolbar">
        <div class="query-builder__radio query-builder__radio_orange"></div>
      </div>
    </div>
  </div>
</div>

import { ChangeDetectionStrategy, Component, HostBinding, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-section',
  templateUrl: './section.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SectionComponent implements OnInit {
  @Input() @HostBinding('class.section') cls = true;
  @Input() @HostBinding('class.section_top') top = false;
  @Input() @HostBinding('class.section_no-top-margin') noTopMargin = false;
  @Input() @HostBinding('class.section_no-bottom-margin') noBottomMargin = false;
  @Input() @HostBinding('class.section_screen') screen = false;
  @Input() @HostBinding('class.section_disabled') disabled = false;
  @Input() @HostBinding('class.section_full-border-radius') fullBorderRadius = false;
  @Input() @HostBinding('class.section_small-border-radius') smallBorderRadius = false;

  constructor() {}

  ngOnInit() {}
}

<app-sidebar-collapse
  *ngIf="collapse"
  [title]="title"
  [titleAdditional]="titleAdditional$ | async"
  [titleAdditionalRed]="itemForm.value['required'] || (form.parameter$(itemForm) | async)?.required"
  [titleAdditionalSelectedOption]="itemForm.value['required']"
  [titleAdditionalOptions]="
    (form.parameter$(itemForm) | async)?.required || !requiredEditable ? [] : titleAdditionalOptions
  "
  [error]="
    (form.parameter$(itemForm) | async)?.required && itemForm.errors?.required ? 'This input is required' : undefined
  "
  [description]="(valueDescription | appIsSet) ? valueDescription : description"
  [descriptionWhenOpened]="description"
  [descriptionWrap]="valueDescription | appIsSet"
  [block]="true"
  [context]="collapseContext"
  [openedInitial]="openedInitial"
  [openable]="itemForm.value['field']"
  [disabled]="
    !((form.parameter$(itemForm) | async)?.required && itemForm.errors?.required) && !(form.isSet$(itemForm) | async)
  "
  [arrow]="!(remove && (form.parameter$(itemForm) | async)?.required != true)"
  [classes]="classes"
  (titleAdditionalOptionSelected)="itemForm.patchValue({ required: $event })"
  #collapse_element
>
  <ng-container right>
    <a
      *ngIf="remove && (form.parameter$(itemForm) | async)?.required != true"
      href="javascript:void(0)"
      class="sidebar-collapse__action icon-bin"
      (click)="$event.stopPropagation(); removeItem()"
    >
    </a>
  </ng-container>

  <app-input-edit
    *ngIf="collapse_element.isOpened() && itemForm.value['field']"
    [itemForm]="itemForm"
    [context]="context"
    [contextElement]="contextElement"
    [contextElementPath]="contextElementPath"
    [contextElementPaths]="contextElementPaths"
    [staticValueField]="(form.parameter$(itemForm) | async)?.field"
    [staticValueParams]="(form.parameter$(itemForm) | async)?.params"
    [staticValueDisabled]="staticValueDisabled"
    [filterFields]="form.filterFields$() | async"
    [userInput]="userInput"
    [focusedInitial]="focusedOnOpen && !collapse_element.titleAdditionalOptionsOpened"
    [placeholder]="placeholder"
    [formulaPlaceholder]="formulaPlaceholder"
    [jsPlaceholder]="jsPlaceholder"
    [displayValueTypes]="displayValueTypes"
    [fill]="true"
    [analyticsSource]="object"
  ></app-input-edit>

  <app-alert *ngIf="warning" [warning]="true" style="margin-top: 10px;">
    <span [innerHTML]="warning"></span>
  </app-alert>
</app-sidebar-collapse>

<app-sidebar-field *ngIf="!collapse" [label]="title">
  <app-input-edit
    [itemForm]="itemForm"
    [context]="context"
    [contextElement]="contextElement"
    [contextElementPath]="contextElementPath"
    [contextElementPaths]="contextElementPaths"
    [staticValueField]="(form.parameter$(itemForm) | async)?.field"
    [staticValueParams]="(form.parameter$(itemForm) | async)?.params"
    [staticValueDisabled]="staticValueDisabled"
    [filterFields]="form.filterFields$() | async"
    [userInput]="userInput"
    [displayValueTypes]="displayValueTypes"
    [fill]="true"
    [analyticsSource]="object"
  ></app-input-edit>
  <div *ngIf="(form.parameter$(itemForm) | async)?.required && itemForm.errors?.required" class="field__errors">
    <div class="field__error">required</div>
  </div>
  <app-alert *ngIf="warning" [warning]="true" style="margin-top: 10px;">
    <span [innerHTML]="warning"></span>
  </app-alert>
</app-sidebar-field>

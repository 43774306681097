/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../list-groups-edit/list-groups-edit.component.ngfactory";
import * as i2 from "../list-groups-edit/list-groups-edit.component";
import * as i3 from "../../../../common/notifications/services/notification/notification.service";
import * as i4 from "@angular/cdk/overlay";
import * as i5 from "@angular/cdk/bidi";
import * as i6 from "./list-groups-edit-overlay.component";
var styles_ListGroupsEditOverlayComponent = [];
var RenderType_ListGroupsEditOverlayComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ListGroupsEditOverlayComponent, data: {} });
export { RenderType_ListGroupsEditOverlayComponent as RenderType_ListGroupsEditOverlayComponent };
function View_ListGroupsEditOverlayComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [["class", "popover2 overlay-content overlay-content_animated"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 4, "div", [["class", "popover2__inner popover2__inner_sidebar"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 3, "div", [["class", "popover2__viewport popover2__viewport_scrollable"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 2, "div", [["style", "min-width: 300px;"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 1, "app-list-groups-edit", [], null, null, null, i1.View_ListGroupsEditComponent_0, i1.RenderType_ListGroupsEditComponent)), i0.ɵdid(5, 245760, null, 0, i2.ListGroupsEditComponent, [i3.NotificationService, i0.ChangeDetectorRef], { form: [0, "form"], context: [1, "context"], contextElement: [2, "contextElement"], addingMissing: [3, "addingMissing"], analyticsSource: [4, "analyticsSource"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.form; var currVal_1 = _co.context; var currVal_2 = _co.contextElement; var currVal_3 = _co.addingMissing; var currVal_4 = _co.analyticsSource; _ck(_v, 5, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }, null); }
export function View_ListGroupsEditOverlayComponent_0(_l) { return i0.ɵvid(2, [i0.ɵqud(402653184, 1, { cdkConnectedOverlay: 0 }), (_l()(), i0.ɵand(16777216, null, null, 2, function (_v, en, $event) { var ad = true; var _co = _v.component; if (("backdropClick" === en)) {
        var pd_0 = (_co.setDropdownOpened(false) !== false);
        ad = (pd_0 && ad);
    } if (("detach" === en)) {
        var pd_1 = (_co.setDropdownOpened(false) !== false);
        ad = (pd_1 && ad);
    } return ad; }, View_ListGroupsEditOverlayComponent_1)), i0.ɵdid(2, 671744, [[1, 4]], 0, i4.CdkConnectedOverlay, [i4.Overlay, i0.TemplateRef, i0.ViewContainerRef, i4.ɵc, [2, i5.Directionality]], { origin: [0, "origin"], positions: [1, "positions"], backdropClass: [2, "backdropClass"], panelClass: [3, "panelClass"], open: [4, "open"], hasBackdrop: [5, "hasBackdrop"], growAfterOpen: [6, "growAfterOpen"], push: [7, "push"] }, { backdropClick: "backdropClick", detach: "detach" }), i0.ɵpad(3, 2)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.origin; var currVal_1 = _co.popoverPositions; var currVal_2 = "popover2-backdrop"; var currVal_3 = _ck(_v, 3, 0, "overlay", "overlay_horizontal_center"); var currVal_4 = _co.dropdownOpened; var currVal_5 = true; var currVal_6 = true; var currVal_7 = true; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); }, null); }
export function View_ListGroupsEditOverlayComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-list-groups-edit-overlay", [], null, null, null, View_ListGroupsEditOverlayComponent_0, RenderType_ListGroupsEditOverlayComponent)), i0.ɵdid(1, 4440064, null, 0, i6.ListGroupsEditOverlayComponent, [i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ListGroupsEditOverlayComponentNgFactory = i0.ɵccf("app-list-groups-edit-overlay", i6.ListGroupsEditOverlayComponent, View_ListGroupsEditOverlayComponent_Host_0, { form: "form", context: "context", contextElement: "contextElement", addingMissing: "addingMissing", origin: "origin", trigger: "trigger", triggerManual: "triggerManual", openedInitial: "openedInitial", analyticsSource: "analyticsSource" }, {}, []);
export { ListGroupsEditOverlayComponentNgFactory as ListGroupsEditOverlayComponentNgFactory };

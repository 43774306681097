import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';

import { ActionService, WorkflowStepInfo } from '@modules/action-queries';
import { Workflow } from '@modules/workflow';
import { TypedChanges } from '@shared';

@Component({
  selector: 'app-workflow-button',
  templateUrl: './workflow-button.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WorkflowButtonComponent implements OnInit, OnDestroy, OnChanges {
  @Input() workflow: Workflow;
  @Input() title = 'Workflow';
  @Input() emptySubtitle = 'Not specified';
  @Input() emptyDisabled = false;
  @Input() icon = 'edit';
  @Input() border = false;
  @Input() remove = false;
  @Output() editClick = new EventEmitter<void>();
  @Output() removeClick = new EventEmitter<void>();

  workflowStepsCount = 0;
  stepsInfo: WorkflowStepInfo[] = [];
  stepsMore = 0;

  constructor(private actionService: ActionService, private cd: ChangeDetectorRef) {}

  ngOnInit() {}

  ngOnDestroy(): void {}

  ngOnChanges(changes: TypedChanges<WorkflowButtonComponent>): void {
    if (changes.workflow) {
      this.workflowStepsCount = this.workflow ? this.workflow.getStepsCount() : 0;
      this.updateStepsInfo();
    }
  }

  updateStepsInfo() {
    const maxSteps = 2;
    const steps = this.workflow ? this.workflow.flattenSteps() : [];

    this.actionService
      .getStepsInfo(steps.slice(0, maxSteps))
      .pipe(untilDestroyed(this))
      .subscribe(
        result => {
          this.stepsInfo = result;
          this.stepsMore = steps.length - maxSteps;
          this.cd.markForCheck();
        },
        () => {
          this.stepsInfo = [];
          this.stepsMore = 0;
          this.cd.markForCheck();
        }
      );
  }
}

import { Injectable, OnDestroy } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';

import { GlobalsService } from '@modules/globals';
import { ProjectApiService } from '@modules/project-api';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';

@Injectable()
export class AdminGlobalsService implements OnDestroy {
  constructor(
    private globalsService: GlobalsService,
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private projectApiService: ProjectApiService
  ) {
    this.currentProjectStore.instance$.pipe(untilDestroyed(this)).subscribe(project => {
      this.globalsService.setProject(project);
    });

    this.currentEnvironmentStore.instance$.pipe(untilDestroyed(this)).subscribe(environment => {
      this.globalsService.setEnvironment(environment);
    });

    this.globalsService.setGetProjectToken(() => {
      return this.projectApiService.getAccessToken();
    });
  }

  ngOnDestroy(): void {
    this.globalsService.clearEnvironment();
    this.globalsService.clearGetProjectToken();
  }
}

import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { removeElement } from '@shared';
import * as i0 from "@angular/core";
var PageLoadingService = /** @class */ (function () {
    function PageLoadingService() {
        this._loaders = new BehaviorSubject([]);
    }
    PageLoadingService.prototype.hasLoader = function (loader) {
        return this._loaders.value.some(function (item) { return item === loader; });
    };
    PageLoadingService.prototype.hasLoaders$ = function () {
        return this._loaders.pipe(map(function (value) { return !!value.length; }));
    };
    PageLoadingService.prototype.addLoader = function (loader) {
        if (!this.hasLoader(loader)) {
            this._loaders.next(this._loaders.value.concat([loader]));
        }
    };
    PageLoadingService.prototype.removeLoader = function (loader) {
        if (this.hasLoader(loader)) {
            this._loaders.next(this._loaders.value.filter(function (item) { return item !== loader; }));
        }
    };
    PageLoadingService.prototype.removeAppLoader = function () {
        removeElement(document.getElementsByTagName('app-preloader')[0]);
    };
    PageLoadingService.ngInjectableDef = i0.defineInjectable({ factory: function PageLoadingService_Factory() { return new PageLoadingService(); }, token: PageLoadingService, providedIn: "root" });
    return PageLoadingService;
}());
export { PageLoadingService };

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Power2, TimelineMax } from 'gsap';
import keys from 'lodash/keys';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { EMPTY, of } from 'rxjs';
import { delayWhen, filter, map, switchMap } from 'rxjs/operators';

import { NotificationService } from '@common/notifications';
import { SessionStorage } from '@core';
import { AdminMode } from '@modules/admin-mode';
import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { CustomizeService, CustomizeType, ViewSettings } from '@modules/customize';
import { Domain } from '@modules/domain';
import { createFormFieldFactory } from '@modules/fields';
import { MetaService } from '@modules/meta';
import { Project, ProjectsStore, ProjectType, ResourceTypeItem, resourceTypeItems } from '@modules/projects';
import { RoutingService } from '@modules/routing';
import { TemplateService } from '@modules/template';
import { adminPanelTemplateItem, TemplateItem, TemplateItemType } from '@modules/template-components';
import { CurrentUserStore, Employment } from '@modules/users';

import { ProductTypeValue, ProjectCreateProjectForm } from './project-create-project.form';
import { ProjectCreateUserForm } from './project-create-user.form';

export const PROJECT_CREATE_TEMPLATE_ITEM = 'project_create_template_item';

export interface TemplateItemSerialized {
  type: TemplateItemType;
  resource?: ResourceTypeItem;
  template?: number;
}

@Component({
  selector: 'app-project-create3',
  templateUrl: './project-create3.component.html',
  providers: [ProjectCreateProjectForm, ProjectCreateUserForm],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProjectCreate3Component implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('root') root: ElementRef;
  @ViewChild('background') background: ElementRef;
  @ViewChild('create_project') createProjectElement: ElementRef;
  @ViewChild('install_type') installTypeElement: ElementRef;
  @ViewChild('choose_template') chooseTemplateElement: ElementRef;
  @ViewChild('choose_resource') chooseResourceElement: ElementRef;

  createdProject: Project;
  createdPage: ViewSettings;
  finishLink: any[];
  applyTemplateItem: TemplateItem;
  createLoading = false;
  userInfoLoading = false;
  showButtonBack = true;
  createField = createFormFieldFactory();
  projectTypes = ProjectType;
  employments = Employment;
  domain: Domain;
  userInfoStep = false;
  hasProjects = false;
  productTypeValues = ProductTypeValue;

  constructor(
    public currentUserStore: CurrentUserStore,
    public projectForm: ProjectCreateProjectForm,
    public userForm: ProjectCreateUserForm,
    private metaService: MetaService,
    private activatedRoute: ActivatedRoute,
    private notificationService: NotificationService,
    private routing: RoutingService,
    private customizeService: CustomizeService,
    private sessionStorage: SessionStorage,
    private templateService: TemplateService,
    private analyticsService: UniversalAnalyticsService,
    private cd: ChangeDetectorRef,
    private projectsStore: ProjectsStore
  ) {}

  ngOnInit() {
    this.metaService.set({ title: 'New Project' });

    this.activatedRoute.data.pipe(untilDestroyed(this)).subscribe(data => {
      this.domain = data.domain;
      this.cd.markForCheck();
    });

    this.activatedRoute.queryParams.pipe(untilDestroyed(this)).subscribe(queryParams => {
      const params = keys(queryParams).length ? queryParams : { create: '1' };
      this.routing.navigate(['/projects'], { queryParams: params });
    });

    // this.activatedRoute.queryParams
    //   .pipe(
    //     switchMap(params => {
    //       if (params['apply_template']) {
    //         return this.templateService.getDetail(params['apply_template']).pipe(
    //           map(template => {
    //             if (!template) {
    //               return;
    //             }
    //
    //             return {
    //               name: template.name,
    //               description: template.description,
    //               type: TemplateItemType.Template,
    //               template: template
    //             } as TemplateItem;
    //           })
    //         );
    //       } else if (params['apply_template_admin_panel']) {
    //         return of(adminPanelTemplateItem);
    //       } else if (params['apply_template_admin_panel_resource']) {
    //         return of(resourceTypeItems.find(item => item.name == params['apply_template_admin_panel_resource'])).pipe(
    //           map(resource => {
    //             return {
    //               ...adminPanelTemplateItem,
    //               resource: resource
    //             };
    //           })
    //         );
    //       } else {
    //         return EMPTY;
    //       }
    //     }),
    //     filter(params => params != undefined),
    //     untilDestroyed(this)
    //   )
    //   .subscribe(item => {
    //     this.applyTemplateItem = item;
    //     this.cd.markForCheck();
    //   });

    this.hasProjects = this.projectsStore.instance && !!this.projectsStore.instance.length;
    this.cd.markForCheck();
  }

  ngOnDestroy(): void {}

  ngAfterViewInit(): void {
    // this.show();
  }

  show() {
    new TimelineMax().fromTo(
      this.root.nativeElement,
      0.6,
      {
        scale: 0.5,
        opacity: 0
      },
      {
        scale: 1,
        opacity: 1,
        ease: Power2.easeOut
      },
      0
    );
  }

  get isWhiteLabel() {
    return this.domain && this.domain.whiteLabel;
  }

  createProject() {
    if (this.createLoading || this.projectForm.invalid) {
      return;
    }

    this.createLoading = true;
    this.cd.markForCheck();

    this.projectForm
      .create()
      .pipe(
        delayWhen(result => {
          if (this.applyTemplateItem) {
            const serialized: TemplateItemSerialized = {
              type: this.applyTemplateItem.type,
              resource: this.applyTemplateItem.resource,
              template: this.applyTemplateItem.template ? this.applyTemplateItem.template.id : undefined
            };
            this.sessionStorage.set(PROJECT_CREATE_TEMPLATE_ITEM, JSON.stringify(serialized));
          } else {
            this.sessionStorage.remove(PROJECT_CREATE_TEMPLATE_ITEM);
          }

          if (result.page) {
            this.finishLink = result.page.link;
          } else {
            this.finishLink = result.project.homeLink;
          }

          return of(result);
        }),
        untilDestroyed(this)
      )
      .subscribe(
        result => {
          this.createdProject = result.project;
          this.createdPage = result.page;

          if (this.currentUserStore.instance.params.userInfo) {
            this.createLoading = false;
            this.userInfoStep = true;
            this.finishCreateProject();
          } else {
            this.createLoading = false;
            this.userInfoStep = true;
            this.cd.markForCheck();
          }
        },
        error => {
          this.notificationService.error('Error', `Creating failed: ${error}`);
          this.createLoading = false;
          this.cd.markForCheck();
        }
      );
  }

  sendUserInfo() {
    this.userInfoLoading = true;
    this.cd.markForCheck();

    this.userForm
      .send()
      .pipe(untilDestroyed(this))
      .subscribe(
        () => {
          this.finishCreateProject();
        },
        error => {
          this.notificationService.error('Error', `Send failed: ${error}`);
          this.userInfoLoading = false;
          this.cd.markForCheck();
        }
      );
  }

  skipUserInfo() {
    this.finishCreateProject();

    this.analyticsService.sendSimpleEvent(AnalyticsEvent.Project.UserInfoEntered, {
      Type: 'Skip'
    });
  }

  finishCreateProject() {
    if (!this.customizeService.layoutEnabled) {
      this.customizeService.toggleEnabled(CustomizeType.Layout);
    }

    const onboardingEventParams = {
      ...(this.applyTemplateItem &&
        this.applyTemplateItem.type == TemplateItemType.AdminPanel &&
        !this.applyTemplateItem.resource && {
          OnboardingTemplateAdminPanel: true
        }),
      ...(this.applyTemplateItem &&
        this.applyTemplateItem.type == TemplateItemType.AdminPanel &&
        this.applyTemplateItem.resource && {
          OnboardingTemplateAdminPanelResource: this.applyTemplateItem.resource.name
        })
    };

    this.analyticsService.sendSimpleEvent(AnalyticsEvent.Project.ProjectCreated, {
      ProjectID: this.createdProject.uniqueName,
      About: this.projectForm.getAbout(),
      ...onboardingEventParams
      // Type: value['type']
    });

    this.analyticsService.sendSimpleEvent(AnalyticsEvent.Subscription.SuccessfulSubscribed, {
      ProjectID: this.createdProject.uniqueName,
      Plan: this.createdProject.subscription ? this.createdProject.subscription.plan.uniqueName : undefined,
      Price: this.createdProject.subscription ? this.createdProject.subscription.price : undefined,
      FreeTrial: this.createdProject.subscription ? !!this.createdProject.subscription.freeTrial : undefined,
      DateBegin:
        this.createdProject.subscription && this.createdProject.subscription.dateBegin
          ? this.createdProject.subscription.dateBegin.toISOString()
          : undefined,
      DateEnd:
        this.createdProject.subscription && this.createdProject.subscription.dateEnd
          ? this.createdProject.subscription.dateEnd.toISOString()
          : undefined
    });

    if (this.finishLink) {
      this.routing.navigateApp(this.finishLink, undefined, {
        projectName: this.createdProject.uniqueName,
        environmentName: this.createdProject.defaultEnvironment
          ? this.createdProject.defaultEnvironment.uniqueName
          : 'prod',
        modeName: AdminMode.Builder
      });
    }
  }
}

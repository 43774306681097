var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ChangeDetectorRef, Injector, OnChanges, OnDestroy, OnInit, QueryList, SimpleChanges } from '@angular/core';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import values from 'lodash/values';
import { combineLatest, of } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { NotificationService } from '@common/notifications';
import { PopoverService } from '@common/popover';
import { PopupService } from '@common/popups';
import { ActionControllerService } from '@modules/action-queries';
import { UniversalAnalyticsService } from '@modules/analytics';
import { getModelAttributesByColumns, getModelBulkAttributesByColumns, ListDefaultSelection, rawListViewSettingsColumnsToViewContextOutputs, ViewSettingsService, ViewSettingsStore } from '@modules/customize';
import { CustomizeBarContext, CustomizeBarService } from '@modules/customize-bar';
import { DataSourceType } from '@modules/data-sources';
import { applyParamInput$, applyParamInputs$, DisplayFieldType, FieldType, getFieldDescriptionByType, LOADING_VALUE, NOT_SET_VALUE } from '@modules/fields';
import { EMPTY_FILTER_VALUES } from '@modules/filters';
import { ListLayoutType } from '@modules/layouts';
import { AFTER_ITEM_OUTPUT, BEFORE_ITEM_OUTPUT, CHECKED_ITEMS_OUTPUT, COLUMN_FROM_OUTPUT, COLUMN_OUTPUT, COLUMN_TO_OUTPUT, EMPTY_OUTPUT, HAS_SELECTED_ITEM_OUTPUT, ITEM_OUTPUT, NO_SELECTED_ITEM_OUTPUT, ORDER_OUTPUT, PREV_AFTER_ITEM_OUTPUT, PREV_BEFORE_ITEM_OUTPUT, PREV_COLUMN_OUTPUT, PREV_ORDER_OUTPUT, SELECTED_ITEM_OUTPUT } from '@modules/list';
import { ListLayoutComponent } from '@modules/list-components';
import { MenuSettingsStore } from '@modules/menu';
import { ModelDescriptionStore, ModelService } from '@modules/model-queries';
import { NEXT_PAGE_SCROLL_PARAM, PAGE_PARAM } from '@modules/models';
import { InputService } from '@modules/parameters';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { RoutingService } from '@modules/routing';
import { isSet } from '@shared';
import { KanbanBoardColumnComponent } from '../kanban-board-column/kanban-board-column.component';
import { getBaseStateFetch, getListStateColumns, getListStateDefaultSelection, getListStateFetchNewParams } from './kanban-board-state';
export function getListStateFetch(state) {
    return __assign({}, getBaseStateFetch(state), { stageField: state.stageField, stages: state.stages ? state.stages.map(function (item) { return ({ name: item.name }); }) : undefined });
}
function getListStateSelection(state) {
    return {
        multipleSelection: state.multipleSelection
    };
}
var KanbanBoardComponent = /** @class */ (function (_super) {
    __extends(KanbanBoardComponent, _super);
    function KanbanBoardComponent(modelService, actionControllerService, notificationService, injector, cd, customizeBarContext, customizeBarService, analyticsService, viewSettingsService, viewSettingsStore, menuSettingsStore, modelDescriptionStore, inputService, routing, currentProjectStore, currentEnvironmentStore, popupService, popoverService) {
        var _this = _super.call(this, injector, cd, customizeBarContext, customizeBarService, analyticsService, viewSettingsService, viewSettingsStore, menuSettingsStore, modelDescriptionStore, inputService, routing, currentProjectStore, currentEnvironmentStore, popupService, popoverService) || this;
        _this.modelService = modelService;
        _this.actionControllerService = actionControllerService;
        _this.notificationService = notificationService;
        _this.columnComponents = new QueryList();
        _this.layout = ListLayoutType.KanbanBoard;
        _this.visibleColumns = [];
        _this.loading = true;
        _this.configured = true;
        _this.checkedItems = {};
        return _this;
    }
    KanbanBoardComponent.prototype.ngOnInit = function () {
        _super.prototype.ngOnInit.call(this);
        this.initContext();
    };
    KanbanBoardComponent.prototype.ngOnDestroy = function () { };
    KanbanBoardComponent.prototype.ngOnChanges = function (changes) {
        _super.prototype.ngOnChanges.call(this, changes);
    };
    KanbanBoardComponent.prototype.getListState = function (settings, params, filters, search, sort) {
        var _this = this;
        params = cloneDeep(params);
        delete params[PAGE_PARAM];
        delete params[NEXT_PAGE_SCROLL_PARAM];
        if (!sort.length && isSet(settings.sortingField)) {
            sort = [{ field: settings.sortingField, desc: !settings.sortingAsc }];
        }
        var staticData$ = settings.dataSource && settings.dataSource.type == DataSourceType.Input && settings.dataSource.input
            ? applyParamInput$(settings.dataSource.input, {
                context: this.context,
                defaultValue: [],
                handleLoading: true,
                ignoreEmpty: true
            }).pipe(distinctUntilChanged(function (lhs, rhs) { return isEqual(lhs, rhs); }))
            : of([]);
        var inputParams$ = settings.dataSource
            ? applyParamInputs$({}, settings.dataSource.queryInputs, {
                context: this.context,
                parameters: settings.dataSource.queryParameters,
                handleLoading: true,
                ignoreEmpty: true,
                emptyValues: EMPTY_FILTER_VALUES
            }).pipe(distinctUntilChanged(function (lhs, rhs) { return isEqual(lhs, rhs); }))
            : of({});
        return combineLatest(staticData$, inputParams$, this.getQueryModelDescription(settings.dataSource)).pipe(map(function (_a) {
            var staticData = _a[0], inputParams = _a[1], modelDescription = _a[2];
            var resource = settings.dataSource
                ? _this.currentEnvironmentStore.resources.find(function (item) { return item.uniqueName == settings.dataSource.queryResource; })
                : undefined;
            return {
                settings: settings,
                dataSource: settings.dataSource,
                dataSourceStaticData: staticData,
                dataSourceParams: __assign({}, inputParams, params),
                userParams: params,
                filters: filters,
                search: search,
                sort: sort,
                resource: resource,
                modelDescription: modelDescription,
                inputsLoading: [inputParams, staticData].some(function (obj) {
                    return obj == LOADING_VALUE || values(obj).some(function (item) { return item === LOADING_VALUE; });
                }),
                inputsNotSet: [inputParams, staticData].some(function (obj) {
                    return obj == NOT_SET_VALUE || values(obj).some(function (item) { return item === NOT_SET_VALUE; });
                }),
                perPage: settings ? settings.perPage : undefined,
                stageField: settings ? settings.stageField : undefined,
                stages: settings ? settings.stages : [],
                sortingField: settings ? settings.sortingField : undefined,
                sortingAsc: settings ? settings.sortingAsc : undefined,
                multipleSelection: settings.multipleSelection,
                defaultSelection: settings ? settings.defaultSelection : undefined
            };
        }));
    };
    KanbanBoardComponent.prototype.onStateUpdated = function (state) {
        if (!isEqual(getListStateColumns(state), getListStateColumns(this.listState)) ||
            !isEqual(getListStateSelection(state), getListStateSelection(this.listState))) {
            this.updateContextOutputs(state);
        }
        if (!isEqual(getListStateColumns(state), getListStateColumns(this.listState))) {
            this.updateVisibleColumns(state);
        }
        if (!isEqual(getListStateFetch(state), getListStateFetch(this.listState))) {
            var newParams = !isEqual(getListStateFetchNewParams(state), getListStateFetchNewParams(this.listState));
            if (newParams) {
                this.setSelectedItem(undefined, false);
                this.setChecked([], false);
                this.updateSelectedContext();
            }
            this.fetch(state);
        }
        else {
            if (!isEqual(getListStateDefaultSelection(state), getListStateDefaultSelection(this.listState)) &&
                state.defaultSelection == ListDefaultSelection.First) {
                var component = this.columnComponents.toArray()[0];
                var componentItems = component ? component.getItems() : undefined;
                var firstItem = componentItems ? componentItems[0] : undefined;
                this.setSelectedItem(firstItem, false);
                this.setChecked(firstItem ? [firstItem.model] : [], false);
                this.updateSelectedContext();
            }
        }
    };
    KanbanBoardComponent.prototype.getStateQueryOptions = function (state, extraParams) {
        if (extraParams === void 0) { extraParams = {}; }
        var component = this.columnComponents.toArray()[0];
        return component ? component.getBaseStateQueryOptions() : {};
    };
    KanbanBoardComponent.prototype.fetch = function (state) {
        this.configured = state.dataSource && state.dataSource.isConfigured() && state.settings.isConfigured();
        this.parameters = this.getParameters(state);
        this.inputs = this.getInputs(state);
        this.selectedItem = undefined;
        this.loading = false;
        this.cd.markForCheck();
    };
    KanbanBoardComponent.prototype.onFetch = function () {
        this.contextElement.setOutputValue(EMPTY_OUTPUT, false, { loading: true });
    };
    KanbanBoardComponent.prototype.onFetched = function () {
        var loading = this.columnComponents.toArray().some(function (item) { return item.loading; });
        if (!loading) {
            var isEmpty = this.columnComponents.toArray().every(function (item) {
                var groupItems = item.getItems();
                return groupItems && !groupItems.length;
            });
            this.contextElement.setOutputValue(EMPTY_OUTPUT, isEmpty, { loading: false });
        }
    };
    KanbanBoardComponent.prototype.reloadData = function () {
        _super.prototype.reloadData.call(this);
        this.columnComponents.forEach(function (item) { return item.reloadData(); });
    };
    KanbanBoardComponent.prototype.getStageName = function (index) {
        return [this.viewId, 'stage', index].join('_');
    };
    KanbanBoardComponent.prototype.getStageCount = function (index) {
        var component = this.columnComponents.toArray()[index];
        return component ? component.getCount() : undefined;
    };
    KanbanBoardComponent.prototype.setStageCount = function (index, value) {
        var component = this.columnComponents.toArray()[index];
        if (component) {
            component.setCount(value);
        }
    };
    Object.defineProperty(KanbanBoardComponent.prototype, "droppable", {
        get: function () {
            var _this = this;
            if (!this.listState.stages) {
                return [];
            }
            return this.listState.stages.map(function (item, i) { return _this.getStageName(i); });
        },
        enumerable: true,
        configurable: true
    });
    KanbanBoardComponent.prototype.onItemAdded = function (i, item) {
        this.itemMove.toStage = i;
    };
    KanbanBoardComponent.prototype.getItemOrdering = function (stage, item) {
        var items = this.columnComponents.toArray()[stage].dropList.data;
        var itemIndex = items.findIndex(function (i) { return i === item; });
        return {
            columnIndex: itemIndex,
            beforeItem: items[itemIndex + 1] ? items[itemIndex + 1].model : undefined,
            afterItem: items[itemIndex - 1] ? items[itemIndex - 1].model : undefined
        };
    };
    KanbanBoardComponent.prototype.onDragStarted = function (i, item) {
        var fromOrdering = this.getItemOrdering(i, item);
        this.itemMove = {
            model: item.model,
            fromStage: i,
            fromOrdering: fromOrdering,
            toStage: i,
            toOrdering: fromOrdering
        };
    };
    KanbanBoardComponent.prototype.onDragFinished = function (i, item) {
        if (!this.itemMove) {
            return;
        }
        var fromStageCount = this.getStageCount(this.itemMove.fromStage);
        if (fromStageCount !== undefined) {
            this.setStageCount(this.itemMove.fromStage, fromStageCount - 1);
        }
        var toStageCount = this.getStageCount(this.itemMove.toStage);
        if (toStageCount !== undefined) {
            this.setStageCount(this.itemMove.toStage, toStageCount + 1);
        }
        this.itemMove.toOrdering = this.getItemOrdering(this.itemMove.toStage, item);
        this.changeStageIfNeeded();
        this.reorderIfNeeded();
        this.itemMove = undefined;
    };
    KanbanBoardComponent.prototype.changeStageIfNeeded = function () {
        var _this = this;
        var _a;
        if (!this.itemMove) {
            return;
        }
        if (this.itemMove.toStage === this.itemMove.fromStage) {
            return;
        }
        var stageColumn = this.settings.dataSource.columns.find(function (item) { return item.name == _this.settings.stageField; });
        var stages = this.listState.stages || [];
        var toStage = stages[this.itemMove.toStage].value;
        var fromStage = stages[this.itemMove.fromStage].value;
        var fieldDescription = getFieldDescriptionByType(stageColumn ? stageColumn.field : undefined);
        if (fieldDescription.deserializeValue) {
            toStage = fieldDescription.deserializeValue(toStage, stageColumn);
            fromStage = fieldDescription.deserializeValue(fromStage, stageColumn);
        }
        // const model = this.itemMove.model;
        //
        // if (model.getAttribute(this.settings.stageField) != stage.value) {
        //   model.setAttribute(this.settings.stageField, stage.value);
        //
        //   this.modelService.update(this.params.modelDescription.modelId, model, [this.settings.stageField])
        //     .pipe(untilDestroyed(this))
        //     .subscribe();
        // }
        if (this.settings.cardColumnChangeAction) {
            var model = this.itemMove.model;
            this.actionControllerService
                .execute(this.settings.cardColumnChangeAction, {
                context: this.contextElement.context,
                contextElement: this.contextElement,
                localContext: (_a = {},
                    _a[COLUMN_FROM_OUTPUT] = fromStage,
                    _a[COLUMN_TO_OUTPUT] = toStage,
                    _a[ITEM_OUTPUT] = model.getAttributes(),
                    _a),
                injector: this.injector
            })
                .subscribe();
        }
    };
    KanbanBoardComponent.prototype.reorderIfNeeded = function () {
        var _this = this;
        var _a;
        if (!this.itemMove) {
            return;
        }
        if (this.itemMove.toStage === this.itemMove.fromStage &&
            this.itemMove.toOrdering.columnIndex === this.itemMove.fromOrdering.columnIndex) {
            return;
        }
        var stageColumn = this.settings.dataSource.columns.find(function (item) { return item.name == _this.settings.stageField; });
        var stages = this.listState.stages || [];
        var toStage = stages[this.itemMove.toStage].value;
        var fromStage = stages[this.itemMove.fromStage].value;
        var fieldDescription = getFieldDescriptionByType(stageColumn ? stageColumn.field : undefined);
        if (fieldDescription.deserializeValue) {
            toStage = fieldDescription.deserializeValue(toStage, stageColumn);
            fromStage = fieldDescription.deserializeValue(fromStage, stageColumn);
        }
        // const ordering = [
        //   this.params.modelDescription.orderingField,
        //   this.params.modelDescription.primaryKeyField
        // ].join(',');
        // const valueOrderingValues = this.viewSettings.kanbanBoardSettings.stages.map(stage => stage.value).join(',');
        // const valueOrdering = `${this.viewSettings.kanbanBoardSettings.stageField}-${valueOrderingValues}`;
        //
        // this.modelService.resetOrder(
        //   this.params.modelDescription.modelId,
        //   ordering,
        //   valueOrdering
        // )
        //   .pipe(
        //     switchMap(() => {
        //       if (move.toOrdering == move.fromOrdering) {
        //         return of(false);
        //       }
        //
        //       let forward, segmentFrom, segmentTo;
        //
        //       if (this.itemMove.toOrdering >= this.itemMove.fromOrdering) {
        //         forward = true;
        //         segmentFrom = this.itemMove.fromOrdering + 1;
        //         segmentTo = this.itemMove.toOrdering;
        //       } else {
        //         forward = false;
        //         segmentFrom = this.itemMove.toOrdering;
        //         segmentTo = this.itemMove.fromOrdering - 1;
        //       }
        //
        //       return this.modelService.reorder(
        //         this.params.modelDescription.modelId,
        //         forward,
        //         segmentFrom,
        //         segmentTo,
        //         move.model.primaryKey,
        //         true
        //       );
        //     }),
        //     untilDestroyed(this)
        //   )
        //   .subscribe();
        if (this.settings.cardOrderChangeAction) {
            var model = this.itemMove.model;
            this.actionControllerService
                .execute(this.settings.cardOrderChangeAction, {
                context: this.contextElement.context,
                contextElement: this.contextElement,
                localContext: (_a = {},
                    _a[ORDER_OUTPUT] = this.itemMove.toOrdering.columnIndex,
                    _a[BEFORE_ITEM_OUTPUT] = this.itemMove.toOrdering.beforeItem
                        ? this.itemMove.toOrdering.beforeItem.getAttributes()
                        : undefined,
                    _a[AFTER_ITEM_OUTPUT] = this.itemMove.toOrdering.afterItem
                        ? this.itemMove.toOrdering.afterItem.getAttributes()
                        : undefined,
                    _a[COLUMN_OUTPUT] = toStage,
                    _a[PREV_ORDER_OUTPUT] = this.itemMove.fromOrdering.columnIndex,
                    _a[PREV_BEFORE_ITEM_OUTPUT] = this.itemMove.fromOrdering.beforeItem
                        ? this.itemMove.fromOrdering.beforeItem.getAttributes()
                        : undefined,
                    _a[PREV_AFTER_ITEM_OUTPUT] = this.itemMove.fromOrdering.afterItem
                        ? this.itemMove.fromOrdering.afterItem.getAttributes()
                        : undefined,
                    _a[PREV_COLUMN_OUTPUT] = fromStage,
                    _a[ITEM_OUTPUT] = model.getAttributes(),
                    _a),
                injector: this.injector
            })
                .subscribe();
        }
    };
    Object.defineProperty(KanbanBoardComponent.prototype, "isAvailable", {
        get: function () {
            if (!this.resource || !this.resource.apiInfo) {
                return true;
            }
            var apiInfo = this.resource.apiInfo;
            return apiInfo.isCompatibleJetBridge({ jetBridge: '0.1.5', jetDjango: '0.4.8' }) || apiInfo.customType;
        },
        enumerable: true,
        configurable: true
    });
    KanbanBoardComponent.prototype.initContext = function () {
        var _this = this;
        this.contextElement.setActions([
            {
                uniqueName: 'update_data',
                name: 'Update Data',
                icon: 'repeat',
                parameters: [],
                handler: function () { return _this.reloadData(); }
            },
            {
                uniqueName: 'clear_selected_item',
                name: 'Reset Selected Card',
                icon: 'deselect',
                parameters: [],
                handler: function () {
                    _this.setSelectedItem(undefined);
                    _this.setChecked([]);
                }
            },
            {
                uniqueName: 'clear_filters',
                name: 'Reset Filters',
                icon: 'delete',
                parameters: [],
                handler: function () { return _this.resetFilters(); }
            }
        ]);
    };
    KanbanBoardComponent.prototype.updateContextOutputs = function (state) {
        var stageColumn = state.dataSource
            ? state.dataSource.columns.find(function (item) { return item.name == state.settings.stageField; })
            : undefined;
        var columnOutputs = function (modelDescription, ignoreFlex) {
            if (!state.dataSource) {
                return [];
            }
            return rawListViewSettingsColumnsToViewContextOutputs(state.dataSource.columns.filter(function (item) { return !ignoreFlex || (ignoreFlex && item.type != DisplayFieldType.Computed); }), modelDescription);
        };
        this.contextElement.setOutputs([
            __assign({ uniqueName: COLUMN_FROM_OUTPUT, name: 'Move from Column', icon: 'undo', internal: true, byPathOnly: true }, (stageColumn && {
                fieldType: stageColumn.field,
                fieldParams: stageColumn.params
            })),
            __assign({ uniqueName: COLUMN_TO_OUTPUT, name: 'Move to Column', icon: 'redo', internal: true, byPathOnly: true }, (stageColumn && {
                fieldType: stageColumn.field,
                fieldParams: stageColumn.params
            })),
            {
                uniqueName: ORDER_OUTPUT,
                name: 'Move to Order (1...)',
                icon: 'filter_down',
                internal: true,
                byPathOnly: true,
                fieldType: FieldType.Number
            },
            {
                uniqueName: BEFORE_ITEM_OUTPUT,
                name: 'Move before Card',
                icon: 'arrow_down',
                internal: true,
                byPathOnly: true,
                children: columnOutputs(state.modelDescription)
            },
            {
                uniqueName: AFTER_ITEM_OUTPUT,
                name: 'Move after Card',
                icon: 'arrow_up',
                internal: true,
                byPathOnly: true,
                children: columnOutputs(state.modelDescription)
            },
            __assign({ uniqueName: COLUMN_OUTPUT, name: 'Move to Column', icon: 'versions', internal: true, byPathOnly: true }, (stageColumn && {
                fieldType: stageColumn.field,
                fieldParams: stageColumn.params
            })),
            {
                uniqueName: PREV_ORDER_OUTPUT,
                name: 'Was with Order (1...)',
                icon: 'filter_down',
                internal: true,
                byPathOnly: true,
                fieldType: FieldType.Number
            },
            {
                uniqueName: PREV_BEFORE_ITEM_OUTPUT,
                name: 'Was before Card',
                icon: 'arrow_down',
                internal: true,
                byPathOnly: true,
                children: columnOutputs(state.modelDescription)
            },
            {
                uniqueName: PREV_AFTER_ITEM_OUTPUT,
                name: 'Was after Card',
                icon: 'arrow_up',
                internal: true,
                byPathOnly: true,
                children: columnOutputs(state.modelDescription)
            },
            __assign({ uniqueName: PREV_COLUMN_OUTPUT, name: 'Was in Column', icon: 'versions', internal: true, byPathOnly: true }, (stageColumn && {
                fieldType: stageColumn.field,
                fieldParams: stageColumn.params
            })),
            {
                uniqueName: ITEM_OUTPUT,
                name: 'Current Card',
                icon: 'duplicate_2',
                internal: true,
                byPathOnly: true,
                allowSkip: true,
                children: columnOutputs(state.modelDescription, true)
            },
            {
                uniqueName: SELECTED_ITEM_OUTPUT,
                name: 'Selected Card',
                icon: 'hand',
                children: columnOutputs(state.modelDescription)
            },
            {
                uniqueName: HAS_SELECTED_ITEM_OUTPUT,
                name: 'Is any Card selected',
                icon: 'select_all',
                fieldType: FieldType.Boolean,
                defaultValue: false
            },
            {
                uniqueName: NO_SELECTED_ITEM_OUTPUT,
                name: 'No Card selected',
                icon: 'deselect',
                fieldType: FieldType.Boolean,
                defaultValue: true
            },
            {
                uniqueName: EMPTY_OUTPUT,
                name: 'Is Empty',
                icon: 'uncheck',
                fieldType: FieldType.Boolean,
                defaultValue: false
            }
        ].concat((state.multipleSelection
            ? [
                {
                    uniqueName: CHECKED_ITEMS_OUTPUT,
                    name: 'Checked Cards',
                    icon: 'check',
                    children: columnOutputs(state.modelDescription)
                }
            ]
            : [])));
        this.updateSelectedContext();
    };
    KanbanBoardComponent.prototype.updateVisibleColumns = function (state) {
        this.visibleColumns = state.dataSource.columns.filter(function (item) { return item.visible; });
        this.cd.markForCheck();
    };
    KanbanBoardComponent.prototype.updateSelectedContext = function () {
        var columns = this.settings.dataSource ? this.settings.dataSource.columns : [];
        if (this.selectedItem) {
            this.contextElement.setOutputValue(SELECTED_ITEM_OUTPUT, getModelAttributesByColumns(this.selectedItem.model, columns));
            this.contextElement.setOutputValue(HAS_SELECTED_ITEM_OUTPUT, true);
            this.contextElement.setOutputValue(NO_SELECTED_ITEM_OUTPUT, false);
        }
        else {
            this.contextElement.setOutputValue(SELECTED_ITEM_OUTPUT, undefined);
            this.contextElement.setOutputValue(HAS_SELECTED_ITEM_OUTPUT, false);
            this.contextElement.setOutputValue(NO_SELECTED_ITEM_OUTPUT, true);
        }
        if (this.settings.multipleSelection) {
            var models = values(this.checkedItems);
            this.contextElement.setOutputValue(CHECKED_ITEMS_OUTPUT, getModelBulkAttributesByColumns(models, columns));
        }
        else {
            this.contextElement.setOutputValue(CHECKED_ITEMS_OUTPUT, getModelBulkAttributesByColumns([], columns));
        }
    };
    KanbanBoardComponent.prototype.setSelectedItem = function (item, updateContext) {
        if (updateContext === void 0) { updateContext = true; }
        this.selectedItem = item;
        this.cd.markForCheck();
        if (updateContext) {
            this.updateSelectedContext();
        }
    };
    KanbanBoardComponent.prototype.setChecked = function (value, updateContext) {
        if (updateContext === void 0) { updateContext = true; }
        this.checkedItems = value.reduce(function (acc, item) {
            var pk = item.primaryKey;
            acc[pk] = item;
            return acc;
        }, {});
        this.cd.markForCheck();
        if (updateContext) {
            this.updateSelectedContext();
        }
    };
    KanbanBoardComponent.prototype.onModelUpdated = function (model) {
        if (this.selectedItem && this.selectedItem.model.isSame(model)) {
            this.updateSelectedContext();
        }
        var checkedModels = values(this.checkedItems);
        if (checkedModels.some(function (item) { return item.isSame(model); })) {
            this.updateSelectedContext();
        }
    };
    KanbanBoardComponent.prototype.trackStageFn = function (i, item) {
        return item.uid;
    };
    KanbanBoardComponent.prototype.getAnyModel = function () {
        for (var _i = 0, _a = this.columnComponents.toArray(); _i < _a.length; _i++) {
            var component = _a[_i];
            var items = component.getItems();
            if (items && items.length) {
                return items[0].model;
            }
        }
    };
    return KanbanBoardComponent;
}(ListLayoutComponent));
export { KanbanBoardComponent };

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { combineLatest, Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { PageLoadingService } from '@common/page-loading';
import { DocumentService } from '@core';
import { AdminMode } from '@modules/admin-mode';
import { serializeTokenOptions, TokenOptions } from '@modules/api';
import { AuthService, TOKEN_FRAGMENT_KEY } from '@modules/auth';
import { DomainStore } from '@modules/domain';
import { Environment, Project, ProjectService, ProjectsStore } from '@modules/projects';
import { RoutingService } from '@modules/routing';

export const ROUTE_LOADED_PROJECTS_STORE = 'ROUTE_LOADED_PROJECTS_STORE';

@Injectable({
  providedIn: 'root'
})
export class DomainProjectGuard implements CanActivate {
  constructor(
    private projectsStore: ProjectsStore,
    private domainStore: DomainStore,
    private projectService: ProjectService,
    private authService: AuthService,
    private documentService: DocumentService,
    private routing: RoutingService,
    private pageLoadingService: PageLoadingService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const loader = !this.projectsStore.instance || !this.domainStore.instance ? {} : undefined;

    if (loader) {
      this.pageLoadingService.addLoader(loader);
    }

    const initialProjects = this.projectsStore.instance;

    return combineLatest(this.projectsStore.getFirst(), this.domainStore.getFirst()).pipe(
      switchMap(([projects, domain]) => {
        if (!initialProjects) {
          this.routing.setRouterAttr(ROUTE_LOADED_PROJECTS_STORE, true);
        }

        if (loader) {
          this.pageLoadingService.removeLoader(loader);
        }

        if (domain && domain.whiteLabel && domain.domain) {
          const domainProjects = projects.filter(item => item.isDomain(domain));
          const singleDomainProject = domainProjects.length === 1 ? domainProjects[0] : undefined;

          if (singleDomainProject) {
            const defaultEnvironmentUniqueName = singleDomainProject.defaultEnvironment
              ? singleDomainProject.defaultEnvironment.uniqueName
              : undefined;
            const environment = [
              ...(singleDomainProject.defaultEnvironment ? [singleDomainProject.defaultEnvironment] : []),
              ...singleDomainProject.environments.filter(item => item.uniqueName != defaultEnvironmentUniqueName)
            ].find(item => singleDomainProject.hasEnvironmentPermissions(item));
            const mode =
              singleDomainProject.hasEnvironmentCustomizationPermission(environment) && !this.documentService.isMobile()
                ? AdminMode.Builder
                : AdminMode.App;
            const link = singleDomainProject.getHomeLinkWithProtocol({
              mode: mode,
              environmentName: environment ? environment.uniqueName : undefined
            });

            if (link.href) {
              return this.authService.generateToken().pipe(
                map(externalToken => {
                  link.href = this.getExternalHref(externalToken, link.href);
                  this.routing.navigateLink(link);

                  return false;
                })
              );
            } else {
              this.routing.navigateLink(link);

              return of(false);
            }
          }
        }

        return of(true);
      }),
      catchError(() => {
        if (loader) {
          this.pageLoadingService.removeLoader(loader);
        }

        return of(true);
      })
    );
  }

  getExternalHref(externalToken: TokenOptions, href: string): string {
    if (externalToken) {
      const tokenStr = btoa(JSON.stringify(serializeTokenOptions(externalToken)));
      return `${href}#${TOKEN_FRAGMENT_KEY}=${tokenStr}`;
    } else {
      return href;
    }
  }
}

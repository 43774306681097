/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../view-editor-tools-item/view-editor-tools-item.component.ngfactory";
import * as i2 from "../view-editor-tools-item/view-editor-tools-item.component";
import * as i3 from "../../../projects/stores/current-project.store";
import * as i4 from "../../../projects/stores/current-environment.store";
import * as i5 from "../../services/view-editor-context/view-editor.context";
import * as i6 from "../../../resources/services/project-storage/project-storage.service";
import * as i7 from "@angular/common";
import * as i8 from "./view-editor-tools.component";
import * as i9 from "../../../template/services/template/template.service";
var styles_ViewEditorToolsComponent = [];
var RenderType_ViewEditorToolsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ViewEditorToolsComponent, data: {} });
export { RenderType_ViewEditorToolsComponent as RenderType_ViewEditorToolsComponent };
function View_ViewEditorToolsComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-view-editor-tools-item", [], null, [[null, "openTool"], [null, "closeTool"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("openTool" === en)) {
        var pd_0 = (_co.setToolOpenedIndex(_v.context.index) !== false);
        ad = (pd_0 && ad);
    } if (("closeTool" === en)) {
        var pd_1 = (_co.setToolOpenedIndex(undefined) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i1.View_ViewEditorToolsItemComponent_0, i1.RenderType_ViewEditorToolsItemComponent)), i0.ɵdid(1, 770048, null, 0, i2.ViewEditorToolsItemComponent, [i3.CurrentProjectStore, i4.CurrentEnvironmentStore, i5.ViewEditorContext, i6.ProjectStorageService, i0.Injector, i0.ChangeDetectorRef], { tool: [0, "tool"], opened: [1, "opened"], first: [2, "first"], last: [3, "last"], elementTemplates: [4, "elementTemplates"], elementTemplatesLoading: [5, "elementTemplatesLoading"], viewContext: [6, "viewContext"] }, { openTool: "openTool", closeTool: "closeTool" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit; var currVal_1 = (_co.toolOpenedIndex == _v.context.index); var currVal_2 = _v.context.first; var currVal_3 = _v.context.last; var currVal_4 = _co.elementTemplates; var currVal_5 = _co.elementTemplatesLoading; var currVal_6 = _co.viewContext; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }, null); }
export function View_ViewEditorToolsComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "view-editor-header-segmented-button-container"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ViewEditorToolsComponent_1)), i0.ɵdid(2, 278528, null, 0, i7.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.tools; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_ViewEditorToolsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-view-editor-tools", [], null, null, null, View_ViewEditorToolsComponent_0, RenderType_ViewEditorToolsComponent)), i0.ɵdid(1, 245760, null, 0, i8.ViewEditorToolsComponent, [i5.ViewEditorContext, i9.TemplateService, i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ViewEditorToolsComponentNgFactory = i0.ɵccf("app-view-editor-tools", i8.ViewEditorToolsComponent, View_ViewEditorToolsComponent_Host_0, { viewContext: "viewContext" }, {}, []);
export { ViewEditorToolsComponentNgFactory as ViewEditorToolsComponentNgFactory };

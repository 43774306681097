import { Field } from './field';

export class PublicSettings {
  public signUpFields: Field[] = [];

  deserialize(data: Object): PublicSettings {
    if (data['sign_up_fields']) {
      this.signUpFields = data['sign_up_fields'].map(item => new Field().deserialize(item));
    }

    return this;
  }
}

import { Injectable } from '@angular/core';
import flatten from 'lodash/flatten';
import fromPairs from 'lodash/fromPairs';
import { combineLatest, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { DashboardStore } from '@modules/dashboard';
import { ModelDescriptionStore, ReducedModelService } from '@modules/model-queries';

import { UserActivity } from '../../data/user-activity';
import { UserActivityType } from '../../data/user-activity-type';

@Injectable()
export class UserActivityDisplayService {
  constructor(
    private dashboardStore: DashboardStore,
    private modelDescriptionStore: ModelDescriptionStore,
    private reducedModelService: ReducedModelService
  ) {}

  getActionRelations(userActivity: UserActivity): Observable<Object> {
    const obs: Observable<any>[] = [];

    if (userActivity.activityType == UserActivityType.DashboardList) {
      obs.push(
        this.dashboardStore.getDetailFirst(userActivity.params['id']).pipe(map(result => [['dashboard', result]]))
      );
    } else if ([UserActivityType.ModelList, UserActivityType.ModelDelete].includes(userActivity.activityType)) {
      obs.push(
        this.modelDescriptionStore
          .getDetailFirst(userActivity.params['model'])
          .pipe(map(result => [['model_description', result]]))
      );
    } else if (
      [UserActivityType.ModelDetail, UserActivityType.ModelCreate, UserActivityType.ModelUpdate].includes(
        userActivity.activityType
      )
    ) {
      obs.push(
        this.modelDescriptionStore.getDetailFirst(userActivity.params['model']).pipe(
          switchMap(modelDescription => {
            const subObs = [];

            subObs.push(
              this.reducedModelService.getDetail(
                modelDescription.modelId,
                modelDescription.primaryKeyField,
                userActivity.params['id']
              )
            );

            if (userActivity.activityType == UserActivityType.ModelCreate && userActivity.params['duplicate']) {
              subObs.push(
                this.reducedModelService.getDetail(
                  modelDescription.modelId,
                  modelDescription.primaryKeyField,
                  userActivity.params['duplicate']
                )
              );
            }

            return combineLatest(...subObs).pipe(
              map(([model, duplicate]) => {
                const result = [
                  ['model_description', modelDescription],
                  ['model', model]
                ];

                if (duplicate) {
                  result.push(['duplicate', duplicate]);
                }

                return result;
              })
            );
          })
        )
      );
    }

    if (!obs.length) {
      return of({});
    }

    return combineLatest(...obs).pipe(
      map(result => {
        result = flatten(result);
        return fromPairs(result);
      })
    );
  }
}

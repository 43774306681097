import { Inject, Injectable, NgZone, Optional } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import toPairs from 'lodash/toPairs';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, first, map, skip, switchMap } from 'rxjs/operators';

import { NotificationService } from '@common/notifications';
import { ActionService } from '@modules/action-queries';
import { modelFieldToDisplayField, RawListViewSettingsColumn } from '@modules/customize';
import { DataSourceType, ModelDescriptionDataSource } from '@modules/data-sources';
import { CustomSelectItem, Option } from '@modules/field-components';
import { DisplayField, ParameterField } from '@modules/fields';
import { ModelDescriptionStore, ModelService } from '@modules/model-queries';
import { ModelDescription, ModelFieldType } from '@modules/models';
import {
  InputFieldProviderItem,
  inputFieldProviderItemsFromModelGet,
  inputFieldProviderItemsFromModelGetDetail,
  modelDescriptionHasAutoParameters,
  parametersToProviderItems
} from '@modules/parameters';
import { ProjectSettingsService } from '@modules/project-settings';
import {
  CurrentEnvironmentStore,
  getResourceTypeItemRequestName,
  Resource,
  ResourceName,
  ResourceType,
  resourceTypeItems
} from '@modules/projects';
import { getResourceAddModelComponents } from '@modules/projects-components';
import {
  editableQueryTypes,
  ListModelDescriptionQuery,
  ModelDescriptionQuery,
  QueryService,
  QueryType
} from '@modules/queries';
import { ResourceGeneratorResolver } from '@modules/resource-generators';
import {
  isResourceCollectionCustom,
  isResourceCustom,
  prepareDataSourceColumnForGet,
  ResourceControllerService
} from '@modules/resources';
import { ascComparator, controlValue, isSet, splitmax } from '@shared';

import { DATA_SOURCE_EXTRA_CONTROLS, DataSourceControl } from './data-source.control';

@Injectable()
export class ModelDescriptionDataSourceControl<
  T extends ModelDescriptionDataSource = ModelDescriptionDataSource
> extends DataSourceControl<T> {
  public static instanceCls = ModelDescriptionDataSource;
  resourceFieldParams: Object = { model_resources: true };

  queryOptionEquals = (() => {
    return (lhs: ModelDescriptionQuery, rhs: ModelDescriptionQuery) => {
      const lhsQueryType = lhs ? lhs.queryType : undefined;
      const lhsName = lhs && lhs.simpleQuery ? lhs.simpleQuery.model : undefined;
      const rhsQueryType = rhs ? rhs.queryType : undefined;
      const rhsName = rhs && rhs.simpleQuery ? rhs.simpleQuery.model : undefined;

      return lhsQueryType == rhsQueryType && lhsName == rhsName;
    };
  })();

  constructor(
    protected modelDescriptionStore: ModelDescriptionStore,
    currentEnvironmentStore: CurrentEnvironmentStore,
    projectSettingsService: ProjectSettingsService,
    resourceControllerService: ResourceControllerService,
    resourceGeneratorResolver: ResourceGeneratorResolver,
    modelService: ModelService,
    actionService: ActionService,
    queryService: QueryService,
    notificationService: NotificationService,
    zone: NgZone,
    @Inject(DATA_SOURCE_EXTRA_CONTROLS)
    @Optional()
    extraControls: {
      [key: string]: AbstractControl;
    } = {}
  ) {
    super(
      currentEnvironmentStore,
      projectSettingsService,
      resourceControllerService,
      resourceGeneratorResolver,
      modelService,
      actionService,
      queryService,
      notificationService,
      zone,
      extraControls
    );
  }

  deserialize(instance: T, initial = true) {
    super.deserialize(instance, initial);

    if (initial) {
      this.getModelDescription$()
        .pipe(
          distinctUntilChanged((lhs, rhs) => {
            const lhsModelId = lhs ? lhs.modelId : undefined;
            const rhsModelId = rhs ? rhs.modelId : undefined;
            return lhsModelId === rhsModelId;
          }),
          skip(1),
          untilDestroyed(this)
        )
        .subscribe(value => this.onModelDescriptionChange(value));
    }
  }

  getSimpleQueryDefaultColumns(): Observable<DisplayField[]> {
    return this.getModelDescription$().pipe(
      first(),
      map(modelDescription => this.getModelDescriptionColumns(modelDescription))
    );
  }

  getModelDescriptionColumns(modelDescription: ModelDescription): DisplayField[] {
    if (!modelDescription) {
      return [];
    }

    const resource = this.currentEnvironmentStore.resources.find(item => item.uniqueName == modelDescription.resource);

    if (!resource) {
      return [];
    }

    return modelDescription.fields
      .map(item => modelFieldToDisplayField(item, false))
      .map(item => prepareDataSourceColumnForGet(resource, modelDescription, item));
  }

  onModelDescriptionChange(modelDescription: ModelDescription) {
    const columns = this.getModelDescriptionColumns(modelDescription);

    this.setColumns(columns, { modelDescription: modelDescription });
    this.controls.query_parameters.setValue([]);
  }

  createModelQuery(): ModelDescriptionQuery {
    return new ModelDescriptionQuery();
  }

  getQueryOptionValue(model: ModelDescription): ModelDescriptionQuery {
    const option = this.createModelQuery();

    option.queryType = QueryType.Simple;
    option.simpleQuery = new option.simpleQueryClass();
    option.simpleQuery.model = model.model;

    return option;
  }

  getCustomQueryOption(name: string, queryType: QueryType): Option<ModelDescriptionQuery> {
    const option = this.createModelQuery();

    option.queryType = queryType;

    return {
      value: option,
      name: name,
      icon: 'plus'
    };
  }

  getCustomQueryOptions(resource: Resource): Option<ModelDescriptionQuery>[] {
    const options: Option<ModelDescriptionQuery>[] = [];

    if (isResourceCustom(resource)) {
      const controller = this.resourceControllerService.get(resource.type);
      const queryTypes = controller ? controller.supportedQueryTypes(ModelDescriptionQuery) : undefined;
      const queryType = editableQueryTypes.find(item => queryTypes.includes(item));

      if (queryType) {
        const option = this.getCustomQueryOption(
          `Make ${getResourceTypeItemRequestName(resource.typeItem)}`,
          queryType
        );
        options.push(option);
      }
    }

    if (resource.type == ResourceType.JetBridge || resource.isSynced() || resource.hasCollectionSync()) {
      const controller = this.resourceControllerService.get(ResourceType.JetBridge);
      const typeItem =
        resource.isSynced() || resource.hasCollectionSync()
          ? resourceTypeItems.find(item => item.name == ResourceName.PostgreSQL)
          : resource.typeItem;
      const queryTypes = controller ? controller.supportedQueryTypes(ModelDescriptionQuery) : undefined;
      const queryType = editableQueryTypes.find(item => queryTypes.includes(item));

      if (queryType) {
        const option = this.getCustomQueryOption(`Make ${getResourceTypeItemRequestName(typeItem)}`, queryType);
        options.push(option);
      }
    }

    return options;
  }

  getQueryOptions$(): Observable<CustomSelectItem<ModelDescriptionQuery>[]> {
    return combineLatest(this.getResource$(), this.modelDescriptionStore.get()).pipe(
      map(([resource, modelDescriptions]) => {
        if (!resource) {
          return [];
        }

        const options: CustomSelectItem<ModelDescriptionQuery>[] = [];
        const resourceModels = modelDescriptions
          ? modelDescriptions
              .filter(item => item.resource == resource.uniqueName)
              .filter(item => {
                const query = this.controls.query.value as ModelDescriptionQuery;
                return (
                  !resource.demo ||
                  item.featured ||
                  (query && query.simpleQuery && query.simpleQuery.model == item.model)
                );
              })
          : [];

        if (resourceModels.length) {
          if (resource.typeItem.name == ResourceName.BigQuery) {
            interface DatasetItem {
              item: ModelDescription;
              name: string;
            }

            const datasets = resourceModels
              .sort((lhs, rhs) => ascComparator(lhs.dbTable, rhs.dbTable))
              .reduce<{ [k: string]: DatasetItem[] }>((acc, item) => {
                const [dataset, name] =
                  isSet(item.dbTable) && item.dbTable.includes('.') ? splitmax(item.dbTable, '.', 2) : ['', item.model];

                if (!acc[dataset]) {
                  acc[dataset] = [];
                }

                acc[dataset].push({
                  item: item,
                  name: name
                });

                return acc;
              }, {});

            options.push(
              ...toPairs<DatasetItem[]>(datasets).reduce<CustomSelectItem<ModelDescriptionQuery>[]>(
                (acc, [dataset, items]) => {
                  if (isSet(dataset)) {
                    acc.push({
                      button: {
                        label: dataset,
                        icon: 'folder'
                      },
                      children: items
                        .sort((lhs, rhs) => {
                          return ascComparator(
                            String(lhs.item.verboseNamePlural).toLowerCase(),
                            String(rhs.item.verboseNamePlural).toLowerCase()
                          );
                        })
                        .map(item => {
                          return {
                            option: {
                              value: this.getQueryOptionValue(item.item),
                              name: item.item.verboseNamePlural,
                              icon: 'document'
                            },
                            valueTag: item.item.demo ? 'DEMO' : undefined
                          };
                        })
                    });
                  } else {
                    acc.push(
                      ...items
                        .sort((lhs, rhs) => {
                          return ascComparator(
                            String(lhs.item.verboseNamePlural).toLowerCase(),
                            String(rhs.item.verboseNamePlural).toLowerCase()
                          );
                        })
                        .map(item => {
                          return {
                            option: {
                              value: this.getQueryOptionValue(item.item),
                              name: item.item.verboseNamePlural,
                              icon: 'document'
                            },
                            valueTag: item.item.demo ? 'DEMO' : undefined
                          };
                        })
                    );
                  }

                  return acc;
                },
                []
              )
            );
          } else {
            options.push(
              ...resourceModels
                .sort((lhs, rhs) => {
                  return ascComparator(
                    String(lhs.verboseNamePlural).toLowerCase(),
                    String(rhs.verboseNamePlural).toLowerCase()
                  );
                })
                .map(item => {
                  return {
                    option: {
                      value: this.getQueryOptionValue(item),
                      name: item.verboseNamePlural,
                      icon: 'document'
                    },
                    valueTag: item.demo ? 'DEMO' : undefined
                  };
                })
            );
          }
        }

        const addModelComponents = !resource.demo ? getResourceAddModelComponents(resource.typeItem.name) : [];

        options.push(
          ...addModelComponents.map(item => {
            return {
              button: {
                name: 'add_model',
                label: item.label,
                icon: item.icon,
                data: {
                  addModelComponent: item
                }
              },
              stickyBottom: true,
              orange: true,
              large: true
            };
          })
        );

        const noCustomQueryData = resource.type == ResourceType.JetBridge && !resourceModels.length;

        if (!noCustomQueryData) {
          options.push(
            ...this.getCustomQueryOptions(resource).map(item => {
              return {
                option: item,
                valueIcon: null,
                stickyBottom: true,
                orange: true,
                large: true
              };
            })
          );
        }

        return options;
      })
    );
  }

  getModelDescription$(): Observable<ModelDescription> {
    return combineLatest(
      controlValue<DataSourceType>(this.controls.type),
      controlValue<string>(this.controls.query_resource),
      controlValue<ModelDescriptionQuery>(this.controls.query)
    ).pipe(
      switchMap(([type, resource, query]) => {
        if (type != DataSourceType.Query || !query || !query.simpleQuery) {
          return of(undefined);
        }

        const modelId = [resource, query.simpleQuery.model].join('.');
        return this.modelDescriptionStore.getDetailFirst(modelId);
      })
    );
  }

  getModelDescriptionCustom$(): Observable<ModelDescription> {
    return combineLatest(this.getResource$(), this.getModelDescription$()).pipe(
      map(([resource, modelDescription]) => {
        if (isResourceCollectionCustom(resource, modelDescription)) {
          return modelDescription;
        }
      })
    );
  }

  getInputFieldProvider$(): Observable<InputFieldProviderItem[]> {
    return combineLatest(
      controlValue<DataSourceType>(this.controls.type),
      this.getResource$(),
      controlValue<ModelDescriptionQuery>(this.controls.query),
      this.getModelDescription$(),
      controlValue<ParameterField[]>(this.controls.query_parameters),
      controlValue<RawListViewSettingsColumn[]>(this.controls.columns)
    ).pipe(
      debounceTime(60),
      map(([type, resource, query, modelDescription, parameters, columns]): InputFieldProviderItem[] => {
        if (
          modelDescription &&
          modelDescription.getDetailQuery &&
          !(modelDescriptionHasAutoParameters(resource, modelDescription) && !modelDescription.virtual)
        ) {
          return inputFieldProviderItemsFromModelGetDetail(resource, modelDescription, parameters);
        } else {
          return [
            ...parametersToProviderItems(parameters),
            ...inputFieldProviderItemsFromModelGet(
              resource,
              modelDescription,
              modelDescription ? modelDescription.getQuery : (query as ListModelDescriptionQuery),
              columns,
              type
            )
          ];
        }

        // if (
        //   query &&
        //   query.queryType == QueryType.Simple &&
        //   modelDescription &&
        //   modelDescription.getDetailQuery &&
        //   !(resourceHasAutoParameters(resource) && !modelDescription.virtual)
        // ) {
        //   return inputFieldProviderItemsFromModelGetDetail(resource, modelDescription, parameters);
        // } else {
        //   return [
        //     ...parametersToProviderItems(parameters),
        //     ...autoInputFieldProviderItemsFromModelGet(resource, modelDescription, query, columns)
        //   ];
        // }
      })
    );
  }

  onResourceReloaded(): Observable<void> {
    return this.reloadColumns();
  }

  reloadColumns(): Observable<void> {
    return this.getModelDescription$().pipe(
      map(modelDescription => {
        const resource = modelDescription
          ? this.currentEnvironmentStore.resources.find(item => item.uniqueName == modelDescription.resource)
          : undefined;
        const columns =
          resource && modelDescription
            ? modelDescription.fields
                .filter(item => item.type == ModelFieldType.Db)
                .map(item => modelFieldToDisplayField(item))
                .map(item => prepareDataSourceColumnForGet(resource, modelDescription, item))
            : [];
        this.mergeColumns(columns);
      })
    );
  }

  reset(options?: { onlySelf?: boolean; emitEvent?: boolean }) {
    this.controls.type.patchValue(DataSourceType.Query, options);
    this.controls.query_resource.patchValue('', options);
    this.controls.query.patchValue(null, options);
    this.controls.query_parameters.patchValue([], options);
    this.controls.query_inputs.patchValue([], options);
    this.controls.input.patchValue({ name: 'value' }, options);
    this.controls.columns.patchValue([], options);
  }
}

<ng-template #content>
  <ng-content></ng-content>
</ng-template>

<ng-template #footer_content>
  <ng-content select="[data-layout-footer]"></ng-content>
</ng-template>

<app-poster-layout
  *ngIf="layout == 'poster_left' || layout == 'poster_right'"
  [left]="layout == 'poster_left'"
  [backgroundColor]="backgroundColor"
  [backgroundImage]="backgroundImage"
  [fillScreen]="fillScreen"
  [preview]="preview"
>
  <ng-container *ngTemplateOutlet="content"></ng-container>
  <ng-container *ngTemplateOutlet="footer_content" data-layout-footer></ng-container>
</app-poster-layout>

<app-form-layout
  *ngIf="layout == 'form'"
  [backgroundColor]="backgroundColor"
  [backgroundImage]="backgroundImage"
  [fillScreen]="fillScreen"
  [preview]="preview"
>
  <ng-container *ngTemplateOutlet="content"></ng-container>
  <ng-container *ngTemplateOutlet="footer_content" data-layout-footer></ng-container>
</app-form-layout>

<app-background-layout
  *ngIf="layout == 'background'"
  [backgroundColor]="backgroundColor"
  [fillScreen]="fillScreen"
  [preview]="preview"
>
  <ng-container *ngTemplateOutlet="content"></ng-container>
  <ng-container *ngTemplateOutlet="footer_content" data-layout-footer></ng-container>
</app-background-layout>

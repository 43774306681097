import { FormControl, FormGroup, Validators } from '@angular/forms';
import assign from 'lodash/assign';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { delay, distinctUntilChanged, map, skip } from 'rxjs/operators';
import { lookups, serializeFieldParamName } from '@modules/field-lookups';
import { defaultFieldType, getFieldDescriptionByType, InputValueType } from '@modules/fields';
import { FilterItem } from '@modules/filters';
import { controlValue, isSet } from '@shared';
export var validateLookupValue = function (control) {
    var lookup = control.value['lookup'];
    var lookupValue = control.value['value'];
    if (lookup && lookup.field && !isSet(lookupValue)) {
        return { required: true };
    }
};
var AddFilterPopupForm = /** @class */ (function () {
    function AddFilterPopupForm() {
        this.columns = [];
        this.parameters = [];
        this.form = new FormGroup({
            field: new FormControl('', Validators.required),
            lookup: new FormControl(undefined),
            value: new FormControl('')
        }, validateLookupValue);
        this.fieldOptions$ = new BehaviorSubject([]);
    }
    AddFilterPopupForm.prototype.init = function (field, columns, parameters, inputs, filterItem) {
        var _this = this;
        this.field = field;
        this.columns = columns;
        this.parameters = parameters;
        this.inputs = inputs;
        this.form = new FormGroup({
            field: new FormControl(this.field, Validators.required),
            lookup: new FormControl(undefined),
            value: new FormControl('')
        }, validateLookupValue);
        var input = filterItem
            ? this.inputs.find(function (i) {
                var name = filterItem.getName();
                if (i.valueType == InputValueType.Filter) {
                    var itemName = serializeFieldParamName(i.filterField, i.filterLookup, false);
                    return itemName == name;
                }
                else if (i.name == name) {
                    return true;
                }
            })
            : undefined;
        if (filterItem && input) {
            var lookup = this.getFieldLookups(input.filterField).find(function (item) { return item.type.lookup === input.filterLookup; });
            var fieldDescription = lookup ? getFieldDescriptionByType(lookup.field) : undefined;
            var value = filterItem.value;
            if (fieldDescription && fieldDescription.deserializeValue) {
                value = fieldDescription.deserializeValue(value);
            }
            this.form.patchValue({
                field: input.filterField,
                lookup: lookup,
                value: value
            });
        }
        else {
            this.form.patchValue({
                field: this.field
            });
            this.setValueDefault();
        }
        this.updateFieldOptions();
        this.lookups$.subscribe(function (fieldLookups) {
            var lookup = fieldLookups.find(function (item) { return _this.form.value['lookup'] && item.type.lookup === _this.form.value['lookup'].type.lookup; });
            _this.form.patchValue({
                lookup: lookup || fieldLookups[0]
            });
        });
        controlValue(this.form.controls['lookup'])
            .pipe(distinctUntilChanged(), skip(1), delay(0))
            .subscribe(function () { return _this.setValueDefault(); });
    };
    AddFilterPopupForm.prototype.setValueDefault = function () {
        var lookup = this.form.controls['lookup'].value;
        var fieldType = lookup ? lookup.field : defaultFieldType;
        var fieldDescription = getFieldDescriptionByType(fieldType);
        this.form.patchValue({ value: fieldDescription.defaultValue });
    };
    AddFilterPopupForm.prototype.updateFieldOptions = function () {
        var _this = this;
        this.fieldOptions$.next(this.columns
            .filter(function (item) {
            return _this.inputs.find(function (input) { return input.valueType == InputValueType.Filter && input.filterField == item.name; });
        })
            .map(function (item) {
            return {
                value: item.name,
                name: item.verboseName || item.name
            };
        }));
    };
    Object.defineProperty(AddFilterPopupForm.prototype, "lookups$", {
        get: function () {
            var _this = this;
            return controlValue(this.form.controls['field']).pipe(map(function (fieldName) { return _this.getFieldLookups(fieldName); }));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AddFilterPopupForm.prototype, "valueField$", {
        get: function () {
            return controlValue(this.form.controls['lookup']).pipe(map(function (lookup) { return (lookup ? lookup.field : undefined); }));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AddFilterPopupForm.prototype, "valueFieldParams$", {
        get: function () {
            var _this = this;
            return combineLatest(controlValue(this.form.controls['field']), controlValue(this.form.controls['lookup'])).pipe(map(function (_a) {
                var fieldName = _a[0], lookup = _a[1];
                if (!fieldName) {
                    return {};
                }
                if (lookup) {
                    var params_1 = {};
                    var input_1 = _this.inputs.find(function (i) {
                        return i.valueType == InputValueType.Filter &&
                            i.filterField == fieldName &&
                            i.filterLookup === (lookup ? lookup.type.lookup : undefined);
                    });
                    var column_1 = input_1 ? _this.columns.find(function (item) { return item.name == input_1.filterField; }) : undefined;
                    var parameter = input_1 ? _this.parameters.find(function (item) { return item.name == input_1.name; }) : undefined;
                    if (parameter) {
                        assign(params_1, parameter.params);
                    }
                    if (lookup.fieldParams && column_1) {
                        lookup.fieldParams.forEach(function (param) { return (params_1[param] = column_1.params[param]); });
                    }
                    if (lookup.extraParams) {
                        assign(params_1, lookup.extraParams);
                    }
                    return params_1;
                }
                else {
                    return {};
                }
            }));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AddFilterPopupForm.prototype, "valueFieldLookup$", {
        get: function () {
            return controlValue(this.form.controls['lookup']).pipe(map(function (lookup) {
                if (!lookup) {
                    return;
                }
                return lookups.find(function (item) { return item === lookup.type; });
            }));
        },
        enumerable: true,
        configurable: true
    });
    AddFilterPopupForm.prototype.getFieldLookups = function (fieldName) {
        var field = this.columns.find(function (item) { return item.name == fieldName; });
        var enabledLookups = this.inputs
            .filter(function (input) { return input.valueType == InputValueType.Filter && input.filterField == fieldName; })
            .map(function (input) { return input.filterLookup; });
        var fieldDescription = field ? getFieldDescriptionByType(field.field) : undefined;
        return fieldDescription ? fieldDescription.lookups.filter(function (item) { return enabledLookups.includes(item.type.lookup); }) : [];
    };
    AddFilterPopupForm.prototype.submit = function (filter) {
        var value = this.form.value;
        var result = new FilterItem();
        var lookup = value['lookup'];
        var input = this.inputs.find(function (item) {
            return item.valueType == InputValueType.Filter &&
                item.filterField == value['field'] &&
                item.filterLookup === (value['lookup'] ? value['lookup'].type.lookup : undefined);
        });
        if (!input) {
            return;
        }
        result.field = input.filterField;
        result.lookup = lookup.type;
        if (lookup && !lookup.field) {
            result.value = true;
        }
        else {
            result.value = value['value'];
            var fieldDescription = lookup ? getFieldDescriptionByType(lookup.field) : undefined;
            if (fieldDescription && fieldDescription.serializeValue) {
                result.value = fieldDescription.serializeValue(result.value, value['value']);
            }
        }
        result.exclude = !filter;
        return result;
    };
    return AddFilterPopupForm;
}());
export { AddFilterPopupForm };

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./popup2-side.component";
var styles_Popup2SideComponent = [];
var RenderType_Popup2SideComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_Popup2SideComponent, data: {} });
export { RenderType_Popup2SideComponent as RenderType_Popup2SideComponent };
export function View_Popup2SideComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 9, "div", [["class", "resource-popup__wrap"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "div", [["class", "resource-popup__left"]], null, null, null, null, null)), i0.ɵncd(null, 0), (_l()(), i0.ɵeld(3, 0, null, null, 6, "div", [["class", "resource-popup__right"]], [[2, "resource-popup__right_visible", null]], null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 5, "div", [["class", "resource-popup__banner"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 2, "div", [["class", "resource-popup__banner-background"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 0, "div", [["class", "resource-popup__banner-circle resource-popup__banner-circle_one"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 0, "div", [["class", "resource-popup__banner-circle resource-popup__banner-circle_two"]], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, [["content", 1]], null, 1, "div", [["class", "resource-popup__banner-content"]], null, null, null, null, null)), i0.ɵncd(null, 1)], null, function (_ck, _v) { var currVal_0 = (i0.ɵnov(_v, 8).children.length > 0); _ck(_v, 3, 0, currVal_0); }); }
export function View_Popup2SideComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-popup2-side", [], null, null, null, View_Popup2SideComponent_0, RenderType_Popup2SideComponent)), i0.ɵdid(1, 114688, null, 0, i1.Popup2SideComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var Popup2SideComponentNgFactory = i0.ɵccf("app-popup2-side", i1.Popup2SideComponent, View_Popup2SideComponent_Host_0, {}, {}, ["*", "[data-side]"]);
export { Popup2SideComponentNgFactory as Popup2SideComponentNgFactory };

import { Injectable } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { delayWhen } from 'rxjs/operators';

import { Dashboard, DashboardService, DashboardStore } from '@modules/dashboard';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';

@Injectable()
export class AddDashboardForm {
  form: FormGroup;

  constructor(
    private fb: FormBuilder,
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private dashboardStore: DashboardStore,
    private dashboardService: DashboardService
  ) {
    this.form = this.fb.group({
      label: new FormControl('', Validators.required)
    });
  }

  submit(): Observable<Dashboard> {
    const value = this.form.value;
    const dashboard = new Dashboard();

    dashboard.project = this.currentProjectStore.instance.uniqueName;
    dashboard.name = value['label'];
    dashboard.ordering = this.dashboardStore.instance.length + 1;

    return this.dashboardService
      .create(this.currentProjectStore.instance.uniqueName, this.currentEnvironmentStore.instance.uniqueName, dashboard)
      .pipe(delayWhen(() => this.dashboardStore.getFirst(true)));
  }
}

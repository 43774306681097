<app-field-wrapper
  *ngIf="!readonly"
  [formGroup]="form"
  [form]="form"
  [field]="field"
  [readonly]="readonly"
  [requiredAsterisk]="requiredAsterisk"
  [label]="label"
  [errors]="errors"
  [truncate]="truncate"
  [manualMargin]="manualMargin"
  [labelButtons]="labelButtons"
  [tooltip]="tooltip"
  [idToken]="idToken"
>
  <div *ngIf="control" class="input-icon-wrapper">
    <input
      class="input"
      [class.input_error]="(form | appFormFieldErrors: field.name)?.length"
      [class.input_wide]="field.params['wide']"
      [class.input_icon-right]="field.resetEnabled"
      [ngClass]="field.params['classes']"
      type="password"
      [appAutofocus]="autofocus"
      [formControlName]="field.name"
      [placeholder]="field.placeholder || ''"
      [id]="field.name | appUniqueId: idToken"
    />

    <a
      *ngIf="field.resetEnabled && (control.value | appIsSet)"
      href="javascript:void(0)"
      class="input-icon input-icon_interactive input-icon_right icon-close"
      [class.input-icon_small]="inputSmall"
      (click)="resetCurrentValue()"
    ></a>
  </div>
</app-field-wrapper>

<app-field-wrapper
  *ngIf="readonly"
  [form]="form"
  [field]="field"
  [readonly]="readonly"
  [requiredAsterisk]="requiredAsterisk"
  [label]="label"
  [errors]="errors"
  [truncate]="truncate"
  [manualMargin]="manualMargin"
  [labelButtons]="labelButtons"
  [tooltip]="tooltip"
  [idToken]="idToken"
>
  {{ formatEmpty(currentValue) }}
</app-field-wrapper>

import { ComponentPortal, DomPortalHost } from '@angular/cdk/portal';
import { ApplicationRef, ComponentFactoryResolver, ComponentRef, ElementRef, Injector, OnChanges, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import defaults from 'lodash/defaults';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest, fromEvent, merge, Subject, timer } from 'rxjs';
import { debounce, distinctUntilChanged, map } from 'rxjs/operators';
import { isSet } from '@shared';
import { defaultTipOptions, TipComponent } from '../../components/tip/tip.component';
var TipDirective = /** @class */ (function () {
    function TipDirective(el, resolver, injector, appRef) {
        this.el = el;
        this.resolver = resolver;
        this.injector = injector;
        this.appRef = appRef;
        this.options = defaultTipOptions;
        this.subscriptions = [];
        this.tipHover$ = new BehaviorSubject(false);
        this.setOriginHover = new Subject();
    }
    Object.defineProperty(TipDirective.prototype, "optionsRaw", {
        set: function (options) {
            this.options = defaults(options, defaultTipOptions);
        },
        enumerable: true,
        configurable: true
    });
    TipDirective.prototype.ngOnInit = function () {
        var _this = this;
        var originHover$ = merge(fromEvent(this.el.nativeElement, 'mouseenter').pipe(map(function () { return true; })), fromEvent(this.el.nativeElement, 'mouseleave').pipe(map(function () { return false; })), this.setOriginHover).pipe(debounce(function (hover) {
            var defaultShowDelay = 0;
            var defaultHideDelay = _this.options.hoverable ? 100 : 0;
            var showDelay = _this.options.showDelay || defaultShowDelay;
            var hideDelay = _this.options.hideDelay || defaultHideDelay;
            return timer(hover ? showDelay : hideDelay);
        }));
        combineLatest(originHover$, this.tipHover$)
            .pipe(map(function (_a) {
            var hover = _a[0], tipHover = _a[1];
            return hover || tipHover;
        }), distinctUntilChanged(), untilDestroyed(this))
            .subscribe(function (hover) {
            if (hover) {
                _this.show();
            }
            else {
                _this.hide();
            }
        });
    };
    TipDirective.prototype.ngOnDestroy = function () {
        this.destroy();
    };
    TipDirective.prototype.ngOnChanges = function (changes) {
        if (changes.content && !changes.content.firstChange) {
            if (isSet(this.content)) {
                if (this.componentRef) {
                    this.componentRef.instance.setContent(this.content);
                }
            }
            else {
                this.hide();
            }
        }
    };
    TipDirective.prototype.show = function () {
        var _this = this;
        this.destroy();
        if (!isSet(this.content)) {
            return;
        }
        var portalHost = new DomPortalHost(document.body, this.resolver, this.appRef, this.injector);
        var portal = new ComponentPortal(TipComponent);
        var componentRef = portalHost.attach(portal);
        var subscriptions = [];
        componentRef.instance.content = this.content;
        componentRef.instance.options = this.options;
        componentRef.instance.origin = this.origin || this.el.nativeElement;
        if (this.options.hoverable) {
            subscriptions.push(merge(fromEvent(componentRef.instance.root.nativeElement, 'mouseenter').pipe(map(function () { return true; })), fromEvent(componentRef.instance.root.nativeElement, 'mouseleave').pipe(map(function () { return false; })))
                .pipe(debounce(function (hover) { return timer(hover ? 0 : 100); }), untilDestroyed(this))
                .subscribe(function (hover) {
                _this.tipHover$.next(hover);
            }));
        }
        else {
            this.tipHover$.next(false);
        }
        subscriptions.push(componentRef.instance.closed.pipe(untilDestroyed(this)).subscribe(function () {
            componentRef.destroy();
            subscriptions.forEach(function (item) { return item.unsubscribe(); });
            if (_this.componentRef === componentRef) {
                _this.componentRef = undefined;
                _this.subscriptions = undefined;
            }
        }));
        this.componentRef = componentRef;
        this.subscriptions = subscriptions;
        if (this.options.observeRemoveFromDom) {
            this.initObserver();
        }
    };
    TipDirective.prototype.hide = function () {
        if (!this.componentRef) {
            return;
        }
        this.componentRef.instance.close();
    };
    TipDirective.prototype.destroy = function () {
        if (!this.componentRef) {
            return;
        }
        this.componentRef.destroy();
        this.destroyObserver();
        this.subscriptions.forEach(function (item) { return item.unsubscribe(); });
        this.componentRef = undefined;
        this.subscriptions = undefined;
    };
    TipDirective.prototype.initObserver = function () {
        var _this = this;
        this.destroyObserver();
        this.observer = new MutationObserver(function (mutations) {
            mutations.forEach(function (mutation) {
                var nodes = Array.from(mutation.removedNodes);
                if (nodes.includes(_this.el.nativeElement) || nodes.some(function (parent) { return parent.contains(_this.el.nativeElement); })) {
                    _this.setOriginHover.next(false);
                    _this.tipHover$.next(false);
                    _this.hide();
                }
            });
        });
        this.observer.observe(document.body, { subtree: true, childList: true });
    };
    TipDirective.prototype.destroyObserver = function () {
        if (this.observer) {
            this.observer.disconnect();
            this.observer = undefined;
        }
    };
    return TipDirective;
}());
export { TipDirective };

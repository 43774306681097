<form [formGroup]="form.form" (ngSubmit)="submit()" style="width: 100%; max-width: 720px;">
  <div class="title">Copy Interface Settings</div>

  <app-page-block>
    <div class="field">
      <label class="field__label" for="project">Source App</label>
      <gxs-select
        id="project"
        formControlName="project_copy"
        [source]="source"
        [options]="{
          theme: 'jet',
          search: true,
          searchMinimumLength: 2,
          searchDebounce: 200,
          labels: {} | localizeSelect
        }"
      ></gxs-select>
    </div>
  </app-page-block>

  <!--  <ng-container *ngIf="form.form.value['project_copy']">-->
  <!--    <div class="description">Select interface settings you want to copy</div>-->

  <!--    <app-select-user-interface-->
  <!--      formControlName="interface_settings"-->
  <!--      [project]="form.form.value['project_copy']"-->
  <!--      [environment]="form.form.value['project_copy'].defaultEnvironment"-->
  <!--    ></app-select-user-interface>-->

  <!--    <div class="description">-->
  <!--      <strong>Warning!</strong> The selected interface settings will be will be copied and replaced in your app-->
  <!--    </div>-->
  <!--  </ng-container>-->

  <div class="change__submit">
    <button
      type="submit"
      class="button button_primary button_danger"
      [disabled]="form.form.invalid || form.form.disabled"
      style="margin-right: 10px;"
    >
      <span class="button__label">Submit</span>
    </button>
    <a href="javascript:void(0)" class="button" (click)="cancel()">
      <span class="button__label">Cancel</span>
    </a>
  </div>
</form>

import { ResourceDeploy } from './resource-deploy';
import { resourceTypeItems } from './resource-type-items';

export interface ResourceDeployType {
  name: string;
  label: string;
  image: string;
  tag?: {
    label: string;
    color: string;
  };
}

export const resourceDeployTypes: ResourceDeployType[] = [
  {
    name: ResourceDeploy.Direct,
    label: 'Without <br>Installation',
    image: 'api',
    tag: {
      label: 'simple',
      color: 'blue'
    }
  },
  {
    name: ResourceDeploy.Docker,
    label: 'Install with <br>Docker',
    image: 'docker'
    // tag: {
    //   label: 'recommended',
    //   color: 'blue'
    // }
  },
  {
    name: ResourceDeploy.Heroku,
    label: 'Run in Cloud <br>Heroku',
    image: 'heroku'
  },
  {
    name: ResourceDeploy.Python,
    label: 'Install <br>Python App',
    image: 'application'
  }
];

export function resourceTypeItemDeployTypes(name: string) {
  const typeItem = resourceTypeItems.find(item => item.name == name);
  return typeItem
    ? resourceDeployTypes.filter(item => typeItem.deployTypes && typeItem.deployTypes.includes(item.name))
    : [];
}

<div class="sidebar__element">
  <app-sidebar-field [label]="'Modal'">
    <mat-menu #queue_dropdown="matMenu">
      <ng-template matMenuContent>
        <button
          *ngFor="let item of popupOptions"
          mat-menu-item
          [disableRipple]="true"
          (click)="form.controls.close_popup.patchValue(item.value)"
        >
          <span *ngIf="item.icon" class="choose-items-item__dropdown-icon" [ngClass]="'icon-' + item.icon"></span>
          {{ item.name }}
        </button>
        <div *ngIf="!popupOptions.length" class="mat-menu-item-message">
          Modals not found
        </div>
      </ng-template>
    </mat-menu>

    <div class="select select_theme_jet select_fill" [matMenuTriggerFor]="queue_dropdown" #trigger="matMenuTrigger">
      <a href="javascript:void(0)" class="select__input" [class.select__input_opened]="trigger.menuOpen">
        <span
          *ngIf="selectedPopupOption?.icon"
          class="select__input-icon"
          [ngClass]="['icon-' + selectedPopupOption.icon]"
        ></span>
        <span class="select__input-label">
          <ng-container *ngIf="selectedPopupOption">{{ selectedPopupOption?.name }}</ng-container>
          <ng-container *ngIf="!selectedPopupOption">Choose Modal</ng-container>
        </span>
        <span class="select__input-arrow"></span>
      </a>
    </div>
  </app-sidebar-field>
</div>

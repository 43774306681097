/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./default-element.component";
var styles_DefaultElementComponent = [];
var RenderType_DefaultElementComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_DefaultElementComponent, data: {} });
export { RenderType_DefaultElementComponent as RenderType_DefaultElementComponent };
export function View_DefaultElementComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵted(-1, null, ["Unknown component\n"]))], null, null); }
export function View_DefaultElementComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-default-element", [], null, null, null, View_DefaultElementComponent_0, RenderType_DefaultElementComponent)), i0.ɵdid(1, 114688, null, 0, i1.DefaultElementComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DefaultElementComponentNgFactory = i0.ɵccf("app-default-element", i1.DefaultElementComponent, View_DefaultElementComponent_Host_0, { element: "element", elementActive: "elementActive", context: "context", visible: "visible", actionClicked: "actionClicked", accentColor: "accentColor" }, { updated: "updated", replaceRequested: "replaceRequested", deleteRequested: "deleteRequested" }, []);
export { DefaultElementComponentNgFactory as DefaultElementComponentNgFactory };

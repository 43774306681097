import { BehaviorSubject, combineLatest, EMPTY, of, throwError } from 'rxjs';
import { catchError, filter, map, share, switchMap, tap } from 'rxjs/operators';
import { ServerRequestError } from '@modules/api';
import { isSet } from '../utils/common/common';
var DEFAULT_PER_PAGE = 20;
var ListStore = /** @class */ (function () {
    function ListStore() {
        var _this = this;
        this._loading = new BehaviorSubject(false);
        this._items = new BehaviorSubject(undefined);
        this._fetchItems = new BehaviorSubject(undefined);
        this._currentPageImmediate = new BehaviorSubject(1);
        this._currentPage = new BehaviorSubject(1);
        this._fetchHasMore = new BehaviorSubject(true);
        this._fromPage = new BehaviorSubject(1);
        this._nextPage = new BehaviorSubject(1);
        this._fetchNextPage = new BehaviorSubject(1);
        this._fetchTotalPages = new BehaviorSubject(0);
        this._count = new BehaviorSubject(undefined);
        this._perPage = new BehaviorSubject(DEFAULT_PER_PAGE);
        this.prevPageCursors = {};
        this.nextPageCursors = {};
        this.hasMore$ = combineLatest(this._items, this._fetchItems, this._fetchHasMore).pipe(map(function (_a) {
            var items = _a[0], fetchItems = _a[1], fetchHasMore = _a[2];
            return _this.getHasMore(items, fetchItems, fetchHasMore);
        }));
        this.totalPages$ = combineLatest(this._count, this._perPage).pipe(map(function (_a) {
            var count = _a[0], perPage = _a[1];
            return _this.getTotalPages(count, perPage);
        }));
    }
    ListStore.prototype.ngOnDestroy = function () {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    };
    Object.defineProperty(ListStore.prototype, "hasMore", {
        get: function () {
            return this.getHasMore(this._items.value, this._fetchItems.value, this._fetchHasMore.value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ListStore.prototype, "totalPages", {
        get: function () {
            return this.getTotalPages(this._count.value, this._perPage.value);
        },
        enumerable: true,
        configurable: true
    });
    ListStore.prototype.getHasMore = function (items, fetchItems, fetchHasMore) {
        var displayCount = items ? items.length : 0;
        var fetchedCount = fetchItems ? fetchItems.length : 0;
        return fetchedCount > displayCount || fetchHasMore;
    };
    ListStore.prototype.getTotalPages = function (count, perPage) {
        if (count === undefined || perPage === undefined) {
            return;
        }
        return Math.ceil(count / perPage);
    };
    Object.defineProperty(ListStore.prototype, "loading$", {
        get: function () {
            return this._loading.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ListStore.prototype, "loading", {
        get: function () {
            return this._loading.value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ListStore.prototype, "items$", {
        get: function () {
            return this._items.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ListStore.prototype, "items", {
        get: function () {
            return this._items.value;
        },
        set: function (value) {
            this._items.next(value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ListStore.prototype, "fetchItems$", {
        get: function () {
            return this._fetchItems.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ListStore.prototype, "fetchItems", {
        get: function () {
            return this._fetchItems.value;
        },
        set: function (value) {
            this._fetchItems.next(value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ListStore.prototype, "currentPage$", {
        get: function () {
            return this._currentPage.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ListStore.prototype, "currentPage", {
        get: function () {
            return this._currentPage.value;
        },
        set: function (value) {
            this._currentPage.next(value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ListStore.prototype, "currentPageImmediate$", {
        get: function () {
            return this._currentPageImmediate.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ListStore.prototype, "currentPageImmediate", {
        get: function () {
            return this._currentPageImmediate.value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ListStore.prototype, "fromPage$", {
        get: function () {
            return this._fromPage.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ListStore.prototype, "fromPage", {
        get: function () {
            return this._fromPage.value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ListStore.prototype, "fetchHasMore", {
        get: function () {
            return this._fetchHasMore.value;
        },
        set: function (value) {
            this._fetchHasMore.next(value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ListStore.prototype, "nextPage", {
        get: function () {
            return this._nextPage.value;
        },
        set: function (value) {
            this._nextPage.next(value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ListStore.prototype, "fetchNextPage", {
        get: function () {
            return this._fetchNextPage.value;
        },
        set: function (value) {
            this._fetchNextPage.next(value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ListStore.prototype, "fetchTotalPages", {
        get: function () {
            return this._fetchTotalPages.value;
        },
        set: function (value) {
            this._fetchTotalPages.next(value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ListStore.prototype, "count$", {
        get: function () {
            return this._count.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ListStore.prototype, "count", {
        get: function () {
            return this._count.value;
        },
        set: function (value) {
            this._count.next(value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ListStore.prototype, "perPage$", {
        get: function () {
            return this._perPage.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ListStore.prototype, "perPage", {
        get: function () {
            return this._perPage.value;
        },
        set: function (value) {
            value = Number(value);
            if (isNaN(value)) {
                value = undefined;
            }
            if (isSet(value) && value > 0) {
                value = Math.floor(value);
                this._perPage.next(value);
            }
            else {
                this._perPage.next(DEFAULT_PER_PAGE);
            }
        },
        enumerable: true,
        configurable: true
    });
    ListStore.prototype.getNext = function () {
        var _this = this;
        if (!this.hasMore || this.loading) {
            return of([]);
        }
        var displayCount = this._items.value ? this._items.value.length : 0;
        var fetchedCount = this._fetchItems.value ? this._fetchItems.value.length : 0;
        var perPage = this._perPage.value;
        var currentPage = this.currentPage;
        var nextPage = this._nextPage.value;
        var fetchNextPage = this.fetchNextPage;
        this._currentPageImmediate.next(currentPage);
        if (fetchedCount - displayCount >= perPage) {
            var fetchedResult = this._fetchItems.value
                ? this._fetchItems.value.slice(displayCount, displayCount + perPage)
                : [];
            this.appendItems(fetchedResult);
            this._currentPage.next(nextPage);
            this._nextPage.next(nextPage + 1);
            return of(fetchedResult);
        }
        if (this.subscription) {
            this.subscription.unsubscribe();
            this.subscription = undefined;
        }
        this._loading.next(true);
        var obs = this.fetchPage(fetchNextPage, true).pipe(catchError(function (e) {
            if (_this.subscription !== subscription) {
                return EMPTY;
            }
            return throwError(e);
        }), filter(function () {
            return _this.subscription === subscription;
        }), tap(function (response) {
            if (!response) {
                throw new ServerRequestError('Failed to fetch response');
            }
            _this.appendFetchItems(response.items);
            var fetchedResult = _this._fetchItems.value
                ? _this._fetchItems.value.slice(displayCount, displayCount + perPage)
                : [];
            _this.appendItems(fetchedResult);
        }), share());
        var subscription = obs.subscribe(function (response) {
            _this._loading.next(false);
            _this._fetchHasMore.next(response.hasMore);
            _this._fetchTotalPages.next(response.totalPages);
            _this._currentPage.next(nextPage);
            _this._nextPage.next(nextPage + 1);
            _this._fetchNextPage.next(fetchNextPage + 1);
            _this._count.next(response.count);
            _this.prevPageCursors[fetchNextPage] = response.cursorPrev;
            _this.nextPageCursors[fetchNextPage] = response.cursorNext;
            _this.subscription = undefined;
        }, function () {
            _this._loading.next(false);
            _this._currentPageImmediate.next(currentPage);
            _this.subscription = undefined;
        });
        this.subscription = subscription;
        return obs.pipe(map(function () {
            var fetchedResult = _this._fetchItems.value
                ? _this._fetchItems.value.slice(displayCount, displayCount + perPage)
                : [];
            return fetchedResult;
        }));
    };
    ListStore.prototype.appendItems = function (appendItems) {
        var items = this._items.value != null ? this._items.value : [];
        items.push.apply(items, appendItems);
        this._items.next(items);
    };
    ListStore.prototype.prependItems = function (prependItems) {
        var items = this._items.value != null ? this._items.value : [];
        items.unshift.apply(items, prependItems);
        this._items.next(items);
    };
    ListStore.prototype.appendFetchItems = function (appendItems) {
        var items = this._fetchItems.value != null ? this._fetchItems.value : [];
        items.push.apply(items, appendItems);
        this._fetchItems.next(items);
    };
    ListStore.prototype.getAll = function () {
        var _this = this;
        var process = function () {
            if (!_this.hasMore) {
                return of(_this.items);
            }
            return _this.getNext().pipe(switchMap(function () { return process(); }));
        };
        return process();
    };
    ListStore.prototype.reset = function (page) {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        this._loading.next(false);
        this._items.next(undefined);
        this._fetchItems.next(undefined);
        this._fetchHasMore.next(true);
        this._fetchTotalPages.next(0);
        this._count.next(undefined);
        if (page === undefined) {
            this._currentPage.next(1);
            this._fromPage.next(1);
            this._nextPage.next(1);
            this._fetchNextPage.next(1);
            this.prevPageCursors = {};
            this.nextPageCursors = {};
        }
        else {
            this._currentPage.next(page);
            this._fromPage.next(page);
            this._nextPage.next(page);
            this._fetchNextPage.next(page);
        }
    };
    return ListStore;
}());
export { ListStore };

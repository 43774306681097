/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../login-template/login-template.component.ngfactory";
import * as i2 from "../../../theme-components/data/theme-context";
import * as i3 from "../login-template/login-template.component";
import * as i4 from "../../../../core/services/app-config/app-config.service";
import * as i5 from "@angular/common";
import * as i6 from "../../../../ui/components/language-selector/language-selector.component.ngfactory";
import * as i7 from "../../../../ui/components/language-selector/language-selector.component";
import * as i8 from "../../../../core/services/local-storage/local.storage";
import * as i9 from "./login.form";
import * as i10 from "../../../../common/form-utils/services/form-utils";
import * as i11 from "@angular/forms";
import * as i12 from "../../../auth/services/auth/auth.service";
import * as i13 from "../../../api/services/api/api.service";
import * as i14 from "./login.component";
import * as i15 from "../../../routing/services/routing/routing.service";
import * as i16 from "../../../users/stores/current-user.store";
import * as i17 from "@angular/router";
import * as i18 from "../../../analytics/services/universal-analytics/universal-analytics.service";
import * as i19 from "../../../analytics/services/google-analytics/google-analytics.service";
import * as i20 from "../../../../common/notifications/services/notification/notification.service";
import * as i21 from "../../../meta/services/meta.service";
import * as i22 from "../../../projects/stores/projects.store";
import * as i23 from "../../../projects/services/project-invite/project-invite.service";
import * as i24 from "../../../projects/services/project-public-invite/project-public-invite.service";
var styles_LoginComponent = [];
var RenderType_LoginComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_LoginComponent, data: {} });
export { RenderType_LoginComponent as RenderType_LoginComponent };
function View_LoginComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "app-login-template", [], null, [[null, "socialLogin"], [null, "ssoLogin"], [null, "ssoReset"], [null, "cleanErrorForm"], [null, "submitJetAuth"], [null, "submitSSOAuth"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("socialLogin" === en)) {
        var pd_0 = (_co.socialLogin($event) !== false);
        ad = (pd_0 && ad);
    } if (("ssoLogin" === en)) {
        var pd_1 = (_co.ssoLogin($event) !== false);
        ad = (pd_1 && ad);
    } if (("ssoReset" === en)) {
        var pd_2 = (_co.ssoReset() !== false);
        ad = (pd_2 && ad);
    } if (("cleanErrorForm" === en)) {
        var pd_3 = (_co.cleanErrorForm() !== false);
        ad = (pd_3 && ad);
    } if (("submitJetAuth" === en)) {
        var pd_4 = (_co.submitJetAuth() !== false);
        ad = (pd_4 && ad);
    } if (("submitSSOAuth" === en)) {
        var pd_5 = (_co.submitSSOAuth($event) !== false);
        ad = (pd_5 && ad);
    } return ad; }, i1.View_LoginTemplateComponent_0, i1.RenderType_LoginTemplateComponent)), i0.ɵprd(135680, null, i2.ThemeContext, i2.ThemeContext, []), i0.ɵdid(2, 638976, null, 0, i3.LoginTemplateComponent, [i4.AppConfigService, i0.ChangeDetectorRef], { form: [0, "form"], title: [1, "title"], domainLoading: [2, "domainLoading"], submitLoading: [3, "submitLoading"], screenLoader: [4, "screenLoader"], domain: [5, "domain"], currentSSO: [6, "currentSSO"], invite: [7, "invite"], inviteLoading: [8, "inviteLoading"], publicInvite: [9, "publicInvite"], publicInviteLoading: [10, "publicInviteLoading"], signUpLink: [11, "signUpLink"], restoreLink: [12, "restoreLink"], fillScreen: [13, "fillScreen"] }, { socialLogin: "socialLogin", ssoLogin: "ssoLogin", ssoReset: "ssoReset", cleanErrorForm: "cleanErrorForm", submitJetAuth: "submitJetAuth", submitSSOAuth: "submitSSOAuth" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.loginForm; var currVal_1 = _co.title; var currVal_2 = _co.domainLoading; var currVal_3 = _co.submitLoading; var currVal_4 = _co.screenLoader; var currVal_5 = _co.domain; var currVal_6 = _co.currentSSO; var currVal_7 = _co.invite; var currVal_8 = _co.inviteLoading; var currVal_9 = _co.publicInvite; var currVal_10 = _co.publicInviteLoading; var currVal_11 = _co.signUpLink; var currVal_12 = _co.restoreLink; var currVal_13 = true; _ck(_v, 2, 1, [currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13]); }, null); }
export function View_LoginComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_LoginComponent_1)), i0.ɵdid(1, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(2, 0, null, null, 1, "app-language-selector", [], null, null, null, i6.View_LanguageSelectorComponent_0, i6.RenderType_LanguageSelectorComponent)), i0.ɵdid(3, 245760, null, 0, i7.LanguageSelectorComponent, [i8.LocalStorage, i0.ChangeDetectorRef], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.inviteLoading; _ck(_v, 1, 0, currVal_0); _ck(_v, 3, 0); }, null); }
export function View_LoginComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "app-login", [], null, null, null, View_LoginComponent_0, RenderType_LoginComponent)), i0.ɵprd(512, null, i9.LoginForm, i9.LoginForm, [i10.FormUtils, i11.FormBuilder, i12.AuthService, i13.ApiService]), i0.ɵdid(2, 245760, null, 0, i14.LoginComponent, [i9.LoginForm, i15.RoutingService, i16.CurrentUserStore, i17.ActivatedRoute, i18.UniversalAnalyticsService, i19.GoogleAnalyticsService, i4.AppConfigService, i12.AuthService, i20.NotificationService, i21.MetaService, i22.ProjectsStore, i13.ApiService, i23.ProjectInviteService, i24.ProjectPublicInviteService, i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var LoginComponentNgFactory = i0.ɵccf("app-login", i14.LoginComponent, View_LoginComponent_Host_0, {}, {}, []);
export { LoginComponentNgFactory as LoginComponentNgFactory };

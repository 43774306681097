import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import shuffle from 'lodash/shuffle';
import * as moment from 'moment';
import { Observable, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { FormUtils } from '@common/form-utils';
import { LocalStorage } from '@core';
import { ViewSettings } from '@modules/customize';
import { Option } from '@modules/field-components';
import {
  MenuBlock,
  MenuBlockLayout,
  MenuBlockLayouts,
  MenuGeneratorService,
  MenuItemSystemActionType
} from '@modules/menu';
import { Project, ProjectDeployment, ProjectService, ProjectsStore } from '@modules/projects';
import { isSet } from '@shared';

export enum ProductTypeValue {
  CRM = 'crm',
  Dashboard = 'dashboard',
  InternalTool = 'internal tool',
  CustomPortal = 'custom portal',
  Other = 'other'
}

@Injectable()
export class ProjectCreateProjectForm extends FormGroup {
  controls: {
    name: FormControl;
    deployment: FormControl;
    product_type: FormControl;
    product_type_custom: FormControl;
  };

  productTypeOptions: Option[] = [
    ...shuffle([
      {
        value: ProductTypeValue.CRM,
        name: 'Custom CRM for internal use',
        icon: 'human_being'
      },
      {
        value: ProductTypeValue.Dashboard,
        name: 'Dashboard with business KPIs',
        icon: 'chart'
      },
      {
        value: ProductTypeValue.InternalTool,
        name: 'Internal tool to help manage my business',
        icon: 'dashboard'
      },
      {
        value: ProductTypeValue.CustomPortal,
        name: 'Portal for my existing customers or partners',
        icon: 'versions'
      }
    ]),
    {
      value: ProductTypeValue.Other,
      name: 'Other',
      icon: 'blocks'
    }
  ];

  constructor(
    private formUtils: FormUtils,
    private projectService: ProjectService,
    private localStorage: LocalStorage,
    private projectsStore: ProjectsStore,
    private menuGeneratorService: MenuGeneratorService
  ) {
    super({
      name: new FormControl('', Validators.required),
      deployment: new FormControl(ProjectDeployment.Cloud, Validators.required),
      product_type: new FormControl('', Validators.required),
      product_type_custom: new FormControl('')
      // type: new FormControl(ProjectType.InternalTool)
    });

    this.markAsSubmit(false);
  }

  markAsSubmit(submit: boolean) {
    this['submit'] = submit;

    if (submit) {
      this.markAsDirty();
    }
  }

  getAbout() {
    return this.controls.product_type.value == ProductTypeValue.Other && isSet(this.controls.product_type_custom.value)
      ? this.controls.product_type_custom.value
      : this.controls.product_type.value;
  }

  create(): Observable<{
    project: Project;
    page?: ViewSettings;
  }> {
    this.markAsSubmit(true);

    // const value = this.form.value;
    const instance = new Project();
    const name = this.projectService.popNewProjectName();

    instance.name = isSet(name) ? name : this.controls.name.value;
    // instance.type = value['type'];
    instance.deployment = this.controls.deployment.value;
    instance.about = this.getAbout();

    const primaryBlock = new MenuBlock();

    primaryBlock.generateUid();
    primaryBlock.layout = MenuBlockLayout.TopThin;
    primaryBlock.accentColor = MenuBlockLayouts.getDefaultColor(MenuBlockLayout.TopThin);
    primaryBlock.startItems = [this.menuGeneratorService.createProjectMenuItem(primaryBlock.layout)];
    primaryBlock.centerItems = [this.menuGeneratorService.createSystemMenuItem(MenuItemSystemActionType.Home)];
    primaryBlock.endItems = [this.menuGeneratorService.createUserMenuItem(primaryBlock.layout)];

    const secondaryBlock = new MenuBlock();

    secondaryBlock.generateUid();
    secondaryBlock.layout = MenuBlockLayout.TopThin;

    const secondaryState = this.menuGeneratorService.getDefaultBlockState();

    if (secondaryState.startItems) {
      secondaryBlock.startItems = secondaryState.startItems;
    }

    if (secondaryState.centerItems) {
      secondaryBlock.centerItems = secondaryState.centerItems;
    }

    if (secondaryState.endItems) {
      secondaryBlock.endItems = secondaryState.endItems;
    }

    instance.menuSettings = {
      blocks: [primaryBlock, secondaryBlock]
    };

    return this.projectService.create(instance, undefined, ['name', 'params', 'menu_settings']).pipe(
      tap(result => this.projectsStore.addItem(result)),
      map(project => {
        return {
          project: project
        };
      }),
      // switchMap(project => {
      //   if (!value['add_page']) {
      //     return of({
      //       project: project
      //     });
      //   }
      //
      //   return this.viewSettingsQueries
      //     .createPage({
      //       project: project,
      //       view: ViewSettingsType.Custom,
      //       name: value['add_page'],
      //       addToMenu: true
      //     })
      //     .pipe(
      //       map(page => {
      //         return {
      //           project: project,
      //           page: page
      //         };
      //       })
      //     );
      // }),
      tap(result => {
        this.localStorage.set(result.project.dateCreatedKey, moment().toISOString());
      }),
      catchError(error => {
        this.formUtils.showFormErrors(this, error);
        return throwError(error);
      })
    );
  }
}

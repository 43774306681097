import { ViewContextToken } from '@modules/customize';

export enum InputTokenType {
  Formula = 'formula',
  TextInputs = 'text_inputs',
  Js = 'js',
  ContextItem = 'context_item',
  Filter = 'filter',
  Prompt = 'prompt',
  EmptyString = 'empty_string',
  Null = 'null'
}

export interface InputTokenData {
  type: string;
}

export interface ContextItemInputTokenData extends InputTokenData {
  token: ViewContextToken;
}

export interface FieldFilterInputTokenData extends InputTokenData {
  field: string;
  lookup: string;
}

import { Injectable } from '@angular/core';

import { DialogButtonHotkey, DialogButtonType, DialogService } from '@common/dialogs';
import { NotificationService } from '@common/notifications';
import { RoutingService } from '@modules/routing';

@Injectable({
  providedIn: 'root'
})
export class ResponseService {
  activitiesMaxExceededWarning = false;

  constructor(
    private notificationService: NotificationService,
    private dialogService: DialogService,
    private routing: RoutingService
  ) {
    // TODO: fix
    // this.currentProjectStore.instance$.subscribe(() => this.activitiesMaxExceededWarning = false);
  }

  handleWarning(warning: string) {
    if (warning == 'activities_max_exceeded') {
      if (!this.activitiesMaxExceededWarning) {
        setTimeout(() => {
          this.dialogService
            .dialog({
              title: 'Activities Limit Exceeded',
              description: 'Your monthly limit exceeded. Please upgrade your plan to continue using Jet.',
              buttons: [
                {
                  name: 'ok',
                  label: 'OK',
                  type: DialogButtonType.Default,
                  hotkey: DialogButtonHotkey.Cancel
                },
                {
                  name: 'upgrade',
                  label: 'Upgrade',
                  type: DialogButtonType.Primary,
                  hotkey: DialogButtonHotkey.Submit
                }
              ]
            })
            .subscribe(result => {
              if (result.button == 'upgrade') {
                this.routing.navigateApp(['project', 'billing']);
              }
            });
        }, 1000);
        this.activitiesMaxExceededWarning = true;
      }
    } else if (warning == 'no_permission') {
      this.notificationService.warning('No Access', "Sorry, you don't have enough permissions");
    } else {
      this.notificationService.warning(warning);
    }
  }
}

import { CdkConnectedOverlay, ConnectedOverlayPositionChange, ConnectedPosition } from '@angular/cdk/overlay';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';

import { Option } from '@modules/field-components';

export class SidebarCollapseContext {
  _opened = new BehaviorSubject<any>(undefined);

  get opened(): any {
    return this._opened.value;
  }

  set opened(value: any) {
    this._opened.next(value);
  }

  get opened$(): Observable<any> {
    return this._opened.asObservable();
  }
}

@Component({
  selector: 'app-sidebar-collapse',
  templateUrl: './sidebar-collapse.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SidebarCollapseComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  @Input() title: string;
  @Input() titleAdditional: string;
  @Input() titleAdditionalRed = false;
  @Input() titleAdditionalSelectedOption: any;
  @Input() titleAdditionalOptions: Option[] = [];
  @Input() description: string;
  @Input() descriptionWhenOpened: string;
  @Input() descriptionWrap = false;
  @Input() block = false;
  @Input() context: SidebarCollapseContext;
  @Input() openedInitial = false;
  @Input() openable = true;
  @Input() disabled = false;
  @Input() arrow = false;
  @Input() drag = false;
  @Input() error: string;
  @Input() padding = true;
  @Input() classes: string | string[];
  @Output() titleAdditionalOptionSelected = new EventEmitter<any>();
  @Output() openedChanged = new EventEmitter<boolean>();

  @HostBinding('class.app-sidebar-collapse_opened') opened = false;

  @ViewChild(CdkConnectedOverlay) cdkConnectedOverlay: CdkConnectedOverlay;

  contextSubscription: Subscription;

  titleAdditionalOptionsOpened = false;
  titleAdditionalOptionsPositions: ConnectedPosition[] = [
    {
      panelClass: ['overlay_position_bottom-left'],
      originX: 'start',
      overlayX: 'start',
      originY: 'bottom',
      overlayY: 'top',
      offsetX: -8
    },
    {
      panelClass: ['overlay_position_bottom-right'],
      originX: 'end',
      overlayX: 'end',
      originY: 'bottom',
      overlayY: 'top',
      offsetX: 8
    },
    {
      panelClass: ['overlay_position_top-left'],
      originX: 'start',
      overlayX: 'start',
      originY: 'top',
      overlayY: 'bottom',
      offsetX: -8
    },
    {
      panelClass: ['overlay_position_top-right'],
      originX: 'end',
      overlayX: 'end',
      originY: 'top',
      overlayY: 'bottom',
      offsetX: 8
    },
    {
      panelClass: ['overlay_position_left-center'],
      originX: 'start',
      overlayX: 'end',
      originY: 'center',
      overlayY: 'center'
    },
    {
      panelClass: ['overlay_position_right-center'],
      originX: 'end',
      overlayX: 'start',
      originY: 'center',
      overlayY: 'center'
    }
  ];
  titleAdditionalOptionsPositionsSubscription: Subscription;

  constructor(private cd: ChangeDetectorRef) {}

  ngOnInit() {
    this.init();
  }

  ngOnDestroy(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['context'] && !changes['context'].firstChange) {
      this.init();
    }
  }

  ngAfterViewInit(): void {
    this.setTitleAdditionalOptionsPositionObserver();
  }

  init() {
    if (this.contextSubscription) {
      this.contextSubscription.unsubscribe();
      this.contextSubscription = undefined;
    }

    if (this.context) {
      this.contextSubscription = this.context.opened$.pipe(untilDestroyed(this)).subscribe(opened => {
        this.opened = opened === this;
        this.cd.markForCheck();
      });
    }

    if (this.openedInitial) {
      this.setOpened(true);
    }
  }

  public isOpened(): boolean {
    return this.opened;
  }

  public setOpened(opened) {
    if (!this.openable) {
      return;
    }

    if (this.context) {
      if (opened && this.context.opened !== this) {
        this.context.opened = this;
      } else if (!opened && this.context.opened === this) {
        this.context.opened = undefined;
      }
    } else {
      this.opened = opened;
      this.cd.markForCheck();
    }

    this.openedChanged.emit(this.opened);
  }

  public toggleOpened() {
    if (!this.openable) {
      return;
    }

    this.setOpened(!this.opened);
  }

  setTitleAdditionalOptionsOpened(value: boolean) {
    this.titleAdditionalOptionsOpened = value;
    this.cd.markForCheck();
  }

  onTitleAdditionalOptionsClick(event: MouseEvent) {
    if (this.opened) {
      event.stopPropagation();
    }

    this.setTitleAdditionalOptionsOpened(true);
  }

  setTitleAdditionalOptionsPositionObserver() {
    if (this.titleAdditionalOptionsPositionsSubscription) {
      this.titleAdditionalOptionsPositionsSubscription.unsubscribe();
    }

    if (!this.cdkConnectedOverlay) {
      return;
    }

    this.titleAdditionalOptionsPositionsSubscription = this.cdkConnectedOverlay.positionChange
      .pipe(untilDestroyed(this))
      .subscribe((e: ConnectedOverlayPositionChange) => {
        const propsEqual = ['offsetX', 'offsetY', 'originX', 'originY', 'overlayX', 'overlayY'];
        const position = this.titleAdditionalOptionsPositions.find(item =>
          propsEqual.every(prop => (item[prop] || undefined) == e.connectionPair[prop])
        );
        const otherPosition = this.titleAdditionalOptionsPositions.filter(item => item !== position);

        if (position) {
          this.cdkConnectedOverlay.overlayRef.addPanelClass(position.panelClass);
        }

        otherPosition.forEach(item => this.cdkConnectedOverlay.overlayRef.removePanelClass(item.panelClass));
      });
  }
}

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../sidebar/components/sidebar-field/sidebar-field.component.ngfactory";
import * as i2 from "../../../../../sidebar/components/sidebar-field/sidebar-field.component";
import * as i3 from "../../customize-bar-action-edit-element-action/customize-bar-action-edit-element-action.component.ngfactory";
import * as i4 from "../../customize-bar-action-edit-element-action/customize-bar-action-edit-element-action.component";
import * as i5 from "../../../../../field-components/components/field-errors/field-errors.component.ngfactory";
import * as i6 from "../../../../../field-components/components/field-errors/field-errors.component";
import * as i7 from "./customize-bar-action-edit-type-element-action.component";
var styles_CustomizeBarActionEditTypeElementActionComponent = [];
var RenderType_CustomizeBarActionEditTypeElementActionComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CustomizeBarActionEditTypeElementActionComponent, data: {} });
export { RenderType_CustomizeBarActionEditTypeElementActionComponent as RenderType_CustomizeBarActionEditTypeElementActionComponent };
export function View_CustomizeBarActionEditTypeElementActionComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "div", [["class", "sidebar__element"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 5, "app-sidebar-field", [], null, null, null, i1.View_SidebarFieldComponent_0, i1.RenderType_SidebarFieldComponent)), i0.ɵdid(2, 114688, null, 0, i2.SidebarFieldComponent, [], { label: [0, "label"], documentation: [1, "documentation"], documentationLabel: [2, "documentationLabel"] }, null), (_l()(), i0.ɵeld(3, 0, null, 3, 1, "app-customize-bar-action-edit-element-action", [], null, null, null, i3.View_CustomizeBarActionEditElementActionComponent_0, i3.RenderType_CustomizeBarActionEditElementActionComponent)), i0.ɵdid(4, 245760, null, 0, i4.CustomizeBarActionEditElementActionComponent, [i0.ChangeDetectorRef], { control: [0, "control"], context: [1, "context"], contextElement: [2, "contextElement"], contextElementPath: [3, "contextElementPath"], contextElementPaths: [4, "contextElementPaths"] }, null), (_l()(), i0.ɵeld(5, 0, null, 3, 1, "app-field-errors", [], null, null, null, i5.View_FieldErrorsComponent_0, i5.RenderType_FieldErrorsComponent)), i0.ɵdid(6, 114688, null, 0, i6.FieldErrorsComponent, [], { form: [0, "form"], fieldName: [1, "fieldName"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "Action"; var currVal_1 = "component-actions"; var currVal_2 = "See how it works"; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = _co.form.controls.element_action; var currVal_4 = _co.context; var currVal_5 = _co.contextElement; var currVal_6 = _co.contextElementPath; var currVal_7 = _co.contextElementPaths; _ck(_v, 4, 0, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); var currVal_8 = _co.form; var currVal_9 = "element_action"; _ck(_v, 6, 0, currVal_8, currVal_9); }, null); }
export function View_CustomizeBarActionEditTypeElementActionComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-customize-bar-action-edit-type-element-action", [], null, null, null, View_CustomizeBarActionEditTypeElementActionComponent_0, RenderType_CustomizeBarActionEditTypeElementActionComponent)), i0.ɵdid(1, 114688, null, 0, i7.CustomizeBarActionEditTypeElementActionComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CustomizeBarActionEditTypeElementActionComponentNgFactory = i0.ɵccf("app-customize-bar-action-edit-type-element-action", i7.CustomizeBarActionEditTypeElementActionComponent, View_CustomizeBarActionEditTypeElementActionComponent_Host_0, { form: "form", context: "context", contextElement: "contextElement", contextElementPath: "contextElementPath", contextElementPaths: "contextElementPaths", analyticsSource: "analyticsSource" }, {}, []);
export { CustomizeBarActionEditTypeElementActionComponentNgFactory as CustomizeBarActionEditTypeElementActionComponentNgFactory };

import isEqual from 'lodash/isEqual';

import { Mappable } from '@shared';

export abstract class SimpleQuery implements Mappable<SimpleQuery> {
  abstract isConfigured(): boolean;

  abstract deserialize(data: Object);

  abstract serialize(fields?: string[]);

  equals(another: this): boolean {
    return isEqual(this.serialize(), another.serialize());
  }
}

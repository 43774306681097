<app-field-wrapper
  *ngIf="!readonly"
  [formGroup]="form"
  [form]="form"
  [field]="field"
  [readonly]="readonly"
  [requiredAsterisk]="requiredAsterisk"
  [label]="label"
  [errors]="errors"
  [fillVertical]="true"
  [truncate]="truncate"
  [manualMargin]="manualMargin"
  [labelButtons]="labelButtons"
  [tooltip]="tooltip"
  [idToken]="idToken"
>
  <app-image-upload
    *ngIf="!field.params['multiple']"
    [name]="field.name"
    [control]="control"
    [outputFormat]="field.params['output_format']"
    [storageResourceName]="field.params['storage_resource']"
    [storageName]="field.params['storage_name']"
    [path]="storagePath"
    [autofocus]="autofocus"
    [background]="field.params['background']"
    [fit]="field.params['fit']"
    [resize]="field.params['resize']"
    [editor]="field.params['editor'] !== false"
    [cropFormat]="field.params['crop_format']"
    [cropFormatCustom]="field.params['crop_format_custom']"
    [context]="context"
    [contextElement]="contextElement"
    [accentColor]="accentColor"
    (uploadedUpdated)="onUploadedUpdated()"
  ></app-image-upload>

  <app-multiple-image-upload
    *ngIf="field.params['multiple']"
    [name]="field.name"
    [control]="control"
    [outputFormat]="field.params['output_format']"
    [storageResourceName]="field.params['storage_resource']"
    [storageName]="field.params['storage_name']"
    [path]="storagePath"
    [autofocus]="autofocus"
    [background]="field.params['background']"
    [fit]="field.params['fit']"
    [resize]="field.params['resize']"
    [editor]="field.params['editor'] !== false"
    [cropFormat]="field.params['crop_format']"
    [cropFormatCustom]="field.params['crop_format_custom']"
    [context]="context"
    [contextElement]="contextElement"
    [compact]="compact"
    [accentColor]="accentColor"
    (uploadedUpdated)="onUploadedUpdated()"
  ></app-multiple-image-upload>
</app-field-wrapper>

<app-field-wrapper
  *ngIf="readonly"
  [form]="form"
  [field]="field"
  [readonly]="readonly"
  [requiredAsterisk]="requiredAsterisk"
  [label]="label"
  [errors]="errors"
  [truncate]="truncate"
  [manualMargin]="manualMargin"
  [labelButtons]="labelButtons"
  [tooltip]="tooltip"
  [idToken]="idToken"
>
  <app-image-preview
    *ngIf="!field.params['multiple']"
    [url]="uploadedFile?.url"
    [value]="currentValue"
    [background]="field.params['background']"
    [fit]="field.params['fit']"
    [type]="field.params['type']"
    [resize]="field.params['resize']"
    [lightBox]="field.params['lightbox']"
    [preview]="field.params['show_preview']"
  ></app-image-preview>

  <div
    *ngIf="field.params['multiple']"
    class="multiple-files-uploader"
    [class.multiple-files-uploader_compact]="compact"
  >
    <div
      *ngFor="let uploadedFile of compact ? uploadedFiles.slice(0, 3) : uploadedFiles"
      class="multiple-files-uploader__item"
    >
      <app-image-preview
        [url]="uploadedFile?.url"
        [value]="currentValue"
        [background]="field.params['background']"
        [fit]="field.params['fit']"
        [type]="field.params['type']"
        [resize]="field.params['resize']"
        [lightBox]="field.params['lightbox']"
        [preview]="field.params['show_preview']"
        [compact]="compact"
      ></app-image-preview>
    </div>

    <div *ngIf="compact && uploadedFiles.length > 3" class="multiple-files-uploader__item">
      <div class="multiple-files-uploader__spoiler">+{{ uploadedFiles.length - 3 }}</div>
    </div>
  </div>
</app-field-wrapper>

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';

import { CustomViewSettings, ViewContext } from '@modules/customize';
import { Option } from '@modules/field-components';
import { createFormFieldFactory } from '@modules/fields';
import { controlValue } from '@shared';

import { CustomizeBarActionEditForm, OPENED_MODAL_VALUE } from '../../customize-bar-action-edit.form';

@Component({
  selector: 'app-customize-bar-action-edit-type-close-popup',
  templateUrl: './customize-bar-action-edit-type-close-popup.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomizeBarActionEditTypeClosePopupComponent implements OnInit, OnDestroy {
  @Input() form: CustomizeBarActionEditForm;
  @Input() context: ViewContext;
  @Input() analyticsSource: string;

  createField = createFormFieldFactory();
  popupOptions: Option<string>[] = [];
  selectedPopupOption: Option;

  constructor(private cd: ChangeDetectorRef) {}

  ngOnInit() {
    this.updatePopupOptions();

    controlValue(this.form.controls.close_popup)
      .pipe(untilDestroyed(this))
      .subscribe(value => {
        this.selectedPopupOption = this.popupOptions.filter(item => item.value == value)[0];
        this.cd.markForCheck();
      });
  }

  ngOnDestroy(): void {}

  updatePopupOptions() {
    if (this.context.viewSettings && this.context.viewSettings instanceof CustomViewSettings) {
      this.popupOptions = [
        {
          value: OPENED_MODAL_VALUE,
          name: 'Any opened Modal',
          icon: 'deselect'
        },
        ...this.context.viewSettings.popups.map(item => {
          return {
            value: item.uid,
            name: item.name,
            icon: item.getIcon()
          };
        })
      ];
    }
  }
}

import { registerElementForType } from '../element-items';
import { ElementType } from '../element-type';
import { ElementItem } from './base';

export class SpacingElementItem extends ElementItem {
  public type = ElementType.Spacing;
  public height: number;

  deserialize(data: Object): ElementItem {
    super.deserialize(data);
    this.height = this.params['height'];

    return this;
  }

  serialize(): Object {
    const data = super.serialize();
    data['params']['height'] = this.height;
    return data;
  }

  get typeStr(): string {
    return 'space between containers';
  }

  get analyticsName(): string {
    return 'spacing';
  }

  defaultName() {
    return 'Spacing';
  }
}

registerElementForType(ElementType.Spacing, SpacingElementItem);

import pickBy from 'lodash/pickBy';

import { ListDefaultSelection, ListGroup, TableSettings } from '@modules/customize';
import { ListState, serializeDataSourceColumns } from '@modules/list-components';
import { PAGE_PARAM } from '@modules/models';

export interface TableState extends ListState<TableSettings> {
  page?: number;
  perPage?: number;
  sortingField?: string;
  sortingAsc?: boolean;
  groupField?: string;
  groups?: ListGroup[];
  defaultSelection?: ListDefaultSelection;
}

export function getListStateFetch(state: TableState): Object {
  return {
    dataSource: state.dataSource
      ? {
          ...state.dataSource.serialize(),
          columns: serializeDataSourceColumns(state.dataSource.columns)
        }
      : undefined,
    dataSourceStaticData: state.dataSourceStaticData,
    dataSourceParams: state.dataSourceParams,
    filters: state.filters ? state.filters.map(item => item.serialize()) : [],
    search: state.search,
    sort: state.sort,
    inputsLoading: state.inputsLoading,
    inputsNotSet: state.inputsNotSet,
    perPage: state.perPage,
    sortingField: state.sortingField,
    sortingAsc: state.sortingAsc
  };
}

export function getListStateFetchNewParams(state: TableState): Object {
  return {
    params: pickBy(state.dataSourceParams, (v, k) => PAGE_PARAM != k),
    filters: state.filters ? state.filters.map(item => item.serialize()) : [],
    search: state.search,
    sort: state.sort
  };
}

export function getListStateColumns(state: TableState): Object {
  return {
    columns: state.dataSource ? state.dataSource.columns : undefined
  };
}

export function getListStateDefaultSelection(state: TableState): Object {
  return {
    defaultSelection: state.defaultSelection
  };
}

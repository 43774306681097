import { Injectable } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import * as moment from 'moment';

import { isSet } from '@shared';

import { defaultTimeFormat, momentTimeFormats, TimeFormat } from '@modules/fields';

@Injectable()
export class TimeFieldViewParamsForm {
  form: FormGroup;
  control: AbstractControl;

  timeFormatOptions = [
    {
      value: TimeFormat.HourMinute24,
      name: `24 hour (${moment().format(momentTimeFormats[TimeFormat.HourMinute24])})`
    },
    {
      value: TimeFormat.HourMinuteSecond24,
      name: `24 hour with seconds (${moment().format(momentTimeFormats[TimeFormat.HourMinuteSecond24])})`
    },
    {
      value: TimeFormat.HourMinute12,
      name: `12 hour (${moment().format(momentTimeFormats[TimeFormat.HourMinute12])})`
    },
    {
      value: TimeFormat.HourMinuteSecond12,
      name: `12 hour with seconds (${moment().format(momentTimeFormats[TimeFormat.HourMinuteSecond12])})`
    },
    {
      value: TimeFormat.Custom,
      name: 'Custom'
    }
  ];

  constructor(private fb: FormBuilder) {
    this.form = this.fb.group({
      time_format: new FormControl(defaultTimeFormat),
      time_format_custom: new FormControl(momentTimeFormats[TimeFormat.HourMinuteSecond24])
    });
  }

  init(control: AbstractControl) {
    this.control = control;

    if (control.value) {
      const value = {};

      if (isSet(control.value['time_format']) && momentTimeFormats[control.value['time_format']]) {
        value['time_format'] = control.value['time_format'];
      } else if (isSet(control.value['time_format'])) {
        value['time_format'] = TimeFormat.Custom;
        value['time_format_custom'] = control.value['time_format'];
      }

      this.form.patchValue(value, { emitEvent: false });
    }

    this.form.valueChanges.subscribe(() => this.submit());
  }

  submit() {
    const data = {
      time_format:
        this.form.value['time_format'] == TimeFormat.Custom && isSet(this.form.value['time_format_custom'])
          ? this.form.value['time_format_custom']
          : this.form.value['time_format']
    };

    this.control.patchValue({
      ...this.control.value,
      ...data
    });
  }
}

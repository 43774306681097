var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import pickBy from 'lodash/pickBy';
import { getWidgetByType, WidgetType } from '@modules/dashboard';
import { registerElementForType } from '../element-items';
import { ElementType } from '../element-type';
import { ElementItem } from './base';
var WidgetElementItem = /** @class */ (function (_super) {
    __extends(WidgetElementItem, _super);
    function WidgetElementItem() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.type = ElementType.Widget;
        return _this;
    }
    WidgetElementItem.prototype.deserialize = function (data) {
        _super.prototype.deserialize.call(this, data);
        this.height = this.params['height'];
        if (this.params['widget']) {
            var element = getWidgetByType(this.params['widget']['widget_type']);
            this.widget = new element().deserialize(this.params['widget']);
        }
        return this;
    };
    WidgetElementItem.prototype.serialize = function (fields) {
        this.params = {};
        this.params['height'] = this.height;
        this.params['widget'] = this.widget ? this.widget.serialize() : undefined;
        var data = _super.prototype.serialize.call(this);
        if (fields) {
            data = pickBy(data, function (v, k) { return fields.includes(k); });
        }
        return data;
    };
    Object.defineProperty(WidgetElementItem.prototype, "typeStr", {
        get: function () {
            return "visualize data on the " + this.widget.typeStr;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(WidgetElementItem.prototype, "analyticsName", {
        get: function () {
            if (!this.widget) {
                return;
            }
            return this.widget.analyticsName;
        },
        enumerable: true,
        configurable: true
    });
    WidgetElementItem.prototype.defaultName = function () {
        if (this.widget.type == WidgetType.Chart) {
            return 'Chart';
        }
        else if (this.widget.type == WidgetType.Value) {
            return 'Single Value';
        }
        else {
            return 'Widget';
        }
    };
    return WidgetElementItem;
}(ElementItem));
export { WidgetElementItem };
registerElementForType(ElementType.Widget, WidgetElementItem);

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';

import { LayerInteractionArray } from '../../controls/layer-interaction.array';
import { LayerInteractionControl } from '../../controls/layer-interaction.control';

@Component({
  selector: 'app-interactions-section-cursor',
  templateUrl: './interactions-section-cursor.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InteractionsSectionCursorComponent implements OnInit, OnDestroy {
  @Input() control: LayerInteractionControl;
  @Input() arrayControl: LayerInteractionArray;

  dropdownOpened = false;

  constructor(private cd: ChangeDetectorRef) {}

  ngOnInit() {
    this.control.controls.cursor.valueChanges.pipe(untilDestroyed(this)).subscribe(() => this.cd.markForCheck());
  }

  ngOnDestroy(): void {}

  setDropdownOpened(value: boolean) {
    this.dropdownOpened = value;
    this.cd.markForCheck();
  }
}

<app-section [cls]="section">
  <app-page-header>
    <app-page-title>Delete a {{ modelDescription.verboseName }}</app-page-title>
    <app-page-description>
      Permanently deletes a {{ modelDescription.verboseName }}. It cannot be undone.
    </app-page-description>
  </app-page-header>

  <app-section-column>
    <app-header-parameters [parameters]="headerParameters" [loading]="headerParametersLoading"></app-header-parameters>

    <ng-container *ngIf="parameters.length">
      <app-page-block>
        <app-page-heading>Request parameters</app-page-heading>
      </app-page-block>

      <app-page-block>
        <div class="code-parameters">
          <div *ngFor="let item of parameters" class="code-parameters__item">
            <app-api-parameter [item]="item"></app-api-parameter>
          </div>
        </div>
      </app-page-block>

      <app-page-block [marginSize]="'l'">
        <hr />
      </app-page-block>
    </ng-container>

    <app-page-block>
      <app-page-heading>Returned result</app-page-heading>
      <app-page-description>
        Returns empty response if delete succeeded. Returns an error if delete parameters are invalid.
      </app-page-description>
    </app-page-block>
  </app-section-column>

  <app-section-column>
    <div class="side-code side-code_sticky">
      <app-page-block>
        <div class="code-block">
          <div class="code-block-header">
            <div class="code-block-header__main">
              <span class="code-block-header__method color_red_1">DELETE</span>
              <span class="code-block-header__url">{{ getUrlPath() }}</span>
            </div>
            <div class="code-block-header__right">
              <div
                class="code-block-header__action icon-duplicate"
                (click)="copy(curl || getAbsoluteApiUrl(), 'CURL')"
              ></div>
            </div>
          </div>
          <div *ngIf="curl" class="code-block-content">
            <div class="code-block__code">{{ curl }}</div>
          </div>
        </div>
      </app-page-block>
    </div>
  </app-section-column>
</app-section>

import { Directive, HostListener, Injector, Input } from '@angular/core';

import { SimplePopupComponent } from '@common/dialog-popup';
import { PopupService } from '@common/popups';
import { isSet } from '@shared';

import { LightboxComponent } from '../../components/image-field/lightbox/lightbox.component';

@Directive({
  selector: '[appLightbox]'
})
export class LightboxDirective {
  @Input('appLightbox') url: string;
  @Input('appLightboxVideo') video = false;
  @Input('appLightboxOrigin') origin: HTMLElement;

  constructor(private popupService: PopupService, private injector: Injector) {}

  @HostListener('click') onClick() {
    this.openLightbox();
  }

  openLightbox() {
    if (!isSet(this.url)) {
      return;
    }

    this.popupService.push({
      component: LightboxComponent,
      popupComponent: SimplePopupComponent,
      inputs: {
        url: this.url,
        video: this.video,
        zoom: false,
        origin: this.origin
      },
      injector: this.injector
    });
  }
}

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Observable } from 'rxjs';
import { delayWhen, filter, map, switchMap } from 'rxjs/operators';

import { DialogButtonHotkey, DialogButtonType, DialogOptions, DialogService } from '@common/dialogs';
import { BasePopupComponent } from '@common/popups';
import { TaskQueue, TaskQueueService, TaskQueueStore } from '@modules/collaboration';
import { createFormFieldFactory } from '@modules/fields';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';

import { TaskQueueEditPopupForm } from './task-queue-edit-popup.form';

@Component({
  selector: 'app-task-queue-edit-popup',
  templateUrl: './task-queue-edit-popup.component.html',
  providers: [TaskQueueEditPopupForm],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaskQueueEditPopupComponent implements OnInit, OnDestroy {
  @Input() queue: TaskQueue;
  @Output() added = new EventEmitter<TaskQueue>();
  @Output() edited = new EventEmitter<TaskQueue>();
  @Output() deleted = new EventEmitter<void>();

  createField = createFormFieldFactory();
  loading = false;

  constructor(
    private dialogService: DialogService,
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private taskQueueService: TaskQueueService,
    private taskQueueStore: TaskQueueStore,
    public form: TaskQueueEditPopupForm,
    private popupComponent: BasePopupComponent,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.form.init(this.queue);
  }

  ngOnDestroy(): void {}

  submit() {
    this.loading = true;
    this.cd.markForCheck();

    this.form
      .submit()
      .pipe(untilDestroyed(this))
      .subscribe(
        queue => {
          if (this.queue) {
            this.edited.emit(queue);
          } else {
            this.added.emit(queue);
          }

          this.popupComponent.close();
        },
        () => {
          this.loading = false;
          this.cd.markForCheck();
        }
      );
  }

  delete(): void {
    this.loading = true;
    this.cd.markForCheck();

    this.deleteConfirm()
      .pipe(
        filter(value => {
          if (!value) {
            this.loading = false;
            this.cd.markForCheck();
            return false;
          }

          return true;
        }),
        switchMap(() =>
          this.taskQueueService.delete(
            this.currentProjectStore.instance.uniqueName,
            this.currentEnvironmentStore.instance.uniqueName,
            this.queue
          )
        ),
        delayWhen(() => this.taskQueueStore.getFirst(true)),
        untilDestroyed(this)
      )
      .subscribe(
        () => {
          this.loading = false;
          this.deleted.emit();
          this.popupComponent.close();
        },
        () => {
          this.loading = false;
          this.cd.markForCheck();
        }
      );
  }

  deleteConfirm(): Observable<boolean> {
    const options: DialogOptions = {
      title: `Delete queue`,
      description: `Do you want to delete <strong>${this.queue.name}</strong> queue?`,
      style: 'orange',
      buttons: [
        {
          name: 'no',
          label: 'No',
          type: DialogButtonType.Primary,
          hotkey: DialogButtonHotkey.Cancel
        },
        {
          name: 'yes',
          label: 'Yes',
          type: DialogButtonType.Danger,
          hotkey: DialogButtonHotkey.Submit
        }
      ]
    };

    return this.dialogService
      .dialog(options)
      .pipe(untilDestroyed(this))
      .pipe(
        map(result => {
          if (result.button == 'yes') {
            return true;
          } else if (result.button == 'no') {
            return false;
          }
        })
      );
  }

  cancel() {
    this.popupComponent.close();
  }
}

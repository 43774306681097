import pickBy from 'lodash/pickBy';
export var EmailTemplateName;
(function (EmailTemplateName) {
    EmailTemplateName["EmailVerification"] = "email_verification";
    EmailTemplateName["UserRestore"] = "user_restore";
    EmailTemplateName["ProjectUserInvitation"] = "project_user_invitation";
    EmailTemplateName["EmailChange"] = "email_change";
})(EmailTemplateName || (EmailTemplateName = {}));
export var emailTemplates = [
    {
        name: EmailTemplateName.EmailVerification,
        label: 'Email verification',
        icon: 'email'
    },
    {
        name: EmailTemplateName.UserRestore,
        label: 'Password restore',
        icon: 'key'
    },
    {
        name: EmailTemplateName.ProjectUserInvitation,
        label: 'User invitation',
        icon: 'human_being'
    },
    {
        name: EmailTemplateName.EmailChange,
        label: 'Email Change',
        icon: 'email'
    }
];
var EmailTemplate = /** @class */ (function () {
    function EmailTemplate() {
        this.language = '';
        this.protected = false;
    }
    EmailTemplate.prototype.deserialize = function (data) {
        this.name = data['name'];
        this.subject = data['subject'];
        this.content = data['content'];
        this.htmlContent = data['html_content'];
        this.language = data['language'];
        this.defaultTemplate = data['default_template'];
        this.created = data['created'];
        this.lastUpdated = data['last_updated'];
        if (data.hasOwnProperty('protected')) {
            this.protected = data['protected'];
        }
        return this;
    };
    EmailTemplate.prototype.serialize = function (fields) {
        var data = {
            name: this.name,
            subject: this.subject,
            content: this.content,
            html_content: this.htmlContent,
            language: this.language,
            default_template: this.defaultTemplate,
            created: this.created,
            last_updated: this.lastUpdated
        };
        if (fields) {
            data = pickBy(data, function (v, k) { return fields.includes(k); });
        }
        return data;
    };
    Object.defineProperty(EmailTemplate.prototype, "info", {
        get: function () {
            var _this = this;
            return emailTemplates.find(function (item) { return item.name == _this.name; });
        },
        enumerable: true,
        configurable: true
    });
    return EmailTemplate;
}());
export { EmailTemplate };

<div
  cdkDropList
  [cdkDropListDisabled]="!configurable.sortable"
  [cdkDropListLockAxis]="'y'"
  class="sidebar-list"
  (cdkDropListDropped)="dragDrop($event)"
>
  <div
    *ngFor="let itemForm of form.form.controls; let i = index"
    [formGroup]="itemForm"
    cdkDrag
    class="sidebar-list__item"
  >
    <div
      class="sidebar-list-item"
      [class.sidebar-list-item_draggable]="configurable.sortable"
      (mouseenter)="hovered$.next(i)"
      (mouseleave)="hovered$.next(undefined)"
    >
      <div *ngIf="configurable.sortable" class="sidebar-list-item__drag"></div>

      <div *ngIf="configurable.field && !configurable.params" class="sidebar-list-item__left">
        <mat-menu #fieldsMenu="matMenu">
          <ng-template matMenuContent>
            <button
              *ngFor="let item of form.fieldOptions"
              mat-menu-item
              [disableRipple]="true"
              (click)="itemForm.patchValue({ field: item.value })"
            >
              <span
                class="icon mat-menu-item-icon"
                [ngClass]="['icon-' + form.fieldDescriptionIcon(item.value)]"
              ></span>
              {{ item.name }}
            </button>
          </ng-template>
        </mat-menu>

        <a
          href="javascript:void(0)"
          class="button button_icon button_orange button_extra-small"
          [matMenuTriggerFor]="fieldsMenu"
          style="margin: 0;"
        >
          <span class="icon button__icon" [ngClass]="['icon-' + (form.fieldIcon$(itemForm) | async)]"></span>
        </a>
      </div>

      <div *ngIf="configurable.params" class="sidebar-list-item__left">
        <a
          href="javascript:void(0)"
          class="button button_icon button_orange button_extra-small"
          (click)="openItemSettings(itemForm, button)"
          style="margin: 0;"
          #button
        >
          <span class="icon button__icon" [ngClass]="['icon-' + (form.fieldIcon$(itemForm) | async)]"></span>
        </a>
      </div>

      <div class="sidebar-list-item__main">
        <div class="sidebar-list-item__title">
          <app-editable-content
            *ngIf="configurable.name"
            [value]="itemForm.value['name']"
            [emptyValue]="'Field name'"
            [pen]="(hovered$ | async) === i"
            [penTitle]="'Rename parameter'"
            (valueChange)="itemForm.patchValue({ name: $event })"
            (mousedown)="$event.stopPropagation()"
          >
          </app-editable-content>

          <ng-container *ngIf="!configurable.name">
            {{ itemForm.value['verboseName'] || itemForm.value['name'] }}
          </ng-container>
        </div>
      </div>

      <div class="sidebar-list-item__right">
        <app-toggle
          *ngIf="configurable.visible"
          [orange]="true"
          [selected]="itemForm.value['visible']"
          class="table-editor__item-status"
          (click)="form.toggleItemVisible(itemForm)"
        >
        </app-toggle>
        <a
          *ngIf="configurable.required"
          href="javascript:void(0)"
          class="sidebar-list-item__action icon-check_4"
          [class.sidebar-list-item__action_active]="itemForm.value['required']"
          [appTip]="itemForm.value['required'] ? 'Make field Optional' : 'Make field Required'"
          (click)="form.toggleItemRequired(itemForm)"
        >
        </a>
        <a
          *ngIf="configurable.add"
          href="javascript:void(0)"
          class="sidebar-list-item__action icon-bin"
          (click)="deleteItem(itemForm)"
        >
        </a>
        <!--          <a *ngIf="configurable.params"-->
        <!--             href="javascript:void(0)"-->
        <!--             class="sidebar-list-item__action icon-gear"-->
        <!--             (click)="openItemSettings(itemForm, button)"-->
        <!--             #button>-->
        <!--          </a>-->
      </div>
    </div>
  </div>

  <div *ngIf="!form.form.controls.length && configurable.add" class="sidebar-list__item">
    <div class="sidebar-list-item sidebar-list-item_clickable sidebar-list-item_disabled" (click)="addItem()">
      <div class="sidebar-list-item__drag"></div>
      <div class="sidebar-list-item__left">
        <span class="button button_icon button_orange button_extra-small" style="margin: 0;">
          <span class="icon-text button__icon"></span>
        </span>
      </div>
      <div class="sidebar-list-item__main">
        <div class="sidebar-list-item__title">Output</div>
        <div class="sidebar-list-item__description">Not specified</div>
      </div>
    </div>
  </div>

  <div *ngIf="configurable.add || buttons?.length" class="sidebar-list__buttons">
    <a
      *ngIf="configurable.add"
      href="javascript:void(0)"
      class="button button_orange-transparent button_small sidebar-list__buttons-item"
      (click)="addItem()"
    >
      <span class="icon icon-plus button__icon button__icon_left"></span>
      <span class="button__label">{{ addLabel }}</span>
    </a>
    <a
      *ngFor="let item of buttons"
      href="javascript:void(0)"
      class="button button_orange-alternative button_small sidebar-list__buttons-item"
      (click)="item.click()"
    >
      <span *ngIf="item.icon" class="icon button__icon button__icon_left" [ngClass]="['icon-' + item.icon]"></span>
      <span class="button__label">{{ item.label }}</span>
    </a>
  </div>
</div>

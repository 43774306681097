<div class="guide-popup-background" (click)="close()"></div>
<div class="guide-popup" [class.guide-popup_left]="options.left" (click)="$event.stopPropagation()" #root>
  <ng-container *ngIf="openedTask">
    <div class="guide-popup__header">
      <a href="javascript:void(0)" class="guide-popup__header-back" (click)="close()">
        <span class="icon icon-arrow_backward_2 guide-popup__header-back-icon"></span> Back to {{ pageTitle }}
      </a>
      <a href="javascript:void(0)" class="guide-popup__header-close icon icon-close" (click)="close()"></a>
    </div>
    <app-guide-popup-task [task]="openedTask"> </app-guide-popup-task>
  </ng-container>

  <app-guide-popup-documentation
    *ngIf="openedDocumentationPath | appIsSet"
    [path]="openedDocumentationPath"
    [pageTitle]="pageTitle"
    (close)="close()"
  >
  </app-guide-popup-documentation>
</div>

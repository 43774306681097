var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ChangeDetectorRef, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { FieldType, registerFieldComponent, VideoOutputFormat } from '@modules/fields';
import { ModelService } from '@modules/model-queries';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { ResourceControllerService } from '@modules/resources';
import { StorageService } from '@modules/storages-queries';
import { isSet } from '@shared';
import { UploadFieldComponent } from '../file-field/upload-field.component';
var VideoFieldComponent = /** @class */ (function (_super) {
    __extends(VideoFieldComponent, _super);
    function VideoFieldComponent(sanitizer, modelService, currentProjectStore, currentEnvironmentStore, storageService, resourceControllerService, cd) {
        var _this = _super.call(this, modelService, currentProjectStore, currentEnvironmentStore, storageService, resourceControllerService, cd) || this;
        _this.sanitizer = sanitizer;
        _this.modelService = modelService;
        _this.currentProjectStore = currentProjectStore;
        _this.currentEnvironmentStore = currentEnvironmentStore;
        _this.storageService = storageService;
        _this.resourceControllerService = resourceControllerService;
        _this.cd = cd;
        _this.outputFormats = VideoOutputFormat;
        return _this;
    }
    VideoFieldComponent.prototype.ngOnInit = function () {
        this.updateStorage();
        this.updateUploaded();
        this.initOutputs();
    };
    VideoFieldComponent.prototype.ngOnDestroy = function () { };
    VideoFieldComponent.prototype.ngOnChanges = function (changes) {
        this.updateStorage();
        this.updateUploaded();
    };
    VideoFieldComponent.prototype.getUploadUrl = function (value) {
        if (!isSet(value)) {
            return value;
        }
        if (this.field.params['output_format'] == VideoOutputFormat.YoutubeID) {
            return "https://www.youtube.com/watch?v=" + value;
        }
        else if (this.field.params['output_format'] == VideoOutputFormat.VimeoID) {
            return "https://vimeo.com/" + value;
        }
        else {
            return value;
        }
    };
    VideoFieldComponent.prototype.onUploadedUpdated = function () {
        if (!this.uploadedFile) {
            this.previewFile = undefined;
            this.previewEmbed = undefined;
            this.cd.markForCheck();
            return;
        }
        var youtubeMatch = /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/.exec(this.uploadedFile.url);
        var vimeoMatch = /(http|https)?:\/\/(www\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|)(\d+)(?:|\/\?)/.exec(this.uploadedFile.url);
        var previewFile;
        var previewEmbed;
        if (youtubeMatch) {
            previewEmbed = "https://www.youtube.com/embed/" + youtubeMatch[5] + "?origin=https://plyr.io&amp;iv_load_policy=3&amp;modestbranding=1&amp;playsinline=1&amp;showinfo=0&amp;rel=0&amp;enablejsapi=1";
        }
        else if (vimeoMatch) {
            previewEmbed = "https://player.vimeo.com/video/" + vimeoMatch[4] + "?loop=false&amp;byline=false&amp;portrait=false&amp;title=false&amp;speed=true&amp;transparent=0&amp;gesture=media";
        }
        else {
            previewFile = this.uploadedFile.url;
        }
        this.previewFile = previewFile;
        this.previewEmbed = previewEmbed ? this.sanitizer.bypassSecurityTrustResourceUrl(previewEmbed) : undefined;
        this.cd.markForCheck();
    };
    return VideoFieldComponent;
}(UploadFieldComponent));
export { VideoFieldComponent };
registerFieldComponent(FieldType.Video, VideoFieldComponent);

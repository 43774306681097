<div
  class="action-button action-button_manual-margin"
  [class.action-button_vertical]="element.orientation == orientations.Vertical"
>
  <app-action-group-element-item
    *ngFor="let item of element.actions; let first = first; let last = last"
    [item]="item"
    [context]="context"
    [fill]="element.orientation == orientations.Vertical"
    [left]="element.orientation == orientations.Horizontal && first && !last"
    [right]="element.orientation == orientations.Horizontal && !first && last"
    [top]="element.orientation == orientations.Vertical && first && !last"
    [bottom]="element.orientation == orientations.Vertical && !first && last"
    [middle]="!first && !last"
    [accentColor]="'accentColor' | appThemeColorHexOption | async"
    [analyticsSource]="element.analyticsGenericName"
  ></app-action-group-element-item>
</div>

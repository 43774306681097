/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../common/tips/directives/tip/tip.directive";
import * as i2 from "@angular/common";
import * as i3 from "../icon-selector-stub/icon-selector-stub.component.ngfactory";
import * as i4 from "../icon-selector-stub/icon-selector-stub.component";
import * as i5 from "../../../../common/unique-id/pipes/unique-id/unique-id.pipe";
import * as i6 from "../../../../common/unique-id/services/unique-id/unique-id.service";
import * as i7 from "../../../../shared/pipes/is-set/is-set.pipe";
import * as i8 from "./icon-selector-images.component";
import * as i9 from "../../../storages-queries/services/storage/storage.service";
import * as i10 from "../../../storages-components/services/storage-upload-popup-controller/storage-upload-popup.controller";
var styles_IconSelectorImagesComponent = [];
var RenderType_IconSelectorImagesComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_IconSelectorImagesComponent, data: {} });
export { RenderType_IconSelectorImagesComponent as RenderType_IconSelectorImagesComponent };
function View_IconSelectorImagesComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "select-icon__item"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "a", [["class", "select-icon-item select-icon-item_interactive"], ["href", "javascript:void(0)"]], [[2, "select-icon-item_current", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.selected.emit(_v.context.$implicit.path) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(2, 737280, null, 0, i1.TipDirective, [i0.ElementRef, i0.ComponentFactoryResolver, i0.Injector, i0.ApplicationRef], { content: [0, "content"], optionsRaw: [1, "optionsRaw"] }, null), i0.ɵpod(3, { showDelay: 0 }), (_l()(), i0.ɵeld(4, 0, null, null, 0, "span", [["class", "select-icon-item__icon"]], [[4, "background-image", null]], null, null, null, null))], function (_ck, _v) { var currVal_1 = _v.context.$implicit.name; var currVal_2 = _ck(_v, 3, 0, 300); _ck(_v, 2, 0, currVal_1, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.current == _v.context.$implicit.path); _ck(_v, 1, 0, currVal_0); var currVal_3 = (("url(" + _v.context.$implicit.url) + ")"); _ck(_v, 4, 0, currVal_3); }); }
function View_IconSelectorImagesComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["class", "select-icon__items-empty"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" No uploaded icons found "]))], null, null); }
function View_IconSelectorImagesComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_IconSelectorImagesComponent_2)), i0.ɵdid(2, 278528, null, 0, i2.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_IconSelectorImagesComponent_3)), i0.ɵdid(4, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵppd(5, 1), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.filteredIcons; _ck(_v, 2, 0, currVal_0); var currVal_1 = (i0.ɵunv(_v, 4, 0, _ck(_v, 5, 0, i0.ɵnov(_v.parent, 1), _co.search)) && !_co.filteredIcons.length); _ck(_v, 4, 0, currVal_1); }, null); }
function View_IconSelectorImagesComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-icon-selector-stub", [], null, null, null, i3.View_IconSelectorStubComponent_0, i3.RenderType_IconSelectorStubComponent)), i0.ɵdid(1, 638976, null, 0, i4.IconSelectorStubComponent, [], { icons: [0, "icons"] }, null)], function (_ck, _v) { var currVal_0 = 14; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_IconSelectorImagesComponent_0(_l) { return i0.ɵvid(2, [i0.ɵpid(0, i5.UniqueIdPipe, [i6.UniqueIdService]), i0.ɵpid(0, i7.IsSetPipe, []), (_l()(), i0.ɵeld(2, 0, null, null, 17, "div", [["class", "select-icon__upload"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 16, "div", [["class", "field"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 13, "div", [["class", "field__value"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 12, "div", [["class", "upload"], ["style", "height: 90px;"]], [[2, "upload_empty", null], [2, "upload_uploadable", null], [2, "upload_active", null]], [[null, "dragover"], [null, "dragleave"], [null, "drop"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("dragover" === en)) {
        var pd_0 = (_co.onDragOver($event) !== false);
        ad = (pd_0 && ad);
    } if (("dragleave" === en)) {
        var pd_1 = (_co.onDragLeave($event) !== false);
        ad = (pd_1 && ad);
    } if (("drop" === en)) {
        var pd_2 = (_co.onDrop($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 6, "div", [["class", "upload__placeholder"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 0, "div", [["class", "upload__placeholder-icon icon-cloud_upload"]], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 4, "div", [["class", "upload__placeholder-label"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Drop files here or "])), (_l()(), i0.ɵeld(10, 0, null, null, 2, "label", [["class", "upload__placeholder-button"]], [[8, "htmlFor", 0]], null, null, null, null)), i0.ɵppd(11, 2), (_l()(), i0.ɵted(-1, null, ["Choose files"])), (_l()(), i0.ɵeld(13, 0, null, null, 2, "div", [["class", "upload__controls"]], null, null, null, null, null)), (_l()(), i0.ɵeld(14, 0, null, null, 1, "input", [["class", "input input_file"], ["multiple", ""], ["type", "file"]], [[8, "accept", 0], [8, "id", 0]], [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.onFileChanged($event.target) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵppd(15, 2), (_l()(), i0.ɵeld(16, 0, null, null, 1, "label", [["class", "upload__overlay"]], [[8, "htmlFor", 0]], null, null, null, null)), i0.ɵppd(17, 2), (_l()(), i0.ɵeld(18, 0, null, null, 1, "div", [["class", "field__description"]], null, null, null, null, null)), (_l()(), i0.ɵted(19, null, ["Allowed files are ", ". Recommended size is 48x48."])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_IconSelectorImagesComponent_1)), i0.ɵdid(21, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_IconSelectorImagesComponent_4)), i0.ɵdid(23, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_8 = !_co.iconsLoading; _ck(_v, 21, 0, currVal_8); var currVal_9 = (_co.loading || _co.iconsLoading); _ck(_v, 23, 0, currVal_9); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = true; var currVal_1 = true; var currVal_2 = _co.draggingOver; _ck(_v, 5, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = i0.ɵunv(_v, 10, 0, _ck(_v, 11, 0, i0.ɵnov(_v, 0), "upload", _co.idToken)); _ck(_v, 10, 0, currVal_3); var currVal_4 = _co.extensions.join(","); var currVal_5 = i0.ɵunv(_v, 14, 1, _ck(_v, 15, 0, i0.ɵnov(_v, 0), "upload", _co.idToken)); _ck(_v, 14, 0, currVal_4, currVal_5); var currVal_6 = i0.ɵunv(_v, 16, 0, _ck(_v, 17, 0, i0.ɵnov(_v, 0), "upload", _co.idToken)); _ck(_v, 16, 0, currVal_6); var currVal_7 = _co.extensions.join(", "); _ck(_v, 19, 0, currVal_7); }); }
export function View_IconSelectorImagesComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-icon-selector-images", [], null, null, null, View_IconSelectorImagesComponent_0, RenderType_IconSelectorImagesComponent)), i0.ɵdid(1, 770048, null, 0, i8.IconSelectorImagesComponent, [i9.StorageService, i10.StorageUploadPopupController, i0.Injector, i0.ComponentFactoryResolver, i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var IconSelectorImagesComponentNgFactory = i0.ɵccf("app-icon-selector-images", i8.IconSelectorImagesComponent, View_IconSelectorImagesComponent_Host_0, { current: "current", search: "search", loading: "loading" }, { selected: "selected" }, []);
export { IconSelectorImagesComponentNgFactory as IconSelectorImagesComponentNgFactory };

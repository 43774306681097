import { Injectable, Injector } from '@angular/core';
import { FormArray } from '@angular/forms';
import isEqual from 'lodash/isEqual';
import range from 'lodash/range';

import { MenuBlock } from '@modules/menu';

import { MenuBlockControl, MenuBlockControlOptions } from './menu-block.control';

@Injectable()
export class MenuBlockArray extends FormArray {
  controls: MenuBlockControl[];
  controlsFixedLength = 3;

  constructor(private injector: Injector, controls: MenuBlockControl[]) {
    super(controls);
  }

  static inject(injector: Injector, controls: MenuBlockControl[]): MenuBlockArray {
    return Injector.create({
      providers: [
        {
          provide: MenuBlockArray,
          useFactory: () => {
            return new MenuBlockArray(injector, controls);
          },
          deps: []
        }
      ],
      parent: injector
    }).get(MenuBlockArray);
  }

  deserialize(value: MenuBlock[]) {
    value.forEach((item, i) => {
      const control = this.controls[i] || this.appendControl();
      control.deserialize(item);
    });

    this.controls.slice(value.length).forEach(item => this.removeControl(item));

    range(this.controlsFixedLength - value.length).forEach(() => {
      const block = new MenuBlock();

      block.generateUid();
      block.enabled = false;

      this.appendControl(block);
    });
  }

  serialize(): MenuBlock[] {
    return this.controls.map(control => control.serialize());
  }

  setControls(controls: MenuBlockControl[]) {
    this.removeControls();
    controls.forEach(item => this.push(item));
  }

  removeControls() {
    range(this.controls.length).forEach(() => this.removeAt(0));
  }

  removeControl(control: MenuBlockControl) {
    const newControls = this.controls.filter(item => !isEqual(item, control));
    this.setControls(newControls);
  }

  createControl(item?: MenuBlock, state?: MenuBlockControlOptions): MenuBlockControl {
    const control = MenuBlockControl.inject(this.injector, state || {});

    if (item) {
      control.deserialize(item);
    }

    control.markAsPristine();
    return control;
  }

  appendControl(item?: MenuBlock, state?: MenuBlockControlOptions): MenuBlockControl {
    const control = this.createControl(item, state);
    this.push(control);
    return control;
  }
}

import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import { combineLatest, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { ActionStore } from '@modules/action-queries';
import { ProjectSettingsStore } from '@modules/all-project-settings';
import { TaskQueueStore } from '@modules/collaboration';
import { CustomViewsStore } from '@modules/custom-views';
import { MenuSettingsStore } from '@modules/menu';
import { ModelDescriptionStore } from '@modules/model-queries';
import { PagePermissionsService, ViewSettingsStore, ViewSettingsType } from '@modules/customize';
import { CurrentEnvironmentStore, CurrentProjectStore, DraftItemsType, ProjectGroupService, ProjectPermissionType, ProjectPropertyStore } from '@modules/projects';
var PublishService = /** @class */ (function () {
    function PublishService(currentProjectStore, currentEnvironmentStore, modelDescriptionStore, actionStore, viewSettingsStore, menuSettingsStore, customViewsStore, taskQueueStore, projectPropertyStore, projectSettingsStore, pagePermissionsService, projectGroupService) {
        this.currentProjectStore = currentProjectStore;
        this.currentEnvironmentStore = currentEnvironmentStore;
        this.modelDescriptionStore = modelDescriptionStore;
        this.actionStore = actionStore;
        this.viewSettingsStore = viewSettingsStore;
        this.menuSettingsStore = menuSettingsStore;
        this.customViewsStore = customViewsStore;
        this.taskQueueStore = taskQueueStore;
        this.projectPropertyStore = projectPropertyStore;
        this.projectSettingsStore = projectSettingsStore;
        this.pagePermissionsService = pagePermissionsService;
        this.projectGroupService = projectGroupService;
    }
    PublishService.prototype.reloadChanges = function (draftChanges) {
        var wait = [];
        if (draftChanges.find(function (item) { return item.type == DraftItemsType.ViewSettings; })) {
            wait.push(this.viewSettingsStore.getFirst(true));
        }
        if (draftChanges.find(function (item) { return item.type == DraftItemsType.ModelDescriptions; })) {
            wait.push(this.modelDescriptionStore.getFirst(true));
        }
        if (draftChanges.find(function (item) { return item.type == DraftItemsType.ActionDescriptions; })) {
            wait.push(this.actionStore.getFirst(true));
        }
        if (draftChanges.find(function (item) { return item.type == DraftItemsType.MenuSettings; })) {
            wait.push(this.menuSettingsStore.getFirst(true));
        }
        if (draftChanges.find(function (item) { return item.type == DraftItemsType.CustomViews; })) {
            wait.push(this.customViewsStore.getFirst(true));
        }
        if (draftChanges.find(function (item) { return item.type == DraftItemsType.Resources; }) ||
            draftChanges.find(function (item) { return item.type == DraftItemsType.SecretTokens; }) ||
            draftChanges.find(function (item) { return item.type == DraftItemsType.Storages; })) {
            wait.push(this.currentProjectStore.getFirst(true));
        }
        if (draftChanges.find(function (item) { return item.type == DraftItemsType.TaskQueues; })) {
            wait.push(this.taskQueueStore.getFirst(true));
        }
        if (draftChanges.find(function (item) { return item.type == DraftItemsType.ProjectProperties; })) {
            wait.push(this.projectPropertyStore.getFirst(true));
        }
        if (draftChanges.find(function (item) { return item.type == DraftItemsType.ProjectSettings; })) {
            wait.push(this.projectSettingsStore.getFirst(true));
        }
        return wait.length ? combineLatest.apply(void 0, wait).pipe(map(function () { return true; })) : of(false);
    };
    PublishService.prototype.updatePagePermissions = function () {
        var _this = this;
        return combineLatest(this.projectGroupService.get(this.currentProjectStore.instance.uniqueName, this.currentEnvironmentStore.instance.uniqueName), this.viewSettingsStore
            .getFirst()
            .pipe(map(function (result) { return result.filter(function (item) { return item.view == ViewSettingsType.Custom; }); }))).pipe(switchMap(function (_a) {
            var groups = _a[0], pages = _a[1];
            groups = groups.filter(function (group) { return !group.permissionsGroup; });
            if (!groups.length) {
                return of(false);
            }
            return combineLatest.apply(void 0, groups.map(function (group) {
                var pagePermissions = group.permissions.filter(function (item) { return item.permissionType == ProjectPermissionType.Page; });
                var existingModelPermissions = group.permissions.filter(function (item) { return item.permissionType == ProjectPermissionType.Model; });
                var newModelPermissions = _this.pagePermissionsService.getModelPermissionsFromPagePermissions(pagePermissions, existingModelPermissions, pages, _this.actionStore.instance);
                if (!isEqual(existingModelPermissions, newModelPermissions)) {
                    var newGroup = cloneDeep(group);
                    newGroup.permissions = group.permissions.filter(function (item) { return item.permissionType != ProjectPermissionType.Model; }).concat(newModelPermissions);
                    return _this.projectGroupService.update(_this.currentProjectStore.instance.uniqueName, _this.currentEnvironmentStore.instance.uniqueName, newGroup);
                }
                else {
                    return of(false);
                }
            })).pipe(map(function () { return true; }));
        }));
    };
    return PublishService;
}());
export { PublishService };

import pickBy from 'lodash/pickBy';

import { ParameterField } from '@modules/fields';
import { User } from '@modules/users';

import { TaskStatus } from './task-status';

export class TaskQueue {
  public uid: string;
  public name: string;
  public author: User;
  public statuses: TaskStatus[] = [];
  public parameters: ParameterField[] = [];
  public params = {};
  public ordering: number;
  public draft = false;
  public deleted = false;
  public dateAdd: string;

  deserialize(data: Object): TaskQueue {
    if (data['uid']) {
      this.uid = data['uid'];
    }
    if (data['name']) {
      this.name = data['name'];
    }
    if (data['author']) {
      this.author = new User().deserialize(data['author']);
    }
    if (data['statuses']) {
      this.statuses = data['statuses'].map(status => new TaskStatus().deserialize(status));
    }
    if (data['params']) {
      this.params = data['params'];
    }
    if (data['date_add']) {
      this.dateAdd = data['date_add'];
    }

    if (this.params['parameters']) {
      this.parameters = this.params['parameters'].map(status => new ParameterField().deserialize(status));
    }

    if (data['draft'] !== undefined) {
      this.draft = data['draft'];
    }

    if (data['deleted'] !== undefined) {
      this.deleted = data['deleted'];
    }

    return this;
  }

  serialize(fields?: string[]): Object {
    this.params = {
      parameters: this.parameters.map(item => item.serialize())
    };

    let data: Object = {
      uid: this.uid,
      name: this.name,
      params: this.params,
      statuses: this.statuses.map(item => item.serialize()),
      ordering: this.ordering,
      draft: this.draft,
      deleted: this.deleted
    };
    if (fields) {
      data = <Object>pickBy(data, (v, k) => fields.includes(k));
    }
    return data;
  }
}

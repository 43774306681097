import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import clamp from 'lodash/clamp';

import { FieldType, registerFieldComponent } from '@modules/fields';
import { isSet } from '@shared';

import { FieldComponent } from '../field/field.component';

@Component({
  selector: 'app-rating-field',
  templateUrl: 'rating-field.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RatingFieldComponent extends FieldComponent implements OnInit, OnChanges {
  defaultMaxValue = 5;
  defaultAllowHalf = false;
  maxValue = this.defaultMaxValue;
  allowHalf = this.defaultAllowHalf;
  hoverValue: number;
  displayValue: number;
  size = 16;

  constructor(private cd: ChangeDetectorRef) {
    super();
  }

  ngOnInit(): void {
    this.updateMaxValue();
    this.updateAllowHalf();
    this.updateSize();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.updateMaxValue();
    this.updateAllowHalf();
    this.updateSize();
  }

  updateMaxValue() {
    this.maxValue = clamp(this.field.params['max_value'] || this.defaultMaxValue, 1, 10);
  }

  updateAllowHalf() {
    this.allowHalf = isSet(this.field.params['allow_half']) ? this.field.params['allow_half'] : this.defaultAllowHalf;
  }

  updateSize() {
    const classes = isSet(this.field.params['classes']) ? this.field.params['classes'] : [];

    if (classes.includes('input_small')) {
      this.size = 12;
    } else {
      this.size = 16;
    }
  }

  toggleCurrentValue(value: number) {
    if (this.currentValue !== value) {
      this.setCurrentValue(value);
    } else {
      this.setCurrentValue(0);
    }
  }

  isRatingValueGte(value: number, i: number, left: boolean) {
    const itemValue = left && this.allowHalf ? 0.5 : 1;
    return value >= i + itemValue;
  }

  getRatingValue(i: number, left: boolean) {
    const itemValue = left && this.allowHalf ? 0.5 : 1;
    return i + itemValue;
  }

  setHoverValue(i: number, left: boolean) {
    this.hoverValue = this.getRatingValue(i, left);
    this.displayValue = this.hoverValue;
    this.cd.markForCheck();
  }

  setDisplayValue(value: number) {
    this.displayValue = value;
    this.cd.markForCheck();
  }

  clearHoverValue() {
    this.hoverValue = undefined;
    this.displayValue = this.hoverValue;
    this.cd.markForCheck();
  }
}

registerFieldComponent(FieldType.Rating, RatingFieldComponent);

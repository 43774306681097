import { fromLegacyModel } from '@modules/models';

import { MenuItem } from './menu-item';
import { MenuItemType } from './menu-item-type';
import { registerMenuItemForType } from './menu-items';

export class ModelLinkMenuItem extends MenuItem {
  public type = MenuItemType.ModelLink;
  public title: string;
  public icon: string;
  public model: string;

  deserialize(data: Object): ModelLinkMenuItem {
    super.deserialize(data);
    this.title = this.params['title'];
    this.icon = this.params['icon'];
    this.model = fromLegacyModel(this.params['model']);

    if (this.model && !this.model.includes('.')) {
      this.model = ['jet_bridge', this.model].join('.');
    }

    return this;
  }

  serialize(): Object {
    const data = super.serialize();
    data['params']['title'] = this.title;
    data['params']['icon'] = this.icon;
    data['params']['model'] = this.model;
    return data;
  }

  copyFrom(item: ModelLinkMenuItem) {
    super.copyFrom(item);
    this.title = item.title;
    this.icon = item.icon;
    this.model = item.model;
  }

  clone(): ModelLinkMenuItem {
    const instance = new ModelLinkMenuItem();
    instance.copyFrom(this);
    return instance;
  }
}

registerMenuItemForType(MenuItemType.ModelLink, ModelLinkMenuItem);

<div class="input2">
  <div *ngIf="iconLeft" class="input2__icon input2__icon_left" [ngClass]="'icon-' + iconLeft"></div>

  <input
    [formControl]="control"
    type="text"
    class="input2__control"
    [class.input2__control_icon-left]="iconLeft"
    [class.input2__control_icon-right]="iconRight"
    [class.input2__control_error]="(control | appControlErrors: true)?.length"
    [placeholder]="placeholder"
    [appAutofocus]="autofocus"
    [tabIndex]="inputControl ? null : -1"
    [autocomplete]="autocomplete"
  />

  <div *ngIf="iconRight" class="input2__icon input2__icon_right" [ngClass]="'icon-' + iconRight"></div>

  <div *ngIf="(control | appControlErrors: true)?.length" class="input2__error">
    {{ (control | appControlErrors: true)[0] }}
  </div>
</div>

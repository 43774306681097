<div class="code">
  <pre *appCode="language" appCopyText class="code__content"><ng-content></ng-content></pre>
  <a
    *ngIf="copyEnabled"
    href="javascript:void(0)"
    class="icon icon-copy code__copy"
    (click)="copy()"
    [appTip]="'Copy to clipboard'"
  ></a>
</div>

var AppVersion = /** @class */ (function () {
    function AppVersion() {
        this.active = false;
        this.params = {};
        this.latest = false;
    }
    AppVersion.prototype.deserialize = function (data) {
        this.name = data['name'];
        this.active = data['active'];
        this.dateAdd = data['date_add'];
        if (data['params']) {
            this.params = data['params'];
        }
        if (data['latest'] != undefined) {
            this.latest = data['latest'];
        }
        if (this.params) {
            this.notice = this.params['notice'];
        }
        return this;
    };
    Object.defineProperty(AppVersion.prototype, "str", {
        get: function () {
            if (this.notice) {
                return this.name + " - " + this.notice;
            }
            else {
                return this.name;
            }
        },
        enumerable: true,
        configurable: true
    });
    return AppVersion;
}());
export { AppVersion };

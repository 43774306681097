import { Directive, Input, OnChanges, OnInit, SimpleChanges, TemplateRef, ViewContainerRef } from '@angular/core';
import highlightjs from 'highlight.js/lib/highlight';
import bash from 'highlight.js/lib/languages/bash';
import javascript from 'highlight.js/lib/languages/javascript';
import python from 'highlight.js/lib/languages/python';
import typescript from 'highlight.js/lib/languages/typescript';

const languages = [
  { name: 'bash', value: bash },
  { name: 'javascript', value: javascript },
  { name: 'typescript', value: typescript },
  { name: 'python', value: python }
];

languages.forEach(item => highlightjs.registerLanguage(item.name, item.value));

@Directive({
  selector: '[appCode]'
})
export class CodeDirective implements OnInit, OnChanges {
  @Input() appCode: string;

  constructor(private container: ViewContainerRef, private template: TemplateRef<any>) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    this.container.clear();

    const viewRef = this.container.createEmbeddedView(this.template);
    const code = viewRef.rootNodes[0].textContent;
    const highlightedCode = highlightjs.highlight(this.appCode, code).value;

    viewRef.rootNodes[0].innerHTML = highlightedCode || code;
  }
}

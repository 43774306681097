<div class="card-row collaboration-messages__row">
  <div class="card-row__inner">
    <div class="collaboration-messages__list-status-dot"></div>
    <div class="card-row__col collaboration-messages__list-col-avatar">
      <div class="card-row__col-inner">
        <div
          [style.background-image]="item.userActivity.user.photo ? 'url(' + item.userActivity.user.photo + ')' : ''"
          class="collaboration-messages__list-avatar"
        ></div>
      </div>
    </div>

    <div class="card-row__col collaboration-messages__list-col-message">
      <div class="card-row__col-inner">
        <div class="collaboration-messages__list-message-wrap">
          <span class="collaboration-messages__list-name"
            >{{ item.userActivity.user.firstName }} {{ item.userActivity.user.lastName }}
            <span class="collaboration-messages__list-dot"></span>
            <span class="collaboration-messages__list-date">{{ item.dateAdd | appTimeAgo: true }}</span></span
          >
          <p class="collaboration-messages__list-message" [innerHTML]="message | appFormatText"></p>
        </div>
      </div>
    </div>

    <!--    <div class="card-row__col collaboration-messages__list-col-description">-->
    <!--      <div class="card-row__col-inner">-->
    <!--        <p class="collaboration-messages__list-description">Some description...</p>-->
    <!--      </div>-->
    <!--    </div>-->

    <!--    <div class="card-row__col collaboration-messages__list-col-counter">-->
    <!--      <div class="card-row__col-inner">-->
    <!--        <div class="collaboration-messages__list-counter">-->
    <!--          <span class="collaboration-messages__list-counter-icon icon-chat"></span>9-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->

    <!--    <div class="card-row__col collaboration-messages__list-col-menu">-->
    <!--      <div class="card-row__col-inner">-->
    <!--        <span class="collaboration-messages__list-menu-icon icon-dots"></span>-->
    <!--      </div>-->
    <!--    </div>-->
  </div>
</div>

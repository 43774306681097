var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { JET_APP_GROUPS, JET_APP_USERS, ModelAction } from '@modules/models';
import { JET_APP_RESOURCE } from '@modules/projects';
import { AutomationTriggerType } from '../automation-trigger-type';
import { registerAutomationTriggerForType } from '../automation-trigger-types';
import { AutomationTrigger } from './base';
var ModelAutomationTrigger = /** @class */ (function (_super) {
    __extends(ModelAutomationTrigger, _super);
    function ModelAutomationTrigger() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.type = AutomationTriggerType.Model;
        return _this;
    }
    ModelAutomationTrigger.prototype.deserialize = function (data) {
        this.resource = data['resource'];
        this.model = data['model'];
        this.action = data['action'];
        return this;
    };
    ModelAutomationTrigger.prototype.serialize = function () {
        return {
            resource: this.resource,
            model: this.model,
            action: this.action
        };
    };
    ModelAutomationTrigger.prototype.getTypeStr = function () {
        if (this.resource == JET_APP_RESOURCE && this.model == JET_APP_USERS) {
            if (this.action == ModelAction.Create) {
                return 'on user joined';
            }
            else if (this.action == ModelAction.Update) {
                return 'on user updated';
            }
            else if (this.action == ModelAction.Delete) {
                return 'on user deleted';
            }
        }
        else if (this.resource == JET_APP_RESOURCE && this.model == JET_APP_GROUPS) {
            if (this.action == ModelAction.Create) {
                return 'on team created';
            }
            else if (this.action == ModelAction.Update) {
                return 'on team updated';
            }
            else if (this.action == ModelAction.Delete) {
                return 'on team deleted';
            }
        }
        return _super.prototype.getTypeStr.call(this);
    };
    ModelAutomationTrigger.prototype.getTypeIcon = function () {
        if (this.resource == JET_APP_RESOURCE && this.model == JET_APP_USERS) {
            return 'users_teams';
        }
        else if (this.resource == JET_APP_RESOURCE && this.model == JET_APP_GROUPS) {
            return 'users_teams';
        }
        else {
            return _super.prototype.getTypeIcon.call(this);
        }
    };
    return ModelAutomationTrigger;
}(AutomationTrigger));
export { ModelAutomationTrigger };
registerAutomationTriggerForType(AutomationTriggerType.Model, ModelAutomationTrigger);

import { Injectable } from '@angular/core';
import flatten from 'lodash/flatten';
import { Observable } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

import { CurrentProjectStore } from '@modules/projects';
import { SingletonStore } from '@shared';

import { GuideSection } from '../data/guide-section';
import { GuideTask } from '../data/guide-task';
import { GuideSectionService } from '../services/guide-section/guide-section.service';

@Injectable()
export class GuideSectionStore extends SingletonStore<GuideSection[]> {
  constructor(private guideSectionService: GuideSectionService, private currentProjectStore: CurrentProjectStore) {
    super();
  }

  protected fetch(): Observable<GuideSection[]> {
    return this.currentProjectStore.getFirst().pipe(
      filter(() => this.currentProjectStore.instance != undefined),
      switchMap(() => this.guideSectionService.get(this.currentProjectStore.instance.uniqueName)),
      map(result => {
        if (!result) {
          return [];
        }

        return result;
      })
    );
  }

  getTasks(forceUpdate = false): Observable<GuideTask[]> {
    return this.getFirst(forceUpdate).pipe(
      map(result => {
        if (!result) {
          return;
        }

        return flatten(result.map(item => item.tasks));
      })
    );
  }
}

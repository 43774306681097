import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { debounceTime } from 'rxjs/operators';

import { UniversalAnalyticsService } from '@modules/analytics';
import { cleanElementName, StepsElementItem, ViewContext, ViewContextElement } from '@modules/customize';
import { Option } from '@modules/field-components';
import { createFormFieldFactory, FieldType } from '@modules/fields';
import { QueryBuilderContext } from '@modules/queries-components';
import { SidebarCollapseContext } from '@modules/sidebar';
import { controlValue } from '@shared';

import { CustomizeBarEditComponent } from '../../data/customize-bar-edit-component';
import { CustomizeBarEditEvent } from '../../data/customize-bar-edit-event';
import { CustomizeBarEditEventType } from '../../data/customize-bar-edit-event-type';
import { CustomizeBarContext } from '../../services/customize-bar-context/customize-bar.context';
import { CustomizeBarStepsEditForm, CustomizeBarStepsItemControl } from './customize-bar-steps-edit.form';

@Component({
  selector: 'app-customize-bar-steps-edit',
  templateUrl: './customize-bar-steps-edit.component.html',
  providers: [CustomizeBarStepsEditForm, QueryBuilderContext],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomizeBarStepsEditComponent implements OnInit, OnDestroy, CustomizeBarEditComponent {
  @Input() element: StepsElementItem;
  @Input() context: ViewContext;
  @Input() contextElement: ViewContextElement;
  @Input() titleEnabled = true;
  @Input() titleEditable: boolean;
  @Input() deleteEnabled = false;
  @Input() parentElement: any;
  @Input() parentPopup: any;
  @Output() event = new EventEmitter<CustomizeBarEditEvent>();

  createField = createFormFieldFactory();
  collapseContext = new SidebarCollapseContext();
  createdStep: CustomizeBarStepsItemControl;
  itemOptions: Option[] = [];
  fieldTypes = FieldType;

  titleCleanValue = (() => {
    return (value: string): string => {
      return cleanElementName(value, this.element, this.context.getElementItems());
    };
  })();

  constructor(
    public form: CustomizeBarStepsEditForm,
    private customizeBarContext: CustomizeBarContext,
    private cd: ChangeDetectorRef,
    private analyticsService: UniversalAnalyticsService
  ) {}

  ngOnInit() {
    this.form.init(this.element);

    this.form.valueChanges.pipe(debounceTime(200), untilDestroyed(this)).subscribe(() => {
      this.emitUpdate(this.form.submit());
    });

    controlValue(this.form.controls.items)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.itemOptions = this.form.controls.items.serialize().map(item => {
          return {
            value: item.value,
            name: item.name
          };
        });
        this.cd.markForCheck();
      });
  }

  ngOnDestroy(): void {}

  onTitleChanged(title: string) {
    this.form.controls.name.setValue(title);
  }

  close() {
    this.customizeBarContext.popSettingsComponent();
  }

  submit() {
    const result = this.form.submit();

    this.emitUpdate(result, true);
    this.close();
  }

  emitUpdate(result: StepsElementItem, submit = false) {
    if (this.element) {
      this.event.emit({ type: CustomizeBarEditEventType.Updated, args: { result: result, submit: submit } });
    } else {
      this.event.emit({ type: CustomizeBarEditEventType.Created, args: { result: result, submit: submit } });
    }
  }

  cancel() {
    this.event.emit({ type: CustomizeBarEditEventType.Canceled });
    this.close();
  }

  delete() {
    this.event.emit({ type: CustomizeBarEditEventType.Deleted });
    this.close();
  }

  addStep() {
    const index = this.form.controls.items.length;

    this.createdStep = new CustomizeBarStepsItemControl({
      name: `Step ${index + 1}`,
      value: index + 1
    });
    this.form.controls.items.append(this.createdStep);
    this.cd.markForCheck();
  }

  dragDropOption(event: CdkDragDrop<CustomizeBarStepsItemControl[]>) {
    if (event.previousIndex !== event.currentIndex) {
      moveItemInArray(this.form.controls.items.controls, event.previousIndex, event.currentIndex);
      this.form.controls.items.updateValueAndValidity();
    }
  }
}

<div
  class="background-layout"
  [class.background-layout_fill-screen]="fillScreen"
  [class.background-layout_preview]="preview"
  [style.background-color]="(backgroundColor | appIsColorHex) ? backgroundColor : null"
  [ngClass]="backgroundColor && !(backgroundColor | appIsColorHex) ? 'background-color_' + backgroundColor + '_1' : ''"
>
  <div class="background-layout__content">
    <ng-content></ng-content>
    <ng-content select="[data-layout-footer]"></ng-content>
  </div>
</div>

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./popup-collapse.component";
var styles_PopupCollapseComponent = [];
var RenderType_PopupCollapseComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_PopupCollapseComponent, data: {} });
export { RenderType_PopupCollapseComponent as RenderType_PopupCollapseComponent };
export function View_PopupCollapseComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "div", [["class", "popup-collapse"]], [[2, "popup-collapse_opened", null]], null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 4, "div", [["class", "popup-collapse__header"]], [[2, "popup-collapse__header_border", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleOpened() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "div", [["class", "popup-collapse__header-main"]], null, null, null, null, null)), (_l()(), i0.ɵted(3, null, [" ", " "])), (_l()(), i0.ɵeld(4, 0, null, null, 1, "div", [["class", "popup-collapse__header-right"]], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 0, "span", [["class", "popup-collapse__arrow icon-arrow_down_2"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 1, "div", [["class", "popup-collapse__content"]], null, null, null, null, null)), i0.ɵncd(null, 0)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.opened; _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.border; _ck(_v, 1, 0, currVal_1); var currVal_2 = _co.title; _ck(_v, 3, 0, currVal_2); }); }
export function View_PopupCollapseComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-popup-collapse", [], null, null, null, View_PopupCollapseComponent_0, RenderType_PopupCollapseComponent)), i0.ɵdid(1, 114688, null, 0, i1.PopupCollapseComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PopupCollapseComponentNgFactory = i0.ɵccf("app-popup-collapse", i1.PopupCollapseComponent, View_PopupCollapseComponent_Host_0, { title: "title", opened: "opened", border: "border" }, {}, ["*"]);
export { PopupCollapseComponentNgFactory as PopupCollapseComponentNgFactory };

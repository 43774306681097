<div class="sidebar__header2">
  <div class="sidebar-header2">
    <div class="sidebar-header2__top">
      <div class="sidebar-header2__top-main">
        <div class="sidebar-header2-button sidebar-header2__top-item" (click)="close()">
          <span class="sidebar-header2-button__icon sidebar-header2-button__icon_left icon-close"></span>
          <span class="sidebar-header2-button__label">Close</span>
        </div>
      </div>
    </div>

    <!--    <div class="sidebar-header2__title">Workflow parameters</div>-->
  </div>
</div>

<div class="sidebar__content">
  <app-sidebar-tabs [initialTabIndex]="(triggerData | appIsSet) ? 1 : 0">
    <app-sidebar-tab [label]="'Settings'" [contentDisabled]="!workflowEditable">
      <app-sidebar-section *ngIf="triggerEditable">
        <div class="sidebar__element">
          <app-sidebar-field
            [label]="'Automation trigger'"
            [subtitle]="'A trigger is an event that starts your Automation'"
            [formGroup]="form"
          >
            <app-select
              [control]="form.controls.trigger_type"
              [placeholder]="'Choose trigger'"
              [orange]="true"
              [fill]="true"
              [expand]="true"
              [options]="form.triggerTypeOptions"
              [searchEnabled]="false"
              #type_select
            >
            </app-select>
          </app-sidebar-field>
        </div>

        <div *ngIf="form.controls.trigger_type.value == triggerTypes.Interval" class="sidebar__element">
          <app-sidebar-field [label]="'interval'" [additional]="'in minutes'">
            <app-auto-field
              [form]="form"
              [label]="false"
              [field]="
                createField({
                  name: 'trigger_interval',
                  field: 'NumberField',
                  required: false,
                  placeholder: 'Default is 15',
                  params: { classes: ['input_fill'], min_value: 1 }
                })
              "
            ></app-auto-field>
          </app-sidebar-field>
        </div>

        <ng-container *ngIf="form.controls.trigger_type.value == triggerTypes.Timetable">
          <div class="sidebar__element">
            <app-sidebar-field>
              <app-custom-select
                [control]="form.controls.trigger_timetable_type"
                [items]="form.timetableTypeOptions"
                [classes]="['select_fill']"
                [panelClasses]="['mat-menu-panel_sidebar']"
                [allowSearch]="false"
              >
              </app-custom-select>
            </app-sidebar-field>
          </div>

          <div
            *ngIf="[timetableTypes.Custom].includes(form.controls.trigger_timetable_type.value)"
            class="sidebar__element"
          >
            <app-sidebar-field [label]="'months'">
              <app-auto-field
                [form]="form"
                [label]="false"
                [field]="
                  createField({
                    name: 'trigger_timetable_months',
                    field: 'MultipleSelectField',
                    required: false,
                    params: {
                      classes: ['select_fill'],
                      options_type: optionsTypes.Static,
                      options: form.monthOptions,
                      valueEquals: exactValueEquals
                    }
                  })
                "
              ></app-auto-field>
            </app-sidebar-field>
          </div>

          <div
            *ngIf="[timetableTypes.EveryWeek].includes(form.controls.trigger_timetable_type.value)"
            class="sidebar__element"
          >
            <app-sidebar-field [label]="'days of week'">
              <app-auto-field
                [form]="form"
                [label]="false"
                [field]="
                  createField({
                    name: 'trigger_timetable_days_of_week',
                    field: 'MultipleSelectField',
                    required: false,
                    params: {
                      classes: ['select_fill'],
                      options_type: optionsTypes.Static,
                      options: form.dayOfWeekOptions,
                      valueEquals: exactValueEquals
                    }
                  })
                "
              ></app-auto-field>
            </app-sidebar-field>
          </div>

          <div
            *ngIf="
              [timetableTypes.EveryMonth, timetableTypes.Custom].includes(form.controls.trigger_timetable_type.value)
            "
            class="sidebar__element"
          >
            <app-sidebar-field [label]="'days'">
              <app-auto-field
                [form]="form"
                [label]="false"
                [field]="
                  createField({
                    name: 'trigger_timetable_days',
                    field: 'MultipleSelectField',
                    required: false,
                    params: {
                      classes: ['select_fill'],
                      options_type: optionsTypes.Static,
                      options: form.dayOptions,
                      valueEquals: exactValueEquals
                    }
                  })
                "
              ></app-auto-field>
            </app-sidebar-field>
          </div>

          <div
            *ngIf="[timetableTypes.Once].includes(form.controls.trigger_timetable_type.value)"
            class="sidebar__element"
          >
            <app-sidebar-field [label]="'date'">
              <app-auto-field
                [form]="form"
                [label]="false"
                [field]="
                  createField({
                    name: 'trigger_timetable_date',
                    field: 'DateTimeField',
                    required: false,
                    params: { classes: ['input_fill'] }
                  })
                "
              ></app-auto-field>
            </app-sidebar-field>
          </div>

          <div
            *ngIf="
              [
                timetableTypes.EveryDay,
                timetableTypes.EveryWeek,
                timetableTypes.EveryMonth,
                timetableTypes.Custom
              ].includes(form.controls.trigger_timetable_type.value)
            "
            class="sidebar__element"
          >
            <app-sidebar-field [label]="'time'">
              <app-auto-field
                [form]="form"
                [label]="false"
                [field]="
                  createField({
                    name: 'trigger_timetable_time',
                    field: 'TimeField',
                    required: false,
                    placeholder: '00:00',
                    params: { classes: ['input_fill'] }
                  })
                "
              ></app-auto-field>

              <ng-container description>
                Time zone is UTC (+0)
              </ng-container>
            </app-sidebar-field>
          </div>
        </ng-container>

        <ng-container *ngIf="form.controls.trigger_type.value == triggerTypes.Webhook">
          <div class="sidebar__element">
            <div class="sidebar-field">
              <div class="sidebar-field-layout-default">
                <div class="sidebar-field-layout-default__label sidebar-field__label">
                  <div class="sidebar-field__label-main">
                    <span class="sidebar-field__label-title">
                      Webhook URL
                      <a
                        href="javascript:void(0)"
                        class="field__label-link"
                        style="margin-left: 4px;"
                        (click)="copyWebhookUrl()"
                      >
                        <span class="field__label-link-icon icon-duplicate"></span>
                        Copy
                      </a>
                    </span>
                  </div>
                </div>

                <div class="sidebar-field-layout-default__content">
                  <div class="sidebar-field-layout-default__content-main">
                    <input
                      *ngIf="!webhookLoading && (webhookUrl | appIsSet)"
                      type="text"
                      class="input input_fill"
                      [value]="webhookUrl"
                      (click)="$event.target.select()"
                      readonly
                    />

                    <input
                      *ngIf="webhookLoading || !(webhookUrl | appIsSet)"
                      class="input input_disabled input_fill input_stub"
                      [class.loading-animation]="webhookLoading"
                      type="text"
                      [value]="'Webhook URL'"
                    />
                  </div>
                </div>

                <div class="sidebar-field-layout-default__description">
                  <div class="sidebar-field__description">
                    Automation is triggered by making HTTP request to the above Webhook URL.
                  </div>
                </div>
              </div>
            </div>
          </div>

          <ng-container *ngIf="!webhookLoading">
            <div *ngIf="webhook?.structureInitialized === false" class="sidebar__element">
              <app-alert [title]="'Webhook is being listened'" [loader]="true" [warning]="true">
                <div>
                  We are listening for the data to determine data structure from request.
                </div>
                <div style="margin-top: 4px;">
                  <a href="javascript:void(0)" (click)="copyWebhookUrl()">Copy Webhook URL</a>
                </div>
              </app-alert>
            </div>

            <div *ngIf="webhook?.structureInitialized === true" class="sidebar__element">
              <div class="sidebar-list-item">
                <div class="sidebar-list-item__left">
                  <span class="sidebar-list-item__icon icon-check_circle color_green_1"></span>
                </div>

                <div class="sidebar-list-item__main">
                  <div class="sidebar-list-item__title">Structure detected</div>
                  <div class="sidebar-list-item__description">
                    <ng-container *ngIf="outputs.length && arrayOutput">
                      array of {{ outputs.length | i18nPlural: { '=1': '# field', other: '# fields' } }}
                    </ng-container>
                    <ng-container *ngIf="outputs.length && !arrayOutput">
                      {{ outputs.length | i18nPlural: { '=1': '# field', other: '# fields' } }}
                    </ng-container>
                    <ng-container *ngIf="!outputs.length">
                      No fields
                    </ng-container>
                  </div>
                </div>

                <div class="sidebar-list-item__right">
                  <a
                    href="javascript:void(0)"
                    class="sidebar-list-item__action sidebar-list-item__action_button icon-repeat"
                    [appTip]="'Redetermine data structure'"
                    [appTipOptions]="{ side: 'top' }"
                    (click)="reinitializeStructure()"
                  ></a>
                </div>
              </div>
            </div>
          </ng-container>
        </ng-container>

        <ng-container *ngIf="form.controls.trigger_type.value == triggerTypes.Model">
          <div class="sidebar__element">
            <div class="sidebar__element-segment">
              <app-sidebar-field [label]="'choose operation'" [required]="true">
                <app-resource-field2
                  [form]="form"
                  [label]="false"
                  [errors]="false"
                  [field]="
                    createField({
                      name: 'trigger_model_resource',
                      field: 'ResourceField',
                      params: { action_resources: true, storage_resources: true, classes: ['select_fill'] }
                    })
                  "
                ></app-resource-field2>
              </app-sidebar-field>
            </div>

            <div class="sidebar__element-segment">
              <app-sidebar-field>
                <app-custom-select
                  [control]="form.controls.trigger_model_action"
                  [items]="actionItems"
                  [compareWith]="form.modelActionEquals"
                  [emptyLabel]="'No operations'"
                  [classes]="['select_fill']"
                  [panelClasses]="['mat-menu-panel_sidebar']"
                >
                </app-custom-select>
                <app-field-errors [form]="form" [fieldName]="'trigger_model_action'"></app-field-errors>
              </app-sidebar-field>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="form.controls.trigger_type.value == triggerTypes.Manual">
          <div class="sidebar__element">
            <app-alert [title]="'Run manually'" [secondary]="true">
              <div>
                This automation will only trigger when click "Run now" from Automations section.
              </div>
            </app-alert>
          </div>
        </ng-container>
      </app-sidebar-section>

      <app-sidebar-section
        *ngIf="outputs.length"
        [title]="'Trigger parameters'"
        [description]="'You can use these fields in other steps'"
      >
        <div class="sidebar__list">
          <app-sidebar-field [label]="arrayOutput ? 'array of fields' : undefined">
            <div class="sidebar-list">
              <div *ngFor="let item of outputs; let i = index; trackBy: trackOutput" class="sidebar-list__item">
                <app-customize-workflow-trigger-output
                  [item]="item"
                  [value]="parametersValue[item.name]"
                  [collapseContext]="collapseContext"
                  [openedInitial]="i == 0"
                  (valueUpdated)="updateParameterValue(item.name, $event)"
                >
                </app-customize-workflow-trigger-output>
              </div>
            </div>
          </app-sidebar-field>
        </div>
      </app-sidebar-section>

      <app-sidebar-section *ngIf="parametersEnabled" [title]="'Workflow parameters'">
        <div class="sidebar__list">
          <app-parameters-edit
            [control]="form.controls.parameters"
            [parametersValueEditable]="true"
            [parametersValue]="parametersValue"
            [placeholder]="'Test value'"
            [configurable]="{
              name: true,
              field: true,
              required: true,
              params: true,
              add: true,
              sortable: true
            }"
            [analyticsSource]="analyticsSource"
            (parameterValueUpdated)="updateParameterValue($event.name, $event.value)"
          >
          </app-parameters-edit>
        </div>
      </app-sidebar-section>
    </app-sidebar-tab>

    <app-sidebar-tab *ngIf="triggerData | appIsSet" [label]="'Input data'">
      <div class="sidebar__element">
        <app-json-viewer [content]="triggerData"></app-json-viewer>
      </div>
    </app-sidebar-tab>
  </app-sidebar-tabs>
</div>

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { moveItemInArray } from '@angular/cdk/drag-drop';
import { ChangeDetectorRef, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest } from 'rxjs';
import { auditTime } from 'rxjs/operators';
import { CustomizeBarContext } from '@modules/customize-bar';
import { LayerType } from '@modules/views';
import { registerCustomizeLayerComponent } from '../../../data/customize-layer-components';
import { ViewEditorContext } from '../../../services/view-editor-context/view-editor.context';
import { CustomizeLayerComponent } from '../base/base-customize-layer.component';
import { LineCustomizeLayerForm } from './line-customize-layer.form';
var LineCustomizeLayerComponent = /** @class */ (function (_super) {
    __extends(LineCustomizeLayerComponent, _super);
    function LineCustomizeLayerComponent(form, editorContext, customizeBarContext, cd) {
        var _this = _super.call(this) || this;
        _this.form = form;
        _this.editorContext = editorContext;
        _this.customizeBarContext = customizeBarContext;
        _this.cd = cd;
        _this.settingsComponents = [];
        return _this;
    }
    LineCustomizeLayerComponent.prototype.ngOnInit = function () {
        var _this = this;
        var layerContainer = this.editorContext.getLayerContainer(this.layer);
        this.form.init(this.layer, { firstInit: true, container: layerContainer });
        this.trackFormUpdates(this.form, function () { return _this.form.submit(); });
        combineLatest(this.layerUpdated$, this.editorContext.getLayerContainer$(this.layer))
            .pipe(auditTime(10), untilDestroyed(this))
            .subscribe(function (_a) {
            var layer = _a[0], container = _a[1];
            _this.pauseFormUpdates();
            _this.form.init(layer, {
                firstInit: false,
                container: container
            });
            _this.resumeFormUpdates();
        });
        this.form.containerLayer$.pipe(untilDestroyed(this)).subscribe(function (layer) {
            _this.containerLayer = layer;
            _this.cd.markForCheck();
        });
        this.customizeBarContext.settingsComponents$.pipe(untilDestroyed(this)).subscribe(function (item) {
            _this.settingsComponents = item;
            _this.cd.markForCheck();
        });
    };
    LineCustomizeLayerComponent.prototype.ngOnDestroy = function () { };
    LineCustomizeLayerComponent.prototype.bordersDragDrop = function (event) {
        if (event.previousIndex !== event.currentIndex) {
            moveItemInArray(this.form.controls.borders.controls, event.previousIndex, event.currentIndex);
            this.form.controls.borders.updateValueAndValidity();
        }
    };
    LineCustomizeLayerComponent.prototype.shadowsDragDrop = function (event) {
        if (event.previousIndex !== event.currentIndex) {
            moveItemInArray(this.form.controls.shadows.controls, event.previousIndex, event.currentIndex);
            this.form.controls.shadows.updateValueAndValidity();
        }
    };
    return LineCustomizeLayerComponent;
}(CustomizeLayerComponent));
export { LineCustomizeLayerComponent };
registerCustomizeLayerComponent(LayerType.Line, LineCustomizeLayerComponent);

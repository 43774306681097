<div class="popover2 overlay-content overlay-content_animated">
  <div class="popover2__inner">
    <div class="mat-menu">
      <div class="mat-menu-content">
        <app-action-menu-item
          *ngFor="let item of actions"
          [item]="item"
          [context]="context"
          [contextElement]="contextElement"
          [localContext]="localContext"
          [analyticsSource]="analyticsSource"
          (selected)="close()"
        ></app-action-menu-item>
      </div>
    </div>
  </div>
</div>

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';

import { CustomizeBarContext } from '../../../services/customize-bar-context/customize-bar.context';
import { CustomizeActionOptions, CustomizeBarActionEditForm } from '../customize-bar-action-edit.form';

@Component({
  selector: 'app-customize-bar-action-edit-wrapper',
  templateUrl: './customize-bar-action-edit-wrapper.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomizeBarActionEditWrapperComponent implements OnInit, OnDestroy {
  @Input() enabled = true;
  @Input() options: CustomizeActionOptions = {};
  @Input() form: CustomizeBarActionEditForm;
  @Input() titleEnabled = true;
  @Input() backLabel = 'Back';
  @Input() backPopSettingsComponent = true;
  @Input() deleteEnabled = false;
  @Input() parentElement: any;
  @Input() parentPopup: any;
  @Input() titleCleanValue?: (value: string) => string;
  @Output() titleChange = new EventEmitter<string>();
  @Output() submit = new EventEmitter<void>();
  @Output() cancel = new EventEmitter<void>();
  @Output() delete = new EventEmitter<void>();
  @Output() back = new EventEmitter<void>();

  hasBack = false;

  constructor(private customizeBarContext: CustomizeBarContext, private cd: ChangeDetectorRef) {}

  ngOnInit() {
    this.form.statusChanges.pipe(untilDestroyed(this)).subscribe(() => this.cd.markForCheck());
    this.customizeBarContext.settingsComponents$.pipe(untilDestroyed(this)).subscribe(components => {
      this.hasBack = components && components.length > 1;
      this.cd.markForCheck();
    });
  }

  ngOnDestroy(): void {}
}

<div
  class="menu2-side menu2-block"
  [class.menu2-side_light]="menuIsLight === true"
  [class.menu2-side_dark]="menuIsLight === false"
  [class.menu2-side_unset]="!(block?.accentColor | appIsSet)"
  [class.menu2-block_light]="menuIsLight === true"
  [class.menu2-block_dark]="menuIsLight === false"
  [class.menu2-block_unset]="!(block?.accentColor | appIsSet)"
  [style.background-color]="(block?.accentColor | appIsColorHex) ? block?.accentColor : null"
  [ngClass]="!(block?.accentColor | appIsColorHex) ? 'background-color_' + block?.accentColor + '_1' : null"
>
  <div class="menu2-side__start">
    <app-auto-menu-item
      *ngFor="let item of startItems; trackBy: trackMenuItemFn"
      [menuItem]="item"
      [menuBlock]="block"
      [primary]="true"
      [horizontal]="horizontal"
      [childrenVertical]="horizontal"
    >
    </app-auto-menu-item>
  </div>

  <div class="menu2-side__main">
    <app-auto-menu-item
      *ngFor="let item of centerItems; trackBy: trackMenuItemFn"
      [menuItem]="item"
      [menuBlock]="block"
      [primary]="true"
      [horizontal]="horizontal"
      [childrenVertical]="horizontal"
    >
    </app-auto-menu-item>
  </div>

  <div class="menu2-side__end">
    <app-auto-menu-item
      *ngFor="let item of endItems; trackBy: trackMenuItemFn"
      [menuItem]="item"
      [menuBlock]="block"
      [primary]="true"
      [horizontal]="horizontal"
      [childrenVertical]="horizontal"
    >
    </app-auto-menu-item>

    <!--    <a href="javascript:void(0)" class="menu2-side-item dropdown-inline-anchor">-->
    <!--      <div-->
    <!--        class="dropdown dropdown_fully_radius dropdown_menu"-->
    <!--        [class.dropdown_position_menu]="!projectSettings?.hasTopMenuPrimary()"-->
    <!--        [class.dropdown_position_menu-horizontal]="projectSettings?.hasTopMenuPrimary()"-->
    <!--      >-->
    <!--        <div class="dropdown__inner">-->
    <!--          <div class="dropdown__section">-->
    <!--            <a href="javascript:void(0)" class="dropdown__item" (click)="openChat()">-->
    <!--              <span class="icon-chat dropdown__item-icon"></span>-->
    <!--              <div class="dropdown__item-label">Support</div>-->
    <!--            </a>-->
    <!--            <a href="https://jetadmin.io/" class="dropdown__item" target="_blank">-->
    <!--              <span class="icon-home dropdown__item-icon"></span>-->
    <!--              <div class="dropdown__item-label">Visit Homepage</div>-->
    <!--            </a>-->
    <!--            <a href="https://docs.jetadmin.io/" class="dropdown__item" target="_blank">-->
    <!--              <span class="icon-documents dropdown__item-icon"></span>-->
    <!--              <div class="dropdown__item-label">Documentation</div>-->
    <!--            </a>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--      <div class="menu2-side-item__icon icon-help"></div>-->
    <!--    </a>-->

    <!--    <a-->
    <!--      *ngIf="-->
    <!--        (mode$ | async) == adminModes.App &&-->
    <!--        currentProject?.hasEnvironmentCustomizationPermission(currentEnvironmentStore.instance)-->
    <!--      "-->
    <!--      href="javascript:void(0)"-->
    <!--      class="menu2-side-item menu2-side-label-anchor menu2-side-item_desktop-only"-->
    <!--      id="menu-enable-customize-mode"-->
    <!--      (click)="openBuilderUrl()"-->
    <!--      [appClickEvent]="analyticsEvents.UserMode.OpenCustomization"-->
    <!--    >-->
    <!--      <div class="menu2-side-item__highlight menu2-side-item__highlight_always">-->
    <!--        <div-->
    <!--          class="menu2-side-item__dot-border"-->
    <!--          [style.background-color]="-->
    <!--            (projectSettings?.menuPrimaryColor | appIsColorHex) ? projectSettings?.menuPrimaryColor : null-->
    <!--          "-->
    <!--          [ngClass]="-->
    <!--            !(projectSettings?.menuPrimaryColor | appIsColorHex)-->
    <!--              ? 'background-color_' + projectSettings?.menuPrimaryColor + '_1'-->
    <!--              : null-->
    <!--          "-->
    <!--        ></div>-->
    <!--        <div class="menu2-side-item__dot"></div>-->
    <!--        <div class="menu2-side-item__icon icon-brush"></div>-->
    <!--      </div>-->
    <!--      <div class="menu2-side-label">Open Visual Builder</div>-->
    <!--    </a>-->
  </div>
</div>

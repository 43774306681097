import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { MenuBlockLayout, MenuItemType } from '@modules/menu';

import { AddMenuItemOptions } from '../add-menu-item-menu/add-menu-item-menu.component';
import { CustomizeBarPagesEditButtonControl } from '../customize-bar-pages-edit/button-control';
import { CustomizeBarPagesEditCustomControl } from '../customize-bar-pages-edit/custom-control';
import { CustomizeBarPagesEditMenuItemControl } from '../customize-bar-pages-edit/customize-bar-pages-edit.form';
import { CustomizeBarPagesEditImageControl } from '../customize-bar-pages-edit/image-control';
import { CustomizeBarPagesEditModelControl } from '../customize-bar-pages-edit/model-link-control';
import { CustomizeBarPagesEditSectionControl } from '../customize-bar-pages-edit/section-control';
import { CustomizeBarPagesEditSeparatorControl } from '../customize-bar-pages-edit/separator-control';
import { CustomizeBarPagesEditShareControl } from '../customize-bar-pages-edit/share-control';
import { CustomizeBarPagesEditSimpleControl } from '../customize-bar-pages-edit/simple-control';

@Component({
  selector: 'app-auto-menu-item-edit',
  templateUrl: './auto-menu-item-edit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AutoMenuItemEditComponent implements OnInit {
  @Input() control: CustomizeBarPagesEditMenuItemControl;
  @Input() layout: MenuBlockLayout;
  @Input() createdOptions: AddMenuItemOptions;
  @Output() deleteRequested = new EventEmitter<void>();

  menuItemType: MenuItemType;
  menuItemTypes = MenuItemType;

  ngOnInit() {
    if (this.control instanceof CustomizeBarPagesEditSectionControl) {
      this.menuItemType = MenuItemType.Section;
    } else if (this.control instanceof CustomizeBarPagesEditSimpleControl) {
      this.menuItemType = MenuItemType.Simple;
    } else if (this.control instanceof CustomizeBarPagesEditModelControl) {
      this.menuItemType = MenuItemType.ModelLink;
    } else if (this.control instanceof CustomizeBarPagesEditImageControl) {
      this.menuItemType = MenuItemType.Image;
    } else if (this.control instanceof CustomizeBarPagesEditButtonControl) {
      this.menuItemType = MenuItemType.Button;
    } else if (this.control instanceof CustomizeBarPagesEditSeparatorControl) {
      this.menuItemType = MenuItemType.Separator;
    } else if (this.control instanceof CustomizeBarPagesEditShareControl) {
      this.menuItemType = MenuItemType.Share;
    } else if (this.control instanceof CustomizeBarPagesEditCustomControl) {
      this.menuItemType = MenuItemType.Custom;
    }
  }

  onItemDeleted() {
    this.deleteRequested.emit();
  }
}

import {
  ComponentRef,
  Directive,
  ElementRef,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  TemplateRef
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import defaults from 'lodash/defaults';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { fromEvent, Subscription } from 'rxjs';
import { debounceTime, filter } from 'rxjs/operators';

import { DynamicComponentService } from '@common/dynamic-component';
import { TipComponent } from '@common/tips';
import { isSet } from '@shared';

import { GuideFocusService } from '../../services/guide-focus/guide-focus.service';
import { GuideService } from '../../services/guide/guide.service';

export interface TutorialTipOptions {
  tip: string | TemplateRef<any>;
  task: string;
  page: number;
  pageItem: string;
  action?: string;
  formGroup?: FormGroup;
}

export const DefaultTutorialTipOptions: TutorialTipOptions = {
  tip: undefined,
  task: undefined,
  page: undefined,
  pageItem: undefined,
  action: 'click'
};

@Directive({
  selector: '[appTutorialTip]'
})
export class TutorialTipDirective implements OnInit, OnDestroy {
  @Input('appTutorialTip') inputOptions: TutorialTipOptions;

  componentRef: ComponentRef<TipComponent>;
  subscriptions: Subscription[] = [];

  constructor(
    private el: ElementRef,
    private injector: Injector,
    @Optional() private guideService: GuideService,
    private guideFocusService: GuideFocusService,
    private dynamicComponentService: DynamicComponentService
  ) {}

  get options(): TutorialTipOptions {
    return defaults(this.inputOptions, DefaultTutorialTipOptions);
  }

  ngOnInit(): void {
    if (!this.guideService) {
      return;
    }

    this.guideService.state$.pipe(untilDestroyed(this)).subscribe(state => {
      const current =
        state &&
        state.task.uniqueName == this.options.task &&
        state.page == this.options.page &&
        this.guideService.statePageItemName == this.options.pageItem;

      if (current) {
        this.show();
        this.initEvents();
      } else {
        this.hide();
        this.deinitEvents();
      }
    });
  }

  ngOnDestroy(): void {
    this.hide();
    setTimeout(() => this.deinitEvents(), 0); // TODO: workaround for navigate after click
  }

  initEvents() {
    if (this.subscriptions.length) {
      return;
    }

    if (this.options.action == 'hover') {
      this.subscriptions.push(
        fromEvent<MouseEvent>(this.el.nativeElement, 'mouseenter')
          .pipe(untilDestroyed(this))
          .subscribe(() => {
            this.onActivated();
          })
      );
    } else if (this.options.action == 'click') {
      this.subscriptions.push(
        fromEvent<MouseEvent>(this.el.nativeElement, 'click')
          // .pipe(untilDestroyed(this)) // TODO: workaround for navigate after click
          .subscribe(() => {
            this.onActivated();
          })
      );
    } else if (this.options.action == 'input') {
      if (this.options.formGroup) {
        const controlName = this.el.nativeElement.getAttribute('formControlName');
        const control = this.options.formGroup.controls[controlName];

        this.subscriptions.push(
          control.valueChanges
            .pipe(
              debounceTime(1000),
              filter(() => control.valid),
              untilDestroyed(this)
            )
            .subscribe(() => {
              this.onActivated();
            })
        );
      } else {
        this.subscriptions.push(
          fromEvent<MouseEvent>(this.el.nativeElement, 'input')
            .pipe(
              debounceTime(1000),
              filter(() => isSet(this.el.nativeElement.value)),
              untilDestroyed(this)
            )
            .subscribe(() => {
              this.onActivated();
            })
        );
      }
    }
  }

  deinitEvents() {
    if (!this.subscriptions.length) {
      return;
    }

    this.subscriptions.forEach(item => item.unsubscribe());
    this.subscriptions = [];
  }

  show() {
    if (this.componentRef) {
      this.hide();
    }

    if (!this.options.tip) {
      return;
    }

    const componentRef: ComponentRef<TipComponent> = this.dynamicComponentService.appendComponentToBody(this.injector, {
      component: TipComponent,
      inputs: {
        content: this.options.tip,
        options: {
          hoverable: false,
          stick: true,
          style: 'bright'
        },
        sourceElement: this.el.nativeElement
      },
      outputs: {
        closed: [
          () => {
            componentRef.destroy();

            if (this.componentRef == componentRef) {
              this.componentRef = undefined;
            }
          }
        ],
        moved: [
          position => {
            if (!this.guideService.state) {
              return;
            }
            this.guideFocusService.position = { x: position.x, y: position.y };
          }
        ]
      }
    });

    this.componentRef = componentRef;
  }

  hide() {
    if (!this.componentRef) {
      return;
    }

    this.componentRef.instance.close();
  }

  onActivated() {
    this.guideService.completeTaskProgress(this.options.task, this.options.page, this.options.pageItem);
  }
}

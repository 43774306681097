import { Injectable, ViewContainerRef } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FixedService {
  private vcr: ViewContainerRef;

  constructor() {}

  registerContainer(vcr: ViewContainerRef) {
    this.vcr = vcr;
  }

  get container(): ViewContainerRef {
    return this.vcr;
  }
}

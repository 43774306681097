<ng-container *ngIf="objectParams">
  <app-json-field-node
    *ngFor="let item of objectParams.items; trackBy: trackItemFn"
    [node]="item"
    [form]="form.controls[node.name]"
    [editable]="editable"
  >
  </app-json-field-node>
</ng-container>

<ng-container *ngIf="arrayNodes">
  <div class="field">
    <div class="field__label">{{ node.label }}</div>

    <app-page-block *ngFor="let item of arrayNodes; let i = index; trackBy: trackItemFn" [paddingSize]="'none'">
      <div class="field__section">
        <app-json-field-node
          [node]="item"
          [form]="form.controls[node.name]"
          [editable]="editable"
        ></app-json-field-node>

        <a
          *ngIf="editable"
          href="javascript:void(0)"
          class="field__section-delete button button_small button_icon button_danger-secondary button_no-margin"
          [appTip]="'Remove item'"
          (click)="remove(i)"
        >
          <span class="icon icon-bin button__icon"></span>
        </a>
      </div>
    </app-page-block>

    <a
      *ngIf="editable && (arrayParams.max_items === undefined || arrayNodes.length < arrayParams.max_items)"
      href="javascript:void(0)"
      class="button button_small button_no-margin"
      [appButtonTintColor]="'accentColor' | appThemeColorHexOption | async"
      [appButtonTintColorStyle]="tintStyles.Default"
      (click)="add()"
    >
      <span class="button__icon button__icon_left icon-plus"></span>
      <span class="button__label">
        <ng-container *ngIf="arrayNodes.length">Add more</ng-container>
        <ng-container *ngIf="!arrayNodes.length">Add item</ng-container>
      </span>
    </a>
  </div>
</ng-container>

<ng-container *ngIf="fieldParams">
  <app-page-block [paddingSize]="'none'">
    <app-auto-field
      *ngIf="form.controls[fieldParams.name]"
      [form]="form"
      [field]="createField(fieldParams)"
      [readonly]="!editable || fieldParams.editable === false"
    ></app-auto-field>
  </app-page-block>
</ng-container>

import * as moment from 'moment';

import { localize } from '@common/localize';
import { isSet } from '@shared';

import { BetweenFieldLookup } from './between';

export class IsLastXDaysFieldLookup extends BetweenFieldLookup {
  public lookup = 'is_last_x_days';
  public param = 'isLastXDays';
  public verboseName = localize('is Last X Days');
  public icon = 'calendar';

  from(value: string): string {
    return moment().subtract(value, 'days').toISOString();
  }

  to(value: string): string {
    return moment().toISOString();
  }

  str(field?: string, value?: string, exclude?: boolean): string {
    if (exclude) {
      if (isSet(field) && isSet(value)) {
        return localize('{0} is not last {1} days', [field, value]);
      } else {
        return localize('is not last X days');
      }
    } else {
      if (isSet(field) && isSet(value)) {
        return localize('{0} is last {1} days', [field, value]);
      } else {
        return localize('last X days');
      }
    }
  }
}

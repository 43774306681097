var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Type } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DefaultQueryPaginationCursorNextFunction, DefaultQueryPaginationCursorPrevFunction, DefaultQueryPaginationHasMoreFunction, DefaultQueryPaginationTotalFunction, ListModelDescriptionQuery, QueryPagination, QueryType } from '@modules/queries';
import { parseNumber } from '@shared';
import { SortingFieldsControl } from '../../data/sorting-fields-control';
var QueryBuilderForm = /** @class */ (function (_super) {
    __extends(QueryBuilderForm, _super);
    function QueryBuilderForm() {
        var _this = _super.call(this, {
            query_type: new FormControl('', Validators.required),
            simple_query: new FormControl(''),
            http_query: new FormControl(''),
            sql_query: new FormControl(''),
            object_query: new FormControl(''),
            pagination_enabled: new FormControl(false),
            pagination: new FormControl(undefined),
            pagination_has_more_function: new FormControl(DefaultQueryPaginationHasMoreFunction),
            pagination_total_function: new FormControl(DefaultQueryPaginationTotalFunction),
            pagination_cursor_prev_function: new FormControl(DefaultQueryPaginationCursorPrevFunction),
            pagination_cursor_next_function: new FormControl(DefaultQueryPaginationCursorNextFunction),
            sorting: new FormControl(false),
            sorting_fields: new SortingFieldsControl({ formState: [] }),
            frontend_filtering: new FormControl(true),
            pagination_has_more_pages_path: new FormControl([]),
            pagination_has_more_total_pages_path: new FormControl([]),
            pagination_has_more_total_records_path: new FormControl([]),
            pagination_per_page: new FormControl(undefined),
            pagination_per_page_min: new FormControl(undefined),
            pagination_per_page_max: new FormControl(undefined),
            pagination_has_more_path: new FormControl(undefined),
            pagination_total_path: new FormControl([]),
            pagination_total_transformer_enabled: new FormControl(false),
            pagination_cursor_prev_path: new FormControl([]),
            pagination_cursor_prev_transformer_enabled: new FormControl(false),
            pagination_cursor_next_path: new FormControl([]),
            pagination_cursor_next_transformer_enabled: new FormControl(false)
        }) || this;
        _this.listQuery = false;
        _this.queryPaginations = QueryPagination;
        _this.paginationOptions = [
            { value: QueryPagination.Page, name: 'Page pagination', icon: 'documents' },
            { value: QueryPagination.Offset, name: 'Offset pagination', icon: 'starts_with' },
            { value: QueryPagination.Cursor, name: 'Cursor pagination', icon: 'cursor' }
        ];
        _this.optionalPaginationOptions = _this.paginationOptions.concat([{ value: undefined, name: 'No pagination' }]);
        return _this;
    }
    QueryBuilderForm.prototype.init = function (queryClass, queryTypes, query) {
        this.queryClass = queryClass;
        this.listQuery = queryClass === ListModelDescriptionQuery;
        if (query) {
            this.controls.query_type.patchValue(query.queryType);
            this.controls.simple_query.patchValue(query.simpleQuery);
            this.controls.http_query.patchValue(query.httpQuery);
            this.controls.sql_query.patchValue(query.sqlQuery);
            this.controls.object_query.patchValue(query.objectQuery);
            if (this.listQuery && query instanceof ListModelDescriptionQuery) {
                this.controls.pagination.patchValue(query.pagination);
                if (query.paginationTotalFunction) {
                    this.controls.pagination_total_transformer_enabled.patchValue(true);
                    this.controls.pagination_total_function.patchValue(query.paginationTotalFunction);
                }
                else {
                    this.controls.pagination_total_transformer_enabled.patchValue(false);
                    this.controls.pagination_total_path.patchValue(query.paginationTotalPath);
                }
                if (query.paginationCursorPrevFunction) {
                    this.controls.pagination_cursor_prev_transformer_enabled.patchValue(true);
                    this.controls.pagination_cursor_prev_function.patchValue(query.paginationCursorPrevFunction);
                }
                else {
                    this.controls.pagination_cursor_prev_transformer_enabled.patchValue(false);
                    this.controls.pagination_cursor_prev_path.patchValue(query.paginationCursorPrevPath);
                }
                if (query.paginationCursorNextFunction) {
                    this.controls.pagination_cursor_next_transformer_enabled.patchValue(true);
                    this.controls.pagination_cursor_next_function.patchValue(query.paginationCursorNextFunction);
                }
                else {
                    this.controls.pagination_cursor_next_transformer_enabled.patchValue(false);
                    this.controls.pagination_cursor_next_path.patchValue(query.paginationCursorNextPath);
                }
                if (query.paginationHasMoreFunction) {
                    this.controls.pagination_has_more_function.patchValue(query.paginationHasMoreFunction);
                    this.controls.pagination_has_more_path.patchValue(undefined);
                }
                else {
                    this.controls.pagination_has_more_pages_path.patchValue(query.paginationHasMorePagesPath);
                    this.controls.pagination_has_more_total_pages_path.patchValue(query.paginationHasMoreTotalPagesPath);
                    this.controls.pagination_has_more_total_records_path.patchValue(query.paginationHasMoreTotalRecordsPath);
                    if (query.paginationHasMorePagesPath.length) {
                        this.controls.pagination_has_more_path.patchValue('pagination_has_more_pages_path');
                    }
                    else if (query.paginationHasMoreTotalPagesPath.length) {
                        this.controls.pagination_has_more_path.patchValue('pagination_has_more_total_pages_path');
                    }
                    else if (query.paginationHasMoreTotalRecordsPath.length) {
                        this.controls.pagination_has_more_path.patchValue('pagination_has_more_total_records_path');
                    }
                    else {
                        this.controls.pagination_has_more_path.patchValue(undefined);
                    }
                }
                this.controls.pagination_per_page.patchValue(query.paginationPerPage);
                this.controls.pagination_per_page_min.patchValue(query.paginationPerPageMin);
                this.controls.pagination_per_page_max.patchValue(query.paginationPerPageMax);
                this.controls.sorting.patchValue(query.sorting);
                this.controls.sorting_fields.patchValue(query.sortingFields || []);
                this.controls.frontend_filtering.patchValue(query.frontendFiltering);
            }
        }
        else {
            this.controls.query_type.patchValue(queryTypes[0]);
        }
    };
    QueryBuilderForm.prototype.setPagination = function (value) {
        this.controls.pagination_enabled.patchValue(!!value);
        this.controls.pagination.patchValue(value);
    };
    QueryBuilderForm.prototype.toggleSorting = function () {
        this.controls.sorting.patchValue(!this.controls.sorting.value);
    };
    QueryBuilderForm.prototype.toggleFrontendFiltering = function () {
        this.controls.frontend_filtering.patchValue(!this.controls.frontend_filtering.value);
    };
    QueryBuilderForm.prototype.isPaginationTotalFunctionChanged = function () {
        return this.controls.pagination_total_function.value != DefaultQueryPaginationTotalFunction;
    };
    QueryBuilderForm.prototype.isPaginationCursorPrevFunctionChanged = function () {
        return this.controls.pagination_cursor_prev_function.value != DefaultQueryPaginationCursorPrevFunction;
    };
    QueryBuilderForm.prototype.isPaginationCursorNextFunctionChanged = function () {
        return this.controls.pagination_cursor_next_function.value != DefaultQueryPaginationCursorNextFunction;
    };
    QueryBuilderForm.prototype.isPaginationHasMoreFunctionChanged = function () {
        return this.controls.pagination_has_more_function.value != DefaultQueryPaginationHasMoreFunction;
    };
    QueryBuilderForm.prototype.getInstance = function () {
        var instance = new this.queryClass();
        instance.queryType = this.controls.query_type.value;
        instance.simpleQuery = this.controls.simple_query.value;
        instance.httpQuery = this.controls.http_query.value;
        instance.sqlQuery = this.controls.sql_query.value;
        instance.objectQuery = this.controls.object_query.value;
        if (this.listQuery && instance instanceof ListModelDescriptionQuery) {
            instance.pagination = this.controls.pagination.value;
            if (this.controls.pagination_has_more_function.value) {
                instance.paginationHasMoreFunction = this.controls.pagination_has_more_function.value;
            }
            if (this.controls.pagination_total_function.value) {
                instance.paginationTotalFunction = this.controls.pagination_total_function.value;
            }
            if (this.controls.pagination_cursor_prev_function.value) {
                instance.paginationCursorPrevFunction = this.controls.pagination_cursor_prev_function.value;
            }
            if (this.controls.pagination_cursor_next_function.value) {
                instance.paginationCursorNextFunction = this.controls.pagination_cursor_next_function.value;
            }
            if (this.controls.pagination_total_transformer_enabled.value) {
                if (this.isPaginationTotalFunctionChanged()) {
                    instance.paginationTotalFunction = this.controls.pagination_total_function.value;
                }
                else {
                    instance.paginationTotalFunction = undefined;
                }
                instance.paginationTotalPath = [];
            }
            else {
                instance.paginationTotalFunction = undefined;
                instance.paginationTotalPath = this.controls.pagination_total_path.value;
            }
            if (this.controls.pagination_cursor_prev_transformer_enabled.value) {
                if (this.isPaginationCursorPrevFunctionChanged()) {
                    instance.paginationCursorPrevFunction = this.controls.pagination_cursor_prev_function.value;
                }
                else {
                    instance.paginationCursorPrevFunction = undefined;
                }
                instance.paginationCursorPrevPath = [];
            }
            else {
                instance.paginationCursorPrevFunction = undefined;
                instance.paginationCursorPrevPath = this.controls.pagination_cursor_prev_path.value;
            }
            if (this.controls.pagination_cursor_next_transformer_enabled.value) {
                if (this.isPaginationCursorNextFunctionChanged()) {
                    instance.paginationCursorNextFunction = this.controls.pagination_cursor_next_function.value;
                }
                else {
                    instance.paginationCursorNextFunction = undefined;
                }
                instance.paginationCursorNextPath = [];
            }
            else {
                instance.paginationCursorNextFunction = undefined;
                instance.paginationCursorNextPath = this.controls.pagination_cursor_next_path.value;
            }
            if (!this.controls.pagination_has_more_path.value) {
                if (this.isPaginationHasMoreFunctionChanged()) {
                    instance.paginationHasMoreFunction = this.controls.pagination_has_more_function.value;
                }
                else {
                    instance.paginationHasMoreFunction = undefined;
                }
                instance.paginationHasMorePagesPath = [];
                instance.paginationHasMoreTotalPagesPath = [];
                instance.paginationHasMoreTotalRecordsPath = [];
            }
            else {
                var hasMorePath = this.controls.pagination_has_more_path.value;
                instance.paginationHasMoreFunction = undefined;
                if (hasMorePath == 'pagination_has_more_pages_path') {
                    instance.paginationHasMorePagesPath = this.controls.pagination_has_more_pages_path.value;
                }
                else {
                    instance.paginationHasMorePagesPath = [];
                }
                if (hasMorePath == 'pagination_has_more_total_pages_path') {
                    instance.paginationHasMoreTotalPagesPath = this.controls.pagination_has_more_total_pages_path.value;
                }
                else {
                    instance.paginationHasMoreTotalPagesPath = [];
                }
                if (hasMorePath == 'pagination_has_more_total_records_path') {
                    instance.paginationHasMoreTotalRecordsPath = this.controls.pagination_has_more_total_records_path.value;
                }
                else {
                    instance.paginationHasMoreTotalRecordsPath = [];
                }
            }
            if (instance.queryType == QueryType.SQL || instance.queryType == QueryType.Object) {
                instance.sorting = true;
            }
            else {
                instance.sorting = this.controls.sorting.value;
            }
            var paginationPerPage = parseNumber(this.controls.pagination_per_page.value);
            var paginationPerPageMin = parseNumber(this.controls.pagination_per_page_min.value);
            var paginationPerPageMax = parseNumber(this.controls.pagination_per_page_max.value);
            instance.paginationPerPage = paginationPerPage && paginationPerPage > 0 ? paginationPerPage : undefined;
            instance.paginationPerPageMin =
                paginationPerPageMin && paginationPerPageMin > 0 ? paginationPerPageMin : undefined;
            instance.paginationPerPageMax =
                paginationPerPageMax && paginationPerPageMax > 0 ? paginationPerPageMax : undefined;
            instance.sortingFields = this.controls.sorting_fields.value;
            instance.frontendFiltering = this.controls.frontend_filtering.value;
        }
        return instance;
    };
    return QueryBuilderForm;
}(FormGroup));
export { QueryBuilderForm };

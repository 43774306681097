/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../demo-block/demo-block.component.ngfactory";
import * as i2 from "../demo-block/demo-block.component";
import * as i3 from "./demo.component";
import * as i4 from "../../../meta/services/meta.service";
var styles_DemoComponent = [];
var RenderType_DemoComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_DemoComponent, data: {} });
export { RenderType_DemoComponent as RenderType_DemoComponent };
export function View_DemoComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-demo-block", [], null, null, null, i1.View_DemoBlockComponent_0, i1.RenderType_DemoBlockComponent)), i0.ɵdid(1, 114688, null, 0, i2.DemoBlockComponent, [], { fill: [0, "fill"] }, null)], function (_ck, _v) { var currVal_0 = true; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_DemoComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_DemoComponent_0, RenderType_DemoComponent)), i0.ɵdid(1, 114688, null, 0, i3.DemoComponent, [i4.MetaService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DemoComponentNgFactory = i0.ɵccf("ng-component", i3.DemoComponent, View_DemoComponent_Host_0, {}, {}, []);
export { DemoComponentNgFactory as DemoComponentNgFactory };

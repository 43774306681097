<div class="sidebar__element">
  <app-sidebar-field [label]="'format'">
    <app-auto-field
      [form]="form.form"
      [label]="false"
      [field]="
        createField({
          name: 'output',
          field: 'SelectField',
          params: { options: form.outputOptions, classes: ['select_fill'] }
        })
      "
    ></app-auto-field>
  </app-sidebar-field>
</div>

<div class="sidebar__element">
  <app-sidebar-field [label]="'apply format when displaying'" [layoutLeft]="true">
    <app-auto-field
      [form]="form.form"
      [label]="false"
      [field]="
        createField({
          name: 'format_readonly',
          field: 'BooleanField',
          params: { style: booleanFieldStyle.Toggle, orange: true }
        })
      "
    ></app-auto-field>
  </app-sidebar-field>
</div>

<div class="preview-tip">
  <div
    class="preview-tip__bounds"
    [style.width.px]="width"
    [style.height.px]="height"
    [style.padding-top.%]="(height / width) * 100"
  >
    <div class="preview-tip__preview" [style.background-image]="'url(' + url + ')'"></div>
  </div>
</div>

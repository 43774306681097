import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouteReuseStrategy, RouterModule } from '@angular/router';

import { FixedComponentModule } from '@common/fixed-component';
import { LocalizeModule } from '@common/localize';
import { ActivitiesRoutesModule } from '@modules/activities-routes';
import { AnalyticsModule } from '@modules/analytics';
import { ApiDocsModule } from '@modules/api-docs';
import { ChangeRoutesModule } from '@modules/change-routes';
import { CollaborationRoutesModule } from '@modules/collaboration-routes';
import { CustomizeComponentsModule } from '@modules/customize-components';
import { CustomizeRoutesModule } from '@modules/customize-routes';
import { CustomizeToolbarModule } from '@modules/customize-toolbar';
import { DashboardRoutesModule } from '@modules/dashboard-routes';
import { DataSyncComponentsModule } from '@modules/data-sync-components';
import { DeleteModule } from '@modules/delete';
import { DemoModule } from '@modules/demo';
import { DemoWidgetModule } from '@modules/demo-widget';
import { ExportModule } from '@modules/export';
import { FeaturesModule } from '@modules/features';
import { FieldParamsComponentsModule } from '@modules/field-params-components';
import { ImportModule } from '@modules/import';
import { IntegrationsComponentsModule } from '@modules/integrations-components';
import { LayoutRoutesModule } from '@modules/layout-routes';
import { ListRoutesModule } from '@modules/list-routes';
import { MassEditRoutesModule } from '@modules/mass-edit-routes';
import { MenuComponentsModule } from '@modules/menu-components';
import { MessagesRoutesModule } from '@modules/messages-routes';
import { ProjectUtilsModule } from '@modules/project-utils';
import { ResourceGeneratorsModule } from '@modules/resource-generators';
import { RoutingModule } from '@modules/routing';
import { TableModule } from '@modules/table';
import { TemplateGeneratorsComponentsModule } from '@modules/template-generators-components';
import { TemplateRoutesModule } from '@modules/template-routes';
import { ThemeComponentsModule } from '@modules/theme-components';
import { TutorialModule } from '@modules/tutorial';
import { WorkflowRoutesModule } from '@modules/workflow-routes';
import { ComponentRouteReuseStrategy, SharedModule } from '@shared';
import { UiModule } from '@ui';

import { AdminComponent } from './admin.component';
import { routes } from './admin.routes';
import { DefaultComponent } from './components/default/default.component';
import { DemoProjectToastComponent } from './components/demo-project-toast/demo-project-toast.component';
import { EmailVerificationToastComponent } from './components/email-verification-toast/email-verification-toast.component';
import { NotAllowedComponent } from './components/not-allowed/not-allowed.component';
import { OpenSlackPopupComponent } from './components/open-slack-popup/open-slack-popup.component';
import { RedirectComponent } from './components/redirect/redirect.component';
import { SubscriptionPastDueToastComponent } from './components/subscription-past-due-toast/subscription-past-due-toast.component';

@NgModule({
  imports: [
    CommonModule,
    ListRoutesModule,
    ChangeRoutesModule,
    DeleteModule,
    DemoModule,
    DemoWidgetModule,
    MenuComponentsModule,
    TutorialModule,
    SharedModule,
    FixedComponentModule,
    AnalyticsModule,
    CollaborationRoutesModule,
    LayoutRoutesModule,
    ProjectUtilsModule,
    FieldParamsComponentsModule,
    CustomizeComponentsModule,
    ActivitiesRoutesModule,
    DashboardRoutesModule,
    MassEditRoutesModule,
    CustomizeRoutesModule,
    RouterModule.forChild(routes),
    RoutingModule,
    CustomizeToolbarModule,
    ResourceGeneratorsModule,
    FeaturesModule,
    TableModule,
    UiModule,
    ExportModule,
    ImportModule,
    DataSyncComponentsModule,
    TemplateRoutesModule,
    WorkflowRoutesModule,
    TemplateGeneratorsComponentsModule,
    ApiDocsModule,
    LocalizeModule,
    MessagesRoutesModule,
    ThemeComponentsModule,
    IntegrationsComponentsModule
  ],
  providers: [
    {
      provide: RouteReuseStrategy,
      useClass: ComponentRouteReuseStrategy
    }
  ],
  declarations: [
    AdminComponent,
    DefaultComponent,
    RedirectComponent,
    EmailVerificationToastComponent,
    SubscriptionPastDueToastComponent,
    DemoProjectToastComponent,
    NotAllowedComponent,
    OpenSlackPopupComponent
  ],
  exports: [AdminComponent]
})
export class AdminModule {}

import pickBy from 'lodash/pickBy';
import * as moment from 'moment';

import { CalendarSettings } from '@modules/customize';
import { ListState, serializeDataSourceColumns } from '@modules/list-components';
import { PAGE_PARAM } from '@modules/models';

import { CalendarType } from '../../data/calendar-type';

export interface CalendarState extends ListState<CalendarSettings> {
  dateField?: string;
  type?: CalendarType;
  date?: moment.Moment;
  sortingField?: string;
  sortingAsc?: boolean;
  multipleSelection?: boolean;
}

export function getListStateFetch(state: CalendarState): Object {
  return {
    dataSource: state.dataSource
      ? {
          ...state.dataSource.serialize(),
          columns: serializeDataSourceColumns(state.dataSource.columns)
        }
      : undefined,
    dataSourceStaticData: state.dataSourceStaticData,
    dataSourceParams: state.dataSourceParams,
    filters: state.filters ? state.filters.map(item => item.serialize()) : [],
    search: state.search,
    sort: state.sort,
    inputsLoading: state.inputsLoading,
    inputsNotSet: state.inputsNotSet,
    dateField: state.dateField,
    sortingField: state.sortingField,
    sortingAsc: state.sortingAsc
  };
}

export function getListStateDayMonthFetch(state: CalendarState): Object {
  return {
    dataSource: state.dataSource
      ? {
          ...state.dataSource.serialize(),
          columns: serializeDataSourceColumns(state.dataSource.columns)
        }
      : undefined,
    dataSourceStaticData: state.dataSourceStaticData,
    filters: state.filters ? state.filters.map(item => item.serialize()) : [],
    inputsLoading: state.inputsLoading,
    inputsNotSet: state.inputsNotSet,
    dateField: state.dateField,
    month: state.date ? state.date.clone().startOf('month').toISOString() : undefined
  };
}

export function getListStateFetchNewParams(state: CalendarState): Object {
  return {
    params: pickBy(state.dataSourceParams, (v, k) => PAGE_PARAM != k),
    filters: state.filters ? state.filters.map(item => item.serialize()) : [],
    search: state.search,
    sort: state.sort
  };
}

export function getListStateColumns(state: CalendarState): Object {
  return {
    columns: state.dataSource ? state.dataSource.columns : undefined
  };
}

export function getListStateSelection(state: CalendarState): Object {
  return {
    multipleSelection: state.multipleSelection
  };
}

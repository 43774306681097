var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from '@modules/api';
import { ResourceType } from '@modules/projects';
import { QueryType, StorageQuery } from '@modules/queries';
import { ResourceControllerService } from '@modules/resources';
import { Storage } from '@modules/storages';
import { ResourceGeneratorService } from '../resource-generator/resource-generator.service';
var AzureBlobGeneratorService = /** @class */ (function (_super) {
    __extends(AzureBlobGeneratorService, _super);
    function AzureBlobGeneratorService(resourceControllerService, apiService) {
        var _this = _super.call(this) || this;
        _this.resourceControllerService = resourceControllerService;
        _this.apiService = apiService;
        return _this;
    }
    AzureBlobGeneratorService.prototype.getParamsOptions = function (project, environment, resource) {
        return of({
            account: resource.params['account'],
            sas: resource.params['sas']
        });
    };
    AzureBlobGeneratorService.prototype.generateParams = function (project, environment, typeItem, options) {
        var controller = this.resourceControllerService.get(ResourceType.AzureBlob);
        return controller.listContainers(options.account, options.sas).pipe(map(function (containers) {
            var storages = containers.map(function (item) {
                var storage = new Storage();
                storage.uniqueName = item;
                storage.name = item;
                storage.uploadQuery = new StorageQuery();
                storage.uploadQuery.queryType = QueryType.Simple;
                storage.uploadQuery.simpleQuery = new storage.uploadQuery.simpleQueryClass();
                return storage;
            });
            return {
                resourceParams: {
                    account: options.account,
                    sas: options.sas
                },
                storages: storages.map(function (item) { return item.serialize(); })
            };
        }), this.apiService.catchApiError());
    };
    return AzureBlobGeneratorService;
}(ResourceGeneratorService));
export { AzureBlobGeneratorService };

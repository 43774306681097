import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';

import { Token } from '@modules/tokens';
import { EMPTY, objectGet } from '@shared';

@Component({
  selector: 'app-input-tokens-list-item',
  templateUrl: './input-tokens-list-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InputTokensListItemComponent implements OnInit, OnChanges {
  @Input() item: Token;
  @Input() tokenValues = {};
  @Output() clickItem = new EventEmitter<Token>();

  value: any;

  constructor() {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges): void {
    const value = objectGet(this.tokenValues, this.item.name);

    if (value !== EMPTY) {
      this.value = value;
    }
  }
}

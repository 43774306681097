<div class="sidebar__element">
  <app-sidebar-field [label]="'Data format'">
    <app-auto-field
      [form]="form.form"
      [label]="false"
      [field]="
        createField({
          name: 'output_format',
          field: 'SelectField',
          params: { options: form.outputFormatOptions, classes: ['select_fill'] }
        })
      "
    ></app-auto-field>
  </app-sidebar-field>
</div>

<ng-container *ngIf="form.form.value['output_format'] == geographyOutputFormats.Object">
  <div class="sidebar__element">
    <app-sidebar-field [label]="'Latitude field'">
      <app-auto-field
        [form]="form.form"
        [label]="false"
        [field]="
          createField({
            name: 'object_lat_field',
            field: 'CharField',
            params: { classes: ['input_fill'] }
          })
        "
      ></app-auto-field>
    </app-sidebar-field>
  </div>
  <div class="sidebar__element">
    <app-sidebar-field [label]="'Longitude field'">
      <app-auto-field
        [form]="form.form"
        [label]="false"
        [field]="
          createField({
            name: 'object_lng_field',
            field: 'CharField',
            params: { classes: ['input_fill'] }
          })
        "
      ></app-auto-field>
    </app-sidebar-field>
  </div>
</ng-container>

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import clone from 'lodash/clone';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import { map } from 'rxjs/operators';
import { PAGE_PARAM } from '@modules/models';
import { ListStore } from '@shared';
import { FeedItemService } from '../services/feed-item/feed-item.service';
var FeedItemListStore = /** @class */ (function (_super) {
    __extends(FeedItemListStore, _super);
    function FeedItemListStore(service) {
        var _this = _super.call(this) || this;
        _this.service = service;
        _this.params = {};
        return _this;
    }
    FeedItemListStore.prototype.paramsForPage = function (page) {
        var params = clone(this.params);
        params[PAGE_PARAM] = page;
        return params;
    };
    FeedItemListStore.prototype.fetchPage = function (page, next) {
        var params = this.paramsForPage(page);
        return this.service.get(this.projectName, this.environmentName, params).pipe(map(function (response) {
            return {
                items: response.results,
                hasMore: response.hasMore,
                totalPages: response.numPages,
                perPage: response.perPage,
                count: response.count
            };
        }));
    };
    FeedItemListStore.prototype.setMarkRead = function (params) {
        this.items = this.items.map(function (item) {
            var currentParams = {};
            if (item.userActivity.objectType) {
                currentParams['object_type'] = item.userActivity.objectType;
            }
            if (item.userActivity.objectId) {
                currentParams['object_id'] = item.userActivity.objectId;
            }
            if (isEqual(currentParams, params)) {
                item = cloneDeep(item);
                item.read = true;
                return item;
            }
            else {
                return item;
            }
        });
    };
    return FeedItemListStore;
}(ListStore));
export { FeedItemListStore };

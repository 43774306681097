var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { AfterViewInit, ChangeDetectorRef, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import 'brace/mode/sql';
import 'brace/snippets/sql';
import 'brace/snippets/text';
import flatten from 'lodash/flatten';
import { AceEditorDirective } from 'ng2-ace-editor';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { FieldType, registerFieldComponent } from '@modules/fields';
import { ModelDescriptionStore, ModelService, ReducedModelService } from '@modules/model-queries';
import { CurrentEnvironmentStore, CurrentProjectStore, ResourceType } from '@modules/projects';
import { ThemeService } from '@modules/theme';
import { FieldComponent } from '../field/field.component';
import './theme-dark';
import './theme-default';
import './theme-default-contrast';
var SqlFieldComponent = /** @class */ (function (_super) {
    __extends(SqlFieldComponent, _super);
    function SqlFieldComponent(modelDescriptionStore, modelService, reducedModelService, currentProjectStore, currentEnvironmentStore, cd, themeService) {
        var _this = _super.call(this) || this;
        _this.modelDescriptionStore = modelDescriptionStore;
        _this.modelService = modelService;
        _this.reducedModelService = reducedModelService;
        _this.currentProjectStore = currentProjectStore;
        _this.currentEnvironmentStore = currentEnvironmentStore;
        _this.cd = cd;
        _this.themeService = themeService;
        return _this;
    }
    SqlFieldComponent.prototype.ngOnDestroy = function () { };
    SqlFieldComponent.prototype.ngOnChanges = function (changes) {
        if (changes['form'] || changes['value']) {
            // this.executeSql();
        }
    };
    SqlFieldComponent.prototype.ngOnInit = function () { };
    SqlFieldComponent.prototype.ngAfterViewInit = function () {
        this.resource = this.field.params['resource'];
        if (this.field.editable) {
            this.updateTables();
            this.addCompleter();
            // this.executeSql();
        }
    };
    Object.defineProperty(SqlFieldComponent.prototype, "theme", {
        get: function () {
            return this.themeService.theme == 'dark' ? 'dracula' : 'xcode';
        },
        enumerable: true,
        configurable: true
    });
    SqlFieldComponent.prototype.addCompleter = function () {
        var _this = this;
        var completer = {
            getCompletions: function (editor, session, pos, prefix, callback) {
                _this.modelDescriptionStore
                    .getFirst()
                    .pipe(untilDestroyed(_this))
                    .subscribe(function (result) {
                    if (_this.resource) {
                        result = result.filter(function (model) { return model.resource == _this.resource; });
                    }
                    var modelDbFields = result
                        .filter(function (item) { return item.dbTable; })
                        .map(function (model) { return [
                        {
                            value: model.dbTable,
                            score: 2,
                            meta: 'table',
                            docHTML: [
                                "<b>" + model.verboseNamePlural + "</b>",
                                '<hr>'
                            ].concat(model.dbFields.slice(0, 10).map(function (field) { return field.dbColumn + ": <i>" + field.field + "</i><br>"; }), [
                                model.fields.length > 10 ? '...' : ''
                            ]).join('')
                        }
                    ].concat(model.dbFields.map(function (field) {
                        return {
                            caption: field.dbColumn + " (" + model.dbTable + ")",
                            value: field.dbColumn,
                            score: 2,
                            meta: 'column',
                            docHTML: [
                                "<b>" + field.verboseName + "</b>",
                                '<hr>',
                                "Type: <i>" + field.field + "</i><br>",
                                "Table: " + model.dbTable + " (" + model.verboseNamePlural + ")"
                            ].join('')
                        };
                    }), model.dbFields.map(function (field) {
                        return {
                            caption: model.dbTable + "." + field.dbColumn,
                            value: model.dbTable + "." + field.dbColumn,
                            score: 1,
                            meta: 'column',
                            docHTML: [
                                "<b>" + field.verboseName + "</b>",
                                '<hr>',
                                "Type: <i>" + field.field + "</i><br>",
                                "Table: " + model.dbTable + " (" + model.verboseNamePlural + ")"
                            ].join('')
                        };
                    })); });
                    callback(null, flatten(modelDbFields));
                });
            }
        };
        this.ace.editor.completers = (this.ace.editor.completers || []).concat([completer]);
    };
    SqlFieldComponent.prototype.updateTables = function () {
        var _this = this;
        this.modelDescriptionStore
            .get()
            .pipe(untilDestroyed(this))
            .subscribe(function (result) {
            if (_this.resource) {
                result = result.filter(function (model) { return model.resource == _this.resource; });
            }
            _this.tables = result
                .filter(function (item) { return item.dbTable; })
                .sort(function (lhs, rhs) {
                return lhs.dbTable < rhs.dbTable ? -1 : lhs.dbTable < rhs.dbTable ? 0 : 1;
            })
                .map(function (item) {
                return {
                    name: item.dbTable,
                    label: item.verboseNamePlural,
                    insert: item.dbTable,
                    children: item.dbFields.map(function (field) {
                        return {
                            name: field.dbColumn,
                            label: field.verboseName,
                            insert: field.dbColumn,
                            children: []
                        };
                    })
                };
            });
            _this.cd.markForCheck();
        });
    };
    SqlFieldComponent.prototype.editorInsert = function (text) {
        this.ace.editor.session.replace(this.ace.editor.selection.getRange(), text);
    };
    SqlFieldComponent.prototype.executeSql = function () {
        var _this = this;
        var sql = this.currentValue;
        this.sqlResult = undefined;
        this.sqlError = undefined;
        this.cd.markForCheck();
        if (!sql) {
            return;
        }
        // TODO: get resource from context
        var resource = this.currentEnvironmentStore.resources.find(function (item) { return item.type == ResourceType.JetBridge; });
        this.modelService
            .sql(resource, sql)
            .pipe(untilDestroyed(this))
            .subscribe(function (result) {
            _this.sqlResult = result;
            _this.cd.markForCheck();
        }, function (error) {
            if (error.fieldErrors['error']) {
                _this.sqlError = error.fieldErrors['error'];
            }
            else {
                _this.sqlError = 'Unknown error';
            }
            _this.cd.markForCheck();
        });
    };
    return SqlFieldComponent;
}(FieldComponent));
export { SqlFieldComponent };
registerFieldComponent(FieldType.Sql, SqlFieldComponent);

<form [formGroup]="form" (ngSubmit)="submit()">
  <app-popup2>
    <app-popup2-header>
      <app-popup2-title>Copy Environment</app-popup2-title>
      <app-popup2-description>
        This will transfer Pages, Collection, Actions, Task Queues and Resources settings from one environment to
        another.<br />
        Resource credentials will be saved for each Environment.
      </app-popup2-description>
    </app-popup2-header>

    <app-page-block>
      <div class="merge-cards">
        <div class="merge-cards__item">
          <div class="merge-cards__item-inner">
            <div class="merge-cards__item-title">Copy From</div>
            <app-select
              [control]="form.controls.from_environment"
              [options]="form.environmentOptionsExcept$(form.controls.to_environment) | async"
              [fill]="true"
              [resetEnabled]="true"
            ></app-select>
          </div>
        </div>
        <div class="merge-cards__item">
          <div class="merge-cards__item-inner">
            <div class="merge-cards__item-title">Copy To</div>
            <app-select
              [control]="form.controls.to_environment"
              [options]="form.environmentOptionsExcept$(form.controls.from_environment) | async"
              [fill]="true"
              [resetEnabled]="true"
            ></app-select>
          </div>
        </div>
      </div>
    </app-page-block>

    <app-page-block *ngIf="resources?.length">
      <div class="merge-items merge-items_contrast">
        <div class="merge-items__title">Resources will be copied the following way</div>
        <div *ngFor="let item of resources" class="merge-items__row">
          <div class="merge-items__row-inner">
            <div class="merge-items__column">
              <div class="merge-item merge-item_right">
                <span
                  *ngIf="item.from.typeItem.image"
                  class="merge-item__img"
                  [style.background-image]="
                    '/assets/images/resources/' + item.from.typeItem.image + '-color.svg' | appDeployCssUrl
                  "
                ></span>
                {{ item.from.name }}
              </div>
            </div>
            <div class="merge-items__arrow icon-arrow_forward_2"></div>
            <div class="merge-items__column">
              <div class="merge-item">
                <span
                  *ngIf="item.from.typeItem.image"
                  class="merge-item__img"
                  [style.background-image]="
                    '/assets/images/resources/' + item.from.typeItem.image + '-color.svg' | appDeployCssUrl
                  "
                ></span>
                <ng-container *ngIf="item.to">
                  {{ item.to.name }}
                </ng-container>
                <ng-container *ngIf="!item.to">
                  New Resource
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </app-page-block>

    <app-popup2-footer>
      <app-page-block>
        <app-stack [align]="'right'">
          <a
            href="javascript:void(0)"
            class="button popup2__footer-item"
            [class.button_disabled]="loading"
            (click)="close()"
          >
            Cancel
          </a>

          <button
            type="submit"
            class="button button_primary popup2__footer-item"
            [class.button_disabled]="form.invalid || form.pending || loading"
          >
            <app-loader-small *ngIf="loading" class="button__icon button__icon_left"></app-loader-small>
            <span class="button__label">Copy</span>
          </button>
        </app-stack>
      </app-page-block>
    </app-popup2-footer>
  </app-popup2>
</form>

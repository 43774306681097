import { Injectable } from '@angular/core';
import { AbstractControl, FormControl, Validators } from '@angular/forms';
import cloneDeep from 'lodash/cloneDeep';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { catchError, debounceTime, delayWhen, map, switchMap, tap } from 'rxjs/operators';

import { NotificationService } from '@common/notifications';
import { ActionService } from '@modules/action-queries';
import { ServerRequestError } from '@modules/api';
import { AggregateFunc, DatasetGroupLookup } from '@modules/charts';
import { CustomView, CustomViewService, CustomViewsStore, CustomViewType } from '@modules/custom-views';
import { KanbanBoardSettings, ListDefaultSelection, ViewContext, ViewContextElement } from '@modules/customize';
import { ElementConfigurationService } from '@modules/customize-configuration';
import { WidgetQueryService } from '@modules/dashboard-queries';
import { DataSourceType } from '@modules/data-sources';
import { applyParamInputs, DisplayField, Input, Input as FieldInput } from '@modules/fields';
import { ModelOptionsSource } from '@modules/filters-components';
import { ModelDescriptionStore, ModelService } from '@modules/model-queries';
import { ModelDescription } from '@modules/models';
import { FieldInputControl, modelDescriptionHasAutoParameters } from '@modules/parameters';
import { CurrentEnvironmentStore, CurrentProjectStore, ResourceType } from '@modules/projects';
import { ChartWidgetQuery, ListModelDescriptionQuery, QueryType } from '@modules/queries';
import { ResourceControllerService } from '@modules/resources';
import { View } from '@modules/views';
import { controlValue, generateAlphanumeric, isSet } from '@shared';

import { ActionOutputFormGroup } from '../../../forms/action-output.form-group';
import { FieldActionsArray } from '../../display-fields-edit/field-actions.array';
import { MarginControl } from '../../margin-control/margin-control.component';
import { ListModelDescriptionDataSourceControl } from '../../model-description-data-source-edit/list-model-description-data-source';
import { OptionEnabledArray } from '../../option-enabled-edit/option-enabled.array';
import {
  CustomizeBarListLayoutSettingsForm,
  CustomizeListOptions,
  CustomizeListResult,
  validateAction,
  validateActions
} from '../customize-bar-list-layout-settings/customize-bar-list-layout-settings.form';
import { KanbanStageArray } from './kanban-stage.array';

@Injectable()
export class CustomizeBarKanbanBoardSettingsForm extends CustomizeBarListLayoutSettingsForm<KanbanBoardSettings> {
  context: ViewContext;
  contextElement: ViewContextElement;

  controls: {
    header: FormControl;
    title: FieldInputControl;
    data_source: ListModelDescriptionDataSourceControl;
    card_view_unique_name: FormControl;
    card_view: FormControl;
    card_view_mappings: FormControl;
    search_enabled: FormControl;
    search_live: FormControl;
    stage_field: FormControl;
    stages: KanbanStageArray;
    ordering_field: FormControl;
    card_click_action: FormControl;
    card_column_change_action: FormControl;
    card_order_change_action: FormControl;
    actions: FormControl;
    model_actions: FormControl;
    columns_actions: FieldActionsArray;
    custom_actions: ActionOutputFormGroup;
    sorting_field: FormControl;
    sorting_asc: FormControl;
    per_page: FormControl;
    multiple_selection: FormControl;
    first_item_default_selection: FormControl;
    display_filters: FormControl;
    filter_fields: OptionEnabledArray;
    visible_input: FieldInputControl;
    name: FormControl;
    tooltip: FormControl;
    gap_horizontal: FormControl;
    gap_vertical: FormControl;
    margin: MarginControl;
    comments_enabled: FormControl;
    comments_object_type: FieldInputControl;
    comments_object_id: FieldInputControl;
  };

  ignoreSubmitControls: AbstractControl[] = [this.controls.card_view];
  submitLoading$ = new BehaviorSubject<boolean>(false);

  constructor(
    private widgetQueryService: WidgetQueryService,
    private modelService: ModelService,
    private modelOptionsSource: ModelOptionsSource,
    private customViewService: CustomViewService,
    private customViewsStore: CustomViewsStore,
    private notificationService: NotificationService,
    currentProjectStore: CurrentProjectStore,
    currentEnvironmentStore: CurrentEnvironmentStore,
    resourceControllerService: ResourceControllerService,
    modelDescriptionStore: ModelDescriptionStore,
    actionService: ActionService,
    elementConfigurationService: ElementConfigurationService,
    dataSourceControl: ListModelDescriptionDataSourceControl
  ) {
    super(
      currentProjectStore,
      currentEnvironmentStore,
      resourceControllerService,
      modelDescriptionStore,
      actionService,
      elementConfigurationService,
      {
        header: new FormControl(true),
        title: new FieldInputControl({ name: 'value' }),
        data_source: dataSourceControl,
        card_view_unique_name: new FormControl(null),
        card_view: new FormControl(null),
        card_view_mappings: new FormControl([]),
        search_enabled: new FormControl(true),
        search_live: new FormControl(true),
        stage_field: new FormControl('', Validators.required),
        stages: new KanbanStageArray([]),
        ordering_field: new FormControl(''),
        card_click_action: new FormControl(undefined, undefined, validateAction),
        card_column_change_action: new FormControl(undefined, undefined, validateAction),
        card_order_change_action: new FormControl(undefined, undefined, validateAction),
        actions: new FormControl([], undefined, validateActions),
        model_actions: new FormControl([], undefined, validateActions),
        columns_actions: new FieldActionsArray([]),
        custom_actions: new ActionOutputFormGroup(elementConfigurationService),
        sorting_field: new FormControl(undefined),
        sorting_asc: new FormControl(true),
        per_page: new FormControl(undefined),
        multiple_selection: new FormControl(false),
        first_item_default_selection: new FormControl(false),
        display_filters: new FormControl(true),
        filter_fields: new OptionEnabledArray([]),
        visible_input: new FieldInputControl({ name: 'value' }),
        name: new FormControl(''),
        tooltip: new FormControl(''),
        gap_horizontal: new FormControl(null),
        gap_vertical: new FormControl(null),
        margin: new MarginControl(),
        comments_enabled: new FormControl(false),
        comments_object_type: new FieldInputControl({ name: 'value' }),
        comments_object_id: new FieldInputControl({ name: 'value' })
      }
    );
    dataSourceControl.setRequired(true);
  }

  init(options: CustomizeListOptions<KanbanBoardSettings>): Observable<void> {
    this.settings = options.settings;
    this.pageUid = options.pageUid;
    this.elementUid = options.elementUid;

    const customView$ = isSet(options.settings.cardCustomView)
      ? this.customViewsStore.getDetailFirst(options.settings.cardCustomView)
      : of(undefined);

    return customView$.pipe(
      tap(customView => {
        const value = {
          header: options.settings.header,
          title: options.settings.titleInput ? options.settings.titleInput.serialize() : {},
          card_view_unique_name: options.settings.cardCustomView,
          card_view: customView ? customView.view : null,
          card_view_mappings: options.settings.cardCustomViewMappings,
          search_enabled: options.settings.searchEnabled,
          search_live: options.settings.searchLive,
          stage_field: options.settings.stageField,
          // ordering_field: modelDescription.orderingField,
          ordering_field: undefined,
          card_click_action: options.settings.cardClickAction,
          card_column_change_action: options.settings.cardColumnChangeAction,
          card_order_change_action: options.settings.cardOrderChangeAction,
          actions: options.settings.actions,
          model_actions: options.settings.modelActions,
          columns_actions: options.settings.columnActions,
          sorting_field: options.settings.sortingField || null,
          sorting_asc: options.settings.sortingAsc,
          per_page: options.settings.perPage,
          multiple_selection: options.settings.multipleSelection,
          first_item_default_selection: options.settings.defaultSelection == ListDefaultSelection.First,
          display_filters: options.settings.displayFilters,
          filter_fields: options.settings.filterFields.map(item => {
            return {
              name: item.name,
              enabled: true
            };
          }),
          visible_input: options.visibleInput ? options.visibleInput.serialize() : {},
          tooltip: options.settings.tooltip,
          gap_horizontal: options.settings.gapHorizontal,
          gap_vertical: options.settings.gapVertical,
          margin: options.margin,
          comments_enabled: options.settings.commentsEnabled,
          comments_object_type: options.settings.commentsObjectType
            ? options.settings.commentsObjectType.serialize()
            : {},
          comments_object_id: options.settings.commentsObjectId ? options.settings.commentsObjectId.serialize() : {}
        };

        if (options.nameEditable) {
          value['name'] = options.name;
        }

        this.patchValue(value, { emitEvent: false });
        this.controls.data_source.deserialize(options.settings.dataSource);
        this.controls.custom_actions.deserialize(
          customView && customView.view ? customView.view.actions : [],
          options.settings.customActions
        );

        if (options.settings.stages) {
          this.controls.stages.deserialize(options.settings.stages);
        } else {
          this.controls.stages.setControls([this.controls.stages.createControl()]);
        }

        this.trackChanges(options);

        if (!options.firstInit) {
          this.markAsDirty();
        }
      })
    );
  }

  trackChanges(options: CustomizeListOptions<KanbanBoardSettings>) {
    super.trackChanges(options);

    combineLatest(
      controlValue<DisplayField[]>(this.controls.data_source.controls.columns),
      this.controls.data_source.getModelDescription$(),
      this.controls.data_source.getModelDescription$().pipe(
        switchMap(modelDescription => {
          if (!modelDescription) {
            return of([]);
          }

          return this.modelOptionsSource.getOptions$(modelDescription, {
            relationsEnabled: true
          });
        })
      )
    )
      .pipe(debounceTime(60), untilDestroyed(this))
      .subscribe(([columns, modelDescription, modelOptions]) => {
        const columnNames = modelDescription ? modelOptions.map(item => item.name) : columns.map(item => item.name);
        const modelId = modelDescription ? modelDescription.modelId : null;
        const filterFieldsSourceChanged = this.controls.filter_fields.isSourceSet()
          ? !this.controls.filter_fields.isSource(modelId)
          : false;

        this.controls.filter_fields.syncControls(columnNames, { source: modelId });

        if (this.controls.display_filters.value && this.controls.filter_fields.isAllDisabled()) {
          this.controls.filter_fields.enableDefault();
        } else if (this.controls.display_filters.value && filterFieldsSourceChanged) {
          this.controls.filter_fields.enableDefault();
        }
      });

    this.controls.card_view.valueChanges
      .pipe(
        debounceTime(60),
        switchMap(value => {
          this.submitLoading$.next(true);

          return this.submitCardView(this.controls.card_view_unique_name.value, value);
        }),
        tap(uniqueName => {
          this.controls.card_view_unique_name.patchValue(uniqueName);

          this.submitLoading$.next(false);
        }),
        catchError(error => {
          this.submitLoading$.next(false);

          if (error instanceof ServerRequestError && error.errors.length) {
            this.notificationService.error('Error', error.errors[0]);
          } else {
            this.notificationService.error('Error', error);
          }

          return of(undefined);
        }),
        untilDestroyed(this)
      )
      .subscribe();

    this.controls.stage_field.valueChanges.subscribe(() => this.controls.stages.removeControls());
  }

  isGetMissingEnabled() {
    if (this.controls.data_source.controls.type.value != DataSourceType.Query) {
      return false;
    }

    const query = this.controls.data_source.controls.query.value as ListModelDescriptionQuery;

    if (!query) {
      return false;
    } else if (query.queryType == QueryType.Simple && query.simpleQuery) {
      const resourceName = this.controls.data_source.controls.query_resource.value;
      const resource = this.currentEnvironmentStore.resources.find(item => item.uniqueName == resourceName);
      const modelDescription = this.modelDescriptionStore.instance
        ? this.modelDescriptionStore.instance.find(item => item.model == query.simpleQuery.model)
        : undefined;

      if (!resource || !modelDescription) {
        return false;
      }

      if (resource.type == ResourceType.Airtable) {
        return resource.isSynced(modelDescription.model) || modelDescription.isSynced();
      } else {
        return modelDescriptionHasAutoParameters(resource, modelDescription);
      }
    } else if (query.queryType == QueryType.Http) {
      return query.frontendFiltering;
    } else if (query.queryType == QueryType.SQL) {
      return true;
    } else if (query.queryType == QueryType.Object) {
      return true;
    } else {
      return false;
    }
  }

  getMissing(): Observable<any[]> {
    if (!this.controls.stage_field.value) {
      return of([]);
    }

    if (!this.isGetMissingEnabled()) {
      return of([]);
    }

    const resourceName = this.controls.data_source.controls.query_resource.value;
    const resource = this.currentEnvironmentStore.resources.find(item => item.uniqueName == resourceName);
    const modelQuery = this.controls.data_source.controls.query.value as ListModelDescriptionQuery;
    const query = new ChartWidgetQuery();
    const parameters = this.controls.data_source.controls.query_parameters.value;
    const columns = this.controls.data_source.controls.columns.value;
    const xColumns = [{ xColumn: this.controls.stage_field.value, xLookup: DatasetGroupLookup.Plain }];

    query.queryType = modelQuery.queryType;
    query.simpleQuery = modelQuery.simpleQuery;
    query.httpQuery = modelQuery.httpQuery;
    query.sqlQuery = modelQuery.sqlQuery;

    const params = applyParamInputs({}, this.controls.data_source.controls.query_inputs.value, {
      context: this.context,
      contextElement: this.contextElement,
      parameters: parameters
    });

    return this.widgetQueryService
      .groupQuery(resource, query, xColumns, AggregateFunc.Count, undefined, parameters, params, columns)
      .pipe(
        map(result => {
          if (!result) {
            return;
          }

          const existingValues = this.controls.stages.controls.map(item => item.controls.value.value);
          const values = result.map(item => item.group);
          return values.filter(item => existingValues.find(i => i == item) == undefined).slice(0, 10);
        }),
        catchError(() => of(undefined))
      );
  }

  getCardView(): View {
    return this.controls.card_view.value;
  }

  isConfigured(instance: KanbanBoardSettings): Observable<boolean> {
    return this.elementConfigurationService.isListKanbanConfigured(instance, { restrictDemo: true });
  }

  onModelChange(modelDescription: ModelDescription) {
    super.onModelChange(modelDescription);

    this.controls.stage_field.patchValue('');
  }

  submitCardView(uniqueName: string, view?: View): Observable<string> {
    if (!view) {
      return of(undefined);
    }

    const customView$ = isSet(uniqueName) ? this.customViewsStore.getDetailFirst(uniqueName) : of(undefined);

    return customView$.pipe(
      switchMap(customView => {
        if (customView) {
          const instance = cloneDeep(customView);
          const fields = ['unique_name', 'view_type', 'view', 'params'];

          instance.view = view;
          instance.pageUid = this.pageUid;
          instance.elementUid = this.elementUid;

          return this.customViewService.update(
            this.currentProjectStore.instance.uniqueName,
            this.currentEnvironmentStore.instance.uniqueName,
            instance,
            { draft: true, fields: fields }
          );
        } else {
          const instance = new CustomView();
          const fields = ['unique_name', 'view_type', 'view', 'params'];

          instance.uniqueName =
            isSet(this.pageUid) && isSet(this.elementUid)
              ? [CustomViewType.ListItem, this.pageUid, this.elementUid].join('.')
              : [CustomViewType.ListItem, generateAlphanumeric(8, { letterFirst: true })].join('.');
          instance.viewType = CustomViewType.ListItem;
          instance.view = view;
          instance.pageUid = this.pageUid;
          instance.elementUid = this.elementUid;

          return this.customViewService.create(
            this.currentProjectStore.instance.uniqueName,
            this.currentEnvironmentStore.instance.uniqueName,
            instance,
            { draft: true, fields: fields }
          );
        }
      }),
      delayWhen(() => this.customViewsStore.getFirst(true)),
      map(result => result.uniqueName)
    );
  }

  submit(): CustomizeListResult<KanbanBoardSettings> {
    const instance: KanbanBoardSettings = cloneDeep(this.settings);

    instance.titleInput = this.controls.title ? new FieldInput().deserialize(this.controls.title.value) : undefined;
    instance.dataSource = this.controls.data_source.serialize();
    instance.cardCustomView = this.controls.card_view_unique_name.value;
    instance.cardCustomViewMappings = this.controls.card_view_mappings.value;
    instance.searchEnabled = this.controls.search_enabled.value;
    instance.searchLive = this.controls.search_live.value;
    instance.stageField = this.controls.stage_field.value;
    instance.stages = this.controls.stages.serialize();

    if (this.controls.card_click_action.value) {
      instance.cardClickAction = this.controls.card_click_action.value;
    } else {
      instance.cardClickAction = undefined;
    }

    if (this.controls.card_column_change_action.value) {
      instance.cardColumnChangeAction = this.controls.card_column_change_action.value;
    } else {
      instance.cardColumnChangeAction = undefined;
    }

    if (this.controls.card_order_change_action.value) {
      instance.cardOrderChangeAction = this.controls.card_order_change_action.value;
    } else {
      instance.cardOrderChangeAction = undefined;
    }

    instance.actions = this.controls.actions.value;
    instance.modelActions = this.controls.model_actions.value;
    instance.columnActions = this.controls.columns_actions.value;
    instance.customActions = this.controls.custom_actions.serialize();

    if (isSet(this.controls.sorting_field.value)) {
      instance.sortingField = this.controls.sorting_field.value;
    } else {
      instance.sortingField = undefined;
    }

    instance.sortingAsc = this.controls.sorting_asc.value;
    instance.perPage = this.controls.per_page.value;
    instance.multipleSelection = this.controls.multiple_selection.value;
    instance.defaultSelection = this.controls.first_item_default_selection.value
      ? ListDefaultSelection.First
      : undefined;
    instance.filterFields = this.controls.filter_fields.value
      .filter(item => item.enabled)
      .map(item => {
        return {
          name: item.name
        };
      });
    instance.displayFilters = instance.filterFields.length && this.controls.display_filters.value;
    instance.header =
      (instance.titleInput && instance.titleInput.isSet()) || (instance.actions && instance.actions.length > 0);
    instance.tooltip = isSet(this.controls.tooltip.value) ? this.controls.tooltip.value.trim() : undefined;
    instance.gapHorizontal = this.controls.gap_horizontal.value;
    instance.gapVertical = this.controls.gap_vertical.value;

    instance.commentsEnabled = this.controls.comments_enabled.value;
    instance.commentsObjectType = this.controls.comments_object_type.value
      ? new FieldInput().deserialize(this.controls.comments_object_type.value)
      : undefined;
    instance.commentsObjectId = this.controls.comments_object_id.value
      ? new FieldInput().deserialize(this.controls.comments_object_id.value)
      : undefined;

    return {
      settings: instance,
      visibleInput: this.controls.visible_input.value
        ? new Input().deserialize(this.controls.visible_input.value)
        : undefined,
      name: this.controls.name.value,
      margin: this.controls.margin.value
    };
  }
}

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, fromEvent, merge, Subscription } from 'rxjs';
import { delay } from 'rxjs/operators';

import { copyTextToClipboard } from '@common/code';
import { NotificationService } from '@common/notifications';
import { BasePopupComponent } from '@common/popups';
import { AdminMode, ROUTE_ADMIN_MODE } from '@modules/admin-mode';
import { IntercomService, UniversalAnalyticsService } from '@modules/analytics';
import { ApiService, ServerRequestError } from '@modules/api';
import {
  customOAuth2BackendName,
  ProjectTokenService,
  ResourceName,
  SecretToken,
  socialBackends
} from '@modules/projects';
import { HttpMethod, HttpQuery, HttpQueryService, submitForm } from '@modules/queries';
import { AirtableAuthType } from '@modules/resource-generators';
import { capitalize, controlValue, isSet } from '@shared';

import { registerResourceSettingsComponent } from '../../../data/resource-settings-components';
import { ChooseSyncController } from '../../../services/choose-sync/choose-sync.controller';
import { BaseResourceSettingsComponent } from '../base-resource-settings/base-resource-settings.component';
import { AirtableBaseSource } from './airtable-base-source';
import { AirtableResourceSettingsForm } from './airtable-resource-settings.form';

@Component({
  selector: 'app-airtable-resource-settings',
  templateUrl: './airtable-resource-settings.component.html',
  providers: [AirtableResourceSettingsForm, AirtableBaseSource],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AirtableResourceSettingsComponent extends BaseResourceSettingsComponent implements OnInit, OnDestroy {
  account: string;
  accountSubscription: Subscription;
  accountLoading = false;
  chooseTablesLoading = false;
  authTypes = AirtableAuthType;

  constructor(
    @Inject(ROUTE_ADMIN_MODE) private mode: AdminMode,
    public baseSource: AirtableBaseSource,
    private chooseSyncController: ChooseSyncController,
    private httpQueryService: HttpQueryService,
    private apiService: ApiService,
    private notificationService: NotificationService,
    public form: AirtableResourceSettingsForm,
    popupComponent: BasePopupComponent,
    projectTokenService: ProjectTokenService,
    intercomService: IntercomService,
    analyticsService: UniversalAnalyticsService,
    cd: ChangeDetectorRef
  ) {
    super(form, popupComponent, projectTokenService, intercomService, analyticsService, cd);
  }

  ngOnInit() {
    super.ngOnInit();

    fromEvent<MessageEvent>(window, 'message')
      .pipe(untilDestroyed(this))
      .subscribe(message => {
        if (message.data && message.data['type'] == 'oauth_response') {
          const data = message.data['params'];
          const secretToken = new SecretToken().deserialize(data);

          this.form.form.patchValue({
            access_token: secretToken.value,
            params: secretToken.params
          });
        }
      });

    combineLatest(
      controlValue(this.form.form.controls['auth_type']),
      controlValue(this.form.form.controls['access_token']),
      controlValue(this.form.form.controls['personal_access_token'])
    )
      .pipe(delay(0), untilDestroyed(this))
      .subscribe(() => {
        const options = this.form.getOptions();
        this.baseSource.accessToken =
          options.auth_type == AirtableAuthType.PersonalAccessToken
            ? options.personal_access_token
            : options.access_token;
        this.baseSource.reset();
      });

    merge(
      this.form.form.controls['auth_type'].valueChanges,
      this.form.form.controls['access_token'].valueChanges,
      this.form.form.controls['personal_access_token'].valueChanges
    )
      .pipe(untilDestroyed(this))
      .subscribe(key => {
        this.form.form.controls['base'].patchValue(null);
      });
  }

  ngOnDestroy(): void {}

  onFormInit() {
    super.onFormInit();

    controlValue(this.form.form.controls['access_token'])
      .pipe(untilDestroyed(this))
      .subscribe(accessToken => this.updateAccount(accessToken));
  }

  updateAccount(accessToken: string) {
    if (this.accountSubscription) {
      this.accountSubscription.unsubscribe();
      this.accountSubscription = undefined;
    }

    if (!isSet(accessToken)) {
      this.account = undefined;
      this.accountLoading = false;
      this.cd.markForCheck();
      return;
    }

    this.accountLoading = true;
    this.cd.markForCheck();

    const query = new HttpQuery();

    query.url = 'https://api.airtable.com/v0/meta/whoami';
    query.headers = [{ name: 'Authorization', value: `Bearer ${accessToken}` }];

    return this.httpQueryService
      .requestBody<{ id: string }>(query)
      .pipe(untilDestroyed(this))
      .subscribe(
        result => {
          this.account = `User ID – ${result.id}`;
          this.accountSubscription = undefined;
          this.accountLoading = false;
          this.cd.markForCheck();
        },
        () => {
          this.account = undefined;
          this.accountSubscription = undefined;
          this.accountLoading = false;
          this.cd.markForCheck();
        }
      );
  }

  getOAuthToken() {
    this.apiService
      .refreshToken()
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        const params = { USE_BACKEND_SHARED_PARAMS: 'airtable' };
        const redirect = this.redirectUrl();
        const backend = socialBackends.find(item => item.name == customOAuth2BackendName);
        const data = [
          {
            name: 'name',
            value: this.form.tokenNameOAuth
          },
          {
            name: 'backend',
            value: backend ? backend.path : ''
          },
          {
            name: 'params',
            value: JSON.stringify(params)
          },
          {
            name: 'access_token',
            value: this.apiService.getAccessToken()
          },
          {
            name: 'redirect_uri',
            value: redirect
          }
        ];

        if (this.mode == AdminMode.Builder) {
          data.push({
            name: 'draft',
            value: '1'
          });
        }

        const w = open('', '', 'height=800,width=600');
        if (!w) {
          this.notificationService.error(
            'Failed to open popup',
            'Your browser has blocked opening a new window. Please check your browser settings to allow opening pop-ups'
          );
          return;
        }

        submitForm(HttpMethod.POST, this.apiService.createOAuthTokenUrl, data, w.document);
      });
  }

  get completeUrl() {
    return this.apiService.createOAuthTokenCompleteUrl;
  }

  redirectUrl() {
    return `${window.location.origin}/oauth_response`;
  }

  chooseTables() {
    this.chooseTablesLoading = true;
    this.cd.markForCheck();

    this.form
      .discoverTables()
      .pipe(untilDestroyed(this))
      .subscribe(
        result => {
          this.form.form.patchValue({ choose_tables: true });
          this.chooseTablesLoading = false;
          this.cd.markForCheck();
        },
        error => {
          this.chooseTablesLoading = false;
          this.cd.markForCheck();

          if (error instanceof ServerRequestError && error.errors.length) {
            this.notificationService.error('Unable to connect', error.errors[0]);
          } else {
            this.notificationService.error('Unable to connect', error);
          }
        }
      );
  }

  copy(text: string, contentLabel?: string) {
    copyTextToClipboard(text)
      .pipe(untilDestroyed(this))
      .subscribe(success => {
        if (!success) {
          return;
        }

        const description = isSet(contentLabel) ? `${capitalize(contentLabel)} was copied to clipboard` : undefined;
        this.notificationService.info('Copied', description);
      });
  }

  chooseSync() {
    this.chooseSyncController
      .chooseSyncMode(this.form.form.controls['sync'], { typeItem: this.typeItem })
      .pipe(untilDestroyed(this))
      .subscribe(() => this.submit());
  }
}

registerResourceSettingsComponent(ResourceName.Airtable, AirtableResourceSettingsComponent);

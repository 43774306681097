import { Injectable, Injector, OnDestroy } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Observable, Subject } from 'rxjs';

import { PopupService } from '@common/popups';

import { ScannerPopupComponent } from '../../components/scanner-popup/scanner-popup.component';
import { ScanResult } from '../../components/scanner/scanner.component';

export interface ScanPopupResult {
  result?: ScanResult;
  cancelled?: boolean;
}

@Injectable()
export class ScannerPopupController implements OnDestroy {
  constructor(private injector: Injector, private popupService: PopupService) {}

  ngOnDestroy(): void {}

  scan(): Observable<ScanPopupResult> {
    const result = new Subject<ScanPopupResult>();
    const popup = this.popupService.showComponent<ScannerPopupComponent>({
      component: ScannerPopupComponent,
      injector: this.injector,
      inputs: {},
      scrollable: true
    });

    popup.instance.scan.pipe(untilDestroyed(this)).subscribe(scan => {
      result.next({ result: scan });
    });

    popup.instance.cancelled.pipe(untilDestroyed(this)).subscribe(() => {
      result.next({ cancelled: true });
    });

    return result.asObservable();
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';

import { Model } from '@modules/models';

import { ModelUtilsService } from '../../services/model-utils/model-utils.service';

@Pipe({
  name: 'appModelStr'
})
export class ModelStrPipe implements PipeTransform {
  constructor(private modelUtilsService: ModelUtilsService) {}

  transform(value: Model): Observable<string> {
    return this.modelUtilsService.str(value);
  }
}

import { Injector } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { PopupService } from '@common/popups';
import { CookieStorage } from '@core';
import { deployUrl } from '@shared';
import { VideoPopupComponent } from '../../components/video-popup/video-popup.component';
import * as i0 from "@angular/core";
import * as i1 from "../../../../common/popups/services/popup/popup.service";
import * as i2 from "../../../../core/services/cookie-storage/cookie.storage";
var IntroService = /** @class */ (function () {
    function IntroService(popupService, cookieStorage, injector) {
        this.popupService = popupService;
        this.cookieStorage = cookieStorage;
        this.injector = injector;
        this._visible = new BehaviorSubject(false);
    }
    Object.defineProperty(IntroService.prototype, "shown", {
        get: function () {
            return this.cookieStorage.get('intro_shown') == 'true';
        },
        set: function (value) {
            this.cookieStorage.set('intro_shown', value.toString());
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(IntroService.prototype, "visible", {
        get: function () {
            return this._visible.value;
        },
        set: function (value) {
            this._visible.next(value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(IntroService.prototype, "visible$", {
        get: function () {
            return this._visible.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    IntroService.prototype.showOverview = function (sourceElement, autoPlay) {
        var _this = this;
        if (autoPlay === void 0) { autoPlay = false; }
        this.visible = true;
        this.popupService.push({
            component: VideoPopupComponent,
            inputs: {
                title: 'Jet – Quick Overview',
                src: deployUrl('/assets/video/demo.mp4'),
                autoPlay: autoPlay,
                sourceElement: sourceElement,
                links: [
                    {
                        label: 'Continue',
                        action: 'close'
                    },
                    {
                        label: 'Create App',
                        link: ['/projects', 'create']
                    },
                    {
                        label: 'Documentation',
                        href: 'https://docs.jetadmin.io'
                    },
                    {
                        label: 'Jet Website',
                        href: 'https://jetadmin.io/'
                    }
                ]
            },
            outputs: {
                closed: [function () { return (_this.visible = false); }]
            },
            injector: this.injector
        });
    };
    IntroService.prototype.showOverviewIfNotShown = function (sourceElement) {
        if (this.shown || this.visible) {
            return;
        }
        this.shown = true;
        this.showOverview(sourceElement);
    };
    IntroService.ngInjectableDef = i0.defineInjectable({ factory: function IntroService_Factory() { return new IntroService(i0.inject(i1.PopupService), i0.inject(i2.CookieStorage), i0.inject(i0.INJECTOR)); }, token: IntroService, providedIn: "root" });
    return IntroService;
}());
export { IntroService };

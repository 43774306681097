/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../common/tips/directives/tip/tip.directive";
import * as i2 from "@angular/common";
import * as i3 from "./model-element-item/model-element-item.component.ngfactory";
import * as i4 from "./model-element-item/model-element-item.component";
import * as i5 from "../../../action-queries/services/action-controller/action-controller.service";
import * as i6 from "../../../../shared/directives/element/element.directive";
import * as i7 from "../../../../core/services/document/document.service";
import * as i8 from "../../../../shared/pipes/is-set/is-set.pipe";
import * as i9 from "../../../customize/data/view-context-element";
import * as i10 from "../../../customize/data/view-context";
import * as i11 from "./model-element.component";
import * as i12 from "../../../customize/services/customize/customize.service";
import * as i13 from "../../../model-queries/services/model/model.service";
import * as i14 from "../../../projects/stores/current-project.store";
import * as i15 from "../../../projects/stores/current-environment.store";
import * as i16 from "../../../model-queries/stores/model-description.store";
import * as i17 from "../../../action-queries/services/action/action.service";
import * as i18 from "../../../data-sources-queries/services/model-description-data-source/model-description-data-source.service";
import * as i19 from "../../../customize-generators/services/data-source-generator/data-source-generator.service";
import * as i20 from "../../../../common/notifications/services/notification/notification.service";
import * as i21 from "../custom-page-popup/custom-page-popup.component";
var styles_ModelElementComponent = [];
var RenderType_ModelElementComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ModelElementComponent, data: {} });
export { RenderType_ModelElementComponent as RenderType_ModelElementComponent };
function View_ModelElementComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "span", [["class", "form__title-help help-icon icon-help"]], null, null, null, null, null)), i0.ɵdid(1, 737280, null, 0, i1.TipDirective, [i0.ElementRef, i0.ComponentFactoryResolver, i0.Injector, i0.ApplicationRef], { content: [0, "content"], optionsRaw: [1, "optionsRaw"] }, null), i0.ɵpod(2, { side: 0 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.element.tooltip; var currVal_1 = _ck(_v, 2, 0, "top"); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_ModelElementComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [["class", "form__title"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "span", [["class", "form__title-text"]], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, ["", ""])), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_ModelElementComponent_3)), i0.ɵdid(4, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵppd(5, 1)], function (_ck, _v) { var _co = _v.component; var currVal_1 = i0.ɵunv(_v, 4, 0, _ck(_v, 5, 0, i0.ɵnov(_v.parent.parent, 0), _co.element.tooltip)); _ck(_v, 4, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 2, 0, currVal_0); }); }
function View_ModelElementComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "div", [["class", "field form__field"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "label", [["class", "field__label"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 2, "span", [], [[2, "loading-animation", null]], null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "span", [["class", "stub-text"]], null, null, null, null, null)), (_l()(), i0.ɵted(4, null, ["", ""])), (_l()(), i0.ɵeld(5, 0, null, null, 2, "span", [], [[2, "loading-animation", null]], null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 1, "span", [["class", "stub-text"]], null, null, null, null, null)), (_l()(), i0.ɵted(7, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.loading; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.stubs[(_v.context.index % _co.stubs.length)].label; _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.loading; _ck(_v, 5, 0, currVal_2); var currVal_3 = _co.stubs[(_v.context.index % _co.stubs.length)].value; _ck(_v, 7, 0, currVal_3); }); }
function View_ModelElementComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ModelElementComponent_5)), i0.ɵdid(2, 278528, null, 0, i2.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.stubRange; _ck(_v, 2, 0, currVal_0); }, null); }
function View_ModelElementComponent_7(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-model-element-item", [["class", "form__field"]], null, null, null, i3.View_ModelElementItemComponent_0, i3.RenderType_ModelElementItemComponent)), i0.ɵdid(1, 770048, null, 0, i4.ModelElementItemComponent, [i5.ActionControllerService, i0.Injector], { item: [0, "item"], model: [1, "model"], columnActions: [2, "columnActions"], context: [3, "context"], contextElement: [4, "contextElement"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit; var currVal_1 = _co.model; var currVal_2 = _co.elementState.element.columnActions; var currVal_3 = _co.context; var currVal_4 = _co.viewContextElement; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }, null); }
function View_ModelElementComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ModelElementComponent_7)), i0.ɵdid(2, 278528, null, 0, i2.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.visibleColumns; _ck(_v, 2, 0, currVal_0); }, null); }
function View_ModelElementComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, "div", [["appElement", ""], ["class", "form"]], null, [[null, "appElementFirstVisible"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("appElementFirstVisible" === en)) {
        var pd_0 = (_co.firstVisible$.next(true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 8601600, null, 0, i6.ElementDirective, [i0.ElementRef, i7.DocumentService], { appElementDetectVisible: [0, "appElementDetectVisible"] }, { appElementFirstVisible: "appElementFirstVisible" }), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ModelElementComponent_2)), i0.ɵdid(3, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(4, 0, null, null, 4, "div", [["class", "form__fieldset form__fieldset_manual-margin"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ModelElementComponent_4)), i0.ɵdid(6, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ModelElementComponent_6)), i0.ɵdid(8, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = true; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.title; _ck(_v, 3, 0, currVal_1); var currVal_2 = (_co.loading || !_co.model); _ck(_v, 6, 0, currVal_2); var currVal_3 = (!_co.loading && _co.model); _ck(_v, 8, 0, currVal_3); }, null); }
export function View_ModelElementComponent_0(_l) { return i0.ɵvid(2, [i0.ɵpid(0, i8.IsSetPipe, []), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_ModelElementComponent_1)), i0.ɵdid(2, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵpid(131072, i2.AsyncPipe, [i0.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.visible || i0.ɵunv(_v, 2, 0, i0.ɵnov(_v, 3).transform(_co.customizeEnabled$))); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_ModelElementComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "app-model-element", [], null, null, null, View_ModelElementComponent_0, RenderType_ModelElementComponent)), i0.ɵprd(131584, null, i9.ViewContextElement, i9.ViewContextElement, [i10.ViewContext]), i0.ɵdid(2, 770048, null, 0, i11.ModelElementComponent, [i12.CustomizeService, i13.ModelService, i14.CurrentProjectStore, i15.CurrentEnvironmentStore, i16.ModelDescriptionStore, i17.ActionService, i18.ModelDescriptionDataSourceService, i19.DataSourceGeneratorService, i20.NotificationService, i0.ChangeDetectorRef, i9.ViewContextElement, [2, i21.CustomPagePopupComponent]], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var ModelElementComponentNgFactory = i0.ɵccf("app-model-element", i11.ModelElementComponent, View_ModelElementComponent_Host_0, { element: "element", elementActive: "elementActive", context: "context", visible: "visible", actionClicked: "actionClicked", accentColor: "accentColor" }, { updated: "updated", replaceRequested: "replaceRequested", deleteRequested: "deleteRequested" }, []);
export { ModelElementComponentNgFactory as ModelElementComponentNgFactory };

import { Injectable } from '@angular/core';
import * as Sentry from '@sentry/browser';

import { CurrentUserStore } from '@modules/users';

@Injectable({
  providedIn: 'root'
})
export class SentryService {
  constructor(private currentUserStore: CurrentUserStore) {
    this.currentUserStore.getFirst().subscribe(user => {
      Sentry.setUser(user ? { email: user.email } : null);
    });
  }
}

import { Pipe, PipeTransform } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { FormUtils } from '../../services/form-utils';

@Pipe({
  name: 'appFormErrors',
  pure: false
})
export class FormErrorsPipe implements PipeTransform {
  constructor(private formUtils: FormUtils) {}

  transform(form: FormGroup): string[] {
    return this.formUtils.getFormErrors(form);
  }
}

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./stack.component";
var styles_StackComponent = [];
var RenderType_StackComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_StackComponent, data: {} });
export { RenderType_StackComponent as RenderType_StackComponent };
export function View_StackComponent_0(_l) { return i0.ɵvid(2, [i0.ɵncd(null, 0)], null, null); }
export function View_StackComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-stack", [], [[2, "stack", null], [2, "stack_align_left", null], [2, "stack_align_center", null], [2, "stack_align_right", null], [2, "stack_align_space-between", null]], null, null, View_StackComponent_0, RenderType_StackComponent)), i0.ɵdid(1, 114688, null, 0, i1.StackComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).cls; var currVal_1 = i0.ɵnov(_v, 1).alignLeft; var currVal_2 = i0.ɵnov(_v, 1).alignCenter; var currVal_3 = i0.ɵnov(_v, 1).alignRight; var currVal_4 = i0.ɵnov(_v, 1).alignSpaceBetween; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }); }
var StackComponentNgFactory = i0.ɵccf("app-stack", i1.StackComponent, View_StackComponent_Host_0, { align: "align" }, {}, ["*"]);
export { StackComponentNgFactory as StackComponentNgFactory };

import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { untilDestroyed } from 'ngx-take-until-destroy';

import { ApiService } from '@modules/api';
import { AuthService } from '@modules/auth';
import { Domain } from '@modules/domain';
import { RoutingService } from '@modules/routing';
import { openUrl } from '@shared';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html'
})
export class LogoutComponent implements OnInit, OnDestroy {
  constructor(
    private activatedRoute: ActivatedRoute,
    private apiService: ApiService,
    private authService: AuthService,
    private routing: RoutingService
  ) {}

  ngOnInit() {
    this.activatedRoute.data.pipe(untilDestroyed(this)).subscribe(data => {
      const domain: Domain = data.domain;
      const ssoUid = this.apiService.getSSOUid();
      const sso = ssoUid && domain ? domain.ssoSettings.find(item => item.uid == ssoUid) : undefined;

      this.authService.logout();

      if (sso && sso.logoutUrl) {
        openUrl(sso.logoutUrl);
      } else {
        this.routing.navigate(['/login'], { replaceUrl: true });
      }
    });
  }

  ngOnDestroy(): void {}
}

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { UserActivity, UserActivityType } from '@modules/activities';

@Component({
  selector: 'app-user-activities-description, [app-user-activities-description]',
  templateUrl: './user-activities-description.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserActivitiesDescriptionComponent {
  @Input() item: UserActivity;
  @Input() relations: Object;

  userActivityTypes = UserActivityType;
}

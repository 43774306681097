/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../input-tokens-list-item/input-tokens-list-item.component.ngfactory";
import * as i2 from "../input-tokens-list-item/input-tokens-list-item.component";
import * as i3 from "@angular/common";
import * as i4 from "./input-tokens-list.component";
var styles_InputTokensListComponent = [];
var RenderType_InputTokensListComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_InputTokensListComponent, data: {} });
export { RenderType_InputTokensListComponent as RenderType_InputTokensListComponent };
function View_InputTokensListComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-input-tokens-list-item", [], null, [[null, "clickItem"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("clickItem" === en)) {
        var pd_0 = (_co.clickItem.emit($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_InputTokensListItemComponent_0, i1.RenderType_InputTokensListItemComponent)), i0.ɵdid(1, 638976, null, 0, i2.InputTokensListItemComponent, [], { item: [0, "item"], tokenValues: [1, "tokenValues"] }, { clickItem: "clickItem" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit; var currVal_1 = _co.tokenValues; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_InputTokensListComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "div", [["class", "token-popup-section"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "div", [["class", "token-popup-section__title"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "div", [["class", "token-popup-section__title-main"]], null, null, null, null, null)), (_l()(), i0.ɵted(3, null, ["", ""])), (_l()(), i0.ɵeld(4, 0, null, null, 2, "div", [["class", "token-popup-section__content"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_InputTokensListComponent_1)), i0.ɵdid(6, 278528, null, 0, i3.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.items; _ck(_v, 6, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 3, 0, currVal_0); }); }
export function View_InputTokensListComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-input-tokens-list", [], null, null, null, View_InputTokensListComponent_0, RenderType_InputTokensListComponent)), i0.ɵdid(1, 114688, null, 0, i4.InputTokensListComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var InputTokensListComponentNgFactory = i0.ɵccf("app-input-tokens-list", i4.InputTokensListComponent, View_InputTokensListComponent_Host_0, { title: "title", items: "items", tokenValues: "tokenValues" }, { clickItem: "clickItem" }, []);
export { InputTokensListComponentNgFactory as InputTokensListComponentNgFactory };

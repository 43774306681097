import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, interval, Observable, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';

import { hasClass } from '@shared';

@Injectable()
export class DriftService {
  private _loaded: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private _playbookActive: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private _manuallyShown: BehaviorSubject<boolean> = new BehaviorSubject(false);

  loadedCheck: Subscription;
  api: any;
  payload: any;

  constructor() {
    this.loadedCheck = interval(1000).subscribe(() => {
      if (!this.drift) {
        return;
      }

      this.loadedCheck.unsubscribe();
      this.drift.on('ready', (api, payload) => {
        this.api = api;
        this.payload = payload;
        this._loaded.next(true);
      });
    });

    this.loaded$.subscribe(() => {
      interval(400).subscribe(() => {
        this._manuallyShown.next(this.visible);
      });

      this.drift.on('campaign:dismiss', () => {
        this._playbookActive.next(false);
      });

      this.drift.on('conversation:playbookFired', () => {
        this._playbookActive.next(true);
      });
    });
  }

  get loaded$() {
    return this._loaded.pipe(filter(item => item == true));
  }

  get drift() {
    return window['drift'];
  }

  openChat() {
    this.loaded$.subscribe(() => {
      this.api.openChat();
    });
  }

  show() {
    this.loaded$.subscribe(() => {
      this.api.widget.show();
      this._manuallyShown.next(true);
    });
  }

  hide() {
    this.loaded$.subscribe(() => {
      this.api.widget.hide();
    });
  }

  get visible() {
    return hasClass(document.getElementById('drift-widget'), 'drift-widget-welcome-expanded-online');
  }

  get visible$(): Observable<boolean> {
    return combineLatest(this._playbookActive, this._manuallyShown).pipe(
      map(([playbookActive, manuallyShown]) => playbookActive || manuallyShown),
      distinctUntilChanged()
    );
  }
}

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError, map, publishLast, refCount } from 'rxjs/operators';
import { AppConfigService } from '@core';
import { ApiService, ServerRequestError } from '@modules/api';
import { PriceFeature } from '../../models/price-feature';
import { PricingPlan } from '../../models/pricing-plan';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../../api/services/api/api.service";
import * as i3 from "../../../../core/services/app-config/app-config.service";
var PricingService = /** @class */ (function () {
    function PricingService(http, apiService, appConfigService) {
        this.http = http;
        this.apiService = apiService;
        this.appConfigService = appConfigService;
        this.baseName = 'apptO8NbyvB2dtUOd';
    }
    PricingService.prototype.setAirTableHeadersToken = function (headers) {
        var token = this.appConfigService.airtableApiKey || '';
        if (!token) {
            return headers;
        }
        return headers.append('Authorization', "Bearer " + token);
    };
    Object.defineProperty(PricingService.prototype, "airBaseUrl", {
        get: function () {
            return "https://api.airtable.com/v0/";
        },
        enumerable: true,
        configurable: true
    });
    PricingService.prototype.airMethodURL = function (baseName, tableName) {
        return this.apiService.methodURL("data/v0/" + baseName + "/" + tableName);
    };
    PricingService.prototype.getPricing = function (tableName) {
        var url = this.airMethodURL(this.baseName, tableName);
        var headers = new HttpHeaders();
        headers = this.setAirTableHeadersToken(headers);
        return this.http
            .get(url, {
            headers: headers,
            params: {
                maxRecords: '',
                view: 'Grid view'
            },
            withCredentials: false
        })
            .pipe(map(function (result) {
            return result['records'].map(function (item) {
                var priceFeature = new PriceFeature().deserialize(item['fields']);
                priceFeature.id = item['id'];
                return priceFeature;
            });
        }), catchError(function (error) { return throwError(new ServerRequestError(error)); }), publishLast(), refCount());
    };
    PricingService.prototype.getPlans = function (tableName) {
        var url = this.airMethodURL(this.baseName, tableName);
        var headers = new HttpHeaders();
        headers = this.setAirTableHeadersToken(headers);
        return this.http
            .get(url, {
            headers: headers,
            params: {
                maxRecords: '',
                view: 'Grid view'
            },
            withCredentials: false
        })
            .pipe(map(function (result) {
            return result['records'].map(function (item) { return new PricingPlan().deserialize(item['fields']); });
        }), catchError(function (error) { return throwError(new ServerRequestError(error)); }), publishLast(), refCount());
    };
    PricingService.ngInjectableDef = i0.defineInjectable({ factory: function PricingService_Factory() { return new PricingService(i0.inject(i1.HttpClient), i0.inject(i2.ApiService), i0.inject(i3.AppConfigService)); }, token: PricingService, providedIn: "root" });
    return PricingService;
}());
export { PricingService };

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Optional,
  Output
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Subscription } from 'rxjs';

import { NotificationService } from '@common/notifications';
import { BasePopupComponent } from '@common/popups';
import { UniqueIdToken } from '@common/unique-id';
import { ServerRequestError } from '@modules/api';
import { Option } from '@modules/field-components';
import { createFormFieldFactory } from '@modules/fields';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
import { SketchService } from '@modules/sketch-integration';
import { View } from '@modules/views';
import { controlValue, getExtension, getFilenameWithExtension } from '@shared';

import { ImportSketchFileForm } from './import-sketch-file.form';

@Component({
  selector: 'app-import-sketch-file',
  templateUrl: './import-sketch-file.component.html',
  providers: [ImportSketchFileForm],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImportSketchFileComponent implements OnInit, OnDestroy {
  @Output() importView = new EventEmitter<View>();
  @Output() cancelled = new EventEmitter<void>();

  createField = createFormFieldFactory();
  idToken = new UniqueIdToken();

  draggingOver = false;
  previewFilename: string;
  previewExtension: string;
  previewSize: number;
  previewImage: string;

  fileSubscriptions: Subscription[] = [];
  pageOptions: Option<string>[];
  artboardOptions: Option<string>[];
  fileLoading = false;
  importLoading = false;

  constructor(
    @Optional() protected popupComponent: BasePopupComponent,
    public form: ImportSketchFileForm,
    private currentProjectStore: CurrentProjectStore,
    private currentEnvironmentStore: CurrentEnvironmentStore,
    private sketchService: SketchService,
    private notificationService: NotificationService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    controlValue<File>(this.form.controls.file)
      .pipe(untilDestroyed(this))
      .subscribe(file => {
        this.fileSubscriptions.forEach(item => item.unsubscribe());
        this.fileSubscriptions = [];

        if (file) {
          this.previewFilename = getFilenameWithExtension(file.name);
          this.previewExtension = getExtension(file.name);
          this.previewSize = file.size;

          this.loadFile(file);
        } else {
          this.previewFilename = undefined;
          this.previewExtension = undefined;
          this.previewSize = undefined;
          this.pageOptions = undefined;
          this.artboardOptions = undefined;
        }

        this.cd.markForCheck();
      });
  }

  ngOnDestroy(): void {}

  onFileChange(el: HTMLInputElement) {
    if (!el.files.length) {
      return;
    }

    const file = el.files[0];
    el.value = null;
    this.form.controls.file.setValue(file);
    this.form.markAsPristine();
  }

  onDragOver(e: DragEvent) {
    e.stopPropagation();
    e.preventDefault();

    this.draggingOver = true;
    this.cd.markForCheck();
  }

  onDragLeave(e: DragEvent) {
    e.stopPropagation();
    e.preventDefault();

    this.draggingOver = false;
    this.cd.markForCheck();
  }

  onDrop(e: DragEvent) {
    e.stopPropagation();
    e.preventDefault();

    if (!e.dataTransfer.files.length) {
      return;
    }

    const file = e.dataTransfer.files[0];

    this.draggingOver = false;
    this.cd.markForCheck();

    if (file) {
      this.form.controls.file.setValue(file);
      this.form.markAsPristine();
    }
  }

  loadFile(file: File) {
    const fileSubscriptions: Subscription[] = [];

    this.fileLoading = true;
    this.pageOptions = undefined;
    this.artboardOptions = undefined;
    this.previewImage = undefined;
    this.cd.markForCheck();

    fileSubscriptions.push(
      this.sketchService
        .getPages(this.currentProjectStore.instance.uniqueName, this.currentEnvironmentStore.instance.uniqueName, file)
        .pipe(untilDestroyed(this))
        .subscribe(
          result => {
            this.pageOptions = result.pages.map(item => {
              return {
                value: item.id,
                name: item.name
              };
            });
            this.artboardOptions = [];
            this.previewImage = result.previewBase64;
            this.fileLoading = false;
            this.cd.markForCheck();

            fileSubscriptions.push(
              controlValue<string>(this.form.controls.page)
                .pipe(untilDestroyed(this))
                .subscribe(pageId => {
                  const page = result.pages.find(item => item.id == pageId);
                  const artboards = page ? page.artboards : [];

                  this.artboardOptions = artboards.map(item => {
                    return {
                      value: item.id,
                      name: item.name
                    };
                  });
                  this.cd.markForCheck();
                })
            );
          },
          error => {
            this.fileLoading = false;
            this.cd.markForCheck();

            if (error instanceof ServerRequestError && error.errors.length) {
              this.notificationService.error('Loading failed', error.errors[0]);
            } else {
              this.notificationService.error('Loading failed', error);
            }
          }
        )
    );

    this.fileSubscriptions = fileSubscriptions;
  }

  importFile() {
    this.importLoading = true;
    this.cd.markForCheck();

    this.sketchService
      .parseArtboard(
        this.currentProjectStore.instance.uniqueName,
        this.currentEnvironmentStore.instance.uniqueName,
        this.form.controls.file.value,
        this.form.controls.page.value,
        this.form.controls.artboard.value
      )
      .pipe(untilDestroyed(this))
      .subscribe(
        view => {
          this.importLoading = false;
          this.cd.markForCheck();

          this.importView.emit(view);
          this.close();

          this.notificationService.success('Imported', 'File was imported successfully');
        },
        error => {
          this.importLoading = false;
          this.cd.markForCheck();

          if (error instanceof ServerRequestError && error.errors.length) {
            this.notificationService.error('Import failed', error.errors[0]);
          } else {
            this.notificationService.error('Import failed', error);
          }
        }
      );
  }

  cancel() {
    this.cancelled.emit();
    this.close();
  }

  close() {
    if (this.popupComponent) {
      this.popupComponent.close();
    }
  }
}

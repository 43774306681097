import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-gap-control',
  templateUrl: './gap-control.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GapControlComponent implements OnInit {
  @Input() horizontalControl: FormControl;
  @Input() verticalControl: FormControl;

  constructor() {}

  ngOnInit() {}
}

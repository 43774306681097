var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { FormArray } from '@angular/forms';
import range from 'lodash/range';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { debounceTime } from 'rxjs/operators';
import { isSet } from '@shared';
import { HttpParameterControl } from './http-parameter.control';
var HttpParameterArray = /** @class */ (function (_super) {
    __extends(HttpParameterArray, _super);
    function HttpParameterArray(controls) {
        var _this = _super.call(this, controls) || this;
        _this.typeEnabled = false;
        _this.addParameterIfNeeded();
        _this.valueChanges.pipe(debounceTime(60), untilDestroyed(_this)).subscribe(function () {
            _this.addParameterIfNeeded();
            _this.removeParameterIfNeeded();
        });
        return _this;
    }
    HttpParameterArray.prototype.ngOnDestroy = function () { };
    HttpParameterArray.prototype.serialize = function () {
        var _this = this;
        return this.controls
            .filter(function (item) { return isSet(item.controls.name.value); })
            .map(function (item) {
            return __assign({ name: item.controls.name.value, value: item.controls.value.value }, (_this.typeEnabled && { type: item.controls.type.value }));
        });
    };
    HttpParameterArray.prototype.setValue = function (value, options) {
        var _this = this;
        var controls = value.map(function (item) { return _this.addParameter(item); });
        this.setControls(controls);
    };
    HttpParameterArray.prototype.patchValue = function (value, options) {
        var _this = this;
        var controls = value.map(function (item) { return _this.addParameter(item); });
        this.setControls(controls);
    };
    HttpParameterArray.prototype.setControls = function (controls) {
        var _this = this;
        this.removeControls();
        controls.forEach(function (item) { return _this.push(item); });
        this.addParameterIfNeeded();
    };
    HttpParameterArray.prototype.removeControls = function () {
        var _this = this;
        range(this.controls.length).forEach(function () { return _this.removeAt(0); });
    };
    HttpParameterArray.prototype.addParameter = function (value) {
        if (value === void 0) { value = {}; }
        var control = new HttpParameterControl(value);
        this.push(control);
        return control;
    };
    HttpParameterArray.prototype.addParameterIfNeeded = function () {
        var lastControl = this.controls.length ? this.controls[this.controls.length - 1] : undefined;
        if (!lastControl || lastControl.isSet()) {
            return this.addParameter();
        }
    };
    HttpParameterArray.prototype.removeParameterIfNeeded = function () {
        var preLastControl = this.controls[this.controls.length - 2];
        var lastControl = this.controls[this.controls.length - 1];
        if (preLastControl && !preLastControl.isSet() && lastControl && !lastControl.isSet()) {
            this.removeAt(this.controls.length - 1);
        }
    };
    return HttpParameterArray;
}(FormArray));
export { HttpParameterArray };

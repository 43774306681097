var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ChangeDetectorRef, EventEmitter, Injector, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BasePopupComponent } from '@common/popups';
import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { CurrentEnvironmentStore, CurrentProjectStore, Environment, isResourceTypeItemCompatible, JET_APP_RESOURCE, Project } from '@modules/projects';
import { ResourceEditController } from '@modules/projects-components';
var ChooseResourcesComponent = /** @class */ (function () {
    function ChooseResourcesComponent(currentProjectStore, currentEnvironmentStore, popupComponent, resourceEditController, analyticsService, injector, cd) {
        this.currentProjectStore = currentProjectStore;
        this.currentEnvironmentStore = currentEnvironmentStore;
        this.popupComponent = popupComponent;
        this.resourceEditController = resourceEditController;
        this.analyticsService = analyticsService;
        this.injector = injector;
        this.cd = cd;
        this.selectResources = [];
        this.demoAvailable = false;
        this.selected = new EventEmitter();
        this.demoSelected = new EventEmitter();
        this.cancelled = new EventEmitter();
        this.resources = [];
        this.selectedResources = [];
    }
    ChooseResourcesComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.currentEnvironmentStore.resources$.pipe(untilDestroyed(this)).subscribe(function (resources) {
            _this.resources = resources.filter(function (item) { return !item.demo && item.uniqueName != JET_APP_RESOURCE; }) || [];
            _this.selectedResources = _this.selectResources
                // .filter(item => !item.options)
                .reduce(function (acc, current) {
                if (acc.find(function (item) { return item.name == current.token; })) {
                    return;
                }
                var existingItem = _this.selectedResources.find(function (item) { return item.token == current.token; });
                acc.push(__assign({}, current, { resource: existingItem ? existingItem.resource : undefined }));
                return acc;
            }, []);
            _this.cd.markForCheck();
        });
        this.analyticsService.sendSimpleEvent(AnalyticsEvent.Template.StartedToChooseResources, {
            Source: this.analyticsSource,
            ResourceID: this.selectResources.length ? this.selectResources[0].typeItem.name : undefined,
            ResourceIDs: this.selectResources.map(function (item) { return item.typeItem.name; }).join(','),
            MultipleResources: this.selectResources.length > 1
        });
    };
    ChooseResourcesComponent.prototype.ngOnDestroy = function () { };
    ChooseResourcesComponent.prototype.isValid = function () {
        return this.selectedResources.every(function (item) { return item.resource != undefined; });
    };
    ChooseResourcesComponent.prototype.resourcesForItem = function (typeItem) {
        var _this = this;
        return this.resources
            .filter(function (item) { return isResourceTypeItemCompatible(item.typeItem, typeItem); })
            .filter(function (item) { return !_this.demoAvailable || (_this.demoAvailable && !item.demo); });
    };
    ChooseResourcesComponent.prototype.openResourceSettings = function (index, resource) {
        var _this = this;
        var typeItem = this.selectedResources[index].typeItem;
        if (!resource) {
            this.analyticsService.sendSimpleEvent(AnalyticsEvent.Resource.ClickedCreateResource, {
                Source: this.analyticsSource,
                ResourceID: typeItem.name
            });
        }
        this.resourceEditController
            .openEditPopup(typeItem, resource, {
            resourceNameEditing: true,
            analyticsSource: this.analyticsSource
        })
            .pipe(untilDestroyed(this))
            .subscribe(function (result) {
            if (result.resource) {
                _this.selectExistingResource(index, result.resource, result.created);
                if (_this.selectedResources.length == 1) {
                    _this.submit();
                }
            }
        });
    };
    ChooseResourcesComponent.prototype.selectExistingResource = function (index, savedResource, created) {
        var _this = this;
        if (created === void 0) { created = false; }
        this.currentEnvironmentStore.resources$.pipe(untilDestroyed(this)).subscribe(function (resources) {
            var resource = resources ? resources.find(function (item) { return item.uniqueName == savedResource.uniqueName; }) : undefined;
            _this.selectedResources = _this.selectedResources.map(function (item, i) {
                if (i == index) {
                    item.resource = resource;
                    item.created = created;
                }
                return item;
            });
            _this.cd.markForCheck();
        });
    };
    ChooseResourcesComponent.prototype.submit = function () {
        this.popupComponent.close();
        this.analyticsService.sendSimpleEvent(AnalyticsEvent.Template.ResourcesChooseCompleted, {
            Source: this.analyticsSource,
            ResourceID: this.selectResources.length ? this.selectResources[0].typeItem.name : undefined,
            ResourceIDs: this.selectResources.map(function (item) { return item.typeItem.name; }).join(','),
            MultipleResources: this.selectResources.length > 1
        });
        this.selected.emit(this.selectedResources);
    };
    ChooseResourcesComponent.prototype.useDemo = function () {
        this.demoSelected.emit();
        this.popupComponent.close();
    };
    ChooseResourcesComponent.prototype.cancel = function () {
        this.cancelled.emit();
        this.popupComponent.close();
    };
    return ChooseResourcesComponent;
}());
export { ChooseResourcesComponent };

<div class="token-popup__item token-popup__border-item">
  <div class="token-popup-list-item">
    <div class="token-popup-list-item__inner token-popup-list-item__line">
      <span class="token-popup-list-item__left">
        <a href="javascript:void(0)" class="button button_orange button_icon button_extra-small" (click)="back.emit()">
          <span class="button__icon icon-arrow_backward_2"></span>
        </a>
      </span>

      <span class="token-popup-list-item__main">
        <span
          *ngIf="selectedToken"
          class="token-popup-list-item__icon"
          [ngClass]="['icon-' + selectedToken.icon]"
        ></span>
        {{ selectedToken.label || selectedToken.name }}
      </span>
    </div>
  </div>

  <div class="token-popup-list-item">
    <div class="token-popup-list-item__inner">
      <div class="token-popup-list-item__line">
        <div class="token-popup-list-item__main">
          <app-auto-field
            *ngIf="!fileInput && formField"
            [form]="form"
            [field]="formField"
            [label]="false"
            [autofocus]="autofocus"
          ></app-auto-field>

          <ng-container *ngIf="fileInput">
            <div class="input input_fill input_small" [style.width.px]="300">
              <ng-container *ngIf="selectedTokenValue.value">{{ selectedTokenValue.value.name }}</ng-container>
              <div *ngIf="!selectedTokenValue.value" class="input__placeholder">
                Example file for test request
              </div>
            </div>

            <div style="margin-top: 8px;">
              <input class="input input_file" type="file" (change)="onFileChange($event.target)" [id]="'file_upload'" />

              <a
                *ngIf="selectedTokenValue.value"
                href="javascript:void(0)"
                class="button button_orange button_small"
                style="margin: 0 8px 0 0;"
                (click)="selectedTokenValue.patchValue(undefined)"
              >
                <span class="icon icon-bin button__icon button__icon_left"></span>
                <span class="button__label">Clear file</span>
              </a>

              <label [for]="'file_upload'" class="button button_orange button_small" style="margin: 0 8px 0 0;">
                <span class="icon icon-cloud_upload button__icon button__icon_left"></span>
                <span class="button__label">
                  <ng-container *ngIf="selectedTokenValue.value">Change file</ng-container>
                  <ng-container *ngIf="!selectedTokenValue.value">Choose file</ng-container>
                </span>
              </label>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>

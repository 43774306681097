import { Directive, ElementRef, Injector, Input, OnDestroy, OnInit, Optional } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { fromEvent } from 'rxjs';

import { PopoverService } from '@common/popover';
import { CurrentProjectStore } from '@modules/projects';
import { RoutingService } from '@modules/routing';

import { DemoBlockComponent } from '../../components/demo-block/demo-block.component';
import { ProBlockComponent } from '../../components/pro-block/pro-block.component';

interface DemoOnlyDirectiveOptions {
  link?: any[];
  pro?: boolean;
  demo?: boolean;
}

@Directive({
  selector: '[appDemoOnly]'
})
export class DemoOnlyDirective implements OnInit, OnDestroy {
  @Input() appDemoOnly: DemoOnlyDirectiveOptions;

  constructor(
    private el: ElementRef,
    @Optional() private currentProjectStore: CurrentProjectStore,
    private routing: RoutingService,
    private popoverService: PopoverService,
    private injector: Injector
  ) {}

  ngOnInit(): void {
    fromEvent<MouseEvent>(this.el.nativeElement, 'click')
      .pipe(untilDestroyed(this))
      .subscribe(e => {
        if (this.appDemoOnly.pro) {
          this.showPopover(ProBlockComponent);
        } else if (
          this.appDemoOnly.demo !== false &&
          this.currentProjectStore &&
          this.currentProjectStore.instance.demo
        ) {
          this.showPopover(DemoBlockComponent);
        } else {
          this.executeOriginalHandler(e);
          return;
        }

        e.preventDefault();
        e.stopPropagation();
      });
  }

  ngOnDestroy(): void {}

  executeOriginalHandler(e: MouseEvent) {
    if (this.appDemoOnly.link) {
      this.routing.navigate(this.appDemoOnly.link);
    }
  }

  showPopover(component) {
    this.popoverService.create(
      {
        component: component,
        injector: this.injector
      },
      this.el.nativeElement
    );
  }
}

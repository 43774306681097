<div
  class="dropdown-selector dropdown-selector_scrollable dropdown-selector_small-border-radius"
  [style.min-width.px]="264"
  [style.max-height.vh]="80"
  infiniteScroll
  (scrolled)="projectUserListStore.getNext()"
>
  <div class="dropdown-selector__search">
    <div class="dropdown-selector-search">
      <input
        type="text"
        class="dropdown-selector-search__input"
        [formControl]="searchControl"
        placeholder="Type to search..."
        [appAutofocus]="true"
        (keyup)="onSearchKey($event)"
      />
      <span class="dropdown-selector-search__icon icon-search"></span>
      <span
        *ngIf="searchControl.value | appIsSet"
        class="dropdown-selector-search__close icon-close"
        (click)="resetSearch()"
      ></span>
    </div>
  </div>

  <div *ngIf="originalUser" class="dropdown-selector__item" (click)="resetUser.emit()">
    <div class="dropdown-selector__item-left">
      <div
        class="dropdown-selector__item-photo"
        [style.background-image]="originalUser.photo ? 'url(' + originalUser.photo + ')' : ''"
      ></div>
    </div>
    <div class="dropdown-selector__item-main">
      <div class="dropdown-selector__item-title">{{ originalUser.strFull }}</div>
      <div class="dropdown-selector__item-subtitle">You</div>
    </div>
    <div *ngIf="!(currentUserStore.override$ | async)" class="dropdown-selector__item-right">
      <span class="dropdown-selector__item-check icon-check_2"></span>
    </div>
  </div>

  <ng-container *ngIf="!loading">
    <div
      *ngFor="let item of projectUserListStore.items$ | async"
      class="dropdown-selector__item"
      (click)="selectUser.emit(item)"
    >
      <div class="dropdown-selector__item-left">
        <div
          class="dropdown-selector__item-photo"
          [style.background-image]="item.user?.photo ? 'url(' + item.user.photo + ')' : ''"
        ></div>
      </div>
      <div class="dropdown-selector__item-main">
        <div class="dropdown-selector__item-title">
          {{ item.user?.strFull }}
          <ng-container *ngIf="item.group && item.group.name | appIsSet"> ({{ item.group.name }})</ng-container>
        </div>
        <div class="dropdown-selector__item-subtitle">
          {{ item.getEmail() }}
        </div>
      </div>
      <div *ngIf="(currentUserStore.override$ | async)?.uid == item.user?.uid" class="dropdown-selector__item-right">
        <span class="dropdown-selector__item-check icon-check_2"></span>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="loading">
    <div *ngFor="let item of [1, 2, 3]" class="dropdown-selector__item">
      <div class="dropdown-selector__item-main">
        <div class="dropdown-selector__item-title">
          <span [class.loading-animation]="true">
            <span class="stub-text">name</span>
          </span>
        </div>
        <div class="dropdown-selector__item-subtitle">
          <span [class.loading-animation]="true">
            <span class="stub-text">surname</span>
          </span>
        </div>
      </div>
    </div>
  </ng-container>
</div>

import {
  AfterViewInit,
  ChangeDetectorRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest, merge, Observable } from 'rxjs';
import { skip } from 'rxjs/operators';

import { AnalyticsEvent, UniversalAnalyticsService } from '@modules/analytics';
import { ElementItem, ListLayoutSettings, ViewContext, ViewContextElement } from '@modules/customize';
import { DataSourceType } from '@modules/data-sources';
import { BooleanFieldStyle, Option, SelectFieldComponent } from '@modules/field-components';
import { createFormFieldFactory } from '@modules/fields';
import { QueryType } from '@modules/queries';
import { HttpResultsSection, SqlResultsSection } from '@modules/queries-components';
import { ResourceGeneratorResolver } from '@modules/resource-generators';

import { CustomizeBarEditComponent } from '../../../data/customize-bar-edit-component';
import { CustomizeBarEditEvent } from '../../../data/customize-bar-edit-event';
import { CustomizeBarEditEventType } from '../../../data/customize-bar-edit-event-type';
import { DataSourceInputsEditComponent } from '../../data-source-inputs-edit/data-source-inputs-edit.component';
import { ModelDescriptionDataSourceEditComponent } from '../../model-description-data-source-edit/model-description-data-source-edit.component';
import { CustomizeBarListLayoutSettingsForm } from './customize-bar-list-layout-settings.form';

export abstract class CustomizeBarListLayoutSettingsDataSourceComponent<T extends ListLayoutSettings>
  implements OnInit, OnDestroy, AfterViewInit, CustomizeBarEditComponent {
  @Input() settings: T;
  @Input() element: ElementItem;
  @Input() title: string;
  @Input() form: CustomizeBarListLayoutSettingsForm<T>;
  @Input() context: ViewContext;
  @Input() contextElement: ViewContextElement;
  @Input() contextElementPath: (string | number)[];
  @Input() contextElementPaths: (string | number)[][];
  @Input() setupOnCreate = false;
  @Input() addInput = false;
  @Input() editSorting = false;
  @Input() object: string;
  @Output() event = new EventEmitter<CustomizeBarEditEvent>();

  @ViewChild('sorting_field', { read: SelectFieldComponent }) sortingFieldComponent: SelectFieldComponent;
  @ViewChild(ModelDescriptionDataSourceEditComponent) dataSourceEditComponent: ModelDescriptionDataSourceEditComponent;
  @ViewChild(DataSourceInputsEditComponent) inputsEditComponent: DataSourceInputsEditComponent;

  createField = createFormFieldFactory();
  queryTypes = QueryType;
  booleanFieldStyle = BooleanFieldStyle;
  dataSourceTypes = DataSourceType;

  columnOptions$: Observable<Option<string>[]>;
  sortableColumnOptions$: Observable<Option<string>[]>;
  dataConfigured$: Observable<boolean>;

  constructor(
    protected resourceGeneratorResolver: ResourceGeneratorResolver,
    protected analyticsService: UniversalAnalyticsService,
    protected cd: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.columnOptions$ = this.form.controls.data_source.getColumnOptions$();
    this.sortableColumnOptions$ = this.form.controls.data_source.getSortableColumnOptions$();
    this.dataConfigured$ = this.form.controls.data_source.getDataConfigured$();

    // TODO: Temp fix
    merge(this.columnOptions$, this.sortableColumnOptions$)
      .pipe(untilDestroyed(this))
      .subscribe(() => this.cd.detectChanges());

    this.form.controls.data_source
      .getResource$()
      .pipe(skip(1), untilDestroyed(this))
      .subscribe(resource => {
        if (resource) {
          this.analyticsService.sendSimpleEvent(AnalyticsEvent.ComponentData.ResourceSelected, {
            ComponentTypeID: this.settings.analyticsName,
            ResourceID: resource ? resource.typeItem.name : undefined,
            ResourceDemo: resource ? resource.demo : false
          });
        }
      });

    combineLatest(
      this.form.controls.data_source.getResource$().pipe(skip(1)),
      this.form.controls.data_source.getModelDescription$().pipe(skip(1))
    )
      .pipe(untilDestroyed(this))
      .subscribe(([resource, modelDescription]) => {
        if (modelDescription) {
          this.analyticsService.sendSimpleEvent(AnalyticsEvent.ComponentData.ResourceItemSelected, {
            ComponentTypeID: this.settings.analyticsName,
            ResourceID: resource ? resource.typeItem.name : undefined,
            ResourceDemo: resource ? resource.demo : false
          });
        }
      });

    this.analyticsService.sendSimpleEvent(AnalyticsEvent.ComponentData.StartedToSetUp, {
      ComponentTypeID: this.settings.analyticsName
    });
  }

  ngOnDestroy(): void {}

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (this.editSorting && this.sortingFieldComponent) {
        this.sortingFieldComponent.open();
      }

      if (this.addInput && this.inputsEditComponent) {
        this.inputsEditComponent.openAddInput();
      }
    }, 0);
  }

  submit() {
    this.event.emit({ type: CustomizeBarEditEventType.Updated, args: { result: this.form.submit() } });
  }

  cancel() {
    this.event.emit({ type: CustomizeBarEditEventType.Canceled });
  }

  addQueryInput() {
    if (this.dataSourceEditComponent) {
      this.dataSourceEditComponent.editQuery({
        initialHttpResultsSection: HttpResultsSection.Parameters,
        initialSqlResultsSection: SqlResultsSection.Parameters
      });
    }
  }
}

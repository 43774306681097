import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';

import { AppDrag, AppDropListGroup } from '@common/drag-drop2';

import { ViewContext } from '@modules/customize';

import { CustomizeBarItem } from '../../../../data/customize-bar-item';
import { TemplatesSectionGroupDisplay } from '../../../../data/templates-section-group';

@Component({
  selector: 'app-change-customize-bar-templates-group-section-item',
  templateUrl: './change-customize-bar-templates-group-section-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChangeCustomizeBarTemplatesGroupSectionItemComponent implements OnInit, OnChanges {
  @Input() title: string;
  @Input() items: CustomizeBarItem[];
  @Input() display = TemplatesSectionGroupDisplay;
  @Input() context: ViewContext;
  @Input() dropListGroups: AppDropListGroup[] = [];
  @Input() collapsible = true;
  @Output() clearSearch = new EventEmitter<void>();

  collapsed = true;
  displayItems: CustomizeBarItem[] = [];
  displays = TemplatesSectionGroupDisplay;
  showCollapsedMax = 4;

  constructor(private cd: ChangeDetectorRef) {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['items'] || changes['collapsible']) {
    }
    this.updateDisplayItems();
  }

  updateDisplayItems() {
    if (this.collapsible && this.collapsed) {
      this.displayItems = this.items.slice(0, this.showCollapsedMax);
    } else {
      this.displayItems = this.items;
    }
    this.cd.markForCheck();
  }

  setCollapsed(value: boolean) {
    this.collapsed = value;
    this.cd.markForCheck();
    this.updateDisplayItems();
  }

  isDroppable(item: AppDrag<CustomizeBarItem>): boolean {
    return false;
  }
}

import { isSet } from '@shared';

import { Layer } from '../data/layer';
import { isContainerLayer } from '../data/layer-types';
import { TextLayer } from '../data/layers/text';
import { View } from '../data/view';

export function traverseLayers(
  layers: Layer[],
  process: (item: Layer, parent?: Layer | View) => boolean | any,
  parent?: Layer | View
): boolean {
  for (const layer of layers) {
    const result = process(layer);
    if (result === false) {
      return false;
    }

    if (isContainerLayer(layer)) {
      const childrenResult = traverseLayers(layer.layers, process, layer);
      if (!childrenResult) {
        return false;
      }
    }
  }

  return true;
}

export function findLayer<T extends Layer>(layers: Layer[], predicate: (item: Layer) => boolean): T {
  let result: T;

  traverseLayers(layers, item => {
    if (predicate(item)) {
      result = item as T;
      return false;
    }
  });

  return result;
}

export function includesAnyLayers<T extends Layer>(layers: Layer[], includesLayers: Layer[]): boolean {
  if (!includesLayers.length) {
    return false;
  }

  return !!findLayer(layers, layer => {
    return !!includesLayers.find(item => item.isSame(layer));
  });
}

export function getAllLayers<T extends Layer>(
  layers: Layer[],
  options: { filter?: (item: Layer) => boolean; absoluteLayout?: boolean } = {}
): T[] {
  const acc: T[] = [];

  traverseLayers(layers, (item, parent) => {
    if (isSet(options.absoluteLayout) && parent instanceof Layer && isContainerLayer(parent)) {
      if (options.absoluteLayout && parent.flexLayout) {
        return false;
      } else if (!options.absoluteLayout && !parent.flexLayout) {
        return false;
      }
    }

    if (!options.filter || options.filter(item)) {
      acc.push(item as T);
    }
  });

  return acc;
}

export function getAllLayersById(layers: Layer[]): { [k: string]: Layer } {
  return getAllLayers(layers).reduce((acc, item) => {
    acc[item.id] = item;
    return acc;
  }, {});
}

export function getAllFontFamilies(layers: Layer[]): string[] {
  return getAllLayers<TextLayer>(layers, { filter: layer => layer instanceof TextLayer }).reduce((acc, item) => {
    if (item.font && isSet(item.font.family) && !acc.includes(item.font.family)) {
      acc.push(item.font.family);
    }

    return acc;
  }, []);
}

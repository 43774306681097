/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../customize-preview-user-dropdown/customize-preview-user-dropdown.component.ngfactory";
import * as i2 from "../../../projects/stores/project-user.list-store";
import * as i3 from "../../../projects/stores/current-project.store";
import * as i4 from "../../../projects/stores/current-environment.store";
import * as i5 from "../../../projects/services/project-user/project-user.service";
import * as i6 from "../customize-preview-user-dropdown/customize-preview-user-dropdown.component";
import * as i7 from "../../../users/stores/current-user.store";
import * as i8 from "../../../../shared/components/loader-small/loader-small.component.ngfactory";
import * as i9 from "../../../../shared/components/loader-small/loader-small.component";
import * as i10 from "@angular/common";
import * as i11 from "@angular/cdk/overlay";
import * as i12 from "@angular/cdk/bidi";
import * as i13 from "./customize-preview.component";
import * as i14 from "../../../menu/stores/menu-settings/menu-settings.store";
import * as i15 from "../../../customize/services/customize/customize.service";
import * as i16 from "../../../api/services/api/api.service";
import * as i17 from "../../../project-api/services/project-api/project-api.service";
var styles_CustomizePreviewComponent = [];
var RenderType_CustomizePreviewComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CustomizePreviewComponent, data: {} });
export { RenderType_CustomizePreviewComponent as RenderType_CustomizePreviewComponent };
function View_CustomizePreviewComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["class", "customize-preview__dropdown-selector theme_dark overlay-content overlay-content_animated"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "app-customize-preview-user-dropdown", [], null, [[null, "selectUser"], [null, "resetUser"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selectUser" === en)) {
        _co.previewAsUser($event);
        var pd_0 = (_co.setPreviewAsOpened(false) !== false);
        ad = (pd_0 && ad);
    } if (("resetUser" === en)) {
        _co.disablePreviewAsUser();
        var pd_1 = (_co.setPreviewAsOpened(false) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i1.View_CustomizePreviewUserDropdownComponent_0, i1.RenderType_CustomizePreviewUserDropdownComponent)), i0.ɵprd(131584, null, i2.ProjectUserListStore, i2.ProjectUserListStore, [i3.CurrentProjectStore, i4.CurrentEnvironmentStore, i5.ProjectUserService]), i0.ɵdid(3, 245760, null, 0, i6.CustomizePreviewUserDropdownComponent, [i7.CurrentUserStore, i2.ProjectUserListStore, i0.ChangeDetectorRef], null, { selectUser: "selectUser", resetUser: "resetUser" })], function (_ck, _v) { _ck(_v, 3, 0); }, null); }
function View_CustomizePreviewComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-loader-small", [["class", "customize-preview__button-icon"]], null, null, null, i8.View_LoaderSmallComponent_0, i8.RenderType_LoaderSmallComponent)), i0.ɵdid(1, 114688, null, 0, i9.LoaderSmallComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
function View_CustomizePreviewComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 12, "div", [["class", "customize-preview__section customize-preview__section_contrast customize-preview__buttons"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 6, "div", [["class", "customize-preview__main"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 0, "div", [["class", "customize-preview__indicator"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 4, "a", [["class", "customize-preview__link"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.showChanges.emit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(4, null, [" ", " "])), i0.ɵpod(5, { "=1": 0, other: 1 }), i0.ɵppd(6, 2), (_l()(), i0.ɵeld(7, 0, null, null, 0, "span", [["class", "icon-arrow_forward_2"]], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 4, "div", [["class", "customize-preview__right customize-preview__buttons"]], null, null, null, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 3, "a", [["class", "customize-preview__button customize-preview__buttons-item customize-preview__button_purple"], ["href", "javascript:void(0)"]], [[2, "customize-preview__button_disabled", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        _co.disablePreviewAsUser();
        _co.exitPreview.emit();
        var pd_0 = (_co.publishDraft.emit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CustomizePreviewComponent_3)), i0.ɵdid(11, 16384, null, 0, i10.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, [" Publish "]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.publishLoading; _ck(_v, 11, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 4, 0, _ck(_v, 6, 0, i0.ɵnov(_v.parent, 0), _co.draftChangesTotal, _ck(_v, 5, 0, "# change", "# changes"))); _ck(_v, 4, 0, currVal_0); var currVal_1 = (_co.publishLoading || _co.savingChanges); _ck(_v, 9, 0, currVal_1); }); }
export function View_CustomizePreviewComponent_0(_l) { return i0.ɵvid(2, [i0.ɵpid(0, i10.I18nPluralPipe, [i10.NgLocalization]), (_l()(), i0.ɵand(16777216, null, null, 4, function (_v, en, $event) { var ad = true; var _co = _v.component; if (("backdropClick" === en)) {
        var pd_0 = (_co.setPreviewAsOpened(false) !== false);
        ad = (pd_0 && ad);
    } if (("detach" === en)) {
        var pd_1 = (_co.setPreviewAsOpened(false) !== false);
        ad = (pd_1 && ad);
    } return ad; }, View_CustomizePreviewComponent_1)), i0.ɵdid(2, 671744, null, 0, i11.CdkConnectedOverlay, [i11.Overlay, i0.TemplateRef, i0.ViewContainerRef, i11.ɵc, [2, i12.Directionality]], { origin: [0, "origin"], positions: [1, "positions"], backdropClass: [2, "backdropClass"], panelClass: [3, "panelClass"], open: [4, "open"], hasBackdrop: [5, "hasBackdrop"], growAfterOpen: [6, "growAfterOpen"], push: [7, "push"] }, { backdropClick: "backdropClick", detach: "detach" }), i0.ɵpod(3, { originX: 0, overlayX: 1, originY: 2, overlayY: 3, offsetX: 4, offsetY: 5 }), i0.ɵpad(4, 1), i0.ɵpad(5, 2), (_l()(), i0.ɵeld(6, 0, null, null, 18, "div", [["class", "customize-preview"]], [[2, "customize-preview_active", null]], null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 15, "div", [["cdkOverlayOrigin", ""], ["class", "customize-preview__section"]], null, null, null, null, null)), i0.ɵdid(8, 16384, [["preview_as_trigger", 4]], 0, i11.CdkOverlayOrigin, [i0.ElementRef], null, null), (_l()(), i0.ɵeld(9, 0, null, null, 10, "div", [["class", "customize-preview__main customize-preview__info-hover"]], [[2, "customize-preview__info-hover_active", null]], null, null, null, null)), (_l()(), i0.ɵeld(10, 0, null, null, 2, "div", [["class", "customize-preview__profile"]], [[4, "background-image", null]], null, null, null, null)), i0.ɵpid(131072, i10.AsyncPipe, [i0.ChangeDetectorRef]), i0.ɵpid(131072, i10.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(13, 0, null, null, 6, "div", [["class", "customize-preview__info"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.setPreviewAsOpened(true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(14, 0, null, null, 2, "div", [["class", "customize-preview__info-top customize-preview__title"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Previewing as "])), (_l()(), i0.ɵeld(16, 0, null, null, 0, "span", [["class", "icon-arrow_down_2"]], null, null, null, null, null)), (_l()(), i0.ɵeld(17, 0, null, null, 2, "div", [["class", "customize-preview__info-bottom customize-preview__subtitle"]], null, null, null, null, null)), (_l()(), i0.ɵted(18, null, [" ", " "])), i0.ɵpid(131072, i10.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵeld(20, 0, null, null, 2, "div", [["class", "customize-preview__right"]], null, null, null, null, null)), (_l()(), i0.ɵeld(21, 0, null, null, 1, "a", [["class", "customize-preview__button"], ["href", "javascript:void(0)"]], [[2, "customize-preview__button_contrast", null], [2, "customize-preview__button_orange", null], [2, "customize-preview__button_disabled", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        _co.disablePreviewAsUser();
        var pd_0 = (_co.exitPreview.emit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, [" Exit Preview "])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CustomizePreviewComponent_2)), i0.ɵdid(24, 16384, null, 0, i10.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵnov(_v, 8); var currVal_1 = _ck(_v, 4, 0, _ck(_v, 3, 0, "start", "start", "top", "bottom", 0, (0 - 4))); var currVal_2 = "popover2-backdrop"; var currVal_3 = _ck(_v, 5, 0, "overlay", "overlay_position_top-left"); var currVal_4 = _co.previewAsOpened; var currVal_5 = true; var currVal_6 = true; var currVal_7 = true; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); var currVal_15 = _co.draftChanges.length; _ck(_v, 24, 0, currVal_15); }, function (_ck, _v) { var _co = _v.component; var currVal_8 = _co.active; _ck(_v, 6, 0, currVal_8); var currVal_9 = _co.previewAsOpened; _ck(_v, 9, 0, currVal_9); var tmp_10_0 = null; var currVal_10 = ((((tmp_10_0 = i0.ɵunv(_v, 10, 0, i0.ɵnov(_v, 11).transform(_co.currentUserStore.instance$))) == null) ? null : tmp_10_0.photo) ? (("url(" + (((tmp_10_0 = i0.ɵunv(_v, 10, 0, i0.ɵnov(_v, 12).transform(_co.currentUserStore.instance$))) == null) ? null : tmp_10_0.photo)) + ")") : ""); _ck(_v, 10, 0, currVal_10); var tmp_11_0 = null; var currVal_11 = (((tmp_11_0 = i0.ɵunv(_v, 18, 0, i0.ɵnov(_v, 19).transform(_co.currentUserStore.instance$))) == null) ? null : tmp_11_0.strFull); _ck(_v, 18, 0, currVal_11); var currVal_12 = true; var currVal_13 = false; var currVal_14 = _co.publishLoading; _ck(_v, 21, 0, currVal_12, currVal_13, currVal_14); }); }
export function View_CustomizePreviewComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-customize-preview", [], null, null, null, View_CustomizePreviewComponent_0, RenderType_CustomizePreviewComponent)), i0.ɵdid(1, 245760, null, 0, i13.CustomizePreviewComponent, [i7.CurrentUserStore, i3.CurrentProjectStore, i4.CurrentEnvironmentStore, i14.MenuSettingsStore, i15.CustomizeService, i16.ApiService, i17.ProjectApiService, i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CustomizePreviewComponentNgFactory = i0.ɵccf("app-customize-preview", i13.CustomizePreviewComponent, View_CustomizePreviewComponent_Host_0, { active: "active", draftChanges: "draftChanges", draftChangesTotal: "draftChangesTotal", savingChanges: "savingChanges", publishLoading: "publishLoading" }, { exitPreview: "exitPreview", deleteDraft: "deleteDraft", publishDraft: "publishDraft", showChanges: "showChanges" }, []);
export { CustomizePreviewComponentNgFactory as CustomizePreviewComponentNgFactory };

import { Input } from '@modules/fields';
import { generateAlphanumeric } from '@shared';

import { getElementByType } from '../element-items';
import { ElementItem } from './base';

export class TabsLayoutItem {
  public uid: string;
  public titleInput: Input;
  public children: ElementItem[] = [];
  public visibleInput: Input;
  public tooltip: string;

  deserialize(data: Object): TabsLayoutItem {
    this.uid = data['uid'];
    this.tooltip = data['tooltip'];

    if (!this.uid) {
      this.generateUid();
    }

    if (data['title_input']) {
      this.titleInput = new Input().deserialize(data['title_input']);
    } else if (data['title']) {
      // Backward compatibility
      this.titleInput = new Input().deserializeFromStatic('value', data['title']);
    }

    if (data['children']) {
      this.children = data['children']
        .map(item => {
          const element = getElementByType(item['type']);
          if (!element) {
            console.error(`Unsupported element type: ${item['type']}`);
            return;
          }
          return new element().deserialize(item);
        })
        .filter(item => item != undefined);
    }

    if (data['visible_input']) {
      this.visibleInput = new Input().deserialize(data['visible_input']);
    } else {
      this.visibleInput = undefined;
    }

    return this;
  }

  serialize(): Object {
    return {
      uid: this.uid,
      title_input: this.titleInput ? this.titleInput.serialize() : null,
      children: this.children.map(item => item.serialize()),
      visible_input: this.visibleInput ? this.visibleInput.serialize() : null,
      tooltip: this.tooltip
    };
  }

  generateUid() {
    this.uid = generateAlphanumeric(8, { letterFirst: true });
  }
}

import { Directive, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { fromEvent, merge, timer } from 'rxjs';
import { debounce, distinctUntilChanged, map, skipWhile } from 'rxjs/operators';

@Directive({
  selector: '[appLongHover]'
})
export class LongHoverDirective implements OnInit, OnDestroy {
  @Input() longHoverInDelay = 0;
  @Input() longHoverOutDelay = 0;
  @Output() longHover = new EventEmitter<boolean>();
  @Output() longHoverIn = new EventEmitter<void>();
  @Output() longHoverOut = new EventEmitter<void>();

  constructor(private el: ElementRef) {}

  ngOnInit(): void {
    merge(
      fromEvent<MouseEvent>(this.el.nativeElement, 'mouseenter').pipe(map(() => true)),
      fromEvent<MouseEvent>(this.el.nativeElement, 'mouseleave').pipe(map(() => false))
    )
      .pipe(
        debounce(hover => timer(hover ? this.longHoverInDelay : this.longHoverOutDelay)),
        distinctUntilChanged(),
        skipWhile(item => !item),
        untilDestroyed(this)
      )
      .subscribe(hover => {
        this.longHover.emit(hover);

        if (hover) {
          this.longHoverIn.emit();
        } else {
          this.longHoverOut.emit();
        }
      });
  }

  ngOnDestroy() {}
}

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import saveAs from 'file-saver';
import fromPairs from 'lodash/fromPairs';
import * as moment from 'moment';
import { from, of, Subject } from 'rxjs';
import { switchMap, takeWhile } from 'rxjs/operators';
import { slugify } from 'transliteration';
import { applyParamInput, getFieldDescriptionByType } from '@modules/fields';
import { ColumnsModelListStore } from '@modules/list';
import { CurrentProjectStore } from '@modules/projects';
import { isSet } from '@shared';
import { exportFormatBookTypes, exportFormats, ExportFormatType } from '../../data/export-formats';
var ExportService = /** @class */ (function () {
    function ExportService(modelListStore, currentProjectStore) {
        this.modelListStore = modelListStore;
        this.currentProjectStore = currentProjectStore;
        this.cancel = false; // TODO: fix concurrent exports
    }
    ExportService.prototype.exportModels = function (title, dataSource, queryOptions, formatType, context, contextElement, localContext) {
        var _this = this;
        var progressObs = new Subject();
        var staticData = [];
        try {
            staticData = applyParamInput(dataSource.input, {
                context: context,
                contextElement: contextElement,
                localContext: localContext,
                defaultValue: []
            });
        }
        catch (e) { }
        this.modelListStore.dataSource = dataSource;
        this.modelListStore.queryOptions = queryOptions;
        this.modelListStore.staticData = staticData;
        this.modelListStore.context = context;
        this.modelListStore.contextElement = contextElement;
        this.modelListStore.localContext = localContext;
        this.modelListStore.perPage = 100;
        this.modelListStore.reset();
        if (queryOptions.paging && isSet(queryOptions.paging.limit)) {
            this.modelListStore.perPage = queryOptions.paging.limit;
        }
        else {
            this.modelListStore.perPage = 100;
        }
        this.cancel = false;
        progressObs.next(0);
        this.processPage(progressObs)
            .pipe(switchMap(function (result) {
            var columns = _this.modelListStore.dataSource.columns.filter(function (item) { return item.visible; });
            var data = result.map(function (item) {
                return columns.map(function (column) {
                    var value = item.model.getAttribute(column.name);
                    return _this.serializeItemColumn(column, value);
                });
            });
            return _this.downloadData(title, columns, data, formatType);
        }))
            .subscribe(function () { return progressObs.next(1); }, function (e) { return progressObs.error(e); });
        return progressObs;
    };
    ExportService.prototype.processPage = function (progressObs, items) {
        var _this = this;
        if (items === void 0) { items = []; }
        return this.modelListStore.getNext().pipe(takeWhile(function () { return !_this.cancel; }), switchMap(function (results) {
            var progress = isSet(_this.modelListStore.currentPage) && isSet(_this.modelListStore.totalPages)
                ? _this.modelListStore.currentPage / (_this.modelListStore.totalPages + 1)
                : undefined;
            progressObs.next(progress);
            var result = items.concat(results);
            if (!_this.modelListStore.hasMore) {
                return of(result);
            }
            return _this.processPage(progressObs, result);
        }));
    };
    ExportService.prototype.serializeItemColumn = function (column, value) {
        var field = column ? column.field : undefined;
        var fieldDescription = getFieldDescriptionByType(field);
        if (fieldDescription.valueToStr) {
            return fieldDescription.valueToStr(value, { field: column, noTruncate: true });
        }
        else {
            return value;
        }
    };
    ExportService.prototype.downloadData = function (title, columns, data, formatType) {
        var exportFormat = exportFormats.find(function (item) { return item.type == formatType; });
        if (formatType == ExportFormatType.JSON) {
            var jsonData = data.map(function (row) {
                return fromPairs(columns.map(function (column, i) {
                    return [isSet(column.verboseName) ? column.verboseName : column.name, row[i]];
                }));
            });
            return this.downloadDataAsJSON(title, jsonData, exportFormat);
        }
        else {
            return from(this.downloadDataAsWorkbook(title, columns, data, exportFormat));
        }
    };
    ExportService.prototype.downloadDataAsJSON = function (title, data, format) {
        if (!data.length) {
            return of(false);
        }
        var blob = new Blob([JSON.stringify(data)], { type: 'application/json; charset=utf-8' });
        var filename = [
            this.currentProjectStore.instance.uniqueName,
            slugify(title, { trim: true, separator: '_' }).replace(/_+/g, '_'),
            moment().format('YYYY-MM-DD'),
            moment().format('HH-mm-ss')
        ].join('_');
        saveAs(blob, filename + "." + format.extension);
        return of(true);
    };
    ExportService.prototype.downloadDataAsWorkbook = function (title, columns, data, format) {
        return __awaiter(this, void 0, void 0, function () {
            var XLSX, xlsx, book, header, sheetTitle, sheet, bookType, out, blob, filename;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!data.length) {
                            return [2 /*return*/, false];
                        }
                        XLSX = import(/* webpackChunkName: "xlsx" */ 'xlsx');
                        return [4 /*yield*/, XLSX];
                    case 1:
                        xlsx = _a.sent();
                        book = xlsx.utils.book_new();
                        header = columns
                            ? columns.map(function (item) {
                                return isSet(item.verboseName) ? item.verboseName : item.name;
                            })
                            : undefined;
                        sheetTitle = slugify(title, { trim: true, separator: '-' }).replace(/-+/g, '-').substring(0, 31);
                        sheet = xlsx.utils.json_to_sheet([], { header: header });
                        sheet = xlsx.utils.sheet_add_aoa(sheet, data, { origin: 1 });
                        xlsx.utils.book_append_sheet(book, sheet, sheetTitle);
                        bookType = exportFormatBookTypes[format.type];
                        out = xlsx.write(book, { bookType: bookType, bookSST: false, type: 'array' });
                        blob = new Blob([out], { type: 'application/octet-stream' });
                        filename = [
                            this.currentProjectStore.instance.uniqueName,
                            slugify(title, { trim: true, separator: '_' }).replace(/_+/g, '_'),
                            moment().format('YYYY-MM-DD'),
                            moment().format('HH-mm-ss')
                        ].join('_');
                        saveAs(blob, filename + "." + format.extension);
                        return [2 /*return*/, true];
                }
            });
        });
    };
    return ExportService;
}());
export { ExportService };

import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit } from '@angular/core';

import { CustomViewSettings, PopupSettings, ViewContext } from '@modules/customize';
import { TypedChanges } from '@shared';

@Component({
  selector: 'app-page-template-preview-start-page',
  templateUrl: './page-template-preview-start-page.component.html',
  providers: [ViewContext],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PageTemplatePreviewStartPageComponent implements OnInit, OnChanges {
  @Input() page: CustomViewSettings;
  @Input() fade = false;
  @Input() startPageFocus = false;
  @Input() popupFocus = true;

  popup: PopupSettings;

  constructor(public context: ViewContext) {}

  ngOnInit() {}

  ngOnChanges(changes: TypedChanges<PageTemplatePreviewStartPageComponent>): void {
    if (changes.page) {
      this.popup = this.page ? this.page.popups[0] : undefined;
    }
  }
}

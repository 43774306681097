var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { ChangeDetectorRef, OnChanges, OnInit } from '@angular/core';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import last from 'lodash/last';
import { combineLatest, of } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { ITEM_OUTPUT, rawListViewSettingsColumnsToViewContextOutputs, SUBMIT_RESULT_OUTPUT, ViewContextElement } from '@modules/customize';
import { DataSourceType } from '@modules/data-sources';
import { applyParamInput$, DisplayFieldType, FieldType } from '@modules/fields';
import { ModelDescriptionStore } from '@modules/model-queries';
import { QueryService, QueryType } from '@modules/queries';
import { cleanWorkflowStepName, WorkflowStepType } from '@modules/workflow';
import { isSet } from '@shared';
import { registerWorkflowStepComponent } from '../../../../data/workflow-step-components';
import { WorkflowEditContext } from '../../../../services/workflow-edit-context/workflow-edit.context';
import { WorkflowStepComponent } from '../base-workflow-step/base-workflow-step.component';
function getStepStateContextInfo(state) {
    return {
        name: state.name,
        type: state.type,
        icon: state.icon
    };
}
function getStepStateContextOutputs(state) {
    return {
        iterate: state.iterate,
        columns: state.dataSource ? state.dataSource.columns : undefined,
        primaryKeyField: state.modelDescription ? state.modelDescription.primaryKeyField : undefined
    };
}
var IteratorWorkflowStepComponent = /** @class */ (function (_super) {
    __extends(IteratorWorkflowStepComponent, _super);
    function IteratorWorkflowStepComponent(workflowStepsContextElement, queryService, modelDescriptionStore, contextElement, workflowEditContext, cd) {
        var _this = _super.call(this, contextElement, workflowEditContext, cd) || this;
        _this.workflowStepsContextElement = workflowStepsContextElement;
        _this.queryService = queryService;
        _this.modelDescriptionStore = modelDescriptionStore;
        _this.trackStep = (function () {
            return function (i, item) {
                return item ? item.uid : i;
            };
        })();
        return _this;
    }
    IteratorWorkflowStepComponent.prototype.ngOnInit = function () {
        this.initContext();
        this.updateIcon();
        this.trackExecuteStatus({ successOnStart: true });
        this.stepOnChange(this.step);
        this.trackChanges();
        this.workflowEditContext.updateCreatedElement(this.step, { contextElement: this.contextElement });
    };
    IteratorWorkflowStepComponent.prototype.ngOnChanges = function (changes) {
        if (changes.step) {
            if (!changes.step.firstChange) {
                this.stepOnChange(this.step);
            }
        }
    };
    IteratorWorkflowStepComponent.prototype.getStepState = function (step) {
        var iterate$ = step && step.dataSource && step.dataSource.type == DataSourceType.Input
            ? applyParamInput$(step.dataSource.input, {
                context: this.context,
                defaultValue: [],
                raiseErrors: false
            }).pipe(distinctUntilChanged(function (lhs, rhs) { return isEqual(lhs, rhs); }))
            : of({});
        var modelDescription$ = step ? this.getQueryModelDescription(step.dataSource) : of(undefined);
        return combineLatest(iterate$, modelDescription$).pipe(map(function (_a) {
            var iterate = _a[0], modelDescription = _a[1];
            return {
                step: step,
                name: step ? step.name : undefined,
                icon: step ? step.getIcon() : undefined,
                type: step ? step.type : undefined,
                iterate: iterate,
                dataSource: step ? step.dataSource : undefined,
                modelDescription: modelDescription
            };
        }));
    };
    IteratorWorkflowStepComponent.prototype.getQueryModelDescription = function (dataSource) {
        if (!dataSource ||
            !dataSource.query ||
            dataSource.query.queryType != QueryType.Simple ||
            !dataSource.query.simpleQuery) {
            return of(undefined);
        }
        var modelId = [dataSource.queryResource, dataSource.query.simpleQuery.model].join('.');
        return this.modelDescriptionStore.getDetailFirst(modelId);
    };
    IteratorWorkflowStepComponent.prototype.onStateUpdated = function (state) {
        if (!isEqual(getStepStateContextInfo(state), getStepStateContextInfo(this.state))) {
            this.updateContextInfo(state);
        }
        if (!isEqual(getStepStateContextOutputs(state), getStepStateContextOutputs(this.state))) {
            this.updateContextOutputs(state);
        }
    };
    IteratorWorkflowStepComponent.prototype.initContext = function () {
        this.contextElement.initGlobal({
            uniqueName: this.step.uid,
            name: this.step.name || this.step.type,
            getFieldValue: function (field, outputs) {
                return outputs[field];
            }
        }, this.workflowStepsContextElement);
    };
    IteratorWorkflowStepComponent.prototype.updateIcon = function () {
        this.icon = this.step.getIcon();
    };
    IteratorWorkflowStepComponent.prototype.updateContextInfo = function (state) {
        this.contextElement.initInfo({
            name: state.name || state.type,
            icon: state.icon || 'power'
        }, true);
    };
    IteratorWorkflowStepComponent.prototype.updateContextOutputs = function (state) {
        if (state.dataSource) {
            this.contextElement.setOutputs([
                {
                    uniqueName: ITEM_OUTPUT,
                    name: 'Current Item',
                    icon: 'duplicate_2',
                    external: true,
                    children: rawListViewSettingsColumnsToViewContextOutputs(state.dataSource.columns.filter(function (item) { return item.type != DisplayFieldType.Computed; }), state.modelDescription)
                }
            ]);
        }
        else {
            this.contextElement.setOutputs([
                {
                    uniqueName: ITEM_OUTPUT,
                    name: 'Current Item',
                    icon: 'duplicate_2',
                    fieldType: FieldType.JSON,
                    external: true
                }
            ]);
        }
        this.contextElement.setOutputValue(ITEM_OUTPUT, state.iterate);
    };
    IteratorWorkflowStepComponent.prototype.dragDrop = function (event) {
        if (event.previousContainer === event.container) {
            moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        }
        else {
            transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
        }
        this.workflowEditContext.markChanged();
    };
    IteratorWorkflowStepComponent.prototype.addStep = function (step, options) {
        if (options === void 0) { options = {}; }
        this.workflowEditContext.registerCreatedElement(step);
        if (isSet(options.addBeforeIndex)) {
            this.step.steps.splice(options.addBeforeIndex, 0, step);
        }
        else {
            this.step.steps.push(step);
        }
        this.cd.markForCheck();
        this.workflowEditContext.markChanged();
    };
    IteratorWorkflowStepComponent.prototype.duplicateStep = function (index) {
        var step = cloneDeep(this.step.steps[index]);
        var defaultName = "Copy of " + step.name;
        step.generateUid();
        step.name = cleanWorkflowStepName(defaultName, step, this.workflowEditContext.state.workflow.steps);
        this.workflowEditContext.registerCreatedElement(step);
        this.step.steps.splice(index + 1, 0, step);
        this.cd.markForCheck();
        this.workflowEditContext.markChanged();
    };
    IteratorWorkflowStepComponent.prototype.deleteStep = function (index) {
        this.step.steps.splice(index, 1);
        this.cd.markForCheck();
        this.workflowEditContext.markChanged();
    };
    IteratorWorkflowStepComponent.prototype.onRunUpdated = function (event) {
        if (this.contextElement) {
            var item = event.params && event.params['iterate'] ? event.params['iterate'][0] : undefined;
            var result = event.success ? event.result : event.error;
            this.contextElement.setOutputValue(ITEM_OUTPUT, item);
            this.contextElement.setOutputValue(SUBMIT_RESULT_OUTPUT, result);
        }
    };
    IteratorWorkflowStepComponent.prototype.stepsHasNextConnection = function () {
        return !this.step.steps.length || last(this.step.steps).type != WorkflowStepType.Exit;
    };
    return IteratorWorkflowStepComponent;
}(WorkflowStepComponent));
export { IteratorWorkflowStepComponent };
registerWorkflowStepComponent(WorkflowStepType.Iterator, IteratorWorkflowStepComponent);

import { ReplaySubject } from 'rxjs';
import { first } from 'rxjs/operators';
import { RoutingService } from '@modules/routing';
import { AuthService } from '../services/auth/auth.service';
import * as i0 from "@angular/core";
import * as i1 from "../services/auth/auth.service";
import * as i2 from "../../routing/services/routing/routing.service";
var IsUserAuthenticatedGuard = /** @class */ (function () {
    function IsUserAuthenticatedGuard(authService, routing) {
        this.authService = authService;
        this.routing = routing;
    }
    IsUserAuthenticatedGuard.prototype.canActivate = function (route, state) {
        var _this = this;
        var canActivate = new ReplaySubject();
        this.authService
            .isUserAuthorized()
            .pipe(first())
            .subscribe(function (authorized) { return canActivate.next(authorized); }, function () { return canActivate.next(false); });
        canActivate.subscribe(function (allow) {
            if (!allow) {
                _this.authService.logout();
                var queryParams = {};
                var url = _this.routing.getRouteUrl(route);
                var redirect = encodeURIComponent(JSON.stringify({
                    url: url,
                    params: route.queryParams
                }));
                var referrer = route.queryParams['referrer'];
                if (redirect) {
                    queryParams['redirect'] = redirect;
                }
                if (referrer) {
                    queryParams['referrer'] = referrer;
                }
                var ignoreSelfSignOn = route.data['ignoreSelfSignOn'];
                if (route.data['registerWithoutAuthenticated']) {
                    _this.routing.navigate(ignoreSelfSignOn ? ['/user/register'] : ['/register'], { queryParams: queryParams });
                }
                else {
                    _this.routing.navigate(ignoreSelfSignOn ? ['/user/login'] : ['/login'], { queryParams: queryParams });
                }
            }
        });
        return canActivate;
    };
    IsUserAuthenticatedGuard.ngInjectableDef = i0.defineInjectable({ factory: function IsUserAuthenticatedGuard_Factory() { return new IsUserAuthenticatedGuard(i0.inject(i1.AuthService), i0.inject(i2.RoutingService)); }, token: IsUserAuthenticatedGuard, providedIn: "root" });
    return IsUserAuthenticatedGuard;
}());
export { IsUserAuthenticatedGuard };

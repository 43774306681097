import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { debounceTime } from 'rxjs/internal/operators';

import { FieldComponent } from '../field/field.component';

@Component({
  selector: 'app-custom-char-field',
  templateUrl: './custom-char-field.component.html'
})
export class CustomCharFieldComponent extends FieldComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('input_element') inputElement: ElementRef;

  standaloneControl = new FormControl();

  constructor() {
    super();
  }

  ngOnInit() {
    this.standaloneControl.disable();
  }

  ngAfterViewInit(): void {
    if (!this.form) {
      return;
    }

    // fix ios autofill
    this.form.valueChanges.pipe(debounceTime(500), untilDestroyed(this)).subscribe(changes => {
      const passVal = this.inputElement.nativeElement.value;
      if (passVal && passVal !== changes[this.field.name]) {
        this.form.get(this.field.name).setValue(passVal);
      }
    });
  }

  get control() {
    return this.form && this.form.controls[this.field.name]
      ? (this.form.controls[this.field.name] as FormControl)
      : this.standaloneControl;
  }

  reset() {
    this.control.reset(null, { emitEvent: false });
  }

  ngOnDestroy(): void {}
}

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../modules/routing/directives/app-link/app-link.directive";
import * as i2 from "../../../modules/routing/services/routing/routing.service";
import * as i3 from "@angular/router";
import * as i4 from "@angular/common";
import * as i5 from "./background-tab.component";
var styles_BackgroundTabComponent = [];
var RenderType_BackgroundTabComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_BackgroundTabComponent, data: {} });
export { RenderType_BackgroundTabComponent as RenderType_BackgroundTabComponent };
function View_BackgroundTabComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "a", [["class", "background-tab"]], [[2, "background-tab_active", null], [1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.tabClick.emit($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 737280, null, 0, i1.AppLinkDirective, [i2.RoutingService, i3.Router, i3.ActivatedRoute, i4.LocationStrategy], { appLink: [0, "appLink"] }, null), (_l()(), i0.ɵted(2, null, [" ", "\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.link; _ck(_v, 1, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.active; var currVal_1 = i0.ɵnov(_v, 1).target; var currVal_2 = i0.ɵnov(_v, 1).href; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); var currVal_4 = _co.label; _ck(_v, 2, 0, currVal_4); }); }
function View_BackgroundTabComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "a", [["class", "background-tab"], ["href", "javascript:void(0)"]], [[2, "background-tab_active", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.tabClick.emit($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(1, null, [" ", "\n"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.active; _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.label; _ck(_v, 1, 0, currVal_1); }); }
export function View_BackgroundTabComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_BackgroundTabComponent_1)), i0.ɵdid(1, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_BackgroundTabComponent_2)), i0.ɵdid(3, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.link; _ck(_v, 1, 0, currVal_0); var currVal_1 = !_co.link; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_BackgroundTabComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-background-tab", [], null, null, null, View_BackgroundTabComponent_0, RenderType_BackgroundTabComponent)), i0.ɵdid(1, 114688, null, 0, i5.BackgroundTabComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BackgroundTabComponentNgFactory = i0.ɵccf("app-background-tab", i5.BackgroundTabComponent, View_BackgroundTabComponent_Host_0, { name: "name", link: "link", label: "label", active: "active" }, { tabClick: "tabClick" }, []);
export { BackgroundTabComponentNgFactory as BackgroundTabComponentNgFactory };

import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import cloneDeep from 'lodash/cloneDeep';
import keys from 'lodash/keys';
import { combineLatest, Observable, of, throwError } from 'rxjs';
import { catchError, delayWhen, map, share, switchMap } from 'rxjs/operators';

import { AggregateFunc, DataGroup, DatasetGroupLookup } from '@modules/charts';
import { RawListViewSettingsColumn } from '@modules/customize';
import { DataSyncService } from '@modules/data-sync';
import { ParameterField } from '@modules/fields';
import { applyFiltersComputedLookups, applyParamsComputedLookups, applySegments } from '@modules/filter-utils';
import { Model, ModelAction, ModelDescription, PAGE_PARAM } from '@modules/models';
import {
  Environment,
  isResourceTypeItem3rdParty,
  Project,
  Resource,
  ResourceDeploy,
  ResourceName,
  ResourceType
} from '@modules/projects';
import { ListModelDescriptionQuery, ModelDescriptionQuery, QueryType } from '@modules/queries';
import { QueryTokensService } from '@modules/queries-tokens';
import {
  FirebaseResourceController,
  GetQueryOptions,
  getQueryOptionsToParams,
  JetAppResourceController,
  JetBridgeResourceController,
  ModelResponse,
  paramsToGetQueryOptions,
  ResourceController,
  ResourceControllerService,
  RestApiResourceControllerService,
  SqlQueryOptions
} from '@modules/resources';
import { AutomationService } from '@modules/workflow';
import { AppError, isSet } from '@shared';

// TODO: Refactor import
import { UserActivityService } from '../../../activities/services/user-activity/user-activity.service';

import { ModelDescriptionStore } from '../../stores/model-description.store';

@Injectable()
export class ModelService {
  constructor(
    private http: HttpClient,
    private injector: Injector,
    private modelDescriptionStore: ModelDescriptionStore,
    private queryTokensService: QueryTokensService,
    private resourceControllerService: ResourceControllerService,
    private dataSyncService: DataSyncService,
    private automationService: AutomationService,
    private userActivityService: UserActivityService
  ) {}

  getForModel(
    resources: Resource[],
    modelId: string,
    query?: string,
    useSync = true
  ): Observable<[Resource, ModelDescription, ResourceController]> {
    return this.modelDescriptionStore.getDetailFirst(modelId).pipe(
      map<ModelDescription, [Resource, ModelDescription, ResourceController]>(modelDescription => {
        if (!modelDescription) {
          return;
        }

        const resource =
          resources && modelDescription
            ? resources.find(item => item.uniqueName == modelDescription.resource)
            : undefined;

        if (!resource) {
          return;
        }

        if (useSync && (resource.isSynced(modelDescription.model) || modelDescription.isSynced())) {
          const controller = resource ? this.resourceControllerService.get(ResourceType.JetBridge) : undefined;

          if (!controller) {
            return;
          }

          if (!modelDescription.virtual || modelDescription.isSynced()) {
            const newModel = cloneDeep(modelDescription) as ModelDescription;

            newModel.getQuery = new ListModelDescriptionQuery();
            newModel.getQuery.queryType = QueryType.Simple;
            newModel.getQuery.simpleQuery = new newModel.getQuery.simpleQueryClass();
            newModel.getQuery.simpleQuery.model = modelDescription.model;

            newModel.getDetailQuery = undefined;
            newModel.syncResource = true;

            modelDescription = newModel;
          }

          return [resource, modelDescription, controller];
        } else {
          const resourceController = resource ? this.resourceControllerService.get(resource.type) : undefined;
          let controller: ResourceController;

          if (query && modelDescription[query] && modelDescription[query].queryType == QueryType.Http) {
            controller = this.resourceControllerService.get(ResourceType.RestAPI);
          } else if (query && modelDescription[query] && modelDescription[query].queryType == QueryType.SQL) {
            controller = this.resourceControllerService.get(ResourceType.JetBridge);
          } else {
            controller = resourceController;
          }

          if (!controller) {
            return;
          }

          return [resource, modelDescription, controller];
        }
      })
    );
  }

  public get(
    project: Project,
    environment: Environment,
    modelId: string,
    params?: {},
    body?: {}
  ): Observable<ModelResponse.GetResponse> {
    const queryOptions = params ? paramsToGetQueryOptions(params) : undefined;
    return this.getAdv(project, environment, modelId, queryOptions, body);
  }

  public isGetAdvSupported(resource: Resource, modelDescription?: ModelDescription): boolean {
    if (!resource) {
      return false;
    }

    const model = modelDescription ? modelDescription.model : undefined;
    const resourceType =
      resource.isSynced(model) || (modelDescription && modelDescription.isSynced())
        ? ResourceType.JetBridge
        : resource.type;
    const controller = this.resourceControllerService.get<JetBridgeResourceController>(resourceType);
    return controller.isGetAdvSupported && controller.isGetAdvSupported(resource);
  }

  public getAdv(
    project: Project,
    environment: Environment,
    modelId: string,
    options: GetQueryOptions = {},
    body?: {}
  ): Observable<ModelResponse.GetResponse> {
    return this.getForModel(project.getEnvironmentResources(environment.uniqueName), modelId, 'getQuery').pipe(
      switchMap(modelParams => {
        const [resource, modelDescription, controller] = modelParams;
        if ((!options.sort || !options.sort.length) && isSet(modelDescription.defaultOrderBy)) {
          const [field, asc] = modelDescription.defaultOrderBy.startsWith('-')
            ? [modelDescription.defaultOrderBy.slice(1), false]
            : [modelDescription.defaultOrderBy, true];
          options.sort = [{ field: field, desc: !asc }];
        }

        options.modelDescriptions = this.modelDescriptionStore.instance;

        const advSupported = this.isGetAdvSupported(resource, modelDescription);

        if (controller.modelGetAdv && advSupported) {
          return controller.modelGetAdv(resource, modelDescription, options);
        } else {
          const params = getQueryOptionsToParams(options);
          return controller.modelGet(resource, modelDescription, params, body);
        }
      }),
      share()
    );
  }

  public getAll(
    project: Project,
    environment: Environment,
    modelId: string,
    params?: {},
    body?: {}
  ): Observable<Model[]> {
    const iterate = (page: number): Observable<Model[]> => {
      const iterParams = {
        ...params,
        [PAGE_PARAM]: page
      };

      return this.get(project, environment, modelId, iterParams, body).pipe(
        switchMap(result => {
          if (result.hasMore) {
            return iterate(page + 1).pipe(
              map(nextResult => {
                return [...result.results, ...nextResult];
              })
            );
          } else {
            return of(result.results);
          }
        })
      );
    };

    return iterate(1);
  }

  public getQuery(
    project: Project,
    environment: Environment,
    resource: Resource,
    query: ListModelDescriptionQuery,
    parameters: ParameterField[] = [],
    params?: {},
    columns: RawListViewSettingsColumn[] = []
  ): Observable<ModelResponse.GetResponse> {
    const queryOptions = params ? paramsToGetQueryOptions(params) : undefined;
    return this.getQueryAdv(project, environment, resource, query, parameters, queryOptions, columns);
  }

  public getQueryAdv(
    project: Project,
    environment: Environment,
    resource: Resource,
    query: ListModelDescriptionQuery,
    parameters: ParameterField[] = [],
    options: GetQueryOptions = {},
    columns: RawListViewSettingsColumn[] = []
  ): Observable<ModelResponse.GetResponse> {
    if (!resource) {
      return throwError(new AppError('Resource not found'));
    }

    if (!query) {
      return throwError(new AppError('Query not specified'));
    }

    let params = getQueryOptionsToParams(options);

    if (
      isResourceTypeItem3rdParty(resource.typeItem) &&
      !project.features.isThirdPartyResourcesEnabled() &&
      !resource.demo
    ) {
      return throwError(new AppError('Business Apps feature is not enabled in your Plan'));
    }

    if (query.queryType == QueryType.Simple && query.simpleQuery) {
      const modelId = [resource.uniqueName, query.simpleQuery.model].join('.');
      return this.modelDescriptionStore.getDetailFirst(modelId).pipe(
        switchMap(modelDescription => {
          if (!modelDescription) {
            throw new AppError('Collection not found');
          }

          options = {
            ...options,
            filters: applyFiltersComputedLookups(options.filters || [])
          };

          return this.getAdv(project, environment, modelDescription.modelId, options);
        })
      );
    } else if (query.queryType == QueryType.Http && query.httpQuery) {
      if (!project.features.isCustomResourcesEnabled() && !resource.demo) {
        return throwError(new AppError('Custom Queries feature is not enabled in your Plan'));
      }

      // TODO: Rename RestApiResourceControllerService to RestApiResourceController
      const controller = this.resourceControllerService.get<RestApiResourceControllerService>(ResourceType.RestAPI);
      return controller.getHttp(resource, query, parameters, params, {}, columns);
    } else if (query.queryType == QueryType.SQL && query.sqlQuery) {
      if (!project.features.isCustomResourcesEnabled() && !resource.demo) {
        return throwError(new AppError('Custom Queries feature is not enabled in your Plan'));
      }

      params = applyParamsComputedLookups(params);

      const controller = this.resourceControllerService.getForResource<JetBridgeResourceController>(resource, true);
      return controller.getSql(resource, query, parameters, params, undefined, columns);
    } else if (query.queryType == QueryType.Object && query.objectQuery) {
      if (!project.features.isCustomResourcesEnabled() && !resource.demo) {
        return throwError(new AppError('Custom Queries feature is not enabled in your Plan'));
      }

      const controller = this.resourceControllerService.get<FirebaseResourceController>(resource.type);
      return controller.getQueryObject(resource, query, parameters, params, undefined, columns);
    } else {
      return of(undefined);
    }
  }

  public getDetail(
    project: Project,
    environment: Environment,
    modelId: string,
    idField: string,
    id: any,
    params?: {}
  ): Observable<Model> {
    const queryOptions = params ? paramsToGetQueryOptions(params) : undefined;
    return this.getDetailAdv(project, environment, modelId, idField, id, queryOptions);
  }

  public getDetailAdv(
    project: Project,
    environment: Environment,
    modelId: string,
    idField: string,
    id: any,
    options: GetQueryOptions = {}
  ): Observable<Model> {
    return this.getForModel(project.getEnvironmentResources(environment.uniqueName), modelId, 'getDetailQuery').pipe(
      switchMap(modelParams => {
        const [resource, modelDescription, controller] = modelParams;

        options.modelDescriptions = this.modelDescriptionStore.instance;

        const advSupported = this.isGetAdvSupported(resource, modelDescription);

        if (controller.modelGetDetailAdv && advSupported) {
          return controller.modelGetDetailAdv(resource, modelDescription, idField, id, options);
        } else {
          const params = getQueryOptionsToParams(options);
          return controller.modelGetDetail(resource, modelDescription, idField, id, params);
        }
      }),
      map(result => {
        if (!result) {
          throw new AppError('Not found');
        }

        return result;
      }),
      share()
    );
  }

  public getDetailQuery(
    project: Project,
    environment: Environment,
    resource: Resource,
    query: ModelDescriptionQuery,
    parameters: ParameterField[] = [],
    params?: {},
    columns: RawListViewSettingsColumn[] = []
  ): Observable<Model> {
    const queryOptions = params ? paramsToGetQueryOptions(params) : undefined;
    return this.getDetailQueryAdv(project, environment, resource, query, parameters, queryOptions, columns);
  }

  public getDetailQueryAdv(
    project: Project,
    environment: Environment,
    resource: Resource,
    query: ModelDescriptionQuery,
    parameters: ParameterField[] = [],
    options: GetQueryOptions = {},
    columns: RawListViewSettingsColumn[] = []
  ): Observable<Model> {
    if (!resource) {
      return throwError(new AppError('Resource not found'));
    }

    if (!query) {
      return throwError(new AppError('Query not specified'));
    }

    let params = getQueryOptionsToParams(options);

    if (
      isResourceTypeItem3rdParty(resource.typeItem) &&
      !project.features.isThirdPartyResourcesEnabled() &&
      !resource.demo
    ) {
      return throwError(new AppError('Business Apps feature is not enabled in your Plan'));
    }

    if (query.queryType == QueryType.Simple && query.simpleQuery) {
      const modelId = [resource.uniqueName, query.simpleQuery.model].join('.');
      return this.modelDescriptionStore.getDetailFirst(modelId).pipe(
        switchMap(modelDescription => {
          if (!modelDescription) {
            throw new AppError('Collection not found');
          }

          options = {
            ...options,
            filters: applyFiltersComputedLookups(options.filters || [])
          };

          return this.getDetailAdv(
            project,
            environment,
            modelDescription.modelId,
            modelDescription.primaryKeyField,
            params[modelDescription.primaryKeyField],
            options
          );
        })
      );
    } else if (query.queryType == QueryType.Http && query.httpQuery) {
      if (!project.features.isCustomResourcesEnabled() && !resource.demo) {
        return throwError(new AppError('Custom Queries feature is not enabled in your Plan'));
      }

      const controller = this.resourceControllerService.get<RestApiResourceControllerService>(ResourceType.RestAPI);
      return controller.getDetail(resource, query, parameters, params, columns).pipe(
        map(result => {
          if (!result) {
            throw new AppError('Not found');
          }

          return result;
        })
      );
    } else if (query.queryType == QueryType.SQL && query.sqlQuery) {
      if (!project.features.isCustomResourcesEnabled() && !resource.demo) {
        return throwError(new AppError('Custom Queries feature is not enabled in your Plan'));
      }

      params = applyParamsComputedLookups(params);

      const controller = this.resourceControllerService.getForResource<JetBridgeResourceController>(resource, true);
      return controller.getDetailSql(resource, query, parameters, params, columns).pipe(
        map(result => {
          if (!result) {
            throw new AppError('Not found');
          }

          return result;
        })
      );
    } else if (query.queryType == QueryType.Object && query.objectQuery) {
      if (!project.features.isCustomResourcesEnabled() && !resource.demo) {
        return throwError(new AppError('Custom Queries feature is not enabled in your Plan'));
      }

      const controller = this.resourceControllerService.get<FirebaseResourceController>(resource.type);
      return controller.getDetailQueryObject(resource, query, params, columns).pipe(
        map(result => {
          if (!result) {
            throw new AppError('Not found');
          }

          return result;
        })
      );
    } else {
      return of(undefined);
    }
  }

  public onModelAction(
    project: Project,
    environment: Environment,
    resource: Resource,
    controller: ResourceController,
    modelDescription: ModelDescription,
    instance: Model,
    action: ModelAction,
    options: {
      viewSettings?: string;
    } = {}
  ): Observable<boolean> {
    return combineLatest(
      this.syncOnUpdate(project, environment, resource, modelDescription, instance).pipe(catchError(() => of(false))),
      this.triggerAutomationOnUpdate(
        project,
        environment,
        resource,
        controller,
        modelDescription,
        instance,
        action
      ).pipe(catchError(() => of(false))),
      this.logModelActionOnUpdate(
        project,
        environment,
        resource,
        controller,
        modelDescription,
        instance,
        action,
        options
      ).pipe(catchError(() => of(false)))
    ).pipe(map(() => true));
  }

  public syncOnUpdate(
    project: Project,
    environment: Environment,
    resource: Resource,
    modelDescription: ModelDescription,
    instance: Model
  ): Observable<boolean> {
    if (!resource.isSynced(modelDescription.model) && !modelDescription.isSynced()) {
      return of(true);
    }

    let pk: string;
    let params: Object;

    if (resource.typeItem.name == ResourceName.Firebase) {
      // TODO: Move __jet_item_pk__, __parent__ out of generators
      pk = instance.getAttribute('__jet_item_pk__');
      params = { __parent__: instance.getAttribute('__parent__') };
    } else {
      pk = instance.primaryKey;
    }

    if (!isSet(pk)) {
      return of(true);
    }

    return this.dataSyncService
      .updateRecord(project, environment, resource.uniqueName, modelDescription.model, pk, params)
      .pipe(catchError(() => of(false)));
  }

  public triggerAutomationOnUpdate(
    project: Project,
    environment: Environment,
    resource: Resource,
    controller: ResourceController,
    modelDescription: ModelDescription,
    instance: Model,
    action: ModelAction
  ): Observable<boolean> {
    if (
      (controller instanceof JetBridgeResourceController && resource.params['deploy'] == ResourceDeploy.Direct) ||
      controller instanceof JetAppResourceController
    ) {
      return of(true);
    }

    let pk: string;

    if (resource.typeItem.name == ResourceName.Firebase) {
      // TODO: Move externalItemPrimaryKey out of generators
      pk = instance.getAttribute('__jet_item_pk__');
    } else {
      pk = instance.primaryKey;
    }

    return this.automationService
      .trackModelChange(project, environment, resource, modelDescription.model, action, pk, instance.serialize())
      .pipe(catchError(() => of(false)));
  }

  public logModelActionOnUpdate(
    project: Project,
    environment: Environment,
    resource: Resource,
    controller: ResourceController,
    modelDescription: ModelDescription,
    instance: Model,
    action: ModelAction,
    options: {
      viewSettings?: string;
    } = {}
  ) {
    if (action == ModelAction.Create) {
      return this.userActivityService.logModelCreate(project, environment, modelDescription, {
        id: instance.primaryKey,
        data: instance.serialize(),
        viewSettings: options.viewSettings
      });
    } else if (action == ModelAction.Update) {
      return this.userActivityService.logModelUpdate(project, environment, modelDescription, instance.primaryKey, {
        // changes: form.changes,
        viewSettings: options.viewSettings
      });
    } else if (action == ModelAction.Delete) {
      return this.userActivityService.logModelDelete(project, environment, modelDescription, {
        id: instance.primaryKey,
        data: instance.serialize(),
        viewSettings: options.viewSettings
      });
    } else {
      return of(undefined);
    }
  }

  public create(
    project: Project,
    environment: Environment,
    modelId: string,
    modelInstance: Model,
    fields?: string[]
  ): Observable<Model> {
    return this.getForModel(
      project.getEnvironmentResources(environment.uniqueName),
      modelId,
      'createQuery',
      false
    ).pipe(
      switchMap(modelParams => {
        const [resource, modelDescription, controller] = modelParams;
        return controller
          .modelCreate(resource, modelDescription, modelInstance, fields)
          .pipe(
            delayWhen(result =>
              this.onModelAction(
                project,
                environment,
                resource,
                controller,
                modelDescription,
                result,
                ModelAction.Create
              )
            )
          );
      }),
      share()
    );
  }

  public update(
    project: Project,
    environment: Environment,
    modelId: string,
    modelInstance: Model,
    fields?: string[]
  ): Observable<Model> {
    return this.getForModel(
      project.getEnvironmentResources(environment.uniqueName),
      modelId,
      'updateQuery',
      false
    ).pipe(
      switchMap(modelParams => {
        const [resource, modelDescription, controller] = modelParams;
        return controller
          .modelUpdate(resource, modelDescription, modelInstance, fields)
          .pipe(
            delayWhen(() =>
              this.onModelAction(
                project,
                environment,
                resource,
                controller,
                modelDescription,
                modelInstance,
                ModelAction.Update
              )
            )
          );
      }),
      share()
    );
  }

  public delete(project: Project, environment: Environment, modelId: string, modelInstance: Model): Observable<Object> {
    return this.getForModel(
      project.getEnvironmentResources(environment.uniqueName),
      modelId,
      'deleteQuery',
      false
    ).pipe(
      switchMap(modelParams => {
        const [resource, modelDescription, controller] = modelParams;
        return controller
          .modelDelete(resource, modelDescription, modelInstance)
          .pipe(
            delayWhen(() =>
              this.onModelAction(
                project,
                environment,
                resource,
                controller,
                modelDescription,
                modelInstance,
                ModelAction.Delete
              )
            )
          );
      }),
      share()
    );
  }

  public reorder(
    project: Project,
    environment: Environment,
    modelId: string,
    forward: boolean,
    segmentFrom: number,
    segmentTo: number,
    item: number,
    segmentByOrderingField = false
  ): Observable<Object> {
    return this.getForModel(project.getEnvironmentResources(environment.uniqueName), modelId, undefined, false).pipe(
      switchMap(modelParams => {
        const [resource, modelDescription, controller] = modelParams;
        return controller.modelReorder(
          resource,
          modelDescription,
          forward,
          segmentFrom,
          segmentTo,
          item,
          segmentByOrderingField
        );
      }),
      share()
    );
  }

  public resetOrder(
    project: Project,
    environment: Environment,
    modelId: string,
    ordering?: string,
    valueOrdering?: string
  ): Observable<Object> {
    return this.getForModel(project.getEnvironmentResources(environment.uniqueName), modelId, undefined, false).pipe(
      switchMap(modelParams => {
        const [resource, modelDescription, controller] = modelParams;
        return controller.modelResetOrder(resource, modelDescription, ordering, valueOrdering);
      }),
      share()
    );
  }

  public aggregate(
    project: Project,
    environment: Environment,
    modelId: string,
    yFunc: AggregateFunc,
    yColumn: string,
    params?: {}
  ): Observable<any> {
    return this.getForModel(project.getEnvironmentResources(environment.uniqueName), modelId).pipe(
      switchMap(modelParams => {
        const [resource, modelDescription, controller] = modelParams;

        if (!controller.modelAggregate) {
          return of(null);
        }

        return controller.modelAggregate(resource, modelDescription, yFunc, yColumn, params);
      }),
      share()
    );
  }

  public group(
    project: Project,
    environment: Environment,
    modelId: string,
    xColumns: { xColumn: string; xLookup?: DatasetGroupLookup }[],
    yFunc: AggregateFunc,
    yColumn: string,
    params?: {}
  ): Observable<DataGroup[]> {
    return this.getForModel(project.getEnvironmentResources(environment.uniqueName), modelId).pipe(
      switchMap(modelParams => {
        const [resource, modelDescription, controller] = modelParams;

        if (!controller.modelGroup) {
          return of([]);
        }

        return controller.modelGroup(resource, modelDescription, xColumns, yFunc, yColumn, params);
      }),
      share()
    );
  }

  public groupQuery(
    project: Project,
    environment: Environment,
    resource: Resource,
    query: ModelDescriptionQuery,
    xColumns: { xColumn: string; xLookup?: DatasetGroupLookup }[],
    yFunc: AggregateFunc,
    yColumn: string,
    parameters: ParameterField[] = [],
    params?: {}
  ): Observable<DataGroup[]> {
    if (!resource) {
      return throwError(new AppError('Resource not found'));
    }

    if (!query) {
      return throwError(new AppError('Query not specified'));
    }

    params = params || {};

    if (query.queryType == QueryType.Simple && query.simpleQuery) {
      const modelId = [resource.uniqueName, query.simpleQuery.model].join('.');
      return this.modelDescriptionStore.getDetailFirst(modelId).pipe(
        switchMap(modelDescription => {
          if (!modelDescription) {
            throw new AppError('Collection not found');
          }

          return this.group(project, environment, modelDescription.modelId, xColumns, yFunc, yColumn, params);
        })
      );
    } else if (query.queryType == QueryType.Http && query.httpQuery) {
      const controller = this.resourceControllerService.get<RestApiResourceControllerService>(ResourceType.RestAPI);
      return controller.group(resource, query.httpQuery, xColumns, yFunc, yColumn, parameters, params);
    } else {
      return of(undefined);
    }
  }

  public getSiblings(
    project: Project,
    environment: Environment,
    modelId: string,
    id: string,
    params?: {},
    body?: {}
  ): Observable<ModelResponse.SiblingsResponse> {
    return this.getForModel(project.getEnvironmentResources(environment.uniqueName), modelId).pipe(
      switchMap(modelParams => {
        const [resource, modelDescription, controller] = modelParams;
        return controller.modelGetSiblings(resource, modelDescription, id, params, body);
      }),
      share()
    );
  }

  public sql(
    resource: Resource,
    query: string,
    tokens?: Object,
    version?: number
  ): Observable<ModelResponse.SqlResponse> {
    const controller = this.resourceControllerService.get(resource.type);
    return controller.sql(resource, query, { tokens: tokens, version: version }).pipe(share());
  }

  public sqls(
    resource: Resource,
    queries: { query: string; options?: SqlQueryOptions }[]
  ): Observable<ModelResponse.SqlResponse[]> {
    const controller = this.resourceControllerService.get(resource.type);
    return controller.sqls(resource, queries).pipe(share());
  }
}

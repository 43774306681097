import { ChangeDetectionStrategy, Component, HostBinding, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-column',
  templateUrl: './page-column.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PageColumnComponent implements OnInit {
  @Input() fill = true;
  @Input() fillGrow = 1;
  @Input() verticalAlign: 'top' | 'center' = 'top';

  @HostBinding('class.page-columns__item') cls = true;

  @HostBinding('class.page-columns__item_fill') get fillCls() {
    return this.fill;
  }

  @HostBinding('style.flex-grow') get flexGrow() {
    return this.fill ? this.fillGrow : null;
  }

  @HostBinding('class.page-columns__item_vertical-align_center') get verticalAlignTop() {
    return this.verticalAlign == 'center';
  }

  constructor() {}

  ngOnInit() {}
}

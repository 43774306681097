import { Pipe, PipeTransform } from '@angular/core';
import clamp from 'lodash/clamp';

import { isSet } from '@shared';

@Pipe({
  name: 'appMarginControlBorderWidth'
})
export class MarginControlBorderWidthPipe implements PipeTransform {
  valueToWidth(value: number): number {
    return clamp(1 + Math.pow(value, 0.5), 2, 10);
  }

  transform(value: number): any {
    return isSet(value) ? this.valueToWidth(value) : null;
  }
}

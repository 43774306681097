import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import toPairs from 'lodash/toPairs';
import { combineLatest, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { AdminMode, ROUTE_ADMIN_MODE } from '@modules/admin-mode';
import { ApiService } from '@modules/api';
import {
  Environment,
  Project,
  Resource,
  ResourceTypeItem,
  SecretToken,
  SecretTokenService,
  SecretTokenType
} from '@modules/projects';
import { HttpContentType, HttpMethod, HttpQuery, HttpQueryService, QueryType, StorageQuery } from '@modules/queries';
import { ResourceParamsResult } from '@modules/resources';
import { Storage } from '@modules/storages';
import { AppError } from '@shared';

import { ResourceGeneratorService } from '../resource-generator/resource-generator.service';

export interface GoogleCloudStorageParamsOptions {
  serviceToken: string;
  projectId: string;
  accessToken: string;
}

@Injectable()
export class GoogleCloudStorageGeneratorService extends ResourceGeneratorService<GoogleCloudStorageParamsOptions> {
  tokenName = 'access_token';

  constructor(
    @Inject(ROUTE_ADMIN_MODE) private mode: AdminMode,
    private secretTokenService: SecretTokenService,
    private apiService: ApiService,
    private http: HttpClient,
    private httpQueryService: HttpQueryService
  ) {
    super();
  }

  getParamsOptions(
    project: Project,
    environment: Environment,
    resource: Resource
  ): Observable<GoogleCloudStorageParamsOptions> {
    return this.getServiceToken(project.uniqueName, environment.uniqueName, resource.uniqueName).pipe(
      switchMap(serviceToken => {
        return this.readServiceToken(serviceToken).pipe(
          map(result => [serviceToken, result ? result.projectId : undefined, result ? result.accessToken : undefined])
        );
      }),
      map(([serviceToken, projectId, accessToken]) => {
        return {
          serviceToken: serviceToken,
          projectId: projectId,
          accessToken: accessToken
        };
      })
    );
  }

  getServiceToken(projectName: string, environmentName: string, resourceName: string) {
    return this.secretTokenService
      .getDetail(projectName, environmentName, resourceName, this.tokenName, this.mode == AdminMode.Builder)
      .pipe(
        map(result => {
          if (result.params['service_token']) {
            return JSON.stringify(result.params['service_token']);
          }

          return '';
        })
      );
  }

  readServiceToken(value: string): Observable<{ projectId: string; accessToken: string }> {
    // const value = this.form.value['service_token'].trim();

    try {
      const serviceToken = JSON.parse(value.trim());

      const url = this.apiService.methodURL('resource_auth/');
      let headers = new HttpHeaders();
      const data = {
        name: 'firebase',
        service_token: serviceToken
      };

      headers = this.apiService.setHeadersToken(headers);

      return this.http.post(url, data, { headers: headers }).pipe(
        map(
          result => {
            // this.form.patchValue({
            //   project_id: result['project_id'],
            //   access_token: result['token']
            // });
            return {
              projectId: result['project_id'],
              accessToken: result['token']
            };
          }
          // () => {
          //   this.form.patchValue({
          //     project_id: '',
          //     access_token: ''
          //   });
          // }
        )
      );
    } catch (e) {
      // this.form.patchValue({
      //   project_id: '',
      //   access_token: ''
      // });
      return of(undefined);
    }
  }

  createStorages(projectId: string, setUpHeaders: { [header: string]: string | string[] }): Observable<Storage[]> {
    const query = new HttpQuery();

    query.url = `https://storage.googleapis.com/storage/v1/b?project=${projectId}`;
    query.headers = toPairs(setUpHeaders).map(([k, v]) => {
      return { name: k, value: v as string };
    });

    return this.httpQueryService.requestBody<Object>(query).pipe(
      map(response => {
        if (!response['items'] || !response['items'].length) {
          throw new AppError('This account has no storages');
        }

        return response['items'].map((item, i) => {
          const storage = new Storage();
          const bucketId = item['id'];
          const bucketName = item['name'];

          storage.uniqueName = bucketId;
          storage.name = bucketName;

          storage.uploadQuery = new StorageQuery();
          storage.uploadQuery.queryType = QueryType.Http;
          storage.uploadQuery.httpQuery = new HttpQuery();
          storage.uploadQuery.httpQuery.method = HttpMethod.POST;
          storage.uploadQuery.httpQuery.url = `https://storage.googleapis.com/upload/storage/v1/b/${bucketId}/o?uploadType=multipart`;
          storage.uploadQuery.httpQuery.headers = [{ name: 'Authorization', value: `Bearer {-${this.tokenName}-}` }];
          storage.uploadQuery.httpQuery.bodyType = HttpContentType.FormData;
          storage.uploadQuery.httpQuery.body = [
            {
              name: 'metadata',
              value: `{
                  "name": "{{path}}{{file_name}}",
                  "predefinedAcl": "publicRead",
                  "mimeType": "{{file_mime_type}}",
                  "acl": [{"entity": "allUsers", "role": "READER"}]
                }`,
              contentType: 'application/json'
            },
            {
              name: 'file',
              value: '{{file}}'
            }
          ];
          storage.uploadQuery.httpQuery.responseTransformer = `return 'https://storage.googleapis.com/${bucketId}/' + encodeURIComponent(data.name)`;

          storage.removeQuery = new StorageQuery();
          storage.removeQuery.queryType = QueryType.Http;
          storage.removeQuery.httpQuery = new HttpQuery();
          storage.removeQuery.httpQuery.method = HttpMethod.DELETE;
          storage.removeQuery.httpQuery.url = `https://storage.googleapis.com/storage/v1/b/${bucketId}/o/{{encodeURIComponent(path)}}`;
          storage.removeQuery.httpQuery.headers = [{ name: 'Authorization', value: `Bearer {-${this.tokenName}-}` }];
          storage.removeQuery.httpQuery.bodyType = HttpContentType.Raw;

          storage.createDirectoryQuery = new StorageQuery();
          storage.createDirectoryQuery.queryType = QueryType.Http;
          storage.createDirectoryQuery.httpQuery = new HttpQuery();
          storage.createDirectoryQuery.httpQuery.method = HttpMethod.POST;
          storage.createDirectoryQuery.httpQuery.url = `https://storage.googleapis.com/upload/storage/v1/b/${bucketId}/o`;
          storage.createDirectoryQuery.httpQuery.headers = [
            { name: 'Authorization', value: `Bearer {-${this.tokenName}-}` }
          ];
          storage.createDirectoryQuery.httpQuery.queryParams = [
            {
              name: 'uploadType',
              value: 'media'
            },
            {
              name: 'name',
              value: '{{path}}/'
            }
          ];

          storage.getQuery = new StorageQuery();
          storage.getQuery.queryType = QueryType.Http;
          storage.getQuery.httpQuery = new HttpQuery();
          storage.getQuery.httpQuery.method = HttpMethod.GET;
          storage.getQuery.httpQuery.url = `https://storage.googleapis.com/storage/v1/b/${bucketId}/o`;
          storage.getQuery.httpQuery.headers = [{ name: 'Authorization', value: `Bearer {-${this.tokenName}-}` }];
          storage.getQuery.httpQuery.queryParams = [
            {
              name: 'delimiter',
              value: '/'
            },
            {
              name: 'prefix',
              value: '{{path}}'
            }
          ];
          storage.getQuery.httpQuery.responseTransformer = `
const folders = (data.prefixes || []).map(item => {
    return {
      type: 'folder',
      path: item
    };
});

const files = (data.items || []).filter(item => !item['name'].endsWith('/')).map(item => {
    return {
      type: item['name'].substr(-1) == '/' ? 'folder' : 'file',
      path: item['name'],
      url: 'https://storage.googleapis.com/${bucketId}/' + encodeURIComponent(item.name),
      size: item.size,
      created: item.timeCreated,
      updated: item.updated
    };
});


return [...folders, ...files];`;

          return storage;
        });
      }),
      this.apiService.catchApiError()
    );
  }

  generateParams(
    project: Project,
    environment: Environment,
    typeItem: ResourceTypeItem,
    options: GoogleCloudStorageParamsOptions
  ): Observable<ResourceParamsResult> {
    const setUpHeaders = {
      Authorization: `Bearer ${options.accessToken}`
    };

    return combineLatest(this.createStorages(options.projectId, setUpHeaders)).pipe(
      map(([storages]) => {
        const token = new SecretToken();

        token.name = this.tokenName;
        token.type = SecretTokenType.Firebase;
        token.value = '';

        try {
          token.params = {
            service_token: JSON.parse(options.serviceToken)
          };
        } catch (e) {
          token.params = {};
        }

        return {
          resourceParams: {},
          secretTokens: [token.serialize()],
          storages: storages.map(item => item.serialize())
        };
      })
    );
  }
}

<div class="section-tab" [class.section-tab_active]="active">
  <a *ngIf="link" [appLink]="link" class="section-tab__inner" (click)="selected.emit()">
    <span class="section-tab__inner-label">{{ label }}</span>
    <span *ngIf="icon" class="section-tab__inner-icon" [ngClass]="['icon-' + icon]"></span>
  </a>
  <a *ngIf="!link" href="javascript:void(0)" class="section-tab__inner" (click)="selected.emit()">
    <span class="section-tab__inner-label">{{ label }}</span>
    <span *ngIf="icon" class="section-tab__inner-icon" [ngClass]="['icon-' + icon]"></span>
  </a>
</div>

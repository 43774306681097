import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ModelStrPipe } from './pipes/model-str/model-str.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [ModelStrPipe],
  exports: [ModelStrPipe]
})
export class ModelQueriesModule {}

import { BehaviorSubject, of, ReplaySubject } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';
var SingletonStore = /** @class */ (function () {
    function SingletonStore() {
        this._instance = new BehaviorSubject(undefined);
        this._loading = new BehaviorSubject(false);
        this._error = new BehaviorSubject(undefined);
    }
    Object.defineProperty(SingletonStore.prototype, "instance", {
        get: function () {
            return this._instance.value;
        },
        set: function (value) {
            this._instance.next(value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SingletonStore.prototype, "instance$", {
        get: function () {
            return this._instance.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SingletonStore.prototype, "loading", {
        get: function () {
            return this._loading.value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SingletonStore.prototype, "loading$", {
        get: function () {
            return this._loading.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SingletonStore.prototype, "error", {
        get: function () {
            return this._error.value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SingletonStore.prototype, "error$", {
        get: function () {
            return this._error.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    SingletonStore.prototype.get = function (forceUpdate, options) {
        var _this = this;
        if (forceUpdate === void 0) { forceUpdate = false; }
        if (options === void 0) { options = {}; }
        if (this.requested && forceUpdate) {
            // this.fetchSubscription.unsubscribe(); // TODO: remove redundant queries
            this.requested = undefined;
            this.fetchSubscription = undefined;
        }
        if (!this.requested && this.instance && !forceUpdate) {
            return this.instance$;
        }
        if (!this.requested) {
            var subject_1 = new ReplaySubject(1);
            this.requested = subject_1;
            this._loading.next(true);
            this._error.next(undefined);
            this.fetchSubscription = this.fetch().subscribe(function (result) {
                subject_1.next(result);
                _this._loading.next(false);
                _this._error.next(undefined);
            }, function (error) {
                subject_1.error(error);
                _this._loading.next(false);
                _this._error.next(error);
            });
        }
        var requested = this.requested;
        return requested.pipe(switchMap(function (result) {
            if (_this.requested === requested) {
                _this.requested = undefined;
                _this.fetchSubscription = undefined;
                _this.instance = result;
                return _this.instance$;
            }
            else {
                return _this.get(false, options);
            }
        })
        // catchError(e => {
        //   if (this.requested === requested) {
        //     this.requested = undefined;
        //     this.fetchSubscription = undefined;
        //     this.instance = undefined;
        //     return this.instance$;
        //   } else {
        //     return this.get();
        //   }
        // })
        );
    };
    SingletonStore.prototype.getFirst = function (forceUpdate, options) {
        if (forceUpdate === void 0) { forceUpdate = false; }
        if (options === void 0) { options = {}; }
        return this.get(forceUpdate, options).pipe(first());
    };
    SingletonStore.prototype.updateIfLoaded = function () {
        if (this.instance || this.requested) {
            return this.getFirst(true).pipe(map(function () { return undefined; }));
        }
        else {
            return of(undefined);
        }
    };
    SingletonStore.prototype.reset = function () {
        if (this.fetchSubscription) {
            this.fetchSubscription.unsubscribe();
        }
        this.requested = undefined;
        this.instance = undefined;
    };
    return SingletonStore;
}());
export { SingletonStore };

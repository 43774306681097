import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { combineLatest, of, throwError } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { FormUtils } from '@common/form-utils';
import { AggregateFunc } from '@modules/charts';
import { ModelService } from '@modules/model-queries';
import { CurrentEnvironmentStore, CurrentProjectStore } from '@modules/projects';
var MoveForm = /** @class */ (function () {
    function MoveForm(formUtils, fb, modelService, currentProjectStore, currentEnvironmentStore) {
        this.formUtils = formUtils;
        this.fb = fb;
        this.modelService = modelService;
        this.currentProjectStore = currentProjectStore;
        this.currentEnvironmentStore = currentEnvironmentStore;
        this.form = this.fb.group({
            move: new FormControl('after', Validators.required),
            model: new FormControl(null)
        });
    }
    MoveForm.prototype.init = function (modelDescription) {
        this.modelDescription = modelDescription;
    };
    Object.defineProperty(MoveForm.prototype, "modelNeeded", {
        get: function () {
            var value = this.form.value;
            return value['move'] == 'after' || value['move'] == 'before';
        },
        enumerable: true,
        configurable: true
    });
    MoveForm.prototype.isForwardProcessing = function () {
        var value = this.form.value;
        if (value['move'] == 'after') {
            return false;
        }
        else if (value['move'] == 'before') {
            return true;
        }
        else if (value['move'] == 'first') {
            return false;
        }
        else if (value['move'] == 'last') {
            return true;
        }
        else {
            return true;
        }
    };
    MoveForm.prototype.submit = function (id) {
        var _this = this;
        this.form.disable();
        var value = this.form.value;
        var requests = [
            this.modelService.getDetail(this.currentProjectStore.instance, this.currentEnvironmentStore.instance, this.modelDescription.modelId, this.modelDescription.primaryKeyField, id)
        ];
        if (this.modelNeeded) {
            requests.push(this.modelService.getDetail(this.currentProjectStore.instance, this.currentEnvironmentStore.instance, this.modelDescription.modelId, this.modelDescription.primaryKeyField, value['model']));
            requests.push(of(undefined));
        }
        else {
            requests.push(of(undefined));
            this.modelService.aggregate(this.currentProjectStore.instance, this.currentEnvironmentStore.instance, this.modelDescription.modelId, AggregateFunc.Count, undefined);
        }
        return combineLatest.apply(void 0, requests).pipe(switchMap(function (result) {
            var moveModel = result[0];
            var toModel = result[1];
            var count = result[2];
            var orderingField = _this.modelDescription.orderingField;
            var moveOrder = moveModel.getAttribute(orderingField);
            var toOrder = toModel ? toModel.getAttribute(orderingField) : undefined;
            var forward;
            var segmentFrom;
            var segmentTo;
            if (value['move'] == 'after') {
                forward = toOrder >= moveOrder;
                if (forward) {
                    forward = toOrder >= moveOrder;
                    segmentFrom = moveOrder + 1;
                    segmentTo = toOrder;
                }
                else {
                    segmentFrom = toOrder + 1;
                    segmentTo = moveOrder - 1;
                }
            }
            else if (value['move'] == 'before') {
                forward = toOrder >= moveOrder;
                if (forward) {
                    segmentFrom = moveOrder + 1;
                    segmentTo = toOrder - 1;
                }
                else {
                    segmentFrom = toOrder;
                    segmentTo = moveOrder - 1;
                }
            }
            else if (value['move'] == 'first') {
                forward = false;
                segmentFrom = 1;
                segmentTo = moveOrder - 1;
            }
            else if (value['move'] == 'last') {
                forward = true;
                segmentFrom = moveOrder + 1;
                segmentTo = count;
            }
            return _this.modelService.reorder(_this.currentProjectStore.instance, _this.currentEnvironmentStore.instance, _this.modelDescription.modelId, forward, segmentFrom, segmentTo, moveModel.primaryKey, true);
        }), tap(function () {
            _this.form.enable();
            _this.form.markAsPristine();
        }), catchError(function (error) {
            _this.formUtils.showFormErrors(_this.form, error);
            _this.form.enable();
            _this.form.markAsDirty();
            return throwError(error);
        }));
    };
    return MoveForm;
}());
export { MoveForm };
